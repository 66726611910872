import React, { useCallback, useEffect, useMemo, useState } from "react";
import PageLayout from "layouts/PageLayout";
import Snackbar from "@mui/material/Snackbar";
import Alert from "components/Alert";
import PageContentLayout from "layouts/PageContentLayout";
import MeetingEditPageContent from "components/Content/MeetingEditPageContent";
import { useSearchParams } from "react-router-dom";
import { GetMySettings } from "services/UsersService";
import useOnboarding from "hooks/UseOnboarding";
import MeetingsOnboardingDialog from "components/Dialogs/Onboarding/Meetings";

const getDistributionModeIdFromSettingValue = (value: string) => {
    if (value === 'NoneRecordOnly') return 0;
    if (value === 'CalendarInviteOnly') return 1;
    if (value === 'CalendarInvitePlusEmail') return 2;
    return +value;
}

export default function MeetingCreatePage() {
    const [summaryBar, setSummaryBar] = useState(<></>);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [searchParams] = useSearchParams();
    const [defaultLocationTypeId, setDefaultLocationTypeId] = useState(2);
    const [defaultDistributionModeId, setDefaultDistributionModeId] = useState(2);
    const [defaultDuration, setDefaultDuration] = useState(60);
    const { isNeeded: isOnboardingNeeded, showDialog: showOnboardingDialog, setShowDialog: setShowOnboardingDialog } = useOnboarding('MeetingsOnboardingUtcDate');

    useEffect(() => {
        if (isOnboardingNeeded) setShowOnboardingDialog(true);
    }, [isOnboardingNeeded, setShowOnboardingDialog]);

    const contactIds = useMemo(() => {
        const cIds = searchParams.get('contactIds');
        if (cIds) {
            const split = cIds.split(',');
            return split.map(s => +s);
        }
        return undefined;
    }, [searchParams]);

    const typeId = useMemo(() => {
        const tId = searchParams.get('typeId');
        if (tId && !isNaN(+tId)) return +tId;
        return undefined;
    }, [searchParams]);

    const source = useMemo(() => {
        const tId = searchParams.get('source');
        if (tId && !isNaN(+tId)) return +tId;
        return undefined;
    }, [searchParams]);

    const placementId = useMemo(() => {
        const tId = searchParams.get('placementId');
        if (tId && !isNaN(+tId)) return +tId;
        return undefined;
    }, [searchParams]);

    const jobId = useMemo(() => {
        const tId = searchParams.get('jobId');
        if (tId && !isNaN(+tId)) return +tId;
        return undefined;
    }, [searchParams]);

    useEffect(() => {
        const getSettings = async () => {
            const settings = await GetMySettings();
            if (settings) {
                for (let i = 0; i < settings.length; i++) {
                    const s = settings[i];
                    if (s.value && s.type === 'DefaultMeetingLocationTypeId') setDefaultLocationTypeId(+s.value);
                    if (s.value && s.type === 'DefaultDistributionMethod_Meetings') setDefaultDistributionModeId(getDistributionModeIdFromSettingValue(s.value));
                    if (s.value && s.type === 'DefaultDuration_Meetings') setDefaultDuration(+s.value);
                }
            }
        };
        getSettings();
    }, []);

    const onContinueOnboarding = useCallback((distributionMethod: string, locationType: string, duration: string) => {
        setDefaultDistributionModeId(getDistributionModeIdFromSettingValue(distributionMethod));
        setDefaultLocationTypeId(+locationType);
        setDefaultDuration(+duration);
        setShowOnboardingDialog(false);
    }, [setShowOnboardingDialog]);

    return (
        <PageLayout SummaryBar={summaryBar}>
            <Snackbar open={errorMessage !== ''} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <Snackbar open={successMessage !== ''} autoHideDuration={3000} onClose={() => setSuccessMessage('')} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert color="success" onClose={() => setSuccessMessage('')}>{ successMessage }</Alert>
            </Snackbar>
            <PageContentLayout showLoading={isLoading}>
                <MeetingsOnboardingDialog
                    open={showOnboardingDialog}
                    closeHandler={() => setShowOnboardingDialog(false)}
                    continueHandler={onContinueOnboarding}
                />
                <MeetingEditPageContent
                    typeId={typeId}
                    contactIds={contactIds}
                    source={source}
                    placementId={placementId}
                    jobId={jobId}
                    defaultLocationTypeId={defaultLocationTypeId}
                    defaultDistributionModeId={defaultDistributionModeId}
                    defaultDuration={defaultDuration}
                    setSummaryBar={setSummaryBar}
                    errorHandler={setErrorMessage}
                    loadingHandler={setIsLoading}
                    successHandler={setSuccessMessage}
                />
            </PageContentLayout>
        </PageLayout>
    );
}