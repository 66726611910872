import Box from "@mui/material/Box";
import { JobBoardFieldSetupValue } from "common/models/JobPosting/JobBoards";
import React, { useEffect, useMemo, useState } from "react";
import { GetJobBoardFieldSetupValues } from "services/JobBoardsService";
import { CategoryToOccupatonsMap } from "util/Definitions/Adverts/CarrerOne";
import JobBoardViewField from "./JobBoardViewField";

interface Props {
    jobBoardId: number,
    advertId: number,
    attributes?: Record<string, string>,
    advertRecordAttributes?: Record<string, string>,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
}

const countryMap: Record<string, string> = {
	"15343": "Afghanistan",
	"15354": "Aland Islands",
	"15346": "Albania",
	"15401": "Algeria",
	"15351": "American Samoa",
	"15341": "Andorra",
	"15348": "Angola",
	"15345": "Anguilla",
	"15349": "Antarctica",
	"15344": "Antigua and Barbuda",
	"15350": "Argentina",
	"15347": "Armenia",
	"15353": "Aruba",
	"15299": "Australia",
	"15352": "Austria",
	"15355": "Azerbaijan",
	"15371": "Bahamas",
	"15362": "Bahrain",
	"15358": "Bangladesh",
	"15357": "Barbados",
	"15375": "Belarus",
	"15359": "Belgium",
	"15376": "Belize",
	"15364": "Benin",
	"15366": "Bermuda",
	"15372": "Bhutan",
	"15368": "Bolivia",
	"15369": "Bonaire, Sint Eustatius and Saba",
	"15356": "Bosnia and Herzegovina",
	"15374": "Botswana",
	"15373": "Bouvet Island",
	"15370": "Brazil",
	"15445": "British Indian Ocean Territory",
	"15367": "Brunei Darussalam",
	"15361": "Bulgaria",
	"15360": "Burkina Faso",
	"15363": "Burundi",
	"15391": "Cabo Verde",
	"15456": "Cambodia",
	"15386": "Cameroon",
	"15377": "Canada",
	"15463": "Cayman Islands",
	"15380": "Central African Republic",
	"15553": "Chad",
	"15385": "Chile",
	"15387": "China",
	"15393": "Christmas Island",
	"15378": "Cocos (Keeling) Islands",
	"15388": "Colombia",
	"15458": "Comoros",
	"15381": "Congo",
	"15379": "Congo, Democratic Republic of the",
	"15384": "Cook Islands",
	"15389": "Costa Rica",
	"15383": "Cote d'Ivoire",
	"15437": "Croatia",
	"15390": "Cuba",
	"15392": "Curacao",
	"15394": "Cyprus",
	"15395": "Czechia",
	"15398": "Denmark",
	"15397": "Djibouti",
	"15399": "Dominica",
	"15400": "Dominican Republic",
	"15402": "Ecuador",
	"15404": "Egypt",
	"15548": "El Salvador",
	"15427": "Equatorial Guinea",
	"15406": "Eritrea",
	"15403": "Estonia",
	"15551": "Eswatini",
	"15408": "Ethiopia",
	"15411": "Falkland Islands (Malvinas)",
	"15413": "Faroe Islands",
	"15410": "Fiji",
	"15409": "Finland",
	"15414": "France",
	"15419": "French Guiana",
	"15513": "French Polynesia",
	"15554": "French Southern Territories",
	"15415": "Gabon",
	"15424": "Gambia",
	"15418": "Georgia",
	"15396": "Germany",
	"15421": "Ghana",
	"15422": "Gibraltar",
	"15428": "Greece",
	"15423": "Greenland",
	"15417": "Grenada",
	"15426": "Guadeloupe",
	"15431": "Guam",
	"15430": "Guatemala",
	"15420": "Guernsey",
	"15425": "Guinea",
	"15432": "Guinea-Bissau",
	"15433": "Guyana",
	"15438": "Haiti",
	"15435": "Heard Island and McDonald Islands",
	"15574": "Holy See",
	"15436": "Honduras",
	"15434": "Hong Kong",
	"15439": "Hungary",
	"15448": "Iceland",
	"15444": "India",
	"15440": "Indonesia",
	"15447": "Iran",
	"15446": "Iraq",
	"15441": "Ireland",
	"15443": "Isle of Man",
	"15442": "Israel",
	"15449": "Italy",
	"15451": "Jamaica",
	"15453": "Japan",
	"15450": "Jersey",
	"15452": "Jordan",
	"15464": "Kazakhstan",
	"15454": "Kenya",
	"15457": "Kiribati",
	"15462": "Kuwait",
	"15455": "Kyrgyzstan",
	"15465": "Lao People's Democratic Republic",
	"15474": "Latvia",
	"15466": "Lebanon",
	"15471": "Lesotho",
	"15470": "Liberia",
	"15475": "Libya",
	"15468": "Liechtenstein",
	"15472": "Lithuania",
	"15473": "Luxembourg",
	"15487": "Macao",
	"15481": "Madagascar",
	"15495": "Malawi",
	"15497": "Malaysia",
	"15494": "Maldives",
	"15484": "Mali",
	"15492": "Malta",
	"15482": "MarshIslands",
	"15489": "Martinique",
	"15490": "Mauritania",
	"15493": "Mauritius",
	"15584": "Mayotte",
	"15496": "Mexico",
	"15412": "Micronesia",
	"15478": "Moldova",
	"15477": "Monaco",
	"15486": "Mongolia",
	"15479": "Montenegro",
	"15491": "Montserrat",
	"15476": "Morocco",
	"15498": "Mozambique",
	"15485": "Myanmar",
	"15499": "Namibia",
	"15508": "Nauru",
	"15507": "Nepal",
	"15505": "Netherlands",
	"15500": "New Caledonia",
	"15335": "New Zealand",
	"15504": "Nicaragua",
	"15501": "Niger",
	"15503": "Nigeria",
	"15509": "Niue",
	"15502": "Norfolk Island",
	"15460": "North Korea",
	"15483": "North Macedonia",
	"15488": "Northern Mariana Islands",
	"15506": "Norway",
	"15510": "Oman",
	"15516": "Pakistan",
	"15523": "Palau",
	"15521": "Palestine",
	"15511": "Panama",
	"15514": "Papua New Guinea",
	"15524": "Paraguay",
	"15512": "Peru",
	"15515": "Philippines",
	"15519": "Pitcairn",
	"15517": "Poland",
	"15522": "Portugal",
	"15520": "Puerto Rico",
	"15525": "Qatar",
	"15526": "Reunion",
	"15527": "Romania",
	"15529": "Russia",
	"15530": "Rwanda",
	"15365": "Saint Barthelemy",
	"15537": "Saint Helena, Ascension and Tristan da Cunha",
	"15459": "Saint Kitts and Nevis",
	"15467": "Saint Lucia",
	"15480": "Saint Martin",
	"15518": "Saint Pierre and Miquelon",
	"15575": "Saint Vincent and the Grenadines",
	"15582": "Samoa",
	"15542": "San Marino",
	"15547": "Sao Tome and Principe",
	"15531": "Saudi Arabia",
	"15543": "Senegal",
	"15528": "Serbia",
	"15533": "Seychelles",
	"15541": "Sierra Leone",
	"15536": "Singapore",
	"15549": "Sint Maarten",
	"15540": "Slovakia",
	"15538": "Slovenia",
	"15532": "Solomon Islands",
	"15544": "Somalia",
	"15585": "South Africa",
	"15429": "South Georgia and the South Sandwich Islands",
	"15461": "South Korea",
	"15546": "South Sudan",
	"15407": "Spain",
	"15469": "Sri Lanka",
	"15534": "Sudan",
	"15545": "Suriname",
	"15539": "Svalbard and Jan Mayen",
	"15535": "Sweden",
	"15382": "Switzerland",
	"15550": "Syrian Arab Republic",
	"15566": "Taiwan",
	"15557": "Tajikistan",
	"15567": "Tanzania",
	"15556": "Thailand",
	"15559": "Timor-Leste",
	"15555": "Togo",
	"15558": "Tokelau",
	"15562": "Tonga",
	"15564": "Trinidad and Tobago",
	"15561": "Tunisia",
	"15563": "Turkey",
	"15560": "Turkmenistan",
	"15552": "Turks and Caicos Islands",
	"15565": "Tuvalu",
	"15569": "Uganda",
	"15568": "Ukraine",
	"15342": "United Arab Emirates",
	"15416": "United Kingdom of Great Britain and Northern Ireland",
	"15570": "United States Minor Outlying Islands",
	"15571": "United States of America",
	"15572": "Uruguay",
	"15573": "Uzbekistan",
	"15580": "Vanuatu",
	"15576": "Venezuela",
	"15579": "Vietnam",
	"15577": "Virgin Islands (British)",
	"15578": "Virgin Islands (U.S.)",
	"15581": "Wallis and Futuna",
	"15405": "Western Sahara",
	"15583": "Yemen",
	"15586": "Zambia",
	"15587": "Zimbabwe",
};

const categoryMap: Record<string, string> = {
	"C|1": "Accounting",
	"C|2": "Administration & Office Support",
	"C|3": "Agriculture, Horticulture, Animal & Fishing",
	"C|5": "Banking, Superannuation & Finance",
	"C|6": "Construction",
	"C|7": "Customer Service & CCentre",
	"C|4": "Design & Architecture",
	"C|8": "Editorial, Media & Creative Arts",
	"C|9": "Education, Training & Childcare",
	"C|11": "Engineering",
	"C|12": "Executive Management & Consulting",
	"C|10": "Government, Emergency Services & Defence",
	"C|13": "Healthcare & Medical",
	"C|14": "Hospitality, Tourism & Food Services",
	"C|15": "Human Resources (HR) & Recruitment",
	"C|17": "Information Technology (IT)",
	"C|16": "Insurance",
	"C|18": "Legal",
	"C|19": "Manufacturing, Production & Operations",
	"C|20": "Marketing & Advertising",
	"C|21": "Mining & Energy",
	"C|22": "Property & Real Estate",
	"C|23": "Retail",
	"C|24": "Sales",
	"C|25": "Science, Technology & Environment",
	"C|26": "Social Work & Community Services",
	"C|27": "Sport & Recreation",
	"C|28": "Trades & Services",
	"C|29": "Transport & Logistics",
	"C|30": "Work From Home & Self Employed",
};

const contractTypeMap: Record<string, string> = {
	"13": "Contract",
	"11": "Freelance & Tasks",
	"10": "Internship & Trainee",
	"7": "Permanent",
	"14": "Temporary",
	"9": "Volunteer",
};

const adTypeMap: Record<string, string> = {
	"1": "Standard",
	"7": "Premium",
};

const showSalaryMap: Record<string, string> = {
	"1": "Yes",
	"0": "No",
};

const jobBoardTypeId = 68;

export default function CareerOneView({ jobBoardId, advertId, attributes, advertRecordAttributes, loadingHandler, errorHandler }: Props) {
    const [countryToStateMap, setCountryToStateMap] = useState<Record<string, JobBoardFieldSetupValue[]>>({});
    const [stateToRegionMap, setStateToRegionMap] = useState<Record<string, JobBoardFieldSetupValue[]>>({});
    const [regionToAreaMap, setRegionToAreaMap] = useState<Record<string, JobBoardFieldSetupValue[]>>({});
    const [areaToLocationMap, setAreaToLocationMap] = useState<Record<string, JobBoardFieldSetupValue[]>>({});

    const atts = useMemo<Record<string, string>>(() => {
        if (attributes) return attributes;
        return {};
    }, [attributes]);

    const recordAtts = useMemo<Record<string, string>>(() => {
        if (advertRecordAttributes) return advertRecordAttributes;
        return {};
    }, [advertRecordAttributes]);

    const country = useMemo(() => atts.Country ?? '0', [atts.Country]);
    const state = useMemo(() => atts.State ?? '', [atts.State]);
    const region = useMemo(() => atts.Region ?? '', [atts.Region]);
    const area = useMemo(() => atts.Area ?? '', [atts.Area]);
    const suburb = useMemo(() => atts.Location ?? '', [atts.Location]);
    const category = useMemo(() => atts.Category ?? '0', [atts.Category]);
    const occupation = useMemo(() => atts.Occupation ?? '', [atts.Occupation]);
    const contratType = useMemo(() => atts.ContratType ?? '0', [atts.ContratType]);
    const adType = useMemo(() => atts.AdType ?? '1', [atts.AdType]);
    const showSalary = useMemo(() => atts.ShowSalary ?? '1', [atts.ShowSalary]);

    const workType = useMemo(() => recordAtts.workType ?? '', [recordAtts.workType]);
    const salaryType = useMemo(() => recordAtts.salaryType ?? '', [recordAtts.salaryType]);
    const salaryFrom = useMemo(() => recordAtts.salaryFrom ?? '', [recordAtts.salaryFrom]);
    const salaryTo = useMemo(() => recordAtts.salaryTo ?? '', [recordAtts.salaryTo]);

    useEffect(() => {
        const getStateValues = async () => {
            if (country && country !== '0') {
                const opts = countryToStateMap[country];
                if (!Boolean(opts)) {
                    loadingHandler && loadingHandler(true);
                    const res = await GetJobBoardFieldSetupValues(jobBoardTypeId, advertId, 'STATE', country, errorHandler);
                    if (res) {
                        setCountryToStateMap(prev => {
                            let tmp = {...prev, [country]: res};
                            return tmp;
                        });
                    }
                    loadingHandler && loadingHandler(false);
                }
            }
        };
        getStateValues();
    }, [advertId, country, countryToStateMap, errorHandler, loadingHandler]);

    useEffect(() => {
        const getRegionValues = async () => {
            if (state) {
                const opts = stateToRegionMap[state];
                if (!Boolean(opts)) {
                    loadingHandler && loadingHandler(true);
                    const res = await GetJobBoardFieldSetupValues(jobBoardTypeId, advertId, 'REGION', state, errorHandler);
                    if (res) {
                        setStateToRegionMap(prev => {
                            let tmp = {...prev, [state]: res};
                            return tmp;
                        });
                    }
                    loadingHandler && loadingHandler(false);
                }
            }
        };
        getRegionValues();
    }, [advertId, errorHandler, loadingHandler, state, stateToRegionMap]);

    useEffect(() => {
        const getAreaValues = async () => {
            if (region) {
                const opts = regionToAreaMap[region];
                if (!Boolean(opts)) {
                    loadingHandler && loadingHandler(true);
                    const res = await GetJobBoardFieldSetupValues(jobBoardTypeId, advertId, 'AREA', region, errorHandler);
                    if (res) {
                        setRegionToAreaMap(prev => {
                            let tmp = {...prev, [region]: res};
                            return tmp;
                        });
                    }
                    loadingHandler && loadingHandler(false);
                }
            }
        };
        getAreaValues();
    }, [advertId, errorHandler, loadingHandler, region, regionToAreaMap]);

    useEffect(() => {
        const getLocationValues = async () => {
            if (area) {
                const opts = areaToLocationMap[area];
                if (!Boolean(opts)) {
                    loadingHandler && loadingHandler(true);
                    const res = await GetJobBoardFieldSetupValues(jobBoardTypeId, advertId, 'SUBURB', area, errorHandler);
                    if (res) {
                        setAreaToLocationMap(prev => {
                            let tmp = {...prev, [area]: res};
                            return tmp;
                        });
                    }
                    loadingHandler && loadingHandler(false);
                }
            }
        };
        getLocationValues();
    }, [advertId, area, areaToLocationMap, errorHandler, loadingHandler]);

    const occupationName = useMemo(() => {
        if (category) {
            const opts = CategoryToOccupatonsMap[category];
            if (opts) {
				const o = opts.find(v => v.id === occupation);
				if (o) return o.name;
			}
        }
        return "";
    }, [category, occupation]);

    const stateName = useMemo(() => {
        if (country && country !== '0') {
            const opts = countryToStateMap[country];
            if (opts && opts.length > 0) {
				const s = opts.find(o => o.value === state);
				if (s) return s.text;
			}
        }
        return '';
    }, [country, countryToStateMap, state]);

    const regionName = useMemo(() => {
        if (state) {
            const opts = stateToRegionMap[state];
            if (opts && opts.length > 0) {
				const r = opts.find(o => o.value === region);
				if (r) return r.text;
			}
        }
        return '';
    }, [region, state, stateToRegionMap]);

    const areaName = useMemo(() => {
        if (region) {
            const opts = regionToAreaMap[region];
            if (opts && opts.length > 0) {
				const a = opts.find(o => o.value === area);
				if (a) return a.text;
			}
        }
        return '';
    }, [area, region, regionToAreaMap]);

    const locationName = useMemo(() => {
        if (area) {
            const opts = areaToLocationMap[area];
            if (opts && opts.length > 0) {
				const l = opts.find(o => o.value === suburb);
				if (l) return l.text;
			}
        }
        return '';
    }, [area, areaToLocationMap, suburb]);

    return (
        <Box display="flex" gap={2} pt={2}>
            <Box display="flex" flexDirection="column" gap={2} width="50%">
                <JobBoardViewField label="Country" value={countryMap[country] ?? ''} />
                <JobBoardViewField label="State" value={stateName} />
                <JobBoardViewField label="Region" value={regionName} />
                <JobBoardViewField label="Area" value={areaName} />
                <JobBoardViewField label="Suburb" value={locationName} />
                <JobBoardViewField label="Work Type" value={workType} />
            </Box>
            <Box display="flex" flexDirection="column" gap={2} width="50%">
                <JobBoardViewField label="Category" value={categoryMap[category] ?? ''} />
                <JobBoardViewField label="Occupation" value={occupationName} />
                <JobBoardViewField label="Salary Type" value={salaryType} />
                <Box display="flex" gap={2}>
                    <JobBoardViewField label="Salary From" sx={{ flex: '1 1 0' }} value={salaryFrom} />
                    <JobBoardViewField label="Salary To" sx={{ flex: '1 1 0' }} value={salaryTo} />
                </Box>
                <JobBoardViewField label="Contract Type" value={contractTypeMap[contratType] ?? ''} />
                <JobBoardViewField label="Ad Type" value={adTypeMap[adType] ?? ''} />
                <JobBoardViewField label="Show salary on Ad?" value={showSalaryMap[showSalary] ?? ''} />
            </Box>
        </Box>
    );
}