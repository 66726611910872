import { GridColumnVisibilityModel, GridEditCellProps, GridPreProcessEditCellProps } from "@mui/x-data-grid-premium";
import { RegexIsNumberWith2Decimals } from "./RegExUtils";

export const defaultGridCellStyle: React.CSSProperties = { lineHeight: '41px', overflow: 'hidden', textOverflow: 'ellipsis' };

export const customFieldColsVisibility: GridColumnVisibilityModel = {
    "customField1": false,
    "customField2": false,
    "customField3": false,
    "customField4": false,
    "customField5": false,
    "customField6": false,
    "customField7": false,
    "customField8": false,
    "customField9": false,
    "customField10": false,
    "customField11": false,
    "customField12": false,
    "customField13": false,
    "customField14": false,
    "customField15": false,
    "customField16": false,
    "customField17": false,
    "customField18": false,
    "customField19": false,
    "customField20": false,
    "customField21": false,
    "customField22": false,
    "customField23": false,
    "customField24": false,
    "customField25": false,
    "customField26": false,
    "customField27": false,
    "customField28": false,
    "customField29": false,
    "customField30": false,
    "customField31": false,
    "customField32": false,
    "customField33": false,
    "customField34": false,
    "customField35": false,
    "customField36": false,
    "customField37": false,
    "customField38": false,
    "customField39": false,
    "customField40": false,
    "customField41": false,
    "customField42": false,
    "customField43": false,
    "customField44": false,
    "customField45": false,
    "customField46": false,
    "customField47": false,
    "customField48": false,
    "customField49": false,
    "customField50": false,
    "customField51": false,
    "customField52": false,
    "customField53": false,
    "customField54": false,
    "customField55": false,
    "customField56": false,
    "customField57": false,
    "customField58": false,
    "customField59": false,
    "customField60": false,
};

export const numberUdfEditValidator = (params: GridPreProcessEditCellProps): GridEditCellProps => {
    const hasError = !RegexIsNumberWith2Decimals(params.props.value);
    return { ...params.props, error: hasError };
};

export const mandatoryUdfEditValidator = (params: GridPreProcessEditCellProps): GridEditCellProps => {
    const value = params.props.value;
    let isEmpty = !Boolean(value);
    if (!isEmpty && typeof value === 'string') isEmpty = !Boolean(value.trim())
    return { ...params.props, error: isEmpty };
};