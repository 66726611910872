import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { SxProps } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import moment from "moment";
import React, { useCallback, useState } from "react";
import * as XLSX from "xlsx";
import { GetConsultantCommissionReports } from "services/PlacementsService";
import { ReportDataProcessDateFields } from "util/ReportUtils";

interface Props {
    description: string,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void
}

const filterElementStyle: SxProps = { flex: '1 1 0', mr: 1 };

export default function ConsultantCommissionReport({ description, loadingHandler, errorHandler }: Props) {
    const [year, setYear] = useState(moment().year());
    const [month, setMonth] = useState(moment().month() + 1);

    const getDataCallback = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        const res = await GetConsultantCommissionReports(year, month, errorHandler);
        if (res) {
            ReportDataProcessDateFields(res as any[]);

            const m = moment();
            const filename = `ConsultantCommissionExtract${m.format('YYYYMMDDhhmmss')}.xlsx`;
            const ws = XLSX.utils.json_to_sheet(res);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws);
            XLSX.writeFile(wb, filename);
        }
        loadingHandler && loadingHandler(false);
    }, [year, month, errorHandler, loadingHandler]);

    return (
        <>
            <Box display="flex">

                <TextField select label="Year" value={year.toString()} onChange={({ target }) => setYear(+target.value)} sx={filterElementStyle}>
                    <MenuItem value={moment().year()}>{moment().year()}</MenuItem>
                    <MenuItem value={moment().year() - 1}>{moment().year() - 1}</MenuItem>
                    <MenuItem value={moment().year() - 2}>{moment().year() - 2}</MenuItem>
                    <MenuItem value={moment().year() - 3}>{moment().year() - 3}</MenuItem>
                    <MenuItem value={moment().year() - 4}>{moment().year() - 4}</MenuItem>
                    <MenuItem value={moment().year() - 5}>{moment().year() - 5}</MenuItem>
                    <MenuItem value={moment().year() - 6}>{moment().year() - 6}</MenuItem>
                    <MenuItem value={moment().year() - 7}>{moment().year() - 7}</MenuItem>
                    <MenuItem value={moment().year() - 8}>{moment().year() - 8}</MenuItem>
                    <MenuItem value={moment().year() - 9}>{moment().year() - 9}</MenuItem>
                </TextField>
                <TextField select label="Month" value={month.toString()} onChange={({ target }) => setMonth(+target.value)} sx={filterElementStyle}>
                    <MenuItem value="1">January</MenuItem>
                    <MenuItem value="2">Febrary</MenuItem>
                    <MenuItem value="3">March</MenuItem>
                    <MenuItem value="4">April</MenuItem>
                    <MenuItem value="5">May</MenuItem>
                    <MenuItem value="6">June</MenuItem>
                    <MenuItem value="7">July</MenuItem>
                    <MenuItem value="8">August</MenuItem>
                    <MenuItem value="9">September</MenuItem>
                    <MenuItem value="10">October</MenuItem>
                    <MenuItem value="11">November</MenuItem>
                    <MenuItem value="12">December</MenuItem>
                </TextField>
                <Button variant="contained" color="success" onClick={getDataCallback}>Run Report</Button>
            </Box>
            <Typography component="div" variant="h6" mt={2}>Report Description</Typography>
            <Typography component="div" variant="body2">{description}</Typography>
        </>
    );
}