import Alert from "components/Alert";
import Snackbar from "@mui/material/Snackbar";
import {
    GridColDef,
    GridRenderCellParams,
    GridRowParams,
    GridRowSelectionModel,
    GridValueGetter,
    useGridApiRef
} from "@mui/x-data-grid-premium";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { OnboardingRequest } from "common/models/Onboarding";
import ConfirmationDialog from "components/Dialogs/Generic/ConfirmationDialog";
import GridWithStateWrapper from "components/GridWidthStateWrapper";
import OnlyTitleSummaryBar from "components/SummaryBars/OnlyTitleSummaryBar";
import PageContentLayout from "layouts/PageContentLayout";
import PageLayout from "layouts/PageLayout";
import { GetCurrentCustomerESignRequests_Incomplete, GetCurrentCustomerESignRequests_All } from "services/OnboardingService";
import {ESignRequest} from "../../common/models/ESigning";

interface Props {
    showOnlyIncomplete?: boolean
}

export default function OnboardingRequestsPage({ showOnlyIncomplete }: Props) {
    const [summaryBar, setSummaryBar] = useState<JSX.Element>(<></>);
    const [isLoading, setIsLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [rows, setRows] = useState<OnboardingRequest[]>([]);
    const [revokeRow, setRevokeRow] = useState<OnboardingRequest | null>(null);
    const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
    const [showBulkReminderDialog, setShowBulkReminderDialog] = useState(false);
    const [showBulkRevokeDialog, setShowBulkRevokeDialog] = useState(false);
    const apiRef = useGridApiRef();
    
    useEffect(() => {
        const summaryBar = <OnlyTitleSummaryBar title={`Account > Onboarding Requests > ${ showOnlyIncomplete ? 'Incomplete' : 'All' }`} />;
        setSummaryBar(summaryBar);
    }, [showOnlyIncomplete]);

    const getRows = useCallback(async () => {
        setIsLoading(true);
        let res: OnboardingRequest[] | null = [];
    
        if (showOnlyIncomplete) {
                res = await GetCurrentCustomerESignRequests_Incomplete(setErrorMessage);
        }
        else {
                res = await GetCurrentCustomerESignRequests_All(setErrorMessage);
        }
    
        if (res) setRows(res);
        setIsLoading(false);
    }, [showOnlyIncomplete]);

    useEffect(() => {
        getRows()
    }, [getRows]);

    const sendBulkReminderHandler = useCallback(async () => {
        setIsLoading(true);
        if (selectionModel.length > 0) {
            // const res = await ESignRequest_BulkSendReminder(selectionModel as number[], setErrorMessage);
            // if (res) {
            //     setSuccessMessage('Reminder(s) sent');
            //     setShowBulkReminderDialog(false);
            //     setSelectionModel([]);
            // }
        }
        setIsLoading(false);
    }, [selectionModel]);

    const revokeHandler = useCallback(async () => {
        setIsLoading(true);
        if (revokeRow) {
            // const res = await ESignRequest_Revoke(revokeRow.id, setErrorMessage);
            // if (res) {
            //     setSuccessMessage('Request revoked');
            //     setRevokeRow(null);
            //     getRows();
            // }
        }
        setIsLoading(false);
    }, [revokeRow]);

    const bulkRevokeHandler = useCallback(async () => {
        setIsLoading(true);
        if (selectionModel.length > 0) {
            // const res = await ESignRequest_BulkRevoke(selectionModel as number[], setErrorMessage);
            // if (res) {
            //     setSuccessMessage('Request(s) revoked');
            //     setShowBulkRevokeDialog(false);
            //     setSelectionModel([]);
            //     getRows();
            // }
        }
        setIsLoading(false);
    }, [selectionModel]);

    const columns = useMemo<GridColDef[]>(() => {
        const dateValueGetter: GridValueGetter<ESignRequest, any, undefined, string> = (value) => {
            if (value) {
                const d = moment(value);
                if (d.isValid() && d.get("year") > 1) {
                    return d.toDate();
                }
            }
            return null;
        };

        const dateRenderer = (params: GridRenderCellParams) => {
            if (params.value) {
                return moment(params.value).format('DD MMM YYYY');
            }
        };

        const candidateNameRenderer = (params: GridRenderCellParams) => {
            return (
                <Link
                    style={{ color: 'inherit', textDecoration: 'underline' }}
                    to={`/candidates/${params.row.candidateID}`}
                    target="_blank"
                >{params.value}</Link>
            );
        };

        const placementRenderer = (params: GridRenderCellParams) => {
            return (
                <Link
                    style={{ color: 'inherit', textDecoration: 'underline' }}
                    to={`/placements/${params.row.placementID}`}
                    target="_blank"
                >{params.value}</Link>
            );
        };
        
        return [
            { field: 'id', headerName: 'Request ID', width: 120 },
            { field: 'formName', headerName: 'Form Name', width: 200 },
            { field: 'candidateName', headerName: 'Candidate Name', width: 200, renderCell: candidateNameRenderer },
            { field: 'placementID', headerName: 'Placement', width: 200, renderCell: placementRenderer },
            { field: 'createdDate', headerName: 'Request Date', width: 120, type: 'date', valueGetter: dateValueGetter, renderCell: dateRenderer },
            { field: 'statusName', headerName: 'Status', width: 200 },
            //{ field: 'actions', headerName: 'Actions', width: 250, disableColumnMenu: true, sortable: false, renderCell: actionsRenderer },
        ];
    }, []);

    const isRowSelectableCallback = useCallback((p: GridRowParams) => {
        return p.row.statusID === 1 || p.row.statusID === 2;
    }, []);

    return (
        <PageLayout SummaryBar={summaryBar}>
            <ConfirmationDialog
                open={revokeRow !== null}
                title="Confirm action"
                message="Are you sure you want to revoke the request?"
                onClose={ () => setRevokeRow(null) }
                onContinue={ revokeHandler }
                fullWidth
            />
            <ConfirmationDialog
                open={showBulkReminderDialog}
                title="Confirm action"
                message={`Are you sure you want to send reminders to the ${selectionModel.length} selected request(s)?`}
                onClose={ () => setShowBulkReminderDialog(false) }
                onContinue={ sendBulkReminderHandler }
                fullWidth
            />
            <ConfirmationDialog
                open={showBulkRevokeDialog}
                title="Confirm action"
                message={`Are you sure you want to revoke the ${selectionModel.length} selected request(s)?`}
                onClose={ () => setShowBulkRevokeDialog(false) }
                onContinue={ bulkRevokeHandler }
                fullWidth
            />
            <Snackbar
                open={successMessage.length > 0}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={3000}
                onClose={() => setSuccessMessage('')}
            >
                <Alert onClose={() => setSuccessMessage('')}>{ successMessage }</Alert>
            </Snackbar>
            <Snackbar
                open={errorMessage !== ''}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <PageContentLayout title={showOnlyIncomplete ? 'Onboarding Requests - Incomplete' : 'Onboarding Requests'} showLoading={isLoading}>
                {/*<Box>*/}
                {/*    <Box pb="10px" sx={{ float: 'right' }}>{ gridActions }</Box>*/}
                {/*</Box>*/}
                <GridWithStateWrapper
                    gridName="account/onboarding-requests"
                    apiRef={apiRef}
                    rows={rows}
                    columns={columns}
                    checkboxSelection
                    disableRowSelectionOnClick
                    rowSelectionModel={selectionModel}
                    onRowSelectionModelChange={ sm => setSelectionModel(sm) }
                    isRowSelectable={ isRowSelectableCallback}
                    pagination
                    density="compact"
                    pageSizeOptions={[100,250,500,1000]}
                />
            </PageContentLayout>
        </PageLayout>
    );
}