import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import React, { useCallback, useEffect, useState } from "react";
import { RemoveRedFlag } from "services/CandidatesService";
import DialogContentText from "@mui/material/DialogContentText";
import RWTextFieldComponent from "components/RWTextFieldComponent";

interface Props {
    open: boolean,
    candidateId: number,
    candidateName: string,
    closeHandler: () => void,
    loadingHandler?: (isLoading: boolean) => void,
    successHandler?: (message: string) => void,
    errorHandler?: (message: string) => void
}

export default function RemoveRedFlagDialog({ open, candidateId, candidateName,  closeHandler, loadingHandler, successHandler, errorHandler }: Props) {
    const [comments, setComments] = useState('');

    useEffect(() => {
        if (!open) {
            setComments('');
        }
    }, [open]);

    const submitCallback = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        const res = await RemoveRedFlag(candidateId, comments, errorHandler);
        if (res) {
            successHandler && successHandler(candidateName + ' has had their Red Flag removed');
            closeHandler();
        }
        loadingHandler && loadingHandler(false);
        
    }, [candidateId, comments, candidateName, loadingHandler, errorHandler, successHandler, closeHandler]);

    return (
        <Dialog open={open} maxWidth={"lg"} >
            <DialogTitle>Remove Red Flag</DialogTitle>
            <DialogContent>
                <Stack spacing={2} mt="10px">
                    <DialogContentText>Removing the Red Flag will allow them to be included in searches + apply for roles again.</DialogContentText>
                    <RWTextFieldComponent
                        multiline={true}
                        minRows={6}
                        label="Comments"
                        name="comments"
                        value={comments}
                        onChange={ (e) => setComments(e.target.value) }
                        sxOptions={{ flex: '1 1 0', pr: '5px' }}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={ closeHandler }>Cancel</Button>
                <Button variant="contained" color="success" onClick={ submitCallback } >Submit</Button>
            </DialogActions>
        </Dialog>
    );
}