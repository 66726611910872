import { Components, PaletteColor } from '@mui/material';
import { Theme, createTheme } from "@mui/material/styles";
import type {} from '@mui/x-date-pickers-pro/themeAugmentation';
import { purple } from "@mui/material/colors";

declare module '@mui/material/styles' {
    interface Palette {
        customAccentColor?: PaletteColor
    }
    interface PaletteOptions {
        customAccentColor?: PaletteColor
    }

    interface Theme {
        customVars: {
            bgcLoginForm: string;
        };
    }
    interface ThemeOptions {
        customVars?: {
            bgcLoginForm?: string;
        };
    }
}

declare module "@mui/material/FormControl" {
    interface FormControlPropsColorOverrides {
        customAccentColor: true;
    }
}

declare module "@mui/material/TextField" {
    interface TextFieldPropsColorOverrides {
        customAccentColor: true;
    }
}

declare module "@mui/material/Alert" {
    interface AlertPropsColorOverrides {
        primary: true
    }
}

const components: Components = {
    MuiTextField: {
        defaultProps: {
            size: 'small',
            color: 'customAccentColor',
            inputProps: { "data-lpignore": "true" },
        }
    },
    MuiInputBase: {
        defaultProps: {
            inputProps: { "data-lpignore": "true" }
        }
    },
    MuiSnackbar: {
        defaultProps: {
            anchorOrigin: { vertical: 'top', horizontal: 'center' }
        }
    },
    MuiTabs: {
        defaultProps: {
            variant: 'scrollable',
            scrollButtons: false,
            TabIndicatorProps: { hidden: true}
        }
    },
    MuiTab: {
        styleOverrides: {
            // Name of the slot
            root: {
                // Some CSS
                fontSize: '11px',
                minWidth: '50px',
                "&:hover": {
                    backgroundColor: purple[100],
                    color: purple[700]
                },
                "&.Mui-selected": {
                    backgroundColor: purple[100],
                    color: purple[700],
                    borderRadius: 2
                }
            }
        }
    },
    MuiDateTimePicker: {
        defaultProps: {
            format: 'DD MMM YYYY h:mm A'
        }
    },
    MuiDatePicker: {
        defaultProps: {
            format: 'DD MMM YYYY'
        }
    },
    MuiDateTimeRangePicker: {
        defaultProps: {
            format: 'DD MMM YYYY h:mm A',
        }
    }
}

const { palette } = createTheme();

const LightTheme: Theme = createTheme({
    components,
    typography: {
        button: {
            textTransform: 'none'
        }
    },
    palette: {
        mode: 'light',
        success: {
            main: '#00B065',
            contrastText: '#fff'
        },
        primary: {
            dark: '#7136df',
            main: '#5E21D0',
            light: '#7136df',
        },
        secondary: {
            dark: '#000872',
            main: '#000872',
            light: '#000872',
        },
        customAccentColor: palette.augmentColor({
            color: {
                main: '#000'
            }
        }),
        background: {
            default: '#eee'
        }
    },
    customVars: {
        bgcLoginForm: '#f0f0f0'
    }
});

const DarkTheme: Theme = createTheme({
    components,
    typography: {
        button: {
            textTransform: 'none'
        }
    },
    palette: {
        mode: 'dark',
        success: {
            main: '#00B065',
            contrastText: '#fff'
        },
        primary: {
            dark: '#0A67B1',
            main: '#1080d8',
            light: '#0A67B1',
        },
        secondary: {
            dark: '#000872',
            main: '#000872',
            light: '#000872',
        },
        customAccentColor: palette.augmentColor({
            color: {
                main: '#fff'
            }
        }),
        background: {
            default: '#3E4C59',
            paper: '#111a23', 
        },
    },
    customVars: {
        bgcLoginForm: 'rgba(255,255,255,.15)'
    }
});

export { LightTheme, DarkTheme };