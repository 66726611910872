import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { MessageAttachment } from "common/models/EmailIntegration";
import { NameIdObj } from "common/models/GenericTypes";
import { Permission } from "common/models/Permissions";
import CandidatePicker from "components/Pickers/CandidatePicker";
import ClientPicker from "components/Pickers/ClientPicker";
import ContactPicker from "components/Pickers/ContactPicker";
import JobPicker from "components/Pickers/JobPicker";
import MultipleNumericValuesPicker from "components/Pickers/MultipleNumericValuesPicker";
import OpportunityPicker from "components/Pickers/OpportunityPicker";
import PlacementPicker from "components/Pickers/PlacementPicker";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { CopyEmailAttachmentsToRecord } from "services/MessagesService";
import { userHasSinglePermission } from "util/PermissionsUtils";

interface Props {
    open: boolean,
    messageId: number,
    attachments: MessageAttachment[],
    closeHandler: () => void,
    loadingHandler?: (isLoading: boolean) => void,
    successHandler?: (message: string) => void,
    errorHandler?: (message: string) => void,
}

export default function CopyAttachmentsToRecordDialog({ open, messageId, attachments: attachmentsProp, closeHandler, loadingHandler, successHandler, errorHandler }: Props) {
    const [entityTypeId, setEntityTypeId] = useState(0);
    const [playerId, setPlayerId] = useState(0);
    const [attachmentIds, setAttachmentIds] = useState<number[]>([]);

    const userHasClientAccess = useMemo(() => userHasSinglePermission(Permission.Clients), []);
    const userHasContactAccess = useMemo(() => userHasSinglePermission(Permission.Contacts), []);
    const userHasCandidateAccess = useMemo(() => userHasSinglePermission(Permission.Candidates), []);
    const userHasJobAccess = useMemo(() => userHasSinglePermission(Permission.Jobs), []);
    const userHasPlacementAccess = useMemo(() => userHasSinglePermission(Permission.Placements), []);
    const userHasOpportunityAccess = useMemo(() => userHasSinglePermission(Permission.Opportunities), []);

    useEffect(() => {
        if (!open) {
            setEntityTypeId(0);
            setPlayerId(0);
            setAttachmentIds([]);
        }
    }, [open]);

    const attachments = useMemo<NameIdObj[]>(() => {
        return attachmentsProp.map(a => ({ id: a.id, name: a.documentName }));
    }, [attachmentsProp]);

    const submitHandler = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        const res = await CopyEmailAttachmentsToRecord(messageId, entityTypeId, playerId, attachmentIds, errorHandler);
        if (res) {
            successHandler && successHandler('Attachments Copied');
            closeHandler();
        }
        loadingHandler && loadingHandler(false);
    }, [attachmentIds, closeHandler, entityTypeId, errorHandler, loadingHandler, messageId, playerId, successHandler]);

    const entityChangeHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setPlayerId(0);
        const { value } = e.target;
        setEntityTypeId(+value);
    }, []);

    const attachmentsChangeHandler = useCallback((values: NameIdObj[]) => {
        setAttachmentIds(values.map(v => v.id));
    }, []);

    return (
        <Dialog open={open} fullWidth>
            <DialogTitle>Copy Attachments to Record</DialogTitle>
            <DialogContent>
                <Stack spacing={2} pt={2}>
                    <MultipleNumericValuesPicker label="Attachments" value={attachmentIds} options={attachments} onSelect={attachmentsChangeHandler} />
                    <TextField select label="Record Type" value={entityTypeId.toString()} onChange={entityChangeHandler}>
                        <MenuItem value="0">None</MenuItem>
                        { userHasClientAccess && <MenuItem value="1">Client</MenuItem> }
                        { userHasContactAccess && <MenuItem value="2">Contact</MenuItem> }
                        { userHasCandidateAccess && <MenuItem value="3">Candidate</MenuItem> }
                        { userHasJobAccess && <MenuItem value="4">Job</MenuItem> }
                        { userHasPlacementAccess && <MenuItem value="5">Placement</MenuItem> }
                        { userHasOpportunityAccess && <MenuItem value="8">Opportunity</MenuItem> }
                    </TextField>
                    { entityTypeId === 1 && <ClientPicker value={playerId} onSelectCallback={c => setPlayerId(c ? c.id : 0)} /> }
                    { entityTypeId === 2 && <ContactPicker value={playerId} onSelectCallback={c => setPlayerId(c ? c.id : 0)} /> }
                    { entityTypeId === 3 && <CandidatePicker value={playerId} onSelectCallback={c => setPlayerId(c ? c.id : 0)} /> }
                    { entityTypeId === 4 && <JobPicker value={playerId} onSelectCallback={j => setPlayerId(j ? j.id : 0)} /> }
                    { entityTypeId === 5 && <PlacementPicker value={playerId} onSelectCallback={p => setPlayerId(p ? p.id : 0)} /> }
                    { entityTypeId === 8 && <OpportunityPicker value={playerId} onSelectCallback={o => setPlayerId(o ? o.id : 0)} /> }
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={ closeHandler }>Cancel</Button>
                <Button variant="contained" color="success" onClick={ submitHandler } disabled={!Boolean(entityTypeId) || !Boolean(playerId) || attachmentIds.length === 0}>Copy</Button>
            </DialogActions>
        </Dialog>
    );
}