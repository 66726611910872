import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import React, { useEffect, useMemo, useState } from "react";
import { ScreeningQuestionsTemplate } from "common/models/Templates/ScreeningQuestionsTemplate";
import { GetAvailableScreeningQuestionTemplates } from "services/WorkflowService";

interface Props {
    onSelectCallback: (template: ScreeningQuestionsTemplate | null) => void,
    templateId: number | null,
    userId: number,
    label?: string
}

const noneOption: ScreeningQuestionsTemplate = { id: 0, json: '', name: 'None', owner: '', ownerID: 0, status: '', statusID: 0, documentTemplateId: 0 };

export default function ScreeningTemplatePicker({ templateId, userId, label = "Screening Template", onSelectCallback }: Props) {
    const [options, setOptions] = useState<ScreeningQuestionsTemplate[]>([]);

    useEffect(() => {
        const getData = async () => {
            const data = await GetAvailableScreeningQuestionTemplates(userId);
            if (data) setOptions([noneOption, ...data]);
        };
        getData();
    }, [userId]);

    const template = useMemo(() => {
        if (templateId === null) return null;
        const template = options.find(t => t.id === templateId);
        if (template) return template;
        return null;
    }, [options, templateId]);

    return (
        <Autocomplete
            size="small"
            value={template}
            options={options}
            getOptionLabel={o => o.name}
            isOptionEqualToValue={ (o,v) => o.id === v.id }
            onChange={ (e,v) => onSelectCallback(v) }
            renderInput={ params => <TextField {...params} label={label} inputProps={{ ...params.inputProps, "data-lpignore": "true" }} /> }
        />
    );
}