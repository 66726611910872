import React from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import CheckIcon from '@mui/icons-material/Check';

import { UserIntegration } from "common/models/Configuration/UserIntegrations";

interface Props {
    title: string,
    items: UserIntegration[],
    pt?: string,
    pb?: string
}

export default function UserIntegrationsGrid({ title, items, pt, pb }: Props) {
    return (
        <div style={{ paddingTop: pt, paddingBottom: pb }}>
            <Typography component="div">{title} ({items.length})</Typography>
            <Box display="flex" flexWrap="wrap" justifyContent="center">
                {items.map(item => (
                    <Card key={item.integrationID} elevation={6} sx={{ display: 'flex', width: '350px', height: '150px', m: '10px' }}>
                        <CardActionArea>
                            <Link
                                to={`/account/integrations/${item.integrationID}`}
                                style={{ color: 'inherit', textDecoration: 'none', padding: '10px', display: 'flex', width: '100%', height: '100%' }}
                            >
                                <Box
                                    component="span"
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{
                                        bgcolor: (t) => {
                                            const notActiveColor = t.palette.mode === 'dark' ? t.palette.text.disabled : '#dae1e7';
                                            return item.statusID === 1 ? t.palette.success.main : notActiveColor;
                                        },
                                        width: '30px',
                                        height: '30px',
                                        position: 'absolute',
                                        right: '10px',
                                        borderRadius: '50%'
                                    }}
                                >
                                    {item.statusID === 1 &&  <CheckIcon htmlColor="white" />}
                                </Box>
                                <CardMedia
                                    component="img"
                                    sx={{ maxHeight: '50px', width: 'unset', borderRadius: '5px', mr: '20px' }}
                                    image={`https://cdn.recruitwizard.com/images/integrations/${item.logoUrl}`}
                                />
                                <div>
                                    <Typography gutterBottom variant="subtitle1" component="div">
                                        {item.name}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" textOverflow="ellipsis">
                                        {item.summary}
                                    </Typography>
                                </div>
                            </Link>
                        </CardActionArea>
                    </Card>
                ))}
            </Box>
        </div>
    );
}