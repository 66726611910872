import { SubmissionsConfigOptions } from "common/models/Configuration/Submissions";
import { ChangeTracker } from "common/models/hooks/ChangeTracker";

export const DefaultSubmissionsSettings: SubmissionsConfigOptions = {
    CVDeadlineContract: '5',
    CVDeadlineFixedContract: '14',
    CVDeadlinePerm: '14',
    CandidateSubmissionConfirmation: '',
    CandidateSubmissionConfirmationType: '0',
    DefaultSubmissionComments: '',
    ManualSubmissionSendEmailMessageContents: 'False',
    SubmissionDeadlinesAutoPopulate: 'False',
    SubmissionExpirationDays: '30',
}

export const SubmissionsSettingsChangeTracker: ChangeTracker<SubmissionsConfigOptions> = {
    CVDeadlineContract: false,
    CVDeadlineFixedContract: false,
    CVDeadlinePerm: false,
    CandidateSubmissionConfirmation: false,
    CandidateSubmissionConfirmationType: false,
    DefaultSubmissionComments: false,
    ManualSubmissionSendEmailMessageContents: false,
    SubmissionDeadlinesAutoPopulate: false,
    SubmissionExpirationDays: false,
}