import Alert from "components/Alert";
import Snackbar from "@mui/material/Snackbar";
import React, { useEffect, useState } from "react";
import OnlyTitleSummaryBar from "components/SummaryBars/OnlyTitleSummaryBar";
import PageContentLayout from "layouts/PageContentLayout";
import QuickViewLayoutAdmin from "components/QuickViewLayouts/Placement/QuickViewLayoutAdmin";

interface Props {
    placementType: 'Permanent' | 'Contract' | 'FixedTermContract' | 'MaxTermContract' | 'MarginOnly',
    setSummaryBar?: (summaryBar: JSX.Element) => void
}

export default function PlacementsQuickViewLayoutPage({ placementType, setSummaryBar }: Props) {
    const [isLoading, setIsloading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [placementTypeSwitch, setPlacementTypeSwitch] = useState(false);

    useEffect(() => {
        if (placementType) {}
        setPlacementTypeSwitch(prev => !prev);
    }, [placementType]);

    useEffect(() => {
        if (setSummaryBar) {
            let jt: string = placementType;
            if (placementType === 'FixedTermContract') jt = 'Fixed Term';
            else if (placementType === 'MaxTermContract') jt = 'Maximum Term';
            else if (placementType === 'MarginOnly') jt = 'Margin Only';
            const summaryBar = <OnlyTitleSummaryBar title={`Configuration > Screen Layouts > ${jt} Placements - Quick View`} browserTabTitle="Screen Layouts > Configuration" />;
            setSummaryBar(summaryBar);
        }
    }, [setSummaryBar, placementType]);
    
    return (
        <>
            <Snackbar open={errorMessage !== ''} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <PageContentLayout showLoading={isLoading}>
                { placementTypeSwitch && <QuickViewLayoutAdmin placementType={placementType} errorHandler={setErrorMessage} loadingHandler={setIsloading} /> }
                { !placementTypeSwitch && <QuickViewLayoutAdmin placementType={placementType} errorHandler={setErrorMessage} loadingHandler={setIsloading} /> }
            </PageContentLayout>
        </>
    );
}