import { SettingObject } from "../common/models/GenericTypes";

export function SettingsArrayToObj<T>(settings: SettingObject[], obj: T, ignoreValue: string = "") {
    let settingsObj = {...obj};
    for(let i = 0; i < settings.length; i++) {
        const setting = settings[i];
        if(setting.value === ignoreValue) continue;
        (settingsObj as any)[setting.type] = setting.value;
    }
    return settingsObj;
}

export function ObjToSettingsArray(obj: any): SettingObject[] {
    let settings: SettingObject[] = [];
    if(obj) {
        for(const key in obj) {
            const val = obj[key];
            settings.push({ type: key, value: val });
        }
    }
    return settings;
}

export function ObjToSettingsArray_Ignore<T>(obj: T, ignore: Array<keyof NonNullable<T>>): SettingObject[] {
    let settings: SettingObject[] = [];
    if(obj) {
        for(const key in obj) {
            if(ignore.includes(key)) continue;
            const val = (obj as any)[key];
            settings.push({ type: key, value: val });
        }
    }
    return settings;
}