import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";

import PageLayout from "layouts/PageLayout";
import PageContentLayout from "layouts/PageContentLayout";
import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import { Contact } from "common/models/Contacts";
import { ConvertContactToCandidate, DeleteContactById, GetContactById, GrantClientPortalAccess, RemoveContactFromClient, RevokeClientPortalAccess, ToggleContactOptOut } from "services/ContactsService";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { GetSettingBySettingName, UpdateSettingBySettingName } from "services/UsersService";

import Avatar from '@mui/material/Avatar';
import TwitterIcon from '../../assets/icons/twitter-icon.svg';
import LinkedInIcon from '../../assets/icons/linkedin-icon.svg';
import GoogleIcon from '../../assets/icons/google-icon.svg';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import { AllowContactsAddEdit, AllowContactsDelete } from "common/data/Permissions/ContactsAccess";
import ConfirmationDialog from "components/Dialogs/Generic/ConfirmationDialog";
import Snackbar from "@mui/material/Snackbar";
import Alert from "components/Alert";
import ActionMenu from "components/Menus/ActionMenu";
import { AllowJobsAddEdit } from "common/data/Permissions/JobsAccess";
import moment from "moment";
import { GetActivitiesSettings } from "services/ConfigurationService";
import { ActivitySettings } from "common/models/Configuration/Activities";
import { MenuOptionDefinition } from "common/models/MenuDefinition";
import EditActivityDialog from "components/Dialogs/Activities/EditActivityDialog";
import { DefaultActivity } from "util/Definitions/Activities";
import { AllowOpportunitiesAddEdit } from "common/data/Permissions/OpportunitiesAccess";
import PreviewLoaderComponent from "components/Previews/PreviewLoader";
import TagsManagementDialog from "components/Dialogs/TagsManagementDialog";
import ActivitiesGridComponent from "components/Grids/ActivitiesGrid";
import { GetPhoneLinkWithStandardized } from "util/CommonUtils";
import PlacementsGridComponent from "components/Grids/PlacementsGrid";
import ActivityMessagesGridComponent from "components/Grids/ActivityMessagesGrid";
import OpportunitiesGridComponent from "components/Grids/OpportunitiesGrid";
import JobsGridComponent from "components/Grids/JobsGrid";
import Switch from "@mui/material/Switch";
import JobsKanbanComponent from "components/Kanbans/JobsKanban";
import DocumentsGridComponent from "components/Grids/DocumentsGrid";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import SubmissionsGridComponent from "components/Grids/SubmissionsGrid";
import SendReferralDialog from "components/Dialogs/SendReferralDialog";
import { Permission } from "common/models/Permissions";
import { companyHasSinglePermission, userHasSinglePermission } from "util/PermissionsUtils";
import { AddRecentRecord } from "util/LocalStorageUtils";
import ViewRecordScreenLayout from "components/ScreenLayouts/Contacts/ViewRecordScreenLayout";
import SendEmailDialog from "components/Dialogs/Messages/SendEmailDialog";
import { EmailRecipient } from "common/models/EmailRecipient";
import SendSmsDialog from "components/Dialogs/Messages/SendSmsDialog";
import GenerateDocumentDialog from "components/Dialogs/Documents/GenerateDocumentDialog";
import usePartnerActions from "hooks/UsePartnerActions";
import ShowIframeDialog from "components/Dialogs/Generic/ShowIFrameDialog";
import { PartnerActionType } from "common/models/PartnerActions";
import useRecordHotkeys from "hooks/UseRecordHotkeys";
import { Activity } from "common/models/Activities";
import CallOutNotification, {CallOutNotificationType} from "../../components/CallOutNotification";
import { GetActiveMeetingTypes } from "services/MeetingsService";
import { MeetingType } from "common/models/Configuration/MeetingType";
import MeetingsGridComponent from "../../components/Grids/MeetingsGrid";
import InterviewsGridComponent from "../../components/Grids/InterviewsGrid";

const summaryLinkStyle: React.CSSProperties = { color: 'inherit', textDecoration: 'none' };
// const fullHeightStyle: React.CSSProperties = { height: '100%' };
const defaultJobViewSettingName = 'DefaultJobsViewContact';

const formatDateString = (d: string, f: string = 'DD MMM YYYY h:mm A') => {
    if (d && d !== '0001-01-01T00:00:00') {
        const m = moment(d);
        if (m.isValid()) return m.format(f);
    }
    return 'Never';
}

const renderSummary = (c: Contact) => {
    const tooltipContent = (
        <table>
            <tbody>
                <tr>
                    <Typography variant="caption" fontWeight={600} component="td">ID</Typography>
                    <Typography variant="caption" pl="10px" component="td">{c.id}</Typography>
                </tr>
                <tr>
                    <Typography variant="caption" fontWeight={600} component="td" sx={{ verticalAlign: 'top' }}>Name</Typography>
                    <Typography variant="caption" pl="10px" component="td">{c.fullName}</Typography>
                </tr>
                <tr>
                    <Typography variant="caption" fontWeight={600} component="td">Created Date</Typography>
                    <Typography variant="caption" pl="10px" component="td">{formatDateString(c.createdDate)}</Typography>
                </tr>
                <tr>
                    <Typography variant="caption" fontWeight={600} component="td">Created By</Typography>
                    <Typography variant="caption" pl="10px" component="td">{c.createdByName}</Typography>
                </tr>
                <tr>
                    <Typography variant="caption" fontWeight={600} component="td">Updated Date</Typography>
                    <Typography variant="caption" pl="10px" component="td">{formatDateString(c.updatedDate)}</Typography>
                </tr>
                <tr>
                    <Typography variant="caption" fontWeight={600} component="td">Updated By</Typography>
                    <Typography variant="caption" pl="10px" component="td">{c.updatedByName}</Typography>
                </tr>
            </tbody>
        </table>
    );
    const contactName = (
        <Tooltip arrow title={tooltipContent}>
            <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{c.fullName}</span>
        </Tooltip>
    );
    var clientName = <span></span>;
    if (c.clientName) {
        const locationUrl = '/clients/' + c.clientID;
        var jobTitle = "";
        if (c.jobTitle)
            jobTitle = c.jobTitle + ' at ';
        clientName = <span style={{ padding: '0 5px' }}>{`(`}{`${jobTitle}`}<a href={locationUrl} style={{ color: 'inherit', textDecoration: 'underline' }} key="clientNameAction" target="_blank" rel="noopener noreferrer">{`${c.clientName})`}</a></span>;
    }

    let phone = '';
    let phoneStd = '';

    if (c.mobile) {
        phone = c.mobile;
        phoneStd = c.mobile_Standardised;
    }
    else if (c.phone) {
        phone = c.phone;
        phoneStd = c.phone_Standardised;
    }
   
    if (phone) {
        const phoneLink = GetPhoneLinkWithStandardized(phone, phoneStd, true);
        return (<>{contactName}{clientName}<span style={{ paddingRight: '5px' }}>-</span>{phoneLink}</>);
    }
    return (<>{contactName}{clientName}</>);
};

const googleMapsLink = (c: Contact) => {
    const baseUrl = 'https://www.google.com/maps/search/?api=1';
    let address = '';
    if (c.address1) address = c.address1;
    if (c.address2) address += ' ' + c.address2;
    if (c.address3) address += ' ' + c.address3;
    if (c.suburb) address += ' ' + c.suburb;
    if (c.state) address += ' ' + c.state;
    if (c.postcode) address += ' ' + c.postcode;
    if (c.countryName) address += ' ' + c.countryName;

    if (address !== '') {
        return `${baseUrl}&query=${encodeURIComponent(address)}`;
    }
    return null;
}

const linkedInLink = (c: Contact) => {
    if (c.linkedinURL) {
        const l = c.linkedinURL;
        if (!l.includes('http://') && !l.includes('https://')) return `https://${l}`;
        return l;
    }
    return `https://www.linkedin.com/search/results/people/?keywords=${encodeURIComponent(c.fullName)}`;
}

const twitterLink = (c: Contact) => {
    if (c.twitterURL) {
        const l = c.twitterURL;
        if (!l.includes('http://') && !l.includes('https://')) return `https://${l}`;
        return l;
    }
    return `https://twitter.com/search?f=users&vertical=default&q=${encodeURIComponent(c.fullName)}`;
}

const entityTypeId = 2;

export default function ContactRecord() {
    const [contact, setContact] = useState<Contact>();
    const [activitySettings, setActivitySettings] = useState<ActivitySettings[]>([]);
    const [selectedActivity, setSelectedActivity] = useState<ActivitySettings | null>(null);
    const [noteActivitySetting, setNoteActivitySetting] = useState<ActivitySettings | null>(null);
    const [meetingTypes, setMeetingTypes] = useState<MeetingType[]>([]);
    const params = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [isFetchingDefaultTab, setIsFetchingDefaultTab] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [isRecordDeleted, setIsRecordDeleted] = useState(false);
    const [isLoadingTab, setIsLoadingTab] = useState(false);
    const [showTagsPrevew, setShowTagsPreview] = useState(false);
    const [showTagsPrevewNoDelay, setShowTagsPreviewNoDelay] = useState(false);
    const [showTagsManagement, setShowTagsManagement] = useState(false);
    const [showSendReferralDialog, setShowSendReferralDialog] = useState(false);
    const [showConfirmConvertToCandidate, setShowConfirmConvertToCandidate] = useState(false);
    const [showGenerateDocumentsDialog, setShowGenerateDocumentsDialog] = useState(false);
    const [searchParams] = useSearchParams();
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showConfirmRemoveFromClient, setShowConfirmRemoveFromClient] = useState(false);
    const [showConfirmGrantClientPortalAccess, setShowConfirmGrantClientPortalAccess] = useState(false);
    const [showConfirmRevokeClientPortalAccess, setShowConfirmRevokeClientPortalAccess] = useState(false);
    const [showConfirmUpdateOptOut, setShowConfirmUpdateOptOut] = useState(false);
    const [isFinishedSetup, setIsFinishedSetup] = useState(false);
    const [refreshActivitiesGridControl, setRefreshActivitiesGridControl] = useState(false);
    const [activeTab, setActiveTab] = useState('CONTACTS_Home');
    const [defaultTab, setDefaultTab] = useState<string>();
    const [tabSetupFinished, setTabSetupFinished] = useState(false);
    const [jobsView, setJobsView] = useState<'list' |'kanban'>('list');
    const [sendMessageDialogType, setSendMessageDialogType] = useState<'email' | 'sms' | null>(null);
    const [messageRecipients, setMessageRecipients] = useState<EmailRecipient[]>([]);
    const [tabActionMenuOptions, setTabActionMenuOptions] = useState<MenuOptionDefinition[]>([]);
    const [showPartnerActionPopup, setShowPartnerActionPopup] = useState(false);
    const [callToActionType, setCallToActionType] = useState<CallOutNotificationType>('info');
    const [callToActionTitle, setCallToActionTitle] = useState<string>('');
    const navigate = useNavigate();

    useEffect(() => {
        if (!showTagsPrevew) setShowTagsPreviewNoDelay(false);
    }, [showTagsPrevew]);
    
    const canAccessOpportunities = useMemo(() => companyHasSinglePermission(Permission.Opportunities) && userHasSinglePermission(Permission.Opportunities), []);
    const canAccessJobs = useMemo(() => companyHasSinglePermission(Permission.Jobs) && userHasSinglePermission(Permission.Jobs), []);
    const canAccessPlacements = useMemo(() => companyHasSinglePermission(Permission.Placements) && userHasSinglePermission(Permission.Placements), []);
    const canAccessActivities = useMemo(() => companyHasSinglePermission(Permission.Activities) && userHasSinglePermission(Permission.Activities), []);
    const canAddEditCandidates = useMemo(() => companyHasSinglePermission(Permission.CandidatesAddEdit) && userHasSinglePermission(Permission.CandidatesAddEdit), []);
    const canGenerateDocuments = useMemo(() => companyHasSinglePermission(Permission.DocumentTemplates) && userHasSinglePermission(Permission.DocumentTemplates), []);

    const contactId = useMemo(() => {
        const parsedId = +(params.id ?? '0');
        if (isNaN(parsedId)) return 0;
        return parsedId;
    }, [params.id]);

    const contactIdArray = useMemo(() => [contactId], [contactId]);

    const partnerActionsData = useMemo(() => ({ contactId }), [contactId]);
    const { partnerActions, popupTitle, popupUrl } = usePartnerActions(PartnerActionType.Contacts, partnerActionsData, setShowPartnerActionPopup);

    const canAddEditContacts = useMemo(() => AllowContactsAddEdit(), []);
    const canDeleteContacts = useMemo(() => AllowContactsDelete(), []);
    const canSendEmails = useMemo(() => userHasSinglePermission(Permission.MessageCentre) && companyHasSinglePermission(Permission.MessageCentre), []);
    const canSendSms = useMemo(() => userHasSinglePermission(Permission.MessageCentreSms) && companyHasSinglePermission(Permission.MessageCentreSms), []);
    const canAddJobs = useMemo(() => AllowJobsAddEdit(), []);
    const canAddOpportunities = useMemo(() => AllowOpportunitiesAddEdit(), []);
    const isClientPortalAdmin = useMemo(() => userHasSinglePermission(Permission.ClientPortalAdmin) && companyHasSinglePermission(Permission.ClientPortalAdmin), []);

    const hotkeySettings = useMemo<Record<string, () => void>>(() => {
        return {
            n: () => noteActivitySetting && setSelectedActivity({...noteActivitySetting}),
            e: () => navigate(`/contacts/${contactId}/edit`),
            t: () => setShowTagsManagement(true),
        };
    }, [contactId, navigate, noteActivitySetting]);

    useRecordHotkeys(hotkeySettings, !canAddEditContacts);

    useEffect(() => {
        const getData = async () => {
            setIsLoading(true);
            const res = await GetContactById(contactId);
            if (res === null) navigate('/not-found');

            if (res && res.linkedCandidateID !== 0) {
                setCallToActionType('info');
                setCallToActionTitle(`${res.firstName} is also a Candidate`);
            }
            
            const settings = await GetActivitiesSettings(2, undefined, setErrorMessage);
            if (res) {
                AddRecentRecord(2, contactId, res.fullName);
                setContact(res);
            }
            if (settings) setActivitySettings(settings);
            setIsLoading(false);
        };
        getData();
    }, [contactId, navigate]);

    useEffect(() => {
        const getMeetingTypes = async () => {
            const res = await GetActiveMeetingTypes(entityTypeId);
            if (res) setMeetingTypes(res);
        };
        getMeetingTypes();
    }, []);

    useEffect(() => {
        const tabParam = searchParams.get('tab');
        const getData = async () => {
            setIsFetchingDefaultTab(true);
            const defaultJobView = await GetSettingBySettingName(defaultJobViewSettingName);
            if (defaultJobView) setJobsView(defaultJobView === 'list' ? 'list' : 'kanban');
            if (!tabParam) {
                const tabSetting = await GetSettingBySettingName('TabDefaultsContacts');
                if (tabSetting) setDefaultTab(tabSetting);
            }
            else setActiveTab(tabParam)
            setIsFetchingDefaultTab(false);
            setIsFinishedSetup(true);
        };
        getData();
    }, [searchParams]);

    const sendMessageCallback = useCallback((type: 'email' | 'sms') => {
        if (contact) {
            let recipientEmail = '';
            let recipientMobile = '';
            if (type === 'email') {
                const email = contact.email;
                const email2 = contact.email2;
                recipientEmail = email ? email : email2;
            }
            else if (type === 'sms') {
                const mobileStd = contact.mobile_Standardised;
                const mobile = contact.mobile;
                const phoneStd = contact.phone_Standardised;
                const phone = contact.phone;
                if (Boolean(mobileStd)) recipientMobile = mobileStd;
                else if (Boolean(mobile)) recipientMobile = mobile;
                else if (Boolean(phoneStd)) recipientMobile = phoneStd;
                else if (Boolean(phone)) recipientMobile = phone;
            }
            const recipients: EmailRecipient[] = [
                {
                    id: contact.id,
                    name: contact.fullName,
                    email: recipientEmail,
                    mobile: recipientMobile,
                    contact: contact
                }
            ];
            setSendMessageDialogType(type);
            setMessageRecipients(recipients);
        }
    }, [contact]);

    const actionMenuDefinitions = useMemo<MenuOptionDefinition[]>(() => {
        const hasClient = contact ? contact.clientID > 0 : false;
        const clientPortalUserId = contact ? contact.clientPortalUserId : 0;
        let actions: MenuOptionDefinition[] = [
            ...partnerActions,
            ...tabActionMenuOptions,
            { label: 'Delete', type: 'action', action: () => setShowDeleteDialog(true), allow: () => canDeleteContacts },
            { label: 'Convert to Candidate', type: 'action', action: () => setShowConfirmConvertToCandidate(true), allow: () => canAddEditCandidates && contact?.linkedCandidateID === 0 },
            { label: 'Remove from Client', type: 'action', action: () => setShowConfirmRemoveFromClient(true), allow: () => canAddEditContacts && hasClient },
            { label: 'Allow Client Portal Access', type: 'action', action: () => setShowConfirmGrantClientPortalAccess(true), allow: () => isClientPortalAdmin && clientPortalUserId === 0 },
            { label: 'Revoke Client Portal Access', type: 'action', action: () => setShowConfirmRevokeClientPortalAccess(true), allow: () => isClientPortalAdmin && clientPortalUserId !== 0 },
            { label: 'Update Opt Out', type: 'action', action: () => setShowConfirmUpdateOptOut(true), allow: () => canAddEditContacts },
            { label: 'Send Email', type: 'action', allow: () => canSendEmails, action: () => sendMessageCallback('email') },
            { label: 'Send SMS', type: 'action', allow: () => canSendSms, action: () => sendMessageCallback('sms') },
            { label: 'Create Job', type: 'parent', allow: () => canAddJobs, subMenu: [
                { label: 'Contract', type: 'link', href: `/jobs/create?ContactID=${contactId}&JobType=Contract` },
                { label: 'Fixed Term', type: 'link', href: `/jobs/create?ContactID=${contactId}&JobType=FixedContract` },
                { label: 'Panel', type: 'link', href: `/jobs/create?ContactID=${contactId}&JobType=Panel` },
                { label: 'Permanent', type: 'link', href: `/jobs/create?ContactID=${contactId}&JobType=Permanent` },
                { label: 'Talent Pool', type: 'link', href: `/jobs/create?ContactID=${contactId}&JobType=TalentPool` },
            ]},
            { label: 'Create Opportunity', type: 'link', href: `/opportunities/create?ClientID=${contact?.clientID}&ContactID=${contactId}`, allow: () => canAddOpportunities },
            { label: 'Generate Document', type: 'action', action: () => setShowGenerateDocumentsDialog(true), allow: () => canGenerateDocuments },
        ];

        for (let i = 0; i < activitySettings.length; i++) {
            const setting = activitySettings[i];
            if (setting.category === 2) continue;
            if (setting.id === 18) setNoteActivitySetting(setting);
            if (setting.id === 23)
                actions.push({ label: setting.customName, type: 'action', action: () => setShowSendReferralDialog(true) });
            else
                actions.push({ label: setting.customName, type: 'action', action: () => setSelectedActivity(setting) });
        }

        let meetingActions: MenuOptionDefinition = {
            label: 'Meetings', type: 'parent', subMenu: []
        };

        if (meetingTypes.length > 0 && meetingActions.subMenu) {
            for (let i = 0; i < meetingTypes.length; i++) {
                const t = meetingTypes[i];
                const href = `/meetings/create?contactIds=${contactId}&typeId=${t.id}&source=${entityTypeId}`;
                meetingActions.subMenu.push({ label: t.name, type: 'link', href: href });
            }
        }

        if (meetingActions.subMenu && meetingActions.subMenu.length > 0) actions.push(meetingActions);

        return actions;
    }, [contact, partnerActions, tabActionMenuOptions, contactId, meetingTypes, canDeleteContacts, canAddEditCandidates, canAddEditContacts, isClientPortalAdmin, canSendEmails, sendMessageCallback, canSendSms, canAddJobs, canAddOpportunities, canGenerateDocuments, activitySettings]);

    const SummaryBar = useMemo(() => {
        if (contact) {
            const linkedInAction = (
                <a href={linkedInLink(contact)}
                    style={summaryLinkStyle}
                    key="linkedInAction"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <Avatar sx={{ bgcolor: "#0e76a8", mr: '5px' }}>
                        <img src={LinkedInIcon} alt="linkedin-icon" width="100%" />
                    </Avatar>
                </a>
            );

            const twitterAction = (
                <a href={twitterLink(contact)}
                    style={summaryLinkStyle}
                    key="twitterAction"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <Avatar sx={{ bgcolor: "#1DA1F2", mr: '5px' }}>
                        <img src={TwitterIcon} alt="twitter-icon" width="100%" />
                    </Avatar>
                </a>
            );

            const googleAction = (
                <a href={`https://www.google.com/search?q=${encodeURIComponent(contact.fullName)}`}
                    style={summaryLinkStyle}
                    key="googleAction"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <Avatar sx={{ bgcolor: 'transparent', mr: '5px' }}>
                        <img src={GoogleIcon} alt="google-icon" />
                    </Avatar>
                </a>
            );

            const tagsAction = (
                <Avatar
                    key="tagsAction"
                    onClick={canAddEditContacts ? () => setShowTagsManagement(true) : () => setShowTagsPreviewNoDelay(true)}
                    onMouseEnter={ () => setShowTagsPreview(true) }
                    onMouseLeave={ () => setShowTagsPreview(false) }
                    sx={{ bgcolor: '#f209a6', mr: '5px', cursor: 'pointer' }}>
                    { contact.tags ? <LocalOfferIcon /> : <LocalOfferOutlinedIcon /> }
                </Avatar>
            );

            const locationUrl = googleMapsLink(contact);
            const locationAction = locationUrl ? (
                <a href={ locationUrl }
                    style={summaryLinkStyle}
                    key="locationAction"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <Avatar sx={{ bgcolor: "#ea4335", mr: '5px' }}>
                        <LocationOnIcon />
                    </Avatar>
                </a>
            ) : <Avatar key="locationPlaceholder" sx={{ mr: '5px' }}><LocationOnIcon /></Avatar>;

            const action1 = canAddEditContacts ? (
                <Link key="editLink" to={`/contacts/${contactId}/edit`} style={summaryLinkStyle}>
                    <Button variant="contained" color="success" sx={{ mr: '5px' }}>Edit</Button>
                </Link>
            ) : <React.Fragment key="editPlaceholder" />;

            const action = (
                <Box key="actionsMenu">
                    <ActionMenu definition={actionMenuDefinitions} />
                </Box>
            );

            return (
                <TitleAndActionSummaryBar
                    height="4.5rem"
                    title={contact ? renderSummary(contact) : ''}
                    action={[locationAction, linkedInAction, twitterAction, googleAction, tagsAction, action1, action]}
                    browserTabTitle={!contact ? '' : contact.fullName + " - Contacts"}
                />
            );
        }
    }, [contactId, contact, actionMenuDefinitions, canAddEditContacts]);

    const handleTabChange = useCallback((e: React.SyntheticEvent, newValue: string) => {
        navigate(`/contacts/${contactId}?tab=${newValue}`);
    }, [contactId, navigate]);

    const deleteRecordCallback = useCallback(async () => {
        setIsLoading(true);
        const res = await DeleteContactById(contactId, setErrorMessage);
        if (res) setIsRecordDeleted(true);
        setIsLoading(false);
    }, [contactId]);

    const convertToCandidateCallback = useCallback(async () => {
        setIsLoading(true);
        const res = await ConvertContactToCandidate(contactId, setErrorMessage);
        if (res) {
            setIsLoading(false);
            navigate(`/candidates/${res.value}`);
        }
        setIsLoading(false);
    }, [contactId, navigate]);

    const removeFromClientCallback = useCallback(async () => {
        setIsLoading(true);
        const res = await RemoveContactFromClient(contactId, setErrorMessage);
        if (res) {
            setContact(prev => prev ? {...prev, clientID: 0, clientName: ''} : undefined);
            setSuccessMessage('Removed from Client');
            setShowConfirmRemoveFromClient(false);
        }
        setIsLoading(false);
    }, [contactId]);

    const grantClientPortalAccessCallback = useCallback(async () => {
        setIsLoading(true);
        const res = await GrantClientPortalAccess(contactId, setErrorMessage);
        if (res) {
            setContact(prev => prev ? {...prev, clientPortalUserId: res.value} : undefined);
            setSuccessMessage('Granted access to client portal');
            setShowConfirmGrantClientPortalAccess(false);
        }
        setIsLoading(false);
    }, [contactId]);

    const revokeClientPortalAccessCallback = useCallback(async () => {
        setIsLoading(true);
        const res = await RevokeClientPortalAccess(contactId, setErrorMessage);
        if (res) {
            setContact(prev => prev ? {...prev, clientPortalUserId: 0} : undefined);
            setSuccessMessage('Revoked access to client portal');
            setShowConfirmRevokeClientPortalAccess(false);
        }
        setIsLoading(false);
    }, [contactId]);

    const changeOptOutCallback = useCallback(async () => {
        setIsLoading(true);
        const res = await ToggleContactOptOut(contactId, setErrorMessage);
        if (res) {
            setContact(prev => prev ? { ...prev, optOut: !prev.optOut } : undefined);
            setSuccessMessage('Opt Out Updated');
            setShowConfirmUpdateOptOut(false);
        }
        setIsLoading(false);
    }, [contactId]);

    const counts = useMemo(() => {
        let counts = {
            documents: 0,
            callsNotes: 0,
            callsNotesDate: '\u00A0',
            meetings: 0,
            meetingsDate: '\u00A0',
            messages: 0,
            messagesDate: '\u00A0',
            opportunities: 0,
            opportunitiesDate: '\u00A0',
            openJobs: 0,
            openJobsDate: '\u00A0',
            jobs: 0,
            jobsDate: '\u00A0',
            interviews: 0,
            interviewsDate: '\u00A0',
            placements: 0,
            placementsDate: '\u00A0',
            activities: 0,
            activitiesDate: '\u00A0',
            submissions: 0,
            submissionsDate: '\u00A0'
        };

        if (contact && contact.statisticsCounts && contact.statisticsCounts.length > 0) {
            for (let i = 0; i < contact.statisticsCounts.length; i++) {
                const s = contact.statisticsCounts[i];
                switch (s.type) {
                    case "DocumentsCount": counts.documents = s.value;
                        break;
                    case "CallsNotesCount": counts.callsNotes = s.value;
                        break;
                    case "MeetingsCount": counts.meetings = s.value;
                        break;
                    case "MessagesCount": counts.messages = s.value;
                        break;
                    case "OpportunitiesCount": counts.opportunities = s.value;
                        break;
                    case "JobsOpenCount": counts.openJobs = s.value;
                        break;
                    case "JobsCount": counts.jobs = s.value;
                        break;
                    case "InterviewsCount": counts.interviews = s.value;
                        break;
                    case "PlacementsCount": counts.placements = s.value;
                        break;
                    case "ActivitiesCount": counts.activities = s.value;
                        break;
                    case "SubmissionsCount": counts.submissions = s.value;
                        break;
                }
            }
        }
        if (contact && contact.statisticsDates && contact.statisticsDates.length > 0) {
            for (let i = 0; i < contact.statisticsDates.length; i++) {
                const s = contact.statisticsDates[i];
                switch (s.type) {
                    case "CallsNotesDate":
                        const cnDate = moment(s.value);
                        if (cnDate.isValid()) counts.callsNotesDate = cnDate.format('DD MMM YYYY');
                        break;
                    case "MeetingsDate":
                        const mDate = moment(s.value);
                        if (mDate.isValid()) counts.meetingsDate = mDate.format('DD MMM YYYY');
                        break;
                    case "MessagesDate":
                        const mssDate = moment(s.value);
                        if (mssDate.isValid()) counts.messagesDate = mssDate.format('DD MMM YYYY');
                        break;
                    case "OpportunitiesDate":
                        const oppDate = moment(s.value);
                        if (oppDate.isValid()) counts.opportunitiesDate = oppDate.format('DD MMM YYYY');
                        break;
                    case "JobsOpenDate":
                        const joDate = moment(s.value);
                        if (joDate.isValid()) counts.openJobsDate = joDate.format('DD MMM YYYY');
                        break;
                    case "JobsDate":
                        const jDate = moment(s.value);
                        if (jDate.isValid()) counts.jobsDate = jDate.format('DD MMM YYYY');
                        break;
                    case "InterviewsDate":
                        const iDate = moment(s.value);
                        if (iDate.isValid()) counts.interviewsDate = iDate.format('DD MMM YYYY');
                        break;
                    case "PlacementsDate":
                        const pDate = moment(s.value);
                        if (pDate.isValid()) counts.placementsDate = pDate.format('DD MMM YYYY');
                        break;
                    case "ActivitiesDate":
                        const aDate = moment(s.value);
                        if (aDate.isValid()) counts.activitiesDate = aDate.format('DD MMM YYYY');
                        break;
                    case "SubmissionsDate":
                        const cvDate = moment(s.value);
                        if (cvDate.isValid()) counts.submissionsDate = cvDate.format('DD MMM YYYY');
                        break;
                }
            }
        }
        return counts;
    }, [contact]);

    const jobsViewChangeHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = e.target.checked;
        const value = isChecked ? 'list' : 'kanban';
        setJobsView( value );
        UpdateSettingBySettingName(defaultJobViewSettingName, value);
    }, []);

    const openJobsViewSwitch = useMemo(() => (
        <span>
            <span>Kanban</span>
            <Switch checked={jobsView === 'list'} onChange={ jobsViewChangeHandler } />
            <span>List</span>
        </span>
    ), [jobsView, jobsViewChangeHandler]);

    useEffect(() => {
        if (tabActionMenuOptions.length > 0 && activeTab !== 'CONTACTS_Home') {
            setTabActionMenuOptions([]);
        }
    }, [activeTab, tabActionMenuOptions]);

    const tagManagementSuccessHandler = useCallback((message: string, recordIds: number[], finalTagCount: number) => {
        setSuccessMessage(message);
    }, []);

    const activityCreatedSuccessCallback = useCallback((message: string) => {
        setSuccessMessage(message);
        setRefreshActivitiesGridControl(prev => !prev);
    }, []);

    const selectedActivityData = useMemo<Activity | null>(() => {

        if (contact && selectedActivity) {
            return {
                ...DefaultActivity,
                contactID: contactId,
                contactName: contact.fullName,
                category: 1,
                type: selectedActivity.customName,
                typeID: selectedActivity.id
            };
        }
        return null;
    }, [contact, contactId, selectedActivity]);

    useEffect(() => {
        if (defaultTab && !tabSetupFinished) {
            const accessRestrictedTabs = ['CONTACTS_CallsNotes', 'CONTACTS_Meetings', 'CONTACTS_Messages', 'CONTACTS_Activities'];
            setActiveTab(prev => {
                if (accessRestrictedTabs.includes(defaultTab) && !canAccessActivities) return prev;
                if (defaultTab === 'CONTACTS_JobsOpen' && !canAccessJobs) return prev;
                if (defaultTab === 'CONTACTS_Jobs' && !canAccessJobs) return prev;
                if (defaultTab === 'CONTACTS_Submissions' && !(canAccessActivities && canAccessJobs)) return prev;
                if (defaultTab === 'CONTACTS_Interviews' && !(canAccessActivities && canAccessJobs)) return prev;
                if (defaultTab === 'CONTACTS_Placements' && !canAccessPlacements) return prev;
                if (defaultTab === 'CONTACTS_Opportunities' && !canAccessOpportunities) return prev;
                return defaultTab;
            });
            setTabSetupFinished(true);
        }
    }, [defaultTab, canAccessActivities, canAccessJobs, canAccessPlacements, canAccessOpportunities, tabSetupFinished]);

    return (
        <>
            <Snackbar open={successMessage !== ''} autoHideDuration={3000} onClose={() => setSuccessMessage('')}>
                <Alert onClose={() => setSuccessMessage('')}>{ successMessage }</Alert>
            </Snackbar>
            <Snackbar open={isRecordDeleted} autoHideDuration={1200} onClose={() => navigate('/contacts/')}>
                <Alert onClose={() => navigate('/contacts/')}>Record deleted</Alert>
            </Snackbar>
            <Snackbar open={errorMessage !== ''} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <PageLayout paddingTop={0} SummaryBar={SummaryBar}>
                <ConfirmationDialog
                    message="Are you sure you want to delete this record?"
                    onClose={ () => setShowDeleteDialog(false) }
                    onContinue={ deleteRecordCallback }
                    open={showDeleteDialog}
                    title="Confirm Action"
                    confirmActionText="Yes"
                    cancelActionText="No"
                />
                <ConfirmationDialog
                    message="Are you sure you want to convert this record?"
                    onClose={ () => setShowConfirmConvertToCandidate(false) }
                    onContinue={ convertToCandidateCallback }
                    open={showConfirmConvertToCandidate}
                    title="Confirm Action"
                    confirmActionText="Yes"
                    cancelActionText="No"
                />
                <ConfirmationDialog
                    message="Are you sure you want to remove this record from its Client?"
                    onClose={ () => setShowConfirmRemoveFromClient(false) }
                    onContinue={ removeFromClientCallback }
                    open={showConfirmRemoveFromClient}
                    title="Confirm Action"
                    confirmActionText="Yes"
                    cancelActionText="No"
                />
                <ConfirmationDialog
                    message="Are you sure you want to ALLOW access to the Client Portal?"
                    onClose={ () => setShowConfirmGrantClientPortalAccess(false) }
                    onContinue={ grantClientPortalAccessCallback }
                    open={showConfirmGrantClientPortalAccess}
                    title="Confirm Action"
                    confirmActionText="Yes"
                    cancelActionText="No"
                />
                <ConfirmationDialog
                    message="Are you sure you want to REVOKE access to the Client Portal?"
                    onClose={ () => setShowConfirmRevokeClientPortalAccess(false) }
                    onContinue={ revokeClientPortalAccessCallback }
                    open={showConfirmRevokeClientPortalAccess}
                    title="Confirm Action"
                    confirmActionText="Yes"
                    cancelActionText="No"
                />
                <ConfirmationDialog
                    message={`Are you sure you want to update Opt Out to ${contact && contact.optOut ? 'No' : 'Yes'} ?`}
                    onClose={ () => setShowConfirmUpdateOptOut(false) }
                    onContinue={ changeOptOutCallback }
                    open={showConfirmUpdateOptOut}
                    title="Update Opt Out"
                    confirmActionText="Yes"
                    cancelActionText="No"
                />
                <EditActivityDialog
                    closeHandler={ () => setSelectedActivity(null) }
                    data={selectedActivityData}
                    open={ Boolean(selectedActivity) }
                    loadingHandler={setIsLoading}
                    errorHandler={setErrorMessage}
                    successHandler={activityCreatedSuccessCallback}
                />
                <SendReferralDialog
                    open={showSendReferralDialog}
                    contactId={contact && contact.id ? contact.id : 0}
                    contactName={contact && contact.fullName}
                    closeHandler={ () => setShowSendReferralDialog(false) }
                    loadingHandler={setIsLoading}
                    errorHandler={setErrorMessage}
                    successHandler={setSuccessMessage}
                />
                <PreviewLoaderComponent
                    open={showTagsPrevew}
                    entityType="contact"
                    recordId={contactId}
                    showDelayMs={showTagsPrevewNoDelay ? 0 : undefined}
                    isTagsPreview
                />
                <TagsManagementDialog
                    open={showTagsManagement}
                    entityId={2}
                    recordIds={contactIdArray}
                    closeHandler={ () => setShowTagsManagement(false) }
                    loadingHandler={ setIsLoading }
                    errorHandler={ setErrorMessage }
                    successHandler={ tagManagementSuccessHandler }
                />
                <SendEmailDialog
                    open={ sendMessageDialogType === 'email' }
                    sourceEntityId={entityTypeId}
                    recipients={ messageRecipients }
                    allowCcField
                    recipientEntityTypeId={ 2 }
                    closeHandler={ () => setSendMessageDialogType(null) }
                    loadingHandler={setIsLoading}
                    errorHandler={setErrorMessage}
                    successHandler={setSuccessMessage}
                />
                <SendSmsDialog
                    open={ sendMessageDialogType === 'sms' }
                    sourceEntityId={entityTypeId}
                    recipients={ messageRecipients }
                    recipientEntityTypeId={ 2 }
                    closeHandler={ () => setSendMessageDialogType(null) }
                    loadingHandler={setIsLoading}
                    errorHandler={setErrorMessage}
                    successHandler={setSuccessMessage}
                />
                <GenerateDocumentDialog
                    open={showGenerateDocumentsDialog}
                    entityId={entityTypeId}
                    playerId={contactId}
                    closeHandler={() => setShowGenerateDocumentsDialog(false)}
                    loadingHandler={ setIsLoading }
                    errorHandler={ setErrorMessage }
                    successHandler={ setSuccessMessage }
                />
                <Tabs value={activeTab} onChange={handleTabChange}>
                    <Tab value="CONTACTS_Home" label={<>Home<br/>&nbsp;</>} />
                    {canAccessActivities && <Tab value="CONTACTS_CallsNotes" label={<>Calls/Notes ({counts.callsNotes})<br />{counts.callsNotesDate}</>} />}
                    {canAccessActivities && <Tab value="CONTACTS_Meetings" label={<>Meetings ({counts.meetings})<br />{counts.meetingsDate}</>} />}
                    {canAccessActivities && <Tab value="CONTACTS_Messages" label={<>Messages ({counts.messages})<br />{counts.messagesDate}</>} />}
                    {canAccessOpportunities && <Tab value="CONTACTS_Opportunities" label={<>Opportunities ({counts.opportunities})<br />{counts.opportunitiesDate}</>} />}
                    {canAccessJobs && <Tab value="CONTACTS_JobsOpen" label={<>Open Jobs ({counts.openJobs})<br />{counts.openJobsDate}</>} />}
                    {canAccessJobs && <Tab value="CONTACTS_Jobs" label={<>All Jobs ({counts.jobs})<br/>{counts.jobsDate}</>}/> }
                    {canAccessActivities && canAccessJobs && <Tab value="CONTACTS_Submissions" label={<>Submissions ({counts.submissions})<br />{counts.submissionsDate}</>} />}
                    {canAccessActivities && canAccessJobs && <Tab value="CONTACTS_Interviews" label={<>Interviews ({counts.interviews})<br />{counts.interviewsDate}</>} />}
                    {canAccessPlacements && <Tab value="CONTACTS_Placements" label={<>Placements ({counts.placements})<br />{counts.placementsDate}</>} />}
                    <Tab value="CONTACTS_Documents" label={<>Documents ({counts.documents})<br />&nbsp;</>} />
                    {canAccessActivities && <Tab value="CONTACTS_Activities" label={<>Activities ({counts.activities})<br />{counts.activitiesDate}</>} />}
                </Tabs>
                { Boolean(callToActionTitle) &&
                    <CallOutNotification
                        type={callToActionType}
                        title={callToActionTitle}
                        onClose={() => setCallToActionTitle('')}
                    />
                }
                <PageContentLayout showLoading={isLoading || isFetchingDefaultTab || isLoadingTab}>
                    <ShowIframeDialog open={showPartnerActionPopup} closeHandler={() => setShowPartnerActionPopup(false)} title={popupTitle} url={popupUrl} />
                    { isFinishedSetup && activeTab === "CONTACTS_Home" &&
                        <ViewRecordScreenLayout
                            contact={contact ? contact : null}
                            loadingHandler={setIsLoadingTab}
                            errorHandler={setErrorMessage}
                        />
                    }
                    { isFinishedSetup && canAccessActivities && activeTab === "CONTACTS_CallsNotes" &&
                        <ActivitiesGridComponent
                            source="contact-record"
                            sourceId={contactId}
                            categoryFilter={1}
                            loadingHandler={ setIsLoadingTab }
                            errorHandler={ setErrorMessage }
                            successHandler={ setSuccessMessage }
                            gridName="contacts/CallsNotes"
                            refreshControl={refreshActivitiesGridControl}
                            hideActionsMenu
                        />
                    }
                    { isFinishedSetup && canAccessActivities && activeTab === "CONTACTS_Meetings" &&
                        <MeetingsGridComponent
                            source="contact-record"
                            sourceId={contactId}
                            loadingHandler={ setIsLoadingTab }
                            errorHandler={ setErrorMessage }
                            successHandler={ setSuccessMessage }
                            gridName="contacts/Meetings"
                        />
                    }
                    { isFinishedSetup && canAccessActivities && activeTab === "CONTACTS_Messages" &&
                        <ActivityMessagesGridComponent
                            source="contact-record"
                            sourceId={contactId}
                            errorHandler={ setErrorMessage }
                            loadingHandler={ setIsLoadingTab }
                            successHandler={ setSuccessMessage }
                            gridName="contacts/Messages"
                        />
                    }
                    { isFinishedSetup && canAccessOpportunities && activeTab === "CONTACTS_Opportunities" &&
                        <OpportunitiesGridComponent
                            source="contact-record"
                            sourceId={contactId}
                            loadingHandler={setIsLoadingTab}
                            errorHandler={setErrorMessage}
                            successHandler={setSuccessMessage}
                            gridName="contacts/Opportunities"
                        />
                    }
                    { isFinishedSetup && canAccessJobs && activeTab === "CONTACTS_JobsOpen" &&
                        <>
                            {jobsView === 'kanban' &&
                                <>
                                    {openJobsViewSwitch}
                                    <JobsKanbanComponent source="contact-record" sourceId={contactId} loadingHandler={setIsLoadingTab} />
                                </>
                            }
                            {jobsView === 'list' &&
                                <JobsGridComponent
                                    source="contact-record"
                                    sourceId={contactId}
                                    jobState={1}
                                    loadingHandler={setIsLoadingTab}
                                    extraActions={openJobsViewSwitch}
                                    gridName="contacts/OpenJobs"
                                />
                            }
                        </>
                    }
                    { isFinishedSetup && canAccessJobs && activeTab === "CONTACTS_Jobs" &&
                        <JobsGridComponent
                            source="contact-record"
                            sourceId={contactId}
                            jobState={0}
                            loadingHandler={setIsLoadingTab}
                            gridName="contacts/Jobs"
                        />
                    }
                    { isFinishedSetup && canAccessActivities && canAccessJobs && activeTab === "CONTACTS_Submissions" &&
                        <SubmissionsGridComponent
                            source="contact-record"
                            sourceId={contactId}
                            loadingHandler={ setIsLoadingTab }
                            errorHandler={ setErrorMessage }
                            successHandler={ setSuccessMessage }
                            gridName="contacts/Submissions"
                        />
                    }
                    { isFinishedSetup && canAccessActivities && canAccessJobs && activeTab === "CONTACTS_Interviews" &&
                        <InterviewsGridComponent
                            source="contact-record"
                            sourceId={contactId}
                            loadingHandler={ setIsLoadingTab }
                            errorHandler={ setErrorMessage }
                            successHandler={ setSuccessMessage }
                            gridName="contacts/Interviews"
                        />
                    }
                    { isFinishedSetup && canAccessPlacements && activeTab === "CONTACTS_Placements" &&
                        <PlacementsGridComponent
                            source="contact-record"
                            sourceId={contactId}
                            loadingHandler={setIsLoadingTab}
                            errorHandler={setErrorMessage}
                            successHandler={setSuccessMessage}
                            gridName="contacts/Placements"
                        />
                    }
                    { isFinishedSetup && activeTab === "CONTACTS_Documents" &&
                        <DocumentsGridComponent
                            gridName="contacts/Documents"
                            source="contact-record"
                            sourceId={contactId}
                            loadingHandler={setIsLoadingTab}
                            errorHandler={setErrorMessage}
                            successHandler={setSuccessMessage}
                        />
                    }
                    { isFinishedSetup && canAccessActivities && activeTab === "CONTACTS_Activities" &&
                        <ActivitiesGridComponent
                            source="contact-record"
                            sourceId={contactId}
                            loadingHandler={ setIsLoadingTab }
                            errorHandler={ setErrorMessage }
                            successHandler={ setSuccessMessage }
                            gridName="contacts/Activities"
                            refreshControl={refreshActivitiesGridControl}
                            hideActionsMenu
                        />
                    }
                </PageContentLayout>
            </PageLayout>
        </>
    );
}
