import React, { useEffect, useState } from "react";
import { Job, JobStage } from "../../common/models/Jobs/Job";
import useKanbanStyleUpdater from "../../hooks/UseKanbanStyleUpdater";
import { GetClientJobs } from "../../services/ClientsService";
import { GetContactJobs } from "../../services/ContactsService";
import { GetSiteJobs } from "../../services/SitesService";
import { GetMyJobs, GetTeamOpenJobs, GetCompanyOpenJobs, GetMyJobsByStateId, GetUserJobsByStateId } from "../../services/JobsService";
import { KanbanCardTagStyle } from "../../util/Definitions/Kanban";
import { GetJobStages } from "../../services/ConfigurationService";
import { KanbanGroupingColumn } from "../../common/models/Jobs/JobTracking";

interface Props {
    source: 'dashboard' | 'client-record' | 'contact-record' | 'site-record' | 'my-jobs' | 'team-jobs' | 'company-jobs' | 'consultant-jobs',
    sourceId?: number,
    loadingHandler?: (isLoading: boolean) => void
}

const jobTypeStyle = {
    'c': {
        background: '#ffeb9c',
        text: '#9c6500',
    },
    'p': {
        background: '#c6efce',
        text: '#006100',
    },
    'ft': {
        background: '#9bc2e6',
        text: '#203764',
    },
    'tp': {
        background: '#ffc7ce',
        text: '#9c0006',
    },
    'pa': {
        background: '#cbcbcb',
        text: '#3a3a3a',
    }
};

let ratingStyle = {
    'B': {
        background: '#ffeb9c',
        text: '#9c6500',
    },
    'A': {
        background: '#c6efce',
        text: '#006100',
    },
    'C': {
        background: '#ffc7ce',
        text: '#9c0006',
    }
};

const jobTypes = {
    "Contract": 'c',
    "Permanent": 'p',
    "Fixed Term": 'ft',
    "Talent Pool": 'tp',
    "Panel": 'pa'
};

const formatNumber = (value: number) => {
    return value.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 });
};

const kanbanElementDomId = 'jobs-kanban-element';

export default function JobsKanbanComponent({ source, sourceId, loadingHandler }: Props) {
    const [isSetupFinished, setIsSetupFinished] = useState(false);
    const [jobStages, setJobStages] = useState<JobStage[]>([]);
    const [styleUpdaterTrigger, setStyleUpdaterTrigger] = useState(false);
    useKanbanStyleUpdater(isSetupFinished,undefined,styleUpdaterTrigger);

    useEffect(() => {
        const getData = async () => {
            loadingHandler && loadingHandler(true);

            let stages: JobStage[] | null = [];
            stages = await GetJobStages(true);
            if(stages) {
                setJobStages(stages);
            }
            loadingHandler && loadingHandler(false);
        };
        getData();
    }, [loadingHandler]);

    useEffect(() => {
        if (sourceId) {}
        const k = document.getElementById(kanbanElementDomId);
        if (k) {
            for (let i = 0; i < k.children.length; i++) {
                const child = k.children[i];
                k.removeChild(child);
            }
        }
    }, [sourceId]);

    useEffect(() => {
        const setup = async () => {
            loadingHandler && loadingHandler(true);
            setIsSetupFinished(false);
            let rawData: Job[] | null = [];
            if (source === 'dashboard') rawData = await GetMyJobsByStateId(1);
            else if (source === 'my-jobs') rawData = await GetMyJobs();
            else if (source === 'team-jobs') rawData = await GetTeamOpenJobs();
            else if (source === 'company-jobs') rawData = await GetCompanyOpenJobs();
            else if (source === 'consultant-jobs' && sourceId) rawData = await GetUserJobsByStateId(sourceId, 1);
            else if (source === 'client-record' && sourceId) rawData = await GetClientJobs(sourceId, 1);
            else if (source === 'contact-record' && sourceId) rawData = await GetContactJobs(sourceId, 1);
            else if (source === 'site-record' && sourceId) rawData = await GetSiteJobs(sourceId, 1);

            const t = document.getElementById(kanbanElementDomId);
            if (t && rawData && jobStages) {

                let cardData = [];
                let cardColumns: KanbanGroupingColumn[] = [];
                jobStages.map(k => {
                    if(rawData) {
                        const jobs = rawData.filter(r=>r.stageID == k.id);
                        let dealAmount = 0;
                        jobs.forEach(job => {
                            dealAmount +=job.dealAmount;
                        });
                        cardColumns.push({id: k.id, label: `${k.name} [${jobs.length}] - ${formatNumber(dealAmount)}`} )
                    }
                });
                
                for (var i = 0; i < rawData.length; i++) {
                    const d = rawData[i];
                
                    let consultants = '<b>' + d.consultant1Name + '</b>';

                    if (d.consultant2Name !== null)
                        consultants = consultants + ' / <b>' + d.consultant2Name + '</b>';

                    const jt = (jobTypes as any)[d.typeName];
                    let jobTypeStyleObj = { background: 'white', text: 'black' };
                    if (jt) jobTypeStyleObj = (jobTypeStyle as any)[jt];

                    let ratingStyleObj = (ratingStyle as any)[d.ratingName] ?? { background: 'white', text: 'black' };

                    cardData.push({
                        id: d.id,
                        title: d.title,
                        clientName: d.clientName,
                        jobType: d.typeName,
                        rating: d.ratingName,
                        suburb: d.shortAddress,
                        todo: d.toDo,
                        total: d.total,
                        dealAmount: d.dealAmount,
                        consultants: consultants,
                        column: d.stageID,
                        jobTypeTagBgColor: jobTypeStyleObj.background,
                        jobTypeTagTextColor: jobTypeStyleObj.text,
                        ratingTagBgColor: ratingStyleObj.background,
                        ratingTagTextColor: ratingStyleObj.text,
                    });
                }
                if(cardColumns.length > 0) {
                new (window as any).kanban.Kanban(`#${kanbanElementDomId}`, {
                    readonly: true,
                    scrollType: 'column',
                    columns: cardColumns,
                    cards: cardData,
                    cardTemplate: (params: any) => {
                        const {cardFields} = params;
                        const {
                            id, title, consultants, clientName, suburb, total, todo, dealAmount, jobType, rating, jobTypeTagBgColor, jobTypeTagTextColor, ratingTagBgColor, ratingTagTextColor
                        } = cardFields;

                        return (
                            `
<div style='padding: 10px 5px; border-left: 0.5rem solid ${jobTypeTagBgColor}; border-radius: var(--wx-kanban-card-border-radius)'>
    <div style="font-weight: bold; color: #008299"><a href='/jobs/${id}' style='color: inherit'>${title}</a></div>
    <div>${consultants}</div>
    <div>${clientName}</div>
    <div>${suburb}</div>
    <div>Total: <a style="color: #008299; font-weight: bold" href='/jobs/${id}?tab=JOBS_Candidates'>${total}</a> - Unreviewed: <a style="color: #008299; font-weight: bold" href='/jobs/${id}?tab=JOBS_Candidates&RatingID=3'>${todo}</a></div>
    <div>Deal Value: ${dealAmount}</div>
    <div style='display: flex; justify-content: end'>
        ${ratingTagBgColor !== 'white' ? `<div style="background: ${ratingTagBgColor}; color: ${ratingTagTextColor}; ${KanbanCardTagStyle}">${rating}</div>` : ''}
        ${jobTypeTagBgColor !== 'white' ? `<div style="background: ${jobTypeTagBgColor}; color: ${jobTypeTagTextColor}; ${KanbanCardTagStyle}">${jobType}</div>` : ''}
    </div>
</div>`
    )
}
                });
            }
                setStyleUpdaterTrigger(prev => !prev);
                setIsSetupFinished(true);
            }
            loadingHandler && loadingHandler(false);
        };
        setup();
    }, [source, sourceId,jobStages, loadingHandler]);

    return (
        <div style={{ display: 'flex', flex: '1', flexDirection: 'column', position: 'relative' }}>
            <div style={{ display: 'flex', flex: '1', flexDirection: 'column', position: 'relative', overflow: 'hidden' }}>
                <div id={kanbanElementDomId} style={{ position: 'absolute', top: 0, left: 0, height: '100%', width: '100%', overflow: 'auto' }} />
            </div>
        </div>
    );
}