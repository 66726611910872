import MenuList from "@mui/material/MenuList";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { MenuOptionDefinition } from "common/models/MenuDefinition";
import Button from "@mui/material/Button";
import Popper, { PopperPlacementType } from "@mui/material/Popper";
import Paper from "@mui/material/Paper";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import MenuOptionRenderer from "./MenuOptionRenderer";

interface Props {
    definition: MenuOptionDefinition[],
    sortType?: 'alphabetical' | 'workflow',
    label?: string,
    placement?: PopperPlacementType,
    ml?: string | number,
    mr?: string | number,
    color?: "inherit" | "success" | "primary" | "secondary" | "error" | "info" | "warning" | undefined,
}

const sortDefinitionByLabel = (a: MenuOptionDefinition, b: MenuOptionDefinition) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0);

const sortDefinitionByOrderIndex = (a: MenuOptionDefinition, b: MenuOptionDefinition) => {
    const ai = a.orderIndex ?? 99;
    const bi = b.orderIndex ?? 99;
    return (ai > bi) ? 1 : ((bi > ai) ? -1 : 0);
};

export default function ActionMenu({ definition, label = "Actions", sortType = 'alphabetical', placement = "bottom-end", color = "primary", ml, mr }: Props) {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [isRootHovered, setIsRootHovered] = useState(false);
    const [isRootPaperHovered, setIsRootPaperHovered] = useState(false);
    const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
    const sortedDefinition = useMemo(() => {
        if (sortType === 'workflow') return definition.sort(sortDefinitionByOrderIndex);        
        return definition.sort(sortDefinitionByLabel);
    }
    , [definition, sortType]);
    
    const handleCloseMenu = useCallback(() => setAnchorEl(null), []);
    
    const handleOpenMenu = useCallback((e: React.MouseEvent<HTMLElement>) => {
        setIsRootHovered(true);
        setAnchorEl(e.currentTarget);
    }, []);

    useEffect(() => {
        if (Boolean(anchorEl) && !isRootHovered && !isRootPaperHovered) setAnchorEl(null);
    }, [anchorEl, isRootHovered, isRootPaperHovered]);

    return (
        <>
            <Button
                variant="contained"
                color={color}
                endIcon={ <ArrowDropDown /> }
                onClick={ handleOpenMenu }
                onMouseEnter={ handleOpenMenu }
                onMouseLeave={ () => setIsRootHovered(false) }
                sx={{ ml: ml, mr: mr }}
            >{ label }</Button>
            <Popper
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                placement={placement}
                disablePortal
                sx={{ zIndex: t => t.zIndex.appBar - 2 }}
                onMouseEnter={ () => setIsRootPaperHovered(true) }
                onMouseLeave={ () => setIsRootPaperHovered(false) }
            >
                <Paper elevation={6}>
                    <ClickAwayListener onClickAway={ handleCloseMenu }>
                        <MenuList>
                            {sortedDefinition.map((d, i) => {
                                return (
                                    <MenuOptionRenderer
                                        key={i}
                                        index={i}
                                        hoveredIndex={hoveredIndex}
                                        definition={d}
                                        hoveredIndexHandler={ setHoveredIndex }
                                        closeMenuHandler={ handleCloseMenu }
                                    />
                                );
                            })}
                        </MenuList>
                    </ClickAwayListener>
                </Paper>
            </Popper>
        </>
    );
}