import Alert from "@mui/material/Alert";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { SxProps } from "@mui/material/styles";
import { BasicFilter, FilterWithTimeRange } from "common/models/Analytics/AnalyticsFilterData";
import { NameIdObj } from "common/models/GenericTypes";
import MultipleDivisionsPicker from "components/Pickers/MultipleDivisionPicker";
import MultipleGroupPicker from "components/Pickers/MultipleGroupPicker";
import MultipleUserPicker from "components/Pickers/MultipleUserPicker";
import PageContentLayout from "layouts/PageContentLayout";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {Link, useNavigate} from "react-router-dom";
import ShareIcon from '@mui/icons-material/Share';
import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PlacementsAveragePermFeesContent from "components/Analytics/Placements/PlacementsAveragePermFees";
import PlacementsAverageDailyContractMarginContent from "components/Analytics/Placements/PlacementsAverageDailyContractMargin";
import PlacementsAverageHourlyContractMarginContent from "components/Analytics/Placements/PlacementsAverageHourlyContractMargin";
import moment from "moment";
import { DatePicker } from "@mui/x-date-pickers-pro";
import PlacementsAverageByConsultantContent from "components/Analytics/Placements/PlacementsAverageByConsultant";
import { GetCustomerSettingBySettingName } from "services/ConfigurationService";
import { GetDatesFromTimeRangeFilter } from "util/DateUtils";
import PageLayout from "../../../layouts/PageLayout";
const filterElementStyle: SxProps = { flex: '1 1 0' };

type PlacementsAverageFeesTab = 'perm-fees' | 'contract-daily' | 'contract-hourly' | 'consultant';
const tabValues = [ 'perm-fees', 'contract-daily', 'contract-hourly', 'consultant' ];
type DisplayValue = 'graph' | 'table';
const displayValues: DisplayValue[] = ['graph', 'table'];

export default function PlacementsAverageFeesPage() {
    const [summaryBar, setSummaryBar] = useState(<></>);
    const [activeTab, setActiveTab] = useState<PlacementsAverageFeesTab>('perm-fees');
    const [displayMode, setDisplayMode] = useState<DisplayValue>('graph');
    const [isLoading, setIsLoading] = useState(false);
    const [sourceType, setSourceType] = useState(1);
    const [timeRange, setTimeRange] = useState(4);
    const [startDate, setStartDate] = useState<moment.Moment | null>(null);
    const [endDate, setEndDate] = useState<moment.Moment | null>(null);
    const [selectedDivisions, setSelectedDivisions] = useState<number[]>([]);
    const [selectedGroups, setSelectedGroups] = useState<number[]>([]);
    const [selectedUsers, setSelectedUsers] = useState<number[]>([]);
    const [infoMessage, setInfoMessage] = useState('');
    const [shouldRunReport, setShouldRunReport] = useState(false);
    const [isInitialSetupFinished, setIsInitialSetupFinished] = useState(false);
    const [isInitialQuerySetupFinished, setIsInitialQuerySetupFinished] = useState(false);
    const [filterData, setFilterData] = useState<BasicFilter | null>(null);
    const [currentFilterData, setCurrentFilterData] = useState<BasicFilter | null>(null);
    const [filterWithTimeRangeData, setFilterWithTimeRangeData] = useState<FilterWithTimeRange | null>(null);
    const [currentFilterWithTimeRangeData, setCurrentFilterWithTimeRangeData] = useState<FilterWithTimeRange | null>(null);
    const [financialYearStartMonth, setFinancialYearStartMonth] = useState(1);
    const navigate = useNavigate();

    useEffect(() => {
        const getYearStart = async () => {
            const res = await GetCustomerSettingBySettingName('YearStart');
            if (res) {
                const parsed = +res;
                if (parsed >= 1 && parsed <= 12) setFinancialYearStartMonth(parsed);
            }
            setIsInitialSetupFinished(true);
        };
        getYearStart();
    }, []);

    useEffect(() => {
        if (isInitialSetupFinished) {
            const urlParams = new URLSearchParams(window.location.search);
            const tab = urlParams.get('tab');
            const st = urlParams.get('sourceType');
            const sid = urlParams.get('sourceIds');
            const view = urlParams.get('view');
            let parsedSt = 1;
    
            if (tab && tabValues.includes(tab)) setActiveTab(tab as PlacementsAverageFeesTab);
    
            if (view && displayValues.includes(view as DisplayValue)) setDisplayMode(view as DisplayValue);
    
            if (st) {
                const p = Number(st);
                if (p >= 1 && p <= 4) {
                    parsedSt = p;
                    setSourceType(p);
                }
            }
            if (sid) {
                const ids: number[] = sid.split(',').map(n => Number(n));
                if (parsedSt === 2) setSelectedDivisions(ids);
                else if (parsedSt === 3) setSelectedGroups(ids);
                else if (parsedSt === 4) setSelectedUsers(ids);
            }
    
            if (tab && tab === 'consultant') {
                const tr = urlParams.get('timeRange');
                const sd = urlParams.get('startDate');
                const ed = urlParams.get('endDate');
    
                if (tr && tr !== '0') {
                    const p = Number(tr);
                    if (p >=1 && p <= 16) {
                        const dates = GetDatesFromTimeRangeFilter(p, financialYearStartMonth);
                        if (dates) {
                            const [start, end] = dates;
                            setTimeRange(p);
                            setStartDate(start);
                            setEndDate(end);
                        }
                    }
                }
                else {
                    if (sd) {
                        const m = moment(sd);
                        if (m.isValid()) setStartDate(m);
                    }
                    if (ed) {
                        const m = moment(ed);
                        if (m.isValid()) setEndDate(m);
                    }
                    if (ed || sd) setTimeRange(0);
                }
            }
    
            setShouldRunReport(true);
            setIsInitialQuerySetupFinished(true);
        }
    }, [financialYearStartMonth, isInitialSetupFinished]);

    const sourceIds = useMemo(() => {
        let sourceIds = '';
        if (sourceType === 2) sourceIds = selectedDivisions.join(',');
        else if (sourceType === 3) sourceIds = selectedGroups.join(',');
        else if (sourceType === 4) sourceIds = selectedUsers.join(',');
        return sourceIds;
    }, [selectedDivisions, selectedGroups, selectedUsers, sourceType]);

    const generateQueryString = useCallback(() => {
        if (activeTab === 'consultant') {
            const sd = timeRange === 0 && startDate ? startDate.format('YYYY-MM-DD') : '';
            const ed = timeRange === 0 && endDate ? endDate.format('YYYY-MM-DD') : '';
            if (sourceType === 1) {
                return timeRange === 0 ? `?tab=${activeTab}&view=${displayMode}&sourceType=${sourceType}&startDate=${sd}&endDate=${ed}` : `?tab=${activeTab}&view=${displayMode}&sourceType=${sourceType}&timeRange=${timeRange}`;
            }
            else {
                return timeRange === 0 ? `?tab=${activeTab}&view=${displayMode}&sourceType=${sourceType}&startDate=${sd}&endDate=${ed}&sourceIds=${encodeURIComponent(sourceIds)}` : `?tab=${activeTab}&view=${displayMode}&sourceType=${sourceType}&timeRange=${timeRange}&sourceIds=${encodeURIComponent(sourceIds)}`;
            }
        }

        if (sourceType === 1) return `?tab=${activeTab}&view=${displayMode}&sourceType=${sourceType}`;
        else {
            return `?tab=${activeTab}&view=${displayMode}&sourceType=${sourceType}&sourceIds=${encodeURIComponent(sourceIds)}`;
        }
    }, [activeTab, displayMode, endDate, sourceIds, sourceType, startDate, timeRange]);

    const shareActionCallback = useCallback(() => {
        let url = window.location.protocol + '//' + window.location.host + window.location.pathname + generateQueryString();
        navigator.clipboard.writeText(url);
        setInfoMessage('Link copied to clipboard');
    }, [generateQueryString]);

    const runReportCallback = useCallback(() => {
        let f: FilterWithTimeRange = {
            startDate: startDate ? startDate.format('YYYY-MM-DD') : '',
            endDate: endDate ? endDate.format('YYYY-MM-DD') : '',
            userFilterID: sourceIds,
            userFilterTypeID: sourceType
        };

        if (startDate === null || endDate === null) {
            const d = GetDatesFromTimeRangeFilter(timeRange, financialYearStartMonth);
            if (d) {
                const [s,e] = d;
                f.startDate = s ? s.format('YYYY-MM-DD') : '';
                f.endDate = e ? e.format('YYYY-MM-DD') : '';
            }
        }

        setFilterData({ userFilterTypeID: sourceType, userFilterID: sourceIds });
        setCurrentFilterData({ userFilterTypeID: sourceType, userFilterID: sourceIds });
        setFilterWithTimeRangeData(f);
        setCurrentFilterWithTimeRangeData(f);
    }, [endDate, financialYearStartMonth, sourceIds, sourceType, startDate, timeRange]);

    useEffect(() => {
        const action = (
            <Avatar
                key="splitsAction"
                onClick={shareActionCallback}
                title="Copy link to clipboard"
                sx={{ bgcolor: t => t.palette.primary.main, mr: '5px', cursor: 'pointer' }}
            >
                <ShareIcon />
            </Avatar>
        );
        const reportsLink = <Link to="/analytics/placements" style={{ color: 'inherit', textDecoration: 'underline' }}>Placements</Link>;

        const title = <>{'Analytics > '} {reportsLink} {' > Average Fees'}</>;
        const tabTitle = 'Average Fees > Placements > Analytics';

        const summaryBar = <TitleAndActionSummaryBar title={title} browserTabTitle={tabTitle} action={action} />;
        setSummaryBar(summaryBar);
    }, [shareActionCallback]);
    
    useEffect(() => {
        if (isInitialQuerySetupFinished) {
            const dates = GetDatesFromTimeRangeFilter(timeRange, financialYearStartMonth);
            if (dates) {
                const [start, end] = dates;
                setStartDate(start);
                setEndDate(end);
            }
        }
    }, [financialYearStartMonth, isInitialQuerySetupFinished, timeRange]);

    useEffect(() => {
        if (isInitialQuerySetupFinished && shouldRunReport) {
            let f: FilterWithTimeRange = {
                startDate: startDate ? startDate.format('YYYY-MM-DD') : '',
                endDate: endDate ? endDate.format('YYYY-MM-DD') : '',
                userFilterID: sourceIds,
                userFilterTypeID: sourceType
            };
    
            if (startDate === null || endDate === null) {
                const d = GetDatesFromTimeRangeFilter(timeRange, financialYearStartMonth);
                if (d) {
                    const [s,e] = d;
                    f.startDate = s ? s.format('YYYY-MM-DD') : '';
                    f.endDate = e ? e.format('YYYY-MM-DD') : '';
                }
            }

            setFilterData({ userFilterID: sourceIds, userFilterTypeID: sourceType });
            setCurrentFilterData({ userFilterID: sourceIds, userFilterTypeID: sourceType });
            setFilterWithTimeRangeData(f);
            setCurrentFilterWithTimeRangeData(f);
            setShouldRunReport(false);
        }
    }, [endDate, financialYearStartMonth, isInitialQuerySetupFinished, selectedDivisions, selectedGroups, selectedUsers, shouldRunReport, sourceIds, sourceType, startDate, timeRange]);

    useEffect(() => console.log('filterWithTimeRangeData', filterWithTimeRangeData), [filterWithTimeRangeData]);

    useEffect(() => {
        if (isInitialQuerySetupFinished) {
            let url = window.location.pathname + generateQueryString();
            navigate(url);
        }
    }, [generateQueryString, isInitialQuerySetupFinished, navigate]);

    const onTimeRangeChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const v = +e.target.value;
        setTimeRange(v);
    }, []);

    const onSourceTypeChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const v = +e.target.value;
        setSourceType(v);
    }, []);

    const onDivisionsChange = useCallback((divisions: NameIdObj[]) => {
        setSelectedDivisions(divisions.map(u => u.id));
    }, []);

    const onGroupsChange = useCallback((groups: NameIdObj[]) => {
        setSelectedGroups(groups.map(u => u.id));
    }, []);

    const onUsersChange = useCallback((users: NameIdObj[]) => {
        setSelectedUsers(users.map(u => u.id));
    }, []);

    const handleTabChange = useCallback((e: React.SyntheticEvent, newValue: string) => {
        setActiveTab(newValue as PlacementsAverageFeesTab);
    }, []);

    const onDisplayModeToggle = useCallback(() => {
        setDisplayMode(prev => prev === 'graph' ? 'table' : 'graph');
    }, []);

    const isRunButtonEnabled = useMemo(() => {
        const isConsultantTab = activeTab === 'consultant';
        if (isConsultantTab && currentFilterWithTimeRangeData) {
            let sDate = startDate ? startDate.format('YYYY-MM-DD') : '';
            let eDate = endDate ? endDate.format('YYYY-MM-DD') : '';
    
            if (startDate === null || endDate === null) {
                const d = GetDatesFromTimeRangeFilter(timeRange, financialYearStartMonth);
                if (d) {
                    const [s,e] = d;
                    sDate = s ? s.format('YYYY-MM-DD') : '';
                    eDate = e ? e.format('YYYY-MM-DD') : '';
                }
            }
            return currentFilterWithTimeRangeData.userFilterID !== sourceIds || currentFilterWithTimeRangeData.userFilterTypeID !== sourceType || currentFilterWithTimeRangeData.startDate !== sDate || currentFilterWithTimeRangeData.endDate !== eDate;
        }
        else if (!isConsultantTab && currentFilterData) {

            return currentFilterData.userFilterID !== sourceIds || currentFilterData.userFilterTypeID !== sourceType;
        }
        return true;
    }, [activeTab, currentFilterData, currentFilterWithTimeRangeData, endDate, financialYearStartMonth, sourceIds, sourceType, startDate, timeRange]);

    return (
        <>
            <PageLayout SummaryBar={summaryBar}>
                <Tabs value={activeTab} onChange={handleTabChange}>
                    <Tab value="perm-fees" label="Perm Fees" />
                    <Tab value="contract-daily" label="Contract Margin - Daily" />
                    <Tab value="contract-hourly" label="Contract Margin - Hourly" />
                    <Tab value="consultant" label="By Consultant" />
                </Tabs>
                <PageContentLayout showLoading={isLoading} >
                    <Snackbar open={Boolean(infoMessage)} autoHideDuration={1500} onClose={() => setInfoMessage('')}>
                        <Alert severity="info" onClose={() => setInfoMessage('')}>{infoMessage}</Alert>
                    </Snackbar>
                    <Stack spacing={1}>
                        <Box display="flex" gap={1}>
                            <TextField select label="Source Type" value={sourceType.toString()} onChange={onSourceTypeChange} sx={filterElementStyle} >
                                <MenuItem value="1">Company</MenuItem>
                                <MenuItem value="2">Division</MenuItem>
                                <MenuItem value="3">Group</MenuItem>
                                <MenuItem value="4">User</MenuItem>
                            </TextField>
                            <TextField select label="Time Range" value={timeRange.toString()} onChange={onTimeRangeChange} sx={{...filterElementStyle, display: activeTab === 'consultant' ? undefined : 'none'}} >
                                <MenuItem value="0">Custom Range</MenuItem>
                                <MenuItem value="1">This Week</MenuItem>
                                <MenuItem value="2">This Month</MenuItem>
                                <MenuItem value="3">This Quarter (Calendar)</MenuItem>
                                <MenuItem value="13">This Quarter (Financial)</MenuItem>
                                <MenuItem value="4">This Year (Calendar)</MenuItem>
                                <MenuItem value="14">This Year (Financial)</MenuItem>
                                <MenuItem value="5">Last Week</MenuItem>
                                <MenuItem value="6">Last Month</MenuItem>
                                <MenuItem value="7">Last Quarter (Calendar)</MenuItem>
                                <MenuItem value="15">Last Quarter (Financial)</MenuItem>
                                <MenuItem value="8">Last Year (Calendar)</MenuItem>
                                <MenuItem value="16">Last Year (Financial)</MenuItem>
                                <MenuItem value="9">Last 7 Days</MenuItem>
                                <MenuItem value="10">Last 30 Days</MenuItem>
                                <MenuItem value="11">Last 90 Days</MenuItem>
                                <MenuItem value="12">Last 365 Days</MenuItem>
                            </TextField>
                            <DatePicker label="From Date" value={startDate} onChange={m => setStartDate(m)} disabled={timeRange !== 0} sx={{...filterElementStyle, display: activeTab === 'consultant' ? undefined : 'none'}} slotProps={{actionBar: { actions: ["clear", "today", "cancel", "accept"] }}} />
                            <DatePicker label="To Date" value={endDate} onChange={m => setEndDate(m)} disabled={timeRange !== 0} sx={{...filterElementStyle, display: activeTab === 'consultant' ? undefined : 'none'}} slotProps={{actionBar: { actions: ["clear", "today", "cancel", "accept"] }}} />
                            <Button variant="contained" onClick={onDisplayModeToggle}>Show as {displayMode === 'graph' ? 'Table' : 'Graph'}</Button>
                            <Button variant="contained" color="success" onClick={runReportCallback} disabled={!isRunButtonEnabled}>Run Report</Button>
                        </Box>
                        <Box display={sourceType === 2 ? undefined : 'none'}>
                            <MultipleDivisionsPicker value={selectedDivisions} onSelect={onDivisionsChange} />
                        </Box>
                        <Box display={sourceType === 3 ? undefined : 'none'}>
                            <MultipleGroupPicker value={selectedGroups} onSelect={onGroupsChange} />
                        </Box>
                        <Box display={sourceType === 4 ? undefined : 'none'}>
                            <MultipleUserPicker value={selectedUsers} onSelect={onUsersChange} />
                        </Box>
                    </Stack>
                    {filterData && activeTab === 'perm-fees' && <PlacementsAveragePermFeesContent filterData={filterData} graphType={displayMode} loadingHandler={setIsLoading} /> }
                    {filterData && activeTab === 'contract-daily' && <PlacementsAverageDailyContractMarginContent filterData={filterData} graphType={displayMode} loadingHandler={setIsLoading} /> }
                    {filterData && activeTab === 'contract-hourly' && <PlacementsAverageHourlyContractMarginContent filterData={filterData} graphType={displayMode} loadingHandler={setIsLoading} /> }
                    {filterWithTimeRangeData && activeTab === 'consultant' && <PlacementsAverageByConsultantContent filterData={filterWithTimeRangeData} graphType={displayMode} loadingHandler={setIsLoading} /> }
                </PageContentLayout>
            </PageLayout>
        </>
    );
}