import IconButton from "@mui/material/IconButton";
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import { GridColDef, GridRenderCellParams, GridRowId, GridValueGetter, GridRowSelectionModel, gridPaginatedVisibleSortedGridRowEntriesSelector, useGridApiRef, GridColumnVisibilityModel } from "@mui/x-data-grid-premium";
import { CandidatePositionTracker } from "common/models/CandidatePositionTracker";
import { CandidateList } from "common/models/Candidates/CandidateList";
import { RecordExportData } from "common/models/Common";
import { ActivitySettings } from "common/models/Configuration/Activities";
import { EmailRecipient } from "common/models/EmailRecipient";
import { Permission } from "common/models/Permissions";
import { PreviewEntityType } from "common/models/Previews/Previews";
import { CandidateSearch } from "common/models/Search/CandidateSearch";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import {Link, useSearchParams} from "react-router-dom";
import { SearchCandidates, UpdateCandidateJobRating_Bulk, UpdateCandidateSavedSearchRating_Bulk } from "services/CandidatesService";
import { GetActivitiesSettings, GetCachedRatingReasons, GetGeneralSettings } from "services/ConfigurationService";
import { companyHasSinglePermission, userHasSinglePermission } from "util/PermissionsUtils";
import { GetPhoneLink, GetPhoneLinkWithStandardized } from "util/CommonUtils";
import { GetMarketingPlatformsAvailable, GetMySettings } from "services/UsersService";
import { GetIntegration } from "services/CustomerIntegrationsService";
import { MenuOptionDefinition } from "common/models/MenuDefinition";
import ActionMenu from "components/Menus/ActionMenu";
import PreviewLoaderComponent from "components/Previews/PreviewLoader";
import TagsManagementDialog from "components/Dialogs/TagsManagementDialog";
import ExportRecordsDialog from "components/Dialogs/ExportRecordsDialog";
import { Box } from "@mui/system";
import EditActivityDialog from "components/Dialogs/Activities/EditActivityDialog";
import LinkCandidatesToJobDialog from "components/Dialogs/Candidates/LinkCandidatesToJobDialog";
import MergeCandidatesDialog from "components/Dialogs/Candidates/MergeCandidatesDialog";
import ReferooActionsDialog from "components/Dialogs/Candidates/ReferooActionsDialog";
import ExportRecordsToCampaignMonitorDialog from "components/Dialogs/ExportRecordsToCampaignMonitorDialog";
import ExportRecordsToMailchimpDialog from "components/Dialogs/ExportRecordsToMailchimpDialog";
import SendEmailDialog from "components/Dialogs/Messages/SendEmailDialog";
import SendSmsDialog from "components/Dialogs/Messages/SendSmsDialog";
import SendReferralDialog from "components/Dialogs/SendReferralDialog";
import GridWithStateWrapper from "components/GridWidthStateWrapper";
import { DefaultActivity } from "util/Definitions/Activities";
import CandidateRatingIcon from "components/Candidates/CandidateRatingIcon";
import { RatingReason } from "common/models/Configuration/Jobs";
import useWorkflowOrder from "hooks/UseWorkflowOrder";
import DigitalReferenceCheckDialog from "components/Dialogs/Candidates/DigitalReferenceCheckDialog";
import { MeetingType } from "common/models/Configuration/MeetingType";
import { InterviewType } from "common/models/Configuration/InterviewType";
import { GetActiveMeetingTypes } from "services/MeetingsService";
import { GetActiveInterviewTypes } from "services/InterviewsService";

interface Props {
    searchData?: CandidateSearch,
    gridName: string,
    lookupTerm?: string,
    scrollToId?: number,
    setRowCountHandler?: (count: number) => void,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
    successHandler?: (message: string) => void,
}

const compareRatingReasons = (a: RatingReason, b: RatingReason) => {
    if (b.value < a.value) return 1;
    if (b.value > a.value) return -1;
    return 0;
};

const linkStyle: React.CSSProperties = { color: 'inherit', textDecoration: 'underline' };
const entityTypeId = 3;

const defaultHiddenCols: GridColumnVisibilityModel = {
    'id': false,
    'emailAddress': false,
    'mobile': false,
    'preferredSalary': false,
    'preferredRate': false,
    'lastActivityDate': false,
    'lastContactDate': false,
};

export default function CandidatesSavedSearchGridComponent({ gridName, searchData, scrollToId, setRowCountHandler, loadingHandler, errorHandler, successHandler }: Props) {
    const [rows, setRows] = useState<CandidateList[]>([]);
    const [activitySettings, setActivitySettings] = useState<ActivitySettings[]>([]);
    const [selectedActivity, setSelectedActivity] = useState<ActivitySettings | null>(null);
    const [meetingTypes, setMeetingTypes] = useState<MeetingType[]>([]);
    const [interviewTypes, setInterviewTypes] = useState<InterviewType[]>([]);
    const [showSendReferralDialog, setShowSendReferralDialog] = useState(false);
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    const [showTagsPrevewNoDelay, setShowTagsPreviewNoDelay] = useState(false);
    const [previewType, setPreviewType] = useState<PreviewEntityType | ''>('');
    const [previewRecordId, setPreviewRecordId] = useState(0);
    const [isPreviewTags, setIsPreviewTags] = useState(false);
    const [previewRecordName, setPreviewRecordName] = useState('');
    const [hasMailChimpAccess, setHasMailChimpAccess] = useState(false);
    const [hasCampaignMonitorAccess, setHasCampaignMonitorAccess] = useState(false);
    const [isReferooConnected, setIsReferooConnected] = useState(false);
    const [showReferooDialog, setShowReferooDialog] = useState(false);
    const [showLinkCandidateToJobDialog, setShowLinkCandidateToJobDialog] = useState(false);
    const [sendMessageDialogType, setSendMessageDialogType] = useState<'email' | 'sms' | 'marketing-list' | null>(null);
    const [exportRecordDialogType, setExportRecordDialogType] = useState<'mailchimp' | 'campaign-monitor' | null>(null);
    const [messageRecipients, setMessageRecipients] = useState<EmailRecipient[]>([]);
    const [exportRecords, setExportRecords] = useState<RecordExportData[]>([]);
    const [taggingRecords, setTaggingRecords] = useState<number[]>([]);
    const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
    const [mergeRecords, setMergeRecords] = useState<CandidateList[]>([]);
    const [greenReasons, setGreenReasons] = useState<RatingReason[]>([]);
    const [yellowReasons, setYellowReasons] = useState<RatingReason[]>([]);
    const [blueReasons, setBlueReasons] = useState<RatingReason[]>([]);
    const [redReasons, setRedReasons] = useState<RatingReason[]>([]);
    const [defaultRejectionTemplateId, setDefaultRejectionTemplateId] = useState(0);
    const apiRef = useGridApiRef();
    const navigate = useNavigate();
    const workflowOrder = useWorkflowOrder();
    const [searchParams] = useSearchParams();
    const [showDigitalReferenceCheckDialog, setShowDigitalReferenceCheckDialog] = useState(false);
    const [onlyDataAdminsCanMerge, setOnlyDataAdminsCanMerge] = useState(false);

    const canExportCandidates = useMemo(() => companyHasSinglePermission(Permission.CandidatesExport) && userHasSinglePermission(Permission.CandidatesExport), []);
    const userCanAddEditCandidates = useMemo(() => userHasSinglePermission(Permission.CandidatesAddEdit) && companyHasSinglePermission(Permission.CandidatesAddEdit), []);
    const userCanEmailMarket = useMemo(() => userHasSinglePermission(Permission.EmailMarketing) && companyHasSinglePermission(Permission.EmailMarketing), []);
    const userCanSendEmail = useMemo(() => userHasSinglePermission(Permission.MessageCentre) && companyHasSinglePermission(Permission.MessageCentre), []);
    const userCanSendSms = useMemo(() => userHasSinglePermission(Permission.MessageCentreSms) && companyHasSinglePermission(Permission.MessageCentreSms), []);
    const isUserDataMaintenanceAdmin = useMemo(() => userHasSinglePermission(Permission.DataMaintenanceAdministrator), []);

    const userCanMergeRecords = useMemo(() => {
        if (onlyDataAdminsCanMerge && !isUserDataMaintenanceAdmin) return false;
        return true;
    }, [isUserDataMaintenanceAdmin, onlyDataAdminsCanMerge]);

    useEffect(() => {
        const getSettings = async () => {
            const mySettings = await GetMySettings();
            if (mySettings) {
                const templateSetting = mySettings.find(s => s.type === 'DefaultRejectionEmailTemplateID');
                if (templateSetting && templateSetting.value !== '0') setDefaultRejectionTemplateId(+templateSetting.value);
            }
        };
        getSettings();
    }, []);

    const getData = useCallback(async () => {
        if (searchData) {
            loadingHandler && loadingHandler(true);
            const res = await SearchCandidates(searchData, errorHandler);
            if (res) {
                setRowCountHandler && setRowCountHandler(res.length)
                setRows(res);
            }
            loadingHandler && loadingHandler(false);
        }
    }, [searchData, setRowCountHandler, loadingHandler, errorHandler]);

    useEffect(() => {
        getData();
    }, [getData]);

    useEffect(() => {
        const getMeetingTypes = async () => {
            const res = await GetActiveMeetingTypes(entityTypeId);
            if (res) setMeetingTypes(res);
        };
        getMeetingTypes();
    }, []);

    useEffect(() => {
        const getInterviewTypes = async () => {
            const res = await GetActiveInterviewTypes();
            if (res) setInterviewTypes(res);
        };
        getInterviewTypes();
    }, []);

    useEffect(() => {
        const getGeneralSettings = async () => {
            const res = await GetGeneralSettings(errorHandler);
            if (res) {
                const setting = res.find(s => s.type === 'WhoCanMergeRecords');
                if (setting) setOnlyDataAdminsCanMerge(setting.value === 'DataMaintenanceAdministrator');
            }
        };
        getGeneralSettings();
    }, [errorHandler]);

    useEffect(() => {
        const getActivitySettings = async () => {
            const res = await GetActivitiesSettings(3, undefined, errorHandler);
            if (res) setActivitySettings(res);

            let green: RatingReason[] = [];
            let yellow: RatingReason[] = [];
            let blue: RatingReason[] = [];
            let red: RatingReason[] = [];
            const ratingReasons = await GetCachedRatingReasons(false);
            if (ratingReasons) {
                for (let i = 0; i < ratingReasons.length; i++) {
                    const rr = ratingReasons[i];
                    if (rr.actionType === 4) green.push(rr);
                    else if(rr.actionType === 2) yellow.push(rr);
                    else if(rr.actionType === 1) blue.push(rr);
                    else if(rr.actionType === 0) red.push(rr);
                }
                green.sort(compareRatingReasons);
                yellow.sort(compareRatingReasons);
                blue.sort(compareRatingReasons);
                red.sort(compareRatingReasons);
                setGreenReasons(green);
                setYellowReasons(yellow);
                setBlueReasons(blue);
                setRedReasons(red);
            }
        };
        getActivitySettings();
    }, [errorHandler]);

    const ratingChangedHandler = useCallback((rowId: number, newRatingId: number, reason: string) => {
        const api = apiRef.current;
        if (api) {
            api.updateRows([{ id: rowId, ratingID: newRatingId }]);
        }
    }, [apiRef]);

    const columns = useMemo<GridColDef[]>(() => {
        const handlePreviewHover = (type: PreviewEntityType | '', id: number, isTags: boolean = false, recordName: string = '', noDelay: boolean = false) => {
            setPreviewType(type);
            setPreviewRecordId(id);
            setIsPreviewTags(isTags);
            setPreviewRecordName(recordName);
            setIsPreviewOpen(true);
            setShowTagsPreviewNoDelay(noDelay);
        };

        const handlePreviewClose = () => {
            setIsPreviewOpen(false);
            setShowTagsPreviewNoDelay(false);
        };

        const dateValueGetter: GridValueGetter<CandidateList, any, undefined, string> = (value) => {
            if (value) {
                const m = moment(value);
                if (m.isValid() && m.get('year') > 1) {
                    return m.toDate();
                }
            }
        };

        const dateRenderer = (params: GridRenderCellParams) => {
            if (params.value) {
                return moment(params.value).format('DD MMM YYYY');
            }
            return 'Never';
        };

        const linkToCandidateRenderer = (params: GridRenderCellParams) => {
            if (params.id) {
                const jId = searchData && searchData.jobId ? searchData.jobId : 0;
                const ssid = searchData && searchData.ssid ? searchData.ssid : 0;
                const qParams = new URLSearchParams();
                qParams.set('jobId', jId.toString());
                qParams.set('ssid', ssid.toString());
                return (
                    <Link
                        to={`/candidates/${params.id}?${qParams.toString()}`}
                        style={linkStyle}
                        onMouseEnter={() => handlePreviewHover('candidate', params.row.id)}
                        onMouseLeave={handlePreviewClose}
                        onClick={(e) => handleCandidateClick(e, params.row.id, params.row.jobID)}
                    >{params.value}</Link>
                );
            }
            return params.value;
        };

        const nameValueGetter: GridValueGetter<CandidateList, any, undefined, string> = (value) => {
            if (value && value.trim() !== '') {
                return value;
            }

            return '-----';
        };

        const makeId = (length: number) => {
            let result = '';
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            const charactersLength = characters.length;
            let counter = 0;
            while (counter < length) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
                counter += 1;
            }
            return result;
        };

        const handleCandidateClick = (e: React.MouseEvent<HTMLElement>, candidateId: number, jobId: number) => {
            e.preventDefault();

            let jId = 0;
            let sId = 0;

            if (searchData) {
                jId = searchData.jobId ?? 0;
            }
            
            if(searchParams.get('ssid'))
            {
                let id = searchParams.get('ssid');
                if(id)
                    sId = +id;
            }

            let dataId = makeId(10);

            var rows = apiRef.current.getAllRowIds();

            let maxIndex = rows.length - 1;

            let mainCandidateIndex = apiRef.current.getRowIndexRelativeToVisibleRows(candidateId);
            mainCandidateIndex = mainCandidateIndex + (apiRef.current.state.pagination.paginationModel.page * apiRef.current.state.pagination.paginationModel.pageSize);
            let mainCandidatePrevIndex = mainCandidateIndex - 1;
            let mainCandidateNextIndex = mainCandidateIndex + 1;

            let mainCandidatePrevCandidateId = "0";
            let mainCandidateNextCandidateId = "0";

            if (mainCandidatePrevIndex >= 0)
                mainCandidatePrevCandidateId = apiRef.current.getRowIdFromRowIndex(mainCandidatePrevIndex).toString();

            if (mainCandidateNextIndex <= maxIndex)
                mainCandidateNextCandidateId = apiRef.current.getRowIdFromRowIndex(mainCandidateNextIndex).toString();

            let collection: CandidatePositionTracker[] = [];

            rows.forEach((element: GridRowId) => {

                let candidateId: number = element as number;

                let currentIndex = apiRef.current.getRowIndexRelativeToVisibleRows(candidateId);
                currentIndex = currentIndex + (apiRef.current.state.pagination.paginationModel.page * apiRef.current.state.pagination.paginationModel.pageSize);
                let prevIndex = currentIndex - 1;
                let nextIndex = currentIndex + 1;

                let prevCandidateId: number = 0;
                let nextCandidateId: number = 0;

                if (prevIndex >= 0)
                    prevCandidateId = apiRef.current.getRowIdFromRowIndex(prevIndex) as number;

                if (nextIndex <= maxIndex)
                    nextCandidateId = apiRef.current.getRowIdFromRowIndex(nextIndex) as number;

                let info: CandidatePositionTracker = { candidateId, prevCandidateId, nextCandidateId };

                collection.push(info)
            });

            let val = JSON.stringify(collection);
            localStorage.setItem(dataId, val);

            navigate(`/candidates/${candidateId}?jobId=${jId}&ssid=${sId}&dataId=${dataId}&prevCandidateId=${mainCandidatePrevCandidateId}&nextCandidateId=${mainCandidateNextCandidateId}`);
        };

        const emailLinkRenderer = (params: GridRenderCellParams) => {
            const email = params.value;
            if (email) {
                return <a href={`mailto:${email}`} style={linkStyle}>{email}</a>;
            }
        };

        const tagsRenderer = (params: GridRenderCellParams) => {
            if (userCanAddEditCandidates) {
                return (
                    <IconButton
                        size="small"
                        onMouseEnter={ () => handlePreviewHover('candidate', params.row.id, true, params.row.fullName) }
                        onMouseLeave={ handlePreviewClose }
                        onClick={ () => setTaggingRecords([params.row.id]) }
                    >
                        {params.value
                            ? <LocalOfferIcon style={{ color: '#f209a6' }} />
                            : <LocalOfferOutlinedIcon />
                        }
                    </IconButton>
                );
            }
            return (
                <IconButton
                    size="small"
                    onMouseEnter={() => handlePreviewHover('candidate', params.row.id, true, params.row.fullName)}
                    onMouseLeave={handlePreviewClose}
                    onClick={() => handlePreviewHover('candidate', params.row.id, true, params.row.fullName, true)}
                >
                    {params.value
                        ? <LocalOfferIcon />
                        : <LocalOfferOutlinedIcon />
                    }
                </IconButton>
            );
        };

        const phoneLinkRenderer = (params: GridRenderCellParams) => {
            if (params.row.mobile_standardized) {
                return GetPhoneLinkWithStandardized(params.row.mobile, params.row.mobile_standardized);     
            }
            return GetPhoneLink(params.row.mobile, false);  
        };

        const ratingIdRenderer = (params: GridRenderCellParams) => {
            if (searchData) {
                const candidateId = params.row.id;
                const jId = searchData.jobId ?? 0;
                const ssid = searchData.ssid ?? 0;
                return (
                    <CandidateRatingIcon
                        size={30}
                        candidateId={candidateId}
                        jobId={jId}
                        ssid={ssid}
                        actionId={params.value}
                        useRatingReasonsProps
                        greenReasonsProp={greenReasons}
                        yellowReasonsProp={yellowReasons}
                        blueReasonsProp={blueReasons}
                        redReasonsProp={redReasons}
                        defaultRejectionTemplateId={defaultRejectionTemplateId}
                        onActionChangeHandler={ (rId, rr) => ratingChangedHandler(+params.id, rId, rr)}
                        horizontal
                        errorHandler={errorHandler}
                        loadingHandler={loadingHandler}
                        successHandler={successHandler}
                        fetchApplicationIdOnRejection
                    />
                );
            }
            return <></>;
        };

        const numberRenderer = (params: GridRenderCellParams) => {
            if (typeof params.value === "number")
                return params.value.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 });
        };
        
        return [
            { headerName: 'ID', field: 'id', width: 75, renderCell: linkToCandidateRenderer },
            { headerName: 'Rating', field: 'ratingID', renderCell: ratingIdRenderer },
            { headerName: 'Name', field: 'fullName', width: 250, valueGetter: nameValueGetter, renderCell: linkToCandidateRenderer },
            { headerName: 'Email', field: 'emailAddress', headerAlign: 'center', align: 'center', width: 250, renderCell: emailLinkRenderer },
            { headerName: 'Mobile', field: 'mobile', headerAlign: 'center', align: 'center', width: 250, renderCell: phoneLinkRenderer },
            { headerName: 'Tags', field: 'tags', width: 70, headerAlign: 'center', align: 'center', renderCell: tagsRenderer, editable: false },
            { headerName: 'Job Title', field: 'jobTitle', width: 200 },
            { headerName: 'Employer', field: 'employer', width: 200 },
            { headerName: 'Location', field: 'location', width: 200 },
            { headerName: 'Job Type', field: 'preferredEmploymentType', headerAlign: 'center', align: 'center', width: 150 },
            { headerName: 'Available', field: 'available', headerAlign: 'center', align: 'center', width: 125 },
            { headerName: 'Preferred Salary', field: 'preferredSalary', headerAlign: 'center', align: 'center', width: 125 },
            { headerName: 'Preferred Rate', field: 'preferredRate', headerAlign: 'center', align: 'center', width: 125 },
            { headerName: 'Last Updated', field: 'compareDate', headerAlign: 'center', align: 'center', width: 125, valueGetter: dateValueGetter, renderCell: dateRenderer, type: 'date', },
            { headerName: 'Last Activity', field: 'lastActivityDate', headerAlign: 'center', align: 'center', width: 125, valueGetter: dateValueGetter, renderCell: dateRenderer, type: 'date' },
            { headerName: 'Last Contact', field: 'lastContactDate', headerAlign: 'center', align: 'center', width: 125, valueGetter: dateValueGetter, renderCell: dateRenderer, type: 'date' },
            { headerName: 'Score', field: 'score', width: 100, renderCell: numberRenderer },
        ];
    }, [apiRef, blueReasons, defaultRejectionTemplateId, errorHandler, greenReasons, loadingHandler, navigate, ratingChangedHandler, redReasons, searchData, searchParams, successHandler, userCanAddEditCandidates, yellowReasons]);

    useEffect(() => {
        const getData = async () => {
            const res = await GetMarketingPlatformsAvailable();
            if (res) {
                setHasMailChimpAccess(res.mailChimp);
                setHasCampaignMonitorAccess(res.campaignMonitor);
            }
            const referoo = await GetIntegration(1);
            if (referoo) setIsReferooConnected(referoo.statusID === 1);
        };
        userCanEmailMarket && getData();
    }, [userCanEmailMarket]);

    const sendMessageCallback = useCallback((type: 'email' | 'sms' | 'marketing-list', idKey: keyof CandidateList, nameKey: keyof CandidateList, emailOrPhoneKey: keyof CandidateList) => {
        if (selectionModel.length > 0) {
            let recipients: EmailRecipient[] = [];
            for (let i = 0; i < selectionModel.length; i++) {
                const id = selectionModel[i];
                const row = rows.find(r => r.id === +id.valueOf());
                if (row) {
                    const recipientId = row[idKey] as number;
                    const recipientName = row[nameKey] as string;
                    const emailOrPhone = row[emailOrPhoneKey] as string;
                    const email = type === 'email' || type === 'marketing-list' ? emailOrPhone : '';
                    const phone = type === 'sms' ? emailOrPhone : '';

                    recipients.push({ id: recipientId, email: email, name: recipientName, mobile: phone, candidateList: row });
                }
            }
            setSendMessageDialogType(type);
            setMessageRecipients(recipients);
        }
    }, [selectionModel, rows]);

    const exportToMailchimpOrCampaignMonitorCallback = useCallback((dialogType: 'mailchimp' | 'campaign-monitor') => {
        if (selectionModel.length > 0) {
            let exportRecordsData: RecordExportData[] = [];
            for (let i = 0; i < selectionModel.length; i++) {
                const id = selectionModel[i];
                const row = rows.find(r => r.id === +id);
                if (row) {
                    exportRecordsData.push({
                        id: row.id,
                        email: row.emailAddress,
                        firstName: row.firstName,
                        lastName: row.lastName,
                        isOptOut: row.optOut,
                        candidate: row
                    });
                }
            }
            setExportRecordDialogType(dialogType);
            setExportRecords(exportRecordsData);
        }
    }, [selectionModel, rows]);

    const mergeRecordsHandler = useCallback(() => {
        let selectedCandidates: CandidateList[] = [];
        for (let i = 0; i < selectionModel.length; i++) {
            const candidateId = selectionModel[i];
            const candidate = rows.find(s => s.id === candidateId.valueOf());
            if (candidate) selectedCandidates.push(candidate);
        }
        setMergeRecords(selectedCandidates);
    }, [rows, selectionModel]);

    const exportToExcel = useCallback(() => {
        const api = apiRef.current;
        if (api) api.exportDataAsExcel();
    }, [apiRef]);

    const actionMenuDefinitions = useMemo<MenuOptionDefinition[]>(() => {
        const noRecordSelected = selectionModel.length === 0;
        const notEnoughForMerge = selectionModel.length < 2;
        const hasMarketingPlatform = hasMailChimpAccess || hasCampaignMonitorAccess;
        const contactIdsQuery = encodeURIComponent(selectionModel.join(','));

        let actions: MenuOptionDefinition[] = [
            { label: 'Export', type: 'action', action: exportToExcel, allow: () => canExportCandidates },
            { label: 'Export Marketing List', type: 'action', disabled: noRecordSelected, allow: () => userCanEmailMarket && !hasMarketingPlatform, action: () => sendMessageCallback('marketing-list', 'id', 'fullName', 'emailAddress') },
            { label: 'Export to Mailchimp', type: 'action', disabled: noRecordSelected, allow: () => userCanEmailMarket && hasMailChimpAccess, action: () => exportToMailchimpOrCampaignMonitorCallback('mailchimp') },
            { label: 'Export to Campaign Monitor', type: 'action', disabled: noRecordSelected, allow: () => userCanEmailMarket && hasCampaignMonitorAccess, action: () => exportToMailchimpOrCampaignMonitorCallback('campaign-monitor') },
            { label: 'Merge', type: 'action', disabled: notEnoughForMerge, allow: () => userCanAddEditCandidates && userCanMergeRecords, action: mergeRecordsHandler },
            { label: 'Send Email', type: 'action', disabled: noRecordSelected, allow: () => userCanSendEmail, action: () => sendMessageCallback('email', 'id', 'fullName', 'emailAddress') },
            { label: 'Send SMS', type: 'action', disabled: noRecordSelected, allow: () => userCanSendSms, action: () => sendMessageCallback('sms', 'id', 'fullName', 'mobile_Standardized') },
            { label: 'Bulk Tag', type: 'action', disabled: noRecordSelected, allow: () => userCanAddEditCandidates, action: () => setTaggingRecords(selectionModel as number[]) },
            { label: 'Link To Job', type: 'action', disabled: noRecordSelected, action: () => setShowLinkCandidateToJobDialog(true) },
        ];

        for (let i = 0; i < activitySettings.length; i++) {
            const setting = activitySettings[i];
            if (setting.category === 2) continue;
            if (setting.workflowStatus === 0) {
                if (setting.id === 23)
                    actions.push({ label: setting.customName, type: 'action', disabled: selectionModel.length !== 1, action: () => setShowSendReferralDialog(true) });
                else
                    actions.push({ label: setting.customName, type: 'action', disabled: selectionModel.length !== 1, action: () => setSelectedActivity(setting) });
            }
        }

        let meetingActions: MenuOptionDefinition = {
            label: 'Meetings', type: 'parent', subMenu: []
        };

        if (meetingTypes.length > 0 && meetingActions.subMenu) {
            for (let i = 0; i < meetingTypes.length; i++) {
                const t = meetingTypes[i];
                const href = `/meetings/create?contactIds=${contactIdsQuery}&typeId=${t.id}&source=${entityTypeId}`;
                meetingActions.subMenu.push({ label: t.name, type: 'link', href: href });
            }
        }

        if (meetingActions.subMenu && meetingActions.subMenu.length > 0) actions.push(meetingActions);

        return actions;
    }, [selectionModel, hasMailChimpAccess, hasCampaignMonitorAccess, exportToExcel, mergeRecordsHandler, meetingTypes, canExportCandidates, userCanEmailMarket, sendMessageCallback, exportToMailchimpOrCampaignMonitorCallback, userCanAddEditCandidates, userCanMergeRecords, userCanSendEmail, userCanSendSms, activitySettings]);

    const bulkRateCallback = useCallback(async (actionId: number, reason: string) => {
        if (searchData) {
            loadingHandler && loadingHandler(true);
            const ssid = searchData.ssid ?? 0;
            const jId = searchData.jobId ?? 0;
            let ssRes: boolean | null = null;
            let jRes: boolean | null = null;
            if (jId) jRes = await UpdateCandidateJobRating_Bulk(selectionModel as number[], jId, actionId, reason, errorHandler);
            if (ssid) ssRes = await UpdateCandidateSavedSearchRating_Bulk(selectionModel as number[], ssid, actionId, errorHandler);
            if (jRes || ssRes) {
                successHandler && successHandler('Record(s) rating changed');
                const api = apiRef.current;
                if (api) api.updateRows(selectionModel.map(id => ({ id: id, ratingID: actionId })));
            }
            loadingHandler && loadingHandler(false);
        }
    }, [apiRef, errorHandler, loadingHandler, searchData, selectionModel, successHandler]);

    const bulkRateActions = useMemo<MenuOptionDefinition>(() => {
        const yellowOptions: MenuOptionDefinition[] = yellowReasons.map<MenuOptionDefinition>(r => ({ label: r.value, type: 'action', action: () => bulkRateCallback(2, r.value) })); // 2
        const blueOptions: MenuOptionDefinition[] = blueReasons.map<MenuOptionDefinition>(r => ({ label: r.value, type: 'action', action: () => bulkRateCallback(1, r.value) })); // 1
        const redOptions: MenuOptionDefinition[] = redReasons.map<MenuOptionDefinition>(r => ({ label: r.value, type: 'action', action: () => bulkRateCallback(0, r.value) })); // 0

        return { label: 'Rate Candidate(s)', type: 'parent', disabled: selectionModel.length === 0, orderIndex: -1, subMenu: [
            { label: 'Grey', type: 'action', action: () => bulkRateCallback(3, '') },
            { label: 'Yellow', type: yellowReasons.length > 0 ? 'parent' : 'action', subMenu: yellowOptions, action: () => bulkRateCallback(2, '') },
            { label: 'Blue', type: blueReasons.length > 0 ? 'parent' : 'action', subMenu: blueOptions, action: () => bulkRateCallback(1, '') },
            { label: 'Red', type: redReasons.length > 0 ? 'parent' : 'action', subMenu: redOptions, action: () => bulkRateCallback(0, '') },
        ] };
    }, [blueReasons, bulkRateCallback, redReasons, selectionModel.length, yellowReasons]);

    const workflowMenuDefinitions = useMemo<MenuOptionDefinition[]>(() => {
        const selectedCount = selectionModel.length;
        const firstCandidateId = selectedCount > 0 ? +selectionModel[0] : 0;
        
        let workflow: MenuOptionDefinition[] = [
            { label: 'Referoo', type: 'action', disabled: selectedCount !== 1, allow: () => isReferooConnected, action: () => setShowReferooDialog(true) },
        ];

        for (let i = 0; i < activitySettings.length; i++) {
            const s = activitySettings[i];
            if (s.workflowStatus === 0 || s.id === 31) continue;
            if (s.id === 30) {
                workflow.push({ label: s.customName, type: 'link', disabled: selectedCount !== 1, href: `/candidates/${firstCandidateId}/reference-check`, orderIndex: workflowOrder.refCheckIndex });
                workflow.push(  { label: 'Digital Reference Check', type: 'action', disabled: selectedCount !== 1, action: () => setShowDigitalReferenceCheckDialog(true) });
            }
            if (s.id === 10)
                workflow.push({ label: s.customName, type: 'link', disabled: selectedCount !== 1, href: `/candidates/${firstCandidateId}/screening`, orderIndex: workflowOrder.indexes[s.workflowStatus] });
        }

        let interviewActions: MenuOptionDefinition = { label: 'Interviews', type: 'parent', subMenu: [], orderIndex: workflowOrder.interviewIndex };
        if (interviewTypes.length > 0 && interviewActions.subMenu) {
            for (let i = 0; i < interviewTypes.length; i++) {
                const t = interviewTypes[i];
                const href = `/interviews/create?typeId=${t.id}&candidateId=${firstCandidateId}`;
                interviewActions.subMenu.push({ label: t.name, type: 'link', href: href, disabled: selectedCount !== 1 });
            }
        }

        if (interviewActions.subMenu && interviewActions.subMenu.length > 0) {
            interviewActions.subMenu.sort((a,b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));
            workflow.push(interviewActions);
        }

        workflow.push(bulkRateActions)

        return workflow;
    }, [selectionModel, workflowOrder.interviewIndex, workflowOrder.indexes, workflowOrder.refCheckIndex, interviewTypes, bulkRateActions, isReferooConnected, activitySettings]);
    
    const gridActions = useMemo(() => {
        return <ActionMenu color="secondary" label="List Actions" definition={actionMenuDefinitions} />;
    }, [actionMenuDefinitions]);

    const gridWorkflowActions = useMemo(() => {
        return <ActionMenu color="primary" label="Workflow" mr="5px" definition={workflowMenuDefinitions} sortType={workflowOrder.sortType} />;
    }, [workflowMenuDefinitions, workflowOrder.sortType]);

    const tagManagementSuccessHandler = useCallback((message: string, recordIds: number[], finalTagCount: number) => {
        successHandler && successHandler(message);
        const api = apiRef.current;
        if (api) {
            const hasTags = finalTagCount > 0;
            api.updateRows(recordIds.map(id => ({ id: id, tags: hasTags })));
        }
    }, [apiRef, successHandler]);

    const selectedSingleCandidate = useMemo<CandidateList | null>(() => {
        if (selectionModel.length === 1) {
            const [id] = selectionModel;
            const candidate = rows.find(c => c.id === +id);
            if (candidate) return candidate;
        }
        return null;
    }, [selectionModel, rows]);

    useEffect(() => {
        const api = apiRef.current;
        if (scrollToId && api && rows.length > 0) {
            const scrollAndFocus = setTimeout(() => {
                const allRows = gridPaginatedVisibleSortedGridRowEntriesSelector(apiRef);
                const rowIndex = allRows.findIndex(r => r.id === scrollToId);
                api.setCellFocus(scrollToId, 'fullName');
                api.scrollToIndexes({ rowIndex });
            }, 1000);

            return () => clearTimeout(scrollAndFocus);
        }
    }, [apiRef, rows.length, scrollToId]);

    return (
        <>
            <PreviewLoaderComponent
                open={isPreviewOpen}
                entityType={previewType}
                recordId={previewRecordId}
                isTagsPreview={isPreviewTags}
                showDelayMs={showTagsPrevewNoDelay ? 0 : undefined}
                titleOverride={previewRecordName}
            />
            <TagsManagementDialog
                open={ taggingRecords.length > 0 }
                closeHandler={ () => setTaggingRecords([]) }
                entityId={ entityTypeId }
                recordIds={ taggingRecords }
                loadingHandler={ loadingHandler }
                errorHandler={ errorHandler }
                successHandler={ tagManagementSuccessHandler }
            />
            <ExportRecordsDialog
                open={ sendMessageDialogType === 'marketing-list' }
                closeHandler={ () => setSendMessageDialogType(null) }
                entityType="candidates"
                filename="Candidates"
                records={messageRecipients}
                includeTimeStamp
            />
            <ExportRecordsToMailchimpDialog
                open={exportRecordDialogType === 'mailchimp'}
                closeHandler={() => setExportRecordDialogType(null)}
                records={exportRecords}
                loadingHandler={loadingHandler}
                successHandler={successHandler}
                errorHandler={errorHandler}
            />
            <ExportRecordsToCampaignMonitorDialog
                open={exportRecordDialogType === 'campaign-monitor'}
                closeHandler={() => setExportRecordDialogType(null)}
                records={exportRecords}
                loadingHandler={loadingHandler}
                successHandler={successHandler}
                errorHandler={errorHandler}
            />
            <SendEmailDialog
                open={ sendMessageDialogType === 'email' }
                sourceEntityId={entityTypeId}
                recipients={ messageRecipients }
                recipientEntityTypeId={ entityTypeId }
                closeHandler={ () => setSendMessageDialogType(null) }
                loadingHandler={loadingHandler}
                errorHandler={errorHandler}
                successHandler={successHandler}
            />
            <SendSmsDialog
                open={ sendMessageDialogType === 'sms' }
                sourceEntityId={entityTypeId}
                recipients={ messageRecipients }
                recipientEntityTypeId={ entityTypeId }
                closeHandler={ () => setSendMessageDialogType(null) }
                loadingHandler={loadingHandler}
                errorHandler={errorHandler}
                successHandler={successHandler}
            />
            <MergeCandidatesDialog
                open={mergeRecords.length > 1}
                candidates={mergeRecords}
                closeHandler={() => setMergeRecords([])}
                errorHandler={errorHandler}
                loadingHandler={loadingHandler}
                successHandler={successHandler}
            />
            <EditActivityDialog
                closeHandler={() => setSelectedActivity(null)}
                data={selectedSingleCandidate && selectedActivity 
                    ? {
                        ...DefaultActivity,
                        candidateID: selectedSingleCandidate.id,
                        candidateName: selectedSingleCandidate.fullName,
                        category: 1,
                        type: selectedActivity.customName,
                        typeID: selectedActivity.id
                        } 
                    : null
                }
                open={ Boolean(selectedActivity) && Boolean(selectedSingleCandidate) }
                loadingHandler={loadingHandler}
                errorHandler={errorHandler}
                successHandler={successHandler}
            />
            <SendReferralDialog
                open={showSendReferralDialog && Boolean(selectedSingleCandidate)}
                candidateId={selectedSingleCandidate ? selectedSingleCandidate.id : 0}
                candidateName={selectedSingleCandidate ? selectedSingleCandidate.fullName : ''}
                closeHandler={ () => setShowSendReferralDialog(false) }
                loadingHandler={loadingHandler}
                errorHandler={errorHandler}
                successHandler={successHandler}
            />
            <LinkCandidatesToJobDialog
                open={showLinkCandidateToJobDialog && selectionModel.length > 0}
                closeHandler={() => setShowLinkCandidateToJobDialog(false)}
                candidateIds={selectionModel as number[]}
                loadingHandler={loadingHandler}
                errorHandler={errorHandler}
                successHandler={successHandler}
            />
            <ReferooActionsDialog
                open={showReferooDialog}
                candidateId={selectionModel.length === 1 ? +selectionModel[0] : 0}
                closeHandler={() => setShowReferooDialog(false)}
                loadingHandler={loadingHandler}
                errorHandler={errorHandler}
                successHandler={successHandler}
            />
            <DigitalReferenceCheckDialog
                open={showDigitalReferenceCheckDialog}
                candidateId={selectionModel.length === 1 ? +selectionModel[0] : 0}
                closeHandler={() => setShowDigitalReferenceCheckDialog(false)}
                loadingHandler={loadingHandler}
                errorHandler={errorHandler}
                successHandler={successHandler} 
            />
            {Boolean(gridActions) &&
                <Box pb="10px" ml="auto">
                    <Box display="flex">
                        {gridWorkflowActions}
                        {gridActions}
                    </Box>
                </Box>
            }
            <GridWithStateWrapper
                gridName={gridName}
                defaultViewModel={defaultHiddenCols}
                rows={rows}
                columns={columns}
                apiRef={apiRef}
                density="compact"
                checkboxSelection
                disableRowSelectionOnClick
                rowSelectionModel={selectionModel}
                onRowSelectionModelChange={ sm => setSelectionModel(sm) }
                pagination
                pageSizeOptions={[100,250,500,1000]}
            />
        </>
    );
}