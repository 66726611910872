import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect, useMemo, useState } from "react";

interface Props {
    id: string,
    row: number,
    col: number,
    sizeX: number,
    sizeY: number,
    minSizeX: number,
    minSizeY: number,
    maxSizeX?: number | null,
    maxSizeY?: number | null,
    unitWidth: number,
    unitHeight: number,
    gapX: number,
    gapY: number,
    resizeControl: boolean,
    resizeIconColor?: string
    removeHandler?: (id: string) => void,
}

const getContentWidth = (sizeX: number, gapX: number, unitWidth: number) => {
    const gapSpace = (sizeX -1) * gapX;
    return (sizeX * unitWidth) + gapSpace;
}

const getContentHeight = (sizeY: number, gapY: number, unitHeight: number) => {
    const gapSpace = (sizeY -1) * gapY;
    return (sizeY * unitHeight) + gapSpace;
}

export default function PanelWrapper({ 
    id, row, col, sizeX, sizeY, minSizeX, minSizeY, maxSizeX, maxSizeY, gapX, gapY, unitHeight, unitWidth, resizeControl, resizeIconColor, removeHandler, children 
}: React.PropsWithChildren<Props>) {
    const [contentSizeX, setContentSizeX] = useState(sizeX);
    const [contentSizeY, setContentSizeY] = useState(sizeY);
    const [containerWidth, setContainerWidth] = useState(0);
    const [containerHeight, setContainerHeight] = useState(0);

    useEffect(() => {
        if (removeHandler) {
            const panelRoot = document.getElementById(id);
            if (panelRoot) {
                const resizeIcons = panelRoot.getElementsByClassName('e-resize') as HTMLCollectionOf<HTMLElement>;

                for (let i = 0; i < resizeIcons.length; i++) {
                    const r = resizeIcons[i];
                    if (resizeIconColor) r.style.color = resizeIconColor;
                    break;
                }
            }
        }
        
    }, [id, resizeIconColor, removeHandler]);

    useEffect(() => {
        if (resizeControl) {}
        const panelRoot = document.getElementById(id);
        if (panelRoot) {
            const contentContainer = panelRoot.getElementsByClassName('e-panel-container') as HTMLCollectionOf<HTMLElement>;
            for (let i = 0; i < contentContainer.length; i++) {
                const c = contentContainer[i];
                const contWidthString = panelRoot.dataset["sizex"];
                const contHeightString = panelRoot.dataset["sizey"];
                const contWidth = contWidthString ? +contWidthString : sizeX;
                const contHeight = contHeightString ? +contHeightString : sizeY;
                if (!isNaN(contWidth) && !isNaN(contHeight)) {
                    setContentSizeX(contWidth);
                    setContentSizeY(contHeight);
                }
                setContainerWidth(c.clientWidth);
                setContainerHeight(c.clientHeight);
                break;
            }
        }
    }, [id, sizeX, sizeY, resizeControl]);

    const baseWidth = useMemo(() => {
        return getContentWidth(contentSizeX, gapX, unitWidth);
    }, [contentSizeX, gapX, unitWidth]);

    const baseHeight = useMemo(() => {
        return getContentHeight(contentSizeY, gapY, unitHeight);
    }, [contentSizeY, gapY, unitHeight]);

    const scale = useMemo(() => {
        if (containerHeight > 0 && containerWidth > 0) {
            const s1 = containerWidth / baseWidth;
            const s2 = containerHeight / baseHeight;
            if (s1 < 1 && s2 < 1) return Math.min(s1, s2);
            return Math.max(s1, s2);
        }
        return 1;
    }, [baseWidth, baseHeight, containerWidth, containerHeight]);

    return (
        <div
            id={id}
            className="e-panel"
            data-row={row}
            data-col={col}
            data-sizex={sizeX}
            data-sizey={sizeY}
            data-minsizex={minSizeX}
            data-minsizey={minSizeY}
            data-maxsizex={maxSizeX}
            data-maxsizey={maxSizeY}
        >
            <div className="e-panel-container">
                <div style={{ height: baseHeight, width: baseWidth, position: 'relative', left: '50%', top: '50%', transform: `translate(-50%, -50%) scale(${scale})`, transformOrigin: 'center center' }}>
                    {removeHandler ? (
                        <IconButton
                            name="panel-content-close-btn"
                            sx={{ position: 'absolute', right: 0, zIndex: t => t.zIndex.appBar - 5, color: t => t.palette.text.disabled }}
                            onClick={() => removeHandler && removeHandler(id)}
                        ><CloseIcon /></IconButton>
                    ) : <></>}
                    {children}
                </div>
            </div>
        </div>
    );
}