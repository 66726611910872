import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { GridColDef } from "@mui/x-data-grid/models/colDef";
import { FilterWithTimeRange } from "common/models/Analytics/AnalyticsFilterData";
import { TopContactsByActiviyRecord } from "common/models/Analytics/BusinessIntelligence";
import GridWithStateWrapper from "components/GridWidthStateWrapper";
import React, { useEffect, useMemo, useState } from "react";
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { TopContactsByActivity } from "services/BusinessIntelligence";
import { TickWithHoverRenderer, VerticalBarChartYAxisWidth } from "util/AnalyticsGraphUtils";

interface Props {
    filterData: FilterWithTimeRange,
    graphType?: 'table' | 'graph',
    loadingHandler?: (isLoading: boolean) => void
}

interface TopContactsByActivityRecordWithId extends TopContactsByActiviyRecord {
    id: number,
}

const gridName = 'analytics/top-contacts/by-activity';

export default function TopContactsByActivityContent({ filterData, graphType = 'graph', loadingHandler }: Props) {
    const [rows, setRows] = useState<TopContactsByActivityRecordWithId[]>([]);
    const [selectedActivity, setSelectedActivity] = useState('placements');
    const t = useTheme();

    useEffect(() => {
        const getData = async () => {
            loadingHandler && loadingHandler(true);
            const res = await TopContactsByActivity(filterData);
            if (res) {
                const data: TopContactsByActivityRecordWithId[] = res.map((r, i) => ({ ...r, id: i }));
                setRows(data);
            }
            loadingHandler && loadingHandler(false);
        };
        getData();
    }, [filterData, loadingHandler]);

    const columns = useMemo<GridColDef[]>(() => {
        return [
            { field: 'contact', headerName: 'Contact', width: 250 },
            { field: 'meetings', headerName: 'Meetings', headerAlign: 'center', align: 'right', width: 150 },
            { field: 'submissions', headerName: 'Submissions', headerAlign: 'center', align: 'right', width: 150 },
            { field: 'interviews', headerName: 'Interviews', headerAlign: 'center', align: 'right' },
            { field: 'offers', headerName: 'Offers', headerAlign: 'center', align: 'right' },
            { field: 'placements', headerName: 'Placements', headerAlign: 'center', align: 'right' },
        ];
    }, []);

    const sortedGraphData = useMemo(() => {
        if (graphType === 'graph') return rows.sort((a,b) => (b as any)[selectedActivity] - (a as any)[selectedActivity]);
        return [];
    }, [graphType, rows, selectedActivity]);

    if (graphType === 'table') {
        return (
            <GridWithStateWrapper
                gridName={gridName}
                rows={rows}
                density="compact"
                columns={columns}
                sx={{ mt: '20px' }}
            />
        );
    }
    else if (graphType === 'graph') {
        return (
            <>
                <Typography variant="h6" padding={1} textAlign="center" fontWeight={600} component="div">
                    Top 20 Contacts by 
                    <TextField variant="standard" select value={selectedActivity} onChange={({target}) => setSelectedActivity(target.value)} sx={{ ml: '10px' }}>
                        <MenuItem value="meetings">Meetings</MenuItem>
                        <MenuItem value="submissions">Submissions</MenuItem>
                        <MenuItem value="interviews">Interviews</MenuItem>
                        <MenuItem value="offers">Offers</MenuItem>
                        <MenuItem value="placements">Placements</MenuItem>
                    </TextField>
                </Typography>
                <div style={{ height: 'calc(100% - 48px)' }}>
                    <ResponsiveContainer width="100%" height="100%">
                        <BarChart width={150} height={150} layout="vertical" data={sortedGraphData} margin={{ right: 50 }}>
                            <CartesianGrid strokeDasharray="3 3" vertical />
                            <XAxis type="number" tickFormatter={v => v.toLocaleString()} stroke={t.palette.text.primary} />
                            <YAxis dataKey="contact" type="category" tick={ TickWithHoverRenderer } stroke={t.palette.text.primary} width={VerticalBarChartYAxisWidth} minTickGap={0} interval={0} />
                            <Tooltip />
                            <Bar dataKey={selectedActivity} fill={t.palette.info.main} min={0} />
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </>
        );
    }


    return <></>;
}