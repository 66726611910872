import Alert from "components/Alert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MenuItem from "@mui/material/MenuItem";
import Snackbar from "@mui/material/Snackbar";
import { GridColDef, GridRenderCellParams, GridRowSelectionModel, useGridApiRef } from "@mui/x-data-grid-premium";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { AllowTemplates } from "common/data/Permissions/UserMenuAccess";
import { ReferenceCheckTemplate } from "common/models/Templates/ReferenceCheckTemplate";
import ConfirmationDialog from "components/Dialogs/Generic/ConfirmationDialog";
import GridWithStateWrapper from "components/GridWidthStateWrapper";
import ActionsDropDownButton from "components/SummaryBars/Actions/ActionsDropsDownMenu";
import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import PageContentLayout from "layouts/PageContentLayout";
import PageLayout from "layouts/PageLayout";
import { CopyReferenceCheckTemplate, GetAllReferenceCheckTemplates, GetMyReferenceCheckTemplates, MarkReferenceCheckTemplatesAsInactive } from "services/TemplatesService";
import RWTextFieldComponent from "components/RWTextFieldComponent";

const addTemplateLink = <Link to="/account/templates/reference-checks/0" style={{ color: 'inherit', textDecoration: 'none', padding: '6px 16px', flexGrow: 1 }}>Add Template</Link>;

export default function AccountTemplatesReferenceChecksPage() {
    const [summaryBar, setSummaryBar] = useState<JSX.Element>(<></>);
    const [isLoading, setIsLoading] = useState(false);
    const [showMarkAsInactiveDialog, setShowMarkAsInactiveDialog] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
    const [rows, setRows] = useState<ReferenceCheckTemplate[]>([]);
    const [copyTemplateName, setCopyTemplateName] = useState('');
    const apiRef = useGridApiRef();

    const isTemplatesAdmin = useMemo(() => AllowTemplates(), []);

    useEffect(() => {
        const handleCopyTemplate = () => {
            const selectedId = +selectionModel[0];
            const row = rows.find(r => r.id === selectedId);
            if(row) setCopyTemplateName(row.name);
        }

        const actionsMenu = (
            <ActionsDropDownButton>
                <MenuItem sx={{ p: 0 }}>{ addTemplateLink }</MenuItem>
                <MenuItem onClick={ handleCopyTemplate } disabled={ selectionModel.length !== 1 } >Copy Template</MenuItem>
                <MenuItem onClick={() => setShowMarkAsInactiveDialog(true)} disabled={selectionModel.length === 0}>Mark as Inactive</MenuItem>
            </ActionsDropDownButton>
        );

        const SummaryBar = (
            <TitleAndActionSummaryBar
                title="Account > Templates > Reference Checks"
                browserTabTitle="Reference Checks > Templates"
                action={actionsMenu}
            />
        );
        setSummaryBar(SummaryBar);
    }, [rows, selectionModel]);

    const getRows = useCallback(async () => {
        setIsLoading(true);
        let data: ReferenceCheckTemplate[] | null = null;
        if (isTemplatesAdmin) {
            data = await GetAllReferenceCheckTemplates(setErrorMessage);
        }
        else {
            data = await GetMyReferenceCheckTemplates(setErrorMessage);
        }
        if(data) setRows(data);
        setIsLoading(false);
    }, [isTemplatesAdmin]);

    useEffect(() => {
        getRows();
    }, [getRows]);

    const handleMarkAsInactive = useCallback(async () => {
        setIsLoading(true);
        const res = await MarkReferenceCheckTemplatesAsInactive(selectionModel as number[], setErrorMessage);
        if (res) {
            setShowMarkAsInactiveDialog(false);
            setSelectionModel([]);
            getRows();
        }
        setIsLoading(false);
    }, [selectionModel, getRows]);

    const columns = useMemo<GridColDef[]>(() => {
        const itemLinkRenderer = (params: GridRenderCellParams) => (
            <Link
                to={ '/account/templates/reference-checks/' + params.id.toString() }
                style={{ color: 'inherit', textDecoration: 'underline' }}
            >{params.value}</Link>
        );

        return [
            { field: 'id', headerName: 'ID', renderCell: itemLinkRenderer },
            { field: 'name', headerName: 'Name', renderCell: itemLinkRenderer, width: 300 },
            { field: 'owner', headerName: 'Owner', width: 200 },
            { field: 'documentTemplateName', headerName: 'Document Template', width: 200 },
            { field: 'status', headerName: 'Status', width: 200 },
        ];
    }, []);

    const copyTemplateConfirmCallback = useCallback(async () => {
        setIsLoading(true);
        const templateId = +selectionModel[0];
        const res = await CopyReferenceCheckTemplate(templateId, copyTemplateName, setErrorMessage);
        if (res) {
            setCopyTemplateName('');
            setSelectionModel([]);
            setSuccessMessage('Copy created');
            await getRows();
        }
        setIsLoading(false);
    }, [copyTemplateName, selectionModel, getRows]);
    
    return (
        <PageLayout SummaryBar={summaryBar}>
            <Snackbar open={successMessage !== ''} autoHideDuration={3000} onClose={() => setSuccessMessage('')}>
                <Alert onClose={() => setSuccessMessage('')}>{successMessage}</Alert>
            </Snackbar>
            <Snackbar open={errorMessage !== ''} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            { copyTemplateName !== '' &&
                <Dialog open={true} fullWidth>
                    <DialogTitle>Confirm</DialogTitle>
                    <DialogContent dividers>
                        <RWTextFieldComponent
                            label="New template name"
                            autofocus={true}
                            value={copyTemplateName}
                            onChange={(e) => setCopyTemplateName(e.target.value)}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="error" onClick={() => setCopyTemplateName('')}>Cancel</Button>
                        <Button variant="contained" color="success" onClick={copyTemplateConfirmCallback}>Ok</Button>
                    </DialogActions>
                </Dialog>
            }
            <ConfirmationDialog
                message={`Are you sure you want to mark as inactive the selected (${selectionModel.length}) items?`}
                onClose={() => setShowMarkAsInactiveDialog(false)}
                onContinue={handleMarkAsInactive}
                open={showMarkAsInactiveDialog}
                title="Confirm Action"
            />
            <PageContentLayout showLoading={isLoading}>
                <GridWithStateWrapper
                    gridName="ReferenceChecks"
                    apiRef={apiRef}
                    rows={rows}
                    columns={columns}
                    disableRowSelectionOnClick
                    checkboxSelection
                    pagination
                    density="compact"
                    rowSelectionModel={selectionModel}
                    onRowSelectionModelChange={sm => setSelectionModel(sm)}
                    pageSizeOptions={[100,250,500,1000]}
                    
                />
            </PageContentLayout>
        </PageLayout>
    );
}