import React, { useCallback, useEffect, useMemo, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import moment from "moment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ChangePlacementStartDate } from "services/ChangeRequestsService";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

interface Props {
    open: boolean,
    placementId: number,
    startDate: string,
    endDate: string,
    rateId: number,
    isPendingChanges?: boolean,
    closeHandler: () => void,
    loadingHandler?: (isLoading: boolean) => void,
    successHandler?: (message: string) => void,
    errorHandler?: (message: string) => void
}

export default function ChangeStartDateDialog({ open, placementId, startDate: startDateProp, endDate: endDateProp, rateId, isPendingChanges, closeHandler: closeHandlerProp, loadingHandler, successHandler, errorHandler }: Props) {
    const [startDate, setStartDate] = useState<moment.Moment | null>(null);
    const [endDate, setEndDate] = useState<moment.Moment | null>(null);
    const [showEndDate, setShowEndDate] = useState(false);

    useEffect(() => {
        if (open) {
            const m1 = moment(startDateProp);
            setStartDate(m1.isValid() ? m1 : null);
            const m2 = moment(endDateProp);
            setEndDate(m2.isValid() ? m2 : null);
        }
    }, [endDateProp, open, startDateProp]);

    const closeHandler = useCallback(() => {
        closeHandlerProp();
        errorHandler && errorHandler('');
    }, [closeHandlerProp, errorHandler]);

    useEffect(() => {
        if (open && isPendingChanges) errorHandler && errorHandler('There is already a pending Change Request');
    }, [errorHandler, isPendingChanges, open]);

    const submitCallback = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        const sDate = startDate && startDate.isValid() ? startDate.format('YYYY-MM-DD') : '';
        const eDate = showEndDate && endDate && endDate.isValid() ? endDate.format('YYYY-MM-DD') : '';
        if (showEndDate) {
            if (!Boolean(eDate)) {
                errorHandler && errorHandler('No End Date Selected');
                loadingHandler && loadingHandler(false);
                return;
            }
            if (eDate < sDate) {
                errorHandler && errorHandler('End Date must be after Start Date');
                loadingHandler && loadingHandler(false);
                return;
            }
        }
        const res = await ChangePlacementStartDate(placementId, sDate, eDate, rateId, errorHandler);
        if (res) {
            successHandler && successHandler('Change Request Submitted');
            closeHandler();
        }
        loadingHandler && loadingHandler(false);
    }, [loadingHandler, startDate, endDate, showEndDate, placementId, rateId, errorHandler, successHandler, closeHandler]);

    const isSubmitEnabled = useMemo(() => {
        let enabled = false;
        if (showEndDate && endDate) {
            const propM2 = moment(endDateProp);
            enabled = !endDate.isSame(propM2);
        }
        if (startDate) {
            const propM1 = moment(startDateProp);
            enabled = enabled || !startDate.isSame(propM1);
        }
        return enabled;
    }, [showEndDate, endDate, startDate, endDateProp, startDateProp]);

    return (
        <>
            <Dialog open={open} fullWidth>
                <DialogTitle>Change Start Date</DialogTitle>
                <DialogContent>
                    <Stack mt="10px" spacing={2}>
                        <DatePicker
                            value={startDate}
                            label="Start Date"
                            onChange={m => setStartDate(m)}
                            slotProps={{actionBar: { actions: ["clear", "today", "cancel", "accept"] }}}
                        />
                        <FormControlLabel
                            sx={{ m: 0, mr:'20px' }}
                            label="Also change end date"
                            control={
                                <Checkbox
                                    checked={ showEndDate }
                                    onChange={ () => setShowEndDate(prev => !prev) }
                                    sx={{ p: '4px' }}
                                />
                            }
                        />
                        {showEndDate && 
                            <DatePicker
                                value={endDate}
                                label="End Date"
                                onChange={m => setEndDate(m)}
                                slotProps={{actionBar: { actions: ["clear", "today", "cancel", "accept"] }}}
                            />
                        }
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="error" onClick={ closeHandler }>Cancel</Button>
                    <Button variant="contained" color="success" disabled={!isSubmitEnabled} onClick={ submitCallback } >Submit</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}