import { MinPlacement, Placement } from "common/models/Placements";
import { ChangeTracker } from "common/models/hooks/ChangeTracker";

export function PlacementToMinPlacement(p: Placement): MinPlacement {
	return {
		id: p.id,
		jobID: p.jobID,
		placementTypeID: p.placementTypeID,
		jobTitle: p.jobTitle,
		candidateID: p.candidateID,
		startDate: p.startDate,
		endDate: p.endDate,
		payDivision: p.payDivision,
		division: p.division,
		currencyID: p.currencyID,
		currencyName: p.currencyName,
		salary: p.salary,
		salaryUnits: p.salaryUnits,
		feeType: p.feeType,
		permFeeAmount: p.permFeeAmount,
		permFeePercentage: p.permFeePercentage,
		chargeRate: p.chargeRate,
		chargeRateUnits: p.chargeRateUnits,
		payRate: p.payRate,
		payRateUnits: p.payRateUnits,
		onCosts: p.onCosts,
		onCostsUnits: p.onCostsUnits,
		hoursPerDay: p.hoursPerDay,
		daysPerWeek: p.daysPerWeek,
		remComments: p.remComments,
		paymentTypeID: p.paymentTypeID,
		paymentCompanyID: p.paymentCompanyID,
		paymentContactID: p.paymentContactID,
		warrantyLength: p.warrantyLength,
		siteID: p.siteID,
		siteName: p.siteName,
		address1: p.address1,
		address2: p.address2,
		address3: p.address3,
		suburb: p.suburb,
		state: p.state,
		postcode: p.postcode,
		countryCode: p.countryCode,
		countryName: p.countryName,
		clientID: p.clientID,
		contactID: p.contactID,
		hiringManagerContactID: p.hiringManagerContactID,
		billingContactContactID: p.billingContactContactID,
		billingContact2ContactID: p.billingContact2ContactID,
		billingContact3ContactID: p.billingContact3ContactID,
		signatoryContactID: p.signatoryContactID,
		clientReference: p.clientReference,
		timesheetApprover1: p.timesheetApprover1,
		timesheetApprover2: p.timesheetApprover2,
		summary: p.summary,
		placementStatusID: p.placementStatusID,
		consultantID1: p.consultantID1,
		consultantID2: p.consultantID2,
		consultantID3: p.consultantID3,
		consultantID4: p.consultantID4,
		consultantID5: p.consultantID5,
		consultantPercentage1: p.consultantPercentage1,
		consultantPercentage2: p.consultantPercentage2,
		consultantPercentage3: p.consultantPercentage3,
		consultantPercentage4: p.consultantPercentage4,
		consultantPercentage5: p.consultantPercentage5,
		source: p.source,
		netRevenue: p.netRevenue,
		placementDate: p.placementDate,
		probationLength: p.probationLength,
		noticePeriod: p.noticePeriod,
		noticePeriodUnits: p.noticePeriodUnits,
		payFrequencyID: p.payFrequencyID,
		complianceChecklistID: p.complianceChecklistID,
		customField1: p.customField1, customField2: p.customField2, customField3: p.customField3, customField4: p.customField4, customField5: p.customField5, customField6: p.customField6, customField7: p.customField7, customField8: p.customField8, customField9: p.customField9, customField10: p.customField10,
        customField11: p.customField11, customField12: p.customField12, customField13: p.customField13, customField14: p.customField14, customField15: p.customField15, customField16: p.customField16, customField17: p.customField17, customField18: p.customField18, customField19: p.customField19, customField20: p.customField20,
        customField21: p.customField21, customField22: p.customField22, customField23: p.customField23, customField24: p.customField24, customField25: p.customField25, customField26: p.customField26, customField27: p.customField27, customField28: p.customField28, customField29: p.customField29, customField30: p.customField30, 
        customField31: p.customField31, customField32: p.customField32, customField33: p.customField33, customField34: p.customField34, customField35: p.customField35, customField36: p.customField36, customField37: p.customField37, customField38: p.customField38, customField39: p.customField39, customField40: p.customField40, 
        customField41: p.customField41, customField42: p.customField42, customField43: p.customField43, customField44: p.customField44, customField45: p.customField45, customField46: p.customField46, customField47: p.customField47, customField48: p.customField48, customField49: p.customField49, customField50: p.customField50, 
        customField51: p.customField51, customField52: p.customField52, customField53: p.customField53, customField54: p.customField54, customField55: p.customField55, customField56: p.customField56, customField57: p.customField57, customField58: p.customField58, customField59: p.customField59, customField60: p.customField60
	};
}

export const DefaultMinPlacement: MinPlacement = {
	id: 0,
	jobID: 0,
	placementTypeID: 0,
	jobTitle: '',
	candidateID: 0,
	startDate: '',
	endDate: '',
	payDivision: 0,
	division: 0,
	currencyID: 0,
	currencyName: '',
	salary: 0,
	salaryUnits: 0,
	feeType: 0,
	permFeeAmount: 0,
	permFeePercentage: 0,
	chargeRate: 0,
	chargeRateUnits: 0,
	payRate: 0,
	payRateUnits: 0,
	onCosts: 0,
	onCostsUnits: 0,
	hoursPerDay: 0,
	daysPerWeek: 0,
	remComments: '',
	paymentTypeID: 0,
	paymentCompanyID: 0,
	paymentContactID: 0,
	warrantyLength: 0,
	siteID: 0,
	siteName: '',
	address1: '',
	address2: '',
	address3: '',
	suburb: '',
	state: '',
	postcode: '',
	countryCode: '',
	countryName: '',
	clientID: 0,
	contactID: 0,
	hiringManagerContactID: 0,
	billingContactContactID: 0,
	billingContact2ContactID: 0,
	billingContact3ContactID: 0,
	signatoryContactID: 0,
	clientReference: '',
	timesheetApprover1: 0,
	timesheetApprover2: 0,
	summary: '',
	placementStatusID: 0,
	consultantID1: 0,
	consultantID2: 0,
	consultantID3: 0,
	consultantID4: 0,
	consultantID5: 0,
	consultantPercentage1: 0,
	consultantPercentage2: 0,
	consultantPercentage3: 0,
	consultantPercentage4: 0,
	consultantPercentage5: 0,
	source: '',
	netRevenue: 0,
	placementDate: '',
	probationLength: 0,
	noticePeriod: 0,
	noticePeriodUnits: 0,
	payFrequencyID: 0,
	complianceChecklistID: 0,
	customField1: '', customField2: '', customField3: '', customField4: '', customField5: '', customField6: '', customField7: '', customField8: '', customField9: '', customField10: '',
	customField11: undefined, customField12: undefined, customField13: undefined, customField14: undefined, customField15: undefined, customField16: '0001-01-01T00:00:00', customField17: '0001-01-01T00:00:00', customField18: '0001-01-01T00:00:00', customField19: '0001-01-01T00:00:00', customField20: '0001-01-01T00:00:00',
	customField21: '', customField22: '', customField23: '', customField24: '', customField25: '', customField26: '', customField27: '', customField28: '', customField29: '', customField30: '', 
	customField31: '', customField32: '', customField33: '', customField34: '', customField35: '', customField36: '', customField37: '', customField38: '', customField39: '', customField40: '', 
	customField41: undefined, customField42: undefined, customField43: undefined, customField44: undefined, customField45: undefined, customField46: undefined, customField47: undefined, customField48: undefined, customField49: undefined, customField50: undefined, 
	customField51: '0001-01-01T00:00:00', customField52: '0001-01-01T00:00:00', customField53: '0001-01-01T00:00:00', customField54: '0001-01-01T00:00:00', customField55: '0001-01-01T00:00:00', customField56: '0001-01-01T00:00:00', customField57: '0001-01-01T00:00:00', customField58: '0001-01-01T00:00:00', customField59: '0001-01-01T00:00:00', customField60: '0001-01-01T00:00:00'
};

export const DefaultMinPlacementNoChanges: ChangeTracker<MinPlacement> = {
	id: false,
	jobID: false,
	placementTypeID: false,
	jobTitle: false,
	candidateID: false,
	startDate: false,
	endDate: false,
	payDivision: false,
	division: false,
	currencyID: false,
	currencyName: false,
	salary: false,
	salaryUnits: false,
	feeType: false,
	permFeeAmount: false,
	permFeePercentage: false,
	chargeRate: false,
	chargeRateUnits: false,
	payRate: false,
	payRateUnits: false,
	onCosts: false,
	onCostsUnits: false,
	hoursPerDay: false,
	daysPerWeek: false,
	remComments: false,
	paymentTypeID: false,
	paymentCompanyID: false,
	paymentContactID: false,
	warrantyLength: false,
	siteID: false,
	siteName: false,
	address1: false,
	address2: false,
	address3: false,
	suburb: false,
	state: false,
	postcode: false,
	countryCode: false,
	countryName: false,
	clientID: false,
	contactID: false,
	hiringManagerContactID: false,
	billingContactContactID: false,
	billingContact2ContactID: false,
	billingContact3ContactID: false,
	signatoryContactID: false,
	clientReference: false,
	timesheetApprover1: false,
	timesheetApprover2: false,
	summary: false,
	placementStatusID: false,
	consultantID1: false,
	consultantID2: false,
	consultantID3: false,
	consultantID4: false,
	consultantID5: false,
	consultantPercentage1: false,
	consultantPercentage2: false,
	consultantPercentage3: false,
	consultantPercentage4: false,
	consultantPercentage5: false,
	source: false,
	netRevenue: false,
	placementDate: false,
	probationLength: false,
	noticePeriod: false,
	noticePeriodUnits: false,
	payFrequencyID: false,
	complianceChecklistID: false,
	customField1: false, customField2: false, customField3: false, customField4: false, customField5: false, customField6: false, customField7: false, customField8: false, customField9: false, customField10: false,
    customField11: false, customField12: false, customField13: false, customField14: false, customField15: false, customField16: false, customField17: false, customField18: false, customField19: false, customField20: false,
    customField21: false, customField22: false, customField23: false, customField24: false, customField25: false, customField26: false, customField27: false, customField28: false, customField29: false, customField30: false, 
    customField31: false, customField32: false, customField33: false, customField34: false, customField35: false, customField36: false, customField37: false, customField38: false, customField39: false, customField40: false, 
    customField41: false, customField42: false, customField43: false, customField44: false, customField45: false, customField46: false, customField47: false, customField48: false, customField49: false, customField50: false, 
    customField51: false, customField52: false, customField53: false, customField54: false, customField55: false, customField56: false, customField57: false, customField58: false, customField59: false, customField60: false
}

export const PlaceholderPlacement: Placement = {
    id: 123456,
	approved: true,
	exportedToBackOffice: false,
	contractValue: 0,
	netRevenue: 0,
	altID: null,
	jobID: 123456,
	candidateID: 16645589,
	candidateName: "Placeholder Candidate",
	placementStatusID: 6,
	placementStatusName: "Current",
	clientID: 204304,
	clientName: "Placeholder Client",
	contactID: 682841,
	contactName: "Placeholder Contact",
	hiringManagerName: "Placeholder Hiring Manager",
	signatoryName: "Placeholder Signatory",
	billingContactName: "Placeholder  Billing 1",
	billingContact2Name: null,
	billingContact3Name: null,
	placementTypeID: 1,
	jobTitle: "Placeholder Job",
	jobRef: "555644",
	siteID: 171689,
	siteName: "Placeholder Site",
	address1: "North ",
	address2: "",
	address3: "",
	suburb: "Pole",
	state: "",
	postcode: "",
	countryCode: null,
	countryName: null,
	payDivision: 178,
	division: 178,
	updatedByID: 0,
	createdByRecruiterID: 12104,
	createdDate: "2021-12-09T01:21:41.167",
	updatedDate: "0001-01-01T00:00:00",
	startDate: "2022-01-07T00:00:00",
	endDate: "0001-01-01T00:00:00",
	originalStartDate: "0001-01-01T00:00:00",
	placementDate: "2021-12-09T00:00:00",
	salary: 21,
	duration: 0,
	cancellationReason: 0,
	parentPlacementID: 0,
	hiringManagerContactID: 682841,
	signatoryContactID: 682841,
	billingContactContactID: 682841,
	billingContact2ContactID: 0,
	billingContact3ContactID: 0,
	durationPeriod: 0,
	permFeePercentage: 0,
	permFeeAmount: 0,
	salaryUnits: 5,
	probationLength: 0,
	warrantyLength: 6,
	feeType: 0,
	approvalDate: "2022-11-07T02:10:08.553",
	approvedByRecruiterID: 12107,
	payFrequencyID: 2,
	clientReference: "",
	approvedByConsultantName: "User One",
	noticePeriod: 0,
	noticePeriodUnits: 0,
	paymentTypeID: 0,
	paymentCompanyID: 0,
	paymentContactID: 0,
	remComments: "",
	paymentCompanyName: "Placeholder Pay Company",
	paymentContactName: "Placeholder Pay Contact",
	paymentContactEmail: "payment.contact@placeholder.com",
	paymentContactMobile_Standardised: "+61483846580",
	source: "Database",
	summary: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Voluptatem, atque? Ducimus modi optio nulla impedit dolorem fugit eum asperiores libero, culpa, placeat voluptatibus corporis ratione officiis id excepturi explicabo necessitatibus?",
	currencyID: 9,
	currencyName: "Australian Dollar",
	candidateFirstName: "Candidate",
	candidateLastName: "Four",
	candidateEmail: "candidatefour@wizardforce.com",
	candidateOptOut: false,
	candidateTel1_Standardised: "+61483846580",
	contactFirstName: "Bruce",
	contactSurName: "Whane",
	contactEmail: "a@b.com",
	contactMobile_Standardised: "",
	hiringManagerFirstName: "Bruce",
	hiringManagerSurName: "Whane",
	hiringManagerEmail: "a@b.com",
	hiringManagerMobile_Standardised: "",
	billingContactFirstName: "Bruce",
	billingContactSurName: "Whane",
	billingContactEmail: "a@b.com",
	billingContactMobile_Standardised: "",
	deleted: false,
    customField1: 'Custom Field 1',
    customField2: 'Custom Field 2',
    customField3: 'Custom Field 3',
    customField4: 'Custom Field 4',
    customField5: 'Custom Field 5',
    customField6: 'Custom Field 6',
    customField7: 'Custom Field 7',
    customField8: 'Custom Field 8',
    customField9: 'Custom Field 9',
    customField10: 'Custom Field 10',
    customField11: 11,
    customField12: 12,
    customField13: 13,
    customField14: 14,
    customField15: 15,
    customField16: '2010-10-10T10:10:10.100',
    customField17: '2010-10-10T10:10:10.100',
    customField18: '2010-10-10T10:10:10.100',
    customField19: '2010-10-10T10:10:10.100',
    customField20: '2010-10-10T10:10:10.100',
    customField21: 'Custom Field 21',
    customField22: 'Custom Field 22',
    customField23: 'Custom Field 23',
    customField24: 'Custom Field 24',
    customField25: 'Custom Field 25',
    customField26: 'Custom Field 26',
    customField27: 'Custom Field 27',
    customField28: 'Custom Field 28',
    customField29: 'Custom Field 29',
    customField30: 'Custom Field 30',
    customField31: 'Custom Field 31',
    customField32: 'Custom Field 32',
    customField33: 'Custom Field 33',
    customField34: 'Custom Field 34',
    customField35: 'Custom Field 35',
    customField36: 'Custom Field 36',
    customField37: 'Custom Field 37',
    customField38: 'Custom Field 38',
    customField39: 'Custom Field 39',
    customField40: 'Custom Field 40',
    customField41: 41,
    customField42: 42,
    customField43: 43,
    customField44: 44,
    customField45: 45,
    customField46: 46,
    customField47: 47,
    customField48: 48,
    customField49: 49,
    customField50: 50,
    customField51: '2010-10-10T10:10:10.100',
    customField52: '2010-10-10T10:10:10.100',
    customField53: '2010-10-10T10:10:10.100',
    customField54: '2010-10-10T10:10:10.100',
    customField55: '2010-10-10T10:10:10.100',
    customField56: '2010-10-10T10:10:10.100',
    customField57: '2010-10-10T10:10:10.100',
    customField58: '2010-10-10T10:10:10.100',
    customField59: '2010-10-10T10:10:10.100',
    customField60: '2010-10-10T10:10:10.100',
    extendedDate: "0001-01-01T00:00:00",
	timesheetApprover1: 0,
	timesheetApprover1Name: '',
	timesheetApprover2: 0,
	timesheetApprover2Name: null,
	tags: false,
	approvedCount: 1,
	firstApprovedDate: "2022-11-07T02:10:08.553",
	isAmendment: false,
	isExtension: false,
	isValidated: true,
	isTimesheetDataAvailable: false,
	numberOfRateCards: 1,
	payRateUnitsName: "",
	chargeRateUnitsName: "",
	onCostsUnitsName: "",
	salaryUnitsName: "Per Annum",
	placementTypeName: "Permanent",
	paymentTypeName: "",
	payFrequencyName: "Fortnightly",
	invoiceFee: "",
	shortAddress: "Pole",
	fullAddress: "North Pole",
	payRate: 0,
	chargeRate: 0,
	onCosts: 0,
	payRateUnits: 0,
	chargeRateUnits: 0,
	onCostsUnits: 0,
	consultantID1: -1,
	consultantID2: -1,
	consultantID3: -1,
	consultantID4: -1,
	consultantID5: -1,
	consultantName1: "Placeholder Consultant 1",
	consultantName2: 'Placeholder Consultant 2',
	consultantName3: 'Placeholder Consultant 3',
	consultantName4: 'Placeholder Consultant 4',
	consultantName5: 'Placeholder Consultant 5',
	consultantPercentage1: 20,
	consultantPercentage2: 20,
	consultantPercentage3: 20,
	consultantPercentage4: 20,
	consultantPercentage5: 20,
	hoursPerDay: 8,
	daysPerWeek: 5,
	averageDailyMargin: 0,
	averageHourlyMargin: 0,
	dailyMargin: 0,
	hourlyMargin: 0,
    createdByName: '',
    divisionName: '',
    paymentCompany: '',
    paymentContact: '',
    updatedByName: '',
	isChangeRequestPending: false,
	rateCardID: 0,
	complianceChecklistID: 1,
	complianceChecklistName: 'Baseline Requirements',
    statisticsDates: [],
    statisticsCounts: []
};