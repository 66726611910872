import Box from "@mui/material/Box";
import { TagGroup, TagSettings, TagValue } from "common/models/Configuration/Tags";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import TagsSelectionList from "./TagsSelectionList";
import Chip from "@mui/material/Chip";
import CancelIcon from '@mui/icons-material/Cancel';
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import { EmailReceiverDataAddTags } from "services/EmailReceiverService";

interface Props {
    emailReceiverkey: string,
    tagSettings: TagSettings[],
    availableTags: TagValue[],
    availableStructuredTags: TagValue[],
    assignedTags: TagValue[],
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
    successHandler?: (message: string) => void,
}

const tagTypeColors = {
    "text": '#333333',
    "1": '#8db6fd',
    "2": '#FFFF66',
    "3": '#fdbf96',
    "4": '#66fc66',
    "5": '#ff9df5',
    "6": '#DCDCDC',
    "7": '#B0E0E6',
    "8": '#F5F5DC',
    "9": '#40E0D0',
    "10": '#FFA07A',
}

export default function TagsMinRequiredManagement({ emailReceiverkey, tagSettings: tagSettingsProp, availableTags: availableTagsProp, availableStructuredTags: availableStructuredTagsProp, assignedTags: assignedTagsProp, loadingHandler, errorHandler, successHandler }: Props) {
    const [availableTags, setAvailableTags] = useState<TagValue[]>([]);
    const [tagGroups, setTagGroups] =useState<TagGroup[]>([]);
    const [assignedTags, setAssignedTags] = useState<TagValue[]>([]);

    useEffect(() => {
        let tg1: TagGroup | null = null;
        let tg2: TagGroup | null = null;
        let tg3: TagGroup | null = null;
        let tg4: TagGroup | null = null;
        let tg5: TagGroup | null = null;
        let tg6: TagGroup | null = null;
        let tg7: TagGroup | null = null;
        let tg8: TagGroup | null = null;
        let tg9: TagGroup | null = null;
        let tg10: TagGroup | null = null;

        let availableTags = [...availableTagsProp];

        for (let i = 0; i < tagSettingsProp.length; i++) {
            const setting = tagSettingsProp[i];
            let vals: TagValue[] = [];
            if (setting.structured) {
                const structuredValues = availableStructuredTagsProp.filter(v => v.tagTypeID === setting.tagTypeId);
                let newAvailables = availableTags.filter(t => t.tagTypeID !== setting.tagTypeId);
                availableTags = [...newAvailables, ...structuredValues];
                vals = structuredValues;
            }

            const title = Boolean(setting.tagAgencyName) ? setting.tagAgencyName : setting.databaseName;
            if (setting.tagTypeId === 1) tg1 = { typeId: setting.tagTypeId, isStructured: setting.structured, tags: vals, title, color: tagTypeColors[1], minTags: setting.minTags };
            else if (setting.tagTypeId === 2) tg2 = { typeId: setting.tagTypeId, isStructured: setting.structured, tags: vals, title, color: tagTypeColors[2], minTags: setting.minTags };
            else if (setting.tagTypeId === 3) tg3 = { typeId: setting.tagTypeId, isStructured: setting.structured, tags: vals, title, color: tagTypeColors[3], minTags: setting.minTags };
            else if (setting.tagTypeId === 4) tg4 = { typeId: setting.tagTypeId, isStructured: setting.structured, tags: vals, title, color: tagTypeColors[4], minTags: setting.minTags };
            else if (setting.tagTypeId === 5) tg5 = { typeId: setting.tagTypeId, isStructured: setting.structured, tags: vals, title, color: tagTypeColors[5], minTags: setting.minTags };
            else if (setting.tagTypeId === 6) tg6 = { typeId: setting.tagTypeId, isStructured: setting.structured, tags: vals, title, color: tagTypeColors[6], minTags: setting.minTags };
            else if (setting.tagTypeId === 7) tg7 = { typeId: setting.tagTypeId, isStructured: setting.structured, tags: vals, title, color: tagTypeColors[7], minTags: setting.minTags };
            else if (setting.tagTypeId === 8) tg8 = { typeId: setting.tagTypeId, isStructured: setting.structured, tags: vals, title, color: tagTypeColors[8], minTags: setting.minTags };
            else if (setting.tagTypeId === 9) tg9 = { typeId: setting.tagTypeId, isStructured: setting.structured, tags: vals, title, color: tagTypeColors[9], minTags: setting.minTags };
            else if (setting.tagTypeId === 10) tg10 = { typeId: setting.tagTypeId, isStructured: setting.structured, tags: vals, title, color: tagTypeColors[10], minTags: setting.minTags };
        }

        for (let i = 0; i < availableTags.length; i++) {
            const tag = availableTags[i];
            if (tg1 && !tg1.isStructured && tag.tagTypeID === 1) tg1.tags.push(tag);
            if (tg2 && !tg2.isStructured && tag.tagTypeID === 2) tg2.tags.push(tag);
            if (tg3 && !tg3.isStructured && tag.tagTypeID === 3) tg3.tags.push(tag);
            if (tg4 && !tg4.isStructured && tag.tagTypeID === 4) tg4.tags.push(tag);
            if (tg5 && !tg5.isStructured && tag.tagTypeID === 5) tg5.tags.push(tag);
            if (tg6 && !tg6.isStructured && tag.tagTypeID === 6) tg6.tags.push(tag);
            if (tg7 && !tg7.isStructured && tag.tagTypeID === 7) tg7.tags.push(tag);
            if (tg8 && !tg8.isStructured && tag.tagTypeID === 8) tg8.tags.push(tag);
            if (tg9 && !tg9.isStructured && tag.tagTypeID === 9) tg9.tags.push(tag);
            if (tg10 && !tg10.isStructured && tag.tagTypeID === 10) tg10.tags.push(tag);
        }
        setAvailableTags(availableTags);
        let tg: TagGroup[] = [];
        tg1 && tg.push(tg1);
        tg2 && tg.push(tg2);
        tg3 && tg.push(tg3);
        tg4 && tg.push(tg4);
        tg5 && tg.push(tg5);
        tg6 && tg.push(tg6);
        tg7 && tg.push(tg7);
        tg8 && tg.push(tg8);
        tg9 && tg.push(tg9);
        tg10 && tg.push(tg10);
        setTagGroups(tg);

    }, [availableStructuredTagsProp, availableTagsProp, tagSettingsProp]);

    const allAssignedTags = useMemo(() => ([...assignedTagsProp, ...assignedTags]), [assignedTagsProp, assignedTags]);

    const hasMinRequiredTags = useMemo(() => {
        for (let i = 0; i < tagGroups.length; i++) {
            const group = tagGroups[i];
            const minReq = group.minTags;
            if (minReq === 0) continue;
            if (minReq > 0) {
                const f = allAssignedTags.filter(t => t.tagTypeID === group.typeId);
                if (f.length < minReq) return false;
            }
        }
        return true;
    }, [allAssignedTags, tagGroups]);

    const saveChangesHandlerCallback = useCallback(async () => {
        if (!hasMinRequiredTags) {
            errorHandler && errorHandler('Make sure to include the minimum required on each tag group');
            return;
        }

        loadingHandler && loadingHandler(true);

        const res = await EmailReceiverDataAddTags(emailReceiverkey, assignedTags, errorHandler);

        if (res) {
            successHandler && successHandler('Tags added');
        }

        loadingHandler && loadingHandler(false);
    }, [assignedTags, errorHandler, hasMinRequiredTags, emailReceiverkey, loadingHandler, successHandler]);

    const handleAddCallback = useCallback((typeId: number, tag: string) => {
        setAssignedTags(prev => {
            const originalAssignedTagIndex = assignedTagsProp.findIndex(t => t.tagTypeID === typeId && t.tag === tag);
            if (originalAssignedTagIndex === -1) {
                const assignedTagIndex = prev.findIndex(t => t.tagTypeID === typeId && t.tag === tag);
                const availableTagIndex = availableTags.findIndex(t => t.tagTypeID === typeId && t.tag === tag);
                if (assignedTagIndex === -1 && availableTagIndex !== -1) {
                    const selectedTag = availableTags[availableTagIndex];
                    return [...prev, selectedTag]
                }
            }
            return prev;
        });
    }, [availableTags, assignedTagsProp]);

    const handleRemoveCallback = useCallback((data: TagValue) => {
        setAssignedTags(prev => {
            let tmp = [...prev];
            const index = tmp.findIndex(t => t.tagTypeID === data.tagTypeID && t.tag === data.tag);
            if (index !== -1) tmp.splice(index, 1);
            return tmp;
        })
    }, []);

    const handleCreateTagCallback = useCallback((tag: TagValue) => {
        setTagGroups(prev => {
            let tmp = [...prev];
            const tg = tmp.find(g => g.typeId === tag.tagTypeID);
            if (tg) {
                const tagIndex = tg.tags.findIndex(t => t.tagTypeID === tag.tagTypeID && t.tag === tag.tag);
                if (tagIndex === -1) {
                    tg.tags.push(tag);
                    setAvailableTags(prev => [...prev, tag]);
                    setAssignedTags(prev => [...prev, tag]);
                }
            }
            return tmp;
        });
    }, []);

    return (
        <>
            <Box display="flex" flexWrap="wrap" justifyContent="center">
                { tagGroups.map((g, i) =>
                    <TagsSelectionList
                        key={i}
                        typeId={g.typeId}
                        isStructured={g.isStructured}
                        title={g.title}
                        availableTags={g.tags}
                        assignedTags={allAssignedTags}
                        color={g.color}
                        textColor={tagTypeColors.text}
                        minRequired={g.minTags}
                        addHandler={handleAddCallback}
                        removeHandler={handleRemoveCallback}
                        createTagHandler={handleCreateTagCallback}
                    />
                )}
            </Box>
            {assignedTagsProp && assignedTagsProp.length > 0 &&
                <Box my={2}>
                    <Divider variant="middle">Already assigned tags</Divider>
                    <Box display="flex" flexWrap="wrap" justifyContent="center" gap="5px" mt={1}>
                        { assignedTagsProp.map((t, i) =>
                            <Chip
                                key={`${i}-${t.tagTypeID}-${t.tag}`}
                                size="small"
                                label={t.tag}
                                sx={{ bgcolor: (tagTypeColors as any)[t.tagTypeID], color: tagTypeColors.text }}
                            />
                        )}
                    </Box>
                </Box>
            }
            {assignedTags && assignedTags.length > 0 &&
                <Box mb={2}>
                    <Divider variant="middle">New tags</Divider>
                    <Box display="flex" flexWrap="wrap" justifyContent="center" gap="5px" mt={1}>
                        { assignedTags.map((t, i) =>
                            <Chip
                                key={`${i}-${t.tagTypeID}-${t.tag}`}
                                size="small"
                                label={t.tag}
                                onDelete={ () => handleRemoveCallback(t) }
                                sx={{ bgcolor: (tagTypeColors as any)[t.tagTypeID], color: tagTypeColors.text }}
                                deleteIcon={ <CancelIcon style={{ color: tagTypeColors.text }} /> }
                            />
                        )}
                    </Box>
                </Box>
            }
            <Button variant="contained" disabled={assignedTags.length === 0} onClick={saveChangesHandlerCallback}>Add Tags & Continue</Button>
        </>
    );
}