import React, { useCallback, useEffect, useMemo, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import moment from "moment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { UpdateChangeRequest } from "services/ChangeRequestsService";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { ChangeRequest, ChangeStartDateParams } from "common/models/ChangeRequest";
import { ChangeRequestTypeEnum } from "util/Definitions/Configuration/Placements";

interface Props {
    open: boolean,
    changeRequest: ChangeRequest,
    closeHandler: () => void,
    loadingHandler?: (isLoading: boolean) => void,
    successHandler?: (message: string) => void,
    errorHandler?: (message: string) => void
}

export default function EditChangeStartDateDialog({ open, changeRequest, closeHandler: closeHandlerProp, loadingHandler, successHandler, errorHandler }: Props) {
    const [startDate, setStartDate] = useState<moment.Moment | null>(null);
    const [endDate, setEndDate] = useState<moment.Moment | null>(null);
    const [showEndDate, setShowEndDate] = useState(false);

    const p = useMemo<Partial<ChangeStartDateParams> | null>(() => {
        let tmp: Partial<ChangeStartDateParams> = {};
        if (changeRequest.json) {
            const parsed = JSON.parse(changeRequest.json);
            const s1 = 'startDate';
            const s2 = 'StartDate';
            if (parsed[s1]) tmp.startDate = parsed[s1];
            else if (parsed[s2]) tmp.startDate = parsed[s2];
            
            const e1 = 'endDate';
            const e2 = 'EndDate';
            if (parsed[e1]) tmp.endDate = parsed[e1];
            else if (parsed[e2]) tmp.endDate = parsed[e2];
            
            const r1 = 'rateId';
            const r2 = 'RateId';
            if (parsed[r1]) tmp.rateId = parsed[r1];
            else if (parsed[r2]) tmp.rateId = parsed[r2];

            return tmp;
        }
        return null;
    }, [changeRequest.json]);

    useEffect(() => {
        if (open && p) {
            if (p.startDate) {
                const m1 = moment(p.startDate);
                setStartDate(m1.isValid() ? m1 : null);
            }
            if (p.endDate) {
                setShowEndDate(true);
                const m2 = moment(p.endDate);
                setEndDate(m2.isValid() ? m2 : null);
            }
        }
    }, [open, p]);

    const closeHandler = useCallback(() => {
        closeHandlerProp();
        errorHandler && errorHandler('');
    }, [closeHandlerProp, errorHandler]);

    const submitCallback = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        const sDate = startDate && startDate.isValid() ? startDate.format('YYYY-MM-DD') : '';
        const eDate = showEndDate && endDate && endDate.isValid() ? endDate.format('YYYY-MM-DD') : '';
        if (showEndDate) {
            if (!Boolean(eDate)) {
                errorHandler && errorHandler('No End Date Selected');
                loadingHandler && loadingHandler(false);
                return;
            }
            if (eDate < sDate) {
                errorHandler && errorHandler('End Date must be after Start Date');
                loadingHandler && loadingHandler(false);
                return;
            }
        }

        const data: ChangeStartDateParams = {
            rateId: changeRequest.rateID,
            startDate: sDate,
            endDate: Boolean(eDate) ? eDate : null
        };

        const res = await UpdateChangeRequest(changeRequest.id, ChangeRequestTypeEnum.StartDateChange, data, errorHandler);
        loadingHandler && loadingHandler(false);
        if (res) {
            successHandler && successHandler('Change Request Updated');
            closeHandler();
        }
    }, [loadingHandler, startDate, showEndDate, endDate, changeRequest.rateID, changeRequest.id, errorHandler, successHandler, closeHandler]);

    const isSubmitEnabled = useMemo(() => {
        let enabled = false;
        if (showEndDate && endDate && p) {
            const propM2 = moment(p.endDate);
            enabled = !endDate.isSame(propM2);
        }
        if (startDate && p) {
            const propM1 = moment(p.startDate);
            enabled = enabled || !startDate.isSame(propM1);
        }
        return enabled;
    }, [showEndDate, endDate, p, startDate]);

    return (
        <>
            <Dialog open={open} fullWidth>
                <DialogTitle>Change Start Date</DialogTitle>
                <DialogContent>
                    <Stack mt="10px" spacing={2}>
                        <DatePicker
                            value={startDate}
                            label="Start Date"
                            onChange={m => setStartDate(m)}
                            slotProps={{actionBar: { actions: ["clear", "today", "cancel", "accept"] }}}
                        />
                        <FormControlLabel
                            sx={{ m: 0, mr:'20px' }}
                            label="Also change end date"
                            control={
                                <Checkbox
                                    checked={ showEndDate }
                                    onChange={ () => setShowEndDate(prev => !prev) }
                                    sx={{ p: '4px' }}
                                />
                            }
                        />
                        {showEndDate && 
                            <DatePicker
                                value={endDate}
                                label="End Date"
                                onChange={m => setEndDate(m)}
                                slotProps={{actionBar: { actions: ["clear", "today", "cancel", "accept"] }}}
                            />
                        }
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="error" onClick={ closeHandler }>Cancel</Button>
                    <Button variant="contained" color="success" disabled={!isSubmitEnabled} onClick={ submitCallback } >Submit</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}