import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import React from "react";

interface Props {
    data: unknown[],
    nameField: string,
    valueField: string,
}

export default function ListValueDisplayGraph({ data, nameField, valueField }: Props) {
    return (
        <Paper sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }} >
            {data && data.map((v, i) => (
                <Box 
                    key={i}
                    display="flex"
                    px="10px"
                    py="4px"
                    sx={{
                        borderBottomWidth: '2px',
                        borderBottomStyle: 'solid',
                        borderBottomColor: t => t.palette.background.default,
                        ":hover": { borderBottomColor: t => t.palette.primary.main, bgcolor: t => t.palette.background.default }
                    }}
                >
                    <Box flexGrow={1}>{(v as any)[nameField]}</Box>
                    <Box>{(v as any)[valueField]}</Box>
                </Box>
            ))}
        </Paper>
    );
}