import Autocomplete, { AutocompleteInputChangeReason } from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import TextField from "@mui/material/TextField";
import React, { useCallback, useEffect, useState } from "react";
import { Contact } from "common/models/Contacts";
import { ContactLookup, GetContactById } from "services/ContactsService";
import QuickCreateContactDialog from "../Dialogs/Contacts/QuickCreateContactDialog";

interface Props {
    value: number | null,
    clientId?: number,
    onSelectCallback: (c: Contact | null) => void,
    variant?: "standard" | "filled" | "outlined" | undefined,
    label?: string,
    delayMs?: number,
    hideLabel?: boolean,
    disableClearable?: boolean,
    disableQuickCreate?: boolean,
    blurOnSelect?: boolean,
    disabled?: boolean,
    error?: boolean,
    errorMessage?: string,
    loadingHandler?: (isLoading: boolean) => void,
    successHandler?: (message: string) => void,
    errorHandler?: (message: string) => void,
}

export default function ContactPicker({ value, label = 'Contact', clientId: clientIdProp = 0, variant, hideLabel = false, delayMs = 200, disableClearable, disableQuickCreate = false, error, errorMessage, blurOnSelect, disabled, onSelectCallback, errorHandler, loadingHandler, successHandler }: Props) {
    const [selectedContact, setSelectedContact] = useState<Contact | null>(null);
    const [lookupTerm, setLookupTerm] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState<Contact[]>([]);
    const [showQuickCreateDialog, setShowQuickCreateDialog] = useState(false);

    const getContact = useCallback(async (contactId: number) => {
        const res = await GetContactById(contactId);
        if (res) setSelectedContact(res);
    }, []);

    useEffect(() => {
        if (value) {
            if (!selectedContact || (selectedContact && selectedContact.id !== value)) {
                const item = options.find(o => o.id === value);
                if (item) setSelectedContact(item);
                else getContact(value);
            }
        }
        else setSelectedContact(null);
    }, [value, selectedContact, options, getContact]);

    useEffect(() => {
        if (lookupTerm.length > 0) {
            const delaySearch = setTimeout(async () => {
                setIsLoading(true);
                const res = await ContactLookup(lookupTerm, 25);
                if (res) setOptions(res);
                setIsLoading(false);
            }, delayMs);
            return () => clearTimeout(delaySearch);
        }
    }, [lookupTerm, delayMs]);

    const handleInputChange = useCallback((event: React.SyntheticEvent<Element, Event>, value: string, reason: AutocompleteInputChangeReason) => {
        reason !== 'reset' && setLookupTerm(value);
    }, []);

    return (
        <Box display="flex">
            {!disableQuickCreate &&
                <QuickCreateContactDialog
                    open={showQuickCreateDialog}
                    firstName={lookupTerm}
                    clientId={clientIdProp}
                    closeHandler={() => setShowQuickCreateDialog(false)}
                    onContactCreated={ onSelectCallback }
                    errorHandler={errorHandler}
                    loadingHandler={loadingHandler}
                    successHandler={successHandler}
                />
            }
            <Autocomplete
                size="small"
                fullWidth
                options={options}
                value={ selectedContact }
                getOptionLabel={ o => o.fullName }
                isOptionEqualToValue={ (o,v) => o.id === v.id }
                loading={isLoading}
                filterOptions={ x => x }
                onChange={ (e,v) => onSelectCallback(v) }
                disableClearable={disableClearable}
                disabled={disabled}
                blurOnSelect={blurOnSelect}
                renderInput={ (params) => (
                    <TextField
                        {...params}
                        variant={variant}
                        label={hideLabel ? undefined : label}
                        inputProps={{ ...params.inputProps, "data-lpignore": "true" }}
                        error={error}
                        helperText={errorMessage}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (<>{isLoading ? <CircularProgress color="inherit" size={20} /> : params.InputProps.endAdornment}</>)
                        }}
                    />
                )}
                onInputChange={ handleInputChange }
                renderOption={(props, option) => (
                    <ListItem {...props} key={option.id}>
                        <ListItemText
                            primary={option.fullName}
                            secondary={(
                                <>
                                    {option.jobTitle && <Box>{option.jobTitle}</Box>}
                                    {option.clientName && <Box>{option.clientName}</Box>}
                                    {option.address1 && <Box>{option.address1}</Box>}
                                </>
                            )}
                            secondaryTypographyProps={{ component: 'div' }}
                        />
                    </ListItem>
                )}
            />
            { !disableQuickCreate && <IconButton onClick={() => setShowQuickCreateDialog(true)} disabled={disabled}><AddCircleIcon /></IconButton> }
        </Box>
    );
}