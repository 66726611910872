import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import moment from "moment";
import React, { useCallback } from "react";
import * as XLSX from "xlsx";
import {TscGetCurrentContractorsReportData} from "services/PlacementsService";
import { ReportDataProcessDateFields } from "util/ReportUtils";

interface Props {
    description: string,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void
}

export default function TSCCurrentContractorsReport({ description, loadingHandler, errorHandler }: Props) {


    const getDataCallback = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        const res = await TscGetCurrentContractorsReportData(errorHandler);
        if (res) {
            ReportDataProcessDateFields(res as any[]);
            
            const m = moment();
            const filename = `TSC-CurrentContractors-MondayDotDom${m.format('YYYYMMDDhhmmss')}.xlsx`;
            const ws = XLSX.utils.json_to_sheet(res);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws);
            XLSX.writeFile(wb, filename);
        }
        loadingHandler && loadingHandler(false);
    }, [errorHandler, loadingHandler]);

    return (
        <>
            <Box display="flex">
                
                <Button variant="contained" color="success" onClick={getDataCallback}>Run Report</Button>
            </Box>
            <Typography component="div" variant="h6" mt={2}>Report Description</Typography>
            <Typography component="div" variant="body2">{description}</Typography>
        </>
    );
}
