import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import * as tinymce from 'tinymce';
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import MessageTemplatePicker from "components/Pickers/MessageTemplatePicker";
import { MessageTemplate } from "common/models/Templates/MessageTemplate";
import { Editor } from "@tinymce/tinymce-react/lib/cjs/main/ts/components/Editor";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { CandidateOfferMade } from "services/CandidatesService";
import { OfferConfirmationData } from "common/models/Candidates/MakeOfferData";
import FilePicker from "components/Pickers/FilePicker";
import { SimpleFileObj } from "common/models/GenericTypes";
import { DownloadMessageTemplateAttachmentFile, GetMessageTemplateAttachments } from "services/TemplatesService";
import { TemplatePlaceholder } from "common/models/Templates/TemplatePlaceholder";
import { GetPlaceholdersByEntity } from "services/PlaceholdersService";
import InsertPlaceholderDialog from "../Generic/InsertPlaceholderDialog";
import RWTextFieldComponent from "components/RWTextFieldComponent";

interface Props {
    open: boolean,
    candidateId: number,
    jobId: number,
    closeHandler: () => void,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
    successHandler?: (message: string) => void
}

const entityTypeId = 3;

export default function OfferMadeDialog({ open, candidateId, jobId, closeHandler, loadingHandler, errorHandler, successHandler }: Props) {
    const [notes, setNotes] = useState('');
    const [isSendOfferConfirmation, setIsSendOfferConfirmation] = useState(false);
    const [showValidation, setShowValidation] = useState(false);
    const [templateType, setTemplateType] = useState('Me');
    const [subject, setSubject] = useState('');
    const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
    const [attachments, setAttachments] = useState<SimpleFileObj[]>([]);
    const [selectedTemplate, setSelectedTemplate] = useState<MessageTemplate | null>(null);
    const [showPlaceholders, setShowPlaceholders] = useState(false);
    const [placeholders, setPlaceholders] = useState<TemplatePlaceholder[]>([]);
    const editorRef = useRef<tinymce.Editor | null>(null);
    const theme = useTheme();
    const isDarkTheme = useMemo(() => theme.palette.mode === 'dark', [theme.palette.mode]);

    useEffect(() => {
        if (!isSendOfferConfirmation) {
            setTemplateType('Me');
            setSubject('');
            setShowValidation(false);
        }
    }, [isSendOfferConfirmation]);

    useEffect(() => {
        if (!open) {
            const api = editorRef.current;
            if (api) api.setContent('');
            setNotes('')
            setTemplateType('Me');
            setSubject('');
            setShowValidation(false);
            setIsSendOfferConfirmation(false);
            setFilesToUpload([]);
        }
    }, [open]);

    useEffect(() => {
        const getAttachments = async () => {
            if (selectedTemplate) {
                const att = await GetMessageTemplateAttachments(selectedTemplate.id);
                if (att) {
                    let files: SimpleFileObj[] = [];
                    for (let i = 0; i < att.length; i++) {
                        const f = att[i];
                        files.push({ id: f.id, name: f.friendlyName, size: f.size })
                    }
                    if (files.length > 0) setAttachments(files);
                }
            }
        };

        open && selectedTemplate && getAttachments();
    }, [open, selectedTemplate]);

    useEffect(() => {
        if (open && placeholders.length === 0) {
            const getPlaceholders = async () => {
                loadingHandler && loadingHandler(true);
                const res = await GetPlaceholdersByEntity(entityTypeId);
                if (res) setPlaceholders(res);
                loadingHandler && loadingHandler(false);
            };
            getPlaceholders();
        }
    }, [loadingHandler, open, placeholders.length]);

    const templateChangeHandlerCallback = useCallback((template: MessageTemplate | null) => {
        if (template) {
            setSubject(template.subject);
            editorRef.current?.setContent(template.body);
        }
        setAttachments([]);
        setSelectedTemplate(template);
    }, []);

    const submitCallback = useCallback(async () => {
        const api = editorRef.current;
        let body = '';
        if (isSendOfferConfirmation && api) {
            body = api.getContent();
            if (!Boolean(subject) || !Boolean(body)) {
                setShowValidation(true);
                return;
            }
        }

        loadingHandler && loadingHandler(true);
        const data: OfferConfirmationData = {
            notes: notes,
            subject: isSendOfferConfirmation ? subject : '',
            message: isSendOfferConfirmation ? body : '',
            templateId: selectedTemplate ? selectedTemplate.id : 0,
            templateAttachmentIds: attachments.map(a => a.id),
        };

        const res = await CandidateOfferMade(candidateId, jobId, data, filesToUpload, errorHandler);

        if (res) {
            successHandler && successHandler('Candidate marked as Offer Made');
            closeHandler();
        }
        loadingHandler && loadingHandler(false);
    }, [isSendOfferConfirmation, loadingHandler, notes, subject, selectedTemplate, attachments, candidateId, jobId, filesToUpload, errorHandler, successHandler, closeHandler]);

    const addFilesCallback = useCallback((files: File[]) => {
        setFilesToUpload(prev => [...prev, ...files]);
    }, []);

    const removeUploadedFileCallback = useCallback((index: number) => {
        setAttachments(prev => {
            let att = [...prev];
            att.splice(index, 1);
            return att;
        });
    }, []);

    const removeFileCallback = useCallback((index: number) => {
        setFilesToUpload(prev => {
            let files = [...prev];
            files.splice(index, 1);
            return files;
        });
    }, []);

    const downloadFileCallback = useCallback(async (file: SimpleFileObj) => {
        if (selectedTemplate) {
            await DownloadMessageTemplateAttachmentFile(selectedTemplate.id, file.id);
        }
    }, [selectedTemplate]);

    const insertPlaceholderHandler = useCallback((placeholder: string) => {
        const api = editorRef.current;
        if (api) api.insertContent(placeholder);
    }, []);

    return (
        <>
            <InsertPlaceholderDialog
                open={showPlaceholders}
                closeHandler={() => setShowPlaceholders(false)}
                placeholders={placeholders}
                insertHandler={insertPlaceholderHandler}
            />
            <Dialog open={open} fullWidth>
                <DialogTitle>Offer Made</DialogTitle>
                <DialogContent>
                    <Stack spacing={2} mt="10px">
                        <RWTextFieldComponent
                            value={notes}
                            label="Notes"
                            onChange={ (e) => setNotes(e.target.value) }
                            multiline
                            minRows={3}
                        />
                        <FormControlLabel
                            label="Send Offer Confirmation"
                            control={ 
                                <Checkbox
                                    checked={isSendOfferConfirmation}
                                    onChange={() => setIsSendOfferConfirmation(prev => !prev)} 
                                />
                            }
                        />
                        { isSendOfferConfirmation &&
                            <Box display="flex">
                                <TextField
                                    select
                                    fullWidth
                                    label="Template Type"
                                    value={templateType}
                                    onChange={ ({target}) => setTemplateType(target.value) }
                                    sx={{ flex: '1 1 0', pr: '5px' }}
                                >
                                    <MenuItem value="Me">My Templates</MenuItem>
                                    <MenuItem value="Team">My Team's Templates</MenuItem>
                                    <MenuItem value="All">All Templates</MenuItem>
                                </TextField>
                                {open &&
                                    <Box sx={{ flex: '1 1 0', pl: '5px' }}>
                                        <MessageTemplatePicker
                                            templateId={selectedTemplate ? selectedTemplate.id : null}
                                            includeCompanyOwned
                                            typeFilter={6}
                                            templateSource={templateType}
                                            entityTypeFilter={entityTypeId}
                                            onSelectHandler={ templateChangeHandlerCallback }
                                        />
                                    </Box>
                                }
                            </Box>
                        }
                        { isSendOfferConfirmation &&
                            <RWTextFieldComponent
                                label="Subject"
                                value={subject}
                                onChange={ (e) => setSubject(e.target.value) }
                                isError={showValidation && subject === ''}
                            />
                        }
                        { isSendOfferConfirmation &&
                            <Editor
                                tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce-5.10.2/tinymce.min.js'}
                                // onDirty={ () => setIsEditorDirty(true) }
                                onInit={ (evt, editor) => editorRef.current = editor }
                                // initialValue={`<br /><br /><br />${signature}`}
                                init={{
                                    height: 300,
                                    skin: isDarkTheme ? 'oxide-dark' : undefined,
                                    content_css: isDarkTheme ? 'dark' : undefined,
                                    branding: false,
                                    menubar: false,
                                    statusbar: false,
                                    contextmenu: false,
                                    resize: false,
                                    browser_spellcheck: true,
                                    plugins: 'powerpaste code link emoticons table print preview visualchars lists fullscreen',
                                    powerpaste_word_import: 'prompt',
                                    powerpaste_html_import: 'prompt',
                                    powerpaste_allow_local_images: true,
                                    fontsize_formats: "8pt 9pt 10pt 12pt 14pt 18pt 24pt 36pt",
                                    toolbar1: 'placeholders fontsizeselect fontselect styleselect forecolor backcolor bold italic alignleft aligncenter alignright alignjustify bullist numlist code fullscreen link mybutton table',
                                    font_formats: 'Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Calibri=calibri; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Segoe=segoe,segoe ui,dejavu sans,trebuchet ms,verdana,sans-serif; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva',
                                    setup: editor => {
                                        editor.ui.registry.addButton('placeholders', {
                                            text: 'Insert Placeholder',
                                            onAction: () => setShowPlaceholders(true)
                                        });
                                    }
                                }}
                            />
                        }
                        { isSendOfferConfirmation &&
                            <FilePicker
                                files={ filesToUpload }
                                uploadedFiles={ attachments }
                                addFilesHandler={ addFilesCallback }
                                removeFileHandler={ removeFileCallback }
                                removeUploadedFileHandler={ removeUploadedFileCallback }
                                downloadFileHandler={ downloadFileCallback }
                            />
                        }
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={ closeHandler }>Cancel</Button>
                    <Button variant="contained" color="success" /* disabled={ reason === 0 } */ onClick={ submitCallback } >Submit</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}