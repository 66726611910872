import { useEffect, useState } from "react";
import { GetActiveUsers } from "../services/UsersService";
import { NameIdObj } from "../common/models/GenericTypes";

const activeUsersLocalSettingName = 'ActiveUsers';

export default function useActiveUsers() {
    const [users, setUsers] = useState<NameIdObj[]>([]);
    useEffect(() => {
        const getData = async () => {
            const cachedUsers = localStorage.getItem(activeUsersLocalSettingName);
            if (cachedUsers) setUsers(JSON.parse(cachedUsers));
            else {
                const res = await GetActiveUsers();
                if (res) {
                    const data: NameIdObj[] = res.map(u => ({ id: u.id, name: u.displayName }));
                    localStorage.setItem(activeUsersLocalSettingName, JSON.stringify(data));
                    setUsers(data);
                }
            }
        };

        getData();
    }, []);

    return users;
}