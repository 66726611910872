import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { SxProps } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import moment from "moment";
import React, { useCallback, useState } from "react";
import * as XLSX from "xlsx";
import { GetCurrentContractorsReportData } from "services/PlacementsService";
import UserPicker from "components/Pickers/UserPicker";
import { NameIdObj } from "common/models/GenericTypes";
import { ReportDataProcessDateFields } from "util/ReportUtils";

interface Props {
    description: string,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void
}

const ownerSpecialOptions: NameIdObj[] = [
    { id: 0, name: 'My Company' },
    { id: -1, name: 'Me' },
    { id: -2, name: 'My Team' },
];

const filterElementStyle: SxProps = { flex: '1 1 0', mr: 1 };

export default function CurrentContractorsReport({ description, loadingHandler, errorHandler }: Props) {
    const [ownerId, setOwnerId] = useState(0);
    const [using, setUsing] = useState(0);

    var cur_time = new Date();
    var year = cur_time.getFullYear();
    var month = cur_time.getUTCMonth() + 1;
    var date = (new Date(year, month, 0)).getDate();
    var month_first_day = year + "-" + (month < 10 ? "0" : "") + month + "-01";
    var month_cur_day = year + "-" + (month < 10 ? "0" : "") + month + "-" + (date < 10 ? "0" : "") + date;

    const [as_at_date, setAsAtDate] = useState(month_first_day);
    const [start_date, setStartDate] = useState(month_first_day);
    const [end_date, setEndDate] = useState(month_cur_day);

    const getDataCallback = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        const res = await GetCurrentContractorsReportData(ownerId, as_at_date, start_date, end_date, using, errorHandler);
        if (res) {
            ReportDataProcessDateFields(res as any[]);
            
            const m = moment();
            const filename = `CurrentContractors${m.format('YYYYMMDDhhmmss')}.xlsx`;
            const ws = XLSX.utils.json_to_sheet(res);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws);
            XLSX.writeFile(wb, filename);
        }
        loadingHandler && loadingHandler(false);
    }, [ownerId, as_at_date, start_date, end_date,  using, errorHandler, loadingHandler]);

    return (
        <>
            <Box display="flex">
                <Box flex="1 1 0" pr={1}>
                    <UserPicker disableClearable label="Consultant" userId={ownerId} onSelect={u => setOwnerId(u ? u.id : 0)} appendToStart={ownerSpecialOptions} includeInactiveUsers={true} />
                </Box>
                <TextField select label="Using" value={using.toString()} onChange={({ target }) => setUsing(+target.value)} sx={filterElementStyle}>
                    <MenuItem value="0">Snapshot</MenuItem>
                    <MenuItem value="1">As at</MenuItem>
                    <MenuItem value="2">During</MenuItem>
                </TextField>

                {using === 1 && <TextField label="As at Date" value={as_at_date.toString()} defaultValue={month_first_day} onChange={({ target }) => setAsAtDate("" + target.value)} type="date" sx={filterElementStyle} />}
                {using === 2 && <TextField label="From Date" value={start_date.toString()} defaultValue={month_first_day} onChange={({ target }) => setStartDate("" + target.value)} type="date" sx={filterElementStyle} />}
                {using === 2 && <TextField label="To Date" value={end_date.toString()} defaultValue={month_cur_day} onChange={({ target }) => setEndDate("" + target.value)} type="date" sx={filterElementStyle} />}

                <Button variant="contained" color="success" onClick={getDataCallback}>Run Report</Button>
            </Box>
            <Typography component="div" variant="h6" mt={2}>Report Description</Typography>
            <Typography component="div" variant="body2">{description}</Typography>
        </>
    );
}
