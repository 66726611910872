import React, { useCallback, useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { JobStage } from "../../../common/models/Jobs/Job";
import { Checkbox, FormControlLabel, MenuItem } from "@mui/material";

interface Props {
    jobStage: JobStage | null,
    open: boolean,
    submitHandler: (jobStage: JobStage) => void,
    closeHandler: () => void,
}

export default function JobStageDialog({ open,jobStage,submitHandler, closeHandler }: Props) {
    const [formValues, setFormValues] = useState({
        name: '',
        enabled: 'True',
      });
    
      const [errors, setErrors] = useState({
        name: '',
      });

      useEffect(() => {
        if(jobStage){
          setFormValues({
            name: jobStage.name ?? '',
            enabled: jobStage.enabled == true ? 'true' : 'false'
          });
        } else {
          setFormValues({
            name:  '',
            enabled: 'true'
          });
        }

    }, [jobStage]);
    
    const handleChange = (e : any) => {
      const { name, value } = e.target;
      setFormValues({ ...formValues, [name]: value });
      setErrors({ ...errors, [name]: '' });
    };
    
      const validate = () => {
        let tempErrors = { ...errors };
        tempErrors.name = formValues.name ? '' : 'Stage name is required.';


    
        setErrors(tempErrors);
        return Object.values(tempErrors).every(x => x === '');
      };

    const handleSubmit = () => {
        if (validate()) {
            let stage: JobStage = {
                name: formValues.name,
                enabled: stringToBoolean(formValues.enabled),
                id: jobStage?.id ?? 0,
                stageOrder: jobStage?.stageOrder ?? -1
            };
            submitHandler(stage);

        }
      };

      const stringToBoolean = (str: string): boolean => {
        const truthyValues = ['true', '1', 'yes'];
        return truthyValues.includes(str.toLowerCase().trim());
    }

      const handleClose = () => {
        closeHandler();
      }

    return (
        <>
            <Dialog open={open} fullWidth>
                <DialogTitle>{jobStage == null ? 'Add Stage' : 'Edit Stage'}</DialogTitle>
                <DialogContent>
                <TextField
                            margin="dense"
                            name="name"
                            label="Stage Name"
                            type="text"
                            fullWidth
                            variant="outlined"
                            sx={{mb: '15px'}}
                            value={formValues.name}
                            onChange={handleChange}
                            error={!!errors.name}
                            helperText={errors.name}/>
               <TextField
                        sx={{minWidth: '100%', mt: '10px'}}
                        select
                        label="Status"
                        name="enabled"
                        value={formValues.enabled}
                        onChange={handleChange}
                    >
                        <MenuItem value="true">Enabled</MenuItem>
                        <MenuItem value="false">Disabled</MenuItem>
                    </TextField>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="error" onClick={ handleClose }>Cancel</Button>
                    <Button variant="contained" color="success" onClick={handleSubmit} >Submit</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}