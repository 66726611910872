import Box from "@mui/material/Box";
import React, { useMemo } from "react";
import JobBoardViewField from "./JobBoardViewField";

interface Props {
    jobBoardId: number,
    advertId: number,
    attributes?: Record<string, string>,
    advertRecordAttributes?: Record<string, string>,
}

const jobTypeMap: Record<string, string> = {
    "1": "Employee",
    "3": "Intern",
    "20": "Seasonal",
    "2": "Temporary / Contract",
    "75": "Volunteer",
};

export default function MonsterView({ jobBoardId, advertId, attributes, advertRecordAttributes }: Props) {
    const atts = useMemo<Record<string, string>>(() => {
        if (attributes) return attributes;
        return {};
    }, [attributes]);

    const recordAtts = useMemo<Record<string, string>>(() => {
        if (advertRecordAttributes) return advertRecordAttributes;
        return {};
    }, [advertRecordAttributes]);

    const jobType = useMemo(() => atts.JobType ?? '', [atts.JobType]);

    const classification = useMemo(() => recordAtts.classification ?? '', [recordAtts.classification]);
    const subClassification = useMemo(() => recordAtts.subClassification ?? '', [recordAtts.subClassification]);
    const country = useMemo(() => recordAtts.country ?? '', [recordAtts.country]);
    const region = useMemo(() => recordAtts.region ?? '', [recordAtts.region]);
    const location = useMemo(() => recordAtts.location ?? '', [recordAtts.location]);
    const workType = useMemo(() => recordAtts.workType ?? '', [recordAtts.workType]);
    const currencyName = useMemo(() => recordAtts.currencyName ?? '', [recordAtts.currencyName]);
    const salaryType = useMemo(() => recordAtts.salaryType ?? '', [recordAtts.salaryType]);
    const salaryFrom = useMemo(() => recordAtts.salaryFrom ?? '', [recordAtts.salaryFrom]);
    const salaryTo = useMemo(() => recordAtts.salaryTo ?? '', [recordAtts.salaryTo]);

    return (
        <Box display="flex" gap={2} pt={2}>
            <Box display="flex" flexDirection="column" gap={2} width="50%">
                <JobBoardViewField label="Category" value={classification} />
                <JobBoardViewField label="Sub Category" value={subClassification} />
                <JobBoardViewField label="Country" value={country} />
                <JobBoardViewField label="Region" value={region} />
                <JobBoardViewField label="Location" value={location} />
            </Box>
            <Box display="flex" flexDirection="column" gap={2} width="50%">
                <JobBoardViewField label="Job Type" value={jobTypeMap[jobType] ?? ''} />
                <JobBoardViewField label="Work Type" value={workType} />
                <JobBoardViewField label="Pay Currency" value={currencyName} />
                <JobBoardViewField label="Pay Type" value={salaryType} />
                <Box display="flex" gap={2}>
                    <JobBoardViewField label="Salary From" sx={{ flex: '1 1 0' }} value={salaryFrom} />
                    <JobBoardViewField label="Salary To" sx={{ flex: '1 1 0' }} value={salaryTo} />
                </Box>
            </Box>
        </Box>
    );
}