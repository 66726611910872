import { JobsConfigOptions } from "common/models/Configuration/Jobs";
import { ChangeTracker } from '../../../common/models/hooks/ChangeTracker';

export const DefaultOptions: JobsConfigOptions = {
    AllowResetStage: 'True',
    AutomatedRejectionsAdministrative: '0',
    AutomatedRejectionsBudgetNotApproved: '0',
    AutomatedRejectionsClientDidNotWinWork: '0',
    AutomatedRejectionsFilledByClient: '0',
    AutomatedRejectionsFilledByCompetitor: '0',
    AutomatedRejectionsFilledByUs: '0',
    AutomatedRejectionsNotSupplied: '0',
    AutomatedRejectionsUnknown: '0',
    AutomatedRejectionsWithdrawn: '0',
    AutomatedRejectionsRedFlaggedCandidates: '0',
    AutomatedRejectionsRedFlaggedCandidatesDelayInMinutes: 4320,
    ExclusiveJobsByDefault: 'False',
    JobDefaultWorkType: '2',
    JobStartDateOffsetContract: '30',
    JobStartDateOffsetFTC: '60',
    JobStartDateOffsetPerm: '60',
    MandatoryRejectionEmails: 'False',
    OnHold: 'False',
    RejectionEmails: 'True',
    structureddeadlinechanges: 'True',
    WorkflowSortOrder: '0',
    JobDefaultHoursPerDay: '8',
    JobDefaultDaysPerWeek: '5',
    JobDefaultSummary: '',
}

export const NoChangesTracker: ChangeTracker<JobsConfigOptions> = {
    AllowResetStage: false,
    AutomatedRejectionsAdministrative: false,
    AutomatedRejectionsBudgetNotApproved: false,
    AutomatedRejectionsClientDidNotWinWork: false,
    AutomatedRejectionsFilledByClient: false,
    AutomatedRejectionsFilledByCompetitor: false,
    AutomatedRejectionsFilledByUs: false,
    AutomatedRejectionsNotSupplied: false,
    AutomatedRejectionsUnknown: false,
    AutomatedRejectionsWithdrawn: false,
    AutomatedRejectionsRedFlaggedCandidates: false,
    AutomatedRejectionsRedFlaggedCandidatesDelayInMinutes: false,
    ExclusiveJobsByDefault: false,
    JobDefaultWorkType: false,
    JobStartDateOffsetContract: false,
    JobStartDateOffsetFTC: false,
    JobStartDateOffsetPerm: false,
    MandatoryRejectionEmails: false,
    OnHold: false,
    RejectionEmails: false,
    structureddeadlinechanges: false,
    WorkflowSortOrder: false,
    JobDefaultDaysPerWeek: false,
    JobDefaultHoursPerDay: false,
    JobDefaultSummary: false,
}