import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { VolcanicDiscipline } from "common/models/JobPosting/Volcanic";
import SlowTextFieldWrapper from "components/SlowTextFieldWrapper";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { GetVolcanicBoardSetupData } from "services/JobBoardsService";

interface Props {
    jobBoardId: number,
    advertId: number,
    attributes?: Record<string, string>,
    advertRecordAttributes?: Record<string, string>,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
    attributeChangeHandler?: (jobBoardId: number, key: string, value: string) => void
}

export default function VolcanicEdit({ jobBoardId, advertId, attributes, advertRecordAttributes, loadingHandler, errorHandler, attributeChangeHandler }: Props) {
    const [disciplines, setDisciplines] = useState<VolcanicDiscipline[]>([]);
    const [subDisciplinesMap, setSubDisciplinesMap] = useState<Record<string, VolcanicDiscipline[]>>({});

    useEffect(() => {
        const getSetupData = async () => {
            loadingHandler && loadingHandler(true);
            const res = await GetVolcanicBoardSetupData(jobBoardId, advertId, errorHandler);
            let parents: VolcanicDiscipline[] = [];
            let map: Record<string, VolcanicDiscipline[]> = {};
            if (res) {
                for (let i = 0; i < res.length; i++) {
                    const item = res[i];
                    if (item.parent_id) {
                        if (map[item.parent_id]) map[item.parent_id].push(item);
                        else map[item.parent_id] = [item];
                    }
                    else parents.push(item);
                }
            }
            setDisciplines(parents);
            setSubDisciplinesMap(map);
            loadingHandler && loadingHandler(false);
        };
        getSetupData();
    }, [advertId, errorHandler, jobBoardId, loadingHandler]);

    const atts = useMemo<Record<string, string>>(() => {
        if (attributes) return attributes;
        return {};
    }, [attributes]);

    const recordAtts = useMemo<Record<string, string>>(() => {
        if (advertRecordAttributes) return advertRecordAttributes;
        return {};
    }, [advertRecordAttributes]);

    const country = useMemo(() => recordAtts.country ?? '', [recordAtts.country]);
    const region = useMemo(() => recordAtts.region ?? '', [recordAtts.region]);
    const location = useMemo(() => recordAtts.location ?? '', [recordAtts.location]);
    const salaryType = useMemo(() => recordAtts.salaryType ?? '', [recordAtts.salaryType]);
    const salaryFrom = useMemo(() => recordAtts.salaryFrom ?? '', [recordAtts.salaryFrom]);
    const salaryTo = useMemo(() => recordAtts.salaryTo ?? '', [recordAtts.salaryTo]);
    const displaySalary = useMemo(() => recordAtts.displaySalary ?? '', [recordAtts.displaySalary]);

    useEffect(() => attributeChangeHandler && attributeChangeHandler(jobBoardId, 'Country', country), [attributeChangeHandler, country, jobBoardId]);
    useEffect(() => attributeChangeHandler && attributeChangeHandler(jobBoardId, 'Region', region), [attributeChangeHandler, region, jobBoardId]);
    useEffect(() => attributeChangeHandler && attributeChangeHandler(jobBoardId, 'Location', location), [attributeChangeHandler, location, jobBoardId]);
    useEffect(() => attributeChangeHandler && attributeChangeHandler(jobBoardId, 'SalaryType', salaryType), [attributeChangeHandler, salaryType, jobBoardId]);
    useEffect(() => attributeChangeHandler && attributeChangeHandler(jobBoardId, 'SalaryMin', salaryFrom), [attributeChangeHandler, salaryFrom, jobBoardId]);
    useEffect(() => attributeChangeHandler && attributeChangeHandler(jobBoardId, 'SalaryMax', salaryTo), [attributeChangeHandler, salaryTo, jobBoardId]);
    useEffect(() => attributeChangeHandler && attributeChangeHandler(jobBoardId, 'Salary', displaySalary), [attributeChangeHandler, displaySalary, jobBoardId]);

    const jobType = useMemo(() => atts.JobType ?? '', [atts.JobType]);
    const discipline = useMemo(() => atts.Discipline ?? '', [atts.Discipline]);
    const subDiscipline = useMemo(() => atts.SubDiscipline ?? '', [atts.SubDiscipline]);
    const contractDuration = useMemo(() => atts.ContractDuration ?? '', [atts.ContractDuration]);
    const salaryBenefits = useMemo(() => atts.SalaryBenefits ?? '', [atts.SalaryBenefits]);

    const renderedDisciplines = useMemo(() => {
        return disciplines.map(d => <MenuItem key={d.reference} value={d.reference}>{d.name}</MenuItem>);
    }, [disciplines]);

    const renderedSubDisciplines = useMemo(() => {
        if (discipline) {
            const opts = subDisciplinesMap[discipline];
            if (opts) return opts.map(o => <MenuItem key={o.reference} value={o.reference}>{o.name}</MenuItem>);
        }
        return [];
    }, [discipline, subDisciplinesMap]);

    const localChangeHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        attributeChangeHandler && attributeChangeHandler(jobBoardId, name, value);
    }, [attributeChangeHandler, jobBoardId]);

    const contractDurationChangeHandler = useCallback((newValue: string) => {
        attributeChangeHandler && attributeChangeHandler(jobBoardId, 'ContractDuration', newValue);
    }, [attributeChangeHandler, jobBoardId]);

    const salaryBenefitsChangeHandler = useCallback((newValue: string) => {
        attributeChangeHandler && attributeChangeHandler(jobBoardId, 'SalaryBenefits', newValue);
    }, [attributeChangeHandler, jobBoardId]);

    return (
        <Box display="flex" gap={2} pt={2}>
            <Box display="flex" flexDirection="column" gap={2} width="50%">
                <TextField select label="Job Type" name="JobType" value={jobType} onChange={localChangeHandler}>
                    <MenuItem value=""></MenuItem>
                    <MenuItem value="perm">Permanent</MenuItem>
                    <MenuItem value="temp">Temporary / Contract</MenuItem>
                </TextField>
                <TextField select label="Discipline" name="Discipline" value={discipline} onChange={localChangeHandler}>
                    <MenuItem value=""></MenuItem>
                    {renderedDisciplines}
                </TextField>
                <TextField select label="Sub Discipline" name="SubDiscipline" value={subDiscipline} onChange={localChangeHandler}>
                    <MenuItem value=""></MenuItem>
                    {renderedSubDisciplines}
                </TextField>
                <SlowTextFieldWrapper label="Job Duration" value={contractDuration} onChange={contractDurationChangeHandler} />
            </Box>
            <Box display="flex" flexDirection="column" gap={2} width="50%">
                <TextField disabled label="Country" value={country} />
                <TextField disabled label="Region" value={region} />
                <TextField disabled label="Location" value={location} />
                <TextField disabled label="Salary Type" value={salaryType} />
                <Box display="flex" gap={2}>
                    <TextField disabled label="Salary From" sx={{ flex: '1 1 0' }} value={salaryFrom} />
                    <TextField disabled label="Salary To" sx={{ flex: '1 1 0' }} value={salaryTo} />
                </Box>
                <TextField disabled label="Display Salary" value={displaySalary} />
                <SlowTextFieldWrapper label="Salary Benefits" value={salaryBenefits} onChange={salaryBenefitsChangeHandler} />
            </Box>
        </Box>
    );
}