import React, { useEffect, useMemo, useState } from "react";
import { ComplianceChecklist } from "common/models/Configuration/Compliance";
import { GetComplianceChecklists } from "services/ComplianceService";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

interface Props {
    checklistId: number | null,
    onSelect: (item: ComplianceChecklist | null) => void,
    variant?: "standard" | "filled" | "outlined" | undefined,
    ignoreIds?: number[],
    label?: string,
    disableClearable?: boolean
}

export default function ComplianceChecklistPicker({ checklistId, onSelect, variant, ignoreIds, label, disableClearable }: Props) {
    const [options, setOptions] = useState<ComplianceChecklist[]>([]);

    useEffect(() => {
        const getData = async () => {
            const res = await GetComplianceChecklists(false);
            if (res) setOptions(res);
        };
        getData();
    }, []);

    const filteredOptions = useMemo(() => {
        if (ignoreIds && ignoreIds.length > 0 && options.length > 0){
            return options.filter(v => !ignoreIds.includes(v.id));
        }
        else return options;
    }, [options, ignoreIds]);

    const checklist = useMemo(() => {
        if (checklistId === null) return null;
        const checklist = options.find(c => c.id === checklistId);
        if (checklist) return checklist;
        return null;
    }, [checklistId, options]);

    return (
        <Autocomplete
            size="small"
            value={checklist}
            options={filteredOptions}
            getOptionLabel={ o => o.name }
            isOptionEqualToValue={ (o,v) => o.id === v.id }
            onChange={ (e,v) => onSelect(v) }
            renderInput={ params => <TextField {...params} variant={variant} label={label} inputProps={{ ...params.inputProps, "data-lpignore": "true" }} /> }
        />
    );
}