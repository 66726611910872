import { PanelModel } from "@syncfusion/ej2-layouts/src/dashboard-layout/dashboard-layout-model";
import { ScreenLayoutSettings } from "common/models/ScreenLayouts/ScreenLayouts";
import { SubmissionRecordDashboardElementDefinition, SubmissionRecordDashboardElementType } from "common/models/Dashboard/EditLayout";

export const DefaultSubmissionRecordDashboardElements: SubmissionRecordDashboardElementDefinition[] = [
    { id: "SubmissionJobId_Default", row: 0, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'SubmissionJobId' },
    { id: "SubmissionType_Default", row: 1, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'SubmissionType' },
    { id: "SubmissionDate_Default", row: 2, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'SubmissionDate' },
    { id: "SubmissionDistributionMode_Default", row: 3, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'SubmissionDistributionMode' },
    { id: "SubmissionCandidateNotificationSent_Default", row: 4, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'SubmissionCandidateNotificationSent' },
    { id: "SubmissionClientRate_Default", row: 5, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'SubmissionClientRate' },
    { id: "SubmissionCandidateRate_Default", row: 6, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'SubmissionCandidateRate' },
    { id: "SubmissionAvailability_Default", row: 7, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'SubmissionAvailability' },
    { id: "SubmissionRating_Default", row: 8, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'SubmissionRating' },
    { id: "SubmissionVideoLink_Default", row: 9, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'SubmissionVideoLink' },
    { id: "SubmissionAssignedTo_Default", row: 10, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'SubmissionAssignedTo' },
    
    { id: "SubmissionComments_Default", row: 0, col: 1, sizeX: 2, sizeY: 8, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: null, type: 'SubmissionComments' },
];

export const DefaultSubmissionRecordDashboardElements_Float: SubmissionRecordDashboardElementDefinition[] = [
    { id: "SubmissionJobTitle_Default", row: 0, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'SubmissionJobTitle' },
    { id: "SubmissionType_Default", row: 1, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'SubmissionType' },
    { id: "SubmissionDate_Default", row: 2, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'SubmissionDate' },
    { id: "SubmissionCandidateNotificationSent_Default", row: 3, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'SubmissionCandidateNotificationSent' },
    { id: "SubmissionClientRate_Default", row: 4, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'SubmissionClientRate' },
    { id: "SubmissionCandidateRate_Default", row: 5, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'SubmissionCandidateRate' },
    { id: "SubmissionAvailability_Default", row: 6, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'SubmissionAvailability' },
    { id: "SubmissionRating_Default", row: 7, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'SubmissionRating' },
    { id: "SubmissionVideoLink_Default", row: 8, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'SubmissionVideoLink' },
    { id: "SubmissionAssignedTo_Default", row: 9, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'SubmissionAssignedTo' },
    
    { id: "SubmissionComments_Default", row: 0, col: 1, sizeX: 2, sizeY: 8, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: null, type: 'SubmissionComments' },
];

export const GetMinSizeByElementType = (type: SubmissionRecordDashboardElementType) => {
    switch (type) {
        default: return [1, 1];
    }
};

export const GetElementTypeById = (id: string): SubmissionRecordDashboardElementType => {
    if (id && id.startsWith('Divider_')) return 'Divider';
    if (id && id.startsWith('Spacer_')) return 'Spacer';
    if (id && id.startsWith('SubmissionJobId_')) return 'SubmissionJobId';
    if (id && id.startsWith('SubmissionJobTitle_')) return 'SubmissionJobTitle';
    if (id && id.startsWith('SubmissionType_')) return 'SubmissionType';
    if (id && id.startsWith('SubmissionDate_')) return 'SubmissionDate';
    if (id && id.startsWith('SubmissionClientRate_')) return 'SubmissionClientRate';
    if (id && id.startsWith('SubmissionCandidateRate_')) return 'SubmissionCandidateRate';
    if (id && id.startsWith('SubmissionRating_')) return 'SubmissionRating';
    if (id && id.startsWith('SubmissionVideoLink_')) return 'SubmissionVideoLink';
    if (id && id.startsWith('SubmissionComments_')) return 'SubmissionComments';
    if (id && id.startsWith('SubmissionAvailability_')) return 'SubmissionAvailability';
    if (id && id.startsWith('SubmissionCandidateNotificationSent_')) return 'SubmissionCandidateNotificationSent';
    // if ( id && id.startsWith('CustomField')) return GetCustomFieldTypeById(id);
    return '';
};

export const GetPanelDefinitionsFromPanelModels = (panels: PanelModel[]) => {
    let elements: SubmissionRecordDashboardElementDefinition[] = [];
    panels.forEach((p, i) => {
        let type = GetElementTypeById(p.id ?? '');
        elements.push({
            id: p.id ?? i.toString(),
            sizeX: p.sizeX ?? 1,
            sizeY: p.sizeY ?? 1,
            col: p.col ?? 0,
            row: p.row ?? 0,
            minSizeX: p.minSizeX ?? 1,
            minSizeY: p.minSizeY ?? 1,
            maxSizeX: p.maxSizeX ?? 1,
            maxSizeY: p.maxSizeY ?? null,
            type: type
        });
    });
    return elements ;
};

export const SubmissionScreenLayoutSettings: ScreenLayoutSettings = {
    unitWidth: 600,
    unitHeight: 40,
    gapX: 10,
    gapY: 0,
    columns: 3,
    mediaQueryMaxWidth: '1000px'
};