import Alert from "components/Alert";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { AdvertSearch } from "common/models/Search/AdvertSearch";
import AdvertsGridComponent from "components/Grids/AdvertsGrid";
import UserPicker from "components/Pickers/UserPicker";
import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import PageContentLayout from "layouts/PageContentLayout";
import PageLayout from "layouts/PageLayout";
import { NameIdObj } from "common/models/GenericTypes";
import Box from "@mui/material/Box";
import { SxProps } from "@mui/material/styles";
import { useNavigate, useSearchParams } from "react-router-dom";
import SaveSearchDialog from "components/Dialogs/SaveSearchDialog";
import { SaveAdvertsSearch, UpdateAdvertsSavedSearch } from "services/AdvertsService";
import { GetSavedSearchById } from "services/SavedSearchesService";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

const ownerSpecialOptions: NameIdObj[] = [
    { id: -1, name: 'Me' },
    { id: -2, name: 'My Team' },
    { id: 0, name: 'My Company' },
    { id: -3, name: 'Unassigned' },
];

const filterElementStyle: SxProps = { flex: '1 1 0' };

export default function AdvertsSearchPage() {
    const [activeTab, setActiveTab] = useState('criteria');
    const [isLoading, setIsLoading] = useState(false);
    const [isGridLoading, setIsGridLoading] = useState(false);

    const [ownerId, setOwnerId] = useState(0);
    const [status, setStatus] = useState("All");
    const [jobType, setJobType] = useState("All");
    const [timeRange, setTimeRange] = useState(999);
    const [searchData, setSearchData] = useState<AdvertSearch | undefined>();
    const [showGrid, setShowGrid] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isFetchingSavedSearch, setIsFetchingSavedSearch] = useState(false);
    const [showSaveSearchDialog, setShowSaveSearchDialog] = useState(false);
    const [savedSearchName, setSavedSearchName] = useState('');
    const [shouldUpdateSavedSearch, setShouldUpdateSavedSearch] = useState(false);
    const [loadedSavedSearchName, setLoadedSavedSearchName] = useState('');
    const [loadedSavedSearchFrequencyId, setLoadedSavedSearchFrequencyId] = useState(0);
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const ssid = useMemo(() => {
        const v = searchParams.get('ssid');
        if (v) return +v;
        return null;
    }, [searchParams]);

    useEffect(() => {
        const getSavedSearch = async () => {
            if (ssid) {
                setIsFetchingSavedSearch(true);
                const res = await GetSavedSearchById(ssid, setErrorMessage);
                if (res && res.json) {
                    setSavedSearchName(res.name);
                    const t1 = JSON.parse(res.json);
                    if (t1.Status) setStatus(t1.Status);
                    if (t1.TimeRange) setTimeRange(t1.TimeRange);
                    if (t1.Owner) {
                        if (t1.Owner === 'All') setOwnerId(0);
                        else if (t1.Owner === 'Me') setOwnerId(-1);
                        else if (t1.Owner === 'Team') setOwnerId(-2);
                        else if (t1.Owner === 'Unassigned') setOwnerId(-3);
                        else setOwnerId(+t1.Owner);
                    }
                    if (t1.JobType) setJobType(t1.JobType);

                    setLoadedSavedSearchName(res.name);
                    setLoadedSavedSearchFrequencyId(res.autoRunFrequency);
                }
                setIsFetchingSavedSearch(false);
            }
        };
        ssid && getSavedSearch();
    }, [ssid]);

    useEffect(() => {
        if(ssid && !isFetchingSavedSearch) {
            setShouldUpdateSavedSearch(true);
        }
    }, [ssid, isFetchingSavedSearch, ownerId, status]);

    const searchCallback = useCallback(async () => {
        let s: AdvertSearch = {
            JobType: jobType,
            Owner: ownerId,
            Status: status,
            TimeRange: timeRange
        };

        if (shouldUpdateSavedSearch && ssid) {
            const res = await UpdateAdvertsSavedSearch(ssid, loadedSavedSearchName, loadedSavedSearchFrequencyId, s);
            if (res) setShouldUpdateSavedSearch(false);
        }

        setSearchData(s);
        setShowGrid(true);
        setActiveTab('results');
    }, [jobType, loadedSavedSearchFrequencyId, loadedSavedSearchName, ownerId, shouldUpdateSavedSearch, ssid, status, timeRange]);

    const saveSearchCallback = useCallback(async (searchName: string, searchFreq: number) => {
        let s: AdvertSearch = {
            JobType: jobType,
            Owner: ownerId,
            Status: status,
            TimeRange: timeRange
        };

        setIsLoading(true);
        var newSsid = await SaveAdvertsSearch(searchName, searchFreq, s, setErrorMessage);
        if (newSsid) {
            setShowSaveSearchDialog(false);
            navigate(`/adverts?ssid=${newSsid.value}`);
        }
        setIsLoading(false);
    }, [jobType, navigate, ownerId, status, timeRange]);

    const clearCallback = useCallback(() => {
        setOwnerId(0);
        setStatus('All');
        setJobType('All');
        setTimeRange(999);
        setShowGrid(false);
    }, []);

    useEffect(() => {
        if (!ssid) {
            setSavedSearchName('');
            clearCallback();
        }
    }, [clearCallback, ssid]);

    const summaryBar = useMemo(() => {
        const clearAction = <Button variant="contained" color="error" onClick={clearCallback} sx={{ mr: '5px' }}>Clear</Button>;
        const saveAction = <Button variant="contained" onClick={() => setShowSaveSearchDialog(true)} sx={{ mr: '5px' }}>Save</Button>;
        const searchAction = <Button variant="contained" color="success" onClick={searchCallback}>Search</Button>;
        const actions = showGrid && !Boolean(ssid) ? <>{clearAction}{saveAction}{searchAction}</> : <>{clearAction}{searchAction}</>;
        
        const title = Boolean(savedSearchName) ? `Adverts > Search > ${savedSearchName}` : 'Adverts > Search';
        return <TitleAndActionSummaryBar title={title} browserTabTitle="Search > Adverts"  action={actions} />;
    }, [clearCallback, savedSearchName, searchCallback, showGrid, ssid]);

    useEffect(() => setIsLoading(isFetchingSavedSearch || isGridLoading), [isFetchingSavedSearch, isGridLoading]);

    const keyUpHandler = useCallback((e: KeyboardEvent) => {
        if (e.code === 'Enter') searchCallback();
    }, [searchCallback]);

    useEffect(() => {
        window.addEventListener('keyup', keyUpHandler);
        return () => window.removeEventListener('keyup', keyUpHandler);
    }, [keyUpHandler]);

    useEffect(() => {
        const item = document.querySelector<HTMLElement>('#advert-search-collapse-root>.MuiCollapse-wrapper>.MuiCollapse-wrapperInner');
        if (item) {
            item.style.display = 'flex';
            item.style.flexDirection = 'column';
        }
    }, []);

    const handleTabChange = useCallback((e: React.SyntheticEvent, newValue: string) => {
        setActiveTab(newValue);
    }, []);

    return (
        <PageLayout SummaryBar={summaryBar} paddingTop={0}>
            <Snackbar open={successMessage !== ''} autoHideDuration={3000} onClose={() => setSuccessMessage('')}>
                <Alert onClose={() => setSuccessMessage('')}>Changes Saved</Alert>
            </Snackbar>
            <Snackbar open={errorMessage !== ''} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <SaveSearchDialog open={showSaveSearchDialog} closeHandler={() => setShowSaveSearchDialog(false)} saveHandler={saveSearchCallback} disableFrequencyField />
            <Tabs value={activeTab} onChange={handleTabChange}>
                <Tab value="criteria" label="Criteria" />
                <Tab value="results" label="Results"/>
            </Tabs>
            <PageContentLayout showLoading={isLoading}>
                <Stack spacing={2} paddingTop={2} display={activeTab === 'criteria' ? undefined : 'none'}>
                    <Box display="flex" gap={1}>
                        <UserPicker label="Owner" userId={ownerId} onSelect={ u => u && setOwnerId(u.id) } appendToStart={ownerSpecialOptions} sx={filterElementStyle} />
                        <TextField select label="Status" value={status.toString()} onChange={ ({target}) => setStatus(target.value) } sx={filterElementStyle}>
                            <MenuItem value="All">All</MenuItem>
                            <MenuItem value="1">Draft</MenuItem>
                            <MenuItem value="2">Qualified</MenuItem>
                            <MenuItem value="3">Awaiting Posting</MenuItem>
                            <MenuItem value="4">Partially Posted</MenuItem>
                            <MenuItem value="5">Posted</MenuItem>
                            <MenuItem value="6">Completed</MenuItem>
                            <MenuItem value="8">Expired</MenuItem>
                        </TextField>
                    </Box>
                    <Box display="flex" gap={1}>
                        <TextField select label="Job Type" value={jobType} onChange={({ target }) => setJobType(target.value)} sx={filterElementStyle}>
                            <MenuItem value="All">All</MenuItem>
                            <MenuItem value="Permanent">Permanent</MenuItem>
                            <MenuItem value="Contract">Contract</MenuItem>
                            <MenuItem value="FixedContract">Fixed Term</MenuItem>
                            <MenuItem value="TalentPool">Talent Pool</MenuItem>
                        </TextField>
                        <TextField select label="Time Range" value={timeRange.toString()} onChange={({ target }) => setTimeRange(+target.value)} sx={filterElementStyle}>
                            <MenuItem value="999">All</MenuItem>
                            <MenuItem value="1">1 Month</MenuItem>
                            <MenuItem value="2">2 Months</MenuItem>
                            <MenuItem value="3">3 Months</MenuItem>
                            <MenuItem value="6">6 Months</MenuItem>
                            <MenuItem value="12">12 Months</MenuItem>
                            <MenuItem value="24">24 Months</MenuItem>
                        </TextField>
                    </Box>
                </Stack>
                <Box display={activeTab === 'results' ? 'flex' : 'none'} flexGrow={1} flexDirection="column">
                    <AdvertsGridComponent gridName="adverts-search" source="search" searchData={searchData} loadingHandler={setIsGridLoading} errorHandler={setErrorMessage} />
                </Box>
            </PageContentLayout>
        </PageLayout>
    );
}