import React, { useCallback, useEffect, useMemo, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import { GridColDef, GridRenderCellParams, useGridApiRef } from "@mui/x-data-grid-premium";
import { GetJobCandidateStatusesForLookup } from "../../../services/ConfigurationService";
import {InterviewType} from "../../../common/models/Configuration/InterviewType";
import TitleAndActionSummaryBar from "../../../components/SummaryBars/TitleAndActionSummaryBar";
import PageContentLayout from "../../../layouts/PageContentLayout";
import { GetInterviewTypes, CreateType, UpdateType } from "../../../services/InterviewsService";
import GridWithStateWrapper from "../../../components/GridWidthStateWrapper";
import UnsavedChangesDialog from "../../../components/Dialogs/UnsavedChangesDialog";
import RWTextFieldComponent from "../../../components/RWTextFieldComponent";
import { NameIdObj } from "../../../common/models/GenericTypes";

interface Props {
    setSummaryBar?: (summaryBar: JSX.Element) => void
}

export default function InterviewTypes({ setSummaryBar }: Props) {
    const [isLoading, setIsLoading] = useState(false);
    const [rows, setRows] = useState<InterviewType[]>([]);
    const [initialDialogData, setInitialDialogData] = useState<InterviewType | null>(null);
    const [dialogData, setDialogData] = useState<InterviewType | null>(null);
    const [workflowStatuses, setWorkflowStatuses] = useState<NameIdObj[]>([]);
    const [showUnsavedChangesDialog, setShowUnsavedChangesDialog] = useState(false);
    const apiRef = useGridApiRef();

    const createBlankHandler = useCallback(async () => {
        const d: InterviewType = {
            id: 0,
            name: '',
            isEnabled: true,
            isSlot: false,
            isInternal: false,
            workflowStatusID: 0
        };

        setDialogData(d);
        setInitialDialogData(d);
        return true;
    }, []);

    useEffect(() => {
        const actionButton = <Button variant="contained" color="primary" onClick={createBlankHandler}>Create Type</Button>

        const sb = <TitleAndActionSummaryBar title="Configuration > Interview Types" browserTabTitle="Interview Types > Configuration"  action={actionButton} />;
        setSummaryBar && setSummaryBar(sb)
    }, [setSummaryBar, createBlankHandler]);


    useEffect(() => {
        const getData = async () => {
            setIsLoading(true);
            const data = await GetInterviewTypes();
            if(data) setRows(data);
            
            const statuses = await GetJobCandidateStatusesForLookup();
            
            if(statuses)
                setWorkflowStatuses(statuses);

            setIsLoading(false);
        };
        getData();
    }, []);

    const columns = useMemo<GridColDef[]>(() => {
        const dbNameRenderer = (params: GridRenderCellParams) => {
            return (
                <span
                    onClick={() => {
                        setDialogData(params.row);
                        setInitialDialogData(params.row);
                    }}
                    style={{ cursor: 'pointer', textDecoration: 'underline' }}
                >{params.value}</span>
            );
        };
        
        const IsEnabledRenderer = (params: GridRenderCellParams) => {
            if(params.value === true)
                return (<span>Yes</span>);
            if(params.value === false)
                return (<span>No</span>);
        };

        const workflowStatusNameRenderer = (params: GridRenderCellParams) => {
            const numValue = +params.value;
            const opt = workflowStatuses.find(w => w.id === numValue);
            return opt ? opt.name : '';
        };

        return [
            { field: 'id', headerName: 'ID' },
            { field: 'name', headerName: 'Name', width: 200, renderCell: dbNameRenderer },
            { field: 'isEnabled', headerName: 'Is Enabled', width: 150, renderCell: IsEnabledRenderer, align: "center", headerAlign: "center" },
            { field: 'isSlot', headerName: 'Is Slot', width: 150, renderCell: IsEnabledRenderer, align: "center", headerAlign: "center" },
            { field: 'isInternal', headerName: 'Is Internal', width: 150, renderCell: IsEnabledRenderer, align: "center", headerAlign: "center" },
            { field: 'workflowStatusID', headerName: 'Workflow Status', width: 200, renderCell: workflowStatusNameRenderer },
        ];
    }, [workflowStatuses]);

    const onChangeString = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setDialogData(prev => ( prev ? {...prev, [name]: value} : null));
    };

    const onChangeNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        const numValue = +value;
        setDialogData(prev => ( prev ? {...prev, [name]: numValue} : null));
    };

    const onSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = e.target;
        setDialogData(prev => ( prev ? {...prev, [name]: checked} : null));
    };

    const hasChanges = useMemo(() => {
        if (dialogData && initialDialogData) {
            if (dialogData.name !== initialDialogData.name) return true;
            if (dialogData.isEnabled !== initialDialogData.isEnabled) return true;
            if (dialogData.isSlot !== initialDialogData.isSlot) return true;
            if (dialogData.isInternal !== initialDialogData.isInternal) return true;
            if (dialogData.workflowStatusID !== initialDialogData.workflowStatusID) return true;
        }
        return false;
    }, [dialogData, initialDialogData]);

    const closeEditDialogHandler = useCallback(() => {
        setDialogData(null);
        setInitialDialogData(null);
        setShowUnsavedChangesDialog(false);
    }, []);

    const saveChanges = useCallback(async () => {
        if(dialogData){
            setIsLoading(true);

            let res : boolean | null = false;

            if(dialogData.id === 0)
                res = await CreateType(dialogData);
            else res = await UpdateType(dialogData.id, dialogData);

            if(res) {
                if(dialogData.id === 0) // Have to do full reload so we have an accurate Id value
                {
                    setIsLoading(true);
                    const data = await GetInterviewTypes();
                    if(data) setRows(data);

                    setIsLoading(false);
                } else { // We can just refresh the existing row data in UI
                    apiRef.current.updateRows([dialogData]);
                }
            }
            closeEditDialogHandler();
            setIsLoading(false);
        }
    }, [dialogData, apiRef, closeEditDialogHandler]);

    const closeHandlerWithConfirmation = useMemo(() => hasChanges ? () => setShowUnsavedChangesDialog(true) : closeEditDialogHandler, [hasChanges, closeEditDialogHandler]);

    return (
        <>
            {dialogData &&
                <Dialog open={true} maxWidth="sm" fullWidth onClose={ closeHandlerWithConfirmation }>
                    <DialogTitle>Interview Type - {dialogData.name}</DialogTitle>
                    <DialogContent dividers>
                        <Stack spacing={3}>
                            <RWTextFieldComponent
                                label="Name"
                                name="name"
                                value={dialogData.name}
                                onChange={onChangeString}
                            />
                            <FormControlLabel
                                control={<Switch checked={dialogData.isEnabled} name="isEnabled" onChange={onSwitchChange} />}
                                label="Is Enabled"
                            />
                            <FormControlLabel
                                control={<Switch checked={dialogData.isSlot} name="isSlot" onChange={onSwitchChange} />}
                                label="Is Slot"
                            />
                            <FormControlLabel
                                control={<Switch checked={dialogData.isInternal} name="isInternal" onChange={onSwitchChange} />}
                                label="Is Internal"
                            />
                            <TextField
                                select
                                label="Workflow Status"
                                name="workflowStatusID"
                                value={dialogData.workflowStatusID.toString()}
                                onChange={onChangeNumber}
                            >
                                <MenuItem value="0">None</MenuItem>
                                {workflowStatuses.map((s) => (
                                    <MenuItem key={s.id} value={s.id.toString()}>{s.name}</MenuItem>
                                ))}
                            </TextField>

                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="error" onClick={ closeHandlerWithConfirmation }>Cancel</Button>
                        <Button variant="contained" color="success" disabled={!hasChanges} onClick={saveChanges}>Save</Button>
                    </DialogActions>
                </Dialog>
            }
            <UnsavedChangesDialog
                open={showUnsavedChangesDialog}
                discardChangesHandler={closeEditDialogHandler}
                cancelHandler={() => setShowUnsavedChangesDialog(false)}
                saveChangesHandler={saveChanges}
                message="If you continue any changes made will be lost. Are you sure you wish to continue?"
                hideBackdrop
            />
            <PageContentLayout title="Interview Types" showLoading={isLoading}>
                <GridWithStateWrapper
                    gridName="configuration/interview-Types"
                    apiRef={apiRef}
                    rows={rows}
                    columns={columns}
                    disableRowSelectionOnClick
                    pagination={true}
                    density="compact"
                    pageSizeOptions={[100,250,500,1000]}
                />
            </PageContentLayout>
        </>
    );
}