import React, { useEffect, useMemo, useState } from "react";
import { Report } from "common/models/Reports/Report";
import ReportsGridComponent from "components/Reports/ReportsGrid";
import OnlyTitleSummaryBar from "components/SummaryBars/OnlyTitleSummaryBar";
import PageContentLayout from "layouts/PageContentLayout";
import PageLayout from "layouts/PageLayout";
import { GetReports } from "services/AdvertsService";
import { Link, useParams } from 'react-router-dom';
import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import DetailedDocumentsReport from "components/Reports/Documents/DocumentsDetailed";
import AdvertsAnalysisReport from "components/Reports/Adverts/AdvertsAnalysis";
import AdvertsAnalysisSnapshotReport from "components/Reports/Adverts/AdvertsAnalysisSnapshot";

const gridSummaryBar = <OnlyTitleSummaryBar title="Analytics > Adverts" browserTabTitle="Adverts > Analytics" />;
const ClientsReportsLink = <Link to="/analytics/adverts" style={{ color: 'inherit', textDecoration: 'underline' }}>Adverts</Link>;

export default function AdvertsReportsPage() {
    const [isLoading, setIsLoading] = useState(false);
    const [reports, setReports] = useState<Report[]>([]);
    const params = useParams();

    const reportName = useMemo(() => {
        const reportNameParam = params.reportName;
        return reportNameParam;
    }, [params.reportName]);

    const reportObj = useMemo(() => {
        return reports.find(r => r.pathName === reportName)
    }, [reports, reportName]);

    const summaryBar = useMemo(() => {
        if (reportObj) {
            const title = <>{'Analytics > '} {ClientsReportsLink} {' > ' + reportObj.title}</>;
            const tabTitle = reportObj.title + ' > Adverts > Analytics';
            return <TitleAndActionSummaryBar title={title} browserTabTitle={tabTitle} action={<></>} />;
        }
        return gridSummaryBar;
    }, [reportObj]);

    useEffect(() => {
        const getReports = async () => {
            setIsLoading(true);
            const res = await GetReports();
            if (res) setReports(res);
            setIsLoading(false);
        };
        getReports();
    }, []);

    return (
        <PageLayout SummaryBar={summaryBar}>
            <PageContentLayout showLoading={isLoading}>
                { !reportName && <ReportsGridComponent data={reports} /> }
                { reportObj && reportObj.pathName === 'documents' && <DetailedDocumentsReport entityTypeId={4} description={reportObj.longDescription} loadingHandler={setIsLoading} /> }
                { reportObj && reportObj.pathName === 'analysis' && <AdvertsAnalysisReport description={reportObj.longDescription} loadingHandler={setIsLoading} /> }
                { reportObj && reportObj.pathName === 'analysis-snapshot' && <AdvertsAnalysisSnapshotReport description={reportObj.longDescription} loadingHandler={setIsLoading} /> }
            </PageContentLayout>
        </PageLayout>
    );
}