import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-premium";
import { FilterWithTimeRange } from "common/models/Analytics/AnalyticsFilterData";
import { JobsByConsultantRecord } from "common/models/Analytics/BusinessIntelligence";
import GridWithStateWrapper from "components/GridWidthStateWrapper";
import React, { useEffect, useMemo, useState } from "react";
import { ResponsiveContainer, BarChart, CartesianGrid, XAxis, YAxis, Legend, Bar, Tooltip } from "recharts";
import { GetClosedJobsByConsultant } from "services/BusinessIntelligence";
import { TickWithHoverRenderer, VerticalBarChartYAxisWidth } from "util/AnalyticsGraphUtils";

interface Props {
    filterData: FilterWithTimeRange,
    graphType?: 'table' | 'graph',
    loadingHandler?: (isLoading: boolean) => void
}

interface JobsByConsultantRecordWithId extends JobsByConsultantRecord {
    id: number,
}

const gridName = 'analytics/closed-jobs/by-consultant';

export default function ClosedJobsByConsultantContent({ filterData, graphType, loadingHandler }: Props) {
    const [rows, setRows] = useState<JobsByConsultantRecordWithId[]>([]);
    const t = useTheme();

    useEffect(() => {
        const getData = async () => {
            loadingHandler && loadingHandler(true);
            const res = await GetClosedJobsByConsultant(filterData);
            if (res) {
                const data: JobsByConsultantRecordWithId[] = res.map((r, i) => ({ ...r, id: i }));
                setRows(data);
            }
            loadingHandler && loadingHandler(false);
        };
        getData();
    }, [filterData, loadingHandler]);

    const columns = useMemo<GridColDef[]>(() => {
        const exclusivePercentRenderer = (params: GridRenderCellParams) => {
            return `${params.value} %`
        };

        return [
            { field: 'consultant', headerName: 'Consultant Name', width: 250 },
            { field: 'exclusive', headerName: 'Exclusive Jobs', headerAlign: 'center', align: 'right', width: 150 },
            { field: 'competitive', headerName: 'Competitive Jobs', headerAlign: 'center', align: 'right', width: 150 },
            { field: 'total', headerName: 'Total Jobs', headerAlign: 'center', align: 'right' },
            { field: 'percentExclusive', headerName: '% Exclusive', headerAlign: 'center', align: 'right', renderCell: exclusivePercentRenderer },
        ];
    }, []);

    if (graphType === 'table') {
        return (
            <GridWithStateWrapper
                gridName={gridName}
                rows={rows}
                density="compact"
                columns={columns}
                sx={{ mt: '20px' }}
                initialState={{
                    pinnedColumns: { 
                        left: ['consultant'],
                    },
                }}
            />
        );
    }
    else if (graphType === 'graph') {
        return (
            <>
                <Typography variant="h6" padding={1} textAlign="center" fontWeight={600} component="div">Closed Jobs By Consultant</Typography>
                <div style={{ height: 'calc(100% - 48px)' }}>
                    <ResponsiveContainer width="100%" height="100%">
                        <BarChart width={150} height={150} layout="vertical" data={rows} margin={{ right: 50 }}>
                            <CartesianGrid strokeDasharray="3 3" vertical />
                            <XAxis type="number" tickFormatter={v => v.toLocaleString()} stroke={t.palette.text.primary} domain={[0, 'dataMax']} />
                            <YAxis dataKey="consultant" type="category" tick={ TickWithHoverRenderer } stroke={t.palette.text.primary} width={VerticalBarChartYAxisWidth} minTickGap={0} interval={0} />
                            <Legend />
                            <Tooltip />
                            <Bar name="Exclusive Jobs" dataKey="exclusive" stackId="a" fill={t.palette.info.main} />
                            <Bar name="Competitive Jobs" dataKey="competitive" stackId="a" fill={t.palette.error.main} />
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </>
        );
    }

    return <></>;
}