import { Typography } from "@mui/material";
import React, { useEffect } from "react";
import SummaryBarContainer from './SummaryBarContainer';

interface Props {
    title: string,
    browserTabTitle?: string
}

export default function OnlyTitleSummaryBar({ title, browserTabTitle }: Props) {
    useEffect(() => {
        document.title = `${browserTabTitle ?? title} | Recruit Wizard`;
    }, [title, browserTabTitle]);

    return (
        <SummaryBarContainer>
            <Typography variant="h6" fontSize="1.15rem">
                {title}
            </Typography>
        </SummaryBarContainer>
    );
}