import React, { useCallback, useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { List, ListItem, ListItemIcon, ListItemText, styled } from "@mui/material";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { NameIdObj } from "../../common/models/GenericTypes";
interface Props {
    open: boolean,
    title: string,
    orderItems: NameIdObj[]
    submitHandler: (items: NameIdObj[]) => void,
    closeHandler: () => void,
}

export default function OrderDialog({ open,orderItems,title,submitHandler, closeHandler }: Props) {
  const [items, setItems] = React.useState<NameIdObj[]>([]);

  useEffect(() => {
    setItems(orderItems)
}, [orderItems]);

const handleOnDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const reorderedItems = Array.from(items);
    const [reorderedItem] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, reorderedItem);

    setItems(reorderedItems);
};

    const handleSubmit = () => {
        submitHandler(items);
    };

    const handleClose = () => {
      closeHandler();
    }
     
    return (
        <>
            <Dialog open={open} fullWidth>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="droppable-list">
                {(provided) => (
                    <List
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                    >
                        {items.map((item, index) => (
                        <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                                {(provided) => (
                                    <ListItem
                                      sx={{border: '1px solid', mb: '5px', borderRadius: '5px', borderColor: "lightgray"}}
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                    >
                                        <ListItemIcon><DragIndicatorIcon/></ListItemIcon>
                                        <ListItemText primary={item.name} />
                                    </ListItem>
                                )}
                            </Draggable>

                            
                        ))}
                        {provided.placeholder}
                    </List>
                )}
            </Droppable>
        </DragDropContext>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="error" onClick={ handleClose }>Cancel</Button>
                    <Button variant="contained" color="success" onClick={handleSubmit} >Save</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}