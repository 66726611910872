import Button from "@mui/material/Button";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import TitleAndActionSummaryBar from "../SummaryBars/TitleAndActionSummaryBar";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import MenuItem from "@mui/material/MenuItem";
import * as tinymce from 'tinymce';
import UserPicker from "../Pickers/UserPicker";
import TimeZonePicker from "../Pickers/TimeZonePicker";
import { TimeZone } from "common/models/Common";
import MessageTemplatePicker from "../Pickers/MessageTemplatePicker";
import { MessageTemplate } from "common/models/Templates/MessageTemplate";
import { Editor } from "@tinymce/tinymce-react/lib/cjs/main/ts/components/Editor";
import { useTheme } from "@mui/material/styles";
import { DateTimePicker } from "@mui/x-date-pickers-pro";
import ContactPicker from "../Pickers/ContactPicker";
import { NameIdObj, SimpleFileObj } from "common/models/GenericTypes";
import { Contact } from "common/models/Contacts";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from '@mui/icons-material/Delete';
import Typography from "@mui/material/Typography";
import FilePicker from "../Pickers/FilePicker";
import { GetMyUser } from "services/UsersService";
import { CreateMeeting, GetActiveMeetingTypes, GetMeetingAttendees, GetMeetingById, GetMeetingTypeById, UpdateMeeting } from "services/MeetingsService";
import { Navigate } from "react-router";
import moment from "moment";
import { GetTimeZone } from "services/CommonService";
import { DownloadDocument, GetDocuments } from "services/DocumentsService";
import { MeetingAttendee, MeetingEdit, UiMeetingEdit } from "common/models/Meetings";
import { ChangeTracker } from "common/models/hooks/ChangeTracker";
import useObjectStateWithChangeTracker from "hooks/UseObjectStateWithChangeTracker";
import useUnsavedChangesDialog from "hooks/UseUnsavedChangesDialog";
import { GetContactAttendeeData } from "services/ContactsService";
import CandidatePicker from "components/Pickers/CandidatePicker";
import { CandidateList } from "common/models/Candidates/CandidateList";
import { GetCandidateAttendeeData } from "services/CandidatesService";
import { Link } from "react-router-dom";
import MeetingPreviewDialog from "components/Dialogs/Meetings/MeetingPreviewDialog";
import { DownloadMessageTemplateAttachmentFile, GetMessageTemplateAttachments } from "services/TemplatesService";
import { AttachmentsLimitBytes } from "util/FilesUtils";
import { GetPlaceholdersByEntity } from "services/PlaceholdersService";
import { TemplatePlaceholder } from "common/models/Templates/TemplatePlaceholder";
import InsertPlaceholderDialog from "components/Dialogs/Generic/InsertPlaceholderDialog";
import RWTextFieldComponent from "components/RWTextFieldComponent";
import { MeetingType } from "common/models/Configuration/MeetingType";

interface Props {
    meetingId?: number,
    typeId?: number,
    contactIds?: number[],
    source?: number,
    placementId?: number,
    jobId?: number,
    defaultLocationTypeId?: number,
    defaultDistributionModeId?: number,
    defaultDuration?: number,
    setSummaryBar?: (sb: JSX.Element) => void,
    loadingHandler?: (isLoading: boolean) => void,
    successHandler?: (message: string) => void,
    errorHandler?: (message: string) => void,
}

const defaultEmailContent = ""
+ "Subject: #{Meeting.Subject}<br/>"
+ "Date: #{Meeting.Date}<br/>"
+ "Time: #{Meeting.Time}<br/>"
+ "Timezone: #{Meeting.Timezone}<br/>"
+ "Location: #{Meeting.Location}<br/>"
+ "Attendees:<br/>"
+ "#{Meeting.Attendees} <br/>"
const webConferencPlaceholder = '#{Meeting.WebConferenceUrl}';

interface ContactAttendee extends NameIdObj {
    email: string | null
}

const DefaultMeetingData: UiMeetingEdit = {
    distributionId: 2,
    organizerId: 0,
    typeId: 0,
    dateInLocalTimeZone: '',
    duration: 0,
    timeZoneName: '',
    subject: '',
    location: '',
    locationTypeId: 2,
    templateId: 0,
    message: '',
    isCandidateContacts: false,
    placementId: 0,
    jobId: 0,
    users: [],
    contacts: [],
    filesToRemove: [],
    usersJson: '[]',
    contactsJson: '[]',
    filesToRemoveJson: '[]'
};

const NoChangesMeetingData: ChangeTracker<UiMeetingEdit> = {
    distributionId: false,
    organizerId: false,
    typeId: false,
    dateInLocalTimeZone: false,
    duration: false,
    timeZoneName: false,
    subject: false,
    location: false,
    locationTypeId: false,
    templateId: false,
    message: false,
    isCandidateContacts: false,
    placementId: false,
    jobId: false,
    users: false,
    contacts: false,
    filesToRemove: false,
    usersJson: false,
    contactsJson: false,
    filesToRemoveJson: false
}

const meetingEntityType = 10;

export default function MeetingEditPageContent({ meetingId = 0, typeId: typeIdProp = 0, contactIds, source = 0, placementId = 0, jobId = 0, defaultLocationTypeId = 2, defaultDistributionModeId = 2, defaultDuration = 60, setSummaryBar, loadingHandler, successHandler, errorHandler }: Props) {
    const [meetingTypes, setMeetingTypes] = useState<MeetingType[]>([]);
    const [selectedMeetingType, setSelectedMeetingType] = useState<MeetingType | null>(null);
    const [activeStep, setActiveStep] = useState(0);
    const [date, setDate] = useState<moment.Moment | null>(null);
    const [timeZone, setTimeZone] = useState<TimeZone | null>(null);
    const [templateType, setTemplateType] = useState('Me');
    const [template, setTemplate] = useState<MessageTemplate | null>(null);
    const editorRef = useRef<tinymce.Editor | null>(null);
    const theme = useTheme();
    const isDarkTheme = useMemo(() => theme.palette.mode === 'dark', [theme.palette.mode]);
    const [users, setUsers] = useState<NameIdObj[]>([]);
    const [contacts, setContacts] = useState<ContactAttendee[]>([]);
    const [files, setFiles] = useState<File[]>([]);
    const [templateFiles, setTemplateFiles] = useState<SimpleFileObj[]>([]);
    const [uploadedFiles, setUploadedFiles] = useState<SimpleFileObj[]>([]);
    const [attachmentsToRemove, setAttachmentsToRemove] = useState<number[]>([]);
    const [showValidation1, setShowValidation1] = useState(false);
    const [showValidation2, setShowValidation2] = useState(false);
    const [showValidation3, setShowValidation3] = useState(false);
    const [createdMeetingId, setCreatedMeetingId] = useState(0);
    const [savedChanges, setSavedChanges] = useState(false);
    const [isFetchingSetupData, setIsFetchingSetupData] = useState(false);
    const [isFetchingCreateData, setIsFetchingCreateData] = useState(false);
    const [isFetchingEditData, setIsFetchingEditData] = useState(false);
    const [isEditorDirty, setIsEditorDirty] = useState(false);
    // const [isCandidateContacts, setIsCandidateContacts] = useState(source === 3 || typeIdProp === candidateMeetingType);
    // const [isCandidateContacts, setIsCandidateContacts] = useState(false);
    const [showPreviewDialog, setShowPreviewDialog] = useState(false);
    const [showPlaceholders, setShowPlaceholders] = useState(false);
    const [placeholders, setPlaceholders] = useState<TemplatePlaceholder[]>([]);
    const { state, init, change, updateInitial, hasChanges } = useObjectStateWithChangeTracker<UiMeetingEdit>(DefaultMeetingData, NoChangesMeetingData);

    useEffect(() => {
        const getPlaceholders = async () => {
            const res = await GetPlaceholdersByEntity(meetingEntityType);
            if (res) setPlaceholders(res);
        };
        getPlaceholders();
    }, []);

    useEffect(() => {
        const getData = async () => {
            setIsFetchingSetupData(true);
            const activitySettings = await GetActiveMeetingTypes(source);
            if (activitySettings) setMeetingTypes(activitySettings);
            setIsFetchingSetupData(false);
        };
        getData();
    }, [source]);

    useEffect(() => {
        if (date) change('dateInLocalTimeZone', date.format('YYYY-MM-DDTHH:mm'));
        else change('dateInLocalTimeZone', '');
    }, [date, change]);

    useEffect(() => {
        const setupCreate = async () => {
            setIsFetchingCreateData(true);
            let tmp = {...DefaultMeetingData};
            const m = moment();
            setDate(m);
            tmp.dateInLocalTimeZone = m.format('YYYY-MM-DDTHH:mm');
            tmp.typeId = typeIdProp;
            tmp.locationTypeId = defaultLocationTypeId;
            tmp.distributionId = defaultDistributionModeId;
            tmp.duration = defaultDuration;
            tmp.placementId = placementId;
            tmp.jobId = jobId;
            
            const me = await GetMyUser();
            if (me) {
                setUsers([{ id: me.userID, name: me.displayName }]);
                tmp.usersJson = JSON.stringify([me.userID]);
                tmp.organizerId = me.userID;
                if (me.timeZoneName) {
                    const tz = await GetTimeZone(me.timeZoneName);
                    setTimeZone(tz);
                    tmp.timeZoneName = me.timeZoneName;
                }
            }

            const meetingType = await GetMeetingTypeById(typeIdProp);
            if (contactIds && contactIds.length > 0 && meetingType){
                let res: MeetingAttendee[] | null = null;
                
                if (meetingType.candidateOrClientMeeting === 3) res = await GetCandidateAttendeeData(contactIds);
                else res = await GetContactAttendeeData(contactIds);
                
                if (res) {
                    let c: ContactAttendee[] = res.map(v => ({ id: v.attendeeID, name: v.name, email: v.email }));
                    setContacts(c);
                    tmp.contactsJson = JSON.stringify(c.map(v => v.id));
                }
                setSelectedMeetingType(meetingType);
            }

            init(tmp);
            setIsFetchingCreateData(false);
        };
        meetingId === 0 && setupCreate();
    }, [meetingId, contactIds, typeIdProp, init, source, defaultLocationTypeId, defaultDistributionModeId, placementId, jobId, defaultDuration]);

    const reValidateAttendees = useCallback(async (): Promise<boolean> => {
        loadingHandler && loadingHandler(true);
        let res: MeetingAttendee[] | null = null;
        let allValid = false;
        const attendeeIds = contacts.map(c => c.id);
        if (source === 3) res = await GetCandidateAttendeeData(attendeeIds);
        else res = await GetContactAttendeeData(attendeeIds);
        
        if (res) {
            const recentValidated: ContactAttendee[] = res.map(v => ({ id: v.attendeeID, name: v.name, email: v.email }));
            const noEmailContact = recentValidated.find(c => !Boolean(c.email));
            setContacts(recentValidated);
            allValid = !Boolean(noEmailContact);
        }
        loadingHandler && loadingHandler(false);
        return allValid;
    }, [contacts, loadingHandler, source]);

    useEffect(() => {
        const getEditData = async () => {
            setIsFetchingEditData(true);
            let tmp = {...DefaultMeetingData};
            const res = await GetMeetingById(meetingId, errorHandler);
            if (res) {
                tmp.distributionId = res.distributionMode;
                tmp.organizerId = res.organizerID;
                tmp.typeId = res.typeId;
                const m = moment(res.dateInLocalTimeZone);
                setDate(m.isValid() ? m : null);
                tmp.duration = res.duration;
                tmp.dateInLocalTimeZone = m.isValid() ? m.format('YYYY-MM-DDTHH:mm') : '';
                if (res.timeZoneName) {
                    const tz = await GetTimeZone(res.timeZoneName);
                    if (tz) setTimeZone(tz);
                }
                tmp.locationTypeId = res.locationTypeID;
                tmp.location = res.location;
                tmp.subject = res.subject ?? '';
                tmp.placementId = res.placementId;
                tmp.jobId = res.jobId;

                if (editorRef.current) editorRef.current.setContent(res.message);

                const attendees = await GetMeetingAttendees(meetingId, errorHandler);
                if (attendees) {
                    let u: NameIdObj[] = [];
                    let c: ContactAttendee[] = [];
                    // let isCandidates = false;
                    for (let i = 0; i < attendees.length; i++) {
                        const a = attendees[i];
                        if (a.type === 'User') u.push({ id: a.attendeeID, name: a.name });
                        else if (a.type === 'Contact') c.push({ id: a.attendeeID, name: a.name, email: a.email });
                        else if (a.type === 'Candidate') c.push({ id: a.attendeeID, name: a.name, email: a.email });
                    }
                    
                    const meetingType = await GetMeetingTypeById(tmp.typeId);
                    if (meetingType) setSelectedMeetingType(meetingType);

                    setUsers(u);
                    tmp.usersJson = JSON.stringify(u.map(v => v.id));
                    setContacts(c);
                    tmp.contactsJson = JSON.stringify(c.map(v => v.id));
                }

                const attachments = await GetDocuments(10, meetingId, errorHandler);
                if (attachments && attachments.length > 0) {
                    let f: SimpleFileObj[] = [];
                    for (let i = 0; i < attachments.length; i++) {
                        const file = attachments[i];
                        f.push({ id: file.id, name: file.documentName, size: file.size });
                    }
                    setUploadedFiles(f);
                }
            }
            init(tmp);
            setIsFetchingEditData(false);
        };
        meetingId && getEditData();
    }, [meetingId, init, errorHandler]);

    useEffect(() => {
        loadingHandler && loadingHandler(isFetchingSetupData || isFetchingCreateData || isFetchingEditData);
    }, [isFetchingEditData, loadingHandler, isFetchingCreateData, isFetchingSetupData]);

    useEffect(() => {
        const getData = async () => {
            if (template) {
                loadingHandler && loadingHandler(true);
                const res = await GetMessageTemplateAttachments(template.id, errorHandler);
                if (res) {
                    let files: SimpleFileObj[] = [];
                    for (let i = 0; i < res.length; i++) {
                        const f = res[i];
                        files.push({ id: f.id, name: f.friendlyName, size: f.size })
                    }
                    if (files.length > 0) setTemplateFiles(files);
                    else setTemplateFiles([]);
                }
                else setTemplateFiles([]);
                loadingHandler && loadingHandler(false);
            }
        };
        template && getData();
    }, [template, errorHandler, loadingHandler]);

    const isActiveStepLast = useMemo(() => {
        if ((state.distributionId === 2 || state.distributionId === 1) && activeStep === 2) return true;
        if (state.distributionId !== 2 && state.distributionId !== 1 && activeStep === 1) return true;
        return false;
    }, [activeStep, state.distributionId]);

    const isStep1Error = useMemo(() => {
        if (state.distributionId === 0) return state.typeId === 0 || date === null || state.locationTypeId === 3 || state.locationTypeId === 4;
        return state.typeId === 0 || date === null || state.duration === 0 || state.locationTypeId === 0 || ( (state.locationTypeId === 1 || state.locationTypeId === 5) && !Boolean(state.location) );
    }, [state.distributionId, state.typeId, state.duration, state.locationTypeId, state.location, date]);

    const isStep2Error = useMemo(() => {
        if (contacts.length > 0) {
            if (state.distributionId === 0) return false;
            const noEmailContact = contacts.find(c => !Boolean(c.email));
            if (noEmailContact) return true;
            else return false;
        }
        return true;
    }, [contacts, state.distributionId]);

    const isStep3Error = useMemo(() => {
        if (state.distributionId === 1 || state.distributionId === 2) return !Boolean(state.subject)
        return false;
    }, [state.distributionId, state.subject]);

    const nextStepHandler = useCallback(async () => {
        let canContinue = true;
        if (activeStep === 0) {
            setShowValidation1(true);
            if (isStep1Error) {
                canContinue = false;
                if (state.distributionId === 0) {
                    if (state.locationTypeId === 3) errorHandler && errorHandler("Location Type cannot be Microsoft Teams when distribution is None");
                    else if (state.locationTypeId === 4) errorHandler && errorHandler("Location Type cannot be Zoom when distribution is None");
                }
            }
            else {
                const rightNow = moment().format('YYYY-MM-DDTHH:mm');
                const dString = date ? date.format('YYYY-MM-DDTHH:mm') : '';
                if (state.distributionId !== 0 && dString < rightNow) {
                    errorHandler && errorHandler('Cannot send invites in the past');
                    canContinue = false;
                }
                else errorHandler && errorHandler('');
            }
        }
        else if (activeStep === 1) {
            setShowValidation2(true);
            if (isStep2Error) canContinue = await reValidateAttendees();
        }
        if (canContinue) setActiveStep(prev => prev +1);
    }, [activeStep, date, errorHandler, isStep1Error, isStep2Error, reValidateAttendees, state.distributionId, state.locationTypeId]);

    const prevStepHandler = useCallback(() => {
        setActiveStep(prev => prev -1);
    }, []);

    const saveHandler = useCallback(async () => {
        if (activeStep === 2) setShowValidation3(true);
        if (isStep1Error || isStep2Error || isStep3Error) return false;

        if (files.length > 0) {
            const totalSizeToUpload = files.reduce((total, item) => total + item.size, 0);
            if (totalSizeToUpload > AttachmentsLimitBytes) {
                errorHandler && errorHandler("The total size of Attachments cannot be more than 25MB");
                return false;
            }
        }
        
        let editorContent = '';
        const editorApi = editorRef.current;
        const isWebConference = state.locationTypeId === 3 || state.locationTypeId === 4;
        if (editorApi) editorContent = editorApi.getContent();

        if (state.distributionId !== 0) {
            const hasWebConverenceUrl = editorContent.includes(webConferencPlaceholder);
            if (hasWebConverenceUrl && !isWebConference ) {
                errorHandler && errorHandler(`Invite info can't include placeholder ${webConferencPlaceholder}`);
                return false;
            }
            if (!hasWebConverenceUrl && isWebConference ) {
                errorHandler && errorHandler(`Invite info must include placeholder ${webConferencPlaceholder}`);
                return false;
            }
        }
        
        loadingHandler && loadingHandler(true);
        const uIds = users.map(u => u.id);
        const cIds = contacts.map(c => c.id);

        const tId = template ? template.id : 0;
        const tzName = timeZone ? timeZone.zoneName : '';

        const meetingData: MeetingEdit = {
            distributionId: state.distributionId,
            organizerId: state.organizerId,
            dateInLocalTimeZone: date ? date.format('YYYY-MM-DDTHH:mm') : '',
            timeZoneName: tzName,
            duration: state.duration,
            location: state.location,
            locationTypeId: state.locationTypeId,
            subject: state.subject,
            templateId: tId,
            message: editorContent,
            typeId: state.typeId,
            isCandidateContacts: selectedMeetingType ? selectedMeetingType.candidateOrClientMeeting === 3 : false,
            placementId: state.placementId,
            jobId: state.jobId,
            contacts: cIds,
            users: uIds,
            filesToRemove: attachmentsToRemove
        };

        if (meetingId === 0) {
            const res = await CreateMeeting(meetingData, files, errorHandler);
            if (!Boolean(res)) {
                loadingHandler && loadingHandler(false);
                return false;
            }
            else if (res) setCreatedMeetingId(res.value);
        }
        else {
            const res = await UpdateMeeting(meetingId, meetingData, files, errorHandler);
            if (!Boolean(res)) {
                loadingHandler && loadingHandler(false);
                return false;
            }
            else if (res) setCreatedMeetingId(meetingId);
        }
        updateInitial();
        setIsEditorDirty(false);
        if(editorApi) editorApi.setDirty(false);
        setFiles([]);
        loadingHandler && loadingHandler(false);
        setSavedChanges(true);
        return true;
    }, [activeStep, isStep1Error, isStep2Error, isStep3Error, files, state.locationTypeId, state.distributionId, state.organizerId, state.duration, state.location, state.subject, state.typeId, state.placementId, state.jobId, loadingHandler, users, contacts, template, timeZone, date, selectedMeetingType, attachmentsToRemove, meetingId, updateInitial, errorHandler]);

    const redirectMeetingId = useMemo(() => {
        if (meetingId !== 0) return meetingId;
        if (savedChanges && createdMeetingId) return createdMeetingId;
        return 0;
    }, [meetingId, createdMeetingId, savedChanges]);

    useEffect(() => {
        const action = (
            <>
                <Button variant="contained" disabled={activeStep === 0} onClick={ prevStepHandler } sx={{ mr: '5px' }} startIcon={<KeyboardArrowLeftIcon />}>Prev</Button>
                { !isActiveStepLast && <Button variant="contained" disabled={activeStep === 2} onClick={ nextStepHandler } endIcon={<KeyboardArrowRightIcon />}>Next</Button> }
                { isActiveStepLast && <Button variant="contained" disabled={(isStep1Error || isStep2Error) && meetingId === 0} onClick={ saveHandler } color="success" endIcon={<KeyboardArrowRightIcon />}>Save</Button> }
            </>
        );

        if (meetingId === 0 && setSummaryBar) {
            const sb = <TitleAndActionSummaryBar title="Meetings > Create" browserTabTitle="Create > Meetings" action={action} />;
            setSummaryBar(sb);
        }
        else if (meetingId && setSummaryBar) {
            const sb = <TitleAndActionSummaryBar title="Meetings > Edit" browserTabTitle="Edit > Meetings" action={action} />;
            setSummaryBar(sb);
        }
    }, [meetingId, files, isEditorDirty, activeStep, isActiveStepLast, isStep1Error, isStep2Error, nextStepHandler, prevStepHandler, saveHandler, setSummaryBar]);

    const templateChangeHandlerCallback = useCallback((t: MessageTemplate | null) => {
        if (t) {
            setTemplate(t);
            change('subject', t.subject);
            editorRef.current?.setContent(t.body);
        }
        else setTemplate(null);
    }, [change]);

    const addedUserIds = useMemo(() => users.map(u => u.id), [users]);

    const addUserHandler = useCallback((u: NameIdObj | null) => {
        if (u) setUsers(prev => {
            const tmp = [...prev, u];
            change('usersJson', JSON.stringify(tmp.map(v => v.id)));
            return tmp;
        });
    }, [change]);

    const removeUserHandler = useCallback((index: number) => {
        setUsers(prev => {
            let tmp = [...prev];
            tmp.splice(index, 1);
            change('usersJson', JSON.stringify(tmp.map(v => v.id)));
            return tmp;
        });
    }, [change]);

    const addContactHandler = useCallback((c: Contact | null) => {
        if (c) {
            setContacts(prev => {
                const existing = prev.find(v => v.id === c.id);
                if (existing) {
                    errorHandler && errorHandler('Contact Already Added');
                    return prev;
                }
                const tmp = [...prev, { id: c.id, name: c.fullName, email: c.email }];
                change('contactsJson', JSON.stringify(tmp.map(v => v.id)));
                return tmp;
            });
        }
    }, [errorHandler, change]);

    const addCandidateHandler = useCallback((c: CandidateList | null) => {
        if (c) {
            setContacts(prev => {
                const existing = prev.find(v => v.id === c.id);
                if (existing) {
                    errorHandler && errorHandler('Candidate Already Added');
                    return prev;
                }
                const tmp = [...prev, { id: c.id, name: c.fullName, email: c.emailAddress }];
                change('contactsJson', JSON.stringify(tmp.map(v => v.id)));
                return tmp;
            });
        }
    }, [change, errorHandler]);

    const removeContactHandler = useCallback((index: number) => {
        setContacts(prev => {
            let tmp = [...prev];
            tmp.splice(index, 1);
            change('contactsJson', JSON.stringify(tmp.map(v => v.id)));
            return tmp;
        });
    }, [change]);

    const usersListElement = useMemo(() => {
        return users.map((u, i) => (
            <React.Fragment key={i}>
                <ListItem disablePadding secondaryAction={ <IconButton edge="end" onClick={ () => removeUserHandler(i) }><DeleteIcon /></IconButton> }>
                    <ListItemButton dense>
                        <ListItemText primary={u.name} />
                    </ListItemButton>
                </ListItem>
            </React.Fragment>
        ));
    }, [removeUserHandler, users]);

    const contactsListElement = useMemo(() => {
        return contacts.map((c, i) => {
            const hasEmail = Boolean(c.email);
            const isCandidateContacts = selectedMeetingType ? selectedMeetingType.candidateOrClientMeeting === 3 : false;
            return (
                <React.Fragment key={i}>
                    <ListItem disablePadding secondaryAction={ <IconButton edge="end" onClick={ () => removeContactHandler(i) }><DeleteIcon /></IconButton> }>
                        <ListItemButton dense>
                            <Link to={isCandidateContacts ? `/candidates/${c.id}` : `/contacts/${c.id}`} target="_blank" style={{ color: 'inherit', textDecoration: 'none', width: '100%' }}>
                                <ListItemText sx={{ color: t => hasEmail ? undefined : t.palette.error.main }} primary={c.name} secondary={hasEmail ? '' : 'Has No Email'} />
                            </Link>
                        </ListItemButton>
                    </ListItem>
                </React.Fragment>
            )
        });
    }, [contacts, removeContactHandler, selectedMeetingType]);

    const addFileCallback = useCallback((f: File[]) => {
        setFiles(prev => ([...prev, ...f]));
    }, []);

    const removeFileCallback = useCallback((index: number) => {
        setFiles(prev => {
            let files = [...prev];
            files.splice(index, 1);
            return files;
        });
    }, []);

    const removeUploadedFileCallback = useCallback((index: number) => {
        setUploadedFiles(prev => {
            let att = [...prev];
            const file = att[index];
            if (file.id !== 0) {
                setAttachmentsToRemove(prev2 => {
                    const tmp = [...prev2, file.id];
                    change('filesToRemoveJson', JSON.stringify(tmp));
                    return tmp;
                });
            }
            att.splice(index, 1);
            return att;
        });
    }, [change]);

    const downloadFileCallback = useCallback(async (file: SimpleFileObj) => {
        loadingHandler && loadingHandler(true);
        await DownloadDocument(file.id, undefined, errorHandler);
        loadingHandler && loadingHandler(false);
    }, [loadingHandler, errorHandler]);

    const downloadTemplateFileCallback = useCallback(async (file: SimpleFileObj) => {
        if (template) {
            loadingHandler && loadingHandler(true);
            await DownloadMessageTemplateAttachmentFile(template.id, file.id, errorHandler);
            loadingHandler && loadingHandler(false);
        }
    }, [template, loadingHandler, errorHandler]);

    const stringChangesCallback = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const {value, name} = e.target;
        change(name as keyof MeetingEdit, value);
    }, [change]);

    const numberChangesCallback = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const {value, name} = e.target;
        change(name as keyof MeetingEdit, +value);
    }, [change]);

    const meetingTypeChangeHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        const parsed = +value;
        change('typeId', parsed);
        if (parsed === 0) setSelectedMeetingType(null);
        else if (parsed > 0) {
            const type = meetingTypes.find(t => t.id === parsed);
            if (type) setSelectedMeetingType(type);
        }
    }, [change, meetingTypes]);

    const { unsavedChangesDialog, hasBlockedRoute } = useUnsavedChangesDialog(hasChanges || files.length > 0 || isEditorDirty, saveHandler);

    const previewBody = useMemo(() => {
        const api = editorRef.current;
        if (showPreviewDialog && api) return api.getContent();
        return ''; 
    }, [showPreviewDialog]);

    const insertPlaceholderHandler = useCallback((placeholder: string) => {
        const api = editorRef.current;
        if (api) api.insertContent(placeholder);
    }, []);

    return (
        <>
            {unsavedChangesDialog}
            { !hasBlockedRoute && redirectMeetingId !== 0 && savedChanges && <Navigate to={`/meetings/${redirectMeetingId}`} /> }
            <MeetingPreviewDialog
                open={showPreviewDialog}
                isCandidateContacts={selectedMeetingType ? selectedMeetingType.candidateOrClientMeeting === 3 : false}
                meetingId={meetingId}
                body={previewBody}
                isDarkTheme={isDarkTheme}
                meetingData={state}
                closeHandler={() => setShowPreviewDialog(false)}
                loadingHandler={loadingHandler}
            />
            <InsertPlaceholderDialog
                open={showPlaceholders}
                closeHandler={() => setShowPlaceholders(false)}
                placeholders={placeholders}
                insertHandler={insertPlaceholderHandler}
            />
            <Stepper nonLinear activeStep={activeStep}>
                <Step><StepLabel error={showValidation1 && isStep1Error}>Meeting Info</StepLabel></Step>
                <Step><StepLabel error={showValidation2 && isStep2Error}>Attendees</StepLabel></Step>
                { (state.distributionId === 1 || state.distributionId === 2) && <Step><StepLabel error={showValidation3 && isStep3Error}>Invite Info</StepLabel></Step> }
            </Stepper>
            <Box mt="20px" display={activeStep === 0 ? undefined : 'none'}>
                <Stack spacing={2}>
                    <TextField select label="Distribution" value={state.distributionId.toString()} name="distributionId" onChange={numberChangesCallback}>
                        <MenuItem value="0">None - Record in Recruit Wizard Only</MenuItem>
                        <MenuItem value="1">Calendar Invite</MenuItem>
                        <MenuItem value="2">Calendar Invite + Email</MenuItem>
                    </TextField>
                    <UserPicker label="Organizer" onSelect={u => change('organizerId', u ? u.id : 0)} userId={state.organizerId} />
                    <TextField select label="Type" value={state.typeId.toString()} name="typeId" onChange={meetingTypeChangeHandler} error={showValidation1 && state.typeId === 0}>
                        <MenuItem value="0">None</MenuItem>
                        {meetingTypes.map(t => <MenuItem key={t.id} value={t.id.toString()}>{t.name}</MenuItem>)}
                    </TextField>
                    <TimeZonePicker value={timeZone} onSelectCallback={setTimeZone} />
                    <DateTimePicker label="Date" value={date} onChange={setDate} disablePast={state.distributionId !== 0} slotProps={{ textField: { error: showValidation1 && !date }, actionBar: { actions: ["clear", "today", "cancel", "accept"] } }} />
                    <TextField select label="Duration" value={state.duration.toString()} name="duration" onChange={numberChangesCallback} error={state.distributionId !== 0 && showValidation1 && state.duration === 0}>
                        <MenuItem value="0">None</MenuItem>
                        <MenuItem value="15">15 mins</MenuItem>
                        <MenuItem value="30">30 mins</MenuItem>
                        <MenuItem value="60">1 hour</MenuItem>
                        <MenuItem value="90">90 mins</MenuItem>
                        <MenuItem value="120">2 hours</MenuItem>
                        <MenuItem value="240">4 hours</MenuItem>
                        <MenuItem value="480">1 Day</MenuItem>
                    </TextField>
                    <TextField
                        select
                        label="Location Type"
                        value={state.locationTypeId.toString()}
                        name="locationTypeId"
                        onChange={numberChangesCallback}
                        error={showValidation1 && state.distributionId === 0 && (state.locationTypeId === 3 || state.locationTypeId === 4) }
                    >
                        <MenuItem value="1">In Person</MenuItem>
                        <MenuItem value="2">Phone Call</MenuItem>
                        <MenuItem value="3">Microsoft Teams</MenuItem>
                        {/*<MenuItem value="4">Zoom</MenuItem>*/}
                        <MenuItem value="5">Other</MenuItem>
                    </TextField>
                    {(state.locationTypeId === 1 || state.locationTypeId === 5) &&
                        <RWTextFieldComponent 
                            label="Location"
                            value={state.location}
                            name="location"
                            onChange={stringChangesCallback}
                            isError={state.distributionId !== 0 && showValidation1 && !Boolean(state.location)}/>
                    }
                </Stack>
            </Box>
            <Box mt="20px" display={activeStep === 1 ? undefined : 'none'}>
                <Box display="flex">
                    <Box flex="1 1 0" mr="10px">
                        <Typography variant="h6" mb="10px" textAlign="center">Users</Typography>
                        <UserPicker label="Add User" userId={null} onSelect={addUserHandler} ignoreIds={addedUserIds} blurOnSelect />
                        <List>{usersListElement}</List>
                    </Box>
                    {selectedMeetingType && selectedMeetingType.candidateOrClientMeeting === 1 &&
                        <Box flex="1 1 0" ml="10px">
                            <Typography variant="h6" mb="10px" textAlign="center">Contacts</Typography>
                            <ContactPicker label="Add Contact" disableQuickCreate value={null} onSelectCallback={addContactHandler} blurOnSelect error={showValidation2 && isStep2Error} />
                            <List>{contactsListElement}</List>
                        </Box>
                    }
                    {selectedMeetingType && selectedMeetingType.candidateOrClientMeeting === 3 &&
                        <Box flex="1 1 0" ml="10px">
                            <Typography variant="h6" mb="10px" textAlign="center">Candidates</Typography>
                            <CandidatePicker label="Add Candidate" value={null} onSelectCallback={addCandidateHandler} blurOnSelect error={showValidation2 && isStep2Error} />
                            <List>{contactsListElement}</List>
                        </Box>
                    }
                </Box>
            </Box>
            <Box mt="20px" display={activeStep === 2 ? undefined : 'none'}>
                <Stack spacing={2}>
                    <Box display="flex" gap={1}>
                        <TextField
                            select
                            fullWidth
                            label="Template Source"
                            value={templateType}
                            onChange={ ({target}) => setTemplateType(target.value) }
                            sx={{ flex: '1 1 0' }}
                        >
                            <MenuItem value="Me">Me</MenuItem>
                            <MenuItem value="Team">My Team</MenuItem>
                            <MenuItem value="All">My Company</MenuItem>
                        </TextField>
                        <Box flex="1 1 0">
                            <MessageTemplatePicker
                                templateId={template ? template.id : null}
                                includeCompanyOwned
                                typeFilter={5}
                                templateSource={templateType}
                                entityTypeFilter={selectedMeetingType && selectedMeetingType.candidateOrClientMeeting === 3 ? 3 : 2}
                                onSelectHandler={ templateChangeHandlerCallback }
                            />
                        </Box>
                        <Button variant="contained" onClick={() => setShowPreviewDialog(true)}>Preview</Button>
                    </Box>
                    <RWTextFieldComponent 
                        label="Subject"
                        value={state.subject}
                        name="subject"
                        onChange={stringChangesCallback}
                        isError={showValidation3 && !Boolean(state.subject)} />
                    <Editor
                        tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce-5.10.2/tinymce.min.js'}
                        onInit={ (evt, editor) => editorRef.current = editor }
                        initialValue={defaultEmailContent}
                        onDirty={ () => setIsEditorDirty(true) }
                        init={{
                            height: 475,
                            skin: isDarkTheme ? 'oxide-dark' : undefined,
                            content_css: isDarkTheme ? 'dark' : undefined,
                            branding: false,
                            menubar: false,
                            statusbar: false,
                            contextmenu: false,
                            resize: false,
                            browser_spellcheck: true,
                            plugins: 'powerpaste code link emoticons table print preview visualchars lists fullscreen',
                            powerpaste_word_import: 'prompt',
                            powerpaste_html_import: 'prompt',
                            powerpaste_allow_local_images: true,
                            fontsize_formats: "8pt 9pt 10pt 12pt 14pt 18pt 24pt 36pt",
                            toolbar1: 'placeholders fontsizeselect fontselect styleselect forecolor backcolor bold italic alignleft aligncenter alignright alignjustify bullist numlist code fullscreen link mybutton table',
                            font_formats: 'Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Calibri=calibri; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Segoe=segoe,segoe ui,dejavu sans,trebuchet ms,verdana,sans-serif; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva',
                            setup: editor => {
                                editor.ui.registry.addButton('placeholders', {
                                    text: 'Insert Placeholder',
                                    onAction: () => setShowPlaceholders(true)
                                });
                            }
                        }}
                    />
                    <FilePicker
                        files={files}
                        uploadedFiles={uploadedFiles}
                        templateFiles={templateFiles}
                        addFilesHandler={ addFileCallback }
                        removeFileHandler={ removeFileCallback }
                        downloadFileHandler={downloadFileCallback}
                        downloadTemplateFileHandler={downloadTemplateFileCallback}
                        removeUploadedFileHandler={removeUploadedFileCallback}
                        limitSizeBytes={AttachmentsLimitBytes}
                    />
                </Stack>
            </Box>
        </>
    );
}
