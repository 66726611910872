import IconButton from "@mui/material/IconButton";
import FastRewindIcon from '@mui/icons-material/FastRewind';
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Popper from "@mui/material/Popper";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Paper from "@mui/material/Paper";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import { RecentRecordsSettingName } from "util/LocalStorageUtils";
import { RecentRecordItem } from "common/models/CustomApplicationContextType";

const getEntityPath = (entityId: number, recordId: number) => {
    switch (entityId) {
        case 1: return `/clients/${recordId}`;
        case 2: return `/contacts/${recordId}`;
        case 3: return `/candidates/${recordId}`;
        case 4: return `/jobs/${recordId}`;
        case 5: return `/placements/${recordId}`;
        case 8: return `/opportunities/${recordId}`;
        case 9: return `/sites/${recordId}`;
        case 15: return `/adverts/${recordId}`;
        default: return '';
    }
}

const getRecentRecordTitle = (entityId: number, title: string) => {
    switch (entityId) {
        case 1: return `${title} - Client`;
        case 2: return `${title} - Contact`;
        case 3: return `${title} - Candidate`;
        case 4: return `${title} - Job`;
        case 5: return `${title} - Placement`;
        case 8: return `${title} - Opportunity`;
        case 9: return `${title} - Site`;
        case 15: return `${title} - Advert`;
        default: return '';
    }
}

export function WizTopBarRecentRecordsMenu() {
    const [anchor, setAnchor] = useState<HTMLElement | null>(null);
    const [isRootHovered, setIsRootHovered] = useState(false);
    const [isRootPaperHovered, setIsRootPaperHovered] = useState(false);
    
    const handleOpenMenu = useCallback((e: React.MouseEvent<HTMLElement>) => {
        setIsRootHovered(true);
        setAnchor(e.currentTarget);
    }, []);

    const handleCloseMenu = useCallback(() => setAnchor(null), []);

    useEffect(() => {
        if (Boolean(anchor) && !isRootHovered && !isRootPaperHovered) setAnchor(null);
    }, [anchor, isRootHovered, isRootPaperHovered]);

    const recentRecords = useMemo(() => {
        if (Boolean(anchor)) {
            const stored = localStorage.getItem(RecentRecordsSettingName);
            if (stored) return JSON.parse(stored) as RecentRecordItem[];
        }
        return [];
    }, [anchor]);

    return (
        <>
            <Box component="span" p="5px">
                <IconButton
                    size="large"
                    color="inherit"
                    onClick={handleOpenMenu}
                    onMouseEnter={handleOpenMenu}
                    onMouseLeave={ () => setIsRootHovered(false) }
                    sx={{ p: 0 }}
                >
                    <FastRewindIcon sx={{ fontSize: '1.7rem' }} />
                </IconButton>
            </Box>
            <Popper
                disablePortal
                anchorEl={anchor}
                open={Boolean(anchor)}
                placement="bottom-end"
                sx={{ zIndex: t => t.zIndex.appBar + 2 }}
                onMouseEnter={ () => setIsRootPaperHovered(true) }
                onMouseLeave={ () => setIsRootPaperHovered(false) }
            >
                <ClickAwayListener onClickAway={ handleCloseMenu }>
                    <Paper elevation={8} onMouseLeave={ handleCloseMenu }>
                        <MenuList>
                            {recentRecords.length === 0 && <MenuItem>None</MenuItem>}
                            {recentRecords.length > 0 && recentRecords.map(r => (
                                <Link key={`${r.entityId}-${r.recordId}`} to={getEntityPath(r.entityId, r.recordId)} style={{ color: 'inherit', textDecoration: 'none' }}>
                                    <MenuItem>{getRecentRecordTitle(r.entityId, r.title)}</MenuItem>
                                </Link>
                            ))}
                        </MenuList>
                    </Paper>
                </ClickAwayListener>
            </Popper>
        </>
    );
}
