import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { SxProps } from "@mui/material/styles";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment, { Moment } from "moment";
import * as XLSX from "xlsx";
import React, { useCallback, useEffect, useState } from "react";
import { Get_TRC_SalesMonthly } from "services/PlacementsService";
import Typography from "@mui/material/Typography";
import { ReportDataProcessDateFields } from "util/ReportUtils";

interface Props {
    description: string,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void
}

const filterElementStyle: SxProps = { flex: '1 1 0', mr: 1 }

export default function TRCSalesMonthly({ description, loadingHandler, errorHandler }: Props) {
    const [monthYear, setMonthYear] = useState<Moment|null>(null);
    const [type, setType] = useState(0);

    useEffect(() => {
        setMonthYear(moment());
    }, []);

    const getDataCallback = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        let m = 0;
        let y = 0;
        if (monthYear) {
            m = monthYear.month() + 1;
            y = monthYear.year();
        }
        const res = await Get_TRC_SalesMonthly(type, y, m, errorHandler);
        if (res) {
            ReportDataProcessDateFields(res as any[]);
            
            const mo = moment();
            const typeString = type === 1 ? '_Detailed' : '';
            const filename = `MonthlySales${typeString}${mo.format('YYYYMMDDhhmmss')}.xlsx`;
            const ws = XLSX.utils.json_to_sheet(res);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws);
            XLSX.writeFile(wb, filename);
        }
        loadingHandler && loadingHandler(false);
    }, [type, monthYear, loadingHandler, errorHandler]);

    return (
        <>
            <Box display="flex">
                <DatePicker value={monthYear} closeOnSelect format="MMM YYYY" onChange={setMonthYear} label="Month & Year" views={['month', 'year']} sx={filterElementStyle} />
                <TextField select label="Type" value={type.toString()} onChange={({ target }) => setType(+target.value)} sx={filterElementStyle}>
                    <MenuItem value="0">Summary</MenuItem>
                    <MenuItem value="1">Detailed</MenuItem>
                </TextField>
                <Button variant="contained" color="success" onClick={getDataCallback} disabled={!Boolean(monthYear)}>Run Report</Button>
            </Box>
            <Typography component="div" variant="h6" mt={2}>Report Description</Typography>
            <Typography component="div" variant="body2">{description}</Typography>
        </>
    );
}