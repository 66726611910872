import Box from "@mui/material/Box";
import { ExtendedUser } from "common/models/Configuration/User";
import React, { useEffect, useMemo, useState } from "react";
import { GetMyUser } from "services/UsersService";
import JobBoardViewField from "./JobBoardViewField";

interface Props {
    jobBoardId: number,
    advertId: number,
    attributes?: Record<string, string>,
    advertRecordAttributes?: Record<string, string>,
}

export default function IndeedView({ jobBoardId, advertId, attributes, advertRecordAttributes }: Props) {
    const [currentUser, setCurrentUser] = useState<ExtendedUser>();
    
    useEffect(() => {
        const getUserData = async () => {
            const res = await GetMyUser();
            if (res) setCurrentUser(res);
        };
        getUserData();
    }, []);

    const atts = useMemo<Record<string, string>>(() => {
        if (attributes) return attributes;
        return {};
    }, [attributes]);

    const recordAtts = useMemo<Record<string, string>>(() => {
        if (advertRecordAttributes) return advertRecordAttributes;
        return {};
    }, [advertRecordAttributes]);
    
    const country = useMemo(() => recordAtts.country ?? '', [recordAtts.country]);
    const region = useMemo(() => recordAtts.region ?? '', [recordAtts.region]);
    const location = useMemo(() => recordAtts.location ?? '', [recordAtts.location]);
    const workType = useMemo(() => recordAtts.workType ?? '', [recordAtts.workType]);
    const displaySalary = useMemo(() => recordAtts.displaySalary ?? '', [recordAtts.displaySalary]);

    const contactIndeedUrl = useMemo(() => {
        if (currentUser) {
            const baseUrl = "https://wwww.indeed.com/hire/contact?";
            let params = new URLSearchParams();
            params.append('mini', 'true');
            params.append('tag', 'wizardsoft');
            params.append('email', currentUser.userName);
            params.append('firstName', currentUser.firstName);
            params.append('lastName', currentUser.lastName);
            params.append('company', currentUser.customerName);
            params.append('phone', currentUser.mobile);
            return baseUrl + params;
        }
        return '';
    }, [currentUser]);

    return (
        <Box display="flex" flexDirection="column" gap={2}>
            <Box display="flex" gap={2} pt={2}>
                <Box display="flex" flexDirection="column" gap={2} width="50%">
                    <JobBoardViewField label="Country" value={country} />
                    <JobBoardViewField label="State" value={region} />
                    <JobBoardViewField label="City" value={location} />
                </Box>
                <Box display="flex" flexDirection="column" gap={2} width="50%">
                    <JobBoardViewField label="Job Type" value={workType} />
                    <JobBoardViewField label="Display Salary" value={displaySalary} />
                </Box>
            </Box>
        </Box>
    );
}