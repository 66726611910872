import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import moment from "moment";
import React, { useCallback, useMemo, useState } from "react";
import { GetCandidatesSeriouslyLookingReportData } from "services/CandidatesService";
import { Link } from "react-router-dom";
import { PreviewEntityType } from "common/models/Previews/Previews";
import PreviewLoaderComponent from "components/Previews/PreviewLoader";
import { GridColDef, GridRenderCellParams, useGridApiRef } from "@mui/x-data-grid-premium";
import { CandidatesSeriouslyLookingReportData } from "common/models/Reports/CandidatesSeriouslyLooking";
import GridWithStateWrapper from "components/GridWidthStateWrapper";

interface Props {
    description: string,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
}

const linkStyle: React.CSSProperties = { color: 'inherit', textDecoration: 'underline' };

export default function CandidatesSeriouslyLookingReport({ description, loadingHandler, errorHandler }: Props) {
    const [rows, setRows] = useState<CandidatesSeriouslyLookingReportData[]>([]);
    const [showGrid, setShowGrid] = useState(false);
    const apiRef = useGridApiRef();
    
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    const [showTagsPrevewNoDelay, setShowTagsPreviewNoDelay] = useState(false);
    const [previewType, setPreviewType] = useState<PreviewEntityType | ''>('');
    const [previewRecordId, setPreviewRecordId] = useState(0);
    const [isPreviewTags, setIsPreviewTags] = useState(false);
    const [previewRecordName, setPreviewRecordName] = useState('');

    const getDataCallback = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        const res = await GetCandidatesSeriouslyLookingReportData(errorHandler);
        if (res) {
            setShowGrid(true);
            setRows(res);
        }
        loadingHandler && loadingHandler(false);
    }, [errorHandler, loadingHandler]);

    const exportAsExcelHandler = useCallback(() => {
        const api = apiRef.current;
        if (api) {
            const m = moment();
            const filename = `CandidatesSeriouslyLooking${m.format('YYYYMMDDhhmmss')}`;
            api.exportDataAsExcel({ fileName: filename });
        }
    }, [apiRef]);

    const columns = useMemo<GridColDef[]>(() => {
        const handlePreviewHover = (type: PreviewEntityType | '', id: number, isTags: boolean = false, recordName: string = '', noDelay: boolean = false) => {
            setPreviewType(type);
            setPreviewRecordId(id);
            setIsPreviewTags(isTags);
            setPreviewRecordName(recordName);
            setIsPreviewOpen(true);
            setShowTagsPreviewNoDelay(noDelay);
        };

        const handlePreviewClose = () => {
            setIsPreviewOpen(false);
            setShowTagsPreviewNoDelay(false);
        };

        const linkToCandidateRenderer = (params: GridRenderCellParams) => {
            if (params.value) {
                return (
                    <Link
                        to={`/candidates/${params.id}`}
                        style={ linkStyle }
                        onMouseEnter={ () => handlePreviewHover('candidate', +params.id) }
                        onMouseLeave={ handlePreviewClose }
                    >{params.value}</Link>
                );
            }
        };

        return [
            { field: 'FullName', headerName: 'Candidate Name', renderCell: linkToCandidateRenderer, width: 300 },
            { field: 'Location', width: 300 },
            { field: 'CurrentJobTitle', headerName: 'Current Job Title', width: 300 },
            { field: 'CurrentEmployer', headerName: 'Current Employer', width: 300 },
            { field: 'PrefJobType', headerName: 'Pref Job Type', width: 300 },
        ];
    }, []);

    return (
        <>
            <PreviewLoaderComponent
                open={isPreviewOpen}
                entityType={previewType}
                recordId={previewRecordId}
                isTagsPreview={isPreviewTags}
                showDelayMs={showTagsPrevewNoDelay ? 0 : undefined}
                titleOverride={previewRecordName}
            />
            <Box display="flex" justifyContent="flex-end">
                <Button variant="contained" color="success" onClick={getDataCallback}>Run Report</Button>
            </Box>
            <Typography component="div" variant="h6" mt={2}>Report Description</Typography>
            <Typography component="div" variant="body2" mb={2}>{description}</Typography>
            {showGrid &&
                <>
                    <Box pb="10px" ml="auto">
                        <Button variant="contained" color="success" onClick={exportAsExcelHandler}>Export As Excel</Button>
                    </Box>
                    <GridWithStateWrapper
                        density="compact"
                        rows={rows}
                        columns={columns}
                        apiRef={apiRef}
                        getRowId={r => r.CandidateID}
                        disableRowSelectionOnClick
                        gridName="reports-seriouslylookingcandidates"
                    />
                </>
            }
        </>
    );
}
