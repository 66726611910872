import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-premium";
import { BasicFilter } from "common/models/Analytics/AnalyticsFilterData";
import { PerformanceActivityRatioTrendsRecord } from "common/models/Analytics/BusinessIntelligence";
import GridWithStateWrapper from "components/GridWidthStateWrapper";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import {  GetPerformanceActivityRatioTrendData } from "services/BusinessIntelligence";

interface Props {
    filterData: BasicFilter,
    ratioType?: string,
    graphType?: 'table' | 'graph',
    loadingHandler?: (isLoading: boolean) => void
}

interface PerformanceActivityRatioTrendsRecordWithId extends PerformanceActivityRatioTrendsRecord {
    id: number,
}

const graphTitleMap = {
    submissionsToFirstInterviewRatio: 'Submission To 1st Interview',
    firstInterviewToSecondInterviewRatio: 'Submission To 2nd Interview',
    firstInterviewToOfferRatio: '1st Interview To Offer',
    secondInterviewToOfferRatio: '2nd Interview To Offer',
    submissionsToPlacementsRatio: 'Submission To Placements',
    firstInterviewToPlacementRatio: '1st Interview To Placement',
    secondInterviewToPlacementRatio: '2nd Interview To Placement',
    offersToPlacementsRatio: 'Offer To Placement',
    jobsToPlacementsRatio: 'Jobs To Placement',
};

const gridName = 'analytics/performance/activity-ratio-trends';

export default function PerformanceActivityRatioTrendsContent({ filterData, ratioType = 'submissionsToPlacementsRatio', graphType = 'graph', loadingHandler }: Props) {
    const [rows, setRows] = useState<PerformanceActivityRatioTrendsRecordWithId[]>([]);
    const t = useTheme();

    useEffect(() => {
        const getData = async () => {
            loadingHandler && loadingHandler(true);
            const res = await GetPerformanceActivityRatioTrendData(filterData);
            if (res) {
                const data: PerformanceActivityRatioTrendsRecordWithId[] = res.map((r, i) => ({ ...r, id: i }));
                setRows(data);
            }
            loadingHandler && loadingHandler(false);
        };
        getData();
    }, [filterData, loadingHandler]);

    const columns = useMemo<GridColDef[]>(() => {
        const percentRenderer = (params: GridRenderCellParams) => {
            return `${params.value} %`
        };
        
        return [
            { field: 'month', headerName: 'Month', width: 150 },
            { field: 'jobs', headerName: 'Jobs', headerAlign: 'center', align: 'right', width: 150 },
            { field: 'submissions', headerName: 'Submissions', headerAlign: 'center', align: 'right', width: 150 },
            { field: 'firstInterviews', headerName: '1st Interviews', headerAlign: 'center', align: 'right', width: 150 },
            { field: 'secondInterviews', headerName: '2nd Interviews', headerAlign: 'center', align: 'right', width: 150 },
            { field: 'offers', headerName: 'Offers', headerAlign: 'center', align: 'right', width: 150 },
            { field: 'placements', headerName: 'Placements', headerAlign: 'center', align: 'right', width: 150 },
            { field: 'submissionsToFirstInterviewRatio', headerName: 'Submission to 1st Interview', headerAlign: 'center', align: 'right', renderCell: percentRenderer, width: 250 },
            { field: 'firstInterviewToSecondInterviewRatio', headerName: 'Submission to 2nd Interview', headerAlign: 'center', align: 'right', renderCell: percentRenderer, width: 250 },
            { field: 'firstInterviewToOfferRatio', headerName: '1st Interview to Offer', headerAlign: 'center', align: 'right', renderCell: percentRenderer, width: 250 },
            { field: 'secondInterviewToOfferRatio', headerName: '2nd Interview to Offer', headerAlign: 'center', align: 'right', renderCell: percentRenderer, width: 250 },
            { field: 'submissionsToPlacementsRatio', headerName: 'Submission to Placements', headerAlign: 'center', align: 'right', renderCell: percentRenderer, width: 250 },
            { field: 'firstInterviewToPlacementRatio', headerName: '1st Interview to Placement', headerAlign: 'center', align: 'right', renderCell: percentRenderer, width: 250 },
            { field: 'secondInterviewToPlacementRatio', headerName: '2nd Interview to Placement', headerAlign: 'center', align: 'right', renderCell: percentRenderer, width: 250 },
            { field: 'offersToPlacementsRatio', headerName: 'Offer to Placement', headerAlign: 'center', align: 'right', renderCell: percentRenderer, width: 250 },
            { field: 'jobsToPlacementsRatio', headerName: 'Jobs to Placement', headerAlign: 'center', align: 'right', renderCell: percentRenderer, width: 250 },
        ];
    }, []);

    const xAxisLabelsFormatter = useCallback((value: any, index: number) => {
        if (typeof value === 'string') {
            const s = value.split(' ');
            if (s.length === 2) {
                return s[0].substring(0, 3) + ' ' + s[1];
            }
        }
        return '';
    }, []);

    if (graphType === 'table') {
        return (
            <GridWithStateWrapper
                gridName={gridName}
                rows={rows}
                density="compact"
                columns={columns}
                sx={{ mt: '20px' }}
                initialState={{
                    pinnedColumns: {
                        left: ['month'],
                    },
                }}
            />
        );
    }
    else if (graphType === 'graph') {
        return (
            <>
                <Typography variant="h6" padding={1} textAlign="center" fontWeight={600} component="div">{(graphTitleMap as any)[ratioType]} Activity Ratio Trend Analysis</Typography>
                <div style={{ height: 'calc(100% - 48px)' }}>
                    <ResponsiveContainer width="100%" height="100%">
                        <LineChart width={150} height={150} data={rows}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="month" stroke={t.palette.text.primary} tickFormatter={ xAxisLabelsFormatter } />
                            <YAxis type="number" width={100} tickFormatter={v => v.toLocaleString()} stroke={t.palette.text.primary} />
                            <Tooltip />
                            <Line dataKey={ratioType} name="Ratio" stroke={t.palette.info.main} strokeWidth={3} offset="positive" />
                        </LineChart>
                    </ResponsiveContainer>
                </div>
            </>
        );
    }

    return <></>;
}