import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import TextField from "@mui/material/TextField";
import React, { useMemo, useState } from "react";
import { CustomField } from "common/models/Configuration/CustomFields";
import { CandidateRecordDashboardElementType } from "common/models/Dashboard/EditLayout";
import { CustomFieldType, EditableCandidateCustomFieldType } from "common/models/ScreenLayouts/CustomFields";

interface Props {
    open: boolean,
    activeFields: CustomField[],
    alreadyAddedElements: string[],
    ignoreElements?: string[],
    isViewRecord?: boolean,
    closeHandler: () => void,
    addElementHandler: (type: CandidateRecordDashboardElementType) => void
}

interface AddOption {
    value: CandidateRecordDashboardElementType,
    label: string,
    secondaryLabel?: string
}

const availableElements: AddOption[] = [
    { value: 'CandidateInfo', label: 'Photo' },
    { value: 'CandidateId', label: 'ID' },
    { value: 'CandidateName', label: 'Name' },
    { value: 'CandidateGender', label: 'Gender' },
    { value: 'CandidateAvailable', label: 'Available' },
    { value: 'CandidateNotice', label: 'Notice' },
    { value: 'CandidateStatus', label: 'Status' },
    { value: 'CandidateDateOfBirth', label: 'Date Of Birth' },
    { value: 'CandidateNationality', label: 'Nationality' },
    { value: 'CandidateDivisions', label: 'Divisions' },
    { value: 'CandidateEmail1', label: 'Email 1' },
    { value: 'CandidateEmail2', label: 'Email 2' },
    { value: 'CandidatePayrollEmail', label: 'Payroll Email' },
    { value: 'CandidateWebsite', label: 'Website' },
    { value: 'CandidateLocation', label: 'Location' },
    { value: 'CandidateMobile', label: 'Mobile' },
    { value: 'CandidatePhone', label: 'Phone' },
    { value: 'CandidateWork', label: 'Work' },
    { value: 'CandidateOptOut', label: 'OptOut' },
    { value: 'CandidateSummary', label: 'Summary' },
    { value: 'CandidatePreferredJobType', label: 'Preferred Job Type' },
    { value: 'CandidatePreferredRate', label: 'Preferred Rate' },
    { value: 'CandidatePreferredSalary', label: 'Preferred Salary' },
    { value: 'CandidateLinkedIn', label: 'LinkedIn' },
    { value: 'CandidateTwitter', label: 'Twitter' },
    { value: 'Divider', label: 'Divider' },
    { value: 'Spacer', label: 'Spacer' },
];

const getCustomFieldName = (name: string) => {
    if (!name.startsWith('Custom Field ')) return '';
    return 'CustomField' + name.substring(13);
};

export default function AddCandidateDashboardElementDialog({ open, activeFields, alreadyAddedElements, ignoreElements, isViewRecord, closeHandler, addElementHandler }: Props) {
    const [elementType, setElementType] = useState<CandidateRecordDashboardElementType>('');

    const options = useMemo<AddOption[]>(() => {
        let o: AddOption[] = [];

        for (let i = 0; i < availableElements.length; i++) {
            const element = availableElements[i];
            const isAlreadyAdded = alreadyAddedElements.includes(element.value) && element.value !== 'Divider' && element.value !== 'Spacer';
            const isIgnore = ignoreElements && ignoreElements.includes(element.value);
            if (isAlreadyAdded || isIgnore) continue;
            o.push(element);
        }

        for (let i = 0; i < activeFields.length; i++) {
            const udf = activeFields[i];
            const name = getCustomFieldName(udf.name);
            const isAlreadyAdded = alreadyAddedElements.includes(name);
            const isIgnore = ignoreElements && ignoreElements.includes(name);
            if (isAlreadyAdded || isIgnore) continue;
            if (name) o.push({ value: name as CustomFieldType, label: udf.agencyName, secondaryLabel: udf.name });
            if (isViewRecord && name && udf.isActive && udf.dataType === 'String' && udf.usePredefinedValues && udf.editable) o.push({ value: `${name}_Editable` as EditableCandidateCustomFieldType, label: udf.agencyName, secondaryLabel: udf.name + ' (Editable)' });
        }

        o.sort((a, b) => {
            if (b.label < a.label) return 1;
            if (b.label > a.label) return -1;
            return 0;
        });

        return o;
    }, [activeFields, alreadyAddedElements, ignoreElements, isViewRecord]);

    const selectedElementType = useMemo(() => {
        if (elementType === '') return null;
        const type = options.find( e => e.value === elementType );
        return type ?? null;
    }, [elementType, options]);

    return (
        <Dialog open={open} fullWidth>
            <DialogTitle>Add new element</DialogTitle>
            <DialogContent>
                <Box pt={1}>
                    <Autocomplete size="small"
                        value={selectedElementType}
                        options={options}
                        onChange={ (e, v) => setElementType(v ? v.value : '') }
                        renderInput={ (params) => (
                            <TextField
                                {...params}
                                label="Element Type"
                                inputProps={{ ...params.inputProps, "data-lpignore": "true" }}
                        />)}
                        renderOption={(props, option) => (
                            <ListItem {...props}><ListItemText primary={option.label} secondary={option.secondaryLabel} /></ListItem>
                        )}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="error" onClick={ closeHandler }>Cancel</Button>
                <Button variant="contained" color="success" disabled={elementType === ''} onClick={ () => addElementHandler(elementType) }>Add</Button>
            </DialogActions>
        </Dialog>
    );
}