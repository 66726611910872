import moment from "moment";

export function ReportDataProcessDateFields(data: any[]) {
    if (!Boolean(data)) return;
    
    const dateKeys = data.length > 0 ? Object.keys(data[0]).filter(k => k.includes('Date')) : [];
    if (dateKeys.length > 0) {
        for (let i = 0; i < data.length; i++) {
            const row = data[i];
            for (let j = 0; j < dateKeys.length; j++) {
                const k = dateKeys[j];
                let v = row[k];
                const m = moment(v);
                if (m.isValid()) {
                    const d = m.add(16, 'seconds').toDate();
                    // const timeDiff = d.getTimezoneOffset() * 60000;
                    // const realDate = new Date(d.valueOf() + timeDiff);
                    // if (k === 'CreatedDate') {
                    //     console.log('moment', m);
                    //     console.log('mdate', d);
                    //     console.log('real?', realDate);
                    // }
                    row[k] = d;
                }
            }
        }
    }
};