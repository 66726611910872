import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { SxProps } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import React, { useCallback, useState } from "react";
import { GetClientsNotContactedReportData } from "services/ClientsService";
import UserPicker from "components/Pickers/UserPicker";
import * as XLSX from "xlsx";
import moment from "moment";
import { Typography } from "@mui/material";
import { NameIdObj } from "common/models/GenericTypes";
import { ReportDataProcessDateFields } from "util/ReportUtils";

interface Props {
    description: string,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
}

const ownerSpecialOptions: NameIdObj[] = [
    { id: 0, name: 'My Company' },
    { id: -1, name: 'Me' },
    { id: -2, name: 'My Team' },
    { id: -3, name: 'Unassigned' },
];

const filterElementStyle: SxProps = { flex: '1 1 0', mr: 1 };

export default function NotContactedClientsReport({ description, loadingHandler, errorHandler }: Props) {
    const [ownerId, setOwnerId] = useState(0);
    const [status, setStatus] = useState(0);
    const [rating, setRating] = useState(0);
    const [timeRange, setTimeRange] = useState(1);

    const getDataCallback = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        const res = await GetClientsNotContactedReportData(ownerId, status, rating, timeRange, errorHandler);
        if (res) {
            ReportDataProcessDateFields(res as any[]);
            
            const m = moment();
            const filename = `ClientsNotContacted${m.format('YYYYMMDDhhmmss')}.xlsx`;
            const ws = XLSX.utils.json_to_sheet(res);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws);
            XLSX.writeFile(wb, filename);
        }
        loadingHandler && loadingHandler(false);
    }, [ownerId, status, rating, timeRange, errorHandler, loadingHandler]);

    return (
        <>
            <Box display="flex">
                <Box flex="1 1 0" pr={1}>
                    <UserPicker disableClearable label="Owner" userId={ownerId} onSelect={u => setOwnerId(u ? u.id : 0)} appendToStart={ownerSpecialOptions} includeInactiveUsers={true} />
                </Box>
                <TextField select label="Status" value={status.toString()} onChange={ ({target}) => setStatus(+target.value) } sx={filterElementStyle}>
                    <MenuItem value="0">All</MenuItem>
                    <MenuItem value="1">Unqualified</MenuItem>
                    <MenuItem value="2">Qualified</MenuItem>
                    <MenuItem value="3">Client</MenuItem>
                    <MenuItem value="4">Supplier</MenuItem>
                    <MenuItem value="6">Supplier - Sub Contractor</MenuItem>
                    <MenuItem value="5">Archived</MenuItem>
                    <MenuItem value="7">Prospect</MenuItem>
                    <MenuItem value="8">Previous Client</MenuItem>
                </TextField>
                <TextField select label="Rating" value={rating.toString()} onChange={ ({target}) => setRating(+target.value) } sx={filterElementStyle}>
                    <MenuItem value="0">All</MenuItem>
                    <MenuItem value="1">1</MenuItem>
                    <MenuItem value="2">2</MenuItem>
                    <MenuItem value="3">3</MenuItem>
                    <MenuItem value="4">4</MenuItem>
                    <MenuItem value="5">5</MenuItem>
                </TextField>
                <TextField select label="Time Range" value={timeRange.toString()} onChange={ ({target}) => setTimeRange(+target.value) } sx={filterElementStyle}>
                    <MenuItem value="1">1 month</MenuItem>
                    <MenuItem value="3">3 months</MenuItem>
                    <MenuItem value="6">6 months</MenuItem>
                    <MenuItem value="12">1 year</MenuItem>
                </TextField>
                <Button variant="contained" color="success" onClick={getDataCallback}>Run Report</Button>
            </Box>
            <Typography component="div" variant="h6" mt={2}>Report Description</Typography>
            <Typography component="div" variant="body2">{description}</Typography>
        </>
    );
}