import React, { useCallback, useEffect, useState } from "react";
import { Job } from "common/models/Jobs/Job";
import { GetJobById, JobLookup } from "services/JobsService";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import moment from "moment";

interface Props {
    value: number | null,
    onSelectCallback: (j: Job | null) => void,
    openJobsOnly?: boolean,
    variant?: "standard" | "filled" | "outlined" | undefined,
    label?: string,
    delayMs?: number,
    hideLabel?: boolean,
}

export default function JobPicker({ value, onSelectCallback, variant, openJobsOnly = false, label = 'Job', delayMs = 200, hideLabel = false }: Props) {
    const [selectedJob, setSelectedJob] = useState<Job | null>(null);
    const [lookupTerm, setLookupTerm] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState<Job[]>([]);

    const getJob = useCallback(async (jobId: number) => {
        const res = await GetJobById(jobId);
        if (res) setSelectedJob(res);
    }, []);

    useEffect(() => {
        if (value) {
            if (!selectedJob || (selectedJob && selectedJob.id !== value)) {
                const item = options.find(j => j.id === value);
                if (item) setSelectedJob(item);
                else getJob(value);
            }
        }
        else setSelectedJob(null);
    }, [value, selectedJob, options, getJob]);

    useEffect(() => {
        if (lookupTerm.length > 0) {
            const delaySearch = setTimeout(async () => {
                setIsLoading(true);
                const res = await JobLookup(lookupTerm, 25, openJobsOnly);
                if (res) setOptions(res);
                setIsLoading(false);
            }, delayMs);

            return () => clearTimeout(delaySearch);
        }
    }, [lookupTerm, openJobsOnly, delayMs]);

    return (
        <Autocomplete
            size="small"
            options={options}
            value={ selectedJob }
            getOptionLabel={ o => o.title }
            isOptionEqualToValue={(o,v) => o.id === v.id}
            loading={isLoading}
            filterOptions={ x => x }
            onChange={ (e,v) => onSelectCallback(v) }
            renderInput={ (params) => (
                <TextField autoFocus {...params} variant={variant} label={hideLabel ? undefined : label} inputProps={{ ...params.inputProps, "data-lpignore": "true" }}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (<>{isLoading ? <CircularProgress color="inherit" size={20} /> : params.InputProps.endAdornment}</>)
                    }}
                />
            )}
            onInputChange={ (e, val) => setLookupTerm(val) }
            renderOption={(props, option) => (
                <ListItem {...props} key={option.id}>
                    <ListItemText
                        primary={`${option.title} @ ${option.clientName} - ${option.typeName} (#${option.id})`}
                        secondary={`${option.consultant1Name} - ${moment(option.startDate).format('DD MMM YYYY')} - ${option.shortAddress}`}
                    />
                </ListItem>
            )}
        />
    );
}