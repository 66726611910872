import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Draggable from "react-draggable";
import { Bar, CartesianGrid, Cell, ComposedChart, Legend, ReferenceDot, ReferenceLine, ResponsiveContainer, XAxis, YAxis } from "recharts";
import { GetCandidateWorkHistory } from "services/CandidatesService";
import CloseIcon from '@mui/icons-material/Close';
import ControlCameraIcon from '@mui/icons-material/ControlCamera';
import { PREVIEW_HEIGHT, PREVIEW_TABLE_CONTENT, PREVIEW_TABLE_LABEL, PREVIEW_TABLE_STYLE, PREVIEW_TITLE_STYLE, PREVIEW_WIDTH } from "util/Definitions/Constants/Previews";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import { Payload } from "recharts/types/component/DefaultLegendContent";

interface Props {
    candidateId: number,
    lastUpdateDate: string,
    lastCvDate: string,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
}

interface Props2 {
    data: GraphWorkHistory,
    isSourceHovered: boolean,
    hideCallback: () => void,
}

interface GraphWorkHistory {
    range: number[],
    formattedStartDate: string,
    formattedEndDate: string,
    tenure: string,
    title: string,
    orgName: string,
    type: string,
    description: string
}

export default function WorkHistoryTimelineComponent({ candidateId, lastCvDate, lastUpdateDate, loadingHandler, errorHandler }: Props) {
    const [rows, setRows] = useState<GraphWorkHistory[]>([]);
    const [hoverIndex, setHoverIndex] = useState<number | null>(null);
    const [previewIndex, setPreviewIndex] = useState<number | null>(null);
    const [cvUpdateXValues, setCvUpdateXValues] = useState<any>({});
    const [legendPayload, setLegendPayload] = useState<Payload[]>();
    const t = useTheme();

    const current = useMemo(() => moment(), []);

    useEffect(() => {
        let lastCvDays: number | null = null;
        let lastUpdateDays: number | null = null;
        let payloadData: Payload[] = [];

        const cvMoment = moment(lastCvDate);
        if (cvMoment.isValid()) {
            lastCvDays = cvMoment.diff(current, 'days');
            const formatted = cvMoment.format('DD MMM YYYY');
            payloadData.push({ value: `Last CV: ${formatted}`, color: t.palette.error.main });
        }

        const lastUpdateMoment = moment(lastUpdateDate);
        if (lastUpdateMoment.isValid()) {
            lastUpdateDays = lastUpdateMoment.diff(current, 'days');
            const formatted = lastUpdateMoment.format('DD MMM YYYY');
            payloadData.push({ value: `Last Update: ${formatted}`, color: t.palette.primary.main });
        }

        setCvUpdateXValues({ cv: lastCvDays, u: lastUpdateDays });
        setLegendPayload(payloadData);
    }, [current, lastCvDate, lastUpdateDate, t.palette.error.main, t.palette.primary.main]);

    useEffect(() => {
        if (hoverIndex !== null) {
            const showPreviewTimeout = setTimeout(() => {
                setPreviewIndex(hoverIndex);
            }, 100);
            return () => clearTimeout(showPreviewTimeout);
        }
    }, [hoverIndex]);

    useEffect(() => {
        const getData = async () => {
            loadingHandler && loadingHandler(true);
            const res = await GetCandidateWorkHistory(candidateId, 5, errorHandler);
            if (res) {
                let data: GraphWorkHistory[] = [];
                let minD = '';
                for (let i = 0; i < res.length; i++) {
                    const wh = res[i];
                    if (wh.startDate && wh.startDate < minD) minD = wh.startDate;
                    const m1 = moment(wh.startDate);
                    const m2 = wh.endDate ? moment(wh.endDate) : current;
                    let v1 = 0;
                    let v2 = 0;
                    let startDate = '';
                    let endDate = '';
                    let tenure = '';
                    let isSingleMonth = wh.startDate === wh.endDate;
    
                    if (m1.isValid()) {
                        startDate = m1.format('DD MMM YYYY');
                        v1 = m1.diff(current, 'days');
                    }
                    if (m2.isValid()) {
                        if (isSingleMonth) {
                            endDate = m2.add(1, 'month').format('DD MMM YYYY');
                            v2 = m2.add(1, 'month').diff(current, 'days');
                        }
                        else {
                            endDate = m2.format('DD MMM YYYY');
                            v2 = m2.diff(current, 'days');
                        }
                    }
    
                    if (m1.isValid() && m2.isValid()) {
                        const diff = m2.diff(m1, 'months');
                        const months = diff % 12;
                        const years = Math.floor(diff / 12);
                        if (isSingleMonth) tenure = '0 Year(s) 1 Month(s)';
                        else tenure = `${years} Year(s) ${months} Month(s)`;
                    }
                    
                    data.push(
                        {
                            orgName: wh.organizationName,
                            title: wh.title,
                            type: wh.jobTypeName,
                            description: wh.description,
                            formattedEndDate: endDate,
                            formattedStartDate: startDate,
                            tenure: tenure,
                            range: [v1, v2]
                        }
                    );
                }
                setRows(data);
            }
            loadingHandler && loadingHandler(false);
        };
        getData();
    }, [candidateId, current, errorHandler, loadingHandler]);

    const xAxisLabelsFormatter = useCallback((value: any) => {
        if (typeof value === 'number') {
            const m = moment();
            return m.add(value, 'days').format('DD MMM YYYY');
        }
        else if (typeof value === 'string') {
            const m = moment(value);
            if (m.isValid()) return m.format('MMM YYYY');
        }
        return '';
    }, []);

    const yAxisTickRenderer = useCallback((tickProps: any) => {
        const { x, y, payload} = tickProps;
        if (tickProps && payload) {
            const index = payload.index;
            const r = rows[index];
            const orgName = r.orgName.length > 40 ? r.orgName.substring(0, 37) + '...' : r.orgName;
            const title = r.title.length > 40 ? r.title.substring(0, 37) + '...' : r.title;
            if (r) {
                return (
                    <svg cursor="pointer" onMouseEnter={() => setHoverIndex(index)} onMouseLeave={() => setHoverIndex(null)} fontSize={t.typography.body2.fontSize} textLength={50}>
                        <text x={x} y={y - 5} stroke={t.palette.text.primary}>
                            <title>{r.title}</title>
                            {title}
                        </text>
                        <text x={x} y={y + 15} stroke={t.palette.text.disabled}>
                            <title>{r.orgName}</title>
                            {orgName}
                        </text>
                    </svg>
                );
            }
        }
        return <></>;
    }, [rows, t.palette.text.disabled, t.palette.text.primary, t.typography.body2.fontSize]);

    const barColors = useMemo<string[]>(() => {
        const p = t.palette;
        return [p.primary.main, p.secondary.main, p.info.main, p.warning.main, p.success.main];
    }, [t.palette]);

    const selectedWorkHistory = useMemo(() => {
        if (previewIndex !== null) return rows[previewIndex];
        return null;
    }, [previewIndex, rows]);

    const hidePreviewCallback = useCallback(() => {
        setPreviewIndex(null);
    }, []);

    return (
        <>
            {selectedWorkHistory &&
                <CustomWorkHistoryPreview isSourceHovered={hoverIndex !== null} data={selectedWorkHistory} hideCallback={hidePreviewCallback} />
            }
            <Box height="calc(100% - 48px)">
                <ResponsiveContainer width="100%">
                    <ComposedChart layout="vertical" data={rows} width={150} height={150}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="range" type="number" domain={['dataMin', 'dataMax']} tickFormatter={xAxisLabelsFormatter} stroke={t.palette.text.primary} />
                        <YAxis dataKey="orgName" type="category" orientation="right" width={270} tick={yAxisTickRenderer} />
                        <YAxis hide yAxisId="dots" orientation="right" />
                        <Bar cursor="pointer" dataKey="range" fill={t.palette.primary.main} onMouseEnter={(data, index) => setHoverIndex(index)} onMouseLeave={() => setHoverIndex(null)}>
                            {rows.map((entry, index) => (
                                <Cell key={index} fill={barColors[index]} />
                            ))}
                        </Bar>
                        <Legend payload={legendPayload} />
                        { cvUpdateXValues.cv !== null && <ReferenceLine x={cvUpdateXValues.cv} />  }
                        { cvUpdateXValues.cv !== null && <ReferenceDot x={cvUpdateXValues.cv} y={1} radius={8} fill={t.palette.error.main} yAxisId="dots" stroke="none" />  }
                        { cvUpdateXValues.u !== null && <ReferenceLine x={cvUpdateXValues.u} />  }
                        { cvUpdateXValues.u !== null && <ReferenceDot x={cvUpdateXValues.u} y={1} radius={8} fill={t.palette.primary.main} yAxisId="dots" stroke="none" />  }
                    </ComposedChart>
                </ResponsiveContainer>
            <Box>
                <Box component="span" bgcolor="error.main" width={1} height={1} />
                <Box component="span" bgcolor="primary.main" width={1} height={1} />
            </Box>
            </Box>
        </>
    );
}

const CustomWorkHistoryPreview = ({ data, isSourceHovered, hideCallback }: Props2) => {
    const [isHovered, setIsHovered] = useState(false);
    const [shouldClose, setShouldClose] = useState(false);

    useEffect(() => {
        if (isHovered || isSourceHovered) setShouldClose(false);
        else setShouldClose(true);
    }, [isHovered, isSourceHovered]);

    useEffect(() => {
        if (shouldClose) {
            const hidePreviewTimeout = setTimeout(() => {
                hideCallback();
            }, 150);
            return () => clearTimeout(hidePreviewTimeout);
        }
    }, [shouldClose, hideCallback]);

    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
            defaultPosition={{ x: (window.innerWidth / 2) - (PREVIEW_WIDTH / 2), y: (window.innerHeight / 2) - (PREVIEW_HEIGHT / 2) }}
        >
            <Dialog
                open
                maxWidth="md"
                fullWidth
                PaperProps={{
                    onMouseEnter: () => setIsHovered(true),
                    onMouseLeave: () => setIsHovered(false),
                    sx: { height: `${PREVIEW_HEIGHT}px`, width: `${PREVIEW_WIDTH}px` }
                }}
                hideBackdrop
                sx={{ bottom: 'unset', right: 'unset', left: 'unset' }}
            >
                <DialogTitle component="div" display="flex" sx={ PREVIEW_TITLE_STYLE }>
                    <div style={{ alignSelf: 'center' }}>Work History</div>
                    <div style={{ marginLeft: 'auto' }}>
                        <IconButton id="draggable-dialog-title" sx={{ cursor: 'move', color: t => t.palette.primary.contrastText }}><ControlCameraIcon /></IconButton>
                        <IconButton onClick={ () => hideCallback() } sx={{ color: t => t.palette.primary.contrastText }}><CloseIcon /></IconButton>
                    </div>
                </DialogTitle>
                <DialogContent sx={{ display: 'flex' }}>
                    <div  style={{ paddingTop: '10px', display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <table style={PREVIEW_TABLE_STYLE}>
                                    <tbody>
                                        <tr>
                                            <td width="30%" style={PREVIEW_TABLE_LABEL}>Role Title</td>
                                            <td width="70%" style={PREVIEW_TABLE_CONTENT}>{data.title}</td>
                                        </tr>
                                        <tr>
                                            <td width="30%" style={PREVIEW_TABLE_LABEL}>Employer</td>
                                            <td width="70%" style={PREVIEW_TABLE_CONTENT}>{data.orgName}</td>
                                        </tr>
                                        <tr>
                                            <td width="30%" style={PREVIEW_TABLE_LABEL}>Role Type</td>
                                            <td width="70%" style={PREVIEW_TABLE_CONTENT}>{data.type}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Grid>
                            <Grid item xs={6}>
                                <table style={PREVIEW_TABLE_STYLE}>
                                    <tbody>
                                        <tr>
                                            <td width="30%" style={PREVIEW_TABLE_LABEL}>Start Date</td>
                                            <td width="70%" style={PREVIEW_TABLE_CONTENT}>{data.formattedStartDate}</td>
                                        </tr>
                                        <tr>
                                            <td width="30%" style={PREVIEW_TABLE_LABEL}>End Date</td>
                                            <td width="70%" style={PREVIEW_TABLE_CONTENT}>{data.formattedEndDate}</td>
                                        </tr>
                                        <tr>
                                            <td width="30%" style={PREVIEW_TABLE_LABEL}>Tenure</td>
                                            <td width="70%" style={PREVIEW_TABLE_CONTENT}>{data.tenure}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Grid>
                        </Grid>
                        <div style={{ paddingTop: '5px' }}>
                            <Divider>Description</Divider>
                        </div>
                        <div style={{ flexGrow: 1, overflow: 'auto', maxHeight: '400px', minHeight: '50px' }} dangerouslySetInnerHTML={{ __html: data.description }} />
                    </div>
                </DialogContent>
            </Dialog>
        </Draggable>
    );
};