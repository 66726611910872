import { PanelModel } from "@syncfusion/ej2-layouts/src/dashboard-layout/dashboard-layout-model";
import { SiteRecordDashboardElementDefinition, SiteRecordDashboardElementType } from "common/models/Dashboard/EditLayout";
import { ScreenLayoutSettings } from "common/models/ScreenLayouts/ScreenLayouts";
import { GetCustomFieldTypeById } from "./CustomFields";

export const DefaultSiteRecordDashboardElements: SiteRecordDashboardElementDefinition[] = [
    { id: 'SiteName_Default', row: 0, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'SiteName' },
    { id: 'SiteStatus_Default', row: 1, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'SiteStatus' },
    { id: 'SitePhone_Default', row: 2, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'SitePhone' },
    { id: 'SitePrimaryContact_Default', row: 3, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'SitePrimaryContact' },
    
    { id: 'SiteAddress1_Default', row: 0, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'SiteAddress1' },
    { id: 'SiteAddress2_Default', row: 1, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'SiteAddress2' },
    { id: 'SiteAddress3_Default', row: 2, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'SiteAddress3' },
    { id: 'SiteSuburb_Default', row: 3, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'SiteSuburb' },
    { id: 'SiteState_Default', row: 4, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'SiteState' },
    { id: 'SitePostcode_Default', row: 5, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'SitePostcode' },
    { id: 'SiteCountry_Default', row: 6, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'SiteCountry' },
    
    { id: 'SiteSummary_Default', row: 0, col: 2, sizeX: 1, sizeY: 9, minSizeX: 1, minSizeY: 3, maxSizeX: 1, maxSizeY: null, type: 'SiteSummary' },
];

export const GetMinSizeByElementType = (type: SiteRecordDashboardElementType) => {
    switch (type) {
        case "SiteSummary": return [1, 9];
        default: return [1, 1];
    }
};

export const GetElementTypeById = (id: string): SiteRecordDashboardElementType => {
    if ( id && id.startsWith('Divider_')) return 'Divider';
    if ( id && id.startsWith('Spacer_')) return 'Spacer';
    if ( id && id.startsWith('SiteId_')) return 'SiteId';
    if ( id && id.startsWith('SiteName_')) return 'SiteName';
    if ( id && id.startsWith('SiteStatus_')) return 'SiteStatus';
    if ( id && id.startsWith('SitePhone_')) return 'SitePhone';
    if ( id && id.startsWith('SitePrimaryContact_')) return 'SitePrimaryContact';
    if ( id && id.startsWith('SiteAddress1_')) return 'SiteAddress1';
    if ( id && id.startsWith('SiteAddress2_')) return 'SiteAddress2';
    if ( id && id.startsWith('SiteAddress3_')) return 'SiteAddress3';
    if ( id && id.startsWith('SiteSuburb_')) return 'SiteSuburb';
    if ( id && id.startsWith('SiteState_')) return 'SiteState';
    if ( id && id.startsWith('SitePostcode_')) return 'SitePostcode';
    if ( id && id.startsWith('SiteCountry_')) return 'SiteCountry';
    if ( id && id.startsWith('SiteSummary_')) return 'SiteSummary';
    if ( id && id.startsWith('CustomField')) return GetCustomFieldTypeById(id);
    return '';
};

export const GetPanelDefinitionsFromPanelModels = (panels: PanelModel[]) => {
    let elements: SiteRecordDashboardElementDefinition[] = [];
    panels.forEach((p, i) => {
        let type = GetElementTypeById(p.id ?? '');
        elements.push({
            id: p.id ?? i.toString(),
            sizeX: p.sizeX ?? 1,
            sizeY: p.sizeY ?? 1,
            col: p.col ?? 0,
            row: p.row ?? 0,
            minSizeX: p.minSizeX ?? 1,
            minSizeY: p.minSizeY ?? 1,
            maxSizeX: p.maxSizeX ?? 1,
            maxSizeY: p.maxSizeY ?? null,
            type: type
        });
    });
    return elements ;
};

export const SiteScreenLayoutSettings: ScreenLayoutSettings = {
    unitWidth: 600,
    unitHeight: 40,
    gapX: 10,
    gapY: 0,
    columns: 3,
    mediaQueryMaxWidth: '1000px'
};