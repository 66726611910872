import React, { useCallback, useEffect, useMemo, useState } from "react";
import useKanbanStyleUpdater from "../../hooks/UseKanbanStyleUpdater";
import { GetJobCandidateRatingCounts, GetJobCandidates, GetJobCandidatesByAdvertId } from "../../services/JobsService";
import { KanbanCardTagStyle } from "../../util/Definitions/Kanban";
import { JobTracking } from "../../common/models/Jobs/JobTracking";
import PersonIcon from '@mui/icons-material/Person';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import Box from "@mui/material/Box";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import { useNavigate } from "react-router-dom";
import { JobCandidateKanbanGroup } from "../../common/models/Jobs/Job";
import { GetKanbanGroups } from "../../services/ConfigurationService";
import { KanbanGroupingColumn } from "../../common/models/Jobs/JobTracking";

interface Props {
    jobId: number,
    advertId?: number,
    extraActions?: JSX.Element,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void
}

const ratingStyle = {
    '3': {
        background: '#C0C0C0',  //grey
        text: '#696969',
    },
    '1': {
        background: '#9bc2e6', //blue
        text: '#203764',
    },
    '2': {
        background: '#ffeb9c',  //yellow
        text: '#9c6500',
    },
    '0': {
        background: '#ffc7ce', //red
        text: '#9c0006',
    },
    '4': {
        background: '#c6efce', //green
        text: '#006100',
    },
};

const kanbanElementDomId = 'candidates-kanban';
const greenBgColor = "#1da826";
const yellowBgColor = "#fecc02";
const blueBgColor = "#0366cc";
const redBgColor = "#cc0303";
const emptyRatingCounts = [0, 0, 0, 0, 0, 0];

export default function CandidateKanbanComponent({ jobId, advertId = 0, extraActions, loadingHandler, errorHandler }: Props) {
    const [rawData, setRawData] = useState<JobTracking[] | null>(null);
    const [kanbanGroupings, setKanbanGroupings] = useState<JobCandidateKanbanGroup[]>([]);
    const [isSetupFinished, setIsSetupFinished] = useState(false);
    const [ratingCounts, setRatingCounts] = useState(emptyRatingCounts);
    const [ratingIdFilter, setRatingIdFilter] = useState<number>();
    const [isInitialQuerySetupFinished, setIsInitialQuerySetupFinished] = useState(false);
    const [styleUpdaterTrigger, setStyleUpdaterTrigger] = useState(false);
    const navigate = useNavigate();
    useKanbanStyleUpdater(isSetupFinished, undefined, styleUpdaterTrigger);

    useEffect(() => {
        const getData = async () => {
            loadingHandler && loadingHandler(true);
            let res: JobTracking[] | null = [];
            if (advertId === 0) res = await GetJobCandidates(jobId, errorHandler);
            else res = await GetJobCandidatesByAdvertId(jobId, advertId, errorHandler);
            if (res) setRawData(res);
            let grouping: JobCandidateKanbanGroup[] | null = [];
            grouping = await GetKanbanGroups(true);
            if(grouping) {
                setKanbanGroupings(grouping);
            }
            loadingHandler && loadingHandler(false);
        };
        getData();
    }, [advertId, errorHandler, jobId, loadingHandler]);

    useEffect(() => {
        if (jobId || advertId || ratingIdFilter) {}
        const k = document.getElementById(kanbanElementDomId);
        if (k) {
            for (let i = 0; i < k.children.length; i++) {
                const child = k.children[i];
                k.removeChild(child);
            }
        }
        setStyleUpdaterTrigger(prev => !prev);
    }, [advertId, jobId, ratingIdFilter]);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const rating = urlParams.get('RatingID');
        if (rating) {
            const parsed = +rating;
            if (parsed === 0 || parsed === 1 || parsed === 2 || parsed === 3 || parsed === 4) setRatingIdFilter(parsed);
        }
        setIsInitialQuerySetupFinished(true);
    }, []);

    useEffect(() => {
        const getCounts = async () => {
            const res = await GetJobCandidateRatingCounts(jobId, advertId);
            if (res) setRatingCounts([res.red, res.blue, res.yellow, res.unrated, res.green, res.total]);
        };
        getCounts();
    }, [advertId, jobId]);

    const generatQueryString = useCallback(() => {
        const urlParams = new URLSearchParams(window.location.search);
        if (ratingIdFilter || ratingIdFilter === 0) urlParams.set('RatingID', ratingIdFilter.toString());
        else urlParams.delete('RatingID');
        return urlParams.toString();
    }, [ratingIdFilter]);

    useEffect(() => {
        if (isInitialQuerySetupFinished) {
            let url = window.location.pathname + '?' + generatQueryString();
            navigate(url);
        }
    }, [generatQueryString, isInitialQuerySetupFinished, navigate]);
    
    useEffect(() => {
        const setup = async () => {
            setIsSetupFinished(false);
            const kanbanContainer = document.getElementById(kanbanElementDomId);

            if (kanbanContainer && rawData) {
                let cardColumns: KanbanGroupingColumn[] = [];
                kanbanGroupings.map(k=> {
                    if(rawData) {
                        const count = rawData.filter(r=>r.kanbanGroupingID == k.id);
                        cardColumns.push({id: k.id, label: `${k.name} [${count.length}]`} )
                    }
                });
                let cardData = [];
                for (var i = 0; i < rawData.length; i++) {
                    const d = rawData[i];

                    if (ratingIdFilter === undefined || ratingIdFilter === d.ratingID) {
                        let cardColor = (ratingStyle as any)[d.ratingID] ?? { background: 'white', text: 'black' };

                        cardData.push({
                            id: d.jobTrackingID,
                            column: d.kanbanGroupingID,
                            title: d.fullName,
                            candidateID: d.id,
                            jobID: d.jobID,
                            ratingBgColor: cardColor.background,
                            ratingTextColor: cardColor.text,
                            statusName: d.currentStatusName,
                            statusLastUpdatedValue: d.statusLastUpdatedValue,
                            jobTitle: d.jobTitle,
                            client: d.employer,
                            source: d.source
                        });
                    }
                }

                if(cardColumns.length > 0) {
                    new (window as any).kanban.Kanban(`#${kanbanElementDomId}`, {
                        readonly: true,
                        scrollType: 'column',
                        columns: cardColumns,
                        cards: cardData,
                        cardTemplate: (params: any) => {
                            const {cardFields} = params;
                            const { candidateID, jobID, title, source, statusLastUpdatedValue, statusName, ratingBgColor, ratingTextColor } = cardFields;
                            return `
    <div style='padding: 10px 5px; border-left: 0.5rem solid ${ratingBgColor}; border-radius: var(--wx-kanban-card-border-radius)'>
        <div><a style="color: #008299; font-weight: bold;" href='/candidates/${candidateID}?jobId=${jobID}'>${title}</a></div>
        ${source ? `<div>${source}</div>` : ''}
        <div>${statusLastUpdatedValue}</div>
        <div style='display: flex; justify-content: end'>
            ${ratingBgColor !== 'white' ? `<div style="background: ${ratingBgColor}; color: ${ratingTextColor}; ${KanbanCardTagStyle}">${statusName}</div>` : ''}
        </div>
    </div>
    `;
                        },
                    });
                }
                setStyleUpdaterTrigger(prev => !prev);
                setIsSetupFinished(true);
            }
        };
        isInitialQuerySetupFinished && rawData && setup();
    }, [jobId, advertId, rawData, isInitialQuerySetupFinished, ratingIdFilter,kanbanGroupings]);

    const ratingFilterActions = useMemo(() => {
        const size = 30;
        return (
            <Box display="flex" gap="15px" alignItems="center">
                <Badge badgeContent={ratingCounts[4]} color="primary" max={10000}>
                    <Avatar onClick={() => setRatingIdFilter(prev => prev === 4 ? undefined : 4)} sx={{ color: ratingIdFilter === 4 ? 'black' : undefined, bgcolor: greenBgColor, width: size, height: size, cursor: 'pointer' }}>
                        <PersonIcon />
                    </Avatar>
                </Badge>
                <Badge badgeContent={ratingCounts[2]} color="primary" max={10000}>
                    <Avatar onClick={() => setRatingIdFilter(prev => prev === 2 ? undefined : 2)} sx={{ color: ratingIdFilter === 2 ? 'black' : undefined, bgcolor: yellowBgColor, width: size, height: size, cursor: 'pointer' }}>
                        <PersonIcon />
                    </Avatar>
                </Badge>
                <Badge badgeContent={ratingCounts[1]} color="primary" max={10000}>
                    <Avatar onClick={() => setRatingIdFilter(prev => prev === 1 ? undefined : 1)} sx={{ color: ratingIdFilter === 1 ? 'black' : undefined, bgcolor: blueBgColor, width: size, height: size, cursor: 'pointer' }}>
                        <PersonIcon />
                    </Avatar>
                </Badge>
                <Badge badgeContent={ratingCounts[0]} color="primary" max={10000}>
                    <Avatar onClick={() => setRatingIdFilter(prev => prev === 0 ? undefined : 0)} sx={{ color: ratingIdFilter === 0 ? 'black' : undefined, bgcolor: redBgColor, width: size, height: size, cursor: 'pointer' }}>
                        <PersonIcon />
                    </Avatar>
                </Badge>
                <Badge badgeContent={ratingCounts[3]} color="primary" max={10000}>
                    <Avatar onClick={() => setRatingIdFilter(prev => prev === 3 ? undefined : 3)} sx={{ color: ratingIdFilter === 3 ? 'black' : undefined, width: size, height: size, cursor: 'pointer' }}>
                        <PersonIcon />
                    </Avatar>
                </Badge>
                <Badge badgeContent={ratingCounts[5]} color="primary" max={10000}>
                    <Avatar onClick={() => setRatingIdFilter(undefined)} sx={{ width: size, height: size, cursor: 'pointer' }}>
                        <FilterAltOffIcon />
                    </Avatar>
                </Badge>
            </Box>
        );
    }, [ratingCounts, ratingIdFilter]);

    return (
        <>
            <Box>
                { extraActions }
                { <Box pb="10px" sx={{ float: 'right' }}>{ ratingFilterActions }</Box>}
            </Box>
            <div style={{ display: 'flex', flex: '1', flexDirection: 'column', position: 'relative' }}>
                <div style={{ display: 'flex', flex: '1', flexDirection: 'column', position: 'relative', overflow: 'hidden' }}>
                    <div id={kanbanElementDomId} style={{ position: 'absolute', top: 0, left: 0, height: '100%', width: '100%', overflow: 'auto' }} />
                </div>
            </div>
        </>
    );
}