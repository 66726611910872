import { ScreeningQuestionnaireTemplate } from "common/models/Configuration/AdvertScreeningQuestionnaire";
import { ChangeTracker } from "common/models/hooks/ChangeTracker";


export const DefaultScreeningQuestionnaire: ScreeningQuestionnaireTemplate = {
    id: 0,
    automatedRejectionDelay: 60,
    name: '',
    statusID: 1,
    status: 'Active',
    json: '[]'
}

export const NoChangesTracker: ChangeTracker<ScreeningQuestionnaireTemplate> = {
    id: false,
    automatedRejectionDelay: false,
    name: false,
    statusID: false,
    status: false,
    json: false
}