import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import SlowTextFieldWrapper from "components/SlowTextFieldWrapper";
import React, { useCallback, useMemo } from "react";
import { LinkedInSocialDeleteImage, LinkedInSocialUploadImage } from "services/JobBoardsService";

interface Props {
    jobBoardId: number,
    advertId: number,
    attributes?: Record<string, string>,
    attributeChangeHandler?: (jobBoardId: number, key: string, value: string) => void,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
}

export function IsTextValid(value: string) {
    if (value === '') return true;
    return /^(?=.*[a-zA-Z\d].*)[a-zA-Z\d\s.',?/\n]+$/g.test(value);
}

const helpText = "Only letters, numbers and the following symbols are allowed for LinkedIn Social: . ' , ? /";

export default function LinkedInSocialEdit({ jobBoardId, advertId, attributes, attributeChangeHandler, loadingHandler, errorHandler }: Props) {
    const fileInputId = useMemo(() => `file-picker-${jobBoardId}`, [jobBoardId]);

    const atts = useMemo<Record<string, string>>(() => {
        if (attributes) return attributes;
        return {};
    }, [attributes]);

    const socialContents = useMemo(() => atts.SocialContents ?? '', [atts.SocialContents]);
    const friendlyName = useMemo(() => atts.ImageFriendlyName ?? '', [atts.ImageFriendlyName]);
    const realName = useMemo(() => atts.ImageRealName ?? '', [atts.ImageRealName]);

    const onStatusChange = useCallback((newValue: string) => {
        attributeChangeHandler && attributeChangeHandler(jobBoardId, 'SocialContents', newValue);
    }, [attributeChangeHandler, jobBoardId]);

    const onFileChange = useCallback(async (e: React.ChangeEvent<HTMLInputElement>) => {
        const { files } = e.target;
        if (files && files.length > 0) {
            loadingHandler && loadingHandler(true);
            const res = await LinkedInSocialUploadImage(jobBoardId, advertId, files[0], errorHandler);
            loadingHandler && loadingHandler(false);
            if (res) {
                attributeChangeHandler && attributeChangeHandler(jobBoardId, 'ImageFriendlyName', res.friendlyName);
                attributeChangeHandler && attributeChangeHandler(jobBoardId, 'ImageRealName', res.realName);
                attributeChangeHandler && attributeChangeHandler(jobBoardId, 'UrlImage', res.url);
            }
        }
    }, [advertId, attributeChangeHandler, errorHandler, jobBoardId, loadingHandler]);

    const onDeleteFile = useCallback(async () => {
        if (realName) {
            loadingHandler && loadingHandler(true);
            const res = await LinkedInSocialDeleteImage(jobBoardId, advertId, realName, errorHandler);
            if (res) {
                attributeChangeHandler && attributeChangeHandler(jobBoardId, 'ImageFriendlyName', '');
                attributeChangeHandler && attributeChangeHandler(jobBoardId, 'ImageRealName', '');
                attributeChangeHandler && attributeChangeHandler(jobBoardId, 'UrlImage', '');
            }
            loadingHandler && loadingHandler(false);
        }
    }, [advertId, attributeChangeHandler, errorHandler, jobBoardId, loadingHandler, realName]);

    const onUploadButtonClick = useCallback(() => {
        const b = document.getElementById(fileInputId);
        if (b) b.click();
    }, [fileInputId]);

    return (
        <Box display="flex" gap={2} pt={2} flexDirection="column">
            <SlowTextFieldWrapper label="LinkedIn Status" multiline rows={7} helperText={helpText} value={socialContents} onChange={onStatusChange} />
            <Box>
                <input id={fileInputId} type="file" accept="image/*" onChange={onFileChange} style={{ display: 'none' }} />
                <Box>
                    <input type="button" value="Upload File..." onClick={onUploadButtonClick} />
                    {Boolean(friendlyName) && <Typography variant="caption" ml={1}>{friendlyName}</Typography> }
                </Box>
                <Typography variant="caption" ml={1.75} color="text.secondary">For best results Image should be 138w x 72h or 520w x 272h</Typography>
            </Box>
            {Boolean(realName) && <Box><input type="button" value="Delete File" onClick={onDeleteFile} /> </Box> }
        </Box>
    );
}