import { useEffect, useState } from "react";
import { GetAllUsers } from "../services/UsersService";
import { NameIdObj } from "../common/models/GenericTypes";

const allUsersLocalSettingName = 'AllUsers';

export default function useAllUsersMap() {
    const [userMap, setUserMap] = useState<Record<number, string>>({});
    useEffect(() => {
        const getData = async () => {
            const cachedUsers = localStorage.getItem(allUsersLocalSettingName);
            let users: NameIdObj[] = [];
            if (cachedUsers) users = JSON.parse(cachedUsers);
            else {
                const res = await GetAllUsers();
                if (res) {
                    const data: NameIdObj[] = res.map(u => ({ id: u.id, name: u.displayName }));
                    localStorage.setItem(allUsersLocalSettingName, JSON.stringify(data));
                    users = data;
                }
            }

            let map: Record<number, string> = {};
            for (let i = 0; i < users.length; i++) {
                const u = users[i];
                map[u.id] = u.name;
            }
            setUserMap(map);
        };

        getData();
    }, []);

    return userMap;
}