import { Activity, ActivityAttribute } from "../common/models/Activities";
import { ErrorHandler, NumberValueWrapper, RequestOptions } from "../common/models/GenericTypes";
import { Delete, Get, Post, Put } from "../util/HttpUtils";

export async function GetActivityById(activityId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Activity>(`api/Activities/${activityId}`, options);
}

export async function GetActivityAttributesById(activityId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<ActivityAttribute[]>(`api/Activities/${activityId}/Attributes`, options);
}

export async function CreateActivity(data: Activity, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<NumberValueWrapper>(`api/Activities`, options, data);
}

export async function UpdateActivity(activityId: number, data: Activity, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/Activities/${activityId}`, options, data);
}

export async function GetDashboardActivities(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Activity[]>('api/Activities/Dashboard', options);
}

export async function GetActivitiesByClientId(clientId: number, status: number = 0, category: number = 0, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Activity[]>(`api/clients/${clientId}/Activities?status=${status}&category=${category}`, options);
}

export async function GetActivitiesByContactId(contactId: number, status: number = 0, category: number = 0, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Activity[]>(`api/contacts/${contactId}/Activities?status=${status}&category=${category}`, options);
}

export async function GetActivitiesByOpportunityId(opportunityId: number, status: number = 0, category: number = 0, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Activity[]>(`api/opportunities/${opportunityId}/Activities?status=${status}&category=${category}`, options);
}

export async function GetActivitiesByJobId(jobId: number, status: number = 0, category: number = 0, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Activity[]>(`api/jobs/${jobId}/Activities?status=${status}&category=${category}`, options);
}

export async function GetActivitiesByMeetingId(meetingId: number, status: number = 0, category: number = 0, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Activity[]>(`api/meetings/${meetingId}/Activities?status=${status}&category=${category}`, options);
}

export async function GetActivitiesByInterviewId(interviewId: number, status: number = 0, category: number = 0, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Activity[]>(`api/interviews/${interviewId}/Activities?status=${status}&category=${category}`, options);
}

export async function GetActivitiesByCandidateId(candidateId: number, status: number = 0, category: number = 0, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Activity[]>(`api/candidates/${candidateId}/Activities?status=${status}&category=${category}`, options);
}

export async function GetActivitiesByPlacementId(placementId: number, status: number = 0, category: number = 0, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Activity[]>(`api/placements/${placementId}/Activities?status=${status}&category=${category}`, options);
}

export async function DeleteActivityById(activityId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Delete<boolean>(`api/Activities/${activityId}`, options);
}

export async function DeleteActivities(activityIds: number[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Delete<boolean>('api/Activities/Bulk', options, activityIds);
}

export async function CloseActivities(activityIds: number[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>('api/Activities/Close/Bulk', options, activityIds);
}

export async function RescheduleActivities(date: string, activityIds: number[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/Activities/Reschedule/Bulk?date=${encodeURIComponent(date)}`, options, activityIds);
}

export async function ActivityLeftMessage(activityId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>(`api/Activities/${activityId}/LeftMessage`, options);
}

export async function ActivityFollowUp(activityId: number, assignTo: number, followUpDate: string, notes: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };
    var result = Post<Activity>(`api/Activities/${activityId}/FollowUp/${assignTo}?date=${encodeURIComponent(followUpDate)}`, options, { value: notes });
    return result;
}

export async function ReassignActivities(userId: number, activityIds: number[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/Activities/Reassign/Bulk?userId=${userId}`, options, activityIds);
}

export async function GetActivityReportData(recruiterId: number, reportType: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Activity[]>(`api/Activities/Search?recruiterId=${recruiterId}&reportType=${encodeURIComponent(reportType)}`, options);
}


export async function GetActivityDetailedReportData(owner: number, start_date: string, end_date: string, using: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<any[]>(`api/misc/reports/activity-detailed?owner=${owner}&startDate=${start_date}&endDate=${end_date}&reportType=${using}`, options);
}

export async function GetInterviewsDetailedReportData(owner: number, start_date: string, end_date: string, using: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<any[]>(`api/misc/reports/interviews-detailed?owner=${owner}&startDate=${start_date}&endDate=${end_date}&reportType=${using}`, options);
}

export async function GetMeetingsDetailedReportData(owner: number, start_date: string, end_date: string, using: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<any[]>(`api/misc/reports/meetings-detailed?owner=${owner}&startDate=${start_date}&endDate=${end_date}&reportType=${using}`, options);
}

export async function GetSubmissionsDetailedReportData(owner: number, start_date: string, end_date: string, using: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<any[]>(`api/misc/reports/submissions-detailed?owner=${owner}&startDate=${start_date}&endDate=${end_date}&reportType=${using}`, options);
}

export async function GetActivitySummaryReportData(owner: number, status: number, start_date: string, end_date: string, using: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<any[]>(`api/misc/reports/activity-summary?owner=${owner}&status=${status}&startDate=${start_date}&endDate=${end_date}&reportType=${using}`, options);
}