import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import * as tinymce from 'tinymce';
import { TemplatePlaceholder } from "common/models/Templates/TemplatePlaceholder";
import { useTheme } from "@mui/material/styles";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

interface Props {
    toolbar1?: string,
    height?: string | number,
    editorRef?: React.MutableRefObject<tinymce.Editor | null>,
    initialValue?: string,
    placeholders?: TemplatePlaceholder[],
    onDirtyHandler?: (isDirty: boolean) => void,
    onContentChange: (content: string) => void,
}

export default function TextAreaWithFormat({ height = 380, editorRef: editorRefProp, initialValue, placeholders, toolbar1: toolbar1Prop = '', onDirtyHandler, onContentChange }: Props) {
    const theme = useTheme();
    const isDarkTheme = theme.palette.mode === 'dark';
    const editorRef = useRef<tinymce.Editor | null>(null);
    const [inputValue, setInputValue] = useState('');
    const [showPlaceholders, setShowPlaceholders] = useState(false);
    const [rerenderControl, setRerenderControl] = useState(false);

    useEffect(() => {
        if (placeholders) {}
        setRerenderControl(prev => !prev);
    }, [placeholders]);

    const hasPlaceholders = useMemo(() => placeholders && placeholders.length > 0, [placeholders]);

    const onPlacementSelectedCallback = useCallback((e: any, o: TemplatePlaceholder | null) => {
        const api = editorRef.current;
        if (hasPlaceholders && o && api) {
            api.insertContent(o.placeholderUsage);
        }
    }, [hasPlaceholders]);

    const toolbar1 = useMemo(() => {
        if (toolbar1Prop && hasPlaceholders) return 'placeholders ' + toolbar1Prop;
        else if (toolbar1Prop) return toolbar1Prop;
        return undefined;
    }, [hasPlaceholders, toolbar1Prop]);

    const editor = useMemo(() => {
        return (
            <Editor
                tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce-5.10.2/tinymce.min.js'}
                value={initialValue}
                onDirty={ () => onDirtyHandler && onDirtyHandler(true) }
                onInit={ (evt, editor) => {
                    if (editorRefProp !== undefined) editorRefProp.current = editor;
                    editorRef.current = editor;
                }}
                onEditorChange={onContentChange}
                init={{
                    height: height,
                    skin: isDarkTheme ? 'oxide-dark' : undefined,
                    content_css: isDarkTheme ? 'dark' : undefined,
                    branding: false,
                    menubar: false,
                    statusbar: false,
                    contextmenu: false,
                    resize: false,
                    plugins: 'powerpaste link code fullscreen table',
                    browser_spellcheck: true,
                    forced_root_block: false,
                    verify_html: false,
                    toolbar1: toolbar1 ,
                    font_formats: 'Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Calibri=calibri; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Segoe=segoe,segoe ui,dejavu sans,trebuchet ms,verdana,sans-serif; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva',
                    setup: (editor) => {
                        if (hasPlaceholders) {
                            editor.ui.registry.addButton('placeholders', {
                                text: 'Insert Placeholder',
                                onAction: () => setShowPlaceholders(true)
                            });
                        }
                    }
                }}
            />
        );
    }, [editorRefProp, hasPlaceholders, height, initialValue, isDarkTheme, onContentChange, onDirtyHandler, toolbar1]);
    
    return (
        <>
            {hasPlaceholders &&
                <Dialog open={showPlaceholders} fullWidth>
                    <DialogTitle>Insert Placeholder</DialogTitle>
                    <DialogContent>
                        <Box pt={1}>
                            <Autocomplete
                                size="small"
                                inputValue={inputValue}
                                onInputChange={(e, v, r) => r !== 'reset' && setInputValue(v)}
                                options={placeholders ?? []}
                                getOptionLabel={o => o.friendlyName}
                                isOptionEqualToValue={ (o, v) => o.placeholder === v.placeholder }
                                onChange={ onPlacementSelectedCallback }
                                renderInput={ params => <TextField {...params} label="Placeholders" inputProps={{ ...params.inputProps, "data-lpignore": "true" }} /> }
                                renderOption={(props, option) => (
                                    <ListItem {...props}><ListItemText primary={option.friendlyName} secondary={option.placeholder} /></ListItem>
                                )}
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={() => setShowPlaceholders(false)}>Close</Button>
                    </DialogActions>
                </Dialog>
            }
            {rerenderControl && editor}
            {!rerenderControl && editor}
        </>
    );
};