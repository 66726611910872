import { SurveyMonkeySurvey } from "common/models/Integrations/SurveyMonkey";
import { CampaignMonitorClientListData } from "../common/models/CampaignMonitor";
import { RecordExportData } from "../common/models/Common";
import { CustomerIntegration } from "../common/models/Configuration/CustomerIntegrations";
import { ErrorHandler, NameIdObj, RequestOptions, StringIdStringValueWrapper, StringValueWrapper } from "../common/models/GenericTypes";
import { Delete, Get, Post } from "../util/HttpUtils";
import { WorkProPackage } from "common/models/Integrations/WorkPro";

export async function GetAllIntegrations(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<CustomerIntegration[]>('api/Integrations/All', options);
}

export async function GetIntegration(id: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<CustomerIntegration>(`api/Integrations/${id}`, options);
}

export async function Connect_OutsourcingWizard(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>('api/Integrations/OutsourcingWizard', options);
}

export async function Disconnect_OutsourcingWizard(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Delete<boolean>('api/Integrations/OutsourcingWizard', options);
}

export async function GetRedirectUrl_Microsoft365(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<StringValueWrapper>('api/Integrations/Microsoft365', options);
}

export async function Connect_Microsoft365(tenant: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>(`api/Integrations/Microsoft365`, options, { value: tenant });
}

export async function Disconnect_Microsoft365(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Delete<boolean>('api/Integrations/Microsoft365', options);
}

export async function GetRedirectUrl_Aircall(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<StringValueWrapper>('api/Integrations/Aircall', options);
}

export async function Connect_Aircall(code: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>(`api/Integrations/Aircall`, options, { value: code });
}

export async function Disconnect_Aircall(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Delete<boolean>('api/Integrations/Aircall', options);
}

export async function Connect_ActiveCampaign(subdomain: string, apiKey: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>(`api/Integrations/ActiveCampaign`, options, { subdomain: subdomain, apiKey: apiKey });
}

export async function Disconnect_ActiveCampaign(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Delete<boolean>(`api/Integrations/ActiveCampaign`, options);
}

export async function GetRedirectUrl_CampaignMonitor(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<StringValueWrapper>('api/Integrations/CampaignMonitor', options);
}

export async function Connect_CampaignMonitor(code: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>(`api/Integrations/CampaignMonitor`, options, { value: code });
}

export async function Disconnect_CampaignMonitor(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Delete<boolean>('api/Integrations/CampaignMonitor', options);
}

export async function GetLists_CampaignMonitor(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<CampaignMonitorClientListData>('api/Integrations/CampaignMonitor/Lists', options);
}

export async function ExportRecords_CampaignMonitor(clientId: string, listId: string, records: Partial<RecordExportData>[], newListName: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>(`api/Integrations/CampaignMonitor/Clients/${clientId}/Lists/${listId}?newListName=${encodeURIComponent(newListName)}`, options, records);
}

export async function GetRedirectUrl_Mailchimp(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<StringValueWrapper>('api/Integrations/Mailchimp', options);
}

export async function Connect_Mailchimp(code: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>(`api/Integrations/Mailchimp`, options, { value: code });
}

export async function Disconnect_Mailchimp(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Delete<boolean>('api/Integrations/Mailchimp', options);
}

export async function GetLists_Mailchimp(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<StringIdStringValueWrapper[]>('api/Integrations/Mailchimp/Lists', options);
}

export async function ExportRecords_Mailchimp(listId: string, records: Partial<RecordExportData>[], newListName: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>(`api/Integrations/Mailchimp/${listId}?newListName=${encodeURIComponent(newListName)}`, options, records);
}

export async function GetRedirectUrl_FoundU(subdomain: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<StringValueWrapper>(`api/Integrations/FoundU/${encodeURIComponent(subdomain)}`, options);
}

export async function Connect_FoundU(code: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>(`api/Integrations/FoundU`, options, { value: code });
}

export async function Disconnect_FoundU(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Delete<boolean>('api/Integrations/FoundU', options);
}

export async function Connect_Refari(apiKey: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>(`api/Integrations/Refari`, options, { value: apiKey });
}

export async function Disconnect_Refari(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Delete<boolean>('api/Integrations/Refari', options);
}

export async function GetRedirectUrl_Referoo(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<StringValueWrapper>('api/Integrations/Referoo', options);
}

export async function Connect_Referoo(code: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>(`api/Integrations/Referoo`, options, { value: code });
}

export async function Disconnect_Referoo(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Delete<boolean>('api/Integrations/Referoo', options);
}

export async function GetQuestionnaires_Referoo(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<NameIdObj[]>('api/Integrations/Referoo/Questionnaires', options);
}

export async function GetWebHookUrl_AskNicely(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<StringValueWrapper>('api/Integrations/AskNicely', options);
}

export async function Connect_AskNicely(subdomain: string, apiKey: string, minuteDelay: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>(`api/Integrations/AskNicely`, options, { subdomain: subdomain, apiKey: apiKey, sendDelay: minuteDelay });
}

export async function Disconnect_AskNicely(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Delete<boolean>('api/Integrations/AskNicely', options);
}

export async function GetRedirectUrl_Deputy(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<StringValueWrapper>('api/Integrations/Deputy', options);
}

export async function Connect_Deputy(code: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>(`api/Integrations/Deputy`, options, { value: code });
}

export async function Disconnect_Deputy(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Delete<boolean>('api/Integrations/Deputy', options);
}

export async function GetRedirectUrl_SurveyMonkey(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<StringValueWrapper>('api/Integrations/SurveyMonkey', options);
}

export async function GetSurveys_SurveyMonkey(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<SurveyMonkeySurvey[]>('api/Integrations/SurveyMonkey/Surveys', options);
}

export async function Connect_SurveyMonkey(code: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>(`api/Integrations/SurveyMonkey`, options, { value: code });
}

export async function Disconnect_SurveyMonkey(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Delete<boolean>('api/Integrations/SurveyMonkey', options);
}

export async function Connect_WorkPro(subscriptionKey: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>(`api/Integrations/WorkPro`, options, { value: subscriptionKey });
}

export async function Disconnect_WorkPro(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Delete<boolean>('api/Integrations/WorkPro', options);
}

export async function GetWorkProSitePackages(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<WorkProPackage[]>('api/Integrations/WorkPro/Sites/Packages', options);
}

export async function WorkProSendPackageToCandidate(candidateId: number, packageId: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>(`api/Integrations/WorkPro/Packages/Send/${candidateId}`, options, { value: packageId });
}