import React, { useCallback, useEffect, useMemo, useState } from "react";
import { GridColDef, GridColumnVisibilityModel, GridRenderCellParams, useGridApiRef } from "@mui/x-data-grid-premium";
import { PreviewEntityType } from "common/models/Previews/Previews";
import PreviewLoaderComponent from "../Previews/PreviewLoader";
import { Link } from "react-router-dom";
import GridWithStateWrapper from "../GridWidthStateWrapper";
import { GetInterviewAttendees } from "services/InterviewsService";
import { InterviewAttendee } from "common/models/Interviews";

interface Props {
    interviewId: number,
    gridName: string,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
    successHandler?: (message: string) => void
}

const linkStyle: React.CSSProperties = { color: 'inherit', textDecoration: 'underline' };

const defaultHiddenCols: GridColumnVisibilityModel = {
    'id': false,
};

export default function InterviewAttendeesGridComponent({ interviewId, gridName, loadingHandler, errorHandler, successHandler }: Props) {
    const [rows, setRows] = useState<InterviewAttendee[]>([]);
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    const [previewType, setPreviewType] = useState<PreviewEntityType | ''>('');
    const [previewRecordId, setPreviewRecordId] = useState(0);
    const [isPreviewTags, setIsPreviewTags] = useState(false);
    const [previewRecordName, setPreviewRecordName] = useState('');
    const apiRef = useGridApiRef();

    const getRowsCallback = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        const res = await GetInterviewAttendees(interviewId, errorHandler);
        if (res) setRows(res);
        loadingHandler && loadingHandler(false);
    }, [interviewId, loadingHandler, errorHandler]);

    useEffect(() => {
        getRowsCallback();
    }, [getRowsCallback]);

    const columns = useMemo<GridColDef[]>(() => {
        const handlePreviewHover = (type: PreviewEntityType | '', id: number, isTags: boolean = false, recordName: string = '') => {
            setPreviewType(type);
            setPreviewRecordId(id);
            setIsPreviewTags(isTags);
            setPreviewRecordName(recordName);
            setIsPreviewOpen(true);
        };

        const handlePreviewClose = () => {
            setIsPreviewOpen(false);
        };

        const linkToAttendeeRenderer = (params: GridRenderCellParams) => {
            if (params.row.type === 'Contact' && params.row.attendeeID) return <Link to={`/contacts/${params.row.attendeeID}`} style={ linkStyle } onMouseEnter={ () => handlePreviewHover('contact', params.row.attendeeID) } onMouseLeave={ handlePreviewClose } >{params.value}</Link>;
            else if (params.row.type === 'Candidate' && params.row.attendeeID) return <Link to={`/candidates/${params.row.attendeeID}`} style={ linkStyle } onMouseEnter={ () => handlePreviewHover('candidate', params.row.attendeeID) } onMouseLeave={ handlePreviewClose } >{params.value}</Link>;
            return params.value;
        }
        
        return [
            { headerName: 'ID', field: 'id', width: 75 },
            { headerName: 'Name', field: 'name', width: 300, renderCell: linkToAttendeeRenderer },
            { headerName: 'Type', field: 'type', cellsalign: 'center', align: 'center', headerAlign: 'center', width: 150 },
            { headerName: 'Status', field: 'statusName', cellsalign: 'center', align: 'center', headerAlign: 'center', width: 150 }
        ];
    }, []);

    return (
        <>
            <PreviewLoaderComponent
                open={isPreviewOpen}
                entityType={previewType}
                recordId={previewRecordId}
                isTagsPreview={isPreviewTags}
                titleOverride={previewRecordName}
            />
            <GridWithStateWrapper
                gridName={gridName}
                defaultViewModel={defaultHiddenCols}
                rows={rows}
                columns={columns}
                apiRef={apiRef}
                density="compact"
                disableRowSelectionOnClick
            />
        </>
    );
}