import IconButton from "@mui/material/IconButton";
import { GridCellEditStopParams, GridCellEditStopReasons, GridColDef, GridColumnVisibilityModel, GridRenderCellParams, GridRenderEditCellParams, GridRowSelectionModel, GridValueFormatter, GridValueGetter, useGridApiRef } from "@mui/x-data-grid-premium";
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Opportunity } from "common/models/Opportunities";
import { GetMyOpportunities, GetMyOpenOpportunities, GetOpportunitiesByTags, OpportunityLookup, SearchOpportunities, UpdateOpportunityCustomField } from "services/OpportunitiesService";
import GridWithStateWrapper from "../GridWidthStateWrapper";
import PreviewLoaderComponent from "../Previews/PreviewLoader";
import { GetDivisions } from "services/DivisionsService";
import { Division } from "common/models/Configuration/Division";
import Chip from "@mui/material/Chip";
import ActionsDropDownButton from "../SummaryBars/Actions/ActionsDropsDownMenu";
import MenuItem from "@mui/material/MenuItem";
import { PreviewEntityType } from "common/models/Previews/Previews";
import { GetClientOpportunities } from "services/ClientsService";
import { GetContactOpportunities } from "services/ContactsService";
import TagsManagementDialog from "../Dialogs/TagsManagementDialog";
import { companyHasSinglePermission, userHasSinglePermission } from "util/PermissionsUtils";
import { Permission } from "common/models/Permissions";
import { OpportunitySearch } from "common/models/Search/OpportunitySearch";
import Box from "@mui/material/Box";
import { CustomField, CustomFieldPredefinedValue } from "common/models/Configuration/CustomFields";
import { GetCustomFieldsByEntity_OnlyActive, GetPredefinedValues } from "services/CustomFieldsService";
import { customFieldColsVisibility, mandatoryUdfEditValidator, numberUdfEditValidator } from "util/GridUtils";
import { DatePicker } from "@mui/x-date-pickers-pro";
import useAllUsersMap from "hooks/UseAllUsersMap";

interface Props {
    source: 'dashboard' | 'client-record' | 'contact-record' | 'tags' | 'my-opportunities' | 'search' | 'lookup',
    sourceId?: number,
    gridName: string,
    tagLabel?: string,
    tagTypeId?: number,
    taggedBy?: number,
    lookupTerm?: string,
    searchData?: OpportunitySearch,
    setRowCountHandler?: (count: number) => void,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
    successHandler?: (message: string) => void
}

interface CustomFieldWithPredefinedValues extends CustomField {
    values?: CustomFieldPredefinedValue[],
}

const linkStyle: React.CSSProperties = { color: 'inherit', textDecoration: 'underline' };
const entityTypeId = 8;

const getStageNameById = (stageId: number) => {
    if (stageId === 1) return 'Lead';
    if (stageId === 2) return 'Opportunity';
    if (stageId === 3) return 'Converted';
    return '';
}

const defaultHiddenCols: GridColumnVisibilityModel = {
    "id": false,
    "tags": false,
    "division": false,
    ...customFieldColsVisibility
};

export default function OpportunitiesGridComponent({ source, sourceId, tagLabel = '', tagTypeId = 0, taggedBy = 0, lookupTerm = '', searchData, setRowCountHandler, loadingHandler, errorHandler, successHandler, gridName }: Props) {
    const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
    const [rows, setRows] = useState<Opportunity[]>([]);
    const [divisions, setDivisions] = useState<Division[]>([]);
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    const [showTagsPrevewNoDelay, setShowTagsPreviewNoDelay] = useState(false);
    const [previewType, setPreviewType] = useState<PreviewEntityType | ''>('');
    const [previewRecordId, setPreviewRecordId] = useState(0);
    const [isPreviewTags, setIsPreviewTags] = useState(false);
    const [previewRecordName, setPreviewRecordName] = useState('');
    const [taggingRecords, setTaggingRecords] = useState<number[]>([]);
    const [activeFields, setActiveFields] = useState<CustomFieldWithPredefinedValues[]>([]);
    const [editCellFieldKey, setEditCellFieldKey] = useState<keyof Opportunity | null>(null);
    const allUsersMap = useAllUsersMap();
    const apiRef = useGridApiRef();

    const canUserAddEditOpportunities = useMemo(() => companyHasSinglePermission(Permission.OpportunitiesAddEdit) && userHasSinglePermission(Permission.OpportunitiesAddEdit), []);

    useEffect(() => {
        const getRows = async () => {
            loadingHandler && loadingHandler(true);
            let res: Opportunity[] | null = [];
            if (source === 'dashboard') res = await GetMyOpenOpportunities(errorHandler);
            if (source === 'client-record' && sourceId) res = await GetClientOpportunities(sourceId, errorHandler);
            if (source === 'contact-record' && sourceId) res = await GetContactOpportunities(sourceId, errorHandler);
            if (source === 'tags') res = await GetOpportunitiesByTags(tagTypeId, taggedBy, tagLabel, errorHandler);
            if (source === 'my-opportunities') res = await GetMyOpportunities(errorHandler);
            if (source === 'search' && searchData) res = await SearchOpportunities(searchData, errorHandler);
            if (source === 'lookup' && lookupTerm) res = await OpportunityLookup(lookupTerm, 0, errorHandler);
            if (res) {
                setRowCountHandler && setRowCountHandler(res.length)
                setRows(res);
            }
            const divs = await GetDivisions();
            if (divs) setDivisions(divs);
            loadingHandler && loadingHandler(false);
        };
        getRows();
    }, [source, sourceId, tagLabel, tagTypeId, taggedBy, lookupTerm, searchData, setRowCountHandler, loadingHandler, errorHandler]);

    useEffect(() => {
        const getActiveFields = async () => {
            const customFields = await GetCustomFieldsByEntity_OnlyActive(entityTypeId);
            if (customFields) {
                let udfDefinitions: CustomFieldWithPredefinedValues[] = [];
                for (let i = 0; i < customFields.length; i++) {
                    const u = customFields[i];
                    if (u.usePredefinedValues) {
                        const vals = await GetPredefinedValues(u.id);
                        if (vals) udfDefinitions.push({ ...u, values: vals });
                        else udfDefinitions.push(u);
                        continue;
                    }

                    udfDefinitions.push(u);
                }
                setActiveFields(udfDefinitions);
            }
        };
        getActiveFields();
    }, []);

    const columns = useMemo<GridColDef[]>(() => {
        const dateValueGetter: GridValueGetter<Opportunity, any, undefined, string> = (value) => {
            if (value) {
                const m = moment(value);
                if (m.isValid() && m.get('year') > 1) {
                    return m.toDate();
                }
            }
        };

        const dateRenderer = (params: GridRenderCellParams) => {
            if (params.value) {
                return moment(params.value).format('DD MMM YYYY');
            }
            if (params.field === 'lastActivityDate') return 'Never';
        };

        const dateEditorRenderer = (params: GridRenderEditCellParams) => {
            const { id, value, field } = params;
            const dateUdfChangeHandler = (date: moment.Moment | null) => {
                if (date) {
                    if (date.isValid()) {
                        const formatted = date.format('YYYY-MM-DD');
                        apiRef.current.setEditCellValue({ id, field, value: formatted });
                    }
                }
                else apiRef.current.setEditCellValue({ id, field, value: null });
            };

            let v: moment.Moment | null = null;
            if (value) {
                const m = moment(value);
                if (m.isValid()) v = m;
            }

            return <DatePicker value={v} onChange={dateUdfChangeHandler} slotProps={{actionBar: { actions: ["clear", "today", "cancel", "accept"] }}} />;
        };

        const typeValueGetter: GridValueGetter<Opportunity, any, undefined, number> = (value) => {
            switch (value)
            {
                case 1: return "Back Fill";
                case 2: return "Tender";
                case 3: return "Float";
                case 4: return "Bid";
                case 5: return "General";
                case 6: return "Lead";
                default: return "";
            }
        };

        const statusValueGetter: GridValueGetter<Opportunity, any, undefined, number> = (value) => {
            switch (value)
            {
                case 1: return "Open";
                case 2: return "Closed";
                default: return "";
            }
        };

        const divisionsValueGetter: GridValueGetter<Opportunity, any, undefined, string> = (value) => {
            if (value) {
                const divs = value.split(';');
                let divNamesList: string[] = [];
                for (let i = 0; i < divs.length; i++) {
                    const div = divs[i];
                    if (div) {
                        const index = divisions.findIndex(d => d.id === +div);
                        if (index !== -1) divNamesList.push(divisions[index].name);
                    }

                }
                return divNamesList.join(';');
            }
        };

        const ownersValueGetter: GridValueGetter<Opportunity, any, undefined, string> = (value) => {
            if (value) {
                const owners = value.split(';');
                let ownerNamesList: string[] = [];
                for (let i = 0; i < owners.length; i++) {
                    const o = owners[i];
                    if (o) {
                        const owner = allUsersMap[+o];
                        if (owner) ownerNamesList.push(owner);
                    }

                }
                return ownerNamesList.sort().join(';');
            }
        };

        const stringNamesRenderer = (params: GridRenderCellParams) => {
            if (params.value) {
                const names = (params.value as string).split(';');
                return names.map((d, i) => <Chip key={i} size="small" label={d} />);
            }
        }

        const linkToOpportunityRenderer = (params: GridRenderCellParams) => {
            if (params.id) {
                return <Link 
                    to={`/opportunities/${params.id}`} 
                    style={ linkStyle } 
                    onMouseEnter={ () => handlePreviewHover('opportunity', params.row.id) } 
                    onMouseLeave={ handlePreviewClose }
                    target={ source === "search" ? "_blank" : "_self" }
                >{params.value}</Link>;
            }
            return params.value;
        };

        const linkToClientRenderer = (params: GridRenderCellParams) => {
            if (params.row.clientID) {
                return <Link to={`/clients/${params.row.clientID}`} style={ linkStyle } onMouseEnter={ () => handlePreviewHover('client', params.row.clientID) } onMouseLeave={ handlePreviewClose } >{params.row.clientName}</Link>;
            }
            return params.value;
        };

        const linkToContactRenderer = (params: GridRenderCellParams) => {
            if (params.row.contactID) {
                return <Link to={`/contacts/${params.row.contactID}`} style={ linkStyle } onMouseEnter={ () => handlePreviewHover('contact', params.row.contactID) } onMouseLeave={ handlePreviewClose } >{params.value}</Link>;
            }
            return params.value;
        }

        const handlePreviewHover = (type: PreviewEntityType | '', id: number, isTags: boolean = false, recordName: string = '', noDelay: boolean = false) => {
            setPreviewType(type);
            setPreviewRecordId(id);
            setIsPreviewTags(isTags);
            setPreviewRecordName(recordName);
            setIsPreviewOpen(true);
            setShowTagsPreviewNoDelay(noDelay);
        };

        const handlePreviewClose = () => {
            setIsPreviewOpen(false);
            setShowTagsPreviewNoDelay(false);
        };

        const tagsRenderer = (params: GridRenderCellParams) => {
            if (canUserAddEditOpportunities) {
                return (
                    <IconButton
                        size="small"
                        onMouseEnter={ () => handlePreviewHover('opportunity', params.row.id, true, params.row.name) }
                        onMouseLeave={ handlePreviewClose }
                        onClick={ () => setTaggingRecords([params.row.id]) }
                    >
                        {params.value
                            ? <LocalOfferIcon style={{ color: '#f209a6' }} />
                            : <LocalOfferOutlinedIcon />
                        }
                    </IconButton>
                );
            }
            return (
                <IconButton
                    size="small"
                    onMouseEnter={() => handlePreviewHover('opportunity', params.row.id, true, params.row.name)}
                    onMouseLeave={handlePreviewClose}
                    onClick={() => handlePreviewHover('opportunity', params.row.id, true, params.row.name, true)}
                >
                    {params.value
                        ? <LocalOfferIcon />
                        : <LocalOfferOutlinedIcon />
                    }
                </IconButton>
            );
        };

        const numberValueFormatter: GridValueFormatter<Opportunity, any, string | undefined, number> = (value) => {
            if (typeof value === 'number') return value.toLocaleString();
        };

        const stageValueGetter: GridValueGetter<Opportunity, any, undefined, number> = (value) => {
            if (typeof value === 'number') return getStageNameById(value);
        };

        let cols: GridColDef[] = [
            { field: 'id', headerName: 'ID', renderCell: linkToOpportunityRenderer, width: 100 },
            { field: 'name', headerName: 'Name', renderCell: linkToOpportunityRenderer, width: 200 },
            { field: 'startDate', headerName: 'Date', type: 'date', align: 'center', headerAlign: 'center', valueGetter: dateValueGetter, renderCell: dateRenderer, width: 150 },
            { field: 'tags', headerName: 'Tags', align: 'center', headerAlign: 'center', renderCell: tagsRenderer, width: 70 },
            { field: 'type', headerName: 'Type', align: 'center', headerAlign: 'center', valueGetter: typeValueGetter, width: 150 },
            { field: 'statusID', headerName: 'Status', align: 'center', headerAlign: 'center', valueGetter: statusValueGetter, width: 150 },
            { field: 'clientName', headerName: 'Client', renderCell: linkToClientRenderer, width: 200 },
            { field: 'contactName', headerName: 'Contact', renderCell: linkToContactRenderer, width: 200 },
            { field: 'lastActivityDate', headerName: 'Last Activity', align: 'center', headerAlign: 'center', valueGetter: dateValueGetter, renderCell: dateRenderer, width: 150 },
            { field: 'divisions', headerName: 'Divisions', valueGetter: divisionsValueGetter, renderCell: stringNamesRenderer, width: 150 },
            { field: 'owners', headerName: 'Owners', valueGetter: ownersValueGetter, renderCell: stringNamesRenderer, width: 300 },
            { field: 'stageID', headerName: 'Stage', valueGetter: stageValueGetter, width: 150 },
            { field: 'value', headerName: 'Value', type: 'number', valueFormatter: numberValueFormatter , width: 150 },
            { field: 'probability', headerName: 'Probability (%)', type: 'number', valueFormatter: numberValueFormatter , width: 150 },
            { field: 'deadline', headerName: 'Deadline', valueGetter: dateValueGetter, renderCell: dateRenderer, width: 150 },
        ];

        if (activeFields && activeFields.length > 0) {
            for (let i = 0; i < activeFields.length; i++) {
                const udf = activeFields[i];
                const udfNumber = udf.name.slice(13);
                const isString = udf.dataType === 'String';
                const isDecimal = udf.dataType === 'Decimal';
                const isDate = udf.dataType === 'DateTime';
                const isEditable = udf.editableViaGrids;
                const isDropdown = udf.usePredefinedValues;
                const isMandatory = udf.mandatory;

                if (isEditable && isDecimal) {
                    cols.push({
                        field: `customField${udfNumber}`,
                        headerName: udf.agencyName,
                        headerAlign: 'center',
                        align: 'right',
                        editable: true,
                        preProcessEditCellProps: numberUdfEditValidator,
                    });
                }
                else if (isDate) {
                    cols.push({
                        field: `customField${udfNumber}`,
                        headerName: udf.agencyName,
                        headerAlign: 'center',
                        editable: isEditable,
                        valueGetter: dateValueGetter,
                        renderCell: dateRenderer,
                        renderEditCell: isEditable ? dateEditorRenderer : undefined,
                        preProcessEditCellProps: isMandatory ? mandatoryUdfEditValidator : undefined
                    });
                }
                else if (isString && isDropdown && udf.values && udf.values.length > 0) {
                    cols.push({
                        field: `customField${udfNumber}`,
                        headerName: udf.agencyName,
                        headerAlign: 'center',
                        editable: isEditable,
                        valueOptions: udf.values.map(v => v.value),
                        type: 'singleSelect',
                    });
                }
                else if (isEditable && isString) {
                    cols.push({
                        field: `customField${udfNumber}`,
                        headerName: udf.agencyName,
                        headerAlign: 'center',
                        align: 'right',
                        editable: true,
                        preProcessEditCellProps: isMandatory ? mandatoryUdfEditValidator : undefined
                    });
                }
                else {
                    cols.push({ field: `customField${udfNumber}`, headerName: udf.agencyName, headerAlign: 'center', align: isDecimal ? 'right' : undefined });
                }
            }
        }

        return cols;
    }, [activeFields, apiRef, divisions, allUsersMap, source, canUserAddEditOpportunities]);

    const exportToExcel = useCallback(() => {
        const api = apiRef.current;
        if (api) api.exportDataAsExcel();
    }, [apiRef]);

    const canExport = useMemo(() => companyHasSinglePermission(Permission.OpportunitiesExport) && userHasSinglePermission(Permission.OpportunitiesExport), []);

    const gridActions = useMemo(() => {
        if (canExport || canUserAddEditOpportunities) {
            return (
                <ActionsDropDownButton color="secondary" label="List Actions">
                    { canUserAddEditOpportunities && <MenuItem disabled={selectionModel.length === 0} onClick={() => setTaggingRecords(selectionModel as number[])}>Bulk Tag</MenuItem> }
                    { canExport && <MenuItem onClick={exportToExcel}>Export</MenuItem> }
                </ActionsDropDownButton>
            );
        }
        return undefined;
    }, [selectionModel, canExport, canUserAddEditOpportunities, exportToExcel]);

    const tagManagementSuccessHandler = useCallback((message: string, recordIds: number[], finalTagCount: number) => {
        successHandler && successHandler(message);
        const api = apiRef.current;
        if (api) {
            const hasTags = finalTagCount > 0;
            api.updateRows(recordIds.map(id => ({ id: id, tags: hasTags })));
        }
    }, [apiRef, successHandler]);

    const cellEditHandler = useCallback((params: GridCellEditStopParams) => {
        if (params.reason === GridCellEditStopReasons.escapeKeyDown) return ;
        setEditCellFieldKey(params.field as keyof Opportunity);
    }, []);

    const processRowUpdate = useCallback(async (newRow: Opportunity) => {
        if (editCellFieldKey && editCellFieldKey.startsWith('customField')) {
            const value = newRow[editCellFieldKey] as string;
            const n = +editCellFieldKey.slice(11);
            loadingHandler && loadingHandler(true);
            if ((n >= 16 && n <= 20) || (n >= 36 && n <= 40) || (n >= 56 && n <= 60)) { // IS DATE
                await UpdateOpportunityCustomField(+newRow.id, n, value ?? '0001-01-01', errorHandler);
            }
            else if (n >= 1 && n <= 60) await UpdateOpportunityCustomField(+newRow.id, n, value, errorHandler);
            loadingHandler && loadingHandler(false);
        }
        setEditCellFieldKey(null);
        return newRow;
    }, [editCellFieldKey, errorHandler, loadingHandler]);

    return (
        <>
            <PreviewLoaderComponent
                open={isPreviewOpen}
                entityType={previewType}
                recordId={previewRecordId}
                isTagsPreview={isPreviewTags}
                showDelayMs={showTagsPrevewNoDelay ? 0 : undefined}
                titleOverride={previewRecordName}
            />
            <TagsManagementDialog
                open={ taggingRecords.length > 0 }
                closeHandler={ () => setTaggingRecords([]) }
                entityId={ entityTypeId }
                recordIds={ taggingRecords }
                loadingHandler={ loadingHandler }
                errorHandler={ errorHandler }
                successHandler={ tagManagementSuccessHandler }
            />
            {Boolean(gridActions) &&
                <Box pb="10px" ml="auto">
                    {gridActions}
                </Box>
            }
            <GridWithStateWrapper
                rows={rows}
                columns={columns}
                apiRef={apiRef}
                density="compact"
                checkboxSelection
                disableRowSelectionOnClick
                rowSelectionModel={selectionModel}
                onRowSelectionModelChange={ sm => setSelectionModel(sm) }
                pagination
                onCellEditStop={ cellEditHandler }
                processRowUpdate={ processRowUpdate }
                pageSizeOptions={[100,250,500,1000]}
                gridName={gridName}
                defaultViewModel={defaultHiddenCols}
            />
        </>
    );
}
