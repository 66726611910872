import Box from "@mui/material/Box";
import { NameIdObj } from "common/models/GenericTypes";
import { RefariCategory } from "common/models/JobPosting/Refari";
import React, { useEffect, useMemo, useState } from "react";
import { GetRefariBoardSetupData } from "services/JobBoardsService";
import JobBoardViewField from "./JobBoardViewField";

interface Props {
    jobBoardId: number,
    advertId: number,
    attributes?: Record<string, string>,
    advertRecordAttributes?: Record<string, string>,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
}

export default function RefariView({ jobBoardId, advertId, attributes, advertRecordAttributes, loadingHandler, errorHandler }: Props) {
    const [categories, setCategories] = useState<RefariCategory[]>([]);
    const [categoryIdToIndex, setCategoryIdToIndex] = useState<Record<string, number>>({});
    const [locations, setLocations] = useState<NameIdObj[]>([]);
    const [workTypes, setWorkTypes] = useState<NameIdObj[]>([]);

    useEffect(() => {
        const getSetupData = async () => {
            loadingHandler && loadingHandler(true);
            const res = await GetRefariBoardSetupData(jobBoardId, advertId, errorHandler);
            if (res) {
                if (res.categories && res.categories.length > 0) {
                    setCategories(res.categories);
                    let idToIndexMap: Record<string, number> = {};
                    for (let i = 0; i < res.categories.length; i++) {
                        const cat = res.categories[i];
                        idToIndexMap[cat.id.toString()] = i;
                    }
                    setCategoryIdToIndex(idToIndexMap);
                }
                if (res.locations && res.locations.length > 0) setLocations(res.locations);
                if (res.workTypes && res.workTypes.length > 0) setWorkTypes(res.workTypes);
            }
            loadingHandler && loadingHandler(false);
        };
        getSetupData();
    }, [advertId, errorHandler, jobBoardId, loadingHandler]);

    const atts = useMemo<Record<string, string>>(() => {
        if (attributes) return attributes;
        return {};
    }, [attributes]);

    const recordAtts = useMemo<Record<string, string>>(() => {
        if (advertRecordAttributes) return advertRecordAttributes;
        return {};
    }, [advertRecordAttributes]);

    const category = useMemo(() => atts.category ?? '', [atts.category]);
    const subcategory = useMemo(() => atts.subcategory ?? '', [atts.subcategory]);
    const location = useMemo(() => atts.location ?? '', [atts.location]);
    const location2 = useMemo(() => atts.location2 ?? '', [atts.location2]);
    const location3 = useMemo(() => atts.location3 ?? '', [atts.location3]);
    const location4 = useMemo(() => atts.location4 ?? '', [atts.location4]);
    const location5 = useMemo(() => atts.location5 ?? '', [atts.location5]);
    const location6 = useMemo(() => atts.location6 ?? '', [atts.location6]);
    const location7 = useMemo(() => atts.location7 ?? '', [atts.location7]);
    const location8 = useMemo(() => atts.location8 ?? '', [atts.location8]);
    const location9 = useMemo(() => atts.location9 ?? '', [atts.location9]);
    const location10 = useMemo(() => atts.location10 ?? '', [atts.location10]);
    const referralRewardFee = useMemo(() => atts.referralRewardFee ?? '', [atts.referralRewardFee]);
    const workType = useMemo(() => atts.worktype ?? '', [atts.worktype]);

    const salaryType = useMemo(() => recordAtts.salaryType ?? '', [recordAtts.salaryType]);
    const salaryFrom = useMemo(() => recordAtts.salaryFrom ?? '', [recordAtts.salaryFrom]);
    const salaryTo = useMemo(() => recordAtts.salaryTo ?? '', [recordAtts.salaryTo]);

    const categoryName = useMemo(() => {
        if (category && categories && categories.length > 0) {
            const c = categories.find(v => v.id.toString() === category);
            if (c) return c.name;
        }
        return '';
    }, [categories, category]);

    const subCategoryName = useMemo(() => {
        if (category) {
            const index = categoryIdToIndex[category];
            const opts = categories[index] ? categories[index].children : null;
            if (opts) {
                const sc = opts.find(v => v.id.toString() === subcategory);
                if (sc) return sc.name;
            }
        }
        return "";
    }, [categories, category, categoryIdToIndex, subcategory]);

    const location1Name = useMemo(() => {
        if (locations && locations.length > 0) {
            const l = locations.find(v => v.id.toString() === location);
            if (l) return l.name;
        }
        return '';
    }, [location, locations]);

    const location2Name = useMemo(() => {
        if (locations && locations.length > 0) {
            const l = locations.find(v => v.id.toString() === location2);
            if (l) return l.name;
        }
        return '';
    }, [location2, locations]);

    const location3Name = useMemo(() => {
        if (locations && locations.length > 0) {
            const l = locations.find(v => v.id.toString() === location3);
            if (l) return l.name;
        }
        return '';
    }, [location3, locations]);

    const location4Name = useMemo(() => {
        if (locations && locations.length > 0) {
            const l = locations.find(v => v.id.toString() === location4);
            if (l) return l.name;
        }
        return '';
    }, [location4, locations]);

    const location5Name = useMemo(() => {
        if (locations && locations.length > 0) {
            const l = locations.find(v => v.id.toString() === location5);
            if (l) return l.name;
        }
        return '';
    }, [location5, locations]);

    const location6Name = useMemo(() => {
        if (locations && locations.length > 0) {
            const l = locations.find(v => v.id.toString() === location6);
            if (l) return l.name;
        }
        return '';
    }, [location6, locations]);

    const location7Name = useMemo(() => {
        if (locations && locations.length > 0) {
            const l = locations.find(v => v.id.toString() === location7);
            if (l) return l.name;
        }
        return '';
    }, [location7, locations]);

    const location8Name = useMemo(() => {
        if (locations && locations.length > 0) {
            const l = locations.find(v => v.id.toString() === location8);
            if (l) return l.name;
        }
        return '';
    }, [location8, locations]);

    const location9Name = useMemo(() => {
        if (locations && locations.length > 0) {
            const l = locations.find(v => v.id.toString() === location9);
            if (l) return l.name;
        }
        return '';
    }, [location9, locations]);

    const location10Name = useMemo(() => {
        if (locations && locations.length > 0) {
            const l = locations.find(v => v.id.toString() === location10);
            if (l) return l.name;
        }
        return '';
    }, [location10, locations]);

    const workTypeName = useMemo(() => {
        if (workType && workTypes && workTypes.length > 0) {
            const w = workTypes.find(v => v.id.toString() === workType);
            if (w) return w.name;
        }
        return '';
    }, [workType, workTypes]);

    return (
        <Box display="flex" gap={2} pt={2}>
            <Box display="flex" flexDirection="column" gap={2} width="50%">
                <JobBoardViewField label="Category" value={categoryName} />
                <JobBoardViewField label="Sub Category" value={subCategoryName} />
                <JobBoardViewField label="Location 1" value={location1Name} />
                <JobBoardViewField label="Location 2" value={location2Name} />
                <JobBoardViewField label="Location 3" value={location3Name} />
                <JobBoardViewField label="Location 4" value={location4Name} />
                <JobBoardViewField label="Location 5" value={location5Name} />
                <JobBoardViewField label="Location 6" value={location6Name} />
                <JobBoardViewField label="Location 7" value={location7Name} />
                <JobBoardViewField label="Location 8" value={location8Name} />
                <JobBoardViewField label="Location 9" value={location9Name} />
                <JobBoardViewField label="Location 10" value={location10Name} />
            </Box>
            <Box display="flex" flexDirection="column" gap={2} width="50%">
                <JobBoardViewField label="Work Type" value={workTypeName} />
                <JobBoardViewField label="Pay Type" value={salaryType} />
                <Box display="flex" gap={2}>
                    <JobBoardViewField label="Salary From" sx={{ flex: '1 1 0' }} value={salaryFrom} />
                    <JobBoardViewField label="Salary To" sx={{ flex: '1 1 0' }} value={salaryTo} />
                </Box>
                <JobBoardViewField label="Referral Reward Fee" value={referralRewardFee} />
            </Box>
        </Box>
    );
}