import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Editor } from "@tinymce/tinymce-react/lib/cjs/main/ts/components/Editor";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import * as tinymce from 'tinymce';
import { Client } from "common/models/Clients";
import { CreateClient, SetClientOwners } from "services/ClientsService";
import { GetMyUser } from "services/UsersService";
import UserPicker from "components/Pickers/UserPicker";
import RWTextFieldComponent from "components/RWTextFieldComponent";

interface Props {
    open: boolean,
    name?: string,
    onClientCreated: (c: Client) => void,
    closeHandler: () => void,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
    successHandler?: (message: string) => void
}

export default function QuickCreateClientDialog({ open, name: nameProp = '', onClientCreated, closeHandler, loadingHandler, errorHandler, successHandler }: Props) {
    const [showValidation, setShowValidation] = useState(false);
    const [isComponentMounted, setIsComponentMounted] = useState(false);
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [website, setWebsite] = useState('');
    const [ownerId, setOwnerId] = useState<number | null>(null);
    const [defaultOwnerId, setDefaultOwnerId] = useState<number | null>(null);
    const [statusId, setStatusId] = useState(1);
    const [rating, setRating] = useState(0);
    const [code1Type, setCode1Type] = useState(0);
    const [code1Value, setCode1Value] = useState('');
    const [code2Type, setCode2Type] = useState(0);
    const [code2Value, setCode2Value] = useState('');
    const editorRef = useRef<tinymce.Editor | null>(null);
    const theme = useTheme();
    const isDarkTheme = useMemo(() => theme.palette.mode === 'dark', [theme.palette.mode]);

    useEffect(() => setIsComponentMounted(true) , []);

    useEffect(() => {
        if (open && nameProp) {
            setName(nameProp);
        }
    }, [open, nameProp]);

    useEffect(() => {
        const getUserId = async () => {
            const u = await GetMyUser();
            if (u) {
                setDefaultOwnerId(u.userID);
                setOwnerId(u.userID);
            }
        };

        getUserId();
    }, []);

    const resetToDefaultValues = useCallback(() => {
        setShowValidation(false);
        setName('');
        setPhone('');
        setWebsite('');
        setOwnerId(defaultOwnerId);
        setStatusId(1);
        setRating(0);
        setCode1Type(0);
        setCode1Value('');
        setCode2Type(0);
        setCode2Value('');
        if (editorRef.current) editorRef.current.setContent('');
    }, [defaultOwnerId]);

    const saveChangesHandler = useCallback(async () => {
        if (!Boolean(name.trim())) {
            setShowValidation(true);
            errorHandler && errorHandler('Name is required');
            return;
        }

        loadingHandler && loadingHandler(true);
        const api = editorRef.current;
        if (api) {
            const summary = api.getContent();
            const c: Partial<Client> = {
                legalName: name,
                phone: phone,
                website: website,
                type: statusId,
                rating: rating,
                code1Type: code1Type,
                code1: code1Value,
                code2Type: code2Type,
                code2: code2Value,
                description: summary
            };

            const res = await CreateClient(c, null, errorHandler);
            if (res && res.value) {
                const oId = ownerId ? ownerId : defaultOwnerId;
                await SetClientOwners(res.value, [oId ?? 0], errorHandler);
                const newClient = {...c, id: res.value} as Client;
                successHandler && successHandler('Client Created');
                onClientCreated(newClient);
                resetToDefaultValues();
                closeHandler();
            }
        }
        loadingHandler && loadingHandler(false);
    }, [name, phone, website, statusId, rating, code1Type, code1Value, code2Type, code2Value, ownerId, defaultOwnerId,
        closeHandler, errorHandler, loadingHandler, successHandler, onClientCreated, resetToDefaultValues]);

    const handleClose = useCallback(() => {
        closeHandler();
        resetToDefaultValues();
    }, [closeHandler, resetToDefaultValues]);

    return (
        <Dialog open={open} fullWidth maxWidth="md">
            <DialogTitle>Quick Create Client</DialogTitle>
            <DialogContent>
                <Box display="flex">
                    <Stack spacing={2} mt={1} flex="1 1 0" pr={2}>
                        <RWTextFieldComponent value={name} onChange={(e) => setName(e.target.value)} label="Name" isError={ showValidation && !Boolean(name.trim()) } />
                        <RWTextFieldComponent value={phone} onChange={(e) => setPhone(e.target.value)} label="Phone" />
                        <RWTextFieldComponent value={website} onChange={(e) => setWebsite(e.target.value)} label="Website" />
                        <UserPicker userId={ownerId} onSelect={u => setOwnerId(u ? u.id : null)} label="Owner" />
                        <TextField select value={statusId.toString()} onChange={({target}) => setStatusId(+target.value)} label="Status" >
                            <MenuItem value="1">Unqualified</MenuItem>
                            <MenuItem value="2">Qualified</MenuItem>
                            <MenuItem value="3">Client</MenuItem>
                            <MenuItem value="4">Supplier</MenuItem>
                            <MenuItem value="6">Supplier - Sub Contractor</MenuItem>
                            <MenuItem value="5">Archived</MenuItem>
                            <MenuItem value="7">Prospect</MenuItem>
                            <MenuItem value="8">Previous Client</MenuItem>
                        </TextField>
                        <TextField select value={rating.toString()} onChange={({target}) => setRating(+target.value)} label="Rating" >
                            <MenuItem value="0">None</MenuItem>
                            <MenuItem value="1">1</MenuItem>
                            <MenuItem value="2">2</MenuItem>
                            <MenuItem value="3">3</MenuItem>
                            <MenuItem value="4">4</MenuItem>
                            <MenuItem value="5">5</MenuItem>
                        </TextField>
                        <Box display="flex">
                            <TextField select value={code1Type.toString()} label="Code 1 Type" sx={{ flex: '1 1 0', pr: '5px' }} onChange={ ({target}) => setCode1Type(+target.value) }>
                                <MenuItem value="0">None</MenuItem>
                                <MenuItem value="1">ABN</MenuItem>
                                <MenuItem value="2">ACN</MenuItem>
                                <MenuItem value="3">Company #</MenuItem>
                                <MenuItem value="4">VAT #</MenuItem>
                            </TextField>
                            <RWTextFieldComponent value={code1Value} label="Code 1 Value" sxOptions={{ flex: '1 1 0', pl: '5px' }} onChange={ (e) => setCode1Value(e.target.value) } />
                        </Box>
                        <Box display="flex">
                            <TextField select value={code2Type.toString()} label="Code 2 Type" sx={{ flex: '1 1 0', pr: '5px' }} onChange={ ({target}) => setCode2Type(+target.value) }>
                                <MenuItem value="0">None</MenuItem>
                                <MenuItem value="1">ABN</MenuItem>
                                <MenuItem value="2">ACN</MenuItem>
                                <MenuItem value="3">Company #</MenuItem>
                                <MenuItem value="4">VAT #</MenuItem>
                            </TextField>
                            <RWTextFieldComponent value={code2Value} label="Code 2 Value" sxOptions={{ flex: '1 1 0', pl: '5px' }} onChange={ (e) => setCode2Value(e.target.value) } />
                        </Box>
                    </Stack>
                    <Box flex="2 1 0" display="flex" flexDirection="column">
                        <Typography variant="h6" component="div">Summary</Typography>
                        {isComponentMounted &&
                            <Editor
                                tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce-5.10.2/tinymce.min.js'}
                                onInit={ (evt, editor) => editorRef.current = editor }
                                init={{
                                    height: '100%',
                                    skin: isDarkTheme ? 'oxide-dark' : undefined,
                                    content_css: isDarkTheme ? 'dark' : undefined,
                                    branding: false,
                                    menubar: false,
                                    contextmenu: false,
                                    resize: false
                                }}
                            />
                        }
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="error" onClick={ handleClose }>Cancel</Button>
                <Button variant="contained" color="success" onClick={ saveChangesHandler }>Submit</Button>
            </DialogActions>
        </Dialog>
    );
}