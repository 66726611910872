import Alert from "components/Alert";
import Snackbar from "@mui/material/Snackbar";
import React, { useEffect, useState } from "react";
import ScreenLayoutAdmin from "components/ScreenLayouts/Opportunities/ScreenLayoutAdmin";
import OnlyTitleSummaryBar from "components/SummaryBars/OnlyTitleSummaryBar";
import PageContentLayout from "layouts/PageContentLayout";

interface Props {
    type: 'record' | 'edit',
    opportunityType: 'BackFill' | 'Bid' | 'Float' | 'General' | 'Lead' | 'Tender',
    setSummaryBar?: (summaryBar: JSX.Element) => void
}

export default function OpportunitiesScreenLayoutPage({ type, opportunityType, setSummaryBar }: Props) {
    const [isLoading, setIsloading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [opportunityTypeSwitch, setOpportunityTypeSwitch] = useState(false);

    useEffect(() => {
        if (opportunityType) {}
        setOpportunityTypeSwitch(prev => !prev);
    }, [opportunityType]);

    useEffect(() => {
        if (setSummaryBar) {
            const typeTitle = type === 'record' ? 'View' : 'Create / Edit';
            let ot: string = opportunityType;
            if (opportunityType === 'BackFill') ot = 'Back Fill';
            const summaryBar = <OnlyTitleSummaryBar title={`Configuration > Screen Layouts > ${ot} Opportunities - ${typeTitle}`} browserTabTitle="Screen Layouts > Configuration" />;
            setSummaryBar(summaryBar);
        }
    }, [opportunityType, setSummaryBar, type]);
    
    return (
        <>
            <Snackbar open={errorMessage !== ''} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <PageContentLayout showLoading={isLoading}>
                { type === 'record' &&  opportunityTypeSwitch && <ScreenLayoutAdmin opportunityType={opportunityType} layoutType="Record" errorHandler={setErrorMessage} loadingHandler={setIsloading} /> }
                { type === 'edit' &&  opportunityTypeSwitch && <ScreenLayoutAdmin opportunityType={opportunityType} layoutType="Edit" errorHandler={setErrorMessage} loadingHandler={setIsloading} /> }
                { type === 'record' &&  !opportunityTypeSwitch && <ScreenLayoutAdmin opportunityType={opportunityType} layoutType="Record" errorHandler={setErrorMessage} loadingHandler={setIsloading} /> }
                { type === 'edit' &&  !opportunityTypeSwitch && <ScreenLayoutAdmin opportunityType={opportunityType} layoutType="Edit" errorHandler={setErrorMessage} loadingHandler={setIsloading} /> }
            </PageContentLayout>
        </>
    );
}