import { ChangeTracker } from "common/models/hooks/ChangeTracker";
import { MinSite, Site } from "common/models/Site";

export function SiteToMinSite(s: Site): MinSite {
    return {
        id: s.id,
        address1: s.address1,
        address2: s.address2,
        address3: s.address3,
        country: s.country,
        name: s.name,
        phone: s.phone,
        postcode: s.postcode,
        state: s.state,
        suburb: s.suburb,
        primaryContact: s.primaryContact,
        statusID: s.statusID,
        description: s.description,
        customField1: s.customField1, customField2: s.customField2, customField3: s.customField3, customField4: s.customField4, customField5: s.customField5, customField6: s.customField6, customField7: s.customField7, customField8: s.customField8, customField9: s.customField9, customField10: s.customField10,
        customField11: s.customField11, customField12: s.customField12, customField13: s.customField13, customField14: s.customField14, customField15: s.customField15, customField16: s.customField16, customField17: s.customField17, customField18: s.customField18, customField19: s.customField19, customField20: s.customField20,
        customField21: s.customField21, customField22: s.customField22, customField23: s.customField23, customField24: s.customField24, customField25: s.customField25, customField26: s.customField26, customField27: s.customField27, customField28: s.customField28, customField29: s.customField29, customField30: s.customField30, 
        customField31: s.customField31, customField32: s.customField32, customField33: s.customField33, customField34: s.customField34, customField35: s.customField35, customField36: s.customField36, customField37: s.customField37, customField38: s.customField38, customField39: s.customField39, customField40: s.customField40, 
        customField41: s.customField41, customField42: s.customField42, customField43: s.customField43, customField44: s.customField44, customField45: s.customField45, customField46: s.customField46, customField47: s.customField47, customField48: s.customField48, customField49: s.customField49, customField50: s.customField50, 
        customField51: s.customField51, customField52: s.customField52, customField53: s.customField53, customField54: s.customField54, customField55: s.customField55, customField56: s.customField56, customField57: s.customField57, customField58: s.customField58, customField59: s.customField59, customField60: s.customField60
    }
}

export const DefaultMinSite: MinSite = {
    id: 0,
    address1: '',
    address2: '',
    address3: '',
    country: '',
    name: '',
    phone: '',
    postcode: '',
    state: '',
    suburb: '',
    primaryContact: 0,
    statusID: 0,
    description: '',
    customField1: '', customField2: '', customField3: '', customField4: '', customField5: '', customField6: '', customField7: '', customField8: '', customField9: '', customField10: '',
    customField11: undefined, customField12: undefined, customField13: undefined, customField14: undefined, customField15: undefined,
    customField16: '0001-01-01T00:00:00', customField17: '0001-01-01T00:00:00', customField18: '0001-01-01T00:00:00', customField19: '0001-01-01T00:00:00', customField20: '0001-01-01T00:00:00',
    customField21: '', customField22: '', customField23: '', customField24: '', customField25: '', customField26: '', customField27: '', customField28: '', customField29: '', customField30: '',
    customField31: undefined, customField32: undefined, customField33: undefined, customField34: undefined, customField35: undefined,
    customField36: '0001-01-01T00:00:00', customField37: '0001-01-01T00:00:00', customField38: '0001-01-01T00:00:00', customField39: '0001-01-01T00:00:00', customField40: '0001-01-01T00:00:00',
    customField41: '', customField42: '', customField43: '', customField44: '', customField45: '', customField46: '', customField47: '', customField48: '', customField49: '', customField50: '',
    customField51: undefined, customField52: undefined, customField53: undefined, customField54: undefined, customField55: undefined,
    customField56: '0001-01-01T00:00:00', customField57: '0001-01-01T00:00:00', customField58: '0001-01-01T00:00:00', customField59: '0001-01-01T00:00:00', customField60: '0001-01-01T00:00:00'
};

export const DefaultMinSiteNoChanges: ChangeTracker<MinSite> = {
    id: false,
    address1: false,
    address2: false,
    address3: false,
    country: false,
    name: false,
    primaryContact: false,
    statusID: false,
    phone: false,
    postcode: false,
    state: false,
    suburb: false,
    description: false,
    customField1: false, customField2: false, customField3: false, customField4: false, customField5: false, customField6: false, customField7: false, customField8: false, customField9: false, customField10: false,
    customField11: false, customField12: false, customField13: false, customField14: false, customField15: false, customField16: false, customField17: false, customField18: false, customField19: false, customField20: false,
    customField21: false, customField22: false, customField23: false, customField24: false, customField25: false, customField26: false, customField27: false, customField28: false, customField29: false, customField30: false, 
    customField31: false, customField32: false, customField33: false, customField34: false, customField35: false, customField36: false, customField37: false, customField38: false, customField39: false, customField40: false, 
    customField41: false, customField42: false, customField43: false, customField44: false, customField45: false, customField46: false, customField47: false, customField48: false, customField49: false, customField50: false, 
    customField51: false, customField52: false, customField53: false, customField54: false, customField55: false, customField56: false, customField57: false, customField58: false, customField59: false, customField60: false
}

export const PlaceholderSite: Site = {
    id: -1,
    clientID: -1,
    name: 'Sydney HQ',
    clientName: 'Recruit Wizard Pty Ltd',
    address1: 'Suite 1, Level 1',
    address2: '16 Greenfield Parade',
    address3: '',
    suburb: 'Bankstown',
    state: 'NSW',
    postcode: '2200',
    country: 'AU',
    countryName: 'Australia',
    createdById: -1,
    createdByName: '',
    updatedById: -1,
    updatedByName: '',
    statusID: 0,
    status: 'Active',
    contacts: '',
    createdDate: '2010-10-10T10:10:10.100',
    updatedDate: '2010-10-10T10:10:10.100',
    phone: '+61 8071 8700',
    primaryContact: -1,
    primaryContactName: 'Glen Perry',
    primaryContactFirstName: 'Glen',
    primaryContactLastName: 'Perry',
    primaryContactEmail: 'glen@recruitwizard.com',
    primaryContactOptOut: 'false',
    phoneStandardised: "+61 8071 8700",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam in lacinia est, vel venenatis felis. Praesent sed rhoncus arcu. Nulla quis pellentesque orci. Duis blandit sodales auctor. Etiam ut justo egestas lacus sodales molestie. Nulla facilisi. Curabitur in metus odio. Nullam eu auctor nunc. In hac habitasse platea dictumst. Nulla ultrices lacinia leo ut blandit. Nullam aliquam justo enim, aliquet suscipit dui bibendum id. Fusce vitae egestas leo, nec mollis nibh. Donec libero libero, finibus vitae tellus eu, tincidunt rhoncus mi.<br/><br/> Cras dui ipsum, varius ut magna sit amet, lacinia tincidunt velit. Proin interdum ex augue. Etiam ac risus nunc. Nullam enim nunc, eleifend vel sem eget, interdum fermentum enim. Donec consectetur nibh quis dui rutrum ultrices. Nam congue nibh neque, at commodo elit fringilla quis. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Curabitur et semper sapien. Vivamus congue ante non lacus bibendum scelerisque ac sit amet felis. Morbi sit amet nunc faucibus, laoreet nunc quis, scelerisque purus.",
    tags: false,
    customField1: 'Custom Field 1',
    customField2: 'Custom Field 2',
    customField3: 'Custom Field 3',
    customField4: 'Custom Field 4',
    customField5: 'Custom Field 5',
    customField6: 'Custom Field 6',
    customField7: 'Custom Field 7',
    customField8: 'Custom Field 8',
    customField9: 'Custom Field 9',
    customField10: 'Custom Field 10',
    customField11: 11,
    customField12: 12,
    customField13: 13,
    customField14: 14,
    customField15: 15,
    customField16: '2010-10-10T10:10:10.100',
    customField17: '2010-10-10T10:10:10.100',
    customField18: '2010-10-10T10:10:10.100',
    customField19: '2010-10-10T10:10:10.100',
    customField20: '2010-10-10T10:10:10.100',
    customField21: 'Custom Field 21',
    customField22: 'Custom Field 22',
    customField23: 'Custom Field 23',
    customField24: 'Custom Field 24',
    customField25: 'Custom Field 25',
    customField26: 'Custom Field 26',
    customField27: 'Custom Field 27',
    customField28: 'Custom Field 28',
    customField29: 'Custom Field 29',
    customField30: 'Custom Field 30',
    customField31: 31,
    customField32: 32,
    customField33: 33,
    customField34: 34,
    customField35: 35,
    customField36: '2010-10-10T10:10:10.100',
    customField37: '2010-10-10T10:10:10.100',
    customField38: '2010-10-10T10:10:10.100',
    customField39: '2010-10-10T10:10:10.100',
    customField40: '2010-10-10T10:10:10.100',
    customField41: 'Custom Field 41',
    customField42: 'Custom Field 42',
    customField43: 'Custom Field 43',
    customField44: 'Custom Field 44',
    customField45: 'Custom Field 45',
    customField46: 'Custom Field 46',
    customField47: 'Custom Field 47',
    customField48: 'Custom Field 48',
    customField49: 'Custom Field 49',
    customField50: 'Custom Field 50',
    customField51: 51,
    customField52: 52,
    customField53: 53,
    customField54: 54,
    customField55: 55,
    customField56: '2010-10-10T10:10:10.100',
    customField57: '2010-10-10T10:10:10.100',
    customField58: '2010-10-10T10:10:10.100',
    customField59: '2010-10-10T10:10:10.100',
    customField60: '2010-10-10T10:10:10.100',
    statisticsDates: [],
    statisticsCounts: []
};