import React, { useCallback, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

import { GroupParticipant, GroupParticipantListItem } from "common/models/Group";
import UserGroupRelationList from "./UserGroupRelationList";
import { User } from "common/models/Configuration/User";

interface Props {
    titleLeft?: string,
    titleRight?: string,
    subtitleLeft?: string,
    subtitleRight?: string,
    users: User[],
    participants: GroupParticipant[],
    moveLeftHandler: (ids: number[]) => void,
    moveRightHandler: (ids: number[]) => void,
}

export default function UserGroupRelationTransferList({
    titleLeft = '',
    titleRight = '',
    subtitleLeft = '',
    subtitleRight = '',
    users,
    participants,
    moveLeftHandler,
    moveRightHandler
}: Props) {
    const [itemsLeft, setItemsLeft] = useState<GroupParticipantListItem[]>([]);
    const [itemsRight, setItemsRight] = useState<GroupParticipantListItem[]>([]);
    const [selectedLeft, setSelectedLeft] = useState<number[]>([]);
    const [selectedRight, setSelectedRight] = useState<number[]>([]);

    useEffect(() => {
        let left: GroupParticipantListItem[] = [];
        let right: GroupParticipantListItem[] = [];

        let newUsers = [...users];

        for (let i = 0; i < participants.length; i++) {
            const participant = participants[i];
            right.push({ userId: participant.userID, user: participant.user, isEnabled: participant.isEnabled });
            const index = newUsers.findIndex(u => u.id === participant.userID);
            if (index !== -1) newUsers.splice(index, 1);
        }

        for (let i = 0; i < newUsers.length; i++) {
            const user = newUsers[i];
            left.push({ userId: user.id, user: user.displayName, isEnabled: user.isEnabled });
        }

        setItemsLeft(left);
        setItemsRight(right);

    }, [users, participants]);

    const toggleHandlerLeft = useCallback((id: number) => {
        setSelectedLeft(prev => {
            const i = prev.indexOf(id);
            if(i === -1) return [...prev, id];
            let newSelected = [...prev];
            newSelected.splice(i, 1);
            return newSelected;
        });
    }, []);

    const toggleHandlerRight = useCallback((id: number) => {
        setSelectedRight(prev => {
            const i = prev.indexOf(id);
            if(i === -1) return [...prev, id];
            let newSelected = [...prev];
            newSelected.splice(i, 1);
            return newSelected;
        });
    }, []);

    const handleMoveLeft = useCallback(() => {
        moveLeftHandler(selectedRight);
        setSelectedRight([]);
    }, [moveLeftHandler, selectedRight]);

    const handleMoveRight = useCallback(() => {
        moveRightHandler(selectedLeft);
        setSelectedLeft([]);
    }, [moveRightHandler, selectedLeft]);

    return (
        <Grid container spacing={2} justifyContent="center" alignItems="center">
            <Grid item>
                <UserGroupRelationList
                    title={titleLeft}
                    subTitle={subtitleLeft === '' ? `Selected ${selectedLeft.length} / ${itemsLeft.length}` : subtitleLeft }
                    items={itemsLeft}
                    selected={selectedLeft}
                    toggleHandler={toggleHandlerLeft}
                    toggleAllHandler={setSelectedLeft}
                />
            </Grid>
            <Grid item>
                <Grid container direction="column" alignItems="center">
                    <Button
                        sx={{ my: 0.5 }}
                        variant="outlined"
                        size="small"
                        disabled={selectedRight.length === 0}
                        onClick={ handleMoveLeft }
                    >
                        {"<"}
                    </Button>
                    <Button
                        sx={{ my: 0.5 }}
                        variant="outlined"
                        size="small"
                        disabled={selectedLeft.length === 0}
                        onClick={ handleMoveRight }
                    >
                        {">"}
                    </Button>
                </Grid>
            </Grid>
            <Grid item>
                <UserGroupRelationList
                    title={titleRight}
                    subTitle={subtitleRight === '' ? `Selected ${selectedRight.length} / ${itemsRight.length}` : subtitleRight }
                    items={itemsRight}
                    selected={selectedRight}
                    toggleHandler={toggleHandlerRight}
                    toggleAllHandler={setSelectedRight}
                />
            </Grid>
        </Grid>
    );
}