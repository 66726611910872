import { ErrorHandler, RequestOptions } from "../common/models/GenericTypes";
import { ChangeRequest, ChangeRequestParams, ChangeStartDateParams, ConvertPlacementToPermParams } from "../common/models/ChangeRequest";
import { Get, Post, Put } from "../util/HttpUtils";
import { ChangeRequestTypeEnum } from "util/Definitions/Configuration/Placements";
import { ChangeRequestSearch } from "common/models/Search/ChangeRequestSearch";

export async function GetMyPending(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<ChangeRequest[]>('api/changerequests/pending/me', options);
}

export async function GetAllPending(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<ChangeRequest[]>('api/changerequests/pending/all', options);
}

export async function SearchChangeRequests(data: ChangeRequestSearch, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<ChangeRequest[]>('api/changerequests/search', options, data);
}

export async function GetChangeRequestById(changeRequestId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<ChangeRequest>(`api/changerequests/${changeRequestId}`, options);
}

export async function ChangePlacementStartDate(placementId: number, startDate: string, endDate: string, rateId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>(`api/changerequests/${placementId}/StartDate`, options, { rateId: rateId, startDate: startDate, endDate: Boolean(endDate) ? endDate : null });
}

export async function ApproveChangeRequest(changeRequestId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>(`api/changerequests/${changeRequestId}/approve`, options);
}

export async function RejectChangeRequest(changeRequestId: number, comments: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>(`api/changerequests/${changeRequestId}/reject`, options, { value: comments });
}

export async function ReverseChangeRequest(changeRequestId: number, comments: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>(`api/changerequests/${changeRequestId}/reverse`, options, { value: comments });
}

export async function RescindChangeRequest(changeRequestId: number, comments: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>(`api/changerequests/${changeRequestId}/rescind`, options, { value: comments });
}

export async function ConvertPlacementToPerm(placementId: number, data: ConvertPlacementToPermParams, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/changerequests/${placementId}/Convert-To-Perm`, options, data);
}

export async function ChangePlacementConsultants(placementId: number, data: Partial<ChangeRequestParams>, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/changerequests/${placementId}/Consultants`, options, data);
}

export async function ChangePlacementPaymentType(placementId: number, data: Partial<ChangeRequestParams>, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/changerequests/${placementId}/Payment-Type`, options, data);
}

export async function ChangePlacementRates(placementId: number, data: Partial<ChangeRequestParams>, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/changerequests/${placementId}/Rates`, options, data);
}

export async function CorrectPlacementRates(placementId: number, data: Partial<ChangeRequestParams>, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/changerequests/${placementId}/Rates?rateCorrection=true`, options, data);
}

export async function ExtendPlacement(placementId: number, data: Partial<ChangeRequestParams>, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/changerequests/${placementId}/Rates?extension=true`, options, data);
}

export async function UpdateChangeRequest(changeRequestId: number, typeId: ChangeRequestTypeEnum, data: Partial<ChangeRequestParams> | ConvertPlacementToPermParams | ChangeStartDateParams, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    let path = '';
    if (typeId === ChangeRequestTypeEnum.RateChange || typeId === ChangeRequestTypeEnum.Extension || typeId === ChangeRequestTypeEnum.RateCorrection) path = 'Rates';
    else if (typeId === ChangeRequestTypeEnum.PaymentType) path = 'Payment-Type';
    else if (typeId === ChangeRequestTypeEnum.ConsultantsChange) path = 'Consultants';
    else if (typeId === ChangeRequestTypeEnum.ConvertToPerm) path = 'Convert-To-Perm';
    else if (typeId === ChangeRequestTypeEnum.StartDateChange) path = 'StartDate';

    return Put<boolean>(`api/changerequests/${path}/${changeRequestId}`, options, data);
}