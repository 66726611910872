export function NoValidation(value: string, name?: string) {
    return '';
}

export function Required(value: string, name?: string) {
    if(value)
        return '';
    
    return name ? `${name} is required` : 'This field is required';
}

export function RequiredEmail(value: string, name?: string) {
    if (!value) {
        return name ? `${name} is required` : 'This field is required';
    }

    const char = /^[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;

    if (char.test(value)) return '';

    return 'Valid email required';
}