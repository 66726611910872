import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CancelIcon from '@mui/icons-material/Cancel';
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { PREVIEW_DRAGGABLE_TITLE_STYLE } from "util/Definitions/Constants/Previews";
import DraggablePaperComponent from "components/DraggablePaperComponent";
import DialogContent from "@mui/material/DialogContent";
import Stack from "@mui/material/Stack";
import { Editor } from "@tinymce/tinymce-react";
import * as tinymce from 'tinymce';
import { Ui } from 'tinymce';
import { useTheme } from "@mui/material/styles";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { GetSms } from "services/MessagesService";
import { UpdateScheduledSms } from "services/ScheduledMessagesService";

interface Props {
    open: boolean,
    messageId: number,
    entityId: number,
    closeHandler: () => void,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
    successHandler?: (message: string) => void,
}

export default function EditScheduledSmsDialog({ open, messageId, entityId, closeHandler, loadingHandler, errorHandler, successHandler }: Props) {
    const [body, setBody] = useState('');
    const [isEditorDirty, setIsEditorDirty] = useState(false);
    const [rerenderControl, setRerenderControl] = useState(false);
    const editorRef = useRef<tinymce.Editor | null>(null);
    const theme = useTheme();
    const isDarkTheme = useMemo(() => theme.palette.mode === 'dark', [theme.palette.mode]);

    useEffect(() => {
        if (!open) {
            setBody('');
            setIsEditorDirty(false);
        }
    }, [open]);

    useEffect(() => {
        if (entityId) {}
        setRerenderControl(prev => !prev);
    }, [entityId, open]);

    useEffect(() => {
        const getData = async () => {
            loadingHandler && loadingHandler(true);
            const res = await GetSms(messageId, errorHandler);
            if (res) {
                setBody(res.body);
            }
            loadingHandler && loadingHandler(false);
        };
        messageId && getData();
    }, [errorHandler, loadingHandler, messageId]);

    const editor = useMemo(() => {
        if (open) {
            return (
                <Editor
                    tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce-5.10.2/tinymce.min.js'}
                    initialValue={body}
                    onDirty={ () => setIsEditorDirty(true) }
                    onInit={ (evt, editor) => editorRef.current = editor }
                    init={{
                        height: 510,
                        skin: isDarkTheme ? 'oxide-dark' : undefined,
                        content_css: isDarkTheme ? 'dark' : undefined,
                        branding: false,
                        menubar: false,
                        statusbar: false,
                        contextmenu: false,
                        resize: false,
                        browser_spellcheck: true,
                        plugins: 'paste',
                        paste_as_text: true,
                        toolbar1: 'mergefields',
                        forced_root_block: "",
                        entity_encoding: 'raw',
                        setup: editor => {
                            editor.ui.registry.addMenuButton('mergeFields', {
                                text: 'Placeholders',
                                fetch: callback =>{
                                    let placeholders: string[] = [];

                                    if (entityId === 2) placeholders = ['{Contact.FirstName}', '{Contact.LastName}', '{Contact.Email}'];
                                    else if (entityId === 3) placeholders = ['{Candidate.FirstName}', '{Candidate.LastName}', '{Candidate.Email}'];

                                    placeholders.push('|', '{User.FirstName}');

                                    let menuItems: Ui.Menu.NestedMenuItemContents[] = [];
                                    for (let i = 0; i < placeholders.length; i++) {
                                        const p = placeholders[i];
                                        if (p === '|') menuItems.push(p);
                                        else menuItems.push({ type: 'menuitem', text: p, onAction: () => editor.insertContent(p) });
                                    }

                                    callback(menuItems);
                                }
                            })
                        }
                    }}
                />
            );
        }
        return <></>;
    }, [body, entityId, isDarkTheme, open]);

    const submitCallback = useCallback(async () => {
        const api = editorRef.current;
        if (api) {
            const content = api.getContent();    
            if (!Boolean(content.trim())) {
                errorHandler && errorHandler('Body cannot be empty');
                return false;
            }
    
            loadingHandler && loadingHandler(true);
            const res = await UpdateScheduledSms(messageId, content, errorHandler);
            if (res) {
                successHandler && successHandler('Message Saved');
                closeHandler();
            }
            loadingHandler && loadingHandler(false);

        }
    }, [closeHandler, errorHandler, loadingHandler, messageId, successHandler]);

    return (
        <Dialog open={open} maxWidth="lg" fullWidth PaperComponent={DraggablePaperComponent} aria-labelledby="draggable-dialog-title">
            <DialogTitle component="div" display="flex" sx={PREVIEW_DRAGGABLE_TITLE_STYLE} id="draggable-dialog-title" >
                <Box alignSelf="center">Scheduled Message</Box >
                <Box ml="auto" style={{ marginLeft: 'auto' }}>
                    <IconButton size="small" onClick={ closeHandler } sx={{ color: t => t.palette.primary.contrastText }}><CancelIcon /></IconButton>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Stack spacing={2} pt={2}>
                    <>
                        {rerenderControl && editor}
                        {!rerenderControl && editor}
                    </>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="success" disabled={!isEditorDirty} onClick={submitCallback}>Save</Button>
            </DialogActions>
        </Dialog>
    );
}