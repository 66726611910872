import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { PanelModel } from "@syncfusion/ej2-layouts/src/dashboard-layout/dashboard-layout-model";
import { DashboardLayoutComponent } from "@syncfusion/ej2-react-layouts/src/dashboard-layout/dashboardlayout.component";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import * as tinymce from 'tinymce';
import { Client } from "common/models/Clients";
import { CustomField, CustomFieldPredefinedValue } from "common/models/Configuration/CustomFields";
import { Contact } from "common/models/Contacts";
import { JobRecordDashboardElementDefinition, JobRecordDashboardElementType } from "common/models/Dashboard/EditLayout";
import { Job, MinJob } from "common/models/Jobs/Job";
import { CustomFieldSettingsMap, CustomFieldType } from "common/models/ScreenLayouts/CustomFields";
import { Site } from "common/models/Site";
import useObjectStateWithChangeTracker from "hooks/UseObjectStateWithChangeTracker";
import useUnsavedChangesDialog from "hooks/UseUnsavedChangesDialog";
import { GetClientById } from "services/ClientsService";
import { GetCustomerSettingBySettingName, GetJobSources } from "services/ConfigurationService";
import { GetContactById } from "services/ContactsService";
import { GetCustomFieldsByEntity_OnlyActive, GetPredefinedValues } from "services/CustomFieldsService";
import { CopyJob, CreateJob, GetJobById, UpdateJob } from "services/JobsService";
import { GetSiteById } from "services/SitesService";
import { GetMyUser, GetSingleSetting } from "services/UsersService";
import { DefaultMinJob, DefaultMinJobNoChanges, JobToMinJob } from "util/Definitions/Jobs";
import { IsValidCustomFieldValue, UdfJobFieldMapObj } from "util/Definitions/ScreenLayouts/CustomFields";
import { DefaultJobRecordDashboardElements_Contract, DefaultJobRecordDashboardElements_FixedContract, DefaultJobRecordDashboardElements_Panel, DefaultJobRecordDashboardElements_Permanent, DefaultJobRecordDashboardElements_TalentPool, GetPanelDefinitionsFromPanelModels, JobScreenLayoutSettings } from "util/Definitions/ScreenLayouts/Job";
import PanelWrapper from "components/Dashboards/PanelWrapper";
import ClientPicker from "components/Pickers/ClientPicker";
import ContactPicker from "components/Pickers/ContactPicker";
import CurrencyPicker from "components/Pickers/CurrencyPicker";
import DivisionPicker from "components/Pickers/DivisionPicker";
import UserPicker from "components/Pickers/UserPicker";
import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import EditableClientLocationElement from "../Clients/EditableClientLocationElement";
import EditableRichTextElement from "../EditableRichTextElement";
import EditableSingleFieldElement from "../EditableSingleFieldElement";
import SingleFieldElement from "../SingleFieldElement";
import moment from "moment-business-days";
import { IsValidNumericValue, RegexIsPositiveNumberWith2Decimals } from "util/RegExUtils";
import { NameIdObj } from "common/models/GenericTypes";
import { ComplianceChecklist } from "common/models/Configuration/Compliance";
import ComplianceChecklistPicker from "components/Pickers/ComplianceChecklistPicker";
import CopyJobDialog from "components/Dialogs/Jobs/CopyJobDialog";
import RWTextFieldComponent from "components/RWTextFieldComponent";

interface Props {
    jobId?: number
    jobType?: string,
    clientId?: number,
    contactId?: number,
    siteId?: number,
    defaultCurrency?: number,
    defaultHoursPerDay?: number,
    defaultDaysPerWeek?: number,
    isCopy?: boolean,
    defaultSummary?: string,
    setSummaryBar?: (sb: JSX.Element) => void,
    loadingHandler?: (isLoading: boolean) => void,
    successHandler?: (message: string) => void,
    errorHandler?: (message: string) => void,
}

interface CustomFieldWithPredefinedValues extends CustomField {
    values?: CustomFieldPredefinedValue[],
}

type CustomFieldValidateMap = Record<string, boolean>;

const validationErrorMessage = (validationResult: string) => {
    if (validationResult === 'ok') return '';
    if (validationResult === 'required') return 'Required';
    return 'Not Valid';
};

const daysPerWeekValidator = (value: string) => {
    return value === '' || (RegexIsPositiveNumberWith2Decimals(value) && +value <= 7);
};

const { unitWidth, unitHeight, gapX, gapY, columns, mediaQueryMaxWidth } = JobScreenLayoutSettings;
const cellSpacing = [gapX, gapY];

const quantityOptions = [
    <MenuItem key={1} value="1">1</MenuItem>,
    <MenuItem key={2} value="2">2</MenuItem>,
    <MenuItem key={3} value="3">3</MenuItem>,
    <MenuItem key={4} value="4">4</MenuItem>,
    <MenuItem key={5} value="5">5</MenuItem>,
    <MenuItem key={6} value="6">6</MenuItem>,
    <MenuItem key={7} value="7">7</MenuItem>,
    <MenuItem key={8} value="8">8</MenuItem>,
    <MenuItem key={9} value="9">9</MenuItem>,
    <MenuItem key={10} value="10">10</MenuItem>,
    <MenuItem key={11} value="11">11</MenuItem>,
    <MenuItem key={12} value="12">12</MenuItem>,
    <MenuItem key={13} value="13">13</MenuItem>,
    <MenuItem key={14} value="14">14</MenuItem>,
    <MenuItem key={15} value="15">15</MenuItem>,
    <MenuItem key={16} value="16">16</MenuItem>,
    <MenuItem key={17} value="17">17</MenuItem>,
    <MenuItem key={18} value="18">18</MenuItem>,
    <MenuItem key={19} value="19">19</MenuItem>,
    <MenuItem key={20} value="20">20</MenuItem>,
    <MenuItem key={21} value="21">21</MenuItem>,
    <MenuItem key={22} value="22">22</MenuItem>,
    <MenuItem key={23} value="23">23</MenuItem>,
    <MenuItem key={24} value="24">24</MenuItem>,
    <MenuItem key={25} value="25">25</MenuItem>,
    <MenuItem key={26} value="26">26</MenuItem>,
    <MenuItem key={27} value="27">27</MenuItem>,
    <MenuItem key={28} value="28">28</MenuItem>,
    <MenuItem key={29} value="29">29</MenuItem>,
    <MenuItem key={30} value="30">30</MenuItem>,
    <MenuItem key={31} value="31">31</MenuItem>,
    <MenuItem key={32} value="32">32</MenuItem>,
    <MenuItem key={33} value="33">33</MenuItem>,
    <MenuItem key={34} value="34">34</MenuItem>,
    <MenuItem key={35} value="35">35</MenuItem>,
    <MenuItem key={36} value="36">36</MenuItem>,
    <MenuItem key={37} value="37">37</MenuItem>,
    <MenuItem key={38} value="38">38</MenuItem>,
    <MenuItem key={39} value="39">39</MenuItem>,
    <MenuItem key={40} value="40">40</MenuItem>,
    <MenuItem key={41} value="41">41</MenuItem>,
    <MenuItem key={42} value="42">42</MenuItem>,
    <MenuItem key={43} value="43">43</MenuItem>,
    <MenuItem key={44} value="44">44</MenuItem>,
    <MenuItem key={45} value="45">45</MenuItem>,
    <MenuItem key={46} value="46">46</MenuItem>,
    <MenuItem key={47} value="47">47</MenuItem>,
    <MenuItem key={48} value="48">48</MenuItem>,
    <MenuItem key={49} value="49">49</MenuItem>,
    <MenuItem key={50} value="50">50</MenuItem>,
    <MenuItem key={51} value="51">51</MenuItem>,
    <MenuItem key={52} value="52">52</MenuItem>,
    <MenuItem key={53} value="53">53</MenuItem>,
    <MenuItem key={54} value="54">54</MenuItem>,
    <MenuItem key={55} value="55">55</MenuItem>,
    <MenuItem key={56} value="56">56</MenuItem>,
    <MenuItem key={57} value="57">57</MenuItem>,
    <MenuItem key={58} value="58">58</MenuItem>,
    <MenuItem key={59} value="59">59</MenuItem>,
    <MenuItem key={60} value="60">60</MenuItem>,
    <MenuItem key={61} value="61">61</MenuItem>,
    <MenuItem key={62} value="62">62</MenuItem>,
    <MenuItem key={63} value="63">63</MenuItem>,
    <MenuItem key={64} value="64">64</MenuItem>,
    <MenuItem key={65} value="65">65</MenuItem>,
    <MenuItem key={66} value="66">66</MenuItem>,
    <MenuItem key={67} value="67">67</MenuItem>,
    <MenuItem key={68} value="68">68</MenuItem>,
    <MenuItem key={69} value="69">69</MenuItem>,
    <MenuItem key={70} value="70">70</MenuItem>,
    <MenuItem key={71} value="71">71</MenuItem>,
    <MenuItem key={72} value="72">72</MenuItem>,
    <MenuItem key={73} value="73">73</MenuItem>,
    <MenuItem key={74} value="74">74</MenuItem>,
    <MenuItem key={75} value="75">75</MenuItem>,
    <MenuItem key={76} value="76">76</MenuItem>,
    <MenuItem key={77} value="77">77</MenuItem>,
    <MenuItem key={78} value="78">78</MenuItem>,
    <MenuItem key={79} value="79">79</MenuItem>,
    <MenuItem key={80} value="80">80</MenuItem>,
    <MenuItem key={81} value="81">81</MenuItem>,
    <MenuItem key={82} value="82">82</MenuItem>,
    <MenuItem key={83} value="83">83</MenuItem>,
    <MenuItem key={84} value="84">84</MenuItem>,
    <MenuItem key={85} value="85">85</MenuItem>,
    <MenuItem key={86} value="86">86</MenuItem>,
    <MenuItem key={87} value="87">87</MenuItem>,
    <MenuItem key={88} value="88">88</MenuItem>,
    <MenuItem key={89} value="89">89</MenuItem>,
    <MenuItem key={90} value="90">90</MenuItem>,
    <MenuItem key={91} value="91">91</MenuItem>,
    <MenuItem key={92} value="92">92</MenuItem>,
    <MenuItem key={93} value="93">93</MenuItem>,
    <MenuItem key={94} value="94">94</MenuItem>,
    <MenuItem key={95} value="95">95</MenuItem>,
    <MenuItem key={96} value="96">96</MenuItem>,
    <MenuItem key={97} value="97">97</MenuItem>,
    <MenuItem key={98} value="98">98</MenuItem>,
    <MenuItem key={99} value="99">99</MenuItem>,
    <MenuItem key={100} value="100">100</MenuItem>,
];

const timePeriodOptions = [
    <MenuItem key={0} value="0">Select</MenuItem>,
    <MenuItem key={1} value="1">Hourly</MenuItem>,
    <MenuItem key={2} value="2">Daily</MenuItem>,
    <MenuItem key={3} value="3">Weekly</MenuItem>,
    <MenuItem key={4} value="4">Monthly</MenuItem>,
    <MenuItem key={5} value="5">Yearly</MenuItem>,
];

const getJobOutcomeName = (outcomeId: number) => {
    switch (outcomeId)
    {
        case 1: return "Filled by Us";
        case 2: return "Filled by Client";
        case 3: return "Filled by Competitor";
        case 4: return "Withdrawn";
        case 5: return "Administrative";
        case 6: return "Unknown";
        case 7: return "Not Supplied";
        case 8: return "Client did not win work";
        case 9: return "Budget not approved";
        case 10: return "Not worked Properly";
        case 11: return "Over Capacity";
        case 12: return "Closed Due To Inactivity";
    }
    return "N/A";
};

export default function EditRecordScreenLayout({ jobId = 0, jobType = '', clientId = 0, contactId = 0, siteId = 0, defaultCurrency, defaultHoursPerDay, defaultDaysPerWeek, isCopy = false, defaultSummary = '', setSummaryBar, loadingHandler, successHandler, errorHandler }: Props) {
    const [isFetchingConfig, setIsFetchingConfig] = useState(false);
    const [isFetchingJob, setIsFetchingJob] = useState(false);
    const [fetchedConfig, setFetchedConfig] = useState(false);
    const [fetchedJob, setFetchedJob] = useState(false);
    const [showValidation, setShowValidation] = useState(false);
    const [fetchedLayoutConfig, setFetchedLayoutConfig] = useState(false);
    const [fetchingLayoutConfig, setFetchingLayoutConfig] = useState(false);
    const [layoutConfiguration, setLayoutConfiguration] = useState<PanelModel[] | null>(null);
    const [existingJob, setExistingJob] = useState<Job | null>(null);
    const [availableCustomFields, setAvailableCustomFields] = useState<CustomFieldWithPredefinedValues[]>([]);
    const [isEditorDirty, setIsEditorDirty] = useState(false);
    const { state, init, change, updateInitial, hasChanges } = useObjectStateWithChangeTracker<MinJob>(DefaultMinJob, DefaultMinJobNoChanges);
    const editorRef = useRef<tinymce.Editor | null>(null);
    const [savedChanges, setSavedChanges] = useState(false);
    const [createdJobId, setCreatedJobId] = useState(0);
    const [screenResizedControl, setScreenResizedControl] = useState(false);
    const [jobSources, setJobSources] = useState<string[]>([]);
    const [isDeadlineEnabled, setIsDeadlineEnabled] = useState(true);
    const [jobFeeValue, setJobFeeValue] = useState('');
    const [initialJobFeeValue, setInitialJobFeeValue] = useState(0);
    const [customFieldValidateMap, setCustomFieldValidateMap] = useState<CustomFieldValidateMap>({});
    const [isCopyJobDialogOpen, setIsCopyJobDialogOpen] = useState(false);
    const layoutRef = useRef<DashboardLayoutComponent | null>(null);
    const theme = useTheme();

    const layoutDataSettingName = useMemo(() => {
        const jType = state.type ? state.type : jobType;
        switch (jType) {
            case 'Permanent': return 'JobRecordDashboardLayoutElements_Permanent';
            case 'Contract': return 'JobRecordDashboardLayoutElements_Contract';
            case 'FixedContract': return 'JobRecordDashboardLayoutElements_FixedContract';
            case 'TalentPool': return 'JobRecordDashboardLayoutElements_TalentPool';
            case 'Panel': return 'JobRecordDashboardLayoutElements_Panel';
        }
        return '';
    }, [state.type, jobType]);

    const defaultElements = useMemo(() => {
        const jType = state.type ? state.type : jobType;
        switch (jType) {
            case 'Permanent': return DefaultJobRecordDashboardElements_Permanent;
            case 'Contract': return DefaultJobRecordDashboardElements_Contract;
            case 'FixedContract': return DefaultJobRecordDashboardElements_FixedContract;
            case 'TalentPool': return DefaultJobRecordDashboardElements_TalentPool;
            case 'Panel': return DefaultJobRecordDashboardElements_Panel;
        }
        return [];
    }, [state.type, jobType]);

    useEffect(() => {
        const getExistingJob = async () => {
            setIsFetchingJob(true);
            const res = await GetJobById(jobId, errorHandler);
            if (res && jobId) {
                setExistingJob(res);
                const fee = res.feeType === 1 ? res.permFeePercentage : res.permFeeAmount;
                setJobFeeValue(fee.toString());
                setInitialJobFeeValue(fee);
            }
            setFetchedJob(true);
            setIsFetchingJob(false);
        };
        jobId && getExistingJob();
    }, [jobId, errorHandler]);

    useEffect(() => {
        const getActiveFields = async () => {
            setIsFetchingConfig(true);
            const customFields = await GetCustomFieldsByEntity_OnlyActive(4);
            if (customFields) {
                let udfDefinitions: CustomFieldWithPredefinedValues[] = [];
                for (let i = 0; i < customFields.length; i++) {
                    const u = customFields[i];
                    if (!u.usePredefinedValues) {
                        udfDefinitions.push(u);
                        continue;
                    }

                    const vals = await GetPredefinedValues(u.id);
                    if (vals) udfDefinitions.push({ ...u, values: vals });
                    else udfDefinitions.push(u);
                }
                setAvailableCustomFields(udfDefinitions);
            }
            setFetchedConfig(true);
            setIsFetchingConfig(false);
        };
        getActiveFields();
    }, []);

    useEffect(() => {
        const setDefaultCreationValues = async () => {
            let tempState = {...DefaultMinJob};
            let client: Client | null = null;
            tempState.type = jobType;
            tempState.description = defaultSummary;
            if (defaultCurrency) tempState.currencyID = defaultCurrency;
            if (defaultHoursPerDay) tempState.hoursPerDay = defaultHoursPerDay;
            if (defaultDaysPerWeek) tempState.daysPerWeek = defaultDaysPerWeek;
            if (contactId) {
                if (!clientId || !siteId) {
                    const contact = await GetContactById(contactId);
                    if (contact && !clientId) tempState.clientID = contact.clientID;
                    if (contact && !siteId) {
                        tempState.siteID = contact.siteID;
                        tempState.address1 = contact.address1 ?? '';
                        tempState.address2 = contact.address2 ?? '';
                        tempState.address3 = contact.address3 ?? '';
                        tempState.suburb = contact.suburb ?? '';
                        tempState.state = contact.state ?? '';
                        tempState.postcode = contact.postcode ?? '';
                        tempState.countryName = contact.countryName ?? '';
                    }

                }
                tempState.contact1ID = contactId;
                tempState.contact2ID = contactId;
                tempState.contact3ID = contactId;
            }
            if (clientId) {
                if (!contactId || !siteId) {
                    client = await GetClientById(clientId);
                    if (client && !contactId) {
                        tempState.contact1ID = client.primaryContact;
                        tempState.contact2ID = client.primaryContact;
                        tempState.contact3ID = client.primaryContact;
                    }
                    if (client && !siteId) {
                        tempState.siteID = client.siteID;
                        tempState.address1 = client.address1 ?? '';
                        tempState.address2 = client.address2 ?? '';
                        tempState.address3 = client.address3 ?? '';
                        tempState.suburb = client.suburb ?? '';
                        tempState.state = client.state ?? '';
                        tempState.postcode = client.postcode ?? '';
                        tempState.countryName = client.countryName ?? '';
                    }
                }
                tempState.clientID = clientId;
            }
            if (siteId) {
                const site = await GetSiteById(siteId);
                if (site) {
                    tempState.siteID = siteId;
                    tempState.address1 = site.address1 ?? '';
                    tempState.address2 = site.address2 ?? '';
                    tempState.address3 = site.address3 ?? '';
                    tempState.suburb = site.suburb ?? '';
                    tempState.state = site.state ?? '';
                    tempState.postcode = site.postcode ?? '';
                    tempState.countryName = site.countryName ?? '';
                    
                    if (!contactId) {
                        tempState.contact1ID = site.primaryContact;
                        tempState.contact2ID = site.primaryContact;
                        tempState.contact3ID = site.primaryContact;
                    }

                    if (!clientId) tempState.clientID = site.clientID;
                }
            }

            let startDateOffsetSettingName = '';
            let deadlineOffsetSettingName = '';
            let deadlineOffset = 0;
            if (jobType === 'Permanent') {
                startDateOffsetSettingName = 'JobStartDateOffsetPerm';
                deadlineOffsetSettingName = 'CVDeadlinePerm';
                if (client) deadlineOffset = client.cvDeadlinePerm;

            }
            else if (jobType === 'Contract') {
                startDateOffsetSettingName = 'JobStartDateOffsetContract';
                deadlineOffsetSettingName = 'CVDeadlineContract';
                if (client) deadlineOffset = client.cvDeadlineContract;
            }
            else if (jobType === 'FixedContract') {
                startDateOffsetSettingName = 'JobStartDateOffsetFTC';
                deadlineOffsetSettingName = 'CVDeadlineFixedContract';
                if (client) deadlineOffset = client.cvDeadlineFixedContract;
            }

            if (startDateOffsetSettingName) {
                const offset = await GetCustomerSettingBySettingName(startDateOffsetSettingName);
                if (offset && IsValidNumericValue(offset)) {
                    const sd = moment().businessAdd(+offset).format('YYYY-MM-DD');
                    tempState.startDate = sd;
                }
            }

            const autoPopulateDeadlineValue = await GetCustomerSettingBySettingName('SubmissionDeadlinesAutoPopulate');
            const isAutoPopulateDeadline = autoPopulateDeadlineValue && autoPopulateDeadlineValue.toLowerCase() === 'true';

            if (deadlineOffset === 0 && deadlineOffsetSettingName && isAutoPopulateDeadline) {
                const dOffset = await GetCustomerSettingBySettingName(deadlineOffsetSettingName);
                if (dOffset && IsValidNumericValue(dOffset)) deadlineOffset = +dOffset;
            }

            if (isAutoPopulateDeadline) {
                const md = moment().businessAdd(deadlineOffset);
                tempState.deadline = md.format('YYYY-MM-DD');
            }

            const deadlineChangesSettingValue = await GetCustomerSettingBySettingName('structureddeadlinechanges');
            const isDeadlineChanges = deadlineChangesSettingValue && deadlineChangesSettingValue.toLowerCase() === 'true';
            setIsDeadlineEnabled(!isDeadlineChanges);
            
            const u = await GetMyUser();
            if (u) tempState.consultantID = u.userID;
            const division = await GetSingleSetting('Division');
            const workType = await GetCustomerSettingBySettingName('JobDefaultWorkType');
            if (division && division.value) tempState.division = +division.value;
            if (workType) tempState.workTypeID = +workType;
            if (availableCustomFields.length > 0) {
                for (let i = 0; i < availableCustomFields.length; i++) {
                    const f = availableCustomFields[i];
                    if (f.usePredefinedValues && f.values && f.values.length > 0) {
                        const defaultValue = f.values.find(v => v.isDefault);
                        if (defaultValue) {
                            const field = 'customField' + f.name.substring(13) as keyof MinJob;
                            (tempState as any)[field] = defaultValue.value;
                        }
                    }
                }
            }
            init(tempState);
        };
        jobId === 0 && fetchedConfig && setDefaultCreationValues();
    }, [jobId, clientId, contactId, siteId, jobType, fetchedConfig, availableCustomFields, init, defaultCurrency, defaultHoursPerDay, defaultDaysPerWeek, defaultSummary]);

    useEffect(() => {
        const getSavedState = async () => {
            setFetchingLayoutConfig(true);
            const elementsJson = await GetCustomerSettingBySettingName(layoutDataSettingName);
            if (elementsJson) {
                const panels = JSON.parse(elementsJson) as PanelModel[];
                setLayoutConfiguration(panels);
            }
            setFetchingLayoutConfig(false);
            setFetchedLayoutConfig(true);
        };
        layoutDataSettingName && getSavedState();
    }, [layoutDataSettingName]);

    useEffect(() => {
        const getSources = async () => {
            const res = await GetJobSources(errorHandler);
            if (res) setJobSources(res);
        };
        getSources();
    }, [errorHandler]);

    const jobSourcesOptions = useMemo<JSX.Element[]>(() => {
        const opts = jobSources.map(s => <MenuItem key={s} value={s}>{s}</MenuItem>);
        const noneOption = <MenuItem key="NONE-ELEMENT" value="">None</MenuItem>;
        if (existingJob && existingJob.source && !jobSources.includes(existingJob.source)) {
            const savedOpt = <MenuItem key={existingJob.source} value={existingJob.source}>{existingJob.source}</MenuItem>
            return [noneOption, ...opts, savedOpt];
        }
        return [noneOption, ...opts];
    }, [existingJob, jobSources]);

    useEffect(() => loadingHandler && loadingHandler(isFetchingJob || isFetchingConfig || fetchingLayoutConfig), [isFetchingJob, isFetchingConfig, fetchingLayoutConfig, loadingHandler]);

    useEffect(() => {
        if (jobId && existingJob && fetchedJob && fetchedConfig) {
            const mj = JobToMinJob(existingJob);
            init(mj);
            const api = editorRef.current;
            if (api) api.setContent(existingJob.description ?? '');
        }
    }, [jobId, existingJob, fetchedJob, fetchedConfig, init]);

    const saveChangesHandler = useCallback(async (isCopyTags?: boolean, isCopyDocuments?: boolean, isCopyCandidates?: boolean) => {
        if (!state.title.trim()) {
            setShowValidation(true);
            errorHandler && errorHandler("Title can't be empty");
            return false;
        }

        if (!state.source.trim()) {
            setShowValidation(true);
            errorHandler && errorHandler("Source can't be empty");
            return false;
        }
        
        if (state.clientID === 0) {
            setShowValidation(true);
            errorHandler && errorHandler("Client is required");
            return false;
        }
        
        if (state.contact1ID === 0) {
            setShowValidation(true);
            errorHandler && errorHandler("Contact is required");
            return false;
        }

        const hoursPerDay = state.hoursPerDay;
        if (!Boolean(hoursPerDay)) {
            setShowValidation(true);
            errorHandler && errorHandler("Hours Per Day field is required");
            return false;
        }
        else if(+hoursPerDay > 24 || +hoursPerDay < 0) {
            setShowValidation(true);
            errorHandler && errorHandler("Hours Per Day can't be greater tha 24");
            return false;
        }

        let emptyNumericUdfs: Partial<MinJob> = { };
        let nullDateUdfs: Partial<MinJob> = { };
        for (let i = 0; i < availableCustomFields.length; i++) {
            const u = availableCustomFields[i];
            if (!u.editable) continue;

            const customFieldNumber = u.name.substring(13);
            const customFieldKey = "customField" + customFieldNumber as keyof MinJob;
            const { format } = UdfJobFieldMapObj['CustomField' + customFieldNumber as CustomFieldType];
            const value = state[customFieldKey] as string | number | undefined;
            if (format === "number" && (value === '' || value === null || value === undefined)) (emptyNumericUdfs[customFieldKey] as any) = undefined;
            if ((format === "date" || format === 'datetime') && !Boolean(value)) (nullDateUdfs[customFieldKey] as any) = '0001-01-01T00:00:00';
            
            if (!customFieldValidateMap['CustomField' + customFieldNumber]) continue;

            const validation = IsValidCustomFieldValue(value, format, u.mandatory);

            if (validation === "required") {
                setShowValidation(true);
                errorHandler && errorHandler(`${u.agencyName} is required`);
                return false;
            }
            else if (validation === "invalid-number") {
                setShowValidation(true);
                errorHandler && errorHandler(`${u.agencyName} must have a valid numeric value`);
                return false;
            }
            else if (validation === "invalid-date") {
                setShowValidation(true);
                errorHandler && errorHandler(`${u.agencyName} must have a valid date value`);
                return false;
            }
            else if (validation === "range-date") {
                setShowValidation(true);
                errorHandler && errorHandler(`${u.agencyName} must be a date between 01-01-1753 and 31-12-9999`);
                return false;
            }
        }

        loadingHandler && loadingHandler(true);
        const editorApi = editorRef.current;
        const summaryContent = editorApi ? editorApi.getContent() : '';
        const startDate = state.startDate ? state.startDate : '0001-01-01T00:00:00';
        const deadline = state.deadline ? state.deadline : '0001-01-01T00:00:00';

        const feeValue = jobFeeValue === '' ? 0 : +jobFeeValue;
        let feePercent = 0;
        let feeFixed = 0;
        if (state.feeType !== 0) {
            if (state.feeType === 1) feePercent = feeValue;
            else if (state.feeType === 2) feeFixed = feeValue;
        }
        
        if (jobId === 0) {
            const res = await CreateJob({...state, description: summaryContent, startDate: startDate, deadline: deadline, permFeePercentage: feePercent, permFeeAmount: feeFixed, ...emptyNumericUdfs, ...nullDateUdfs }, errorHandler);
            if (!res) {
                loadingHandler && loadingHandler(false);
                return false;
            }
            setCreatedJobId(res.value);
        }
        else if (isCopy) {
            const t = isCopyTags ?? false;
            const d = isCopyDocuments ?? false;
            const c = isCopyCandidates ?? false;
            const res = await CopyJob(jobId, {...state, description: summaryContent, startDate: startDate, deadline: deadline, permFeePercentage: feePercent, permFeeAmount: feeFixed, ...emptyNumericUdfs, ...nullDateUdfs }, t, d, c, errorHandler);
            if (!res) {
                loadingHandler && loadingHandler(false);
                return false;
            }
            setCreatedJobId(res.value);
        }
        else if (jobId && (hasChanges || isEditorDirty || feeValue !== initialJobFeeValue)) {
            const res = await UpdateJob(jobId, {...state, description: summaryContent, startDate: startDate, deadline: deadline, permFeePercentage: feePercent, permFeeAmount: feeFixed, ...emptyNumericUdfs, ...nullDateUdfs }, errorHandler);
            if (!res) {
                loadingHandler && loadingHandler(false);
                return false;
            }
        }
        
        updateInitial();
        setIsEditorDirty(false);
        editorApi && editorApi.setDirty(false);
        loadingHandler && loadingHandler(false);
        setSavedChanges(true);
        return true;
    }, [state, loadingHandler, jobFeeValue, jobId, isCopy, hasChanges, isEditorDirty, initialJobFeeValue, updateInitial, errorHandler, availableCustomFields, customFieldValidateMap]);

    const onSaveClick = useCallback(async () => {
        if (isCopy) {
            setIsCopyJobDialogOpen(true);
            return false;
        }
        return saveChangesHandler();
    }, [isCopy, saveChangesHandler]);
    
    useEffect(() => {
        const actionButton = <Button variant="contained" color="success" disabled={ !hasChanges && +jobFeeValue === initialJobFeeValue && !isEditorDirty && !isCopy } onClick={onSaveClick}>Save</Button>
        if (jobId === 0 && setSummaryBar) {
            const sb = <TitleAndActionSummaryBar title="Jobs > Create" browserTabTitle="Create > Jobs" action={actionButton} />;
            setSummaryBar(sb);
        }
        else if (jobId && !isCopy && setSummaryBar) {
            const goBackAction = <Link to={`/jobs/${jobId}`} style={{ textDecoration: 'none' }}><Button variant="contained" color="error" sx={{ mr: '5px' }}>Cancel</Button></Link>;
            const sb = <TitleAndActionSummaryBar title="Jobs > Edit" browserTabTitle="Edit > Jobs" action={<>{goBackAction}{actionButton}</>} />;
            setSummaryBar(sb);
        }
        else if (jobId && isCopy && setSummaryBar) {
            const goBackAction = <Link to={`/jobs/${jobId}`} style={{ textDecoration: 'none' }}><Button variant="contained" color="error" sx={{ mr: '5px' }}>Cancel</Button></Link>;
            const sb = <TitleAndActionSummaryBar title="Jobs > Copy" browserTabTitle="Copy > Jobs" action={<>{goBackAction}{actionButton}</>} />;
            setSummaryBar(sb);
        }
    }, [jobId, hasChanges, isEditorDirty, jobFeeValue, initialJobFeeValue, setSummaryBar, onSaveClick, isCopy]);

    const onStringFieldChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        let val = value;
        if (name.startsWith('dtUDF') && value && !value.includes('T')) val += 'T00:00:00';
        change(name as keyof MinJob, val);
    }, [change]);

    const onBooleanFieldChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        change(name as keyof MinJob, value === '1');
    }, [change]);

    const onDateFieldChange = useCallback((m: moment.Moment | null, fieldName: string) => {
        if(m && m.isValid()) {
            change(fieldName as keyof MinJob, m.format('YYYY-MM-DD'))
        }
        else if (m === null) change(fieldName as keyof MinJob, '');
    }, [change]);

    const onDateTimeFieldChange = useCallback((m: moment.Moment | null, fieldName: string) => {
        if(m && m.isValid()) {
            change(fieldName as keyof MinJob, m.format('YYYY-MM-DDTHH:mm'));
        }
        else if (m === null) change(fieldName as keyof MinJob, '');
    }, [change]);

    const onNumericListFieldChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        change(name as keyof MinJob, +value);
    }, [change]);

    const onDecimalFieldChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        if (value === '') change(name as keyof MinJob, '');
        if (RegexIsPositiveNumberWith2Decimals(value)) change(name as keyof MinJob, value);
    }, [change]);

    const onChargeRateUnitsChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        change('chargeRateUnits', +value);
        change('rateUnits', +value);
        change('onCostsUnits', +value);
    }, [change]);

    const onNumberFieldChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        
        const n = +value;
        if (isNaN(n) && value !== '') return;
        if (value.length > 11) return;

        const i = value.indexOf('.');
        if (value.length > 8 && i === -1) return;

        if(i >= 0) {
            const decimals = value.substring(i + 1);
            if (decimals.length > 2) {
                change(name as keyof MinJob, value.substring(0, i + 3));
                return;
            }
        }

        change(name as keyof MinJob, value);

    }, [change]);

    const onFeeChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        
        if (value === '') setJobFeeValue('');
        if (RegexIsPositiveNumberWith2Decimals(value)) setJobFeeValue(value);
    }, []);

    const onSiteChange = useCallback((site: Site | null) => {
        if (site) {
            change('siteID', site.id,);
            change('siteName', site.name,);
            change('address1', site.address1);
            change('address2', site.address2);
            change('address3', site.address3);
            change('suburb', site.suburb);
            change('state', site.state);
            change('postcode', site.postcode);
            change('countryName', site.countryName ?? '');
        }
        else {
            change('siteID', 0);
            change('siteName', '',);
            change('address1', '');
            change('address2', '');
            change('address3', '');
            change('suburb', '');
            change('state', '');
            change('postcode', '');
            change('countryName', '');
        }

    }, [change]);

    const onDivisionChange = useCallback((divisionId: number | null) => {
        change('division', divisionId ? divisionId : 0);
    }, [change]);

    const onCurrencyChange = useCallback((cId: number | null) => {
        change('currencyID', cId ?? 0);
    }, [change]);

    const onClientChange = useCallback((client: Client | null) => {
        change('clientID', client ? client.id : 0);
    }, [change]);

    const onContactChange = useCallback((contact: Contact | null, fieldName: keyof MinJob) => {
        change(fieldName, contact ? contact.id : 0);
    }, [change]);

    const onConsultantChange = useCallback((user: NameIdObj | null, fieldName: keyof MinJob) => {
        change(fieldName, user ? user.id : 0);
    }, [change]);

    const onComplianceChecklistChange = useCallback((c: ComplianceChecklist | null) => {
        change('complianceChecklistID', c ? c.id : 0);
    }, [change])

    const { unsavedChangesDialog, hasBlockedRoute } = useUnsavedChangesDialog(hasChanges || isEditorDirty , onSaveClick);

    const redirectJobId = useMemo(() => {
        if (isCopy && savedChanges && createdJobId) return createdJobId;
        if (jobId !== 0) return jobId;
        if (savedChanges && createdJobId) return createdJobId;
        return 0;
    }, [isCopy, savedChanges, createdJobId, jobId]);

    const elements = useMemo<JobRecordDashboardElementDefinition[]>(() => {
        if (!fetchedLayoutConfig) return [];
        if (layoutConfiguration !== null) {
            const elements = GetPanelDefinitionsFromPanelModels(layoutConfiguration);
            return elements ;
        }
        return defaultElements;
    }, [layoutConfiguration, fetchedLayoutConfig, defaultElements]);

    const customFieldsSettingsMap = useMemo<CustomFieldSettingsMap>(() => {
        let obj: CustomFieldSettingsMap = {};
        availableCustomFields.forEach(u => {
            const customFieldNumber = u.name.substring(13);
            const key = 'CustomField' + customFieldNumber as CustomFieldType;
            obj[key] = {
                title: u.agencyName,
                isMultiLine: u.multiLine && !u.usePredefinedValues,
                isMandatory: u.mandatory,
                isEditable: u.editable,
                usePredefinedValues: u.usePredefinedValues,
                values: u.values
            }
        });
        return obj;
    }, [availableCustomFields]);

    useEffect(() => {
        if (elements.length > 0) {
            let vm: CustomFieldValidateMap = {};
            for (let i = 0; i < elements.length; i++) {
                const element = elements[i];
                if (element.id.startsWith('CustomField')) {
                    const split = element.id.split('_');
                    const key = split[0];
                    if (key) vm[key] = true;
                }
            }
            setCustomFieldValidateMap(vm);
        }
    }, [elements]);

    const renderElement = useCallback((id: string, type: JobRecordDashboardElementType) => {
        switch (type) {
            case 'Divider': return <Box pt="20px"><Divider component="div" /></Box>;
            case 'Spacer': return <></>;
            case 'JobId': return <SingleFieldElement useEllipsisForLongValues labelWidthPercent={30} fontSize="1rem" fieldTitle="ID" fieldValue={state.jobReference ? state.jobReference : state.id.toString()} />;
            case 'JobType': 
                let jobType = '';
                if (state.type === 'Contract') jobType = "Contract";
                else if (state.type === 'FixedContract') jobType = "Fixed Term";
                else if (state.type === 'Panel') jobType = "Panel";
                else if (state.type === 'Permanent') jobType = "Permanent";
                else if (state.type === 'TalentPool') jobType = "Talent Pool";
                return <SingleFieldElement labelWidthPercent={30} fontSize="1rem" fieldTitle="Job Type" fieldValue={jobType} helpInfo={"Job Type is updated using the 'Change Job Type' Action" } />;
            case 'JobWorkType': return (
                <EditableSingleFieldElement format="select" fieldTitle='Work Type' fieldValue={state.workTypeID.toString()} fieldName="workTypeID" onChangeHandler={onNumericListFieldChange} >
                    <MenuItem value="0">Select</MenuItem>
                    <MenuItem value="1">Casual</MenuItem>
                    <MenuItem value="2">Full Time</MenuItem>
                    <MenuItem value="3">Part Time</MenuItem>
                </EditableSingleFieldElement>
            );
            case 'JobTitle': return (
                <EditableSingleFieldElement
                    isError={showValidation && !state.title.trim()}
                    errorMessage="Required"
                    fieldTitle='Job Title'
                    fieldValue={state.title}
                    fieldName="title"
                    onChangeHandler={onStringFieldChange}
                />
            );
            case 'JobExclusive': return (
                <EditableSingleFieldElement format="select" fieldTitle='Exclusive' fieldValue={state.exclusive ? '1' : '0'} fieldName="exclusive" onChangeHandler={onBooleanFieldChange} >
                    <MenuItem value="0">No</MenuItem>
                    <MenuItem value="1">Yes</MenuItem>
                </EditableSingleFieldElement>
            );
            case 'JobQuantity': return (
                <EditableSingleFieldElement format="select" fieldTitle='Quantity' fieldValue={state.candidatesRequired.toString()} fieldName="candidatesRequired" onChangeHandler={onNumberFieldChange} >
                    {quantityOptions}
                </EditableSingleFieldElement>
            );
            case 'JobStartDate': return <EditableSingleFieldElement fieldTitle='Start Date' fieldValue={state.startDate} fieldName="startDate" onDateChangeHandler={onDateFieldChange} format="date" />;
            case 'JobStartTime': return <EditableSingleFieldElement fieldTitle='Start Time' fieldValue={state.startTime} fieldName="startTime" onDateChangeHandler={onDateTimeFieldChange} format="time" />;
            case 'JobDuration': return (
                <EditableSingleFieldElement format="custom" fieldTitle='Duration'>
                    <Box display="flex">
                        <RWTextFieldComponent value={state.duration?.toString()} name="duration" variant="standard" sxOptions={{ flex: '1 1 0', pr: '5px' }} onChange={ onNumberFieldChange } />
                        <TextField select value={state.durationPeriod} name="durationPeriod" variant="standard" sx={{ flex: '1 1 0', pl: '5px' }} onChange={ onNumberFieldChange } >
                            <MenuItem value="0">Select</MenuItem>
                            <MenuItem value="1">Hours</MenuItem>
                            <MenuItem value="2">Days</MenuItem>
                            <MenuItem value="3">Weeks</MenuItem>
                            <MenuItem value="4">Months</MenuItem>
                            <MenuItem value="5">Years</MenuItem>
                        </TextField>
                    </Box>
                </EditableSingleFieldElement>
            );
            case 'JobDeadline': return <EditableSingleFieldElement fieldTitle='Deadline' fieldValue={state.deadline} fieldName="deadline" onDateChangeHandler={onDateFieldChange} format="date" disabled={!isDeadlineEnabled} />;
            case 'JobDivision': return (
                <EditableSingleFieldElement format="custom" fieldTitle='Division' >
                    <DivisionPicker divisionId={state.division} onSelectCallback={onDivisionChange} hideLabel variant="standard" />
                </EditableSingleFieldElement>
            );
            case 'JobSalaryPackage': return (
                <EditableSingleFieldElement format="custom" fieldTitle='Salary Package'>
                    <Box display="flex">
                        <RWTextFieldComponent value={state.salaryFrom?.toString()} name="salaryFrom" variant="standard" placeholder="From" sxOptions={{ flex: '1 1 0', pr: '5px' }} onChange={ onNumberFieldChange } />
                        <RWTextFieldComponent value={state.salaryTo?.toString()} name="salaryTo" variant="standard" placeholder="To" sxOptions={{ flex: '1 1 0', pr: '5px' }} onChange={ onNumberFieldChange } />
                        <TextField select value={state.salaryUnits} name="salaryUnits" variant="standard" sx={{ flex: '1 1 0', pl: '5px' }} onChange={ onNumberFieldChange } >
                            {timePeriodOptions}
                        </TextField>
                    </Box>
                </EditableSingleFieldElement>
            );
            case 'JobFee':
                return (
                    <EditableSingleFieldElement format="custom" fieldTitle='Fee'>
                        <Box display="flex">
                            <RWTextFieldComponent value={jobFeeValue} variant="standard" sxOptions={{ flex: '1 1 0', pr: '5px' }} onChange={ onFeeChange } />
                            <TextField select value={state.feeType.toString()} name="feeType" variant="standard" sx={{ flex: '1 1 0', pl: '5px' }} onChange={ onNumericListFieldChange } >
                                <MenuItem value="0">Select</MenuItem>
                                <MenuItem value="1">Percentage</MenuItem>
                                <MenuItem value="2">Fixed</MenuItem>
                            </TextField>
                        </Box>
                    </EditableSingleFieldElement>
                );
            case 'JobChargeRate': return (
                <EditableSingleFieldElement format="custom" fieldTitle='Charge Rate'>
                    <Box display="flex">
                        <RWTextFieldComponent value={state.chargeRateFrom?.toString()} name="chargeRateFrom" variant="standard" placeholder="From" sxOptions={{ flex: '1 1 0', pr: '5px' }} onChange={ onNumberFieldChange } />
                        <RWTextFieldComponent value={state.chargeRateTo?.toString()} name="chargeRateTo" variant="standard" placeholder="To" sxOptions={{ flex: '1 1 0', pr: '5px' }} onChange={ onNumberFieldChange } />
                        <TextField select value={state.chargeRateUnits.toString()} name="chargeRateUnits" variant="standard" sx={{ flex: '1 1 0', pl: '5px' }} onChange={ onChargeRateUnitsChange } >
                            {timePeriodOptions}
                        </TextField>
                    </Box>
                </EditableSingleFieldElement>
            );
            case 'JobPayRate': return (
                <EditableSingleFieldElement format="custom" fieldTitle='Pay Rate'>
                    <Box display="flex">
                        <TextField value={state.rateFrom} name="rateFrom" variant="standard" placeholder="From" sx={{ flex: '1 1 0', pr: '5px' }} onChange={ onNumberFieldChange } />
                        <TextField value={state.rateTo} name="rateTo" variant="standard" placeholder="To" sx={{ flex: '1 1 0', pr: '5px' }} onChange={ onNumberFieldChange } />
                        <TextField select value={state.rateUnits.toString()} name="rateUnits" variant="standard" sx={{ flex: '1 1 0', pl: '5px' }} onChange={ onNumericListFieldChange } >
                            {timePeriodOptions}
                        </TextField>
                    </Box>
                </EditableSingleFieldElement>
            );
            case 'JobOnCosts': return (
                <EditableSingleFieldElement format="custom" fieldTitle='On Costs'>
                    <Box display="flex">
                        <RWTextFieldComponent value={state.onCostsFrom?.toString()} name="onCostsFrom" variant="standard" placeholder="From" sxOptions={{ flex: '1 1 0', pr: '5px' }} onChange={ onNumberFieldChange } />
                        <RWTextFieldComponent value={state.onCostsTo?.toString()} name="onCostsTo" variant="standard" placeholder="To" sxOptions={{ flex: '1 1 0', pr: '5px' }} onChange={ onNumberFieldChange } />
                        <TextField select value={state.onCostsUnits.toString()} name="onCostsUnits" variant="standard" sx={{ flex: '1 1 0', pl: '5px' }} onChange={ onNumericListFieldChange } >
                            {timePeriodOptions}
                        </TextField>
                    </Box>
                </EditableSingleFieldElement>
            );
            case 'JobComments': return <EditableSingleFieldElement fieldTitle='Comments' fieldValue={state.remunerationComment} fieldName="remunerationComment" onChangeHandler={onStringFieldChange} />;
            case 'JobCurrency': return (
                <EditableSingleFieldElement format="custom" fieldTitle='Currency' >
                    <CurrencyPicker
                        hideLabel
                        variant="standard"
                        currencyId={state.currencyID}
                        onSelectCallback={ onCurrencyChange }
                    />
                </EditableSingleFieldElement>
            );
            case 'JobSource':
                const isSourceEmpty = !Boolean(state.source.trim());
                return (
                    <EditableSingleFieldElement format="select" fieldTitle='Source' fieldValue={state.source} fieldName="source" isError={showValidation && isSourceEmpty} errorMessage="Required" onChangeHandler={onStringFieldChange} >
                        {jobSourcesOptions}
                    </EditableSingleFieldElement>
                );
            case 'JobLocation': return (
                <EditableClientLocationElement
                    siteId={state.siteID}
                    siteName={state.siteName}
                    address1={state.address1}
                    address2={state.address2}
                    address3={state.address3}
                    suburb={state.suburb}
                    state={state.state}
                    postcode={state.postcode}
                    country={state.countryName}
                    clientId={state.clientID}
                    onSiteChange={onSiteChange}
                    errorHandler={errorHandler}
                    loadingHandler={loadingHandler}
                    successHandler={successHandler}
                />
            );
            case 'JobClient': return (
                <EditableSingleFieldElement isError={showValidation && state.clientID === 0} errorMessage="Required" format="custom" fieldTitle='Client' >
                    <ClientPicker
                        hideLabel
                        variant="standard"
                        value={state.clientID}
                        onSelectCallback={ onClientChange }
                        errorHandler={errorHandler}
                        loadingHandler={loadingHandler}
                        successHandler={successHandler}
                    />
                </EditableSingleFieldElement>
            );
            case 'JobContact': return (
                <EditableSingleFieldElement isError={showValidation && state.contact1ID === 0} errorMessage="Required" format="custom" fieldTitle='Contact' >
                    <ContactPicker
                        hideLabel
                        variant="standard"
                        value={state.contact1ID} onSelectCallback={ c => onContactChange(c, 'contact1ID') }
                        errorHandler={errorHandler}
                        loadingHandler={loadingHandler}
                        successHandler={successHandler}
                    />
                </EditableSingleFieldElement>
            );
            case 'JobOtherContact': return (
                <EditableSingleFieldElement format="custom" fieldTitle='Other Contact' >
                    <ContactPicker
                        hideLabel
                        variant="standard"
                        value={state.contact4ID} onSelectCallback={ c => onContactChange(c, 'contact4ID') }
                        errorHandler={errorHandler}
                        loadingHandler={loadingHandler}
                        successHandler={successHandler}
                    />
                </EditableSingleFieldElement>
            );
            case 'JobHiringManager': return (
                <EditableSingleFieldElement format="custom" fieldTitle='Hiring Mgr' >
                    <ContactPicker
                        hideLabel
                        variant="standard"
                        value={state.contact2ID} onSelectCallback={ c => onContactChange(c, 'contact2ID') }
                        errorHandler={errorHandler}
                        loadingHandler={loadingHandler}
                        successHandler={successHandler}
                    />
                </EditableSingleFieldElement>
            );
            case 'JobBilling1': return (
                <EditableSingleFieldElement format="custom" fieldTitle='Billing 1' >
                    <ContactPicker
                        hideLabel
                        variant="standard"
                        value={state.contact3ID} onSelectCallback={ c => onContactChange(c, 'contact3ID') }
                        errorHandler={errorHandler}
                        loadingHandler={loadingHandler}
                        successHandler={successHandler}
                    />
                </EditableSingleFieldElement>
            );
            case 'JobClientRef': return <EditableSingleFieldElement fieldTitle='Client Ref' fieldValue={state.clientReference} fieldName="clientReference" onChangeHandler={onStringFieldChange} />;
            case 'JobVideoLink': return <EditableSingleFieldElement fieldTitle='Video Link' fieldValue={state.videoLink} fieldName="videoLink" onChangeHandler={onStringFieldChange} />;
            case 'JobRating': return (
                <EditableSingleFieldElement format="select" fieldTitle='Rating' fieldValue={state.rating.toString()} fieldName="rating" onChangeHandler={onNumericListFieldChange} >
                    <MenuItem value="0">None</MenuItem>
                    <MenuItem value="1">A</MenuItem>
                    <MenuItem value="2">B</MenuItem>
                    <MenuItem value="3">C</MenuItem>
                </EditableSingleFieldElement>
            );
            case 'JobSummary': return (
                <EditableRichTextElement
                    title="Summary"
                    content={state.description}
                    isDarkTheme={theme.palette.mode === 'dark'}
                    editorRef={editorRef}
                    editorDirtyHandler={ setIsEditorDirty }
                />
            );
            case 'JobStatus': return <SingleFieldElement useEllipsisForLongValues labelWidthPercent={30} fontSize="1rem" fieldTitle="Status" fieldValue={state.statusName} />;
            case 'JobStage': return <SingleFieldElement useEllipsisForLongValues labelWidthPercent={30} fontSize="1rem" fieldTitle="Stage" fieldValue={state.stageName} />;
            case 'JobOutcome': return <SingleFieldElement useEllipsisForLongValues labelWidthPercent={30} fontSize="1rem" fieldTitle="Outcome" fieldValue={getJobOutcomeName(state.outcome)} />;
            case 'JobHoursPerDay': return <EditableSingleFieldElement fieldTitle='Hours Per Day' fieldValue={state.hoursPerDay.toString()} fieldName="hoursPerDay" onChangeHandler={onDecimalFieldChange} isError={ showValidation && (!Boolean(state.hoursPerDay) || +state.hoursPerDay > 24) } errorMessage={ Boolean(state.hoursPerDay) ? 'Not valid' : 'Required' } />
            case 'JobDaysPerWeek': return (
                <EditableSingleFieldElement format="custom" fieldTitle='Days Per Week'>
                    <RWTextFieldComponent
                        name="daysPerWeek"
                        variant="standard"
                        value={state.daysPerWeek.toString()}
                        onChange={onStringFieldChange}
                        validator={daysPerWeekValidator}
                    />
                </EditableSingleFieldElement>
            );
            case 'JobConsultant1': return (
                <EditableSingleFieldElement format="custom" fieldTitle='Consultant 1' >
                    <UserPicker
                        hideLabel
                        variant="standard"
                        userId={state.consultantID}
                        onSelect={ u => onConsultantChange(u, 'consultantID') }
                    />
                </EditableSingleFieldElement>
            );
            case 'JobConsultant2': return (
                <EditableSingleFieldElement format="custom" fieldTitle='Consultant 2' >
                    <UserPicker
                        hideLabel
                        variant="standard"
                        userId={state.consultantID2}
                        onSelect={ u => onConsultantChange(u, 'consultantID2') }
                    />
                </EditableSingleFieldElement>
            );
            case 'JobComplianceChecklist': return (
                <EditableSingleFieldElement format="custom" fieldTitle="Compliance Checklist">
                    <ComplianceChecklistPicker checklistId={state.complianceChecklistID} onSelect={onComplianceChecklistChange} variant="standard" />
                </EditableSingleFieldElement>
            );
            default: 
            if (type.startsWith('CustomField')) {
                const settings = customFieldsSettingsMap[type];
                if (settings && settings.isEditable) {
                    let title = settings.title;
                    let predefinedValues = settings.usePredefinedValues && settings.values ? settings.values : null;
                    const { field, format } = UdfJobFieldMapObj[type as CustomFieldType];
                    const rawValue = state[field as keyof MinJob] as string | number | undefined;
                    const udfValue = (state[field as keyof MinJob] ?? '').toString();
                    const validationResult = IsValidCustomFieldValue(rawValue, format, settings.isMandatory ?? false);
                    const isValid = validationResult === "ok";
                    const errorMessage = validationErrorMessage(validationResult);
                    if (predefinedValues) {
                        return (
                            <EditableSingleFieldElement isError={showValidation && !isValid} errorMessage={errorMessage} format="select" fieldTitle={title} fieldValue={udfValue} fieldName={field} onChangeHandler={onStringFieldChange}>
                                <MenuItem value="">None</MenuItem>
                                {predefinedValues.map(v => <MenuItem key={v.value} value={v.value}>{v.value + (v.isDefault ? ' (Default)' : '')}</MenuItem>)}
                            </EditableSingleFieldElement>
                        );
                    }
                    else if (udfValue !== undefined && format === 'string') {
                        return (
                            <EditableSingleFieldElement
                                isError={showValidation && !isValid}
                                errorMessage={errorMessage}
                                format={format}
                                fieldTitle={title}
                                fieldValue={udfValue}
                                fieldName={field}
                                multiline={settings.isMultiLine}
                                onChangeHandler={onStringFieldChange}
                            />
                        );
                    }
                    else if (udfValue !== undefined && format === 'number') {
                        return (
                            <EditableSingleFieldElement
                                isError={showValidation && !isValid}
                                errorMessage={errorMessage}
                                format={format}
                                fieldTitle={title}
                                fieldValue={udfValue}
                                fieldName={field}
                                onChangeHandler={onNumberFieldChange}
                            />
                        );
                    }
                    else if (format === 'date') {
                        return (
                            <EditableSingleFieldElement
                                isError={showValidation && !isValid}
                                errorMessage={errorMessage}
                                format={format}
                                fieldTitle={title}
                                fieldValue={udfValue}
                                fieldName={field}
                                onDateChangeHandler={onDateFieldChange}
                            />
                        );
                    }
                    else if (format === 'datetime') {
                        return (
                            <EditableSingleFieldElement
                                isError={showValidation && !isValid}
                                errorMessage={errorMessage}
                                format={format}
                                fieldTitle={title}
                                fieldValue={udfValue}
                                fieldName={field}
                                onDateChangeHandler={onDateTimeFieldChange}
                            />
                        );
                    }
                }
                else if (settings) return <SingleFieldElement labelWidthPercent={30} fontSize="1rem" fieldTitle={settings.title} fieldValue="[ Non Editable Custom Field ]" />;
                else return <SingleFieldElement labelWidthPercent={30} fontSize="1rem" fieldTitle={type} fieldValue="[ Disabled Custom Field ]" />;
            }
                return <div>{id}</div>;
        }
    }, [customFieldsSettingsMap, errorHandler, isDeadlineEnabled, jobFeeValue, jobSourcesOptions, loadingHandler, onBooleanFieldChange, onChargeRateUnitsChange, onClientChange, onComplianceChecklistChange, onConsultantChange, onContactChange, onCurrencyChange, onDateFieldChange, onDateTimeFieldChange, onDecimalFieldChange, onDivisionChange, onFeeChange, onNumberFieldChange, onNumericListFieldChange, onSiteChange, onStringFieldChange, showValidation, state, successHandler, theme.palette.mode]);

    const layoutResizeStopHandler = useCallback(() => setScreenResizedControl(prev => !prev), []);

    useEffect(() => {
        if (screenResizedControl) {}
        const api = layoutRef.current;
        if (api && fetchedLayoutConfig) {
            const refreshTimeout = setTimeout(() => {
                api.refresh();
                api.refresh();
            }, 250);
            return () => clearTimeout(refreshTimeout);
        }
    }, [fetchedLayoutConfig, screenResizedControl]);

    useEffect(() => {
        window.addEventListener('resize', layoutResizeStopHandler);
        return () => window.removeEventListener('resize', layoutResizeStopHandler);
    }, [layoutResizeStopHandler]);

    const renderLayout = useCallback(() => {
        if (!fetchedLayoutConfig) return <></>;
        
        let sorted = [...elements];
        sorted.sort((a, b) => {
            if (a.col === b.col) return a.row < b.row ? -1 : 1;
            else return a.col < b.col ? -1 : 1;
        });

        const mediaQuery = mediaQueryMaxWidth ? `max-width: ${mediaQueryMaxWidth}` : undefined;
        return (
            <DashboardLayoutComponent
                cellSpacing={cellSpacing}
                columns={columns}
                cellAspectRatio={30 / 2}
                allowDragging={false}
                allowResizing={false}
                resizeStop={ layoutResizeStopHandler }
                ref={l => layoutRef.current = l}
                mediaQuery={mediaQuery}
            >
                {sorted.map(e => (
                    <PanelWrapper
                        key={e.id}
                        id={e.id}
                        col={e.col}
                        row={e.row}
                        sizeX={e.sizeX}
                        sizeY={e.sizeY}
                        minSizeX={e.minSizeX}
                        minSizeY={e.minSizeY}
                        maxSizeX={e.maxSizeX}
                        maxSizeY={e.maxSizeY}
                        resizeControl={screenResizedControl}
                        resizeIconColor={theme.palette.text.disabled}
                        gapX={gapX}
                        gapY={gapY}
                        unitWidth={unitWidth}
                        unitHeight={unitHeight}
                    >
                        {renderElement(e.id, e.type)}
                    </PanelWrapper>
                ))}
            </DashboardLayoutComponent>
        );
    }, [elements, fetchedLayoutConfig, theme.palette.text.disabled, screenResizedControl, layoutResizeStopHandler, renderElement]);

    const confirmJobCopyHandler = useCallback((isCopyTags: boolean, isCopyDocuments: boolean, isCopyCandidates: boolean) => {
        saveChangesHandler(isCopyTags, isCopyDocuments, isCopyCandidates);
    }, [saveChangesHandler]);

    return (
        <>
            {unsavedChangesDialog}
            { !hasBlockedRoute && redirectJobId !== 0 && savedChanges && !hasChanges && !isEditorDirty && <Navigate to={`/jobs/${redirectJobId}`} /> }
            <CopyJobDialog
                open={isCopyJobDialogOpen}
                closeHandler={() => setIsCopyJobDialogOpen(false)}
                continueHandler={confirmJobCopyHandler}
            />
            <Box p="10px" height="100%">
                <div className="control-section">
                    { renderLayout() }
                </div>
            </Box>
        </>
    );
}