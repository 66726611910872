import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import React, { useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import { ClientHierarchy } from "common/models/Clients";

interface Props {
    open: boolean,
    hierarchyData: ClientHierarchy[],
    closeHandler: () => void,
    selectedClientId: number
}

interface HierarchyItem {
    id: number,
    name: string,
    children: HierarchyItem[]
}

function MakeTree(items: ClientHierarchy[], parentId: number = 0): HierarchyItem[] {
    return items.filter(i => i.parentID === parentId).map(i => {
        return { id: i.id, name: i.name, children: MakeTree(items, i.id) };
    });
}

export default function ClientHierarchyDialog({ open, hierarchyData, selectedClientId, closeHandler }: Props) {
    const hierarchy = useMemo<HierarchyItem[]>(() => {
        if (hierarchyData.length === 0) return [];
        const treeData = MakeTree(hierarchyData);
        return treeData;
    }, [hierarchyData]);

    const renderNodes = useCallback((item: HierarchyItem) => {
        const isSelected = item.id === selectedClientId;
        const clientName = isSelected ?
            <Box component="span" color="text.primary">{item.name}{' '}( Selected Client )</Box> :
            <Link onClick={closeHandler} to={`/clients/${item.id}`} style={{ color: 'inherit', textDecoration: 'none' }}>{item.name}</Link>;
        return (
            <li key={item.id}>
                {clientName}
                {item.children.length > 0 && (
                    <ul>
                        {item.children.map(i => renderNodes(i))}
                    </ul>
                )}
            </li>
        );
    }, [selectedClientId, closeHandler]);

    return (
        <Dialog open={open} fullWidth>
            <DialogTitle>Client Hierarchy</DialogTitle>
            <DialogContent>
                <Box component="ul" color="primary.main">
                    {hierarchy.length > 0 && hierarchy.map(h => renderNodes(h))}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="success" onClick={ closeHandler }>Ok</Button>
            </DialogActions>
        </Dialog>
    );
}