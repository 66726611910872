import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import React, { useEffect, useMemo, useState } from "react";
import { ReferenceCheckTemplate } from "common/models/Templates/ReferenceCheckTemplate";
import { GetAvailableReferenceCheckTemplates } from "services/WorkflowService";

interface Props {
    onSelectCallback: (template: ReferenceCheckTemplate | null) => void,
    templateId: number | null,
    userId: number,
    label?: string
}

export default function ReferenceCheckTemplatePicker({ templateId, userId, label = "Reference Check Template", onSelectCallback }: Props) {
    const [options, setOptions] = useState<ReferenceCheckTemplate[]>([]);

    useEffect(() => {
        const getData = async () => {
            const data = await GetAvailableReferenceCheckTemplates(userId);
            if (data) setOptions(data);
        };
        getData();
    }, [userId]);

    const template = useMemo(() => {
        if (templateId === null) return null;
        const template = options.find(t => t.id === templateId);
        if (template) return template;
        return null;
    }, [options, templateId]);

    return (
        <Autocomplete
            size="small"
            value={template}
            options={options}
            getOptionLabel={o => o.name}
            isOptionEqualToValue={ (o,v) => o.id === v.id }
            onChange={ (e,v) => onSelectCallback(v) }
            renderInput={ params => <TextField {...params} label={label} inputProps={{ ...params.inputProps, "data-lpignore": "true" }} /> }
        />
    );
}