import { PlacementsConfigOptions } from "common/models/Configuration/Placements";
import { ChangeTracker } from '../../../common/models/hooks/ChangeTracker';

export const DefaultOptions: PlacementsConfigOptions = {
    BulkPlacements: 'False',
    DefaultPayFrequency: '2',
    DefaultWarrantyPeriod: '6',
    HideExcludingSuper: 'False',
    PermExclusionPeriod: '24',
    PlacementAutoExportOnApprovalContract: 'False',
    PlacementAutoExportOnApprovalPermanent: 'False',
    PlacementCandidateConfirmation: 'True',
    PlacementDefaultPayBillUnits: '0',
    PlacementDefaultNoticePeriodUnits: '2',
    PlacementDefaultPaymentType: '0',
    PlacementOnCostsPercentage: '0',
    PlacementOptionalEndDate: 'False',
    PlacementRatesCalculator: '',
    PlacementsGenerateDocsAfterApproval: 'False',
    PlacementsSingleBackOfficeEntity: 'False',
    PlacementsNewWorkflow: 'False',
    PlacementsPredefinedSources: 'False',
    PlacementsSeperatePayBillDivision: 'False',
    PlacementsCheckForRefCheck: 'False',
    PlacementApprovalNotificationForConsultants: "True",
    BackOfficeErrorsNotification: "",
    PlacementsWhoCanCancelAndTerminate: "Anyone",
    PlacementDefaultSummary: '',
}

export const NoChangesTracker: ChangeTracker<PlacementsConfigOptions> = {
    BulkPlacements: false,
    DefaultPayFrequency: false,
    DefaultWarrantyPeriod: false,
    HideExcludingSuper: false,
    PermExclusionPeriod: false,
    PlacementAutoExportOnApprovalContract: false,
    PlacementAutoExportOnApprovalPermanent: false,
    PlacementCandidateConfirmation: false,
    PlacementDefaultPayBillUnits: false,
    PlacementDefaultNoticePeriodUnits: false,
    PlacementDefaultPaymentType: false,
    PlacementOnCostsPercentage: false,
    PlacementOptionalEndDate: false,
    PlacementRatesCalculator: false,
    PlacementsGenerateDocsAfterApproval: false,
    PlacementsSingleBackOfficeEntity: false,
    PlacementsNewWorkflow: false,
    PlacementsPredefinedSources: false,
    PlacementsSeperatePayBillDivision: false,
    PlacementsCheckForRefCheck: false,
    PlacementApprovalNotificationForConsultants: false,
    BackOfficeErrorsNotification: false,
    PlacementsWhoCanCancelAndTerminate: false,
    PlacementDefaultSummary: false,
}

export enum PlacementStatusEnum {
    Future = 4,
    Warranty = 5,
    Current = 6,
    Completed = 8,
    Deleted = 10,
    PendingCancellation = 11,
    Cancelled = 12,
    PendingEarlyTermination = 13,
    EarlyTerminated = 14
}

export enum ChangeRequestDecisionEnum
{
    Pending = 0,
    Approved = 1,
    Rejected = 2,
    Reversed = 3,
    Rescinded = 4
}

export enum ChangeRequestTypeEnum
{
    RateChange = 1,
    ConvertToPerm = 2,
    ConsultantsChange = 3,
    Extension = 4,
    StartDateChange = 5,
    PaymentType = 6,
    RateCorrection = 7,
}