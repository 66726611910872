import { useEffect, useMemo, useState } from "react";
import { GetJobsSettings } from "services/ConfigurationService";
import { GetWorkflowItemsOrder } from "services/WorkflowService";

export default function useWorkflowOrder() {
    const [workflowSortType, setWorkflowSortType] = useState<'alphabetical' | 'workflow'>('alphabetical');
    const [workflowOrder, setWorkflowOrder] = useState<number[]>([]);
    
    useEffect(() => {
        const getWorkflowConfig = async () => {
            const jobSettings = await GetJobsSettings();
            if (jobSettings) {
                const orderSetting = jobSettings.find(s => s.type === 'WorkflowSortOrder');
                if (orderSetting && orderSetting.value === '1') {
                    setWorkflowSortType('workflow');
                    const order = await GetWorkflowItemsOrder();
                    if (order) setWorkflowOrder(order.map(o => o.statusID));
                }
            }
        };
        getWorkflowConfig();
    }, []);

    const indexes = useMemo(() => {
        let offerIndex: number | undefined = undefined;
        let interviewIndex: number | undefined = undefined;
        let refCheckIndex: number | undefined = undefined;
        let submitIndex: number | undefined = undefined;
        let indexes: Record<number, (number | undefined)> = {};

        for (let i = 0; i < workflowOrder.length; i++) {
            const w = workflowOrder[i];
            if (offerIndex === undefined && (w === 8 || w === 13)) offerIndex = i;
            else if (submitIndex === undefined && w === 6) submitIndex = i;
            else if (interviewIndex === undefined && (w === 7 || w === 14 || w === 15 || w === 16 ||w === 17)) interviewIndex = i;
            else if (refCheckIndex === undefined && w === 9) refCheckIndex = i;
            if (w === 3 || w === 13 || w === 18 || w === 4 || w === 10) indexes[w] = i;
        }

        return {
            sortType: workflowSortType,
            offerIndex,
            interviewIndex,
            refCheckIndex,
            submitIndex,
            indexes
        };

    }, [workflowOrder, workflowSortType]);

    return indexes;
}