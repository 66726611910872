import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { SxProps } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import UserPicker from "components/Pickers/UserPicker";
import OnlyTitleSummaryBar from "components/SummaryBars/OnlyTitleSummaryBar";
import PageContentLayout from "layouts/PageContentLayout";
import PageLayout from "layouts/PageLayout";
import Snackbar from "@mui/material/Snackbar";
import Alert from "components/Alert";
import { NameIdObj } from "common/models/GenericTypes";
import { MeetingSearch } from "common/models/Search/MeetingSearch";
import MeetingsGridComponent from "components/Grids/MeetingsGrid";

const getMeetingType = (t: string | null): string => {
    if(t === "1") return ": Recently Completed";
    if(t === "2") return ": Upcoming";
    return "";
}

const ownerSpecialOptions: NameIdObj[] = [
    { id: 0, name: 'Me' },
    { id: -1, name: 'My Team' },
    { id: -2, name: 'My Company' },
];

const filterElementStyle: SxProps = { flex: '1 1 0', mr: 1 };

export default function Meetings() {
    const [showGrid, setShowGrid] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [searchParams] = useSearchParams();
    const [consultantId, setConsultantId] = useState(-1)
    const [timeRange, setTimeRange] = useState(11);
    const [status, setStatus] = useState(1);
    const [searchData, setSearchData] = useState<MeetingSearch>();
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const reportType = useMemo(() => {
        const param = searchParams.get('t');
        if (param === '1' || param === '2') return param;
        return null;
    }, [searchParams]);

    const summaryBar = useMemo(() => {
        if (reportType === "1") {
            setTimeRange(5);
            setStatus(2);
        }
        else if (reportType === "2") {
            setTimeRange(2);
            setStatus(1);
        }
        return <OnlyTitleSummaryBar title={`Meetings${getMeetingType(reportType)}`} />;
    }, [reportType]);

    useEffect(() => {
        if (reportType) {
            const s: MeetingSearch = {
                consultantId: -1,
                timeRange: reportType === '1' ? 5 : 2,
                status: reportType === '1' ? 2 : 1
            };

            setShowGrid(true);
            setSearchData(s);
        }
    }, [reportType]);

    const searchCallback = useCallback(() => {
        let s: MeetingSearch = {
            consultantId: consultantId,
            timeRange: timeRange,
            status: status
        };

        setSearchData(s);
        setShowGrid(true);
    }, [consultantId, status, timeRange]);

    const excelFileName = useMemo(() => {
        if(reportType === '1') return 'Meetings_RecentlyCompleted';
        if (reportType === '2') return 'Meetings_Upcoming';
    }, [reportType]);

    return(
        <PageLayout SummaryBar={summaryBar}>
            <Snackbar open={successMessage !== ''} autoHideDuration={3000} onClose={() => setSuccessMessage('')}>
                <Alert onClose={() => setSuccessMessage('')}>Interview Canceled</Alert>
            </Snackbar>
            <Snackbar open={errorMessage !== ''}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <PageContentLayout showLoading={isLoading}>
                <Box display="flex" mb={2}>
                    <Box flex="1 1 0" pr={1}>
                        <UserPicker disableClearable label="Consultant" userId={consultantId} onSelect={u => setConsultantId( u ? u.id : 0 )} appendToStart={ownerSpecialOptions} />
                    </Box>
                    <TextField select label="Time Range" value={timeRange.toString()} onChange={ ({target}) => setTimeRange(+target.value) } sx={filterElementStyle}>
                        <MenuItem value="11">Today</MenuItem>
                        <MenuItem value="1">This Week</MenuItem>
                        <MenuItem value="2">This Month</MenuItem>
                        <MenuItem value="4">This Year</MenuItem>
                        <MenuItem value="10">Yesterday</MenuItem>
                        <MenuItem value="5">Last Week</MenuItem>
                        <MenuItem value="6">Last Month</MenuItem>
                        <MenuItem value="8">Last Year</MenuItem>
                        <MenuItem value="12">Tomorrow</MenuItem>
                        <MenuItem value="9">Future</MenuItem>
                    </TextField>
                    <TextField select label="Status" value={status.toString()} onChange={ ({target}) => setStatus(+target.value) } sx={filterElementStyle}>
                        <MenuItem value="999">All</MenuItem>
                        <MenuItem value="1">Open</MenuItem>
                        <MenuItem value="3">Cancelled</MenuItem>
                        <MenuItem value="2">Closed</MenuItem>
                    </TextField>
                    <Button variant="contained" color="success" onClick={searchCallback}>Run Report</Button>
                </Box>
                {showGrid && searchData &&
                    <MeetingsGridComponent
                        gridName="meetings-search"
                        source="search"
                        excelName={excelFileName}
                        searchData={searchData}
                        loadingHandler={setIsLoading}
                        errorHandler={setErrorMessage}
                        successHandler={setSuccessMessage}
                    />
                }
            </PageContentLayout>
        </PageLayout>
    );
}