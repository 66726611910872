import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { PanelModel } from "@syncfusion/ej2-layouts/src/dashboard-layout/dashboard-layout-model";
import { DashboardLayoutComponent } from "@syncfusion/ej2-react-layouts/src/dashboard-layout/dashboardlayout.component";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import * as tinymce from 'tinymce';
import { CandidateList } from "common/models/Candidates/CandidateList";
import { Client } from "common/models/Clients";
import { CustomField, CustomFieldPredefinedValue } from "common/models/Configuration/CustomFields";
import { Contact } from "common/models/Contacts";
import { OpportunityRecordDashboardElementDefinition, OpportunityRecordDashboardElementType } from "common/models/Dashboard/EditLayout";
import { MinOpportunity, Opportunity, OpportunityOwner } from "common/models/Opportunities";
import { CustomFieldSettingsMap, CustomFieldType } from "common/models/ScreenLayouts/CustomFields";
import useObjectStateWithChangeTracker from "hooks/UseObjectStateWithChangeTracker";
import useUnsavedChangesDialog from "hooks/UseUnsavedChangesDialog";
import { GetCustomerSettingBySettingName } from "services/ConfigurationService";
import { GetCustomFieldsByEntity_OnlyActive, GetPredefinedValues } from "services/CustomFieldsService";
import { CreateOpportunity, GetOpportunityById, GetOpportunityOwners, SetOpportunityOwners, UpdateOpportunity } from "services/OpportunitiesService";
import { GetMySettings, GetMyUser } from "services/UsersService";
import { DefaultMinOpportunity, DefaultMinOpportunityNoChanges, OpportunityToMinOpportunity } from "util/Definitions/Opportunities";
import { IsValidCustomFieldValue, UdfOpportunityFieldMapObj } from "util/Definitions/ScreenLayouts/CustomFields";
import { DefaultOpportunityRecordDashboardElements_BackFill, DefaultOpportunityRecordDashboardElements_Bid, DefaultOpportunityRecordDashboardElements_Float, DefaultOpportunityRecordDashboardElements_General, DefaultOpportunityRecordDashboardElements_Lead, DefaultOpportunityRecordDashboardElements_Tender, GetPanelDefinitionsFromPanelModels, OpportunityScreenLayoutSettings } from "util/Definitions/ScreenLayouts/Opportunity";
import { IsValidNumericValue } from "util/RegExUtils";
import PanelWrapper from "components/Dashboards/PanelWrapper";
import OwnersListDialog from "components/Dialogs/Dashboard/OwnersListDialog";
import CandidatePicker from "components/Pickers/CandidatePicker";
import ClientPicker from "components/Pickers/ClientPicker";
import ContactPicker from "components/Pickers/ContactPicker";
import CurrencyPicker from "components/Pickers/CurrencyPicker";
import DivisionPicker from "components/Pickers/DivisionPicker";
import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import EditableRichTextElement from "../EditableRichTextElement";
import EditableSingleFieldElement from "../EditableSingleFieldElement";
import SingleFieldElement from "../SingleFieldElement";
import { NameIdObj } from "common/models/GenericTypes";

interface Props {
    opportunityId?: number
    clientId?: number,
    contactId?: number,
    typeId?: number,
    defaultSummary?: string,
    setSummaryBar?: (sb: JSX.Element) => void,
    loadingHandler?: (isLoading: boolean) => void,
    successHandler?: (message: string) => void,
    errorHandler?: (message: string) => void,
}

interface CustomFieldWithPredefinedValues extends CustomField {
    values?: CustomFieldPredefinedValue[],
}

type CustomFieldValidateMap = Record<string, boolean>;

const validationErrorMessage = (validationResult: string) => {
    if (validationResult === 'ok') return '';
    if (validationResult === 'required') return 'Required';
    return 'Not Valid';
};

const { unitWidth, unitHeight, gapX, gapY, columns, mediaQueryMaxWidth } = OpportunityScreenLayoutSettings;
const cellSpacing = [gapX, gapY];

const getTypeName = (typeId: number) => {
    switch (typeId) {
        case 1: return 'Back Fill';
        case 2: return 'Tender';
        case 3: return 'Float';
        case 4: return 'Bid';
        case 5: return 'General';
        case 6: return 'Lead';    
        default: return '';
    }
};

export default function EditRecordScreenLayout({ typeId = 5, opportunityId = 0, clientId = 0, contactId = 0, defaultSummary = '', setSummaryBar, loadingHandler, successHandler, errorHandler }: Props) {
    const [isFetchingConfig, setIsFetchingConfig] = useState(false);
    const [isFetchingOpportunity, setIsFetchingOpportunity] = useState(false);
    const [fetchedConfig, setFetchedConfig] = useState(false);
    const [fetchedOpportunity, setFetchedOpportunity] = useState(false);
    const [showValidation, setShowValidation] = useState(false);
    const [fetchedLayoutConfig, setFetchedLayoutConfig] = useState(false);
    const [fetchingLayoutConfig, setFetchingLayoutConfig] = useState(false);
    const [layoutConfiguration, setLayoutConfiguration] = useState<PanelModel[] | null>(null);
    const [isOwnersDialogOpen, setIsOwnersDialogOpen] = useState(false);
    const [existingOpportunity, setExistingOpportunity] = useState<Opportunity | null>(null);
    const [owners, setOwners] = useState<OpportunityOwner[]>([]);
    const [initialOwnersString, setInitialOwnersString] = useState('');
    const [availableCustomFields, setAvailableCustomFields] = useState<CustomFieldWithPredefinedValues[]>([]);
    const [isEditorDirty, setIsEditorDirty] = useState(false);
    const { state, init, change, updateInitial, hasChanges } = useObjectStateWithChangeTracker<MinOpportunity>(DefaultMinOpportunity, DefaultMinOpportunityNoChanges);
    const editorRef = useRef<tinymce.Editor | null>(null);
    const [savedChanges, setSavedChanges] = useState(false);
    const [createdOpportunityId, setCreatedOpportunityId] = useState(0);
    const [screenResizedControl, setScreenResizedControl] = useState(false);
    const [customFieldValidateMap, setCustomFieldValidateMap] = useState<CustomFieldValidateMap>({});
    const layoutRef = useRef<DashboardLayoutComponent | null>(null);
    const theme = useTheme();

    const layoutDataSettingName = useMemo(() => {
        const oType = state.type ? state.type : typeId;
        switch (oType) {
            case 1: return 'OpportunityRecordDashboardLayoutElements_BackFill';
            case 4: return 'OpportunityRecordDashboardLayoutElements_Bid';
            case 3: return 'OpportunityRecordDashboardLayoutElements_Float';
            case 5: return 'OpportunityRecordDashboardLayoutElements_General';
            case 6: return 'OpportunityRecordDashboardLayoutElements_Lead';
            case 2: return 'OpportunityRecordDashboardLayoutElements_Tender';
        }
        return '';
    }, [state.type, typeId]);

    const defaultElements = useMemo(() => {
        const oType = state.type ? state.type : typeId;
        switch (oType) {
            case 1: return DefaultOpportunityRecordDashboardElements_BackFill;
            case 4: return DefaultOpportunityRecordDashboardElements_Bid;
            case 3: return DefaultOpportunityRecordDashboardElements_Float;
            case 5: return DefaultOpportunityRecordDashboardElements_General;
            case 6: return DefaultOpportunityRecordDashboardElements_Lead;
            case 2: return DefaultOpportunityRecordDashboardElements_Tender;
        }
        return [];
    }, [state.type, typeId]);

    const hasOwnerChanges = useMemo(() => {
        const currentOwnersString = owners.map(o => o.recruiterID).sort().join('|');
        return currentOwnersString !== initialOwnersString;
    }, [initialOwnersString, owners]);

    useEffect(() => {
        const getExistingOpportunity = async () => {
            setIsFetchingOpportunity(true);
            const res = await GetOpportunityById(opportunityId, errorHandler);
            if (res) setExistingOpportunity(res);
            setFetchedOpportunity(true);
            setIsFetchingOpportunity(false);
        };
        opportunityId && getExistingOpportunity();
    }, [opportunityId, errorHandler]);

    useEffect(() => {
        const getOwners = async () => {
            if (opportunityId) {
                const res = await GetOpportunityOwners(opportunityId);
                if (res) {
                    const ownersString = res.map(o => o.recruiterID).sort().join('|');
                    setOwners(res);
                    setInitialOwnersString(ownersString);
                }
            }
        };
        opportunityId && getOwners();
    }, [opportunityId]);

    const addOwnerCallback = useCallback((u: NameIdObj | null) => {
        if (u) {
            setOwners(prev => ([...prev, {
                opportunityID: opportunityId, firstName: '', fullName: u.name, lastName: '', recruiterID: u.id, userName: ''
            }]));
        }
    }, [opportunityId]);

    const removeOwnerCallback = useCallback((userId: number) => {
        setOwners(prev => {
            let ows = [...prev];
            const index = ows.findIndex(o => o.recruiterID === userId);
            if (index !== -1) ows.splice(index, 1);
            return ows;
        });
    }, []);

    useEffect(() => {
        const getActiveFields = async () => {
            setIsFetchingConfig(true);
            const customFields = await GetCustomFieldsByEntity_OnlyActive(8);
            if (customFields) {
                let udfDefinitions: CustomFieldWithPredefinedValues[] = [];
                for (let i = 0; i < customFields.length; i++) {
                    const u = customFields[i];
                    if (!u.usePredefinedValues) {
                        udfDefinitions.push(u);
                        continue;
                    }

                    const vals = await GetPredefinedValues(u.id);
                    if (vals) udfDefinitions.push({ ...u, values: vals });
                    else udfDefinitions.push(u);
                }
                setAvailableCustomFields(udfDefinitions);
            }
            setFetchedConfig(true);
            setIsFetchingConfig(false);
        };
        getActiveFields();
    }, []);

    useEffect(() => {
        const setDefaultCreationValues = async () => {
            let tempState = {...DefaultMinOpportunity};
            tempState.clientID = clientId;
            tempState.contactID = contactId;
            tempState.type = typeId;
            tempState.description = defaultSummary;
            const res = await GetMySettings();
            if (res) {
                const division = res.find(s => s.type === 'Division');
                const currency = res.find(s => s.type === 'Currency');
                if (division) tempState.divisions = division.value;
                if (currency) tempState.currency = +currency.value;
            }
            if (availableCustomFields.length > 0) {
                for (let i = 0; i < availableCustomFields.length; i++) {
                    const f = availableCustomFields[i];
                    if (f.usePredefinedValues && f.values && f.values.length > 0) {
                        const defaultValue = f.values.find(v => v.isDefault);
                        if (defaultValue) {
                            const field = 'customField' + f.name.substring(13) as keyof MinOpportunity;
                            (tempState as any)[field] = defaultValue.value;
                        }
                    }
                }
            }
            init(tempState);
        };
        opportunityId === 0 && fetchedConfig && setDefaultCreationValues();
    }, [opportunityId, clientId, contactId, typeId, fetchedConfig, availableCustomFields, init, defaultSummary]);

    useEffect(() => {
        const getSavedState = async () => {
            setFetchingLayoutConfig(true);
            const elementsJson = await GetCustomerSettingBySettingName(layoutDataSettingName);
            if (elementsJson) {
                const panels = JSON.parse(elementsJson) as PanelModel[];
                setLayoutConfiguration(panels);
            }
            setFetchingLayoutConfig(false);
            setFetchedLayoutConfig(true);
        };
        layoutDataSettingName && getSavedState();
    }, [layoutDataSettingName]);

    useEffect(() => loadingHandler && loadingHandler(isFetchingOpportunity || isFetchingConfig || fetchingLayoutConfig), [isFetchingOpportunity, isFetchingConfig, fetchingLayoutConfig, loadingHandler]);

    useEffect(() => {
        if (opportunityId && existingOpportunity && fetchedOpportunity && fetchedConfig) {
            const mc = OpportunityToMinOpportunity(existingOpportunity);
            init(mc);
            const api = editorRef.current;
            if (api) api.setContent(existingOpportunity.description ?? '');
        }
    }, [opportunityId, existingOpportunity, fetchedOpportunity, fetchedConfig, init]);

    const saveChangesHandler = useCallback(async () => {
        if(!state.name) {
            setShowValidation(true);
            errorHandler && errorHandler("Name can't be empty");
            return false;
        }

        if(!IsValidNumericValue(state.value.toString())) {
            setShowValidation(true);
            errorHandler && errorHandler("Value must have a valid numeric value");
            return false;
        }

        if(!IsValidNumericValue(state.probability.toString())) {
            setShowValidation(true);
            errorHandler && errorHandler("Probability must have a valid numeric value");
            return false;
        }

        let emptyNumericUdfs: Partial<MinOpportunity> = { };
        let nullDateUdfs: Partial<MinOpportunity> = { };
        for (let i = 0; i < availableCustomFields.length; i++) {
            const u = availableCustomFields[i];
            if (!u.editable) continue;

            const customFieldNumber = u.name.substring(13);
            const customFieldKey = "customField" + customFieldNumber as keyof MinOpportunity;
            const { format } = UdfOpportunityFieldMapObj['CustomField' + customFieldNumber as CustomFieldType];
            const value = state[customFieldKey];
            if (format === "number" && (value === '' || value === null || value === undefined)) (emptyNumericUdfs[customFieldKey] as any) = undefined;
            if ((format === "date" || format === 'datetime') && !Boolean(value)) (nullDateUdfs[customFieldKey] as any) = '0001-01-01T00:00:00';

            if (!customFieldValidateMap['CustomField' + customFieldNumber]) continue;

            const validation = IsValidCustomFieldValue(value ?? undefined, format, u.mandatory);

            if (validation === "required") {
                setShowValidation(true);
                errorHandler && errorHandler(`${u.agencyName} is required`);
                return false;
            }
            else if (validation === "invalid-number") {
                setShowValidation(true);
                errorHandler && errorHandler(`${u.agencyName} must have a valid numeric value`);
                return false;
            }
            else if (validation === "invalid-date") {
                setShowValidation(true);
                errorHandler && errorHandler(`${u.agencyName} must have a valid date value`);
                return false;
            }
            else if (validation === "range-date") {
                setShowValidation(true);
                errorHandler && errorHandler(`${u.agencyName} must be a date between 01-01-1753 and 31-12-9999`);
                return false;
            }
        }

        loadingHandler && loadingHandler(true);
        const editorApi = editorRef.current;
        const summaryContent = editorApi ? editorApi.getContent() : '';
        const ownerIds = owners.map(o => o.recruiterID);
        const valueField = state.value ? state.value : undefined;
        const probability = state.probability ? state.probability : undefined;
        const startDate = state.startDate ? state.startDate : '0001-01-01T00:00:00';
        const closeDate = state.closeDate ? state.closeDate : '0001-01-01T00:00:00';
        const deadline = state.deadline ? state.deadline : '0001-01-01T00:00:00';
        let oId = opportunityId;
        if (opportunityId === 0) {
            const res = await CreateOpportunity({...state, description: summaryContent, startDate: startDate, closeDate: closeDate, deadline: deadline, value: valueField, probability: probability, ...emptyNumericUdfs, ...nullDateUdfs }, errorHandler);
            if (!res) {
                loadingHandler && loadingHandler(false);
                return false;
            }
            oId = res.value;
            if (ownerIds.length === 0 && oId !== 0) {
                const me = await GetMyUser();
                if (me && me.userID) {
                    await SetOpportunityOwners(oId, [+me.userID]);
                }
            }
            setCreatedOpportunityId(res.value);
        }
        else if (opportunityId && (hasChanges || isEditorDirty)) {
            const res = await UpdateOpportunity({...state, description: summaryContent, startDate: startDate, closeDate: closeDate, deadline: deadline, value: valueField, probability: probability, ...emptyNumericUdfs, ...nullDateUdfs }, errorHandler);
            if (!res) {
                loadingHandler && loadingHandler(false);
                return false;
            }
        }
        if (hasOwnerChanges && oId) {
            const res = await SetOpportunityOwners(oId, ownerIds);
            if (res) setInitialOwnersString(ownerIds.sort().join('|'));
        }
        updateInitial();
        setIsEditorDirty(false);
        editorApi && editorApi.setDirty(false);
        loadingHandler && loadingHandler(false);
        setSavedChanges(true);
        return true;
    }, [state, loadingHandler, owners, opportunityId, hasChanges, isEditorDirty, hasOwnerChanges, updateInitial, errorHandler, availableCustomFields, customFieldValidateMap]);

    useEffect(() => {
        const actionButton = <Button variant="contained" color="success" disabled={!hasChanges && !isEditorDirty && ! hasOwnerChanges} onClick={saveChangesHandler}>Save</Button>
        if (opportunityId === 0 && setSummaryBar) {
            const sb = <TitleAndActionSummaryBar title="Opportunities > Create" browserTabTitle="Create > Opportunities" action={actionButton} />;
            setSummaryBar(sb);
        }
        else if (opportunityId && setSummaryBar) {
            const goBackAction = <Link to={`/opportunities/${opportunityId}`} style={{ textDecoration: 'none' }}><Button variant="contained" color="error" sx={{ mr: '5px' }}>Cancel</Button></Link>;
            const sb = <TitleAndActionSummaryBar title="Opportunities > Edit" browserTabTitle="Edit > Opportunities" action={<>{goBackAction}{actionButton}</>} />;
            setSummaryBar(sb);
        }
    }, [opportunityId, hasChanges, isEditorDirty, hasOwnerChanges, setSummaryBar, saveChangesHandler]);

    const onStringFieldChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        let val = value;
        if (name.startsWith('dtUDF') && value && !value.includes('T')) val += 'T00:00:00';
        change(name as keyof MinOpportunity, val);
    }, [change]);

    const onDateFieldChange = useCallback((m: moment.Moment | null, fieldName: string) => {
        if(m && m.isValid()) {
            change(fieldName as keyof MinOpportunity, m.format('YYYY-MM-DD'))
        }
        else if (m === null) change(fieldName as keyof MinOpportunity, '');
    }, [change]);

    const onDateTimeFieldChange = useCallback((m: moment.Moment | null, fieldName: string) => {
        if(m && m.isValid()) {
            change(fieldName as keyof MinOpportunity, m.format('YYYY-MM-DDTHH:mm'));
        }
        else if (m === null) change(fieldName as keyof MinOpportunity, '');
    }, [change]);

    const onNumberFieldChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        const n = +value;
        if (isNaN(n) && value !== '') return;
        if (value.length > 11) return;

        const i = value.indexOf('.');
        if (value.length > 8 && i === -1) return;

        if(i >= 0) {
            const decimals = value.substring(i + 1);
            if (decimals.length > 2) {
                change(name as keyof MinOpportunity, value.substring(0, i + 3));
                return;
            }
        }

        change(name as keyof MinOpportunity, value);

    }, [change]);

    const onClientChange = useCallback((client: Client | null) => {
        change('clientID', client ? client.id : 0);
    }, [change]);

    const onContactChange = useCallback((contact: Contact | null) => {
        change('contactID', contact ? contact.id : 0);
    }, [change]);

    const onCandidateChange = useCallback((candidate: CandidateList | null) => {
        change('candidateID', candidate ? candidate.id : 0);
    }, [change]);

    const onDivisionChange = useCallback((divisionId: number | null) => {
        change('divisions', divisionId ? divisionId.toString() : '');
    }, [change]);

    const onCurrencyChange = useCallback((cId: number | null) => {
        change('currency', cId ?? 0);
    }, [change]);

    const { unsavedChangesDialog, hasBlockedRoute } = useUnsavedChangesDialog(hasChanges || isEditorDirty || hasOwnerChanges, saveChangesHandler);

    const redirectOpportunityId = useMemo(() => {
        if (opportunityId !== 0) return opportunityId;
        if (savedChanges && createdOpportunityId) return createdOpportunityId;
        return 0;
    }, [opportunityId, createdOpportunityId, savedChanges]);

    const elements = useMemo<OpportunityRecordDashboardElementDefinition[]>(() => {
        if (!fetchedLayoutConfig) return [];
        if (layoutConfiguration !== null) {
            const elements = GetPanelDefinitionsFromPanelModels(layoutConfiguration);
            return elements ;
        }
        return defaultElements;
    }, [layoutConfiguration, fetchedLayoutConfig, defaultElements]);

    const customFieldsSettingsMap = useMemo<CustomFieldSettingsMap>(() => {
        let obj: CustomFieldSettingsMap = {};
        availableCustomFields.forEach(u => {
            const customFieldNumber = u.name.substring(13);
            const key = 'CustomField' + customFieldNumber as CustomFieldType;
            obj[key] = {
                title: u.agencyName,
                isMultiLine: u.multiLine && !u.usePredefinedValues,
                isMandatory: u.mandatory,
                isEditable: u.editable,
                usePredefinedValues: u.usePredefinedValues,
                values: u.values
            }
        });
        return obj;
    }, [availableCustomFields]);

    useEffect(() => {
        if (elements.length > 0) {
            let vm: CustomFieldValidateMap = {};
            for (let i = 0; i < elements.length; i++) {
                const element = elements[i];
                if (element.id.startsWith('CustomField')) {
                    const split = element.id.split('_');
                    const key = split[0];
                    if (key) vm[key] = true;
                }
            }
            setCustomFieldValidateMap(vm);
        }
    }, [elements]);

    const renderElement = useCallback((id: string, type: OpportunityRecordDashboardElementType) => {
        switch (type) {
            case 'Divider': return <Box pt="20px"><Divider component="div" /></Box>;
            case 'Spacer': return <></>;
            case 'OpportunitySummary': return (
                <EditableRichTextElement
                    title="Summary"
                    content={state.description ?? ''}
                    isDarkTheme={theme.palette.mode === 'dark'}
                    editorRef={editorRef}
                    editorDirtyHandler={ setIsEditorDirty }
                />
            );
            case 'OpportunityId': return <SingleFieldElement labelWidthPercent={30} fontSize="1rem" fieldTitle='ID' fieldValue={state.id} format="number" />;
            case 'OpportunityName': return (
                <EditableSingleFieldElement
                    isError={showValidation && !state.name}
                    errorMessage="Required"
                    fieldTitle='Name'
                    fieldValue={state.name}
                    fieldName="name"
                    onChangeHandler={onStringFieldChange}
                />
            );
            case 'OpportunityContact': return (
                <EditableSingleFieldElement format="custom" fieldTitle='Contact' >
                    <ContactPicker
                        hideLabel
                        variant="standard"
                        value={state.contactID} onSelectCallback={ c => onContactChange(c) }
                        errorHandler={errorHandler}
                        loadingHandler={loadingHandler}
                        successHandler={successHandler}
                    />
                </EditableSingleFieldElement>
            );
            case 'OpportunityStatus': return <SingleFieldElement useEllipsisForLongValues labelWidthPercent={30} fontSize="1rem" fieldTitle="Status" fieldValue={state.statusName} />;
            case 'OpportunityType': return <SingleFieldElement useEllipsisForLongValues labelWidthPercent={30} fontSize="1rem" fieldTitle="Type" fieldValue={getTypeName(state.type)} />;
            case 'OpportunityStage': return (
                <EditableSingleFieldElement format="select" fieldTitle='Stage' fieldValue={state.stageID.toString()} fieldName="stageID" onChangeHandler={onNumberFieldChange} >
                    <MenuItem value="1">Lead</MenuItem>
                    <MenuItem value="2">Opportunity</MenuItem>
                    <MenuItem value="3">Converted</MenuItem>
                </EditableSingleFieldElement>
            );
            case 'OpportunityValue': return <EditableSingleFieldElement fieldTitle='Value' fieldValue={state.value.toString()} fieldName="value" onChangeHandler={onNumberFieldChange} format="number" />;
            case 'OpportunityProbability': return <EditableSingleFieldElement fieldTitle='Probability (%)' fieldValue={state.probability.toString()} fieldName="probability" onChangeHandler={onNumberFieldChange} format="number" />;
            case 'OpportunityCurrency': return (
                <EditableSingleFieldElement format="custom" fieldTitle='Currency' >
                    <CurrencyPicker
                        hideLabel
                        variant="standard"
                        currencyId={state.currency}
                        onSelectCallback={ onCurrencyChange }
                    />
                </EditableSingleFieldElement>
            );
            case 'OpportunityStartDate': return <EditableSingleFieldElement fieldTitle='Start Date' fieldValue={state.startDate} fieldName="startDate" onDateChangeHandler={onDateFieldChange} format="date" />;
            case 'OpportunityCloseDate': return <SingleFieldElement useEllipsisForLongValues labelWidthPercent={30} fontSize="1rem" fieldTitle="Close Date" fieldValue="N/A" />;
            case 'OpportunityDeadline': return <EditableSingleFieldElement fieldTitle='Deadline' fieldValue={state.deadline} fieldName="deadline" onDateChangeHandler={onDateFieldChange} format="date" />;
            case 'OpportunityClient': return (
                <EditableSingleFieldElement format="custom" fieldTitle='Client' >
                    <ClientPicker
                        hideLabel
                        variant="standard"
                        value={state.clientID}
                        onSelectCallback={ onClientChange }
                        errorHandler={errorHandler}
                        loadingHandler={loadingHandler}
                        successHandler={successHandler}
                    />
                </EditableSingleFieldElement>
            );
            case 'OpportunityCandidate': return (
                <EditableSingleFieldElement format="custom" fieldTitle='Candidate' >
                    <CandidatePicker
                        hideLabel
                        variant="standard"
                        value={state.candidateID}
                        onSelectCallback={ onCandidateChange }
                    />
                </EditableSingleFieldElement>
            );
            case 'OpportunityOwners': return (
                <EditableSingleFieldElement format="custom" fieldTitle="Owners">
                    <Typography variant="body1" component="span" color="primary.main" sx={{ cursor: 'pointer' }} onClick={ () => setIsOwnersDialogOpen(true) }>Click to change Owners</Typography>
                </EditableSingleFieldElement>
            );
            case 'OpportunityDivision': return (
                <EditableSingleFieldElement format="custom" fieldTitle='Division' >
                    <DivisionPicker divisionId={+state.divisions} onSelectCallback={onDivisionChange} hideLabel variant="standard" />
                </EditableSingleFieldElement>
            );
            default:
                if (type.startsWith('CustomField')) {
                    const settings = customFieldsSettingsMap[type];
                    if (settings && settings.isEditable) {
                        let title = settings.title;
                        let predefinedValues = settings.usePredefinedValues && settings.values ? settings.values : null;
                        const { field, format } = UdfOpportunityFieldMapObj[type as CustomFieldType];
                        const rawValue = state[field as keyof MinOpportunity];
                        const udfValue = (state[field as keyof MinOpportunity] ?? '').toString();
                        const validationResult = IsValidCustomFieldValue(rawValue === null ? undefined : rawValue, format, settings.isMandatory ?? false);
                        const isValid = validationResult === "ok";
                        const errorMessage = validationErrorMessage(validationResult);
                        if (predefinedValues) {
                            return (
                                <EditableSingleFieldElement isError={showValidation && !isValid} errorMessage={errorMessage} format="select" fieldTitle={title} fieldValue={udfValue} fieldName={field} onChangeHandler={onStringFieldChange}>
                                    <MenuItem value="">None</MenuItem>
                                    {predefinedValues.map(v => <MenuItem key={v.value} value={v.value}>{v.value + (v.isDefault ? ' (Default)' : '')}</MenuItem>)}
                                </EditableSingleFieldElement>
                            );
                        }
                        else if (udfValue !== undefined && format === 'string') {
                            return (
                                <EditableSingleFieldElement
                                    isError={showValidation && !isValid}
                                    errorMessage={errorMessage}
                                    format={format}
                                    fieldTitle={title}
                                    fieldValue={udfValue}
                                    fieldName={field}
                                    onChangeHandler={onStringFieldChange}
                                />
                            );
                        }
                        else if (udfValue !== undefined && format === 'number') {
                            return (
                                <EditableSingleFieldElement
                                    isError={showValidation && !isValid}
                                    errorMessage={errorMessage}
                                    format={format}
                                    fieldTitle={title}
                                    fieldValue={udfValue}
                                    fieldName={field}
                                    multiline={settings.isMultiLine}
                                    onChangeHandler={onNumberFieldChange}
                                />
                            );
                        }
                        else if (format === 'date') {
                            return (
                                <EditableSingleFieldElement
                                    isError={showValidation && !isValid}
                                    errorMessage={errorMessage}
                                    format={format}
                                    fieldTitle={title}
                                    fieldValue={udfValue}
                                    fieldName={field}
                                    onDateChangeHandler={onDateFieldChange}
                                />
                            );
                        }
                        else if (format === 'datetime') {
                            return (
                                <EditableSingleFieldElement
                                    isError={showValidation && !isValid}
                                    errorMessage={errorMessage}
                                    format={format}
                                    fieldTitle={title}
                                    fieldValue={udfValue}
                                    fieldName={field}
                                    onDateChangeHandler={onDateTimeFieldChange}
                                />
                            );
                        }
                    }
                    else if (settings) return <SingleFieldElement labelWidthPercent={30} fontSize="1rem" fieldTitle={settings.title} fieldValue="[ Non Editable Custom Field ]" />;
                    else return <SingleFieldElement labelWidthPercent={30} fontSize="1rem" fieldTitle={type} fieldValue="[ Disabled Custom Field ]" />;
                }
                return <div>{id}</div>;
        }
    }, [state, showValidation, customFieldsSettingsMap, setIsEditorDirty, editorRef, theme.palette.mode,
        onStringFieldChange, onNumberFieldChange, onDateFieldChange, onDateTimeFieldChange, onContactChange,
        errorHandler, successHandler, loadingHandler, onCandidateChange, onClientChange, onCurrencyChange, onDivisionChange]);
    
    const layoutResizeStopHandler = useCallback(() => setScreenResizedControl(prev => !prev), []);

    useEffect(() => {
        if (screenResizedControl) {}
        const api = layoutRef.current;
        if (api && fetchedLayoutConfig) {
            const refreshTimeout = setTimeout(() => {
                api.refresh();
                api.refresh();
            }, 250);
            return () => clearTimeout(refreshTimeout);
        }
    }, [fetchedLayoutConfig, screenResizedControl]);

    useEffect(() => {
        window.addEventListener('resize', layoutResizeStopHandler);
        return () => window.removeEventListener('resize', layoutResizeStopHandler);
    }, [layoutResizeStopHandler]);

    const renderLayout = useCallback(() => {
        if (!fetchedLayoutConfig) return <></>;

        let sorted = [...elements];
        sorted.sort((a, b) => {
            if (a.col === b.col) return a.row < b.row ? -1 : 1;
            else return a.col < b.col ? -1 : 1;
        });

        const mediaQuery = mediaQueryMaxWidth ? `max-width: ${mediaQueryMaxWidth}` : undefined;
        return (
            <DashboardLayoutComponent
                cellSpacing={cellSpacing}
                columns={columns}
                cellAspectRatio={30 / 2}
                allowDragging={false}
                allowResizing={false}
                resizeStop={ layoutResizeStopHandler }
                ref={l => layoutRef.current = l}
                mediaQuery={mediaQuery}
            >
                {sorted.map(e => (
                    <PanelWrapper
                        key={e.id}
                        id={e.id}
                        col={e.col}
                        row={e.row}
                        sizeX={e.sizeX}
                        sizeY={e.sizeY}
                        minSizeX={e.minSizeX}
                        minSizeY={e.minSizeY}
                        maxSizeX={e.maxSizeX}
                        maxSizeY={e.maxSizeY}
                        resizeControl={screenResizedControl}
                        resizeIconColor={theme.palette.text.disabled}
                        gapX={gapX}
                        gapY={gapY}
                        unitWidth={unitWidth}
                        unitHeight={unitHeight}
                    >
                        {renderElement(e.id, e.type)}
                    </PanelWrapper>
                ))}
            </DashboardLayoutComponent>
        );
    }, [elements, fetchedLayoutConfig, theme.palette.text.disabled, screenResizedControl, layoutResizeStopHandler, renderElement]);


    return (
        <>
            {unsavedChangesDialog}
            { !hasBlockedRoute && redirectOpportunityId !== 0 && savedChanges && !hasChanges && !isEditorDirty && <Navigate to={`/opportunities/${redirectOpportunityId}`} /> }
            <OwnersListDialog
                type="Opportunity"
                open={isOwnersDialogOpen}
                editable
                closeHandler={ () => setIsOwnersDialogOpen(false) }
                owners={owners}
                addOwnerHandler={addOwnerCallback}
                removeOwnerHandler={removeOwnerCallback}
            />
            <Box p="10px" height="100%">
                <div className="control-section">
                    { renderLayout() }
                </div>
            </Box>
        </>
    );
}