import { Automation } from "common/models/Configuration/Automation";
import { ChangeTracker } from "common/models/hooks/ChangeTracker";


export const DefaultAutomation: Automation = {
    id: 0,
    name: '',
    triggerID: 1,
    trigger: 'Event Based',
    eventID: 0,
    event: '',
    statusID: 1,
    status: 'Active',
    actionsJSON: '[]',
    filtersJSON: '[]',
    excludeRecordsProcessedInDays: 30,
    scheduleMetaDataJSON: null,
    moduleId: null,
    maxNumberOfRecordsToExecute: 10000,
    orderByJSON: null
}

export const NoChangesTracker: ChangeTracker<Automation> = {
    id: false,
    name: false,
    triggerID: false,
    trigger: false,
    eventID: false,
    event: false,
    statusID: false,
    status: false,
    actionsJSON: false,
    filtersJSON: false,
    excludeRecordsProcessedInDays: false,
    moduleId: false,
    scheduleMetaDataJSON: false,
    maxNumberOfRecordsToExecute: false,
    orderByJSON: false
}