import React, { useEffect, useMemo, useState } from "react";
import { GridColDef, GridRenderCellParams, useGridApiRef } from '@mui/x-data-grid-premium';
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import OnlyTitleSummaryBar from "components/SummaryBars/OnlyTitleSummaryBar";
import PageContentLayout from "layouts/PageContentLayout";
import { GetCvProcessingTemplate, GetCvProcessingTemplates } from "services/TemplatesService";
import { CVProcessingEmailTemplate } from "common/models/Templates/CVProcessingEmailTemplate";
import GridWithStateWrapper from "components/GridWidthStateWrapper";

interface Props {
    setSummaryBar?: (summaryBar: JSX.Element) => void
}

const SummaryBar = <OnlyTitleSummaryBar title="Configuration > CV Processing Emails" browserTabTitle="CV Processing Emails > Configuration"/>;

export default function CvProcessingEmails({ setSummaryBar }: Props) {
    useEffect(() => { setSummaryBar && setSummaryBar(SummaryBar); }, [setSummaryBar]);
    const [isLoading, setIsLoading] = useState(false);
    const [rows, setRows] = useState<CVProcessingEmailTemplate[]>([]);
    const [previews, setPreviews] = useState<any>({});
    const [selectedPreview, setSelectedPreview] = useState<CVProcessingEmailTemplate | null>(null);
    const apiRef = useGridApiRef();

    useEffect(() => {
        const getGridData = async () => {
            setIsLoading(true);
            const data = await GetCvProcessingTemplates();
            if(data) {
                setRows(data);
            }
            setIsLoading(false);
        };
        getGridData();
    }, []);

    const columns = useMemo<GridColDef[]>(() => {
        const getTemplateHtml = async (templateId: number) => {
            const id:string = templateId.toString();
            if(previews[id]) {
                setSelectedPreview(previews[id] as CVProcessingEmailTemplate);
                return;
            }
            
            setIsLoading(true);
            const data = await GetCvProcessingTemplate(templateId);
            if (data) {
                setPreviews((prev: any) => ({...prev, [templateId.toString()]: data}));
                setSelectedPreview(data);
            }
            setIsLoading(false);
        };

        const dialogLinkRenderer = (params: GridRenderCellParams) => {
            return (
                <span
                    onClick={() => getTemplateHtml(params.row.templateID)}
                    style={{ cursor: 'pointer', textDecoration: 'underline' }}
                >{params.value}</span>
            );
        }

        return [
            { field: 'templateID', headerName: 'ID', width: 100, renderCell: dialogLinkRenderer },
            { field: 'name', headerName: 'Name', width: 250, renderCell: dialogLinkRenderer },
            { field: 'subject', headerName: 'Subject', width: 300 },
            { field: 'senderName', headerName: 'Sender Name', width: 200 },
            { field: 'senderAddress', headerName: 'Sender Address', width: 210 },
        ];
    }, [previews])

    return (
        <>
            {selectedPreview &&
                <Dialog open={true} maxWidth="xl" fullWidth onClose={() => setSelectedPreview(null)}>
                    <DialogTitle>{selectedPreview.name}</DialogTitle>
                    <DialogContent>
                        <DialogContentText component="div">
                            {"Subject: " + selectedPreview.subject }
                            <div
                                style={{ boxSizing: 'border-box', overflow: 'auto', backgroundColor: '#fff', color: 'initial' }}
                                dangerouslySetInnerHTML={{ __html: selectedPreview.messageText}}
                            />
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={() => setSelectedPreview(null)}>Close</Button>
                    </DialogActions>
                </Dialog>
            }
            <PageContentLayout title="CV Processing Emails" showLoading={isLoading}>
                <GridWithStateWrapper
                    gridName="configuration/cv-processing-emails"
                    apiRef={apiRef}
                    getRowId={ (row) => row.templateID }
                    rows={rows}
                    columns={columns}
                    disableRowSelectionOnClick
                    pagination={true}
                    density="compact"
                    pageSizeOptions={[100,250,500,1000]}
                />
            </PageContentLayout>
        </>
    );
}