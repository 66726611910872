import React from "react";
import UserSettingsPageContent from "components/Content/UserSettingsPageContent";
import CustomApplicationContext from "util/CustomApplicationContext";

interface Props {
    setSummaryBar?: (summaryBar: JSX.Element) => void
}

export default function AccountSettingsPage({ setSummaryBar }: Props) {
    return (
        <>
            <CustomApplicationContext.Consumer>
                {c => (
                    <UserSettingsPageContent
                        summaryBarTitle="Account > Settings"
                        summaryBarTabTitle="Settings > Account"
                        setSummaryBar={setSummaryBar}
                        refreshCurrentUserHandler={c.refreshCurrentUser}
                    />
                )}
            </CustomApplicationContext.Consumer>
        </>
    );
}