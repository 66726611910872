import ClickAwayListener from "@mui/material/ClickAwayListener";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from '@mui/icons-material/InfoTwoTone';
import React, { useState } from "react";

interface Props {
    text: React.ReactNode
}

export default function InfoButtonToolTip({ text }: Props) {
    const [open, setOpen] = useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
      };
    
      const handleTooltipOpen = () => {
        setOpen(true);
      };

    return (
        <ClickAwayListener onClickAway={handleTooltipClose}>
            <div>
                <Tooltip
                    arrow
                    onClose={handleTooltipClose}
                    open={open}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={text}
                >
                    <IconButton color="primary" onClick={handleTooltipOpen}>
                        <InfoIcon />
                    </IconButton>
                </Tooltip>
            </div>
        </ClickAwayListener>
    );
}