import Skeleton from "@mui/material/Skeleton";
import { SxProps, Theme } from "@mui/material/styles";
import React from "react";
import { Link } from "react-router-dom";

export const PREVIEW_HEIGHT = 645;
export const PREVIEW_WIDTH = 935;
export const PREVIEW_DRAGGABLE_TITLE_STYLE: SxProps<Theme> = { cursor: 'move', py: '5px', bgcolor: t => t.palette.primary.main, color: t => t.palette.primary.contrastText };
export const PREVIEW_TITLE_STYLE: SxProps<Theme> = { py: '5px', bgcolor: t => t.palette.primary.main, color: t => t.palette.primary.contrastText };
export const PREVIEW_TABLE_STYLE: React.CSSProperties = { width: '100%' };
export const PREVIEW_TABLE_LABEL: React.CSSProperties = { fontWeight: 'bold' };
export const PREVIEW_TABLE_CONTENT: React.CSSProperties = { overflowWrap: 'anywhere' };
export const PREVIEW_TEXT_SKELETON = <Skeleton variant="text" sx={{ minWidth: '300px' }} />;

export const PREVIEW_LINK_RENDERER = (url: string, text?: string | number | null, target?: React.HTMLAttributeAnchorTarget, decoration: string = 'underline') => (
    <Link to={url} target={target} style={{ color: 'inherit', textDecoration: decoration }}>{text ? text : url}</Link>
);

export const PREVIEW_EXTERNAL_LINK_RENDERER = (url: string, text?: string | number | null, target?: React.HTMLAttributeAnchorTarget, decoration: string = 'underline') => (
    <a href={url} target={target} style={{ color: 'inherit', textDecoration: decoration }} rel="noopener noreferrer" >{text ? text : url}</a>
);

export const PREVIEW_PHONE_RENDERER = (phone: string | number | null, standardPhone?: string | number | null) => {
    if (standardPhone) return PREVIEW_EXTERNAL_LINK_RENDERER(`tel:${standardPhone.toString().replaceAll(' ', '')}`, phone, undefined, 'none');
    if (phone) return PREVIEW_EXTERNAL_LINK_RENDERER(`tel:${phone.toString().replaceAll(' ', '')}`, phone, undefined, 'none');
    return "";
}

export const PREVIEW_EMAIL_RENDERER = (email: string) => {
    if (email) return PREVIEW_EXTERNAL_LINK_RENDERER(`mailto:${email}`, email);
    return "";
}

export const PREVIEW_SITE_RENDERER = (id: number, name: string | null, emptyString: string = '') => {
    if (id !== 0 && name) return PREVIEW_LINK_RENDERER(`/sites/${id}`, name, '_blank');
    return emptyString;
}

export const PREVIEW_CONTACT_RENDERER = (id: number, name: string | null) => {
    if (id !== 0 && name) return PREVIEW_LINK_RENDERER(`/contacts/${id}`, name, '_blank');
    return 'None';
};

export const PREVIEW_CLIENT_RENDERER = (id: number, name: string | null) => {
    if (id !== 0 && name) return PREVIEW_LINK_RENDERER(`/clients/${id}`, name, '_blank');
    return 'None';
};

export const PREVIEW_JOB_RENDERER = (id: number, name: string | null) => {
    if (id !== 0 && name) return PREVIEW_LINK_RENDERER(`/jobs/${id}`, name, '_blank');
    return 'None';
};

export const PREVIEW_CANDIDATE_RENDERER = (id: number, name: string | null) => {
    if (id !== 0 && name) return PREVIEW_LINK_RENDERER(`/candidates/${id}`, name, '_blank');
    return 'None';
};