import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { SxProps } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { GetInternalInterviewsConversionReportData } from "services/MiscReportsService";
import { GridColDef, useGridApiRef } from "@mui/x-data-grid-premium";
import { InternalInterviewsConversionReportData } from "common/models/Reports/InternalInterviewsConversion";
import GridWithStateWrapper from "components/GridWidthStateWrapper";
import { DatePicker } from "@mui/x-date-pickers-pro";

interface Props {
    description: string,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
}

const filterElementStyle: SxProps = { flex: '1 1 0', mr: 1 };

export default function InternalInterviewsConversionReport({ description, loadingHandler, errorHandler }: Props) {
    const [rows, setRows] = useState<InternalInterviewsConversionReportData[]>([]);
    const [showGrid, setShowGrid] = useState(false);
    const [consultantId] = useState(0);
    const [fromDate, setFromDate] = useState<moment.Moment | null>(null);
    const [toDate, setToDate] = useState<moment.Moment | null>(null);
    const [submissions, setSubmissions] = useState(3);
    const [interviews, setInterviews] = useState(4);
    const [offers, setOffers] = useState(5);
    const [placements, setPlacements] = useState(6);
    const apiRef = useGridApiRef();

    useEffect(() => {
        setFromDate(moment().startOf('month'));
        setToDate(moment().endOf('month'));
    }, []);

    const getDataCallback = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        const stringFromDate = fromDate && fromDate.isValid() ? fromDate.format('YYYY-MM-DD') : '';
        const stringToDate = toDate && toDate.isValid() ? toDate.format('YYYY-MM-DD') : '';
        const res = await GetInternalInterviewsConversionReportData(consultantId, stringFromDate, stringToDate, submissions, interviews, offers, placements, errorHandler);
        if (res) {
            setShowGrid(true);
            setRows(res);
        }
        loadingHandler && loadingHandler(false);
    }, [consultantId, fromDate, toDate, submissions, interviews, offers, placements, errorHandler, loadingHandler]);

    const exportAsExcelHandler = useCallback(() => {
        const api = apiRef.current;
        if (api) {
            const m = moment();
            const filename = `InternalInterviewsConversion${m.format('YYYYMMDDhhmmss')}`;
            api.exportDataAsExcel({ fileName: filename });
        }
    }, [apiRef]);

    const columns = useMemo<GridColDef[]>(() => {
        return [
            { field: 'CandidateName', headerName: 'Candidate Name', width: 300 },
            { field: 'InterviewDate', headerName: 'Interview Date', width: 250 },
            { field: 'InterviewBy', headerName: 'Interview By', width: 250 },
            { field: 'Submissions', headerName: 'Submissions', width: 120 },
            { field: 'ClientInterviews', headerName: 'Client Interviews', width: 120 },
            { field: 'Offers', headerName: 'Offers', width: 120 },
            { field: 'Placements', headerName: 'Placements', width: 120 },
        ];
    }, []);

    return (
        <>
            <Box display="flex">
                <DatePicker label="From Date" value={fromDate} onChange={m => setFromDate} sx={filterElementStyle} slotProps={{actionBar: { actions: ["clear", "today", "cancel", "accept"] }}} />
                <DatePicker label="To Date" value={toDate} onChange={m => setToDate} sx={filterElementStyle} slotProps={{actionBar: { actions: ["clear", "today", "cancel", "accept"] }}} />
                <TextField select label="Submissions within" value={submissions.toString()} onChange={ ({target}) => setSubmissions(+target.value) } sx={filterElementStyle}>
                    <MenuItem value="1">1 month</MenuItem>
                    <MenuItem value="2">2 months</MenuItem>
                    <MenuItem value="3">3 months</MenuItem>
                    <MenuItem value="4">4 months</MenuItem>
                    <MenuItem value="5">5 months</MenuItem>
                    <MenuItem value="6">6 month</MenuItem>
                    <MenuItem value="7">7 months</MenuItem>
                    <MenuItem value="8">8 months</MenuItem>
                    <MenuItem value="9">9 months</MenuItem>
                    <MenuItem value="10">10 months</MenuItem>
                    <MenuItem value="11">11 months</MenuItem>
                    <MenuItem value="12">12 months</MenuItem>
                </TextField>
                <TextField select label="Client Interviews within" value={interviews.toString()} onChange={ ({target}) => setInterviews(+target.value) } sx={filterElementStyle}>
                    <MenuItem value="1">1 month</MenuItem>
                    <MenuItem value="2">2 months</MenuItem>
                    <MenuItem value="3">3 months</MenuItem>
                    <MenuItem value="4">4 months</MenuItem>
                    <MenuItem value="5">5 months</MenuItem>
                    <MenuItem value="6">6 month</MenuItem>
                    <MenuItem value="7">7 months</MenuItem>
                    <MenuItem value="8">8 months</MenuItem>
                    <MenuItem value="9">9 months</MenuItem>
                    <MenuItem value="10">10 months</MenuItem>
                    <MenuItem value="11">11 months</MenuItem>
                    <MenuItem value="12">12 months</MenuItem>
                </TextField>
                <TextField select label="Offers within" value={offers.toString()} onChange={ ({target}) => setOffers(+target.value) } sx={filterElementStyle}>
                    <MenuItem value="1">1 month</MenuItem>
                    <MenuItem value="2">2 months</MenuItem>
                    <MenuItem value="3">3 months</MenuItem>
                    <MenuItem value="4">4 months</MenuItem>
                    <MenuItem value="5">5 months</MenuItem>
                    <MenuItem value="6">6 month</MenuItem>
                    <MenuItem value="7">7 months</MenuItem>
                    <MenuItem value="8">8 months</MenuItem>
                    <MenuItem value="9">9 months</MenuItem>
                    <MenuItem value="10">10 months</MenuItem>
                    <MenuItem value="11">11 months</MenuItem>
                    <MenuItem value="12">12 months</MenuItem>
                </TextField>
                <TextField select label="Placements within" value={placements.toString()} onChange={ ({target}) => setPlacements(+target.value) } sx={filterElementStyle}>
                    <MenuItem value="1">1 month</MenuItem>
                    <MenuItem value="2">2 months</MenuItem>
                    <MenuItem value="3">3 months</MenuItem>
                    <MenuItem value="4">4 months</MenuItem>
                    <MenuItem value="5">5 months</MenuItem>
                    <MenuItem value="6">6 month</MenuItem>
                    <MenuItem value="7">7 months</MenuItem>
                    <MenuItem value="8">8 months</MenuItem>
                    <MenuItem value="9">9 months</MenuItem>
                    <MenuItem value="10">10 months</MenuItem>
                    <MenuItem value="11">11 months</MenuItem>
                    <MenuItem value="12">12 months</MenuItem>
                </TextField>
                <Button variant="contained" color="success" onClick={getDataCallback}>Run Report</Button>
            </Box>
            <Typography component="div" variant="h6" mt={2}>Report Description</Typography>
            <Typography component="div" variant="body2" mb={2}>{description}</Typography>
            {showGrid &&
                <>
                    <Box pb="10px" ml="auto">
                        <Button variant="contained" color="success" onClick={exportAsExcelHandler}>Export As Excel</Button>
                    </Box>
                    <GridWithStateWrapper
                        density="compact"
                        gridName="reports-internalinterviews"
                        rows={rows}
                        columns={columns}
                        apiRef={apiRef}
                        getRowId={r => r.CandidateID}
                        disableRowSelectionOnClick
                    />
                </>
            }
        </>
    );
}
