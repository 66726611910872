import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import moment from "moment";
import React, { useCallback, useState, useEffect } from "react";
import * as XLSX from "xlsx";
import { GetAdvertsAnalysisSnapshotReportData } from "services/AdvertsService";
import { ReportDataProcessDateFields } from "util/ReportUtils";

interface Props {
    description: string,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void
}

export default function AdvertsAnalysisSnapshotReport({ description, loadingHandler, errorHandler }: Props) {
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');

    useEffect(() => {
        const m = moment();
        setFromDate(m.startOf('month').format('YYYY-MM-DD'));
        setToDate(m.endOf('month').format('YYYY-MM-DD'));
    }, []);

    const getDataCallback = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        const wb = XLSX.utils.book_new();
        const m = moment();
        const filename = `AdvertsAnalysisSnapshot${m.format('YYYYMMDDhhmmss')}.xlsx`;
        const res = await GetAdvertsAnalysisSnapshotReportData(fromDate, toDate, errorHandler);
        if (res["data1"]) {
            const ws = XLSX.utils.json_to_sheet(res["data1"]);
            XLSX.utils.book_append_sheet(wb, ws, "Summary");
        }
        if (res["data2"]) {
            ReportDataProcessDateFields(res["data2"] as any[]);
            const ws = XLSX.utils.json_to_sheet(res["data2"]);
            XLSX.utils.book_append_sheet(wb, ws, "Summary By Consultant");
        }
        if (res["data3"]) {
            const ws = XLSX.utils.json_to_sheet(res["data3"]);
            XLSX.utils.book_append_sheet(wb, ws, "Detailed");
        }
        if (res["data4"]) {
            ReportDataProcessDateFields(res["data4"] as any[]);
            const ws = XLSX.utils.json_to_sheet(res["data4"]);
            XLSX.utils.book_append_sheet(wb, ws, "Consultant Report");
        }
        if (res["data5"]) {
            const ws = XLSX.utils.json_to_sheet(res["data5"]);
            XLSX.utils.book_append_sheet(wb, ws, "Consultant Report By Open Job");
        }
        XLSX.writeFile(wb, filename);
        loadingHandler && loadingHandler(false);
    }, [fromDate, toDate, errorHandler, loadingHandler]);

    return (
        <>
            <Box display="flex" justifyContent="flex-end">
                <Button variant="contained" color="success" onClick={getDataCallback}>Run Report</Button>
            </Box>
            <Typography component="div" variant="h6" mt={2}>Report Description</Typography>
            <Typography component="div" variant="body2">{description}</Typography>
        </>
    );
}
