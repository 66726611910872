import { MandatoryTagsTriggers, TagSettings, TagValue } from "../common/models/Configuration/Tags";
import { ErrorHandler, RequestOptions } from "../common/models/GenericTypes";
import { Delete, Get, Post, Put } from "../util/HttpUtils";

export async function GetCustomerTags(entityId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<TagValue[]>(`api/Tags/${entityId}`, options);
}

export async function GetCustomerTagsIncludeStructured(entityId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<TagValue[]>(`api/Tags/${entityId}?useStructured=true`, options);
}

export async function GetMyCustomerTags(entityId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<TagValue[]>(`api/Tags/Me/${entityId}`, options);
}

export async function GetRecordTags(entityId: number, recordId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<TagValue[]>(`api/Tags/${entityId}/${recordId}`, options);
}

export async function BulkAddTags(entityId: number, recordIds: number[], tags: TagValue[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>(`api/Tags/${entityId}/Bulk`, options, { recordIds, tags });
}

export async function AddTagsToRecord(entityId: number, recordId: number, tags: TagValue[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>(`api/Tags/${entityId}/${recordId}`, options, tags);
}

export async function RemoveTagsFromRecord(entityId: number, recordId: number, tags: TagValue[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Delete<boolean>(`api/Tags/${entityId}/${recordId}`, options, tags);
}

export async function GetAllTagsSettings(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<TagSettings[]>('api/Tags/Settings/all', options);
}

export async function GetTagsSettings(entityId: number, onlyActive: boolean, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<TagSettings[]>(`api/Tags/Settings/${entityId}?onlyActive=${onlyActive}`, options);
}

export async function UpdateTagsSettings(entityId: number, isCopyToAllUsers: boolean, tagsSettings: TagSettings[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/Tags/Settings/${entityId}`, options, { isCopyToAllUsers, tagsSettings });
}

export async function GetTagValues(entityId: number,tagTypeId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<TagValue[]>(`api/Tags/Values/${entityId}/${tagTypeId}`, options);
}

export async function SaveTagValues(entityId: number, tagTypeId: number, values: TagValue[], deletedIds: number[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>(`api/tags/Values/${entityId}/${tagTypeId}`, options, { values, deletedIds });
}

export async function GetMandatoryTagsTriggers(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<MandatoryTagsTriggers>('api/tags/MandatoryTagsTriggers', options);
}

export async function UpdateMandatoryTagsTriggers(triggers: MandatoryTagsTriggers, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>('api/tags/MandatoryTagsTriggers', options, triggers);
}

export async function GetMyTagsSettings(entityId: number, onlyActive: boolean, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<TagSettings[]>(`api/Tags/Settings/${entityId}/Users/Me?onlyActive=${onlyActive}`, options);
}

export async function GetUserTagsSettings(userId: number, entityId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<TagSettings[]>(`api/Tags/Settings/${entityId}/Users/${userId}`, options);
}

export async function UpdateUserTagsSettings(userId: number, entityId: number, settings: TagSettings[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/Tags/Settings/${entityId}/users/${userId}`, options, settings);
}

export async function GetTagsOverview(entityId: number, tagTypeId: number, taggedBy: number, lookupText: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    const encodedLookup = lookupText ? `&lookup=${encodeURIComponent(lookupText)}` : '';

    return Get<TagValue[]>(`api/Tags/Overviews/${entityId}?tagTypeId=${tagTypeId}&taggedBy=${taggedBy}${encodedLookup}`, options);
}
