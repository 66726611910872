import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { TagFilter } from "common/models/Configuration/Tags";
import UserPicker from "components/Pickers/UserPicker";
import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import PageContentLayout from "layouts/PageContentLayout";
import PageLayout from "layouts/PageLayout";
import Box from "@mui/material/Box";
import Switch from "@mui/material/Switch";
import TagsSearchFilter from "components/Tags/TagsSearchFilter";
import OpportunitiesGridComponent from "components/Grids/OpportunitiesGrid";
import { OpportunitySearch } from "common/models/Search/OpportunitySearch";
import Snackbar from "@mui/material/Snackbar";
import Alert from "components/Alert";
import { NameIdObj } from "common/models/GenericTypes";
import { SxProps } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

const ownerSpecialOptions: NameIdObj[] = [
    { id: -1, name: 'Me' },
    { id: -2, name: 'My Team' },
    { id: 0, name: 'My Company' },
    { id: -3, name: 'Unassigned' },
];

const filterElementStyle: SxProps = { flex: '1 1 0' };

export default function OpportunitiesSearchPage() {
    const [activeTab, setActiveTab] = useState('criteria');
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [searchData, setSearchData] = useState<OpportunitySearch>();
    const [ownerId, setOwnerId] = useState(0);
    const [statusId, setStatusId] = useState(0);
    const [typeId, setTypeId] = useState(0);
    const [probability, setProbability] = useState('0_100');
    const [dateCreated, setDateCreated] = useState('All');
    const [closeDate, setCloseDate] = useState('All');
    const [taggedBy, setTaggedBy] = useState<'a' | 'm'>('a');
    const [taggedWithin, setTaggedWithin] = useState(999);
    
    const [isIncludeTag, setIsIncludeTag] = useState(true);
    const [includeTags, setIncludeTags] = useState<TagFilter[]>([]);
    const [excludeTags, setExcludeTags] = useState<TagFilter[]>([]);
    const [availableTags_All, setAvailableTags_All] = useState<TagFilter[]>([]);
    const [availableTags_Me, setAvailableTags_Me] = useState<TagFilter[]>([]);
    
    const searchCallback = useCallback(() => {
        let s: OpportunitySearch = {
            owner: ownerId,
            status: statusId,
            type: typeId,
            closeDate: closeDate,
            dateCreated: dateCreated,
            probability: probability,
            taggedTimeRange: taggedWithin
        };

        if (includeTags.length > 0) s.includeTags = includeTags;
        if (excludeTags.length > 0) s.excludeTags = excludeTags;

        setSearchData(s);
        setActiveTab('results');
    }, [ownerId, statusId, typeId, closeDate, dateCreated, probability, taggedWithin, includeTags, excludeTags]);

    const clearCallback = useCallback(() => {
        setOwnerId(0);
        setStatusId(0);
        setTypeId(0);
        setProbability('0_100');
        setDateCreated('All');
        setCloseDate('All');
        setIncludeTags([]);
        setExcludeTags([]);
    }, []);

    const summaryBar = useMemo(() => {
        const clearAction = <Button variant="contained" color="error" onClick={clearCallback} sx={{ mr: '5px' }}>Clear</Button>;
        const searchAction = <Button variant="contained" color="success" onClick={searchCallback}>Search</Button>
        return <TitleAndActionSummaryBar title="Opportunities > Search" browserTabTitle="Search > Opportunities" action={<>{clearAction}{searchAction}</>} />
    }, [clearCallback, searchCallback]);

    const handleRemoveCallback = useCallback((data: TagFilter) => {
        const includeIndex = includeTags.findIndex(t => t.typeId === data.typeId && t.tag === data.tag);

        if (includeIndex !== -1) {
            setIncludeTags(prev => {
                let tmp = [...prev];
                const index = tmp.findIndex(t => t.typeId === data.typeId && t.tag === data.tag);
                if (index !== -1) tmp.splice(index, 1);
                return tmp;
            });
        }
        else {
            setExcludeTags(prev => {
                let tmp = [...prev];
                const index = tmp.findIndex(t => t.typeId === data.typeId && t.tag === data.tag);
                if (index !== -1) tmp.splice(index, 1);
                return tmp;
            });
        }

    }, [includeTags]);

    const handleAddCallback = useCallback((typeId: number, tag: string) => {
        const selectedTag = (taggedBy === 'a' ? availableTags_All : availableTags_Me).find(t => t.typeId === typeId && t.tag === tag);
        if (isIncludeTag) {
            setIncludeTags(prev => {
                const assignedTagIndex = prev.findIndex(t => t.typeId === typeId && t.tag === tag);
                if (assignedTagIndex === -1 && selectedTag) {
                    return [...prev, {...selectedTag, source: taggedBy}]
                }
                return prev;
            });
        }
        else {
            setExcludeTags(prev => {
                const assignedTagIndex = prev.findIndex(t => t.typeId === typeId && t.tag === tag);
                if (assignedTagIndex === -1 && selectedTag) {
                    return [...prev, {...selectedTag, source: taggedBy}]
                }
                return prev;
            });

        }
    }, [isIncludeTag, availableTags_All, availableTags_Me, taggedBy]);

    useEffect(() => {
        const item = document.querySelector<HTMLElement>('#opportunity-search-collapse-root>.MuiCollapse-wrapper>.MuiCollapse-wrapperInner');
        if (item) {
            item.style.display = 'flex';
            item.style.flexDirection = 'column';
        }
    }, []);

    const handleTabChange = useCallback((e: React.SyntheticEvent, newValue: string) => {
        setActiveTab(newValue);
    }, []);
    
    return (
        <PageLayout SummaryBar={summaryBar} paddingTop={0}>
            <Snackbar open={successMessage !== ''} autoHideDuration={3000} onClose={() => setSuccessMessage('')}>
                <Alert onClose={() => setSuccessMessage('')}>Changes Saved</Alert>
            </Snackbar>
            <Snackbar open={errorMessage !== ''} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <Tabs value={activeTab} onChange={handleTabChange}>
                <Tab value="criteria" label="Criteria" />
                <Tab value="results" label="Results"/>
            </Tabs>
            <PageContentLayout showLoading={isLoading}>
                <Stack spacing={2} paddingTop={2} display={activeTab === 'criteria' ? undefined : 'none'}>
                    <Box display="flex" gap={1}>
                        <UserPicker label="Owner" userId={ownerId} onSelect={u => u && setOwnerId(u.id)} appendToStart={ownerSpecialOptions} sx={filterElementStyle} />
                        <TextField select label="Status" value={statusId.toString()} onChange={({ target }) => setStatusId(+target.value)} sx={filterElementStyle}>
                            <MenuItem value="0">All</MenuItem>
                            <MenuItem value="1">Open</MenuItem>
                            <MenuItem value="2">Closed</MenuItem>
                        </TextField>
                        <TextField select label="Type" value={typeId.toString()} onChange={({ target }) => setTypeId(+target.value)} sx={filterElementStyle}>
                            <MenuItem value="0">All</MenuItem>
                            <MenuItem value="1">Back Fill</MenuItem>
                            <MenuItem value="4">Bid</MenuItem>
                            <MenuItem value="3">Float</MenuItem>
                            <MenuItem value="5">General</MenuItem>
                            <MenuItem value="6">Lead</MenuItem>
                            <MenuItem value="2">Tender</MenuItem>
                        </TextField>
                    </Box>

                    <Box display="flex" gap={1}>
                        <TextField select label="Probability" value={probability} onChange={({ target }) => setProbability(target.value)} sx={filterElementStyle}>
                            <MenuItem value="0_100">All</MenuItem>
                            <MenuItem value="0_25">{'Very Low (< 25%)'}</MenuItem>
                            <MenuItem value="25_50">{'Low  (25% - 50%)'}</MenuItem>
                            <MenuItem value="50_75">{'Medium (50% - 75%)'}</MenuItem>
                            <MenuItem value="75_90">{'High (75% - 90%)'}</MenuItem>
                            <MenuItem value="90_100">{'Very High (90% - 100%)'}</MenuItem>
                        </TextField>
                        <TextField select label="Date Created" value={dateCreated} onChange={({ target }) => setDateCreated(target.value)} sx={filterElementStyle}>
                            <MenuItem value="All">All</MenuItem>
                            <MenuItem value="Within_1">Within 1 Month</MenuItem>
                            <MenuItem value="Within_2">Within 3 Months</MenuItem>
                            <MenuItem value="Within_6">Within 6 Months</MenuItem>
                            <MenuItem value="Within_12">Within 1 Year</MenuItem>
                            <MenuItem value="Within_24">Within 2 Years</MenuItem>
                            <MenuItem value="Outside_1">Outside 1 Month</MenuItem>
                            <MenuItem value="Outside_3">Outside 3 Months</MenuItem>
                            <MenuItem value="Outside_6">Outside 6 Months</MenuItem>
                            <MenuItem value="Outside_12">Outside 1 Year</MenuItem>
                            <MenuItem value="Outside_24">Outside 2 Years</MenuItem>
                        </TextField>
                        <TextField select label="Close Date" value={closeDate} onChange={({ target }) => setCloseDate(target.value)} sx={filterElementStyle}>
                            <MenuItem value="All">All</MenuItem>
                            <MenuItem value="Within_1">Within 1 Month</MenuItem>
                            <MenuItem value="Within_2">Within 3 Months</MenuItem>
                            <MenuItem value="Within_6">Within 6 Months</MenuItem>
                            <MenuItem value="Within_12">Within 1 Year</MenuItem>
                            <MenuItem value="Within_24">Within 2 Years</MenuItem>
                            <MenuItem value="Outside_1">Outside 1 Month</MenuItem>
                            <MenuItem value="Outside_3">Outside 3 Months</MenuItem>
                            <MenuItem value="Outside_6">Outside 6 Months</MenuItem>
                            <MenuItem value="Outside_12">Outside 1 Year</MenuItem>
                            <MenuItem value="Outside_24">Outside 2 Years</MenuItem>
                        </TextField>
                    </Box>


                    <TagsSearchFilter
                        entityId={8}
                        includeTags={includeTags}
                        excludeTags={excludeTags}
                        tagSource={taggedBy}
                        addHandler={handleAddCallback}
                        removeHandler={handleRemoveCallback}
                        setAvailableTagsHandler={taggedBy === 'a' ? setAvailableTags_All : setAvailableTags_Me}
                        loadingHandler={setIsLoading}
                        errorHandler={setErrorMessage}
                    >
                        <Box>
                            <Box>
                                Exclude
                                <Switch checked={isIncludeTag} onChange={({target}) => setIsIncludeTag(target.checked)} />
                                Include
                            </Box>
                            <TextField select label="Tagged By" value={taggedBy} onChange={ ({target}) => setTaggedBy(target.value === 'm' ? 'm' : 'a') }>
                                <MenuItem value="m">Me</MenuItem>
                                <MenuItem value="a">Everyone</MenuItem>
                            </TextField>
                            <TextField select label="Tagged Within" value={taggedWithin.toString()} onChange={ ({target}) => setTaggedWithin(+target.value) }>
                                <MenuItem value="999">Anytime</MenuItem>
                                <MenuItem value="1">1 Months</MenuItem>
                                <MenuItem value="2">2 Months</MenuItem>
                                <MenuItem value="3">3 Months</MenuItem>
                                <MenuItem value="6">6 Months</MenuItem>
                                <MenuItem value="12">1 Year</MenuItem>
                                <MenuItem value="24">2 Years</MenuItem>
                                <MenuItem value="36">3 Years</MenuItem>
                            </TextField>
                        </Box>
                    </TagsSearchFilter>
                </Stack>
                <Box display={activeTab === 'results' ? 'flex' : 'none'} flexGrow={1} flexDirection="column">
                    <OpportunitiesGridComponent gridName="opportunity-search" source="search" searchData={searchData} loadingHandler={setIsLoading} errorHandler={setErrorMessage} successHandler={setSuccessMessage} />
                </Box>
            </PageContentLayout>
        </PageLayout>
    );
}