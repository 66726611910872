import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { GridColDef } from "@mui/x-data-grid/models/colDef";
import { GridRenderCellParams } from "@mui/x-data-grid/models/params";
import { FilterWithTimeRange } from "common/models/Analytics/AnalyticsFilterData";
import { TopContactsByFeesRecord } from "common/models/Analytics/BusinessIntelligence";
import GridWithStateWrapper from "components/GridWidthStateWrapper";
import React, { useEffect, useMemo, useState } from "react";
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { TopContactsByFees } from "services/BusinessIntelligence";
import { TickWithHoverRenderer, VerticalBarChartYAxisWidth } from "util/AnalyticsGraphUtils";

interface Props {
    filterData: FilterWithTimeRange,
    graphType?: 'table' | 'graph',
    loadingHandler?: (isLoading: boolean) => void
}

interface TopContactsByFeesRecordWithId extends TopContactsByFeesRecord {
    id: number,
}

const gridName = 'analytics/top-contacts/by-fees';

export default function TopContactsByFeesContent({ filterData, graphType = 'graph', loadingHandler }: Props) {
    const [rows, setRows] = useState<TopContactsByFeesRecordWithId[]>([]);
    const t = useTheme();

    useEffect(() => {
        const getData = async () => {
            loadingHandler && loadingHandler(true);
            const res = await TopContactsByFees(filterData);
            if (res) {
                const data: TopContactsByFeesRecordWithId[] = res.map((r, i) => ({ ...r, id: i }));
                setRows(data);
            }
            loadingHandler && loadingHandler(false);
        };
        getData();
    }, [filterData, loadingHandler]);

    const columns = useMemo<GridColDef[]>(() => {
        const percentRenderer = (params: GridRenderCellParams) => {
            return `${params.value} %`
        };

        return [
            { field: 'contact', headerName: 'Contact', width: 250 },
            { field: 'perm', headerName: 'Perm Fees', headerAlign: 'center', align: 'right', width: 150 },
            { field: 'margin', headerName: 'Contract Margin', headerAlign: 'center', align: 'right', width: 150 },
            { field: 'total', headerName: 'Total', headerAlign: 'center', align: 'right' },
            { field: 'percentContract', headerName: '% Contract', headerAlign: 'center', align: 'right', renderCell: percentRenderer },
        ];
    }, []);

    if (graphType === 'table') {
        return (
            <GridWithStateWrapper
                gridName={gridName}
                rows={rows}
                density="compact"
                columns={columns}
                sx={{ mt: '20px' }}
            />
        );
    }
    else if (graphType === 'graph') {
        return (
            <>
                <Typography variant="h6" padding={1} textAlign="center" fontWeight={600} component="div">Top 20 Contacts by Fees</Typography>
                <div style={{ height: 'calc(100% - 48px)' }}>
                    <ResponsiveContainer width="100%" height="100%">
                        <BarChart width={150} height={150} layout="vertical" data={rows} margin={{ right: 50 }} stackOffset="positive">
                            <CartesianGrid strokeDasharray="3 3" vertical />
                            <XAxis type="number" tickFormatter={v => v.toLocaleString()} stroke={t.palette.text.primary} domain={[0, 'dataMax']} />
                            <YAxis dataKey="contact" type="category" tick={ TickWithHoverRenderer } stroke={t.palette.text.primary} width={VerticalBarChartYAxisWidth} minTickGap={0} interval={0} />
                            <Legend />
                            <Tooltip />
                            <Bar name="Margin" dataKey="margin" stackId="a" fill={t.palette.error.main} min={0} />
                            <Bar name="Perm" dataKey="perm" stackId="a" fill={t.palette.info.main} min={0} />
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </>
        );
    }

    return <></>;
}