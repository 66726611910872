import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React, { useCallback, useState } from "react";

interface Props {
    open: boolean,
    closeHandler: () => void,
    continueHandler: (isCopyTags: boolean, isCopyDocuments: boolean, isCopyCandidates: boolean) => void,
}

export default function CopyJobDialog({ open, closeHandler, continueHandler }: Props) {
    const [isCopyTags, setIsCopyTags] = useState(false);
    const [isCopyDocuments, setIsCopyDocuments] = useState(false);
    const [isCopyCandidates, setIsCopyCandidates] = useState(false);

    const onSubmitCallback = useCallback(() => {
        continueHandler(isCopyTags, isCopyDocuments, isCopyCandidates);
        closeHandler();
    }, [closeHandler, continueHandler, isCopyCandidates, isCopyDocuments, isCopyTags]);

    return (
        <Dialog open={open} fullWidth>
            <DialogTitle>Copy Job</DialogTitle>
            <DialogContent>
                <Stack>
                    <Typography>Please select additional info to copy</Typography>
                    <FormControlLabel
                        label="Tags"
                        labelPlacement="end"
                        control={
                            <Checkbox
                                checked={isCopyTags}
                                onChange={ () => setIsCopyTags(prev => !prev) }
                            />
                        }
                    />
                    <FormControlLabel
                        label="Documents"
                        labelPlacement="end"
                        control={
                            <Checkbox
                                checked={isCopyDocuments}
                                onChange={ () => setIsCopyDocuments(prev => !prev) }
                            />
                        }
                    />
                    <FormControlLabel
                        label="Candidates"
                        labelPlacement="end"
                        control={
                            <Checkbox
                                checked={isCopyCandidates}
                                onChange={ () => setIsCopyCandidates(prev => !prev) }
                            />
                        }
                    />
                    <Typography variant="caption">{'*Only candidates that are rated Green will be copied'}</Typography>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="error" onClick={ closeHandler }>Cancel</Button>
                <Button variant="contained" color="success" onClick={ onSubmitCallback } >Ok</Button>
            </DialogActions>
        </Dialog>
    );
}