import { LoginResponse } from '../common/models/LoginResponse';
import { Get, Post, Put } from '../util/HttpUtils';
import { ErrorHandler, RequestOptions } from '../common/models/GenericTypes';

export async function Authenticate(Username: string, Password: string, errorHandler?: ErrorHandler): Promise<LoginResponse | null> {
    const options: RequestOptions = {
        needsAuth: false,
        errorHandler
    };

    return Post<LoginResponse>('api/Auth', options, {Username, Password});
}

export async function Impersonate(Token: string, CheckSum: string, errorHandler?: ErrorHandler): Promise<LoginResponse | null> {
    const options: RequestOptions = {
        needsAuth: false,
        errorHandler
    };

    return Post<LoginResponse>('api/Auth/Impersonate', options, { Token, CheckSum });
}

export async function ForgotPassword(Username: string, errorHandler?: ErrorHandler): Promise<boolean | null> {
    const options: RequestOptions = {
        needsAuth: false,
        errorHandler,
        noDataReturned: true
    };

    return Post<boolean>('api/Auth/ForgotPassword', options, { Username });
}

export async function ChangePassword(Token: string, NewPassword: string, errorHandler?: ErrorHandler): Promise<boolean | null> {
    const options: RequestOptions = {
        needsAuth: false,
        errorHandler,
        noDataReturned: true
    };

    return Post<boolean>('api/Auth/RecoverPassword', options, { Token, NewPassword });
}

export async function ChangeMyPassword(NewPassword: string, errorHandler?: ErrorHandler): Promise<boolean | null> {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler,
        noDataReturned: true
    };

    return Put<boolean>('api/Auth/ChangePassword', options, { Token: '', NewPassword });
}

interface ExternalAuthUrl { url: string };
export async function ExternalAuth(Provider: string, errorHandler?: ErrorHandler): Promise<ExternalAuthUrl | null> {
    const options: RequestOptions = {
        needsAuth: false,
        errorHandler
    };

    return Get<ExternalAuthUrl>(`api/Auth/External/${Provider}`, options);
}

export async function ExternalAuthCallback(Provider: string, SearchParams: string, errorHandler: ErrorHandler): Promise<LoginResponse | null> {
    const endpoint = `api/Auth/ExternalCallback/${Provider}` + SearchParams;
    const options: RequestOptions = {
        needsAuth: false,
        errorHandler
    };

    return Post<LoginResponse>(endpoint, options);
}