import React, { useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import TagSearchPageContent from "components/Content/TagSearchPageContent";
import JobsGridComponent from "components/Grids/JobsGrid";
import OnlyTitleSummaryBar from "components/SummaryBars/OnlyTitleSummaryBar";
import PageContentLayout from "layouts/PageContentLayout";
import PageLayout from "layouts/PageLayout";

export default function JobTagsPage() {
    const [isLoading, setIsLoading] = useState(false);
    const [searchParams] = useSearchParams();

    const tagTypeId = useMemo(() => {
        const val = searchParams.get('tagType');
        if (val) return +val;
        return 0;
    }, [searchParams]);

    const taggedBy = useMemo(() => {
        const val = searchParams.get('taggedBy');
        if (val) return +val;
        return 0;
    }, [searchParams]);

    const tag = useMemo(() => {
        const val = searchParams.get('tag');
        if (val) return val;
        return '';
    }, [searchParams]);

    const showClientsByTag = useMemo(() => {
        return tagTypeId !== 0 && tag !== '';
    }, [tagTypeId, tag]);

    const summaryBar = useMemo(() => {
        if (showClientsByTag) return <OnlyTitleSummaryBar title={`Jobs tagged with "${tag}"`} browserTabTitle="Tags > Jobs" />
        return <OnlyTitleSummaryBar title="Jobs > Tags" browserTabTitle="Tags > Jobs" />;
    }, [tag, showClientsByTag]);
    
    return (
        <PageLayout SummaryBar={summaryBar}>
            <PageContentLayout showLoading={ isLoading }>
                {!showClientsByTag && <TagSearchPageContent entityType="jobs" loadingHandler={setIsLoading} />}
                {showClientsByTag && <JobsGridComponent gridName="jobs-tags" source="tags" jobState={0} tagLabel={tag} tagTypeId={tagTypeId} taggedBy={taggedBy} loadingHandler={setIsLoading} />}
            </PageContentLayout>
        </PageLayout>
    );
}