import Autocomplete, { AutocompleteInputChangeReason } from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import TextField from "@mui/material/TextField";
import React, { useCallback, useEffect, useState } from "react";
import { Client } from "common/models/Clients";
import { ClientLookup, GetClientById } from "services/ClientsService";
import QuickCreateClientDialog from "../Dialogs/Clients/QuickCreateClientDialog";

interface Props {
    value: number | null,
    onSelectCallback: (c: Client | null) => void,
    variant?: "standard" | "filled" | "outlined" | undefined
    label?: string,
    delayMs?: number,
    hideLabel?: boolean,
    disabled?: boolean,
    loadingHandler?: (isLoading: boolean) => void,
    successHandler?: (message: string) => void,
    errorHandler?: (message: string) => void,
}

export default function ClientPicker({ value, label = 'Client', variant, hideLabel = false, delayMs = 200, disabled, onSelectCallback, errorHandler, loadingHandler, successHandler }: Props) {
    const [selectedClient, setSelectedClient] = useState<Client | null>(null);
    const [lookupTerm, setLookupTerm] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState<Client[]>([]);
    const [showQuickCreateDialog, setShowQuickCreateDialog] = useState(false);

    const getClient = useCallback(async (clientId: number) => {
        const res = await GetClientById(clientId);
        if (res) setSelectedClient(res);
    }, []);

    useEffect(() => {
        if (value) {
            if (!selectedClient || (selectedClient && selectedClient.id !== value)) {
                const item = options.find(o => o.id === value);
                if (item) setSelectedClient(item);
                else getClient(value);
            }
        }
        else setSelectedClient(null);
    }, [value, selectedClient, options, getClient]);

    useEffect(() => {
        if (lookupTerm.length > 0) {
            const delaySearch = setTimeout(async () => {
                setIsLoading(true);
                const res = await ClientLookup(lookupTerm, 25);
                if (res) setOptions(res);
                setIsLoading(false);
            }, delayMs);
            return () => clearTimeout(delaySearch);
        }
    }, [lookupTerm, delayMs]);

    const handleInputChange = useCallback((event: React.SyntheticEvent<Element, Event>, value: string, reason: AutocompleteInputChangeReason) => {
        reason !== 'reset' && setLookupTerm(value);
    }, []);

    return (
        <Box display="flex">
            <QuickCreateClientDialog
                open={showQuickCreateDialog}
                name={lookupTerm}
                closeHandler={() => setShowQuickCreateDialog(false)}
                onClientCreated={ onSelectCallback }
                errorHandler={errorHandler}
                loadingHandler={loadingHandler}
                successHandler={successHandler}
            />
            <Autocomplete
                size="small"
                fullWidth
                options={options}
                value={ selectedClient }
                getOptionLabel={ o => o.legalName }
                isOptionEqualToValue={ (o,v) => o.id === v.id }
                loading={isLoading}
                disabled={disabled}
                filterOptions={ x => x }
                onChange={ (e,v) => onSelectCallback(v) }
                renderInput={ (params) => (
                    <TextField {...params} variant={variant} label={hideLabel ? undefined : label} inputProps={{ ...params.inputProps, "data-lpignore": "true" }}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (<>{isLoading ? <CircularProgress color="inherit" size={20} /> : params.InputProps.endAdornment}</>)
                        }}
                    />
                )}
                onInputChange={ handleInputChange }
                renderOption={(props, option) => (
                    <ListItem {...props} key={option.id}>
                        <ListItemText
                            primary={option.legalName} 
                            secondary={(
                                <>
                                    {option.tradingName && <Box>{option.tradingName}</Box>}
                                    {option.address1 && <Box>{option.address1}</Box>}
                                </>
                            )}
                        />
                    </ListItem>
                )}
            />
            <IconButton onClick={() => setShowQuickCreateDialog(true)} disabled={disabled}><AddCircleIcon /></IconButton>
        </Box>
    );
}