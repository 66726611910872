import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Document } from "common/models/Document";
import { DocumentLookup, GetDocumentById } from "services/DocumentsService";
import Autocomplete from "@mui/material/Autocomplete/Autocomplete";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import Box from "@mui/material/Box";
import GenerateFormattedCvDialog from "components/Dialogs/Documents/GenerateFormattedCvDialog";
import { OneDriveMoveToDocLibrary, OneDriveUploadFromDocLibrary } from "services/UserIntegrationsService";
import { OneDriveFile } from "common/models/Integrations/OneDrive";
import InformationDialog from "components/Dialogs/Generic/InformationDialog";
import ConfirmationDialog from "components/Dialogs/Generic/ConfirmationDialog";
import moment from "moment";
import { UploadFormattedCVToCandidate } from "services/CandidatesService";

interface Props {
    value: number | null,
    sourceId: number,
    jobId?: number,
    applicationId?: number,
    lookupType: 'submission' | 'ref-check' | 'job-order',
    variant?: "standard" | "filled" | "outlined" | undefined,
    label?: string,
    hideLabel?: boolean,
    disableClearable?: boolean,
    disableQuickCreate?: boolean,
    blurOnSelect?: boolean,
    error?: boolean,
    appendToStart?: Document[],
    onSelectCallback: (d: Document | null) => void,
    loadingHandler?: (isLoading: boolean) => void,
    successHandler?: (message: string) => void,
    errorHandler?: (message: string) => void,
}

const getDateString = (date: string | null) => {
    if (date) {
        const m = moment(date);
        if (m.isValid()) return m.format('DD MMM YYYY');
    }

    return '';
};

export default function DocumentPicker({ value, sourceId, jobId = 0, applicationId = 0, lookupType, appendToStart, variant, label = 'Document', hideLabel = false, disableClearable = false, disableQuickCreate = false, blurOnSelect, error, onSelectCallback, loadingHandler, successHandler, errorHandler }: Props) {
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState<Document[]>([]);
    const [showCreateDialog, setShowCreateDialog] = useState(false);
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [showConfirmEditDialog, setShowConfirmEditDialog] = useState(false);
    const [oneDriveFile, setOneDriveFile] = useState<OneDriveFile>();
    const inputFileRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        const getOptions = async () => {
            setIsLoading(true);
            const res = await DocumentLookup(sourceId, lookupType, '', errorHandler);
            if (res) {
                res.sort((a, b) => ((b.createdDate < a.createdDate) ? -1 : (b.createdDate > a.createdDate) ? 1 : 0));
                setOptions(res);
            }
            setIsLoading(false);
        };
        getOptions();
    }, [sourceId, lookupType, errorHandler]);

    const selectedDocument = useMemo<Document | null>(() => {
        if (!isLoading && value) {
            const doc = options.find(d => d.id === value);
            if (doc) return doc;
            if (appendToStart) {
                const appendOption = appendToStart.find(a => a.id === value);
                if (appendOption) return appendOption;
            }
        }
        return null;
    }, [isLoading, value, options, appendToStart]);

    const onDocumentCreatedHandler = useCallback(async (documentId: number) => {
        if (documentId) {
            const d = options.find(o => o.id === documentId);
            if (d) {
                onSelectCallback(d);
                return;
            }
            const res = await GetDocumentById(documentId);
            if (res) {
                setOptions(prev => [...prev, res]);
                onSelectCallback(res);
            }
        }
    }, [onSelectCallback, options]);

    const isSelectedDocx = useMemo(() => {
        if (selectedDocument) return selectedDocument.documentName.toLowerCase().endsWith('.docx');
        return false;
    }, [selectedDocument]);

    const editDocumentCallback = useCallback(async () => {
        if (selectedDocument) {
            loadingHandler && loadingHandler(true);
            const res = await OneDriveUploadFromDocLibrary(selectedDocument.id, errorHandler);
            if (res) {
                setOneDriveFile(res);
                setShowEditDialog(true);
                window.location.replace('ms-word:ofe|u|' + res.webDavUrl);
            }
            loadingHandler && loadingHandler(false);
        }
    }, [errorHandler, loadingHandler, selectedDocument]);

    const editConfirmationRejectHandler = useCallback(() => {
        setShowConfirmEditDialog(false);
        setShowEditDialog(false);
    }, []);

    const editConfirmationAcceptHandler = useCallback(async () => {
        if (selectedDocument && oneDriveFile) {
            loadingHandler && loadingHandler(true);
            const res = await OneDriveMoveToDocLibrary(selectedDocument.id, oneDriveFile.id, errorHandler);
            if (res) {
                setShowConfirmEditDialog(false);
                setShowEditDialog(false);
                successHandler && successHandler('File Edited');
            }
            loadingHandler && loadingHandler(false);
        }
    }, [errorHandler, loadingHandler, oneDriveFile, selectedDocument, successHandler]);

    const editDialogContinueHandler = useCallback(() => {
        setShowEditDialog(false);
        setShowConfirmEditDialog(true);
    }, []);

    const allOptions = useMemo(() => {
        if (appendToStart) return [...appendToStart, ...options];
        return options;
    }, [appendToStart, options]);

    const onFileUploadClick = useCallback(() => {
        if (inputFileRef.current) inputFileRef.current.click();
    }, []);

    const onFileUploadChange = useCallback(async () => {
        const ref = inputFileRef.current;
        if (lookupType === 'submission' && ref && ref.files && ref.files.length === 1) {
            const file = ref.files[0];
            loadingHandler && loadingHandler(true);
            const res = await UploadFormattedCVToCandidate(sourceId, file, errorHandler);
            if (res) onDocumentCreatedHandler(res.value);
            loadingHandler && loadingHandler(false);
        }
    }, [errorHandler, loadingHandler, lookupType, onDocumentCreatedHandler, sourceId]);

    return (
        <>
            { lookupType === 'submission' && <input type="file" ref={inputFileRef} onChange={onFileUploadChange} style={{ display: 'none' }} />}
            <Box display="flex">
                <GenerateFormattedCvDialog
                    open={showCreateDialog}
                    candidateId={sourceId}
                    jobId={jobId}
                    applicationId={applicationId}
                    closeHandler={() => setShowCreateDialog(false)}
                    onDocumentCreatedHandler={onDocumentCreatedHandler}
                    loadingHandler={loadingHandler}
                    errorHandler={errorHandler}
                    successHandler={successHandler}
                />
                <InformationDialog
                    open={showEditDialog}
                    fullWidth
                    message={
                        <Box textAlign="center">
                            <br/>
                            The selected file will open in Word. When you finish working on it press the <strong>Continue</strong> button.
                            <br/>
                            Please remember to <strong>Save Changes</strong> and <strong>Close Word</strong> before pressing the button
                        </Box>
                    }
                    actionBtnText="Continue"
                    onClose={editDialogContinueHandler}
                    title="Recruit Wizard File Editor"
                />
                <ConfirmationDialog
                    open={showConfirmEditDialog}
                    title="Confirm Action"
                    message="Have you Saved and Closed the file you were editing?"
                    onClose={editConfirmationRejectHandler}
                    onContinue={editConfirmationAcceptHandler}
                    cancelActionText="No"
                    confirmActionText="Yes"
                />
                <Autocomplete
                    size="small"
                    fullWidth
                    // options={options}
                    options={allOptions}
                    value={ selectedDocument }
                    getOptionLabel={ o => o.documentName }
                    isOptionEqualToValue={ (o,v) => o.id === v.id }
                    loading={isLoading}
                    onChange={ (e,v) => onSelectCallback(v) }
                    disableClearable={disableClearable}
                    blurOnSelect={blurOnSelect}
                    renderInput={ (params) => (
                        <TextField {...params} variant={variant} label={hideLabel ? undefined : label} inputProps={{ ...params.inputProps, "data-lpignore": "true" }} error={error}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (<>{isLoading ? <CircularProgress color="inherit" size={20} /> : params.InputProps.endAdornment}</>)
                            }}
                        />
                    )}
                    renderOption={(props, option) => (
                        <ListItem {...props} key={option.id}>
                            <ListItemText
                                primary={option.documentName}
                                secondary={`${option.createdByName} - ${getDateString(option.createdDate)}`}
                                secondaryTypographyProps={{ component: 'div' }}
                            />
                        </ListItem>
                    )}
                />
                <IconButton title="Generate File" onClick={() => setShowCreateDialog(true)}><AddCircleIcon /></IconButton>
                { isSelectedDocx && <IconButton title="Edit File" color="info" onClick={editDocumentCallback}><EditIcon /></IconButton> }
                {lookupType === 'submission' && <IconButton title="Upload File" onClick={onFileUploadClick}><FileUploadIcon /></IconButton>}
            </Box>
        </>
    );
}