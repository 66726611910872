import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { GetSingleSetting, UpdateSettingBySettingName } from "services/UsersService";
import { DefaultUserSettings } from "util/Definitions/Configuration/Users/Edit";

type Props = {
    open: boolean,
    closeHandler: () => void,
    continueHandler: (distributionMethod: string, locationType: string, duration: string) => void,
    loadingHandler?: (isLoading: boolean) => void
};

const DistributionMethodSettingName = 'DefaultDistributionMethod_Meetings';
const LocationTypeSettingName = 'DefaultMeetingLocationTypeId';
const DurationTypeSettingName = 'DefaultDuration_Meetings';
const OnboardingSettingName = 'MeetingsOnboardingUtcDate';
const DefaultDistributionMethod = DefaultUserSettings.DefaultDistributionMethod_Meetings;
const DefaultLocationType = DefaultUserSettings.DefaultMeetingLocationTypeId;
const DefaultDuration = DefaultUserSettings.DefaultDuration_Interviews;

export default function MeetingsOnboardingDialog({ open, closeHandler, continueHandler, loadingHandler }: Props) {
    const [distributionMethod, setDistributionMethod] = useState(DefaultDistributionMethod);
    const [locationType, setLocationType] = useState(DefaultLocationType);
    const [duration, setDuration] = useState(DefaultDuration);
    const [originalDistributionMethod, setOriginalDistributionMethod] = useState(DefaultDistributionMethod);
    const [originalLocationType, setOriginalLocationType] = useState(DefaultLocationType);
    const [originalDuration, setOriginalDuration] = useState(DefaultDuration);

    useEffect(() => {
        const getData = async () => {
            const dist = await GetSingleSetting(DistributionMethodSettingName);
            if (dist && dist.value) {
                setDistributionMethod(dist.value);
                setOriginalDistributionMethod(dist.value);
            }

            const ltype = await GetSingleSetting(LocationTypeSettingName);
            if (ltype && ltype.value) {
                setLocationType(ltype.value);
                setOriginalLocationType(ltype.value);
            }

            const dur = await GetSingleSetting(DurationTypeSettingName);
            if (dur && dur.value) {
                setDuration(dur.value);
                setOriginalDuration(dur.value);
            }
        };
        getData();
    }, []);

    const hasChanges = useMemo(() => distributionMethod !== originalDistributionMethod || locationType !== originalLocationType, [distributionMethod, locationType, originalDistributionMethod, originalLocationType]);

    const onContinueCallback = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        if (distributionMethod !== originalDistributionMethod) await UpdateSettingBySettingName(DistributionMethodSettingName, distributionMethod);
        if (locationType !== originalLocationType) await UpdateSettingBySettingName(LocationTypeSettingName, locationType);
        if (duration !== originalDuration) await UpdateSettingBySettingName(DurationTypeSettingName, duration);
        const m = moment.utc();
        UpdateSettingBySettingName(OnboardingSettingName, m.format('YYYY-MM-DDTHH:mm'));
        loadingHandler && loadingHandler(false);
        continueHandler(distributionMethod, locationType, duration);
    }, [continueHandler, distributionMethod, duration, loadingHandler, locationType, originalDistributionMethod, originalDuration, originalLocationType]);

    return (
        <Dialog open={open}>
            <DialogTitle>Meetings Onboarding</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Did you know you can set defaults for Meetings? You can change your defaults right now or do it later from the settings page on your account menu.
                </DialogContentText>
                <Stack spacing={2} sx={{ pt: 2 }}>
                    <TextField
                        select
                        label="Default Distribution Method"
                        value={distributionMethod}
                        onChange={({target}) => setDistributionMethod(target.value)}
                    >
                        <MenuItem value="CalendarInviteOnly">Calendar Invite</MenuItem>
                        <MenuItem value="CalendarInvitePlusEmail">Calendar Invite + Email</MenuItem>
                        <MenuItem value="NoneRecordOnly">None - Record In Recruit Wizard Only</MenuItem>
                    </TextField>
                    <TextField
                        select
                        label="Default Location Type"
                        value={locationType}
                        onChange={({target}) => setLocationType(target.value)}
                    >
                        <MenuItem value="1">In Person</MenuItem>
                        <MenuItem value="2">Phone Call</MenuItem>
                        <MenuItem value="3">Microsoft Teams</MenuItem>
                        {/*<MenuItem value="4">Zoom</MenuItem>*/}
                        <MenuItem value="5">Other</MenuItem>
                    </TextField>
                    <TextField
                        select
                        label="Default Duration"
                        value={duration}
                        onChange={({target}) => setDuration(target.value)}
                    >
                        <MenuItem value="0">None</MenuItem>
                        <MenuItem value="15">15 mins</MenuItem>
                        <MenuItem value="30">30 mins</MenuItem>
                        <MenuItem value="60">1 hour</MenuItem>
                        <MenuItem value="90">90 mins</MenuItem>
                        <MenuItem value="120">2 hours</MenuItem>
                        <MenuItem value="240">4 hours</MenuItem>
                        <MenuItem value="480">1 Day</MenuItem>
                    </TextField>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="error" onClick={ closeHandler } >Cancel</Button>
                <Button variant="contained" color="success" onClick={ onContinueCallback } >{ hasChanges ? 'Save Changes + Continue' : 'Continue' }</Button>
            </DialogActions>
        </Dialog>
    );
}