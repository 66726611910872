import React, { useCallback, useEffect, useMemo, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import moment from "moment";
import MenuItem from "@mui/material/MenuItem";
import { ChangePlacementPayFrequency } from "services/PlacementsService";
import { PlacementStatusEnum } from "util/Definitions/Configuration/Placements";
import ConfirmationDialog from "../Generic/ConfirmationDialog";

interface Props {
    open: boolean,
    placementId: number,
    payFrequency?: number,
    placementStatusId?: number,
    closeHandler: () => void,
    loadingHandler?: (isLoading: boolean) => void,
    successHandler?: (message: string, payFreqId: number) => void,
    errorHandler?: (message: string) => void
}

export default function ChangePayFrequencyDialog({ open, placementId, placementStatusId = 0, payFrequency: payFrequencyProp = 0, closeHandler, loadingHandler, successHandler, errorHandler }: Props) {
    const [showWarning, setShowWarning] = useState(false);
    const [payFrequency, setPayFrequency] = useState(0);

    const warningText = useMemo(() => {
        if (placementStatusId === PlacementStatusEnum.Completed) return 'This placement is already Completed. Are you sure you want to make this change?';
        if (placementStatusId === PlacementStatusEnum.PendingEarlyTermination || placementStatusId === PlacementStatusEnum.EarlyTerminated) return 'This placement has been Early Terminated. Are you sure you want to make this change?';
        return '';
    }, [placementStatusId]);

    useEffect(() => {
        if (open && Boolean(warningText)) setShowWarning(true);
    }, [open, warningText]);

    useEffect(() => {
        if (open) setPayFrequency(payFrequencyProp);
    }, [open, payFrequencyProp]);

    const effectiveDate = useMemo(() => {
        return moment().format('DD MMM YYYY');
    }, []);

    const submitCallback = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        const res = await ChangePlacementPayFrequency(placementId, payFrequency, errorHandler);
        if (res) {
            successHandler && successHandler('Pay Frequency Changed', payFrequency);
            closeHandler();
        }
        loadingHandler && loadingHandler(false);
    }, [placementId, payFrequency, loadingHandler, successHandler, errorHandler, closeHandler]);

    return (
        <>
            <ConfirmationDialog
                open={open && showWarning && Boolean(warningText)}
                message={warningText}
                onClose={closeHandler}
                title="Change Pay Frequency"
                onContinue={() => setShowWarning(false)}
            />
            <Dialog open={open && !showWarning} fullWidth>
                <DialogTitle>Change Pay Frequency</DialogTitle>
                <DialogContent>
                    <Stack mt="10px" spacing={2}>
                        <TextField label="Effective Date" value={effectiveDate} InputProps={{ readOnly: true }} />
                        <TextField select label="Pay Frequency" value={payFrequency.toString()} onChange={({target}) => setPayFrequency(+target.value)}>
                            <MenuItem value="0">None</MenuItem>
                            <MenuItem value="1">Weekly</MenuItem>
                            <MenuItem value="5">Weekly ( 1 Week in Arrears )</MenuItem>
                            <MenuItem value="6">Weekly ( 3 Weeks in Arrears )</MenuItem>
                            <MenuItem value="2">Fortnightly</MenuItem>
                            <MenuItem value="3">Monthly</MenuItem>
                            <MenuItem value="4">4 Weekly</MenuItem>
                        </TextField>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="error" onClick={ closeHandler }>Cancel</Button>
                    <Button variant="contained" color="success" disabled={payFrequency === payFrequencyProp || payFrequency === 0} onClick={ submitCallback } >Submit</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}