import React, { useMemo } from "react";
import GenericApplyLink from "./GenericApplyLink";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import WebsiteFeedsEdit from "./WebsiteFeedsEdit";
import JobsWidgetEdit from "./JobsWidgetEdit";
import WebsiteFeedsFTPEdit from "./WebsiteFeedsFTPEdit";
import BalanceRecruitmentWebsiteEdit from "./BalanceRecruitmentWebsiteEdit";
import JobAdderXMLFeedFTPUploadEdit from "./JobAdderXMLFeedFTPUploadEdit";
import LinkedInSocialEdit from "./LinkedInSocialEdit";
import LinkedInSocialView from "./LinkedInSocialView";
import LinkedInBasicJobsEdit from "./LinkedInBasicJobsEdit";
import LinkedInBasicJobsView from "./LinkedInBasicJobsView";
import AdzunaEdit from "./AdzunaEdit";
import AdzunaView from "./AdzunaView";
import JoraEdit from "./JoraEdit";
import LinkedInJobWrappingEdit from "./LinkedInJobWrappingEdit";
import LinkedInJobWrappingView from "./LinkedInJobWrappingView";
import ShazammeEdit from "./ShazammeEdit";
import ShazammeView from "./ShazammeView";
import TalentDotComEdit from "./TalentDotComEdit";
import DigitalMomentumEdit from "./DigitalMomentumEdit";
import TalentDotComView from "./TalentDotComView";
import MonsterEdit from "./MonsterEdit";
import MonsterView from "./MonsterView";
import IndeedEdit from "./IndeedEdit";
import IndeedView from "./IndeedView";
import JXTEdit from "./JXTEdit";
import JXTView from "./JXTView";
import VolcanicEdit from "./VolcanicEdit";
import VolcanicView from "./VolcanicView";
import RecruitDotNetEdit from "./RecruitDotNetEdit";
import CVLibraryEdit from "./CVLibraryEdit";
import CVLibraryView from "./CVLibraryView";
import WPJobManagerV2Edit from "./WPJobManagerV2Edit";
import WPJobManagerV2View from "./WPJobManagerV2View";
import IrishJobsEdit from "./IrishJobsEdit";
import IrishJobsView from "./IrishJobsView";
import JobServeEdit from "./JobServeEdit";
import JobServeView from "./JobServeView";
import CareerOneEdit from "./CareerOneEdit";
import CareerOneView from "./CareerOneView";
import RefariEdit from "./RefariEdit";
import RefariView from "./RefariView";
import JobsWidgetView from "./JobsWidgetView";
import DigitalMomentumView from "./DigitalMomentumView";
import WebsiteFeedsView from "./WebsiteFeedsView";
import JoraView from "./JoraView";
import RecruitDotNetView from "./RecruitDotNetView";
import BalanceRecruitmentWebsiteView from "./BalanceRecruitmentWebsiteView";
import WebsiteFeedsFTPView from "./WebsiteFeedsFTPView";
import JobAdderXMLFeedFTPUploadView from "./JobAdderXMLFeedFTPUploadView";
import SeekEdit from "./SeekEdit";
import SeekView from "./SeekView";

interface Props {
    jobBoardTypeId: number,
    jobBoardId: number,
    advertId: number,
    title?: string,
    type?: 'edit' | 'view',
    attributes?: Record<string, string>,
    advertRecordAttributes?: Record<string, string>,
    attributeChangeHandler?: (jobBoardId: number, key: string, value: string) => void,
    validationMessageHandler?: (jobBoardId: number, message: string) => void,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
}

enum JobBoardTypes {
    JXT = 4,
    LinkedInSocial = 9,
    DigitalMomentum = 11,
    WebsiteFeeds = 13,
    Indeed = 15,
    IrishJobs = 16,
    Jora = 17,
    Monster = 18,
    Adzuna = 20,
    Volcanic = 22,
    RecruitDotNet = 29,
    JobServe = 21,
    CVLibrary = 36,
    JobsWidget = 67,
    CareerOne = 68,
    LinkedInJobWrapping = 71,
    BalanceRecruitmentWebsite = 72,
    WebsiteFeedsFTP = 73,
    TalentDotCom = 77,
    LinkedInBasicJobs = 78,
    GenericApplyLink = 79,
    Shazamme = 80,
    WPJobManagerV2 = 81,
    Seek = 82,
    Refari = 83,
    JobAdderXMLFeedFTPUpload = 85,
};

export default function JobBoardLoaderComponent({ jobBoardId, jobBoardTypeId, advertId, title = '', type = 'view', attributes, advertRecordAttributes, attributeChangeHandler, validationMessageHandler, loadingHandler, errorHandler }: Props) {
    const renderedBoard = useMemo(() => {
        if (JobBoardTypes.JXT === jobBoardTypeId && type === 'view') return (
            <JXTView advertId={advertId} jobBoardId={jobBoardId} attributes={attributes} advertRecordAttributes={advertRecordAttributes} loadingHandler={loadingHandler} />
        );
        if (JobBoardTypes.JXT === jobBoardTypeId && type === 'edit') return (
            <JXTEdit advertId={advertId} jobBoardId={jobBoardId} attributes={attributes} advertRecordAttributes={advertRecordAttributes} attributeChangeHandler={attributeChangeHandler} loadingHandler={loadingHandler} />
        );
        if (JobBoardTypes.LinkedInSocial === jobBoardTypeId && type === 'view') return (
            <LinkedInSocialView advertId={advertId} jobBoardId={jobBoardId} attributes={attributes} />
        );
        if (JobBoardTypes.LinkedInSocial === jobBoardTypeId && type === 'edit') return (
            <LinkedInSocialEdit advertId={advertId} jobBoardId={jobBoardId} attributes={attributes} attributeChangeHandler={attributeChangeHandler} loadingHandler={loadingHandler} errorHandler={errorHandler} />
        );
        if (JobBoardTypes.DigitalMomentum === jobBoardTypeId && type === 'view') return (
            <DigitalMomentumView advertId={advertId} jobBoardId={jobBoardId} attributes={advertRecordAttributes} />
        );
        if (JobBoardTypes.DigitalMomentum === jobBoardTypeId && type === 'edit') return (
            <DigitalMomentumEdit advertId={advertId} jobBoardId={jobBoardId} attributes={advertRecordAttributes} attributeChangeHandler={attributeChangeHandler} />
        );
        if (JobBoardTypes.WebsiteFeeds === jobBoardTypeId && type === 'view') return (
            <WebsiteFeedsView advertId={advertId} jobBoardId={jobBoardId} attributes={advertRecordAttributes} />
        );
        if (JobBoardTypes.WebsiteFeeds === jobBoardTypeId && type === 'edit') return (
            <WebsiteFeedsEdit advertId={advertId} jobBoardId={jobBoardId} attributes={advertRecordAttributes} attributeChangeHandler={attributeChangeHandler} />
        );
        if (JobBoardTypes.Indeed === jobBoardTypeId && type === 'view') return (
            <IndeedView advertId={advertId} jobBoardId={jobBoardId} attributes={attributes} advertRecordAttributes={advertRecordAttributes} />
        );
        if (JobBoardTypes.Indeed === jobBoardTypeId && type === 'edit') return (
            <IndeedEdit advertId={advertId} jobBoardId={jobBoardId} attributes={attributes} advertRecordAttributes={advertRecordAttributes} attributeChangeHandler={attributeChangeHandler} />
        );
        if (JobBoardTypes.IrishJobs === jobBoardTypeId && type === 'view') return (
            <IrishJobsView advertId={advertId} jobBoardId={jobBoardId} attributes={attributes} />
        );
        if (JobBoardTypes.IrishJobs === jobBoardTypeId && type === 'edit') return (
            <IrishJobsEdit advertId={advertId} jobBoardId={jobBoardId} attributes={attributes} attributeChangeHandler={attributeChangeHandler} />
        );
        if (JobBoardTypes.Jora === jobBoardTypeId && type === 'view') return (
            <JoraView advertId={advertId} jobBoardId={jobBoardId} attributes={advertRecordAttributes} />
        );
        if (JobBoardTypes.Jora === jobBoardTypeId && type === 'edit') return (
            <JoraEdit advertId={advertId} jobBoardId={jobBoardId} attributes={advertRecordAttributes} attributeChangeHandler={attributeChangeHandler} />
        );
        if (JobBoardTypes.Monster === jobBoardTypeId && type === 'view') return (
            <MonsterView advertId={advertId} jobBoardId={jobBoardId} attributes={attributes} advertRecordAttributes={advertRecordAttributes} />
        );
        if (JobBoardTypes.Monster === jobBoardTypeId && type === 'edit') return (
            <MonsterEdit advertId={advertId} jobBoardId={jobBoardId} attributes={attributes} advertRecordAttributes={advertRecordAttributes} attributeChangeHandler={attributeChangeHandler} />
        );
        if (JobBoardTypes.Adzuna === jobBoardTypeId && type === 'view') return (
            <AdzunaView advertId={advertId} jobBoardId={jobBoardId} attributes={attributes} advertRecordAttributes={advertRecordAttributes} />
        );
        if (JobBoardTypes.Adzuna === jobBoardTypeId && type === 'edit') return (
            <AdzunaEdit advertId={advertId} jobBoardId={jobBoardId} attributes={attributes} advertRecordAttributes={advertRecordAttributes} attributeChangeHandler={attributeChangeHandler} />
        );
        if (JobBoardTypes.Volcanic === jobBoardTypeId && type === 'view') return (
            <VolcanicView advertId={advertId} jobBoardId={jobBoardId} attributes={attributes} advertRecordAttributes={advertRecordAttributes} loadingHandler={loadingHandler} errorHandler={errorHandler} />
        );
        if (JobBoardTypes.Volcanic === jobBoardTypeId && type === 'edit') return (
            <VolcanicEdit advertId={advertId} jobBoardId={jobBoardId} attributes={attributes} advertRecordAttributes={advertRecordAttributes} attributeChangeHandler={attributeChangeHandler} loadingHandler={loadingHandler} errorHandler={errorHandler} />
        );
        if (JobBoardTypes.RecruitDotNet === jobBoardTypeId && type === 'view') return (
            <RecruitDotNetView advertId={advertId} jobBoardId={jobBoardId} attributes={advertRecordAttributes} />
        );
        if (JobBoardTypes.RecruitDotNet === jobBoardTypeId && type === 'edit') return (
            <RecruitDotNetEdit advertId={advertId} jobBoardId={jobBoardId} attributes={advertRecordAttributes} attributeChangeHandler={attributeChangeHandler} />
        );
        if (JobBoardTypes.CVLibrary === jobBoardTypeId && type === 'view') return (
            <CVLibraryView advertId={advertId} jobBoardId={jobBoardId} attributes={attributes} advertRecordAttributes={advertRecordAttributes} />
        );
        if (JobBoardTypes.CVLibrary === jobBoardTypeId && type === 'edit') return (
            <CVLibraryEdit advertId={advertId} jobBoardId={jobBoardId} attributes={attributes} advertRecordAttributes={advertRecordAttributes} attributeChangeHandler={attributeChangeHandler} />
        );
        if (JobBoardTypes.JobServe === jobBoardTypeId && type === 'view') return (
            <JobServeView advertId={advertId} jobBoardId={jobBoardId} attributes={attributes} advertRecordAttributes={advertRecordAttributes} />
        );
        if (JobBoardTypes.JobServe === jobBoardTypeId && type === 'edit') return (
            <JobServeEdit advertId={advertId} jobBoardId={jobBoardId} attributes={attributes} advertRecordAttributes={advertRecordAttributes} attributeChangeHandler={attributeChangeHandler} />
        );
        if (JobBoardTypes.JobsWidget === jobBoardTypeId && type === 'view') return (
            <JobsWidgetView advertId={advertId} jobBoardId={jobBoardId} attributes={advertRecordAttributes} />
        );
        if (JobBoardTypes.JobsWidget === jobBoardTypeId && type === 'edit') return (
            <JobsWidgetEdit advertId={advertId} jobBoardId={jobBoardId} attributes={advertRecordAttributes} attributeChangeHandler={attributeChangeHandler} />
        );
        if (JobBoardTypes.CareerOne === jobBoardTypeId && type === 'view') return (
            <CareerOneView advertId={advertId} jobBoardId={jobBoardId} attributes={attributes} advertRecordAttributes={advertRecordAttributes} loadingHandler={loadingHandler} errorHandler={errorHandler} />
        );
        if (JobBoardTypes.CareerOne === jobBoardTypeId && type === 'edit') return (
            <CareerOneEdit advertId={advertId} jobBoardId={jobBoardId} attributes={attributes} advertRecordAttributes={advertRecordAttributes} attributeChangeHandler={attributeChangeHandler} loadingHandler={loadingHandler} errorHandler={errorHandler} />
        );
        if (JobBoardTypes.LinkedInJobWrapping === jobBoardTypeId && type === 'view') return (
            <LinkedInJobWrappingView advertId={advertId} jobBoardId={jobBoardId} attributes={attributes} advertRecordAttributes={advertRecordAttributes} />
        );
        if (JobBoardTypes.LinkedInJobWrapping === jobBoardTypeId && type === 'edit') return (
            <LinkedInJobWrappingEdit advertId={advertId} jobBoardId={jobBoardId} attributes={attributes} advertRecordAttributes={advertRecordAttributes} attributeChangeHandler={attributeChangeHandler} />
        );
        if (JobBoardTypes.BalanceRecruitmentWebsite === jobBoardTypeId && type === 'view') return (
            <BalanceRecruitmentWebsiteView advertId={advertId} jobBoardId={jobBoardId} attributes={advertRecordAttributes} />
        );
        if (JobBoardTypes.BalanceRecruitmentWebsite === jobBoardTypeId && type === 'edit') return (
            <BalanceRecruitmentWebsiteEdit advertId={advertId} jobBoardId={jobBoardId} attributes={advertRecordAttributes} attributeChangeHandler={attributeChangeHandler} />
        );
        if (JobBoardTypes.WebsiteFeedsFTP === jobBoardTypeId && type === 'view') return (
            <WebsiteFeedsFTPView advertId={advertId} jobBoardId={jobBoardId} attributes={advertRecordAttributes} />
        );
        if (JobBoardTypes.WebsiteFeedsFTP === jobBoardTypeId && type === 'edit') return (
            <WebsiteFeedsFTPEdit advertId={advertId} jobBoardId={jobBoardId} attributes={advertRecordAttributes} attributeChangeHandler={attributeChangeHandler} />
        );
        if (JobBoardTypes.TalentDotCom === jobBoardTypeId && type === 'view') return (
            <TalentDotComView advertId={advertId} jobBoardId={jobBoardId} attributes={attributes} advertRecordAttributes={advertRecordAttributes} />
        );
        if (JobBoardTypes.TalentDotCom === jobBoardTypeId && type === 'edit') return (
            <TalentDotComEdit advertId={advertId} jobBoardId={jobBoardId} attributes={attributes} advertRecordAttributes={advertRecordAttributes} attributeChangeHandler={attributeChangeHandler} />
        );
        if (JobBoardTypes.LinkedInBasicJobs === jobBoardTypeId && type === 'view') return (
            <LinkedInBasicJobsView advertId={advertId} jobBoardId={jobBoardId} attributes={attributes} advertRecordAttributes={advertRecordAttributes} />
        );
        if (JobBoardTypes.LinkedInBasicJobs === jobBoardTypeId && type === 'edit') return (
            <LinkedInBasicJobsEdit advertId={advertId} jobBoardId={jobBoardId} attributes={attributes} advertRecordAttributes={advertRecordAttributes} attributeChangeHandler={attributeChangeHandler} />
        );
        if (JobBoardTypes.GenericApplyLink === jobBoardTypeId) return (
            <GenericApplyLink advertId={advertId} jobBoardId={jobBoardId} attributes={attributes} attributeChangeHandler={attributeChangeHandler} />
        );
        if (JobBoardTypes.Shazamme === jobBoardTypeId && type === 'view') return (
            <ShazammeView advertId={advertId} jobBoardId={jobBoardId} attributes={attributes} advertRecordAttributes={advertRecordAttributes} loadingHandler={loadingHandler} />
        )
        if (JobBoardTypes.Shazamme === jobBoardTypeId && type === 'edit') return (
            <ShazammeEdit advertId={advertId} jobBoardId={jobBoardId} attributes={attributes} advertRecordAttributes={advertRecordAttributes} attributeChangeHandler={attributeChangeHandler} loadingHandler={loadingHandler} />
        )
        if (JobBoardTypes.WPJobManagerV2 === jobBoardTypeId && type === 'view') return (
            <WPJobManagerV2View advertId={advertId} jobBoardId={jobBoardId} attributes={attributes} advertRecordAttributes={advertRecordAttributes} />
        );
        if (JobBoardTypes.WPJobManagerV2 === jobBoardTypeId && type === 'edit') return (
            <WPJobManagerV2Edit advertId={advertId} jobBoardId={jobBoardId} attributes={attributes} advertRecordAttributes={advertRecordAttributes} attributeChangeHandler={attributeChangeHandler} />
        );
        if (JobBoardTypes.Seek === jobBoardTypeId && type === 'view') return (
            <SeekView advertId={advertId} jobBoardId={jobBoardId} attributes={attributes} advertRecordAttributes={advertRecordAttributes} loadingHandler={loadingHandler} />
        );
        if (JobBoardTypes.Seek === jobBoardTypeId && type === 'edit') return (
            <SeekEdit advertId={advertId} jobBoardId={jobBoardId} attributes={attributes} advertRecordAttributes={advertRecordAttributes} attributeChangeHandler={attributeChangeHandler} validationMessageHandler={validationMessageHandler} loadingHandler={loadingHandler} errorHandler={errorHandler} />
        );
        if (JobBoardTypes.Refari === jobBoardTypeId && type === 'view') return (
            <RefariView advertId={advertId} jobBoardId={jobBoardId} attributes={attributes} advertRecordAttributes={advertRecordAttributes} loadingHandler={loadingHandler} errorHandler={errorHandler} />
        );
        if (JobBoardTypes.Refari === jobBoardTypeId && type === 'edit') return (
            <RefariEdit advertId={advertId} jobBoardId={jobBoardId} attributes={attributes} advertRecordAttributes={advertRecordAttributes} attributeChangeHandler={attributeChangeHandler} loadingHandler={loadingHandler} errorHandler={errorHandler} />
        );
        if (JobBoardTypes.JobAdderXMLFeedFTPUpload === jobBoardTypeId && type === 'view') return (
            <JobAdderXMLFeedFTPUploadView advertId={advertId} jobBoardId={jobBoardId} attributes={advertRecordAttributes} />
        );
        if (JobBoardTypes.JobAdderXMLFeedFTPUpload === jobBoardTypeId && type === 'edit') return (
            <JobAdderXMLFeedFTPUploadEdit advertId={advertId} jobBoardId={jobBoardId} attributes={advertRecordAttributes} attributeChangeHandler={attributeChangeHandler} />
        );
        return <div>Not Implemented Yet</div>
    }, [advertId, advertRecordAttributes, attributeChangeHandler, attributes, errorHandler, jobBoardId, jobBoardTypeId, loadingHandler, type, validationMessageHandler]);

    if (type === 'view') {
        return (
            <Paper sx={{ p: 2 }}>
                { Boolean(title) && <Typography variant="h5" textAlign="center" pt={1}>{title}</Typography> }
                { renderedBoard }
            </Paper>
        );
    }

    return renderedBoard;
}
