import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import { GridCellEditStopParams, GridCellEditStopReasons, GridColDef, GridColumnVisibilityModel, GridRenderCellParams, GridRenderEditCellParams, GridRowSelectionModel, GridValueGetter, useGridApiRef } from "@mui/x-data-grid-premium";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Division } from "common/models/Configuration/Division";
import { Contact } from "common/models/Contacts";
import { PreviewEntityType } from "common/models/Previews/Previews";
import { GetClientContacts } from "services/ClientsService";
import { GetSiteContacts } from "services/SitesService";
import { GetMyContacts, GetContactsByTag, ContactLookup, SearchContacts, UpdateContactCustomField, BulkRemoveContactFromClient } from "services/ContactsService";
import { GetDivisions } from "services/DivisionsService";
import GridWithStateWrapper from "../GridWidthStateWrapper";
import PreviewLoaderComponent from "../Previews/PreviewLoader";
import TagsManagementDialog from "../Dialogs/TagsManagementDialog";
import { GetPhoneLink, GetPhoneLinkWithStandardized } from "util/CommonUtils";
import { MenuOptionDefinition } from "common/models/MenuDefinition";
import ActionMenu from "../Menus/ActionMenu";
import { companyHasSinglePermission, userHasSinglePermission } from "util/PermissionsUtils";
import { Permission } from "common/models/Permissions";
import SendEmailDialog from "../Dialogs/Messages/SendEmailDialog";
import SendSmsDialog from "../Dialogs/Messages/SendSmsDialog";
import { EmailRecipient } from "common/models/EmailRecipient";
import { GetMarketingPlatformsAvailable } from "services/UsersService";
import MergeContactsDialog from "../Dialogs/Contacts/MergeContactsDialog";
import { ClientSearch } from "common/models/Search/ClientSearch";
import Snackbar from "@mui/material/Snackbar";
import Alert from "components/Alert";
import ExportRecordsDialog from "../Dialogs/ExportRecordsDialog";
import { RecordExportData } from "common/models/Common";
import ExportRecordsToMailchimpDialog from "../Dialogs/ExportRecordsToMailchimpDialog";
import ExportRecordsToCampaignMonitorDialog from "../Dialogs/ExportRecordsToCampaignMonitorDialog";
import { GetGeneralSettings } from "services/ConfigurationService";
import { GetSubmissionContacts } from "services/SubmissionsService";
import Box from "@mui/material/Box";
import FloatCandidateDialog from "../Dialogs/Submissions/SitesFloatDialog";
import { GetCustomFieldsByEntity_OnlyActive, GetPredefinedValues } from "services/CustomFieldsService";
import { CustomField, CustomFieldPredefinedValue } from "common/models/Configuration/CustomFields";
import { customFieldColsVisibility, mandatoryUdfEditValidator, numberUdfEditValidator } from "util/GridUtils";
import { DatePicker } from "@mui/x-date-pickers-pro";
import { AllowContactsAddEdit } from "common/data/Permissions/ContactsAccess";
import ConfirmationDialog from "components/Dialogs/Generic/ConfirmationDialog";
import { MeetingType } from "common/models/Configuration/MeetingType";
import { GetActiveMeetingTypes } from "services/MeetingsService";

interface Props {
    source: 'client-record' | 'site-record' | 'my-contacts' | 'submission-record' | 'tags' | 'search' | 'lookup',
    sourceId?: number,
    gridName: string,
    tagLabel?: string,
    tagTypeId?: number,
    taggedBy?: number,
    searchData?: ClientSearch,
    lookupTerm?: string,
    setRowCountHandler?: (count: number) => void,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
    successHandler?: (message: string) => void,
}

interface CustomFieldWithPredefinedValues extends CustomField {
    values?: CustomFieldPredefinedValue[],
}

const linkStyle: React.CSSProperties = { color: 'inherit', textDecoration: 'underline' };
const entityTypeId = 2;

const defaultHiddenCols: GridColumnVisibilityModel = {
    "id": false,
    "email": false,
    "phone": false,
    "lastActivityDate": false,
    "nextActivityDate": false,
    "division": false,
    ...customFieldColsVisibility
};

export default function ContactsGridComponent({ source, sourceId, tagLabel = '', tagTypeId = 0, taggedBy = 0, lookupTerm = '', searchData, setRowCountHandler, loadingHandler, errorHandler, successHandler, gridName }: Props) {
    const [rows, setRows] = useState<Contact[]>([]);
    const [floatRecords, setFloatRecords] = useState<number[]>([]);
    const [divisions, setDivisions] = useState<Division[]>([]);
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    const [showTagsPrevewNoDelay, setShowTagsPreviewNoDelay] = useState(false);
    const [previewType, setPreviewType] = useState<PreviewEntityType | ''>('');
    const [previewRecordId, setPreviewRecordId] = useState(0);
    const [isPreviewTags, setIsPreviewTags] = useState(false);
    const [previewRecordName, setPreviewRecordName] = useState('');
    const [hasMailChimpAccess, setHasMailChimpAccess] = useState(false);
    const [hasCampaignMonitorAccess, setHasCampaignMonitorAccess] = useState(false);
    const [showConfirmRemoveFromClient, setShowConfirmRemoveFromClient] = useState(false);
    const [sendMessageDialogType, setSendMessageDialogType] = useState<'email' | 'sms' | 'marketing-list' | null>(null);
    const [exportRecordDialogType, setExportRecordDialogType] = useState<'mailchimp' | 'campaign-monitor' | null>(null);
    const [messageRecipients, setMessageRecipients] = useState<EmailRecipient[]>([]);
    const [exportRecords, setExportRecords] = useState<RecordExportData[]>([]);
    const [taggingRecords, setTaggingRecords] = useState<number[]>([]);
    const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
    const [mergeRecords, setMergeRecords] = useState<Contact[]>([]);
    const [editCellFieldKey, setEditCellFieldKey] = useState<keyof Contact | null>(null);
    const [successMessage, setSuccessMessage] = useState('');
    const [meetingTypes, setMeetingTypes] = useState<MeetingType[]>([]);
    const [activeFields, setActiveFields] = useState<CustomFieldWithPredefinedValues[]>([]);
    const [onlyDataAdminsCanMerge, setOnlyDataAdminsCanMerge] = useState(false);
    const apiRef = useGridApiRef();

    const canExport = useMemo(() => companyHasSinglePermission(Permission.ContactsExport) && userHasSinglePermission(Permission.ContactsExport), []);
    const userCanAddEditContacts = useMemo(() => userHasSinglePermission(Permission.ContactsAddEdit) && companyHasSinglePermission(Permission.ContactsAddEdit), []);
    const userCanEmailMarket = useMemo(() => userHasSinglePermission(Permission.EmailMarketing) && companyHasSinglePermission(Permission.EmailMarketing), []);
    const userCanSendEmail = useMemo(() => userHasSinglePermission(Permission.MessageCentre) && companyHasSinglePermission(Permission.MessageCentre), []);
    const userCanSendSms = useMemo(() => userHasSinglePermission(Permission.MessageCentreSms) && companyHasSinglePermission(Permission.MessageCentreSms), []);
    const canAddEditContacts = useMemo(() => AllowContactsAddEdit(), []);
    const isUserDataMaintenanceAdmin = useMemo(() => userHasSinglePermission(Permission.DataMaintenanceAdministrator), []);

    const userCanMergeRecords = useMemo(() => {
        if (onlyDataAdminsCanMerge && !isUserDataMaintenanceAdmin) return false;
        return true;
    }, [isUserDataMaintenanceAdmin, onlyDataAdminsCanMerge]);

    useEffect(() => {
        const getActiveFields = async () => {
            const customFields = await GetCustomFieldsByEntity_OnlyActive(entityTypeId);
            if (customFields) {
                let udfDefinitions: CustomFieldWithPredefinedValues[] = [];
                for (let i = 0; i < customFields.length; i++) {
                    const u = customFields[i];
                    if (u.usePredefinedValues) {
                        const vals = await GetPredefinedValues(u.id);
                        if (vals) udfDefinitions.push({ ...u, values: vals });
                        else udfDefinitions.push(u);
                        continue;
                    }
                    
                    udfDefinitions.push(u);
                }
                setActiveFields(udfDefinitions);
            }
        };
        getActiveFields();
    }, []);

    const getData = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        let res: Contact[] | null = [];
        const divs = await GetDivisions();
        if (divs) setDivisions(divs);
        if (source === 'client-record' && sourceId) res = await GetClientContacts(sourceId, errorHandler);
        if (source === 'site-record' && sourceId) res = await GetSiteContacts(sourceId, errorHandler);
        if (source === 'submission-record' && sourceId) res = await GetSubmissionContacts(sourceId, errorHandler);
        if (source === 'my-contacts') res = await GetMyContacts(errorHandler);
        if (source === 'tags') res = await GetContactsByTag(tagTypeId, taggedBy, tagLabel, errorHandler);
        if (source === 'search' && searchData) res = await SearchContacts(searchData, errorHandler)
        if (source === 'lookup' && lookupTerm) res = await ContactLookup(lookupTerm, 0, errorHandler);
        if (res) {
            setRowCountHandler && setRowCountHandler(res.length)
            setRows(res);
        }
        loadingHandler && loadingHandler(false);
        
    }, [source, sourceId, tagLabel, tagTypeId, taggedBy, searchData, lookupTerm, setRowCountHandler, loadingHandler, errorHandler]);

    useEffect(() => {
        getData();
    }, [getData]);

    useEffect(() => {
        const getMeetingTypes = async () => {
            const res = await GetActiveMeetingTypes(entityTypeId);
            if (res) setMeetingTypes(res);
        };
        getMeetingTypes();
    }, []);

    useEffect(() => {
        const getGeneralSettings = async () => {
            const res = await GetGeneralSettings(errorHandler);
            if (res) {
                const setting = res.find(s => s.type === 'WhoCanMergeRecords');
                if (setting) setOnlyDataAdminsCanMerge(setting.value === 'DataMaintenanceAdministrator');
            }
        };
        getGeneralSettings();
    }, [errorHandler]);

    const columns = useMemo<GridColDef[]>(() => {
        const handlePreviewHover = (type: PreviewEntityType | '', id: number, isTags: boolean = false, recordName: string = '', noDelay: boolean = false) => {
            setPreviewType(type);
            setPreviewRecordId(id);
            setIsPreviewTags(isTags);
            setPreviewRecordName(recordName);
            setIsPreviewOpen(true);
            setShowTagsPreviewNoDelay(noDelay);
        };

        const handlePreviewClose = () => {
            setIsPreviewOpen(false);
            setShowTagsPreviewNoDelay(false);
        };

        const dateValueGetter: GridValueGetter<Contact, any, undefined, string> = (value) => {
            if (value) {
                const m = moment(value);
                if (m.isValid() && m.get('year') > 1) {
                    return m.toDate();
                }
            }
        };

        const lastContactDateRenderer = (params: GridRenderCellParams) => {
            if (params.value) {
                return moment(params.value).format('DD MMM YYYY');
            }
            return 'Never';
        };

        const dateRenderer = (params: GridRenderCellParams) => {
            if (params.value) {
                return moment(params.value).format('DD MMM YYYY');
            }
        };

        const dateEditorRenderer = (params: GridRenderEditCellParams) => {
            const { id, value, field } = params;
            const dateUdfChangeHandler = (date: moment.Moment | null) => {
                if (date) {
                    if (date.isValid()) {
                        const formatted = date.format('YYYY-MM-DD');
                        apiRef.current.setEditCellValue({ id, field, value: formatted });
                    }
                }
                else apiRef.current.setEditCellValue({ id, field, value: null });
            };

            let v: moment.Moment | null = null;
            if (value) {
                const m = moment(value);
                if (m.isValid()) v = m;
            }

            return <DatePicker value={v} onChange={dateUdfChangeHandler} slotProps={{actionBar: { actions: ["clear", "today", "cancel", "accept"] }}} />;
        };

        const divisionsValueGetter: GridValueGetter<Contact, any, undefined, string> = (value) => {
            if (value) {
                const divs = value.split(';');
                let divNamesList: string[] = [];
                for (let i = 0; i < divs.length; i++) {
                    const div = divs[i];
                    if (div) {
                        const index = divisions.findIndex(d => d.id === +div);
                        if (index !== -1) divNamesList.push(divisions[index].name);
                    }
                    
                }
                return divNamesList.join(';');
            }
        };

        const divisionsRenderer = (params: GridRenderCellParams) => {
            if (params.value) {
                const names = (params.value as string).split(';');
                return names.map((d, i) => <Chip key={i} size="small" label={d} />);
            }
        };

        const linkToContactRenderer = (params: GridRenderCellParams) => {
            if (params.id) {
                return (
                    <Link
                        to={`/contacts/${params.id}`}
                        style={ linkStyle }
                        onMouseEnter={ () => handlePreviewHover('contact', params.row.id) }
                        onMouseLeave={ handlePreviewClose }
                        target={ source === "search" ? "_blank" : "_self" }
                    >{params.value}</Link>
                );
            }
            return params.value;
        };

        const linkToClientRenderer = (params: GridRenderCellParams) => {
            const clientId = params.row.clientID;
            if (clientId) {
                return (
                    <Link
                        to={`/clients/${clientId}`}
                        style={ linkStyle }
                        onMouseEnter={ () => handlePreviewHover('client', clientId) }
                        onMouseLeave={ handlePreviewClose }
                    >{params.value}</Link>
                );
            }
            return params.value;
        };

        const linkToSiteRenderer = (params: GridRenderCellParams) => {
            const siteId = params.row.siteID;
            if (siteId) {
                return (
                    <Link
                        to={`/sites/${siteId}`}
                        style={linkStyle}
                        onMouseEnter={() => handlePreviewHover('site', siteId)}
                        onMouseLeave={handlePreviewClose}
                    >{params.value}</Link>
                );
            }
            return params.value;
        };

        const emailLinkRenderer = (params: GridRenderCellParams) => {
            const email = params.value;
            if (email) {
                return <a href={`mailto:${email}`} style={linkStyle}>{email}</a>;
            }
        };

        const tagsRenderer = (params: GridRenderCellParams) => {
            if (userCanAddEditContacts) {
                return (
                    <IconButton
                        size="small"
                        onMouseEnter={ () => handlePreviewHover('contact', params.row.id, true, params.row.fullName) }
                        onMouseLeave={ handlePreviewClose }
                        onClick={ () => setTaggingRecords([params.row.id]) }
                    >
                        {params.value
                            ? <LocalOfferIcon style={{ color: '#f209a6' }} />
                            : <LocalOfferOutlinedIcon />
                        }
                    </IconButton>
                );
            }
            return (
                <IconButton
                    size="small"
                    onMouseEnter={() => handlePreviewHover('contact', params.row.id, true, params.row.fullName)}
                    onMouseLeave={handlePreviewClose}
                    onClick={() => handlePreviewHover('contact', params.row.id, true, params.row.fullName, true)}
                >
                    {params.value
                        ? <LocalOfferIcon />
                        : <LocalOfferOutlinedIcon />
                    }
                </IconButton>
            );
        };

        const phoneLinkRenderer = (params: GridRenderCellParams) => {
            const phone = params.row.phone;
            if (phone) {
                return GetPhoneLink(phone, true);
            }
        };

        const mobileLinkRenderer = (params: GridRenderCellParams) => {
            return GetPhoneLinkWithStandardized(params.value, params.row.mobile_Standardised, true);
        };

        const linkToOpenJobsRenderer = (params: GridRenderCellParams) => {
            return <Link target="_blank" style={linkStyle} to={`/contacts/${params.id}?tab=CONTACTS_JobsOpen`}>{params.value}</Link>;
        };

        const linkToPlacementsRenderer = (params: GridRenderCellParams) => {
            return <Link target="_blank" style={linkStyle} to={`/contacts/${params.id}?tab=CONTACTS_Placements`}>{params.value}</Link>;
        };
        
        let cols: GridColDef[] = [
            { headerName: 'ID', field: 'id', width: 75, renderCell: linkToContactRenderer },
            { headerName: 'Name', field: 'fullName', width: 300, renderCell: linkToContactRenderer },
            { headerName: 'Email', field: 'email', headerAlign: 'center', align: 'center', width: 300, renderCell: emailLinkRenderer },
            { headerName: 'Tags', field: 'tags', width: 70, headerAlign: 'center', align: 'center', renderCell: tagsRenderer, editable: false },
            { headerName: 'Client', field: 'clientName', width: 300, renderCell: linkToClientRenderer },
            { headerName: 'Job Title', field: 'jobTitle', width: 200 },
            { headerName: 'Site Name', field: 'siteName', width: 150, renderCell: linkToSiteRenderer },
            { headerName: 'Location', field: 'location', width: 200 },
            { headerName: 'Mobile', field: 'mobile', headerAlign: 'center', align: 'center', width: 150, renderCell: mobileLinkRenderer },
            { headerName: 'Phone', field: 'phone', headerAlign: 'center', align: 'center', width: 150, renderCell: phoneLinkRenderer },
            { headerName: 'Status', field: 'statusName', headerAlign: 'center', align: 'center', width: 200 },
            { headerName: 'Last Contact', field: 'lastContactDate', headerAlign: 'center', align: 'center', width: 110, valueGetter: dateValueGetter, renderCell: lastContactDateRenderer, type: 'date' },
            { headerName: 'Next Contact', field: 'nextContactDate', headerAlign: 'center', align: 'center', width: 110, valueGetter: dateValueGetter, renderCell: lastContactDateRenderer, type: 'date' },
            { headerName: 'Last Activity', field: 'lastActivityDate', headerAlign: 'center', align: 'center', width: 110, valueGetter: dateValueGetter, renderCell: lastContactDateRenderer, type: 'date' },
            { headerName: 'Next Activity', field: 'nextActivityDate', headerAlign: 'center', align: 'center', width: 110, valueGetter: dateValueGetter, renderCell: lastContactDateRenderer, type: 'date' },
            { headerName: 'Open Jobs', field: 'openJobs', type: 'number', headerAlign: 'center', align: 'center', width: 100, renderCell: linkToOpenJobsRenderer },
            { headerName: 'Placements', field: 'placements', type: 'number', headerAlign: 'center', align: 'center', width: 100, renderCell: linkToPlacementsRenderer },
            { headerName: 'Divisions', field: 'divisions', width: 300, valueGetter: divisionsValueGetter, renderCell: divisionsRenderer }
        ];

        if (activeFields && activeFields.length > 0) {
            for (let i = 0; i < activeFields.length; i++) {
                const udf = activeFields[i];
                const udfNumber = udf.name.slice(13);
                const isString = udf.dataType === 'String';
                const isDecimal = udf.dataType === 'Decimal';
                const isDate = udf.dataType === 'DateTime';
                const isEditable = udf.editableViaGrids;
                const isDropdown = udf.usePredefinedValues;
                const isMandatory = udf.mandatory;

                if (isEditable && isDecimal) {
                    cols.push({ 
                        field: `customField${udfNumber}`,
                        headerName: udf.agencyName,
                        headerAlign: 'center',
                        align: 'right',
                        editable: true,
                        preProcessEditCellProps: numberUdfEditValidator,
                    });
                }
                else if (isDate) {
                    cols.push({ 
                        field: `customField${udfNumber}`,
                        headerName: udf.agencyName,
                        headerAlign: 'center',
                        editable: isEditable,
                        valueGetter: dateValueGetter,
                        renderCell: dateRenderer,
                        renderEditCell: isEditable ? dateEditorRenderer : undefined,
                        preProcessEditCellProps: isMandatory ? mandatoryUdfEditValidator : undefined
                    });
                }
                else if (isString && isDropdown && udf.values && udf.values.length > 0) {
                    cols.push({ 
                        field: `customField${udfNumber}`,
                        headerName: udf.agencyName,
                        headerAlign: 'center',
                        editable: isEditable,
                        valueOptions: udf.values.map(v => v.value),
                        type: 'singleSelect',
                    });
                }
                else if (isEditable && isString) {
                    cols.push({ 
                        field: `customField${udfNumber}`,
                        headerName: udf.agencyName,
                        headerAlign: 'center',
                        align: 'right',
                        editable: true,
                        preProcessEditCellProps: isMandatory ? mandatoryUdfEditValidator : undefined
                    });
                }
                else {
                    cols.push({ field: `customField${udfNumber}`, headerName: udf.agencyName, headerAlign: 'center', align: isDecimal ? 'right' : undefined });
                }
            }
        }

        return cols;
    }, [activeFields, apiRef, divisions, userCanAddEditContacts, source]);

    useEffect(() => {
        const getData = async () => {
            const res = await GetMarketingPlatformsAvailable();
            if (res) {
                setHasMailChimpAccess(res.mailChimp);
                setHasCampaignMonitorAccess(res.campaignMonitor);
            }
        };
        userCanEmailMarket && getData();
    }, [userCanEmailMarket]);

    const sendMessageCallback = useCallback((type: 'email' | 'sms' | 'marketing-list', idKey: keyof Contact, nameKey: keyof Contact) => {
        if (selectionModel.length > 0) {
            let recipients: EmailRecipient[] = [];
            for (let i = 0; i < selectionModel.length; i++) {
                const id = selectionModel[i];
                const row = rows.find(r => r.id === +id.valueOf());
                if (row) {
                    const recipientId = row[idKey] as number;
                    const recipientName = row[nameKey] as string;
                    if (type === 'email' || type === 'marketing-list') {
                        const email = row['email'];
                        const email2 = row['email2'];
                        recipients.push({ id: recipientId, email: email ? email : email2, name: recipientName, isOptOut: row.optOut, mobile: '', contact: row });
                    }
                    else if (type === 'sms') {
                        const mobileStd = row['mobile_Standardised'];
                        const mobile = row['mobile'];
                        const phoneStd = row['phone_Standardised'];
                        const phone = row['phone'];
                        let mobileValue = '';
                        if (Boolean(mobileStd)) mobileValue = mobileStd;
                        else if (Boolean(mobile)) mobileValue = mobile;
                        else if (Boolean(phoneStd)) mobileValue = phoneStd;
                        else if (Boolean(phone)) mobileValue = phone;
                        recipients.push({ id: recipientId, email: '', name: recipientName, isOptOut: row.optOut, mobile: mobileValue, contact: row });
                    }

                }
            }
            setSendMessageDialogType(type);
            setMessageRecipients(recipients);
        }
    }, [selectionModel, rows]);

    const exportToMailchimpOrCampaignMonitorCallback = useCallback((dialogType: 'mailchimp' | 'campaign-monitor') => {
        if (selectionModel.length > 0) {
            let exportRecordsData: RecordExportData[] = [];
            for (let i = 0; i < selectionModel.length; i++) {
                const id = selectionModel[i];
                const row = rows.find(r => r.id === +id);
                if (row) {
                    exportRecordsData.push({
                        id: row.id,
                        email: row.email ?? row.email2,
                        firstName: row.firstName,
                        lastName: row.surname,
                        isOptOut: row.optOut,
                        contact: row
                    });
                }
            }
            setExportRecordDialogType(dialogType);
            setExportRecords(exportRecordsData);
        }
    }, [selectionModel, rows]);

    const mergeRecordsHandler = useCallback(() => {
        let selectedContacts: Contact[] = [];
        for (let i = 0; i < selectionModel.length; i++) {
            const contactId = selectionModel[i];
            const contact = rows.find(s => s.id === contactId.valueOf());
            if (contact) selectedContacts.push(contact);
        }
        setMergeRecords(selectedContacts);
    }, [rows, selectionModel]);

    const exportToExcel = useCallback(() => {
        const api = apiRef.current;
        if (api) api.exportDataAsExcel();
    }, [apiRef]);

    const performFloatHandler = useCallback(() => {
        let selectedContacts: number[] = [];
        for (let i = 0; i < selectionModel.length; i++) {
            const contactId = selectionModel[i];
            const contact = rows.find(c => c.id === contactId.valueOf());
            if (contact) {
                if (!Boolean(contact.email)) {
                    errorHandler && errorHandler('All selected contacts must have a valid email');
                    return;
                }
                selectedContacts.push(contact.id);
            }
        }
        setFloatRecords(selectedContacts);
    }, [rows, selectionModel, errorHandler]);

    const actionMenuDefinitions = useMemo<MenuOptionDefinition[]>(() => {
        const noRecordSelected = selectionModel.length === 0;
        const notEnoughForMerge = selectionModel.length < 2;
        const hasMarketingPlatform = hasMailChimpAccess || hasCampaignMonitorAccess;
        const contactIdsQuery = encodeURIComponent(selectionModel.join(','));

        let actions: MenuOptionDefinition[] = [
            { label: 'Export', type: 'action', action: exportToExcel, allow: () => canExport },
            { label: 'Export Marketing List', type: 'action', disabled: noRecordSelected, allow: () => userCanEmailMarket && !hasMarketingPlatform, action: () => sendMessageCallback('marketing-list', 'id', 'fullName') },
            { label: 'Export to Mailchimp', type: 'action', disabled: noRecordSelected, allow: () => userCanEmailMarket && hasMailChimpAccess, action: () => exportToMailchimpOrCampaignMonitorCallback('mailchimp') },
            { label: 'Export to Campaign Monitor', type: 'action', disabled: noRecordSelected, allow: () => userCanEmailMarket && hasCampaignMonitorAccess, action: () => exportToMailchimpOrCampaignMonitorCallback('campaign-monitor') },
            { label: 'Merge', type: 'action', disabled: notEnoughForMerge, allow: () => userCanAddEditContacts && userCanMergeRecords, action: mergeRecordsHandler },
            { label: 'Send Email', type: 'action', disabled: noRecordSelected, allow: () => userCanSendEmail, action: () => sendMessageCallback('email', 'id', 'fullName') },
            { label: 'Send SMS', type: 'action', disabled: noRecordSelected, allow: () => userCanSendSms, action: () => sendMessageCallback('sms', 'id', 'fullName') },
            { label: 'Remove from Client', type: 'action', disabled: noRecordSelected, action: () => setShowConfirmRemoveFromClient(true), allow: () => canAddEditContacts },
            { label: 'Bulk Tag', type: 'action', disabled: noRecordSelected, allow: () => userCanAddEditContacts, action: () => setTaggingRecords(selectionModel as number[]) },
        ];

        let meetingActions: MenuOptionDefinition = {
            label: 'Meetings', type: 'parent', subMenu: []
        };

        if (meetingTypes.length > 0 && meetingActions.subMenu) {
            for (let i = 0; i < meetingTypes.length; i++) {
                const t = meetingTypes[i];
                const href = `/meetings/create?contactIds=${contactIdsQuery}&typeId=${t.id}&source=${entityTypeId}`;
                meetingActions.subMenu.push({ label: t.name, type: 'link', href: href, disabled: selectionModel.length === 0  });
            }
        }

        if (meetingActions.subMenu && meetingActions.subMenu.length > 0) actions.push(meetingActions);

        return actions;
    }, [selectionModel, hasMailChimpAccess, hasCampaignMonitorAccess, exportToExcel, mergeRecordsHandler, meetingTypes, canExport, userCanEmailMarket, sendMessageCallback, exportToMailchimpOrCampaignMonitorCallback, userCanAddEditContacts, userCanMergeRecords, userCanSendEmail, userCanSendSms, canAddEditContacts]);
    
    const workflowMenuDefinitions = useMemo<MenuOptionDefinition[]>(() => {
        return [
            { label: 'Float Candidate', type: 'action', action: performFloatHandler, disabled: selectionModel.length === 0 }
        ];
    }, [selectionModel, performFloatHandler]);

    const gridActions = useMemo(() => {
        return <ActionMenu color="secondary" label="List Actions" definition={actionMenuDefinitions} />;
    }, [actionMenuDefinitions]);

    const gridWorkflowActions = useMemo(() => {
        return <ActionMenu color="primary" label="Workflow" mr="5px" definition={workflowMenuDefinitions} />;
    }, [workflowMenuDefinitions]);

    const tagManagementSuccessHandler = useCallback((message: string, recordIds: number[], finalTagCount: number) => {
        successHandler && successHandler(message);
        const api = apiRef.current;
        if (api) {
            const hasTags = finalTagCount > 0;
            api.updateRows(recordIds.map(id => ({ id: id, tags: hasTags })));
        }
    }, [apiRef, successHandler]);

    const cellEditHandler = useCallback((params: GridCellEditStopParams) => {
        if (params.reason === GridCellEditStopReasons.escapeKeyDown) return;
        setEditCellFieldKey(params.field as keyof Contact);
    }, []);

    const processRowUpdate = useCallback(async (newRow: Contact) => {
        if (editCellFieldKey && editCellFieldKey.startsWith('customField')) {
            const value = newRow[editCellFieldKey] as string;
            const n = +editCellFieldKey.slice(11);
            loadingHandler && loadingHandler(true);
            if ((n >= 16 && n <= 20) || (n >= 51 && n <= 60)) { // IS DATE                
                await UpdateContactCustomField(newRow.id, n, value ?? '0001-01-01', errorHandler);
            }
            else if (n >= 1 && n <= 60) await UpdateContactCustomField(newRow.id, n, value, errorHandler);
            loadingHandler && loadingHandler(false);
        }
        setEditCellFieldKey(null);
        return newRow;
    }, [editCellFieldKey, errorHandler, loadingHandler]);

    const removeFromClientCallback = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        const res = await BulkRemoveContactFromClient(selectionModel as number[], errorHandler);
        if (res) {
            setSuccessMessage('Removed from Client');
            setShowConfirmRemoveFromClient(false);
            setSelectionModel([]);
            await getData();
        }
        loadingHandler && loadingHandler(false);
    }, [errorHandler, getData, loadingHandler, selectionModel]);

    return (
        <>
            <PreviewLoaderComponent
                open={isPreviewOpen}
                entityType={previewType}
                recordId={previewRecordId}
                isTagsPreview={isPreviewTags}
                showDelayMs={showTagsPrevewNoDelay ? 0 : undefined}
                titleOverride={previewRecordName}
            />
            <ConfirmationDialog
                message={`Are you sure you want to remove the ${selectionModel.length} selected record(s) from the Client(s)?`}
                onClose={ () => setShowConfirmRemoveFromClient(false) }
                onContinue={ removeFromClientCallback }
                open={showConfirmRemoveFromClient}
                title="Confirm Action"
                confirmActionText="Yes"
                cancelActionText="No"
            />
            <TagsManagementDialog
                open={ taggingRecords.length > 0 }
                closeHandler={ () => setTaggingRecords([]) }
                entityId={ entityTypeId }
                recordIds={ taggingRecords }
                loadingHandler={ loadingHandler }
                errorHandler={ errorHandler }
                successHandler={ tagManagementSuccessHandler }
            />
            <ExportRecordsDialog
                open={ sendMessageDialogType === 'marketing-list' }
                closeHandler={ () => setSendMessageDialogType(null) }
                entityType="contacts"
                filename="Contacts"
                records={messageRecipients}
                includeTimeStamp
            />
            <ExportRecordsToMailchimpDialog
                open={exportRecordDialogType === 'mailchimp'}
                closeHandler={() => setExportRecordDialogType(null)}
                records={exportRecords}
                loadingHandler={loadingHandler}
                successHandler={successHandler}
                errorHandler={errorHandler}
            />
            <ExportRecordsToCampaignMonitorDialog
                open={exportRecordDialogType === 'campaign-monitor'}
                closeHandler={() => setExportRecordDialogType(null)}
                records={exportRecords}
                loadingHandler={loadingHandler}
                successHandler={successHandler}
                errorHandler={errorHandler}
            />
            <SendEmailDialog
                open={ sendMessageDialogType === 'email' }
                sourceEntityId={entityTypeId}
                recipients={ messageRecipients }
                recipientEntityTypeId={ entityTypeId }
                closeHandler={ () => setSendMessageDialogType(null) }
                loadingHandler={loadingHandler}
                errorHandler={errorHandler}
                successHandler={successHandler ?? setSuccessMessage}
            />
            <SendSmsDialog
                open={ sendMessageDialogType === 'sms' }
                sourceEntityId={entityTypeId}
                recipients={ messageRecipients }
                recipientEntityTypeId={ entityTypeId }
                closeHandler={ () => setSendMessageDialogType(null) }
                loadingHandler={loadingHandler}
                errorHandler={errorHandler}
                successHandler={successHandler ?? setSuccessMessage}
            />
            <MergeContactsDialog
                open={mergeRecords.length > 1}
                contacts={mergeRecords}
                closeHandler={() => setMergeRecords([])}
                errorHandler={errorHandler}
                loadingHandler={loadingHandler}
                successHandler={successHandler ?? setSuccessMessage}
            />
            <FloatCandidateDialog
                open={floatRecords.length > 0}
                contactIds={ floatRecords }
                closeHandler={() => setFloatRecords([])}
            />
            <Snackbar open={successMessage !== ''} autoHideDuration={3000} onClose={() => setSuccessMessage('')}>
                <Alert onClose={() => setSuccessMessage('')}>{ successMessage }</Alert>
            </Snackbar>
            {Boolean(gridActions) &&
                <Box pb="10px" ml="auto">
                    <Box display="flex">
                        {gridWorkflowActions}
                        {gridActions}
                    </Box>
                </Box>
            }
            <GridWithStateWrapper
                gridName={gridName}
                rows={rows}
                defaultViewModel={defaultHiddenCols}
                columns={columns}
                apiRef={apiRef}
                density="compact"
                checkboxSelection
                disableRowSelectionOnClick
                rowSelectionModel={selectionModel}
                onRowSelectionModelChange={ sm => setSelectionModel(sm) }
                pagination
                onCellEditStop={ cellEditHandler }
                processRowUpdate={processRowUpdate}
                pageSizeOptions={[100,250,500,1000]}
            />
        </>
    );
}
