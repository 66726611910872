import Alert from "@mui/material/Alert";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { SxProps } from "@mui/material/styles";
import { BasicFilter } from "common/models/Analytics/AnalyticsFilterData";
import { NameIdObj } from "common/models/GenericTypes";
import MultipleDivisionsPicker from "components/Pickers/MultipleDivisionPicker";
import MultipleGroupPicker from "components/Pickers/MultipleGroupPicker";
import MultipleUserPicker from "components/Pickers/MultipleUserPicker";
import PageContentLayout from "layouts/PageContentLayout";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {Link, useNavigate} from "react-router-dom";
import ShareIcon from '@mui/icons-material/Share';
import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PlacementsContractFinancialsOverviewContent from "components/Analytics/Placements/PlacementsContractFinancialsOverview";
import PlacementsContractFinancialsTrendContent from "components/Analytics/Placements/PlacementsContractFinancialsTrend";
import PageLayout from "../../../layouts/PageLayout";
const filterElementStyle: SxProps = { flex: '1 1 0' };

type PlacementsContractFinancialsTab = 'overview' | 'trend';
const tabValues = [ 'overview', 'trend' ];
type DisplayValue = 'graph' | 'table';
const displayValues: DisplayValue[] = ['graph', 'table'];

export default function PlacementsContractFinancialsPage() {
    const [summaryBar, setSummaryBar] = useState(<></>);
    const [activeTab, setActiveTab] = useState<PlacementsContractFinancialsTab>('overview');
    const [displayMode, setDisplayMode] = useState<DisplayValue>('graph');
    const [isLoading, setIsLoading] = useState(false);
    const [sourceType, setSourceType] = useState(1);
    const [selectedDivisions, setSelectedDivisions] = useState<number[]>([]);
    const [selectedGroups, setSelectedGroups] = useState<number[]>([]);
    const [selectedUsers, setSelectedUsers] = useState<number[]>([]);
    const [infoMessage, setInfoMessage] = useState('');
    const [shouldRunReport, setShouldRunReport] = useState(false);
    const [isInitialQuerySetupFinished, setIsInitialQuerySetupFinished] = useState(false);
    const [filterData, setFilterData] = useState<BasicFilter | null>(null);
    const [currentFilterData, setCurrentFilterData] = useState<BasicFilter | null>(null);
    const navigate = useNavigate();

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const tab = urlParams.get('tab');
        const st = urlParams.get('sourceType');
        const sid = urlParams.get('sourceIds');
        const view = urlParams.get('view');
        let parsedSt = 1;

        if (tab && tabValues.includes(tab)) setActiveTab(tab as PlacementsContractFinancialsTab);

        if (view && displayValues.includes(view as DisplayValue)) setDisplayMode(view as DisplayValue);

        if (st) {
            const p = Number(st);
            if (p >= 1 && p <= 4) {
                parsedSt = p;
                setSourceType(p);
            }
        }
        if (sid) {
            const ids: number[] = sid.split(',').map(n => Number(n));
            if (parsedSt === 2) setSelectedDivisions(ids);
            else if (parsedSt === 3) setSelectedGroups(ids);
            else if (parsedSt === 4) setSelectedUsers(ids);
        }

        setShouldRunReport(true);
        setIsInitialQuerySetupFinished(true);
    }, []);

    const sourceIds = useMemo(() => {
        let sourceIds = '';
        if (sourceType === 2) sourceIds = selectedDivisions.join(',');
        else if (sourceType === 3) sourceIds = selectedGroups.join(',');
        else if (sourceType === 4) sourceIds = selectedUsers.join(',');
        return sourceIds;
    }, [selectedDivisions, selectedGroups, selectedUsers, sourceType]);

    const generateQueryString = useCallback(() => {
        if (sourceType === 1) return `?tab=${activeTab}&view=${displayMode}&sourceType=${sourceType}`;
        else {
            return `?tab=${activeTab}&view=${displayMode}&sourceType=${sourceType}&sourceIds=${encodeURIComponent(sourceIds)}`;
        }
    }, [activeTab, displayMode, sourceIds, sourceType]);

    const shareActionCallback = useCallback(() => {
        let url = window.location.protocol + '//' + window.location.host + window.location.pathname + generateQueryString();
        navigator.clipboard.writeText(url);
        setInfoMessage('Link copied to clipboard');
    }, [generateQueryString]);

    const runReportCallback = useCallback(() => {
        setFilterData({ userFilterTypeID: sourceType, userFilterID: sourceIds });
        setCurrentFilterData({ userFilterTypeID: sourceType, userFilterID: sourceIds });
    }, [sourceIds, sourceType]);

    useEffect(() => {
        const action = (
            <Avatar
                key="splitsAction"
                onClick={shareActionCallback}
                title="Copy link to clipboard"
                sx={{ bgcolor: t => t.palette.primary.main, mr: '5px', cursor: 'pointer' }}
            >
                <ShareIcon />
            </Avatar>
        );
        const reportsLink = <Link to="/analytics/placements" style={{ color: 'inherit', textDecoration: 'underline' }}>Placements</Link>;

        const title = <>{'Analytics > '} {reportsLink} {' > Contract Financials'}</>;
        const tabTitle = 'Contract Financials > Placements > Analytics';

        const summaryBar = <TitleAndActionSummaryBar title={title} browserTabTitle={tabTitle} action={action} />;
        setSummaryBar(summaryBar);
    }, [shareActionCallback]);
    
    useEffect(() => {
        if (isInitialQuerySetupFinished && shouldRunReport) {
            setFilterData({ userFilterID: sourceIds, userFilterTypeID: sourceType });
            setCurrentFilterData({ userFilterID: sourceIds, userFilterTypeID: sourceType });
            setShouldRunReport(false);
        }
    }, [isInitialQuerySetupFinished, selectedDivisions, selectedGroups, selectedUsers, shouldRunReport, sourceIds, sourceType]);

    useEffect(() => {
        if (isInitialQuerySetupFinished) {
            let url = window.location.pathname + generateQueryString();
            navigate(url);
        }
    }, [generateQueryString, isInitialQuerySetupFinished, navigate]);

    const onSourceTypeChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const v = +e.target.value;
        setSourceType(v);
    }, []);

    const onDivisionsChange = useCallback((divisions: NameIdObj[]) => {
        setSelectedDivisions(divisions.map(u => u.id));
    }, []);

    const onGroupsChange = useCallback((groups: NameIdObj[]) => {
        setSelectedGroups(groups.map(u => u.id));
    }, []);

    const onUsersChange = useCallback((users: NameIdObj[]) => {
        setSelectedUsers(users.map(u => u.id));
    }, []);

    const handleTabChange = useCallback((e: React.SyntheticEvent, newValue: string) => {
        setActiveTab(newValue as PlacementsContractFinancialsTab);
    }, []);

    const onDisplayModeToggle = useCallback(() => {
        setDisplayMode(prev => prev === 'graph' ? 'table' : 'graph');
    }, []);

    const isRunButtonEnabled = useMemo(() => {
        if (currentFilterData) {
            return currentFilterData.userFilterID !== sourceIds || currentFilterData.userFilterTypeID !== sourceType;
        }
        return true;
    }, [currentFilterData, sourceIds, sourceType]);
    
    return (
        <>
            <PageLayout SummaryBar={summaryBar}>
                <Tabs value={activeTab} onChange={handleTabChange}>
                    <Tab value="overview" label="Overview" />
                    <Tab value="trend" label="Trend" />
                </Tabs>
                <PageContentLayout showLoading={isLoading} >
                    <Snackbar open={Boolean(infoMessage)} autoHideDuration={1500} onClose={() => setInfoMessage('')}>
                        <Alert severity="info" onClose={() => setInfoMessage('')}>{infoMessage}</Alert>
                    </Snackbar>
                    <Stack spacing={1}/*  pb={1} */>
                        <Box display="flex" gap={1}>
                            <TextField select label="Source Type" value={sourceType.toString()} onChange={onSourceTypeChange} sx={filterElementStyle} >
                                <MenuItem value="1">Company</MenuItem>
                                <MenuItem value="2">Division</MenuItem>
                                <MenuItem value="3">Group</MenuItem>
                                <MenuItem value="4">User</MenuItem>
                            </TextField>
                            <Button variant="contained" onClick={onDisplayModeToggle}>Show as {displayMode === 'graph' ? 'Table' : 'Graph'}</Button>
                            <Button variant="contained" color="success" onClick={runReportCallback} disabled={!isRunButtonEnabled}>Run Report</Button>
                        </Box>
                        <Box display={sourceType === 2 ? undefined : 'none'}>
                            <MultipleDivisionsPicker value={selectedDivisions} onSelect={onDivisionsChange} />
                        </Box>
                        <Box display={sourceType === 3 ? undefined : 'none'}>
                            <MultipleGroupPicker value={selectedGroups} onSelect={onGroupsChange} />
                        </Box>
                        <Box display={sourceType === 4 ? undefined : 'none'}>
                            <MultipleUserPicker value={selectedUsers} onSelect={onUsersChange} />
                        </Box>
                    </Stack>
                    {filterData && activeTab === 'overview' && <PlacementsContractFinancialsOverviewContent filterData={filterData} graphType={displayMode} loadingHandler={setIsLoading} /> }
                    {filterData && activeTab === 'trend' && <PlacementsContractFinancialsTrendContent filterData={filterData} graphType={displayMode} loadingHandler={setIsLoading} /> }
                </PageContentLayout>
            </PageLayout>
        </>
    );
}