import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Bar, BarChart, CartesianGrid, Cell, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import SettingsIcon from '@mui/icons-material/Settings';
import { useTheme } from "@mui/material/styles";
import { GetDashboardActivitiesGraphData } from "services/BusinessIntelligence";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import RWTextFieldComponent from "components/RWTextFieldComponent";
import useActiveUsers from "hooks/UseActiveUsers";

interface Props {
    id: string,
    title: string,
    consultant: number,
    timeRange: number,
    showConfigButton?: boolean,
    saveConfigHandler?: (id: string, title: string, newTimeRange: number, newConsultant: number) => void
}

interface GraphData {
    title: string,
    count: number,
    color: string
}

const getDatesFromTimeRange = (tr: number) => {
    let start: moment.Moment | null = null;
    let end: moment.Moment | null = null;
    if (tr === 0) return;
    if (tr === 1) { // This Week
        start = moment().startOf('isoWeek');
        end = moment().endOf('isoWeek');
    }
    else if (tr === 2) { // This Month
        start = moment().startOf('month');
        end = moment().endOf('month');
    }
    else if (tr === 3) { // This Quarter
        start = moment().startOf('quarter');
        end = moment().endOf('quarter');
    }
    else if (tr === 4) { // This Year
        start = moment().startOf('year');
        end = moment().endOf('year');
    }
    else if (tr === 5) { // Last Week
        start = moment().subtract(1,'week').startOf('isoWeek');
        end = moment().subtract(1,'week').endOf('isoWeek');
    }
    else if (tr === 6) { // Last Month
        start = moment().subtract(1, 'months').startOf('month');
        end = moment().subtract(1, 'months').endOf('month');
    }
    else if (tr === 7) { // Last Quarter
        start = moment().subtract(1, 'quarter').startOf('quarter');
        end = moment().subtract(1, 'quarter').endOf('quarter');
    }
    else if (tr === 8) { // Last Year
        const lastYear = moment().get('year') - 1;
        start = moment(`${lastYear}-01-01`);
        end = moment(`${lastYear}-12-31`);
    }
    else if (tr === 9) { // Last 7 Days
        start = moment().subtract(6, 'days');
        end = moment();
    }
    else if (tr === 10) { // Last 30 Days
        start = moment().subtract(30, 'days');
        end = moment();
    }
    else if (tr === 11) { // Last 90 Days
        start = moment().subtract(90, 'days');
        end = moment();
    }
    else if (tr === 12) { // Last 365 Days
        start = moment().subtract(365, 'days');
        end = moment();
    }
    return [start, end];
};


export default function ActivitiesGraphComponent({ id, title: titleProp, consultant: consultantProp, timeRange: timeRangeProp, showConfigButton = false, saveConfigHandler }: Props) {
    const [title, setTitle] = useState('');
    const [consultant, setConsultant] = useState(-1);
    const [timeRange, setTimeRange] = useState(1);
    const [showConfigDialog, setShowConfigDialog] = useState(false);
    const [graphData, setGraphData] = useState<GraphData[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const users = useActiveUsers();
    const t = useTheme();

    useEffect(() => {
        const getData = async () => {
            const dates = getDatesFromTimeRange(timeRangeProp);
            if (dates) {
                const [m1, m2] = dates;
                if (m1 && m2) {
                    const start = m1.format('YYYY-MM-DD');
                    const end = m2.format('YYYY-MM-DD');
                    setIsLoading(true);
                    const res = await GetDashboardActivitiesGraphData(consultantProp, start, end, setErrorMessage);
                    if (res) {
                        const data: GraphData[] = [
                            { title: 'BD Calls', count: res.bdCalls, color: t.palette.text.disabled },
                            { title: 'Client Visits', count: res.clientVisits, color: t.palette.primary.main },
                            { title: 'Floats', count: res.floats, color: t.palette.secondary.main },
                            { title: 'Jobs', count: res.jobs, color: t.palette.info.main },
                            { title: 'Exclusives', count: res.exclusives, color: t.palette.warning.main },
                            { title: 'Subs', count: res.subs, color: t.palette.error.main },
                            { title: 'Interviews', count: res.interviews, color: t.palette.success.main },
                            { title: 'Offers', count: res.offers, color: t.palette.text.primary },
                            { title: 'Placements', count: res.placements, color: t.palette.text.secondary },
                        ];

                        setGraphData(data);
                    }
                }
                setIsLoading(false);
            }
        };
        getData();
    }, [consultantProp, t.palette.error.main, t.palette.info.main, t.palette.primary.main, t.palette.secondary.main, t.palette.success.main, t.palette.text.disabled, t.palette.text.primary, t.palette.text.secondary, t.palette.warning.main, timeRangeProp]);

    const onSaveConfig = useCallback(() => {
        saveConfigHandler && saveConfigHandler(id, title, timeRange, consultant);
        setShowConfigDialog(false);
    }, [id, saveConfigHandler, timeRange, title, consultant]);

    useEffect(() => {
        if (showConfigDialog) {
            setTitle(titleProp);
            setTimeRange(timeRangeProp);
            setConsultant(consultantProp);
        }
    }, [showConfigDialog, timeRangeProp, titleProp, consultantProp]);

    const isError = useMemo(() => Boolean(errorMessage), [errorMessage]);

    return (
        <>
            <Dialog open={showConfigDialog} fullWidth>
                <DialogTitle>Configure Element</DialogTitle>
                <DialogContent>
                    <Stack spacing={2} mt="10px">
                        <RWTextFieldComponent label="Title" value={title} onChange={(e) => setTitle(e.target.value)} />
                        <TextField select label="Consultant" value={consultant.toString()} onChange={({target}) => setConsultant(+target.value)} fullWidth>
                            <MenuItem value="-1">Me</MenuItem>
                            <MenuItem value="-2">My Team</MenuItem>
                            <MenuItem value="-3">My Company</MenuItem>
                            {users.map(u => (
                                <MenuItem key={u.id} value={u.id}>{u.name}</MenuItem>
                            ))}
                        </TextField>
                        <TextField select label="Time Range" value={timeRange.toString()} onChange={({target}) => setTimeRange(+target.value)} fullWidth>
                            <MenuItem value="1">This Week</MenuItem>
                            <MenuItem value="2">This Month</MenuItem>
                            <MenuItem value="3">This Quarter</MenuItem>
                            <MenuItem value="4">This Year</MenuItem>
                            <MenuItem value="5">Last Week</MenuItem>
                            <MenuItem value="6">Last Month</MenuItem>
                            <MenuItem value="7">Last Quarter</MenuItem>
                            <MenuItem value="8">Last Year</MenuItem>
                            <MenuItem value="9">Last 7 Days</MenuItem>
                            <MenuItem value="10">Last 30 Days</MenuItem>
                            <MenuItem value="11">Last 90 Days</MenuItem>
                            <MenuItem value="12">Last 365 Days</MenuItem>
                        </TextField>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="error" onClick={() => setShowConfigDialog(false)}>Cancel</Button>
                    <Button variant="contained" color="success" onClick={onSaveConfig} disabled={!Boolean(titleProp)}>Save</Button>
                </DialogActions>
            </Dialog>
            {showConfigButton &&
                <IconButton
                    sx={{ position: 'absolute', right: 30, zIndex: t => t.zIndex.appBar - 5, color: t => t.palette.text.disabled }}
                    onClick={() => setShowConfigDialog(true)}
                >
                    <SettingsIcon />
                </IconButton>
            }
            <Paper sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                <Typography variant="h6" padding={1} textAlign="center" fontWeight={600} component="div">{titleProp}</Typography>
                { isLoading && <Box m="auto"><CircularProgress /></Box> }
                { isError && !isLoading && <Alert severity="error" sx={{ justifyContent: 'center' }}>{ errorMessage }</Alert> }
                <div style={{ height: 'calc(100% - 48px)', display: isLoading ? 'none' : undefined }}>
                    <ResponsiveContainer width="100%" height="100%">
                        <BarChart width={150} height={150} data={graphData}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="title" type="category" stroke={t.palette.text.primary} />
                            <YAxis type="number" dataKey="count"  />
                            <Tooltip contentStyle={{ backgroundColor: t.palette.background.paper }} />
                            <Bar dataKey="count" name="Number" fill={t.palette.text.disabled} >
                                {graphData.map((d, i) => 
                                    <Cell key={`cell-${i}`} fill={d.color} />
                                )}
                            </Bar>
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </Paper>
        </>
    );
}