import React, { useCallback, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import InputBase from '@mui/material/InputBase';
import CircularProgress from '@mui/material/CircularProgress';
import { alpha, styled } from "@mui/material/styles";

import { GlobalLookup } from '../../services/SearchService';
import { ClickAwayListener, MenuList, Paper, Popper } from "@mui/material";
import GlobalSearchRecord from '../GlobalSearchRecord';
import { IGlobalLookupRecord } from "common/models/SearchDefinitions";
import { GlobalHotkeyHandler } from '../../common/HotkeyHandlers';
import ServerInfoDialog from "../Dialogs/Help/ServerInfoDialog";

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    display: 'flex',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    right: 0,
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        // padding: theme.spacing(1, 1, 1, 0),
        padding: theme.spacing(1, 0, 1, 1),
        // vertical padding + font size from searchIcon
        // paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        paddingRight: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));

export default function WizTopBarSearch(): JSX.Element {
    const navigate = useNavigate();
    const [query, setQuery] = useState('');
    const [results, setResults] = useState<IGlobalLookupRecord[]>([]);
    const [anchor, setAnchor] = useState<HTMLElement | null>(null);
    const [isSearching, setIsSearching] = useState(false);
    const [showList, setShowList] = useState(false);
    const [enterPressed, setEnterPressed] = useState(false);
    const [showServerInfoDialog, setShowServerInfoDialog] = useState(false);

    const localHotkeyHandler = useCallback((e: KeyboardEvent) => {
        if (e.key === 'i' && e.ctrlKey) setShowServerInfoDialog(prev => !prev);
    }, []);

    const hideSearchResultsList = useCallback(() => {
        setShowList(false);
        document.getElementById('globalLookupInput')?.blur();
    }, []);

    useEffect(() => {
        document.addEventListener('keyup', GlobalHotkeyHandler);
        return () => document.removeEventListener('keyup', GlobalHotkeyHandler);
    }, []);

    useEffect(() => {
        document.addEventListener('keydown', localHotkeyHandler);
        return () => document.removeEventListener('keydown', localHotkeyHandler);
    }, [localHotkeyHandler]);

    useEffect(() => {
        document.addEventListener('hideGlobalLookupList', hideSearchResultsList);
        return () => document.removeEventListener('hideGlobalLookupList', hideSearchResultsList);
    }, [hideSearchResultsList]);

    useEffect(() => {
        const delaySearch = setTimeout(async () => {
            if (query.length > 2) {
                setIsSearching(true);
                const data = await GlobalLookup(query);
                if (data) {
                    if (data.lookup === query) {
                        setResults(data.results);
                        setShowList(true);
                    }
                    setIsSearching(false);
                }
            }
            else setResults([]);
        }, 300);

        return () => clearTimeout(delaySearch);
    }, [query]);

    useEffect(() => {
        if (showList && enterPressed) setShowList(false);
    }, [showList, enterPressed]);

    useEffect(() => {
        if (anchor) {
            const delayListDisplay = setTimeout(async () => {
                setShowList(true);
            }, 150);
            return () => clearTimeout(delayListDisplay);
        }
    }, [anchor]);

    const onSubmit = useCallback((e: React.SyntheticEvent) => {
        e.preventDefault();
        setEnterPressed(true);
        const current = window.location.pathname + window.location.search;
        const path = `/dashboard/lookup?q=${encodeURIComponent(query)}`;
        if (path !== current) navigate(path);
        else window.location.reload();

        setQuery("");
    }, [navigate, query]);

    const handleClose = useCallback((e: MouseEvent | TouchEvent) => {
        const targetId = (e.target as any).id;
        if (targetId === 'globalLookupInput' || targetId === 'recruitWizardAppBar') return;
        setQuery("");
        setShowList(false);
    }, []);

    const onSearchInputClick = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setAnchor(e.currentTarget);
        setEnterPressed(false);
        setTimeout(() => setShowList(true), 150);
    }, []);

    const onQueryChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const v = e.currentTarget.value;
        if(v) {
            setEnterPressed(false);
            setShowList(true);
        }
        setQuery(v);
    }, []);

    return (
        <>
            <Search>
                <form onSubmit={onSubmit}>
                    <StyledInputBase
                        id="globalLookupInput"
                        placeholder="Search..."
                        inputProps={{ 'aria-label': 'search' }}
                        onClick={ onSearchInputClick }
                        onChange={onQueryChange}
                        value={query}
                        autoComplete="off"
                    />
                </form>
                <SearchIconWrapper>
                    {isSearching && <CircularProgress size={20} color='inherit' />}
                </SearchIconWrapper>
            </Search>
            <ServerInfoDialog open={showServerInfoDialog} closeHandler={ () => setShowServerInfoDialog(false) } />
            {( showList && Boolean(anchor) && results.length > 0) &&
                <Popper
                    open={Boolean(anchor)}
                    anchorEl={anchor}
                    disablePortal
                    sx={{ zIndex: t => t.zIndex.appBar - 1, p: '0 10px 10px 10px'}}
                >
                    <ClickAwayListener onClickAway={handleClose}>
                        <Paper square elevation={8}>
                            <MenuList sx={{ overflow: 'auto', maxHeight: 'calc(100vh - 53px)', mt: '5px' }}>
                                {results.map((v, i) => (
                                    <GlobalSearchRecord
                                        key={i}
                                        record={v}
                                    />))
                                }
                            </MenuList>
                        </Paper>
                    </ClickAwayListener>
                </Popper>
            }
        </>
    );
}