import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { SxProps } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import moment from "moment";
import React, { useCallback, useState } from "react";
import * as XLSX from "xlsx";
import { Get_IvoryGroup_MarginReportData } from "services/PlacementsService";
import { ReportDataProcessDateFields } from "util/ReportUtils";

interface Props {
    description: string,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void
}

const filterElementStyle: SxProps = { flex: '1 1 0', mr: 1 };

export default function IvoryGroupMarginReport({ description, loadingHandler, errorHandler }: Props) {
    var cur_time = new Date();
    var year = cur_time.getFullYear();
    var month = cur_time.getUTCMonth() + 1;
    var date = (new Date(year, month, 0)).getDate();
    var month_first_day = year + "-" + (month < 10 ? "0" : "") + month + "-01";
    var month_cur_day = year + "-" + (month < 10 ? "0" : "") + month + "-" + (date < 10 ? "0" : "") + date;

    const [start_date, setStartDate] = useState(month_first_day);
    const [end_date, setEndDate] = useState(month_cur_day);

    const getDataCallback = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        const res = await Get_IvoryGroup_MarginReportData(start_date, end_date, errorHandler);
        const wb = XLSX.utils.book_new();
        const m = moment();
        const filename = `IvoryGroup_MarginReport${m.format('YYYYMMDDhhmmss')}.xlsx`;
        if (res && res["data1"]) {
            ReportDataProcessDateFields(res["data1"] as any[]);

            const ws = XLSX.utils.json_to_sheet(res["data1"]);
            XLSX.utils.book_append_sheet(wb, ws, "Summary");
        }
        if (res && res["data2"]) {
            ReportDataProcessDateFields(res["data2"] as any[]);

            const ws = XLSX.utils.json_to_sheet(res["data2"]);
            XLSX.utils.book_append_sheet(wb, ws, "Permanent");
        }
        if (res && res["data3"]) {
            ReportDataProcessDateFields(res["data3"] as any[]);

            const ws = XLSX.utils.json_to_sheet(res["data3"]);
            XLSX.utils.book_append_sheet(wb, ws, "Contract");
        }
        XLSX.writeFile(wb, filename);
        loadingHandler && loadingHandler(false);
    }, [errorHandler, start_date, end_date, loadingHandler]);

    return (
        <>
            <Box display="flex" justifyContent="flex-end">
                <TextField label="From Date" value={start_date.toString()} defaultValue={month_first_day} onChange={({ target }) => setStartDate("" + target.value)} type="date" sx={filterElementStyle} />
                <TextField label="To Date" value={end_date.toString()} defaultValue={month_cur_day} onChange={({ target }) => setEndDate("" + target.value)} type="date" sx={filterElementStyle} />

                <Button variant="contained" color="success" onClick={getDataCallback}>Run Report</Button>
            </Box>
            <Typography component="div" variant="h6" mt={2}>Report Description</Typography>
            <Typography component="div" variant="body2">{description}</Typography>
        </>
    );
}