import Box from "@mui/material/Box";
import { VolcanicDiscipline } from "common/models/JobPosting/Volcanic";
import React, { useEffect, useMemo, useState } from "react";
import { GetVolcanicBoardSetupData } from "services/JobBoardsService";
import JobBoardViewField from "./JobBoardViewField";

interface Props {
    jobBoardId: number,
    advertId: number,
    attributes?: Record<string, string>,
    advertRecordAttributes?: Record<string, string>,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
}

const jobTypeMap: Record<string, string> = {
    "perm": "Permanent",
    "temp": "Temporary / Contract",
};

export default function VolcanicView({ jobBoardId, advertId, attributes, advertRecordAttributes, loadingHandler, errorHandler }: Props) {
    const [disciplines, setDisciplines] = useState<VolcanicDiscipline[]>([]);
    const [subDisciplinesMap, setSubDisciplinesMap] = useState<Record<string, VolcanicDiscipline[]>>({});

    useEffect(() => {
        const getSetupData = async () => {
            loadingHandler && loadingHandler(true);
            const res = await GetVolcanicBoardSetupData(jobBoardId, advertId, errorHandler);
            let parents: VolcanicDiscipline[] = [];
            let map: Record<string, VolcanicDiscipline[]> = {};
            if (res) {
                for (let i = 0; i < res.length; i++) {
                    const item = res[i];
                    if (item.parent_id) {
                        if (map[item.parent_id]) map[item.parent_id].push(item);
                        else map[item.parent_id] = [item];
                    }
                    else parents.push(item);
                }
            }
            setDisciplines(parents);
            setSubDisciplinesMap(map);
            loadingHandler && loadingHandler(false);
        };
        getSetupData();
    }, [advertId, errorHandler, jobBoardId, loadingHandler]);

    const atts = useMemo<Record<string, string>>(() => {
        if (attributes) return attributes;
        return {};
    }, [attributes]);

    const recordAtts = useMemo<Record<string, string>>(() => {
        if (advertRecordAttributes) return advertRecordAttributes;
        return {};
    }, [advertRecordAttributes]);

    const country = useMemo(() => recordAtts.country ?? '', [recordAtts.country]);
    const region = useMemo(() => recordAtts.region ?? '', [recordAtts.region]);
    const location = useMemo(() => recordAtts.location ?? '', [recordAtts.location]);
    const salaryType = useMemo(() => recordAtts.salaryType ?? '', [recordAtts.salaryType]);
    const salaryFrom = useMemo(() => recordAtts.salaryFrom ?? '', [recordAtts.salaryFrom]);
    const salaryTo = useMemo(() => recordAtts.salaryTo ?? '', [recordAtts.salaryTo]);
    const displaySalary = useMemo(() => recordAtts.displaySalary ?? '', [recordAtts.displaySalary]);

    const jobType = useMemo(() => atts.JobType ?? '', [atts.JobType]);
    const discipline = useMemo(() => atts.Discipline ?? '', [atts.Discipline]);
    const subDiscipline = useMemo(() => atts.SubDiscipline ?? '', [atts.SubDiscipline]);
    const contractDuration = useMemo(() => atts.ContractDuration ?? '', [atts.ContractDuration]);
    const salaryBenefits = useMemo(() => atts.SalaryBenefits ?? '', [atts.SalaryBenefits]);

    const disciplineName = useMemo(() => {
        if (discipline && disciplines && disciplines.length > 0) {
            const d = disciplines.find(v => v.reference === discipline);
            if (d) return d.name;
        }
        return '';
    }, [discipline, disciplines]);

    const subDisciplineName = useMemo(() => {
        if (discipline) {
            const opts = subDisciplinesMap[discipline];
            if (opts) {
                const sd = opts.find(o => o.reference === subDiscipline);
                if (sd) return sd.name;
            }
        }
        return '';
    }, [discipline, subDiscipline, subDisciplinesMap]);

    return (
        <Box display="flex" gap={2} pt={2}>
            <Box display="flex" flexDirection="column" gap={2} width="50%">
                <JobBoardViewField label="Job Type" value={jobTypeMap[jobType] ?? ''} />
                <JobBoardViewField label="Discipline" value={disciplineName} />
                <JobBoardViewField label="Sub Discipline" value={subDisciplineName} />
                <JobBoardViewField label="Job Duration" value={contractDuration} />
            </Box>
            <Box display="flex" flexDirection="column" gap={2} width="50%">
                <JobBoardViewField label="Country" value={country} />
                <JobBoardViewField label="Region" value={region} />
                <JobBoardViewField label="Location" value={location} />
                <JobBoardViewField label="Salary Type" value={salaryType} />
                <Box display="flex" gap={2}>
                    <JobBoardViewField label="Salary From" sx={{ flex: '1 1 0' }} value={salaryFrom} />
                    <JobBoardViewField label="Salary To" sx={{ flex: '1 1 0' }} value={salaryTo} />
                </Box>
                <JobBoardViewField label="Display Salary" value={displaySalary} />
                <JobBoardViewField label="Salary Benefits" value={salaryBenefits} />
            </Box>
        </Box>
    );
}