import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import ControlCameraIcon from '@mui/icons-material/ControlCamera';
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Draggable from "react-draggable";
import { Contact, ContactOwner } from "common/models/Contacts";
import { PreviewProps } from "common/models/Previews/Previews";
import { GetContactById, GetContactOwners } from "services/ContactsService";
import Divider from "@mui/material/Divider";
import { PREVIEW_HEIGHT, PREVIEW_TEXT_SKELETON, PREVIEW_TITLE_STYLE, PREVIEW_WIDTH } from "util/Definitions/Constants/Previews";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { CustomField } from "common/models/Configuration/CustomFields";
import { DashboardLayoutComponent, PanelModel } from "@syncfusion/ej2-react-layouts";
import useTheme from "@mui/material/styles/useTheme";
import PanelWrapper from "components/Dashboards/PanelWrapper";
import { ContactQuickViewLayoutSettings, DefaultContactQuickViewLayoutElements, GetPanelDefinitionsFromPanelModels } from "util/Definitions/QuickViewLayout/Contact";
import { GetCustomFieldsByEntity_OnlyActive } from "services/CustomFieldsService";
import { GetDivisions } from "services/DivisionsService";
import { CustomFieldSettingsMap, CustomFieldType } from "common/models/ScreenLayouts/CustomFields";
import { Division } from "common/models/Configuration/Division";
import { GetCustomerSettingBySettingName } from "services/ConfigurationService";
import { ContactRecordDashboardElementDefinition, ContactRecordDashboardElementType } from "common/models/Dashboard/EditLayout";
import RecordAvatarComponent from "components/Dashboards/Graphs/ClientAvatarComponent";
import SingleFieldElement from "components/ScreenLayouts/SingleFieldElement";
import ViewClientLocationElement from "components/ScreenLayouts/Clients/ViewClientLocationElement";
import MultiLineTextElement from "components/ScreenLayouts/MultiLineTextElement";
import { UdfContactFieldMapObj } from "util/Definitions/ScreenLayouts/CustomFields";
import Chip from "@mui/material/Chip";

const { unitWidth, unitHeight, gapX, gapY, columns } = ContactQuickViewLayoutSettings;
const cellSpacing = [gapX, gapY];

export default function ContactQuickViewComponent({ recordId, isSourceHovered, hideCallback, hideDelayMs = 1250 }: PreviewProps) {
    const [shouldClose, setShouldClose] = useState(false);
    const [isFetchingData, setIsFetchingData] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [contact, setContact] = useState<Contact>();

    const [isLoading, setIsLoading] = useState(false);
    const [screenResizedControl, setScreenResizedControl] = useState(false);
    const [fetchingSavedState, setFetchingSavedState] = useState(false);
    const [fetchedSavedState, setFetchedSavedState] = useState(false);
    const [activeFields, setActiveFields] = useState<CustomField[]>([]);
    const [lastSavedState, setLastSavedState] = useState<PanelModel[] | null>(null);
    const [owners, setOwners] = useState<ContactOwner[]>([]);
    const [divisions, setDivisions] = useState<Division[]>([]);
    const theme = useTheme();

    useEffect(() => {
        const windowResizeHandler = () => setScreenResizedControl(prev => !prev);
        window.addEventListener('resize', windowResizeHandler);
        return () => window.removeEventListener('resize', windowResizeHandler);
    }, []);

    useEffect(() => {
        const getData = async () => {
            setIsFetchingData(true);
            const res = await GetContactById(recordId);
            if (res) setContact(res);
            setIsFetchingData(false);
        };
        getData();
    }, [recordId]);

    useEffect(() => {
        if (shouldClose) {
            const hidePreviewTimeout = setTimeout(() => {
                hideCallback();
            }, hideDelayMs);
            return () => clearTimeout(hidePreviewTimeout);
        }
    }, [shouldClose, hideDelayMs, hideCallback]);

    useEffect(() => {
        if (isSourceHovered || isHovered) setShouldClose(false);
        else setShouldClose(true);
    }, [isSourceHovered, isHovered]);

    useEffect(() => {
        setIsLoading(fetchingSavedState || isFetchingData);
    }, [fetchingSavedState, isFetchingData]);

    useEffect(() => {
        const getActiveFields = async () => {
            const res = await GetCustomFieldsByEntity_OnlyActive(2);
            if (res) setActiveFields(res);
        };
        getActiveFields();
    }, []);

    useEffect(() => {
        const getOwners = async () => {
            if (contact) {
                const res = await GetContactOwners(contact.id);
                if (res) setOwners(res);
            }
        };
        contact && contact.id && getOwners();
    }, [contact]);

    useEffect(() => {
        const getDivs = async () => {
            const res = await GetDivisions();
            if (res) setDivisions(res);
        };
        getDivs();
    }, []);

    const divisionNames = useMemo<string[]>(() => {
        if (contact) {
            let names: string[] = [];
            const ids = contact.divisions.split(';');
            ids.forEach(d => {
                const item = divisions.find(div => div.id === +d);
                if (item) names.push(item.name);
            });
            return names;
        }
        return [];
    }, [contact, divisions]);

    const customFieldsSettingsMap = useMemo<CustomFieldSettingsMap>(() => {
        let obj: CustomFieldSettingsMap = {};
        activeFields.forEach(u => {
            const customFieldNumber = u.name.substring(13);
            const key = 'CustomField' + customFieldNumber as CustomFieldType;
            obj[key] = {
                title: u.agencyName,
                isMultiLine: u.multiLine && !u.usePredefinedValues,
            }
        });
        return obj;
    }, [activeFields]);

    useEffect(() => {
        const getSavedState = async () => {
            setFetchingSavedState(true);
            const elementsJson = await GetCustomerSettingBySettingName('ContactQuickViewLayoutElements');
            if (elementsJson) {
                const panels = JSON.parse(elementsJson) as PanelModel[];
                setLastSavedState(panels);
            }
            setFetchingSavedState(false);
            setFetchedSavedState(true);
        };
        getSavedState();
    }, []);

    const elements = useMemo<ContactRecordDashboardElementDefinition[]>(() => {
        if (!fetchedSavedState) return [];
        if (lastSavedState !== null) {
            const elements = GetPanelDefinitionsFromPanelModels(lastSavedState);
            return elements ;
        }
        return DefaultContactQuickViewLayoutElements;
    }, [lastSavedState, fetchedSavedState]);

    const renderElement = useCallback((id: string, type: ContactRecordDashboardElementType) => {
        if (contact) {
            switch (type) {
                case 'Divider': return <Box pt="20px"><Divider component="div" /></Box>;
                case 'Spacer': return <></>;
                case 'ContactInfo': return (
                    <RecordAvatarComponent
                        entityId={2}
                        primaryText={contact.fullName}
                        secondaryText={contact.clientName ?? ''}
                        photoUrl={contact.photoUrl}
                    />);
                case 'ContactId': return <SingleFieldElement useEllipsisForLongValues fieldTitle="ID" fieldValue={contact.id.toString()} />;
                case 'ContactName': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Name" fieldValue={contact.fullName} />;
                case 'ContactJobTitle': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Job Title" fieldValue={contact.jobTitle} />;
                case 'ContactClient': return (
                    <SingleFieldElement
                        fieldTitle='Client'
                        fieldValue={contact.clientName ?? ''}
                        format="internal-link"
                        href={`/clients/${contact.clientID}`}
                        previewType="client"
                        previewRecordId={contact.clientID}
                    />);
                case 'ContactManager': return (
                    <SingleFieldElement
                        fieldTitle="Manager"
                        fieldValue={contact.reportsToName ?? 'None'}
                        previewType="contact"
                        previewRecordId={contact.reportsToID}
                    />);
                case 'ContactOwner': return <SingleFieldElement fieldTitle="Owners" fieldValue={owners.length.toString()} />;
                case 'ContactLinkedIn':
                    const linkedIn = contact.linkedinURL;
                    const lhref = linkedIn && !linkedIn.startsWith('http') ? 'https://' + linkedIn : linkedIn;
                    return <SingleFieldElement fieldTitle='LinkedIn' href={lhref} fieldValue={linkedIn} format="external-link" />;
                case 'ContactTwitter':
                    const twitter = contact.twitterURL;
                    const thref = twitter && !twitter.startsWith('http') ? 'https://' + twitter : twitter;
                    return <SingleFieldElement fieldTitle='Twitter' href={thref} fieldValue={twitter} format="external-link" />;
                case 'ContactStatus': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Status" fieldValue={contact.statusName} />;
                case 'ContactEmail1': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Email 1" fieldValue={contact.email} format="email-link" href={contact.email} />;
                case 'ContactEmail2': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Email 2" fieldValue={contact.email2} format="email-link" href={contact.email2} />;
                case 'ContactOptOut': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Opt Out" fieldValue={contact.optOut ? 'Yes' : 'No'} />;
                case 'ContactLocation': return (
                    <ViewClientLocationElement
                        address1={contact.address1 ?? ''}
                        address2={contact.address2 ?? ''}
                        address3={contact.address3 ?? ''}
                        countryName={contact.countryName}
                        postcode={contact.postcode ?? ''}
                        siteId={contact.siteID}
                        siteName={contact.siteName ?? ''}
                        state={contact.state ?? ''}
                        suburb={contact.suburb ?? ''}
                    />
                );
                case 'ContactMobile': 
                    return <SingleFieldElement useEllipsisForLongValues fieldTitle="Mobile" fieldValue={contact.mobile} href={contact.mobile_Standardised} format="phone-link" />;
                case 'ContactPhone': 
                    return <SingleFieldElement useEllipsisForLongValues fieldTitle="Phone" fieldValue={contact.phone} href={contact.phone_Standardised} format="phone-link" />;
                case 'ContactSummary': return <MultiLineTextElement title="Summary" content={contact.description} />;
                case 'ContactSummaryFullWidth': return <MultiLineTextElement title="Summary" content={contact.description} dividerTitle />;
                case 'ContactCandidate': return (
                    <SingleFieldElement
                        fieldTitle="Candidate"
                        fieldValue={contact.linkedCandidateName}
                        format="internal-link"
                        href={`/candidates/${contact.linkedCandidateID}`}
                        previewType="candidate"
                        previewRecordId={contact.linkedCandidateID}
                    />);
                case 'ContactDivision': return (
                    <SingleFieldElement fieldTitle="Divisions" format="custom">
                        {divisionNames.map(d => (
                            <Chip key={d} size="small" label={d} sx={{ mr: '5px' }} />
                        ))}
                    </SingleFieldElement>
                );
                default: 
                    if (type.startsWith('CustomField')) {
                        const settings = customFieldsSettingsMap[type];
                        if (settings) {
                            const title = settings.title;
                            const { field, format } = UdfContactFieldMapObj[type as CustomFieldType];
                            const udfValue = contact[field];
                            if (format === 'string' || format === 'date' || format === 'datetime') {
                                return <SingleFieldElement isMultiLine={settings.isMultiLine} format={format} fieldTitle={title} fieldValue={udfValue ? udfValue.toString() : null} />;
                            }
                            if (format === 'number') {
                                const numValue = udfValue === undefined || udfValue === null ? '' : udfValue.toString();
                                return <SingleFieldElement fieldTitle={title} fieldValue={numValue} />;
                            }
                        }
                        else return <SingleFieldElement fieldTitle={type} fieldValue="[ Disabled Custom Field ]" />
                    }
                    return <div>{id}</div>;
            }
        }
    }, [contact, customFieldsSettingsMap, divisionNames, owners.length]);

    const layoutResizeStopHandler = useCallback(() => {
        setScreenResizedControl(prev => !prev);
    }, []);

    const renderLayout = useCallback(() => {
        if (!fetchedSavedState) return <></>;
        return (
            <DashboardLayoutComponent
                cellSpacing={cellSpacing}
                columns={columns}
                cellAspectRatio={12 / 2}
                resizeStop={ layoutResizeStopHandler }
                allowDragging={false}
                allowResizing={false}
            >
                {elements.map(e => (
                    <PanelWrapper
                        key={e.id}
                        id={e.id}
                        col={e.col}
                        row={e.row}
                        sizeX={e.sizeX}
                        sizeY={e.sizeY}
                        minSizeX={e.minSizeX}
                        minSizeY={e.minSizeY}
                        maxSizeX={e.maxSizeX}
                        maxSizeY={e.maxSizeY}
                        resizeControl={screenResizedControl}
                        resizeIconColor={theme.palette.text.disabled}
                        gapX={gapX}
                        gapY={gapY}
                        unitWidth={unitWidth}
                        unitHeight={unitHeight}
                    >
                        {renderElement(e.id, e.type)}
                    </PanelWrapper>
                ))}
            </DashboardLayoutComponent>
        );
    }, [fetchedSavedState, layoutResizeStopHandler, elements, screenResizedControl, theme.palette.text.disabled, renderElement]);

    return (
        <>
            <Draggable
                handle="#draggable-dialog-title"
                cancel={'[class*="MuiDialogContent-root"]'}
                defaultPosition={{ x: (window.innerWidth / 2) - (PREVIEW_WIDTH / 2), y: (window.innerHeight / 2) - (PREVIEW_HEIGHT / 2) }}
            >
                <Dialog
                    open={true}
                    maxWidth={false}
                    PaperProps={{
                        onMouseEnter: () => setIsHovered(true),
                        onMouseLeave: () => setIsHovered(false),
                        sx: { height: `${PREVIEW_HEIGHT}px`, width: `${PREVIEW_WIDTH}px`, p: 0 }
                    }}
                    hideBackdrop
                    sx={{ bottom: 'unset', right: 'unset', left: 'unset' }}
                >
                    <DialogTitle component="div" display="flex" sx={ PREVIEW_TITLE_STYLE }>
                        <div style={{ alignSelf: 'center' }}>
                            { isFetchingData || !contact ? PREVIEW_TEXT_SKELETON : contact.fullName }
                        </div>
                        <div style={{ marginLeft: 'auto' }}>
                            <IconButton id="draggable-dialog-title" sx={{ cursor: 'move', color: t => t.palette.primary.contrastText }}><ControlCameraIcon /></IconButton>
                            <IconButton onClick={ () => hideCallback() } sx={{ color: t => t.palette.primary.contrastText }}><CloseIcon /></IconButton>
                        </div>
                    </DialogTitle>
                    <DialogContent sx={{ p: 0 }}>
                        {isLoading &&
                            <Box display="flex" justifyContent="center" alignItems="center" height="100%" >
                                <CircularProgress />
                            </Box>
                        }
                        {!isLoading &&
                            <Box p="10px" height="100%">
                                <div className="control-section">
                                    { renderLayout() }
                                </div>
                            </Box>
                        }
                    </DialogContent>
                </Dialog>
            </Draggable>
        </>
    );
}