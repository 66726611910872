import { MainDashboardElementDefinition, MainDashboardElementType, ExtendedPanelModel, TypesTracker } from "common/models/Dashboard/EditLayout";

export const DefaultMainDashboardElements: MainDashboardElementDefinition[] = [
    { id: 'UserInfo_Default', sizeX: 3, sizeY: 1, row: 0, col: 0, minSizeX: 2, minSizeY: 1, maxSizeX: null, maxSizeY: null, type: 'UserInfo' },
    { id: "ForecastThisMonth_Default", row: 1, col: 0, sizeX: 3, sizeY: 2, minSizeX: 3, minSizeY: 2, maxSizeX: null, maxSizeY: null, type: 'ForecastThisMonth' },
    { id: "ForecastNextMonth_Default", row: 3, col: 0, sizeX: 3, sizeY: 2, minSizeX: 3, minSizeY: 2, maxSizeX: null, maxSizeY: null, type: 'ForecastNextMonth' },
    { id: "BillingsThisMonth_Default", row: 0, col: 12, sizeX: 3, sizeY: 2, minSizeX: 3, minSizeY: 2, maxSizeX: null, maxSizeY: null, type: 'BillingsThisMonth' },
    { id: "BillingsThisQtr_Default", row: 2, col: 12, sizeX: 3, sizeY: 2, minSizeX: 3, minSizeY: 2, maxSizeX: null, maxSizeY: null, type: 'BillingsThisQtr' },
    { id: "BillingsThisYear_Default", row: 4, col: 12, sizeX: 3, sizeY: 2, minSizeX: 3, minSizeY: 2, maxSizeX: null, maxSizeY: null, type: 'BillingsThisYear' },
    { id: "MyActiveInterviews_Default", row: 0, col: 7, sizeX: 5, sizeY: 3, minSizeX: 4, minSizeY: 3, maxSizeX: null, maxSizeY: null, type: 'MyActiveInterviews' },
    { id: "MyOpenJobs_Default", row: 0, col: 3, sizeX: 4, sizeY: 3, minSizeX: 4, minSizeY: 3, maxSizeX: null, maxSizeY: null, type: 'MyOpenJobs' },
    { id: "HistoricalBillings_Default", row: 3, col: 3, sizeX: 5, sizeY: 3, minSizeX: 4, minSizeY: 2, maxSizeX: null, maxSizeY: null, type: 'HistoricalBillings' },
    { id: "CurrentContractors_Default", row: 3, col: 8, sizeX: 4, sizeY: 3, minSizeX: 3, minSizeY: 2, maxSizeX: null, maxSizeY: null, type: 'CurrentContractors' },
];

export const DefaultMainDashboardElementTypesTracker: TypesTracker = {
    BillingsThisMonth: false,
    BillingsThisQtr: false,
    BillingsThisYear: false,
    CurrentContractors: false,
    ForecastNextMonth: false,
    ForecastThisMonth: false,
    HistoricalBillings: false,
    MyActiveInterviews: false,
    MyOpenJobs: false,
    UserInfo: false,
    LinkButton: false,
    Activity: false,
    JobPipeline: false,
    CandidatePipeline: false,
    PlacementFees: false,
    KPI: false,
    Ratio: false,
    "": false,
};

export const GetMinSizeByElementType = (type: MainDashboardElementType) => {
    switch (type) {
        case "BillingsThisMonth": return [3, 2];
        case "BillingsThisQtr": return [3, 2];
        case "BillingsThisYear": return [3, 2];
        case "CurrentContractors": return [3, 2];
        case "ForecastNextMonth": return [3, 2];
        case "ForecastThisMonth": return [3, 2];
        case "HistoricalBillings": return [4, 2];
        case "MyActiveInterviews": return [4, 3];
        case "MyOpenJobs": return [4, 3];
        case "UserInfo": return [2, 1];
        case "Activity": return [4, 2];
        case "PlacementFees": return [4, 2];
        case "KPI": return [3, 2];
        case "Ratio": return [3, 2];
        default: return [1, 1];
    }
};

export const GetDefaultSizeByElementType = (type: MainDashboardElementType) => {
    switch (type) {
        case "BillingsThisMonth": return [3, 2];
        case "BillingsThisQtr": return [3, 2];
        case "BillingsThisYear": return [3, 2];
        case "CurrentContractors": return [3, 2];
        case "ForecastNextMonth": return [3, 2];
        case "ForecastThisMonth": return [3, 2];
        case "HistoricalBillings": return [4, 2];
        case "MyActiveInterviews": return [4, 3];
        case "MyOpenJobs": return [4, 3];
        case "UserInfo": return [2, 1];
        case "LinkButton": return [3, 1];
        case "Activity": return [7, 2];
        case "JobPipeline": return [11, 1];
        case "CandidatePipeline": return [11, 1];
        default: return [1, 1];
    }
};

export const GetElementTypeById = (id: string): MainDashboardElementType => {
    if (id && id.startsWith('UserInfo_')) return 'UserInfo';
    if (id && id.startsWith('CurrentContractors_')) return 'CurrentContractors';
    if (id && id.startsWith('BillingsThisMonth_')) return 'BillingsThisMonth';
    if (id && id.startsWith('BillingsThisQtr_')) return 'BillingsThisQtr';
    if (id && id.startsWith('BillingsThisYear_')) return 'BillingsThisYear';
    if (id && id.startsWith('ForecastNextMonth_')) return 'ForecastNextMonth';
    if (id && id.startsWith('ForecastThisMonth_')) return 'ForecastThisMonth';
    if (id && id.startsWith('HistoricalBillings_')) return 'HistoricalBillings';
    if (id && id.startsWith('MyActiveInterviews_')) return 'MyActiveInterviews';
    if (id && id.startsWith('MyOpenJobs_')) return 'MyOpenJobs';
    if (id && id.startsWith('LinkButton_')) return 'LinkButton';
    if (id && id.startsWith('Activity_')) return 'Activity';
    if (id && id.startsWith('JobPipeline_')) return 'JobPipeline';
    if (id && id.startsWith('CandidatePipeline_')) return 'CandidatePipeline';
    if (id && id.startsWith('PlacementFees_')) return 'PlacementFees';
    if (id && id.startsWith('KPI_')) return 'KPI';
    if (id && id.startsWith('Ratio_')) return 'Ratio';
    return '';
};

export const GetPanelDefinitionsFromPanelModels = (panels: ExtendedPanelModel[]) => {
    let elements: MainDashboardElementDefinition[] = [];
    panels.forEach((p, i) => {
        let type = GetElementTypeById(p.id ?? '');
        elements.push({
            id: p.id ?? i.toString(),
            sizeX: p.sizeX ?? 1,
            sizeY: p.sizeY ?? 1,
            col: p.col ?? 0,
            row: p.row ?? 0,
            minSizeX: p.minSizeX ?? 1,
            minSizeY: p.minSizeY ?? 1,
            maxSizeX: p.maxSizeX ?? null,
            maxSizeY: p.maxSizeY ?? null,
            type: type,
            config: p.config
        });
    });
    return elements ;
};