import { PanelModel } from "@syncfusion/ej2-layouts/src/dashboard-layout/dashboard-layout-model";
import { ContactRecordDashboardElementDefinition, ContactRecordDashboardElementType } from "common/models/Dashboard/EditLayout";
import { ScreenLayoutSettings } from "common/models/ScreenLayouts/ScreenLayouts";
import { GetCustomFieldTypeById } from "./CustomFields";

export const DefaultContactRecordDashboardElements: ContactRecordDashboardElementDefinition[] = [
    { id: "ContactName_Default", row: 0, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: "ContactName"},
    { id: "ContactJobTitle_Default", row: 1, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: "ContactJobTitle"},
    { id: "ContactClient_Default", row: 2, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: "ContactClient"},
    { id: "ContactStatus_Default", row: 3, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: "ContactStatus"},
    { id: "ContactMobile_Default", row: 4, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: "ContactMobile"},
    { id: "ContactPhone_Default", row: 5, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: "ContactPhone"},
    { id: "ContactEmail1_Default", row: 6, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: "ContactEmail1"},
    { id: "ContactEmail2_Default", row: 7, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: "ContactEmail2"},
    { id: "ContactOptOut_Default", row: 8, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: "ContactOptOut"},
    { id: "Divider_Default1", row: 9, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: "Divider"},
    { id: "ContactOwner_Default", row: 10, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: "ContactOwner"},
    
    { id: "ContactLocation_Default", row: 0, col: 1, sizeX: 1, sizeY: 9, minSizeX: 1, minSizeY: 9, maxSizeX: 1, maxSizeY: 9, type: "ContactLocation"},
    { id: "Divider_Default2", row: 9, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: "Divider"},
    { id: "ContactCandidate_Default", row: 10, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: "ContactCandidate"},
    
    { id: "ContactSummary_Default", row: 0, col: 2, sizeX: 1, sizeY: 9, minSizeX: 1, minSizeY: 3, maxSizeX: 1, maxSizeY: null, type: "ContactSummary"},
    { id: "Divider_Default3", row: 9, col: 2, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: "Divider"},
    { id: "ContactLinkedIn_Default", row: 10, col: 2, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: "ContactLinkedIn"},
    { id: "ContactTwitter_Default", row: 11, col: 2, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: "ContactTwitter"},
];

export const GetMinSizeByElementType = (type: ContactRecordDashboardElementType) => {
    switch (type) {
        case "ContactInfo": return [1, 3];
        case "ContactSummary": return [1, 9];
        case "ContactLocation": return [1, 9];
        default: return [1, 1];
    }
};

export const GetElementTypeById = (id: string): ContactRecordDashboardElementType => {
    if ( id && id.startsWith('Divider_')) return 'Divider';
    if ( id && id.startsWith('Spacer_')) return 'Spacer';
    if (id && id.startsWith('ContactInfo_')) return 'ContactInfo';
    if (id && id.startsWith('ContactId_')) return 'ContactId';
    if (id && id.startsWith('ContactName_')) return 'ContactName';
    if (id && id.startsWith('ContactJobTitle_')) return 'ContactJobTitle';
    if (id && id.startsWith('ContactClient_')) return 'ContactClient';
    if (id && id.startsWith('ContactManager_')) return 'ContactManager';
    if (id && id.startsWith('ContactOwner_')) return 'ContactOwner';
    if (id && id.startsWith('ContactStatus_')) return 'ContactStatus';
    if (id && id.startsWith('ContactEmail1_')) return 'ContactEmail1';
    if (id && id.startsWith('ContactEmail2_')) return 'ContactEmail2';
    if (id && id.startsWith('ContactOptOut_')) return 'ContactOptOut';
    if (id && id.startsWith('ContactLocation_')) return 'ContactLocation';
    if (id && id.startsWith('ContactMobile_')) return 'ContactMobile';
    if (id && id.startsWith('ContactPhone_')) return 'ContactPhone';
    if (id && id.startsWith('ContactSummary_')) return 'ContactSummary';
    if (id && id.startsWith('ContactCandidate_')) return 'ContactCandidate';
    if (id && id.startsWith('ContactDivision_')) return 'ContactDivision';
    if (id && id.startsWith('ContactLinkedIn_')) return 'ContactLinkedIn';
    if (id && id.startsWith('ContactTwitter_')) return 'ContactTwitter';
    if ( id && id.startsWith('CustomField')) return GetCustomFieldTypeById(id);
    return '';
};

export const GetPanelDefinitionsFromPanelModels = (panels: PanelModel[]) => {
    let elements: ContactRecordDashboardElementDefinition[] = [];
    panels.forEach((p, i) => {
        let type = GetElementTypeById(p.id ?? '');
        elements.push({
            id: p.id ?? i.toString(),
            sizeX: p.sizeX ?? 1,
            sizeY: p.sizeY ?? 1,
            col: p.col ?? 0,
            row: p.row ?? 0,
            minSizeX: p.minSizeX ?? 1,
            minSizeY: p.minSizeY ?? 1,
            maxSizeX: p.maxSizeX ?? 1,
            maxSizeY: p.maxSizeY ?? null,
            type: type
        });
    });
    return elements ;
};

export const ContactScreenLayoutSettings: ScreenLayoutSettings = {
    unitWidth: 600,
    unitHeight: 40,
    gapX: 10,
    gapY: 0,
    columns: 3,
    mediaQueryMaxWidth: '1000px'
}