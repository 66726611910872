import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import Typography from "@mui/material/Typography";
import * as MUIcon from "@mui/icons-material";
import React from "react";
import { Report } from "common/models/Reports/Report";
import { Link } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import moment from "moment";
import Chip from "@mui/material/Chip";

interface Props {
    data: Report[],
}

const FallbackIconComponent = MUIcon['TableRows'];
const fallbackIcon = <FallbackIconComponent sx={{ fontSize: '35px' }} />;

export default function ReportsGridComponent({ data }: Props) {
    return (
        <Box display="flex" flexWrap="wrap" justifyContent="center">
            {data.map(report => {
                const TestIcon = MUIcon[report.iconName as keyof typeof MUIcon];
                const createdString = report.creationDate;
                const updateString = report.updatedDate;
                const customString = report.custom;
                const today = moment.utc();
                let badgeText = '';

                if (customString) {
                    badgeText = 'Custom';
                }

                if (createdString) {
                    const createdMoment = moment.utc(createdString);
                    const days = today.diff(createdMoment, 'days');
                    if(days <= 90) badgeText = 'NEW';
                }

                if (!badgeText && updateString) {
                    const updatedMoment = moment.utc(updateString);
                    const days = today.diff(updatedMoment, 'days');
                    if(days <= 90) badgeText = 'UPDATED';
                }

                return (
                <Card key={report.pathName} elevation={6} sx={{ display: 'flex', width: '350px', height: '150px', m: '10px' }}>
                    <CardActionArea>
                        <Link to={report.pathName} style={{ color: 'inherit', textDecoration: 'none', padding: "10px", display: "flex", width: "100%", height: "100%" }}>
                            { badgeText && (
                                <Box component="span" display="flex" justifyContent="center" alignItems="center" position="absolute" bottom="10px" right="10px">
                                    <Chip label={badgeText} size="small" />
                                </Box>
                            )}
                            <Avatar alt={report.title} sx={{ mr: '10px', width: '50px', height: '50px', bgcolor: 'primary.main' }}>
                                { TestIcon ? <TestIcon sx={{ fontSize: '35px' }} /> : fallbackIcon }
                            </Avatar>
                            <Box display="flex" flexDirection="column" overflow="hidden">
                                <Typography gutterBottom variant="subtitle1" component="div" title={report.title}>{report.title}</Typography>
                                <Typography gutterBottom flexGrow={1} variant="body2" color="text.secondary" component="div" textOverflow="ellipsis">{report.shortDescription}</Typography>
                            </Box>
                        </Link>
                    </CardActionArea>
                </Card>
            )})}
        </Box>
    );
}