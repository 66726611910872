import Alert from "components/Alert";
import Snackbar from "@mui/material/Snackbar";
import React, { useState } from "react";
import PlacementsGridComponent from "components/Grids/PlacementsGrid";
import OnlyTitleSummaryBar from "components/SummaryBars/OnlyTitleSummaryBar";
import PageContentLayout from "layouts/PageContentLayout";
import PageLayout from "layouts/PageLayout";

const summaryBar = <OnlyTitleSummaryBar title="Placements > My Placements" browserTabTitle="My Placements > Placements" />;

export default function MyPlacementsPage() {
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    return (
        <>
            <Snackbar open={errorMessage !== ''} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <Snackbar open={successMessage !== ''} autoHideDuration={3000} onClose={() => setSuccessMessage('')}>
                <Alert onClose={() => setSuccessMessage('')}>{ successMessage }</Alert>
            </Snackbar>
            <PageLayout SummaryBar={summaryBar}>
                <PageContentLayout showLoading={isLoading}>
                    <PlacementsGridComponent
                        gridName="placements/my-placements"
                        source="my-placements"
                        loadingHandler={setIsLoading}
                        errorHandler={setErrorMessage}
                        successHandler={setSuccessMessage}
                    />
                </PageContentLayout>
            </PageLayout>
        </>
    );
}