import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import ControlCameraIcon from '@mui/icons-material/ControlCamera';
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Draggable from "react-draggable";
import { PreviewProps } from "common/models/Previews/Previews";
import { Site } from "common/models/Site";
import { GetSiteById } from "services/SitesService";
import { PREVIEW_TEXT_SKELETON, PREVIEW_TITLE_STYLE, PREVIEW_WIDTH } from "util/Definitions/Constants/Previews";
import { CustomField } from "common/models/Configuration/CustomFields";
import { DashboardLayoutComponent, PanelModel } from "@syncfusion/ej2-react-layouts";
import { useTheme } from "@mui/material/styles";
import { DefaultSiteQuickViewLayoutElements, GetPanelDefinitionsFromPanelModels, SiteQuickViewLayoutSettings } from "util/Definitions/QuickViewLayout/Site";
import { GetCustomFieldsByEntity_OnlyActive } from "services/CustomFieldsService";
import { CustomFieldSettingsMap, CustomFieldType } from "common/models/ScreenLayouts/CustomFields";
import { GetCustomerSettingBySettingName } from "services/ConfigurationService";
import { SiteRecordDashboardElementDefinition, SiteRecordDashboardElementType } from "common/models/Dashboard/EditLayout";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import SingleFieldElement from "components/ScreenLayouts/SingleFieldElement";
import MultiLineTextElement from "components/ScreenLayouts/MultiLineTextElement";
import { UdfSiteFieldMapObj } from "util/Definitions/ScreenLayouts/CustomFields";
import PanelWrapper from "components/Dashboards/PanelWrapper";
import CircularProgress from "@mui/material/CircularProgress";

const halfPreviewHeight = 322;
const { unitWidth, unitHeight, gapX, gapY, columns } = SiteQuickViewLayoutSettings;
const cellSpacing = [gapX, gapY];

export default function SiteQuickViewComponent({ recordId, isSourceHovered, hideCallback, hideDelayMs = 1250 }: PreviewProps) {
    const [shouldClose, setShouldClose] = useState(false);
    const [isFetchingData, setIsFetchingData] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [site, setSite] = useState<Site>();

    const [isLoading, setIsLoading] = useState(false);
    const [screenResizedControl, setScreenResizedControl] = useState(false);
    const [fetchingSavedState, setFetchingSavedState] = useState(false);
    const [fetchedSavedState, setFetchedSavedState] = useState(false);
    const [activeFields, setActiveFields] = useState<CustomField[]>([]);
    const [lastSavedState, setLastSavedState] = useState<PanelModel[] | null>(null);
    const [isFetchingConfig, setIsFetchingConfig] = useState(false);
    const theme = useTheme();

    useEffect(() => {
        const getData = async () => {
            setIsFetchingData(true);
            const res = await GetSiteById(recordId);
            if (res) setSite(res);
            setIsFetchingData(false);
        };
        getData();
    }, [recordId]);

    useEffect(() => {
        if (shouldClose) {
            const hidePreviewTimeout = setTimeout(() => {
                hideCallback();
            }, hideDelayMs);
            return () => clearTimeout(hidePreviewTimeout);
        }
    }, [shouldClose, hideDelayMs, hideCallback]);

    useEffect(() => {
        if (isSourceHovered || isHovered) setShouldClose(false);
        else setShouldClose(true);
    }, [isSourceHovered, isHovered]);

    useEffect(() => {
        const windowResizeHandler = () => setScreenResizedControl(prev => !prev);
        window.addEventListener('resize', windowResizeHandler);
        return () => window.removeEventListener('resize', windowResizeHandler);
    }, []);

    useEffect(() => {
        const getActiveFields = async () => {
            setIsFetchingConfig(true);
            const res = await GetCustomFieldsByEntity_OnlyActive(9);
            if (res) setActiveFields(res);
            setIsFetchingConfig(false);
        };
        getActiveFields();
    }, []);

    const customFieldsSettingsMap = useMemo<CustomFieldSettingsMap>(() => {
        let obj: CustomFieldSettingsMap = {};
        activeFields.forEach(u => {
            const customFieldNumber = u.name.substring(13);
            const key = 'CustomField' + customFieldNumber as CustomFieldType;
            obj[key] = {
                title: u.agencyName,
                isMultiLine: u.multiLine && !u.usePredefinedValues,
            }
        });
        return obj;
    }, [activeFields]);

    useEffect(() => {
        setIsLoading(isFetchingConfig || fetchingSavedState || isFetchingData);
    }, [fetchingSavedState, isFetchingConfig, isFetchingData]);

    useEffect(() => {
        const getSavedState = async () => {
            setFetchingSavedState(true);
            const elementsJson = await GetCustomerSettingBySettingName('SiteQuickViewLayoutElements');
            if (elementsJson) {
                const panels = JSON.parse(elementsJson) as PanelModel[];
                setLastSavedState(panels);
            }
            setFetchingSavedState(false);
            setFetchedSavedState(true);
        };
        getSavedState();
    }, []);

    const elements = useMemo<SiteRecordDashboardElementDefinition[]>(() => {
        if (!fetchedSavedState) return [];
        if (lastSavedState !== null) {
            const elements = GetPanelDefinitionsFromPanelModels(lastSavedState);
            return elements ;
        }
        return DefaultSiteQuickViewLayoutElements;
    }, [lastSavedState, fetchedSavedState]);

    const renderElement = useCallback((id: string, type: SiteRecordDashboardElementType) => {
        if (site) {
            switch (type) {
                case 'Divider': return <Box pt="20px"><Divider component="div" /></Box>;
                case 'Spacer': return <></>;
                case 'SiteId': return <SingleFieldElement useEllipsisForLongValues fieldTitle="ID" fieldValue={site.id.toString()} />;
                case 'SiteName': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Name" fieldValue={site.name} />;
                case 'SiteStatus': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Status" fieldValue={site.status} />;
                case 'SitePhone':
                    const phoneLink = site.phoneStandardised ? site.phoneStandardised : site.phone;
                    return <SingleFieldElement useEllipsisForLongValues fieldTitle="Phone" fieldValue={site.phone} href={phoneLink} format="phone-link" />;
                case 'SitePrimaryContact': return (
                    <SingleFieldElement
                        fieldTitle='Primary Contact'
                        fieldValue={site.primaryContactName}
                        format="internal-link"
                        href={`/contacts/${site.primaryContact}`}
                        previewType="contact"
                        previewRecordId={site.primaryContact}
                    />);
                case 'SiteAddress1': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Address 1" fieldValue={site.address1} />;
                case 'SiteAddress2': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Address 2" fieldValue={site.address2} />;
                case 'SiteAddress3': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Address 3" fieldValue={site.address3} />;
                case 'SiteSuburb': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Suburb" fieldValue={site.suburb} />;
                case 'SiteState': return <SingleFieldElement useEllipsisForLongValues fieldTitle={site.country === 'NZ' ? "Region" : "State"} fieldValue = { site.state } />;
                case 'SitePostcode': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Postcode" fieldValue={site.postcode} />;
                case 'SiteCountry': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Country" fieldValue={site.countryName} />;
                case 'SiteSummary': return <MultiLineTextElement title="Summary" content={site.description ?? ''} />;
                default:
                    if (type.startsWith('CustomField')) {
                        const settings = customFieldsSettingsMap[type];
                        if (settings) {
                            const title = settings.title;
                            const { field, format } = UdfSiteFieldMapObj[type as CustomFieldType];
                            const udfValue = site[field];
                            if (format === 'string' || format === 'date' || format === 'datetime') {
                                return <SingleFieldElement isMultiLine={settings.isMultiLine} format={format} fieldTitle={title} fieldValue={udfValue ? udfValue.toString() : null} />;
                            }
                            if (format === 'number') {
                                return <SingleFieldElement format={format} fieldTitle={title} fieldValue={udfValue ? +udfValue : 0} />;
                            }
                        }
                        else return <SingleFieldElement fieldTitle={type} fieldValue="[ Disabled Custom Field ]" />
                    }
                    return <div>{id}</div>;
            }
        }
    }, [site, customFieldsSettingsMap]);

    const layoutResizeStopHandler = useCallback(() => {
        setScreenResizedControl(prev => !prev);
    }, []);

    const renderLayout = useCallback(() => {
        if (!fetchedSavedState) return <></>;
        return (
            <DashboardLayoutComponent
                cellSpacing={cellSpacing}
                columns={columns}
                cellAspectRatio={12 / 2}
                resizeStop={ layoutResizeStopHandler }
                allowDragging={false}
                allowResizing={false}
            >
                {elements.map(e => (
                    <PanelWrapper
                        key={e.id}
                        id={e.id}
                        col={e.col}
                        row={e.row}
                        sizeX={e.sizeX}
                        sizeY={e.sizeY}
                        minSizeX={e.minSizeX}
                        minSizeY={e.minSizeY}
                        maxSizeX={e.maxSizeX}
                        maxSizeY={e.maxSizeY}
                        resizeControl={screenResizedControl}
                        resizeIconColor={theme.palette.text.disabled}
                        gapX={gapX}
                        gapY={gapY}
                        unitWidth={unitWidth}
                        unitHeight={unitHeight}
                    >
                        {renderElement(e.id, e.type)}
                    </PanelWrapper>
                ))}
            </DashboardLayoutComponent>
        );
    }, [elements, fetchedSavedState, theme.palette.text.disabled, screenResizedControl, layoutResizeStopHandler, renderElement]);

    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
            defaultPosition={{ x: (window.innerWidth / 2) - (PREVIEW_WIDTH / 2), y: (window.innerHeight / 2) - (halfPreviewHeight / 2) }}
        >
            <Dialog
                open={true}
                maxWidth={false}
                PaperProps={{ 
                    onMouseEnter: () => setIsHovered(true),
                    onMouseLeave: () => setIsHovered(false),
                    sx: { height: `${halfPreviewHeight}px`, width: `${PREVIEW_WIDTH}px` }
                }}
                hideBackdrop
                sx={{ bottom: 'unset', right: 'unset', left: 'unset' }}
            >
                <DialogTitle component="div" display="flex" sx={ PREVIEW_TITLE_STYLE }>
                    <div style={{ alignSelf: 'center' }}>
                        { isFetchingData || !site ? PREVIEW_TEXT_SKELETON : site.name }
                    </div>
                    <div style={{ marginLeft: 'auto' }}>
                        <IconButton id="draggable-dialog-title" sx={{ cursor: 'move', color: t => t.palette.primary.contrastText }}><ControlCameraIcon /></IconButton>
                        <IconButton onClick={ () => hideCallback() } sx={{ color: t => t.palette.primary.contrastText }}><CloseIcon /></IconButton>
                    </div>
                </DialogTitle>
                <DialogContent sx={{ p: 0 }}>
                    {isLoading &&
                        <Box display="flex" justifyContent="center" alignItems="center" height="100%" >
                            <CircularProgress />
                        </Box>
                    }
                    {!isLoading &&
                        <Box p="10px" height="100%">
                            <div className="control-section">
                                { renderLayout() }
                            </div>
                        </Box>
                    }
                </DialogContent>
            </Dialog>
        </Draggable>
    );
}