import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { NameIdObj } from "common/models/GenericTypes";
import { RefariCategory } from "common/models/JobPosting/Refari";
import SlowTextFieldWrapper from "components/SlowTextFieldWrapper";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { GetRefariBoardSetupData } from "services/JobBoardsService";
import { RegexIsNumberWith2Decimals } from "util/RegExUtils";

interface Props {
    jobBoardId: number,
    advertId: number,
    attributes?: Record<string, string>,
    advertRecordAttributes?: Record<string, string>,
    attributeChangeHandler?: (jobBoardId: number, key: string, value: string) => void,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
}

export default function RefariEdit({ jobBoardId, advertId, attributes, advertRecordAttributes, attributeChangeHandler, loadingHandler, errorHandler }: Props) {
    const [categories, setCategories] = useState<RefariCategory[]>([]);
    const [categoryIdToIndex, setCategoryIdToIndex] = useState<Record<string, number>>({});
    const [locations, setLocations] = useState<NameIdObj[]>([]);
    const [workTypes, setWorkTypes] = useState<NameIdObj[]>([]);

    useEffect(() => {
        const getSetupData = async () => {
            loadingHandler && loadingHandler(true);
            const res = await GetRefariBoardSetupData(jobBoardId, advertId, errorHandler);
            if (res) {
                if (res.categories && res.categories.length > 0) {
                    setCategories(res.categories);
                    let idToIndexMap: Record<string, number> = {};
                    for (let i = 0; i < res.categories.length; i++) {
                        const cat = res.categories[i];
                        idToIndexMap[cat.id.toString()] = i;
                    }
                    setCategoryIdToIndex(idToIndexMap);
                }
                if (res.locations && res.locations.length > 0) setLocations(res.locations);
                if (res.workTypes && res.workTypes.length > 0) setWorkTypes(res.workTypes);
            }
            loadingHandler && loadingHandler(false);
        };
        getSetupData();
    }, [advertId, errorHandler, jobBoardId, loadingHandler]);

    const atts = useMemo<Record<string, string>>(() => {
        if (attributes) return attributes;
        return {};
    }, [attributes]);

    const recordAtts = useMemo<Record<string, string>>(() => {
        if (advertRecordAttributes) return advertRecordAttributes;
        return {};
    }, [advertRecordAttributes]);

    const category = useMemo(() => atts.category ?? '', [atts.category]);
    const subcategory = useMemo(() => atts.subcategory ?? '', [atts.subcategory]);
    const location = useMemo(() => atts.location ?? '', [atts.location]);
    const location2 = useMemo(() => atts.location2 ?? '', [atts.location2]);
    const location3 = useMemo(() => atts.location3 ?? '', [atts.location3]);
    const location4 = useMemo(() => atts.location4 ?? '', [atts.location4]);
    const location5 = useMemo(() => atts.location5 ?? '', [atts.location5]);
    const location6 = useMemo(() => atts.location6 ?? '', [atts.location6]);
    const location7 = useMemo(() => atts.location7 ?? '', [atts.location7]);
    const location8 = useMemo(() => atts.location8 ?? '', [atts.location8]);
    const location9 = useMemo(() => atts.location9 ?? '', [atts.location9]);
    const location10 = useMemo(() => atts.location10 ?? '', [atts.location10]);
    const referralRewardFee = useMemo(() => atts.referralRewardFee ?? '', [atts.referralRewardFee]);
    const workType = useMemo(() => atts.worktype ?? '', [atts.worktype]);

    const salaryType = useMemo(() => recordAtts.salaryType ?? '', [recordAtts.salaryType]);
    const salaryFrom = useMemo(() => recordAtts.salaryFrom ?? '', [recordAtts.salaryFrom]);
    const salaryTo = useMemo(() => recordAtts.salaryTo ?? '', [recordAtts.salaryTo]);

    useEffect(() => attributeChangeHandler && attributeChangeHandler(jobBoardId, 'salaryRatePeriod', salaryType), [attributeChangeHandler, salaryType, jobBoardId]);
    useEffect(() => attributeChangeHandler && attributeChangeHandler(jobBoardId, 'salaryRateMin', salaryFrom), [attributeChangeHandler, salaryFrom, jobBoardId]);
    useEffect(() => attributeChangeHandler && attributeChangeHandler(jobBoardId, 'salaryRateMax', salaryTo), [attributeChangeHandler, salaryTo, jobBoardId]);

    const localChangeHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        attributeChangeHandler && attributeChangeHandler(jobBoardId, name, value);
    }, [attributeChangeHandler, jobBoardId]);

    const categoryChangeHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        if (attributeChangeHandler) {
            attributeChangeHandler(jobBoardId, 'subcategory', '');
            attributeChangeHandler(jobBoardId, name, value);
        }
    }, [attributeChangeHandler, jobBoardId]);

    const rewardFeeChangeHandler = useCallback((newValue: string) => {
        attributeChangeHandler && attributeChangeHandler(jobBoardId, 'referralRewardFee', newValue);
    }, [attributeChangeHandler, jobBoardId]);

    const renderedCategoryOptions = useMemo(() => {
        return categories.map(c => <MenuItem key={c.id} value={c.id.toString()}>{c.name}</MenuItem>);
    }, [categories]);

    const renderedSubCategoryOptions = useMemo(() => {
        if (category) {
            const index = categoryIdToIndex[category];
            const opts = categories[index] ? categories[index].children : null;
            if (opts) return opts.map(o => <MenuItem key={o.id} value={o.id.toString()}>{o.name}</MenuItem>);
        }
        return [];
    }, [categories, category, categoryIdToIndex]);

    const renderedLocationOptions = useMemo(() => {
        return locations.map(l => <MenuItem key={l.id} value={l.id.toString()}>{l.name}</MenuItem>);
    }, [locations]);

    const renderedWorkTypeOptions = useMemo(() => {
        return workTypes.map(w => <MenuItem key={w.id} value={w.id.toString()}>{w.name}</MenuItem>);
    }, [workTypes]);

    return (
        <Box display="flex" gap={2} pt={2}>
            <Box display="flex" flexDirection="column" gap={2} width="50%">
                <TextField select label="Category" value={category} name="category" onChange={categoryChangeHandler}>
                    <MenuItem value=""></MenuItem>
                    {renderedCategoryOptions}
                </TextField>
                <TextField select label="Sub Category" value={subcategory} name="subcategory" onChange={localChangeHandler}>
                    <MenuItem value=""></MenuItem>
                    {renderedSubCategoryOptions}
                </TextField>
                <TextField select label="Location 1" value={location} name="location" onChange={localChangeHandler}>
                    <MenuItem value=""></MenuItem>
                    {renderedLocationOptions}
                </TextField>
                <TextField select label="Location 2" value={location2} name="location2" onChange={localChangeHandler}>
                    <MenuItem value=""></MenuItem>
                    {renderedLocationOptions}
                </TextField>
                <TextField select label="Location 3" value={location3} name="location3" onChange={localChangeHandler}>
                    <MenuItem value=""></MenuItem>
                    {renderedLocationOptions}
                </TextField>
                <TextField select label="Location 4" value={location4} name="location4" onChange={localChangeHandler}>
                    <MenuItem value=""></MenuItem>
                    {renderedLocationOptions}
                </TextField>
                <TextField select label="Location 5" value={location5} name="location5" onChange={localChangeHandler}>
                    <MenuItem value=""></MenuItem>
                    {renderedLocationOptions}
                </TextField>
                <TextField select label="Location 6" value={location6} name="location6" onChange={localChangeHandler}>
                    <MenuItem value=""></MenuItem>
                    {renderedLocationOptions}
                </TextField>
                <TextField select label="Location 7" value={location7} name="location7" onChange={localChangeHandler}>
                    <MenuItem value=""></MenuItem>
                    {renderedLocationOptions}
                </TextField>
                <TextField select label="Location 8" value={location8} name="location8" onChange={localChangeHandler}>
                    <MenuItem value=""></MenuItem>
                    {renderedLocationOptions}
                </TextField>
                <TextField select label="Location 9" value={location9} name="location9" onChange={localChangeHandler}>
                    <MenuItem value=""></MenuItem>
                    {renderedLocationOptions}
                </TextField>
                <TextField select label="Location 10" value={location10} name="location10" onChange={localChangeHandler}>
                    <MenuItem value=""></MenuItem>
                    {renderedLocationOptions}
                </TextField>
            </Box>
            <Box display="flex" flexDirection="column" gap={2} width="50%">
                <TextField select label="Work Type" value={workType} name="worktype" onChange={localChangeHandler}>
                    <MenuItem value=""></MenuItem>
                    {renderedWorkTypeOptions}
                </TextField>
                <TextField disabled label="Pay Type" value={salaryType} />
                <Box display="flex" gap={2}>
                    <TextField disabled label="Salary From" sx={{ flex: '1 1 0' }} value={salaryFrom} />
                    <TextField disabled label="Salary To" sx={{ flex: '1 1 0' }} value={salaryTo} />
                </Box>
                <SlowTextFieldWrapper label="Referral Reward Fee" value={referralRewardFee} validator={RegexIsNumberWith2Decimals} onChange={rewardFeeChangeHandler} />
            </Box>
        </Box>
    );
}