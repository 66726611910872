import React, { useCallback, useEffect, useMemo, useState } from "react";
import { GridColDef, GridRenderCellParams, useGridApiRef } from "@mui/x-data-grid-premium";
import Alert from "components/Alert";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';

import { MandatoryTagsTriggers, TagSettings, TagValue } from "common/models/Configuration/Tags";
import OnlyTitleSummaryBar from "components/SummaryBars/OnlyTitleSummaryBar";
import PageContentLayout from "layouts/PageContentLayout";
import { GetMandatoryTagsTriggers, GetTagsSettings, GetTagValues, SaveTagValues, UpdateMandatoryTagsTriggers, UpdateTagsSettings } from "services/TagsService";
import GreenIcon from "assets/icons/jobs/Candidate_Green_Small.png";
import YellowIcon from "assets/icons/jobs/Candidate_Yellow_Small.png";
import BlueIcon from "assets/icons/jobs/Candidate_Blue_Small.png";
import RedIcon from "assets/icons/jobs/Candidate_Red_Small.png";
import { ChangeTracker } from "common/models/hooks/ChangeTracker";
import useObjectStateWithChangeTracker from "hooks/UseObjectStateWithChangeTracker";
import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import GridWithStateWrapper from "components/GridWidthStateWrapper";
import { IsAlphaNumericString } from "util/RegExUtils";
import UnsavedChangesDialog from "components/Dialogs/UnsavedChangesDialog";
import RWTextFieldComponent from "components/RWTextFieldComponent";

interface Props {
    setSummaryBar?: (summaryBar: JSX.Element) => void,
    entityId: number
}

const entityNameMap = {
    3: 'Candidates',
    1: 'Clients',
    2: 'Contacts',
    4: 'Jobs',
    8: 'Opportunities',
    5: 'Placements',
    9: 'Sites',
};

const defaultTagValue: TagValue = {
    tagID: 0,
    entityID: 0,
    playerID: 0,
    recruiterID: 0,
    recruiterName: null,
    agencyID: 0,
    tagTypeID: 0,
    tagTypeName: '',
    countTotal: 0,
    tag: "",
    createdDate: "0001-01-01T00:00:00",
    lastTagged: "0001-01-01T00:00:00",
    isAgency: 0
};

const defaultTagsTriggers: MandatoryTagsTriggers = {
    green: false,
    yellow: false,
    blue: false,
    red: false
};

const defaultNoChangesTriggers: ChangeTracker<MandatoryTagsTriggers> = {
    green: false,
    yellow: false,
    blue: false,
    red: false
}

export default function TagsAdmin({ setSummaryBar, entityId }: Props) {
    const [isLoading, setIsloading] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [rows, setRows] = useState<TagSettings[]>([]);
    const [selectedRow, setSelectedRow] = useState<TagSettings | null>(null);
    const [editedRow, setEditedRow] = useState<TagSettings | null>(null);
    const [showEditRowDialog, setShowEditRowDialog] = useState(false);
    const [showUnsavedRowChangesDialog, setShowUnsavedRowChangesDialog] = useState(false);
    const [showUnsavedValueChangesDialog, setShowUnsavedValueChangesDialog] = useState(false);
    const [showEditValuesDialog, setShowEditValuesDialog] = useState(false);
    const [newValueString, setNewValueString] = useState('');
    const [tagValues, setTagValues] = useState<TagValue[]>([]);
    const [originalTagValues, setOriginalTagValues] = useState('');
    const [validTagValues, setValidTagValues] = useState<boolean[]>([]);
    const [deletedTagValues, setDeletedTagValues] = useState<number[]>([]);
    const [isCopyToAllUsers, setIsCopyToAllUsers] = useState(true);
    const { state, init, change, updateInitial, hasChanges } = useObjectStateWithChangeTracker<MandatoryTagsTriggers>(defaultTagsTriggers, defaultNoChangesTriggers);
    const gridApiRef = useGridApiRef();

    const entityName = useMemo(() => (entityNameMap as any)[entityId.toString()], [entityId]);

    useEffect(() => {
        if(entityId === 3) {
            const handleTriggersSave = async () => {
                setIsloading(true);
                const res = await UpdateMandatoryTagsTriggers(state, setErrorMessage);
                if(!res) {
                    setIsloading(false);
                    return false;
                }
                setShowSuccess(true);
                updateInitial();
                setIsloading(false);
                return true;
            };
            const action = <Button variant="contained" color="primary" disabled={!hasChanges} onClick={handleTriggersSave}>Save</Button>;
            const summarBar = <TitleAndActionSummaryBar title={`Configuration > Tags > ${entityName}`} browserTabTitle="Tags > Configuration" action={action} />;
            setSummaryBar && setSummaryBar(summarBar);
        }
        else {
            const summarBar = <OnlyTitleSummaryBar title={`Configuration > Tags > ${entityName}`} browserTabTitle="Tags > Configuration" />;
            setSummaryBar && setSummaryBar(summarBar);
        }

    }, [setSummaryBar, entityName, entityId, hasChanges, state, updateInitial]);

    const getRowDataCallback = useCallback( async () => {
        const data = await GetTagsSettings(entityId, false, setErrorMessage);

        if(entityId === 3) {
            const triggers = await GetMandatoryTagsTriggers();
            if(triggers) init(triggers);
        }
        if(data) setRows(data);
    }, [entityId, init])

    useEffect(() => {
        const getData = async () => {
            setIsloading(true);
            await getRowDataCallback();
            setIsloading(false);
        };

        getData();
    }, [getRowDataCallback]);

    const columns = useMemo<GridColDef[]>(() => {
        const nameRenderer = (params: GridRenderCellParams) => (
            <span
                style={{ cursor: 'pointer', textDecoration: 'underline' }}
                onClick={ () => {
                    setSelectedRow(params.row);
                    setEditedRow(params.row);
                    setShowEditRowDialog(true);
                }}
            >
                {params.value}
            </span>
        );

        const getValues = async (entity: number, tagTypeId: number) => {
            setIsloading(true);
            const values = await GetTagValues(entity, tagTypeId);
            if (values) {
                setTagValues(values);
                const originalTags = values.map(v => v.tag).join('|');
                setOriginalTagValues(originalTags);
                let valids: boolean[] = Array(values.length).fill(true);
                setValidTagValues(valids);
            }
            setIsloading(false);
        };

        const valuesRenderer = (params: GridRenderCellParams) => (
            <span
                style={{ cursor: 'pointer', textDecoration: 'underline' }}
                onClick={ async () => {
                    await getValues(params.row.entityId, params.row.tagTypeId);
                    setSelectedRow(params.row);
                    setShowEditValuesDialog(true);
                } }
            >
                Edit Values
            </span>
        );

        let cols: GridColDef[] = [
            { field: 'databaseName', headerName: 'Tag Type', width: 200, renderCell: nameRenderer },
            { field: 'tagAgencyName', headerName: 'Screen Name', width: 200 },
            { field: 'visible', headerName: 'Enabled', type: 'boolean' },
            { field: 'structured', headerName: 'Structured', type: 'boolean' },
            { field: 'values', headerName: 'Values', renderCell: valuesRenderer },
        ];

        if(entityId === 3) cols.push({ field: 'minTags', headerName: 'Min Required' });

        return cols;
    }, [entityId]);

    const rowStringChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        // setSelectedRow(prev => ( prev ? { ...prev, [name]: value } : null ));
        setEditedRow(prev => ( prev ? { ...prev, [name]: value } : null ));
    }

    const rowBooleanChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        // setSelectedRow(prev => ( prev ? { ...prev, [name]: value === '1' } : null ));
        setEditedRow(prev => ( prev ? { ...prev, [name]: value === '1' } : null ));
    }

    const rowNumberChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        // setSelectedRow(prev => ( prev ? { ...prev, [name]: +value } : null ));
        setEditedRow(prev => ( prev ? { ...prev, [name]: +value } : null ));
    }

    const hasRowChanges = useMemo(() => {
        if (selectedRow && editedRow) {
            if (selectedRow.tagAgencyName !== editedRow.tagAgencyName) return true;
            else if (selectedRow.visible !== editedRow.visible) return true;
            else if (selectedRow.structured !== editedRow.structured) return true;
            else if (selectedRow.minTags !== editedRow.minTags) return true;
        }
        return false;
    }, [editedRow, selectedRow]);

    const handleCloseEditRowDialog = useCallback(() => {
        setShowEditRowDialog(false);
        setSelectedRow(null);
        setEditedRow(null);
        setShowUnsavedRowChangesDialog(false);
    }, []);

    const hasValueChanges = useMemo(() => {
        const newValuesString = tagValues.map(v => v.tag).join('|');
        return newValuesString !== originalTagValues;
    }, [tagValues, originalTagValues]);

    const handleCloseValuesDialog = () => {
        setShowEditValuesDialog(false);
        setTagValues([]);
        setDeletedTagValues([]);
        setNewValueString('');
        setOriginalTagValues('');
        setSelectedRow(null);
        setShowUnsavedValueChangesDialog(false);
    };

    const handleSaveRow = useCallback(async () => {
        if (editedRow) {
            setIsloading(true);
            const res = await UpdateTagsSettings(entityId, isCopyToAllUsers, [editedRow], setErrorMessage);

            if(!res) {
                setIsloading(false);
                return false;
            }

            if (editedRow.id === 0) {
                await getRowDataCallback();
            }
            else {
                gridApiRef.current.updateRows([editedRow]);
            }

            handleCloseEditRowDialog();
            setShowSuccess(true);
            setIsloading(false);
        }
        return true;
    }, [entityId, isCopyToAllUsers, editedRow, gridApiRef, getRowDataCallback, handleCloseEditRowDialog]);

    const isNewValueValid = useMemo(() => {
        if (newValueString !== '') {
            return IsAlphaNumericString(newValueString);
        }
        return true;
    }, [newValueString]);

    const isNewValueRepeated = useMemo(() => {
        const v = tagValues.find(tv => tv.tag === newValueString);
        if (v) return true;
        return false;
    }, [newValueString, tagValues]);

    const newValueErrorMessage = useMemo(() => {
        if (!isNewValueValid) return 'No special characters allowed';
        else if (isNewValueRepeated) return 'Value already exists';
        return '';
    }, [isNewValueRepeated, isNewValueValid]);

    const isAllTagValuesValid = useMemo(() => {
        for (let i = 0; i < validTagValues.length; i++) {
            const v = validTagValues[i];
            if (!v) return false;
        }
        return true;
    }, [validTagValues]);

    const handleSaveTagValues = async () => {
        setIsloading(true);
        if (selectedRow) {
            const res = await SaveTagValues(entityId, selectedRow.tagTypeId, tagValues, deletedTagValues, setErrorMessage);
            if(res) {
                handleCloseValuesDialog();
                setShowSuccess(true);
            }
        }
        setIsloading(false);
    };

    const tagValueNameChangeHandler = useCallback((index: number, value: string) => {
        setTagValues(prev => {
            let newValues = [ ...prev]
            newValues[index].tag = value;
            return newValues;
        });
        setValidTagValues(prev => {
            let p = [...prev];
            p[index] = IsAlphaNumericString(value);
            return p;
        });
    }, []);

    const tagValuesAddHandler = () => {
        if (isNewValueValid && newValueString !== '') {
            setTagValues(prev => {
                let newValues: TagValue[] = [ ...prev, {...defaultTagValue, tag: newValueString  }]
                setNewValueString('');
                return newValues;
            });
            setValidTagValues(prev => [...prev, true]);
        }
    }

    const tagValuesDeleteHandler = (index: number) => {
        setTagValues(prev => {
            const deletedId = prev[index].tagID;
            if (deletedId !== 0) setDeletedTagValues(prev => [...prev, deletedId]);

            let newValues = [...prev];
            newValues.splice(index, 1);
            return newValues;
        });
    }

    const handleTriggersChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = e.target;
        change(name as keyof MandatoryTagsTriggers, checked);
    }

    return (
        <>
            <Snackbar open={showSuccess} autoHideDuration={3000} onClose={() => setShowSuccess(false)}>
                <Alert onClose={() => setShowSuccess(false)}>Changes Saved</Alert>
            </Snackbar>
            <Snackbar open={errorMessage !== ''}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <UnsavedChangesDialog
                open={showUnsavedRowChangesDialog}
                message="If you continue, all unsaved changes will be lost. Are you sure you wish to continue?"
                cancelHandler={() => setShowUnsavedRowChangesDialog(false)}
                discardChangesHandler={handleCloseEditRowDialog}
                saveChangesHandler={handleSaveRow}
            />
            <UnsavedChangesDialog
                open={showUnsavedValueChangesDialog}
                message="If you continue, all unsaved changes will be lost. Are you sure you wish to continue?"
                cancelHandler={() => setShowUnsavedValueChangesDialog(false)}
                discardChangesHandler={handleCloseValuesDialog}
                saveChangesHandler={handleSaveTagValues}
            />
            {showEditRowDialog && editedRow &&
                <Dialog open={true} onClose={ hasRowChanges ? () => setShowUnsavedRowChangesDialog(true) : handleCloseEditRowDialog } fullWidth>
                    <DialogTitle>
                        Edit Tag {editedRow.databaseName}
                    </DialogTitle>
                    <DialogContent dividers>
                        <Stack spacing={2}>
                            <RWTextFieldComponent
                                label="Screen Name"
                                value={editedRow.tagAgencyName}
                                name="tagAgencyName"
                                onChange={rowStringChangeHandler}
                            />

                            <TextField
                                select
                                label="Enabled"
                                value={ editedRow.visible ? "1": "0" }
                                name="visible"
                                onChange={ rowBooleanChangeHandler }
                            >
                                <MenuItem value="1">Yes</MenuItem>
                                <MenuItem value="0">No</MenuItem>
                            </TextField>

                            <TextField
                                select
                                label="Structured"
                                value={ editedRow.structured ? "1": "0" }
                                name="structured"
                                onChange={ rowBooleanChangeHandler }
                            >
                                <MenuItem value="1">Yes</MenuItem>
                                <MenuItem value="0">No</MenuItem>
                            </TextField>

                            {entityId === 3 &&
                                <RWTextFieldComponent
                                    type="number"
                                    label="Min Required"
                                    value={editedRow.minTags?.toString()}
                                    name="minTags"
                                    onChange={ rowNumberChangeHandler }
                                />
                            }
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <FormControlLabel
                            sx={{ m: 0, mr: 'auto' }}
                            label="Copy to All Users"
                            labelPlacement="end"
                            control={
                                <Checkbox
                                    checked={ isCopyToAllUsers }
                                    onChange={ () => setIsCopyToAllUsers(prev => !prev) }
                                    sx={{ p: '4px' }}
                                />}
                        />
                        <Button variant="contained" color="error" onClick={ hasRowChanges ? () => setShowUnsavedRowChangesDialog(true) : handleCloseEditRowDialog }>Cancel</Button>
                        <Button variant="contained" color="success" onClick={ handleSaveRow }>Save</Button>
                    </DialogActions>
                </Dialog>
            }
            {showEditValuesDialog && selectedRow &&
                <Dialog open={true} onClose={ hasValueChanges ? () => setShowUnsavedValueChangesDialog(true) : handleCloseValuesDialog } fullWidth>
                    <DialogTitle>Structured Tag Values [{selectedRow.databaseName}]</DialogTitle>
                    <DialogContent dividers>
                        <div>
                            <span style={{ width: '85%', display: 'inline-block', textAlign: 'center' }}>Value</span>
                            <span style={{ width: '15%', display: 'inline-block', textAlign: 'center' }}>Action</span>
                        </div>
                        <div style={{ paddingBottom: '20px' }}>
                            <TextField
                                variant="standard"
                                placeholder="New Value"
                                sx={{ width: '85%' }}
                                size="small"
                                value={newValueString}
                                onChange={ ({target}) => setNewValueString(target.value) }
                                error={!isNewValueValid || isNewValueRepeated}
                                helperText={newValueErrorMessage}
                            />
                            <span style={{ width: '15%', display: 'inline-block', textAlign: 'center' }}>
                                <IconButton disabled={newValueString === '' || !isNewValueValid || isNewValueRepeated} onClick={tagValuesAddHandler}><AddCircleIcon /></IconButton>
                            </span>
                        </div>
                        <Divider />
                        {tagValues.map((v, i) => (
                            <div key={i} style={{ paddingBottom: '10px' }}>
                                <RWTextFieldComponent
                                    variant="standard"
                                    sxOptions={{ width: '85%' }}
                                    value={v.tag}
                                    onChange={ ({target}) => tagValueNameChangeHandler(i, target.value) }
                                    isError={!validTagValues[i]}
                                    helperText={validTagValues[i] ? '' : 'No special characters allowed'}
                                />
                                <span style={{ width: '15%', display: 'inline-block', textAlign: 'center' }}>
                                    <IconButton onClick={ () => tagValuesDeleteHandler(i) }><DeleteIcon /></IconButton>
                                </span>
                            </div>
                        ))}
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="error" onClick={ hasValueChanges ? () => setShowUnsavedValueChangesDialog(true) : handleCloseValuesDialog }>Cancel</Button>
                        <Button variant="contained" color="success" onClick={ handleSaveTagValues } disabled={!isAllTagValuesValid || !hasValueChanges}>Save</Button>
                    </DialogActions>
                </Dialog>
            }
            <PageContentLayout title={`Tags - ${entityName}`} showLoading={isLoading}>
                {entityId === 3 &&
                    <Stack spacing={2} direction="row" marginLeft="auto" marginTop="-32px">
                        <Typography alignSelf="center" pr="10px">Mandatory Tags Triggers: </Typography>
                        <div>
                            <img src={GreenIcon} alt="Green" />
                            <Checkbox name="green" checked={state.green} onChange={handleTriggersChange} />
                        </div>
                        <div>
                            <img src={YellowIcon} alt="Yellow" />
                            <Checkbox name="yellow" checked={state.yellow} onChange={handleTriggersChange} />
                        </div>
                        <div>
                            <img src={BlueIcon} alt="Blue" />
                            <Checkbox name="blue" checked={state.blue} onChange={handleTriggersChange} />
                        </div>
                        <div>
                            <img src={RedIcon} alt="Red" />
                            <Checkbox name="red" checked={state.red} onChange={handleTriggersChange} />
                        </div>
                    </Stack>
                }
                <GridWithStateWrapper
                    gridName="configuration/tags"
                    getRowId={ row => row.tagTypeId }
                    rows={rows}
                    columns={columns}
                    disableRowSelectionOnClick
                    apiRef={gridApiRef}
                    hideFooter
                />
            </PageContentLayout>
        </>
    );
}