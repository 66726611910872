import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Meeting } from "common/models/Meetings";
import { useTheme } from "@mui/material/styles";
import { MeetingRecordDashboardElementDefinition, MeetingRecordDashboardElementType } from "common/models/Dashboard/EditLayout";
import { DefaultMeetingRecordDashboardElements, MeetingScreenLayoutSettings } from "util/Definitions/ScreenLayouts/Meeting";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import SingleFieldElement from "../SingleFieldElement";
import { DashboardLayoutComponent } from "@syncfusion/ej2-react-layouts/src/dashboard-layout/dashboardlayout.component";
import PanelWrapper from "components/Dashboards/PanelWrapper";
import MultiLineTextElement from "../MultiLineTextElement";
import moment from "moment";
import { GetMyUser } from "services/UsersService";

interface Props {
    meeting: Meeting | null,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
}

const { unitWidth, unitHeight, gapX, gapY, columns, mediaQueryMaxWidth } = MeetingScreenLayoutSettings;
const cellSpacing = [gapX, gapY];

const getDateString = (date: string | null, timezone: string | null) => {
    let dateString = '';

    const mdt = moment(date);
    if (mdt.isValid()) dateString = mdt.format('DD MMM YYYY h:mm A');


    return dateString + " " + timezone;
};

const getMeetingDurationString = (duration: number) => {
    switch (duration)
    {
        case 15: return "15 mins";
        case 30: return "30 mins";
        case 60: return "60 mins";
        case 90: return "90 mins";
        case 120: return "2 hours";
        case 240: return "4 hours";
        case 480: return "1 Day";
    }
    return '';
};

const getMeetingDistributionString = (distribution: number) => {
    switch (distribution)
    {
        case 0: return "None - Record in Recruit Wizard Only";
        case 1: return "Calendar Invite";
        case 2: return "Calendar Invite + Email"
    }
    return '';
};

const getMeetingLocationTypeName = (locationTypeId: number) => {
    switch (locationTypeId) {
        case 1: return "In Person";
        case 2: return "Phone Call";
        case 3: return "Microsoft Teams";
        case 4: return "Zoom";
        case 5: return "Other";
    }
    return '';
};

export default function ViewRecordScreenLayout({ meeting, loadingHandler, errorHandler }: Props) {
    const [screenResizedControl, setScreenResizedControl] = useState(false);
    const [fetchingSavedState, setFetchingSavedState] = useState(false);
    const [fetchedSavedState, setFetchedSavedState] = useState(false);
    const [myTimezone, setMyTimezone] = useState('');
    const layoutRef = useRef<DashboardLayoutComponent | null>(null);
    const theme = useTheme();

    useEffect(() => {
        const windowResizeHandler = () => setScreenResizedControl(prev => !prev);
        window.addEventListener('resize', windowResizeHandler);
        return () => window.removeEventListener('resize', windowResizeHandler);
    }, []);

    useEffect(() => {
        loadingHandler && loadingHandler(fetchingSavedState);
    }, [loadingHandler, fetchingSavedState]);

    useEffect(() => {
        const getSavedState = async () => {
            setFetchingSavedState(true);
            setFetchingSavedState(false);
            setFetchedSavedState(true);
        };
        getSavedState();
    }, []);

    useEffect(() => {
        const getData = async () => {
            const res = await GetMyUser();
            if (res) setMyTimezone(res.timeZoneName);
        };
        getData();
    }, []);

    const elements = useMemo<MeetingRecordDashboardElementDefinition[]>(() => {
        return DefaultMeetingRecordDashboardElements;
    }, []);

    const renderElement = useCallback((id: string, type: MeetingRecordDashboardElementType) => {
        if (meeting) {
            switch (type) {
                case 'Divider': return <Box pt="20px"><Divider component="div" /></Box>;
                case 'Spacer': return <></>;
                case 'MeetingDistributionMode': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Distribution Mode" fieldValue={getMeetingDistributionString(meeting.distributionMode)} />;
                case 'MeetingOrganizer': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Organizer" fieldValue={meeting.organizerName} />;
                case 'MeetingType': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Type" fieldValue={meeting.typeName} />;
                case 'MeetingDate': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Date (Local TZ)" fieldValue={getDateString(meeting.dateInLocalTimeZone, meeting.timeZoneName)} />;
                case 'MeetingDateUser': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Date (Your TZ)" fieldValue={getDateString(meeting.date, myTimezone)} />;
                case 'MeetingDuration': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Duration" fieldValue={getMeetingDurationString(meeting.duration)} />;
                case 'MeetingLocationType':
                    return <SingleFieldElement useEllipsisForLongValues fieldTitle="Location Type" fieldValue={getMeetingLocationTypeName(meeting.locationTypeID)} />;
                case 'MeetingLocation':
                    return <SingleFieldElement useEllipsisForLongValues fieldTitle="Location" fieldValue={meeting.location} />;
                case 'MeetingNotes': return <MultiLineTextElement title="Notes" content={meeting.meetingNotes ?? ''} />;
                default: return <div>{id}</div>;
            }
        }
    }, [meeting, myTimezone]);
    
    const layoutResizeStopHandler = useCallback(() => {
        setScreenResizedControl(prev => !prev);
    }, []);

    useEffect(() => {
        if (screenResizedControl) {}
        const api = layoutRef.current;
        if (api && fetchedSavedState) {
            const refreshTimeout = setTimeout(() => {
                api.refresh();
                api.refresh();
            }, 250);
            return () => clearTimeout(refreshTimeout);
        }
    }, [fetchedSavedState, screenResizedControl]);

    const renderLayout = useCallback(() => {
        if (!fetchedSavedState) return <></>;

        const mediaQuery = mediaQueryMaxWidth ? `max-width: ${mediaQueryMaxWidth}` : undefined;
        return (
            <DashboardLayoutComponent
                cellSpacing={cellSpacing}
                columns={columns}
                cellAspectRatio={30 / 2}
                resizeStop={ layoutResizeStopHandler }
                allowDragging={false}
                allowResizing={false}
                ref={l => layoutRef.current = l}
                mediaQuery={mediaQuery}
            >
                {elements.map(e => (
                    <PanelWrapper
                        key={e.id}
                        id={e.id}
                        col={e.col}
                        row={e.row}
                        sizeX={e.sizeX}
                        sizeY={e.sizeY}
                        minSizeX={e.minSizeX}
                        minSizeY={e.minSizeY}
                        maxSizeX={e.maxSizeX}
                        maxSizeY={e.maxSizeY}
                        resizeControl={screenResizedControl}
                        resizeIconColor={theme.palette.text.disabled}
                        gapX={gapX}
                        gapY={gapY}
                        unitWidth={unitWidth}
                        unitHeight={unitHeight}
                    >
                        {renderElement(e.id, e.type)}
                    </PanelWrapper>
                ))}
            </DashboardLayoutComponent>
        );
    }, [elements, fetchedSavedState, theme.palette.text.disabled, screenResizedControl, layoutResizeStopHandler, renderElement]);
    
    return (
        <>
            <Box p="10px" height="100%">
                <div className="control-section">
                    { renderLayout() }
                </div>
            </Box>
        </>
    );
}