import { ErrorHandler, RequestOptions } from "common/models/GenericTypes";
import { TemplatePlaceholder } from "common/models/Templates/TemplatePlaceholder";
import { Get } from "util/HttpUtils";

export async function GetPlaceholdersByEntity(entityId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<TemplatePlaceholder[]>(`api/placeholders/${entityId}/available`, options);
}

export async function GetPlaceholdersByEntityList(entityIds: number[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    const queryParam = 'entityIds=' + entityIds.join('&entityIds=');
    return Get<TemplatePlaceholder[]>(`api/placeholders/available?${queryParam}`, options);
}