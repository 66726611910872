import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import React, { useEffect, useMemo, useState } from "react";
import { GetActiveItems } from "services/InvoiceService";
import { Item } from "common/models/Invoices/Item";
import { SxProps, Theme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

interface Props {
    itemId: number | null,
    onSelect: (userId: Item | null) => void,
    variant?: "standard" | "filled" | "outlined" | undefined,
    label?: string,
    sx?: SxProps<Theme>,
    disableClearable?: boolean
    errorMessage?: string,
    blurOnSelect?: boolean
    hideLabel?: boolean
}

export default function InvoiceItemPicker({ itemId, label = "Item", variant, onSelect, disableClearable, errorMessage = "", blurOnSelect, hideLabel = false, sx }: Props) {
    const [options, setOptions] = useState<Item[]>([]);

    useEffect(() => {
        const getData = async () => {
            const items = await GetActiveItems();
            if (items) {
                setOptions(items);
            }        
        };
        getData();
    }, []);

    const user = useMemo(() => {
        if (itemId === null) return null;
        const user = options.find(u => u.id === itemId);
        if (user) return user;
        return null;
    }, [itemId, options]);

    return (
        <Autocomplete
            size="small"
            value={user}
            options={options}
            getOptionLabel={o => o.code}
            isOptionEqualToValue={ (o,v) => o.id === v.id }
            onChange={ (e,v) => onSelect(v) }
            disableClearable={disableClearable}
            blurOnSelect={blurOnSelect}
            sx={sx}
            renderInput={params => <TextField {...params} error={errorMessage !== ''} helperText={errorMessage} variant={variant} label={hideLabel ? undefined : label} inputProps={{ ...params.inputProps, "data-lpignore": "true" }} />}
            renderOption={(props, option) => (
                <ListItem {...props} key={option.id}>
                    <ListItemText
                        primary={option.code}
                        secondary={(
                            <>
                                {option.description && <Box>{option.description}</Box>}
                            </>
                        )}
                    />
                </ListItem>
            )}
        />
    );
}