import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import React from 'react';
import PageContentLayout from '../../layouts/PageContentLayout';

export default function NotFound() {
    return (
        <Container sx={{ paddingTop: 2 }}>
            <PageContentLayout title='Not Found'>
                <Typography variant='body1'>
                    The resource you are trying to access does not exist
                </Typography>
            </PageContentLayout>
        </Container>
    );
}