import { Activity } from "common/models/Activities";

export const NonEditableActivityTypes = [2, 5, 7, 8, 11, 12, 16, 17, 19, 20, 21, 22, 23, 24, 25, 27, 28, 29, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71,73, 72, 74, 75, 76, 77, 78];
export const NonAdminEditableActivityTypes = [2, 5, 7, 8, 11, 12, 14, 15, 16, 17, 19, 20, 21, 22, 23, 24, 25, 27, 28, 29, 34, 38, 39, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72,73, 74, 75, 76, 77, 78];

export const DefaultActivity: Activity = {
    id: 0,
    parentID: 0,
    typeID: 0,
    type: "",
    category: 0,
    createdByID: 0,
    createdByName: null,
    lastUpdatedByID: 0,
    updatedByName: null,
    assignedToID: 0,
    assignedToName: '',
    notes: '',
    activityDate: '',
    activityEndDate: '',
    dateCreated: '',
    lastUpdatedDate: '',
    clientName: null,
    contactName: null,
    candidateName: null,
    contactID: 0,
    clientID: 0,
    candidateID: 0,
    jobReference: '',
    jobID: 0,
    jobTitle: '',
    statusID: 2,
    statusName: '',
    opportunityID: 0,
    opportunityName: null,
    entityId: 0,
    placementID: 0,
    duration: 0,
    associatedData: null,
    documentId: 0
}