import Alert from "components/Alert";
import Snackbar from "@mui/material/Snackbar";
import React, { useEffect, useState } from "react";
import SmsPurchasesGridComponent from "components/Grids/SmsPurchasesGrid";
import OnlyTitleSummaryBar from "components/SummaryBars/OnlyTitleSummaryBar";
import PageContentLayout from "layouts/PageContentLayout";

interface Props {
    setSummaryBar?: (summaryBar: JSX.Element) => void
}

export default function SmsPurchases({ setSummaryBar }: Props) {
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    useEffect(() => {
        const summaryBar = <OnlyTitleSummaryBar title="Configuration > SMS > Purchases" browserTabTitle="SMS > Configuration" />;
        setSummaryBar && setSummaryBar(summaryBar);
    }, [setSummaryBar]);

    return (
        <>
            <Snackbar open={successMessage !== ''} autoHideDuration={3000} onClose={() => setSuccessMessage('')}>
                <Alert onClose={() => setSuccessMessage('')}>{ successMessage }</Alert>
            </Snackbar>
            <Snackbar open={errorMessage !== ''} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <PageContentLayout title="Recruit Wizard SMS Purchases" showLoading={isLoading}>
                <SmsPurchasesGridComponent
                    gridName="configuration/sms/purchases"
                    errorHandler={setErrorMessage}
                    loadingHandler={setIsLoading}
                    successHandler={setSuccessMessage}
                />
            </PageContentLayout>
        </>
    );
}