import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import React, { useEffect, useMemo, useState } from "react";
import useActiveUsers from "hooks/UseActiveUsers";
import useAllUsers from "hooks/UseAllUsers";
import { NameIdObj } from "common/models/GenericTypes";
import { SxProps, Theme } from "@mui/material/styles";

interface Props {
    userId: number | null,
    onSelect: (userId: NameIdObj | null) => void,
    onInactiveSelected?: (isInactive: boolean) => void,
    variant?: "standard" | "filled" | "outlined" | undefined,
    ignoreIds?: number[],
    label?: string,
    sx?: SxProps<Theme>,
    appendToStart?: NameIdObj[],
    disableClearable?: boolean
    error?: boolean,
    errorMessage?: string,
    blurOnSelect?: boolean
    hideLabel?: boolean,
    includeInactiveUsers?: boolean
    disabled?: boolean
}

export default function UserPicker({ userId, label = "User", variant, appendToStart, ignoreIds = [], onSelect, onInactiveSelected, disableClearable, error, errorMessage = "", blurOnSelect, hideLabel = false, sx, includeInactiveUsers, disabled = false }: Props) {
    const [options, setOptions] = useState<NameIdObj[]>([]);
    const [needsInactiveAppend, setNeedsInactiveAppend] = useState(false);
    const activeUsers = useActiveUsers();
    const allUsers = useAllUsers();

    useEffect(() => {
        var users = activeUsers;

        if (includeInactiveUsers)
            users = allUsers;

        if (appendToStart && appendToStart.length > 0) setOptions([...appendToStart, ...users]);
        else setOptions(users);
    }, [appendToStart, activeUsers, allUsers, includeInactiveUsers]);

    const user = useMemo(() => {
        if (userId === null) {
            onInactiveSelected && onInactiveSelected(false);
            return null;
        }
        const user = options.find(u => u.id === userId);
        if (user) {
            onInactiveSelected && onInactiveSelected(false);
            setNeedsInactiveAppend(false);
            return user;
        }
        if (!includeInactiveUsers) {
            const inactive = allUsers.find(u => u.id === userId);
            if (inactive) {
                onInactiveSelected && onInactiveSelected(true);
                setNeedsInactiveAppend(true);
                return inactive;
            }
        }
        onInactiveSelected && onInactiveSelected(false);
        return null;
    }, [userId, options, includeInactiveUsers, allUsers, onInactiveSelected]);

    const definitiveOptions = useMemo(() => {
        let tmp = [...options];
        if (user && needsInactiveAppend) {
            tmp = [...tmp, user];
        }
        if (tmp.length > 0) {
            if (ignoreIds.length > 0) {
                for (let i = 0; i < ignoreIds.length; i++) {
                    const id = ignoreIds[i];
                    const index = tmp.findIndex(u => u.id === id);
                    if (index !== -1) tmp.splice(index, 1);
                }
            }
        }
        return tmp;
    }, [ignoreIds, needsInactiveAppend, options, user]);

    return (
        <Autocomplete
            size="small"
            value={user}
            options={definitiveOptions}
            getOptionLabel={o => o.name}
            isOptionEqualToValue={ (o,v) => o.id === v.id }
            onChange={ (e,v) => onSelect(v) }
            disableClearable={disableClearable}
            blurOnSelect={blurOnSelect}
            disabled={disabled}
            sx={sx}
            renderInput={ params => <TextField {...params} error={errorMessage !== '' || error} helperText={errorMessage} variant={variant} label={hideLabel ? undefined : label} inputProps={{ ...params.inputProps, "data-lpignore": "true" }} /> }
        />
    );
}