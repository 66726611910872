import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import MenuList from "@mui/material/MenuList";
import Paper from "@mui/material/Paper";
import Popper, { PopperPlacementType } from "@mui/material/Popper";
import React, { useCallback, useEffect, useState } from "react";

interface Props {
    label?: string,
    marginRight?: string,
    color?: "inherit" | "success" | "primary" | "secondary" | "error" | "info" | "warning" | undefined,
    placement?: PopperPlacementType,
    ml?: string | number
}

export default function ActionsDropDownButton({ children, label = "Actions", marginRight, color = "primary", placement = "bottom-end", ml }: React.PropsWithChildren<Props>) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [isRootHovered, setIsRootHovered] = useState(false);
    const [isMenuHovered, setIsMenuHovered] = useState(false);
    const handleCloseMenu = useCallback(() => setAnchorEl(null), []);

    const handleOpenMenu = useCallback((e: React.MouseEvent<HTMLElement>) => {
        setIsRootHovered(true);
        setAnchorEl(e.currentTarget);
    }, []);

    useEffect(() => {
        if (Boolean(anchorEl) && !isRootHovered && !isMenuHovered) setAnchorEl(null);
    }, [anchorEl, isRootHovered, isMenuHovered]);

    return (
        <>
            <Button
                variant="contained"
                color={color}
                endIcon={ <ArrowDropDown /> }
                onClick={ handleOpenMenu }
                onMouseEnter={ handleOpenMenu }
                onMouseLeave={ () => setIsRootHovered(false) }
                sx={{ mr: marginRight, ml: ml }}
            >{label}</Button>
            <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} placement={placement} disablePortal sx={{ zIndex: t => t.zIndex.appBar - 2 }}>
                <Paper elevation={6} onMouseEnter={ () => setIsMenuHovered(true) } onMouseLeave={ () => setIsMenuHovered(false) }>
                    <ClickAwayListener onClickAway={handleCloseMenu}>
                        <MenuList onMouseLeave={handleCloseMenu}>
                            {children}
                        </MenuList>
                    </ClickAwayListener>
                </Paper>
            </Popper>
        </>
    );
}