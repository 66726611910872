import { useEffect, useState } from "react";
import { GetAllUsers } from "../services/UsersService";
import { NameIdObj } from "../common/models/GenericTypes";

const allUsersLocalSettingName = 'AllUsers';

export default function useAllUsers() {
    const [users, setUsers] = useState<NameIdObj[]>([]);
    useEffect(() => {
        const getData = async () => {
            const cachedUsers = localStorage.getItem(allUsersLocalSettingName);
            if (cachedUsers) setUsers(JSON.parse(cachedUsers));
            else {
                const res = await GetAllUsers();
                if (res) {
                    const data: NameIdObj[] = res.map(u => ({ id: u.id, name: u.displayName }));
                    localStorage.setItem(allUsersLocalSettingName, JSON.stringify(data));
                    setUsers(data);
                }
            }
        };

        getData();
    }, []);

    return users;
}