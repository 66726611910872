import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import ControlCameraIcon from '@mui/icons-material/ControlCamera';
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Draggable from "react-draggable";
import { Job } from "common/models/Jobs/Job";
import { PreviewProps } from "common/models/Previews/Previews";
import { GetJobById } from "services/JobsService";
import { PREVIEW_HEIGHT, PREVIEW_TEXT_SKELETON, PREVIEW_TITLE_STYLE, PREVIEW_WIDTH } from "util/Definitions/Constants/Previews";
import Divider from "@mui/material/Divider";
import useTheme from "@mui/material/styles/useTheme";
import { CustomField } from "common/models/Configuration/CustomFields";
import { DashboardLayoutComponent, PanelModel } from "@syncfusion/ej2-react-layouts";
import { DefaultJobQuickViewLayoutElements_Contract, DefaultJobQuickViewLayoutElements_FixedContract, DefaultJobQuickViewLayoutElements_Panel, DefaultJobQuickViewLayoutElements_Permanent, DefaultJobQuickViewLayoutElements_TalentPool, GetPanelDefinitionsFromPanelModels, JobQuickViewLayoutSettings } from "util/Definitions/QuickViewLayout/Job";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import PanelWrapper from "components/Dashboards/PanelWrapper";
import { JobRecordDashboardElementDefinition, JobRecordDashboardElementType } from "common/models/Dashboard/EditLayout";
import SingleFieldElement from "components/ScreenLayouts/SingleFieldElement";
import Chip from "@mui/material/Chip";
import ViewClientLocationElement from "components/ScreenLayouts/Clients/ViewClientLocationElement";
import MultiLineTextElement from "components/ScreenLayouts/MultiLineTextElement";
import { UdfJobFieldMapObj } from "util/Definitions/ScreenLayouts/CustomFields";
import { CustomFieldSettingsMap, CustomFieldType } from "common/models/ScreenLayouts/CustomFields";
import { GetCustomerSettingBySettingName } from "services/ConfigurationService";
import { GetDivisions } from "services/DivisionsService";
import { GetCustomFieldsByEntity_OnlyActive } from "services/CustomFieldsService";
import { Division } from "common/models/Configuration/Division";

interface Props2 {
    activeFields: CustomField[],
    divisions: Division[],
    job?: Job,
    setFetchingSavedState: (v: boolean) => void
}

const width = PREVIEW_WIDTH + 200;
const { unitWidth, unitHeight, gapX, gapY, columns } = JobQuickViewLayoutSettings;
const cellSpacing = [gapX, gapY];

const formatNumber = (value: number) => {
    return value.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 });
};

const getSalaryUnitsName = (unitsId: number) => {
    switch (unitsId)
    {
        case 1: return "Hourly";
        case 2: return "Daily";
        case 3: return "Weekly";
        case 4: return "Monthly";
        case 5: return "Yearly";
        default: return "";
    }
};

const getJobRatingName = (rating: number) => {
    switch (rating)
    {
        case 1: return "A";
        case 2: return "B";
        case 3: return "C";
    }
    return "";
};

const getJobOutcomeName = (outcomeId: number) => {
    switch (outcomeId)
    {
        case 1: return "Filled by Us";
        case 2: return "Filled by Client";
        case 3: return "Filled by Competitor";
        case 4: return "Withdrawn";
        case 5: return "Administrative";
        case 6: return "Unknown";
        case 7: return "Not Supplied";
        case 8: return "Client did not win work";
        case 9: return "Budget not approved";
        case 10: return "Not worked Properly";
        case 11: return "Over Capacity";
        case 12: return "Closed Due To Inactivity";
    }
    return "";
};

const getDurationPeriodName = (period: number) => {
    switch (period)
    {
        case 1: return "Hour(s)";
        case 2: return "Day(s)";
        case 3: return "Week(s)";
        case 4: return "Month(s)";
        case 5: return "Year(s)";
    }
    return "";
}

const getFeeString = (jobType: string, feeType: number, feeAmount: number, feePercent: number) => {
    if (feeType === 1) return `${formatNumber(feePercent)} Percent (${formatNumber(feeAmount)})`;
    if (feeType === 2 && jobType === 'Panel') return `${formatNumber(feeAmount)} Fixed`;
    if (feeType === 2) return `${formatNumber(feeAmount)} Fixed (${formatNumber(feePercent)}%)`;
    return '';
};

const getRateString = (chargeRateFrom: number, chargeRateTo: number, chargeRateUnits: number) => {
    return `${formatNumber(chargeRateFrom)} to ${formatNumber(chargeRateTo)} ${getSalaryUnitsName(chargeRateUnits)}`;
};

export default function JobQuickViewComponent({ recordId, isSourceHovered, hideCallback, hideDelayMs = 1250 }: PreviewProps) {
    const [shouldClose, setShouldClose] = useState(false);
    const [isFetchingData, setIsFetchingData] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [job, setJob] = useState<Job>();

    const [isLoading, setIsLoading] = useState(false);
    const [fetchingSavedState, setFetchingSavedState] = useState(false);
    const [jobTypeSwitch, setJobTypeSwitch] = useState(false);
    const [jobType, setJobType] = useState('');
    const [activeFields, setActiveFields] = useState<CustomField[]>([]);
    // const [owners, setOwners] = useState<ContactOwner[]>([]);
    const [divisions, setDivisions] = useState<Division[]>([]);

    useEffect(() => {
        const getData = async () => {
            setIsFetchingData(true);
            const res = await GetJobById(recordId);
            if (res) {
                setJob(res);
                setJobType(res.type)
            }
            setIsFetchingData(false);
        };
        getData();
    }, [recordId]);

    useEffect(() => {
        if (shouldClose) {
            const hidePreviewTimeout = setTimeout(() => {
                hideCallback();
            }, hideDelayMs);
            return () => clearTimeout(hidePreviewTimeout);
        }
    }, [shouldClose, hideDelayMs, hideCallback]);

    useEffect(() => {
        if (isSourceHovered || isHovered) setShouldClose(false);
        else setShouldClose(true);
    }, [isSourceHovered, isHovered]);

    useEffect(() => {
        if (jobType) {}
        setJobTypeSwitch(prev => !prev);
    }, [jobType]);

    useEffect(() => {
        const getActiveFields = async () => {
            const res = await GetCustomFieldsByEntity_OnlyActive(4);
            if (res) setActiveFields(res);
        };
        getActiveFields();
    }, []);

    useEffect(() => {
        const getDivs = async () => {
            const res = await GetDivisions();
            if (res) setDivisions(res);
        };
        getDivs();
    }, []);

    useEffect(() => {
        setIsLoading(fetchingSavedState || isFetchingData);
    }, [fetchingSavedState, isFetchingData]);

    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
            defaultPosition={{ x: (window.innerWidth / 2) - (width / 2), y: (window.innerHeight / 2) - (PREVIEW_HEIGHT / 2) }}
        >
            <Dialog
                open={true}
                maxWidth={false}
                PaperProps={{
                    onMouseEnter: () => setIsHovered(true),
                    onMouseLeave: () => setIsHovered(false),
                    sx: { height: `${PREVIEW_HEIGHT}px`, width: `${width}px` }
                }}
                hideBackdrop
                sx={{ bottom: 'unset', right: 'unset', left: 'unset' }}
            >
                <DialogTitle component="div" display="flex" sx={ PREVIEW_TITLE_STYLE }>
                    <div style={{ alignSelf: 'center' }}>
                        { isFetchingData || !job ? PREVIEW_TEXT_SKELETON : job.title }
                    </div>
                    <div style={{ marginLeft: 'auto' }}>
                        <IconButton id="draggable-dialog-title" sx={{ cursor: 'move', color: t => t.palette.primary.contrastText }}><ControlCameraIcon /></IconButton>
                        <IconButton onClick={ () => hideCallback() } sx={{ color: t => t.palette.primary.contrastText }}><CloseIcon /></IconButton>
                    </div>
                </DialogTitle>
                <DialogContent sx={{ p: 0 }}>
                    {isLoading &&
                        <Box display="flex" justifyContent="center" alignItems="center" height="100%" >
                            <CircularProgress />
                        </Box>
                    }
                    <Box p="10px" height="100%" display={isLoading ? 'none' : undefined}>
                        <div className="control-section">
                            { job && jobTypeSwitch &&
                                <RenderContent
                                    activeFields={activeFields}
                                    divisions={divisions}
                                    setFetchingSavedState={setFetchingSavedState}
                                    job={job}
                                />
                            }
                            { job && !jobTypeSwitch &&
                                <RenderContent
                                    activeFields={activeFields}
                                    divisions={divisions}
                                    setFetchingSavedState={setFetchingSavedState}
                                    job={job}
                                />
                            }
                        </div>
                    </Box>
                </DialogContent>
            </Dialog>
        </Draggable>
    );
}

function RenderContent({ job, activeFields, divisions, setFetchingSavedState }: Props2) {
    const [screenResizedControl, setScreenResizedControl] = useState(false);
    const [fetchedSavedState, setFetchedSavedState] = useState(false);
    const [lastSavedState, setLastSavedState] = useState<PanelModel[] | null>(null);
    const theme = useTheme();

    const layoutDataSettingName = useMemo(() => {
        if (job) {
            switch (job.type) {
                case 'Permanent': return 'JobQuickViewLayoutElements_Permanent';
                case 'Contract': return 'JobQuickViewLayoutElements_Contract';
                case 'FixedContract': return 'JobQuickViewLayoutElements_FixedContract';
                case 'TalentPool': return 'JobQuickViewLayoutElements_TalentPool';
                case 'Panel': return 'JobQuickViewLayoutElements_Panel';
            }
        }
        return '';
    }, [job]);

    const defaultElements = useMemo(() => {
        if (job) {
            switch (job.type) {
                case 'Permanent': return DefaultJobQuickViewLayoutElements_Permanent;
                case 'Contract': return DefaultJobQuickViewLayoutElements_Contract;
                case 'FixedContract': return DefaultJobQuickViewLayoutElements_FixedContract;
                case 'TalentPool': return DefaultJobQuickViewLayoutElements_TalentPool;
                case 'Panel': return DefaultJobQuickViewLayoutElements_Panel;
            }
        }
        return [];
    }, [job]);

    useEffect(() => {
        const windowResizeHandler = () => setScreenResizedControl(prev => !prev);
        window.addEventListener('resize', windowResizeHandler);
        return () => window.removeEventListener('resize', windowResizeHandler);
    }, []);

    const divisionNames = useMemo<string[]>(() => {
        if (job) {
            let names: string[] = [];
            const ids = job.division.toString().split(';');
            ids.forEach(d => {
                const item = divisions.find(div => div.id === +d);
                if (item) names.push(item.name);
            });
            return names;
        }
        return [];
    }, [job, divisions]);

    const customFieldsSettingsMap = useMemo<CustomFieldSettingsMap>(() => {
        let obj: CustomFieldSettingsMap = {};
        activeFields.forEach(u => {
            const customFieldNumber = u.name.substring(13);
            const key = 'CustomField' + customFieldNumber as CustomFieldType;
            obj[key] = {
                title: u.agencyName,
                isMultiLine: u.multiLine && !u.usePredefinedValues,
            }
        });
        return obj;
    }, [activeFields]);

    useEffect(() => {
        const getSavedState = async () => {
            setFetchingSavedState(true);
            const elementsJson = await GetCustomerSettingBySettingName(layoutDataSettingName);
            if (elementsJson) {
                const panels = JSON.parse(elementsJson) as PanelModel[];
                setLastSavedState(panels);
            }
            setFetchingSavedState(false);
            setFetchedSavedState(true);
        };
        getSavedState();
    }, [layoutDataSettingName, setFetchingSavedState]);

    const elements = useMemo<JobRecordDashboardElementDefinition[]>(() => {
        if (!fetchedSavedState) return [];
        if (lastSavedState !== null) {
            const elements = GetPanelDefinitionsFromPanelModels(lastSavedState);
            return elements ;
        }
        return defaultElements;
    }, [fetchedSavedState, lastSavedState, defaultElements]);

    const renderElement = useCallback((id: string, type: JobRecordDashboardElementType) => {
        if (job) {
            switch (type) {
                case 'Divider': return <Box pt="20px"><Divider component="div" /></Box>;
                case 'Spacer': return <></>;
                case 'JobId': return <SingleFieldElement useEllipsisForLongValues fieldTitle="ID" fieldValue={job.jobReference} />;
                case 'JobType': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Job Type" fieldValue={job.typeName} />;
                case 'JobWorkType': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Work Type" fieldValue={job.workType} />;
                case 'JobTitle': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Job Title" fieldValue={job.title} />;
                case 'JobExclusive': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Exclusive" fieldValue={job.exclusive ? 'Yes' : 'No'} />;
                case 'JobQuantity': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Quantity" fieldValue={job.candidatesRequired.toString()} />;
                case 'JobStartDate': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Start Date" fieldValue={job.startDate} format="date" />;
                case 'JobDuration':
                    const duration = job.duration !== 0 ? `${job.duration} ${getDurationPeriodName(job.durationPeriod)}` : '';
                    return <SingleFieldElement useEllipsisForLongValues fieldTitle="Duration" fieldValue={duration} />;
                case 'JobDeadline': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Deadline" fieldValue={job.deadline} format="date" />;
                case 'JobDivision': return (
                        <SingleFieldElement fieldTitle="Division" format="custom">
                            {divisionNames.map(d => (
                                <Chip key={d} size="small" label={d} sx={{ mr: '5px' }} />
                            ))}
                        </SingleFieldElement>
                    );
                case 'JobSalaryPackage':
                    const salaryFrom = formatNumber(job.salaryFrom);
                    const salaryTo = formatNumber(job.salaryTo);
                    const salaryUnits = getSalaryUnitsName(job.salaryUnits);
                    return <SingleFieldElement useEllipsisForLongValues fieldTitle="Salary Package" fieldValue={`${salaryFrom} to ${salaryTo} ${salaryUnits}`} />;
                case 'JobFee': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Fee" fieldValue={getFeeString(job.type, job.feeType, job.permFeeAmount, job.permFeePercentage)} />;
                case 'JobChargeRate': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Charge Rate" fieldValue={getRateString(job.chargeRateFrom, job.chargeRateTo, job.chargeRateUnits)} />;
                case 'JobPayRate': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Pay Rate" fieldValue={getRateString(job.rateFrom, job.rateTo, job.rateUnits)} />;
                case 'JobOnCosts': return <SingleFieldElement useEllipsisForLongValues fieldTitle="On Costs" fieldValue={getRateString(job.onCostsFrom, job.onCostsTo, job.onCostsUnits)} />;
                case 'JobComments': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Comments" fieldValue={job.remunerationComment} />;
                case 'JobCurrency': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Currency" fieldValue={job.currencyName} />;
                case 'JobSource': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Source" fieldValue={job.source} />;
                case 'JobLocation': return (
                    <ViewClientLocationElement
                        address1={job.address1}
                        address2={job.address2}
                        address3={job.address3}
                        countryName={job.countryName}
                        postcode={job.postcode}
                        siteId={job.siteID}
                        siteName={job.siteName}
                        state={job.state}
                        suburb={job.suburb}
                    />);
                case 'JobClient': return (
                    <SingleFieldElement
                        fieldTitle='Client'
                        fieldValue={job.clientName}
                        format="internal-link"
                        href={`/clients/${job.clientID}`}
                        previewType="client"
                        previewRecordId={job.clientID}
                    />);
                case 'JobContact': return (
                    <SingleFieldElement
                        fieldTitle='Contact'
                        fieldValue={job.contact1Name}
                        format="internal-link"
                        href={`/contacts/${job.contact1ID}`}
                        previewType="contact"
                        previewRecordId={job.contact1ID}
                    />);
                case 'JobHiringManager': return (
                    <SingleFieldElement
                        fieldTitle='Hiring Mgr'
                        fieldValue={job.contact2Name}
                        format="internal-link"
                        href={`/contacts/${job.contact2ID}`}
                        previewType="contact"
                        previewRecordId={job.contact2ID}
                    />);
                case 'JobBilling1': return (
                    <SingleFieldElement
                        fieldTitle='Billing 1'
                        fieldValue={job.contact3Name}
                        format="internal-link"
                        href={`/contacts/${job.contact3ID}`}
                        previewType="contact"
                        previewRecordId={job.contact3ID}
                    />);
                case 'JobClientRef': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Client Ref" fieldValue={job.clientReference} />;
                case 'JobVideoLink':
                    const videoUrl = job.videoLink;
                    const href = videoUrl && !videoUrl.startsWith('http') ? 'https://' + videoUrl : videoUrl;
                    return <SingleFieldElement useEllipsisForLongValues fieldTitle="Video Link" fieldValue={videoUrl ? 'Click to View' : ''} href={href} format="external-link" />;
                case 'JobRating': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Rating" fieldValue={getJobRatingName(job.rating)} />;
                case 'JobSummary': return <MultiLineTextElement title="Summary" content={job.description} />;
                case 'JobSummaryFullWidth': return <MultiLineTextElement title="Summary" content={job.description} dividerTitle />;
                case 'JobStatus': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Status" fieldValue={job.statusName} />;
                case 'JobStage': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Stage" fieldValue={job.stageName} />;
                case 'JobOutcome': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Outcome" fieldValue={getJobOutcomeName(job.outcome)} />;
                case 'JobHoursPerDay': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Hours Per Day" fieldValue={job.hoursPerDay.toString()} />;
                case 'JobDaysPerWeek': 
                    const daysPerWeek = job.daysPerWeek >= 1 && job.daysPerWeek <= 7 ? job.daysPerWeek.toString() : '';
                    return <SingleFieldElement useEllipsisForLongValues fieldTitle="Days Per Week" fieldValue={`${daysPerWeek} Day${job.daysPerWeek > 1 ? 's' : ''}`} />;
                case 'JobConsultant1': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Consultant 1" fieldValue={job.consultant1Name} />;
                case 'JobConsultant2': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Consultant 2" fieldValue={job.consultant2Name} />;
                case 'JobComplianceChecklist': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Compliance Checklist" fieldValue={job.complianceChecklistName} />;
                default: 
                    if (type.startsWith('CustomField')) {
                        const settings = customFieldsSettingsMap[type];
                        if (settings) {
                            const title = settings.title;
                            const { field, format } = UdfJobFieldMapObj[type as CustomFieldType];
                            const udfValue = job[field];
                            if (format === 'string' || format === 'date' || format === 'datetime') {
                                return <SingleFieldElement isMultiLine={settings.isMultiLine} format={format} fieldTitle={title} fieldValue={udfValue ? udfValue.toString() : null} />;
                            }
                            if (format === 'number') {
                                const numValue = udfValue === undefined || udfValue === null ? '' : udfValue.toString();
                                return <SingleFieldElement fieldTitle={title} fieldValue={numValue} />;
                            }
                        }
                        else return <SingleFieldElement fieldTitle={type} fieldValue="[ Disabled Custom Field ]" />
                    }
                    return <div>{id}</div>;
            }
        }
    }, [customFieldsSettingsMap, divisionNames, job]);

    const layoutResizeStopHandler = useCallback(() => {
        setScreenResizedControl(prev => !prev);
    }, []);

    const renderLayout = useCallback(() => {
        if (!fetchedSavedState) return <></>;
        return (
            <DashboardLayoutComponent
                cellSpacing={cellSpacing}
                columns={columns}
                cellAspectRatio={12 / 2}
                resizeStop={ layoutResizeStopHandler }
                allowDragging={false}
                allowResizing={false}
            >
                {elements.map(e => (
                    <PanelWrapper
                        key={e.id}
                        id={e.id}
                        col={e.col}
                        row={e.row}
                        sizeX={e.sizeX}
                        sizeY={e.sizeY}
                        minSizeX={e.minSizeX}
                        minSizeY={e.minSizeY}
                        maxSizeX={e.maxSizeX}
                        maxSizeY={e.maxSizeY}
                        resizeControl={screenResizedControl}
                        resizeIconColor={theme.palette.text.disabled}
                        gapX={gapX}
                        gapY={gapY}
                        unitWidth={unitWidth}
                        unitHeight={unitHeight}
                    >
                        {renderElement(e.id, e.type)}
                    </PanelWrapper>
                ))}
            </DashboardLayoutComponent>
        );
    }, [elements, fetchedSavedState, layoutResizeStopHandler, renderElement, screenResizedControl, theme.palette.text.disabled]);

    return renderLayout();
}