import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import PageLayout from "layouts/PageLayout";
import PageContentLayout from "layouts/PageContentLayout";
import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CvIcon from '../../assets/icons/cv.svg';
import { GetSettingBySettingName } from "services/UsersService";
import ConfirmationDialog from "components/Dialogs/Generic/ConfirmationDialog";
import Snackbar from "@mui/material/Snackbar";
import Alert from "components/Alert";
import ActionMenu from "components/Menus/ActionMenu";
import moment from "moment";
import { MenuOptionDefinition } from "common/models/MenuDefinition";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import ActivitiesGridComponent from "components/Grids/ActivitiesGrid";
import { ConvertSubmissionToContractJob, ConvertSubmissionToPermJob, GetSubmissionById, ResendSubmission } from "services/SubmissionsService";
import { Submission } from "common/models/Submissions/Submission";
import ViewRecordScreenLayout from "components/ScreenLayouts/Submissions/ViewRecordScreenLayout";
import ContactsGridComponent from "components/Grids/ContactsGrid";
import Avatar from "@mui/material/Avatar";
import { DownloadCandidateResume, GetCandidateById } from "services/CandidatesService";
import { Candidate } from "common/models/Candidates/Candidate";
import DocumentsGridComponent from "components/Grids/DocumentsGrid";
import { GetClientById } from "services/ClientsService";
import { Client } from "common/models/Clients";
import ListItemText from "@mui/material/ListItemText";
import { DownloadDocument } from "services/DocumentsService";

const floatTypeId = 2;

const summaryLinkStyle: React.CSSProperties = { color: 'inherit', textDecoration: 'none' };
const formatDateString = (d: string, f: string = 'DD MMM YYYY h:mm A') => {
    if (d && d !== '0001-01-01T00:00:00') {
        const m = moment(d);
        if (m.isValid()) return m.format(f);
    }
    return 'Never';
}

const renderSummary = (s: Submission, c?: Client) => {
    const tooltipContent = (
        <table>
            <tbody>
                <tr>
                    <Typography variant="caption" fontWeight={600} component="td">ID</Typography>
                    <Typography variant="caption" pl="10px" component="td">{s.id}</Typography>
                </tr>
                <tr>
                    <Typography variant="caption" fontWeight={600} component="td">Created Date</Typography>
                    <Typography variant="caption" pl="10px" component="td">{formatDateString(s.createdDate)}</Typography>
                </tr>
            </tbody>
        </table>
    );

    const candidateLink = (
        <Box component="span" sx={{ color: t => t.palette.primary.main }}>
            <Link to={`/candidates/${s.candidateID}`} style={summaryLinkStyle}>{s.candidate}</Link>
        </Box>
    );

    const submissionSummary = (
        <Tooltip arrow title={tooltipContent}>
            <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>Submission Details - {candidateLink}</span>
        </Tooltip>
    );

    if (s.typeId !== floatTypeId && c) {
        const jLink = <Link to={`/jobs/${s.jobID}`} style={{ color: 'inherit' }}>{`${s.jobID} - ${s.jobTitle}`}</Link>;
        const cLink = <Link to={`/clients/${c.id}`} style={{ color: 'inherit' }}>{c.legalName}</Link>;
        const extraData = <>{jLink}{' @ '}{cLink}</>;
        return <ListItemText primary={submissionSummary} secondary={extraData} />
    }

    return (<>{submissionSummary}</>);
};

export default function SubmissionRecord() {
    const [submission, setSubmission] = useState<Submission>();
    const [candidate, setCandidate] = useState<Candidate>();
    const [client, setClient] = useState<Client>();
    const params = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [isFetchingDefaultTab, setIsFetchingDefaultTab] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [isLoadingTab, setIsLoadingTab] = useState(false);
    const [showResendConfirmation, setShowResendConfirmation] = useState(false);
    const [showConvertToPermConfirm, setShowConvertToPermConfirm] = useState(false);
    const [showConvertToContractConfirm, setShowConvertToContractConfirm] = useState(false);
    const [isFinishedSetup, setIsFinishedSetup] = useState(false);
    const [searchParams] = useSearchParams();

    const [activeTab, setActiveTab] = useState('Home');
    const navigate = useNavigate();

    const submissionId = useMemo(() => {
        const parsedId = +(params.id ?? '0');
        if (isNaN(parsedId)) return 0;
        return parsedId;
    }, [params.id]);

    const isFloat = useMemo(() => submission ? submission.typeId === floatTypeId : false, [submission]);

    useEffect(() => {
        const getData = async () => {
            setIsLoading(true);
            const res = await GetSubmissionById(submissionId, setErrorMessage);
            if (res === null) navigate('/not-found');
            if (res) {
                setSubmission(res);
                const cand = await GetCandidateById(res.candidateID, setErrorMessage);
                if (cand) setCandidate(cand);
                if (res.clientID !== 0) {
                    const cli = await GetClientById(res.clientID, setErrorMessage);
                    if (cli) setClient(cli);
                }
            }
            setIsLoading(false);
        };
        getData();
    }, [submissionId, navigate]);

    useEffect(() => {
        const tabParam = searchParams.get('tab');
        const getData = async () => {
            setIsFetchingDefaultTab(true);
            if (!tabParam) {
                const tabSetting = await GetSettingBySettingName('TabDefaultsSubmissions');
                if (tabSetting) setActiveTab(tabSetting);
            }
            else setActiveTab(tabParam)
            setIsFetchingDefaultTab(false);
            setIsFinishedSetup(true);
        };
        getData();
    }, [searchParams]);

    const downloadCV = useCallback(async () => {
        setIsLoading(true);
        if (submission && submission.documentID) await DownloadDocument(submission.documentID);
        else if (candidate && candidate.hasResume) await DownloadCandidateResume(candidate.id);
        setIsLoading(false);
    }, [candidate, submission]);

    const actionMenuDefinitions = useMemo<MenuOptionDefinition[]>(() => {
        const submissionType = submission ? submission.typeId : 0;
        const allowConvert = submissionType === floatTypeId || submissionType === 42;
        let actions: (MenuOptionDefinition)[] = [
            { label: 'Resend Submission', type: 'action', action: () => setShowResendConfirmation(true) },
            { label: 'Convert To Job - Perm', type: 'action', action: () => setShowConvertToPermConfirm(true), allow: () => allowConvert },
            { label: 'Convert To Job - Contract', type: 'action', action: () => setShowConvertToContractConfirm(true), allow: () => allowConvert },
        ];

        return actions;
    }, [submission]);

    const SummaryBar = useMemo(() => {
        if (submission) {
            const downloadCvAction = (submission && submission.documentID) || (candidate && candidate.hasResume) ? (
                <Avatar
                    key="downloadResumeAction"
                    onClick={ downloadCV }
                    sx={{ mr: '5px', cursor: 'pointer' }}
                    title="Download CV"
                    src={CvIcon}
                />
            ) : <React.Fragment key="downloadResumeAction" /> ;
            
            const action = (
                <Box key="actionsMenu">
                    <ActionMenu definition={actionMenuDefinitions} />
                </Box>
            );

            return (
                <TitleAndActionSummaryBar
                    height="4.5rem"
                    title={submission ? renderSummary(submission, client) : ''}
                    action={[downloadCvAction, action]}
                    browserTabTitle={!submission ? '' : "Submissions"}
                />
            );
        }
    }, [submission, candidate, actionMenuDefinitions, client, downloadCV]);


    const handleTabChange = useCallback((e: React.SyntheticEvent, newValue: string) => {
        navigate(`/submissions/${submissionId}?tab=${newValue}`);
    }, [submissionId, navigate]);

    const counts = useMemo(() => {
        let counts = {
            activities: 0,
            activitiesDate: '\u00A0',
        };

         if (submission && submission.statisticsCounts && submission.statisticsCounts.length > 0) {
             for (let i = 0; i < submission.statisticsCounts.length; i++) {
                 const s = submission.statisticsCounts[i];
                 switch (s.type) {
                     case "ActivitiesCount": counts.activities = s.value;
                         break;
                 }
             }
         }

         if (submission && submission.statisticsDates && submission.statisticsDates.length > 0) {
             for (let i = 0; i < submission.statisticsDates.length; i++) {
                 const s = submission.statisticsDates[i];
                 switch (s.type) {
                     case "ActivitiesDate":
                         const aDate = moment(s.value);
                         if (aDate.isValid()) counts.activitiesDate = aDate.format('DD MMM YYYY');
                         break;
                 }
             }
         }

        return counts;
    }, [submission]);

    const resendSubmissionCallback = useCallback(async () => {
        setIsLoading(true);
        const res = await ResendSubmission(submissionId, setErrorMessage);
        if (res) setSuccessMessage('Submission resent');
        setShowResendConfirmation(false);
        setIsLoading(false);
    }, [submissionId]);

    const convertToPermCallback = useCallback(async () => {
        setIsLoading(true);
        const res = await ConvertSubmissionToPermJob(submissionId, setErrorMessage);
        if (res) navigate(`/jobs/${res.value}`);
        setIsLoading(false);
    }, [submissionId, navigate]);

    const convertToContractCallback = useCallback(async () => {
        setIsLoading(true);
        const res = await ConvertSubmissionToContractJob(submissionId, setErrorMessage);
        if (res) navigate(`/jobs/${res.value}`);
        setIsLoading(false);
    }, [submissionId, navigate]);

    return (
        <>
            <Snackbar open={successMessage !== ''} autoHideDuration={3000} onClose={() => setSuccessMessage('')}>
                <Alert onClose={() => setSuccessMessage('')}>{ successMessage }</Alert>
            </Snackbar>
            <Snackbar open={errorMessage !== ''} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <ConfirmationDialog
                open={showResendConfirmation}
                title="Confirm Action"
                message="Are you sure you want to resend this submission?"
                onClose={() => setShowResendConfirmation(false)}
                onContinue={resendSubmissionCallback}
            />
            <ConfirmationDialog
                open={showConvertToPermConfirm}
                title="Confirm Action"
                message="Are you sure you want to convert this submission to a perm job?"
                onClose={() => setShowConvertToPermConfirm(false)}
                onContinue={convertToPermCallback}
            />
            <ConfirmationDialog
                open={showConvertToContractConfirm}
                title="Confirm Action"
                message="Are you sure you want to convert this submission to a contract job?"
                onClose={() => setShowConvertToContractConfirm(false)}
                onContinue={convertToContractCallback}
            />
            <PageLayout paddingTop={0} SummaryBar={SummaryBar}>
                <Tabs value={activeTab} onChange={handleTabChange}>
                    <Tab value="Home" label={<>Home<br />&nbsp;</>} />
                    <Tab value="Recipients" label={<>Recipients<br />&nbsp;</>} />
                    <Tab value="Attachments" label={<>Attachments<br />&nbsp;</>} />
                    <Tab value="Activities" label={<>Activities ({counts.activities})<br />{counts.activitiesDate}</>} />
                </Tabs>
                <PageContentLayout showLoading={isLoading || isFetchingDefaultTab || isLoadingTab}>
                    { isFinishedSetup && activeTab === "Home" &&
                        <ViewRecordScreenLayout
                            submission={submission ? submission : null}
                            isFloat={isFloat}
                            loadingHandler={setIsLoadingTab}
                            errorHandler={setErrorMessage}
                        />
                    }
                    { isFinishedSetup && activeTab === "Recipients" &&
                        <ContactsGridComponent
                            gridName="submission/recipients"
                            source="submission-record"
                            sourceId={submissionId}
                            errorHandler={setErrorMessage}
                            loadingHandler={setIsLoadingTab}
                        />
                    }
                    { isFinishedSetup && activeTab === "Attachments" &&
                        <DocumentsGridComponent
                            gridName="submission/attachments"
                            source="submission-record"
                            sourceId={submissionId}
                            hideActions
                            hideCheckboxSelection
                            loadingHandler={setIsLoadingTab}
                            errorHandler={setErrorMessage}
                            successHandler={setSuccessMessage}
                        />
                    }
                    { isFinishedSetup && activeTab === "Activities" && 
                        <ActivitiesGridComponent
                            source="submission-record"
                            sourceId={submissionId}
                            loadingHandler={setIsLoadingTab}
                            errorHandler={setErrorMessage}
                            successHandler={setSuccessMessage}
                            gridName="submissions/Activities"
                            hideActionsMenu
                        />
                    }
                </PageContentLayout>
            </PageLayout>
        </>
    );
}