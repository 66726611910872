import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import ControlCameraIcon from '@mui/icons-material/ControlCamera';
import React, { useEffect, useState } from "react";
import Draggable from "react-draggable";
import { Advert } from "common/models/Advert";
import { PreviewProps } from "common/models/Previews/Previews";
import { GetAdvertById } from "services/AdvertsService";
import { PREVIEW_HEIGHT, PREVIEW_TABLE_CONTENT, PREVIEW_TABLE_LABEL, PREVIEW_TABLE_STYLE, PREVIEW_TEXT_SKELETON, PREVIEW_TITLE_STYLE, PREVIEW_WIDTH } from "util/Definitions/Constants/Previews";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";

const getAdvertStatus = (statusId: number) => {
    switch (statusId)
    {
        case -1: return "Pending Approval";
        case 0: return "Template";
        case 1: return "Draft";
        case 2: return "Scheduled";
        case 3: return "Awaiting Posting";
        case 4: return "Partially Posted";
        case 5: return "Posted";
        case 6: return "Completed";
        case 7: return "Awaiting Expiration";
        case 8: return "Expired";
        case 9: return "Awaiting Update";
        case 10: return "Errored";
    }
    return "Unknown";
};

export default function AdvertPreviewComponent({ recordId, isSourceHovered, hideCallback, hideDelayMs = 1250 }: PreviewProps) {
    const [shouldClose, setShouldClose] = useState(false);
    const [isFetchingData, setIsFetchingData] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [advert, setAdvert] = useState<Advert>();

    useEffect(() => {
        const getData = async () => {
            setIsFetchingData(true);
            const res = await GetAdvertById(recordId);
            if (res) setAdvert(res);
            setIsFetchingData(false);
        };
        getData();
    }, [recordId]);

    useEffect(() => {
        if (shouldClose) {
            const hidePreviewTimeout = setTimeout(() => {
                hideCallback();
            }, hideDelayMs);
            return () => clearTimeout(hidePreviewTimeout);
        }
    }, [shouldClose, hideDelayMs, hideCallback]);

    useEffect(() => {
        if (isSourceHovered || isHovered) setShouldClose(false);
        else setShouldClose(true);
    }, [isSourceHovered, isHovered]);

    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
            defaultPosition={{ x: (window.innerWidth / 2) - (PREVIEW_WIDTH / 2), y: (window.innerHeight / 2) - (PREVIEW_HEIGHT / 2) }}
        >
            <Dialog
                open={true}
                maxWidth={false}
                PaperProps={{ 
                    onMouseEnter: () => setIsHovered(true),
                    onMouseLeave: () => setIsHovered(false),
                    sx: { height: `${PREVIEW_HEIGHT}px`, width: `${PREVIEW_WIDTH}px` }
                }}
                hideBackdrop
                sx={{ bottom: 'unset', right: 'unset', left: 'unset' }}
            >
                <DialogTitle component="div" display="flex" sx={ PREVIEW_TITLE_STYLE }>
                    <div style={{ alignSelf: 'center' }}>{ isFetchingData || !advert ? PREVIEW_TEXT_SKELETON : advert.title }</div>
                    <div style={{ marginLeft: 'auto' }}>
                        <IconButton id="draggable-dialog-title" sx={{ cursor: 'move', color: t => t.palette.primary.contrastText }}><ControlCameraIcon /></IconButton>
                        <IconButton onClick={ () => hideCallback() } sx={{ color: t => t.palette.primary.contrastText }}><CloseIcon /></IconButton>
                    </div>
                </DialogTitle>
                <DialogContent sx={{ display: 'flex'}}>
                    <div style={{ paddingTop: '10px', display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <table style={ PREVIEW_TABLE_STYLE }>
                                    <tbody>
                                        <tr>
                                            <td width="20%" style={ PREVIEW_TABLE_LABEL }>ID</td>
                                            <td width="80%" style={ PREVIEW_TABLE_CONTENT }>{isFetchingData || ! advert ? PREVIEW_TEXT_SKELETON : advert.id}</td>
                                        </tr>
                                        <tr>
                                            <td width="20%" style={ PREVIEW_TABLE_LABEL }>Status</td>
                                            <td width="80%" style={ PREVIEW_TABLE_CONTENT }>{isFetchingData || ! advert ? PREVIEW_TEXT_SKELETON : getAdvertStatus(advert.statusID)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Grid>
                        </Grid>
                        <div style={{ paddingTop: '5px' }}>
                            <Divider />
                        </div>
                        <Box flexGrow={1} overflow="auto">
                            <br />
                            <Typography>{isFetchingData || ! advert ? PREVIEW_TEXT_SKELETON : advert.shortDescription}</Typography>
                            <ul>
                                {isFetchingData || ! advert ? PREVIEW_TEXT_SKELETON : <li>{advert.bullet1}</li>}
                                {isFetchingData || ! advert ? PREVIEW_TEXT_SKELETON : <li>{advert.bullet2}</li>}
                                {isFetchingData || ! advert ? PREVIEW_TEXT_SKELETON : <li>{advert.bullet3}</li>}
                            </ul>
                            { isFetchingData || !advert
                                ? <Skeleton variant="rectangular" height="100%" />
                                : <div style={{ flexGrow: 1, overflow: 'auto' }} dangerouslySetInnerHTML={{ __html: advert.description ?? '' }} />
                            }
                        </Box>
                    </div>
                </DialogContent>
            </Dialog>
        </Draggable>
    );
}