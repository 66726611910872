import { Permission } from '../common/models/Permissions';
import { ExtendedUser, TeamMember, TransferRecordsData, User, UserGeneralSettings, UsersRecordsCounts } from '../common/models/Configuration/User';
import { UserCreate } from '../common/models/Configuration/UserCreate';
import { RequestOptions, ErrorHandler, StringValueWrapper, SettingObject } from '../common/models/GenericTypes';
import { Get, Post, Put } from '../util/HttpUtils';
import { KpiData, KpiWeek } from '../common/models/Kpi';
import { ExclusionValue } from '../common/models/Configuration/EmailIntegration';
import { UnlinkedMail } from '../common/models/Activities';
import { SavedSearch, SavedSearchSettings } from '../common/models/SavedSearches';
import { MarketingPlatformsAccess } from '../common/models/Common';

export async function GetAllUsers(errorHandler?: ErrorHandler): Promise<User[] | null> {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<User[]>('api/users/all', options);
}

export async function GetActiveUsers(errorHandler?: ErrorHandler): Promise<User[] | null> {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<User[]>('api/users/active', options);
}

export async function GetMyPermissions(errorHandler?: ErrorHandler): Promise<Permission[] | null> {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Permission[]>('api/users/me/permissions', options);
}

export async function GetMyCompanyPermissions(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Permission[]>('api/users/customer/permissions', options);
}

export async function GetMyUser(errorHandler?: ErrorHandler) {
    const cachedValue = localStorage.getItem('Me');
    if (cachedValue) {
        var user: ExtendedUser = JSON.parse(cachedValue);
        return user;
    }

    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    const res = await Get<ExtendedUser>('api/users/Me', options);
    if (res) {
        localStorage.setItem('Me', JSON.stringify(res));
        return res;
    }
}

export async function GetUser(id: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<ExtendedUser>(`api/users/${id}`, options);
}

export async function AddUser(user: UserCreate, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>('api/users', options, user);
}

export async function DisableUsers(userIds: number[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>('api/users/disable', options, userIds);
}

export async function ForcePasswordChange(userId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/users/${userId}/ForcePasswordReset`, options);
}

export async function GetUsersRecordsCounts(userIds: number[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<UsersRecordsCounts>('api/users/counts/records', options, userIds);
}

export async function TransferRecordsOwnership(data: TransferRecordsData, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>('api/users/records/transfer', options, data);
}

export async function ResendWelcomeEmail(usersId: number[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>('api/users/resend-welcome', options, usersId);
}

export async function GetKpiYears(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<number[]>('api/users/kpis/years', options);
}

export async function GetKpiWeeks(year: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<KpiWeek[]>(`api/users/kpis/years/${year}/weeks`, options);
}

export async function GetKpiData(userId: number, year: number, week: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<KpiData[]>(`api/users/${userId}/kpis/${year}/${week}`, options);
}

export async function UpdateKpiData(userId: number, year: number, week: number, data: KpiData[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/users/${userId}/kpis/${year}/${week}`, options, data);
}

export async function GetMyGeneralSettings(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<UserGeneralSettings>('api/Users/Me/General', options);
}

export async function UpdateMyGeneralSettings(settings: UserGeneralSettings, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    const retVal = await Put<boolean>('api/Users/Me/General', options, settings);

    const options2: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    const res = await Get<ExtendedUser>('api/users/Me', options2);
    if (res) {
        localStorage.setItem('Me', JSON.stringify(res));
    }

    return retVal;
}

export async function GetUserGeneralSettings(userId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<UserGeneralSettings>(`api/Users/${userId}/General`, options);
}

export async function UpdateUserGeneralSettings(userId: number, settings: UserGeneralSettings, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/Users/${userId}/General`, options, settings);
}

export async function GetMySignature(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<StringValueWrapper>('api/Users/Me/Signature', options);
}

export async function UpdateMySignature(signature: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>('api/Users/Me/Signature', options, { value: signature });
}

export async function GetUserSignature(userId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<StringValueWrapper>(`api/Users/${userId}/Signature`, options);
}

export async function UpdateUserSignature(userId: number, signature: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/Users/${userId}/Signature`, options, { value: signature });
}

export async function GetMyProfile(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<StringValueWrapper>('api/Users/Me/Profile', options);
}

export async function UpdateMyProfile(profile: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>('api/Users/Me/Profile', options, { value: profile });
}

export async function GetUserProfile(userId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<StringValueWrapper>(`api/Users/${userId}/Profile`, options);
}

export async function UpdateUserProfile(userId: number, profile: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/Users/${userId}/Profile`, options, { value: profile });
}

export async function GetMySettings(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    const settings = await Get<SettingObject[]>('api/Users/Me/Settings', options);

    if (settings) {
        settings.forEach((element) => {
            localStorage.setItem(element.type, element.value);
        });
    }

    return settings;
}

export async function UpdateMySettings(settings: SettingObject[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    settings.forEach((element) => {
        localStorage.setItem(element.type, element.value);
    });

    return Put<boolean>('api/Users/Me/Settings', options, settings);
}

export async function GetUserSettings(userId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<SettingObject[]>(`api/Users/${userId}/Settings`, options);
}

export async function UpdateUserSettings(userId: number, settings: SettingObject[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/Users/${userId}/Settings`, options, settings);
}

export async function GetUserPermissions(userId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Permission[]>(`api/Users/${userId}/Permissions`, options);
}

export async function UpdateUserPermissions(userId: number, permissions: Permission[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/Users/${userId}/Permissions`, options, permissions);
}

export async function GetMyExclusionList(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<ExclusionValue[]>('api/Users/Me/ExclusionList', options);
}

export async function UpdateMyExclusionList(exclusions: ExclusionValue[], deleteIds: number[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>('api/Users/Me/ExclusionList', options, { exclusions, deleteIds })
}

export async function GetMyUnlinkedMail(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<UnlinkedMail[]>('api/Users/Me/UnlinkedMail', options);
}

export async function GetMySavedSearches(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<SavedSearch[]>('api/Users/Me/SavedSearches', options);
}

export async function GetMySavedSearchSettings(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<SavedSearchSettings>('api/Users/Me/SavedSearches/Settings', options);
}

export async function UpdateMySavedSearchSettings(settings: SavedSearchSettings, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>('api/Users/Me/SavedSearches/Settings', options, settings);
}

export async function GetMyTeamMembers(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<TeamMember[]>('api/Users/Me/TeamMembers', options);
}

export async function UpdateMyTeamMembers(ids: number[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>('api/Users/Me/TeamMembers', options, ids);
}

export async function GetMyRecordDefaults(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<SettingObject[]>('api/Users/Me/RecordDefaults', options);
}

export async function UpdateMyRecordDefaults(settings: SettingObject[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    settings.forEach((element) => {
        localStorage.setItem(element.type, element.value);
    });

    return Put<boolean>('api/Users/Me/RecordDefaults', options, settings);
}

export async function GetSingleSetting(settingName: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<StringValueWrapper>(`api/Users/Me/Settings/Single?setting=${encodeURIComponent(settingName)}`, options);
}

export async function GetSettingBySettingName(settingName: string, errorHandler?: ErrorHandler) {
    const cachedValue = localStorage.getItem(settingName);
    if (cachedValue) {
        return cachedValue;
    }

    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    const res = await Get<StringValueWrapper>(`api/Users/Me/Settings/Single?setting=${encodeURIComponent(settingName)}`, options);
    if (res && res.value) {
        localStorage.setItem(settingName, res.value);
        return res.value;
    }
    return null;
}

export async function UpdateSettingBySettingName(settingName: string, value: string, errorHandler?: ErrorHandler) {
    const options:RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    }

    const res = await Put<boolean>(`api/Users/Me/Settings/Single?setting=${encodeURIComponent(settingName)}`, options, { value });
    if (res) localStorage.setItem(settingName, value);
    return res;
}

export async function GetMarketingPlatformsAvailable(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<MarketingPlatformsAccess>('api/Users/Customer/MarketingPlatforms', options);
}

export async function GetAdvertTemplatesVisibility(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<StringValueWrapper>('api/Users/Customer/AdvertTemplatesVisibility', options);
}

export async function GetJobRecordSettings(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<SettingObject[]>('api/Users/Customer/Jobs', options);
}