import { Permission } from '../models/Permissions';
import { TopBarRoute } from './../models/TopBarTypes';
import { userHasSinglePermission, userHasAllPermissions, companyHasSinglePermission, companyHasAllPermissions, userHasAnyPermission } from '../../util/PermissionsUtils';
import * as ConfigurationPermissions from './Permissions/ConfigurationAccess';
import { AllowClientsAddEdit, AllowClientsModule } from './Permissions/ClientsAccess';
import { isEdenRitchie } from 'util/AuthUtils';

export const ConfigurationEditUsersRoutes: TopBarRoute = {
    path: '/configuration/users',
    text: 'Users',
    allow: ConfigurationPermissions.AllowUsers,
    secondaryOptions: [
        {
            path: 'edit/general',
            text: 'General',
            allow: ConfigurationPermissions.AllowUsers
        },
        {
            path: 'edit/permissions',
            text: 'Permissions',
            allow: ConfigurationPermissions.AllowUsers
        },
        {
            path: 'edit/profile',
            text: 'Profile',
            allow: ConfigurationPermissions.AllowUsers
        },
        {
            path: 'edit/settings',
            text: 'Settings',
            allow: ConfigurationPermissions.AllowUsers
        },
        {
            path: 'edit/signature',
            text: 'Signature',
            allow: ConfigurationPermissions.AllowUsers
        },
        {
            path: 'edit/tags',
            text: 'Tags',
            allow: ConfigurationPermissions.AllowUsers
        },
    ]
}

export const ConfigurationRoutes: TopBarRoute = {
    text: "Configuration",
    path: "/configuration",
    allow: ConfigurationPermissions.AllowConfigurationModule,
    secondaryOptions: [
        {
            path: '/configuration/activities',
            text: 'Activities',
            allow: ConfigurationPermissions.AllowActivities,
        },
        {
            path: '',
            text: 'Adverts',
            allow: ConfigurationPermissions.AllowAdverts,
            options: [
                {
                    path: '/configuration/adverts/classifications',
                    text: 'Classifications',
                },
                {
                    path: '/configuration/adverts/job-boards',
                    text: 'Job Boards',
                },
                {
                    path: '/configuration/adverts/locations',
                    text: 'Locations',
                },
                {
                    path: '/configuration/adverts/notification-template',
                    text: 'Notification Template',
                },
                {
                    path: '/configuration/adverts/screening-questionnaires',
                    text: 'Screening Questionnaires',
                },
                {
                    path: '/configuration/adverts',
                    text: 'Settings',
                    allow: ConfigurationPermissions.AllowAdvertsSettings
                },
            ]
        },
        {
            path: '/configuration/analytics',
            text: 'Analytics',
            allow: ConfigurationPermissions.AllowAnalytics,
        },
        {
            path: '/configuration/authentication',
            text: 'Authentication',
            allow: ConfigurationPermissions.AllowGeneral,
        },
        {
            path: '/configuration/automation',
            text: 'Automation Lite',
            allow: ConfigurationPermissions.AllowAutomationSideBarLite,
        },
        {
            path: '/configuration/automation',
            text: 'Automation',
            allow: ConfigurationPermissions.AllowAutomationSideBarFull,
        },
        {
            path: '/configuration/candidates',
            text: 'Candidates',
            allow: ConfigurationPermissions.AllowGeneral
        },
        {
            path: '/configuration/clients',
            text: 'Clients',
            allow: ConfigurationPermissions.AllowGeneral
        },
        {
            path: '',
            text: 'Compliance',
            allow: ConfigurationPermissions.AllowCompliance,
            options: [
                {
                    path: '/configuration/compliance/checklists',
                    text: 'Checklists',
                },
                {
                    path: '/configuration/compliance/items',
                    text: 'Items',
                },
            ]
        },
        {
            path: '/configuration/contacts',
            text: 'Contacts',
            allow: ConfigurationPermissions.AllowGeneral
        },
        {
            path: '',
            text: 'Custom Fields',
            allow: ConfigurationPermissions.AllowCustomFields,
            options: [
                {
                    path: '/configuration/custom-fields/clients',
                    text: 'Clients',
                },
                {
                    path: '/configuration/custom-fields/contacts',
                    text: 'Contacts',
                },
                {
                    path: '/configuration/custom-fields/document-templates',
                    text: 'Document Templates',
                },
                {
                    path: '/configuration/custom-fields/opportunities',
                    text: 'Opportunities',
                },
                {
                    path: '/configuration/custom-fields/jobs',
                    text: 'Jobs',
                },
                {
                    path: '/configuration/custom-fields/candidates',
                    text: 'Candidates',
                },
                {
                    path: '/configuration/custom-fields/placements',
                    text: 'Placements',
                },
                {
                    path: '/configuration/custom-fields/sites',
                    text: 'Sites',
                },
            ]
        },
        {
            path: '/configuration/cv-processing-emails',
            text: 'CV Processing Emails',
            allow: ConfigurationPermissions.AllowCVProcessingEmails,
        },
        {
            path: '/configuration/divisions',
            text: 'Divisions',
            allow: ConfigurationPermissions.AllowDivisions,
        },
        {
            path: '',
            text: 'Email Integration',
            allow: ConfigurationPermissions.AllowEmailIntegration,
            options: [
                {
                    path: '/configuration/email-integration/exclusion-list',
                    text: 'Exclusion List'
                },
                {
                    path: '/configuration/email-integration/settings',
                    text: 'Settings',
                    allow: ConfigurationPermissions.AllowGeneral
                }
            ]
        },
        {
            path: '/configuration/general',
            text: 'General',
            allow: ConfigurationPermissions.AllowGeneral,
        },
        {
            path: '/configuration/groups',
            text: 'Groups',
            allow: ConfigurationPermissions.AllowGroups,
        },
        {
            path: '/configuration/integrations',
            text: 'Integrations',
            allow: ConfigurationPermissions.AllowIntegrations,
        },
        {
            path: '/configuration/interviews/types',
            text: 'Interview Types',
            allow: ConfigurationPermissions.AllowActivities,
        },
        /*{
            path: '',
            text: 'Invoices',
            allow: ConfigurationPermissions.AllowInvoices,
            options: [
                {
                    path: '/configuration/invoices/items',
                    text: 'Items',
                },
                {
                    path: '/configuration/invoices/settings',
                    text: 'Settings',
                },
                {
                    path: '/configuration/invoices/tax-rates',
                    text: 'Tax Rates',
                },
            ]
        },*/
        {
            path: '',
            text: 'Jobs',
            allow: ConfigurationPermissions.AllowJobs,
            options: [
                {
                    path: '/configuration/jobs/kanban-groups',
                    text: 'Candidate Kanban Groups',
                    allow: () => userHasAnyPermission([Permission.GlobalAdministrator])
                },
                {
                    path: '/configuration/jobs/candidate-statuses',
                    text: 'Candidate Workflow Statuses',
                    allow: () => userHasAnyPermission([Permission.GlobalAdministrator])
                },
                {
                    path: '/configuration/jobs/rating-reasons',
                    text: 'Rating Reasons'
                },
                {
                    path: '/configuration/jobs',
                    text: 'Job Settings',
                },

                {
                    path: '/configuration/jobs/sources',
                    text: 'Job Sources',
                },
                {
                    path: '/configuration/jobs/stages',
                    text: 'Job Stages',
                    allow: () => userHasAnyPermission([Permission.GlobalAdministrator])
                },
            ]
        },
        {
            path: '/configuration/meetings/types',
            text: 'Meeting Types',
            allow: ConfigurationPermissions.AllowActivities,
        },
        {
            path: '/configuration/opportunities',
            text: 'Opportunities',
            allow: ConfigurationPermissions.AllowGeneral
        },
        {
            path: '/configuration/performance',
            text: 'Performance',
            allow: ConfigurationPermissions.AllowPerformance,
        },
        {
            path: '',
            text: 'Placements',
            allow: ConfigurationPermissions.AllowPlacements,
            options: [
                {
                    path: '/configuration/placements',
                    text: 'Settings',
                },
                {
                    path: '/configuration/placements/sources',
                    text: 'Sources',
                }
            ]
        },
        {
            path: '/configuration/recently-deleted-records',
            text: 'Recently Deleted Records',
            allow: ConfigurationPermissions.AllowRecentlyDeletedRecords,
        },
        {
            path: '/configuration/scheduled-messages',
            text: 'Scheduled Messages',
            allow: ConfigurationPermissions.AllowScheduledMessages
        },
        {
            path: '',
            text: 'Screen Layouts',
            allow: ConfigurationPermissions.AllowScreenLayouts,
            options: [
                {
                    path: '',
                    text: 'Candidates',
                    options: [
                        {
                            path: '/configuration/screen-layouts/candidates/edit',
                            text: 'Create / Edit',
                        },
                        {
                            path: '/configuration/screen-layouts/candidates/quick-view',
                            text: 'Quick View',
                        },
                        {
                            path: '/configuration/screen-layouts/candidates/record',
                            text: 'View',
                        },
                    ],
                },
                {
                    path: '',
                    text: 'Clients',
                    options: [
                        {
                            path: '/configuration/screen-layouts/clients/edit',
                            text: 'Create / Edit'
                        },
                        {
                            path: '/configuration/screen-layouts/clients/quick-view',
                            text: 'Quick View',
                        },
                        {
                            path: '/configuration/screen-layouts/clients/record',
                            text: 'View'
                        },
                    ],
                },
                {
                    path: '',
                    text: 'Contacts',
                    options: [
                        {
                            path: '/configuration/screen-layouts/contacts/edit',
                            text: 'Create / Edit',
                        },
                        {
                            path: '/configuration/screen-layouts/contacts/quick-view',
                            text: 'Quick View',
                        },
                        {
                            path: '/configuration/screen-layouts/contacts/record',
                            text: 'View',
                        },
                    ],
                },
                {
                    path: '',
                    text: 'Jobs',
                    options: [
                        {
                            path: '/configuration/screen-layouts/jobs/contract/edit',
                            text: 'Contract - Create / Edit',
                        },
                        {
                            path: '/configuration/screen-layouts/jobs/contract/quick-view',
                            text: 'Contract - Quick View',
                        },
                        {
                            path: '/configuration/screen-layouts/jobs/contract/record',
                            text: 'Contract - View',
                        },
                        {
                            path: '/configuration/screen-layouts/jobs/fixed-term/edit',
                            text: 'Fixed Term - Create / Edit',
                        },
                        {
                            path: '/configuration/screen-layouts/jobs/fixed-term/quick-view',
                            text: 'Fixed Term - Quick View',
                        },
                        {
                            path: '/configuration/screen-layouts/jobs/fixed-term/record',
                            text: 'Fixed Term - View',
                        },
                        {
                            path: '/configuration/screen-layouts/jobs/panel/edit',
                            text: 'Panel - Create / Edit',
                        },
                        {
                            path: '/configuration/screen-layouts/jobs/panel/quick-view',
                            text: 'Panel - Quick View',
                        },
                        {
                            path: '/configuration/screen-layouts/jobs/panel/record',
                            text: 'Panel - View',
                        },
                        {
                            path: '/configuration/screen-layouts/jobs/permanent/edit',
                            text: 'Permanent - Create / Edit',
                        },
                        {
                            path: '/configuration/screen-layouts/jobs/permanent/quick-view',
                            text: 'Permanent - Quick View',
                        },
                        {
                            path: '/configuration/screen-layouts/jobs/permanent/record',
                            text: 'Permanent - View',
                        },
                        {
                            path: '/configuration/screen-layouts/jobs/talent-pool/edit',
                            text: 'Talent Pool - Create / Edit',
                        },
                        {
                            path: '/configuration/screen-layouts/jobs/talent-pool/quick-view',
                            text: 'Talent Pool - Quick View',
                        },
                        {
                            path: '/configuration/screen-layouts/jobs/talent-pool/record',
                            text: 'Talent Pool - View',
                        },
                    ],
                },
                {
                    path: '',
                    text: 'Opportunities',
                    options: [
                        {
                            path: '/configuration/screen-layouts/opportunities/back-fill/edit',
                            text: 'Back Fill - Create / Edit',
                        },
                        {
                            path: '/configuration/screen-layouts/opportunities/back-fill/quick-view',
                            text: 'Back Fill - Quick View',
                        },
                        {
                            path: '/configuration/screen-layouts/opportunities/back-fill/record',
                            text: 'Back Fill - View',
                        },
                        {
                            path: '/configuration/screen-layouts/opportunities/bid/edit',
                            text: 'Bid - Create / Edit',
                        },
                        {
                            path: '/configuration/screen-layouts/opportunities/bid/quick-view',
                            text: 'Bid - Quick View',
                        },
                        {
                            path: '/configuration/screen-layouts/opportunities/bid/record',
                            text: 'Bid - View',
                        },
                        {
                            path: '/configuration/screen-layouts/opportunities/float/edit',
                            text: 'Float - Create / Edit',
                        },
                        {
                            path: '/configuration/screen-layouts/opportunities/float/quick-view',
                            text: 'Float - Quick View',
                        },
                        {
                            path: '/configuration/screen-layouts/opportunities/float/record',
                            text: 'Float - View',
                        },
                        {
                            path: '/configuration/screen-layouts/opportunities/general/edit',
                            text: 'General - Create / Edit',
                        },
                        {
                            path: '/configuration/screen-layouts/opportunities/general/quick-view',
                            text: 'General - Quick View',
                        },
                        {
                            path: '/configuration/screen-layouts/opportunities/general/record',
                            text: 'General - View',
                        },
                        {
                            path: '/configuration/screen-layouts/opportunities/lead/edit',
                            text: 'Lead - Create / Edit',
                        },
                        {
                            path: '/configuration/screen-layouts/opportunities/lead/quick-view',
                            text: 'Lead - Quick View',
                        },
                        {
                            path: '/configuration/screen-layouts/opportunities/lead/record',
                            text: 'Lead - View',
                        },
                        {
                            path: '/configuration/screen-layouts/opportunities/tender/edit',
                            text: 'Tender - Create / Edit',
                        },
                        {
                            path: '/configuration/screen-layouts/opportunities/tender/quick-view',
                            text: 'Tender - Quick View',
                        },
                        {
                            path: '/configuration/screen-layouts/opportunities/tender/record',
                            text: 'Tender - View',
                        },
                    ],
                },
                {
                    path: '',
                    text: 'Placements',
                    options: [
                        {
                            path: '/configuration/screen-layouts/placements/contract/edit',
                            text: 'Contract - Create / Edit',
                        },
                        {
                            path: '/configuration/screen-layouts/placements/contract/quick-view',
                            text: 'Contract - Quick View',
                        },
                        {
                            path: '/configuration/screen-layouts/placements/contract/record',
                            text: 'Contract - View',
                        },
                        {
                            path: '/configuration/screen-layouts/placements/fixed-term/edit',
                            text: 'Fixed Term - Create / Edit',
                        },
                        {
                            path: '/configuration/screen-layouts/placements/fixed-term/quick-view',
                            text: 'Fixed Term - Quick View',
                        },
                        {
                            path: '/configuration/screen-layouts/placements/fixed-term/record',
                            text: 'Fixed Term - View',
                        },
                        {
                            path: '/configuration/screen-layouts/placements/margin-only/edit',
                            text: 'Margin Only - Create / Edit',
                        },
                        {
                            path: '/configuration/screen-layouts/placements/margin-only/quick-view',
                            text: 'Margin Only - Quick View',
                        },
                        {
                            path: '/configuration/screen-layouts/placements/margin-only/record',
                            text: 'Margin Only - View',
                        },
                        {
                            path: '/configuration/screen-layouts/placements/max-term/edit',
                            text: 'Maximum Term - Create / Edit',
                        },
                        {
                            path: '/configuration/screen-layouts/placements/max-term/quick-view',
                            text: 'Maximum Term - Quick View',
                        },
                        {
                            path: '/configuration/screen-layouts/placements/max-term/record',
                            text: 'Maximum Term - View',
                        },
                        {
                            path: '/configuration/screen-layouts/placements/permanent/edit',
                            text: 'Permanent - Create / Edit',
                        },
                        {
                            path: '/configuration/screen-layouts/placements/permanent/quick-view',
                            text: 'Permanent - Quick View',
                        },
                        {
                            path: '/configuration/screen-layouts/placements/permanent/record',
                            text: 'Permanent - View',
                        },
                    ],
                },
                {
                    path: '',
                    text: 'Sites',
                    options: [
                        {
                            path: '/configuration/screen-layouts/sites/edit',
                            text: 'Create / Edit',
                        },
                        {
                            path: '/configuration/screen-layouts/sites/quick-view',
                            text: 'Quick View',
                        },
                        {
                            path: '/configuration/screen-layouts/sites/record',
                            text: 'View',
                        },
                    ],
                },
            ]
        },
        {
            path: '/configuration/sites',
            text: 'Sites',
            allow: ConfigurationPermissions.AllowGeneral
        },
        {
            path: '',
            text: 'SMS',
            allow: ConfigurationPermissions.AllowSMSPurchaces,
            options: [
                {
                    path: '/configuration/sms/purchases',
                    text: 'Purchases',
                },
                {
                    path: '/configuration/sms/settings',
                    text: 'Settings',
                    allow: ConfigurationPermissions.AllowSMSSettings,
                },
            ]
        },
        {
            path: '/configuration/submissions',
            text: 'Submissions',
            allow: ConfigurationPermissions.AllowSubmissions,
        },
        {
            path: '',
            text: 'Tags',
            allow: ConfigurationPermissions.AllowTags,
            options: [
                {
                    path: '/configuration/tags/clients',
                    text: 'Clients',
                },
                {
                    path: '/configuration/tags/contacts',
                    text: 'Contacts',
                },
                {
                    path: '/configuration/tags/opportunities',
                    text: 'Opportunities',
                },
                {
                    path: '/configuration/tags/jobs',
                    text: 'Jobs',
                },
                {
                    path: '/configuration/tags/candidates',
                    text: 'Candidates',
                },
                {
                    path: '/configuration/tags/placements',
                    text: 'Placements',
                },
                {
                    path: '/configuration/tags/sites',
                    text: 'Sites',
                },
            ]
        },
        {
            path: '/configuration/users',
            text: 'Users',
            allow: ConfigurationPermissions.AllowUsers
        },
    ]
};

const TopBarRoutes: Array<TopBarRoute> = [
    {
        text: "Dashboard",
        path: "/dashboard",
        allow: () => companyHasSinglePermission(Permission.Dashboard) && userHasSinglePermission(Permission.Dashboard),
        options: [
            {
                text: 'Activity',
                path: '/dashboard/activities?rt=OpenActivityTodayPrior',
                allow: () => companyHasSinglePermission(Permission.Activities) && userHasSinglePermission(Permission.Activities),
                options: [
                    {
                        path: '/dashboard/activities?rt=OpenActivityTodayPrior',
                        text: 'Open Activity - Today + Prior',
                    },
                    {
                        path: '/dashboard/activities?rt=OpenBDCallsTodayPrior',
                        text: 'BD Calls - Today + Prior',
                    },
                    {
                        path: '/dashboard/activities?rt=OpenActivityTomorrow',
                        text: 'Open Activity - Tomorrow',
                    },
                    {
                        path: '/dashboard/activities?rt=OpenActivityNext7Days',
                        text: 'Open Activity - Next 7 Days',
                    },
                    {
                        path: '/dashboard/activities?rt=OpenActivityAll',
                        text: 'Open Activity - All',
                    },
                    {
                        path: '/dashboard/activities?rt=ClosedActivityToday',
                        text: 'Closed Activity - Today',
                    },
                    {
                        path: '/dashboard/activities?rt=ClosedActivityYesterday',
                        text: 'Closed Activity - Yesterday',
                    },
                    {
                        path: '/dashboard/activities?rt=ClosedActivityLast7Days',
                        text: 'Closed Activity - Last 7 Days',
                    },
                ]
            },
            {
                text: 'Interviews',
                path: '/interviews',
                allow: () => companyHasAllPermissions([Permission.Contacts, Permission.Jobs]) && userHasAllPermissions([Permission.Contacts, Permission.Jobs]),
                options: [
                    {
                        path: '/interviews?t=1',
                        text: 'Recently Completed',
                    },
                    {
                        path: '/interviews?t=2',
                        text: 'Upcoming',
                    },
                ]
            },
            {
                text: 'Meetings',
                path: '/meetings',
                options: [
                    {
                        path: '/meetings?t=1',
                        text: 'Recently Completed',
                    },
                    {
                        path: '/meetings?t=2',
                        text: 'Upcoming',
                    },
                ]
            },
            {
                text: 'My Scheduled Messages',
                path: '/dashboard/my-scheduled-messages',
            },
            {
                text: 'Reports',
                path: 'analytics/misc',
            },
        ]
    },
    {
        text: "Clients",
        path: "/clients",
        allow: () => AllowClientsModule(),
        options: [
            {
                path: '/clients/create',
                text: 'Create',
                allow: () => AllowClientsAddEdit(),
            },
            {
                path: '/clients/my-clients',
                text: 'My Clients',
                allow: () => companyHasSinglePermission(Permission.Clients)
            },
            {
                path: '/clients/import',
                text: 'Import Spreadsheet',
                allow: () => userHasAnyPermission([Permission.DataMaintenanceAdministrator, Permission.GlobalAdministrator])
            },
            {
                path: '/analytics/clients',
                text: 'Reports',
                allow: () => userHasSinglePermission(Permission.ClientsReports),
            },
            {
                path: '/sites',
                text: 'Sites Search',
            },
            {
                path: '/clients',
                text: 'Search',
            },
            {
                path: '/clients/tags',
                text: 'Tags',
            },
        ]
    },
    {
        text: "Contacts",
        path: "/contacts",
        allow: () => companyHasSinglePermission(Permission.Contacts) && userHasSinglePermission(Permission.Contacts),
        options: [
            {
                path: '/contacts/create',
                text: 'Create',
                allow: () => companyHasSinglePermission(Permission.ContactsAddEdit) && userHasSinglePermission(Permission.ContactsAddEdit),
            },
            {
                path: '/contacts/my-contacts',
                text: 'My Contacts',
                allow: () => companyHasSinglePermission(Permission.Contacts) && userHasSinglePermission(Permission.Contacts),
            },
            {
                path: '/contacts/import',
                text: 'Import Spreadsheet',
                allow: () => userHasAnyPermission([Permission.DataMaintenanceAdministrator, Permission.GlobalAdministrator])
            },
            {
                path: '/analytics/contacts',
                text: 'Reports',
                allow: () => userHasSinglePermission(Permission.ContactsReports),
            },
            {
                path: '/contacts',
                text: 'Search',
            },
            {
                path: '/contacts/tags',
                text: 'Tags',
            },
        ]
    },
    {
        text: 'Opportunities',
        path: '/opportunities',
        allow: () => companyHasSinglePermission(Permission.Opportunities) && userHasSinglePermission(Permission.Opportunities),
        options: [
            {
                path: '/opportunities/create',
                text: 'Create',
                allow: () => companyHasSinglePermission(Permission.OpportunitiesAddEdit) && userHasSinglePermission(Permission.OpportunitiesAddEdit),

            },
            {
                path: '/opportunities/my-opportunities',
                text: 'My Opportunities',
            },
            {
                path: '/analytics/opportunities',
                text: 'Reports',
                allow: () => userHasSinglePermission(Permission.OpportunitiesReports),
            },
            {
                path: '/opportunities',
                text: 'Search',
            },
            {
                path: '/opportunities/tags',
                text: 'Tags',
            },
        ]
    },
    {
        text: "Jobs",
        path: "/jobs",
        allow: () => companyHasSinglePermission(Permission.Jobs) && userHasSinglePermission(Permission.Jobs),
        options: [
            {
                path: '/jobs/my-jobs',
                text: 'My Jobs',
            },
            {
                text: 'Open Jobs',
                path: '/dashboard/?tab=DASHBOARD_Jobs',
                options: [
                    {
                        path: '/jobs/company-jobs',
                        text: 'All',
                    },
                    {
                        path: '/jobs/consultant-jobs',
                        text: 'Consultant',
                    },
                    {
                        path: '/dashboard/?tab=DASHBOARD_Jobs',
                        text: 'Mine',
                    },
                    {
                        path: '/jobs/team-jobs',
                        text: 'Team',
                    },
                ]
            },
            {
                path: '/analytics/jobs',
                text: 'Reports',
                allow: () => userHasSinglePermission(Permission.JobsReports),
            },
            {
                path: '/jobs',
                text: 'Search',
            },
            {
                path: '/jobs/tags',
                text: 'Tags',
            },
        ]
    },
    {
        text: "Adverts",
        path: "/adverts",
        allow: () => companyHasSinglePermission(Permission.Adverts) && userHasSinglePermission(Permission.Adverts),
        options: [
            {
                path: '/adverts/drafts',
                text: 'Drafts',
            },
            {
                path: '/adverts/my-adverts',
                text: 'My Adverts',
            },
            {
                path: '/analytics/adverts',
                text: 'Reports',
                allow: () => userHasSinglePermission(Permission.AdvertsReports),
            },
            {
                path: '/adverts',
                text: 'Search',
            },
            {
                path: '/adverts/templates',
                text: 'Templates',
            }
        ]
    },
    {
        text: "Candidates",
        path: "/candidates",
        allow: () => companyHasSinglePermission(Permission.Candidates) && userHasSinglePermission(Permission.Candidates),
        options: [
            {
                path: '/candidates/create',
                text: 'Create',
                allow: () => companyHasSinglePermission(Permission.CandidatesAddEdit) && userHasSinglePermission(Permission.CandidatesAddEdit),
            },
            {
                path: '/candidates/import',
                text: 'Import Spreadsheet',
                allow: () => userHasAnyPermission([Permission.DataMaintenanceAdministrator, Permission.GlobalAdministrator])
            },
            {
                path: '/candidates/recent-uploads',
                text: 'Recent Uploads',
            },
            {
                path: 'analytics/candidates',
                text: 'Reports',
                allow: () => userHasSinglePermission(Permission.CandidatesReports),
            },
            {
                path: '/candidates',
                text: 'Search',
            },
            {
                path: '/candidates/tags',
                text: 'Tags',
            },
            {
                path: '/candidates/upload',
                text: 'Upload CVs',
                allow: () => companyHasSinglePermission(Permission.CandidatesAddEdit) && userHasSinglePermission(Permission.CandidatesAddEdit),
            },
        ]
    },
    {
        text: "Placements",
        path: "/placements",
        allow: () => companyHasSinglePermission(Permission.Placements) && userHasSinglePermission(Permission.Placements),
        options: [
            {
                text: 'Awaiting Approval',
                path: '/placements/my-awaiting-approval',
                options: [
                    {
                        path: '/placements/awaiting-approval',
                        text: 'All',
                        allow: () => userHasSinglePermission(Permission.FinancialController),
                    },
                    {
                        path: '/placements/my-awaiting-approval',
                        text: 'Mine',
                    },
                ]
            },
            {
                text: 'Change Requests',
                path: '/placements/change-requests/search',
                options: [
                    {
                        path: '/placements/change-requests/search',
                        text: 'Search',
                        allow: () => userHasSinglePermission(Permission.FinancialController),
                    },
                    {
                        path: '/placements/change-requests/pending/mine',
                        text: 'Pending',
                        options: [
                            {
                                path: '/placements/change-requests/pending/all',
                                text: 'All',
                                allow: () => userHasSinglePermission(Permission.FinancialController),
                            },
                            {
                                path: '/placements/change-requests/pending/mine',
                                text: 'Mine',
                            },
                        ],
                    },
                ]
            },
            {
                text: 'Contractors',
                path: '/placements/my-contractors',
                options: [
                    {
                        path: '/placements/contractors',
                        text: 'All',
                    },
                    {
                        path: '/placements/my-contractors',
                        text: 'Mine',
                    },
                ]
            },
            {
                text: 'Expiring Contractors',
                path: '/placements/my-expiring-contractors',
                options: [
                    {
                        path: '/placements/expiring-contractors',
                        text: 'All',
                        allow: () => userHasSinglePermission(Permission.FinancialController),
                    },
                    {
                        path: '/placements/my-expiring-contractors',
                        text: 'Mine',
                    },
                ]
            },
            {
                path: '/placements/my-placements',
                text: 'My Placements',
            },
            {
                path: '',
                text: 'Import Spreadsheet',
                allow: () => userHasAnyPermission([Permission.DataMaintenanceAdministrator, Permission.GlobalAdministrator]),
                options: [
                    {
                        path: '/placements/import/bulk-change-requests-extensions',
                        text: 'Bulk Extensions',
                    },
                    {
                        path: '/placements/import/bulk-change-requests-rate-changes',
                        text: 'Bulk Rate Changes',
                    },
                ]
            },
            {
                text: 'Recently Expired Contractors',
                path: '/placements/my-recently-expired-contractors',
                options: [
                    {
                        path: '/placements/recently-expired-contractors',
                        text: 'All',
                        allow: () => userHasSinglePermission(Permission.FinancialController),
                    },
                    {
                        path: '/placements/my-recently-expired-contractors',
                        text: 'Mine',
                    },
                ]
            },
            {
                path: 'analytics/placements',
                text: 'Reports',
                allow: () => userHasSinglePermission(Permission.PlacementsReports),
            },
            {
                path: '/placements',
                text: 'Search',
            },
            {
                path: '/placements/tags',
                text: 'Tags',
            },
        ]
    },
/*    {
        text: "Invoices",
        path: "/invoices/all",
        allow: () => userHasSinglePermission(Permission.FinancialController),
        options: [
            {
                text: 'All',
                path: '/invoices/all',
            },
            {
                text: 'Awaiting Approval',
                path: '/invoices/awaiting-approval',
            },
            {
                text: 'Drafts',
                path: '/invoices/drafts',
            },
        ]
    },*/
    {
        text: "Analytics",
        path: "/analytics",
        options: [
            {
                text: 'Dashboards',
                path: '/analytics/dashboards/mine',
                options: [
                    {
                        text: 'Company',
                        path: '/analytics/dashboards/company'
                    },
                    {
                        text: 'Custom',
                        path: '/analytics/dashboards/custom'
                    },
                    {
                        text: 'Eden Ritchie',
                        path: '/analytics/dashboards/eden-ritchie',
                        allow: () => isEdenRitchie(),
                    },
                    {
                        text: 'KPI',
                        path: '/analytics/dashboards/kpi'
                    },
                    {
                        text: 'Mine',
                        path: '/analytics/dashboards/mine'
                    },
                ]
            },
            {
                text: 'Adverts',
                path: '/analytics/adverts',
                allow: () => userHasSinglePermission(Permission.AdvertsReports)
            },            
            {
                text: 'Candidates',
                path: '/analytics/candidates',
                allow: () => userHasSinglePermission(Permission.CandidatesReports)
            },            
            {
                text: 'Clients',
                path: '/analytics/clients',
                allow: () => userHasSinglePermission(Permission.ClientsReports)
            },
            {
                text: 'Contacts',
                path: '/analytics/contacts',
                allow: () => userHasSinglePermission(Permission.ContactsReports)
            },
            {
                text: 'Jobs',
                path: '/analytics/jobs',
                allow: () => userHasSinglePermission(Permission.JobsReports)
            },
            {
                text: 'Misc',
                path: '/analytics/misc',
                allow: () => userHasSinglePermission(Permission.MiscReports)
            },
            {
                text: 'Opportunities',
                path: '/analytics/opportunities',
                allow: () => userHasSinglePermission(Permission.OpportunitiesReports)
            },
            {
                text: 'Placements',
                path: '/analytics/placements',
                allow: () => userHasSinglePermission(Permission.PlacementsReports)
            },
        ]
    }
];

export default TopBarRoutes;
