import moment from "moment";
import { Candidate } from "common/models/Candidates/Candidate";
import { Client } from "common/models/Clients";
import { Contact } from "common/models/Contacts";
import { Job } from "common/models/Jobs/Job";
import { Opportunity } from "common/models/Opportunities";
import { UdfObjFieldMap as CustomFieldObjMap, CustomFieldType } from "common/models/ScreenLayouts/CustomFields";
import { Site } from "common/models/Site";
import { IsValidNumericValue } from "util/RegExUtils";
import { Placement } from "common/models/Placements";

export const IsValidCustomFieldValue = (value: string | number | undefined, format: "string" | "number" | "date" | "datetime", isMandatory: boolean) => {
    const hasValue = value !== "" && value !== null && value !== undefined;
    if (isMandatory && !hasValue) return "required";
    switch (format) {
        case "string": return "ok";
        case "number":
            if (hasValue && !IsValidNumericValue(value.toString())) return "invalid-number";
            break;
        case "date":
        case "datetime":
            if (hasValue && isMandatory && value === '0001-01-01T00:00:00') return "required";
            if (hasValue && value !== '0001-01-01' && value !== '0001-01-01T00:00:00') {
                const m = moment(value);
                if (!m.isValid()) return "invalid-date";
                const isInRange = m.isBetween('1753-01-01', '9999-12-31', undefined, "[]");
                if (!isInRange) return "range-date"
            }
            break;
        default: return "ok";
    }
    return "ok";
};

export const GetCustomFieldTypeById = (id: string): CustomFieldType | '' => {
    if ( id && id.startsWith('CustomField1_')) return 'CustomField1';
    if ( id && id.startsWith('CustomField2_')) return 'CustomField2';
    if ( id && id.startsWith('CustomField3_')) return 'CustomField3';
    if ( id && id.startsWith('CustomField4_')) return 'CustomField4';
    if ( id && id.startsWith('CustomField5_')) return 'CustomField5';
    if ( id && id.startsWith('CustomField6_')) return 'CustomField6';
    if ( id && id.startsWith('CustomField7_')) return 'CustomField7';
    if ( id && id.startsWith('CustomField8_')) return 'CustomField8';
    if ( id && id.startsWith('CustomField9_')) return 'CustomField9';
    if ( id && id.startsWith('CustomField10_')) return 'CustomField10';
    if ( id && id.startsWith('CustomField11_')) return 'CustomField11';
    if ( id && id.startsWith('CustomField12_')) return 'CustomField12';
    if ( id && id.startsWith('CustomField13_')) return 'CustomField13';
    if ( id && id.startsWith('CustomField14_')) return 'CustomField14';
    if ( id && id.startsWith('CustomField15_')) return 'CustomField15';
    if ( id && id.startsWith('CustomField16_')) return 'CustomField16';
    if ( id && id.startsWith('CustomField17_')) return 'CustomField17';
    if ( id && id.startsWith('CustomField18_')) return 'CustomField18';
    if ( id && id.startsWith('CustomField19_')) return 'CustomField19';
    if ( id && id.startsWith('CustomField20_')) return 'CustomField20';
    if ( id && id.startsWith('CustomField21_')) return 'CustomField21';
    if ( id && id.startsWith('CustomField22_')) return 'CustomField22';
    if ( id && id.startsWith('CustomField23_')) return 'CustomField23';
    if ( id && id.startsWith('CustomField24_')) return 'CustomField24';
    if ( id && id.startsWith('CustomField25_')) return 'CustomField25';
    if ( id && id.startsWith('CustomField26_')) return 'CustomField26';
    if ( id && id.startsWith('CustomField27_')) return 'CustomField27';
    if ( id && id.startsWith('CustomField28_')) return 'CustomField28';
    if ( id && id.startsWith('CustomField29_')) return 'CustomField29';
    if ( id && id.startsWith('CustomField30_')) return 'CustomField30';
    if ( id && id.startsWith('CustomField31_')) return 'CustomField31';
    if ( id && id.startsWith('CustomField32_')) return 'CustomField32';
    if ( id && id.startsWith('CustomField33_')) return 'CustomField33';
    if ( id && id.startsWith('CustomField34_')) return 'CustomField34';
    if ( id && id.startsWith('CustomField35_')) return 'CustomField35';
    if ( id && id.startsWith('CustomField36_')) return 'CustomField36';
    if ( id && id.startsWith('CustomField37_')) return 'CustomField37';
    if ( id && id.startsWith('CustomField38_')) return 'CustomField38';
    if ( id && id.startsWith('CustomField39_')) return 'CustomField39';
    if ( id && id.startsWith('CustomField40_')) return 'CustomField40';
    if ( id && id.startsWith('CustomField41_')) return 'CustomField41';
    if ( id && id.startsWith('CustomField42_')) return 'CustomField42';
    if ( id && id.startsWith('CustomField43_')) return 'CustomField43';
    if ( id && id.startsWith('CustomField44_')) return 'CustomField44';
    if ( id && id.startsWith('CustomField45_')) return 'CustomField45';
    if ( id && id.startsWith('CustomField46_')) return 'CustomField46';
    if ( id && id.startsWith('CustomField47_')) return 'CustomField47';
    if ( id && id.startsWith('CustomField48_')) return 'CustomField48';
    if ( id && id.startsWith('CustomField49_')) return 'CustomField49';
    if ( id && id.startsWith('CustomField50_')) return 'CustomField50';
    if ( id && id.startsWith('CustomField51_')) return 'CustomField51';
    if ( id && id.startsWith('CustomField52_')) return 'CustomField52';
    if ( id && id.startsWith('CustomField53_')) return 'CustomField53';
    if ( id && id.startsWith('CustomField54_')) return 'CustomField54';
    if ( id && id.startsWith('CustomField55_')) return 'CustomField55';
    if ( id && id.startsWith('CustomField56_')) return 'CustomField56';
    if ( id && id.startsWith('CustomField57_')) return 'CustomField57';
    if ( id && id.startsWith('CustomField58_')) return 'CustomField58';
    if ( id && id.startsWith('CustomField59_')) return 'CustomField59';
    if ( id && id.startsWith('CustomField60_')) return 'CustomField60';
    return '';
}

export const UdfClientFieldMapObj: CustomFieldObjMap<Client> = {
    CustomField1: { field: 'customField1', format: 'string' },
    CustomField2: { field: 'customField2', format: 'string' },
    CustomField3: { field: 'customField3', format: 'string' },
    CustomField4: { field: 'customField4', format: 'string' },
    CustomField5: { field: 'customField5', format: 'string' },
    CustomField6: { field: 'customField6', format: 'string' },
    CustomField7: { field: 'customField7', format: 'string' },
    CustomField8: { field: 'customField8', format: 'string' },
    CustomField9: { field: 'customField9', format: 'string' },
    CustomField10: { field: 'customField10', format: 'string' },
    CustomField11: { field: 'customField11', format: 'number'},
    CustomField12: { field: 'customField12', format: 'number'},
    CustomField13: { field: 'customField13', format: 'number'},
    CustomField14: { field: 'customField14', format: 'number'},
    CustomField15: { field: 'customField15', format: 'number'},
    CustomField16: { field: 'customField16', format: 'date'},
    CustomField17: { field: 'customField17', format: 'date'},
    CustomField18: { field: 'customField18', format: 'date'},
    CustomField19: { field: 'customField19', format: 'date'},
    CustomField20: { field: 'customField20', format: 'date'},
    CustomField21: { field: 'customField21', format: 'string'},
    CustomField22: { field: 'customField22', format: 'string'},
    CustomField23: { field: 'customField23', format: 'string'},
    CustomField24: { field: 'customField24', format: 'string'},
    CustomField25: { field: 'customField25', format: 'string'},
    CustomField26: { field: 'customField26', format: 'string'},
    CustomField27: { field: 'customField27', format: 'string'},
    CustomField28: { field: 'customField28', format: 'string'},
    CustomField29: { field: 'customField29', format: 'string'},
    CustomField30: { field: 'customField30', format: 'string'},
    CustomField31: { field: 'customField31', format: 'string'},
    CustomField32: { field: 'customField32', format: 'string'},
    CustomField33: { field: 'customField33', format: 'string'},
    CustomField34: { field: 'customField34', format: 'string'},
    CustomField35: { field: 'customField35', format: 'string'},
    CustomField36: { field: 'customField36', format: 'string'},
    CustomField37: { field: 'customField37', format: 'string'},
    CustomField38: { field: 'customField38', format: 'string'},
    CustomField39: { field: 'customField39', format: 'string'},
    CustomField40: { field: 'customField40', format: 'string'},
    CustomField41: { field: 'customField41', format: 'number'},
    CustomField42: { field: 'customField42', format: 'number'},
    CustomField43: { field: 'customField43', format: 'number'},
    CustomField44: { field: 'customField44', format: 'number'},
    CustomField45: { field: 'customField45', format: 'number'},
    CustomField46: { field: 'customField46', format: 'number'},
    CustomField47: { field: 'customField47', format: 'number'},
    CustomField48: { field: 'customField48', format: 'number'},
    CustomField49: { field: 'customField49', format: 'number'},
    CustomField50: { field: 'customField50', format: 'number'},
    CustomField51: { field: 'customField51', format: 'date'},
    CustomField52: { field: 'customField52', format: 'date'},
    CustomField53: { field: 'customField53', format: 'date'},
    CustomField54: { field: 'customField54', format: 'date'},
    CustomField55: { field: 'customField55', format: 'date'},
    CustomField56: { field: 'customField56', format: 'date'},
    CustomField57: { field: 'customField57', format: 'date'},
    CustomField58: { field: 'customField58', format: 'date'},
    CustomField59: { field: 'customField59', format: 'date'},
    CustomField60: { field: 'customField60', format: 'date'},
};

export const UdfContactFieldMapObj: CustomFieldObjMap<Contact> = {
    CustomField1: { field: 'customField1', format: 'string' },
    CustomField2: { field: 'customField2', format: 'string' },
    CustomField3: { field: 'customField3', format: 'string' },
    CustomField4: { field: 'customField4', format: 'string' },
    CustomField5: { field: 'customField5', format: 'string' },
    CustomField6: { field: 'customField6', format: 'string' },
    CustomField7: { field: 'customField7', format: 'string' },
    CustomField8: { field: 'customField8', format: 'string' },
    CustomField9: { field: 'customField9', format: 'string' },
    CustomField10: { field: 'customField10', format: 'string' },
    CustomField11: { field: 'customField11', format: 'number'},
    CustomField12: { field: 'customField12', format: 'number'},
    CustomField13: { field: 'customField13', format: 'number'},
    CustomField14: { field: 'customField14', format: 'number'},
    CustomField15: { field: 'customField15', format: 'number'},
    CustomField16: { field: 'customField16', format: 'date'},
    CustomField17: { field: 'customField17', format: 'date'},
    CustomField18: { field: 'customField18', format: 'date'},
    CustomField19: { field: 'customField19', format: 'date'},
    CustomField20: { field: 'customField20', format: 'date'},
    CustomField21: { field: 'customField21', format: 'string'},
    CustomField22: { field: 'customField22', format: 'string'},
    CustomField23: { field: 'customField23', format: 'string'},
    CustomField24: { field: 'customField24', format: 'string'},
    CustomField25: { field: 'customField25', format: 'string'},
    CustomField26: { field: 'customField26', format: 'string'},
    CustomField27: { field: 'customField27', format: 'string'},
    CustomField28: { field: 'customField28', format: 'string'},
    CustomField29: { field: 'customField29', format: 'string'},
    CustomField30: { field: 'customField30', format: 'string'},
    CustomField31: { field: 'customField31', format: 'string'},
    CustomField32: { field: 'customField32', format: 'string'},
    CustomField33: { field: 'customField33', format: 'string'},
    CustomField34: { field: 'customField34', format: 'string'},
    CustomField35: { field: 'customField35', format: 'string'},
    CustomField36: { field: 'customField36', format: 'string'},
    CustomField37: { field: 'customField37', format: 'string'},
    CustomField38: { field: 'customField38', format: 'string'},
    CustomField39: { field: 'customField39', format: 'string'},
    CustomField40: { field: 'customField40', format: 'string'},
    CustomField41: { field: 'customField41', format: 'number'},
    CustomField42: { field: 'customField42', format: 'number'},
    CustomField43: { field: 'customField43', format: 'number'},
    CustomField44: { field: 'customField44', format: 'number'},
    CustomField45: { field: 'customField45', format: 'number'},
    CustomField46: { field: 'customField46', format: 'number'},
    CustomField47: { field: 'customField47', format: 'number'},
    CustomField48: { field: 'customField48', format: 'number'},
    CustomField49: { field: 'customField49', format: 'number'},
    CustomField50: { field: 'customField50', format: 'number'},
    CustomField51: { field: 'customField51', format: 'date'},
    CustomField52: { field: 'customField52', format: 'date'},
    CustomField53: { field: 'customField53', format: 'date'},
    CustomField54: { field: 'customField54', format: 'date'},
    CustomField55: { field: 'customField55', format: 'date'},
    CustomField56: { field: 'customField56', format: 'date'},
    CustomField57: { field: 'customField57', format: 'date'},
    CustomField58: { field: 'customField58', format: 'date'},
    CustomField59: { field: 'customField59', format: 'date'},
    CustomField60: { field: 'customField60', format: 'date'},
};

export const UdfCandidateFieldMapObj: CustomFieldObjMap<Candidate> = {
    CustomField1: { field: 'customField1', format: 'string' },
    CustomField2: { field: 'customField2', format: 'string' },
    CustomField3: { field: 'customField3', format: 'string' },
    CustomField4: { field: 'customField4', format: 'string' },
    CustomField5: { field: 'customField5', format: 'string' },
    CustomField6: { field: 'customField6', format: 'string' },
    CustomField7: { field: 'customField7', format: 'string' },
    CustomField8: { field: 'customField8', format: 'string' },
    CustomField9: { field: 'customField9', format: 'string' },
    CustomField10: { field: 'customField10', format: 'string' },
    CustomField11: { field: 'customField11', format: 'string'},
    CustomField12: { field: 'customField12', format: 'string'},
    CustomField13: { field: 'customField13', format: 'string'},
    CustomField14: { field: 'customField14', format: 'string'},
    CustomField15: { field: 'customField15', format: 'string'},
    CustomField16: { field: 'customField16', format: 'string'},
    CustomField17: { field: 'customField17', format: 'string'},
    CustomField18: { field: 'customField18', format: 'string'},
    CustomField19: { field: 'customField19', format: 'string'},
    CustomField20: { field: 'customField20', format: 'string'},
    CustomField21: { field: 'customField21', format: 'string'},
    CustomField22: { field: 'customField22', format: 'string'},
    CustomField23: { field: 'customField23', format: 'string'},
    CustomField24: { field: 'customField24', format: 'string'},
    CustomField25: { field: 'customField25', format: 'string'},
    CustomField26: { field: 'customField26', format: 'string'},
    CustomField27: { field: 'customField27', format: 'string'},
    CustomField28: { field: 'customField28', format: 'string'},
    CustomField29: { field: 'customField29', format: 'string'},
    CustomField30: { field: 'customField30', format: 'string'},
    CustomField31: { field: 'customField31', format: 'date'},
    CustomField32: { field: 'customField32', format: 'date'},
    CustomField33: { field: 'customField33', format: 'date'},
    CustomField34: { field: 'customField34', format: 'date'},
    CustomField35: { field: 'customField35', format: 'date'},
    CustomField36: { field: 'customField36', format: 'date'},
    CustomField37: { field: 'customField37', format: 'date'},
    CustomField38: { field: 'customField38', format: 'date'},
    CustomField39: { field: 'customField39', format: 'date'},
    CustomField40: { field: 'customField40', format: 'date'},
    CustomField41: { field: 'customField41', format: 'date'},
    CustomField42: { field: 'customField42', format: 'date'},
    CustomField43: { field: 'customField43', format: 'date'},
    CustomField44: { field: 'customField44', format: 'date'},
    CustomField45: { field: 'customField45', format: 'date'},
    CustomField46: { field: 'customField46', format: 'number'},
    CustomField47: { field: 'customField47', format: 'number'},
    CustomField48: { field: 'customField48', format: 'number'},
    CustomField49: { field: 'customField49', format: 'number'},
    CustomField50: { field: 'customField50', format: 'number'},
    CustomField51: { field: 'customField51', format: 'number'},
    CustomField52: { field: 'customField52', format: 'number'},
    CustomField53: { field: 'customField53', format: 'number'},
    CustomField54: { field: 'customField54', format: 'number'},
    CustomField55: { field: 'customField55', format: 'number'},
    CustomField56: { field: 'customField56', format: 'number'},
    CustomField57: { field: 'customField57', format: 'number'},
    CustomField58: { field: 'customField58', format: 'number'},
    CustomField59: { field: 'customField59', format: 'number'},
    CustomField60: { field: 'customField60', format: 'number'},
};

export const UdfJobFieldMapObj: CustomFieldObjMap<Job> = {
    CustomField1: { field: 'customField1', format: 'string' },
    CustomField2: { field: 'customField2', format: 'string' },
    CustomField3: { field: 'customField3', format: 'string' },
    CustomField4: { field: 'customField4', format: 'string' },
    CustomField5: { field: 'customField5', format: 'string' },
    CustomField6: { field: 'customField6', format: 'string' },
    CustomField7: { field: 'customField7', format: 'string' },
    CustomField8: { field: 'customField8', format: 'string' },
    CustomField9: { field: 'customField9', format: 'string' },
    CustomField10: { field: 'customField10', format: 'string' },
    CustomField11: { field: 'customField11', format: 'number' },
    CustomField12: { field: 'customField12', format: 'number' },
    CustomField13: { field: 'customField13', format: 'number' },
    CustomField14: { field: 'customField14', format: 'number' },
    CustomField15: { field: 'customField15', format: 'number' },
    CustomField16: { field: 'customField16', format: 'date' },
    CustomField17: { field: 'customField17', format: 'date' },
    CustomField18: { field: 'customField18', format: 'date' },
    CustomField19: { field: 'customField19', format: 'date' },
    CustomField20: { field: 'customField20', format: 'date' },
    CustomField21: { field: 'customField21', format: 'string' },
    CustomField22: { field: 'customField22', format: 'string' },
    CustomField23: { field: 'customField23', format: 'string' },
    CustomField24: { field: 'customField24', format: 'string' },
    CustomField25: { field: 'customField25', format: 'string' },
    CustomField26: { field: 'customField26', format: 'string' },
    CustomField27: { field: 'customField27', format: 'string' },
    CustomField28: { field: 'customField28', format: 'string' },
    CustomField29: { field: 'customField29', format: 'string' },
    CustomField30: { field: 'customField30', format: 'string' },
    CustomField31: { field: 'customField31', format: 'number' },
    CustomField32: { field: 'customField32', format: 'number' },
    CustomField33: { field: 'customField33', format: 'number' },
    CustomField34: { field: 'customField34', format: 'number' },
    CustomField35: { field: 'customField35', format: 'number' },
    CustomField36: { field: 'customField36', format: 'date' },
    CustomField37: { field: 'customField37', format: 'date' },
    CustomField38: { field: 'customField38', format: 'date' },
    CustomField39: { field: 'customField39', format: 'date' },
    CustomField40: { field: 'customField40', format: 'date' },
    CustomField41: { field: 'customField41', format: 'string' },
    CustomField42: { field: 'customField42', format: 'string' },
    CustomField43: { field: 'customField43', format: 'string' },
    CustomField44: { field: 'customField44', format: 'string' },
    CustomField45: { field: 'customField45', format: 'string' },
    CustomField46: { field: 'customField46', format: 'string' },
    CustomField47: { field: 'customField47', format: 'string' },
    CustomField48: { field: 'customField48', format: 'string' },
    CustomField49: { field: 'customField49', format: 'string' },
    CustomField50: { field: 'customField50', format: 'string' },
    CustomField51: { field: 'customField51', format: 'number' },
    CustomField52: { field: 'customField52', format: 'number' },
    CustomField53: { field: 'customField53', format: 'number' },
    CustomField54: { field: 'customField54', format: 'number' },
    CustomField55: { field: 'customField55', format: 'number' },
    CustomField56: { field: 'customField56', format: 'date' },
    CustomField57: { field: 'customField57', format: 'date' },
    CustomField58: { field: 'customField58', format: 'date' },
    CustomField59: { field: 'customField59', format: 'date' },
    CustomField60: { field: 'customField60', format: 'date' },
};

export const UdfPlacementFieldMapObj: CustomFieldObjMap<Placement> = {
    CustomField1: { field: 'customField1', format: 'string' },
    CustomField2: { field: 'customField2', format: 'string' },
    CustomField3: { field: 'customField3', format: 'string' },
    CustomField4: { field: 'customField4', format: 'string' },
    CustomField5: { field: 'customField5', format: 'string' },
    CustomField6: { field: 'customField6', format: 'string' },
    CustomField7: { field: 'customField7', format: 'string' },
    CustomField8: { field: 'customField8', format: 'string' },
    CustomField9: { field: 'customField9', format: 'string' },
    CustomField10: { field: 'customField10', format: 'string' },
    CustomField11: { field: 'customField11', format: 'number' },
    CustomField12: { field: 'customField12', format: 'number' },
    CustomField13: { field: 'customField13', format: 'number' },
    CustomField14: { field: 'customField14', format: 'number' },
    CustomField15: { field: 'customField15', format: 'number' },
    CustomField16: { field: 'customField16', format: 'date' },
    CustomField17: { field: 'customField17', format: 'date' },
    CustomField18: { field: 'customField18', format: 'date' },
    CustomField19: { field: 'customField19', format: 'date' },
    CustomField20: { field: 'customField20', format: 'date' },
    CustomField21: { field: 'customField21', format: 'string' },
    CustomField22: { field: 'customField22', format: 'string' },
    CustomField23: { field: 'customField23', format: 'string' },
    CustomField24: { field: 'customField24', format: 'string' },
    CustomField25: { field: 'customField25', format: 'string' },
    CustomField26: { field: 'customField26', format: 'string' },
    CustomField27: { field: 'customField27', format: 'string' },
    CustomField28: { field: 'customField28', format: 'string' },
    CustomField29: { field: 'customField29', format: 'string' },
    CustomField30: { field: 'customField30', format: 'string' },
    CustomField31: { field: 'customField31', format: 'string' },
    CustomField32: { field: 'customField32', format: 'string' },
    CustomField33: { field: 'customField33', format: 'string' },
    CustomField34: { field: 'customField34', format: 'string' },
    CustomField35: { field: 'customField35', format: 'string' },
    CustomField36: { field: 'customField36', format: 'string' },
    CustomField37: { field: 'customField37', format: 'string' },
    CustomField38: { field: 'customField38', format: 'string' },
    CustomField39: { field: 'customField39', format: 'string' },
    CustomField40: { field: 'customField40', format: 'string' },
    CustomField41: { field: 'customField41', format: 'number' },
    CustomField42: { field: 'customField42', format: 'number' },
    CustomField43: { field: 'customField43', format: 'number' },
    CustomField44: { field: 'customField44', format: 'number' },
    CustomField45: { field: 'customField45', format: 'number' },
    CustomField46: { field: 'customField46', format: 'number' },
    CustomField47: { field: 'customField47', format: 'number' },
    CustomField48: { field: 'customField48', format: 'number' },
    CustomField49: { field: 'customField49', format: 'number' },
    CustomField50: { field: 'customField50', format: 'number' },
    CustomField51: { field: 'customField51', format: 'date' },
    CustomField52: { field: 'customField52', format: 'date' },
    CustomField53: { field: 'customField53', format: 'date' },
    CustomField54: { field: 'customField54', format: 'date' },
    CustomField55: { field: 'customField55', format: 'date' },
    CustomField56: { field: 'customField56', format: 'date' },
    CustomField57: { field: 'customField57', format: 'date' },
    CustomField58: { field: 'customField58', format: 'date' },
    CustomField59: { field: 'customField59', format: 'date' },
    CustomField60: { field: 'customField60', format: 'date' },
};

export const UdfOpportunityFieldMapObj: CustomFieldObjMap<Opportunity> = {
    CustomField1: { field: 'customField1', format: 'string' },
    CustomField2: { field: 'customField2', format: 'string' },
    CustomField3: { field: 'customField3', format: 'string' },
    CustomField4: { field: 'customField4', format: 'string' },
    CustomField5: { field: 'customField5', format: 'string' },
    CustomField6: { field: 'customField6', format: 'string' },
    CustomField7: { field: 'customField7', format: 'string' },
    CustomField8: { field: 'customField8', format: 'string' },
    CustomField9: { field: 'customField9', format: 'string' },
    CustomField10: { field: 'customField10', format: 'string' },
    CustomField11: { field: 'customField11', format: 'number'},
    CustomField12: { field: 'customField12', format: 'number'},
    CustomField13: { field: 'customField13', format: 'number'},
    CustomField14: { field: 'customField14', format: 'number'},
    CustomField15: { field: 'customField15', format: 'number'},
    CustomField16: { field: 'customField16', format: 'date'},
    CustomField17: { field: 'customField17', format: 'date'},
    CustomField18: { field: 'customField18', format: 'date'},
    CustomField19: { field: 'customField19', format: 'date'},
    CustomField20: { field: 'customField20', format: 'date'},
    CustomField21: { field: 'customField21', format: 'string'},
    CustomField22: { field: 'customField22', format: 'string'},
    CustomField23: { field: 'customField23', format: 'string'},
    CustomField24: { field: 'customField24', format: 'string'},
    CustomField25: { field: 'customField25', format: 'string'},
    CustomField26: { field: 'customField26', format: 'string'},
    CustomField27: { field: 'customField27', format: 'string'},
    CustomField28: { field: 'customField28', format: 'string'},
    CustomField29: { field: 'customField29', format: 'string'},
    CustomField30: { field: 'customField30', format: 'string'},
    CustomField31: { field: 'customField31', format: 'number'},
    CustomField32: { field: 'customField32', format: 'number'},
    CustomField33: { field: 'customField33', format: 'number'},
    CustomField34: { field: 'customField34', format: 'number'},
    CustomField35: { field: 'customField35', format: 'number'},
    CustomField36: { field: 'customField36', format: 'date'},
    CustomField37: { field: 'customField37', format: 'date'},
    CustomField38: { field: 'customField38', format: 'date'},
    CustomField39: { field: 'customField39', format: 'date'},
    CustomField40: { field: 'customField40', format: 'date'},
    CustomField41: { field: 'customField41', format: 'string'},
    CustomField42: { field: 'customField42', format: 'string'},
    CustomField43: { field: 'customField43', format: 'string'},
    CustomField44: { field: 'customField44', format: 'string'},
    CustomField45: { field: 'customField45', format: 'string'},
    CustomField46: { field: 'customField46', format: 'string'},
    CustomField47: { field: 'customField47', format: 'string'},
    CustomField48: { field: 'customField48', format: 'string'},
    CustomField49: { field: 'customField49', format: 'string'},
    CustomField50: { field: 'customField50', format: 'string'},
    CustomField51: { field: 'customField51', format: 'number'},
    CustomField52: { field: 'customField52', format: 'number'},
    CustomField53: { field: 'customField53', format: 'number'},
    CustomField54: { field: 'customField54', format: 'number'},
    CustomField55: { field: 'customField55', format: 'number'},
    CustomField56: { field: 'customField56', format: 'date'},
    CustomField57: { field: 'customField57', format: 'date'},
    CustomField58: { field: 'customField58', format: 'date'},
    CustomField59: { field: 'customField59', format: 'date'},
    CustomField60: { field: 'customField60', format: 'date'},
};

export const UdfSiteFieldMapObj: CustomFieldObjMap<Site> = {
    CustomField1: { field: 'customField1', format: 'string' },
    CustomField2: { field: 'customField2', format: 'string' },
    CustomField3: { field: 'customField3', format: 'string' },
    CustomField4: { field: 'customField4', format: 'string' },
    CustomField5: { field: 'customField5', format: 'string' },
    CustomField6: { field: 'customField6', format: 'string' },
    CustomField7: { field: 'customField7', format: 'string' },
    CustomField8: { field: 'customField8', format: 'string' },
    CustomField9: { field: 'customField9', format: 'string' },
    CustomField10: { field: 'customField10', format: 'string' },
    CustomField11: { field: 'customField11', format: 'number'},
    CustomField12: { field: 'customField12', format: 'number'},
    CustomField13: { field: 'customField13', format: 'number'},
    CustomField14: { field: 'customField14', format: 'number'},
    CustomField15: { field: 'customField15', format: 'number'},
    CustomField16: { field: 'customField16', format: 'date'},
    CustomField17: { field: 'customField17', format: 'date'},
    CustomField18: { field: 'customField18', format: 'date'},
    CustomField19: { field: 'customField19', format: 'date'},
    CustomField20: { field: 'customField20', format: 'date'},
    CustomField21: { field: 'customField21', format: 'string'},
    CustomField22: { field: 'customField22', format: 'string'},
    CustomField23: { field: 'customField23', format: 'string'},
    CustomField24: { field: 'customField24', format: 'string'},
    CustomField25: { field: 'customField25', format: 'string'},
    CustomField26: { field: 'customField26', format: 'string'},
    CustomField27: { field: 'customField27', format: 'string'},
    CustomField28: { field: 'customField28', format: 'string'},
    CustomField29: { field: 'customField29', format: 'string'},
    CustomField30: { field: 'customField30', format: 'string'},
    CustomField31: { field: 'customField31', format: 'number'},
    CustomField32: { field: 'customField32', format: 'number'},
    CustomField33: { field: 'customField33', format: 'number'},
    CustomField34: { field: 'customField34', format: 'number'},
    CustomField35: { field: 'customField35', format: 'number'},
    CustomField36: { field: 'customField36', format: 'date'},
    CustomField37: { field: 'customField37', format: 'date'},
    CustomField38: { field: 'customField38', format: 'date'},
    CustomField39: { field: 'customField39', format: 'date'},
    CustomField40: { field: 'customField40', format: 'date'},
    CustomField41: { field: 'customField41', format: 'string'},
    CustomField42: { field: 'customField42', format: 'string'},
    CustomField43: { field: 'customField43', format: 'string'},
    CustomField44: { field: 'customField44', format: 'string'},
    CustomField45: { field: 'customField45', format: 'string'},
    CustomField46: { field: 'customField46', format: 'string'},
    CustomField47: { field: 'customField47', format: 'string'},
    CustomField48: { field: 'customField48', format: 'string'},
    CustomField49: { field: 'customField49', format: 'string'},
    CustomField50: { field: 'customField50', format: 'string'},
    CustomField51: { field: 'customField51', format: 'number'},
    CustomField52: { field: 'customField52', format: 'number'},
    CustomField53: { field: 'customField53', format: 'number'},
    CustomField54: { field: 'customField54', format: 'number'},
    CustomField55: { field: 'customField55', format: 'number'},
    CustomField56: { field: 'customField56', format: 'date'},
    CustomField57: { field: 'customField57', format: 'date'},
    CustomField58: { field: 'customField58', format: 'date'},
    CustomField59: { field: 'customField59', format: 'date'},
    CustomField60: { field: 'customField60', format: 'date'},
};