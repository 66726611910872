import Box from "@mui/material/Box";
import { JXTArea, JXTCountry, JXTLocation, JXTProfession, JXTRole, JXTWorkTypeOption } from "common/models/JobPosting/JXT";
import React, { useEffect, useMemo, useState } from "react";
import { GetJXTBoardSetupData } from "services/JobBoardsService";
import JobBoardViewField from "./JobBoardViewField";

interface Props {
    jobBoardId: number,
    advertId: number,
    attributes?: Record<string, string>,
    advertRecordAttributes?: Record<string, string>,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
}

const binaryOptionsMap: Record<string, string> = {
    "on": 'Yes',
    "off": 'No',
};

export default function JXTView({ jobBoardId, advertId, attributes, advertRecordAttributes, loadingHandler, errorHandler }: Props) {
    const [professions, setProfessions] = useState<JXTProfession[]>([]);
    const [professionToRolesMap, setProfessionToRolesMap] = useState<Record<string, JXTRole[]>>({});
    const [countries, setCountries] = useState<JXTCountry[]>([]);
    const [countryToLocationsMap, setCountryToLocationsMap] = useState<Record<string, JXTLocation[]>>({});
    const [locationToAreasMap, setLocationToAreasMap] = useState<Record<string, JXTArea[]>>({});
    const [workTypes, setWorkTypes] = useState<JXTWorkTypeOption[]>([]);

    useEffect(() => {
        const getSetupData = async () => {
            loadingHandler && loadingHandler(true);
            const res = await GetJXTBoardSetupData(jobBoardId, advertId, errorHandler);
            if (res) {
                if (res.professions && res.professions.length > 0) setProfessions(res.professions);

                let p2rMap: Record<string, JXTRole[]> = {};
                for (let i = 0; i < res.roles.length; i++) {
                    const r = res.roles[i];
                    if (p2rMap[r.professionRole]) p2rMap[r.professionRole].push(r);
                    else p2rMap[r.professionRole] = [r];
                }

                if (res.countries && res.countries.length > 0) setCountries(res.countries);
                
                let c2lMap: Record<string, JXTLocation[]> = {};
                for (let i = 0; i < res.locations.length; i++) {
                    const l = res.locations[i];
                    if (c2lMap[l.countryLocation]) c2lMap[l.countryLocation].push(l);
                    else c2lMap[l.countryLocation] = [l];
                }
                
                let l2aMap: Record<string, JXTArea[]> = {};
                for (let i = 0; i < res.areas.length; i++) {
                    const a = res.areas[i];
                    if (l2aMap[a.locationArea]) l2aMap[a.locationArea].push(a);
                    else l2aMap[a.locationArea] = [a];
                }

                if (res.worktypes && res.worktypes.length > 0) setWorkTypes(res.worktypes);

                setProfessionToRolesMap(p2rMap);
                setCountryToLocationsMap(c2lMap);
                setLocationToAreasMap(l2aMap);
            }
            loadingHandler && loadingHandler(false);
        };
        getSetupData();
    }, [advertId, errorHandler, jobBoardId, loadingHandler]);

    const atts = useMemo<Record<string, string>>(() => {
        if (attributes) return attributes;
        return {};
    }, [attributes]);

    const recordAtts = useMemo<Record<string, string>>(() => {
        if (advertRecordAttributes) return advertRecordAttributes;
        return {};
    }, [advertRecordAttributes]);

    const profession1 = useMemo(() => atts.Profession1 ?? '', [atts.Profession1]);
    const profession2 = useMemo(() => atts.Profession2 ?? '', [atts.Profession2]);
    const profession3 = useMemo(() => atts.Profession3 ?? '', [atts.Profession3]);
    const role1 = useMemo(() => atts.Role1 ?? '', [atts.Role1]);
    const role2 = useMemo(() => atts.Role2 ?? '', [atts.Role2]);
    const role3 = useMemo(() => atts.Role3 ?? '', [atts.Role3]);
    const country = useMemo(() => atts.Country ?? '', [atts.Country]);
    const location = useMemo(() => atts.Location ?? '', [atts.Location]);
    const area = useMemo(() => atts.Area ?? '', [atts.Area]);
    const workType = useMemo(() => atts.WorkType ?? '', [atts.WorkType]);
    const displaySalary = useMemo(() => atts.DisplaySalary ?? 'off', [atts.DisplaySalary]);
    const residentsOnly = useMemo(() => atts.ResidentsOnly ?? 'off', [atts.ResidentsOnly]);
    const jobTemplate = useMemo(() => atts.JobTemplate ?? '', [atts.JobTemplate]);
    const sector = useMemo(() => atts.Sector ?? '', [atts.Sector]);

    const salaryType = useMemo(() => recordAtts.salaryType ?? '', [recordAtts.salaryType]);
    const salaryFrom = useMemo(() => recordAtts.salaryFrom ?? '', [recordAtts.salaryFrom]);
    const salaryTo = useMemo(() => recordAtts.salaryTo ?? '', [recordAtts.salaryTo]);

    const professionsMap = useMemo<Record<string, string>>(() => {
        let m: Record<string, string> = {};
        for (let i = 0; i < professions.length; i++) {
            const p = professions[i];
            m[p.id] = p.name;
        }
        return m;
    }, [professions]);

    const role1Name = useMemo(() => {
        if (profession1) {
            const opts = professionToRolesMap[profession1];
            if (opts) {
                const r = opts.find(o => `R|${o.id}` === role1);
                if (r) return r.name;
            }
        }
        return '';
    }, [profession1, professionToRolesMap, role1]);

    const role2Name = useMemo(() => {
        if (profession2) {
            const opts = professionToRolesMap[profession2];
            if (opts) {
                const r = opts.find(o => `R|${o.id}` === role2);
                if (r) return r.name;
            }
        }
        return '';
    }, [profession2, professionToRolesMap, role2]);

    const role3Name = useMemo(() => {
        if (profession3) {
            const opts = professionToRolesMap[profession3];
            if (opts) {
                const r = opts.find(o => `R|${o.id}` === role3);
                if (r) return r.name;
            }
        }
        return '';
    }, [profession3, professionToRolesMap, role3]);

    const countryName = useMemo(() => {
        if (country) {
            const c = countries.find(v => `C|${v.id}` === country);
            if (c) return c.name;
        }
        return '';
    }, [countries, country]);

    const locationName = useMemo(() => {
        if (country) {
            const opts = countryToLocationsMap[country];
            if (opts) {
                const l = opts.find(o => `L|${o.id}` === location);
                if (l) return l.name;
            }
        }
        return '';
    }, [country, countryToLocationsMap, location]);

    const areaName = useMemo(() => {
        if (location) {
            const opts = locationToAreasMap[location];
            if (opts) {
                const a = opts.find(o => `A|${o.id}` === area);
                if (a) return a.name;
            }
        }
        return '';
    }, [area, location, locationToAreasMap]);

    const workTypeName = useMemo(() => {
        if (workType) {
            const w = workTypes.find(v => v.id === workType);
            if (w) return w.name;
        }
        return '';
    }, [workType, workTypes]);

    return (
        <Box display="flex" gap={2} pt={2}>
            <Box display="flex" flexDirection="column" gap={2} width="50%">
                <JobBoardViewField label="Profession 1" value={professionsMap[profession1] ?? ''} />
                <JobBoardViewField label="Role 1" value={role1Name} />
                <JobBoardViewField label="Profession 2" value={professionsMap[profession2] ?? ''} />
                <JobBoardViewField label="Role 2" value={role2Name} />
                <JobBoardViewField label="Profession 3" value={professionsMap[profession3] ?? ''} />
                <JobBoardViewField label="Role 3" value={role3Name} />
                <JobBoardViewField label="Country" value={countryName} />
                <JobBoardViewField label="Location" value={locationName} />
                <JobBoardViewField label="Area" value={areaName} />
            </Box>
            <Box display="flex" flexDirection="column" gap={2} width="50%">
                <JobBoardViewField label="Work Type" value={workTypeName} />
                <JobBoardViewField label="Salary Type" value={salaryType} />
                <Box display="flex" gap={2}>
                    <JobBoardViewField label="Salary From" sx={{ flex: '1 1 0' }} value={salaryFrom} />
                    <JobBoardViewField label="Salary To" sx={{ flex: '1 1 0' }} value={salaryTo} />
                </Box>
                <JobBoardViewField label="Display Salary" value={binaryOptionsMap[displaySalary] ?? 'No'} />
                <JobBoardViewField label="Residents Only" value={binaryOptionsMap[residentsOnly] ?? 'No'} />
                <JobBoardViewField label="Job Template" value={jobTemplate} />
                <JobBoardViewField label="Sector" value={sector} />
            </Box>
        </Box>
    );
}