import { FilterWithTimeRange } from "common/models/Analytics/AnalyticsFilterData";
import { EdenRitchieMetricValue } from "common/models/Analytics/BusinessIntelligenceCustom";
import { ErrorHandler, RequestOptions } from "common/models/GenericTypes";
import { Post } from "util/HttpUtils";

export async function GetEdenRitchieRatios(filterData: FilterWithTimeRange, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<EdenRitchieMetricValue[]>('api/BusinessIntelligenceCustom/EdenRitchie/Ratios', options, filterData);
}

export async function GetEdenRitchieLiveMetrics(filterData: FilterWithTimeRange, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<EdenRitchieMetricValue[]>('api/BusinessIntelligenceCustom/EdenRitchie/LiveMetrics', options, filterData);
}

export async function GetEdenRitchieActivitySummary(filterData: FilterWithTimeRange, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<EdenRitchieMetricValue[]>('api/BusinessIntelligenceCustom/EdenRitchie/ActivitySummary', options, filterData);
}