import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import TextField from "@mui/material/TextField";
import { WorkProPackage } from "common/models/Integrations/WorkPro";
import React, { useCallback, useEffect, useState } from "react";
import { GetWorkProSitePackages, WorkProSendPackageToCandidate } from "services/CustomerIntegrationsService";

interface Props {
    open: boolean,
    candidateId: number,
    closeHandler: () => void,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
    successHandler?: (message: string) => void,
}

export default function WorkProSendPackageDialog({ open, candidateId, closeHandler, loadingHandler, errorHandler, successHandler }: Props) {
    const [packages, setPackages] = useState<WorkProPackage[]>([]);
    const [selectedPackage, setSelectedPackage] = useState<WorkProPackage | null>(null);

    useEffect(() => {
        const getData = async () => {
            loadingHandler && loadingHandler(true);
            const res = await GetWorkProSitePackages(errorHandler);
            if (res) setPackages(res);
            loadingHandler && loadingHandler(false);
        };
        open && getData();
    }, [errorHandler, loadingHandler, open]);

    const submitCallback = useCallback(async () => {
        if (selectedPackage) {
            loadingHandler && loadingHandler(true);
            const res = await WorkProSendPackageToCandidate(candidateId, selectedPackage.id, errorHandler);
            if (res) {
                successHandler && successHandler('Package sent');
                closeHandler();
            }
            loadingHandler && loadingHandler(false);
        }
    }, [candidateId, closeHandler, errorHandler, loadingHandler, selectedPackage, successHandler]);

    return (
        <Dialog open={open} fullWidth>
            <DialogTitle>Send Package</DialogTitle>
            <DialogContent>
                <Box pt={2}>
                    <Autocomplete
                        size="small"
                        fullWidth
                        options={packages}
                        value={ selectedPackage }
                        getOptionLabel={ o => o.name }
                        isOptionEqualToValue={ (o,v) => o.id === v.id }
                        onChange={ (e,v) => setSelectedPackage(v) }
                        renderInput={ (params) => (
                            <TextField
                                {...params}
                                label="Packages"
                                inputProps={{ ...params.inputProps, "data-lpignore": "true" }}
                            />
                        )}
                        renderOption={(props, option) => (
                            <ListItem {...props} key={option.id}>
                                <ListItemText
                                    primary={option.name}
                                    secondary={option.short_code}
                                />
                            </ListItem>
                        )}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="error" onClick={ closeHandler }>Cancel</Button>
                <Button variant="contained" color="success" disabled={ selectedPackage === null } onClick={ submitCallback } >Send</Button>
            </DialogActions>
        </Dialog>
    );
}