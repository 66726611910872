import { PanelModel } from "@syncfusion/ej2-react-layouts";
import { AnalyticsDashboardElementType, CustomDashboardElementDefinition } from "common/models/Dashboard/Analytics";

export const DefaultCustomDashboardElements: CustomDashboardElementDefinition[] = [
    { id: 'TopPerformersThisMonth_Default', row: 0, col: 0, sizeX: 3, sizeY: 2, minSizeX: 3, minSizeY: 2, maxSizeX: null, maxSizeY: null, type: 'TopPerformersThisMonth' },
    { id: "ForecastThisMonth_Default", row: 2, col: 0, sizeX: 3, sizeY: 2, minSizeX: 3, minSizeY: 2, maxSizeX: null, maxSizeY: null, type: 'ForecastThisMonth' },
    { id: "ForecastNextMonth_Default", row: 4, col: 0, sizeX: 3, sizeY: 2, minSizeX: 3, minSizeY: 2, maxSizeX: null, maxSizeY: null, type: 'ForecastNextMonth' },
    
    { id: "BillingsThisMonth_Default", row: 0, col: 12, sizeX: 3, sizeY: 2, minSizeX: 3, minSizeY: 2, maxSizeX: null, maxSizeY: null, type: 'BillingsThisMonth' },
    { id: "BillingsThisQtr_Default", row: 2, col: 12, sizeX: 3, sizeY: 2, minSizeX: 3, minSizeY: 2, maxSizeX: null, maxSizeY: null, type: 'BillingsThisQtr' },
    { id: "BillingsThisYear_Default", row: 4, col: 12, sizeX: 3, sizeY: 2, minSizeX: 3, minSizeY: 2, maxSizeX: null, maxSizeY: null, type: 'BillingsThisYear' },
    
    { id: "KeyActivityThisMonth_Default", row: 0, col: 7, sizeX: 5, sizeY: 3, minSizeX: 4, minSizeY: 3, maxSizeX: null, maxSizeY: null, type: 'KeyActivityThisMonth' },
    { id: "OpenJobsByClient_Default", row: 0, col: 3, sizeX: 4, sizeY: 3, minSizeX: 4, minSizeY: 3, maxSizeX: null, maxSizeY: null, type: 'OpenJobsByClient' },
    { id: "HistoricalBillings_Default", row: 3, col: 3, sizeX: 5, sizeY: 3, minSizeX: 4, minSizeY: 2, maxSizeX: null, maxSizeY: null, type: 'HistoricalBillings' },
    { id: "CurrentContractors_Default", row: 3, col: 8, sizeX: 4, sizeY: 3, minSizeX: 3, minSizeY: 2, maxSizeX: null, maxSizeY: null, type: 'CurrentContractors' },
];

export const GetMinSizeByElementType = (type: AnalyticsDashboardElementType) => {
    switch (type) {
        case "TopPerformersThisMonth": return [3, 2];
        case "BillingsThisMonth": return [3, 2];
        case "BillingsThisQtr": return [3, 2];
        case "BillingsThisYear": return [3, 2];
        case "CurrentContractors": return [3, 2];
        case "ForecastNextMonth": return [3, 2];
        case "ForecastThisMonth": return [3, 2];
        case "HistoricalBillings": return [4, 2];
        case "KeyActivityThisMonth": return [4, 3];
        case "OpenJobsByClient": return [4, 3];
        default: return [1, 1];
    }
};

export const GetElementTypeById = (id: string): AnalyticsDashboardElementType => {
    if (id && id.startsWith('TopPerformersThisMonth_')) return 'TopPerformersThisMonth';
    if (id && id.startsWith('CurrentContractors_')) return 'CurrentContractors';
    if (id && id.startsWith('BillingsThisMonth_')) return 'BillingsThisMonth';
    if (id && id.startsWith('BillingsThisQtr_')) return 'BillingsThisQtr';
    if (id && id.startsWith('BillingsThisYear_')) return 'BillingsThisYear';
    if (id && id.startsWith('ForecastNextMonth_')) return 'ForecastNextMonth';
    if (id && id.startsWith('ForecastThisMonth_')) return 'ForecastThisMonth';
    if (id && id.startsWith('HistoricalBillings_')) return 'HistoricalBillings';
    if (id && id.startsWith('KeyActivityThisMonth_')) return 'KeyActivityThisMonth';
    if (id && id.startsWith('OpenJobsByClient_')) return 'OpenJobsByClient';
    return '';
};

export const GetPanelDefinitionsFromPanelModels = (panels: PanelModel[]) => {
    let elements: CustomDashboardElementDefinition[] = [];
    panels.forEach((p, i) => {
        let type = GetElementTypeById(p.id ?? '');
        elements.push({
            id: p.id ?? i.toString(),
            sizeX: p.sizeX ?? 1,
            sizeY: p.sizeY ?? 1,
            col: p.col ?? 0,
            row: p.row ?? 0,
            minSizeX: p.minSizeX ?? 1,
            minSizeY: p.minSizeY ?? 1,
            maxSizeX: p.maxSizeX ?? null,
            maxSizeY: p.maxSizeY ?? null,
            type: type
        });
    });
    return elements ;
};