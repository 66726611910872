import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CancelIcon from '@mui/icons-material/Cancel';
import React, { useCallback, useEffect, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { PREVIEW_TITLE_STYLE } from "util/Definitions/Constants/Previews";
import { InterviewEdit, UiInterviewEdit } from "common/models/Interviews";
import { GetInterviewMessagePreview } from "services/InterviewsService";

interface Props {
    open: boolean,
    body: string,
    interviewId: number,
    isDarkTheme: boolean,
    interviewData: UiInterviewEdit,
    messageType: 'candidate' | 'client',
    loadingHandler?: (isLoading: boolean) => void,
    closeHandler: () => void,
}

export default function InterviewPreviewDialog({ open, interviewId, body, isDarkTheme, interviewData, messageType, loadingHandler, closeHandler }: Props) {
    const [previewBody, setPreviewBody] = useState('');

    const getPreviewCallback = useCallback(async () => {
        if (interviewData) {
            let users: number[] = [];
            let contacts: number[] = [];

            if (interviewData.usersJson) users = JSON.parse(interviewData.usersJson);
            if (interviewData.contactsJson) contacts = JSON.parse(interviewData.contactsJson);

            let data: InterviewEdit = {
                candidateDistributionMode: interviewData.candidateDistributionMode,
                candidateId: interviewData.candidateId,
                candidateMessage: '',
                candidateTemplateId: 0,
                clientDistributionMode: interviewData.clientDistributionMode,
                clientMessage: '',
                clientTemplateId: 0,
                contacts: contacts,
                dateInLocalTimeZone: interviewData.dateInLocalTimeZone,
                duration: interviewData.duration,
                filesToRemove: [],
                jobId: interviewData.jobId,
                location: interviewData.location,
                locationTypeId: interviewData.locationTypeId,
                organizerId: interviewData.organizerId,
                subject: interviewData.subject,
                timeZoneName: interviewData.timeZoneName,
                typeId: interviewData.typeId,
                users: users,
            };

            if (messageType === 'candidate') data.candidateMessage = body;
            else if (messageType === 'client') data.clientMessage = body;

            loadingHandler && loadingHandler(true);
            const res = await GetInterviewMessagePreview(interviewId, data, messageType);
            if (res) setPreviewBody(res.value);
            loadingHandler && loadingHandler(false);
        }
    }, [body, interviewData, interviewId, loadingHandler, messageType]);

    useEffect(() => {
        if (open && interviewData) {
            setPreviewBody('');
            getPreviewCallback();
        }
    }, [getPreviewCallback, interviewData, open]);

    return (
        <Dialog open={open} maxWidth="lg" fullWidth>
            <DialogTitle component="div" display="flex" sx={ PREVIEW_TITLE_STYLE }>
                <Box alignSelf="center">Interview Preview</Box>
                <Box ml="auto">
                    <IconButton size="small" onClick={ closeHandler } sx={{ color: t => t.palette.primary.contrastText }}><CancelIcon /></IconButton>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Box pt={2}>
                    <Editor
                        tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce-5.10.2/tinymce.min.js'}
                        value={previewBody}
                        disabled={true}
                        init={{
                            height: 420,
                            skin: isDarkTheme ? 'oxide-dark' : undefined,
                            content_css: isDarkTheme ? 'dark' : undefined,
                            branding: false,
                            menubar: false,
                            statusbar: false,
                            contextmenu: false,
                            resize: false,
                            plugins: 'powerpaste link code',
                            browser_spellcheck: true,
                            verify_html: false,
                            toolbar1: '',
                            readonly: true,
                        }}
                    />
                </Box>
            </DialogContent>
        </Dialog>
    );
}