import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { DashboardLayoutComponent, PanelModel } from "@syncfusion/ej2-react-layouts";
import Box from "@mui/material/Box";
import { GetCompanyBillings, GetCompanyForecasts } from "services/ForecastsService";
import { Forecast } from "common/models/Analytics/Forecasts";
import { useTheme } from "@mui/material/styles";
import { Billing } from "common/models/Analytics/Billings";
import { GetContractorBookByQty, CompanyTopPerformers, GetCompanyBillingsTrend, GetCompanyOpenJobsByClient, GetCompanyKeyActivity } from "services/BusinessIntelligence";
import { BillingTrend, TopPerformersRecord, JobsByClientRecord, KeyActivityRecord, StageTotalObj } from "common/models/Analytics/BusinessIntelligence";
import { GetDashboardSettings } from "services/ConfigurationService";
import PanelWrapper from "components/Dashboards/PanelWrapper";
import { AnalyticsDashboardElementType, CompanyDashboardElementDefinition } from "common/models/Dashboard/Analytics";
import TopPerformersTableGraph from "../Graphs/TopPerformersGraph";
import GaugeGraphComponent from "components/Dashboards/Graphs/GaugeGraphComponent";
import BillingsGaugeGraphComponent from "components/Dashboards/Graphs/BillingsGaugeGraphComponent";
import BarGraphBillingsTrend from "components/Dashboards/Graphs/BarGraphBillingsTrend";
import BarGraphStageTotal from "components/Dashboards/Graphs/BarGraphStageTotal";
import OpenJobsByClientTableGraph from "../Graphs/OpenJobsByClientTableGraph";
import KeyActivityTableGraph from "../Graphs/KeyActivityTableGraph";
import { DefaultCompanyDashboardElements } from "util/Definitions/Dashboards/Company";
import { BasicFilter } from "common/models/Analytics/AnalyticsFilterData";

interface Props {
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
}

const unitWidth: number = 112;
const unitHeight: number = 112;
const gapX: number = 10;
const gapY: number = 10;
const cellSpacing = [gapX, gapY];
const columns = 15;
const mediaQueryMaxWidth = '1000px';

const CompanyFilterValues: BasicFilter = { userFilterTypeID: 1, userFilterID: '' };

export default function CompanyDashboardLayout({ loadingHandler, errorHandler }: Props) {
    const [topPerformers, setTopPerformers] = useState<TopPerformersRecord[] | null>(null);
    const [thisMonthForecast, setThisMonthForecast] = useState<Forecast | null>(null);
    const [nextMonthForecast, setNextMonthForecast] = useState<Forecast | null>(null);
    const [thisMonthBillings, setThisMonthBillings] = useState<Billing | null>(null);
    const [thisQtrBillings, setThisQtrBillings] = useState<Billing | null>(null);
    const [thisYearBillings, setThisYearBillings] = useState<Billing | null>(null);
    const [jobsByClient, setJobsByClient] = useState<JobsByClientRecord[] | null>(null);
    const [keyActivityData, setKeyActivityData] = useState<KeyActivityRecord[] | null>(null);
    const [currentContractors, setCurrentContractors] = useState<StageTotalObj[] | null>(null);
    const [historicalBillings, setHistoricalBillings] = useState<BillingTrend[] | null>(null);
    const [stretchLabelOverride, setStretchLabelOverride] = useState<string>();
    const [targetLabelOverride, setTargetLabelOverride] = useState<string>();
    const [layoutSwapControl, setLayoutSwapControl] = useState(true);
    const [screenResizedControl, setScreenResizedControl] = useState(false);
    const [lastSavedState] = useState<PanelModel[] | null>(null);
    const [fetchingSavedState, setFetchingSavedState] = useState(false);
    const [fetchedSavedState, setFetchedSavedState] = useState(false);
    const layoutRef = useRef<DashboardLayoutComponent | null>(null);
    const theme = useTheme();

    useEffect(() => {
        if (lastSavedState) {}
        setLayoutSwapControl(prev => !prev);
    }, [lastSavedState]);

    useEffect(() => {
        const windowResizeHandler = () => setScreenResizedControl(prev => !prev);
        window.addEventListener('resize', windowResizeHandler);
        return () => window.removeEventListener('resize', windowResizeHandler);
    }, []);

    useEffect(() => {
        loadingHandler && loadingHandler(fetchingSavedState);
    }, [loadingHandler, fetchingSavedState]);

    useEffect(() => {
        const getSavedState = async () => {
            setFetchingSavedState(true);
            setFetchingSavedState(false);
            setFetchedSavedState(true);
        };
        getSavedState();
    }, []);

    const elements = useMemo<CompanyDashboardElementDefinition[]>(() => {
        return DefaultCompanyDashboardElements;
    }, []);

    useEffect(() => {
        const getDashboardSettings = async () => {
            const res = await GetDashboardSettings();
            if (res) {
                const s = res.find(v => v.type === 'StretchLabelOverride');
                const t = res.find(v => v.type === 'TargetLabelOverride');
                if (s) setStretchLabelOverride(s.value);
                if (t) setTargetLabelOverride(t.value);
            }
        };
        getDashboardSettings();
    }, []);

    useEffect(() => {
        const getTopPerformersData = async () => {
            const res = await CompanyTopPerformers();
            if (res) setTopPerformers(res);
        };
        getTopPerformersData();
    }, []);

    useEffect(() => {
        const getForecastData = async () => {
            const res = await GetCompanyForecasts();
            if (res) {
                const thisMonth = res.find(f => f.period.toLowerCase() === 'this month');
                const nextMonth = res.find(f => f.period.toLowerCase() === 'next month');
                if (thisMonth) setThisMonthForecast(thisMonth);
                if (nextMonth) setNextMonthForecast(nextMonth);
            }
        };
        getForecastData();
    }, []);

    useEffect(() => {
        const getThisMonthBillings = async () => {
            const billingsThisMonthRes = await GetCompanyBillings('ThisMonth');
            if (billingsThisMonthRes) setThisMonthBillings(billingsThisMonthRes);
        };
        getThisMonthBillings();
    }, []);

    useEffect(() => {
        const getThisQtrBillings = async () => {
            const billingsThisQtrRes = await GetCompanyBillings('ThisQtr');
            if (billingsThisQtrRes) setThisQtrBillings(billingsThisQtrRes);
        };
        getThisQtrBillings();
    }, []);

    useEffect(() => {
        const getThisYearBillings = async () => {
            const billingsThisYearRes = await GetCompanyBillings('ThisYear');
            if (billingsThisYearRes) setThisYearBillings(billingsThisYearRes);
        };
        getThisYearBillings();
    }, []);

    useEffect(() => {
        const getBillingsTrendData = async () => {
            const historicalBillingsRes = await GetCompanyBillingsTrend();
            if (historicalBillingsRes) setHistoricalBillings(historicalBillingsRes);
        };
        getBillingsTrendData();
    }, []);

    useEffect(() => {
        const getCurrentContractorsData = async () => {
            const currentContractorsRes = await GetContractorBookByQty(CompanyFilterValues);
            if (currentContractorsRes) setCurrentContractors(currentContractorsRes);
        };
        getCurrentContractorsData();
    }, []);

    useEffect(() => {
        const getOpenJobsData = async () => {
            const res = await GetCompanyOpenJobsByClient();
            if (res) setJobsByClient(res);
        };
        getOpenJobsData();
    }, []);

    useEffect(() => {
        const getActiveInterviewsData = async () => {
            const res = await GetCompanyKeyActivity();
            if (res) setKeyActivityData(res);
        };
        getActiveInterviewsData();
    }, []);

    const renderElement = useCallback((id: string, type: AnalyticsDashboardElementType) => {
        switch (type) {
            case "TopPerformersThisMonth": return <TopPerformersTableGraph data={topPerformers} />;
            case "ForecastThisMonth": return <GaugeGraphComponent data={thisMonthForecast} title="Forecast - This Month" />;
            case "ForecastNextMonth": return <GaugeGraphComponent data={nextMonthForecast} title="Forecast - Next Month" />;
            case "BillingsThisMonth": return <BillingsGaugeGraphComponent data={thisMonthBillings} title="Billings - This Month" />;
            case "BillingsThisQtr": return <BillingsGaugeGraphComponent data={thisQtrBillings} title="Billings - This Qtr" />;
            case "BillingsThisYear": return <BillingsGaugeGraphComponent data={thisYearBillings} title="Billings - This Year" />;
            case "HistoricalBillings": return  <BarGraphBillingsTrend data={historicalBillings} title="Historical Billings" stretchLabel={stretchLabelOverride} targetLabel={targetLabelOverride} />
            case "OpenJobsByClient": return <OpenJobsByClientTableGraph data={jobsByClient} />;
            case "KeyActivityThisMonth": return <KeyActivityTableGraph data={keyActivityData} />;
            case "CurrentContractors": return <BarGraphStageTotal data={currentContractors} title="Current Contractors" />;
            default: return <div>{id}</div>;
        }
    }, [currentContractors, historicalBillings, jobsByClient, keyActivityData, nextMonthForecast, stretchLabelOverride, targetLabelOverride, thisMonthBillings, thisMonthForecast, thisQtrBillings, thisYearBillings, topPerformers]);
    
    const layoutResizeStopHandler = useCallback(() => {
        setScreenResizedControl(prev => !prev);
    }, []);

    useEffect(() => {
        if (screenResizedControl) {}
        const api = layoutRef.current;
        if (api && fetchedSavedState) {
            const refreshTimeout = setTimeout(() => {
                api.refresh();
                api.refresh();
            }, 250);
            return () => clearTimeout(refreshTimeout);
        }
    }, [fetchedSavedState, screenResizedControl]);

    const renderLayout = useCallback(() => {
        if (!fetchedSavedState) return <></>;

        const mediaQuery = mediaQueryMaxWidth ? `max-width: ${mediaQueryMaxWidth}` : undefined;
        const w = window.innerWidth;
        return (
            <DashboardLayoutComponent
                cellSpacing={cellSpacing}
                columns={columns}
                resizeStop={ layoutResizeStopHandler }
                allowDragging={false}
                allowResizing={false}
                ref={l => layoutRef.current = l}
                mediaQuery={mediaQuery}
                cellAspectRatio={w <= 1000 ? 3 : 1}
            >
                {elements.map(e => (
                    <PanelWrapper
                        key={e.id}
                        id={e.id}
                        col={e.col}
                        row={e.row}
                        sizeX={e.sizeX}
                        sizeY={e.sizeY}
                        minSizeX={e.minSizeX}
                        minSizeY={e.minSizeY}
                        maxSizeX={e.maxSizeX}
                        maxSizeY={e.maxSizeY}
                        resizeControl={screenResizedControl}
                        resizeIconColor={theme.palette.text.disabled}
                        gapX={gapX}
                        gapY={gapY}
                        unitWidth={unitWidth}
                        unitHeight={unitHeight}
                    >
                        {renderElement(e.id, e.type)}
                    </PanelWrapper>
                ))}
            </DashboardLayoutComponent>
        );
    }, [elements, fetchedSavedState, theme.palette.text.disabled, renderElement, layoutResizeStopHandler, screenResizedControl]);

    return (
        <>
            <Box bgcolor={t => t.palette.background.default} p="10px" height="100%">
                <div className="control-section">
                    { layoutSwapControl && renderLayout() }
                    { !layoutSwapControl && renderLayout() }
                </div>
            </Box>
        </>
    );
}