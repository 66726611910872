import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { SxProps } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import React, { useCallback, useState } from "react";
import { GetEmailMarketingReportData } from "services/ContactsService";
import UserPicker from "components/Pickers/UserPicker";
import * as XLSX from "xlsx";
import moment from "moment";
import { NameIdObj } from "common/models/GenericTypes";

interface Props {
    description: string,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
}

const ownerSpecialOptions: NameIdObj[] = [
    { id: 0, name: 'My Company' },
    { id: -1, name: 'Me' },
    { id: -2, name: 'My Team' },
    { id: -3, name: 'Unassigned' },
];

const filterElementStyle: SxProps = { flex: '1 1 0', mr: 1 };

export default function EmailMarketingReport({ description, loadingHandler, errorHandler }: Props) {
    const [ownerId, setOwnerId] = useState(0);
    const [status, setStatus] = useState(0);

    const getDataCallback = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        const res = await GetEmailMarketingReportData(ownerId, status, errorHandler);
        if (res) {
            const m = moment();
            const filename = `EmailMarketing${m.format('YYYYMMDDhhmmss')}.xlsx`;
            const ws = XLSX.utils.json_to_sheet(res);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws);
            XLSX.writeFile(wb, filename);
        }
        loadingHandler && loadingHandler(false);
    }, [ownerId, status, errorHandler, loadingHandler]);

    return (
        <>
            <Box display="flex">
                <Box flex="1 1 0" pr={1}>
                    <UserPicker disableClearable label="Owner" userId={ownerId} onSelect={u => setOwnerId(u ? u.id : 0)} appendToStart={ownerSpecialOptions} includeInactiveUsers={true} />
                </Box>
                <TextField select label="Status" value={status.toString()} onChange={({ target }) => setStatus(+target.value)} sx={filterElementStyle}>
                    <MenuItem value="0">All</MenuItem>
                    <MenuItem value="1">Unqualified</MenuItem>
                    <MenuItem value="2">Qualified</MenuItem>
                    <MenuItem value="3">Client</MenuItem>
                    <MenuItem value="4">Supplier</MenuItem>
                    <MenuItem value="7">Supplier - Sub Contractor</MenuItem>
                    <MenuItem value="5">Billing</MenuItem>
                    <MenuItem value="6">Archived</MenuItem>
                    <MenuItem value="8">Referee</MenuItem>
                    <MenuItem value="9">Prospect</MenuItem>
                </TextField>
                <Button variant="contained" color="success" onClick={getDataCallback}>Run Report</Button>
            </Box>
        </>
    );
}