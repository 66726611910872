import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import React, { useMemo } from "react";
import { NationalityList } from "util/Definitions/Nationality";

interface Props {
    onSelectCallback: (nationalityId: number | null) => void,
    variant?: "standard" | "filled" | "outlined" | undefined,
    nationalityId: number | null,
    label?: string,
    hideLabel?: boolean,
}

export default function NationalityPicker({ nationalityId, variant, label = "Nationality", hideLabel = false, onSelectCallback }: Props) {
    const nationality = useMemo(() => {
        if (nationalityId === null) return null;
        const nationality = NationalityList.find(n => n.id === nationalityId);
        if (nationality) return nationality;
        return null;
    }, [nationalityId]);
    
    return (
        <Autocomplete
            size="small"
            value={nationality}
            options={NationalityList}
            getOptionLabel={o => o.name}
            isOptionEqualToValue={ (o, v) => o.id === v.id }
            onChange={ (e,v) => onSelectCallback(v ? v.id : null) }
            renderInput={ params => <TextField {...params} variant={variant} label={hideLabel ? undefined : label} inputProps={{ ...params.inputProps, "data-lpignore": "true" }} /> }
        />
    );
}