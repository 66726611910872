import Alert from "components/Alert";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import { GridColDef, GridRowSelectionModel, useGridApiRef } from "@mui/x-data-grid-premium";
import React, { useEffect, useMemo, useState } from "react";
import { RecentlyDeletedRecord, RecoverDeletedRecordData } from "common/models/Configuration/RecentlyDeletedRecord";
import ConfirmationDialog from "components/Dialogs/Generic/ConfirmationDialog";
import GridWithStateWrapper from "components/GridWidthStateWrapper";
import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import PageContentLayout from "layouts/PageContentLayout";
import { GetRecentlyDeletedRecords, RecoverRecentlyDeletedRecords } from "services/ConfigurationService";

interface Props {
    setSummaryBar?: (summaryBar: JSX.Element) => void
}

export default function RecentlyDeleteRecords({ setSummaryBar }: Props) {
    const [isLoading, setIsLoading] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
    const [rows, setRows] = useState<RecentlyDeletedRecord[]>([]);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const apiRef = useGridApiRef();

    useEffect(() => {
        const action = <Button variant="contained" color="primary" disabled={ selectionModel.length === 0 } onClick={ () => setShowConfirmDialog(true) }>Recover</Button>;
        const summaryBar = <TitleAndActionSummaryBar title="Configuration > Recently Deleted Records" browserTabTitle="Recently Deleted Records" action={action} />;
        setSummaryBar && setSummaryBar(summaryBar);
    }, [setSummaryBar, selectionModel.length]);

    useEffect(() => {
        const getData = async () => {
            setIsLoading(true);
            const data = await GetRecentlyDeletedRecords(setErrorMessage);
            if (data) setRows(data);
            setIsLoading(false);
        };
        getData();
    }, []);

    const columns = useMemo<GridColDef[]>(() => {
        return [
            { field: 'id', headerName: 'ID', width: 75 },
            { field: 'name', headerName: 'Name', width: 300 },
            { field: 'recordType', headerName: 'Record Type', width: 150 },
            { field: 'deletedDate', headerName: 'Deleted Date', width: 110, type: 'date', valueGetter: ({ value }) => value && new Date(value) },
            { field: 'deletedBy', headerName: 'Deleted By', width: 300 }
        ];
    }, []);

    const handleRecover = async () => {
        setIsLoading(true);
        let data: RecoverDeletedRecordData[] = [];
        for (let i = 0; i < selectionModel.length; i++) {
            const rowId = selectionModel[i];
            const row = apiRef.current.getRow(rowId) as RecentlyDeletedRecord;
            data.push({ id: row.id, entityTypeID: row.entityTypeID });
        }
        const res = await RecoverRecentlyDeletedRecords(data, setErrorMessage);
        if (res) {
            let newRows = [...rows];
            for (let i = 0; i < data.length; i++) {
                const record = data[i];
                const rowIndex = newRows.findIndex(r => r.id === record.id && r.entityTypeID === record.entityTypeID);
                if(rowIndex !== -1) newRows.splice(rowIndex, 1);
            }
            setRows(newRows);
            setSelectionModel([]);
            setShowConfirmDialog(false);
            setShowSuccess(true);
        }
        setIsLoading(false);
    }

    return (
        <>
            <Snackbar open={showSuccess} autoHideDuration={3000} onClose={() => setShowSuccess(false)}>
                <Alert onClose={() => setShowSuccess(false)}>Changes Saved</Alert>
            </Snackbar>
            <Snackbar open={errorMessage !== ''} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <ConfirmationDialog
                open={ showConfirmDialog }
                title={ `Recover Deleted ${ selectionModel.length === 1 ? 'Record' : 'Records' }` }
                message={
                    selectionModel.length === 1 ?
                    "Are you sure you want to recover this record?" :
                    `Are you sure you want to recover all ${selectionModel.length} selected records?`
                }
                onClose={ () => setShowConfirmDialog(false) }
                onContinue={ handleRecover }
                confirmActionText="Recover"
                fullWidth
                dividers
            />
            <PageContentLayout title="Recently Deleted Records" showLoading={isLoading}>
                <GridWithStateWrapper
                    gridName="configuration/recently-deleted-records"
                    apiRef={apiRef}
                    rows={rows}
                    columns={columns}
                    disableRowSelectionOnClick
                    checkboxSelection
                    pagination={true}
                    density="compact"
                    rowSelectionModel={selectionModel}
                    onRowSelectionModelChange={ sm => setSelectionModel(sm) }
                    pageSizeOptions={[100,250,500,1000]}
                />
            </PageContentLayout>
        </>
    );
}