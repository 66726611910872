import React, { useCallback, useEffect, useMemo, useState } from "react";
import { TemplatePlaceholder } from "common/models/Templates/TemplatePlaceholder";
import Button from "@mui/material/Button";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PlaceholderSelectionList from "./PlaceholderSelectionList";
import { DocumentTemplatePlaceholder } from "common/models/Templates/DocumentTemplate";
import Box from "@mui/material/Box";

interface Props {
    placeholders: TemplatePlaceholder[],
    selectedPlaceholders: DocumentTemplatePlaceholder[],
    setPlaceholdersHandler: (placeholders: DocumentTemplatePlaceholder[]) => void,
    infoMessageHandler?: (message: string) => void
}

export default function PlaceholderTransferList({ placeholders, selectedPlaceholders, setPlaceholdersHandler, infoMessageHandler }: Props) {
    const [availablePlaceholders, setAvailablePlaceholders] = useState<DocumentTemplatePlaceholder[]>([]);
    const [usedPlaceholders, setUsedPlaceholders] = useState<DocumentTemplatePlaceholder[]>([]);
    const [availableSelected, setAvailableSelected] = useState<DocumentTemplatePlaceholder[]>([]);
    const [usedSelected, setUsedSelected] = useState<DocumentTemplatePlaceholder[]>([]);

    const processedPlaceholders = useMemo<DocumentTemplatePlaceholder[]>(() => {
        let newP: DocumentTemplatePlaceholder[] = [];
        for (let i = 0; i < placeholders.length; i++) {
            const p = placeholders[i];
            newP.push({ friendlyName: p.friendlyName, placeholder: p.placeholder, isEditable: false });
        }
        return newP;
    }, [placeholders]);

    useEffect(() => {
        if (selectedPlaceholders.length > 0) {
            let available = [...processedPlaceholders];
            let used: DocumentTemplatePlaceholder[] = [];
            for (let i = 0; i < selectedPlaceholders.length; i++) {
                const sp = selectedPlaceholders[i];
                const index = available.findIndex(p => p.placeholder === sp.placeholder);
                if (index !== -1) {
                    const item = available[index];
                    used.push({...sp, friendlyName: item.friendlyName});
                    available.splice(index, 1)
                }
            }
            setAvailablePlaceholders(available);
            setUsedPlaceholders(used);
        }
        else {
            setAvailablePlaceholders(processedPlaceholders);
            setUsedPlaceholders([]);
        }
    }, [processedPlaceholders, selectedPlaceholders]);

    const handleAvailableSelection = useCallback((p: DocumentTemplatePlaceholder) => {
        const index = availableSelected.findIndex(s => s.placeholder === p.placeholder);
        if (index !== -1) {
            let selected = [...availableSelected];
            selected.splice(index, 1);
            setAvailableSelected(selected);
        }
        else {
            setAvailableSelected(prev => [...prev, p]);
        }
    }, [availableSelected]);

    const handleUsedSelection = useCallback((p: DocumentTemplatePlaceholder) => {
        const index = usedSelected.findIndex(s => s.placeholder === p.placeholder);
        if (index !== -1) {
            let selected = [...usedSelected];
            selected.splice(index, 1);
            setUsedSelected(selected);
        }
        else {
            setUsedSelected(prev => [...prev, p]);
        }
    }, [usedSelected]);    

    const moveRightHandler = useCallback(() => {
        if (availableSelected.length > 0) {
            setPlaceholdersHandler([...selectedPlaceholders, ...availableSelected]);
            setAvailableSelected([]);
        }
    }, [availableSelected, selectedPlaceholders, setPlaceholdersHandler]);

    const moveLeftHandler = useCallback(() => {
        if(usedSelected.length > 0) {
            let newPlaceholders = [...selectedPlaceholders];
            for (let i = 0; i < usedSelected.length; i++) {
                const rp = usedSelected[i];
                const index = newPlaceholders.findIndex(p => p.placeholder === rp.placeholder);
                if (index !== -1) newPlaceholders.splice(index, 1);
            }
            setPlaceholdersHandler(newPlaceholders);
            setUsedSelected([]);
        }
    }, [usedSelected, selectedPlaceholders, setPlaceholdersHandler]);

    const moveUpHandler = useCallback(() => {
        if (usedSelected.length === 1) {
            const placeholder = usedSelected[0];
            let newPlaceholders = [...selectedPlaceholders];
            const index = newPlaceholders.findIndex(p => p.placeholder === placeholder.placeholder);
            if (index > 0) {
                newPlaceholders.splice(index, 1);
                newPlaceholders.splice(index -1, 0, placeholder);
                setPlaceholdersHandler(newPlaceholders);
            }
        }
    }, [usedSelected, selectedPlaceholders, setPlaceholdersHandler]);
    
    const moveDownHandler = useCallback(() => {
        if (usedSelected.length === 1) {
            const placeholder = usedSelected[0];
            let newPlaceholders = [...selectedPlaceholders];
            const index = newPlaceholders.findIndex(p => p.placeholder === placeholder.placeholder);
            if (index < newPlaceholders.length - 1) {
                newPlaceholders.splice(index, 1);
                newPlaceholders.splice(index +1, 0, placeholder);
                setPlaceholdersHandler(newPlaceholders);
            }
        }
    }, [usedSelected, selectedPlaceholders, setPlaceholdersHandler]);

    const toggleEditable = useCallback((placeholder: string) => {
        if (placeholder !== '') {
            let newPlaceholders = [...usedPlaceholders];
            const item = newPlaceholders.find(p => p.placeholder === placeholder);
            if (item) item.isEditable = !item.isEditable;
            setPlaceholdersHandler(newPlaceholders);
        }
    }, [usedPlaceholders, setPlaceholdersHandler]);

    const isUpDisabled = useMemo(() => {
        if (usedSelected.length === 1) {
            const placeholder = usedSelected[0];
            const index = usedPlaceholders.findIndex(p => p.placeholder === placeholder.placeholder);
            return index <= 0;
        }
        return true;
    }, [usedSelected, usedPlaceholders]);

    const isDownDisabled = useMemo(() => {
        if (usedSelected.length === 1) {
            const placeholder = usedSelected[0];
            const index = usedPlaceholders.findIndex(p => p.placeholder === placeholder.placeholder);
            return index === -1 || index === usedPlaceholders.length - 1;
        }
        return true;
    }, [usedSelected, usedPlaceholders]);

    const copyToClipboardHandler = useCallback((placeholder: string) => {
        if (placeholder) {
            navigator.clipboard.writeText(`#{${placeholder}}`);
            infoMessageHandler && infoMessageHandler('Placeholder copied to clipboard');
        }
    }, [infoMessageHandler]);
    
    return (
        <Box display="flex" gap={2} justifyContent="center" height="100%" pb="5px">
            <PlaceholderSelectionList
                title="Available placeholders"
                placeholders={availablePlaceholders}
                placeholderSelectedHandler={handleAvailableSelection}
                selectedPlaceholders={availableSelected}
            />
            <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
                <Button
                    variant="outlined"
                    size="small"
                    sx={{ my: 0.5 }}
                    disabled={ isUpDisabled }
                    onClick={moveUpHandler}
                ><KeyboardArrowUpIcon /></Button>
                <Button
                    variant="outlined"
                    size="small"
                    sx={{ my: 0.5 }}
                    disabled={ usedSelected.length === 0 }
                    onClick={moveLeftHandler}
                ><KeyboardArrowLeftIcon /></Button>
                <Button
                    variant="outlined"
                    size="small"
                    sx={{ my: 0.5 }}
                    disabled={ availableSelected.length === 0 }
                    onClick={moveRightHandler}
                ><KeyboardArrowRightIcon /></Button>
                <Button
                    variant="outlined"
                    size="small"
                    sx={{ my: 0.5 }}
                    disabled={ isDownDisabled }
                    onClick={moveDownHandler}
                ><KeyboardArrowDownIcon /></Button>
            </Box>
            <PlaceholderSelectionList
                title="Selected placeholders"
                placeholders={usedPlaceholders}
                placeholderSelectedHandler={handleUsedSelection}
                selectedPlaceholders={usedSelected}
                placeholderEditableHandler={ toggleEditable }
                copyToClipboardHandler={copyToClipboardHandler}
            />
        </Box>
    );
}