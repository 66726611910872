import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import React from "react";

interface Props {
    SummaryBar?: JSX.Element,
    paddingTop?: number,
}

export default function PageLayout({ SummaryBar, paddingTop = 2, children }: React.PropsWithChildren<Props>) {
    return (
        <>
        <Box position="sticky" top="0" sx={{ zIndex: (t) => t.zIndex.appBar - 2 }}>
            {SummaryBar}
        </Box>
        <Grid container flexGrow={1}>
            <Grid item xs={12}>
                <Container maxWidth={false} sx={{ pb: 2, pt: paddingTop, height: '100%', display: 'flex', flexDirection: 'column', overflowY: 'auto' }}>
                    {children}
                </Container>
            </Grid>
        </Grid>
        </>
    );
}