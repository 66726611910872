import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import * as tinymce from 'tinymce';
import { TemplatePlaceholder } from "common/models/Templates/TemplatePlaceholder";
import { useTheme } from "@mui/material/styles";
import InsertPlaceholderDialog from "./Dialogs/Generic/InsertPlaceholderDialog";

interface Props {
    height?: string | number,
    editorRef?: React.MutableRefObject<tinymce.Editor | null>,
    initialValue?: string,
    placeholders?: TemplatePlaceholder[],
    onDirtyHandler?: (isDirty: boolean) => void,
    onContentChange: (content: string) => void,
}

export default function TextAreaNoFormat({ height = 380, editorRef: editorRefProp, initialValue, placeholders, onDirtyHandler, onContentChange }: Props) {
    const theme = useTheme();
    const isDarkTheme = theme.palette.mode === 'dark';
    const editorRef = useRef<tinymce.Editor | null>(null);
    const [showPlaceholders, setShowPlaceholders] = useState(false);
    const [rerenderControl, setRerenderControl] = useState(false);

    useEffect(() => {
        if (placeholders) {}
        setRerenderControl(prev => !prev);
    }, [placeholders]);

    const hasPlaceholders = useMemo(() => placeholders && placeholders.length > 0, [placeholders]);

    const onPlacementSelectedCallback = useCallback((placeholder: string) => {
        const api = editorRef.current;
        if (hasPlaceholders && api) {
            api.insertContent(placeholder);
        }
    }, [hasPlaceholders]);

    const editor = useMemo(() => {
        return (
            <Editor
                tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce-5.10.2/tinymce.min.js'}
                value={initialValue}
                onDirty={ () => onDirtyHandler && onDirtyHandler(true) }
                onInit={ (evt, editor) => {
                    if (editorRefProp !== undefined) editorRefProp.current = editor;
                    editorRef.current = editor;
                }}
                onEditorChange={onContentChange}
                init={{
                    height: height,
                    skin: isDarkTheme ? 'oxide-dark' : undefined,
                    content_css: isDarkTheme ? 'dark' : undefined,
                    branding: false,
                    menubar: false,
                    statusbar: false,
                    contextmenu: false,
                    resize: false,
                    plugins: 'paste',
                    paste_as_text: true,
                    browser_spellcheck: true,
                    forced_root_block: false,
                    verify_html: false,
                    toolbar1: 'placeholders ',
                    setup: (editor) => {
                        if (hasPlaceholders) {
                            editor.ui.registry.addButton('placeholders', {
                                text: 'Insert Placeholder',
                                onAction: () => setShowPlaceholders(true)
                            });
                        }
                    }
                }}
            />
        );
    }, [editorRefProp, hasPlaceholders, height, initialValue, isDarkTheme, onContentChange, onDirtyHandler]);
    
    return (
        <>
            {hasPlaceholders &&
                <InsertPlaceholderDialog
                    open={showPlaceholders}
                    closeHandler={() => setShowPlaceholders(false)}
                    placeholders={placeholders ?? []}
                    insertHandler={onPlacementSelectedCallback}
                />
            }
            {rerenderControl && editor}
            {!rerenderControl && editor}
        </>
    );
};