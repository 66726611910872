import { Report } from "../common/models/Reports/Report";
import { Get } from "../util/HttpUtils";
import { ErrorHandler, RequestOptions } from "../common/models/GenericTypes";

export async function GetDashboardReports(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Report[]>('api/misc/reports', options);
}