import Alert from "components/Alert";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import PageContentLayout from "layouts/PageContentLayout";
import { ChangeMyPassword } from "services/AuthService";
import { IsValidPassword } from "util/PasswordUtils";
import RWTextFieldComponent from "components/RWTextFieldComponent";

interface Props {
    setSummaryBar?: (summaryBar: JSX.Element) => void
}

export default function PasswordPage({ setSummaryBar }: Props) {
    const [isLoading, setIsLoading] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const newValidationMessage = useMemo(() => {
        if (newPassword.length > 0 && !IsValidPassword(newPassword)) {
            return 'Must be at least 12 characters long and include at least one number and one uppercase character';
        }
        return '';
    }, [newPassword]);

    const confirmValidationMessage = useMemo(() => {
        if (confirmPassword.length > 0 && newPassword !== confirmPassword) {
            return "Doesn't match New Password value";
        }
        return '';
    }, [newPassword, confirmPassword]);

    const submitHandler = useCallback( async () => {
        setIsLoading(true);
        const res = await ChangeMyPassword(newPassword, setErrorMessage);
        if(res) {
            setNewPassword('');
            setConfirmPassword('');
            setShowSuccess(true);
        }
        setIsLoading(false);
    }, [newPassword]);

    useEffect(() => {
        const isDisabled = newPassword.length === 0
            || confirmPassword.length === 0
            || newValidationMessage.length > 0
            || confirmValidationMessage.length > 0;

        const action = (
            <Button
                variant="contained"
                color="success"
                disabled={ isDisabled }
                onClick={ submitHandler }
            >Save</Button>
        );

        const sb = (
                <TitleAndActionSummaryBar
                    action={action}
                    title="Account > Password"
                    browserTabTitle="Password > Account"
                />
            );
            setSummaryBar && setSummaryBar(sb);
    }, [submitHandler, newPassword, confirmPassword, newValidationMessage.length, confirmValidationMessage.length, setSummaryBar]);

    return (
        <>
            <Snackbar open={showSuccess} autoHideDuration={3000} onClose={() => setShowSuccess(false)}>
                <Alert onClose={() => setShowSuccess(false)}>Changes Saved</Alert>
            </Snackbar>
            <Snackbar open={errorMessage !== ''} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <PageContentLayout showLoading={isLoading} title="Password">
                <div style={{ width: '100%', maxWidth: '700px', margin: '0 auto' }}>
                    <Stack spacing={3}>
                        Must be at least 12 characters long and include at least one number and one uppercase character
                        <RWTextFieldComponent
                            type="password"
                            label="New Password"
                            value={newPassword}
                            onChange={ ({target}) => setNewPassword(target.value) }
                            isError={ newValidationMessage.length > 0 }
                            helperText={ newValidationMessage }

                        />
                        <RWTextFieldComponent
                            type="password"
                            label="Confirm Password"
                            value={confirmPassword}
                            onChange={ ({target}) => setConfirmPassword(target.value) }
                            isError={ confirmValidationMessage.length > 0 }
                            helperText={ confirmValidationMessage }
                        />
                    </Stack>
                </div>
            </PageContentLayout>
        </>
    );
}