import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { GridColDef, GridRenderCellParams, GridRowSelectionModel, GridValueGetter, useGridApiRef } from "@mui/x-data-grid-premium";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { AllowTemplates } from "common/data/Permissions/UserMenuAccess";
import GridWithStateWrapper from "components/GridWidthStateWrapper";
import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import PageContentLayout from "layouts/PageContentLayout";
import PageLayout from "layouts/PageLayout";
import { CopyMessageTemplate, DeleteMessageTemplates, GetAllMessageTemplates, GetMyMessageTemplates } from "services/TemplatesService";
import Snackbar from "@mui/material/Snackbar";
import Alert from "components/Alert";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import ConfirmationDialog from "components/Dialogs/Generic/ConfirmationDialog";
import Divider from "@mui/material/Divider";
import ActionsDropDownButton from "components/SummaryBars/Actions/ActionsDropsDownMenu";
import { MessageTemplate } from "common/models/Templates/MessageTemplate";
import RWTextFieldComponent from "components/RWTextFieldComponent";

const linkStyle: React.CSSProperties = { color: 'inherit', textDecoration: 'none', padding: '6px 16px', flexGrow: 1 };

const addTemplateEmail = <Link to="/account/templates/messages/0?type=1" style={ linkStyle }>Add - Email Template</Link>;
const addTemplateSMS = <Link to="/account/templates/messages/0?type=2" style={ linkStyle }>Add - SMS Template</Link>;
const addTemplateRejectionEmail = <Link to="/account/templates/messages/0?type=3" style={ linkStyle }>Add - Rejection Email Template</Link>;
const addTemplateInterviewConfirmation = <Link to="/account/templates/messages/0?type=4" style={ linkStyle }>Add - Interview Confirmation Template</Link>;
const addTemplateMeetingConfirmation = <Link to="/account/templates/messages/0?type=5" style={ linkStyle }>Add - Meeting Confirmation Template</Link>;
const addTemplateOfferConfirmation = <Link to="/account/templates/messages/0?type=6" style={ linkStyle }>Add - Offer Confirmation Template</Link>;
const addTemplateSubmission = <Link to="/account/templates/messages/0?type=7" style={ linkStyle }>Add - Submission Template</Link>;
const addTemplateFloat = <Link to="/account/templates/messages/0?type=8" style={ linkStyle }>Add - Float Template</Link>;
const addTemplatePlacement = <Link to="/account/templates/messages/0?type=9" style={ linkStyle }>Add - Placement Template</Link>;
const addTemplateOnboarding = <Link to="/account/templates/messages/0?type=10" style={ linkStyle }>Add - Onboarding Template</Link>;


export default function AccountTemplatesMessagesPage() {
    const [summaryBar, setSummaryBar] = useState<JSX.Element>(<></>);
    const [isLoading, setIsLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
    const [rows, setRows] = useState<MessageTemplate[]>([]);
    const [copyTemplateName, setCopyTemplateName] = useState('');
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const apiRef = useGridApiRef();

    useEffect(() => {
        const handleCopyTemplate = () => {
            const selectedId = +selectionModel[0];
            const row = rows.find(r => r.id === selectedId);
            if(row) setCopyTemplateName(row.name);
        }
        const handleDeleteTemplate = () => setShowDeleteDialog(true);

        const actionsMenu = (
            <ActionsDropDownButton>
                <MenuItem sx={{ p: 0 }}>{ addTemplateEmail }</MenuItem>
                <MenuItem sx={{ p: 0 }}>{ addTemplateSMS }</MenuItem>
                <MenuItem sx={{ p: 0 }}>{ addTemplateRejectionEmail }</MenuItem>
                <MenuItem sx={{ p: 0 }}>{ addTemplateInterviewConfirmation }</MenuItem>
                <MenuItem sx={{ p: 0 }}>{ addTemplateMeetingConfirmation }</MenuItem>
                <MenuItem sx={{ p: 0 }}>{ addTemplateOfferConfirmation }</MenuItem>
                <MenuItem sx={{ p: 0 }}>{ addTemplateSubmission }</MenuItem>
                <MenuItem sx={{ p: 0 }}>{ addTemplateFloat }</MenuItem>
                <MenuItem sx={{ p: 0 }}>{ addTemplatePlacement }</MenuItem>
                <MenuItem sx={{ p: 0 }}>{ addTemplateOnboarding }</MenuItem>
                <Divider />
                <MenuItem onClick={ handleCopyTemplate } disabled={ selectionModel.length !== 1 } >Copy Template</MenuItem>
                <MenuItem onClick={ handleDeleteTemplate } disabled={ selectionModel.length < 1 } >Delete Template</MenuItem>
            </ActionsDropDownButton>
        );
        const SummaryBar = (
            <TitleAndActionSummaryBar
                title="Account > Templates > Messages"
                browserTabTitle="Messages > Templates"
                action={actionsMenu}
            />
        );
        setSummaryBar(SummaryBar);
    }, [rows, selectionModel]);

    const isTemplatesAdmin = useMemo(() => AllowTemplates(), []);

    const getRows = useCallback(async () => {
        setIsLoading(true);
        let data: MessageTemplate[] | null = null;
        if (isTemplatesAdmin) {
            data = await GetAllMessageTemplates(setErrorMessage);
        }
        else {
            data = await GetMyMessageTemplates(setErrorMessage);
        }
        
        if (data) setRows(data);

        setIsLoading(false);
    }, [isTemplatesAdmin]);

    useEffect(() => {
        getRows();
    }, [getRows]);

    const columns = useMemo<GridColDef[]>(() => {
        const linkToTemplateRenderer = (params: GridRenderCellParams) => {
            return (
                <Link
                    to={'/account/templates/messages/' + params.id}
                    style={{ color: 'inherit', textDecoration: 'underline' }}
                >
                    {params.value}
                </Link>
            );
        };

        const recipientValueGetter: GridValueGetter<MessageTemplate, any, undefined, any> = (value, row) => {
            if (row.candidate) return 'Candidate';
            if (row.contact) return 'Contact';
        };

        return [
            { field: 'id', headerName: 'Id', renderCell: linkToTemplateRenderer },
            { field: 'name', headerName: 'Name', width: 300, renderCell: linkToTemplateRenderer },
            { field: 'typeName', headerName: 'Type', width: 300 },
            { field: 'subject', headerName: 'Subject', width: 200 },
            { field: 'userFullName', headerName: 'Owner', width: 200 },
            { field: 'recipient', headerName: 'Recipient', width: 200, valueGetter: recipientValueGetter },
        ]
    }, []);

    const copyTemplateConfirmCallback = useCallback(async () => {
        setIsLoading(true);
        const templateId = +selectionModel[0];
        const res = await CopyMessageTemplate(templateId, copyTemplateName, setErrorMessage);
        if (res) {
            setCopyTemplateName('');
            setSelectionModel([]);
            setSuccessMessage('Copy created');
            await getRows();
        }
        setIsLoading(false);
    }, [copyTemplateName, selectionModel, getRows]);

    const deleteTemplateConfirmCallback = useCallback(async () => {
        setIsLoading(true);
        const res = await DeleteMessageTemplates(selectionModel as number[], setErrorMessage);
        if (res) {
            setShowDeleteDialog(false);
            setSuccessMessage(selectionModel.length === 1 ? 'Template deleted' : 'Templates deleted');
            setSelectionModel([]);
            await getRows();
        }
        setIsLoading(false);
    }, [selectionModel, getRows]);
    
    return (
        <PageLayout SummaryBar={summaryBar}>
            <Snackbar open={successMessage !== ''} autoHideDuration={3000} onClose={() => setSuccessMessage('')}>
                <Alert onClose={() => setSuccessMessage('')}>{successMessage}</Alert>
            </Snackbar>
            <Snackbar open={errorMessage !== ''} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            { copyTemplateName !== '' &&
                <Dialog open={copyTemplateName !== ''} fullWidth>
                    <DialogTitle>Confirm</DialogTitle>
                    <DialogContent dividers>
                        <RWTextFieldComponent
                            label="New template name"
                            autofocus={true}
                            value={copyTemplateName}
                            onChange={(e) => setCopyTemplateName(e.target.value)}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="error" onClick={() => setCopyTemplateName('')}>Cancel</Button>
                        <Button variant="contained" color="success" onClick={ copyTemplateConfirmCallback }>OK</Button>
                    </DialogActions>
                </Dialog>
            }
            { showDeleteDialog &&
                <ConfirmationDialog
                    open={showDeleteDialog}
                    title="Confirm deletion"
                    message={
                        selectionModel.length === 1
                        ? `Are you sure you want to delete the selected record?`
                            : `Are you sure you want to delete all ${selectionModel.length} selected records?`
                    }
                    onClose={ () => setShowDeleteDialog(false) }
                    onContinue={ deleteTemplateConfirmCallback }
                />
            }
            <PageContentLayout showLoading={isLoading}>
                <GridWithStateWrapper
                    gridName="MessageTemplates"
                    apiRef={apiRef}
                    rows={rows}
                    columns={columns}
                    disableRowSelectionOnClick
                    checkboxSelection
                    pagination
                    density="compact"
                    rowSelectionModel={selectionModel}
                    onRowSelectionModelChange={(sm) => setSelectionModel(sm)}
                    pageSizeOptions={[100,250,500,1000]}
                    
                />
            </PageContentLayout>
        </PageLayout>
    );
}