import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { TopBarRoute } from '../../../common/models/TopBarTypes';
import SideNav from '../../../components/SideBars/SideNav';
import PageWithSidebarLayout from '../../../layouts/PageWithSidebarLayout';
import AccountSettingsPage from '../AccountSettings';
import AccountSignaturePage from '../AccountSignature';
import PasswordPage from '../PasswordPage';
import RecordDefaultsPage from '../RecordDefaultsPage';
import AccountTeamPage from '../TeamPage';
import AccountGeneralSettingsPage from './AccountGeneralSettings';
import AccountProfilePage from './AccountProfile';

const accountGeneralRoutes: TopBarRoute[] = [
    { path: '/account', text: 'Contact Details' },
    { path: '/account/password', text: 'Password' },
    { path: '/account/profile', text: 'Profile' },
    { path: '/account/record-defaults', text: 'Record Defaults' },
    { path: '/account/settings', text: 'Settings' },
    { path: '/account/signature', text: 'Signature' },
    { path: '/account/team', text: 'Team' }
];

const sideNav = <SideNav routes={accountGeneralRoutes} />;

export default function AccountGeneralIndex() {
    const [summaryBar, setSummaryBar] = useState<JSX.Element>(<></>);
    
    return (
        <PageWithSidebarLayout SummaryBar={summaryBar} SideBar={sideNav}>
            <Routes>
                <Route path="" element={<AccountGeneralSettingsPage setSummaryBar={setSummaryBar} />} />
                <Route path="profile" element={<AccountProfilePage setSummaryBar={setSummaryBar} />} />
                <Route path="settings" element={<AccountSettingsPage setSummaryBar={setSummaryBar} />} />
                <Route path="record-defaults" element={<RecordDefaultsPage setSummaryBar={setSummaryBar} />} />
                <Route path="signature" element={<AccountSignaturePage setSummaryBar={setSummaryBar} />} />
                <Route path="team" element={<AccountTeamPage setSummaryBar={setSummaryBar} />} />
                <Route path="password" element={<PasswordPage setSummaryBar={setSummaryBar} />} />
            </Routes>
        </PageWithSidebarLayout>
    );
}
