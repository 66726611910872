import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import CancelIcon from '@mui/icons-material/Cancel';
import React, { PropsWithChildren, useCallback, useEffect, useMemo, useState } from "react";
import { TagFilter, TagFilterGroup, TagSettings, TagValue } from "common/models/Configuration/Tags";
import { GetCustomerTags, GetMyCustomerTags, GetMyTagsSettings } from "services/TagsService";
import TagFilterSelectionList from "./TagFilterSelectionList";

interface Props {
    entityId: number,
    includeTags: TagFilter[],
    excludeTags: TagFilter[],
    tagSource: 'a' | 'm',
    setAvailableTagsHandler: (t: TagFilter[]) => void,
    addHandler: (typeId: number, tag: string) => void,
    removeHandler: (tag: TagFilter) => void,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void
}

const tagValueToTagFilter = (t: TagValue): TagFilter => {
    return { tag: t.tag, typeId: t.tagTypeID, source: 'a' };
};

const tagValuesToTagFilters = (t: TagValue[]): TagFilter[] => {
    let tf: TagFilter[] = [];
    for (let i = 0; i < t.length; i++) {
        const tag = t[i];
        tf.push(tagValueToTagFilter(tag));
    }
    return tf;
};

const tagTypeColors = {
    "text": '#333333',
    "1": '#8db6fd',
    "2": '#FFFF66',
    "3": '#fdbf96',
    "4": '#66fc66',
    "5": '#ff9df5',
    "6": '#DCDCDC',
    "7": '#B0E0E6',
    "8": '#F5F5DC',
    "9": '#40E0D0',
    "10": '#FFA07A',
}

export default function TagsSearchFilter({ entityId, includeTags, excludeTags, tagSource, setAvailableTagsHandler, addHandler, removeHandler, loadingHandler, errorHandler, children }: PropsWithChildren<Props>) {
    const [tagGroups, setTagGroups] = useState<TagFilterGroup[]>([]);
    const [myTagGroups, setMyTagGroups] = useState<TagFilterGroup[]>([]);
    const [fetchingSettings, setFetchingSettings] = useState(false);
    const [fetchingTags, setFetchingTags] = useState(false);
    const [isCreatingTagGroup, setIsCreatingTagGroup] = useState(false);
    const [tagSettings, setTagSettings] = useState<TagSettings[] | null>(null);

    useEffect(() => {
        const getTagSettings = async () => {
            setFetchingSettings(true);
            const res = await GetMyTagsSettings(entityId, true, errorHandler);
            if (res && res.length > 0) setTagSettings(res);
            setFetchingSettings(false);
        };
        getTagSettings();
    }, [entityId, errorHandler]);

    const createTagGroups = useCallback((tagSettings: TagSettings[], tags: TagValue[], source: 'a' | 'm') => {
        setIsCreatingTagGroup(true);
            let availableTags = [...tags];

            let tg1: TagFilterGroup | null = null;
            let tg2: TagFilterGroup | null = null;
            let tg3: TagFilterGroup | null = null;
            let tg4: TagFilterGroup | null = null;
            let tg5: TagFilterGroup | null = null;
            let tg6: TagFilterGroup | null = null;
            let tg7: TagFilterGroup | null = null;
            let tg8: TagFilterGroup | null = null;
            let tg9: TagFilterGroup | null = null;
            let tg10: TagFilterGroup | null = null;

            for (let i = 0; i < tagSettings.length; i++) {
                const setting = tagSettings[i];
                const filters: TagFilter[] = [];
                const title = setting.tagAgencyName !== '' ? setting.tagAgencyName : setting.databaseName;
                if (setting.tagTypeId === 1) tg1 = { typeId: setting.tagTypeId, isStructured: setting.structured, tags: filters, title, color: tagTypeColors[1] };
                else if (setting.tagTypeId === 2) tg2 = { typeId: setting.tagTypeId, isStructured: setting.structured, tags: filters, title, color: tagTypeColors[2] };
                else if (setting.tagTypeId === 3) tg3 = { typeId: setting.tagTypeId, isStructured: setting.structured, tags: filters, title, color: tagTypeColors[3] };
                else if (setting.tagTypeId === 4) tg4 = { typeId: setting.tagTypeId, isStructured: setting.structured, tags: filters, title, color: tagTypeColors[4] };
                else if (setting.tagTypeId === 5) tg5 = { typeId: setting.tagTypeId, isStructured: setting.structured, tags: filters, title, color: tagTypeColors[5] };
                else if (setting.tagTypeId === 6) tg6 = { typeId: setting.tagTypeId, isStructured: setting.structured, tags: filters, title, color: tagTypeColors[6] };
                else if (setting.tagTypeId === 7) tg7 = { typeId: setting.tagTypeId, isStructured: setting.structured, tags: filters, title, color: tagTypeColors[7] };
                else if (setting.tagTypeId === 8) tg8 = { typeId: setting.tagTypeId, isStructured: setting.structured, tags: filters, title, color: tagTypeColors[8] };
                else if (setting.tagTypeId === 9) tg9 = { typeId: setting.tagTypeId, isStructured: setting.structured, tags: filters, title, color: tagTypeColors[9] };
                else if (setting.tagTypeId === 10) tg10 = { typeId: setting.tagTypeId, isStructured: setting.structured, tags: filters, title, color: tagTypeColors[10] };
            }
            
            for (let i = 0; i < availableTags.length; i++) {
                const tag = tagValueToTagFilter(availableTags[i]);
                if (tg1 && tag.typeId === 1) tg1.tags.push(tag);
                if (tg2 && tag.typeId === 2) tg2.tags.push(tag);
                if (tg3 && tag.typeId === 3) tg3.tags.push(tag);
                if (tg4 && tag.typeId === 4) tg4.tags.push(tag);
                if (tg5 && tag.typeId === 5) tg5.tags.push(tag);
                if (tg6 && tag.typeId === 6) tg6.tags.push(tag);
                if (tg7 && tag.typeId === 7) tg7.tags.push(tag);
                if (tg8 && tag.typeId === 8) tg8.tags.push(tag);
                if (tg9 && tag.typeId === 9) tg9.tags.push(tag);
                if (tg10 && tag.typeId === 10) tg10.tags.push(tag);
            }
            setAvailableTagsHandler(tagValuesToTagFilters(availableTags));
            let tg: TagFilterGroup[] = [];
            tg1 && tg.push(tg1);
            tg2 && tg.push(tg2);
            tg3 && tg.push(tg3);
            tg4 && tg.push(tg4);
            tg5 && tg.push(tg5);
            tg6 && tg.push(tg6);
            tg7 && tg.push(tg7);
            tg8 && tg.push(tg8);
            tg9 && tg.push(tg9);
            tg10 && tg.push(tg10);
            source === 'a' && setTagGroups(tg);
            source === 'm' && setMyTagGroups(tg);

            setIsCreatingTagGroup(false);
    }, [setAvailableTagsHandler]);

    useEffect(() => {
        const getTags = async () => {
            if (tagSettings && tagSettings.length > 0 && tagSource === 'a' && tagGroups.length === 0) {
                setFetchingTags(true);
                const res = await GetCustomerTags(entityId, errorHandler);
                setFetchingTags(false);
                if (res) createTagGroups(tagSettings, res, tagSource);
            }
            else if (tagSettings && tagSettings.length > 0 && tagSource === 'm' && myTagGroups.length === 0) {
                setFetchingTags(true);
                const res = await GetMyCustomerTags(entityId, errorHandler);
                setFetchingTags(false);
                if (res) createTagGroups(tagSettings, res, tagSource);
            }
        };
        tagSettings && tagSettings.length > 0 && (tagGroups.length === 0 || myTagGroups.length === 0) && getTags();
    }, [entityId, tagGroups, myTagGroups, tagSettings, tagSource, createTagGroups, errorHandler]);
    
    const assignedTags = useMemo(() => {
        return [...includeTags, ...excludeTags];
    }, [includeTags, excludeTags]);

    useEffect(() => {
        loadingHandler && loadingHandler(fetchingSettings || isCreatingTagGroup || fetchingTags);
    }, [fetchingSettings, fetchingTags, isCreatingTagGroup, loadingHandler]);


    if (tagSettings && tagSettings.length > 0) {
        return (
            <>
                {children}
                <Box display="flex" flexWrap="wrap" justifyContent="center" >
                    { (tagSource === 'a' ? tagGroups : myTagGroups).map((g, i) =>
                        <TagFilterSelectionList
                            key={i}
                            title={g.title}
                            availableTags={g.tags}
                            assignedTags={assignedTags}
                            color={g.color}
                            textColor={tagTypeColors.text}
                            addHandler={addHandler}
                            removeHandler={removeHandler}
                        />
                    )}
                </Box>
                <Box>
                    {"Include Tags: "}
                    { includeTags.map((t, i) =>
                        <Chip
                            key={`${i}-${t.typeId}-${t.tag}`}
                            size="small"
                            label={t.source === 'm' ? t.tag + ' (Me)' : t.tag}
                            onDelete={ () => removeHandler(t) }
                            sx={{ bgcolor: (tagTypeColors as any)[t.typeId], color: tagTypeColors.text, mr: '5px' }}
                            deleteIcon={ <CancelIcon style={{ color: tagTypeColors.text }} /> }
                        />
                    )}
                </Box>
                <Box>
                    {"Exclude Tags: "}
                    { excludeTags.map((t, i) =>
                        <Chip
                            key={`${i}-${t.typeId}-${t.tag}`}
                            size="small"
                            label={t.source === 'm' ? t.tag + ' (Me)' : t.tag}
                            onDelete={ () => removeHandler(t) }
                            sx={{ bgcolor: (tagTypeColors as any)[t.typeId], color: tagTypeColors.text, mr: '5px' }}
                            deleteIcon={ <CancelIcon style={{ color: tagTypeColors.text }} /> }
                        />
                    )}
                </Box>
            </>
        );
    }
    else return <></>;
}