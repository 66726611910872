import React, { useMemo, useState, useEffect } from "react";
import OnlyTitleSummaryBar from "components/SummaryBars/OnlyTitleSummaryBar";
import PageContentLayout from "layouts/PageContentLayout";
import PageLayout from "layouts/PageLayout";
import { ContactImport } from "common/models/Imports/ContactImport";
import { ImportContact } from "services/ContactsService";
import { GetMyUser } from "services/UsersService";

import Snackbar from "@mui/material/Snackbar";
import Alert from "components/Alert";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

// @ts-ignore
import { CSVBoxButton } from '@csvbox/react'

export default function ContactImportPage() {
    const [isLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [consultantId, setConsultantId] = useState(0);

    useEffect(() => {
        const getUser = async () => {
            const u = await GetMyUser();
            if (u) setConsultantId(u.userID);
        };
        getUser();
    }, []);

    const summaryBar = useMemo(() => {
        return <OnlyTitleSummaryBar title="Contacts > Import Spreadsheet" browserTabTitle="Import Spreadsheet > Contacts" />;
    }, []);

    function castJsonToType<T>(json: string): T {
        try {
            const parsedJson = JSON.parse(json);
            return parsedJson as T;
        } catch (e) {
            const m = (e as any).message;
            throw new Error(`Failed to cast JSON to type: ${m}`);
        }
    }
    
    return (
        <PageLayout SummaryBar={summaryBar}>
            <Snackbar open={successMessage !== ''} autoHideDuration={3000} onClose={() => setSuccessMessage('')}>
                <Alert onClose={() => setSuccessMessage('')}>{ successMessage }</Alert>
            </Snackbar>
            <Snackbar open={errorMessage !== ''} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <PageContentLayout showLoading={ isLoading }>
                <CSVBoxButton
                    licenseKey="3Z9DkTXnApXZNkk5gkzA1ooACN37gU"
                    user={{
                        user_id: consultantId,
                    }}
                    onImport={(result: any, data: any) => {
                        if (result) {
                            const json = JSON.stringify(data);

                            const obj = castJsonToType<ContactImport>(json);

                            obj.rows.forEach(function (value) {
                                const importData = async () => {                                    
                                    await ImportContact(value, setErrorMessage);
                                };
                                importData();
                            }); 

                            setSuccessMessage(data.row_success + " rows imported");
                        } else {
                            setErrorMessage("fail");
                        }
                    }}
                    render={(launch: any, isLoading: any) => {
                        return <Box textAlign='center'><Button variant="contained" disabled={isLoading} onClick={launch}>Start Import</Button></Box>;
                    }}
                >
                    Import
                </CSVBoxButton>
            </PageContentLayout>
        </PageLayout>
    );
}