import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-premium";
import React, { useMemo } from "react";
import { ActiveInterview } from "common/models/Analytics/BusinessIntelligence";
import GridWithStateWrapper from "components/GridWidthStateWrapper";

interface Props {
    gridName: string,
    data: ActiveInterview[] | null
}

export default function ActiveInterviewsTableGraph({ gridName, data }: Props) {
    const columns = useMemo<GridColDef[]>(() => {
        const tooltipRenderer = (params: GridRenderCellParams) => {
            return <div title={params.value}>{params.value}</div>
        };

        return [
            { field: 'candidate', headerName: 'Candidate', flex: 1, renderCell: tooltipRenderer },
            { field: 'jobTitle', headerName: 'Job Title', flex: 1, renderCell: tooltipRenderer },
            { field: 'client', headerName: 'Client', flex: 1, renderCell: tooltipRenderer },
            { field: 'stage', headerName: 'Stage', width: 70 },
        ];
    }, []);

    const titleCounter = useMemo(() => {
        if (data) return ` (${data.length})`;
        return '';
    }, [data]);

    return (
        <Paper sx={{ height: '100%', width: '100%' }}>
            <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                <Typography variant="h6" padding={1} textAlign="center" fontWeight={600} component="div">Active Interviews{titleCounter}</Typography>
                <GridWithStateWrapper
                    noContainer
                    gridName={gridName}
                    density="compact"
                    loading={data === null}
                    rows={ data ?? [] }
                    columns={columns}
                    getRowId={r => `${r.candidateID}-${r.jobID}`}
                    disableRowSelectionOnClick
                    hideFooter
                />
            </div>
        </Paper>
    );
}