import Alert from "components/Alert";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Division } from "common/models/Configuration/Division";
import { TagFilter } from "common/models/Configuration/Tags";
import DivisionPicker from "components/Pickers/DivisionPicker";
import UserPicker from "components/Pickers/UserPicker";
import TagsSearchFilter from "components/Tags/TagsSearchFilter";
import PageContentLayout from "layouts/PageContentLayout";
import PageLayout from "layouts/PageLayout";
import Button from "@mui/material/Button";
import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import { PlacementSearch } from "common/models/Search/PlacementSearch";
import PlacementsGridComponent from "components/Grids/PlacementsGrid";
import { NameIdObj } from "common/models/GenericTypes";
import { SxProps } from "@mui/material/styles";
import { useNavigate, useSearchParams } from "react-router-dom";
import SaveSearchDialog from "components/Dialogs/SaveSearchDialog";
import { SavePlacementsSearch, UpdatePlacementsSavedSearch } from "services/PlacementsService";
import { GetSavedSearchById } from "services/SavedSearchesService";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

const ownerSpecialOptions: NameIdObj[] = [
    { id: -1, name: 'Me' },
    { id: -2, name: 'My Team' },
    { id: 0, name: 'My Company' },
];

const divisionSpecialOptions: Division[] = [
    { id: 0, automatedRejectionDelay: 0, name: 'All', shortName: 'All', active: true, country: '', countryName: '', timeZone: '', timesheetSystem: 0 }
];

const filterElementStyle: SxProps = { flex: '1 1 0' };

export default function PlacementSearchPage() {
    const [activeTab, setActiveTab] = useState('criteria');
    const [isLoading, setIsLoading] = useState(false);
    const [isGridLoading, setIsGridLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [searchData, setSearchData] = useState<PlacementSearch>();
    const [showGrid, setShowGrid] = useState(false);
    const [status, setStatus] = useState(0);
    const [approved, setApproved] = useState(3);
    const [ownerId, setOwnerId] = useState(0);
    const [type, setType] = useState(0);
    const [division, setDivision] = useState(0);
    const [taggedBy, setTaggedBy] = useState<'a' | 'm'>('a');
    const [taggedWithin, setTaggedWithin] = useState(999);
    const [isFetchingSavedSearch, setIsFetchingSavedSearch] = useState(false);
    const [showSaveSearchDialog, setShowSaveSearchDialog] = useState(false);
    const [savedSearchName, setSavedSearchName] = useState('');
    const [shouldUpdateSavedSearch, setShouldUpdateSavedSearch] = useState(false);
    const [loadedSavedSearchName, setLoadedSavedSearchName] = useState('');
    const [loadedSavedSearchFrequencyId, setLoadedSavedSearchFrequencyId] = useState(0);
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    
    const [isIncludeTag, setIsIncludeTag] = useState(true);
    const [includeTags, setIncludeTags] = useState<TagFilter[]>([]);
    const [excludeTags, setExcludeTags] = useState<TagFilter[]>([]);
    const [availableTags_All, setAvailableTags_All] = useState<TagFilter[]>([]);
    const [availableTags_Me, setAvailableTags_Me] = useState<TagFilter[]>([]);

    const ssid = useMemo(() => {
        const v = searchParams.get('ssid');
        if (v) return +v;
        return null;
    }, [searchParams]);

    useEffect(() => {
        const getSavedSearch = async () => {
            if (ssid) {
                setIsFetchingSavedSearch(true);
                const res = await GetSavedSearchById(ssid, setErrorMessage);
                if (res && res.json) {
                    setSavedSearchName(res.name);
                    const t1 = JSON.parse(res.json);
                    if (t1.status) setStatus(+t1.status);
                    if (t1.tagsTimeRange) setTaggedWithin(+t1.tagsTimeRange);
                    if (t1.division) setDivision(+t1.division);
                    if (t1.owner) {
                        if (t1.owner === 'All') setOwnerId(0);
                        else if (t1.owner === 'Me') setOwnerId(-1);
                        else if (t1.owner === 'Team') setOwnerId(-2);
                        else setOwnerId(+t1.owner);
                    }
                    
                    if (t1.approved === 'True') setApproved(1);
                    else if (t1.approved === 'False') setApproved(0);
                    else if (t1.approved === 'All') setApproved(3);

                    if (t1.jobtype) setType(+t1.jobtype);

                    if (t1.tags && t1.tags !== '' && t1.tags !== '[]') {
                        const tags = JSON.parse(t1.tags);
                        let inc: TagFilter[] = [];
                        let exc: TagFilter[] = [];
                        for (let i = 0; i < tags.length; i++) {
                            const {id, name, type} = tags[i];
                            const [tName, tTypeId] = (name as string).split('__');
                            if ((id as string).includes('_EXCLUDE')) exc.push({ source: type === 'm' ? 'm' : 'a', tag: tName, typeId: +tTypeId });
                            else inc.push({ source: type === 'm' ? 'm' : 'a', tag: tName, typeId: +tTypeId });
                        }
                        setIncludeTags(inc);
                        setExcludeTags(exc);
                    }

                    setLoadedSavedSearchName(res.name);
                    setLoadedSavedSearchFrequencyId(res.autoRunFrequency);
                }
                setIsFetchingSavedSearch(false);
            }
        };
        ssid && getSavedSearch();
    }, [ssid]);

    useEffect(() => {
        if(ssid && !isFetchingSavedSearch) {
            setShouldUpdateSavedSearch(true);
        }
    }, [ssid, isFetchingSavedSearch, includeTags, excludeTags, ownerId, status, division, taggedWithin]);

    const searchCallback = useCallback(async () => {
        let s: PlacementSearch = {
            status: status,
            approved: approved,
            owner: ownerId,
            type: type,
            division: division,
            taggedTimeRange: taggedWithin
        };

        if (includeTags.length > 0) s.includeTags = includeTags;
        if (excludeTags.length > 0) s.excludeTags = excludeTags;

        if (shouldUpdateSavedSearch && ssid) {
            const res = await UpdatePlacementsSavedSearch(ssid, loadedSavedSearchName, loadedSavedSearchFrequencyId, s);
            if (res) setShouldUpdateSavedSearch(false);
        }

        setSearchData(s);
        setShowGrid(true);
        setActiveTab('results');
    }, [status, approved, ownerId, type, division, taggedWithin, includeTags, excludeTags, shouldUpdateSavedSearch, ssid, loadedSavedSearchName, loadedSavedSearchFrequencyId]);

    const saveSearchCallback = useCallback(async (searchName: string, searchFreq: number) => {
        let s: PlacementSearch = {
            status: status,
            approved: approved,
            owner: ownerId,
            type: type,
            division: division,
            taggedTimeRange: taggedWithin
        };

        if (includeTags.length > 0) s.includeTags = includeTags;
        if (excludeTags.length > 0) s.excludeTags = excludeTags;

        setIsLoading(true);
        var newSsid = await SavePlacementsSearch(searchName, searchFreq, s, setErrorMessage);
        if (newSsid) {
            setShowSaveSearchDialog(false);
            navigate(`/placements?ssid=${newSsid.value}`);
        }
        setIsLoading(false);
    }, [approved, division, excludeTags, includeTags, navigate, ownerId, status, taggedWithin, type]);

    const clearCallback = useCallback(() => {
        setOwnerId(0);
        setStatus(0);
        setType(0);
        setApproved(3);
        setDivision(0);
        setIncludeTags([]);
        setExcludeTags([]);
        setShowGrid(false);
    }, []);

    useEffect(() => {
        if (!ssid) {
            setSavedSearchName('');
            clearCallback();
        }
    }, [clearCallback, ssid]);

    const summaryBar = useMemo(() => {
        const clearAction = <Button variant="contained" color="error" onClick={clearCallback} sx={{ mr: '5px' }}>Clear</Button>;
        const saveAction = <Button variant="contained" onClick={() => setShowSaveSearchDialog(true)} sx={{ mr: '5px' }}>Save</Button>;
        const searchAction = <Button variant="contained" color="success" onClick={searchCallback}>Search</Button>;
        const actions = showGrid && !Boolean(ssid) ? <>{clearAction}{saveAction}{searchAction}</> : <>{clearAction}{searchAction}</>;

        const title = Boolean(savedSearchName) ? `Placements > Search > ${savedSearchName}` : 'Placements > Search';
        return <TitleAndActionSummaryBar title={title} browserTabTitle="Search > Placements" action={actions} />
    }, [clearCallback, savedSearchName, searchCallback, showGrid, ssid]);

    const handleRemoveCallback = useCallback((data: TagFilter) => {
        const includeIndex = includeTags.findIndex(t => t.typeId === data.typeId && t.tag === data.tag);

        if (includeIndex !== -1) {
            setIncludeTags(prev => {
                let tmp = [...prev];
                const index = tmp.findIndex(t => t.typeId === data.typeId && t.tag === data.tag);
                if (index !== -1) tmp.splice(index, 1);
                return tmp;
            });
        }
        else {
            setExcludeTags(prev => {
                let tmp = [...prev];
                const index = tmp.findIndex(t => t.typeId === data.typeId && t.tag === data.tag);
                if (index !== -1) tmp.splice(index, 1);
                return tmp;
            });
        }

    }, [includeTags]);

    const handleAddCallback = useCallback((typeId: number, tag: string) => {
        const selectedTag = (taggedBy === 'a' ? availableTags_All : availableTags_Me).find(t => t.typeId === typeId && t.tag === tag);
        if (isIncludeTag) {
            setIncludeTags(prev => {
                const assignedTagIndex = prev.findIndex(t => t.typeId === typeId && t.tag === tag);
                if (assignedTagIndex === -1 && selectedTag) {
                    return [...prev, {...selectedTag, source: taggedBy}]
                }
                return prev;
            });
        }
        else {
            setExcludeTags(prev => {
                const assignedTagIndex = prev.findIndex(t => t.typeId === typeId && t.tag === tag);
                if (assignedTagIndex === -1 && selectedTag) {
                    return [...prev, {...selectedTag, source: taggedBy}]
                }
                return prev;
            });

        }
    }, [isIncludeTag, availableTags_All, availableTags_Me, taggedBy]);

    useEffect(() => {
        const item = document.querySelector<HTMLElement>('#placement-search-collapse-root>.MuiCollapse-wrapper>.MuiCollapse-wrapperInner');
        if (item) {
            item.style.display = 'flex';
            item.style.flexDirection = 'column';
        }
    }, []);

    useEffect(() => setIsLoading(isFetchingSavedSearch || isGridLoading), [isFetchingSavedSearch, isGridLoading]);

    const handleTabChange = useCallback((e: React.SyntheticEvent, newValue: string) => {
        setActiveTab(newValue);
    }, []);
    
    return (
        <PageLayout SummaryBar={summaryBar} paddingTop={0}>
            <Snackbar open={successMessage !== ''} autoHideDuration={3000} onClose={() => setSuccessMessage('')}>
                <Alert onClose={() => setSuccessMessage('')}>Changes Saved</Alert>
            </Snackbar>
            <Snackbar open={errorMessage !== ''} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <SaveSearchDialog open={showSaveSearchDialog} closeHandler={() => setShowSaveSearchDialog(false)} saveHandler={saveSearchCallback} disableFrequencyField />
            <Tabs value={activeTab} onChange={handleTabChange}>
                <Tab value="criteria" label="Criteria" />
                <Tab value="results" label="Results"/>
            </Tabs>
            <PageContentLayout showLoading={isLoading}>
                <Stack spacing={2} paddingTop={2} display={activeTab === 'criteria' ? undefined : 'none'}>
                    <Box display="flex" gap={1}>
                        <UserPicker label="Consultant" userId={ownerId} onSelect={u => u && setOwnerId(u.id)} appendToStart={ownerSpecialOptions} sx={filterElementStyle} />
                        <TextField select label="Status" value={status.toString()} onChange={ ({target}) => setStatus(+target.value) } sx={filterElementStyle}>
                            <MenuItem value="0" >All</MenuItem>
                            <MenuItem value="12">Cancelled</MenuItem>
                            <MenuItem value="8">Completed</MenuItem>
                            <MenuItem value="6">Current</MenuItem>
                            <MenuItem value="10">Deleted</MenuItem>
                            <MenuItem value="4">Future</MenuItem>
                            <MenuItem value="13">Pending Early Termination</MenuItem>
                            <MenuItem value="14">Early Terminated</MenuItem>
                            <MenuItem value="5">Warranty</MenuItem>
                        </TextField>
                        <TextField select label="Type" value={type.toString()} onChange={({ target }) => setType(+target.value)} sx={filterElementStyle}>
                            <MenuItem value="0">All</MenuItem>
                            <MenuItem value="1">Permanent</MenuItem>
                            <MenuItem value="2">Contract</MenuItem>
                            <MenuItem value="3">Fixed Term</MenuItem>
                            <MenuItem value="4">Maximum Term</MenuItem>
                            <MenuItem value="5">Margin Only</MenuItem>
                        </TextField>
                    </Box>
                    <Box display="flex" gap={1}>
                        <TextField select label="Approved" value={approved.toString()} onChange={ ({target}) => setApproved(+target.value) } sx={filterElementStyle}>
                            <MenuItem value="3">All</MenuItem>
                            <MenuItem value="1">Yes</MenuItem>
                            <MenuItem value="0">No</MenuItem>
                        </TextField>
                        <DivisionPicker divisionId={division} onSelectCallback={d => d !== null && setDivision(d)} appendToStart={divisionSpecialOptions} sx={filterElementStyle} />
                    </Box>
                    <TagsSearchFilter
                        entityId={5}
                        includeTags={includeTags}
                        excludeTags={excludeTags}
                        tagSource={taggedBy}
                        addHandler={handleAddCallback}
                        removeHandler={handleRemoveCallback}
                        setAvailableTagsHandler={taggedBy === 'a' ? setAvailableTags_All : setAvailableTags_Me}
                        loadingHandler={setIsLoading}
                        errorHandler={setErrorMessage}
                    >
                        <Box>
                            <Box>
                                Exclude
                                <Switch checked={isIncludeTag} onChange={({target}) => setIsIncludeTag(target.checked)} />
                                Include
                            </Box>
                            <TextField select label="Tagged By" value={taggedBy} onChange={ ({target}) => setTaggedBy(target.value === 'm' ? 'm' : 'a') }>
                                <MenuItem value="m">Me</MenuItem>
                                <MenuItem value="a">Everyone</MenuItem>
                            </TextField>
                            <TextField select label="Tagged Within" value={taggedWithin.toString()} onChange={ ({target}) => setTaggedWithin(+target.value) }>
                                <MenuItem value="999">Anytime</MenuItem>
                                <MenuItem value="1">1 Months</MenuItem>
                                <MenuItem value="2">2 Months</MenuItem>
                                <MenuItem value="3">3 Months</MenuItem>
                                <MenuItem value="6">6 Months</MenuItem>
                                <MenuItem value="12">1 Year</MenuItem>
                                <MenuItem value="24">2 Years</MenuItem>
                                <MenuItem value="36">3 Years</MenuItem>
                            </TextField>
                        </Box>
                    </TagsSearchFilter>
                </Stack>
                <Box display={activeTab === 'results' ? 'flex' : 'none'} flexGrow={1} flexDirection="column">
                    <PlacementsGridComponent gridName="placement-search" source="search" searchData={searchData} loadingHandler={setIsGridLoading} errorHandler={setErrorMessage} successHandler={setSuccessMessage} />
                </Box>
            </PageContentLayout>
        </PageLayout>
    );
}