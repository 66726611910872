import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import moment from "moment";
import React, { useCallback, useState } from "react";
import * as XLSX from "xlsx";
import { GetForecastUserDetailedReportData } from "services/MiscReportsService";
import UserPicker from "components/Pickers/UserPicker";

interface Props {
    description: string,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void
}

export default function ForecastUserDetailedReport({ description, loadingHandler, errorHandler }: Props) {
    const [ownerId, setOwnerId] = useState(0);
    const [ownerName, setOwnerName] = useState("");

    const getDataCallback = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        const res = await GetForecastUserDetailedReportData(ownerId, errorHandler);
        if (res) {
            const m = moment();
            const filename = `${ownerName}_AnalyticsForcastUserDetailed_${m.format('YYYYMMDDhhmmss')}.xlsx`;
            const wb = XLSX.utils.book_new();
            for (var key of Object.keys(res)) {
                const ws = XLSX.utils.json_to_sheet(res[key]);
                XLSX.utils.book_append_sheet(wb, ws, key.charAt(0).toUpperCase() + key.slice(1));
            }
            XLSX.writeFile(wb, filename);
        }
        loadingHandler && loadingHandler(false);
    }, [ownerId, ownerName, errorHandler, loadingHandler]);

    return (
        <>
            <Box display="flex">
                <Box flex="1 1 0" pr={1}>
                    <UserPicker disableClearable label="Consultant" userId={ownerId} onSelect={u => { setOwnerId(u ? u.id : 0); setOwnerName(u ? u.name : "") }} includeInactiveUsers={true} />
                </Box>
                <Button variant="contained" color="success" onClick={getDataCallback}>Run Report</Button>
            </Box>
            <Typography component="div" variant="h6" mt={2}>Report Description</Typography>
            <Typography component="div" variant="body2">{description}</Typography>
        </>
    );
}