import React from "react";
import Paper from "@mui/material/Paper";

interface Props {
    height?: number | string
}

export default function SummaryBarContainer(props: React.PropsWithChildren<Props>) {
    return (
        <Paper
            square
            variant="outlined"
            sx={{ 
                px: '20px',
                // height: '3rem',
                height: props.height ?? '3rem',
                borderRight: 'none',
                borderLeft: 'none',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between' }}
        >
            { props.children }
        </Paper>
    );
}