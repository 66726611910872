import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { GridColDef } from "@mui/x-data-grid-premium";
import { BasicFilter } from "common/models/Analytics/AnalyticsFilterData";
import { Forecast } from "common/models/Analytics/Forecasts";
import GridWithStateWrapper from "components/GridWidthStateWrapper";
import React, { useEffect, useMemo, useState } from "react";
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { GetCustomForecasts } from "services/ForecastsService";

interface Props {
    filterData: BasicFilter,
    targetLabelOverride?: string,
    stretchLabelOverride?: string,
    graphType?: 'table' | 'graph',
    loadingHandler?: (isLoading: boolean) => void
}

interface ForecastWithId extends Forecast {
    id: number,
}

const gridName = 'analytics/performance/forecast-vs-target';

export default function PerformanceForecastVsTargetContent({ filterData, graphType = 'graph', targetLabelOverride = 'Target', stretchLabelOverride = 'Stretch Target', loadingHandler }: Props) {
    const [rows, setRows] = useState<ForecastWithId[]>([]);
    const t = useTheme();

    useEffect(() => {
        const getData = async () => {
            loadingHandler && loadingHandler(true);
            const res = await GetCustomForecasts(filterData);
            if (res) {
                const data: ForecastWithId[] = res.map((r, i) => ({ ...r, id: i }));
                setRows(data);
            }
            loadingHandler && loadingHandler(false);
        };
        getData();
    }, [filterData, loadingHandler]);

    const columns = useMemo<GridColDef[]>(() => {
        // const percentRenderer = (params: GridRenderCellParams) => {
        //     return `${params.value} %`
        // };

        return [
            { field: 'period', headerName: 'Period', width: 250 },
            { field: 'totalForecast', headerName: 'Forecast', headerAlign: 'center', align: 'right', width: 150 },
            { field: 'target', headerName: targetLabelOverride, headerAlign: 'center', align: 'right', width: 150 },
            { field: 'percentageTarget', headerName: `% ${targetLabelOverride}`, headerAlign: 'center', align: 'right', width: 150 },
            { field: 'stretch', headerName: stretchLabelOverride, headerAlign: 'center', align: 'right', width: 150 },
            { field: 'percentageStretch', headerName: `% ${stretchLabelOverride}`, headerAlign: 'center', align: 'right', width: 150 },
        ];
    }, [stretchLabelOverride, targetLabelOverride]);

    if (graphType === 'table') {
        return (
            <GridWithStateWrapper
                gridName={gridName}
                rows={rows}
                density="compact"
                columns={columns}
                sx={{ mt: '20px' }}
            />
        );
    }
    else if (graphType === 'graph') {
        return (
            <>
                <Typography variant="h6" padding={1} textAlign="center" fontWeight={600} component="div">Forecast Against Target</Typography>
                <div style={{ height: 'calc(100% - 48px)' }}>
                    <ResponsiveContainer width="100%" height="100%">
                        <BarChart width={150} height={150} data={rows}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="period" stroke={t.palette.text.primary} />
                            <YAxis type="number" width={100} tickFormatter={v => v.toLocaleString()} stroke={t.palette.text.primary} />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="totalForecast" name="Forecast" fill={t.palette.info.main} offset="positive" />
                            <Bar dataKey="target" name={targetLabelOverride} fill={t.palette.error.main} offset="positive" />
                            <Bar dataKey="stretch" name={stretchLabelOverride} fill={t.palette.primary.main} offset="positive" />
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </>
        );
    }

    return <></>;
}