import Alert from "components/Alert";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { UserIntegration } from "common/models/Configuration/UserIntegrations";
import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import PageContentLayout from "layouts/PageContentLayout";
import PageLayout from "layouts/PageLayout";
import { Connect_Gmail, Connect_LinkedIn, Connect_MicrosoftTeams, Connect_OneDrive, Connect_Zoom, Disconnect_Gmail, Disconnect_LinkedIn, Disconnect_MicrosoftTeams, Disconnect_OneDrive, Disconnect_Zoom, GetMyUserIntegrationById, GetRedirectUrl_Gmail, GetRedirectUrl_LinkedIn, GetRedirectUrl_MicrosoftTeams, GetRedirectUrl_OneDrive, GetRedirectUrl_Zoom } from "services/UserIntegrationsService";

export default function UserIntegrationItem() {
    const [isLoading, setIsLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [summaryBar, setSummaryBar] = useState<JSX.Element>(<></>);
    const [integration, setIntegration] = useState<UserIntegration | null>(null);
    const [searchParams] = useSearchParams();
    const params = useParams();

    const itemId = useMemo(() => {
        const parsedId = +(params.id ?? '0');
        if(isNaN(parsedId)) return 0;
        return parsedId
    }, [params.id]);
    
    useEffect(() => {
        const getData = async () => {
            setIsLoading(true);
            const data = await GetMyUserIntegrationById(itemId, setErrorMessage);
            if(data) setIntegration(data);
            setIsLoading(false);
        };
        getData();
    }, [itemId]);

    useEffect(() => {
        const handleFromRedirect = async () => {
            if (integration && integration.statusID === 0) {
                setIsLoading(true);
                switch (itemId) {
                    case 1:
                        const code1 = searchParams.get('code');
                        if (code1) {
                            const res = await Connect_OneDrive(code1, setErrorMessage);
                            if (res) {
                                setSuccessMessage('Connected');
                                setIntegration(prev => prev ? ({...prev, statusID: 1}) : prev);
                            }
                        }
                    break;
                    case 3:
                        const code3 = searchParams.get('code');
                        if (code3) {
                            const res = await Connect_LinkedIn(code3, setErrorMessage);
                            if (res) {
                                setSuccessMessage('Connected');
                                setIntegration(prev => prev ? ({...prev, statusID: 1}) : prev);
                            }
                        }
                    break;
                    case 4:
                        const code4 = searchParams.get('code');
                        if (code4) {
                            const res = await Connect_Gmail(code4, setErrorMessage);
                            if (res) {
                                setSuccessMessage('Connected');
                                setIntegration(prev => prev ? ({...prev, statusID: 1}) : prev);
                            }
                        }
                    break;
                    case 6:
                        const code6 = searchParams.get('code');
                        if (code6) {
                            const res = await Connect_MicrosoftTeams(code6, setErrorMessage);
                            if (res) {
                                setSuccessMessage('Connected');
                                setIntegration(prev => prev ? ({...prev, statusID: 1}) : prev);
                            }
                        }
                    break;
                    case 9:
                        const code9 = searchParams.get('code');
                        if (code9) {
                            const res = await Connect_Zoom(code9, setErrorMessage);
                            if (res) {
                                setSuccessMessage('Connected');
                                setIntegration(prev => prev ? ({...prev, statusID: 1}) : prev);
                            }
                        }
                    break;
                }
                setIsLoading(false);
            }
        };
        integration && integration.statusID === 0 && handleFromRedirect();
    }, [integration, itemId, searchParams]);

    const connectCallback = useCallback(async () => {
        setIsLoading(true);
        switch (itemId) {
            case 1:
                const res1 = await GetRedirectUrl_OneDrive(setErrorMessage);
                if (res1 && res1.value) window.open(res1.value, "_self");
                break;
            case 3:
                const res3 = await GetRedirectUrl_LinkedIn(setErrorMessage);
                if (res3 && res3.value) window.open(res3.value, "_self");
                break;
            case 4:
                const res4 = await GetRedirectUrl_Gmail(setErrorMessage);
                if (res4 && res4.value) window.open(res4.value, "_self");
                break;
            case 6:
                const res6 = await GetRedirectUrl_MicrosoftTeams(setErrorMessage);
                if (res6 && res6.value) window.open(res6.value, "_self");
                break;
            case 9:
                const res9 = await GetRedirectUrl_Zoom(setErrorMessage);
                if (res9 && res9.value) window.open(res9.value, "_self");
                break;
        }
        setIsLoading(true);
    }, [itemId]);

    const disconnectCallback = useCallback(async () => {
        let success = false;
        setIsLoading(true);
        switch (itemId) {
            case 1:
                const res1 = await Disconnect_OneDrive(setErrorMessage);
                if (res1) success = true;
                break;
            case 3:
                const res3 = await Disconnect_LinkedIn(setErrorMessage);
                if (res3) success = true;
                break;
            case 4:
                const res4 = await Disconnect_Gmail(setErrorMessage);
                if (res4) success = true;
                break;
            case 6:
                const res6 = await Disconnect_MicrosoftTeams(setErrorMessage);
                if (res6) success = true;
                break;
            case 9:
                const res9 = await Disconnect_Zoom(setErrorMessage);
                if (res9) success = true;
                break;
        }

        if (success) {
            setSuccessMessage('Disconnected');
            setIntegration(prev => prev ? ({...prev, statusID: 0}) : prev);
        }
        setIsLoading(false);
    }, [itemId]);

    useEffect(() => {
        const statusId = integration ? integration.statusID : 0;
        const connectButton = (
            <Button
                variant="contained"
                color="success"
                onClick={statusId === 1 ? disconnectCallback : connectCallback}
            >{statusId === 1 ? 'Disconnect' : 'Connect'}</Button>
        );
        const summaryBar = (
            <TitleAndActionSummaryBar
                title="Account > Integrations"
                browserTabTitle="Integrations > Account"
                action={connectButton}
            />
        );
        setSummaryBar && setSummaryBar(summaryBar);
    }, [setSummaryBar, integration, connectCallback, disconnectCallback]);

    return (
        <PageLayout SummaryBar={summaryBar}>
            <Snackbar open={successMessage !== ''} autoHideDuration={3000} onClose={() => setSuccessMessage('')}>
                <Alert onClose={() => setSuccessMessage('')}>{ successMessage }</Alert>
            </Snackbar>
            <Snackbar open={errorMessage !== ''}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <PageContentLayout title={integration != null ? `${integration.name} Integration` : 'Integration'} showLoading={isLoading}>
                {integration && <img src={`https://cdn.recruitwizard.com/images/integrations/${integration.logoUrl}`} style={{ maxHeight: '50px', width: 'fit-content', marginBottom: '20px' }} alt="logo" />}
                {integration !== null && <div dangerouslySetInnerHTML={{ __html: integration.description }} />}
            </PageContentLayout>
        </PageLayout>
    );
}