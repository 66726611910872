import { AuthenticationConfigOptions } from '../../../common/models/Configuration/Authentication';
import { ChangeTracker } from '../../../common/models/hooks/ChangeTracker';

export const DefaultOptions: AuthenticationConfigOptions = {
    SessionTimeout: "86400",
    AllowedLoginMethod: 0
}

export const NoChangesTracker: ChangeTracker<AuthenticationConfigOptions> = {
    SessionTimeout: false,
    AllowedLoginMethod: false,
}