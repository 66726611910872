import IconButton from "@mui/material/IconButton";
import { GridCellEditStopParams, GridCellEditStopReasons, GridColDef, GridColumnVisibilityModel, GridRenderCellParams, GridRenderEditCellParams, GridRowSelectionModel, GridValueGetter, useGridApiRef } from "@mui/x-data-grid-premium";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import SearchIcon from '@mui/icons-material/Search';
import { Job } from "common/models/Jobs/Job";
import { GetMyJobs, GetTeamOpenJobs, GetCompanyOpenJobs, GetMyJobsByStateId, JobLookup, SearchJobs, UpdateJobCustomField, GetUserJobsByStateId } from "services/JobsService";
import GridWithStateWrapper from "../GridWidthStateWrapper";
import ActionsDropDownButton from "../SummaryBars/Actions/ActionsDropsDownMenu";
import MenuItem from "@mui/material/MenuItem";
import PreviewLoaderComponent from "../Previews/PreviewLoader";
import CloseJobsDialog from "../Dialogs/Jobs/CloseJobsDialog";
import Snackbar from "@mui/material/Snackbar";
import Alert from "components/Alert";
import { PreviewEntityType } from "common/models/Previews/Previews";
import { GetClientJobs } from "services/ClientsService";
import { GetContactJobs } from "services/ContactsService";
import { GetSiteJobs } from "services/SitesService";
import { GetJobsByTags } from "services/JobsService";
import TagsManagementDialog from "../Dialogs/TagsManagementDialog";
import { companyHasSinglePermission, userHasSinglePermission } from "util/PermissionsUtils";
import { Permission } from "common/models/Permissions";
import { JobSearch } from "common/models/Search/JobSearch";
import Box from "@mui/material/Box";
import { CustomField, CustomFieldPredefinedValue } from "common/models/Configuration/CustomFields";
import { GetCustomFieldsByEntity_OnlyActive, GetPredefinedValues } from "services/CustomFieldsService";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { customFieldColsVisibility, mandatoryUdfEditValidator, numberUdfEditValidator } from "util/GridUtils";

interface Props {
    source: 'dashboard' | 'dashboard-widget' | 'client-record' | 'contact-record' | 'site-record' | 'tags' | 'my-jobs'| 'team-jobs'| 'company-jobs' | 'consultant-jobs' | 'search' | 'lookup',
    gridName: string,
    tagLabel?: string,
    tagTypeId?: number,
    taggedBy?: number,
    sourceId?: number,
    jobState: number,
    lookupTerm?: string,
    searchData?: JobSearch,
    hideActionsMenu?: boolean,
    setRowCountHandler?: (count: number) => void,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
    extraActions?: JSX.Element
}

interface CustomFieldWithPredefinedValues extends CustomField {
    values?: CustomFieldPredefinedValue[],
}

const linkStyle: React.CSSProperties = { color: 'inherit', textDecoration: 'underline' };
const entityTypeId = 4;

const formatNumber = (value: number) => {
    return value.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 });
};

const defaultColsVisibility: GridColumnVisibilityModel = {
    "id": false,
    "consultant1Name": false,
    "tags": false,
    "siteName": false,
    "consultant2Name": false,
    "startDate": false,
    "endDate": false,
    "contact2Name": false,
    "candidatesFilled": false,
    "createDate": false,
    "clientReference": false,
    "divisionName": false,
    "ratingName": false,
    "liveAdverts": false,
    "payRange": false,
    "dealAmount": false,
    ...customFieldColsVisibility
};

const defaultColsVisibilityDashboardWidget: GridColumnVisibilityModel = {
    "id": false,
    "consultant1Name": false,
    "jobReference": false,
    "tags": false,
    "shortAddress": false,
    "contact1Name": false,
    "typeName": false,
    "deadline": false,
    "statusName": false,
    "stageName": false,
    "estimatedPlacementDate": false,
    "green": false,
    "yellow": false,
    "blue": false,
    "toDo": false,
    "total": false,
    "search": false,
    "candidatesRequired": false,
    "placements": false,
    "siteName": false,
    "consultant2Name": false,
    "startDate": false,
    "endDate": false,
    "contact2Name": false,
    "candidatesFilled": false,
    "createDate": false,
    "clientReference": false,
    "divisionName": false,
    "ratingName": false,
    "liveAdverts": false,
    "payRange": false,
    ...customFieldColsVisibility
};

export default function JobsGridComponent({ source, sourceId, jobState, tagLabel = '', tagTypeId = 0, taggedBy = 0, lookupTerm = '', searchData, setRowCountHandler, loadingHandler, errorHandler, extraActions, hideActionsMenu = false, gridName }: Props) {
    const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
    const [rows, setRows] = useState<Job[]>([]);
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    const [showTagsPrevewNoDelay, setShowTagsPreviewNoDelay] = useState(false);
    const [previewType, setPreviewType] = useState<PreviewEntityType | ''>('');
    const [previewRecordId, setPreviewRecordId] = useState(0);
    const [isPreviewTags, setIsPreviewTags] = useState(false);
    const [previewRecordName, setPreviewRecordName] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isCloseJobsDialogOpen, setIsCloseJobsDialogOpen] = useState(false);
    const [taggingRecords, setTaggingRecords] = useState<number[]>([]);
    const [activeFields, setActiveFields] = useState<CustomFieldWithPredefinedValues[]>([]);
    const [editCellFieldKey, setEditCellFieldKey] = useState<keyof Job | null>(null);
    const apiRef = useGridApiRef();

    const userCanAddEditJobs = useMemo(() => userHasSinglePermission(Permission.JobsAddEdit) && companyHasSinglePermission(Permission.JobsAddEdit), []);

    useEffect(() => {
        const getActiveFields = async () => {
            const customFields = await GetCustomFieldsByEntity_OnlyActive(4);
            if (customFields) {
                let udfDefinitions: CustomFieldWithPredefinedValues[] = [];
                for (let i = 0; i < customFields.length; i++) {
                    const u = customFields[i];
                    if (u.usePredefinedValues) {
                        const vals = await GetPredefinedValues(u.id);
                        if (vals) udfDefinitions.push({ ...u, values: vals });
                        else udfDefinitions.push(u);
                        continue;
                    }
                    
                    udfDefinitions.push(u);
                }
                setActiveFields(udfDefinitions);
            }
        };
        getActiveFields();
    }, []);

    useEffect(() => {
        const getData = async () => {
            loadingHandler && loadingHandler(true);
            let res: Job[] | null = [];
            if (source === 'dashboard') res = await GetMyJobsByStateId(jobState, errorHandler);
            if (source === 'dashboard-widget') res = await GetMyJobsByStateId(jobState, errorHandler);
            if (source === 'my-jobs') res = await GetMyJobs(errorHandler);
            if (source === 'team-jobs') res = await GetTeamOpenJobs(errorHandler);
            if (source === 'company-jobs') res = await GetCompanyOpenJobs(errorHandler);
            if (source === 'consultant-jobs' && sourceId) res = await GetUserJobsByStateId(sourceId, 1, errorHandler);
            if (source === 'client-record' && sourceId) res = await GetClientJobs(sourceId, jobState, errorHandler);
            if (source === 'contact-record' && sourceId) res = await GetContactJobs(sourceId, jobState, errorHandler);
            if (source === 'site-record' && sourceId) res = await GetSiteJobs(sourceId, jobState, errorHandler);
            if (source === 'tags') res = await GetJobsByTags(tagTypeId, taggedBy, tagLabel, errorHandler);
            if (source === 'search' && searchData) res = await SearchJobs(searchData, errorHandler);
            if (source === 'lookup' && lookupTerm) res = await JobLookup(lookupTerm, 0, false, errorHandler);
            if (res) {
                setRowCountHandler && setRowCountHandler(res.length);
                setRows(res);
            }
            loadingHandler && loadingHandler(false);
        };

        getData();
    }, [source, sourceId, jobState, tagLabel, tagTypeId, taggedBy, lookupTerm, searchData, setRowCountHandler, loadingHandler, errorHandler]);

    const columns = useMemo<GridColDef[]>(() => {
        const dateValueGetter: GridValueGetter<Job, any, undefined, string> = (value) => {
            if (value) {
                const d = moment(value);
                if (d.isValid() && d.get("year") > 1) {
                    return d.toDate();
                }
            }
            return null;
        };

        const dateRenderer = (params: GridRenderCellParams) => {
            if (params.value) {
                return moment(params.value).format('DD MMM YYYY');
            }
        };
        
        const dateEditorRenderer = (params: GridRenderEditCellParams) => {
            const { id, value, field } = params;
            const dateUdfChangeHandler = (date: moment.Moment | null) => {
                if (date) {
                    if (date.isValid()) {
                        const formatted = date.format('YYYY-MM-DD');
                        apiRef.current.setEditCellValue({ id, field, value: formatted });
                    }
                }
                else apiRef.current.setEditCellValue({ id, field, value: null });
            };

            let v: moment.Moment | null = null;
            if (value) {
                const m = moment(value);
                if (m.isValid()) v = m;
            }

            return <DatePicker value={v} onChange={dateUdfChangeHandler} slotProps={{actionBar: { actions: ["clear", "today", "cancel", "accept"] }}} />;
        };

        const endDateRenderer = (params: GridRenderCellParams) => {
            if (params.value) {
                return moment(params.value).format('DD MMM YYYY');
            }
            return 'Never';
        };

        const handlePreviewHover = (type: PreviewEntityType | '', id: number, isTags: boolean = false, recordName: string = '', noDelay: boolean = false) => {
            setPreviewType(type);
            setPreviewRecordId(id);
            setIsPreviewTags(isTags);
            setPreviewRecordName(recordName);
            setIsPreviewOpen(true);
            setShowTagsPreviewNoDelay(noDelay);
        };

        const handlePreviewClose = () => {
            setIsPreviewOpen(false);
            setShowTagsPreviewNoDelay(false);
        };

        const linkToJobRenderer = (params: GridRenderCellParams) => {
            if (params.value) {
                return <Link 
                    to={`/jobs/${params.id}`} 
                    style={ linkStyle } 
                    onMouseEnter={ () => handlePreviewHover('job', +params.id) } 
                    onMouseLeave={ handlePreviewClose }
                    target={ source === "search" ? "_blank" : "_self" }
                >{params.value}</Link>;
            }
        }

        const linkToClientRenderer = (params: GridRenderCellParams) => {
            if (params.row.clientID) {
                return <Link to={`/clients/${params.row.clientID}`} style={ linkStyle } onMouseEnter={ () => handlePreviewHover('client', params.row.clientID) } onMouseLeave={ handlePreviewClose } >{params.value}</Link>;
            }
            return params.value;
        }

        const linkToContactRenderer = (params: GridRenderCellParams) => {
            if (params.row.contact1ID) {
                return <Link to={`/contacts/${params.row.contact1ID}`} style={ linkStyle } onMouseEnter={ () => handlePreviewHover('contact', params.row.contact1ID) } onMouseLeave={ handlePreviewClose } >{params.value}</Link>;
            }
            return params.value;
        }

        const linkToJobCandidates_green = (params: GridRenderCellParams) => {
            return <Link target="_blank" to={`/jobs/${params.id}?tab=JOBS_Candidates&RatingID=4`} style={linkStyle} >{params.value}</Link>;
        };

        const linkToJobCandidates_yellow = (params: GridRenderCellParams) => {
            return <Link target="_blank" to={`/jobs/${params.id}?tab=JOBS_Candidates&RatingID=2`} style={ linkStyle } >{params.value}</Link>;
        };

        const linkToJobCandidates_blue = (params: GridRenderCellParams) => {
            return <Link target="_blank" to={`/jobs/${params.id}?tab=JOBS_Candidates&RatingID=1`} style={ linkStyle } >{params.value}</Link>;
        };

        const linkToJobCandidates_all = (params: GridRenderCellParams) => {
            return <Link target="_blank" to={`/jobs/${params.id}?tab=JOBS_Candidates`} style={linkStyle} >{params.value}</Link>;
        };

        const linkToJobCandidates_todo = (params: GridRenderCellParams) => {
            return <Link target="_blank" to={`/jobs/${params.id}?tab=JOBS_Candidates&RatingID=3`} style={ linkStyle } >{params.value}</Link>;
        };

        const linkToSite = (params: GridRenderCellParams) => {
            if (params.row.siteID) return <Link to={`/sites/${params.row.siteID}`} style={ linkStyle } onMouseEnter={ () => handlePreviewHover('site', params.row.siteID) } onMouseLeave={ handlePreviewClose } >{params.value}</Link>;
            return params.value;
        }

        const linkToJobPlacementsTab = (params: GridRenderCellParams) => {
            return <Link target="_blank" to={`/jobs/${params.id}?tab=JOBS_Placements`} style={ linkStyle } >{params.value}</Link>
        }

        const linkToContact2Renderer = (params: GridRenderCellParams) => {
            if (params.row.contact2ID) {
                return <Link to={`/contacts/${params.row.contact2ID}`} style={ linkStyle } onMouseEnter={ () => handlePreviewHover('contact', params.row.contact2ID) } onMouseLeave={ handlePreviewClose } >{params.value}</Link>;
            }
            return params.value;
        }

        const linkToJobAdvertsTab = (params: GridRenderCellParams) => {
            return <Link target="_blank" to={`/jobs/${params.id}?tab=JOBS_Adverts`} style={ linkStyle } >{params.value}</Link>;
        }

        const hasTagsRenderer = (params: GridRenderCellParams) => {
            if (userCanAddEditJobs) {
                return (
                    <IconButton
                        size="small"
                        onMouseEnter={ () => handlePreviewHover('job', +params.id, true, params.row.title) }
                        onMouseLeave={ handlePreviewClose }
                        onClick={ () => setTaggingRecords([params.row.id]) }
                    >
                        {params.value
                            ? <LocalOfferIcon style={{ color: '#f209a6' }} />
                            : <LocalOfferOutlinedIcon />
                        }
                    </IconButton>
                );
            }
            return (
                <IconButton
                    size="small"
                    onMouseEnter={() => handlePreviewHover('job', +params.id, true, params.row.title)}
                    onMouseLeave={handlePreviewClose}
                    onClick={() => handlePreviewHover('job', +params.id, true, params.row.title, true)}
                >
                    {params.value
                        ? <LocalOfferIcon />
                        : <LocalOfferOutlinedIcon />
                    }
                </IconButton>
            );
        };

        const linkToSavedSearch = (params: GridRenderCellParams) => {
            return (
                <Link target="_blank" to={`/candidates?ssid=${params.row.savedSearchID ?? 0}&jobId=${params.id}`} style={ linkStyle } >
                    <IconButton size="small"><SearchIcon /></IconButton>
                </Link>
            );
        }

        const numberRenderer = (params: GridRenderCellParams) => {
            return formatNumber(params.value);
        };

        let cols: GridColDef[] =  [
            { field: 'id', headerName: 'ID', width: 75, renderCell: linkToJobRenderer },
            { field: 'consultant1Name', headerName: 'Consultant', align: 'center', headerAlign: 'center', width: 150  },
            { field: 'jobReference', headerName: 'Job ID', align: 'center', headerAlign: 'center', width: 80, renderCell: linkToJobRenderer },
            { field: 'title', headerName: 'Title', width: source === 'dashboard-widget' ? 200 : 300, renderCell: linkToJobRenderer },
            { field: 'tags', headerName: 'Tags', width: 70, align: 'center', headerAlign: 'center', renderCell: hasTagsRenderer },
            { field: 'shortAddress', headerName: 'Location', width: 180 },
            { field: 'clientName', headerName: 'Client', width: source === 'dashboard-widget' ? 120 : 250, renderCell: linkToClientRenderer },
            { field: 'contact1Name', headerName: 'Contact', width: 250, renderCell: linkToContactRenderer },
            { field: 'typeName', headerName: 'Type', align: 'center', headerAlign: 'center', width: 100 },
            { field: 'deadline', headerName: 'Deadline', align: 'center', headerAlign: 'center', width: 100, renderCell: dateRenderer, type: 'date', valueGetter: dateValueGetter },
            { field: 'statusName', headerName: 'Status', align: 'center', headerAlign: 'center', width: 120 },
            { field: 'stageName', headerName: 'Stage', align: 'center', headerAlign: 'center', width: 120 },
            { field: 'dealAmount', headerName: 'Deal Value', align: 'right', headerAlign: 'center', renderCell: numberRenderer, width: 120 },
            { field: 'estimatedPlacementDate', headerName: 'Estimated Placement Date', align: 'center', headerAlign: 'center', width: 180, renderCell: dateRenderer, type: 'date', valueGetter: dateValueGetter },
            { field: 'green', headerName: 'Green', type: 'number', align: 'center', headerAlign: 'center', width: 65, renderCell: linkToJobCandidates_green },
            { field: 'yellow', headerName: 'Yellow', type: 'number', align: 'center', headerAlign: 'center', width: 65, renderCell: linkToJobCandidates_yellow },
            { field: 'blue', headerName: 'Blue', type: 'number', align: 'center', headerAlign: 'center', width: 65, renderCell: linkToJobCandidates_blue },
            { field: 'toDo', headerName: 'Todo', type: 'number', align: 'center', headerAlign: 'center', width: 65, renderCell: linkToJobCandidates_todo },
            { field: 'total', headerName: 'All', type: 'number', align: 'center', headerAlign: 'center', width: 65, renderCell: linkToJobCandidates_all },
            { field: 'search', headerName: 'Search', align: 'center', headerAlign: 'center', width: 65, renderCell: linkToSavedSearch },
            { field: 'candidatesRequired', headerName: 'Quantity', type: 'number', align: 'center', headerAlign: 'center', width: 75 },
            { field: 'placements', headerName: 'Placements', type: 'number', align: 'center', headerAlign: 'center', width: 90, renderCell: linkToJobPlacementsTab },
            { field: 'siteName', headerName: 'Site', align: 'center', headerAlign: 'center', width: 150, renderCell: linkToSite },
            { field: 'consultant2Name', headerName: 'Consultant 2', align: 'center', headerAlign: 'center', width: 150 },
            { field: 'startDate', headerName: 'Start Date', align: 'center', headerAlign: 'center', width: 110, renderCell: dateRenderer, type: 'date', valueGetter: dateValueGetter },
            { field: 'endDate', headerName: 'End Date', align: 'center', headerAlign: 'center', width: 110, renderCell: endDateRenderer, type: 'date', valueGetter: dateValueGetter },
            { field: 'contact2Name', headerName: 'Hiring Manager', width: 250, renderCell: linkToContact2Renderer },
            { field: 'candidatesFilled', headerName: 'Filled', type: 'number', align: 'center', headerAlign: 'center', width: 70, renderCell: linkToJobPlacementsTab },
            { field: 'createDate', headerName: 'Created Date', align: 'center', headerAlign: 'center', width: 110, renderCell: dateRenderer, type: 'date', valueGetter: dateValueGetter },
            { field: 'clientReference', headerName: 'Client Ref', align: 'center', headerAlign: 'center', width: 150 },
            { field: 'divisionName', headerName: 'Division', width: 200 },
            { field: 'ratingName', headerName: 'Rating', align: 'center', headerAlign: 'center', width: 100 },
            { field: 'liveAdverts', headerName: 'Live Adverts', type: 'number', align: 'center', headerAlign: 'center', width: 65, renderCell: linkToJobAdvertsTab },
            { field: 'payRange', headerName: 'Pay Range', width: 200 }
        ];

        if (activeFields && activeFields.length > 0) {
            for (let i = 0; i < activeFields.length; i++) {
                const udf = activeFields[i];
                const udfNumber = udf.name.slice(13);
                const isString = udf.dataType === 'String';
                const isDecimal = udf.dataType === 'Decimal';
                const isDate = udf.dataType === 'DateTime';
                const isEditable = udf.editableViaGrids;
                const isDropdown = udf.usePredefinedValues;
                const isMandatory = udf.mandatory;

                if (isEditable && isDecimal) {
                    cols.push({ 
                        field: `customField${udfNumber}`,
                        headerName: udf.agencyName,
                        headerAlign: 'center',
                        align: 'right',
                        editable: true,
                        preProcessEditCellProps: numberUdfEditValidator,
                    });
                }
                else if (isDate) {
                    cols.push({ 
                        field: `customField${udfNumber}`,
                        headerName: udf.agencyName,
                        headerAlign: 'center',
                        editable: isEditable,
                        valueGetter: dateValueGetter,
                        renderCell: dateRenderer,
                        renderEditCell: isEditable ? dateEditorRenderer : undefined,
                        preProcessEditCellProps: isMandatory ? mandatoryUdfEditValidator : undefined
                    });
                }
                else if (isString && isDropdown && udf.values && udf.values.length > 0) {
                    cols.push({ 
                        field: `customField${udfNumber}`,
                        headerName: udf.agencyName,
                        headerAlign: 'center',
                        editable: isEditable,
                        valueOptions: udf.values.map(v => v.value),
                        type: 'singleSelect',
                    });
                }
                else if (isEditable && isString) {
                    cols.push({ 
                        field: `customField${udfNumber}`,
                        headerName: udf.agencyName,
                        headerAlign: 'center',
                        align: 'right',
                        editable: true,
                        preProcessEditCellProps: isMandatory ? mandatoryUdfEditValidator : undefined
                    });
                }
                else {
                    cols.push({ field: `customField${udfNumber}`, headerName: udf.agencyName, headerAlign: 'center', align: isDecimal ? 'right' : undefined });
                }

            }
        }

        return cols;
    }, [activeFields, apiRef, userCanAddEditJobs, source]);
    
    const closeJobsSuccessCallback = useCallback(() => {
        if (jobState === 1) {
            setRows(prev => {
                let tmp = [...prev];
                for (let i = 0; i < selectionModel.length; i++) {
                    const id = selectionModel[i];
                    const index = tmp.findIndex(j => j.id === id);
                    if (id !== -1) tmp.splice(index, 1);
                }
                setSelectionModel([]);
                return tmp;
            });
        }
        else setSelectionModel([]);
        setSuccessMessage('Jobs Closed Successfully');
    }, [selectionModel, jobState]);

    const exportToExcel = useCallback(() => {
        const api = apiRef.current;
        if (api) api.exportDataAsExcel();
    }, [apiRef]);

    const canExport = useMemo(() => companyHasSinglePermission(Permission.JobsExport) && userHasSinglePermission(Permission.JobsExport), []);
    const canAddEditJobs = useMemo(() => companyHasSinglePermission(Permission.JobsAddEdit) && userHasSinglePermission(Permission.JobsAddEdit), []);

    const gridActions = useMemo(() => {
        if (hideActionsMenu) return undefined;
        if (canExport || canAddEditJobs) {
            return (
                <ActionsDropDownButton color="secondary" label="List Actions">
                    { canAddEditJobs && <MenuItem disabled={selectionModel.length === 0} onClick={() => setTaggingRecords(selectionModel as number[])}>Bulk Tag</MenuItem>}
                    { canAddEditJobs && <MenuItem disabled={selectionModel.length === 0} onClick={() => setIsCloseJobsDialogOpen(true)}>Close Jobs</MenuItem>}
                    { canExport && <MenuItem onClick={exportToExcel}>Export</MenuItem>}
                </ActionsDropDownButton>
            );
        }
        return undefined;
    }, [hideActionsMenu, canExport, canAddEditJobs, selectionModel, exportToExcel]);

    const tagManagementSuccessHandler = useCallback((message: string, recordIds: number[], finalTagCount: number) => {
        setSuccessMessage(message);
        const api = apiRef.current;
        if (api) {
            const hasTags = finalTagCount > 0;
            api.updateRows(recordIds.map(id => ({ id: id, tags: hasTags })));
        }
    }, [apiRef]);

    const cellEditHandler = useCallback((params: GridCellEditStopParams) => {
        if (params.reason === GridCellEditStopReasons.escapeKeyDown) return;
        setEditCellFieldKey(params.field as keyof Job);
    }, []);

    const processRowUpdate = useCallback(async (newRow: Job) => {
        if (editCellFieldKey && editCellFieldKey.startsWith('customField')) {
            const value = newRow[editCellFieldKey] as string;
            const n = +editCellFieldKey.slice(11);
            loadingHandler && loadingHandler(true);
            if ((n >= 16 && n <= 20) || (n >= 36 && n <= 40) || (n >= 56 && n <= 60)) { // IS DATE                
                await UpdateJobCustomField(newRow.id, n, value ?? '0001-01-01', errorHandler);
            }
            else if (n >= 1 && n <= 60) await UpdateJobCustomField(newRow.id, n, value, errorHandler);
            loadingHandler && loadingHandler(false);
        }
        setEditCellFieldKey(null);
        return newRow;
    }, [editCellFieldKey, errorHandler, loadingHandler]);

    const pagination = useMemo(() => {
        if (source === 'dashboard-widget') return false;
        return true;
    }, [source]);

    const defaultViewModel = useMemo<GridColumnVisibilityModel>(() => {
        if (source === 'dashboard-widget') return defaultColsVisibilityDashboardWidget;
        return defaultColsVisibility;
    }, [source]);

    return (
        <>
            <PreviewLoaderComponent
                open={isPreviewOpen}
                entityType={previewType}
                recordId={previewRecordId}
                isTagsPreview={isPreviewTags}
                showDelayMs={showTagsPrevewNoDelay ? 0 : undefined}
                titleOverride={previewRecordName}
            />
            <TagsManagementDialog
                open={ taggingRecords.length > 0 }
                closeHandler={ () => setTaggingRecords([]) }
                entityId={ entityTypeId }
                recordIds={ taggingRecords }
                loadingHandler={ loadingHandler }
                errorHandler={ errorHandler }
                successHandler={ tagManagementSuccessHandler }
            />
            <CloseJobsDialog
                isOpen={ isCloseJobsDialogOpen }
                closeHandler={ () => setIsCloseJobsDialogOpen(false) }
                jobIds={ selectionModel as number[] }
                errorHandler={setErrorMessage}
                successHandler={closeJobsSuccessCallback}
            />
            <Snackbar open={successMessage !== ''} autoHideDuration={3000} onClose={() => setSuccessMessage('')}>
                <Alert onClose={() => setSuccessMessage('')}>{ successMessage }</Alert>
            </Snackbar>
            <Snackbar open={errorMessage !== ''} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <Box>
                { extraActions }
                { Boolean(gridActions) && <Box pb="10px" sx={{ float: 'right' }}>{ gridActions }</Box>}
            </Box>
            <GridWithStateWrapper
                gridName={gridName}
                defaultViewModel={defaultViewModel}
                rows={rows}
                columns={columns}
                apiRef={apiRef}
                density="compact"
                checkboxSelection={!hideActionsMenu}
                disableRowSelectionOnClick
                rowSelectionModel={selectionModel}
                onRowSelectionModelChange={ sm => setSelectionModel(sm) }
                pagination={pagination}
                onCellEditStop={ cellEditHandler }
                processRowUpdate={ processRowUpdate }
                pageSizeOptions={[100,250,500,1000]}
                hideFooter={!pagination}
            />
        </>
    );
}
