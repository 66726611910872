import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { GridColDef, GridRenderCellParams, GridValueGetter, useGridApiRef } from "@mui/x-data-grid-premium";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { SmsPackage, SmsPurchase } from "common/models/Configuration/SMS";
import { AddPurchase, GetSmsPackages, GetSmsPurchases } from "services/ConfigurationService";
import GridWithStateWrapper from "../GridWidthStateWrapper";
import { defaultGridCellStyle } from "util/GridUtils";

interface Props {
    gridName: string,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
    successHandler?: (message: string) => void,
}

export default function SmsPurchasesGridComponent({ gridName, loadingHandler, errorHandler, successHandler }: Props) {
    const [rows, setRows] = useState<SmsPurchase[]>([]);
    const [isFetchingRows, setIsFetchingRows] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [packages, setPackages] = useState<SmsPackage[]>([]);
    const [showAddPurchaseDialog, setShowAddPurchaseDialog] = useState(false);
    const [selectedPackageId, setSelectedPackageId] = useState(0);
    const [addPurchaseNotes, setAddPurchaseNotes] = useState('');
    const apiRef = useGridApiRef();

    const getRowsData = useCallback(async () => {
        setIsFetchingRows(true);
        const res = await GetSmsPurchases(errorHandler);
        if (res) setRows(res);
        setIsFetchingRows(false);
    }, [errorHandler]);

    useEffect(() => {
        const getData = async () => {
            setIsLoading(true);
            await getRowsData();
            const res = await GetSmsPackages(errorHandler);
            if (res) setPackages(res);
            setIsLoading(false);
        };
        getData();
    }, [getRowsData, errorHandler]);

    useEffect(() => loadingHandler && loadingHandler(isFetchingRows || isLoading), [isFetchingRows, isLoading, loadingHandler]);

    useEffect(() => {
        if (!showAddPurchaseDialog) {
            setSelectedPackageId(0);
            setAddPurchaseNotes('');
        }
    }, [showAddPurchaseDialog]);

    const columns = useMemo<GridColDef[]>(() => {
        const dateValueGetter: GridValueGetter<SmsPurchase, any, undefined, string> = (value) => {
            if (value) {
                const m = moment(value);
                if (m.isValid() && m.get('year') > 1) {
                    return m.toDate();
                }
            }
        };

        const dateRenderer = (params: GridRenderCellParams) => {
            if (params.value) {
                return <Box lineHeight="41px">{moment(params.value).format('DD MMM YYYY')}</Box>;
            }
            return <Box lineHeight="41px">Never</Box>;
        };

        const minHeightCellRenderer = (params: GridRenderCellParams) => {
            return (
                <div style={defaultGridCellStyle}>
                    <span>{params.value}</span>
                </div>
            );
        };
        
        return [
            { headerName: 'ID', field: 'id', width: 75, renderCell: minHeightCellRenderer },
            { headerName: 'Type', field: 'type', width: 150, headerAlign: 'center', align: 'center', renderCell: minHeightCellRenderer },
            { headerName: 'Quantity', field: 'quantity', width: 150, headerAlign: 'center', align: 'center', renderCell: minHeightCellRenderer },
            { headerName: 'Price', field: 'priceFormat', headerAlign: 'center', align: 'center', width: 150, renderCell: minHeightCellRenderer },
            { headerName: 'Requested By', field: 'requestedBy', headerAlign: 'center', align: 'center', width: 200, renderCell: minHeightCellRenderer },
            { headerName: 'Requested Date', field: 'requestedDate', headerAlign: 'center', align: 'center', width: 150, valueGetter: dateValueGetter, renderCell: dateRenderer, type: 'date' },
            { headerName: 'Approved', field: 'approved', headerAlign: 'center', align: 'center', width: 100, type: 'boolean' },
            { headerName: 'Expiry Date', field: 'expiryDate', headerAlign: 'center', align: 'center', width: 150, valueGetter: dateValueGetter, renderCell: dateRenderer, type: 'date' },
            { headerName: 'Notes', field: 'notes', headerAlign: 'center', align: 'center', width: 300, renderCell: minHeightCellRenderer }
        ];
    }, []);

    const packageOptions = useMemo(() => {
        let options = packages.map(p => {
            let label = '';
            if (p.typeID === 1) label = `${p.quantity} credits for ${p.price.toFixed(2)} AUD (${p.pricePerCredit} Per Credit)`;
            else if (p.typeID === 2) label = `1x Dedicated Number for ${p.price.toFixed(2)} AUD AUD Per Month`;
            return <MenuItem key={p.id} value={p.id.toString()}>{label}</MenuItem>;
        });

        return [<MenuItem key={0} value="0">None</MenuItem>, ...options];
    }, [packages]);

    const gridActions = useMemo(() => {
        return <Button variant="contained" color="success" onClick={() => setShowAddPurchaseDialog(true)}>Add Purchase</Button>;
    }, []);

    const addPurchaseCallback = useCallback(async () => {
        setIsLoading(true);
        const res = await AddPurchase(selectedPackageId, addPurchaseNotes, errorHandler);
        if (res) {
            successHandler && successHandler('Package purchased');
            setShowAddPurchaseDialog(false);
            getRowsData();
        }
        setIsLoading(false);
    }, [selectedPackageId, addPurchaseNotes, errorHandler, successHandler, getRowsData]);

    return (
        <>
            <Dialog open={showAddPurchaseDialog} fullWidth>
                <DialogTitle>Purchase SMS Packages</DialogTitle>
                <DialogContent>
                    <Stack spacing={2} mt="10px">
                        <TextField select value={selectedPackageId} label="Package" onChange={({target}) => setSelectedPackageId(+target.value)}>
                            {packageOptions}
                        </TextField>
                        <TextField
                            value={addPurchaseNotes}
                            label="Notes"
                            onChange={({target}) => setAddPurchaseNotes(target.value) }
                            multiline
                            minRows={6}
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="error" onClick={ () => setShowAddPurchaseDialog(false) }>Cancel</Button>
                    <Button variant="contained" color="success" disabled={ selectedPackageId === 0 } onClick={ addPurchaseCallback } >Purchase</Button>
                </DialogActions>
            </Dialog>
            {Boolean(gridActions) &&
                <Box pb="10px" ml="auto">
                    {gridActions}
                </Box>
            }
            <GridWithStateWrapper
                gridName={gridName}
                rows={rows}
                columns={columns}
                apiRef={apiRef}
                disableRowSelectionOnClick
                pagination={true}
                density="compact"
                getRowHeight={() => 'auto'}
                pageSizeOptions={[100, 250, 500, 1000]}
            />
        </>
    );
}