import React, { useEffect, useMemo, useState } from "react";
import { Navigate, Route, Routes, useParams } from "react-router-dom";
import { ConfigurationEditUsersRoutes } from "common/data/TopBarRoutes";
import SideNav from "components/SideBars/SideNav";
import PageWithSidebarLayout from "layouts/PageWithSidebarLayout";
import * as P from '../../../common/data/Permissions/ConfigurationAccess';
import UserGeneral from "./Edit/General";
import UserTags from "./Edit/Tags";
import UserProfile from "./Edit/Profile";
import { GetUser } from "services/UsersService";
import UserSignature from "./Edit/Signature";
import UserSettings from "./Edit/Settings";
import UserPermissions from "./Edit/Permissions";

const NotAllowed = <Navigate to='/not-authorized' />;

export default function UserEditIndex() {
    const [userName, setUserName] = useState("");
    const params = useParams();
    const [summaryBar, setSummaryBar] = useState<JSX.Element>(<></>);
    
    const userId = useMemo(() => {
        const parsedId = +(params.userId ?? '0');
        if(isNaN(parsedId)) return 0;
        return parsedId;
    }, [params.userId]);

    useEffect(() => {
        const getUserName = async () => {
            const data = await GetUser(userId);
            if (data) setUserName(data.displayName);
        };
        getUserName();
    }, [userId]);

    const editUsersSideNav = useMemo(() => {
        const prefix = `/configuration/users/${userId}/`;
        return <SideNav routes={ ConfigurationEditUsersRoutes.secondaryOptions } prefix={prefix} />;
    }, [userId]);

    return (
        <PageWithSidebarLayout SummaryBar={summaryBar} SideBar={editUsersSideNav}>
            <Routes>
                <Route path='' element={ P.AllowUsers() ? <UserGeneral userName={userName} setSummaryBar={setSummaryBar} /> : NotAllowed } />
                <Route path='general' element={ P.AllowUsers() ? <UserGeneral userName={userName} setSummaryBar={setSummaryBar} /> : NotAllowed } />
                <Route path='permissions' element={ P.AllowUsers() ? <UserPermissions userName={userName} setSummaryBar={setSummaryBar} /> : NotAllowed } />
                <Route path='profile' element={ P.AllowUsers() ? <UserProfile userName={userName} setSummaryBar={setSummaryBar} /> : NotAllowed } />
                <Route path='settings' element={ P.AllowUsers() ? <UserSettings userName={userName} setSummaryBar={setSummaryBar} /> : NotAllowed } />
                <Route path='signature' element={ P.AllowUsers() ? <UserSignature userName={userName} setSummaryBar={setSummaryBar} /> : NotAllowed } />
                <Route path='tags' element={ P.AllowUsers() ? <UserTags userName={userName} setSummaryBar={setSummaryBar} /> : NotAllowed } />
            </Routes>
        </PageWithSidebarLayout>
    );
}