import { ErrorHandler, RequestOptions } from "../common/models/GenericTypes";
import { Get } from "../util/HttpUtils";

export async function GetInternalInterviewsConversionReportData(owner: number, start_date: string, end_date: string, submissions: number, interviews: number, offers: number, placements: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<any>(`api/misc/reports/internal-interview-conversion?owner=${owner}&startDate=${start_date}&endDate=${end_date}&submissions=${submissions}&interviews=${interviews}&offers=${offers}&placements=${placements}`, options);
}

export async function GetForecastDetailedReportData(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<any>(`api/misc/reports/forecast-detailed`, options);
}

export async function GetForecastUserDetailedReportData(owner: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<any>(`api/misc/reports/user-forecast-detailed?owner=${owner}`, options);
}

export async function GetMonthlySCGradesReports(year: number, month: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<any>(`api/misc/reports/custom-trcgroup-monthlyscgrades?year=${year}&month=${month}`, options);
}

export async function GetTRC_MMAByDivisionsReports(divisionId: number, start_date: string, end_date: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<any>(`api/misc/reports/custom-trcgroup-mmasbydivision?divisionId=${divisionId}&startDate=${start_date}&endDate=${end_date}`, options);
}

export async function GetTRC_MMAByUserReports(owner: number, start_date: string, end_date: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<any>(`api/misc/reports/custom-trcgroup-mmasbyuser?owner=${owner}&startDate=${start_date}&endDate=${end_date}`, options);
}

export async function GetNTT_SLA_SnapshotReports(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<any>(`api/misc/reports/custom-ntt-sla-snapshot`, options);
}

export async function GetNTT_AgencySLA(start_date: string, end_date: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<any>(`api/misc/reports/custom-ntt-agency-sla?startDate=${start_date}&endDate=${end_date}`, options);
}

export async function GetTRC_Weekly_CompanyActivity_PartReportData(start_date: string, end_date: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<any>(`api/misc/reports/custom-trcgroup-company-activity?startDate=${start_date}&endDate=${end_date}`, options);
}

export async function GetTRC_Weekly121_PartReportData(owner: number, start_date: string, end_date: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<any>(`api/misc/reports/custom-trcgroup-weekly-121?owner=${owner}&startDate=${start_date}&endDate=${end_date}`, options);
}

export async function GetStrategic_CoachingReportData(owner: number, start_date: string, end_date: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<any>(`api/misc/reports/custom-trcgroup-monthlysc?owner=${owner}&startDate=${start_date}&endDate=${end_date}`, options);
}

export async function GetNTTSLATimeRangeReports(start_date: string, end_date: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<any>(`api/misc/reports/custom-ntt-sla-timerange?startDate=${start_date}&endDate=${end_date}`, options);
}

export async function GetLoginTrackingData(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<any>(`api/misc/reports/login-tracking`, options);
}

export async function GetRandstadUserUsage(start_date: string, end_date: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<any[]>(`api/misc/reports/randstad-user-usage?startDate=${start_date}&endDate=${end_date}`, options);
}