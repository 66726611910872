import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import ReplayIcon from '@mui/icons-material/Replay';
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useTheme } from "@mui/material/styles";
import { PanelModel } from "@syncfusion/ej2-layouts/src/dashboard-layout/dashboard-layout-model";
import { DashboardLayoutComponent } from "@syncfusion/ej2-react-layouts/src/dashboard-layout/dashboardlayout.component";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { CustomField } from "common/models/Configuration/CustomFields";
import { JobRecordDashboardElementDefinition, JobRecordDashboardElementType } from "common/models/Dashboard/EditLayout";
import { CustomFieldSettingsMap, CustomFieldType } from "common/models/ScreenLayouts/CustomFields";
import useUnsavedChangesDialog from "hooks/UseUnsavedChangesDialog";
import { GetCustomerSettingBySettingName, UpdateCustomerSettingBySettingName } from "services/ConfigurationService";
import { GetCustomFieldsByEntity_OnlyActive } from "services/CustomFieldsService";
import { PlaceholderJob } from "util/Definitions/Jobs";
import { UdfJobFieldMapObj } from "util/Definitions/ScreenLayouts/CustomFields";
import { DefaultJobRecordDashboardElements_Contract, DefaultJobRecordDashboardElements_FixedContract, DefaultJobRecordDashboardElements_Panel, DefaultJobRecordDashboardElements_Permanent, DefaultJobRecordDashboardElements_TalentPool, GetElementTypeById, GetMinSizeByElementType, GetPanelDefinitionsFromPanelModels, JobScreenLayoutSettings } from "util/Definitions/ScreenLayouts/Job";
import PanelWrapper from "components/Dashboards/PanelWrapper";
import ConfirmationDialog from "components/Dialogs/Generic/ConfirmationDialog";
import MultiLineTextElement from "../MultiLineTextElement";
import SingleFieldElement from "../SingleFieldElement";
import ViewClientLocationElement from "../Clients/ViewClientLocationElement";
import Chip from "@mui/material/Chip";
import AddJobDashboardElementDialog from "components/Dialogs/Dashboard/AddJobDashboardElementDialog";

interface Props {
    layoutType: 'Record' | 'Edit',
    jobType: 'Permanent' | 'Contract' | 'FixedContract' | 'TalentPool' | 'Panel',
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
}

const job = PlaceholderJob;

const LayoutElementsToString = (elements: PanelModel[]) => {
    if (elements.length === 0) return '';
    let s = elements.map(e => ElementString(e));
    s.sort()
    return s.join('|');
}

const ElementString = (element: PanelModel) => {
    const type = GetElementTypeById(element.id ?? '');
    const val = `${type}|${element.row}|${element.col}|${element.sizeX}|${element.sizeY}`;
    return val;
};

const { unitWidth, unitHeight, gapX, gapY, columns, mediaQueryMaxWidth } = JobScreenLayoutSettings;
const cellSpacing = [gapX, gapY];

export default function ScreenLayoutAdmin({ layoutType, jobType, loadingHandler, errorHandler }: Props) {
    const [layoutSwapControl, setLayoutSwapControl] = useState(true);
    const [screenResizedControl, setScreenResizedControl] = useState(false);
    const [showAddElementDialog, setShowAddElementDialog] = useState(false);
    const [fetchingSavedState, setFetchingSavedState] = useState(false);
    const [fetchingActiveFields, setFetchingActiveFields] = useState(false);
    const [fetchedSavedState, setFetchedSavedState] = useState(false);
    const [activeFields, setActiveFields] = useState<CustomField[]>([]);
    const [initialState, setInitialState] = useState<PanelModel[] | null>(null);
    const [hasChanges, setHasChanges] = useState(false);
    const [saveAsBothEditAndView, setSaveAsBothEditAndView] = useState(false);
    const [showConfirmSaveBothDialog, setShowConfirmSaveBothDialog] = useState(false);
    const [showResetToDefaultConfirmDialog, setShowResetToDefaultConfirmDialog] = useState(false);
    const [lastSavedState, setLastSavedState] = useState<PanelModel[] | null>(null);
    const layoutRef = useRef<DashboardLayoutComponent | null>(null);
    const theme = useTheme();

    const layoutDataSettingName = useMemo(() => {
        if (layoutType === 'Edit') return `JobEditRecordDashboardLayoutElements_${jobType}`;
        return `JobRecordDashboardLayoutElements_${jobType}`;
    }, [jobType, layoutType]);

    const defaultElements = useMemo(() => {
        switch (jobType) {
            case 'Permanent': return DefaultJobRecordDashboardElements_Permanent;
            case 'Contract': return DefaultJobRecordDashboardElements_Contract;
            case 'FixedContract': return DefaultJobRecordDashboardElements_FixedContract;
            case 'TalentPool': return DefaultJobRecordDashboardElements_TalentPool;
            case 'Panel': return DefaultJobRecordDashboardElements_Panel;
        }
    }, [jobType]);

    useEffect(() => {
        const windowResizeHandler = () => setScreenResizedControl(prev => !prev);
        window.addEventListener('resize', windowResizeHandler);
        return () => window.removeEventListener('resize', windowResizeHandler);
    }, []);

    useEffect(() => {
        const getActiveFields = async () => {
            setFetchingActiveFields(true);
            const res = await GetCustomFieldsByEntity_OnlyActive(4);
            if (res) setActiveFields(res);
            setFetchingActiveFields(false);
        };
        getActiveFields();
    }, []);

    const customFieldsSettingsMap = useMemo<CustomFieldSettingsMap>(() => {
        let obj: CustomFieldSettingsMap = {};
        activeFields.forEach(u => {
            const customFieldNumber = u.name.substring(13);
            const key = 'CustomField' + customFieldNumber as CustomFieldType;
            obj[key] = {
                title: u.agencyName,
                isMultiLine: u.multiLine && !u.usePredefinedValues,
                isEditable: u.editable
            }
        });
        return obj;
    }, [activeFields]);

    useEffect(() => {
        const getSavedState = async () => {
            setFetchingSavedState(true);
            const elementsJson = await GetCustomerSettingBySettingName(layoutDataSettingName);
            if (elementsJson) {
                const panels = JSON.parse(elementsJson) as PanelModel[];
                setLastSavedState(panels);
                setInitialState(panels);
            }
            setFetchingSavedState(false);
            setFetchedSavedState(true);
        };
        getSavedState();
    }, [layoutDataSettingName]);

    useEffect(() => {
        loadingHandler && loadingHandler(fetchingSavedState || fetchingActiveFields);
    }, [loadingHandler, fetchingSavedState, fetchingActiveFields]);

    const initialStateString = useMemo(() => {
        if (initialState && initialState.length ) return LayoutElementsToString(initialState);
        return '';
    }, [initialState]);

    const layoutChangeHandler = useCallback(() => {
        const api = layoutRef.current;
        if (api) {
            const currentData = api.serialize();
            const currentStringData = LayoutElementsToString(currentData);
            setHasChanges(currentStringData !== initialStateString);
        }
    }, [initialStateString]);

    useEffect(() => {
        if (layoutSwapControl) {}
        layoutChangeHandler();
    }, [layoutSwapControl, layoutChangeHandler]);

    const elements = useMemo<JobRecordDashboardElementDefinition[]>(() => {
        if (!fetchedSavedState) return [];
        if (lastSavedState !== null) {
            const elements = GetPanelDefinitionsFromPanelModels(lastSavedState);
            return elements ;
        }
        return defaultElements;
    }, [lastSavedState, fetchedSavedState, defaultElements]);

    const removeElementHandler = useCallback((id: string) => {
        const api = layoutRef.current;
        if (api) {
            api.removePanel(id);
            layoutChangeHandler();
        }
    }, [layoutChangeHandler]);

    const addElementHandler = useCallback((newElementType: JobRecordDashboardElementType) => {
        const api = layoutRef.current;
        if (api) {
            const m = moment().format('YYYYMMDDhhmmssSS');
            const id = `${newElementType}_${m}`;
            const canGrowY = newElementType === 'JobSummary' || newElementType.startsWith('CustomField');
            const [minX, minY] = GetMinSizeByElementType(newElementType);
            const maxSizeY = canGrowY ? undefined : minY;
            api.addPanel({ id: id, col: 0, row: 0, sizeX: 1, sizeY: 1 });
            api.updatePanel({ id: id, col: 0, row: 0, sizeX: minX, sizeY: minY, minSizeX: minX, minSizeY: minY, maxSizeX: 1, maxSizeY: maxSizeY, enabled: false });
            const newDataObj = api.serialize();
            setLastSavedState(newDataObj);
            setLayoutSwapControl(prev => !prev);
            setShowAddElementDialog(false);
            layoutChangeHandler();
        }
    }, [layoutChangeHandler]);

    const renderElement = useCallback((id: string, type: JobRecordDashboardElementType) => {
        if (job) {
            switch (type) {
                case 'Divider': return <Box pt="20px"><Divider component="div" /></Box>;
                case 'Spacer': return <></>;
                case 'JobId': return <SingleFieldElement disableTextSelection useEllipsisForLongValues fieldTitle="ID" fieldValue={job.jobReference} />;
                case 'JobType': return <SingleFieldElement disableTextSelection useEllipsisForLongValues fieldTitle="Job Type" fieldValue={job.type} />;
                case 'JobWorkType': return <SingleFieldElement disableTextSelection useEllipsisForLongValues fieldTitle="Work Type" fieldValue={job.workType} />;
                case 'JobTitle': return <SingleFieldElement disableTextSelection useEllipsisForLongValues fieldTitle="Job Title" fieldValue={job.title} />;
                case 'JobExclusive': return <SingleFieldElement disableTextSelection useEllipsisForLongValues fieldTitle="Exclusive" fieldValue={job.exclusive ? 'Yes' : 'No'} />;
                case 'JobQuantity': return <SingleFieldElement disableTextSelection useEllipsisForLongValues fieldTitle="Quantity" fieldValue={job.candidatesRequired.toString()} />;
                case 'JobStartDate': return <SingleFieldElement disableTextSelection useEllipsisForLongValues fieldTitle="Start Date" fieldValue={job.startDate} format="date" />;
                case 'JobStartTime': return <SingleFieldElement disableTextSelection useEllipsisForLongValues fieldTitle="Start Time" fieldValue={job.startTime} format="time" />;
                case 'JobDuration': return <SingleFieldElement disableTextSelection useEllipsisForLongValues fieldTitle="Duration" fieldValue={'5 Month(s)'} />;
                case 'JobDeadline': return <SingleFieldElement disableTextSelection useEllipsisForLongValues fieldTitle="Deadline" fieldValue={job.deadline} format="date" />;
                case 'JobDivision': return (
                        <SingleFieldElement disableTextSelection fieldTitle="Division" format="custom">
                            <Chip size="small" label="Placeholder Division" sx={{ mr: '5px' }} />
                        </SingleFieldElement>
                    );
                case 'JobSalaryPackage': return <SingleFieldElement disableTextSelection useEllipsisForLongValues fieldTitle="Salary Package" fieldValue={'100,000.00 to 120,000.00 Yearly'} />;
                case 'JobFee': return <SingleFieldElement disableTextSelection useEllipsisForLongValues fieldTitle="Fee" fieldValue={'12.00 Percent ( 13,200.00 )'} />;
                case 'JobChargeRate': return <SingleFieldElement disableTextSelection useEllipsisForLongValues fieldTitle="Charge Rate" fieldValue={'10.15 to 15.25 Hourly'} />;
                case 'JobPayRate': return <SingleFieldElement disableTextSelection useEllipsisForLongValues fieldTitle="Pay Rate" fieldValue={'10.15 to 15.25 Hourly'} />;
                case 'JobOnCosts': return <SingleFieldElement disableTextSelection useEllipsisForLongValues fieldTitle="On Costs" fieldValue={'10.15 to 15.25 Hourly'} />;
                case 'JobComments': return <SingleFieldElement disableTextSelection useEllipsisForLongValues fieldTitle="Comments" fieldValue={job.remunerationComment} />;
                case 'JobCurrency': return <SingleFieldElement disableTextSelection useEllipsisForLongValues fieldTitle="Currency" fieldValue={job.currencyName} />;
                case 'JobSource': return <SingleFieldElement disableTextSelection useEllipsisForLongValues fieldTitle="Source" fieldValue={job.source} />;
                case 'JobLocation': return (
                    <ViewClientLocationElement
                        disableTextSelection
                        address1={job.address1}
                        address2={job.address2}
                        address3={job.address3}
                        countryName={job.countryName}
                        postcode={job.postcode}
                        siteId={job.siteID}
                        siteName={job.siteName}
                        state={job.state}
                        suburb={job.suburb}
                    />);
                case 'JobClient': return (
                    <SingleFieldElement disableTextSelection fieldTitle='Client' format="custom">
                        <Box component="span" color="primary.main">{job.clientName}</Box>
                    </SingleFieldElement>
                );
                case 'JobContact': return (
                    <SingleFieldElement disableTextSelection fieldTitle='Contact' format="custom">
                        <Box component="span" color="primary.main">{job.contact1Name}</Box>
                    </SingleFieldElement>
                );
                case 'JobOtherContact': return (
                    <SingleFieldElement disableTextSelection fieldTitle='Other Contact' format="custom">
                        <Box component="span" color="primary.main">{job.contact4Name}</Box>
                    </SingleFieldElement>
                );
                case 'JobHiringManager': return (
                    <SingleFieldElement disableTextSelection fieldTitle='Hiring Mgr' format="custom">
                        <Box component="span" color="primary.main">{job.contact2Name}</Box>
                    </SingleFieldElement>
                );
                case 'JobBilling1': return (
                    <SingleFieldElement disableTextSelection fieldTitle='Billing 1' format="custom">
                        <Box component="span" color="primary.main">{job.contact3Name}</Box>
                    </SingleFieldElement>
                );
                case 'JobClientRef': return <SingleFieldElement disableTextSelection useEllipsisForLongValues fieldTitle="Client Ref" fieldValue={job.clientReference} />;
                case 'JobVideoLink': return (
                    <SingleFieldElement disableTextSelection fieldTitle='Video Link' format="custom">
                        <Box component="span" color="primary.main">Click to View</Box>
                    </SingleFieldElement>
                );
                case 'JobRating': return <SingleFieldElement disableTextSelection useEllipsisForLongValues fieldTitle="Rating" fieldValue={'A'} />;
                case 'JobSummary': return <MultiLineTextElement disableTextSelection title="Summary" content={job.description} />;
                case 'JobStatus': return <SingleFieldElement disableTextSelection useEllipsisForLongValues fieldTitle="Status" fieldValue={job.statusName} />;
                case 'JobStage': return <SingleFieldElement disableTextSelection useEllipsisForLongValues fieldTitle="Stage" fieldValue={job.stageName} />;
                case 'JobOutcome': return <SingleFieldElement disableTextSelection useEllipsisForLongValues fieldTitle="Outcome" fieldValue={'Filled by Us'} />;
                case 'JobHoursPerDay': return <SingleFieldElement disableTextSelection useEllipsisForLongValues fieldTitle="Hours Per Day" fieldValue={job.hoursPerDay.toString()} />;
                case 'JobDaysPerWeek': 
                    const daysPerWeek = job.daysPerWeek >= 1 && job.daysPerWeek <= 7 ? job.daysPerWeek.toString() : '';
                    return <SingleFieldElement disableTextSelection useEllipsisForLongValues fieldTitle="Days Per Week" fieldValue={`${daysPerWeek} Day${job.daysPerWeek > 1 ? 's' : ''}`} />;
                case 'JobConsultant1': return <SingleFieldElement disableTextSelection useEllipsisForLongValues fieldTitle="Consultant 1" fieldValue={job.consultant1Name} />;
                case 'JobConsultant2': return <SingleFieldElement disableTextSelection useEllipsisForLongValues fieldTitle="Consultant 2" fieldValue={job.consultant2Name} />;
                case 'JobComplianceChecklist': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Compliance Checklist" fieldValue={job.complianceChecklistName} />;
                default: 
                    if (type.startsWith('CustomField')) {
                        const settings = customFieldsSettingsMap[type];
                        if (settings && layoutType === 'Edit' && !settings.isEditable) return <SingleFieldElement disableTextSelection fieldTitle={settings.title} fieldValue="[ Non Editable Custom Field ]" />;
                        if (settings) {
                            const title = settings.title;
                            const { field, format } = UdfJobFieldMapObj[type as CustomFieldType];
                            const udfValue = job[field];
                            if (format === 'string' || format === 'date' || format === 'datetime') {
                                return <SingleFieldElement disableTextSelection isMultiLine={settings.isMultiLine} format={format} fieldTitle={title} fieldValue={udfValue ? udfValue.toString() : null} />;
                            }
                            if (format === 'number') {
                                return <SingleFieldElement disableTextSelection format={format} fieldTitle={title} fieldValue={udfValue ? +udfValue : 0} />;
                            }
                        }
                        else return <SingleFieldElement disableTextSelection fieldTitle={type} fieldValue="[ Disabled Custom Field ]" />
                    }
                    return <div>{id}</div>;
            }
        }
    }, [customFieldsSettingsMap, layoutType]);
    
    const saveChangesHandler = useCallback(async () => {
        const api = layoutRef.current;
        if (api && fetchedSavedState) {
            const obj = api.serialize();
            loadingHandler && loadingHandler(true);
            const res = await UpdateCustomerSettingBySettingName(layoutDataSettingName, JSON.stringify(obj), errorHandler);
            loadingHandler && loadingHandler(false);
            if (!res) return false;
            setInitialState(obj);
        }
        return true;
    }, [fetchedSavedState, layoutDataSettingName, loadingHandler, errorHandler]);

    const saveChangesAsBothHandler = useCallback(async () => {
        const api = layoutRef.current;
        if (api && fetchedSavedState) {
            const obj = api.serialize();
            loadingHandler && loadingHandler(true);
            const res1 = await UpdateCustomerSettingBySettingName(`JobEditRecordDashboardLayoutElements_${jobType}`, JSON.stringify(obj), errorHandler);
            const res2 = await UpdateCustomerSettingBySettingName(`JobRecordDashboardLayoutElements_${jobType}`, JSON.stringify(obj), errorHandler);
            loadingHandler && loadingHandler(false);
            if (!res1 || !res2) return false;
            setInitialState(obj);
            setShowConfirmSaveBothDialog(false);
            setSaveAsBothEditAndView(false);
        }
        return true;
    }, [jobType, fetchedSavedState, loadingHandler, errorHandler]);

    const resetLayoutHandler = useCallback(() => {
        setLastSavedState(null);
        setShowResetToDefaultConfirmDialog(false);
        setLayoutSwapControl(prev => !prev);
    }, []);
    
    const layoutResizeStopHandler = useCallback(() => {
        setScreenResizedControl(prev => !prev);
        layoutChangeHandler();
    }, [layoutChangeHandler]);

    useEffect(() => {
        if (screenResizedControl) {}
        const api = layoutRef.current;
        if (api && fetchedSavedState) {
            const refreshTimeout = setTimeout(() => {
                api.refresh();
                api.refresh();
            }, 250);
            return () => clearTimeout(refreshTimeout);
        }
    }, [fetchedSavedState, screenResizedControl]);

    const renderLayout = useCallback(() => {
        if (!fetchedSavedState) return <></>;

        const mediaQuery = mediaQueryMaxWidth ? `max-width: ${mediaQueryMaxWidth}` : undefined;
        return (
            <DashboardLayoutComponent
                cellSpacing={cellSpacing}
                columns={columns}
                cellAspectRatio={30 / 2}
                allowResizing
                resizeStop={ layoutResizeStopHandler }
                change={ layoutChangeHandler }
                ref={l => layoutRef.current = l}
                mediaQuery={mediaQuery}
            >
                {elements.map(e => (
                    <PanelWrapper
                        key={e.id}
                        id={e.id}
                        col={e.col}
                        row={e.row}
                        sizeX={e.sizeX}
                        sizeY={e.sizeY}
                        minSizeX={e.minSizeX}
                        minSizeY={e.minSizeY}
                        maxSizeX={e.maxSizeX}
                        maxSizeY={e.maxSizeY}
                        resizeControl={screenResizedControl}
                        resizeIconColor={theme.palette.text.disabled}
                        gapX={gapX}
                        gapY={gapY}
                        unitWidth={unitWidth}
                        unitHeight={unitHeight}
                        removeHandler={removeElementHandler}
                    >
                        {renderElement(e.id, e.type)}
                    </PanelWrapper>
                ))}
            </DashboardLayoutComponent>
        );
    }, [elements, fetchedSavedState, theme.palette.text.disabled, screenResizedControl, layoutResizeStopHandler, layoutChangeHandler, removeElementHandler, renderElement]);

    const { unsavedChangesDialog } = useUnsavedChangesDialog(hasChanges, saveChangesHandler, undefined, undefined, true);

    const addedElementTypes = useMemo(() => {
        const api = layoutRef.current;
        if (api && showAddElementDialog) {
            const t = api.serialize();
            return t.map(e => {
                const id = e.id;
                if (id) {
                    const i = id.indexOf('_');
                    return id.substring(0, i);
                }
                return '';
            });

        }
        return [];
    }, [showAddElementDialog]);
    
    return (
        <>
            {unsavedChangesDialog}
            <ConfirmationDialog
                message="Are you sure you want to save the current layout for both Edit and View?"
                title="Confirm Action"
                onClose={ () => setShowConfirmSaveBothDialog(false) }
                onContinue={ saveChangesAsBothHandler }
                open={showConfirmSaveBothDialog}
                fullWidth
            />
            <ConfirmationDialog
                message="Are you sure you want to reset the current layout to its default form? All changes will be lost"
                title="Confirm Action"
                onClose={ () => setShowResetToDefaultConfirmDialog(false) }
                onContinue={ resetLayoutHandler }
                open={showResetToDefaultConfirmDialog}
                fullWidth
            />
            <AddJobDashboardElementDialog
                open={showAddElementDialog}
                activeFields={activeFields}
                alreadyAddedElements={addedElementTypes}
                closeHandler={() => setShowAddElementDialog(false)}
                addElementHandler={addElementHandler}
            />
            <div style={{ marginBottom: '10px' }}>
                <span>
                    <Button
                        variant="contained"
                        endIcon={<ReplayIcon />}
                        onClick={() => setShowResetToDefaultConfirmDialog(true)}
                        sx={{ mr: '5px' }}
                    >Reset to default</Button>
                    <Button
                        variant="contained"
                        endIcon={<AddIcon />}
                        onClick={() => setShowAddElementDialog(true)}
                    >Add Element</Button>
                </span>
                <span style={{ float: 'right' }}>
                    <FormControlLabel
                        sx={{ m: 0, mr:'20px' }}
                        label="Save as both Edit & View"
                        labelPlacement="start"
                        control={
                            <Checkbox
                                checked={ saveAsBothEditAndView }
                                onChange={ () => setSaveAsBothEditAndView(prev => !prev) }
                                sx={{ p: '4px' }}
                            />
                        }
                    />
                    <Button
                        color="success"
                        variant="contained"
                        endIcon={<SaveIcon />}
                        disabled={!hasChanges && !saveAsBothEditAndView}
                        onClick={saveAsBothEditAndView ? () => setShowConfirmSaveBothDialog(true) : saveChangesHandler}
                    >Save</Button>
                </span>
            </div>
            <Box p="10px" height="100%">
                <div className="control-section">
                    {layoutSwapControl && renderLayout()}
                    {!layoutSwapControl && renderLayout()}
                </div>
            </Box>
        </>
    );
}