import Alert from "components/Alert";
import Snackbar from "@mui/material/Snackbar";
import React, { useEffect, useState } from "react";
import EditRecordScreenLayout from "components/ScreenLayouts/Clients/EditRecordScreenLayout";
import PageContentLayout from "layouts/PageContentLayout";
import PageLayout from "layouts/PageLayout";
import { GetClientsSettings } from "services/ConfigurationService";

export default function ClientCreatePage() {
    const [summaryBar, setSummaryBar] = useState(<></>);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [defaultCode1Type, setDefaultCode1Type] = useState(0);
    const [defaultCode2Type, setDefaultCode2Type] = useState(0);
    const [defaultSummary, setDefaultSummary] = useState('');

    useEffect(() => {
        const getSettings = async () => {
            const settings = await GetClientsSettings();
            if (settings && settings.length > 0) {
                for (let i = 0; i < settings.length; i++) {
                    const s = settings[i];
                    if (s.type === 'ClientDefaultCode1Type' && s.value) setDefaultCode1Type(+s.value);
                    else if (s.type === 'ClientDefaultCode2Type' && s.value) setDefaultCode2Type(+s.value);
                    else if (s.type === 'ClientDefaultSummary' && s.value) setDefaultSummary(s.value);
                }
            }
        };
        getSettings();
    }, []);

    return (
        <PageLayout SummaryBar={summaryBar}>
            <Snackbar open={errorMessage !== ''} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <Snackbar open={successMessage !== ''} autoHideDuration={3000} onClose={() => setSuccessMessage('')} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert color="success" onClose={() => setSuccessMessage('')}>{ successMessage }</Alert>
            </Snackbar>
            <PageContentLayout showLoading={isLoading}>
                <EditRecordScreenLayout
                    defaultCode1Type={defaultCode1Type}
                    defaultCode2Type={defaultCode2Type}
                    defaultSummary={defaultSummary}
                    loadingHandler={setIsLoading}
                    setSummaryBar={setSummaryBar}
                    errorHandler={setErrorMessage}
                    successHandler={setSuccessMessage}
                />
            </PageContentLayout>
        </PageLayout>
    );
}