import Alert from "components/Alert";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ChangeTracker } from "common/models/hooks/ChangeTracker";
import { Permission } from "common/models/Permissions";
import useObjectStateWithChangeTracker from "hooks/UseObjectStateWithChangeTracker";
import useUnsavedChangesDialog from "hooks/UseUnsavedChangesDialog";
import PageContentLayout from "layouts/PageContentLayout";
import { GetMyRecordDefaults, UpdateMyRecordDefaults } from "services/UsersService";
import { companyHasSinglePermission, userHasSinglePermission } from "util/PermissionsUtils";
import { ObjToSettingsArray } from "util/SettingsUtil";
import TitleAndActionSummaryBar from "../SummaryBars/TitleAndActionSummaryBar";

interface Props {
    setSummaryBar?: (summaryBar: JSX.Element) => void
}

interface RecordDefaultsObj {
    DefaultTabDashboard: string,
    TabDefaultsCandidates: string,
    TabDefaultsClients: string,
    TabDefaultsContacts: string,
    TabDefaultsJobs: string,
    TabDefaultsLookup: string,
    TabDefaultsOpportunities: string,
    TabDefaultsPlacements: string,
};

const defaultAvailability: ChangeTracker<Partial<RecordDefaultsObj>> = {
    TabDefaultsCandidates: false,
    TabDefaultsClients: false,
    TabDefaultsContacts: false,
    TabDefaultsJobs: false,
    TabDefaultsOpportunities: false,
    TabDefaultsPlacements: false,
}

const defaultRecordDefaultsObj: RecordDefaultsObj = {
    DefaultTabDashboard: 'DASHBOARD_Dashboard',
    TabDefaultsCandidates: 'CANDIDATES_Home',
    TabDefaultsClients: 'CLIENTS_Home',
    TabDefaultsContacts: 'CONTACTS_Home',
    TabDefaultsJobs: 'JOBS_Home',
    TabDefaultsLookup: 'TAB_CANDIDATES',
    TabDefaultsOpportunities: 'OPPORTUNITIES_Home',
    TabDefaultsPlacements: 'PLACEMENTS_Home',
};

const noChangesRecordDefaults: ChangeTracker<RecordDefaultsObj> = {
    DefaultTabDashboard: false,
    TabDefaultsCandidates: false,
    TabDefaultsClients: false,
    TabDefaultsContacts: false,
    TabDefaultsJobs: false,
    TabDefaultsLookup: false,
    TabDefaultsOpportunities: false,
    TabDefaultsPlacements: false,
}

export default function UserRecordDefaultsPageContent({ setSummaryBar }: Props) {
    const [isLoading, setIsLoading] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const { state, init, change, updateInitial, hasChanges } = useObjectStateWithChangeTracker<RecordDefaultsObj>(defaultRecordDefaultsObj, noChangesRecordDefaults);

    const saveChangesCallback = useCallback(async () => {
        setIsLoading(true);
        const settings = ObjToSettingsArray(state);
        const res = await UpdateMyRecordDefaults(settings, setErrorMessage);
        
        if (!res) {
            setIsLoading(false);
            return false;
        }

        updateInitial();
        setShowSuccess(true);
        setIsLoading(false);
        return true;
    }, [state, updateInitial]);

    useEffect(() => {
        const action = (
            <Button
                variant="contained"
                color="success"
                disabled={!hasChanges}
                onClick={saveChangesCallback}
            >Save</Button>
        );

        const summaryBar = (
            <TitleAndActionSummaryBar
                action={action}
                title="Account > Record Defaults"
                browserTabTitle="Record Defaults > Account"
            />
        );
        setSummaryBar && setSummaryBar(summaryBar);
    }, [hasChanges, saveChangesCallback, setSummaryBar]);

    const availableSettings = useMemo(() => {
        let res = {...defaultAvailability};
        res.TabDefaultsCandidates = userHasSinglePermission(Permission.Candidates) && companyHasSinglePermission(Permission.Candidates);
        res.TabDefaultsClients = userHasSinglePermission(Permission.Clients) && companyHasSinglePermission(Permission.Clients);
        res.TabDefaultsContacts = userHasSinglePermission(Permission.Contacts) && companyHasSinglePermission(Permission.Contacts);
        res.TabDefaultsJobs = userHasSinglePermission(Permission.Jobs) && companyHasSinglePermission(Permission.Jobs);
        res.TabDefaultsOpportunities = userHasSinglePermission(Permission.Opportunities) && companyHasSinglePermission(Permission.Opportunities);
        res.TabDefaultsPlacements = userHasSinglePermission(Permission.Placements) && companyHasSinglePermission(Permission.Placements);
        return res;
    }, []);

    useEffect(() => {
        const getSettings = async () => {
            setIsLoading(true);
            const settings = await GetMyRecordDefaults(setErrorMessage);
            if (settings && settings.length > 0) {
                let savedSettings: RecordDefaultsObj = {...defaultRecordDefaultsObj};
                for (let i = 0; i < settings.length; i++) {
                    const setting = settings[i];
                    (savedSettings as any)[setting.type] = setting.value;
                }
                init(savedSettings);
            }
            setIsLoading(false);
        };
        getSettings();
    }, [init]);

    const changeHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        change(name as keyof RecordDefaultsObj, value);
    }, [change]);

    const { unsavedChangesDialog } = useUnsavedChangesDialog(hasChanges, saveChangesCallback);

    return (
        <>
            {unsavedChangesDialog}
            <Snackbar open={showSuccess} autoHideDuration={3000} onClose={() => setShowSuccess(false)}>
                <Alert onClose={() => setShowSuccess(false)}>Changes Saved</Alert>
            </Snackbar>
            <Snackbar open={errorMessage !== ''} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <PageContentLayout showLoading={isLoading} title="Record Defaults">
                <Stack spacing={3}>
                    {availableSettings.TabDefaultsCandidates &&
                        <TextField
                            select
                            label="Candidates"
                            value={state.TabDefaultsCandidates === '' ? 'CANDIDATES_Home' : state.TabDefaultsCandidates}
                            name="TabDefaultsCandidates"
                            onChange={changeHandler}
                        >
                            <MenuItem value="CANDIDATES_Activities">Activities</MenuItem>
                            <MenuItem value="AdditionalInfo">Additional Info</MenuItem>
                            <MenuItem value="CANDIDATES_CallsNotes">Calls/Notes</MenuItem>
                            <MenuItem value="CANDIDATES_Documents">Documents</MenuItem>
                            <MenuItem value="CANDIDATES_Home">Home (Default)</MenuItem>
                            <MenuItem value="CANDIDATES_Interviews">Interviews</MenuItem>
                            <MenuItem value="CANDIDATES_Jobs">Jobs</MenuItem>
                            <MenuItem value="CANDIDATES_Messages">Messages</MenuItem>
                            <MenuItem value="CANDIDATES_Placements">Placements</MenuItem>
                            <MenuItem value="CANDIDATES_CV">Resume</MenuItem>
                            <MenuItem value="CANDIDATES_CVsSent">Submissions</MenuItem>
                            <MenuItem value="CANDIDATES_WorkHistory">Work History</MenuItem>
                        </TextField>
                    }
                    {availableSettings.TabDefaultsClients &&
                        <TextField
                            select
                            label="Clients"
                            value={state.TabDefaultsClients === '' ? 'CLIENTS_Home' : state.TabDefaultsClients}
                            name="TabDefaultsClients"
                            onChange={changeHandler}
                        >
                            <MenuItem value="CLIENTS_Activities">Activities</MenuItem>
                            <MenuItem value="CLIENTS_CallsNotes">Calls/Notes</MenuItem>
                            <MenuItem value="CLIENTS_Contacts">Contacts</MenuItem>
                            <MenuItem value="CLIENTS_Documents">Documents</MenuItem>
                            <MenuItem value="CLIENTS_Home">Home (Default)</MenuItem>
                            <MenuItem value="CLIENTS_Interviews">Interviews</MenuItem>
                            <MenuItem value="CLIENTS_Jobs">Jobs</MenuItem>
                            <MenuItem value="CLIENTS_Meetings">Meetings</MenuItem>
                            <MenuItem value="CLIENTS_Messages">Messages</MenuItem>
                            <MenuItem value="CLIENTS_OpenJobs">Open Jobs</MenuItem>
                            <MenuItem value="CLIENTS_Placements">Placements</MenuItem>
                            <MenuItem value="CLIENTS_Sites">Sites</MenuItem>
                            <MenuItem value="CLIENTS_Submissions">Submissions</MenuItem>
                        </TextField>
                    }
                    {availableSettings.TabDefaultsContacts &&
                        <TextField
                            select
                            label="Contacts"
                            value={state.TabDefaultsContacts === '' ? 'CONTACTS_Home' : state.TabDefaultsContacts}
                            name="TabDefaultsContacts"
                            onChange={changeHandler}
                        >
                            <MenuItem value="CONTACTS_Activities">Activities</MenuItem>
                            <MenuItem value="CONTACTS_CallsNotes">Calls/Notes</MenuItem>
                            <MenuItem value="CONTACTS_Documents">Documents</MenuItem>
                            <MenuItem value="CONTACTS_Home">Home (Default)</MenuItem>
                            <MenuItem value="CONTACTS_Interviews">Interviews</MenuItem>
                            <MenuItem value="CONTACTS_Jobs">Jobs</MenuItem>
                            <MenuItem value="CONTACTS_Meetings">Meetings</MenuItem>
                            <MenuItem value="CONTACTS_Messages">Messages</MenuItem>
                            <MenuItem value="CONTACTS_JobsOpen">Open Jobs</MenuItem>
                            <MenuItem value="CONTACTS_Placements">Placements</MenuItem>
                            <MenuItem value="CONTACTS_CVsSent">Submissions</MenuItem>
                        </TextField>
                    }
                    <TextField
                        select
                        label="Dashboard"
                        value={state.DefaultTabDashboard === '' ? 'DASHBOARD_Dashboard' : state.DefaultTabDashboard}
                        name="DefaultTabDashboard"
                        onChange={changeHandler}
                    >
                        <MenuItem value="DASHBOARD_Dashboard">Home (Default)</MenuItem>
                        <MenuItem value="DASHBOARD_Jobs">Open Jobs</MenuItem>
                        <MenuItem value="DASHBOARD_Jobs_CG">Jobs Roster</MenuItem>
                        <MenuItem value="DASHBOARD_Activities">Open Activity</MenuItem>
                        <MenuItem value="DASHBOARD_Meetings">Open Meetings</MenuItem>
                        <MenuItem value="DASHBOARD_opportunities">Open Opportunities</MenuItem>
                        <MenuItem value="DASHBOARD_adverts">Live Adverts</MenuItem>
                        <MenuItem value="DASHBOARD_contractors">Contractors</MenuItem>
                    </TextField>
                    {availableSettings.TabDefaultsJobs &&
                        <TextField
                            select
                            label="Jobs"
                            value={state.TabDefaultsJobs === '' ? 'JOBS_Home' : state.TabDefaultsJobs}
                            name="TabDefaultsJobs"
                            onChange={changeHandler}
                        >
                            <MenuItem value="JOBS_Activities">Activities</MenuItem>
                            <MenuItem value="JOBS_Adverts">Adverts</MenuItem>
                            <MenuItem value="JOBS_CallsNotes">Calls/Notes</MenuItem>
                            <MenuItem value="JOBS_Candidates">Candidates</MenuItem>
                            <MenuItem value="Description">Description</MenuItem>
                            <MenuItem value="JOBS_Documents">Documents</MenuItem>
                            <MenuItem value="JOBS_Home">Home (Default)</MenuItem>
                            <MenuItem value="JOBS_Interviews">Interviews</MenuItem>
                            <MenuItem value="JOBS_Meetings">Meetings</MenuItem>
                            <MenuItem value="Messages">Messages</MenuItem>
                            <MenuItem value="JOBS_Placements">Placements</MenuItem>
                            <MenuItem value="JOBS_CVsSent">Submissions</MenuItem>
                        </TextField>
                    }
                    <TextField
                        select
                        label="Lookup"
                        value={state.TabDefaultsLookup === '' ? 'TAB_CLIENTS' : state.TabDefaultsLookup}
                        name="TabDefaultsLookup"
                        onChange={changeHandler}
                    >
                        <MenuItem value="TAB_ADVERTS">Adverts</MenuItem>
                        <MenuItem value="TAB_CANDIDATES">Candidates</MenuItem>
                        <MenuItem value="TAB_CLIENTS">Clients (Default)</MenuItem>
                        <MenuItem value="TAB_CONTACTS">Contacts</MenuItem>
                        <MenuItem value="TAB_JOBS">Jobs</MenuItem>
                        <MenuItem value="TAB_OPPORTUNITIES">Opportunities</MenuItem>
                        <MenuItem value="TAB_PLACEMENTS">Placements</MenuItem>
                        <MenuItem value="TAB_SITES">Sites</MenuItem>
                    </TextField>
                    {availableSettings.TabDefaultsOpportunities &&
                        <TextField
                            select
                            label="Opportunities"
                            value={state.TabDefaultsOpportunities === '' ? 'OPPORTUNITIES_Home' : state.TabDefaultsOpportunities}
                            name="TabDefaultsOpportunities"
                            onChange={changeHandler}
                        >
                            <MenuItem value="OPPORTUNITIES_Activities">Activities</MenuItem>
                            <MenuItem value="OPPORTUNITIES_CallsNotes">Calls/Notes</MenuItem>
                            <MenuItem value="OPPORTUNITIES_Documents">Documents</MenuItem>
                            <MenuItem value="OPPORTUNITIES_Home">Home (Default)</MenuItem>
                            <MenuItem value="OPPORTUNITIES_Meetings">Meetings</MenuItem>
                        </TextField>
                    }
                    {availableSettings.TabDefaultsPlacements &&
                        <TextField
                            select
                            label="Placements"
                            value={state.TabDefaultsPlacements === '' ? 'PLACEMENTS_Home' : state.TabDefaultsPlacements}
                            name="TabDefaultsPlacements"
                            onChange={changeHandler}
                        >
                            <MenuItem value="PLACEMENTS_Activities">Activities</MenuItem>
                            <MenuItem value="PLACEMENTS_CallsNotes">Calls/Notes</MenuItem>
                            <MenuItem value="PLACEMENTS_ChangeRequests">Change Requests</MenuItem>
                            <MenuItem value="PLACEMENTS_Documents">Documents</MenuItem>
                            <MenuItem value="PLACEMENTS_Financials">Financials</MenuItem>
                            <MenuItem value="PLACEMENTS_Home">Home (Default)</MenuItem>
                            <MenuItem value="Messages">Messages</MenuItem>
                        </TextField>
                    }
                </Stack>
            </PageContentLayout>
        </>
    );
}