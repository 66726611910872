import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { SeekCategoryMin, SeekJobCategory } from "common/models/JobPosting/Seek";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { GetSeekCategories } from "services/SeekService";

interface Props {
    value1: string | null,
    value2: string | null,
    onSelectCallback1: (c1: SeekJobCategory | null) => void,
    onSelectCallback2: (c2: SeekCategoryMin | null) => void,
    label1?: string,
    label2?: string,
}

export default function SeekCategoryPicker({ value1, value2, onSelectCallback1, onSelectCallback2, label1 = 'Category', label2 = 'Sub-Category' }: Props) {
    const [selectedCategory, setSelectedCategory] = useState<SeekJobCategory | null>(null);
    const [selectedSubCategory, setSelectedSubCategory] = useState<SeekCategoryMin | null>(null);
    const [options, setOptions] = useState<SeekJobCategory[]>([]);

    useEffect(() => {
        const getCategories = async () => {
            const res = await GetSeekCategories();
            if (res) setOptions(res);
        };
        getCategories();
    }, []);

    useEffect(() => {
        if (value1) {
            if (!selectedCategory || (selectedCategory && selectedCategory.id.value !== value1)) {
                const item = options.find(o => o.id.value === value1);
                if (item) setSelectedCategory(item);
            }
        }
        else setSelectedCategory(null);
    }, [options, selectedCategory, value1]);

    const options2 = useMemo(() => {
        if (selectedCategory) {
            return selectedCategory.children;
        }
        return [];
    }, [selectedCategory]);

    useEffect(() => {
        if (value2 && options2) {
            if (!selectedSubCategory || (selectedSubCategory && selectedSubCategory.id.value !== value2)) {
                const item = options2.find(o => o.id.value === value2);
                if (item) setSelectedSubCategory(item);
            }
        }
        else setSelectedSubCategory(null);
    }, [options2, selectedSubCategory, value2]);

    const cat1ChangeHandler = useCallback((e: any, c1: SeekJobCategory | null) => {
        onSelectCallback1(c1);
        onSelectCallback2(null);
    }, [onSelectCallback1, onSelectCallback2]);

    return (
        <Box display="flex" flexDirection="column" gap={2}>
            <Autocomplete
                size="small"
                fullWidth
                options={options}
                value={selectedCategory}
                getOptionLabel={o => o.name}
                isOptionEqualToValue={ (o,v) => o.id.value === v.id.value }
                onChange={ cat1ChangeHandler }
                renderInput={ (params) => (
                    <TextField {...params} label={label1} inputProps={{ ...params.inputProps, "data-lpignore": "true" }} />
                )}
            />
            <Autocomplete
                size="small"
                fullWidth
                options={options2}
                value={selectedSubCategory}
                getOptionLabel={o => o.name}
                isOptionEqualToValue={ (o,v) => o.id.value === v.id.value }
                onChange={ (e,v) => onSelectCallback2(v) }
                renderInput={ (params) => (
                    <TextField {...params} label={label2} inputProps={{ ...params.inputProps, "data-lpignore": "true" }} />
                )}
            />
        </Box>
    );

}