import { TimeZone } from "common/models/Common";
import moment from "moment";

export interface AutomationScheduleData {
  schedule: string,
  timeZone: TimeZone| null,
  interval: string,
  runDays: number[],
  monthTrigger: string,
  dayOfMonth: number,
  weekOrdinal: number,
  dayOfWeek: number,
  hourMinuteInterval: number,
  startTime: moment.Moment | undefined,
  runAfter: moment.Moment | undefined,
  runUntil: moment.Moment | undefined,
  startTimeString: string,
  runAfterString: string,
  runUntilString: string,
  isNew: boolean | undefined
}

export  const NewScheduleData:AutomationScheduleData  ={
  schedule: "Daily",
  runDays: [],
  monthTrigger: "",
  dayOfMonth: 0,
  weekOrdinal: 0,
  dayOfWeek: 0,
  timeZone: null,
  interval: "Once",
  hourMinuteInterval: 1,
  startTime: moment().minutes(0),
  runAfter: moment().hour(0).minutes(0),
  runUntil: moment().hour(23).minutes(45),
  startTimeString: moment().minutes(0).format("HH:mm"),
  runAfterString: moment().hour(0).minutes(0).format("HH:mm"),
  runUntilString: moment().hour(23).minutes(45).format("HH:mm"),
  isNew: true
}