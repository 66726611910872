import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import ControlCameraIcon from '@mui/icons-material/ControlCamera';
import { DataGridPremium, GridColDef } from "@mui/x-data-grid-premium";
import { MeetingAttendee } from "common/models/Meetings";
import { PreviewProps } from "common/models/Previews/Previews";
import React, { useEffect, useMemo, useState } from "react";
import Draggable from "react-draggable";
import { GetMeetingAttendees } from "services/MeetingsService";
import { PREVIEW_HEIGHT, PREVIEW_TITLE_STYLE, PREVIEW_WIDTH } from "util/Definitions/Constants/Previews";

export default function MeetingAttendeesQuickView({ recordId, hideCallback, isSourceHovered, hideDelayMs = 1250 }: PreviewProps) {
    const [shouldClose, setShouldClose] = useState(false);
    const [isFetchingData, setIsFetchingData] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [rows, setRows] = useState<MeetingAttendee[]>([]);

    useEffect(() => {
        const getData = async () => {
            setIsFetchingData(true);
            const res = await GetMeetingAttendees(recordId);
            if (res) setRows(res);
            setIsFetchingData(false);
        };
        getData();
    }, [recordId]);

    useEffect(() => {
        if (shouldClose) {
            const hidePreviewTimeout = setTimeout(() => {
                hideCallback();
            }, hideDelayMs);
            return () => clearTimeout(hidePreviewTimeout);
        }
    }, [shouldClose, hideDelayMs, hideCallback]);

    useEffect(() => {
        if (isSourceHovered || isHovered) setShouldClose(false);
        else setShouldClose(true);
    }, [isSourceHovered, isHovered]);

    const columns = useMemo<GridColDef[]>(() => {
        return [
            { headerName: 'ID', field: 'id', width: 75 },
            { headerName: 'Name', field: 'name', width: 300, },
            { headerName: 'Type', field: 'type', cellsalign: 'center', align: 'center', width: 150 }
        ];
    }, []);
    
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
            defaultPosition={{ x: (window.innerWidth / 2) - (PREVIEW_WIDTH / 2), y: (window.innerHeight / 2) - (PREVIEW_HEIGHT / 2) }}
        >
            <Dialog
                open={true}
                maxWidth={false}
                PaperProps={{
                    onMouseEnter: () => setIsHovered(true),
                    onMouseLeave: () => setIsHovered(false),
                    sx: { height: `${PREVIEW_HEIGHT}px`, width: `${PREVIEW_WIDTH}px` }
                }}
                hideBackdrop
                sx={{ bottom: 'unset', right: 'unset', left: 'unset' }}
            >
                <DialogTitle component="div" display="flex" sx={ PREVIEW_TITLE_STYLE }>
                    <div style={{ alignSelf: 'center' }}>
                        Attendees - Meeting {recordId}
                    </div>
                    <div style={{ marginLeft: 'auto' }}>
                        <IconButton id="draggable-dialog-title" sx={{ cursor: 'move', color: t => t.palette.primary.contrastText }}><ControlCameraIcon /></IconButton>
                        <IconButton onClick={ () => hideCallback() } sx={{ color: t => t.palette.primary.contrastText }}><CloseIcon /></IconButton>
                    </div>
                </DialogTitle>
                <DialogContent sx={{ display: 'flex' }}>
                    <div style={{ paddingTop: '10px', display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                        <DataGridPremium
                            loading={isFetchingData}
                            density="compact"
                            disableColumnMenu
                            disableColumnReorder
                            disableColumnResize
                            hideFooter
                            columns={columns}
                            rows={rows}
                        />
                    </div>
                </DialogContent>
            </Dialog>
        </Draggable>
    );
}