import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { SxProps } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import React, { useCallback, useState } from "react";
import { GetCandidatesRecentlyInactive } from "services/CandidatesService";
import * as XLSX from "xlsx";
import moment from "moment";

interface Props {
    description: string,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
}

const filterElementStyle: SxProps = { flex: '1 1 0', mr: 1 };

export default function RecentlyInactive({ description, loadingHandler, errorHandler }: Props) {
    
    const [timeRange, setTimeRange] = useState(7);

    const getDataCallback = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        const res = await GetCandidatesRecentlyInactive(timeRange, errorHandler);
        if (res) {
            const m = moment();
            const filename = `RecentlyInactive${m.format('YYYYMMDDhhmmss')}.xlsx`;
            const ws = XLSX.utils.json_to_sheet(res);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws);
            XLSX.writeFile(wb, filename);
        }
        loadingHandler && loadingHandler(false);
    }, [timeRange, errorHandler, loadingHandler]);

    return (
        <>
            <Box display="flex">
                <TextField select label="Time Range" value={timeRange.toString()} onChange={({ target }) => setTimeRange(+target.value)} sx={filterElementStyle}>
                    <MenuItem value="7">7 Days</MenuItem>
                    <MenuItem value="14">14 Days</MenuItem>
                    <MenuItem value="30">30 Days</MenuItem>
                </TextField>
                <Button variant="contained" color="success" onClick={getDataCallback}>Run Report</Button>
            </Box>
        </>
    );
}