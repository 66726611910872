import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Navigate } from "react-router-dom";
import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import { Candidate, QuestionField, ScreenCandidateData } from "common/models/Candidates/Candidate";
import { Job } from "common/models/Jobs/Job";
import Avatar from "@mui/material/Avatar";
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import { AllowCandidatesAddEdit } from "common/data/Permissions/CandidatesAccess";
import Button from "@mui/material/Button";
import { PREVIEW_CANDIDATE_RENDERER, PREVIEW_JOB_RENDERER } from "util/Definitions/Constants/Previews";
import { GetCandidateById, ScreenCandidate } from "services/CandidatesService";
import { GetJobById } from "services/JobsService";
import Stack from "@mui/material/Stack";
import ScreeningTemplatePicker from "components/Pickers/ScreeningTemplatePicker";
import { GetMyUser, GetSettingBySettingName } from "services/UsersService";
import { ScreeningQuestionsTemplate } from "common/models/Templates/ScreeningQuestionsTemplate";
import { GetScreeningQuestionnaireTemplateById } from "services/TemplatesService";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import CandidateResumeRenderer from "components/Candidates/CandidateResumeRenderer";
import TagsManagementDialog from "components/Dialogs/TagsManagementDialog";
import { DeleteActivityById, GetActivityAttributesById, GetActivityById } from "services/ActivitiesService";
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmationDialog from "components/Dialogs/Generic/ConfirmationDialog";
import PreviewLoaderComponent from "components/Previews/PreviewLoader";
import ActionMenu from "components/Menus/ActionMenu";
import { MenuOptionDefinition } from "common/models/MenuDefinition";
import SendReferralDialog from "components/Dialogs/SendReferralDialog";
import LinkCandidatesToJobDialog from "components/Dialogs/Candidates/LinkCandidatesToJobDialog";
import { GenerateScreeningRefCheckDocument } from "services/DocumentGenerationService";

interface Props {
    candidateId?: number,
    jobId?: number,
    activityId?: number,
    setSummaryBar?: (sb: JSX.Element) => void,
    loadingHandler?: (isLoading: boolean) => void,
    successHandler?: (message: string) => void,
    errorHandler?: (message: string) => void,
}

interface QuestionnaireFields {
    order: number,
    name: string,
    type: string,
    mandatory: boolean
}

const renderSummary = (c: Candidate, j?: Job) => {

    const candidateName = <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>Screening Questions for {PREVIEW_CANDIDATE_RENDERER(c.id, c.fullName)}</span>;
    let jobData = <span></span>;
    if (j) jobData = <span>&nbsp;for {PREVIEW_JOB_RENDERER(j.id, j.title)}</span>;
    return <>{candidateName}{jobData}</>;
};

export default function ScreeningEditPageContent({ candidateId: candidateIdProp = 0, jobId = 0, activityId = 0, setSummaryBar, loadingHandler, successHandler, errorHandler }: Props) {
    const [candidate, setCandidate] = useState<Candidate>();
    const [job, setJob] = useState<Job>();
    const [selectedTemplate, setSelectedTemplate] = useState<ScreeningQuestionsTemplate | null>(null);
    const [showTagsPrevew, setShowTagsPreview] = useState(false);
    const [showTagsPrevewNoDelay, setShowTagsPreviewNoDelay] = useState(false);
    const [showTagsManagement, setShowTagsManagement] = useState(false);
    const [showValidation, setShowValidation] = useState(false);
    const [isCvExpanded, setIsCvExpanded] = useState(true);
    const [savedChanges, setSavedChanges] = useState(false);
    const [fields, setFields] = useState<QuestionField[]>([]);
    const [screeningNotes, setScreeningNotes] = useState('');
    const [myUserId, setMyUserId] = useState(0);
    const [isJustNotes, setIsJustNotes] = useState(true);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [showLinkCandidateToJobDialog, setShowLinkCandidateToJobDialog] = useState(false);
    const [showSendReferralDialog, setShowSendReferralDialog] = useState(false);

    const candidateIdArray = useMemo(() => {
        if (candidate) return [candidate.id];
        return [];
    }, [candidate])

    useEffect(() => {
        const getCreateData = async () => {
            loadingHandler && loadingHandler(true);
            if (candidateIdProp) {
                const res = await GetCandidateById(candidateIdProp);
                if (res) setCandidate(res);
            }

            if (jobId) {
                const res = await GetJobById(jobId);
                if (res) setJob(res);
            }

            const me = await GetMyUser();
            if (me) setMyUserId(me.userID);

            const d = await GetSettingBySettingName('DefaultScreeningQuestionID');
            if (d) {
                const res = await GetScreeningQuestionnaireTemplateById(+d);
                if (res) {
                    setSelectedTemplate(res);
                    setIsJustNotes(false);
                    if (res.json) {
                        const f = JSON.parse(res.json) as QuestionnaireFields[];
                        setFields(f.map(q => ({ ...q, value: '',templateId:res?.documentTemplateId ?? 0, options: []}) ));
                    }
                }
            }

            loadingHandler && loadingHandler(false);
        };
        candidateIdProp > 0 && getCreateData();
    }, [candidateIdProp, jobId, loadingHandler]);

    useEffect(() => {
        const getEditData = async () => {
            loadingHandler && loadingHandler(true);
            if (activityId) {
                const res = await GetActivityById(activityId);
                if (res) {
                    const atts = await GetActivityAttributesById(activityId);
                    if (atts) {
                        const candidateAttribute = atts.find(a => a.entityID === 3);
                        if (candidateAttribute) {
                            const c = await GetCandidateById(candidateAttribute.playerID);
                            if (c) setCandidate(c);
                        }
                    }
                    if (res.associatedData) {
                        const f = JSON.parse(res.associatedData) as QuestionField[];
                        setFields(f);
                        setIsJustNotes(false);
                    }
                    else {
                        setScreeningNotes(res.notes);
                        setIsJustNotes(true);
                    }
                }
            }
            loadingHandler && loadingHandler(false);
        };
        activityId > 0 && getEditData();
    }, [activityId, loadingHandler]);

    const canAddEditCandidates = useMemo(() => AllowCandidatesAddEdit(), []);

    const saveScreening = useCallback(async () => {
        setShowValidation(true);
        for (let i = 0; i < fields.length; i++) {
            const f = fields[i];
            if (f.mandatory && !Boolean(f.value)) {
                errorHandler && errorHandler("Required fields can't be empty");
                return false;
            }
        }

        let notesField: QuestionField[] = [{ mandatory: true, name: 'Notes', order: -1, type: '0', value: screeningNotes, templateId: fields[0]?.templateId ?? 0, options: []}];
        if (isJustNotes) {
            if (!Boolean(screeningNotes)) {
                errorHandler && errorHandler("Required fields can't be empty");
                return false;
            }
        }
        const candidateId = candidate ? candidate.id : 0;

        const data: ScreenCandidateData = {
            candidateId: candidateId,
            activityId: activityId ?? 0,
            jobId: jobId ?? 0,
            fields: isJustNotes ? notesField : fields
        };

        loadingHandler && loadingHandler(true);
        const res = await ScreenCandidate(data, errorHandler);

        if(res) {
            // activityId = res.value;
        }

        loadingHandler && loadingHandler(false);
        return res;

    },[screeningNotes, isJustNotes, candidate, activityId, jobId, fields, loadingHandler, errorHandler]);

    const submitHandler = useCallback(async () => {
       
        const res = await saveScreening();
        if (res) {
                setSavedChanges(true);
            return true;
        }

        return false;

    }, [saveScreening]);

    const generateDocument = useCallback(async (isPdf: boolean) => {
            loadingHandler && loadingHandler(true);
            var result = await saveScreening();
            if(result) {
                await GenerateScreeningRefCheckDocument(13, activityId > 0 ? activityId : result.value, fields[0]?.templateId ?? 0, [{ placeholder: '', value: '' }], errorHandler,true,isPdf);
            }

            loadingHandler && loadingHandler(false);
    }, [loadingHandler, saveScreening, activityId, fields, errorHandler]);

    const actionMenuDefinitions = useMemo<MenuOptionDefinition[]>(() => {
        return [
            { label: 'Link To Job', type: 'action', action: () => setShowLinkCandidateToJobDialog(true) },
            { label: 'Referral - Send', type: 'action', action: () => setShowSendReferralDialog(true) },
            { label: 'Download - PDF', type: 'action', action: () => generateDocument(true) },
            { label: 'Download - Docx', type: 'action', action: () => generateDocument(false) },
        ];
    }, [generateDocument]);

    useEffect(() => {
        if (candidate && setSummaryBar) {
            const tagsAction = (
                <Avatar
                    key="tagsAction"
                    onClick={canAddEditCandidates ? () => setShowTagsManagement(true) : () => setShowTagsPreviewNoDelay(true)}
                    onMouseEnter={ () => setShowTagsPreview(true) }
                    onMouseLeave={ () => setShowTagsPreview(false) }
                    sx={{ bgcolor: '#f209a6', mr: '5px', cursor: 'pointer', height: '36.5px', width: '36.5px' }}>
                    { candidate.tags ? <LocalOfferIcon /> : <LocalOfferOutlinedIcon /> }
                </Avatar>
            );

            const submitButton = <Button key="submitAction" disabled={Boolean(candidateIdProp) && !isJustNotes && !Boolean(selectedTemplate)} variant="contained" color="success" onClick={submitHandler} sx={{ mr: '5px' }}>Save</Button>;

            const deleteButton = activityId > 0 ? (
                <Avatar
                    key="deleteAction"
                    onClick={ () => setShowDeleteConfirmation(true) }
                    sx={{ mr: '5px', cursor: 'pointer', height: '36.5px', width: '36.5px' }}
                    title="Delete Screening"
                >
                    <DeleteIcon />
                </Avatar>
            ) : <React.Fragment key="deleteAction" />;

            const actionMenu = (
                <Box key="actionsMenu">
                    <ActionMenu definition={actionMenuDefinitions} mr="5px" />
                </Box>
            );

            const actions = <>{deleteButton}{tagsAction}{actionMenu}{submitButton}</>

            setSummaryBar(
                <TitleAndActionSummaryBar
                    title={candidate ? <>{renderSummary(candidate, job)}</> : ''}
                    action={actions}
                    browserTabTitle={!candidate ? '' : candidate.fullName + " - Candidates"}
                />
            );
        }
    }, [candidate, job, canAddEditCandidates, selectedTemplate, submitHandler, setSummaryBar, isJustNotes, candidateIdProp, activityId, actionMenuDefinitions]);

    const templateChangedHandler = useCallback((t: ScreeningQuestionsTemplate | null) => {
        setSelectedTemplate(t);
        if (t && t.json) {
            setIsJustNotes(false);
            const f = JSON.parse(t.json) as QuestionnaireFields[];
            setFields(f.map(q => ({ ...q, value: '', templateId: t?.documentTemplateId ?? 0, options: [] }) ));
        }
        else {
            setIsJustNotes(true);
            setFields([]);
        }
    }, []);

    const answerChangeHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { value, name } = e.target;
        setFields(prev => {
            let tmp = [...prev];
            tmp[+name].value = value;
            return tmp;
        });
    }, []);

    const redirectUrl = useMemo(() => {
        if (savedChanges && candidate) {
            if (jobId) return `/candidates/${candidate.id}?jobId=${jobId}`;
            return `/candidates/${candidate.id}`;
        }
        return '';
    }, [candidate, jobId, savedChanges]);

    const deleteCallback = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        if (activityId) {
            const res = await DeleteActivityById(activityId);
            if (res) setSavedChanges(true);
        }
        loadingHandler && loadingHandler(false);
    }, [activityId, loadingHandler]);

    useEffect(() => {
        if (isJustNotes) {
            const item = document.getElementById('ScreeningNotesTextArea');
            if (item) {
                item.style.alignSelf = 'start';
            }
        }
    }, [isJustNotes]);

    return (
        <>
            {savedChanges && redirectUrl && <Navigate to={redirectUrl} />}
            <ConfirmationDialog
                open={showDeleteConfirmation}
                message="Are you sure you want to delete this screening record?"
                onClose={() => setShowDeleteConfirmation(false)}
                title="Confirm Action"
                onContinue={deleteCallback}
            />
            <PreviewLoaderComponent
                open={showTagsPrevew}
                entityType="candidate"
                recordId={candidate ? candidate.id : 0}
                showDelayMs={showTagsPrevewNoDelay ? 0 : undefined}
                titleOverride={candidate ? candidate.fullName : undefined}
                isTagsPreview
            />
            <TagsManagementDialog
                open={showTagsManagement}
                entityId={3}
                recordIds={candidateIdArray}
                closeHandler={ () => setShowTagsManagement(false) }
                loadingHandler={ loadingHandler }
                errorHandler={ errorHandler }
                successHandler={ successHandler }
            />
            <SendReferralDialog
                open={showSendReferralDialog}
                candidateId={candidate && candidate.id ? candidate.id : 0}
                candidateName={candidate && candidate.fullName}
                closeHandler={ () => setShowSendReferralDialog(false) }
                loadingHandler={ loadingHandler }
                errorHandler={ errorHandler }
                successHandler={ successHandler }
            />
            <LinkCandidatesToJobDialog
                open={showLinkCandidateToJobDialog}
                closeHandler={() => setShowLinkCandidateToJobDialog(false)}
                candidateIds={candidateIdArray}
                loadingHandler={ loadingHandler }
                errorHandler={ errorHandler }
                successHandler={ successHandler }
            />
            
            <Box display="flex" height="100%">
                {!isJustNotes &&
                    <Stack spacing={2} pr="10px" width={isCvExpanded ? '50%' : 'calc(100% - 20px)'} sx={{ transition: '1s ease' }}>
                        {candidateIdProp > 0 &&
                            <ScreeningTemplatePicker
                                userId={myUserId}
                                templateId={selectedTemplate ? selectedTemplate.id : 0}
                                label="Template"
                                onSelectCallback={templateChangedHandler}
                            />
                        }
                        {fields.map((f, i) =>
                            <TextField
                                key={i}
                                required={f.mandatory}
                                error={showValidation && f.mandatory && !Boolean(f.value)}
                                value={f.value}
                                name={i.toString()}
                                label={f.name}
                                multiline={f.type === '1'}
                                rows={f.type === '1' ? 6 : undefined}
                                onChange={ answerChangeHandler }
                            />
                        )}
                    </Stack>
                }
                {isJustNotes && 
                    <Box display="flex" flexDirection="column" gap={2} pr="10px"  width={isCvExpanded ? '50%' : 'calc(100% - 20px)'} sx={{ transition: '1s ease' }}>
                        {candidateIdProp > 0 &&
                            <ScreeningTemplatePicker
                                userId={myUserId}
                                templateId={selectedTemplate ? selectedTemplate.id : 0}
                                label="Template"
                                onSelectCallback={templateChangedHandler}
                            />
                        }
                        <TextField
                            required
                            multiline
                            label="Screening Notes"
                            value={screeningNotes}
                            onChange={({target}) => setScreeningNotes(target.value)}
                            sx={{ height: '100%' }}
                            id="ScreeningNotesTextArea"
                            InputProps={{ sx: { height: '100%' } }}
                            error={showValidation && !Boolean(screeningNotes)}
                        />
                    </Box>
                }
                <Box height="100%" width={isCvExpanded ? '50%' : '20px'} bgcolor="background.default" sx={{ transition: '1s ease' }} >
                    <Box component="span" border="none" height="100%" width="10px" fontWeight="bold" fontSize="large" sx={{ cursor: 'e-resize', float: 'left' }} onClick={ () => setIsCvExpanded(prev => !prev) } />
                    <Box py="5px" height="100%" display={isCvExpanded ? 'flex' : 'none'}>
                        <CandidateResumeRenderer candidateId={candidate ? candidate.id : 0} loadingHandler={loadingHandler} />
                    </Box>
                </Box>
            </Box>
        </>
    );
}