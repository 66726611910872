import { MenuOptionDefinition } from "common/models/MenuDefinition";
import { PartnerAction, PartnerActionType, PartnerActionUrlType, PartnerActionWorkflowType } from "common/models/PartnerActions";
import { useCallback, useEffect, useState } from "react";
import { GetCandidatePartnerActions, GetCandidateWorkflowPartnerActions } from "services/CandidatesService";
import { GetClientPartnerActions } from "services/ClientsService";
import { GetContactPartnerActions } from "services/ContactsService";
import { GetJobPartnerActions } from "services/JobsService";
import { GetOpportunityPartnerActions } from "services/OpportunitiesService";
import { GetApprovedPlacementPartnerActions, GetApprovedPlacementWorkflowPartnerActions, GetPlacementPartnerActions, GetPlacementWorkflowPartnerActions } from "services/PlacementsService";
import { GetMyUser } from "services/UsersService";

interface PartnerActionsData {
    clientId?: number,
    contactId?: number,
    candidateId?: number,
    opportunityId?: number,
    jobId?: number,
    placementId?: number,
}

export default function usePartnerActions(typeId: PartnerActionType | null, data: PartnerActionsData, showPopupHandler: (show: boolean) => void) {
    const [partnerActions, setPartnerActions] = useState<MenuOptionDefinition[]>([]);
    const [interviewActions, setInterviewActions] = useState<MenuOptionDefinition[]>([]);
    const [refCheckActions, setRefCheckActions] = useState<MenuOptionDefinition[]>([]);
    const [popupTitle, setPopupTitle] = useState('');
    const [popupUrl, setPopupUrl] = useState('');

    const setPopupData = useCallback((title: string, url: string) => {
        if (Boolean(title) && Boolean(url)) {
            setPopupTitle(title);
            setPopupUrl(url);
            showPopupHandler(true);
        }
    }, [showPopupHandler]);

    useEffect(() => {
        const getPartnerActions = async () => {
            const me = await GetMyUser();
            const isCandidateWorkflow = typeId === PartnerActionType.CandidatesWorkflow;
            
            let res: PartnerAction[] | null = [];
            if (typeId === PartnerActionType.Clients) res = await GetClientPartnerActions();
            else if (typeId === PartnerActionType.Contacts) res = await GetContactPartnerActions();
            else if (typeId === PartnerActionType.Candidates) res = await GetCandidatePartnerActions();
            else if (typeId === PartnerActionType.Opportunities) res = await GetOpportunityPartnerActions();
            else if (typeId === PartnerActionType.Placements) res = await GetPlacementPartnerActions();
            else if (typeId === PartnerActionType.PlacementsApproved) res = await GetApprovedPlacementPartnerActions();
            else if (typeId === PartnerActionType.PlacementsWorkflow) res = await GetPlacementWorkflowPartnerActions();
            else if (typeId === PartnerActionType.PlacementsWorkflowApproved) res = await GetApprovedPlacementWorkflowPartnerActions();
            else if (typeId === PartnerActionType.Jobs) res = await GetJobPartnerActions();
            else if (isCandidateWorkflow) res = await GetCandidateWorkflowPartnerActions();

            if (res && res.length > 0 && typeId) {
                let actions: MenuOptionDefinition[] = [];
                let interview: MenuOptionDefinition[] = [];
                let refCheck: MenuOptionDefinition[] = [];

                for (let i = 0; i < res.length; i++) {
                    const action = res[i];
                    let urlAction = action.url;
                    if (data.clientId) urlAction = urlAction.replaceAll("{ClientID}", data.clientId.toString());
                    if (data.contactId) urlAction = urlAction.replaceAll("{ContactID}", data.contactId.toString());
                    if (data.candidateId) urlAction = urlAction.replaceAll("{CandidateID}", data.candidateId.toString());
                    if (data.opportunityId) urlAction = urlAction.replaceAll("{OpportunityID}", data.opportunityId.toString());
                    if (data.jobId) urlAction = urlAction.replaceAll("{JobID}", data.jobId.toString());
                    if (data.placementId) urlAction = urlAction.replaceAll("{PlacementID}", data.placementId.toString());
                    if (me && me.userID) urlAction = urlAction.replaceAll("{UserID}", me.userID.toString());

                    let a: MenuOptionDefinition | null = null;

                    if (action.urlTypeId === PartnerActionUrlType.NewWindow) {
                        a = {
                            label: action.name,
                            type: 'external-link',
                            href: urlAction,
                        };
                    }
                    else if (action.urlTypeId === PartnerActionUrlType.Popup) {
                        a = {
                            label: action.name,
                            type: 'action',
                            action: () => setPopupData(action.name, urlAction),
                        }
                    }

                    if (a &&!isCandidateWorkflow) actions.push(a);
                    else if (a && action.workflowTypeId === PartnerActionWorkflowType.Interviews) interview.push(a);
                    else if (a && action.workflowTypeId === PartnerActionWorkflowType.ReferenceChecks) refCheck.push(a);
                    else if (a) actions.push(a);
                }

                setPartnerActions(actions);
                setInterviewActions(interview);
                setRefCheckActions(refCheck);
            }

        };
        typeId !== null && getPartnerActions();
    }, [data.candidateId, data.clientId, data.contactId, data.jobId, data.opportunityId, data.placementId, setPopupData, typeId]);

    return { partnerActions, interviewActions, refCheckActions, popupTitle, popupUrl };
}