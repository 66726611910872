import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import DivisionPicker from "components/Pickers/DivisionPicker";
import UserPicker from "components/Pickers/UserPicker";
import TagsSearchFilter from "components/Tags/TagsSearchFilter";
import PageContentLayout from "layouts/PageContentLayout";
import PageLayout from "layouts/PageLayout";
import { TagFilter } from "common/models/Configuration/Tags";
import { Division } from "common/models/Configuration/Division";
import { JobSearch } from "common/models/Search/JobSearch";
import Button from "@mui/material/Button";
import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import JobsGridComponent from "components/Grids/JobsGrid";
import Snackbar from "@mui/material/Snackbar";
import Alert from "components/Alert";
import { NameIdObj, NameIdObjString } from "common/models/GenericTypes";
import { SxProps } from "@mui/material/styles";
import { GetJobsSettings, GetJobStagesLookup } from "services/ConfigurationService";
import { useNavigate, useSearchParams } from "react-router-dom";
import { GetSavedSearchById } from "services/SavedSearchesService";
import SaveSearchDialog from "components/Dialogs/SaveSearchDialog";
import { SaveJobsSearch, UpdateJobsSavedSearch } from "services/JobsService";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import MultipleStringValuesPicker from "components/Pickers/MultipleStringValuesPicker";

const ownerSpecialOptions: NameIdObj[] = [
    { id: -1, name: 'Me' },
    { id: -2, name: 'My Team' },
    { id: 0, name: 'My Company' },
    { id: -3, name: 'Unassigned' },
];

const divisionSpecialOptions: Division[] = [
    { id: 0, automatedRejectionDelay: 0, name: 'All', shortName: 'All', active: true, country: '', countryName: '', timeZone: '', timesheetSystem: 0 }
];

const jobTypeOptions: NameIdObjString[] = [
     { id: "All", name: "All" },
     { id: "Contract", name: "Contract" },
     { id: "FixedContract", name: "Fixed Term" },
     { id: "Panel", name: "Panel" },
     { id: "Permanent", name: "Permanent" },
     { id: "TalentPool", name: "Talent Pool" },
];

const filterElementStyle: SxProps = { flex: '1 1 0' };
const defaultJobStage = {id: 999, name: 'All'} as NameIdObj;
export default function JobsSearchPage() {
    const [activeTab, setActiveTab] = useState('criteria');
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [searchData, setSearchData] = useState<JobSearch>();
    const [showGrid, setShowGrid] = useState(false);
    const [status, setStatus] = useState("All");
    const [timeRange, setTimeRange] = useState('999');
    const [stage, setStage] = useState('999');
    const [ownerId, setOwnerId] = useState(-1);
    const [jobTypes, setJobTypes] = useState(['All']);
    const [division, setDivision] = useState(0);
    const [taggedBy, setTaggedBy] = useState<'a' | 'm'>('a');
    const [taggedWithin, setTaggedWithin] = useState(999);
    const [isOnHoldActive, setIsOnHoldActive] = useState(false);
    const [isFetchingSettings, setIsFetchingSettings] = useState(false);
    const [isFetchingSavedSearch, setIsFetchingSavedSearch] = useState(false);
    const [isGridLoading, setIsGridLoading] = useState(false);
    const [showSaveSearchDialog, setShowSaveSearchDialog] = useState(false);
    const [savedSearchName, setSavedSearchName] = useState('');
    const [shouldUpdateSavedSearch, setShouldUpdateSavedSearch] = useState(false);
    const [loadedSavedSearchName, setLoadedSavedSearchName] = useState('');
    const [loadedSavedSearchFrequencyId, setLoadedSavedSearchFrequencyId] = useState(0);
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    
    const [isIncludeTag, setIsIncludeTag] = useState(true);
    const [includeTags, setIncludeTags] = useState<TagFilter[]>([]);
    const [excludeTags, setExcludeTags] = useState<TagFilter[]>([]);
    const [availableTags_All, setAvailableTags_All] = useState<TagFilter[]>([]);
    const [availableTags_Me, setAvailableTags_Me] = useState<TagFilter[]>([]);
    const [jobStages, setJobStages] = useState<NameIdObj[]>([]);

    const ssid = useMemo(() => {
        const v = searchParams.get('ssid');
        if (v) return +v;
        return null;
    }, [searchParams]);

    useEffect(() => {
        const getDefaultSettings = async () => {
            setIsFetchingSettings(true);
            const res = await GetJobsSettings(setErrorMessage);
            if (res) {
                const onHoldSetting = res.find(v => v.type === 'OnHold');
                if (onHoldSetting) setIsOnHoldActive(onHoldSetting.value === 'True');
            }

            let jobStages: NameIdObj[] | null = [];
            jobStages = await GetJobStagesLookup();
            if (jobStages) {
              jobStages.unshift(defaultJobStage);
              setJobStages(jobStages);
            }
            setIsFetchingSettings(false);
        };
        !ssid && getDefaultSettings();
    }, [ssid]);


    
    useEffect(() => {
        const getSavedSearch = async () => {
            if (ssid) {
                setIsFetchingSavedSearch(true);
                const res = await GetSavedSearchById(ssid, setErrorMessage);
                if (res && res.json) {
                    setSavedSearchName(res.name);
                    const t1 = JSON.parse(res.json);
                    if (t1.status) setStatus(t1.status);
                    if (t1.TimeRange) setTimeRange(t1.TimeRange);
                    if (t1.owner) {
                        if (t1.owner === 'All') setOwnerId(0);
                        else if (t1.owner === 'Me') setOwnerId(-1);
                        else if (t1.owner === 'Team') setOwnerId(-2);
                        else if (t1.owner === 'Unassigned') setOwnerId(-3);
                        else setOwnerId(+t1.owner);
                    }
                    
                    if (t1.jobtype && typeof t1.jobtype === 'string') setJobTypes((t1.jobtype as string).split(';'));
                    else if (t1.jobtype && Array.isArray(t1.jobtype) ) setJobTypes(t1.jobtype);
                    
                    if (t1.division) setDivision(+t1.division);
                    if (t1.stage) setStage(t1.stage);
                    if (t1.tagsTimeRange) setTaggedWithin(+t1.tagsTimeRange);

                    if (t1.tags && t1.tags !== '' && t1.tags !== '[]') {
                        const tags = JSON.parse(t1.tags);
                        let inc: TagFilter[] = [];
                        let exc: TagFilter[] = [];
                        for (let i = 0; i < tags.length; i++) {
                            const {id, name, type} = tags[i];
                            const [tName, tTypeId] = (name as string).split('__');
                            if ((id as string).includes('_EXCLUDE')) exc.push({ source: type === 'm' ? 'm' : 'a', tag: tName, typeId: +tTypeId });
                            else inc.push({ source: type === 'm' ? 'm' : 'a', tag: tName, typeId: +tTypeId });
                        }
                        setIncludeTags(inc);
                        setExcludeTags(exc);
                    }
                    setLoadedSavedSearchName(res.name);
                    setLoadedSavedSearchFrequencyId(res.autoRunFrequency);
                }
                setIsFetchingSavedSearch(false);
            }
        };
        ssid && getSavedSearch();
    }, [ssid]);

    useEffect(() => {
        if(ssid && !isFetchingSavedSearch) {
            setShouldUpdateSavedSearch(true);
        }
    }, [ssid, isFetchingSavedSearch, includeTags, excludeTags, ownerId, status, division, taggedWithin]);

    const searchCallback = useCallback(async () => {
        let jt = [...jobTypes];
        if (jobTypes.length === 0 || (jobTypes.length > 1 && jobTypes.includes('All'))) jt = ['All'];

        let s: JobSearch = {
            status: status,
            timeRange: timeRange,
            owner: ownerId,
            type: jt,
            division: division,
            stage: stage,
            taggedTimeRange: taggedWithin
        };

        if (includeTags.length > 0) s.includeTags = includeTags;
        if (excludeTags.length > 0) s.excludeTags = excludeTags;

        if (shouldUpdateSavedSearch && ssid) {
            const res = await UpdateJobsSavedSearch(ssid, loadedSavedSearchName, loadedSavedSearchFrequencyId, s);
            if (res) setShouldUpdateSavedSearch(false);
        }

        setSearchData(s);
        setShowGrid(true);
        setActiveTab('results');
    }, [status, timeRange, ownerId, jobTypes, division, stage, taggedWithin, includeTags, excludeTags, shouldUpdateSavedSearch, ssid, loadedSavedSearchName, loadedSavedSearchFrequencyId]);

    const saveSearchCallback = useCallback(async (searchName: string, searchFreq: number) => {
        let jt = [...jobTypes];
        if (jobTypes.length === 0 || (jobTypes.length > 1 && jobTypes.includes('All'))) jt = ['All'];

        let s: JobSearch = {
            status: status,
            timeRange: timeRange,
            owner: ownerId,
            type: jt,
            division: division,
            stage: stage,
            taggedTimeRange: taggedWithin
        };
        
        if (includeTags.length > 0) s.includeTags = includeTags;
        if (excludeTags.length > 0) s.excludeTags = excludeTags;

        setIsLoading(true);
        var newSsid = await SaveJobsSearch(searchName, searchFreq, s, setErrorMessage);
        if (newSsid) {
            setShowSaveSearchDialog(false);
            navigate(`/jobs?ssid=${newSsid.value}`);
        }
        setIsLoading(false);
    }, [division, excludeTags, includeTags, jobTypes, navigate, ownerId, stage, status, taggedWithin, timeRange]);

    const clearCallback = useCallback(() => {
        setOwnerId(-1);
        setStatus('All');
        setJobTypes(['All']);
        setTimeRange('999');
        setDivision(0);
        setStage('999');
        setIncludeTags([]);
        setExcludeTags([]);
        setShowGrid(false);
    }, []);

    useEffect(() => {
        if (!ssid) {
            setSavedSearchName('');
            clearCallback();
        }
    }, [clearCallback, ssid]);

    const summaryBar = useMemo(() => {
        const clearAction = <Button variant="contained" color="error" onClick={clearCallback} sx={{ mr: '5px' }}>Clear</Button>;
        const saveAction = <Button variant="contained" onClick={() => setShowSaveSearchDialog(true)} sx={{ mr: '5px' }}>Save</Button>;
        const searchAction = <Button variant="contained" color="success" onClick={searchCallback}>Search</Button>;
        const actions = showGrid && !Boolean(ssid) ? <>{clearAction}{saveAction}{searchAction}</> : <>{clearAction}{searchAction}</>;

        const title = Boolean(savedSearchName) ? `Jobs > Search > ${savedSearchName}` : 'Jobs > Search';
        return <TitleAndActionSummaryBar title={title} browserTabTitle="Search > Jobs" action={actions} />
    }, [clearCallback, savedSearchName, searchCallback, showGrid, ssid]);

    const handleRemoveCallback = useCallback((data: TagFilter) => {
        const includeIndex = includeTags.findIndex(t => t.typeId === data.typeId && t.tag === data.tag);

        if (includeIndex !== -1) {
            setIncludeTags(prev => {
                let tmp = [...prev];
                const index = tmp.findIndex(t => t.typeId === data.typeId && t.tag === data.tag);
                if (index !== -1) tmp.splice(index, 1);
                return tmp;
            });
        }
        else {
            setExcludeTags(prev => {
                let tmp = [...prev];
                const index = tmp.findIndex(t => t.typeId === data.typeId && t.tag === data.tag);
                if (index !== -1) tmp.splice(index, 1);
                return tmp;
            });
        }

    }, [includeTags]);

    const handleAddCallback = useCallback((typeId: number, tag: string) => {
        const selectedTag = (taggedBy === 'a' ? availableTags_All : availableTags_Me).find(t => t.typeId === typeId && t.tag === tag);
        if (isIncludeTag) {
            setIncludeTags(prev => {
                const assignedTagIndex = prev.findIndex(t => t.typeId === typeId && t.tag === tag);
                if (assignedTagIndex === -1 && selectedTag) {
                    return [...prev, {...selectedTag, source: taggedBy}]
                }
                return prev;
            });
        }
        else {
            setExcludeTags(prev => {
                const assignedTagIndex = prev.findIndex(t => t.typeId === typeId && t.tag === tag);
                if (assignedTagIndex === -1 && selectedTag) {
                    return [...prev, {...selectedTag, source: taggedBy}]
                }
                return prev;
            });

        }
    }, [isIncludeTag, availableTags_All, availableTags_Me, taggedBy]);

    useEffect(() => {
        const item = document.querySelector<HTMLElement>('#job-search-collapse-root>.MuiCollapse-wrapper>.MuiCollapse-wrapperInner');
        if (item) {
            item.style.display = 'flex';
            item.style.flexDirection = 'column';
        }
    }, []);

    useEffect(() => setIsLoading(isFetchingSavedSearch || isGridLoading || isFetchingSettings), [isFetchingSavedSearch, isFetchingSettings, isGridLoading]);

    const handleTabChange = useCallback((e: React.SyntheticEvent, newValue: string) => {
        setActiveTab(newValue);
    }, []);

    const handleMultiJobTypeChange = useCallback((values: NameIdObjString[]) => {
        const ids = values.map(v => v.id);
        setJobTypes(ids);
    }, []);
    
    return (
        <PageLayout SummaryBar={summaryBar} paddingTop={0}>
            <Snackbar open={successMessage !== ''} autoHideDuration={3000} onClose={() => setSuccessMessage('')}>
                <Alert onClose={() => setSuccessMessage('')}>{ successMessage }</Alert>
            </Snackbar>
            <Snackbar open={errorMessage !== ''} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <SaveSearchDialog open={showSaveSearchDialog} closeHandler={() => setShowSaveSearchDialog(false)} saveHandler={saveSearchCallback} disableFrequencyField />
            <Tabs value={activeTab} onChange={handleTabChange}>
                <Tab value="criteria" label="Criteria" />
                <Tab value="results" label="Results"/>
            </Tabs>
            <PageContentLayout showLoading={isLoading}>
                <Stack spacing={2} paddingTop={2} display={activeTab === 'criteria' ? undefined : 'none'}>
                    <Box display="flex" gap={1}>
                        <UserPicker label="Consultant" userId={ownerId} onSelect={u => u && setOwnerId(u.id)} appendToStart={ownerSpecialOptions} sx={filterElementStyle} />
                        <TextField select label="Status" value={status} onChange={ ({target}) => setStatus(target.value) } sx={filterElementStyle}>
                            <MenuItem value="Open">Open</MenuItem>
                            { isOnHoldActive && <MenuItem value="Hold">Hold</MenuItem>}
                            <MenuItem value="Closed">Closed</MenuItem>
                            <MenuItem value="All">All</MenuItem>
                        </TextField>
                        <MultipleStringValuesPicker label="Type" options={jobTypeOptions} value={jobTypes} onSelect={handleMultiJobTypeChange} sx={filterElementStyle} />
                    </Box>
                    <Box display="flex" gap={1}>
                        <TextField select label="Time Range" value={timeRange} onChange={ ({target}) => setTimeRange(target.value) } sx={filterElementStyle}>
                            <MenuItem value="999">All</MenuItem>
                            <MenuItem value="1">1 Month</MenuItem>
                            <MenuItem value="3">3 Months</MenuItem>
                            <MenuItem value="6">6 Months</MenuItem>
                            <MenuItem value="12">12 Months</MenuItem>
                        </TextField>
                        <DivisionPicker divisionId={division} onSelectCallback={d => d !== null && setDivision(d)} appendToStart={divisionSpecialOptions} sx={filterElementStyle} />
                        
                        
                        <TextField select label="Stage" value={stage} onChange={ ({target}) => setStage(target.value) } sx={filterElementStyle}>
                        {jobStages.map((a,index) => {
                            return <MenuItem key={index} value={a.id}>{a.name}</MenuItem>
                          })}
                        </TextField>
                    </Box>
                    <TagsSearchFilter
                        entityId={4}
                        includeTags={includeTags}
                        excludeTags={excludeTags}
                        tagSource={taggedBy}
                        addHandler={handleAddCallback}
                        removeHandler={handleRemoveCallback}
                        setAvailableTagsHandler={taggedBy === 'a' ? setAvailableTags_All : setAvailableTags_Me}
                        loadingHandler={setIsLoading}
                        errorHandler={setErrorMessage}
                    >
                        <Box>
                            <Box>
                                Exclude
                                <Switch checked={isIncludeTag} onChange={({target}) => setIsIncludeTag(target.checked)} />
                                Include
                            </Box>
                            <TextField select label="Tagged By" value={taggedBy} onChange={ ({target}) => setTaggedBy(target.value === 'm' ? 'm' : 'a') }>
                                <MenuItem value="m">Me</MenuItem>
                                <MenuItem value="a">Everyone</MenuItem>
                            </TextField>
                            <TextField select label="Tagged Within" value={taggedWithin.toString()} onChange={ ({target}) => setTaggedWithin(+target.value) }>
                                <MenuItem value="999">Anytime</MenuItem>
                                <MenuItem value="1">1 Months</MenuItem>
                                <MenuItem value="2">2 Months</MenuItem>
                                <MenuItem value="3">3 Months</MenuItem>
                                <MenuItem value="6">6 Months</MenuItem>
                                <MenuItem value="12">1 Year</MenuItem>
                                <MenuItem value="24">2 Years</MenuItem>
                                <MenuItem value="36">3 Years</MenuItem>
                            </TextField>
                        </Box>
                    </TagsSearchFilter>
                </Stack>
                <Box display={activeTab === 'results' ? 'flex' : 'none'} flexGrow={1} flexDirection="column">
                    <JobsGridComponent gridName="job-search" jobState={0} source="search" searchData={searchData} loadingHandler={setIsGridLoading} errorHandler={setErrorMessage} />
                </Box>
            </PageContentLayout>
        </PageLayout>
    );
}