import { PanelModel } from "@syncfusion/ej2-layouts/src/dashboard-layout/dashboard-layout-model";
import { ScreenLayoutSettings } from "common/models/ScreenLayouts/ScreenLayouts";
import { MeetingRecordDashboardElementDefinition, MeetingRecordDashboardElementType } from "common/models/Dashboard/EditLayout";

export const DefaultMeetingRecordDashboardElements: MeetingRecordDashboardElementDefinition[] = [
    { id: "MeetingDistributionMode_Default", row: 0, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'MeetingDistributionMode' },
    { id: "MeetingOrganizer_Default", row: 1, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'MeetingOrganizer' },
    { id: "MeetingType_Default", row: 2, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'MeetingType' },
    { id: "MeetingDate_Default", row: 3, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'MeetingDate' },
    { id: "MeetingDateUser_Default", row: 4, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'MeetingDateUser' },
    { id: "MeetingDuration_Default", row: 5, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'MeetingDuration' },
    { id: "MeetingLocationType_Default", row: 6, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'MeetingLocationType' },
    { id: "MeetingLocation_Default", row: 7, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'MeetingLocation' },
    
    { id: "MeetingNotes_Default", row: 0, col: 1, sizeX: 2, sizeY: 6, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: null, type: 'MeetingNotes' },
];

export const GetMinSizeByElementType = (type: MeetingRecordDashboardElementType) => {
    switch (type) {
        default: return [1, 1];
    }
};

export const GetElementTypeById = (id: string): MeetingRecordDashboardElementType => {
    if ( id && id.startsWith('Divider_')) return 'Divider';
    if ( id && id.startsWith('Spacer_')) return 'Spacer';
    if (id && id.startsWith('MeetingDistributionMode_')) return 'MeetingDistributionMode';
    if (id && id.startsWith('MeetingOrganizer_')) return 'MeetingOrganizer';
    if (id && id.startsWith('MeetingType_')) return 'MeetingType';
    if (id && id.startsWith('MeetingDate_')) return 'MeetingDate';
    if (id && id.startsWith('MeetingDateUser_')) return 'MeetingDateUser';
    if (id && id.startsWith('MeetingDuration_')) return 'MeetingDuration';
    if (id && id.startsWith('MeetingLocationType_')) return 'MeetingLocationType';
    if (id && id.startsWith('MeetingLocation_')) return 'MeetingLocation';
    if (id && id.startsWith('MeetingNotes_')) return 'MeetingNotes';
    return '';
};

export const GetPanelDefinitionsFromPanelModels = (panels: PanelModel[]) => {
    let elements: MeetingRecordDashboardElementDefinition[] = [];
    panels.forEach((p, i) => {
        let type = GetElementTypeById(p.id ?? '');
        elements.push({
            id: p.id ?? i.toString(),
            sizeX: p.sizeX ?? 1,
            sizeY: p.sizeY ?? 1,
            col: p.col ?? 0,
            row: p.row ?? 0,
            minSizeX: p.minSizeX ?? 1,
            minSizeY: p.minSizeY ?? 1,
            maxSizeX: p.maxSizeX ?? 1,
            maxSizeY: p.maxSizeY ?? null,
            type: type
        });
    });
    return elements ;
};

export const MeetingScreenLayoutSettings: ScreenLayoutSettings = {
    unitWidth: 600,
    unitHeight: 40,
    gapX: 10,
    gapY: 0,
    columns: 3,
    mediaQueryMaxWidth: '1000px'
};