import { GridCellEditStopParams, GridCellEditStopReasons, GridColDef, GridRenderCellParams, GridRenderEditCellParams, GridRowSelectionModel, GridColumnVisibilityModel, GridValueGetter, useGridApiRef } from "@mui/x-data-grid-premium";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Invoice } from "../../common/models/Invoices/Invoice";
import { PreviewEntityType } from "../../common/models/Previews/Previews";
import { GetAllInvoices, GetDraftInvoices, GetAwaitingApprovalInvoices } from "../../services/InvoiceService";
import { GetClientInvoices } from "../../services/ClientsService";
import { GetJobInvoices } from "../../services/JobsService";
import { GetPlacementInvoices } from "../../services/PlacementsService";
import GridWithStateWrapper from "../GridWidthStateWrapper";
import PreviewLoaderComponent from "../Previews/PreviewLoader";
import { MenuOptionDefinition } from "../../common/models/MenuDefinition";
import ActionMenu from "../Menus/ActionMenu";
import { companyHasSinglePermission, userHasSinglePermission } from "../../util/PermissionsUtils";
import { Permission } from "../../common/models/Permissions";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";

interface Props {
    source: 'all' | 'drafts' | 'awaiting-approval' |'client-record' | 'job-record' | 'placement-record',
    sourceId?: number,
    gridName: string,
    setRowCountHandler?: (count: number) => void,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
    successHandler?: (message: string) => void,
    refreshControl?: boolean,
}

const linkStyle: React.CSSProperties = { color: 'inherit', textDecoration: 'underline' };
const entityTypeId = 2;

export default function InvoicesGridComponent({ source, sourceId, loadingHandler, errorHandler, successHandler, gridName, refreshControl }: Props) {
    const [rows, setRows] = useState<Invoice[]>([]);
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    const [showTagsPrevewNoDelay, setShowTagsPreviewNoDelay] = useState(false);
    const [previewType, setPreviewType] = useState<PreviewEntityType | ''>('');
    const [previewRecordId, setPreviewRecordId] = useState(0);
    const [isPreviewTags, setIsPreviewTags] = useState(false);
    const [previewRecordName, setPreviewRecordName] = useState('');
    const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);

    const [successMessage, setSuccessMessage] = useState('');
    const apiRef = useGridApiRef();

    const canExport = useMemo(() => companyHasSinglePermission(Permission.MiscExport) && userHasSinglePermission(Permission.MiscExport), []);

    const getData = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        let res: Invoice[] | null = [];

        if (source === 'all') res = await GetAllInvoices(errorHandler);
        if (source === 'drafts') res = await GetDraftInvoices(errorHandler);
        if (source === 'awaiting-approval') res = await GetAwaitingApprovalInvoices(errorHandler);
        if (source === 'client-record' && sourceId) res = await GetClientInvoices(sourceId, errorHandler);
        if (source === 'job-record' && sourceId) res = await GetJobInvoices(sourceId, errorHandler);
        if (source === 'placement-record' && sourceId) res = await GetPlacementInvoices(sourceId, errorHandler);
        
        if (res) {
            setRows(res);
        }
        loadingHandler && loadingHandler(false);
        
    }, [source, sourceId, loadingHandler, errorHandler]);

    useEffect(() => {
        getData();
    }, [getData]);

    useEffect(() => {
        if (refreshControl) { }
        getData();
    }, [getData, refreshControl]);

    const columns = useMemo<GridColDef[]>(() => {
        const handlePreviewHover = (type: PreviewEntityType | '', id: number, isTags: boolean = false, recordName: string = '', noDelay: boolean = false) => {
            setPreviewType(type);
            setPreviewRecordId(id);
            setIsPreviewTags(isTags);
            setPreviewRecordName(recordName);
            setIsPreviewOpen(true);
            setShowTagsPreviewNoDelay(noDelay);
        };

        const handlePreviewClose = () => {
            setIsPreviewOpen(false);
            setShowTagsPreviewNoDelay(false);
        };

        const dateValueGetter = (params: GridRenderCellParams) => {
            if (params.value) {
                const m = moment(params.value);
                if (m.isValid() && m.get('year') > 1) {
                    return m.toDate();
                }
            }
        };

        const dateRenderer = (params: GridRenderCellParams) => {
            if (params.value) {
                return moment(params.value).format('DD MMM YYYY');
            }
            return 'Never';
        };

        const linkToInvoiceRenderer = (params: GridRenderCellParams) => {
            if (params.id) {
                return (
                    <Link
                        to={`/invoices/${params.id}`}
                        style={linkStyle}
                    >{params.value}</Link>
                );
            }
            return params.value;
        };

        const linkToClientRenderer = (params: GridRenderCellParams) => {
            const clientId = params.row.clientID;
            if (clientId) {
                return (
                    <Link
                        to={`/clients/${clientId}`}
                        style={linkStyle}
                        onMouseEnter={() => handlePreviewHover('client', clientId)}
                        onMouseLeave={handlePreviewClose}
                    >{params.value}</Link>
                );
            }
            return params.value;
        };

        const linkToContactRenderer = (params: GridRenderCellParams) => {
            const contactId = params.row.billingContactID;
            if (contactId) {
                return (
                    <Link
                        to={`/contacts/${contactId}`}
                        style={linkStyle}
                        onMouseEnter={() => handlePreviewHover('contact', contactId)}
                        onMouseLeave={handlePreviewClose}
                    >{params.value}</Link>
                );
            }
            return params.value;
        };
   
        return [
            { headerName: 'ID', field: 'id', width: 75, renderCell: linkToInvoiceRenderer },
            { headerName: 'Client Reference', field: 'clientReference', headerAlign: 'center', align: 'center', width: 150 },
            { headerName: 'Client', field: 'clientName', headerAlign: 'center', align: 'center', width: 200, renderCell: linkToClientRenderer },
            { headerName: 'Billing Contact', field: 'billingContactName', headerAlign: 'center', align: 'center', width: 200, renderCell: linkToContactRenderer },
            { headerName: 'Date', field: 'date', headerAlign: 'center', align: 'center', width: 180, type: 'date', valueGetter: dateValueGetter, renderCell: dateRenderer },
            { headerName: 'Due Date', field: 'dueDate', headerAlign: 'center', align: 'center', width: 180, type: 'date', valueGetter: dateValueGetter, renderCell: dateRenderer },           
            { headerName: 'Sub Total', field: 'subTotal', headerAlign: 'center', align: 'center', width: 150 },
            { headerName: 'Created By', field: 'createdByName', headerAlign: 'center', align: 'center', width: 200 },
            { headerName: 'Status', field: 'statusName', headerAlign: 'center', align: 'center', width: 180, hide: source == 'drafts' || source == 'awaiting-approval' },
        ];
    }, [source]);

    const exportToExcel = useCallback(() => {
        const api = apiRef.current;
        if (api) api.exportDataAsExcel();
    }, [apiRef]);

    const actionMenuDefinitions = useMemo<MenuOptionDefinition[]>(() => {
        const noRecordSelected = selectionModel.length === 0;
        const invoiceIdsQuery = encodeURIComponent(selectionModel.join(','));

        let actions: MenuOptionDefinition[] = [
            { label: 'Export', type: 'action', action: exportToExcel, allow: () => canExport }
        ];

        return actions;
    }, [selectionModel, canExport, exportToExcel]);
    
    const gridActions = useMemo(() => {
        return <ActionMenu color="secondary" ml={2} placement="top-start" label="List Actions" definition={actionMenuDefinitions} />;
    }, [actionMenuDefinitions]);

    return (
        <>
            <PreviewLoaderComponent
                open={isPreviewOpen}
                entityType={previewType}
                recordId={previewRecordId}
                isTagsPreview={isPreviewTags}
                showDelayMs={showTagsPrevewNoDelay ? 0 : undefined}
                titleOverride={previewRecordName}
            />           
            <Snackbar open={successMessage !== ''} autoHideDuration={3000} onClose={() => setSuccessMessage('')}>
                <Alert onClose={() => setSuccessMessage('')}>{ successMessage }</Alert>
            </Snackbar>
            {Boolean(gridActions) &&
                <Box pb="10px" ml="auto">
                    {gridActions}
                </Box>
            }
            <GridWithStateWrapper
                gridName={gridName}
                rows={rows}
                columns={columns}
                apiRef={apiRef}
                density="compact"
                checkboxSelection
                disableRowSelectionOnClick
                rowSelectionModel={selectionModel}
                onRowSelectionModelChange={ sm => setSelectionModel(sm) }
                pagination
                pageSizeOptions={[100,250,500,1000]}
            />
        </>
    );
}