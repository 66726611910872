import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import UserPicker from "components/Pickers/UserPicker";
import TextField from "@mui/material/TextField";
import { SxProps, Theme } from "@mui/material/styles";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { UpdateChangeRequest } from "services/ChangeRequestsService";
import { ChangeRequest, ChangeRequestParams } from "common/models/ChangeRequest";
import MenuItem from "@mui/material/MenuItem";
import { CustomField, CustomFieldPredefinedValue } from "common/models/Configuration/CustomFields";
import { GetCustomFieldsByEntity_OnlyActive, GetPredefinedValues } from "services/CustomFieldsService";
import ClientPicker from "components/Pickers/ClientPicker";
import ContactPicker from "components/Pickers/ContactPicker";
import { ChangeRequestDecisionEnum, ChangeRequestTypeEnum } from "util/Definitions/Configuration/Placements";
import moment from "moment";
import { RegexIsPositiveNumberWith2Decimals } from "util/RegExUtils";
import { IsValidCustomFieldValue } from "util/Definitions/ScreenLayouts/CustomFields";
import { PREVIEW_EXTERNAL_LINK_RENDERER } from "util/Definitions/Constants/Previews";
import RWTextFieldComponent from "components/RWTextFieldComponent";
import ConfirmationDialog from "components/Dialogs/Generic/ConfirmationDialog";

interface Props {
    open: boolean,
    changeRequest: ChangeRequest,
    ratesCalculatorLink?: string,
    closeHandler: () => void,
    loadingHandler?: (isLoading: boolean) => void,
    successHandler?: (message: string) => void,
    errorHandler?: (message: string) => void
}

const leftStyle: SxProps<Theme> = { flex: '1 1 0', pr: '5px' };
const rightStyle: SxProps<Theme> = { flex: '1 1 0', pl: '5px' };

const defaultUdfs: Partial<ChangeRequestParams> = {
    endDate: '',
    sUDF1: '',
    sUDF2: '',
    sUDF3: '',
    sUDF4: '',
    sUDF5: '',
    sUDF6: '',
    sUDF7: '',
    sUDF8: '',
    sUDF9: '',
    sUDF10: '',
    sUDF21: '',
    sUDF22: '',
    sUDF23: '',
    sUDF24: '',
    sUDF25: '',
    sUDF26: '',
    sUDF27: '',
    sUDF28: '',
    sUDF29: '',
    sUDF30: '',
    sUDF31: '',
    sUDF32: '',
    sUDF33: '',
    sUDF34: '',
    sUDF35: '',
    sUDF36: '',
    sUDF37: '',
    sUDF38: '',
    sUDF39: '',
    sUDF40: '',
};

const udfNameMap: Record<string, keyof ChangeRequestParams> = {
    'Custom Field 1': 'sUDF1',
    'Custom Field 2': 'sUDF2',
    'Custom Field 3': 'sUDF3',
    'Custom Field 4': 'sUDF4',
    'Custom Field 5': 'sUDF5',
    'Custom Field 6': 'sUDF6',
    'Custom Field 7': 'sUDF7',
    'Custom Field 8': 'sUDF8',
    'Custom Field 9': 'sUDF9',
    'Custom Field 10': 'sUDF10',
    'Custom Field 11': 'lUDF11',
    'Custom Field 12': 'lUDF12',
    'Custom Field 13': 'lUDF13',
    'Custom Field 14': 'lUDF14',
    'Custom Field 15': 'lUDF15',
    'Custom Field 16': 'dtUDF16',
    'Custom Field 17': 'dtUDF17',
    'Custom Field 18': 'dtUDF18',
    'Custom Field 19': 'dtUDF19',
    'Custom Field 20': 'dtUDF20',
    'Custom Field 21': 'sUDF21',
    'Custom Field 22': 'sUDF22',
    'Custom Field 23': 'sUDF23',
    'Custom Field 24': 'sUDF24',
    'Custom Field 25': 'sUDF25',
    'Custom Field 26': 'sUDF26',
    'Custom Field 27': 'sUDF27',
    'Custom Field 28': 'sUDF28',
    'Custom Field 29': 'sUDF29',
    'Custom Field 30': 'sUDF30',
    'Custom Field 31': 'sUDF31',
    'Custom Field 32': 'sUDF32',
    'Custom Field 33': 'sUDF33',
    'Custom Field 34': 'sUDF34',
    'Custom Field 35': 'sUDF35',
    'Custom Field 36': 'sUDF36',
    'Custom Field 37': 'sUDF37',
    'Custom Field 38': 'sUDF38',
    'Custom Field 39': 'sUDF39',
    'Custom Field 40': 'sUDF40',
    'Custom Field 41': 'lUDF41',
    'Custom Field 42': 'lUDF42',
    'Custom Field 43': 'lUDF43',
    'Custom Field 44': 'lUDF44',
    'Custom Field 45': 'lUDF45',
    'Custom Field 46': 'lUDF46',
    'Custom Field 47': 'lUDF47',
    'Custom Field 48': 'lUDF48',
    'Custom Field 49': 'lUDF49',
    'Custom Field 50': 'lUDF50',
    'Custom Field 51': 'dtUDF51',
    'Custom Field 52': 'dtUDF52',
    'Custom Field 53': 'dtUDF53',
    'Custom Field 54': 'dtUDF54',
    'Custom Field 55': 'dtUDF55',
    'Custom Field 56': 'dtUDF56',
    'Custom Field 57': 'dtUDF57',
    'Custom Field 58': 'dtUDF58',
    'Custom Field 59': 'dtUDF59',
    'Custom Field 60': 'dtUDF60',
};

type ValuesMap = Partial<Record<keyof ChangeRequestParams, CustomFieldPredefinedValue[]>>;

const timePeriodOptions = [
    <MenuItem key={0} value="0">Select</MenuItem>,
    <MenuItem key={1} value="1">Per Hour</MenuItem>,
    <MenuItem key={2} value="2">Per Day</MenuItem>,
    <MenuItem key={3} value="3">Per Week</MenuItem>,
    <MenuItem key={4} value="4">Per Month</MenuItem>,
    <MenuItem key={5} value="5">Per Annum</MenuItem>,
];

const noticeOptions = [
    <MenuItem key="0" value="0">None</MenuItem>,
    <MenuItem key="1" value="1">Days</MenuItem>,
    <MenuItem key="2" value="2">Weeks</MenuItem>,
    <MenuItem key="3" value="3">Months</MenuItem>,
];

const validationErrorMessage = (validationResult: string) => {
    if (validationResult === 'ok') return '';
    if (validationResult === 'required') return 'Required';
    return 'Not Valid';
};

const daysPerWeekValidator = (value: string) => {
    return value === '' || (RegexIsPositiveNumberWith2Decimals(value) && +value <= 7);
};

export default function EditChangePaymentTypeDialog({ open, changeRequest, ratesCalculatorLink = '', closeHandler: closeHandlerProp, loadingHandler, successHandler, errorHandler }: Props) {
    const [showWarning, setShowWarning] = useState(false);
    const [showValidation, setShowValidation] = useState(false);
    const [effectiveDate, setEffectiveDate] = useState<moment.Moment | null>(null);
    const [endDate, setEndDate] = useState<moment.Moment | null>(null);
    const [paymentType, setPaymentType] = useState(1);
    const [paymentCompany, setPaymentCompany] = useState(0);
    const [paymentContact, setPaymentContact] = useState(0);
    const [chargeRate, setChargeRate] = useState('');
    const [chargeRateUnits, setChargeRateUnits] = useState(0);
    const [payRate, setPayRate] = useState('');
    const [payRateUnits, setPayRateUnits] = useState(0);
    const [onCosts, setOnCosts] = useState('');
    const [onCostsUnits, setOnCostsUnits] = useState(0);
    const [hoursPerDay, setHoursPerDay] = useState('8');
    const [daysPerWeek, setDaysPerWeek] = useState(5);
    const [noticePeriod, setNoticePeriod] = useState('');
    const [noticePeriodUnitsId, setNoticePeriodUnitsId] = useState(0);
    const [consultant1Id, setConsultant1Id] = useState(0);
    const [consultant1Percent, setConsultant1Percent] = useState('');
    const [consultant2Id, setConsultant2Id] = useState(0);
    const [consultant2Percent, setConsultant2Percent] = useState('');
    const [consultant3Id, setConsultant3Id] = useState(0);
    const [consultant3Percent, setConsultant3Percent] = useState('');
    const [consultant4Id, setConsultant4Id] = useState(0);
    const [consultant4Percent, setConsultant4Percent] = useState('');
    const [consultant5Id, setConsultant5Id] = useState(0);
    const [consultant5Percent, setConsultant5Percent] = useState('');
    const [udfState, setUdfState] = useState<Partial<ChangeRequestParams>>(defaultUdfs);
    const [activeFields, setActiveFields] = useState<CustomField[]>([]);
    const [valuesMap, setValuesMap] = useState<ValuesMap>({});

    const p = useMemo<Partial<ChangeRequestParams> | null>(() => {
        if (changeRequest.json) return JSON.parse(changeRequest.json) as Partial<ChangeRequestParams>;
        return null;
    }, [changeRequest.json]);

    const warningText = useMemo(() => {
        if (changeRequest.decisionID !== ChangeRequestDecisionEnum.Pending) return 'Only pending Change Requests can be edited';
        return '';
    }, [changeRequest.decisionID]);

    const changeType = useMemo(() => {
        if (changeRequest.changeTypeID === ChangeRequestTypeEnum.PaymentType) return 'payment-type';
        if (changeRequest.changeTypeID === ChangeRequestTypeEnum.RateChange) return 'rates';
        if (changeRequest.changeTypeID === ChangeRequestTypeEnum.Extension) return 'extend';
        if (changeRequest.changeTypeID === ChangeRequestTypeEnum.RateCorrection) return 'rate-correction';
        return null;
    }, [changeRequest.changeTypeID]);

    const closeHandler = useCallback(() => {
        closeHandlerProp();
        errorHandler && errorHandler('');
    }, [closeHandlerProp, errorHandler]);

    useEffect(() => {
        if (open && Boolean(warningText)) setShowWarning(true);
    }, [open, warningText]);

    useEffect(() => {
        const getActiveFields = async () => {
            loadingHandler && loadingHandler(true);
            const res = await GetCustomFieldsByEntity_OnlyActive(5);
            if (res) {
                let values: ValuesMap = {};
                for (let i = 0; i < res.length; i++) {
                    const f = res[i];
                    if (f.usePredefinedValues) {
                        const k = udfNameMap[f.name];
                        const vals = await GetPredefinedValues(f.id);
                        if (vals) values[k] = vals;
                    }
                }
                setActiveFields(res);
                setValuesMap(values);
            }
            loadingHandler && loadingHandler(false);
        };
        getActiveFields();
    }, [loadingHandler]);
    
    useEffect(() => {
        if (open && p) {
            if (changeType === 'extend' && p.endDate) {
                const m1 = moment(p.endDate);
                if (m1.isValid()) setEndDate(m1);

                const m2 = moment(p.endDate).add(1, 'days');
                if (m2.isValid()) setEffectiveDate(m2);
            }
            
            if (p.effectiveDate) {
                const m = moment(p.effectiveDate);
                if (m.isValid()) setEffectiveDate(m);
            }
            
            setPaymentType(p.paymentTypeId ?? 0);
            setPaymentCompany(p.paymentCompanyId ?? 0);
            setPaymentContact(p.paymentContactId ?? 0);
            p.hoursPerDay !== undefined && setHoursPerDay(p.hoursPerDay.toString());
            p.daysPerWeek !== undefined && setDaysPerWeek(p.daysPerWeek);

            p.chargeRate !== undefined && setChargeRate(p.chargeRate.toString());
            p.chargeRateUnits !== undefined && setChargeRateUnits(p.chargeRateUnits);
            p.payRate !== undefined && setPayRate(p.payRate.toString());
            p.payRateUnits !== undefined && setPayRateUnits(p.payRateUnits);
            p.onCosts !== undefined && setOnCosts(p.onCosts.toString());
            p.onCostsUnits !== undefined && setOnCostsUnits(p.onCostsUnits);
            
            p.noticePeriod !== undefined && setNoticePeriod(p.noticePeriod.toString());
            p.noticePeriodUnits !== undefined && setNoticePeriodUnitsId(p.noticePeriodUnits);

            setUdfState({
                sUDF1: p.sUDF1 ?? '',
                sUDF2: p.sUDF2 ?? '',
                sUDF3: p.sUDF3 ?? '',
                sUDF4: p.sUDF4 ?? '',
                sUDF5: p.sUDF5 ?? '',
                sUDF6: p.sUDF6 ?? '',
                sUDF7: p.sUDF7 ?? '',
                sUDF8: p.sUDF8 ?? '',
                sUDF9: p.sUDF9 ?? '',
                sUDF10: p.sUDF10 ?? '',
                lUDF11: p.lUDF11 ? p.lUDF11 : 0,
                lUDF12: p.lUDF12 ? p.lUDF12 : 0,
                lUDF13: p.lUDF13 ? p.lUDF13 : 0,
                lUDF14: p.lUDF14 ? p.lUDF14 : 0,
                lUDF15: p.lUDF15 ? p.lUDF15 : 0,
                dtUDF16: p.dtUDF16,
                dtUDF17: p.dtUDF17,
                dtUDF18: p.dtUDF18,
                dtUDF19: p.dtUDF19,
                dtUDF20: p.dtUDF20,
                sUDF21: p.sUDF21 ?? '',
                sUDF22: p.sUDF22 ?? '',
                sUDF23: p.sUDF23 ?? '',
                sUDF24: p.sUDF24 ?? '',
                sUDF25: p.sUDF25 ?? '',
                sUDF26: p.sUDF26 ?? '',
                sUDF27: p.sUDF27 ?? '',
                sUDF28: p.sUDF28 ?? '',
                sUDF29: p.sUDF29 ?? '',
                sUDF30: p.sUDF30 ?? '',
                sUDF31: p.sUDF31 ?? '',
                sUDF32: p.sUDF32 ?? '',
                sUDF33: p.sUDF33 ?? '',
                sUDF34: p.sUDF34 ?? '',
                sUDF35: p.sUDF35 ?? '',
                sUDF36: p.sUDF36 ?? '',
                sUDF37: p.sUDF37 ?? '',
                sUDF38: p.sUDF38 ?? '',
                sUDF39: p.sUDF39 ?? '',
                sUDF40: p.sUDF40 ?? '',
                lUDF41: p.lUDF41 ? p.lUDF41 : 0,
                lUDF42: p.lUDF42 ? p.lUDF42 : 0,
                lUDF43: p.lUDF43 ? p.lUDF43 : 0,
                lUDF44: p.lUDF44 ? p.lUDF44 : 0,
                lUDF45: p.lUDF45 ? p.lUDF45 : 0,
                lUDF46: p.lUDF46 ? p.lUDF46 : 0,
                lUDF47: p.lUDF47 ? p.lUDF47 : 0,
                lUDF48: p.lUDF48 ? p.lUDF48 : 0,
                lUDF49: p.lUDF49 ? p.lUDF49 : 0,
                lUDF50: p.lUDF50 ? p.lUDF50 : 0,
                dtUDF51: p.dtUDF51,
                dtUDF52: p.dtUDF52,
                dtUDF53: p.dtUDF53,
                dtUDF54: p.dtUDF54,
                dtUDF55: p.dtUDF55,
                dtUDF56: p.dtUDF56,
                dtUDF57: p.dtUDF57,
                dtUDF58: p.dtUDF58,
                dtUDF59: p.dtUDF59,
                dtUDF60: p.dtUDF60,
            });

            p.consultantId1 && setConsultant1Id(p.consultantId1);
            p.consultantId2 && setConsultant2Id(p.consultantId2);
            p.consultantId3 && setConsultant3Id(p.consultantId3);
            p.consultantId4 && setConsultant4Id(p.consultantId4);
            p.consultantId5 && setConsultant5Id(p.consultantId5);

            p.consultantPercentage1 && setConsultant1Percent(p.consultantPercentage1.toString());
            p.consultantPercentage2 && setConsultant2Percent(p.consultantPercentage2.toString());
            p.consultantPercentage3 && setConsultant3Percent(p.consultantPercentage3.toString());
            p.consultantPercentage4 && setConsultant4Percent(p.consultantPercentage4.toString());
            p.consultantPercentage5 && setConsultant5Percent(p.consultantPercentage5.toString()); 
        }
    }, [open, changeType, p]);

    const submitCallback = useCallback(async () => {
        setShowValidation(true);

        if (!Boolean(hoursPerDay)) {
            errorHandler && errorHandler("Hours Per Day field is required");
            return false;
        }
        else if (+hoursPerDay <= 0) {
            errorHandler && errorHandler("Hours Per Day must be greater than 0")
            return false;
        }
        else if(+hoursPerDay > 24) {
            errorHandler && errorHandler("Hours Per Day must be less than 24");
            return false;
        }

        if (!Boolean(daysPerWeek)) {
            errorHandler && errorHandler("Days Per Week field is required");
            return false;
        }
        else if(+daysPerWeek <= 0) {
            errorHandler && errorHandler("Days Per Week must be greater than 0");
            return false;
        }
        else if(+daysPerWeek > 7) {
            errorHandler && errorHandler("Days Per Week must be less than 7");
            return false;
        }

        for (let i = 0; i < activeFields.length; i++) {
            const u = activeFields[i];
            if (!u.editable) continue;
            const k = udfNameMap[u.name];
            const value = udfState[k];

            let format: 'string' | 'number' | 'date' = 'string';
            if (u.dataType === 'Decimal') format = 'number';
            else if (u.dataType === 'DateTime') format = 'date';

            const validation = IsValidCustomFieldValue(value, format, u.mandatory);

            if (validation === "required") {
                errorHandler && errorHandler(`${u.agencyName} is required`);
                return false;
            }
            else if (validation === "invalid-number") {
                errorHandler && errorHandler(`${u.agencyName} must have a valid numeric value`);
                return false;
            }
            else if (validation === "invalid-date") {
                errorHandler && errorHandler(`${u.agencyName} must have a valid date value`);
                return false;
            }
            else if (validation === "range-date") {
                errorHandler && errorHandler(`${u.agencyName} must be a date between 01-01-1753 and 31-12-9999`);
                return false;
            }
        }

        loadingHandler && loadingHandler(true);
        const p1 = consultant1Percent.trim() === '' ? '0' : consultant1Percent;
        const p2 = consultant2Percent.trim() === '' ? '0' : consultant2Percent;
        const p3 = consultant3Percent.trim() === '' ? '0' : consultant3Percent;
        const p4 = consultant4Percent.trim() === '' ? '0' : consultant4Percent;
        const p5 = consultant5Percent.trim() === '' ? '0' : consultant5Percent;

        const efDate = effectiveDate && effectiveDate.isValid() ? effectiveDate.format('YYYY-MM-DD') : '';
        const data: Partial<ChangeRequestParams> = {
            rateId: changeRequest.rateID,
            paymentTypeId: paymentType,
            effectiveDate: efDate,
            chargeRate: Boolean(chargeRate) ? +chargeRate : 0,
            chargeRateUnits: chargeRateUnits,
            payRate: Boolean(payRate) ? +payRate : 0,
            payRateUnits: payRateUnits,
            onCosts: Boolean(onCosts) ? +onCosts : 0,
            onCostsUnits: onCostsUnits,
            hoursPerDay: Boolean(hoursPerDay) ? +hoursPerDay : 0,
            daysPerWeek: daysPerWeek,
            noticePeriod: Boolean(noticePeriod) ? +noticePeriod : 0,
            noticePeriodUnits: noticePeriodUnitsId,
            consultantId1: consultant1Id,
            consultantId2: consultant2Id,
            consultantId3: consultant3Id,
            consultantId4: consultant4Id,
            consultantId5: consultant5Id,
            consultantPercentage1: +p1,
            consultantPercentage2: +p2,
            consultantPercentage3: +p3,
            consultantPercentage4: +p4,
            consultantPercentage5: +p5,
        };

        if (paymentType === 2 && changeType === 'payment-type') {
            data.paymentCompanyId = paymentCompany;
            data.paymentContactId = paymentContact;
        }

        let res: boolean | null = null;
        if (changeType === 'extend') {
            const stringEndDate = endDate && endDate.isValid() ? endDate.format('YYYY-MM-DD') : '';
            res = await UpdateChangeRequest(changeRequest.id, changeRequest.changeTypeID, {...defaultUdfs, ...udfState, ...data, endDate: stringEndDate}, errorHandler);
        }
        else res = await UpdateChangeRequest(changeRequest.id, changeRequest.changeTypeID, {...defaultUdfs, ...udfState, ...data}, errorHandler);
        loadingHandler && loadingHandler(false);

        if (res) {
            successHandler && successHandler('Change Request Updated');
            closeHandler();
        }

    }, [loadingHandler, consultant1Percent, consultant2Percent, consultant3Percent, consultant4Percent, consultant5Percent, effectiveDate, changeRequest.rateID, changeRequest.id, changeRequest.changeTypeID, paymentType, chargeRate, chargeRateUnits, payRate, payRateUnits, onCosts, onCostsUnits, hoursPerDay, daysPerWeek, noticePeriod, noticePeriodUnitsId, consultant1Id, consultant2Id, consultant3Id, consultant4Id, consultant5Id, changeType, udfState, errorHandler, activeFields, paymentCompany, paymentContact, endDate, successHandler, closeHandler]);

    const onChargeRateChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;
        setChargeRate(val);
    }, []);

    const onPayRateChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;
        setPayRate(val);
    }, []);

    const onOnCostsChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;
        setOnCosts(val);
    }, []);

    const onHoursPerDayChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;
        setHoursPerDay(val);
    }, []);

    const onNoticePeriodChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;
        setNoticePeriod(val);
    }, []);

    const onPercent1Change = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;
        setConsultant1Percent(val);
    }, []);

    const onPercent2Change = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;
        setConsultant2Percent(val);
    }, []);

    const onPercent3Change = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;
        setConsultant3Percent(val);
    }, []);

    const onPercent4Change = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;
        setConsultant4Percent(val);
    }, []);

    const onPercent5Change = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;
        setConsultant5Percent(val);
    }, []);

    const handleNumericUdfChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;
        const k = e.target.name as keyof ChangeRequestParams;
        if (RegexIsPositiveNumberWith2Decimals(val)) {
            setUdfState(prev => ({...prev, [k]: val}));
        }
    }, []);

    const numberValidator = useCallback((value: string) => {
        return value === '' || RegexIsPositiveNumberWith2Decimals(value);
    }, []);

    const handleUdfDateChange = useCallback((m: moment.Moment | null, k: keyof ChangeRequestParams) => {
        if (m) setUdfState( prev => ({...prev, [k]: m.format('YYYY-MM-DD')}));
        else setUdfState( prev => ({...prev, [k]: '0001-01-01T00:00:00'}));
    }, []);

    const handleStringUdfChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;
        const k = e.target.name as keyof ChangeRequestParams;
        setUdfState(prev => ({...prev, [k]: val}));
    }, []);

    const renderedCustomFields = useMemo(() => {
        return activeFields.map(f => {
            if (!f.editable) return <React.Fragment key={f.id} />

            const k = udfNameMap[f.name];
            if (f.dataType === 'String') {
                const values = valuesMap[k];
                const validationResult = IsValidCustomFieldValue(udfState[k], 'string', f.mandatory);
                const isError = validationResult !== 'ok' && showValidation;
                const errorMessage = isError ? validationErrorMessage(validationResult) : '';
                if (f.usePredefinedValues && values && values.length > 0) {
                    return (
                        <TextField select key={f.id} label={f.agencyName} value={udfState[k]} name={k} onChange={handleStringUdfChange} error={isError} helperText={errorMessage} >
                            <MenuItem value="">None</MenuItem>
                            {values.map(v => <MenuItem key={v.value} value={v.value}>{v.value + (v.isDefault ? ' (Default)' : '')}</MenuItem>)}
                        </TextField>
                    );
                }
                return (
                    <TextField key={f.id} label={f.agencyName} value={udfState[k]} name={k} onChange={handleStringUdfChange} error={isError} helperText={errorMessage} />
                );
            }
            if (f.dataType === 'Decimal') {
                const validationResult = IsValidCustomFieldValue(udfState[k], 'number', f.mandatory);
                const isError = validationResult !== 'ok' && showValidation;
                const errorMessage = isError ? validationErrorMessage(validationResult) : '';
                return (
                    <TextField key={f.id} label={f.agencyName} value={udfState[k]} name={k} onChange={handleNumericUdfChange} error={isError} helperText={errorMessage} />
                );
            }
            if (f.dataType === 'DateTime') {
                const v = !Boolean(udfState[k]) || udfState[k] === '0001-01-01T00:00:00' ? null : udfState[k];
                const vm = v === null ? v : moment(v);
                const validationResult = IsValidCustomFieldValue(udfState[k], 'date', f.mandatory);
                const isError = validationResult !== 'ok' && showValidation;
                const errorMessage = isError ? validationErrorMessage(validationResult) : '';
                return (
                    <DatePicker key={f.id} label={f.agencyName} value={vm} onChange={(m: moment.Moment | null) => handleUdfDateChange(m, k)} slotProps={{ textField: { error: isError, helperText: errorMessage }, actionBar: { actions: ["clear", "today", "cancel", "accept"] } }} />
                );
            }
            return <></>;
        });
    }, [activeFields, udfState, valuesMap, showValidation, handleStringUdfChange, handleNumericUdfChange, handleUdfDateChange]);

    const dialogTitle = useMemo(() => {
        if (changeType === 'payment-type') return 'Change Payment Type';
        if (changeType === 'rates') return 'Change Rates';
        if (changeType === 'extend') return 'Extend Placement';
        if (changeType === 'rate-correction') return 'Correct Rates';
        return '';
    }, [changeType]);

    return (
        <>
            <ConfirmationDialog
                open={open && showWarning && Boolean(warningText)}
                message={warningText}
                onClose={closeHandler}
                title={dialogTitle}
                onContinue={() => setShowWarning(false)}
            />
            <Dialog open={open && !showWarning} fullWidth>
                <DialogTitle>
                    <Box display="flex" justifyContent="space-between">
                        {dialogTitle}
                        {Boolean(ratesCalculatorLink) && <Box component="span" color="primary.main">{PREVIEW_EXTERNAL_LINK_RENDERER(ratesCalculatorLink, 'Rates Calculator', '_blank', 'none')}</Box>}
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Stack spacing={2} mt="10px">
                        <DatePicker label="Effective Date" value={effectiveDate} onChange={m => setEffectiveDate(m)} disabled={changeType === 'extend' || changeType === 'rate-correction'} />
                        {changeType === 'payment-type' &&
                            <TextField select value={paymentType.toString()} label="Payment Type" onChange={ ({target}) => setPaymentType(+target.value) } InputProps={{ readOnly: p ? p.paymentTypeId === 1 : true }} >
                                <MenuItem value="0">None</MenuItem>
                                <MenuItem value="1">Employee</MenuItem>
                                <MenuItem value="2">Sub Contractor</MenuItem>
                            </TextField>
                        }
                        {changeType === 'extend' && <DatePicker label="End Date" value={endDate} onChange={m => setEndDate(m)} slotProps={{actionBar: { actions: ["clear", "today", "cancel", "accept"] }}} />}
                        {paymentType === 2 && changeType === 'payment-type' && <ClientPicker label="Pay Company" value={paymentCompany} onSelectCallback={c => setPaymentCompany(c ? c.id : 0)} /> }
                        {paymentType === 2 && changeType === 'payment-type' && <ContactPicker label="Pay Contact" value={paymentContact} onSelectCallback={c => setPaymentContact(c ? c.id : 0)} /> }
                        <Box display="flex">
                            <RWTextFieldComponent value={chargeRate} label="Charge Rate" sxOptions={leftStyle} onChange={ onChargeRateChange } validator={ numberValidator } />
                            <TextField select value={chargeRateUnits.toString()} label="Charge Rate Units" sx={rightStyle} onChange={ ({target}) => setChargeRateUnits(+target.value) } >
                                {timePeriodOptions}
                            </TextField>
                        </Box>
                        <Box display="flex">
                            <RWTextFieldComponent value={payRate} label="Pay Rate" sxOptions={leftStyle} onChange={ onPayRateChange } validator={ numberValidator } />
                            <TextField select value={payRateUnits.toString()} label="Pay Rate Units" sx={rightStyle} onChange={ ({target}) => setPayRateUnits(+target.value) } >
                                {timePeriodOptions}
                            </TextField>
                        </Box>
                        <Box display="flex">
                            <RWTextFieldComponent value={onCosts} label="On Costs" sxOptions={leftStyle} onChange={ onOnCostsChange } validator={ numberValidator } />
                            <TextField select value={onCostsUnits.toString()} label="On Costs Units" sx={rightStyle} onChange={ ({target}) => setOnCostsUnits(+target.value) } >
                                {timePeriodOptions}
                            </TextField>
                        </Box>
                        <RWTextFieldComponent value={hoursPerDay} label="Hours Per Day" onChange={ onHoursPerDayChange } validator={ numberValidator } isError={ showValidation && (!Boolean(hoursPerDay) || +hoursPerDay > 24) } />
                        <RWTextFieldComponent
                            label="Days Per Week"
                            value={daysPerWeek.toString()}
                            onChange={({target}) => setDaysPerWeek(+target.value)}
                            validator={daysPerWeekValidator}
                            isError={showValidation && (!Boolean(daysPerWeek) || +daysPerWeek > 7)}
                        />
                        <Box display="flex">
                            <RWTextFieldComponent value={noticePeriod} label="Notice Period" sxOptions={leftStyle} onChange={ onNoticePeriodChange } validator={ numberValidator } />
                            <TextField select value={noticePeriodUnitsId.toString()} label="Notice Period Units" sx={rightStyle} onChange={ ({target}) => setNoticePeriodUnitsId(+target.value) } >
                                {noticeOptions}
                            </TextField>
                        </Box>
                        <Box display="flex">
                            <UserPicker label="Consultant 1" userId={consultant1Id} onSelect={ u => setConsultant1Id(u ? u.id : 0)} sx={leftStyle} />
                            <RWTextFieldComponent label="Consultant 1 %" value={consultant1Percent} onChange={onPercent1Change} validator={ numberValidator } disabled={consultant1Id === 0} sxOptions={rightStyle} />
                        </Box>
                        <Box display="flex">
                            <UserPicker label="Consultant 2" userId={consultant2Id} onSelect={ u => setConsultant2Id(u ? u.id : 0)} sx={leftStyle} />
                            <RWTextFieldComponent label="Consultant 2 %" value={consultant2Percent} onChange={onPercent2Change} validator={ numberValidator } disabled={consultant2Id === 0} sxOptions={rightStyle} />
                        </Box>
                        <Box display="flex">
                            <UserPicker label="Consultant 3" userId={consultant3Id} onSelect={ u => setConsultant3Id(u ? u.id : 0)} sx={leftStyle} />
                            <RWTextFieldComponent label="Consultant 3 %" value={consultant3Percent} onChange={onPercent3Change} validator={ numberValidator } disabled={consultant3Id === 0} sxOptions={rightStyle} />
                        </Box>
                        <Box display="flex">
                            <UserPicker label="Consultant 4" userId={consultant4Id} onSelect={ u => setConsultant4Id(u ? u.id : 0)} sx={leftStyle} />
                            <RWTextFieldComponent label="Consultant 4 %" value={consultant4Percent} onChange={onPercent4Change} validator={ numberValidator } disabled={consultant4Id === 0} sxOptions={rightStyle} />
                        </Box>
                        <Box display="flex">
                            <UserPicker label="Consultant 5" userId={consultant5Id} onSelect={ u => setConsultant5Id(u ? u.id : 0)} sx={leftStyle} />
                            <RWTextFieldComponent label="Consultant 5 %" value={consultant5Percent} onChange={onPercent5Change} validator={ numberValidator } disabled={consultant5Id === 0} sxOptions={rightStyle} />
                        </Box>
                        {renderedCustomFields}
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="error" onClick={ closeHandler }>Cancel</Button>
                    <Button variant="contained" color="success" disabled={!Boolean(effectiveDate)} onClick={ submitCallback } >Submit</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}