import Box from "@mui/material/Box";
import { JobSite } from "common/models/Advert";
import React, { useEffect, useState } from "react";
import { GetJobBoardsByAdvertId } from "services/AdvertsService";
import JobBoardLoaderComponent from "./JobBoardLoader";

interface Props {
    advertId: number,
    advertRecordAttributes?: Record<string, string>,
    loadingHandler?: (isLoading: boolean) => void,
}

type AttributeType = Record<string, string>;
type AttributesMapType = Record<number, AttributeType>;

export default function AdvertJobBoardsRenderer({ advertId, advertRecordAttributes, loadingHandler }: Props) {
    const [jobBoards, setJobBoards] = useState<JobSite[]>([]);
    const [attributesMap, setAttributesMap] = useState<AttributesMapType>({});

    useEffect(() => {
        const getData = async () => {
            loadingHandler && loadingHandler(true);
            const res = await GetJobBoardsByAdvertId(advertId, true);
            if (res) {
                let attsMap: AttributesMapType = {};
                
                for (let i = 0; i < res.length; i++) {
                    const board = res[i];
                    let atts: AttributeType = {};
                    
                    if (board.attributes && board.attributes.length > 0) {
                        for (let j = 0; j < board.attributes.length; j++) {
                            const att = board.attributes[j];
                            atts[att.type] = att.value;
                        }
                    }
                    
                    attsMap[board.jobBoardID] = atts;
                }

                setAttributesMap(attsMap);
                setJobBoards(res);
            }
            loadingHandler && loadingHandler(false);
        };
        
        getData();
    }, [advertId, loadingHandler]);

    return (
        <>
            <Box display="flex" flexDirection="column" gap={2} bgcolor="background.default" p={2} flexGrow={1}>
                {jobBoards.map(j => <JobBoardLoaderComponent key={j.jobBoardID} advertId={advertId} jobBoardId={j.jobBoardID} jobBoardTypeId={j.jobBoardTypeID} title={j.jobBoard} attributes={attributesMap[j.jobBoardID]} advertRecordAttributes={advertRecordAttributes} />)}
            </Box>
        </>
    );

}