import React, { useCallback, useEffect, useMemo, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import moment from "moment";
import { ChangePlacementNetRevenue } from "services/PlacementsService";
import RWTextFieldComponent from "components/RWTextFieldComponent";

interface Props {
    open: boolean,
    placementId: number,
    netRevenue: number,
    netRevenueLabel?: string,
    closeHandler: () => void,
    loadingHandler?: (isLoading: boolean) => void,
    successHandler?: (message: string, netRevenue: number) => void,
    errorHandler?: (message: string) => void
}

export default function ChangeNetRevenueDialog({ open, placementId, netRevenue: netRevenueProp, netRevenueLabel = 'Net Revenue', closeHandler, loadingHandler, successHandler, errorHandler }: Props) {
    const [netRevenue, setNetRevenue] = useState('');

    useEffect(() => {
        if (open) setNetRevenue(netRevenueProp.toString());
    }, [open, netRevenueProp]);

    const effectiveDate = useMemo(() => {
        return moment().format('DD MMM YYYY');
    }, []);

    const submitCallback = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        const n = netRevenue ? +netRevenue : 0;
        const res = await ChangePlacementNetRevenue(placementId, n, errorHandler);
        if (res) {
            successHandler && successHandler(`${netRevenueLabel} Changed`, n);
            closeHandler();
        }
        loadingHandler && loadingHandler(false);
    }, [placementId, netRevenueLabel, netRevenue, loadingHandler, successHandler, errorHandler, closeHandler]);
    
    const netRevenueChanged = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const {value} = e.target;
        const n = +value;
        if (isNaN(n) && value !== '') return;
        if (value.length > 11) return;

        const i = value.indexOf('.');
        if (value.length > 8 && i === -1) return;

        if(i >= 0) {
            const decimals = value.substring(i + 1);
            if (decimals.length > 2) {
                setNetRevenue(value.substring(0, i + 3));
                return;
            }
        }

        setNetRevenue(value);
    }, []);

    return (
        <>
            <Dialog open={open} fullWidth>
                <DialogTitle>Change {netRevenueLabel}</DialogTitle>
                <DialogContent>
                    <Stack mt="10px" spacing={2}>
                        <TextField label="Effective Date" value={effectiveDate} InputProps={{ readOnly: true }} />
                        <RWTextFieldComponent label={netRevenueLabel} value={netRevenue} onChange={netRevenueChanged} />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="error" onClick={ closeHandler }>Cancel</Button>
                    <Button variant="contained" color="success" disabled={netRevenue === netRevenueProp.toString()} onClick={ submitCallback } >Submit</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}