import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import TextField from "@mui/material/TextField";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { MessageTemplate } from "common/models/Templates/MessageTemplate";
import { GetAllMessageTemplates, GetMessageTemplate, GetMyMessageTemplates, GetMyTeamMessageTemplates } from "services/TemplatesService";

interface Props {
    templateId: number | null,
    templateSource: string,
    onSelectHandler: (t: MessageTemplate | null) => void
    appendToStart?: MessageTemplate[],
    typeFilter?: number,
    entityTypeFilter?: number,
    includeCompanyOwned?: boolean,
    label?: string,
    errorHandler?: (message: string) => void,
}

export default function MessageTemplatePicker({ templateId, templateSource, onSelectHandler, typeFilter = 0, entityTypeFilter = 0, includeCompanyOwned = false, label = 'Template', appendToStart, errorHandler }: Props) {
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState<MessageTemplate[]>([]);

    useEffect(() => {
        const getData = async () => {
            setIsLoading(true);
            let data: MessageTemplate[] | null = [];
            if (templateSource === 'Me') data = await GetMyMessageTemplates(errorHandler, typeFilter, entityTypeFilter, includeCompanyOwned);
            else if (templateSource === 'Team') data = await GetMyTeamMessageTemplates(errorHandler, typeFilter, entityTypeFilter, includeCompanyOwned);
            else if (templateSource === 'All') data = await GetAllMessageTemplates(errorHandler, typeFilter, entityTypeFilter, includeCompanyOwned);
            if (data && appendToStart && appendToStart.length > 0) setOptions([...appendToStart, ...data]);
            else if (data) setOptions(data);
            setIsLoading(false);
        };
        getData();
    }, [templateSource, typeFilter, entityTypeFilter, includeCompanyOwned, errorHandler, appendToStart]);

    const template = useMemo(() => {
        if (templateId === null) return null;
        const template = options.find(t => t.id === templateId);
        if (template) return template;
        return null;
    }, [options, templateId]);

    const localSelectHandler = useCallback(async (event: any, selectedTemplate: MessageTemplate | null) => {
        if (selectedTemplate === null || Boolean(selectedTemplate.body)) onSelectHandler(selectedTemplate);
        else {
            if (selectedTemplate.id <= 0) onSelectHandler(selectedTemplate);
            setIsLoading(true);
            const res = await GetMessageTemplate(selectedTemplate.id);
            setIsLoading(false);
            if (res) {
                setOptions(prev => {
                    let tmp = [...prev];
                    const item = tmp.find(v => v.id === selectedTemplate.id);
                    if (item) item.body = res.body;
                    return tmp;
                });
                onSelectHandler(res);
            }
        }
        
    }, [onSelectHandler]);

    return (
        <Autocomplete
            size="small"
            value={template}
            options={options}
            getOptionLabel={ o => o.name }
            isOptionEqualToValue={ (o,v) => o.id === v.id }
            loading={isLoading}
            onChange={ localSelectHandler }
            renderInput={ params => (
                <TextField
                    {...params}
                    label={label}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {isLoading ? <CircularProgress color="inherit" size={20} /> : params.InputProps.endAdornment}
                            </React.Fragment>
                        )
                    }}
                    inputProps={{ ...params.inputProps, "data-lpignore": "true" }}
                />
            )}
            renderOption={ (props, option) => (
                <ListItem {...props} key={option.id}>
                    <ListItemText
                        primary={option.name}
                        secondary={option.userFullName}
                    />
                </ListItem>
            )}
        />
    );
}