import { EmailReceiverData } from "common/models/Candidates/EmailReceiverData";
import { TagValue } from "common/models/Configuration/Tags";
import { ErrorHandler, RequestOptions } from "common/models/GenericTypes";
import { Get, Post, Put } from "util/HttpUtils";

export async function GetEmailReceiverData(key: string, ratingId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: false,
        errorHandler
    };

    return Get<EmailReceiverData>(`api/EmailReceiver/${key}?ratingId=${ratingId}`, options);
}

export async function EmailReceiverDataAddTags(key: string, tags: TagValue[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: false,
        errorHandler
    };

    return Post<boolean>(`api/EmailReceiver/${key}/Tags`, options, tags);
}

export async function EmailReceiverUpdateApplication(key: string, ratingId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: false,
        errorHandler
    };

    return Put<boolean>(`api/EmailReceiver/${key}?ratingId=${ratingId}`, options);
}

export async function EmailReceiverCreateFollowUp(key: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: false,
        errorHandler
    };

    return Post<boolean>(`api/EmailReceiver/${key}/FollowUp`, options);
}

export async function EmailReceiverSendRejectionEmail(key: string, templateId: number, delay: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: false,
        errorHandler
    };

    return Post<boolean>(`api/EmailReceiver/${key}/Reject/${templateId}?delay=${delay}`, options);
}