import { MessagePreviewData, SmsQueueItem } from "common/models/Messages";
import { IdEmailWrapper } from "../common/models/Common";
import { EmailMessage, MessageAttachment, MessageCenterMessage } from "../common/models/EmailIntegration";
import {EmailData, OnboardingData} from "../common/models/EmailRecipient";
import { ErrorHandler, NumberValueWrapper, RequestOptions } from "../common/models/GenericTypes";
import { DownloadBlob } from "../util/FilesUtils";
import { Delete, DownloadFile, Get, Post, PostFile } from "../util/HttpUtils";
import { SmsCreditCheckResponse } from "common/models/Configuration/Messages";

export async function GetEmailMessageById(messageId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<MessageCenterMessage>(`api/Messages/Emails/${messageId}`, options);
}

export async function GetEmailMessageByActivityId(activityId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<EmailMessage>(`api/Messages/Activities/Emails/${activityId}`, options);
}

export async function IgnoreEmailMessage(messageId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>(`api/Messages/Emails/${messageId}/Ignore`, options);
}

export async function IgnoreMultipleEmailMessages(messageIds: number[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>('api/Messages/Emails/Ignore', options, messageIds);
}

export async function CreateExclussion_EamilIntegration(messageId: number, email: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>(`api/Messages/Emails/${messageId}/Exclusion?email=${encodeURIComponent(email)}`, options);
}

export async function CreateMultipleExclussions_EamilIntegration(data: IdEmailWrapper[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>('api/Messages/Emails/Exclusion', options, data);
}

export async function DeleteEmailMessage(messageId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Delete<boolean>(`api/Messages/Emails/${messageId}`, options);
}

export async function GetEmailAttachments(messageId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<MessageAttachment[]>(`api/Messages/Emails/${messageId}/Attachments`, options);
}

export async function CopyEmailAttachmentsToRecord(messageId: number, entityId: number, playerId: number, documentIds: number[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>(`api/Messages/Emails/${messageId}/Attachments/Copy`, options, { entityId: entityId, playerId: playerId, attachmentIds: documentIds });
}

export async function DownloadAttachmentFile(attachmentId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    const file = await DownloadFile(`api/Messages/Documents/${attachmentId}`, options);
    if (file !== null) {
        DownloadBlob(file.data, file.fileName);
    }
}

export async function SendEmail(data: EmailData, files: File[], minutesDelay: number = 0, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    const formData = new FormData();
    formData.append('emailData', JSON.stringify(data));
    for (let i = 0; i < files.length; i++) {
        const f = files[i];
        formData.append('files', f);
    }

    return PostFile<boolean>(`api/Messages/Emails?minutesDelay=${minutesDelay}`, formData, options);
}

export async function SendOnboardingForm(data: OnboardingData, files: File[], minutesDelay: number = 0, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    const formData = new FormData();
    formData.append('onboardingData', JSON.stringify(data));
    for (let i = 0; i < files.length; i++) {
        const f = files[i];
        formData.append('files', f);
    }

    return PostFile<boolean>(`api/Messages/Onboarding?minutesDelay=${minutesDelay}`, formData, options);
}

export async function GetSms(smsId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<SmsQueueItem>(`api/Messages/Sms/${smsId}`, options);
}

export async function SendSms(data: EmailData, minutesDelay: number = 0, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>(`api/Messages/Sms?minutesDelay=${minutesDelay}`, options, data);
}

export async function ReplySms(smsId: number, newMessage: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>(`api/Messages/Sms/${smsId}`, options, { value: newMessage });
}

export async function GetRemainingSmsCredits(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<NumberValueWrapper>('api/Messages/Sms/Credits', options);
}

export async function GetMessagePreview(data: MessagePreviewData, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<MessagePreviewData>('api/Messages/Preview', options, data);
}

export async function SmsMessageCreditCheck(data: MessagePreviewData[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<SmsCreditCheckResponse>('api/Messages/Sms/CreditCheck', options, data);
}