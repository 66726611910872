import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import RWTextFieldComponent from "components/RWTextFieldComponent";
import React, { useCallback, useEffect, useState } from "react";
import { CreateComplianceItem } from "services/ComplianceService";

interface Props {
    open: boolean,
    closeHandler: () => void,
    loadingHandler?: (isLoading: boolean) => void,
    successHandler?: (message: string) => void,
    errorHandler?: (message: string) => void,
}

export default function CreateComplianceItemDialog({ open, closeHandler, loadingHandler, successHandler, errorHandler }: Props) {
    const [name, setName] = useState('');
    const [scope, setScope] = useState(1);
    const [type, setType] = useState(1);

    useEffect(() => {
        if (open) {
            setName('');
            setScope(1);
            setType(1);
        }
    }, [open]);

    const submitCallback = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        const res = await CreateComplianceItem({ name, scopeID: scope, typeID: type }, errorHandler);
        if (res) {
            successHandler && successHandler('Item Created');
            closeHandler();
        }
        loadingHandler && loadingHandler(false);
    }, [name, scope, type, closeHandler, loadingHandler, successHandler, errorHandler]);

    return (
        <Dialog open={open} fullWidth>
            <DialogTitle>Create Item</DialogTitle>
            <DialogContent>
                <Stack spacing={2} mt="10px">
                    <RWTextFieldComponent label="Name" value={name} onChange={(e) => setName(e.target.value)} />
                    <TextField select label="Scope" value={scope.toString()} onChange={({target}) => setScope(+target.value)}>
                        <MenuItem value="1">Candidate</MenuItem>
                        <MenuItem value="2">Placement</MenuItem>
                    </TextField>
                    <TextField select label="Type" value={type.toString()} onChange={({target}) => setType(+target.value)}>
                        <MenuItem value="1">Document</MenuItem>
                    </TextField>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="error" onClick={closeHandler}>Cancel</Button>
                <Button variant="contained" onClick={submitCallback} color="success">Submit</Button>
            </DialogActions>
        </Dialog>
    );
}