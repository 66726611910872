import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import PageLayout from "layouts/PageLayout";
import OnlyTitleSummaryBar from "components/SummaryBars/OnlyTitleSummaryBar";
import Box from "@mui/material/Box";
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import UserPicker from "components/Pickers/UserPicker";
import { SxProps } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import PageContentLayout from "layouts/PageContentLayout";
import Button from "@mui/material/Button";
import { CancelInterview, GetInterviewsReportData } from "services/InterviewsService";
import { Interview } from "common/models/Interviews";
import GridWithStateWrapper from "components/GridWidthStateWrapper";
import {
    GridActionsCellItem,
    GridColDef,
    GridColumnVisibilityModel,
    GridRenderCellParams,
    GridValueGetter,
    useGridApiRef
} from "@mui/x-data-grid-premium";
import moment from "moment";
import { Link } from "react-router-dom";
import ConfirmationDialog from "components/Dialogs/Generic/ConfirmationDialog";
import Snackbar from "@mui/material/Snackbar";
import Alert from "components/Alert";
import { NameIdObj } from "common/models/GenericTypes";
import ActionsDropDownButton from "components/SummaryBars/Actions/ActionsDropsDownMenu";
import {defaultGridCellStyle} from "../../util/GridUtils";

const getInterviewType = (t: string | null): string => {
    if(t === "1") return ": Recently Completed";
    if(t === "2") return ": Upcoming";
    return "";
}

const ownerSpecialOptions: NameIdObj[] = [
    { id: 0, name: 'Me' },
    { id: -1, name: 'My Team' },
    { id: -2, name: 'My Company' },
];

const filterElementStyle: SxProps = { flex: '1 1 0', mr: 1 };
const linkStyle: React.CSSProperties = { color: 'inherit', textDecoration: 'underline' };

export default function Interviews() {
    const [rows, setRows] = useState<Interview[]>([]);
    const [showGrid, setShowGrid] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [searchParams] = useSearchParams();
    const [consultantId, setConsultantId] = useState(-1)
    const [timeRange, setTimeRange] = useState(11);
    const [status, setStatus] = useState(1);
    const [cancelInterviewId, setCancelInterviewId] = useState<number | null>(null);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const apiRef = useGridApiRef();

    const reportType = useMemo(() => {
        const param = searchParams.get('t');
        return param ? param : '1';
    }, [searchParams]);

    const summaryBar = useMemo(() => {
        if (reportType === "1") {
            setTimeRange(5);
            setStatus(2);
        }
        else if (reportType === "2") {
            setTimeRange(2);
            setStatus(1);
        }
        return <OnlyTitleSummaryBar title={`Interviews${getInterviewType(reportType)}`} />;
    }, [reportType]);

    useEffect(() => {
        const getData = async (consultantId: number, timeRange: number, status: number) => {
            setIsLoading(true);
            const res = await GetInterviewsReportData(consultantId, timeRange, status);
            if (res) {
                setShowGrid(true);
                setRows(res);
            }
            setIsLoading(false);
        };
        if (reportType === '1') getData(-1, 5, 2);
        if (reportType === '2') getData(-1, 2, 1);
    }, [reportType]);

    const getDataCallback = useCallback(async () => {
        setIsLoading(true);
        const res = await GetInterviewsReportData(consultantId, timeRange, status);
        if (res) {
            setShowGrid(true);
            setRows(res);
        }
        setIsLoading(false);
    }, [consultantId, timeRange, status]);

    const exportAsExcelHandler = useCallback(() => {
        const api = apiRef.current;
        if (api) {
            const reportName = reportType === '1' ? 'Interviews_RecentlyCompleted' : 'Interviews_Upcoming';
            const m = moment();
            const filename = reportName + m.format('YYYYMMDDhhmmss');
            api.exportDataAsExcel({ fileName: filename });
        }
    }, [apiRef, reportType]);

    const columns = useMemo<GridColDef[]>(() => {
        const dateValueGetter: GridValueGetter<Interview, any, undefined, string> = (value) => {
            if (value) {
                const d = moment(value);
                if (d.isValid() && d.get("year") > 1) {
                    return d.toDate();
                }
            }
            return null;
        };

        const dateRenderer = (params: GridRenderCellParams) => {
            if (params.value) {
                let val = moment(params.value).format('DD MMM YYYY h:mm A');

                return (
                    <div style={defaultGridCellStyle}>
                        <span>{val}</span>
                    </div>
                );
            }
            return params.value;
        }

        const linkToInterviewRenderer = (params: GridRenderCellParams) => {
            const interviewId = params.row.id;
            if (interviewId) {
                return <div style={defaultGridCellStyle}>
                    <Link to={`/interviews/${interviewId}`} style={linkStyle}>{params.value}</Link>
                </div>
            }
            return params.value;
        };

        const htmlRenderer = (params: GridRenderCellParams) => {
            if (params.value) {
                const newLineReplaced = (params.value as string).replaceAll('\n', '<br />');
                return <div style={{padding: '5px 0' }} dangerouslySetInnerHTML={{ __html: newLineReplaced }} />
            }
        }

        const minHeightCellRenderer = (params: GridRenderCellParams) => {
            return (
                <div style={defaultGridCellStyle}>
                    <span>{params.value}</span>
                </div>
            );
        };

        return [
            { field: 'actions', headerName: 'Actions', width: 100, align: 'center', headerAlign: 'center', type: 'actions', getActions: params => [
                (params.row.statusID === 1)
                    ? <GridActionsCellItem icon={ <CancelIcon /> } label="Cancel" onClick={ () => setCancelInterviewId(params.row.id) } onResize={()=> {}} onResizeCapture={() => {}} />
                    : <></>
                ,
                (params.row.statusID === 1)
                    ? <GridActionsCellItem
                        icon={
                            <Link to={`/interviews/${params.id}/edit`} target="_blank" style={{ color: 'inherit', textDecoration: 'none', maxHeight: '20px', maxWidth: '20px' }}>
                                <EditIcon sx={{ width: '20px', height: '20px' }} />
                            </Link>
                        }
                        label="Edit"
                        onResize={()=> {}}
                        onResizeCapture={() => {}}
                    />
                    : <></>
                ,
            ]},
            { field: 'id', headerName: 'ID', width: 100, align: 'center', headerAlign: 'center', renderCell: linkToInterviewRenderer },
            { field: 'date', headerName: 'Date', width: 200, type: 'date', align: 'center', headerAlign: 'center', valueGetter: dateValueGetter, renderCell: dateRenderer },
            { field: 'statusName', headerName: 'Status', width: 200, align: 'center', headerAlign: 'center', renderCell: minHeightCellRenderer },
            { field: 'typeName', headerName: 'Type', width: 200, align: 'center', headerAlign: 'center', renderCell: linkToInterviewRenderer },
            { field: 'organizerName', headerName: 'Organizer', width: 200, align: 'center', headerAlign: 'center', renderCell: minHeightCellRenderer },
            { field: 'numberOfAttendees', headerName: '# Attendees', width: 200, align: 'center', headerAlign: 'center', renderCell: minHeightCellRenderer },
            { field: 'location', headerName: 'Location', width: 200, align: 'center', headerAlign: 'center', renderCell: minHeightCellRenderer },
            { field: 'subject', headerName: 'Subject', width: 200, renderCell: linkToInterviewRenderer, },
            { field: 'interviewNotes', headerName: 'Notes', width: 200, renderCell: htmlRenderer },
        ];
    }, []);

    const cancelInterviewCallback = useCallback(async () => {
        setIsLoading(true);
        if (cancelInterviewId) {
            const res =await CancelInterview(cancelInterviewId, setErrorMessage);
            if (res) {
                setSuccessMessage('Interview Canceled');
                setCancelInterviewId(null);
                getDataCallback();
            }
        }
        setIsLoading(false);
    }, [cancelInterviewId, getDataCallback]);

    const defaultHiddenCols: GridColumnVisibilityModel = {
        'id': false,
        'interviewNotes': false
    };

    return (
        <PageLayout SummaryBar={summaryBar}>
            <Snackbar open={successMessage !== ''} autoHideDuration={3000} onClose={() => setSuccessMessage('')}>
                <Alert onClose={() => setSuccessMessage('')}>Interview Canceled</Alert>
            </Snackbar>
            <Snackbar open={errorMessage !== ''}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <ConfirmationDialog
                open={cancelInterviewId !== null}
                title="Confirm Action"
                message="Are you sure you want to cancel this Interview?"
                confirmActionText="Yes"
                cancelActionText="No"
                onClose={ () => setCancelInterviewId(null) }
                onContinue={ cancelInterviewCallback }
            />
            <PageContentLayout showLoading={isLoading}>
                <Box display="flex" mb={2}>
                    <Box flex="1 1 0" pr={1}>
                        <UserPicker disableClearable label="Consultant" userId={consultantId} onSelect={u => setConsultantId( u ? u.id : 0 )} appendToStart={ownerSpecialOptions} />
                    </Box>
                    <TextField select label="Time Range" value={timeRange.toString()} onChange={ ({target}) => setTimeRange(+target.value) } sx={filterElementStyle}>
                        <MenuItem value="11">Today</MenuItem>
                        <MenuItem value="1">This Week</MenuItem>
                        <MenuItem value="2">This Month</MenuItem>
                        <MenuItem value="4">This Year</MenuItem>
                        <MenuItem value="10">Yesterday</MenuItem>
                        <MenuItem value="5">Last Week</MenuItem>
                        <MenuItem value="6">Last Month</MenuItem>
                        <MenuItem value="8">Last Year</MenuItem>
                        <MenuItem value="12">Tomorrow</MenuItem>
                        <MenuItem value="9">Future</MenuItem>
                    </TextField>
                    <TextField select label="Status" value={status.toString()} onChange={ ({target}) => setStatus(+target.value) } sx={filterElementStyle}>
                        <MenuItem value="999">All</MenuItem>
                        <MenuItem value="1">Open</MenuItem>
                        <MenuItem value="3">Cancelled</MenuItem>
                        <MenuItem value="2">Closed</MenuItem>
                    </TextField>
                    <Button variant="contained" color="success" onClick={getDataCallback}>Run Report</Button>
                </Box>
                {showGrid &&
                    <>
                        <Box pb="10px" ml="auto">
                            <ActionsDropDownButton color="secondary" label="List Actions">
                                <MenuItem onClick={exportAsExcelHandler}>Export</MenuItem>
                            </ActionsDropDownButton>
                        </Box>
                        <GridWithStateWrapper
                            gridName="interviews-search"
                            defaultViewModel={defaultHiddenCols}
                            rows={rows}
                            columns={columns}
                            apiRef={apiRef}
                            density="compact"
                            disableRowSelectionOnClick
                            pagination={true}
                            pageSizeOptions={[100,250,500,1000]}
                            getRowHeight={() => 'auto'}
                        />
                    </>
                }
            </PageContentLayout>
        </PageLayout>
    );
}
