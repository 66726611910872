import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Alert from "components/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Input from "@mui/material/Input";
import { InputBaseComponentProps } from "@mui/material/InputBase/InputBase";
import MenuItem from "@mui/material/MenuItem";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { TimeZone } from "common/models/Common";
import { Division } from "common/models/Configuration/Division";
import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import PageContentLayout from "layouts/PageContentLayout";
import { GetTimeZone } from "services/CommonService";
import {
    CreateDivision,
    GetDivision,
    GetDivisionEditSettings, GetDivisionSettings,
    SaveDivisionSettings,
    UpdateDivision
} from "services/DivisionsService";
import { CandidateThreshold, DivisionEditSettings, PredictedPlacementDateOffset } from "common/models/Configuration/Forecasting";
import TimeZonePicker from "components/Pickers/TimeZonePicker";
import useObjectStateWithChangeTracker from "hooks/UseObjectStateWithChangeTracker";
import { DefaultDivision, NoChangesDivision } from "util/Definitions/Divisions";
import { ChangeTracker } from "common/models/hooks/ChangeTracker";
import useUnsavedChangesDialog from "hooks/UseUnsavedChangesDialog";
import CountryPicker from "components/Pickers/CountryPicker";
import {GetAutomatedRejectionsOptions, GetJobStagesLookup } from "../../../services/ConfigurationService";
import {DivisionConfigOptions} from "../../../common/models/Configuration/Division";
import {DefaultOptions, NoChangesTracker} from "../../../util/Definitions/Configuration/Divisions";
import {ObjToSettingsArray, SettingsArrayToObj} from "../../../util/SettingsUtil";
import Typography from "@mui/material/Typography";
import RWTextFieldComponent from "components/RWTextFieldComponent";
import InputAdornment from '@mui/material/InputAdornment';

interface Props {
    setSummaryBar?: (summaryBar: JSX.Element) => void
}
const inputProps: InputBaseComponentProps = { style: { textAlign: 'right' } };

interface EditDivision extends Division {
    jobSettings: string,
    candidateSettings: string
};

const DefaultEditDivision: EditDivision = { ...DefaultDivision, jobSettings: '', candidateSettings: '' };
const NoChangesEditDivision: ChangeTracker<EditDivision> = { ...NoChangesDivision, jobSettings: false, candidateSettings: false };
const automatedRejectionDoNotSend = <MenuItem key={0} value="-1">Do Not Send</MenuItem>;
const automatedRejectionMirrorGlobalConfig = <MenuItem key={1} value="0">Use Global Configuration</MenuItem>;

export default function DivisionItem({ setSummaryBar }: Props) {
    const [isLoading, setIsLoading] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [generalExpanded, setGeneralExpanded] = useState(true);
    const [jobSettingsExpanded, setJobSettingsExpanded] = useState(false);
    const [forecast1Expanded, setForecast1Expanded] = useState(false);
    const [forecast2Expanded, setForecast2Expanded] = useState(false);
    const { state, init, change, updateInitial, hasChanges } = useObjectStateWithChangeTracker<EditDivision>(DefaultEditDivision, NoChangesEditDivision);
    const [divisionData, setDivisionData] = useState<DivisionEditSettings | null>(null);
    const [jobStageData, setJobStageData] = useState<PredictedPlacementDateOffset[]>([]);
    const [workflowStatuses, setWorkflowStatuses] = useState<CandidateThreshold[]>([]);

    const [initialJobSettingsString, setInitialJobsettingsString] = useState('');
    const [initialCandidateSettings, setInitialCandidatesettingsString] = useState('');
    const [selectedTimeZone, setSelectedTimeZone] = useState<TimeZone | null>(null);
    const [automatedRejectionOptions, setAutomatedRejectionOptions] = useState<JSX.Element[]>([automatedRejectionDoNotSend, automatedRejectionMirrorGlobalConfig]);
    const { state: settingsState, init: settingsInit, change : settingsChange, hasChanges: settingsHasChanges } = useObjectStateWithChangeTracker<DivisionConfigOptions>(DefaultOptions, NoChangesTracker);
    const params = useParams();

    useEffect(() => {
        const getSettings = async () => {
            setIsLoading(true);
            const options = await GetAutomatedRejectionsOptions(setErrorMessage);

            if(options) {
                let t = options.map(option => (<MenuItem key={option.id} value={option.id.toString()}>{option.name}</MenuItem>));
                t.unshift(automatedRejectionDoNotSend);
                t.unshift(automatedRejectionMirrorGlobalConfig);
                setAutomatedRejectionOptions(t);
            }

            setIsLoading(false);
        };
        getSettings();
    }, [init]);

    const itemId = useMemo(() => {
        const stringId = params.id ?? '0';
        if (isNaN(+stringId)) {
            return 0;
        }
        return +stringId;
    }, [params.id]);

    const isJobForecastValuesValid = useMemo(() => {
        for (let i = 0; i < jobStageData.length; i++) {
            const v = jobStageData[i];
            if (v.contract < 0) return false;
            if (v.fixedTerm < 0) return false;
            if (v.permanent < 0) return false;
        }
        return true;
    }, [jobStageData]);

    const isCandidateForecastValuesValid = useMemo(() => {
        for (let i = 0; i < workflowStatuses.length; i++) {
            const v = workflowStatuses[i];
            if (v.contract < 0 || v.contract > 100) return false;
            if (v.fixedTerm < 0 || v.fixedTerm > 100) return false;
            if (v.permanent < 0 || v.permanent > 100) return false;
        }
        return true;
    }, [workflowStatuses]);

    const saveHandler = useCallback(async () => {
        if (!isJobForecastValuesValid) {
            setErrorMessage('All job settings values must be equal or greater than 0');
            return false;
        }

        if (!isCandidateForecastValuesValid) {
            setErrorMessage('All candidate settings values must be between 0 - 100');
            return false;
        }

        setIsLoading(true);
        const d: Partial<Division> = {
            name: state.name,
            shortName: state.shortName,
            active: state.active,
            country: state.country,
            timeZone: state.timeZone,
            timesheetSystem: state.timesheetSystem
        };

        if (itemId === 0) {
            const res = await CreateDivision(d as Division, setErrorMessage);
            if (!res) {
                setIsLoading(false);
                return false;
            }
        }
        else {
            const res = await UpdateDivision(itemId, d as Division, jobStageData, workflowStatuses, setErrorMessage);

            const settings = ObjToSettingsArray(settingsState);
            await SaveDivisionSettings(itemId, settings, setErrorMessage);

            if (!res) {
                setIsLoading(false);
                return false;
            }
        }

        updateInitial();
        setShowSuccess(true);
        setIsLoading(false);
        return true;
    }, [state, settingsState, updateInitial, initialCandidateSettings, initialJobSettingsString, itemId]);

    const { unsavedChangesDialog, hasBlockedRoute } = useUnsavedChangesDialog(hasChanges, saveHandler);

    useEffect(() => {
        const saveButton = <Button variant="contained" color="primary" disabled={!hasChanges && !settingsHasChanges} onClick={saveHandler} >Save</Button>;
        const summaryBar = (
            <TitleAndActionSummaryBar
                title="Configuration > Divisions"
                browserTabTitle="Divisions > Configuration"
                action={saveButton}
            />
        );
        setSummaryBar && setSummaryBar(summaryBar);
    }, [setSummaryBar, hasChanges, settingsHasChanges, saveHandler]);

    useEffect(() => {
        const getDivisionData = async () => {
            setIsLoading(true);
            const data = await GetDivision(itemId);
            if (data) {
                if (data.timeZone) {
                    const tz = await GetTimeZone(data.timeZone);
                    setSelectedTimeZone(tz);
                }

                const settings = await GetDivisionEditSettings(itemId);
                if (settings) {
                    setDivisionData(settings)
                    setJobStageData(settings.ppDateOffsets);
                    setWorkflowStatuses(settings.cThresholds);
                    setInitialJobsettingsString(JSON.stringify(settings.ppDateOffsets));
                    setInitialCandidatesettingsString(JSON.stringify(settings.cThresholds));
                    init({ ...data, timesheetSystem: settings.timesheet, jobSettings: JSON.stringify(settings.ppDateOffsets), candidateSettings: JSON.stringify(settings.cThresholds) });
                }

                const settingsData = await GetDivisionSettings(itemId, setErrorMessage);

                if (settingsData) {
                    const savedSettings = SettingsArrayToObj<DivisionConfigOptions>(settingsData, DefaultOptions);
                    settingsInit(savedSettings);
                }
            }
            setIsLoading(false);
        };

        itemId !== 0 && getDivisionData();
    }, [itemId, init, settingsInit]);

    const timeZoneChangeCallback = useCallback((tz: TimeZone | null) => {
        setSelectedTimeZone(tz);
        if (tz) {
            change('timeZone', tz.zoneName);
        }
    }, [change]);


    const settingsHandleChanges = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        settingsChange(name as keyof DivisionConfigOptions, value);
    }, [settingsChange]);


    const jobStageValueChange = useCallback((name: string, value: string, index: number) => {
        setJobStageData((prev) => {
            let tmp = [...prev];
            let jobStage = { ...tmp[index] };
    
            if (name === "permanent") {
                jobStage.permanent = +value;
            }
    
            if (name === "fixedTerm") {
                jobStage.fixedTerm = +value;
            }
    
            if (name === "contract") {
                jobStage.contract = +value;
            }
    
            tmp[index] = jobStage;
            change('jobSettings', JSON.stringify(tmp));
            return tmp;
        });

    }, [jobStageData]);

    const workflowValueChange = useCallback((name: string, value: string, index: number) => {
        setWorkflowStatuses((prev) => {
            let tmp = [...prev];
            let workflow = { ...tmp[index] };
    
            if (name === "permanent") {
                workflow.permanent = +value;
            }
    
            if (name === "fixedTerm") {
                workflow.fixedTerm = +value;
            }
    
            if (name === "contract") {
                workflow.contract = +value;
            }
    
            tmp[index] = workflow;

            change('candidateSettings', JSON.stringify(tmp));
            return tmp;
        });
    }, [workflowStatuses]);

    return (
        <>
            {unsavedChangesDialog}
            { !hasBlockedRoute && showSuccess && <Navigate to="/configuration/divisions" />}
            <Snackbar open={showSuccess} autoHideDuration={3000} onClose={() => setShowSuccess(false)}>
                <Alert onClose={() => setShowSuccess(false)}>Changes Saved</Alert>
            </Snackbar>
            <Snackbar open={errorMessage !== ''}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{errorMessage}</Alert>
            </Snackbar>
            <PageContentLayout title="Division" showLoading={isLoading}>
                <Box sx={{ bgcolor: 'background.default', p: 1 }}>
                    <Accordion expanded={generalExpanded} onChange={() => setGeneralExpanded(prev => !prev)}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            General
                            <Typography color="text.secondary" component="span" ml="auto" mr={2}>{generalExpanded ? 'Click to collapse' : 'Click to expand'}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Stack spacing={3}>
                                <RWTextFieldComponent
                                    label="Name"
                                    value={state.name}
                                    onChange={(e) => change("name", e.target.value)}
                                />
                                {itemId === 0 &&
                                    <RWTextFieldComponent
                                        label="Short Name"
                                        value={state.shortName}
                                        onChange={(e) => change("shortName", e.target.value)}
                                    />
                                }
                                {itemId !== 0 &&
                                    <TextField
                                        select
                                        label="Status"
                                        value={state.active}
                                        onChange={({ target }) => change("active", target.value)}
                                    >
                                        <MenuItem value="true">Enabled</MenuItem>
                                        <MenuItem value="false">Disabled</MenuItem>
                                    </TextField>
                                }
                                <CountryPicker
                                    code={state.country}
                                    disableClearable
                                    label="Default Country"
                                    onSelectCallback={ v => change('country', v ?? 'AU') }
                                />
                                <TimeZonePicker value={selectedTimeZone} onSelectCallback={timeZoneChangeCallback} />
                                <TextField
                                    select
                                    label="Timesheet System"
                                    value={state.timesheetSystem.toString()}
                                    onChange={({ target }) => change('timesheetSystem', +target.value)}
                                >
                                    <MenuItem value="0">None</MenuItem>
                                    <MenuItem value="1">Astute Payroll</MenuItem>
                                </TextField>
                            </Stack>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={jobSettingsExpanded} onChange={() => setJobSettingsExpanded(prev => !prev)}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            Job Settings
                            <Typography color="text.secondary" component="span" ml="auto" mr={2}>{jobSettingsExpanded ? 'Click to collapse' : 'Click to expand'}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Stack spacing={3}>
                                <TextField
                                    select
                                    label="Automated Rejections - Filled by Us"
                                    name="AutomatedRejectionsFilledByUs"
                                    value={settingsState.AutomatedRejectionsFilledByUs}
                                    onChange={settingsHandleChanges}
                                >
                                    {automatedRejectionOptions}
                                </TextField>
                                <TextField
                                    select
                                    label="Automated Rejections - Filled by Client"
                                    name="AutomatedRejectionsFilledByClient"
                                    value={settingsState.AutomatedRejectionsFilledByClient}
                                    onChange={settingsHandleChanges}
                                >
                                    {automatedRejectionOptions}
                                </TextField>
                                <TextField
                                    select
                                    label="Automated Rejections - Filled by Competitor"
                                    name="AutomatedRejectionsFilledByCompetitor"
                                    value={settingsState.AutomatedRejectionsFilledByCompetitor}
                                    onChange={settingsHandleChanges}
                                >
                                    {automatedRejectionOptions}
                                </TextField>
                                <TextField
                                    select
                                    label="Automated Rejections - Withdrawn"
                                    name="AutomatedRejectionsWithdrawn"
                                    value={settingsState.AutomatedRejectionsWithdrawn}
                                    onChange={settingsHandleChanges}
                                >
                                    {automatedRejectionOptions}
                                </TextField>
                                <TextField
                                    select
                                    label="Automated Rejections - Administrative"
                                    name="AutomatedRejectionsAdministrative"
                                    value={settingsState.AutomatedRejectionsAdministrative}
                                    onChange={settingsHandleChanges}
                                >
                                    {automatedRejectionOptions}
                                </TextField>
                                <TextField
                                    select
                                    label="Automated Rejections - Unknown"
                                    name="AutomatedRejectionsUnknown"
                                    value={settingsState.AutomatedRejectionsUnknown}
                                    onChange={settingsHandleChanges}
                                >
                                    {automatedRejectionOptions}
                                </TextField>
                                <TextField
                                    select
                                    label="Automated Rejections - Not Supplied"
                                    name="AutomatedRejectionsNotSupplied"
                                    value={settingsState.AutomatedRejectionsNotSupplied}
                                    onChange={settingsHandleChanges}
                                >
                                    {automatedRejectionOptions}
                                </TextField>
                                <TextField
                                    select
                                    label="Automated Rejections - Client did not win work"
                                    name="AutomatedRejectionsClientDidNotWinWork"
                                    value={settingsState.AutomatedRejectionsClientDidNotWinWork}
                                    onChange={settingsHandleChanges}
                                >
                                    {automatedRejectionOptions}
                                </TextField>
                                <TextField
                                    select
                                    label="Automated Rejections - Budget not Approved"
                                    name="AutomatedRejectionsBudgetNotApproved"
                                    value={settingsState.AutomatedRejectionsBudgetNotApproved}
                                    onChange={settingsHandleChanges}
                                >
                                    {automatedRejectionOptions}
                                </TextField>
                            </Stack>
                        </AccordionDetails>
                    </Accordion>
                    {itemId !== 0 &&
                        <>
                           <Accordion expanded={forecast1Expanded} onChange={() => setForecast1Expanded(prev => !prev)}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                                    Forecasting - Job Settings
                                    <Typography color="text.secondary" component="span" ml="auto" mr={2}>{forecast1Expanded ? 'Click to collapse' : 'Click to expand'}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Box display="flex" justifyContent="center">
                                        <Grid container spacing={2} maxWidth="700px">
                                            <Grid item xs={6}><b>Job Stage</b></Grid>
                                            <Grid item xs={2} textAlign="center"><b>Contract</b></Grid>
                                            <Grid item xs={2} textAlign="center"><b>Fixed Term</b></Grid>
                                            <Grid item xs={2} textAlign="center"><b>Permanent</b></Grid>

                                            {jobStageData && jobStageData.map((stage,index) => {
                                                return <>
                                                       <Grid item xs={6}>{stage.stageName}</Grid>
                                            <Grid item xs={2}>
                                                <Input
                                                    key={`contract_${index}`}
                                                    size="small"
                                                    type="number"
                                                    name="contract"
                                                    fullWidth
                                                    inputProps={inputProps}
                                                    value={stage.contract}
                                                    error={stage.contract < 0}
                                                    endAdornment={<InputAdornment position="end">days</InputAdornment>}
                                                    onChange={(e)=> jobStageValueChange(e.target.name,e.target.value,index)}
                                                />
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Input
                                                    key={`fixedTerm_${index}`}
                                                    size="small"
                                                    type="number"
                                                    fullWidth
                                                    name="fixedTerm"
                                                    inputProps={inputProps}
                                                    value={stage.fixedTerm}
                                                    error={stage.fixedTerm < 0}
                                                    endAdornment={<InputAdornment position="end">days</InputAdornment>}
                                                    onChange={(e)=> jobStageValueChange(e.target.name,e.target.value,index)}
                                                />
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Input
                                                    key={`permanent_${index}`}
                                                    size="small"
                                                    type="number"
                                                    fullWidth
                                                    name="permanent"
                                                    inputProps={inputProps}
                                                    value={stage.permanent}
                                                    error={stage.permanent < 0}
                                                    endAdornment={<InputAdornment position="end">days</InputAdornment>}
                                                    onChange={(e)=> jobStageValueChange(e.target.name,e.target.value,index)}
                                                />
                                            </Grid>
                                                </>
                                            })}
                                        </Grid>
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={forecast2Expanded} onChange={() => setForecast2Expanded(prev => !prev)}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                                    Forecasting - Candidate Settings
                                    <Typography color="text.secondary" component="span" ml="auto" mr={2}>{forecast2Expanded ? 'Click to collapse' : 'Click to expand'}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Box display="flex" justifyContent="center">
                                        <Grid container spacing={2} maxWidth="700px">
                                            <Grid item xs={6}><b>Workflow Status</b></Grid>
                                            <Grid item xs={2} textAlign="center"><b>Contract</b></Grid>
                                            <Grid item xs={2} textAlign="center"><b>Fixed Term</b></Grid>
                                            <Grid item xs={2} textAlign="center"><b>Permanent</b></Grid>
                                            {workflowStatuses && workflowStatuses.map((workflow,index) => {
                                                return <>
                                                       <Grid item xs={6}>{workflow.name}</Grid>
                                            <Grid item xs={2}>
                                                <Input
                                                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                                    key={`contract_${index}`}
                                                    size="small"
                                                    type="number"
                                                    name="contract"
                                                    inputProps={inputProps}
                                                    value={workflow.contract}
                                                    error={workflow.contract < 0}
                                                    onChange={(e)=> workflowValueChange(e.target.name,e.target.value,index)}
                                                /> 
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Input
                                                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                                    key={`fixedTerm_${index}`}
                                                    size="small"
                                                    type="number"
                                                    name="fixedTerm"
                                                    inputProps={inputProps}
                                                    value={workflow.fixedTerm}
                                                    error={workflow.fixedTerm < 0}
                                                    onChange={(e)=> workflowValueChange(e.target.name,e.target.value,index)}
                                                />
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Input
                                                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                                    key={`permanent_${index}`}
                                                    size="small"
                                                    type="number"
                                                    name="permanent"
                                                    inputProps={inputProps}
                                                    value={workflow.permanent}
                                                    error={workflow.permanent < 0}
                                                    onChange={(e)=> workflowValueChange(e.target.name,e.target.value,index)}
                                                />
                                            </Grid>
                                                </>
                                            })}
                                        </Grid>
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                        </>
                    }
                </Box>
            </PageContentLayout>
        </>
    );
}