import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import { useTheme } from "@mui/material/styles";
import { PanelModel } from "@syncfusion/ej2-layouts/src/dashboard-layout/dashboard-layout-model";
import { DashboardLayoutComponent } from "@syncfusion/ej2-react-layouts/src/dashboard-layout/dashboardlayout.component";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Candidate } from "common/models/Candidates/Candidate";
import { CustomField, CustomFieldPredefinedValue } from "common/models/Configuration/CustomFields";
import { Division } from "common/models/Configuration/Division";
import { CandidateRecordDashboardElementDefinition, CandidateRecordDashboardElementType } from "common/models/Dashboard/EditLayout";
import { CustomFieldSettingsMap, CustomFieldType } from "common/models/ScreenLayouts/CustomFields";
import { GetCustomerSettingBySettingName } from "services/ConfigurationService";
import { GetCustomFieldsByEntity_OnlyActive, GetPredefinedValues } from "services/CustomFieldsService";
import { GetDivisions } from "services/DivisionsService";
import { CandidateScreenLayoutSettings, DefaultCandidateRecordDashboardElements, GetPanelDefinitionsFromPanelModels } from "util/Definitions/ScreenLayouts/Candidate";
import { UdfCandidateFieldMapObj } from "util/Definitions/ScreenLayouts/CustomFields";
import PanelWrapper from "components/Dashboards/PanelWrapper";
import MultiLineTextElement from "../MultiLineTextElement";
import SingleFieldElement from "../SingleFieldElement";
import ViewCandidateLocationElement from "./ViewCandidateLocationElement";
import { NationalityList } from "util/Definitions/Nationality";
import EditableSingleFieldElement from "../EditableSingleFieldElement";
import MenuItem from "@mui/material/MenuItem";
import { UpdateCandidateCustomField } from "services/CandidatesService";
import RecordAvatarComponent from "components/Dashboards/Graphs/ClientAvatarComponent";

interface Props {
    candidate: Candidate | null,
    onEditableCustomFieldChange?: (udfNumber: number, value: string) => void,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
}

interface CustomFieldWithPredefinedValues extends CustomField {
    values?: CustomFieldPredefinedValue[],
}

const { unitWidth, unitHeight, gapX, gapY, columns, mediaQueryMaxWidth } = CandidateScreenLayoutSettings;
const cellSpacing = [gapX, gapY];

const formatNumber = (value: number) => {
    return value.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 });
};

const getCandidateGenderName = (genderId: number) => {
    switch (genderId) {
        case 0: return 'Not stated or inadequately described';
        case 1: return 'Male';
        case 2: return 'Female';
        case 3: return 'Other';
        default: return '';
    }
};

const getCandidateJobSeekingStatusName = (statusId: number) => {
    switch (statusId) {
        case 1: return 'Seriously Looking';
        case 2: return 'Open To Opportunities';
        case 3: return 'Not Currently Looking';
        default: return '';
    }
};

export default function ViewRecordScreenLayout({ candidate, onEditableCustomFieldChange, loadingHandler, errorHandler }: Props) {
    const [divisions, setDivisions] = useState<Division[]>([]);
    const [screenResizedControl, setScreenResizedControl] = useState(false);
    const [fetchingSavedState, setFetchingSavedState] = useState(false);
    const [fetchedSavedState, setFetchedSavedState] = useState(false);
    const [activeFields, setActiveFields] = useState<CustomFieldWithPredefinedValues[]>([]);
    const [lastSavedState, setLastSavedState] = useState<PanelModel[] | null>(null);
    const [isFetchingConfig, setIsFetchingConfig] = useState(false);
    const layoutRef = useRef<DashboardLayoutComponent | null>(null);
    const theme = useTheme();

    const candidateId = useMemo(() => {
        return candidate ? candidate.id : 0;
    }, [candidate]);

    useEffect(() => {
        const windowResizeHandler = () => setScreenResizedControl(prev => !prev);
        window.addEventListener('resize', windowResizeHandler);
        return () => window.removeEventListener('resize', windowResizeHandler);
    }, []);

    useEffect(() => {
        const getActiveFields = async () => {
            setIsFetchingConfig(true);
            const customFields = await GetCustomFieldsByEntity_OnlyActive(3);
            if (customFields) {
                let udfDefinitions: CustomFieldWithPredefinedValues[] = [];
                for (let i = 0; i < customFields.length; i++) {
                    const u = customFields[i];
                    if (!u.usePredefinedValues) {
                        udfDefinitions.push(u);
                        continue;
                    }

                    const vals = await GetPredefinedValues(u.id);
                    if (vals) udfDefinitions.push({ ...u, values: vals });
                    else udfDefinitions.push(u);
                }
                setActiveFields(udfDefinitions);
            }
            setIsFetchingConfig(false);
        };
        getActiveFields();
    }, []);

    useEffect(() => {
        const getDivs = async () => {
            const res = await GetDivisions();
            if (res) setDivisions(res);
        };
        getDivs();
    }, []);

    const divisionNames = useMemo<string[]>(() => {
        if (candidate) {
            let names: string[] = [];
            const ids = candidate.divisions.split(';');
            ids.forEach(d => {
                const item = divisions.find(div => div.id === +d);
                if (item) names.push(item.name);
            });
            return names;
        }
        return [];
    }, [candidate, divisions]);

    const customFieldsSettingsMap = useMemo<CustomFieldSettingsMap>(() => {
        let obj: CustomFieldSettingsMap = {};
        activeFields.forEach(u => {
            const customFieldNumber = u.name.substring(13);
            const key = 'CustomField' + customFieldNumber as CustomFieldType;
            obj[key] = {
                title: u.agencyName,
                isMultiLine: u.multiLine && !u.usePredefinedValues,
                isEditable: u.editable,
                usePredefinedValues: u.usePredefinedValues,
                values: u.values
            }
        });
        return obj;
    }, [activeFields]);

    useEffect(() => {
        loadingHandler && loadingHandler(isFetchingConfig || fetchingSavedState);
    }, [loadingHandler, fetchingSavedState, isFetchingConfig]);

    useEffect(() => {
        const getSavedState = async () => {
            setFetchingSavedState(true);
            const elementsJson = await GetCustomerSettingBySettingName('CandidateRecordDashboardLayoutElements');
            if (elementsJson) {
                const panels = JSON.parse(elementsJson) as PanelModel[];
                setLastSavedState(panels);
            }
            setFetchingSavedState(false);
            setFetchedSavedState(true);
        };
        getSavedState();
    }, []);

    const elements = useMemo<CandidateRecordDashboardElementDefinition[]>(() => {
        if (!fetchedSavedState) return [];
        if (lastSavedState !== null) {
            const elements = GetPanelDefinitionsFromPanelModels(lastSavedState);
            return elements ;
        }
        return DefaultCandidateRecordDashboardElements;
    }, [lastSavedState, fetchedSavedState]);

    const candidateNationality = useMemo(() => {
        if (candidate && candidate.nationalityID) {
            const nat = NationalityList.find(n => n.id === candidate.nationalityID);
            if (nat) return nat.name;
        }
        return '';
    }, [candidate]);

    const onEditableUdfChanged = useCallback(async (field: string, value: string) => {
        loadingHandler && loadingHandler(true);
        const parsedNumber = +field.slice(11);
        if (isNaN(parsedNumber)) return;

        const res = await UpdateCandidateCustomField(candidateId, parsedNumber, value, errorHandler);
        if (res) onEditableCustomFieldChange && onEditableCustomFieldChange(parsedNumber, value);

        loadingHandler && loadingHandler(false);
    }, [candidateId, errorHandler, loadingHandler, onEditableCustomFieldChange]);

    const renderElement = useCallback((id: string, type: CandidateRecordDashboardElementType) => {
        if (candidate) {
            switch (type) {
                case 'Divider': return <Box pt="20px"><Divider component="div" /></Box>;
                case 'Spacer': return <></>;
                case 'CandidateInfo': return (
                    <RecordAvatarComponent
                        entityId={3}
                        primaryText={candidate.fullName}
                        secondaryText={""}
                        photoUrl={candidate.photoUrl}
                    />);
                case 'CandidateId': return <SingleFieldElement useEllipsisForLongValues fieldTitle="ID" fieldValue={candidate.id.toString()} />
                case 'CandidateName': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Name" fieldValue={candidate.fullName} />
                case 'CandidateGender': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Gender" fieldValue={getCandidateGenderName(candidate.genderID)} />
                case 'CandidateAvailable': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Available" fieldValue={candidate.availabilityDate} format="date" />
                case 'CandidateNotice': 
                    const weeks = candidate.weeksNotice ? candidate.weeksNotice : 0;
                    return <SingleFieldElement useEllipsisForLongValues fieldTitle="Notice" fieldValue={weeks > 0 ? `${weeks} week(s)` : ''} />
                case 'CandidateStatus': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Status" fieldValue={getCandidateJobSeekingStatusName(candidate.jobSeekingStatusID)} />
                case 'CandidateDateOfBirth': return <SingleFieldElement useEllipsisForLongValues fieldTitle="DOB" fieldValue={candidate.dateOfBirth} format="date" />
                case 'CandidateNationality': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Nationality" fieldValue={candidateNationality} />
                case 'CandidateDivisions': return (
                    <SingleFieldElement fieldTitle="Divisions" format="custom">
                        {divisionNames.map(d => (
                            <Chip key={d} size="small" label={d} sx={{ mr: '5px' }} />
                        ))}
                    </SingleFieldElement>
                );
                case 'CandidateEmail1': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Email 1" fieldValue={candidate.email1} format="email-link" href={candidate.email1} />
                case 'CandidateEmail2': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Email 2" fieldValue={candidate.email2} format="email-link" href={candidate.email2} />
                case 'CandidatePayrollEmail': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Payroll Email" fieldValue={candidate.payrollEmail} format="email-link" href={candidate.payrollEmail} />
                case 'CandidateWebsite': 
                    const website = candidate.website;
                    const href = website && !website.startsWith('http') ? 'https://' + website : website;
                    return <SingleFieldElement useEllipsisForLongValues fieldTitle='Website' href={href} fieldValue={website} format="external-link" />;
                case 'CandidateLocation': return (
                    <ViewCandidateLocationElement
                        address1={candidate.address1 ?? ''}
                        address2={candidate.address2 ?? ''}
                        countryName={candidate.countryName}
                        postcode={candidate.postcode ?? ''}
                        state={candidate.state ?? ''}
                        suburb={candidate.suburb ?? ''}
                    />
                );
                case 'CandidateMobile': 
                    return <SingleFieldElement useEllipsisForLongValues fieldTitle="Mobile" fieldValue={candidate.mobile} href={candidate.mobile_Standardised} format="phone-link" />;
                case 'CandidatePhone': 
                    return <SingleFieldElement useEllipsisForLongValues fieldTitle="Phone" fieldValue={candidate.home} href={candidate.home_Standardised} format="phone-link" />;
                case 'CandidateWork': 
                    return <SingleFieldElement useEllipsisForLongValues fieldTitle="Work" fieldValue={candidate.work} href={candidate.work_Standardised} format="phone-link" />;
                case 'CandidateOptOut': return <SingleFieldElement useEllipsisForLongValues fieldTitle="OptOut" fieldValue={candidate.optOut ? 'Yes' : 'No'} />
                case 'CandidateSummary': return <MultiLineTextElement title="Summary" content={candidate.summary} />;
                case 'CandidatePreferredJobType': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Preferred Job Type" fieldValue={candidate.preferredJobTypeName} />
                case 'CandidatePreferredRate': 
                    const numericRate = candidate.preferredRate;
                    const rateUnits = candidate.preferredRateUnits === 'Unrecognised' || numericRate === 0 ? '' : candidate.preferredRateUnits;
                    const preferredRate = numericRate > 0 ? formatNumber(numericRate) : '';
                    return <SingleFieldElement useEllipsisForLongValues fieldTitle="Preferred Rate" fieldValue={`${preferredRate} ${rateUnits}`} />
                case 'CandidatePreferredSalary':
                    const numericSalary = candidate.preferredSalary;
                    const salaryUnits = candidate.preferredSalaryUnits === 'Unrecognised' || numericSalary === 0 ? '' : candidate.preferredSalaryUnits;
                    const preferredSalary = numericSalary > 0 ? formatNumber(numericSalary) : '';
                    return <SingleFieldElement useEllipsisForLongValues fieldTitle="Preferred Salary" fieldValue={`${preferredSalary} ${salaryUnits}`} />
                case 'CandidateLinkedIn': 
                    const linkedIn = candidate.linkedInUrl;
                    const lhref = linkedIn && !linkedIn.startsWith('http') ? 'https://' + linkedIn : linkedIn;
                    return <SingleFieldElement useEllipsisForLongValues fieldTitle='LinkedIn' href={lhref} fieldValue={linkedIn} format="external-link" />;
                case 'CandidateTwitter': 
                    const twitter = candidate.twitterUrl;
                    const thref = twitter && !twitter.startsWith('http') ? 'https://' + twitter : twitter;
                    return <SingleFieldElement useEllipsisForLongValues fieldTitle='Twitter' href={thref} fieldValue={twitter} format="external-link" />;
                default: 
                    if (type.startsWith('CustomField')) {
                        const settings = customFieldsSettingsMap[type];
                        if (settings) {
                            const title = settings.title;
                            let predefinedValues = settings.usePredefinedValues && settings.values ? settings.values : null;
                            const { field, format } = UdfCandidateFieldMapObj[type as CustomFieldType];
                            const udfValue = candidate[field];
                            const isValueUnset = udfValue === null || udfValue === undefined;
                            if (format === 'string' && predefinedValues && id.includes('_Editable_') && settings.usePredefinedValues && (typeof udfValue === 'string' || isValueUnset)) {
                                return (
                                    <EditableSingleFieldElement format="select" fieldTitle={title} fieldValue={udfValue ?? ''} fieldName={field} onChangeHandler={({target}) => onEditableUdfChanged(type, target.value)}>
                                        <MenuItem value="">None</MenuItem>
                                        {predefinedValues.map(v => <MenuItem key={v.value} value={v.value}>{v.value + (v.isDefault ? ' (Default)' : '')}</MenuItem>)}
                                    </EditableSingleFieldElement>
                                );
                            }
                            if (format === 'string' || format === 'date' || format === 'datetime') {
                                return <SingleFieldElement isMultiLine={settings.isMultiLine} format={format} fieldTitle={title} fieldValue={udfValue ? udfValue.toString() : null} />;
                            }
                            if (format === 'number') {
                                const numValue = udfValue === undefined || udfValue === null ? '' : udfValue.toString();
                                return <SingleFieldElement fieldTitle={title} fieldValue={numValue} />;
                            }
                        }
                        else return <SingleFieldElement fieldTitle={type} fieldValue="[ Disabled Custom Field ]" />
                    }
                    return <div>{id}</div>;
            }
        }
    }, [candidate, candidateNationality, divisionNames, customFieldsSettingsMap, onEditableUdfChanged]);

    const layoutResizeStopHandler = useCallback(() => {
        setScreenResizedControl(prev => !prev);
    }, []);

    useEffect(() => {
        if (screenResizedControl) {}
        const api = layoutRef.current;
        if (api && fetchedSavedState) {
            const refreshTimeout = setTimeout(() => {
                api.refresh();
                api.refresh();
            }, 250);
            return () => clearTimeout(refreshTimeout);
        }
    }, [fetchedSavedState, screenResizedControl]);

    const renderLayout = useCallback(() => {
        if (!fetchedSavedState) return <></>;

        const mediaQuery = mediaQueryMaxWidth ? `max-width: ${mediaQueryMaxWidth}` : undefined;
        return (
            <DashboardLayoutComponent
                cellSpacing={cellSpacing}
                columns={columns}
                cellAspectRatio={30 / 2}
                resizeStop={ layoutResizeStopHandler }
                allowDragging={false}
                allowResizing={false}
                ref={l => layoutRef.current = l}
                mediaQuery={mediaQuery}
            >
                {elements.map(e => (
                    <PanelWrapper
                        key={e.id}
                        id={e.id}
                        col={e.col}
                        row={e.row}
                        sizeX={e.sizeX}
                        sizeY={e.sizeY}
                        minSizeX={e.minSizeX}
                        minSizeY={e.minSizeY}
                        maxSizeX={e.maxSizeX}
                        maxSizeY={e.maxSizeY}
                        resizeControl={screenResizedControl}
                        resizeIconColor={theme.palette.text.disabled}
                        gapX={gapX}
                        gapY={gapY}
                        unitWidth={unitWidth}
                        unitHeight={unitHeight}
                    >
                        {renderElement(e.id, e.type)}
                    </PanelWrapper>
                ))}
            </DashboardLayoutComponent>
        );
    }, [elements, fetchedSavedState, theme.palette.text.disabled, screenResizedControl, layoutResizeStopHandler, renderElement]);

    return (
        <>
            <Box p="10px" height="100%">
                <div className="control-section">
                    { renderLayout() }
                </div>
            </Box>
        </>
    );
}