import React, { useCallback, useEffect, useMemo, useState } from "react";
import { GetCandidateJobs } from "services/CandidatesService";
import { CandidateJob } from "common/models/Jobs/CandidateJob";
import { GridColDef, GridColumnVisibilityModel, GridRenderCellParams, GridValueGetter, useGridApiRef } from "@mui/x-data-grid-premium";
import { PreviewEntityType } from "common/models/Previews/Previews";
import moment from "moment";
import { Link } from "react-router-dom";
import CandidateRatingIcon from "../Candidates/CandidateRatingIcon";
import { RatingReason } from "common/models/Configuration/Jobs";
import { GetCachedRatingReasons } from "services/ConfigurationService";
import GridWithStateWrapper from "../GridWidthStateWrapper";
import { UpdateJobTrackingComments } from "services/JobsService";
import PreviewLoaderComponent from "../Previews/PreviewLoader";
import TagsManagementDialog from "../Dialogs/TagsManagementDialog";
import { GetMySettings } from "services/UsersService";
import { defaultGridCellStyle } from "util/GridUtils";

interface Props {
    candidateId: number,
    gridName: string,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
    successHandler?: (message: string) => void,
}

const linkStyle: React.CSSProperties = { color: 'inherit', textDecoration: 'underline' };
const entityTypeId = 3;

const compareRatingReasons = (a: RatingReason, b: RatingReason) => {
    if (b.value < a.value) return 1;
    if (b.value > a.value) return -1;
    return 0;
};

const defaultHiddenCols: GridColumnVisibilityModel = {
    'positionPostingsID': false,
};

export default function CandidateJobsGridComponent({ candidateId, gridName, loadingHandler, errorHandler, successHandler }: Props) {
    const [rows, setRows] = useState<CandidateJob[]>([]);
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    const [showTagsPrevewNoDelay, setShowTagsPreviewNoDelay] = useState(false);
    const [previewType, setPreviewType] = useState<PreviewEntityType | ''>('');
    const [previewRecordId, setPreviewRecordId] = useState(0);
    const [isPreviewTags, setIsPreviewTags] = useState(false);
    const [previewRecordName, setPreviewRecordName] = useState('');
    const [taggingRecords, setTaggingRecords] = useState<number[]>([]);
    const [greenReasons, setGreenReasons] = useState<RatingReason[]>([]);
    const [yellowReasons, setYellowReasons] = useState<RatingReason[]>([]);
    const [blueReasons, setBlueReasons] = useState<RatingReason[]>([]);
    const [redReasons, setRedReasons] = useState<RatingReason[]>([]);
    const [defaultRejectionTemplateId, setDefaultRejectionTemplateId] = useState(0);
    const apiRef = useGridApiRef();

    useEffect(() => {
        const getSettings = async () => {
            const mySettings = await GetMySettings();
            if (mySettings) {
                const templateSetting = mySettings.find(s => s.type === 'DefaultRejectionEmailTemplateID');
                if (templateSetting && templateSetting.value !== '0') setDefaultRejectionTemplateId(+templateSetting.value);
            }
        };
        getSettings();
    }, []);

    useEffect(() => {
        const getRatingReasons = async () => {
            let green: RatingReason[] = [];
            let yellow: RatingReason[] = [];
            let blue: RatingReason[] = [];
            let red: RatingReason[] = [];
            const ratingReasons = await GetCachedRatingReasons(false);
            if (ratingReasons) {
                for (let i = 0; i < ratingReasons.length; i++) {
                    const rr = ratingReasons[i];
                    if (rr.actionType === 4) green.push(rr);
                    else if(rr.actionType === 2) yellow.push(rr);
                    else if(rr.actionType === 1) blue.push(rr);
                    else if(rr.actionType === 0) red.push(rr);
                }
                green.sort(compareRatingReasons);
                yellow.sort(compareRatingReasons);
                blue.sort(compareRatingReasons);
                red.sort(compareRatingReasons);
                setGreenReasons(green);
                setYellowReasons(yellow);
                setBlueReasons(blue);
                setRedReasons(red);
            }
        };
        getRatingReasons();
    }, []);
    
    const getData = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        const res = await GetCandidateJobs(candidateId, errorHandler);
        if (res) setRows(res);
        loadingHandler && loadingHandler(false);
    }, [candidateId, loadingHandler, errorHandler]);

    useEffect(() => {
        getData();
    }, [getData]);

    const ratingChangedHandler = useCallback((id: number, newRatingId: number, reason: string) => {
        const api = apiRef.current;
        if (api) {
            api.updateRows([{ positionPostingsID: id, actionID: newRatingId, ratingReason: reason }]);
        }
    }, [apiRef]);

    const columns = useMemo<GridColDef[]>(() => {
        const handlePreviewHover = (type: PreviewEntityType | '', id: number, isTags: boolean = false, recordName: string = '', noDelay: boolean = false) => {
            setPreviewType(type);
            setPreviewRecordId(id);
            setIsPreviewTags(isTags);
            setPreviewRecordName(recordName);
            setIsPreviewOpen(true);
            setShowTagsPreviewNoDelay(noDelay);
        };

        const handlePreviewClose = () => {
            setIsPreviewOpen(false);
            setShowTagsPreviewNoDelay(false);
        };

        const dateValueGetter: GridValueGetter<CandidateJob, any, undefined, string> = (value) => {
            if (value) {
                const m = moment(value);
                if (m.isValid() && m.get('year') > 1) {
                    return m.toDate();
                }
            }
        };

        const dateRenderer = (params: GridRenderCellParams) => {
            if (params.value) {
                return <div style={defaultGridCellStyle}>{moment(params.value).format('DD MMM YYYY')}</div>;
            }
            return <div style={defaultGridCellStyle}>Never</div>;
        };

        const minHeightCellRenderer = (params: GridRenderCellParams) => {
            return (
                <div style={defaultGridCellStyle}>
                    <span>{params.value}</span>
                </div>
            );
        };

        const linkToJobRenderer = (params: GridRenderCellParams) => {
            if (params.value) {
                return (
                    <div style={defaultGridCellStyle}>
                        <Link to={`/jobs/${params.row.jobID}`} style={ linkStyle } onMouseEnter={ () => handlePreviewHover('job', params.row.jobID) } onMouseLeave={ handlePreviewClose } >{params.value}</Link>
                    </div>
                );
            }
        }

        const linkToClientRenderer = (params: GridRenderCellParams) => {
            if (params.row.clientID) {
                return (
                    <div style={defaultGridCellStyle}>
                        <Link to={`/clients/${params.row.clientID}`} style={ linkStyle } onMouseEnter={ () => handlePreviewHover('client', params.row.clientID) } onMouseLeave={ handlePreviewClose } >{params.value}</Link>
                    </div>
                );
            }
            return <div style={defaultGridCellStyle}>{params.value}</div>;
        }

        const ratingIdRenderer = (params: GridRenderCellParams) => {
            const jobId = params.row.jobID;
            const applicationId = params.row.applicationID;
            return (
                <div style={defaultGridCellStyle}>
                    <CandidateRatingIcon
                        size={30}
                        candidateId={candidateId}
                        jobId={jobId}
                        actionId={params.value}
                        useRatingReasonsProps
                        greenReasonsProp={greenReasons}
                        yellowReasonsProp={yellowReasons}
                        blueReasonsProp={blueReasons}
                        redReasonsProp={redReasons}
                        defaultRejectionTemplateId={defaultRejectionTemplateId}
                        applicationId={applicationId}
                        onActionChangeHandler={ (rId, rr) => ratingChangedHandler(+params.id, rId, rr)}
                        horizontal
                        errorHandler={errorHandler}
                        loadingHandler={loadingHandler}
                        successHandler={successHandler}
                    />
                </div>
            );
        };
        
        return [
            { headerName: 'ID', field: 'positionPostingsID', width: 75, renderCell: minHeightCellRenderer },
            { headerName: 'Consultant', field: 'consultant', cellsalign: 'center', align: 'center', headerAlign: 'center', width: 150, renderCell: minHeightCellRenderer },
            { headerName: 'Job ID', field: 'jobReference', cellsalign: 'center', align: 'center', headerAlign: 'center', width: 100, renderCell: linkToJobRenderer },
            { headerName: 'Job Title', field: 'title', width: 300, renderCell: linkToJobRenderer },
            { headerName: 'Location', field: 'location', width: 200, renderCell: minHeightCellRenderer },
            { headerName: 'Client', field: 'client', width: 300, renderCell: linkToClientRenderer },
            { headerName: 'Job Type', field: 'employmentType', cellsalign: 'center', align: 'center', headerAlign: 'center', width: 100, renderCell: minHeightCellRenderer },
            { headerName: 'Rating', field: 'actionID', cellsalign: 'center', align: 'center', headerAlign: 'center', width: 50, renderCell: ratingIdRenderer },
            { headerName: 'Source', field: 'source', cellsalign: 'center', align: 'center', headerAlign: 'center', width: 150, renderCell: minHeightCellRenderer },
            { headerName: 'Rating Reason', field: 'ratingReason', cellsalign: 'left', align: 'center', headerAlign: 'center', width: 150, renderCell: minHeightCellRenderer },
            { headerName: 'Workflow Status', field: 'currentStatus', cellsalign: 'center', align: 'center', headerAlign: 'center', width: 150, renderCell: minHeightCellRenderer },
            { headerName: 'Furthest Status', field: 'furthestStatus', cellsalign: 'center', align: 'center', headerAlign: 'center', width: 150, renderCell: minHeightCellRenderer },
            { headerName: 'Last Updated', field: 'statusUpdatedDate', cellsalign: 'center', align: 'center', headerAlign: 'center', width: 110, valueGetter: dateValueGetter, renderCell: dateRenderer, type: 'date' },
            { headerName: 'Comments', field: 'comments', width: 200, editable: true, renderCell: minHeightCellRenderer }
        ];
    }, [blueReasons, candidateId, defaultRejectionTemplateId, errorHandler, greenReasons, loadingHandler, ratingChangedHandler, redReasons, successHandler, yellowReasons]);

    const processRowUpdate = useCallback(async (newRow: CandidateJob, oldRow: CandidateJob) => {
        if (newRow.comments !== oldRow.comments) {
            loadingHandler && loadingHandler(true);
            await UpdateJobTrackingComments(newRow.positionPostingsID, newRow.comments, errorHandler);
            loadingHandler && loadingHandler(false);
        }
        return newRow;
    }, [errorHandler, loadingHandler]);

    return (
        <>
            <PreviewLoaderComponent
                open={isPreviewOpen}
                entityType={previewType}
                recordId={previewRecordId}
                isTagsPreview={isPreviewTags}
                showDelayMs={showTagsPrevewNoDelay ? 0 : undefined}
                titleOverride={previewRecordName}
            />
            <TagsManagementDialog
                open={ taggingRecords.length > 0 }
                closeHandler={ () => setTaggingRecords([]) }
                entityId={ entityTypeId }
                recordIds={ taggingRecords }
                loadingHandler={ loadingHandler }
                errorHandler={ errorHandler }
            />
            <GridWithStateWrapper
                gridName={gridName}
                defaultViewModel={defaultHiddenCols}
                rows={rows}
                columns={columns}
                apiRef={apiRef}
                density="compact"
                disableRowSelectionOnClick
                processRowUpdate={ processRowUpdate }
                getRowId={r => r.positionPostingsID}
                pagination
                pageSizeOptions={[100,250,500,1000]}
                getRowHeight={() => 'auto'}
            />
        </>
    );
}