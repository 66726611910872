import React, { useCallback, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import KeyboardControlKeyIcon from '@mui/icons-material/KeyboardControlKey';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

import { ScreeningQuestionnaireQuestion, ScreeningQuestionnaireQuestionAnswer } from "common/models/Configuration/AdvertScreeningQuestionnaire";
import ScreeningQuestionnaireAnswerComponent from "./ScreeningQuestionnaireAnswer";
import { Collapse } from "@mui/material";
import RWTextFieldComponent from "components/RWTextFieldComponent";

interface Props {
    index: number,
    isLast: boolean,
    question: ScreeningQuestionnaireQuestion,
    rejectionOptions: JSX.Element[],
    onChange: (index: number, question: ScreeningQuestionnaireQuestion) => void,
    moveUp: (index: number) => void,
    moveDown: (index: number, isLast: boolean) => void,
    onDelete: (index: number) => void
}

export default function ScreeningQuestionnaireQuestionComponent({ index, isLast, question, rejectionOptions, onChange, moveUp, moveDown, onDelete }: Props) {
    const [isCollapsed, setIsCollapsed] = useState(false);

    const stringChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        onChange(index, { ...question, [name]: value });
    };

    const numberChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        onChange(index, { ...question, [name]: +value });
    };

    const addAnswerHandler = () => {
        const defaultAnswer: ScreeningQuestionnaireQuestionAnswer = { Text: '', Knockout: false };
        if(question.answers) {
            let tmp = [...question.answers];
            tmp.push(defaultAnswer);
            onChange(index, { ...question, answers: tmp });
            return;
        }

        onChange(index, { ...question, answers: [defaultAnswer] });
    }

    const answerChangeHandler = useCallback((aIndex: number, answer: ScreeningQuestionnaireQuestionAnswer) => {
        if(question.answers) {
            let tmp = [...question.answers];
            tmp[aIndex] = answer;
            onChange(index, { ...question, answers: tmp });
        }
    }, [question, index, onChange]);

    const answerDeleteHandler = useCallback((aIndex: number) => {
        if(question.answers) {
            let tmp = [...question.answers];
            tmp.splice(aIndex, 1);
            onChange(index, { ...question, answers: tmp });
        }
    }, [question, index, onChange]);

    return (
        <Paper variant="outlined" sx={{ p: 2 }}>
            <Box display="flex" alignItems="center">
                <Typography component="span" paddingRight="10px"># {index + 1}</Typography>
                <IconButton size="small" disabled={index === 0} onClick={() => moveUp(index)}>
                    <ArrowUpwardIcon />
                </IconButton>
                <IconButton size="small" disabled={isLast} onClick={() => moveDown(index, isLast)}>
                    <ArrowDownwardIcon />
                </IconButton>
                <IconButton size="small" onClick={() => setIsCollapsed(prev => !prev)}>
                    { isCollapsed ?  <KeyboardArrowDownIcon /> : <KeyboardControlKeyIcon /> }
                </IconButton>

                {isCollapsed && <Typography component="span" px="10px">{question.question}</Typography> }


                <IconButton size="small" onClick={() => onDelete(index)} color="error" sx={{ marginLeft: 'auto' }}>
                    <DeleteIcon />
                </IconButton>
            </Box>
            <Collapse in={!isCollapsed}>
                <Stack spacing={2} paddingTop={2}>
                    <RWTextFieldComponent
                        label="Question"
                        name="question"
                        value={question.question}
                        onChange={stringChangeHandler}
                        /> 
                    <TextField
                        select
                        label="Question Type"
                        name="typeId"
                        value={question.typeId.toString()}
                        onChange={numberChangeHandler}
                    >
                        <MenuItem value="2">Multi Select</MenuItem>
                        <MenuItem value="3">Single Select</MenuItem>
                        <MenuItem value="1">Free Text</MenuItem>
                    </TextField>
                    {question.typeId !== 1 &&
                        <>
                            <TextField
                                select
                                label="Rejection Template"
                                name="rejectionEmailTemplateId"
                                value={question.rejectionEmailTemplateId.toString()}
                                onChange={numberChangeHandler}
                            >
                                {rejectionOptions}
                            </TextField>
                            <Box>
                                <Paper square sx={{ p: 2 }}>
                                    <Box display="flex" alignItems="center" paddingBottom={2}>
                                        <Typography component="span" paddingRight="10px">Answers</Typography>
                                        <Button color="success" sx={{ marginLeft: 'auto' }} onClick={addAnswerHandler} endIcon={<AddIcon />}>Add Answer Value</Button>
                                    </Box>
                                    <Stack spacing={1.5}>
                                        {question.answers && question.answers.map((answer, i) => (
                                            <ScreeningQuestionnaireAnswerComponent
                                                key={i}
                                                index={i}
                                                answer={answer}
                                                onChange={answerChangeHandler}
                                                onDelete={answerDeleteHandler}
                                            />
                                        ))}
                                    </Stack>
                                </Paper>
                            </Box>
                        </>
                    }
                </Stack>
            </Collapse>
        </Paper>
    );
}