import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { DataGrid } from "@mui/x-data-grid/DataGrid";
import { GridColDef } from "@mui/x-data-grid/models/colDef";
import { UnlinkCandidateData } from "common/models/Candidates/Candidate";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { UnlinkCandidatesFromJob } from "services/JobsService";

type Props = {
    open: boolean,
    data: UnlinkCandidateData[],
    jobId: number,
    closeHandler: () => void,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
    successHandler?: (message: string) => void
};

type ErrorData = {
    id: number,
    name: string,
    reason: string,
};

const colsDef: GridColDef[] = [
    { field: 'name', width: 200, headerName: 'Candidate' },
    { field: 'reason', flex: 1, headerName: 'Reason' },
];

export default function UnlinkCandidatesFromJobDialog({ open, data, jobId, closeHandler, loadingHandler, errorHandler, successHandler }: Props) {
    const [validCandidateIds, setValidCandidateIds] = useState<number[]>([]);
    const [errorData, setErrorData] = useState<ErrorData[]>([]);

    useEffect(() => {
        if (open) {
            let errors: ErrorData[] = [];
            let valids: number[] = [];
            for (let i = 0; i < data.length; i++) {
                const d = data[i];
                if (d.source === 'Float') {
                    errors.push({ id: d.candidateId, name: d.candidateName, reason: "Float Conversion" });
                    continue;
                }
                if (d.applicationId !== 0) {
                    errors.push({ id: d.candidateId, name: d.candidateName, reason: "Candidate applied for the role" });
                    continue;
                }
                if (d.furthestStatus !== 'Linked to Job') {
                    errors.push({ id: d.candidateId, name: d.candidateName, reason: "Workflow steps performed" });
                    continue;
                }

                valids.push(d.candidateId);
            }

            setValidCandidateIds(valids);
            setErrorData(errors);
        }
        else {
            setValidCandidateIds([]);
            setErrorData([]);
        }
    }, [data, open]);

    const unlinkFromJobCallback = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        const res = await UnlinkCandidatesFromJob(jobId, validCandidateIds, errorHandler);
        if (res) {
            successHandler && successHandler('Selected Candidate(s) Unlinked from Job');
            closeHandler();
        }
        loadingHandler && loadingHandler(false);
    }, [closeHandler, errorHandler, jobId, loadingHandler, successHandler, validCandidateIds]);

    const errorGrid = useMemo(() => {
        if (open && errorData.length > 0) {
            return (
                <div style={{ height: '200px' }}>
                    <DataGrid density="compact" rows={errorData} columns={colsDef} disableColumnMenu disableColumnFilter hideFooter />
                </div>
            );
        }
    }, [errorData, open]);


    return (
        <Dialog open={open} fullWidth>
            <DialogTitle>Unlink From Job</DialogTitle>
            <DialogContent>
                {errorData.length === data.length &&
                    <DialogContentText>None of candidates can be unlinked for the following reasons:</DialogContentText>
                }
                {errorData.length === 0 && validCandidateIds.length === 1 &&
                    <DialogContentText>Are you sure you want to unlink this candidate form the job?</DialogContentText>
                }
                {errorData.length === 0 && validCandidateIds.length > 1 &&
                    <DialogContentText>Are you sure you want to unlink these <b>{validCandidateIds.length}</b> candidates form the job?</DialogContentText>
                }
                {errorData.length > 0 && validCandidateIds.length > 1 &&
                    <DialogContentText><b>{errorData.length}</b> of the <b>{data.length}</b> selected candidates cannot be unlinked for the following reasons:</DialogContentText>
                }
                {errorGrid}
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="error" onClick={ closeHandler }>Cancel</Button>
                <Button variant="contained" color="success" disabled={ jobId === 0 || validCandidateIds.length === 0 } onClick={ unlinkFromJobCallback }>Continue</Button>
            </DialogActions>
        </Dialog>
    );
}