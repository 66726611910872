import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import moment from "moment";
import React, { useCallback, useState } from "react";
import * as XLSX from "xlsx";
import { ReportDataProcessDateFields } from "util/ReportUtils";
import MultipleDivisionsPicker from "components/Pickers/MultipleDivisionPicker";
import { NameIdObj } from "common/models/GenericTypes";
import MultipleNumericValuesPicker from "components/Pickers/MultipleNumericValuesPicker";
import { GetCandidatesDetailedReportData } from "services/CandidatesService";

interface Props {
    description: string,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void
}

const jobSeekingOptions: NameIdObj[] = [
    { id: 0, name: 'All'},
    { id: 3, name: 'Not Currently Looking'},
    { id: 2, name: 'Open To Opportunities'},
    { id: 1, name: 'Seriously Looking'},
];

const divissionAllOptionArray: NameIdObj[] = [{ id: 0, name: 'All' }];

export default function DetailedCandidatesReport({ description, loadingHandler, errorHandler }: Props) {
    const [divisions, setDivisions] = useState<number[]>([0]);
    const [jobSeekingFilter, setJobSeekingFilter] = useState<number[]>([0]);

    const getDataCallback = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        const res = await GetCandidatesDetailedReportData(divisions, jobSeekingFilter, errorHandler);
        if (res) {
            ReportDataProcessDateFields(res as any[]);

            const m = moment();
            const filename = `CandidatesDetailed${m.format('YYYYMMDDhhmmss')}.xlsx`;
            const ws = XLSX.utils.json_to_sheet(res);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws);
            XLSX.writeFile(wb, filename);
        }
        loadingHandler && loadingHandler(false);
    }, [divisions, errorHandler, jobSeekingFilter, loadingHandler]);

    const onDivisionChange = useCallback((items: NameIdObj[]) => {
        const ids = items.map(i => i.id);
        setDivisions(ids);
    }, []);

    const onJobSeekingStatusChange = useCallback((items: NameIdObj[]) => {
        const ids = items.map(i => i.id);
        setJobSeekingFilter(ids);
    }, []);

    return (
        <>
            <Box display="flex" gap={2}>
                <Box flex="1 1 0">
                    <MultipleDivisionsPicker value={divisions} appendToStart={divissionAllOptionArray} onSelect={onDivisionChange}  />
                </Box>
                <Box flex="1 1 0">
                    <MultipleNumericValuesPicker label="Job Seeking Status" options={jobSeekingOptions} value={jobSeekingFilter} onSelect={onJobSeekingStatusChange} />
                </Box>
                <Button variant="contained" color="success" onClick={getDataCallback}>Run Report</Button>
            </Box>
            <Typography component="div" variant="h6" mt={2}>Report Description</Typography>
            <Typography component="div" variant="body2">{description}</Typography>
        </>
    );
}