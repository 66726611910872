import { AlertColor, AlertPropsColorOverrides } from "@mui/material/Alert";
import { OverridableStringUnion } from "@mui/types";
import React, { useMemo } from "react";
import Alert from "./Alert";
import Box from "@mui/material/Box";
import InfoIcon from '@mui/icons-material/Info';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import GradeIcon from '@mui/icons-material/Grade';
import parse from 'html-react-parser';

export type CallOutNotificationType = 'info' | 'success' | 'warning' | 'danger' | 'new-feature';

type Props = {
    title?: string,
    type?: CallOutNotificationType,
    content?: string,
    onClose?: (event: React.SyntheticEvent<Element, Event>) => void,
    isHtmlContent?: boolean
};

const TypeSeverityMap: Record<CallOutNotificationType, OverridableStringUnion<AlertColor, AlertPropsColorOverrides>> = {
    danger: 'error',
    "new-feature": 'primary',
    info: 'info',
    success: 'success',
    warning: 'warning'
};

export default function CallOutNotification({ title, type = 'success', content, onClose, isHtmlContent }: Props) {
    const icon = useMemo(() => {
        if (type === 'success') return <CheckCircleIcon />;
        if (type === 'info') return <InfoIcon />;
        if (type === 'danger') return <ErrorIcon />;
        if (type === 'warning') return <WarningIcon />;
        if (type === 'new-feature') return <GradeIcon />;
    }, [type]);

    const severity = useMemo(() => {
        return TypeSeverityMap[type];
    }, [type]);

    return (
        <Alert
            variant="standard"
            severity={severity}
            square
            onClose={onClose}
            icon={icon}
            sx={{ 
                boxShadow: "none",
                borderLeftColor: t => t.palette[severity].main,
                borderLeftWidth: '5px',
                borderLeftStyle: 'solid',
            }}
        >
            { Boolean(title) && <Box color={`${severity}.main`} fontWeight="bold">{title}</Box> }
            <Box sx={{ color: t => t.palette.text.primary }}>
                { isHtmlContent ?  parse(content ?? '')  : content }
            </Box>
        </Alert>
    );
}