import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { PanelModel } from "@syncfusion/ej2-layouts/src/dashboard-layout/dashboard-layout-model";
import { DashboardLayoutComponent } from "@syncfusion/ej2-react-layouts/src/dashboard-layout/dashboardlayout.component";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { Link, Navigate } from "react-router-dom";
import * as tinymce from 'tinymce';
import { CandidateList } from "common/models/Candidates/CandidateList";
import { Client } from "common/models/Clients";
import { CustomField, CustomFieldPredefinedValue } from "common/models/Configuration/CustomFields";
import { Contact, ContactOwner, MinContact } from "common/models/Contacts";
import { ContactRecordDashboardElementDefinition, ContactRecordDashboardElementType } from "common/models/Dashboard/EditLayout";
import { CustomFieldSettingsMap, CustomFieldType } from "common/models/ScreenLayouts/CustomFields";
import { Site } from "common/models/Site";
import useObjectStateWithChangeTracker from "hooks/UseObjectStateWithChangeTracker";
import useUnsavedChangesDialog from "hooks/UseUnsavedChangesDialog";
import { GetClientById } from "services/ClientsService";
import { GetCustomerSettingBySettingName } from "services/ConfigurationService";
import { CreateContact, GetContactById, GetContactOwners, SetContactOwners, UpdateContact } from "services/ContactsService";
import { GetCustomFieldsByEntity_OnlyActive, GetPredefinedValues } from "services/CustomFieldsService";
import { GetMyUser, GetSingleSetting } from "services/UsersService";
import { ContactToMinContact, DefaultMinContact, DefaultMinContactNoChanges } from "util/Definitions/Contacts";
import { ContactScreenLayoutSettings, DefaultContactRecordDashboardElements, GetPanelDefinitionsFromPanelModels } from "util/Definitions/ScreenLayouts/Contact";
import { IsValidCustomFieldValue, UdfContactFieldMapObj } from "util/Definitions/ScreenLayouts/CustomFields";
import PanelWrapper from "components/Dashboards/PanelWrapper";
import OwnersListDialog from "components/Dialogs/Dashboard/OwnersListDialog";
import CandidatePicker from "components/Pickers/CandidatePicker";
import ClientPicker from "components/Pickers/ClientPicker";
import ContactPicker from "components/Pickers/ContactPicker";
import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import EditableClientLocationElement from "../Clients/EditableClientLocationElement";
import EditableRichTextElement from "../EditableRichTextElement";
import EditableSingleFieldElement from "../EditableSingleFieldElement";
import SingleFieldElement from "../SingleFieldElement";
import { NameIdObj } from "common/models/GenericTypes";
import { GetCandidateById, GetCandidateWorkHistory } from "services/CandidatesService";
import RecordAvatarComponent from "components/Dashboards/Graphs/ClientAvatarComponent";
import MultipleDivisionsPicker from "components/Pickers/MultipleDivisionPicker";
import { RegexIsValidEmail } from "util/RegExUtils";
import RWTextFieldComponent from "components/RWTextFieldComponent";

interface Props {
    contactId?: number
    clientId?: number,
    candidateId?: number,
    defaultSummary?: string,
    setSummaryBar?: (sb: JSX.Element) => void,
    loadingHandler?: (isLoading: boolean) => void,
    successHandler?: (message: string) => void,
    errorHandler?: (message: string) => void,
    emailAddress?: string | null
}

interface CustomFieldWithPredefinedValues extends CustomField {
    values?: CustomFieldPredefinedValue[],
}

const validationErrorMessage = (validationResult: string) => {
    if (validationResult === 'ok') return '';
    if (validationResult === 'required') return 'Required';
    return 'Not Valid';
};

const emailValidation = (email: string) => {
    if (email === '' || email === null || email === undefined) return true;
    return RegexIsValidEmail(email);
};

const { unitWidth, unitHeight, gapX, gapY, columns, mediaQueryMaxWidth } = ContactScreenLayoutSettings;
const cellSpacing = [gapX, gapY];

export default function EditRecordScreenLayout({ contactId = 0, clientId = 0, candidateId = 0, emailAddress= null, defaultSummary = '', setSummaryBar, loadingHandler, successHandler, errorHandler }: Props) {
    const [isFetchingConfig, setIsFetchingConfig] = useState(false);
    const [isFetchingContact, setIsFetchingContact] = useState(false);
    const [fetchedConfig, setFetchedConfig] = useState(false);
    const [fetchedContact, setFetchedContact] = useState(false);
    const [showValidation, setShowValidation] = useState(false);
    const [fetchedLayoutConfig, setFetchedLayoutConfig] = useState(false);
    const [fetchingLayoutConfig, setFetchingLayoutConfig] = useState(false);
    const [layoutConfiguration, setLayoutConfiguration] = useState<PanelModel[] | null>(null);
    const [isOwnersDialogOpen, setIsOwnersDialogOpen] = useState(false);
    const [existingContact, setExistingContact] = useState<Contact | null>(null);
    const [owners, setOwners] = useState<ContactOwner[]>([]);
    const [initialOwnersString, setInitialOwnersString] = useState('');
    const [availableCustomFields, setAvailableCustomFields] = useState<CustomFieldWithPredefinedValues[]>([]);
    const [isEditorDirty, setIsEditorDirty] = useState(false);
    const [avatarImage, setAvatarImage] = useState<File | null>(null);
    const { state, init, change, updateInitial, hasChanges } = useObjectStateWithChangeTracker<MinContact>(DefaultMinContact, DefaultMinContactNoChanges);
    const editorRef = useRef<tinymce.Editor | null>(null);
    const [savedChanges, setSavedChanges] = useState(false);
    const [createdContactId, setCreatedContactId] = useState(0);
    const [screenResizedControl, setScreenResizedControl] = useState(false);
    const layoutRef = useRef<DashboardLayoutComponent | null>(null);
    const theme = useTheme();

    useEffect(() => {
        if(emailAddress) {
            change('email', emailAddress);
        }
    }, [change, emailAddress]);

    const hasOwnerChanges = useMemo(() => {
        const currentOwnersString = owners.map(o => o.recruiterID).sort().join('|');
        return currentOwnersString !== initialOwnersString;
    }, [initialOwnersString, owners]);

    useEffect(() => {
        const getExistingContact = async () => {
            setIsFetchingContact(true);
            const res = await GetContactById(contactId, errorHandler);
            if (res) setExistingContact(res);
            setFetchedContact(true);
            setIsFetchingContact(false);
        };
        contactId && getExistingContact();
    }, [contactId, errorHandler]);

    useEffect(() => {
        const getOwners = async () => {
            if (contactId) {
                const res = await GetContactOwners(contactId);
                if (res) {
                    const ownersString = res.map(o => o.recruiterID).sort().join('|');
                    setOwners(res);
                    setInitialOwnersString(ownersString);
                }
            }
        };
        contactId && getOwners();
    }, [contactId]);

    const addOwnerCallback = useCallback((u: NameIdObj | null) => {
        if (u) {
            setOwners(prev => ([...prev, {
                contactID: contactId, firstName: '', fullName: u.name, lastName: '', recruiterID: u.id, userName: ''
            }]));
        }
    }, [contactId]);

    const removeOwnerCallback = useCallback((userId: number) => {
        setOwners(prev => {
            let ows = [...prev];
            const index = ows.findIndex(o => o.recruiterID === userId);
            if (index !== -1) ows.splice(index, 1);
            return ows;
        });
    }, []);

    useEffect(() => {
        const getActiveFields = async () => {
            setIsFetchingConfig(true);
            const customFields = await GetCustomFieldsByEntity_OnlyActive(2);
            if (customFields) {
                let udfDefinitions: CustomFieldWithPredefinedValues[] = [];
                for (let i = 0; i < customFields.length; i++) {
                    const u = customFields[i];
                    if (!u.usePredefinedValues) {
                        udfDefinitions.push(u);
                        continue;
                    }

                    const vals = await GetPredefinedValues(u.id);
                    if (vals) udfDefinitions.push({ ...u, values: vals });
                    else udfDefinitions.push(u);
                }
                setAvailableCustomFields(udfDefinitions);
            }
            setFetchedConfig(true);
            setIsFetchingConfig(false);
        };
        getActiveFields();
    }, []);

    useEffect(() => {
        const setDefaultCreationValues = async () => {
            let tempState = {...DefaultMinContact};
            tempState.description = defaultSummary;

            if (candidateId) {
                const candidate = await GetCandidateById(candidateId);
                if (candidate) {
                    tempState.firstName = candidate.firstName;
                    tempState.surname = candidate.lastName;
                    tempState.email2 = candidate.email1;
                    tempState.mobile = candidate.mobile;
                    tempState.linkedCandidateID = candidate.id;
                    
                    const wh = await GetCandidateWorkHistory(candidateId, 1);
                    if (wh && wh.length > 0) tempState.jobTitle = wh[0].title;
                }
            }

            if (clientId) {
                const client = await GetClientById(clientId);
                if (client) {
                    tempState.clientID = clientId;
                    tempState.siteID = client.siteID;
                    tempState.address1 = client.address1 ?? '';
                    tempState.address2 = client.address2 ?? '';
                    tempState.address3 = client.address3 ?? '';
                    tempState.suburb = client.suburb ?? '';
                    tempState.state = client.state ?? '';
                    tempState.postcode = client.postcode ?? '';
                    tempState.countryName = client.countryName ?? '';
                }
            }
            const res = await GetSingleSetting('Division');
            if (res && res.value) tempState.divisions = res.value;
            if (availableCustomFields.length > 0) {
                for (let i = 0; i < availableCustomFields.length; i++) {
                    const f = availableCustomFields[i];
                    if (f.usePredefinedValues && f.values && f.values.length > 0) {
                        const defaultValue = f.values.find(v => v.isDefault);
                        if (defaultValue) {
                            const field = 'customField' + f.name.substring(13) as keyof MinContact;
                            (tempState as any)[field] = defaultValue.value;
                        }
                    }
                }
            }
            init(tempState);
        };
        contactId === 0 && fetchedConfig && setDefaultCreationValues();
    }, [contactId, clientId, candidateId, fetchedConfig, availableCustomFields, init, defaultSummary]);

    useEffect(() => {
        const getSavedState = async () => {
            setFetchingLayoutConfig(true);
            const elementsJson = await GetCustomerSettingBySettingName('ContactEditRecordDashboardLayoutElements');
            if (elementsJson) {
                const panels = JSON.parse(elementsJson) as PanelModel[];
                setLayoutConfiguration(panels);
            }
            setFetchingLayoutConfig(false);
            setFetchedLayoutConfig(true);
        };
        getSavedState();
    }, []);

    useEffect(() => loadingHandler && loadingHandler(isFetchingContact || isFetchingConfig || fetchingLayoutConfig), [isFetchingContact, isFetchingConfig, fetchingLayoutConfig, loadingHandler]);

    useEffect(() => {
        if (contactId && existingContact && fetchedContact && fetchedConfig) {
            const mc = ContactToMinContact(existingContact);
            init(mc);
            const api = editorRef.current;
            if (api) api.setContent(existingContact.description ?? '');
        }
    }, [contactId, existingContact, fetchedContact, fetchedConfig, init]);

    const saveChangesHandler = useCallback(async () => {
        if (!state.firstName.trim()) {
            setShowValidation(true);
            errorHandler && errorHandler("First Name can't be empty");
            return false;
        }

        if(Boolean(state.phone) && state.phone.length > 45) {
            setShowValidation(true);
            errorHandler && errorHandler("Phone can't have more than 45 characters");
            return false;
        }

        if(Boolean(state.mobile) && state.mobile.length > 45) {
            setShowValidation(true);
            errorHandler && errorHandler("Mobile can't have more than 45 characters");
            return false;
        }
        
        if (!emailValidation(state.email)) {
            setShowValidation(true);
            errorHandler && errorHandler("Email 1 not valid");
            return false;
        }
        
        if (!emailValidation(state.email2)) {
            setShowValidation(true);
            errorHandler && errorHandler("Email 2 not valid");
            return false;
        }

        let emptyNumericUdfs: Partial<MinContact> = { };
        let nullDateUdfs: Partial<MinContact> = { };
        for (let i = 0; i < availableCustomFields.length; i++) {
            const u = availableCustomFields[i];
            if (!u.editable) continue;
            const customFieldNumber = u.name.substring(13);
            const customFieldKey = "customField" + customFieldNumber as keyof MinContact;
            const { format } = UdfContactFieldMapObj['CustomField' + customFieldNumber as CustomFieldType];
            const value = state[customFieldKey] as string | number | undefined;

            const validation = IsValidCustomFieldValue(value, format, u.mandatory);

            if (validation === "required") {
                setShowValidation(true);
                errorHandler && errorHandler(`${u.agencyName} is required`);
                return false;
            }
            else if (validation === "invalid-number") {
                setShowValidation(true);
                errorHandler && errorHandler(`${u.agencyName} must have a valid numeric value`);
                return false;
            }
            else if (validation === "invalid-date") {
                setShowValidation(true);
                errorHandler && errorHandler(`${u.agencyName} must have a valid date value`);
                return false;
            }
            else if (validation === "range-date") {
                setShowValidation(true);
                errorHandler && errorHandler(`${u.agencyName} must be a date between 01-01-1753 and 31-12-9999`);
                return false;
            }

            if (format === "number" && (value === '' || value === null || value === undefined)) (emptyNumericUdfs[customFieldKey] as any) = undefined;
            if ((format === "date" || format === 'datetime') && !Boolean(value)) (nullDateUdfs[customFieldKey] as any) = '0001-01-01T00:00:00';
        }

        loadingHandler && loadingHandler(true);
        const editorApi = editorRef.current;
        const summaryContent = editorApi ? editorApi.getContent() : '';
        const ownerIds = owners.map(o => o.recruiterID);
        let cId = contactId;
        if (contactId === 0) {
            const res = await CreateContact({...state, description: summaryContent, ...emptyNumericUdfs, ...nullDateUdfs }, avatarImage, errorHandler);
            if (!res) {
                loadingHandler && loadingHandler(false);
                return false;
            }
            cId = res.value;
            if (ownerIds.length === 0 && cId !== 0) {
                const me = await GetMyUser();
                if (me && me.userID) {
                    await SetContactOwners(cId, [+me.userID]);
                }
            }
            setCreatedContactId(res.value);
        }
        else if (contactId && (hasChanges || isEditorDirty || avatarImage)) {
            const res = await UpdateContact({...state, description: summaryContent, ...emptyNumericUdfs, ...nullDateUdfs }, avatarImage, errorHandler);
            if (!res) {
                loadingHandler && loadingHandler(false);
                return false;
            }
        }
        if (hasOwnerChanges && cId) {
            const res = await SetContactOwners(cId, ownerIds);
            if (res) setInitialOwnersString(ownerIds.sort().join('|'));
        }
        updateInitial();
        setIsEditorDirty(false);
        editorApi && editorApi.setDirty(false);
        loadingHandler && loadingHandler(false);
        setAvatarImage(null);
        setSavedChanges(true);
        return true;
    }, [contactId, state, availableCustomFields, owners, hasChanges, hasOwnerChanges, isEditorDirty, avatarImage, updateInitial, loadingHandler, errorHandler]);

    useEffect(() => {
        const actionButton = <Button variant="contained" color="success" disabled={!hasChanges && !isEditorDirty && ! hasOwnerChanges && avatarImage === null} onClick={saveChangesHandler}>Save</Button>
        if (contactId === 0 && setSummaryBar) {
            const sb = <TitleAndActionSummaryBar title="Contacts > Create" browserTabTitle="Create > Contacts" action={actionButton} />;
            setSummaryBar(sb);
        }
        else if (contactId && setSummaryBar) {
            const goBackAction = <Link to={`/contacts/${contactId}`} style={{ textDecoration: 'none' }}><Button variant="contained" color="error" sx={{ mr: '5px' }}>Cancel</Button></Link>;
            const sb = <TitleAndActionSummaryBar title="Contacts > Edit" browserTabTitle="Edit > Contacts" action={<>{goBackAction}{actionButton}</>} />;
            setSummaryBar(sb);
        }
    }, [contactId, hasChanges, isEditorDirty, hasOwnerChanges, avatarImage, setSummaryBar, saveChangesHandler]);

    const onFirstNameFieldChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        change('firstName', value);
    }, [change]);

    const onLastNameFieldChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        change('surname', value);
    }, [change]);

    const onStringFieldChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        let val = value;
        if (name.startsWith('dtUDF') && value && !value.includes('T')) val += 'T00:00:00';
        change(name as keyof MinContact, val);
    }, [change]);

    const onDateFieldChange = useCallback((m: moment.Moment | null, fieldName: string) => {
        if(m && m.isValid()) {
            change(fieldName as keyof MinContact, m.format('YYYY-MM-DD'))
        }
        else if (m === null) change(fieldName as keyof MinContact, '');
    }, [change]);

    const onDateTimeFieldChange = useCallback((m: moment.Moment | null, fieldName: string) => {
        if(m && m.isValid()) {
            change(fieldName as keyof MinContact, m.format('YYYY-MM-DDTHH:mm'));
        }
        else if (m === null) change(fieldName as keyof MinContact, '');
    }, [change]);

    const onNumberFieldChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        const n = +value;
        if (isNaN(n) && value !== '') return;
        if (value.length > 11) return;

        const i = value.indexOf('.');
        if (value.length > 8 && i === -1) return;

        if(i >= 0) {
            const decimals = value.substring(i + 1);
            if (decimals.length > 2) {
                change(name as keyof MinContact, value.substring(0, i + 3));
                return;
            }
        }

        change(name as keyof MinContact, value);

    }, [change]);

    const onSiteChange = useCallback((site: Site | null) => {
        if (site) {
            change('siteID', site.id,);
            change('siteName', site.name,);
            change('address1', site.address1);
            change('address2', site.address2);
            change('address3', site.address3);
            change('suburb', site.suburb);
            change('state', site.state);
            change('postcode', site.postcode);
            change('countryName', site.countryName ?? '');
        }
        else {
            change('siteID', 0);
            change('siteName', '',);
            change('address1', '');
            change('address2', '');
            change('address3', '');
            change('suburb', '');
            change('state', '');
            change('postcode', '');
            change('countryName', '');
        }

    }, [change]);

    const divisionIds = useMemo<number[]>(() => {
        if (state.divisions) {
            const split = state.divisions.split(';');
            return split.map(d => +d);
        }
        return [];
    }, [state.divisions]);

    const onDivisionChange = useCallback((divisionIds: NameIdObj[]) => {
        const joined = divisionIds.map(d => d.id).join(';');
        change('divisions', joined);
    }, [change]);

    const onClientChange = useCallback((client: Client | null) => {
        change('clientID', client ? client.id : 0);
    }, [change]);

    const onManagerChange = useCallback((contact: Contact | null) => {
        change('reportsToID', contact ? contact.id : 0);
    }, [change]);

    const onCandidateChange = useCallback((candidate: CandidateList | null) => {
        change('linkedCandidateID', candidate ? candidate.id : 0);
    }, [change]);

    const { unsavedChangesDialog, hasBlockedRoute } = useUnsavedChangesDialog(hasChanges || isEditorDirty || hasOwnerChanges || avatarImage !== null, saveChangesHandler);

    const redirectContactId = useMemo(() => {
        if (contactId !== 0) return contactId;
        if (savedChanges && createdContactId) return createdContactId;
        return 0;
    }, [contactId, createdContactId, savedChanges]);

    const elements = useMemo<ContactRecordDashboardElementDefinition[]>(() => {
        if (!fetchedLayoutConfig) return [];
        if (layoutConfiguration !== null) {
            const elements = GetPanelDefinitionsFromPanelModels(layoutConfiguration);
            return elements ;
        }
        return DefaultContactRecordDashboardElements;
    }, [layoutConfiguration, fetchedLayoutConfig]);

    const removeUploadedImageHandler = useCallback(() => {
        change('photoName', '');
        change('photoUrl', '');
    }, [change]);

    const customFieldsSettingsMap = useMemo<CustomFieldSettingsMap>(() => {
        let obj: CustomFieldSettingsMap = {};
        availableCustomFields.forEach(u => {
            const customFieldNumber = u.name.substring(13);
            const key = 'CustomField' + customFieldNumber as CustomFieldType;
            obj[key] = {
                title: u.agencyName,
                isMultiLine: u.multiLine && !u.usePredefinedValues,
                isMandatory: u.mandatory,
                isEditable: u.editable,
                usePredefinedValues: u.usePredefinedValues,
                values: u.values
            }
        });
        return obj;
    }, [availableCustomFields]);

    const renderElement = useCallback((id: string, type: ContactRecordDashboardElementType) => {
        switch (type) {
            case 'Divider': return <Box pt="20px"><Divider component="div" /></Box>;
            case 'Spacer': return <></>;
            case 'ContactInfo': return (
                <RecordAvatarComponent
                    isEditable
                    entityId={2}
                    photoUrl={state.photoUrl}
                    imageFile={avatarImage}
                    imageSetHandler={setAvatarImage}
                    removeUploadedImageHandler={removeUploadedImageHandler}
                />
            );
            case 'ContactLocation': return (
                <EditableClientLocationElement
                    siteId={state.siteID}
                    clientId={state.clientID}
                    siteName={state.siteName ?? ''}
                    address1={state.address1 ?? ''}
                    address2={state.address2 ?? ''}
                    address3={state.address3 ?? ''}
                    suburb={state.suburb ?? ''}
                    state={state.state ?? ''}
                    postcode={state.postcode ?? ''}
                    country={state.countryName}
                    onSiteChange={onSiteChange}
                    errorHandler={errorHandler}
                    loadingHandler={loadingHandler}
                    successHandler={successHandler}
                />
            );
            case 'ContactSummary': return (
                <EditableRichTextElement
                    title="Summary"
                    content={state.description}
                    isDarkTheme={theme.palette.mode === 'dark'}
                    editorRef={editorRef}
                    editorDirtyHandler={ setIsEditorDirty }
                />
            );
            case 'ContactId': return <SingleFieldElement labelWidthPercent={30} fontSize="1rem" fieldTitle='ID' fieldValue={state.id} format="number" />;
            case 'ContactPhone':
                const phoneValue = state.phone;
                const isPhoneError = Boolean(state.phone) && state.phone.length > 45;
                return <EditableSingleFieldElement isError={showValidation && isPhoneError} errorMessage="Not Valid" fieldTitle='Phone' fieldValue={phoneValue} fieldName="phone" onChangeHandler={onStringFieldChange} />;
            case 'ContactMobile':
                const mobileValue = state.mobile;
                const isMobileError = Boolean(state.mobile) && state.mobile.length > 45;
                return <EditableSingleFieldElement isError={showValidation && isMobileError} errorMessage="Not Valid" fieldTitle='Mobile' fieldValue={mobileValue} fieldName="mobile" onChangeHandler={onStringFieldChange} />;
            case 'ContactLinkedIn':
                const linkedIn = state.linkedinURL;
                return <EditableSingleFieldElement fieldTitle='LinkedIn' fieldValue={linkedIn} fieldName="linkedinURL" onChangeHandler={onStringFieldChange} />;
            case 'ContactTwitter':
                const twitter = state.twitterURL;
                return <EditableSingleFieldElement fieldTitle='Twitter' fieldValue={twitter} fieldName="twitterURL" onChangeHandler={onStringFieldChange} />;
            case 'ContactOwner': return (
                <EditableSingleFieldElement format="custom" fieldTitle="Owners">
                    <Typography variant="body1" component="span" color="primary.main" sx={{ cursor: 'pointer' }} onClick={ () => setIsOwnersDialogOpen(true) }>Click to change Owners</Typography>
                </EditableSingleFieldElement>
            );
            case 'ContactStatus': return (
                <EditableSingleFieldElement format="select" fieldTitle='Status' fieldValue={state.statusID.toString()} fieldName="statusID" onChangeHandler={onNumberFieldChange} >
                    <MenuItem value="1">Unqualified</MenuItem>
                    <MenuItem value="2">Qualified</MenuItem>
                    <MenuItem value="3">Client</MenuItem>
                    <MenuItem value="4">Supplier</MenuItem>
                    <MenuItem value="7">Supplier - Sub Contractor</MenuItem>
                    <MenuItem value="5">Billing</MenuItem>
                    <MenuItem value="6">Archived</MenuItem>
                    <MenuItem value="8">Referee</MenuItem>
                    <MenuItem value="9">Prospect</MenuItem>
                </EditableSingleFieldElement>
            );
            case 'ContactDivision': return (
                <EditableSingleFieldElement format="custom" fieldTitle='Division' >
                    <MultipleDivisionsPicker value={divisionIds} onSelect={onDivisionChange} hideLabel variant="standard" limitVisible={2} />
                </EditableSingleFieldElement>
            );
            case 'ContactName':
                const isFirstNameError = showValidation && !state.firstName.trim();
                return (
                    <EditableSingleFieldElement isError={isFirstNameError} errorMessage="Required" format="custom" fieldTitle='Name'>
                        <Box display="flex">
                            <RWTextFieldComponent
                                isError={isFirstNameError}
                                helperText
                                value={state.firstName}
                                variant="standard"
                                placeholder="First Name"
                                sxOptions={{ flex: '1 1 0', pr: '5px' }}
                                onChange={ onFirstNameFieldChange }
                            />
                            <RWTextFieldComponent value={state.surname} variant="standard" placeholder="Surname" sxOptions={{ flex: '1 1 0', pl: '5px' }} onChange={ onLastNameFieldChange } />
                        </Box>
                    </EditableSingleFieldElement>
                );
            case 'ContactJobTitle': return <EditableSingleFieldElement fieldTitle='Job Title' fieldValue={state.jobTitle} fieldName="jobTitle" onChangeHandler={onStringFieldChange} />;
            case 'ContactClient': return (
                <EditableSingleFieldElement format="custom" fieldTitle='Client' >
                    <ClientPicker
                        hideLabel
                        variant="standard"
                        value={state.clientID}
                        onSelectCallback={ onClientChange }
                        errorHandler={errorHandler}
                        loadingHandler={loadingHandler}
                        successHandler={successHandler}
                    />
                </EditableSingleFieldElement>
            );
            case 'ContactManager': return (
                <EditableSingleFieldElement format="custom" fieldTitle='Manager' >
                    <ContactPicker
                        hideLabel
                        variant="standard"
                        value={state.reportsToID}
                        onSelectCallback={ onManagerChange }
                        errorHandler={errorHandler}
                        loadingHandler={loadingHandler}
                        successHandler={successHandler}
                    />
                </EditableSingleFieldElement>
            );
            case 'ContactEmail1':
                const email1Valid = showValidation ? emailValidation(state.email) : true;
                return <EditableSingleFieldElement fieldTitle='Email 1' fieldValue={state.email} fieldName="email" onChangeHandler={onStringFieldChange} isError={!email1Valid} />;
            case 'ContactEmail2': 
                const email2Valid = showValidation ? emailValidation(state.email2) : true;
                return <EditableSingleFieldElement fieldTitle='Email 2' fieldValue={state.email2} fieldName="email2" onChangeHandler={onStringFieldChange} isError={!email2Valid} />;
            case 'ContactOptOut': return <SingleFieldElement useEllipsisForLongValues labelWidthPercent={30} fontSize="1rem" fieldTitle="Opt Out" fieldValue={state.optOut ? 'Yes' : 'No'} />;
            case 'ContactCandidate': return (
                <EditableSingleFieldElement format="custom" fieldTitle='Candidate' >
                    <CandidatePicker
                        hideLabel
                        variant="standard"
                        value={state.linkedCandidateID}
                        onSelectCallback={ onCandidateChange }
                    />
                </EditableSingleFieldElement>
            );
            default:
                if (type.startsWith('CustomField')) {
                    const settings = customFieldsSettingsMap[type];
                    if (settings && settings.isEditable) {
                        let title = settings.title;
                        let predefinedValues = settings.usePredefinedValues && settings.values ? settings.values : null;
                        const { field, format } = UdfContactFieldMapObj[type as CustomFieldType];
                        const rawValue = state[field as keyof MinContact] as string | number | undefined;
                        const udfValue = (state[field as keyof MinContact] ?? '').toString();
                        const validationResult = IsValidCustomFieldValue(rawValue, format, settings.isMandatory ?? false);
                        const isValid = validationResult === "ok";
                        const errorMessage = validationErrorMessage(validationResult);
                        if (predefinedValues) {
                            return (
                                <EditableSingleFieldElement isError={showValidation && !isValid} errorMessage={errorMessage} format="select" fieldTitle={title} fieldValue={udfValue} fieldName={field} onChangeHandler={onStringFieldChange}>
                                    <MenuItem value="">None</MenuItem>
                                    {predefinedValues.map(v => <MenuItem key={v.value} value={v.value}>{v.value + (v.isDefault ? ' (Default)' : '')}</MenuItem>)}
                                </EditableSingleFieldElement>
                            );
                        }
                        else if (udfValue !== undefined && format === 'string') {
                            return (
                                <EditableSingleFieldElement
                                    isError={showValidation && !isValid}
                                    errorMessage={errorMessage}
                                    format={format}
                                    fieldTitle={title}
                                    fieldValue={udfValue}
                                    fieldName={field}
                                    multiline={settings.isMultiLine}
                                    onChangeHandler={onStringFieldChange}
                                />
                            );
                        }
                        else if (udfValue !== undefined && format === 'number') {
                            return (
                                <EditableSingleFieldElement
                                    isError={showValidation && !isValid}
                                    errorMessage={errorMessage}
                                    format={format}
                                    fieldTitle={title}
                                    fieldValue={udfValue}
                                    fieldName={field}
                                    onChangeHandler={onNumberFieldChange}
                                />
                            );
                        }
                        else if (format === 'date') {
                            return (
                                <EditableSingleFieldElement
                                    isError={showValidation && !isValid}
                                    errorMessage={errorMessage}
                                    format={format}
                                    fieldTitle={title}
                                    fieldValue={udfValue}
                                    fieldName={field}
                                    onDateChangeHandler={onDateFieldChange}
                                />
                            );
                        }
                        else if (format === 'datetime') {
                            return (
                                <EditableSingleFieldElement
                                    isError={showValidation && !isValid}
                                    errorMessage={errorMessage}
                                    format={format}
                                    fieldTitle={title}
                                    fieldValue={udfValue}
                                    fieldName={field}
                                    onDateChangeHandler={onDateTimeFieldChange}
                                />
                            );
                        }
                    }
                    else if (settings) return <SingleFieldElement labelWidthPercent={30} fontSize="1rem" fieldTitle={settings.title} fieldValue="[ Non Editable Custom Field ]" />;
                    else return <SingleFieldElement labelWidthPercent={30} fontSize="1rem" fieldTitle={type} fieldValue="[ Disabled Custom Field ]" />;
                }
                return <div>{id}</div>;
        }
    }, [state, avatarImage, removeUploadedImageHandler, onSiteChange, errorHandler, loadingHandler, successHandler, theme.palette.mode, showValidation, onStringFieldChange, onNumberFieldChange, divisionIds, onDivisionChange, onFirstNameFieldChange, onLastNameFieldChange, onClientChange, onManagerChange, onCandidateChange, customFieldsSettingsMap, onDateFieldChange, onDateTimeFieldChange]);

    const layoutResizeStopHandler = useCallback(() => setScreenResizedControl(prev => !prev), []);

    useEffect(() => {
        if (screenResizedControl) {}
        const api = layoutRef.current;
        if (api && fetchedLayoutConfig) {
            const refreshTimeout = setTimeout(() => {
                api.refresh();
                api.refresh();
            }, 250);
            return () => clearTimeout(refreshTimeout);
        }
    }, [fetchedLayoutConfig, screenResizedControl]);

    useEffect(() => {
        window.addEventListener('resize', layoutResizeStopHandler);
        return () => window.removeEventListener('resize', layoutResizeStopHandler);
    }, [layoutResizeStopHandler]);

    const renderLayout = useCallback(() => {
        if (!fetchedLayoutConfig) return <></>;
        
        let sorted = [...elements];
        sorted.sort((a, b) => {
            if (a.col === b.col) return a.row < b.row ? -1 : 1;
            else return a.col < b.col ? -1 : 1;
        });

        const mediaQuery = mediaQueryMaxWidth ? `max-width: ${mediaQueryMaxWidth}` : undefined;
        return (
            <DashboardLayoutComponent
                cellSpacing={cellSpacing}
                columns={columns}
                cellAspectRatio={30 / 2}
                allowDragging={false}
                allowResizing={false}
                resizeStop={ layoutResizeStopHandler }
                ref={l => layoutRef.current = l}
                mediaQuery={mediaQuery}
            >
                {sorted.map(e => (
                    <PanelWrapper
                        key={e.id}
                        id={e.id}
                        col={e.col}
                        row={e.row}
                        sizeX={e.sizeX}
                        sizeY={e.sizeY}
                        minSizeX={e.minSizeX}
                        minSizeY={e.minSizeY}
                        maxSizeX={e.maxSizeX}
                        maxSizeY={e.maxSizeY}
                        resizeControl={screenResizedControl}
                        resizeIconColor={theme.palette.text.disabled}
                        gapX={gapX}
                        gapY={gapY}
                        unitWidth={unitWidth}
                        unitHeight={unitHeight}
                    >
                        {renderElement(e.id, e.type)}
                    </PanelWrapper>
                ))}
            </DashboardLayoutComponent>
        );
    }, [elements, fetchedLayoutConfig, theme.palette.text.disabled, screenResizedControl, layoutResizeStopHandler, renderElement]);


    return (
        <>
            {unsavedChangesDialog}
            { !hasBlockedRoute && redirectContactId !== 0 && savedChanges && !hasChanges && !isEditorDirty && <Navigate to={`/contacts/${redirectContactId}`} /> }
            <OwnersListDialog
                type="Contact"
                open={isOwnersDialogOpen}
                editable
                closeHandler={ () => setIsOwnersDialogOpen(false) }
                owners={owners}
                addOwnerHandler={addOwnerCallback}
                removeOwnerHandler={removeOwnerCallback}
            />
            <Box p="10px" height="100%">
                <div className="control-section">
                    { renderLayout() }
                </div>
            </Box>
        </>
    );
}
