import React, { useCallback, useEffect, useMemo, useState } from "react";
import ConfirmationDialog from "../Generic/ConfirmationDialog";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import ContactPicker from "components/Pickers/ContactPicker";
import TextField from "@mui/material/TextField";
import moment from "moment";
import { PlacementStatusEnum } from "util/Definitions/Configuration/Placements";
import { ChangePlacementContacts } from "services/PlacementsService";
import { Contact } from "common/models/Contacts";

interface Props {
    open: boolean,
    placementId: number,
    contactId?: number,
    contactName?: string,
    hiringManagerId?: number,
    hiringManagerName?: string,
    billing1Id?: number,
    billing1Name?: string,
    billing2Id?: number,
    billing2Name?: string,
    billing3Id?: number,
    billing3Name?: string,
    signatoryId?: number,
    signatoryName?: string,
    placementStatusId?: number,
    closeHandler: () => void,
    loadingHandler?: (isLoading: boolean) => void,
    successHandler?: (message: string, cId: number, cName: string, hId: number, hName: string, b1Id: number, b1Name: string, b2Id: number, b2Name: string, b3Id: number, b3Name: string, sId: number, sName: string) => void,
    errorHandler?: (message: string) => void
}

export default function ChangeContactsDialog({ open, placementId, placementStatusId = 0, contactId: contactIdProp = 0, contactName: contactNameProp = '', hiringManagerId: hiringManagerIdProp = 0, hiringManagerName: hiringManagerNameProp = '',
    billing1Id: billing1IdProp = 0, billing1Name: billing1NameProp = '', billing2Id: billing2IdProp = 0, billing2Name: billing2NameProp = '', billing3Id: billing3IdProp = 0, billing3Name: billing3NameProp = '', signatoryId: signatoryIdProp = 0, signatoryName: signatoryNameProp = '',
    closeHandler, loadingHandler, successHandler, errorHandler }: Props) {
    
    const [showWarning, setShowWarning] = useState(false);
    const [contactId, setContactId] = useState(0);
    const [contactName, setContactName] = useState('');
    const [hiringManagerId, setHiringManagerId] = useState(0);
    const [hiringManagerName, setHiringManagerName] = useState('');
    const [billing1Id, setBilling1Id] = useState(0);
    const [billing1Name, setBilling1Name] = useState('');
    const [billing2Id, setBilling2Id] = useState(0);
    const [billing2Name, setBilling2Name] = useState('');
    const [billing3Id, setBilling3Id] = useState(0);
    const [billing3Name, setBilling3Name] = useState('');
    const [signatoryId, setSignatoryId] = useState(0);
    const [signatoryName, setSignatoryName] = useState('');

    const warningText = useMemo(() => {
        if (placementStatusId === PlacementStatusEnum.Completed) return 'This placement is already Completed. Are you sure you want to make this change?';
        if (placementStatusId === PlacementStatusEnum.PendingEarlyTermination || placementStatusId === PlacementStatusEnum.EarlyTerminated) return 'This placement has been Early Terminated. Are you sure you want to make this change?';
        return '';
    }, [placementStatusId]);

    useEffect(() => {
        if (open && Boolean(warningText)) setShowWarning(true);
    }, [open, warningText]);

    useEffect(() => {
        if (open) {
            setContactId(contactIdProp);
            setContactName(contactNameProp);
            setHiringManagerId(hiringManagerIdProp);
            setHiringManagerName(hiringManagerNameProp)
            setBilling1Id(billing1IdProp);
            setBilling1Name(billing1NameProp);
            setBilling2Id(billing2IdProp);
            setBilling2Name(billing2NameProp);
            setBilling3Id(billing3IdProp);
            setBilling3Name(billing3NameProp);
            setSignatoryId(signatoryIdProp);
            setSignatoryName(signatoryNameProp);
        }
    }, [open, contactIdProp, contactNameProp, hiringManagerIdProp, hiringManagerNameProp, billing1IdProp, billing1NameProp, billing2IdProp, billing2NameProp, billing3IdProp, billing3NameProp, signatoryIdProp, signatoryNameProp]);

    const effectiveDate = useMemo(() => {
        return moment().format('DD MMM YYYY');
    }, []);

    const submitCallback = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        const res = await ChangePlacementContacts(placementId, contactId, hiringManagerId, billing1Id, billing2Id, billing3Id, signatoryId, errorHandler);
        if (res) {
            successHandler && successHandler('Contacts Changed', contactId, contactName, hiringManagerId, hiringManagerName, billing1Id, billing1Name, billing2Id, billing2Name, billing3Id, billing3Name, signatoryId, signatoryName);
            closeHandler();
        }
        loadingHandler && loadingHandler(false);
    }, [placementId, contactId, hiringManagerId, billing1Id, billing2Id, billing3Id, signatoryId, contactName, hiringManagerName, billing1Name, billing2Name, billing3Name, signatoryName, loadingHandler, successHandler, errorHandler, closeHandler]);
    
    const hasChanges = useMemo(() => {
        return contactId !== contactIdProp ||
        hiringManagerId !== hiringManagerIdProp ||
        billing1Id !== billing1IdProp ||
        billing2Id !== billing2IdProp ||
        billing3Id !== billing3IdProp ||
        signatoryId !== signatoryIdProp;
    }, [contactId, hiringManagerId, billing1Id, billing2Id, billing3Id, signatoryId,
        contactIdProp, hiringManagerIdProp, billing1IdProp, billing2IdProp, billing3IdProp, signatoryIdProp]);

    const contactChangeHandler = useCallback((c: Contact | null) => {
        if (c) {
            setContactId(c.id);
            setContactName(c.fullName);
        }
        else {
            setContactId(0);
            setContactName('');
        }
    }, []);

    const hiringManagerChangeHandler = useCallback((c: Contact | null) => {
        if (c) {
            setHiringManagerId(c.id);
            setHiringManagerName(c.fullName);
        }
        else {
            setHiringManagerId(0);
            setHiringManagerName('');
        }
    }, []);

    const billing1ChangeHandler = useCallback((c: Contact | null) => {
        if (c) {
            setBilling1Id(c.id);
            setBilling1Name(c.fullName);
        }
        else {
            setBilling1Id(0);
            setBilling1Name('');
        }
    }, []);

    const billing2ChangeHandler = useCallback((c: Contact | null) => {
        if (c) {
            setBilling2Id(c.id);
            setBilling2Name(c.fullName);
        }
        else {
            setBilling2Id(0);
            setBilling2Name('');
        }
    }, []);

    const billing3ChangeHandler = useCallback((c: Contact | null) => {
        if (c) {
            setBilling3Id(c.id);
            setBilling3Name(c.fullName);
        }
        else {
            setBilling3Id(0);
            setBilling3Name('');
        }
    }, []);

    const signatoryChangeHandler = useCallback((c: Contact | null) => {
        if (c) {
            setSignatoryId(c.id);
            setSignatoryName(c.fullName);
        }
        else {
            setSignatoryId(0);
            setSignatoryName('');
        }
    }, []);

    return (
        <>
            <ConfirmationDialog
                open={open && showWarning && Boolean(warningText)}
                message={warningText}
                onClose={closeHandler}
                title="Change Contacts"
                onContinue={() => setShowWarning(false)}
            />
            <Dialog open={open && !showWarning} fullWidth>
                <DialogTitle>Change Contacts</DialogTitle>
                <DialogContent>
                    <Stack mt="10px" spacing={2}>
                        <TextField label="Effective Date" value={effectiveDate} InputProps={{ readOnly: true }} />
                        <ContactPicker
                            value={contactId}
                            label="Contact"
                            disableClearable
                            onSelectCallback={contactChangeHandler}
                        />
                        <ContactPicker
                            value={hiringManagerId}
                            label="Hiring Manager"
                            disableClearable
                            onSelectCallback={hiringManagerChangeHandler}
                        />
                        <ContactPicker
                            value={billing1Id}
                            label="Billing 1"
                            disableClearable
                            onSelectCallback={billing1ChangeHandler}
                        />
                        <ContactPicker
                            value={billing2Id}
                            label="Billing 2"
                            onSelectCallback={billing2ChangeHandler}
                        />
                        <ContactPicker
                            value={billing3Id}
                            label="Billing 3"
                            onSelectCallback={billing3ChangeHandler}
                        />
                        <ContactPicker
                            value={signatoryId}
                            label="Signatory"
                            onSelectCallback={signatoryChangeHandler}
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="error" onClick={ closeHandler }>Cancel</Button>
                    <Button variant="contained" color="success" disabled={!hasChanges || contactId === 0 || hiringManagerId === 0 || billing1Id === 0} onClick={ submitCallback } >Submit</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}