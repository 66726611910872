import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import TextField from "@mui/material/TextField";
import React, { useMemo, useState } from "react";
import { CustomField } from "common/models/Configuration/CustomFields";
import { ClientRecordDashboardElementType } from "common/models/Dashboard/EditLayout";
import { CustomFieldType } from "common/models/ScreenLayouts/CustomFields";

interface Props {
    open: boolean,
    activeFields: CustomField[],
    alreadyAddedElements: string[],
    closeHandler: () => void,
    addElementHandler: (type: ClientRecordDashboardElementType) => void
}

interface AddOption {
    value: ClientRecordDashboardElementType,
    label: string,
    secondaryLabel?: string
}

const availableElements: AddOption[] = [
    { value: 'ClientInfo', label: 'Photo' },
    { value: 'ClientSummary', label: 'Summary' },
    { value: 'ClientSummaryFullWidth', label: 'Summary - Full Width' },
    { value: 'ClientLocation', label: 'Location' },
    { value: 'ClientId', label: 'Id' },
    { value: 'ClientName', label: 'Legal Name' },
    { value: 'ClientTradingName', label: 'Trading Name' },
    { value: 'ClientPhone', label: 'Phone' },
    { value: 'ClientWebsite', label: 'Website' },
    { value: 'ClientParent', label: 'Parent' },
    { value: 'ClientPrimaryContact', label: 'Primary Contact' },
    { value: 'ClientBillingContact', label: 'Billing Contact' },
    { value: 'ClientDefaultApprover', label: 'Default Approver' },
    { value: 'ClientDefaultNoticePeriod', label: 'Default Notice Period' },
    { value: 'ClientOwners', label: 'Owners' },
    { value: 'ClientStatus', label: 'Status' },
    { value: 'ClientRating', label: 'Rating' },
    { value: 'ClientSubmissionDeadlines', label: 'Submission Deadlines' },
    { value: 'ClientCredit', label: 'Credit' },
    { value: 'ClientDivisions', label: 'Divisions' },
    { value: 'ClientCode1', label: 'Code 1' },
    { value: 'ClientCode2', label: 'Code 2' },
    { value: 'ClientLinkedIn', label: 'Linked In' },
    { value: 'ClientTwitter', label: 'Twitter' },
    { value: 'Divider', label: 'Divider' },
    { value: 'Spacer', label: 'Spacer' },
];

const getCustomFieldName = (name: string) => {
    if (!name.startsWith('Custom Field ')) return '';
    return 'CustomField' + name.substring(13);
};

export default function AddClientDashboardElementDialog({ open, activeFields, alreadyAddedElements, closeHandler, addElementHandler }: Props) {
    const [elementType, setElementType] = useState<ClientRecordDashboardElementType>('');

    const options = useMemo<AddOption[]>(() => {
        let o: AddOption[] = [];

        for (let i = 0; i < availableElements.length; i++) {
            const element = availableElements[i];
            const isAlreadyAdded = alreadyAddedElements.includes(element.value) && element.value !== 'Divider' && element.value !== 'Spacer';
            if (!isAlreadyAdded) o.push(element);
        }

        for (let i = 0; i < activeFields.length; i++) {
            const udf = activeFields[i];
            const name = getCustomFieldName(udf.name);
            const isAlreadyAdded = alreadyAddedElements.includes(name);
            if (name && !isAlreadyAdded) o.push({ value: name as CustomFieldType, label: udf.agencyName, secondaryLabel: udf.name });
        }

        o.sort((a, b) => {
            if (b.label < a.label) return 1;
            if (b.label > a.label) return -1;
            return 0;
        });

        return o;
    }, [activeFields, alreadyAddedElements]);

    const selectedElementType = useMemo(() => {
        if (elementType === '') return null;
        const type = options.find( e => e.value === elementType );
        return type ?? null;
    }, [elementType, options]);

    return (
        <Dialog open={open} fullWidth>
            <DialogTitle>Add new element</DialogTitle>
            <DialogContent>
                <Box pt={1}>
                    <Autocomplete size="small"
                        value={selectedElementType}
                        options={options}
                        onChange={ (e, v) => setElementType(v ? v.value : '') }
                        renderInput={ (params) => (
                            <TextField
                                {...params}
                                label="Element Type"
                                inputProps={{ ...params.inputProps, "data-lpignore": "true" }}
                        />)}
                        renderOption={(props, option) => (
                            <ListItem {...props}><ListItemText primary={option.label} secondary={option.secondaryLabel} /></ListItem>
                        )}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="error" onClick={ closeHandler }>Cancel</Button>
                <Button variant="contained" color="success" disabled={elementType === ''} onClick={ () => addElementHandler(elementType) }>Add</Button>
            </DialogActions>
        </Dialog>
    );
}