import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { DataGridPremium, GridColDef, GridRenderCellParams } from "@mui/x-data-grid-premium";
import React, { useEffect, useMemo, useState } from "react";
import { SignatureStatus } from "common/models/ESigning";
import { GetSignatureStatus } from "services/ESignService";

interface Props {
    open: boolean,
    eSignRequestId: number,
    onClose: () => void,
    loadingHandler: (isLoading: boolean) => void
}

export default function SignatureStatusDialog({ open, eSignRequestId, onClose, loadingHandler }: Props) {
    const [rows, setRows] = useState<SignatureStatus[]>([]);

    useEffect(() => {
        const getData = async () => {
            loadingHandler(true);
            const data = await GetSignatureStatus(eSignRequestId);
            if (data) setRows(data);
            loadingHandler(false);
        };
        getData();
    }, [eSignRequestId, loadingHandler]);

    const columns = useMemo<GridColDef[]>(() => {
        const statusRenderer = (params: GridRenderCellParams) => (
            params.value ? 'Completed' : 'Awaiting Signature'
        );

        return [
            { field: 'signerName', headerName: 'Signer', width: 250 },
            { field: 'signerEmail', headerName: 'Email', width: 300 },
            { field: 'isComplete', headerName: 'Status', width: 200, renderCell: statusRenderer },
        ]
    }, []);

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
            <DialogTitle>Signature Status</DialogTitle>
            <DialogContent>
                <div style={{ height: '100%', width: '100%' }}>
                    <div style={{ display: 'flex', height: '100%', minHeight: '300px' }}>
                        <div style={{ flexGrow: 1 }}>
                            <DataGridPremium
                                rows={rows}
                                columns={columns}
                                getRowId={ row => row.signerEmail }
                                disableRowSelectionOnClick
                                disableColumnMenu
                                disableColumnReorder
                                hideFooter
                                density="compact"    
                            />
                        </div>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={onClose}>Ok</Button>
            </DialogActions>
        </Dialog>
    );
}