import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import DeleteIcon from '@mui/icons-material/Delete';
import MicIcon from '@mui/icons-material/Mic';
import CloseIcon from '@mui/icons-material/Close';
import PersonIcon from '@mui/icons-material/Person';
import StopIcon from '@mui/icons-material/Stop';
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
import { Activity } from "common/models/Activities";
import UserPicker from "components/Pickers/UserPicker";
import ActionsSplitButton from "components/SummaryBars/Actions/ActionsSplitButton";
import Stack from "@mui/material/Stack";
import { Editor } from "@tinymce/tinymce-react";
import * as tinymce from 'tinymce';
import { useTheme } from "@mui/material/styles";
import ActionsDropDownButton from "components/SummaryBars/Actions/ActionsDropsDownMenu";
import moment from "moment";
import { ActivityFollowUp, ActivityLeftMessage, CreateActivity, DeleteActivityById, UpdateActivity } from "services/ActivitiesService";
import ConfirmationDialog from "../Generic/ConfirmationDialog";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { Link } from "react-router-dom";
import ActivityPreviewComponent from "components/Previews/ActivityPreview";
import ConfirmationWithDateDialog from "../Generic/ConfirmationWithDateDialog";
import ConfirmationWithDateAndUserDialog from "../Generic/ConfirmationWithDateAndUserDialog";
import UnsavedChangesDialog from "../UnsavedChangesDialog";
import { getJwtPayload } from "util/AuthUtils";
import { NonEditableActivityTypes } from "util/Definitions/Activities";
import { companyHasSinglePermission, userHasSinglePermission } from "util/PermissionsUtils";
import { Permission } from "common/models/Permissions";
import { DateTimePicker } from "@mui/x-date-pickers-pro";
import { GetActivitiesSettings } from "services/ConfigurationService";
import { NameIdObj } from "common/models/GenericTypes";
import useActiveUsers from "hooks/UseActiveUsers";
import useActiveGroups from "hooks/UseActiveGroups";
import Box from "@mui/material/Box";
import { GetSettingBySettingName } from "services/UsersService";

interface ActivityPlayers {
    clientId: number,
    clientName: string,
    contactId: number,
    contactName: string,
    candidateId: number,
    candidateName: string,
    jobId: number,
    jobName: string,
    opportunityId: number,
    opportunityName: string,
    placementId: number,
    placementName: string,
};

interface Props {
    open: boolean,
    data: Activity | null,
    closeHandler: () => void,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
    successHandler?: (message: string) => void,
}

const playerLinkStyle: React.CSSProperties = { width: '100%', color: 'inherit', textDecoration: 'none', padding: '0 16px' };
const isoDateFormat = "YYYY-MM-DDTHH:mm";

const CandidateWorkflowActivities = [9, 38, 40, 50];
const CandidateWorkflowOfferRejectedActivityId = 39;

export default function EditActivityDialog({ open, data, closeHandler, loadingHandler, errorHandler, successHandler }: Props) {
    const [activity, setActivity] = useState<Activity | null>(null);
    const [defaultStatusId, setDefaultStatusId] = useState(2);
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [showPlayersDialog, setShowPlayersDialog] = useState(false);
    const [showParentPreview, setShowParentPreview] = useState(false);
    const [showCustomLeftMessage, setShowCustomLeftMessage] = useState(false);
    const [showCustomFollowUp, setShowCustomFollowUp] = useState(false);
    const [showUnsavedChangesDialog, setShowUnsavedChangesDialog] = useState(false);
    const [isEditorDirty, setIsEditorDirty] = useState(false);
    const editorRef = useRef<tinymce.Editor | null>(null);
    const { browserSupportsSpeechRecognition, isMicrophoneAvailable, listening, interimTranscript, finalTranscript, resetTranscript } = useSpeechRecognition();
    const theme = useTheme();
    const isDarkTheme = theme.palette.mode === 'dark';
    const activeUsers = useActiveUsers();
    const activeGroups = useActiveGroups();
    const [openEditDialogOnFollowUpActivitiesCreated, setOpenEditDialogOnFollowUpActivitiesCreated] = useState(false); 

    useEffect(() => {
        if (open && data && data.id && NonEditableActivityTypes.includes(data.typeID)) {
            errorHandler && errorHandler("Cannot edit Non Editable Activities");
            closeHandler();
        }
    }, [open, data, closeHandler, errorHandler]);

    useEffect(() => {
        if (data && data.id === 0) {
            const getActivitySettings = async () => {
                const settings = await GetActivitiesSettings();
                let defaultStatus = 2;
                if (settings) {
                    const s = settings.find(v => v.id === data.typeID);
                    if (s) {
                        defaultStatus = s.defaultStatus;
                        setDefaultStatusId(s.defaultStatus);
                    }
                }
                const tokenPayload = getJwtPayload();
                if (tokenPayload) {
                    const currentDateTime = moment().format(isoDateFormat);
                    setActivity({...data, assignedToID: +tokenPayload.UserID, activityDate: currentDateTime, statusID: defaultStatus});
                    return;
                }
                
            };
            getActivitySettings();
        }
        else setActivity(data);

        const getDialogOnFollowUpActivitiesCreatedSetting = async () => {
            const editDialogOnFollowUpActivitiesCreated = await GetSettingBySettingName('OpenEditDialogOnFollowUpActivitiesCreated');
            setOpenEditDialogOnFollowUpActivitiesCreated(editDialogOnFollowUpActivitiesCreated?.toLowerCase() === 'true' ? true : false);
        }
        
        getDialogOnFollowUpActivitiesCreatedSetting();

        
    }, [data]);

    const canUserDeleteActivity = useMemo(() => (companyHasSinglePermission(Permission.ActivitiesDelete) && userHasSinglePermission(Permission.ActivitiesDelete)), []);

    const players = useMemo<ActivityPlayers | null>(() => {
        if (activity) {
            return {
                candidateId: activity.candidateID,
                candidateName: activity.candidateName ?? '',
                clientId: activity.clientID,
                clientName: activity.clientName ?? '',
                contactId: activity.contactID,
                contactName: activity.contactName ?? '',
                jobId: activity.jobID,
                jobName: activity.jobTitle,
                opportunityId: activity.opportunityID,
                opportunityName: activity.opportunityName ?? '',
                placementId: activity.placementID,
                placementName: activity.jobTitle
            }
        }
        return null;
    }, [activity]);

    const assignedToChanged = (u: NameIdObj | null) => {
        if (activity && u) setActivity({...activity, assignedToID: u.id, assignedToName: u.name});
    }

    const handleStatusChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = +e.target.value;
        if(activity) {
            if (activity.id > 0 && activity.statusID === 1 && value === 2) {
                const currentDate = moment().format(isoDateFormat);
                setActivity({...activity, statusID: value, activityDate: currentDate });
                return;
            }

            setActivity({...activity, statusID: value});
        }
    }

    const handleNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        if(activity) setActivity({...activity, [name]: +value});
    }

    const handleDateChange = useCallback((m: moment.Moment | null) => {
        if (m && m.isValid()) setActivity(prev => prev ? ({...prev, activityDate: m.format(isoDateFormat)}) : prev);
    }, []);

    const hasChanges = useMemo(() => {
        if (data && activity && activity.id === 0) {
            return (isEditorDirty ||
                defaultStatusId !== activity.statusID ||
                data.duration !== activity.duration);
        }
        if (data && activity) {
            return (isEditorDirty ||
                data.statusID !== activity.statusID ||
                data.assignedToID !== activity.assignedToID ||
                data.activityDate !== activity.activityDate ||
                data.duration !== activity.duration);
        }
        return false;
    }, [activity, data, isEditorDirty, defaultStatusId]);

    const saveChangesCallback = useCallback(async () => {
        SpeechRecognition.stopListening();
        setShowUnsavedChangesDialog(false);
        if (activity) {
            loadingHandler && loadingHandler(true);
            const content = editorRef.current ? editorRef.current.getContent() : activity.notes;
            let res = null;

            if (activity.id === 0) res = await CreateActivity({...activity, notes: content}, errorHandler);
            else res = await UpdateActivity(activity.id, {...activity, notes: content}, errorHandler);

            if (res) {
                let successMessage = activity.id === 0 ? `${activity.type} Created` : 'Changes saved';
                if (activity.id === 0 && CandidateWorkflowActivities.includes(activity.typeID)) successMessage = `Candidate marked as ${activity.type}`;
                else if (activity.id === 0 && CandidateWorkflowOfferRejectedActivityId === activity.typeID) successMessage = `${activity.type} successfully and Candidate Withdrawn`;
                setIsEditorDirty(false);
                editorRef.current && editorRef.current.setDirty(false);
                successHandler && successHandler(successMessage);
                closeHandler();
            }
            loadingHandler && loadingHandler(false);
        }
    }, [loadingHandler, activity, errorHandler, successHandler, closeHandler]);

    const leftMessageCallback = useCallback(async (hours: number, date?: string) => {
        SpeechRecognition.stopListening();
        if (activity) {
            loadingHandler && loadingHandler(true);
            const content = editorRef.current ? editorRef.current.getContent() : activity.notes;
            let newDate = activity.activityDate;
            if (date) newDate = date;
            else newDate = moment().add(hours, 'hours').format(isoDateFormat);
            
            let activityId = activity.id;
            let isSuccess = false;
            
            if (activityId === 0) {
                const createdActivityId = await CreateActivity({...activity, notes: content, activityDate: newDate});
                if (createdActivityId) {
                    isSuccess = true;
                    activityId = createdActivityId.value;
                }
            }
            else {
                const updatedSuccess = await UpdateActivity(activityId, {...activity, notes: content, activityDate: newDate}, errorHandler);
                if (updatedSuccess) isSuccess = true;
            }

            if (isSuccess) {
                setIsEditorDirty(false);
                editorRef.current && editorRef.current.setDirty(false);
                const leftMessageSuccess = await ActivityLeftMessage(activityId, errorHandler);
                if (leftMessageSuccess) {
                    setShowCustomLeftMessage(false);
                    successHandler && successHandler('Changes saved');
                    closeHandler();
                }
            }
            loadingHandler && loadingHandler(false);
        }
    }, [loadingHandler, activity, errorHandler, successHandler, closeHandler]);

    const activityDate = useMemo(() => {
        if (activity) {
            const d = moment(activity.activityDate);
            if (d.isValid()) {
                return d;
            }
        }
        return moment();
    }, [activity]);

    const followUpCallback = useCallback(async (assignedTo: number, days: number, date?: string) => {
        SpeechRecognition.stopListening();
        if (activity) {
            loadingHandler && loadingHandler(true);
            const content = editorRef.current ? editorRef.current.getContent() : activity.notes;
            let newDate = '';
            if (date) newDate = date;
            else newDate = moment().add(days, 'days').format(isoDateFormat);
            
            let activityId = activity.id;
            let isSuccess = false;
            
            if (activityId === 0) {
                const createdActivityId = await CreateActivity({...activity, notes: content});
                if (createdActivityId) {
                    isSuccess = true;
                    activityId = createdActivityId.value;
                }
            }
            else {
                const updatedSuccess = await UpdateActivity(activityId, {...activity, notes: content}, errorHandler);
                if (updatedSuccess) isSuccess = true;
            }

            if (isSuccess) {
                setIsEditorDirty(false);
                editorRef.current && editorRef.current.setDirty(false);
                const followUpActivity = await ActivityFollowUp(activityId, assignedTo, newDate, content, errorHandler);
                
                if (followUpActivity) {
                    setShowCustomFollowUp(false);
                    successHandler && successHandler('Changes saved');
                    if(openEditDialogOnFollowUpActivitiesCreated) {
                        setActivity(followUpActivity);
                    }else{
                        closeHandler();
                    }
                }
            }
            loadingHandler && loadingHandler(false);
        }
    }, [loadingHandler, activity,openEditDialogOnFollowUpActivitiesCreated, errorHandler, successHandler, closeHandler]);

    const deleteActionCallback = useCallback(async () => {
        setShowConfirmDelete(false);
        SpeechRecognition.stopListening();
        if (activity) {
            loadingHandler && loadingHandler(true);
            const res = await DeleteActivityById(activity.id, errorHandler);
            if (res) {
                successHandler && successHandler('Activity Deleted');
                setIsEditorDirty(false);
                editorRef.current && editorRef.current.setDirty(false);
                closeHandler();
            }
            loadingHandler && loadingHandler(false);
        }
    }, [activity, loadingHandler, successHandler, errorHandler, closeHandler]);

    const closeButtonCallback = useCallback(() => {
        if (hasChanges) {
            setShowUnsavedChangesDialog(true);
            return;
        }
        closeHandler();
    }, [hasChanges, closeHandler]);

    const discardChangesCallback = useCallback(() => {
        setIsEditorDirty(false);
        editorRef.current && editorRef.current.setDirty(false);
        setShowUnsavedChangesDialog(false);
        closeHandler();
    }, [closeHandler]);

    useEffect(() => {
        if (browserSupportsSpeechRecognition && isMicrophoneAvailable && listening) {
            if (finalTranscript !== '' && editorRef.current) {
                editorRef.current.insertContent(finalTranscript + ' ');
                resetTranscript();
            }
        }
    }, [browserSupportsSpeechRecognition, isMicrophoneAvailable, listening, finalTranscript, resetTranscript]);

    useEffect(() => {
        if (!open || showConfirmDelete || showCustomFollowUp || showCustomLeftMessage || showParentPreview || showPlayersDialog ) SpeechRecognition.stopListening();
    }, [open, showConfirmDelete, showCustomFollowUp, showCustomLeftMessage, showParentPreview, showPlayersDialog]);

    return (
        <>
            <ConfirmationDialog
                open={showConfirmDelete}
                message="Are your sure you want to delete this activity?"
                title="Confirm action"
                cancelActionText="No"
                confirmActionText="Yes"
                onClose={ () => setShowConfirmDelete(false) }
                onContinue={ deleteActionCallback }
                hideBackdrop
            />
            <ConfirmationWithDateDialog
                open={showCustomLeftMessage}
                title="LM + Reschedule Custom Date"
                onClose={ () => setShowCustomLeftMessage(false) }
                onContinue={ (date) => leftMessageCallback(0, date) }
                dateType="datetime-local"
                dateFieldLabel="Reschedule Date"
                hideBackdrop
            />
            <ConfirmationWithDateAndUserDialog
                open={showCustomFollowUp}
                title="Custom Follow Up"
                onClose={ () => setShowCustomFollowUp(false) }
                onContinue={ (date, userId) => followUpCallback(userId, 0, date) }
                dateType="datetime-local"
                userLabel="Assigned To"
                hideBackdrop
                disableUserClearable
            />
            <UnsavedChangesDialog
                open={showUnsavedChangesDialog}
                saveChangesHandler={saveChangesCallback}
                discardChangesHandler={ discardChangesCallback }
                cancelHandler={ () => setShowUnsavedChangesDialog(false) }
                message="Any changes you have made will be lost. Are you sure you wish to continue?"
                hideBackdrop
            />
            <Dialog open={showPlayersDialog} hideBackdrop fullWidth maxWidth="xs" onClose={ () => setShowPlayersDialog(false) } >
                <DialogTitle component="div" display="flex">
                    <div style={{ alignSelf: 'center' }}>Linked Records</div>
                    <div style={{ marginLeft: 'auto' }}>
                        <IconButton onClick={ () => setShowPlayersDialog(false) }>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent>
                    {players &&
                        <List>
                            {players.candidateId !== 0 &&
                                <ListItemButton sx={{ p: 0 }}>
                                    <Link to={ '/candidates/' + players.candidateId } target="_blank" style={playerLinkStyle}>
                                        <ListItemText primary={players.candidateName} secondary="Candidate" />
                                    </Link>
                                </ListItemButton>
                            }
                            {players.clientId !== 0 &&
                                <ListItemButton sx={{ p: 0 }}>
                                    <Link to={ '/clients/' + players.clientId } target="_blank" style={playerLinkStyle}>
                                        <ListItemText primary={players.clientName} secondary="Client" />
                                    </Link>
                                </ListItemButton>
                            }
                            {players.contactId !== 0 &&
                                <ListItemButton sx={{ p: 0 }}>
                                    <Link to={ '/contacts/' + players.contactId } target="_blank" style={playerLinkStyle}>
                                        <ListItemText primary={players.contactName} secondary="Contact" />
                                    </Link>
                                </ListItemButton>
                            }
                            {players.jobId !== 0 &&
                                <ListItemButton sx={{ p: 0 }}>
                                    <Link to={ '/jobs/' + players.jobId } target="_blank" style={playerLinkStyle}>
                                        <ListItemText primary={players.jobName} secondary="Job" />
                                    </Link>
                                </ListItemButton>
                            }
                            {players.opportunityId !== 0 &&
                                <ListItemButton sx={{ p: 0 }}>
                                    <Link to={ '/opportunities/' + players.opportunityId } target="_blank" style={playerLinkStyle}>
                                        <ListItemText primary={players.opportunityName} secondary="Opportunity" />
                                    </Link>
                                </ListItemButton>
                            }
                            {players.placementId !== 0 &&
                                <ListItemButton sx={{ p: 0 }}>
                                    <Link to={ '/placements/' + players.placementId } target="_blank" style={playerLinkStyle}>
                                        <ListItemText primary={players.placementName} secondary="Placement" />
                                    </Link>
                                </ListItemButton>
                            }
                        </List>
                    }
                </DialogContent>
            </Dialog>
            {activity && activity.parentID > 0 && showParentPreview &&
                <ActivityPreviewComponent
                    recordId={activity.parentID}
                    isSourceHovered
                    hideCallback={() => setShowParentPreview(false)}
                />
            }
            <Dialog open={open} fullWidth maxWidth="md" >
                <DialogTitle component="div" display="flex">
                    <div style={{ alignSelf: 'center' }}>{activity ? activity.type : ''}</div>
                    <div style={{ marginLeft: 'auto' }}>
                        <IconButton onClick={ () => setShowPlayersDialog(true) }>
                            <PersonIcon />
                        </IconButton>
                        <IconButton onClick={ closeButtonCallback }>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <div style={{ paddingTop: '10px' }}>
                        <Stack spacing={2}>
                            <Box display="flex" gap={2}>
                                <TextField
                                    select
                                    label="Status"
                                    fullWidth
                                    value={ activity && activity.statusID === 1 ? '1' : '2' }
                                    name="statusID"
                                    // onChange={ handleNumberChange }
                                    onChange={ handleStatusChange }
                                    sx={{ flex: '1 0 0' }}
                                >
                                    <MenuItem value="1">Open</MenuItem>
                                    <MenuItem value="2">Closed</MenuItem>
                                </TextField>
                                <UserPicker
                                    userId={activity ? activity.assignedToID : 0}
                                    onSelect={ assignedToChanged }
                                    disableClearable
                                    sx={{ flex: '1 0 0' }}
                                />
                            </Box>
                            <Box display="flex" gap={2}>
                                <DateTimePicker
                                    sx={{ flex: '1 0 0' }}
                                    value={activityDate}
                                    label="Date"
                                    onChange={handleDateChange}
                                    slotProps={{
                                        actionBar: { actions: ["clear", "today", "cancel", "accept"] },
                                    }}
                                />
                                <TextField
                                    select
                                    label="Duration"
                                    fullWidth
                                    value={activity ? activity.duration.toString() : "0"}
                                    name="duration"
                                    onChange={ handleNumberChange }
                                    sx={{ flex: '1 0 0' }}
                                >
                                    <MenuItem value="0">None</MenuItem>
                                    <MenuItem value="15">15 mins</MenuItem>
                                    <MenuItem value="30">30 mins</MenuItem>
                                    <MenuItem value="60">1 hour</MenuItem>
                                    <MenuItem value="90">90 mins</MenuItem>
                                    <MenuItem value="120">2 hours</MenuItem>
                                    <MenuItem value="240">4 hours</MenuItem>
                                    <MenuItem value="480">1 Day</MenuItem>
                                </TextField>
                            </Box>
                            <Editor
                                tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce-5.10.2/tinymce.min.js'}
                                initialValue={activity ? activity.notes : ''}
                                onDirty={ () => setIsEditorDirty(true) }
                                onInit={ (evt, editor) => editorRef.current = editor }
                                init={{
                                    height: 380,
                                    skin: isDarkTheme ? 'oxide-dark' : undefined,
                                    content_css: isDarkTheme ? 'dark' : undefined,
                                    branding: false,
                                    menubar: false,
                                    statusbar: false,
                                    contextmenu: false,
                                    resize: false,
                                    plugins: 'powerpaste link code',
                                    browser_spellcheck: true,
                                    forced_root_block: false,
                                    verify_html: false,
                                    toolbar1: '',
                                    auto_focus: true,
                                    setup: (editor) => {
                                        if (activeUsers.length > 0 || activeGroups.length > 0) {
                                            
                                            const getMatchedMentions = (pattern: string) => {
                                                return activeUsers.filter( u => {
                                                    return u.name.toLowerCase().indexOf(pattern.toLowerCase()) !== -1;
                                                });
                                            };

                                            const getMatchedGroups = (pattern: string) => {
                                                return activeGroups.filter(u => u.name.toLowerCase().indexOf(pattern.toLowerCase()) !== -1);
                                            }

                                            editor.ui.registry.addAutocompleter('mentions', {
                                                ch: '@',
                                                minChars: 1,
                                                columns: 1,
                                                onAction: (api, rng, value) => {
                                                    editor.selection.setRng(rng);
                                                    editor.insertContent(value);
                                                    editor.insertContent(" ");
                                                    api.hide();
                                                },
                                                fetch: async (pattern) => {
                                                    const contents = getMatchedMentions(pattern).map(u => {
                                                        const options: tinymce.Ui.InlineContent.AutocompleterContents = {
                                                            type: 'autocompleteitem',
                                                            text: u.name,
                                                            value: `<strong data-umention="${u.id}">${u.name}</strong> `
                                                        }
                                                        return options;
                                                    });

                                                    const gContents = getMatchedGroups(pattern).map(g => {
                                                        const options: tinymce.Ui.InlineContent.AutocompleterContents = {
                                                            type: 'autocompleteitem',
                                                            text: `${g.name} [GROUP]`,
                                                            value: `<strong data-gmention="${g.id}">${g.name}</strong> `
                                                        };
                                                        return options;
                                                    })

                                                    return [...contents, ...gContents];
                                                }
                                            });
                                        }
                                    }
                                }}
                            />
                            { activity && activity.parentID > 0 &&
                                <span onClick={ () => setShowParentPreview(true) } style={{ cursor: 'pointer', textDecoration: 'underline' }}>Click to view previous activity</span>
                            }
                        </Stack>
                    </div>
                </DialogContent>
                <DialogActions>
                    <div style={{ marginRight: 'auto', display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                        {data && data.id !== 0 && canUserDeleteActivity &&
                            <IconButton onClick={ () => setShowConfirmDelete(true) }>
                                <DeleteIcon />
                            </IconButton>
                        }
                        {browserSupportsSpeechRecognition && !listening &&
                            <IconButton onClick={ () => SpeechRecognition.startListening({language: 'en-AU', continuous: true}) }><MicIcon /></IconButton>
                        }
                        {browserSupportsSpeechRecognition && listening &&
                            <>
                                <IconButton onClick={ () => SpeechRecognition.stopListening() }><StopIcon /></IconButton>
                                {interimTranscript === '' && <span style={{ padding: '0 10px' }}>Listening...</span>}
                                <div style={{
                                    flexGrow: 1,
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    maxWidth: '510px',
                                    padding: '0 10px'
                                }} >{interimTranscript}</div>
                            </>
                        }
                    </div>
                    {activity && activity.category === 1 && activity.typeID !== 41 &&
                        <ActionsDropDownButton color="primary" label="Left Message">
                            <MenuItem onClick={ () => leftMessageCallback(0) }>Left Message</MenuItem>
                            <MenuItem onClick={ () => leftMessageCallback(1) }>LM + Reschedule 1 Hour</MenuItem>
                            <MenuItem onClick={ () => leftMessageCallback(24) }>LM + Reschedule 1 Day</MenuItem>
                            <MenuItem onClick={ () => leftMessageCallback(168) }>LM + Reschedule 1 Week</MenuItem>
                            <MenuItem onClick={ () => setShowCustomLeftMessage(true) }>LM + Reschedule Custom Date</MenuItem>
                        </ActionsDropDownButton>
                    }
                    <ActionsSplitButton mainAction={ saveChangesCallback } color="success" menuPlacement="top-end">
                        { activity &&
                            [<MenuItem key={1} onClick={ () => followUpCallback(0, 1) }>Submit + Follow up 1 Day</MenuItem>,
                            <MenuItem key={2} onClick={ () => followUpCallback(0, 7) }>Submit + Follow up 1 Week</MenuItem>,
                            <MenuItem key={3} onClick={ () => followUpCallback(0, 14) }>Submit + Follow up 2 Weeks</MenuItem>,
                            <MenuItem key={4} onClick={ () => followUpCallback(0, 30) }>Submit + Follow up 1 Month</MenuItem>,
                            <MenuItem key={5} onClick={ () => followUpCallback(0, 90) }>Submit + Follow up 3 Months</MenuItem>,
                            <MenuItem key={6} onClick={ () => followUpCallback(0, 180) }>Submit + Follow up 6 Months</MenuItem>,
                            <MenuItem key={7} onClick={ () => setShowCustomFollowUp(true) }>Submit + Follow up Custom</MenuItem>]
                        }
                    </ActionsSplitButton>
                </DialogActions>
            </Dialog>
        </>
    );
}