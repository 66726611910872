import React, { useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import UserSettingsPageContent from "components/Content/UserSettingsPageContent";

interface Props {
    setSummaryBar?: (summaryBar: JSX.Element) => void,
    userName?: string
}

const UsersLink = <Link to="/configuration/users" style={{ color: 'inherit', textDecoration: 'underline' }}>Users</Link>;

export default function UserSettings({ setSummaryBar, userName }: Props) {
    const params = useParams();

    const userId = useMemo(() => {
        const parsedId = +(params.userId ?? '0');
        if(isNaN(parsedId)) return 0;
        return parsedId;
    }, [params.userId]);

    const userNameText = useMemo(() => {
        return userName ? userName + ' > ' : '';
    }, [userName]);

    const summaryBarTitle = useMemo(() => {
        return <> {'Configuration > '} {UsersLink} {' > ' + userNameText + 'Settings'} </>;
    }, [userNameText]);

    return (
        <UserSettingsPageContent
            userId={userId}
            summaryBarTitle={summaryBarTitle}
            summaryBarTabTitle={userNameText + 'Users > Configuration > Recruit Wizard'}
            setSummaryBar={setSummaryBar}
        />
    );
}