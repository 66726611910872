import { FilterWithTimeRange } from "common/models/Analytics/AnalyticsFilterData";
import { KpiOverview } from "common/models/Analytics/BusinessIntelligence";
import React, { useEffect, useState } from "react";
import { GetKpiOverviewData } from "services/BusinessIntelligence";
import KpiGaugeGraph from "../Graphs/KpiGaugeGraph";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { GetEdenRitchieActivitySummary, GetEdenRitchieLiveMetrics, GetEdenRitchieRatios } from "services/BusinessIntelligenceCustom";
import { EdenRitchieMetricValue } from "common/models/Analytics/BusinessIntelligenceCustom";
import SingleValueGraph from "../Graphs/SingleValueGraph";
import ListValueDisplayGraph from "../Graphs/ListValueDisplayGraph";

interface Props {
    filterData: FilterWithTimeRange,
    loadingHandler?: (isLoading: boolean) => void
}

const kpiFields = [
    {prefix: 'newJobs', title: 'New Jobs'},
    {prefix: 'placements', title: 'New Placements'},
    {prefix: 'clientVisits', title: 'Client Meetings'},
    {prefix: 'candidateMeetings', title: 'Candidate Meetings'}
];

export default function EdenRitchieDashboardContent({ filterData, loadingHandler }: Props) {
    const [isGettingKpiData, setIsGettingKpiData] = useState(false);
    const [isGettingRatioData, setIsGettingRatioData] = useState(false);
    const [isGettingLiveMetricData, setIsGettingLiveMetricData] = useState(false);
    const [isGettingActivitySummaryData, setIsGettingActivitySummaryData] = useState(false);
    const [kpiOverviewData, setKpiOverviewData] = useState<KpiOverview>();
    const [ratiosData, setRatiosData] = useState<EdenRitchieMetricValue[]>();
    const [liveMetricData, setLiveMetricData] = useState<EdenRitchieMetricValue[]>();
    const [activitySummaryData, setActivitySummaryData] = useState<EdenRitchieMetricValue[]>();

    useEffect(() => {
        loadingHandler && loadingHandler(isGettingKpiData || isGettingRatioData || isGettingLiveMetricData || isGettingActivitySummaryData);
    }, [isGettingActivitySummaryData, isGettingKpiData, isGettingLiveMetricData, isGettingRatioData, loadingHandler]);

    useEffect(() => {
        const getKpiData = async () => {
            setIsGettingKpiData(true);
            const res = await GetKpiOverviewData(filterData);
            if (res) setKpiOverviewData(res);
            setIsGettingKpiData(false);
        };
        getKpiData();
    }, [filterData]);

    useEffect(() => {
        const getRatioData = async () => {
            setIsGettingRatioData(true);
            const res = await GetEdenRitchieRatios(filterData);
            if (res) setRatiosData(res);
            setIsGettingRatioData(false);
        };
        getRatioData();
    }, [filterData]);

    useEffect(() => {
        const getLiveMetricData = async () => {
            setIsGettingLiveMetricData(true);
            const res = await GetEdenRitchieLiveMetrics(filterData);
            if (res) setLiveMetricData(res);
            setIsGettingLiveMetricData(false);
        };
        getLiveMetricData();
    }, [filterData]);

    useEffect(() => {
        const getActivitySummaryData = async () => {
            setIsGettingActivitySummaryData(true);
            const res = await GetEdenRitchieActivitySummary(filterData);
            if (res) setActivitySummaryData(res);
            setIsGettingActivitySummaryData(false);
        };
        getActivitySummaryData();
    }, [filterData]);

    return (
        <Box bgcolor={t => t.palette.background.default} p="10px" height="100%" mt="10px">
            <Stack spacing={6}>
                {kpiOverviewData &&
                    <Box>
                        <Typography variant="h5" pb="10px">KPI</Typography>
                        <Box display="flex" justifyContent="space-between" gap={1}>
                            {
                                kpiFields.map((v,i) => (
                                    <KpiGaugeGraph
                                        key={i}
                                        title={v.title}
                                        target={(kpiOverviewData as any)[`${v.prefix}_Target`]}
                                        actual={(kpiOverviewData as any)[`${v.prefix}_Actual`]}
                                        targetToDate={(kpiOverviewData as any)[`${v.prefix}_TargetToDate`]}
                                        explaination={(kpiOverviewData as any)[`${v.prefix}_Explaination`]}
                                        color={(kpiOverviewData as any)[`${v.prefix}_Formatting`]}
                                    />
                                ))
                            }
                        </Box>
                    </Box>
                }
                {ratiosData &&
                    <Box>
                        <Typography variant="h5" pb="10px">RATIOS</Typography>
                        <Box display="flex" justifyContent="space-between" gap={1}>
                            {
                                ratiosData.map((v,i) => (
                                    <SingleValueGraph key={i} title={v.metric} value={v.actual} />
                                ))
                            }
                        </Box>
                    </Box>
                }
                {liveMetricData &&
                    <Box>
                        <Typography variant="h5" pb="10px">LIVE METRICS</Typography>
                        <ListValueDisplayGraph data={liveMetricData} nameField="metric" valueField="actual" />
                    </Box>
                }
                {activitySummaryData &&
                    <Box>
                        <Typography variant="h5" pb="10px">ACTIVITY SUMMARY</Typography>
                        <ListValueDisplayGraph data={activitySummaryData} nameField="metric" valueField="actual" />
                    </Box>
                }
            </Stack>
        </Box>
    );
}