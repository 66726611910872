import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { GetDashboardJobPipelineGraphData } from "services/BusinessIntelligence";

interface GraphData {
    title: string,
    count: number,
    color: string,
}

export default function JobPipelineGraphComponent() {
    const [graphData, setGraphData] = useState<GraphData[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const t = useTheme();
    
    useEffect(() => {
        const getData = async () => {
            setIsLoading(true);
            const res = await GetDashboardJobPipelineGraphData(setErrorMessage);
            if (res) {
                const data: GraphData[] = [
                    { title: 'Resourcing', count: res.resourcing, color: t.palette.text.disabled },
                    { title: 'Shortlist', count: res.shortlist, color: t.palette.primary.main },
                    { title: 'Submitted', count: res.submitted, color: t.palette.secondary.main },
                    { title: 'Interview', count: res.interview, color: t.palette.info.main },
                    { title: 'Offered', count: res.offered, color: t.palette.warning.main },
                    { title: 'Placed', count: res.placed, color: t.palette.success.main },
                ];

                setGraphData(data);
            }
            setIsLoading(false);
        };
        getData();
    }, [t.palette, t.palette.error.main, t.palette.info.main, t.palette.primary.main, t.palette.secondary.main, t.palette.success.main, t.palette.text.disabled, t.palette.text.primary, t.palette.text.secondary, t.palette.warning.main]);

    const isError = useMemo(() => Boolean(errorMessage), [errorMessage]);

    return (
        <Paper sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h6" padding={1} textAlign="center" fontWeight={600} component="div">Job Pipeline</Typography>
            {isLoading && <Box m="auto"><CircularProgress /></Box> }
            { isError && !isLoading && <Alert severity="error" sx={{ justifyContent: 'center' }}>{ errorMessage }</Alert> }
            <Box flexGrow={1} display={isLoading || isError ? 'none' : "flex"}>
                <Box display="flex" px={2} pb={2} gap={4} flexWrap="wrap" justifyContent="center" width="100%">
                    {graphData.map((v, i) => (
                        <Link key={i} to={"/dashboard?tab=DASHBOARD_Jobs"} style={{ textDecoration: 'none' }}>
                            <Box display="flex" bgcolor={v.color} color={t.palette.getContrastText(v.color)} px={2} py={2} borderRadius="4px">
                                <Typography width="100px">{v.title}</Typography>
                                <Typography width="50px" textAlign="right">{v.count}</Typography>
                            </Box>
                        </Link>
                    ))}
                </Box>
            </Box>
        </Paper>
    );
}