import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import Alert from "components/Alert";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import { DataGridPremium, GridColDef, GridRenderCellParams, GridRowSelectionModel, useGridApiRef } from "@mui/x-data-grid-premium";

import { JobBoard } from "common/models/Configuration/JobBoard";
import { NumberArrayWrapper } from "common/models/GenericTypes";
import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import PageContentLayout from "layouts/PageContentLayout";
import { AddNewAdvertsJobBoards, GetNewAdvertsJobBoards } from "services/AdvertsService";

interface Props {
    setSummaryBar?: (summaryBar: JSX.Element) => void
}


export default function JobBoardsAdd({ setSummaryBar }: Props) {
    const apiRef = useGridApiRef();
    const [isLoading, setIsLoading] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
    const [rows, setRows] = useState<JobBoard[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        const addHandler = async () => {
            const selectedJobBoards: NumberArrayWrapper = {
                values: selectionModel as number[]
            };
            
            setIsLoading(true);
            const res = await AddNewAdvertsJobBoards(selectedJobBoards, setErrorMessage);
            if(res) {
                setShowSuccess(true);
                setSelectionModel([]);
                navigate('/configuration/adverts/job-boards');
            }
            setIsLoading(false);
        };
        const addButton = <Button disabled={ selectionModel.length === 0 } variant="contained" color="primary" onClick={addHandler} >Add</Button>;
        const SummaryBar = (
            <TitleAndActionSummaryBar
                title="Configuration > Adverts > Job Boards > Add"
                browserTabTitle="Adverts > Configuration"
                action={addButton}
            />
        );

        setSummaryBar && setSummaryBar(SummaryBar) 
    }, [setSummaryBar, selectionModel, apiRef, navigate]);

    useEffect(() => {
        const getData = async () => {
            setIsLoading(true);
            const data = await GetNewAdvertsJobBoards();
            if (data) setRows(data);
            setIsLoading(false);
        };
        getData();
    }, []);

    const columns = useMemo<GridColDef[]>(() => {
        const imgRender = (params: GridRenderCellParams) => {
            return <img alt="img" style={{ maxHeight: '60px', maxWidth: '100px' }} src={`https://cdn.recruitwizard.com/images/jobboards/default/${params.value}.png`} />;
        }
        return [
            { field: 'id', headerName: 'Logo', width: 110, disableColumnMenu: true, sortable: false, renderCell: imgRender },
            { field: 'name', headerName: 'Name', flex: 1 }
        ];
    }, []);

    return (
        <>
            <Snackbar open={showSuccess} autoHideDuration={3000} onClose={() => setShowSuccess(false)}>
                <Alert onClose={() => setShowSuccess(false)}>Changes Saved</Alert>
            </Snackbar>
            <Snackbar open={errorMessage !== ''}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <PageContentLayout title="Job Boards - Add" showLoading={isLoading}>
                <div style={{ height: '100%', width: '100%', minHeight: '500px' }}>
                    <div style={{ display: 'flex', height: '100%' }}>
                        <div style={{ flexGrow: 1 }}>
                            <DataGridPremium
                                density="comfortable"
                                apiRef={apiRef}
                                checkboxSelection
                                onRowSelectionModelChange={ selectionModel => setSelectionModel(selectionModel) }
                                rows={rows}
                                columns={columns}
                            />
                        </div>
                    </div>
                </div>
            </PageContentLayout>
        </>
    );
}