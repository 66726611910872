import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CancelIcon from '@mui/icons-material/Cancel';
import React from "react";
import DocumentsGridComponent from "components/Grids/DocumentsGrid";
import { PREVIEW_TITLE_STYLE } from "util/Definitions/Constants/Previews";
import IconButton from "@mui/material/IconButton";

interface Props {
    open: boolean,
    source: 'client-record' | 'contact-record' | 'candidate-record' | 'job-record' | 'placement-record' | 'change-request-record' | 'opportunity-record' | 'site-record' | 'submission-record',
    title?: string,
    sourceId?: number,
    hideGridActions?: boolean,
    closeHandler: () => void,
    loadingHandler?: (isLoading: boolean) => void,
}

export default function ViewDocumentsDialog({ open, title = 'Documents', source, sourceId, hideGridActions, closeHandler, loadingHandler }: Props) {
    return (
        <Dialog open={open} onClose={closeHandler} maxWidth="lg" fullWidth>
            <DialogTitle component="div" display="flex" sx={ PREVIEW_TITLE_STYLE }>
                <div style={{ alignSelf: 'center' }}>{title}</div>
                <div style={{ marginLeft: 'auto' }}>
                    <IconButton size="small" onClick={ closeHandler } sx={{ color: t => t.palette.primary.contrastText }}><CancelIcon /></IconButton>
                </div>
            </DialogTitle>
            <DialogContent sx={{ mt: '20px' }}>
                <DocumentsGridComponent
                    source={source}
                    sourceId={sourceId}
                    loadingHandler={loadingHandler}
                    hideActions={hideGridActions}
                    gridName="ViewDocumentsDialog_DocumentsGrid"
                />
            </DialogContent>
        </Dialog>
    );
}