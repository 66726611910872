import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import TextField from "@mui/material/TextField";
import { TemplatePlaceholder } from "common/models/Templates/TemplatePlaceholder";
import React, { useCallback, useState } from "react";

type Props = {
    open: boolean,
    placeholders: TemplatePlaceholder[],
    insertHandler: (placeholder: string) => void,
    closeHandler: () => void,
};

export default function InsertPlaceholderDialog({ open, placeholders, insertHandler, closeHandler }: Props) {
    const [inputValue, setInputValue] = useState('');

    const onSelectedCallback = useCallback((e: any, o: TemplatePlaceholder | null) => {
        if (o) insertHandler(o.placeholderUsage);
    }, [insertHandler]);
    
    return (
        <Dialog open={open} fullWidth>
            <DialogTitle>Insert Placeholder</DialogTitle>
            <DialogContent>
                <Box pt={1}>
                    <Autocomplete
                        size="small"
                        inputValue={inputValue}
                        onInputChange={(e, v, r) => r !== 'reset' && setInputValue(v)}
                        options={placeholders}
                        getOptionLabel={o => o.friendlyName}
                        isOptionEqualToValue={ (o, v) => o.placeholder === v.placeholder }
                        onChange={ onSelectedCallback }
                        renderInput={ params => <TextField {...params} label="Placeholders" inputProps={{ ...params.inputProps, "data-lpignore": "true" }} /> }
                        renderOption={(props, option) => (
                            <ListItem {...props}><ListItemText primary={option.friendlyName} secondary={option.placeholder} /></ListItem>
                        )}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={closeHandler}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}