import React, { useEffect, useMemo, useState } from "react";
import { Report } from "common/models/Reports/Report";
import ReportsGridComponent from "components/Reports/ReportsGrid";
import OnlyTitleSummaryBar from "components/SummaryBars/OnlyTitleSummaryBar";
import PageContentLayout from "layouts/PageContentLayout";
import PageLayout from "layouts/PageLayout";
import { GetReports } from "services/CandidatesService";
import { Link, useParams } from 'react-router-dom';
import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import DetailedDocumentsReport from "components/Reports/Documents/DocumentsDetailed";
import NewCandidatesReport from "components/Reports/Candidates/CandidatesNew";
import RecentlyInactive from "components/Reports/Candidates/RecentlyInactive";
import CandidatesByEmployerReport from "components/Reports/Candidates/CandidatesByEmployer";
import CandidatesSnapshotReport from "components/Reports/Candidates/CandidatesSnapshot";
import CurrentJobForPreviouslyPlacedCandidatesReport from "components/Reports/Candidates/CurrentJobForPreviouslyPlacedCandidates";
import CandidatesSeriouslyLookingReport from "components/Reports/Candidates/CandidatesSeriouslyLooking";
import CandidatesDataExceptionReport from "components/Reports/Candidates/CandidatesDataException";
import DetailedCandidatesReport from "components/Reports/Candidates/CandidatesDetailed";

const gridSummaryBar = <OnlyTitleSummaryBar title="Analytics > Candidates" browserTabTitle="Candidates > Analytics" />;
const ClientsReportsLink = <Link to="/analytics/candidates" style={{ color: 'inherit', textDecoration: 'underline' }}>Candidates</Link>;

export default function CandidatesReportsPage() {
    const [isLoading, setIsLoading] = useState(false);
    const [reports, setReports] = useState<Report[]>([]);
    const params = useParams();

    const reportName = useMemo(() => {
        const reportNameParam = params.reportName;
        return reportNameParam;
    }, [params.reportName]);

    const reportObj = useMemo(() => {
        return reports.find(r => r.pathName === reportName)
    }, [reports, reportName]);

    const summaryBar = useMemo(() => {
        if (reportObj) {
            const title = <>{'Analytics > '} {ClientsReportsLink} {' > ' + reportObj.title}</>;
            const tabTitle = reportObj.title + ' > Candidates > Analytics';
            return <TitleAndActionSummaryBar title={title} browserTabTitle={tabTitle} action={<></>} />;
        }
        return gridSummaryBar;
    }, [reportObj]);

    useEffect(() => {
        const getReports = async () => {
            setIsLoading(true);
            const res = await GetReports();
            if (res) setReports(res);
            setIsLoading(false);
        };
        getReports();
    }, []);

    return (
        <PageLayout SummaryBar={summaryBar}>
            <PageContentLayout showLoading={isLoading}>
                { !reportName && <ReportsGridComponent data={reports} /> }
                {reportObj && reportObj.pathName === 'documents' && <DetailedDocumentsReport entityTypeId={3} description={reportObj.longDescription} loadingHandler={setIsLoading} />}
                {reportObj && reportObj.pathName === 'new-candidates' && <NewCandidatesReport entityTypeId={3} description={reportObj.longDescription} loadingHandler={setIsLoading} />}
                {reportObj && reportObj.pathName === 'recently-inactive' && <RecentlyInactive  description={reportObj.longDescription} loadingHandler={setIsLoading} />}
                {reportObj && reportObj.pathName === 'candidates-by-employer' && <CandidatesByEmployerReport description={reportObj.longDescription} loadingHandler={setIsLoading} />}
                {reportObj && reportObj.pathName === 'database-snapshot' && <CandidatesSnapshotReport description={reportObj.longDescription} loadingHandler={setIsLoading} />}
                {reportObj && reportObj.pathName === 'previously-placed' && <CurrentJobForPreviouslyPlacedCandidatesReport description={reportObj.longDescription} loadingHandler={setIsLoading} />}
                {reportObj && reportObj.pathName === 'seriously-looking' && <CandidatesSeriouslyLookingReport description={reportObj.longDescription} loadingHandler={setIsLoading} />}
                {reportObj && reportObj.pathName === 'data-exception' && <CandidatesDataExceptionReport description={reportObj.longDescription} loadingHandler={setIsLoading} />}
                {reportObj && reportObj.pathName === 'detailed' && <DetailedCandidatesReport description={reportObj.longDescription} loadingHandler={setIsLoading} />}
            </PageContentLayout>
        </PageLayout>
    );
}