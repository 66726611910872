import React, { useEffect, useMemo, useState } from "react";
import { GetGenericApplyLink } from "services/JobBoardsService";

interface Props {
    jobBoardId: number,
    advertId: number,
    attributes?: Record<string, string>,
    attributeChangeHandler?: (jobBoardId: number, key: string, value: string) => void
}

export default function GenericApplyLink({ jobBoardId, advertId, attributes }: Props) {
    const [generatedLink, setGeneratedLink] = useState('');

    const atts = useMemo<Record<string, string>>(() => {
        if (attributes) return attributes;
        return {};
    }, [attributes]);

    const attributeLink = useMemo(() => atts.ApplyURL ?? '', [atts.ApplyURL]);
    
    useEffect(() => {
        const getLink = async () => {
            const res = await GetGenericApplyLink(jobBoardId, advertId);
            if (res && Boolean(res.value)) {
                setGeneratedLink(res.value);
            }
        };
        !Boolean(attributeLink) && jobBoardId && advertId && getLink();
    }, [advertId, attributeLink, jobBoardId]);

    const realUrl = useMemo(() => {
        if (attributeLink) return attributeLink;
        if (generatedLink) return generatedLink;
        return '';
    }, [generatedLink, attributeLink]);
    
    return (
        <>
            <p><b>Note: </b>Jobs will NOT automatically post. You must copy the Application URL below and manually post to your desired location.</p>
            { Boolean(realUrl) && <a href={realUrl} target="_blank" rel="noopener noreferrer">{realUrl}</a> }
        </>
    );
}