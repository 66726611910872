import React, { useCallback, useEffect, useState } from "react";
import Alert from "components/Alert";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import useObjectStateWithChangeTracker from '../../hooks/UseObjectStateWithChangeTracker';
import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import PageContentLayout from "layouts/PageContentLayout";
import { AnalyticsConfigOptions } from "common/models/Configuration/Analytics";
import { DefaultOptions, NoChangesTracker } from "util/Definitions/Configuration/Analytics";
import { GetAnalyticsSettings, SaveAnalyticsSettings } from '../../services/ConfigurationService';
import { ObjToSettingsArray } from '../../util/SettingsUtil';
import useUnsavedChangesDialog from "hooks/UseUnsavedChangesDialog";
import RWTextFieldComponent from "components/RWTextFieldComponent";

interface Props {
    setSummaryBar?: (summaryBar: JSX.Element) => void
}

export default function Analytics({ setSummaryBar }: Props) {
    const [isLoading, setIsLoading] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const { state, init, change, updateInitial, hasChanges } = useObjectStateWithChangeTracker<AnalyticsConfigOptions>(DefaultOptions, NoChangesTracker);

    useEffect(() => {
        const getSettings = async () => {
            setIsLoading(true);
            const data = await GetAnalyticsSettings(setErrorMessage);
            if (data) {
                let savedSettings = DefaultOptions;
                for (let i = 0; i < data.length; i++) {
                    const setting = data[i];
                    (savedSettings as any)[setting.type] = setting.value;
                }
                
                init(savedSettings);
            }
            setIsLoading(false);
        };
        getSettings();
    }, [init]);
    
    const saveSettings = useCallback(async () => {
        setIsLoading(true);
        const settings = ObjToSettingsArray(state);
        const res = await SaveAnalyticsSettings(settings, setErrorMessage);
        
        if (!res) {
            setIsLoading(false);
            return false;
        }

        updateInitial();
        setShowSuccess(true);
        setIsLoading(false);
        return true;
    }, [state, updateInitial]);

    const { unsavedChangesDialog } = useUnsavedChangesDialog(hasChanges, saveSettings);

    const handleChanges = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        change(name as keyof AnalyticsConfigOptions, value);
    }

    useEffect(() => {
        const saveButton = (
            <Button
                disabled={!hasChanges}
                variant="contained"
                color="primary"
                onClick={saveSettings}
                sx={{ borderRadius: '2px', padding: 0, height: '36px', width: '80px' }}
            >
                Save
            </Button>
        );

        const SummaryBar = (
            <TitleAndActionSummaryBar
                title="Configuration > Analytics"
                browserTabTitle="Analytics > Configuration"
                action={saveButton}
            />);

        setSummaryBar && setSummaryBar(SummaryBar);
    }, [hasChanges, saveSettings, setSummaryBar]);


    return (
        <>
            {unsavedChangesDialog}
            <Snackbar open={showSuccess} autoHideDuration={3000} onClose={() => setShowSuccess(false)}>
                <Alert onClose={() => setShowSuccess(false)}>Changes Saved</Alert>
            </Snackbar>
            <Snackbar open={errorMessage !== ''}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <PageContentLayout title="Analytics Settings" showLoading={isLoading}>
                <Stack spacing={3}>
                    <TextField
                        select
                        label="Forecast Mode"
                        name="ForecastingMode"
                        value={state["ForecastingMode"]}
                        onChange={handleChanges}
                    >
                        <MenuItem value="0">Automated ( With Override )</MenuItem>
                        <MenuItem value="1">Start Date</MenuItem>
                    </TextField>
                    <TextField
                        select
                        label="Perm Billings Based On"
                        name="AnalyticsPermBillingsBasedOn"
                        value={state["AnalyticsPermBillingsBasedOn"]}
                        onChange={handleChanges}
                    >
                        <MenuItem value="0">Placement Date</MenuItem>
                        <MenuItem value="1">Start Date</MenuItem>
                        <MenuItem value="2">Created Date</MenuItem>
                    </TextField>
                    <TextField
                        select
                        label="Contract Billings"
                        name="AnalyticsContractBillingsBasedOn"
                        value={state["AnalyticsContractBillingsBasedOn"]}
                        onChange={handleChanges}
                    >
                            <MenuItem value="0">Include missing Timesheet Data</MenuItem>
                            <MenuItem value="1">Timesheet based only</MenuItem>
                    </TextField>
                    <TextField
                        select
                        label="Placement Numbers Based On"
                        name="AnalyticsPlacementNumbersBasedOn"
                        value={state["AnalyticsPlacementNumbersBasedOn"]}
                        onChange={handleChanges}
                    >
                        <MenuItem value="0">Placement Date</MenuItem>
                        <MenuItem value="1">Start Date</MenuItem>
                        <MenuItem value="2">Created Date</MenuItem>
                    </TextField>
                    <TextField
                        select
                        label="Activity Numbers Based On"
                        name="AnalyticsActivityNumbersBasedOn"
                        value={state["AnalyticsActivityNumbersBasedOn"]}
                        onChange={handleChanges}
                    >
                        <MenuItem value="0">Created Date</MenuItem>
                        <MenuItem value="1">Activity Date</MenuItem>
                    </TextField>
                    <RWTextFieldComponent
                        label="Target Label Override"
                        name="TargetLabelOverride"
                        value={state["TargetLabelOverride"]}
                        onChange={handleChanges}
                    />
                    <RWTextFieldComponent
                        label="Stretch Label Override"
                        name="StretchLabelOverride"
                        value={state["StretchLabelOverride"]}
                        onChange={handleChanges}
                    />
                    <TextField
                        select
                        label="Year Start"
                        name="YearStart"
                        value={state["YearStart"]}
                        onChange={handleChanges}
                    >
                        <MenuItem value="1">January</MenuItem>
                        <MenuItem value="2">February</MenuItem>
                        <MenuItem value="3">March</MenuItem>
                        <MenuItem value="4">April</MenuItem>
                        <MenuItem value="5">May</MenuItem>
                        <MenuItem value="6">June</MenuItem>
                        <MenuItem value="7">July</MenuItem>
                        <MenuItem value="8">August</MenuItem>
                        <MenuItem value="9">September</MenuItem>
                        <MenuItem value="10">October</MenuItem>
                        <MenuItem value="11">November</MenuItem>
                        <MenuItem value="12">December</MenuItem>
                    </TextField>
                    <RWTextFieldComponent
                        label="Net Revenue Name"
                        name="NetRevenueName"
                        value={state["NetRevenueName"]}
                        onChange={handleChanges}
                    />
                </Stack>
            </PageContentLayout>
        </>
    );
}