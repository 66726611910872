import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import {
    GridColDef,
    GridRenderCellParams,
    useGridApiRef,
    GridRowSelectionModel,
    GridColumnVisibilityModel
} from "@mui/x-data-grid-premium";
import Alert from "components/Alert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MenuItem from "@mui/material/MenuItem";
import Snackbar from "@mui/material/Snackbar";

import { Automation } from "common/models/Configuration/Automation";
import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import PageContentLayout from "layouts/PageContentLayout";
import { CopyAutomation, GetAutomations } from "services/AutomationService";
import GridWithStateWrapper from "components/GridWidthStateWrapper";
import ActionsDropDownButton from "components/SummaryBars/Actions/ActionsDropsDownMenu";
import CreateAutomationDialog from "components/Dialogs/Automations/CreateAutomationDialog";
import CallOutNotification from "components/CallOutNotification";
import { companyHasSinglePermission } from "util/PermissionsUtils";
import { Permission } from "common/models/Permissions";
import AutomationLogDataDialog from "components/Automation/AutomationLogDataDialog";
import RWTextFieldComponent from "components/RWTextFieldComponent";

interface Props {
    setSummaryBar?: (summaryBar: JSX.Element) => void
}

export default function Automations({ setSummaryBar }: Props) {
    const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [rows, setRows] = useState<Automation[]>([]);
    const [copyTemplateName, setCopyTemplateName] = useState('');
    const [showCreateAutomationDialog, setShowCreateAutomationDialog] = useState(false);
    const [callToActionTitle, setCallToActionTitle] = useState<string>('');
    const [callToActionContent, setCallToActionContent] = useState<string>('');
    const apiRef = useGridApiRef();
    const [openPreviewData, setOpenPreviewData] = useState(false);
    const [moduleId, setModuleId] = useState(0);
    const [automationId, setAutomationId] = useState(0);
    const [automationName, setAutomationName] = useState('');

    const updateModuleId = useCallback((value: number) => {
        setModuleId(value)
    }, [setModuleId]);

    const updateOpenPreviewData = useCallback((value: boolean) => {
        setOpenPreviewData(value)
    }, [setOpenPreviewData]);

    const previewDataHandler = useCallback((value: boolean) => {
        updateOpenPreviewData(value);
    }, [updateOpenPreviewData]);

    useEffect(() => {
        const handleCopyQuestionnaire = () => {
            const selectedId = +selectionModel[0];
            const row = rows.find(r => r.id === selectedId);
            if(row) setCopyTemplateName(row.name);
        };

        const handleViewAutomationLogs = () => {
            const selectedId = +selectionModel[0];
            const row = rows.find(r => r.id === selectedId);
            if(row) {
                updateModuleId(row.moduleId ?? 0);
                previewDataHandler(true);
                setAutomationId(row.id);
                setAutomationName(row.name);
            }

        };

        const actionsMenu = (
            <ActionsDropDownButton>
                <MenuItem onClick={() =>setShowCreateAutomationDialog(true)} >Create Automation</MenuItem>
                <MenuItem onClick={handleCopyQuestionnaire} disabled={ selectionModel.length !== 1 } >Copy Automation</MenuItem>
                <MenuItem onClick={handleViewAutomationLogs} disabled={ selectionModel.length !== 1 } >View Automation Logs</MenuItem>
            </ActionsDropDownButton>
        );
        const SummaryBar = (
            <TitleAndActionSummaryBar
                title="Configuration > Automation"
                browserTabTitle="Automation > Configuration"
                action={actionsMenu}
            />
        );
        setSummaryBar && setSummaryBar(SummaryBar);
    }, [setSummaryBar, selectionModel, rows, setAutomationId, automationId, updateModuleId, previewDataHandler]);

    useEffect(() => {
        if(!companyHasSinglePermission(Permission.Automation)) {
            setCallToActionTitle('Automation Experience');
            setCallToActionContent('Upgrade to the Full Automation Experience! 🚀 Unlock advanced features and unleash the full potential of Automation. <a href="mailto:support@recruitwizard.com?subject=Learn More about Recruit Wizard Automation">Contact us</a> to learn more and upgrade today.');
        }
    }, []);

    const getData = useCallback(async () => {
        setIsLoading(true);
        let data = await GetAutomations(setErrorMessage);
        if(data) {
            data.sort((a, b) => {
                return a.statusID - b.statusID || a.name.localeCompare(b.name);
            });
            setRows(data);
        }

        setIsLoading(false);
    }, [])

    useEffect(() => {
        getData();
    }, [getData]);

    const columns = useMemo<GridColDef[]>(() => {
        const dbNameRenderer = (params: GridRenderCellParams) => {
            return (
                <Link
                    to={ '/configuration/automation/' + params.id }
                    style={{ color: "inherit", textDecoration: 'underline' }}
                >
                    {params.value}
                </Link>
            );
        };

        return [
                { field: 'id', headerName: 'ID', renderCell: dbNameRenderer },
                { field: 'name', headerName: 'Name', width: 400, renderCell: dbNameRenderer },
                { field: 'triggerName', headerName: 'Trigger', width: 200 },
                { field: 'eventName', headerName: 'Event', width: 200 },
                { field: 'statusName', headerName: 'Status', width: 200 }
            ];
    }, []);

    const selectionChangeHandler = (newSelectionModel: GridRowSelectionModel) => {
        setSelectionModel(prev => {
            const prevLength = prev.length;
            const newLength = newSelectionModel.length;

            if(prevLength === 0 && newLength > 1)
                return [newSelectionModel[0]];

            if(prevLength === 1 && newLength > 0)
                return [newSelectionModel[newLength - 1]]
            return newSelectionModel;
        });
    };

    const handleCopyQuestionnaireConfirmation = async () => {
        setIsLoading(true);
        const templateId = +selectionModel[0];
        const res = await CopyAutomation(templateId, copyTemplateName, setErrorMessage);
        if(res) {
            setCopyTemplateName('');
            setSelectionModel([]);
            setShowSuccess(true);
            await getData();
        }
        setIsLoading(false);
    };

    const defaultHiddenCols: GridColumnVisibilityModel = {
        "id": false,
    };

    return (
        <>
            <Snackbar open={showSuccess} autoHideDuration={3000} onClose={() => setShowSuccess(false)}>
                <Alert onClose={() => setShowSuccess(false)}>Changes Saved</Alert>
            </Snackbar>
            <Snackbar open={errorMessage !== ''} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <CreateAutomationDialog
                closeHandler={() => setShowCreateAutomationDialog(false)}
                open={showCreateAutomationDialog}
                loadingHandler={setIsLoading}
                errorHandler={setErrorMessage}
                //successHandler={setShowSuccess}
            />
              <AutomationLogDataDialog
                    moduleId={moduleId}
                    automationName={automationName}
                    automationId={automationId}
                    open={openPreviewData}
                    cancelHandler={() => {previewDataHandler(false)}}
                    errorHandler={setErrorMessage}
                    loadingHandler={setIsLoading}/>
            <Dialog open={copyTemplateName !== ''} onClose={() => {}} fullWidth>
                <DialogTitle>Confirm</DialogTitle>
                <DialogContent dividers>
                    <RWTextFieldComponent
                        label="New Automation Name"
                        autofocus={true}
                        value={copyTemplateName}
                        onChange={(e) => setCopyTemplateName(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="error" onClick={() => setCopyTemplateName('')}>Cancel</Button>
                    <Button variant="contained" color="success" onClick={ handleCopyQuestionnaireConfirmation }>OK</Button>
                </DialogActions>
            </Dialog>
            <PageContentLayout title="Automation" showLoading={isLoading}>
            { Boolean(callToActionTitle) &&
                    <CallOutNotification
                        type="new-feature"
                        title={callToActionTitle}
                        content={callToActionContent}
                        onClose={() => setCallToActionTitle('')}
                        isHtmlContent={true}
                    />
                }
                <GridWithStateWrapper
                    gridName="configuration/automations"
                    apiRef={apiRef}
                    rows={rows}
                    columns={columns}
                    disableRowSelectionOnClick
                    disableMultipleRowSelection
                    checkboxSelection
                    pagination={true}
                    density="compact"
                    rowSelectionModel={selectionModel}
                    defaultViewModel={defaultHiddenCols}
                    onRowSelectionModelChange={ selectionChangeHandler }
                    pageSizeOptions={[100,250,500,1000]}
                />
            </PageContentLayout>
        </>
    );
}