import React, { useCallback, useEffect, useMemo, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import UserPicker from "components/Pickers/UserPicker";
import { SxProps } from "@mui/system/styleFunctionSx/styleFunctionSx";
import { Theme } from "@mui/material/styles";
import { UpdateJobSplits } from "services/JobsService";
import RWTextFieldComponent from "components/RWTextFieldComponent";

interface Props {
    open: boolean,
    jobId: number,
    consultant1Id?: number,
    consultant1Percent?: number,
    consultant2Id?: number,
    consultant2Percent?: number,
    consultant3Id?: number,
    consultant3Percent?: number,
    consultant4Id?: number,
    consultant4Percent?: number,
    consultant5Id?: number,
    consultant5Percent?: number,
    closeHandler: () => void,
    loadingHandler?: (isLoading: boolean) => void,
    successHandler?: (message: string, id1: number, p1: number, id2: number, p2: number, id3: number, p3: number, id4: number, p4: number, id5: number, p5: number) => void,
    errorHandler?: (message: string) => void
}

const leftStyle: SxProps<Theme> = { flex: '1 1 0', pr: '5px' };
const rightStyle: SxProps<Theme> = { flex: '1 1 0', pl: '5px' };
const validPositiveNumberValueRegex = /^\d+(\.\d{1,2})?$/;

export default function ChangeSplitsDialog({ open, jobId,
    consultant1Id: consultant1IdProp = 0, consultant1Percent: consultant1PercentProp = 0,
    consultant2Id: consultant2IdProp = 0, consultant2Percent: consultant2PercentProp = 0,
    consultant3Id: consultant3IdProp = 0, consultant3Percent: consultant3PercentProp = 0,
    consultant4Id: consultant4IdProp = 0, consultant4Percent: consultant4PercentProp = 0,
    consultant5Id: consultant5IdProp = 0, consultant5Percent: consultant5PercentProp = 0,
    closeHandler, loadingHandler, successHandler, errorHandler }: Props) {

    const [consultant1Id, setConsultant1Id] = useState(0);
    const [consultant1Percent, setConsultant1Percent] = useState('');
    const [consultant2Id, setConsultant2Id] = useState(0);
    const [consultant2Percent, setConsultant2Percent] = useState('');
    const [consultant3Id, setConsultant3Id] = useState(0);
    const [consultant3Percent, setConsultant3Percent] = useState('');
    const [consultant4Id, setConsultant4Id] = useState(0);
    const [consultant4Percent, setConsultant4Percent] = useState('');
    const [consultant5Id, setConsultant5Id] = useState(0);
    const [consultant5Percent, setConsultant5Percent] = useState('');

    useEffect(() => {
        if (open) {
            setConsultant1Id(consultant1IdProp);
            setConsultant1Percent(consultant1PercentProp.toString());
            setConsultant2Id(consultant2IdProp);
            setConsultant2Percent(consultant2PercentProp.toString());
            setConsultant3Id(consultant3IdProp);
            setConsultant3Percent(consultant3PercentProp.toString());
            setConsultant4Id(consultant4IdProp);
            setConsultant4Percent(consultant4PercentProp.toString());
            setConsultant5Id(consultant5IdProp);
            setConsultant5Percent(consultant5PercentProp.toString());
        }
    }, [open, consultant1IdProp, consultant1PercentProp, consultant2IdProp, consultant2PercentProp, consultant3IdProp, consultant3PercentProp, consultant4IdProp, consultant4PercentProp, consultant5IdProp, consultant5PercentProp]);

    const submitCallback = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        let totalPercent = 0;
        const p1 = consultant1Id ? +consultant1Percent : 0;
        const p2 = consultant2Id ? +consultant2Percent : 0;
        const p3 = consultant3Id ? +consultant3Percent : 0;
        const p4 = consultant4Id ? +consultant4Percent : 0;
        const p5 = consultant5Id ? +consultant5Percent : 0;
        if (consultant1Id) totalPercent += p1;
        if (consultant2Id) totalPercent += p2;
        if (consultant3Id) totalPercent += p3;
        if (consultant4Id) totalPercent += p4;
        if (consultant5Id) totalPercent += p5;

        if (p1 < 0 || p2 < 0 || p3 < 0 || p4 < 0 || p5 < 0) {
            loadingHandler && loadingHandler(false);
            errorHandler && errorHandler("Splits can't be negative");
            return;
        }
        
        if (totalPercent !== 100) {
            loadingHandler && loadingHandler(false);
            errorHandler && errorHandler('Splits must add up to 100%');
            return;
        }

        const res = await UpdateJobSplits(jobId, consultant1Id, p1, consultant2Id, p2, consultant3Id, p3, consultant4Id, p4, consultant5Id, p5, errorHandler);
        if (res) {
            successHandler && successHandler('Job Splits Updated', consultant1Id, p1, consultant2Id, p2, consultant3Id, p3, consultant4Id, p4, consultant5Id, p5);
            errorHandler && errorHandler('');
            closeHandler();
        }
        loadingHandler && loadingHandler(false);
    }, [jobId, consultant1Id, consultant2Id, consultant3Id, consultant4Id, consultant5Id, consultant1Percent, consultant2Percent, consultant3Percent, consultant4Percent, consultant5Percent, loadingHandler, successHandler, errorHandler, closeHandler]);
    
    const hasChanges = useMemo(() => {
        return consultant1Id !== consultant1IdProp ||
        consultant2Id !== consultant2IdProp ||
        consultant3Id !== consultant3IdProp ||
        consultant4Id !== consultant4IdProp ||
        consultant5Id !== consultant5IdProp ||
        consultant1Percent !== consultant1PercentProp.toString() ||
        consultant2Percent !== consultant2PercentProp.toString() ||
        consultant3Percent !== consultant3PercentProp.toString() ||
        consultant4Percent !== consultant4PercentProp.toString() ||
        consultant5Percent !== consultant5PercentProp.toString() ;
    }, [consultant1Id, consultant2Id, consultant3Id, consultant4Id, consultant5Id,
        consultant1IdProp, consultant2IdProp, consultant3IdProp, consultant4IdProp, consultant5IdProp,
        consultant1Percent, consultant2Percent, consultant3Percent, consultant4Percent, consultant5Percent,
        consultant1PercentProp, consultant2PercentProp, consultant3PercentProp, consultant4PercentProp, consultant5PercentProp
    ]);

    const onPercent1Change = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;
        if ((val.endsWith('.') && !val.endsWith('..'))|| val.match(validPositiveNumberValueRegex)) {
            setConsultant1Percent(val);
        }
    }, []);

    const onPercent2Change = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;
        if ((val.endsWith('.') && !val.endsWith('..'))|| val.match(validPositiveNumberValueRegex)) {
            setConsultant2Percent(val);
        }
    }, []);

    const onPercent3Change = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;
        if ((val.endsWith('.') && !val.endsWith('..'))|| val.match(validPositiveNumberValueRegex)) {
            setConsultant3Percent(val);
        }
    }, []);

    const onPercent4Change = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;
        if ((val.endsWith('.') && !val.endsWith('..'))|| val.match(validPositiveNumberValueRegex)) {
            setConsultant4Percent(val);
        }
    }, []);

    const onPercent5Change = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;
        if ((val.endsWith('.') && !val.endsWith('..'))|| val.match(validPositiveNumberValueRegex)) {
            setConsultant5Percent(val);
        }
    }, []);

    return (
        <>
            <Dialog open={open} fullWidth>
                <DialogTitle>Job Splits</DialogTitle>
                <DialogContent>
                    <Stack mt="10px" spacing={2}>
                        <Box display="flex">
                            <UserPicker label="Consultant 1" userId={consultant1Id} onSelect={ u => setConsultant1Id(u ? u.id : 0)} sx={leftStyle} />
                            <RWTextFieldComponent label="Consultant 1 %" value={consultant1Percent} onChange={onPercent1Change} disabled={consultant1Id === 0} sxOptions={rightStyle} />
                        </Box>
                        <Box display="flex">
                            <UserPicker label="Consultant 2" userId={consultant2Id} onSelect={ u => setConsultant2Id(u ? u.id : 0)} sx={leftStyle} />
                            <RWTextFieldComponent label="Consultant 2 %" value={consultant2Percent} onChange={onPercent2Change} disabled={consultant2Id === 0} sxOptions={rightStyle} />
                        </Box>
                        <Box display="flex">
                            <UserPicker label="Consultant 3" userId={consultant3Id} onSelect={ u => setConsultant3Id(u ? u.id : 0)} sx={leftStyle} />
                            <RWTextFieldComponent label="Consultant 3 %" value={consultant3Percent} onChange={onPercent3Change} disabled={consultant3Id === 0} sxOptions={rightStyle} />
                        </Box>
                        <Box display="flex">
                            <UserPicker label="Consultant 4" userId={consultant4Id} onSelect={ u => setConsultant4Id(u ? u.id : 0)} sx={leftStyle} />
                            <RWTextFieldComponent label="Consultant 4 %" value={consultant4Percent} onChange={onPercent4Change} disabled={consultant4Id === 0} sxOptions={rightStyle} />
                        </Box>
                        <Box display="flex">
                            <UserPicker label="Consultant 5" userId={consultant5Id} onSelect={ u => setConsultant5Id(u ? u.id : 0)} sx={leftStyle} />
                            <RWTextFieldComponent label="Consultant 5 %" value={consultant5Percent} onChange={onPercent5Change} disabled={consultant5Id === 0} sxOptions={rightStyle} />
                        </Box>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="error" onClick={ closeHandler }>Cancel</Button>
                    <Button variant="contained" color="success" disabled={!hasChanges} onClick={ submitCallback } >Submit</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}