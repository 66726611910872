import Alert from "components/Alert";
import Snackbar from "@mui/material/Snackbar";
import React, { useEffect, useState } from "react";
import ScreenLayoutAdmin from "components/ScreenLayouts/Contacts/ScreenLayoutAdmin";
import OnlyTitleSummaryBar from "components/SummaryBars/OnlyTitleSummaryBar";
import PageContentLayout from "layouts/PageContentLayout";

interface Props {
    type: 'record' | 'edit',
    setSummaryBar?: (summaryBar: JSX.Element) => void
}

export default function ContactsScreenLayoutPage({ type, setSummaryBar }: Props) {
    const [isLoading, setIsloading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        if (setSummaryBar) {
            const typeTitle = type === 'record' ? 'View' : 'Create / Edit';
            const summaryBar = <OnlyTitleSummaryBar title={`Configuration > Screen Layouts > Contacts - ${typeTitle}`} browserTabTitle="Screen Layouts > Configuration" />;
            setSummaryBar(summaryBar);
        }
    }, [setSummaryBar, type]);
    
    return (
        <>
            <Snackbar open={errorMessage !== ''} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <PageContentLayout showLoading={isLoading}>
                { type === 'record' && <ScreenLayoutAdmin layoutType="Record" errorHandler={setErrorMessage} loadingHandler={setIsloading} /> }
                { type === 'edit' && <ScreenLayoutAdmin layoutType="Edit" errorHandler={setErrorMessage} loadingHandler={setIsloading} /> }
            </PageContentLayout>
        </>
    );
}