import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { DateTimePicker } from "@mui/x-date-pickers-pro";
import moment from "moment";
import React, { useCallback, useMemo } from "react";

interface Props {
    open: boolean,
    dateTime: string,
    onDateChange: (date: string) => void,
    onClose: () => void,
    onSubmit: () => void
}

export default function RescheduleDialog({ open, dateTime, onDateChange, onClose, onSubmit }: Props) {
    const handleDateChange = useCallback((m: moment.Moment | null) => {
        if (m) onDateChange(m.format('YYYY-MM-DDTHH:mm'));
        else onDateChange('');
    }, [onDateChange]);

    const mDate = useMemo(() => {
        if (dateTime) {
            const m = moment(dateTime);
            if (m.isValid()) return m;
        }
        return null;
    }, [dateTime]);
    
    return (
        <Dialog open={ open } fullWidth>
            <DialogTitle>Reschedule Message</DialogTitle>
            <DialogContent dividers>
                <Box display="flex">
                    <DateTimePicker
                        value={mDate}
                        label="New Date"
                        onChange={handleDateChange}
                        sx={{ flex: '1 1 0' }}
                        slotProps={{
                            actionBar: { actions: ["clear", "today", "cancel", "accept"] },
                        }}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="error" onClick={ onClose }>Cancel</Button>
                <Button variant="contained" color="success" onClick={ onSubmit }>Reschedule</Button>
            </DialogActions>
        </Dialog>
    );
}