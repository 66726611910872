import Alert from "@mui/material/Alert";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Snackbar from "@mui/material/Snackbar";
import Typography from "@mui/material/Typography";
import { EmailReceiverData } from "common/models/Candidates/EmailReceiverData";
import PersonIcon from '@mui/icons-material/Person';
import ConfirmationDialog from "components/Dialogs/Generic/ConfirmationDialog";
import TagsMinRequiredManagement from "components/Tags/TagsMinRequiredManagement";
import PageContentLayout from "layouts/PageContentLayout";
import PageLayout from "layouts/PageLayout";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { EmailReceiverCreateFollowUp, EmailReceiverSendRejectionEmail, EmailReceiverUpdateApplication, GetEmailReceiverData } from "services/EmailReceiverService";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import ActionsSplitButton from "components/SummaryBars/Actions/ActionsSplitButton";
import MenuItem from "@mui/material/MenuItem";
import Autocomplete from "@mui/material/Autocomplete";
import { MessageTemplate } from "common/models/Templates/MessageTemplate";
import TextField from "@mui/material/TextField";

const greenBgColor = "#1da826";
const yellowBgColor = "#fecc02";
const blueBgColor = "#0366cc";
const redBgColor = "#cc0303";

const expiredTokenErrorMessage = "Whoops, it appears that the Security Token for this action has expired. Security Tokens are only valid for 7 Days. If you are trying to use the rating icons for a Job Application that was made more than 7 days ago, you will need to rate the candidate from within Recruit Wizard instead of from the email.";

export default function EmailReceiverPage() {
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [infoMessage, setInfoMessage] = useState('');
    const [tmpErrorMessage, setTmpErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [data, setData] = useState<EmailReceiverData>();
    const [isConfirmationNeeded, setIsConfirmationNeeded] = useState(false);
    const [isTagsNeeded, setIsTagsNeeded] = useState(false);
    const [isRatingUpdated, setIsRatingUpdated] = useState(false);
    const [candidateId, setCandidateId] = useState(0);
    const [jobId, setJobId] = useState(0);
    const [followUpCreated, setFollowUpCreated] = useState(false);
    const [isRejectionEmailFeatureActive, setIsRejectionEmailFeatureActive] = useState(false);
    const [isRejectionEmailRequired, setIsRejectionEmailRequired] = useState(false);
    const [rejectionSentDate, setRejectionSentDate] = useState('');
    const [showRejectionEmailDialog, setShowRejectionEmailDialog] = useState(false);
    const [rejectionTemplate, setRejectionTemplate] = useState<MessageTemplate | null>(null);
    const params = useParams();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const key = useMemo(() => (params.key ?? '') , [params.key]);
    const ratingId = useMemo(() => {
        const param = searchParams.get('ratingId');
        if (param) {
            const parsedId = +param;
            if (isNaN(parsedId)) return undefined;
            return parsedId;
        }
    }, [searchParams]);
    
    const isViewCandidate = useMemo(() => {
        const param = searchParams.get('ViewCandidate');
        if (param) {
            return param.toLowerCase() === 'true';
        }
        return false;
    }, [searchParams]);

    const expiredTokenErrorHandler = useCallback((message: string) => {
        if (message === 'Token has expired') setErrorMessage(expiredTokenErrorMessage);
        else setErrorMessage(message);
    }, []);

    useEffect(() => {
        const getData = async () => {
            setIsLoading(true);
            const res = await GetEmailReceiverData(key, ratingId ?? 4, expiredTokenErrorHandler);
            if (res) {
                if (isViewCandidate) {
                    navigate(`/candidates/${res.candidateId}?jobId=${res.jobId}`);
                }
                else {
                    setIsConfirmationNeeded(res.isConfirmJobApplication);
                    setIsTagsNeeded(res.isMandatoryTagsCheckNeeded);
                    setCandidateId(res.candidateId);
                    setJobId(res.jobId);
                    setIsRejectionEmailFeatureActive(res.isRejectionEmailsFeatureActive);
                    setIsRejectionEmailRequired(res.isRejectionEmailsRequired);
                    setRejectionSentDate(res.rejectionEmailSentDate);
                    setData(res);
                }
            }
            setIsLoading(false);
        };
        getData();
    }, [expiredTokenErrorHandler, isViewCandidate, key, navigate, ratingId]);

    const addTagsSuccessHandler = useCallback((message: string) => {
        setSuccessMessage(message);
        setIsTagsNeeded(false);
    }, []);

    const createFollowUp = useCallback(async () => {
        setIsLoading(true);
        const res = await EmailReceiverCreateFollowUp(key, setErrorMessage);
        if (res) {
            setFollowUpCreated(true);
            setSuccessMessage('Follow up call logged')
        }
        setIsLoading(false);
    }, [key]);

    useEffect(() => {
        const updateApplication = async () => {
            if (data && !isConfirmationNeeded && !isTagsNeeded) {
                setIsLoading(true);
                const res = await EmailReceiverUpdateApplication(key, ratingId ?? 4, setErrorMessage);
                if (res) setIsRatingUpdated(true);
                setIsLoading(false);
            }
        };
        data && !isConfirmationNeeded && !isTagsNeeded && updateApplication();
    }, [data, isConfirmationNeeded, isTagsNeeded, key, ratingId]);

    useEffect(() => {
        if (isRatingUpdated && ratingId === 0 && isRejectionEmailFeatureActive) {
            if (Boolean(rejectionSentDate)) setInfoMessage(`A rejection email was previously sent on ${rejectionSentDate}`);
            else setShowRejectionEmailDialog(true);
        }
    }, [isRatingUpdated, isRejectionEmailFeatureActive, ratingId, rejectionSentDate]);

    const ratingColor = useMemo(() => {
        switch (ratingId) {
            case 4: return greenBgColor;
            case 2: return yellowBgColor;
            case 1: return blueBgColor;
            case 0: return redBgColor;
            case 3: return undefined;
            default: return greenBgColor;
        }
    }, [ratingId]);

    const ratingName = useMemo(() => {
        switch (ratingId) {
            case 4: return 'GREEN';
            case 2: return 'YELLOW';
            case 1: return 'BLUE';
            case 0: return 'RED';
            case 3: return 'WHITE';
            default: return 'GREEN';
        }
    }, [ratingId]);

    const sendRejectionEmailCallback = useCallback(async (laterOption: number) => {
        if (rejectionTemplate) {
            setIsLoading(true);
            const res = await EmailReceiverSendRejectionEmail(key, rejectionTemplate.id, laterOption, setErrorMessage);
            if (res) {
                setSuccessMessage('Rejection email sent');
                setShowRejectionEmailDialog(false);
            }
            setIsLoading(false);
        }
    }, [key, rejectionTemplate]);

    const closeConfirmDialogHandler = useCallback(() => {
        navigate('/');
    }, [navigate]);

    return (
        <>
            <Snackbar open={successMessage !== ''} autoHideDuration={3000} onClose={() => setSuccessMessage('')}>
                <Alert onClose={() => setSuccessMessage('')}>{ successMessage }</Alert>
            </Snackbar>
            <Snackbar open={errorMessage !== ''} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <Snackbar open={Boolean(tmpErrorMessage)} autoHideDuration={3000} onClose={() => setTmpErrorMessage('')}>
                <Alert severity="error" onClose={() => setTmpErrorMessage('')}>{tmpErrorMessage}</Alert>
            </Snackbar>
            <Snackbar open={infoMessage !== ''} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity="info" onClose={() => setInfoMessage('')}>{ infoMessage }</Alert>
            </Snackbar>
            <PageLayout>
                <PageContentLayout showLoading={isLoading}>
                    <ConfirmationDialog
                        open={isConfirmationNeeded}
                        message={`Please confirm your rating of ${ratingName}`}
                        title="Confirm action"
                        onContinue={() => setIsConfirmationNeeded(false)}
                        onClose={closeConfirmDialogHandler}
                    />
                    {isRejectionEmailFeatureActive && data && data.rejectionEmailTemplates &&
                        <Dialog open={showRejectionEmailDialog} fullWidth>
                            <DialogTitle>Rejection Email</DialogTitle>
                            <DialogContent>
                                <Box pt="10px">
                                    <Autocomplete
                                        size="small"
                                        options={data.rejectionEmailTemplates}
                                        getOptionLabel={o => o.name}
                                        isOptionEqualToValue={(o,v) => o.id === v.id}
                                        onChange={(e,v) => setRejectionTemplate(v)}
                                        renderInput={params => <TextField {...params} label="Rejection Email Template" />}
                                    />
                                </Box>
                            </DialogContent>
                            <DialogActions>
                                <Button variant="contained" color="error" disabled={isRejectionEmailRequired} onClick={() => setShowRejectionEmailDialog(false)}>Cancel</Button>
                                <Box ml="5px">
                                    <ActionsSplitButton mainAction={ () => sendRejectionEmailCallback(0) } mainActionDisabled={!Boolean(rejectionTemplate)} secondaryActionDisabled={!Boolean(rejectionTemplate)} disablePopperPortal={false} label="Send" color="success" menuPlacement="top-end">
                                        <MenuItem onClick={ () => sendRejectionEmailCallback(1) }>In an Hour</MenuItem>
                                        <MenuItem onClick={ () => sendRejectionEmailCallback(2) }>In 4 Hours</MenuItem>
                                        <MenuItem onClick={ () => sendRejectionEmailCallback(3) }>In 8 Hours</MenuItem>
                                        <MenuItem onClick={ () => sendRejectionEmailCallback(4) }>In 12 Hours</MenuItem>
                                        <MenuItem onClick={ () => sendRejectionEmailCallback(5) }>In 24 Hours</MenuItem>
                                        <MenuItem onClick={ () => sendRejectionEmailCallback(6) }>In 2 Days</MenuItem>
                                        <MenuItem onClick={ () => sendRejectionEmailCallback(7) }>In 3 Days</MenuItem>
                                        <MenuItem onClick={ () => sendRejectionEmailCallback(9) }>In 5 Days</MenuItem>
                                        <MenuItem onClick={ () => sendRejectionEmailCallback(8) }>In 1 Week</MenuItem>
                                    </ActionsSplitButton>
                                </Box>
                            </DialogActions>
                        </Dialog>
                    }
                    {!isConfirmationNeeded && isTagsNeeded && data &&
                        <TagsMinRequiredManagement
                            emailReceiverkey={key}
                            assignedTags={data.currentCandidateTags ?? []}
                            availableStructuredTags={data.availableStructuredTags ?? []}
                            availableTags={data.availableTags ?? []}
                            tagSettings={data.userTagSettings ?? []}
                            loadingHandler={setIsLoading}
                            successHandler={addTagsSuccessHandler}
                            errorHandler={setErrorMessage}
                        />
                    }
                    {isRatingUpdated &&
                        <Box display="flex" flexDirection="column" gap={2} alignItems="center">
                            <Typography variant="h5">You have rated this candidate</Typography>
                            <Avatar sx={{ bgcolor: ratingColor}}>
                                <PersonIcon />
                            </Avatar>
                            <Button variant="contained" disabled={followUpCreated} onClick={createFollowUp}>Log a Follow up Call</Button>
                            <Link to={`/candidates/${candidateId}?jobId=${jobId}`}>
                                <Button variant="contained">Go to Candidate Record</Button>
                            </Link>
                        </Box>
                    }
                </PageContentLayout>
            </PageLayout>
        </>
    );
}
