import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import React, { useCallback, useEffect, useState } from "react";
import { MenuOptionDefinition } from "common/models/MenuDefinition";
import MenuOptionRenderer from "./MenuOptionRenderer";

interface Props {
    index: number,
    hoveredIndex: number | null,
    definition: MenuOptionDefinition,
    action?: () => void,
    hoveredIndexHandler: (i: number | null) => void,
    closeMenuHandler: () => void
}

export default function SubMenuContainer({ definition, index, hoveredIndex, action, hoveredIndexHandler, closeMenuHandler }: Props) {
    const [anchor, setAnchor] = useState<HTMLElement | null>(null);
    const [hoveredSubIndex, setHoveredSubIndex] = useState<number | null>(null);
    const handleCloseSubmenu = useCallback(() => setAnchor(null), []);

    const onMouseEnterCallback = useCallback((e: React.MouseEvent<HTMLElement>) => {
        if (definition.disabled) return;
        hoveredIndexHandler(index);
        setAnchor(e.currentTarget);
    }, [definition.disabled, hoveredIndexHandler, index]);

    useEffect(() => {
        if (index !== hoveredIndex) setAnchor(null);
    }, [index, hoveredIndex]);

    return (
        <>
            <MenuItem onMouseEnter={ onMouseEnterCallback } onClick={action} disabled={definition.disabled}>
                <ListItemText>{definition.label}</ListItemText>
                <ArrowRightIcon />
            </MenuItem>
            {definition.subMenu && definition.subMenu.length > 0 && index === hoveredIndex &&
                <Popper open={ Boolean(anchor) } anchorEl={anchor} placement="left-start" sx={{ zIndex: t => t.zIndex.appBar - 2 }}>
                    <Paper elevation={8}>
                        <MenuList>
                            {definition.subMenu.map((d, i) => {
                                return (
                                    <MenuOptionRenderer
                                        key={i}
                                        index={i}
                                        hoveredIndex={hoveredSubIndex}
                                        hoveredIndexHandler={ setHoveredSubIndex }
                                        closeMenuHandler={ handleCloseSubmenu }
                                        definition={d}
                                    />
                                );
                            })}
                        </MenuList>
                    </Paper>
                </Popper>
            }
        </>
    );
}