import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { EmailRecipient } from "common/models/EmailRecipient";

interface Props {
    open: boolean,
    entityTypeId: number,
    totalRecipients: EmailRecipient[],
    invalidEmailOrPhoneRecipients: EmailRecipient[],
    noPrimaryContactRecipients: EmailRecipient[],
    optOutRecipients: EmailRecipient[],
    validRecipients: EmailRecipient[],
    closeHandler: () => void,
    continueHandler: () => void,
    isSmsRecipients?: boolean,
    isOverLimit?: boolean,
}

const recipientLinkStyle: React.CSSProperties = { width: '100%', color: 'inherit', textDecoration: 'none', padding: '0 16px' };
const listSpanStyle: React.CSSProperties = { cursor: 'pointer', textDecoration: 'underline' };

export default function RecipientsValidationDialog({ open, entityTypeId, totalRecipients, invalidEmailOrPhoneRecipients, noPrimaryContactRecipients, optOutRecipients, validRecipients, closeHandler, continueHandler, isSmsRecipients = false, isOverLimit = false }: Props) {
    const [listType, setListType] = useState<null | 'total' | 'invalid' | 'noContact' |'optOut' | 'valid'>(null);
    const listTitle = useMemo(() => {
        if (listType === null) return '';
        if (listType === 'total') return 'Total Selected';
        if (listType === 'invalid') return isSmsRecipients ? 'Invalid Phone' : 'Invalid Email Address';
        if (listType === 'noContact') return 'Records with no primary contact';
        if (listType === 'optOut') return 'Invalid Opt Out';
        if (listType === 'valid') return 'Valid Records';
        return '';
    }, [listType, isSmsRecipients]);

    const listItems = useMemo(() => {
        if (listType === null) return [];
        if (listType === 'total') return totalRecipients;
        if (listType === 'invalid') return invalidEmailOrPhoneRecipients;
        if (listType === 'noContact') return noPrimaryContactRecipients;
        if (listType === 'optOut') return optOutRecipients;
        if (listType === 'valid') return validRecipients;
        return [];
    }, [listType, totalRecipients, invalidEmailOrPhoneRecipients, noPrimaryContactRecipients, optOutRecipients, validRecipients]);

    const recipientPath = useMemo(() => {
        if (entityTypeId === 1) return 'clients';
        if (entityTypeId === 2) return 'contacts';
        if (entityTypeId === 3) return 'candidates';
        return '';
    }, [entityTypeId]);

    const noContactList = useMemo(() => {
        if (listType === 'noContact') {
            return listItems.map((r, i) => {
                let recordName = '';
                let recordPath = '';

                if (r.candidate) {
                    recordName = r.candidate.fullName;
                    recordPath = '/candidates/' + r.candidate.id;
                }

                if (r.client) {
                    recordName = r.client.tradingName ? r.client.tradingName : r.client.legalName;
                    recordPath = '/clients/' + r.client.id;
                }

                if (r.contact) {
                    recordName = r.contact.fullName;
                    recordPath = '/contacts/' + r.contact.id
                }

                return (
                    <ListItemButton key={i}>
                        <Link to={recordPath} target="_blank" style={recipientLinkStyle}>
                            <ListItemText primary={recordName} />
                        </Link>
                    </ListItemButton>
                );
            });
        }
        return <></>;
    }, [listType, listItems]);

    return (
        <>
            <Dialog open={ open && listType !== null && listItems.length > 0 }>
                <DialogTitle>{listTitle}</DialogTitle>
                <DialogContent>
                    {listType !== 'noContact' && (
                        <List>
                            {listItems.map((r, i) => (
                                <ListItemButton key={i} disableGutters={r.id !== 0}>
                                    {r.id ? (
                                        <Link to={`/${recipientPath}/${r.id}`} target="_blank" style={recipientLinkStyle}>
                                            <ListItemText primary={r.name} secondary={ isSmsRecipients ? r.mobile : r.email} />
                                        </Link>
                                    ) : <ListItemText primary="[No Primary Contact]" secondary="Invalid" />}
                                </ListItemButton>
                            ))}
                        </List>
                    )}
                    {listType === 'noContact' && (
                        <List>
                            {noContactList}
                        </List>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={ () => setListType(null) }>Ok</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={ open } fullWidth>
                <DialogTitle>Warning</DialogTitle>
                <DialogContent>
                    { totalRecipients.length > 0 && <div>Total Selected - <span style={ listSpanStyle } onClick={ () => setListType('total') }>{totalRecipients.length}</span></div> }
                    { invalidEmailOrPhoneRecipients.length > 0 && <div>Invalid { isSmsRecipients ? 'Phone' : 'Email Address' } - <span style={ listSpanStyle } onClick={ () => setListType('invalid') }>{invalidEmailOrPhoneRecipients.length}</span></div> }
                    { noPrimaryContactRecipients.length > 0 && <div>No Primary Contact - <span style={ listSpanStyle } onClick={ () => setListType('noContact') }>{noPrimaryContactRecipients.length}</span></div> }
                    { optOutRecipients.length > 0 && <div>Invalid Opt Out - <span style={ listSpanStyle } onClick={ () => setListType('optOut') }>{optOutRecipients.length}</span></div> }
                    { validRecipients.length > 0 && <div>Valid Records - <span style={ listSpanStyle } onClick={ () => setListType('valid') }>{validRecipients.length}</span></div> }
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="error" onClick={ closeHandler }>Cancel</Button>
                    <Button variant="contained" disabled={validRecipients.length === 0 || isOverLimit} onClick={ continueHandler } color="success">Proceed with valid records [ {validRecipients.length} ]</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}