import React from "react";
import ScheduledMessagesPageContent from "components/Content/ScheduledMessagesPageContent";

interface Props {
    setSummaryBar?: (summaryBar: JSX.Element) => void
}

export default function ScheduledMessagesPage({ setSummaryBar }: Props) {
    return (
        <ScheduledMessagesPageContent
            summaryBarTitle="Configuration > Scheduled Messages"
            summaryBarTabTitle="Scheduled Messages > Configuration"
            setSummaryBar={setSummaryBar}
            dataSource="customer"
        />
    );
}