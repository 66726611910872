export function GetComplianceItemScopeName(scopeId: number) {
    switch (scopeId) {
        case 1: return 'Candidate';
        case 2: return 'Placement';
        default: return '';
    }
}

export function GetComplianceItemTypeName(typeId: number) {
    switch (typeId) {
        case 1: return 'General';
        case 2: return 'Baseline Required';
        case 3: return 'State Specific';
        default: return '';
    }
}

export function GetComplianceItemStatusName(statusId: number) {
    switch (statusId) {
        case 0: return 'Inactive';
        case 1: return 'Active';
        default: return '';
    }
}