import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";

import PageLayout from "layouts/PageLayout";
import PageContentLayout from "layouts/PageContentLayout";
import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import { Opportunity } from "common/models/Opportunities";
import { DeleteOpportunityById, GetOpportunityById } from "services/OpportunitiesService";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { GetSettingBySettingName } from "services/UsersService";

import Avatar from '@mui/material/Avatar';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import ConfirmationDialog from "components/Dialogs/Generic/ConfirmationDialog";
import Snackbar from "@mui/material/Snackbar";
import Alert from "components/Alert";
import ActionMenu from "components/Menus/ActionMenu";
import { AllowJobsAddEdit } from "common/data/Permissions/JobsAccess";
import moment from "moment";
import { GetActivitiesSettings } from "services/ConfigurationService";
import { ActivitySettings } from "common/models/Configuration/Activities";
import { MenuOptionDefinition } from "common/models/MenuDefinition";
import EditActivityDialog from "components/Dialogs/Activities/EditActivityDialog";
import { DefaultActivity } from "util/Definitions/Activities";
import { AllowOpportunitiesAddEdit, AllowOpportunitiesDelete } from "common/data/Permissions/OpportunitiesAccess";
import PreviewLoaderComponent from "components/Previews/PreviewLoader";
import TagsManagementDialog from "components/Dialogs/TagsManagementDialog";
import ActivitiesGridComponent from "components/Grids/ActivitiesGrid";
import DocumentsGridComponent from "components/Grids/DocumentsGrid";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { Permission } from "common/models/Permissions";
import { companyHasSinglePermission, userHasSinglePermission } from "util/PermissionsUtils";
import { AddRecentRecord } from "util/LocalStorageUtils";
import ViewRecordScreenLayout from "components/ScreenLayouts/Opportunities/ViewRecordScreenLayout";
import CloseOpportunitiesDialog from "components/Dialogs/Opportunities/CloseOpportunitiesDialog";
import ConvertToJobDialog from "components/Dialogs/Opportunities/ConvertToJobDialog";
import GenerateDocumentDialog from "components/Dialogs/Documents/GenerateDocumentDialog";
import usePartnerActions from "hooks/UsePartnerActions";
import ShowIframeDialog from "components/Dialogs/Generic/ShowIFrameDialog";
import { PartnerActionType } from "common/models/PartnerActions";
import useRecordHotkeys from "hooks/UseRecordHotkeys";
import { GetActiveMeetingTypes } from "services/MeetingsService";
import { MeetingType } from "common/models/Configuration/MeetingType";
import MeetingsGridComponent from "../../components/Grids/MeetingsGrid";

const summaryLinkStyle: React.CSSProperties = { color: 'inherit', textDecoration: 'none' };
// const fullHeightStyle: React.CSSProperties = { height: '100%' };
const formatDateString = (d: string, f: string = 'DD MMM YYYY h:mm A') => {
    if (d && d !== '0001-01-01T00:00:00') {
        const m = moment(d);
        if (m.isValid()) return m.format(f);
    }
    return 'Never';
}

const renderSummary = (c: Opportunity) => {
    const tooltipContent = (
        <table>
            <tbody>
                <tr>
                    <Typography variant="caption" fontWeight={600} component="td">ID</Typography>
                    <Typography variant="caption" pl="10px" component="td">{c.id}</Typography>
                </tr>
                <tr>
                    <Typography variant="caption" fontWeight={600} component="td" sx={{ verticalAlign: 'top' }}>Name</Typography>
                    <Typography variant="caption" pl="10px" component="td">{c.name}</Typography>
                </tr>
                <tr>
                    <Typography variant="caption" fontWeight={600} component="td">Created Date</Typography>
                    <Typography variant="caption" pl="10px" component="td">{formatDateString(c.createdDate)}</Typography>
                </tr>
                <tr>
                    <Typography variant="caption" fontWeight={600} component="td">Created By</Typography>
                    <Typography variant="caption" pl="10px" component="td">{c.createdByName}</Typography>
                </tr>
                <tr>
                    <Typography variant="caption" fontWeight={600} component="td">Updated Date</Typography>
                    <Typography variant="caption" pl="10px" component="td">{formatDateString(c.updatedDate)}</Typography>
                </tr>
                <tr>
                    <Typography variant="caption" fontWeight={600} component="td">Updated By</Typography>
                    <Typography variant="caption" pl="10px" component="td">{c.updatedByName}</Typography>
                </tr>
            </tbody>
        </table>
    );
    const locationUrl = '/clients/' + c.clientID;

    const opportunityName = (
        <Tooltip arrow title={tooltipContent}>
            <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{c.name} ({c.statusName}) for <a href={locationUrl} style={{ color: 'inherit', textDecoration: 'underline' }} key="clientNameAction" target="_blank" rel="noopener noreferrer" >{`${c.clientName}`}</a> - {c.typeName}</span>
        </Tooltip>
    );

    return (<>{opportunityName}</>);
};

const entityTypeId = 8;
const contactEntityTypeId = 2;

export default function OpportunityRecord() {
    const [opportunity, setOpportunity] = useState<Opportunity>();
    const [activitySettings, setActivitySettings] = useState<ActivitySettings[]>([]);
    const [selectedActivity, setSelectedActivity] = useState<ActivitySettings | null>(null);
    const [noteActivitySetting, setNoteActivitySetting] = useState<ActivitySettings | null>(null);
    const [meetingTypes, setMeetingTypes] = useState<MeetingType[]>([]);
    const params = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [isFetchingDefaultTab, setIsFetchingDefaultTab] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [isRecordDeleted, setIsRecordDeleted] = useState(false);
    const [isLoadingTab, setIsLoadingTab] = useState(false);
    const [showTagsPrevew, setShowTagsPreview] = useState(false);
    const [showTagsPrevewNoDelay, setShowTagsPreviewNoDelay] = useState(false);
    const [showTagsManagement, setShowTagsManagement] = useState(false);
    const [showGenerateDocumentsDialog, setShowGenerateDocumentsDialog] = useState(false);
    const [convertToJobType, setConvertToJobType] = useState<'contract' | 'perm' | 'fixedTerm' | null>(null);
    const [searchParams] = useSearchParams();
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showCloseOpportunityDialog, setShowCloseOpportunityDialog] = useState(false);
    const [isFinishedSetup, setIsFinishedSetup] = useState(false);
    const [refreshActivitiesGridControl, setRefreshActivitiesGridControl] = useState(false);
    const [activeTab, setActiveTab] = useState('OPPORTUNITIES_Home');
    const [defaultTab, setDefaultTab] = useState<string>();
    const [tabSetupFinished, setTabSetupFinished] = useState(false);
    const [tabActionMenuOptions, setTabActionMenuOptions] = useState<MenuOptionDefinition[]>([]);
    const [showPartnerActionPopup, setShowPartnerActionPopup] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (!showTagsPrevew) setShowTagsPreviewNoDelay(false);
    }, [showTagsPrevew]);
    const canAccessActivities = useMemo(() => companyHasSinglePermission(Permission.Activities) && userHasSinglePermission(Permission.Activities), []);

    const opportunityId = useMemo(() => {
        const parsedId = +(params.id ?? '0');
        if (isNaN(parsedId)) return 0;
        return parsedId;
    }, [params.id]);

    const opportunityIdArray = useMemo(() => [opportunityId], [opportunityId]);

    const partnerActionsData = useMemo(() => ({ opportunityId }), [opportunityId]);
    const { partnerActions, popupTitle, popupUrl } = usePartnerActions(PartnerActionType.Opportunities, partnerActionsData, setShowPartnerActionPopup);

    const canAddEditOpportunities = useMemo(() => AllowOpportunitiesAddEdit(), []);
    const canDeleteOpportunities = useMemo(() => AllowOpportunitiesDelete(), []);
    const canAddJobs = useMemo(() => AllowJobsAddEdit(), []);
    const canGenerateDocuments = useMemo(() => companyHasSinglePermission(Permission.DocumentTemplates) && userHasSinglePermission(Permission.DocumentTemplates), []);

    const hotkeySettings = useMemo<Record<string, () => void>>(() => {
        return {
            n: () => noteActivitySetting && setSelectedActivity({...noteActivitySetting}),
            e: () => navigate(`/opportunities/${opportunityId}/edit`),
            t: () => setShowTagsManagement(true),
        };
    }, [navigate, noteActivitySetting, opportunityId]);

    useRecordHotkeys(hotkeySettings, !canAddEditOpportunities);

    useEffect(() => {
        const getData = async () => {
            setIsLoading(true);
            const res = await GetOpportunityById(opportunityId);
            if (res === null) navigate('/not-found');
            const settings = await GetActivitiesSettings(8, undefined, setErrorMessage);
            if (res) {
                AddRecentRecord(8, opportunityId, res.name);
                setOpportunity(res);
            }
            if (settings) setActivitySettings(settings);
            setIsLoading(false);
        };
        getData();
    }, [opportunityId, navigate]);

    useEffect(() => {
        const getMeetingTypes = async () => {
            const res = await GetActiveMeetingTypes(contactEntityTypeId);
            if (res) setMeetingTypes(res);
        };
        getMeetingTypes();
    }, []);

    useEffect(() => {
        const tabParam = searchParams.get('tab');
        const getData = async () => {
            setIsFetchingDefaultTab(true);
            if (!tabParam) {
                const tabSetting = await GetSettingBySettingName('TabDefaultsOpportunities');
                if (tabSetting) setDefaultTab(tabSetting);
            }
            else setActiveTab(tabParam)
            setIsFetchingDefaultTab(false);
            setIsFinishedSetup(true);
        };
        getData();
    }, [searchParams]);

    const convertToJobHandler = useCallback((jobType: "contract" | "perm" | "fixedTerm") => {
        if (!opportunity) return;
        if (!Boolean(opportunity.clientID)) {
            setErrorMessage('Opportunity must have a Client before converting to a Job');
            return;
        }
        if (!Boolean(opportunity.contactID)) {
            setErrorMessage('Opportunity must have a Contact before converting to a Job');
            return;
        }
        setConvertToJobType(jobType);
    }, [opportunity]);

    const actionMenuDefinitions = useMemo<MenuOptionDefinition[]>(() => {
        const isOpen = opportunity ? opportunity.statusID === 1 : false;
        const contactId = opportunity ? opportunity.contactID : 0;
        let actions: MenuOptionDefinition[] = [
            ...partnerActions,
            ...tabActionMenuOptions,
            { label: 'Delete', type: 'action', action: () => setShowDeleteDialog(true), allow: () => canDeleteOpportunities },
            { label: 'Close Opportunity', type: 'action', action: () => setShowCloseOpportunityDialog(true), allow: () => canAddEditOpportunities && isOpen },
            { label: 'Generate Document', type: 'action', action: () => setShowGenerateDocumentsDialog(true), allow: () => canGenerateDocuments },
            { label: 'Convert to Job', type: 'parent', allow: () => canAddJobs, subMenu: [
                { label: 'Contract', type: 'action', action: () => convertToJobHandler('contract') },
                { label: 'Fixed Term', type: 'action', action: () => convertToJobHandler('fixedTerm') },
                { label: 'Permanent', type: 'action', action: () => convertToJobHandler('perm') },
            ]}
        ];

        for (let i = 0; i < activitySettings.length; i++) {
            const setting = activitySettings[i];
            if (setting.category === 2) continue;
            if (setting.id === 18) setNoteActivitySetting(setting);
            else
                actions.push({ label: setting.customName, type: 'action', action: () => setSelectedActivity(setting) });
        }

        let meetingActions: MenuOptionDefinition = {
            label: 'Meetings', type: 'parent', subMenu: []
        };

        if (meetingTypes.length > 0 && meetingActions.subMenu) {
            for (let i = 0; i < meetingTypes.length; i++) {
                const t = meetingTypes[i];
                const href = `/meetings/create?contactIds=${contactId}&typeId=${t.id}&source=${contactEntityTypeId}`;
                meetingActions.subMenu?.push({ label: t.name, type: 'link', href: href, disabled: contactId === 0 });
            }
        }

        if (meetingActions.subMenu && meetingActions.subMenu.length > 0) actions.push(meetingActions);

        return actions;
    }, [opportunity, partnerActions, tabActionMenuOptions, meetingTypes, canDeleteOpportunities, canAddEditOpportunities, canGenerateDocuments, canAddJobs, convertToJobHandler, activitySettings]);

    const SummaryBar = useMemo(() => {
        if (opportunity) {
            const tagsAction = (
                <Avatar
                    key="tagsAction"
                    onClick={canAddEditOpportunities ? () => setShowTagsManagement(true) : () => setShowTagsPreviewNoDelay(true)}
                    onMouseEnter={ () => setShowTagsPreview(true) }
                    onMouseLeave={ () => setShowTagsPreview(false) }
                    sx={{ bgcolor: '#f209a6', mr: '5px', cursor: 'pointer' }}>
                    { opportunity.tags ? <LocalOfferIcon /> : <LocalOfferOutlinedIcon /> }
                </Avatar>
            );

            const action1 = canAddEditOpportunities ? (
                <Link key="editLink" to={`/opportunities/${opportunityId}/edit`} style={summaryLinkStyle}>
                    <Button variant="contained" color="success" sx={{ mr: '5px' }}>Edit</Button>
                </Link>
            ) : <React.Fragment key="editPlaceholder" />;

            const action = (
                <Box key="actionsMenu">
                    <ActionMenu definition={actionMenuDefinitions} />
                </Box>
            );

            return (
                <TitleAndActionSummaryBar
                    height="4.5rem"
                    title={opportunity ? renderSummary(opportunity) : ''}
                    action={[tagsAction, action1, action]}
                    browserTabTitle={!opportunity ? '' : opportunity.name + " - Opportunitiess"}
                />
            );
        }
    }, [opportunityId, opportunity, actionMenuDefinitions, canAddEditOpportunities]);

    const handleTabChange = useCallback((e: React.SyntheticEvent, newValue: string) => {
        navigate(`/opportunities/${opportunityId}?tab=${newValue}`);
    }, [opportunityId, navigate]);

    const deleteRecordCallback = useCallback(async () => {
        setIsLoading(true);
        const res = await DeleteOpportunityById(opportunityId, setErrorMessage);
        if (res) setIsRecordDeleted(true);
        setIsLoading(false);
    }, [opportunityId]);

    const counts = useMemo(() => {
        let counts = {
            documents: 0,
            callsNotes: 0,
            callsNotesDate: '\u00A0',
            meetings: 0,
            meetingsDate: '\u00A0',
            activities: 0,
            activitiesDate: '\u00A0'
        };

        if (opportunity && opportunity.statisticsCounts && opportunity.statisticsCounts.length > 0) {
            for (let i = 0; i < opportunity.statisticsCounts.length; i++) {
                const s = opportunity.statisticsCounts[i];
                switch (s.type) {
                    case "DocumentsCount": counts.documents = s.value;
                        break;
                    case "CallsNotesCount": counts.callsNotes = s.value;
                        break;
                    case "MeetingsCount": counts.meetings = s.value;
                        break;
                    case "ActivitiesCount": counts.activities = s.value;
                        break;
                }
            }
        }
        if (opportunity && opportunity.statisticsDates && opportunity.statisticsDates.length > 0) {
            for (let i = 0; i < opportunity.statisticsDates.length; i++) {
                const s = opportunity.statisticsDates[i];
                switch (s.type) {
                    case "CallsNotesDate":
                        const cnDate = moment(s.value);
                        if (cnDate.isValid()) counts.callsNotesDate = cnDate.format('DD MMM YYYY');
                        break;
                    case "MeetingsDate":
                        const mDate = moment(s.value);
                        if (mDate.isValid()) counts.meetingsDate = mDate.format('DD MMM YYYY');
                        break;
                    case "ActivitiesDate":
                        const aDate = moment(s.value);
                        if (aDate.isValid()) counts.activitiesDate = aDate.format('DD MMM YYYY');
                        break;
                }
            }
        }
        return counts;
    }, [opportunity]);

    useEffect(() => {
        if (tabActionMenuOptions.length > 0 && activeTab !== 'OPPORTUNITIES_Home') {
            setTabActionMenuOptions([]);
        }
    }, [activeTab, tabActionMenuOptions]);

    const tagManagementSuccessHandler = useCallback((message: string, recordIds: number[], finalTagCount: number) => {
        setSuccessMessage(message);
    }, []);

    const navigateToJobCallback = useCallback((jobId: number) => {
        navigate(`/jobs/${jobId}`);
    }, [navigate]);

    const activityCreatedSuccessCallback = useCallback((message: string) => {
        setSuccessMessage(message);
        setRefreshActivitiesGridControl(prev => !prev);
    }, []);

    useEffect(() => {
        if (defaultTab && !tabSetupFinished) {
            const accessRestrictedTabs = ['OPPORTUNITIES_CallsNotes', 'OPPORTUNITIES_Meetings', 'OPPORTUNITIES_Activities'];
            setActiveTab(prev => {
                if (accessRestrictedTabs.includes(defaultTab) && !canAccessActivities) return prev;
                return defaultTab;
            });
            setTabSetupFinished(true);
        }
    }, [defaultTab, canAccessActivities, tabSetupFinished]);

    return (
        <>
            <Snackbar open={successMessage !== ''} autoHideDuration={3000} onClose={() => setSuccessMessage('')}>
                <Alert onClose={() => setSuccessMessage('')}>{ successMessage }</Alert>
            </Snackbar>
            <Snackbar open={isRecordDeleted} autoHideDuration={1200} onClose={() => navigate('/opportunities/')}>
                <Alert onClose={() => navigate('/opportunities/')}>Record deleted</Alert>
            </Snackbar>
            <Snackbar open={errorMessage !== ''} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <PageLayout paddingTop={0} SummaryBar={SummaryBar}>
                <ConfirmationDialog
                    message="Are you sure you want to delete this record?"
                    onClose={ () => setShowDeleteDialog(false) }
                    onContinue={ deleteRecordCallback }
                    open={showDeleteDialog}
                    title="Confirm Action"
                    confirmActionText="Yes"
                    cancelActionText="No"
                />
                <ConvertToJobDialog
                    opportunityId={opportunityId}
                    isOpen={convertToJobType !== null}
                    closeHandler={ () => setConvertToJobType(null) }
                    jobType={convertToJobType}
                    loadingHandler={setIsLoading}
                    errorHandler={setErrorMessage}
                    successHandler={navigateToJobCallback}
                />
                <CloseOpportunitiesDialog
                    isOpen={showCloseOpportunityDialog}
                    closeHandler={() => setShowCloseOpportunityDialog(false)}
                    opportunityId={opportunityId}
                    opportunityName={opportunity ? opportunity.name : ''}
                    errorHandler={setErrorMessage}
                    successHandler={setSuccessMessage}
                />
                <EditActivityDialog
                    closeHandler={() => setSelectedActivity(null)}
                    data={opportunity && selectedActivity
                        ? {
                            ...DefaultActivity,
                            opportunityID: opportunityId,
                            opportunityName: opportunity.name,
                            category: 1,
                            type: selectedActivity.customName,
                            typeID: selectedActivity.id
                          }
                        : null
                    }
                    open={ Boolean(selectedActivity) }
                    loadingHandler={setIsLoading}
                    errorHandler={setErrorMessage}
                    successHandler={activityCreatedSuccessCallback}
                />
                <PreviewLoaderComponent
                    open={showTagsPrevew}
                    entityType="opportunity"
                    recordId={opportunityId}
                    showDelayMs={showTagsPrevewNoDelay ? 0 : undefined}
                    isTagsPreview
                />
                <TagsManagementDialog
                    open={showTagsManagement}
                    entityId={8}
                    recordIds={opportunityIdArray}
                    closeHandler={ () => setShowTagsManagement(false) }
                    loadingHandler={ setIsLoading }
                    errorHandler={ setErrorMessage }
                    successHandler={ tagManagementSuccessHandler }
                />
                <GenerateDocumentDialog
                    open={showGenerateDocumentsDialog}
                    entityId={entityTypeId}
                    playerId={opportunityId}
                    closeHandler={() => setShowGenerateDocumentsDialog(false)}
                    loadingHandler={ setIsLoading }
                    errorHandler={ setErrorMessage }
                    successHandler={ setSuccessMessage }
                />
                <Tabs value={activeTab} onChange={handleTabChange}>
                    <Tab value="OPPORTUNITIES_Home" label={<>Home<br/>&nbsp;</>} />
                    {canAccessActivities && <Tab value="OPPORTUNITIES_CallsNotes" label={<>Calls/Notes ({counts.callsNotes})<br />{counts.callsNotesDate}</>} />}
                    {canAccessActivities && <Tab value="OPPORTUNITIES_Meetings" label={<>Meetings ({counts.meetings})<br />{counts.meetingsDate}</>} />}
                    <Tab value="OPPORTUNITIES_Documents" label={<>Documents ({counts.documents})<br />&nbsp;</>} />
                    {canAccessActivities && <Tab value="OPPORTUNITIES_Activities" label={<>Activities ({counts.activities})<br />{counts.activitiesDate}</>} />}
                </Tabs>
                <PageContentLayout showLoading={isLoading || isFetchingDefaultTab || isLoadingTab}>
                    <ShowIframeDialog open={showPartnerActionPopup} closeHandler={() => setShowPartnerActionPopup(false)} title={popupTitle} url={popupUrl} />
                    { isFinishedSetup && activeTab === "OPPORTUNITIES_Home" &&
                        <ViewRecordScreenLayout
                            opportunity={opportunity ? opportunity : null}
                            loadingHandler={setIsLoadingTab}
                            errorHandler={setErrorMessage}
                        />
                    }
                    { isFinishedSetup && canAccessActivities && activeTab === "OPPORTUNITIES_CallsNotes" &&
                        <ActivitiesGridComponent
                            source="opportunity-record"
                            sourceId={opportunityId}
                            categoryFilter={1}
                            loadingHandler={ setIsLoadingTab }
                            errorHandler={ setErrorMessage }
                            successHandler={ setSuccessMessage }
                            gridName="opportunities/CallsNotes"
                            refreshControl={refreshActivitiesGridControl}
                            hideActionsMenu
                        />
                    }
                    { isFinishedSetup && canAccessActivities && activeTab === "OPPORTUNITIES_Meetings" &&
                        <MeetingsGridComponent
                            source="opportunity-record"
                            sourceId={opportunityId}
                            loadingHandler={ setIsLoadingTab }
                            errorHandler={ setErrorMessage }
                            successHandler={ setSuccessMessage }
                            gridName="opportunities/Meetings"
                        />
                    }
                    { isFinishedSetup && activeTab === "OPPORTUNITIES_Documents" &&
                        <DocumentsGridComponent
                            gridName="opportunities/Documents"
                            source="opportunity-record"
                            sourceId={opportunityId}
                            loadingHandler={setIsLoadingTab}
                            errorHandler={setErrorMessage}
                            successHandler={setSuccessMessage}
                        />
                    }
                    { isFinishedSetup && canAccessActivities && activeTab === "OPPORTUNITIES_Activities" &&
                        <ActivitiesGridComponent
                            source="opportunity-record"
                            sourceId={opportunityId}
                            loadingHandler={ setIsLoadingTab }
                            errorHandler={ setErrorMessage }
                            successHandler={ setSuccessMessage }
                            gridName="opportunities/Activities"
                            refreshControl={refreshActivitiesGridControl}
                            hideActionsMenu
                        />
                    }
                </PageContentLayout>
            </PageLayout>
        </>
    );
}