import React, { useCallback, useEffect, useState } from "react";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import PageContentLayout from "layouts/PageContentLayout";
import { GetInvoiceSettings, SaveInvoiceSettings } from 'services/ConfigurationService';
import { ObjToSettingsArray, SettingsArrayToObj } from "util/SettingsUtil";
import { InvoiceConfigOptions } from "common/models/Configuration/Invoices";
import { DefaultOptions, NoChangesTracker } from "util/Definitions/Configuration/Invoices";
import useObjectStateWithChangeTracker from "hooks/UseObjectStateWithChangeTracker";
import useUnsavedChangesDialog from "hooks/UseUnsavedChangesDialog";

interface Props {
    setSummaryBar?: (summaryBar: JSX.Element) => void
}

export default function InvoiceSettings({ setSummaryBar }: Props) {
    const [isLoading, setIsLoading] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const { state, init, change, updateInitial, hasChanges } = useObjectStateWithChangeTracker<InvoiceConfigOptions>(DefaultOptions, NoChangesTracker);

    useEffect(() => {
        const getSettings = async () => {
            setIsLoading(true);
            const data = await GetInvoiceSettings(setErrorMessage);
            if (data) {
                let savedSettings = DefaultOptions;
                for (let i = 0; i < data.length; i++) {
                    const setting = data[i];
                    (savedSettings as any)[setting.type] = setting.value;
                }
                
                init(savedSettings);
            }
            setIsLoading(false);
        };
        getSettings();
    }, [init]);
    
    const saveSettings = useCallback(async () => {
        setIsLoading(true);
        const settings = ObjToSettingsArray(state);
        const res = await SaveInvoiceSettings(settings, setErrorMessage);
        
        if (!res) {
            setIsLoading(false);
            return false;
        }

        updateInitial();
        setShowSuccess(true);
        setIsLoading(false);
        return true;
    }, [state, updateInitial]);

    const { unsavedChangesDialog } = useUnsavedChangesDialog(hasChanges, saveSettings);

    const handleChanges = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        change(name as keyof InvoiceConfigOptions, value);
    }

    useEffect(() => {
        const saveButton = (
            <Button
                disabled={!hasChanges}
                variant="contained"
                color="success"
                onClick={saveSettings}
                sx={{ borderRadius: '2px', padding: 0, height: '36px', width: '80px' }}
            >
                Save
            </Button>
        );

        const SummaryBar = (
            <TitleAndActionSummaryBar
                title="Configuration > Invoices > Settings"
                browserTabTitle="Settings > Invoices"
                action={saveButton}
            />);

        setSummaryBar && setSummaryBar(SummaryBar);
    }, [hasChanges, saveSettings, setSummaryBar]);


    return (
        <>
            {unsavedChangesDialog}
            <Snackbar open={showSuccess} autoHideDuration={3000} onClose={() => setShowSuccess(false)}>
                <Alert onClose={() => setShowSuccess(false)}>Changes Saved</Alert>
            </Snackbar>
            <Snackbar open={errorMessage !== ''}>
                <Alert color="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <PageContentLayout title="Invoice Settings" showLoading={isLoading}>
                <Stack spacing={3}>                    
                    <TextField select label="Default Payment Terms" name="InvoicesDefaultPaymentTerms" value={state.InvoicesDefaultPaymentTerms} onChange={handleChanges} >
                        <MenuItem value="0">Immediately</MenuItem>
                        <MenuItem value="7">7 Days</MenuItem>
                        <MenuItem value="14">14 Days</MenuItem>
                        <MenuItem value="30">30 Days</MenuItem>
                        <MenuItem value="45">45 Days</MenuItem>
                        <MenuItem value="60">60 Days</MenuItem>
                    </TextField>

                    <TextField label="Approval Request Notification" name="NotificationInvoiceApprovalRequest" value={state.NotificationInvoiceApprovalRequest} onChange={handleChanges} />
                </Stack>
            </PageContentLayout>
        </>
    );
}