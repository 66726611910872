import React, { useCallback, useEffect, useMemo, useState } from "react";
import Alert from "components/Alert";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import Snackbar from "@mui/material/Snackbar";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';

import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import PageContentLayout from "layouts/PageContentLayout";
import { GetDocTemplateCustomFields, SaveDocTemplateCustomfields } from "services/ConfigurationService";
import { DocumentTemplateCustomField } from "common/models/Configuration/CustomFields/DocumentTemplates";
import useUnsavedChangesDialog from "hooks/UseUnsavedChangesDialog";

interface Props {
    setSummaryBar?: (summaryBar: JSX.Element) => void
}

export default function DocumentTemplatesCustomFields({ setSummaryBar }: Props) {
    const [isLoading, setIsLoading] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [sources, setSources] = useState<DocumentTemplateCustomField[]>([]);
    const [deleteIds, setDeleteIds] = useState<number[]>([]);
    const [newSource, setNewSource] = useState('');
    const [initialSources, setInitialSources] = useState<DocumentTemplateCustomField[]>([]);

    const getData = useCallback(async () => {
        setIsLoading(true);
            const data = await GetDocTemplateCustomFields(setErrorMessage);
            if(data) {
                setSources(data);
                setInitialSources(data);
            }
            setIsLoading(false);
    }, []);

    useEffect(() => {
        getData();
    }, [getData]);

    const saveSourcesCallback = useCallback( async () => {
        setIsLoading(true);
        const res = await SaveDocTemplateCustomfields(sources, deleteIds, setErrorMessage);
        if(!res) {
            setIsLoading(false);
            return false;
        }
        
        await getData();
        setShowSuccess(true);
        setIsLoading(false);
        return true;
    }, [sources, deleteIds, getData]);

    const hasChanges = useMemo(() => {
        if (initialSources.length !== sources.length) return true;
        const j1 = JSON.stringify(initialSources.map(v => v.name));
        const j2 = JSON.stringify(sources.map(v => v.name));
        return j1 !== j2;
    }, [initialSources, sources]);

    useEffect(() => {
        const action = <Button variant="contained" color="primary" disabled={!hasChanges} onClick={saveSourcesCallback}>Save</Button>;
        const summaryBar = <TitleAndActionSummaryBar title="Configuration > Custom Fields > Document Templates" browserTabTitle="Document Templates > Custom Fields" action={action} />;
        setSummaryBar && setSummaryBar(summaryBar);
    }, [setSummaryBar, saveSourcesCallback, hasChanges]);

    const changeSourceHandler = (i: number, val: string) => {
        setSources(prev => {
            let newSources = [...prev];
            newSources[i].name = val;
            return newSources;
        });
    };

    const addSourceHandler = () => {
        setSources(prev => {
            let newSources: DocumentTemplateCustomField[] = [...prev, { id: 0, name: newSource}];
            setNewSource('');
            return newSources;
        });
    };

    const deleteSouceHandler = (i: number) => {
        setSources(prev => {
            const deletedId = prev[i].id;
            if(deletedId !== 0) setDeleteIds(prev => [...prev, deletedId]);
            let newSources = [...prev];
            newSources.splice(i, 1);
            return newSources;
        });
    };

    const { unsavedChangesDialog } = useUnsavedChangesDialog(hasChanges, saveSourcesCallback);

    return (
        <>
            {unsavedChangesDialog}
            <Snackbar open={showSuccess} autoHideDuration={3000} onClose={() => setShowSuccess(false)}>
                <Alert onClose={() => setShowSuccess(false)}>Changes Saved</Alert>
            </Snackbar>
            <Snackbar open={errorMessage !== ''}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <PageContentLayout title="Custom Fields - Document Templates" showLoading={isLoading}>
                <div>
                    <span style={{ width: '85%', display: 'inline-block', textAlign: 'center' }}>Custom Field</span>
                    <span style={{ width: '15%', display: 'inline-block', textAlign: 'center' }}>Action</span>
                </div>
                <div style={{ paddingBottom: '20px' }}>
                    <Input placeholder="New Custom Field" sx={{ width: '85%' }} size="small" value={newSource} onChange={ ({target}) => setNewSource(target.value) } />
                    <span style={{ width: '15%', display: 'inline-block', textAlign: 'center' }}>
                        <IconButton disabled={newSource === ''} onClick={addSourceHandler}><AddCircleIcon /></IconButton>
                    </span>
                </div>
                <Divider />
                {sources.map((v, i) => (
                    <div key={i} style={{ paddingBottom: '10px' }}>
                        <Input sx={{ width: '85%' }} value={v.name} onChange={ ({target}) => changeSourceHandler(i, target.value) } />
                        <span style={{ width: '15%', display: 'inline-block', textAlign: 'center' }}>
                            <IconButton onClick={ () => deleteSouceHandler(i) }><DeleteIcon /></IconButton>
                        </span>
                    </div>
                ))}
            </PageContentLayout>
        </>
    );
}