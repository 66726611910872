import IconButton from "@mui/material/IconButton";
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import { GridColDef, GridRenderCellParams, GridRowSelectionModel, GridValueGetter, useGridApiRef, GridRowId, GridColumnVisibilityModel } from "@mui/x-data-grid-premium";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import Link from '@mui/material/Link';
import { CandidateList } from "common/models/Candidates/CandidateList";
import { PreviewEntityType } from "common/models/Previews/Previews";
import { CandidateLookup, ExportCandidateToFoundU, ExportMultipleCandidatesToFoundU, GetCandidatesByTag, GetCandidatesRecordsRecentUploads, SearchCandidates } from "services/CandidatesService";
import GridWithStateWrapper from "../GridWidthStateWrapper";
import PreviewLoaderComponent from "../Previews/PreviewLoader";
import TagsManagementDialog from "../Dialogs/TagsManagementDialog";
import { GetPhoneLink, GetPhoneLinkWithStandardized } from "util/CommonUtils";
import { MenuOptionDefinition } from "common/models/MenuDefinition";
import ActionMenu from "../Menus/ActionMenu";
import { companyHasSinglePermission, userHasSinglePermission } from "util/PermissionsUtils";
import { Permission } from "common/models/Permissions";
import SendEmailDialog from "../Dialogs/Messages/SendEmailDialog";
import SendSmsDialog from "../Dialogs/Messages/SendSmsDialog";
import { EmailRecipient } from "common/models/EmailRecipient";
import { GetMarketingPlatformsAvailable } from "services/UsersService";
import MergeCandidatesDialog from "../Dialogs/Candidates/MergeCandidatesDialog";
import { ActivitySettings } from "common/models/Configuration/Activities";
import { GetActivitiesSettings, GetGeneralSettings } from "services/ConfigurationService";
import EditActivityDialog from "../Dialogs/Activities/EditActivityDialog";
import SendReferralDialog from "../Dialogs/SendReferralDialog";
import { DefaultActivity } from "util/Definitions/Activities";
import { RecordExportData } from "common/models/Common";
import ExportRecordsToMailchimpDialog from "../Dialogs/ExportRecordsToMailchimpDialog";
import ExportRecordsToCampaignMonitorDialog from "../Dialogs/ExportRecordsToCampaignMonitorDialog";
import ExportRecordsDialog from "../Dialogs/ExportRecordsDialog";
import LinkCandidatesToJobDialog from "../Dialogs/Candidates/LinkCandidatesToJobDialog";
import Box from "@mui/material/Box";
import { GetIntegration } from "services/CustomerIntegrationsService";
import ReferooActionsDialog from "../Dialogs/Candidates/ReferooActionsDialog";
import { CandidateSearch } from "common/models/Search/CandidateSearch";
import { CandidatePositionTracker } from 'common/models/CandidatePositionTracker'
import ConfirmationDialog from "components/Dialogs/Generic/ConfirmationDialog";
import useWorkflowOrder from "hooks/UseWorkflowOrder";
import { GetCandidatesByAutomationFilters } from "services/AutomationService";
import { AutomationPreviewData } from "common/models/Configuration/AutomationPreviewData";
import DigitalReferenceCheckDialog from "components/Dialogs/Candidates/DigitalReferenceCheckDialog";
import { MeetingType } from "common/models/Configuration/MeetingType";
import { InterviewType } from "common/models/Configuration/InterviewType";
import { GetActiveMeetingTypes } from "services/MeetingsService";
import { GetActiveInterviewTypes } from "services/InterviewsService";

interface Props {
    source: 'tags' | 'search' | 'lookup' | 'recent' | 'automation',
    sourceId?: number,
    gridName: string,
    tagLabel?: string,
    tagTypeId?: number,
    taggedBy?: number,
    lookupTerm?: string,
    searchData?: CandidateSearch,
    filterJSON?: string,
    setRowCountHandler?: (count: number) => void,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
    successHandler?: (message: string) => void,
    fromDialog?: boolean,
    fromDialogPreviewData?: AutomationPreviewData
}

const linkStyle: React.CSSProperties = { color: 'inherit', textDecoration: 'underline' };
const entityTypeId = 3;

export default function CandidatesGridComponent({ source, sourceId, tagLabel = '', tagTypeId = 0, taggedBy = 0, lookupTerm = '', searchData, filterJSON = '',fromDialog = false,fromDialogPreviewData, setRowCountHandler, loadingHandler, errorHandler, successHandler, gridName }: Props) {
    const [rows, setRows] = useState<CandidateList[]>([]);
    const [activitySettings, setActivitySettings] = useState<ActivitySettings[]>([]);
    const [selectedActivity, setSelectedActivity] = useState<ActivitySettings | null>(null);
    const [meetingTypes, setMeetingTypes] = useState<MeetingType[]>([]);
    const [interviewTypes, setInterviewTypes] = useState<InterviewType[]>([]);
    const [showSendReferralDialog, setShowSendReferralDialog] = useState(false);
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    const [showTagsPrevewNoDelay, setShowTagsPreviewNoDelay] = useState(false);
    const [previewType, setPreviewType] = useState<PreviewEntityType | ''>('');
    const [previewRecordId, setPreviewRecordId] = useState(0);
    const [isPreviewTags, setIsPreviewTags] = useState(false);
    const [previewRecordName, setPreviewRecordName] = useState('');
    const [hasMailChimpAccess, setHasMailChimpAccess] = useState(false);
    const [hasCampaignMonitorAccess, setHasCampaignMonitorAccess] = useState(false);
    const [isReferooConnected, setIsReferooConnected] = useState(false);
    const [showReferooDialog, setShowReferooDialog] = useState(false);
    const [showLinkCandidateToJobDialog, setShowLinkCandidateToJobDialog] = useState(false);
    const [showExportToFoundUDialog, setShowExportToFoundUDialog] = useState(false);
    const [isFoundUConnected, setIsFoundUConnected] = useState(false);
    const [sendMessageDialogType, setSendMessageDialogType] = useState<'email' | 'sms' | 'marketing-list' | null>(null);
    const [exportRecordDialogType, setExportRecordDialogType] = useState<'mailchimp' | 'campaign-monitor' | null>(null);
    const [messageRecipients, setMessageRecipients] = useState<EmailRecipient[]>([]);
    const [exportRecords, setExportRecords] = useState<RecordExportData[]>([]);
    const [taggingRecords, setTaggingRecords] = useState<number[]>([]);
    const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
    const [mergeRecords, setMergeRecords] = useState<CandidateList[]>([]);
    const apiRef = useGridApiRef();
    const navigate = useNavigate();
    const workflowOrder = useWorkflowOrder();
    const [showDigitalReferenceCheckDialog, setShowDigitalReferenceCheckDialog] = useState(false);
    const [loadingForDialog, setLoadingForDialog] = useState<boolean>(false);
    const [onlyDataAdminsCanMerge, setOnlyDataAdminsCanMerge] = useState(false);

    const canExportCandidates = useMemo(() => companyHasSinglePermission(Permission.CandidatesExport) && userHasSinglePermission(Permission.CandidatesExport), []);
    const userCanAddEditCandidates = useMemo(() => userHasSinglePermission(Permission.CandidatesAddEdit) && companyHasSinglePermission(Permission.CandidatesAddEdit), []);
    const userCanEmailMarket = useMemo(() => userHasSinglePermission(Permission.EmailMarketing) && companyHasSinglePermission(Permission.EmailMarketing), []);
    const userCanSendEmail = useMemo(() => userHasSinglePermission(Permission.MessageCentre) && companyHasSinglePermission(Permission.MessageCentre), []);
    const userCanSendSms = useMemo(() => userHasSinglePermission(Permission.MessageCentreSms) && companyHasSinglePermission(Permission.MessageCentreSms), []);
    const isUserDataMaintenanceAdmin = useMemo(() => userHasSinglePermission(Permission.DataMaintenanceAdministrator), []);

    const userCanMergeRecords = useMemo(() => {
        if (onlyDataAdminsCanMerge && !isUserDataMaintenanceAdmin) return false;
        return true;
    }, [isUserDataMaintenanceAdmin, onlyDataAdminsCanMerge]);
    

    const getData = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        let res: CandidateList[] | null = [];
        if (source === 'tags') res = await GetCandidatesByTag(tagTypeId, taggedBy, tagLabel, errorHandler);
        else if (source === 'search' && searchData) res = await SearchCandidates(searchData, errorHandler);
        else if (source === 'lookup' && lookupTerm) res = await CandidateLookup(lookupTerm, 0, errorHandler);
        else if (source === 'recent') res = await GetCandidatesRecordsRecentUploads(errorHandler);
        else if (source === 'automation' && fromDialogPreviewData) {
            res = await GetCandidatesByAutomationFilters(filterJSON,fromDialogPreviewData,errorHandler);
            setLoadingForDialog(true);
        }
        
        if (res) {
            setRowCountHandler && setRowCountHandler(res.length)
            setRows(res);
        }
        loadingHandler && loadingHandler(false);
        
    }, [loadingHandler, source, tagTypeId, taggedBy, tagLabel, errorHandler, searchData, lookupTerm, fromDialogPreviewData, filterJSON, setRowCountHandler]);

    useEffect(() => {
        if(!loadingForDialog) {
            getData();
        }
    }, [getData,loadingForDialog]);

    useEffect(() => {
        const getMeetingTypes = async () => {
            const res = await GetActiveMeetingTypes(entityTypeId);
            if (res) setMeetingTypes(res);
        };
        getMeetingTypes();
    }, []);

    useEffect(() => {
        const getInterviewTypes = async () => {
            const res = await GetActiveInterviewTypes();
            if (res) setInterviewTypes(res);
        };
        getInterviewTypes();
    }, []);

    useEffect(() => {
        const getGeneralSettings = async () => {
            const res = await GetGeneralSettings(errorHandler);
            if (res) {
                const setting = res.find(s => s.type === 'WhoCanMergeRecords');
                if (setting) setOnlyDataAdminsCanMerge(setting.value === 'DataMaintenanceAdministrator');
            }
        };
        getGeneralSettings();
    }, [errorHandler]);

    useEffect(() => {
        const getActivitySettings = async () => {
            const res = await GetActivitiesSettings(3, undefined, errorHandler);
            if (res) setActivitySettings(res);
        };
        getActivitySettings();
    }, [errorHandler]);

    const columns = useMemo<GridColDef[]>(() => {
        const handlePreviewHover = (type: PreviewEntityType | '', id: number, isTags: boolean = false, recordName: string = '', noDelay: boolean = false) => {
            setPreviewType(type);
            setPreviewRecordId(id);
            setIsPreviewTags(isTags);
            setPreviewRecordName(recordName);
            setIsPreviewOpen(true);
            setShowTagsPreviewNoDelay(noDelay);
        };

        const handlePreviewClose = () => {
            setIsPreviewOpen(false);
            setShowTagsPreviewNoDelay(false);
        };

        const dateValueGetter: GridValueGetter<CandidateList, any, undefined, string> = (value) => {
            if (value) {
                const m = moment(value);
                if (m.isValid() && m.get('year') > 1) {
                    return m.toDate();
                }
            }
        };

        const dateRenderer = (params: GridRenderCellParams) => {
            if (params.value) {
                return moment(params.value).format('DD MMM YYYY');
            }
            return 'Never';
        };

        const linkToCandidateRenderer = (params: GridRenderCellParams) => {
            if (params.id) {
                return (
                    <Link
                        href={`/candidates/${params.id}`}
                        style={linkStyle}
                        onMouseEnter={() => handlePreviewHover('candidate', params.row.id)}
                        onMouseLeave={handlePreviewClose}
                        onClick={(e) => handleCandidateClick(e, params.row.id, params.row.jobID)}
                    >{params.value}</Link>
                );
            }
            return params.value;
        };

        const nameValueGetter: GridValueGetter<CandidateList, any, undefined, string> = (value) => {
            if (value && value.trim() !== '') {
                return value;
            }

            return '-----';
        };

        const makeId = (length: number) => {
            let result = '';
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            const charactersLength = characters.length;
            let counter = 0;
            while (counter < length) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
                counter += 1;
            }
            return result;
        };

        const handleCandidateClick = (e: React.MouseEvent<HTMLElement>, candidateId: number, jobId: number) => {
            e.preventDefault();
            let dataId = makeId(10);

            var rows = apiRef.current.getAllRowIds();

            let maxIndex = rows.length - 1;

            let mainCandidateIndex = apiRef.current.getRowIndexRelativeToVisibleRows(candidateId);
            mainCandidateIndex = mainCandidateIndex + (apiRef.current.state.pagination.paginationModel.page * apiRef.current.state.pagination.paginationModel.pageSize);
            let mainCandidatePrevIndex = mainCandidateIndex - 1;
            let mainCandidateNextIndex = mainCandidateIndex + 1;

            let mainCandidatePrevCandidateId = "0";
            let mainCandidateNextCandidateId = "0";

            if (mainCandidatePrevIndex >= 0)
                mainCandidatePrevCandidateId = apiRef.current.getRowIdFromRowIndex(mainCandidatePrevIndex).toString();

            if (mainCandidateNextIndex <= maxIndex)
                mainCandidateNextCandidateId = apiRef.current.getRowIdFromRowIndex(mainCandidateNextIndex).toString();

            let collection: CandidatePositionTracker[] = [];

            rows.forEach((element: GridRowId) => {

                let candidateId: number = element as number;

                let currentIndex = apiRef.current.getRowIndexRelativeToVisibleRows(candidateId);
                currentIndex = currentIndex + (apiRef.current.state.pagination.paginationModel.page * apiRef.current.state.pagination.paginationModel.pageSize);
                let prevIndex = currentIndex - 1;
                let nextIndex = currentIndex + 1;

                let prevCandidateId: number = 0;
                let nextCandidateId: number = 0;

                if (prevIndex >= 0)
                    prevCandidateId = apiRef.current.getRowIdFromRowIndex(prevIndex) as number;

                if (nextIndex <= maxIndex)
                    nextCandidateId = apiRef.current.getRowIdFromRowIndex(nextIndex) as number;

                let info: CandidatePositionTracker = { candidateId, prevCandidateId, nextCandidateId };

                collection.push(info)
            });

            let val = JSON.stringify(collection);
            localStorage.setItem(dataId, val);

            navigate(`/candidates/${candidateId}?dataId=${dataId}&prevCandidateId=${mainCandidatePrevCandidateId}&nextCandidateId=${mainCandidateNextCandidateId}`);
        };

        const emailLinkRenderer = (params: GridRenderCellParams) => {
            const email = params.value;
            if (email) {
                return <a href={`mailto:${email}`} style={linkStyle}>{email}</a>;
            }
        };

        const tagsRenderer = (params: GridRenderCellParams) => {
            if (userCanAddEditCandidates) {
                return (
                    <IconButton
                        size="small"
                        onMouseEnter={ () => handlePreviewHover('candidate', params.row.id, true, params.row.fullName) }
                        onMouseLeave={ handlePreviewClose }
                        onClick={ () => setTaggingRecords([params.row.id]) }
                    >
                        {params.value
                            ? <LocalOfferIcon style={{ color: '#f209a6' }} />
                            : <LocalOfferOutlinedIcon />
                        }
                    </IconButton>
                );
            }
            return (
                <IconButton
                    size="small"
                    onMouseEnter={() => handlePreviewHover('candidate', params.row.id, true, params.row.fullName)}
                    onMouseLeave={handlePreviewClose}
                    onClick={() => handlePreviewHover('candidate', params.row.id, true, params.row.fullName, true)}
                >
                    {params.value
                        ? <LocalOfferIcon />
                        : <LocalOfferOutlinedIcon />
                    }
                </IconButton>
            );
        };

        const phoneLinkRenderer = (params: GridRenderCellParams) => {
            if (params.row.mobile_standardized) {
                return GetPhoneLinkWithStandardized(params.row.mobile, params.row.mobile_standardized);     
            }
            return GetPhoneLink(params.row.mobile, false);  
        };

        const numberRenderer = (params: GridRenderCellParams) => {
            return params.value.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 });
        };
        
        return [
            { headerName: 'ID', field: 'id', width: 75, renderCell: linkToCandidateRenderer },
            { headerName: 'Name', field: 'fullName', width: 250, valueGetter: nameValueGetter, renderCell: linkToCandidateRenderer },
            { headerName: 'Email', field: 'emailAddress', headerAlign: 'center', align: 'center', width: 250, renderCell: emailLinkRenderer },
            { headerName: 'Mobile', field: 'mobile', headerAlign: 'center', align: 'center', width: 250, renderCell: phoneLinkRenderer },
            { headerName: 'Tags', field: 'tags', width: 70, headerAlign: 'center', align: 'center', renderCell: tagsRenderer, editable: false },
            { headerName: 'Job Title', field: 'jobTitle', width: 200 },
            { headerName: 'Employer', field: 'employer', width: 200 },
            { headerName: 'Location', field: 'location', width: 200 },
            { headerName: 'Job Type', field: 'preferredEmploymentType', headerAlign: 'center', align: 'center', width: 150 },
            { headerName: 'Available', field: 'available', headerAlign: 'center', align: 'center', width: 125 },
            { headerName: 'Preferred Salary', field: 'preferredSalary', headerAlign: 'center', align: 'center', width: 125 },
            { headerName: 'Preferred Rate', field: 'preferredRate', headerAlign: 'center', align: 'center', width: 125 },
            { headerName: 'Last Updated', field: 'compareDate', headerAlign: 'center', align: 'center', width: 125, valueGetter: dateValueGetter, renderCell: dateRenderer, type: 'date', },
            { headerName: 'Last Activity', field: 'lastActivityDate', headerAlign: 'center', align: 'center', width: 125, valueGetter: dateValueGetter, renderCell: dateRenderer, type: 'date' },
            { headerName: 'Last Contact', field: 'lastContactDate', headerAlign: 'center', align: 'center', width: 125, valueGetter: dateValueGetter, renderCell: dateRenderer, type: 'date' },
            { headerName: 'Score', field: 'score', width: 100, renderCell: numberRenderer },
        ];
    }, [apiRef, navigate, userCanAddEditCandidates]);

    useEffect(() => {
        const getData = async () => {
            const res = await GetMarketingPlatformsAvailable();
            if (res) {
                setHasMailChimpAccess(res.mailChimp);
                setHasCampaignMonitorAccess(res.campaignMonitor);
            }
        };
        userCanEmailMarket && getData();
    }, [userCanEmailMarket]);

    useEffect(() => {
        const getData = async () => {
            const referoo = await GetIntegration(1);
            if (referoo) setIsReferooConnected(referoo.statusID === 1);

            const foundU = await GetIntegration(16);
            if (foundU) setIsFoundUConnected(foundU.statusID === 1);
        };
        getData();
    }, []);

    const sendMessageCallback = useCallback((type: 'email' | 'sms' | 'marketing-list', idKey: keyof CandidateList, nameKey: keyof CandidateList, emailOrPhoneKey: keyof CandidateList, optOutKey: keyof CandidateList) => {
        if (selectionModel.length > 0) {
            let recipients: EmailRecipient[] = [];
            for (let i = 0; i < selectionModel.length; i++) {
                const id = selectionModel[i];
                const row = rows.find(r => r.id === +id.valueOf());
                if (row) {
                    const recipientId = row[idKey] as number;
                    const recipientName = row[nameKey] as string;
                    const emailOrPhone = row[emailOrPhoneKey] as string;
                    const isOptOut = row[optOutKey] as boolean;
                    const email = type === 'email' || type === 'marketing-list' ? emailOrPhone : '';
                    const phone = type === 'sms' ? emailOrPhone : '';

                    recipients.push({ id: recipientId, email: email, name: recipientName, mobile: phone, candidateList: row, isOptOut: isOptOut });
                }
            }
            setSendMessageDialogType(type);
            setMessageRecipients(recipients);
        }
    }, [selectionModel, rows]);

    const exportToMailchimpOrCampaignMonitorCallback = useCallback((dialogType: 'mailchimp' | 'campaign-monitor') => {
        if (selectionModel.length > 0) {
            let exportRecordsData: RecordExportData[] = [];
            for (let i = 0; i < selectionModel.length; i++) {
                const id = selectionModel[i];
                const row = rows.find(r => r.id === +id);
                if (row) {
                    exportRecordsData.push({
                        id: row.id,
                        email: row.emailAddress,
                        firstName: row.firstName,
                        lastName: row.lastName,
                        isOptOut: row.optOut,
                        candidate: row
                    });
                }
            }
            setExportRecordDialogType(dialogType);
            setExportRecords(exportRecordsData);
        }
    }, [selectionModel, rows]);

    const mergeRecordsHandler = useCallback(() => {
        let selectedCandidates: CandidateList[] = [];
        for (let i = 0; i < selectionModel.length; i++) {
            const candidateId = selectionModel[i];
            const candidate = rows.find(s => s.id === candidateId.valueOf());
            if (candidate) selectedCandidates.push(candidate);
        }
        setMergeRecords(selectedCandidates);
    }, [rows, selectionModel]);

    const exportToExcel = useCallback(() => {
        const api = apiRef.current;
        if (api) api.exportDataAsExcel();
    }, [apiRef]);

    const actionMenuDefinitions = useMemo<MenuOptionDefinition[]>(() => {
        const noRecordSelected = selectionModel.length === 0;
        const notEnoughForMerge = selectionModel.length < 2;
        const hasMarketingPlatform = hasMailChimpAccess || hasCampaignMonitorAccess;
        const contactIdsQuery = encodeURIComponent(selectionModel.join(','));

        let actions: MenuOptionDefinition[] = [
            { label: 'Export', type: 'action', action: exportToExcel, allow: () => canExportCandidates },
            { label: 'Export Marketing List', type: 'action', disabled: noRecordSelected, allow: () => userCanEmailMarket && !hasMarketingPlatform, action: () => sendMessageCallback('marketing-list', 'id', 'fullName', 'emailAddress', 'optOut') },
            { label: 'Export to Mailchimp', type: 'action', disabled: noRecordSelected, allow: () => userCanEmailMarket && hasMailChimpAccess, action: () => exportToMailchimpOrCampaignMonitorCallback('mailchimp') },
            { label: 'Export to Campaign Monitor', type: 'action', disabled: noRecordSelected, allow: () => userCanEmailMarket && hasCampaignMonitorAccess, action: () => exportToMailchimpOrCampaignMonitorCallback('campaign-monitor') },
            { label: 'Merge', type: 'action', disabled: notEnoughForMerge, allow: () => userCanAddEditCandidates && userCanMergeRecords, action: mergeRecordsHandler },
            { label: 'Send Email', type: 'action', disabled: noRecordSelected, allow: () => userCanSendEmail, action: () => sendMessageCallback('email', 'id', 'fullName', 'emailAddress', 'optOut') },
            { label: 'Send SMS', type: 'action', disabled: noRecordSelected, allow: () => userCanSendSms, action: () => sendMessageCallback('sms', 'id', 'fullName', 'mobile_Standardized', 'optOut') },
            { label: 'Bulk Tag', type: 'action', disabled: noRecordSelected, allow: () => userCanAddEditCandidates, action: () => setTaggingRecords(selectionModel as number[]) },
            { label: 'Link To Job', type: 'action', disabled: noRecordSelected, action: () => setShowLinkCandidateToJobDialog(true) },
        ];

        for (let i = 0; i < activitySettings.length; i++) {
            const setting = activitySettings[i];
            if (setting.category === 2) continue;
            if (setting.workflowStatus === 0) {
                if (setting.id === 23)
                    actions.push({ label: setting.customName, type: 'action', disabled: selectionModel.length !== 1, action: () => setShowSendReferralDialog(true) });
                else
                    actions.push({ label: setting.customName, type: 'action', disabled: selectionModel.length !== 1, action: () => setSelectedActivity(setting) });
            }
        }

        let meetingActions: MenuOptionDefinition = {
            label: 'Meetings', type: 'parent', subMenu: []
        };

        if (meetingTypes.length > 0 && meetingActions.subMenu) {
            for (let i = 0; i < meetingTypes.length; i++) {
                const t = meetingTypes[i];
                const href = `/meetings/create?contactIds=${contactIdsQuery}&typeId=${t.id}&source=${entityTypeId}`;
                meetingActions.subMenu.push({ label: t.name, type: 'link', href: href });
            }
        }

        if (meetingActions.subMenu && meetingActions.subMenu.length > 0) actions.push(meetingActions);

        return actions;
    }, [selectionModel, hasMailChimpAccess, hasCampaignMonitorAccess, exportToExcel, mergeRecordsHandler, meetingTypes, canExportCandidates, userCanEmailMarket, sendMessageCallback, exportToMailchimpOrCampaignMonitorCallback, userCanAddEditCandidates, userCanMergeRecords, userCanSendEmail, userCanSendSms, activitySettings]);

    const workflowMenuDefinitions = useMemo<MenuOptionDefinition[]>(() => {
        const selectedCount = selectionModel.length;
        const firstCandidateId = selectedCount > 0 ? +selectionModel[0] : 0;
        
        let workflow: MenuOptionDefinition[] = [
            { label: 'Export to foundU', type: 'action', action: () => setShowExportToFoundUDialog(true), disabled: selectedCount === 0, allow: () => isFoundUConnected },
            { label: 'Referoo', type: 'action', disabled: selectedCount !== 1, allow: () => isReferooConnected, action: () => setShowReferooDialog(true) },
        ];

        for (let i = 0; i < activitySettings.length; i++) {
            const s = activitySettings[i];
            if (s.workflowStatus === 0 || s.id === 31) continue;
            if (s.id === 30) {
                workflow.push({ label: s.customName, type: 'link', disabled: selectedCount !== 1, href: `/candidates/${firstCandidateId}/reference-check`, orderIndex: workflowOrder.refCheckIndex });
                workflow.push({ label: 'Digital Reference Check', type: 'action', disabled: selectedCount !== 1, action: () => setShowDigitalReferenceCheckDialog(true) });
            }
            if (s.id === 10)
                workflow.push({ label: s.customName, type: 'link', disabled: selectedCount !== 1, href: `/candidates/${firstCandidateId}/screening`, orderIndex: workflowOrder.indexes[s.workflowStatus] });
        }

        let interviewActions: MenuOptionDefinition = { label: 'Interviews', type: 'parent', subMenu: [], orderIndex: workflowOrder.interviewIndex };
        if (interviewTypes.length > 0 && interviewActions.subMenu) {
            for (let i = 0; i < interviewTypes.length; i++) {
                const t = interviewTypes[i];
                const href = `/interviews/create?typeId=${t.id}&candidateId=${firstCandidateId}`;
                interviewActions.subMenu.push({ label: t.name, type: 'link', href: href, disabled: selectedCount !== 1 });
            }
        }

        if (interviewActions.subMenu && interviewActions.subMenu.length > 0) {
            interviewActions.subMenu.sort((a,b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));
            workflow.push(interviewActions);
        }

        return workflow;
    }, [selectionModel, workflowOrder.interviewIndex, workflowOrder.indexes, workflowOrder.refCheckIndex, interviewTypes, isFoundUConnected, isReferooConnected, activitySettings]);
    
    const gridActions = useMemo(() => {
        return <ActionMenu color="secondary" label="List Actions" definition={actionMenuDefinitions} />;
    }, [actionMenuDefinitions]);

    const gridWorkflowActions = useMemo(() => {
        return <ActionMenu color="primary" label="Workflow" mr="5px" definition={workflowMenuDefinitions} sortType={workflowOrder.sortType} />;
    }, [workflowMenuDefinitions, workflowOrder.sortType]);

    const tagManagementSuccessHandler = useCallback((message: string, recordIds: number[], finalTagCount: number) => {
        successHandler && successHandler(message);
        const api = apiRef.current;
        if (api) {
            const hasTags = finalTagCount > 0;
            api.updateRows(recordIds.map(id => ({ id: id, tags: hasTags })));
        }
    }, [apiRef, successHandler]);

    const selectedSingleCandidate = useMemo<CandidateList | null>(() => {
        if (selectionModel.length === 1) {
            const [id] = selectionModel;
            const candidate = rows.find(c => c.id === +id);
            if (candidate) return candidate;
        }
        return null;
    }, [selectionModel, rows]);

    const exportToFoundUCallback = useCallback(async () => {
        if (selectionModel.length > 0) {
            loadingHandler && loadingHandler(true);
            let res: boolean | null = null;
            if (selectionModel.length === 1) res = await ExportCandidateToFoundU(+selectionModel[0], errorHandler);
            else res = await ExportMultipleCandidatesToFoundU(selectionModel as number[], errorHandler);
            if (res) {
                setShowExportToFoundUDialog(false);
                successHandler && successHandler('Record(s) Exported to FoundU');
            }
            loadingHandler && loadingHandler(false);
        }
    }, [selectionModel, loadingHandler, errorHandler, successHandler]);

    const defaultHiddenCols = useMemo<GridColumnVisibilityModel>(() => {
        let model: GridColumnVisibilityModel = { 
            'id': false,
            'emailAddress': false,
            'mobile': false,
            'preferredSalary': false,
            'preferredRate': false,
            'lastActivityDate': false,
            'lastContactDate': false,
        };
        if (source === 'search') model['score'] = false;
        return model;
    }, [source]);

    return (
        <>
            <PreviewLoaderComponent
                open={isPreviewOpen}
                entityType={previewType}
                recordId={previewRecordId}
                isTagsPreview={isPreviewTags}
                showDelayMs={showTagsPrevewNoDelay ? 0 : undefined}
                titleOverride={previewRecordName}
            />
            <TagsManagementDialog
                open={ taggingRecords.length > 0 }
                closeHandler={ () => setTaggingRecords([]) }
                entityId={ entityTypeId }
                recordIds={ taggingRecords }
                loadingHandler={ loadingHandler }
                errorHandler={ errorHandler }
                successHandler={ tagManagementSuccessHandler }
            />
            <ExportRecordsDialog
                open={ sendMessageDialogType === 'marketing-list' }
                closeHandler={ () => setSendMessageDialogType(null) }
                entityType="candidates"
                filename="Candidates"
                records={messageRecipients}
                includeTimeStamp
            />
            <ExportRecordsToMailchimpDialog
                open={exportRecordDialogType === 'mailchimp'}
                closeHandler={() => setExportRecordDialogType(null)}
                records={exportRecords}
                loadingHandler={loadingHandler}
                successHandler={successHandler}
                errorHandler={errorHandler}
            />
            <ExportRecordsToCampaignMonitorDialog
                open={exportRecordDialogType === 'campaign-monitor'}
                closeHandler={() => setExportRecordDialogType(null)}
                records={exportRecords}
                loadingHandler={loadingHandler}
                successHandler={successHandler}
                errorHandler={errorHandler}
            />
            <SendEmailDialog
                open={ sendMessageDialogType === 'email' }
                sourceEntityId={entityTypeId}
                recipients={ messageRecipients }
                recipientEntityTypeId={ entityTypeId }
                closeHandler={ () => setSendMessageDialogType(null) }
                loadingHandler={loadingHandler}
                errorHandler={errorHandler}
                successHandler={successHandler}
            />
            <SendSmsDialog
                open={ sendMessageDialogType === 'sms' }
                sourceEntityId={entityTypeId}
                recipients={ messageRecipients }
                recipientEntityTypeId={ entityTypeId }
                closeHandler={ () => setSendMessageDialogType(null) }
                loadingHandler={loadingHandler}
                errorHandler={errorHandler}
                successHandler={successHandler}
            />
            <MergeCandidatesDialog
                open={mergeRecords.length > 1}
                candidates={mergeRecords}
                closeHandler={() => setMergeRecords([])}
                errorHandler={errorHandler}
                loadingHandler={loadingHandler}
                successHandler={successHandler}
            />
            <EditActivityDialog
                closeHandler={() => setSelectedActivity(null)}
                data={selectedSingleCandidate && selectedActivity 
                    ? {
                        ...DefaultActivity,
                        candidateID: selectedSingleCandidate.id,
                        candidateName: selectedSingleCandidate.fullName,
                        category: 1,
                        type: selectedActivity.customName,
                        typeID: selectedActivity.id
                        } 
                    : null
                }
                open={ Boolean(selectedActivity) && Boolean(selectedSingleCandidate) }
                loadingHandler={loadingHandler}
                errorHandler={errorHandler}
                successHandler={successHandler}
            />
            <SendReferralDialog
                open={showSendReferralDialog && Boolean(selectedSingleCandidate)}
                candidateId={selectedSingleCandidate ? selectedSingleCandidate.id : 0}
                candidateName={selectedSingleCandidate ? selectedSingleCandidate.fullName : ''}
                closeHandler={ () => setShowSendReferralDialog(false) }
                loadingHandler={loadingHandler}
                errorHandler={errorHandler}
                successHandler={successHandler}
            />
            <LinkCandidatesToJobDialog
                open={showLinkCandidateToJobDialog && selectionModel.length > 0}
                closeHandler={() => setShowLinkCandidateToJobDialog(false)}
                candidateIds={selectionModel as number[]}
                loadingHandler={loadingHandler}
                errorHandler={errorHandler}
                successHandler={successHandler}
            />
            <ReferooActionsDialog
                open={showReferooDialog}
                candidateId={selectionModel.length === 1 ? +selectionModel[0] : 0}
                closeHandler={() => setShowReferooDialog(false)}
                loadingHandler={loadingHandler}
                errorHandler={errorHandler}
                successHandler={successHandler}
            />
            <DigitalReferenceCheckDialog
                open={showDigitalReferenceCheckDialog}
                candidateId={selectionModel.length === 1 ? +selectionModel[0] : 0}
                closeHandler={() => setShowDigitalReferenceCheckDialog(false)}
                loadingHandler={loadingHandler}
                errorHandler={errorHandler}
                successHandler={successHandler} 
            />
            <ConfirmationDialog
                message={`Are you sure you want to export the ${selectionModel.length} selected record(s) to foundU?`}
                onClose={ () => setShowExportToFoundUDialog(false) }
                onContinue={ exportToFoundUCallback }
                open={showExportToFoundUDialog}
                title="Confirm Action"
                confirmActionText="Yes"
                cancelActionText="No"
            />
            {source !== 'automation' && Boolean(gridActions) &&
                <Box pb="10px" ml="auto">
                    <Box display="flex">
                        {gridWorkflowActions}
                        {gridActions}
                    </Box>
                </Box>
            }
            <GridWithStateWrapper
                gridName={gridName}
                defaultViewModel={defaultHiddenCols}
                rows={rows}
                columns={columns}
                apiRef={apiRef}
                density="compact"
                checkboxSelection={source === 'automation' ? false : true}
                disableRowSelectionOnClick
                rowSelectionModel={selectionModel}
                onRowSelectionModelChange={ sm => setSelectionModel(sm) }
                pagination
                pageSizeOptions={[100,250,500,1000]}
                fromDialog={fromDialog}
            />
        </>
    );
}