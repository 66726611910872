import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import React, { useCallback, useState } from "react";
import Popper, { PopperPlacementType } from "@mui/material/Popper";
import Paper from "@mui/material/Paper";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import MenuList from "@mui/material/MenuList";

interface Props {
    mainAction: () => void,
    label?: string
    color?: "inherit" | "success" | "primary" | "secondary" | "error" | "info" | "warning" | undefined,
    menuPlacement?: PopperPlacementType,
    mainActionDisabled?: boolean,
    secondaryActionDisabled?: boolean,
    disablePopperPortal?: boolean
}

export default function ActionsSplitButton({ children, color, menuPlacement, mainAction, label = 'Submit', mainActionDisabled, secondaryActionDisabled, disablePopperPortal = true }: React.PropsWithChildren<Props>) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    
    const handleCloseMenu = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const handleOpenMenu = useCallback((e: React.MouseEvent<HTMLElement>) => {
        if (secondaryActionDisabled) return;
        setAnchorEl(e.currentTarget);
    }, [secondaryActionDisabled]);

    return (
        <>
            <ButtonGroup variant="contained" color={ color }>
                <Button onClick={ mainAction } disabled={mainActionDisabled} >{ label }</Button>
                {children &&
                    <Button
                        size="small"
                        sx={{ p: 0 }}
                        style={{ minWidth: '10px' }}
                        onClick={ handleOpenMenu }
                        onMouseEnter={ handleOpenMenu }
                        disabled={secondaryActionDisabled}
                    >
                        <ArrowDropDownIcon />
                    </Button>
                }
            </ButtonGroup>
            {children &&
                <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} placement={menuPlacement} disablePortal={disablePopperPortal} sx={{ zIndex: t => t.zIndex.tooltip - 1 }}>
                    <Paper elevation={6}>
                        <ClickAwayListener onClickAway={handleCloseMenu}>
                            <MenuList onMouseLeave={handleCloseMenu}>
                                {children}
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Popper>
            }
        </>
    );
}