import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import moment from "moment";
import React, { useCallback, useMemo, useState } from "react";
import PreviewLoaderComponent from "components/Previews/PreviewLoader";
import { GetForecastExceptionsJobsReportData, GetForecastExceptionsCandidatesReportData } from "services/JobsService";
import UserPicker from "components/Pickers/UserPicker";
import { GridColDef, GridRenderCellParams, GridValueGetter, useGridApiRef } from "@mui/x-data-grid-premium";
import { ForecastExceptionsJobsReportData, ForecastExceptionsCandidatesReportData } from "common/models/Reports/ForecastExceptions";
import GridWithStateWrapper from "components/GridWidthStateWrapper";
import { PreviewEntityType } from "common/models/Previews/Previews";
import { Tab, Tabs } from "@mui/material";
import { NameIdObj } from "common/models/GenericTypes";
import { defaultGridCellStyle } from "util/GridUtils";

interface Props {
    description: string,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
}

const linkStyle: React.CSSProperties = { color: 'inherit', textDecoration: 'underline' };

const ownerSpecialOptions: NameIdObj[] = [
    { id: -1, name: 'Me' },
    { id: -2, name: 'My Team' },
    { id: 0, name: 'My Company' },
];

export default function ForecastExceptionsReport({ description, loadingHandler, errorHandler }: Props) {
    const [rows1, setRows1] = useState<ForecastExceptionsJobsReportData[]>([]);
    const [rows2, setRows2] = useState<ForecastExceptionsCandidatesReportData[]>([]);
    const [showGrid, setShowGrid] = useState(false);
    const [consultantId, setConsultantId] = useState(0);
    const apiRef1 = useGridApiRef();
    const apiRef2 = useGridApiRef();
    const [activeTab, setActiveTab] = useState("Jobs");
    
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    const [showTagsPrevewNoDelay, setShowTagsPreviewNoDelay] = useState(false);
    const [previewType, setPreviewType] = useState<PreviewEntityType | ''>('');
    const [previewRecordId, setPreviewRecordId] = useState(0);
    const [isPreviewTags, setIsPreviewTags] = useState(false);
    const [previewRecordName, setPreviewRecordName] = useState('');
    
    const handlePreviewHover = (type: PreviewEntityType | '', id: number, isTags: boolean = false, recordName: string = '', noDelay: boolean = false) => {
        setPreviewType(type);
        setPreviewRecordId(id);
        setIsPreviewTags(isTags);
        setPreviewRecordName(recordName);
        setIsPreviewOpen(true);
        setShowTagsPreviewNoDelay(noDelay);
    };

    const handlePreviewClose = () => {
        setIsPreviewOpen(false);
        setShowTagsPreviewNoDelay(false);
    };

    const getDataCallback = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        const res1 = await GetForecastExceptionsJobsReportData(consultantId, errorHandler);
        if (res1) {
            setRows1(res1);
        }
        const res2 = await GetForecastExceptionsCandidatesReportData(consultantId, errorHandler);
        if (res2) {
            setRows2(res2);
        }
        setShowGrid(true);
        loadingHandler && loadingHandler(false);
    }, [consultantId, errorHandler, loadingHandler]);

    const exportAsExcelHandler1 = useCallback(() => {
        const api = apiRef1.current;
        if (api) {
            const m = moment();
            const filename = `ForecastExceptionsJobs${m.format('YYYYMMDDhhmmss')}`;
            api.exportDataAsExcel({ fileName: filename });
        }
    }, [apiRef1]);

    const exportAsExcelHandler2 = useCallback(() => {
        const api = apiRef2.current;
        if (api) {
            const m = moment();
            const filename = `ForecastExceptionsCandidates${m.format('YYYYMMDDhhmmss')}`;
            api.exportDataAsExcel({ fileName: filename });
        }
    }, [apiRef2]);

    const linkToClientRenderer = useCallback((params: GridRenderCellParams) => {
        if (params.value) {
            return (
                <div style={defaultGridCellStyle}>
                    <Link
                        to={`/clients/${params.row.ClientID}`}
                        style={ linkStyle }
                        onMouseEnter={ () => handlePreviewHover('client', +params.row.ClientID) }
                        onMouseLeave={ handlePreviewClose }
                    >{params.value}</Link>
                </div>
            );
        }
    }, []);

    const linkToJobRenderer = useCallback((params: GridRenderCellParams) => {
        if (params.value) {
            return (
                <div style={defaultGridCellStyle}>
                    <Link
                        to={`/jobs/${params.id}`}
                        style={ linkStyle }
                        onMouseEnter={ () => handlePreviewHover('job', +params.id) }
                        onMouseLeave={ handlePreviewClose }
                    >{params.value}</Link>
                </div>
            );
        }
    }, []);

    const linkToCandidatesRenderer = useCallback((params: GridRenderCellParams) => {
        if (params.value) {
            return (
                <div style={defaultGridCellStyle}>
                    <Link
                        to={`/candidates/${params.row.CandidateID}`}
                        style={ linkStyle }
                        onMouseEnter={ () => handlePreviewHover('candidate', +params.row.CandidateID) }
                        onMouseLeave={ handlePreviewClose }
                    >{params.value}</Link>
                </div>
            );
        }
    }, []);

    const minHeightCellRenderer = useCallback((params: GridRenderCellParams) => {
        return (
            <div style={defaultGridCellStyle}>
                <span>{params.value}</span>
            </div>
        );
    }, []);

    const columns1 = useMemo<GridColDef[]>(() => {
        const htmlRenderer = (params: GridRenderCellParams) => {
            if (params.value) {
                return <div dangerouslySetInnerHTML={{ __html: params.value }} />
            }
        };
        return [
            { field: 'JobID', headerName: 'ID', width: 120, renderCell: linkToJobRenderer },
            { field: 'EmploymentType', headerName: 'Job Type', width: 120, renderCell: minHeightCellRenderer },
            { field: 'JobTitle', headerName: 'Title', width: 120, renderCell: linkToJobRenderer },
            { field: 'ClientName', headerName: 'Client Name', width: 120, renderCell: linkToClientRenderer },
            { field: 'Consultant1', headerName: 'Counsultant 1', width: 175, renderCell: minHeightCellRenderer },
            { field: 'Consultant2', headerName: 'Counsultant 2', width: 175, renderCell: minHeightCellRenderer },
            { field: 'Issue', headerName: 'Issues', width: 300, renderCell: htmlRenderer }
        ];
    }, [linkToClientRenderer, linkToJobRenderer, minHeightCellRenderer]);

    const columns2 = useMemo<GridColDef[]>(() => {
        const dateValueGetter: GridValueGetter<ForecastExceptionsCandidatesReportData, any, undefined, string> = (value) => {
            if (value) {
                const d = moment(value);
                if (d.isValid() && d.get('year') > 1) {
                    return d.toDate();
                }
            }
        };

        const dateRenderer = (params: GridRenderCellParams) => {
            if (params.value) {
                return <div style={defaultGridCellStyle}>{moment(params.value).format('DD MMM YYYY h:mm A')}</div>;
            }
        };

        return [
            { field: 'JobID', headerName: 'ID', width: 120, renderCell: linkToJobRenderer },
            { field: 'EmploymentType', headerName: 'Job Type', width: 120, renderCell: minHeightCellRenderer },
            { field: 'JobTitle', headerName: 'Title', width: 120, renderCell: linkToJobRenderer },
            { field: 'ClientName', headerName: 'Client Name', width: 120, renderCell: linkToClientRenderer },
            { field: 'CandidateName', headerName: 'Candidate Name', width: 120, renderCell: linkToCandidatesRenderer },
            { field: 'CurrentStatus', headerName: 'Current Status', width: 120, renderCell: minHeightCellRenderer },
            { field: 'StatusLastUpdated', headerName: 'Status Last Update', width: 150, type: 'date', valueGetter: dateValueGetter, renderCell: dateRenderer },
            { field: 'Consultant1', headerName: 'Counsultant 1', width: 175, renderCell: minHeightCellRenderer },
            { field: 'Consultant2', headerName: 'Counsultant 2', width: 175, renderCell: minHeightCellRenderer },
        ];
    }, [linkToCandidatesRenderer, linkToClientRenderer, linkToJobRenderer, minHeightCellRenderer]);

    return (
        <>
            <PreviewLoaderComponent
                open={isPreviewOpen}
                entityType={previewType}
                recordId={previewRecordId}
                isTagsPreview={isPreviewTags}
                showDelayMs={showTagsPrevewNoDelay ? 0 : undefined}
                titleOverride={previewRecordName}
            />
            <Box display="flex">
                <Box flex="1 1 0" pr={1}>
                    <UserPicker disableClearable label="Consultant" userId={consultantId} onSelect={u => setConsultantId(u ? u.id : 0)} appendToStart={ownerSpecialOptions} includeInactiveUsers={true} />
                </Box><Button variant="contained" color="success" onClick={getDataCallback}>Run Report</Button>
            </Box>
            <Typography component="div" variant="h6" mt={2}>Report Description</Typography>
            <Typography component="div" variant="body2" mb={2}>{description}</Typography>
            {showGrid &&
                <>
                <Tabs value={activeTab} onChange={ (e: React.SyntheticEvent, value: string) => setActiveTab(value)}>
                    <Tab value="Jobs" label="Jobs" />
                    <Tab value="Candidates" label="Candidates" />
                </Tabs>
                {activeTab === 'Jobs' &&
                    <>
                        <Box pb="10px" ml="auto">
                            <Button variant="contained" color="success" onClick={exportAsExcelHandler1}>Export As Excel</Button>
                        </Box>
                        <GridWithStateWrapper
                            density="compact"
                            gridName="reports-forecastexception-jobs"
                            rows={rows1}
                            columns={columns1}
                            apiRef={apiRef1}
                            getRowId={r => r.JobID}
                            disableRowSelectionOnClick
                            getRowHeight={() => 'auto'}
                        />
                    </>
                }
                {activeTab !== 'Jobs' &&
                    <>
                        <Box pb="10px" ml="auto">
                            <Button variant="contained" color="success" onClick={exportAsExcelHandler2}>Export As Excel</Button>
                        </Box>
                        <GridWithStateWrapper
                            density="compact"
                            gridName="reports-forecastexception-candidates"
                            rows={rows2}
                            columns={columns2}
                            apiRef={apiRef2}
                            getRowId={r => `${r.JobID};${r.CandidateID}`}
                            disableRowSelectionOnClick
                        />
                    </>
                }
                </>
            }
        </>
    );
}
