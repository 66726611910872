import React, { useCallback, useEffect, useMemo, useState } from "react";
import OnlyTitleSummaryBar from "components/SummaryBars/OnlyTitleSummaryBar";
import PageContentLayout from "layouts/PageContentLayout";
import PageLayout from "layouts/PageLayout";
import { GetSettingBySettingName, UpdateSettingBySettingName } from "services/UsersService";
import JobsGridComponent from "components/Grids/JobsGrid";
import JobsKanbanComponent from "components/Kanbans/JobsKanban";
import Switch from "@mui/material/Switch";
import { getJwtPayload } from "util/AuthUtils";
import Box from "@mui/material/Box";
import UserPicker from "components/Pickers/UserPicker";

const summaryBar = <OnlyTitleSummaryBar title="Jobs > Open > Consultant" browserTabTitle="Consultant > Open > Jobs" />;
const defaultJobViewSettingName = 'DefaultJobsViewConsultantJobs';

export default function ConsultantJobsPage() {
    const [jobsView, setJobsView] = useState<'list' | 'kanban'>('list');
    const [userSource, setUserSource] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingTab, setIsLoadingTab] = useState(false);
    const [fetchedViewSetting, setFetchedViewSetting] = useState(false);

    useEffect(() => {
        const jwtPayload = getJwtPayload();
        if (jwtPayload) setUserSource(jwtPayload.UserID);
    }, []);

    useEffect(() => {
        const getData = async () => {
            setIsLoading(true);
            const defaultJobView = await GetSettingBySettingName(defaultJobViewSettingName);
            if (defaultJobView) setJobsView(defaultJobView === 'list' ? 'list' : 'kanban');
            setFetchedViewSetting(true);
            setIsLoading(false);
        };
        getData();
    }, []);

    const jobsViewChangeHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = e.target.checked;
        const value = isChecked ? 'list' : 'kanban';
        setJobsView(value);
        UpdateSettingBySettingName(defaultJobViewSettingName, value);
    }, []);

    const openJobsViewSwitch = useMemo(() => (
        <Box display="flex" justifyContent="space-between" pb="10px">
            <span>
                <span>Kanban</span>
                <Switch checked={jobsView === 'list'} onChange={ jobsViewChangeHandler } />
                <span>List</span>
            </span>
            <UserPicker onSelect={u => setUserSource(u ? u.id : 0)} userId={userSource} label="Consultant" sx={{ flex: '0 1 400px' }} />
        </Box>
    ), [jobsView, jobsViewChangeHandler, userSource]);

    return (
        <>
            <PageLayout SummaryBar={summaryBar}>
                <PageContentLayout showLoading={isLoading || isLoadingTab}>
                    {fetchedViewSetting && jobsView === 'kanban' &&
                        <>
                            {openJobsViewSwitch}
                            <JobsKanbanComponent source="consultant-jobs" sourceId={userSource} loadingHandler={setIsLoadingTab} />
                        </>
                    }
                    {fetchedViewSetting && jobsView === 'list' &&
                        <JobsGridComponent
                            source="consultant-jobs"
                            sourceId={userSource}
                            jobState={1}
                            loadingHandler={setIsLoadingTab}
                            extraActions={openJobsViewSwitch}
                            gridName="jobs/consultant-jobs"
                        />
                    }
                </PageContentLayout>
            </PageLayout>
        </>
    );
}