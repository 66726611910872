import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import ControlCameraIcon from '@mui/icons-material/ControlCamera';
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Draggable from "react-draggable";
import { PreviewProps } from "common/models/Previews/Previews";
import { PREVIEW_HEIGHT, PREVIEW_TITLE_STYLE, PREVIEW_WIDTH } from "util/Definitions/Constants/Previews";
import { Placement } from "common/models/Placements";
import { GetPlacementById } from "services/PlacementsService";
import { GetCurrencies } from "services/CommonService";
import { Currency } from "common/models/Common";
import Divider from "@mui/material/Divider";
import { CustomField } from "common/models/Configuration/CustomFields";
import { Division } from "common/models/Configuration/Division";
import { DashboardLayoutComponent, PanelModel } from "@syncfusion/ej2-react-layouts";
import { useTheme } from "@mui/material/styles";
import { DefaultPlacementQuickViewLayoutElements_Contract, DefaultPlacementQuickViewLayoutElements_FixedTermContract, DefaultPlacementQuickViewLayoutElements_MarginOnlyContract, DefaultPlacementQuickViewLayoutElements_MaxTermContract, DefaultPlacementQuickViewLayoutElements_Permanent, GetPanelDefinitionsFromPanelModels, PlacementQuickViewLayoutSettings } from "util/Definitions/QuickViewLayout/Placement";
import { CustomFieldSettingsMap, CustomFieldType } from "common/models/ScreenLayouts/CustomFields";
import { GetCustomerSettingBySettingName } from "services/ConfigurationService";
import { PlacementRecordDashboardElementDefinition, PlacementRecordDashboardElementType } from "common/models/Dashboard/EditLayout";
import SingleFieldElement from "components/ScreenLayouts/SingleFieldElement";
import MultiLineTextElement from "components/ScreenLayouts/MultiLineTextElement";
import { UdfPlacementFieldMapObj } from "util/Definitions/ScreenLayouts/CustomFields";
import PanelWrapper from "components/Dashboards/PanelWrapper";
import Box from "@mui/material/Box";
import ViewClientLocationElement from "components/ScreenLayouts/Clients/ViewClientLocationElement";
import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import { GetCustomFieldsByEntity_OnlyActive } from "services/CustomFieldsService";
import { GetDivisions } from "services/DivisionsService";

interface Props2 {
    isSeperatePayBillDivision: boolean,
    netRevenueLabel: string,
    currencies: Currency[],
    activeFields: CustomField[],
    divisions: Division[],
    placement?: Placement,
    setFetchingSavedState: (v: boolean) => void
}

const formatNumber = (value: number) => {
    return value.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 });
};

const getSalaryUnitsName = (unitsId: number) => {
    switch (unitsId)
    {
        case 1: return "Hourly";
        case 2: return "Daily";
        case 3: return "Weekly";
        case 4: return "Monthly";
        case 5: return "Yearly";
        default: return "";
    }
};

const getNoticePeriodUnitsName = (unitsId: number) => {
    switch (unitsId)
    {
        case 1: return "Day(s)";
        case 2: return "Week(s)";
        case 3: return "Month(s)";
        default: return "";
    }
};

const getPayFreqName = (payFreqId: number) => {
    switch (payFreqId)
    {
        case 1: return "Weekly";
        case 2: return "Fortnightly";
        case 3: return "Monthly";
        case 4: return "4 Weekly";
        case 5: return "Weekly ( 1 Week in Arrears )";
        case 6: return "Weekly ( 3 Weeks in Arrears )";
        default: return "";
    }
};

const getPaymentTypeName = (paymentTypeId: number) => {
    switch (paymentTypeId)
    {
        case 1: return "Employee";
        case 2: return "Sub Contractor";
        default: return "";
    }
};

const getFeeString = (feeType: number, feeAmount: number, feePercent: number) => {
    if (feeType === 1) return `${formatNumber(feePercent)} Percent (${formatNumber(feeAmount)})`;
    if (feeType === 2) return `${formatNumber(feeAmount)} Fixed (${formatNumber(feePercent)}%)`;
    return '';
};

const { unitWidth, unitHeight, gapX, gapY, columns } = PlacementQuickViewLayoutSettings;
const cellSpacing = [gapX, gapY];

export default function PlacementQuickViewComponent({ recordId, isSourceHovered, hideCallback, hideDelayMs = 1250 }: PreviewProps) {
    const [shouldClose, setShouldClose] = useState(false);
    const [isFetchingData, setIsFetchingData] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [placement, setPlacement] = useState<Placement>();
    const [currencies, setCurrencies] = useState<Currency[]>([]);

    const [isLoading, setIsLoading] = useState(false);
    const [placementTypeSwitch, setPlacementTypeSwitch] = useState(false);
    const [placementType, setPlacementType] = useState(0);
    const [fetchingSavedState, setFetchingSavedState] = useState(false);
    const [activeFields, setActiveFields] = useState<CustomField[]>([]);
    const [divisions, setDivisions] = useState<Division[]>([]);
    const [netRevenueLabel, setNetRevenueLabel] = useState('Net Revenue');
    const [isSeperatePayBillDivision, setIsSeperatePayBillDivision] = useState(false);

    useEffect(() => {
        const getData = async () => {
            setIsFetchingData(true);
            const cur = await GetCurrencies();
            if (cur) setCurrencies(cur);
            const res = await GetPlacementById(recordId);
            if (res) {
                setPlacement(res);
                setPlacementType(res.placementTypeID);
            }
            setIsFetchingData(false);
        };
        getData();
    }, [recordId]);

    useEffect(() => {
        if (shouldClose) {
            const hidePreviewTimeout = setTimeout(() => {
                hideCallback();
            }, hideDelayMs);
            return () => clearTimeout(hidePreviewTimeout);
        }
    }, [shouldClose, hideDelayMs, hideCallback]);

    useEffect(() => {
        if (isSourceHovered || isHovered) setShouldClose(false);
        else setShouldClose(true);
    }, [isSourceHovered, isHovered]);

    useEffect(() => {
        if (placementType) {}
        setPlacementTypeSwitch(prev => !prev);
    }, [placementType]);

    useEffect(() => {
        const getActiveFields = async () => {
            const res = await GetCustomFieldsByEntity_OnlyActive(5);
            if (res) setActiveFields(res);
        };
        getActiveFields();
    }, []);

    useEffect(() => {
        const getSettingsData = async () => {
            const res = await GetDivisions();
            if (res) setDivisions(res);
        };
        getSettingsData();
    }, []);

    useEffect(() => {
        const getNetRevenueLabel = async () => {
            const res = await GetCustomerSettingBySettingName('NetRevenueName');
            if (res) setNetRevenueLabel(res);
        };
        getNetRevenueLabel();
    }, []);

    useEffect(() => {
        const getData = async () => {
            const value = await GetCustomerSettingBySettingName('PlacementsSeperatePayBillDivision');
            if (value) setIsSeperatePayBillDivision(value === 'True');
        };
        getData();
    }, []);

    useEffect(() => {
        setIsLoading(fetchingSavedState || isFetchingData);
    }, [fetchingSavedState, isFetchingData]);

    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
            defaultPosition={{ x: (window.innerWidth / 2) - (PREVIEW_WIDTH / 2), y: (window.innerHeight / 2) - (PREVIEW_HEIGHT / 2) }}
        >
            <Dialog
                open={true}
                maxWidth={false}
                PaperProps={{ 
                    onMouseEnter: () => setIsHovered(true),
                    onMouseLeave: () => setIsHovered(false),
                    sx: { height: `${PREVIEW_HEIGHT}px`, width: `${PREVIEW_WIDTH}px` }
                }}
                hideBackdrop
                sx={{ bottom: 'unset', right: 'unset', left: 'unset' }}
            >
                <DialogTitle component="div" display="flex" sx={ PREVIEW_TITLE_STYLE }>
                    <div style={{ alignSelf: 'center' }}>Placement Preview</div>
                    <div style={{ marginLeft: 'auto' }}>
                        <IconButton id="draggable-dialog-title" sx={{ cursor: 'move', color: t => t.palette.primary.contrastText }}><ControlCameraIcon /></IconButton>
                        <IconButton onClick={ () => hideCallback() } sx={{ color: t => t.palette.primary.contrastText }}><CloseIcon /></IconButton>
                    </div>
                </DialogTitle>
                <DialogContent sx={{ p: 0 }}>
                    {(isLoading || !Boolean(placement)) &&
                        <Box display="flex" justifyContent="center" alignItems="center" height="100%" >
                            <CircularProgress />
                        </Box>
                    }
                    <Box p="10px" height="100%" display={isLoading ? 'none' : undefined}>
                        <div className="control-section">
                            { placement && placementTypeSwitch &&
                                <RenderContent
                                    currencies={currencies}
                                    activeFields={activeFields}
                                    divisions={divisions}
                                    placement={placement}
                                    netRevenueLabel={netRevenueLabel}
                                    isSeperatePayBillDivision={isSeperatePayBillDivision}
                                    setFetchingSavedState={setFetchingSavedState}
                                />
                            }
                            { placement && !placementTypeSwitch &&
                                <RenderContent
                                    currencies={currencies}
                                    activeFields={activeFields}
                                    divisions={divisions}
                                    placement={placement}
                                    netRevenueLabel={netRevenueLabel}
                                    isSeperatePayBillDivision={isSeperatePayBillDivision}
                                    setFetchingSavedState={setFetchingSavedState}
                                    
                                />
                            }
                        </div>
                    </Box>
                </DialogContent>
            </Dialog>
        </Draggable>
    );
}

function RenderContent({ placement, netRevenueLabel, activeFields, divisions, currencies, isSeperatePayBillDivision, setFetchingSavedState }: Props2) {
    const [screenResizedControl, setScreenResizedControl] = useState(false);
    const [fetchedSavedState, setFetchedSavedState] = useState(false);
    const [lastSavedState, setLastSavedState] = useState<PanelModel[] | null>(null);
    const theme = useTheme();

    const layoutDataSettingName = useMemo(() => {
        if (placement) {
            switch (placement.placementTypeID) {
                case 1: return 'PlacementQuickViewLayoutElements_Permanent';
                case 2: return 'PlacementQuickViewLayoutElements_Contract';
                case 3: return 'PlacementQuickViewLayoutElements_FixedTermContract';
                case 4: return 'PlacementQuickViewLayoutElements_MaxTermContract';
                case 5: return 'PlacementQuickViewLayoutElements_MarginOnlyContract';
            }
        }
        return '';
    }, [placement]);

    const defaultElements = useMemo(() => {
        if (placement) {
            switch (placement.placementTypeID) {
                case 1: return DefaultPlacementQuickViewLayoutElements_Permanent;
                case 2: return DefaultPlacementQuickViewLayoutElements_Contract;
                case 3: return DefaultPlacementQuickViewLayoutElements_FixedTermContract;
                case 4: return DefaultPlacementQuickViewLayoutElements_MaxTermContract;
                case 5: return DefaultPlacementQuickViewLayoutElements_MarginOnlyContract;
            }
        }
        return [];
    }, [placement]);

    useEffect(() => {
        const windowResizeHandler = () => setScreenResizedControl(prev => !prev);
        window.addEventListener('resize', windowResizeHandler);
        return () => window.removeEventListener('resize', windowResizeHandler);
    }, []);

    const divisionNames = useMemo<string[]>(() => {
        if (placement) {
            let names: string[] = [];
            const ids = placement.division.toString().split(';');
            ids.forEach(d => {
                const item = divisions.find(div => div.id === +d);
                if (item) names.push(item.name);
            });
            return names;
        }
        return [];
    }, [placement, divisions]);

    const payDivisionNames = useMemo<string[]>(() => {
        if (placement) {
            let names: string[] = [];
            const ids = placement.payDivision.toString().split(';');
            ids.forEach(d => {
                const item = divisions.find(div => div.id === +d);
                if (item) names.push(item.name);
            });
            return names;
        }
        return [];
    }, [placement, divisions]);

    const customFieldsSettingsMap = useMemo<CustomFieldSettingsMap>(() => {
        let obj: CustomFieldSettingsMap = {};
        activeFields.forEach(u => {
            const customFieldNumber = u.name.substring(13);
            const key = 'CustomField' + customFieldNumber as CustomFieldType;
            obj[key] = {
                title: u.agencyName,
                isMultiLine: u.multiLine && !u.usePredefinedValues,
            }
        });
        return obj;
    }, [activeFields]);

    useEffect(() => {
        const getSavedState = async () => {
            setFetchingSavedState(true);
            const elementsJson = await GetCustomerSettingBySettingName(layoutDataSettingName);
            if (elementsJson) {
                const panels = JSON.parse(elementsJson) as PanelModel[];
                setLastSavedState(panels);
            }
            setFetchingSavedState(false);
            setFetchedSavedState(true);
        };
        layoutDataSettingName && getSavedState();
    }, [layoutDataSettingName, setFetchingSavedState]);

    const elements = useMemo<PlacementRecordDashboardElementDefinition[]>(() => {
        if (!fetchedSavedState) return [];
        if (lastSavedState !== null) {
            const elements = GetPanelDefinitionsFromPanelModels(lastSavedState);
            return elements ;
        }
        return defaultElements;
    }, [lastSavedState, fetchedSavedState, defaultElements]);

    const currencyName = useMemo(() => {
        if (placement) {
            const currency = currencies.find(c => c.id === placement.currencyID);
            if (currency) return currency.formattedDescription;
        }
        return '';
    }, [placement, currencies]);

    const renderElement = useCallback((id: string, type: PlacementRecordDashboardElementType) => {
        if (placement) {
            switch (type) {
                case 'Divider': return <Box pt="20px"><Divider component="div" /></Box>;
                case 'Spacer': return <></>;
                case 'PlacementId': return <SingleFieldElement useEllipsisForLongValues fieldTitle="ID" fieldValue={placement.id.toString()} />;
                case 'PlacementJobId': return (
                    <SingleFieldElement
                        fieldTitle='Job ID'
                        fieldValue={placement.jobID.toString()}
                        format="internal-link"
                        href={`/jobs/${placement.jobID}`}
                    />);
                case 'PlacementType': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Job Type" fieldValue={placement.placementTypeName} />;
                case 'PlacementJobTitle': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Job Title" fieldValue={placement.jobTitle} />;
                case 'PlacementCandidate': return (
                    <SingleFieldElement
                        fieldTitle='Candidate'
                        fieldValue={placement.candidateName}
                        format="internal-link"
                        href={`/candidates/${placement.candidateID}`}
                    />);
                case 'PlacementStartDate': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Start Date" fieldValue={placement.startDate} format="date" />;
                case 'PlacementEndDate': return <SingleFieldElement useEllipsisForLongValues fieldTitle="End Date" fieldValue={placement.endDate} format="date" />;
                case 'PlacementDivision': return (
                        <SingleFieldElement fieldTitle={isSeperatePayBillDivision ? 'Bill Division' : 'Division'} format="custom">
                            {divisionNames.map(d => (
                                <Chip key={d} size="small" label={d} sx={{ mr: '5px' }} />
                            ))}
                        </SingleFieldElement>
                    );
                case 'PlacementPayDivision': return (
                        <SingleFieldElement fieldTitle="Pay Division" format="custom">
                            {payDivisionNames.map(d => (
                                <Chip key={d} size="small" label={d} sx={{ mr: '5px' }} />
                            ))}
                        </SingleFieldElement>
                    );
                case 'PlacementCurrency': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Currency" fieldValue={currencyName} />;
                case 'PlacementSalaryPackage':
                    const salaryFrom = formatNumber(placement.salary);
                    const salaryUnits = getSalaryUnitsName(placement.salaryUnits);
                    return <SingleFieldElement useEllipsisForLongValues fieldTitle="Salary Package" fieldValue={`${salaryFrom} ${salaryUnits}`} />;
                case 'PlacementInvoiceFee': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Fee" fieldValue={getFeeString(placement.feeType, placement.permFeeAmount, placement.permFeePercentage)} />;
                case 'PlacementRatesCalculator': return <SingleFieldElement useEllipsisForLongValues fieldTitle="" fieldValue="Rates Calculator" />;
                case 'PlacementChargeRate': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Charge Rate" fieldValue={ formatNumber(placement.chargeRate) + ' ' + placement.chargeRateUnitsName} />;
                case 'PlacementPayRate': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Pay Rate" fieldValue={ formatNumber(placement.payRate) + ' ' + placement.payRateUnitsName} />;
                case 'PlacementOnCost': return <SingleFieldElement useEllipsisForLongValues fieldTitle="On Costs" fieldValue={ formatNumber(placement.onCosts) + ' ' + placement.onCostsUnitsName} />;
                case 'PlacementMargin': 
                    const margin = placement.chargeRate - (placement.payRate + placement.onCosts);
                    const marginPercent = placement.chargeRate !== 0 ? (margin * 100) / placement.chargeRate : 0;
                    return <SingleFieldElement useEllipsisForLongValues fieldTitle="Margin" fieldValue={ placement.chargeRate === 0 ? '' : `${formatNumber(margin)} (${formatNumber(marginPercent)}%)`} />;
                case 'PlacementHoursPerDay': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Hours Per Day" fieldValue={placement.hoursPerDay.toString()} />;
                case 'PlacementDaysPerWeek': 
                    const daysPerWeek = placement.daysPerWeek >= 1 && placement.daysPerWeek <= 7 ? placement.daysPerWeek.toString() : '';
                    return <SingleFieldElement useEllipsisForLongValues fieldTitle="Days Per Week" fieldValue={`${daysPerWeek} Day${placement.daysPerWeek > 1 ? 's' : ''}`} />;
                case 'PlacementComments': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Comments" fieldValue={placement.remComments} />;
                case 'PlacementPaymentType': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Payment Type" fieldValue={getPaymentTypeName(placement.paymentTypeID)} />;
                case 'PlacementPayCompany': return (
                    <SingleFieldElement
                        fieldTitle='Pay Company'
                        fieldValue={placement.paymentCompanyName ?? ''}
                        format="internal-link"
                        href={`/clients/${placement.paymentCompanyID}`}
                    />);
                case 'PlacementPayContact': return (
                    <SingleFieldElement
                        fieldTitle='Pay Contact'
                        fieldValue={placement.paymentContactName ?? ''}
                        format="internal-link"
                        href={`/contacts/${placement.paymentContactID}`}
                    />);
                case 'PlacementWarranty': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Warranty" fieldValue={placement.warrantyLength + ' Month(s)'} />;
                case 'PlacementLocation': return (
                    <ViewClientLocationElement
                        address1={placement.address1 ?? ''}
                        address2={placement.address2 ?? ''}
                        address3={placement.address3 ?? ''}
                        countryName={placement.countryName ?? ''}
                        postcode={placement.postcode ?? ''}
                        siteId={placement.siteID}
                        siteName={placement.siteName ?? ''}
                        state={placement.state ?? ''}
                        suburb={placement.suburb ?? ''}
                    />);
                case 'PlacementClient': return (
                    <SingleFieldElement
                        fieldTitle='Client'
                        fieldValue={placement.clientName}
                        format="internal-link"
                        href={`/clients/${placement.clientID}`}
                    />);
                case 'PlacementContact': return (
                    <SingleFieldElement
                        fieldTitle='Contact'
                        fieldValue={placement.contactName}
                        format="internal-link"
                        href={`/contacts/${placement.contactID}`}
                    />);
                case 'PlacementHiringManager': return (
                    <SingleFieldElement
                        fieldTitle='Hiring Mgr'
                        fieldValue={placement.hiringManagerName}
                        format="internal-link"
                        href={`/contacts/${placement.hiringManagerContactID}`}
                    />);
                case 'PlacementBilling1': return (
                    <SingleFieldElement
                        fieldTitle='Billing 1'
                        fieldValue={placement.billingContactName ?? ''}
                        format="internal-link"
                        href={`/contacts/${placement.billingContactContactID}`}
                    />);
                case 'PlacementBilling2': return (
                    <SingleFieldElement
                        fieldTitle='Billing 2'
                        fieldValue={placement.billingContact2Name ?? ''}
                        format="internal-link"
                        href={`/contacts/${placement.billingContact2ContactID}`}
                    />);
                case 'PlacementBilling3': return (
                    <SingleFieldElement
                        fieldTitle='Billing 3'
                        fieldValue={placement.billingContact3Name ?? ''}
                        format="internal-link"
                        href={`/contacts/${placement.billingContact3ContactID}`}
                    />);
                case 'PlacementSignatory': return (
                    <SingleFieldElement
                        fieldTitle='Signatory'
                        fieldValue={placement.signatoryName ?? ''}
                        format="internal-link"
                        href={`/contacts/${placement.signatoryContactID}`}
                    />);
                case 'PlacementClientRef': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Client Ref" fieldValue={placement.clientReference} />;
                case 'PlacementApprover1': return (
                    <SingleFieldElement
                        fieldTitle='Approver 1'
                        fieldValue={placement.timesheetApprover1Name ?? ''}
                        format="internal-link"
                        href={`/contacts/${placement.timesheetApprover1}`}
                    />);
                case 'PlacementApprover2': return (
                    <SingleFieldElement
                        fieldTitle='Approver 2'
                        fieldValue={placement.timesheetApprover2Name ?? ''}
                        format="internal-link"
                        href={`/contacts/${placement.timesheetApprover2}`}
                    />);
                case 'PlacementSite': return (
                    <SingleFieldElement
                        fieldTitle='Site'
                        fieldValue={placement.siteName ?? ''}
                        format="internal-link"
                        href={`/sites/${placement.siteID}`}
                    />);
                case 'PlacementSummary': return <MultiLineTextElement title="Summary" content={placement.summary ?? ''} />;
                case 'PlacementStatus': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Status" fieldValue={placement.placementStatusName} />;
                case 'PlacementConsultant1': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Consultant 1" fieldValue={ placement.consultantID1 ? `${placement.consultantName1} (${placement.consultantPercentage1}%)` : ''} />;
                case 'PlacementConsultant2': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Consultant 2" fieldValue={ placement.consultantID2 ? `${placement.consultantName2} (${placement.consultantPercentage2}%)` : ''} />;
                case 'PlacementConsultant3': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Consultant 3" fieldValue={ placement.consultantID3 ? `${placement.consultantName3} (${placement.consultantPercentage3}%)` : ''} />;
                case 'PlacementConsultant4': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Consultant 4" fieldValue={ placement.consultantID4 ? `${placement.consultantName4} (${placement.consultantPercentage4}%)` : ''} />;
                case 'PlacementConsultant5': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Consultant 5" fieldValue={ placement.consultantID5 ? `${placement.consultantName5} (${placement.consultantPercentage5}%)` : ''} />;
                case 'PlacementSource': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Source" fieldValue={placement.source} />;
                case 'PlacementNetRevenue': return <SingleFieldElement useEllipsisForLongValues fieldTitle={netRevenueLabel} fieldValue={formatNumber(placement.netRevenue)} />;
                case 'PlacementDate': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Placement Date" fieldValue={placement.placementDate} format="date" />;
                case 'PlacementProbation': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Probation" fieldValue={placement.probationLength + ' Month(s)'} />;
                case 'PlacementNotice': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Notice" fieldValue={placement.noticePeriod + ' ' + getNoticePeriodUnitsName(placement.noticePeriodUnits)} />;
                case 'PlacementPayFreq': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Pay Frequency" fieldValue={getPayFreqName(placement.payFrequencyID)} />;
                case 'PlacementComplianceChecklist': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Compliance Checklist" fieldValue={placement.complianceChecklistName} />;
                default: 
                    if (type.startsWith('CustomField')) {
                        const settings = customFieldsSettingsMap[type];
                        if (settings) {
                            const title = settings.title;
                            const { field, format } = UdfPlacementFieldMapObj[type as CustomFieldType];
                            const udfValue = placement[field];
                            if (format === 'string' || format === 'date' || format === 'datetime') {
                                return <SingleFieldElement isMultiLine={settings.isMultiLine} format={format} fieldTitle={title} fieldValue={udfValue ? udfValue.toString() : null} />;
                            }
                            if (format === 'number') {
                                const numValue = udfValue === undefined || udfValue === null ? '' : udfValue.toString();
                                return <SingleFieldElement fieldTitle={title} fieldValue={numValue} />;
                            }
                        }
                        else return <SingleFieldElement fieldTitle={type} fieldValue="[ Disabled Custom Field ]" />
                    }
                    return <div>{id}</div>;
            }
        }
    }, [placement, isSeperatePayBillDivision, divisionNames, payDivisionNames, currencyName, netRevenueLabel, customFieldsSettingsMap]);

    const layoutResizeStopHandler = useCallback(() => {
        setScreenResizedControl(prev => !prev);
    }, []);

    const renderLayout = useCallback(() => {
        if (!fetchedSavedState) return <></>;
        return (
            <DashboardLayoutComponent
                cellSpacing={cellSpacing}
                columns={columns}
                cellAspectRatio={12 / 2}
                resizeStop={ layoutResizeStopHandler }
                allowDragging={false}
                allowResizing={false}
            >
                {elements.map(e => (
                    <PanelWrapper
                        key={e.id}
                        id={e.id}
                        col={e.col}
                        row={e.row}
                        sizeX={e.sizeX}
                        sizeY={e.sizeY}
                        minSizeX={e.minSizeX}
                        minSizeY={e.minSizeY}
                        maxSizeX={e.maxSizeX}
                        maxSizeY={e.maxSizeY}
                        resizeControl={screenResizedControl}
                        resizeIconColor={theme.palette.text.disabled}
                        gapX={gapX}
                        gapY={gapY}
                        unitWidth={unitWidth}
                        unitHeight={unitHeight}
                    >
                        {renderElement(e.id, e.type)}
                    </PanelWrapper>
                ))}
            </DashboardLayoutComponent>
        );
    }, [elements, fetchedSavedState, theme.palette.text.disabled, screenResizedControl, layoutResizeStopHandler, renderElement]);

    return renderLayout();
}