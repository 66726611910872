import Alert from "components/Alert";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";
import Snackbar from "@mui/material/Snackbar";
import Typography from "@mui/material/Typography";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { EmailMessage, MessageAttachment } from "common/models/EmailIntegration";
import { Permission } from "common/models/Permissions";
import ConfirmationDialog from "components/Dialogs/Generic/ConfirmationDialog";
import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import PageContentLayout from "layouts/PageContentLayout";
import PageLayout from "layouts/PageLayout";
import { DeleteActivityById } from "services/ActivitiesService";
import { DownloadAttachmentFile, GetEmailAttachments, GetEmailMessageByActivityId } from "services/MessagesService";
import { companyHasSinglePermission, userHasSinglePermission } from "util/PermissionsUtils";
import { Editor } from "@tinymce/tinymce-react";
import Box from "@mui/material/Box";
import CopyAttachmentsToRecordDialog from "components/Dialogs/Messages/CopyAttachmentsToRecord";

const defaultMessage: EmailMessage = {
    activityID: 0,
    attachments: 0,
    body: '',
    cc: '',
    createdDate: '',
    customerId: 0,
    deleted: false,
    from: '',
    messageDate: '',
    subject: '',
    to: '',
    userId: 0
};

export default function MessageItemPage() {
    const [summaryBar, setSummaryBar] = useState<JSX.Element>(<></>);
    const [isLoading, setIsLoading] = useState(false);
    const [isFetchingMessage, setIsFetchingMessage] = useState(false);
    const [isFetchingAttachments, setIsFetchingAttachments] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [attachments, setAttachments] = useState<MessageAttachment[]>([]);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [message, setMessage] = useState(defaultMessage);
    const [showCopyAttachmentsDialog, setShowCopyAttachmentsDialog] = useState(false);
    const params = useParams();

    const itemId = useMemo(() => {
        const parsedId = +(params.messageId ?? '0');
        if(isNaN(parsedId)) return 0;
        return parsedId
    }, [params.messageId]);

    const canDeleteMessage = useMemo(() => companyHasSinglePermission(Permission.ActivitiesDelete) && userHasSinglePermission(Permission.ActivitiesDelete), []);

    useEffect(() => {
        const deleteAction = canDeleteMessage ? <Button variant="contained" color="error" disabled={message.deleted || itemId === 0} onClick={() => setShowDeleteConfirmation(true)}>Delete</Button> : <></>;
        const copyAttachmentsAction = attachments.length > 0 ? <Button variant="contained" onClick={() => setShowCopyAttachmentsDialog(true)}>Copy Attachments</Button> : <></>;
        const actions = <Box display="flex" gap="5px">{deleteAction}{copyAttachmentsAction}</Box>;
        const summaryBar = <TitleAndActionSummaryBar title="Messages" action={actions} />
        setSummaryBar(summaryBar);
    }, [itemId, message.deleted, canDeleteMessage, attachments.length]);

    useEffect(() => {
        const getData = async () => {
            setIsFetchingMessage(true);
            const data = await GetEmailMessageByActivityId(itemId, setErrorMessage);
            if (data) setMessage(data);
            setIsFetchingMessage(false);
        };
        getData();
    }, [itemId]);

    useEffect(() => {
        const getAttachments = async () => {
            setIsFetchingAttachments(true);
            const res = await GetEmailAttachments(itemId, setErrorMessage);
            if (res) setAttachments(res);
            setIsFetchingAttachments(false);
        };
        message.attachments > 0 && getAttachments();
    }, [message.attachments, itemId]);

    const messageDate = useMemo(() => {
        if (message.messageDate) {
            const m = moment(message.messageDate);
            if (m.isValid())
                return moment(message.messageDate).format('DD MMM YYYY hh:mm:ss A');
        }
        return '';
    }, [message.messageDate]);

    const downloadAttachment = useCallback(async (attachmentId: number) => {
        setIsFetchingAttachments(true);
        await DownloadAttachmentFile(attachmentId, setErrorMessage);
        setIsFetchingAttachments(false);
    }, []);

    const deleteMessageHandler = useCallback(async () => {
        setIsFetchingMessage(true);
        const res = await DeleteActivityById(itemId, setErrorMessage);
        if (res) {
            setMessage(prev => ({...prev, deleted: true}));
            setShowDeleteConfirmation(false);
        }
        setIsFetchingMessage(false);
    }, [itemId]);

    return (
        <PageLayout SummaryBar={summaryBar}>
            <Snackbar open={Boolean(successMessage)} autoHideDuration={3000} onClose={() => setSuccessMessage('')}>
                <Alert onClose={() => setSuccessMessage('')}>{successMessage}</Alert>
            </Snackbar>
            <Snackbar open={errorMessage !== ''} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <ConfirmationDialog
                open={showDeleteConfirmation}
                message="Are you sure you want to delete this message?"
                title="Confirm Action"
                onClose={() => setShowDeleteConfirmation(false)}
                onContinue={ deleteMessageHandler }
            />
            <CopyAttachmentsToRecordDialog
                open={showCopyAttachmentsDialog}
                attachments={attachments}
                closeHandler={() => setShowCopyAttachmentsDialog(false)}
                messageId={itemId}
                loadingHandler={setIsLoading}
                successHandler={setSuccessMessage}
                errorHandler={setErrorMessage}
            />
            <PageContentLayout showLoading={isFetchingMessage || isFetchingAttachments || isLoading}>
                <Card sx={{ mb: '20px' }} elevation={12}>
                    <CardContent>
                        { message.deleted && <Typography variant="h5" textAlign="center">{'[ DELETED ]'}</Typography> }
                        <div>
                            <div style={{ borderBottom: '1px solid rgb(128,128,128)' }}>
                                <Typography component="span" fontWeight={600} variant="body1" sx={{ p: '3px 16px', width: '125px', display: 'inline-block' }}>Sent:</Typography>
                                <Typography component="span" variant="body2">{messageDate}</Typography>
                            </div>
                            <div style={{ borderBottom: '1px solid rgb(128,128,128)' }}>
                                <Typography component="span" fontWeight={600} variant="body1" sx={{ p: '3px 16px', width: '125px', display: 'inline-block' }}>From:</Typography>
                                <Typography component="span" variant="body2">{message.from}</Typography>
                            </div>
                            <div style={{ borderBottom: '1px solid rgb(128,128,128)' }}>
                                <Typography component="span" fontWeight={600} variant="body1" sx={{ p: '3px 16px', width: '125px', display: 'inline-block' }}>To:</Typography>
                                <Typography component="span" variant="body2">{message.to}</Typography>
                            </div>
                            <div style={{ borderBottom: '1px solid rgb(128,128,128)' }}>
                                <Typography component="span" fontWeight={600} variant="body1" sx={{ p: '3px 16px', width: '125px', display: 'inline-block' }}>Cc:</Typography>
                                <Typography component="span" variant="body2">{message.cc}</Typography>
                            </div>
                            <div style={{ borderBottom: '1px solid rgb(128,128,128)' }}>
                                <Typography component="span" fontWeight={600} variant="body1" sx={{ p: '3px 16px', width: '125px', display: 'inline-block' }}>Subject:</Typography>
                                <Typography component="span" variant="body2">{message.subject}</Typography>
                            </div>
                            <div style={{ borderBottom: '1px solid rgb(128,128,128)', display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
                                <Typography component="span" fontWeight={600} variant="body1" sx={{ p: '3px 16px', width: '125px', display: 'inline-block' }}>Attachments:</Typography>
                                {attachments.length > 0
                                    ? attachments.map( att => (
                                        <Chip key={att.id} label={att.documentName} size="small" onClick={() => downloadAttachment(att.id)} sx={{ mr: '10px' }} />
                                    ))
                                    : <Typography component="span" variant="body2">None</Typography>
                                }
                            </div>
                        </div>
                    </CardContent>
                </Card>
                <div style={{ display: 'flex', flex: '1', flexDirection: 'column', position: 'relative' }}>
                    <div style={{ display: 'flex', flex: '1', flexDirection: 'column', position: 'relative', overflow: 'hidden' }}>
                        <div style={{ position: 'absolute', top: 0, left: 0, height: '100%', width: '100%', overflow: 'auto' }}>
                            <Editor
                                disabled
                                tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce-5.10.2/tinymce.min.js'}
                                value={message.body}
                                init={{
                                    height: '100%',
                                    branding: false,
                                    menubar: false,
                                    toolbar: false,
                                    statusbar: false,
                                    contextmenu: false,
                                    resize: false,
                                    browser_spellcheck: true,
                                    forced_root_block: false,
                                }}
                            />
                        </div>
                    </div>
                </div>
            </PageContentLayout>
        </PageLayout>
    );
}