import Alert from "components/Alert";
import Snackbar from "@mui/material/Snackbar";
import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import EditRecordScreenLayout from "components/ScreenLayouts/Opportunities/EditRecordScreenLayout";
import PageContentLayout from "layouts/PageContentLayout";
import PageLayout from "layouts/PageLayout";

export default function OpportunityEditPage() {
    const [summaryBar, setSummaryBar] = useState(<></>);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const params = useParams();

    const opportunityId = useMemo(() => {
        const parsedId = +(params.id ?? '0');
        if (isNaN(parsedId)) return 0;
        return parsedId;
    }, [params.id]);
    
    return (
        <PageLayout SummaryBar={summaryBar}>
            <Snackbar open={errorMessage !== ''} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <Snackbar open={successMessage !== ''} autoHideDuration={3000} onClose={() => setSuccessMessage('')} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert color="success" onClose={() => setSuccessMessage('')}>{ successMessage }</Alert>
            </Snackbar>
            <PageContentLayout showLoading={isLoading}>
                <EditRecordScreenLayout opportunityId={opportunityId} loadingHandler={setIsLoading} setSummaryBar={setSummaryBar} errorHandler={setErrorMessage} successHandler={setSuccessMessage} />
            </PageContentLayout>
        </PageLayout>
    );
}