import { Contact, ContactHierarchy, ContactOwner } from "../common/models/Contacts";
import { ErrorHandler, NumberValueWrapper, RequestOptions } from "../common/models/GenericTypes";
import { Delete, Get, Post, PostFile, Put, PutFile } from "../util/HttpUtils";
import { Report } from "../common/models/Reports/Report";
import { Job } from "../common/models/Jobs/Job";
import { Opportunity } from "../common/models/Opportunities";
import { Placement } from "../common/models/Placements";
import { ActivityMessage } from "../common/models/Activities";
import { Submission } from "../common/models/Submissions/Submission";
import { ClientSearch } from "../common/models/Search/ClientSearch";
import { ImportableContact } from "../common/models/Imports/ContactImport";
import {Meeting, MeetingAttendee} from "../common/models/Meetings";
import { PartnerAction } from "common/models/PartnerActions";
import {Interview} from "common/models/Interviews";

export async function ContactLookup(term: string, limit: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return await Get<Contact[]>(`api/contacts/Lookup?query=${encodeURIComponent(term)}&limit=${limit}`, options);
}

export async function SearchContacts(data: ClientSearch, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<Contact[]>('api/contacts/search', options, data);
}

export async function GetContactById(contactId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Contact>(`api/contacts/${contactId}`, options);
}

export async function CreateContact(contactData: Partial<Contact>, photoFile: File | null, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    const formData = new FormData();
    formData.append('contact', JSON.stringify(contactData));
    if (photoFile) formData.append('photo', photoFile);

    return PostFile<NumberValueWrapper>('api/contacts', formData, options);
}

export async function UpdateContact(contactData: Partial<Contact>, photoFile: File | null, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    const formData = new FormData();
    formData.append('contact', JSON.stringify(contactData));
    if (photoFile) formData.append('photo', photoFile);

    return PutFile<boolean>('api/contacts', formData, options);
}

export async function UpdateContactCustomField(contactId: number, fieldNumber: number, value: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/contacts/${contactId}/custom-fields`, options, { fieldNumber, value });
}

export async function MergeContacts(masterId: number, contactIds: number[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>(`api/contacts/${masterId}/merge`, options, contactIds);
}

export async function GetReports(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Report[]>('api/contacts/reports', options);
}

export async function GetMyContacts(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Contact[]>(`api/contacts/me`, options);
}

export async function GetContactsByTag(tagTypeId: number, taggedBy: number, tag: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Contact[]>(`api/contacts/tags/${tagTypeId}?taggedBy=${taggedBy}&tag=${encodeURIComponent(tag)}`, options);
}

export async function GetContactsDetailedReportData(owner: number, status: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<any>(`api/contacts/reports/detailed?owner=${owner}&status=${status}`, options);
}
export async function GetContactsNotContactedReportData(owner: number, status: number, timeRange: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<any>(`api/contacts/reports/not-contacted?owner=${owner}&status=${status}&timeRange=${timeRange}`, options);
}
export async function GetEmailMarketingReportData(owner: number, status: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<any>(`api/contacts/reports/email-marketing-list?owner=${owner}&status=${status}`, options);
}
export async function GetContactsNewReportData(start_date: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<any>(`api/contacts/reports/new?startDate=${start_date}`, options);
}

export async function DeleteContactById(contactId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Delete<boolean>(`api/contacts/${contactId}`, options);
}

export async function ConvertContactToCandidate(contactId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<NumberValueWrapper>(`api/contacts/${contactId}/convert/candidate`, options);
}

export async function GetContactOwners(contactId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<ContactOwner[]>(`api/contacts/${contactId}/Owners`, options);
}

export async function SetContactOwners(contactId: number, ownerIds: number[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/contacts/${contactId}/Owners`, options, ownerIds);
}

export async function GetContactHierarchy(contactId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<ContactHierarchy[]>(`api/contacts/${contactId}/Hierarchy`, options);
}

export async function GetContactPlacements(contactId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Placement[]>(`api/contacts/${contactId}/placements`, options);
}

export async function GetContactJobs(contactId: number, stateId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Job[]>(`api/contacts/${contactId}/jobs?stateId=${stateId}`, options);
}

export async function GetContactOpportunities(contactId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Opportunity[]>(`api/contacts/${contactId}/opportunities`, options);
}

export async function GetContactMeetings(contactId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Meeting[]>(`api/contacts/${contactId}/meetings`, options);
}

export async function GetContactInterviews(contactId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Interview[]>(`api/contacts/${contactId}/interviews`, options);
}

export async function GetContactMessages(contactId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<ActivityMessage[]>(`api/contacts/${contactId}/messages`, options);
}

export async function GetContactSubmissions(contactId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Submission[]>(`api/contacts/${contactId}/submissions`, options);
}

export async function SaveContactSearch(searchName: string, searchFrequency: number, data: ClientSearch, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<NumberValueWrapper>(`api/contacts/search/${searchFrequency}?name=${encodeURIComponent(searchName)}`, options, data);
}

export async function UpdateContactSavedSearch(ssid: number, searchName: string, searchFrequency: number, data: ClientSearch, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/contacts/search/${ssid}?freqId=${searchFrequency}&name=${encodeURIComponent(searchName)}`, options, data);
}

export async function RemoveContactFromClient(contactId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/contacts/${contactId}/Remove/Client`, options);
}

export async function BulkRemoveContactFromClient(contactIds: number[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/contacts/Remove/Client/Bulk`, options, contactIds);
}

export async function GrantClientPortalAccess(contactId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<NumberValueWrapper>(`api/contacts/${contactId}/client-portal`, options);
}

export async function RevokeClientPortalAccess(contactId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Delete<boolean>(`api/contacts/${contactId}/client-portal`, options);
}

export async function ToggleContactOptOut(contactId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/contacts/${contactId}/opt-out`, options);
}

export async function ImportContact(data: Partial<ImportableContact>, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<NumberValueWrapper>('api/contacts/import', options, data);
}

export async function GetContactAttendeeData(contactIds: number[], errorHandler?: ErrorHandler){
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    let query = '';

    if (contactIds.length > 0) {
        query = 'contactIds=' + contactIds.join('&contactIds=');
    }

    return Get<MeetingAttendee[]>(`api/Contacts/Attendees?${query}`, options);
}

export async function GetContactPartnerActions(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<PartnerAction[]>('api/contacts/partneractions', options);
}