import React, { useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import TagSearchPageContent from "components/Content/TagSearchPageContent";
import ClientsGridComponent from "components/Grids/ClientsGrid";
import OnlyTitleSummaryBar from "components/SummaryBars/OnlyTitleSummaryBar";
import PageContentLayout from "layouts/PageContentLayout";
import PageLayout from "layouts/PageLayout";
import Snackbar from "@mui/material/Snackbar";
import Alert from "components/Alert";

export default function ClientTagsPage() {
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [searchParams] = useSearchParams();

    const tagTypeId = useMemo(() => {
        const val = searchParams.get('tagType');
        if (val) return +val;
        return 0;
    }, [searchParams]);

    const taggedBy = useMemo(() => {
        const val = searchParams.get('taggedBy');
        if (val) return +val;
        return 0;
    }, [searchParams]);

    const tag = useMemo(() => {
        const val = searchParams.get('tag');
        if (val) return val;
        return '';
    }, [searchParams]);

    const showClientsByTag = useMemo(() => {
        return tagTypeId !== 0 && tag !== '';
    }, [tagTypeId, tag]);

    const summaryBar = useMemo(() => {
        if (showClientsByTag) return <OnlyTitleSummaryBar title={`Clients tagged with "${tag}"`} browserTabTitle="Tags > Clients" />
        return <OnlyTitleSummaryBar title="Clients > Tags" browserTabTitle="Tags > Clients" />;
    }, [tag, showClientsByTag]);
    
    return (
        <PageLayout SummaryBar={summaryBar}>
            <Snackbar open={successMessage !== ''} autoHideDuration={3000} onClose={() => setSuccessMessage('')}>
                <Alert onClose={() => setSuccessMessage('')}>{ successMessage }</Alert>
            </Snackbar>
            <Snackbar open={errorMessage !== ''} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <PageContentLayout showLoading={ isLoading }>
                {!showClientsByTag && <TagSearchPageContent entityType="clients" loadingHandler={setIsLoading} />}
                {showClientsByTag && <ClientsGridComponent gridName="client-tags" source="tags" tagLabel={tag} tagTypeId={tagTypeId} taggedBy={taggedBy} loadingHandler={setIsLoading} successHandler={setSuccessMessage} errorHandler={setErrorMessage} />}
            </PageContentLayout>
        </PageLayout>
    );
}