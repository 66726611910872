import { ChangeTracker } from "common/models/hooks/ChangeTracker";
import { DocumentTemplate } from "common/models/Templates/DocumentTemplate";

export const DefaultDocumentTemplate: DocumentTemplate = {
    id: 0,
    name: '',
    typeID: 0,
    statusID: 0,
    ownerID: 0,
    ownerName: '',
    fileID: 0,
    fileName: '',
    placeholders: [],
    isElectronicSigningEnabled: true,
    electronicSigningMessage: '',
    electronicSigningSubject: '',
    isElectronicSigningOrderedSigners: false,
    signatoryType1: 1,
    signatoryUserID1: 0,
    signatoryType2: 0,
    signatoryUserID2: 0,
    signatoryType3: 0,
    signatoryUserID3: 0,
    signatoryType4: 0,
    signatoryUserID4: 0,
    signatoryType5: 0,
    signatoryUserID5: 0,
    signatoryType6: 0,
    signatoryUserID6: 0,
    signatoryType7: 0,
    signatoryUserID7: 0,
    signatoryType8: 0,
    signatoryUserID8: 0,
    signatoryType9: 0,
    signatoryUserID9: 0,
    signatoryType10: 0,
    signatoryUserID10: 0,
    createdDate: '0001-01-01',
    createdByID: 0,
    createdByName: '',
    isDeleted: false,
    deletedByID: 0,
    deletedDate: '0001-01-01',
    generatedFileName: ''
}

export const NoChangesDocumentTemplate: ChangeTracker<DocumentTemplate> = {
    id: false,
    name: false,
    typeID: false,
    statusID: false,
    ownerID: false,
    ownerName: false,
    fileID: false,
    fileName: false,
    placeholders: false,
    isElectronicSigningEnabled: false,
    electronicSigningMessage: false,
    electronicSigningSubject: false,
    isElectronicSigningOrderedSigners: false,
    signatoryType1: false,
    signatoryUserID1: false,
    signatoryType2: false,
    signatoryUserID2: false,
    signatoryType3: false,
    signatoryUserID3: false,
    signatoryType4: false,
    signatoryUserID4: false,
    signatoryType5: false,
    signatoryUserID5: false,
    signatoryType6: false,
    signatoryUserID6: false,
    signatoryType7: false,
    signatoryUserID7: false,
    signatoryType8: false,
    signatoryUserID8: false,
    signatoryType9: false,
    signatoryUserID9: false,
    signatoryType10: false,
    signatoryUserID10: false,
    createdDate: false,
    createdByID: false,
    createdByName: false,
    isDeleted: false,
    deletedByID: false,
    deletedDate: false,
    generatedFileName: false
}