import Alert from "components/Alert";
import Snackbar from "@mui/material/Snackbar";
import React, { useEffect, useMemo, useState } from "react";
import PageContentLayout from "layouts/PageContentLayout";
import PageLayout from "layouts/PageLayout";
import { useSearchParams } from "react-router-dom";
import EditRecordScreenLayout from "components/ScreenLayouts/Placements/EditRecordScreenLayout";
import { GetCustomerSettingBySettingName, GetPlacementsSettings, GetPlacementsSources } from "services/ConfigurationService";

export default function PlacementCreatePage() {
    const [summaryBar, setSummaryBar] = useState(<></>);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [netRevenueLabel, setNetRevenueLabel] = useState('Net Revenue');
    const [defaultPayFreq, setDefaultPayFreq] = useState(0);
    const [defaultPaymentType, setDefaultPaymentType] = useState(0);
    const [defaultPayBillUnits, setDefaultPayBillUnits] = useState(0);
    const [defaultNoticePeriodUnits, setDefaultNoticePeriodUnits] = useState(0);
    const [isOptionalEndDate, setIsOptionalEndDate] = useState(false);
    const [isPredefinedSources, setIsPredefinedSources] = useState(false);
    const [isSeperatePayBillDivision, setIsSeperatePayBillDivision] = useState(false);
    const [isPlacementCandidateConfirmation, setIsPlacementCandidateConfirmation] = useState(false);
    const [defaultWarrantyPeriod, setDefaultWarrantyPeriod] = useState(0);
    const [ratesCalculatorLink, setRatesCalculatorLink] = useState('');
    const [onCostsPercentage, setOnCostsPercentage] = useState(0);
    const [sources, setSources] = useState<string[]>();
    const [defaultSummary, setDefaultSummary] = useState('');
    const [searchParams] = useSearchParams();
    
    const jobId = useMemo(() => {
        const cId = searchParams.get('jobId');
        if (cId && !isNaN(+cId)) return +cId;
        return undefined;
    }, [searchParams]);
    
    const typeId = useMemo(() => {
        const tId = searchParams.get('typeId');
        if (tId && !isNaN(+tId)) return +tId;
        return undefined;
    }, [searchParams]);
    
    const candidateId = useMemo(() => {
        const cId = searchParams.get('candidateId');
        if (cId && !isNaN(+cId)) return +cId;
        return undefined;
    }, [searchParams]);

    const copyPlacementId = useMemo(() => {
        const cId = searchParams.get('copyPlacementId');
        if (cId && !isNaN(+cId)) return +cId;
        return undefined;
    }, [searchParams]);

    const candidateIds = useMemo(() => {
        const cId = searchParams.get('candidateIds');
        if (cId) {
            const stringIds = cId.split(';');
            return stringIds.map(id => +id);
        }
        return undefined;
    }, [searchParams]);

    useEffect(() => {
        const getNetRevenueLabel = async () => {
            const settings = await GetPlacementsSettings();
            if (settings && settings.length > 0) {
                for (let i = 0; i < settings.length; i++) {
                    const s = settings[i];
                    if (s.type === 'DefaultPayFrequency' && s.value) setDefaultPayFreq(+s.value);
                    else if (s.type === 'PlacementDefaultPaymentType' && s.value) setDefaultPaymentType(+s.value);
                    else if (s.type === 'PlacementDefaultPayBillUnits' && s.value) setDefaultPayBillUnits(+s.value);
                    else if (s.type === 'PlacementDefaultNoticePeriodUnits' && s.value) setDefaultNoticePeriodUnits(+s.value);
                    else if (s.type === 'PlacementOptionalEndDate' && s.value) setIsOptionalEndDate(s.value.toLowerCase() === 'true');
                    else if (s.type === 'PlacementRatesCalculator' && s.value) setRatesCalculatorLink(s.value);
                    else if (s.type === 'PlacementsPredefinedSources' && s.value) setIsPredefinedSources(s.value.toLowerCase() === 'true');
                    else if (s.type === 'PlacementOnCostsPercentage' && s.value) setOnCostsPercentage(+s.value);
                    else if (s.type === 'DefaultWarrantyPeriod' && s.value) setDefaultWarrantyPeriod(+s.value);
                    else if (s.type === 'PlacementsSeperatePayBillDivision' && s.value) setIsSeperatePayBillDivision(s.value.toLowerCase() === 'true');
                    else if (s.type === 'PlacementCandidateConfirmation' && s.value) setIsPlacementCandidateConfirmation(s.value.toLowerCase() === 'true');
                    else if (s.type === 'PlacementDefaultSummary' && s.value) setDefaultSummary(s.value);
                }
            }
            const res = await GetCustomerSettingBySettingName('NetRevenueName');
            if (res) setNetRevenueLabel(res);
        };
        getNetRevenueLabel();
    }, []);

    const ids = useMemo(() => {
        if (candidateIds) return candidateIds;
        if (candidateId) return [candidateId];
        return undefined;
    }, [candidateId, candidateIds]);

    useEffect(() => {
        const getSources = async () => {
            const res = await GetPlacementsSources();
            if (res) setSources(res.map(s => s.name));
        };
        isPredefinedSources && ids && ids.length === 1 && getSources();
    }, [isPredefinedSources, ids]);

    return (
        <PageLayout SummaryBar={summaryBar}>
            <Snackbar open={errorMessage !== ''} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <Snackbar open={successMessage !== ''} autoHideDuration={3000} onClose={() => setSuccessMessage('')} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert color="success" onClose={() => setSuccessMessage('')}>{ successMessage }</Alert>
            </Snackbar>
            <PageContentLayout showLoading={isLoading}>
                <EditRecordScreenLayout
                    copyPlacementId={copyPlacementId}
                    jobId={jobId}
                    candidateIds={ids}
                    placementTypeId={typeId}
                    netRevenueLabel={netRevenueLabel}
                    defaultPayFreq={defaultPayFreq}
                    defaultPaymentType={defaultPaymentType}
                    defaultPayBillUnits={defaultPayBillUnits}
                    defaultNoticePeriodUnits={defaultNoticePeriodUnits}
                    defaultWarrantyPeriod={defaultWarrantyPeriod}
                    isOptionalEndDate={isOptionalEndDate}
                    isSeperatePayBillDivision={isSeperatePayBillDivision}
                    isPlacementCandidateConfirmation={isPlacementCandidateConfirmation}
                    ratesCalculatorLink={ratesCalculatorLink}
                    onCostsPercentage={onCostsPercentage}
                    predefinedSources={sources}
                    defaultSummary={defaultSummary}
                    loadingHandler={setIsLoading}
                    setSummaryBar={setSummaryBar}
                    errorHandler={setErrorMessage}
                    successHandler={setSuccessMessage}
                />
            </PageContentLayout>
        </PageLayout>
    );
}