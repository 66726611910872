import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import RWTextFieldComponent from "components/RWTextFieldComponent";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { UpdateComplianceItem } from "services/ComplianceService";

interface Props {
    open: boolean,
    itemId: number,
    name: string,
    status: number,
    scope: number,
    type: number,
    closeHandler: () => void,
    loadingHandler?: (isLoading: boolean) => void,
    successHandler?: (message: string, itemId: number, name: string, status: number, scope: number, type: number) => void,
    errorHandler?: (message: string) => void,
}

export default function EditComplianceItemDialog({ open, itemId, name: nameProp, status: statusProp, scope: scopeProp, type: typeProp, closeHandler, loadingHandler, successHandler, errorHandler }: Props) {
    const [name, setName] = useState('');
    const [status, setStatus] = useState(1);
    const [scope, setScope] = useState(1);
    const [type, setType] = useState(1);

    useEffect(() => {
        if (open) {
            setStatus(statusProp);
            setName(nameProp);
            setScope(scopeProp);
            setType(typeProp);
        }
    }, [open, statusProp, nameProp, scopeProp, typeProp]);

    const submitCallback = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        const res = await UpdateComplianceItem(itemId, { name, statusID: status, scopeID: scope, typeID: type }, errorHandler);
        if (res) {
            successHandler && successHandler('Item Updated', itemId, name, status, scope, type);
            closeHandler();
        }
        loadingHandler && loadingHandler(false);
    }, [itemId, name, status, scope, type, closeHandler, loadingHandler, successHandler, errorHandler]);

    const hasChanges = useMemo(() => {
        return name !== nameProp || status !== statusProp || scope !== scopeProp || type !== typeProp;
    }, [name, nameProp, status, statusProp, scope, scopeProp, type, typeProp]);

    return (
        <Dialog open={open} fullWidth>
            <DialogTitle>Edit Item</DialogTitle>
            <DialogContent>
                <Stack spacing={2} mt="10px">
                    <RWTextFieldComponent label="Name" value={name} onChange={(e) => setName(e.target.value)} />
                    <TextField select label="Status" value={status.toString()} onChange={({target}) => setStatus(+target.value)}>
                        <MenuItem value="0">Inactive</MenuItem>
                        <MenuItem value="1">Active</MenuItem>
                    </TextField>
                    <TextField select label="Scope" value={scope.toString()} onChange={({target}) => setScope(+target.value)}>
                        <MenuItem value="1">Candidate</MenuItem>
                        <MenuItem value="2">Placement</MenuItem>
                    </TextField>
                    <TextField select label="Type" value={type.toString()} onChange={({target}) => setType(+target.value)}>
                        <MenuItem value="1">General</MenuItem>
                        <MenuItem value="2">Baseline Required</MenuItem>
                        <MenuItem value="3">State Specific</MenuItem>
                    </TextField>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="error" onClick={closeHandler}>Cancel</Button>
                <Button variant="contained" onClick={submitCallback} color="success" disabled={!hasChanges}>Submit</Button>
            </DialogActions>
        </Dialog>
    );
}