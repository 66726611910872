import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React, { useMemo } from "react";
import { IsValidNumericValue } from "util/RegExUtils";

interface Props {
    perm: number,
    contract: number,
    ftc: number,
    showValidation: boolean
    onNumberChangeHandler?: (e: React.ChangeEvent<HTMLInputElement>) => void,
}

const DaysAdornment = <InputAdornment position="end">Day(s)</InputAdornment>;

export default function EditableClientDeadlinesElement({ perm, contract, ftc, showValidation, onNumberChangeHandler }: Props) {
    const isPermError = useMemo(() => !IsValidNumericValue(perm.toString()), [perm]);
    const isContractError = useMemo(() => !IsValidNumericValue(contract.toString()), [contract]);
    const isFtcError = useMemo(() => !IsValidNumericValue(ftc.toString()), [ftc]);

    return (
        <Box height="100%" display="flex" flexDirection="column">
            <Typography variant="h6" component="div" height="40px">Submission Deadlines</Typography>
            <Box display="flex" height="40px" px="5px">
                <Typography width="30%" variant="body1" fontWeight={600} component="div" sx={{ userSelect: 'text' }}>Perm</Typography>
                <Box width="70%">
                    <TextField
                        fullWidth
                        variant="standard"
                        value={perm.toString()}
                        onChange={onNumberChangeHandler}
                        name="cvDeadlinePerm"
                        InputProps={{ endAdornment: DaysAdornment }}
                        error={showValidation && isPermError}
                    />
                </Box>
            </Box>
            <Box display="flex" height="40px" px="5px">
                <Typography width="30%" variant="body1" fontWeight={600} component="div" sx={{ userSelect: 'text' }}>Contract</Typography>
                <Box width="70%">
                    <TextField
                        fullWidth
                        variant="standard"
                        value={contract.toString()}
                        onChange={onNumberChangeHandler}
                        name="cvDeadlineContract"
                        InputProps={{ endAdornment: DaysAdornment }}
                        error={showValidation && isContractError}
                    />
                </Box>
            </Box>
            <Box display="flex" height="40px" px="5px">
                <Typography width="30%" variant="body1" fontWeight={600} component="div" sx={{ userSelect: 'text' }}>FTC</Typography>
                <Box width="70%">
                    <TextField
                        fullWidth
                        variant="standard"
                        value={ftc.toString()}
                        onChange={onNumberChangeHandler}
                        name="cvDeadlineFixedContract"
                        InputProps={{ endAdornment: DaysAdornment }}
                        error={showValidation && isFtcError}
                    />
                </Box>
            </Box>
        </Box>
    );
}