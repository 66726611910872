import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { SeekBranding } from "common/models/JobPosting/Seek";
import React, { useEffect, useState } from "react";
import { GetSeekBrandings } from "services/SeekService";
import SeekBrandingCard from "./SeekBrandingCard";

interface Props {
    hirerId: string,
    value: string | null,
    onSelectCallback: (b: SeekBranding | null) => void,
    isError?: boolean,
    label?: string,
}

const helpText = 'Select a brand to feature on your ad';

export default function SeekBrandingPicker({ value, hirerId, onSelectCallback, label = 'Branding', isError = false }: Props) {
    const [selectedBranding, setSelectedBranding] = useState<SeekBranding | null>(null);
    const [options, setOptions] = useState<SeekBranding[]>([]);

    useEffect(() => {
        const getBranding = async () => {
            const res = await GetSeekBrandings(hirerId);
            if (res) setOptions(res.map(r => r.node));
        };
        Boolean(hirerId) && getBranding();
    }, [hirerId]);

    useEffect(() => {
        if (value) {
            if (!selectedBranding || (selectedBranding && selectedBranding.id.value !== value)) {
                const item = options.find(o => o.id.value === value);
                if (item) setSelectedBranding(item);
            }
        }
        else setSelectedBranding(null);
    }, [options, selectedBranding, value]);

    return (
        <Box display="flex" flexDirection="column" gap={2}>
            <Autocomplete
                size="small"
                fullWidth
                options={options}
                value={selectedBranding}
                getOptionLabel={o => o.name}
                isOptionEqualToValue={ (o,v) => o.id.value === v.id.value }
                onChange={ (e, v) => onSelectCallback(v) }
                renderInput={ (params) => (
                    <TextField {...params} label={label} helperText={helpText} error={isError} inputProps={{ ...params.inputProps, "data-lpignore": "true" }} />
                )}
            />
            { selectedBranding !== null &&
                <Box display="flex" flexWrap="wrap" justifyContent="center" minHeight="186px"> 
                    <SeekBrandingCard isSelected brandingData={selectedBranding} />
                </Box>
            }
        </Box>
    );

}