import Alert from "components/Alert";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Snackbar from "@mui/material/Snackbar";
import React, { useCallback, useEffect, useState } from "react";
import { TeamMember, User } from "common/models/Configuration/User";
import { StringValueWrapper } from "common/models/GenericTypes";
import { ChangeTracker } from "common/models/hooks/ChangeTracker";
import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import UserTeamRelationTransferList from "components/Teams/UserTeamRelationTransferList";
import useObjectStateWithChangeTracker from "hooks/UseObjectStateWithChangeTracker";
import useUnsavedChangesDialog from "hooks/UseUnsavedChangesDialog";
import PageContentLayout from "layouts/PageContentLayout";
import { GetActiveUsers, GetAllUsers, GetMyTeamMembers, UpdateMyTeamMembers } from "services/UsersService";

interface Props {
    setSummaryBar?: (summaryBar: JSX.Element) => void
}

const defaultString: StringValueWrapper = { value: '' };
const noChangesString: ChangeTracker<StringValueWrapper> = { value: false };

export default function AccountTeamPage({ setSummaryBar }: Props) {
    const [isLoading, setIsLoading] = useState(false);
    const [isFetchingUsers, setIsFetchingUsers] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [isShowInactive, setIsShowInactive] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [users, setUsers] = useState<User[]>([]);
    const [members, setMembers] = useState<TeamMember[]>([]);
    const { state, init, change, updateInitial, hasChanges } = useObjectStateWithChangeTracker<StringValueWrapper>(defaultString, noChangesString);

    const saveHandler = useCallback(async () => {
        setIsLoading(true);
        const memberIds = JSON.parse(state.value) as number[];
        const res = await UpdateMyTeamMembers(memberIds, setErrorMessage);
        if (!res) {
            setIsLoading(false);
            return false;
        }
        setShowSuccess(true);
        updateInitial();
        setIsLoading(false);
        return true;
    }, [state.value, updateInitial]);

    useEffect(() => {
        const showInactive = (
            <FormControlLabel
                sx={{ m: 0, mr:'20px' }}
                label="Include Inactive Users"
                labelPlacement="start"
                control={
                    <Checkbox
                        checked={ isShowInactive }
                        onChange={ () => setIsShowInactive(prev => !prev) }
                        sx={{ p: '4px' }}
                    />
                }
            />
        );
        const saveButton = (
            <Button
                variant="contained"
                color="success"
                disabled={!hasChanges}
                onClick={saveHandler}
            >Save</Button>
        );
        const summaryBar = (
            <TitleAndActionSummaryBar
                title="Account > Team"
                browserTabTitle="Team > Account"
                action={<div>{showInactive}{saveButton}</div>}
            />
        );
        setSummaryBar && setSummaryBar(summaryBar);
    }, [isShowInactive, hasChanges, saveHandler, setSummaryBar]);

    useEffect(() => {
        const getData = async () => {
            setIsLoading(true);
            const data = await GetMyTeamMembers();
            if (data) {
                let ids: number[] = [];
                for (let i = 0; i < data.length; i++) {
                    const m = data[i];
                    ids.push(m.id);
                }
                ids.sort();
                setMembers(data);
                init({ value: JSON.stringify(ids) })
            }
            setIsLoading(false);
        };
        getData();
    }, [init]);

    useEffect(() => {
        const getUsers = async () => {
            setIsFetchingUsers(true);
            if (isShowInactive) {
                const all = await GetAllUsers(setErrorMessage);
                if (all) setUsers(all);
            }
            else {
                const active = await GetActiveUsers(setErrorMessage);
                if (active) setUsers(active);
            }
            setIsFetchingUsers(false);
        };

        getUsers();
    }, [isShowInactive]);

    const memberMoveLeftCallback = useCallback((ids: number[]) => {
        setMembers(prev => {
            let newValues = [...prev];
            for (let i = 0; i < ids.length; i++) {
                const id = ids[i];
                const index = newValues.findIndex(v => v.id === id);
                if(index !== -1) newValues.splice(index, 1);
            }
            return newValues;
        });
    }, []);

    const memberMoveRightCallback = useCallback((ids: number[]) => {
        setMembers(prev => {
            let newValues = [...prev];
            for (let i = 0; i < ids.length; i++) {
                const id = ids[i];
                const index = users.findIndex(u => u.id === id);
                if(index !== -1) {
                    const u = users[index];
                    newValues.push({
                        id: u.id,
                        enabled: u.isEnabled,
                        fullName: u.displayName,
                        dtDeleted: '',
                        dtDisabled: '',
                        firstName: '',
                        fullNameWithDisabled : '',
                        lastName: '',
                        userName: ''
                    });
                }
            }
            return newValues;
        });
    }, [users]);

    useEffect(() => {
        let ids: number[] = [];
        for (let i = 0; i < members.length; i++) {
            const m = members[i];
            ids.push(m.id);
        }
        ids.sort();
        change('value', JSON.stringify(ids));
    }, [members, change]);

    const { unsavedChangesDialog } = useUnsavedChangesDialog(hasChanges, saveHandler);

    return (
        <>
            {unsavedChangesDialog}
            <Snackbar open={showSuccess} autoHideDuration={3000} onClose={() => setShowSuccess(false)}>
                <Alert onClose={() => setShowSuccess(false)}>Changes Saved</Alert>
            </Snackbar>
            <Snackbar open={errorMessage !== ''}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <PageContentLayout showLoading={isLoading || isFetchingUsers}>
                <UserTeamRelationTransferList
                    users={users}
                    participants={members}
                    titleLeft="Not In Team"
                    titleRight="Team Members"
                    ignoreLoggedUser
                    moveLeftHandler={ memberMoveLeftCallback }
                    moveRightHandler={ memberMoveRightCallback }
                />
            </PageContentLayout>
        </>
    );
}