import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { StringValueWrapper } from "common/models/GenericTypes";
import { ChangeTracker } from "common/models/hooks/ChangeTracker";
import { KpiData, KpiWeek } from "common/models/Kpi";
import useObjectStateWithChangeTracker from "hooks/UseObjectStateWithChangeTracker";
import { GetKpiData, GetKpiWeeks, GetKpiYears, UpdateKpiData } from "services/UsersService";
import UnsavedChangesDialog from "../UnsavedChangesDialog";

interface Props {
    open: boolean,
    userId: number,
    userName: string,
    loadingHandler: (isLoading: boolean) => void,
    closeHandler: () => void,
    successHandler: () => void,
    errorMessageHandler: (message: string) => void
}

const defaultStringValue: StringValueWrapper = { value: '' };
const noChangesStringValue: ChangeTracker<StringValueWrapper> = { value: false };

export default function EditKpisDialog({ open, userId, userName, loadingHandler, closeHandler, successHandler, errorMessageHandler }: Props) {
    const [isFetchingYears, setIsFetchingYears] = useState(false);
    const [isFetchingWeeks, setIsFetchingWeeks] = useState(false);
    const [isFetchingKpis, setIsFetchingKpis] = useState(false);
    const [showSaveDialog, setShowSaveDialog] = useState(false);
    const currentDate = useMemo(() => moment(), [])
    const [years, setYears] = useState<number[]>([]);
    const [weeks, setWeeks] = useState<KpiWeek[]>([]);
    const [kpiData, setKpiData] = useState<KpiData[]>([]);
    const [year, setYear] = useState(currentDate.year());
    const [week, setWeek] = useState(currentDate.week());
    const [nextYear, setNextYear] = useState<number | null>(null);
    const [nextWeek, setNextWeek] = useState<number | null>(null);
    const { init, change, updateInitial, hasChanges } = useObjectStateWithChangeTracker<StringValueWrapper>(defaultStringValue, noChangesStringValue);

    useEffect(() => {
        const getYears = async () => {
            setIsFetchingYears(true);
            const years = await GetKpiYears();
            if(years) setYears(years);
            setIsFetchingYears(false);
        };
        getYears();
    }, []);

    useEffect(() => {
        const getWeeks = async () => {
            setIsFetchingWeeks(true);
            const weeks = await GetKpiWeeks(year);
            if (weeks) setWeeks(weeks);
            setIsFetchingWeeks(false);
        };
        year !== 0 && getWeeks();
    }, [year]);

    useEffect(() => {
        const getData = async () => {
            setIsFetchingKpis(true);
            const data = await GetKpiData(userId, year, week);
            if (data) {
                let kpiValues: number[] = [];
                
                for (let i = 0; i < data.length; i++) {
                    const d = data[i];
                    kpiValues.push(d.kpi);
                }

                setKpiData(data);
                init({ value: JSON.stringify(kpiValues) });
            }
            setIsFetchingKpis(false);
        };

        year !== 0 && week !== 0 && getData();
    }, [userId, year, week, init]);

    useEffect(() => {
        let kpiValues: number[] = [];
        for (let i = 0; i < kpiData.length; i++) {
            const d = kpiData[i];
            kpiValues.push(d.kpi);
        }
        change('value', JSON.stringify(kpiValues));
    }, [kpiData, change]);

    useEffect(() => {
        loadingHandler(isFetchingKpis || isFetchingWeeks || isFetchingYears);
    }, [isFetchingKpis, isFetchingWeeks, isFetchingYears, loadingHandler]);

    const weekObj = useMemo(() => {
        if (week !== 0 && year !== 0 && weeks.length > 0) {
            const obj = weeks.find(w => w.week === week && w.year === year);
            if (obj) return obj;
        }
        return null;
    }, [year, week, weeks]);

    const handleKpiChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setKpiData(prev => {
            let newKpi = [...prev];
            newKpi[+name].kpi = +value;
            return newKpi;
        });
    }, []);

    const hasNegativeKpiValue = useMemo(() => {
        for (let i = 0; i < kpiData.length; i++) {
            const k = kpiData[i];
            if (k.kpi < 0) return true;
        }
        return false;
    }, [kpiData]);

    const saveChangesCallback = useCallback(async () => {
        if (hasNegativeKpiValue) {
            errorMessageHandler('KPI values must be equal or greater than 0');
            return false;
        }

        loadingHandler(true);
        const res = await UpdateKpiData(userId, year, week, kpiData, errorMessageHandler);
        if (!res) return false;
        loadingHandler(false);
        updateInitial();
        return true;
    }, [errorMessageHandler, kpiData, hasNegativeKpiValue, loadingHandler, updateInitial, userId, year, week]);

    const saveBtnClickHandler = useCallback(async () => {
        const res = await saveChangesCallback();
        if (res) successHandler();
    }, [saveChangesCallback, successHandler]);

    const discardChangesAndContinueHandler = useCallback(() => {
        if (nextYear) {
            setYear(nextYear);
            setNextYear(null);
        }
        if (nextWeek) {
            setWeek(nextWeek);
            setNextWeek(null);
        }
        setShowSaveDialog(false);
    }, [nextYear, nextWeek]);

    const saveChangesFromDialogHandler = useCallback( async () => {
        const res = await saveChangesCallback();
        if (res) discardChangesAndContinueHandler();
    }, [saveChangesCallback, discardChangesAndContinueHandler]);

    const cancelDialogHandler = useCallback(() => {
        setShowSaveDialog(false);
        setNextYear(null);
        setNextWeek(null);
    }, []);

    const yearChangedHandler = useCallback( async (year: number) => {        
        if (hasChanges) {
            setShowSaveDialog(true);
            setNextYear(year);
            return;
        }
        setYear(year);
    }, [hasChanges]);    
    
    const weekChangedHandler = useCallback( async (week: number) => {        
        if (hasChanges) {
            setShowSaveDialog(true);
            setNextWeek(week);
            return;
        }
        setWeek(week);
    }, [hasChanges]);

    return (
        <>
            <UnsavedChangesDialog
                open={showSaveDialog}
                cancelHandler={ cancelDialogHandler }
                discardChangesHandler={ discardChangesAndContinueHandler } 
                saveChangesHandler={ saveChangesFromDialogHandler }
                message="If you continue changes will be lost. Would you like to save changes before proceeding?"
            />
            <Dialog open={open} fullWidth maxWidth="md">
                <DialogTitle>KPIs By Week - {userName}</DialogTitle>
                <DialogContent dividers>
                    <Stack spacing={2} direction="row">
                        <Autocomplete
                            size="small"
                            value={year === 0 ? null : year}
                            options={years}
                            fullWidth
                            getOptionLabel={ o => o.toString() }
                            onChange={ (e,v) => yearChangedHandler(v === null ? 0 : v) }
                            renderInput={ params => <TextField {...params} label="Year" inputProps={{ ...params.inputProps, "data-lpignore": "true" }} /> }
                        />
                        <Autocomplete
                            size="small"
                            options={weeks}
                            value={weekObj}
                            fullWidth
                            getOptionLabel={o => `Week ${o.week} | ${moment(o.startDate).format('DD MMM YYYY')} - ${moment(o.endDate).format('DD MMM YYYY')}`}
                            onChange={ (e,v) => weekChangedHandler(v === null ? 0 : v.week) }
                            renderInput={ params => <TextField {...params} label="Week" inputProps={{ ...params.inputProps, "data-lpignore": "true" }} /> }
                        />
                    </Stack>
                    <Stack spacing={2}>
                        <Divider sx={{ my: 2 }} />
                        {kpiData.map((k, i) => (
                            <TextField
                                type="number"
                                key={k.typeID}
                                label={k.name}
                                value={k.kpi.toString()}
                                name={i.toString()}
                                disabled={week === 0 || year === 0}
                                onChange={handleKpiChange}
                                error={k.kpi < 0}
                            />
                        ))}
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="error" onClick={closeHandler}>Cancel</Button>
                    <Button variant="contained" color="success" onClick={saveBtnClickHandler} disabled={!hasChanges || week === 0 || year === 0}>Save</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}