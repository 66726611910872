import { DivisionConfigOptions } from "common/models/Configuration/Division";
import { ChangeTracker } from '../../../common/models/hooks/ChangeTracker';

export const DefaultOptions: DivisionConfigOptions = {
    AutomatedRejectionsAdministrative: '0',
    AutomatedRejectionsBudgetNotApproved: '0',
    AutomatedRejectionsClientDidNotWinWork: '0',
    AutomatedRejectionsFilledByClient: '0',
    AutomatedRejectionsFilledByCompetitor: '0',
    AutomatedRejectionsFilledByUs: '0',
    AutomatedRejectionsNotSupplied: '0',
    AutomatedRejectionsUnknown: '0',
    AutomatedRejectionsWithdrawn: '0',
}

export const NoChangesTracker: ChangeTracker<DivisionConfigOptions> = {
    AutomatedRejectionsAdministrative: false,
    AutomatedRejectionsBudgetNotApproved: false,
    AutomatedRejectionsClientDidNotWinWork: false,
    AutomatedRejectionsFilledByClient: false,
    AutomatedRejectionsFilledByCompetitor: false,
    AutomatedRejectionsFilledByUs: false,
    AutomatedRejectionsNotSupplied: false,
    AutomatedRejectionsUnknown: false,
    AutomatedRejectionsWithdrawn: false,
}