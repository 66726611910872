import { ChangeTracker } from "common/models/hooks/ChangeTracker";
import { MessageTemplate } from "common/models/Templates/MessageTemplate";

export const DefaultMessageTemplateData: MessageTemplate = {
    body: '',
    candidate: false,
    contact: true,
    createdDate: '2020-01-01',
    deleted: false,
    id: 0,
    name: '',
    recruiterID: 0,
    subject: '',
    type: 1,
    typeName: '',
    userFullName: ''
};

export const NoChangesDefaultMessageTemplate: ChangeTracker<MessageTemplate> = {
    body: false,
    candidate: false,
    contact: false,
    createdDate: false,
    deleted: false,
    id: false,
    name: false,
    recruiterID: false,
    subject: false,
    type: false,
    typeName: false,
    userFullName: false,
};
