import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import React, { useCallback, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import {DownloadResumes} from "services/CandidatesService";
import MenuItem from "@mui/material/MenuItem";

interface Props {
    open: boolean,
    jobId: number,
    candidateIds: number[],
    closeHandler: () => void,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
    successHandler?: (message: string) => void
}

export default function DownloadResumesDialog({ open, jobId, candidateIds, closeHandler, loadingHandler, errorHandler, successHandler }: Props) {
    const [typeId, setTypeId] = useState(0);

    useEffect(() => {
        if (!open) {
            setTypeId(0);
        }
    }, [open]);

    const linkToJobCallback = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        let res: boolean | null = null;
        res = await DownloadResumes(candidateIds, jobId, typeId, errorHandler);
        if (res) {
            closeHandler();
            successHandler && successHandler('Download Resume Request Logged');
        }
        loadingHandler && loadingHandler(false);
    }, [candidateIds, jobId, typeId, loadingHandler, successHandler, errorHandler, closeHandler]);
    
    return (
        <Dialog open={open} fullWidth>
            <DialogTitle>Download Resumes</DialogTitle>
            <DialogContent>
                <Stack spacing={2} mt="10px">
                    <TextField
                        select
                        label="File Name Format"
                        value={typeId}
                        name="TypeId"
                        onChange={ ({target}) => setTypeId(+target.value) }
                    >
                        <MenuItem value="0">Last Name, First Name</MenuItem>
                        <MenuItem value="1">First Name, LastName</MenuItem>
                    </TextField>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="error" onClick={ closeHandler }>Cancel</Button>
                <Button variant="contained" color="success" onClick={ linkToJobCallback }>Continue</Button>
            </DialogActions>
        </Dialog>
    );
}