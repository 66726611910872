import React, { useState } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import KeyboardControlKeyIcon from '@mui/icons-material/KeyboardControlKey';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DeleteIcon from '@mui/icons-material/Delete';

import { Collapse } from "@mui/material";
import { ScreeningQuestionsTemplateField } from "common/models/Templates/ScreeningQuestionsTemplate";
import RWTextFieldComponent from "components/RWTextFieldComponent";

interface Props {
    index: number,
    isLast: boolean,
    field: ScreeningQuestionsTemplateField,
    onChange: (index: number, field: ScreeningQuestionsTemplateField) => void,
    moveUp: (index: number) => void,
    moveDown: (index: number, isLast: boolean) => void,
    onDelete: (index: number) => void
}

export default function ScreeningQuestionsField({ index, isLast, field, onChange, moveUp, moveDown, onDelete }: Props) {
    const [isCollapsed, setIsCollapsed] = useState(false);

    const stringChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        onChange(index, { ...field, [name]: value });
    };

    // const numberChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     const { name, value } = e.target;
    //     onChange(index, { ...field, [name]: +value });
    // };

    const booleanChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        onChange(index, {...field, [name]: value === '1'})
    }

    return (
        <Paper variant="outlined" sx={{ p: 2 }}>
            <Box display="flex" alignItems="center">
                <Typography component="span" paddingRight="10px"># {index + 1}</Typography>
                <IconButton size="small" disabled={index === 0} onClick={() => moveUp(index)}>
                    <ArrowUpwardIcon />
                </IconButton>
                <IconButton size="small" disabled={isLast} onClick={() => moveDown(index, isLast)}>
                    <ArrowDownwardIcon />
                </IconButton>
                <IconButton size="small" onClick={() => setIsCollapsed(prev => !prev)}>
                    { isCollapsed ?  <KeyboardArrowDownIcon /> : <KeyboardControlKeyIcon /> }
                </IconButton>

                {isCollapsed && <Typography component="span" px="10px">{field.name}</Typography> }


                <IconButton size="small" onClick={() => onDelete(index)} color="error" sx={{ marginLeft: 'auto' }}>
                    <DeleteIcon />
                </IconButton>
            </Box>
            <Collapse in={!isCollapsed}>
                <Stack spacing={2} paddingTop={2}>
                    <RWTextFieldComponent
                        label="Question"
                        name="name"
                        value={field.name}
                        onChange={stringChangeHandler}
                    />
                    <TextField
                        select
                        label="Field Type"
                        name="type"
                        value={field.type}
                        onChange={stringChangeHandler}
                    >
                        <MenuItem value="0">Text - Single line</MenuItem>
                        <MenuItem value="1">Text - Multi line</MenuItem>
                    </TextField>
                    <TextField
                        select
                        label="Mandatory"
                        name="mandatory"
                        value={field.mandatory ? '1' : '0'}
                        onChange={booleanChangeHandler}
                    >
                        <MenuItem value="1">Yes</MenuItem>
                        <MenuItem value="0">No</MenuItem>
                    </TextField>
                </Stack>
            </Collapse>
        </Paper>
    );
}