import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { Editor } from "@tinymce/tinymce-react/lib/cjs/main/ts/components/Editor";
import * as tinymce from 'tinymce';
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Contact } from "common/models/Contacts";
import { GetMyUser } from "services/UsersService";
import ClientPicker from "components/Pickers/ClientPicker";
import UserPicker from "components/Pickers/UserPicker";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { CreateContact, SetContactOwners } from "services/ContactsService";
import { RegexIsValidEmail } from "util/RegExUtils";
import RWTextFieldComponent from "components/RWTextFieldComponent";

interface Props {
    open: boolean,
    firstName?: string,
    clientId?: number,
    onContactCreated: (c: Contact) => void,
    closeHandler: () => void,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
    successHandler?: (message: string) => void
}

const emailValidation = (email: string) => {
    if (email === '' || email === null || email === undefined) return true;
    return RegexIsValidEmail(email);
};

export default function QuickCreateContactDialog({ open, firstName: firstNameProp = '', clientId: clientIdProp = 0, onContactCreated, closeHandler, loadingHandler, errorHandler, successHandler }: Props) {
    const [showValidation, setShowValidation] = useState(false);
    const [isComponentMounted, setIsComponentMounted] = useState(false);
    const editorRef = useRef<tinymce.Editor | null>(null);
    const [firstName, setFirstName] = useState('');
    const [surname, setSurname] = useState('');
    const [jobTitle, setJobTitle] = useState('');
    const [clientId, setClientId] = useState<number | null>(clientIdProp);
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [mobile, setMobile] = useState('');
    const [ownerId, setOwnerId] = useState<number | null>(null);
    const [defaultOwnerId, setDefaultOwnerId] = useState<number | null>(null);
    const [statusId, setStatusId] = useState(1);
    const theme = useTheme();
    const isDarkTheme = useMemo(() => theme.palette.mode === 'dark', [theme.palette.mode]);

    useEffect(() => setIsComponentMounted(true) , []);

    useEffect(() => {
        if (open && firstNameProp) {
            setFirstName(firstNameProp);
        }
    }, [open, firstNameProp]);

    useEffect(() => {
        const getUserId = async () => {
            const u = await GetMyUser();
            if (u) {
                setDefaultOwnerId(u.userID);
                setOwnerId(u.userID);
            }
        };

        getUserId();
    }, []);

    const isEmailValid = useMemo(() => emailValidation(email), [email]);

    const resetToDefaultValues = useCallback(() => {
        setShowValidation(false);
        setFirstName('');
        setSurname('');
        setJobTitle('');
        setClientId(null);
        setEmail('');
        setPhone('');
        setMobile('');
        setOwnerId(defaultOwnerId);
        setStatusId(1);
        if (editorRef.current) editorRef.current.setContent('');
    }, [defaultOwnerId]);

    const saveChangesHandler = useCallback(async () => {
        if (!firstName.trim()) {
            errorHandler && errorHandler("First name can't be empty");
            setShowValidation(true);
            return;
        }

        if (!isEmailValid) {
            errorHandler && errorHandler("Email not valid");
            setShowValidation(true);
            return;
        }
        
        loadingHandler && loadingHandler(true);
        const api = editorRef.current;
        if (api) {
            const summary = api.getContent();
            const c: Partial<Contact> = {
                firstName: firstName,
                surname: surname,
                jobTitle: jobTitle,
                clientID: clientId ?? undefined,
                email: email,
                phone: phone,
                mobile: mobile,
                statusID: statusId,
                description: summary
            };

            const res = await CreateContact(c, null, errorHandler);
            if (res && res.value) {
                const oId = ownerId ? ownerId : defaultOwnerId;
                await SetContactOwners(res.value, [oId ?? 0], errorHandler);
                const newContact = {...c, id: res.value} as Contact;
                successHandler && successHandler('Contact Created');
                onContactCreated(newContact);
                resetToDefaultValues();
                closeHandler();
            }
        }
        loadingHandler && loadingHandler(false);
    }, [firstName, isEmailValid, loadingHandler, errorHandler, surname, jobTitle, clientId, email, phone, mobile, statusId, ownerId, defaultOwnerId, successHandler, onContactCreated, resetToDefaultValues, closeHandler]);
    
    const handleClose = useCallback(() => {
        closeHandler();
        resetToDefaultValues();
    }, [closeHandler, resetToDefaultValues]);

    return (
        <Dialog open={open} fullWidth maxWidth="md">
            <DialogTitle>Quick Create Contact</DialogTitle>
            <DialogContent>
                <Box display="flex">
                    <Stack spacing={2} mt={1} flex="1 1 0" pr={2}>
                        <RWTextFieldComponent value={firstName} onChange={(e) => setFirstName(e.target.value)} label="First Name" isError={showValidation && !firstName.trim()} />
                        <RWTextFieldComponent value={surname} onChange={(e) => setSurname(e.target.value)} label="Surname" />
                        <RWTextFieldComponent value={jobTitle} onChange={(e) => setJobTitle(e.target.value)} label="Job Title" />
                        <ClientPicker
                            onSelectCallback={c => setClientId(c ? c.id : null)}
                            value={clientId}
                            errorHandler={errorHandler}
                            successHandler={successHandler}
                            loadingHandler={loadingHandler}
                        />
                        <RWTextFieldComponent value={email} onChange={(e) => setEmail(e.target.value)} label="Email" isError={showValidation && !isEmailValid} />
                        <RWTextFieldComponent value={phone} onChange={(e) => setPhone(e.target.value)} label="Phone" />
                        <RWTextFieldComponent value={mobile} onChange={(e) => setMobile(e.target.value)} label="Mobile" />
                        <UserPicker onSelect={u => setOwnerId(u ? u.id : null)} userId={ownerId} />
                        <TextField select value={statusId.toString()} onChange={({target}) => setStatusId(+target.value)} label="Status" >
                            <MenuItem value="1">Unqualified</MenuItem>
                            <MenuItem value="2">Qualified</MenuItem>
                            <MenuItem value="3">Client</MenuItem>
                            <MenuItem value="4">Supplier</MenuItem>
                            <MenuItem value="7">Supplier - Sub Contractor</MenuItem>
                            <MenuItem value="5">Billing</MenuItem>
                            <MenuItem value="6">Archived</MenuItem>
                            <MenuItem value="8">Referee</MenuItem>
                            <MenuItem value="9">Prospect</MenuItem>
                        </TextField>
                    </Stack>
                    <Box flex="2 1 0" display="flex" flexDirection="column">
                        <Typography variant="h6" component="div">Summary</Typography>
                        {isComponentMounted &&
                            <Editor
                                tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce-5.10.2/tinymce.min.js'}
                                onInit={ (evt, editor) => editorRef.current = editor }
                                init={{
                                    height: '100%',
                                    skin: isDarkTheme ? 'oxide-dark' : undefined,
                                    content_css: isDarkTheme ? 'dark' : undefined,
                                    branding: false,
                                    menubar: false,
                                    contextmenu: false,
                                    resize: false
                                }}
                            />
                        }
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="error" onClick={ handleClose }>Cancel</Button>
                <Button variant="contained" color="success" onClick={ saveChangesHandler }>Submit</Button>
            </DialogActions>
        </Dialog>
    );
}