import { Division } from "common/models/Configuration/Division";
import { ChangeTracker } from "common/models/hooks/ChangeTracker";

export const DefaultDivision: Division = {
    id: 0,
    active: true,
    automatedRejectionDelay: 0,
    country: 'AU',
    countryName: 'Australia',
    name: '',
    shortName: '',
    timeZone: '',
    timesheetSystem: 0
};

export const NoChangesDivision: ChangeTracker<Division> = {
    id: false,
    active: false,
    automatedRejectionDelay: false,
    country: false,
    countryName: false,
    name: false,
    shortName: false,
    timeZone: false,
    timesheetSystem: false
};