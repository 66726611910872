import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";

import PageLayout from "layouts/PageLayout";
import PageContentLayout from "layouts/PageContentLayout";
import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import { Candidate } from "common/models/Candidates/Candidate";
import { DeleteCandidateById, DownloadCandidateResume, ExportCandidateToDeputy, ExportCandidateToFoundU, GetCandidateById, GetCandidateJob, ToggleCandidateOptOut } from "services/CandidatesService";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { GetMySettings, GetSettingBySettingName, UpdateSettingBySettingName, } from "services/UsersService";
import { GetCustomerSettingBySettingName, GetInterviewWorkflowStatuses, GetOfferAcceptedWorkflowStatus, GetOfferedWorkflowStatus } from "services/ConfigurationService";
import Avatar from '@mui/material/Avatar';
import FacebookIcon from '../../assets/icons/facebook-icon.svg';
import TwitterIcon from '../../assets/icons/twitter-icon.svg';
import LinkedInIcon from '../../assets/icons/linkedin-icon.svg';
import GoogleIcon from '../../assets/icons/google-icon.svg';
import CvIcon from '../../assets/icons/cv.svg';
import CoverLetterIcon from '../../assets/icons/cover-letter.svg';
import BallotIcon from '@mui/icons-material/Ballot';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import IconButton from "@mui/material/IconButton";
import WorkIcon from '@mui/icons-material/Work';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

import ConfirmationDialog from "components/Dialogs/Generic/ConfirmationDialog";
import Snackbar from "@mui/material/Snackbar";
import Alert from "components/Alert";
import ActionMenu from "components/Menus/ActionMenu";
import moment from "moment";
import { GetActivitiesSettings } from "services/ConfigurationService";
import { ActivitySettings } from "common/models/Configuration/Activities";
import { MenuOptionDefinition } from "common/models/MenuDefinition";
import EditActivityDialog from "components/Dialogs/Activities/EditActivityDialog";
import { DefaultActivity } from "util/Definitions/Activities";
import PreviewLoaderComponent from "components/Previews/PreviewLoader";
import TagsManagementDialog from "components/Dialogs/TagsManagementDialog";
import ActivitiesGridComponent from "components/Grids/ActivitiesGrid";
import { GetPhoneLinkWithStandardized } from "util/CommonUtils";
import PlacementsGridComponent from "components/Grids/PlacementsGrid";
import ActivityMessagesGridComponent from "components/Grids/ActivityMessagesGrid";
import DocumentsGridComponent from "components/Grids/DocumentsGrid";
import ComplianceGridComponent from "components/Grids/ComplianceGrid";
import { AllowCandidatesAddEdit, AllowCandidatesDelete } from "common/data/Permissions/CandidatesAccess";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import SubmissionsGridComponent from "components/Grids/SubmissionsGrid";
import SendReferralDialog from "components/Dialogs/SendReferralDialog";
import { Permission } from "common/models/Permissions";
import { companyHasSinglePermission, userHasSinglePermission } from "util/PermissionsUtils";
import { AddRecentRecord } from "util/LocalStorageUtils";
import ViewRecordScreenLayout from "components/ScreenLayouts/Candidates/ViewRecordScreenLayout";
import UploadCvDialog from "components/Dialogs/Candidates/UploadCvDialog";
import SendEmailDialog from "components/Dialogs/Messages/SendEmailDialog";
import SendSmsDialog from "components/Dialogs/Messages/SendSmsDialog";
import { EmailRecipient } from "common/models/EmailRecipient";
import LinkCandidatesToJobDialog from "components/Dialogs/Candidates/LinkCandidatesToJobDialog";
import { Job } from "common/models/Jobs/Job";
import { GetJobById } from "services/JobsService";
import ListItemText from "@mui/material/ListItemText";
import { Activity } from "common/models/Activities";
import OfferMadeDialog from "components/Dialogs/Candidates/OfferMadeDialog";
import { GetAllIntegrations } from "services/CustomerIntegrationsService";
import SendCvForFormattingDialog from "components/Dialogs/SendCvForFormattingDialog";
import { JobTracking } from "common/models/Jobs/JobTracking";
import { DownloadApplicationDocument, GetApplicationDocsByApplicationId } from "services/AdvertsService";
import CandidateRatingIcon from "components/Candidates/CandidateRatingIcon";
import PlaceCandidatesDialog from "components/Dialogs/Candidates/PlaceCandidatesDialog";
import CandidateJobsGridComponent from "components/Grids/CandidateJobsGrid";
import GenerateDocumentDialog from "components/Dialogs/Documents/GenerateDocumentDialog";
import CandidateResumeRenderer from "components/Candidates/CandidateResumeRenderer";
import ReferooActionsDialog from "components/Dialogs/Candidates/ReferooActionsDialog";
import { CandidatePositionTracker } from 'common/models/CandidatePositionTracker'
import GenerateFormattedCvDialog from "components/Dialogs/Documents/GenerateFormattedCvDialog";
import WorkHistoryGridComponent from "components/Grids/WorkHistoryGrid";
import WorkHistoryTimelineComponent from "components/Dialogs/Candidates/WorkHistoryTimeline";
import Switch from "@mui/material/Switch";
import useWorkflowOrder from "hooks/UseWorkflowOrder";
import usePartnerActions from "hooks/UsePartnerActions";
import ShowIframeDialog from "components/Dialogs/Generic/ShowIFrameDialog";
import { PartnerActionType } from "common/models/PartnerActions";
import RedFlagCandidateDialog from "components/Dialogs/Candidates/RedFlagCandidateDialog";
import RemoveRedFlagDialog from "components/Dialogs/Candidates/RemoveRedFlagDialog"
import RedFlagWarningDialog from "components/Dialogs/Candidates/RedFlagWarningDialog"
import useRecordHotkeys from "hooks/UseRecordHotkeys";
import CallOutNotification, {CallOutNotificationType} from "../../components/CallOutNotification";
import DigitalReferenceCheckDialog from "components/Dialogs/Candidates/DigitalReferenceCheckDialog";
import EditWorkHistoryDialog from "components/Dialogs/Candidates/EditWorkHistoryDialog";
import SendOnboardingDialog from "components/Dialogs/Onboarding/SendOnboardingDialog";
import { GetActiveMeetingTypes } from "services/MeetingsService";
import { GetActiveInterviewTypes } from "services/InterviewsService";
import { MeetingType } from "common/models/Configuration/MeetingType";
import { InterviewType } from "common/models/Configuration/InterviewType";
import InterviewsGridComponent from "../../components/Grids/InterviewsGrid";
import MeetingsGridComponent from "../../components/Grids/MeetingsGrid";
import WorkProSendPackageDialog from "components/Dialogs/Candidates/WorkProSendPackageDialog";

const summaryLinkStyle: React.CSSProperties = { color: 'inherit', textDecoration: 'none' };

const renderSummary = (c: Candidate) => {
    const tooltipContent = (
        <table>
            <tbody>
                <tr>
                    <Typography variant="caption" fontWeight={600} component="td">ID</Typography>
                    <Typography variant="caption" pl="10px" component="td">{c.id}</Typography>
                </tr>
                <tr>
                    <Typography variant="caption" fontWeight={600} component="td" sx={{ verticalAlign: 'top' }}>Name</Typography>
                    <Typography variant="caption" pl="10px" component="td">{c.fullName}</Typography>
                </tr>
            </tbody>
        </table>
    );

    const contactName = (
        <Tooltip arrow title={tooltipContent}>
            <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{c.fullName}</span>
        </Tooltip>
    );
    
    let jobData: React.ReactNode | undefined = undefined;
    
    if(c.jobTitle === '')
        c.jobTitle = "Unknown";

    if(c.employer === '')
        c.employer = "Unknown";

    jobData = <>{c.jobTitle}{' @ '}{c.employer}</>

    let phone = '';
    let phoneStd = '';

    if (c.mobile) {
        phone = c.mobile;
        phoneStd = c.mobile_Standardised;
    }
    else if (c.work) {
        phone = c.work;
        phoneStd = c.work_Standardised;
    }
    else if (c.home) {
        phone = c.home;
        phoneStd = c.home_Standardised;
    }

    if (phone) {
        const phoneLink = GetPhoneLinkWithStandardized(phone, phoneStd, true);
        const primary = <>{contactName}<span style={{ paddingRight: '5px' }}>&nbsp;-</span>{phoneLink}</>;
        return (<ListItemText primary={primary} secondary={jobData} />);
    }
    const primary = <>{contactName}</>;
    return (<ListItemText primary={primary} secondary={jobData} />);
};

const googleMapsLink = (c: Candidate) => {
    const baseUrl = 'https://www.google.com/maps/search/?api=1';
    let address = '';
    if (c.address1) address = c.address1;
    if (c.address2) address += ' ' + c.address2;
    if (c.suburb) address += ' ' + c.suburb;
    if (c.state) address += ' ' + c.state;
    if (c.postcode) address += ' ' + c.postcode;
    if (c.countryName) address += ' ' + c.countryName;

    if (address !== '') {
        return `${baseUrl}&query=${encodeURIComponent(address)}`;
    }
    return null;
}

const facebookLink = (c: Candidate) => {
    if (c.facebookUrl) {
        const l = c.facebookUrl
        if (!l.includes('http://') && !l.includes('https://')) return `https://${l}`;
        return l;
    }
    return `https://www.facebook.com/search/top?q=${encodeURIComponent(c.fullName)}`;
}

const linkedInLink = (c: Candidate) => {
    if (c.linkedInUrl) {
        const l = c.linkedInUrl
        if (!l.includes('http://') && !l.includes('https://')) return `https://${l}`;
        return l;
    }
    return `https://www.linkedin.com/search/results/people/?keywords=${encodeURIComponent(c.fullName)}`;
}

const twitterLink = (c: Candidate) => {
    if (c.twitterUrl) {
        const l = c.twitterUrl;
        if (!l.includes('http://') && !l.includes('https://')) return `https://${l}`;
        return l;
    }
    return `https://twitter.com/search?f=users&vertical=default&q=${encodeURIComponent(c.fullName)}`;
}

const entityTypeId = 3;
const workHistoryViewSettingName = 'CandidateRecord_WorkHistoryView';

export default function CandidateRecord() {
    const [candidate, setCandidate] = useState<Candidate>();
    const [job, setJob] = useState<Job>();
    const [candidateJob, setCandidateJob] = useState<JobTracking>();
    const [hasApplicationCv, setHasApplicationCv] = useState(false);
    const [hasApplicationCoverLetter, setHasApplicationCoverLetter] = useState(false);
    const [hasApplicationQuestionnaire, setHasApplicationQuestionnaire] = useState(false);
    const [activitySettings, setActivitySettings] = useState<ActivitySettings[]>([]);
    const [interviewWorkflowStatuses, setInterviewWorkflowStatuses] = useState<number[]>([]); 
    const [offerWorkflowStatus, setOfferWorkflowStatus] = useState<number>(0); 
    const [offerAcceptedWorkflowStatus, setOfferAcceptedWorkflowStatus] = useState<number>(0); 
    const [selectedActivity, setSelectedActivity] = useState<ActivitySettings | null>(null);
    const [meetingTypes, setMeetingTypes] = useState<MeetingType[]>([]);
    const [interviewTypes, setInterviewTypes] = useState<InterviewType[]>([]);
    const [noteActivitySetting, setNoteActivitySetting] = useState<ActivitySettings | null>(null);
    const params = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [isFetchingDefaultTab, setIsFetchingDefaultTab] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [tmpErrorMessage, setTmpErrorMessage] = useState('');
    const [isRecordDeleted, setIsRecordDeleted] = useState(false);
    const [isLoadingTab, setIsLoadingTab] = useState(false);
    const [showTagsPrevew, setShowTagsPreview] = useState(false);
    const [showTagsPrevewNoDelay, setShowTagsPreviewNoDelay] = useState(false);
    const [showTagsManagement, setShowTagsManagement] = useState(false);
    const [showSendReferralDialog, setShowSendReferralDialog] = useState(false);
    const [searchParams] = useSearchParams();
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showAddWorkHistoryDialog, setShowAddWorkHistoryDialog] = useState(false);
    const [showExportToFoundUDialog, setShowExportToFoundUDialog] = useState(false);
    const [showExportToDeputyDialog, setShowExportToDeputyDialog] = useState(false);
    const [showSendWorkProPackageDialog, setShowSendWorkProPackageDialog] = useState(false);
    const [showUploadCvDialog, setShowUploadCvDialog] = useState(false);
    const [showRedFlagCandidateDialog, setShowRedFlagCandidateDialog] = useState(false);
    const [showRemoveRedFlagDialog, setShowRemoveRedFlagDialog] = useState(false);
    const [showRedFlagWarningDialog, setShowRedFlagWarningDialog] = useState(false);
    const [showConfirmUpdateOptOut, setShowConfirmUpdateOptOut] = useState(false);
    const [showLinkCandidateToJobDialog, setShowLinkCandidateToJobDialog] = useState(false);
    const [showOfferMadeDialog, setShowOfferMadeDialog] = useState(false);
    const [showSendCvForFormattingDialog, setShowSendCvForFormattingDialog] = useState(false);
    const [showPlaceCandidatesDialog, setShowPlaceCandidatesDialog] = useState(false);
    const [showGenerateDocumentsDialog, setShowGenerateDocumentsDialog] = useState(false);
    const [showGenerateFormattedCvDialog, setShowGenerateFormattedCvDialog] = useState(false);
    const [showReferooDialog, setShowReferooDialog] = useState(false);
    const [activeTab, setActiveTab] = useState('CANDIDATES_Home');
    const [workHistoryView, setWorkHistoryView] = useState<'list' | 'timeline'>('timeline');
    const [defaultTab, setDefaultTab] = useState<string>();
    const [tabSetupFinished, setTabSetupFinished] = useState(false);
    const [compliance, setCompliance] = useState(false);
    const [sendMessageDialogType, setSendMessageDialogType] = useState<'email' | 'sms' | null>(null);
    const [messageRecipients, setMessageRecipients] = useState<EmailRecipient[]>([]);
    const [onboardingRecipient, setOnboardingRecipient] = useState<EmailRecipient | null>(null);
    const [tabActionMenuOptions, setTabActionMenuOptions] = useState<MenuOptionDefinition[]>([]);
    const [isOutsourcingWizardConnected, setIsOutsourcingWizardConnected] = useState(false);
    const [isFoundUConnected, setIsFoundUConnected] = useState(false);
    const [isDeputyConnected, setIsDeputyConnected] = useState(false);
    const [isReferooConnected, setIsReferooConnected] = useState(false);
    const [isWorkProConnected, setIsWorkProConnected] = useState(false);
    const [isFinishedSetup, setIsFinishedSetup] = useState(false);
    const [isFinishedDataSetup, setIsFinishedDataSetup] = useState(false);
    const [refreshActivitiesGridControl, setRefreshActivitiesGridControl] = useState(false);
    const [dataId, setDataId] = useState("");
    const [prevCandidateId, setPrevCandidateId] = useState(0);
    const [nextCandidateId, setNextCandidateId] = useState(0);
    const workflowOrder = useWorkflowOrder();
    const [showPartnerActionPopup, setShowPartnerActionPopup] = useState(false);
    const [showWorkflowPartnerActionPopup, setShowWorkflowPartnerActionPopup] = useState(false);
    const [defaultRejectionTemplateId, setDefaultRejectionTemplateId] = useState(0);
    const [callToActionType, setCallToActionType] = useState<CallOutNotificationType>('info');
    const [callToActionTitle, setCallToActionTitle] = useState<string>('');
    const navigate = useNavigate();
    const [showDigitalReferenceCheckDialog, setShowDigitalReferenceCheckDialog] = useState(false);

    useEffect(() => {
        if (!showTagsPrevew) setShowTagsPreviewNoDelay(false);
    }, [showTagsPrevew]);

    const canAccessJobs = useMemo(() => companyHasSinglePermission(Permission.Jobs) && userHasSinglePermission(Permission.Jobs), []);
    const canAccessPlacements = useMemo(() => companyHasSinglePermission(Permission.Placements) && userHasSinglePermission(Permission.Placements), []);
    const canAccessActivities = useMemo(() => companyHasSinglePermission(Permission.Activities) && userHasSinglePermission(Permission.Activities), []);
    const canSendEmails = useMemo(() => userHasSinglePermission(Permission.MessageCentre) && companyHasSinglePermission(Permission.MessageCentre), []);
    const canSendSms = useMemo(() => userHasSinglePermission(Permission.MessageCentreSms) && companyHasSinglePermission(Permission.MessageCentreSms), []);
    const canDeleteCandidates = useMemo(() => AllowCandidatesDelete(), []);
    const canAddEditCandidates = useMemo(() => AllowCandidatesAddEdit(), []);
    const isDataMaintenanceAdministrator = useMemo(() => userHasSinglePermission(Permission.DataMaintenanceAdministrator), []);
    const canAddEditContacts = useMemo(() => userHasSinglePermission(Permission.ContactsAddEdit) && companyHasSinglePermission(Permission.ContactsAddEdit), []);
    const canGenerateDocuments = useMemo(() => companyHasSinglePermission(Permission.DocumentTemplates) && userHasSinglePermission(Permission.DocumentTemplates), []);
    const canSendOnboarding = useMemo(() => companyHasSinglePermission(Permission.Onboarding), []);

    const candidateId = useMemo(() => {
        const parsedId = +(params.id ?? '0');
        if (isNaN(parsedId)) return 0;
        return parsedId;
    }, [params.id]);

    const jobId = useMemo(() => {
        const parsedJobId = +(searchParams.get('jobId') ?? '0');
        if (isNaN(parsedJobId)) return 0;
        return parsedJobId;
    }, [searchParams]);

    const ratingId = useMemo(() => {
        const parsedRatingId = +(searchParams.get('ratingId') ?? '-1');
        if (isNaN(parsedRatingId)) return -1;
        return parsedRatingId;
    }, [searchParams]);

    const ssid = useMemo(() => {
        const parsedSsid = +(searchParams.get('ssid') ?? '0');
        if (isNaN(parsedSsid)) return 0;
        return parsedSsid;
    }, [searchParams]);

    const candidateIdArray = useMemo(() => [candidateId], [candidateId]);

    const partnerActionsData = useMemo(() => ({ candidateId, jobId }), [candidateId, jobId]);
    const { partnerActions, popupTitle, popupUrl } = usePartnerActions(PartnerActionType.Candidates, partnerActionsData, setShowPartnerActionPopup);
    const {
        partnerActions: workflowPartnerActions,
        interviewActions: interviewPartnerActions,
        refCheckActions: refCheckPartnerActions,
        popupTitle: popupTitle2,
        popupUrl: popupUrl2
    } = usePartnerActions(PartnerActionType.CandidatesWorkflow, partnerActionsData, setShowWorkflowPartnerActionPopup);

    const hotkeySettings = useMemo<Record<string, () => void>>(() => {
        return {
            n: () => noteActivitySetting && setSelectedActivity({...noteActivitySetting}),
            e: () => navigate(`/candidates/${candidateId}/edit`),
            t: () => setShowTagsManagement(true),
        };
    }, [candidateId, navigate, noteActivitySetting]);

    useRecordHotkeys(hotkeySettings, !canAddEditCandidates);

    useEffect(() => {
        const getData = async () => {
            setIsLoading(true);
            const res = await GetCandidateById(candidateId);
            if (res === null) navigate('/not-found');

            setCallToActionType('info');
            setCallToActionTitle('');

            if (res && res.profileStatusID === -1) {
                setCallToActionType('danger');
                setCallToActionTitle(`ARCHIVED - DO NOT CONTACT`);
            }
            else if (res && res.candidateClientID !== 0) {
                setCallToActionType('info');
                setCallToActionTitle(`EMPLOYEE at ${res.candidateClientName}`);
                //setCallToActionTitle(`EMPLOYEE at ${PREVIEW_CLIENT_RENDERER(res.candidateClientID, res.candidateClientName)}`);
            }
            else if (res && (res.candidatePlacementTypeID >= 1 && res.candidatePlacementTypeID <= 4)) {
                const placementType = res.candidatePlacementTypeID === 1 ? 'PERMANENT' : 'Current Contractor';

                setCallToActionType('warning');
                setCallToActionTitle(`${placementType} at ${res.candidatePlacementClientName}`);
                //setCallToActionTitle(`${placementType} at ${PREVIEW_CLIENT_RENDERER(res.candidatePlacementClientID, res.candidatePlacementClientName)}`);
            }
            
            const settings = await GetActivitiesSettings(3, undefined, setErrorMessage);
            if (res) {
                AddRecentRecord(3, candidateId, res.fullName);
                setCandidate(res);

                setDataId(searchParams.get('dataId') ?? '');

                const parsedPrevCandidateId = +(searchParams.get('prevCandidateId') ?? '0');
                setPrevCandidateId(parsedPrevCandidateId);
                const parsedNextCandidateId = +(searchParams.get('nextCandidateId') ?? '0');
                setNextCandidateId(parsedNextCandidateId);
                setShowRedFlagWarningDialog(res.isRedFlagged);
            }
            if (settings) setActivitySettings(settings);

            const interviewStutuses = await GetInterviewWorkflowStatuses();
            if(interviewStutuses) {
                setInterviewWorkflowStatuses(interviewStutuses);
            }

            const offeredStatsus = await GetOfferedWorkflowStatus();
            if(offeredStatsus) {
                setOfferWorkflowStatus(offeredStatsus);
            }

            const offerAcceptedStatus = await GetOfferAcceptedWorkflowStatus();
            if(offerAcceptedStatus) {
                setOfferAcceptedWorkflowStatus(offerAcceptedStatus);
            }

			
            if (jobId) {
                const j = await GetJobById(jobId);
                if (j) setJob(j);
                const cj = await GetCandidateJob(candidateId, jobId);
                if (cj) {
                    setCandidateJob(cj);
                    if (cj.applicationID) {
                        const docs = await GetApplicationDocsByApplicationId(cj.applicationID, setErrorMessage);
                        if (docs && docs.length > 0) {
                            let hasCv = false;
                            let hasCoverLetter = false;
                            let hasQuestionnaire = false;
                            for (let i = 0; i < docs.length; i++) {
                                const d = docs[i];
                                if (d.documentType === 1) hasCv = true;
                                else if (d.documentType === 2) hasCoverLetter = true;
                                else hasQuestionnaire = true;
                            }

                            if (hasCv) setHasApplicationCv(true);
                            if (hasCoverLetter) setHasApplicationCoverLetter(true);
                            if (hasQuestionnaire) setHasApplicationQuestionnaire(true);
                        }
                    }
                }
                else setCandidateJob(undefined);
            }
            
            const integrations = await GetAllIntegrations();
            if (integrations) {
                for (let i = 0; i < integrations.length; i++) {
                    const item = integrations[i];
                    if (item.statusID !== 1) continue;
                    else if (item.id === 13) setIsOutsourcingWizardConnected(true);
                    else if (item.id === 16) setIsFoundUConnected(true);
                    else if (item.id === 27) setIsDeputyConnected(true);
                    else if (item.id === 1) setIsReferooConnected(true);
                    else if (item.id === 29) setIsWorkProConnected(true);
                }
            }
            setIsFinishedDataSetup(true);
            setIsLoading(false);
        };
        !isFinishedDataSetup && getData();
    }, [candidateId, isFinishedDataSetup, jobId, navigate, searchParams]);

    useEffect(() => {
        const getMeetingTypes = async () => {
            const res = await GetActiveMeetingTypes(entityTypeId);
            if (res) setMeetingTypes(res);
        };
        getMeetingTypes();
    }, []);

    useEffect(() => {
        const getInterviewTypes = async () => {
            const res = await GetActiveInterviewTypes();
            if (res) setInterviewTypes(res);
        };
        getInterviewTypes();
    }, []);

    useEffect(() => {
        const tabParam = searchParams.get('tab');
        const getData = async () => {
            setIsFetchingDefaultTab(true);
            const savedWorkHistoryView = await GetSettingBySettingName(workHistoryViewSettingName);
            if (savedWorkHistoryView) setWorkHistoryView(savedWorkHistoryView === 'timeline' ? 'timeline' : 'list');
            if (!tabParam) {
                const tabSetting = await GetSettingBySettingName('TabDefaultsCandidates');
                if (tabSetting) setDefaultTab(tabSetting);
            }
            else setActiveTab(tabParam)
            setIsFetchingDefaultTab(false);
            setIsFinishedSetup(true);
        };
        getData();
    }, [searchParams]);

    useEffect(() => {
        const getData = async () => {
            const value = await GetCustomerSettingBySettingName('IsComplianceEnabled');
            if (value) setCompliance(value === 'True');
        };
        getData();
    }, []);

    useEffect(() => {
        const getSettings = async () => {
            const mySettings = await GetMySettings();
            if (mySettings) {
                const templateSetting = mySettings.find(s => s.type === 'DefaultRejectionEmailTemplateID');
                if (templateSetting && templateSetting.value !== '0') setDefaultRejectionTemplateId(+templateSetting.value);
            }
        };
        getSettings();
    }, []);

    const downloadCV = useCallback(async (candidateId: number) => {
        setIsLoading(true);
        if (candidateJob && candidateJob.applicationID && hasApplicationCv)
            await DownloadApplicationDocument(candidateJob.applicationID, 'cv', undefined, setErrorMessage);
        else await DownloadCandidateResume(candidateId);
        setIsLoading(false);
    }, [hasApplicationCv, candidateJob]);

    const downloadApplicationDocument = useCallback(async (docType: 'cover-letter' | 'questionnaire') => {
        setIsLoading(true);
        if (candidateJob && candidateJob.applicationID)
            await DownloadApplicationDocument(candidateJob.applicationID, docType, undefined, setErrorMessage);
        setIsLoading(false);
    }, [candidateJob]);

    const sendMessageCallback = useCallback((type: 'email' | 'sms') => {
        if (candidate) {
            let recipientEmail = '';
            let recipientMobile = '';
            if (type === 'email') {
                const email1 = candidate.email1;
                const email2 = candidate.email2;
                recipientEmail = email1 ? email1 : email2;
            }
            else if (type === 'sms') {
                const mobileStd = candidate.mobile_Standardised;
                const mobile = candidate.mobile;
                const phoneStd = candidate.home_Standardised;
                const phone = candidate.home;
                if (Boolean(mobileStd)) recipientMobile = mobileStd;
                else if (Boolean(mobile)) recipientMobile = mobile;
                else if (Boolean(phoneStd)) recipientMobile = phoneStd;
                else if (Boolean(phone)) recipientMobile = phone;
            }
            const recipients: EmailRecipient[] = [
                {
                    id: candidate.id,
                    name: candidate.fullName,
                    email: recipientEmail,
                    mobile: recipientMobile,
                    candidate: candidate,
                    job: job
                }
            ];
            setSendMessageDialogType(type);
            setMessageRecipients(recipients);
        }
    }, [candidate, job]);

    const sendOnboardingCallback = useCallback(() => {
        if (candidate) {
            let id = candidate.id;
            let email = candidate.email1;
            let name = candidate.fullName;

            const r: EmailRecipient = {
                id: id,
                mobile: '',
                email: email,
                name: name,
                candidate: candidate,
                isOptOut: false,
            };
            setOnboardingRecipient(r);
        }
    }, [candidate]);

    const handleRatingChangeCallback = useCallback((newActionId: number, reason: string) => {
        const partial: Partial<JobTracking> = { id: 0, applicationID: 0, ratingID: newActionId, ratingReason: reason };
        setCandidateJob(prev => prev ? {...prev, ratingID: newActionId, ratingReason: reason } : partial as JobTracking);
    }, []);

    const candidateRating = useMemo(() => {
        if (candidateJob) return candidateJob.ratingID;
        return null;
    }, [candidateJob]);

    const actionMenuDefinitions = useMemo<MenuOptionDefinition[]>(() => {
        const isCandidateRedFlagged = candidate ? candidate.isRedFlagged: false;
        let actions: (MenuOptionDefinition)[] = [
            ...partnerActions,
            ...tabActionMenuOptions,
            { label: 'Add Work History Item', type: 'action', action: () => setShowAddWorkHistoryDialog(true), allow: () => canAddEditCandidates },
            { label: 'Convert to Contact', type: 'link', href: `/contacts/create?CandidateID=${candidateId}`, allow: () => canAddEditContacts },
            { label: 'Delete', type: 'action', action: () => setShowDeleteDialog(true), allow: () => canDeleteCandidates },
            { label: 'Upload CV', type: 'action', action: () => setShowUploadCvDialog(true), allow: () => canAddEditCandidates },
            { label: 'Update Opt Out', type: 'action', action: () => setShowConfirmUpdateOptOut(true), allow: () => canAddEditCandidates && !isCandidateRedFlagged },
            { label: 'Red Flag Candidate', type: 'action', action: () => setShowRedFlagCandidateDialog(true), allow: () => canAddEditCandidates && !isCandidateRedFlagged },
            { label: 'Remove Red Flag', type: 'action', action: () => setShowRemoveRedFlagDialog(true), allow: () => canAddEditCandidates && isCandidateRedFlagged && isDataMaintenanceAdministrator },
            { label: 'Send Email', type: 'action', allow: () => canSendEmails, action: () => sendMessageCallback('email') },
            { label: 'Send Onboarding Form', type: 'action', allow: () => canSendOnboarding, action: () => sendOnboardingCallback() },
            { label: 'Send SMS', type: 'action', allow: () => canSendSms, action: () => sendMessageCallback('sms') },
            { label: 'Send WorkPro Package', type: 'action', allow: () => isWorkProConnected, action: () => setShowSendWorkProPackageDialog(true) },
            { label: 'Link To Job', type: 'action', action: () => setShowLinkCandidateToJobDialog(true) },
            { label: 'Send CV for Formatting', allow: () => isOutsourcingWizardConnected, type: 'action', action: () => setShowSendCvForFormattingDialog(true) },
            { label: 'Generate Document', type: 'action', action: () => setShowGenerateDocumentsDialog(true), allow: () => canGenerateDocuments },
            { label: 'Generate Formatted CV', type: 'action', action: () => setShowGenerateFormattedCvDialog(true), allow: () => canGenerateDocuments },
        ];

        for (let i = 0; i < activitySettings.length; i++) {
            const setting = activitySettings[i];
            if (setting.category === 2) continue;
            if (setting.workflowStatus === 0) {
                if (setting.id === 18) setNoteActivitySetting(setting);
                if (setting.id === 23)
                    actions.push({ label: setting.customName, type: 'action', action: () => setShowSendReferralDialog(true) });
                else
                    actions.push({ label: setting.customName, type: 'action', action: () => setSelectedActivity(setting) });
            }
        }

        let meetingActions: MenuOptionDefinition = {
            label: 'Meetings', type: 'parent', subMenu: []
        };

        if (meetingTypes.length > 0 && meetingActions.subMenu) {
            for (let i = 0; i < meetingTypes.length; i++) {
                const t = meetingTypes[i];
                const href = `/meetings/create?contactIds=${candidateId}&typeId=${t.id}&source=${entityTypeId}`;
                meetingActions.subMenu.push({ label: t.name, type: 'link', href: href });
            }
        }

        if (meetingActions.subMenu && meetingActions.subMenu.length > 0) actions.push(meetingActions);

        return actions;
    }, [candidate, partnerActions, tabActionMenuOptions, candidateId, meetingTypes, canAddEditCandidates, canAddEditContacts, canDeleteCandidates, isDataMaintenanceAdministrator, canSendEmails, sendMessageCallback, canSendOnboarding, sendOnboardingCallback, canSendSms, isWorkProConnected, isOutsourcingWizardConnected, canGenerateDocuments, activitySettings]);

    const workflowMenuDefinitions = useMemo<MenuOptionDefinition[]>(() => {
        let workflow: MenuOptionDefinition[] = [
            { label: 'Export to foundU', type: 'action', action: () => setShowExportToFoundUDialog(true), allow: () => isFoundUConnected },
            { label: 'Export to Deputy', type: 'action', action: () => setShowExportToDeputyDialog(true), allow: () => isDeputyConnected },
            ...workflowPartnerActions
        ];

        let offerOptions: MenuOptionDefinition = { label: 'Offers', type: 'parent', subMenu: [], orderIndex: workflowOrder.offerIndex };

        let interviewActions: MenuOptionDefinition = { label: 'Interviews', type: 'parent', subMenu: [...interviewPartnerActions], orderIndex: workflowOrder.interviewIndex };

        let referenceCheckActions: MenuOptionDefinition = { label: 'Ref Checks', type: 'parent', subMenu: [...refCheckPartnerActions], orderIndex: workflowOrder.refCheckIndex };

        if (jobId) {
            if (candidateRating !== 4) workflow.push({ label: 'Submit Candidate', type: 'action', action: () => setTmpErrorMessage('Candidate must be rated Green'), orderIndex: workflowOrder.submitIndex });
            else workflow.push({ label: 'Submit Candidate', type: 'link', href: `/submissions/create?candidateIds=${candidateId}&jobId=${jobId}`, orderIndex: workflowOrder.submitIndex });
            for (let i = 0; i < activitySettings.length; i++) {
                const s = activitySettings[i];
                if (s.workflowStatus === 0) continue;
                if (interviewWorkflowStatuses.indexOf(s.workflowStatus) > -1) continue;
                if (s.id === 10) workflow.push({ label: s.customName, type: 'link', href: `/candidates/${candidateId}/screening?jobId=${jobId}`, orderIndex: workflowOrder.indexes[s.workflowStatus] });
                else if (s.id === 16)
                    workflow.push({ label: 'Place Candidate', type: 'action', action: () => setShowPlaceCandidatesDialog(true), orderIndex: workflowOrder.indexes[s.workflowStatus] });
                else if (s.workflowStatus === offerWorkflowStatus || s.id === 39 || s.workflowStatus === offerAcceptedWorkflowStatus) {
                    if (s.id === 14)
                        offerOptions.subMenu?.push({
                            label: s.customName,
                            type: 'action',
                            action: () => setShowOfferMadeDialog(true)
                        });
                    else
                        offerOptions.subMenu?.push({
                            label: s.customName,
                            type: 'action',
                            action: () => setSelectedActivity(s)
                        });
                }
                else if (s.id === 30) {
                    referenceCheckActions.subMenu?.push({ label: s.customName, type: 'link', href: `/candidates/${candidateId}/reference-check?jobId=${jobId}` });
                    referenceCheckActions.subMenu?.push({ label: 'Digital Reference Check', type: 'action', action: () => setShowDigitalReferenceCheckDialog(true) });
                    referenceCheckActions.subMenu?.push({ label: 'Referoo', allow: () => isReferooConnected, type: 'action', action: () => setShowReferooDialog(true)});
                }
                else if (s.id === 9 || s.id === 40 || s.id === 50 || s.id === 73) {
                    workflow.push({ label: s.customName, type: 'action', action: () => setSelectedActivity(s), orderIndex: workflowOrder.indexes[s.workflowStatus] });
                } else if (s.workflowStatus > 0 && s.id !== 15) {
                    workflow.push({ label: s.customName, type: 'action', action: () => setSelectedActivity(s), orderIndex: workflowOrder.indexes[s.workflowStatus] });
                }
            }

            if (interviewTypes.length > 0 && interviewActions.subMenu) {
                for (let i = 0; i < interviewTypes.length; i++) {
                    const t = interviewTypes[i];
                    const href = `/interviews/create?typeId=${t.id}&candidateId=${candidateId}&jobId=${jobId}`;
                    interviewActions.subMenu.push({ label: t.name, type: 'link', href: href });
                }
            }
        }
        else {
            if (interviewTypes.length > 0 && interviewActions.subMenu) {
                for (let i = 0; i < interviewTypes.length; i++) {
                    const t = interviewTypes[i];
                    if(t.isInternal) {
                        const href = `/interviews/create?typeId=${t.id}&candidateId=${candidateId}&jobId=${jobId}`;
                        interviewActions.subMenu.push({ label: t.name, type: 'link', href: href });
                    }
                }
            }
            
            const screenSetting = activitySettings.find(a => a.id === 10);
            if (screenSetting) workflow.push({ label: screenSetting.customName, type: 'link', href: `/candidates/${candidateId}/screening`, orderIndex: workflowOrder.indexes[screenSetting.workflowStatus] });

            const referenceCheckSetting = activitySettings.find(a => a.id === 30);
            if (referenceCheckSetting) {
                workflow.push({ label: referenceCheckSetting.customName, type: 'link', href: `/candidates/${candidateId}/reference-check`, orderIndex: workflowOrder.refCheckIndex });
                workflow.push({ label: 'Digital Reference Check', type: 'action', action: () => setShowDigitalReferenceCheckDialog(true) },);
            }
            workflow.push({ label: 'Float Candidate', type: 'link', href: `/submissions/create?candidateIds=${candidateId}` });
            //workflow.push({ label: 'Referoo', type: 'action', allow: () => isReferooConnected, action: () => setShowReferooDialog(true) });
        }

        if (offerOptions.subMenu && offerOptions.subMenu.length > 0) workflow.push(offerOptions);
        if (referenceCheckActions.subMenu && referenceCheckActions.subMenu.length > 0) workflow.push(referenceCheckActions);
        if (interviewActions.subMenu && interviewActions.subMenu.length > 0) {
            interviewActions.subMenu.sort((a,b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));
            workflow.push(interviewActions);
        }

        return workflow;
    }, [workflowPartnerActions, workflowOrder.offerIndex, workflowOrder.interviewIndex, workflowOrder.refCheckIndex, workflowOrder.submitIndex, workflowOrder.indexes, interviewPartnerActions, refCheckPartnerActions, jobId, isFoundUConnected, isDeputyConnected, candidateRating, candidateId, interviewTypes, activitySettings, isReferooConnected]);

    const SummaryBar = useMemo(() => {
        if (candidate && candidate.id !== candidateId) setIsFinishedDataSetup(false)
        if (candidate) {
            const linkedInAction = (
                <a href={linkedInLink(candidate)}
                    style={summaryLinkStyle}
                    key="linkedInAction"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <Avatar sx={{ bgcolor: "#0e76a8", mr: '5px' }}>
                        <img src={LinkedInIcon} alt="linkedin-icon" width="100%" />
                    </Avatar>
                </a>
            );

            const twitterAction = (
                <a href={twitterLink(candidate)}
                    style={summaryLinkStyle}
                    key="twitterAction"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <Avatar sx={{ bgcolor: "#1DA1F2", mr: '5px' }}>
                        <img src={TwitterIcon} alt="twitter-icon" width="100%" />
                    </Avatar>
                </a>
            );

            const facebookAction = (
                <a href={facebookLink(candidate)}
                    style={summaryLinkStyle}
                    key="facebookAction"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <Avatar sx={{ bgcolor: "#1877F2", mr: '5px' }}>
                        <img src={FacebookIcon} alt="facebook-icon" width="100%" />
                    </Avatar>
                </a>
            );

            const googleAction = (
                <a href={`https://www.google.com/search?q=${encodeURIComponent(candidate.fullName)}`}
                    style={summaryLinkStyle}
                    key="googleAction"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <Avatar sx={{ bgcolor: 'transparent', mr: '5px' }}>
                        <img src={GoogleIcon} alt="google-icon" />
                    </Avatar>
                </a>
            );

            const tagsAction = (
                <Avatar
                    key="tagsAction"
                    onClick={canAddEditCandidates ? () => setShowTagsManagement(true) : () => setShowTagsPreviewNoDelay(true)}
                    onMouseEnter={ () => setShowTagsPreview(true) }
                    onMouseLeave={ () => setShowTagsPreview(false) }
                    sx={{ bgcolor: '#f209a6', mr: '5px', cursor: 'pointer' }}>
                    { candidate.tags ? <LocalOfferIcon /> : <LocalOfferOutlinedIcon /> }
                </Avatar>
            );

            const locationUrl = googleMapsLink(candidate);
            const locationAction = locationUrl ? (
                <a href={ locationUrl }
                    style={summaryLinkStyle}
                    key="locationAction"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <Avatar sx={{ bgcolor: "#ea4335", mr: '5px' }}>
                        <LocationOnIcon />
                    </Avatar>
                </a>
            ) : <Avatar key="locationPlaceholder" sx={{ mr: '5px' }}><LocationOnIcon /></Avatar>;

            const downloadCvAction = candidate.hasResume || hasApplicationCv ? (
                <Avatar
                    key="downloadResumeAction"
                    onClick={ () => downloadCV(candidateId) }
                    sx={{ mr: '5px', cursor: 'pointer' }}
                    title="Download CV"
                    src={CvIcon}
                />
            ) : <React.Fragment key="downloadResumeAction" /> ;

            const downloadCoverLetterAction = hasApplicationCoverLetter ? (
                <Avatar
                    key="downloadCoverLetterAction"
                    onClick={ () => downloadApplicationDocument('cover-letter') }
                    sx={{ mr: '5px', cursor: 'pointer' }}
                    title="Download Cover Letter"
                    src={CoverLetterIcon}
                />
            ) : <React.Fragment key="downloadCoverLetterAction" />;

            const downloadQuestionnaireAction = hasApplicationQuestionnaire ? (
                <Avatar
                    key="downloadQuestionnaireAction"
                    onClick={ () => downloadApplicationDocument('questionnaire') }
                    sx={{ mr: '5px', cursor: 'pointer' }}
                    title="Download Advert Screening Questionnaire"
                >
                    <BallotIcon />
                </Avatar>
            ) : <React.Fragment key="downloadQuestionnaireAction" />;

            const action1 = canAddEditCandidates ? (
                <Link target={"_blank"} key="editLink" to={`/candidates/${candidateId}/edit`} style={summaryLinkStyle}>
                    <Button variant="contained" color="success" sx={{ mr: '5px' }}>Edit</Button>
                </Link>
            ) : <React.Fragment key="editPlaceholder" />;

            const ratingIcon = candidateJob || jobId ? (
                <CandidateRatingIcon
                    key="testingRatingIcon"
                    candidateId={candidateId}
                    jobId={jobId}
                    applicationId={candidateJob ? candidateJob.applicationID : 0}
                    onActionChangeHandler={handleRatingChangeCallback}
                    errorHandler={setTmpErrorMessage}
                    loadingHandler={setIsLoading}
                    successHandler={setSuccessMessage}
                    defaultRejectionTemplateId={defaultRejectionTemplateId}
                    actionId={candidateJob ? candidateJob.ratingID : -1}
                    mr="5px"
                />
            ) : <React.Fragment key="testingRatingIcon" />;

            const handlePrevNextNavigation = (id: number) => {
                let dataId = searchParams.get('dataId') ?? '';
                let prevCandidateId = 0;
                let nextCandidateId = 0;

                const savedSortModel = localStorage.getItem(dataId);
                if (savedSortModel) {
                    const model = JSON.parse(savedSortModel) as CandidatePositionTracker[];

                    model.forEach((element: CandidatePositionTracker) => {

                        if (element.candidateId === id) {
                            prevCandidateId = element.prevCandidateId;
                            nextCandidateId = element.nextCandidateId;
                            return;
                        }
                    });
                }

                setIsFinishedDataSetup(false);
                setIsFinishedSetup(false);

                const params = new URLSearchParams();
                if (jobId) params.set('jobId', jobId.toString());
                if (ssid) params.set('ssid', ssid.toString());
                if (ratingId || ratingId === 0) params.set('ratingId', ratingId.toString());
                if (dataId) params.set('dataId', dataId);
                if (prevCandidateId) params.set('prevCandidateId', prevCandidateId.toString());
                if (nextCandidateId) params.set('nextCandidateId', nextCandidateId.toString());

                navigate(`/candidates/${id}?${params}`, { replace: true });
            };

            const searchResultsIcon = ssid !== 0 ? (
                <Link key="searchResults" to={`/candidates?ssid=${ssid}&jobId=${jobId}&candidateId=${candidateId}`}>
                    <IconButton size="small" >
                        <Avatar sx={{ bgcolor: '#5E21D0' }} >
                            <FormatListBulletedIcon style={{ color: "white" }} sx={{ cursor: 'pointer' }} />
                        </Avatar>
                    </IconButton>
                </Link>
            ) : <React.Fragment key="searchResults" />;

            const jobIcon = jobId !== 0 ? (
                <Link key="navigateToJob" to={`/jobs/${jobId}?tab=JOBS_Candidates&RatingID=${ratingId}`}>
                    <IconButton size="small" >
                        <Avatar sx={{ bgcolor: '#5E21D0' }} >
                            <WorkIcon style={{ color: "white" }} sx={{ cursor: 'pointer' }} />
                        </Avatar>
                    </IconButton>
                </Link>
            ) : <React.Fragment key="noJob" />;

            const leftDirectionIndicator = dataId !== "" ? (
                <IconButton
                    size="small"
                    disabled={prevCandidateId === 0}
                    onClick={() => handlePrevNextNavigation(prevCandidateId)}
                    key="prevCandidate"
                >
                    <ArrowCircleLeftIcon color={prevCandidateId === 0 ? undefined : 'primary'} sx={{ fontSize: 45, cursor: 'pointer' }} />
                </IconButton>
            ) : <React.Fragment key="noPrevCandidate" />;

            const rightDirectionIndicator = dataId !== "" ? (
                <IconButton
                    size="small"
                    disabled={nextCandidateId === 0}
                    onClick={() => handlePrevNextNavigation(nextCandidateId)}
                    key="nextCandidate"
                >
                    <ArrowCircleRightIcon color={nextCandidateId === 0 ? undefined : 'primary'} sx={{ fontSize: 45, cursor: 'pointer' }} />
                </IconButton>
            ) : <React.Fragment key="noNextCandidate" />;

            const workflow = (
                <Box key="workflowMenu">
                    <ActionMenu label="Workflow" mr="5px" color="secondary" definition={workflowMenuDefinitions} sortType={workflowOrder.sortType} />
                </Box>
            );

            const action = (
                <Box key="actionsMenu">
                    <ActionMenu definition={actionMenuDefinitions} />
                </Box>
            );

            return (
                <TitleAndActionSummaryBar
                    height="4.5rem"
                    title={candidate ? <>{searchResultsIcon}{jobIcon}{ratingIcon}{leftDirectionIndicator}{rightDirectionIndicator}{renderSummary(candidate)}</> : ''}
                    action={[downloadCvAction, downloadCoverLetterAction, downloadQuestionnaireAction, locationAction, linkedInAction, twitterAction, facebookAction, googleAction, tagsAction, action1, workflow, action]}
                    browserTabTitle={!candidate ? '' : candidate.fullName + " - Candidates"}
                />
            );
        }
    }, [candidate, canAddEditCandidates, hasApplicationCv, hasApplicationCoverLetter, hasApplicationQuestionnaire, candidateId, candidateJob, jobId, handleRatingChangeCallback, defaultRejectionTemplateId, ssid, ratingId, dataId, prevCandidateId, nextCandidateId, workflowMenuDefinitions, workflowOrder.sortType, actionMenuDefinitions, downloadCV, downloadApplicationDocument, searchParams, navigate]);

    const handleTabChange = useCallback((e: React.SyntheticEvent, newValue: string) => {
        if (jobId) {
            navigate(`/candidates/${candidateId}?jobId=${jobId}&ssid=${ssid}&ratingId=${ratingId}&dataId=${dataId}&prevCandidateId=${prevCandidateId}&nextCandidateId=${nextCandidateId}&tab=${newValue}`);
        }
        else if (dataId)
            navigate(`/candidates/${candidateId}?ssid=${ssid}&dataId=${dataId}&prevCandidateId=${prevCandidateId}&nextCandidateId=${nextCandidateId}&tab=${newValue}`);
        else
            navigate(`/candidates/${candidateId}?tab=${newValue}`);
    }, [candidateId, jobId, ssid, ratingId, dataId, prevCandidateId, nextCandidateId, navigate]);

    const deleteRecordCallback = useCallback(async () => {
        setIsLoading(true);
        const res = await DeleteCandidateById(candidateId, setErrorMessage);
        if (res) setIsRecordDeleted(true);
        setIsLoading(false);
    }, [candidateId]);

    const exportToFoundUCallback = useCallback(async () => {
        if (Boolean(candidateId)) {
            setIsLoading(true);
            const res = await ExportCandidateToFoundU(candidateId, setErrorMessage);
            if (res) {
                setSuccessMessage('Record Exported to FoundU');
                setShowExportToFoundUDialog(false);
            }
            setIsLoading(false);
        }
    }, [candidateId]);

    const exportToDeputyCallback = useCallback(async () => {
        if (Boolean(candidateId)) {
            setIsLoading(true);
            const res = await ExportCandidateToDeputy(candidateId, setErrorMessage);
            if (res) {
                setSuccessMessage('Record Exported to Deputy');
                setShowExportToDeputyDialog(false);
            }
            setIsLoading(false);
        }
    }, [candidateId]);

    const changeOptOutCallback = useCallback(async () => {
        setIsLoading(true);
        const res = await ToggleCandidateOptOut(candidateId, setErrorMessage);
        if (res) {
            setCandidate(prev => prev ? { ...prev, optOut: !prev.optOut } : undefined);
            setSuccessMessage('Opt Out Updated');
            setShowConfirmUpdateOptOut(false);
        }
        setIsLoading(false);
    }, [candidateId]);

    const onEditableCustomFieldChange = useCallback((udfNumber: number, value: string) => {
        setCandidate(prev => {
            if (prev) {
                let tmp = {...prev};
                (tmp as any)['customField' + udfNumber] = value;
                return tmp;
            }
            return prev;
        });
    }, []);

    const counts = useMemo(() => {
        let counts = {
            documents: 0,
            callsNotes: 0,
            callsNotesDate: '\u00A0',
            meetings: 0,
            meetingsDate: '\u00A0',
            messages: 0,
            messagesDate: '\u00A0',
            opportunities: 0,
            opportunitiesDate: '\u00A0',
            openJobs: 0,
            openJobsDate: '\u00A0',
            jobs: 0,
            jobsDate: '\u00A0',
            interviews: 0,
            interviewsDate: '\u00A0',
            placements: 0,
            placementsDate: '\u00A0',
            activities: 0,
            activitiesDate: '\u00A0',
            submissions: 0,
            submissionsDate: '\u00A0'
        };

        if (candidate && candidate.statisticsCounts && candidate.statisticsCounts.length > 0) {
            for (let i = 0; i < candidate.statisticsCounts.length; i++) {
                const s = candidate.statisticsCounts[i];
                switch (s.type) {
                    case "DocumentsCount": counts.documents = s.value;
                        break;
                    case "CallsNotesCount": counts.callsNotes = s.value;
                        break;
                    case "MeetingsCount": counts.meetings = s.value;
                        break;
                    case "MessagesCount": counts.messages = s.value;
                        break;
                    case "OpportunitiesCount": counts.opportunities = s.value;
                        break;
                    case "JobsOpenCount": counts.openJobs = s.value;
                        break;
                    case "JobsCount": counts.jobs = s.value;
                        break;
                    case "InterviewsCount": counts.interviews = s.value;
                        break;
                    case "PlacementsCount": counts.placements = s.value;
                        break;
                    case "ActivitiesCount": counts.activities = s.value;
                        break;
                    case "SubmissionsCount": counts.submissions = s.value;
                        break;
                }
            }
        }
        if (candidate && candidate.statisticsDates && candidate.statisticsDates.length > 0) {
            for (let i = 0; i < candidate.statisticsDates.length; i++) {
                const s = candidate.statisticsDates[i];
                switch (s.type) {
                    case "CallsNotesDate":
                        const cnDate = moment(s.value);
                        if (cnDate.isValid()) counts.callsNotesDate = cnDate.format('DD MMM YYYY');
                        break;
                    case "MeetingsDate":
                        const mDate = moment(s.value);
                        if (mDate.isValid()) counts.meetingsDate = mDate.format('DD MMM YYYY');
                        break;
                    case "MessagesDate":
                        const mssDate = moment(s.value);
                        if (mssDate.isValid()) counts.messagesDate = mssDate.format('DD MMM YYYY');
                        break;
                    case "OpportunitiesDate":
                        const oppDate = moment(s.value);
                        if (oppDate.isValid()) counts.opportunitiesDate = oppDate.format('DD MMM YYYY');
                        break;
                    case "JobsOpenDate":
                        const joDate = moment(s.value);
                        if (joDate.isValid()) counts.openJobsDate = joDate.format('DD MMM YYYY');
                        break;
                    case "JobsDate":
                        const jDate = moment(s.value);
                        if (jDate.isValid()) counts.jobsDate = jDate.format('DD MMM YYYY');
                        break;
                    case "InterviewsDate":
                        const iDate = moment(s.value);
                        if (iDate.isValid()) counts.interviewsDate = iDate.format('DD MMM YYYY');
                        break;
                    case "PlacementsDate":
                        const pDate = moment(s.value);
                        if (pDate.isValid()) counts.placementsDate = pDate.format('DD MMM YYYY');
                        break;
                    case "ActivitiesDate":
                        const aDate = moment(s.value);
                        if (aDate.isValid()) counts.activitiesDate = aDate.format('DD MMM YYYY');
                        break;
                    case "SubmissionsDate":
                        const cvDate = moment(s.value);
                        if (cvDate.isValid()) counts.submissionsDate = cvDate.format('DD MMM YYYY');
                        break;
                }
            }
        }
        return counts;
    }, [candidate]);

    useEffect(() => {
        if (tabActionMenuOptions.length > 0 && activeTab !== 'CANDIDATES_Home') {
            setTabActionMenuOptions([]);
        }
    }, [activeTab, tabActionMenuOptions]);

    useEffect(() => {
        if (candidateId) setTabSetupFinished(false);
    }, [candidateId]);

    useEffect(() => {
        if (candidate && defaultTab && isFinishedDataSetup && !tabSetupFinished) {
            const accessRestrictedTabs = ['CANDIDATES_CallsNotes', 'CANDIDATES_Meetings', 'CANDIDATES_Messages', 'CANDIDATES_Activities'];
            setActiveTab(prev => {
                if (defaultTab === 'CANDIDATES_CV' && !candidate.hasResumeViewerDoc) return 'CANDIDATES_Home';
                if (accessRestrictedTabs.includes(defaultTab) && !canAccessActivities) return 'CANDIDATES_Home';
                if (defaultTab === 'CANDIDATES_Jobs' && !canAccessJobs) return 'CANDIDATES_Home';
                if (defaultTab === 'CANDIDATES_Submissions' && !(canAccessActivities && canAccessJobs)) return 'CANDIDATES_Home';
                if (defaultTab === 'CANDIDATES_Interviews' && !(canAccessActivities && canAccessJobs)) return 'CANDIDATES_Home';
                if (defaultTab === 'CANDIDATES_Placements' && !canAccessPlacements) return 'CANDIDATES_Home';
                if (defaultTab === 'Compliance' && !compliance) return 'CANDIDATES_Home';
                return defaultTab;
            });
            setTabSetupFinished(true);
        }
    }, [candidate, defaultTab, canAccessActivities, canAccessJobs, canAccessPlacements, compliance, tabSetupFinished, isFinishedDataSetup]);

    const selectedActivityData = useMemo<Activity | null>(() => {
        if (candidate && job && selectedActivity && selectedActivity.workflowStatus !== 0) {
            return {
                ...DefaultActivity,
                candidateID: candidateId,
                candidateName: candidate.fullName,
                jobID: job.jobID,
                jobReference: job.jobReference,
                jobTitle: job.title,
                clientID: job.clientID,
                clientName: job.clientName,
                contactID: job.contact1ID,
                contactName: job.contact1Name,
                category: selectedActivity.category,
                type: selectedActivity.customName,
                typeID: selectedActivity.id
            };
        }
        if (candidate && job && selectedActivity) {
            return {
                ...DefaultActivity,
                candidateID: candidateId,
                candidateName: candidate.fullName,
                jobID: job.jobID,
                jobReference: job.jobReference,
                jobTitle: job.title,
                category: selectedActivity.category,
                type: selectedActivity.customName,
                typeID: selectedActivity.id
            };
        }
        if (candidate && selectedActivity) {
            return {
                ...DefaultActivity,
                candidateID: candidateId,
                candidateName: candidate.fullName,
                category: selectedActivity.category,
                type: selectedActivity.customName,
                typeID: selectedActivity.id
            };
        }
        return null;
    }, [candidateId, candidate, job, selectedActivity]);

    const activityCreatedSuccessCallback = useCallback((message: string) => {
        setSuccessMessage(message);
        setRefreshActivitiesGridControl(prev => !prev);
    }, []);

    const workHistoryViewChangeHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = e.target.checked;
        const value = isChecked ? 'list' : 'timeline';
        setWorkHistoryView(value);
        UpdateSettingBySettingName(workHistoryViewSettingName, value);
    }, []);

    const workHistorySwitch = useMemo(() => (
        <span>
            <span>Timeline</span>
            <Switch checked={workHistoryView === 'list'} onChange={workHistoryViewChangeHandler} />
            <span>List</span>
        </span>
    ), [workHistoryView, workHistoryViewChangeHandler]);

    return (
        <>
            <Snackbar open={successMessage !== ''} autoHideDuration={3000} onClose={() => setSuccessMessage('')}>
                <Alert onClose={() => setSuccessMessage('')}>{ successMessage }</Alert>
            </Snackbar>
            <Snackbar open={isRecordDeleted} autoHideDuration={1200} onClose={() => navigate('/candidates/')}>
                <Alert onClose={() => navigate('/candidates/')}>Record deleted</Alert>
            </Snackbar>
            <Snackbar open={errorMessage !== ''} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <Snackbar open={Boolean(tmpErrorMessage)} autoHideDuration={3000} onClose={() => setTmpErrorMessage('')}>
                <Alert severity="error" onClose={() => setTmpErrorMessage('')}>{tmpErrorMessage}</Alert>
            </Snackbar>
            <PageLayout paddingTop={0} SummaryBar={SummaryBar}>
                <ConfirmationDialog
                    message="Are you sure you want to delete this record?"
                    onClose={ () => setShowDeleteDialog(false) }
                    onContinue={ deleteRecordCallback }
                    open={showDeleteDialog}
                    title="Confirm Action"
                    confirmActionText="Yes"
                    cancelActionText="No"
                />
                <ConfirmationDialog
                    message="Are you sure you want to export this record to foundU?"
                    onClose={ () => setShowExportToFoundUDialog(false) }
                    onContinue={ exportToFoundUCallback }
                    open={showExportToFoundUDialog}
                    title="Confirm Action"
                    confirmActionText="Yes"
                    cancelActionText="No"
                />
                <ConfirmationDialog
                    message="Are you sure you want to export this record to Deputy?"
                    onClose={ () => setShowExportToDeputyDialog(false) }
                    onContinue={ exportToDeputyCallback }
                    open={showExportToDeputyDialog}
                    title="Confirm Action"
                    confirmActionText="Yes"
                    cancelActionText="No"
                />
                <RedFlagCandidateDialog
                    open={showRedFlagCandidateDialog}
                    candidateId={candidateId}
                    candidateName={candidate ? candidate.fullName : ""}
                    closeHandler={ () => setShowRedFlagCandidateDialog(false) }
                    errorHandler={setErrorMessage}
                    loadingHandler={setIsLoading}
                    successHandler={setSuccessMessage}
                />
                <RemoveRedFlagDialog
                    open={showRemoveRedFlagDialog}
                    candidateId={candidateId}
                    candidateName={candidate ? candidate.fullName : ""}
                    closeHandler={ () => setShowRemoveRedFlagDialog(false) }
                    errorHandler={setErrorMessage}
                    loadingHandler={setIsLoading}
                    successHandler={setSuccessMessage}
                />
                <RedFlagWarningDialog
                    open={showRedFlagWarningDialog}
                    candidateId={candidateId}
                    candidateName={candidate ? candidate.fullName : ""}
                    date={candidate ? candidate.redFlaggedDate : ""}
                    flaggedBy={candidate ? candidate.redFlaggedByUserName : ""}
                    comments={candidate ? candidate.redFlaggedComments : ""}
                    closeHandler={ () => setShowRedFlagWarningDialog(false) }
                    errorHandler={setErrorMessage}
                    loadingHandler={setIsLoading}
                    successHandler={setSuccessMessage}
                />

                <UploadCvDialog
                    open={showUploadCvDialog}
                    candidateId={candidateId}
                    jobId={0}
                    singleFile={true}
                    closeHandler={ () => setShowUploadCvDialog(false) }
                    errorHandler={setErrorMessage}
                    loadingHandler={setIsLoading}
                    successHandler={setSuccessMessage}
                />
                <ConfirmationDialog
                    message={`Are you sure you want to update Opt Out to ${candidate && candidate.optOut ? 'No' : 'Yes'} ?`}
                    onClose={ () => setShowConfirmUpdateOptOut(false) }
                    onContinue={ changeOptOutCallback }
                    open={showConfirmUpdateOptOut}
                    title="Update Opt Out"
                    confirmActionText="Yes"
                    cancelActionText="No"
                />
                <EditActivityDialog
                    closeHandler={() => setSelectedActivity(null)}
                    data={selectedActivityData}
                    open={ Boolean(selectedActivity) }
                    loadingHandler={setIsLoading}
                    errorHandler={setErrorMessage}
                    successHandler={activityCreatedSuccessCallback}
                />
                <SendReferralDialog
                    open={showSendReferralDialog}
                    candidateId={candidate && candidate.id ? candidate.id : 0}
                    candidateName={candidate && candidate.fullName}
                    closeHandler={ () => setShowSendReferralDialog(false) }
                    loadingHandler={setIsLoading}
                    errorHandler={setErrorMessage}
                    successHandler={setSuccessMessage}
                />
                <PreviewLoaderComponent
                    open={showTagsPrevew}
                    entityType="candidate"
                    recordId={candidateId}
                    showDelayMs={showTagsPrevewNoDelay ? 0 : undefined}
                    isTagsPreview
                />
                <TagsManagementDialog
                    open={showTagsManagement}
                    entityId={3}
                    recordIds={candidateIdArray}
                    closeHandler={ () => setShowTagsManagement(false) }
                    loadingHandler={ setIsLoading }
                    errorHandler={ setErrorMessage }
                    successHandler={ setSuccessMessage }
                />
                <SendEmailDialog
                    open={sendMessageDialogType === 'email'}
                    sourceEntityId={entityTypeId}
                    jobId={jobId}
                    allowCcField
                    recipients={ messageRecipients }
                    recipientEntityTypeId={ 3 }
                    closeHandler={ () => setSendMessageDialogType(null) }
                    loadingHandler={setIsLoading}
                    errorHandler={setErrorMessage}
                    successHandler={setSuccessMessage}
                />
                <SendOnboardingDialog
                    open={ onboardingRecipient !== null }
                    sourceEntityId={entityTypeId}
                    jobId={jobId}
                    recipients={ onboardingRecipient ? [onboardingRecipient] : [] }
                    recipientEntityTypeId={ 3 }
                    closeHandler={ () => setOnboardingRecipient(null) }
                    loadingHandler={setIsLoading}
                    errorHandler={setErrorMessage}
                    successHandler={setSuccessMessage}
                />
                <SendSmsDialog
                    open={ sendMessageDialogType === 'sms' }
                    sourceEntityId={entityTypeId}
                    jobId={jobId}
                    recipients={ messageRecipients }
                    recipientEntityTypeId={ 3 }
                    closeHandler={ () => setSendMessageDialogType(null) }
                    loadingHandler={setIsLoading}
                    errorHandler={setErrorMessage}
                    successHandler={setSuccessMessage}
                />
                <LinkCandidatesToJobDialog
                    open={showLinkCandidateToJobDialog}
                    closeHandler={() => setShowLinkCandidateToJobDialog(false)}
                    candidateIds={candidateIdArray}
                    loadingHandler={setIsLoading}
                    errorHandler={setErrorMessage}
                    successHandler={setSuccessMessage}
                />
                <SendCvForFormattingDialog
                    candidateId={candidateId}
                    jobId={jobId}
                    open={showSendCvForFormattingDialog}
                    closeHandler={ () => setShowSendCvForFormattingDialog(false) }
                    loadingHandler={setIsLoading}
                    errorHandler={setErrorMessage}
                    successHandler={setSuccessMessage}
                />
                {jobId !== 0 &&
                    <OfferMadeDialog
                        open={showOfferMadeDialog}
                        candidateId={candidateId}
                        jobId={jobId}
                        closeHandler={() => setShowOfferMadeDialog(false)}
                        loadingHandler={setIsLoading}
                        errorHandler={setErrorMessage}
                        successHandler={setSuccessMessage}
                    />
                }
                <PlaceCandidatesDialog
                    open={showPlaceCandidatesDialog}
                    candidateIds={candidateIdArray}
                    jobId={jobId}
                    jobType={job ? job.type : ''}
                    closeHandler={() => setShowPlaceCandidatesDialog(false)}
                    loadingHandler={setIsLoading}
                    errorHandler={setErrorMessage}
                />
                <GenerateDocumentDialog
                    open={showGenerateDocumentsDialog}
                    entityId={entityTypeId}
                    playerId={candidateId}
                    closeHandler={() => setShowGenerateDocumentsDialog(false)}
                    loadingHandler={ setIsLoading }
                    errorHandler={ setErrorMessage }
                    successHandler={ setSuccessMessage }
                />
                <GenerateFormattedCvDialog
                    open={showGenerateFormattedCvDialog}
                    candidateId={candidateId}
                    closeHandler={() => setShowGenerateFormattedCvDialog(false)}
                    jobId={jobId}
                    loadingHandler={setIsLoading}
                    errorHandler={setErrorMessage}
                    successHandler={setSuccessMessage}
                />
                <ReferooActionsDialog
                    open={showReferooDialog}
                    candidateId={candidateId}
                    candidateName={candidate ? candidate.fullName : ''}
                    jobId={jobId}
                    jobTitle={job ? job.title : ''}
                    closeHandler={() => setShowReferooDialog(false)}
                    errorHandler={setErrorMessage}
                    loadingHandler={setIsLoading}
                    successHandler={setSuccessMessage}
                />
                 <DigitalReferenceCheckDialog
                    open={showDigitalReferenceCheckDialog}
                    jobId={jobId}
                    jobTitle={job ? job.title : ''}
                    candidateId={candidateId}
                    closeHandler={() => setShowDigitalReferenceCheckDialog(false)}
                    loadingHandler={setIsLoading}
                    errorHandler={setErrorMessage}
                    successHandler={setSuccessMessage} 
                />
                <EditWorkHistoryDialog
                    open={showAddWorkHistoryDialog}
                    candidateId={candidateId}
                    closeHandler={() => setShowAddWorkHistoryDialog(false)}
                    loadingHandler={setIsLoading}
                    errorHandler={setErrorMessage}
                    successHandler={setSuccessMessage}
                />
                { isWorkProConnected &&
                    <WorkProSendPackageDialog
                        open={showSendWorkProPackageDialog}
                        candidateId={candidateId}
                        closeHandler={() => setShowSendWorkProPackageDialog(false)}
                        errorHandler={setErrorMessage}
                        loadingHandler={setIsLoading}
                        successHandler={setSuccessMessage}
                    />
                }
                <Tabs value={activeTab} onChange={handleTabChange}>
                    <Tab value="CANDIDATES_Home" label={<>Home<br />&nbsp;</>} />
                    { candidate && candidate.hasResumeViewerDoc && <Tab value="CANDIDATES_CV" label={<>Resume<br />&nbsp;</>} /> }
                    <Tab value="CANDIDATES_WorkHistory" label={<>Work<br />History</>} />
                    {canAccessActivities && <Tab value="CANDIDATES_CallsNotes" label={<>Calls/Notes ({counts.callsNotes})<br />{counts.callsNotesDate}</>} />}
                    {canAccessActivities && <Tab value="CANDIDATES_Meetings" label={<>Meetings ({counts.meetings})<br />{counts.meetingsDate}</>} />}
                    {canAccessActivities && <Tab value="CANDIDATES_Messages" label={<>Messages ({counts.messages})<br />{counts.messagesDate}</>} />}
                    {canAccessJobs && <Tab value="CANDIDATES_Jobs" label={<>Jobs ({counts.jobs})<br/>{counts.jobsDate}</>}/> }
                    {canAccessActivities && canAccessJobs && <Tab value="CANDIDATES_Submissions" label={<>Submissions ({counts.submissions})<br />{counts.submissionsDate}</>} />}
                    {canAccessActivities && canAccessJobs && <Tab value="CANDIDATES_Interviews" label={<>Interviews ({counts.interviews})<br />{counts.interviewsDate}</>} />}
                    {canAccessPlacements && <Tab value="CANDIDATES_Placements" label={<>Placements ({counts.placements})<br />{counts.placementsDate}</>} />}
                    {compliance && <Tab value="Compliance" label={<>Compliance<br />&nbsp;</>} />}
                    <Tab value="CANDIDATES_Documents" label={<>Documents ({counts.documents})<br />&nbsp;</>} />
                    {canAccessActivities && <Tab value="CANDIDATES_Activities" label={<>Activities ({counts.activities})<br />{counts.activitiesDate}</>} />}
                </Tabs>
                { Boolean(callToActionTitle) &&
                    <CallOutNotification
                        type={callToActionType}
                        title={callToActionTitle}
                        onClose={() => setCallToActionTitle('')}
                    />
                }
                <PageContentLayout showLoading={isLoading || isFetchingDefaultTab || isLoadingTab}>
                    <ShowIframeDialog open={showPartnerActionPopup} closeHandler={() => setShowPartnerActionPopup(false)} title={popupTitle} url={popupUrl} />
                    <ShowIframeDialog open={showWorkflowPartnerActionPopup} closeHandler={() => setShowWorkflowPartnerActionPopup(false)} title={popupTitle2} url={popupUrl2} />
                    { isFinishedSetup && activeTab === "CANDIDATES_Home" &&
                        <ViewRecordScreenLayout
                            candidate={candidate ? candidate : null}
                            loadingHandler={setIsLoadingTab}
                            errorHandler={setErrorMessage}
                            onEditableCustomFieldChange={onEditableCustomFieldChange}
                        />
                    }
                    { isFinishedSetup && activeTab === "CANDIDATES_CV" &&
                        <CandidateResumeRenderer candidateId={candidateId} loadingHandler={setIsLoadingTab} errorHandler={setTmpErrorMessage} />
                    }
                    { isFinishedSetup && activeTab === "CANDIDATES_WorkHistory" &&
                        <>
                            {workHistoryView === 'list' &&
                                <WorkHistoryGridComponent
                                    gridName="candidates/WorkHistory"
                                    candidateId={candidateId}
                                    loadingHandler={setIsLoadingTab}
                                    errorHandler={setErrorMessage}
                                    successHandler={setSuccessMessage}
                                    extraActions={workHistorySwitch}
                                />
                            }
                            {workHistoryView === 'timeline' &&
                                <>
                                    {workHistorySwitch}
                                    <WorkHistoryTimelineComponent
                                        candidateId={candidateId}
                                        lastCvDate={candidate && candidate.resumeUpdateDate ? candidate.resumeUpdateDate : ''}
                                        lastUpdateDate={candidate && candidate.lastUpdateDate ? candidate.lastUpdateDate : ''}
                                        loadingHandler={setIsLoadingTab}
                                        errorHandler={setErrorMessage}
                                    />
                                </>
                            }
                        </>
                    }
                    { isFinishedSetup && compliance && activeTab === "Compliance" &&
                        <ComplianceGridComponent
                            gridName="candidates/Compliance"
                            candidateId={candidateId}
                            placementId={0}
                            checklistId={0}
                            loadingHandler={setIsLoadingTab}
                            errorHandler={setErrorMessage}
                            successHandler={setSuccessMessage}
                        />
                    }
                    { isFinishedSetup && canAccessActivities && activeTab === "CANDIDATES_CallsNotes" &&
                        <ActivitiesGridComponent
                            source="candidate-record"
                            sourceId={candidateId}
                            categoryFilter={1}
                            loadingHandler={ setIsLoadingTab }
                            errorHandler={ setErrorMessage }
                            successHandler={ setSuccessMessage }
                            gridName="candidates/CallsNotes"
                            refreshControl={refreshActivitiesGridControl}
                            hideActionsMenu
                        />
                    }
                    { isFinishedSetup && canAccessActivities && activeTab === "CANDIDATES_Meetings" &&
                        <MeetingsGridComponent
                            source="candidate-record"
                            sourceId={candidateId}
                            loadingHandler={ setIsLoadingTab }
                            errorHandler={ setErrorMessage }
                            successHandler={ setSuccessMessage }
                            gridName="candidates/Meetings"
                        />
                    }
                    { isFinishedSetup && canAccessActivities && activeTab === "CANDIDATES_Messages" &&
                        <ActivityMessagesGridComponent
                            source="candidate-record"
                            sourceId={candidateId}
                            errorHandler={ setErrorMessage }
                            loadingHandler={ setIsLoadingTab }
                            successHandler={ setSuccessMessage }
                            gridName="candidates/Messages"
                        />
                    }

                    { isFinishedSetup && canAccessJobs && activeTab === "CANDIDATES_Jobs" &&
                        <CandidateJobsGridComponent
                            candidateId={candidateId}
                            gridName="candidates/jobs"
                            errorHandler={ setTmpErrorMessage }
                            loadingHandler={ setIsLoadingTab }
                            successHandler={ setSuccessMessage }
                        />
                    }
                    { isFinishedSetup && canAccessActivities && canAccessJobs && activeTab === "CANDIDATES_Submissions" &&
                        <SubmissionsGridComponent
                            source="candidate-record"
                            sourceId={candidateId}
                            loadingHandler={ setIsLoadingTab }
                            errorHandler={ setErrorMessage }
                            successHandler={ setSuccessMessage }
                            gridName="candidates/Submissions"
                        />
                    }
                    { isFinishedSetup && canAccessActivities && canAccessJobs && activeTab === "CANDIDATES_Interviews" &&
                        <InterviewsGridComponent
                            source="candidate-record"
                            sourceId={candidateId}
                            loadingHandler={ setIsLoadingTab }
                            errorHandler={ setErrorMessage }
                            successHandler={ setSuccessMessage }
                            gridName="candidates/Interviews"
                        />
                    }
                    { isFinishedSetup && canAccessPlacements && activeTab === "CANDIDATES_Placements" &&
                        <PlacementsGridComponent
                            source="candidate-record"
                            sourceId={candidateId}
                            loadingHandler={setIsLoadingTab}
                            errorHandler={setErrorMessage}
                            successHandler={setSuccessMessage}
                            gridName="candidates/Placements"
                        />
                    }
                    { isFinishedSetup && activeTab === "CANDIDATES_Documents" &&
                        <DocumentsGridComponent
                            gridName="candidates/Documents"
                            source="candidate-record"
                            sourceId={candidateId}
                            loadingHandler={setIsLoadingTab}
                            errorHandler={setErrorMessage}
                            successHandler={setSuccessMessage}
                        />
                    }
                    { isFinishedSetup && canAccessActivities && activeTab === "CANDIDATES_Activities" &&
                        <ActivitiesGridComponent
                            source="candidate-record"
                            sourceId={candidateId}
                            loadingHandler={ setIsLoadingTab }
                            errorHandler={ setErrorMessage }
                            successHandler={ setSuccessMessage }
                            gridName="candidates/Activities"
                            refreshControl={refreshActivitiesGridControl}
                            hideActionsMenu
                        />
                    }
                </PageContentLayout>
            </PageLayout>
        </>
    );
}
