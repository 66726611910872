import React, { useEffect, useMemo, useState } from "react";
import { GridColDef, GridRenderCellParams, useGridApiRef } from "@mui/x-data-grid-premium";
import Button from "@mui/material/Button";

import { Group } from "common/models/Group";
import PageContentLayout from "layouts/PageContentLayout";
import { GetAllGroups } from "services/GroupsService";
import { Link } from "react-router-dom";
import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import GridWithStateWrapper from "components/GridWidthStateWrapper";

interface Props {
    setSummaryBar?: (summaryBar: JSX.Element) => void
}

const SummaryBar = (
    <TitleAndActionSummaryBar
        title="Configuration > Groups"
        browserTabTitle="Groups > Configuration"
        action={ <Button variant="contained" color="primary"><Link to="/configuration/groups/0" style={{ color: 'inherit', textDecoration: 'none' }}>Add Group</Link></Button> }
    />
);

export default function GroupsList({ setSummaryBar }: Props) {
    useEffect(() => { setSummaryBar && setSummaryBar(SummaryBar); }, [setSummaryBar]);
    const [isLoading, setIsLoading] = useState(false);
    const [rows, setRows] = useState<Group[]>([]);
    const apiRef = useGridApiRef();


    useEffect(() => {
        const getData = async () => {
            setIsLoading(true);

            const data = await GetAllGroups();

            if (data)
                setRows(data);

            setIsLoading(false);
        };
        getData();
    }, []);

    const columns = useMemo<GridColDef[]>(() => {
        const dbNameRenderer = (params: GridRenderCellParams) => {
            return (
                <Link
                    to={`/configuration/groups/${params.id}`}
                    style={{ color: 'inherit', textDecoration: 'underline' }}
                >{params.value}</Link>
            );
        };

        const statusRenderer = (params: GridRenderCellParams) => {
            if (params.value)
                return "Active";
            else
                return "Inactive";
        };

        return [
            { field: 'id', headerName: 'ID' },
            { field: 'name', headerName: 'Name', width: 300, renderCell: dbNameRenderer },
            { field: 'active', headerName: 'Status', width: 200, renderCell: statusRenderer }
        ];
    }, []);

    return (
        <>
            <PageContentLayout title="Groups" showLoading={isLoading}>
                <GridWithStateWrapper
                    gridName="configuration/groups"
                    apiRef={apiRef}
                    rows={rows}
                    columns={columns}
                    disableRowSelectionOnClick
                    pagination={true}
                    density="compact"
                    pageSizeOptions={[100, 250, 500, 1000]}
                />
            </PageContentLayout>
        </>
    );
}