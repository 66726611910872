import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {GridColDef, GridRenderCellParams, GridValueGetter, useGridApiRef} from "@mui/x-data-grid-premium";
import moment from "moment";
import React, { useCallback, useMemo, useState } from "react";
import { LoginTrackingReportData } from "common/models/Reports/LoginTrackingReportData";
import { GetLoginTrackingData } from "services/MiscReportsService";
import GridWithStateWrapper from "components/GridWidthStateWrapper";

interface Props {
    description: string,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void
}

export default function LoginTrackingReport({ description, loadingHandler, errorHandler }: Props) {
    const apiRef = useGridApiRef();
    const [rows, setRows] = useState<LoginTrackingReportData[]>([]);
    const [showGrid, setShowGrid] = useState(false);
    
    const getDataCallback = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        const res = await GetLoginTrackingData(errorHandler);
        if (res) {
            setShowGrid(true);
            setRows(res);
        }
        loadingHandler && loadingHandler(false);
    }, [errorHandler, loadingHandler]);

    const exportAsExcelHandler = useCallback(() => {
        const api = apiRef.current;
        if (api) {
            const m = moment();
            const filename = `LoginTracking${m.format('YYYYMMDDhhmmss')}`;
            api.exportDataAsExcel({ fileName: filename });
        }
    }, [apiRef]);

    const columns = useMemo<GridColDef[]>(() => {

        const dateValueGetter: GridValueGetter<LoginTrackingReportData, any, undefined, string> = (value) => {
            if (value) {
                const d = moment(value);
                if (d.isValid() && d.get('year') > 1) {
                    return d.toDate();
                }
            }
        }

        const dateCellRenderer = (params: GridRenderCellParams) => {
            let val = params.value;
            
            val = moment(val).format('DD MMM YYYY h:mm A');
            
            return val;
        }
        
        return [
            { field: 'id', width: 100 },
            { field: 'displayName', headerName: 'User', width: 250 },
            { field: 'ipAddress', headerName: 'IP Address', width: 200 },
            { field: 'website', headerName: 'Website', width: 250 },
            { field: 'loginDate', headerName: 'Login Date', headerAlign: 'center', align: 'center', width: 180, type: 'date', valueGetter: dateValueGetter, renderCell: dateCellRenderer },
        ];
    }, []);

    return (
        <>
            <Box display="flex">
                <Button variant="contained" color="success" onClick={getDataCallback}>Run Report</Button>
            </Box>
            <Typography component="div" variant="h6" mt={2}>Report Description</Typography>
            <Typography component="div" variant="body2">{description}</Typography>
            {showGrid &&
                <>
                    <Box pb="10px" ml="auto">
                        <Button variant="contained" color="success" onClick={exportAsExcelHandler}>Export As Excel</Button>
                    </Box>
                    <GridWithStateWrapper
                        density="compact"
                        gridName="reports-login-traking"
                        rows={rows}
                        columns={columns}
                        apiRef={apiRef}
                        getRowId={r => r.id}
                        disableRowSelectionOnClick
                    />
                </>
            }
        </>
    );
}