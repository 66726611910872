import { GridInitialStatePremium } from "@mui/x-data-grid-premium/models/gridStatePremium";
import { ErrorHandler, RequestOptions, StringValueWrapper } from "../common/models/GenericTypes";
import { Get, Put } from "../util/HttpUtils";

export async function GetGridState(gridName: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    const stringData = await Get<StringValueWrapper>(`api/grids/state?gridName=${encodeURIComponent(gridName)}`, options);
    if (stringData) {
        try {
            const gridState = JSON.parse(stringData.value) as GridInitialStatePremium;
            return gridState;
            
        } catch (error) {
            return null;
        }
    }
    
    return null;
}

export async function UpdateGridState(gridName: string, state: GridInitialStatePremium, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    const columnsAndSortState: GridInitialStatePremium = { columns: {...state.columns}, sorting: state.sorting ? state.sorting : undefined };
    const stringData: StringValueWrapper = { value: JSON.stringify(columnsAndSortState) };
    return Put<boolean>(`api/grids/state?gridName=${encodeURIComponent(gridName)}`, options, stringData);
}