import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { GridColDef } from "@mui/x-data-grid/models/colDef";
import { GridRenderCellParams } from "@mui/x-data-grid/models/params";
import { FilterWithTimeRange } from "common/models/Analytics/AnalyticsFilterData";
import { TopContactsByRatiosRecord } from "common/models/Analytics/BusinessIntelligence";
import GridWithStateWrapper from "components/GridWidthStateWrapper";
import React, { useEffect, useMemo, useState } from "react";
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { TopContactsByRatios } from "services/BusinessIntelligence";
import { TickWithHoverRenderer, VerticalBarChartYAxisWidth } from "util/AnalyticsGraphUtils";

interface Props {
    filterData: FilterWithTimeRange,
    graphType?: 'table' | 'graph',
    loadingHandler?: (isLoading: boolean) => void
}

interface TopContactsByRatiosRecordWithId extends TopContactsByRatiosRecord {
    id: number,
}

const gridName = 'analytics/top-contacts/by-ratios';

export default function TopContactsByRatiosContent({ filterData, graphType = 'graph', loadingHandler }: Props) {
    const [rows, setRows] = useState<TopContactsByRatiosRecordWithId[]>([]);
    const [selectedActivity, setSelectedActivity] = useState('submissionsToPlacementsRatio');
    const t = useTheme();

    useEffect(() => {
        const getData = async () => {
            loadingHandler && loadingHandler(true);
            const res = await TopContactsByRatios(filterData);
            if (res) {
                const data: TopContactsByRatiosRecordWithId[] = res.map((r, i) => ({ ...r, id: i }));
                setRows(data);
            }
            loadingHandler && loadingHandler(false);
        };
        getData();
    }, [filterData, loadingHandler]);

    const columns = useMemo<GridColDef[]>(() => {
        const percentRenderer = (params: GridRenderCellParams) => {
            return `${params.value} %`
        };

        return [
            { field: 'contact', headerName: 'Contacts', width: 250 },
            { field: 'submissions', headerName: 'Submissions', headerAlign: 'center', align: 'right', width: 150 },
            { field: 'firstInterviews', headerName: '1st Interviews', headerAlign: 'center', align: 'right', width: 150 },
            { field: 'secondInterviews', headerName: '2nd Interviews', headerAlign: 'center', align: 'right', width: 150 },
            { field: 'offers', headerName: 'Offers', headerAlign: 'center', align: 'right' },
            { field: 'placements', headerName: 'Placements', headerAlign: 'center', align: 'right' },
            { field: 'submissionsToFirstInterviewRatio', headerName: 'Submission to 1st Interview', headerAlign: 'center', align: 'right', renderCell: percentRenderer },
            { field: 'firstInterviewToSecondInterviewRatio', headerName: 'Submission to 2nd Interview', headerAlign: 'center', align: 'right', renderCell: percentRenderer },
            { field: 'firstInterviewToOfferRatio', headerName: '1st Interview to Offer', headerAlign: 'center', align: 'right', renderCell: percentRenderer },
            { field: 'secondInterviewToOfferRatio', headerName: '2nd Interview to Offer', headerAlign: 'center', align: 'right', renderCell: percentRenderer },
            { field: 'submissionsToPlacementsRatio', headerName: 'Submission to Placements', headerAlign: 'center', align: 'right', renderCell: percentRenderer },
            { field: 'firstInterviewToPlacementRatio', headerName: '1st Interview to Placement', headerAlign: 'center', align: 'right', renderCell: percentRenderer },
            { field: 'secondInterviewToPlacementRatio', headerName: '2nd Interview to Placement', headerAlign: 'center', align: 'right', renderCell: percentRenderer },
            { field: 'offersToPlacementsRatio', headerName: 'Offer to Placement', headerAlign: 'center', align: 'right', renderCell: percentRenderer },
        ];
    }, []);

    const sortedGraphData = useMemo(() => {
        if (graphType === 'graph') return rows.sort((a,b) => (b as any)[selectedActivity] - (a as any)[selectedActivity]);
        return [];
    }, [graphType, rows, selectedActivity]);

    if (graphType === 'table') {
        return (
            <GridWithStateWrapper
                gridName={gridName}
                rows={rows}
                density="compact"
                columns={columns}
                sx={{ mt: '20px' }}
            />
        );
    }
    else if (graphType === 'graph') {
        return (
            <>
                <Typography variant="h6" padding={1} textAlign="center" fontWeight={600} component="div">
                    Top 20 Contacts by 
                    <TextField variant="standard" select value={selectedActivity} onChange={({target}) => setSelectedActivity(target.value)} sx={{ ml: '10px' }}>
                        <MenuItem value="submissionsToFirstInterviewRatio">Submissions to 1st Interview</MenuItem>
                        <MenuItem value="firstInterviewToSecondInterviewRatio">1st Interview to 2nd Interview Ratio</MenuItem>
                        <MenuItem value="firstInterviewToOfferRatio">1st Interview to Offer Ratio</MenuItem>
                        <MenuItem value="secondInterviewToOfferRatio">2nd Interview to Offer Ratio</MenuItem>
                        <MenuItem value="submissionsToPlacementsRatio">Submission to Placements Ratio</MenuItem>
                        <MenuItem value="firstInterviewToPlacementRatio">1st Interview to Placement Ratio</MenuItem>
                        <MenuItem value="secondInterviewToPlacementRatio">2nd Interview to Placement Ratio</MenuItem>
                        <MenuItem value="offersToPlacementsRatio">Offer to Placement Ratio</MenuItem>
                    </TextField>
                </Typography>
                <div style={{ height: 'calc(100% - 48px)' }}>
                    <ResponsiveContainer width="100%" height="100%">
                        <BarChart width={150} height={150} layout="vertical" data={sortedGraphData} margin={{ right: 50 }}>
                            <CartesianGrid strokeDasharray="3 3" vertical />
                            <XAxis type="number" tickFormatter={v => `${v.toLocaleString()} %`} stroke={t.palette.text.primary} />
                            <YAxis dataKey="contact" type="category" tick={ TickWithHoverRenderer } stroke={t.palette.text.primary} width={VerticalBarChartYAxisWidth} minTickGap={0} interval={0} />
                            <Tooltip />
                            <Bar dataKey={selectedActivity} fill={t.palette.info.main} min={0} />
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </>
        );
    }

    return <></>;
}