import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { DatePicker, DateTimePicker } from "@mui/x-date-pickers-pro";
import moment from "moment";
import React, { useCallback, useMemo } from "react";
import {TimePicker} from "@mui/x-date-pickers/TimePicker";
import RWTextFieldComponent from "components/RWTextFieldComponent";

interface Props {
    fieldTitle: string,
    // fieldValue: string | number,
    fieldValue?: string,
    format?: 'string' | 'number' | 'date' | 'datetime' | 'select' | 'custom' | 'time',
    multiline?: boolean,
    fieldName?: string,
    isError?: boolean,
    errorMessage?: string,
    disabled?: boolean,
    onChangeHandler?: (e: React.ChangeEvent<HTMLInputElement>) => void,
    onDateChangeHandler?: (m: moment.Moment | null, fieldName: string) => void
}

export default function EditableSingleFieldElement({fieldTitle, fieldValue, fieldName, disabled, format = 'string', multiline = false, isError = false, errorMessage = '', children, onChangeHandler = () => {}, onDateChangeHandler}: React.PropsWithChildren<Props>) {
    // const dateIsoFormatString = useMemo(() => {
    //     if (format === 'date') return 'YYYY-MM-DD';
    //     if (format === 'datetime') return 'YYYY-MM-DDThh:mm:ss';
    //     return '';
    // }, [format]);
    
    const handleDateChange = useCallback((m: moment.Moment | null) => {
        onDateChangeHandler && fieldName && onDateChangeHandler(m, fieldName);
    }, [fieldName, onDateChangeHandler]);


    const value = useMemo(() => {
        switch (format) {
            case 'custom': return children;
            case 'select':
                return  (
                    <TextField select fullWidth variant="standard" value={fieldValue} name={fieldName} onChange={ onChangeHandler } error={isError}>
                        {children}
                    </TextField>
                );
            case 'string':
                if (multiline) {
                    return (
                        <RWTextFieldComponent 
                            variant="standard"
                            multiline={true}
                            name={fieldName ?? ''}
                            onChange={onChangeHandler}
                            value={fieldValue ?? ''}
                            isError={isError} />
                    );
                }
                return <RWTextFieldComponent 
                variant="standard"
                multiline={true}
                name={fieldName ?? ''}
                onChange={onChangeHandler}
                value={fieldValue ?? ''}
                isError={isError} />
                
                
            case 'number':
                return <RWTextFieldComponent 
                variant="standard"
                   multiline={true}
                   name={fieldName ?? ''}
                   onChange={onChangeHandler}
                   value={fieldValue ?? ''}
                   isError={isError} />
            case 'date':
                let md = !Boolean(fieldValue) || fieldValue === '0001-01-01T00:00:00' ? null : moment(fieldValue);
                if (onDateChangeHandler) return <DatePicker value={md} onChange={handleDateChange} disabled={disabled} slotProps={{ textField: (props) => ({...props, fullWidth: true, variant: 'standard', error: isError}), actionBar: { actions: ["clear", "today", "cancel", "accept"] } }} />
                return <></>;
            case 'datetime':
                let mdt = !Boolean(fieldValue) || fieldValue === '0001-01-01T00:00:00' ? null : moment(fieldValue);
                if (onDateChangeHandler) return <DateTimePicker value={mdt} onChange={handleDateChange} disabled={disabled} slotProps={{ textField: (props) => ({...props, fullWidth: true, variant: 'standard', error: isError}), actionBar: { actions: ["clear", "today", "cancel", "accept"] } }} />
                return <></>;
            case 'time':
                let time = !Boolean(fieldValue) || fieldValue === '0001-01-01T00:00:00' ? null : moment(fieldValue);
                if (onDateChangeHandler) return <TimePicker value={time} onChange={handleDateChange} disabled={disabled} slotProps={{ textField: (props) => ({...props, fullWidth: true, variant: 'standard', error: isError}), actionBar: { actions: ["clear", "today", "cancel", "accept"] } }} />
                return <></>;
            default:
                break;
        }
    }, [children, format, isError, multiline, fieldName, fieldValue, disabled, onChangeHandler, onDateChangeHandler, handleDateChange]);

    if (fieldTitle.length > 20) {
        return (
            <Box display="flex" flexDirection="column" height="100%" minHeight="40px" maxHeight="100%" px="5px">
                <Box display="flex" component="div" flexDirection="column">
                    <Typography component="div" color={isError ? 'error' : undefined} variant="body1" fontWeight={600} sx={{ userSelect: 'text' }}>
                        { fieldTitle }
                    </Typography>
                    { isError && errorMessage && <Typography component="div" color="error" variant="caption" sx={{ userSelect: 'text' }}>{errorMessage}</Typography> }
                </Box>
                <Box width="100%" overflow={multiline ? 'auto' : undefined}>
                    {value}
                </Box>
            </Box>
        );
    }

    return (
        <Box display="flex" height="100%" maxHeight="100%" px="5px">
            <Box height="100%" width="30%" display="flex" component="div" flexDirection="column">
                <Typography component="div" color={isError ? 'error' : undefined} variant="body1" fontWeight={600} sx={{ userSelect: 'text' }}>
                    { fieldTitle }
                </Typography>
                { isError && errorMessage && <Typography component="div" color="error" variant="caption" sx={{ userSelect: 'text' }}>{errorMessage}</Typography> }
            </Box>
            <Box height="100%" width="70%" overflow={multiline ? 'auto' : undefined}>
                {value}
            </Box>
        </Box>
    );
}