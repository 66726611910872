import React, { useCallback, useEffect, useMemo, useState } from "react";
import ConfirmationDialog from "../Generic/ConfirmationDialog";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import moment from "moment";
import { PlacementStatusEnum } from "util/Definitions/Configuration/Placements";
import MenuItem from "@mui/material/MenuItem";
import { DatePicker } from "@mui/x-date-pickers-pro";
import { TerminatePlacement } from "services/PlacementsService";
import RWTextFieldComponent from "components/RWTextFieldComponent";

interface Props {
    open: boolean,
    placementId: number,
    candidateName?: string,
    jobTitle?: string,
    startDate: string,
    endDate: string,
    placementStatusId?: number,
    closeHandler: () => void,
    loadingHandler?: (isLoading: boolean) => void,
    successHandler?: (message: string) => void,
    errorHandler?: (message: string) => void
}

export default function EarlyTerminateDialog({ open, placementId, candidateName = '', jobTitle = '', endDate: endDateProp, startDate: startDateProp, placementStatusId = 0, closeHandler, loadingHandler, successHandler, errorHandler }: Props) {
    const [showWarning, setShowWarning] = useState(false);
    const [reasonId, setReasonId] = useState(0);
    const [endDate, setEndDate] = useState<moment.Moment | null>(null);
    const [comments, setComments] = useState('');

    const warningText = useMemo(() => {
        if (placementStatusId === PlacementStatusEnum.Completed) return 'This placement is already Completed. Are you sure you want to make this change?';
        if (placementStatusId === PlacementStatusEnum.PendingEarlyTermination || placementStatusId === PlacementStatusEnum.EarlyTerminated) return 'This placement is already Terminated. Are you sure you want to make this change?';
        return '';
    }, [placementStatusId]);

    const startDateMoment = useMemo(() => {
        if (startDateProp && startDateProp !== '0001-01-01T00:00:00') {
            const m = moment(startDateProp);
            if (m.isValid()) return m;
        }
        return null;
    }, [startDateProp]);

    const endDateMoment = useMemo(() => {
        if (endDateProp && endDateProp !== '0001-01-01T00:00:00') {
            const m = moment(endDateProp);
            if (m.isValid()) return m;
        }
        return null;
    }, [endDateProp]);

    useEffect(() => {
        if (open && Boolean(warningText)) setShowWarning(true);
    }, [open, warningText]);

    useEffect(() => {
        if (open) {
            setReasonId(0);
            setEndDate(null);
            setComments('');
        }
    }, [open]);

    const currentDate = useMemo(() => {
        return moment().format('DD MMM YYYY');
    }, []);

    const submitCallback = useCallback(async () => {
        const m = moment(endDate);
        if (m.isSame(endDateMoment)) {
            errorHandler && errorHandler(`Please verify selected date as that Placement is due to end [ ${m.format('DD MMM YYYY')} ]`);
            return false;
        }
        if (startDateMoment && startDateMoment.isAfter(m)) {
            errorHandler && errorHandler('The End Date must be greater than Start Date');
            return false;
        }
        if (endDateMoment && endDateMoment.isBefore(m)) {
            errorHandler && errorHandler('The new End Date must be before the current End Date');
            return false;
        }

        loadingHandler && loadingHandler(true);
        const stringEndDate = endDate && endDate.isValid() ? endDate.format('YYYY-MM-DD') : '';
        const res = await TerminatePlacement(placementId, reasonId, stringEndDate, comments, errorHandler)
        if (res) {
            successHandler && successHandler('Placement Terminated');
            closeHandler();
        }
        loadingHandler && loadingHandler(false);
    }, [placementId, reasonId, endDate, comments, startDateMoment, endDateMoment, loadingHandler, successHandler, errorHandler, closeHandler]);
    
    return (
        <>
            <ConfirmationDialog
                open={open && showWarning && Boolean(warningText)}
                message={warningText}
                onClose={closeHandler}
                title="Termination"
                onContinue={() => setShowWarning(false)}
            />
            <Dialog open={open && !showWarning} fullWidth>
                <DialogTitle>Termination</DialogTitle>
                <DialogContent>
                    <Stack mt="10px" spacing={2}>
                        <TextField label="Placement ID" value={placementId} InputProps={{ readOnly: true }} />
                        <TextField label="Candidate" value={candidateName} InputProps={{ readOnly: true }} />
                        <TextField label="Job Title" value={jobTitle} InputProps={{ readOnly: true }} />
                        <TextField label="Date" value={currentDate} InputProps={{ readOnly: true }} />
                        <TextField select label="Reason" value={reasonId.toString()} onChange={({target}) => setReasonId(+target.value)}>
                            <MenuItem value="0">None</MenuItem>
                            <MenuItem value="5">Administrative</MenuItem>
                            <MenuItem value="1">Terminated By Candidate</MenuItem>
                            <MenuItem value="3">Terminated By Client</MenuItem>
                            <MenuItem value="2">Terminated Due To Warranty</MenuItem>
                        </TextField>
                        <DatePicker label="End Date" value={endDate} onChange={m => setEndDate(m)} slotProps={{actionBar: { actions: ["clear", "today", "cancel", "accept"] }}} />
                        <RWTextFieldComponent label="Comments" value={comments} onChange={({target}) => setComments(target.value)} rows={5} multiline />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="error" onClick={ closeHandler }>Cancel</Button>
                    <Button variant="contained" color="success" disabled={!Boolean(endDate)} onClick={ submitCallback } >Submit</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}