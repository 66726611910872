import React, { useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import TextField from "@mui/material/TextField";

import { TimeZone } from "common/models/Common";
import { TimeZoneLookup } from "services/CommonService";

interface Props {
    onSelectCallback: (tz: TimeZone | null) => void
    value?: TimeZone | null,
    delayMs?: number,
}

export default function ({ delayMs = 200, value = null, onSelectCallback }: Props) {
    const [lookupTerm, setLookupTerm] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState<TimeZone[]>([]);

    useEffect(() => {
        if (lookupTerm.length > 0) {
            const delaySearch = setTimeout(async () => {
                setIsLoading(true);
                const options = await TimeZoneLookup(lookupTerm);
                if(options) setOptions(options);
                setIsLoading(false);
            }, delayMs);
    
            return () => clearTimeout(delaySearch);
        }
    }, [lookupTerm, delayMs]);

    return (
        <Autocomplete
            size="small"
            value={value}
            options={options}
            getOptionLabel={o => o.zoneName}
            isOptionEqualToValue={(o,v) => o.zoneName === v.zoneName}
            loading={isLoading}
            filterOptions={ x => x }
            onChange={ (e,v) => onSelectCallback(v) }
            renderInput={ (params) => (
                <TextField
                    {...params}
                    label="Time Zone"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {isLoading ? <CircularProgress color="inherit" size={20} /> : params.InputProps.endAdornment}
                                {/* {params.InputProps.endAdornment} */}
                            </React.Fragment>
                        )
                    }}
                    inputProps={{ ...params.inputProps, "data-lpignore": "true" }}
                />
            ) }
            onInputChange={ (e, val) => setLookupTerm(val) }
            renderOption={(props, option) => (
                <ListItem {...props}>
                    <ListItemText
                        primary={option.zoneName}
                        secondary={`${option.countryName} - GMT ${option.gmtOffsetHours >= 0 ? '+' + option.gmtOffsetHours : option.gmtOffsetHours}`}
                    />
                </ListItem>
            )}
        />
    );
}