import React, { useCallback, useEffect, useMemo, useState } from "react";
import { DeleteAdvertTemplate, GetMyTemplateAdverts } from "services/AdvertsService";
import { GridColDef } from "@mui/x-data-grid/models/colDef/gridColDef";
import moment from "moment";
import { Link } from "react-router-dom";
import { PreviewEntityType } from "common/models/Previews/Previews";
import PreviewLoaderComponent from "../Previews/PreviewLoader";
import GridWithStateWrapper from "../GridWidthStateWrapper";
import { AdvertTemplate } from "common/models/Advert";
import { useGridApiRef } from "@mui/x-data-grid-premium/hooks/utils/useGridApiRef";
import Box from "@mui/material/Box";
import ConfirmationDialog from "../Dialogs/Generic/ConfirmationDialog";
import { GridRenderCellParams, GridValueGetter } from "@mui/x-data-grid-premium";

interface Props {
    gridName: string,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
    successHandler?: (message: string) => void,
}

const linkStyle: React.CSSProperties = { color: 'inherit', textDecoration: 'underline' };

export default function AdvertTemplatesGridComponent({gridName, loadingHandler, errorHandler, successHandler }: Props) {
    const [rows, setRows] = useState<AdvertTemplate[]>([]);
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    const [previewType, setPreviewType] = useState<PreviewEntityType | ''>('');
    const [previewRecordId, setPreviewRecordId] = useState(0);
    const [templateToDelete, setTemplateToDelete] = useState<number | null>(null);
    const apiRef = useGridApiRef();
    
    const getRowsCallback = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        const res = await GetMyTemplateAdverts(errorHandler);
        if (res) setRows(res);
        loadingHandler && loadingHandler(false);
    }, [loadingHandler, errorHandler]);

    useEffect(() => {
        getRowsCallback();
    }, [getRowsCallback]);

    const deleteTemplateCallback = useCallback(async () => {
        if (templateToDelete) {
            loadingHandler && loadingHandler(true);
            const res = await DeleteAdvertTemplate(templateToDelete, errorHandler);
            if (res) {
                successHandler && successHandler('Template Deleted');
                setRows(prev => {
                    let tmp = [...prev];
                    const i = tmp.findIndex(t => t.id === templateToDelete);
                    tmp.splice(i, 1);
                    setTemplateToDelete(null);
                    return tmp;
                });
            }
            loadingHandler && loadingHandler(false);
        }
    }, [templateToDelete, loadingHandler, errorHandler, successHandler]);

    const columns = useMemo<GridColDef[]>(() => {
        const dateValueGetter: GridValueGetter<AdvertTemplate, any, undefined, string> = (value) => {
            if (value) {
                const m = moment(value);
                if (m.isValid() && m.get('year') > 1) {
                    return m.toDate();
                }
            }
        };

        const dateRenderer = (params: GridRenderCellParams) => {
            if (params.value) {
                return moment(params.value).format('DD MMM YYYY');
            }
        };

        const linkToAdvertRenderer = (params: GridRenderCellParams) => {
            if (params.value) {
                return <Link target="_blank" to={`/adverts/${params.row.advertID}`} style={ linkStyle } onMouseEnter={ () => handlePreviewHover('advert', params.row.advertID) } onMouseLeave={ handlePreviewClose } >{params.value}</Link>;
            }
            return params.value;
        };

        const deleteActionRenderer = (params: GridRenderCellParams) => {
            return <Box onClick={ () => setTemplateToDelete(+params.id) } sx={{ textDecoration: 'underline', cursor: 'pointer' }}>Delete</Box>;
        };

        const editActionRenderer = (params: GridRenderCellParams) => {
            return <Link target="_blank" to={`/adverts/${params.row.advertID}/edit`} style={ linkStyle }>Edit</Link>;
        };

        const handlePreviewHover = (type: PreviewEntityType | '', id: number) => {
            setPreviewType(type);
            setPreviewRecordId(id);
            setIsPreviewOpen(true);
        };

        const handlePreviewClose = () => {
            setIsPreviewOpen(false);
        };
        
        return [
            { headerName: 'ID', field: 'id', width: 75 },
            { headerName: 'Name', field: 'name', width: 450, renderCell: linkToAdvertRenderer },
            { headerName: 'Owner', field: 'userFullName', width: 450 },
            { headerName: 'Created Date', field: 'dateCreated', align: 'center', headerAlign: 'center', width: 110, type: 'date', valueGetter: dateValueGetter, renderCell: dateRenderer },
            { headerName: 'Edit', field: 'action', align: 'center', headerAlign: 'center', width: 100, renderCell: editActionRenderer },
            { headerName: 'Delete', field: 'userId', align: 'center', headerAlign: 'center', width: 100, renderCell: deleteActionRenderer }
        ];
    }, []);


    return (
        <>
            <PreviewLoaderComponent
                open={isPreviewOpen}
                entityType={previewType}
                recordId={previewRecordId}
            />
            <ConfirmationDialog
                open={Boolean(templateToDelete)}
                message="Are you sure you want to delete this Template?"
                title="Confirm Action"
                onContinue={ deleteTemplateCallback }
                onClose={ () => setTemplateToDelete(null) }
            />
            <GridWithStateWrapper
                gridName={gridName}
                rows={rows}
                columns={columns}
                apiRef={apiRef}
                density="compact"
                disableRowSelectionOnClick
                pagination
                pageSizeOptions={[100,250,500,1000]}
            />
        </>
    );
}