import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { CandidateWorkHistory } from "common/models/Candidates/Candidate";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { CreateCandidateWorkHistory, GetCandidateSingleWorkHistory, UpdateCandidateWorkHistory } from "services/CandidatesService";

interface Props {
    candidateId: number,
    open: boolean,
    workHistoryId?: number,
    closeHandler: () => void,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
    successHandler?: (message: string) => void,
}

export default function EditWorkHistoryDialog({ open, candidateId, workHistoryId = 0, closeHandler, loadingHandler, errorHandler, successHandler }: Props) {
    const [title, setTitle] = useState('');
    const [orgName, setOrgName] = useState('');
    const [description, setDescription] = useState('');
    const [jobTypeId, setJobTypeId] = useState(0);
    const [startDate, setStartDate] = useState<moment.Moment | null>(null);
    const [endDate, setEndDate] = useState<moment.Moment | null>(null);

    const dialogTitle = useMemo(() => workHistoryId === 0 ? 'Add Work History' : 'Edit Work History', [workHistoryId]);

    useEffect(() => {
        if (!open) {
            setTitle('');
            setOrgName('');
            setDescription('');
            setJobTypeId(0);
            setStartDate(null);
            setEndDate(null);
        }
    }, [open]);

    useEffect(() => {
        const getExistingWorkHistory = async () => {
            loadingHandler && loadingHandler(true);
            const res = await GetCandidateSingleWorkHistory(candidateId, workHistoryId, errorHandler);
            if (res) {
                setTitle(res.title);
                setOrgName(res.organizationName);
                setJobTypeId(res.jobTypeID);
                setDescription(res.description);
                if (res.startDate) {
                    const m1 = moment(res.startDate);
                    if (m1.isValid()) setStartDate(m1);
                }
                if (res.endDate) {
                    const m2 = moment(res.endDate);
                    if (m2.isValid()) setEndDate(m2);
                }

            }
            loadingHandler && loadingHandler(false);
        };
        open && workHistoryId && getExistingWorkHistory();
    }, [candidateId, errorHandler, loadingHandler, open, workHistoryId]);

    const submitCallback = useCallback(async () => {
        let data: Partial<CandidateWorkHistory> = {
            title: title,
            organizationName: orgName,
            description: description,
            jobTypeID: jobTypeId,
        };

        if (startDate && startDate.isValid()) data.startDate = startDate.format('YYYY-MM-DD');
        if (endDate && endDate.isValid()) data.endDate = endDate.format('YYYY-MM-DD');

        if (workHistoryId === 0) {
            loadingHandler && loadingHandler(true);
            const res = await CreateCandidateWorkHistory(candidateId, data, errorHandler);
            if (res) {
                loadingHandler && loadingHandler(false);
                successHandler && successHandler('Work History Created');
                closeHandler();
            }
        }
        else {
            loadingHandler && loadingHandler(true);
            const res = await UpdateCandidateWorkHistory(candidateId, workHistoryId, data, errorHandler);
            if (res) {
                loadingHandler && loadingHandler(false);
                successHandler && successHandler('Saved Changes');
                closeHandler();
            }
        }
    }, [candidateId, closeHandler, description, endDate, errorHandler, jobTypeId, loadingHandler, orgName, startDate, successHandler, title, workHistoryId]);
    
    return (
        <Dialog open={open} fullWidth>
            <DialogTitle>{dialogTitle}</DialogTitle>
            <DialogContent>
                <Stack spacing={2} pt={2}>
                    <TextField label="Title" value={title} onChange={({target}) => setTitle(target.value)} />
                    <TextField label="Organization Name" value={orgName} onChange={({target}) => setOrgName(target.value)} />
                    <TextField select label="Job Type" value={jobTypeId.toString()} onChange={({target}) => setJobTypeId(+target.value)}>
                        <MenuItem value="0">Unspecified</MenuItem>
                        <MenuItem value="2">Permanent</MenuItem>
                        <MenuItem value="3">Contract/Temp</MenuItem>
                    </TextField>
                    <DatePicker label="Start Date" value={startDate} onChange={setStartDate} />
                    <DatePicker label="End Date" value={endDate} onChange={setEndDate} />
                    <TextField label="Description" value={description} multiline rows={6} onChange={({target}) => setDescription(target.value)} />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={ closeHandler }>Cancel</Button>
                <Button variant="contained" color="success" disabled={title === ''} onClick={ submitCallback } >Submit</Button>
            </DialogActions>
        </Dialog>
    );
}