import React, { useCallback, useEffect, useMemo, useState } from "react";
import { RecordExportData } from "common/models/Common";
import { ExportRecords_CampaignMonitor, GetLists_CampaignMonitor } from "services/CustomerIntegrationsService";
import ExportRecordsValidationDialog from "./ExportRecordsValidationDialog";
import CustomContentConfirmationDialog from "./Generic/CustomContentConfirmationDialog";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { CampaignMonitorClient, CampaignMonitorList } from "common/models/CampaignMonitor";
import RWTextFieldComponent from "components/RWTextFieldComponent";

interface Props {
    open: boolean,
    records: RecordExportData[],
    closeHandler: () => void,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
    successHandler?: (message: string) => void
}

export default function ExportRecordsToCampaignMonitorDialog({ open, records, closeHandler, loadingHandler, errorHandler, successHandler }: Props) {
    const [fetchedLists, setFetchedLists] = useState(false);
    const [showValidationDialog, setShowValidationDialog] = useState(true);
    const [campaignMonitorLists, setCampaignMonitorLists] = useState<CampaignMonitorList[]>([]);
    const [campaignMonitorClients, setCampaignMonitorClients] = useState<CampaignMonitorClient[]>([]);
    const [selectedClient, setSelectedClient] = useState("0");
    const [selectedList, setSelectedList] = useState("0");
    const [newListName, setNewListName] = useState("");

    useEffect(() => {
        const getLists = async () => {
            const res = await GetLists_CampaignMonitor(errorHandler);
            if (res) {
                setCampaignMonitorClients(res.clients);
                setCampaignMonitorLists(res.lists);
                setFetchedLists(true);
            }
        };
        open && !fetchedLists && getLists();
    }, [open, fetchedLists, errorHandler]);


    const invalidRecords = useMemo(() => {
        return records.filter(r => r.id && !r.isOptOut && !Boolean(r.email));
    }, [records]);

    const noContactRecords = useMemo(() => {
        return records.filter(r => !Boolean(r.id));
    }, [records]);

    const optOutRecords = useMemo(() => {
        return records.filter(r => r.isOptOut);
    }, [records]);

    const validRecords = useMemo(() => {
        return records.filter(r => r.id && !r.isOptOut && Boolean(r.email));
    }, [records]);

    const continueCallback = useCallback(async () => {
        setShowValidationDialog(false);
    }, []);

    const onClientChangeCallback = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setSelectedClient(value);
        setSelectedList("0");
    }, []);

    const onCloseCallback = useCallback(() => {
        closeHandler();
        setSelectedClient("0");
        setSelectedList("0");
        setNewListName("");
        setShowValidationDialog(true);
    }, [closeHandler]);

    const onConfirmExportCallback = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        const recordData: Partial<RecordExportData>[] = validRecords.map(r => ({ 
            id: r.id,
            firstName: r.firstName,
            lastName: r.lastName,
            email: r.email 
        }));
        
        const res = await ExportRecords_CampaignMonitor(selectedClient, selectedList, recordData, newListName, errorHandler);
        if (res) {
            successHandler && successHandler("Records exported to Campaign Monitor");
            onCloseCallback();
        }
        loadingHandler && loadingHandler(false);
    }, [selectedClient, selectedList, validRecords, newListName, loadingHandler, errorHandler, successHandler, onCloseCallback]);

    const listsByClient = useMemo<CampaignMonitorList[]>(() => {
        return campaignMonitorLists.filter(l => l.clientID === selectedClient);
    }, [selectedClient, campaignMonitorLists]);

    return (
        <>
            <ExportRecordsValidationDialog
                open={open && showValidationDialog}
                closeHandler={onCloseCallback}
                continueHandler={continueCallback}
                entityTypeId={2}
                invalidEmailRecords={invalidRecords}
                noPrimaryContactRecords={noContactRecords}
                optOutRecords={optOutRecords}
                validRecords={validRecords}
                totalRecords={records}
            />
            <CustomContentConfirmationDialog
                fullWidth
                open={open && !showValidationDialog}
                cancelHandler={onCloseCallback}
                confirmHandler={ onConfirmExportCallback }
                title="Export to Campaign Monitor"
                isConfirmButtonDisabled={selectedClient === "0" || (selectedList === "0" && !Boolean(newListName))}
            >
                <Stack spacing={2} mt="10px">
                    <TextField select label="Campaign Monitor Client" value={selectedClient} onChange={ onClientChangeCallback }>
                        <MenuItem value="0">None</MenuItem>
                        { campaignMonitorClients.map(c => <MenuItem value={c.clientID}>{c.name}</MenuItem>) }
                    </TextField>
                    <TextField select label="Campaign Monitor List" value={selectedList} onChange={ ({target}) => setSelectedList(target.value) } disabled={selectedClient === '0'} >
                        <MenuItem value="0">Create New List</MenuItem>
                        { listsByClient.map(l => <MenuItem value={l.listID}>{l.name}</MenuItem>) }
                    </TextField>
                    { selectedList === "0" && selectedClient !== "0" &&
                        <RWTextFieldComponent label="New List Name" value={newListName} onChange={ (e) => setNewListName(e.target.value) } />
                    }
                </Stack>
            </CustomContentConfirmationDialog>
        </>
    );
}