import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { GridColDef, GridRenderCellParams, GridRowSelectionModel, useGridApiRef } from "@mui/x-data-grid-premium";
import { User } from "common/models/Configuration/User";
import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import PageContentLayout from "layouts/PageContentLayout";
import { DisableUsers, ForcePasswordChange, GetActiveUsers, ResendWelcomeEmail } from "services/UsersService";
import MenuItem from "@mui/material/MenuItem";
import Alert from "components/Alert";
import Snackbar from "@mui/material/Snackbar";
import ConfirmationDialog from "components/Dialogs/Generic/ConfirmationDialog";
import TransferRecordsDialog from "components/Dialogs/UsersAdmin/TransferRecordsDialog";
import GridWithStateWrapper from "components/GridWidthStateWrapper";
import ActionsDropDownButton from "components/SummaryBars/Actions/ActionsDropsDownMenu";
import { userHasSinglePermission } from "util/PermissionsUtils";
import { Permission } from "common/models/Permissions";

interface Props {
    setSummaryBar?: (summaryBar: JSX.Element) => void
}

type UsersListDialogTypes = null | 'disable' | 'forceChangePassword' | 'transfer' | 'resendWelcomeEmail';
const addUserLink = <Link to="/configuration/users/add" style={{ color: 'inherit', textDecoration: 'none', padding: '6px 16px', flexGrow: 1 }}>Add User</Link>;

export default function UsersList({ setSummaryBar }: Props) {
    const [isLoading, setIsLoading] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showTransferSuccess, setShowTransferSuccess] = useState(false);
    const [showSendEmailSuccess, setShowSendEmailSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
    const [rows, setRows] = useState<User[]>([]);
    const apiRef = useGridApiRef();
    const [activeDialog, setActiveDiaog] = useState<UsersListDialogTypes>(null);

    const isUserGlobalAdmin = useMemo(() => userHasSinglePermission(Permission.GlobalAdministrator), []);

    useEffect(() => {
        const handleShowDialog = (dialogType: UsersListDialogTypes) => setActiveDiaog(dialogType);
        const actionMenu = (
            <ActionsDropDownButton>
                <MenuItem sx={{ p: 0 }}>{addUserLink}</MenuItem>
                <MenuItem disabled={ selectionModel.length === 0 } onClick={ () => handleShowDialog('disable') } >Disable</MenuItem>
                <MenuItem disabled={ selectionModel.length !== 1 } onClick={ () => handleShowDialog('forceChangePassword') } >Force Password Reset</MenuItem>
                <MenuItem disabled={ selectionModel.length === rows.length } onClick={ () => handleShowDialog('transfer') } >Transfer Records</MenuItem>
                <MenuItem disabled={ selectionModel.length === 0 } onClick={ () => handleShowDialog('resendWelcomeEmail') } >Resend Welcome Email</MenuItem>
            </ActionsDropDownButton>
        );

        const SummaryBar = (
            <TitleAndActionSummaryBar
                title="Configuration > Users"
                browserTabTitle="Users > Configuration"
                action={actionMenu}
            />
        );

        setSummaryBar && setSummaryBar(SummaryBar);
    }, [setSummaryBar, selectionModel.length, rows.length]);



    useEffect(() => {
        const getData = async () => {
            setIsLoading(true);
            const data = await GetActiveUsers();
            if (data) setRows(data);
            setIsLoading(false);
        };
        getData();
    }, []);

    const columns = useMemo<GridColDef[]>(() => {
        const dbNameRenderer = (params: GridRenderCellParams) => {
            return (
                <Link
                    to={`/configuration/users/${params.id}/edit`}
                    style={{ color: 'inherit', textDecoration: 'underline' }}
                >{params.value}</Link>
            );
        };

        const c = [
            { field: 'id', headerName: 'ID' },
            { field: 'displayName', headerName: 'Display Name', width: 300, renderCell: dbNameRenderer },
            { field: 'firstName', headerName: 'First Name', width: 200 },
            { field: 'lastName', headerName: 'Last Name', width: 200 },
            { field: 'userName', headerName: 'Username', width: 400 },
        ];
        
        if (isUserGlobalAdmin) return [...c, { field: 'configurationManager', type: 'boolean', headerName: 'Config Manager', width: 400 }];
        return c;
    }, [isUserGlobalAdmin]);

    const disableUsersCallback = useCallback(async () => {
        setIsLoading(true);
        const res = await DisableUsers(selectionModel as number[], setErrorMessage);
        if (res) {
            setRows(prev => {
                let newRows = [...prev];
                for (let i = 0; i < selectionModel.length; i++) {
                    const rowId = selectionModel[i].valueOf() as number;
                    const index = newRows.findIndex(r => r.id === rowId);
                    if(index !== -1) newRows.splice(index, 1);
                }
                setSelectionModel([]);
                return newRows;
            });

            setShowSuccess(true);
            setActiveDiaog(null);
        }
        setIsLoading(false);
    }, [selectionModel]);

    const forceChangePasswordCallback = useCallback(async () => {
        setIsLoading(true);
        if (selectionModel.length === 1) {
            const userId = selectionModel[0] as number;
            const res = await ForcePasswordChange(userId, setErrorMessage);
            if (res) {
                setSelectionModel([]);
                setShowSuccess(true);
                setActiveDiaog(null);
            }
        }
        setIsLoading(false);
    }, [selectionModel]);

    const resendWelcomeEmailCallback = useCallback(async () => {
        setIsLoading(true);
        const res = await ResendWelcomeEmail(selectionModel as number[], setErrorMessage);
        if (res) {
            setShowSendEmailSuccess(true);
            setSelectionModel([]);
            setActiveDiaog(null);
        }
        setIsLoading(false);
    }, [selectionModel]);

    const selectedUsers = useMemo(() => {
        let selectedUsers: User[] = [];
        for (let i = 0; i < selectionModel.length; i++) {
            const id = selectionModel[i].valueOf() as number;
            const match = rows.find(u => u.id === id);
            if (match) selectedUsers.push(match);
        }
        return selectedUsers;
    }, [selectionModel, rows]);

    const transferSuccessHandler = () => {
        setShowTransferSuccess(true);
        setSelectionModel([]);
        setActiveDiaog(null);
    }

    return (
        <>
            <Snackbar open={showTransferSuccess} anchorOrigin={{ horizontal: 'center', vertical: 'top' }} onClose={() => setShowTransferSuccess(false)}>
                <Alert onClose={() => setShowTransferSuccess(false)}>Transfer request logged. An email confirmation will be sent once processing is complete</Alert>
            </Snackbar>
            <Snackbar open={showSendEmailSuccess} anchorOrigin={{ horizontal: 'center', vertical: 'top' }} onClose={() => setShowSendEmailSuccess(false)}>
                <Alert onClose={() => setShowSendEmailSuccess(false)}>Email sent to the selected user(s)</Alert>
            </Snackbar>
            <Snackbar open={showSuccess} autoHideDuration={3000} onClose={() => setShowSuccess(false)}>
                <Alert onClose={() => setShowSuccess(false)}>Changes Saved</Alert>
            </Snackbar>
            <Snackbar open={errorMessage !== ''} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <ConfirmationDialog
                open={ activeDialog === 'disable' }
                title={ selectionModel.length === 1 ? 'Disable User' : 'Disable Users' }
                message={ selectionModel.length === 1
                    ? 'Are you sure you want to disable this user?'
                    : `Are you sure you want to disable the selected users (${selectionModel.length})?`
                }
                onClose={ () => setActiveDiaog(null) }
                onContinue={disableUsersCallback}
                confirmActionText="Disable"
                fullWidth
                dividers
            />
            <ConfirmationDialog
                open={ activeDialog === 'forceChangePassword' }
                title="Confirm Action"
                message="Are you sure you want to force this user to change their password?"
                onClose={ () => setActiveDiaog(null) }
                onContinue={forceChangePasswordCallback}
                confirmActionText="Yes"
                cancelActionText="No"
                fullWidth
                dividers
            />
            { activeDialog === 'resendWelcomeEmail' &&
                <ConfirmationDialog
                    open={ true }
                    title="Resend Welcome Email"
                    message={ selectionModel.length === 1
                        ? 'Are you sure you want to resend the welcome email to this user?'
                        : `Are you sure you want to resend the welcome email to the selected users (${selectionModel.length})?`
                    }
                    onClose={ () => setActiveDiaog(null) }
                    onContinue={resendWelcomeEmailCallback}
                    confirmActionText="Resend"
                    fullWidth
                    dividers
                />
            }
            {activeDialog === 'transfer' &&
                <TransferRecordsDialog
                    open={true}
                    setErrorMessage={setErrorMessage}
                    loadingHandler={setIsLoading}
                    successHandler={transferSuccessHandler}
                    cancelHandler={ () => setActiveDiaog(null) }
                    selectedUsers={ selectedUsers }
                />
            }
            <PageContentLayout title="Users" showLoading={isLoading}>
                <GridWithStateWrapper
                    gridName="configuration/users"
                    apiRef={apiRef}
                    rows={rows}
                    columns={columns}
                    disableRowSelectionOnClick
                    checkboxSelection
                    pagination={true}
                    density="compact"
                    rowSelectionModel={selectionModel}
                    onRowSelectionModelChange={ sm => setSelectionModel(sm) }
                    pageSizeOptions={[100, 250, 500, 1000]}
                />
            </PageContentLayout>
        </>
    );
}