import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import { DatePicker } from "@mui/x-date-pickers/DatePicker/DatePicker";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import FilePicker from "components/Pickers/FilePicker";
import { ComplianceData } from "common/models/ComplianceData";
import { UploadComplianceData } from "services/ComplianceService";
import RWTextFieldComponent from "components/RWTextFieldComponent";

interface Props {
    open: boolean,
    itemId: number,
    candidateId: number,
    placementId: number,
    data: ComplianceData | null,
    closeHandler: () => void,
    loadingHandler?: (isLoading: boolean) => void,
    successHandler?: (message: string) => void,
    errorHandler?: (message: string) => void,
}

export default function EditComplianceDataDialog({ open, itemId, candidateId, placementId, data, closeHandler, loadingHandler, successHandler, errorHandler }: Props) {
    const [expirationDate, setExpirationDate] = useState<moment.Moment | null>(null);
    const [notes, setNotes] = useState('');
    const [files, setFiles] = useState<File[]>([]);

    useEffect(() => {
        if (open) {
            setNotes(data ? data.notes : '');
            const dataExpiry = data ? data.expiryDate : '';
            if (dataExpiry && dataExpiry !== '0001-01-01T00:00:00') setExpirationDate(moment(dataExpiry));
            else setExpirationDate(null);
            setFiles([]);
        }
    }, [open, data]);

    const submitCallback = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        const expDate = expirationDate ? expirationDate.format('YYYY-MM-DD') : '';
        const res = await UploadComplianceData(itemId, candidateId, placementId, files, expDate, notes, errorHandler);
        if (res) {
            successHandler && successHandler('Compliance Data Uploaded');
            closeHandler();
        }
        loadingHandler && loadingHandler(false);
    }, [itemId, candidateId, placementId, files, expirationDate, notes, loadingHandler, successHandler, errorHandler, closeHandler]);

    const removeFileCallback = useCallback((index: number) => {
        setFiles(prev => {
            let tmp = [...prev];
            tmp.splice(index, 1);
            return tmp;
        });
    }, []);

    return (
        <Dialog open={open} fullWidth>
            <DialogTitle>Edit Compliance Data</DialogTitle>
            <DialogContent>
                <Stack spacing={2} mt="10px">
                    <DatePicker
                        label="Expiration Date"
                        value={expirationDate}
                        onChange={setExpirationDate}
                        slotProps={{actionBar: { actions: ["clear", "today", "cancel", "accept"] }}}
                    />
                    <RWTextFieldComponent label="Notes" value={notes} onChange={(e) => setNotes(e.target.value)} multiline={true} rows={4} />
                    <FilePicker
                        files={files}
                        singleFile
                        addFilesHandler={ setFiles }
                        removeFileHandler={ removeFileCallback }
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="error" onClick={closeHandler}>Cancel</Button>
                <Button variant="contained" onClick={submitCallback} disabled={files.length === 0} color="success">Submit</Button>
            </DialogActions>
        </Dialog>
    );
}