import React, { useEffect, useMemo, useState } from "react";
import { Report } from "common/models/Reports/Report";
import ReportsGridComponent from "components/Reports/ReportsGrid";
import OnlyTitleSummaryBar from "components/SummaryBars/OnlyTitleSummaryBar";
import PageContentLayout from "layouts/PageContentLayout";
import PageLayout from "layouts/PageLayout";
import { GetDashboardReports } from "services/DashboardService";
import { Link, useParams } from 'react-router-dom';
import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import DetailedActivityReport from "components/Reports/Activities/ActivityDetailed";
import ConsultantReport from "components/Reports/Consultant/Consultant";
import LoginTrackingReport from "components/Reports/Misc/LoginTracking";
import SpellerConsultantReport from "components/Reports/Consultant/SpellerConsultant";
import ActivitySummaryReport from "components/Reports/Activities/ActivitySummary";
import SmsUsageReport from "components/Reports/Sms/SmsUsage";
import InternalInterviewsConversionReport from "components/Reports/Misc/InternalInterviewsConversion";
import ForecastDetailedReport from "components/Reports/Misc/ForecastDetailed";
import ForecastUserDetailedReport from "components/Reports/Misc/ForecastUserDetailed";
import TRCMonthlyStrategicCoachingReport from "components/Reports/Misc/TRC_MonthlySCGrades";
import TRCMMAByDivisionsReport from "components/Reports/Misc/TRCMMAsByDivision";
import TRCMMAByUsersReport from "components/Reports/Misc/TRC_MMAByUsers";
import TRCCompanyActivityReport from "components/Reports/Misc/TRC_CompanyActivity";
import TRCWeekly121 from "components/Reports/Misc/TRC_Weekly121";
import TRCStrategicCoachingReport from "components/Reports/Misc/TRC_StrategicCoaching";
import NTTSLATimeRangeReport from "components/Reports/Misc/NTT_SLATimeRange";
import NTTSLASnapshotReport from "components/Reports/Misc/NTT_SLASnapshot";
import NTTAgencySupply from "components/Reports/Misc/NTT_AgencySupply";
import ComplianceExpiryReport from "components/Reports/Misc/ComplianceExpiry";
import RandstadUserUsage from "components/Reports/Misc/Randstad_UserUsage";

const gridSummaryBar = <OnlyTitleSummaryBar title="Analytics > Misc" browserTabTitle="Misc > Analytics" />;
const ClientsReportsLink = <Link to="/analytics/misc" style={{ color: 'inherit', textDecoration: 'underline' }}>Misc</Link>;

export default function DashboardReportsPage() {
    const [isLoading, setIsLoading] = useState(false);
    const [reports, setReports] = useState<Report[]>([]);
    const params = useParams();

    const reportName = useMemo(() => {
        const reportNameParam = params.reportName;
        return reportNameParam;
    }, [params.reportName]);

    const reportObj = useMemo(() => {
        return reports.find(r => r.pathName === reportName || r.pathName.split("?")[0] === reportName)
    }, [reports, reportName]);

    const summaryBar = useMemo(() => {
        if (reportObj) {
            const title = <>{'Analytics > '} {ClientsReportsLink} {' > ' + reportObj.title}</>;
            const tabTitle = reportObj.title + ' > Misc > Analytics';
            return <TitleAndActionSummaryBar title={title} browserTabTitle={tabTitle} action={<></>} />;
        }
        return gridSummaryBar;
    }, [reportObj]);

    useEffect(() => {
        const getReports = async () => {
            setIsLoading(true);
            const res = await GetDashboardReports();
            if (res) setReports(res);
            setIsLoading(false);
        };
        getReports();
    }, []);

    return (
        <PageLayout SummaryBar={summaryBar}>
            <PageContentLayout showLoading={isLoading}>
                {!reportName && <ReportsGridComponent data={reports} />}
                {reportObj && reportObj.pathName === 'activity-detailed' && <DetailedActivityReport description={reportObj.longDescription} loadingHandler={setIsLoading} />}
                {reportObj && reportObj.pathName === 'activity-summary' && <ActivitySummaryReport description={reportObj.longDescription} loadingHandler={setIsLoading} />}
                {reportObj && reportObj.pathName === 'consultant' && <ConsultantReport description={reportObj.longDescription} loadingHandler={setIsLoading} />}
                {reportObj && reportObj.pathName === 'login-tracking' && <LoginTrackingReport description={reportObj.longDescription} loadingHandler={setIsLoading} />}
                {reportObj && reportObj.pathName === 'custom-speller-consultant' && <SpellerConsultantReport description={reportObj.longDescription} loadingHandler={setIsLoading} />}
                {reportObj && reportObj.pathName === 'internal-interview-conversion' && <InternalInterviewsConversionReport description={reportObj.longDescription} loadingHandler={setIsLoading} />}
                {reportObj && reportObj.pathName === 'sms-usage' && <SmsUsageReport description={reportObj.longDescription} loadingHandler={setIsLoading} />}
                {reportObj && reportObj.pathName === 'forecast-detailed' && <ForecastDetailedReport description={reportObj.longDescription} loadingHandler={setIsLoading} />}
                {reportObj && reportObj.pathName === 'user-forecast-detailed' && <ForecastUserDetailedReport description={reportObj.longDescription} loadingHandler={setIsLoading} />}
                {reportObj && reportObj.pathName === 'custom-trcgroup-monthlyscgrades' && <TRCMonthlyStrategicCoachingReport description={reportObj.longDescription} loadingHandler={setIsLoading} />}
                {reportObj && reportObj.pathName === 'custom-trcgroup-mmasbydivision' && <TRCMMAByDivisionsReport description={reportObj.longDescription} loadingHandler={setIsLoading} />}
                {reportObj && reportObj.pathName === 'custom-trcgroup-mmasbyuser?Yesterday=1' && <TRCMMAByUsersReport description={reportObj.longDescription} loadingHandler={setIsLoading} />}
                {reportObj && reportObj.pathName === 'custom-trcgroup-company-activity' && <TRCCompanyActivityReport description={reportObj.longDescription} loadingHandler={setIsLoading} />}
                {reportObj && reportObj.pathName === 'custom-trcgroup-weekly-121' && <TRCWeekly121 description={reportObj.longDescription} loadingHandler={setIsLoading} />}
                {reportObj && reportObj.pathName === 'custom-trcgroup-monthlysc' && <TRCStrategicCoachingReport description={reportObj.longDescription} loadingHandler={setIsLoading} />}
                {reportObj && reportObj.pathName === 'custom-ntt-sla-timerange' && <NTTSLATimeRangeReport description={reportObj.longDescription} loadingHandler={setIsLoading} />}
                {reportObj && reportObj.pathName === 'custom-ntt-sla-snapshot' && <NTTSLASnapshotReport description={reportObj.longDescription} loadingHandler={setIsLoading} />}
                {reportObj && reportObj.pathName === 'custom-ntt-agencysupply-sla' && <NTTAgencySupply description={reportObj.longDescription} loadingHandler={setIsLoading} />}
                {reportObj && reportObj.pathName === 'custom-randstad-user-usage' && <RandstadUserUsage description={reportObj.longDescription} loadingHandler={setIsLoading} />}
                
                {reportObj && reportObj.pathName === 'compliance-expiry' && <ComplianceExpiryReport description={reportObj.longDescription} loadingHandler={setIsLoading} />}
            </PageContentLayout>
        </PageLayout>
    );
}