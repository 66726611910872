import { Automation } from "../common/models/Configuration/Automation";
import { AutomationEventType } from "../common/models/Automation/AutomationEventType";
import { ErrorHandler, RequestOptions, NameIdObj } from "../common/models/GenericTypes";
import { Get, Post, Put } from "../util/HttpUtils";
import { AutomationPlacementLog } from "common/models/Automation/AutomationPlacementLog";
import { AutomationCandidateLog } from "common/models/Automation/AutomationCandidateLog";
import { CandidateList } from "common/models/Candidates/CandidateList";
import { Placement } from "common/models/Placements";
import { AutomationPreviewData } from "common/models/Configuration/AutomationPreviewData";

export async function GetAutomationEventTypes(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    }

    return Get<AutomationEventType[]>('api/automation/events/all', options);
}

export async function GetAutomations(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    }

    return Get<Automation[]>('api/automation/all', options);
}

export async function GetAutomation(id: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Automation>(`api/automation/${id}`, options);
}

export async function CreateAutomation(template: Automation, runNow: boolean, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>(`api/automation?runNow=${runNow}`, options, template);
}

export async function CreateAutomationFromTemplate(templateId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<Automation>(`api/automation/${templateId}`, options);
}

export async function UpdateAutomation(id: number, template: Automation, runNow: boolean, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/automation/${id}?runNow=${runNow}`, options, template);
}

export async function CopyAutomation(id: number, newName: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>(`api/automation/${id}/copy`, options, { value: newName });
}

export async function GetAutomationTemplates(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    }

    return Get<NameIdObj[]>('api/automation/templates/all', options);
}

export async function GetAutomationModuleTypes(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    }

    return Get<NameIdObj[]>('api/automation/modules/all', options);
}

export async function GetPlacementAutomationLogs(automationId:number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<AutomationPlacementLog[]>(`api/automation/logs/${automationId}/placements`, options);
}

export async function GetCandidateAutomationLogs(automationId:number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<AutomationCandidateLog[]>(`api/automation/logs/${automationId}/candidates`, options);
}

export async function GetCandidatesByAutomationFilters(filters: string,previewData: AutomationPreviewData | undefined, errorHandler?: ErrorHandler) {
    if(previewData) {
        const data = {
            filters: filters,
            nextRunOnly: previewData.nextRunOnly,
            maxRecords: previewData.maxRecords,
            automationId: previewData.automationId,
            orderBy: previewData.orderBy
        }
        const options: RequestOptions = {
            needsAuth: true,
            errorHandler
        };
    
        return Post<CandidateList[]>('api/automation/search/candidates/filters', options, data);
    }
    return [];
}

export async function GetPlacementsByAutomationFilters(filters: string,previewData: AutomationPreviewData | undefined, errorHandler?: ErrorHandler) {
    if(previewData) {
        const data = {
            filters: filters,
            nextRunOnly: previewData.nextRunOnly,
            maxRecords: previewData.maxRecords,
            automationId: previewData.automationId,
            orderBy: previewData.orderBy
        }
        const options: RequestOptions = {
            needsAuth: true,
            errorHandler
        };
    
        return Post<Placement[]>('api/automation/search/placements/filters', options, data);
    }
    return [];
    
    
   
}