import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import RWTextFieldComponent from "components/RWTextFieldComponent";
import React, { useCallback, useState } from "react";
import { ConvertAdvertToTemplate } from "services/AdvertsService";

interface Props {
    open: boolean,
    advertId: number | null,
    onClose: () => void,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
    successHandler?: (message: string) => void,
}

export default function ConvertToTemplateDialog({ open, advertId, onClose, loadingHandler, errorHandler, successHandler }: Props) {
    const [templateName, setTemplateName] = useState('');

    const closeHandler = useCallback(() => {
        setTemplateName('');
        onClose();
    }, [onClose]);

    const convertToTemplateCallback = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        if (advertId) {
            const res = await ConvertAdvertToTemplate(advertId, templateName, errorHandler);
            if (res) {
                successHandler && successHandler('Template Created');
                closeHandler();
            }
        }
        loadingHandler && loadingHandler(false);
    }, [advertId, templateName, loadingHandler, errorHandler, successHandler, closeHandler]);

    return (
        <Dialog open={open} fullWidth>
            <DialogTitle>Create Template</DialogTitle>
            <DialogContent>
                <div style={{ marginTop: '10px' }} />
                <RWTextFieldComponent value={templateName} label="Template Name" onChange={(e) => setTemplateName(e.target.value)} />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="error" onClick={ closeHandler }>Cancel</Button>
                <Button variant="contained" color="success" disabled={ !Boolean(templateName) } onClick={ convertToTemplateCallback }>Create</Button>
            </DialogActions>
        </Dialog>
    );
}