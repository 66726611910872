import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { DataGridPremium, GridColDef, GridRowSelectionModel } from "@mui/x-data-grid-premium";
import React, { useCallback, useMemo, useState } from "react";
import { Site } from "common/models/Site";
import { MergeSites } from "services/SitesService";

interface Props {
    open: boolean,
    sites: Site[],
    closeHandler: () => void,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
    successHandler?: (message: string) => void
}

export default function MergeSitesDialog({ open, sites, closeHandler, loadingHandler, errorHandler, successHandler }: Props) {
    const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
    
    const columns = useMemo<GridColDef[]>(() => {
        return [
            { field: 'name', headerName: 'Name', sortable: false, flex: 1 },
            { field: 'primaryContactName', headerName: 'Primary Contact', sortable: false, width: 150 },
            { field: 'suburb', headerName: 'Suburb', sortable: false, width: 150 },
            { field: 'state', headerName: 'State', sortable: false, width: 150 },
            { field: 'countryName', headerName: 'Country', sortable: false, width: 150 },
            { field: 'status', headerName: 'Status', sortable: false },
        ];
    }, []);

    const selectionChangeHandler = useCallback((sm: GridRowSelectionModel) => {
        if (sm.length > 1) {
            const last = sm[sm.length - 1];
            setSelectionModel([last]);
            return;
        }
        setSelectionModel(sm);
    }, []);

    const mergeHandlerCallback = useCallback(async () => {
        if (selectionModel.length === 1) {
            const selectedId = selectionModel[0].valueOf() as number;
            loadingHandler && loadingHandler(true);
            let siteIds: number[] = [];
            for (let i = 0; i < sites.length; i++) {
                const site = sites[i];
                if (site.id !== selectedId) siteIds.push(site.id);
            }
            const res = await MergeSites(selectedId, siteIds, errorHandler);
            if (res) {
                successHandler && successHandler('Records have been queued for merging. This process takes approximately 5 minutes.');
                closeHandler();
            }
            loadingHandler && loadingHandler(false);
        }
    }, [closeHandler, errorHandler, loadingHandler, successHandler, selectionModel, sites]);

    return (
        <Dialog open={open} maxWidth="lg" fullWidth PaperProps={{ sx: { height: '645px' } }}>
            <DialogTitle>Sites Merge</DialogTitle>
            <DialogContent sx={{ display: 'flex' }}>
                <div style={{ paddingTop: '10px', display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                    Select a master record to remain and merge the other record(s) into
                    <DataGridPremium
                        rowSelectionModel={selectionModel}
                        onRowSelectionModelChange={ selectionChangeHandler }
                        density="compact"
                        disableColumnMenu
                        disableColumnReorder
                        disableColumnResize
                        checkboxSelection
                        hideFooter
                        columns={columns}
                        rows={sites}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="error" onClick={ closeHandler }>Cancel</Button>
                <Button variant="contained" color="success" disabled={ selectionModel.length !== 1 } onClick={ mergeHandlerCallback }>Merge</Button>
            </DialogActions>
        </Dialog>
    );
}