import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import ContactPicker from "components/Pickers/ContactPicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import UserPicker from "components/Pickers/UserPicker";
import { GetQuestionnaires_Referoo } from "services/CustomerIntegrationsService";
import { NameIdObj } from "common/models/GenericTypes";
import { GetMyUser } from "services/UsersService";
import { ExtendedUser } from "common/models/Configuration/User";
import { CreateRefCheck_Referoo } from "services/WorkflowService";
import moment from "moment";
import { Contact } from "common/models/Contacts";
import { RegexIsValidEmail } from "util/RegExUtils";

interface Props {
    open: boolean,
    candidateId: number,
    candidateName?: string,
    jobId?: number,
    jobTitle?: string,
    closeHandler: () => void,
    loadingHandler?: (isLoading: boolean) => void,
    successHandler?: (message: string) => void,
    errorHandler?: (message: string) => void,
}

const emailErrorMessage = "No Valid Email";

export default function ReferooActionsDialog({ open, candidateId, candidateName = '', jobId = 0, jobTitle = '', closeHandler: closeHandlerProp, loadingHandler, successHandler, errorHandler }: Props) {
    const [type, setType] = useState(0);
    const [referee1Contact, setReferee1Contact] = useState<Contact | null>(null);
    const [referee2Contact, setReferee2Contact] = useState<Contact | null>(null);
    const [referee3Contact, setReferee3Contact] = useState<Contact | null>(null);
    const [refereeFollowUpDate, setRefereeFollowUpDate] = useState<moment.Moment | null>(null);
    const [candidateFollowUpDate, setCandidateFollowUpDate] = useState<moment.Moment | null>(null);
    const [refereeFollowUpAssignedTo, setRefereeFollowUpAssignedTo] = useState(0);
    const [candidateFollowUpAssignedTo, setCandidateFollowUpAssignedTo] = useState(0);
    const [refereesNumber, setRefereesNumber] = useState(1);
    const [questionnaires, setQuestionnaires] = useState<NameIdObj[]>([]);
    const [selectedQuestionnaire, setSelectedQuestionnaire] = useState(0);
    const [myUserObj, setMyUserObj] = useState<ExtendedUser | null>(null);
    const [setupFinished, setSetupFinished] = useState(false);

    const referee1 = useMemo(() => (referee1Contact ? referee1Contact.id : 0), [referee1Contact]);
    const referee2 = useMemo(() => (referee2Contact ? referee2Contact.id : 0), [referee2Contact]);
    const referee3 = useMemo(() => (referee3Contact ? referee3Contact.id : 0), [referee3Contact]);

    const isReferee1EmailValid = useMemo(() => {
        if (referee1Contact) return RegexIsValidEmail(referee1Contact.email);
        return true;
    }, [referee1Contact]);

    const isReferee2EmailValid = useMemo(() => {
        if (referee2Contact) return RegexIsValidEmail(referee2Contact.email);
        return true;
    }, [referee2Contact]);

    const isReferee3EmailValid = useMemo(() => {
        if (referee3Contact) return RegexIsValidEmail(referee3Contact.email);
        return true;
    }, [referee3Contact]);

    const referee1ErrorMessage = useMemo(() => (!isReferee1EmailValid ? emailErrorMessage : ''), [isReferee1EmailValid]);
    const referee2ErrorMessage = useMemo(() => (!isReferee2EmailValid ? emailErrorMessage : ''), [isReferee2EmailValid]);
    const referee3ErrorMessage = useMemo(() => (!isReferee3EmailValid ? emailErrorMessage : ''), [isReferee3EmailValid]);

    useEffect(() => {
        const getData = async () => {
            const res = await GetQuestionnaires_Referoo(errorHandler);
            if (res) setQuestionnaires(res);
            const me = await GetMyUser();
            if (me) setMyUserObj(me);
            setSetupFinished(true);
        };
        open && !setupFinished && getData();
    }, [open, setupFinished, errorHandler]);

    useEffect(() => {
        if (open && myUserObj) {
            setRefereeFollowUpAssignedTo(myUserObj.userID);
            setCandidateFollowUpAssignedTo(myUserObj.userID);
        }
    }, [open, myUserObj]);

    useEffect(() => {
        if (open) {
            setType(0);
            setReferee1Contact(null);
            setReferee2Contact(null);
            setReferee3Contact(null);
            setRefereeFollowUpDate(null);
            setCandidateFollowUpDate(null);
            setRefereesNumber(1);
            setSelectedQuestionnaire(0);
        }
    }, [open]);

    const closeHandler = useCallback(() => {
        errorHandler && errorHandler('');
        closeHandlerProp();
    }, [closeHandlerProp, errorHandler]);

    const submitCallback = useCallback(async () => {
        let data: any = {
            candidateId: candidateId,
            jobId: jobId,
            type: type,
            questionnaireId: selectedQuestionnaire,
        };

        if (type === 0) {
            data.referee1 = referee1;
            data.referee2 = referee2;
            data.referee3 = referee3;
            const now = moment().format('YYYY-MM-DDTHH:mm');
            if (refereeFollowUpDate && refereeFollowUpDate.isValid()) {
                const formatted = refereeFollowUpDate.format('YYYY-MM-DDTHH:mm');
                if (now > formatted) {
                    errorHandler && errorHandler('Referee Follow Up Date must be in the future')
                    return;
                }
                data.RefereeFollowUpDate = formatted;

            }

            if (candidateFollowUpDate && candidateFollowUpDate.isValid()) {
                const formatted = candidateFollowUpDate.format('YYYY-MM-DDTHH:mm');
                if (now > formatted) {
                    errorHandler && errorHandler('Candidate Follow Up Date must be in the future')
                    return;
                }
                data.CandidateFollowUpDate = formatted;

            }

            data.RefereeFollowUpAssignedTo = refereeFollowUpAssignedTo;
            data.CandidateFollowUpAssignedTo = candidateFollowUpAssignedTo;
        }
        else if (type === 1) {
            data.refereesNumber = refereesNumber;
        }

        loadingHandler && loadingHandler(true);
        const res = await CreateRefCheck_Referoo(data, errorHandler);
        if (res) {
            successHandler && successHandler('Referoo Ref Check Created');
            closeHandler();
        }
        loadingHandler && loadingHandler(false);
    }, [candidateId, jobId, type, selectedQuestionnaire, loadingHandler, errorHandler, referee1, referee2, referee3, refereeFollowUpDate, candidateFollowUpDate, refereeFollowUpAssignedTo, candidateFollowUpAssignedTo, refereesNumber, successHandler, closeHandler]);

    const title = useMemo(() => {
        let t = 'Referoo Reference Check';
        if (Boolean(candidateName)) t += ` for ${candidateName}`;
        if (Boolean(jobTitle)) t += ` (${jobTitle})`
        return t;
    }, [candidateName, jobTitle]);

    return (
        <Dialog fullWidth open={open}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <Stack spacing={2} pt="10px">
                    <TextField label="Type" value={type.toString()} select onChange={({target}) => setType(+target.value)} >
                        <MenuItem value="0">Referees Known Now</MenuItem>
                        <MenuItem value="1">Candidate Supplied Later</MenuItem>
                    </TextField>
                    {type === 0 &&
                        <>
                            <ContactPicker label="Referee 1" value={referee1} onSelectCallback={setReferee1Contact} error={!isReferee1EmailValid} errorMessage={referee1ErrorMessage} />
                            <ContactPicker label="Referee 2" value={referee2} onSelectCallback={setReferee2Contact} error={!isReferee2EmailValid} errorMessage={referee2ErrorMessage} />
                            <ContactPicker label="Referee 3" value={referee3} onSelectCallback={setReferee3Contact} error={!isReferee3EmailValid} errorMessage={referee3ErrorMessage} />
                        </>
                    }
                    {type === 1 &&
                        <TextField label="Number of Referees" value={refereesNumber.toString()} select onChange={({target}) => setRefereesNumber(+target.value)}>
                            <MenuItem value="1">1</MenuItem>
                            <MenuItem value="2">2</MenuItem>
                            <MenuItem value="3">3</MenuItem>
                            <MenuItem value="4">4</MenuItem>
                            <MenuItem value="5">5</MenuItem>
                            <MenuItem value="6">6</MenuItem>
                            <MenuItem value="7">7</MenuItem>
                            <MenuItem value="8">8</MenuItem>
                            <MenuItem value="9">9</MenuItem>
                            <MenuItem value="10">10</MenuItem>
                        </TextField>
                    }
                    <TextField label="Questionnaire" value={selectedQuestionnaire} select onChange={({target}) => setSelectedQuestionnaire(+target.value)}>
                        <MenuItem value="0">None</MenuItem>
                        {questionnaires.map(q => <MenuItem key={q.id} value={q.id.toString()}>{q.name}</MenuItem>)}
                    </TextField>
                    {type === 0 &&
                        <>
                            <DateTimePicker disablePast label="Referee Follow up Date" value={refereeFollowUpDate} onChange={setRefereeFollowUpDate}
                                            slotProps={{
                                                actionBar: { actions: ["clear", "today", "cancel", "accept"] },
                                            }}/>
                            <UserPicker label="Referee Follow up Assigned to" userId={refereeFollowUpAssignedTo} onSelect={u => setRefereeFollowUpAssignedTo(u ? u.id : 0)} />
                        </>
                    }
                    <DateTimePicker disablePast label="Candidate Follow up Date" value={candidateFollowUpDate} onChange={setCandidateFollowUpDate}
                                    slotProps={{
                                        actionBar: { actions: ["clear", "today", "cancel", "accept"] },
                                    }}/>
                    <UserPicker label="Candidate Follow up Assigned to" userId={candidateFollowUpAssignedTo} onSelect={u => setCandidateFollowUpAssignedTo(u ? u.id : 0)} />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="error" onClick={ closeHandler }>Cancel</Button>
                <Button variant="contained" color="success" disabled={ selectedQuestionnaire === 0 || !isReferee1EmailValid || !isReferee2EmailValid || !isReferee3EmailValid } onClick={ submitCallback } >Submit</Button>
            </DialogActions>
        </Dialog>
    );
}