import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import RWTextFieldComponent from "components/RWTextFieldComponent";
import React, { useCallback, useState } from "react";

interface Props {
    open: boolean,
    disableFrequencyField?: boolean,
    closeHandler: () => void,
    saveHandler: (name: string, freq: number) => void,
}

export default function SaveSearchDialog({ open, disableFrequencyField = false, closeHandler, saveHandler }: Props) {
    const [searchName, setSearchName] = useState('');
    const [autoRunFreq, setAutoRunFreq] = useState(0);

    const onCancelCallback = useCallback(() => {
        setSearchName('');
        closeHandler();
    }, [closeHandler]);

    const onSaveCallback = useCallback(() => {
        saveHandler(searchName, autoRunFreq);
        setSearchName('');
    }, [searchName, autoRunFreq, saveHandler]);

    return (
        <Dialog open={open} fullWidth>
            <DialogTitle>Save Search</DialogTitle>
            <DialogContent>
                <Stack spacing={2} paddingTop="10px">
                    <RWTextFieldComponent label="Name" value={searchName} onChange={(e) => setSearchName(e.target.value)} />
                    <TextField select label="Auto Run Frequency" value={autoRunFreq.toString()} onChange={({target}) => setAutoRunFreq(+target.value)} disabled={disableFrequencyField}>
                        <MenuItem value="0">Manual</MenuItem>
                        <MenuItem value="1">Hourly</MenuItem>
                        <MenuItem value="2">Daily</MenuItem>
                        <MenuItem value="3">Weekly</MenuItem>
                        <MenuItem value="4">Monthly</MenuItem>
                    </TextField>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="error" onClick={ onCancelCallback }>Cancel</Button>
                <Button variant="contained" disabled={searchName === ''} color="success" onClick={ onSaveCallback }>Save</Button>
            </DialogActions>
        </Dialog>
    );
}