import React, { useCallback, useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { Checkbox, Container, FormControlLabel, Grid, InputAdornment, MenuItem, Radio, RadioGroup, TextField } from "@mui/material";
import { NameIdObj, NameIdObjExtended } from "common/models/GenericTypes";
import { TimeZone } from "common/models/Common";
import TimeZonePicker from "components/Pickers/TimeZonePicker";
import { AutomationScheduleData } from "./AutomationScheduleData";
import { TimePicker } from "@mui/x-date-pickers-pro";
import { GetMyUser } from "services/UsersService";
import { GetTimeZone } from "services/CommonService";
import moment from "moment";

interface Props {
  scheduleData: AutomationScheduleData,
  onChange: (data: AutomationScheduleData) => void
}

export interface TimeScheduleAccordion {
    id: number,
    name: string,
    openDescription: string,
    closedDescription: string,
    expanded: boolean
};

export interface DayOfMonth {
  id: number,
  name: string
};

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&::before': {
      display: 'none',
    },
  }));

  const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, .05)'
        : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
  }));

  const daysOfMonth: NameIdObj[] = [
    { id: 1, name: '1st' },
    { id: 2, name: '2nd' },
    { id: 3, name: '3rd' },
    { id: 4, name: '4th' },
    { id: 5, name: '5th' },
    { id: 6, name: '6th' },
    { id: 7, name: '7th' },
    { id: 8, name: '8th' },
    { id: 9, name: '9th' },
    { id: 10, name: '10th' },
    { id: 11, name: '11th' },
    { id: 12, name: '12th' },
    { id: 13, name: '13th' },
    { id: 14, name: '14th' },
    { id: 15, name: '15th' },
    { id: 16, name: '16th' },
    { id: 17, name: '17th' },
    { id: 18, name: '18th' },
    { id: 19, name: '19th' },
    { id: 20, name: '20th' },
    { id: 21, name: '21st' },
    { id: 22, name: '22nd' },
    { id: 23, name: '23rd' },
    { id: 24, name: '24th' },
    { id: 25, name: '25th' },
    { id: 26, name: '26th' },
    { id: 27, name: '27th' },
    { id: 28, name: '28th' },
    { id: 29, name: '29th' },
    { id: 30, name: '30th' },
    { id: 31, name: '31st' },
    { id: 99, name: 'Last' },
];

const weekOrdinals: NameIdObj[] = [
  {id:1, name: 'First'},
  {id:2, name: 'Second'},
  {id:3, name: 'Third'},
  {id:4, name: 'Fourth'},
  {id:99, name: 'Last'}
];

const daysOfWeek: NameIdObjExtended[] = [
  {id:1, displayId:1, name: 'Monday'},
  {id:2, displayId:2, name: 'Tuesday'},
  {id:3, displayId:3, name: 'Wednesday'},
  {id:4, displayId:4, name: 'Thursday'},
  {id:5, displayId:5, name: 'Friday'},
  {id:6, displayId:6, name: 'Saturday'},
  {id:0, displayId:7, name: 'Sunday'},
];

const accordionDefaults: TimeScheduleAccordion[] = [
  {
      id:1,
      name: "Schedule",
      closedDescription: "Daily",
      openDescription: "Select the schedule that the trigger should run on.",
      expanded: false,
  },
  {
      id:2,
      name: "Run Days",
      closedDescription: "",
      openDescription: "Select what days of the week the schedule should execute.",
      expanded: false,
  },
  {
      id:3,
      name: "Schedule Time Zone",
      closedDescription: "",
      openDescription: "Select the timezone used for the schedule.",
      expanded: false,
  },
  {
      id:4,
      name: "Interval",
      closedDescription: "",
      openDescription: "Select at what interval the schedule should execute.",
      expanded: false,
  },
  {
      id:5,
      name: "Start Time",
      closedDescription: "",
      openDescription: "Select at what time the schedule should execute",
      expanded: false
  }
];

export default function AutomationSchedule({scheduleData,onChange}: Props) {
    const [hourMinuteIntervalDesc,setHourMinuteIntervalDesc] = useState<string>("");
    const [runTimesErrors,setRunTimesErrors] = useState<string>('');
    const [accordions, setAccordions] = useState<TimeScheduleAccordion[]>([...accordionDefaults]);

    const updateHourMinuteIntervalDesc = useCallback((value: string) => {
      setHourMinuteIntervalDesc(value);
    },[setHourMinuteIntervalDesc])

    const updateAccordions = useCallback((panelId: number, name: string | null, openDescription: string | ((t: TimeScheduleAccordion) => string) | null, closedDescription: string | null, isOpen: boolean | null) => {
      setAccordions((prevItems) => {
        return prevItems.map(item => {
          if (item.id === panelId) {
            let desc = item.openDescription;
            if (openDescription && typeof openDescription === 'string') desc = openDescription;
            else if (openDescription && typeof openDescription === 'function') desc = openDescription(item);
            const nameValue = name ?? item.name;
            const openDescValue = desc;
            const closedDescValue = closedDescription ?? item.closedDescription;
            const open = isOpen ?? item.expanded;
            return { ...item, name: nameValue, openDescription: openDescValue, closedDescription:closedDescValue, expanded: open};
          }
          return item;
        });
      });
  }, [setAccordions]);

    const generateDaysPerMonthDescription = useCallback(() => {
      updateAccordions(1,null,null,'Days per month',null);
      let closeDescription = '';
      if(scheduleData.monthTrigger === 'SpecificDate' && scheduleData.dayOfMonth) {
        closeDescription = `The ${daysOfMonth.find(d=>d.id === scheduleData.dayOfMonth)?.name} day of every month`;
      }

      if(scheduleData.monthTrigger === 'DayOfWeekOfMonth' && scheduleData.weekOrdinal) {
        closeDescription = `The ${weekOrdinals.find(w=>w.id === scheduleData.weekOrdinal)?.name} ${daysOfWeek.find(d=> d.id === scheduleData.dayOfWeek)?.name} of every month`;
      }
      updateAccordions(2,'On',(a: TimeScheduleAccordion) => a.id === 2 ? a.openDescription : 'Select when in the month the trigger should execute' , closeDescription,null);
    }, [scheduleData.dayOfMonth, scheduleData.dayOfWeek, scheduleData.monthTrigger, scheduleData.weekOrdinal, updateAccordions]);

    const generateIntervalDescription = useCallback((interval: string, value: number) => {
      if(interval === "Hourly") {
        if(value > 1) {
          updateHourMinuteIntervalDesc("hours");
          updateAccordions(4,null,null,`Runs every ${value} hours`,null)
          updateAccordions(5,"Run","Select at what time the schedule should begin and end processing the execution.",`Start execution at ${moment(scheduleData.runAfter).format("hh:mm a")} and execute until ${moment(scheduleData.runUntil).format("hh:mm a")}`,null);
        }else {
          updateHourMinuteIntervalDesc("hour");
         updateAccordions(4,null,null,"Runs every hour",null)
         updateAccordions(5,"Run","Select at what time the schedule should begin and end processing the execution.",`Start execution at ${moment(scheduleData.runAfter).format("hh:mm a")} and execute until ${moment(scheduleData.runUntil).format("hh:mm a")}`,null);
        }
     }
     else {
      updateHourMinuteIntervalDesc("");
      updateAccordions(4,null,null,"Runs once",null)
      updateAccordions(5,"Start Time",accordionDefaults.find(a=>a.id === 5)?.openDescription ?? '',moment(scheduleData.startTime).format("hh:mm a"),null);
     }
    }, [scheduleData.runAfter, scheduleData.runUntil, scheduleData.startTime, updateAccordions, updateHourMinuteIntervalDesc]);

    useEffect(() => {
      const getUser = async () => {
        const user =  await GetMyUser();
        if(user?.timeZoneName) {
          const timeZone = await GetTimeZone(user?.timeZoneName,(e) => {console.log(e)});
          scheduleData.timeZone = timeZone;
          updateAccordions(3,null,null,generateTimeZoneDescription(timeZone),null);
          onChange(scheduleData);
        }
      };

      if(scheduleData.isNew) {
        updateAccordions(1,null,null,null,true);
        updateAccordions(2,null,null,null,true);
        updateAccordions(3,null,null,null,true);
        updateAccordions(4,null,null,null,true);
        updateAccordions(5,null,null,null,true);
        getUser();
      }

      if(scheduleData.schedule === "Daily") {
        updateAccordions(1,null,null,scheduleData.schedule,null);
        updateAccordions(2,null,null,generateRunDaysDescription(scheduleData.runDays),null);
      }else {
        generateDaysPerMonthDescription();
      }

      if(scheduleData.timeZone) {
        updateAccordions(3,null,null,generateTimeZoneDescription(scheduleData.timeZone),null);
      }else{
        getUser();
        onChange(scheduleData);
      }

      generateIntervalDescription(scheduleData.interval,scheduleData.hourMinuteInterval);

      if(scheduleData.interval !== "Once") {
        if(scheduleData.runAfter && scheduleData.runUntil){
          updateAccordions(5,null,null,`Start execution at ${moment(scheduleData.runAfter).format("hh:mm a")} and execute until ${moment(scheduleData.runUntil).format("hh:mm a")}`,null)
        }
      }else {
        updateAccordions(5,null,null,moment(scheduleData.startTime).format("hh:mm a"),null)
      }

    }, [generateDaysPerMonthDescription, generateIntervalDescription, onChange, scheduleData, updateAccordions]);

      const updateItem = (panel: TimeScheduleAccordion) => (event: React.SyntheticEvent) => {
        setAccordions(prevItems => {
          return prevItems.map(item => {
            if (item.id === panel.id) {
              return { ...item, expanded: !panel.expanded };
            }
            return item;
          });
        });
      };

      const handleRunDayChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(+event.target.value >= 0) {
          updateRunDays(+event.target.value);
        }
      };

      const handleScheduleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        updateSchedule(event.target.value);
      }

      const handleEveryMonthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        updateMonthTrigger(event.target.value);
      }

      const handleDayOfMonthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        updateDayOfMonth(+event.target.value);
      }
      const handleWeekOrdinalChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        updateWeekOrdinal(+event.target.value);
      }

      const handleDayOfWeekChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        updateDayOfWeek(+event.target.value);
      }

      const handleIntervalChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        updateInterval(value);
        generateIntervalDescription(value,scheduleData.hourMinuteInterval);
      }

      const handleHourMinuteIntervalChange = (event: React.ChangeEvent<HTMLInputElement>) => {
          const value = +event.target.value;
          generateIntervalDescription(scheduleData.interval,value);
          updateHourMinuteInterval(value);
      }

      const handleStartTimeChange = (event: moment.Moment | null) => {
        if(event){
        updateStartTime(event);
        }
      };

      const handleRunAfterTimeChange = (event: moment.Moment | null) => {
        if(event){
        updateRunAfterTime(event);
        }
      };

      const handleRunUntilTimeChange = (event: moment.Moment | null) => {
        if(event){
        updateRunUntilTime(event);
        }
      };


      const timeZoneChangeCallback = useCallback((tz: TimeZone | null) => {
        scheduleData.timeZone = tz;
        onChange(scheduleData);
        if (tz) {
          updateAccordions(3,null,null,generateTimeZoneDescription(tz),null);
        }else {
          updateAccordions(3,null,null,'',null);
        }

    }, [onChange, scheduleData, updateAccordions]);

    const generateTimeZoneDescription = (tz: TimeZone | null) => {
      return `${tz?.zoneName} (UTC ${tz?.gmtOffsetHours !== undefined && tz ?.gmtOffsetHours > 0 ? "+" : "" ?? ""}${tz?.gmtOffsetHours}:00)` ?? '';
    }


    const updateStartTime = useCallback((time: moment.Moment | null) => {
      if (time) {
        scheduleData.startTime = time;
        scheduleData.startTimeString = time.format("HH:mm");
        onChange(scheduleData);
        updateAccordions(5,null,null,moment(time).format("hh:mm a"),null)
      }
    },[onChange, scheduleData, updateAccordions]);


    const compareTime = (startTime: moment.Moment, endTime: moment.Moment) => {
      const startHours = +startTime.format("HH");
      const startMinutes = +startTime.format("mm");
      const endHours = +endTime.format("HH");
      const endMinutes = +endTime.format("mm");

      if(startHours === endHours) {
        return startMinutes < endMinutes;
      }

      return startHours < endHours;
    }

    const updateRunAfterTime = useCallback((time: moment.Moment) => {
      if(scheduleData.runUntil) {
        if(!compareTime(time,scheduleData.runUntil)) {
          setRunTimesErrors("Run after time can not be greater or equal to run until time.")
        }else {
          setRunTimesErrors("")
        }
      }
        scheduleData.runAfter = time;
        scheduleData.runAfterString = time.format("HH:mm");
        onChange(scheduleData);
      if(scheduleData.runAfter && scheduleData.runUntil){
        updateAccordions(5,null,null,`Start execution at ${moment(time).format("hh:mm a")} and execute until ${moment(scheduleData.runUntil).format("hh:mm a")}`,null)
      }

    },[scheduleData, onChange, updateAccordions]);

    const updateRunUntilTime = useCallback((time: moment.Moment) => {
      if(scheduleData.runAfter) {
        if(!compareTime(scheduleData.runAfter,time)) {
          setRunTimesErrors("Run after time can not be greater or equal to run until time.")
        }else {
          setRunTimesErrors("")
        }
      }

        scheduleData.runUntil = time;
        scheduleData.runUntilString = time.format("HH:mm");
        onChange(scheduleData);
      if(scheduleData.runAfter && scheduleData.runUntil) {
      updateAccordions(5,null,null,`Start execution at ${moment(scheduleData.runAfter).format("hh:mm a")} and execute until ${moment(time).format("hh:mm a")}`,null)
      }
    },[scheduleData, onChange, updateAccordions]);

    const updateSchedule = useCallback((value: string) => {
      scheduleData.schedule = value;
      onChange(scheduleData);
      let closeDescription ='';
      if(value === 'Daily'){
        updateAccordions(1,null,null,'Daily',null);
        if(scheduleData?.runDays.length > 0) {
          closeDescription = generateRunDaysDescription(scheduleData?.runDays);
        }
        updateAccordions(2,'Run Days',accordions.find(a=>a.id === 2)?.openDescription ?? 'Select what days of the week the schedule should execute.',closeDescription,null);
      } else{
        generateDaysPerMonthDescription();
      }
    },[scheduleData, onChange, updateAccordions, accordions, generateDaysPerMonthDescription]);


    const updateMonthTrigger = useCallback((value: string) => {
      scheduleData.monthTrigger = value;
      onChange(scheduleData);
      if(value === 'SpecificDate') {
        updateAccordions(2,null,null,`The ${daysOfMonth.find(d=>d.id === scheduleData.dayOfMonth)?.name} day of every month`,null)
      }

      if(value === 'DayOfWeekOfMonth') {
        updateAccordions(2,null,null,`The ${weekOrdinals.find(w=>w.id === scheduleData.weekOrdinal)?.name} ${daysOfWeek.find(d=> d.id === scheduleData.dayOfWeek)?.name} of every month`,null)
      }
    },[scheduleData, onChange, updateAccordions]);

    const updateDayOfMonth = useCallback((value: number) => {
      scheduleData.dayOfMonth = value;
      onChange(scheduleData);
      if(scheduleData.monthTrigger === 'SpecificDate') {
        updateAccordions(2,null,null,`The ${daysOfMonth.find(d=>d.id === scheduleData.dayOfMonth)?.name} day of every month`,null)
      }

    },[onChange, scheduleData, updateAccordions]);

    const updateWeekOrdinal = useCallback((value: number) => {
      scheduleData.weekOrdinal = value;
      onChange(scheduleData);
      updateAccordions(2,null,null,`The ${weekOrdinals.find(w=>w.id === value)?.name} ${daysOfWeek.find(d=> d.id === scheduleData.dayOfWeek)?.name} of every month`,null)
    },[scheduleData, onChange, updateAccordions]);


    const updateDayOfWeek = useCallback((value: number) => {
      scheduleData.dayOfWeek = value;
      onChange(scheduleData);
      updateAccordions(2,null,null,`The ${weekOrdinals.find(w=>w.id === scheduleData.weekOrdinal)?.name} ${daysOfWeek.find(d=> d.id === value)?.name} of every month`,null)
    },[onChange, scheduleData, updateAccordions]);


    const updateInterval = useCallback((value: string) => {
      scheduleData.interval = value;
      onChange(scheduleData);
    },[onChange, scheduleData]);

    const updateHourMinuteInterval = useCallback((value: number) => {
      scheduleData.hourMinuteInterval = value;
      onChange(scheduleData);
    },[onChange, scheduleData]);

      const generateRunDaysDescription = (runDays: number[]) => {
        let selectedDays = '';
        runDays.sort().forEach(r=> {
              switch (r) {
                case 1:
                  selectedDays += `${daysOfWeek[0].name}, `;
                  break;

                case 2:
                  selectedDays += `${daysOfWeek[1].name}, `;
                  break;

                case 3:
                  selectedDays += `${daysOfWeek[2].name}, `;
                  break;

                case 4:
                  selectedDays += `${daysOfWeek[3].name}, `;
                  break;

                case 5:
                  selectedDays += `${daysOfWeek[4].name}, `;
                  break;

                case 6:
                  selectedDays += `${daysOfWeek[5].name}, `;
                  break;

                case 0:
                  selectedDays += `${daysOfWeek[6].name}, `;
                  break;

                default:
                  break;
              }
            });

            let description = 'No schedule selected';

            if(runDays.length > 0)
            description = `Scheduled to run on ${selectedDays}`.slice(0,-2);
            return description;
      }

    const updateRunDays = useCallback((id: number) => {
      const indexOfItem = scheduleData?.runDays.indexOf(id);
      const newNumbers = [...scheduleData?.runDays];
      if(indexOfItem === -1) {
              newNumbers.push(id);
            } else {
              newNumbers.splice(indexOfItem, 1);
            }
      updateAccordions(2,null,null,generateRunDaysDescription(newNumbers),null);
      scheduleData.runDays = newNumbers;
      onChange(scheduleData);

  }, [onChange, scheduleData, updateAccordions]);

    return (
        <>
        <h3>Trigger Schedule</h3>
        {accordions.map(a=>
        <Accordion key={a.id} onChange={updateItem(a)} expanded={a.expanded}>
          <AccordionSummary aria-controls={a.name + 'd-content'} id={a.name + 'd-header'}>
            <Typography sx={{ width: '33%', flexShrink: 0 }}>{a.name}</Typography>
            <Container><Typography sx={{ color: 'text.secondary' }}>{a.expanded ? a.openDescription : a.closedDescription}</Typography></Container>
          </AccordionSummary>
          <AccordionDetails>
            {a.id === 1 ?
              <Container style={{ marginLeft: '33.5%' }}>
                <Container>
                <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="Daily"
                    name="radio-buttons-group"
                    value={scheduleData.schedule}>
                    <FormControlLabel value="Daily" control={<Radio value="Daily" onChange={handleScheduleChange}/>} label="Daily"/>
                    <FormControlLabel value="DaysPerMonth" control={<Radio value="DaysPerMonth" onChange={handleScheduleChange} />} label="Days Per Month" />
                  </RadioGroup>
                </Container>

              </Container>
              : ''}
            {a.id === 2  && scheduleData.schedule === "Daily"? <Container style={{marginLeft: '33.5%'  }}>
              <Container>
                <Grid container justifyContent="center" spacing={1} gap={-10} maxWidth={350}>
                 {daysOfWeek.map(r=>
                   <Grid key={r.id} item xs={4}>
                   <FormControlLabel control={<Checkbox checked={scheduleData.runDays.includes(r.id)} name={r.name} value={r.id} onChange={handleRunDayChange} />} label={r.name}/>
                 </Grid>
                 )}
                    <Grid item xs={4}></Grid>
                    <Grid item xs={4}></Grid>
                </Grid>
                </Container>
              </Container> : ''
              }
            {a.id === 2 && scheduleData.schedule === "DaysPerMonth" ?
             <Container style={{ marginLeft: '33.5%' }}>
              <Container>
              <RadioGroup
                 aria-labelledby="demo-radio-buttons-group-label"
                 defaultValue="SpecificDateOfEveryMonth"
                 name="radio-buttons-group"
                 value={scheduleData.monthTrigger}>
                 <FormControlLabel value="SpecificDateOfEveryMonth" control={<Radio value="SpecificDate" onChange={handleEveryMonthChange}/>} label="Specific date of every month" />
                 <FormControlLabel value="DayOfWeekOfMonth" control={<Radio value="DayOfWeekOfMonth" onChange={handleEveryMonthChange} />} label="Specific day of week of every month" />
               </RadioGroup>
              </Container>

               {scheduleData.monthTrigger === "SpecificDate" ?  <Container><TextField
                                style={{width: '280px'}}
                                select
                                SelectProps={{
                                  MenuProps: {
                                      style: {
                                          maxHeight: 400,
                                      },
                                  },
                                  IconComponent: () => null
                              }}
                                label="Day of month"
                                InputProps={{
                                  startAdornment: <InputAdornment position="start" style={{marginRight:'20px'}}>The </InputAdornment>,
                                  endAdornment: <InputAdornment position="start" style={{marginLeft:'30px'}}> day of every month</InputAdornment>
                                }}
                                variant="standard"
                                onChange={handleDayOfMonthChange}
                                value={scheduleData.dayOfMonth}
                            >
                             {daysOfMonth.map(d => <MenuItem key={d.id} value={d.id} >{d.name}</MenuItem>)}
                            </TextField></Container> : ''}


               {scheduleData.monthTrigger === "DayOfWeekOfMonth" ? <Container>
                            <TextField
                                style={{width: '280px'}}
                                select
                                SelectProps={{
                                  MenuProps: {
                                      style: {
                                          maxHeight: 400,
                                      },
                                  },
                                  IconComponent: () => null
                              }}
                                label="Day of week ordinal"
                                InputProps={{
                                  startAdornment: <InputAdornment position="start" style={{marginRight:'20px'}}>The </InputAdornment>
                                }}
                                variant="standard"
                                onChange={handleWeekOrdinalChange}
                                value={scheduleData.weekOrdinal}
                            >
                             {weekOrdinals.map(d => <MenuItem key={d.id} value={d.id}>{d.name}</MenuItem>)}
                            </TextField>
                            <TextField
                                style={{width: '280px'}}
                                select
                                SelectProps={{
                                  MenuProps: {
                                      style: {
                                          maxHeight: 400,
                                      },
                                  },
                                  IconComponent: () => null
                              }}
                                label="Day of week"
                                InputProps={{
                                  endAdornment: <InputAdornment position="start" style={{marginLeft:'30px'}}> of every month</InputAdornment>
                                }}
                                variant="standard"
                                onChange={handleDayOfWeekChange}
                                value={scheduleData.dayOfWeek}
                            >
                             {daysOfWeek.map(d => <MenuItem key={d.displayId} value={d.id}>{d.name}</MenuItem>)}
                            </TextField>
                            </Container>
                            : ''}
           </Container> : ''}
            {a.id === 3 ? <TimeZonePicker value={scheduleData.timeZone} onSelectCallback={ timeZoneChangeCallback } /> : ''}
            {a.id === 4 ?  <Container style={{ marginLeft: '33.5%' }}>
              <Container>
              <RadioGroup
                 aria-labelledby="demo-radio-buttons-group-label"
                 defaultValue="Interval"
                 name="radio-buttons-group"
                 value={scheduleData.interval}>
                 <FormControlLabel value="Once" control={<Radio value="Once" onChange={handleIntervalChange}/>} label="Once" />
                 <FormControlLabel value="Hourly" control={<Radio value="Hourly" onChange={handleIntervalChange} />} label="Hourly" />
               </RadioGroup>
              </Container>
              {scheduleData.interval !== "Once" ?
              <Container>
                  <TextField
                      type="number"
                      label=""
                      name="hourMinuteInterval"
                      InputProps={{
                        startAdornment: <InputAdornment position="start" style={{marginRight:'20px'}}>Every </InputAdornment>,
                        endAdornment: <InputAdornment position="start" style={{marginLeft:'30px'}}> {hourMinuteIntervalDesc}</InputAdornment>
                      }}
                      value={scheduleData.hourMinuteInterval}
                      onChange={handleHourMinuteIntervalChange}
                  />
              </Container> : ''}

              </Container>: ''}
              {a.id === 5 && scheduleData.interval === "Once" ?
             <Container style={{ marginLeft: '33.5%' }}>
              <Container>
              <TimePicker
                        minutesStep={15}
                        value={scheduleData.startTime}
                        onChange={handleStartTimeChange}/>
                </Container>
                </Container>
              :  ''}
              {a.id === 5 && scheduleData.interval !== "Once" ?
              <Container style={{ marginLeft: '33.5%' }}>
              <Container>
              <TimePicker
                        maxTime={scheduleData.runUntil}
                        value={scheduleData.runAfter}
                        minutesStep={15}
                        label='Run After'
                        onChange={handleRunAfterTimeChange}/>
                </Container>
                <Container style={{marginTop: '10px'}}>
                <TimePicker
                        minTime={scheduleData.runAfter}
                        value={scheduleData.runUntil}
                        minutesStep={15}
                        label='Run Until'
                        onChange={handleRunUntilTimeChange}/>
                </Container>
                {runTimesErrors && <Typography color="error">{runTimesErrors}</Typography>}
                </Container>
                : ''}
          </AccordionDetails>
        </Accordion>)}
      </>
    )
  }
