import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { SxProps } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { GridColDef, useGridApiRef } from "@mui/x-data-grid-premium";
import { DatePicker } from "@mui/x-date-pickers-pro";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ConsultantReportData } from "common/models/Reports/ConsultantReportData";
import { GetConsultantReportData } from "services/ConsultantService";
import GridWithStateWrapper from "components/GridWidthStateWrapper";
import UserPicker from "components/Pickers/UserPicker";
import { NameIdObj } from "common/models/GenericTypes";
import { MenuItem, TextField } from "@mui/material";
import MultipleDivisionsPicker from "components/Pickers/MultipleDivisionPicker";
import MultipleGroupPicker from "components/Pickers/MultipleGroupPicker";
import MultipleUserPicker from "components/Pickers/MultipleUserPicker";

interface Props {
    description: string,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void
}

const ownerSpecialOptions: NameIdObj[] = [
    { id: 0, name: 'My Company' },
    { id: -1, name: 'Me' },
    { id: -2, name: 'My Team' },
    { id: -3, name: 'Unassigned' },
];

const filterElementStyle: SxProps = { flex: '1 1 0', mr: 1 };

export default function ConsultantReport({ description, loadingHandler, errorHandler }: Props) {
    const [ownerId, setOwnerId] = useState(0);
    const apiRef = useGridApiRef();
    const [rows, setRows] = useState<ConsultantReportData[]>([]);
    const [showGrid, setShowGrid] = useState(false);
    const [startDate, setStartDate] = useState<moment.Moment | null>(null);
    const [endDate, setEndDate] = useState<moment.Moment | null>(null);
    const [sourceType, setSourceType] = useState(1);
    const [selectedDivisions, setSelectedDivisions] = useState<number[]>([]);
    const [selectedGroups, setSelectedGroups] = useState<number[]>([]);
    const [selectedUsers, setSelectedUsers] = useState<number[]>([]);

    useEffect(() => {
        setStartDate(moment().startOf('month'));
        setEndDate(moment().endOf('month'));
    }, []);

    const getDataCallback = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        const stringStartDate = startDate && startDate.isValid() ? startDate.format('YYYY-MM-DD') : '';
        const stringEndDate = endDate && endDate.isValid() ? endDate.format('YYYY-MM-DD') : '';
        let sourceIds = '';
        if (sourceType === 2) sourceIds = selectedGroups.join(',');
        else if (sourceType === 3) sourceIds = selectedUsers.join(',');
        const res = await GetConsultantReportData(ownerId, stringStartDate, stringEndDate,sourceType,sourceIds, errorHandler);
        if (res) {
            setShowGrid(true);
            setRows(res);
        }
        loadingHandler && loadingHandler(false);
    }, [loadingHandler, startDate, endDate, sourceType, selectedGroups, selectedUsers, ownerId, errorHandler]);

    const exportAsExcelHandler = useCallback(() => {
        const api = apiRef.current;
        if (api) {
            const m = moment();
            const filename = `Consultant${m.format('YYYYMMDDhhmmss')}`;
            api.exportDataAsExcel({ fileName: filename });
        }
    }, [apiRef]);

    const columns = useMemo<GridColDef[]>(() => {
        return [
            { field: 'Name', width: 300 },
            { field: 'Calls', width: 120 },
            { field: 'CallsCandidate', headerName: 'Candidate Calls', width: 120 },
            { field: 'CallsClient', headerName: 'Client Calls', width: 120 },
            { field: 'Notes', width: 120 },
            { field: 'CustomCallsAndNotes', headerName: 'Custom Calls And Notes', width: 200 },
            { field: 'CallsAndNotes', headerName: 'Calls And Notes', width: 120 },
            { field: 'Meetings', width: 120 },
            { field: 'Messages', headerName: 'Messages', width: 120 },
            { field: 'Submissions', width: 120 },
            { field: 'Floats', width: 120 },
            { field: 'TelephoneScreened', headerName: 'Screened', width: 150 },
            { field: 'Interviews', width: 120 },
            { field: 'Offers', width: 120 },
            { field: 'ReferenceChecksRequested', headerName: 'Reference Checks Requested', width: 200 },
            { field: 'ReferenceChecks', headerName: 'Reference Checks', width: 150 },
            { field: 'OpenJobs', headerName: 'Open Jobs', width: 120 },
            { field: 'Placements', headerName: 'Placements', width: 120 },
            { field: 'PlacementsNew', headerName: 'New Placements', width: 130 },
            { field: 'PlacementsAmendmentsAndExtensions', headerName: 'Placements Amendments And Extensions', width: 250 },
            { field: 'ClientTags', headerName: 'Client Tags', width: 120 },
            { field: 'ContactTags', headerName: 'Contact Tags', width: 120 },
            { field: 'CandidateTags', headerName: 'Candidate Tags', width: 120 },
            { field: 'TotalTags', headerName: 'Total Tags', width: 120 }
        ];
    }, []);

    const onSourceTypeChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const v = +e.target.value;
        setSourceType(v);
    }, []);

    const onDivisionsChange = useCallback((divisions: NameIdObj[]) => {
        setSelectedDivisions(divisions.map(u => u.id));
    }, []);

    const onGroupsChange = useCallback((groups: NameIdObj[]) => {
        setSelectedGroups(groups.map(u => u.id));
    }, []);

    const onUsersChange = useCallback((users: NameIdObj[]) => {
        setSelectedUsers(users.map(u => u.id));
    }, []);


    return (
        <>
            <Box display="flex">
                <Box flex="1 1 0" pr={1}>
                <TextField select label="Source Type" value={sourceType.toString()} onChange={onSourceTypeChange} sx={{width: '100%'}} >
                    <MenuItem value="1">Company</MenuItem>
                    <MenuItem value="2">Group</MenuItem>
                    <MenuItem value="3">User</MenuItem>
                </TextField>
                </Box>
                <DatePicker label="From Date" value={startDate} onChange={m => setStartDate(m)} sx={filterElementStyle} slotProps={{actionBar: { actions: ["clear", "today", "cancel", "accept"] }}} />
                <DatePicker label="To Date" value={endDate} onChange={m => setEndDate(m)} sx={filterElementStyle} slotProps={{actionBar: { actions: ["clear", "today", "cancel", "accept"] }}} />
                <Button variant="contained" color="success" onClick={getDataCallback}>Run Report</Button>
            </Box>
            <Box display={sourceType === 2 ? undefined : 'none'} mt={2}>
                <MultipleGroupPicker value={selectedGroups} onSelect={onGroupsChange} />
            </Box>
            <Box display={sourceType === 3 ? undefined : 'none'} mt={2}>
                <MultipleUserPicker value={selectedUsers} onSelect={onUsersChange} />
            </Box>
            <Typography component="div" variant="h6" mt={2}>Report Description</Typography>
            <Typography component="div" variant="body2">{description}</Typography>
            {showGrid &&
                <>
                    <Box pb="10px" ml="auto">
                        <Button variant="contained" color="success" onClick={exportAsExcelHandler}>Export As Excel</Button>
                    </Box>
                    <GridWithStateWrapper
                        density="compact"
                        gridName="reports-consultant"
                        rows={rows}
                        columns={columns}
                        apiRef={apiRef}
                        getRowId={r => r.UserID}
                        disableRowSelectionOnClick
                    />
                </>
            }
        </>
    );
}
