import React, { useCallback, useEffect, useMemo, useState } from "react";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import PersonIcon from '@mui/icons-material/Person';
import SearchIcon from '@mui/icons-material/Search';
import Popper from "@mui/material/Popper";
import Paper from "@mui/material/Paper";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import { GetCachedRatingReasons, GetCustomerSettingBySettingName } from "services/ConfigurationService";
import { RatingReason } from "common/models/Configuration/Jobs";
import { GetCandidateJob, UpdateCandidateJobRating, UpdateCandidateSavedSearchRating, VerifyRejectionEmail } from "services/CandidatesService";
import RejectionEmailDialog from "../Dialogs/Candidates/RejectionEmailDialog";
import TagsManagementDialog from "../Dialogs/TagsManagementDialog";
import ClickAwayListener from "@mui/material/ClickAwayListener";

interface Props {
    candidateId: number,
    jobId: number
    actionId: number,
    ssid?: number,
    applicationId?: number,
    mr?: string,
    size?: number,
    horizontal?: boolean,
    useRatingReasonsProps?: boolean,
    greenReasonsProp?: RatingReason[],
    yellowReasonsProp?: RatingReason[],
    blueReasonsProp?: RatingReason[],
    redReasonsProp?: RatingReason[],
    defaultRejectionTemplateId?: number,
    fetchApplicationIdOnRejection?: boolean,
    onActionChangeHandler: (newActionId: number, reason: string) => void,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
    successHandler?: (message: string) => void,
}

interface Props2 {
    actionId: number,
    iconBgColor?: string,
    index: number,
    hoveredIndex: number | null,
    reasons: RatingReason[],
    size?: number,
    horizontal?: boolean,
    updateRatingHandler: (actionId: number, reason: string) => void,
    hoveredIndexHandler: (i: number | null) => void,
    closeMenuHandler: () => void
}

const compareRatingReasons = (a: RatingReason, b: RatingReason) => {
    if (b.value < a.value) return 1;
    if (b.value > a.value) return -1;
    return 0;
};

const greenBgColor = "#1da826";
const yellowBgColor = "#fecc02";
const blueBgColor = "#0366cc";
const redBgColor = "#cc0303";

export default function CandidateRatingIcon({ candidateId, jobId, actionId, applicationId = 0, ssid = 0, mr, horizontal = false, fetchApplicationIdOnRejection = false, useRatingReasonsProps, greenReasonsProp, yellowReasonsProp, blueReasonsProp, redReasonsProp, size, defaultRejectionTemplateId = 0, onActionChangeHandler, loadingHandler, errorHandler, successHandler }: Props) {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
    const [greenReasons, setGreenReasons] = useState<RatingReason[]>([]);
    const [yellowReasons, setYellowReasons] = useState<RatingReason[]>([]);
    const [blueReasons, setBlueReasons] = useState<RatingReason[]>([]);
    const [redReasons, setRedReasons] = useState<RatingReason[]>([]);
    const [isRootIconBeingHovered, setIsRootIconBeingHovered] = useState(false);
    const [isRootMenuBeingHovered, setIsRootMenuBeingHovered] = useState(false);
    const [rejectionEmailReason, setRejectionEmailReason] = useState('');
    const [showRejectionEmailDialog, setShowRejectionEmailDialog] = useState(false);
    const [showTagsManagement, setShowTagsManagement] = useState(false);
    const [pendingNewActionId, setPendingNewActionId] = useState<number | null>(null);
    const [pendingNewReason, setPendingNewReason] = useState<string | null>(null);

    const handleRootPaperHovered = useCallback(() => setIsRootMenuBeingHovered(true), []);
    const handleRootPaperLeft = useCallback(() => setIsRootMenuBeingHovered(false), []);
    const handleRootIconLeft = useCallback(() => setIsRootIconBeingHovered(false), []);

    const candidateIdArray = useMemo(() => [candidateId], [candidateId]);

    const handleCloseMenu = useCallback(() => setAnchorEl(null), []);
    const handleOpenMenu = useCallback((e: React.MouseEvent<HTMLElement>) => {
        setIsRootIconBeingHovered(true);
        setAnchorEl(e.currentTarget);
    }, []);

    useEffect(() => {
        if (Boolean(anchorEl) && !isRootIconBeingHovered && !isRootMenuBeingHovered) setAnchorEl(null);
    }, [anchorEl, isRootIconBeingHovered, isRootMenuBeingHovered]);
    
    useEffect(() => {
        const getRatingReasons = async () => {
            const res = await GetCachedRatingReasons(false);
            if (res) {
                const green = res.filter(r => r.actionType === 4).sort(compareRatingReasons);
                const yellow = res.filter(r => r.actionType === 2).sort(compareRatingReasons);
                const blue = res.filter(r => r.actionType === 1).sort(compareRatingReasons);
                const red = res.filter(r => r.actionType === 0).sort(compareRatingReasons);
                if (green.length > 0) setGreenReasons(green);
                if (yellow.length > 0) setYellowReasons(yellow);
                if (blue.length > 0) setBlueReasons(blue);
                if (red.length > 0) setRedReasons(red);
            }
        };
        !useRatingReasonsProps && getRatingReasons();
    }, [useRatingReasonsProps]);

    const selectedActionBgColor = useMemo(() => {
        switch (actionId) {
            case 4: return greenBgColor;
            case 2: return yellowBgColor;
            case 1: return blueBgColor;
            case 0: return redBgColor;
            case 3: return undefined;
            default: return undefined;
        }
    }, [actionId]);
    
    const iconTooltip = useMemo(() => {
        switch (actionId) {
            case -1: return 'Unrated';
            case 4: return 'Good';
            case 2: return 'Possible';
            case 1: return 'Undecided';
            case 0: return 'Unsuitable';
            case 3: return 'To Do';
            default: return 'To Do';
        }
    }, [actionId]);

    const showTagAdminErrorHandler = useCallback((message: string, newActionId: number, reason: string) => {
        errorHandler && errorHandler(message);
        if (message.toLowerCase() === 'not enough tags') {
            setPendingNewActionId(newActionId);
            setPendingNewReason(reason);
            setShowTagsManagement(true);
        }
    }, [errorHandler]);

    const closeRejectionEmailDialoghandler = useCallback(() => {
        setShowRejectionEmailDialog(false);
        setRejectionEmailReason('');
    }, []);

    const setRatingCallback = useCallback(async (newActionId: number, reason: string) => {
        loadingHandler && loadingHandler(true);
        closeRejectionEmailDialoghandler();
        let res: boolean | null = null;
        if (jobId) res = await UpdateCandidateJobRating(candidateId, jobId, newActionId, reason, m => showTagAdminErrorHandler(m, newActionId, reason));
        if (ssid) res = await UpdateCandidateSavedSearchRating(candidateId, ssid, newActionId, errorHandler)
        if (res) {
            successHandler && successHandler('Rating Changed');
            errorHandler && errorHandler('');
            onActionChangeHandler(newActionId, reason);
            setPendingNewActionId(null);
            setPendingNewReason(null);
            handleCloseMenu();
        }
        loadingHandler && loadingHandler(false);
    }, [loadingHandler, closeRejectionEmailDialoghandler, jobId, candidateId, ssid, errorHandler, showTagAdminErrorHandler, successHandler, onActionChangeHandler, handleCloseMenu]);

    const setRedRatingCallback = useCallback(async (newActionId: number, reason: string) => {
        let appId = applicationId;
        if (fetchApplicationIdOnRejection) {
            const cj = await GetCandidateJob(candidateId, jobId);
            if (cj) appId = cj.applicationID;
        }
        if (appId) {
            const rejectionVerification = await VerifyRejectionEmail(candidateId, jobId, errorHandler);
            if (rejectionVerification && rejectionVerification.value) {
                successHandler && successHandler(`A rejection email was previously sent on ${rejectionVerification.value}`);
                await setRatingCallback(newActionId, reason);
            }
            else if (rejectionVerification && !Boolean(rejectionVerification.value)) {
                const rejectionEmailsSetting = await GetCustomerSettingBySettingName('RejectionEmails');
                let rejectionEmails = false;
                if (rejectionEmailsSetting && rejectionEmailsSetting.toLowerCase() === 'true') rejectionEmails = true;
                if(!rejectionEmails) await setRatingCallback(newActionId, reason);
                else {
                    setShowRejectionEmailDialog(true);
                    setRejectionEmailReason(reason);
                }
            }
        }
        else await setRatingCallback(newActionId, reason);
    }, [applicationId, fetchApplicationIdOnRejection, setRatingCallback, candidateId, jobId, errorHandler, successHandler]);

    const tagManagementSuccessHandler = useCallback((message: string) => {
        successHandler && successHandler(message);
        if (pendingNewActionId) setRatingCallback(pendingNewActionId, pendingNewReason ?? '');
    }, [pendingNewActionId, pendingNewReason, setRatingCallback, successHandler]);

    return (
        <>
            <RejectionEmailDialog
                open={showRejectionEmailDialog}
                candidateIds={candidateIdArray}
                jobId={jobId}
                defaultRejectionTemplateId={defaultRejectionTemplateId}
                closeHandler={closeRejectionEmailDialoghandler}
                onRejectionEmailSent={ () => setRatingCallback(0, rejectionEmailReason) }
                errorHandler={errorHandler}
            />
            <TagsManagementDialog
                open={showTagsManagement}
                entityId={3}
                recordIds={candidateIdArray}
                enforceMinRequired
                closeHandler={ () => setShowTagsManagement(false) }
                loadingHandler={ loadingHandler }
                errorHandler={ errorHandler }
                successHandler={ tagManagementSuccessHandler }
            />
            <IconButton
                size="small" sx={{ p: 0, mr: mr }}
                onClick={handleOpenMenu}
                onMouseEnter={ handleOpenMenu }
                onMouseLeave={ handleRootIconLeft }
            >
                <Avatar title={iconTooltip} sx={{ bgcolor: selectedActionBgColor, width: size, height: size }}>
                    { actionId !== -1 ? <PersonIcon /> : <SearchIcon /> }
                </Avatar>
            </IconButton>
            <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} placement="right" disablePortal sx={{ zIndex: t => t.zIndex.appBar - 2 }}>
                <Paper elevation={6} onMouseEnter={ handleRootPaperHovered } onMouseLeave={ handleRootPaperLeft }>
                    <ClickAwayListener onClickAway={ handleCloseMenu }>
                        <MenuList sx={ horizontal ? { display: 'flex', py: 0 } : undefined}>
                            <SubMenuRenderer
                                actionId={4}
                                iconBgColor={greenBgColor}
                                index={1}
                                size={size}
                                updateRatingHandler={setRatingCallback}
                                closeMenuHandler={handleCloseMenu}
                                hoveredIndex={hoveredIndex}
                                hoveredIndexHandler={setHoveredIndex}
                                reasons={useRatingReasonsProps && greenReasonsProp ? greenReasonsProp : greenReasons}
                                horizontal={horizontal}
                            />
                            <SubMenuRenderer
                                actionId={2}
                                iconBgColor={yellowBgColor}
                                index={2}
                                size={size}
                                updateRatingHandler={setRatingCallback}
                                closeMenuHandler={handleCloseMenu}
                                hoveredIndex={hoveredIndex}
                                hoveredIndexHandler={setHoveredIndex}
                                reasons={useRatingReasonsProps && yellowReasonsProp ? yellowReasonsProp : yellowReasons}
                                horizontal={horizontal}
                            />
                            <SubMenuRenderer
                                actionId={1}
                                iconBgColor={blueBgColor}
                                index={3}
                                size={size}
                                updateRatingHandler={setRatingCallback}
                                closeMenuHandler={handleCloseMenu}
                                hoveredIndex={hoveredIndex}
                                hoveredIndexHandler={setHoveredIndex}
                                reasons={useRatingReasonsProps && blueReasonsProp ? blueReasonsProp : blueReasons}
                                horizontal={horizontal}
                            />
                            <SubMenuRenderer
                                actionId={0}
                                iconBgColor={redBgColor}
                                index={4}
                                size={size}
                                updateRatingHandler={setRedRatingCallback}
                                closeMenuHandler={handleCloseMenu}
                                hoveredIndex={hoveredIndex}
                                hoveredIndexHandler={setHoveredIndex}
                                reasons={useRatingReasonsProps && redReasonsProp ? redReasonsProp : redReasons}
                                horizontal={horizontal}
                            />
                            <MenuItem onMouseEnter={ () => setHoveredIndex(5) } onClick={ () => setRatingCallback(3, '') } sx={ horizontal ? { px: 1 } : undefined }>
                                <Avatar sx={{ width: size, height: size }} >
                                    <PersonIcon />
                                </Avatar>
                            </MenuItem>
                        </MenuList>
                    </ClickAwayListener>
                </Paper>
            </Popper>
        </>
    );
}

const SubMenuRenderer = ({ actionId, iconBgColor, index, hoveredIndex, reasons, size, horizontal, updateRatingHandler, hoveredIndexHandler, closeMenuHandler }: Props2) => {
    const [anchor, setAnchor] = useState<HTMLElement | null>(null);

    const onMouseEnterCallback = useCallback((e: React.MouseEvent<HTMLElement>) => {
        hoveredIndexHandler(index);
        setAnchor(e.currentTarget);
    }, [index, hoveredIndexHandler]);

    useEffect(() => {
        if (index !== hoveredIndex) setAnchor(null);
    }, [index, hoveredIndex]);

    return (
        <>
            <MenuItem onMouseEnter={onMouseEnterCallback} onClick={ () => updateRatingHandler(actionId, '') } sx={ horizontal ? { px: 1 } : undefined }>
                <Avatar sx={{ bgcolor: iconBgColor, width: size, height: size }}>
                    <PersonIcon />
                </Avatar>
            </MenuItem>
            {reasons.length > 0 && index === hoveredIndex &&
                <Popper open={Boolean(anchor)} anchorEl={anchor} placement={horizontal ? "bottom-start" : "right-start"} sx={{ zIndex: t => t.zIndex.appBar - 2 }}>
                    <Paper elevation={8}>
                        <MenuList onClick={ closeMenuHandler }>
                            {reasons.map((r, i) => (
                                <MenuItem key={r.id} onClick={ () => updateRatingHandler(actionId, r.value) }>{r.value}</MenuItem>
                            ))}
                        </MenuList>
                    </Paper>
                </Popper>
            }
        </>
    );
};