import { NameIdObjString } from "common/models/GenericTypes";

export const JobBoardCountriesOptions: NameIdObjString[] = [
    { id: "af", name: "Afghanistan" },
    { id: "ax", name: "Aland Islands" },
    { id: "al", name: "Albania" },
    { id: "dz", name: "Algeria" },
    { id: "as", name: "American Samoa" },
    { id: "ad", name: "Andorra" },
    { id: "ao", name: "Angola" },
    { id: "ai", name: "Anguilla" },
    { id: "aq", name: "Antarctica" },
    { id: "ag", name: "Antigua and Barbuda" },
    { id: "ar", name: "Argentina"},
    { id: "am", name: "Armenia"},
    { id: "aw", name: "Aruba"},
    { id: "au", name: "Australia"},
    { id: "at", name: "Austria"},
    { id: "az", name: "Azerbaijan"},
    { id: "bs", name: "Bahamas"},
    { id: "bh", name: "Bahrain"},
    { id: "bd", name: "Bangladesh"},
    { id: "bb", name: "Barbados"},
    { id: "by", name: "Belarus"},
    { id: "be", name: "Belgium"},
    { id: "bz", name: "Belize"},
    { id: "bj", name: "Benin"},
    { id: "bm", name: "Bermuda"},
    { id: "bt", name: "Bhutan"},
    { id: "bo", name: "Bolivia"},
    { id: "ba", name: "Bosnia and Herzegovina"},
    { id: "bw", name: "Botswana" },
    { id: "bv", name: "Bouvet Island" },
    { id: "br", name: "Brazil" },
    { id: "io", name: "British Indian Ocean Territory" },
    { id: "bn", name: "Brunei Darussalam" },
    { id: "bg", name: "Bulgaria" },
    { id: "bf", name: "Burkina Faso" },
    { id: "bi", name: "Burundi" },
    { id: "kh", name: "Cambodia" },
    { id: "cm", name: "Cameroon" },
    { id: "ca", name: "Canada" },
    { id: "cv", name: "Cape Verde" },
    { id: "cb", name: "Caribbean Nations" },
    { id: "ky", name: "Cayman Islands" },
    { id: "cf", name: "Central African Republic" },
    { id: "td", name: "Chad" },
    { id: "cl", name: "Chile" },
    { id: "cn", name: "China" },
    { id: "cx", name: "Christmas Island" },
    { id: "cc", name: "Cocos (Keeling) Islands" },
    { id: "co", name: "Colombia" },
    { id: "km", name: "Comoros" },
    { id: "cg", name: "Congo" },
    { id: "ck", name: "Cook Islands" },
    { id: "cr", name: "Costa Rica" },
    { id: "ci", name: "Cote D'Ivoire (Ivory Coast)" },
    { id: "hr", name: "Croatia" },
    { id: "cu", name: "Cuba" },
    { id: "cy", name: "Cyprus" },
    { id: "cz", name: "Czech Republic" },
    { id: "cd", name: "Democratic Republic of the Congo" },
    { id: "dk", name: "Denmark" },
    { id: "dj", name: "Djibouti" },
    { id: "dm", name: "Dominica" },
    { id: "do", name: "Dominican Republic" },
    { id: "tp", name: "East Timor" },
    { id: "ec", name: "Ecuador" },
    { id: "eg", name: "Egypt" },
    { id: "sv", name: "El Salvador" },
    { id: "gq", name: "Equatorial Guinea" },
    { id: "er", name: "Eritrea" },
    { id: "ee", name: "Estonia" },
    { id: "et", name: "Ethiopia" },
    { id: "fk", name: "Falkland Islands (Malvinas)" },
    { id: "fo", name: "Faroe Islands" },
    { id: "fm", name: "Federated States of Micronesia" },
    { id: "fj", name: "Fiji" },
    { id: "fi", name: "Finland" },
    { id: "fr", name: "France" },
    { id: "fx", name: "France, Metropolitan" },
    { id: "gf", name: "French Guiana" },
    { id: "pf", name: "French Polynesia" },
    { id: "tf", name: "French Southern Territories" },
    { id: "ga", name: "Gabon" },
    { id: "gm", name: "Gambia" },
    { id: "ge", name: "Georgia" },
    { id: "de", name: "Germany" },
    { id: "gh", name: "Ghana" },
    { id: "gi", name: "Gibraltar" },
    { id: "gr", name: "Greece" },
    { id: "gl", name: "Greenland" },
    { id: "gd", name: "Grenada" },
    { id: "gp", name: "Guadeloupe" },
    { id: "gu", name: "Guam" },
    { id: "gt", name: "Guatemala" },
    { id: "gn", name: "Guinea" },
    { id: "gw", name: "Guinea-Bissau" },
    { id: "gy", name: "Guyana" },
    { id: "ht", name: "Haiti" },
    { id: "hm", name: "Heard Island and McDonald Islands" },
    { id: "hn", name: "Honduras" },
    { id: "hk", name: "Hong Kong" },
    { id: "hu", name: "Hungary" },
    { id: "is", name: "Iceland" },
    { id: "in", name: "India" },
    { id: "id", name: "Indonesia" },
    { id: "ir", name: "Iran" },
    { id: "iq", name: "Iraq" },
    { id: "ie", name: "Ireland" },
    { id: "il", name: "Israel" },
    { id: "it", name: "Italy" },
    { id: "jm", name: "Jamaica" },
    { id: "jp", name: "Japan" },
    { id: "jo", name: "Jordan" },
    { id: "kz", name: "Kazakhstan" },
    { id: "ke", name: "Kenya" },
    { id: "ki", name: "Kiribati" },
    { id: "kr", name: "Korea" },
    { id: "kp", name: "Korea (North)" },
    { id: "kw", name: "Kuwait" },
    { id: "kg", name: "Kyrgyzstan" },
    { id: "la", name: "Laos" },
    { id: "lv", name: "Latvia" },
    { id: "lb", name: "Lebanon" },
    { id: "ls", name: "Lesotho" },
    { id: "lr", name: "Liberia" },
    { id: "ly", name: "Libya" },
    { id: "li", name: "Liechtenstein" },
    { id: "lt", name: "Lithuania" },
    { id: "lu", name: "Luxembourg" },
    { id: "mo", name: "Macao" },
    { id: "mk", name: "Macedonia" },
    { id: "mg", name: "Madagascar" },
    { id: "mw", name: "Malawi" },
    { id: "my", name: "Malaysia" },
    { id: "mv", name: "Maldives" },
    { id: "ml", name: "Mali" },
    { id: "mt", name: "Malta" },
    { id: "mh", name: "Marshall Islands" },
    { id: "mq", name: "Martinique" },
    { id: "mr", name: "Mauritania" },
    { id: "mu", name: "Mauritius" },
    { id: "yt", name: "Mayotte" },
    { id: "mx", name: "Mexico" },
    { id: "md", name: "Moldova" },
    { id: "mc", name: "Monaco" },
    { id: "mn", name: "Mongolia" },
    { id: "ms", name: "Montserrat" },
    { id: "ma", name: "Morocco" },
    { id: "mz", name: "Mozambique" },
    { id: "mm", name: "Myanmar" },
    { id: "na", name: "Namibia" },
    { id: "nr", name: "Nauru" },
    { id: "np", name: "Nepal" },
    { id: "nl", name: "Netherlands" },
    { id: "an", name: "Netherlands Antilles" },
    { id: "nc", name: "New Caledonia" },
    { id: "nz", name: "New Zealand" },
    { id: "ni", name: "Nicaragua" },
    { id: "ne", name: "Niger" },
    { id: "ng", name: "Nigeria" },
    { id: "nu", name: "Niue" },
    { id: "nf", name: "Norfolk Island" },
    { id: "mp", name: "Northern Mariana Islands" },
    { id: "no", name: "Norway" },
    { id: "oo", name: "Other" },
    { id: "pk", name: "Pakistan" },
    { id: "pw", name: "Palau" },
    { id: "ps", name: "Palestinian Territory" },
    { id: "pa", name: "Panama" },
    { id: "pg", name: "Papua New Guinea" },
    { id: "py", name: "Paraguay" },
    { id: "pe", name: "Peru" },
    { id: "ph", name: "Philippines" },
    { id: "pn", name: "Pitcairn" },
    { id: "pl", name: "Poland" },
    { id: "pt", name: "Portugal" },
    { id: "pr", name: "Puerto Rico" },
    { id: "qa", name: "Qatar" },
    { id: "re", name: "Reunion" },
    { id: "ro", name: "Romania" },
    { id: "ru", name: "Russian Federation" },
    { id: "rw", name: "Rwanda" },
    { id: "gs", name: "S. Georgia and S. Sandwich Islands" },
    { id: "sh", name: "Saint Helena" },
    { id: "kn", name: "Saint Kitts and Nevis" },
    { id: "lc", name: "Saint Lucia" },
    { id: "pm", name: "Saint Pierre and Miquelon" },
    { id: "vc", name: "Saint Vincent and the Grenadines" },
    { id: "ws", name: "Samoa" },
    { id: "sm", name: "San Marino" },
    { id: "st", name: "Sao Tome and Principe" },
    { id: "sa", name: "Saudi Arabia" },
    { id: "sn", name: "Senegal" },
    { id: "cs", name: "Serbia and Montenegro" },
    { id: "sc", name: "Seychelles" },
    { id: "sl", name: "Sierra Leone" },
    { id: "sg", name: "Singapore" },
    { id: "sk", name: "Slovak Republic" },
    { id: "si", name: "Slovenia" },
    { id: "sb", name: "Solomon Islands" },
    { id: "so", name: "Somalia" },
    { id: "za", name: "South Africa" },
    { id: "es", name: "Spain" },
    { id: "lk", name: "Sri Lanka" },
    { id: "sd", name: "Sudan" },
    { id: "om", name: "Sultanate of Oman" },
    { id: "sr", name: "Suriname" },
    { id: "sj", name: "Svalbard and Jan Mayen" },
    { id: "sz", name: "Swaziland" },
    { id: "se", name: "Sweden" },
    { id: "ch", name: "Switzerland" },
    { id: "sy", name: "Syria" },
    { id: "tw", name: "Taiwan" },
    { id: "tj", name: "Tajikistan" },
    { id: "tz", name: "Tanzania" },
    { id: "th", name: "Thailand" },
    { id: "tl", name: "Timor-Leste" },
    { id: "tg", name: "Togo" },
    { id: "tk", name: "Tokelau" },
    { id: "to", name: "Tonga" },
    { id: "tt", name: "Trinidad and Tobago" },
    { id: "tn", name: "Tunisia" },
    { id: "tr", name: "Turkey" },
    { id: "tm", name: "Turkmenistan" },
    { id: "tc", name: "Turks and Caicos Islands" },
    { id: "tv", name: "Tuvalu" },
    { id: "ug", name: "Uganda" },
    { id: "ua", name: "Ukraine" },
    { id: "ae", name: "United Arab Emirates" },
    { id: "gb", name: "United Kingdom" },
    { id: "us", name: "United States" },
    { id: "uy", name: "Uruguay" },
    { id: "uz", name: "Uzbekistan" },
    { id: "vu", name: "Vanuatu" },
    { id: "va", name: "Vatican City State (Holy See)" },
    { id: "ve", name: "Venezuela" },
    { id: "vn", name: "Vietnam" },
    { id: "vg", name: "Virgin Islands (British)" },
    { id: "vi", name: "Virgin Islands (U.S.)" },
    { id: "wf", name: "Wallis and Futuna" },
    { id: "eh", name: "Western Sahara" },
    { id: "ye", name: "Yemen" },
    { id: "yu", name: "Yugoslavia" },
    { id: "zm", name: "Zambia" },
    { id: "zw", name: "Zimbabwe" }
];

export const JobBoardCountriesStatesMap: Record<string, NameIdObjString[]> = {
    "af": [
        { name: "Badakhshan", id: "BDS" },
        { name: "Badghis", id: "BDG" },
        { name: "Baghlan", id: "BGL" },
        { name: "Balkh", id: "BAL" },
        { name: "Bamyan", id: "BAM" },
        { name: "Daykundi", id: "DAY" },
        { name: "Farah", id: "FRA" },
        { name: "Faryab", id: "FYB" },
        { name: "Ghazni", id: "GHA" },
        { name: "Ghōr", id: "GHO" },
        { name: "Helmand", id: "HEL" },
        { name: "Herat", id: "HER" },
        { name: "Jowzjan", id: "JOW" },
        { name: "Kabul", id: "KAB" },
        { name: "Kandahar", id: "KAN" },
        { name: "Kapisa", id: "KAP" },
        { name: "Khost", id: "KHO" },
        { name: "Kunar", id: "KNR" },
        { name: "Kunduz Province", id: "KDZ" },
        { name: "Laghman", id: "LAG" },
        { name: "Logar", id: "LOG" },
        { name: "Nangarhar", id: "NAN" },
        { name: "Nimruz", id: "NIM" },
        { name: "Nuristan", id: "NUR" },
        { name: "Paktia", id: "PIA" },
        { name: "Paktika", id: "PKA" },
        { name: "Panjshir", id: "PAN" },
        { name: "Parwan", id: "PAR" },
        { name: "Samangan", id: "SAM" },
        { name: "Sar-e Pol", id: "SAR" },
        { name: "Takhar", id: "TAK" },
        { name: "Urozgan", id: "URU" },
        { name: "Zabul", id: "ZAB" }
    ],
    "al": [
        { name: "Berat County", id: "01" },
        { name: "Berat District", id: "BR" },
        { name: "Bulqizë District", id: "BU" },
        { name: "Delvinë District", id: "DL" },
        { name: "Devoll District", id: "DV" },
        { name: "Dibër County", id: "09" },
        { name: "Dibër District", id: "DI" },
        { name: "Durrës County", id: "02" },
        { name: "Durrës District", id: "DR" },
        { name: "Elbasan County", id: "03" },
        { name: "Fier County", id: "04" },
        { name: "Fier District", id: "FR" },
        { name: "Gjirokastër County", id: "05" },
        { name: "Gjirokastër District", id: "GJ" },
        { name: "Gramsh District", id: "GR" },
        { name: "Has District", id: "HA" },
        { name: "Kavajë District", id: "KA" },
        { name: "Kolonjë District", id: "ER" },
        { name: "Korçë County", id: "06" },
        { name: "Korçë District", id: "KO" },
        { name: "Krujë District", id: "KR" },
        { name: "Kuçovë District", id: "KC" },
        { name: "Kukës County", id: "07" },
        { name: "Kukës District", id: "KU" },
        { name: "Kurbin District", id: "KB" },
        { name: "Lezhë County", id: "08" },
        { name: "Lezhë District", id: "LE" },
        { name: "Librazhd District", id: "LB" },
        { name: "Lushnjë District", id: "LU" },
        { name: "Malësi e Madhe District", id: "MM" },
        { name: "Mallakastër District", id: "MK" },
        { name: "Mat District", id: "MT" },
        { name: "Mirditë District", id: "MR" },
        { name: "Peqin District", id: "PQ" },
        { name: "Përmet District", id: "PR" },
        { name: "Pogradec District", id: "PG" },
        { name: "Pukë District", id: "PU" },
        { name: "Sarandë District", id: "SR" },
        { name: "Shkodër County", id: "10" },
        { name: "Shkodër District", id: "SH" },
        { name: "Skrapar District", id: "SK" },
        { name: "Tepelenë District", id: "TE" },
        { name: "Tirana County", id: "11" },
        { name: "Tirana District", id: "TR" },
        { name: "Tropojë District", id: "TP" },
        { name: "Vlorë County", id: "12" },
        { name: "Vlorë District", id: "VL" }
    ],
    "dz": [
        { name: "Adrar", id: "01" },
        { name: "Aïn Defla", id: "44" },
        { name: "Aïn Témouchent", id: "46" },
        { name: "Algiers", id: "16" },
        { name: "Annaba", id: "23" },
        { name: "Batna", id: "05" },
        { name: "Béchar", id: "08" },
        { name: "Béjaïa", id: "06" },
        { name: "Béni Abbès", id: "53" },
        { name: "Biskra", id: "07" },
        { name: "Blida", id: "09" },
        { name: "Bordj Baji Mokhtar", id: "52" },
        { name: "Bordj Bou Arréridj", id: "34" },
        { name: "Bouïra", id: "10" },
        { name: "Boumerdès", id: "35" },
        { name: "Chlef", id: "02" },
        { name: "Constantine", id: "25" },
        { name: "Djanet", id: "56" },
        { name: "Djelfa", id: "17" },
        { name: "El Bayadh", id: "32" },
        { name: "El M'ghair", id: "49" },
        { name: "El Menia", id: "50" },
        { name: "El Oued", id: "39" },
        { name: "El Tarf", id: "36" },
        { name: "Ghardaïa", id: "47" },
        { name: "Guelma", id: "24" },
        { name: "Illizi", id: "33" },
        { name: "In Guezzam", id: "58" },
        { name: "In Salah", id: "57" },
        { name: "Jijel", id: "18" },
        { name: "Khenchela", id: "40" },
        { name: "Laghouat", id: "03" },
        { name: "M'Sila", id: "28" },
        { name: "Mascara", id: "29" },
        { name: "Médéa", id: "26" },
        { name: "Mila", id: "43" },
        { name: "Mostaganem", id: "27" },
        { name: "Naama", id: "45" },
        { name: "Oran", id: "31" },
        { name: "Ouargla", id: "30" },
        { name: "Ouled Djellal", id: "51" },
        { name: "Oum El Bouaghi", id: "04" },
        { name: "Relizane", id: "48" },
        { name: "Saïda", id: "20" },
        { name: "Sétif", id: "19" },
        { name: "Sidi Bel Abbès", id: "22" },
        { name: "Skikda", id: "21" },
        { name: "Souk Ahras", id: "41" },
        { name: "Tamanghasset", id: "11" },
        { name: "Tébessa", id: "12" },
        { name: "Tiaret", id: "14" },
        { name: "Timimoun", id: "54" },
        { name: "Tindouf", id: "37" },
        { name: "Tipasa", id: "42" },
        { name: "Tissemsilt", id: "38" },
        { name: "Tizi Ouzou", id: "15" },
        { name: "Tlemcen", id: "13" },
        { name: "Touggourt", id: "55" }
    ],
    "ad": [
        { name: "Andorra la Vella", id: "07" },
        { name: "Canillo", id: "02" },
        { name: "Encamp", id: "03" },
        { name: "Escaldes-Engordany", id: "08" },
        { name: "La Massana", id: "04" },
        { name: "Ordino", id: "05" },
        { name: "Sant Julià de Lòria", id: "06" }
    ],
    "ao": [
        { name: "Bengo Province", id: "BGO" },
        { name: "Benguela Province", id: "BGU" },
        { name: "Bié Province", id: "BIE" },
        { name: "Cabinda Province", id: "CAB" },
        { name: "Cuando Cubango Province", id: "CCU" },
        { name: "Cuanza Norte Province", id: "CNO" },
        { name: "Cuanza Sul", id: "CUS" },
        { name: "Cunene Province", id: "CNN" },
        { name: "Huambo Province", id: "HUA" },
        { name: "Huíla Province", id: "HUI" },
        { name: "Luanda Province", id: "LUA" },
        { name: "Lunda Norte Province", id: "LNO" },
        { name: "Lunda Sul Province", id: "LSU" },
        { name: "Malanje Province", id: "MAL" },
        { name: "Moxico Province", id: "MOX" },
        { name: "Uíge Province", id: "UIG" },
        { name: "Zaire Province", id: "ZAI" }
    ],
    "ag": [
        { name: "Barbuda", id: "10" },
        { name: "Redonda", id: "11" },
        { name: "Saint George Parish", id: "03" },
        { name: "Saint John Parish", id: "04" },
        { name: "Saint Mary Parish", id: "05" },
        { name: "Saint Paul Parish", id: "06" },
        { name: "Saint Peter Parish", id: "07" },
        { name: "Saint Philip Parish", id: "08" }
    ],
    "ar": [
        { name: "Buenos Aires", id: "B" },
        { name: "Catamarca", id: "K" },
        { name: "Chaco", id: "H" },
        { name: "Chubut", id: "U" },
        { name: "Ciudad Autónoma de Buenos Aires", id: "C" },
        { name: "Córdoba", id: "X" },
        { name: "Corrientes", id: "W" },
        { name: "Entre Ríos", id: "E" },
        { name: "Formosa", id: "P" },
        { name: "Jujuy", id: "Y" },
        { name: "La Pampa", id: "L" },
        { name: "La Rioja", id: "F" },
        { name: "Mendoza", id: "M" },
        { name: "Misiones", id: "N" },
        { name: "Neuquén", id: "Q" },
        { name: "Río Negro", id: "R" },
        { name: "Salta", id: "A" },
        { name: "San Juan", id: "J" },
        { name: "San Luis", id: "D" },
        { name: "Santa Cruz", id: "Z" },
        { name: "Santa Fe", id: "S" },
        { name: "Santiago del Estero", id: "G" },
        { name: "Tierra del Fuego", id: "V" },
        { name: "Tucumán", id: "T" }
    ],
    "am": [
        { name: "Aragatsotn Region", id: "AG" },
        { name: "Ararat Province", id: "AR" },
        { name: "Armavir Region", id: "AV" },
        { name: "Gegharkunik Province", id: "GR" },
        { name: "Kotayk Region", id: "KT" },
        { name: "Lori Region", id: "LO" },
        { name: "Shirak Region", id: "SH" },
        { name: "Syunik Province", id: "SU" },
        { name: "Tavush Region", id: "TV" },
        { name: "Vayots Dzor Region", id: "VD" },
        { name: "Yerevan", id: "ER" }
    ],
    "au": [
        { name: "Australian Capital Territory", id: "ACT" },
        { name: "New South Wales", id: "NSW" },
        { name: "Northern Territory", id: "NT" },
        { name: "Queensland", id: "QLD" },
        { name: "South Australia", id: "SA" },
        { name: "Tasmania", id: "TAS" },
        { name: "Victoria", id: "VIC" },
        { name: "Western Australia", id: "WA" }
    ],
    "at": [
        { name: "Burgenland", id: "1" },
        { name: "Carinthia", id: "2" },
        { name: "Lower Austria", id: "3" },
        { name: "Salzburg", id: "5" },
        { name: "Styria", id: "6" },
        { name: "Tyrol", id: "7" },
        { name: "Upper Austria", id: "4" },
        { name: "Vienna", id: "9" },
        { name: "Vorarlberg", id: "8" }
    ],
    "az": [
        { name: "Absheron District", id: "ABS" },
        { name: "Agdam District", id: "AGM" },
        { name: "Agdash District", id: "AGS" },
        { name: "Aghjabadi District", id: "AGC" },
        { name: "Agstafa District", id: "AGA" },
        { name: "Agsu District", id: "AGU" },
        { name: "Astara District", id: "AST" },
        { name: "Babek District", id: "BAB" },
        { name: "Baku", id: "BA" },
        { name: "Balakan District", id: "BAL" },
        { name: "Barda District", id: "BAR" },
        { name: "Beylagan District", id: "BEY" },
        { name: "Bilasuvar District", id: "BIL" },
        { name: "Dashkasan District", id: "DAS" },
        { name: "Fizuli District", id: "FUZ" },
        { name: "Ganja", id: "GA" },
        { name: "Gədəbəy", id: "GAD" },
        { name: "Gobustan District", id: "QOB" },
        { name: "Goranboy District", id: "GOR" },
        { name: "Goychay", id: "GOY" },
        { name: "Goygol District", id: "GYG" },
        { name: "Hajigabul District", id: "HAC" },
        { name: "Imishli District", id: "IMI" },
        { name: "Ismailli District", id: "ISM" },
        { name: "Jabrayil District", id: "CAB" },
        { name: "Jalilabad District", id: "CAL" },
        { name: "Julfa District", id: "CUL" },
        { name: "Kalbajar District", id: "KAL" },
        { name: "Kangarli District", id: "KAN" },
        { name: "Khachmaz District", id: "XAC" },
        { name: "Khizi District", id: "XIZ" },
        { name: "Khojali District", id: "XCI" },
        { name: "Kurdamir District", id: "KUR" },
        { name: "Lachin District", id: "LAC" },
        { name: "Lankaran", id: "LAN" },
        { name: "Lankaran District", id: "LA" },
        { name: "Lerik District", id: "LER" },
        { name: "Martuni", id: "XVD" },
        { name: "Masally District", id: "MAS" },
        { name: "Mingachevir", id: "MI" },
        { name: "Nakhchivan Autonomous Republic", id: "NX" },
        { name: "Neftchala District", id: "NEF" },
        { name: "Oghuz District", id: "OGU" },
        { name: "Ordubad District", id: "ORD" },
        { name: "Qabala District", id: "QAB" },
        { name: "Qakh District", id: "QAX" },
        { name: "Qazakh District", id: "QAZ" },
        { name: "Quba District", id: "QBA" },
        { name: "Qubadli District", id: "QBI" },
        { name: "Qusar District", id: "QUS" },
        { name: "Saatly District", id: "SAT" },
        { name: "Sabirabad District", id: "SAB" },
        { name: "Sadarak District", id: "SAD" },
        { name: "Salyan District", id: "SAL" },
        { name: "Samukh District", id: "SMX" },
        { name: "Shabran District", id: "SBN" },
        { name: "Shahbuz District", id: "SAH" },
        { name: "Shaki", id: "SA" },
        { name: "Shaki District", id: "SAK" },
        { name: "Shamakhi District", id: "SMI" },
        { name: "Shamkir District", id: "SKR" },
        { name: "Sharur District", id: "SAR" },
        { name: "Shirvan", id: "SR" },
        { name: "Shusha District", id: "SUS" },
        { name: "Siazan District", id: "SIY" },
        { name: "Sumqayit", id: "SM" },
        { name: "Tartar District", id: "TAR" },
        { name: "Tovuz District", id: "TOV" },
        { name: "Ujar District", id: "UCA" },
        { name: "Yardymli District", id: "YAR" },
        { name: "Yevlakh", id: "YE" },
        { name: "Yevlakh District", id: "YEV" },
        { name: "Zangilan District", id: "ZAN" },
        { name: "Zaqatala District", id: "ZAQ" },
        { name: "Zardab District", id: "ZAR" }
    ],
    "bs": [
        { name: "Acklins", id: "AK" },
        { name: "Acklins and Crooked Islands", id: "AC" },
        { name: "Berry Islands", id: "BY" },
        { name: "Bimini", id: "BI" },
        { name: "Black Point", id: "BP" },
        { name: "Cat Island", id: "CI" },
        { name: "Central Abaco", id: "CO" },
        { name: "Central Andros", id: "CS" },
        { name: "Central Eleuthera", id: "CE" },
        { name: "Crooked Island", id: "CK" },
        { name: "East Grand Bahama", id: "EG" },
        { name: "Exuma", id: "EX" },
        { name: "Freeport", id: "FP" },
        { name: "Fresh Creek", id: "FC" },
        { name: "Governor's Harbour", id: "GH" },
        { name: "Grand Cay", id: "GC" },
        { name: "Green Turtle Cay", id: "GT" },
        { name: "Harbour Island", id: "HI" },
        { name: "High Rock", id: "HR" },
        { name: "Hope Town", id: "HT" },
        { name: "Inagua", id: "IN" },
        { name: "Kemps Bay", id: "KB" },
        { name: "Long Island", id: "LI" },
        { name: "Mangrove Cay", id: "MC" },
        { name: "Marsh Harbour", id: "MH" },
        { name: "Mayaguana District", id: "MG" },
        { name: "New Providence", id: "NP" },
        { name: "Nichollstown and Berry Islands", id: "NB" },
        { name: "North Abaco", id: "NO" },
        { name: "North Andros", id: "NS" },
        { name: "North Eleuthera", id: "NE" },
        { name: "Ragged Island", id: "RI" },
        { name: "Rock Sound", id: "RS" },
        { name: "Rum Cay District", id: "RC" },
        { name: "San Salvador and Rum Cay", id: "SR" },
        { name: "San Salvador Island", id: "SS" },
        { name: "Sandy Point", id: "SP" },
        { name: "South Abaco", id: "SO" },
        { name: "South Andros", id: "SA" },
        { name: "South Eleuthera", id: "SE" },
        { name: "Spanish Wells", id: "SW" },
        { name: "West Grand Bahama", id: "WG" }
    ],
    "bh": [
        { name: "Capital Governorate", id: "13" },
        { name: "Central Governorate", id: "16" },
        { name: "Muharraq Governorate", id: "15" },
        { name: "Northern Governorate", id: "17" },
        { name: "Southern Governorate", id: "14" }
    ],
    "bd": [
        { name: "Bagerhat District", id: "05" },
        { name: "Bahadia", id: "33" },
        { name: "Bandarban District", id: "01" },
        { name: "Barguna District", id: "02" },
        { name: "Barisal District", id: "06" },
        { name: "Barisal Division", id: "A" },
        { name: "Bhola District", id: "07" },
        { name: "Bogra District", id: "03" },
        { name: "Brahmanbaria District", id: "04" },
        { name: "Chandpur District", id: "09" },
        { name: "Chapai Nawabganj District", id: "45" },
        { name: "Chittagong District", id: "10" },
        { name: "Chittagong Division", id: "B" },
        { name: "Chuadanga District", id: "12" },
        { name: "Comilla District", id: "08" },
        { name: "Cox's Bazar District", id: "11" },
        { name: "Dhaka District", id: "13" },
        { name: "Dhaka Division", id: "C" },
        { name: "Dinajpur District", id: "14" },
        { name: "Faridpur District", id: "15" },
        { name: "Feni District", id: "16" },
        { name: "Gaibandha District", id: "19" },
        { name: "Gazipur District", id: "18" },
        { name: "Gopalganj District", id: "17" },
        { name: "Habiganj District", id: "20" },
        { name: "Jamalpur District", id: "21" },
        { name: "Jessore District", id: "22" },
        { name: "Jhalokati District", id: "25" },
        { name: "Jhenaidah District", id: "23" },
        { name: "Joypurhat District", id: "24" },
        { name: "Khagrachari District", id: "29" },
        { name: "Khulna District", id: "27" },
        { name: "Khulna Division", id: "D" },
        { name: "Kishoreganj District", id: "26" },
        { name: "Kurigram District", id: "28" },
        { name: "Kushtia District", id: "30" },
        { name: "Lakshmipur District", id: "31" },
        { name: "Lalmonirhat District", id: "32" },
        { name: "Madaripur District", id: "36" },
        { name: "Meherpur District", id: "39" },
        { name: "Moulvibazar District", id: "38" },
        { name: "Munshiganj District", id: "35" },
        { name: "Mymensingh District", id: "34" },
        { name: "Mymensingh Division", id: "H" },
        { name: "Naogaon District", id: "48" },
        { name: "Narail District", id: "43" },
        { name: "Narayanganj District", id: "40" },
        { name: "Natore District", id: "44" },
        { name: "Netrokona District", id: "41" },
        { name: "Nilphamari District", id: "46" },
        { name: "Noakhali District", id: "47" },
        { name: "Pabna District", id: "49" },
        { name: "Panchagarh District", id: "52" },
        { name: "Patuakhali District", id: "51" },
        { name: "Pirojpur District", id: "50" },
        { name: "Rajbari District", id: "53" },
        { name: "Rajshahi District", id: "54" },
        { name: "Rajshahi Division", id: "E" },
        { name: "Rangamati Hill District", id: "56" },
        { name: "Rangpur District", id: "55" },
        { name: "Rangpur Division", id: "F" },
        { name: "Satkhira District", id: "58" },
        { name: "Shariatpur District", id: "62" },
        { name: "Sherpur District", id: "57" },
        { name: "Sirajganj District", id: "59" },
        { name: "Sunamganj District", id: "61" },
        { name: "Sylhet District", id: "60" },
        { name: "Sylhet Division", id: "G" },
        { name: "Tangail District", id: "63" },
        { name: "Thakurgaon District", id: "64" }
    ],
    "bb": [
        { name: "Christ Church", id: "01" },
        { name: "Saint Andrew", id: "02" },
        { name: "Saint George", id: "03" },
        { name: "Saint James", id: "04" },
        { name: "Saint John", id: "05" },
        { name: "Saint Joseph", id: "06" },
        { name: "Saint Lucy", id: "07" },
        { name: "Saint Michael", id: "08" },
        { name: "Saint Peter", id: "09" },
        { name: "Saint Philip", id: "10" },
        { name: "Saint Thomas", id: "11" }
    ],
    "by": [
        { name: "Brest Region", id: "BR" },
        { name: "Gomel Region", id: "HO" },
        { name: "Grodno Region", id: "HR" },
        { name: "Minsk", id: "HM" },
        { name: "Minsk Region", id: "MI" },
        { name: "Mogilev Region", id: "MA" },
        { name: "Vitebsk Region", id: "VI" }
    ],
    "be": [
        { name: "Antwerp", id: "VAN" },
        { name: "Brussels-Capital Region", id: "BRU" },
        { name: "East Flanders", id: "VOV" },
        { name: "Flanders", id: "VLG" },
        { name: "Flemish Brabant", id: "VBR" },
        { name: "Hainaut", id: "WHT" },
        { name: "Liège", id: "WLG" },
        { name: "Limburg", id: "VLI" },
        { name: "Luxembourg", id: "WLX" },
        { name: "Namur", id: "WNA" },
        { name: "Wallonia", id: "WAL" },
        { name: "Walloon Brabant", id: "WBR" },
        { name: "West Flanders", id: "VWV" }
    ],
    "bz": [
        { name: "Belize District", id: "BZ" },
        { name: "Cayo District", id: "CY" },
        { name: "Corozal District", id: "CZL" },
        { name: "Orange Walk District", id: "OW" },
        { name: "Stann Creek District", id: "SC" },
        { name: "Toledo District", id: "TOL" }
    ],
    "bj": [
        { name: "Alibori Department", id: "AL" },
        { name: "Atakora Department", id: "AK" },
        { name: "Atlantique Department", id: "AQ" },
        { name: "Borgou Department", id: "BO" },
        { name: "Collines Department", id: "CO" },
        { name: "Donga Department", id: "DO" },
        { name: "Kouffo Department", id: "KO" },
        { name: "Littoral Department", id: "LI" },
        { name: "Mono Department", id: "MO" },
        { name: "Ouémé Department", id: "OU" },
        { name: "Plateau Department", id: "PL" },
        { name: "Zou Department", id: "ZO" }
    ],
    "bm": [
        { name: "Devonshire Parish", id: "DEV" },
        { name: "Hamilton Municipality", id: "HAM" },
        { name: "Hamilton Parish", id: "HA" },
        { name: "Paget Parish", id: "PAG" },
        { name: "Pembroke Parish", id: "PEM" },
        { name: "Saint George's Municipality", id: "SG" },
        { name: "Saint George's Parish", id: "SGE" },
        { name: "Sandys Parish", id: "SAN" },
        { name: "Smith's Parish,", id: "SMI" },
        { name: "Southampton Parish", id: "SOU" },
        { name: "Warwick Parish", id: "WAR" }
    ],
    "bt": [
        { name: "Bumthang District", id: "33" },
        { name: "Chukha District", id: "12" },
        { name: "Dagana District", id: "22" },
        { name: "Gasa District", id: "GA" },
        { name: "Haa District", id: "13" },
        { name: "Lhuntse District", id: "44" },
        { name: "Mongar District", id: "42" },
        { name: "Paro District", id: "11" },
        { name: "Pemagatshel District", id: "43" },
        { name: "Punakha District", id: "23" },
        { name: "Samdrup Jongkhar District", id: "45" },
        { name: "Samtse District", id: "14" },
        { name: "Sarpang District", id: "31" },
        { name: "Thimphu District", id: "15" },
        { name: "Trashigang District", id: "41" },
        { name: "Trongsa District", id: "32" },
        { name: "Tsirang District", id: "21" },
        { name: "Wangdue Phodrang District", id: "24" },
        { name: "Zhemgang District", id: "34" }
    ],
    "bo": [
        { name: "Beni Department", id: "B" },
        { name: "Chuquisaca Department", id: "H" },
        { name: "Cochabamba Department", id: "C" },
        { name: "La Paz Department", id: "L" },
        { name: "Oruro Department", id: "O" },
        { name: "Pando Department", id: "N" },
        { name: "Potosí Department", id: "P" },
        { name: "Santa Cruz Department", id: "S" },
        { name: "Tarija Department", id: "T" }
    ],
    "ba": [
        { name: "Bosnian Podrinje Canton", id: "05" },
        { name: "Brčko District", id: "BRC" },
        { name: "Canton 10", id: "10" },
        { name: "Central Bosnia Canton", id: "06" },
        { name: "Federation of Bosnia and Herzegovina", id: "BIH" },
        { name: "Herzegovina-Neretva Canton", id: "07" },
        { name: "Posavina Canton", id: "02" },
        { name: "Republika Srpska", id: "SRP" },
        { name: "Sarajevo Canton", id: "09" },
        { name: "Tuzla Canton", id: "03" },
        { name: "Una-Sana Canton", id: "01" },
        { name: "West Herzegovina Canton", id: "08" },
        { name: "Zenica-Doboj Canton", id: "04" }
    ],
    "bw": [
        { name: "Central District", id: "CE" },
        { name: "Ghanzi District", id: "GH" },
        { name: "Kgalagadi District", id: "KG" },
        { name: "Kgatleng District", id: "KL" },
        { name: "Kweneng District", id: "KW" },
        { name: "Ngamiland", id: "NG" },
        { name: "North-East District", id: "NE" },
        { name: "North-West District", id: "NW" },
        { name: "South-East District", id: "SE" },
        { name: "Southern District", id: "SO" }
    ],
    "br": [
        { name: "Acre", id: "AC" },
        { name: "Alagoas", id: "AL" },
        { name: "Amapá", id: "AP" },
        { name: "Amazonas", id: "AM" },
        { name: "Bahia", id: "BA" },
        { name: "Ceará", id: "CE" },
        { name: "Distrito Federal", id: "DF" },
        { name: "Espírito Santo", id: "ES" },
        { name: "Goiás", id: "GO" },
        { name: "Maranhão", id: "MA" },
        { name: "Mato Grosso", id: "MT" },
        { name: "Mato Grosso do Sul", id: "MS" },
        { name: "Minas Gerais", id: "MG" },
        { name: "Pará", id: "PA" },
        { name: "Paraíba", id: "PB" },
        { name: "Paraná", id: "PR" },
        { name: "Pernambuco", id: "PE" },
        { name: "Piauí", id: "PI" },
        { name: "Rio de Janeiro", id: "RJ" },
        { name: "Rio Grande do Norte", id: "RN" },
        { name: "Rio Grande do Sul", id: "RS" },
        { name: "Rondônia", id: "RO" },
        { name: "Roraima", id: "RR" },
        { name: "Santa Catarina", id: "SC" },
        { name: "São Paulo", id: "SP" },
        { name: "Sergipe", id: "SE" },
        { name: "Tocantins", id: "TO" }
    ],
    "bn": [
        { name: "Belait District", id: "BE" },
        { name: "Brunei-Muara District", id: "BM" },
        { name: "Temburong District", id: "TE" },
        { name: "Tutong District", id: "TU" }
    ],
    "bg": [
        { name: "Blagoevgrad Province", id: "01" },
        { name: "Burgas Province", id: "02" },
        { name: "Dobrich Province", id: "08" },
        { name: "Gabrovo Province", id: "07" },
        { name: "Haskovo Province", id: "26" },
        { name: "Kardzhali Province", id: "09" },
        { name: "Kyustendil Province", id: "10" },
        { name: "Lovech Province", id: "11" },
        { name: "Montana Province", id: "12" },
        { name: "Pazardzhik Province", id: "13" },
        { name: "Pernik Province", id: "14" },
        { name: "Pleven Province", id: "15" },
        { name: "Plovdiv Province", id: "16" },
        { name: "Razgrad Province", id: "17" },
        { name: "Ruse Province", id: "18" },
        { name: "Shumen", id: "27" },
        { name: "Silistra Province", id: "19" },
        { name: "Sliven Province", id: "20" },
        { name: "Smolyan Province", id: "21" },
        { name: "Sofia City Province", id: "22" },
        { name: "Sofia Province", id: "23" },
        { name: "Stara Zagora Province", id: "24" },
        { name: "Targovishte Province", id: "25" },
        { name: "Varna Province", id: "03" },
        { name: "Veliko Tarnovo Province", id: "04" },
        { name: "Vidin Province", id: "05" },
        { name: "Vratsa Province", id: "06" },
        { name: "Yambol Province", id: "28" }
    ],
    "bf": [
        { name: "Balé Province", id: "BAL" },
        { name: "Bam Province", id: "BAM" },
        { name: "Banwa Province", id: "BAN" },
        { name: "Bazèga Province", id: "BAZ" },
        { name: "Boucle du Mouhoun Region", id: "01" },
        { name: "Bougouriba Province", id: "BGR" },
        { name: "Boulgou", id: "BLG" },
        { name: "Cascades Region", id: "02" },
        { name: "Centre", id: "03" },
        { name: "Centre-Est Region", id: "04" },
        { name: "Centre-Nord Region", id: "05" },
        { name: "Centre-Ouest Region", id: "06" },
        { name: "Centre-Sud Region", id: "07" },
        { name: "Comoé Province", id: "COM" },
        { name: "Est Region", id: "08" },
        { name: "Ganzourgou Province", id: "GAN" },
        { name: "Gnagna Province", id: "GNA" },
        { name: "Gourma Province", id: "GOU" },
        { name: "Hauts-Bassins Region", id: "09" },
        { name: "Houet Province", id: "HOU" },
        { name: "Ioba Province", id: "IOB" },
        { name: "Kadiogo Province", id: "KAD" },
        { name: "Kénédougou Province", id: "KEN" },
        { name: "Komondjari Province", id: "KMD" },
        { name: "Kompienga Province", id: "KMP" },
        { name: "Kossi Province", id: "KOS" },
        { name: "Koulpélogo Province", id: "KOP" },
        { name: "Kouritenga Province", id: "KOT" },
        { name: "Kourwéogo Province", id: "KOW" },
        { name: "Léraba Province", id: "LER" },
        { name: "Loroum Province", id: "LOR" },
        { name: "Mouhoun", id: "MOU" },
        { name: "Nahouri Province", id: "NAO" },
        { name: "Namentenga Province", id: "NAM" },
        { name: "Nayala Province", id: "NAY" },
        { name: "Nord Region, Burkina Faso", id: "10" },
        { name: "Noumbiel Province", id: "NOU" },
        { name: "Oubritenga Province", id: "OUB" },
        { name: "Oudalan Province", id: "OUD" },
        { name: "Passoré Province", id: "PAS" },
        { name: "Plateau-Central Region", id: "11" },
        { name: "Poni Province", id: "PON" },
        { name: "Sahel Region", id: "12" },
        { name: "Sanguié Province", id: "SNG" },
        { name: "Sanmatenga Province", id: "SMT" },
        { name: "Séno Province", id: "SEN" },
        { name: "Sissili Province", id: "SIS" },
        { name: "Soum Province", id: "SOM" },
        { name: "Sourou Province", id: "SOR" },
        { name: "Sud-Ouest Region", id: "13" },
        { name: "Tapoa Province", id: "TAP" },
        { name: "Tuy Province", id: "TUI" },
        { name: "Yagha Province", id: "YAG" },
        { name: "Yatenga Province", id: "YAT" },
        { name: "Ziro Province", id: "ZIR" },
        { name: "Zondoma Province", id: "ZON" },
        { name: "Zoundwéogo Province", id: "ZOU" }
    ],
    "bi": [
        { name: "Bubanza Province", id: "BB" },
        { name: "Bujumbura Mairie Province", id: "BM" },
        { name: "Bujumbura Rural Province", id: "BL" },
        { name: "Bururi Province", id: "BR" },
        { name: "Cankuzo Province", id: "CA" },
        { name: "Cibitoke Province", id: "CI" },
        { name: "Gitega Province", id: "GI" },
        { name: "Karuzi Province", id: "KR" },
        { name: "Kayanza Province", id: "KY" },
        { name: "Kirundo Province", id: "KI" },
        { name: "Makamba Province", id: "MA" },
        { name: "Muramvya Province", id: "MU" },
        { name: "Muyinga Province", id: "MY" },
        { name: "Mwaro Province", id: "MW" },
        { name: "Ngozi Province", id: "NG" },
        { name: "Rumonge Province", id: "RM" },
        { name: "Rutana Province", id: "RT" },
        { name: "Ruyigi Province", id: "RY" }
    ],
    "kh": [
        { name: "Banteay Meanchey Province", id: "1" },
        { name: "Battambang Province", id: "2" },
        { name: "Kampong Cham Province", id: "3" },
        { name: "Kampong Chhnang Province", id: "4" },
        { name: "Kampong Speu Province", id: "5" },
        { name: "Kampot Province", id: "7" },
        { name: "Kandal Province", id: "8" },
        { name: "Kep Province", id: "23" },
        { name: "Koh Kong Province", id: "9" },
        { name: "Kratié Province", id: "10" },
        { name: "Mondulkiri Province", id: "11" },
        { name: "Oddar Meanchey Province", id: "22" },
        { name: "Pailin Province", id: "24" },
        { name: "Phnom Penh", id: "12" },
        { name: "Preah Vihear Province", id: "13" },
        { name: "Prey Veng Province", id: "14" },
        { name: "Pursat Province", id: "15" },
        { name: "Ratanakiri Province", id: "16" },
        { name: "Siem Reap Province", id: "17" },
        { name: "Sihanoukville Province", id: "18" },
        { name: "Stung Treng Province", id: "19" },
        { name: "Svay Rieng Province", id: "20" },
        { name: "Takéo Province", id: "21" },
        { name: "Tai Po District", id: "NTP" }
    ],
    "cm": [
        { name: "Adamawa", id: "AD" },
        { name: "Centre", id: "CE" },
        { name: "East", id: "ES" },
        { name: "Far North", id: "EN" },
        { name: "Littoral", id: "LT" },
        { name: "North", id: "NO" },
        { name: "Northwest", id: "NW" },
        { name: "South", id: "SU" },
        { name: "Southwest", id: "SW" },
        { name: "West", id: "OU" }
    ],
    "ca": [
        { name: "Alberta", id: "AB" },
        { name: "British Columbia", id: "BC" },
        { name: "Manitoba", id: "MB" },
        { name: "New Brunswick", id: "NB" },
        { name: "Newfoundland and Labrador", id: "NL" },
        { name: "Northwest Territories", id: "NT" },
        { name: "Nova Scotia", id: "NS" },
        { name: "Nunavut", id: "NU" },
        { name: "Ontario", id: "ON" },
        { name: "Prince Edward Island", id: "PE" },
        { name: "Quebec", id: "QC" },
        { name: "Saskatchewan", id: "SK" },
        { name: "Yukon", id: "YT" }
    ],
    "cv": [
        { name: "Barlavento Islands", id: "B" },
        { name: "Boa Vista", id: "BV" },
        { name: "Brava", id: "BR" },
        { name: "Maio Municipality", id: "MA" },
        { name: "Mosteiros", id: "MO" },
        { name: "Paul", id: "PA" },
        { name: "Porto Novo", id: "PN" },
        { name: "Praia", id: "PR" },
        { name: "Ribeira Brava Municipality", id: "RB" },
        { name: "Ribeira Grande", id: "RG" },
        { name: "Ribeira Grande de Santiago", id: "RS" },
        { name: "Sal", id: "SL" },
        { name: "Santa Catarina", id: "CA" },
        { name: "Santa Catarina do Fogo", id: "CF" },
        { name: "Santa Cruz", id: "CR" },
        { name: "São Domingos", id: "SD" },
        { name: "São Filipe", id: "SF" },
        { name: "São Lourenço dos Órgãos", id: "SO" },
        { name: "São Miguel", id: "SM" },
        { name: "São Vicente", id: "SV" },
        { name: "Sotavento Islands", id: "S" },
        { name: "Tarrafal", id: "TA" },
        { name: "Tarrafal de São Nicolau", id: "TS" }
    ],
    "cf": [
        { name: "Bamingui-Bangoran Prefecture", id: "BB" },
        { name: "Bangui", id: "BGF" },
        { name: "Basse-Kotto Prefecture", id: "BK" },
        { name: "Haut-Mbomou Prefecture", id: "HM" },
        { name: "Haute-Kotto Prefecture", id: "HK" },
        { name: "Kémo Prefecture", id: "KG" },
        { name: "Lobaye Prefecture", id: "LB" },
        { name: "Mambéré-Kadéï", id: "HS" },
        { name: "Mbomou Prefecture", id: "MB" },
        { name: "Nana-Grébizi Economic Prefecture", id: "KB" },
        { name: "Nana-Mambéré Prefecture", id: "NM" },
        { name: "Ombella-M'Poko Prefecture", id: "MP" },
        { name: "Ouaka Prefecture", id: "UK" },
        { name: "Ouham Prefecture", id: "AC" },
        { name: "Ouham-Pendé Prefecture", id: "OP" },
        { name: "Sangha-Mbaéré", id: "SE" },
        { name: "Vakaga Prefecture", id: "VK" }
    ],
    "td": [
        { name: "Bahr el Gazel", id: "BG" },
        { name: "Batha Region", id: "BA" },
        { name: "Borkou", id: "BO" },
        { name: "Ennedi Region", id: "EN" },
        { name: "Ennedi-Est", id: "EE" },
        { name: "Ennedi-Ouest", id: "EO" },
        { name: "Guéra Region", id: "GR" },
        { name: "Hadjer-Lamis", id: "HL" },
        { name: "Kanem Region", id: "KA" },
        { name: "Lac Region", id: "LC" },
        { name: "Logone Occidental Region", id: "LO" },
        { name: "Logone Oriental Region", id: "LR" },
        { name: "Mandoul Region", id: "MA" },
        { name: "Mayo-Kebbi Est Region", id: "ME" },
        { name: "Mayo-Kebbi Ouest Region", id: "MO" },
        { name: "Moyen-Chari Region", id: "MC" },
        { name: "N'Djamena", id: "ND" },
        { name: "Ouaddaï Region", id: "OD" },
        { name: "Salamat Region", id: "SA" },
        { name: "Sila Region", id: "SI" },
        { name: "Tandjilé Region", id: "TA" },
        { name: "Tibesti Region", id: "TI" },
        { name: "Wadi Fira Region", id: "WF" }
    ],
    "cl": [
        { name: "Aisén del General Carlos Ibañez del Campo", id: "AI" },
        { name: "Antofagasta", id: "AN" },
        { name: "Arica y Parinacota", id: "AP" },
        { name: "Atacama", id: "AT" },
        { name: "Biobío", id: "BI" },
        { name: "Coquimbo", id: "CO" },
        { name: "La Araucanía", id: "AR" },
        { name: "Libertador General Bernardo O'Higgins", id: "LI" },
        { name: "Los Lagos", id: "LL" },
        { name: "Los Ríos", id: "LR" },
        { name: "Magallanes y de la Antártica Chilena", id: "MA" },
        { name: "Maule", id: "ML" },
        { name: "Ñuble", id: "NB" },
        { name: "Región Metropolitana de Santiago", id: "RM" },
        { name: "Tarapacá", id: "TA" },
        { name: "Valparaíso", id: "VS" }
    ],
    "cn": [
        { name: "Anhui", id: "AH" },
        { name: "Beijing", id: "BJ" },
        { name: "Chongqing", id: "CQ" },
        { name: "Fujian", id: "FJ" },
        { name: "Gansu", id: "GS" },
        { name: "Guangdong", id: "GD" },
        { name: "Guangxi Zhuang", id: "GX" },
        { name: "Guizhou", id: "GZ" },
        { name: "Hainan", id: "HI" },
        { name: "Hebei", id: "HE" },
        { name: "Heilongjiang", id: "HL" },
        { name: "Henan", id: "HA" },
        { name: "Hong Kong SAR", id: "HK" },
        { name: "Hubei", id: "HB" },
        { name: "Hunan", id: "HN" },
        { name: "Inner Mongolia", id: "NM" },
        { name: "Jiangsu", id: "JS" },
        { name: "Jiangxi", id: "JX" },
        { name: "Jilin", id: "JL" },
        { name: "Liaoning", id: "LN" },
        { name: "Macau SAR", id: "MO" },
        { name: "Ningxia Huizu", id: "NX" },
        { name: "Qinghai", id: "QH" },
        { name: "Shaanxi", id: "SN" },
        { name: "Shandong", id: "SD" },
        { name: "Shanghai", id: "SH" },
        { name: "Shanxi", id: "SX" },
        { name: "Sichuan", id: "SC" },
        { name: "Taiwan", id: "TW" },
        { name: "Tianjin", id: "TJ" },
        { name: "Xinjiang", id: "XJ" },
        { name: "Xizang", id: "XZ" },
        { name: "Yunnan", id: "YN" },
        { name: "Zhejiang", id: "ZJ" }
    ],
    "co": [
        { name: "Amazonas", id: "AMA" },
        { name: "Antioquia", id: "ANT" },
        { name: "Arauca", id: "ARA" },
        { name: "Atlántico", id: "ATL" },
        { name: "Bogotá D.C.", id: "DC" },
        { name: "Bolívar", id: "BOL" },
        { name: "Boyacá", id: "BOY" },
        { name: "Caldas", id: "CAL" },
        { name: "Caquetá", id: "CAQ" },
        { name: "Casanare", id: "CAS" },
        { name: "Cauca", id: "CAU" },
        { name: "Cesar", id: "CES" },
        { name: "Chocó", id: "CHO" },
        { name: "Córdoba", id: "COR" },
        { name: "Cundinamarca", id: "CUN" },
        { name: "Guainía", id: "GUA" },
        { name: "Guaviare", id: "GUV" },
        { name: "Huila", id: "HUI" },
        { name: "La Guajira", id: "LAG" },
        { name: "Magdalena", id: "MAG" },
        { name: "Meta", id: "MET" },
        { name: "Nariño", id: "NAR" },
        { name: "Norte de Santander", id: "NSA" },
        { name: "Putumayo", id: "PUT" },
        { name: "Quindío", id: "QUI" },
        { name: "Risaralda", id: "RIS" },
        { name: "San Andrés, Providencia y Santa Catalina", id: "SAP" },
        { name: "Santander", id: "SAN" },
        { name: "Sucre", id: "SUC" },
        { name: "Tolima", id: "TOL" },
        { name: "Valle del Cauca", id: "VAC" },
        { name: "Vaupés", id: "VAU" },
        { name: "Vichada", id: "VID" }
    ],
    "km": [
        { name: "Anjouan", id: "A" },
        { name: "Grande Comore", id: "G" },
        { name: "Mohéli", id: "M" }
    ],
    "cg": [
        { name: "Bouenza Department", id: "11" },
        { name: "Brazzaville", id: "BZV" },
        { name: "Cuvette Department", id: "8" },
        { name: "Cuvette-Ouest Department", id: "15" },
        { name: "Kouilou Department", id: "5" },
        { name: "Lékoumou Department", id: "2" },
        { name: "Likouala Department", id: "7" },
        { name: "Niari Department", id: "9" },
        { name: "Plateaux Department", id: "14" },
        { name: "Pointe-Noire", id: "16" },
        { name: "Pool Department", id: "12" },
        { name: "Sangha Department", id: "13" }
    ],
    "cr": [
        { name: "Alajuela Province", id: "A" },
        { name: "Guanacaste Province", id: "G" },
        { name: "Heredia Province", id: "H" },
        { name: "Limón Province", id: "L" },
        { name: "Provincia de Cartago", id: "C" },
        { name: "Puntarenas Province", id: "P" },
        { name: "San José Province", id: "SJ" }
    ],
    "ci": [
        { name: "Abidjan", id: "AB" },
        { name: "Agnéby", id: "16" },
        { name: "Bafing Region", id: "17" },
        { name: "Bas-Sassandra District", id: "BS" },
        { name: "Bas-Sassandra Region", id: "09" },
        { name: "Comoé District", id: "CM" },
        { name: "Denguélé District", id: "DN" },
        { name: "Denguélé Region", id: "10" },
        { name: "Dix-Huit Montagnes", id: "06" },
        { name: "Fromager", id: "18" },
        { name: "Gôh-Djiboua District", id: "GD" },
        { name: "Haut-Sassandra", id: "02" },
        { name: "Lacs District", id: "LC" },
        { name: "Lacs Region", id: "07" },
        { name: "Lagunes District", id: "LG" },
        { name: "Lagunes region", id: "01" },
        { name: "Marahoué Region", id: "12" },
        { name: "Montagnes District", id: "MG" },
        { name: "Moyen-Cavally", id: "19" },
        { name: "Moyen-Comoé", id: "05" },
        { name: "N'zi-Comoé", id: "11" },
        { name: "Sassandra-Marahoué District", id: "SM" },
        { name: "Savanes Region", id: "03" },
        { name: "Sud-Bandama", id: "15" },
        { name: "Sud-Comoé", id: "13" },
        { name: "Vallée du Bandama District", id: "VB" },
        { name: "Vallée du Bandama Region", id: "04" },
        { name: "Woroba District", id: "WR" },
        { name: "Worodougou", id: "14" },
        { name: "Yamoussoukro", id: "YM" },
        { name: "Zanzan Region", id: "ZZ" }
    ],
    "hr": [
        { name: "Bjelovar-Bilogora County", id: "07" },
        { name: "Brod-Posavina County", id: "12" },
        { name: "Dubrovnik-Neretva County", id: "19" },
        { name: "Istria County", id: "18" },
        { name: "Koprivnica-Križevci County", id: "06" },
        { name: "Krapina-Zagorje County", id: "02" },
        { name: "Lika-Senj County", id: "09" },
        { name: "Međimurje County", id: "20" },
        { name: "Osijek-Baranja County", id: "14" },
        { name: "Požega-Slavonia County", id: "11" },
        { name: "Primorje-Gorski Kotar County", id: "08" },
        { name: "Šibenik-Knin County", id: "15" },
        { name: "Sisak-Moslavina County", id: "03" },
        { name: "Split-Dalmatia County", id: "17" },
        { name: "Varaždin County", id: "05" },
        { name: "Virovitica-Podravina County", id: "10" },
        { name: "Vukovar-Syrmia County", id: "16" },
        { name: "Zadar County", id: "13" },
        { name: "Zagreb", id: "21" },
        { name: "Zagreb County", id: "01" }
    ],
    "cu": [
        { name: "Artemisa Province", id: "15" },
        { name: "Camagüey Province", id: "09" },
        { name: "Ciego de Ávila Province", id: "08" },
        { name: "Cienfuegos Province", id: "06" },
        { name: "Granma Province", id: "12" },
        { name: "Guantánamo Province", id: "14" },
        { name: "Havana Province", id: "03" },
        { name: "Holguín Province", id: "11" },
        { name: "Isla de la Juventud", id: "99" },
        { name: "Las Tunas Province", id: "10" },
        { name: "Matanzas Province", id: "04" },
        { name: "Mayabeque Province", id: "16" },
        { name: "Pinar del Río Province", id: "01" },
        { name: "Sancti Spíritus Province", id: "07" },
        { name: "Santiago de Cuba Province", id: "13" },
        { name: "Villa Clara Province", id: "05" }
    ],
    "cy": [
        { name: "Famagusta District (Mağusa)", id: "04" },
        { name: "Kyrenia District (Keryneia)", id: "06" },
        { name: "Larnaca District (Larnaka)", id: "03" },
        { name: "Limassol District (Leymasun)", id: "02" },
        { name: "Nicosia District (Lefkoşa)", id: "01" },
        { name: "Paphos District (Pafos)", id: "05" }
    ],
    "cz": [
        { name: "Benešov", id: "201" },
        { name: "Beroun", id: "202" },
        { name: "Blansko", id: "641" },
        { name: "Břeclav", id: "644" },
        { name: "Brno-město", id: "642" },
        { name: "Brno-venkov", id: "643" },
        { name: "Bruntál", id: "801" },
        { name: "Česká Lípa", id: "511" },
        { name: "České Budějovice", id: "311" },
        { name: "Český Krumlov", id: "312" },
        { name: "Cheb", id: "411" },
        { name: "Chomutov", id: "422" },
        { name: "Chrudim", id: "531" },
        { name: "Děčín", id: "421" },
        { name: "Domažlice", id: "321" },
        { name: "Frýdek-Místek", id: "802" },
        { name: "Havlíčkův Brod", id: "631" },
        { name: "Hodonín", id: "645" },
        { name: "Hradec Králové", id: "521" },
        { name: "Jablonec nad Nisou", id: "512" },
        { name: "Jeseník", id: "711" },
        { name: "Jičín", id: "522" },
        { name: "Jihlava", id: "632" },
        { name: "Jihočeský kraj", id: "31" },
        { name: "Jihomoravský kraj", id: "64" },
        { name: "Jindřichův Hradec", id: "313" },
        { name: "Karlovarský kraj", id: "41" },
        { name: "Karlovy Vary", id: "412" },
        { name: "Karviná", id: "803" },
        { name: "Kladno", id: "203" },
        { name: "Klatovy", id: "322" },
        { name: "Kolín", id: "204" },
        { name: "Kraj Vysočina", id: "63" },
        { name: "Královéhradecký kraj", id: "52" },
        { name: "Kroměříž", id: "721" },
        { name: "Kutná Hora", id: "205" },
        { name: "Liberec", id: "513" },
        { name: "Liberecký kraj", id: "51" },
        { name: "Litoměřice", id: "423" },
        { name: "Louny", id: "424" },
        { name: "Mělník", id: "206" },
        { name: "Mladá Boleslav", id: "207" },
        { name: "Moravskoslezský kraj", id: "80" },
        { name: "Most", id: "425" },
        { name: "Náchod", id: "523" },
        { name: "Nový Jičín", id: "804" },
        { name: "Nymburk", id: "208" },
        { name: "Olomouc", id: "712" },
        { name: "Olomoucký kraj", id: "71" },
        { name: "Opava", id: "805" },
        { name: "Ostrava-město", id: "806" },
        { name: "Pardubice", id: "532" },
        { name: "Pardubický kraj", id: "53" },
        { name: "Pelhřimov", id: "633" },
        { name: "Písek", id: "314" },
        { name: "Plzeň-jih", id: "324" },
        { name: "Plzeň-město", id: "323" },
        { name: "Plzeň-sever", id: "325" },
        { name: "Plzeňský kraj", id: "32" },
        { name: "Prachatice", id: "315" },
        { name: "Praha-východ", id: "209" },
        { name: "Praha-západ", id: "20A" },
        { name: "Praha, Hlavní město", id: "10" },
        { name: "Přerov", id: "714" },
        { name: "Příbram", id: "20B" },
        { name: "Prostějov", id: "713" },
        { name: "Rakovník", id: "20C" },
        { name: "Rokycany", id: "326" },
        { name: "Rychnov nad Kněžnou", id: "524" },
        { name: "Semily", id: "514" },
        { name: "Sokolov", id: "413" },
        { name: "Strakonice", id: "316" },
        { name: "Středočeský kraj", id: "20" },
        { name: "Šumperk", id: "715" },
        { name: "Svitavy", id: "533" },
        { name: "Tábor", id: "317" },
        { name: "Tachov", id: "327" },
        { name: "Teplice", id: "426" },
        { name: "Třebíč", id: "634" },
        { name: "Trutnov", id: "525" },
        { name: "Uherské Hradiště", id: "722" },
        { name: "Ústecký kraj", id: "42" },
        { name: "Ústí nad Labem", id: "427" },
        { name: "Ústí nad Orlicí", id: "534" },
        { name: "Vsetín", id: "723" },
        { name: "Vyškov", id: "646" },
        { name: "Žďár nad Sázavou", id: "635" },
        { name: "Zlín", id: "724" },
        { name: "Zlínský kraj", id: "72" },
        { name: "Znojmo", id: "647" }
    ],
    "cd": [
        { name: "Bas-Uélé", id: "BU" },
        { name: "Équateur", id: "EQ" },
        { name: "Haut-Katanga", id: "HK" },
        { name: "Haut-Lomami", id: "HL" },
        { name: "Haut-Uélé", id: "HU" },
        { name: "Ituri", id: "IT" },
        { name: "Kasaï", id: "KS" },
        { name: "Kasaï Central", id: "KC" },
        { name: "Kasaï Oriental", id: "KE" },
        { name: "Kinshasa", id: "KN" },
        { name: "Kongo Central", id: "BC" },
        { name: "Kwango", id: "KG" },
        { name: "Kwilu", id: "KL" },
        { name: "Lomami", id: "LO" },
        { name: "Lualaba", id: "LU" },
        { name: "Mai-Ndombe", id: "MN" },
        { name: "Maniema", id: "MA" },
        { name: "Mongala", id: "MO" },
        { name: "Nord-Kivu", id: "NK" },
        { name: "Nord-Ubangi", id: "NU" },
        { name: "Sankuru", id: "SA" },
        { name: "Sud-Kivu", id: "SK" },
        { name: "Sud-Ubangi", id: "SU" },
        { name: "Tanganyika", id: "TA" },
        { name: "Tshopo", id: "TO" },
        { name: "Tshuapa", id: "TU" }
    ],
    "dk": [
        { name: "Capital Region of Denmark", id: "84" },
        { name: "Central Denmark Region", id: "82" },
        { name: "North Denmark Region", id: "81" },
        { name: "Region of Southern Denmark", id: "83" },
        { name: "Region Zealand", id: "85" }
    ],
    "dj": [
        { name: "Ali Sabieh Region", id: "AS" },
        { name: "Arta Region", id: "AR" },
        { name: "Dikhil Region", id: "DI" },
        { name: "Djibouti", id: "DJ" },
        { name: "Obock Region", id: "OB" },
        { name: "Tadjourah Region", id: "TA" }
    ],
    "dm": [
        { name: "Saint Andrew Parish", id: "02" },
        { name: "Saint David Parish", id: "03" },
        { name: "Saint George Parish", id: "04" },
        { name: "Saint John Parish", id: "05" },
        { name: "Saint Joseph Parish", id: "06" },
        { name: "Saint Luke Parish", id: "07" },
        { name: "Saint Mark Parish", id: "08" },
        { name: "Saint Patrick Parish", id: "09" },
        { name: "Saint Paul Parish", id: "10" },
        { name: "Saint Peter Parish", id: "11" }
    ],
    "do": [
        { name: "Azua Province", id: "02" },
        { name: "Baoruco Province", id: "03" },
        { name: "Barahona Province", id: "04" },
        { name: "Dajabón Province", id: "05" },
        { name: "Distrito Nacional", id: "01" },
        { name: "Duarte Province", id: "06" },
        { name: "El Seibo Province", id: "08" },
        { name: "Espaillat Province", id: "09" },
        { name: "Hato Mayor Province", id: "30" },
        { name: "Hermanas Mirabal Province", id: "19" },
        { name: "Independencia", id: "10" },
        { name: "La Altagracia Province", id: "11" },
        { name: "La Romana Province", id: "12" },
        { name: "La Vega Province", id: "13" },
        { name: "María Trinidad Sánchez Province", id: "14" },
        { name: "Monseñor Nouel Province", id: "28" },
        { name: "Monte Cristi Province", id: "15" },
        { name: "Monte Plata Province", id: "29" },
        { name: "Pedernales Province", id: "16" },
        { name: "Peravia Province", id: "17" },
        { name: "Puerto Plata Province", id: "18" },
        { name: "Samaná Province", id: "20" },
        { name: "San Cristóbal Province", id: "21" },
        { name: "San José de Ocoa Province", id: "31" },
        { name: "San Juan Province", id: "22" },
        { name: "San Pedro de Macorís", id: "23" },
        { name: "Sánchez Ramírez Province", id: "24" },
        { name: "Santiago Province", id: "25" },
        { name: "Santiago Rodríguez Province", id: "26" },
        { name: "Santo Domingo Province", id: "32" },
        { name: "Valverde Province", id: "27" }
    ],
    "tl": [
        { name: "Aileu municipality", id: "AL" },
        { name: "Ainaro Municipality", id: "AN" },
        { name: "Baucau Municipality", id: "BA" },
        { name: "Bobonaro Municipality", id: "BO" },
        { name: "Cova Lima Municipality", id: "CO" },
        { name: "Dili municipality", id: "DI" },
        { name: "Ermera District", id: "ER" },
        { name: "Lautém Municipality", id: "LA" },
        { name: "Liquiçá Municipality", id: "LI" },
        { name: "Manatuto District", id: "MT" },
        { name: "Manufahi Municipality", id: "MF" },
        { name: "Viqueque Municipality", id: "VI" }
    ],
    "ec": [
        { name: "Azuay", id: "A" },
        { name: "Bolívar", id: "B" },
        { name: "Cañar", id: "F" },
        { name: "Carchi", id: "C" },
        { name: "Chimborazo", id: "H" },
        { name: "Cotopaxi", id: "X" },
        { name: "El Oro", id: "O" },
        { name: "Esmeraldas", id: "E" },
        { name: "Galápagos", id: "W" },
        { name: "Guayas", id: "G" },
        { name: "Imbabura", id: "I" },
        { name: "Loja", id: "L" },
        { name: "Los Ríos", id: "R" },
        { name: "Manabí", id: "M" },
        { name: "Morona-Santiago", id: "S" },
        { name: "Napo", id: "N" },
        { name: "Orellana", id: "D" },
        { name: "Pastaza", id: "Y" },
        { name: "Pichincha", id: "P" },
        { name: "Santa Elena", id: "SE" },
        { name: "Santo Domingo de los Tsáchilas", id: "SD" },
        { name: "Sucumbíos", id: "U" },
        { name: "Tungurahua", id: "T" },
        { name: "Zamora Chinchipe", id: "Z" }
    ],
    "eg": [
        { name: "Alexandria", id: "ALX" },
        { name: "Aswan", id: "ASN" },
        { name: "Asyut", id: "AST" },
        { name: "Beheira", id: "BH" },
        { name: "Beni Suef", id: "BNS" },
        { name: "Cairo", id: "C" },
        { name: "Dakahlia", id: "DK" },
        { name: "Damietta", id: "DT" },
        { name: "Faiyum", id: "FYM" },
        { name: "Gharbia", id: "GH" },
        { name: "Giza", id: "GZ" },
        { name: "Ismailia", id: "IS" },
        { name: "Kafr el-Sheikh", id: "KFS" },
        { name: "Luxor", id: "LX" },
        { name: "Matrouh", id: "MT" },
        { name: "Minya", id: "MN" },
        { name: "Monufia", id: "MNF" },
        { name: "New Valley", id: "WAD" },
        { name: "North Sinai", id: "SIN" },
        { name: "Port Said", id: "PTS" },
        { name: "Qalyubia", id: "KB" },
        { name: "Qena", id: "KN" },
        { name: "Red Sea", id: "BA" },
        { name: "Sharqia", id: "SHR" },
        { name: "Sohag", id: "SHG" },
        { name: "South Sinai", id: "JS" },
        { name: "Suez", id: "SUZ" }
    ],
    "sv": [
        { name: "Ahuachapán Department", id: "AH" },
        { name: "Cabañas Department", id: "CA" },
        { name: "Chalatenango Department", id: "CH" },
        { name: "Cuscatlán Department", id: "CU" },
        { name: "La Libertad Department", id: "LI" },
        { name: "La Paz Department", id: "PA" },
        { name: "La Unión Department", id: "UN" },
        { name: "Morazán Department", id: "MO" },
        { name: "San Miguel Department", id: "SM" },
        { name: "San Salvador Department", id: "SS" },
        { name: "San Vicente Department", id: "SV" },
        { name: "Santa Ana Department", id: "SA" },
        { name: "Sonsonate Department", id: "SO" },
        { name: "Usulután Department", id: "US" }
    ],
    "gq": [
        { name: "Annobón Province", id: "AN" },
        { name: "Bioko Norte Province", id: "BN" },
        { name: "Bioko Sur Province", id: "BS" },
        { name: "Centro Sur Province", id: "CS" },
        { name: "Insular Region", id: "I" },
        { name: "Kié-Ntem Province", id: "KN" },
        { name: "Litoral Province", id: "LI" },
        { name: "Río Muni", id: "C" },
        { name: "Wele-Nzas Province", id: "WN" }
    ],
    "er": [
        { name: "Anseba Region", id: "AN" },
        { name: "Debub Region", id: "DU" },
        { name: "Gash-Barka Region", id: "GB" },
        { name: "Maekel Region", id: "MA" },
        { name: "Northern Red Sea Region", id: "SK" },
        { name: "Southern Red Sea Region", id: "DK" }
    ],
    "ee": [
        { name: "Harju County", id: "37" },
        { name: "Hiiu County", id: "39" },
        { name: "Ida-Viru County", id: "44" },
        { name: "Järva County", id: "51" },
        { name: "Jõgeva County", id: "49" },
        { name: "Lääne County", id: "57" },
        { name: "Lääne-Viru County", id: "59" },
        { name: "Pärnu County", id: "67" },
        { name: "Põlva County", id: "65" },
        { name: "Rapla County", id: "70" },
        { name: "Saare County", id: "74" },
        { name: "Tartu County", id: "78" },
        { name: "Valga County", id: "82" },
        { name: "Viljandi County", id: "84" },
        { name: "Võru County", id: "86" }
    ],
    "et": [
        { name: "Addis Ababa", id: "AA" },
        { name: "Afar Region", id: "AF" },
        { name: "Amhara Region", id: "AM" },
        { name: "Benishangul-Gumuz Region", id: "BE" },
        { name: "Dire Dawa", id: "DD" },
        { name: "Gambela Region", id: "GA" },
        { name: "Harari Region", id: "HA" },
        { name: "Oromia Region", id: "OR" },
        { name: "Somali Region", id: "SO" },
        { name: "Southern Nations, Nationalities, and Peoples' Region", id: "SN" },
        { name: "Tigray Region", id: "TI" }
    ],
    "fj": [
        { name: "Ba", id: "01" },
        { name: "Bua", id: "02" },
        { name: "Cakaudrove", id: "03" },
        { name: "Central Division", id: "C" },
        { name: "Eastern Division", id: "E" },
        { name: "Kadavu", id: "04" },
        { name: "Lau", id: "05" },
        { name: "Lomaiviti", id: "06" },
        { name: "Macuata", id: "07" },
        { name: "Nadroga-Navosa", id: "08" },
        { name: "Naitasiri", id: "09" },
        { name: "Namosi", id: "10" },
        { name: "Northern Division", id: "N" },
        { name: "Ra", id: "11" },
        { name: "Rewa", id: "12" },
        { name: "Rotuma", id: "R" },
        { name: "Serua", id: "13" },
        { name: "Tailevu", id: "14" },
        { name: "Western Division", id: "W" }
    ],
    "fi": [
        { name: "Åland Islands", id: "01" },
        { name: "Central Finland", id: "08" },
        { name: "Central Ostrobothnia", id: "07" },
        { name: "Eastern Finland Province", id: "IS" },
        { name: "Finland Proper", id: "19" },
        { name: "Kainuu", id: "05" },
        { name: "Kymenlaakso", id: "09" },
        { name: "Lapland", id: "LL" },
        { name: "North Karelia", id: "13" },
        { name: "Northern Ostrobothnia", id: "14" },
        { name: "Northern Savonia", id: "15" },
        { name: "Ostrobothnia", id: "12" },
        { name: "Oulu Province", id: "OL" },
        { name: "Päijänne Tavastia", id: "16" },
        { name: "Pirkanmaa", id: "11" },
        { name: "Satakunta", id: "17" },
        { name: "South Karelia", id: "02" },
        { name: "Southern Ostrobothnia", id: "03" },
        { name: "Southern Savonia", id: "04" },
        { name: "Tavastia Proper", id: "06" },
        { name: "Uusimaa", id: "18" }
    ],
    "fr": [
        { name: "Ain", id: "01" },
        { name: "Aisne", id: "02" },
        { name: "Allier", id: "03" },
        { name: "Alpes-de-Haute-Provence", id: "04" },
        { name: "Alpes-Maritimes", id: "06" },
        { name: "Alsace", id: "6AE" },
        { name: "Ardèche", id: "07" },
        { name: "Ardennes", id: "08" },
        { name: "Ariège", id: "09" },
        { name: "Aube", id: "10" },
        { name: "Aude", id: "11" },
        { name: "Auvergne-Rhône-Alpes", id: "ARA" },
        { name: "Aveyron", id: "12" },
        { name: "Bas-Rhin", id: "67" },
        { name: "Bouches-du-Rhône", id: "13" },
        { name: "Bourgogne-Franche-Comté", id: "BFC" },
        { name: "Bretagne", id: "BRE" },
        { name: "Calvados", id: "14" },
        { name: "Cantal", id: "15" },
        { name: "Centre-Val de Loire", id: "CVL" },
        { name: "Charente", id: "16" },
        { name: "Charente-Maritime", id: "17" },
        { name: "Cher", id: "18" },
        { name: "Clipperton", id: "CP" },
        { name: "Corrèze", id: "19" },
        { name: "Corse", id: "20R" },
        { name: "Corse-du-Sud", id: "2A" },
        { name: "Côte-d'Or", id: "21" },
        { name: "Côtes-d'Armor", id: "22" },
        { name: "Creuse", id: "23" },
        { name: "Deux-Sèvres", id: "79" },
        { name: "Dordogne", id: "24" },
        { name: "Doubs", id: "25" },
        { name: "Drôme", id: "26" },
        { name: "Essonne", id: "91" },
        { name: "Eure", id: "27" },
        { name: "Eure-et-Loir", id: "28" },
        { name: "Finistère", id: "29" },
        { name: "French Guiana", id: "973" },
        { name: "French Polynesia", id: "PF" },
        { name: "French Southern and Antarctic Lands", id: "TF" },
        { name: "Gard", id: "30" },
        { name: "Gers", id: "32" },
        { name: "Gironde", id: "33" },
        { name: "Grand-Est", id: "GES" },
        { name: "Guadeloupe", id: "971" },
        { name: "Haut-Rhin", id: "68" },
        { name: "Haute-Corse", id: "2B" },
        { name: "Haute-Garonne", id: "31" },
        { name: "Haute-Loire", id: "43" },
        { name: "Haute-Marne", id: "52" },
        { name: "Haute-Saône", id: "70" },
        { name: "Haute-Savoie", id: "74" },
        { name: "Haute-Vienne", id: "87" },
        { name: "Hautes-Alpes", id: "05" },
        { name: "Hautes-Pyrénées", id: "65" },
        { name: "Hauts-de-France", id: "HDF" },
        { name: "Hauts-de-Seine", id: "92" },
        { name: "Hérault", id: "34" },
        { name: "Île-de-France", id: "IDF" },
        { name: "Ille-et-Vilaine", id: "35" },
        { name: "Indre", id: "36" },
        { name: "Indre-et-Loire", id: "37" },
        { name: "Isère", id: "38" },
        { name: "Jura", id: "39" },
        { name: "La Réunion", id: "974" },
        { name: "Landes", id: "40" },
        { name: "Loir-et-Cher", id: "41" },
        { name: "Loire", id: "42" },
        { name: "Loire-Atlantique", id: "44" },
        { name: "Loiret", id: "45" },
        { name: "Lot", id: "46" },
        { name: "Lot-et-Garonne", id: "47" },
        { name: "Lozère", id: "48" },
        { name: "Maine-et-Loire", id: "49" },
        { name: "Manche", id: "50" },
        { name: "Marne", id: "51" },
        { name: "Martinique", id: "972" },
        { name: "Mayenne", id: "53" },
        { name: "Mayotte", id: "976" },
        { name: "Métropole de Lyon", id: "69M" },
        { name: "Meurthe-et-Moselle", id: "54" },
        { name: "Meuse", id: "55" },
        { name: "Morbihan", id: "56" },
        { name: "Moselle", id: "57" },
        { name: "Nièvre", id: "58" },
        { name: "Nord", id: "59" },
        { name: "Normandie", id: "NOR" },
        { name: "Nouvelle-Aquitaine", id: "NAQ" },
        { name: "Occitanie", id: "OCC" },
        { name: "Oise", id: "60" },
        { name: "Orne", id: "61" },
        { name: "Paris", id: "75C" },
        { name: "Pas-de-Calais", id: "62" },
        { name: "Pays-de-la-Loire", id: "PDL" },
        { name: "Provence-Alpes-Côte-d’Azur", id: "PAC" },
        { name: "Puy-de-Dôme", id: "63" },
        { name: "Pyrénées-Atlantiques", id: "64" },
        { name: "Pyrénées-Orientales", id: "66" },
        { name: "Rhône", id: "69" },
        { name: "Saint Pierre and Miquelon", id: "PM" },
        { name: "Saint-Barthélemy", id: "BL" },
        { name: "Saint-Martin", id: "MF" },
        { name: "Saône-et-Loire", id: "71" },
        { name: "Sarthe", id: "72" },
        { name: "Savoie", id: "73" },
        { name: "Seine-et-Marne", id: "77" },
        { name: "Seine-Maritime", id: "76" },
        { name: "Seine-Saint-Denis", id: "93" },
        { name: "Somme", id: "80" },
        { name: "Tarn", id: "81" },
        { name: "Tarn-et-Garonne", id: "82" },
        { name: "Territoire de Belfort", id: "90" },
        { name: "Val-d'Oise", id: "95" },
        { name: "Val-de-Marne", id: "94" },
        { name: "Var", id: "83" },
        { name: "Vaucluse", id: "84" },
        { name: "Vendée", id: "85" },
        { name: "Vienne", id: "86" },
        { name: "Vosges", id: "88" },
        { name: "Wallis and Futuna", id: "WF" },
        { name: "Yonne", id: "89" },
        { name: "Yvelines", id: "78" }
    ],
    "ga": [
        { name: "Estuaire Province", id: "1" },
        { name: "Haut-Ogooué Province", id: "2" },
        { name: "Moyen-Ogooué Province", id: "3" },
        { name: "Ngounié Province", id: "4" },
        { name: "Nyanga Province", id: "5" },
        { name: "Ogooué-Ivindo Province", id: "6" },
        { name: "Ogooué-Lolo Province", id: "7" },
        { name: "Ogooué-Maritime Province", id: "8" },
        { name: "Woleu-Ntem Province", id: "9" }
    ],
    "gm": [
        { name: "Banjul", id: "B" },
        { name: "Central River Division", id: "M" },
        { name: "Lower River Division", id: "L" },
        { name: "North Bank Division", id: "N" },
        { name: "Upper River Division", id: "U" },
        { name: "West Coast Division", id: "W" }
    ],
    "ge": [
        { name: "Adjara", id: "AJ" },
        { name: "Autonomous Republic of Abkhazia", id: "AB" },
        { name: "Guria", id: "GU" },
        { name: "Imereti", id: "IM" },
        { name: "Kakheti", id: "KA" },
        { name: "Khelvachauri Municipality", id: "29" },
        { name: "Kvemo Kartli", id: "KK" },
        { name: "Mtskheta-Mtianeti", id: "MM" },
        { name: "Racha-Lechkhumi and Kvemo Svaneti", id: "RL" },
        { name: "Samegrelo-Zemo Svaneti", id: "SZ" },
        { name: "Samtskhe-Javakheti", id: "SJ" },
        { name: "Senaki Municipality", id: "50" },
        { name: "Shida Kartli", id: "SK" },
        { name: "Tbilisi", id: "TB" }
    ],
    "de": [
        { name: "Baden-Württemberg", id: "BW" },
        { name: "Bavaria", id: "BY" },
        { name: "Berlin", id: "BE" },
        { name: "Brandenburg", id: "BB" },
        { name: "Bremen", id: "HB" },
        { name: "Hamburg", id: "HH" },
        { name: "Hesse", id: "HE" },
        { name: "Lower Saxony", id: "NI" },
        { name: "Mecklenburg-Vorpommern", id: "MV" },
        { name: "North Rhine-Westphalia", id: "NW" },
        { name: "Rhineland-Palatinate", id: "RP" },
        { name: "Saarland", id: "SL" },
        { name: "Saxony", id: "SN" },
        { name: "Saxony-Anhalt", id: "ST" },
        { name: "Schleswig-Holstein", id: "SH" },
        { name: "Thuringia", id: "TH" }
    ],
    "gh": [
        { name: "Ahafo", id: "AF" },
        { name: "Ashanti", id: "AH" },
        { name: "Bono", id: "BO" },
        { name: "Bono East", id: "BE" },
        { name: "Central", id: "CP" },
        { name: "Eastern", id: "EP" },
        { name: "Greater Accra", id: "AA" },
        { name: "North East", id: "NE" },
        { name: "Northern", id: "NP" },
        { name: "Oti", id: "OT" },
        { name: "Savannah", id: "SV" },
        { name: "Upper East", id: "UE" },
        { name: "Upper West", id: "UW" },
        { name: "Volta", id: "TV" },
        { name: "Western", id: "WP" },
        { name: "Western North", id: "WN" }
    ],
    "gr": [
        { name: "Achaea Regional Unit", id: "13" },
        { name: "Aetolia-Acarnania Regional Unit", id: "01" },
        { name: "Arcadia Prefecture", id: "12" },
        { name: "Argolis Regional Unit", id: "11" },
        { name: "Attica Region", id: "I" },
        { name: "Boeotia Regional Unit", id: "03" },
        { name: "Central Greece Region", id: "H" },
        { name: "Central Macedonia", id: "B" },
        { name: "Chania Regional Unit", id: "94" },
        { name: "Corfu Prefecture", id: "22" },
        { name: "Corinthia Regional Unit", id: "15" },
        { name: "Crete Region", id: "M" },
        { name: "Drama Regional Unit", id: "52" },
        { name: "East Attica Regional Unit", id: "A2" },
        { name: "East Macedonia and Thrace", id: "A" },
        { name: "Epirus Region", id: "D" },
        { name: "Euboea", id: "04" },
        { name: "Grevena Prefecture", id: "51" },
        { name: "Imathia Regional Unit", id: "53" },
        { name: "Ioannina Regional Unit", id: "33" },
        { name: "Ionian Islands Region", id: "F" },
        { name: "Karditsa Regional Unit", id: "41" },
        { name: "Kastoria Regional Unit", id: "56" },
        { name: "Kefalonia Prefecture", id: "23" },
        { name: "Kilkis Regional Unit", id: "57" },
        { name: "Kozani Prefecture", id: "58" },
        { name: "Laconia", id: "16" },
        { name: "Larissa Prefecture", id: "42" },
        { name: "Lefkada Regional Unit", id: "24" },
        { name: "Pella Regional Unit", id: "59" },
        { name: "Peloponnese Region", id: "J" },
        { name: "Phthiotis Prefecture", id: "06" },
        { name: "Preveza Prefecture", id: "34" },
        { name: "Serres Prefecture", id: "62" },
        { name: "South Aegean", id: "L" },
        { name: "Thessaloniki Regional Unit", id: "54" },
        { name: "West Greece Region", id: "G" },
        { name: "West Macedonia Region", id: "C" }
    ],
    "gd": [
        { name: "Carriacou and Petite Martinique", id: "10" },
        { name: "Saint Andrew Parish", id: "01" },
        { name: "Saint David Parish", id: "02" },
        { name: "Saint George Parish", id: "03" },
        { name: "Saint John Parish", id: "04" },
        { name: "Saint Mark Parish", id: "05" },
        { name: "Saint Patrick Parish", id: "06" }
    ],
    "gt": [
        { name: "Alta Verapaz Department", id: "AV" },
        { name: "Baja Verapaz Department", id: "BV" },
        { name: "Chimaltenango Department", id: "CM" },
        { name: "Chiquimula Department", id: "CQ" },
        { name: "El Progreso Department", id: "PR" },
        { name: "Escuintla Department", id: "ES" },
        { name: "Guatemala Department", id: "GU" },
        { name: "Huehuetenango Department", id: "HU" },
        { name: "Izabal Department", id: "IZ" },
        { name: "Jalapa Department", id: "JA" },
        { name: "Jutiapa Department", id: "JU" },
        { name: "Petén Department", id: "PE" },
        { name: "Quetzaltenango Department", id: "QZ" },
        { name: "Quiché Department", id: "QC" },
        { name: "Retalhuleu Department", id: "RE" },
        { name: "Sacatepéquez Department", id: "SA" },
        { name: "San Marcos Department", id: "SM" },
        { name: "Santa Rosa Department", id: "SR" },
        { name: "Sololá Department", id: "SO" },
        { name: "Suchitepéquez Department", id: "SU" },
        { name: "Totonicapán Department", id: "TO" }
    ],
    "gn": [
        { name: "Beyla Prefecture", id: "BE" },
        { name: "Boffa Prefecture", id: "BF" },
        { name: "Boké Prefecture", id: "BK" },
        { name: "Boké Region", id: "B" },
        { name: "Conakry", id: "C" },
        { name: "Coyah Prefecture", id: "CO" },
        { name: "Dabola Prefecture", id: "DB" },
        { name: "Dalaba Prefecture", id: "DL" },
        { name: "Dinguiraye Prefecture", id: "DI" },
        { name: "Dubréka Prefecture", id: "DU" },
        { name: "Faranah Prefecture", id: "FA" },
        { name: "Forécariah Prefecture", id: "FO" },
        { name: "Fria Prefecture", id: "FR" },
        { name: "Gaoual Prefecture", id: "GA" },
        { name: "Guéckédou Prefecture", id: "GU" },
        { name: "Kankan Prefecture", id: "KA" },
        { name: "Kankan Region", id: "K" },
        { name: "Kérouané Prefecture", id: "KE" },
        { name: "Kindia Prefecture", id: "KD" },
        { name: "Kindia Region", id: "D" },
        { name: "Kissidougou Prefecture", id: "KS" },
        { name: "Koubia Prefecture", id: "KB" },
        { name: "Koundara Prefecture", id: "KN" },
        { name: "Kouroussa Prefecture", id: "KO" },
        { name: "Labé Prefecture", id: "LA" },
        { name: "Labé Region", id: "L" },
        { name: "Lélouma Prefecture", id: "LE" },
        { name: "Lola Prefecture", id: "LO" },
        { name: "Macenta Prefecture", id: "MC" },
        { name: "Mali Prefecture", id: "ML" },
        { name: "Mamou Prefecture", id: "MM" },
        { name: "Mamou Region", id: "M" },
        { name: "Mandiana Prefecture", id: "MD" },
        { name: "Nzérékoré Prefecture", id: "NZ" },
        { name: "Nzérékoré Region", id: "N" },
        { name: "Pita Prefecture", id: "PI" },
        { name: "Siguiri Prefecture", id: "SI" },
        { name: "Télimélé Prefecture", id: "TE" },
        { name: "Tougué Prefecture", id: "TO" },
        { name: "Yomou Prefecture", id: "YO" }
    ],
    "gw": [
        { name: "Bafatá", id: "BA" },
        { name: "Biombo Region", id: "BM" },
        { name: "Bolama Region", id: "BL" },
        { name: "Cacheu Region", id: "CA" },
        { name: "Gabú Region", id: "GA" },
        { name: "Leste Province", id: "L" },
        { name: "Norte Province", id: "N" },
        { name: "Oio Region", id: "OI" },
        { name: "Quinara Region", id: "QU" },
        { name: "Sul Province", id: "S" },
        { name: "Tombali Region", id: "TO" }
    ],
    "gy": [
        { name: "Barima-Waini", id: "BA" },
        { name: "Cuyuni-Mazaruni", id: "CU" },
        { name: "Demerara-Mahaica", id: "DE" },
        { name: "East Berbice-Corentyne", id: "EB" },
        { name: "Essequibo Islands-West Demerara", id: "ES" },
        { name: "Mahaica-Berbice", id: "MA" },
        { name: "Pomeroon-Supenaam", id: "PM" },
        { name: "Potaro-Siparuni", id: "PT" },
        { name: "Upper Demerara-Berbice", id: "UD" },
        { name: "Upper Takutu-Upper Essequibo", id: "UT" }
    ],
    "ht": [
        { name: "Artibonite", id: "AR" },
        { name: "Centre", id: "CE" },
        { name: "Grand'Anse", id: "GA" },
        { name: "Nippes", id: "NI" },
        { name: "Nord", id: "ND" },
        { name: "Nord-Est", id: "NE" },
        { name: "Nord-Ouest", id: "NO" },
        { name: "Ouest", id: "OU" },
        { name: "Sud", id: "SD" },
        { name: "Sud-Est", id: "SE" }
    ],
    "hn": [
        { name: "Atlántida Department", id: "AT" },
        { name: "Bay Islands Department", id: "IB" },
        { name: "Choluteca Department", id: "CH" },
        { name: "Colón Department", id: "CL" },
        { name: "Comayagua Department", id: "CM" },
        { name: "Copán Department", id: "CP" },
        { name: "Cortés Department", id: "CR" },
        { name: "El Paraíso Department", id: "EP" },
        { name: "Francisco Morazán Department", id: "FM" },
        { name: "Gracias a Dios Department", id: "GD" },
        { name: "Intibucá Department", id: "IN" },
        { name: "La Paz Department", id: "LP" },
        { name: "Lempira Department", id: "LE" },
        { name: "Ocotepeque Department", id: "OC" },
        { name: "Olancho Department", id: "OL" },
        { name: "Santa Bárbara Department", id: "SB" },
        { name: "Valle Department", id: "VA" },
        { name: "Yoro Department", id: "YO" }
    ],
    "hk": [
        { name: "Central and Western District", id: "HCW" },
        { name: "Eastern", id: "HEA" },
        { name: "Islands District", id: "NIS" },
        { name: "Kowloon City", id: "KKC" },
        { name: "Kwai Tsing", id: "NKT" },
        { name: "Kwun Tong", id: "KKT" },
        { name: "North", id: "NNO" },
        { name: "Sai Kung District", id: "NSK" },
        { name: "Sha Tin", id: "NST" },
        { name: "Sham Shui Po", id: "KSS" },
        { name: "Southern", id: "HSO" },
        { name: "Tsuen Wan District", id: "NTW" },
        { name: "Tuen Mun", id: "NTM" },
        { name: "Wan Chai", id: "HWC" },
        { name: "Wong Tai Sin", id: "KWT" },
        { name: "Yau Tsim Mong", id: "KYT" },
        { name: "Yuen Long District", id: "NYL" }
    ],
    "hu": [
        { name: "Bács-Kiskun County", id: "BK" },
        { name: "Baranya County", id: "BA" },
        { name: "Békés County", id: "BE" },
        { name: "Békéscsaba", id: "BC" },
        { name: "Borsod-Abaúj-Zemplén County", id: "BZ" },
        { name: "Budapest", id: "BU" },
        { name: "Csongrád County", id: "CS" },
        { name: "Debrecen", id: "DE" },
        { name: "Dunaújváros", id: "DU" },
        { name: "Eger", id: "EG" },
        { name: "Érd", id: "ER" },
        { name: "Fejér County", id: "FE" },
        { name: "Győr", id: "GY" },
        { name: "Győr-Moson-Sopron County", id: "GS" },
        { name: "Hajdú-Bihar County", id: "HB" },
        { name: "Heves County", id: "HE" },
        { name: "Hódmezővásárhely", id: "HV" },
        { name: "Jász-Nagykun-Szolnok County", id: "JN" },
        { name: "Kaposvár", id: "KV" },
        { name: "Kecskemét", id: "KM" },
        { name: "Miskolc", id: "MI" },
        { name: "Nagykanizsa", id: "NK" },
        { name: "Nógrád County", id: "NO" },
        { name: "Nyíregyháza", id: "NY" },
        { name: "Pécs", id: "PS" },
        { name: "Pest County", id: "PE" },
        { name: "Salgótarján", id: "ST" },
        { name: "Somogy County", id: "SO" },
        { name: "Sopron", id: "SN" },
        { name: "Szabolcs-Szatmár-Bereg County", id: "SZ" },
        { name: "Szeged", id: "SD" },
        { name: "Székesfehérvár", id: "SF" },
        { name: "Szekszárd", id: "SS" },
        { name: "Szolnok", id: "SK" },
        { name: "Szombathely", id: "SH" },
        { name: "Tatabánya", id: "TB" },
        { name: "Tolna County", id: "TO" },
        { name: "Vas County", id: "VA" },
        { name: "Veszprém", id: "VM" },
        { name: "Veszprém County", id: "VE" },
        { name: "Zala County", id: "ZA" },
        { name: "Zalaegerszeg", id: "ZE" }
    ],
    "is": [
        { name: "Capital Region", id: "1" },
        { name: "Eastern Region", id: "7" },
        { name: "Northeastern Region", id: "6" },
        { name: "Northwestern Region", id: "5" },
        { name: "Southern Peninsula Region", id: "2" },
        { name: "Southern Region", id: "8" },
        { name: "Western Region", id: "3" },
        { name: "Westfjords", id: "4" }
    ],
    "in": [
        { name: "Andaman and Nicobar Islands", id: "AN" },
        { name: "Andhra Pradesh", id: "AP" },
        { name: "Arunachal Pradesh", id: "AR" },
        { name: "Assam", id: "AS" },
        { name: "Bihar", id: "BR" },
        { name: "Chandigarh", id: "CH" },
        { name: "Chhattisgarh", id: "CT" },
        { name: "Dadra and Nagar Haveli and Daman and Diu", id: "DH" },
        { name: "Delhi", id: "DL" },
        { name: "Goa", id: "GA" },
        { name: "Gujarat", id: "GJ" },
        { name: "Haryana", id: "HR" },
        { name: "Himachal Pradesh", id: "HP" },
        { name: "Jammu and Kashmir", id: "JK" },
        { name: "Jharkhand", id: "JH" },
        { name: "Karnataka", id: "KA" },
        { name: "Kerala", id: "KL" },
        { name: "Ladakh", id: "LA" },
        { name: "Lakshadweep", id: "LD" },
        { name: "Madhya Pradesh", id: "MP" },
        { name: "Maharashtra", id: "MH" },
        { name: "Manipur", id: "MN" },
        { name: "Meghalaya", id: "ML" },
        { name: "Mizoram", id: "MZ" },
        { name: "Nagaland", id: "NL" },
        { name: "Odisha", id: "OR" },
        { name: "Puducherry", id: "PY" },
        { name: "Punjab", id: "PB" },
        { name: "Rajasthan", id: "RJ" },
        { name: "Sikkim", id: "SK" },
        { name: "Tamil Nadu", id: "TN" },
        { name: "Telangana", id: "TG" },
        { name: "Tripura", id: "TR" },
        { name: "Uttar Pradesh", id: "UP" },
        { name: "Uttarakhand", id: "UT" },
        { name: "West Bengal", id: "WB" }
    ],
    id: [
        { name: "Aceh", id: "AC" },
        { name: "Bali", id: "BA" },
        { name: "Banten", id: "BT" },
        { name: "Bengkulu", id: "BE" },
        { name: "DI Yogyakarta", id: "YO" },
        { name: "DKI Jakarta", id: "JK" },
        { name: "Gorontalo", id: "GO" },
        { name: "Jambi", id: "JA" },
        { name: "Jawa Barat", id: "JB" },
        { name: "Jawa Tengah", id: "JT" },
        { name: "Jawa Timur", id: "JI" },
        { name: "Kalimantan Barat", id: "KA" },
        { name: "Kalimantan Selatan", id: "KS" },
        { name: "Kalimantan Tengah", id: "KT" },
        { name: "Kalimantan Timur", id: "KI" },
        { name: "Kalimantan Utara", id: "KU" },
        { name: "Kepulauan Bangka Belitung", id: "BB" },
        { name: "Kepulauan Riau", id: "KR" },
        { name: "Lampung", id: "LA" },
        { name: "Maluku", id: "MA" },
        { name: "Maluku Utara", id: "MU" },
        { name: "Nusa Tenggara Barat", id: "NB" },
        { name: "Nusa Tenggara Timur", id: "NT" },
        { name: "Papua", id: "PA" },
        { name: "Papua Barat", id: "PB" },
        { name: "Riau", id: "RI" },
        { name: "Sulawesi Barat", id: "SR" },
        { name: "Sulawesi Selatan", id: "SN" },
        { name: "Sulawesi Tengah", id: "ST" },
        { name: "Sulawesi Tenggara", id: "SG" },
        { name: "Sulawesi Utara", id: "SA" },
        { name: "Sumatera Barat", id: "SB" },
        { name: "Sumatera Selatan", id: "SS" },
        { name: "Sumatera Utara", id: "SU" }
    ],
    "ir": [
        { name: "Alborz Province", id: "30" },
        { name: "Ardabil Province", id: "24" },
        { name: "Bushehr Province", id: "18" },
        { name: "Chaharmahal and Bakhtiari Province", id: "14" },
        { name: "East Azerbaijan Province", id: "03" },
        { name: "Fars Province", id: "07" },
        { name: "Gilan Province", id: "01" },
        { name: "Golestan Province", id: "27" },
        { name: "Hamadan Province", id: "13" },
        { name: "Hormozgan Province", id: "22" },
        { name: "Ilam Province", id: "16" },
        { name: "Isfahan Province", id: "10" },
        { name: "Kerman Province", id: "08" },
        { name: "Kermanshah Province", id: "05" },
        { name: "Khuzestan Province", id: "06" },
        { name: "Kohgiluyeh and Boyer-Ahmad Province", id: "17" },
        { name: "Kurdistan Province", id: "12" },
        { name: "Lorestan Province", id: "15" },
        { name: "Markazi Province", id: "00" },
        { name: "Mazandaran Province", id: "02" },
        { name: "North Khorasan Province", id: "28" },
        { name: "Qazvin Province", id: "26" },
        { name: "Qom Province", id: "25" },
        { name: "Razavi Khorasan Province", id: "09" },
        { name: "Semnan Province", id: "20" },
        { name: "Sistan and Baluchestan", id: "11" },
        { name: "South Khorasan Province", id: "29" },
        { name: "Tehran Province", id: "23" },
        { name: "West Azarbaijan Province", id: "04" },
        { name: "Yazd Province", id: "21" },
        { name: "Zanjan Province", id: "19" }
    ],
    "iq": [
        { name: "Al Anbar Governorate", id: "AN" },
        { name: "Al Muthanna Governorate", id: "MU" },
        { name: "Al-Qādisiyyah Governorate", id: "QA" },
        { name: "Babylon Governorate", id: "BB" },
        { name: "Baghdad Governorate", id: "BG" },
        { name: "Basra Governorate", id: "BA" },
        { name: "Dhi Qar Governorate", id: "DQ" },
        { name: "Diyala Governorate", id: "DI" },
        { name: "Dohuk Governorate", id: "DA" },
        { name: "Erbil Governorate", id: "AR" },
        { name: "Karbala Governorate", id: "KA" },
        { name: "Kirkuk Governorate", id: "KI" },
        { name: "Maysan Governorate", id: "MA" },
        { name: "Najaf Governorate", id: "NA" },
        { name: "Nineveh Governorate", id: "NI" },
        { name: "Saladin Governorate", id: "SD" },
        { name: "Sulaymaniyah Governorate", id: "SU" },
        { name: "Wasit Governorate", id: "WA" }
    ],
    "ie": [
        { name: "Connacht", id: "C" },
        { name: "County Carlow", id: "CW" },
        { name: "County Cavan", id: "CN" },
        { name: "County Clare", id: "CE" },
        { name: "County Cork", id: "CO" },
        { name: "County Donegal", id: "DL" },
        { name: "County Dublin", id: "D" },
        { name: "County Galway", id: "G" },
        { name: "County Kerry", id: "KY" },
        { name: "County Kildare", id: "KE" },
        { name: "County Kilkenny", id: "KK" },
        { name: "County Laois", id: "LS" },
        { name: "County Limerick", id: "LK" },
        { name: "County Longford", id: "LD" },
        { name: "County Louth", id: "LH" },
        { name: "County Mayo", id: "MO" },
        { name: "County Meath", id: "MH" },
        { name: "County Monaghan", id: "MN" },
        { name: "County Offaly", id: "OY" },
        { name: "County Roscommon", id: "RN" },
        { name: "County Sligo", id: "SO" },
        { name: "County Tipperary", id: "TA" },
        { name: "County Waterford", id: "WD" },
        { name: "County Westmeath", id: "WH" },
        { name: "County Wexford", id: "WX" },
        { name: "County Wicklow", id: "WW" },
        { name: "Leinster", id: "L" },
        { name: "Munster", id: "M" },
        { name: "Ulster", id: "U" }
    ],
    "il": [
        { name: "Central District", id: "M" },
        { name: "Haifa District", id: "HA" },
        { name: "Jerusalem District", id: "JM" },
        { name: "Northern District", id: "Z" },
        { name: "Southern District", id: "D" },
        { name: "Tel Aviv District", id: "TA" }
    ],
    "it": [
        { name: "Abruzzo", id: "65" },
        { name: "Aosta Valley", id: "23" },
        { name: "Apulia", id: "75" },
        { name: "Basilicata", id: "77" },
        { name: "Benevento Province", id: "BN" },
        { name: "Calabria", id: "78" },
        { name: "Campania", id: "72" },
        { name: "Emilia-Romagna", id: "45" },
        { name: "Friuli–Venezia Giulia", id: "36" },
        { name: "Lazio", id: "62" },
        { name: "Libero consorzio comunale di Agrigento", id: "AG" },
        { name: "Libero consorzio comunale di Caltanissetta", id: "CL" },
        { name: "Libero consorzio comunale di Enna", id: "EN" },
        { name: "Libero consorzio comunale di Ragusa", id: "RG" },
        { name: "Libero consorzio comunale di Siracusa", id: "SR" },
        { name: "Libero consorzio comunale di Trapani", id: "TP" },
        { name: "Liguria", id: "42" },
        { name: "Lombardy", id: "25" },
        { name: "Marche", id: "57" },
        { name: "Metropolitan City of Bari", id: "BA" },
        { name: "Metropolitan City of Bologna", id: "BO" },
        { name: "Metropolitan City of Cagliari", id: "CA" },
        { name: "Metropolitan City of Catania", id: "CT" },
        { name: "Metropolitan City of Florence", id: "FI" },
        { name: "Metropolitan City of Genoa", id: "GE" },
        { name: "Metropolitan City of Messina", id: "ME" },
        { name: "Metropolitan City of Milan", id: "MI" },
        { name: "Metropolitan City of Naples", id: "NA" },
        { name: "Metropolitan City of Palermo", id: "PA" },
        { name: "Metropolitan City of Reggio Calabria", id: "RC" },
        { name: "Metropolitan City of Rome", id: "RM" },
        { name: "Metropolitan City of Turin", id: "TO" },
        { name: "Metropolitan City of Venice", id: "VE" },
        { name: "Molise", id: "67" },
        { name: "Pesaro and Urbino Province", id: "PU" },
        { name: "Piedmont", id: "21" },
        { name: "Province of Alessandria", id: "AL" },
        { name: "Province of Ancona", id: "AN" },
        { name: "Province of Ascoli Piceno", id: "AP" },
        { name: "Province of Asti", id: "AT" },
        { name: "Province of Avellino", id: "AV" },
        { name: "Province of Barletta-Andria-Trani", id: "BT" },
        { name: "Province of Belluno", id: "BL" },
        { name: "Province of Bergamo", id: "BG" },
        { name: "Province of Biella", id: "BI" },
        { name: "Province of Brescia", id: "BS" },
        { name: "Province of Brindisi", id: "BR" },
        { name: "Province of Campobasso", id: "CB" },
        { name: "Province of Carbonia-Iglesias", id: "CI" },
        { name: "Province of Caserta", id: "CE" },
        { name: "Province of Catanzaro", id: "CZ" },
        { name: "Province of Chieti", id: "CH" },
        { name: "Province of Como", id: "CO" },
        { name: "Province of Cosenza", id: "CS" },
        { name: "Province of Cremona", id: "CR" },
        { name: "Province of Crotone", id: "KR" },
        { name: "Province of Cuneo", id: "CN" },
        { name: "Province of Fermo", id: "FM" },
        { name: "Province of Ferrara", id: "FE" },
        { name: "Province of Foggia", id: "FG" },
        { name: "Province of Forlì-Cesena", id: "FC" },
        { name: "Province of Frosinone", id: "FR" },
        { name: "Province of Gorizia", id: "GO" },
        { name: "Province of Grosseto", id: "GR" },
        { name: "Province of Imperia", id: "IM" },
        { name: "Province of Isernia", id: "IS" },
        { name: "Province of L'Aquila", id: "AQ" },
        { name: "Province of La Spezia", id: "SP" },
        { name: "Province of Latina", id: "LT" },
        { name: "Province of Lecce", id: "LE" },
        { name: "Province of Lecco", id: "LC" },
        { name: "Province of Livorno", id: "LI" },
        { name: "Province of Lodi", id: "LO" },
        { name: "Province of Lucca", id: "LU" },
        { name: "Province of Macerata", id: "MC" },
        { name: "Province of Mantua", id: "MN" },
        { name: "Province of Massa and Carrara", id: "MS" },
        { name: "Province of Matera", id: "MT" },
        { name: "Province of Medio Campidano", id: "VS" },
        { name: "Province of Modena", id: "MO" },
        { name: "Province of Monza and Brianza", id: "MB" },
        { name: "Province of Novara", id: "NO" },
        { name: "Province of Nuoro", id: "NU" },
        { name: "Province of Ogliastra", id: "OG" },
        { name: "Province of Olbia-Tempio", id: "OT" },
        { name: "Province of Oristano", id: "OR" },
        { name: "Province of Padua", id: "PD" },
        { name: "Province of Parma", id: "PR" },
        { name: "Province of Pavia", id: "PV" },
        { name: "Province of Perugia", id: "PG" },
        { name: "Province of Pescara", id: "PE" },
        { name: "Province of Piacenza", id: "PC" },
        { name: "Province of Pisa", id: "PI" },
        { name: "Province of Pistoia", id: "PT" },
        { name: "Province of Pordenone", id: "PN" },
        { name: "Province of Potenza", id: "PZ" },
        { name: "Province of Prato", id: "PO" },
        { name: "Province of Ravenna", id: "RA" },
        { name: "Province of Reggio Emilia", id: "RE" },
        { name: "Province of Rieti", id: "RI" },
        { name: "Province of Rimini", id: "RN" },
        { name: "Province of Rovigo", id: "RO" },
        { name: "Province of Salerno", id: "SA" },
        { name: "Province of Sassari", id: "SS" },
        { name: "Province of Savona", id: "SV" },
        { name: "Province of Siena", id: "SI" },
        { name: "Province of Sondrio", id: "SO" },
        { name: "Province of Taranto", id: "TA" },
        { name: "Province of Teramo", id: "TE" },
        { name: "Province of Terni", id: "TR" },
        { name: "Province of Treviso", id: "TV" },
        { name: "Province of Trieste", id: "TS" },
        { name: "Province of Udine", id: "UD" },
        { name: "Province of Varese", id: "VA" },
        { name: "Province of Verbano-Cusio-Ossola", id: "VB" },
        { name: "Province of Vercelli", id: "VC" },
        { name: "Province of Verona", id: "VR" },
        { name: "Province of Vibo Valentia", id: "VV" },
        { name: "Province of Vicenza", id: "VI" },
        { name: "Province of Viterbo", id: "VT" },
        { name: "Sardinia", id: "88" },
        { name: "Sicily", id: "82" },
        { name: "South Tyrol", id: "BZ" },
        { name: "Trentino", id: "TN" },
        { name: "Trentino-South Tyrol", id: "32" },
        { name: "Tuscany", id: "52" },
        { name: "Umbria", id: "55" },
        { name: "Veneto", id: "34" }
    ],
    "jm": [
        { name: "Clarendon Parish", id: "13" },
        { name: "Hanover Parish", id: "09" },
        { name: "Kingston Parish", id: "01" },
        { name: "Manchester Parish", id: "12" },
        { name: "Portland Parish", id: "04" },
        { name: "Saint Andrew", id: "02" },
        { name: "Saint Ann Parish", id: "06" },
        { name: "Saint Catherine Parish", id: "14" },
        { name: "Saint Elizabeth Parish", id: "11" },
        { name: "Saint James Parish", id: "08" },
        { name: "Saint Mary Parish", id: "05" },
        { name: "Saint Thomas Parish", id: "03" },
        { name: "Trelawny Parish", id: "07" },
        { name: "Westmoreland Parish", id: "10" }
    ],
    "jp": [
        { name: "Aichi Prefecture", id: "23" },
        { name: "Akita Prefecture", id: "05" },
        { name: "Aomori Prefecture", id: "02" },
        { name: "Chiba Prefecture", id: "12" },
        { name: "Ehime Prefecture", id: "38" },
        { name: "Fukui Prefecture", id: "18" },
        { name: "Fukuoka Prefecture", id: "40" },
        { name: "Fukushima Prefecture", id: "07" },
        { name: "Gifu Prefecture", id: "21" },
        { name: "Gunma Prefecture", id: "10" },
        { name: "Hiroshima Prefecture", id: "34" },
        { name: "Hokkaidō Prefecture", id: "01" },
        { name: "Hyōgo Prefecture", id: "28" },
        { name: "Ibaraki Prefecture", id: "08" },
        { name: "Ishikawa Prefecture", id: "17" },
        { name: "Iwate Prefecture", id: "03" },
        { name: "Kagawa Prefecture", id: "37" },
        { name: "Kagoshima Prefecture", id: "46" },
        { name: "Kanagawa Prefecture", id: "14" },
        { name: "Kōchi Prefecture", id: "39" },
        { name: "Kumamoto Prefecture", id: "43" },
        { name: "Kyōto Prefecture", id: "26" },
        { name: "Mie Prefecture", id: "24" },
        { name: "Miyagi Prefecture", id: "04" },
        { name: "Miyazaki Prefecture", id: "45" },
        { name: "Nagano Prefecture", id: "20" },
        { name: "Nagasaki Prefecture", id: "42" },
        { name: "Nara Prefecture", id: "29" },
        { name: "Niigata Prefecture", id: "15" },
        { name: "Ōita Prefecture", id: "44" },
        { name: "Okayama Prefecture", id: "33" },
        { name: "Okinawa Prefecture", id: "47" },
        { name: "Ōsaka Prefecture", id: "27" },
        { name: "Saga Prefecture", id: "41" },
        { name: "Saitama Prefecture", id: "11" },
        { name: "Shiga Prefecture", id: "25" },
        { name: "Shimane Prefecture", id: "32" },
        { name: "Shizuoka Prefecture", id: "22" },
        { name: "Tochigi Prefecture", id: "09" },
        { name: "Tokushima Prefecture", id: "36" },
        { name: "Tokyo", id: "13" },
        { name: "Tottori Prefecture", id: "31" },
        { name: "Toyama Prefecture", id: "16" },
        { name: "Wakayama Prefecture", id: "30" },
        { name: "Yamagata Prefecture", id: "06" },
        { name: "Yamaguchi Prefecture", id: "35" },
        { name: "Yamanashi Prefecture", id: "19" }
    ],
    "jo": [
        { name: "Ajloun Governorate", id: "AJ" },
        { name: "Amman Governorate", id: "AM" },
        { name: "Aqaba Governorate", id: "AQ" },
        { name: "Balqa Governorate", id: "BA" },
        { name: "Irbid Governorate", id: "IR" },
        { name: "Jerash Governorate", id: "JA" },
        { name: "Karak Governorate", id: "KA" },
        { name: "Ma'an Governorate", id: "MN" },
        { name: "Madaba Governorate", id: "MD" },
        { name: "Mafraq Governorate", id: "MA" },
        { name: "Tafilah Governorate", id: "AT" },
        { name: "Zarqa Governorate", id: "AZ" }
    ],
    "kz": [
        { name: "Akmola Region", id: "AKM" },
        { name: "Aktobe Region", id: "AKT" },
        { name: "Almaty", id: "ALA" },
        { name: "Almaty Region", id: "ALM" },
        { name: "Atyrau Region", id: "ATY" },
        { name: "Baikonur", id: "BAY" },
        { name: "East Kazakhstan Region", id: "VOS" },
        { name: "Jambyl Region", id: "ZHA" },
        { name: "Karaganda Region", id: "KAR" },
        { name: "Kostanay Region", id: "KUS" },
        { name: "Kyzylorda Region", id: "KZY" },
        { name: "Mangystau Region", id: "MAN" },
        { name: "North Kazakhstan Region", id: "SEV" },
        { name: "Nur-Sultan", id: "AST" },
        { name: "Pavlodar Region", id: "PAV" },
        { name: "Turkestan Region", id: "YUZ" },
        { name: "West Kazakhstan Province", id: "ZAP" }
    ],
    "ke": [
        { name: "Baringo", id: "01" },
        { name: "Bomet", id: "02" },
        { name: "Bungoma", id: "03" },
        { name: "Busia", id: "04" },
        { name: "Elgeyo-Marakwet", id: "05" },
        { name: "Embu", id: "06" },
        { name: "Garissa", id: "07" },
        { name: "Homa Bay", id: "08" },
        { name: "Isiolo", id: "09" },
        { name: "Kajiado", id: "10" },
        { name: "Kakamega", id: "11" },
        { name: "Kericho", id: "12" },
        { name: "Kiambu", id: "13" },
        { name: "Kilifi", id: "14" },
        { name: "Kirinyaga", id: "15" },
        { name: "Kisii", id: "16" },
        { name: "Kisumu", id: "17" },
        { name: "Kitui", id: "18" },
        { name: "Kwale", id: "19" },
        { name: "Laikipia", id: "20" },
        { name: "Lamu", id: "21" },
        { name: "Machakos", id: "22" },
        { name: "Makueni", id: "23" },
        { name: "Mandera", id: "24" },
        { name: "Marsabit", id: "25" },
        { name: "Meru", id: "26" },
        { name: "Migori", id: "27" },
        { name: "Mombasa", id: "28" },
        { name: "Murang'a", id: "29" },
        { name: "Nairobi City", id: "30" },
        { name: "Nakuru", id: "31" },
        { name: "Nandi", id: "32" },
        { name: "Narok", id: "33" },
        { name: "Nyamira", id: "34" },
        { name: "Nyandarua", id: "35" },
        { name: "Nyeri", id: "36" },
        { name: "Samburu", id: "37" },
        { name: "Siaya", id: "38" },
        { name: "Taita–Taveta", id: "39" },
        { name: "Tana River", id: "40" },
        { name: "Tharaka-Nithi", id: "41" },
        { name: "Trans Nzoia", id: "42" },
        { name: "Turkana", id: "43" },
        { name: "Uasin Gishu", id: "44" },
        { name: "Vihiga", id: "45" },
        { name: "Wajir", id: "46" },
        { name: "West Pokot", id: "47" }
    ],
    "ki": [
        { name: "Gilbert Islands", id: "G" },
        { name: "Line Islands", id: "L" },
        { name: "Phoenix Islands", id: "P" }
    ],
    "xk": [
        { name: "Đakovica District (Gjakove)", id: "XDG" },
        { name: "Gjilan District", id: "XGJ" },
        { name: "Kosovska Mitrovica District", id: "XKM" },
        { name: "Peć District", id: "XPE" },
        { name: "Pristina (Priştine)", id: "XPI" },
        { name: "Prizren District", id: "XPR" },
        { name: "Uroševac District (Ferizaj)", id: "XUF" }
    ],
    "kw": [
        { name: "Al Ahmadi Governorate", id: "AH" },
        { name: "Al Farwaniyah Governorate", id: "FA" },
        { name: "Al Jahra Governorate", id: "JA" },
        { name: "Capital Governorate", id: "KU" },
        { name: "Hawalli Governorate", id: "HA" },
        { name: "Mubarak Al-Kabeer Governorate", id: "MU" }
    ],
    "kg": [
        { name: "Batken Region", id: "B" },
        { name: "Bishkek", id: "GB" },
        { name: "Chuy Region", id: "C" },
        { name: "Issyk-Kul Region", id: "Y" },
        { name: "Jalal-Abad Region", id: "J" },
        { name: "Naryn Region", id: "N" },
        { name: "Osh", id: "GO" },
        { name: "Osh Region", id: "O" },
        { name: "Talas Region", id: "T" }
    ],
    "la": [
        { name: "Attapeu Province", id: "AT" },
        { name: "Bokeo Province", id: "BK" },
        { name: "Bolikhamsai Province", id: "BL" },
        { name: "Champasak Province", id: "CH" },
        { name: "Houaphanh Province", id: "HO" },
        { name: "Khammouane Province", id: "KH" },
        { name: "Luang Namtha Province", id: "LM" },
        { name: "Luang Prabang Province", id: "LP" },
        { name: "Oudomxay Province", id: "OU" },
        { name: "Phongsaly Province", id: "PH" },
        { name: "Sainyabuli Province", id: "XA" },
        { name: "Salavan Province", id: "SL" },
        { name: "Savannakhet Province", id: "SV" },
        { name: "Sekong Province", id: "XE" },
        { name: "Vientiane Prefecture", id: "VT" },
        { name: "Vientiane Province", id: "VI" },
        { name: "Xaisomboun", id: "XN" },
        { name: "Xaisomboun Province", id: "XS" },
        { name: "Xiangkhouang Province", id: "XI" }
    ],
    "lv": [
        { name: "Aglona Municipality", id: "001" },
        { name: "Aizkraukle Municipality", id: "002" },
        { name: "Aizpute Municipality", id: "003" },
        { name: "Aknīste Municipality", id: "004" },
        { name: "Aloja Municipality", id: "005" },
        { name: "Alsunga Municipality", id: "006" },
        { name: "Alūksne Municipality", id: "007" },
        { name: "Amata Municipality", id: "008" },
        { name: "Ape Municipality", id: "009" },
        { name: "Auce Municipality", id: "010" },
        { name: "Babīte Municipality", id: "012" },
        { name: "Baldone Municipality", id: "013" },
        { name: "Baltinava Municipality", id: "014" },
        { name: "Balvi Municipality", id: "015" },
        { name: "Bauska Municipality", id: "016" },
        { name: "Beverīna Municipality", id: "017" },
        { name: "Brocēni Municipality", id: "018" },
        { name: "Burtnieki Municipality", id: "019" },
        { name: "Carnikava Municipality", id: "020" },
        { name: "Cēsis Municipality", id: "022" },
        { name: "Cesvaine Municipality", id: "021" },
        { name: "Cibla Municipality", id: "023" },
        { name: "Dagda Municipality", id: "024" },
        { name: "Daugavpils", id: "DGV" },
        { name: "Daugavpils Municipality", id: "025" },
        { name: "Dobele Municipality", id: "026" },
        { name: "Dundaga Municipality", id: "027" },
        { name: "Durbe Municipality", id: "028" },
        { name: "Engure Municipality", id: "029" },
        { name: "Ērgļi Municipality", id: "030" },
        { name: "Garkalne Municipality", id: "031" },
        { name: "Grobiņa Municipality", id: "032" },
        { name: "Gulbene Municipality", id: "033" },
        { name: "Iecava Municipality", id: "034" },
        { name: "Ikšķile Municipality", id: "035" },
        { name: "Ilūkste Municipality", id: "036" },
        { name: "Inčukalns Municipality", id: "037" },
        { name: "Jaunjelgava Municipality", id: "038" },
        { name: "Jaunpiebalga Municipality", id: "039" },
        { name: "Jaunpils Municipality", id: "040" },
        { name: "Jēkabpils", id: "JKB" },
        { name: "Jēkabpils Municipality", id: "042" },
        { name: "Jelgava", id: "JEL" },
        { name: "Jelgava Municipality", id: "041" },
        { name: "Jūrmala", id: "JUR" },
        { name: "Kandava Municipality", id: "043" },
        { name: "Kārsava Municipality", id: "044" },
        { name: "Ķegums Municipality", id: "051" },
        { name: "Ķekava Municipality", id: "052" },
        { name: "Kocēni Municipality", id: "045" },
        { name: "Koknese Municipality", id: "046" },
        { name: "Krāslava Municipality", id: "047" },
        { name: "Krimulda Municipality", id: "048" },
        { name: "Krustpils Municipality", id: "049" },
        { name: "Kuldīga Municipality", id: "050" },
        { name: "Lielvārde Municipality", id: "053" },
        { name: "Liepāja", id: "LPX" },
        { name: "Līgatne Municipality", id: "055" },
        { name: "Limbaži Municipality", id: "054" },
        { name: "Līvāni Municipality", id: "056" },
        { name: "Lubāna Municipality", id: "057" },
        { name: "Ludza Municipality", id: "058" },
        { name: "Madona Municipality", id: "059" },
        { name: "Mālpils Municipality", id: "061" },
        { name: "Mārupe Municipality", id: "062" },
        { name: "Mazsalaca Municipality", id: "060" },
        { name: "Mērsrags Municipality", id: "063" },
        { name: "Naukšēni Municipality", id: "064" },
        { name: "Nereta Municipality", id: "065" },
        { name: "Nīca Municipality", id: "066" },
        { name: "Ogre Municipality", id: "067" },
        { name: "Olaine Municipality", id: "068" },
        { name: "Ozolnieki Municipality", id: "069" },
        { name: "Pārgauja Municipality", id: "070" },
        { name: "Pāvilosta Municipality", id: "071" },
        { name: "Pļaviņas Municipality", id: "072" },
        { name: "Preiļi Municipality", id: "073" },
        { name: "Priekule Municipality", id: "074" },
        { name: "Priekuļi Municipality", id: "075" },
        { name: "Rauna Municipality", id: "076" },
        { name: "Rēzekne", id: "REZ" },
        { name: "Rēzekne Municipality", id: "077" },
        { name: "Riebiņi Municipality", id: "078" },
        { name: "Riga", id: "RIX" },
        { name: "Roja Municipality", id: "079" },
        { name: "Ropaži Municipality", id: "080" },
        { name: "Rucava Municipality", id: "081" },
        { name: "Rugāji Municipality", id: "082" },
        { name: "Rūjiena Municipality", id: "084" },
        { name: "Rundāle Municipality", id: "083" },
        { name: "Sala Municipality", id: "085" },
        { name: "Salacgrīva Municipality", id: "086" },
        { name: "Salaspils Municipality", id: "087" },
        { name: "Saldus Municipality", id: "088" },
        { name: "Saulkrasti Municipality", id: "089" },
        { name: "Sēja Municipality", id: "090" },
        { name: "Sigulda Municipality", id: "091" },
        { name: "Skrīveri Municipality", id: "092" },
        { name: "Skrunda Municipality", id: "093" },
        { name: "Smiltene Municipality", id: "094" },
        { name: "Stopiņi Municipality", id: "095" },
        { name: "Strenči Municipality", id: "096" },
        { name: "Talsi Municipality", id: "097" },
        { name: "Tērvete Municipality", id: "098" },
        { name: "Tukums Municipality", id: "099" },
        { name: "Vaiņode Municipality", id: "100" },
        { name: "Valka Municipality", id: "101" },
        { name: "Valmiera", id: "VMR" },
        { name: "Varakļāni Municipality", id: "102" },
        { name: "Vārkava Municipality", id: "103" },
        { name: "Vecpiebalga Municipality", id: "104" },
        { name: "Vecumnieki Municipality", id: "105" },
        { name: "Ventspils", id: "VEN" },
        { name: "Ventspils Municipality", id: "106" },
        { name: "Viesīte Municipality", id: "107" },
        { name: "Viļaka Municipality", id: "108" },
        { name: "Viļāni Municipality", id: "109" },
        { name: "Zilupe Municipality", id: "110" }
    ],
    "lb": [
        { name: "Akkar Governorate", id: "AK" },
        { name: "Baalbek-Hermel Governorate", id: "BH" },
        { name: "Beirut Governorate", id: "BA" },
        { name: "Beqaa Governorate", id: "BI" },
        { name: "Mount Lebanon Governorate", id: "JL" },
        { name: "Nabatieh Governorate", id: "NA" },
        { name: "North Governorate", id: "AS" },
        { name: "South Governorate", id: "JA" }
    ],
    "ls": [
        { name: "Berea District", id: "D" },
        { name: "Butha-Buthe District", id: "B" },
        { name: "Leribe District", id: "C" },
        { name: "Mafeteng District", id: "E" },
        { name: "Maseru District", id: "A" },
        { name: "Mohale's Hoek District", id: "F" },
        { name: "Mokhotlong District", id: "J" },
        { name: "Qacha's Nek District", id: "H" },
        { name: "Quthing District", id: "G" },
        { name: "Thaba-Tseka District", id: "K" }
    ],
    "lr": [
        { name: "Bomi County", id: "BM" },
        { name: "Bong County", id: "BG" },
        { name: "Gbarpolu County", id: "GP" },
        { name: "Grand Bassa County", id: "GB" },
        { name: "Grand Cape Mount County", id: "CM" },
        { name: "Grand Gedeh County", id: "GG" },
        { name: "Grand Kru County", id: "GK" },
        { name: "Lofa County", id: "LO" },
        { name: "Margibi County", id: "MG" },
        { name: "Maryland County", id: "MY" },
        { name: "Montserrado County", id: "MO" },
        { name: "Nimba", id: "NI" },
        { name: "River Cess County", id: "RI" },
        { name: "River Gee County", id: "RG" },
        { name: "Sinoe County", id: "SI" }
    ],
    "ly": [
        { name: "Al Wahat District", id: "WA" },
        { name: "Benghazi", id: "BA" },
        { name: "Derna District", id: "DR" },
        { name: "Ghat District", id: "GT" },
        { name: "Jabal al Akhdar", id: "JA" },
        { name: "Jabal al Gharbi District", id: "JG" },
        { name: "Jafara", id: "JI" },
        { name: "Jufra", id: "JU" },
        { name: "Kufra District", id: "KF" },
        { name: "Marj District", id: "MJ" },
        { name: "Misrata District", id: "MI" },
        { name: "Murqub", id: "MB" },
        { name: "Murzuq District", id: "MQ" },
        { name: "Nalut District", id: "NL" },
        { name: "Nuqat al Khams", id: "NQ" },
        { name: "Sabha District", id: "SB" },
        { name: "Sirte District", id: "SR" },
        { name: "Tripoli District", id: "TB" },
        { name: "Wadi al Hayaa District", id: "WD" },
        { name: "Wadi al Shatii District", id: "WS" },
        { name: "Zawiya District", id: "ZA" }
    ],
    "li": [
        { name: "Balzers", id: "01" },
        { name: "Eschen", id: "02" },
        { name: "Gamprin", id: "03" },
        { name: "Mauren", id: "04" },
        { name: "Planken", id: "05" },
        { name: "Ruggell", id: "06" },
        { name: "Schaan", id: "07" },
        { name: "Schellenberg", id: "08" },
        { name: "Triesen", id: "09" },
        { name: "Triesenberg", id: "10" },
        { name: "Vaduz", id: "11" }
    ],
    "lt": [
        { name: "Akmenė District Municipality", id: "01" },
        { name: "Alytus City Municipality", id: "02" },
        { name: "Alytus County", id: "AL" },
        { name: "Alytus District Municipality", id: "03" },
        { name: "Birštonas Municipality", id: "05" },
        { name: "Biržai District Municipality", id: "06" },
        { name: "Druskininkai municipality", id: "07" },
        { name: "Elektrėnai municipality", id: "08" },
        { name: "Ignalina District Municipality", id: "09" },
        { name: "Jonava District Municipality", id: "10" },
        { name: "Joniškis District Municipality", id: "11" },
        { name: "Jurbarkas District Municipality", id: "12" },
        { name: "Kaišiadorys District Municipality", id: "13" },
        { name: "Kalvarija municipality", id: "14" },
        { name: "Kaunas City Municipality", id: "15" },
        { name: "Kaunas County", id: "KU" },
        { name: "Kaunas District Municipality", id: "16" },
        { name: "Kazlų Rūda municipality", id: "17" },
        { name: "Kėdainiai District Municipality", id: "18" },
        { name: "Kelmė District Municipality", id: "19" },
        { name: "Klaipeda City Municipality", id: "20" },
        { name: "Klaipėda County", id: "KL" },
        { name: "Klaipėda District Municipality", id: "21" },
        { name: "Kretinga District Municipality", id: "22" },
        { name: "Kupiškis District Municipality", id: "23" },
        { name: "Lazdijai District Municipality", id: "24" },
        { name: "Marijampolė County", id: "MR" },
        { name: "Marijampolė Municipality", id: "25" },
        { name: "Mažeikiai District Municipality", id: "26" },
        { name: "Molėtai District Municipality", id: "27" },
        { name: "Neringa Municipality", id: "28" },
        { name: "Pagėgiai municipality", id: "29" },
        { name: "Pakruojis District Municipality", id: "30" },
        { name: "Palanga City Municipality", id: "31" },
        { name: "Panevėžys City Municipality", id: "32" },
        { name: "Panevėžys County", id: "PN" },
        { name: "Panevėžys District Municipality", id: "33" },
        { name: "Pasvalys District Municipality", id: "34" },
        { name: "Plungė District Municipality", id: "35" },
        { name: "Prienai District Municipality", id: "36" },
        { name: "Radviliškis District Municipality", id: "37" },
        { name: "Raseiniai District Municipality", id: "38" },
        { name: "Rietavas municipality", id: "39" },
        { name: "Rokiškis District Municipality", id: "40" },
        { name: "Šakiai District Municipality", id: "41" },
        { name: "Šalčininkai District Municipality", id: "42" },
        { name: "Šiauliai City Municipality", id: "43" },
        { name: "Šiauliai County", id: "SA" },
        { name: "Šiauliai District Municipality", id: "44" },
        { name: "Šilalė District Municipality", id: "45" },
        { name: "Šilutė District Municipality", id: "46" },
        { name: "Širvintos District Municipality", id: "47" },
        { name: "Skuodas District Municipality", id: "48" },
        { name: "Švenčionys District Municipality", id: "49" },
        { name: "Tauragė County", id: "TA" },
        { name: "Tauragė District Municipality", id: "50" },
        { name: "Telšiai County", id: "TE" },
        { name: "Telšiai District Municipality", id: "51" },
        { name: "Trakai District Municipality", id: "52" },
        { name: "Ukmergė District Municipality", id: "53" },
        { name: "Utena County", id: "UT" },
        { name: "Utena District Municipality", id: "54" },
        { name: "Varėna District Municipality", id: "55" },
        { name: "Vilkaviškis District Municipality", id: "56" },
        { name: "Vilnius City Municipality", id: "57" },
        { name: "Vilnius County", id: "VL" },
        { name: "Vilnius District Municipality", id: "58" },
        { name: "Visaginas Municipality", id: "59" },
        { name: "Zarasai District Municipality", id: "60" }
    ],
    "lu": [
        { name: "Canton of Capellen", id: "CA" },
        { name: "Canton of Clervaux", id: "CL" },
        { name: "Canton of Diekirch", id: "DI" },
        { name: "Canton of Echternach", id: "EC" },
        { name: "Canton of Esch-sur-Alzette", id: "ES" },
        { name: "Canton of Grevenmacher", id: "GR" },
        { name: "Canton of Luxembourg", id: "LU" },
        { name: "Canton of Mersch", id: "ME" },
        { name: "Canton of Redange", id: "RD" },
        { name: "Canton of Remich", id: "RM" },
        { name: "Canton of Vianden", id: "VD" },
        { name: "Canton of Wiltz", id: "WI" },
        { name: "Diekirch District", id: "D" },
        { name: "Grevenmacher District", id: "G" },
        { name: "Luxembourg District", id: "L" }
    ],
    "mk": [
        { name: "Aerodrom Municipality", id: "01" },
        { name: "Aračinovo Municipality", id: "02" },
        { name: "Berovo Municipality", id: "03" },
        { name: "Bitola Municipality", id: "04" },
        { name: "Bogdanci Municipality", id: "05" },
        { name: "Bogovinje Municipality", id: "06" },
        { name: "Bosilovo Municipality", id: "07" },
        { name: "Brvenica Municipality", id: "08" },
        { name: "Butel Municipality", id: "09" },
        { name: "Čair Municipality", id: "79" },
        { name: "Čaška Municipality", id: "80" },
        { name: "Centar Municipality", id: "77" },
        { name: "Centar Župa Municipality", id: "78" },
        { name: "Češinovo-Obleševo Municipality", id: "81" },
        { name: "Čučer-Sandevo Municipality", id: "82" },
        { name: "Debarca Municipality", id: "22" },
        { name: "Delčevo Municipality", id: "23" },
        { name: "Demir Hisar Municipality", id: "25" },
        { name: "Demir Kapija Municipality", id: "24" },
        { name: "Dojran Municipality", id: "26" },
        { name: "Dolneni Municipality", id: "27" },
        { name: "Drugovo Municipality", id: "28" },
        { name: "Gazi Baba Municipality", id: "17" },
        { name: "Gevgelija Municipality", id: "18" },
        { name: "Gjorče Petrov Municipality", id: "29" },
        { name: "Gostivar Municipality", id: "19" },
        { name: "Gradsko Municipality", id: "20" },
        { name: "Greater Skopje", id: "85" },
        { name: "Ilinden Municipality", id: "34" },
        { name: "Jegunovce Municipality", id: "35" },
        { name: "Karbinci", id: "37" },
        { name: "Karpoš Municipality", id: "38" },
        { name: "Kavadarci Municipality", id: "36" },
        { name: "Kičevo Municipality", id: "40" },
        { name: "Kisela Voda Municipality", id: "39" },
        { name: "Kočani Municipality", id: "42" },
        { name: "Konče Municipality", id: "41" },
        { name: "Kratovo Municipality", id: "43" },
        { name: "Kriva Palanka Municipality", id: "44" },
        { name: "Krivogaštani Municipality", id: "45" },
        { name: "Kruševo Municipality", id: "46" },
        { name: "Kumanovo Municipality", id: "47" },
        { name: "Lipkovo Municipality", id: "48" },
        { name: "Lozovo Municipality", id: "49" },
        { name: "Makedonska Kamenica Municipality", id: "51" },
        { name: "Makedonski Brod Municipality", id: "52" },
        { name: "Mavrovo and Rostuša Municipality", id: "50" },
        { name: "Mogila Municipality", id: "53" },
        { name: "Negotino Municipality", id: "54" },
        { name: "Novaci Municipality", id: "55" },
        { name: "Novo Selo Municipality", id: "56" },
        { name: "Ohrid Municipality", id: "58" },
        { name: "Oslomej Municipality", id: "57" },
        { name: "Pehčevo Municipality", id: "60" },
        { name: "Petrovec Municipality", id: "59" },
        { name: "Plasnica Municipality", id: "61" },
        { name: "Prilep Municipality", id: "62" },
        { name: "Probištip Municipality", id: "63" },
        { name: "Radoviš Municipality", id: "64" },
        { name: "Rankovce Municipality", id: "65" },
        { name: "Resen Municipality", id: "66" },
        { name: "Rosoman Municipality", id: "67" },
        { name: "Saraj Municipality", id: "68" },
        { name: "Sopište Municipality", id: "70" },
        { name: "Staro Nagoričane Municipality", id: "71" },
        { name: "Štip Municipality", id: "83" },
        { name: "Struga Municipality", id: "72" },
        { name: "Strumica Municipality", id: "73" },
        { name: "Studeničani Municipality", id: "74" },
        { name: "Šuto Orizari Municipality", id: "84" },
        { name: "Sveti Nikole Municipality", id: "69" },
        { name: "Tearce Municipality", id: "75" },
        { name: "Tetovo Municipality", id: "76" },
        { name: "Valandovo Municipality", id: "10" },
        { name: "Vasilevo Municipality", id: "11" },
        { name: "Veles Municipality", id: "13" },
        { name: "Vevčani Municipality", id: "12" },
        { name: "Vinica Municipality", id: "14" },
        { name: "Vraneštica Municipality", id: "15" },
        { name: "Vrapčište Municipality", id: "16" },
        { name: "Zajas Municipality", id: "31" },
        { name: "Zelenikovo Municipality", id: "32" },
        { name: "Želino Municipality", id: "30" },
        { name: "Zrnovci Municipality", id: "33" }
    ],
    "mg": [
        { name: "Antananarivo Province", id: "T" },
        { name: "Antsiranana Province", id: "D" },
        { name: "Fianarantsoa Province", id: "F" },
        { name: "Mahajanga Province", id: "M" },
        { name: "Toamasina Province", id: "A" },
        { name: "Toliara Province", id: "U" }
    ],
    "mw": [
        { name: "Balaka District", id: "BA" },
        { name: "Blantyre District", id: "BL" },
        { name: "Central Region", id: "C" },
        { name: "Chikwawa District", id: "CK" },
        { name: "Chiradzulu District", id: "CR" },
        { name: "Chitipa district", id: "CT" },
        { name: "Dedza District", id: "DE" },
        { name: "Dowa District", id: "DO" },
        { name: "Karonga District", id: "KR" },
        { name: "Kasungu District", id: "KS" },
        { name: "Likoma District", id: "LK" },
        { name: "Lilongwe District", id: "LI" },
        { name: "Machinga District", id: "MH" },
        { name: "Mangochi District", id: "MG" },
        { name: "Mchinji District", id: "MC" },
        { name: "Mulanje District", id: "MU" },
        { name: "Mwanza District", id: "MW" },
        { name: "Mzimba District", id: "MZ" },
        { name: "Nkhata Bay District", id: "NB" },
        { name: "Nkhotakota District", id: "NK" },
        { name: "Northern Region", id: "N" },
        { name: "Nsanje District", id: "NS" },
        { name: "Ntcheu District", id: "NU" },
        { name: "Ntchisi District", id: "NI" },
        { name: "Phalombe District", id: "PH" },
        { name: "Rumphi District", id: "RU" },
        { name: "Salima District", id: "SA" },
        { name: "Southern Region", id: "S" },
        { name: "Thyolo District", id: "TH" },
        { name: "Zomba District", id: "ZO" }
    ],
    "my": [
        { name: "Johor", id: "01" },
        { name: "Kedah", id: "02" },
        { name: "Kelantan", id: "03" },
        { name: "Kuala Lumpur", id: "14" },
        { name: "Labuan", id: "15" },
        { name: "Malacca", id: "04" },
        { name: "Negeri Sembilan", id: "05" },
        { name: "Pahang", id: "06" },
        { name: "Penang", id: "07" },
        { name: "Perak", id: "08" },
        { name: "Perlis", id: "09" },
        { name: "Putrajaya", id: "16" },
        { name: "Sabah", id: "12" },
        { name: "Sarawak", id: "13" },
        { name: "Selangor", id: "10" },
        { name: "Terengganu", id: "11" }
    ],
    "mv": [
        { name: "Addu Atoll", id: "01" },
        { name: "Alif Alif Atoll", id: "02" },
        { name: "Alif Dhaal Atoll", id: "00" },
        { name: "Central Province", id: "CE" },
        { name: "Dhaalu Atoll", id: "17" },
        { name: "Faafu Atoll", id: "14" },
        { name: "Gaafu Alif Atoll", id: "27" },
        { name: "Gaafu Dhaalu Atoll", id: "28" },
        { name: "Gnaviyani Atoll", id: "29" },
        { name: "Haa Alif Atoll", id: "07" },
        { name: "Haa Dhaalu Atoll", id: "23" },
        { name: "Kaafu Atoll", id: "26" },
        { name: "Laamu Atoll", id: "05" },
        { name: "Lhaviyani Atoll", id: "03" },
        { name: "Malé", id: "MLE" },
        { name: "Meemu Atoll", id: "12" },
        { name: "Noonu Atoll", id: "25" },
        { name: "North Central Province", id: "NC" },
        { name: "North Province", id: "NO" },
        { name: "Raa Atoll", id: "13" },
        { name: "Shaviyani Atoll", id: "24" },
        { name: "South Central Province", id: "SC" },
        { name: "South Province", id: "SU" },
        { name: "Thaa Atoll", id: "08" },
        { name: "Upper South Province", id: "US" },
        { name: "Vaavu Atoll", id: "04" }
    ],
    "ml": [
        { name: "Bamako", id: "BKO" },
        { name: "Gao Region", id: "7" },
        { name: "Kayes Region", id: "1" },
        { name: "Kidal Region", id: "8" },
        { name: "Koulikoro Region", id: "2" },
        { name: "Ménaka Region", id: "9" },
        { name: "Mopti Region", id: "5" },
        { name: "Ségou Region", id: "4" },
        { name: "Sikasso Region", id: "3" },
        { name: "Taoudénit Region", id: "10" },
        { name: "Tombouctou Region", id: "6" }
    ],
    "mt": [
        { name: "Attard", id: "01" },
        { name: "Balzan", id: "02" },
        { name: "Birgu", id: "03" },
        { name: "Birkirkara", id: "04" },
        { name: "Birżebbuġa", id: "05" },
        { name: "Cospicua", id: "06" },
        { name: "Dingli", id: "07" },
        { name: "Fgura", id: "08" },
        { name: "Floriana", id: "09" },
        { name: "Fontana", id: "10" },
        { name: "Għajnsielem", id: "13" },
        { name: "Għarb", id: "14" },
        { name: "Għargħur", id: "15" },
        { name: "Għasri", id: "16" },
        { name: "Għaxaq", id: "17" },
        { name: "Gudja", id: "11" },
        { name: "Gżira", id: "12" },
        { name: "Ħamrun", id: "18" },
        { name: "Iklin", id: "19" },
        { name: "Kalkara", id: "21" },
        { name: "Kerċem", id: "22" },
        { name: "Kirkop", id: "23" },
        { name: "Lija", id: "24" },
        { name: "Luqa", id: "25" },
        { name: "Marsa", id: "26" },
        { name: "Marsaskala", id: "27" },
        { name: "Marsaxlokk", id: "28" },
        { name: "Mdina", id: "29" },
        { name: "Mellieħa", id: "30" },
        { name: "Mġarr", id: "31" },
        { name: "Mosta", id: "32" },
        { name: "Mqabba", id: "33" },
        { name: "Msida", id: "34" },
        { name: "Mtarfa", id: "35" },
        { name: "Munxar", id: "36" },
        { name: "Nadur", id: "37" },
        { name: "Naxxar", id: "38" },
        { name: "Paola", id: "39" },
        { name: "Pembroke", id: "40" },
        { name: "Pietà", id: "41" },
        { name: "Qala", id: "42" },
        { name: "Qormi", id: "43" },
        { name: "Qrendi", id: "44" },
        { name: "Rabat", id: "46" },
        { name: "Saint Lawrence", id: "50" },
        { name: "San Ġwann", id: "49" },
        { name: "Sannat", id: "52" },
        { name: "Santa Luċija", id: "53" },
        { name: "Santa Venera", id: "54" },
        { name: "Senglea", id: "20" },
        { name: "Siġġiewi", id: "55" },
        { name: "Sliema", id: "56" },
        { name: "St. Julian's", id: "48" },
        { name: "St. Paul's Bay", id: "51" },
        { name: "Swieqi", id: "57" },
        { name: "Ta' Xbiex", id: "58" },
        { name: "Tarxien", id: "59" },
        { name: "Valletta", id: "60" },
        { name: "Victoria", id: "45" },
        { name: "Xagħra", id: "61" },
        { name: "Xewkija", id: "62" },
        { name: "Xgħajra", id: "63" },
        { name: "Żabbar", id: "64" },
        { name: "Żebbuġ Gozo", id: "65" },
        { name: "Żebbuġ Malta", id: "66" },
        { name: "Żejtun", id: "67" },
        { name: "Żurrieq", id: "68" }
    ],
    "mh": [
        { name: "Ralik Chain", id: "L" },
        { name: "Ratak Chain", id: "T" }
    ],
    "mr": [
        { name: "Adrar Region", id: "07" },
        { name: "Assaba Region", id: "03" },
        { name: "Brakna Region", id: "05" },
        { name: "Dakhlet Nouadhibou", id: "08" },
        { name: "Gorgol Region", id: "04" },
        { name: "Guidimaka Region", id: "10" },
        { name: "Hodh Ech Chargui Region", id: "01" },
        { name: "Hodh El Gharbi Region", id: "02" },
        { name: "Inchiri Region", id: "12" },
        { name: "Nouakchott-Nord Region", id: "14" },
        { name: "Nouakchott-Ouest Region", id: "13" },
        { name: "Nouakchott-Sud Region", id: "15" },
        { name: "Tagant Region", id: "09" },
        { name: "Tiris Zemmour Region", id: "11" },
        { name: "Trarza Region", id: "06" }
    ],
    "mu": [
        { name: "Agaléga", id: "AG" },
        { name: "Beau Bassin-Rose Hill", id: "BR" },
        { name: "Cargados Carajos", id: "CC" },
        { name: "Curepipe", id: "CU" },
        { name: "Flacq District", id: "FL" },
        { name: "Grand Port District", id: "GP" },
        { name: "Moka District", id: "MO" },
        { name: "Pamplemousses District", id: "PA" },
        { name: "Plaines Wilhems District", id: "PW" },
        { name: "Port Louis", id: "PU" },
        { name: "Port Louis District", id: "PL" },
        { name: "Quatre Bornes", id: "QB" },
        { name: "Rivière du Rempart District", id: "RR" },
        { name: "Rivière Noire District", id: "BL" },
        { name: "Rodrigues", id: "RO" },
        { name: "Savanne District", id: "SA" },
        { name: "Vacoas-Phoenix", id: "VP" }
    ],
    "mx": [
        { name: "Aguascalientes", id: "AGU" },
        { name: "Baja California", id: "BCN" },
        { name: "Baja California Sur", id: "BCS" },
        { name: "Campeche", id: "CAM" },
        { name: "Chiapas", id: "CHP" },
        { name: "Chihuahua", id: "CHH" },
        { name: "Coahuila", id: "COA" },
        { name: "Colima", id: "COL" },
        { name: "Durango", id: "DUR" },
        { name: "Guanajuato", id: "GUA" },
        { name: "Guerrero", id: "GRO" },
        { name: "Hidalgo", id: "HID" },
        { name: "Jalisco", id: "JAL" },
        { name: "México", id: "MEX" },
        { name: "Mexico City", id: "CMX" },
        { name: "Michoacán", id: "MIC" },
        { name: "Morelos", id: "MOR" },
        { name: "Nayarit", id: "NAY" },
        { name: "Nuevo León", id: "NLE" },
        { name: "Oaxaca", id: "OAX" },
        { name: "Puebla", id: "PUE" },
        { name: "Querétaro", id: "QUE" },
        { name: "Quintana Roo", id: "ROO" },
        { name: "San Luis Potosí", id: "SLP" },
        { name: "Sinaloa", id: "SIN" },
        { name: "Sonora", id: "SON" },
        { name: "Tabasco", id: "TAB" },
        { name: "Tamaulipas", id: "TAM" },
        { name: "Tlaxcala", id: "TLA" },
        { name: "Veracruz", id: "VER" },
        { name: "Yucatán", id: "YUC" },
        { name: "Zacatecas", id: "ZAC" }
    ],
    "fm": [
        { name: "Chuuk State", id: "TRK" },
        { name: "Kosrae State", id: "KSA" },
        { name: "Pohnpei State", id: "PNI" },
        { name: "Yap State", id: "YAP" }
    ],
    "md": [
        { name: "Anenii Noi District", id: "AN" },
        { name: "Bălți Municipality", id: "BA" },
        { name: "Basarabeasca District", id: "BS" },
        { name: "Bender Municipality", id: "BD" },
        { name: "Briceni District", id: "BR" },
        { name: "Cahul District", id: "CA" },
        { name: "Călărași District", id: "CL" },
        { name: "Cantemir District", id: "CT" },
        { name: "Căușeni District", id: "CS" },
        { name: "Chișinău Municipality", id: "CU" },
        { name: "Cimișlia District", id: "CM" },
        { name: "Criuleni District", id: "CR" },
        { name: "Dondușeni District", id: "DO" },
        { name: "Drochia District", id: "DR" },
        { name: "Dubăsari District", id: "DU" },
        { name: "Edineț District", id: "ED" },
        { name: "Fălești District", id: "FA" },
        { name: "Florești District", id: "FL" },
        { name: "Gagauzia", id: "GA" },
        { name: "Glodeni District", id: "GL" },
        { name: "Hîncești District", id: "HI" },
        { name: "Ialoveni District", id: "IA" },
        { name: "Nisporeni District", id: "NI" },
        { name: "Ocnița District", id: "OC" },
        { name: "Orhei District", id: "OR" },
        { name: "Rezina District", id: "RE" },
        { name: "Rîșcani District", id: "RI" },
        { name: "Sîngerei District", id: "SI" },
        { name: "Șoldănești District", id: "SD" },
        { name: "Soroca District", id: "SO" },
        { name: "Ștefan Vodă District", id: "SV" },
        { name: "Strășeni District", id: "ST" },
        { name: "Taraclia District", id: "TA" },
        { name: "Telenești District", id: "TE" },
        { name: "Transnistria autonomous territorial unit", id: "SN" },
        { name: "Ungheni District", id: "UN" }
    ],
    "mc": [
        { name: "La Colle", id: "CL" },
        { name: "La Condamine", id: "CO" },
        { name: "Moneghetti", id: "MG" }
    ],
    "mn": [
        { name: "Arkhangai Province", id: "073" },
        { name: "Bayan-Ölgii Province", id: "071" },
        { name: "Bayankhongor Province", id: "069" },
        { name: "Bulgan Province", id: "067" },
        { name: "Darkhan-Uul Province", id: "037" },
        { name: "Dornod Province", id: "061" },
        { name: "Dornogovi Province", id: "063" },
        { name: "Dundgovi Province", id: "059" },
        { name: "Govi-Altai Province", id: "065" },
        { name: "Govisümber Province", id: "064" },
        { name: "Khentii Province", id: "039" },
        { name: "Khovd Province", id: "043" },
        { name: "Khövsgöl Province", id: "041" },
        { name: "Ömnögovi Province", id: "053" },
        { name: "Orkhon Province", id: "035" },
        { name: "Övörkhangai Province", id: "055" },
        { name: "Selenge Province", id: "049" },
        { name: "Sükhbaatar Province", id: "051" },
        { name: "Töv Province", id: "047" },
        { name: "Uvs Province", id: "046" },
        { name: "Zavkhan Province", id: "057" }
    ],
    "me": [
        { name: "Andrijevica Municipality", id: "01" },
        { name: "Bar Municipality", id: "02" },
        { name: "Berane Municipality", id: "03" },
        { name: "Bijelo Polje Municipality", id: "04" },
        { name: "Budva Municipality", id: "05" },
        { name: "Danilovgrad Municipality", id: "07" },
        { name: "Gusinje Municipality", id: "22" },
        { name: "Kolašin Municipality", id: "09" },
        { name: "Kotor Municipality", id: "10" },
        { name: "Mojkovac Municipality", id: "11" },
        { name: "Nikšić Municipality", id: "12" },
        { name: "Old Royal Capital Cetinje", id: "06" },
        { name: "Petnjica Municipality", id: "23" },
        { name: "Plav Municipality", id: "13" },
        { name: "Pljevlja Municipality", id: "14" },
        { name: "Plužine Municipality", id: "15" },
        { name: "Podgorica Municipality", id: "16" },
        { name: "Rožaje Municipality", id: "17" },
        { name: "Šavnik Municipality", id: "18" },
        { name: "Tivat Municipality", id: "19" },
        { name: "Ulcinj Municipality", id: "20" },
        { name: "Žabljak Municipality", id: "21" }
    ],
    "ma": [
        { name: "Agadir-Ida-Ou-Tanane", id: "AGD" },
        { name: "Al Haouz", id: "HAO" },
        { name: "Al Hoceïma", id: "HOC" },
        { name: "Aousserd (EH)", id: "AOU" },
        { name: "Assa-Zag (EH-partial)", id: "ASZ" },
        { name: "Azilal", id: "AZI" },
        { name: "Béni Mellal", id: "BEM" },
        { name: "Béni Mellal-Khénifra", id: "05" },
        { name: "Benslimane", id: "BES" },
        { name: "Berkane", id: "BER" },
        { name: "Berrechid", id: "BRR" },
        { name: "Boujdour (EH)", id: "BOD" },
        { name: "Boulemane", id: "BOM" },
        { name: "Casablanca", id: "CAS" },
        { name: "Casablanca-Settat", id: "06" },
        { name: "Chefchaouen", id: "CHE" },
        { name: "Chichaoua", id: "CHI" },
        { name: "Chtouka-Ait Baha", id: "CHT" },
        { name: "Dakhla-Oued Ed-Dahab (EH)", id: "12" },
        { name: "Drâa-Tafilalet", id: "08" },
        { name: "Driouch", id: "DRI" },
        { name: "El Hajeb", id: "HAJ" },
        { name: "El Jadida", id: "JDI" },
        { name: "El Kelâa des Sraghna", id: "KES" },
        { name: "Errachidia", id: "ERR" },
        { name: "Es-Semara (EH-partial)", id: "ESM" },
        { name: "Essaouira", id: "ESI" },
        { name: "Fahs-Anjra", id: "FAH" },
        { name: "Fès", id: "FES" },
        { name: "Fès-Meknès", id: "03" },
        { name: "Figuig", id: "FIG" },
        { name: "Fquih Ben Salah", id: "FQH" },
        { name: "Guelmim", id: "GUE" },
        { name: "Guelmim-Oued Noun (EH-partial)", id: "10" },
        { name: "Guercif", id: "GUF" },
        { name: "Ifrane", id: "IFR" },
        { name: "Inezgane-Ait Melloul", id: "INE" },
        { name: "Jerada", id: "JRA" },
        { name: "Kénitra", id: "KEN" },
        { name: "Khémisset", id: "KHE" },
        { name: "Khénifra", id: "KHN" },
        { name: "Khouribga", id: "KHO" },
        { name: "L'Oriental", id: "02" },
        { name: "Laâyoune (EH)", id: "LAA" },
        { name: "Laâyoune-Sakia El Hamra (EH-partial)", id: "11" },
        { name: "Larache", id: "LAR" },
        { name: "M’diq-Fnideq", id: "MDF" },
        { name: "Marrakech", id: "MAR" },
        { name: "Marrakesh-Safi", id: "07" },
        { name: "Médiouna", id: "MED" },
        { name: "Meknès", id: "MEK" },
        { name: "Midelt", id: "MID" },
        { name: "Mohammadia", id: "MOH" },
        { name: "Moulay Yacoub", id: "MOU" },
        { name: "Nador", id: "NAD" },
        { name: "Nouaceur", id: "NOU" },
        { name: "Ouarzazate", id: "OUA" },
        { name: "Oued Ed-Dahab (EH)", id: "OUD" },
        { name: "Ouezzane", id: "OUZ" },
        { name: "Oujda-Angad", id: "OUJ" },
        { name: "Rabat", id: "RAB" },
        { name: "Rabat-Salé-Kénitra", id: "04" },
        { name: "Rehamna", id: "REH" },
        { name: "Safi", id: "SAF" },
        { name: "Salé", id: "SAL" },
        { name: "Sefrou", id: "SEF" },
        { name: "Settat", id: "SET" },
        { name: "Sidi Bennour", id: "SIB" },
        { name: "Sidi Ifni", id: "SIF" },
        { name: "Sidi Kacem", id: "SIK" },
        { name: "Sidi Slimane", id: "SIL" },
        { name: "Skhirate-Témara", id: "SKH" },
        { name: "Souss-Massa", id: "09" },
        { name: "Tan-Tan (EH-partial)", id: "TNT" },
        { name: "Tanger-Assilah", id: "TNG" },
        { name: "Tanger-Tétouan-Al Hoceïma", id: "01" },
        { name: "Taounate", id: "TAO" },
        { name: "Taourirt", id: "TAI" },
        { name: "Tarfaya (EH-partial)", id: "TAF" },
        { name: "Taroudannt", id: "TAR" },
        { name: "Tata", id: "TAT" },
        { name: "Taza", id: "TAZ" },
        { name: "Tétouan", id: "TET" },
        { name: "Tinghir", id: "TIN" },
        { name: "Tiznit", id: "TIZ" },
        { name: "Youssoufia", id: "YUS" },
        { name: "Zagora", id: "ZAG" }
    ],
    "mz": [
        { name: "Cabo Delgado Province", id: "P" },
        { name: "Gaza Province", id: "G" },
        { name: "Inhambane Province", id: "I" },
        { name: "Manica Province", id: "B" },
        { name: "Maputo", id: "MPM" },
        { name: "Maputo Province", id: "L" },
        { name: "Nampula Province", id: "N" },
        { name: "Niassa Province", id: "A" },
        { name: "Sofala Province", id: "S" },
        { name: "Tete Province", id: "T" },
        { name: "Zambezia Province", id: "Q" }
    ],
    "mm": [
        { name: "Ayeyarwady Region", id: "07" },
        { name: "Bago", id: "02" },
        { name: "Chin State", id: "14" },
        { name: "Kachin State", id: "11" },
        { name: "Kayah State", id: "12" },
        { name: "Kayin State", id: "13" },
        { name: "Magway Region", id: "03" },
        { name: "Mandalay Region", id: "04" },
        { name: "Mon State", id: "15" },
        { name: "Naypyidaw Union Territory", id: "18" },
        { name: "Rakhine State", id: "16" },
        { name: "Sagaing Region", id: "01" },
        { name: "Shan State", id: "17" },
        { name: "Tanintharyi Region", id: "05" },
        { name: "Yangon Region", id: "06" }
    ],
    "na": [
        { name: "Erongo Region", id: "ER" },
        { name: "Hardap Region", id: "HA" },
        { name: "Karas Region", id: "KA" },
        { name: "Kavango East Region", id: "KE" },
        { name: "Kavango West Region", id: "KW" },
        { name: "Khomas Region", id: "KH" },
        { name: "Kunene Region", id: "KU" },
        { name: "Ohangwena Region", id: "OW" },
        { name: "Omaheke Region", id: "OH" },
        { name: "Omusati Region", id: "OS" },
        { name: "Oshana Region", id: "ON" },
        { name: "Oshikoto Region", id: "OT" },
        { name: "Otjozondjupa Region", id: "OD" },
        { name: "Zambezi Region", id: "CA" }
    ],
    "nr": [
        { name: "Aiwo District", id: "01" },
        { name: "Anabar District", id: "02" },
        { name: "Anetan District", id: "03" },
        { name: "Anibare District", id: "04" },
        { name: "Baiti District", id: "05" },
        { name: "Boe District", id: "06" },
        { name: "Buada District", id: "07" },
        { name: "Denigomodu District", id: "08" },
        { name: "Ewa District", id: "09" },
        { name: "Ijuw District", id: "10" },
        { name: "Meneng District", id: "11" },
        { name: "Nibok District", id: "12" },
        { name: "Uaboe District", id: "13" },
        { name: "Yaren District", id: "14" }
    ],
    "np": [
        { name: "Bagmati Zone", id: "BA" },
        { name: "Bheri Zone", id: "BH" },
        { name: "Central Region", id: "1" },
        { name: "Dhaulagiri Zone", id: "DH" },
        { name: "Eastern Development Region", id: "4" },
        { name: "Far-Western Development Region", id: "5" },
        { name: "Gandaki Zone", id: "GA" },
        { name: "Janakpur Zone", id: "JA" },
        { name: "Karnali Zone", id: "KA" },
        { name: "Kosi Zone", id: "KO" },
        { name: "Lumbini Zone", id: "LU" },
        { name: "Mahakali Zone", id: "MA" },
        { name: "Mechi Zone", id: "ME" },
        { name: "Mid-Western Region", id: "2" },
        { name: "Narayani Zone", id: "NA" },
        { name: "Rapti Zone", id: "RA" },
        { name: "Sagarmatha Zone", id: "SA" },
        { name: "Seti Zone", id: "SE" },
        { name: "Western Region", id: "3" }
    ],
    "nl": [
        { name: "Bonaire", id: "BQ1" },
        { name: "Drenthe", id: "DR" },
        { name: "Flevoland", id: "FL" },
        { name: "Friesland", id: "FR" },
        { name: "Gelderland", id: "GE" },
        { name: "Groningen", id: "GR" },
        { name: "Limburg", id: "LI" },
        { name: "North Brabant", id: "NB" },
        { name: "North Holland", id: "NH" },
        { name: "Overijssel", id: "OV" },
        { name: "Saba", id: "BQ2" },
        { name: "Sint Eustatius", id: "BQ3" },
        { name: "South Holland", id: "ZH" },
        { name: "Utrecht", id: "UT" },
        { name: "Zeeland", id: "ZE" }
    ],
    "nz": [
        { name: "Auckland Region", id: "AUK" },
        { name: "Bay of Plenty Region", id: "BOP" },
        { name: "Canterbury Region", id: "CAN" },
        { name: "Chatham Islands", id: "CIT" },
        { name: "Gisborne District", id: "GIS" },
        { name: "Hawke's Bay Region", id: "HKB" },
        { name: "Manawatu-Wanganui Region", id: "MWT" },
        { name: "Marlborough Region", id: "MBH" },
        { name: "Nelson Region", id: "NSN" },
        { name: "Northland Region", id: "NTL" },
        { name: "Otago Region", id: "OTA" },
        { name: "Southland Region", id: "STL" },
        { name: "Taranaki Region", id: "TKI" },
        { name: "Tasman District", id: "TAS" },
        { name: "Waikato Region", id: "WKO" },
        { name: "Wellington Region", id: "WGN" },
        { name: "West Coast Region", id: "WTC" }
    ],
    "ni": [
        { name: "Boaco", id: "BO" },
        { name: "Carazo", id: "CA" },
        { name: "Chinandega", id: "CI" },
        { name: "Chontales", id: "CO" },
        { name: "Estelí", id: "ES" },
        { name: "Granada", id: "GR" },
        { name: "Jinotega", id: "JI" },
        { name: "León", id: "LE" },
        { name: "Madriz", id: "MD" },
        { name: "Managua", id: "MN" },
        { name: "Masaya", id: "MS" },
        { name: "Matagalpa", id: "MT" },
        { name: "North Caribbean Coast", id: "AN" },
        { name: "Nueva Segovia", id: "NS" },
        { name: "Río San Juan", id: "SJ" },
        { name: "Rivas", id: "RI" },
        { name: "South Caribbean Coast", id: "AS" }
    ],
    "ne": [
        { name: "Agadez Region", id: "1" },
        { name: "Diffa Region", id: "2" },
        { name: "Dosso Region", id: "3" },
        { name: "Maradi Region", id: "4" },
        { name: "Tahoua Region", id: "5" },
        { name: "Tillabéri Region", id: "6" },
        { name: "Zinder Region", id: "7" }
    ],
    "ng": [
        { name: "Abia", id: "AB" },
        { name: "Abuja Federal Capital Territory", id: "FC" },
        { name: "Adamawa", id: "AD" },
        { name: "Akwa Ibom", id: "AK" },
        { name: "Anambra", id: "AN" },
        { name: "Bauchi", id: "BA" },
        { name: "Bayelsa", id: "BY" },
        { name: "Benue", id: "BE" },
        { name: "Borno", id: "BO" },
        { name: "Cross River", id: "CR" },
        { name: "Delta", id: "DE" },
        { name: "Ebonyi", id: "EB" },
        { name: "Edo", id: "ED" },
        { name: "Ekiti", id: "EK" },
        { name: "Enugu", id: "EN" },
        { name: "Gombe", id: "GO" },
        { name: "Imo", id: "IM" },
        { name: "Jigawa", id: "JI" },
        { name: "Kaduna", id: "KD" },
        { name: "Kano", id: "KN" },
        { name: "Katsina", id: "KT" },
        { name: "Kebbi", id: "KE" },
        { name: "Kogi", id: "KO" },
        { name: "Kwara", id: "KW" },
        { name: "Lagos", id: "LA" },
        { name: "Nasarawa", id: "NA" },
        { name: "Niger", id: "NI" },
        { name: "Ogun", id: "OG" },
        { name: "Ondo", id: "ON" },
        { name: "Osun", id: "OS" },
        { name: "Oyo", id: "OY" },
        { name: "Plateau", id: "PL" },
        { name: "Rivers", id: "RI" },
        { name: "Sokoto", id: "SO" },
        { name: "Taraba", id: "TA" },
        { name: "Yobe", id: "YO" },
        { name: "Zamfara", id: "ZA" }
    ],
    "kp": [
        { name: "Chagang Province", id: "04" },
        { name: "Kangwon Province", id: "07" },
        { name: "North Hamgyong Province", id: "09" },
        { name: "North Hwanghae Province", id: "06" },
        { name: "North Pyongan Province", id: "03" },
        { name: "Pyongyang", id: "01" },
        { name: "Rason", id: "13" },
        { name: "Ryanggang Province", id: "10" },
        { name: "South Hamgyong Province", id: "08" },
        { name: "South Hwanghae Province", id: "05" },
        { name: "South Pyongan Province", id: "02" }
    ],
    "no": [
        { name: "Akershus", id: "02" },
        { name: "Buskerud", id: "06" },
        { name: "Finnmark", id: "20" },
        { name: "Hedmark", id: "04" },
        { name: "Hordaland", id: "12" },
        { name: "Jan Mayen", id: "22" },
        { name: "Møre og Romsdal", id: "15" },
        { name: "Nord-Trøndelag", id: "17" },
        { name: "Nordland", id: "18" },
        { name: "Oppland", id: "05" },
        { name: "Oslo", id: "03" },
        { name: "Østfold", id: "01" },
        { name: "Rogaland", id: "11" },
        { name: "Sogn og Fjordane", id: "14" },
        { name: "Sør-Trøndelag", id: "16" },
        { name: "Svalbard", id: "21" },
        { name: "Telemark", id: "08" },
        { name: "Troms", id: "19" },
        { name: "Trøndelag", id: "50" },
        { name: "Vest-Agder", id: "10" },
        { name: "Vestfold", id: "07" }
    ],
    "om": [
        { name: "Ad Dakhiliyah Governorate", id: "DA" },
        { name: "Ad Dhahirah Governorate", id: "ZA" },
        { name: "Al Batinah North Governorate", id: "BS" },
        { name: "Al Batinah Region", id: "BA" },
        { name: "Al Batinah South Governorate", id: "BJ" },
        { name: "Al Buraimi Governorate", id: "BU" },
        { name: "Al Wusta Governorate", id: "WU" },
        { name: "Ash Sharqiyah North Governorate", id: "SS" },
        { name: "Ash Sharqiyah Region", id: "SH" },
        { name: "Ash Sharqiyah South Governorate", id: "SJ" },
        { name: "Dhofar Governorate", id: "ZU" },
        { name: "Musandam Governorate", id: "MU" },
        { name: "Muscat Governorate", id: "MA" }
    ],
    "pk": [
        { name: "Azad Kashmir", id: "JK" },
        { name: "Balochistan", id: "BA" },
        { name: "Federally Administered Tribal Areas", id: "TA" },
        { name: "Gilgit-Baltistan", id: "GB" },
        { name: "Islamabad Capital Territory", id: "IS" },
        { name: "Khyber Pakhtunkhwa", id: "KP" },
        { name: "Punjab", id: "PB" },
        { name: "Sindh", id: "SD" }
    ],
    "pw": [
        { name: "Aimeliik", id: "002" },
        { name: "Airai", id: "004" },
        { name: "Angaur", id: "010" },
        { name: "Hatohobei", id: "050" },
        { name: "Kayangel", id: "100" },
        { name: "Koror", id: "150" },
        { name: "Melekeok", id: "212" },
        { name: "Ngaraard", id: "214" },
        { name: "Ngarchelong", id: "218" },
        { name: "Ngardmau", id: "222" },
        { name: "Ngatpang", id: "224" },
        { name: "Ngchesar", id: "226" },
        { name: "Ngeremlengui", id: "227" },
        { name: "Ngiwal", id: "228" },
        { name: "Peleliu", id: "350" },
        { name: "Sonsorol", id: "370" }
    ],
    "pa": [
        { name: "Bocas del Toro Province", id: "1" },
        { name: "Chiriquí Province", id: "4" },
        { name: "Coclé Province", id: "2" },
        { name: "Colón Province", id: "3" },
        { name: "Darién Province", id: "5" },
        { name: "Emberá-Wounaan Comarca", id: "EM" },
        { name: "Guna Yala", id: "KY" },
        { name: "Herrera Province", id: "6" },
        { name: "Los Santos Province", id: "7" },
        { name: "Ngöbe-Buglé Comarca", id: "NB" },
        { name: "Panamá Oeste Province", id: "10" },
        { name: "Panamá Province", id: "8" },
        { name: "Veraguas Province", id: "9" }
    ],
    "pg": [
        { name: "Bougainville", id: "NSB" },
        { name: "Central Province", id: "CPM" },
        { name: "Chimbu Province", id: "CPK" },
        { name: "East New Britain", id: "EBR" },
        { name: "Eastern Highlands Province", id: "EHG" },
        { name: "Enga Province", id: "EPW" },
        { name: "Gulf", id: "GPK" },
        { name: "Hela", id: "HLA" },
        { name: "Jiwaka Province", id: "JWK" },
        { name: "Madang Province", id: "MPM" },
        { name: "Manus Province", id: "MRL" },
        { name: "Milne Bay Province", id: "MBA" },
        { name: "Morobe Province", id: "MPL" },
        { name: "New Ireland Province", id: "NIK" },
        { name: "Oro Province", id: "NPP" },
        { name: "Port Moresby", id: "NCD" },
        { name: "Sandaun Province", id: "SAN" },
        { name: "Southern Highlands Province", id: "SHM" },
        { name: "West New Britain Province", id: "WBK" },
        { name: "Western Highlands Province", id: "WHM" },
        { name: "Western Province", id: "WPD" }
    ],
    "py": [
        { name: "Alto Paraguay Department", id: "16" },
        { name: "Alto Paraná Department", id: "10" },
        { name: "Amambay Department", id: "13" },
        { name: "Boquerón Department", id: "19" },
        { name: "Caaguazú", id: "5" },
        { name: "Caazapá", id: "6" },
        { name: "Canindeyú", id: "14" },
        { name: "Central Department", id: "11" },
        { name: "Concepción Department", id: "1" },
        { name: "Cordillera Department", id: "3" },
        { name: "Guairá Department", id: "4" },
        { name: "Itapúa", id: "7" },
        { name: "Misiones Department", id: "8" },
        { name: "Ñeembucú Department", id: "12" },
        { name: "Paraguarí Department", id: "9" },
        { name: "Presidente Hayes Department", id: "15" },
        { name: "San Pedro Department", id: "2" }
    ],
    "pe": [
        { name: "Amazonas", id: "AMA" },
        { name: "Áncash", id: "ANC" },
        { name: "Apurímac", id: "APU" },
        { name: "Arequipa", id: "ARE" },
        { name: "Ayacucho", id: "AYA" },
        { name: "Cajamarca", id: "CAJ" },
        { name: "Callao", id: "CAL" },
        { name: "Cusco", id: "CUS" },
        { name: "Huancavelica", id: "HUV" },
        { name: "Huanuco", id: "HUC" },
        { name: "Ica", id: "ICA" },
        { name: "Junín", id: "JUN" },
        { name: "La Libertad", id: "LAL" },
        { name: "Lambayeque", id: "LAM" },
        { name: "Lima", id: "LIM" },
        { name: "Loreto", id: "LOR" },
        { name: "Madre de Dios", id: "MDD" },
        { name: "Moquegua", id: "MOQ" },
        { name: "Pasco", id: "PAS" },
        { name: "Piura", id: "PIU" },
        { name: "Puno", id: "PUN" },
        { name: "San Martín", id: "SAM" },
        { name: "Tacna", id: "TAC" },
        { name: "Tumbes", id: "TUM" },
        { name: "Ucayali", id: "UCA" }
    ],
    "ph": [
        { name: "Abra", id: "ABR" },
        { name: "Agusan del Norte", id: "AGN" },
        { name: "Agusan del Sur", id: "AGS" },
        { name: "Aklan", id: "AKL" },
        { name: "Albay", id: "ALB" },
        { name: "Antique", id: "ANT" },
        { name: "Apayao", id: "APA" },
        { name: "Aurora", id: "AUR" },
        { name: "Autonomous Region in Muslim Mindanao", id: "14" },
        { name: "Basilan", id: "BAS" },
        { name: "Bataan", id: "BAN" },
        { name: "Batanes", id: "BTN" },
        { name: "Batangas", id: "BTG" },
        { name: "Benguet", id: "BEN" },
        { name: "Bicol Region", id: "05" },
        { name: "Biliran", id: "BIL" },
        { name: "Bohol", id: "BOH" },
        { name: "Bukidnon", id: "BUK" },
        { name: "Bulacan", id: "BUL" },
        { name: "Cagayan", id: "CAG" },
        { name: "Cagayan Valley", id: "02" },
        { name: "Calabarzon", id: "40" },
        { name: "Camarines Norte", id: "CAN" },
        { name: "Camarines Sur", id: "CAS" },
        { name: "Camiguin", id: "CAM" },
        { name: "Capiz", id: "CAP" },
        { name: "Caraga", id: "13" },
        { name: "Catanduanes", id: "CAT" },
        { name: "Cavite", id: "CAV" },
        { name: "Cebu", id: "CEB" },
        { name: "Central Luzon", id: "03" },
        { name: "Central Visayas", id: "07" },
        { name: "Compostela Valley", id: "COM" },
        { name: "Cordillera Administrative Region", id: "15" },
        { name: "Cotabato", id: "NCO" },
        { name: "Davao del Norte", id: "DAV" },
        { name: "Davao del Sur", id: "DAS" },
        { name: "Davao Occidental", id: "DVO" },
        { name: "Davao Oriental", id: "DAO" },
        { name: "Davao Region", id: "11" },
        { name: "Dinagat Islands", id: "DIN" },
        { name: "Eastern Samar", id: "EAS" },
        { name: "Eastern Visayas", id: "08" },
        { name: "Guimaras", id: "GUI" },
        { name: "Ifugao", id: "IFU" },
        { name: "Ilocos Norte", id: "ILN" },
        { name: "Ilocos Region", id: "01" },
        { name: "Ilocos Sur", id: "ILS" },
        { name: "Iloilo", id: "ILI" },
        { name: "Isabela", id: "ISA" },
        { name: "Kalinga", id: "KAL" },
        { name: "La Union", id: "LUN" },
        { name: "Laguna", id: "LAG" },
        { name: "Lanao del Norte", id: "LAN" },
        { name: "Lanao del Sur", id: "LAS" },
        { name: "Leyte", id: "LEY" },
        { name: "Maguindanao", id: "MAG" },
        { name: "Marinduque", id: "MAD" },
        { name: "Masbate", id: "MAS" },
        { name: "Metro Manila", id: "NCR" },
        { name: "Mimaropa", id: "41" },
        { name: "Misamis Occidental", id: "MSC" },
        { name: "Misamis Oriental", id: "MSR" },
        { name: "Mountain Province", id: "MOU" },
        { name: "Negros Occidental", id: "NEC" },
        { name: "Negros Oriental", id: "NER" },
        { name: "Northern Mindanao", id: "10" },
        { name: "Northern Samar", id: "NSA" },
        { name: "Nueva Ecija", id: "NUE" },
        { name: "Nueva Vizcaya", id: "NUV" },
        { name: "Occidental Mindoro", id: "MDC" },
        { name: "Oriental Mindoro", id: "MDR" },
        { name: "Palawan", id: "PLW" },
        { name: "Pampanga", id: "PAM" },
        { name: "Pangasinan", id: "PAN" },
        { name: "Quezon", id: "QUE" },
        { name: "Quirino", id: "QUI" },
        { name: "Rizal", id: "RIZ" },
        { name: "Romblon", id: "ROM" },
        { name: "Sarangani", id: "SAR" },
        { name: "Siquijor", id: "SIG" },
        { name: "Soccsksargen", id: "12" },
        { name: "Sorsogon", id: "SOR" },
        { name: "South Cotabato", id: "SCO" },
        { name: "Southern Leyte", id: "SLE" },
        { name: "Sultan Kudarat", id: "SUK" },
        { name: "Sulu", id: "SLU" },
        { name: "Surigao del Norte", id: "SUN" },
        { name: "Surigao del Sur", id: "SUR" },
        { name: "Tarlac", id: "TAR" },
        { name: "Tawi-Tawi", id: "TAW" },
        { name: "Western Visayas", id: "06" },
        { name: "Zambales", id: "ZMB" },
        { name: "Zamboanga del Norte", id: "ZAN" },
        { name: "Zamboanga del Sur", id: "ZAS" },
        { name: "Zamboanga Peninsula", id: "09" },
        { name: "Zamboanga Sibugay", id: "ZSI" }
    ],
    "pl": [
        { name: "Greater Poland Voivodeship", id: "WP" },
        { name: "Kuyavian-Pomeranian Voivodeship", id: "KP" },
        { name: "Lesser Poland Voivodeship", id: "MA" },
        { name: "Lower Silesian Voivodeship", id: "DS" },
        { name: "Lublin Voivodeship", id: "LU" },
        { name: "Lubusz Voivodeship", id: "LB" },
        { name: "Łódź Voivodeship", id: "LD" },
        { name: "Masovian Voivodeship", id: "MZ" },
        { name: "Opole Voivodeship", id: "OP" },
        { name: "Podkarpackie Voivodeship", id: "PK" },
        { name: "Podlaskie Voivodeship", id: "PD" },
        { name: "Pomeranian Voivodeship", id: "PM" },
        { name: "Silesian Voivodeship", id: "SL" },
        { name: "Świętokrzyskie Voivodeship", id: "SK" },
        { name: "Warmian-Masurian Voivodeship", id: "WN" },
        { name: "West Pomeranian Voivodeship", id: "ZP" }
    ],
    "pt": [
        { name: "Açores", id: "20" },
        { name: "Aveiro", id: "01" },
        { name: "Beja", id: "02" },
        { name: "Braga", id: "03" },
        { name: "Bragança", id: "04" },
        { name: "Castelo Branco", id: "05" },
        { name: "Coimbra", id: "06" },
        { name: "Évora", id: "07" },
        { name: "Faro", id: "08" },
        { name: "Guarda", id: "09" },
        { name: "Leiria", id: "10" },
        { name: "Lisbon", id: "11" },
        { name: "Madeira", id: "30" },
        { name: "Portalegre", id: "12" },
        { name: "Porto", id: "13" },
        { name: "Santarém", id: "14" },
        { name: "Setúbal", id: "15" },
        { name: "Viana do Castelo", id: "16" },
        { name: "Vila Real", id: "17" },
        { name: "Viseu", id: "18" }
    ],
    "qa": [
        { name: "Al Daayen", id: "ZA" },
        { name: "Al Khor", id: "KH" },
        { name: "Al Rayyan Municipality", id: "RA" },
        { name: "Al Wakrah", id: "WA" },
        { name: "Al-Shahaniya", id: "SH" },
        { name: "Doha", id: "DA" },
        { name: "Madinat ash Shamal", id: "MS" },
        { name: "Umm Salal Municipality", id: "US" }
    ],
    "ro": [
        { name: "Alba", id: "AB" },
        { name: "Arad County", id: "AR" },
        { name: "Arges", id: "AG" },
        { name: "Bacău County", id: "BC" },
        { name: "Bihor County", id: "BH" },
        { name: "Bistrița-Năsăud County", id: "BN" },
        { name: "Botoșani County", id: "BT" },
        { name: "Braila", id: "BR" },
        { name: "Brașov County", id: "BV" },
        { name: "Bucharest", id: "B" },
        { name: "Buzău County", id: "BZ" },
        { name: "Călărași County", id: "CL" },
        { name: "Caraș-Severin County", id: "CS" },
        { name: "Cluj County", id: "CJ" },
        { name: "Constanța County", id: "CT" },
        { name: "Covasna County", id: "CV" },
        { name: "Dâmbovița County", id: "DB" },
        { name: "Dolj County", id: "DJ" },
        { name: "Galați County", id: "GL" },
        { name: "Giurgiu County", id: "GR" },
        { name: "Gorj County", id: "GJ" },
        { name: "Harghita County", id: "HR" },
        { name: "Hunedoara County", id: "HD" },
        { name: "Ialomița County", id: "IL" },
        { name: "Iași County", id: "IS" },
        { name: "Ilfov County", id: "IF" },
        { name: "Maramureș County", id: "MM" },
        { name: "Mehedinți County", id: "MH" },
        { name: "Mureș County", id: "MS" },
        { name: "Neamț County", id: "NT" },
        { name: "Olt County", id: "OT" },
        { name: "Prahova County", id: "PH" },
        { name: "Sălaj County", id: "SJ" },
        { name: "Satu Mare County", id: "SM" },
        { name: "Sibiu County", id: "SB" },
        { name: "Suceava County", id: "SV" },
        { name: "Teleorman County", id: "TR" },
        { name: "Timiș County", id: "TM" },
        { name: "Tulcea County", id: "TL" },
        { name: "Vâlcea County", id: "VL" },
        { name: "Vaslui County", id: "VS" },
        { name: "Vrancea County", id: "VN" }
    ],
    "ru": [
        { name: "Altai Krai", id: "ALT" },
        { name: "Altai Republic", id: "AL" },
        { name: "Amur Oblast", id: "AMU" },
        { name: "Arkhangelsk", id: "ARK" },
        { name: "Astrakhan Oblast", id: "AST" },
        { name: "Belgorod Oblast", id: "BEL" },
        { name: "Bryansk Oblast", id: "BRY" },
        { name: "Chechen Republic", id: "CE" },
        { name: "Chelyabinsk Oblast", id: "CHE" },
        { name: "Chukotka Autonomous Okrug", id: "CHU" },
        { name: "Chuvash Republic", id: "CU" },
        { name: "Irkutsk", id: "IRK" },
        { name: "Ivanovo Oblast", id: "IVA" },
        { name: "Jewish Autonomous Oblast", id: "YEV" },
        { name: "Kabardino-Balkar Republic", id: "KB" },
        { name: "Kaliningrad", id: "KGD" },
        { name: "Kaluga Oblast", id: "KLU" },
        { name: "Kamchatka Krai", id: "KAM" },
        { name: "Karachay-Cherkess Republic", id: "KC" },
        { name: "Kemerovo Oblast", id: "KEM" },
        { name: "Khabarovsk Krai", id: "KHA" },
        { name: "Khanty-Mansi Autonomous Okrug", id: "KHM" },
        { name: "Kirov Oblast", id: "KIR" },
        { name: "Komi Republic", id: "KO" },
        { name: "Kostroma Oblast", id: "KOS" },
        { name: "Krasnodar Krai", id: "KDA" },
        { name: "Krasnoyarsk Krai", id: "KYA" },
        { name: "Kurgan Oblast", id: "KGN" },
        { name: "Kursk Oblast", id: "KRS" },
        { name: "Leningrad Oblast", id: "LEN" },
        { name: "Lipetsk Oblast", id: "LIP" },
        { name: "Magadan Oblast", id: "MAG" },
        { name: "Mari El Republic", id: "ME" },
        { name: "Moscow", id: "MOW" },
        { name: "Moscow Oblast", id: "MOS" },
        { name: "Murmansk Oblast", id: "MUR" },
        { name: "Nenets Autonomous Okrug", id: "NEN" },
        { name: "Nizhny Novgorod Oblast", id: "NIZ" },
        { name: "Novgorod Oblast", id: "NGR" },
        { name: "Novosibirsk", id: "NVS" },
        { name: "Omsk Oblast", id: "OMS" },
        { name: "Orenburg Oblast", id: "ORE" },
        { name: "Oryol Oblast", id: "ORL" },
        { name: "Penza Oblast", id: "PNZ" },
        { name: "Perm Krai", id: "PER" },
        { name: "Primorsky Krai", id: "PRI" },
        { name: "Pskov Oblast", id: "PSK" },
        { name: "Republic of Adygea", id: "AD" },
        { name: "Republic of Bashkortostan", id: "BA" },
        { name: "Republic of Buryatia", id: "BU" },
        { name: "Republic of Dagestan", id: "DA" },
        { name: "Republic of Ingushetia", id: "IN" },
        { name: "Republic of Kalmykia", id: "KL" },
        { name: "Republic of Karelia", id: "KR" },
        { name: "Republic of Khakassia", id: "KK" },
        { name: "Republic of Mordovia", id: "MO" },
        { name: "Republic of North Ossetia-Alania", id: "SE" },
        { name: "Republic of Tatarstan", id: "TA" },
        { name: "Rostov Oblast", id: "ROS" },
        { name: "Ryazan Oblast", id: "RYA" },
        { name: "Saint Petersburg", id: "SPE" },
        { name: "Sakha Republic", id: "SA" },
        { name: "Sakhalin", id: "SAK" },
        { name: "Samara Oblast", id: "SAM" },
        { name: "Saratov Oblast", id: "SAR" },
        { name: "Sevastopol", id: "UA-40" },
        { name: "Smolensk Oblast", id: "SMO" },
        { name: "Stavropol Krai", id: "STA" },
        { name: "Sverdlovsk", id: "SVE" },
        { name: "Tambov Oblast", id: "TAM" },
        { name: "Tomsk Oblast", id: "TOM" },
        { name: "Tula Oblast", id: "TUL" },
        { name: "Tuva Republic", id: "TY" },
        { name: "Tver Oblast", id: "TVE" },
        { name: "Tyumen Oblast", id: "TYU" },
        { name: "Udmurt Republic", id: "UD" },
        { name: "Ulyanovsk Oblast", id: "ULY" },
        { name: "Vladimir Oblast", id: "VLA" },
        { name: "Volgograd Oblast", id: "VGG" },
        { name: "Vologda Oblast", id: "VLG" },
        { name: "Voronezh Oblast", id: "VOR" },
        { name: "Yamalo-Nenets Autonomous Okrug", id: "YAN" },
        { name: "Yaroslavl Oblast", id: "YAR" },
        { name: "Zabaykalsky Krai", id: "ZAB" }
    ],
    "rw": [
        { name: "Eastern Province", id: "02" },
        { name: "Kigali district", id: "01" },
        { name: "Northern Province", id: "03" },
        { name: "Southern Province", id: "05" },
        { name: "Western Province", id: "04" }
    ],
    "kn": [
        { name: "Christ Church Nichola Town Parish", id: "01" },
        { name: "Nevis", id: "N" },
        { name: "Saint Anne Sandy Point Parish", id: "02" },
        { name: "Saint George Gingerland Parish", id: "04" },
        { name: "Saint James Windward Parish", id: "05" },
        { name: "Saint John Capisterre Parish", id: "06" },
        { name: "Saint John Figtree Parish", id: "07" },
        { name: "Saint Kitts", id: "K" },
        { name: "Saint Mary Cayon Parish", id: "08" },
        { name: "Saint Paul Capisterre Parish", id: "09" },
        { name: "Saint Paul Charlestown Parish", id: "10" },
        { name: "Saint Peter Basseterre Parish", id: "11" },
        { name: "Saint Thomas Lowland Parish", id: "12" },
        { name: "Saint Thomas Middle Island Parish", id: "13" },
        { name: "Trinity Palmetto Point Parish", id: "15" }
    ],
    "lc": [
        { name: "Anse la Raye Quarter", id: "01" },
        { name: "Canaries", id: "12" },
        { name: "Castries Quarter", id: "02" },
        { name: "Choiseul Quarter", id: "03" },
        { name: "Dauphin Quarter", id: "04" },
        { name: "Dennery Quarter", id: "05" },
        { name: "Gros Islet Quarter", id: "06" },
        { name: "Laborie Quarter", id: "07" },
        { name: "Micoud Quarter", id: "08" },
        { name: "Praslin Quarter", id: "09" },
        { name: "Soufrière Quarter", id: "10" },
        { name: "Vieux Fort Quarter", id: "11" }
    ],
    "vc": [
        { name: "Charlotte Parish", id: "01" },
        { name: "Grenadines Parish", id: "06" },
        { name: "Saint Andrew Parish", id: "02" },
        { name: "Saint David Parish", id: "03" },
        { name: "Saint George Parish", id: "04" },
        { name: "Saint Patrick Parish", id: "05" }
    ],
    "ws": [
        { name: "A'ana", id: "AA" },
        { name: "Aiga-i-le-Tai", id: "AL" },
        { name: "Atua", id: "AT" },
        { name: "Fa'asaleleaga", id: "FA" },
        { name: "Gaga'emauga", id: "GE" },
        { name: "Gaga'ifomauga", id: "GI" },
        { name: "Palauli", id: "PA" },
        { name: "Satupa'itea", id: "SA" },
        { name: "Tuamasaga", id: "TU" },
        { name: "Va'a-o-Fonoti", id: "VF" },
        { name: "Vaisigano", id: "VS" }
    ],
    "sm": [
        { name: "Acquaviva", id: "01" },
        { name: "Borgo Maggiore", id: "06" },
        { name: "Chiesanuova", id: "02" },
        { name: "Domagnano", id: "03" },
        { name: "Faetano", id: "04" },
        { name: "Fiorentino", id: "05" },
        { name: "Montegiardino", id: "08" },
        { name: "San Marino", id: "07" },
        { name: "Serravalle", id: "09" }
    ],
    "st": [
        { name: "Príncipe Province", id: "P" },
        { name: "São Tomé Province", id: "S" }
    ],
    "sa": [
        { name: "'Asir", id: "14" },
        { name: "Al Bahah", id: "11" },
        { name: "Al Jawf", id: "12" },
        { name: "Al Madinah", id: "03" },
        { name: "Al-Qassim", id: "05" },
        { name: "Eastern Province", id: "04" },
        { name: "Ha'il", id: "06" },
        { name: "Jizan", id: "09" },
        { name: "Makkah", id: "02" },
        { name: "Najran", id: "10" },
        { name: "Northern Borders", id: "08" },
        { name: "Riyadh", id: "01" },
        { name: "Tabuk", id: "07" }
    ],
    "sn": [
        { name: "Dakar", id: "DK" },
        { name: "Diourbel Region", id: "DB" },
        { name: "Fatick", id: "FK" },
        { name: "Kaffrine", id: "KA" },
        { name: "Kaolack", id: "KL" },
        { name: "Kédougou", id: "KE" },
        { name: "Kolda", id: "KD" },
        { name: "Louga", id: "LG" },
        { name: "Matam", id: "MT" },
        { name: "Saint-Louis", id: "SL" },
        { name: "Sédhiou", id: "SE" },
        { name: "Tambacounda Region", id: "TC" },
        { name: "Thiès Region", id: "TH" },
        { name: "Ziguinchor", id: "ZG" }
    ],
    "rs": [
        { name: "Belgrade", id: "00" },
        { name: "Bor District", id: "14" },
        { name: "Braničevo District", id: "11" },
        { name: "Central Banat District", id: "02" },
        { name: "Jablanica District", id: "23" },
        { name: "Kolubara District", id: "09" },
        { name: "Mačva District", id: "08" },
        { name: "Moravica District", id: "17" },
        { name: "Nišava District", id: "20" },
        { name: "North Bačka District", id: "01" },
        { name: "North Banat District", id: "03" },
        { name: "Pčinja District", id: "24" },
        { name: "Pirot District", id: "22" },
        { name: "Podunavlje District", id: "10" },
        { name: "Pomoravlje District", id: "13" },
        { name: "Rasina District", id: "19" },
        { name: "Raška District", id: "18" },
        { name: "South Bačka District", id: "06" },
        { name: "South Banat District", id: "04" },
        { name: "Srem District", id: "07" },
        { name: "Šumadija District", id: "12" },
        { name: "Toplica District", id: "21" },
        { name: "Vojvodina", id: "VO" },
        { name: "West Bačka District", id: "05" },
        { name: "Zaječar District", id: "15" },
        { name: "Zlatibor District", id: "16" }
    ],
    "sc": [
        { name: "Anse Boileau", id: "02" },
        { name: "Anse Royale", id: "05" },
        { name: "Anse-aux-Pins", id: "01" },
        { name: "Au Cap", id: "04" },
        { name: "Baie Lazare", id: "06" },
        { name: "Baie Sainte Anne", id: "07" },
        { name: "Beau Vallon", id: "08" },
        { name: "Bel Air", id: "09" },
        { name: "Bel Ombre", id: "10" },
        { name: "Cascade", id: "11" },
        { name: "Glacis", id: "12" },
        { name: "Grand'Anse Mahé", id: "13" },
        { name: "Grand'Anse Praslin", id: "14" },
        { name: "La Digue", id: "15" },
        { name: "La Rivière Anglaise", id: "16" },
        { name: "Les Mamelles", id: "24" },
        { name: "Mont Buxton", id: "17" },
        { name: "Mont Fleuri", id: "18" },
        { name: "Plaisance", id: "19" },
        { name: "Pointe La Rue", id: "20" },
        { name: "Port Glaud", id: "21" },
        { name: "Roche Caiman", id: "25" },
        { name: "Saint Louis", id: "22" },
        { name: "Takamaka", id: "23" }
    ],
    "sl": [
        { name: "Eastern Province", id: "E" },
        { name: "Northern Province", id: "N" },
        { name: "Southern Province", id: "S" },
        { name: "Western Area", id: "W" }
    ],
    "sg": [
        { name: "Central Singapore Community Development Council", id: "01" },
        { name: "North East Community Development Council", id: "02" },
        { name: "North West Community Development Council", id: "03" },
        { name: "South East Community Development Council", id: "04" },
        { name: "South West Community Development Council", id: "05" }
    ],
    "sk": [
        { name: "Banská Bystrica Region", id: "BC" },
        { name: "Bratislava Region", id: "BL" },
        { name: "Košice Region", id: "KI" },
        { name: "Nitra Region", id: "NI" },
        { name: "Prešov Region", id: "PV" },
        { name: "Trenčín Region", id: "TC" },
        { name: "Trnava Region", id: "TA" },
        { name: "Žilina Region", id: "ZI" }
    ],
    "si": [
        { name: "Ajdovščina Municipality", id: "001" },
        { name: "Ankaran Municipality", id: "213" },
        { name: "Beltinci Municipality", id: "002" },
        { name: "Benedikt Municipality", id: "148" },
        { name: "Bistrica ob Sotli Municipality", id: "149" },
        { name: "Bled Municipality", id: "003" },
        { name: "Bloke Municipality", id: "150" },
        { name: "Bohinj Municipality", id: "004" },
        { name: "Borovnica Municipality", id: "005" },
        { name: "Bovec Municipality", id: "006" },
        { name: "Braslovče Municipality", id: "151" },
        { name: "Brda Municipality", id: "007" },
        { name: "Brežice Municipality", id: "009" },
        { name: "Brezovica Municipality", id: "008" },
        { name: "Cankova Municipality", id: "152" },
        { name: "Cerklje na Gorenjskem Municipality", id: "012" },
        { name: "Cerknica Municipality", id: "013" },
        { name: "Cerkno Municipality", id: "014" },
        { name: "Cerkvenjak Municipality", id: "153" },
        { name: "City Municipality of Celje", id: "011" },
        { name: "City Municipality of Novo Mesto", id: "085" },
        { name: "Črenšovci Municipality", id: "015" },
        { name: "Črna na Koroškem Municipality", id: "016" },
        { name: "Črnomelj Municipality", id: "017" },
        { name: "Destrnik Municipality", id: "018" },
        { name: "Divača Municipality", id: "019" },
        { name: "Dobje Municipality", id: "154" },
        { name: "Dobrepolje Municipality", id: "020" },
        { name: "Dobrna Municipality", id: "155" },
        { name: "Dobrova–Polhov Gradec Municipality", id: "021" },
        { name: "Dobrovnik Municipality", id: "156" },
        { name: "Dol pri Ljubljani Municipality", id: "022" },
        { name: "Dolenjske Toplice Municipality", id: "157" },
        { name: "Domžale Municipality", id: "023" },
        { name: "Dornava Municipality", id: "024" },
        { name: "Dravograd Municipality", id: "025" },
        { name: "Duplek Municipality", id: "026" },
        { name: "Gorenja Vas–Poljane Municipality", id: "027" },
        { name: "Gorišnica Municipality", id: "028" },
        { name: "Gorje Municipality", id: "207" },
        { name: "Gornja Radgona Municipality", id: "029" },
        { name: "Gornji Grad Municipality", id: "030" },
        { name: "Gornji Petrovci Municipality", id: "031" },
        { name: "Grad Municipality", id: "158" },
        { name: "Grosuplje Municipality", id: "032" },
        { name: "Hajdina Municipality", id: "159" },
        { name: "Hoče–Slivnica Municipality", id: "160" },
        { name: "Hodoš Municipality", id: "161" },
        { name: "Horjul Municipality", id: "162" },
        { name: "Hrastnik Municipality", id: "034" },
        { name: "Hrpelje–Kozina Municipality", id: "035" },
        { name: "Idrija Municipality", id: "036" },
        { name: "Ig Municipality", id: "037" },
        { name: "Ivančna Gorica Municipality", id: "039" },
        { name: "Izola Municipality", id: "040" },
        { name: "Jesenice Municipality", id: "041" },
        { name: "Jezersko Municipality", id: "163" },
        { name: "Juršinci Municipality", id: "042" },
        { name: "Kamnik Municipality", id: "043" },
        { name: "Kanal ob Soči Municipality", id: "044" },
        { name: "Kidričevo Municipality", id: "045" },
        { name: "Kobarid Municipality", id: "046" },
        { name: "Kobilje Municipality", id: "047" },
        { name: "Kočevje Municipality", id: "048" },
        { name: "Komen Municipality", id: "049" },
        { name: "Komenda Municipality", id: "164" },
        { name: "Koper City Municipality", id: "050" },
        { name: "Kostanjevica na Krki Municipality", id: "197" },
        { name: "Kostel Municipality", id: "165" },
        { name: "Kozje Municipality", id: "051" },
        { name: "Kranj City Municipality", id: "052" },
        { name: "Kranjska Gora Municipality", id: "053" },
        { name: "Križevci Municipality", id: "166" },
        { name: "Kungota", id: "055" },
        { name: "Kuzma Municipality", id: "056" },
        { name: "Laško Municipality", id: "057" },
        { name: "Lenart Municipality", id: "058" },
        { name: "Lendava Municipality", id: "059" },
        { name: "Litija Municipality", id: "060" },
        { name: "Ljubljana City Municipality", id: "061" },
        { name: "Ljubno Municipality", id: "062" },
        { name: "Ljutomer Municipality", id: "063" },
        { name: "Log–Dragomer Municipality", id: "208" },
        { name: "Logatec Municipality", id: "064" },
        { name: "Loška Dolina Municipality", id: "065" },
        { name: "Loški Potok Municipality", id: "066" },
        { name: "Lovrenc na Pohorju Municipality", id: "167" },
        { name: "Luče Municipality", id: "067" },
        { name: "Lukovica Municipality", id: "068" },
        { name: "Majšperk Municipality", id: "069" },
        { name: "Makole Municipality", id: "198" },
        { name: "Maribor City Municipality", id: "070" },
        { name: "Markovci Municipality", id: "168" },
        { name: "Medvode Municipality", id: "071" },
        { name: "Mengeš Municipality", id: "072" },
        { name: "Metlika Municipality", id: "073" },
        { name: "Mežica Municipality", id: "074" },
        { name: "Miklavž na Dravskem Polju Municipality", id: "169" },
        { name: "Miren–Kostanjevica Municipality", id: "075" },
        { name: "Mirna Municipality", id: "212" },
        { name: "Mirna Peč Municipality", id: "170" },
        { name: "Mislinja Municipality", id: "076" },
        { name: "Mokronog–Trebelno Municipality", id: "199" },
        { name: "Moravče Municipality", id: "077" },
        { name: "Moravske Toplice Municipality", id: "078" },
        { name: "Mozirje Municipality", id: "079" },
        { name: "Municipality of Apače", id: "195" },
        { name: "Municipality of Cirkulane", id: "196" },
        { name: "Municipality of Ilirska Bistrica", id: "038" },
        { name: "Municipality of Krško", id: "054" },
        { name: "Municipality of Škofljica", id: "123" },
        { name: "Murska Sobota City Municipality", id: "080" },
        { name: "Muta Municipality", id: "081" },
        { name: "Naklo Municipality", id: "082" },
        { name: "Nazarje Municipality", id: "083" },
        { name: "Nova Gorica City Municipality", id: "084" },
        { name: "Odranci Municipality", id: "086" },
        { name: "Oplotnica", id: "171" },
        { name: "Ormož Municipality", id: "087" },
        { name: "Osilnica Municipality", id: "088" },
        { name: "Pesnica Municipality", id: "089" },
        { name: "Piran Municipality", id: "090" },
        { name: "Pivka Municipality", id: "091" },
        { name: "Podčetrtek Municipality", id: "092" },
        { name: "Podlehnik Municipality", id: "172" },
        { name: "Podvelka Municipality", id: "093" },
        { name: "Poljčane Municipality", id: "200" },
        { name: "Polzela Municipality", id: "173" },
        { name: "Postojna Municipality", id: "094" },
        { name: "Prebold Municipality", id: "174" },
        { name: "Preddvor Municipality", id: "095" },
        { name: "Prevalje Municipality", id: "175" },
        { name: "Ptuj City Municipality", id: "096" },
        { name: "Puconci Municipality", id: "097" },
        { name: "Rače–Fram Municipality", id: "098" },
        { name: "Radeče Municipality", id: "099" },
        { name: "Radenci Municipality", id: "100" },
        { name: "Radlje ob Dravi Municipality", id: "101" },
        { name: "Radovljica Municipality", id: "102" },
        { name: "Ravne na Koroškem Municipality", id: "103" },
        { name: "Razkrižje Municipality", id: "176" },
        { name: "Rečica ob Savinji Municipality", id: "209" },
        { name: "Renče–Vogrsko Municipality", id: "201" },
        { name: "Ribnica Municipality", id: "104" },
        { name: "Ribnica na Pohorju Municipality", id: "177" },
        { name: "Rogaška Slatina Municipality", id: "106" },
        { name: "Rogašovci Municipality", id: "105" },
        { name: "Rogatec Municipality", id: "107" },
        { name: "Ruše Municipality", id: "108" },
        { name: "Šalovci Municipality", id: "033" },
        { name: "Selnica ob Dravi Municipality", id: "178" },
        { name: "Semič Municipality", id: "109" },
        { name: "Šempeter–Vrtojba Municipality", id: "183" },
        { name: "Šenčur Municipality", id: "117" },
        { name: "Šentilj Municipality", id: "118" },
        { name: "Šentjernej Municipality", id: "119" },
        { name: "Šentjur Municipality", id: "120" },
        { name: "Šentrupert Municipality", id: "211" },
        { name: "Sevnica Municipality", id: "110" },
        { name: "Sežana Municipality", id: "111" },
        { name: "Škocjan Municipality", id: "121" },
        { name: "Škofja Loka Municipality", id: "122" },
        { name: "Slovenj Gradec City Municipality", id: "112" },
        { name: "Slovenska Bistrica Municipality", id: "113" },
        { name: "Slovenske Konjice Municipality", id: "114" },
        { name: "Šmarje pri Jelšah Municipality", id: "124" },
        { name: "Šmarješke Toplice Municipality", id: "206" },
        { name: "Šmartno ob Paki Municipality", id: "125" },
        { name: "Šmartno pri Litiji Municipality", id: "194" },
        { name: "Sodražica Municipality", id: "179" },
        { name: "Solčava Municipality", id: "180" },
        { name: "Šoštanj Municipality", id: "126" },
        { name: "Središče ob Dravi", id: "202" },
        { name: "Starše Municipality", id: "115" },
        { name: "Štore Municipality", id: "127" },
        { name: "Straža Municipality", id: "203" },
        { name: "Sveta Ana Municipality", id: "181" },
        { name: "Sveta Trojica v Slovenskih Goricah Municipality", id: "204" },
        { name: "Sveti Andraž v Slovenskih Goricah Municipality", id: "182" },
        { name: "Sveti Jurij ob Ščavnici Municipality", id: "116" },
        { name: "Sveti Jurij v Slovenskih Goricah Municipality", id: "210" },
        { name: "Sveti Tomaž Municipality", id: "205" },
        { name: "Tabor Municipality", id: "184" },
        { name: "Tišina Municipality", id: "010" },
        { name: "Tolmin Municipality", id: "128" },
        { name: "Trbovlje Municipality", id: "129" },
        { name: "Trebnje Municipality", id: "130" },
        { name: "Trnovska Vas Municipality", id: "185" },
        { name: "Tržič Municipality", id: "131" },
        { name: "Trzin Municipality", id: "186" },
        { name: "Turnišče Municipality", id: "132" },
        { name: "Velika Polana Municipality", id: "187" },
        { name: "Velike Lašče Municipality", id: "134" },
        { name: "Veržej Municipality", id: "188" },
        { name: "Videm Municipality", id: "135" },
        { name: "Vipava Municipality", id: "136" },
        { name: "Vitanje Municipality", id: "137" },
        { name: "Vodice Municipality", id: "138" },
        { name: "Vojnik Municipality", id: "139" },
        { name: "Vransko Municipality", id: "189" },
        { name: "Vrhnika Municipality", id: "140" },
        { name: "Vuzenica Municipality", id: "141" },
        { name: "Zagorje ob Savi Municipality", id: "142" },
        { name: "Žalec Municipality", id: "190" },
        { name: "Zavrč Municipality", id: "143" },
        { name: "Železniki Municipality", id: "146" },
        { name: "Žetale Municipality", id: "191" },
        { name: "Žiri Municipality", id: "147" },
        { name: "Žirovnica Municipality", id: "192" },
        { name: "Zreče Municipality", id: "144" },
        { name: "Žužemberk Municipality", id: "193" }
    ],
    "sb": [
        { name: "Central Province", id: "CE" },
        { name: "Choiseul Province", id: "CH" },
        { name: "Guadalcanal Province", id: "GU" },
        { name: "Honiara", id: "CT" },
        { name: "Isabel Province", id: "IS" },
        { name: "Makira-Ulawa Province", id: "MK" },
        { name: "Malaita Province", id: "ML" },
        { name: "Rennell and Bellona Province", id: "RB" },
        { name: "Temotu Province", id: "TE" },
        { name: "Western Province", id: "WE" }
    ],
    "so": [
        { name: "Awdal Region", id: "AW" },
        { name: "Bakool", id: "BK" },
        { name: "Banaadir", id: "BN" },
        { name: "Bari", id: "BR" },
        { name: "Bay", id: "BY" },
        { name: "Galguduud", id: "GA" },
        { name: "Gedo", id: "GE" },
        { name: "Hiran", id: "HI" },
        { name: "Lower Juba", id: "JH" },
        { name: "Lower Shebelle", id: "SH" },
        { name: "Middle Juba", id: "JD" },
        { name: "Middle Shebelle", id: "SD" },
        { name: "Mudug", id: "MU" },
        { name: "Nugal", id: "NU" },
        { name: "Sanaag Region", id: "SA" },
        { name: "Togdheer Region", id: "TO" }
    ],
    "za": [
        { name: "Eastern Cape", id: "EC" },
        { name: "Free State", id: "FS" },
        { name: "Gauteng", id: "GP" },
        { name: "KwaZulu-Natal", id: "KZN" },
        { name: "Limpopo", id: "LP" },
        { name: "Mpumalanga", id: "MP" },
        { name: "North West", id: "NW" },
        { name: "Northern Cape", id: "NC" },
        { name: "Western Cape", id: "WC" }
    ],
    "kr": [
        { name: "Busan", id: "26" },
        { name: "Daegu", id: "27" },
        { name: "Daejeon", id: "30" },
        { name: "Gangwon Province", id: "42" },
        { name: "Gwangju", id: "29" },
        { name: "Gyeonggi Province", id: "41" },
        { name: "Incheon", id: "28" },
        { name: "Jeju", id: "49" },
        { name: "North Chungcheong Province", id: "43" },
        { name: "North Gyeongsang Province", id: "47" },
        { name: "North Jeolla Province", id: "45" },
        { name: "Sejong City", id: "50" },
        { name: "Seoul", id: "11" },
        { name: "South Chungcheong Province", id: "44" },
        { name: "South Gyeongsang Province", id: "48" },
        { name: "South Jeolla Province", id: "46" },
        { name: "Ulsan", id: "31" }
    ],
    "ss": [
        { name: "Central Equatoria", id: "EC" },
        { name: "Eastern Equatoria", id: "EE" },
        { name: "Jonglei State", id: "JG" },
        { name: "Lakes", id: "LK" },
        { name: "Northern Bahr el Ghazal", id: "BN" },
        { name: "Unity", id: "UY" },
        { name: "Upper Nile", id: "NU" },
        { name: "Warrap", id: "WR" },
        { name: "Western Bahr el Ghazal", id: "BW" },
        { name: "Western Equatoria", id: "EW" }
    ],
    "es": [
        { name: "Andalusia", id: "AN" },
        { name: "Aragon", id: "AR" },
        { name: "Asturias", id: "AS" },
        { name: "Ávila", id: "AV" },
        { name: "Balearic Islands", id: "PM" },
        { name: "Basque Country", id: "PV" },
        { name: "Burgos Province", id: "BU" },
        { name: "Canary Islands", id: "CN" },
        { name: "Cantabria", id: "CB" },
        { name: "Castile and León", id: "CL" },
        { name: "Castilla La Mancha", id: "CM" },
        { name: "Catalonia", id: "CT" },
        { name: "Ceuta", id: "CE" },
        { name: "Extremadura", id: "EX" },
        { name: "Galicia", id: "GA" },
        { name: "La Rioja", id: "RI" },
        { name: "Léon", id: "LE" },
        { name: "Madrid", id: "MD" },
        { name: "Melilla", id: "ML" },
        { name: "Murcia", id: "MC" },
        { name: "Navarra", id: "NC" },
        { name: "Palencia Province", id: "P" },
        { name: "Salamanca Province", id: "SA" },
        { name: "Segovia Province", id: "SG" },
        { name: "Soria Province", id: "SO" },
        { name: "Valencia", id: "VC" },
        { name: "Valladolid Province", id: "VA" },
        { name: "Zamora Province", id: "ZA" }
    ],
    "lk": [
        { name: "Ampara District", id: "52" },
        { name: "Anuradhapura District", id: "71" },
        { name: "Badulla District", id: "81" },
        { name: "Batticaloa District", id: "51" },
        { name: "Central Province", id: "2" },
        { name: "Colombo District", id: "11" },
        { name: "Eastern Province", id: "5" },
        { name: "Galle District", id: "31" },
        { name: "Gampaha District", id: "12" },
        { name: "Hambantota District", id: "33" },
        { name: "Jaffna District", id: "41" },
        { name: "Kalutara District", id: "13" },
        { name: "Kandy District", id: "21" },
        { name: "Kegalle District", id: "92" },
        { name: "Kilinochchi District", id: "42" },
        { name: "Mannar District", id: "43" },
        { name: "Matale District", id: "22" },
        { name: "Matara District", id: "32" },
        { name: "Monaragala District", id: "82" },
        { name: "Mullaitivu District", id: "45" },
        { name: "North Central Province", id: "7" },
        { name: "North Western Province", id: "6" },
        { name: "Northern Province", id: "4" },
        { name: "Nuwara Eliya District", id: "23" },
        { name: "Polonnaruwa District", id: "72" },
        { name: "Puttalam District", id: "62" },
        { name: "Ratnapura district", id: "91" },
        { name: "Sabaragamuwa Province", id: "9" },
        { name: "Southern Province", id: "3" },
        { name: "Trincomalee District", id: "53" },
        { name: "Uva Province", id: "8" },
        { name: "Vavuniya District", id: "44" },
        { name: "Western Province", id: "1" }
    ],
    "sd": [
        { name: "Al Jazirah", id: "GZ" },
        { name: "Al Qadarif", id: "GD" },
        { name: "Blue Nile", id: "NB" },
        { name: "Central Darfur", id: "DC" },
        { name: "East Darfur", id: "DE" },
        { name: "Kassala", id: "KA" },
        { name: "Khartoum", id: "KH" },
        { name: "North Darfur", id: "DN" },
        { name: "North Kordofan", id: "KN" },
        { name: "Northern", id: "NO" },
        { name: "Red Sea", id: "RS" },
        { name: "River Nile", id: "NR" },
        { name: "Sennar", id: "SI" },
        { name: "South Darfur", id: "DS" },
        { name: "South Kordofan", id: "KS" },
        { name: "West Darfur", id: "DW" },
        { name: "West Kordofan", id: "GK" },
        { name: "White Nile", id: "NW" }
    ],
    "sr": [
        { name: "Brokopondo District", id: "BR" },
        { name: "Commewijne District", id: "CM" },
        { name: "Coronie District", id: "CR" },
        { name: "Marowijne District", id: "MA" },
        { name: "Nickerie District", id: "NI" },
        { name: "Para District", id: "PR" },
        { name: "Paramaribo District", id: "PM" },
        { name: "Saramacca District", id: "SA" },
        { name: "Sipaliwini District", id: "SI" },
        { name: "Wanica District", id: "WA" }
    ],
    "sz": [
        { name: "Hhohho District", id: "HH" },
        { name: "Lubombo District", id: "LU" },
        { name: "Manzini District", id: "MA" },
        { name: "Shiselweni District", id: "SH" }
    ],
    "se": [
        { name: "Blekinge", id: "K" },
        { name: "Dalarna County", id: "W" },
        { name: "Gävleborg County", id: "X" },
        { name: "Gotland County", id: "I" },
        { name: "Halland County", id: "N" },
        { name: "Jönköping County", id: "F" },
        { name: "Kalmar County", id: "H" },
        { name: "Kronoberg County", id: "G" },
        { name: "Norrbotten County", id: "BD" },
        { name: "Örebro County", id: "T" },
        { name: "Östergötland County", id: "E" },
        { name: "Skåne County", id: "M" },
        { name: "Södermanland County", id: "D" },
        { name: "Stockholm County", id: "AB" },
        { name: "Uppsala County", id: "C" },
        { name: "Värmland County", id: "S" },
        { name: "Västerbotten County", id: "AC" },
        { name: "Västernorrland County", id: "Y" },
        { name: "Västmanland County", id: "U" },
        { name: "Västra Götaland County", id: "O" }
    ],
    "ch": [
        { name: "Aargau", id: "AG" },
        { name: "Appenzell Ausserrhoden", id: "AR" },
        { name: "Appenzell Innerrhoden", id: "AI" },
        { name: "Basel-Land", id: "BL" },
        { name: "Basel-Stadt", id: "BS" },
        { name: "Bern", id: "BE" },
        { name: "Fribourg", id: "FR" },
        { name: "Geneva", id: "GE" },
        { name: "Glarus", id: "GL" },
        { name: "Graubünden", id: "GR" },
        { name: "Jura", id: "JU" },
        { name: "Lucerne", id: "LU" },
        { name: "Neuchâtel", id: "NE" },
        { name: "Nidwalden", id: "NW" },
        { name: "Obwalden", id: "OW" },
        { name: "Schaffhausen", id: "SH" },
        { name: "Schwyz", id: "SZ" },
        { name: "Solothurn", id: "SO" },
        { name: "St. Gallen", id: "SG" },
        { name: "Thurgau", id: "TG" },
        { name: "Ticino", id: "TI" },
        { name: "Uri", id: "UR" },
        { name: "Valais", id: "VS" },
        { name: "Vaud", id: "VD" },
        { name: "Zug", id: "ZG" },
        { name: "Zürich", id: "ZH" }
    ],
    "sy": [
        { name: "Al-Hasakah Governorate", id: "HA" },
        { name: "Al-Raqqah Governorate", id: "RA" },
        { name: "Aleppo Governorate", id: "HL" },
        { name: "As-Suwayda Governorate", id: "SU" },
        { name: "Damascus Governorate", id: "DI" },
        { name: "Daraa Governorate", id: "DR" },
        { name: "Deir ez-Zor Governorate", id: "DY" },
        { name: "Hama Governorate", id: "HM" },
        { name: "Homs Governorate", id: "HI" },
        { name: "Idlib Governorate", id: "ID" },
        { name: "Latakia Governorate", id: "LA" },
        { name: "Quneitra Governorate", id: "QU" },
        { name: "Rif Dimashq Governorate", id: "RD" },
        { name: "Tartus Governorate", id: "TA" }
    ],
    "tw": [
        { name: "Changhua", id: "CHA" },
        { name: "Chiayi", id: "CYI" },
        { name: "Chiayi", id: "CYQ" },
        { name: "Hsinchu", id: "HSQ" },
        { name: "Hsinchu", id: "HSZ" },
        { name: "Hualien", id: "HUA" },
        { name: "Kaohsiung", id: "KHH" },
        { name: "Keelung", id: "KEE" },
        { name: "Kinmen", id: "KIN" },
        { name: "Lienchiang", id: "LIE" },
        { name: "Miaoli", id: "MIA" },
        { name: "Nantou", id: "NAN" },
        { name: "New Taipei", id: "NWT" },
        { name: "Penghu", id: "PEN" },
        { name: "Pingtung", id: "PIF" },
        { name: "Taichung", id: "TXG" },
        { name: "Tainan", id: "TNN" },
        { name: "Taipei", id: "TPE" },
        { name: "Taitung", id: "TTT" },
        { name: "Taoyuan", id: "TAO" },
        { name: "Yilan", id: "ILA" },
        { name: "Yunlin", id: "YUN" }
    ],
    "tj": [
        { name: "districts of Republican Subordination", id: "RA" },
        { name: "Gorno-Badakhshan Autonomous Province", id: "GB" },
        { name: "Khatlon Province", id: "KT" },
        { name: "Sughd Province", id: "SU" }
    ],
    "tz": [
        { name: "Arusha", id: "01" },
        { name: "Dar es Salaam", id: "02" },
        { name: "Dodoma", id: "03" },
        { name: "Geita", id: "27" },
        { name: "Iringa", id: "04" },
        { name: "Kagera", id: "05" },
        { name: "Katavi", id: "28" },
        { name: "Kigoma", id: "08" },
        { name: "Kilimanjaro", id: "09" },
        { name: "Lindi", id: "12" },
        { name: "Manyara", id: "26" },
        { name: "Mara", id: "13" },
        { name: "Mbeya", id: "14" },
        { name: "Morogoro", id: "16" },
        { name: "Mtwara", id: "17" },
        { name: "Mwanza", id: "18" },
        { name: "Njombe", id: "29" },
        { name: "Pemba North", id: "06" },
        { name: "Pemba South", id: "10" },
        { name: "Pwani", id: "19" },
        { name: "Rukwa", id: "20" },
        { name: "Ruvuma", id: "21" },
        { name: "Shinyanga", id: "22" },
        { name: "Simiyu", id: "30" },
        { name: "Singida", id: "23" },
        { name: "Songwe", id: "31" },
        { name: "Tabora", id: "24" },
        { name: "Tanga", id: "25" },
        { name: "Zanzibar North", id: "07" },
        { name: "Zanzibar South", id: "11" },
        { name: "Zanzibar West", id: "15" }
    ],
    "th": [
        { name: "Amnat Charoen", id: "37" },
        { name: "Ang Thong", id: "15" },
        { name: "Bangkok", id: "10" },
        { name: "Bueng Kan", id: "38" },
        { name: "Buri Ram", id: "31" },
        { name: "Chachoengsao", id: "24" },
        { name: "Chai Nat", id: "18" },
        { name: "Chaiyaphum", id: "36" },
        { name: "Chanthaburi", id: "22" },
        { name: "Chiang Mai", id: "50" },
        { name: "Chiang Rai", id: "57" },
        { name: "Chon Buri", id: "20" },
        { name: "Chumphon", id: "86" },
        { name: "Kalasin", id: "46" },
        { name: "Kamphaeng Phet", id: "62" },
        { name: "Kanchanaburi", id: "71" },
        { name: "Khon Kaen", id: "40" },
        { name: "Krabi", id: "81" },
        { name: "Lampang", id: "52" },
        { name: "Lamphun", id: "51" },
        { name: "Loei", id: "42" },
        { name: "Lop Buri", id: "16" },
        { name: "Mae Hong Son", id: "58" },
        { name: "Maha Sarakham", id: "44" },
        { name: "Mukdahan", id: "49" },
        { name: "Nakhon Nayok", id: "26" },
        { name: "Nakhon Pathom", id: "73" },
        { name: "Nakhon Phanom", id: "48" },
        { name: "Nakhon Ratchasima", id: "30" },
        { name: "Nakhon Sawan", id: "60" },
        { name: "Nakhon Si Thammarat", id: "80" },
        { name: "Nan", id: "55" },
        { name: "Narathiwat", id: "96" },
        { name: "Nong Bua Lam Phu", id: "39" },
        { name: "Nong Khai", id: "43" },
        { name: "Nonthaburi", id: "12" },
        { name: "Pathum Thani", id: "13" },
        { name: "Pattani", id: "94" },
        { name: "Pattaya", id: "S" },
        { name: "Phangnga", id: "82" },
        { name: "Phatthalung", id: "93" },
        { name: "Phayao", id: "56" },
        { name: "Phetchabun", id: "67" },
        { name: "Phetchaburi", id: "76" },
        { name: "Phichit", id: "66" },
        { name: "Phitsanulok", id: "65" },
        { name: "Phra Nakhon Si Ayutthaya", id: "14" },
        { name: "Phrae", id: "54" },
        { name: "Phuket", id: "83" },
        { name: "Prachin Buri", id: "25" },
        { name: "Prachuap Khiri Khan", id: "77" },
        { name: "Ranong", id: "85" },
        { name: "Ratchaburi", id: "70" },
        { name: "Rayong", id: "21" },
        { name: "Roi Et", id: "45" },
        { name: "Sa Kaeo", id: "27" },
        { name: "Sakon Nakhon", id: "47" },
        { name: "Samut Prakan", id: "11" },
        { name: "Samut Sakhon", id: "74" },
        { name: "Samut Songkhram", id: "75" },
        { name: "Saraburi", id: "19" },
        { name: "Satun", id: "91" },
        { name: "Si Sa Ket", id: "33" },
        { name: "Sing Buri", id: "17" },
        { name: "Songkhla", id: "90" },
        { name: "Sukhothai", id: "64" },
        { name: "Suphan Buri", id: "72" },
        { name: "Surat Thani", id: "84" },
        { name: "Surin", id: "32" },
        { name: "Tak", id: "63" },
        { name: "Trang", id: "92" },
        { name: "Trat", id: "23" },
        { name: "Ubon Ratchathani", id: "34" },
        { name: "Udon Thani", id: "41" },
        { name: "Uthai Thani", id: "61" },
        { name: "Uttaradit", id: "53" },
        { name: "Yala", id: "95" },
        { name: "Yasothon", id: "35" }
    ],
    "tg": [
        { name: "Centrale Region", id: "C" },
        { name: "Kara Region", id: "K" },
        { name: "Maritime", id: "M" },
        { name: "Plateaux Region", id: "P" },
        { name: "Savanes Region", id: "S" }
    ],
    "to": [
        { name: "Haʻapai", id: "02" },
        { name: "ʻEua", id: "01" },
        { name: "Niuas", id: "03" },
        { name: "Tongatapu", id: "04" },
        { name: "Vavaʻu", id: "05" }
    ],
    "tt": [
        { name: "Arima", id: "ARI" },
        { name: "Chaguanas", id: "CHA" },
        { name: "Couva-Tabaquite-Talparo Regional Corporation", id: "CTT" },
        { name: "Diego Martin Regional Corporation", id: "DMN" },
        { name: "Eastern Tobago", id: "ETO" },
        { name: "Penal-Debe Regional Corporation", id: "PED" },
        { name: "Point Fortin", id: "PTF" },
        { name: "Port of Spain", id: "POS" },
        { name: "Princes Town Regional Corporation", id: "PRT" },
        { name: "Rio Claro-Mayaro Regional Corporation", id: "MRC" },
        { name: "San Fernando", id: "SFO" },
        { name: "San Juan-Laventille Regional Corporation", id: "SJL" },
        { name: "Sangre Grande Regional Corporation", id: "SGE" },
        { name: "Siparia Regional Corporation", id: "SIP" },
        { name: "Tunapuna-Piarco Regional Corporation", id: "TUP" },
        { name: "Western Tobago", id: "WTO" }
    ],
    "tn": [
        { name: "Ariana Governorate", id: "12" },
        { name: "Ben Arous Governorate", id: "13" },
        { name: "Bizerte Governorate", id: "23" },
        { name: "Gabès Governorate", id: "81" },
        { name: "Gafsa Governorate", id: "71" },
        { name: "Jendouba Governorate", id: "32" },
        { name: "Kairouan Governorate", id: "41" },
        { name: "Kasserine Governorate", id: "42" },
        { name: "Kassrine", id: "31" },
        { name: "Kebili Governorate", id: "73" },
        { name: "Kef Governorate", id: "33" },
        { name: "Mahdia Governorate", id: "53" },
        { name: "Manouba Governorate", id: "14" },
        { name: "Medenine Governorate", id: "82" },
        { name: "Monastir Governorate", id: "52" },
        { name: "Sfax Governorate", id: "61" },
        { name: "Sidi Bouzid Governorate", id: "43" },
        { name: "Siliana Governorate", id: "34" },
        { name: "Sousse Governorate", id: "51" },
        { name: "Tataouine Governorate", id: "83" },
        { name: "Tozeur Governorate", id: "72" },
        { name: "Tunis Governorate", id: "11" },
        { name: "Zaghouan Governorate", id: "22" }
    ],
    "tr": [
        { name: "Adana", id: "01" },
        { name: "Adıyaman", id: "02" },
        { name: "Afyonkarahisar", id: "03" },
        { name: "Ağrı", id: "04" },
        { name: "Aksaray", id: "68" },
        { name: "Amasya", id: "05" },
        { name: "Ankara", id: "06" },
        { name: "Antalya", id: "07" },
        { name: "Ardahan", id: "75" },
        { name: "Artvin", id: "08" },
        { name: "Aydın", id: "09" },
        { name: "Balıkesir", id: "10" },
        { name: "Bartın", id: "74" },
        { name: "Batman", id: "72" },
        { name: "Bayburt", id: "69" },
        { name: "Bilecik", id: "11" },
        { name: "Bingöl", id: "12" },
        { name: "Bitlis", id: "13" },
        { name: "Bolu", id: "14" },
        { name: "Burdur", id: "15" },
        { name: "Bursa", id: "16" },
        { name: "Çanakkale", id: "17" },
        { name: "Çankırı", id: "18" },
        { name: "Çorum", id: "19" },
        { name: "Denizli", id: "20" },
        { name: "Diyarbakır", id: "21" },
        { name: "Düzce", id: "81" },
        { name: "Edirne", id: "22" },
        { name: "Elazığ", id: "23" },
        { name: "Erzincan", id: "24" },
        { name: "Erzurum", id: "25" },
        { name: "Eskişehir", id: "26" },
        { name: "Gaziantep", id: "27" },
        { name: "Giresun", id: "28" },
        { name: "Gümüşhane", id: "29" },
        { name: "Hakkâri", id: "30" },
        { name: "Hatay", id: "31" },
        { name: "Iğdır", id: "76" },
        { name: "Isparta", id: "32" },
        { name: "Istanbul", id: "34" },
        { name: "İzmir", id: "35" },
        { name: "Kahramanmaraş", id: "46" },
        { name: "Karabük", id: "78" },
        { name: "Karaman", id: "70" },
        { name: "Kars", id: "36" },
        { name: "Kastamonu", id: "37" },
        { name: "Kayseri", id: "38" },
        { name: "Kilis", id: "79" },
        { name: "Kırıkkale", id: "71" },
        { name: "Kırklareli", id: "39" },
        { name: "Kırşehir", id: "40" },
        { name: "Kocaeli", id: "41" },
        { name: "Konya", id: "42" },
        { name: "Kütahya", id: "43" },
        { name: "Malatya", id: "44" },
        { name: "Manisa", id: "45" },
        { name: "Mardin", id: "47" },
        { name: "Mersin", id: "33" },
        { name: "Muğla", id: "48" },
        { name: "Muş", id: "49" },
        { name: "Nevşehir", id: "50" },
        { name: "Niğde", id: "51" },
        { name: "Ordu", id: "52" },
        { name: "Osmaniye", id: "80" },
        { name: "Rize", id: "53" },
        { name: "Sakarya", id: "54" },
        { name: "Samsun", id: "55" },
        { name: "Şanlıurfa", id: "63" },
        { name: "Siirt", id: "56" },
        { name: "Sinop", id: "57" },
        { name: "Sivas", id: "58" },
        { name: "Şırnak", id: "73" },
        { name: "Tekirdağ", id: "59" },
        { name: "Tokat", id: "60" },
        { name: "Trabzon", id: "61" },
        { name: "Tunceli", id: "62" },
        { name: "Uşak", id: "64" },
        { name: "Van", id: "65" },
        { name: "Yalova", id: "77" },
        { name: "Yozgat", id: "66" },
        { name: "Zonguldak", id: "67" }
    ],
    "tm": [
        { name: "Ahal Region", id: "A" },
        { name: "Ashgabat", id: "S" },
        { name: "Balkan Region", id: "B" },
        { name: "Daşoguz Region", id: "D" },
        { name: "Lebap Region", id: "L" },
        { name: "Mary Region", id: "M" }
    ],
    "tv": [
        { name: "Funafuti", id: "FUN" },
        { name: "Nanumanga", id: "NMG" },
        { name: "Nanumea", id: "NMA" },
        { name: "Niutao Island Council", id: "NIT" },
        { name: "Nui", id: "NUI" },
        { name: "Nukufetau", id: "NKF" },
        { name: "Nukulaelae", id: "NKL" },
        { name: "Vaitupu", id: "VAI" }
    ],
    "ug": [
        { name: "Abim District", id: "314" },
        { name: "Adjumani District", id: "301" },
        { name: "Agago District", id: "322" },
        { name: "Alebtong District", id: "323" },
        { name: "Amolatar District", id: "315" },
        { name: "Amudat District", id: "324" },
        { name: "Amuria District", id: "216" },
        { name: "Amuru District", id: "316" },
        { name: "Apac District", id: "302" },
        { name: "Arua District", id: "303" },
        { name: "Budaka District", id: "217" },
        { name: "Bududa District", id: "218" },
        { name: "Bugiri District", id: "201" },
        { name: "Buhweju District", id: "420" },
        { name: "Buikwe District", id: "117" },
        { name: "Bukedea District", id: "219" },
        { name: "Bukomansimbi District", id: "118" },
        { name: "Bukwo District", id: "220" },
        { name: "Bulambuli District", id: "225" },
        { name: "Buliisa District", id: "416" },
        { name: "Bundibugyo District", id: "401" },
        { name: "Bunyangabu District", id: "430" },
        { name: "Bushenyi District", id: "402" },
        { name: "Busia District", id: "202" },
        { name: "Butaleja District", id: "221" },
        { name: "Butambala District", id: "119" },
        { name: "Butebo District", id: "233" },
        { name: "Buvuma District", id: "120" },
        { name: "Buyende District", id: "226" },
        { name: "Central Region", id: "C" },
        { name: "Dokolo District", id: "317" },
        { name: "Eastern Region", id: "E" },
        { name: "Gomba District", id: "121" },
        { name: "Gulu District", id: "304" },
        { name: "Ibanda District", id: "417" },
        { name: "Iganga District", id: "203" },
        { name: "Isingiro District", id: "418" },
        { name: "Jinja District", id: "204" },
        { name: "Kaabong District", id: "318" },
        { name: "Kabale District", id: "404" },
        { name: "Kabarole District", id: "405" },
        { name: "Kaberamaido District", id: "213" },
        { name: "Kagadi District", id: "427" },
        { name: "Kakumiro District", id: "428" },
        { name: "Kalangala District", id: "101" },
        { name: "Kaliro District", id: "222" },
        { name: "Kalungu District", id: "122" },
        { name: "Kampala District", id: "102" },
        { name: "Kamuli District", id: "205" },
        { name: "Kamwenge District", id: "413" },
        { name: "Kanungu District", id: "414" },
        { name: "Kapchorwa District", id: "206" },
        { name: "Kasese District", id: "406" },
        { name: "Katakwi District", id: "207" },
        { name: "Kayunga District", id: "112" },
        { name: "Kibaale District", id: "407" },
        { name: "Kiboga District", id: "103" },
        { name: "Kibuku District", id: "227" },
        { name: "Kiruhura District", id: "419" },
        { name: "Kiryandongo District", id: "421" },
        { name: "Kisoro District", id: "408" },
        { name: "Kitgum District", id: "305" },
        { name: "Koboko District", id: "319" },
        { name: "Kole District", id: "325" },
        { name: "Kotido District", id: "306" },
        { name: "Kumi District", id: "208" },
        { name: "Kween District", id: "228" },
        { name: "Kyankwanzi District", id: "123" },
        { name: "Kyegegwa District", id: "422" },
        { name: "Kyenjojo District", id: "415" },
        { name: "Kyotera District", id: "125" },
        { name: "Lamwo District", id: "326" },
        { name: "Lira District", id: "307" },
        { name: "Luuka District", id: "229" },
        { name: "Luwero District", id: "104" },
        { name: "Lwengo District", id: "124" },
        { name: "Lyantonde District", id: "114" },
        { name: "Manafwa District", id: "223" },
        { name: "Maracha District", id: "320" },
        { name: "Masaka District", id: "105" },
        { name: "Masindi District", id: "409" },
        { name: "Mayuge District", id: "214" },
        { name: "Mbale District", id: "209" },
        { name: "Mbarara District", id: "410" },
        { name: "Mitooma District", id: "423" },
        { name: "Mityana District", id: "115" },
        { name: "Moroto District", id: "308" },
        { name: "Moyo District", id: "309" },
        { name: "Mpigi District", id: "106" },
        { name: "Mubende District", id: "107" },
        { name: "Mukono District", id: "108" },
        { name: "Nakapiripirit District", id: "311" },
        { name: "Nakaseke District", id: "116" },
        { name: "Nakasongola District", id: "109" },
        { name: "Namayingo District", id: "230" },
        { name: "Namisindwa District", id: "234" },
        { name: "Namutumba District", id: "224" },
        { name: "Napak District", id: "327" },
        { name: "Nebbi District", id: "310" },
        { name: "Ngora District", id: "231" },
        { name: "Northern Region", id: "N" },
        { name: "Ntoroko District", id: "424" },
        { name: "Ntungamo District", id: "411" },
        { name: "Nwoya District", id: "328" },
        { name: "Omoro District", id: "331" },
        { name: "Otuke District", id: "329" },
        { name: "Oyam District", id: "321" },
        { name: "Pader District", id: "312" },
        { name: "Pakwach District", id: "332" },
        { name: "Pallisa District", id: "210" },
        { name: "Rakai District", id: "110" },
        { name: "Rubanda District", id: "429" },
        { name: "Rubirizi District", id: "425" },
        { name: "Rukiga District", id: "431" },
        { name: "Rukungiri District", id: "412" },
        { name: "Sembabule District", id: "111" },
        { name: "Serere District", id: "232" },
        { name: "Sheema District", id: "426" },
        { name: "Sironko District", id: "215" },
        { name: "Soroti District", id: "211" },
        { name: "Tororo District", id: "212" },
        { name: "Wakiso District", id: "113" },
        { name: "Western Region", id: "W" },
        { name: "Yumbe District", id: "313" },
        { name: "Zombo District", id: "330" }
    ],
    "ua": [
        { name: "Autonomous Republic of Crimea", id: "43" },
        { name: "Cherkasy Oblast", id: "71" },
        { name: "Chernihiv Oblast", id: "74" },
        { name: "Chernivtsi Oblast", id: "77" },
        { name: "Dnipropetrovsk Oblast", id: "12" },
        { name: "Donetsk Oblast", id: "14" },
        { name: "Ivano-Frankivsk Oblast", id: "26" },
        { name: "Kharkiv Oblast", id: "63" },
        { name: "Kherson Oblast", id: "65" },
        { name: "Khmelnytsky Oblast", id: "68" },
        { name: "Kiev", id: "30" },
        { name: "Kirovohrad Oblast", id: "35" },
        { name: "Kyiv Oblast", id: "32" },
        { name: "Luhansk Oblast", id: "09" },
        { name: "Lviv Oblast", id: "46" },
        { name: "Mykolaiv Oblast", id: "48" },
        { name: "Odessa Oblast", id: "51" },
        { name: "Rivne Oblast", id: "56" },
        { name: "Sumy Oblast", id: "59" },
        { name: "Ternopil Oblast", id: "61" },
        { name: "Vinnytsia Oblast", id: "05" },
        { name: "Volyn Oblast", id: "07" },
        { name: "Zakarpattia Oblast", id: "21" },
        { name: "Zaporizhzhya Oblast", id: "23" },
        { name: "Zhytomyr Oblast", id: "18" }
    ],
    "ae": [
        { name: "Abu Dhabi Emirate", id: "AZ" },
        { name: "Ajman Emirate", id: "AJ" },
        { name: "Dubai", id: "DU" },
        { name: "Fujairah", id: "FU" },
        { name: "Ras al-Khaimah", id: "RK" },
        { name: "Sharjah Emirate", id: "SH" },
        { name: "Umm al-Quwain", id: "UQ" }
    ],
    "gb": [
        { name: "Aberdeen", id: "ABE" },
        { name: "Aberdeenshire", id: "ABD" },
        { name: "Angus", id: "ANS" },
        { name: "Antrim", id: "ANT" },
        { name: "Antrim and Newtownabbey", id: "ANN" },
        { name: "Ards", id: "ARD" },
        { name: "Ards and North Down", id: "AND" },
        { name: "Argyll and Bute", id: "AGB" },
        { name: "Armagh City and District Council", id: "ARM" },
        { name: "Armagh, Banbridge and Craigavon", id: "ABC" },
        { name: "Ascension Island", id: "SH-AC" },
        { name: "Ballymena Borough", id: "BLA" },
        { name: "Ballymoney", id: "BLY" },
        { name: "Banbridge", id: "BNB" },
        { name: "Barnsley", id: "BNS" },
        { name: "Bath and North East Somerset", id: "BAS" },
        { name: "Bedford", id: "BDF" },
        { name: "Belfast district", id: "BFS" },
        { name: "Birmingham", id: "BIR" },
        { name: "Blackburn with Darwen", id: "BBD" },
        { name: "Blackpool", id: "BPL" },
        { name: "Blaenau Gwent County Borough", id: "BGW" },
        { name: "Bolton", id: "BOL" },
        { name: "Bournemouth", id: "BMH" },
        { name: "Bracknell Forest", id: "BRC" },
        { name: "Bradford", id: "BRD" },
        { name: "Bridgend County Borough", id: "BGE" },
        { name: "Brighton and Hove", id: "BNH" },
        { name: "Buckinghamshire", id: "BKM" },
        { name: "Bury", id: "BUR" },
        { name: "Caerphilly County Borough", id: "CAY" },
        { name: "Calderdale", id: "CLD" },
        { name: "Cambridgeshire", id: "CAM" },
        { name: "Carmarthenshire", id: "CMN" },
        { name: "Carrickfergus Borough Council", id: "CKF" },
        { name: "Castlereagh", id: "CSR" },
        { name: "Causeway Coast and Glens", id: "CCG" },
        { name: "Central Bedfordshire", id: "CBF" },
        { name: "Ceredigion", id: "CGN" },
        { name: "Cheshire East", id: "CHE" },
        { name: "Cheshire West and Chester", id: "CHW" },
        { name: "City and County of Cardiff", id: "CRF" },
        { name: "City and County of Swansea", id: "SWA" },
        { name: "City of Bristol", id: "BST" },
        { name: "City of Derby", id: "DER" },
        { name: "City of Kingston upon Hull", id: "KHL" },
        { name: "City of Leicester", id: "LCE" },
        { name: "City of London", id: "LND" },
        { name: "City of Nottingham", id: "NGM" },
        { name: "City of Peterborough", id: "PTE" },
        { name: "City of Plymouth", id: "PLY" },
        { name: "City of Portsmouth", id: "POR" },
        { name: "City of Southampton", id: "STH" },
        { name: "City of Stoke-on-Trent", id: "STE" },
        { name: "City of Sunderland", id: "SND" },
        { name: "City of Westminster", id: "WSM" },
        { name: "City of Wolverhampton", id: "WLV" },
        { name: "City of York", id: "YOR" },
        { name: "Clackmannanshire", id: "CLK" },
        { name: "Coleraine Borough Council", id: "CLR" },
        { name: "Conwy County Borough", id: "CWY" },
        { name: "Cookstown District Council", id: "CKT" },
        { name: "Cornwall", id: "CON" },
        { name: "County Durham", id: "DUR" },
        { name: "Coventry", id: "COV" },
        { name: "Craigavon Borough Council", id: "CGV" },
        { name: "Cumbria", id: "CMA" },
        { name: "Darlington", id: "DAL" },
        { name: "Denbighshire", id: "DEN" },
        { name: "Derbyshire", id: "DBY" },
        { name: "Derry City and Strabane", id: "DRS" },
        { name: "Derry City Council", id: "DRY" },
        { name: "Devon", id: "DEV" },
        { name: "Doncaster", id: "DNC" },
        { name: "Dorset", id: "DOR" },
        { name: "Down District Council", id: "DOW" },
        { name: "Dudley", id: "DUD" },
        { name: "Dumfries and Galloway", id: "DGY" },
        { name: "Dundee", id: "DND" },
        { name: "Dungannon and South Tyrone Borough Council", id: "DGN" },
        { name: "East Ayrshire", id: "EAY" },
        { name: "East Dunbartonshire", id: "EDU" },
        { name: "East Lothian", id: "ELN" },
        { name: "East Renfrewshire", id: "ERW" },
        { name: "East Riding of Yorkshire", id: "ERY" },
        { name: "East Sussex", id: "ESX" },
        { name: "Edinburgh", id: "EDH" },
        { name: "England", id: "ENG" },
        { name: "Essex", id: "ESS" },
        { name: "Falkirk", id: "FAL" },
        { name: "Fermanagh and Omagh", id: "FMO" },
        { name: "Fermanagh District Council", id: "FER" },
        { name: "Fife", id: "FIF" },
        { name: "Flintshire", id: "FLN" },
        { name: "Gateshead", id: "GAT" },
        { name: "Glasgow", id: "GLG" },
        { name: "Gloucestershire", id: "GLS" },
        { name: "Gwynedd", id: "GWN" },
        { name: "Halton", id: "HAL" },
        { name: "Hampshire", id: "HAM" },
        { name: "Hartlepool", id: "HPL" },
        { name: "Herefordshire", id: "HEF" },
        { name: "Hertfordshire", id: "HRT" },
        { name: "Highland", id: "HLD" },
        { name: "Inverclyde", id: "IVC" },
        { name: "Isle of Wight", id: "IOW" },
        { name: "Isles of Scilly", id: "IOS" },
        { name: "Kent", id: "KEN" },
        { name: "Kirklees", id: "KIR" },
        { name: "Knowsley", id: "KWL" },
        { name: "Lancashire", id: "LAN" },
        { name: "Larne Borough Council", id: "LRN" },
        { name: "Leeds", id: "LDS" },
        { name: "Leicestershire", id: "LEC" },
        { name: "Limavady Borough Council", id: "LMV" },
        { name: "Lincolnshire", id: "LIN" },
        { name: "Lisburn and Castlereagh", id: "LBC" },
        { name: "Lisburn City Council", id: "LSB" },
        { name: "Liverpool", id: "LIV" },
        { name: "London Borough of Barking and Dagenham", id: "BDG" },
        { name: "London Borough of Barnet", id: "BNE" },
        { name: "London Borough of Bexley", id: "BEX" },
        { name: "London Borough of Brent", id: "BEN" },
        { name: "London Borough of Bromley", id: "BRY" },
        { name: "London Borough of Camden", id: "CMD" },
        { name: "London Borough of Croydon", id: "CRY" },
        { name: "London Borough of Ealing", id: "EAL" },
        { name: "London Borough of Enfield", id: "ENF" },
        { name: "London Borough of Hackney", id: "HCK" },
        { name: "London Borough of Hammersmith and Fulham", id: "HMF" },
        { name: "London Borough of Haringey", id: "HRY" },
        { name: "London Borough of Harrow", id: "HRW" },
        { name: "London Borough of Havering", id: "HAV" },
        { name: "London Borough of Hillingdon", id: "HIL" },
        { name: "London Borough of Hounslow", id: "HNS" },
        { name: "London Borough of Islington", id: "ISL" },
        { name: "London Borough of Lambeth", id: "LBH" },
        { name: "London Borough of Lewisham", id: "LEW" },
        { name: "London Borough of Merton", id: "MRT" },
        { name: "London Borough of Newham", id: "NWM" },
        { name: "London Borough of Redbridge", id: "RDB" },
        { name: "London Borough of Richmond upon Thames", id: "RIC" },
        { name: "London Borough of Southwark", id: "SWK" },
        { name: "London Borough of Sutton", id: "STN" },
        { name: "London Borough of Tower Hamlets", id: "TWH" },
        { name: "London Borough of Waltham Forest", id: "WFT" },
        { name: "London Borough of Wandsworth", id: "WND" },
        { name: "Magherafelt District Council", id: "MFT" },
        { name: "Manchester", id: "MAN" },
        { name: "Medway", id: "MDW" },
        { name: "Merthyr Tydfil County Borough", id: "MTY" },
        { name: "Metropolitan Borough of Wigan", id: "WGN" },
        { name: "Mid and East Antrim", id: "MEA" },
        { name: "Mid Ulster", id: "MUL" },
        { name: "Middlesbrough", id: "MDB" },
        { name: "Midlothian", id: "MLN" },
        { name: "Milton Keynes", id: "MIK" },
        { name: "Monmouthshire", id: "MON" },
        { name: "Moray", id: "MRY" },
        { name: "Moyle District Council", id: "MYL" },
        { name: "Neath Port Talbot County Borough", id: "NTL" },
        { name: "Newcastle upon Tyne", id: "NET" },
        { name: "Newport", id: "NWP" },
        { name: "Newry and Mourne District Council", id: "NYM" },
        { name: "Newry, Mourne and Down", id: "NMD" },
        { name: "Newtownabbey Borough Council", id: "NTA" },
        { name: "Norfolk", id: "NFK" },
        { name: "North Ayrshire", id: "NAY" },
        { name: "North Down Borough Council", id: "NDN" },
        { name: "North East Lincolnshire", id: "NEL" },
        { name: "North Lanarkshire", id: "NLK" },
        { name: "North Lincolnshire", id: "NLN" },
        { name: "North Somerset", id: "NSM" },
        { name: "North Tyneside", id: "NTY" },
        { name: "North Yorkshire", id: "NYK" },
        { name: "Northamptonshire", id: "NTH" },
        { name: "Northern Ireland", id: "NIR" },
        { name: "Northumberland", id: "NBL" },
        { name: "Nottinghamshire", id: "NTT" },
        { name: "Oldham", id: "OLD" },
        { name: "Omagh District Council", id: "OMH" },
        { name: "Orkney Islands", id: "ORK" },
        { name: "Outer Hebrides", id: "ELS" },
        { name: "Oxfordshire", id: "OXF" },
        { name: "Pembrokeshire", id: "PEM" },
        { name: "Perth and Kinross", id: "PKN" },
        { name: "Poole", id: "POL" },
        { name: "Powys", id: "POW" },
        { name: "Reading", id: "RDG" },
        { name: "Redcar and Cleveland", id: "RCC" },
        { name: "Renfrewshire", id: "RFW" },
        { name: "Rhondda Cynon Taf", id: "RCT" },
        { name: "Rochdale", id: "RCH" },
        { name: "Rotherham", id: "ROT" },
        { name: "Royal Borough of Greenwich", id: "GRE" },
        { name: "Royal Borough of Kensington and Chelsea", id: "KEC" },
        { name: "Royal Borough of Kingston upon Thames", id: "KTT" },
        { name: "Rutland", id: "RUT" },
        { name: "Saint Helena", id: "SH-HL" },
        { name: "Salford", id: "SLF" },
        { name: "Sandwell", id: "SAW" },
        { name: "Scotland", id: "SCT" },
        { name: "Scottish Borders", id: "SCB" },
        { name: "Sefton", id: "SFT" },
        { name: "Sheffield", id: "SHF" },
        { name: "Shetland Islands", id: "ZET" },
        { name: "Shropshire", id: "SHR" },
        { name: "Slough", id: "SLG" },
        { name: "Solihull", id: "SOL" },
        { name: "Somerset", id: "SOM" },
        { name: "South Ayrshire", id: "SAY" },
        { name: "South Gloucestershire", id: "SGC" },
        { name: "South Lanarkshire", id: "SLK" },
        { name: "South Tyneside", id: "STY" },
        { name: "Southend-on-Sea", id: "SOS" },
        { name: "St Helens", id: "SHN" },
        { name: "Staffordshire", id: "STS" },
        { name: "Stirling", id: "STG" },
        { name: "Stockport", id: "SKP" },
        { name: "Stockton-on-Tees", id: "STT" },
        { name: "Strabane District Council", id: "STB" },
        { name: "Suffolk", id: "SFK" },
        { name: "Surrey", id: "SRY" },
        { name: "Swindon", id: "SWD" },
        { name: "Tameside", id: "TAM" },
        { name: "Telford and Wrekin", id: "TFW" },
        { name: "Thurrock", id: "THR" },
        { name: "Torbay", id: "TOB" },
        { name: "Torfaen", id: "TOF" },
        { name: "Trafford", id: "TRF" },
        { name: "United Kingdom", id: "UKM" },
        { name: "Vale of Glamorgan", id: "VGL" },
        { name: "Wakefield", id: "WKF" },
        { name: "Wales", id: "WLS" },
        { name: "Walsall", id: "WLL" },
        { name: "Warrington", id: "WRT" },
        { name: "Warwickshire", id: "WAR" },
        { name: "West Berkshire", id: "WBK" },
        { name: "West Dunbartonshire", id: "WDU" },
        { name: "West Lothian", id: "WLN" },
        { name: "West Sussex", id: "WSX" },
        { name: "Wiltshire", id: "WIL" },
        { name: "Windsor and Maidenhead", id: "WNM" },
        { name: "Wirral", id: "WRL" },
        { name: "Wokingham", id: "WOK" },
        { name: "Worcestershire", id: "WOR" },
        { name: "Wrexham County Borough", id: "WRX" }
    ],
    "us": [
        { name: "Alabama", id: "AL" },
        { name: "Alaska", id: "AK" },
        { name: "American Samoa", id: "AS" },
        { name: "Arizona", id: "AZ" },
        { name: "Arkansas", id: "AR" },
        { name: "Baker Island", id: "UM-81" },
        { name: "California", id: "CA" },
        { name: "Colorado", id: "CO" },
        { name: "Connecticut", id: "CT" },
        { name: "Delaware", id: "DE" },
        { name: "District of Columbia", id: "DC" },
        { name: "Florida", id: "FL" },
        { name: "Georgia", id: "GA" },
        { name: "Guam", id: "GU" },
        { name: "Hawaii", id: "HI" },
        { name: "Howland Island", id: "UM-84" },
        { name: "Idaho", id: "ID" },
        { name: "Illinois", id: "IL" },
        { name: "Indiana", id: "IN" },
        { name: "Iowa", id: "IA" },
        { name: "Jarvis Island", id: "UM-86" },
        { name: "Johnston Atoll", id: "UM-67" },
        { name: "Kansas", id: "KS" },
        { name: "Kentucky", id: "KY" },
        { name: "Kingman Reef", id: "UM-89" },
        { name: "Louisiana", id: "LA" },
        { name: "Maine", id: "ME" },
        { name: "Maryland", id: "MD" },
        { name: "Massachusetts", id: "MA" },
        { name: "Michigan", id: "MI" },
        { name: "Midway Atoll", id: "UM-71" },
        { name: "Minnesota", id: "MN" },
        { name: "Mississippi", id: "MS" },
        { name: "Missouri", id: "MO" },
        { name: "Montana", id: "MT" },
        { name: "Navassa Island", id: "UM-76" },
        { name: "Nebraska", id: "NE" },
        { name: "Nevada", id: "NV" },
        { name: "New Hampshire", id: "NH" },
        { name: "New Jersey", id: "NJ" },
        { name: "New Mexico", id: "NM" },
        { name: "New York", id: "NY" },
        { name: "North Carolina", id: "NC" },
        { name: "North Dakota", id: "ND" },
        { name: "Northern Mariana Islands", id: "MP" },
        { name: "Ohio", id: "OH" },
        { name: "Oklahoma", id: "OK" },
        { name: "Oregon", id: "OR" },
        { name: "Palmyra Atoll", id: "UM-95" },
        { name: "Pennsylvania", id: "PA" },
        { name: "Puerto Rico", id: "PR" },
        { name: "Rhode Island", id: "RI" },
        { name: "South Carolina", id: "SC" },
        { name: "South Dakota", id: "SD" },
        { name: "Tennessee", id: "TN" },
        { name: "Texas", id: "TX" },
        { name: "United States Minor Outlying Islands", id: "UM" },
        { name: "United States Virgin Islands", id: "VI" },
        { name: "Utah", id: "UT" },
        { name: "Vermont", id: "VT" },
        { name: "Virginia", id: "VA" },
        { name: "Wake Island", id: "UM-79" },
        { name: "Washington", id: "WA" },
        { name: "West Virginia", id: "WV" },
        { name: "Wisconsin", id: "WI" },
        { name: "Wyoming", id: "WY" }
    ],
    "uy": [
        { name: "Artigas Department", id: "AR" },
        { name: "Canelones Department", id: "CA" },
        { name: "Cerro Largo Department", id: "CL" },
        { name: "Colonia Department", id: "CO" },
        { name: "Durazno Department", id: "DU" },
        { name: "Flores Department", id: "FS" },
        { name: "Florida Department", id: "FD" },
        { name: "Lavalleja Department", id: "LA" },
        { name: "Maldonado Department", id: "MA" },
        { name: "Montevideo Department", id: "MO" },
        { name: "Paysandú Department", id: "PA" },
        { name: "Río Negro Department", id: "RN" },
        { name: "Rivera Department", id: "RV" },
        { name: "Rocha Department", id: "RO" },
        { name: "Salto Department", id: "SA" },
        { name: "San José Department", id: "SJ" },
        { name: "Soriano Department", id: "SO" },
        { name: "Tacuarembó Department", id: "TA" },
        { name: "Treinta y Tres Department", id: "TT" }
    ],
    "uz": [
        { name: "Andijan Region", id: "AN" },
        { name: "Bukhara Region", id: "BU" },
        { name: "Fergana Region", id: "FA" },
        { name: "Jizzakh Region", id: "JI" },
        { name: "Karakalpakstan", id: "QR" },
        { name: "Namangan Region", id: "NG" },
        { name: "Navoiy Region", id: "NW" },
        { name: "Qashqadaryo Region", id: "QA" },
        { name: "Samarqand Region", id: "SA" },
        { name: "Sirdaryo Region", id: "SI" },
        { name: "Surxondaryo Region", id: "SU" },
        { name: "Tashkent", id: "TK" },
        { name: "Tashkent Region", id: "TO" },
        { name: "Xorazm Region", id: "XO" }
    ],
    "vu": [
        { name: "Malampa", id: "MAP" },
        { name: "Penama", id: "PAM" },
        { name: "Sanma", id: "SAM" },
        { name: "Shefa", id: "SEE" },
        { name: "Tafea", id: "TAE" },
        { name: "Torba", id: "TOB" }
    ],
    "ve": [
        { name: "Amazonas", id: "Z" },
        { name: "Anzoátegui", id: "B" },
        { name: "Apure", id: "C" },
        { name: "Aragua", id: "D" },
        { name: "Barinas", id: "E" },
        { name: "Bolívar", id: "F" },
        { name: "Carabobo", id: "G" },
        { name: "Cojedes", id: "H" },
        { name: "Delta Amacuro", id: "Y" },
        { name: "Distrito Capital", id: "A" },
        { name: "Falcón", id: "I" },
        { name: "Federal Dependencies of Venezuela", id: "W" },
        { name: "Guárico", id: "J" },
        { name: "La Guaira", id: "X" },
        { name: "Lara", id: "K" },
        { name: "Mérida", id: "L" },
        { name: "Miranda", id: "M" },
        { name: "Monagas", id: "N" },
        { name: "Nueva Esparta", id: "O" },
        { name: "Portuguesa", id: "P" },
        { name: "Sucre", id: "R" },
        { name: "Táchira", id: "S" },
        { name: "Trujillo", id: "T" },
        { name: "Yaracuy", id: "U" },
        { name: "Zulia", id: "V" }
    ],
    "vn": [
        { name: "An Giang", id: "44" },
        { name: "Bà Rịa-Vũng Tàu", id: "43" },
        { name: "Bắc Giang", id: "54" },
        { name: "Bắc Kạn", id: "53" },
        { name: "Bạc Liêu", id: "55" },
        { name: "Bắc Ninh", id: "56" },
        { name: "Bến Tre", id: "50" },
        { name: "Bình Dương", id: "57" },
        { name: "Bình Định", id: "31" },
        { name: "Bình Phước", id: "58" },
        { name: "Bình Thuận", id: "40" },
        { name: "Cà Mau", id: "59" },
        { name: "Cần Thơ", id: "CT" },
        { name: "Cao Bằng", id: "04" },
        { name: "Đà Nẵng", id: "DN" },
        { name: "Đắk Lắk", id: "33" },
        { name: "Đắk Nông", id: "72" },
        { name: "Điện Biên", id: "71" },
        { name: "Đồng Nai", id: "39" },
        { name: "Đồng Tháp", id: "45" },
        { name: "Gia Lai", id: "30" },
        { name: "Hà Giang", id: "03" },
        { name: "Hà Nam", id: "63" },
        { name: "Hà Nội", id: "HN" },
        { name: "Hà Tĩnh", id: "23" },
        { name: "Hải Dương", id: "61" },
        { name: "Hải Phòng", id: "HP" },
        { name: "Hậu Giang", id: "73" },
        { name: "Hồ Chí Minh", id: "SG" },
        { name: "Hòa Bình", id: "14" },
        { name: "Hưng Yên", id: "66" },
        { name: "Khánh Hòa", id: "34" },
        { name: "Kiên Giang", id: "47" },
        { name: "Kon Tum", id: "28" },
        { name: "Lai Châu", id: "01" },
        { name: "Lâm Đồng", id: "35" },
        { name: "Lạng Sơn", id: "09" },
        { name: "Lào Cai", id: "02" },
        { name: "Long An", id: "41" },
        { name: "Nam Định", id: "67" },
        { name: "Nghệ An", id: "22" },
        { name: "Ninh Bình", id: "18" },
        { name: "Ninh Thuận", id: "36" },
        { name: "Phú Thọ", id: "68" },
        { name: "Phú Yên", id: "32" },
        { name: "Quảng Bình", id: "24" },
        { name: "Quảng Nam", id: "27" },
        { name: "Quảng Ngãi", id: "29" },
        { name: "Quảng Ninh", id: "13" },
        { name: "Quảng Trị", id: "25" },
        { name: "Sóc Trăng", id: "52" },
        { name: "Sơn La", id: "05" },
        { name: "Tây Ninh", id: "37" },
        { name: "Thái Bình", id: "20" },
        { name: "Thái Nguyên", id: "69" },
        { name: "Thanh Hóa", id: "21" },
        { name: "Thừa Thiên-Huế", id: "26" },
        { name: "Tiền Giang", id: "46" },
        { name: "Trà Vinh", id: "51" },
        { name: "Tuyên Quang", id: "07" },
        { name: "Vĩnh Long", id: "49" },
        { name: "Vĩnh Phúc", id: "70" },
        { name: "Yên Bái", id: "06" }
    ],
    "ye": [
        { name: "'Adan Governorate", id: "AD" },
        { name: "'Amran Governorate", id: "AM" },
        { name: "Abyan Governorate", id: "AB" },
        { name: "Al Bayda' Governorate", id: "BA" },
        { name: "Al Hudaydah Governorate", id: "HU" },
        { name: "Al Jawf Governorate", id: "JA" },
        { name: "Al Mahrah Governorate", id: "MR" },
        { name: "Al Mahwit Governorate", id: "MW" },
        { name: "Dhamar Governorate", id: "DH" },
        { name: "Hadhramaut Governorate", id: "HD" },
        { name: "Hajjah Governorate", id: "HJ" },
        { name: "Ibb Governorate", id: "IB" },
        { name: "Lahij Governorate", id: "LA" },
        { name: "Ma'rib Governorate", id: "MA" },
        { name: "Raymah Governorate", id: "RA" },
        { name: "Saada Governorate", id: "SD" },
        { name: "Sana'a", id: "SA" },
        { name: "Sana'a Governorate", id: "SN" },
        { name: "Shabwah Governorate", id: "SH" },
        { name: "Socotra Governorate", id: "SU" },
        { name: "Ta'izz Governorate", id: "TA" }
    ],
    "zm": [
        { name: "Central Province", id: "02" },
        { name: "Copperbelt Province", id: "08" },
        { name: "Eastern Province", id: "03" },
        { name: "Luapula Province", id: "04" },
        { name: "Lusaka Province", id: "09" },
        { name: "Muchinga Province", id: "10" },
        { name: "Northern Province", id: "05" },
        { name: "Northwestern Province", id: "06" },
        { name: "Southern Province", id: "07" },
        { name: "Western Province", id: "01" }
    ],
    "zw": [
        { name: "Bulawayo Province", id: "BU" },
        { name: "Harare Province", id: "HA" },
        { name: "Manicaland", id: "MA" },
        { name: "Mashonaland Central Province", id: "MC" },
        { name: "Mashonaland East Province", id: "ME" },
        { name: "Mashonaland West Province", id: "MW" },
        { name: "Masvingo Province", id: "MV" },
        { name: "Matabeleland North Province", id: "MN" },
        { name: "Matabeleland South Province", id: "MS" },
        { name: "Midlands Province", id: "MI" }
    ]
};