import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { SxProps } from "@mui/material/styles";
import React from "react";

interface Props {
    label: string,
    value: string | React.ReactNode,
    sx?: SxProps
}

export default function JobBoardViewField({ label, value, sx }: Props) {
    return (
        <Box display="flex" gap={2} sx={sx}>
            <Typography width="30%" fontWeight={600}>{label}</Typography>
            <Typography width="70%" component="div">{value}</Typography>
        </Box>
    );
}