import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import { ContactsConfigOptions } from "common/models/Configuration/Contacts";
import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import TextAreaWithFormat from "components/TextAreaWithFormat";
import useObjectStateWithChangeTracker from "hooks/UseObjectStateWithChangeTracker";
import useUnsavedChangesDialog from "hooks/UseUnsavedChangesDialog";
import PageContentLayout from "layouts/PageContentLayout";
import React, { useCallback, useEffect, useState } from "react";
import { GetContactsSettings, SaveContactsSettings } from "services/ConfigurationService";
import { DefaultOptions, NoChangesTracker } from "util/Definitions/Configuration/Contacts";
import { ObjToSettingsArray, SettingsArrayToObj } from "util/SettingsUtil";

interface Props {
    setSummaryBar?: (summaryBar: JSX.Element) => void
}

export default function ContactSettings({ setSummaryBar }: Props) {
    const [isLoading, setIsLoading] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const { state, init, change, updateInitial, hasChanges } = useObjectStateWithChangeTracker<ContactsConfigOptions>(DefaultOptions, NoChangesTracker);

    useEffect(() => {
        const getSettings = async () => {
            setIsLoading(true);
            const data = await GetContactsSettings(setErrorMessage);

            if (data) {
                const savedSettings = SettingsArrayToObj<ContactsConfigOptions>(data, DefaultOptions);
                init(savedSettings);
            }
            setIsLoading(false);
        };
        getSettings();
    }, [init]);

    const saveSettings = useCallback(async() => {
        setIsLoading(true);
        const settings = ObjToSettingsArray(state);
        const res = await SaveContactsSettings(settings, setErrorMessage);
        
        if (!res) {
            setIsLoading(false);
            return false;
        }
        
        updateInitial();
        setShowSuccess(true);
        setIsLoading(false);
        return true;
    }, [state, updateInitial]);

    const { unsavedChangesDialog } = useUnsavedChangesDialog(hasChanges, saveSettings);

    useEffect(() => {
        const saveButton = (
            <Button
                disabled={!hasChanges}
                variant="contained"
                color="primary"
                onClick={saveSettings}
                sx={{ borderRadius: '2px', padding: 0, height: '36px', width: '80px' }}
            >
                Save
            </Button>
        );
        
        const SummaryBar = (
            <TitleAndActionSummaryBar
                title="Configuration > Contacts"
                browserTabTitle="Contacts > Configuration"
                action={saveButton}
            />);

        setSummaryBar && setSummaryBar(SummaryBar);
    }, [hasChanges, saveSettings, setSummaryBar]);

    return (
        <>
            {unsavedChangesDialog}
            <Snackbar open={showSuccess} autoHideDuration={3000} onClose={() => setShowSuccess(false)}>
                <Alert onClose={() => setShowSuccess(false)}>Changes Saved</Alert>
            </Snackbar>
            <Snackbar open={errorMessage !== ''}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <PageContentLayout title="Contact Settings" showLoading={isLoading}>
                <Stack spacing={3}>
                    <Box>
                        <InputLabel shrink sx={{ pl: 2 }}>Default Summary</InputLabel>
                        <TextAreaWithFormat
                            height={300}
                            initialValue={state.ContactDefaultSummary}
                            onContentChange={content => change('ContactDefaultSummary', content)}
                        />
                    </Box>
                </Stack>
            </PageContentLayout>
        </>
    );
}