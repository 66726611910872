import { ErrorHandler, NumberValueWrapper, RequestOptions } from "../common/models/GenericTypes";
import { Contact } from "../common/models/Contacts";
import { Job } from "../common/models/Jobs/Job";
import { Site } from "../common/models/Site";
import { Placement } from "../common/models/Placements";
import { Get, Post, Put } from "../util/HttpUtils";
import { ClientSearch } from "../common/models/Search/ClientSearch";

export async function SearchSites(data: ClientSearch, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<Site[]>('api/sites/search', options, data);
}

export async function SiteLookup(term: string, limit: number, clientId?: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    const clientIdQuery = clientId ? `&clientId=${clientId}` : '';

    return await Get<Site[]>(`api/sites/lookup?query=${encodeURIComponent(term)}&limit=${limit}` + clientIdQuery, options);
}

export async function GetSiteById(siteId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return await Get<Site>(`api/sites/${siteId}`, options);
}

export async function MergeSites(masterId: number, siteIds: number[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>(`api/sites/${masterId}/merge`, options, siteIds);
}

export async function GetSitesDetailedReportData(status: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<any>(`api/sites/reports/detailed?status=${status}`, options);
}

export async function GetSiteContacts(siteId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Contact[]>(`api/sites/${siteId}/contacts`, options);
}

export async function GetSiteJobs(siteId: number, stateId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Job[]>(`api/sites/${siteId}/jobs?stateId=${stateId}`, options);
}

export async function GetSiteContractors(siteId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Placement[]>(`api/sites/${siteId}/Contractors`, options);
}

export async function CreateSite(siteData: Partial<Site>, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<NumberValueWrapper>('api/sites', options, siteData);
}

export async function UpdateSite(siteId: number, siteData: Partial<Site>, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/sites/${siteId}`, options, siteData);
}

export async function UpdateSiteCustomField(siteId: number, fieldNumber: number, value: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/sites/${siteId}/custom-fields`, options, { fieldNumber, value });
}