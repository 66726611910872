import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import React, { useEffect, useMemo, useState } from "react";
import { GetScreeningQuestionnaireTemplates } from "services/AdvertsService";
import { ScreeningQuestionnaireTemplate } from "common/models/Configuration/AdvertScreeningQuestionnaire";

interface Props {
    onSelectCallback: (template: ScreeningQuestionnaireTemplate | null) => void,
    templateId: number | null,
    label?: string
    disabled?: boolean
}

const noneOption: ScreeningQuestionnaireTemplate = { id: 0, json: '', name: 'None', status: '', statusID: 0, automatedRejectionDelay: 0 };

export default function AdvertScreeningQuestionnairePicker({ templateId, label = "Screening Questionnaire", disabled = false, onSelectCallback }: Props) {
    const [options, setOptions] = useState<ScreeningQuestionnaireTemplate[]>([]);

    useEffect(() => {
        const getData = async () => {
            const data = await GetScreeningQuestionnaireTemplates();
            if (data) setOptions([noneOption, ...data.filter(d => d.statusID === 1)]);
        };
        getData();
    }, []);

    const questionnaire = useMemo(() => {
        if (templateId === null) return null;
        const template = options.find(t => t.id === templateId);
        if (template) return template;
        return null;
    }, [options, templateId]);

    return (
        <Autocomplete
            size="small"
            value={questionnaire}
            options={options}
            getOptionLabel={o => o.name}
            isOptionEqualToValue={ (o,v) => o.id === v.id }
            onChange={ (e,v) => onSelectCallback(v) }
            disabled={disabled}
            renderInput={ params => <TextField {...params} label={label} inputProps={{ ...params.inputProps, "data-lpignore": "true" }} /> }
        />
    );
}