import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import TitleAndActionSummaryBar from "../SummaryBars/TitleAndActionSummaryBar";
import Button from "@mui/material/Button";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import DeleteIcon from '@mui/icons-material/Delete';
import * as tinymce from 'tinymce';
import Step from "@mui/material/Step";
import Stepper from "@mui/material/Stepper";
import StepLabel from "@mui/material/StepLabel";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import UserPicker from "../Pickers/UserPicker";
import { ChangeTracker } from "common/models/hooks/ChangeTracker";
import useObjectStateWithChangeTracker from "hooks/UseObjectStateWithChangeTracker";
import useUnsavedChangesDialog from "hooks/UseUnsavedChangesDialog";
import { NameIdObj, SimpleFileObj } from "common/models/GenericTypes";
import { TimeZone } from "common/models/Common";
import moment from "moment";
import { GetMyUser, GetSettingBySettingName } from "services/UsersService";
import { useTheme } from "@mui/material/styles";
import { GetTimeZone } from "services/CommonService";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import TimeZonePicker from "../Pickers/TimeZonePicker";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ContactPicker from "../Pickers/ContactPicker";
import ListItem from "@mui/material/ListItem";
import IconButton from "@mui/material/IconButton";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { Contact } from "common/models/Contacts";
import PreviewLoaderComponent from "../Previews/PreviewLoader";
import { PreviewEntityType } from "common/models/Previews/Previews";
import { Candidate } from "common/models/Candidates/Candidate";
import { GetCandidateById } from "services/CandidatesService";
import { PREVIEW_LINK_RENDERER } from "util/Definitions/Constants/Previews";
import MessageTemplatePicker from "../Pickers/MessageTemplatePicker";
import FilePicker from "../Pickers/FilePicker";
import { Editor } from "@tinymce/tinymce-react/lib/cjs/main/ts/components/Editor";
import { DownloadDocument } from "services/DocumentsService";
import { MessageTemplate } from "common/models/Templates/MessageTemplate";
import { GetJobById } from "services/JobsService";
import { InterviewEdit, UiInterviewEdit } from "common/models/Interviews";
import { CreateInterview, GetActiveInterviewTypes, GetInterviewAttendees, GetInterviewById, GetInterviewDocuments, GetInterviewTypeById, UpdateInterview } from "services/InterviewsService";
import { Navigate } from "react-router-dom";
import { GetContactAttendeeData } from "services/ContactsService";
import InterviewPreviewDialog from "components/Dialogs/Interviews/InterviewPreviewDialog";
import { DownloadMessageTemplateAttachmentFile, GetMessageTemplateAttachments } from "services/TemplatesService";
import { AttachmentsLimitBytes } from "util/FilesUtils";
import InsertPlaceholderDialog from "components/Dialogs/Generic/InsertPlaceholderDialog";
import { TemplatePlaceholder } from "common/models/Templates/TemplatePlaceholder";
import { GetPlaceholdersByEntity } from "services/PlaceholdersService";
import RWTextFieldComponent from "components/RWTextFieldComponent";
import { InterviewType } from "common/models/Configuration/InterviewType";

interface Props {
    interviewId?: number,
    typeId?: number,
    candidateId?: number,
    jobId?: number,
    defaultLocationTypeId?: number,
    defaultLocation?: string,
    defaultClientDistributionModeId?: number,
    defaultCandidateDistributionModeId?: number,
    defaultDuration?: number,
    setSummaryBar?: (sb: JSX.Element) => void,
    loadingHandler?: (isLoading: boolean) => void,
    successHandler?: (message: string) => void,
    errorHandler?: (message: string) => void,
}

const DefaultInterviewData: UiInterviewEdit = {
    clientDistributionMode: 2,
    candidateDistributionMode: 2,
    organizerId: 0,
    candidateId: 0,
    jobId: 0,
    typeId: 0,
    dateInLocalTimeZone: '',
    duration: 0,
    timeZoneName: '',
    subject: '',
    locationTypeId: 2,
    location: '',
    usersJson: '[]',
    contactsJson: '[]',
    candidatesJson: '[]',
    filesToRemoveJson: '[]',
};

const NoChangesInterviewData: ChangeTracker<UiInterviewEdit> = {
    clientDistributionMode: false,
    candidateDistributionMode: false,
    organizerId: false,
    candidateId: false,
    jobId: false,
    typeId: false,
    dateInLocalTimeZone: false,
    duration: false,
    timeZoneName: false,
    subject: false,
    locationTypeId: false,
    location: false,
    usersJson: false,
    contactsJson: false,
    candidatesJson: false,
    filesToRemoveJson: false,
};

var defaultEmailContent = ""
+ "Subject: #{Interview.Subject}<br/>"
+ "Date: #{Interview.Date}<br/>"
+ "Time: #{Interview.Time}<br/>"
+ "Timezone: #{Interview.Timezone}<br/>"
+ "Location: #{Interview.Location}<br/>"
+ "Attendees:<br/>"
+ "#{Interview.Attendees}";

interface AttendeeWithEmail extends NameIdObj {
    email: string | null
}

const interviewEntityType = 11;
const webConferencPlaceholder = '#{Interview.WebConferenceUrl}';

export default function InterviewEditPageContent({ interviewId = 0, typeId: typeIdProp = 0, candidateId: candidateIdProp = 0, jobId: jobIdProp = 0, defaultLocationTypeId = 2, defaultLocation = '', defaultCandidateDistributionModeId = 2, defaultClientDistributionModeId = 2, defaultDuration = 60, setSummaryBar, loadingHandler, successHandler, errorHandler }: Props) {
    const [interviewTypes, setInterviewTypes] = useState<InterviewType[]>([]);
    const [selectedInterviewType, setSelectedInterviewType] = useState<InterviewType | null>(null);
    const [initialInterviewType, setInitialInterviewType] = useState<InterviewType | null>(null);
    const [selectedCandidate, setSelectedCandidate] = useState<Candidate>();
    const [activeStep, setActiveStep] = useState(0);
    const [date, setDate] = useState<moment.Moment | null>(null);
    const [timeZone, setTimeZone] = useState<TimeZone | null>(null);
    const [candidateTemplateType, setCandidateTemplateType] = useState('Me');
    const [clientTemplateType, setClientTemplateType] = useState('Me');
    const [candidateTemplate, setCandidateTemplate] = useState<MessageTemplate | null>(null);
    const [clientTemplate, setClientTemplate] = useState<MessageTemplate | null>(null);
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    const [previewType, setPreviewType] = useState<PreviewEntityType | ''>('');
    const [previewRecordId, setPreviewRecordId] = useState(0);
    const [showValidation1, setShowValidation1] = useState(false);
    const [showValidation2, setShowValidation2] = useState(false);
    const [showValidation3, setShowValidation3] = useState(false);
    const [showValidation4, setShowValidation4] = useState(false);
    const [createdInterviewId, setCreatedInterviewId] = useState(0);
    const [savedChanges, setSavedChanges] = useState(false);
    const [isFetchingSetupData, setIsFetchingSetupData] = useState(false);
    const [isFetchingCreateData, setIsFetchingCreateData] = useState(false);
    const [isFetchingEditData, setIsFetchingEditData] = useState(false);
    const candidateEditorRef = useRef<tinymce.Editor | null>(null);
    const clientEditorRef = useRef<tinymce.Editor | null>(null);
    const theme = useTheme();
    const isDarkTheme = useMemo(() => theme.palette.mode === 'dark', [theme.palette.mode]);
    const [users, setUsers] = useState<NameIdObj[]>([]);
    const [contacts, setContacts] = useState<AttendeeWithEmail[]>([]);
    const [candidateTemplateFiles, setCandidateTemplateFiles] = useState<SimpleFileObj[]>([]);
    const [candidateFiles, setCandidateFiles] = useState<File[]>([]);
    const [uploadedCandidateFiles, setUploadedCandidateFiles] = useState<SimpleFileObj[]>([]);
    const [candidateAttachmentsToRemove, setCandidateAttachmentsToRemove] = useState<number[]>([]);
    const [isCandidateEditorDirty, setIsCandidateEditorDirty] = useState(false);
    const [clientTemplateFiles, setClientTemplateFiles] = useState<SimpleFileObj[]>([]);
    const [clientFiles, setClientFiles] = useState<File[]>([]);
    const [uploadedClientFiles, setUploadedClientFiles] = useState<SimpleFileObj[]>([]);
    const [clientAttachmentsToRemove, setClientAttachmentsToRemove] = useState<number[]>([]);
    const [isClientEditorDirty, setIsClientEditorDirty] = useState(false);
    const { state, init, change, updateInitial, hasChanges } = useObjectStateWithChangeTracker<UiInterviewEdit>(DefaultInterviewData, NoChangesInterviewData);
    const [showCandidatePreviewDialog, setShowCandidatePreviewDialog] = useState(false);
    const [showClientPreviewDialog, setShowClientPreviewDialog] = useState(false);
    const [showCandidatePlaceholders, setShowCandidatePlaceholders] = useState(false);
    const [showClientPlaceholders, setShowClientPlaceholders] = useState(false);
    const [placeholders, setPlaceholders] = useState<TemplatePlaceholder[]>([]);
    const [candidateName,setCandidateName] = useState('');

    useEffect(() => {
        const getPlaceholders = async () => {
            const res = await GetPlaceholdersByEntity(interviewEntityType);
            if (res) setPlaceholders(res);
        };
        getPlaceholders();
    }, []);

    useEffect(() => {
        const getData = async () => {
            setIsFetchingSetupData(true);
            const activitySettings = await GetActiveInterviewTypes();
            if (activitySettings) setInterviewTypes(activitySettings);
            setIsFetchingSetupData(false);
        };
        getData();
    }, []);

    useEffect(() => {
        if (date) change('dateInLocalTimeZone', date.format('YYYY-MM-DDTHH:mm'));
        else change('dateInLocalTimeZone', '');
    }, [date, change]);

    useEffect(() => {
        const setupCreate = async () => {
            setIsFetchingCreateData(true);
            let tmp = {...DefaultInterviewData};
            const m = moment();
            setDate(m);
            tmp.dateInLocalTimeZone = m.format('YYYY-MM-DDTHH:mm');
            tmp.typeId = typeIdProp;
            tmp.locationTypeId = defaultLocationTypeId;
            tmp.location = defaultLocation;
            tmp.candidateDistributionMode = defaultCandidateDistributionModeId;
            tmp.clientDistributionMode = defaultClientDistributionModeId;
            tmp.duration = defaultDuration;
            let tmpCandidateName = '';
            if (candidateIdProp) {
                const c = await GetCandidateById(candidateIdProp)
                if (c) {
                    setSelectedCandidate(c);
                    tmp.candidateId = c.id;
                    tmpCandidateName = c.fullName;
                    setCandidateName(tmpCandidateName);
                }
            }
            const calendarUserDefaultSetting = await GetSettingBySettingName('Calendar_User_ByDefault');
            const me = await GetMyUser();
            if (me) {
                tmp.organizerId = me.userID;
                if (me.timeZoneName) {
                    const tz = await GetTimeZone(me.timeZoneName);
                    setTimeZone(tz);
                    tmp.timeZoneName = me.timeZoneName;
                }
                if (calendarUserDefaultSetting && calendarUserDefaultSetting.toLowerCase() === 'true') {
                    setUsers([{ id: me.userID, name: me.displayName }]);
                    tmp.usersJson = JSON.stringify([me.userID]);
                }
            }

            const interviewType = await GetInterviewTypeById(typeIdProp);
            let tmpClientName = '';
            if (interviewType) {
                if (jobIdProp && !interviewType.isInternal) {
                    const j = await GetJobById(jobIdProp);
                    if (j) {
                        tmp.jobId = jobIdProp;
                        tmpClientName = j.clientName;
                        if (Boolean(j.fullAddress)) tmp.location = j.fullAddress;
                        const res = await GetContactAttendeeData([j.contact1ID, j.contact2ID]);
                        if (res) {
                            let a: AttendeeWithEmail[] = res.map(v => ({ id: v.attendeeID, name: v.name, email: v.email }));
                            setContacts(a);
                            tmp.contactsJson = JSON.stringify(a.map(v => v.id));
                        }
                    }
                }
                if (interviewType.isInternal) {
                    tmp.jobId = jobIdProp;
                    const me = await GetMyUser();
                    if (me) setUsers([{ id: me.userID, name: me.displayName }]);
                }
    
                if (tmpClientName === '' && interviewType.isInternal) tmp.subject = `Internal Interview - ${tmpCandidateName}`;
                else tmp.subject = `${tmpClientName} - Interview Confirmation - ${tmpCandidateName}`;

                setSelectedInterviewType(interviewType);
            }

            init(tmp);
            setIsFetchingCreateData(false);
        };
        interviewId === 0 && setupCreate();
    }, [interviewId, typeIdProp, candidateIdProp, jobIdProp, init, defaultLocationTypeId, defaultCandidateDistributionModeId, defaultClientDistributionModeId, defaultDuration, defaultLocation]);

    useEffect(() => {
        const getEditData = async () => {
            setIsFetchingEditData(true);
            let tmp = {...DefaultInterviewData};
            const res = await GetInterviewById(interviewId, errorHandler);
            if (res) {
                let interviewType = await GetInterviewTypeById(res.typeId);
                const isSlot = interviewType ? interviewType.isSlot : false;
                tmp.clientDistributionMode = res.distributionModeClient;
                tmp.candidateDistributionMode = res.distributionModeCandidate;
                tmp.organizerId = res.organizerID;
                tmp.jobId = res.jobID;
                
                if (interviewType) setInitialInterviewType({...interviewType});
                if (isSlot && typeIdProp) {
                    interviewType = await GetInterviewTypeById(typeIdProp);
                    tmp.typeId = typeIdProp;
                }
                else tmp.typeId = res.typeId;
                
                const m = moment(res.dateInLocalTimeZone);
                setDate(m.isValid() ? m : null);
                tmp.duration = res.duration;
                tmp.dateInLocalTimeZone = m.isValid() ? m.format('YYYY-MM-DDTHH:mm') : '';
                if (res.timeZoneName) {
                    const tz = await GetTimeZone(res.timeZoneName);
                    if (tz) setTimeZone(tz);
                }
                tmp.location = res.location;
                tmp.locationTypeId = res.locationTypeID;
                tmp.subject = res.subject;

                if (candidateEditorRef.current) candidateEditorRef.current.setContent(res.messageCandidate);
                if (clientEditorRef.current) clientEditorRef.current.setContent(res.messageClient);

                const attendees = await GetInterviewAttendees(interviewId, errorHandler);
                if (attendees) {
                    let u: NameIdObj[] = [];
                    let c: AttendeeWithEmail[] = [];
                    for (let i = 0; i < attendees.length; i++) {
                        const a = attendees[i];
                        if (a.type === 'User') u.push({ id: a.attendeeID, name: a.name });
                        else if (a.type === 'Contact') c.push({ id: a.attendeeID, name: a.name, email: a.email });
                        else if (a.type === 'Candidate') tmp.candidateId = a.attendeeID;
                    }
                    if (isSlot && candidateIdProp) tmp.candidateId = candidateIdProp;
                    if (tmp.candidateId) {
                        const cand = await GetCandidateById(tmp.candidateId);
                        if (cand) setSelectedCandidate(cand);
                    }
                    setUsers(u);
                    tmp.usersJson = JSON.stringify(u.map(v => v.id));
                    setContacts(c);
                    tmp.contactsJson = JSON.stringify(c.map(v => v.id));
                }

                const attachments = await GetInterviewDocuments(interviewId, errorHandler);
                if (attachments && attachments.length > 0) {
                    let fClient: SimpleFileObj[] = [];
                    let fCandidate: SimpleFileObj[] = [];
                    for (let i = 0; i < attachments.length; i++) {
                        const a = attachments[i];
                        if (a.entityID === 1) fClient.push({ id: a.id, name: a.documentName, size: a.size });
                        else if (a.entityID === 3) fCandidate.push({ id: a.id, name: a.documentName, size: a.size });
                    }
                    setUploadedClientFiles(fClient);
                    setUploadedCandidateFiles(fCandidate);
                }
                
                setSelectedInterviewType(interviewType);
            }
            init(tmp);
            setIsFetchingEditData(false);
        };
        interviewId && getEditData();
    }, [interviewId, init, errorHandler, typeIdProp, candidateIdProp]);

    useEffect(() => {
        loadingHandler && loadingHandler(isFetchingSetupData || isFetchingCreateData || isFetchingEditData);
    }, [isFetchingSetupData, isFetchingEditData, loadingHandler, isFetchingCreateData]);

    useEffect(() => {
        const getData = async () => {
            if (candidateTemplate) {
                loadingHandler && loadingHandler(true);
                const res = await GetMessageTemplateAttachments(candidateTemplate.id, errorHandler);
                if (res) {
                    let files: SimpleFileObj[] = [];
                    for (let i = 0; i < res.length; i++) {
                        const f = res[i];
                        files.push({ id: f.id, name: f.friendlyName, size: f.size })
                    }
                    if (files.length > 0) setCandidateTemplateFiles(files);
                    else setCandidateTemplateFiles([]);
                }
                else setCandidateTemplateFiles([]);
                loadingHandler && loadingHandler(false);
            }
        };
        candidateTemplate && getData();
    }, [candidateTemplate, errorHandler, loadingHandler]);

    useEffect(() => {
        const getData = async () => {
            if (clientTemplate) {
                loadingHandler && loadingHandler(true);
                const res = await GetMessageTemplateAttachments(clientTemplate.id, errorHandler);
                if (res) {
                    let files: SimpleFileObj[] = [];
                    for (let i = 0; i < res.length; i++) {
                        const f = res[i];
                        files.push({ id: f.id, name: f.friendlyName, size: f.size })
                    }
                    if (files.length > 0) setClientTemplateFiles(files);
                    else setClientTemplateFiles([]);
                }
                else setClientTemplateFiles([]);
                loadingHandler && loadingHandler(false);
            }
        };
        clientTemplate && getData();
    }, [clientTemplate, errorHandler, loadingHandler]);

    const isInternal = useMemo(() => selectedInterviewType ? selectedInterviewType.isInternal : false, [selectedInterviewType]);
    const isSlot = useMemo(() => selectedInterviewType ? selectedInterviewType.isSlot : false, [selectedInterviewType]);

    const isStep1Error = useMemo(() => {
        if (date === null) return true;
        if ((state.clientDistributionMode === 0 || state.candidateDistributionMode === 0) && (state.locationTypeId === 3 || state.locationTypeId === 4)) return true;
        return state.typeId === 0 || state.duration === 0 || state.locationTypeId === 0 || ((state.locationTypeId === 1 || state.locationTypeId === 5) && !Boolean(state.location)) || !Boolean(state.subject);
    }, [state.candidateDistributionMode, state.clientDistributionMode, state.typeId, state.duration, state.locationTypeId, state.location, state.subject, date]);

    const isStep2Error = useMemo(() => {
        if (isInternal) {
            if (users.length === 0) return true;
            return false;
        }
        
        if (!isSlot && state.candidateId === 0) return true;
        if (contacts.length > 0) {
            if (state.candidateDistributionMode === 0 && state.clientDistributionMode === 0) return false;
            const noEmailContact = contacts.find(c => !Boolean(c.email));
            if (noEmailContact) return true;
            else return false;
        }
        return true;
    }, [isInternal, isSlot, state.candidateId, state.candidateDistributionMode, state.clientDistributionMode, contacts, users.length]);

    const isStep3Error = useMemo(() => {
        return false;
        // if (state.candidateDistributionMode === 0) return false;
        // return !Boolean(state.candidateSubject);
    }, [/* state.candidateDistributionMode, state.candidateSubject */]);

    const isStep4Error = useMemo(() => {
        return false;
        // if (state.clientDistributionMode === 0) return false;
        // return !Boolean(state.clientSubject);
    }, [/* state.clientDistributionMode, state.clientSubject */]);

    const candidateInviteInfoStepIndex = useMemo(() => {
        if (state.candidateDistributionMode === 0 || isSlot) return null;
        return 2;
    }, [isSlot, state.candidateDistributionMode]);

    const clientInviteInfoStepIndex = useMemo(() => {
        if (state.clientDistributionMode === 0 || isInternal) return null;
        if (state.candidateDistributionMode === 0 || isSlot) return 2;
        return 3;
    }, [isInternal, isSlot, state.candidateDistributionMode, state.clientDistributionMode]);

    const nextStepHandler = useCallback(() => {
        let canContinue = true;
        if (activeStep === 0) {
            setShowValidation1(true);
            if (isStep1Error) {
                canContinue = false;
                if (state.candidateDistributionMode === 0 || state.clientDistributionMode === 0) {
                    if (state.locationTypeId === 3) errorHandler && errorHandler("Location Type cannot be Microsoft Teams when distribution is None");
                    else if (state.locationTypeId === 4) errorHandler && errorHandler("Location Type cannot be Zoom when distribution is None");
                }
            }
            else {
                const rightNow = moment().format('YYYY-MM-DDTHH:mm');
                const dString = date ? date.format('YYYY-MM-DDTHH:mm') : '';
                if (state.clientDistributionMode !== 0 && state.candidateDistributionMode !== 0 && dString < rightNow) {
                    errorHandler && errorHandler('Cannot send invites in the past');
                    canContinue = false;
                }
                else errorHandler && errorHandler('');
            }
        }
        else if (activeStep === 1) {
            setShowValidation2(true);
            if (isStep2Error) canContinue = false;
        }
        else if (candidateInviteInfoStepIndex && activeStep === candidateInviteInfoStepIndex) {
            setShowValidation3(true);
            const candidateEditorApi = candidateEditorRef.current;
            if (candidateEditorApi) {
                const content = candidateEditorApi.getContent();
                if (state.candidateDistributionMode !== 0) {
                    const isWebConference = state.locationTypeId === 3 || state.locationTypeId === 4;
                    const hasWebConverenceUrl = content.includes(webConferencPlaceholder);
                    if (hasWebConverenceUrl && !isWebConference ) {
                        errorHandler && errorHandler(`Remove placeholder ${webConferencPlaceholder} to continue`);
                        return false;
                    }
                    if (!hasWebConverenceUrl && isWebConference ) {
                        errorHandler && errorHandler(`Include placeholder ${webConferencPlaceholder} to continue`);
                        return false;
                    }
                }
            }

            if (isStep3Error) canContinue = false;
        }
        if (canContinue) setActiveStep(prev => prev + 1);
    }, [activeStep, candidateInviteInfoStepIndex, isStep1Error, date, state.clientDistributionMode, state.candidateDistributionMode, state.locationTypeId, errorHandler, isStep2Error, isStep3Error]);

    const prevStepHandler = useCallback(() => {
        setActiveStep(prev => prev -1);
    }, []);

    const saveHandler = useCallback(async () => {
        setShowValidation3(true);
        setShowValidation4(true);
        if (isStep1Error || isStep2Error || isStep3Error || isStep4Error) return false;

        if (candidateFiles.length > 0) {
            const totalSizeToUpload = candidateFiles.reduce((total, item) => total + item.size, 0);
            if (totalSizeToUpload > AttachmentsLimitBytes) {
                errorHandler && errorHandler("The attached candidate files surpass the size limit of 25MB");
                return false;
            }
        }

        if (clientFiles.length > 0) {
            const totalSizeToUpload = clientFiles.reduce((total, item) => total + item.size, 0);
            if (totalSizeToUpload > AttachmentsLimitBytes) {
                errorHandler && errorHandler("The attached client files surpass the size limit of 25MB");
                return false;
            }
        }
        
        const dString = date ? date.format('YYYY-MM-DDTHH:mm') : '';
        const isWebConference = state.locationTypeId === 3 || state.locationTypeId === 4;

        let candidateEditorContent = '';
        const candidateEditorApi = candidateEditorRef.current;
        if (candidateEditorApi) candidateEditorContent = candidateEditorApi.getContent();

        if (state.candidateDistributionMode !== 0 && candidateInviteInfoStepIndex !== null) {
            const hasWebConverenceUrl = candidateEditorContent.includes(webConferencPlaceholder);
            if (hasWebConverenceUrl && !isWebConference ) {
                errorHandler && errorHandler(`Candidate invite can't include placeholder ${webConferencPlaceholder}`);
                return false;
            }
            if (!hasWebConverenceUrl && isWebConference ) {
                errorHandler && errorHandler(`Candidate invite must include placeholder ${webConferencPlaceholder}`);
                return false;
            }
        }

        let clientEditorContent = '';
        const clientEditorApi = clientEditorRef.current;
        if (clientEditorApi) clientEditorContent = clientEditorApi.getContent();
        
        if (state.clientDistributionMode !== 0 && clientInviteInfoStepIndex !== null) {
            const hasWebConverenceUrl = clientEditorContent.includes(webConferencPlaceholder);
            if (hasWebConverenceUrl && !isWebConference ) {
                errorHandler && errorHandler(`Client invite can't include placeholder ${webConferencPlaceholder}`);
                return false;
            }
            if (!hasWebConverenceUrl && isWebConference ) {
                errorHandler && errorHandler(`Client invite must include placeholder ${webConferencPlaceholder}`);
                return false;
            }
        }

        loadingHandler && loadingHandler(true);
        const uIds = users.map(u => u.id);
        const cIds = contacts.map(c => c.id);

        const candidateTId = candidateTemplate ? candidateTemplate.id : 0;
        const clientTId = clientTemplate ? clientTemplate.id : 0;
        const tzName = timeZone ? timeZone.zoneName : '';

        const interviewData: InterviewEdit = {
            candidateDistributionMode: state.candidateDistributionMode,
            clientDistributionMode: state.clientDistributionMode,
            organizerId: state.organizerId,
            dateInLocalTimeZone: dString,
            timeZoneName: tzName,
            duration: state.duration,
            location: state.location,
            locationTypeId: state.locationTypeId,
            subject: state.subject,
            candidateTemplateId: candidateTId,
            clientTemplateId: clientTId,
            candidateMessage: candidateEditorContent,
            clientMessage: clientEditorContent,
            typeId: state.typeId,
            contacts: cIds,
            users: uIds,
            candidateId: state.candidateId,
            jobId: state.jobId,
            filesToRemove: [...candidateAttachmentsToRemove, ...clientAttachmentsToRemove]
        };

        if (interviewId === 0) {
            const res = await CreateInterview(interviewData, candidateFiles, clientFiles, errorHandler);
            if (!Boolean(res)) {
                loadingHandler && loadingHandler(false);
                return false;
            }
            else if (res) setCreatedInterviewId(res.value);
        }
        else {
            const res = await UpdateInterview(interviewId, interviewData, candidateFiles, clientFiles, errorHandler);
            if (!Boolean(res)) {
                loadingHandler && loadingHandler(false);
                return false;
            }
            else if (res) setCreatedInterviewId(interviewId);
        }

        updateInitial();
        setIsCandidateEditorDirty(false);
        setIsClientEditorDirty(false);
        if (candidateEditorApi) candidateEditorApi.setDirty(false);
        if (clientEditorApi) clientEditorApi.setDirty(false);
        setCandidateFiles([]);
        setClientFiles([]);
        loadingHandler && loadingHandler(false);
        setSavedChanges(true);
        return true;
    }, [isStep1Error, isStep2Error, isStep3Error, isStep4Error, date, state.locationTypeId, state.candidateDistributionMode, state.clientDistributionMode, state.organizerId, state.duration, state.location, state.subject, state.typeId, state.candidateId, state.jobId, candidateInviteInfoStepIndex, clientInviteInfoStepIndex, loadingHandler, users, contacts, candidateTemplate, clientTemplate, timeZone, candidateAttachmentsToRemove, clientAttachmentsToRemove, interviewId, updateInitial, errorHandler, candidateFiles, clientFiles]);

    const isActiveStepLast = useMemo(() => {
        let removed = 0;
        if (!Boolean(candidateInviteInfoStepIndex)) removed +=1;
        if (!Boolean(clientInviteInfoStepIndex)) removed += 1;
        return activeStep === (3 - removed);
    }, [activeStep, candidateInviteInfoStepIndex, clientInviteInfoStepIndex]);

    const redirectInterviewId = useMemo(() => {
        if (interviewId !== 0) return interviewId;
        if (savedChanges && createdInterviewId) return createdInterviewId;
    }, [interviewId, createdInterviewId, savedChanges]);

    useEffect(() => {
        const action = (
            <>
                <Button variant="contained" disabled={activeStep === 0} onClick={ prevStepHandler } sx={{ mr: '5px' }} startIcon={<KeyboardArrowLeftIcon />}>Prev</Button>
                { !isActiveStepLast && <Button variant="contained" disabled={activeStep === 3} onClick={ nextStepHandler } endIcon={<KeyboardArrowRightIcon />}>Next</Button> }
                { isActiveStepLast && <Button variant="contained" onClick={ saveHandler } color="success" endIcon={<KeyboardArrowRightIcon />}>Save</Button> }
            </>
        );

        if (interviewId === 0 && setSummaryBar) {
            const sb = <TitleAndActionSummaryBar title={`Interviews > Create > ${candidateName}`} browserTabTitle={`Create > Interview`} action={action} />;
            setSummaryBar(sb);
        }
        else if (interviewId && setSummaryBar) {
            const sb = <TitleAndActionSummaryBar title={`Interviews > Edit > ${candidateName}`} browserTabTitle={`Edit > Interview`} action={action} />;
            setSummaryBar(sb);
        }
    }, [activeStep, candidateName, interviewId, isActiveStepLast, nextStepHandler, prevStepHandler, saveHandler, setSummaryBar]);

    const candidateTemplateChangeHandlerCallback = useCallback((t: MessageTemplate | null) => {
        if (t) {
            setCandidateTemplate(t);
            // change('candidateSubject', t.subject);
            candidateEditorRef.current?.setContent(t.body);
        }
        else setCandidateTemplate(null);
    }, [/* change */]);

    const clientTemplateChangeHandlerCallback = useCallback((t: MessageTemplate | null) => {
        if (t) {
            setClientTemplate(t);
            // change('clientSubject', t.subject);
            clientEditorRef.current?.setContent(t.body);
        }
        else setClientTemplate(null);
    }, [/* change */]);

    const stringChangesCallback = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const {value, name} = e.target;
        change(name as keyof UiInterviewEdit, value);
    }, [change]);

    const numberChangesCallback = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const {value, name} = e.target;
        change(name as keyof UiInterviewEdit, +value);
    }, [change]);

    const interviewTypeChangeHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        const parsed = +value;
        change('typeId', parsed);
        if (parsed === 0) setSelectedInterviewType(null);
        else if (parsed > 0) {
            const type = interviewTypes.find(t => t.id === parsed);
            if (type) setSelectedInterviewType(type);
        }
    }, [change, interviewTypes]);

    const addedUserIds = useMemo(() => users.map(u => u.id), [users]);

    const addUserHandler = useCallback((u: NameIdObj | null) => {
        if (u) setUsers(prev => {
            const tmp = [...prev, u];
            change('usersJson', JSON.stringify(tmp.map(v => v.id)));
            return tmp;
        });
    }, [change]);

    const removeUserHandler = useCallback((index: number) => {
        setUsers(prev => {
            let tmp = [...prev];
            tmp.splice(index, 1);
            change('usersJson', JSON.stringify(tmp.map(v => v.id)));
            return tmp;
        });
    }, [change]);

    const addContactHandler = useCallback((c: Contact | null) => {
        if (c) {
            setContacts(prev => {
                const existing = prev.find(v => v.id === c.id);
                if (existing) {
                    errorHandler && errorHandler('Contact Already Added');
                    return prev;
                }
                const tmp = [...prev, { id: c.id, name: c.fullName, email: c.email }];
                change('contactsJson', JSON.stringify(tmp.map(v => v.id)));
                return tmp;
            });
        }
    }, [errorHandler, change]);

    const removeContactHandler = useCallback((index: number) => {
        setContacts(prev => {
            let tmp = [...prev];
            tmp.splice(index, 1);
            change('contactsJson', JSON.stringify(tmp.map(v => v.id)));
            return tmp;
        });
    }, [change]);

    const usersListElement = useMemo(() => {
        return users.map((u, i) => (
            <React.Fragment key={i}>
                <ListItem disablePadding secondaryAction={ <IconButton edge="end" onClick={ () => removeUserHandler(i) }><DeleteIcon /></IconButton> }>
                    <ListItemButton dense>
                        <ListItemText primary={u.name} />
                    </ListItemButton>
                </ListItem>
            </React.Fragment>
        ));
    }, [removeUserHandler, users]);

    const contactsListElement = useMemo(() => {
        return contacts.map((c, i) => {
            const hasEmail = Boolean(c.email);
            return (
                <React.Fragment key={i}>
                    <ListItem disablePadding secondaryAction={ <IconButton edge="end" onClick={ () => removeContactHandler(i) }><DeleteIcon /></IconButton> }>
                        <ListItemButton dense>
                            <ListItemText sx={{ color: t => hasEmail ? undefined : t.palette.error.main }} primary={c.name} secondary={hasEmail ? '' : 'Has No Email'} />
                        </ListItemButton>
                    </ListItem>
                </React.Fragment>
            )
        });
    }, [removeContactHandler, contacts]);

    const addCandidateFileCallback = useCallback((f: File[]) => {
        setCandidateFiles(prev => ([...prev, ...f]));
    }, []);

    const removeCandidateFileCallback = useCallback((index: number) => {
        setCandidateFiles(prev => {
            let files = [...prev];
            files.splice(index, 1);
            return files;
        });
    }, []);

    const removeUploadedCandidateFileCallback = useCallback((index: number) => {
        setUploadedCandidateFiles(prev => {
            let att = [...prev];
            const file = att[index];
            if (file.id !== 0) {
                setCandidateAttachmentsToRemove(prev2 => {
                    const tmp = [...prev2, file.id];
                    change('filesToRemoveJson', JSON.stringify(tmp));
                    return tmp;
                });
            }
            att.splice(index, 1);
            return att;
        });
    }, [change]);

    const addClientFileCallback = useCallback((f: File[]) => {
        setClientFiles(prev => ([...prev, ...f]));
    }, []);

    const removeClientFileCallback = useCallback((index: number) => {
        setClientFiles(prev => {
            let files = [...prev];
            files.splice(index, 1);
            return files;
        });
    }, []);

    const removeUploadedClientFileCallback = useCallback((index: number) => {
        setUploadedClientFiles(prev => {
            let att = [...prev];
            const file = att[index];
            if (file.id !== 0) {
                setClientAttachmentsToRemove(prev2 => {
                    const tmp = [...prev2, file.id];
                    change('filesToRemoveJson', JSON.stringify(tmp));
                    return tmp;
                });
            }
            att.splice(index, 1);
            return att;
        });
    }, [change]);

    const downloadFileCallback = useCallback(async (file: SimpleFileObj) => {
        loadingHandler && loadingHandler(true);
        await DownloadDocument(file.id, undefined, errorHandler);
        loadingHandler && loadingHandler(false);
    }, [loadingHandler, errorHandler]);

    const downloadCandidateTemplateFileCallback = useCallback(async (file: SimpleFileObj) => {
        if (candidateTemplate) {
            loadingHandler && loadingHandler(true);
            await DownloadMessageTemplateAttachmentFile(candidateTemplate.id, file.id, errorHandler);
            loadingHandler && loadingHandler(false);
        }
    }, [candidateTemplate, loadingHandler, errorHandler]);

    const downloadClientTemplateFileCallback = useCallback(async (file: SimpleFileObj) => {
        if (clientTemplate) {
            loadingHandler && loadingHandler(true);
            await DownloadMessageTemplateAttachmentFile(clientTemplate.id, file.id, errorHandler);
            loadingHandler && loadingHandler(false);
        }
    }, [clientTemplate, loadingHandler, errorHandler]);

    const { unsavedChangesDialog, hasBlockedRoute } = useUnsavedChangesDialog(hasChanges || candidateFiles.length > 0 || isCandidateEditorDirty || isClientEditorDirty, saveHandler);

    const openPreviewHandler = useCallback((type: PreviewEntityType, recordId: number) => {
        setPreviewType(type);
        setPreviewRecordId(recordId);
        setIsPreviewOpen(true);
    }, []);

    const closePreviewHandler = useCallback(() => {
        setIsPreviewOpen(false);
    }, []);

    const isTypeReadOnly = useMemo(() => {
        if (interviewId === 0 && isSlot) return false;
        if (interviewId !== 0 && initialInterviewType && initialInterviewType.isSlot) return false;
        return true;
    }, [interviewId, isSlot, initialInterviewType]);

    const showSelectedCandidate = useMemo(() => {
        if (isSlot) return false;
        return true;
    }, [isSlot]);

    const previewBody = useMemo(() => {
        const candidateApi = candidateEditorRef.current;
        const clientApi = clientEditorRef.current;
        if (showCandidatePreviewDialog && candidateApi) return candidateApi.getContent();
        if (showClientPreviewDialog && clientApi) return clientApi.getContent();
        return ''; 
    }, [showCandidatePreviewDialog, showClientPreviewDialog]);

    const closePreviewDialogHandler = useCallback(() => {
        setShowCandidatePreviewDialog(false);
        setShowClientPreviewDialog(false);
    }, []);

    const insertCandidatePlaceholderHandler = useCallback((placeholder: string) => {
        const api = candidateEditorRef.current;
        if (api) api.insertContent(placeholder);
    }, []);

    const insertClientPlaceholderHandler = useCallback((placeholder: string) => {
        const api = clientEditorRef.current;
        if (api) api.insertContent(placeholder);
    }, []);

    const previewMessageType = useMemo(() => {
        if (showClientPlaceholders) return 'client';
        return 'candidate';
    }, [showClientPlaceholders]);

    const isLocationTypeError = useMemo(() => {
        return (state.clientDistributionMode === 0 || state.candidateDistributionMode === 0) && (state.locationTypeId === 3 || state.locationTypeId === 4);
    }, [state.candidateDistributionMode, state.clientDistributionMode, state.locationTypeId]);

    return (
        <>
            {unsavedChangesDialog}
            { !hasBlockedRoute && redirectInterviewId !== 0 && savedChanges && <Navigate to={`/interviews/${redirectInterviewId}`} /> }
            <PreviewLoaderComponent
                open={isPreviewOpen}
                entityType={previewType}
                recordId={previewRecordId}
            />
            <InsertPlaceholderDialog
                open={showCandidatePlaceholders}
                closeHandler={() => setShowCandidatePlaceholders(false)}
                placeholders={placeholders}
                insertHandler={insertCandidatePlaceholderHandler}
            />
            <InsertPlaceholderDialog
                open={showClientPlaceholders}
                closeHandler={() => setShowClientPlaceholders(false)}
                placeholders={placeholders}
                insertHandler={insertClientPlaceholderHandler}
            />
            <InterviewPreviewDialog
                open={showCandidatePreviewDialog || showClientPreviewDialog}
                interviewId={interviewId}
                messageType={previewMessageType}
                body={previewBody}
                isDarkTheme={isDarkTheme}
                interviewData={state}
                closeHandler={closePreviewDialogHandler}
                loadingHandler={loadingHandler}
            />
            <Stepper nonLinear activeStep={activeStep}>
                <Step><StepLabel error={showValidation1 && isStep1Error}>Interview Info</StepLabel></Step>
                <Step><StepLabel error={showValidation2 && isStep2Error}>Attendees</StepLabel></Step>
                { Boolean(candidateInviteInfoStepIndex) && <Step><StepLabel error={showValidation3 && isStep3Error}>Candidate Invite Info</StepLabel></Step> }
                { Boolean(clientInviteInfoStepIndex) && <Step><StepLabel error={showValidation4 && isStep4Error}>Client Invite Info</StepLabel></Step> }
            </Stepper>
            <Box mt="20px" display={activeStep === 0 ? undefined : 'none'}>
                <Stack spacing={2}>
                    { !isSlot &&
                        <TextField select label="Distribution - Candidate" value={state.candidateDistributionMode} name="candidateDistributionMode" onChange={numberChangesCallback}>
                            <MenuItem value="0">None - Record in Recruit Wizard Only</MenuItem>
                            <MenuItem value="1">Calendar Invite</MenuItem>
                            <MenuItem value="2">Calendar Invite + Email</MenuItem>
                        </TextField>
                    }
                    { !isInternal &&
                        <TextField select label="Distribution - Client" value={state.clientDistributionMode} name="clientDistributionMode" onChange={numberChangesCallback}>
                            <MenuItem value="0">None - Record in Recruit Wizard Only</MenuItem>
                            <MenuItem value="1">Calendar Invite</MenuItem>
                            <MenuItem value="2">Calendar Invite + Email</MenuItem>
                        </TextField>
                    }
                    <UserPicker label="Organizer" onSelect={u => change('organizerId', u ? u.id : 0)} userId={state.organizerId} />
                    <TextField select label="Type" value={state.typeId.toString()} name="typeId" onChange={interviewTypeChangeHandler} error={showValidation1 && state.typeId === 0} InputProps={{ readOnly: isTypeReadOnly }}>
                        <MenuItem value="0">None</MenuItem>
                        {interviewTypes.map(t => <MenuItem key={t.id} value={t.id.toString()}>{t.name}</MenuItem>)}
                    </TextField>
                    <TimeZonePicker value={timeZone} onSelectCallback={setTimeZone} />
                    <DateTimePicker label="Date" value={date} onChange={setDate} disablePast={state.clientDistributionMode !== 0 || state.candidateDistributionMode !== 0} slotProps={{ textField: { error: showValidation1 && !date }, actionBar: { actions: ["clear", "today", "cancel", "accept"] } }} />
                    <TextField select label="Duration" value={state.duration.toString()} name="duration" onChange={numberChangesCallback} error={(state.clientDistributionMode !== 0 || state.candidateDistributionMode !== 0) && showValidation1 && state.duration === 0}>
                        <MenuItem value="0">None</MenuItem>
                        <MenuItem value="15">15 mins</MenuItem>
                        <MenuItem value="30">30 mins</MenuItem>
                        <MenuItem value="45">45 mins</MenuItem>
                        <MenuItem value="60">1 hour</MenuItem>
                        <MenuItem value="90">90 mins</MenuItem>
                        <MenuItem value="120">2 hours</MenuItem>
                        <MenuItem value="180">3 hours</MenuItem>
                        <MenuItem value="240">4 hours</MenuItem>
                        <MenuItem value="360">6 hours</MenuItem>
                        <MenuItem value="480">8 hours</MenuItem>
                    </TextField>
                    <TextField
                        select
                        label="Location Type"
                        value={state.locationTypeId.toString()}
                        name="locationTypeId"
                        onChange={numberChangesCallback}
                        error={showValidation1 && isLocationTypeError }
                    >
                        <MenuItem value="1">In Person</MenuItem>
                        <MenuItem value="2">Phone Call</MenuItem>
                        <MenuItem value="3">Microsoft Teams</MenuItem>
                        {/*<MenuItem value="4">Zoom</MenuItem>*/}
                        <MenuItem value="5">Other</MenuItem>
                    </TextField>
                    {(state.locationTypeId === 1 || state.locationTypeId === 5) &&
                        <RWTextFieldComponent
                            label="Location"
                            value={state.location}
                            name="location"
                            onChange={stringChangesCallback}
                            isError={(state.clientDistributionMode !== 0 || state.candidateDistributionMode !== 0) && showValidation1 && !Boolean(state.location)} />  
                    }
                    <RWTextFieldComponent
                            label="Subject"
                            value={state.subject}
                            name="subject"
                            onChange={stringChangesCallback}
                            isError={showValidation1 && state.candidateDistributionMode !== 0 && state.clientDistributionMode !== 0 && !Boolean(state.subject)} />  
                </Stack>
            </Box>
            <Box mt="20px" display={activeStep === 1 ? undefined : 'none'}>
                {showSelectedCandidate &&
                    <Box display="flex">
                        <Typography variant="h6" mr="20px">Candidate</Typography>
                        <Typography variant="h6" component="span" color="primary.main" display="flex" alignItems="center" sx={{ cursor: 'pointer' }} onMouseEnter={() => openPreviewHandler('candidate', selectedCandidate ? selectedCandidate.id : 0)} onMouseLeave={closePreviewHandler}>{PREVIEW_LINK_RENDERER(`/candidates/${selectedCandidate ? selectedCandidate.id : 0}`, selectedCandidate ? selectedCandidate.fullName : '', '_blank', 'none' )}</Typography>
                    </Box>
                }
                <Box display="flex" mt="10px">
                    <Box flex="1 1 0" mr="10px">
                        <Typography variant="h6" mb="10px" textAlign="center">Users</Typography>
                        <UserPicker label="Add User" userId={null} error={showValidation2 && isInternal && users.length === 0} onSelect={addUserHandler} ignoreIds={addedUserIds} blurOnSelect />
                        <List>{usersListElement}</List>
                    </Box>
                    { !isInternal &&
                        <Box flex="1 1 0" ml="10px">
                            <Typography variant="h6" mb="10px" textAlign="center">Contacts</Typography>
                            <ContactPicker label="Add Contact" disableQuickCreate value={null} onSelectCallback={addContactHandler} blurOnSelect error={showValidation2 && isStep2Error && state.candidateId !== 0} />
                            <List>{contactsListElement}</List>
                        </Box>
                    }
                </Box>
            </Box>
            <Box mt="20px" display={candidateInviteInfoStepIndex && state.candidateDistributionMode !== 0 && activeStep === candidateInviteInfoStepIndex ? undefined : 'none'}>
                <Stack spacing={2}>
                    <Box display="flex" gap={1}>
                        <TextField
                            select
                            fullWidth
                            label="Template Source"
                            value={candidateTemplateType}
                            onChange={ ({target}) => setCandidateTemplateType(target.value) }
                            sx={{ flex: '1 1 0' }}
                        >
                            <MenuItem value="Me">Me</MenuItem>
                            <MenuItem value="Team">My Team</MenuItem>
                            <MenuItem value="All">My Company</MenuItem>
                        </TextField>
                        <Box flex="1 1 0">
                            <MessageTemplatePicker
                                templateId={candidateTemplate ? candidateTemplate.id : null}
                                includeCompanyOwned
                                typeFilter={4}
                                templateSource={candidateTemplateType}
                                entityTypeFilter={3}
                                onSelectHandler={ candidateTemplateChangeHandlerCallback }
                            />
                        </Box>
                        <Button variant="contained" onClick={() => setShowCandidatePreviewDialog(true)}>Preview</Button>
                    </Box>
                    <Editor
                        tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce-5.10.2/tinymce.min.js'}
                        onInit={ (evt, editor) => candidateEditorRef.current = editor }
                        initialValue={defaultEmailContent}
                        onDirty={ () => setIsCandidateEditorDirty(true) }
                        init={{
                            height: 475,
                            skin: isDarkTheme ? 'oxide-dark' : undefined,
                            content_css: isDarkTheme ? 'dark' : undefined,
                            branding: false,
                            menubar: false,
                            statusbar: false,
                            contextmenu: false,
                            resize: false,
                            browser_spellcheck: true,
                            plugins: 'powerpaste code link emoticons table print preview visualchars lists fullscreen',
                            powerpaste_word_import: 'prompt',
                            powerpaste_html_import: 'prompt',
                            powerpaste_allow_local_images: true,
                            fontsize_formats: "8pt 9pt 10pt 12pt 14pt 18pt 24pt 36pt",
                            toolbar1: 'placeholders fontsizeselect fontselect styleselect forecolor backcolor bold italic alignleft aligncenter alignright alignjustify bullist numlist code fullscreen link mybutton table',
                            font_formats: 'Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Calibri=calibri; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Segoe=segoe,segoe ui,dejavu sans,trebuchet ms,verdana,sans-serif; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva',
                            setup: editor => {
                                editor.ui.registry.addButton('placeholders', {
                                    text: 'Insert Placeholder',
                                    onAction: () => setShowCandidatePlaceholders(true)
                                });
                            }
                        }}
                    />
                    <FilePicker
                        files={candidateFiles}
                        uploadedFiles={uploadedCandidateFiles}
                        templateFiles={candidateTemplateFiles}
                        addFilesHandler={ addCandidateFileCallback }
                        removeFileHandler={ removeCandidateFileCallback }
                        downloadFileHandler={downloadFileCallback}
                        downloadTemplateFileHandler={downloadCandidateTemplateFileCallback}
                        removeUploadedFileHandler={removeUploadedCandidateFileCallback}
                        limitSizeBytes={AttachmentsLimitBytes}
                    />
                </Stack>
            </Box>
            <Box mt="20px" display={clientInviteInfoStepIndex && state.clientDistributionMode !== 0 && activeStep === clientInviteInfoStepIndex ? undefined : 'none'}>
                <Stack spacing={2}>
                    <Box display="flex" gap={1}>
                        <TextField
                            select
                            fullWidth
                            label="Template Source"
                            value={clientTemplateType}
                            onChange={ ({target}) => setClientTemplateType(target.value) }
                            sx={{ flex: '1 1 0' }}
                        >
                            <MenuItem value="Me">Me</MenuItem>
                            <MenuItem value="Team">My Team</MenuItem>
                            <MenuItem value="All">My Company</MenuItem>
                        </TextField>
                        <Box flex="1 1 0">
                            <MessageTemplatePicker
                                templateId={clientTemplate ? clientTemplate.id : null}
                                includeCompanyOwned
                                typeFilter={4}
                                templateSource={clientTemplateType}
                                entityTypeFilter={2}
                                onSelectHandler={ clientTemplateChangeHandlerCallback }
                            />
                        </Box>
                        <Button variant="contained" onClick={() => setShowClientPreviewDialog(true)}>Preview</Button>
                    </Box>
                    <Editor
                        tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce-5.10.2/tinymce.min.js'}
                        onInit={ (evt, editor) => clientEditorRef.current = editor }
                        initialValue={defaultEmailContent}
                        onDirty={ () => setIsCandidateEditorDirty(true) }
                        init={{
                            height: 475,
                            skin: isDarkTheme ? 'oxide-dark' : undefined,
                            content_css: isDarkTheme ? 'dark' : undefined,
                            branding: false,
                            menubar: false,
                            statusbar: false,
                            contextmenu: false,
                            resize: false,
                            browser_spellcheck: true,
                            plugins: 'powerpaste code link emoticons table print preview visualchars lists fullscreen',
                            powerpaste_word_import: 'prompt',
                            powerpaste_html_import: 'prompt',
                            powerpaste_allow_local_images: true,
                            fontsize_formats: "8pt 9pt 10pt 12pt 14pt 18pt 24pt 36pt",
                            toolbar1: 'placeholders fontsizeselect fontselect styleselect forecolor backcolor bold italic alignleft aligncenter alignright alignjustify bullist numlist code fullscreen link mybutton table',
                            font_formats: 'Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Calibri=calibri; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Segoe=segoe,segoe ui,dejavu sans,trebuchet ms,verdana,sans-serif; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva',
                            setup: editor => {
                                editor.ui.registry.addButton('placeholders', {
                                    text: 'Insert Placeholder',
                                    onAction: () => setShowClientPlaceholders(true)
                                });
                            }
                        }}
                    />
                    <FilePicker
                        files={clientFiles}
                        uploadedFiles={uploadedClientFiles}
                        templateFiles={clientTemplateFiles}
                        addFilesHandler={ addClientFileCallback }
                        removeFileHandler={ removeClientFileCallback }
                        downloadFileHandler={downloadFileCallback}
                        downloadTemplateFileHandler={downloadClientTemplateFileCallback}
                        removeUploadedFileHandler={removeUploadedClientFileCallback}
                        limitSizeBytes={AttachmentsLimitBytes}
                    />
                </Stack>
            </Box>
        </>
    );
}
