import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";

import PageLayout from "layouts/PageLayout";
import PageContentLayout from "layouts/PageContentLayout";
import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import { Placement, PlacementRateChangeRequest } from "common/models/Placements";
import { ApprovePlacementById, DeletePlacementById, ExportPlacementToAstutePayroll, GetLatestPlacementRate, GetPlacementById, GetLatestPlacementById, RefreshPlacementToAstutePayroll, RevokePlacementApproval } from "services/PlacementsService";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { GetSettingBySettingName } from "services/UsersService";

import Avatar from '@mui/material/Avatar';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import ConfirmationDialog from "components/Dialogs/Generic/ConfirmationDialog";
import Snackbar from "@mui/material/Snackbar";
import Alert from "components/Alert";
import ActionMenu from "components/Menus/ActionMenu";
import moment from "moment";
import { GetActivitiesSettings, GetCustomerSettingBySettingName, GetPlacementsSettings } from "services/ConfigurationService";
import { ActivitySettings } from "common/models/Configuration/Activities";
import { MenuOptionDefinition } from "common/models/MenuDefinition";
import EditActivityDialog from "components/Dialogs/Activities/EditActivityDialog";
import { DefaultActivity } from "util/Definitions/Activities";
import { AllowPlacementsDelete, AllowPlacementsAddEdit } from "common/data/Permissions/PlacementsAccess";
import PreviewLoaderComponent from "components/Previews/PreviewLoader";
import TagsManagementDialog from "components/Dialogs/TagsManagementDialog";
import ActivitiesGridComponent from "components/Grids/ActivitiesGrid";
import ActivityMessagesGridComponent from "components/Grids/ActivityMessagesGrid";
import PlacementsGridComponent from "components/Grids/PlacementsGrid";
import DocumentsGridComponent from "components/Grids/DocumentsGrid";
import ComplianceGridComponent from "components/Grids/ComplianceGrid";
import ChangeRequestsGridComponent from "components/Grids/ChangeRequestsGrid";
import FinancialsGridComponent from "components/Grids/FinancialsGrid";
import InvoicesGridComponent from "components/Grids/InvoicesGrid";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { Permission } from "common/models/Permissions";
import { companyHasSinglePermission, userHasSinglePermission } from "util/PermissionsUtils";
import { AddRecentRecord } from "util/LocalStorageUtils";
import { Activity } from "common/models/Activities";
import { EmailRecipient } from "common/models/EmailRecipient";
import SendEmailDialog from "components/Dialogs/Messages/SendEmailDialog";
import SendOnboardingDialog from "components/Dialogs/Onboarding/SendOnboardingDialog";
import SendSmsDialog from "components/Dialogs/Messages/SendSmsDialog";
import CancelPlacementDialog from "components/Dialogs/Placements/CancelPlacementDialog";
import ViewRecordScreenLayout from "components/ScreenLayouts/Placements/ViewRecordScreenLayout";
import ChangeApproversDialog from "components/Dialogs/Placements/ChangeApproversDialog";
import { PlacementStatusEnum } from "util/Definitions/Configuration/Placements";
import ChangeContactsDialog from "components/Dialogs/Placements/ChangeContactsDialog";
import ChangeNetRevenueDialog from "components/Dialogs/Placements/ChangeNetRevenueDialog";
import ChangePayFrequencyDialog from "components/Dialogs/Placements/ChangePayFrequencyDialog";
import ChangeClientRefDialog from "components/Dialogs/Placements/ChangeClientRefDialog";
import ChangeStartDateDialog from "components/Dialogs/Placements/ChangeStartDateDialog";
import ChangeJobTitleDialog from "components/Dialogs/Placements/ChangeJobTitleDialog";
import EarlyTerminateDialog from "components/Dialogs/Placements/EarlyTerminateDialog";
import ConvertToPermDialog from "components/Dialogs/Placements/ConvertToPermDialog";
import ChangeConsultantsDialog from "components/Dialogs/Placements/ChangeConsultantsDialog";
import ChangePaymentTypeDialog from "components/Dialogs/Placements/ChangePaymentTypeDialog";
import GenerateDocumentDialog from "components/Dialogs/Documents/GenerateDocumentDialog";
import { Division } from "common/models/Configuration/Division";
import { GetDivision } from "services/DivisionsService";
import CreateInvoiceDialog from "components/Dialogs/Invoices/CreateInvoiceDialog";
import PlacementCandidateConfirmationDialog from "components/Dialogs/Placements/PlacementCandidateConfirmationDialog";
import ShowIframeDialog from "components/Dialogs/Generic/ShowIFrameDialog";
import usePartnerActions from "hooks/UsePartnerActions";
import { PartnerActionType } from "common/models/PartnerActions";
import CallOutNotification from "components/CallOutNotification";
import useRecordHotkeys from "hooks/UseRecordHotkeys";
import { MeetingType } from "common/models/Configuration/MeetingType";
import { GetActiveMeetingTypes } from "services/MeetingsService";

const summaryLinkStyle: React.CSSProperties = { color: 'inherit', textDecoration: 'none' };

const formatDateString = (d: string, f: string = 'DD MMM YYYY h:mm A') => {
    if (d && d !== '0001-01-01T00:00:00') {
        const m = moment(d);
        if (m.isValid()) return m.format(f);
    }
    return 'Never';
}

const renderSummary = (c: Placement) => {
    const tooltipContent = (
        <table>
            <tbody>
                <tr>
                    <Typography variant="caption" fontWeight={600} component="td">ID</Typography>
                    <Typography variant="caption" pl="10px" component="td">{c.id}</Typography>
                </tr>
                <tr>
                    <Typography variant="caption" fontWeight={600} component="td" sx={{ verticalAlign: 'top' }}>Title</Typography>
                    <Typography variant="caption" pl="10px" component="td">{c.jobTitle}</Typography>
                </tr>
                <tr>
                    <Typography variant="caption" fontWeight={600} component="td">Created Date</Typography>
                    <Typography variant="caption" pl="10px" component="td">{formatDateString(c.createdDate)}</Typography>
                </tr>
                <tr>
                    <Typography variant="caption" fontWeight={600} component="td">Created By</Typography>
                    <Typography variant="caption" pl="10px" component="td">{c.createdByName}</Typography>
                </tr>
                <tr>
                    <Typography variant="caption" fontWeight={600} component="td">Updated Date</Typography>
                    <Typography variant="caption" pl="10px" component="td">{formatDateString(c.updatedDate)}</Typography>
                </tr>
                <tr>
                    <Typography variant="caption" fontWeight={600} component="td">Updated By</Typography>
                    <Typography variant="caption" pl="10px" component="td">{c.updatedByName}</Typography>
                </tr>
            </tbody>
        </table>
    );
    const locationUrl = '/clients/' + c.clientID;

    const jobSummary = (
        <Tooltip arrow title={tooltipContent}>
            <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{c.jobTitle} ({c.placementTypeName}) at <a href={locationUrl} style={{ color: 'inherit', textDecoration: 'underline' }} key="clientNameAction" target="_blank" rel="noopener noreferrer" >{`${c.clientName}`}</a> - {c.placementStatusName}</span>
        </Tooltip>
    );

    return (<>{jobSummary}</>);
};

const entityTypeId = 5;

export default function PlacementRecord() {
    const [placement, setPlacement] = useState<Placement>();
    const [latestPlacement, setLatestPlacement] = useState<Placement>();
    const [parentPlacement, setParentPlacement] = useState<Placement>();
    const [latestRate, setLatestRate] = useState<PlacementRateChangeRequest>();
    const [division, setDivision] = useState<Division>();
    const [emailRecipient, setEmailRecipient] = useState<EmailRecipient | null>(null);
    const [onboardingRecipient, setOnboardingRecipient] = useState<EmailRecipient | null>(null);
    const [smsRecipient, setSmsRecipient] = useState<EmailRecipient | null>(null);
    const [contactEntityType, setContactEntityType] = useState(2);
    const [activitySettings, setActivitySettings] = useState<ActivitySettings[]>([]);
    const [selectedActivity, setSelectedActivity] = useState<ActivitySettings | null>(null);
    const [meetingTypes, setMeetingTypes] = useState<MeetingType[]>([]);
    const [selectedActivityRecipientType, setSelectedActivityRecipientType] = useState<'billing-contact' | 'candidate' | 'contact' | 'hiring-manager' | null>(null);
    const params = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [isFetchingDefaultTab, setIsFetchingDefaultTab] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [isRecordDeleted, setIsRecordDeleted] = useState(false);
    const [isLoadingTab, setIsLoadingTab] = useState(false);
    const [showTagsPrevew, setShowTagsPreview] = useState(false);
    const [showTagsPrevewNoDelay, setShowTagsPreviewNoDelay] = useState(false);
    const [showTagsManagement, setShowTagsManagement] = useState(false);
    const [searchParams] = useSearchParams();
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showApproveDialog, setShowApproveDialog] = useState(false);
    const [showRevokeDialog, setShowRevokeDialog] = useState(false);
    const [showCancelDialog, setShowCancelDialog] = useState(false);
    const [showChangeApproversDialog, setShowChangeApproversDialog] = useState(false);
    const [showChangeContactsDialog, setShowChangeContactsDialog] = useState(false);
    const [showChangeNetRevenueDialog, setShowChangeNetRevenueDialog] = useState(false);
    const [showChangePayFrequencyDialog, setShowChangePayFrequencyDialog] = useState(false);
    const [showChangeClientRefDialog, setShowChangeClientRefDialog] = useState(false);
    const [showChangeStartDateDialog, setShowChangeStartDateDialog] = useState(false);
    const [showRateCorrectionDialog, setShowRateCorrectionDialog] = useState(false);
    const [showChangeJobTitleDialog, setShowChangeJobTitleDialog] = useState(false);
    const [showEarlyTerminateDialog, setShowEarlyTerminateDialog] = useState(false);
    const [showTerminateDialog, setShowTerminateDialog] = useState(false);
    const [showConvertToPermDialog, setShowConvertToPermDialog] = useState(false);
    const [showChangeConsultantsDialog, setShowChangeConsultantsDialog] = useState(false);
    const [showChangePaymentTypeDialog, setShowChangePaymentTypeDialog] = useState(false);
    const [showChangeRatesDialog, setShowChangeRatesDialog] = useState(false);
    const [showExtendPlacementDialog, setShowExtendPlacementDialog] = useState(false);
    const [showExportToAstuteDialog, setShowExportToAstuteDialog] = useState(false);
    const [showRefreshToAstuteDialog, setShowRefreshToAstuteDialog] = useState(false);
    const [showCreateInvoiceDialog, setShowCreateInvoiceDialog] = useState(false);
    const [showCandidateConfirmationDialog, setShowCandidateConfirmationDialog] = useState(false);
    const [isFinishedSetup, setIsFinishedSetup] = useState(false);
    const [generateDocumentType, setGenerateDocumentType] = useState<'client' | 'candidate' | ''>('');
    const [isGenDocsOnlyAfterApproval, setIsGenDocsOnlyAfterApproval] = useState(false);
    const [activeTab, setActiveTab] = useState('PLACEMENTS_Home');
    const [defaultTab, setDefaultTab] = useState<string>();
    const [tabSetupFinished, setTabSetupFinished] = useState(false);
    const [netRevenueLabel, setNetRevenueLabel] = useState('Net Revenue');
    const [compliance, setCompliance] = useState(false);
    const [isSeperatePayBillDivision, setIsSeperatePayBillDivision] = useState(false);
    const [refreshActivitiesGridControl, setRefreshActivitiesGridControl] = useState(false);
    const [tabActionMenuOptions, setTabActionMenuOptions] = useState<MenuOptionDefinition[]>([]);
    const [canCancelOrTerminate, setCanCancelOrTerminate] = useState(false);
    const [ratesCalculatorLink, setRatesCalculatorLink] = useState('');
    const [showPartnerActionPopup, setShowPartnerActionPopup] = useState(false);
    const [showWorkflowPartnerActionPopup, setShowWorkflowPartnerActionPopup] = useState(false);
    const [callToActionTitle, setCallToActionTitle] = useState<string>('');
    const navigate = useNavigate();

    useEffect(() => {
        if (!showTagsPrevew) setShowTagsPreviewNoDelay(false);
    }, [showTagsPrevew]);

    const canAccessPlacements = useMemo(() => companyHasSinglePermission(Permission.Placements) && userHasSinglePermission(Permission.Placements), []);
    const canAccessActivities = useMemo(() => companyHasSinglePermission(Permission.Activities) && userHasSinglePermission(Permission.Activities), []);
    const canAddEditPlacements = useMemo(() => AllowPlacementsAddEdit(), []);
    const canDeletePlacements = useMemo(() => AllowPlacementsDelete(), []);
    const canApprovePlacements = useMemo(() => companyHasSinglePermission(Permission.FinancialController) && userHasSinglePermission(Permission.FinancialController), []);
    const userCanSendEmail = useMemo(() => userHasSinglePermission(Permission.MessageCentre) && companyHasSinglePermission(Permission.MessageCentre), []);
    const userCanSendSms = useMemo(() => userHasSinglePermission(Permission.MessageCentreSms) && companyHasSinglePermission(Permission.MessageCentreSms), []);
    const canBackOfficeIntegration = useMemo(() => companyHasSinglePermission(Permission.BackOfficeIntegration), []);
    const canSendOnboarding = useMemo(() => companyHasSinglePermission(Permission.Onboarding), []);
    const isUserFinancialController = useMemo(() => userHasSinglePermission(Permission.FinancialController), []);
    
    const canGenerateDocuments = useMemo(() => {
        const hasPermission = companyHasSinglePermission(Permission.DocumentTemplates) && userHasSinglePermission(Permission.DocumentTemplates);
        if (hasPermission) {
            const isApproved = placement ? placement.approved : false;
            if (isGenDocsOnlyAfterApproval && !isApproved) return false;
            return true;
        }
        return false;
    }, [isGenDocsOnlyAfterApproval, placement]);

    const placementId = useMemo(() => {
        const parsedId = +(params.id ?? '0');
        if (isNaN(parsedId)) return 0;
        return parsedId;
    }, [params.id]);

    const placementIdArray = useMemo(() => [placementId], [placementId]);

    const partnerActionType = useMemo(() => {
        if (placement) return placement.approved ? PartnerActionType.PlacementsApproved : PartnerActionType.Placements;
        return null;
    }, [placement]);

    const partnerWorkflowActionType = useMemo(() => {
        if (placement) return placement.approved ? PartnerActionType.PlacementsWorkflowApproved : PartnerActionType.PlacementsWorkflow;
        return null;
    }, [placement]);

    const partnerActionsData = useMemo(() => ({ placementId }), [placementId]);
    const { partnerActions, popupTitle, popupUrl } = usePartnerActions(partnerActionType, partnerActionsData, setShowPartnerActionPopup);
    const {
        partnerActions: workflowPartnerActions,
        popupTitle: popupTitle2,
        popupUrl: popupUrl2
    } = usePartnerActions(partnerWorkflowActionType, partnerActionsData, setShowWorkflowPartnerActionPopup);

    const activitySelectedHandler = useCallback((s: ActivitySettings, r: 'billing-contact' | 'candidate' | 'contact' | 'hiring-manager') => {
        setSelectedActivity(s);
        setSelectedActivityRecipientType(r);
    }, []);

    const closeActivityDialogHandler = useCallback(() => {
        setSelectedActivity(null);
        setSelectedActivityRecipientType(null);
    }, []);

    useEffect(() => {
        if (canApprovePlacements && placement && placement.placementStatusID !== 11 && placement.placementStatusID !== 12 ) {
            if (placement.isChangeRequestPending) {
                setCallToActionTitle('Placement has pending Change Requests');
            }
            else if (!placement.approved) {
                setCallToActionTitle('Placement requires Approval');
            }
        }
    }, [canApprovePlacements, placement, placementId]);

    useEffect(() => {
        const getSettings = async () => {
            const settings = await GetPlacementsSettings();
            if (settings && settings.length > 0) {
                for (let i = 0; i < settings.length; i++) {
                    const s = settings[i];
                    if (s.type === 'PlacementsWhoCanCancelAndTerminate' && s.value) {
                        if (s.value === 'FinancialControllers' && !isUserFinancialController) setCanCancelOrTerminate(false);
                        else setCanCancelOrTerminate(true);
                    }
                    else if (s.type === 'PlacementRatesCalculator' && s.value) setRatesCalculatorLink(s.value);
                    else if (s.type === 'PlacementsGenerateDocsAfterApproval' && s.value) setIsGenDocsOnlyAfterApproval(s.value === 'True');
                }
            }
        };
        getSettings();
    }, [canApprovePlacements, isUserFinancialController]);

    const approvePlacementCallback = useCallback(async () => {
        setIsLoading(true);
        const res = await ApprovePlacementById(placementId, setErrorMessage);
        if (res) {
            setSuccessMessage('Placement Approved Successfully');
            setShowApproveDialog(false);
            const p = await GetPlacementById(placementId);
            if (p) {
                setPlacement(p);
                setLatestPlacement(p);
            }
        }
        setIsLoading(false);
    }, [placementId]);

    const revokeApprovalCallback = useCallback(async () => {
        setIsLoading(true);
        const res = await RevokePlacementApproval(placementId, setErrorMessage);
        if (res) {
            setSuccessMessage('Approval Revoked Successfully');
            setShowRevokeDialog(false);
            const p = await GetPlacementById(placementId);
            if (p) {
                setPlacement(p);
                setLatestPlacement(p);
            }
        }
        setIsLoading(false);
    }, [placementId]);

    const exportToAsuteCallback = useCallback(async () => {
        setIsLoading(true);
        const res = await ExportPlacementToAstutePayroll(placementId, setErrorMessage);
        if (res) {
            setShowExportToAstuteDialog(false);
            setSuccessMessage('Exported To Astute Payroll');
        }
        setIsLoading(false);
    }, [placementId]);

    const refreshToAsuteCallback = useCallback(async () => {
        setIsLoading(true);
        const res = await RefreshPlacementToAstutePayroll(placementId, setErrorMessage);
        if (res) {
            setShowRefreshToAstuteDialog(false);
            setSuccessMessage('Astute Payroll Data Refreshed');
        }
        setIsLoading(false);
    }, [placementId]);

    const changePaymentTypeOrRatesDialogType = useMemo(() => {
        if (showChangePaymentTypeDialog) return 'payment-type';
        if (showChangeRatesDialog) return 'rates';
        if (showExtendPlacementDialog) return 'extend';
        if (showRateCorrectionDialog) return 'rate-correction';
        return null;
    }, [showChangePaymentTypeDialog, showChangeRatesDialog, showExtendPlacementDialog, showRateCorrectionDialog]);

    const hotkeySettings = useMemo<Record<string, () => void>>(() => {
        return {
            e: () => navigate(`/placements/${placementId}/edit`),
            t: () => setShowTagsManagement(true),
        };
    }, [navigate, placementId]);

    useRecordHotkeys(hotkeySettings, !canAddEditPlacements);

    const onCreateInvoiceSuccess = useCallback((message: string) => {
        setSuccessMessage(message);
    }, []);

    useEffect(() => {
        const getData = async () => {
            setIsLoading(true);
            const res = await GetPlacementById(placementId);
            if (res === null) navigate('/not-found');

            if (res) {
                const candidateConfirmation = localStorage.getItem('placementCandidateConfirmationId');
                if (candidateConfirmation && candidateConfirmation === placementId.toString()) {
                    localStorage.removeItem('placementCandidateConfirmationId');
                    setShowCandidateConfirmationDialog(true);
                }

                const lp = await GetLatestPlacementById(placementId);

                if(lp) {
                    setLatestPlacement(lp);
                }

                const rate = await GetLatestPlacementRate(placementId, setErrorMessage);
                if (rate) {
                    setLatestRate({...rate, paymentTypeID: res.paymentTypeID, paymentTypeName: res.paymentTypeName, paymentCompanyID: res.paymentCompanyID, paymentContactID: res.paymentContactID});
                }

                AddRecentRecord(5, placementId, res.jobTitle);
                setPlacement(res);
                if (res.parentPlacementID) {
                    const res2 = await GetPlacementById(res.parentPlacementID);
                    if (res2) setParentPlacement(res2);
                }
                var res3 = await GetDivision(res.division);
                if (res3) {
                    setDivision(res3);
                }
            }

            const settings = await GetActivitiesSettings(5, undefined, setErrorMessage);

            if (settings) setActivitySettings(settings);
            setIsLoading(false);
        };
        getData();
    }, [placementId, navigate]);

    useEffect(() => {
        const getMeetingTypes = async () => {
            const res = await GetActiveMeetingTypes(entityTypeId);
            if (res) setMeetingTypes(res);
        };
        getMeetingTypes();
    }, []);

    const isPlacementCancelledOrPending = useMemo(() => {
        if (placement) {
            const statusId = placement.placementStatusID;
            return statusId === 11 || statusId === 12;
        }
        return true;
     }, [placement]);

    useEffect(() => {
        const tabParam = searchParams.get('tab');
        const getData = async () => {
            setIsFetchingDefaultTab(true);
            if (!tabParam) {
                const tabSetting = await GetSettingBySettingName('TabDefaultsPlacements');
                if (tabSetting) setDefaultTab(tabSetting);
            }
            else setActiveTab(tabParam)
            setIsFetchingDefaultTab(false);
            setIsFinishedSetup(true);
        };
        getData();
    }, [searchParams]);

    useEffect(() => {
        const getNetRevenueLabel = async () => {
            const res = await GetCustomerSettingBySettingName('NetRevenueName');
            if (res) setNetRevenueLabel(res);
        };
        getNetRevenueLabel();
    }, []);

    useEffect(() => {
        const getData = async () => {
            const value = await GetCustomerSettingBySettingName('IsComplianceEnabled');
            if (value) setCompliance(value === 'True');
        };
        getData();
    }, []);

    useEffect(() => {
        const getData = async () => {
            const value = await GetCustomerSettingBySettingName('PlacementsSeperatePayBillDivision');
            if (value) setIsSeperatePayBillDivision(value === 'True');
        };
        getData();
    }, []);

    const sendEmailCallback = useCallback((type: 'billing' | 'candidate' | 'contact' | 'hiring' | 'pay-contact') => {
        if (placement) {
            let id = 0;
            let email = '';
            let name = '';
            let isOptOut = false;
            let contactEntityType = 2;
            switch (type) {
                case 'billing':
                    id = placement.billingContactContactID;
                    name = placement.billingContactName ?? '';
                    email = placement.billingContactEmail;
                break;
                case 'candidate':
                    id = placement.candidateID;
                    email = placement.candidateEmail;
                    name = placement.candidateName;
                    isOptOut = placement.candidateOptOut;
                    contactEntityType = 3;
                break;
                case 'contact':
                    id = placement.contactID;
                    email = placement.contactEmail;
                    name = placement.contactName;
                break;
                case 'hiring':
                    id = placement.hiringManagerContactID;
                    email = placement.hiringManagerEmail;
                    name = placement.hiringManagerName;
                break;
                case 'pay-contact':
                    id = placement.paymentContactID;
                    email = placement.paymentContactEmail ?? '';
                    name = placement.paymentContactName ?? '';
            }

            const r: EmailRecipient = {
                id: id,
                mobile: '',
                email: email,
                name: name,
                placement: placement,
                isOptOut: isOptOut,
            };
            setContactEntityType(contactEntityType);
            setEmailRecipient(r);
        }
    }, [placement]);

    const sendOnboardingCallback = useCallback(() => {
        if (placement) {
            let id = placement.candidateID;
            let email = placement.candidateEmail;
            let name = placement.candidateName;
            let contactEntityType = 3;

            const r: EmailRecipient = {
                id: id,
                mobile: '',
                email: email,
                name: name,
                placement: placement,
                isOptOut: false,
            };
            setContactEntityType(contactEntityType);
            setOnboardingRecipient(r);
        }
    }, [placement]);

    const sendSmsCallback = useCallback((type: 'billing' | 'candidate' | 'contact' | 'hiring' | 'pay-contact') => {
        if (placement) {
            let id = 0;
            let phone = '';
            let name = '';
            let isOptOut = false;
            let contactEntityType = 2;
            switch (type) {
                case 'billing':
                    id = placement.billingContactContactID;
                    name = placement.billingContactName ?? '';
                    phone = placement.billingContactMobile_Standardised;
                break;
                case 'candidate':
                    id = placement.candidateID;
                    phone = placement.candidateTel1_Standardised ?? '';
                    name = placement.candidateName;
                    isOptOut = placement.candidateOptOut;
                    contactEntityType = 3;
                break;
                case 'contact':
                    id = placement.contactID;
                    phone = placement.contactMobile_Standardised;
                    name = placement.contactName;
                break;
                case 'hiring':
                    id = placement.hiringManagerContactID;
                    phone = placement.hiringManagerMobile_Standardised;
                    name = placement.hiringManagerName;
                break;
                case 'pay-contact':
                    id = placement.paymentContactID;
                    phone = placement.paymentContactMobile_Standardised ?? '';
                    name = placement.paymentContactName ?? '';
                break;
            }

            const r: EmailRecipient = {
                id: id,
                mobile: phone,
                email: '',
                name: name,
                placement: placement,
                isOptOut: isOptOut,
            };
            setContactEntityType(contactEntityType);
            setSmsRecipient(r);
        }
    }, [placement]);

    const cancelPlacementErrorMessage = useMemo(() => {
        if (placement) {
            const statusId = placement.placementStatusID;
            if (statusId === PlacementStatusEnum.Cancelled) return 'Placement has been Cancelled already';
            if (!placement.approved || statusId === PlacementStatusEnum.Future || canApprovePlacements) return 'ok';

            const isEarlyTerminated = statusId === PlacementStatusEnum.PendingEarlyTermination || statusId === PlacementStatusEnum.EarlyTerminated;
            if (isEarlyTerminated) return 'Placements can only be Cancelled if the Candidate did not start';

            const startDatePlus14 = moment(placement.startDate).add(14, 'd');
            const currentDateMoment = moment();
            if (!canCancelOrTerminate) return 'You do not have Permission to Cancel Placements';
            if ((statusId === PlacementStatusEnum.Warranty || statusId === PlacementStatusEnum.Current || statusId === PlacementStatusEnum.Completed) && startDatePlus14 > currentDateMoment ) return 'ok';
            if ((statusId === PlacementStatusEnum.Warranty || statusId === PlacementStatusEnum.Current || statusId === PlacementStatusEnum.Completed) && startDatePlus14 < currentDateMoment ) return 'Placements can only be Cancelled within 14 days of the Start Date';
        }
        return '';
    }, [placement, canApprovePlacements, canCancelOrTerminate]);

    const changeApproversErrorMessage = useMemo(() => {
        if(canAddEditPlacements && placement && placement.approved && placement.placementTypeID === 2) {
            const statusId = placement.placementStatusID;
            if (statusId === PlacementStatusEnum.Future || statusId === PlacementStatusEnum.Current || statusId === PlacementStatusEnum.Completed ||
                statusId === PlacementStatusEnum.PendingEarlyTermination || statusId === PlacementStatusEnum.EarlyTerminated) return 'ok';
            if (statusId === PlacementStatusEnum.Cancelled) return 'Placement has been Cancelled already';
        }
        return '';
    }, [canAddEditPlacements, placement]);

    const changeContactsErrorMessage = useMemo(() => {
        if (canAddEditPlacements && placement && placement.approved) {
            const statusId = placement.placementStatusID;
            if (statusId === PlacementStatusEnum.Future || statusId === PlacementStatusEnum.Current || statusId === PlacementStatusEnum.Warranty || statusId === PlacementStatusEnum.Completed ||
                statusId === PlacementStatusEnum.PendingEarlyTermination || statusId === PlacementStatusEnum.EarlyTerminated) return 'ok';
            if (statusId === PlacementStatusEnum.Cancelled) return 'Placement has been Cancelled already';
        }
        return '';
    }, [canAddEditPlacements, placement]);

    const changeNetRevenueErrorMessage = useMemo(() => {
        if (canAddEditPlacements && placement && placement.approved) {
            const statusId = placement.placementStatusID;
            if (statusId === PlacementStatusEnum.Future || statusId === PlacementStatusEnum.Current || statusId === PlacementStatusEnum.Warranty || statusId === PlacementStatusEnum.Completed ||
                statusId === PlacementStatusEnum.PendingEarlyTermination || statusId === PlacementStatusEnum.EarlyTerminated) return 'ok';
            if (statusId === PlacementStatusEnum.Cancelled) return 'Placement has been Cancelled already';
        }
        return '';
    }, [canAddEditPlacements, placement]);

    const changePayFrequencyErrorMessage = useMemo(() => {
        if (canAddEditPlacements && placement && placement.approved && placement.placementTypeID === 2) {
            const statusId = placement.placementStatusID;
            if (statusId === PlacementStatusEnum.Future || statusId === PlacementStatusEnum.Current || statusId === PlacementStatusEnum.Completed ||
                statusId === PlacementStatusEnum.PendingEarlyTermination || statusId === PlacementStatusEnum.EarlyTerminated) return 'ok';
            if (statusId === PlacementStatusEnum.Cancelled) return 'Placement has been Cancelled already';
        }
        return '';
    }, [canAddEditPlacements, placement]);

    const changeClientRefErrorMessage = useMemo(() => {
        if (canAddEditPlacements && placement && placement.approved) {
            const statusId = placement.placementStatusID;
            if (statusId === PlacementStatusEnum.Future || statusId === PlacementStatusEnum.Current || statusId === PlacementStatusEnum.Warranty) return 'ok';
            if (statusId === PlacementStatusEnum.PendingEarlyTermination) return 'ok';
            if (statusId === PlacementStatusEnum.Cancelled) return 'Placement has been Cancelled already';

            const endDatePlus14 = moment(placement.endDate).add(14, 'd');
            const currentDateMoment = moment();
            if (statusId === PlacementStatusEnum.Completed || statusId === PlacementStatusEnum.EarlyTerminated) {
                if (endDatePlus14 > currentDateMoment) return 'Client Ref can only be changed within 14 days of the End Date';
                else return 'ok';
            }
        }
        return '';
    }, [placement, canAddEditPlacements]);

    const changeStartDateErrorMessage = useMemo(() => {
        if (canAddEditPlacements && placement) {
            if (placement.isChangeRequestPending) return 'Change Request is already pending. This must be dealt with first';
            if (placement.numberOfRateCards > 1) return 'Start Date can only be changed when there is a single Rate Card';

            const statusId = placement.placementStatusID;
            if (statusId === PlacementStatusEnum.PendingEarlyTermination || statusId === PlacementStatusEnum.EarlyTerminated) return 'Start Date cannot be changed as Placement has been Early Terminated';
            if (statusId === PlacementStatusEnum.Cancelled) return 'Placement has been Cancelled already';

            const isApproved = placement.approved;
            if (isApproved && statusId === PlacementStatusEnum.Future) return 'ok';

            const startDatePlus28 = moment(placement.startDate).add(60, 'd');
            const isWithin4Weeks = startDatePlus28 > moment();

            if (isApproved && (statusId === PlacementStatusEnum.Current || statusId === PlacementStatusEnum.Warranty) && isWithin4Weeks) return 'ok';
            if (isApproved && (statusId === PlacementStatusEnum.Current || statusId === PlacementStatusEnum.Warranty) && !isWithin4Weeks) return 'Start Date can only be changed within 60 days of the Start Date';
            if (statusId === PlacementStatusEnum.Completed && isWithin4Weeks) return 'ok';
            if (statusId === PlacementStatusEnum.Completed && !isWithin4Weeks) return 'Start Date can only be changed within 60 days of the Start Date';

        }
        return '';
    }, [placement, canAddEditPlacements]);

    const rateCorrectionErrorMessage = useMemo(() => {
         if (canAddEditPlacements && placement && placement.approved) {
             if (placement.isChangeRequestPending) return 'Change Request is already pending. This must be dealt with first';
             if (placement.numberOfRateCards > 1) return 'Rates can only be corrected when there is a single Rate Card';
             if (placement.placementTypeID !== 2) return `${placement.placementTypeName} Placement cannot have Rates changed`;

             const statusId = placement.placementStatusID;
             if (statusId === PlacementStatusEnum.PendingEarlyTermination || statusId === PlacementStatusEnum.EarlyTerminated) return 'Rates cannot be corrected as Placement has been Early Terminated';
             if (statusId === PlacementStatusEnum.Cancelled) return 'Placement has been Cancelled already';

             const isApproved = placement.approved;
             if (isApproved && statusId === PlacementStatusEnum.Future) return 'ok';

             const startDatePlus28 = moment(placement.startDate).add(60, 'd');
             const isWithin4Weeks = startDatePlus28 > moment();

             if (isApproved && (statusId === PlacementStatusEnum.Current || statusId === PlacementStatusEnum.Warranty) && isWithin4Weeks) return 'ok';
             if (isApproved && (statusId === PlacementStatusEnum.Current || statusId === PlacementStatusEnum.Warranty) && !isWithin4Weeks) return 'Rates can only be corrected within 60 days of the Start Date';
             if (statusId === PlacementStatusEnum.Completed && isWithin4Weeks) return 'ok';
             if (statusId === PlacementStatusEnum.Completed && !isWithin4Weeks) return 'Rates can only be corrected within 60 days of the Start Date';

         }
         return '';
    }, [placement, canAddEditPlacements]);

    const changeJobTitleErrorMessage = useMemo(() => {
        if (canAddEditPlacements && placement && placement.approved) {
            const statusId = placement.placementStatusID;
            const validStatusIds = [PlacementStatusEnum.Future, PlacementStatusEnum.Current, PlacementStatusEnum.Warranty, PlacementStatusEnum.Completed, PlacementStatusEnum.PendingCancellation, PlacementStatusEnum.EarlyTerminated];
            if (statusId === PlacementStatusEnum.Cancelled) return 'Placement has been Cancelled already';
            if (validStatusIds.includes(statusId)) return 'ok';
        }
        return '';
    }, [placement, canAddEditPlacements]);

    const earlyTerminateErrorMessage = useMemo(() => {
        if (canAddEditPlacements && placement && placement.approved && placement.endDate !== '0001-01-01T00:00:00') {

            const statusId = placement.placementStatusID;
            if (statusId === PlacementStatusEnum.Cancelled) return 'Placement has been Cancelled already';
            if (placement.placementTypeID === 1 && statusId === PlacementStatusEnum.Future) return 'Permanent future placements cannot be terminated';

            const validStatusIds = [PlacementStatusEnum.Future, PlacementStatusEnum.Current, PlacementStatusEnum.Completed, PlacementStatusEnum.PendingEarlyTermination, PlacementStatusEnum.EarlyTerminated];
            if (!canCancelOrTerminate) return 'You do not have Permission to Terminate Placements';
            if (validStatusIds.includes(statusId)) return 'ok';
        }

        return '';
    }, [placement, canAddEditPlacements, canCancelOrTerminate]);

    const terminateErrorMessage = useMemo(() => {
        if (canAddEditPlacements && placement && placement.approved && (placement.endDate === '0001-01-01T00:00:00' || placement.endDate === '')) {
            const statusId = placement.placementStatusID;
            if (statusId === PlacementStatusEnum.Cancelled) return 'Placement has been Cancelled already';
            if (placement.placementTypeID === 1 && statusId === PlacementStatusEnum.Future) return 'Permanent future placements cannot be terminated';

            if (!canCancelOrTerminate) return 'You do not have Permission to Terminate Placements';
            if (statusId === PlacementStatusEnum.Future || statusId === PlacementStatusEnum.Current || statusId === PlacementStatusEnum.Warranty) return 'ok';
            if (canApprovePlacements && statusId === PlacementStatusEnum.Completed) return 'ok';
        }
        return '';
    }, [placement, canAddEditPlacements, canApprovePlacements, canCancelOrTerminate]);

    const convertToPermErrorMessage = useMemo(() => {
        if (canAddEditPlacements && placement && placement.approved && placement.placementTypeID !== 1) {
            if (placement.placementTypeID !== 2) return `${placement.placementTypeName} Placement cannot be converted to Perm`;

            const statusId = placement.placementStatusID;
            if (statusId === PlacementStatusEnum.Cancelled) return 'Placement has been Cancelled already';

            if (placement.isChangeRequestPending) return 'Change Request is already pending. This must be dealt with first';

            const validStatusIds = [PlacementStatusEnum.Future, PlacementStatusEnum.Current, PlacementStatusEnum.Completed, PlacementStatusEnum.PendingEarlyTermination, PlacementStatusEnum.EarlyTerminated];
            if (validStatusIds.includes(statusId)) return 'ok';
        }
        return '';
    }, [placement, canAddEditPlacements]);

    const changeConsultantsErrorMessage = useMemo(() => {
        if (canAddEditPlacements && placement && placement.approved && placement.placementTypeID === 2) {
            if (placement.isChangeRequestPending) return 'Change Request already pending';
            else return 'ok';
        }
        return '';
    }, [placement, canAddEditPlacements]);

    const changePaymentTypeErrorMessage = useMemo(() => {
        if (canAddEditPlacements && placement && placement.approved && placement.placementTypeID === 2) {
            if (placement.placementTypeID !== 2) return `${placement.placementTypeName} Placement cannot have Payment Type changed`;
            if (placement.isChangeRequestPending) return 'Change Request already pending';
            else return 'ok';
        }
        return '';
    }, [placement, canAddEditPlacements]);

    const changeRatesErrorMessage = useMemo(() => {
        if (canAddEditPlacements && placement && placement.approved && placement.placementTypeID === 2) {
            if (placement.placementTypeID !== 2) return `${placement.placementTypeName} Placement cannot have Rates changed`;
            if (placement.isChangeRequestPending) return 'Change Request already pending';
            else return 'ok';
        }
        return '';
    }, [placement, canAddEditPlacements]);

    const extendPlacementErrorMessage = useMemo(() => {
        if (canAddEditPlacements && placement && placement.approved && placement.placementTypeID === 2) {
            if (placement.placementTypeID !== 2) return `${placement.placementTypeName} Placement cannot be extended`;
            if ((placement.endDate === '0001-01-01T00:00:00' || placement.endDate === '')) return 'Placement is already Open Ended and cannot be Extended';
            const statusId = placement.placementStatusID;

            if (statusId === PlacementStatusEnum.Cancelled) return 'Placement has been Cancelled already';

            const validStatusIds = [PlacementStatusEnum.Future, PlacementStatusEnum.Current, PlacementStatusEnum.Completed, PlacementStatusEnum.PendingEarlyTermination, PlacementStatusEnum.EarlyTerminated];
            if (validStatusIds.includes(statusId)) return 'ok';
        }
        return '';
    }, [placement, canAddEditPlacements]);

    const exportToAstuteErrorMessage = useMemo(() => {
        if (canBackOfficeIntegration && canApprovePlacements && placement && placement.approved && placement.placementTypeID !== 4 && placement.placementTypeID !== 5 && !placement.exportedToBackOffice && division && division.timesheetSystem === 1) {
            const ppId = placement.parentPlacementID;
            const typeId = placement.placementTypeID;

            if (ppId === 0) return 'ok';
            if (typeId !== 2 && ppId !== 0) return 'ok';
            if (typeId === 2 && ppId !== 0) {
                if (parentPlacement && parentPlacement.exportedToBackOffice) return 'ok';
                else return 'Parent Placement has not been Exported, therefore you cannot export this Placement';
            }
        }
        return '';
    }, [canBackOfficeIntegration, canApprovePlacements, placement, parentPlacement, division]);

    const actionMenuDefinitions = useMemo<MenuOptionDefinition[]>(() => {
        let actions: (MenuOptionDefinition)[] = [
            ...partnerActions,
            ...tabActionMenuOptions,
            { label: 'Copy', type: 'link', href: `/placements/create?jobId=${placement?.jobID}&typeId=${placement?.placementTypeID}&candidateId=${placement?.candidateID}&copyPlacementId=${placementId}`, allow: () => canAddEditPlacements },
            { label: 'Create Invoice', type: 'action', action: () => setShowCreateInvoiceDialog(true), allow: () => canAddEditPlacements },
            { label: 'Delete', type: 'action', action: () => setShowDeleteDialog(true), allow: () => canDeletePlacements },
            { label: 'Send Email', type: 'parent', allow: () => userCanSendEmail, subMenu: [
                { label: 'To Billing Contact', type: 'action', action: () => sendEmailCallback('billing') },
                { label: 'To Candidate', type: 'action', action: () => sendEmailCallback('candidate') },
                { label: 'To Contact', type: 'action', action: () => sendEmailCallback('contact') },
                { label: 'To Hiring Manager', type: 'action', action: () => sendEmailCallback('hiring') },
                { label: 'To Pay Contact', type: 'action', action: () => sendEmailCallback('pay-contact') },
            ] },
            { label: 'Send Onboarding Form', type: 'action', allow: () => canSendOnboarding, action: () => sendOnboardingCallback() },
            { label: 'Send SMS', type: 'parent', allow: () => userCanSendSms, subMenu: [
                { label: 'To Billing Contact', type: 'action', action: () => sendSmsCallback('billing') },
                { label: 'To Candidate', type: 'action', action: () => sendSmsCallback('candidate') },
                { label: 'To Contact', type: 'action', action: () => sendSmsCallback('contact') },
                { label: 'To Hiring Manager', type: 'action', action: () => sendSmsCallback('hiring') },
                { label: 'To Pay Contact', type: 'action', action: () => sendSmsCallback('pay-contact') },
            ] },
            { label: 'Generate Document', type: 'parent', allow: () => canGenerateDocuments, subMenu: [
                { label: 'Candidate Documents', type: 'action', action: () => setGenerateDocumentType('candidate'), allow: () => canGenerateDocuments },
                { label: 'Client Documents', type: 'action', action: () => setGenerateDocumentType('client'), allow: () => canGenerateDocuments },
            ] },
        ];

        for (let i = 0; i < activitySettings.length; i++) {
            const setting = activitySettings[i];
            if (setting.category === 2) continue;
            if (setting.category === 1) {
                actions.push({ label: setting.customName, type: 'parent', subMenu: [
                    { label: 'Billing Contact', type: 'action', action: () => activitySelectedHandler(setting, 'billing-contact') },
                    { label: 'Candidate', type: 'action', action: () => activitySelectedHandler(setting, 'candidate') },
                    { label: 'Contact', type: 'action', action: () => activitySelectedHandler(setting, 'contact') },
                    { label: 'Hiring Manager', type: 'action', action: () => activitySelectedHandler(setting, 'hiring-manager') },
                ]});
            }
            else actions.push({ label: setting.customName, type: 'action', action: () => setSelectedActivity(setting) });
        }

        let meetingActions: MenuOptionDefinition = {
            label: 'Meetings', type: 'parent', subMenu: []
        };

        if (meetingTypes.length > 0 && meetingActions.subMenu) {
            for (let i = 0; i < meetingTypes.length; i++) {
                const t = meetingTypes[i];
                const candidateId = placement ? placement.candidateID : 0;
                const contactId = placement ? placement.contactID : 0;
                const cId = t.candidateOrClientMeeting === 3 ? candidateId : contactId;
                const href = `/meetings/create?contactIds=${cId}&typeId=${t.id}&source=${entityTypeId}&placementId=${placementId}`;
                meetingActions.subMenu.push({ label: t.name, type: 'link', href: href });
            }
        }

        if (meetingActions.subMenu && meetingActions.subMenu.length > 0) actions.push(meetingActions);

        return actions;
    }, [partnerActions, tabActionMenuOptions, placement, placementId, meetingTypes, canAddEditPlacements, canDeletePlacements, userCanSendEmail, sendEmailCallback, canSendOnboarding, sendOnboardingCallback, userCanSendSms, sendSmsCallback, canGenerateDocuments, activitySettings, activitySelectedHandler]);

    const workflowMenuDefinitions = useMemo<MenuOptionDefinition[]>(() => {
        if (isPlacementCancelledOrPending) return [];

        const placementTypeId = placement ? placement.placementTypeID : 0;
        const isPlacementApproved = placement ? placement.approved : false;
        const isExportedToBackOffice = placement ? placement.exportedToBackOffice : false;
        const isTimesheetDataAvailable = placement ? placement.isTimesheetDataAvailable : false;
        const numberOfRateCards = placement ? placement.numberOfRateCards : 0;
        let revokeError = '';

        if (numberOfRateCards > 1) revokeError = 'Unable to Revoke Approval once multiple Rate Cards exist';
        else if (isTimesheetDataAvailable) revokeError = 'Unable to Revoke Approval once Timesheets have been collected';

        const revokeAction = revokeError ? () => setErrorMessage(revokeError) : () => setShowRevokeDialog(true);
        const cancelAction = cancelPlacementErrorMessage === 'ok' ? () => setShowCancelDialog(true) : () => setErrorMessage(cancelPlacementErrorMessage);
        const changeApproversAction = changeApproversErrorMessage === 'ok' ? () => setShowChangeApproversDialog(true) : () => setErrorMessage(changeApproversErrorMessage);
        const changeContactsAction = changeContactsErrorMessage === 'ok' ? () => setShowChangeContactsDialog(true) : () => setErrorMessage(changeContactsErrorMessage);
        const changeNetRevenueAction = changeNetRevenueErrorMessage === 'ok' ? () => setShowChangeNetRevenueDialog(true) : () => setErrorMessage(changeNetRevenueErrorMessage);
        const changePayFrequencyAction = changePayFrequencyErrorMessage === 'ok' ? () => setShowChangePayFrequencyDialog(true) : () => setErrorMessage(changePayFrequencyErrorMessage);
        const changeClientRefAction = changeClientRefErrorMessage === 'ok' ? () => setShowChangeClientRefDialog(true) : () => setErrorMessage(changeClientRefErrorMessage);
        const changeStartDateAction = changeStartDateErrorMessage === 'ok' ? () => setShowChangeStartDateDialog(true) : () => setErrorMessage(changeStartDateErrorMessage);
        const rateCorrectionAction = rateCorrectionErrorMessage === 'ok' ? () => setShowRateCorrectionDialog(true) : () => setErrorMessage(rateCorrectionErrorMessage);
        const changeJobTitleAction = changeJobTitleErrorMessage === 'ok' ? () => setShowChangeJobTitleDialog(true) : () => setErrorMessage(changeJobTitleErrorMessage);
        const earlyTerminateAction = earlyTerminateErrorMessage === 'ok' ? () => setShowEarlyTerminateDialog(true) : () => setErrorMessage(earlyTerminateErrorMessage);
        const terminateAction = terminateErrorMessage === 'ok' ? () => setShowTerminateDialog(true) : () => setErrorMessage(terminateErrorMessage);
        const convertToPermAction = convertToPermErrorMessage === 'ok' ? () => setShowConvertToPermDialog(true) : () => setErrorMessage(convertToPermErrorMessage);
        const changeConsultantsAction = changeConsultantsErrorMessage === 'ok' ? () => setShowChangeConsultantsDialog(true) : () => setErrorMessage(changeConsultantsErrorMessage);
        const changePaymentTypeAction = changePaymentTypeErrorMessage === 'ok' ? () => setShowChangePaymentTypeDialog(true) : () => setErrorMessage(changePaymentTypeErrorMessage);
        const changeRatesAction = changeRatesErrorMessage === 'ok' ? () => setShowChangeRatesDialog(true) : () => setErrorMessage(changeRatesErrorMessage);
        const extendPlacementAction = extendPlacementErrorMessage === 'ok' ? () => setShowExtendPlacementDialog(true) : () => setErrorMessage(extendPlacementErrorMessage);
        const exportToAstuteAction = exportToAstuteErrorMessage === 'ok' ? () => setShowExportToAstuteDialog(true) : () => setErrorMessage(exportToAstuteErrorMessage);

        const actions: MenuOptionDefinition[] = [
            { label: 'Approve Placement', type: 'action', action: () => setShowApproveDialog(true), allow: () => canApprovePlacements && !isPlacementApproved },
            { label: 'Revoke Approval', type: 'action', action: revokeAction, allow: () => canApprovePlacements && isPlacementApproved && !isExportedToBackOffice },
            { label: 'Cancel Placement', type: 'action', action: cancelAction, allow: () => canAddEditPlacements && Boolean(cancelPlacementErrorMessage) },
            { label: 'Change Approvers', type: 'action', action: changeApproversAction, allow: () => canAddEditPlacements && Boolean(changeApproversErrorMessage) },
            { label: 'Change Contacts', type: 'action', action: changeContactsAction, allow: () => canAddEditPlacements && Boolean(changeContactsErrorMessage) },
            { label: `Change ${netRevenueLabel}`, type: 'action', action: changeNetRevenueAction, allow: () => canAddEditPlacements && Boolean(changeNetRevenueErrorMessage) },
            { label: 'Change Pay Frequency', type: 'action', action: changePayFrequencyAction, allow: () => canAddEditPlacements && Boolean(changePayFrequencyErrorMessage) },
            { label: 'Change Client Ref', type: 'action', action: changeClientRefAction, allow: () => canAddEditPlacements && Boolean(changeClientRefErrorMessage) },
            { label: 'Change Start Date', type: 'action', action: changeStartDateAction, allow: () => canAddEditPlacements && Boolean(changeStartDateErrorMessage) },
            { label: 'Change Job Title', type: 'action', action: changeJobTitleAction, allow: () => canAddEditPlacements && Boolean(changeJobTitleErrorMessage) },
            { label: 'Change Consultants', type: 'action', action: changeConsultantsAction, allow: () => canAddEditPlacements && Boolean(changeConsultantsErrorMessage) },
            { label: 'Change Payment Type', type: 'action', action: changePaymentTypeAction, allow: () => canAddEditPlacements && Boolean(changePaymentTypeErrorMessage) },
            { label: 'Change Rates', type: 'action', action: changeRatesAction, allow: () => canAddEditPlacements && Boolean(changeRatesErrorMessage) },
            { label: 'Extend Placement', type: 'action', action: extendPlacementAction, allow: () => canAddEditPlacements && Boolean(extendPlacementErrorMessage) },
            { label: 'Early Terminate', type: 'action', action: earlyTerminateAction, allow: () => Boolean(earlyTerminateErrorMessage) },
            { label: 'Rate Correction', type: 'action', action: rateCorrectionAction, allow: () => canAddEditPlacements && Boolean(rateCorrectionErrorMessage) },
            { label: 'Terminate', type: 'action', action: terminateAction, allow: () => Boolean(terminateErrorMessage) },
            { label: 'Convert to Perm', type: 'action', action: convertToPermAction, allow: () => Boolean(convertToPermErrorMessage) },
            { label: 'Export To Astute Payroll', type: 'action', action: exportToAstuteAction, allow: () => Boolean(exportToAstuteErrorMessage) },
            { label: 'Refresh Data To Astute Payroll', type: 'action', action: () => setShowRefreshToAstuteDialog(true), allow: () => canBackOfficeIntegration && canApprovePlacements && isExportedToBackOffice && isPlacementApproved && placementTypeId === 2 && division?.timesheetSystem === 1 },
            ...workflowPartnerActions
        ];
        return actions;
    }, [isPlacementCancelledOrPending, placement, division, cancelPlacementErrorMessage, changeApproversErrorMessage, changeContactsErrorMessage, changeNetRevenueErrorMessage, changePayFrequencyErrorMessage, changeClientRefErrorMessage, changeStartDateErrorMessage, rateCorrectionErrorMessage, changeJobTitleErrorMessage, earlyTerminateErrorMessage, terminateErrorMessage, convertToPermErrorMessage, changeConsultantsErrorMessage, changePaymentTypeErrorMessage, changeRatesErrorMessage, extendPlacementErrorMessage, exportToAstuteErrorMessage, netRevenueLabel, workflowPartnerActions, canApprovePlacements, canAddEditPlacements, canBackOfficeIntegration]);


    const googleMapsLink = (c: Placement) => {
        const baseUrl = 'https://www.google.com/maps/search/?api=1';
        let address = '';
        if (c.address1) address = c.address1;
        if (c.address2) address += ' ' + c.address2;
        if (c.address3) address += ' ' + c.address3;
        if (c.suburb) address += ' ' + c.suburb;
        if (c.state) address += ' ' + c.state;
        if (c.postcode) address += ' ' + c.postcode;
        if (c.countryName) address += ' ' + c.countryName;

        if (address !== '') {
            return `${baseUrl}&query=${encodeURIComponent(address)}`;
        }
        return null;
    }

    const SummaryBar = useMemo(() => {
        if (placement) {
            const tagsAction = (
                <Avatar
                    key="tagsAction"
                    onClick={canAddEditPlacements ? () => setShowTagsManagement(true) : () => setShowTagsPreviewNoDelay(true)}
                    onMouseEnter={ () => setShowTagsPreview(true) }
                    onMouseLeave={ () => setShowTagsPreview(false) }
                    sx={{ bgcolor: '#f209a6', mr: '5px', cursor: 'pointer' }}>
                    { placement.tags ? <LocalOfferIcon /> : <LocalOfferOutlinedIcon />}
                </Avatar>
            );

            const locationUrl = googleMapsLink(placement);
            const locationAction = locationUrl ? (
                <a href={locationUrl}
                    style={summaryLinkStyle}
                    key="locationAction"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <Avatar sx={{ bgcolor: "#ea4335", mr: '5px' }}>
                        <LocationOnIcon />
                    </Avatar>
                </a>
            ) : <Avatar key="locationPlaceholder" sx={{ mr: '5px' }}><LocationOnIcon /></Avatar>;

            const action1 = canAddEditPlacements && !isPlacementCancelledOrPending && !placement.approved ? (
                <Link key="editLink" to={`/placements/${placementId}/edit`} style={summaryLinkStyle}>
                    <Button variant="contained" color="success" sx={{ mr: '5px' }}>Edit</Button>
                </Link>
            ) : <React.Fragment key="editPlaceholder" />;

            const workflow = !isPlacementCancelledOrPending ?  (
                <Box key="workflowMenu">
                    <ActionMenu color="secondary" label="Workflow" mr="5px" definition={workflowMenuDefinitions} />
                </Box>
            ) : <React.Fragment key="workflowMenuPlaceholder" /> ;

            const action = (
                <Box key="actionsMenu">
                    <ActionMenu definition={actionMenuDefinitions} />
                </Box>
            );

            return (
                <TitleAndActionSummaryBar
                    height="4.5rem"
                    title={placement ? renderSummary(placement) : ''}
                    action={[locationAction, tagsAction, action1, workflow, action]}
                    browserTabTitle={!placement ? '' : placement.jobTitle + " - Placements"}
                />
            );
        }
    }, [placement, canAddEditPlacements, isPlacementCancelledOrPending, placementId, workflowMenuDefinitions, actionMenuDefinitions]);


    const handleTabChange = useCallback((e: React.SyntheticEvent, newValue: string) => {
        navigate(`/placements/${placementId}?tab=${newValue}`);
    }, [placementId, navigate]);

    const deleteRecordCallback = useCallback(async () => {
        setIsLoading(true);
        const res = await DeletePlacementById(placementId, setErrorMessage);
        if (res) setIsRecordDeleted(true);
        setIsLoading(false);
    }, [placementId]);

    const counts = useMemo(() => {
        let counts = {
            relatedPlacements: 0,
            changeRequests: 0,
            financials: 0,
            documents: 0,
            callsNotes: 0,
            callsNotesDate: '\u00A0',
            messages: 0,
            messagesDate: '\u00A0',
            activities: 0,
            activitiesDate: '\u00A0',
            invoices: 0
        };

        if (placement && placement.statisticsCounts && placement.statisticsCounts.length > 0) {
            for (let i = 0; i < placement.statisticsCounts.length; i++) {
                const s = placement.statisticsCounts[i];
                switch (s.type) {
                    case "DocumentsCount": counts.documents = s.value;
                        break;
                    case "RelatedPlacementsCount": counts.relatedPlacements = s.value;
                        break;
                    case "ChangeRequestCount": counts.changeRequests = s.value;
                        break;
                    case "FinancialsCount": counts.financials = s.value;
                        break;
                    case "CallsNotesCount": counts.callsNotes = s.value;
                        break;
                    case "MessagesCount": counts.messages = s.value;
                        break;
                    case "ActivitiesCount": counts.activities = s.value;
                        break;
                    case "InvoicesCount": counts.invoices = s.value;
                        break;
                }
            }
        }
        if (placement && placement.statisticsDates && placement.statisticsDates.length > 0) {
            for (let i = 0; i < placement.statisticsDates.length; i++) {
                const s = placement.statisticsDates[i];
                switch (s.type) {
                    case "CallsNotesDate":
                        const cnDate = moment(s.value);
                        if (cnDate.isValid()) counts.callsNotesDate = cnDate.format('DD MMM YYYY');
                        break;
                    case "MessagesDate":
                        const mDate = moment(s.value);
                        if (mDate.isValid()) counts.messagesDate = mDate.format('DD MMM YYYY');
                        break;
                    case "ActivitiesDate":
                        const aDate = moment(s.value);
                        if (aDate.isValid()) counts.activitiesDate = aDate.format('DD MMM YYYY');
                        break;
                }
            }
        }
        return counts;
    }, [placement]);

    useEffect(() => {
        if (tabActionMenuOptions.length > 0 && activeTab !== 'PLACEMENTS_Home') {
            setTabActionMenuOptions([]);
        }
    }, [activeTab, tabActionMenuOptions]);

    const tagManagementSuccessHandler = useCallback((message: string, recordIds: number[], finalTagCount: number) => {
        setSuccessMessage(message);
    }, []);

    const selectedActivityData = useMemo<Activity | null>(() => {
        if (placement && selectedActivity && selectedActivityRecipientType) {
            if (selectedActivityRecipientType === 'billing-contact') {
                return {
                    ...DefaultActivity,
                    clientID: placement.clientID,
                    clientName: placement.clientName,
                    contactID: placement.billingContactContactID,
                    contactName: placement.billingContactName,
                    placementID: placement.id,
                    jobTitle: placement.jobTitle,
                    type: selectedActivity.customName,
                    typeID: selectedActivity.id,
                    category: selectedActivity.category
                };
            }
            if (selectedActivityRecipientType === 'candidate') {
                return {
                    ...DefaultActivity,
                    candidateID: placement.candidateID,
                    candidateName: placement.candidateName,
                    placementID: placement.id,
                    jobTitle: placement.jobTitle,
                    type: selectedActivity.customName,
                    typeID: selectedActivity.id,
                    category: selectedActivity.category
                };
            }
            if (selectedActivityRecipientType === 'contact') {
                return {
                    ...DefaultActivity,
                    clientID: placement.clientID,
                    clientName: placement.clientName,
                    contactID: placement.contactID,
                    contactName: placement.contactName,
                    placementID: placement.id,
                    jobTitle: placement.jobTitle,
                    type: selectedActivity.customName,
                    typeID: selectedActivity.id,
                    category: selectedActivity.category
                };
            }
            if (selectedActivityRecipientType === 'hiring-manager') {
                return {
                    ...DefaultActivity,
                    clientID: placement.clientID,
                    clientName: placement.clientName,
                    contactID: placement.hiringManagerContactID,
                    contactName: placement.hiringManagerName,
                    placementID: placement.id,
                    jobTitle: placement.jobTitle,
                    type: selectedActivity.customName,
                    typeID: selectedActivity.id,
                    category: selectedActivity.category
                };
            }
        }
        return null;
    }, [placement, selectedActivity, selectedActivityRecipientType]);

    const changeApproversSuccessHandler = useCallback((m: string, id1: number, name1: string, id2: number, name2: string) => {
        setSuccessMessage(m);
        setPlacement(prev => prev ? {...prev, timesheetApprover1: id1, timesheetApprover1Name: name1, timesheetApprover2: id2, timesheetApprover2Name: name2} : prev);
        setLatestPlacement(prev => prev ? {...prev, timesheetApprover1: id1, timesheetApprover1Name: name1, timesheetApprover2: id2, timesheetApprover2Name: name2} : prev);
    }, []);

    const changePayFrequencySuccessHandler = useCallback((m: string, payFreqId: number) => {
        setSuccessMessage(m);
        setPlacement(prev => prev ? {...prev, payFrequencyID: payFreqId} : prev);
        setLatestPlacement(prev => prev ? {...prev, payFrequencyID: payFreqId} : prev);
    }, []);

    const changeContactsSuccessHandler = useCallback((m: string, cId: number, cName: string, hId: number, hName: string, b1Id: number, b1Name: string, b2Id: number, b2Name: string, b3Id: number, b3Name: string, sId: number, sName: string) => {
        setSuccessMessage(m);
        setPlacement(prev => prev ? {...prev, contactID: cId, contactName: cName, hiringManagerContactID: hId, hiringManagerName: hName, billingContactContactID: b1Id, billingContactName: b1Name, billingContact2ContactID: b2Id, billingContact2Name: b2Name, billingContact3ContactID: b3Id, billingContact3Name: b3Name, signatoryContactID: sId, signatoryName: sName } : prev);
        setLatestPlacement(prev => prev ? {...prev, contactID: cId, contactName: cName, hiringManagerContactID: hId, hiringManagerName: hName, billingContactContactID: b1Id, billingContactName: b1Name, billingContact2ContactID: b2Id, billingContact2Name: b2Name, billingContact3ContactID: b3Id, billingContact3Name: b3Name, signatoryContactID: sId, signatoryName: sName } : prev);
    }, []);

    const changeClientRefSuccessHandler = useCallback((m: string, ref: string) => {
        setSuccessMessage(m);
        setPlacement(prev => prev ? {...prev, clientReference: ref} : prev);
        setLatestPlacement(prev => prev ? {...prev, clientReference: ref} : prev);
    }, []);

    const changeJobTitleSuccessHandler = useCallback((m: string, jobTitle: string) => {
        setSuccessMessage(m);
        setPlacement(prev => prev ? {...prev, jobTitle: jobTitle} : prev);
        setLatestPlacement(prev => prev ? {...prev, jobTitle: jobTitle} : prev);
    }, []);

    const changeNetRevenueSuccessHandler = useCallback((m: string, n: number) => {
        setSuccessMessage(m);
        setPlacement(prev => prev ? {...prev, netRevenue: n} : prev);
        setLatestPlacement(prev => prev ? {...prev, netRevenue: n} : prev);
    }, []);

    const successHandlerWithRefreshData = useCallback(async (m: string) => {
        setSuccessMessage(m);
        const p = await GetPlacementById(placementId);
        if (p) setPlacement(p);

        const lp = await GetLatestPlacementById(placementId);

        if(lp) {
            setLatestPlacement(lp);
        }

    }, [placementId]);

    const closePaymentTypeOrRatesDialogCallback = useCallback(() => {
        setShowChangePaymentTypeDialog(false);
        setShowChangeRatesDialog(false);
        setShowExtendPlacementDialog(false);
        setShowRateCorrectionDialog(false);
    }, []);

    const activityCreatedSuccessCallback = useCallback((message: string) => {
        setSuccessMessage(message);
        setRefreshActivitiesGridControl(prev => !prev);
    }, []);

    useEffect(() => {
        if (placement && defaultTab && !tabSetupFinished) {
            const accessRestrictedTabs = ['PLACEMENTS_CallsNotes', 'PLACEMENTS_Activities'];
            setActiveTab(prev => {
                if (accessRestrictedTabs.includes(defaultTab) && !canAccessActivities) return prev;
                if (defaultTab === 'Compliance' && !(compliance && placement.complianceChecklistID !== 0)) return prev;
                return defaultTab;
            });
            setTabSetupFinished(true);
        }
    }, [placement, defaultTab, canAccessActivities, compliance, tabSetupFinished]);

    const cancelSuccessHandler = useCallback((message: string) => {
        setSuccessMessage(message);
        setPlacement(prev => prev ? ({ ...prev, placementStatusID: 12, placementStatusName: 'Cancelled' }) : prev);
        setLatestPlacement(prev => prev ? ({ ...prev, placementStatusID: 12, placementStatusName: 'Cancelled' }) : prev);
    }, []);

    return (
        <>
            <Snackbar open={successMessage !== ''} autoHideDuration={3000} onClose={() => setSuccessMessage('')}>
                <Alert onClose={() => setSuccessMessage('')}>{ successMessage }</Alert>
            </Snackbar>
            <Snackbar open={isRecordDeleted} autoHideDuration={1200} onClose={() => navigate('/placements/')}>
                <Alert onClose={() => navigate('/placements/')}>Record deleted</Alert>
            </Snackbar>
            <Snackbar open={errorMessage !== ''} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <PageLayout paddingTop={0} SummaryBar={SummaryBar}>
                <PlacementCandidateConfirmationDialog
                    open={showCandidateConfirmationDialog}
                    closeHandler={() => setShowCandidateConfirmationDialog(false)}
                    sendViaSmsHandler={() => sendSmsCallback('candidate')}
                    sendViaEmailHandler={() => sendEmailCallback('candidate')}
                />
                <SendOnboardingDialog
                    open={ onboardingRecipient !== null }
                    sourceEntityId={entityTypeId}
                    jobId={placement ? placement.jobID : 0}
                    recipients={ onboardingRecipient ? [onboardingRecipient] : [] }
                    recipientEntityTypeId={ contactEntityType }
                    closeHandler={ () => setOnboardingRecipient(null) }
                    loadingHandler={setIsLoading}
                    errorHandler={setErrorMessage}
                    successHandler={setSuccessMessage}
                />
                <SendEmailDialog
                    open={ emailRecipient !== null }
                    sourceEntityId={entityTypeId}
                    isPlacementList
                    allowCcField
                    jobId={placement ? placement.jobID : 0}
                    recipients={ emailRecipient ? [emailRecipient] : [] }
                    recipientEntityTypeId={ contactEntityType }
                    closeHandler={ () => setEmailRecipient(null) }
                    loadingHandler={setIsLoading}
                    errorHandler={setErrorMessage}
                    successHandler={setSuccessMessage}
                />
                <SendSmsDialog
                    open={ smsRecipient !== null }
                    sourceEntityId={entityTypeId}
                    placementId={placementId}
                    recipients={ smsRecipient ? [smsRecipient] : [] }
                    recipientEntityTypeId={ contactEntityType }
                    closeHandler={ () => setSmsRecipient(null) }
                    loadingHandler={setIsLoading}
                    errorHandler={setErrorMessage}
                    successHandler={setSuccessMessage}
                />
                <ConfirmationDialog
                    message="Are you sure you want to delete this record?"
                    onClose={ () => setShowDeleteDialog(false) }
                    onContinue={ deleteRecordCallback }
                    open={showDeleteDialog}
                    title="Confirm Action"
                    confirmActionText="Yes"
                    cancelActionText="No"
                />
                <ConfirmationDialog
                    message="Are you sure you want to approve this placement?"
                    onClose={ () => setShowApproveDialog(false) }
                    onContinue={ approvePlacementCallback }
                    open={showApproveDialog}
                    title="Approve Placement"
                    confirmActionText="Yes"
                    cancelActionText="No"
                />
                <ConfirmationDialog
                    message="Are you sure you want to revoke approval for this placement?"
                    onClose={ () => setShowRevokeDialog(false) }
                    onContinue={ revokeApprovalCallback }
                    open={showRevokeDialog}
                    title="Revoke Placement Approval"
                    confirmActionText="Yes"
                    cancelActionText="No"
                />
                <ConfirmationDialog
                    message="Are you sure you want to Push to Astute?"
                    onClose={ () => setShowExportToAstuteDialog(false) }
                    onContinue={ exportToAsuteCallback }
                    open={showExportToAstuteDialog}
                    title="Confirm Action"
                    confirmActionText="Yes"
                    cancelActionText="No"
                />
                <ConfirmationDialog
                    message="Are you sure you want to Refresh data to Astute?"
                    onClose={ () => setShowRefreshToAstuteDialog(false) }
                    onContinue={ refreshToAsuteCallback }
                    open={showRefreshToAstuteDialog}
                    title="Confirm Action"
                    confirmActionText="Yes"
                    cancelActionText="No"
                />
                <CancelPlacementDialog
                    open={showCancelDialog}
                    placementId={placementId}
                    candidateName={placement ? placement.candidateName : ''}
                    jobTitle={placement ? placement.jobTitle : ''}
                    closeHandler={ () => setShowCancelDialog(false) }
                    errorHandler={setErrorMessage}
                    loadingHandler={setIsLoading}
                    successHandler={cancelSuccessHandler}
                />
                <ChangeApproversDialog
                    open={showChangeApproversDialog}
                    placementId={placementId}
                    placementStatusId={placement ? placement.placementStatusID : 0}
                    approver1Id={placement ? placement.timesheetApprover1 : 0}
                    approver1Name={placement ? placement.timesheetApprover1Name : ''}
                    approver2Id={placement ? placement.timesheetApprover2 : 0}
                    approver2Name={placement ? placement.timesheetApprover2Name ?? '' : ''}
                    closeHandler={() => setShowChangeApproversDialog(false)}
                    errorHandler={setErrorMessage}
                    loadingHandler={setIsLoading}
                    successHandler={changeApproversSuccessHandler}
                />
                <ChangeContactsDialog
                    open={showChangeContactsDialog}
                    placementId={placementId}
                    placementStatusId={placement ? placement.placementStatusID : 0}
                    contactId={placement ? placement.contactID : 0}
                    contactName={placement ? placement.contactName : ''}
                    hiringManagerId={placement ? placement.hiringManagerContactID : 0}
                    hiringManagerName={placement ? placement.hiringManagerName : ''}
                    billing1Id={placement ? placement.billingContactContactID : 0}
                    billing1Name={placement ? placement.billingContactName ?? '' : ''}
                    billing2Id={placement ? placement.billingContact2ContactID : 0}
                    billing2Name={placement ? placement.billingContact2Name ?? '' : ''}
                    billing3Id={placement ? placement.billingContact3ContactID : 0}
                    billing3Name={placement ? placement.billingContact3Name ?? '' : ''}
                    signatoryId={placement ? placement.signatoryContactID : 0}
                    signatoryName={placement ? placement.signatoryName ?? '' : ''}
                    closeHandler={() => setShowChangeContactsDialog(false)}
                    errorHandler={setErrorMessage}
                    loadingHandler={setIsLoading}
                    successHandler={changeContactsSuccessHandler}
                />
                <ChangeNetRevenueDialog
                    open={showChangeNetRevenueDialog}
                    placementId={placementId}
                    netRevenue={placement ? placement.netRevenue : 0}
                    netRevenueLabel={netRevenueLabel}
                    closeHandler={() => setShowChangeNetRevenueDialog(false)}
                    errorHandler={setErrorMessage}
                    loadingHandler={setIsLoading}
                    successHandler={changeNetRevenueSuccessHandler}
                />
                <ChangePayFrequencyDialog
                    open={showChangePayFrequencyDialog}
                    placementId={placementId}
                    placementStatusId={placement ? placement.placementStatusID : 0}
                    payFrequency={placement ? placement.payFrequencyID : 0}
                    closeHandler={() => setShowChangePayFrequencyDialog(false)}
                    errorHandler={setErrorMessage}
                    loadingHandler={setIsLoading}
                    successHandler={changePayFrequencySuccessHandler}
                />
                <ChangeClientRefDialog
                    open={showChangeClientRefDialog}
                    placementId={placementId}
                    placementStatusId={placement ? placement.placementStatusID : 0}
                    clientRef={placement && placement.clientReference ? placement.clientReference : ''}
                    closeHandler={() => setShowChangeClientRefDialog(false)}
                    errorHandler={setErrorMessage}
                    loadingHandler={setIsLoading}
                    successHandler={changeClientRefSuccessHandler}
                    />
                <ChangeStartDateDialog
                    open={showChangeStartDateDialog}
                    placementId={placementId}
                    startDate={placement && placement.startDate ? placement.startDate : ''}
                    endDate={placement && placement.endDate ? placement.endDate : ''}
                    rateId={latestRate ? latestRate.id : 0}
                    isPendingChanges={placement ? placement.isChangeRequestPending : false}
                    closeHandler={() => setShowChangeStartDateDialog(false)}
                    errorHandler={setErrorMessage}
                    loadingHandler={setIsLoading}
                    successHandler={setSuccessMessage}
                />
                <ChangeJobTitleDialog
                    open={showChangeJobTitleDialog}
                    placementId={placementId}
                    jobTitle={placement && placement.jobTitle ? placement.jobTitle : ''}
                    closeHandler={() => setShowChangeJobTitleDialog(false)}
                    errorHandler={setErrorMessage}
                    loadingHandler={setIsLoading}
                    successHandler={changeJobTitleSuccessHandler}
                />
                <EarlyTerminateDialog
                    open={showEarlyTerminateDialog}
                    placementId={placementId}
                    placementStatusId={placement ? placement.placementStatusID : 0}
                    jobTitle={placement && placement.jobTitle ? placement.jobTitle : ''}
                    startDate={placement && placement.startDate ? placement.startDate : ''}
                    endDate={placement && placement.endDate ? placement.endDate : ''}
                    candidateName={placement && placement.candidateName ? placement.candidateName : ''}
                    closeHandler={() => setShowEarlyTerminateDialog(false)}
                    errorHandler={setErrorMessage}
                    loadingHandler={setIsLoading}
                    successHandler={successHandlerWithRefreshData}
                />
                <EarlyTerminateDialog
                    open={showTerminateDialog}
                    placementId={placementId}
                    jobTitle={placement && placement.jobTitle ? placement.jobTitle : ''}
                    startDate={placement && placement.startDate ? placement.startDate : ''}
                    endDate={placement && placement.endDate ? placement.endDate : ''}
                    candidateName={placement && placement.candidateName ? placement.candidateName : ''}
                    closeHandler={() => setShowTerminateDialog(false)}
                    errorHandler={setErrorMessage}
                    loadingHandler={setIsLoading}
                    successHandler={successHandlerWithRefreshData}
                />
                <ConvertToPermDialog
                    open={showConvertToPermDialog}
                    placementId={placementId}
                    rate={latestRate ?? null}
                    isPendingChanges={placement ? placement.isChangeRequestPending : false}
                    closeHandler={() => setShowConvertToPermDialog(false)}
                    errorHandler={setErrorMessage}
                    loadingHandler={setIsLoading}
                    successHandler={successHandlerWithRefreshData}
                />
                <ChangeConsultantsDialog
                    open={showChangeConsultantsDialog}
                    placementId={placementId}
                    rate={latestRate ?? null}
                    isPendingChanges={placement ? placement.isChangeRequestPending : false}
                    ratesCalculatorLink={ratesCalculatorLink}
                    closeHandler={() => setShowChangeConsultantsDialog(false)}
                    errorHandler={setErrorMessage}
                    loadingHandler={setIsLoading}
                    successHandler={setSuccessMessage}
                />
                <ChangePaymentTypeDialog
                    open={Boolean(changePaymentTypeOrRatesDialogType)}
                    changeType={changePaymentTypeOrRatesDialogType}
                    placementId={placementId}
                    placementStatusId={latestPlacement ? latestPlacement.placementStatusID : 0}
                    isPendingChanges={latestPlacement ? latestPlacement.isChangeRequestPending : false}
                    rate={latestRate ?? null}
                    placement={latestPlacement ?? null}
                    ratesCalculatorLink={ratesCalculatorLink}
                    closeHandler={closePaymentTypeOrRatesDialogCallback}
                    errorHandler={setErrorMessage}
                    loadingHandler={setIsLoading}
                    successHandler={successHandlerWithRefreshData}
                />
                <CreateInvoiceDialog
                    closeHandler={() => setShowCreateInvoiceDialog(false)}
                    placementId={placementId}
                    consultant1Id={placement ? placement.consultantID1 : 0}
                    consultant2Id={placement ? placement.consultantID2 : 0}
                    consultant3Id={placement ? placement.consultantID3 : 0}
                    consultant4Id={placement ? placement.consultantID4 : 0}
                    consultant5Id={placement ? placement.consultantID5 : 0}
                    consultant1Percent={placement ? placement.consultantPercentage1 : 0}
                    consultant2Percent={placement ? placement.consultantPercentage2 : 0}
                    consultant3Percent={placement ? placement.consultantPercentage3 : 0}
                    consultant4Percent={placement ? placement.consultantPercentage4 : 0}
                    consultant5Percent={placement ? placement.consultantPercentage5 : 0}
                    open={showCreateInvoiceDialog}
                    loadingHandler={setIsLoading}
                    errorHandler={setErrorMessage}
                    successHandler={onCreateInvoiceSuccess}
                /> 
                <EditActivityDialog
                    closeHandler={closeActivityDialogHandler}
                    data={selectedActivityData}
                    open={ Boolean(selectedActivity) }
                    loadingHandler={setIsLoading}
                    errorHandler={setErrorMessage}
                    successHandler={activityCreatedSuccessCallback}
                />
                <PreviewLoaderComponent
                    open={showTagsPrevew}
                    entityType="placement"
                    recordId={placementId}
                    showDelayMs={showTagsPrevewNoDelay ? 0 : undefined}
                    isTagsPreview
                />
                <TagsManagementDialog
                    open={showTagsManagement}
                    entityId={5}
                    recordIds={placementIdArray}
                    closeHandler={ () => setShowTagsManagement(false) }
                    loadingHandler={ setIsLoading }
                    errorHandler={ setErrorMessage }
                    successHandler={ tagManagementSuccessHandler }
                />
                <GenerateDocumentDialog
                    open={Boolean(generateDocumentType)}
                    entityId={entityTypeId}
                    playerId={placementId}
                    placementDocType={generateDocumentType}
                    closeHandler={() => setGenerateDocumentType('')}
                    loadingHandler={ setIsLoading }
                    errorHandler={ setErrorMessage }
                    successHandler={ setSuccessMessage }
                />
                <Tabs value={activeTab} onChange={handleTabChange}>
                    <Tab value="PLACEMENTS_Home" label={<>Home<br />&nbsp;</>} />
                    <Tab value="PLACEMENTS_ChangeRequests" label={<>Change Requests ({counts.changeRequests})<br />&nbsp;</>} />
                    <Tab value="PLACEMENTS_Financials" label={<>Financials ({counts.financials})<br />&nbsp;</>} />
                    <Tab value="PLACEMENTS_RelatedPlacements" label={<>Related Placements ({counts.relatedPlacements})<br />&nbsp;</>} />
                    {canAccessActivities && <Tab value="PLACEMENTS_CallsNotes" label={<>Calls/Notes ({counts.callsNotes})<br />{counts.callsNotesDate}</>} />}
                    {canAccessActivities && <Tab value="Messages" label={<>Messages ({counts.messages})<br />{counts.messagesDate}</>} />}
                    {compliance && placement && placement.complianceChecklistID !== 0 && <Tab value="Compliance" label={<>Compliance<br />&nbsp;</>} />}
                    {/*<Tab value="Invoices" label={<>Invoices ({counts.invoices})<br />&nbsp;</>} />*/}
                    <Tab value="PLACEMENTS_Documents" label={<>Documents ({counts.documents})<br />&nbsp;</>} />
                    {canAccessActivities && <Tab value="PLACEMENTS_Activities" label={<>Activities ({counts.activities})<br />{counts.activitiesDate}</>} />}
                </Tabs>
                { Boolean(callToActionTitle) &&
                    <CallOutNotification
                        type="info"
                        title={callToActionTitle}
                        onClose={() => setCallToActionTitle('')}
                    />
                }
                <PageContentLayout showLoading={isLoading || isFetchingDefaultTab || isLoadingTab}>
                    <ShowIframeDialog open={showPartnerActionPopup} closeHandler={() => setShowPartnerActionPopup(false)} title={popupTitle} url={popupUrl} />
                    <ShowIframeDialog open={showWorkflowPartnerActionPopup} closeHandler={() => setShowWorkflowPartnerActionPopup(false)} title={popupTitle2} url={popupUrl2} />
                    { isFinishedSetup && activeTab === "PLACEMENTS_Home" &&
                        <ViewRecordScreenLayout
                            placement={placement ? placement : null}
                            isSeperatePayBillDivision={isSeperatePayBillDivision}
                            netRevenueLabel={netRevenueLabel}
                            loadingHandler={setIsLoadingTab}
                            errorHandler={setErrorMessage}
                        />
                    }
                    { isFinishedSetup && compliance && placement && placement.complianceChecklistID !== 0 && activeTab === "Compliance" &&
                        <ComplianceGridComponent
                            gridName="placements/Compliance"
                            candidateId={placement.candidateID}
                            placementId={placementId}
                            checklistId={placement.complianceChecklistID}
                            loadingHandler={setIsLoadingTab}
                            errorHandler={setErrorMessage}
                            successHandler={setSuccessMessage}
                        />
                    }
                    { isFinishedSetup && activeTab === "PLACEMENTS_ChangeRequests" &&
                        <ChangeRequestsGridComponent
                            gridName="placements/change-requests"
                            source="placement-record"
                            placementId={placementId}
                            loadingHandler={setIsLoading}
                            errorHandler={setErrorMessage}
                            successHandler={setSuccessMessage}
                        />
                    }
                    { isFinishedSetup && activeTab === "PLACEMENTS_Financials" &&
                        <FinancialsGridComponent
                            gridName="placements/financials"
                            source="placement-record"
                            placementId={placementId}
                            loadingHandler={setIsLoading}
                            errorHandler={setErrorMessage}
                            successHandler={setSuccessMessage}
                        />
                    }
                    { isFinishedSetup && canAccessActivities && activeTab === "PLACEMENTS_CallsNotes" &&
                        <ActivitiesGridComponent
                            source="placement-record"
                            sourceId={placementId}
                            categoryFilter={1}
                            loadingHandler={ setIsLoadingTab }
                            errorHandler={ setErrorMessage }
                            successHandler={ setSuccessMessage }
                            gridName="placement/CallsNotes"
                            refreshControl={refreshActivitiesGridControl}
                            hideActionsMenu
                        />
                    }
                    {isFinishedSetup && canAccessActivities && activeTab === "Messages" &&
                        <ActivityMessagesGridComponent
                            source="placement-record"
                            sourceId={placementId}
                            errorHandler={setErrorMessage}
                            loadingHandler={setIsLoadingTab}
                            successHandler={setSuccessMessage}
                            gridName="placements/Messages"
                        />
                    }
                    { isFinishedSetup && canAccessPlacements && activeTab === "PLACEMENTS_RelatedPlacements" &&
                        <PlacementsGridComponent
                            source="placement-record"
                            sourceId={placementId}
                            loadingHandler={setIsLoadingTab}
                            errorHandler={setErrorMessage}
                            successHandler={setSuccessMessage}
                            gridName="placement/Placements"
                        />
                    }
                    {isFinishedSetup && activeTab === "Invoices" &&
                        <InvoicesGridComponent
                            source="placement-record"
                            sourceId={placementId}
                            loadingHandler={setIsLoadingTab}
                            errorHandler={setErrorMessage}
                            successHandler={setSuccessMessage}
                            gridName="placements/invoices"
                        />
                    }
                    { isFinishedSetup && activeTab === "PLACEMENTS_Documents" &&
                        <DocumentsGridComponent
                            gridName="placement/Documents"
                            source="placement-record"
                            sourceId={placementId}
                            loadingHandler={setIsLoadingTab}
                            errorHandler={setErrorMessage}
                            successHandler={setSuccessMessage}
                        />
                    }
                    { isFinishedSetup && canAccessActivities && activeTab === "PLACEMENTS_Activities" &&
                        <ActivitiesGridComponent
                            source="placement-record"
                            sourceId={placementId}
                            loadingHandler={ setIsLoadingTab }
                            errorHandler={ setErrorMessage }
                            successHandler={ setSuccessMessage }
                            gridName="placement/Activities"
                            refreshControl={refreshActivitiesGridControl}
                            hideActionsMenu
                        />
                    }
                </PageContentLayout>
            </PageLayout>
        </>
    );
}