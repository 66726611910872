import { useCallback, useEffect, useMemo, useState } from "react"
import { ChangeTracker, ChangeTrackerResult, ValidationFunctions, ValidationTracker } from "../common/models/hooks/ChangeTracker";

export default function useObjectStateWithChangeTracker<T>(
    defaultState: T,
    noChanges: ChangeTracker<T>,
    validationFuncs?: ValidationFunctions<T>,
    defaultValidationTracker?: ValidationTracker<T>
): ChangeTrackerResult<T> {

    const [state, setState] = useState<T>(defaultState);
    const [initialState, setInitialState] = useState<T>(defaultState);
    const [changeTracker, setChangeTracker] = useState<ChangeTracker<T>>(noChanges);
    const [validationTracker, setValidationTracker] = useState<ValidationTracker<T> | undefined>(undefined);
    const [isValid, setIsValid] = useState(true);
    const [isValidationActive, setIsValidationActive] = useState(false);

    useEffect(() => {
        if (validationFuncs && defaultValidationTracker && isValidationActive) {

            const keys = Object.keys(validationFuncs) as Array<keyof T>;
            let vTracker = {...defaultValidationTracker};
            let isValid = true;
            for (let i = 0; i < keys.length; i++) {
                const k = keys[i];
                vTracker[k] = validationFuncs[k](state[k] + '');
                if(vTracker[k] !== '') isValid = false;
            }

            setIsValid(isValid);
            setValidationTracker(vTracker);
        }
    }, [validationFuncs, defaultValidationTracker, isValidationActive, state]);

    const init = useCallback((state: T) => {
        setState(state);
        setInitialState(state);
        setChangeTracker(noChanges);
    }, [noChanges]);

    const updateInitial = useCallback(() => {
        setInitialState(state);
        setChangeTracker(noChanges);
        setValidationTracker(defaultValidationTracker);
    }, [noChanges, state, defaultValidationTracker]);

    const change = useCallback((name: keyof T, value: T[keyof T]) => {
        const initial = initialState[name];
        setState(prev => ({...prev, [name]: value}));
        setChangeTracker(prev => ({...prev, [name]: initial !== value}));
        setIsValidationActive(true);
    }, [initialState]);

    const hasChanges = useMemo(() => {
        for(const key in changeTracker) {
            const element = changeTracker[key];
            if (element) return true;
        }
        return false;
    }, [changeTracker]);

    return {
        state,
        init,
        change,
        updateInitial,
        hasChanges: hasChanges,
        validationMessages: validationTracker,
        isValid
    };
}