import moment from "moment";

export const FormatMonthDigit = (value: number) => {
    return value.toLocaleString('en-US', { maximumFractionDigits: 0, minimumFractionDigits: 0, minimumIntegerDigits: 2 });
};

export const GetFinancialQuarterMonths = (currentMonth: number, financialYearStartMonth: number) =>  {
    const q1: number[] = [];
    const q2: number[] = [];
    const q3: number[] = [];
    const q4: number[] = [];

    for (let i = 0; i < 12; i++) {
        const s = financialYearStartMonth + i;
        const v = s > 12 ? s - 12 : s;
        if (i < 3) q1.push(v);
        else if (i < 6) q2.push(v);
        else if (i < 9) q3.push(v);
        else q4.push(v);
    }

    if (q1.includes(currentMonth)) return q1;
    if (q2.includes(currentMonth)) return q2;
    if (q3.includes(currentMonth)) return q3;
    if (q4.includes(currentMonth)) return q4;
    return [];
};

export const GetDatesFromTimeRangeFilter = (tr: number, financialYearStartMonth: number = 1) => {
    let start: moment.Moment | null = null;
    let end: moment.Moment | null = null;
    if (tr === 0) return;
    if (tr === 1) { // This Week
        start = moment().startOf('isoWeek');
        end = moment().endOf('isoWeek');
    }
    else if (tr === 2) { // This Month
        start = moment().startOf('month');
        end = moment().endOf('month');
    }
    else if (tr === 3) { // This Quarter (Calendar)
        start = moment().startOf('quarter');
        end = moment().endOf('quarter');
    }
    else if (tr === 4) { // This Year (Calendar)
        start = moment().startOf('year');
        end = moment().endOf('year');
    }
    else if (tr === 5) { // Last Week
        start = moment().subtract(1,'week').startOf('isoWeek');
        end = moment().subtract(1,'week').endOf('isoWeek');
    }
    else if (tr === 6) { // Last Month
        start = moment().subtract(1, 'months').startOf('month');
        end = moment().subtract(1, 'months').endOf('month');
    }
    else if (tr === 7) { // Last Quarter (Calendar)
        start = moment().subtract(1, 'quarter').startOf('quarter');
        end = moment().subtract(1, 'quarter').endOf('quarter');
    }
    else if (tr === 8) { // Last Year (Calendar)
        const lastYear = moment().get('year') - 1;
        start = moment(`${lastYear}-01-01`);
        end = moment(`${lastYear}-12-31`);
    }
    else if (tr === 9) { // Last 7 Days
        start = moment().subtract(6, 'days');
        end = moment();
    }
    else if (tr === 10) { // Last 30 Days
        start = moment().subtract(30, 'days');
        end = moment();
    }
    else if (tr === 11) { // Last 90 Days
        start = moment().subtract(90, 'days');
        end = moment();
    }
    else if (tr === 12) { // Last 365 Days
        start = moment().subtract(365, 'days');
        end = moment();
    }
    else if (tr === 13) { // This Quarter (Financial)
        const currentMonth = moment().get('month') + 1;
        const currentYear = moment().get('year');

        const q = GetFinancialQuarterMonths(currentMonth, financialYearStartMonth);
        const m1 = q[0];
        const m3 = q[2];
        const startYearOffset = m3 < m1 && currentMonth <= m3 ? 1 : 0;
        const endYearOffset = m3 < m1 && currentMonth > m3 ? 1 : 0;
        start = moment(`${currentYear - startYearOffset}-${FormatMonthDigit(m1)}-01`);
        end = moment(`${currentYear + endYearOffset}-${FormatMonthDigit(m3)}-01`).endOf('month');
    }
    else if (tr === 14) { // This Year (Financial)
        const currentMonth = moment().get('month') + 1;
        const currentYear = moment().get('year');
        const endMonth = financialYearStartMonth === 1 ? 12 : financialYearStartMonth - 1;
        const yearOffset = financialYearStartMonth === 1 ? 0 : 1;
        if (currentMonth < financialYearStartMonth) {
            start = moment(`${currentYear - yearOffset}-${FormatMonthDigit(financialYearStartMonth)}-01`);
            end = moment(`${currentYear}-${FormatMonthDigit(endMonth)}-01`).endOf('month');
        }
        else {
            start = moment(`${currentYear}-${FormatMonthDigit(financialYearStartMonth)}-01`);
            end = moment(`${currentYear + yearOffset}-${FormatMonthDigit(endMonth)}-01`).endOf('month');
        }
    }
    else if (tr === 15) { // Last Quarter (Financial)
        const threeMonthsAgo = moment().subtract(3, 'months');
        const monthThreeMonthsAgo = threeMonthsAgo.get('month') + 1;
        const yearThreeMonthsAgo = threeMonthsAgo.get('year');

        const q = GetFinancialQuarterMonths(monthThreeMonthsAgo, financialYearStartMonth);
        const m1 = q[0];
        const m3 = q[2];
        const startYearOffset = m3 < m1 && monthThreeMonthsAgo <= m3 ? 1 : 0;
        const endYearOffset = m3 < m1 && monthThreeMonthsAgo > m3 ? 1 : 0;
        start = moment(`${yearThreeMonthsAgo - startYearOffset}-${FormatMonthDigit(m1)}-01`);
        end = moment(`${yearThreeMonthsAgo + endYearOffset}-${FormatMonthDigit(m3)}-01`).endOf('month');
    }
    else if (tr === 16) { // Last Year (Financial)
        const currentMonth = moment().get('month') + 1;
        const lastYear = moment().get('year') - 1;
        const endMonth = financialYearStartMonth === 1 ? 12 : financialYearStartMonth - 1;
        const yearOffset = financialYearStartMonth === 1 ? 0 : 1;
        if (currentMonth < financialYearStartMonth) {
            start = moment(`${lastYear - yearOffset}-${FormatMonthDigit(financialYearStartMonth)}-01`);
            end = moment(`${lastYear}-${FormatMonthDigit(endMonth)}-01`).endOf('month');
        }
        else {
            start = moment(`${lastYear}-${FormatMonthDigit(financialYearStartMonth)}-01`);
            end = moment(`${lastYear + yearOffset}-${FormatMonthDigit(endMonth)}-01`).endOf('month');
        }
    }
    return [start, end];
};