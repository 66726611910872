import React, { useCallback, useEffect, useMemo, useState } from "react";
import ConfirmationDialog from "../Generic/ConfirmationDialog";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import ContactPicker from "components/Pickers/ContactPicker";
import TextField from "@mui/material/TextField";
import moment from "moment";
import { ChangePlacementApprovers } from "services/PlacementsService";
import { PlacementStatusEnum } from "util/Definitions/Configuration/Placements";
import { Contact } from "common/models/Contacts";

interface Props {
    open: boolean,
    placementId: number,
    approver1Id?: number,
    approver2Id?: number,
    approver1Name?: string,
    approver2Name?: string,
    placementStatusId?: number,
    closeHandler: () => void,
    loadingHandler?: (isLoading: boolean) => void,
    successHandler?: (message: string, id1: number, name1: string, id2: number, name2: string) => void,
    errorHandler?: (message: string) => void
}

export default function ChangeApproversDialog({ open, placementId, placementStatusId = 0, approver1Id: approver1IdProp = 0, approver1Name: approver1NameProp = '', approver2Id: approver2IdProp = 0, approver2Name: approver2NameProp = '', closeHandler, loadingHandler, successHandler, errorHandler }: Props) {
    const [showWarning, setShowWarning] = useState(false);
    const [approver1Id, setApprover1Id] = useState(0);
    const [approver2Id, setApprover2Id] = useState(0);
    const [approver1Name, setApprover1Name] = useState('');
    const [approver2Name, setApprover2Name] = useState('');

    const warningText = useMemo(() => {
        if (placementStatusId === PlacementStatusEnum.Completed) return 'This placement is already Completed. Are you sure you want to make this change?';
        if (placementStatusId === PlacementStatusEnum.PendingEarlyTermination || placementStatusId === PlacementStatusEnum.EarlyTerminated) return 'This placement has been Early Terminated. Are you sure you want to make this change?';
        return '';
    }, [placementStatusId]);

    useEffect(() => {
        if (open && Boolean(warningText)) setShowWarning(true);
    }, [open, warningText]);

    useEffect(() => {
        if (open) {
            setApprover1Id(approver1IdProp);
            setApprover2Id(approver2IdProp);
            setApprover1Name(approver1NameProp);
            setApprover2Name(approver2NameProp);
        }
    }, [open, approver1IdProp, approver2IdProp, approver1NameProp, approver2NameProp]);

    const effectiveDate = useMemo(() => {
        return moment().format('DD MMM YYYY');
    }, []);

    const submitCallback = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        const res = await ChangePlacementApprovers(placementId, approver1Id, approver2Id, errorHandler);
        if (res) {
            successHandler && successHandler('Approvers Changed', approver1Id, approver1Name, approver2Id, approver2Name);
            closeHandler();
        }
        loadingHandler && loadingHandler(false);
    }, [placementId, approver1Id, approver1Name, approver2Id, approver2Name, loadingHandler, successHandler, errorHandler, closeHandler]);

    const approver1Changed = useCallback((c: Contact | null) => {
        if (c) {
            setApprover1Id(c.id);
            setApprover1Name(c.fullName);
        }
        else {
            setApprover1Id(0);
            setApprover1Name('');
        }
    }, []);

    const approver2Changed = useCallback((c: Contact | null) => {
        if (c) {
            setApprover2Id(c.id);
            setApprover2Name(c.fullName);
        }
        else {
            setApprover2Id(0);
            setApprover2Name('');
        }
    }, []);
    
    return (
        <>
            <ConfirmationDialog
                open={open && showWarning && Boolean(warningText)}
                message={warningText}
                onClose={closeHandler}
                title="Change Approvers"
                onContinue={() => setShowWarning(false)}
            />
            <Dialog open={open && !showWarning} fullWidth>
                <DialogTitle>Change Approvers</DialogTitle>
                <DialogContent>
                    <Stack mt="10px" spacing={2}>
                        <TextField label="Effective Date" value={effectiveDate} InputProps={{ readOnly: true }} />
                        <ContactPicker
                            value={approver1Id}
                            label="Approver 1"
                            disableClearable
                            onSelectCallback={approver1Changed}
                        />
                        <ContactPicker
                            value={approver2Id}
                            label="Approver 2"
                            onSelectCallback={approver2Changed}
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="error" onClick={ closeHandler }>Cancel</Button>
                    <Button variant="contained" color="success" disabled={(approver1Id === approver1IdProp && approver2Id === approver2IdProp) || approver1Id === 0} onClick={ submitCallback } >Submit</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}