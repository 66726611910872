import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import RWTextFieldComponent from "components/RWTextFieldComponent";
import React from "react";

interface Props {
    type: number,
    value: string,
    typeField: string,
    valueField: string,
    onValueChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void,
    onTypeChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void,
}

export default function EditableClientCodeElement({ type, value, typeField, valueField, onValueChangeHandler, onTypeChangeHandler }: Props) {
    return (
        <Box display="flex" height="100%" px="5px">
            <Box height="100%" width="30%" pr="5px">
                <TextField select fullWidth variant="standard" value={type.toString()} name={typeField} onChange={ onTypeChangeHandler }>
                    <MenuItem value="0">None</MenuItem>
                    <MenuItem value="1">ABN</MenuItem>
                    <MenuItem value="2">ACN</MenuItem>
                    <MenuItem value="3">Company #</MenuItem>
                    <MenuItem value="4">VAT #</MenuItem>
                </TextField>
            </Box>
            <Box height="100%" width="70%">
                <RWTextFieldComponent variant="standard" value={value} name={valueField} onChange={ onValueChangeHandler } />
            </Box>
        </Box>
    );
}