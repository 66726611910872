import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { SxProps } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { DownloadDocument, GetDocumentsDetailedReportData } from "services/DocumentsService";
import UserPicker from "components/Pickers/UserPicker";
import { GridColDef, GridRenderCellParams, useGridApiRef } from "@mui/x-data-grid-premium";
import { DocumentReportData } from "common/models/Reports/Documents";
import GridWithStateWrapper from "components/GridWidthStateWrapper";
import { DatePicker } from "@mui/x-date-pickers-pro";
import { NameIdObj } from "common/models/GenericTypes";
import { Document } from "common/models/Document";
import EditDocumentDialog from "components/Dialogs/Documents/EditDocumentDialog";
import { Link } from "react-router-dom";
import { PreviewEntityType } from "common/models/Previews/Previews";
import PreviewLoaderComponent from "components/Previews/PreviewLoader";

interface Props {
    entityTypeId: number,
    description: string,
    loadingHandler?: (isLoading: boolean) => void,
    successHandler?: (message: string) => void,
    errorHandler?: (message: string) => void,
}

const ownerSpecialOptions: NameIdObj[] = [
    { id: 0, name: 'Me' },
    { id: -1, name: 'My Team' },
    { id: -2, name: 'My Company' },
];

const filterElementStyle: SxProps = { flex: '1 1 0', mr: 1 };

interface BasicOption {
    value: string,
    label: string
}

interface Something {
    [entityId: number]: BasicOption[]
}

const CommonDocTypes: BasicOption[] = [
    { label: 'All', value: '0' },
    { label: 'General', value: '1' },
];

const DoctypesByEntityId: Something = {
    1: [
        { label: 'Terms of Business', value: '2' },
        { label: 'Invoices / Receipts', value: '3' },
        { label: 'Report', value: '4' },
    ],
    2: [
        { label: 'Terms of Business', value: '2' },
        { label: 'Report', value: '4' }
    ],
    3: [
        { label: 'Original CV', value: '2'},
        { label: 'Candidate Setup Forms', value: '5'},
        { label: 'Reference Check', value: '6'},
        { label: 'Formatted CV', value: '9'},
        { label: 'ID, Qualifications, Licenses & Certifications', value: '10'},
        { label: 'Visas', value: '11'},
        { label: 'Vetting & Security', value: '12'},
        { label: 'Test Results', value: '13'},
        { label: 'Interview Notes', value: '14'},
        { label: 'Submitted CV', value: '15'},
        { label: 'Safety / Injuries', value: '16'},
        { label: 'Cover Letter', value: '17'},
    ],
    4: [
        { label: 'Reference Check', value: '6'},
        { label: 'Report', value: '7'},
        { label: 'Job Description', value: '8'},
        { label: 'Terms of Business', value: '9'},
    ],
    5: [
        { label: 'Contract - Client', value: '2'},
        { label: 'Contract - Candidate', value: '3'},
        { label: 'Terms of Business', value: '4'},
        { label: 'Candidate Setup Forms', value: '5'},
        { label: 'Contractor Review Form', value: '6'},
        { label: 'Order Forms', value: '7'},
        { label: 'Insurance', value: '8'},
    ]
};

const linkStyle: React.CSSProperties = { color: 'inherit', textDecoration: 'underline' };

export default function DetailedDocumentsReport({ entityTypeId, description, loadingHandler, successHandler, errorHandler }: Props) {
    const [rows, setRows] = useState<DocumentReportData[]>([]);
    const [editDocument, setEditDocument] = useState<Document | null>(null);
    const [showGrid, setShowGrid] = useState(false);
    const [consultantId, setConsultantId] = useState(0);
    const [type, setType] = useState(0);
    const [fromDate, setFromDate] = useState<moment.Moment | null>(null);
    const [toDate, setToDate] = useState<moment.Moment | null>(null);
    const [dateUse, setDateUse] = useState(0);
    const apiRef = useGridApiRef();
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    const [showTagsPrevewNoDelay, setShowTagsPreviewNoDelay] = useState(false);
    const [previewType, setPreviewType] = useState<PreviewEntityType | ''>('');
    const [previewRecordId, setPreviewRecordId] = useState(0);
    const [isPreviewTags, setIsPreviewTags] = useState(false);
    const [previewRecordName, setPreviewRecordName] = useState('');

    useEffect(() => {
        setFromDate(moment().startOf('month'));
        setToDate(moment().endOf('month'));
    }, []);

    const docTypes = useMemo(() => {
        const options = DoctypesByEntityId[entityTypeId];
        if (options) {
            let defOpts = [...CommonDocTypes, ...options];
            defOpts.sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));
            return defOpts.map(o => <MenuItem key={o.value} value={o.value}>{o.label}</MenuItem>);
        } else {
            let defOpts = [...CommonDocTypes];
            CommonDocTypes.sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));
            return defOpts.map(o => <MenuItem key={o.value} value={o.value}>{o.label}</MenuItem>);
        }
    }, [entityTypeId]);

    const getDataCallback = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        const stringFromDate = fromDate && fromDate.isValid() ? fromDate.format('YYYY-MM-DD') : '';
        const stringToDate = toDate && toDate.isValid() ? toDate.format('YYYY-MM-DD') : '';
        const res = await GetDocumentsDetailedReportData(entityTypeId, consultantId, type, stringFromDate, stringToDate, dateUse, errorHandler);
        if (res) {
            setShowGrid(true);
            setRows(res);
        }
        loadingHandler && loadingHandler(false);
    }, [entityTypeId, consultantId, type, fromDate, toDate, dateUse, errorHandler, loadingHandler]);

    const downloadDocumentCallback = useCallback(async (documentId: number) => {
        loadingHandler && loadingHandler(true);
        await DownloadDocument(documentId, undefined, errorHandler);
        loadingHandler && loadingHandler(false);
    }, [loadingHandler, errorHandler]);

    const editDocumentCallback = useCallback((row: DocumentReportData) => {
        const expiration = row["Expiration Date"] === 'Never' ? null : moment(row["Expiration Date"], 'DD/MM/YYYY');
        const types = DoctypesByEntityId[entityTypeId];
        
        let docType = 1;
        if (types && row["Document Type"] !== 'General') {
            const res = types.find(t => t.label === row["Document Type"]);
            if (res) docType = +res.value;
        }

        const doc: Document = {
            expirationDate: expiration && expiration.isValid() ? expiration.format('YYYY-MM-DD') : '0001-01-01',
            createdByName: '',
            createdDate: '0001-01-01',
            deleted: false,
            documentName: row["Document Name"],
            documentExtension: '.' + row["Document Name"].split('.').pop(),
            documentType: docType,
            documentTypeName: row["Document Type"],
            id: row.ID,
            size: 0
        }

        setEditDocument(doc);
    }, [entityTypeId]);

    const exportAsExcelHandler = useCallback(() => {
        const api = apiRef.current;
        if (api) {
            const m = moment();
            const filename = `DocumentsDetailed${m.format('YYYYMMDDhhmmss')}`;
            api.exportDataAsExcel({ fileName: filename });
        }
    }, [apiRef]);

    const columns = useMemo<GridColDef[]>(() => {
        const handlePreviewHover = (type: PreviewEntityType | '', id: number, isTags: boolean = false, recordName: string = '', noDelay: boolean = false) => {
            setPreviewType(type);
            setPreviewRecordId(id);
            setIsPreviewTags(isTags);
            setPreviewRecordName(recordName);
            setIsPreviewOpen(true);
            setShowTagsPreviewNoDelay(noDelay);
        };

        const handlePreviewClose = () => {
            setIsPreviewOpen(false);
            setShowTagsPreviewNoDelay(false);
        };

        const downloadDocumentRenderer = (params: GridRenderCellParams) => {
            return (
                <span
                    style={{ cursor: 'pointer', textDecoration: 'underline' }}
                    onClick={ () => downloadDocumentCallback(params.id as number) }
                >{params.value}</span>
            );
        };

        const editActionRenderer = (params: GridRenderCellParams) => {
            return (
                <span
                    style={{ cursor: 'pointer', textDecoration: 'underline' }}
                    onClick={ () => editDocumentCallback(params.row) }
                >Edit</span>
            );
        };

        const linkToRecordRenderer = (params: GridRenderCellParams) => {
            if (entityTypeId === 1) {
                return (
                    <Link
                        to={`/clients/${params.row.RecordID}`}
                        target={"_blank"}
                        style={linkStyle}
                        onMouseEnter={() => handlePreviewHover('client', params.row.RecordID)}
                        onMouseLeave={handlePreviewClose}
                    >{params.value}</Link>
                );
            }
            if (entityTypeId === 2) {
                return (
                    <Link
                        to={`/contacts/${params.row.RecordID}`}
                        target={"_blank"}
                        style={linkStyle}
                        onMouseEnter={() => handlePreviewHover('contact', params.row.RecordID)}
                        onMouseLeave={handlePreviewClose}
                    >{params.value}</Link>
                );
            }
            if (entityTypeId === 3) {
                return (
                    <Link
                        to={`/candidates/${params.row.RecordID}`}
                        target={"_blank"}
                        style={linkStyle}
                        onMouseEnter={() => handlePreviewHover('candidate', params.row.RecordID)}
                        onMouseLeave={handlePreviewClose}
                    >{params.value}</Link>
                );
            }
            if (entityTypeId === 4) {
                return (
                    <Link
                        to={`/jobs/${params.row.RecordID}`}
                        target={"_blank"}
                        style={linkStyle}
                        onMouseEnter={() => handlePreviewHover('job', params.row.RecordID)}
                        onMouseLeave={handlePreviewClose}
                    >{params.value}</Link>
                );
            }
            if (entityTypeId === 5) {
                return (
                    <Link
                        to={`/placements/${params.row.RecordID}`}
                        target={"_blank"}
                        style={linkStyle}
                        onMouseEnter={() => handlePreviewHover('placement', params.row.RecordID)}
                        onMouseLeave={handlePreviewClose}
                    >{params.value}</Link>
                );
            }
            if (entityTypeId === 8) {
                return (
                    <Link
                        to={`/opportunities/${params.row.RecordID}`}
                        target={"_blank"}
                        style={linkStyle}
                        onMouseEnter={() => handlePreviewHover('opportunity', params.row.RecordID)}
                        onMouseLeave={handlePreviewClose}
                    >{params.value}</Link>
                );
            }
            if (entityTypeId === 9) {
                return (
                    <Link
                        to={`/sites/${params.row.RecordID}`}
                        target={"_blank"}
                        style={linkStyle}
                        onMouseEnter={() => handlePreviewHover('site', params.row.RecordID)}
                        onMouseLeave={handlePreviewClose}
                    >{params.value}</Link>
                );
            }
            return params.value;
        };
        
        return [
            { field: 'ID', width: 100, renderCell: downloadDocumentRenderer },
            { field: 'Document Name', width: 250, renderCell: downloadDocumentRenderer },
            { field: 'Document Type', width: 200 },
            { field: 'Created By User', width: 200 },
            { field: 'Date Created', width: 200 },
            { field: 'Expiration Date', width: 200 },
            { field: 'Record Name', width: 200, renderCell: linkToRecordRenderer },
            { field: 'Action', width: 200, renderCell: editActionRenderer },
        ];
    }, [downloadDocumentCallback, editDocumentCallback, entityTypeId]);

    const editSuccessCallback = useCallback((message: string, fileId: number, fileName: string, fileType: number, expirationDate: string) => {
        successHandler && successHandler(message);
        const api = apiRef.current;
        if (api) {
            const types = DoctypesByEntityId[entityTypeId];
            let docType = 'General';
            if (types && fileType !== 1) {
                const res = types.find(t => t.value === fileType.toString());
                if (res) docType = res.label;
            }

            const expiration = Boolean(expirationDate) && expirationDate !== '0001-01-01' ? moment(expirationDate).format('DD/MM/YYYY') : 'Never';
            api.updateRows([{ 'ID': fileId, 'Document Name': fileName, 'Document Type': docType, 'Expiration Date': expiration }])
        }
    }, [apiRef, entityTypeId, successHandler]);
    
    return (
        <>
            <EditDocumentDialog
                open={Boolean(editDocument)}
                closeHandler={() => setEditDocument(null)}
                entityTypeId={entityTypeId}
                document={editDocument}
                loadingHandler={loadingHandler}
                successHandler={editSuccessCallback}
                errorHandler={errorHandler}
            />
            <PreviewLoaderComponent
                open={isPreviewOpen}
                entityType={previewType}
                recordId={previewRecordId}
                isTagsPreview={isPreviewTags}
                showDelayMs={showTagsPrevewNoDelay ? 0 : undefined}
                titleOverride={previewRecordName}
            />
            <Box display="flex">
                <Box flex="1 1 0" pr={1}>
                    <UserPicker disableClearable label="Consultant" userId={consultantId} onSelect={u => setConsultantId(u ? u.id : 0)} appendToStart={ownerSpecialOptions} includeInactiveUsers={true} />
                </Box>
                <TextField select label="Type" value={type.toString()} onChange={ ({target}) => setType(+target.value) } sx={filterElementStyle}>
                    {docTypes}
                </TextField>
                <DatePicker label="From Date" value={fromDate} onChange={m => setFromDate(m)} sx={filterElementStyle} slotProps={{actionBar: { actions: ["clear", "today", "cancel", "accept"] }}} />
                <DatePicker label="To Date" value={toDate} onChange={m => setToDate(m)} sx={filterElementStyle} slotProps={{actionBar: { actions: ["clear", "today", "cancel", "accept"] }}} />
                <TextField select label="Using" value={dateUse.toString()} onChange={ ({target}) => setDateUse(+target.value) } sx={filterElementStyle}>
                    <MenuItem value="0">Date Created</MenuItem>
                    <MenuItem value="1">Expiration Date</MenuItem>
                </TextField>
                <Button variant="contained" color="success" onClick={getDataCallback}>Run Report</Button>
            </Box>
            <Typography component="div" variant="h6" mt={2}>Report Description</Typography>
            <Typography component="div" variant="body2" mb={2}>{description}</Typography>
            {showGrid && 
                <>
                    <Box pb="10px" ml="auto">
                        <Button variant="contained" color="success" onClick={exportAsExcelHandler}>Export As Excel</Button>
                    </Box>
                    <GridWithStateWrapper
                        density="compact"
                        gridName="reports-documents"
                        rows={rows}
                        columns={columns}
                        apiRef={apiRef}
                        getRowId={r => r.ID}
                        disableRowSelectionOnClick
                    />
                </>
            }
        </>
    );
}