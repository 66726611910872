import React, { useCallback, useEffect, useMemo, useState } from "react";
import { DashboardLayoutComponent, PanelModel } from '@syncfusion/ej2-react-layouts';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import { GetCustomerSettingBySettingName } from "services/ConfigurationService";
import Divider from "@mui/material/Divider";
import { ClientQuickViewLayoutSettings, DefaultClientQuickViewLayoutElements, GetPanelDefinitionsFromPanelModels } from "util/Definitions/QuickViewLayout/Client";
import { Client, ClientOwner } from "common/models/Clients";
import { CustomField } from "common/models/Configuration/CustomFields";
import { Division } from "common/models/Configuration/Division";
import { ClientRecordDashboardElementDefinition, ClientRecordDashboardElementType } from "common/models/Dashboard/EditLayout";
import { MenuOptionDefinition } from "common/models/MenuDefinition";
import { PreviewProps } from "common/models/Previews/Previews";
import { CustomFieldSettingsMap, CustomFieldType } from "common/models/ScreenLayouts/CustomFields";
import RecordAvatarComponent from "components/Dashboards/Graphs/ClientAvatarComponent";
import PanelWrapper from "components/Dashboards/PanelWrapper";
import ClientFieldsElement from "components/ScreenLayouts/Clients/ClientFieldsElement";
import ViewClientLocationElement from "components/ScreenLayouts/Clients/ViewClientLocationElement";
import MultiLineTextElement from "components/ScreenLayouts/MultiLineTextElement";
import SingleFieldElement from "components/ScreenLayouts/SingleFieldElement";
import { GetClientOwners, GetClientById } from "services/ClientsService";
import { GetCustomFieldsByEntity_OnlyActive } from "services/CustomFieldsService";
import { GetDivisions } from "services/DivisionsService";
import { UdfClientFieldMapObj } from "util/Definitions/ScreenLayouts/CustomFields";
import CloseIcon from '@mui/icons-material/Close';
import ControlCameraIcon from '@mui/icons-material/ControlCamera';
import Draggable from "react-draggable";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { PREVIEW_HEIGHT, PREVIEW_TEXT_SKELETON, PREVIEW_TITLE_STYLE, PREVIEW_WIDTH } from "util/Definitions/Constants/Previews";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";

interface Props {
    client: Client | null,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
    setActionMenuTabOptionsHandler?: (opts: MenuOptionDefinition[]) => void
}

const clientCodeTypeNames = {
    0: 'None',
    1: 'ABN',
    2: 'ACN',
    3: 'Company #',
    4: 'VAT #',
};

const { unitWidth, unitHeight, gapX, gapY, columns } = ClientQuickViewLayoutSettings;
const cellSpacing = [gapX, gapY];

export default function ClientQuickViewComponent({ recordId, isSourceHovered, hideCallback, hideDelayMs = 1250 }: PreviewProps) {
    const [shouldClose, setShouldClose] = useState(false);
    const [isFetchingData, setIsFetchingData] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [client, setClient] = useState<Client | null>(null);

    const [isLoading, setIsLoading] = useState(false);
    const [owners, setOwners] = useState<ClientOwner[]>([]);
    const [divisions, setDivisions] = useState<Division[]>([]);
    const [screenResizedControl, setScreenResizedControl] = useState(false);
    const [fetchingSavedState, setFetchingSavedState] = useState(false);
    const [fetchedSavedState, setFetchedSavedState] = useState(false);
    const [activeFields, setActiveFields] = useState<CustomField[]>([]);
    const [lastSavedState, setLastSavedState] = useState<PanelModel[] | null>(null);
    const theme = useTheme();

    useEffect(() => {
        const windowResizeHandler = () => setScreenResizedControl(prev => !prev);
        window.addEventListener('resize', windowResizeHandler);
        return () => window.removeEventListener('resize', windowResizeHandler);
    }, []);

    useEffect(() => {
        const getData = async () => {
            setIsFetchingData(true);
            const res = await GetClientById(recordId);
            if (res) setClient(res);
            setIsFetchingData(false);
        };
        getData();
    }, [recordId]);

    useEffect(() => {
        if (shouldClose) {
            const hidePreviewTimeout = setTimeout(() => {
                hideCallback();
            }, hideDelayMs);
            return () => clearTimeout(hidePreviewTimeout);
        }
    }, [shouldClose, hideDelayMs, hideCallback]);

    useEffect(() => {
        if (isSourceHovered || isHovered) setShouldClose(false);
        else setShouldClose(true);
    }, [isSourceHovered, isHovered]);

    useEffect(() => {
        const getActiveFields = async () => {
            const res = await GetCustomFieldsByEntity_OnlyActive(1);
            if (res) setActiveFields(res);
        };
        getActiveFields();
    }, []);

    useEffect(() => {
        const getOwners = async () => {
            if (client) {
                const res = await GetClientOwners(client.id);
                if (res) setOwners(res);
            }
        };
        client && client.id && getOwners();
    }, [client]);

    useEffect(() => {
        const getDivs = async () => {
            const res = await GetDivisions();
            if (res) setDivisions(res);
        };
        getDivs();
    }, []);

    const divisionNames = useMemo<string[]>(() => {
        if (client) {
            let names: string[] = [];
            const ids = client.divisions.split(';');
            ids.forEach(d => {
                const item = divisions.find(div => div.id === +d);
                if (item) names.push(item.name);
            });
            return names;
        }
        return [];
    }, [client, divisions]);

    const customFieldsSettingsMap = useMemo<CustomFieldSettingsMap>(() => {
        let obj: CustomFieldSettingsMap = {};
        activeFields.forEach(u => {
            const customFieldNumber = u.name.substring(13);
            const key = 'CustomField' + customFieldNumber as CustomFieldType;
            obj[key] = {
                title: u.agencyName,
                isMultiLine: u.multiLine && !u.usePredefinedValues,
            }
        });
        return obj;
    }, [activeFields]);

    useEffect(() => {
        setIsLoading(fetchingSavedState || isFetchingData);
    }, [fetchingSavedState, isFetchingData]);

    useEffect(() => {
        const getSavedState = async () => {
            setFetchingSavedState(true);
            const elementsJson = await GetCustomerSettingBySettingName('ClientQuickViewLayoutElements');
            if (elementsJson) {
                const panels = JSON.parse(elementsJson) as PanelModel[];
                setLastSavedState(panels);
            }
            setFetchingSavedState(false);
            setFetchedSavedState(true);
        };
        getSavedState();
    }, []);

    const elements = useMemo<ClientRecordDashboardElementDefinition[]>(() => {
        if (!fetchedSavedState) return [];
        if (lastSavedState !== null) {
            const elements = GetPanelDefinitionsFromPanelModels(lastSavedState);
            return elements ;
        }
        return DefaultClientQuickViewLayoutElements;
    }, [lastSavedState, fetchedSavedState]);

    const renderElement = useCallback((id: string, type: ClientRecordDashboardElementType) => {
        if (client) {
            switch (type) {
                case 'Divider': return <Box pt="20px"><Divider component="div" /></Box>;
                case 'Spacer': return <></>;
                case 'ClientInfo': return (
                    <RecordAvatarComponent
                        entityId={1}
                        primaryText={client.tradingName}
                        secondaryText={client.legalName}
                        photoUrl={client.photoUrl}
                    />);
                case 'ClientLocation': return (
                    <ViewClientLocationElement
                        address1={client.address1}
                        address2={client.address2}
                        address3={client.address3}
                        countryName={client.countryName}
                        postcode={client.postcode}
                        siteId={client.siteID}
                        siteName={client.siteName}
                        state={client.state}
                        suburb={client.suburb}
                    />);
                case 'ClientSubmissionDeadlines': return (
                    <ClientFieldsElement
                        client={client}
                        title="Submission Deadlines"
                        suffix=" day(s)"
                        fields={['cvDeadlinePerm', 'cvDeadlineContract', 'cvDeadlineFixedContract']}
                />);
                case 'ClientSummary': return <MultiLineTextElement title="Summary" content={client.description} />;
                case 'ClientSummaryFullWidth': return <MultiLineTextElement title="Summary" content={client.description} dividerTitle />;
                case 'ClientId': return <SingleFieldElement fieldTitle='ID' fieldValue={client.id} format="number" />;
                case 'ClientName': return <SingleFieldElement useEllipsisForLongValues fieldTitle='Legal Name' fieldValue={client.legalName} />;
                case 'ClientTradingName': return <SingleFieldElement useEllipsisForLongValues fieldTitle='Trading Name' fieldValue={client.tradingName} />;
                case 'ClientPhone':
                    return <SingleFieldElement fieldTitle='Phone' format="phone-link" href={client.phone} fieldValue={client.phone} />;
                case 'ClientWebsite':
                    const website = client.website;
                    const href = website && !website.startsWith('http') ? 'https://' + website : website;
                    return <SingleFieldElement fieldTitle='Website' href={href} fieldValue={website} format="external-link" />;
                case 'ClientLinkedIn':
                    const linkedIn = client.linkedinURL;
                    const lhref = linkedIn && !linkedIn.startsWith('http') ? 'https://' + linkedIn : linkedIn;
                    return <SingleFieldElement fieldTitle='LinkedIn' href={lhref} fieldValue={linkedIn} format="external-link" />;
                case 'ClientTwitter':
                    const twitter = client.twitterURL;
                    const thref = twitter && !twitter.startsWith('http') ? 'https://' + twitter : twitter;
                    return <SingleFieldElement fieldTitle='Twitter' href={thref} fieldValue={twitter} format="external-link" />;
                case 'ClientParent': return (
                    <SingleFieldElement
                        fieldTitle="Parent"
                        fieldValue={client.parentCompanyName ?? 'None'}
                        previewType="client"
                        previewRecordId={client.parentCompany}
                    />);
                case 'ClientPrimaryContact': return (
                    <SingleFieldElement
                        fieldTitle='Primary Contact'
                        fieldValue={client.primaryContactName}
                        format="internal-link"
                        href={`/contacts/${client.primaryContact}`}
                        previewType="contact"
                        previewRecordId={client.primaryContact}
                    />);
                case 'ClientBillingContact':
                return (
                    <SingleFieldElement
                        fieldTitle='Billing Contact'
                        fieldValue={client.defaultBillingContactName}
                        format="internal-link"
                        href={`/contacts/${client.defaultBillingContactID}`}
                        previewType="contact"
                        previewRecordId={client.defaultBillingContactID}
                    />);
                case 'ClientDefaultApprover': return (
                    <SingleFieldElement
                        fieldTitle='Default Approver'
                        fieldValue={client.defaultTimesheetApproverName ?? ''}
                        format="internal-link"
                        href={`/contacts/${client.defaultTimesheetApproverID}`}
                        previewType="contact"
                        previewRecordId={client.defaultTimesheetApproverID}
                    />);
                case 'ClientOwners': return <SingleFieldElement fieldTitle="Owners" fieldValue={owners.length.toString()} />;
                case 'ClientStatus': return <SingleFieldElement fieldTitle='Status' fieldValue={client.statusName} />;
                case 'ClientRating': return <SingleFieldElement fieldTitle='Rating' fieldValue={client.rating} format="number" />;
                case 'ClientCredit': return (
                    <ClientFieldsElement
                        client={client}
                        title="Credit"
                        fields={['creditLimit', 'creditRating', 'creditBalance']}
                    />);
                case 'ClientDivisions': return (
                    <SingleFieldElement fieldTitle="Divisions" format="custom">
                        {divisionNames.map(d => (
                            <Chip key={d} size="small" label={d} sx={{ mr: '5px' }} />
                        ))}
                    </SingleFieldElement>
                );
                case 'ClientCode1': return <SingleFieldElement fieldTitle={(clientCodeTypeNames as any)[client.code1Type.toString()]} fieldValue={client.code1} />;
                case 'ClientCode2': return <SingleFieldElement fieldTitle={(clientCodeTypeNames as any)[client.code2Type.toString()]} fieldValue={client.code2} />;
                default: 
                    if (type.startsWith('CustomField')) {
                        const settings = customFieldsSettingsMap[type];
                        if (settings) {
                            const title = settings.title;
                            const { field, format } = UdfClientFieldMapObj[type as CustomFieldType];
                            const udfValue = client[field];
                            if (format === 'string' || format === 'date' || format === 'datetime') {
                                return <SingleFieldElement isMultiLine={settings.isMultiLine} format={format} fieldTitle={title} fieldValue={udfValue ? udfValue.toString() : null} />;
                            }
                            if (format === 'number') {
                                const numValue = udfValue === undefined || udfValue === null ? '' : udfValue.toString();
                                return <SingleFieldElement fieldTitle={title} fieldValue={numValue} />;
                            }
                        }
                        else return <SingleFieldElement fieldTitle={type} fieldValue="[ Disabled Custom Field ]" />
                    }
                    return <div>{id}</div>;
            }
        }
    }, [client, customFieldsSettingsMap, owners.length, divisionNames]);
    
    const layoutResizeStopHandler = useCallback(() => {
        setScreenResizedControl(prev => !prev);
    }, []);

    const renderLayout = useCallback(() => {
        if (!fetchedSavedState) return <></>;
        return (
            <DashboardLayoutComponent
                cellSpacing={cellSpacing}
                columns={columns}
                cellAspectRatio={12 / 2}
                resizeStop={ layoutResizeStopHandler }
                allowDragging={false}
                allowResizing={false}
            >
                {elements.map(e => (
                    <PanelWrapper
                        key={e.id}
                        id={e.id}
                        col={e.col}
                        row={e.row}
                        sizeX={e.sizeX}
                        sizeY={e.sizeY}
                        minSizeX={e.minSizeX}
                        minSizeY={e.minSizeY}
                        maxSizeX={e.maxSizeX}
                        maxSizeY={e.maxSizeY}
                        resizeControl={screenResizedControl}
                        resizeIconColor={theme.palette.text.disabled}
                        gapX={gapX}
                        gapY={gapY}
                        unitWidth={unitWidth}
                        unitHeight={unitHeight}
                    >
                        {renderElement(e.id, e.type)}
                    </PanelWrapper>
                ))}
            </DashboardLayoutComponent>
        );
    }, [elements, fetchedSavedState, theme.palette.text.disabled, screenResizedControl, layoutResizeStopHandler, renderElement]);

    return (
        <>
            <Draggable
                handle="#draggable-dialog-title"
                cancel={'[class*="MuiDialogContent-root"]'}
                defaultPosition={{ x: (window.innerWidth / 2) - (PREVIEW_WIDTH / 2), y: (window.innerHeight / 2) - (PREVIEW_HEIGHT / 2) }}
            >
                <Dialog
                    open={true}
                    maxWidth={false}
                    PaperProps={{
                        onMouseEnter: () => setIsHovered(true),
                        onMouseLeave: () => setIsHovered(false),
                        sx: { height: `${PREVIEW_HEIGHT}px`, width: `${PREVIEW_WIDTH}px`, p: 0 }
                    }}
                    hideBackdrop
                    sx={{ bottom: 'unset', right: 'unset', left: 'unset' }}
                >
                    <DialogTitle component="div" display="flex" sx={ PREVIEW_TITLE_STYLE }>
                        <div style={{ alignSelf: 'center' }}>
                            { isLoading || !client ? PREVIEW_TEXT_SKELETON : client.tradingName }
                        </div>
                        <div style={{ marginLeft: 'auto' }}>
                            <IconButton id="draggable-dialog-title" sx={{ cursor: 'move', color: t => t.palette.primary.contrastText }}><ControlCameraIcon /></IconButton>
                            <IconButton onClick={ () => hideCallback() } sx={{ color: t => t.palette.primary.contrastText }}><CloseIcon /></IconButton>
                        </div>
                    </DialogTitle>
                    <DialogContent sx={{ p: 0 }}>
                        {isLoading &&
                            <Box display="flex" justifyContent="center" alignItems="center" height="100%" >
                                <CircularProgress />
                            </Box>
                        }
                        {!isLoading &&
                            <Box p="10px" height="100%">
                                <div className="control-section">
                                    { renderLayout() }
                                </div>
                            </Box>
                        }
                    </DialogContent>
                </Dialog>
            </Draggable>
        </>
    );
}