import React from "react";
import UserProfilePageContent from "components/Content/UserProfilePageContent";

interface Props {
    setSummaryBar?: (summaryBar: JSX.Element) => void
}

export default function AccountProfilePage({ setSummaryBar }: Props) {
    return (
        <UserProfilePageContent
            summaryBarTitle="Account > Profile"
            summaryBarTabTitle="Profile > Account"
            setSummaryBar={setSummaryBar}
        />
    );
}