import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import React, { useCallback, useEffect, useState } from "react";
import { UploadJobDescription } from "services/JobsService";
import FilePicker from "components/Pickers/FilePicker";

interface Props {
    open: boolean,
    jobId: number,
    closeHandler: () => void,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
    successHandler?: (message: string) => void
}

export default function UploadDescriptionDialog({ open, jobId, closeHandler, loadingHandler, errorHandler, successHandler }: Props) {
    const [files, setFiles] = useState<File[]>([]);

    useEffect(() => {
        if (!open) setFiles([]);
    }, [open]);

    const addFilesCallback = useCallback((files: File[]) => {
        setFiles(prev => [...prev, ...files]);
    }, []);

    const removeFileCallback = useCallback((index: number) => {
        setFiles([]);
    }, []);

    const uploadDescriptionHandler = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        const res = await UploadJobDescription(jobId, files, errorHandler);
        if (res) {
            successHandler && successHandler('File Uploaded');
            closeHandler();
        }
        loadingHandler && loadingHandler(false);
    }, [jobId, files, errorHandler, loadingHandler, successHandler, closeHandler]);
    
    return (
        <Dialog open={open} fullWidth>
            <DialogTitle>Upload Description</DialogTitle>
            <DialogContent>
                <FilePicker
                    singleFile={true}
                    files={files}
                    addFilesHandler={ addFilesCallback }
                    removeFileHandler={ removeFileCallback }
                />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="error" onClick={ closeHandler }>Cancel</Button>
                <Button variant="contained" color="success" disabled={ files.length === 0 } onClick={ uploadDescriptionHandler }>Upload</Button>
            </DialogActions>
        </Dialog>
    );
}