import { ArrowRight } from "@mui/icons-material";
import { Menu, MenuItem, PopoverOrigin, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { GenericMenuState } from "common/models/GenericTypes";

import { TopBarRoute } from  '../../common/models/TopBarTypes';

interface Props { 
    data: TopBarRoute, isSubmenu?: boolean,
    moduleKey: number,
    hoveredModule: number | null | undefined,
    setSelectedModule?: (module: number) => void
}

export default class WizTopBarRouteMenu extends React.Component<Props, GenericMenuState> {
    state: Readonly<GenericMenuState> = { anchor: null }

    handleOpen = (e: React.MouseEvent<HTMLElement>) => {
        const { setSelectedModule = undefined } = this.props;

        if (setSelectedModule)
            setSelectedModule(this.props.moduleKey);

        this.setState({ anchor: e.currentTarget });
    }

    handleClose = () => {
        this.setState({ anchor: null });
    }

    render(): React.ReactNode {
        const { data, isSubmenu = false, moduleKey, hoveredModule } = this.props;
        const { anchor } = this.state;
        const linkStyle = isSubmenu ? { color: 'inherit', flexBasis: '100%', textDecoration: 'none' } : { color: 'white '};
        const  subMenuSx = isSubmenu ? { display: 'flex', justifyContent: 'space-between' } : {};
        const anchorOrigin: PopoverOrigin = isSubmenu
        ? { vertical: 'top', horizontal: 'right' }
        : { vertical: 'bottom', horizontal: 'left' };

        return (
            <>
                <Link style={linkStyle} to={ data.path } onMouseEnter={this.handleOpen} >
                    <Typography component='span' sx={ subMenuSx }>
                        { data.text }
                        {isSubmenu &&
                            <ArrowRight />
                        }
                    </Typography>
                </Link>
                {(data.options && data.options.length > 0) &&
                    <Menu
                        PopoverClasses={{ root: 'testing-zindex' }}
                        anchorEl={anchor}
                        anchorOrigin={anchorOrigin}
                        sx={{ zIndex: (theme) => theme.zIndex.appBar - 1 }}
                        open={Boolean(anchor) && hoveredModule === moduleKey}
                        onClose={this.handleClose}
                        MenuListProps={{ onMouseLeave: this.handleClose }}
                    >
                        <span id='testing-on-leave'>
                            {data.options?.map( (op, index) => {
                                if (op.options && op.options.length > 0) {
                                    return (
                                        <MenuItem key={index}>
                                            <WizTopBarRouteMenu data={op} moduleKey={ moduleKey } hoveredModule={hoveredModule} isSubmenu />
                                        </MenuItem>
                                    );
                                }
                                return (
                                    <MenuItem key={index}>
                                        <Typography>{ op.text }</Typography>
                                    </MenuItem>
                                );
                            })}
                        </span>
                    </Menu>
                }
            </>
        );
    }
}