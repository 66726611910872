import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";

import PageLayout from "layouts/PageLayout";
import PageContentLayout from "layouts/PageContentLayout";
import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import { Advert } from "common/models/Advert";
import { ApproveAdvert, DeleteAdvertById, ExpireAdvertById, GetAdvertById } from "services/AdvertsService";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { GetMyUser, GetSettingBySettingName } from "services/UsersService";

import ConfirmationDialog from "components/Dialogs/Generic/ConfirmationDialog";
import Snackbar from "@mui/material/Snackbar";
import Alert from "components/Alert";
import ActionMenu from "components/Menus/ActionMenu";
import moment from "moment";
import { MenuOptionDefinition } from "common/models/MenuDefinition";
import { AllowAdvertsDelete, AllowAdvertsAddEdit } from "common/data/Permissions/AdvertsAccess";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { Permission } from "common/models/Permissions";
import { userHasSinglePermission } from "util/PermissionsUtils";
import { AddRecentRecord } from "util/LocalStorageUtils";
import CopyAdvertDialog from "components/Dialogs/Adverts/CopyAdvertDialog";
import ViewRecordScreenLayout from "components/ScreenLayouts/Adverts/ViewRecordScreenLayout";
import ConvertToTemplateDialog from "components/Dialogs/Adverts/ConvertToTemplateDialog";
import AdvertBoardsGridComponent from "components/Grids/AdvertBoardsGrid";
import AdvertApplicantsGridComponent from "components/Grids/AdvertApplicantsGrid";
import useRecordHotkeys from "hooks/UseRecordHotkeys";
import AdvertJobBoardsRenderer from "components/JobBoards/AdvertJobBoardsRenderer";
import { GetCustomerSettingBySettingNameIgnoreCache } from "services/ConfigurationService";
import Container from "@mui/material/Container";

const summaryLinkStyle: React.CSSProperties = { color: 'inherit', textDecoration: 'none' };

const userIdsCanApprove = [ 9733, 9760, 9744, 11166, 11315, 11319 ];

const formatDateString = (d: string, f: string = 'DD MMM YYYY h:mm A') => {
    if (d && d !== '0001-01-01T00:00:00') {
        const m = moment(d);
        if (m.isValid()) return m.format(f);
    }
    return 'Never';
}

const renderSummary = (c: Advert) => {
    const tooltipContent = (
        <table>
            <tbody>
                <tr>
                    <Typography variant="caption" fontWeight={600} component="td">ID</Typography>
                    <Typography variant="caption" pl="10px" component="td">{c.id}</Typography>
                </tr>
                <tr>
                    <Typography variant="caption" fontWeight={600} component="td" sx={{ verticalAlign: 'top' }}>Title</Typography>
                    <Typography variant="caption" pl="10px" component="td">{c.title}</Typography>
                </tr>
                <tr>
                    <Typography variant="caption" fontWeight={600} component="td">Created Date</Typography>
                    <Typography variant="caption" pl="10px" component="td">{formatDateString(c.createdDate)}</Typography>
                </tr>
                <tr>
                    <Typography variant="caption" fontWeight={600} component="td">Created By</Typography>
                    <Typography variant="caption" pl="10px" component="td">{c.createdByName}</Typography>
                </tr>
            </tbody>
        </table>
    );

    const jobSummary = (
        <Tooltip arrow title={tooltipContent}>
            <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{c.title} ({c.statusName})</span>
        </Tooltip>
    );
   
    return (<>{jobSummary}</>);
};

const getWorkTypeName = (id: number) => {
    if (id === 1) return 'Casual';
    if (id === 2) return 'Full Time';
    if (id === 3) return 'Part Time';
    return '';
};

const getSalaryTypeName = (id: number) => {
    if (id === 1) return 'Hourly';
    if (id === 2) return 'Daily';
    if (id === 3) return 'Annual Salary';
    return '';
};

const formatNumber = (value: number) => {
    return value.toLocaleString('en-US', { maximumFractionDigits: 2 });
};

export default function AdvertRecord() {
    const [advert, setAdvert] = useState<Advert>();
    const params = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [isFetchingDefaultTab, setIsFetchingDefaultTab] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [isRecordDeleted, setIsRecordDeleted] = useState(false);
    const [isLoadingTab, setIsLoadingTab] = useState(false);
    const [searchParams] = useSearchParams();
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showCopyAdvertDialog, setShowCopyAdvertDialog] = useState(false);
    const [showExpireAdvertDialog, setShowExpireAdvertDialog] = useState(false);
    const [showConvertToTemplateDialog, setShowConvertToTemplateDialog] = useState(false);
    const [showApproveConfirmDialog, setShowApproveConfirmDialog] = useState(false);
    const [isFinishedSetup, setIsFinishedSetup] = useState(false);
    const [activeTab, setActiveTab] = useState('Home');
    const [tabActionMenuOptions, setTabActionMenuOptions] = useState<MenuOptionDefinition[]>([]);
    const [recordAttributes, setRecordAttributes] = useState<Record<string, string>>({});
    const [isAdvertsEapEnabled, setIsAdvertsEapEnabled] = useState(false);
    const [currentUserId, setCurrentUserId] = useState(0);
    const navigate = useNavigate();

    const isGlobalAdministrator = useMemo(() => userHasSinglePermission(Permission.GlobalAdministrator), []);
    const canAddEditAdverts = useMemo(() => AllowAdvertsAddEdit(), []);
    const canDeleteAdverts = useMemo(() => AllowAdvertsDelete(), []);

    const advertId = useMemo(() => {
        const parsedId = +(params.id ?? '0');
        if (isNaN(parsedId)) return 0;
        return parsedId;
    }, [params.id]);

    const advertStatusId = useMemo(() => {
        if (advert) return advert.statusID;
        return 0;
    }, [advert]);

    const hotkeySettings = useMemo<Record<string, () => void>>(() => {
        return {
            e: () => navigate(`/adverts/${advertId}/edit`),
        };
    }, [advertId, navigate]);

    useRecordHotkeys(hotkeySettings, !canAddEditAdverts);

    useEffect(() => {
        const getCurrentUser = async () => {
            const res = await GetMyUser();
            if (res) setCurrentUserId(res.userID);
        };
        getCurrentUser();
    }, []);

    useEffect(() => {
        const getAdvertsSettings = async () => {
            const settings = await GetCustomerSettingBySettingNameIgnoreCache('AdvertsEAP');
            if (settings && settings === 'True') setIsAdvertsEapEnabled(true);
        };
        getAdvertsSettings();
    }, []);

    useEffect(() => {
        const getData = async () => {
            setIsLoading(true);
            const res = await GetAdvertById(advertId);
            if (res === null) navigate('/not-found');
            if (res) {
                AddRecentRecord(15, advertId, res.title);
                setAdvert(res);
                setRecordAttributes({
                    'classification': res.classificationName,
                    'subClassification': res.subClassificationName,
                    'country': res.countryName,
                    'region': res.regionName,
                    'location': res.locationName,
                    'workType': getWorkTypeName(res.workTypeID),
                    'salaryType': getSalaryTypeName(res.payType),
                    'salaryFrom': formatNumber(res.payFrom),
                    'salaryTo': formatNumber(res.payTo),
                    'displaySalary': res.payDisplay,
                    'jobTitle': res.title,
                });
            }
            setIsLoading(false);
        };
        getData();
    }, [advertId, navigate]);

    useEffect(() => {
        const tabParam = searchParams.get('tab');
        const getData = async () => {
            setIsFetchingDefaultTab(true);
            if (!tabParam) {
                const tabSetting = await GetSettingBySettingName('TabDefaultsAdverts');
                if (tabSetting) setActiveTab(tabSetting);
            }
            else setActiveTab(tabParam)
            setIsFetchingDefaultTab(false);
            setIsFinishedSetup(true);
        };
        getData();
    }, [searchParams]);

    const approveHandler = useCallback(async () => {
        setIsLoading(true);
        const res = await ApproveAdvert(advertId, setErrorMessage);
        setIsLoading(false);
        if (res) {
            setShowApproveConfirmDialog(false);
            window.location.reload();
        }
    }, [advertId]);

    const canUserApprove = useMemo(() => {
        if (advert && currentUserId) {
            return userIdsCanApprove.includes(currentUserId) && advert.statusID === -1;
        }
        return false;
    }, [advert, currentUserId]);

    const actionMenuDefinitions = useMemo<MenuOptionDefinition[]>(() => {
        let actions: (MenuOptionDefinition)[] = [
            ...tabActionMenuOptions,
            { label: 'Approve', type: 'action', action: () => setShowApproveConfirmDialog(true), allow: () => canUserApprove },
            { label: 'Convert to Template', type: 'action', action: () => setShowConvertToTemplateDialog(true), allow: () => canAddEditAdverts && advertStatusId !== 0 },
            { label: 'Copy', type: 'action', action: () => setShowCopyAdvertDialog(true), allow: () => canAddEditAdverts && advertStatusId !== 0 },
            { label: 'Delete', type: 'action', action: () => setShowDeleteDialog(true), allow: () => canDeleteAdverts && (advertStatusId === 0 || advertStatusId === 1) },
            { label: 'Expire', type: 'action', action: () => setShowExpireAdvertDialog(true), allow: () => canAddEditAdverts && advertStatusId >= 2 && advertStatusId <= 5 }
        ];

        return actions;
    }, [tabActionMenuOptions, canUserApprove, canAddEditAdverts, advertStatusId, canDeleteAdverts]);


    const SummaryBar = useMemo(() => {
        if (advert) {
            const action1 = canAddEditAdverts && advertStatusId >= 0 && advertStatusId <= 5 ? (
                <Link key="editLink" to={`/adverts/${advertId}/edit`} style={summaryLinkStyle}>
                    <Button variant="contained" color="success" sx={{ mr: '5px' }}>Edit</Button>
                </Link>
            ) : <React.Fragment key="editPlaceholder" />;

            const action = (
                <Box key="actionsMenu">
                    <ActionMenu definition={actionMenuDefinitions} />
                </Box>
            );

            return (
                <TitleAndActionSummaryBar
                    height="4.5rem"
                    title={advert ? renderSummary(advert) : ''}
                    action={[action1, action]}
                    browserTabTitle={!advert ? '' : advert.title + " - Adverts"}
                />
            );
        }
    }, [advertId, advertStatusId, advert, actionMenuDefinitions, canAddEditAdverts]);


    const handleTabChange = useCallback((e: React.SyntheticEvent, newValue: string) => {
        navigate(`/adverts/${advertId}?tab=${newValue}`);
    }, [advertId, navigate]);

    const deleteRecordCallback = useCallback(async () => {
        setIsLoading(true);
        const res = await DeleteAdvertById(advertId, setErrorMessage);
        if (res) setIsRecordDeleted(true);
        setIsLoading(false);
    }, [advertId]);

    const counts = useMemo(() => {
        let counts = {
            boards: 0,
            applications: 0
        };

        if (advert) {
            counts.boards = advert.numberOfSites;
            counts.applications = advert.numApplicants;
        }
                  
        return counts;
    }, [advert]);

    useEffect(() => {
        if (tabActionMenuOptions.length > 0 && activeTab !== 'Home') {
            setTabActionMenuOptions([]);
        }
    }, [activeTab, tabActionMenuOptions]);

    const expireAdvertCallback = useCallback(async () => {
        setIsLoading(true);
        const res = await ExpireAdvertById(advertId, setErrorMessage);
        if (res) {
            setSuccessMessage('Advert Expired');
            setShowExpireAdvertDialog(false);
        }
        setIsLoading(false);
    }, [advertId]);

    return (
        <>
            <Snackbar open={successMessage !== ''} autoHideDuration={3000} onClose={() => setSuccessMessage('')}>
                <Alert onClose={() => setSuccessMessage('')}>{ successMessage }</Alert>
            </Snackbar>
            <Snackbar open={isRecordDeleted} autoHideDuration={1200} onClose={() => navigate('/adverts/')}>
                <Alert onClose={() => navigate('/adverts/')}>Record deleted</Alert>
            </Snackbar>
            <Snackbar open={errorMessage !== ''} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <PageLayout paddingTop={0} SummaryBar={SummaryBar}>
                <CopyAdvertDialog
                    open={ showCopyAdvertDialog }
                    advertId={advertId}
                    advertJobId={advert ? advert.jobID : 0}
                    onClose={ () => setShowCopyAdvertDialog(false) }
                    errorHandler={ setErrorMessage }
                    loadingHandler={ setIsLoading }
                    successHandler={ setSuccessMessage }
                />
                <ConvertToTemplateDialog
                    open={showConvertToTemplateDialog}
                    advertId={advertId}
                    onClose={() => setShowConvertToTemplateDialog(false)}
                    errorHandler={setErrorMessage}
                    loadingHandler={setIsLoading}
                    successHandler={setSuccessMessage}
                />
                <ConfirmationDialog
                    open={showExpireAdvertDialog}
                    title="Confirm Action"
                    message="Are you sure you want to expire this Advert?"
                    confirmActionText="Yes"
                    cancelActionText="No"
                    onClose={ () => setShowExpireAdvertDialog(false) }
                    onContinue={ expireAdvertCallback }
                />
                <ConfirmationDialog
                    message="Are you sure you want to delete this record?"
                    onClose={ () => setShowDeleteDialog(false) }
                    onContinue={ deleteRecordCallback }
                    open={showDeleteDialog}
                    title="Confirm Action"
                    confirmActionText="Yes"
                    cancelActionText="No"
                />
                <ConfirmationDialog
                    message="Are you sure you want to approve this advert?"
                    onClose={ () => setShowApproveConfirmDialog(false) }
                    onContinue={ approveHandler }
                    open={showApproveConfirmDialog}
                    title="Confirm Action"
                    confirmActionText="Yes"
                    cancelActionText="No"
                />
                <Tabs value={activeTab} onChange={handleTabChange}>
                    <Tab value="Home" label={<>Home<br />&nbsp;</>} />
                    <Tab value="Advert" label={<>Advert<br />&nbsp;</>} />
                    <Tab value="Boards" label={<>Board Status ({counts.boards})<br />&nbsp;</>} />
                    {isGlobalAdministrator && <Tab value="Applicants" label={<>Applicants ({counts.applications})<br />&nbsp;</>} />}
                </Tabs>
                <PageContentLayout showLoading={isLoading || isFetchingDefaultTab || isLoadingTab}>
                    { isFinishedSetup && activeTab === "Home" &&
                        <ViewRecordScreenLayout
                            advert={advert ? advert : null}
                            loadingHandler={setIsLoadingTab}
                            errorHandler={setErrorMessage}
                        />
                    }
                    { isFinishedSetup && activeTab === "Advert" && advert &&
                        <Container maxWidth="md" sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                            <Box>
                                
                                <Typography component="div" variant="h6" marginBottom={2}>{advert.title}</Typography>
                                <Typography component="div" variant="body2">{advert.shortDescription}</Typography>
                                <ul>
                                    { advert.bullet1 && <li>{advert.bullet1}</li>}
                                    { advert.bullet2 && <li>{advert.bullet2}</li>}
                                    { advert.bullet3 && <li>{advert.bullet3}</li>}
                                </ul>
                                <Typography component="div" variant="body1" dangerouslySetInnerHTML={{ __html: advert.description ?? '' }} />
                            </Box>
                            { isAdvertsEapEnabled && <AdvertJobBoardsRenderer advertId={advertId} advertRecordAttributes={recordAttributes} loadingHandler={setIsLoading} /> }
                        </Container>
                    }
                    { isFinishedSetup && activeTab === "Boards" && 
                        <AdvertBoardsGridComponent
                            gridName="adverts/Boards"
                            advertId={advertId}
                            loadingHandler={setIsLoading}
                            errorHander={setErrorMessage}
                        />
                    }
                    { isFinishedSetup && activeTab === "Applicants" &&
                        <AdvertApplicantsGridComponent
                            gridName="adverts/Applicants"
                            advertId={advertId}
                            loadingHandler={setIsLoading}
                            errorHander={setErrorMessage}
                        />
                    }
                    
                </PageContentLayout>
            </PageLayout>
        </>
    );
}