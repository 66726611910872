import Alert from "components/Alert";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { TimeZone } from "common/models/Common";
import { UserGeneralSettings } from "common/models/Configuration/User";
import { Permission } from "common/models/Permissions";
import useObjectStateWithChangeTracker from "hooks/UseObjectStateWithChangeTracker";
import useUnsavedChangesDialog from "hooks/UseUnsavedChangesDialog";
import PageContentLayout from "layouts/PageContentLayout";
import { GetTimeZone } from "services/CommonService";
import { GetMyGeneralSettings, GetUserGeneralSettings, UpdateMyGeneralSettings, UpdateUserGeneralSettings } from "services/UsersService";
import { DefaultGeneralUserSettings, NoChangesGeneralUserSettings } from "util/Definitions/Configuration/Users/Edit";
import { userHasSinglePermission } from "util/PermissionsUtils";
import CountryPicker from "../Pickers/CountryPicker";
import TimeZonePicker from "../Pickers/TimeZonePicker";
import TitleAndActionSummaryBar from "../SummaryBars/TitleAndActionSummaryBar";
import RWTextFieldComponent from "components/RWTextFieldComponent";

interface Props {
    userId?: number,
    summaryBarTitle: string | JSX.Element,
    summaryBarTabTitle: string,
    showAdminFields?: boolean,
    setSummaryBar?: (summaryBar: JSX.Element) => void
    refreshCurrentUserHandler?: () => void
}

export default function UserGeneralPageContent({ userId = 0, summaryBarTitle, summaryBarTabTitle, showAdminFields = false, setSummaryBar, refreshCurrentUserHandler }: Props) {
    const [isLoading, setIsLoading] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [selectedTimeZone, setSelectedTimeZone] = useState<TimeZone | null>(null);
    const { state, init, change, updateInitial, hasChanges } = useObjectStateWithChangeTracker<UserGeneralSettings>(DefaultGeneralUserSettings, NoChangesGeneralUserSettings);

    const saveHandler = useCallback(async () => {
        setIsLoading(true);
        let res: boolean | null = null;

        if (userId === 0) {
            res = await UpdateMyGeneralSettings(state, setErrorMessage);
            if (res) refreshCurrentUserHandler && refreshCurrentUserHandler();
        }
        else {
            res = await UpdateUserGeneralSettings(userId, state, setErrorMessage);
        }

        if (!res) {
            setIsLoading(false);
            return false;
        }
        updateInitial();
        setIsLoading(false);
        setShowSuccess(true);
        return true;
    }, [userId, state, updateInitial, refreshCurrentUserHandler]);

    useEffect(() => {
        const action = <Button variant="contained" color="success" onClick={ saveHandler } disabled={!hasChanges}>Save</Button>;
        const summaryBar = <TitleAndActionSummaryBar title={ summaryBarTitle } browserTabTitle={ summaryBarTabTitle } action={action} />;
        setSummaryBar && setSummaryBar(summaryBar);
    }, [setSummaryBar, summaryBarTitle, summaryBarTabTitle, saveHandler, hasChanges]);

    const { unsavedChangesDialog } = useUnsavedChangesDialog(hasChanges, saveHandler);

    useEffect(() => {
        const getData = async () => {
            setIsLoading(true);
            let data: UserGeneralSettings | null = null;

            if (userId === 0) {
                data = await GetMyGeneralSettings();
            }
            else {
                data = await GetUserGeneralSettings(userId);
            }

            if (data) {
                const tz = await GetTimeZone(data.timeZoneName);
                init(data);
                if (tz) setSelectedTimeZone(tz);
            }
            setIsLoading(false);
        };
        getData();
    }, [userId, init]);

    const timeZoneChangeCallback = useCallback((tz: TimeZone | null) => {
        setSelectedTimeZone(tz);
        change('timeZoneName', tz ? tz.zoneName : '');
    }, [change]);

    const countryChangeCallback = useCallback((c: string | null) => {
        change('country', c ? c : '');
    }, [change]);

    const handleSettingsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        change(name as keyof UserGeneralSettings, value);
    };

    const handleSettingsChangeNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        change(name as keyof UserGeneralSettings, +value);
    };

    const handleSettingsChangeBoolean = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        change(name as keyof UserGeneralSettings, value === '1');
    };

    const isGlobalAdmin = useMemo(() => {
        return userHasSinglePermission(Permission.GlobalAdministrator);
    }, []);

    return (
        <>
            {unsavedChangesDialog}
            <Snackbar open={showSuccess} autoHideDuration={3000} onClose={() => setShowSuccess(false)}>
                <Alert onClose={() => setShowSuccess(false)}>Changes Saved</Alert>
            </Snackbar>
            <Snackbar open={errorMessage !== ''} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <PageContentLayout title="Contact Details" showLoading={isLoading}>
                <Stack spacing={3}>
                    <RWTextFieldComponent 
                        label="First Name"
                        name="firstName"
                        value={state.firstName}
                        onChange={handleSettingsChange} />

                    <RWTextFieldComponent 
                        label="Last Name"
                        name="lastName"
                        value={state.lastName}
                        onChange={handleSettingsChange} />

                    <RWTextFieldComponent 
                        label="Email Address"
                        name="userName"
                        value={state.userName}
                        onChange={handleSettingsChange} 
                        disabled={userId === 0}/>

                    {isGlobalAdmin && showAdminFields &&
                        <TextField
                            select
                            label="User Type"
                            value={state.userTypeID.toString()}
                            name="userTypeID"
                            onChange={ handleSettingsChangeNumber }
                        >
                            <MenuItem value="0">Paid</MenuItem>
                            <MenuItem value="1">Complimentary</MenuItem>
                        </TextField>
                    }
                    <TimeZonePicker value={selectedTimeZone} onSelectCallback={ timeZoneChangeCallback } />
                    {isGlobalAdmin && showAdminFields &&
                        <TextField
                            select
                            label="Configuration Manager"
                            value={state.configurationManager ? '1' : '0'}
                            name="configurationManager"
                            onChange={ handleSettingsChangeBoolean }
                        >
                            <MenuItem value="1">Enabled</MenuItem>
                            <MenuItem value="0">Disabled</MenuItem>
                        </TextField>
                    }

                    <RWTextFieldComponent 
                        label="Job Title" 
                        value={state.jobTitle ?? ''}
                        name="jobTitle"
                        onChange={handleSettingsChange} />

                    <RWTextFieldComponent 
                        label="Mobile" 
                        value={state.mobile ?? ''}
                        name="mobile"
                        onChange={handleSettingsChange} />

                    <RWTextFieldComponent 
                        label="Desk Phone" 
                        value={state.landline ?? ''}
                        name="landline"
                        onChange={handleSettingsChange} />

                    <RWTextFieldComponent 
                        label="LinkedIn URL" 
                        value={state.linkedinUrl ?? ''}
                        name="linkedinUrl"
                        onChange={handleSettingsChange} />

                     <RWTextFieldComponent 
                        label="Twitter URL" 
                        value={state.twitterUrl ?? ''}
                        name="twitterUrl"
                        onChange={handleSettingsChange} />

                    <RWTextFieldComponent 
                        label="Address 1" 
                        value={state.address1 ?? ''}
                        name="address1"
                        onChange={handleSettingsChange} />

                    <RWTextFieldComponent 
                        label="Address 2" 
                        value={state.address2 ?? ''}
                        name="address2"
                        onChange={handleSettingsChange} />

                    <RWTextFieldComponent 
                        label="Address 3" 
                        value={state.address3 ?? ''}
                        name="address3"
                        onChange={handleSettingsChange} />

                    <RWTextFieldComponent 
                        label="Suburb" 
                        value={state.suburb ?? ''}
                        name="suburb"
                        onChange={handleSettingsChange} />

                    <RWTextFieldComponent 
                        label="State" 
                        value={state.address1 ?? ''}
                        name="address1"
                        onChange={handleSettingsChange} />
    
                    <RWTextFieldComponent
                        label="State"
                        value={state.state ?? ''}
                        name="state"
                        onChange={handleSettingsChange}
                    />
                    <RWTextFieldComponent
                        label="Postcode"
                        value={state.postcode ?? ''}
                        name="postcode"
                        onChange={handleSettingsChange}
                    />
                    <CountryPicker code={state.country} onSelectCallback={ countryChangeCallback } />
                </Stack>
            </PageContentLayout>
        </>
    );
}