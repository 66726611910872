import { Invoice } from "../common/models/Invoices/Invoice";
import { CreateInvoice } from "../common/models/Invoices/CreateInvoice";
import { ErrorHandler, NameIdObj, NumberValueWrapper, RequestOptions } from "../common/models/GenericTypes";
import { JobTracking } from "../common/models/Jobs/JobTracking";
import { ActivityMessage } from "../common/models/Activities";
import { Submission } from "../common/models/Submissions/Submission";
import { Job, } from "../common/models/Jobs/Job";
import { Placement } from "../common/models/Placements";
import { Report } from "../common/models/Reports/Report";
import {Get, Put, Delete, Post, DownloadFile, PostFile} from "../util/HttpUtils";
import { JobSearch } from "../common/models/Search/JobSearch";
import { ReferenceCheck } from "../common/models/Jobs/ReferenceCheck";
import { PartnerAction } from "../common/models/PartnerActions";
import {DownloadBlob} from "../util/FilesUtils";
import { CandidateRatingCounts } from "../common/models/Configuration/Jobs";
import {Interview} from "../common/models/Interviews";
import {Meeting} from "../common/models/Meetings";

export async function GetJobById(jobId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Job>(`api/Jobs/${jobId}`, options);
}

export async function JobLookup(term: string, limit: number, openOnly: boolean, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return await Get<Job[]>(`api/jobs/lookup?query=${encodeURIComponent(term)}&limit=${limit}&openOnly=${openOnly}`, options);
}

export async function SearchJobs(data: JobSearch, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<Job[]>('api/jobs/search', options, data);
}

export async function GetMyJobs(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Job[]>(`api/jobs/me`, options);
}

export async function GetTeamOpenJobs(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Job[]>(`api/jobs/team/open`, options);
}

export async function GetCompanyOpenJobs(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Job[]>(`api/jobs/company/open`, options);
}

export async function GetMyJobsByStateId(stateId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Job[]>(`api/Jobs/Me/State/${stateId}`, options);
}

export async function GetUserJobsByStateId(userId: number, stateId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Job[]>(`api/Jobs/USer/${userId}/State/${stateId}`, options);
}

export async function GetMyTeamOpenJobs(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Job[]>('api/Jobs/MyTeam', options);
}

export async function CloseJobs(jobIds: number[], reason: number, comments: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/Jobs/Close`, options, { jobIds, reason, comments });
}

export async function ReopenJob(jobId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/Jobs/${jobId}/Reopen`, options);
}

export async function PutJobOnHold(jobId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/Jobs/${jobId}/Hold`, options);
}

export async function ResetJobStage(jobId: number, stageId: number, comments: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    const data = {
        "stageId": stageId,
        "comments": comments
    }

    return Put<boolean>(`api/Jobs/${jobId}/Stages`, options,data);
}

export async function ResetEstimatedPlacementDate(jobId: number, date: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/Jobs/${jobId}/PlacementDate?date=${encodeURIComponent(date)}`, options);
}

export async function UpdateJobDeadline(jobId: number, date: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/Jobs/${jobId}/Deadline?date=${encodeURIComponent(date)}`, options);
}

export async function GetReports(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Report[]>('api/jobs/reports', options);
}

export async function GetJobsByTags(tagTypeId: number, taggedBy: number, tag: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Job[]>(`api/jobs/tags/${tagTypeId}?taggedBy=${taggedBy}&tag=${encodeURIComponent(tag)}`, options);
}

export async function GetJobsConversionReportData(owner: number, start_date: string, end_date: string, type: number, status: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<any>(`api/jobs/reports/conversion?owner=${owner}&startDate=${start_date}&endDate=${end_date}&type=${type}&status=${status}`, options);
}

export async function GetCustomJobsConversionReportData(owner: number, start_date: string, end_date: string, type: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<any>(`api/jobs/reports/custom-ntt-conversion?owner=${owner}&startDate=${start_date}&endDate=${end_date}&type=${type}`, options);
}


export async function GetExclusiveJobsReportData(owner: number, start_date: string, end_date: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<any>(`api/jobs/reports/exclusive?owner=${owner}&startDate=${start_date}&endDate=${end_date}`, options);
}


export async function GetJobsSLAReportData(owner: number, status: number, type: number, range: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<any>(`api/jobs/reports/sla?owner=${owner}&status=${status}&type=${type}&range=${range}`, options);
}

export async function GetJobsDetailedReportData(owner: number, status: string, range: number, type: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<any>(`api/jobs/reports/detailed?owner=${owner}&status=${status}&range=${range}&type=${type}`, options);
}

export async function GetJobsDeadlineReportData(owner: number, status: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<any>(`api/jobs/reports/deadline?owner=${owner}&status=${status === 0? 1: 0}`, options);
}

export async function GetForecastExceptionsJobsReportData(owner: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<any>(`api/jobs/reports/forecast-exceptions-jobs?owner=${owner}`, options);
}

export async function GetForecastExceptionsCandidatesReportData(owner: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<any>(`api/jobs/reports/forecast-exceptions-candidates?owner=${owner}`, options);
}

export async function CreateJob(jobData: Partial<Job>, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<NumberValueWrapper>('api/jobs', options, jobData);
}

export async function CopyJob(jobId: number, jobData: Partial<Job>, isCopyTags: boolean, isCopyDocuments: boolean, isCopyCandidates: boolean, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    const t = isCopyTags ? 'copyTags=true&' : '';
    const d = isCopyDocuments ? 'copyDocuments=true&' : '';
    const c = isCopyCandidates ? 'copyCandidates=true' : '';

    return Post<NumberValueWrapper>(`api/jobs/${jobId}/copy?` + t + d + c, options, jobData);
}

export async function UpdateJob(jobId: number, jobData: Partial<Job>, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/jobs/${jobId}`, options, jobData);
}

export async function UpdateJobCustomField(jobId: number, fieldNumber: number, value: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/jobs/${jobId}/custom-fields`, options, { fieldNumber, value });
}

export async function DeleteJobById(jobId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Delete<boolean>(`api/jobs/${jobId}`, options);
}

export async function GetJobPlacements(jobId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Placement[]>(`api/jobs/${jobId}/placements`, options);
}

export async function GetJobMeetings(jobId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Meeting[]>(`api/jobs/${jobId}/meetings`, options);
}

export async function GetJobInterviews(jobId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Interview[]>(`api/jobs/${jobId}/interviews`, options);
}

export async function GetJobSubmissions(jobId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Submission[]>(`api/jobs/${jobId}/submissions`, options);
}

export async function GetJobCandidates(jobId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<JobTracking[]>(`api/Jobs/${jobId}/candidates`, options);
}

export async function GetJobCandidatesByAdvertId(jobId: number, advertId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<JobTracking[]>(`api/Jobs/${jobId}/candidates?advertId=${advertId}`, options);
}

export async function GetJobCandidatesByAdvertIdAndRatingId(jobId: number, advertId: number, ratingId?: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    const search = new URLSearchParams();
    if (advertId > 0) search.set('advertId', advertId.toString());
    if (ratingId || ratingId === 0) search.set('ratingId', ratingId.toString());

    return Get<JobTracking[]>(`api/Jobs/${jobId}/candidates?${search.toString()}`, options);
}

export async function GetJobCandidateRatingCounts(jobId: number, advertId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<CandidateRatingCounts>(`api/Jobs/${jobId}/candidates/counts?advertId=${advertId}`, options);
}

export async function UpdateJobTrackingComments(jobTrackingId: number, comments: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/Jobs/Tracking/${jobTrackingId}`, options, { value: comments });
}

export async function UpdateJobSplits(jobId: number, consultant1Id: number, consultant1Percent: number, consultant2Id: number, consultant2Percent: number, consultant3Id: number, consultant3Percent: number, consultant4Id: number, consultant4Percent: number, consultant5Id: number, consultant5Percent: number, errorHandler?: ErrorHandler){
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    const data = {
        consultant1Id, consultant1Percent,
        consultant2Id, consultant2Percent,
        consultant3Id, consultant3Percent,
        consultant4Id, consultant4Percent,
        consultant5Id, consultant5Percent 
    };

    return Put<boolean>(`api/Jobs/${jobId}/Splits`, options, data);
}

export async function GetCandidateReferenceCheckCount(jobId: number, candidateId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<ReferenceCheck>(`api/Jobs/${jobId}/candidates/${candidateId}/reference-check`, options);
}

export async function GetJobMessages(jobId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<ActivityMessage[]>(`api/jobs/${jobId}/messages`, options);
}

export async function SaveJobsSearch(searchName: string, searchFrequency: number, data: JobSearch, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<NumberValueWrapper>(`api/jobs/search/${searchFrequency}?name=${encodeURIComponent(searchName)}`, options, data);
}

export async function UpdateJobsSavedSearch(ssid: number, searchName: string, searchFrequency: number, data: JobSearch, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/jobs/search/${ssid}?freqId=${searchFrequency}&name=${encodeURIComponent(searchName)}`, options, data);
}

export async function GetJobPartnerActions(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<PartnerAction[]>('api/jobs/partneractions', options);
}

export async function DownloadJobDescriptionForViewer(jobId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return DownloadFile(`api/jobs/${jobId}/Description/Pdf`, options);
}

export async function DownloadJobDescriptionOriginal(jobId: number, fileName?: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    const file = await DownloadFile(`api/jobs/${jobId}/Description/Original`, options);
    if (file) {
        DownloadBlob(file.data, fileName ? fileName + file.fileExtension : file.fileName);
    }
}

export async function UploadJobDescription(jobId: number, files: File[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
        const f = files[i];
        formData.append('files', f);
    }

    return PostFile<boolean>(`api/jobs/${jobId}/Description`, formData, options);
}

export async function UnlinkCandidatesFromJob(jobId: number, candidateId: number[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/jobs/${jobId}/UnlinkCandidates`, options, candidateId);
}

export async function GetJobInvoices(jobId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return await Get<Invoice[]>(`api/jobs/${jobId}/invoices`, options);
}

export async function CreateJobInvoice(jobId: number, data: CreateInvoice, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<NumberValueWrapper>(`api/jobs/${jobId}/invoice`, options, data);
}