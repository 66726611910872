import { OrderByObject } from "./Automation";

export interface AutomationPreviewData {
    nextRunOnly: boolean,
    maxRecords: number,
    automationId: number,
    orderBy: OrderByObject
}

export const DefaultPreviewData: AutomationPreviewData = {
    nextRunOnly: false,
    maxRecords: 1000,
    automationId:0,
    orderBy: {
        columnName: 'Start Date',
        isAscending: true
    }
}