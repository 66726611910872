import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { GetCandidateReferenceCheckCount } from "services/JobsService";
import ConfirmationDialog from "../Generic/ConfirmationDialog";
import { GetPlacementsSettings } from "services/ConfigurationService";

interface Props {
    open: boolean,
    jobId: number,
    jobType: string,
    candidateIds: number[],
    canBulkPlace?: boolean,
    closeHandler: () => void,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
}

export default function PlaceCandidatesDialog({ open, jobId, jobType, candidateIds, canBulkPlace = false, closeHandler, loadingHandler, errorHandler }: Props) {
    const [fetchedReferenceCheck, setFetchedReferenceCheck] = useState(false);
    const [warningMessage, setWarningMessage] = useState('');
    const [placementTypeId, setPlacementTypeId] = useState(0);

    useEffect(() => {
        if (open) {
            switch (jobType) {
                case 'Permanent':
                    setPlacementTypeId(1);
                    break;
                case 'Contract':
                    setPlacementTypeId(2);
                    break;
                case 'FixedContract':
                    setPlacementTypeId(3);
                    break;
                default:
                    setPlacementTypeId(1);
                    break;
            }
        }
    }, [open, jobType]);

    const createPath = useMemo(() => {
        if (canBulkPlace && candidateIds.length > 1) {
            const ids = candidateIds.join(';');
            return `/placements/create?jobId=${jobId}&typeId=${placementTypeId}&candidateIds=${encodeURIComponent(ids)}`;
        }
        if (candidateIds.length > 0) {
            return `/placements/create?jobId=${jobId}&typeId=${placementTypeId}&candidateId=${candidateIds[0]}`;
        }

        return '';
    }, [jobId, canBulkPlace, candidateIds, placementTypeId]);

    useEffect(() => {
        if (!open) {
            setWarningMessage('');
            setFetchedReferenceCheck(false);
        }
    }, [open]);

    useEffect(() => {
        const getData = async () => {
            loadingHandler && loadingHandler(true);
            const settings = await GetPlacementsSettings(errorHandler);
            if (settings) {
                const check = settings.find(s => s.type === 'PlacementsCheckForRefCheck');
                if (check && check.value === 'True') {
                    const candidateId = candidateIds[0];
                    const res = await GetCandidateReferenceCheckCount(jobId, candidateId, errorHandler);
                    if (res && res.referenceCheckCount === 0) setWarningMessage(`The selected candidate hasn't been reference checked. Would you like to continue?`);
                }
            }
            setFetchedReferenceCheck(true);
            loadingHandler && loadingHandler(false);
        };
        open && candidateIds.length === 1 && getData();
    }, [open, jobId, candidateIds, loadingHandler, errorHandler]);
    
    return (
        <>
            <ConfirmationDialog
                open={open && Boolean(warningMessage) && fetchedReferenceCheck}
                message={warningMessage}
                onClose={closeHandler}
                title="Place Candidate(s)"
                onContinue={() => setWarningMessage('')}
            />
            <Dialog open={open && !Boolean(warningMessage) && (fetchedReferenceCheck || candidateIds.length > 1)} fullWidth>
                <DialogTitle>Place Candidate(s)</DialogTitle>
                <DialogContent>
                    <TextField select label="Placement Type" value={placementTypeId.toString()} onChange={({target}) => setPlacementTypeId(+target.value)} margin="dense" fullWidth>
                        <MenuItem value="1">Permanent</MenuItem>
                        <MenuItem value="2">Contract</MenuItem>
                        <MenuItem value="3">Fixed Term</MenuItem>
                        <MenuItem value="4">Maximum Term</MenuItem>
                        <MenuItem value="5">Margin Only</MenuItem>
                    </TextField>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="error" onClick={ closeHandler }>Cancel</Button>
                    {createPath === '' &&
                        <Button variant="contained" color="success" disabled >Submit</Button>
                    }
                    {Boolean(createPath) &&
                        <Link to={createPath} style={{ marginLeft: '.5rem' }}>
                            <Button variant="contained" color="success" >Submit</Button>
                        </Link>
                    }
                </DialogActions>
            </Dialog>
        </>
    );
}