import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import moment from "moment";
import React, { useCallback, useMemo, useState } from "react";
import { GetCurrentJobForPreviouslyPlacedCandidatesReportData } from "services/CandidatesService";
import { GridColDef, GridRenderCellParams, GridValueGetter, useGridApiRef } from "@mui/x-data-grid-premium";
import { CurrentJobForPreviouslyPlacedCandidatesReportData } from "common/models/Reports/CurrentJobForPreviouslyPlacedCandidatesReport";
import GridWithStateWrapper from "components/GridWidthStateWrapper";
import { Link } from "react-router-dom";
import { PreviewEntityType } from "common/models/Previews/Previews";
import PreviewLoaderComponent from "components/Previews/PreviewLoader";

interface Props {
    description: string,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
}

const linkStyle: React.CSSProperties = { color: 'inherit', textDecoration: 'underline' };

export default function CurrentJobForPreviouslyPlacedCandidatesReport({ description, loadingHandler, errorHandler }: Props) {
    const [rows, setRows] = useState<CurrentJobForPreviouslyPlacedCandidatesReportData[]>([]);
    const [showGrid, setShowGrid] = useState(false);
    const apiRef = useGridApiRef();
    
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    const [showTagsPrevewNoDelay, setShowTagsPreviewNoDelay] = useState(false);
    const [previewType, setPreviewType] = useState<PreviewEntityType | ''>('');
    const [previewRecordId, setPreviewRecordId] = useState(0);
    const [isPreviewTags, setIsPreviewTags] = useState(false);
    const [previewRecordName, setPreviewRecordName] = useState('');

    const getDataCallback = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        const res = await GetCurrentJobForPreviouslyPlacedCandidatesReportData(errorHandler);
        if (res) {
            setShowGrid(true);
            setRows(res);
        }
        loadingHandler && loadingHandler(false);
    }, [errorHandler, loadingHandler]);

    const exportAsExcelHandler = useCallback(() => {
        const api = apiRef.current;
        if (api) {
            const m = moment();
            const filename = `CurrentJobForPreviouslyPlacedCandidates${m.format('YYYYMMDDhhmmss')}`;
            api.exportDataAsExcel({ fileName: filename });
        }
    }, [apiRef]);

    
    const handlePreviewHover = (type: PreviewEntityType | '', id: number, isTags: boolean = false, recordName: string = '', noDelay: boolean = false) => {
        setPreviewType(type);
        setPreviewRecordId(id);
        setIsPreviewTags(isTags);
        setPreviewRecordName(recordName);
        setIsPreviewOpen(true);
        setShowTagsPreviewNoDelay(noDelay);
    };

    const handlePreviewClose = () => {
        setIsPreviewOpen(false);
        setShowTagsPreviewNoDelay(false);
    };

    const columns = useMemo<GridColDef[]>(() => {
        const dateValueGetter: GridValueGetter<CurrentJobForPreviouslyPlacedCandidatesReportData, any, undefined, string> = (value) => {
            if (value) {
                const d = moment(value);
                if (d.isValid() && d.get("year") > 1) {
                    return d.toDate();
                }
            }
            return null;
        };

        const dateRenderer = (params: GridRenderCellParams) => {
            if (params.value) {
                return moment(params.value).format('DD MMM YYYY');
            }
            return 'Never';
        };
        
        const linkToCandidateDetail = (params: GridRenderCellParams) => {
            return (
                <Link
                    style={ linkStyle }
                    onMouseEnter={ () => handlePreviewHover('candidate', +params.id) }
                    onMouseLeave={ handlePreviewClose }
                    to={`/candidates/${params.id}`}
                >{params.value}</Link>
            );
        };
        return [
            { field: "FullName", headerName: "Candidate Name", width: 150, renderCell: linkToCandidateDetail },
            { field: "Country", headerName: "Country", width: 120 },
            { field: "CurrentJobTitle", headerName: "Current Job Title", width: 150 },
            { field: "CurrentEmployer", headerName: "Current Employer", width: 150 },
            { field: "PrefJobType", headerName: "Pref Job Type", width: 120 },
            { field: "PlacementJobTitle", headerName: "Placement Job Title", width: 200 },
            { field: "PlacementClient", headerName: "Placement Client", width: 150 },
            { field: "PlacementStartDate", headerName: "Placement Start Date", width: 200, type: 'date', valueGetter: dateValueGetter, renderCell: dateRenderer },
            { field: "PlacementConsultant", headerName: "Placement Consultant", width: 200 },
            { field: "OriginallyPlacedDate", headerName: "Originally Placed", width: 200, type: 'date', valueGetter: dateValueGetter, renderCell: dateRenderer },
            { field: "OriginallyPlacedBy", headerName: "Originally Placed By", width: 200 },
        ];
    }, []);

    return (
        <>
            <PreviewLoaderComponent
                open={isPreviewOpen}
                entityType={previewType}
                recordId={previewRecordId}
                isTagsPreview={isPreviewTags}
                showDelayMs={showTagsPrevewNoDelay ? 0 : undefined}
                titleOverride={previewRecordName}
            />
            <Box display="flex" justifyContent="flex-end">
                <Button variant="contained" color="success" onClick={getDataCallback}>Run Report</Button>
            </Box>
            <Typography component="div" variant="h6" mt={2}>Report Description</Typography>
            <Typography component="div" variant="body2" mb={2}>{description}</Typography>
            {showGrid &&
                <>
                    <Box pb="10px" ml="auto">
                        <Button variant="contained" color="success" onClick={exportAsExcelHandler}>Export As Excel</Button>
                    </Box>
                    <GridWithStateWrapper
                        density="compact"
                        gridName="reports-candidates-currentjob"
                        rows={rows}
                        columns={columns}
                        apiRef={apiRef}
                        getRowId={r => r.CandidateID}
                        disableRowSelectionOnClick
                    />
                </>
            }
        </>
    );
}
