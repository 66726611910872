import { ErrorHandler, NumberValueWrapper, RequestOptions, StringValueWrapper } from "common/models/GenericTypes";
import { Interview, InterviewAttendee, InterviewDocument, InterviewEdit } from "common/models/Interviews";
import { Get, Post, PostFile, Put, PutFile } from "../util/HttpUtils";
import {InterviewType} from "common/models/Configuration/InterviewType";

export async function GetInterviewsReportData(consultantId: number, timeRange: number, status: number, errorHandler?: ErrorHandler){
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Interview[]>(`api/Interviews/Search?consultant=${consultantId}&timeRange=${timeRange}&status=${status}`, options);
}

export async function CancelInterview(interviewId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/Interviews/${interviewId}/Cancel`, options);
}

export async function GetInterviewById(interviewId: number, errorHandler?: ErrorHandler){
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Interview>(`api/Interviews/${interviewId}`, options);
}

export async function GetInterviewAttendees(interviewId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<InterviewAttendee[]>(`api/Interviews/${interviewId}/attendees`, options);
}

export async function GetInterviewDocuments(interviewId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<InterviewDocument[]>(`api/Interviews/${interviewId}/documents`, options)
}

export async function AddNotesToInterview(interviewId: number, notes: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/Interviews/${interviewId}/Notes`, options, { value: notes });
}

export async function AddNotesToInterviewWithFollowUp(interviewId: number, notes: string, followUpUserId: number, followUpDate: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/Interviews/${interviewId}/Notes`, options, { value: notes, followUpDate: followUpDate, followUpUserId: followUpUserId });
}

export async function CreateInterview(data: InterviewEdit, candidateFiles: File[], clientFiles: File[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    const formData = new FormData();
    formData.append('interviewData', JSON.stringify(data));
    for (let i = 0; i < candidateFiles.length; i++) {
        const f = candidateFiles[i];
        formData.append('candidateFiles', f);
    }
    for (let i = 0; i < clientFiles.length; i++) {
        const f = clientFiles[i];
        formData.append('clientFiles', f);
    }

    return PostFile<NumberValueWrapper>('api/Interviews', formData, options);
}

export async function UpdateInterview(interviewId: number, data: InterviewEdit, candidateFiles: File[], clientFiles: File[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    const formData = new FormData();
    formData.append('interviewData', JSON.stringify(data));
    for (let i = 0; i < candidateFiles.length; i++) {
        const f = candidateFiles[i];
        formData.append('candidateFiles', f);
    }
    for (let i = 0; i < clientFiles.length; i++) {
        const f = clientFiles[i];
        formData.append('clientFiles', f);
    }

    return PutFile<boolean>(`api/Interviews/${interviewId}`, formData, options);
}

export async function GetInterviewMessagePreview(interviewId: number, data: InterviewEdit, messageType: 'candidate' | 'client', errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<StringValueWrapper>(`api/Interviews/${interviewId}/Preview?messageType=${messageType}`, options, data);
}

export async function CreateType(interviewType: InterviewType, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>(`api/Configuration/InterviewTypes`, options, interviewType);
}

export async function UpdateType(interviewTypeId: number, interviewType: InterviewType, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/Configuration/InterviewTypes/${interviewTypeId}`, options, interviewType);
}

export async function GetInterviewTypes(errorHandler?: ErrorHandler){
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<InterviewType[]>(`api/Configuration/InterviewTypes`, options);
}

export async function GetInterviewTypeById(typeId: number, errorHandler?: ErrorHandler){
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<InterviewType>(`api/Configuration/InterviewTypes/${typeId}`, options);
}

export async function GetActiveInterviewTypes(errorHandler?: ErrorHandler){
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<InterviewType[]>(`api/Configuration/InterviewTypes/Active`, options);
}