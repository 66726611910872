import React, { useEffect, useMemo, useState } from "react";
import { DataGridPremium, GridColDef, GridRenderCellParams } from "@mui/x-data-grid-premium";
import Alert from "components/Alert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import Snackbar from "@mui/material/Snackbar";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';

import OnlyTitleSummaryBar from "components/SummaryBars/OnlyTitleSummaryBar";
import PageContentLayout from "layouts/PageContentLayout";
import GreenIcon from "assets/icons/jobs/Candidate_Green_Small.png";
import YellowIcon from "assets/icons/jobs/Candidate_Yellow_Small.png";
import BlueIcon from "assets/icons/jobs/Candidate_Blue_Small.png";
import RedIcon from "assets/icons/jobs/Candidate_Red_Small.png";
import { GetCachedRatingReasons, GetRatingReasons, SaveRatingReasons } from "services/ConfigurationService";
import { RatingReason } from "common/models/Configuration/Jobs";

interface Props {
    setSummaryBar?: (summaryBar: JSX.Element) => void
}

interface RatingReasonRow {
    actionType: number,
    typeName: string,
    imgSrc: string
}

const SummaryBar = <OnlyTitleSummaryBar title="Configuration > Jobs > Rating Reasons" browserTabTitle="Jobs > Configuration" />;

const gridData: RatingReasonRow[] = [
    {
        actionType: 4,
        typeName: 'green',
        imgSrc: GreenIcon
    },
    {
        actionType: 2,
        typeName: 'yellow',
        imgSrc: YellowIcon
    },
    {
        actionType: 1,
        typeName: 'blue',
        imgSrc: BlueIcon
    },
    {
        actionType: 0,
        typeName: 'red',
        imgSrc: RedIcon
    },
];


export default function RatingReasonsAdmin({ setSummaryBar }: Props) {
    const [isLoading, setIsLoading] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [selectedRow, setSelectedRow] = useState<RatingReasonRow | null>(null);
    const [ratingReasons, setRatingReasons] = useState<RatingReason[]>([]);
    const [newRatingReason, setNewRatingReason] = useState('');
    const [deletedRatingReasons, setDeletedRatingReasons] = useState<number[]>([]);

    useEffect(() => {
        setSummaryBar && setSummaryBar(SummaryBar);
    }, [setSummaryBar]);

    const columns = useMemo<GridColDef[]>(() => {
        const typeRenderer = (params: GridRenderCellParams) => (
            <img src={params.row.imgSrc} alt={params.row.typeName} />
        );

        const getValues = async (actionType: number) => {
            setIsLoading(true);
            const values = await GetRatingReasons(actionType, setErrorMessage);
            if(values) setRatingReasons(values);
            setIsLoading(false);
        }

        const valuesRenderer = (params: GridRenderCellParams) => (
            <span
                style={{ cursor: 'pointer', textDecoration: 'underline' }}
                onClick={ async () => {
                    await getValues(params.row.actionType);
                    setSelectedRow(params.row);
                } }
            >
                Edit Values
            </span>
        )

        return [
            { field: 'actionType', headerName: 'Rating Type', align: 'center', renderCell: typeRenderer, sortable: false },
            { field: 'values', headerName: 'Values', renderCell: valuesRenderer, sortable: false },
        ];

    }, []);

    const handleCloseDialog = () => {
        setSelectedRow(null);
        setRatingReasons([]);
    };

    const changeRatingReasonHandler = (index: number, val: string) => {
        setRatingReasons(prev => {
            let newReasons = [...prev];
            newReasons[index].value = val;
            return newReasons;
        })
    }

    const addRatingReasonHandler = () => {
        if (selectedRow) {
            setRatingReasons(prev => {
                let newReasons: RatingReason[] = [...prev, { id: 0, actionType: selectedRow.actionType, value: newRatingReason }];
                setNewRatingReason('');
                return newReasons;
            });
        }
    }

    const deleteRatingReasonHandler = (index: number) => {
        setRatingReasons(prev => {
            const deletedId = prev[index].id;
            if(deletedId !== 0) setDeletedRatingReasons(prev => [...prev, deletedId]);
            let newReasons = [...prev];
            newReasons.splice(index, 1);
            return newReasons;
        });
    }

    const saveRatingReasonsHandler = async () => {
        if(selectedRow) {
            setIsLoading(true);
            const res = await SaveRatingReasons(selectedRow.actionType, ratingReasons, deletedRatingReasons, setErrorMessage);
            if(!res) {
                setIsLoading(false);
                return false;
            }
            GetCachedRatingReasons(true);
            setShowSuccess(true);
            setRatingReasons([]);
            setSelectedRow(null);
            setIsLoading(false);
        }
        return true;
    };

    return (
        <>
            <Snackbar open={showSuccess} autoHideDuration={3000} onClose={() => setShowSuccess(false)}>
                <Alert onClose={() => setShowSuccess(false)}>Changes Saved</Alert>
            </Snackbar>
            <Snackbar open={errorMessage !== ''}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            {selectedRow &&
                <Dialog open={true} onClose={ handleCloseDialog } fullWidth>
                    <DialogTitle>
                        <img src={selectedRow.imgSrc} alt={selectedRow.typeName} style={{ paddingRight: '20px' }} />
                        Rating Reasons
                    </DialogTitle>
                    <DialogContent dividers>
                        <div>
                            <span style={{ width: '85%', display: 'inline-block', textAlign: 'center' }}>Reason</span>
                            <span style={{ width: '15%', display: 'inline-block', textAlign: 'center' }}>Action</span>
                        </div>
                        <div style={{ paddingBottom: '20px' }}>
                            <Input placeholder="New Rating Reason" sx={{ width: '85%' }} size="small" value={newRatingReason} onChange={ ({target}) => setNewRatingReason(target.value) } />
                            <span style={{ width: '15%', display: 'inline-block', textAlign: 'center' }}>
                                <IconButton disabled={newRatingReason === ''} onClick={addRatingReasonHandler}><AddCircleIcon /></IconButton>
                            </span>
                        </div>
                        <Divider />
                        {ratingReasons.map((v, i) => (
                            <div key={i} style={{ paddingBottom: '10px' }}>
                                <Input sx={{ width: '85%' }} value={v.value} onChange={ ({target}) => changeRatingReasonHandler(i, target.value) } />
                                <span style={{ width: '15%', display: 'inline-block', textAlign: 'center' }}>
                                    <IconButton onClick={ () => deleteRatingReasonHandler(i) }><DeleteIcon /></IconButton>
                                </span>
                            </div>
                        ))}
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="error" onClick={ handleCloseDialog }>Cancel</Button>
                        <Button variant="contained" color="success" onClick={ saveRatingReasonsHandler }>Save</Button>
                    </DialogActions>
                </Dialog>
            }
            <PageContentLayout title="Rating Reasons" showLoading={isLoading}>
                <div style={{ height: '100%', width: '100%', minHeight: '400px' }}>
                    <div style={{ display: 'flex', height: '100%' }}>
                        <div style={{ flexGrow: 1 }}>
                            <DataGridPremium
                                disableColumnMenu
                                disableColumnReorder
                                disableColumnResize
                                getRowId={ row => row.actionType}
                                rows={gridData}
                                columns={columns}
                                disableRowSelectionOnClick
                                hideFooter
                            />
                        </div>
                    </div>
                </div>
            </PageContentLayout>
        </>
    );
}