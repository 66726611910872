import React, { useCallback, useMemo } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import DeleteIcon from '@mui/icons-material/Delete';

import { AutomationFilter } from "common/models/Configuration/Automation";
import { NameIdObj, NameIdObjString } from "common/models/GenericTypes";
import Collapse from "@mui/material/Collapse";
import { Division } from "common/models/Configuration/Division";
import MultipleNumericValuesPicker from "components/Pickers/MultipleNumericValuesPicker";
import MultipleStringValuesPicker from "components/Pickers/MultipleStringValuesPicker";
import { PlacementStatusEnum } from "util/Definitions/Configuration/Placements";
import { CustomFieldWithPredefinedValues } from "common/models/Configuration/CustomFields";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import RWTextFieldComponent from "components/RWTextFieldComponent";

interface Props {
    index: number,
    entityId: number,
    filter: AutomationFilter,
    divisions?: Division[],
    jobSources?: NameIdObjString[],
    placementSources?: NameIdObjString[],
    countries?: NameIdObjString[],
    customFields?: CustomFieldWithPredefinedValues[],
    isTimeBased: boolean,
    onChange: (index: number, output: AutomationFilter) => void,
    onDelete: (index: number) => void,
}

interface PropertyOptions {
    value: string,
    label: string,
    eventBased: boolean,
    timeBased: boolean,
    moduleFilter: boolean
}

type PropertiesByEntityId = Record<number, PropertyOptions[]>;


const propertiesByEntity: PropertiesByEntityId = {
    1: [
        { label: 'Custom Field', value: 'custom-field', eventBased: true, timeBased: false, moduleFilter: false },
    ],
    2: [
        { label: 'Custom Field', value: 'custom-field', eventBased: true, timeBased: false, moduleFilter: false },
    ],
    3: [
        { label: 'Custom Field', value: 'custom-field', eventBased: true, timeBased: true , moduleFilter: false},
        { label: 'Where date of birth is today', value: 'module-filter_birthday_today', eventBased: false, timeBased: true , moduleFilter: true },
        { label: 'CV older than X days', value: 'module-filter_cv_older', eventBased: false, timeBased: true , moduleFilter: true},
        { label: 'Update date older than X days', value: 'module-filter_update_date', eventBased: false, timeBased: true, moduleFilter: true },
        { label: 'Last Contact Date older than X days', value: 'module-filter_last_contact_date', eventBased: false, timeBased: true, moduleFilter: true },
        { label: 'Is Current Contractor', value: 'module-filter_is_current_contractor', eventBased: false, timeBased: true, moduleFilter: true },
        { label: 'Is Not Current Contractor', value: 'module-filter_is_not_current_contractor', eventBased: false, timeBased: true, moduleFilter: true },
    ],
    4: [
        { label: 'Type', value: 'type', eventBased: true, timeBased: false, moduleFilter: false},
        { label: 'Division', value: 'division', eventBased: true, timeBased: false, moduleFilter: false},
        { label: 'Stage', value: 'stage', eventBased: true, timeBased: false, moduleFilter: false},
        { label: 'Status', value: 'status', eventBased: true, timeBased: false, moduleFilter: false},
        { label: 'Source', value: 'source', eventBased: true, timeBased: false, moduleFilter: false},
        { label: 'Custom Field', value: 'custom-field', eventBased: true, timeBased: false, moduleFilter: false },
    ],
    5: [
        { label:'Type', value: 'type', eventBased: true, timeBased: true, moduleFilter: false},
        { label:'Division', value: 'division', eventBased: true, timeBased: true, moduleFilter: false},
        { label:'Status', value: 'status', eventBased: true, timeBased: true, moduleFilter: false},
        { label:'Source', value: 'source', eventBased: true, timeBased: true, moduleFilter: false},
        { label:'Payment Type', value: 'payment-type', eventBased: true, timeBased: true, moduleFilter: false},
        { label:'Pay Frequency', value: 'pay-frequency', eventBased: true, timeBased: false, moduleFilter: false},
        { label:'Country', value: 'country', eventBased: true, timeBased: true, moduleFilter: false},
        { label: 'Custom Field', value: 'custom-field', eventBased: true, timeBased: true , moduleFilter: false},
        { label: 'Start Date', value: 'module-filter_start_date', eventBased: false, timeBased: true , moduleFilter: true},
        { label: 'End Date', value: 'module-filter_end_date', eventBased: false, timeBased: true , moduleFilter: true},
    ],
    8: [
        { label: 'Type', value: 'type', eventBased: true, timeBased: false, moduleFilter: false},
        { label: 'Stage', value: 'stage', eventBased: true, timeBased: false, moduleFilter: false},
        { label: 'Status', value: 'status', eventBased: true, timeBased: false, moduleFilter: false},
        { label: 'Custom Field', value: 'custom-field', eventBased: true, timeBased: false, moduleFilter: false },
    ]
};

const opportunityTypes: NameIdObj[] = [
    { id: 1, name: 'Back Fill' },
    { id: 2, name: 'Tender' },
    { id: 3, name: 'Float' },
    { id: 4, name: 'Bid' },
    { id: 5, name: 'General' },
    { id: 6, name: 'Lead' },
];

const opportunityStages: NameIdObj[] = [
    { id: 1, name: 'Lead'},
    { id: 2, name: 'Opportunity'},
    { id: 3, name: 'Converted'},
];

const opportunityStatus: NameIdObj[] = [
    { id: 1, name: 'Open' },
    { id: 2, name: 'Closed' },
];

const jobTypes: NameIdObjString[] = [
    { id: 'Contract', name: 'Contract' },
    { id: 'FixedContract', name: 'Fixed Term' },
    { id: 'Panel', name: 'Panel' },
    { id: 'Permanent', name: 'Permanent' },
    { id: 'TalentPool', name: 'Talent Pool' },
];

const jobStages: NameIdObj[] = [
    { id: 0, name: 'Resourcing' },
    { id: 1, name: 'Shortlist' },
    { id: 2, name: 'Submission' },
    { id: 3, name: 'Interview' },
    { id: 4, name: 'Offer' },
    { id: 5, name: 'Placed' },
];

const jobStatus: NameIdObjString[] = [
    { id: 'Closed', name: 'Closed' },
    { id: 'Hold', name: 'Hold' },
    { id: 'Open', name: 'Open' },
];

const placementTypes: NameIdObj[] = [
    { id: 1, name: 'Permanent' },
    { id: 2, name: 'Contract' },
    { id: 3, name: 'Fixed Term' },
    { id: 4, name: 'Maximum Term' },
    { id: 5, name: 'Margin Only' },
];

const placementStatus: NameIdObj[] = [
    { id: PlacementStatusEnum.Future, name: 'Future'},
    { id: PlacementStatusEnum.Warranty, name: 'Warranty'},
    { id: PlacementStatusEnum.Current, name: 'Current'},
    { id: PlacementStatusEnum.Completed, name: 'Completed'},
    { id: PlacementStatusEnum.Deleted, name: 'Deleted'},
    { id: PlacementStatusEnum.PendingCancellation, name: 'Pending Cancellation'},
    { id: PlacementStatusEnum.Cancelled, name: 'Cancelled'},
    { id: PlacementStatusEnum.PendingEarlyTermination, name: 'Pending Termination'},
    { id: PlacementStatusEnum.EarlyTerminated, name: 'Terminated'},
];

const placementPaymentTypes: NameIdObj[] = [
    { id: 1, name: 'Employee' },
    { id: 2, name: 'Sub Contractor' },
];

const placementPayFrequencyOptions: NameIdObj[] = [
    { id: 1, name: 'Weekly' },
    { id: 5, name: 'Weekly (1 Week in Arrears)' },
    { id: 6, name: 'Weekly (3 Weeks in Arrears)' },
    { id: 2, name: 'Fortnightly' },
    { id: 3, name: 'Monthly' },
    { id: 4, name: '4 Weekly' },
];

const generalFilterTypes: NameIdObj[] = [
    { id: 1, name: 'Includes' },
    { id: 0, name: 'Excludes' },
];

const onlyStringFilterTypes: NameIdObj[] = [
    ...generalFilterTypes,
    { id: 2, name: 'Contains' },
    { id: 3, name: 'Does not contains' },
];

export default function AutomationFilterComponent({ index, entityId, filter, divisions, jobSources, placementSources, countries, customFields, isTimeBased, onChange, onDelete }: Props) {

    const onPropertyChangeHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        if (value !== 'source' && value !== 'custom-field' && (filter.type === 2 || filter.type === 3))
            onChange(index, { ...filter, property: value, type: 1, moduleFilterValues: {days:0,isBusinessDays:false, isFutureDate: false} });
        else 
            onChange(index, { ...filter, property: value, moduleFilterValues: {days:0,isBusinessDays:false, isFutureDate: false} });
    }, [filter, index, onChange]);

    const stringChangeHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        onChange(index, { ...filter, [name]: value, moduleFilterValues: {days:0,isBusinessDays:false, isFutureDate: false} });
    }, [filter, index, onChange]);

    const onFilterTypeChangeHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        onChange(index, { ...filter, type: +value })
    }, [filter, index, onChange]);

    const availableProperties = useMemo(() => {
        const f = propertiesByEntity[entityId];
        if (f) return f;
        return [];
    }, [entityId]);

    const onNumericValuesChange = useCallback((values: NameIdObj[]) => {
        const vals = values.map(v => v.id);
        onChange(index, { ...filter, numericValues: vals, stringValues: [] });
    }, [filter, index, onChange]);

    const onStringValuesChange = useCallback((values: NameIdObjString[]) => {
        const vals = values.map(v => v.id);
        onChange(index, { ...filter, stringValues: vals, numericValues: [] });
    }, [filter, index, onChange]);

    const availablePredefinedValues = useMemo<NameIdObjString[]>(() => {
        if (customFields && filter.customField) {
            const cf = customFields.find(f => f.name === filter.customField);
            if (cf && cf.values) return cf.values.map(v => ({ id: v.value, name: v.value }));
        }
        return [];
    }, [customFields, filter.customField]);

    const updateFilterFuterDaysChanged = useCallback((value:boolean) => {
        let baseFilter = {...filter.moduleFilterValues};
        if(!baseFilter) {
            baseFilter = {days:0,isBusinessDays: false, isFutureDate: value}
        }else{
            baseFilter.isFutureDate = value;
        }
        onChange(index, { ...filter, moduleFilterValues: baseFilter, customField: '', numericValues: [], stringValues: []});
    }, [filter, index, onChange]);

    const updateFilterDaysTypeChanged = useCallback((value:boolean) => {
        let baseFilter = {...filter.moduleFilterValues};
        if(!baseFilter) {
            baseFilter = {days:0,isBusinessDays: value, isFutureDate: false}
        }else{
            baseFilter.isBusinessDays = value;
        }
        onChange(index, { ...filter, moduleFilterValues: baseFilter, customField: '', numericValues: [], stringValues: []});
    }, [filter, index, onChange]);
    
    const updateFilterDaysChanged = useCallback((value:number) => {
        let baseFilter = {...filter.moduleFilterValues};
        if(!baseFilter) {
            baseFilter = {days:value,isBusinessDays: false, isFutureDate: false}
        }else{
            baseFilter.days = value;
        }
        onChange(index, { ...filter, moduleFilterValues: baseFilter, customField: '', numericValues: [], stringValues: []});
    }, [filter, index, onChange]);


    const handleDaysTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        updateFilterDaysTypeChanged(stringToBoolean(event.target.value));
    }

    const handleBaseFilterDaysChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        updateFilterDaysChanged(+event.target.value);
    }

    const handleFuterDaysChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        updateFilterFuterDaysChanged(stringToBoolean(event.target.value));
    }

    const stringToBoolean = (value: string):boolean => {
        return JSON.parse(value.toLowerCase());
    }

    const availableFilterTypes = useMemo(() => {
        if (filter.property === 'custom-field' || filter.property === 'source') return onlyStringFilterTypes;
        return generalFilterTypes;
    }, [filter.property]);

    const isFreeTextFilter = useMemo(() => filter.type === 2 || filter.type === 3, [filter.type]);

    return (
        <Paper variant="outlined" sx={{ p: 2 }}>
            <Collapse in={true}>
                <Stack spacing={2}>
                    <Box display="flex" gap={2}>
                        {(filter.property === 'module-filter_start_date' || filter.property === 'module-filter_end_date') &&
                            <>
                                <RWTextFieldComponent 
                                    sxOptions={{ flex: '0.2 0 0' }}
                                    type="number"
                                    label="Number of Days"
                                    name="Number of days"
                                    value={filter?.moduleFilterValues?.days.toString() ?? ''}
                                    onChange={handleBaseFilterDaysChange}
                                />
                                <TextField
                                    select
                                    sx={{ flex: '0.2 0 0' }}
                                    label="Day type"
                                    name="dayType"
                                    value={filter?.moduleFilterValues?.isBusinessDays}
                                    onChange={handleDaysTypeChange}
                                >
                                    <MenuItem value="true">Business Days</MenuItem>
                                    <MenuItem value="false">Calendar Days</MenuItem>
                                </TextField>
                                <TextField
                                    select
                                    sx={{ flex: '0.2 0 0' }}
                                    label="Interval"
                                    name="interval"
                                    value={filter?.moduleFilterValues?.isFutureDate}
                                    onChange={handleFuterDaysChange}
                                >
                                    <MenuItem value="true">After</MenuItem>
                                    <MenuItem value="false">Before</MenuItem>
                                </TextField>
                                <TextField
                                    select
                                    label="Filter Property"
                                    name="property"
                                    value={filter.property}
                                    onChange={stringChangeHandler}
                                    sx={{ flex: '1 0 0' }}
                                >
                                    <MenuItem value=""></MenuItem>
                                    { isTimeBased
                                        ? availableProperties.filter(a => a.timeBased).map(p => <MenuItem key={p.value} value={p.value}>{p.label}</MenuItem>)
                                        : availableProperties.filter(a => a.eventBased).map(p => <MenuItem key={p.value} value={p.value}>{p.label}</MenuItem>)
                                    }
                                </TextField>
                            </>
                        }

                        {(filter.property !== 'module-filter_start_date' && filter.property !== 'module-filter_end_date') &&
                            <TextField
                                select
                                label="Filter Property"
                                name="property"
                                value={filter.property}
                                onChange={onPropertyChangeHandler}
                                sx={{ flex: '1 1 0' }}
                            >
                                <MenuItem value=""></MenuItem>
                                { isTimeBased 
                                    ? availableProperties.filter(a => a.timeBased).map(p => <MenuItem key={p.value} value={p.value}>{p.label}</MenuItem>)
                                    : availableProperties.filter(a => a.eventBased).map(p => <MenuItem key={p.value} value={p.value}>{p.label}</MenuItem>)
                                }
                            </TextField>
                        }
                      
                        { filter.property === 'custom-field' &&
                            <TextField
                                select
                                label="Custom Field"
                                value={filter.customField}
                                name="customField"
                                onChange={stringChangeHandler}
                                sx={{ flex: '1 1 0' }}
                            >
                                { customFields && customFields.map(cf => <MenuItem key={cf.id} value={cf.name}>{cf.agencyName}</MenuItem>) }
                            </TextField>
                        }
                        {filter.property.indexOf("module-filter") === -1 ? 
                            <TextField
                                select
                                label="Filter Type"
                                name="include"
                                value={filter.type.toString()}
                                onChange={onFilterTypeChangeHandler}
                                sx={{ flex: '1 1 0' }}
                            >
                                { availableFilterTypes.map(t => <MenuItem key={t.id} value={t.id}>{t.name}</MenuItem>) }
                            </TextField>
                            : ''
                        }
                        <IconButton size="small" onClick={() => onDelete(index)} color="error" sx={{ marginLeft: 'auto' }}>
                            <DeleteIcon />
                        </IconButton>
                    </Box>
                    <Box display="flex" gap={2}>
                        {(entityId === 3 || entityId === 5) && 
                                        (filter.property === 'module-filter_cv_older' ||
                                            filter.property === 'module-filter_update_date' || 
                                            filter.property === 'module-filter_last_contact_date') &&
                            <>
                                <RWTextFieldComponent 
                                    sxOptions={{ flex: '0.2 0 0' }}
                                    type="number"
                                    label="Number of Days"
                                    name="Number of days"
                                    value={filter?.moduleFilterValues?.days.toString() ?? ''}
                                    onChange={handleBaseFilterDaysChange}
                                />
                                <RadioGroup
                                    sx={{ flex: '1 1 0' }}
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    value={filter?.moduleFilterValues?.isBusinessDays}
                                >
                                    <FormControlLabel control={<Radio value="false"  onChange={handleDaysTypeChange} />} label="Calendar Days"/>
                                    <FormControlLabel  control={<Radio value="true"  onChange={handleDaysTypeChange}/>}  label="Business Days" />
                                </RadioGroup>
                            </> 
                        }
                        {entityId === 4 && filter.property === 'type' &&
                            <Box sx={{ flex: '1 1 0', alignItems: "flex-start" }}>
                                <MultipleStringValuesPicker
                                    onSelect={onStringValuesChange}
                                    options={jobTypes}
                                    value={filter.stringValues}
                                />
                            </Box>
                        }
                        {entityId === 4 && filter.property === 'stage' &&
                            <Box sx={{ flex: '1 1 0', alignItems: "flex-start" }}>
                                <MultipleNumericValuesPicker
                                    onSelect={onNumericValuesChange}
                                    options={jobStages}
                                    value={filter.numericValues}
                                />
                            </Box>
                        }
                        {entityId === 4 && filter.property === 'status' &&
                            <Box sx={{ flex: '1 1 0', alignItems: "flex-start" }}>
                                <MultipleStringValuesPicker
                                    onSelect={onStringValuesChange}
                                    options={jobStatus}
                                    value={filter.stringValues}
                                />
                            </Box>  
                        }
                        {entityId === 4 && filter.property === 'source' && jobSources && !isFreeTextFilter &&
                            <Box sx={{ flex: '1 1 0', alignItems: "flex-start" }}>
                                <MultipleStringValuesPicker
                                    onSelect={onStringValuesChange}
                                    options={jobSources}
                                    value={filter.stringValues}
                                />
                            </Box>
                        }
                        {entityId === 4 && filter.property === 'source' && isFreeTextFilter &&
                            <RWTextFieldComponent 
                                label="Values"
                                value={filter.freeTextValue}
                                onChange={stringChangeHandler}
                            />
                        }
                        {entityId === 5 && filter.property === 'type' &&
                            <Box sx={{ flex: '1 1 0', alignItems: "flex-start" }}>
                                <MultipleNumericValuesPicker
                                    onSelect={onNumericValuesChange}
                                    options={placementTypes}
                                    value={filter.numericValues}
                                />
                            </Box>
                        }
                        {entityId === 5 && filter.property === 'status' &&
                            <Box sx={{ flex: '1 1 0', alignItems: "flex-start" }}>
                                <MultipleNumericValuesPicker
                                    onSelect={onNumericValuesChange}
                                    options={placementStatus}
                                    value={filter.numericValues}
                                />
                            </Box>  
                        }
                        {entityId === 5 && filter.property === 'source' && placementSources && !isFreeTextFilter &&
                            <Box sx={{ flex: '1 1 0', alignItems: "flex-start" }}>
                                <MultipleStringValuesPicker
                                    onSelect={onStringValuesChange}
                                    options={placementSources}
                                    value={filter.stringValues}
                                />
                            </Box>  
                        }
                        {entityId === 5 && filter.property === 'source' && isFreeTextFilter &&
                            <RWTextFieldComponent 
                                label="Values"
                                value={filter.freeTextValue}
                                onChange={stringChangeHandler}
                            />
                        }
                        {entityId === 5 && filter.property === 'payment-type' &&
                            <Box sx={{ flex: '1 1 0', alignItems: "flex-start" }}>
                                <MultipleNumericValuesPicker
                                    onSelect={onNumericValuesChange}
                                    options={placementPaymentTypes}
                                    value={filter.numericValues}
                                />
                            </Box>  
                        }
                            {entityId === 5 && filter.property === 'pay-frequency' &&
                                <Box sx={{ flex: '1 1 0', alignItems: "flex-start" }}>
                                    <MultipleNumericValuesPicker
                                        onSelect={onNumericValuesChange}
                                        options={placementPayFrequencyOptions}
                                        value={filter.numericValues}
                                    />
                                </Box>
                            }
                        {entityId === 5 && filter.property === 'country' && countries &&
                            <Box sx={{ flex: '1 1 0', alignItems: "flex-start" }}>
                                <MultipleStringValuesPicker
                                    onSelect={onStringValuesChange}
                                    options={countries}
                                    value={filter.stringValues}
                                />
                            </Box>
                        }
                        {entityId === 8 && filter.property === 'type' &&
                            <Box sx={{ flex: '1 1 0', alignItems: "flex-start" }}>
                                <MultipleNumericValuesPicker
                                    onSelect={onNumericValuesChange}
                                    options={opportunityTypes}
                                    value={filter.numericValues}
                                />
                            </Box>  
                        }
                        {entityId === 8 && filter.property === 'stage' &&
                            <Box sx={{ flex: '1 1 0', alignItems: "flex-start" }}>  
                                <MultipleNumericValuesPicker
                                    onSelect={onNumericValuesChange}
                                    options={opportunityStages}
                                    value={filter.numericValues}
                                />
                            </Box>
                        }
                        {entityId === 8 && filter.property === 'status' &&
                            <Box sx={{ flex: '1 1 0', alignItems: "flex-start" }}>
                                <MultipleNumericValuesPicker
                                    onSelect={onNumericValuesChange}
                                    options={opportunityStatus}
                                    value={filter.numericValues}
                                />
                            </Box>
                        }
                        {entityId !== 8 && filter.property === 'division' && divisions &&
                            <Box sx={{ flex: '1 1 0', alignItems: "flex-start" }}>
                                <MultipleNumericValuesPicker
                                    onSelect={onNumericValuesChange}
                                    options={divisions}
                                    value={filter.numericValues}
                                />
                            </Box>  
                        }
                        {filter.property === 'custom-field' && !isFreeTextFilter &&
                            <Box sx={{ flex: '1 1 0', alignItems: "flex-start" }}>
                                <MultipleStringValuesPicker
                                    onSelect={onStringValuesChange}
                                    options={availablePredefinedValues}
                                    value={filter.stringValues}
                                />
                            </Box>
                        }
                        {filter.property === 'custom-field' && isFreeTextFilter &&
                            <RWTextFieldComponent 
                                label="Values"
                                value={filter.freeTextValue}
                                onChange={stringChangeHandler}
                            />
                        }
                    </Box>
                </Stack>
            </Collapse>
        </Paper>
    );
}