import React, { useCallback, useEffect, useRef, useState } from "react";
import { Alert, Button, Snackbar, Stack } from "@mui/material";
import useTheme from "@mui/system/useTheme";
import { Editor } from "@tinymce/tinymce-react";

import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import PageContentLayout from "layouts/PageContentLayout";
import { GetNotificationTemplate, SaveNotificationTemplate } from "services/AdvertsService";
import useUnsavedChangesDialog from "hooks/UseUnsavedChangesDialog";
import RWTextFieldComponent from "components/RWTextFieldComponent";

interface Props {
    setSummaryBar?: (summaryBar: JSX.Element) => void
}

export default function AdvertsNotificationTemplate({ setSummaryBar }: Props) {
    const theme = useTheme();
    const isDarkTheme = theme.palette.mode === 'dark';
    const [isLoading, setIsLoading] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [initialSubject, setInitialSubject] = useState('');
    const [subject, setSubject] = useState('');
    const [body, setBody] = useState('');
    const [isEditorDirty, setIsEditorDirty] = useState(false);
    const editorRef = useRef<any | null>(null);

    const saveHandler = useCallback(async () => {
        setIsLoading(true);
        const res = await SaveNotificationTemplate({ subject, body: editorRef.current.getContent() }, setErrorMessage);
        
        if (!res) {
            setIsLoading(false);
            return false;
        }

        setShowSuccess(true);
        setInitialSubject(subject);
        setIsEditorDirty(false);
        setIsLoading(false);
        return true;
    }, [subject]);

    useEffect(() => {
        const isDisabled = !isEditorDirty && subject === initialSubject;
        const saveButton = <Button variant="contained" color="primary" onClick={saveHandler} disabled={isDisabled}>Save</Button>;
        const SummaryBar = <TitleAndActionSummaryBar title="Configuration > Adverts > Notification Template" browserTabTitle="Adverts > Configuration" action={saveButton} />;
        setSummaryBar && setSummaryBar(SummaryBar);
    }, [setSummaryBar, isEditorDirty, initialSubject, subject, saveHandler]);

    const { unsavedChangesDialog } = useUnsavedChangesDialog((isEditorDirty || subject !== initialSubject), saveHandler);

    useEffect(() => {
        const getData = async () => {
            setIsLoading(true);
            const data = await GetNotificationTemplate();
            if(data) {
                setInitialSubject(data.subject);
                setSubject(data.subject);
                setBody(data.body);
            }
            setIsLoading(false);
        };
        getData();
    }, []);

    return (
        <>
            {unsavedChangesDialog}
            <Snackbar open={showSuccess} autoHideDuration={3000} onClose={() => setShowSuccess(false)}>
                <Alert onClose={() => setShowSuccess(false)}>Changes Saved</Alert>
            </Snackbar>
            <Snackbar open={errorMessage !== ''}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <PageContentLayout title="Notification Template" showLoading={isLoading}>
                <Stack spacing={3} flexGrow={1}>
                    <RWTextFieldComponent
                        label="Subject"
                        name="NotificationTemplateSubject"
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                    />
                    <div style={{ height: '100%', width: '100%', minHeight: '300px', paddingBottom: '3px' }}>
                        <div style={{ display: 'flex', height: '100%' }}>
                            <div style={{ flexGrow: 1 }}>
                                <Editor
                                    tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce-5.10.2/tinymce.min.js'}
                                    initialValue={body}
                                    onDirty={ () => setIsEditorDirty(true) }
                                    onInit={ (evt, editor) => editorRef.current = editor }
                                    init={{
                                        height: '100%',
                                        
                                        skin: isDarkTheme ? 'oxide-dark' : undefined,
                                        content_css: isDarkTheme ? 'dark' : undefined,
                                        branding: false,
                                        menubar: false,
                                        contextmenu: false,
                                        plugins: 'powerpaste code link emoticons table print preview visualchars lists fullscreen',
                                        browser_spellcheck: true,
                                        toolbar1: 'forecolor backcolor | undo redo | bold italic alignleft aligncenter alignright alignjustify bullist numlist outdent indent table | code preview fullscreen',
                                        toolbar2: 'link fontsizeselect fontselect mybutton styleselect mergefields',
                                        font_formats: 'Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Calibri=calibri; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Segoe=segoe,segoe ui,dejavu sans,trebuchet ms,verdana,sans-serif; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva',
                                        setup: editor => {
                                            editor.ui.registry.addMenuButton('mergefields', {
                                                text: 'Placeholders',
                                                fetch: callback => {
                                                    callback([
                                                        { type: 'menuitem', text: '{Advert.ID}', onAction: () => editor.insertContent('{Advert.ID}') },
                                                        { type: 'menuitem', text: '{Advert.Title}', onAction: () => editor.insertContent('{Advert.Title}') },
                                                        { type: 'menuitem', text: '{Advert.Url}', onAction: () => editor.insertContent('{Advert.Url}') },
                                                        '|',
                                                        { type: 'menuitem', text: '{Application.RatingUrl}', onAction: () => editor.insertContent('{Application.RatingUrl}') },
                                                        { type: 'menuitem', text: '{Application.Source}', onAction: () => editor.insertContent('{Application.Source}') },
                                                        '|',
                                                        { type: 'menuitem', text: '{Candidate.Email}', onAction: () => editor.insertContent('{Candidate.Email}') },
                                                        { type: 'menuitem', text: '{Candidate.FirstName}', onAction: () => editor.insertContent('{Candidate.FirstName}') },
                                                        { type: 'menuitem', text: '{Candidate.LastName}', onAction: () => editor.insertContent('{Candidate.LastName}') },
                                                        { type: 'menuitem', text: '{Candidate.Mobile}', onAction: () => editor.insertContent('{Candidate.Mobile}') },
                                                        { type: 'menuitem', text: '{Candidate.Summary}', onAction: () => editor.insertContent('{Candidate.Summary}') },
                                                        { type: 'menuitem', text: '{Candidate.Url}', onAction: () => editor.insertContent('{Candidate.Url}') },
                                                        '|',
                                                        { type: 'menuitem', text: '{Client.Name}', onAction: () => editor.insertContent('{Client.Name}') },
                                                        { type: 'menuitem', text: '{Client.TradingName}', onAction: () => editor.insertContent('{Client.TradingName}') },
                                                        '|',
                                                        { type: 'menuitem', text: '{Job.JobID}', onAction: () => editor.insertContent('{Job.JobID}') },
                                                        { type: 'menuitem', text: '{Job.Title}', onAction: () => editor.insertContent('{Job.Title}') },
                                                        { type: 'menuitem', text: '{Job.Url}', onAction: () => editor.insertContent('{Job.Url}') },
                                                        '|',
                                                        { type: 'menuitem', text: '{User.FirstName}', onAction: () => editor.insertContent('{User.FirstName}') },
                                                        '|',
                                                        { type: 'menuitem', text: '{Application.RatingUrl.TempV2}', onAction: () => editor.insertContent('{Application.RatingUrl.TempV2}') },
                                                        { type: 'menuitem', text: '{Candidate.Url.TempV2}', onAction: () => editor.insertContent('{Candidate.Url.TempV2}') },
                                                        { type: 'menuitem', text: '{Advert.Url.TempV2}', onAction: () => editor.insertContent('{Advert.Url.TempV2}') },
                                                        { type: 'menuitem', text: '{Job.Url.TempV2}', onAction: () => editor.insertContent('{Job.Url.TempV2}') },
                                                    ]);
                                                }
                                            });
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </Stack>
            </PageContentLayout>
        </>
    );
}