import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";

import PageLayout from "layouts/PageLayout";
import PageContentLayout from "layouts/PageContentLayout";
import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import { Client } from "common/models/Clients";
import { DeleteClientById, GetClientById } from "services/ClientsService";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { GetSettingBySettingName, UpdateSettingBySettingName } from "services/UsersService";

import Avatar from '@mui/material/Avatar';
import TwitterIcon from '../../assets/icons/twitter-icon.svg';
import LinkedInIcon from '../../assets/icons/linkedin-icon.svg';
import GoogleIcon from '../../assets/icons/google-icon.svg';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import { AllowClientsAddEdit, AllowClientsDelete } from "common/data/Permissions/ClientsAccess";
import { AllowContactsAddEdit } from "common/data/Permissions/ContactsAccess";
import ConfirmationDialog from "components/Dialogs/Generic/ConfirmationDialog";
import Snackbar from "@mui/material/Snackbar";
import Alert from "components/Alert";
import ActionMenu from "components/Menus/ActionMenu";
import { AllowJobsAddEdit } from "common/data/Permissions/JobsAccess";
import moment from "moment";
import { GetActivitiesSettings } from "services/ConfigurationService";
import { ActivitySettings } from "common/models/Configuration/Activities";
import { MenuOptionDefinition } from "common/models/MenuDefinition";
import EditActivityDialog from "components/Dialogs/Activities/EditActivityDialog";
import { DefaultActivity } from "util/Definitions/Activities";
import { AllowOpportunitiesAddEdit } from "common/data/Permissions/OpportunitiesAccess";
import PreviewLoaderComponent from "components/Previews/PreviewLoader";
import TagsManagementDialog from "components/Dialogs/TagsManagementDialog";
import ActivitiesGridComponent from "components/Grids/ActivitiesGrid";
import SitesGridComponent from "components/Grids/SitesGrid";
import { GetPhoneLink } from "util/CommonUtils";
import PlacementsGridComponent from "components/Grids/PlacementsGrid";
import ActivityMessagesGridComponent from "components/Grids/ActivityMessagesGrid";
import OpportunitiesGridComponent from "components/Grids/OpportunitiesGrid";
import JobsGridComponent from "components/Grids/JobsGrid";
import Switch from "@mui/material/Switch";
import JobsKanbanComponent from "components/Kanbans/JobsKanban";
import ContactsGridComponent from "components/Grids/ContactsGrid";
import DocumentsGridComponent from "components/Grids/DocumentsGrid";
import ViewRecordScreenLayout from "components/ScreenLayouts/Clients/ViewRecordScreenLayout";
import InvoicesGridComponent from "components/Grids/InvoicesGrid";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import SubmissionsGridComponent from "components/Grids/SubmissionsGrid";
import SendReferralDialog from "components/Dialogs/SendReferralDialog";
import { Permission } from "common/models/Permissions";
import { companyHasSinglePermission, userHasSinglePermission } from "util/PermissionsUtils";
import { AddRecentRecord } from "util/LocalStorageUtils";
import GenerateDocumentDialog from "components/Dialogs/Documents/GenerateDocumentDialog";
import usePartnerActions from "hooks/UsePartnerActions";
import ShowIframeDialog from "components/Dialogs/Generic/ShowIFrameDialog";
import { PartnerActionType } from "common/models/PartnerActions";
import useRecordHotkeys from "hooks/UseRecordHotkeys";
import { Activity } from "common/models/Activities";
import MeetingsGridComponent from "../../components/Grids/MeetingsGrid";
import InterviewsGridComponent from "../../components/Grids/InterviewsGrid";

const summaryLinkStyle: React.CSSProperties = { color: 'inherit', textDecoration: 'none' };
const defaultJobViewSettingName = 'DefaultJobsViewClients';

const formatDateString = (d: string, f: string = 'DD MMM YYYY h:mm A') => {
    if (d && d !== '0001-01-01T00:00:00') {
        const m = moment(d);
        if (m.isValid()) return m.format(f);
    }
    return 'Never';
}

const renderSummary = (c: Client) => {
    const tooltipContent = (
        <table>
            <tbody>
                <tr>
                    <Typography variant="caption" fontWeight={600} component="td">ID</Typography>
                    <Typography variant="caption" pl="10px" component="td">{c.id}</Typography>
                </tr>
                <tr>
                    <Typography variant="caption" fontWeight={600} component="td" sx={{ verticalAlign: 'top' }}>Trading Name</Typography>
                    <Typography variant="caption" pl="10px" component="td">{c.tradingName}</Typography>
                </tr>
                <tr>
                    <Typography variant="caption" fontWeight={600} component="td" sx={{ verticalAlign: 'top' }}>Legal Name</Typography>
                    <Typography variant="caption" pl="10px" component="td">{c.legalName}</Typography>
                </tr>
                <tr>
                    <Typography variant="caption" fontWeight={600} component="td">Created Date</Typography>
                    <Typography variant="caption" pl="10px" component="td">{formatDateString(c.createdDate)}</Typography>
                </tr>
                <tr>
                    <Typography variant="caption" fontWeight={600} component="td">Created By</Typography>
                    <Typography variant="caption" pl="10px" component="td">{c.createdByName}</Typography>
                </tr>
                <tr>
                    <Typography variant="caption" fontWeight={600} component="td">Updated Date</Typography>
                    <Typography variant="caption" pl="10px" component="td">{formatDateString(c.updatedDate)}</Typography>
                </tr>
                <tr>
                    <Typography variant="caption" fontWeight={600} component="td">Updated By</Typography>
                    <Typography variant="caption" pl="10px" component="td">{c.updatedByName}</Typography>
                </tr>
            </tbody>
        </table>
    );
    const clientName = (
        <Tooltip arrow title={tooltipContent}>
            <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{c.tradingName ?? c.legalName}</span>
        </Tooltip>
    );
    const statusName = <span style={{ padding: '0 5px' }}>{`(${c.statusName})`}</span>;
    if (c.phone) {
        const phoneLink = GetPhoneLink(c.phone, true);
        return (<>{clientName}{statusName}<span style={{ paddingRight: '5px' }}>-</span>{phoneLink}</>);
    }
    return (<>{clientName}{statusName}</>);
};

const googleMapsLink = (c: Client) => {
    const baseUrl = 'https://www.google.com/maps/search/?api=1';
    let address = '';
    if (c.address1) address = c.address1;
    if (c.address2) address += ' ' + c.address2;
    if (c.address3) address += ' ' + c.address3;
    if (c.suburb) address += ' ' + c.suburb;
    if (c.state) address += ' ' + c.state;
    if (c.postcode) address += ' ' + c.postcode;
    if (c.countryName) address += ' ' + c.countryName;

    if (address !== '') {
        return `${baseUrl}&query=${encodeURIComponent(address)}`;
    }
    return null;
}

const linkedInLink = (c: Client) => {
    if (c.linkedinURL) {
        const l = c.linkedinURL;
        if (!l.includes('http://') && !l.includes('https://')) return `https://${l}`;
        return l;
    }
    return `https://www.linkedin.com/search/results/companies/?keywords=${encodeURIComponent(c.tradingName)}`;
}

const twitterLink = (c: Client) => {
    if (c.twitterURL) {
        const l = c.twitterURL;
        if (!l.includes('http://') && !l.includes('https://')) return `https://${l}`;
        return l;
    }
    return `https://twitter.com/search?f=users&vertical=default&q=${encodeURIComponent(c.tradingName)}`;
}

const entityTypeId = 1;

export default function ClientRecord() {
    const [client, setClient] = useState<Client>();
    const [activitySettings, setActivitySettings] = useState<ActivitySettings[]>([]);
    const [selectedActivity, setSelectedActivity] = useState<ActivitySettings | null>(null);
    const [noteActivitySetting, setNoteActivitySetting] = useState<ActivitySettings | null>(null);
    const params = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [isFetchingDefaultTab, setIsFetchingDefaultTab] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [isRecordDeleted, setIsRecordDeleted] = useState(false);
    const [isLoadingTab, setIsLoadingTab] = useState(false);
    const [showTagsPrevew, setShowTagsPreview] = useState(false);
    const [showTagsPrevewNoDelay, setShowTagsPreviewNoDelay] = useState(false);
    const [showTagsManagement, setShowTagsManagement] = useState(false);
    const [showSendReferralDialog, setShowSendReferralDialog] = useState(false);
    const [showGenerateDocumentsDialog, setShowGenerateDocumentsDialog] = useState(false);
    const [isFinishedSetup, setIsFinishedSetup] = useState(false);
    const [searchParams] = useSearchParams();
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [refreshActivitiesGridControl, setRefreshActivitiesGridControl] = useState(false);
    const [activeTab, setActiveTab] = useState('CLIENTS_Home');
    const [defaultTab, setDefaultTab] = useState<string>();
    const [tabSetupFinished, setTabSetupFinished] = useState(false);
    const [jobsView, setJobsView] = useState<'list' |'kanban'>('list');
    const [tabActionMenuOptions, setTabActionMenuOptions] = useState<MenuOptionDefinition[]>([]);
    const [showPartnerActionPopup, setShowPartnerActionPopup] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (!showTagsPrevew) setShowTagsPreviewNoDelay(false);
    }, [showTagsPrevew]);

    const canAccessContacts = useMemo(() => companyHasSinglePermission(Permission.Contacts) && userHasSinglePermission(Permission.Contacts), []);
    const canAccessOpportunities = useMemo(() => companyHasSinglePermission(Permission.Opportunities) && userHasSinglePermission(Permission.Opportunities), []);
    const canAccessJobs = useMemo(() => companyHasSinglePermission(Permission.Jobs) && userHasSinglePermission(Permission.Jobs), []);
    const canAccessPlacements = useMemo(() => companyHasSinglePermission(Permission.Placements) && userHasSinglePermission(Permission.Placements), []);
    const canAccessActivities = useMemo(() => companyHasSinglePermission(Permission.Activities) && userHasSinglePermission(Permission.Activities), []);
    const canGenerateDocuments = useMemo(() => companyHasSinglePermission(Permission.DocumentTemplates) && userHasSinglePermission(Permission.DocumentTemplates), []);

    const clientId = useMemo(() => {
        const parsedId = +(params.id ?? '0');
        if (isNaN(parsedId)) return 0;
        return parsedId;
    }, [params.id]);
    
    const clientIdArray = useMemo(() => [clientId], [clientId]);

    const partnerActionsData = useMemo(() => ({ clientId }), [clientId]);
    const { partnerActions, popupTitle, popupUrl } = usePartnerActions(PartnerActionType.Clients, partnerActionsData, setShowPartnerActionPopup);

    const canAddEditContacts = useMemo(() => AllowContactsAddEdit(), []);
    const canEditClients = useMemo(() => AllowClientsAddEdit(), []);
    const canDeleteClients = useMemo(() => AllowClientsDelete(), []);
    const canAddJobs = useMemo(() => AllowJobsAddEdit(), []);
    const canAddOpportunities = useMemo(() => AllowOpportunitiesAddEdit(), []);

    const hotkeySettings = useMemo<Record<string, () => void>>(() => {
        return {
            n: () => noteActivitySetting && setSelectedActivity({...noteActivitySetting}),
            e: () => navigate(`/clients/${clientId}/edit`),
            t: () => setShowTagsManagement(true),
        };
    }, [clientId, navigate, noteActivitySetting]);

    useRecordHotkeys(hotkeySettings, !canEditClients);

    useEffect(() => {
        const getData = async () => {
            setIsLoading(true);
            const res = await GetClientById(clientId);
            if (res === null) navigate('/not-found');
            const settings = await GetActivitiesSettings(1, undefined, setErrorMessage);
            if (res) {
                AddRecentRecord(1, clientId, res.tradingName ? res.tradingName : res.legalName);
                setClient(res);
            }
            if (settings) setActivitySettings(settings);
            setIsLoading(false);
        };
        getData();
    }, [clientId, navigate]);

    useEffect(() => {
        const tabParam = searchParams.get('tab');
        const getData = async () => {
            setIsFetchingDefaultTab(true);
            const defaultJobView = await GetSettingBySettingName(defaultJobViewSettingName);
            if (defaultJobView) setJobsView(defaultJobView === 'list' ? 'list' : 'kanban');
            if (!tabParam) {
                const tabSetting = await GetSettingBySettingName('TabDefaultsClients');
                if (tabSetting) setDefaultTab(tabSetting);
            }
            else setActiveTab(tabParam)
            setIsFetchingDefaultTab(false);
            setIsFinishedSetup(true);
        };
        getData();
    }, [searchParams]);

    const actionMenuDefinitions = useMemo<MenuOptionDefinition[]>(() => {
        let actions: (MenuOptionDefinition)[] = [
            ...partnerActions,
            ...tabActionMenuOptions,
            { label: 'Delete', type: 'action', action: () => setShowDeleteDialog(true), allow: () => canDeleteClients },
            { label: 'Create Job', type: 'parent', allow: () => canAddJobs, subMenu: [
                { label: 'Contract', type: 'link', href: `/jobs/create?ClientID=${clientId}&JobType=Contract` },
                { label: 'Fixed Term', type: 'link', href: `/jobs/create?ClientID=${clientId}&JobType=FixedContract` },
                { label: 'Panel', type: 'link', href: `/jobs/create?ClientID=${clientId}&JobType=Panel` },
                { label: 'Permanent', type: 'link', href: `/jobs/create?ClientID=${clientId}&JobType=Permanent` },
                { label: 'Talent Pool', type: 'link', href: `/jobs/create?ClientID=${clientId}&JobType=TalentPool` },
            ]},
            { label: 'Create Contact', type: 'link', href: `/contacts/create?ClientID=${clientId}`, allow: () => canAddEditContacts },
            { label: 'Create Opportunity', type: 'link', href: `/opportunities/create?ClientID=${clientId}`, allow: () => canAddOpportunities },
            { label: 'Generate Document', type: 'action', action: () => setShowGenerateDocumentsDialog(true), allow: () => canGenerateDocuments },
        ];

        for (let i = 0; i < activitySettings.length; i++) {
            const setting = activitySettings[i];
            if (setting.id === 18) setNoteActivitySetting(setting);
            if (setting.category === 2) continue;
            
            if (setting.id === 23)
                actions.push({ label: setting.customName, type: 'action', action: () => setShowSendReferralDialog(true) });
            else
                actions.push({ label: setting.customName, type: 'action', action: () => setSelectedActivity(setting) });
        }

        return actions;
    }, [partnerActions, tabActionMenuOptions, clientId, canDeleteClients, canAddJobs, canAddEditContacts, canAddOpportunities, canGenerateDocuments, activitySettings]);

    const SummaryBar = useMemo(() => {
        if (client) {
            const linkedInAction = (
                <a href={linkedInLink(client)}
                    style={summaryLinkStyle}
                    key="linkedInAction"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <Avatar sx={{ bgcolor: "#0e76a8", mr: '5px' }}>
                        <img src={LinkedInIcon} alt="linkedin-icon" width="100%" />
                    </Avatar>
                </a>
            );

            const twitterAction = (
                <a href={twitterLink(client)}
                    style={summaryLinkStyle}
                    key="twitterAction"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <Avatar sx={{ bgcolor: "#1DA1F2", mr: '5px' }}>
                        <img src={TwitterIcon} alt="twitter-icon" width="100%" />
                    </Avatar>
                </a>
            );

            const googleAction = (
                <a href={`https://www.google.com/search?q=${encodeURIComponent(client.tradingName)}`}
                    style={summaryLinkStyle}
                    key="googleAction"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <Avatar sx={{ bgcolor: 'transparent', mr: '5px' }}>
                        <img src={GoogleIcon} alt="google-icon" />
                    </Avatar>
                </a>
            );

            const tagsAction = (
                <Avatar
                    key="tagsAction"
                    onClick={ canEditClients ? () => setShowTagsManagement(true) : () => setShowTagsPreviewNoDelay(true) }
                    onMouseEnter={ () => setShowTagsPreview(true) }
                    onMouseLeave={ () => setShowTagsPreview(false) }
                    sx={{ bgcolor: '#f209a6', mr: '5px', cursor: 'pointer' }}>
                    { client.tags ? <LocalOfferIcon /> : <LocalOfferOutlinedIcon /> }
                </Avatar>
            );

            const locationUrl = googleMapsLink(client);
            const locationAction = locationUrl ? (
                <a href={ locationUrl }
                    style={summaryLinkStyle}
                    key="locationAction"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <Avatar sx={{ bgcolor: "#ea4335", mr: '5px' }}>
                        <LocationOnIcon />
                    </Avatar>
                </a>
            ) : <Avatar key="locationPlaceholder" sx={{ mr: '5px' }}><LocationOnIcon /></Avatar>;

            const action1 = canEditClients ? (
                <Link key="editLink" to={`/clients/${clientId}/edit`} style={summaryLinkStyle}>
                    <Button variant="contained" color="success" sx={{ mr: '5px' }}>Edit</Button>
                </Link>
            ) : <React.Fragment key="editPlaceholder" />;

            const action = (
                <Box key="actionsMenu">
                    <ActionMenu definition={actionMenuDefinitions} />
                </Box>
            );

            return (
                <TitleAndActionSummaryBar
                    height="4.5rem"
                    title={client ? renderSummary(client) : ''}
                    action={[locationAction, linkedInAction, twitterAction, googleAction, tagsAction, action1, action]}
                    browserTabTitle={!client ? '' : client.tradingName + " - Clients"}
                />
            );
        }
    }, [clientId, client, actionMenuDefinitions, canEditClients]);

    const handleTabChange = useCallback((e: React.SyntheticEvent, newValue: string) => {
        navigate(`/clients/${clientId}?tab=${newValue}`);
    }, [clientId, navigate]);

    const deleteRecordCallback = useCallback(async () => {
        setIsLoading(true);
        const res = await DeleteClientById(clientId, setErrorMessage);
        if (res) setIsRecordDeleted(true);
        setIsLoading(false);
    }, [clientId]);

    const counts = useMemo(() => {
        let counts = {
            documents: 0,
            contacts: 0,
            sites: 0,
            callsNotes: 0,
            callsNotesDate: '\u00A0',
            meetings: 0,
            meetingsDate: '\u00A0',
            messages: 0,
            messagesDate: '\u00A0',
            opportunities: 0,
            opportunitiesDate: '\u00A0',
            openJobs: 0,
            openJobsDate: '\u00A0',
            jobs: 0,
            jobsDate: '\u00A0',
            interviews: 0,
            interviewsDate: '\u00A0',
            placements: 0,
            placementsDate: '\u00A0',
            activities: 0,
            activitiesDate: '\u00A0',
            submissions: 0,
            submissionsDate: '\u00A0',
            invoices: 0
        };

        if (client && client.statisticsCounts && client.statisticsCounts.length > 0) {
            for (let i = 0; i < client.statisticsCounts.length; i++) {
                const s = client.statisticsCounts[i];
                switch (s.type) {
                    case "DocumentsCount": counts.documents = s.value;
                        break;
                    case "ContactsCount": counts.contacts = s.value;
                        break;
                    case "SitesCount": counts.sites = s.value;
                        break;
                    case "CallsNotesCount": counts.callsNotes = s.value;
                        break;
                    case "MeetingsCount": counts.meetings = s.value;
                        break;
                    case "MessagesCount": counts.messages = s.value;
                        break;
                    case "OpportunitiesCount": counts.opportunities = s.value;
                        break;
                    case "JobsOpenCount": counts.openJobs = s.value;
                        break;
                    case "JobsCount": counts.jobs = s.value;
                        break;
                    case "InterviewsCount": counts.interviews = s.value;
                        break;
                    case "PlacementsCount": counts.placements = s.value;
                        break;
                    case "ActivitiesCount": counts.activities = s.value;
                        break;
                    case "SubmissionsCount": counts.submissions = s.value;
                        break;
                    case "InvoicesCount": counts.invoices = s.value;
                        break;
                }
            }
        }
        if (client && client.statisticsDates && client.statisticsDates.length > 0) {
            for (let i = 0; i < client.statisticsDates.length; i++) {
                const s = client.statisticsDates[i];
                switch (s.type) {
                    case "CallsNotesDate":
                        const cnDate = moment(s.value);
                        if (cnDate.isValid()) counts.callsNotesDate = cnDate.format('DD MMM YYYY');
                        break;
                    case "MeetingsDate":
                        const mDate = moment(s.value);
                        if (mDate.isValid()) counts.meetingsDate = mDate.format('DD MMM YYYY');
                        break;
                    case "MessagesDate":
                        const mssDate = moment(s.value);
                        if (mssDate.isValid()) counts.messagesDate = mssDate.format('DD MMM YYYY');
                        break;
                    case "OpportunitiesDate":
                        const oppDate = moment(s.value);
                        if (oppDate.isValid()) counts.opportunitiesDate = oppDate.format('DD MMM YYYY');
                        break;
                    case "JobsOpenDate":
                        const joDate = moment(s.value);
                        if (joDate.isValid()) counts.openJobsDate = joDate.format('DD MMM YYYY');
                        break;
                    case "JobsDate":
                        const jDate = moment(s.value);
                        if (jDate.isValid()) counts.jobsDate = jDate.format('DD MMM YYYY');
                        break;
                    case "InterviewsDate":
                        const iDate = moment(s.value);
                        if (iDate.isValid()) counts.interviewsDate = iDate.format('DD MMM YYYY');
                        break;
                    case "PlacementsDate":
                        const pDate = moment(s.value);
                        if (pDate.isValid()) counts.placementsDate = pDate.format('DD MMM YYYY');
                        break;
                    case "ActivitiesDate":
                        const aDate = moment(s.value);
                        if (aDate.isValid()) counts.activitiesDate = aDate.format('DD MMM YYYY');
                        break;
                    case "SubmissionsDate":
                        const cvDate = moment(s.value);
                        if (cvDate.isValid()) counts.submissionsDate = cvDate.format('DD MMM YYYY');
                        break;
                }
            }
        }
        return counts;
    }, [client]);

    const jobsViewChangeHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = e.target.checked;
        const value = isChecked ? 'list' : 'kanban';
        setJobsView( value );
        UpdateSettingBySettingName(defaultJobViewSettingName, value);
    }, []);

    const openJobsViewSwitch = useMemo(() => (
        <span>
            <span>Kanban</span>
            <Switch checked={jobsView === 'list'} onChange={ jobsViewChangeHandler } />
            <span>List</span>
        </span>
    ), [jobsView, jobsViewChangeHandler]);

    useEffect(() => {
        if (tabActionMenuOptions.length > 0 && activeTab !== 'CLIENTS_Home') {
            setTabActionMenuOptions([]);
        }
    }, [activeTab, tabActionMenuOptions]);

    const tagManagementSuccessHandler = useCallback((message: string, recordIds: number[], finalTagCount: number) => {
        setSuccessMessage(message);
    }, []);

    const activityCreatedSuccessCallback = useCallback((message: string) => {
        setSuccessMessage(message);
        setRefreshActivitiesGridControl(prev => !prev);
    }, []);

    const selectedActivityData = useMemo<Activity | null>(() => {

        if (client && selectedActivity) {
            return {
                ...DefaultActivity,
                clientID: clientId,
                clientName: client.tradingName,
                category: 1,
                type: selectedActivity.customName,
                typeID: selectedActivity.id
            };
        }
        return null;
    }, [client, clientId, selectedActivity]);

    useEffect(() => {
        if (defaultTab && !tabSetupFinished) {
            const accessRestrictedTabs = ['CLIENTS_CallsNotes', 'CLIENTS_Meetings', 'CLIENTS_Messages', 'CLIENTS_Activities'];
            setActiveTab(prev => {
                if (defaultTab === 'CLIENTS_Contacts' && !canAccessContacts) return prev;
                if (accessRestrictedTabs.includes(defaultTab) && !canAccessActivities) return prev;
                if (defaultTab === 'CLIENTS_OpenJobs' && !canAccessJobs) return prev;
                if (defaultTab === 'CLIENTS_Jobs' && !canAccessJobs) return prev;
                if (defaultTab === 'CLIENTS_Submissions' && !(canAccessActivities && canAccessJobs)) return prev;
                if (defaultTab === 'CLIENTS_Interviews' && !(canAccessActivities && canAccessJobs)) return prev;
                if (defaultTab === 'CLIENTS_Placements' && !canAccessPlacements) return prev;
                if (defaultTab === 'CLIENTS_Opportunities' && !canAccessOpportunities) return prev;
                return defaultTab;
            });
            setTabSetupFinished(true);
        }
    }, [defaultTab, canAccessContacts, canAccessActivities, canAccessJobs, canAccessPlacements, canAccessOpportunities, tabSetupFinished]);

    return (
        <>
            <Snackbar open={successMessage !== ''} autoHideDuration={3000} onClose={() => setSuccessMessage('')}>
                <Alert onClose={() => setSuccessMessage('')}>{ successMessage }</Alert>
            </Snackbar>
            <Snackbar open={isRecordDeleted} autoHideDuration={1200} onClose={() => navigate('/clients/')}>
                <Alert onClose={() => navigate('/clients/')}>Record deleted</Alert>
            </Snackbar>
            <Snackbar open={errorMessage !== ''} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <PageLayout paddingTop={0} SummaryBar={SummaryBar}>
                <ConfirmationDialog
                    message="Are you sure you want to delete this record?"
                    onClose={ () => setShowDeleteDialog(false) }
                    onContinue={ deleteRecordCallback }
                    open={showDeleteDialog}
                    title="Confirm Action"
                    confirmActionText="Yes"
                    cancelActionText="No"
                />
                <EditActivityDialog
                    closeHandler={ () => {setSelectedActivity(null) }}
                    data={selectedActivityData}
                    open={ Boolean(selectedActivity) }
                    loadingHandler={setIsLoading}
                    errorHandler={setErrorMessage}
                    successHandler={activityCreatedSuccessCallback}
                />
                <SendReferralDialog
                    open={showSendReferralDialog}
                    clientId={client && client.id ? client.id : 0}
                    clientName={client && client.tradingName ? client.tradingName : ''}
                    closeHandler={ () => setShowSendReferralDialog(false) }
                    loadingHandler={setIsLoading}
                    errorHandler={setErrorMessage}
                    successHandler={setSuccessMessage}
                />
                <PreviewLoaderComponent
                    open={showTagsPrevew}
                    entityType="client"
                    recordId={clientId}
                    showDelayMs={showTagsPrevewNoDelay ? 0 : undefined}
                    isTagsPreview
                />
                <TagsManagementDialog
                    open={showTagsManagement}
                    entityId={1}
                    recordIds={clientIdArray}
                    closeHandler={ () => setShowTagsManagement(false) }
                    loadingHandler={ setIsLoading }
                    errorHandler={ setErrorMessage }
                    successHandler={ tagManagementSuccessHandler }
                />
                <GenerateDocumentDialog
                    open={showGenerateDocumentsDialog}
                    entityId={entityTypeId}
                    playerId={clientId}
                    closeHandler={() => setShowGenerateDocumentsDialog(false)}
                    loadingHandler={ setIsLoading }
                    errorHandler={ setErrorMessage }
                    successHandler={ setSuccessMessage }
                />
                <Tabs value={activeTab} onChange={handleTabChange}>
                    <Tab value="CLIENTS_Home" label={<>Home<br/>&nbsp;</>} />
                    {canAccessContacts && <Tab value="CLIENTS_Contacts" label={<>Contacts ({counts.contacts})<br />&nbsp;</>} />}
                    <Tab value="CLIENTS_Sites" label={<>Sites ({counts.sites})<br/>&nbsp;</>}/>
                    {canAccessActivities && <Tab value="CLIENTS_CallsNotes" label={<>Calls/Notes ({counts.callsNotes})<br />{counts.callsNotesDate}</>} />}
                    {canAccessActivities && <Tab value="CLIENTS_Meetings" label={<>Meetings ({counts.meetings})<br />{counts.meetingsDate}</>} />}
                    {canAccessActivities && <Tab value="CLIENTS_Messages" label={<>Messages ({counts.messages})<br />{counts.messagesDate}</>} />}
                    {canAccessOpportunities && <Tab value="CLIENTS_Opportunities" label={<>Opportunities ({counts.opportunities})<br />{counts.opportunitiesDate}</>} />}
                    {canAccessJobs && <Tab value="CLIENTS_OpenJobs" label={<>Open Jobs ({counts.openJobs})<br />{counts.openJobsDate}</>} />}
                    {canAccessJobs && <Tab value="CLIENTS_Jobs" label={<>All Jobs ({counts.jobs})<br/>{counts.jobsDate}</>}/> }
                    {canAccessActivities && canAccessJobs && <Tab value="CLIENTS_Submissions" label={<>Submissions ({counts.submissions})<br />{counts.submissionsDate}</>} />}
                    {canAccessActivities && canAccessJobs && <Tab value="CLIENTS_Interviews" label={<>Interviews ({counts.interviews})<br />{counts.interviewsDate}</>} />}
                    {canAccessPlacements && <Tab value="CLIENTS_Placements" label={<>Placements ({counts.placements})<br />{counts.placementsDate}</>} />}
                    {/*<Tab value="Invoices" label={<>Invoices ({counts.invoices})<br />&nbsp;</>} />*/}
                    <Tab value="CLIENTS_Documents" label={<>Documents ({counts.documents})<br />&nbsp;</>} />
                    {canAccessActivities && <Tab value="CLIENTS_Activities" label={<>Activities ({counts.activities})<br />{counts.activitiesDate}</>} />}
                </Tabs>
                <PageContentLayout showLoading={isLoading || isFetchingDefaultTab || isLoadingTab}>
                    <ShowIframeDialog open={showPartnerActionPopup} closeHandler={() => setShowPartnerActionPopup(false)} title={popupTitle} url={popupUrl} />
                    { isFinishedSetup && activeTab === "CLIENTS_Home" &&
                        <ViewRecordScreenLayout
                            client={client ? client : null}
                            loadingHandler={setIsLoadingTab}
                            errorHandler={setErrorMessage}
                            setActionMenuTabOptionsHandler={setTabActionMenuOptions}
                        />
                    }
                    { isFinishedSetup && canAccessContacts && activeTab === "CLIENTS_Contacts" &&
                        <ContactsGridComponent
                            source="client-record"
                            sourceId={clientId}
                            loadingHandler={ setIsLoadingTab }
                            errorHandler={ setErrorMessage }
                            successHandler={ setSuccessMessage }
                            gridName="clients/Contacts"
                        />
                    }
                    { isFinishedSetup && activeTab === "CLIENTS_Sites" &&
                        <SitesGridComponent
                            source="client-record"
                            sourceId={clientId}
                            loadingHandler={ setIsLoadingTab }
                            errorHandler={ setErrorMessage }
                            successHandler={ setSuccessMessage }
                            gridName="clients/Sites"
                        />
                    }
                    { isFinishedSetup && canAccessActivities && activeTab === "CLIENTS_CallsNotes" &&
                        <ActivitiesGridComponent
                            source="client-record"
                            sourceId={clientId}
                            categoryFilter={1}
                            loadingHandler={ setIsLoadingTab }
                            errorHandler={ setErrorMessage }
                            successHandler={ setSuccessMessage }
                            gridName="clients/CallsNotes"
                            refreshControl={refreshActivitiesGridControl}
                            hideActionsMenu
                        />
                    }
                    { isFinishedSetup && canAccessActivities && activeTab === "CLIENTS_Meetings" &&
                        <MeetingsGridComponent
                            source="client-record"
                            sourceId={clientId}
                            loadingHandler={ setIsLoadingTab }
                            errorHandler={ setErrorMessage }
                            successHandler={ setSuccessMessage }
                            gridName="clients/Meetings"
                        />
                    }
                    { isFinishedSetup && canAccessActivities && activeTab === "CLIENTS_Messages" &&
                        <ActivityMessagesGridComponent
                            source="client-record"
                            sourceId={clientId}
                            errorHandler={ setErrorMessage }
                            loadingHandler={ setIsLoadingTab }
                            successHandler={ setSuccessMessage }
                            gridName="clients/Messages"
                        />
                    }
                    { isFinishedSetup && canAccessOpportunities && activeTab === "CLIENTS_Opportunities" &&
                        <OpportunitiesGridComponent
                            source="client-record"
                            sourceId={clientId}
                            loadingHandler={setIsLoadingTab}
                            errorHandler={setErrorMessage}
                            successHandler={setSuccessMessage}
                            gridName="clients/Opportunities"
                        />
                    }
                    { isFinishedSetup && canAccessJobs && activeTab === "CLIENTS_OpenJobs" &&
                        <>
                            {jobsView === 'kanban' &&
                                <>
                                    {openJobsViewSwitch}
                                    <JobsKanbanComponent source="client-record" sourceId={clientId} loadingHandler={setIsLoadingTab} />
                                </>
                            }
                            {jobsView === 'list' &&
                                <JobsGridComponent
                                    source="client-record"
                                    sourceId={clientId}
                                    jobState={1}
                                    loadingHandler={setIsLoadingTab}
                                    extraActions={openJobsViewSwitch}
                                    gridName="clients/OpenJobs"
                                />
                            }
                        </>
                    }
                    { isFinishedSetup && canAccessJobs && activeTab === "CLIENTS_Jobs" &&
                        <JobsGridComponent
                            source="client-record"
                            sourceId={clientId}
                            jobState={0}
                            loadingHandler={setIsLoadingTab}
                            gridName="clients/Jobs"
                        />
                    }
                    { isFinishedSetup && canAccessActivities && canAccessJobs && activeTab === "CLIENTS_Submissions" &&
                        <SubmissionsGridComponent
                            source="client-record"
                            sourceId={clientId}
                            loadingHandler={ setIsLoadingTab }
                            errorHandler={ setErrorMessage }
                            successHandler={ setSuccessMessage }
                            gridName="clients/Submissions"
                        />
                    }
                    { isFinishedSetup && canAccessActivities && canAccessJobs && activeTab === "CLIENTS_Interviews" &&
                        <InterviewsGridComponent
                            source="client-record"
                            sourceId={clientId}
                            loadingHandler={ setIsLoadingTab }
                            errorHandler={ setErrorMessage }
                            successHandler={ setSuccessMessage }
                            gridName="clients/Interviews"
                        />
                    }
                    { isFinishedSetup && canAccessPlacements && activeTab === "CLIENTS_Placements" &&
                        <PlacementsGridComponent
                            source="client-record"
                            sourceId={clientId}
                            loadingHandler={setIsLoadingTab}
                            errorHandler={setErrorMessage}
                            successHandler={setSuccessMessage}
                            gridName="clients/Placements"
                        />
                    }
                    {isFinishedSetup && activeTab === "Invoices" &&
                        <InvoicesGridComponent
                            source="client-record"
                            sourceId={clientId}
                            loadingHandler={setIsLoadingTab}
                            errorHandler={setErrorMessage}
                            successHandler={setSuccessMessage}
                            gridName="clients/invoices"
                        />
                    }
                    { isFinishedSetup && activeTab === "CLIENTS_Documents" &&
                        <DocumentsGridComponent
                            gridName="clients/Documents"
                            source="client-record"
                            sourceId={clientId}
                            loadingHandler={setIsLoadingTab}
                            errorHandler={setErrorMessage}
                            successHandler={setSuccessMessage}
                        />
                    }
                    { isFinishedSetup && canAccessActivities && activeTab === "CLIENTS_Activities" &&
                        <ActivitiesGridComponent
                            source="client-record"
                            sourceId={clientId}
                            loadingHandler={ setIsLoadingTab }
                            errorHandler={ setErrorMessage }
                            successHandler={ setSuccessMessage }
                            gridName="clients/Activities"
                            refreshControl={refreshActivitiesGridControl}
                            hideActionsMenu
                        />
                    }
                </PageContentLayout>
            </PageLayout>
        </>
    );
}