import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import React, { PropsWithChildren } from "react";

interface Props {
    open: boolean,
    title: string,
    confirmHandler: () => void,
    cancelHandler: () => void,
    confirmButtonText?: string,
    cancelButtonText?: string,
    isConfirmButtonDisabled?: boolean
    fullWidth?: boolean
}

export default function CustomContentConfirmationDialog({ open, title, children, confirmButtonText = 'Confirm', cancelButtonText = 'Cancel', isConfirmButtonDisabled, fullWidth, confirmHandler, cancelHandler }: PropsWithChildren<Props>) {
    return (
        <Dialog open={open} fullWidth={fullWidth}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                {children}
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="error" onClick={cancelHandler}>{cancelButtonText}</Button>
                <Button variant="contained" color="success" onClick={confirmHandler} disabled={isConfirmButtonDisabled}>{confirmButtonText}</Button>
            </DialogActions>
        </Dialog>
    );
}