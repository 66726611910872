import { DownloadFileResponse, RequestOptions } from '../common/models/GenericTypes';
import { forceLogin, getToken, isTokenValid } from './AuthUtils';

async function fetchHelper<T>(url: string, requestInit: RequestInit, opt: RequestOptions): Promise<T | null> {

    if (opt.needsAuth) {
        const token = getToken();
        if (isTokenValid(token)) {
            if (requestInit.headers) {
                requestInit.headers = { ...requestInit.headers, 'Authorization': 'Bearer ' + token };
            }
            else {
                requestInit.headers = { 'Authorization': 'Bearer ' + token };
            }
        }
    }

    try {
        const res = await fetch(url, requestInit);

        if (res.ok) {
            return opt.noDataReturned ? res.ok : await res.json();
        }

        const message = await res.json() ?? 'An unexpected error has occured';
        
        const shouldReLogin = res.headers.get('LoginRequired');

        if(shouldReLogin) {
            forceLogin();
            return null
        }

        if (opt.errorHandler) opt.errorHandler(message);

        return null;
    } catch (error) {
        if (opt.errorHandler) opt.errorHandler('An unexpected error has occured');
        return null;
    }
}

export async function DownloadFile(url: string, options: RequestOptions): Promise<DownloadFileResponse | null> {
    let requestInit: RequestInit = { method: 'GET' };
    const headers: HeadersInit = {};
    if (options.needsAuth) {
        const token = getToken();
        if (isTokenValid(token)) {
            headers["Authorization"] = "Bearer " + token;
        }
    }
    requestInit.headers = headers;

    try {
        const res = await fetch(url, requestInit);

        if (res.ok) {
            const data: DownloadFileResponse = {
                data: await res.blob(),
                fileName: res.headers.get('DownloadFileName') ?? 'file.txt',
                fileExtension: res.headers.get('DownloadFileExtension') ?? '.txt'
            }
            return data;
        }

        const message = await res.json() ?? 'An unexpected error has occured';
        const shouldReLogin = res.headers.get('LoginRequired');

        if(shouldReLogin) {
            forceLogin();
            return null
        }

        if (options.errorHandler) options.errorHandler(message);

        return null;

    } catch (error) {
        if (options.errorHandler) options.errorHandler('An unexpected error has occured');
        return null;
    }
}

export async function DownloadFileFromPost(url: string, options: RequestOptions, body: string): Promise<DownloadFileResponse | null> {
    let requestInit: RequestInit = { method: 'POST' };
    const headers: HeadersInit = {};
    if (options.needsAuth) {
        const token = getToken();
        if (isTokenValid(token)) {
            headers["Authorization"] = "Bearer " + token;
        }
    }
    headers["Content-Type"]="application/json";
    requestInit.headers = headers;
    requestInit.body = body;

    try {
        const res = await fetch(url, requestInit);

        if (res.ok) {
            const data: DownloadFileResponse = {
                data: await res.blob(),
                fileName: res.headers.get('DownloadFileName') ?? 'file.txt',
                fileExtension: res.headers.get('DownloadFileExtension') ?? '.txt'
            }
            return data;
        }

        const message = await res.json() ?? 'An unexpected error has occured';
        const shouldReLogin = res.headers.get('LoginRequired');

        if(shouldReLogin) {
            forceLogin();
            return null
        }

        if (options.errorHandler) options.errorHandler(message);

        return null;

    } catch (error) {
        if (options.errorHandler) options.errorHandler('An unexpected error has occured');
        return null;
    }
}

export async function PostFile<T>(url: string, body: FormData, options: RequestOptions): Promise<T | null> {
    const requestInit: RequestInit = { method: 'POST' };
    requestInit.body = body;
    return fetchHelper(url, requestInit, options);
}

export async function PutFile<T>(url: string, body: FormData, options: RequestOptions): Promise<T | null> {
    const requestInit: RequestInit = { method: 'PUT' };
    requestInit.body = body;
    return fetchHelper(url, requestInit, options);
}

export async function Get<T>(url: string, options: RequestOptions): Promise<T | null> {
    const requestInit: RequestInit = { method: 'GET', headers: { 'Content-Type': 'application/json' }};

    return fetchHelper(url, requestInit, options);
}

export async function Post<T>(url: string, options: RequestOptions, body?: unknown): Promise<T | null> {
    const requestInit: RequestInit = { method: 'POST', headers: { 'Content-Type': 'application/json' } };
    if (body !== undefined) {
        requestInit.body = JSON.stringify(body);
    }

    return fetchHelper(url, requestInit, options);
}

export async function Put<T>(url: string, options: RequestOptions, body?: unknown): Promise<T | null> {
    const requestInit: RequestInit = { method: 'PUT', headers: { 'Content-Type': 'application/json' } };
    if (body !== undefined) {
        requestInit.body = JSON.stringify(body);
    }

    return fetchHelper(url, requestInit, options);
}

export async function Delete<T>(url: string, options: RequestOptions, body?: unknown): Promise<T | null> {
    const requestInit: RequestInit = { method: 'DELETE', headers: { 'Content-Type': 'application/json' } };
    if (body !== undefined) {
        requestInit.body = JSON.stringify(body);
    }

    return fetchHelper(url, requestInit, options);
}