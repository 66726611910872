import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Alert from "components/Alert";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";

import { ExternalAuth, ExternalAuthCallback } from '../../services/AuthService';
import { GetMyPermissions, GetMyCompanyPermissions, GetMySettings } from "services/UsersService";
import logo from '../../assets/img/full-logo-light.png'

declare global {
    interface Window {
        pendo: any;
    }
}

interface Props {
    refreshCurrentUserHandler?: () => void
}

export default function ExternalLogin({ refreshCurrentUserHandler }: Props): JSX.Element {
    const params = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const navigate = useNavigate();

    const handleError = (message: string) => {
        setErrorMessage(message);
    }

    useEffect(() => {
        const search = window.location.search;
        const service: string = params.service ?? '';

        if (search === '') {

            const RedirectToService = async () => {
                const res = await ExternalAuth(service, handleError);
                if (res) {
                    window.location.replace(res.url);
                }
                else {
                    setIsLoading(false);
                }
            };

            RedirectToService();
        }
        else {
            const UseCodeResponse = async () => {
                const data = await ExternalAuthCallback(service, search, handleError);
                if (data) {
                    localStorage.clear();

                    await GetMySettings();

                    const userPermissions = await GetMyPermissions(handleError);
                    const customerPermissions = await GetMyCompanyPermissions(handleError);
                    if (userPermissions) {
                        localStorage.setItem('uPermissions', JSON.stringify(userPermissions));
                        localStorage.setItem('cPermissions', JSON.stringify(customerPermissions));
                        refreshCurrentUserHandler && refreshCurrentUserHandler();

                        navigate('/');
                    }
                }
                else {
                    setIsLoading(false);
                }
            };

            UseCodeResponse();
        }
    }, [params.service, navigate, refreshCurrentUserHandler])

    return (
        <>
            <Backdrop open={isLoading} sx={{ zIndex: (t) => t.zIndex.drawer + 1 }}>
                <CircularProgress sx={{ color: 'text.disabled' }} />
            </Backdrop>
            <Paper square sx={{ flexBasis: '100%' }}>
                {Boolean(errorMessage) &&
                    <Box textAlign='center' sx={{ maxWidth: '700px', margin: 'auto' }}>
                        <img src={logo} alt="logo" />
                        <Alert sx={{ marginY: '20px' }} severity="error">
                            {errorMessage}
                        </Alert>
                        <Button color="secondary" variant="text" onClick={() => navigate('/login')}>
                            Back to login
                        </Button>
                    </Box>
                }
            </Paper>
        </>
    );
}