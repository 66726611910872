import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { DashboardLayoutComponent, PanelModel } from '@syncfusion/ej2-react-layouts';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import PanelWrapper from '../../Dashboards/PanelWrapper';
import { Client, ClientHierarchy, ClientOwner } from '../../../common/models/Clients';
import RecordAvatarComponent from '../../Dashboards/Graphs/ClientAvatarComponent';
import MultiLineTextElement from '../MultiLineTextElement';
import ClientFieldsElement from './ClientFieldsElement';
import SingleFieldElement from '../SingleFieldElement';
import { GetCustomFieldsByEntity_OnlyActive } from '../../../services/CustomFieldsService';
import { CustomField } from '../../../common/models/Configuration/CustomFields';
import { CustomFieldSettingsMap, CustomFieldType } from '../../../common/models/ScreenLayouts/CustomFields';
import { ClientRecordDashboardElementDefinition, ClientRecordDashboardElementType } from '../../../common/models/Dashboard/EditLayout';
import { ClientScreenLayoutSettings, DefaultClientRecordDashboardElements,  GetPanelDefinitionsFromPanelModels } from '../../../util/Definitions/ScreenLayouts/Client';
import { UdfClientFieldMapObj } from '../../../util/Definitions/ScreenLayouts/CustomFields';
import { MenuOptionDefinition } from '../../../common/models/MenuDefinition';
import { PreviewEntityType } from '../../../common/models/Previews/Previews';
import PreviewLoaderComponent from '../../Previews/PreviewLoader';
import { GetClientHierarchy, GetClientOwners } from '../../../services/ClientsService';
import OwnersListDialog from '../../Dialogs/Dashboard/OwnersListDialog';
import { GetDivisions } from '../../../services/DivisionsService';
import { Division } from '../../../common/models/Configuration/Division';
import Chip from '@mui/material/Chip';
import ClientHierarchyDialog from '../../Dialogs/Dashboard/ClientHierarchyDialog';
import { GetCustomerSettingBySettingName } from "services/ConfigurationService";
import ViewClientLocationElement from "./ViewClientLocationElement";
import Divider from "@mui/material/Divider";

interface Props {
    client: Client | null,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
    setActionMenuTabOptionsHandler?: (opts: MenuOptionDefinition[]) => void
}

const getNoticePeriodUnitsName = (unitsId: number) => {
    switch (unitsId)
    {
        case 1: return "Day(s)";
        case 2: return "Week(s)";
        default: return "";
    }
};

const clientCodeTypeNames = {
    0: 'None',
    1: 'ABN',
    2: 'ACN',
    3: 'Company #',
    4: 'VAT #',
};

const { unitWidth, unitHeight, gapX, gapY, columns, mediaQueryMaxWidth } = ClientScreenLayoutSettings;
const cellSpacing = [gapX, gapY];

export default function ViewRecordScreenLayout({ client, loadingHandler, errorHandler, setActionMenuTabOptionsHandler }: Props) {
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    const [isOwnersDialogOpen, setIsOwnersDialogOpen] = useState(false);
    const [isHierarchyDialogOpen, setIsHierarchyDialogOpen] = useState(false);
    const [owners, setOwners] = useState<ClientOwner[]>([]);
    const [hierarchy, setHierarchy] = useState<ClientHierarchy[]>([]);
    const [divisions, setDivisions] = useState<Division[]>([]);
    const [previewType, setPreviewType] = useState<PreviewEntityType | ''>('');
    const [previewRecordId, setPreviewRecordId] = useState(0);
    const [screenResizedControl, setScreenResizedControl] = useState(false);
    const [fetchingSavedState, setFetchingSavedState] = useState(false);
    const [fetchedSavedState, setFetchedSavedState] = useState(false);
    const [activeFields, setActiveFields] = useState<CustomField[]>([]);
    const [lastSavedState, setLastSavedState] = useState<PanelModel[] | null>(null);
    const layoutRef = useRef<DashboardLayoutComponent | null>(null);
    const theme = useTheme();

    useEffect(() => {
        const windowResizeHandler = () => setScreenResizedControl(prev => !prev);
        window.addEventListener('resize', windowResizeHandler);
        return () => window.removeEventListener('resize', windowResizeHandler);
    }, []);

    useEffect(() => {
        const getActiveFields = async () => {
            const res = await GetCustomFieldsByEntity_OnlyActive(1);
            if (res) setActiveFields(res);
        };
        getActiveFields();
    }, []);

    useEffect(() => {
        const getOwners = async () => {
            if (client) {
                const res = await GetClientOwners(client.id);
                if (res) setOwners(res);
            }
        };
        client && client.id && getOwners();
    }, [client]);

    useEffect(() => {
        const getHierarchy = async () => {
            if (client) {
                const res = await GetClientHierarchy(client.id);
                if (res) setHierarchy(res);
            }
        };
        client && client.id && getHierarchy();
    }, [client]);

    useEffect(() => {
        const getDivs = async () => {
            const res = await GetDivisions();
            if (res) setDivisions(res);
        };
        getDivs();
    }, []);

    const divisionNames = useMemo<string[]>(() => {
        if (client) {
            let names: string[] = [];
            const ids = client.divisions.split(';');
            ids.forEach(d => {
                const item = divisions.find(div => div.id === +d);
                if (item) names.push(item.name);
            });
            return names;
        }
        return [];
    }, [client, divisions]);

    const customFieldsSettingsMap = useMemo<CustomFieldSettingsMap>(() => {
        let obj: CustomFieldSettingsMap = {};
        activeFields.forEach(u => {
            const customFieldNumber = u.name.substring(13);
            const key = 'CustomField' + customFieldNumber as CustomFieldType;
            obj[key] = {
                title: u.agencyName,
                isMultiLine: u.multiLine && !u.usePredefinedValues,
            }
        });
        return obj;
    }, [activeFields]);

    useEffect(() => {
        loadingHandler && loadingHandler(fetchingSavedState);
    }, [loadingHandler, fetchingSavedState]);

    useEffect(() => {
        const getSavedState = async () => {
            setFetchingSavedState(true);
            const elementsJson = await GetCustomerSettingBySettingName('ClientRecordDashboardLayoutElements');
            if (elementsJson) {
                const panels = JSON.parse(elementsJson) as PanelModel[];
                setLastSavedState(panels);
            }
            setFetchingSavedState(false);
            setFetchedSavedState(true);
        };
        getSavedState();
    }, []);

    const elements = useMemo<ClientRecordDashboardElementDefinition[]>(() => {
        if (!fetchedSavedState) return [];
        if (lastSavedState !== null) {
            const elements = GetPanelDefinitionsFromPanelModels(lastSavedState);
            return elements ;
        }
        return DefaultClientRecordDashboardElements;
    }, [lastSavedState, fetchedSavedState]);

    const openPreviewHandler = useCallback((type: PreviewEntityType, recordId: number) => {
        setPreviewType(type);
        setPreviewRecordId(recordId);
        setIsPreviewOpen(true);
    }, []);

    const closePreviewHandler = useCallback(() => {
        setIsPreviewOpen(false);
    }, []);

    const renderElement = useCallback((id: string, type: ClientRecordDashboardElementType) => {
        if (client) {
            switch (type) {
                case 'Divider': return <Box pt="20px"><Divider component="div" /></Box>;
                case 'Spacer': return <></>;
                case 'ClientInfo': return (
                    <RecordAvatarComponent
                        entityId={1}
                        primaryText={client.tradingName}
                        secondaryText={client.legalName}
                        photoUrl={client.photoUrl}
                    />);
                case 'ClientLocation': return (
                    <ViewClientLocationElement
                        address1={client.address1}
                        address2={client.address2}
                        address3={client.address3}
                        countryName={client.countryName}
                        postcode={client.postcode}
                        siteId={client.siteID}
                        siteName={client.siteName}
                        state={client.state}
                        suburb={client.suburb}
                        openPreviewHandler={openPreviewHandler}
                        closePreviewHandler={closePreviewHandler}
                    />);
                case 'ClientSubmissionDeadlines': return (
                    <ClientFieldsElement
                        client={client}
                        title="Submission Deadlines"
                        suffix=" day(s)"
                        fields={['cvDeadlinePerm', 'cvDeadlineContract', 'cvDeadlineFixedContract']}
                />);
                case 'ClientSummary': return <MultiLineTextElement title="Summary" content={client.description} />;
                case 'ClientId': return <SingleFieldElement fieldTitle='ID' fieldValue={client.id} format="number" />;
                case 'ClientName': return <SingleFieldElement useEllipsisForLongValues fieldTitle='Legal Name' fieldValue={client.legalName} />;
                case 'ClientTradingName': return <SingleFieldElement useEllipsisForLongValues fieldTitle='Trading Name' fieldValue={client.tradingName} />;
                case 'ClientPhone':
                    return <SingleFieldElement fieldTitle='Phone' format="phone-link" href={client.phone} fieldValue={client.phone} />;
                case 'ClientWebsite':
                    const website = client.website;
                    const href = website && !website.startsWith('http') ? 'https://' + website : website;
                    return <SingleFieldElement fieldTitle='Website' href={href} fieldValue={website} format="external-link" />;
                case 'ClientLinkedIn':
                    const linkedIn = client.linkedinURL;
                    const lhref = linkedIn && !linkedIn.startsWith('http') ? 'https://' + linkedIn : linkedIn;
                    return <SingleFieldElement fieldTitle='LinkedIn' href={lhref} fieldValue={linkedIn} format="external-link" />;
                case 'ClientTwitter':
                    const twitter = client.twitterURL;
                    const thref = twitter && !twitter.startsWith('http') ? 'https://' + twitter : twitter;
                    return <SingleFieldElement fieldTitle='Twitter' href={thref} fieldValue={twitter} format="external-link" />;
                case 'ClientParent': return (
                    <SingleFieldElement
                        fieldTitle="Parent"
                        fieldValue={client.parentCompanyName ?? 'None'}
                        format="action"
                        previewType="client"
                        action={ () => setIsHierarchyDialogOpen(true) }
                        previewRecordId={client.parentCompany}
                        openPreviewHandler={openPreviewHandler}
                        closePreviewHandler={closePreviewHandler}
                    />);
                case 'ClientPrimaryContact': return (
                    <SingleFieldElement
                        fieldTitle='Primary Contact'
                        fieldValue={client.primaryContactName}
                        format="internal-link"
                        href={`/contacts/${client.primaryContact}`}
                        previewType="contact"
                        previewRecordId={client.primaryContact}
                        openPreviewHandler={openPreviewHandler}
                        closePreviewHandler={closePreviewHandler}
                    />);
                case 'ClientBillingContact':
                return (
                    <SingleFieldElement
                        fieldTitle='Billing Contact'
                        fieldValue={client.defaultBillingContactName}
                        format="internal-link"
                        href={`/contacts/${client.defaultBillingContactID}`}
                        previewType="contact"
                        previewRecordId={client.defaultBillingContactID}
                        openPreviewHandler={openPreviewHandler}
                        closePreviewHandler={closePreviewHandler}
                    />);
                case 'ClientDefaultApprover': return (
                    <SingleFieldElement
                        fieldTitle='Default Approver'
                        fieldValue={client.defaultTimesheetApproverName ?? ''}
                        format="internal-link"
                        href={`/contacts/${client.defaultTimesheetApproverID}`}
                        previewType="contact"
                        previewRecordId={client.defaultTimesheetApproverID}
                        openPreviewHandler={openPreviewHandler}
                        closePreviewHandler={closePreviewHandler}
                    />);
                case 'ClientOwners': return (
                    <SingleFieldElement fieldTitle="Owners" format="custom">
                        <div title={`${owners.length} Owner(s). Click to view list`} onClick={() => setIsOwnersDialogOpen(true)} style={{ cursor: 'pointer', display: 'flex' }}>
                            {owners.map(o => (
                                <Chip key={o.recruiterID} size="small" label={o.fullName} sx={{ mr: '5px' }} />
                            ))}
                        </div>
                    </SingleFieldElement>
                );
                case 'ClientStatus': return <SingleFieldElement fieldTitle='Status' fieldValue={client.statusName} />;
                case 'ClientRating': return <SingleFieldElement fieldTitle='Rating' fieldValue={client.rating} format="number" />;
                case 'ClientCredit': return (
                    <ClientFieldsElement
                        client={client}
                        title="Credit"
                        fields={['creditLimit', 'creditRating', 'creditBalance']}
                    />);
                case 'ClientDivisions': return (
                    <SingleFieldElement fieldTitle="Divisions" format="custom">
                        {divisionNames.map(d => (
                            <Chip key={d} size="small" label={d} sx={{ mr: '5px' }} />
                        ))}
                    </SingleFieldElement>
                );
                case 'ClientCode1': return <SingleFieldElement fieldTitle={(clientCodeTypeNames as any)[client.code1Type.toString()]} fieldValue={client.code1} />;
                case 'ClientCode2': return <SingleFieldElement fieldTitle={(clientCodeTypeNames as any)[client.code2Type.toString()]} fieldValue={client.code2} />;
                case 'ClientDefaultNoticePeriod': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Default Notice" fieldValue={client.defaultNoticePeriod + ' ' + getNoticePeriodUnitsName(client.defaultNoticePeriodUnits)} />;
                default: 
                    if (type.startsWith('CustomField')) {
                        const settings = customFieldsSettingsMap[type];
                        if (settings) {
                            const title = settings.title;
                            const { field, format } = UdfClientFieldMapObj[type as CustomFieldType];
                            const udfValue = client[field];
                            if (format === 'string' || format === 'date' || format === 'datetime') {
                                return <SingleFieldElement isMultiLine={settings.isMultiLine} format={format} fieldTitle={title} fieldValue={udfValue ? udfValue.toString() : null} />;
                            }
                            if (format === 'number') {
                                const numValue = udfValue === undefined || udfValue === null ? '' : udfValue.toString();
                                return <SingleFieldElement fieldTitle={title} fieldValue={numValue} />;
                            }
                        }
                        else return <SingleFieldElement fieldTitle={type} fieldValue="[ Disabled Custom Field ]" />
                    }
                    return <div>{id}</div>;
            }
        }
    }, [client, openPreviewHandler, closePreviewHandler, owners, divisionNames, customFieldsSettingsMap]);
    
    const layoutResizeStopHandler = useCallback(() => {
        setScreenResizedControl(prev => !prev);
    }, []);

    useEffect(() => {
        if (screenResizedControl) {}
        const api = layoutRef.current;
        if (api && fetchedSavedState) {
            const refreshTimeout = setTimeout(() => {
                api.refresh();
                api.refresh();
            }, 250);
            return () => clearTimeout(refreshTimeout);
        }
    }, [fetchedSavedState, screenResizedControl]);

    const renderLayout = useCallback(() => {
        if (!fetchedSavedState) return <></>;

        const mediaQuery = mediaQueryMaxWidth ? `max-width: ${mediaQueryMaxWidth}` : undefined;
        return (
            <DashboardLayoutComponent
                cellSpacing={cellSpacing}
                columns={columns}
                cellAspectRatio={30 / 2}
                resizeStop={ layoutResizeStopHandler }
                allowDragging={false}
                allowResizing={false}
                ref={l => layoutRef.current = l}
                mediaQuery={mediaQuery}
            >
                {elements.map(e => (
                    <PanelWrapper
                        key={e.id}
                        id={e.id}
                        col={e.col}
                        row={e.row}
                        sizeX={e.sizeX}
                        sizeY={e.sizeY}
                        minSizeX={e.minSizeX}
                        minSizeY={e.minSizeY}
                        maxSizeX={e.maxSizeX}
                        maxSizeY={e.maxSizeY}
                        resizeControl={screenResizedControl}
                        resizeIconColor={theme.palette.text.disabled}
                        gapX={gapX}
                        gapY={gapY}
                        unitWidth={unitWidth}
                        unitHeight={unitHeight}
                    >
                        {renderElement(e.id, e.type)}
                    </PanelWrapper>
                ))}
            </DashboardLayoutComponent>
        );
    }, [elements, fetchedSavedState, theme.palette.text.disabled, screenResizedControl, layoutResizeStopHandler, renderElement]);

    return (
        <>
            <PreviewLoaderComponent
                open={isPreviewOpen}
                entityType={previewType}
                recordId={previewRecordId}
            />
            <OwnersListDialog
                type="Client"
                open={isOwnersDialogOpen}
                closeHandler={ () => setIsOwnersDialogOpen(false) }
                owners={owners}
            />
            <ClientHierarchyDialog
                open={isHierarchyDialogOpen}
                selectedClientId={ client ? client.id : 0 }
                closeHandler={ () => setIsHierarchyDialogOpen(false) }
                hierarchyData={hierarchy}
            />
            <Box p="10px" height="100%">
                <div className="control-section">
                    { renderLayout() }
                </div>
            </Box>
        </>
    );
}