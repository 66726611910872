import Alert from "components/Alert";
import Snackbar from "@mui/material/Snackbar";
import React, { useCallback, useState } from "react";
import FilePicker from "components/Pickers/FilePicker";
import PageContentLayout from "layouts/PageContentLayout";
import PageLayout from "layouts/PageLayout";
import { UploadCandidateResume } from "services/CandidatesService";
import { SimpleFileObj } from "common/models/GenericTypes";
import OnlyTitleSummaryBar from "components/SummaryBars/OnlyTitleSummaryBar";

const summaryBar = <OnlyTitleSummaryBar title="Candidates > Upload CVs" browserTabTitle="Upload CVs > Candidates" />;

export default function CandidateUploadPage() {
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [files, setFiles] = useState<File[]>([]);
    const [uploadedFiles, setUploadedFiles] = useState<SimpleFileObj[]>([]);

    const uploadCvHandler = useCallback(async (selectedFiles: File[]) => {
        setFiles(prev => [...prev, ...selectedFiles]);
        setIsLoading(true);
        const res = await UploadCandidateResume(0, 0, selectedFiles, setErrorMessage);
        if (res) {
            setSuccessMessage('File(s) Uploaded');
            setUploadedFiles(prev => {
                const uploaded: SimpleFileObj[] = selectedFiles.map((f, i) => ({ id: i + 1 + prev.length, name: f.name, size: f.size }));
                return [...prev, ...uploaded];
            });
            setFiles([]);
        }
        setIsLoading(false);
    }, []);

    const removeFileCallback = useCallback((index: number) => {
        setFiles(prev => {
            let files = [...prev];
            files.splice(index, 1);
            return files;
        });
    }, []);
    
    return (
        <PageLayout SummaryBar={summaryBar}>
            <Snackbar open={successMessage !== ''} autoHideDuration={3000} onClose={() => setSuccessMessage('')}>
                <Alert onClose={() => setSuccessMessage('')}>{ successMessage }</Alert>
            </Snackbar>
            <Snackbar open={errorMessage !== ''} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <PageContentLayout showLoading={isLoading}>
                <FilePicker
                    files={files}
                    uploadedFiles={uploadedFiles}
                    addFilesHandler={ uploadCvHandler }
                    removeFileHandler={ removeFileCallback }
                    flexibleListContainer
                />
            </PageContentLayout>
        </PageLayout>
    );
}
