import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import React, { useCallback, useEffect, useState } from "react";
import { Site } from "common/models/Site";
import { CreateSite } from "services/SitesService";
import { GetMySettings } from "services/UsersService";
import CountryPicker from "components/Pickers/CountryPicker";
import RWTextFieldComponent from "components/RWTextFieldComponent";

interface Props {
    open: boolean,
    name?: string,
    onSiteCreated: (s: Site) => void,
    closeHandler: () => void,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
    successHandler?: (message: string) => void
}

export default function QuickCreateSiteDialog({ open, name: nameProp = '', onSiteCreated, closeHandler, loadingHandler, errorHandler, successHandler }: Props) {
    const [showValidation, setShowValidation] = useState(false);
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [address1, setAddress1] = useState('');
    const [address2, setAddress2] = useState('');
    const [address3, setAddress3] = useState('');
    const [suburb, setSuburb] = useState('');
    const [state, setState] = useState('');
    const [postcode, setPostcode] = useState('');
    const [countryCode, setCountryCode] = useState<string | null>(null);

    useEffect(() => {
        if (open && nameProp) {
            setName(nameProp);
        }
    }, [open, nameProp]);

    useEffect(() => {
        const getUserLocation = async () => {
            const settings = await GetMySettings();
            if (settings) {
                const countryCode = settings.find(s => s.type === 'DefaultCountry');
                if (countryCode && countryCode.value) setCountryCode(countryCode.value);
            }
        };

        getUserLocation();
    }, []);

    const resetToDefaultValues = useCallback(() => {
        setShowValidation(false);
        setName('');
        setPhone('');
        setAddress1('');
        setAddress2('');
        setAddress3('');
        setSuburb('');
        setState('');
        setPostcode('');
    }, []);

    const saveChangesHandler = useCallback(async () => {
        if (!Boolean(name.trim()) || !Boolean(countryCode)) {
            setShowValidation(true);
            errorHandler && errorHandler('Name and Country are required');
            return;
        }

        loadingHandler && loadingHandler(true);
        const s: Partial<Site> = {
            name: name,
            phone: phone,
            address1: address1,
            address2: address2,
            address3: address3,
            suburb: suburb,
            state: state,
            postcode: postcode,
            country: countryCode ?? undefined
        };
        const res = await CreateSite(s, errorHandler);
        if (res) {
            const newSite = {...s, countryName: s.country, id: res.value} as Site;
            successHandler && successHandler('Site Created');
            onSiteCreated(newSite);
            resetToDefaultValues();
            closeHandler();
        }
        loadingHandler && loadingHandler(false);
    }, [name, phone, address1, address2, address3, suburb, state, postcode, countryCode,
        onSiteCreated, loadingHandler, errorHandler, successHandler, resetToDefaultValues, closeHandler]);

    const handleClose = useCallback(() => {
        closeHandler();
        resetToDefaultValues();
    }, [closeHandler, resetToDefaultValues]);

    const handleCountryChange = useCallback((c: string | null) => {
        if (c === 'AU') setState('');
        setCountryCode(c);
    }, []);

    return (
        <Dialog open={open} fullWidth>
            <DialogTitle>Quick Create Site</DialogTitle>
            <DialogContent>
                <Stack spacing={2} mt={1}>
                    <RWTextFieldComponent value={name} onChange={({target}) => setName(target.value)} label="Name" isError={ showValidation && !Boolean(name.trim()) } />
                    <RWTextFieldComponent value={phone} onChange={({target}) => setPhone(target.value)} label="Phone" />
                    <RWTextFieldComponent value={address1} onChange={({target}) => setAddress1(target.value)} label="Address 1" />
                    <RWTextFieldComponent value={address2} onChange={({target}) => setAddress2(target.value)} label="Address 2" />
                    <RWTextFieldComponent value={address3} onChange={({target}) => setAddress3(target.value)} label="Address 3" />
                    <RWTextFieldComponent value={suburb} onChange={({ target }) => setSuburb(target.value)} label="Suburb" />
                    {countryCode !== 'AU' && countryCode !== 'NZ' && <RWTextFieldComponent value={state} onChange={(e) => setState(e.target.value)} label="State" />}
                    { countryCode === 'AU' &&
                        <TextField select value={state} onChange={({target}) => setState(target.value)} label="State">
                            <MenuItem value="">None</MenuItem>
                            <MenuItem value="ACT">ACT</MenuItem>
                            <MenuItem value="New South Wales">New South Wales</MenuItem>
                            <MenuItem value="Northern Territory">Northern Territory</MenuItem>
                            <MenuItem value="Queensland">Queensland</MenuItem>
                            <MenuItem value="South Australia">South Australia</MenuItem>
                            <MenuItem value="Tasmania">Tasmania</MenuItem>
                            <MenuItem value="Victoria">Victoria</MenuItem>
                            <MenuItem value="Western Australia">Western Australia</MenuItem>
                        </TextField>
                    }
                    {countryCode === 'NZ' &&
                        <TextField select value={state} onChange={({ target }) => setState(target.value)} label="Region">
                            <MenuItem value="">None</MenuItem>
                            <MenuItem value="Auckland">Auckland</MenuItem>
                            <MenuItem value="Bay of Plenty">Bay of Plenty</MenuItem>
                            <MenuItem value="Canterbury">Canterbury</MenuItem>
                            <MenuItem value="Chatham Islands Territory">Chatham Islands Territory</MenuItem>
                            <MenuItem value="Gisborne">Gisborne</MenuItem>
                            <MenuItem value="Hawke's Bay">Hawke's Bay</MenuItem>
                            <MenuItem value="Manawatu-Wanganui">Manawatu-Wanganui</MenuItem>
                            <MenuItem value="Marlborough">Marlborough</MenuItem>
                            <MenuItem value="Nelson">Nelson</MenuItem>
                            <MenuItem value="Northland">Northland</MenuItem>
                            <MenuItem value="Otago">Otago</MenuItem>
                            <MenuItem value="Southland">Southland</MenuItem>
                            <MenuItem value="Taranaki">Taranaki</MenuItem>
                            <MenuItem value="Tasman">Tasman</MenuItem>
                            <MenuItem value="Waikato">Waikato</MenuItem>
                            <MenuItem value="Wellington">Wellington</MenuItem>
                            <MenuItem value="West Coast">West Coast</MenuItem>
                        </TextField>
                    }
                    <RWTextFieldComponent value={postcode} onChange={(e) => setPostcode(e.target.value)} label="Postcode" />
                    <CountryPicker code={countryCode} onSelectCallback={ handleCountryChange } error={ showValidation && !Boolean(countryCode)} />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="error" onClick={ handleClose }>Cancel</Button>
                <Button variant="contained" color="success" onClick={ saveChangesHandler }>Submit</Button>
            </DialogActions>
        </Dialog>
    );
}