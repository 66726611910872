import React, { useCallback, useEffect, useState } from "react";
import CustomContentConfirmationDialog from "./Generic/CustomContentConfirmationDialog";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { SendCandidateToOutsourcingWizard } from "services/CandidatesService";
import RWTextFieldComponent from "components/RWTextFieldComponent";

interface Props {
    open: boolean,
    candidateId: number,
    jobId?: number,
    closeHandler: () => void,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
    successHandler?: (message: string) => void
}

export default function SendCvForFormattingDialog({ open, candidateId, jobId = 0, closeHandler, loadingHandler, errorHandler, successHandler }: Props) {
    const [priority, setPriority] = useState('p2');
    const [template, setTemplate] = useState('');
    const [comments, setComments] = useState('');

    useEffect(() => {
        if (!open) {
            setPriority('p2');
            setTemplate('');
            setComments('');
        }
    }, [open]);

    const sendCallback = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        const res = await SendCandidateToOutsourcingWizard(candidateId, jobId, priority, template, comments, errorHandler);
        if (res) {
            closeHandler();
            successHandler && successHandler('CV Sent');
        }
        loadingHandler && loadingHandler(false);
    }, [candidateId, jobId, priority, template, comments, errorHandler, loadingHandler, successHandler, closeHandler]);

    return (
        <CustomContentConfirmationDialog
            fullWidth
            open={open}
            cancelHandler={ closeHandler }
            confirmHandler={ sendCallback }
            confirmButtonText="Send"
            title="Send CV for formatting"
        >
            <Stack spacing={2} mt="10px">
                <TextField select value={priority} label="Priority" onChange={ ({target}) => setPriority(target.value) }>
                    <MenuItem value="p1">P1</MenuItem>
                    <MenuItem value="p2">P2</MenuItem>
                </TextField>
                <RWTextFieldComponent value={template} label="Formatted CV Template" onChange={(e) => setTemplate(e.target.value) } />
                <RWTextFieldComponent
                    value={comments}
                    label="Comments"
                    onChange={(e) => setComments(e.target.value) }
                    multiline
                    minRows={6}
                />
            </Stack>
        </CustomContentConfirmationDialog>
    );
}