import React, { useCallback, useEffect, useMemo, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import { GridColDef, GridRenderCellParams, useGridApiRef } from "@mui/x-data-grid-premium";

import {MeetingType} from "../../../common/models/Configuration/MeetingType";
import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import PageContentLayout from "layouts/PageContentLayout";
import { GetMeetingTypes, CreateMeetingType, UpdateMeetingType } from "services/MeetingsService";
import GridWithStateWrapper from "components/GridWidthStateWrapper";
import UnsavedChangesDialog from "components/Dialogs/UnsavedChangesDialog";
import RWTextFieldComponent from "components/RWTextFieldComponent";

interface Props {
    setSummaryBar?: (summaryBar: JSX.Element) => void
}

export default function MeetingTypes({ setSummaryBar }: Props) {
    const [isLoading, setIsLoading] = useState(false);
    const [rows, setRows] = useState<MeetingType[]>([]);
    const [initialDialogData, setInitialDialogData] = useState<MeetingType | null>(null);
    const [dialogData, setDialogData] = useState<MeetingType | null>(null);
    const [showUnsavedChangesDialog, setShowUnsavedChangesDialog] = useState(false);
    const apiRef = useGridApiRef();

    const createBlankHandler = useCallback(async () => {
        const d: MeetingType = {
            id: 0,
            name: '',
            candidateOrClientMeeting: 0,
            isEnabledForContacts: false,
            isEnabledForCandidates: false,
            isEnabledForPlacements: false
        };
        
        setDialogData(d);
        setInitialDialogData(d);
        return true;
    }, []);

    useEffect(() => {
        const actionButton = <Button variant="contained" color="primary" onClick={createBlankHandler}>Create Type</Button>
        
        const sb = <TitleAndActionSummaryBar title="Configuration > Meeting Types" browserTabTitle="Meeting Types > Configuration"  action={actionButton} />;
        setSummaryBar && setSummaryBar(sb)
    }, [setSummaryBar, createBlankHandler]);


    useEffect(() => {
        const getData = async () => {
            setIsLoading(true);
            const data = await GetMeetingTypes();
            if(data) setRows(data);
            
            setIsLoading(false);
        };
        getData();
    }, []);
    
    const columns = useMemo<GridColDef[]>(() => {
        const dbNameRenderer = (params: GridRenderCellParams) => {
            return (
                <span
                    onClick={() => {
                        setDialogData(params.row);
                        setInitialDialogData(params.row);
                    }}
                    style={{ cursor: 'pointer', textDecoration: 'underline' }}
                >{params.value}</span>
            );
        };

        const candidateOrClientRenderer = (params: GridRenderCellParams) => {
            if(params.value === 1)
                return (<span>Client</span>);
            if(params.value === 3)
                return (<span>Candidate</span>);
            return (<span>Invalid</span>);
        };

        const IsEnabledRenderer = (params: GridRenderCellParams) => {
            if(params.value === true)
                return (<span>Yes</span>);
            if(params.value === false)
                return (<span>No</span>);
        };

        return [
            { field: 'id', headerName: 'ID' },
            { field: 'name', headerName: 'Name', width: 200, renderCell: dbNameRenderer },
            { field: 'candidateOrClientMeeting', headerName: 'Candidate or Client Meeting', width: 200, renderCell: candidateOrClientRenderer, align: "center", headerAlign: "center" },
            { field: 'isEnabledForContacts', headerName: 'Enabled for Contacts', width: 200, renderCell: IsEnabledRenderer, align: "center", headerAlign: "center" },
            { field: 'isEnabledForCandidates', headerName: 'Enabled for Candidates', width: 200, renderCell: IsEnabledRenderer, align: "center", headerAlign: "center" },
            { field: 'isEnabledForPlacements', headerName: 'Enabled for Placements', width: 200, renderCell: IsEnabledRenderer, align: "center", headerAlign: "center" },
        ];
    }, []);

    const onChangeString = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setDialogData(prev => ( prev ? {...prev, [name]: value} : null));
    };

    const onChangeNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        const numValue = +value;
        setDialogData(prev => ( prev ? {...prev, [name]: numValue} : null));
    };
    
    const onSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = e.target;
        setDialogData(prev => ( prev ? {...prev, [name]: checked} : null));
    };

    const hasChanges = useMemo(() => {
        if (dialogData && initialDialogData) {
            if (dialogData.name !== initialDialogData.name) return true;
            if (dialogData.candidateOrClientMeeting !== initialDialogData.candidateOrClientMeeting) return true;
            if (dialogData.isEnabledForContacts !== initialDialogData.isEnabledForContacts) return true;
            if (dialogData.isEnabledForCandidates !== initialDialogData.isEnabledForCandidates) return true;
            if (dialogData.isEnabledForPlacements !== initialDialogData.isEnabledForPlacements) return true;
        }
        return false;
    }, [dialogData, initialDialogData]);

    const closeEditDialogHandler = useCallback(() => {
        setDialogData(null);
        setInitialDialogData(null);
        setShowUnsavedChangesDialog(false);
    }, []);

    const saveChanges = useCallback(async () => {
        if(dialogData){
            setIsLoading(true);
            
            let res : boolean | null = false;
            
            if(dialogData.id === 0)
                res = await CreateMeetingType(dialogData);
            else res = await UpdateMeetingType(dialogData.id, dialogData);
            
            if(res) {
                if(dialogData.id === 0) // Have to do full reload so we have an accurate Id value
                {
                    setIsLoading(true);
                    const data = await GetMeetingTypes();
                    if(data) setRows(data);

                    setIsLoading(false);
                } else { // We can just refresh the existing row data in UI
                    apiRef.current.updateRows([dialogData]);
                }
            }
            closeEditDialogHandler();
            setIsLoading(false);
        }
    }, [dialogData, apiRef, closeEditDialogHandler]);

    const closeHandlerWithConfirmation = useMemo(() => hasChanges ? () => setShowUnsavedChangesDialog(true) : closeEditDialogHandler, [hasChanges, closeEditDialogHandler]);

    return (
        <>
            {dialogData &&
                <Dialog open={true} maxWidth="sm" fullWidth onClose={ closeHandlerWithConfirmation }>
                    <DialogTitle>Meeting Type - {dialogData.name}</DialogTitle>
                    <DialogContent dividers>
                                <Stack spacing={3}>
                                    <RWTextFieldComponent
                                        label="Name"
                                        name="name"
                                        value={dialogData.name}
                                        onChange={onChangeString}
                                    />
                                    <TextField
                                        select
                                        label="Candidate or Client Meeting"
                                        name="candidateOrClientMeeting"
                                        value={dialogData.candidateOrClientMeeting.toString()}
                                        onChange={onChangeNumber}
                                    >
                                        <MenuItem value="3">Candidate</MenuItem>
                                        <MenuItem value="1">Client</MenuItem>
                                    </TextField>
                                    <FormControlLabel
                                        control={<Switch checked={dialogData.isEnabledForContacts} name="isEnabledForContacts" onChange={onSwitchChange} />}
                                        label="Is Enabled for Contacts"
                                    />
                                    <FormControlLabel
                                        control={<Switch checked={dialogData.isEnabledForCandidates} name="isEnabledForCandidates" onChange={onSwitchChange} />}
                                        label="Is Enabled for Candidates"
                                    />
                                    <FormControlLabel
                                        control={<Switch checked={dialogData.isEnabledForPlacements} name="isEnabledForPlacements" onChange={onSwitchChange} />}
                                        label="Is Enabled for Placements"
                                    />

                                </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="error" onClick={ closeHandlerWithConfirmation }>Cancel</Button>
                        <Button variant="contained" color="success" disabled={!hasChanges} onClick={saveChanges}>Save</Button>
                    </DialogActions>
                </Dialog>
            }
            <UnsavedChangesDialog
                open={showUnsavedChangesDialog}
                discardChangesHandler={closeEditDialogHandler}
                cancelHandler={() => setShowUnsavedChangesDialog(false)}
                saveChangesHandler={saveChanges}
                message="If you continue any changes made will be lost. Are you sure you wish to continue?"
                hideBackdrop
            />
            <PageContentLayout title="Meeting Types" showLoading={isLoading}>
                <GridWithStateWrapper
                    gridName="configuration/meeting-Types"
                    apiRef={apiRef}
                    rows={rows}
                    columns={columns}
                    disableRowSelectionOnClick
                    pagination={true}
                    density="compact"
                    pageSizeOptions={[100,250,500,1000]}
                />
            </PageContentLayout>
        </>
    );
}