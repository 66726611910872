import React, { useCallback, useEffect, useState } from "react";
import Alert from "components/Alert";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import useObjectStateWithChangeTracker from '../../hooks/UseObjectStateWithChangeTracker';
import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import PageContentLayout from "layouts/PageContentLayout";
import { AuthenticationConfigOptions } from "common/models/Configuration/Authentication";
import { DefaultOptions, NoChangesTracker } from "util/Definitions/Configuration/Authentication";
import { GetAuthenticationSettings, SaveAuthenticationSettings } from '../../services/ConfigurationService';
import { ObjToSettingsArray } from '../../util/SettingsUtil';
import useUnsavedChangesDialog from "hooks/UseUnsavedChangesDialog";
import RWTextFieldComponent from "components/RWTextFieldComponent";

interface Props {
    setSummaryBar?: (summaryBar: JSX.Element) => void
}

export default function Authentication({ setSummaryBar }: Props) {
    const [isLoading, setIsLoading] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const { state, init, change, updateInitial, hasChanges } = useObjectStateWithChangeTracker<AuthenticationConfigOptions>(DefaultOptions, NoChangesTracker);

    useEffect(() => {
        const getSettings = async () => {
            setIsLoading(true);
            const data = await GetAuthenticationSettings(setErrorMessage);
            if (data) {
                let savedSettings = DefaultOptions;
                for (let i = 0; i < data.length; i++) {
                    const setting = data[i];
                    (savedSettings as any)[setting.type] = setting.value;
                }
                
                init(savedSettings);
            }
            setIsLoading(false);
        };
        getSettings();
    }, [init]);
    
    const saveSettings = useCallback(async () => {
        setIsLoading(true);
        const settings = ObjToSettingsArray(state);
        const res = await SaveAuthenticationSettings(settings, setErrorMessage);
        
        if (!res) {
            setIsLoading(false);
            return false;
        }

        updateInitial();
        setShowSuccess(true);
        setIsLoading(false);
        return true;
    }, [state, updateInitial]);

    const { unsavedChangesDialog } = useUnsavedChangesDialog(hasChanges, saveSettings);

    const handleChanges = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        change(name as keyof AuthenticationConfigOptions, value);
    }

    useEffect(() => {
        const saveButton = (
            <Button
                disabled={!hasChanges}
                variant="contained"
                color="primary"
                onClick={saveSettings}
                sx={{ borderRadius: '2px', padding: 0, height: '36px', width: '80px' }}
            >
                Save
            </Button>
        );

        const SummaryBar = (
            <TitleAndActionSummaryBar
                title="Configuration > Authentication"
                browserTabTitle="Authentication > Configuration"
                action={saveButton}
            />);

        setSummaryBar && setSummaryBar(SummaryBar);
    }, [hasChanges, saveSettings, setSummaryBar]);


    return (
        <>
            {unsavedChangesDialog}
            <Snackbar open={showSuccess} autoHideDuration={3000} onClose={() => setShowSuccess(false)}>
                <Alert onClose={() => setShowSuccess(false)}>Changes Saved</Alert>
            </Snackbar>
            <Snackbar open={errorMessage !== ''}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <PageContentLayout title="Authentication Settings" showLoading={isLoading}>
                <Stack spacing={3}>
                    <TextField
                        select
                        label="Login Method(s) Allowed"
                        name="AllowedLoginMethod"
                        value={state.AllowedLoginMethod}
                        onChange={handleChanges}
                    >
                        <MenuItem value="0">Any</MenuItem>
                        <MenuItem value="1">Microsoft Only</MenuItem>
                        <MenuItem value="2">Google Only</MenuItem>
                    </TextField>
                    <RWTextFieldComponent
                        label="Session Timeout (seconds)"
                        name="SessionTimeout"
                        value={state.SessionTimeout}
                        onChange={handleChanges}
                    />
                </Stack>
            </PageContentLayout>
        </>
    );
}