import Autocomplete, { AutocompleteInputChangeReason } from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import TextField from "@mui/material/TextField";
import React, { useCallback, useEffect, useState } from "react";
import { Opportunity } from "common/models/Opportunities";
import { GetOpportunityById, OpportunityLookup } from "services/OpportunitiesService";

interface Props {
    value: number | null,
    onSelectCallback: (c: Opportunity | null) => void,
    variant?: "standard" | "filled" | "outlined" | undefined
    label?: string,
    delayMs?: number,
    hideLabel?: boolean,
    disabled?: boolean,
    loadingHandler?: (isLoading: boolean) => void,
    successHandler?: (message: string) => void,
    errorHandler?: (message: string) => void,
}

const opportunityTypeNameMap: Record<number, string> = {
    1: 'Back Fill',
    4: 'Bid',
    3: 'Float',
    5: 'General',
    6: 'Lead',
    2: 'Tender',
};

export default function OpportunityPicker({ value, label = 'Opportunity', variant, hideLabel = false, delayMs = 200, disabled, onSelectCallback, errorHandler, loadingHandler, successHandler }: Props) {
    const [selectedOpportunity, setSelectedOpportunity] = useState<Opportunity | null>(null);
    const [lookupTerm, setLookupTerm] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState<Opportunity[]>([]);

    const getOpportunity = useCallback(async (opportunityId: number) => {
        const res = await GetOpportunityById(opportunityId);
        if (res) setSelectedOpportunity(res);
    }, []);

    useEffect(() => {
        if (value) {
            if (!selectedOpportunity || (selectedOpportunity && selectedOpportunity.id !== value)) {
                const item = options.find(o => o.id === value);
                if (item) setSelectedOpportunity(item);
                else getOpportunity(value);
            }
        }
        else setSelectedOpportunity(null);
    }, [value, selectedOpportunity, options, getOpportunity]);

    useEffect(() => {
        if (lookupTerm.length > 0) {
            const delaySearch = setTimeout(async () => {
                setIsLoading(true);
                const res = await OpportunityLookup(lookupTerm, 25);
                if (res) setOptions(res);
                setIsLoading(false);
            }, delayMs);
            return () => clearTimeout(delaySearch);
        }
    }, [lookupTerm, delayMs]);

    const handleInputChange = useCallback((event: React.SyntheticEvent<Element, Event>, value: string, reason: AutocompleteInputChangeReason) => {
        reason !== 'reset' && setLookupTerm(value);
    }, []);

    return (
        <Autocomplete
            size="small"
            fullWidth
            options={options}
            value={ selectedOpportunity }
            getOptionLabel={ o => `${o.id} - ${o.name}` }
            isOptionEqualToValue={ (o,v) => o.id === v.id }
            loading={isLoading}
            disabled={disabled}
            filterOptions={ x => x }
            onChange={ (e,v) => onSelectCallback(v) }
            renderInput={ (params) => (
                <TextField {...params} variant={variant} label={hideLabel ? undefined : label} inputProps={{ ...params.inputProps, "data-lpignore": "true" }}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (<>{isLoading ? <CircularProgress color="inherit" size={20} /> : params.InputProps.endAdornment}</>)
                    }}
                />
            )}
            onInputChange={ handleInputChange }
            renderOption={(props, option) => (
                <ListItem {...props} key={option.id}>
                    <ListItemText
                        primary={`${option.id} - ${option.name}`}
                        secondary={`${opportunityTypeNameMap[option.type]} - ${option.statusName}`}
                    />
                </ListItem>
            )}
        />
    );
}