import { GridColDef, GridColumnVisibilityModel, GridRenderCellParams, GridValueGetter, useGridApiRef } from "@mui/x-data-grid-premium";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { Submission } from "common/models/Submissions/Submission";
import { GetClientSubmissions } from "services/ClientsService";
import { GetContactSubmissions } from "services/ContactsService";
import { GetCandidateSubmissions } from "services/CandidatesService";
import { GetJobSubmissions } from "services/JobsService";
import { DownloadDocument } from "services/DocumentsService";
import GridWithStateWrapper from "../GridWidthStateWrapper";
import ViewDocumentsDialog from "../Dialogs/Documents/ViewDocumentsDialog";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import {Interview} from "../../common/models/Interviews";
import {defaultGridCellStyle} from "../../util/GridUtils";

interface Props {
    source: 'client-record' | 'contact-record' | 'job-record' | 'candidate-record',
    sourceId?: number,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
    successHandler?: (message: string) => void,
    gridName: string
}

const linkStyle: React.CSSProperties = { color: 'inherit', textDecoration: 'underline' };

const defaultHiddenCols: GridColumnVisibilityModel = {
    'id': false,
    'typeID': false,
};

export default function SubmissionsGridComponent({ source, sourceId, loadingHandler, errorHandler, successHandler, gridName }: Props) {
    const [rows, setRows] = useState<Submission[]>([]);
    const [attachmentsId, setAttachmentsId] = useState(0);
    const apiRef = useGridApiRef();

    const getData = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        let res: any | null = [];
        if (source === 'client-record' && sourceId) res = await GetClientSubmissions(sourceId, errorHandler);
        if (source === 'contact-record' && sourceId) res = await GetContactSubmissions(sourceId, errorHandler);
        if (source === 'job-record' && sourceId) res = await GetJobSubmissions(sourceId, errorHandler);
        if (source === 'candidate-record' && sourceId) res = await GetCandidateSubmissions(sourceId, errorHandler);
        if (res) setRows(res);
        loadingHandler && loadingHandler(false);
    }, [source, sourceId, loadingHandler, errorHandler]);

    useEffect(() => {
        getData();
    }, [getData]);

    const downloadDocumentCallback = useCallback(async (documentId: number) => {
        loadingHandler && loadingHandler(true);
        await DownloadDocument(documentId, undefined, errorHandler);
        loadingHandler && loadingHandler(false);
    }, [loadingHandler, errorHandler]);

    const columns = useMemo<GridColDef[]>(() => {
        const dateValueGetter: GridValueGetter<Interview, any, undefined, string> = (value) => {
            if (value) {
                const d = moment(value);
                if (d.isValid() && d.get("year") > 1) {
                    return d.toDate();
                }
            }
            return null;
        };

        const dateRenderer = (params: GridRenderCellParams) => {
            if (params.value) {
                let val = moment(params.value).format('DD MMM YYYY h:mm A');

                return (
                    <div style={defaultGridCellStyle}>
                        <span>{val}</span>
                    </div>
                );
            }
            return params.value;
        }
        
        const htmlRenderer = (params: GridRenderCellParams) => {
            if (params.value) {
                const newLineReplaced = (params.value as string).replaceAll('\n', '<br />');
                return <div style={{padding: '5px 0' }} dangerouslySetInnerHTML={{ __html: newLineReplaced }} />
            }
        }

        const linkToClientRenderer = (params: GridRenderCellParams) => {
            const clientId = params.row.clientID;
            if (clientId) {
                return <div style={defaultGridCellStyle}>
                    <Link to={`/clients/${clientId}`} style={ linkStyle } >{params.value}</Link>
                </div>;
            }
            return params.value;
        }

        const linkToJobRenderer = (params: GridRenderCellParams) => {
            const jobId = params.row.jobID;
            if (jobId) {
                return <div style={defaultGridCellStyle}>
                    <Link to={`/jobs/${jobId}`} style={ linkStyle } >{params.value}</Link>
                </div>;
            }
                return '';
        }

        const linkToCandidateRenderer = (params: GridRenderCellParams) => {
            const candidateId = params.row.candidateID;
            if (candidateId) {
                return <div style={defaultGridCellStyle}>
                     <Link to={`/candidates/${candidateId}`} style={ linkStyle } >{params.value}</Link>
                </div>;
            }
            return params.value;
        }

        const linkToSubmissionRenderer = (params: GridRenderCellParams) => {
            const submissionId = params.row.id;
            if (submissionId) {
                let toLink = `/submissions/${submissionId}`
                if (params.field === 'recipients') toLink += '?tab=Recipients';
                return <div style={defaultGridCellStyle}>
                    <Link target="_blank" to={toLink} style={ linkStyle } >{params.value}</Link>
                </div>;
            }
            return params.value;
        }

        const downloadCvActionRenderer = (params: GridRenderCellParams) => {
            if (params.value) {
                return (
                    <div style={{ minHeight: '42px', display: 'flex', alignItems: 'center' }}>
                        <span
                            style={{ cursor: 'pointer', textDecoration: 'underline', textAlign: 'center' }}
                            onClick={ () => downloadDocumentCallback(params.value as number) }
                        >{params.value}</span>
                    </div>
                );
            }
            return '';
        }
        
        const attachmentsRenderer = (params: GridRenderCellParams) => {
            if (params.value > 0) {
                return (
                 <div style={defaultGridCellStyle}>
                    <IconButton size="small" sx={{ mt: '5px' }} onClick={() => setAttachmentsId(params.row.id)}>
                        <Badge badgeContent={params.value} max={9} color="secondary" overlap="circular">
                            <AttachFileIcon />
                        </Badge>
                    </IconButton>
                </div>
                );
            }

            return <></>;
        };

        const minHeightCellRenderer = (params: GridRenderCellParams) => {
            return (
                <div style={defaultGridCellStyle}>
                    <span>{params.value}</span>
                </div>
            );
        };

        return [
            { headerName: 'ID', field: 'id', width: 75, key: true },
            { headerName: 'Date', field: 'createdDate', width: 200, type: 'date', align: 'center', headerAlign: 'center', valueGetter: dateValueGetter, renderCell: dateRenderer },
            { headerName: 'Type', field: 'typeName', align: 'center', headerAlign: 'center', width: 125, renderCell: linkToSubmissionRenderer },
            { headerName: 'Client', field: 'clientName', width: 150, renderCell: linkToClientRenderer },
            { headerName: 'Job', field: 'jobID', width: 150, renderCell: linkToJobRenderer },
            { headerName: 'Candidate', field: 'candidateName', width: 150, renderCell: linkToCandidateRenderer },
            { headerName: 'Assigned To', field: 'userName', align: 'center', headerAlign: 'center', width: 200, renderCell: minHeightCellRenderer },
            { headerName: 'Notes', field: 'notes', width: 400, renderCell: htmlRenderer },
            { headerName: 'Client Rate', field: 'clientRate', align: 'center', headerAlign: 'center', width: 250, renderCell: minHeightCellRenderer },
            { headerName: 'Candidate Rate', field: 'candidateRate', align: 'center', headerAlign: 'center', width: 250, renderCell: minHeightCellRenderer },
            { headerName: 'Recipients', field: 'recipients', type: 'number', align: 'center', headerAlign: 'center', width: 100, renderCell: linkToSubmissionRenderer },
            { headerName: 'Views', field: 'views', type: 'number', align: 'center', headerAlign: 'center', width: 80, renderCell: minHeightCellRenderer },
            { headerName: 'Feedback', field: 'feedback', type: 'number', align: 'center', headerAlign: 'center', width: 80, renderCell: linkToSubmissionRenderer },
            { headerName: 'Rating', field: 'rating', align: 'center', headerAlign: 'center', width: 80, renderCell: minHeightCellRenderer },
            { headerName: 'Attachments', field: 'attachments', type: 'number', align: 'center', headerAlign: 'center', width: 100, renderCell: attachmentsRenderer },
            { headerName: 'CV', field: 'documentID', align: 'center', headerAlign: 'center', width: 80, renderCell: downloadCvActionRenderer }
        ];
    }, [downloadDocumentCallback]);
    
    return (
        <>
            <ViewDocumentsDialog
                open={attachmentsId !== 0}
                source="submission-record"
                sourceId={attachmentsId}
                hideGridActions
                title="Submission Attachments"
                closeHandler={() => setAttachmentsId(0)}
                loadingHandler={loadingHandler}
            />
            <GridWithStateWrapper
                gridName={gridName}
                defaultViewModel={defaultHiddenCols}
                rows={rows}
                columns={columns}
                apiRef={apiRef}
                density="compact"
                disableRowSelectionOnClick
                pagination
                pageSizeOptions={[100,250,500,1000]}
                getRowHeight={ () => 'auto' }
            />
        </>
    );
}