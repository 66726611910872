import React, { useEffect, useMemo, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import * as P from '../../common/data/Permissions/ConfigurationAccess';
import { ConfigurationRoutes } from '../../common/data/TopBarRoutes';
import SideNav from '../../components/SideBars/SideNav';
import PageWithSidebarLayout from "layouts/PageWithSidebarLayout";
import Activities from "./Activities";
import InterviewTypes from "./Interviews/InterviewTypes";
import MeetingTypes from "./Meetings/MeetingTypes";
import Adverts from "./Adverts/Settings";
import AdvertsJobBoards from "./Adverts/JobBoards";
import AdvertsScreeningQuestionnaires from "./Adverts/ScreeningQuestionnaires";
import Analytics from "./Analytics";
import Authentication from "./Authentication";
import CvProcessingEmails from "./CvProcessingEmails";
import General from "./General";
import Placements from "./Placements/Settings";
import Jobs from "./Jobs/Settings";
import DivisionsList from "./Divisions/DivisionsList";
import JobBoardsAdd from "./Adverts/JobBoardsAdd";
import AdvertsNotificationTemplate from "./Adverts/NotificationTemplate";
import AdvertsScreeningQuestionnaireItem from "./Adverts/ScreeningQuestionnairesItem";
import UsersList from "./Users/UsersList";
import UsersAdd from "./Users/Add";
import GroupsList from "./Groups/GroupsList";
import CustomFieldsAdmin from "./CustomFields/CustomFields";
import DocumentTemplatesCustomFields from "./CustomFields/DocumentTemplates";
import DivisionItem from "./Divisions/DivisionItem";
import TimesheetIntegrationAstutePayroll from "./Divisions/Integrations/AstutePayroll";
import TagsAdmin from "./Tags";
import RatingReasonsAdmin from "./Jobs/RatingReasons";
import JobsSourcesAdmin from "./Jobs/Sources";
import PlacementsSourcesAdmin from "./Placements/Sources";
import ExclusionListAdmin from "./EmailIntegration/ExclusionList";
import EmailIntegrationSettings from "./EmailIntegration/Settings";
import GroupItem from "./Groups/GroupItem";
import IntegrationsIndex from "./Integrations/Index";
import CustomerIntegrationItem from "./Integrations/CustomerIntegrationItem";
import ScheduledMessagesPage from "./ScheduledMessages";
import RecentlyDeleteRecords from "./RecentlyDeletedRecords";
import SubmissionsAdmin from "./Submissions";
import PerformanceConfigPage from "./Performance";
import ClientsScreenLayoutPage from "./ScreenLayouts/Clients";
import SitesScreenLayoutPage from "./ScreenLayouts/Sites";
import ContactsScreenLayoutPage from "./ScreenLayouts/Contacts";
import OpportunitiesScreenLayoutPage from "./ScreenLayouts/Opportunities";
import CandidatessScreenLayoutPage from "./ScreenLayouts/Candidates";
import JobsScreenLayoutPage from "./ScreenLayouts/Jobs";
import SmsSettings from "./SMS/Settings";
import SmsPurchases from "./SMS/Purchases";
import PlacementsScreenLayoutPage from "./ScreenLayouts/Placements";
import ComplianceItemsConfigPage from "./Compliance/ComplianceItems";
import ComplianceChecklistsConfigPage from "./Compliance/ComplianceChecklists";
import ComplianceChecklistRecordPage from "./Compliance/ComplianceChecklistRecord";
import Automations from "./Automation/Automations";
import AutomationItem from "./Automation/AutomationItem";
import InvoiceSettings from "./Invoices/Settings";
import TaxRates from "./Invoices/TaxRates";
import Items from "./Invoices/Items";
import ClientsQuickViewLayoutPage from "./QuickViewLayouts/Clients";
import ContactsQuickViewLayoutPage from "./QuickViewLayouts/Contacts";
import JobsQuickViewLayoutPage from "./QuickViewLayouts/Jobs";
import OpportunitiesQuickViewLayoutPage from "./QuickViewLayouts/Opportunities";
import CandidatesQuickViewLayoutPage from "./QuickViewLayouts/Candidates";
import SitesQuickViewLayoutPage from "./QuickViewLayouts/Sites";
import PlacementsQuickViewLayoutPage from "./QuickViewLayouts/Placements";
import ClientSettings from "./Clients/Settings";
import { GetCustomerSettingBySettingName } from "services/ConfigurationService";
import AdvertsClassifications from "./Adverts/Classifications";
import AdvertsLocations from "./Adverts/Locations";
import ContactSettings from "./Contacts/Settings";
import CandidateSettings from "./Candidates/Settings";
import OpportunitySettings from "./Opportunities/Settings";
import SiteSettings from "./Sites/Settings";
import JobsStagesAdmin from "./Jobs/Stages";
import KanbanGroupsAdmin from "./Jobs/KanbanGroups";
import JobsCandidateStatusesAdmin from "./Jobs/CandidateStatuses";

const getFirstAllowedPath = () => {
    const { secondaryOptions } = ConfigurationRoutes;
    if (secondaryOptions && secondaryOptions.length > 0) {
        for (let i = 0; i < secondaryOptions.length; i++) {
            const route = secondaryOptions[i];
            if (!route.allow || route.allow()) {
                if (route.path) return route.path;
                if (route.options && route.options.length > 0) {
                    for (let i = 0; i < route.options.length; i++) {
                        const subRoute = route.options[i];
                        if (!subRoute.allow || subRoute.allow()) return subRoute.path;
                    }
                }
            }
        }
    }
    return '/';
}

const NotAllowed = <Navigate to='/not-authorized' />;

export default function ConfigurationIndex() {
    const [summaryBar, setSummaryBar] = useState<JSX.Element>(<></>);
    const [isAdvertsEapEnabled, setIsAdvertsEapEnabled] = useState(false);
    const firstAllowed = useMemo(() => {
        return getFirstAllowedPath();
    }, []);

    useEffect(() => {
        const getAdvertsSettings = async () => {
            const settings = await GetCustomerSettingBySettingName('AdvertsEAP');
            if (settings && settings === 'True') setIsAdvertsEapEnabled(true);
        };
        getAdvertsSettings();
    }, []);

    const ignoreRoutes = useMemo(() => {
        if (!isAdvertsEapEnabled) return ['/configuration/adverts/classifications', '/configuration/adverts/locations'];
        return [];
    }, [isAdvertsEapEnabled]);

    const ConfigurationSideNav = useMemo(() => {
        return <SideNav routes={ConfigurationRoutes.secondaryOptions} ignoreRoutes={ignoreRoutes} />;
    }, [ignoreRoutes]);

    const AllowAdverts = useMemo(() => P.AllowAdverts(), []);
    const AllowCustomFields = useMemo(() => P.AllowCustomFields(), []);
    const AllowDivisions = useMemo(() => P.AllowDivisions(), []);
    const AllowGroups = useMemo(() => P.AllowGroups(), []);
    const AllowIntegrations = useMemo(() => P.AllowIntegrations(), []);
    const AllowJobs = useMemo(() => P.AllowJobs(), []);
    const AllowPlacements = useMemo(() => P.AllowPlacements(), []);
    const AllowTags = useMemo(() => P.AllowTags(), []);
    const AllowUsers = useMemo(() => P.AllowUsers(), []);
    const AllowScreenLayouts = useMemo(() => P.AllowScreenLayouts(), []);
    const AllowSMSPurchases = useMemo(() => P.AllowSMSPurchaces(), []);
    const AllowSMSSettings = useMemo(() => P.AllowSMSSettings(), []);
    const AllowCompliance = useMemo(() => P.AllowCompliance(), []);
    const AllowCustomWorkflow = useMemo(() => P.AllowCustomWorkflows(),[]);

    return (
        <PageWithSidebarLayout SummaryBar={summaryBar} SideBar={ConfigurationSideNav} >
            <Routes>
                <Route path='' element={ P.AllowConfigurationModule() ? <Navigate to={firstAllowed} /> : NotAllowed} />
                <Route path='activities' element={ P.AllowActivities() ? <Activities setSummaryBar={setSummaryBar} /> : NotAllowed} />
                <Route path='adverts' element={P.AllowAdvertsSettings() ? <Adverts setSummaryBar={setSummaryBar} /> : NotAllowed} />
                <Route path='adverts/job-boards' element={ AllowAdverts ? <AdvertsJobBoards setSummaryBar={setSummaryBar} /> : NotAllowed } />
                <Route path='adverts/job-boards/add' element={ AllowAdverts ? <JobBoardsAdd setSummaryBar={setSummaryBar} /> : NotAllowed } />
                <Route path='adverts/notification-template' element={ AllowAdverts ? <AdvertsNotificationTemplate setSummaryBar={setSummaryBar} /> : NotAllowed } />
                <Route path='adverts/screening-questionnaires' element={ AllowAdverts ? <AdvertsScreeningQuestionnaires setSummaryBar={setSummaryBar} /> : NotAllowed } />
                <Route path='adverts/screening-questionnaires/:id' element={ AllowAdverts ? <AdvertsScreeningQuestionnaireItem setSummaryBar={setSummaryBar} /> : NotAllowed } />
                { isAdvertsEapEnabled && <Route path='adverts/classifications' element={ AllowAdverts ? <AdvertsClassifications setSummaryBar={setSummaryBar} /> : NotAllowed } /> }
                { isAdvertsEapEnabled && <Route path='adverts/locations' element={ AllowAdverts ? <AdvertsLocations setSummaryBar={setSummaryBar} /> : NotAllowed } /> }
                
                <Route path='analytics' element={P.AllowAnalytics() ? <Analytics setSummaryBar={setSummaryBar} /> : NotAllowed} />
                <Route path='authentication' element={P.AllowUsers() ? <Authentication setSummaryBar={setSummaryBar} /> : NotAllowed} />

                <Route path='automation' element={P.AllowAutomation() ? <Automations setSummaryBar={setSummaryBar} /> : NotAllowed} />
                <Route path='automation/:id' element={P.AllowAutomation() ? <AutomationItem setSummaryBar={setSummaryBar} /> : NotAllowed} />

                <Route path='clients' element={ P.AllowClients() ? <ClientSettings setSummaryBar={setSummaryBar} /> : NotAllowed } />
                <Route path='contacts' element={ P.AllowContacts() ? <ContactSettings setSummaryBar={setSummaryBar} /> : NotAllowed } />
                <Route path='candidates' element={ P.AllowCandidates() ? <CandidateSettings setSummaryBar={setSummaryBar} /> : NotAllowed } />
                <Route path='opportunities' element={ P.AllowOpportunities() ? <OpportunitySettings setSummaryBar={setSummaryBar} /> : NotAllowed } />
                <Route path='sites' element={ P.AllowSites() ? <SiteSettings setSummaryBar={setSummaryBar} /> : NotAllowed } />

                <Route path='compliance/items' element={ AllowCompliance ? <ComplianceItemsConfigPage setSummaryBar={setSummaryBar} /> : NotAllowed } />
                <Route path='compliance/checklists' element={ AllowCompliance ? <ComplianceChecklistsConfigPage setSummaryBar={setSummaryBar} /> : NotAllowed } />
                <Route path='compliance/checklists/:id' element={ AllowCompliance ? <ComplianceChecklistRecordPage setSummaryBar={setSummaryBar} /> : NotAllowed } />

                <Route path='cv-processing-emails' element={P.AllowCVProcessingEmails() ? <CvProcessingEmails setSummaryBar={setSummaryBar} /> : NotAllowed} />

                <Route path='custom-fields/document-templates' element={AllowCustomFields ? <DocumentTemplatesCustomFields setSummaryBar={setSummaryBar} /> : NotAllowed} />
                <Route path='custom-fields/candidates' element={ AllowCustomFields ? <CustomFieldsAdmin entityId={3} setSummaryBar={setSummaryBar} /> : NotAllowed } />
                <Route path='custom-fields/clients' element={ AllowCustomFields ? <CustomFieldsAdmin entityId={1} setSummaryBar={setSummaryBar} /> : NotAllowed } />
                <Route path='custom-fields/contacts' element={ AllowCustomFields ? <CustomFieldsAdmin entityId={2} setSummaryBar={setSummaryBar} /> : NotAllowed } />
                <Route path='custom-fields/jobs' element={ AllowCustomFields ? <CustomFieldsAdmin entityId={4} setSummaryBar={setSummaryBar} /> : NotAllowed } />
                <Route path='custom-fields/opportunities' element={ AllowCustomFields ? <CustomFieldsAdmin entityId={8} setSummaryBar={setSummaryBar} /> : NotAllowed } />
                <Route path='custom-fields/placements' element={ AllowCustomFields ? <CustomFieldsAdmin entityId={5} setSummaryBar={setSummaryBar} /> : NotAllowed } />
                <Route path='custom-fields/sites' element={ AllowCustomFields ? <CustomFieldsAdmin entityId={9} setSummaryBar={setSummaryBar} /> : NotAllowed } />

                <Route path='divisions' element={ AllowDivisions ? <DivisionsList setSummaryBar={setSummaryBar} /> : NotAllowed } />
                <Route path='divisions/:id' element={ AllowDivisions ? <DivisionItem setSummaryBar={setSummaryBar} /> : NotAllowed } />
                <Route path='divisions/:id/integrations/astutepayroll' element={ AllowDivisions ? <TimesheetIntegrationAstutePayroll setSummaryBar={setSummaryBar} /> : NotAllowed } />

                <Route path='email-integration/exclusion-list' element={P.AllowEmailIntegration() ? <ExclusionListAdmin setSummaryBar={setSummaryBar} /> : NotAllowed} />
                <Route path='email-integration/settings' element={P.AllowGeneral() ? <EmailIntegrationSettings setSummaryBar={setSummaryBar} /> : NotAllowed} />

                <Route path='general' element={P.AllowGeneral() ? <General setSummaryBar={setSummaryBar} /> : NotAllowed} />

                <Route path='groups' element={ AllowGroups ? <GroupsList setSummaryBar={setSummaryBar} /> : NotAllowed } />
                <Route path='groups/:id' element={ AllowGroups ? <GroupItem setSummaryBar={setSummaryBar} /> : NotAllowed } />

                <Route path='integrations' element={ AllowIntegrations ? <IntegrationsIndex setSummaryBar={setSummaryBar} /> : NotAllowed} />
                <Route path='integrations/:id' element={ AllowIntegrations ? <CustomerIntegrationItem setSummaryBar={setSummaryBar} /> : NotAllowed} />

                <Route path='placements' element={ AllowPlacements ? <Placements setSummaryBar={setSummaryBar} /> : NotAllowed} />
                <Route path='placements/sources' element={ AllowPlacements ? <PlacementsSourcesAdmin setSummaryBar={setSummaryBar} /> : NotAllowed} />

                <Route path='recently-deleted-records' element={ P.AllowRecentlyDeletedRecords() ? <RecentlyDeleteRecords setSummaryBar={setSummaryBar} /> : NotAllowed} />
                <Route path='submissions' element={ P.AllowSubmissions() ? <SubmissionsAdmin setSummaryBar={setSummaryBar} /> : NotAllowed} />

                <Route path='jobs' element={ AllowJobs ? <Jobs setSummaryBar={setSummaryBar} /> : NotAllowed } />
                <Route path='jobs/rating-reasons' element={ AllowJobs ? <RatingReasonsAdmin setSummaryBar={setSummaryBar} /> : NotAllowed } />
                <Route path='jobs/sources' element={ AllowJobs ? <JobsSourcesAdmin setSummaryBar={setSummaryBar} /> : NotAllowed } />
                <Route path='jobs/stages' element={ AllowCustomWorkflow ? <JobsStagesAdmin setSummaryBar={setSummaryBar} /> : NotAllowed } />
                <Route path='jobs/kanban-groups' element={ AllowCustomWorkflow ? <KanbanGroupsAdmin setSummaryBar={setSummaryBar} /> : NotAllowed } />
                <Route path='jobs/candidate-statuses' element={ AllowCustomWorkflow ? <JobsCandidateStatusesAdmin setSummaryBar={setSummaryBar} /> : NotAllowed } />

                <Route path='scheduled-messages' element={P.AllowScheduledMessages() ? <ScheduledMessagesPage setSummaryBar={setSummaryBar} /> : NotAllowed} />

                <Route path='screen-layouts/clients/record' element={ AllowScreenLayouts ? <ClientsScreenLayoutPage type="record" setSummaryBar={setSummaryBar} /> : NotAllowed } />
                <Route path='screen-layouts/clients/edit' element={ AllowScreenLayouts ? <ClientsScreenLayoutPage type="edit" setSummaryBar={setSummaryBar} /> : NotAllowed } />
                <Route path='screen-layouts/clients/quick-view' element={<ClientsQuickViewLayoutPage setSummaryBar={setSummaryBar} />} />

                <Route path='screen-layouts/contacts/record' element={ AllowScreenLayouts ? <ContactsScreenLayoutPage type="record" setSummaryBar={setSummaryBar} /> : NotAllowed } />
                <Route path='screen-layouts/contacts/edit' element={ AllowScreenLayouts ? <ContactsScreenLayoutPage type="edit" setSummaryBar={setSummaryBar} /> : NotAllowed } />
                <Route path='screen-layouts/contacts/quick-view' element={<ContactsQuickViewLayoutPage setSummaryBar={setSummaryBar} />} />

                <Route path='screen-layouts/candidates/record' element={ AllowScreenLayouts ? <CandidatessScreenLayoutPage type="record" setSummaryBar={setSummaryBar} /> : NotAllowed } />
                <Route path='screen-layouts/candidates/edit' element={ AllowScreenLayouts ? <CandidatessScreenLayoutPage type="edit" setSummaryBar={setSummaryBar} /> : NotAllowed } />
                <Route path='screen-layouts/candidates/quick-view' element={<CandidatesQuickViewLayoutPage setSummaryBar={setSummaryBar} />} />

                <Route path='screen-layouts/jobs/permanent/record' element={ AllowScreenLayouts ? <JobsScreenLayoutPage jobType="Permanent" type="record" setSummaryBar={setSummaryBar} /> : NotAllowed } />
                <Route path='screen-layouts/jobs/permanent/edit' element={ AllowScreenLayouts ? <JobsScreenLayoutPage jobType="Permanent" type="edit" setSummaryBar={setSummaryBar} /> : NotAllowed } />
                <Route path='screen-layouts/jobs/contract/record' element={ AllowScreenLayouts ? <JobsScreenLayoutPage jobType="Contract" type="record" setSummaryBar={setSummaryBar} /> : NotAllowed } />
                <Route path='screen-layouts/jobs/contract/edit' element={ AllowScreenLayouts ? <JobsScreenLayoutPage jobType="Contract" type="edit" setSummaryBar={setSummaryBar} /> : NotAllowed } />
                <Route path='screen-layouts/jobs/fixed-term/record' element={ AllowScreenLayouts ? <JobsScreenLayoutPage jobType="FixedContract" type="record" setSummaryBar={setSummaryBar} /> : NotAllowed } />
                <Route path='screen-layouts/jobs/fixed-term/edit' element={ AllowScreenLayouts ? <JobsScreenLayoutPage jobType="FixedContract" type="edit" setSummaryBar={setSummaryBar} /> : NotAllowed } />
                <Route path='screen-layouts/jobs/talent-pool/record' element={ AllowScreenLayouts ? <JobsScreenLayoutPage jobType="TalentPool" type="record" setSummaryBar={setSummaryBar} /> : NotAllowed } />
                <Route path='screen-layouts/jobs/talent-pool/edit' element={ AllowScreenLayouts ? <JobsScreenLayoutPage jobType="TalentPool" type="edit" setSummaryBar={setSummaryBar} /> : NotAllowed } />
                <Route path='screen-layouts/jobs/panel/record' element={ AllowScreenLayouts ? <JobsScreenLayoutPage jobType="Panel" type="record" setSummaryBar={setSummaryBar} /> : NotAllowed } />
                <Route path='screen-layouts/jobs/panel/edit' element={ AllowScreenLayouts ? <JobsScreenLayoutPage jobType="Panel" type="edit" setSummaryBar={setSummaryBar} /> : NotAllowed } />
                <Route path='screen-layouts/jobs/permanent/quick-view' element={<JobsQuickViewLayoutPage jobType="Permanent" setSummaryBar={setSummaryBar} />} />
                <Route path='screen-layouts/jobs/contract/quick-view' element={<JobsQuickViewLayoutPage jobType="Contract" setSummaryBar={setSummaryBar} />} />
                <Route path='screen-layouts/jobs/fixed-term/quick-view' element={<JobsQuickViewLayoutPage jobType="FixedContract" setSummaryBar={setSummaryBar} />} />
                <Route path='screen-layouts/jobs/talent-pool/quick-view' element={<JobsQuickViewLayoutPage jobType="TalentPool" setSummaryBar={setSummaryBar} />} />
                <Route path='screen-layouts/jobs/panel/quick-view' element={<JobsQuickViewLayoutPage jobType="Panel" setSummaryBar={setSummaryBar} />} />

                <Route path='screen-layouts/placements/permanent/record' element={ AllowScreenLayouts ? <PlacementsScreenLayoutPage placementType="Permanent" type="record" setSummaryBar={setSummaryBar} /> : NotAllowed } />
                <Route path='screen-layouts/placements/permanent/edit' element={ AllowScreenLayouts ? <PlacementsScreenLayoutPage placementType="Permanent" type="edit" setSummaryBar={setSummaryBar} /> : NotAllowed } />
                <Route path='screen-layouts/placements/contract/record' element={ AllowScreenLayouts ? <PlacementsScreenLayoutPage placementType="Contract" type="record" setSummaryBar={setSummaryBar} /> : NotAllowed } />
                <Route path='screen-layouts/placements/contract/edit' element={ AllowScreenLayouts ? <PlacementsScreenLayoutPage placementType="Contract" type="edit" setSummaryBar={setSummaryBar} /> : NotAllowed } />
                <Route path='screen-layouts/placements/fixed-term/record' element={ AllowScreenLayouts ? <PlacementsScreenLayoutPage placementType="FixedTermContract" type="record" setSummaryBar={setSummaryBar} /> : NotAllowed } />
                <Route path='screen-layouts/placements/fixed-term/edit' element={ AllowScreenLayouts ? <PlacementsScreenLayoutPage placementType="FixedTermContract" type="edit" setSummaryBar={setSummaryBar} /> : NotAllowed } />
                <Route path='screen-layouts/placements/max-term/record' element={ AllowScreenLayouts ? <PlacementsScreenLayoutPage placementType="MaxTermContract" type="record" setSummaryBar={setSummaryBar} /> : NotAllowed } />
                <Route path='screen-layouts/placements/max-term/edit' element={ AllowScreenLayouts ? <PlacementsScreenLayoutPage placementType="MaxTermContract" type="edit" setSummaryBar={setSummaryBar} /> : NotAllowed } />
                <Route path='screen-layouts/placements/margin-only/record' element={ AllowScreenLayouts ? <PlacementsScreenLayoutPage placementType="MarginOnly" type="record" setSummaryBar={setSummaryBar} /> : NotAllowed } />
                <Route path='screen-layouts/placements/margin-only/edit' element={ AllowScreenLayouts ? <PlacementsScreenLayoutPage placementType="MarginOnly" type="edit" setSummaryBar={setSummaryBar} /> : NotAllowed } />
                <Route path='screen-layouts/placements/permanent/quick-view' element={<PlacementsQuickViewLayoutPage setSummaryBar={setSummaryBar} placementType="Permanent" />} />
                <Route path='screen-layouts/placements/contract/quick-view' element={<PlacementsQuickViewLayoutPage setSummaryBar={setSummaryBar} placementType="Contract" />} />
                <Route path='screen-layouts/placements/fixed-term/quick-view' element={<PlacementsQuickViewLayoutPage setSummaryBar={setSummaryBar} placementType="FixedTermContract" />} />
                <Route path='screen-layouts/placements/max-term/quick-view' element={<PlacementsQuickViewLayoutPage setSummaryBar={setSummaryBar} placementType="MaxTermContract" />} />
                <Route path='screen-layouts/placements/margin-only/quick-view' element={<PlacementsQuickViewLayoutPage setSummaryBar={setSummaryBar} placementType="MarginOnly" />} />

                <Route path='screen-layouts/sites/record' element={ AllowScreenLayouts ? <SitesScreenLayoutPage type="record" setSummaryBar={setSummaryBar} /> : NotAllowed } />
                <Route path='screen-layouts/sites/edit' element={ AllowScreenLayouts ? <SitesScreenLayoutPage type="edit" setSummaryBar={setSummaryBar} /> : NotAllowed } />
                <Route path='screen-layouts/sites/quick-view' element={<SitesQuickViewLayoutPage setSummaryBar={setSummaryBar} />} />

                <Route path='screen-layouts/opportunities/back-fill/record' element={ AllowScreenLayouts ? <OpportunitiesScreenLayoutPage opportunityType="BackFill" type="record" setSummaryBar={setSummaryBar} /> : NotAllowed } />
                <Route path='screen-layouts/opportunities/back-fill/edit' element={ AllowScreenLayouts ? <OpportunitiesScreenLayoutPage opportunityType="BackFill" type="edit" setSummaryBar={setSummaryBar} /> : NotAllowed } />
                <Route path='screen-layouts/opportunities/tender/record' element={ AllowScreenLayouts ? <OpportunitiesScreenLayoutPage opportunityType="Tender" type="record" setSummaryBar={setSummaryBar} /> : NotAllowed } />
                <Route path='screen-layouts/opportunities/tender/edit' element={ AllowScreenLayouts ? <OpportunitiesScreenLayoutPage opportunityType="Tender" type="edit" setSummaryBar={setSummaryBar} /> : NotAllowed } />
                <Route path='screen-layouts/opportunities/float/record' element={ AllowScreenLayouts ? <OpportunitiesScreenLayoutPage opportunityType="Float" type="record" setSummaryBar={setSummaryBar} /> : NotAllowed } />
                <Route path='screen-layouts/opportunities/float/edit' element={ AllowScreenLayouts ? <OpportunitiesScreenLayoutPage opportunityType="Float" type="edit" setSummaryBar={setSummaryBar} /> : NotAllowed } />
                <Route path='screen-layouts/opportunities/bid/record' element={ AllowScreenLayouts ? <OpportunitiesScreenLayoutPage opportunityType="Bid" type="record" setSummaryBar={setSummaryBar} /> : NotAllowed } />
                <Route path='screen-layouts/opportunities/bid/edit' element={ AllowScreenLayouts ? <OpportunitiesScreenLayoutPage opportunityType="Bid" type="edit" setSummaryBar={setSummaryBar} /> : NotAllowed } />
                <Route path='screen-layouts/opportunities/general/record' element={ AllowScreenLayouts ? <OpportunitiesScreenLayoutPage opportunityType="General" type="record" setSummaryBar={setSummaryBar} /> : NotAllowed } />
                <Route path='screen-layouts/opportunities/general/edit' element={ AllowScreenLayouts ? <OpportunitiesScreenLayoutPage opportunityType="General" type="edit" setSummaryBar={setSummaryBar} /> : NotAllowed } />
                <Route path='screen-layouts/opportunities/lead/record' element={ AllowScreenLayouts ? <OpportunitiesScreenLayoutPage opportunityType="Lead" type="record" setSummaryBar={setSummaryBar} /> : NotAllowed } />
                <Route path='screen-layouts/opportunities/lead/edit' element={ AllowScreenLayouts ? <OpportunitiesScreenLayoutPage opportunityType="Lead" type="edit" setSummaryBar={setSummaryBar} /> : NotAllowed } />
                <Route path='screen-layouts/opportunities/back-fill/quick-view' element={<OpportunitiesQuickViewLayoutPage setSummaryBar={setSummaryBar} opportunityType="BackFill" />} />
                <Route path='screen-layouts/opportunities/tender/quick-view' element={<OpportunitiesQuickViewLayoutPage setSummaryBar={setSummaryBar} opportunityType="Tender" />} />
                <Route path='screen-layouts/opportunities/float/quick-view' element={<OpportunitiesQuickViewLayoutPage setSummaryBar={setSummaryBar} opportunityType="Float" />} />
                <Route path='screen-layouts/opportunities/bid/quick-view' element={<OpportunitiesQuickViewLayoutPage setSummaryBar={setSummaryBar} opportunityType="Bid" />} />
                <Route path='screen-layouts/opportunities/general/quick-view' element={<OpportunitiesQuickViewLayoutPage setSummaryBar={setSummaryBar} opportunityType="General" />} />
                <Route path='screen-layouts/opportunities/lead/quick-view' element={<OpportunitiesQuickViewLayoutPage setSummaryBar={setSummaryBar} opportunityType="Lead" />} />

                <Route path='sms/settings' element={AllowSMSSettings ? <SmsSettings setSummaryBar={setSummaryBar} /> : NotAllowed} />
                <Route path='sms/purchases' element={AllowSMSPurchases ? <SmsPurchases setSummaryBar={setSummaryBar} /> : NotAllowed} />

                <Route path='performance' element={P.AllowPerformance() ? <PerformanceConfigPage setSummaryBar={setSummaryBar} /> : NotAllowed} />

                <Route path="tags/candidates" element={ AllowTags ? <TagsAdmin entityId={3} setSummaryBar={setSummaryBar} /> : NotAllowed } />
                <Route path="tags/clients" element={ AllowTags ? <TagsAdmin entityId={1} setSummaryBar={setSummaryBar} /> : NotAllowed } />
                <Route path="tags/contacts" element={ AllowTags ? <TagsAdmin entityId={2} setSummaryBar={setSummaryBar} /> : NotAllowed } />
                <Route path="tags/jobs" element={ AllowTags ? <TagsAdmin entityId={4} setSummaryBar={setSummaryBar} /> : NotAllowed } />
                <Route path="tags/opportunities" element={ AllowTags ? <TagsAdmin entityId={8} setSummaryBar={setSummaryBar} /> : NotAllowed } />
                <Route path="tags/placements" element={ AllowTags ? <TagsAdmin entityId={5} setSummaryBar={setSummaryBar} /> : NotAllowed } />
                <Route path="tags/sites" element={ AllowTags ? <TagsAdmin entityId={9} setSummaryBar={setSummaryBar} /> : NotAllowed } />

                <Route path='users' element={ AllowUsers ? <UsersList setSummaryBar={setSummaryBar} /> : NotAllowed } />
                <Route path='users/add' element={ AllowUsers ? <UsersAdd setSummaryBar={setSummaryBar} /> : NotAllowed } />

                <Route path='meetings/types' element={ P.AllowActivities() ? <MeetingTypes setSummaryBar={setSummaryBar} /> : NotAllowed} />
                <Route path='interviews/types' element={ P.AllowActivities() ? <InterviewTypes setSummaryBar={setSummaryBar} /> : NotAllowed} />
                <Route path='invoices/settings' element={P.AllowInvoices() ? <InvoiceSettings setSummaryBar={setSummaryBar} /> : NotAllowed} />
                <Route path='invoices/tax-rates' element={P.AllowInvoices() ? <TaxRates setSummaryBar={setSummaryBar} /> : NotAllowed} />
                <Route path='invoices/items' element={P.AllowInvoices() ? <Items setSummaryBar={setSummaryBar} /> : NotAllowed} />
            </Routes>
        </PageWithSidebarLayout>
    );
}