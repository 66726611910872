import { useGridApiRef } from "@mui/x-data-grid-premium/hooks/utils/useGridApiRef";
import { GridColDef } from "@mui/x-data-grid/models/colDef/gridColDef";
import React, { useEffect, useMemo, useState } from "react";
import { JobSite } from "common/models/Advert";
import { GetJobBoardsByAdvertId } from "services/AdvertsService";
import GridWithStateWrapper from "../GridWidthStateWrapper";

interface Props {
    advertId: number,
    gridName: string,
    loadingHandler?: (isLoading: boolean) => void,
    errorHander?: (message: string) => void
}

export default function AdvertBoardsGridComponent({ advertId, gridName, loadingHandler, errorHander }: Props) {
    const [rows, setRows] = useState<JobSite[]>([]);
    const apiRef = useGridApiRef();

    useEffect(() => {
        const getData = async () => {
            loadingHandler && loadingHandler(true);
            const res = await GetJobBoardsByAdvertId(advertId, false, errorHander);
            if (res) setRows(res);
            loadingHandler && loadingHandler(false);
        };
        
        getData();
    }, [advertId, loadingHandler, errorHander]);
    
    const columns = useMemo<GridColDef[]>(() => {
        return [
            { field: 'jobBoard', headerName: 'Job Board', width: 400 },
            { field: 'status', headerName: 'Status', width: 200 },
            { field: 'numApplicants', headerName: '# Applications', width: 200 },
        ];
    }, []);

    return (
        <GridWithStateWrapper
            gridName={gridName}
            rows={rows}
            columns={columns}
            apiRef={apiRef}
            getRowId={ r => r.jobBoardID }
            disableRowSelectionOnClick
            pagination
            pageSizeOptions={[100, 250, 500, 1000]}
        />
    );
}