import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { DateTimePicker } from "@mui/x-date-pickers-pro";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { Activity, Referral } from "common/models/Activities";
import { UpdateActivity } from "services/ActivitiesService";
import { CreateMultipleReferrals, CreateReferral } from "services/CommonService";
import { getJwtPayload } from "util/AuthUtils";
import { PREVIEW_CANDIDATE_RENDERER, PREVIEW_CLIENT_RENDERER, PREVIEW_CONTACT_RENDERER, PREVIEW_TABLE_LABEL, PREVIEW_TABLE_STYLE } from "util/Definitions/Constants/Previews";
import UserPicker from "../Pickers/UserPicker";
import MultipleUserPicker from "components/Pickers/MultipleUserPicker";
import { NameIdObj } from "common/models/GenericTypes";
import RWTextFieldComponent from "components/RWTextFieldComponent";

interface Props {
    open: boolean,
    dialogMode?: 'create' | 'edit',
    clientId?: number,
    clientName?: string,
    contactId?: number,
    contactName?: string,
    candidateId?: number,
    candidateName?: string,
    activity?: Activity | null,
    closeHandler: () => void,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
    successHandler?: (message: string) => void,
}

export default function SendReferralDialog({ open, dialogMode = 'create', clientId = 0, clientName = '', contactId = 0, contactName = '', candidateId = 0, candidateName = '', activity, closeHandler, loadingHandler, errorHandler, successHandler }: Props) {
    const [status, setStatus] = useState(1);
    const [date, setDate] = useState<moment.Moment | null>(null);
    const [description, setDescription] = useState('');
    const [referredBy, setReferredBy] = useState('');
    const [referTo, setReferTo] = useState(0);
    const [referToMultiple, setReferToMultiple] = useState<number[]>([]);

    useEffect(() => {
        if (open && activity) {
            const d = moment(activity.activityDate);
            setDate(d);
            setReferredBy(activity.createdByName ?? '');
            setReferTo(activity.createdByID);
            setReferToMultiple([activity.createdByID]);
            setStatus(activity.statusID);
            setDescription(activity.notes);
        }
        else if (open){
            const m = moment();
            const p = getJwtPayload();
            setStatus(1);
            setDate(m);
            setReferredBy(p && p.DisplayName ? p.DisplayName : '[Current User]');
            setReferTo(p && p.UserID ? +p.UserID : 0);
            setReferToMultiple(p && p.UserID ? [+p.UserID] : []);
            setDescription('');
        }
    }, [open, activity]);

    const submitHandler = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        const d = date ? date.format('YYYY-MM-DDTHH:mm') : '0001-01-01T00:00:00';
        if (dialogMode === 'create' && referToMultiple.length === 0) {
            errorHandler && errorHandler('At least one user to refer to must be selected');
            loadingHandler && loadingHandler(false);
            return;
        }
        if (dialogMode === 'create' && referToMultiple.length === 1) {
            const r: Referral = {
                assignedTo: referToMultiple[0],
                notes: description,
                date: d,
                status: status,
                candidateID: candidateId,
                clientID: clientId,
                contactID: contactId
            }
            const res = await CreateReferral(r, errorHandler);
            if (res) {
                successHandler && successHandler('Referral Created');
                closeHandler();
            }
        }
        else if (dialogMode === 'create' && referToMultiple.length > 1) {
            const r: Referral = {
                assignedTo: 0,
                notes: description,
                date: d,
                status: status,
                candidateID: candidateId,
                clientID: clientId,
                contactID: contactId
            }
            const res = await CreateMultipleReferrals(r, referToMultiple, errorHandler);
            if (res) {
                successHandler && successHandler('Referrals Created');
                closeHandler();
            }
        }
        else if (dialogMode === 'edit' && activity && activity.id) {
            const a: Activity = {...activity, assignedToID: referTo, notes: description, activityDate: d, statusID: status};
            const res = await UpdateActivity(a.id, a, errorHandler);
            if (res) {
                successHandler && successHandler('Referral Updated');
                closeHandler();
            }
        }
        loadingHandler && loadingHandler(false);
    }, [loadingHandler, date, dialogMode, referToMultiple, activity, errorHandler, description, status, candidateId, clientId, contactId, successHandler, closeHandler, referTo]);

    const onMultipleUserChange = useCallback((users: NameIdObj[]) => {
        setReferToMultiple(users.map(u => u.id));
    }, []);

    return (
        <Dialog open={open} fullWidth maxWidth="md">
            <DialogTitle>Referral</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Stack spacing={2}>
                            <div />
                            <TextField select label="Status" value={status.toString()} onChange={({target}) => setStatus(+target.value)}>
                                <MenuItem value="1">Open</MenuItem>
                                <MenuItem value="2">Completed</MenuItem>
                            </TextField>
                            <DateTimePicker
                                value={date}
                                label="Date"
                                onChange={m => setDate(m)}
                                slotProps={{
                                    actionBar: { actions: ["clear", "today", "cancel", "accept"] },
                                }}
                            />
                            <RWTextFieldComponent label="Description" multiline rows={4} value={description} onChange={(e) => setDescription(e.target.value)} />
                        </Stack>
                    </Grid>
                    <Grid item xs={6}>
                        <table style={PREVIEW_TABLE_STYLE}>
                            <tbody>
                                <tr>
                                    <td style={ PREVIEW_TABLE_LABEL }>Client</td>
                                    { dialogMode === 'create' && <td>{PREVIEW_CLIENT_RENDERER(clientId, clientName)}</td>}
                                    { dialogMode === 'edit' && activity && <td>{PREVIEW_CLIENT_RENDERER(activity.clientID, activity.clientName)}</td>}
                                </tr>
                                <tr>
                                    <td style={ PREVIEW_TABLE_LABEL }>Contact</td>
                                    { dialogMode === 'create' && <td>{PREVIEW_CONTACT_RENDERER(contactId, contactName)}</td>}
                                    { dialogMode === 'edit' && activity && <td>{PREVIEW_CONTACT_RENDERER(activity.contactID, activity.contactName)}</td>}
                                </tr>
                                <tr>
                                    <td style={ PREVIEW_TABLE_LABEL }>Candidate</td>
                                    { dialogMode === 'create' && <td>{PREVIEW_CANDIDATE_RENDERER(candidateId, candidateName)}</td>}
                                    { dialogMode === 'edit' && activity && <td>{PREVIEW_CANDIDATE_RENDERER(activity.candidateID, activity.candidateName)}</td>}
                                </tr>
                                <tr>
                                    <td style={PREVIEW_TABLE_LABEL}>Referred By</td>
                                    <td>{referredBy}</td>
                                </tr>
                                <tr>
                                    <td style={PREVIEW_TABLE_LABEL}>Refer To</td>
                                    <td>
                                        {dialogMode === 'edit' &&
                                            <UserPicker
                                                hideLabel
                                                userId={referTo}
                                                onSelect={ u => setReferTo(u ? u.id : 0) }
                                                disableClearable
                                            />
                                        }
                                        {dialogMode === 'create' &&
                                            <MultipleUserPicker value={referToMultiple} onSelect={onMultipleUserChange} />
                                        }
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="error" onClick={closeHandler}>Cancel</Button>
                <Button variant="contained" color="success" onClick={submitHandler} disabled={description.length === 0}>Submit</Button>
            </DialogActions>
        </Dialog>
    );
}