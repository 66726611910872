import { UserCreate } from "common/models/Configuration/UserCreate";
import { ChangeTracker, ValidationFunctions, ValidationTracker } from '../../../common/models/hooks/ChangeTracker';
import { NoValidation, Required, RequiredEmail } from "../Validation";

export const DefaultOptions: UserCreate = {
    firstName: '',
    lastName: '',
    userName: '',
    configurationManager: false,
    userType: 'standard',
    defaultDivision: 0
}

export const NoChangesTracker: ChangeTracker<UserCreate> = {
    firstName: false,
    lastName: false,
    userName: false,
    configurationManager: false,
    userType: false,
    defaultDivision: false
}

export const DefaultValidationTracker: ValidationTracker<UserCreate> = {
    firstName: '',
    lastName: '',
    userName: '',
    configurationManager: '',
    userType: '',
    defaultDivision: ''
}

export const UsersValidation: ValidationFunctions<UserCreate> = {
    firstName: Required,
    lastName: Required,
    userName: RequiredEmail,
    configurationManager: NoValidation,
    userType: NoValidation,
    defaultDivision: (value: string) => value === '0' ? 'This field is required' : ''
}