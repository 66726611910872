import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { SxProps } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { GetMonthlySCGradesReports } from "services/MiscReportsService";
import { GridColDef, useGridApiRef } from "@mui/x-data-grid-premium";
import { MonthlySCGradesReportData } from "common/models/Reports/MonthlySCGrades";
import GridWithStateWrapper from "components/GridWidthStateWrapper";

interface Props {
    description: string,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
}

const filterElementStyle: SxProps = { flex: '1 1 0', mr: 1 };

export default function TRCMonthlyStrategicCoachingReport({ description, loadingHandler, errorHandler }: Props) {
    const [rows, setRows] = useState<MonthlySCGradesReportData[]>([]);
    const [showGrid, setShowGrid] = useState(false);
    const [year, setYear] = useState(2023);
    const [month, setMonth] = useState(1);
    const apiRef = useGridApiRef();

    useEffect(() => {
        const m = moment();
        setYear(m.year());
        setMonth(m.month() + 1);
    }, []);

    const getDataCallback = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        const res = await GetMonthlySCGradesReports(year, month, errorHandler);
        if (res) {
            setShowGrid(true);
            setRows(res);
        }
        loadingHandler && loadingHandler(false);
    }, [year, month, errorHandler, loadingHandler]);

    const exportAsExcelHandler = useCallback(() => {
        const api = apiRef.current;
        if (api) {
            const m = moment();
            const filename = `TRC_MonthlyStrategicCoaching${m.format('YYYYMMDDhhmmss')}`;
            api.exportDataAsExcel({ fileName: filename });
        }
    }, [apiRef]);

    const columns = useMemo<GridColDef[]>(() => {
        return [
            { field: 'Consultant', width: 400 },
            { field: 'Grade', width: 400 },
        ];
    }, []);

    return (
        <>
            <Box display="flex">
                <TextField select label="Year" value={year.toString()} onChange={ ({target}) => setYear(+target.value) } sx={filterElementStyle}>
                    <MenuItem value={moment().year()}>{moment().year()}</MenuItem>
                    <MenuItem value={moment().year() - 1}>{moment().year() - 1}</MenuItem>
                    <MenuItem value={moment().year() - 2}>{moment().year() - 2}</MenuItem>
                    <MenuItem value={moment().year() - 3}>{moment().year() - 3}</MenuItem>
                    <MenuItem value={moment().year() - 4}>{moment().year() - 4}</MenuItem>
                    <MenuItem value={moment().year() - 5}>{moment().year() - 5}</MenuItem>
                    <MenuItem value={moment().year() - 6}>{moment().year() - 6}</MenuItem>
                    <MenuItem value={moment().year() - 7}>{moment().year() - 7}</MenuItem>
                    <MenuItem value={moment().year() - 8}>{moment().year() - 8}</MenuItem>
                </TextField>
                <TextField select label="Month" value={month.toString()} onChange={ ({target}) => setMonth(+target.value) } sx={filterElementStyle}>
                    <MenuItem value="1">January</MenuItem>
                    <MenuItem value="2">Febrary</MenuItem>
                    <MenuItem value="3">March</MenuItem>
                    <MenuItem value="4">April</MenuItem>
                    <MenuItem value="5">May</MenuItem>
                    <MenuItem value="6">June</MenuItem>
                    <MenuItem value="7">July</MenuItem>
                    <MenuItem value="8">August</MenuItem>
                    <MenuItem value="9">September</MenuItem>
                    <MenuItem value="10">October</MenuItem>
                    <MenuItem value="11">November</MenuItem>
                    <MenuItem value="12">December</MenuItem>
                </TextField>
                <Button variant="contained" color="success" onClick={getDataCallback}>Run Report</Button>
            </Box>
            <Typography component="div" variant="h6" mt={2}>Report Description</Typography>
            <Typography component="div" variant="body2" mb={2}>{description}</Typography>
            {showGrid &&
                <>
                    <Box pb="10px" ml="auto">
                        <Button variant="contained" color="success" onClick={exportAsExcelHandler}>Export As Excel</Button>
                    </Box>
                    <GridWithStateWrapper
                        density="compact"
                        gridName="reports-trc-monthly-sc-grades"
                        rows={rows}
                        columns={columns}
                        apiRef={apiRef}
                        getRowId={r => r.Consultant}
                        disableRowSelectionOnClick
                    />
                </>
            }
        </>
    );
}