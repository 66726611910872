import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import React, { useEffect, useMemo, useState } from "react";
import { Currency } from "common/models/Common";
import { GetCurrencies } from "services/CommonService";
import { SxProps } from "@mui/material/styles";

interface Props {
    currencyId: number | null,
    onSelectCallback: (currencyId: number | null, currencyCode?: string | null, currencyName?: string | null) => void,
    variant?: "standard" | "filled" | "outlined" | undefined
    label?: string,
    hideLabel?: boolean,
    sx?: SxProps,
    error?: boolean
}

export default function CurrencyPicker({ currencyId, label = "Currency", variant, hideLabel, onSelectCallback, sx, error = false }: Props) {
    const [options, setOptions] = useState<Currency[]>([]);

    useEffect(() => {
        const getData = async () => {
            const data = await GetCurrencies();
            if (data) setOptions(data);
        };
        getData();
    }, []);

    const currency = useMemo(() => {
        if (currencyId === null) return null;
        const currency = options.find(c => c.id === currencyId);
        if (currency) return currency;
        return null;
    }, [currencyId, options]);

    return (
        <Autocomplete
            size="small"
            value={currency}
            options={options}
            getOptionLabel={ o => o.formattedDescription }
            isOptionEqualToValue={ (o,v) => o.id === v.id }
            onChange={ (e,v) => onSelectCallback(v ? v.id: null, v ? v.code : null, v ? v.formattedDescription : null) }
            sx={sx}
            renderInput={ params => <TextField {...params} variant={variant} label={hideLabel ? undefined : label} error={error} inputProps={{ ...params.inputProps, "data-lpignore": "true" }} /> }
        />
    );
}