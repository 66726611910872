import IconButton from "@mui/material/IconButton";
import { GridCellEditStopParams, GridCellEditStopReasons, GridColDef, GridRenderCellParams, GridRenderEditCellParams, GridRowSelectionModel, GridColumnVisibilityModel, GridValueGetter, useGridApiRef } from "@mui/x-data-grid-premium";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import { Client } from "common/models/Clients";
import { PreviewEntityType } from "common/models/Previews/Previews";
import { ClientLookup, GetClientsByTag, GetMyClients, SearchClients, UpdateClientCustomField } from "services/ClientsService";
import GridWithStateWrapper from "../GridWidthStateWrapper";
import PreviewLoaderComponent from "../Previews/PreviewLoader";
import TagsManagementDialog from "../Dialogs/TagsManagementDialog";
import moment from "moment";
import { Division } from "common/models/Configuration/Division";
import { GetDivisions } from "services/DivisionsService";
import Chip from "@mui/material/Chip";
import Snackbar from "@mui/material/Snackbar";
import Alert from "components/Alert";
import SendEmailDialog from "../Dialogs/Messages/SendEmailDialog";
import SendSmsDialog from "../Dialogs/Messages/SendSmsDialog";
import { EmailRecipient } from "common/models/EmailRecipient";
import MergeClientsDialog from "../Dialogs/Clients/MergeClientsDialog";
import ExportRecordsDialog from "../Dialogs/ExportRecordsDialog";
import { companyHasSinglePermission, userHasSinglePermission } from "util/PermissionsUtils";
import { Permission } from "common/models/Permissions";
import { GetMarketingPlatformsAvailable } from "services/UsersService";
import { MenuOptionDefinition } from "common/models/MenuDefinition";
import ActionMenu from "../Menus/ActionMenu";
import { ClientSearch } from "common/models/Search/ClientSearch";
import { RecordExportData } from "common/models/Common";
import ExportRecordsToMailchimpDialog from "../Dialogs/ExportRecordsToMailchimpDialog";
import ExportRecordsToCampaignMonitorDialog from "../Dialogs/ExportRecordsToCampaignMonitorDialog";
import Box from "@mui/material/Box";
import FloatCandidateDialog from "../Dialogs/Submissions/SitesFloatDialog";
import { GetCustomFieldsByEntity_OnlyActive, GetPredefinedValues } from "services/CustomFieldsService";
import { CustomField, CustomFieldPredefinedValue } from "common/models/Configuration/CustomFields";
import { customFieldColsVisibility, mandatoryUdfEditValidator, numberUdfEditValidator } from "util/GridUtils";
import { DatePicker } from "@mui/x-date-pickers-pro";
import useAllUsersMap from "hooks/UseAllUsersMap";
import { GetGeneralSettings } from "services/ConfigurationService";

interface Props {
    source: 'my-clients' | 'tags' | 'search' | 'lookup',
    gridName: string,
    tagLabel?: string,
    tagTypeId?: number,
    taggedBy?: number,
    searchData?: ClientSearch,
    lookupTerm?: string,
    setRowCountHandler?: (count: number) => void,
    loadingHandler?: (isLoading: boolean) => void,
    successHandler?: (message: string) => void,
    errorHandler?: (message: string) => void,
}

interface CustomFieldWithPredefinedValues extends CustomField {
    values?: CustomFieldPredefinedValue[],
}

const linkStyle: React.CSSProperties = { color: 'inherit', textDecoration: 'underline' };
const entityTypeId = 1;
const contactsEntityTypeId = 2;

const defaultHiddenCols: GridColumnVisibilityModel = {
    "id": false,
    "tradingName": false,
    "lastActivityDate": false,
    "nextActivityDate": false,
    "division": false,
    "phone": false,
    "website": false,
    ...customFieldColsVisibility
};

export default function ClientsGridComponent({ source, gridName, tagLabel = '', tagTypeId = 0, taggedBy = 0, searchData, lookupTerm = '', setRowCountHandler, loadingHandler, successHandler, errorHandler }: Props) {
    const allUsersMap = useAllUsersMap();
    const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
    const [rows, setRows] = useState<Client[]>([]);
    const [floatRecords, setFloatRecords] = useState<number[]>([]);
    const [divisions, setDivisions] = useState<Division[]>([]);
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    const [showTagsPrevewNoDelay, setShowTagsPreviewNoDelay] = useState(false);
    const [previewType, setPreviewType] = useState<PreviewEntityType | ''>('');
    const [previewRecordId, setPreviewRecordId] = useState(0);
    const [isPreviewTags, setIsPreviewTags] = useState(false);
    const [previewRecordName, setPreviewRecordName] = useState('');
    const [taggingRecords, setTaggingRecords] = useState<number[]>([]);
    const [hasMailChimpAccess, setHasMailChimpAccess] = useState(false);
    const [hasCampaignMonitorAccess, setHasCampaignMonitorAccess] = useState(false);
    const [sendMessageDialogType, setSendMessageDialogType] = useState<'email' | 'sms' | 'marketing-list' | null>(null);
    const [exportRecordDialogType, setExportRecordDialogType] = useState<'mailchimp' | 'campaign-monitor' | null>(null);
    const [messageRecipients, setMessageRecipients] = useState<EmailRecipient[]>([]);
    const [exportRecords, setExportRecords] = useState<RecordExportData[]>([]);
    const [mergeRecords, setMergeRecords] = useState<Client[]>([]);
    const [activeFields, setActiveFields] = useState<CustomFieldWithPredefinedValues[]>([]);
    const [editCellFieldKey, setEditCellFieldKey] = useState<keyof Client | null>(null);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [onlyDataAdminsCanMerge, setOnlyDataAdminsCanMerge] = useState(false);
    const apiRef = useGridApiRef();

    const userCanAddEditClients = useMemo(() => userHasSinglePermission(Permission.ClientsAddEdit) && companyHasSinglePermission(Permission.ClientsAddEdit), []);
    const userCanEmailMarket = useMemo(() => userHasSinglePermission(Permission.EmailMarketing) && companyHasSinglePermission(Permission.EmailMarketing), []);
    const userCanSendEmail = useMemo(() => userHasSinglePermission(Permission.MessageCentre) && companyHasSinglePermission(Permission.MessageCentre), []);
    const userCanSendSms = useMemo(() => userHasSinglePermission(Permission.MessageCentreSms) && companyHasSinglePermission(Permission.MessageCentreSms), []);
    const isUserDataMaintenanceAdmin = useMemo(() => userHasSinglePermission(Permission.DataMaintenanceAdministrator), []);

    const userCanMergeRecords = useMemo(() => {
        if (onlyDataAdminsCanMerge && !isUserDataMaintenanceAdmin) return false;
        return true;
    }, [isUserDataMaintenanceAdmin, onlyDataAdminsCanMerge]);

    useEffect(() => {
        const getRows = async () => {
            loadingHandler && loadingHandler(true);
            const divs = await GetDivisions();
            if (divs) setDivisions(divs);
            let res: Client[] | null = [];
            if (source === 'my-clients') res = await GetMyClients(errorHandler);
            if (source === 'tags') res = await GetClientsByTag(tagTypeId, taggedBy, tagLabel, errorHandler);
            if (source === 'search' && searchData) res = await SearchClients(searchData, errorHandler);
            if (source === 'lookup' && lookupTerm) res = await ClientLookup(lookupTerm, 0, errorHandler);
            if (res) {
                setRowCountHandler && setRowCountHandler(res.length);
                setRows(res);
            }
            loadingHandler && loadingHandler(false);
        };

        getRows();
    }, [source, tagTypeId, taggedBy, tagLabel, searchData, lookupTerm, setRowCountHandler, loadingHandler, errorHandler]);

    useEffect(() => {
        const getGeneralSettings = async () => {
            const res = await GetGeneralSettings(setErrorMessage);
            if (res) {
                const setting = res.find(s => s.type === 'WhoCanMergeRecords');
                if (setting) setOnlyDataAdminsCanMerge(setting.value === 'DataMaintenanceAdministrator');
            }
        };
        getGeneralSettings();
    }, []);

    useEffect(() => {
        const getActiveFields = async () => {
            const customFields = await GetCustomFieldsByEntity_OnlyActive(entityTypeId);
            if (customFields) {
                let udfDefinitions: CustomFieldWithPredefinedValues[] = [];
                for (let i = 0; i < customFields.length; i++) {
                    const u = customFields[i];
                    if (u.usePredefinedValues) {
                        const vals = await GetPredefinedValues(u.id);
                        if (vals) udfDefinitions.push({ ...u, values: vals });
                        else udfDefinitions.push(u);
                        continue;
                    }
                    
                    udfDefinitions.push(u);
                }
                setActiveFields(udfDefinitions);
            }
        };
        getActiveFields();
    }, []);

    const columns = useMemo<GridColDef[]>(() => {
        const handlePreviewHover = (type: PreviewEntityType | '', id: number, isTags: boolean = false, recordName: string = '', noDelay: boolean = false) => {
            setPreviewType(type);
            setPreviewRecordId(id);
            setIsPreviewTags(isTags);
            setPreviewRecordName(recordName);
            setIsPreviewOpen(true);
            setShowTagsPreviewNoDelay(noDelay);
        };

        const handlePreviewClose = () => {
            setIsPreviewOpen(false);
            setShowTagsPreviewNoDelay(false);
        };

        const dateValueGetter: GridValueGetter<Client, any, undefined, string> = (value) => {
            if (value) {
                const d = moment(value);
                if (d.isValid() && d.get("year") > 1) {
                    return d.toDate();
                }
            }
            return null;
        };

        const dateRenderer = (params: GridRenderCellParams) => {
            if (params.value) {
                return moment(params.value).format('DD MMM YYYY');;
            }
        };

        const lastContactDateRenderer = (params: GridRenderCellParams) => {
            if (params.value) {
                return moment(params.value).format('DD MMM YYYY');;
            }
            if (params.field.startsWith('last')) return 'Never';
            return 'None';
        };

        const dateEditorRenderer = (params: GridRenderEditCellParams) => {
            const { id, value, field } = params;
            const dateUdfChangeHandler = (date: moment.Moment | null) => {
                if (date) {
                    if (date.isValid()) {
                        const formatted = date.format('YYYY-MM-DD');
                        apiRef.current.setEditCellValue({ id, field, value: formatted });
                    }
                }
                else apiRef.current.setEditCellValue({ id, field, value: null });
            };

            let v: moment.Moment | null = null;
            if (value) {
                const m = moment(value);
                if (m.isValid()) v = m;
            }

            return <DatePicker value={v} onChange={dateUdfChangeHandler} slotProps={{actionBar: { actions: ["clear", "today", "cancel", "accept"] }}} />;
        };

        const linkToClientRenderer = (params: GridRenderCellParams) => {
            if (params.value) {
                return (
                    <Link
                        to={`/clients/${params.id}`}
                        style={ linkStyle }
                        onMouseEnter={ () => handlePreviewHover('client', +params.id) }
                        onMouseLeave={ handlePreviewClose }
                        target={ source === "search" ? "_blank" : "_self" }
                    >{params.value}</Link>
                );
            }
        };

        const hasTagsRenderer = (params: GridRenderCellParams) => {
            if (userCanAddEditClients) {
                return (
                    <IconButton
                        size="small"
                        onMouseEnter={ () => handlePreviewHover('client', +params.id, true, params.row.legalName) }
                        onMouseLeave={ handlePreviewClose }
                        onClick={ () => setTaggingRecords([params.row.id]) }
                    >
                        {params.value
                            ? <LocalOfferIcon style={{ color: '#f209a6' }} />
                            : <LocalOfferOutlinedIcon />
                        }
                    </IconButton>
                );
            }
            return (
                <IconButton
                    size="small"
                    onMouseEnter={() => handlePreviewHover('client', +params.id, true, params.row.legalName)}
                    onMouseLeave={handlePreviewClose}
                    onClick={() => handlePreviewHover('client', +params.id, true, params.row.legalName, true)}
                >
                    {params.value
                        ? <LocalOfferIcon style={{ color: '#f209a6' }} />
                        : <LocalOfferOutlinedIcon />
                    }
                </IconButton>
            );
        };

        const linkToClientSitesRenderer = (params: GridRenderCellParams) => {
            return (
                <Link target="_blank"
                    to={`/clients/${params.id}?tab=CLIENTS_Sites`}
                    style={ linkStyle }
                >{params.value}</Link>
            );
        };

        const linkToClientContactsRenderer = (params: GridRenderCellParams) => {
            return (
                <Link target="_blank"
                    to={`/clients/${params.id}?tab=CLIENTS_Contacts`}
                    style={ linkStyle }
                >{params.value}</Link>
            );
        };

        const linkToClientJobsRenderer = (params: GridRenderCellParams) => {
            return (
                <Link target="_blank"
                    to={`/clients/${params.id}?tab=CLIENTS_Jobs`}
                    style={ linkStyle }
                >{params.value}</Link>
            );
        };

        const linkToClientPlacementsRenderer = (params: GridRenderCellParams) => {
            return (
                <Link target="_blank"
                    to={`/clients/${params.id}?tab=CLIENTS_Placements`}
                    style={ linkStyle }
                >{params.value}</Link>
            );
        };

        const linkToPrimaryContactRenderer = (params: GridRenderCellParams) => {
            const primaryContactId = params.row.primaryContact;
            if (params.value && primaryContactId) {
                return (
                    <Link target="_blank"
                        to={`/contacts/${primaryContactId}`}
                        style={ linkStyle }
                        onMouseEnter={ () => handlePreviewHover('contact', primaryContactId) }
                        onMouseLeave={ handlePreviewClose }
                    >{params.value}</Link>
                );
            }
        };

        const divisionsValueGetter: GridValueGetter<Client, any, undefined, string> = (value) => {
            if (value) {
                const divs = value.split(';');
                let divNamesList: string[] = [];
                for (let i = 0; i < divs.length; i++) {
                    const div = divs[i];
                    if (div) {
                        const index = divisions.findIndex(d => d.id === +div);
                        if (index !== -1) divNamesList.push(divisions[index].name);
                    }
                    
                }
                return divNamesList.join(';');
            }
        };

        const divisionsRenderer = (params: GridRenderCellParams) => {
            if (params.value) {
                const names = (params.value as string).split(';');
                return names.map((d, i) => <Chip key={i} size="small" label={d} />);
            }
        };

        const ownersValueGetter: GridValueGetter<Client, any, undefined, number[]> = (value) => {
            if (value && value.length > 0) {
                let nameList: string[] = [];
                for (let i = 0; i < value.length; i++) {
                    const userId = value[i];
                    if (userId) {
                        nameList.push(allUsersMap[userId] ?? 'DELETED USER');
                    }
                    
                }
                return nameList;
            }
        };

        const ownersRenderer = (params: GridRenderCellParams) => {
            if (Array.isArray(params.value)) {
                return params.value.map((v, i) => <Chip key={i} size="small" label={v} />);
            }
        };

        const phoneLinkRenderer = (params: GridRenderCellParams) => {
            const std = params.row.phone_Standardised;
            const phone = params.row.phone;
            if (std) return <a href={`tel:${std}`} rel="noopener noreferrer" style={ linkStyle }>{phone}</a>;
            if (phone) return <a href={`tel:${phone}`} rel="noopener noreferrer" style={ linkStyle }>{phone}</a>
        };

        const websiteLinkRenderer = (params: GridRenderCellParams) => {
            const website = params.row.website as string;
            if (website && (website.startsWith('http://') || website.startsWith('https://') )) return <a href={website} rel="noopener noreferrer" style={ linkStyle }>{website}</a>;
            if (website) return <a href={`https://${website}`} rel="noopener noreferrer" style={ linkStyle }>{website}</a>;
        };

        let cols: GridColDef[] = [
            { headerName: 'ID', field: 'id', width: 75, renderCell: linkToClientRenderer },
            { headerName: 'Name', field: 'legalName', width: 300, renderCell: linkToClientRenderer },
            { headerName: 'Trading Name', field: 'tradingName', width: 300, renderCell: linkToClientRenderer },
            { headerName: 'Phone', field: 'phone', headerAlign: 'center', align: 'center', width: 150, renderCell: phoneLinkRenderer },
            { headerName: 'Website', field: 'website', headerAlign: 'center', align: 'center', width: 200, renderCell: websiteLinkRenderer },
            { headerName: 'Tags', field: 'tags', width: 70, align: 'center', headerAlign: 'center', renderCell: hasTagsRenderer, editable: false },
            { headerName: 'Location', field: 'address1', width: 250 },
            { headerName: 'Sites', field: 'sites', type: 'number', align: 'center', headerAlign: 'center', width: 100, renderCell: linkToClientSitesRenderer },
            { headerName: 'Status', field: 'statusName', align: 'center', headerAlign: 'center', width: 200 },
            { headerName: 'Primary Contact', field: 'primaryContactName', width: 200, renderCell: linkToPrimaryContactRenderer },
            { headerName: 'Last Contact', field: 'lastContactDate', align: 'center', headerAlign: 'center', width: 110, type: 'date', valueGetter: dateValueGetter, renderCell: lastContactDateRenderer },
            { headerName: 'Next Contact', field: 'nextContactDate', align: 'center', headerAlign: 'center', width: 110, type: 'date', valueGetter: dateValueGetter, renderCell: lastContactDateRenderer },
            { headerName: 'Last Activity', field: 'lastActivityDate', align: 'center', headerAlign: 'center', width: 110, type: 'date', valueGetter: dateValueGetter, renderCell: lastContactDateRenderer },
            { headerName: 'Next Activity', field: 'nextActivityDate', align: 'center', headerAlign: 'center', width: 110, type: 'date', valueGetter: dateValueGetter, renderCell: lastContactDateRenderer },
            { headerName: 'Contacts', field: 'contacts', type: 'number', align: 'center', headerAlign: 'center', width: 80, renderCell: linkToClientContactsRenderer },
            { headerName: 'All Jobs', field: 'allJobs', type: 'number', align: 'center', headerAlign: 'center', width: 100, renderCell: linkToClientJobsRenderer },
            { headerName: 'Placements', field: 'placements', type: 'number', align: 'center', headerAlign: 'center', width: 100, renderCell: linkToClientPlacementsRenderer },
            { headerName: 'Divisions', field: 'divisions', width: 300, valueGetter: divisionsValueGetter, renderCell: divisionsRenderer },
            { headerName: 'Owners', field: 'ownerIds', width: 300, valueGetter: ownersValueGetter, renderCell: ownersRenderer },
        ];

        if (activeFields && activeFields.length > 0) {
            for (let i = 0; i < activeFields.length; i++) {
                const udf = activeFields[i];
                const udfNumber = udf.name.slice(13);
                const isString = udf.dataType === 'String';
                const isDecimal = udf.dataType === 'Decimal';
                const isDate = udf.dataType === 'DateTime';
                const isEditable = udf.editableViaGrids;
                const isDropdown = udf.usePredefinedValues;
                const isMandatory = udf.mandatory;

                if (isEditable && isDecimal) {
                    cols.push({ 
                        field: `customField${udfNumber}`,
                        headerName: udf.agencyName,
                        headerAlign: 'center',
                        align: 'right',
                        editable: true,
                        preProcessEditCellProps: numberUdfEditValidator,
                    });
                }
                else if (isDate) {
                    cols.push({ 
                        field: `customField${udfNumber}`,
                        headerName: udf.agencyName,
                        headerAlign: 'center',
                        editable: isEditable,
                        valueGetter: dateValueGetter,
                        renderCell: dateRenderer,
                        renderEditCell: isEditable ? dateEditorRenderer : undefined,
                        preProcessEditCellProps: isMandatory ? mandatoryUdfEditValidator : undefined
                    });
                }
                else if (isString && isDropdown && udf.values && udf.values.length > 0) {
                    cols.push({ 
                        field: `customField${udfNumber}`,
                        headerName: udf.agencyName,
                        headerAlign: 'center',
                        editable: isEditable,
                        valueOptions: udf.values.map(v => v.value),
                        type: 'singleSelect',
                    });
                }
                else if (isEditable && isString) {
                    cols.push({ 
                        field: `customField${udfNumber}`,
                        headerName: udf.agencyName,
                        headerAlign: 'center',
                        align: 'right',
                        editable: true,
                        preProcessEditCellProps: isMandatory ? mandatoryUdfEditValidator : undefined
                    });
                }
                else {
                    cols.push({ field: `customField${udfNumber}`, headerName: udf.agencyName, headerAlign: 'center', align: isDecimal ? 'right' : undefined });
                }
            }
        }

        return cols;
    }, [activeFields, apiRef, source, userCanAddEditClients, divisions, allUsersMap]);

    const canExport = useMemo(() => companyHasSinglePermission(Permission.ClientsExport) && userHasSinglePermission(Permission.ClientsExport), []);

    const exportToExcel = useCallback(() => {
        const api = apiRef.current;
        if (api) api.exportDataAsExcel();
    }, [apiRef]);

    const sendMessageCallback = useCallback((type: 'email' | 'sms' | 'marketing-list', idKey: keyof Client, nameKey: keyof Client, emailOrPhoneKey: keyof Client) => {
        if (selectionModel.length > 0) {
            let recipients: EmailRecipient[] = [];
            for (let i = 0; i < selectionModel.length; i++) {
                const id = selectionModel[i];
                const row = rows.find(r => r.id === +id.valueOf());
                if (row) {
                    const recipientId = row[idKey] as number;
                    const recipientName = row[nameKey] as string;
                    const emailOrPhone = row[emailOrPhoneKey] as string;
                    const email = (type === 'email' || type === 'marketing-list') ? emailOrPhone : '';
                    const phone = type === 'sms' ? emailOrPhone : '';

                    recipients.push({ id: recipientId, email: email, name: recipientName, mobile: phone, client: row, isOptOut: row.primaryContactOptOut });
                }
            }
            setSendMessageDialogType(type);
            setMessageRecipients(recipients);
        }
    }, [selectionModel, rows]);

    const exportToMailchimpOrCampaignMonitorCallback = useCallback((dialogType: 'mailchimp' | 'campaign-monitor') => {
        if (selectionModel.length > 0) {
            let exportRecordsData: RecordExportData[] = [];
            for (let i = 0; i < selectionModel.length; i++) {
                const id = selectionModel[i];
                const row = rows.find(r => r.id === +id);
                if (row) {
                    exportRecordsData.push({
                        id: row.primaryContact,
                        email: row.primaryContactEmail,
                        firstName: row.primaryContactFirstName,
                        lastName: row.primaryContactLastName,
                        isOptOut: row.primaryContactOptOut,
                        client: row
                    });
                }
            }
            setExportRecordDialogType(dialogType);
            setExportRecords(exportRecordsData);
        }
    }, [selectionModel, rows]);

    const mergeRecordsHandler = useCallback(() => {
        let selectedClients: Client[] = [];
        for (let i = 0; i < selectionModel.length; i++) {
            const clientId = selectionModel[i];
            const client = rows.find(s => s.id === clientId.valueOf());
            if (client) selectedClients.push(client);
        }
        setMergeRecords(selectedClients);
    }, [rows, selectionModel]);

    useEffect(() => {
        const getData = async () => {
            const res = await GetMarketingPlatformsAvailable();
            if (res) {
                setHasMailChimpAccess(res.mailChimp);
                setHasCampaignMonitorAccess(res.campaignMonitor);
            }
        };
        userCanEmailMarket && getData();
    }, [userCanEmailMarket]);

    const performFloatHandler = useCallback(() => {
        let selectedContacts: number[] = [];
        for (let i = 0; i < selectionModel.length; i++) {
            const clientId = selectionModel[i];
            const client = rows.find(c => c.id === clientId.valueOf());
            if (client) {
                if (client.primaryContact === 0) {
                    errorHandler && errorHandler('All selected clients must have a primary contact');
                    return;
                }
                selectedContacts.push(client.primaryContact);
            }
        }
        setFloatRecords(selectedContacts);
    }, [rows, selectionModel, errorHandler]);

    const actionMenuDefinitions = useMemo<MenuOptionDefinition[]>(() => {
        const noRecordSelected = selectionModel.length === 0;
        const notEnoughForMerge = selectionModel.length < 2;
        const hasMarketingPlatform = hasMailChimpAccess || hasCampaignMonitorAccess;

        return [
            { label: 'Export', type: 'action', action: exportToExcel, allow: () => canExport },
            { label: 'Export Marketing List', type: 'action', disabled: noRecordSelected, allow: () => userCanEmailMarket && !hasMarketingPlatform, action: () => sendMessageCallback('marketing-list', 'id', 'legalName', 'primaryContactEmail') },
            { label: 'Export to Mailchimp', type: 'action', disabled: noRecordSelected, allow: () => userCanEmailMarket && hasMailChimpAccess, action: () => exportToMailchimpOrCampaignMonitorCallback('mailchimp') },
            { label: 'Export to Campaign Monitor', type: 'action', disabled: noRecordSelected, allow: () => userCanEmailMarket && hasCampaignMonitorAccess, action: () => exportToMailchimpOrCampaignMonitorCallback('campaign-monitor') },
            { label: 'Merge', type: 'action', disabled: notEnoughForMerge, allow: () => userCanAddEditClients && userCanMergeRecords, action: mergeRecordsHandler },
            { label: 'Send Email', type: 'action', disabled: noRecordSelected, allow: () => userCanSendEmail, action: () => sendMessageCallback('email', 'primaryContact', 'primaryContactName', 'primaryContactEmail' ) },
            { label: 'Send SMS', type: 'action', disabled: noRecordSelected, allow: () => userCanSendSms, action: () => sendMessageCallback('sms', 'primaryContact', 'primaryContactName', 'primaryContactPhone' ) },
            { label: 'Bulk Tag', type: 'action', disabled: noRecordSelected, allow: () => userCanAddEditClients, action: () => setTaggingRecords(selectionModel as number[]) },
        ];
    }, [selectionModel, hasMailChimpAccess, hasCampaignMonitorAccess, exportToExcel, mergeRecordsHandler, canExport, userCanEmailMarket, sendMessageCallback, exportToMailchimpOrCampaignMonitorCallback, userCanAddEditClients, userCanMergeRecords, userCanSendEmail, userCanSendSms]);

    const workflowMenuDefinitions = useMemo<MenuOptionDefinition[]>(() => {
        return [
            { label: 'Float Candidate', type: 'action', action: performFloatHandler, disabled: selectionModel.length === 0 }
        ];
    }, [selectionModel, performFloatHandler]);

    const gridActions = useMemo(() => {
        return <ActionMenu color="secondary" label="List Actions" definition={actionMenuDefinitions} />;
    }, [actionMenuDefinitions]);

    const gridWorkflowActions = useMemo(() => {
        return <ActionMenu color="primary" label="Workflow" mr="5px" definition={workflowMenuDefinitions} />;
    }, [workflowMenuDefinitions]);

    const tagManagementSuccessHandler = useCallback((message: string, recordIds: number[], finalTagCount: number) => {
        setSuccessMessage(message);
        const api = apiRef.current;
        if (api) {
            const hasTags = finalTagCount > 0;
            api.updateRows(recordIds.map(id => ({ id: id, tags: hasTags })));
        }
    }, [apiRef]);

    const cellEditHandler = useCallback((params: GridCellEditStopParams) => {
        if (params.reason === GridCellEditStopReasons.escapeKeyDown) return;
        setEditCellFieldKey(params.field as keyof Client);
    }, []);

    const processRowUpdate = useCallback(async (newRow: Client) => {
        if (editCellFieldKey && editCellFieldKey.startsWith('customField')) {
            const value = newRow[editCellFieldKey] as string;
            const n = +editCellFieldKey.slice(11);
            loadingHandler && loadingHandler(true);
            if ((n >= 16 && n <= 20) || (n >= 51 && n <= 60)) { // IS DATE                
                await UpdateClientCustomField(newRow.id, n, value ?? '0001-01-01', errorHandler);
            }
            else if (n >= 1 && n <= 60) await UpdateClientCustomField(newRow.id, n, value, errorHandler);
            loadingHandler && loadingHandler(false);
        }
        setEditCellFieldKey(null);
        return newRow;
    }, [editCellFieldKey, errorHandler, loadingHandler]);

    return (
        <>
            <PreviewLoaderComponent
                open={isPreviewOpen}
                entityType={previewType}
                recordId={previewRecordId}
                isTagsPreview={isPreviewTags}
                showDelayMs={showTagsPrevewNoDelay ? 0 : undefined}
                titleOverride={previewRecordName}
            />
            <TagsManagementDialog
                open={ taggingRecords.length > 0 }
                closeHandler={ () => setTaggingRecords([]) }
                entityId={ entityTypeId }
                recordIds={ taggingRecords }
                loadingHandler={ loadingHandler }
                errorHandler={ errorHandler }
                successHandler={ tagManagementSuccessHandler }
            />
            <ExportRecordsDialog
                open={ sendMessageDialogType === 'marketing-list' }
                closeHandler={ () => setSendMessageDialogType(null) }
                entityType="clients"
                filename="Clients"
                records={messageRecipients}
                includeTimeStamp
            />
            <ExportRecordsToMailchimpDialog
                open={exportRecordDialogType === 'mailchimp'}
                closeHandler={() => setExportRecordDialogType(null)}
                records={exportRecords}
                loadingHandler={loadingHandler}
                successHandler={successHandler}
                errorHandler={errorHandler}
            />
            <ExportRecordsToCampaignMonitorDialog
                open={exportRecordDialogType === 'campaign-monitor'}
                closeHandler={() => setExportRecordDialogType(null)}
                records={exportRecords}
                loadingHandler={loadingHandler}
                successHandler={successHandler}
                errorHandler={errorHandler}
            />
            <SendEmailDialog
                open={ sendMessageDialogType === 'email' }
                sourceEntityId={entityTypeId}
                recipients={ messageRecipients }
                recipientEntityTypeId={ contactsEntityTypeId }
                closeHandler={ () => setSendMessageDialogType(null) }
                loadingHandler={loadingHandler}
                errorHandler={errorHandler}
                successHandler={ setSuccessMessage }
            />
            <SendSmsDialog
                open={ sendMessageDialogType === 'sms' }
                sourceEntityId={entityTypeId}
                recipients={ messageRecipients }
                recipientEntityTypeId={ contactsEntityTypeId }
                closeHandler={ () => setSendMessageDialogType(null) }
                loadingHandler={loadingHandler}
                errorHandler={errorHandler}
                successHandler={ setSuccessMessage }
            />
            <MergeClientsDialog
                open={mergeRecords.length > 1}
                clients={mergeRecords}
                closeHandler={() => setMergeRecords([])}
                errorHandler={errorHandler}
                loadingHandler={loadingHandler}
                successHandler={setSuccessMessage}
            />
            <FloatCandidateDialog
                open={floatRecords.length > 0}
                contactIds={ floatRecords }
                closeHandler={() => setFloatRecords([])}
            />
            <Snackbar open={successMessage !== ''} autoHideDuration={3000} onClose={() => setSuccessMessage('')}>
                <Alert onClose={() => setSuccessMessage('')}>{ successMessage }</Alert>
            </Snackbar>
            <Snackbar open={errorMessage !== ''} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            {Boolean(gridActions) &&
                <Box pb="10px" ml="auto">
                    <Box display="flex">
                        {gridWorkflowActions}
                        {gridActions}
                    </Box>
                </Box>
            }
            <GridWithStateWrapper
                gridName={gridName}
                rows={rows}
                columns={columns}
                apiRef={ apiRef }
                density="compact"
                checkboxSelection
                disableRowSelectionOnClick
                rowSelectionModel={selectionModel}
                defaultViewModel={defaultHiddenCols}
                onRowSelectionModelChange={sm => setSelectionModel(sm)}
                pagination
                onCellEditStop={ cellEditHandler }
                processRowUpdate={processRowUpdate}
                pageSizeOptions={[100, 250, 500, 1000]}
            />
        </>
    );
}