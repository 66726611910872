import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { TransferRecordsData, User, UsersRecordsCounts } from "common/models/Configuration/User";
import { NameIdObj } from "common/models/GenericTypes";
import { GetUsersRecordsCounts, TransferRecordsOwnership } from "services/UsersService";
import UserPicker from "components/Pickers/UserPicker";

interface Props {
    open: boolean,
    selectedUsers: User[],
    cancelHandler: () => void,
    loadingHandler: (isLoading: boolean) => void,
    successHandler: () => void,
    setErrorMessage: (message: string) => void,
}

const defaultCounts: UsersRecordsCounts = {
    clients: 0,
    contacts: 0,
    openActivities: 0,
    openJobs: 0
}

interface CatSelection {
    open_activity: boolean,
    open_jobs: boolean,
    client_ownership: boolean,
    contact_ownership: boolean,
}

const defaultCatSelection = {
    open_activity: false,
    open_jobs: false,
    client_ownership: false,
    contact_ownership: false,
}

export default function TransferRecordsDialog({ open, selectedUsers: selectedUsersProp, loadingHandler, successHandler, setErrorMessage, cancelHandler }: Props) {
    const [sourceUsers, setSourceUsers] = useState<NameIdObj[]>([]);
    const [user, setUser] = useState<NameIdObj | null>(null);
    const [counts, setCounts] = useState<UsersRecordsCounts>(defaultCounts);
    const [categories, setCategories] = useState<CatSelection>(defaultCatSelection);
    const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
    const [showConfirm, setShowConfirm] = useState(false);

    useEffect(() => {
        if (open) {
            const parsedSelected: NameIdObj[] = selectedUsersProp.map(u => ({ id: u.id, name: u.displayName }));
            setSourceUsers(parsedSelected);
        }
    }, [open, selectedUsersProp]);

    const userIds = useMemo(() => {
        return sourceUsers.map(u => u.id);
    }, [sourceUsers]);

    useEffect(() => {
        const getData = async () => {
            loadingHandler(true);
            const data = await GetUsersRecordsCounts(userIds);
            if(data) setCounts(data);
            loadingHandler(false);
        };
        userIds.length > 0 && getData();
    }, [loadingHandler, userIds]);

    const isCountsOverZero = useMemo(() => {
        if (counts.clients > 0) return true;
        if (counts.contacts > 0) return true;
        if (counts.openActivities > 0) return true;
        if (counts.openJobs > 0) return true;
        return false;
    }, [counts]);

    const isCategorySelected = useMemo(() => {
        if (categories.client_ownership) return true;
        if (categories.contact_ownership) return true;
        if (categories.open_activity) return true;
        if (categories.open_jobs) return true;
        return false;
    }, [categories]);

    useEffect(() => {
        let selected: string[] = [];
        if (categories.client_ownership) selected.push('client_ownership');
        if (categories.contact_ownership) selected.push('contact_ownership');
        if (categories.open_activity) selected.push('open_activity');
        if (categories.open_jobs) selected.push('open_jobs');
        setSelectedCategories(selected);
    }, [categories]);

    const handleCategoriesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value, checked } = e.target;
        setCategories(prev => ({ ...prev, [value]: checked }));
    };

    const performTransfer = useCallback(async () => {
        loadingHandler(true);
        if (user) {

            let data: TransferRecordsData = {
                categories: selectedCategories,
                destination: { id: user.id, name: user.name },
                sources: sourceUsers
            };
            const res = await TransferRecordsOwnership(data);
            if(res) {
                successHandler();
            }
        }
        loadingHandler(false);
    }, [loadingHandler, user, selectedCategories, sourceUsers, successHandler]);

    const addSourceUserCallback = useCallback((user: NameIdObj | null) => {
        if (user) setSourceUsers([user]);
    }, []);

    return (
        <Dialog open={open} fullWidth >
            { !showConfirm &&
                <>
                    <DialogTitle>Transfer Records</DialogTitle>
                    <DialogContent dividers>
                        { sourceUsers.length > 0 &&
                            <DialogContentText>
                                { isCountsOverZero
                                    ? 'Select the record categories you want to transfer'
                                    : 'The selected user(s) have no records to transfer'
                                }
                            </DialogContentText>
                        }
                        <Stack spacing={1}>
                            { (sourceUsers.length === 0 || !isCountsOverZero) && <UserPicker label="Source User" blurOnSelect includeInactiveUsers userId={null} onSelect={addSourceUserCallback} />}
                            { sourceUsers.length > 0 &&
                                <>
                                    <FormControl>
                                        <FormGroup>
                                            <FormControlLabel
                                                disabled={ counts.openActivities === 0 }
                                                sx={{ my: 0 }}
                                                control={ <Checkbox checked={categories.open_activity} value="open_activity" onChange={handleCategoriesChange} /> }
                                                label={`Open Activity (${counts.openActivities})`}
                                            />
                                            <FormControlLabel
                                                disabled={ counts.openJobs === 0 }
                                                sx={{ my: 0 }}
                                                control={ <Checkbox checked={categories.open_jobs} value="open_jobs" onChange={handleCategoriesChange} /> }
                                                label={`Open Jobs (${counts.openJobs})`}
                                            />
                                            <FormControlLabel
                                                disabled={ counts.clients === 0 }
                                                sx={{ my: 0 }}
                                                control={ <Checkbox checked={categories.client_ownership} value="client_ownership" onChange={handleCategoriesChange} /> }
                                                label={`Client Ownership (${counts.clients})`}
                                            />
                                            <FormControlLabel
                                                disabled={ counts.contacts === 0 }
                                                sx={{ my: 0 }}
                                                control={ <Checkbox checked={categories.contact_ownership} value="contact_ownership" onChange={handleCategoriesChange} /> }
                                                label={`Contact Ownership (${counts.contacts})`}
                                            />
                                        </FormGroup>
                                    </FormControl>
                                    <UserPicker userId={user ? user.id : null} ignoreIds={userIds} label="Transfer to User" onSelect={setUser} />
                                </>
                            }
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={ cancelHandler }>{ isCountsOverZero ? "Cancel" : 'OK'}</Button>
                        { isCountsOverZero && <Button variant="contained" color="success" onClick={ () => setShowConfirm(true) } disabled={ user === null || !isCategorySelected }>Continue</Button> }
                    </DialogActions>
                </>
            }
            {showConfirm &&
                <>
                    <DialogTitle>Confirm Transfer</DialogTitle>
                    <DialogContent dividers>
                        <DialogContentText component="span">
                            Are you sure you want to move:
                            {categories.client_ownership && <Typography fontWeight="500">- Clients</Typography>}
                            {categories.contact_ownership && <Typography fontWeight="500">- Contacts</Typography>}
                            {categories.open_activity && <Typography fontWeight="500">- Open Activity</Typography>}
                            {categories.open_jobs && <Typography fontWeight="500">- Open Jobs</Typography>}
                            <div style={{ paddingTop: '20px' }}>{`From ${sourceUsers.length === 1 ? 'User' : 'Users'}:`}</div>
                            {sourceUsers.map(u => (
                                <Typography key={u.id} fontWeight="500">- {u.name}</Typography>
                            ))}
                            <div style={{ paddingTop: '20px' }}>
                                {'To User: '}<Typography component="span" fontWeight="500">{user ? user.name : ''}</Typography>
                            </div>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={ () => setShowConfirm(false) }>Back</Button>
                        <Button variant="contained" color="success" onClick={ performTransfer }>Transfer</Button>
                    </DialogActions>
                </>
            }
        </Dialog>
    );
}