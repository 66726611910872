import React from "react";
import { Navigate } from "react-router";
import { getToken, isTokenValid } from "../util/AuthUtils";

interface State { isLogged: boolean }

export default class SecureRouteWrapper extends React.Component<any, State> {
    constructor(props: any) {
        super(props);
        const token = getToken();
        this.state = { isLogged: isTokenValid(token) }
    }

    render(): React.ReactNode {
        if (this.state.isLogged)
            return this.props.children;
        return <Navigate to={ `/login?return-url=${encodeURIComponent(document.location.pathname)}` } />
    }
}