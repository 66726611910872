import Box from "@mui/material/Box";
import React, { useMemo } from "react";
import JobBoardViewField from "./JobBoardViewField";

interface Props {
    jobBoardId: number,
    advertId: number,
    attributes?: Record<string, string>,
}

const jobCategoryMap: Record<string, string> = {
    "8": "Accountancy & Finance",
    "20": "Banking, Financial services & Insurance",
    "11": "Beauty, Hair Care, Leisure & Sport",
    "9": "Construction, Architecture & Property",
    "23": "Customer Service, Call Centres & Languages",
    "13": "Education, Childcare & Training",
    "6": "Engineering & Utilities",
    "2": "Environmental, Health & Safety",
    "27": "Executive",
    "28": "Graduate",
    "1": "Hotel & Catering",
    "16": "HR & Recruitment",
    "3": "IT",
    "4": "Legal",
    "21": "Marketing",
    "17": "Medical Professionals & Healthcare",
    "7": "Production, Manufacturing & Materials",
    "115": "Public Sector",
    "25": "Publishing, Media & Creative Arts",
    "10": "Retailing, Wholesaling & Purchasing",
    "24": "Sales",
    "26": "Science, Pharmaceutical & Food",
    "114": "Secretarial & Admin",
    "14": "Security, Trades & General Services",
    "12": "Social & Not for Profit",
    "5": "Telecoms",
    "19": "Tourism, Travel & Airlines",
    "18": "Transport, Warehousing & Motor",
};

const salaryRangeMap: Record<string, string> = {
    "-2": "See description",
    "-1": "Negotiable",
    "0": "Not disclosed",
    "1": "10000 - 20000",
    "2": "15000 - 25000",
    "3": "20000 - 30000",
    "4": "25000 - 35000",
    "5": "30000 - 40000",
    "6": "35000 - 45000",
    "7": "40000 - 50000",
    "8": "45000 - 55000",
    "9": "50000 - 60000",
    "10": "55000 - 65000",
    "11": "60000 - 70000",
    "12": "65000 - 75000",
    "13": "70000 - 80000",
    "14": "75000 - 85000",
    "15": "80000 - 90000",
    "16": "85000 - 95000",
    "17": "90000 - 100000",
    "18": "100000 - or more",
};

const jobTypeMap: Record<string, string> = {
    "1": "Permanent Full-time",
    "2": "Permanent Part-time",
    "3": "Temporary Full-time",
    "4": "Temporary Part-time",
    "5": "Contract",
    "6": "Fixed Term Contract",
    "7": "Volunteer",
};

const jobLocationMap: Record<string, string> = {
    "89": "Africa",
    "21": "Antrim",
    "22": "Armagh",
    "88": "Asia",
    "93": "Australia",
    "23": "Belfast",
    "95": "Canada",
    "90": "Caribbean",
    "2": "Carlow",
    "24": "Cavan",
    "104": "Central America",
    "41": "Clare",
    "42": "Cork",
    "43": "Cork city",
    "25": "Derry",
    "26": "Donegal",
    "27": "Down",
    "102": "Dublin",
    "96": "Dublin City Centre",
    "97": "Dublin North",
    "98": "Dublin South",
    "1": "Dublin West",
    "85": "Eastern Europe",
    "28": "Fermanagh",
    "61": "Galway",
    "62": "Galway city",
    "44": "Kerry",
    "3": "Kildare",
    "4": "Kilkenny",
    "5": "Laois",
    "63": "Leitrim",
    "45": "Limerick",
    "46": "Limerick city",
    "6": "Longford",
    "7": "Louth",
    "94": "Mainland Europe",
    "64": "Mayo",
    "8": "Meath",
    "86": "Middle East",
    "29": "Monaghan",
    "82": "Nationwide",
    "105": "New Zealand",
    "9": "Offaly",
    "81": "Other",
    "65": "Roscommon",
    "66": "Sligo",
    "103": "South America",
    "47": "Tipperary",
    "30": "Tyrone",
    "91": "UK",
    "92": "USA",
    "48": "Waterford",
    "10": "Westmeath",
    "11": "Wexford",
    "12": "Wicklow",
    "106": "Working from Home",
};

const additionalBenefitsMap: Record<string, string> = {
    "33": "Accommodation",
    "17": "Bonus",
    "6": "Canteen",
    "4": "Car Allowance",
    "31": "Clothing Allowance",
    "21": "Commission",
    "13": "Company Vehicle",
    "19": "Compressed hours",
    "8": "Creche",
    "14": "Educational assistance",
    "25": "Expenses",
    "35": "Flexi Start",
    "2": "Flexitime",
    "7": "Gym",
    "40": "Health Plan",
    "32": "Hibernian Health Cover",
    "27": "Holiday Fund",
    "3": "House-Rent Allowance",
    "23": "Laptop",
    "26": "Life Assurance",
    "37": "Lunch Vouchers",
    "30": "Mileage",
    "1": "Mobile phone",
    "16": "Negotiable",
    "5": "Paid Holidays",
    "9": "Parking",
    "12": "Pension",
    "29": "Permanent Health Insurance",
    "36": "Profit Share",
    "18": "Quinn Healthcare",
    "15": "Relocation package",
    "24": "See Description",
    "11": "Share options",
    "38": "Sports & Social Club",
    "22": "Staff discount",
    "34": "Travel Allowance",
    "28": "Travel Tax Incentives",
    "39": "Uniform",
    "10": "VHI",
    "20": "Vouchers",
};

const minimumExperienceRequiredMap: Record<string, string> = {
    "1": "See Description",
    "2": "No minimum required",
    "3": "0 - 1 Years",
    "4": "1 - 2 Years",
    "5": "2 - 3 Years",
    "6": "3 - 4 Years",
    "7": "4 - 5 Years",
    "8": "5 - 6 Years",
    "9": "6 - 7 Years",
    "10": "7 - 8 Years",
    "11": "8 - 9 Years",
    "12": "9 - 10 Years",
    "13": "10 - and more..",
};

const minimumLevelEducationMap: Record<string, string> = {
    "0": "See Description",
    "1": "No Minimum required",
    "2": "Junior-Certificate",
    "3": "Leaving-Certificate",
    "4": "Third Level Certification",
    "5": "Third Level Diploma",
    "6": "Third Level Degree",
    "7": "Post Graduate Qualification",
    "8": "Masters",
    "9": "PHD",
    "10": "Professional Qualification",
    "11": "Part Professional Qualification",
    "12": "Trade Qualification",
};

export default function IrishJobsView({ jobBoardId, advertId, attributes }: Props) {
    const atts = useMemo<Record<string, string>>(() => {
        if (attributes) return attributes;
        return {};
    }, [attributes]);

    const jobCategory = useMemo(() => atts.JobCategories ?? '', [atts.JobCategories]);
    const jobSalaryRange = useMemo(() => atts.JobSalaryRange ?? '-3', [atts.JobSalaryRange]);
    const jobType = useMemo(() => atts.JobType ?? '0', [atts.JobType]);
    const jobQues1 = useMemo(() => atts.JobQues1 ?? '', [atts.JobQues1]);
    const jobQues2 = useMemo(() => atts.JobQues2 ?? '', [atts.JobQues2]);
    const jobQues3 = useMemo(() => atts.JobQues3 ?? '', [atts.JobQues3]);
    const jobLocations = useMemo(() => atts.JobLocations ?? '', [atts.JobLocations]);
    const jobAddnlBens = useMemo(() => atts.JobAddnlBens ?? '', [atts.JobAddnlBens]);
    const jobMinExp = useMemo(() => atts.JobMinExp ?? '0', [atts.JobMinExp]);
    const jobMinQual = useMemo(() => atts.JobMinQual ?? '-1', [atts.JobMinQual]);

    return (
        <Box display="flex" flexDirection="column" gap={2}>
            <Box display="flex" gap={2} pt={2}>
                <Box display="flex" flexDirection="column" gap={2} width="50%">
                    <JobBoardViewField label="Job Category" value={jobCategoryMap[jobCategory] ?? ''} />
                    <JobBoardViewField label="Job Salary Range (€)" value={salaryRangeMap[jobSalaryRange] ?? ''} />
                    <JobBoardViewField label="Job Type" value={jobTypeMap[jobType] ?? ''} />
                    <JobBoardViewField label="Question 1" value={jobQues1} />
                    <JobBoardViewField label="Question 2" value={jobQues2} />
                    <JobBoardViewField label="Question 3" value={jobQues3} />
                </Box>
                <Box display="flex" flexDirection="column" gap={2} width="50%">
                    <JobBoardViewField label="Job Location" value={jobLocationMap[jobLocations] ?? ''} />
                    <JobBoardViewField label="Additional Benefits" value={additionalBenefitsMap[jobAddnlBens] ?? ''} />
                    <JobBoardViewField label="Minimum Experience Required" value={minimumExperienceRequiredMap[jobMinExp] ?? ''} />
                    <JobBoardViewField label="Minimum Level of Education" value={minimumLevelEducationMap[jobMinQual] ?? ''} />
                </Box>
            </Box>
        </Box>
    );
}