import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Typography from "@mui/material/Typography";
import { SeekBranding, SeekCategoryMin, SeekJobCategory, SeekJobCategorySuggestion, SeekLocation, SeekPreviewParams } from "common/models/JobPosting/Seek";
import SeekBrandingPicker from "components/Pickers/SeekBrandingPicker";
import SeekCategoryPicker from "components/Pickers/SeekCategoryPicker";
import SeekLocationPicker from "components/Pickers/SeekLocationPicker";
import SlowTextFieldWrapper from "components/SlowTextFieldWrapper";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { GenerateSeekAdvertPreviewUrl, GetSeekBoardSettingsData, GetSeekCategorySuggestions } from "services/SeekService";

interface Props {
    jobBoardId: number,
    advertId: number,
    attributes?: Record<string, string>,
    advertRecordAttributes?: Record<string, string>,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
    attributeChangeHandler?: (jobBoardId: number, key: string, value: string) => void,
    validationMessageHandler?: (jobBoardId: number, message: string) => void,
}

interface SeekPanelProductFeatures {
    branding: null | { coverImageIndicator: boolean, logoIndicator: boolean },
    searchBulletPoints: null | { limit: number }
}

interface SeekPanelProductPrice {
    summary: string,
    value: number,
    currency: string,
    tax: { value: number, code: string }
}

interface SeekPanelProduct {
    id: string,
    label: string,
    features: SeekPanelProductFeatures
    price: SeekPanelProductPrice | null
}

interface SeekPanelProductWrapper {
    selectedProduct: SeekPanelProduct
}

export default function SeekEdit({ jobBoardId, advertId, attributes, advertRecordAttributes, loadingHandler, errorHandler, attributeChangeHandler, validationMessageHandler }: Props) {
    const [isSuggestedCategoriesRequired, setIsSuggestedCategoriesRequired] = useState(false);
    const [hirerId, setHirerId] = useState('');
    const [token, setToken] = useState('');
    const [suggestedCategory1, setSuggestedCategory1] = useState<SeekJobCategorySuggestion | null>(null);
    const [suggestedCategory2, setSuggestedCategory2] = useState<SeekJobCategorySuggestion | null>(null);
    const [categorySuggestionType, setCategorySuggestionType] = useState('');
    const [hasBranding, setHasBranding] = useState(false);

    useEffect(() => {
        const getSetupData = async () => {
            loadingHandler && loadingHandler(true);
            const res = await GetSeekBoardSettingsData(jobBoardId);
            if (res && res.hirerId) setHirerId(res.hirerId);
            if (res && res.token) setToken(res.token);
            loadingHandler && loadingHandler(false);
        };
        getSetupData();
    }, [advertId, errorHandler, jobBoardId, loadingHandler]);

    const atts = useMemo<Record<string, string>>(() => {
        if (attributes) return attributes;
        return {};
    }, [attributes]);

    const recordAtts = useMemo<Record<string, string>>(() => {
        if (advertRecordAttributes) return advertRecordAttributes;
        return {};
    }, [advertRecordAttributes]);

    const locationId = useMemo(() => atts.Location ?? '', [atts.Location]);
    const locationName = useMemo(() => atts.LocationName ?? '', [atts.LocationName]);
    const category = useMemo(() => atts.Category ?? '', [atts.Category]);
    const subCategory = useMemo(() => atts.SubCategory ?? '', [atts.SubCategory]);
    const billingReference = useMemo(() => atts.BillingReference ?? '', [atts.BillingReference]);
    const branding = useMemo(() => atts.Branding ?? '', [atts.Branding]);
    const seekProductType = useMemo(() => atts.SeekProductType, [atts.SeekProductType]);
    const profileId = useMemo(() => atts.SeekPositionProfileId, [atts.SeekPositionProfileId]);
    const [initialSeekProductType] = useState(seekProductType);

    const jobTitle = useMemo(() => recordAtts.jobTitle ?? '', [recordAtts.jobTitle]);
    const salaryFrom = useMemo(() => recordAtts.salaryFrom ?? '', [recordAtts.salaryFrom]);
    const salaryTo = useMemo(() => recordAtts.salaryTo ?? '', [recordAtts.salaryTo]);
    const salaryDisplay = useMemo(() => recordAtts.displaySalary ?? '', [recordAtts.displaySalary]);
    const workType = useMemo(() => recordAtts.workType ?? '', [recordAtts.workType]);
    const payType = useMemo(() => recordAtts.salaryType ?? '', [recordAtts.salaryType]);
    const bullet1 = useMemo(() => recordAtts.bullet1 ?? '', [recordAtts.bullet1]);
    const bullet2 = useMemo(() => recordAtts.bullet2 ?? '', [recordAtts.bullet2]);
    const bullet3 = useMemo(() => recordAtts.bullet3 ?? '', [recordAtts.bullet3]);
    const shortDescription = useMemo(() => recordAtts.shortDescription ?? '', [recordAtts.shortDescription]);
    const description = useMemo(() => recordAtts.description ?? '', [recordAtts.description]);


    useEffect(() => attributeChangeHandler && attributeChangeHandler(jobBoardId, 'SalaryFrom', salaryFrom), [attributeChangeHandler, salaryFrom, jobBoardId]);
    useEffect(() => attributeChangeHandler && attributeChangeHandler(jobBoardId, 'SalaryTo', salaryTo), [attributeChangeHandler, salaryTo, jobBoardId]);
    useEffect(() => attributeChangeHandler && attributeChangeHandler(jobBoardId, 'SalaryDetails', salaryDisplay), [attributeChangeHandler, salaryDisplay, jobBoardId]);
    useEffect(() => attributeChangeHandler && attributeChangeHandler(jobBoardId, 'WorkType', workType), [attributeChangeHandler, workType, jobBoardId]);
    useEffect(() => attributeChangeHandler && attributeChangeHandler(jobBoardId, 'PayType', payType), [attributeChangeHandler, payType, jobBoardId]);

    useEffect(() => {
        if (validationMessageHandler) {
            if (hasBranding && !Boolean(branding)) validationMessageHandler(jobBoardId, 'A Branding MUST be selected on the SEEK Job Board');
            else validationMessageHandler(jobBoardId, '');
        }
    }, [branding, hasBranding, jobBoardId, validationMessageHandler]);

    const seekPanelDomId = useMemo(() => `testing-seek-panel-${jobBoardId}`, [jobBoardId]);

    const seekPanelChangeHandler = useCallback((s: SeekPanelProductWrapper) => {
        if (s.selectedProduct) {
            const p = s.selectedProduct;
            attributeChangeHandler && attributeChangeHandler(jobBoardId, 'SeekProductType', p.id);
            attributeChangeHandler && attributeChangeHandler(jobBoardId, 'SeekPrice', p.price ? p.price.value.toString() : '');
            attributeChangeHandler && attributeChangeHandler(jobBoardId, 'SeekAdTypeChanged', "true");
            const isBranding = Boolean(p.features.branding);
            setHasBranding(isBranding);
            if (!isBranding) attributeChangeHandler && attributeChangeHandler(jobBoardId, 'Branding', '');
        }
    }, [attributeChangeHandler, jobBoardId]);

    useEffect(() => {
        if (token) {
            (window as any).SeekApi.render(document.getElementById(seekPanelDomId), 'adSelection', {
                getAuthToken: async () => {
                    return token;
                },
                onChange: seekPanelChangeHandler,
                positionProfile: {
                    jobCategories: subCategory,
                    positionLocation: locationId,
                    positionOrganizations: hirerId,
                    positionTitle: jobTitle,
                    profileId: Boolean(profileId) ? profileId : undefined,
                },
                selectedAdvertisementProductId: initialSeekProductType
            });
        }
    }, [hirerId, jobTitle, locationId, seekPanelChangeHandler, seekPanelDomId, subCategory, initialSeekProductType, token, profileId]);

    useEffect(() => {
        if (categorySuggestionType === '' && category) setCategorySuggestionType('custom');
    }, [category, categorySuggestionType]);

    useEffect(() => {
        const getJobTitleSuggestions = async () => {
            const res = await GetSeekCategorySuggestions(hirerId, locationId, jobTitle, errorHandler);
            if (res) {
                const cat1 = res[0];
                const cat2 = res[1];
                if (cat1) setSuggestedCategory1(cat1.jobCategory);
                if (cat2) setSuggestedCategory2(cat2.jobCategory);
            }
        };
        isSuggestedCategoriesRequired && getJobTitleSuggestions();
    }, [errorHandler, hirerId, isSuggestedCategoriesRequired, jobTitle, locationId]);

    const locationChangeHandler = useCallback((l: SeekLocation | null) => {
        if (l) {
            attributeChangeHandler && attributeChangeHandler(jobBoardId, 'Location', l.id.value);
            attributeChangeHandler && attributeChangeHandler(jobBoardId, 'LocationName', l.contextualName);
            setIsSuggestedCategoriesRequired(true);
        }
        else {
            attributeChangeHandler && attributeChangeHandler(jobBoardId, 'Location', '');
            attributeChangeHandler && attributeChangeHandler(jobBoardId, 'LocationName', '');
        }
    }, [attributeChangeHandler, jobBoardId]);

    const categoryChangeHandler = useCallback((c: SeekJobCategory | null) => {
        if (c) attributeChangeHandler && attributeChangeHandler(jobBoardId, 'Category', c.id.value);
        else attributeChangeHandler && attributeChangeHandler(jobBoardId, 'Category', '');
    }, [attributeChangeHandler, jobBoardId]);

    const subCategoryChangeHandler = useCallback((c: SeekCategoryMin | null) => {
        if (c) attributeChangeHandler && attributeChangeHandler(jobBoardId, 'SubCategory', c.id.value);
        else attributeChangeHandler && attributeChangeHandler(jobBoardId, 'SubCategory', '');
    }, [attributeChangeHandler, jobBoardId]);

    const billingReferenceChangeHandler = useCallback((newValue: string) => {
        attributeChangeHandler && attributeChangeHandler(jobBoardId, 'BillingReference', newValue);
    }, [attributeChangeHandler, jobBoardId]);

    const brandingChangeHandler = useCallback((b: SeekBranding |  null) => {
        if (b) attributeChangeHandler && attributeChangeHandler(jobBoardId, 'Branding', b.id.value);
        else attributeChangeHandler && attributeChangeHandler(jobBoardId, 'Branding', '');
    }, [attributeChangeHandler, jobBoardId]);

    const categorySuggestionTypeChangeHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const v = e.target.value;
        if (v === 'cat1' && suggestedCategory1) {
            attributeChangeHandler && attributeChangeHandler(jobBoardId, 'Category', suggestedCategory1.parent.id.value);
            attributeChangeHandler && attributeChangeHandler(jobBoardId, 'SubCategory', suggestedCategory1.id.value);
        }
        else if (v === 'cat2' && suggestedCategory2) {
            attributeChangeHandler && attributeChangeHandler(jobBoardId, 'Category', suggestedCategory2.parent.id.value);
            attributeChangeHandler && attributeChangeHandler(jobBoardId, 'SubCategory', suggestedCategory2.id.value);
        }
        setCategorySuggestionType(e.target.value);
    }, [attributeChangeHandler, jobBoardId, suggestedCategory1, suggestedCategory2]);

    const previewOnSeekCallback = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        let previewData: SeekPreviewParams = {
            brandingId: branding,
            jobCategories: subCategory,
            positionLocation: locationId,
            positionOrganizations: hirerId,
            positionTitle: jobTitle,
            productTypeId: seekProductType,
            workType: workType,
            positionFormattedDescriptions: []
        };

        if (bullet1) previewData.positionFormattedDescriptions.push({ descriptionId: 'SearchBulletPoint', content: bullet1 });
        if (bullet2) previewData.positionFormattedDescriptions.push({ descriptionId: 'SearchBulletPoint', content: bullet2 });
        if (bullet3) previewData.positionFormattedDescriptions.push({ descriptionId: 'SearchBulletPoint', content: bullet3 });
        previewData.positionFormattedDescriptions.push({ descriptionId: 'SearchSummary', content: shortDescription });
        previewData.positionFormattedDescriptions.push({ descriptionId: 'AdvertisementDetails', content: description });



        const res = await GenerateSeekAdvertPreviewUrl(previewData, errorHandler);
        if (res && res.value) window.open(res.value, '_blank');
        loadingHandler && loadingHandler(false);
    }, [branding, bullet1, bullet2, bullet3, description, errorHandler, hirerId, jobTitle, loadingHandler, locationId, seekProductType, shortDescription, subCategory, workType]);

    return (
        <>
            <Box display="flex" flexDirection="column" gap={2}>
                <Box>
                    <Button
                        variant="contained"
                        onClick={previewOnSeekCallback}
                        disabled={!Boolean(jobTitle) || !Boolean(workType)}
                        sx={{ visibility: Boolean(seekProductType) ? undefined : 'hidden', float: 'right' }}
                    >Preview on SEEK</Button>
                </Box>
                <SeekLocationPicker
                    label="Location (Suburb or town)"
                    hirerId={hirerId}
                    value={locationId}
                    name={locationName}
                    onSelectCallback={locationChangeHandler}
                />
                <Box>
                    <Typography>Suggested category based on your job title</Typography>
                    <RadioGroup value={categorySuggestionType} onChange={categorySuggestionTypeChangeHandler} >
                        { suggestedCategory1 &&  <FormControlLabel value="cat1" control={<Radio />} label={`${suggestedCategory1.parent.name} > ${suggestedCategory1.name}`} />}
                        { suggestedCategory2 &&  <FormControlLabel value="cat2" control={<Radio />} label={`${suggestedCategory2.parent.name} > ${suggestedCategory2.name}`} />}
                        <FormControlLabel value="custom" control={<Radio />} label="Choose a different category" />
                    </RadioGroup>
                </Box>
                <Box display={ categorySuggestionType === 'custom' ? undefined : 'none'}>
                    <SeekCategoryPicker
                        label1="Category"
                        label2="Sub-Category"
                        value1={category}
                        value2={subCategory}
                        onSelectCallback1={categoryChangeHandler}
                        onSelectCallback2={subCategoryChangeHandler}
                    />
                </Box>
                <SlowTextFieldWrapper label="Billing Reference (Optional)" value={billingReference} onChange={billingReferenceChangeHandler} />
                <div id={seekPanelDomId} />
                {(hasBranding || Boolean(branding)) &&
                    <SeekBrandingPicker
                        label="Branding (Required)"
                        hirerId={hirerId}
                        value={branding}
                        isError={!Boolean(branding)}
                        onSelectCallback={brandingChangeHandler}
                    />
                }
            </Box>
        </>
    );
}