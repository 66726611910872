import React, { useCallback, useEffect, useState } from "react";
import Alert from "components/Alert";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";


import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import PageContentLayout from "layouts/PageContentLayout";
import { GetAdvertsSettings, SaveAdvertsSettings } from '../../../services/ConfigurationService';
import { DefaultOptions, NoChangesTracker } from '../../../util/Definitions/Configuration/Adverts';
import useObjectStateWithChangeTracker from "hooks/UseObjectStateWithChangeTracker";
import useUnsavedChangesDialog from "hooks/UseUnsavedChangesDialog";
import { AdvertsConfigOptions } from "common/models/Configuration/Adverts";
import { ObjToSettingsArray } from "util/SettingsUtil";
import Box from "@mui/material/Box";
import InfoButtonToolTip from "components/InfoButtonToolTip";
import RWTextFieldComponent from "components/RWTextFieldComponent";

interface Props {
    setSummaryBar?: (summaryBar: JSX.Element) => void
}

const infoTextPlaceholder = 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolore ipsam ea, nulla rem neque earum hic praesentium eum, tempora culpa quisquam eius porro nobis, fuga natus aliquid? Quo tempore porro esse natus odit officiis accusantium fugiat quisquam nostrum assumenda voluptatibus cum culpa ut dicta nesciunt debitis, maiores earum, voluptates deleniti?';

export default function Add({ setSummaryBar }: Props) {
    const [isLoading, setIsLoading] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const { state, init, change, updateInitial, hasChanges } = useObjectStateWithChangeTracker<AdvertsConfigOptions>(DefaultOptions, NoChangesTracker);

    useEffect(() => {
        const getSettings = async () => {
            setIsLoading(true);
            const data = await GetAdvertsSettings(setErrorMessage);
            if (data) {
                let savedSettings = DefaultOptions;
                for (let i = 0; i < data.length; i++) {
                    const setting = data[i];
                    (savedSettings as any)[setting.type] = setting.value;
                }

                init(savedSettings);
            }
            setIsLoading(false);
        };
        getSettings();
    }, [init]);

    const saveSettings = useCallback( async() => {
        setIsLoading(true);
        const settings = ObjToSettingsArray(state);
        const res = await SaveAdvertsSettings(settings, setErrorMessage);

        if (!res) {
            setIsLoading(false);
            return false;
        }

        updateInitial();
        setShowSuccess(true);
        setIsLoading(false);
        return true;
    }, [state, updateInitial]);

    const { unsavedChangesDialog } = useUnsavedChangesDialog(hasChanges, saveSettings);

    const handleChanges = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        change(name as keyof AdvertsConfigOptions, value);
    }

    useEffect(() => {
        const saveButton = (
            <Button
                disabled={!hasChanges}
                variant="contained"
                color="primary"
                onClick={saveSettings}
                sx={{ borderRadius: '2px', padding: 0, height: '36px', width: '80px' }}
            >
                Save
            </Button>
        );

        const SummaryBar = ( <TitleAndActionSummaryBar title="Configuration > Adverts > Settings" browserTabTitle="Adverts > Configuration" action={saveButton} />);

        setSummaryBar && setSummaryBar(SummaryBar);
    }, [setSummaryBar, saveSettings, hasChanges]);


    return (
        <>
            {unsavedChangesDialog}
            <Snackbar open={showSuccess} autoHideDuration={3000} onClose={() => setShowSuccess(false)}>
                <Alert onClose={() => setShowSuccess(false)}>Changes Saved</Alert>
            </Snackbar>
            <Snackbar open={errorMessage !== ''}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <PageContentLayout title="Advert Settings" showLoading={isLoading} >
                <Stack spacing={3}>
                    <Box display="flex">
                        <TextField
                            select
                            label="No Public Advert ID"
                            name="AdvertsNoPublicAdvertID"
                            value={state.AdvertsNoPublicAdvertID}
                            onChange={handleChanges}
                            sx={{ flex: '1 1 0', pr: '5px' }}
                        >
                            <MenuItem value="True">Enabled</MenuItem>
                            <MenuItem value="False">Disabled</MenuItem>
                        </TextField>
                        <InfoButtonToolTip text={infoTextPlaceholder} />
                    </Box>
                    <Box display="flex">
                        <TextField
                            select
                            label="Apply With LinkedIn"
                            name="ApplyWithLinkedIn"
                            value={state.ApplyWithLinkedIn}
                            onChange={handleChanges}
                            sx={{ flex: '1 1 0', pl: '5px' }}
                        >
                            <MenuItem value="True">Enabled</MenuItem>
                            <MenuItem value="False">Disabled</MenuItem>
                        </TextField>
                        <InfoButtonToolTip text={infoTextPlaceholder} />
                    </Box>
                    <TextField
                        select
                        label="Apply With Indeed"
                        name="ApplyWithIndeed"
                        value={state.ApplyWithIndeed}
                        onChange={handleChanges}
                    >
                        <MenuItem value="True">Enabled</MenuItem>
                        <MenuItem value="False">Disabled</MenuItem>
                    </TextField>
                    <TextField
                        select
                        label="Apply With SEEK"
                        name="ApplyWithSeek"
                        value={state.ApplyWithSeek}
                        onChange={handleChanges}
                    >
                        <MenuItem value="True">Enabled</MenuItem>
                        <MenuItem value="False">Disabled</MenuItem>
                    </TextField>

                    <TextField
                        select
                        label="Expire Ads when Job Closed"
                        name="ExpireAdsOnClose"
                        value={state.ExpireAdsOnClose}
                        onChange={handleChanges}
                    >
                        <MenuItem value="True">Enabled</MenuItem>
                        <MenuItem value="False">Disabled</MenuItem>
                    </TextField>
                    <TextField
                        select
                        label="Allow Users to see All Ad Templates"
                        name="AdvertsSeeAllTemplates"
                        value={state.AdvertsSeeAllTemplates}
                        onChange={handleChanges}
                    >
                        <MenuItem value="True">Enabled</MenuItem>
                        <MenuItem value="False">Disabled</MenuItem>
                    </TextField>
                    <TextField
                        select
                        label="Confirmation on Job Application Links"
                        name="JobApplicationConfirmation"
                        value={state.JobApplicationConfirmation}
                        onChange={handleChanges}
                    >
                        <MenuItem value="True">Enabled</MenuItem>
                        <MenuItem value="False">Disabled</MenuItem>
                    </TextField>
                    <TextField
                        select
                        label="Application Notifications Mode"
                        name="ApplicationAlertsMode"
                        value={state.ApplicationAlertsMode}
                        onChange={handleChanges}
                    >
                        <MenuItem value="0">Prevent Duplicate Emails</MenuItem>
                        <MenuItem value="1">Send Email for EVERY Application</MenuItem>
                    </TextField>
                    <RWTextFieldComponent
                        label="Application Form Url Override"
                        name="AdvertsApplicationFormUrl"
                        value={state.AdvertsApplicationFormUrl}
                        onChange={handleChanges}
                    />
                    <TextField
                        select
                        label="Optional Resume"
                        name="AdvertsOptionalResume"
                        value={state.AdvertsOptionalResume}
                        onChange={handleChanges}
                    >
                        <MenuItem value="True">Enabled</MenuItem>
                        <MenuItem value="False">Disabled</MenuItem>
                    </TextField>
                    <TextField
                        select
                        label="Adverts EAP"
                        name="AdvertsEAP"
                        value={state.AdvertsEAP}
                        onChange={handleChanges}
                    >
                        <MenuItem value="True">Enabled</MenuItem>
                        <MenuItem value="False">Disabled</MenuItem>
                    </TextField>
                </Stack>
            </PageContentLayout>
        </>
    );
}