import React, { useCallback, useEffect, useMemo, useState } from "react";
import PageContentLayout from "layouts/PageContentLayout";
import { GetComplianceItems } from "services/ComplianceService";
import { ComplianceItem } from "common/models/Configuration/Compliance";
import { GridColDef, GridRenderCellParams, useGridApiRef } from "@mui/x-data-grid-premium";
import GridWithStateWrapper from "components/GridWidthStateWrapper";
import CreateComplianceItemDialog from "components/Dialogs/Compliance/CreateComplianceItemDialog";
import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import Alert from "components/Alert";
import EditComplianceItemDialog from "components/Dialogs/Compliance/EditComplianceItemDialog";
import { GetComplianceItemScopeName, GetComplianceItemStatusName, GetComplianceItemTypeName } from "util/Compliance";

interface Props {
    setSummaryBar?: (summaryBar: JSX.Element) => void,
}

export default function ComplianceItemsConfigPage({ setSummaryBar }: Props) {
    const [rows, setRows] = useState<ComplianceItem[]>([]);
    const [selectedRow, setSelectedRow] = useState<ComplianceItem | null>(null);
    const [showCreateDialog, setShowCreateDialog] = useState(false);
    const [isloading, setIsLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const gridApiRef = useGridApiRef();

    const getRowsDataCallback = useCallback(async () => {
        setIsLoading(true);
        const res = await GetComplianceItems(setErrorMessage);
        if (res) setRows(res);
        setIsLoading(false);
    }, []);

    useEffect(() => {
        getRowsDataCallback();
    }, [getRowsDataCallback]);
    
    useEffect(() => {
        const action = <Button variant="contained" color="primary" onClick={() => setShowCreateDialog(true)}>Add</Button>
        const summaryBar = (
            <TitleAndActionSummaryBar
                title={'Configuration > Compliance > Items'}
                browserTabTitle="Compliance > Configuration"
                action={action}
            />
        );
        setSummaryBar && setSummaryBar(summaryBar);
    }, [setSummaryBar]);

    const columns = useMemo<GridColDef[]>(() => {

        const editActionRenderer = (params: GridRenderCellParams) => {
            return (
            <span
                style={{ cursor: 'pointer', textDecoration: 'underline' }}
                onClick={ () => setSelectedRow(params.row) }
            >
                {params.value}
            </span>
            );
        };

        const statusRenderer = (params: GridRenderCellParams) => {
            return GetComplianceItemStatusName(params.row.statusID);
        };
        
        const scopeRenderer = (params: GridRenderCellParams) => {
            return GetComplianceItemScopeName(params.row.scopeID);
        };
        
        const typeRenderer = (params: GridRenderCellParams) => {
            return GetComplianceItemTypeName(params.row.typeID);
        };

        return [
            { field: 'id', headerName: 'ID', renderCell: editActionRenderer},
            { field: 'name', headerName: 'Name', width: 200, renderCell: editActionRenderer },
            { field: 'statusID', headerName: 'Status', width: 200, renderCell: statusRenderer },
            { field: 'scopeID', headerName: 'Scope', width: 200, renderCell: scopeRenderer },
            { field: 'typeID', headerName: 'Type', width: 200, renderCell: typeRenderer },
        ];
    }, []);

    const creationSuccessHandler = useCallback((message: string) => {
        setSuccessMessage(message);
        getRowsDataCallback();
    }, [getRowsDataCallback]);

    const editSuccessHandler = useCallback((message: string, itemId: number, name: string, status: number, scope: number, type: number) => {
        setSuccessMessage(message);
        setRows(prev => {
            let tmp = [...prev];
            const i = tmp.findIndex(v => v.id === itemId);
            if (i >= 0) {
                tmp[i].name = name;
                tmp[i].statusID = status;
                tmp[i].scopeID = scope;
                tmp[i].typeID = type;
            }
            return tmp;
        });
    }, []);

    return (
        <>
            <Snackbar open={successMessage !== ''} autoHideDuration={3000} onClose={() => setSuccessMessage('')}>
                <Alert onClose={() => setSuccessMessage('')}>{ successMessage }</Alert>
            </Snackbar>
            <Snackbar open={errorMessage !== ''}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <CreateComplianceItemDialog
                open={showCreateDialog}
                closeHandler={() => setShowCreateDialog(false)}
                loadingHandler={setIsLoading}
                successHandler={creationSuccessHandler}
                errorHandler={setErrorMessage}
            />
            <EditComplianceItemDialog
                open={Boolean(selectedRow)}
                itemId={selectedRow ? selectedRow.id : 0}
                name={selectedRow ? selectedRow.name : ''}
                status={selectedRow ? selectedRow.statusID : 0}
                scope={selectedRow ? selectedRow.scopeID : 1}
                type={selectedRow ? selectedRow.typeID : 1}
                closeHandler={() => setSelectedRow(null)}
                loadingHandler={setIsLoading}
                successHandler={editSuccessHandler}
                errorHandler={setErrorMessage}
            />
            <PageContentLayout showLoading={isloading}>
                <GridWithStateWrapper
                    gridName="configuration/compliance/items"
                    rows={rows}
                    columns={columns}
                    disableRowSelectionOnClick
                    density="compact"
                    apiRef={gridApiRef}
                />
            </PageContentLayout>
        </>
    );
}