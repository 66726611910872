import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Navigate } from "react-router-dom";
import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import { Candidate, CandidateRefCheckData, QuestionField, QuestionFieldCustom } from "common/models/Candidates/Candidate";
import { Job } from "common/models/Jobs/Job";
import Avatar from "@mui/material/Avatar";
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import { AllowCandidatesAddEdit } from "common/data/Permissions/CandidatesAccess";
import Button from "@mui/material/Button";
import { PREVIEW_CANDIDATE_RENDERER, PREVIEW_JOB_RENDERER } from "util/Definitions/Constants/Previews";
import { GetCandidateById, ReferenceCheckCandidate } from "services/CandidatesService";
import { GetJobById } from "services/JobsService";
import Stack from "@mui/material/Stack";
import ReferenceCheckTemplatePicker from "components/Pickers/ReferenceCheckTemplatePicker";
import { GetMyUser, GetSettingBySettingName } from "services/UsersService";
import { ReferenceCheckTemplate } from "common/models/Templates/ReferenceCheckTemplate";
import { GetReferenceCheckTemplateById } from "services/TemplatesService";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import CandidateResumeRenderer from "components/Candidates/CandidateResumeRenderer";
import TagsManagementDialog from "components/Dialogs/TagsManagementDialog";
import ContactPicker from "components/Pickers/ContactPicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import UserPicker from "components/Pickers/UserPicker";
import { GetActivityAttributesById } from "services/ActivitiesService";
import moment from "moment";
import ActionMenu from "components/Menus/ActionMenu";
import { MenuOptionDefinition } from "common/models/MenuDefinition";
import { GenerateScreeningRefCheckDocument } from "services/DocumentGenerationService";
import { GetReferenceCheckRequestByActivityId } from "services/ReferenceCheckService";
import Rating, { IconContainerProps } from "@mui/material/Rating";
import { Autocomplete, Chip, Divider, MenuItem, Typography, styled } from "@mui/material";
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import Looks3Icon from '@mui/icons-material/Looks3';
import Looks4Icon from '@mui/icons-material/Looks4';
import Looks5Icon from '@mui/icons-material/Looks5';

interface Props {
    candidateId?: number,
    jobId?: number,
    activityId?: number,
    setSummaryBar?: (sb: JSX.Element) => void,
    loadingHandler?: (isLoading: boolean) => void,
    successHandler?: (message: string) => void,
    errorHandler?: (message: string) => void,
}

interface QuestionnaireFields {
    order: number,
    name: string,
    type: string,
    mandatory: boolean,
    options: QuestionFieldCustom[]
}

const renderSummary = (c: Candidate, j?: Job) => {

    const candidateName = <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>Reference Check for {PREVIEW_CANDIDATE_RENDERER(c.id, c.fullName)}</span>;
    let jobData = <span></span>;
    if (j) jobData = <span>&nbsp;for {PREVIEW_JOB_RENDERER(j.id, j.title)}</span>;
    return <>{candidateName}{jobData}</>;
};

export default function ReferenceCheckEditPageContent({ candidateId: candidateIdProp = 0, jobId = 0, activityId = 0, setSummaryBar, loadingHandler, successHandler, errorHandler }: Props) {
    const [candidate, setCandidate] = useState<Candidate>();
    const [job, setJob] = useState<Job>();
    const [selectedTemplate, setSelectedTemplate] = useState<ReferenceCheckTemplate | null>(null);
    const [showTagsManagement, setShowTagsManagement] = useState(false);
    const [showValidation, setShowValidation] = useState(false);
    const [isCvExpanded, setIsCvExpanded] = useState(true);
    const [savedChanges, setSavedChanges] = useState(false);
    const [fields, setFields] = useState<QuestionField[]>([]);
    const [refereeId, setRefereeId] = useState(0);
    const [followUpDate, setFollowUpDate] = useState<moment.Moment | null>(null);
    const [followUpUser, setFollowUpUser] = useState(0);
    const [myUserId, setMyUserId] = useState(0);
    const [refCheckId, setRefCheckId] = useState(0);
    const StyledRating = styled(Rating)(({ theme }) => ({
        '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
          color: theme.palette.action.disabled,
        },
      }));

      const customIcons: {
        [index: string]: {
          icon: React.ReactElement;
        };
      } = {
        1: {
          icon: <LooksOneIcon color="error" sx={{fontSize: 50}}/>
        },
        2: {
          icon: <LooksTwoIcon color="error" sx={{fontSize: 50}}/>
        },
        3: {
          icon: <Looks3Icon color="warning" sx={{fontSize: 50}}/>

        },
        4: {
          icon: <Looks4Icon color="success" sx={{fontSize: 50}}/>
        },
        5: {
          icon: <Looks5Icon color="success" sx={{fontSize: 50}}/>
        },
      };

      function IconContainer(props: IconContainerProps) {
        const { value, ...other } = props;
        return <span {...other}>{customIcons[value].icon}</span>;
      }

    useEffect(() => {
        const getUserData = async () => {
            const res = await GetMyUser();
            if (res) setFollowUpUser(res.userID);
        };
        getUserData();
    }, []);

    const candidateIdArray = useMemo(() => {
        if (candidate) return [candidate.id];
        return [];
    }, [candidate])

    useEffect(() => {
        const getData = async () => {
            loadingHandler && loadingHandler(true);
            if (candidateIdProp) {
                const res = await GetCandidateById(candidateIdProp);
                if (res) setCandidate(res);
            }

            if (jobId) {
                const res = await GetJobById(jobId);
                if (res) setJob(res);
            }

            const me = await GetMyUser();
            if (me) setMyUserId(me.userID);

            const d = await GetSettingBySettingName('DefaultReferenceCheckTemplateID');
            if (d) {
                const res = await GetReferenceCheckTemplateById(+d);
                if (res) {
                    setSelectedTemplate(res);
                    if (res.json) {
                        const f = JSON.parse(res.json) as QuestionnaireFields[];
                        setFields(f.map(q => ({ ...q, value: '',templateId: res?.documentTemplateId ?? 0, options: q.options?.map(o => ({Text: o.Text, value: ''})) ?? [] }) ));
                    }
                }
            }

            loadingHandler && loadingHandler(false);
        };
        candidateIdProp > 0 && getData();
    }, [candidateIdProp, jobId, loadingHandler]);

    useEffect(() => {
        const getEditData = async () => {
            loadingHandler && loadingHandler(true);
            if (activityId) {
                const res = await GetReferenceCheckRequestByActivityId(activityId);
                if(res) {
                    setRefCheckId(res?.id);
                    if(res.followUpDate) {
                        setFollowUpDate(moment(res.followUpDate));
                    }
                        
                }

                if(res?.templateId) {
                    const templateRes = await GetReferenceCheckTemplateById(+res?.templateId);
                    setSelectedTemplate(templateRes);
                }
                
                if (res) {
                    const atts = await GetActivityAttributesById(activityId);
                    if (atts) {
                        const candidateAttribute = atts.find(a => a.entityID === 3);
                        if (candidateAttribute) {
                            const c = await GetCandidateById(res.candidateId);
                            if (c) setCandidate(c);
                        }
                        if (res.refereeContactId) setRefereeId(res.refereeContactId);
                    }
                    if (res.json) {
                        const f = JSON.parse(res.json) as QuestionField[];
                        setFields(f);
                    }
                }
            }
            loadingHandler && loadingHandler(false);
        };

        activityId > 0 && getEditData();
    }, [activityId, loadingHandler]);

    const canAddEditCandidates = useMemo(() => AllowCandidatesAddEdit(), []);

    const ReplacePlaceholderWithCandidateName = useCallback((question: string) => {
        if(question.indexOf('#{ReferenceCheck.Candidate.FullName}') !== -1) {
            question = question.replace('#{ReferenceCheck.Candidate.FullName}',candidate?.fullName ?? '');
        }


        if(question.indexOf('#{ReferenceCheck.Candidate.FirstName}') !== -1) {
            question = question.replace('#{ReferenceCheck.Candidate.FirstName}',candidate?.firstName ?? '');
        }
    

        if(question.indexOf('#{ReferenceCheck.Candidate.LastName}') !== -1) {
            question = question.replace('#{ReferenceCheck.Candidate.LastName}',candidate?.firstName ?? '');
        }
        return question;
    }, [candidate]);

    const saveRefCheck = useCallback(async () => {
        
        setShowValidation(true);

        if(!selectedTemplate) {
            errorHandler && errorHandler(`Template is required and can't be empty`)
            return;
        }

        for (let i = 0; i < fields.length; i++) {
            const f = fields[i];
            if (f.mandatory && !Boolean(f.value) && f.type !== '4') {
                errorHandler && errorHandler("Required fields can't be empty");
                return false;
            }

            if(f.type === '4' && f.mandatory) {
                const requiredValue = f.options?.find(o=>o.value === "");
                if(!!requiredValue) {
                    errorHandler && errorHandler(`${ReplacePlaceholderWithCandidateName(f.name)} - ${requiredValue.Text} is required.`);
                    return false;
                }
            }
        }

        let formattedDate: string | undefined = undefined;

        if (followUpDate && followUpDate.isValid()) {
            const formatted = followUpDate.format('YYYY-MM-DDTHH:mm');
            const now = moment().format('YYYY-MM-DDTHH:mm');
            if (now > formatted) {
                errorHandler && errorHandler('Follow Up Date must be in the future')
                return;
            }
            formattedDate = formatted;
        }

        const data: CandidateRefCheckData = {
            candidateId: candidate ? candidate.id : 0,
            activityId: activityId ?? 0,
            jobId: jobId ?? 0,
            fields: fields,
            refereeId: refereeId,
            followUpDate: formattedDate,
            followUpUser: followUpUser,
            templateId: selectedTemplate.id,
            refCheckId: refCheckId
        };

        loadingHandler && loadingHandler(true);
        const res = await ReferenceCheckCandidate(data, errorHandler);
        // if(res) {
        //     activityId = res.value;
        // }
        loadingHandler && loadingHandler(false);
        return res;
        
    },[selectedTemplate, followUpDate, candidate, activityId, jobId, fields, refereeId, followUpUser, refCheckId, loadingHandler, errorHandler, ReplacePlaceholderWithCandidateName]);

    const submitHandler = useCallback(async () => {
        const res = await saveRefCheck();
        if (res) {
            setSavedChanges(true);
            return true;
        }

        return false;

    }, [saveRefCheck]);

    const generateDocument = useCallback(async (isPdf: boolean) => {
        loadingHandler && loadingHandler(true);
        var result = await saveRefCheck();
        if(result) {
            await GenerateScreeningRefCheckDocument(13, activityId > 0 ? activityId : result.value, fields[0]?.templateId ?? 0, [{ placeholder: '', value: '' }], errorHandler,true,isPdf);
        }

        loadingHandler && loadingHandler(false);
}, [loadingHandler, saveRefCheck, activityId, fields, errorHandler]);

    const actionMenuDefinitions = useMemo<MenuOptionDefinition[]>(() => {
        return [
            { label: 'Download - PDF', type: 'action', action: () => generateDocument(true) },
            { label: 'Download - Docx', type: 'action', action: () => generateDocument(false) },
        ];
    }, [generateDocument]);

    useEffect(() => {
        if (candidate && setSummaryBar) {

            const tagsAction = (
                <Avatar
                    key="tagsAction"
                    onClick={canAddEditCandidates ? () => setShowTagsManagement(true) : undefined}
                    sx={{ bgcolor: '#f209a6', mr: '5px', cursor: 'pointer', height: '36.5px', width: '36.5px' }}>
                    { candidate.tags ? <LocalOfferIcon /> : <LocalOfferOutlinedIcon /> }
                </Avatar>
            );
            const actionMenu = (
                <Box key="actionsMenu">
                    <ActionMenu definition={actionMenuDefinitions} mr="5px" />
                </Box>
            );
            const submitButton = <Button key="submitAction" variant="contained" color="success" onClick={submitHandler} sx={{ mr: '5px' }}>Save</Button>;

            const actions = <>{tagsAction}{actionMenu}{submitButton}</>

            setSummaryBar (
                <TitleAndActionSummaryBar
                    title={candidate ? <>{renderSummary(candidate, job)}</> : ''}
                    action={actions}
                    browserTabTitle={!candidate ? '' : candidate.fullName + " - Candidates"}
                />
            );
        }
    }, [candidate, refereeId, job, canAddEditCandidates, fields, followUpDate, selectedTemplate, setSummaryBar, candidateIdProp, actionMenuDefinitions, submitHandler]);

    const templateChangedHandler = useCallback((t: ReferenceCheckTemplate | null) => {
        setSelectedTemplate(t);
        if (t && t.json) {
            const f = JSON.parse(t.json) as QuestionnaireFields[];
            setFields(f.map(q => ({ ...q, value: '',templateId: t?.documentTemplateId ?? 0, options: q.options?.map(o => ({Text: o.Text, value: ''})) ?? [] }) ));
        }
        else setFields([]);
    }, []);

    const answerChangeHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { value, name } = e.target;
        setFields(prev => {
            let tmp = [...prev];
            tmp[+name].value = value;
            return tmp;
        });
    }, []);

    const ratingChangeHandler = useCallback((newValue: string,indexOfChild: string,indexOfParent: string) => {
        setFields(prev => {
            let tmp = [...prev];

            if(tmp != null) {
                const parent = {...tmp[+indexOfParent]}
                if(parent != null && parent.options != null) {
                    const newOptions = [...parent.options];
                    newOptions[+indexOfChild] = {...newOptions, value: newValue, Text: newOptions[+indexOfChild].Text}
                    tmp[+indexOfParent] = { ...parent, options: newOptions };
                    return tmp;
                }
            }
            return tmp;
        });

    }, []);    

    const redirectUrl = useMemo(() => {
        if (savedChanges && candidate) {
            if (jobId) return `/candidates/${candidate.id}?jobId=${jobId}`;
            return `/candidates/${candidate.id}`;
        }
        return '';
    }, [candidate, jobId, savedChanges]);

    return (
        <>
            {savedChanges && redirectUrl && <Navigate to={redirectUrl} />}
            <TagsManagementDialog
                open={showTagsManagement}
                entityId={3}
                recordIds={candidateIdArray}
                closeHandler={ () => setShowTagsManagement(false) }
                loadingHandler={ loadingHandler }
                errorHandler={ errorHandler }
                successHandler={ successHandler }
            />

            <Box display="flex" height="100%">
                <Stack spacing={2} pr="10px" width={isCvExpanded ? '50%' : 'calc(100% - 20px)'} sx={{ transition: '1s ease' }}>
                        <ContactPicker value={refereeId} onSelectCallback={c => {setRefereeId(c ? c.id : 0)}} label="Referee" />
                        <DateTimePicker label="Follow up Date" disablePast value={followUpDate} onChange={setFollowUpDate}
                                        slotProps={{
                                            actionBar: { actions: ["clear", "today", "cancel", "accept"] },
                                        }}/>
                        <UserPicker label="Follow up Assigned To" onSelect={u => setFollowUpUser(u ? u.id : 0)} userId={followUpUser} />
                    {candidateIdProp > 0 &&
                        <ReferenceCheckTemplatePicker
                            userId={myUserId}
                            templateId={selectedTemplate ? selectedTemplate.id : 0}
                            label="Template"
                            onSelectCallback={templateChangedHandler}
                        />
                    }
                    {fields.map((f, i) =>  {
                        if(f.type === '0' || f.type === '1') {
                            return (
                                <TextField
                                    key={i}
                                    required={f.mandatory}
                                    error={showValidation && f.mandatory && !Boolean(f.value)}
                                    value={f.value}
                                    name={i.toString()}
                                    label={ReplacePlaceholderWithCandidateName(f.name)}
                                    multiline={f.type === '1'}
                                    rows={f.type === '1' ? 6 : undefined}
                                    onChange={ answerChangeHandler }
                                />
                        )}

                        if(f.type === '2') {
                            return <TextField select 
                                              label={ReplacePlaceholderWithCandidateName(f.name)} 
                                              name={i.toString()} 
                                              value={f.value} 
                                              onChange={ answerChangeHandler }
                                              required={f.mandatory}
                                              error={showValidation && f.mandatory && !Boolean(f.value)}
                                              key={i}>
                            {f.options?.map((c,iii) =>{
                                 return <MenuItem key={iii +"_menuItem"} value={c.Text}>{c.Text}</MenuItem>
                            })}
                        </TextField>;
                        }

                        if(f.type === '3') {
                            let selectedOptions: QuestionFieldCustom[] = [];
                            if(f.value !== "") {
                                const selectedValues = f.value.split(',').map(item => item.trim());
                                selectedValues.forEach(v=> {
                                    if(f.options != null) {
                                        const selectedOption = f.options.find(o=>o.Text === v);
                                        if(selectedOption) {
                                            selectedOptions.push(selectedOption)
                                        }
                                        
                                    }
                                    
                                })
                            }

                            return  <Autocomplete
                            key={i + "_autocomplete"}
                            multiple
                            id="tags-standard"
                            onChange={(event, newInputValue) => {
                                f.value = "";
                                newInputValue.forEach(i => {
                                    f.value += i.Text +",";
                                });
                                if (f.value.endsWith(",")) { 
                                    f.value = f.value.slice(0, -1);
                                  }
                              }}
                              defaultValue={selectedOptions}
                            options={f?.options ?? []}
                            getOptionLabel={(option) => option.Text}
                            renderTags={(value, getTagProps) =>
                              value.map((option, index) => {
                                const { key, Text, ...restProps } = { ...getTagProps({ index }), ...option };
                                return <Chip {...restProps} key={key} label={Text} />;
                              })
                            }
                            renderInput={(params) => (
                              <TextField
                                key={i + params.id + "_autocomplete_value"}
                                {...params}
                                variant="outlined"
                                label={ReplacePlaceholderWithCandidateName(f.name)}
                              />
                            )}
                          />
                        }

                        if(f.type === '4') {
                            return (
                                <div key={i + "_options_component"}>
                                <Typography key={i +"_optionName"} variant="h6">{ReplacePlaceholderWithCandidateName(f.name)}</Typography>
                                {f.options?.map((c, ii) => {
                                return <div key={ii+'_customField'}>
                                <Box sx={{ width: '100%', display: 'flex', alignItems: 'center',}}>
                                    <Box sx={{width:'70%'}}>{c.Text}</Box>
                                    <Button variant={c.value === "-1" ? "contained" : "outlined"} sx={{width: '38px', height: '38px'}} onClick={() => {ratingChangeHandler("-1",ii.toString(),i.toString());}}>N/A</Button>
                                    <StyledRating
                                        name={ii.toString()} value={+c.value ?? 0}
                                        onChange={(event, newValue) => {ratingChangeHandler(newValue?.toString() ?? "0",ii.toString(),i.toString());}}
                                        IconContainerComponent={IconContainer}
                                        highlightSelectedOnly/>
                                </Box><Divider/></div>
                            })}
                                </div>
                                );
                        }
                        return <React.Fragment key={i} />
                 })}
                </Stack>
                <Box height="100%" width={isCvExpanded ? '50%' : '20px'} bgcolor="background.default" sx={{ transition: '1s ease' }} >
                    <Box component="span" border="none" height="100%" width="10px" fontWeight="bold" fontSize="large" sx={{ cursor: 'e-resize', float: 'left' }} onClick={ () => setIsCvExpanded(prev => !prev) } />
                    <Box py="5px" height="100%" display={isCvExpanded ? 'flex' : 'none'}>
                        <CandidateResumeRenderer candidateId={candidate ? candidate.id : 0} loadingHandler={loadingHandler} />
                    </Box>
                </Box>
            </Box>
        </>
    );
}