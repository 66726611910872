import IconButton from "@mui/material/IconButton";
import { useGridApiRef } from "@mui/x-data-grid-premium/hooks/utils/useGridApiRef";
import { GridColDef } from "@mui/x-data-grid/models/colDef/gridColDef";
// import { GridRenderCellParams, GridValueGetter } from "@mui/x-data-grid/models/params/gridCellParams";
import DownloadIcon from '@mui/icons-material/Download';
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { AdvertApplication } from "common/models/Advert";
import { PreviewEntityType } from "common/models/Previews/Previews";
import { DownloadApplicationDocument, GetApplicationsByAdvertId } from "services/AdvertsService";
import GridWithStateWrapper from "../GridWidthStateWrapper";
import PreviewLoaderComponent from "../Previews/PreviewLoader";
import { GridRenderCellParams, GridValueGetter } from "@mui/x-data-grid-premium";
import { defaultGridCellStyle } from "util/GridUtils";

interface Props {
    advertId: number,
    gridName: string,
    loadingHandler?: (isLoading: boolean) => void,
    errorHander?: (message: string) => void
}

const linkStyle: React.CSSProperties = { color: 'inherit', textDecoration: 'underline' };

const getApplicationStatusName = (statusId: number) => {
    switch (statusId)
    {
        case 0: return "Application Received - Awaiting CV";
        case 1: return "Application Received - CV";
        case 2: return "Application Received - iProfile";
        case 3: return "Processing";
        case 4: return "Application Queued";
        case 8: return "Business Error";
        case 9: return "System Error";
        case 10: return "Processing Complete";
        case 11: return "Errored";
    }
    return "Unknown";
}

export default function AdvertApplicantsGridComponent({ advertId, gridName, loadingHandler, errorHander }: Props) {
    const [rows, setRows] = useState<AdvertApplication[]>([]);
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    const [previewType, setPreviewType] = useState<PreviewEntityType | ''>('');
    const [previewRecordId, setPreviewRecordId] = useState(0);
    const apiRef = useGridApiRef();

    useEffect(() => {
        const getData = async () => {
            loadingHandler && loadingHandler(true);
            const res = await GetApplicationsByAdvertId(advertId, errorHander);
            if (res) setRows(res);
            loadingHandler && loadingHandler(false);
        };
        
        getData();
    }, [advertId, loadingHandler, errorHander]);

    const downloadApplicationDocumentCallback = useCallback(async (applicationId: number, documentType: 'cv' | 'cover-letter') => {
        loadingHandler && loadingHandler(true);
        await DownloadApplicationDocument(applicationId, documentType, undefined, errorHander);
        loadingHandler && loadingHandler(false);
    }, [loadingHandler, errorHander]);

    const columns = useMemo<GridColDef[]>(() => {
        const handlePreviewHover = (type: PreviewEntityType | '', id: number) => {
            setPreviewType(type);
            setPreviewRecordId(id);
            setIsPreviewOpen(true);
        }

        const handlePreviewClose = () => {
            // setPreviewType('');
            // setPreviewRecordId(0);
            setIsPreviewOpen(false);
        }

        const dateValueGetter: GridValueGetter<AdvertApplication, any, undefined, string> = (value, row) => {
            if (value) {
                const d = moment(value);
                if (d.isValid() && d.get("year") > 1) {
                    return d.toDate();
                }
            }
            return null;
        };

        const dateRenderer = (params: GridRenderCellParams) => {
            if (params.value) {
                return (
                    <div style={defaultGridCellStyle}>
                        {moment(params.value).format('DD MMM YYYY')}
                    </div>
                );
            }
        };

        const minHeightCellRenderer = (params: GridRenderCellParams) => {
            return (
                <div style={defaultGridCellStyle}>
                    <span>{params.value}</span>
                </div>
            );
        };

        const nameRenderer = (params: GridRenderCellParams) => {
            const candidateId = params.row.candidateID;
            return (
                <div style={defaultGridCellStyle}>
                    <Link to={`/candidates/${candidateId}?jobId=${params.row.application_jobid}`} style={ linkStyle } target="_blank" onMouseEnter={ () => handlePreviewHover('candidate', candidateId) } onMouseLeave={ handlePreviewClose } >{params.value + ' ' + params.row.application_lastname}</Link>
                </div>
            );
        };

        const emailRenderer = (params: GridRenderCellParams) => {
            return (
                <div style={defaultGridCellStyle}>
                    <a href={`mailto:${params.value}`} style={ linkStyle } >{params.value}</a>
                </div>
            );
        };

        const phoneRenderer = (params: GridRenderCellParams) => {
            return (
                <div style={defaultGridCellStyle}>
                    <a href={`tel:${params.value}`} style={ linkStyle } >{params.value}</a>
                </div>
            );
        };

        const statusRenderer = (params: GridRenderCellParams) => {
            return <div style={defaultGridCellStyle}>{getApplicationStatusName(+params.value)}</div>;
        };

        const cvRenderer = (params: GridRenderCellParams) => {
            if (params.value) {
                return <IconButton onClick={() => downloadApplicationDocumentCallback(+params.id, 'cv')}><DownloadIcon /></IconButton>
            }
        };

        const coverLetterRenderer = (params: GridRenderCellParams) => {
            if (params.value) {
                return <IconButton onClick={() => downloadApplicationDocumentCallback(+params.id, 'cover-letter')}><DownloadIcon /></IconButton>
            }
            return '';
        };
        
        return [
            { headerName: 'Application ID', field: 'id', headerAlign: 'center', align: 'center', width: 150, renderCell: minHeightCellRenderer },
            { headerName: 'Date Applied', field: 'receivedDate', headerAlign: 'center', align: 'center', width: 125, renderCell: dateRenderer, type: 'date', valueGetter: dateValueGetter },
            { headerName: 'Name', field: 'application_firstname', headerAlign: 'center', align: 'center', width: 250, renderCell: nameRenderer },
            { headerName: 'Email', field: 'application_emailaddress', headerAlign: 'center', align: 'center', width: 250, renderCell: emailRenderer },
            { headerName: 'Phone', field: 'application_phonenumber', headerAlign: 'center', align: 'center', width: 150, renderCell: phoneRenderer },
            { headerName: 'Status', field: 'statusID', headerAlign: 'center', align: 'center', width: 175, renderCell: statusRenderer },
            { headerName: 'Source', field: 'jobBoardName', headerAlign: 'center', align: 'center', width: 125, renderCell: minHeightCellRenderer },
            { headerName: 'CV', field: 'hasCV', headerAlign: 'center', align: 'center', width: 50, renderCell: cvRenderer },
            { headerName: 'Cover Letter', field: 'hasCoverLetter', headerAlign: 'center', align: 'center', width: 100, renderCell: coverLetterRenderer }
        ];
    }, [downloadApplicationDocumentCallback]);

    return (
        <>
            <PreviewLoaderComponent
                open={isPreviewOpen}
                entityType={previewType}
                recordId={previewRecordId}
            />
            <GridWithStateWrapper
                gridName={gridName}
                rows={rows}
                columns={columns}
                apiRef={apiRef}
                disableRowSelectionOnClick
                density="compact"
                pagination
                pageSizeOptions={[100, 250, 500, 1000]}
                getRowHeight={() => 'auto'}
            />
        </>
    );
}