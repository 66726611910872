import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import * as tinymce from 'tinymce';
import { DefaultPlacementRecordDashboardElements_Contract, DefaultPlacementRecordDashboardElements_FixedTermContract, DefaultPlacementRecordDashboardElements_MaxTermContract, DefaultPlacementRecordDashboardElements_MarginOnlyContract, DefaultPlacementRecordDashboardElements_Permanent, GetPanelDefinitionsFromPanelModels, PlacementScreenLayoutSettings } from "util/Definitions/ScreenLayouts/Placement";
import { PanelModel } from "@syncfusion/ej2-layouts/src/dashboard-layout/dashboard-layout-model";
import { MinPlacement, Placement } from "common/models/Placements";
import { CustomField, CustomFieldPredefinedValue } from "common/models/Configuration/CustomFields";
import useObjectStateWithChangeTracker from "hooks/UseObjectStateWithChangeTracker";
import { DashboardLayoutComponent } from "@syncfusion/ej2-react-layouts/src/dashboard-layout/dashboardlayout.component";
import { useTheme } from "@mui/material/styles";
import { DefaultMinPlacement, DefaultMinPlacementNoChanges, PlacementToMinPlacement } from "util/Definitions/Placement";
import { CreatePlacement, GetPlacementById, UpdatePlacement } from "services/PlacementsService";
import { GetCustomFieldsByEntity_OnlyActive, GetPredefinedValues } from "services/CustomFieldsService";
import { GetCustomerSettingBySettingName } from "services/ConfigurationService";
import Button from "@mui/material/Button";
import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { Site } from "common/models/Site";
import { Client } from "common/models/Clients";
import { Contact } from "common/models/Contacts";
import { NameIdObj } from "common/models/GenericTypes";
import useUnsavedChangesDialog from "hooks/UseUnsavedChangesDialog";
import { PlacementRecordDashboardElementDefinition, PlacementRecordDashboardElementType } from "common/models/Dashboard/EditLayout";
import { CustomFieldSettingsMap, CustomFieldType } from "common/models/ScreenLayouts/CustomFields";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import PanelWrapper from "components/Dashboards/PanelWrapper";
import { IsValidCustomFieldValue, UdfPlacementFieldMapObj } from "util/Definitions/ScreenLayouts/CustomFields";
import EditableSingleFieldElement from "../EditableSingleFieldElement";
import MenuItem from "@mui/material/MenuItem";
import SingleFieldElement from "../SingleFieldElement";
import EditableClientLocationElement from "../Clients/EditableClientLocationElement";
import EditableRichTextElement from "../EditableRichTextElement";
import { CloseJobs, GetJobById } from "services/JobsService";
import DivisionPicker from "components/Pickers/DivisionPicker";
import CurrencyPicker from "components/Pickers/CurrencyPicker";
import TextField from "@mui/material/TextField";
import ClientPicker from "components/Pickers/ClientPicker";
import ContactPicker from "components/Pickers/ContactPicker";
import UserPicker from "components/Pickers/UserPicker";
import CandidatePicker from "components/Pickers/CandidatePicker";
import { CandidateList } from "common/models/Candidates/CandidateList";
import { PlacementStatusEnum } from "util/Definitions/Configuration/Placements";
import InputAdornment from "@mui/material/InputAdornment";
import { GetClientById } from "services/ClientsService";
import { GetMyUser } from "services/UsersService";
import moment from "moment";
import { GetCandidateById, GetCandidateJob } from "services/CandidatesService";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import ConfirmationDialog from "components/Dialogs/Generic/ConfirmationDialog";
import ComplianceChecklistPicker from "components/Pickers/ComplianceChecklistPicker";
import { ComplianceChecklist } from "common/models/Configuration/Compliance";
import { RegexIsPositiveNumberWith2Decimals } from "util/RegExUtils";
import RWTextFieldComponent from "components/RWTextFieldComponent";

interface Props {
    placementId?: number,
    copyPlacementId?: number,
    jobId?: number,
    placementTypeId?: number,
    candidateIds?: number[],
    netRevenueLabel?: string,
    defaultPayFreq?: number,
    defaultPaymentType?: number,
    defaultPayBillUnits?: number,
    defaultNoticePeriodUnits?: number,
    defaultWarrantyPeriod?: number,
    isOptionalEndDate?: boolean,
    isSeperatePayBillDivision?: boolean,
    isPlacementCandidateConfirmation?: boolean,
    ratesCalculatorLink?: string,
    onCostsPercentage?: number,
    predefinedSources?: string[],
    defaultSummary?: string,
    setSummaryBar?: (sb: JSX.Element) => void,
    loadingHandler?: (isLoading: boolean) => void,
    successHandler?: (message: string) => void,
    errorHandler?: (message: string) => void,
}

interface CustomFieldWithPredefinedValues extends CustomField {
    values?: CustomFieldPredefinedValue[],
}

type CustomFieldValidateMap = Record<string, boolean>;

const validationErrorMessage = (validationResult: string) => {
    if (validationResult === 'ok') return '';
    if (validationResult === 'required') return 'Required';
    return 'Not Valid';
};

const daysPerWeekValidator = (value: string) => {
    return value === '' || (RegexIsPositiveNumberWith2Decimals(value) && +value <= 7);
};

const { unitWidth, unitHeight, gapX, gapY, columns, mediaQueryMaxWidth } = PlacementScreenLayoutSettings;
const cellSpacing = [gapX, gapY];

const formatNumber = (value: number) => {
    return value.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 });
};

const percentAdornment = <InputAdornment position="end">%</InputAdornment>;

const timePeriodOptions = [
    <MenuItem key={0} value="0">Select</MenuItem>,
    <MenuItem key={1} value="1">Per Hour</MenuItem>,
    <MenuItem key={2} value="2">Per Day</MenuItem>,
    <MenuItem key={3} value="3">Per Week</MenuItem>,
    <MenuItem key={4} value="4">Per Month</MenuItem>,
    <MenuItem key={5} value="5">Per Annum</MenuItem>,
];

const paymentTypeOptions = [
    <MenuItem key="0" value="0">None</MenuItem>,
    <MenuItem key="1" value="1">Employee</MenuItem>,
    <MenuItem key="2" value="2">Sub Contractor</MenuItem>,
];

const warrantyAndProbationOptions = [
    <MenuItem key="0" value="0">None</MenuItem>,
    <MenuItem key="1" value="1">1 month</MenuItem>,
    <MenuItem key="2" value="2">2 months</MenuItem>,
    <MenuItem key="3" value="3">3 months</MenuItem>,
    <MenuItem key="4" value="4">4 months</MenuItem>,
    <MenuItem key="5" value="5">5 months</MenuItem>,
    <MenuItem key="6" value="6">6 months</MenuItem>,
    <MenuItem key="7" value="7">7 months</MenuItem>,
    <MenuItem key="8" value="8">8 months</MenuItem>,
    <MenuItem key="9" value="9">9 months</MenuItem>,
    <MenuItem key="10" value="10">10 months</MenuItem>,
    <MenuItem key="11" value="11">11 months</MenuItem>,
    <MenuItem key="12" value="12">12 months</MenuItem>,
];

const noticeOptions = [
    <MenuItem key="0" value="0">None</MenuItem>,
    <MenuItem key="1" value="1">Days</MenuItem>,
    <MenuItem key="2" value="2">Weeks</MenuItem>,
    <MenuItem key="3" value="3">Months</MenuItem>,
];

const payFreqOptions = [
    <MenuItem key="0" value="0">None</MenuItem>,
    <MenuItem key="1" value="1">Weekly</MenuItem>,
    <MenuItem key="5" value="5">Weekly ( 1 Week in Arrears )</MenuItem>,
    <MenuItem key="6" value="6">Weekly ( 3 Weeks in Arrears )</MenuItem>,
    <MenuItem key="2" value="2">Fortnightly</MenuItem>,
    <MenuItem key="3" value="3">Monthly</MenuItem>,
    <MenuItem key="4" value="4">4 Weekly</MenuItem>,
];

const getStatusNameById = (id: number) => {
    switch (id)
    {
        case PlacementStatusEnum.Future: return "Future";
        case PlacementStatusEnum.Warranty: return "Warranty";
        case PlacementStatusEnum.Current: return "Current";
        case PlacementStatusEnum.Completed: return "Completed";
        case PlacementStatusEnum.Deleted: return "Deleted";
        case PlacementStatusEnum.Cancelled: return "Cancelled";
        case PlacementStatusEnum.PendingEarlyTermination: return "Pending Termination";
        case PlacementStatusEnum.EarlyTerminated: return "Terminated";
        default: return "Unknown";
    }
};

const consultantSpecialOptions: NameIdObj[] = [
    { id: 0, name: 'None' }
];

export default function EditRecordScreenLayout({ placementId = 0, copyPlacementId = 0, jobId = 0, placementTypeId = 0, candidateIds, netRevenueLabel = 'Net Revenue', defaultSummary = '',
    defaultPayFreq, defaultPaymentType, defaultPayBillUnits, defaultNoticePeriodUnits, defaultWarrantyPeriod, isOptionalEndDate = false, isSeperatePayBillDivision, isPlacementCandidateConfirmation, ratesCalculatorLink, onCostsPercentage, predefinedSources,
    setSummaryBar,loadingHandler, errorHandler, successHandler }: Props) {

    const [isFetchingConfig, setIsFetchingConfig] = useState(false);
    const [isFetchingPlacement, setIsFetchingPlacement] = useState(false);
    const [fetchedConfig, setFetchedConfig] = useState(false);
    const [fetchedPlacement, setFetchedPlacement] = useState(false);
    const [showValidation, setShowValidation] = useState(false);
    const [showConfirmHoursPerDay, setShowConfirmHoursPerDay] = useState(false);
    const [fetchedLayoutConfig, setFetchedLayoutConfig] = useState(false);
    const [fetchingLayoutConfig, setFetchingLayoutConfig] = useState(false);
    const [layoutConfiguration, setLayoutConfiguration] = useState<PanelModel[] | null>(null);
    const [existingPlacement, setExistingPlacement] = useState<Placement | null>(null);
    // const [sourceJob, setSourceJob] = useState<Job | null>(null);
    const [availableCustomFields, setAvailableCustomFields] = useState<CustomFieldWithPredefinedValues[]>([]);
    const [isEditorDirty, setIsEditorDirty] = useState(false);
    const { state, init, change, updateInitial, hasChanges } = useObjectStateWithChangeTracker<MinPlacement>(DefaultMinPlacement, DefaultMinPlacementNoChanges);
    const editorRef = useRef<tinymce.Editor | null>(null);
    const [savedChanges, setSavedChanges] = useState(false);
    const [createdPlacementId, setCreatedPlacementId] = useState(0);
    const [screenResizedControl, setScreenResizedControl] = useState(false);
    const [invoiceFeeValue, setInvoiceFeeValue] = useState('');
    const [initialInvoiceFeeValue, setInitialInvoiceFeeValue] = useState('');
    const [initialSourceValue, setInitialSourceValue] = useState('');
    const [singleCandidateName, setSingleCandidateName] = useState('');
    const [shouldCloseJob, setShouldCloseJob] = useState(false);
    const [isJobAlreadyClosed, setIsJobAlreadyClosed] = useState(true);
    const [customFieldValidateMap, setCustomFieldValidateMap] = useState<CustomFieldValidateMap>({});
    const [requiresDivisionValidation, setRequiresDivisionValidation] = useState(false);
    const [requiresPaymentTypeValidation, setRequiresPaymentTypeValidation] = useState(false);
    const [requiresPayFreqValidation, setRequiresPayFreqValidation] = useState(false);
    const layoutRef = useRef<DashboardLayoutComponent | null>(null);
    const theme = useTheme();
    const navigate = useNavigate();

    const layoutDataSettingName = useMemo(() => {
        const pType = placementTypeId ? placementTypeId : state.placementTypeID;
        switch (pType) {
            case 1: return 'PlacementEditRecordDashboardLayoutElements_Permanent';
            case 2: return 'PlacementEditRecordDashboardLayoutElements_Contract';
            case 3: return 'PlacementEditRecordDashboardLayoutElements_FixedTermContract';
            case 4: return 'PlacementEditRecordDashboardLayoutElements_MaxTermContract';
            case 5: return 'PlacementEditRecordDashboardLayoutElements_MarginOnlyContract';
        }
        return '';
    }, [placementTypeId, state.placementTypeID]);

    const defaultElements = useMemo(() => {
        const pType = placementTypeId ? placementTypeId : state.placementTypeID;
        switch (pType) {
            case 1: return DefaultPlacementRecordDashboardElements_Permanent;
            case 2: return DefaultPlacementRecordDashboardElements_Contract;
            case 3: return DefaultPlacementRecordDashboardElements_FixedTermContract;
            case 4: return DefaultPlacementRecordDashboardElements_MaxTermContract;
            case 5: return DefaultPlacementRecordDashboardElements_MarginOnlyContract;
        }
        return [];
    }, [placementTypeId, state.placementTypeID]);

    useEffect(() => {
        const getExistingPlacement = async () => {
            setIsFetchingPlacement(true);
            const res = await GetPlacementById(placementId, errorHandler);
            if (res) {
                setExistingPlacement(res);

                if (res.approved) {
                    navigate(`/placements/${res.id}`);
                }

                const fee = res.feeType === 1 ? res.permFeePercentage : res.permFeeAmount;
                setInvoiceFeeValue(fee.toString());
                setInitialInvoiceFeeValue(fee.toString());
                setInitialSourceValue(res.source ?? '');
            }
            setFetchedPlacement(true);
            setIsFetchingPlacement(false);
        };
        placementId && getExistingPlacement();
    }, [placementId, navigate, errorHandler]);

    useEffect(() => {
        const getExistingPlacement = async () => {
            setIsFetchingPlacement(true);
            const res = await GetPlacementById(copyPlacementId, errorHandler);
            if (res) {
                let tmp: Placement = {...res};
                tmp.placementStatusID = 4;
                tmp.placementStatusName = "Future";
                tmp.placementDate = moment().format('YYYY-MM-DD')
                setExistingPlacement(tmp);
                
                const fee = res.feeType === 1 ? res.permFeePercentage : res.permFeeAmount;
                setInvoiceFeeValue(fee.toString());
                setInitialInvoiceFeeValue(fee.toString());
                setInitialSourceValue(res.source ?? '');
            }
            setFetchedPlacement(true);
            setIsFetchingPlacement(false);
        };
        copyPlacementId && getExistingPlacement();
    }, [copyPlacementId, navigate, errorHandler]);

    useEffect(() => {
        const getActiveFields = async () => {
            setIsFetchingConfig(true);
            const customFields = await GetCustomFieldsByEntity_OnlyActive(5);
            if (customFields) {
                let udfDefinitions: CustomFieldWithPredefinedValues[] = [];
                for (let i = 0; i < customFields.length; i++) {
                    const u = customFields[i];
                    if (!u.usePredefinedValues) {
                        udfDefinitions.push(u);
                        continue;
                    }

                    const vals = await GetPredefinedValues(u.id);
                    if (vals) udfDefinitions.push({ ...u, values: vals });
                    else udfDefinitions.push(u);
                }
                setAvailableCustomFields(udfDefinitions);
            }
            setFetchedConfig(true);
            setIsFetchingConfig(false);
        };
        getActiveFields();
    }, []);

    useEffect(() => {
        const setDefaultCreationValues = async () => {
            let tempState = {...DefaultMinPlacement};
            tempState.placementTypeID = placementTypeId;
            tempState.placementStatusID = PlacementStatusEnum.Future;
            tempState.consultantPercentage1 = 100;
            tempState.summary = defaultSummary;
            tempState.placementDate = moment().format('DD MMM YYYY');
            const j = await GetJobById(jobId);
            const me = await GetMyUser();
            if (defaultPayFreq) tempState.payFrequencyID = defaultPayFreq;
            if (defaultPaymentType) tempState.paymentTypeID = defaultPaymentType;
            if (defaultPayBillUnits) {
                tempState.payRateUnits = defaultPayBillUnits;
                tempState.chargeRateUnits = defaultPayBillUnits;
                tempState.onCostsUnits = defaultPayBillUnits;
            }
            if (defaultNoticePeriodUnits) tempState.noticePeriodUnits = defaultNoticePeriodUnits;
            if (defaultWarrantyPeriod) tempState.warrantyLength = defaultWarrantyPeriod;
            
            if (me && j) {
                if(me.userID === j.consultantID) {
                    tempState.consultantID1 = j.consultantID;
                    tempState.consultantID2 = j.consultantID2;
                }
                else tempState.consultantID1 = me.userID;
            }

            if (j) {
                setIsJobAlreadyClosed(j.statusName === 'Closed');
                tempState.jobID = j.id;
                tempState.jobTitle = j.title;
                tempState.siteID = j.siteID;
                tempState.address1 = j.address1;
                tempState.address2 = j.address2;
                tempState.address3 = j.address3;
                tempState.suburb = j.suburb;
                tempState.state = j.state;
                tempState.postcode = j.postcode;
                tempState.countryCode = j.countryCode;
                tempState.clientID = j.clientID;
                tempState.currencyID = j.currencyID;
                tempState.startDate = j.startDate;
                tempState.division = j.division;
                tempState.daysPerWeek = j.daysPerWeek;
                tempState.hoursPerDay = j.hoursPerDay;
                if (isSeperatePayBillDivision) tempState.payDivision = j.division;
                if(tempState.placementTypeID !== 5) {
                    tempState.payRate = j.rateFrom;
                    if (j.rateUnits) tempState.payRateUnits = j.rateUnits;
                }

                tempState.chargeRate = j.chargeRateFrom;
                if (j.chargeRateUnits) tempState.chargeRateUnits = j.chargeRateUnits;
                tempState.onCosts = j.onCostsFrom;
                if (j.onCostsUnits) tempState.onCostsUnits = j.onCostsUnits;
                tempState.salary = j.salaryFrom;
                tempState.salaryUnits = j.salaryUnits;
                tempState.feeType = j.feeType;
                if (j.permFeePercentage > 0) setInvoiceFeeValue(j.permFeePercentage.toString());
                else if (j.permFeeAmount > 0) setInvoiceFeeValue(j.permFeeAmount.toString());
                tempState.contactID = j.contact1ID;
                tempState.signatoryContactID = j.contact1ID;
                tempState.timesheetApprover1 = j.contact1ID;
                tempState.hiringManagerContactID = j.contact2ID;

                if (j.clientID) {
                    const c = await GetClientById(j.clientID);
                    if (c && c.defaultNoticePeriod) tempState.noticePeriod = c.defaultNoticePeriod;
                    if (c && c.defaultNoticePeriodUnits) tempState.noticePeriodUnits = c.defaultNoticePeriodUnits;
                    if (c && c.defaultBillingContactID) tempState.billingContactContactID = c.defaultBillingContactID;
                    else tempState.billingContactContactID = j.contact3ID;
                    
                    if (c && c.defaultTimesheetApproverID) tempState.timesheetApprover1 = c.defaultTimesheetApproverID;
                    else tempState.timesheetApprover1 = j.contact2ID;
                }
                else {
                    tempState.billingContactContactID = j.contact3ID;
                    tempState.timesheetApprover1 = j.contact2ID;
                }

                if (j.contact1ID !== j.contact2ID) tempState.timesheetApprover2 = j.contact1ID;
                tempState.clientReference = j.clientReference;
                if (Boolean(j.description)) tempState.summary = j.description;

                if (candidateIds && candidateIds.length === 1 && candidateIds[0]) {
                    const candidate = await GetCandidateJob(candidateIds[0], jobId);
                    const singleCandidateData = await GetCandidateById(candidateIds[0], errorHandler);
                    if (candidate) {
                        tempState.source = candidate.source;
                        setInitialSourceValue(candidate.source);
                    }
                    if (singleCandidateData) setSingleCandidateName(singleCandidateData.fullName);
                }
                else if (candidateIds && candidateIds.length > 1) tempState.source = 'Bulk Placement';
            }
            
            if (availableCustomFields.length > 0) {
                for (let i = 0; i < availableCustomFields.length; i++) {
                    const f = availableCustomFields[i];
                    if (f.usePredefinedValues && f.values && f.values.length > 0) {
                        const defaultValue = f.values.find(v => v.isDefault);
                        if (defaultValue) {
                            const field = 'customField' + f.name.substring(13) as keyof MinPlacement;
                            (tempState as any)[field] = defaultValue.value;
                        }
                    }
                }
            }
            init(tempState);
        };
        placementId === 0 && copyPlacementId === 0 && jobId && fetchedConfig && setDefaultCreationValues();
    }, [placementId, copyPlacementId, placementTypeId, jobId, fetchedConfig, availableCustomFields, init, candidateIds, defaultNoticePeriodUnits, defaultPayBillUnits, defaultPayFreq, defaultPaymentType, isSeperatePayBillDivision, errorHandler, defaultWarrantyPeriod, defaultSummary]);

    useEffect(() => {
        const getSavedState = async () => {
            setFetchingLayoutConfig(true);
            const elementsJson = await GetCustomerSettingBySettingName(layoutDataSettingName);
            if (elementsJson) {
                const panels = JSON.parse(elementsJson) as PanelModel[];
                setLayoutConfiguration(panels);
            }
            setFetchingLayoutConfig(false);
            setFetchedLayoutConfig(true);
        };
        layoutDataSettingName && getSavedState();
    }, [layoutDataSettingName]);

    useEffect(() => loadingHandler && loadingHandler(isFetchingPlacement || isFetchingConfig || fetchingLayoutConfig), [isFetchingPlacement, isFetchingConfig, fetchingLayoutConfig, loadingHandler]);

    useEffect(() => {
        if ((placementId || copyPlacementId) && existingPlacement && fetchedPlacement && fetchedConfig) {
            const mp = PlacementToMinPlacement(existingPlacement);
            init(mp);
            const api = editorRef.current;
            if (api) api.setContent(existingPlacement.summary ?? '');
        }
    }, [placementId, copyPlacementId, existingPlacement, fetchedPlacement, fetchedConfig, init]);

    const totalConsultantPercent = useMemo(() => {
        let total = 0;
        if (state.consultantID1) total += +state.consultantPercentage1;
        if (state.consultantID2) total += +state.consultantPercentage2;
        if (state.consultantID3) total += +state.consultantPercentage3;
        if (state.consultantID4) total += +state.consultantPercentage4;
        if (state.consultantID5) total += +state.consultantPercentage5;
        return total;
    }, [state.consultantID1, state.consultantPercentage1, state.consultantID2, state.consultantPercentage2, state.consultantID3, state.consultantPercentage3, state.consultantID4, state.consultantPercentage4, state.consultantID5, state.consultantPercentage5]);

    const isOnCostsDisabled = useMemo(() => (state.placementTypeID === 2 || state.placementTypeID === 4) && Boolean(onCostsPercentage), [state.placementTypeID, onCostsPercentage]);

    const saveChangesHandler = useCallback(async () => {
        setShowConfirmHoursPerDay(false);

        if (!Boolean(state.siteID)) {
            setShowValidation(true);
            errorHandler && errorHandler("A Site must be selected");
            return false;
        }

        if (!Boolean(state.clientID)) {
            setShowValidation(true);
            errorHandler && errorHandler("Client can't be empty");
            return false;
        }
        
        if (!Boolean(state.contactID)) {
            setShowValidation(true);
            errorHandler && errorHandler("Contact can't be empty");
            return false;
        }
        
        if (!Boolean(state.hiringManagerContactID)) {
            setShowValidation(true);
            errorHandler && errorHandler("Hiring Manager can't be empty");
            return false;
        }
        
        if (!Boolean(state.billingContactContactID)) {
            setShowValidation(true);
            errorHandler && errorHandler("Billing 1 can't be empty");
            return false;
        }

        if (!Boolean(state.signatoryContactID)) {
            setShowValidation(true);
            errorHandler && errorHandler("Signatory Field can't be empty");
            return false;
        }

        if (totalConsultantPercent !== 100) {
            setShowValidation(true);
            errorHandler && errorHandler("Consultant percentages must add to 100%");
            return false;
        }

        if (requiresDivisionValidation && !Boolean(state.division)) {
            setShowValidation(true);
            errorHandler && errorHandler("Division can't be empty");
            return false;
        }

        if (requiresPaymentTypeValidation && !Boolean(state.paymentTypeID)) {
            setShowValidation(true);
            errorHandler && errorHandler("Payment Type can't be empty");
            return false;
        }

        if (requiresPayFreqValidation && !Boolean(state.payFrequencyID)) {
            setShowValidation(true);
            errorHandler && errorHandler("Pay Frequency can't be empty");
            return false;
        }

        const startDate = state.startDate ? state.startDate : '0001-01-01T00:00:00';
        const endDate = state.endDate ? state.endDate : '0001-01-01T00:00:00';
        const isPermanent = state.placementTypeID === 1;
        const shouldValidateEndDate = !isPermanent && (!isOptionalEndDate || (isOptionalEndDate && state.endDate !== '' && state.endDate !== '0001-01-01T00:00:00'));
        if (shouldValidateEndDate && endDate < startDate) {
            setShowValidation(true);
            errorHandler && errorHandler("End Date Must be equal or after Start Date");
            return false;
        }

        const hoursPerDay = state.hoursPerDay;
        if (!Boolean(hoursPerDay)) {
            setShowValidation(true);
            errorHandler && errorHandler("Hours Per Day field is required");
            return false;
        }
        else if(+hoursPerDay <= 0) {
            setShowValidation(true);
            errorHandler && errorHandler("Hours Per Day must be greater than 0");
            return false;
        }
        else if(+hoursPerDay > 24) {
            setShowValidation(true);
            errorHandler && errorHandler("Hours Per Day must be less than 24");
            return false;
        }

        const daysPerWeek = state.daysPerWeek;
        if (!Boolean(daysPerWeek)) {
            setShowValidation(true);
            errorHandler && errorHandler("Days Per Week field is required");
            return false;
        }
        else if(+daysPerWeek <= 0) {
            setShowValidation(true);
            errorHandler && errorHandler("Days Per Week must be greater than 0");
            return false;
        }
        else if(+daysPerWeek > 7) {
            setShowValidation(true);
            errorHandler && errorHandler("Days Per Week must be less than 7");
            return false;
        }

        let emptyNumericUdfs: Partial<MinPlacement> = { };
        let nullDateUdfs: Partial<MinPlacement> = { };
        for (let i = 0; i < availableCustomFields.length; i++) {
            const u = availableCustomFields[i];
            if (!u.editable) continue;

            const customFieldNumber = u.name.substring(13);
            const customFieldKey = "customField" + customFieldNumber as keyof MinPlacement;
            const { format } = UdfPlacementFieldMapObj['CustomField' + customFieldNumber as CustomFieldType];
            const value = state[customFieldKey] as string | number | undefined;
            if (format === "number" && (value === '' || value === null || value === undefined)) (emptyNumericUdfs[customFieldKey] as any) = undefined;
            if ((format === "date" || format === 'datetime') && !Boolean(value)) (nullDateUdfs[customFieldKey] as any) = '0001-01-01T00:00:00';

            if (!customFieldValidateMap['CustomField' + customFieldNumber]) continue;

            const validation = IsValidCustomFieldValue(value, format, u.mandatory);

            if (validation === "required") {
                setShowValidation(true);
                errorHandler && errorHandler(`${u.agencyName} is required`);
                return false;
            }
            else if (validation === "invalid-number") {
                setShowValidation(true);
                errorHandler && errorHandler(`${u.agencyName} must have a valid numeric value`);
                return false;
            }
            else if (validation === "invalid-date") {
                setShowValidation(true);
                errorHandler && errorHandler(`${u.agencyName} must have a valid date value`);
                return false;
            }
            else if (validation === "range-date") {
                setShowValidation(true);
                errorHandler && errorHandler(`${u.agencyName} must be a date between 01-01-1753 and 31-12-9999`);
                return false;
            }
        }

        loadingHandler && loadingHandler(true);
        const editorApi = editorRef.current;
        const summaryContent = editorApi ? editorApi.getContent() : '';
        const placementDate = state.placementDate ? state.placementDate : '0001-01-01T00:00:00';

        let feePercent = 0;
        let feeFixed = 0;
        if (state.feeType !== 0) {
            if (state.feeType === 1) feePercent = invoiceFeeValue === '' ? 0 : +invoiceFeeValue;
            else if (state.feeType === 2) feeFixed = invoiceFeeValue === '' ? 0 : +invoiceFeeValue;
        }

        const payRate = state.payRate ? state.payRate : 0;
        const chargeRate = state.chargeRate ? state.chargeRate : 0;
        const onCosts = state.onCosts ? state.onCosts : 0;

        const percent1 = state.consultantPercentage1 ? state.consultantPercentage1 : 0;
        const percent2 = state.consultantPercentage2 ? state.consultantPercentage2 : 0;
        const percent3 = state.consultantPercentage3 ? state.consultantPercentage3 : 0;
        const percent4 = state.consultantPercentage4 ? state.consultantPercentage4 : 0;
        const percent5 = state.consultantPercentage5 ? state.consultantPercentage5 : 0;

        const notice = Boolean(state.noticePeriod) ? state.noticePeriod : 0;


        if (placementId === 0 && candidateIds && candidateIds.length === 1) {
            const candidateId = candidateIds[0];
            const res = await CreatePlacement({...state, candidateID: candidateId, summary: summaryContent, startDate: startDate, endDate: endDate, placementDate: placementDate, permFeePercentage: feePercent, permFeeAmount: feeFixed, payRate: payRate, chargeRate: chargeRate, onCosts: onCosts, consultantPercentage1: percent1, consultantPercentage2: percent2, consultantPercentage3: percent3, consultantPercentage4: percent4, consultantPercentage5: percent5, noticePeriod: notice, ...emptyNumericUdfs, ...nullDateUdfs}, shouldCloseJob, errorHandler);
            if (!res) {
                loadingHandler && loadingHandler(false);
                return false;
            }
            if (isPlacementCandidateConfirmation) localStorage.setItem('placementCandidateConfirmationId', res.value.toString());
            setCreatedPlacementId(res.value);
        }
        else if (placementId === 0 && candidateIds && candidateIds.length > 1) {
            let createdPlacements: number[] = [];
            for (let i = 0; i < candidateIds.length; i++) {
                const candidateId = candidateIds[i];
                const res = await CreatePlacement({...state, candidateID: candidateId, summary: summaryContent, startDate: startDate, endDate: endDate, placementDate: placementDate, permFeePercentage: feePercent, permFeeAmount: feeFixed, payRate: payRate, chargeRate: chargeRate, onCosts: onCosts, consultantPercentage1: percent1, consultantPercentage2: percent2, consultantPercentage3: percent3, consultantPercentage4: percent4, consultantPercentage5: percent5, noticePeriod: notice, ...emptyNumericUdfs, ...nullDateUdfs}, false, errorHandler);
                if (!res) {
                    loadingHandler && loadingHandler(false);
                    return false;
                }
                createdPlacements.push(res.value);
            }
            if (createdPlacements.length === candidateIds.length && shouldCloseJob) {
                await CloseJobs([state.jobID], 1, 'Placement Made');
            }
        }
        else if (placementId && (hasChanges || isEditorDirty || invoiceFeeValue !== initialInvoiceFeeValue)) {
            const res = await UpdatePlacement(placementId, {...state, summary: summaryContent, startDate: startDate, endDate: endDate, placementDate: placementDate, permFeePercentage: feePercent, permFeeAmount: feeFixed, payRate: payRate, chargeRate: chargeRate, onCosts: onCosts, consultantPercentage1: percent1, consultantPercentage2: percent2, consultantPercentage3: percent3, consultantPercentage4: percent4, consultantPercentage5: percent5, noticePeriod: notice, ...emptyNumericUdfs, ...nullDateUdfs}, errorHandler);
            if (!res) {
                loadingHandler && loadingHandler(false);
                return false;
            }
        }

        updateInitial();
        setIsEditorDirty(false);
        editorApi && editorApi.setDirty(false);
        loadingHandler && loadingHandler(false);
        setSavedChanges(true);
        return true;
    }, [state, totalConsultantPercent, requiresDivisionValidation, requiresPaymentTypeValidation, requiresPayFreqValidation, isOptionalEndDate, loadingHandler, placementId, candidateIds, hasChanges, isEditorDirty, invoiceFeeValue, initialInvoiceFeeValue, updateInitial, errorHandler, availableCustomFields, customFieldValidateMap, shouldCloseJob, isPlacementCandidateConfirmation]);

    const saveChangesWrapper = useCallback(async () => {
        if (state.hoursPerDay > 12) {
            setShowConfirmHoursPerDay(true);
            return false;
        }
        return await saveChangesHandler();
    }, [saveChangesHandler, state.hoursPerDay]);

    useEffect(() => {
        if (placementId === 0 && setSummaryBar) {
            const shouldCloseCheck = isJobAlreadyClosed ? <></> : (
                <FormControlLabel
                    sx={{ m: 0, mr:'20px' }}
                    label="Close Job ?"
                    labelPlacement="start"
                    control={
                        <Checkbox
                            checked={ shouldCloseJob }
                            onChange={ () => setShouldCloseJob(prev => !prev) }
                            sx={{ p: '4px' }}
                        />
                    }
                />
            );
            const actionButton = <Button variant="contained" color="success" onClick={saveChangesWrapper}>Save</Button>
            const sb = <TitleAndActionSummaryBar title="Placements > Create" browserTabTitle="Create > Placements" action={<>{shouldCloseCheck}{actionButton}</>} />;
            setSummaryBar(sb);
        }
        else if (placementId && setSummaryBar) {
            const actionButton = <Button variant="contained" color="success" disabled={!hasChanges && invoiceFeeValue === initialInvoiceFeeValue && !isEditorDirty } onClick={saveChangesWrapper}>Save</Button>
            const goBackAction = <Link to={`/placements/${placementId}`} style={{ textDecoration: 'none' }}><Button variant="contained" color="error" sx={{ mr: '5px' }}>Cancel</Button></Link>;
            const sb = <TitleAndActionSummaryBar title="Placements > Edit" browserTabTitle="Edit > Placements" action={<>{goBackAction}{actionButton}</>} />;
            setSummaryBar(sb);
        }
    }, [placementId, hasChanges, isEditorDirty, invoiceFeeValue, initialInvoiceFeeValue, shouldCloseJob, setSummaryBar, saveChangesWrapper, isJobAlreadyClosed]);

    const onStringFieldChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        let val = value;
        if (name.startsWith('dtUDF') && value && !value.includes('T')) val += 'T00:00:00';
        change(name as keyof MinPlacement, val);
    }, [change]);

    const onDateFieldChange = useCallback((m: moment.Moment | null, fieldName: string) => {
        if(m && m.isValid()) {
            change(fieldName as keyof MinPlacement, m.format('YYYY-MM-DDT00:00:00'))
        }
        else if (m === null) change(fieldName as keyof MinPlacement, '');
    }, [change]);

    const onDateTimeFieldChange = useCallback((m: moment.Moment | null, fieldName: string) => {
        if(m && m.isValid()) {
            change(fieldName as keyof MinPlacement, m.format('YYYY-MM-DDTHH:mm'));
        }
        else if (m === null) change(fieldName as keyof MinPlacement, '');
    }, [change]);

    const onNumericListFieldChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        change(name as keyof MinPlacement, +value);
    }, [change]);

    const onChargeRateUnitsChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        const parsedValue = +value;
        change('chargeRateUnits', parsedValue);
        change('payRateUnits', parsedValue);
        change('onCostsUnits', parsedValue);
    }, [change]);

    const onDecimalFieldChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        if (value === '') change(name as keyof MinPlacement, '');
        if (RegexIsPositiveNumberWith2Decimals(value)) change(name as keyof MinPlacement, value);
    }, [change]);

    const onNumberFieldChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        
        const n = +value;
        if (isNaN(n) && value !== '') return;
        if (value.length > 11) return;

        const i = value.indexOf('.');
        if (value.length > 8 && i === -1) return;

        if(i >= 0) {
            const decimals = value.substring(i + 1);
            if (decimals.length > 2) {
                change(name as keyof MinPlacement, value.substring(0, i + 3));
                return;
            }
        }
        change(name as keyof MinPlacement, value);
    }, [change]);

    const onPayRateChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        
        const n = +value;
        if (isNaN(n) && value !== '') return;
        if (value.length > 11) return;

        const i = value.indexOf('.');
        if (value.length > 8 && i === -1) return;

        if(i >= 0) {
            const decimals = value.substring(i + 1);
            if (decimals.length > 2) {
                change(name as keyof MinPlacement, value.substring(0, i + 3));
                return;
            }
        }
        change(name as keyof MinPlacement, value);
        if (isOnCostsDisabled && onCostsPercentage && n && Boolean(value)) {
            const payRate = +value;
            const onCosts = (payRate / 100) * onCostsPercentage;
            change('onCosts', +onCosts.toFixed(2))
        }
    }, [change, isOnCostsDisabled, onCostsPercentage]);

    const onFeeChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        
        const n = +value;
        if (isNaN(n) && value !== '') return;
        if (value.length > 11) return;

        const i = value.indexOf('.');
        if (value.length > 8 && i === -1) return;

        if(i >= 0) {
            const decimals = value.substring(i + 1);
            if (decimals.length > 2) {
                setInvoiceFeeValue(value.substring(0, i + 3));
                return;
            }
        }
        setInvoiceFeeValue(value);
    }, []);

    const onSiteChange = useCallback((site: Site | null) => {
        if (site) {
            change('siteID', site.id,);
            change('siteName', site.name,);
            change('address1', site.address1);
            change('address2', site.address2);
            change('address3', site.address3);
            change('suburb', site.suburb);
            change('state', site.state);
            change('postcode', site.postcode);
            change('countryName', site.countryName ?? '');
        }
        else {
            change('siteID', 0);
            change('siteName', '',);
            change('address1', '');
            change('address2', '');
            change('address3', '');
            change('suburb', '');
            change('state', '');
            change('postcode', '');
            change('countryName', '');
        }

    }, [change]);

    const onDivisionChange = useCallback((divisionId: number | null, fieldName: keyof MinPlacement) => {
        change(fieldName, divisionId ? divisionId : 0);
    }, [change]);

    const onCurrencyChange = useCallback((cId: number | null) => {
        change('currencyID', cId ?? 0);
    }, [change]);

    const onClientChange = useCallback((client: Client | null) => {
        change('clientID', client ? client.id : 0);
    }, [change]);

    const onPayClientChange = useCallback((client: Client | null) => {
        change('paymentCompanyID', client ? client.id : 0);
    }, [change]);

    const onCandidateChange = useCallback((candidate: CandidateList | null) => {
        change('candidateID', candidate ? candidate.id : 0);
    }, [change]);

    const onContactChange = useCallback((contact: Contact | null, fieldName: keyof MinPlacement) => {
        change(fieldName, contact ? contact.id : 0);
    }, [change]);

    const onConsultantChange = useCallback((user: NameIdObj | null, fieldName: keyof MinPlacement) => {
        change(fieldName, user ? user.id : 0);
        if(user === null) {
            if (fieldName === 'consultantID1') change('consultantPercentage1', 0);
            if (fieldName === 'consultantID2') change('consultantPercentage2', 0);
            if (fieldName === 'consultantID3') change('consultantPercentage3', 0);
            if (fieldName === 'consultantID4') change('consultantPercentage4', 0);
            if (fieldName === 'consultantID5') change('consultantPercentage5', 0);
        }
    }, [change]);

    const onComplianceChecklistChange = useCallback((c: ComplianceChecklist | null) => {
        change('complianceChecklistID', c ? c.id : 0);
    }, [change])

    const { unsavedChangesDialog, hasBlockedRoute} = useUnsavedChangesDialog(hasChanges || isEditorDirty , saveChangesHandler);

    const redirectPlacementId = useMemo(() => {
        if (placementId !== 0) return placementId;
        if (savedChanges && createdPlacementId) return createdPlacementId;
        return 0;
    }, [placementId, createdPlacementId, savedChanges]);

    const elements = useMemo<PlacementRecordDashboardElementDefinition[]>(() => {
        if (!fetchedLayoutConfig) return [];
        if (layoutConfiguration !== null) {
            const elements = GetPanelDefinitionsFromPanelModels(layoutConfiguration);
            return elements ;
        }
        return defaultElements;
    }, [layoutConfiguration, fetchedLayoutConfig, defaultElements]);

    const customFieldsSettingsMap = useMemo<CustomFieldSettingsMap>(() => {
        let obj: CustomFieldSettingsMap = {};
        availableCustomFields.forEach(u => {
            const customFieldNumber = u.name.substring(13);
            const key = 'CustomField' + customFieldNumber as CustomFieldType;
            obj[key] = {
                title: u.agencyName,
                isMultiLine: u.multiLine && !u.usePredefinedValues,
                isMandatory: u.mandatory,
                isEditable: u.editable,
                usePredefinedValues: u.usePredefinedValues,
                values: u.values
            }
        });
        return obj;
    }, [availableCustomFields]);

    useEffect(() => {
        if (elements.length > 0) {
            let vm: CustomFieldValidateMap = {};
            let hasDivision = false;
            let hasPaymentType = false;
            let hasPayFreq = false;
            for (let i = 0; i < elements.length; i++) {
                const element = elements[i];
                if (element.id.startsWith('CustomField')) {
                    const split = element.id.split('_');
                    const key = split[0];
                    if (key) vm[key] = true;
                }
                else if (element.id.startsWith('PlacementDivision_')) hasDivision = true;
                else if (element.id.startsWith('PlacementPaymentType_')) hasPaymentType = true;
                else if (element.id.startsWith('PlacementPayFreq_')) hasPayFreq = true;
            }
            setCustomFieldValidateMap(vm);
            setRequiresDivisionValidation(hasDivision);
            setRequiresPaymentTypeValidation(hasPaymentType);
            setRequiresPayFreqValidation(hasPayFreq);
        }
    }, [elements]);

    const marginFieldValue = useMemo(() => {
        if (state.placementTypeID === 1) { // PERMS
            if (state.feeType > 0) {
                if (state.feeType === 1) {
                    let feePercent = invoiceFeeValue === '' ? 0 : +invoiceFeeValue;;
                    let feeFixed = (state.salary / 100) * feePercent;
                    return `${formatNumber(feePercent)} Percent (${formatNumber(feeFixed)})`;
                }
                if (state.feeType === 2 && state.salary > 0) {
                    let feeFixed = invoiceFeeValue === '' ? 0 : +invoiceFeeValue;
                    let feePercent = (feeFixed / state.salary) * 100;
                    return `${formatNumber(feeFixed)} Fixed (${formatNumber(feePercent)}%)`;
                }
            }
            return '';
        }

        if (state.onCostsUnits === state.payRateUnits && state.onCostsUnits === state.chargeRateUnits) {
            const margin = +state.chargeRate - (+state.payRate + +state.onCosts);
            const marginPercent = +state.chargeRate !== 0 ? (margin * 100) / +state.chargeRate : 0;
            return `${formatNumber(margin)} (${formatNumber(marginPercent)}%)`;
        }
        return 'Units must match';
    }, [state.placementTypeID, state.onCostsUnits, state.payRateUnits, state.chargeRateUnits, state.feeType, state.salary, state.chargeRate, state.payRate, state.onCosts, invoiceFeeValue]);

    const sourceOptions = useMemo(() => {
        if (predefinedSources) {
            let o = predefinedSources.map(s => <MenuItem key={s} value={s}>{s}</MenuItem>);
            if (initialSourceValue && !predefinedSources.includes(initialSourceValue)) o.push(<MenuItem key={initialSourceValue} value={initialSourceValue}>{initialSourceValue}</MenuItem>);
            o.unshift(<MenuItem key={'NONE_ELEMENT'} value="">None</MenuItem>);
            return o;
        }
    }, [initialSourceValue, predefinedSources]);

    const renderElement = useCallback((id: string, type: PlacementRecordDashboardElementType) => {
        switch (type) {
            case 'Divider': return <Box pt="20px"><Divider component="div" /></Box>;
            case 'Spacer': return <></>;
            case 'PlacementId': return <SingleFieldElement useEllipsisForLongValues labelWidthPercent={30} fontSize="1rem" fieldTitle="ID" fieldValue={state.id.toString()} />;
            case 'PlacementJobId': return <SingleFieldElement useEllipsisForLongValues labelWidthPercent={30} fontSize="1rem" fieldTitle="Job ID" fieldValue={state.jobID.toString()} />;
            case 'PlacementType':
                let pType = '';
                if (state.placementTypeID === 1) pType = "Permanent";
                else if (state.placementTypeID === 2) pType = "Contract";
                else if (state.placementTypeID === 3) pType = "Fixed Term";
                else if (state.placementTypeID === 4) pType = "Maximum Term";
                else if (state.placementTypeID === 5) pType = "Margin Only";
                return <SingleFieldElement labelWidthPercent={30} fontSize="1rem" fieldTitle="Type" fieldValue={pType} />;
            case 'PlacementJobTitle': return (
                <EditableSingleFieldElement
                    isError={showValidation && !state.jobTitle.trim()}
                    errorMessage="Required"
                    fieldTitle='Job Title'
                    fieldValue={state.jobTitle}
                    fieldName="jobTitle"
                    onChangeHandler={onStringFieldChange}
                />
            );
            case 'PlacementCandidate': 
                if (placementId === 0 && copyPlacementId === 0 && candidateIds && candidateIds.length > 1) {
                    return <SingleFieldElement useEllipsisForLongValues labelWidthPercent={30} fontSize="1rem" fieldTitle="Candidate" fieldValue="Bulk Candidates" />;
                }
                if (placementId || copyPlacementId) {
                    return (
                        <EditableSingleFieldElement isError={showValidation && state.candidateID === 0} errorMessage="Required" format="custom" fieldTitle='Candidate' >
                            <CandidatePicker
                                hideLabel
                                variant="standard"
                                value={state.candidateID}
                                onSelectCallback={ onCandidateChange }
                            />
                        </EditableSingleFieldElement>
                    );
                }
                return <SingleFieldElement useEllipsisForLongValues labelWidthPercent={30} fontSize="1rem" fieldTitle="Candidate" fieldValue={singleCandidateName} />;
            case 'PlacementStartDate':
                const isPermanent1 = state.placementTypeID === 1;
                const shouldValidateStartDate = !isPermanent1 && (!isOptionalEndDate || (isOptionalEndDate && state.endDate !== '' && state.endDate !== '0001-01-01T00:00:00'));
                return <EditableSingleFieldElement fieldTitle='Start Date' fieldValue={state.startDate} fieldName="startDate" onDateChangeHandler={onDateFieldChange} format="date" isError={showValidation && shouldValidateStartDate && state.startDate > state.endDate} errorMessage="Can't be after End Date" />;
                case 'PlacementEndDate':
                const isPermanent2 = state.placementTypeID === 1;
                const shouldValidateEndDate = !isPermanent2 && (!isOptionalEndDate || (isOptionalEndDate && state.endDate !== '' && state.endDate !== '0001-01-01T00:00:00'));
                return <EditableSingleFieldElement fieldTitle='End Date' fieldValue={state.endDate} fieldName="endDate" onDateChangeHandler={onDateFieldChange} format="date" isError={showValidation && shouldValidateEndDate && state.startDate > state.endDate} errorMessage="Can't be before Start Date" />;
            case 'PlacementPayDivision':
                if (isSeperatePayBillDivision) {
                    return (
                        <EditableSingleFieldElement format="custom" fieldTitle='Pay Division' >
                            <DivisionPicker divisionId={state.payDivision} onSelectCallback={id => onDivisionChange(id, 'payDivision')} hideLabel variant="standard" />
                        </EditableSingleFieldElement>
                    );
                }
                return <SingleFieldElement labelWidthPercent={30} fontSize="1rem" fieldTitle="Pay Division" fieldValue="[ Disabled Field ]" />;
            case 'PlacementDivision': return (
                <EditableSingleFieldElement format="custom" fieldTitle={isSeperatePayBillDivision ? 'Bill Division' : 'Division'} isError={showValidation && requiresDivisionValidation && !Boolean(state.division)} errorMessage="Required" >
                    <DivisionPicker divisionId={state.division} onSelectCallback={id => onDivisionChange(id, 'division')} hideLabel variant="standard" />
                </EditableSingleFieldElement>
            );
            case 'PlacementCurrency': return (
                <EditableSingleFieldElement format="custom" fieldTitle='Currency' >
                    <CurrencyPicker
                        hideLabel
                        variant="standard"
                        currencyId={state.currencyID}
                        onSelectCallback={ onCurrencyChange }
                    />
                </EditableSingleFieldElement>
            );
            case 'PlacementSalaryPackage': return (
                <EditableSingleFieldElement format="custom" fieldTitle='Salary Package'>
                    <Box display="flex">
                        <RWTextFieldComponent value={state.salary?.toString()} name="salary" variant="standard" placeholder="From" sxOptions={{ flex: '1 1 0', pr: '5px' }} onChange={ onNumberFieldChange } />
                        <TextField select value={state.salaryUnits.toString()} name="salaryUnits" variant="standard" sx={{ flex: '1 1 0', pl: '5px' }} onChange={ onNumericListFieldChange } >
                            {timePeriodOptions}
                        </TextField>
                    </Box>
                </EditableSingleFieldElement>
            );
            case 'PlacementInvoiceFee': return (
                <EditableSingleFieldElement format="custom" fieldTitle='Invoice Fee'>
                    <Box display="flex">
                        <RWTextFieldComponent value={invoiceFeeValue} variant="standard" sxOptions={{ flex: '1 1 0', pr: '5px' }} onChange={ onFeeChange } />
                        <TextField select value={state.feeType.toString()} name="feeType" variant="standard" sx={{ flex: '1 1 0', pl: '5px' }} onChange={ onNumericListFieldChange } >
                            <MenuItem value="0">Select</MenuItem>
                            <MenuItem value="1">Percentage</MenuItem>
                            <MenuItem value="2">Fixed</MenuItem>
                        </TextField>
                    </Box>
                </EditableSingleFieldElement>
            );
            case 'PlacementRatesCalculator':
                if (ratesCalculatorLink) return <SingleFieldElement labelWidthPercent={30} fontSize="1rem" fieldTitle="" format="external-link" fieldValue="Rates Calculator" href={ratesCalculatorLink} />;
                return <SingleFieldElement labelWidthPercent={30} fontSize="1rem" fieldTitle="" fieldValue="Rates Calculator (No link defined)" />;
            case 'PlacementChargeRate': return (
                <EditableSingleFieldElement format="custom" fieldTitle='Charge Rate'>
                    <Box display="flex">
                        <RWTextFieldComponent value={state.chargeRate?.toString()} name="chargeRate" variant="standard" placeholder="From" sxOptions={{ flex: '1 1 0', pr: '5px' }} onChange={ onNumberFieldChange } />
                        <TextField select value={state.chargeRateUnits.toString()} name="chargeRateUnits" variant="standard" sx={{ flex: '1 1 0', pl: '5px' }} onChange={ onChargeRateUnitsChange } >
                            {timePeriodOptions}
                        </TextField>
                    </Box>
                </EditableSingleFieldElement>
            );
            case 'PlacementPayRate': return (
                <EditableSingleFieldElement format="custom" fieldTitle='Pay Rate'>
                    <Box display="flex">
                        <RWTextFieldComponent value={state.payRate?.toString()} name="payRate" variant="standard" placeholder="From" sxOptions={{ flex: '1 1 0', pr: '5px' }} onChange={ onPayRateChange } />
                        <TextField select value={state.payRateUnits.toString()} name="payRateUnits" variant="standard" sx={{ flex: '1 1 0', pl: '5px' }} onChange={ onNumericListFieldChange } >
                            {timePeriodOptions}
                        </TextField>
                    </Box>
                </EditableSingleFieldElement>
            );
            case 'PlacementOnCost': return (
                <EditableSingleFieldElement format="custom" fieldTitle='On Costs'>
                    <Box display="flex">
                        <RWTextFieldComponent disabled={isOnCostsDisabled} value={state.onCosts?.toString()} name="onCosts" variant="standard" placeholder="From" sxOptions={{ flex: '1 1 0', pr: '5px' }} onChange={ onNumberFieldChange } />
                        <TextField disabled={isOnCostsDisabled} select value={state.onCostsUnits.toString()} name="onCostsUnits" variant="standard" sx={{ flex: '1 1 0', pl: '5px' }} onChange={ onNumericListFieldChange } >
                            {timePeriodOptions}
                        </TextField>
                    </Box>
                </EditableSingleFieldElement>
            );
            case 'PlacementMargin': return <SingleFieldElement useEllipsisForLongValues labelWidthPercent={30} fontSize="1rem" fieldTitle="Margin" fieldValue={marginFieldValue} />;
            case 'PlacementHoursPerDay': return (
                <EditableSingleFieldElement fieldTitle='Hours Per Day' fieldValue={state.hoursPerDay.toString()} fieldName="hoursPerDay" onChangeHandler={onDecimalFieldChange} isError={ showValidation && (!Boolean(state.hoursPerDay) || +state.hoursPerDay > 24) } errorMessage={ Boolean(state.hoursPerDay) ? 'Not valid' : 'Required' } />
            );
            case 'PlacementDaysPerWeek': 
                const isDaysPerWeekError = showValidation && (!Boolean(state.daysPerWeek) || +state.daysPerWeek > 7);
                return (
                    <EditableSingleFieldElement format="custom" fieldTitle='Days Per Week'  isError={ isDaysPerWeekError } errorMessage={ Boolean(state.daysPerWeek) ? 'Not valid' : 'Required' }>
                        <RWTextFieldComponent
                            name="daysPerWeek"
                            variant="standard"
                            value={state.daysPerWeek.toString()}
                            onChange={onStringFieldChange}
                            validator={daysPerWeekValidator}
                            isError={isDaysPerWeekError}
                        />
                    </EditableSingleFieldElement>
                );
            case 'PlacementComments': return <EditableSingleFieldElement fieldTitle='Comments' fieldValue={state.remComments ?? ''} fieldName="remComments" onChangeHandler={onStringFieldChange} />;
            case 'PlacementPaymentType': return (
                <EditableSingleFieldElement format="select" fieldTitle='Payment Type' fieldValue={state.paymentTypeID.toString()} fieldName="paymentTypeID" onChangeHandler={onNumericListFieldChange} isError={showValidation && requiresPaymentTypeValidation && !Boolean(state.paymentTypeID)} errorMessage="Required">
                    {paymentTypeOptions}
                </EditableSingleFieldElement>
            );
            case 'PlacementPayCompany': return (
                <EditableSingleFieldElement format="custom" fieldTitle='Pay Company' >
                    <ClientPicker
                        hideLabel
                        variant="standard"
                        disabled={state.paymentTypeID !== 2}
                        value={state.paymentCompanyID}
                        onSelectCallback={ onPayClientChange }
                        errorHandler={errorHandler}
                        loadingHandler={loadingHandler}
                        successHandler={successHandler}
                    />
                </EditableSingleFieldElement>
            );
            case 'PlacementPayContact': return (
                <EditableSingleFieldElement format="custom" fieldTitle='Pay Contact' >
                    <ContactPicker
                        hideLabel
                        variant="standard"
                        disabled={state.paymentTypeID !== 2}
                        value={state.paymentContactID}
                        onSelectCallback={ c => onContactChange(c, 'paymentContactID') }
                        errorHandler={errorHandler}
                        loadingHandler={loadingHandler}
                        successHandler={successHandler}
                    />
                </EditableSingleFieldElement>
            );
            case 'PlacementWarranty': return (
                <EditableSingleFieldElement format="select" fieldTitle='Warranty' fieldValue={state.warrantyLength.toString()} fieldName="warrantyLength" onChangeHandler={onNumericListFieldChange} >
                    {warrantyAndProbationOptions}
                </EditableSingleFieldElement>
            );
            case 'PlacementLocation': return (
                <EditableClientLocationElement
                    siteId={state.siteID}
                    clientId={state.clientID}
                    siteName={state.siteName ?? ''}
                    address1={state.address1 ?? ''}
                    address2={state.address2 ?? ''}
                    address3={state.address3 ?? ''}
                    suburb={state.suburb ?? ''}
                    state={state.state ?? ''}
                    postcode={state.postcode ?? ''}
                    country={state.countryName ?? ''}
                    error={showValidation && !Boolean(state.siteID)}
                    onSiteChange={onSiteChange}
                    errorHandler={errorHandler}
                    loadingHandler={loadingHandler}
                    successHandler={successHandler}
                />
            );
            case 'PlacementClient': return (
                <EditableSingleFieldElement isError={showValidation && state.clientID === 0} errorMessage="Required" format="custom" fieldTitle='Client' >
                    <ClientPicker
                        hideLabel
                        variant="standard"
                        value={state.clientID}
                        onSelectCallback={ onClientChange }
                        errorHandler={errorHandler}
                        loadingHandler={loadingHandler}
                        successHandler={successHandler}
                    />
                </EditableSingleFieldElement>
            );
            case 'PlacementContact': return (
                <EditableSingleFieldElement isError={showValidation && state.contactID === 0} errorMessage="Required" format="custom" fieldTitle='Contact' >
                    <ContactPicker
                        hideLabel
                        variant="standard"
                        value={state.contactID} onSelectCallback={ c => onContactChange(c, 'contactID') }
                        errorHandler={errorHandler}
                        loadingHandler={loadingHandler}
                        successHandler={successHandler}
                    />
                </EditableSingleFieldElement>
            );
            case 'PlacementHiringManager': return (
                <EditableSingleFieldElement isError={showValidation && state.hiringManagerContactID === 0} errorMessage="Required" format="custom" fieldTitle='Hiring Mgr' >
                    <ContactPicker
                        hideLabel
                        variant="standard"
                        value={state.hiringManagerContactID} onSelectCallback={ c => onContactChange(c, 'hiringManagerContactID') }
                        errorHandler={errorHandler}
                        loadingHandler={loadingHandler}
                        successHandler={successHandler}
                    />
                </EditableSingleFieldElement>
            );
            case 'PlacementBilling1': return (
                <EditableSingleFieldElement isError={showValidation && state.billingContactContactID === 0} errorMessage="Required" format="custom" fieldTitle='Billing 1' >
                    <ContactPicker
                        hideLabel
                        variant="standard"
                        value={state.billingContactContactID} onSelectCallback={ c => onContactChange(c, 'billingContactContactID') }
                        errorHandler={errorHandler}
                        loadingHandler={loadingHandler}
                        successHandler={successHandler}
                    />
                </EditableSingleFieldElement>
            );
            case 'PlacementBilling2': return (
                <EditableSingleFieldElement format="custom" fieldTitle='Billing 2' >
                    <ContactPicker
                        hideLabel
                        variant="standard"
                        value={state.billingContact2ContactID} onSelectCallback={ c => onContactChange(c, 'billingContact2ContactID') }
                        errorHandler={errorHandler}
                        loadingHandler={loadingHandler}
                        successHandler={successHandler}
                    />
                </EditableSingleFieldElement>
            );
            case 'PlacementBilling3': return (
                <EditableSingleFieldElement format="custom" fieldTitle='Billing 3' >
                    <ContactPicker
                        hideLabel
                        variant="standard"
                        value={state.billingContact3ContactID} onSelectCallback={ c => onContactChange(c, 'billingContact3ContactID') }
                        errorHandler={errorHandler}
                        loadingHandler={loadingHandler}
                        successHandler={successHandler}
                    />
                </EditableSingleFieldElement>
            );
            case 'PlacementSignatory': return (
                <EditableSingleFieldElement format="custom" fieldTitle='Signatory' isError={showValidation && !Boolean(state.signatoryContactID)} errorMessage="Required" >
                    <ContactPicker
                        hideLabel
                        variant="standard"
                        value={state.signatoryContactID} onSelectCallback={ c => onContactChange(c, 'signatoryContactID') }
                        errorHandler={errorHandler}
                        loadingHandler={loadingHandler}
                        successHandler={successHandler}
                    />
                </EditableSingleFieldElement>
            );
            case 'PlacementClientRef': return <EditableSingleFieldElement fieldTitle='Client Ref' fieldValue={state.clientReference ?? ''} fieldName="clientReference" onChangeHandler={onStringFieldChange} />;
            case 'PlacementApprover1': return (
                <EditableSingleFieldElement format="custom" fieldTitle='Approver 1' >
                    <ContactPicker
                        hideLabel
                        variant="standard"
                        value={state.timesheetApprover1} onSelectCallback={ c => onContactChange(c, 'timesheetApprover1') }
                        errorHandler={errorHandler}
                        loadingHandler={loadingHandler}
                        successHandler={successHandler}
                    />
                </EditableSingleFieldElement>
            );
            case 'PlacementApprover2': return (
                <EditableSingleFieldElement format="custom" fieldTitle='Approver 2' >
                    <ContactPicker
                        hideLabel
                        variant="standard"
                        value={state.timesheetApprover2} onSelectCallback={ c => onContactChange(c, 'timesheetApprover2') }
                        errorHandler={errorHandler}
                        loadingHandler={loadingHandler}
                        successHandler={successHandler}
                    />
                </EditableSingleFieldElement>
            );
            case 'PlacementSummary': return (
                <EditableRichTextElement
                    title="Summary"
                    content={state.summary ?? ''}
                    isDarkTheme={theme.palette.mode === 'dark'}
                    editorRef={editorRef}
                    editorDirtyHandler={ setIsEditorDirty }
                />
            );
            case 'PlacementStatus': return <SingleFieldElement useEllipsisForLongValues labelWidthPercent={30} fontSize="1rem" fieldTitle="Status" fieldValue={getStatusNameById(state.placementStatusID)} />;
            case 'PlacementConsultant1': return (
                <EditableSingleFieldElement isError={showValidation && Boolean(state.consultantID1) && totalConsultantPercent !== 100} errorMessage="Check % values" format="custom" fieldTitle='Consultant 1' >
                    <Box display="flex">
                        <UserPicker
                            hideLabel
                            variant="standard"
                            userId={state.consultantID1}
                            appendToStart={consultantSpecialOptions}
                            sx={{ flex: '2 1 0', pr: '5px' }}
                            onSelect={ u => onConsultantChange(u, 'consultantID1') }
                        />
                        <TextField value={state.consultantPercentage1} name="consultantPercentage1" variant="standard" sx={{ flex: '1 1 0', pl: '5px' }} onChange={ onNumberFieldChange } InputProps={{ endAdornment: percentAdornment }} />
                    </Box>
                </EditableSingleFieldElement>
            );
            case 'PlacementConsultant2': return (
                <EditableSingleFieldElement isError={showValidation && Boolean(state.consultantID2) && totalConsultantPercent !== 100} errorMessage="Check % values" format="custom" fieldTitle='Consultant 2' >
                    <Box display="flex">
                        <UserPicker
                            hideLabel
                            variant="standard"
                            userId={state.consultantID2}
                            appendToStart={consultantSpecialOptions}
                            sx={{ flex: '2 1 0', pr: '5px' }}
                            onSelect={ u => onConsultantChange(u, 'consultantID2') }
                        />
                        <TextField value={state.consultantPercentage2} name="consultantPercentage2" variant="standard" sx={{ flex: '1 1 0', pl: '5px' }} onChange={ onNumberFieldChange } InputProps={{ endAdornment: percentAdornment }} />
                    </Box>
                </EditableSingleFieldElement>
            );
            case 'PlacementConsultant3': return (
                <EditableSingleFieldElement isError={showValidation && Boolean(state.consultantID3) && totalConsultantPercent !== 100} errorMessage="Check % values" format="custom" fieldTitle='Consultant 3' >
                    <Box display="flex">
                        <UserPicker
                            hideLabel
                            variant="standard"
                            userId={state.consultantID3}
                            appendToStart={consultantSpecialOptions}
                            sx={{ flex: '2 1 0', pr: '5px' }}
                            onSelect={ u => onConsultantChange(u, 'consultantID3') }
                        />
                        <TextField value={state.consultantPercentage3} name="consultantPercentage3" variant="standard" sx={{ flex: '1 1 0', pl: '5px' }} onChange={ onNumberFieldChange } InputProps={{ endAdornment: percentAdornment }} />
                    </Box>
                </EditableSingleFieldElement>
            );
            case 'PlacementConsultant4': return (
                <EditableSingleFieldElement isError={showValidation && Boolean(state.consultantID4) && totalConsultantPercent !== 100} errorMessage="Check % values" format="custom" fieldTitle='Consultant 4' >
                    <Box display="flex">
                        <UserPicker
                            hideLabel
                            variant="standard"
                            userId={state.consultantID4}
                            appendToStart={consultantSpecialOptions}
                            sx={{ flex: '2 1 0', pr: '5px' }}
                            onSelect={ u => onConsultantChange(u, 'consultantID4') }
                        />
                        <TextField value={state.consultantPercentage4} name="consultantPercentage4" variant="standard" sx={{ flex: '1 1 0', pl: '5px' }} onChange={ onNumberFieldChange } InputProps={{ endAdornment: percentAdornment }} />
                    </Box>
                </EditableSingleFieldElement>
            );
            case 'PlacementConsultant5': return (
                <EditableSingleFieldElement isError={showValidation && Boolean(state.consultantID5) && totalConsultantPercent !== 100} errorMessage="Check % values" format="custom" fieldTitle='Consultant 5' >
                    <Box display="flex">
                        <UserPicker
                            hideLabel
                            variant="standard"
                            userId={state.consultantID5}
                            appendToStart={consultantSpecialOptions}
                            sx={{ flex: '2 1 0', pr: '5px' }}
                            onSelect={ u => onConsultantChange(u, 'consultantID5') }
                        />
                        <TextField value={state.consultantPercentage5} name="consultantPercentage5" variant="standard" sx={{ flex: '1 1 0', pl: '5px' }} onChange={ onNumberFieldChange } InputProps={{ endAdornment: percentAdornment }} />
                    </Box>
                </EditableSingleFieldElement>
            );
            case 'PlacementSource':
                if (sourceOptions) {
                    return (
                        <EditableSingleFieldElement format="select" fieldTitle='Source' fieldValue={state.source ?? ''} fieldName="source" onChangeHandler={onStringFieldChange} >
                            {sourceOptions}
                        </EditableSingleFieldElement>
                    );
                }
                if (placementId === 0 && copyPlacementId === 0 && candidateIds && candidateIds.length > 1) {
                    return <SingleFieldElement useEllipsisForLongValues labelWidthPercent={30} fontSize="1rem" fieldTitle="Source" fieldValue={state.source} />;
                }
                return <EditableSingleFieldElement fieldTitle="Source" fieldValue={state.source ?? ''} fieldName="source" onChangeHandler={onStringFieldChange} />;
            case 'PlacementNetRevenue': return (
                <EditableSingleFieldElement
                    format="number"
                    fieldTitle={netRevenueLabel}
                    fieldName="netRevenue"
                    fieldValue={state.netRevenue.toString()}
                    onChangeHandler={onNumberFieldChange}
                />
            );
            case 'PlacementDate': return <EditableSingleFieldElement fieldTitle='Placement Date' fieldValue={state.placementDate} fieldName="placementDate" onDateChangeHandler={onDateFieldChange} format="date" />;
            case 'PlacementProbation': return (
                <EditableSingleFieldElement format="select" fieldTitle='Probation' fieldValue={state.probationLength.toString()} fieldName="probationLength" onChangeHandler={onNumericListFieldChange} >
                    {warrantyAndProbationOptions}
                </EditableSingleFieldElement>
            );
            case 'PlacementNotice': return (
                <EditableSingleFieldElement format="custom" fieldTitle='Notice'>
                    <Box display="flex">
                        <RWTextFieldComponent value={state.noticePeriod?.toString()} name="noticePeriod" variant="standard" placeholder="From" sxOptions={{ flex: '1 1 0', pr: '5px' }} onChange={ onNumberFieldChange } />
                        <TextField select value={state.noticePeriodUnits.toString()} name="noticePeriodUnits" variant="standard" sx={{ flex: '1 1 0', pl: '5px' }} onChange={ onNumericListFieldChange } >
                            {noticeOptions}
                        </TextField>
                    </Box>
                </EditableSingleFieldElement>
            );
            case 'PlacementPayFreq': return (
                <EditableSingleFieldElement format="select" fieldTitle='Pay Frequency' fieldValue={state.payFrequencyID.toString()} fieldName="payFrequencyID" onChangeHandler={onNumericListFieldChange} isError={showValidation && requiresPayFreqValidation && !Boolean(state.payFrequencyID)} errorMessage="Required" >
                    {payFreqOptions}
                </EditableSingleFieldElement>
            );
            case 'PlacementComplianceChecklist': return (
                <EditableSingleFieldElement format="custom" fieldTitle="Compliance Checklist">
                    <ComplianceChecklistPicker checklistId={state.complianceChecklistID} onSelect={onComplianceChecklistChange} variant="standard" />
                </EditableSingleFieldElement>
            );
            default:
                if (type.startsWith('CustomField')) {
                    const settings = customFieldsSettingsMap[type];
                    if (settings && settings.isEditable) {
                        let title = settings.title;
                        let predefinedValues = settings.usePredefinedValues && settings.values ? settings.values : null;
                        const { field, format } = UdfPlacementFieldMapObj[type as CustomFieldType];
                        const rawValue = state[field as keyof MinPlacement] as string | number | undefined;
                        const udfValue = (state[field as keyof MinPlacement] ?? '').toString();
                        const validationResult = IsValidCustomFieldValue(rawValue, format, settings.isMandatory ?? false);
                        const isValid = validationResult === "ok";
                        const errorMessage = validationErrorMessage(validationResult);
                        if (predefinedValues) {
                            return (
                                <EditableSingleFieldElement isError={showValidation && !isValid} errorMessage={errorMessage} format="select" fieldTitle={title} fieldValue={udfValue} fieldName={field} onChangeHandler={onStringFieldChange}>
                                    <MenuItem value="">None</MenuItem>
                                    {predefinedValues.map(v => <MenuItem key={v.value} value={v.value}>{v.value + (v.isDefault ? ' (Default)' : '')}</MenuItem>)}
                                </EditableSingleFieldElement>
                            );
                        }
                        else if (udfValue !== undefined && format === 'string') {
                            return (
                                <EditableSingleFieldElement
                                    isError={showValidation && !isValid}
                                    errorMessage={errorMessage}
                                    format={format}
                                    fieldTitle={title}
                                    fieldValue={udfValue}
                                    fieldName={field}
                                    multiline={settings.isMultiLine}
                                    onChangeHandler={onStringFieldChange}
                                />
                            );
                        }
                        else if (udfValue !== undefined && format === 'number') {
                            return (
                                <EditableSingleFieldElement
                                    isError={showValidation && !isValid}
                                    errorMessage={errorMessage}
                                    format={format}
                                    fieldTitle={title}
                                    fieldValue={udfValue}
                                    fieldName={field}
                                    onChangeHandler={onNumberFieldChange}
                                />
                            );
                        }
                        else if (format === 'date') {
                            return (
                                <EditableSingleFieldElement
                                    isError={showValidation && !isValid}
                                    errorMessage={errorMessage}
                                    format={format}
                                    fieldTitle={title}
                                    fieldValue={udfValue}
                                    fieldName={field}
                                    onDateChangeHandler={onDateFieldChange}
                                />
                            );
                        }
                        else if (format === 'datetime') {
                            return (
                                <EditableSingleFieldElement
                                    isError={showValidation && !isValid}
                                    errorMessage={errorMessage}
                                    format={format}
                                    fieldTitle={title}
                                    fieldValue={udfValue}
                                    fieldName={field}
                                    onDateChangeHandler={onDateTimeFieldChange}
                                />
                            );
                        }
                    }
                    else if (settings) return <SingleFieldElement labelWidthPercent={30} fontSize="1rem" fieldTitle={settings.title} fieldValue="[ Non Editable Custom Field ]" />;
                    else return <SingleFieldElement labelWidthPercent={30} fontSize="1rem" fieldTitle={type} fieldValue="[ Disabled Custom Field ]" />;
                }
                return <div>{id}</div>;

        }
    }, [state, showValidation, onStringFieldChange, placementId, copyPlacementId, candidateIds, singleCandidateName, isOptionalEndDate, onDateFieldChange, isSeperatePayBillDivision, requiresDivisionValidation, onCurrencyChange, onNumberFieldChange, onNumericListFieldChange, invoiceFeeValue, onFeeChange, ratesCalculatorLink, onChargeRateUnitsChange, onPayRateChange, isOnCostsDisabled, marginFieldValue, onDecimalFieldChange, requiresPaymentTypeValidation, onPayClientChange, errorHandler, loadingHandler, successHandler, onSiteChange, onClientChange, theme.palette.mode, totalConsultantPercent, sourceOptions, netRevenueLabel, requiresPayFreqValidation, onComplianceChecklistChange, onCandidateChange, onDivisionChange, onContactChange, onConsultantChange, customFieldsSettingsMap, onDateTimeFieldChange]);

    const layoutResizeStopHandler = useCallback(() => setScreenResizedControl(prev => !prev), []);

    useEffect(() => {
        if (screenResizedControl) {}
        const api = layoutRef.current;
        if (api && fetchedLayoutConfig) {
            const refreshTimeout = setTimeout(() => {
                api.refresh();
                api.refresh();
            }, 250);
            return () => clearTimeout(refreshTimeout);
        }
    }, [fetchedLayoutConfig, screenResizedControl]);

    useEffect(() => {
        window.addEventListener('resize', layoutResizeStopHandler);
        return () => window.removeEventListener('resize', layoutResizeStopHandler);
    }, [layoutResizeStopHandler]);

    const renderLayout = useCallback(() => {
        if (!fetchedLayoutConfig) return <></>;
        
        let sorted = [...elements];
        sorted.sort((a, b) => {
            if (a.col === b.col) return a.row < b.row ? -1 : 1;
            else return a.col < b.col ? -1 : 1;
        });

        const mediaQuery = mediaQueryMaxWidth ? `max-width: ${mediaQueryMaxWidth}` : undefined;
        return (
            <DashboardLayoutComponent
                cellSpacing={cellSpacing}
                columns={columns}
                cellAspectRatio={30 / 2}
                allowDragging={false}
                allowResizing={false}
                resizeStop={ layoutResizeStopHandler }
                ref={l => layoutRef.current = l}
                mediaQuery={mediaQuery}
            >
                {sorted.map(e => (
                    <PanelWrapper
                        key={e.id}
                        id={e.id}
                        col={e.col}
                        row={e.row}
                        sizeX={e.sizeX}
                        sizeY={e.sizeY}
                        minSizeX={e.minSizeX}
                        minSizeY={e.minSizeY}
                        maxSizeX={e.maxSizeX}
                        maxSizeY={e.maxSizeY}
                        resizeControl={screenResizedControl}
                        resizeIconColor={theme.palette.text.disabled}
                        gapX={gapX}
                        gapY={gapY}
                        unitWidth={unitWidth}
                        unitHeight={unitHeight}
                    >
                        {renderElement(e.id, e.type)}
                    </PanelWrapper>
                ))}
            </DashboardLayoutComponent>
        );
    }, [elements, fetchedLayoutConfig, theme.palette.text.disabled, screenResizedControl, layoutResizeStopHandler, renderElement]);

    return (
        <>
            {unsavedChangesDialog}
            { !hasBlockedRoute && redirectPlacementId !== 0 && savedChanges && !hasChanges && !isEditorDirty && <Navigate to={`/placements/${redirectPlacementId}`} /> }
            { !hasBlockedRoute && placementId === 0 && candidateIds && candidateIds.length > 1 && savedChanges && !hasChanges && !isEditorDirty && <Navigate to={`/jobs/${jobId}?tab=JOBS_Placements`} /> }
            <ConfirmationDialog
                message={`You have entered ${state.hoursPerDay} Hours Per Day. Are you sure this is correct?`}
                title="Placement Validation"
                open={showConfirmHoursPerDay}
                onClose={ () => setShowConfirmHoursPerDay(false) }
                onContinue={ saveChangesHandler }
            />
            <Box p="10px" height="100%">
                <div className="control-section">
                    { renderLayout() }
                </div>
            </Box>
        </>
    );
}
