import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import SlowTextFieldWrapper from "components/SlowTextFieldWrapper";
import React, { useCallback, useEffect, useMemo } from "react";
import { JobBoardCountriesOptions, JobBoardCountriesStatesMap } from "util/Definitions/Adverts/JobBoards";

interface Props {
    jobBoardId: number,
    advertId: number,
    attributes?: Record<string, string>,
    advertRecordAttributes?: Record<string, string>,
    attributeChangeHandler?: (jobBoardId: number, key: string, value: string) => void
}

export default function LinkedInBasicJobsEdit({ jobBoardId, advertId, attributes, advertRecordAttributes, attributeChangeHandler }: Props) {

    const atts = useMemo<Record<string, string>>(() => {
        if (attributes) return attributes;
        return {};
    }, [attributes]);

    const recordAtts = useMemo<Record<string, string>>(() => {
        if (advertRecordAttributes) return advertRecordAttributes;
        return {};
    }, [advertRecordAttributes]);
    
    const jobFunction = useMemo(() => atts.JobFunction ?? '', [atts.JobFunction]);
    const jobIndustryCode = useMemo(() => atts.JobIndustryCode ?? '', [atts.JobIndustryCode]);
    const jobType = useMemo(() => atts.JobType ?? '', [atts.JobType]);
    const jobCountry = useMemo(() => atts.JobCountry ?? '', [atts.JobCountry]);
    const jobState = useMemo(() => atts.JobState ?? '', [atts.JobState]);
    const jobCity = useMemo(() => atts.JobCity ?? '', [atts.JobCity]);
    const jobExperienceLevel = useMemo(() => atts.JobExperienceLevel ?? '', [atts.JobExperienceLevel]);
    const workplaceType = useMemo(() => atts.WorkplaceType ?? '', [atts.WorkplaceType]);
    
    const salaryType = useMemo(() => recordAtts.salaryType ?? '', [recordAtts.salaryType]);
    const salaryFrom = useMemo(() => recordAtts.salaryFrom ?? '', [recordAtts.salaryFrom]);
    const salaryTo = useMemo(() => recordAtts.salaryTo ?? '', [recordAtts.salaryTo]);
    const salaryCurrencyCode = useMemo(() => recordAtts.currencyCode ?? '', [recordAtts.currencyCode]);
    const salaryCurrencyName = useMemo(() => recordAtts.currencyName ?? '', [recordAtts.currencyName]);

    useEffect(() => attributeChangeHandler && attributeChangeHandler(jobBoardId, 'SalaryType', 'BASE_SALARY'), [attributeChangeHandler, jobBoardId]);
    useEffect(() => attributeChangeHandler && attributeChangeHandler(jobBoardId, 'SalaryPeriod', salaryType), [attributeChangeHandler, salaryType, jobBoardId]);
    useEffect(() => attributeChangeHandler && attributeChangeHandler(jobBoardId, 'SalaryMinValue', salaryFrom), [attributeChangeHandler, salaryFrom, jobBoardId]);
    useEffect(() => attributeChangeHandler && attributeChangeHandler(jobBoardId, 'SalaryMaxValue', salaryTo), [attributeChangeHandler, salaryTo, jobBoardId]);
    useEffect(() => attributeChangeHandler && attributeChangeHandler(jobBoardId, 'SalaryCurrency', salaryCurrencyCode), [attributeChangeHandler, salaryCurrencyCode, jobBoardId]);

    const renderedCountries = useMemo(() => {
        return JobBoardCountriesOptions.map(o => <MenuItem key={o.id} value={o.id}>{o.name}</MenuItem>);
    }, []);

    const renderedStates = useMemo(() => {
        if (jobCountry) {
            const states = JobBoardCountriesStatesMap[jobCountry];
            if (states && states.length > 0) return states.map(o => <MenuItem key={o.id} value={o.id}>{o.name}</MenuItem>);
        }
        return [];
    }, [jobCountry]);

    const localChangeHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        if (name === 'JobCountry') attributeChangeHandler && attributeChangeHandler(jobBoardId, 'JobState', '');
        attributeChangeHandler && attributeChangeHandler(jobBoardId, name, value);
    }, [attributeChangeHandler, jobBoardId]);

    const jobCityChangeHandler = useCallback((value: string) => {
        attributeChangeHandler && attributeChangeHandler(jobBoardId, 'JobCity', value);
    }, [attributeChangeHandler, jobBoardId]);

    return (
        <Box display="flex" gap={2} pt={2}>
            <Box display="flex" flexDirection="column" gap={2} width="50%">
                <TextField select label="Work Function" name="JobFunction" value={jobFunction} onChange={localChangeHandler}>
                    <MenuItem value=""></MenuItem>
                    <MenuItem value="acct">Accounting / Auditing</MenuItem>
                    <MenuItem value="adm">Administrative</MenuItem>
                    <MenuItem value="advr">Advertising</MenuItem>
                    <MenuItem value="anls">Analyst</MenuItem>
                    <MenuItem value="art">Art / Creative</MenuItem>
                    <MenuItem value="bd">Business Development</MenuItem>
                    <MenuItem value="cnsl">Consulting</MenuItem>
                    <MenuItem value="cust">Customer Service</MenuItem>
                    <MenuItem value="dist">Distribution</MenuItem>
                    <MenuItem value="dsgn">Design</MenuItem>
                    <MenuItem value="edu">Education</MenuItem>
                    <MenuItem value="eng">Engineering</MenuItem>
                    <MenuItem value="fin">Finance</MenuItem>
                    <MenuItem value="genb">General Business</MenuItem>
                    <MenuItem value="hcpr">HealthCare Provider</MenuItem>
                    <MenuItem value="hr">Human Resources</MenuItem>
                    <MenuItem value="it">Information Technology</MenuItem>
                    <MenuItem value="lgl">Legal</MenuItem>
                    <MenuItem value="mgmt">Management</MenuItem>
                    <MenuItem value="mnfc">Manufacturing</MenuItem>
                    <MenuItem value="mrkt">Marketing</MenuItem>
                    <MenuItem value="othr">Other</MenuItem>
                    <MenuItem value="pr">Public Relations</MenuItem>
                    <MenuItem value="prch">Purchasing</MenuItem>
                    <MenuItem value="prdm">Product Management</MenuItem>
                    <MenuItem value="prjm">Project Management</MenuItem>
                    <MenuItem value="prod">Production</MenuItem>
                    <MenuItem value="qa">QualityAssurance</MenuItem>
                    <MenuItem value="rsch">Research</MenuItem>
                    <MenuItem value="sale">Sales</MenuItem>
                    <MenuItem value="sci">Science</MenuItem>
                    <MenuItem value="stra">Strategy / Planning</MenuItem>
                    <MenuItem value="supl">Supply Chain</MenuItem>
                    <MenuItem value="trng">Training</MenuItem>
                    <MenuItem value="wrt">Writing / Editing</MenuItem>
                </TextField>
                <TextField select label="Industry" name="JobIndustryCode" value={jobIndustryCode} onChange={localChangeHandler}>
                    <MenuItem value=""></MenuItem>
                    <MenuItem value="47">Accounting</MenuItem>
                    <MenuItem value="94">Airlines/Aviation</MenuItem>
                    <MenuItem value="120">Alternative Dispute Resolution</MenuItem>
                    <MenuItem value="125">Alternative Medicine</MenuItem>
                    <MenuItem value="127">Animation</MenuItem>
                    <MenuItem value="19">Apparel & Fashion</MenuItem>
                    <MenuItem value="50">Architecture & Planning</MenuItem>
                    <MenuItem value="111">Arts and Crafts</MenuItem>
                    <MenuItem value="53">Automotive</MenuItem>
                    <MenuItem value="52">Aviation & Aerospace</MenuItem>
                    <MenuItem value="41">Banking</MenuItem>
                    <MenuItem value="12">Biotechnology</MenuItem>
                    <MenuItem value="36">Broadcast Media</MenuItem>
                    <MenuItem value="49">Building Materials</MenuItem>
                    <MenuItem value="138">Business Supplies and Equipment</MenuItem>
                    <MenuItem value="129">Capital Markets</MenuItem>
                    <MenuItem value="54">Chemicals</MenuItem>
                    <MenuItem value="90">Civic & Social Organization</MenuItem>
                    <MenuItem value="51">Civil Engineering</MenuItem>
                    <MenuItem value="128">Commercial Real Estate</MenuItem>
                    <MenuItem value="118">Computer & Network Security</MenuItem>
                    <MenuItem value="109">Computer Games</MenuItem>
                    <MenuItem value="3">Computer Hardware</MenuItem>
                    <MenuItem value="5">Computer Networking</MenuItem>
                    <MenuItem value="4">Computer Software</MenuItem>
                    <MenuItem value="48">Construction</MenuItem>
                    <MenuItem value="24">Consumer Electronics</MenuItem>
                    <MenuItem value="25">Consumer Goods</MenuItem>
                    <MenuItem value="91">Consumer Services</MenuItem>
                    <MenuItem value="18">Cosmetics</MenuItem>
                    <MenuItem value="65">Dairy</MenuItem>
                    <MenuItem value="1">Defense & Space</MenuItem>
                    <MenuItem value="99">Design</MenuItem>
                    <MenuItem value="69">Education Management</MenuItem>
                    <MenuItem value="132">E-Learning</MenuItem>
                    <MenuItem value="112">Electrical/Electronic Manufacturing</MenuItem>
                    <MenuItem value="28">Entertainment</MenuItem>
                    <MenuItem value="86">Environmental Services</MenuItem>
                    <MenuItem value="110">Events Services</MenuItem>
                    <MenuItem value="76">Executive Office</MenuItem>
                    <MenuItem value="122">Facilities Services</MenuItem>
                    <MenuItem value="63">Farming</MenuItem>
                    <MenuItem value="43">Financial Services</MenuItem>
                    <MenuItem value="38">Fine Art</MenuItem>
                    <MenuItem value="66">Fishery</MenuItem>
                    <MenuItem value="34">Food & Beverages</MenuItem>
                    <MenuItem value="23">Food Production</MenuItem>
                    <MenuItem value="101">Fund-Raising</MenuItem>
                    <MenuItem value="26">Furniture</MenuItem>
                    <MenuItem value="29">Gambling & Casinos</MenuItem>
                    <MenuItem value="145">Glass, Ceramics & Concrete</MenuItem>
                    <MenuItem value="75">Government Administration</MenuItem>
                    <MenuItem value="148">Government Relations</MenuItem>
                    <MenuItem value="140">Graphic Design</MenuItem>
                    <MenuItem value="124">Health, Wellness and Fitness</MenuItem>
                    <MenuItem value="68">Higher Education</MenuItem>
                    <MenuItem value="14">Hospital & Health Care</MenuItem>
                    <MenuItem value="31">Hospitality</MenuItem>
                    <MenuItem value="137">Human Resources</MenuItem>
                    <MenuItem value="134">Import and Export</MenuItem>
                    <MenuItem value="88">Individual & Family Services</MenuItem>
                    <MenuItem value="147">Industrial Automation</MenuItem>
                    <MenuItem value="84">Information Services</MenuItem>
                    <MenuItem value="96">Information Technology and Services</MenuItem>
                    <MenuItem value="42">Insurance</MenuItem>
                    <MenuItem value="74">International Affairs</MenuItem>
                    <MenuItem value="141">International Trade and Development</MenuItem>
                    <MenuItem value="6">Internet</MenuItem>
                    <MenuItem value="45">Investment Banking</MenuItem>
                    <MenuItem value="46">Investment Management</MenuItem>
                    <MenuItem value="73">Judiciary</MenuItem>
                    <MenuItem value="77">Law Enforcement</MenuItem>
                    <MenuItem value="9">Law Practice</MenuItem>
                    <MenuItem value="10">Legal Services</MenuItem>
                    <MenuItem value="72">Legislative Office</MenuItem>
                    <MenuItem value="30">Leisure, Travel & Tourism</MenuItem>
                    <MenuItem value="85">Libraries</MenuItem>
                    <MenuItem value="116">Logistics and Supply Chain</MenuItem>
                    <MenuItem value="143">Luxury Goods & Jewelry</MenuItem>
                    <MenuItem value="55">Machinery</MenuItem>
                    <MenuItem value="11">Management Consulting</MenuItem>
                    <MenuItem value="95">Maritime</MenuItem>
                    <MenuItem value="97">Market Research</MenuItem>
                    <MenuItem value="80">Marketing and Advertising</MenuItem>
                    <MenuItem value="135">Mechanical or Industrial Engineering</MenuItem>
                    <MenuItem value="126">Media Production</MenuItem>
                    <MenuItem value="17">Medical Devices</MenuItem>
                    <MenuItem value="13">Medical Practice</MenuItem>
                    <MenuItem value="139">Mental Health Care</MenuItem>
                    <MenuItem value="71">Military</MenuItem>
                    <MenuItem value="56">Mining & Metals</MenuItem>
                    <MenuItem value="35">Motion Pictures and Film</MenuItem>
                    <MenuItem value="37">Museums and Institutions</MenuItem>
                    <MenuItem value="115">Music</MenuItem>
                    <MenuItem value="114">Nanotechnology</MenuItem>
                    <MenuItem value="81">Newspapers</MenuItem>
                    <MenuItem value="100">Non-Profit Organization Management</MenuItem>
                    <MenuItem value="57">Oil & Energy</MenuItem>
                    <MenuItem value="113">Online Media</MenuItem>
                    <MenuItem value="123">Outsourcing/Offshoring</MenuItem>
                    <MenuItem value="87">Package/Freight Delivery</MenuItem>
                    <MenuItem value="146">Packaging and Containers</MenuItem>
                    <MenuItem value="61">Paper & Forest Products</MenuItem>
                    <MenuItem value="39">Performing Arts</MenuItem>
                    <MenuItem value="15">Pharmaceuticals</MenuItem>
                    <MenuItem value="131">Philanthropy</MenuItem>
                    <MenuItem value="136">Photography</MenuItem>
                    <MenuItem value="117">Plastics</MenuItem>
                    <MenuItem value="107">Political Organization</MenuItem>
                    <MenuItem value="67">Primary/Secondary Education</MenuItem>
                    <MenuItem value="83">Printing</MenuItem>
                    <MenuItem value="105">Professional Training & Coaching</MenuItem>
                    <MenuItem value="102">Program Development</MenuItem>
                    <MenuItem value="79">Public Policy</MenuItem>
                    <MenuItem value="98">Public Relations and Communications</MenuItem>
                    <MenuItem value="78">Public Safety</MenuItem>
                    <MenuItem value="82">Publishing</MenuItem>
                    <MenuItem value="62">Railroad Manufacture</MenuItem>
                    <MenuItem value="64">Ranching</MenuItem>
                    <MenuItem value="44">Real Estate</MenuItem>
                    <MenuItem value="40">Recreational Facilities and Services</MenuItem>
                    <MenuItem value="89">Religious Institutions</MenuItem>
                    <MenuItem value="144">Renewables & Environment</MenuItem>
                    <MenuItem value="70">Research</MenuItem>
                    <MenuItem value="32">Restaurants</MenuItem>
                    <MenuItem value="27">Retail</MenuItem>
                    <MenuItem value="121">Security and Investigations</MenuItem>
                    <MenuItem value="7">Semiconductors</MenuItem>
                    <MenuItem value="58">Shipbuilding</MenuItem>
                    <MenuItem value="20">Sporting Goods</MenuItem>
                    <MenuItem value="33">Sports</MenuItem>
                    <MenuItem value="104">Staffing and Recruiting</MenuItem>
                    <MenuItem value="22">Supermarkets</MenuItem>
                    <MenuItem value="8">Telecommunications</MenuItem>
                    <MenuItem value="60">Textiles</MenuItem>
                    <MenuItem value="130">Think Tanks</MenuItem>
                    <MenuItem value="21">Tobacco</MenuItem>
                    <MenuItem value="108">Translation and Localization</MenuItem>
                    <MenuItem value="92">Transportation/Trucking/Railroad</MenuItem>
                    <MenuItem value="59">Utilities</MenuItem>
                    <MenuItem value="106">Venture Capital & Private Equity</MenuItem>
                    <MenuItem value="16">Veterinary</MenuItem>
                    <MenuItem value="93">Warehousing</MenuItem>
                    <MenuItem value="133">Wholesale</MenuItem>
                    <MenuItem value="142">Wine and Spirits</MenuItem>
                    <MenuItem value="119">Wireless</MenuItem>
                    <MenuItem value="103">Writing and Editing</MenuItem>
                </TextField>
                <TextField select label="Job Type" name="JobType" value={jobType} onChange={localChangeHandler}>
                    <MenuItem value=""></MenuItem>
                    <MenuItem value="FULL_TIME">Full time</MenuItem>
                    <MenuItem value="PART_TIME">Part time</MenuItem>
                    <MenuItem value="CONTRACT">Contract</MenuItem>
                    <MenuItem value="TEMPORARY">Temporary</MenuItem>
                    <MenuItem value="OTHER">Other</MenuItem>
                    <MenuItem value="VOLUNTEER">Volunteer</MenuItem>
                    <MenuItem value="INTERNSHIP">Internship</MenuItem>
                </TextField>
                <TextField select label="Country" name="JobCountry" value={jobCountry} onChange={localChangeHandler}>
                    <MenuItem value=""></MenuItem>
                    {renderedCountries}
                </TextField>
                <TextField select label="State" name="JobState" value={jobState} onChange={localChangeHandler}>
                    <MenuItem value=""></MenuItem>
                    {renderedStates}
                </TextField>
                <SlowTextFieldWrapper label="City" value={jobCity} onChange={jobCityChangeHandler} />
            </Box>
            <Box display="flex" flexDirection="column" gap={2} width="50%">
                <TextField disabled label="Salary Type" value={salaryType} />
                <Box display="flex" gap={2}>
                    <TextField disabled label="Salary From" sx={{ flex: '1 1 0' }} value={salaryFrom} />
                    <TextField disabled label="Salary To" sx={{ flex: '1 1 0' }} value={salaryTo} />
                </Box>
                <TextField disabled label="Salary Currency" value={salaryCurrencyName} />
                <TextField select label="Experience Level" name="JobExperienceLevel" value={jobExperienceLevel} onChange={localChangeHandler}>
                    <MenuItem value=""></MenuItem>
                    <MenuItem value="NOT_APPLICABLE">Not applicable</MenuItem>
                    <MenuItem value="INTERNSHIP">Internship</MenuItem>
                    <MenuItem value="ENTRY_LEVEL">Entry level</MenuItem>
                    <MenuItem value="ASSOCIATE">Associate</MenuItem>
                    <MenuItem value="MID_SENIOR_LEVEL">Mid senior level</MenuItem>
                    <MenuItem value="DIRECTOR">Director</MenuItem>
                    <MenuItem value="EXECUTIVE">Executive</MenuItem>
                </TextField>
                <TextField select label="Workplace Type" name="WorkplaceType" value={workplaceType} onChange={localChangeHandler}>
                    <MenuItem value=""></MenuItem>
                    <MenuItem value="On-site">On Site</MenuItem>
                    <MenuItem value="Hybrid">Hybrid</MenuItem>
                    <MenuItem value="Remote">Remote</MenuItem>
                </TextField>
            </Box>
        </Box>
    );
}