import Box from "@mui/material/Box";
import React, { useMemo } from "react";
import JobBoardViewField from "./JobBoardViewField";

interface Props {
    jobBoardId: number,
    advertId: number,
    attributes?: Record<string, string>,
    advertRecordAttributes?: Record<string, string>,
}

const typeMap: Record<string, string> = {
    "P": "Permanent",
    "C": "Contract",
    "B": "Permanent/Contract",
    "S": "Part Time/Temp/Seasonal",
};

const industryMap: Record<string, string> = {
    "13": "Advertising, Media & Entertainment",
    "26": "Agriculture, Forestry & Fishing",
    "09": "Call Centre & Customer Services",
    "24": "Community & Sport",
    "11": "Construction & Civil Engineering",
    "25": "Consulting & Corporate Strategy",
    "18": "Defence, Military & Armed Forces",
    "20": "Education",
    "03": "Engineering",
    "02": "Finance, Accounting & Banking",
    "14": "Food & Hospitality",
    "08": "Healthcare & Medical",
    "10": "HR & Recruitment",
    "01": "IT & Telecommunications",
    "07": "Legal",
    "21": "Manufacturing",
    "15": "Mining, Energy, Oil & Gas",
    "06": "Office & Administration",
    "27": "Real Estate & Property",
    "17": "Retail & Consumer Products",
    "04": "Sales & Marketing",
    "16": "Science, Biotech & Pharmaceuticals",
    "23": "Self-Employment",
    "22": "Trades & Services",
    "12": "Transport & Logistics",
    "19": "Travel & Tourism",
    "28": "Other",
};

const featuredMap: Record<string, string> = {
    "off": "No",
    "on": "Yes",
};

export default function JobServeView({ jobBoardId, advertId, attributes, advertRecordAttributes }: Props) {
    const atts = useMemo<Record<string, string>>(() => {
        if (attributes) return attributes;
        return {};
    }, [attributes]);

    const recordAtts = useMemo<Record<string, string>>(() => {
        if (advertRecordAttributes) return advertRecordAttributes;
        return {};
    }, [advertRecordAttributes]);

    const category1 = useMemo(() => atts.Category1 ?? '0', [atts.Category1]);
    const category2 = useMemo(() => atts.Category2 ?? '0', [atts.Category2]);
    const category3 = useMemo(() => atts.Category3 ?? '0', [atts.Category3]);
    const type = useMemo(() => atts.Type ?? '0', [atts.Type]);
    const industry = useMemo(() => atts.Industry ?? '0', [atts.Industry]);
    const featured = useMemo(() => atts.Featured ?? 'off', [atts.Featured]);

    const country = useMemo(() => recordAtts.country ?? '', [recordAtts.country]);
    const region = useMemo(() => recordAtts.region ?? '', [recordAtts.region]);
    const location = useMemo(() => recordAtts.location ?? '', [recordAtts.location]);

    return (
        <Box display="flex" gap={2} pt={2}>
            <Box display="flex" flexDirection="column" gap={2} width="50%">
                <JobBoardViewField label="Category 1" value={category1 === '0' ? '' : category1} />
                <JobBoardViewField label="Category 2" value={category2 === '0' ? '' : category2} />
                <JobBoardViewField label="Category 3" value={category3 === '0' ? '' : category3} />
                <JobBoardViewField label="Country" value={country} />
                <JobBoardViewField label="Region" value={region} />
                <JobBoardViewField label="Location" value={location} />
            </Box>
            <Box display="flex" flexDirection="column" gap={2} width="50%">
                <JobBoardViewField label="Type" value={typeMap[type] ?? ''} />
                <JobBoardViewField label="Industry" value={industryMap[industry] ?? ''} />
                <JobBoardViewField label="Featured?" value={featuredMap[featured] ?? ''} />
            </Box>
        </Box>
    );
}