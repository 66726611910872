import React, { useEffect, useState } from "react";
import PageContentLayout from "../../../layouts/PageContentLayout";
import JobCandidateStatusesGrid from "../../../components/Grids/JobCandidateStatusesGrid";
import { JobCandidateStatusRequest, JobCandidateStatusResponse } from "common/models/Jobs/Job";
import { AddJobCandidateStatus, GetJobCandidateStatuses, UpdateJobCandidateStatus, UpdateJobCandidateStatusOrder } from "services/ConfigurationService";
import { NameIdObj } from "common/models/GenericTypes";
import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import { Button } from "@mui/material";
import JobCandidateStatusDialog from "components/Dialogs/Jobs/JobCandidateStatusDialog";
import OrderDialog from "components/Dialogs/OrderDialog";

interface Props {
    setSummaryBar?: (summaryBar: JSX.Element) => void
}


export default function JobsCandidateStatusesAdmin({ setSummaryBar }: Props) {
    const [isLoading, setIsLoading] = useState(false);
    const [openOrderDialog, setOpenOrderDialog] = useState(false);
    const [openAddEditDialog, setAddEditDialog] = useState(false);
    const [rows, setRows] = useState<JobCandidateStatusResponse[]>([]);
    const [selectedJobCandidateStatus, setSelectedJobCandidateStatus] = useState<JobCandidateStatusResponse | null>(null);


    useEffect(() => {
        const action = <>
            <Button variant="contained" color="secondary" sx={{mr: '10px'}} onClick={() => {setOpenOrderDialog(true)}}>Order Statuses</Button>
            <Button variant="contained" color="primary"  onClick={() => {setAddEditDialog(true); setSelectedJobCandidateStatus(null)}}>Create</Button>
        </>;
        const summaryBar = <TitleAndActionSummaryBar title='Configuration > Jobs > Candidate Workflow Statuses' browserTabTitle="Jobs > Configuration"action={action} />;
        setSummaryBar && setSummaryBar(summaryBar);
    }, [setSummaryBar, rows,openOrderDialog]);

    useEffect(() => {
        const getRows = async () => {
            isLoading && setIsLoading(true);
            let res: JobCandidateStatusResponse[] | null = [];
                res = await GetJobCandidateStatuses();
            if (res) {
                setRows(res);
            }
            isLoading && setIsLoading(false);
        };
        
        getRows();
    }, [setIsLoading, isLoading]);

    const handleJobCandidateStatusSave = async (jobCandidateStatus: JobCandidateStatusResponse) => {
        if(jobCandidateStatus.statusOrder == -1) {
            jobCandidateStatus.statusOrder = rows.length-1;
        }

        const candidateStatus =  {
            enabled: jobCandidateStatus.enabled,
            id: jobCandidateStatus.id,
            jobCandidateKanbanGroupId: jobCandidateStatus.jobCandidateKanbanGroupId,
            jobStageId: jobCandidateStatus.jobStageId,
            name: jobCandidateStatus.name,
            statusOrder: jobCandidateStatus.statusOrder,
        } as  JobCandidateStatusRequest

        if(jobCandidateStatus.id == 0) {
        
            const response = await AddJobCandidateStatus(candidateStatus);
            if(jobCandidateStatus.jobStageId == 0) {
                jobCandidateStatus.jobStage = "None";
            }
           if(response) {
             jobCandidateStatus.id = response;
            setRows(prev => {
                let tmp = [...prev];
                tmp.push(jobCandidateStatus);
                return tmp;
            });
           }
        }else {
            const response = await UpdateJobCandidateStatus(candidateStatus);
            if(response) {
                setRows(prev => {
                    let tmp = [...prev];
                    const stage = tmp.find(s=>s.id == jobCandidateStatus.id);
                    if(stage) {
                        let stageIndex = tmp.indexOf(stage);
                        tmp[stageIndex].name = jobCandidateStatus.name;
                        tmp[stageIndex].enabled = jobCandidateStatus.enabled;
                        tmp[stageIndex].jobCandidateKanbanGroup = jobCandidateStatus.jobCandidateKanbanGroup;
                        tmp[stageIndex].jobCandidateKanbanGroupId = jobCandidateStatus.jobCandidateKanbanGroupId;
                        tmp[stageIndex].jobStage = jobCandidateStatus.jobStage;
                        tmp[stageIndex].jobStageId = jobCandidateStatus.jobStageId;
                    }
                    return tmp;
                });
            }
        }
        setAddEditDialog(false);
        setSelectedJobCandidateStatus(null);
    }

    const handlestatusOrder = async (orderItems: NameIdObj[]) => {
        setRows(prev => {
            let tmp = [...prev];
            orderItems.forEach(o => {
                let stage = tmp.find(t=>t.id == o.id);
                if(stage) {
                    let indexOfStage = tmp.indexOf(stage);
                    stage.statusOrder = orderItems.indexOf(o);
                    tmp[indexOfStage] = stage;
                }
            });
            return tmp.sort((a, b) => a.statusOrder - b.statusOrder);
        });

         const result = await UpdateJobCandidateStatusOrder(orderItems.map(o=>o.id));
         if(result) {
            setOpenOrderDialog(false)
         }
    }

    const handleCandidateSelected = (status: JobCandidateStatusResponse) => {
        setSelectedJobCandidateStatus(null);
        setSelectedJobCandidateStatus(status);
        setAddEditDialog(true);
    }


    return (
    <>
        <JobCandidateStatusDialog 
                       open={openAddEditDialog}
                       jobCandidateStatus={selectedJobCandidateStatus}
                       closeHandler={() => setAddEditDialog(false)}
                       submitHandler={handleJobCandidateStatusSave}/>

        <OrderDialog
                    orderItems={rows.map(r=> {
                            return { id: r.id, name: r.name} as NameIdObj;
                        })}
                    title="Order Candidate Workflow Statuses"
                    open={openOrderDialog}
                    closeHandler={() => setOpenOrderDialog(false)}
                    submitHandler={handlestatusOrder}/>

     <PageContentLayout showLoading={isLoading} title="Candidate Workflow Statuses">
      <JobCandidateStatusesGrid 
                    
                    gridName="job-candidate-statuses"
                    handleCandidateSelected={handleCandidateSelected} 
                    candidateWorkflowStatuses={rows}/>
    </PageContentLayout>
    </>
   );
}