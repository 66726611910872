import React, { useState, useEffect, useCallback } from "react";
import { NavLink } from "react-router-dom";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import useTheme from "@mui/material/styles/useTheme";

import NavBarSubmenuContainer from './NavBarSubmenuContainer';
import { TopBarRoute } from "common/models/TopBarTypes";

import './NavBar.css'
import CreateOpportunityHandlerDialog from "components/Dialogs/Opportunities/CreateOpportunityHandlerDialog";

interface Props {
    module: TopBarRoute,
    moduleIndex: number,
    selectedModule: number | null,
    changeSelectedModule: (selectedModule: number | null) => void
}

const hideGlobalLookupListEvent = new CustomEvent('hideGlobalLookupList');

export default function NavBarModuleRoot({ module, moduleIndex, selectedModule, changeSelectedModule }: Props): JSX.Element {
    const [moduleAnchor, setModuleAnchor] = useState<HTMLElement | null>(null);
    const [showCreateOpportunityDialog, setShowCreateOpportunityDialog] = useState(false);
    const [isRootHovered, setIsRootHovered] = useState(false);
    const theme = useTheme();

    const handleHover = useCallback((e: React.MouseEvent<HTMLElement>) => {
        setIsRootHovered(true);
        document.dispatchEvent(hideGlobalLookupListEvent);
        changeSelectedModule(moduleIndex);
        setModuleAnchor(e.currentTarget);
    }, [changeSelectedModule, moduleIndex]);

    const onRootExit = useCallback(() => {
        setIsRootHovered(false);
    }, []);

    const handleCloseSubmodule = useCallback(() => {
        changeSelectedModule(null);
        setModuleAnchor(null);
    }, [changeSelectedModule]);

    useEffect(() => {
        document.addEventListener('hideNavSubMenu', handleCloseSubmodule);
        return () => document.removeEventListener('hideNavSubMenu', handleCloseSubmodule);
    }, [handleCloseSubmodule]);

    const { allow } = module;
    if (allow && !allow()) return <></>;

    const isSelected = moduleIndex === selectedModule;
    const showSubmenu = isSelected && Boolean(moduleAnchor) && Boolean(module.options);

    return (
        <>
            <CreateOpportunityHandlerDialog
                open={showCreateOpportunityDialog}
                closeHandler={() => setShowCreateOpportunityDialog(false)}
            />
            <ListItem disablePadding
                sx={{ bgcolor: (isSelected ? 'primary.light' : 'inherit'), cursor: 'pointer' }}
                onMouseEnter={handleHover}
                onMouseLeave={onRootExit}
            >
                <NavLink
                    onClick={handleCloseSubmodule}
                    to={module.path}
                    className="navbar-module-link"
                    style={ ({ isActive }) => ({ backgroundColor: isActive ? theme.palette.primary.light : 'inherit' })}
                >
                    <ListItemText primaryTypographyProps={{ fontSize: '0.885rem' }}>{module.text}</ListItemText>
                </NavLink>
            </ListItem>
            {showSubmenu &&
                <NavBarSubmenuContainer
                    anchor={moduleAnchor}
                    options={module.options}
                    isRootHovered={isRootHovered}
                    closeSubmodule={handleCloseSubmodule}
                    showCreateOpportunityDialogHandler={module.text === 'Opportunities' ? () => setShowCreateOpportunityDialog(true) : undefined }
                />
            }
        </>
    );
}
