import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { GridColDef } from "@mui/x-data-grid-premium";
import { BasicFilter } from "common/models/Analytics/AnalyticsFilterData";
import { AveragePermFeesRecord } from "common/models/Analytics/BusinessIntelligence";
import GridWithStateWrapper from "components/GridWidthStateWrapper";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Bar, CartesianGrid, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { GetPlacementsAveragePermFees } from "services/BusinessIntelligence";

interface Props {
    filterData: BasicFilter,
    graphType?: 'table' | 'graph',
    loadingHandler?: (isLoading: boolean) => void
}

interface AveragePermFeesRecordWithId extends AveragePermFeesRecord {
    id: number,
}

const gridName = 'analytics/placements/average-fees/perm-fees';

export default function PlacementsAveragePermFeesContent({ filterData, graphType = 'graph', loadingHandler }: Props) {
    const [rows, setRows] = useState<AveragePermFeesRecordWithId[]>([]);
    const t = useTheme();

    useEffect(() => {
        const getData = async () => {
            loadingHandler && loadingHandler(true);
            const res = await GetPlacementsAveragePermFees(filterData);
            if (res) {
                const data: AveragePermFeesRecordWithId[] = res.map((r, i) => ({ ...r, id: i }));
                setRows(data);
            }
            loadingHandler && loadingHandler(false);
        };
        getData();
    }, [filterData, loadingHandler]);

    const columns = useMemo<GridColDef[]>(() => {
        return [
            { field: 'month', headerName: 'Months', width: 250 },
            { field: 'placements', headerName: '# Placements', headerAlign: 'center', align: 'right', width: 150 },
            { field: 'avgPermFee', headerName: 'Average Perm Fee', headerAlign: 'center', align: 'right', width: 150 },
        ];
    }, []);

    const xAxisLabelsFormatter = useCallback((value: any, index: number) => {
        if (typeof value === 'string') {
            const s = value.split(' ');
            if (s.length === 2) {
                return s[0].substring(0, 3) + ' ' + s[1];
            }
        }
        return '';
    }, []);

    if (graphType === 'table') {
        return (
            <GridWithStateWrapper
                gridName={gridName}
                rows={rows}
                density="compact"
                columns={columns}
                sx={{ mt: '20px' }}
            />
        );
    }
    else if (graphType === 'graph') {
        return (
            <>
                <Typography variant="h6" padding={1} textAlign="center" fontWeight={600} component="div">Average Permanent Fees Trend Analysis</Typography>
                <div style={{ height: 'calc(100% - 48px)' }}>
                    <ResponsiveContainer width="100%" height="100%">
                        <ComposedChart width={150} height={150} data={rows} margin={{ right: 50, top: 20 }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="month" stroke={t.palette.text.primary} tickFormatter={ xAxisLabelsFormatter } />
                            <YAxis yAxisId="left" />
                            <YAxis yAxisId="right" orientation="right" />
                            <Tooltip />
                            <Legend  />
                            <Line yAxisId="left" type="monotone" name="Average Perm Fees" dataKey="avgPermFee" stroke={t.palette.info.main} strokeWidth={3} />
                            <Bar yAxisId="right" dataKey="placements" name="Placements" fill={t.palette.text.disabled} />
                        </ComposedChart>
                    </ResponsiveContainer>
                </div>
            </>
        );
    }

    return <></>;
}