import { NameIdObjString } from "common/models/GenericTypes";

export const CategoryToOccupatonsMap: Record<string, NameIdObjString[]> = {
    "C|1": [
        { id: 'O|13', name: 'Accountant' },
        { id: 'O|1', name: 'Accounts Clerk/Officer' },
        { id: 'O|2', name: 'Accounts Payable' },
        { id: 'O|3', name: 'Accounts Receivable/Collections' },
        { id: 'O|4', name: 'Assistant Accountant' },
        { id: 'O|6', name: 'Auditor' },
        { id: 'O|7', name: 'Bookkeeper' },
        { id: 'O|8', name: 'Business Analyst' },
        { id: 'O|9', name: 'Business Services Accountant' },
        { id: 'O|10', name: 'Company Secretary' },
        { id: 'O|11', name: 'Cost Accountant' },
        { id: 'O|12', name: 'Credit Controller' },
        { id: 'O|14', name: 'Financial Manager & Controller' },
        { id: 'O|15', name: 'Forensic Accountant' },
        { id: 'O|16', name: 'Insolvency' },
        { id: 'O|17', name: 'Inventory & Asset Manager' },
        { id: 'O|508', name: 'Management & Senior Leadership (Accounting)' },
        { id: 'O|18', name: 'Management Accountant' },
        { id: 'O|19', name: 'Payroll Officer' },
        { id: 'O|20', name: 'Risk & Compliance Officer' },
        { id: 'O|509', name: 'Strategy & Planning' },
        { id: 'O|21', name: 'Systems Accountant' },
        { id: 'O|22', name: 'Tax Accountant' },
        { id: 'O|23', name: 'Treasury Accountant' },
        { id: 'O|24', name: 'Other (Accounting)' },
    ],
    "C|2": [
        { id: "O|25", name: "Administration Assistants" },
        { id: "O|450", name: "Administration Manager" },
        { id: "O|26", name: "Contracts Administrator (Administration & Office Support)" },
        { id: "O|27", name: "Data Entry" },
        { id: "O|28", name: "Office Manager" },
        { id: "O|29", name: "Personal (PA) & Executive Assistant (EA)" },
        { id: "O|30", name: "Receptionist" },
        { id: "O|31", name: "Information, Records Management Clerk & Filing" },
        { id: "O|32", name: "Sales Administrator" },
        { id: "O|33", name: "Secretary" },
        { id: "O|34", name: "Other (Administration & Office Support)" },
    ],
    "C|3": [
        { id: "O|35", name: "Conservation & Natural Resources" },
        { id: "O|36", name: "Equine Industry" },
        { id: "O|37", name: "Farm Management" },
        { id: "O|38", name: "Farmer" },
        { id: "O|39", name: "Fishing & Marine/Aquaculture" },
        { id: "O|40", name: "Horticulture & Greenhouse" },
        { id: "O|415", name: "Livestock & Breeding" },
        { id: "O|41", name: "Services & Consultancy" },
        { id: "O|42", name: "Veterinarian, Animal Care & Zoology" },
        { id: "O|43", name: "Winery & Viticulture" },
        { id: "O|44", name: "Other (Agriculture, Horticulture, Animal & Fishing)" },
    ],
    "C|4": [
        { id: "O|451", name: "Animation, Illustration & 3D Design" },
        { id: "O|45", name: "Architect" },
        { id: "O|46", name: "Drafter & CAD" },
        { id: "O|485", name: "Fashion & Textile Design" },
        { id: "O|416", name: "Graphic Design" },
        { id: "O|270", name: "Industrial Design" },
        { id: "O|79", name: "Interior Design" },
        { id: "O|47", name: "Landscape Architect" },
        { id: "O|48", name: "Planner/Town Planner" },
        { id: "O|153", name: "Product Development & Design" },
        { id: "O|49", name: "Urban Designer" },
        { id: "O|237", name: "User Interface (UI), User Experience (UX) & Web Design" },
        { id: "O|50", name: "Other (Design & Architecture)" },
    ],
    "C|5": [
        { id: "O|51", name: "Account Manager (Banking & Finance)"},
        { id: "O|52", name: "Analyst (Banking & Finance)"},
        { id: "O|53", name: "Bank - Retail & Branch"},
        { id: "O|504", name: "Banking & Finance Law"},
        { id: "O|54", name: "Business Banking"},
        { id: "O|467", name: "Client Service"},
        { id: "O|55", name: "Compliance, Risk Management & Fraud Investigation"},
        { id: "O|56", name: "Corporate Banking"},
        { id: "O|57", name: "Corporate Finance"},
        { id: "O|58", name: "Credit"},
        { id: "O|417", name: "Economist"},
        { id: "O|59", name: "Financial Planner"},
        { id: "O|60", name: "Funds Manager"},
        { id: "O|61", name: "Investment Banking"},
        { id: "O|468", name: "Management & Senior Leadership (Banking, Superannuation & Finance)"},
        { id: "O|62", name: "Mortgages & Loans"},
        { id: "O|63", name: "Retail Banking"},
        { id: "O|64", name: "Securities and Commodities"},
        { id: "O|65", name: "Settlements"},
        { id: "O|66", name: "Stockbroker & Trader"},
        { id: "O|67", name: "Superannuation"},
        { id: "O|68", name: "Treasury Accountant"},
        { id: "O|69", name: "Other (Banking, Superannuation & Finance)"},
    ],
    "C|6": [
        { id: "O|70", name: "Air Conditioning, Heating & Refrigeration (HVAC)" },
        { id: "O|71", name: "Architect" },
        { id: "O|72", name: "Building Contracts Administration" },
        { id: "O|73", name: "Carpenter, Cabinet Maker & Framing" },
        { id: "O|74", name: "Concreter & Formworking" },
        { id: "O|501", name: "Construction Law" },
        { id: "O|76", name: "Demolition, Excavation & Heavy Equipment Operation" },
        { id: "O|77", name: "Electrician" },
        { id: "O|418", name: "Glaziers" },
        { id: "O|78", name: "Health, Safety & Environment" },
        { id: "O|80", name: "Ironwork & Metal Fabrication" },
        { id: "O|81", name: "Landscape Architect" },
        { id: "O|75", name: "Management & Corporate" },
        { id: "O|82", name: "Painter" },
        { id: "O|476", name: "Plant/Machine Operator" },
        { id: "O|83", name: "Plasterer & Sheetrock" },
        { id: "O|84", name: "Plumber & Pipefitter" },
        { id: "O|85", name: "Project Manager (Construction)" },
        { id: "O|86", name: "Quantity Surveying, Estimating & Compliance" },
        { id: "O|87", name: "Roofing" },
        { id: "O|88", name: "Foreperson, Site & Construction Supervisor" },
        { id: "O|419", name: "Solar Energy & Heating Systems" },
        { id: "O|89", name: "Surveying (Construction)" },
        { id: "O|90", name: "Tiler & Flooring" },
        { id: "O|477", name: "Quality Assurance & Control (Construction)" },
        { id: "O|91", name: "Other (Construction)" },
    ],
    "C|7": [
        { id: "O|92", name: "Contact & Call Centre" },
        { id: "O|93", name: "Customer Service" },
        { id: "O|94", name: "Customer Training" },
        { id: "O|95", name: "Flight Attendant" },
        { id: "O|475", name: "Management & Supervisory (Customer Service & Call Centre)" },
        { id: "O|96", name: "Reservations & Ticketing" },
        { id: "O|524", name: "Retail Assistant" },
        { id: "O|99", name: "Supervisors & Team Leaders" },
        { id: "O|100", name: "Telemarketer" },
        { id: "O|523", name: "Telesales - General" },
        { id: "O|520", name: "Telesales - Inbound" },
        { id: "O|522", name: "Telesales - Outbound" },
        { id: "O|101", name: "Other (Customer Service & Call Centre)" },
    ],
    "C|8": [
        { id: "O|463", name: "Agency Account Manager/Coordinator" },
        { id: "O|421", name: "Artists & Art Directors" },
        { id: "O|423", name: "Audio Visual Technicians" },
        { id: "O|420", name: "Book Editor/Publisher" },
        { id: "O|102", name: "Digital Content Development" },
        { id: "O|103", name: "Editing & Proofreading" },
        { id: "O|104", name: "Event Management" },
        { id: "O|422", name: "Film/TV/Radio Directors, Producers & Staff" },
        { id: "O|105", name: "Journalist/Writer" },
        { id: "O|511", name: "Management & Senior Leadership (Editorial, Media & Creative Arts)" },
        { id: "O|106", name: "Media Strategy, Planning & Buying" },
        { id: "O|107", name: "Performing Arts" },
        { id: "O|108", name: "Photography" },
        { id: "O|109", name: "Programming & Production" },
        { id: "O|110", name: "Promotions" },
        { id: "O|111", name: "Technical Writers" },
        { id: "O|112", name: "Translation & Interpretation" },
        { id: "O|113", name: "Other (Editorial, Media & Creative Arts)" },
    ],
    "C|9": [
        { id: "O|462", name: "Assistant, Aide & Special Needs" },
        { id: "O|114", name: "Corporate Training & Development" },
        { id: "O|115", name: "Early Childhood" },
        { id: "O|116", name: "Fitness & Sports Training" },
        { id: "O|117", name: "Library & Archive" },
        { id: "O|458", name: "Management - Schools" },
        { id: "O|459", name: "Management - Tertiary & Universities" },
        { id: "O|460", name: "Management - Vocational" },
        { id: "O|118", name: "Nanny & Babysitting" },
        { id: "O|425", name: "Principal - Primary/Secondary School" },
        { id: "O|461", name: "Researcher & Fellowship" },
        { id: "O|119", name: "School & University Administration" },
        { id: "O|120", name: "Teacher - Early Childhood" },
        { id: "O|121", name: "Teacher - Primary" },
        { id: "O|122", name: "Teacher - Secondary" },
        { id: "O|123", name: "Teacher - Tertiary" },
        { id: "O|124", name: "Teacher - Vocational" },
        { id: "O|424", name: "Tutors" },
        { id: "O|125", name: "Vocational Training & TAFE" },
        { id: "O|126", name: "Workplace Training & Assessment" },
        { id: "O|127", name: "Other (Education, Training & Childcare)" },
    ],
    "C|10": [
        { id: "O|128", name: "Air Force" },
        { id: "O|129", name: "Ambulance & Paramedic" },
        { id: "O|130", name: "Army" },
        { id: "O|131", name: "Federal Government" },
        { id: "O|132", name: "Firefighting & Rescue" },
        { id: "O|133", name: "Local Government" },
        { id: "O|134", name: "Navy" },
        { id: "O|135", name: "Police & Justice" },
        { id: "O|136", name: "Policy, Planning & Regulation" },
        { id: "O|137", name: "State Government" },
        { id: "O|138", name: "Other (Government, Emergency Services & Defence)" },
    ],
    "C|11": [
        { id: "O|139", name: "Administration & Technical Support" },
        { id: "O|140", name: "Aeronautics, Avionics & Aerospace" },
        { id: "O|141", name: "Automotive Engineering" },
        { id: "O|495", name: "Building Services" },
        { id: "O|142", name: "Chemical & Materials" },
        { id: "O|143", name: "Civil & Structural" },
        { id: "O|496", name: "Drafter & CAD" },
        { id: "O|144", name: "Electrical & Electronics Engineering" },
        { id: "O|145", name: "Energy & Nuclear Engineering" },
        { id: "O|146", name: "Environmental Engineering" },
        { id: "O|147", name: "Field Engineering" },
        { id: "O|148", name: "Industrial & Manufacturing Engineering" },
        { id: "O|497", name: "IT Hardware & Computer Engineering" },
        { id: "O|149", name: "Maintenance (Engineering)" },
        { id: "O|150", name: "Management & Supervisory (Engineering)" },
        { id: "O|152", name: "Mechanical Engineering" },
        { id: "O|154", name: "Project Manager & Project Engineering" },
        { id: "O|426", name: "Rail, Locomotive & Transport" },
        { id: "O|427", name: "Robotics, Artificial Intelligence (AI) & Machine Learning" },
        { id: "O|151", name: "Ships, Water Vessels & Marine (Engineering)" },
        { id: "O|498", name: "Software Engineering" },
        { id: "O|155", name: "Systems & Process Engineering" },
        { id: "O|156", name: "Water & Waste" },
        { id: "O|157", name: "Other (Engineering)" },
    ],
    "C|12": [
        { id: "O|464", name: "Analyst (Executive Management & Consulting)" },
        { id: "O|158", name: "Chief Executive Officer (CEO)" },
        { id: "O|516", name: "Chief Information Officer (CIO) & Chief Technology Officer (CTO)" },
        { id: "O|428", name: "Chief Financial Officer (CFO)" },
        { id: "O|159", name: "Chief Operations Officer (COO), General Manager (GM), Managing Director (MD)" },
        { id: "O|429", name: "C-Suite - Other" },
        { id: "O|160", name: "Directors & Board Appointments" },
        { id: "O|465", name: "Environment & Sustainability" },
        { id: "O|161", name: "Franchise & Business Ownership" },
        { id: "O|162", name: "Management Consultant" },
        { id: "O|164", name: "Mergers & Acquisitions" },
        { id: "O|165", name: "Policy & Planning" },
        { id: "O|166", name: "Strategy" },
        { id: "O|167", name: "Other (Executive Management & Consulting)" },
    ],
    "C|13": [
        { id: "O|168", name: "Aged & Disability Care" },
        { id: "O|169", name: "Ambulance & Paramedic"},
        { id: "O|170", name: "Clinical Research"},
        { id: "O|171", name: "Dental"},
        { id: "O|172", name: "Dietary"},
        { id: "O|173", name: "Hospital Administration & Patient Services"},
        { id: "O|455", name: "Management & Senior Leadership (Healthcare & Medical)"},
        { id: "O|174", name: "Maternal, Midwifery & Obstetrics"},
        { id: "O|175", name: "Medical & Dental Assistants"},
        { id: "O|176", name: "Medical & Pharmaceutical Research"},
        { id: "O|177", name: "Medical & Pharmaceutical Sales"},
        { id: "O|348", name: "Medical Devices & Technology"},
        { id: "O|178", name: "Medical Practitioners & Specialists"},
        { id: "O|179", name: "Mental Health"},
        { id: "O|180", name: "Natural & Massage Therapies"},
        { id: "O|181", name: "Nurse"},
        { id: "O|453", name: "Nursing - Management"},
        { id: "O|454", name: "Nursing - Educators, Facilitators & Trainers"},
        { id: "O|182", name: "Optical"},
        { id: "O|183", name: "Pathology"},
        { id: "O|184", name: "Pharmacy"},
        { id: "O|185", name: "Physiotherapy, Occupational Therapy (OT) & Rehabilitation"},
        { id: "O|186", name: "Psychology & Counselling"},
        { id: "O|187", name: "Public Health Administration"},
        { id: "O|188", name: "Medical Imaging, Radiology & Sonography"},
        { id: "O|456", name: "Residents & Registrars"},
        { id: "O|510", name: "Speech Therapist"},
        { id: "O|189", name: "Sports & Fitness Training"},
        { id: "O|191", name: "Other (Healthcare & Medical)"},
    ],
    "C|14": [
        { id: "O|192", name: "Airlines" },
        { id: "O|431", name: "Barista"},
        { id: "O|193", name: "Casinos & Gaming"},
        { id: "O|194", name: "Chef"},
        { id: "O|430", name: "Cruise Ship & Boat Staff"},
        { id: "O|195", name: "Event Planning & Coordination"},
        { id: "O|196", name: "Food Preparation & Cooking"},
        { id: "O|197", name: "Front Desk & Reception"},
        { id: "O|198", name: "Guest Services & Concierge"},
        { id: "O|199", name: "Housekeeping"},
        { id: "O|432", name: "Hotel Management"},
        { id: "O|515", name: "Reservations & Ticketing"},
        { id: "O|200", name: "Restaurant Management"},
        { id: "O|473", name: "Management & Senior Leadership (Hospitality, Tourism & Food Services)"},
        { id: "O|201", name: "Tour Guides & Operators"},
        { id: "O|202", name: "Travel & Ticketing Agents"},
        { id: "O|203", name: "Wait Staff & Bartenders"},
        { id: "O|204", name: "Wine Steward (Sommelier)"},
        { id: "O|205", name: "Other (Hospitality, Tourism & Food Services)"},
    ],
    "C|15": [
        { id: "O|206", name: "Compliance, Employee & Industrial Relations" },
        { id: "O|207", name: "Consulting & Generalist HR"},
        { id: "O|208", name: "HR Managers & Directors"},
        { id: "O|209", name: "Management - Agency"},
        { id: "O|210", name: "Management - Internal"},
        { id: "O|211", name: "Organisational Development"},
        { id: "O|212", name: "Payroll & Benefits Administration"},
        { id: "O|213", name: "Recruiter - Agency"},
        { id: "O|214", name: "Recruiter - Internal"},
        { id: "O|215", name: "Training & Development"},
        { id: "O|216", name: "Workplace Health & Safety (WHS)"},
        { id: "O|217", name: "Other (Human Resources (HR) & Recruitment)"},
    ],
    "C|16": [
        { id: "O|241", name: "Actuarial & Analysis" },
        { id: "O|471", name: "Assessment"},
        { id: "O|242", name: "Claims"},
        { id: "O|243", name: "Health Insurance"},
        { id: "O|244", name: "Insurance Compliance & Risk"},
        { id: "O|506", name: "Insurance Law"},
        { id: "O|245", name: "Insurance Sales & Broking"},
        { id: "O|472", name: "Management & Senior Leadership (Insurance)"},
        { id: "O|246", name: "Underwriter"},
        { id: "O|247", name: "Workers' Compensation"},
        { id: "O|248", name: "Other (Insurance)"},
    ],
    "C|17": [
        { id: "O|218", name: "Business/Systems Analysts (IT)" },
        { id: "O|219", name: "CIO & CTO"},
        { id: "O|220", name: "Data Warehousing"},
        { id: "O|221", name: "Database Development & Admin"},
        { id: "O|222", name: "Developers/Programmers"},
        { id: "O|223", name: "Hardware Engineering"},
        { id: "O|224", name: "Software Engineering"},
        { id: "O|225", name: "Enterprise Software Implementation & Consulting"},
        { id: "O|226", name: "Help Desk & IT Support"},
        { id: "O|227", name: "Consultant"},
        { id: "O|228", name: "IT Management & Senior Leadership"},
        { id: "O|478", name: "Product Manager"},
        { id: "O|229", name: "Project Manager (IT)"},
        { id: "O|230", name: "IT Sales"},
        { id: "O|231", name: "Networks & Systems Administration"},
        { id: "O|433", name: "Robotics, Artificial Intelligence (AI) & Machine Learning"},
        { id: "O|232", name: "Security"},
        { id: "O|233", name: "Software & System Architecture"},
        { id: "O|479", name: "Team Leader"},
        { id: "O|234", name: "Technical Writing"},
        { id: "O|235", name: "Telecommunications (IT)"},
        { id: "O|236", name: "Testing & Quality Assurance"},
        { id: "O|486", name: "User Interface (UI), User Experience (UX) & Web Design"},
        { id: "O|238", name: "Web Development & Production"},
        { id: "O|239", name: "Web Producers & Web Masters"},
        { id: "O|240", name: "Other (Information Technology (IT))"},
    ],
    "C|18": [
        { id: "O|434", name: "Arbitrators, Mediators & Conciliators" },
        { id: "O|503", name: "Banking & Finance Law"},
        { id: "O|249", name: "Barrister"},
        { id: "O|250", name: "Commercial & Corporate Law"},
        { id: "O|251", name: "Compliance & Trade Practices Law"},
        { id: "O|502", name: "Construction Law"},
        { id: "O|252", name: "Contracts Administrator (Legal)"},
        { id: "O|253", name: "Criminal Law"},
        { id: "O|254", name: "Employment Law"},
        { id: "O|255", name: "Environmental Law"},
        { id: "O|256", name: "Family Law"},
        { id: "O|257", name: "Generalist"},
        { id: "O|505", name: "Insurance Law"},
        { id: "O|258", name: "Intellectual Property Law"},
        { id: "O|436", name: "Judges & Magistrates"},
        { id: "O|507", name: "Legal Management & Partners"},
        { id: "O|259", name: "Legal Secretary"},
        { id: "O|435", name: "Legislator"},
        { id: "O|260", name: "Litigation & Dispute Resolution"},
        { id: "O|261", name: "Paralegal, Clerical & Legal Support"},
        { id: "O|262", name: "Personal Injury Law"},
        { id: "O|263", name: "Property Lawyer/Conveyancer"},
        { id: "O|264", name: "Solicitor"},
        { id: "O|265", name: "Tax Law"},
        { id: "O|266", name: "Other (Legal)"},
    ],
    "C|19": [
        { id: "O|487", name: "Analysis & Reporting (Manufacturing, Production & Operations)" },
        { id: "O|438", name: "Coating, Painting & Spraying Machine Operators/Setters"},
        { id: "O|267", name: "Equipment/Machine Operator"},
        { id: "O|268", name: "Fitting, Machining & Computer Numerical Control (CNC)"},
        { id: "O|269", name: "Hazardous Materials Handling"},
        { id: "O|271", name: "Layout, Prepress, Printing, & Binding Operations"},
        { id: "O|272", name: "Management & Senior Leadership (Manufacturing, Production & Operations)"},
        { id: "O|437", name: "Molders, Carvers, Engravers & Potters"},
        { id: "O|273", name: "Planning & Scheduling"},
        { id: "O|274", name: "Production, Process & Assembly Line Work"},
        { id: "O|275", name: "Production Quality Assurance"},
        { id: "O|276", name: "Purchasing & Procurement"},
        { id: "O|278", name: "Supervisors, Team Leaders & Plant Management"},
        { id: "O|279", name: "Telecommunications (Manufacturing, Production & Operations)"},
        { id: "O|280", name: "Textiles, Jewellery & Footwear"},
        { id: "O|281", name: "Water, Waste & Recycling"},
        { id: "O|282", name: "Other (Manufacturing, Production & Operations)"},
    ],
    "C|20": [
        { id: "O|283", name: "Brand Manager" },
        { id: "O|284", name: "Copywriter"},
        { id: "O|285", name: "Digital Search Marketing - SEM/SEO"},
        { id: "O|286", name: "Direct Marketing & CRM"},
        { id: "O|287", name: "Internal Communications"},
        { id: "O|288", name: "Market Research & Analysis"},
        { id: "O|289", name: "Marketing Assistants/Coordinators"},
        { id: "O|290", name: "Marketing Communications"},
        { id: "O|291", name: "Marketing Managers, Directors & Consultants"},
        { id: "O|292", name: "Media Planner & Buyer"},
        { id: "O|293", name: "Product Manager & Product Developer"},
        { id: "O|294", name: "Production & Traffic"},
        { id: "O|295", name: "Public Relations, Media & Investor"},
        { id: "O|296", name: "Sponsorship, Promotions & Event Management"},
        { id: "O|466", name: "Trade & B2B"},
        { id: "O|297", name: "Visual & Display Merchandising"},
        { id: "O|298", name: "Other (Marketing & Advertising)"},
    ],
    "C|21": [
        { id: "O|299", name: "Corporate & Administrative" },
        { id: "O|300", name: "Drilling/Blasting & Cutting"},
        { id: "O|301", name: "Engineering"},
        { id: "O|302", name: "Environment & Safety"},
        { id: "O|303", name: "Environmental/Geological Testing & Analysis"},
        { id: "O|304", name: "Exploration"},
        { id: "O|305", name: "Field Management & Supervisory"},
        { id: "O|306", name: "Maintenance (Mining & Energy)"},
        { id: "O|489", name: "Management & Senior Leadership (Mining & Energy)"},
        { id: "O|307", name: "Metallurgy"},
        { id: "O|308", name: "Mineral Processing"},
        { id: "O|309", name: "Oil & Gas"},
        { id: "O|490", name: "Operations"},
        { id: "O|310", name: "Power Generation & Distribution"},
        { id: "O|311", name: "Production"},
        { id: "O|491", name: "Renewable Energy"},
        { id: "O|312", name: "Surveying (Mining & Energy)"},
        { id: "O|492", name: "Water & Natural Resources"},
        { id: "O|313", name: "Other (Mining & Energy)"},
    ],
    "C|22": [
        { id: "O|314", name: "Administration" },
        { id: "O|480", name: "Analyst (Property & Real Estate)"},
        { id: "O|484", name: "Body Corporate & Facilities Management"},
        { id: "O|315", name: "Commercial Sales & Leasing"},
        { id: "O|316", name: "Property & Asset Management"},
        { id: "O|317", name: "Property Development"},
        { id: "O|318", name: "Property Lawyer/Conveyancer"},
        { id: "O|319", name: "Real Estate Leasing & Acquisition"},
        { id: "O|320", name: "Residential Sales & Leasing"},
        { id: "O|321", name: "Valuation"},
        { id: "O|322", name: "Other (Property & Real Estate)"},
    ],
    "C|23": [
        { id: "O|323", name: "Buying" },
        { id: "O|324", name: "Counter Sales & Cashier"},
        { id: "O|439", name: "E-Commerce & Online Merchants"},
        { id: "O|325", name: "Management - Area/Multi-site"},
        { id: "O|326", name: "Management - Department"},
        { id: "O|327", name: "Management - Store"},
        { id: "O|328", name: "Merchandiser & Product Planner"},
        { id: "O|329", name: "Retail Assistants"},
        { id: "O|330", name: "Other (Retail)"},
    ],
    "C|24": [
        { id: "O|331", name: "Account Manager (Sales)" },
        { id: "O|332", name: "Analysis & Reporting (Sales)"},
        { id: "O|334", name: "New Business Development"},
        { id: "O|335", name: "Sales Coordinators"},
        { id: "O|333", name: "Sales Manager/Director & Senior Leadership"},
        { id: "O|336", name: "Sales Representatives/Consultants"},
        { id: "O|337", name: "Telesales - General"},
        { id: "O|97", name: "Telesales - Inbound"},
        { id: "O|98", name: "Telesales - Outbound"},
        { id: "O|338", name: "Wholesale & Reselling Sales"},
        { id: "O|339", name: "Other (Sales)"},
    ],
    "C|25": [
        { id: "O|340", name: "Biology, Biomedical & Chemistry" },
        { id: "O|341", name: "Clinical Research"},
        { id: "O|440", name: "Computer & Information Research"},
        { id: "O|342", name: "Environmental/Geological Testing & Analysis"},
        { id: "O|343", name: "Food Science & Technology"},
        { id: "O|344", name: "Forensic Science"},
        { id: "O|499", name: "Genetics"},
        { id: "O|345", name: "Laboratory & Technical Services"},
        { id: "O|346", name: "Materials & Physical Research"},
        { id: "O|347", name: "Mathematical & Statistical Research"},
        { id: "O|500", name: "Modelling & Simulation"},
        { id: "O|349", name: "New Product R&D"},
        { id: "O|457", name: "Medical Devices & Technology"},
        { id: "O|350", name: "Quality Assurance & Control (Science, Technology & Environment)"},
        { id: "O|351", name: "Other (Science, Technology & Environment)"},
    ],
    "C|26": [
        { id: "O|352", name: "Aged & Disability Support" },
        { id: "O|353", name: "Associations, Not For Profit & Charities"},
        { id: "O|354", name: "Child Welfare, Youth & Family Services"},
        { id: "O|355", name: "Community & Social Services"},
        { id: "O|493", name: "Employment Services"},
        { id: "O|356", name: "Fundraising"},
        { id: "O|357", name: "Housing & Homelessness Services"},
        { id: "O|358", name: "Indigenous & Multicultural Services"},
        { id: "O|494", name: "Management & Senior Leadership (Social Work & Community Services)"},
        { id: "O|359", name: "Religion & Church"},
        { id: "O|360", name: "Volunteer"},
        { id: "O|361", name: "Other (Social Work & Community Services)"},
    ],
    "C|27": [
        { id: "O|441", name: "Athletes & Sports Competitors" },
        { id: "O|362", name: "Coaches & Instructors"},
        { id: "O|474", name: "Management & Senior Leadership (Sport & Recreation)"},
        { id: "O|363", name: "Personal Trainer"},
        { id: "O|364", name: "Sports Administration"},
        { id: "O|365", name: "Other (Sport & Recreation)"},
    ],
    "C|28": [
        { id: "O|366", name: "Air Conditioning, Heating & Refrigeration (HVAC)" },
        { id: "O|367", name: "Apprentice (Trades & Services)"},
        { id: "O|369", name: "Bakers"},
        { id: "O|371", name: "Bricklayer"},
        { id: "O|469", name: "Building Trades"},
        { id: "O|372", name: "Butchers"},
        { id: "O|373", name: "Carpenter, Cabinet Maker & Framing"},
        { id: "O|376", name: "Concreter & Formworking"},
        { id: "O|377", name: "Electrician"},
        { id: "O|378", name: "Equipment/Instrument Install, Maintain & Repair"},
        { id: "O|379", name: "Fitting, Machining & Computer Numerical Control (CNC)"},
        { id: "O|381", name: "Florist"},
        { id: "O|442", name: "Funeral Services"},
        { id: "O|443", name: "Furniture & Upholstery"},
        { id: "O|380", name: "Flooring/Tiling"},
        { id: "O|382", name: "Hairdresser, Barber & Beauty"},
        { id: "O|383", name: "Labourer"},
        { id: "O|384", name: "Landscaper & Gardener"},
        { id: "O|385", name: "Locksmith"},
        { id: "O|386", name: "Maintenance & Handypersons"},
        { id: "O|368", name: "Mechanic, Automotive Parts, Maintenance & Repair"},
        { id: "O|512", name: "Painting, Wallpapering & Signage"},
        { id: "O|444", name: "Pest Control"},
        { id: "O|514", name: "Plasterer & Sheet Rock"},
        { id: "O|387", name: "Plumber & Pipefitter"},
        { id: "O|388", name: "Cleaner - Residential & Commercial"},
        { id: "O|389", name: "Security Services"},
        { id: "O|390", name: "Tailor, Sewers & Dressmaker"},
        { id: "O|470", name: "Technician"},
        { id: "O|445", name: "Tool & Die Making"},
        { id: "O|446", name: "Welding, Soldering & Brazing"},
        { id: "O|452", name: "Wood, Stone, Plastic & Metal Workers"},
        { id: "O|391", name: "Other (Trades & Services)"},
    ],
    "C|29": [
        { id: "O|392", name: "Aircraft Maintenance" },
        { id: "O|393", name: "Baggage Handler"},
        { id: "O|394", name: "Courier"},
        { id: "O|395", name: "Driver - Bus & Van"},
        { id: "O|396", name: "Driver - Taxi Services"},
        { id: "O|397", name: "Driver - Truck & Heavy Vehicle"},
        { id: "O|447", name: "Driver - Other"},
        { id: "O|398", name: "Equipment, Forklift & Crane Operation"},
        { id: "O|399", name: "Importer & Exporter"},
        { id: "O|400", name: "Inventory Control & Production"},
        { id: "O|488", name: "Management & Senior Leadership (Transport & Logistics)"},
        { id: "O|401", name: "Merchandising, Product Planning & Buying"},
        { id: "O|405", name: "Picking, Shipping, Postal, Warehousing & Customs"},
        { id: "O|402", name: "Pilots, Aircraft, Air Traffic & Aviation Workers"},
        { id: "O|403", name: "Public Transport Services"},
        { id: "O|404", name: "Purchasing & Procurement"},
        { id: "O|448", name: "Rail & Road Workers"},
        { id: "O|449", name: "Ships, Water Vessels & Marine (Transport & Logistics)"},
        { id: "O|406", name: "Supply Chain Management"},
        { id: "O|517", name: "Team Leader/Supervisor"},
        { id: "O|407", name: "Vehicle Dispatch, Routing & Scheduling"},
        { id: "O|408", name: "Vehicle Hire, Leasing & Fleet"},
        { id: "O|409", name: "Vehicle Inspection"},
        { id: "O|410", name: "Other (Transport & Logistics)"},
    ],
    "C|30": [
        { id: "O|411", name: "Business Owner" },
        { id: "O|412", name: "Franchise & Business Ownership"},
        { id: "O|413", name: "Self Employment"},
        { id: "O|414", name: "Other (Work From Home & Self Employed)"},
    ],
}