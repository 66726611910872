import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { PanelModel } from "@syncfusion/ej2-layouts/src/dashboard-layout/dashboard-layout-model";
import { DashboardLayoutComponent } from "@syncfusion/ej2-react-layouts/src/dashboard-layout/dashboardlayout.component";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import * as tinymce from 'tinymce';
import { Candidate, MinCandidate } from "common/models/Candidates/Candidate";
import { CustomField, CustomFieldPredefinedValue } from "common/models/Configuration/CustomFields";
import { CandidateRecordDashboardElementDefinition, CandidateRecordDashboardElementType } from "common/models/Dashboard/EditLayout";
import { CustomFieldSettingsMap, CustomFieldType } from "common/models/ScreenLayouts/CustomFields";
import useObjectStateWithChangeTracker from "hooks/UseObjectStateWithChangeTracker";
import useUnsavedChangesDialog from "hooks/UseUnsavedChangesDialog";
import { CreateCandidate, GetCandidateById, UpdateCandidate } from "services/CandidatesService";
import { GetCustomerSettingBySettingName } from "services/ConfigurationService";
import { GetCustomFieldsByEntity_OnlyActive, GetPredefinedValues } from "services/CustomFieldsService";
import { GetSingleSetting } from "services/UsersService";
import { CandidateToMinCandidate, DefaultMinCandidate, DefaultMinCandidateNoChanges } from "util/Definitions/Candidates";
import { CandidateScreenLayoutSettings, DefaultCandidateRecordDashboardElements, GetPanelDefinitionsFromPanelModels } from "util/Definitions/ScreenLayouts/Candidate";
import { IsValidCustomFieldValue, UdfCandidateFieldMapObj } from "util/Definitions/ScreenLayouts/CustomFields";
import PanelWrapper from "components/Dashboards/PanelWrapper";
import NationalityPicker from "components/Pickers/NationalityPicker";
import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import EditableRichTextElement from "../EditableRichTextElement";
import EditableSingleFieldElement from "../EditableSingleFieldElement";
import SingleFieldElement from "../SingleFieldElement";
import EditableCandidateLocationElement from "./EditableCandidateLocationElement";
import RecordAvatarComponent from "components/Dashboards/Graphs/ClientAvatarComponent";
import MultipleDivisionsPicker from "components/Pickers/MultipleDivisionPicker";
import { NameIdObj } from "common/models/GenericTypes";
import RWTextFieldComponent from "components/RWTextFieldComponent";

interface Props {
    candidateId?: number,
    defaultCountry?: string,
    defaultSummary?: string,
    setSummaryBar?: (sb: JSX.Element) => void,
    loadingHandler?: (isLoading: boolean) => void,
    successHandler?: (message: string) => void,
    errorHandler?: (message: string) => void,
    emailAddress?: string | null
}

interface CustomFieldWithPredefinedValues extends CustomField {
    values?: CustomFieldPredefinedValue[],
}

const validationErrorMessage = (validationResult: string) => {
    if (validationResult === 'ok') return '';
    if (validationResult === 'required') return 'Required';
    return 'Not Valid';
};

const { unitWidth, unitHeight, gapX, gapY, columns, mediaQueryMaxWidth } = CandidateScreenLayoutSettings;
const cellSpacing = [gapX, gapY];

const preferredUnitValues = [
    <MenuItem key={0} value="0">None</MenuItem>,
    <MenuItem key={1} value="1">Hourly</MenuItem>,
    <MenuItem key={2} value="2">Daily</MenuItem>,
    <MenuItem key={3} value="3">Weekly</MenuItem>,
    <MenuItem key={4} value="4">Monthly</MenuItem>,
    <MenuItem key={5} value="6">Annually</MenuItem>,
];

const WeeksAdornment = <InputAdornment position="end">Week(s)</InputAdornment>;

export default function EditRecordScreenLayout({ candidateId = 0, defaultCountry = '', defaultSummary = '', emailAddress = null, setSummaryBar, loadingHandler, successHandler, errorHandler }: Props) {
    const [isFetchingConfig, setIsFetchingConfig] = useState(false);
    const [isFetchingCandidate, setIsFetchingCandidate] = useState(false);
    const [fetchedConfig, setFetchedConfig] = useState(false);
    const [fetchedCandidate, setFetchedCandidate] = useState(false);
    const [showValidation, setShowValidation] = useState(false);
    const [fetchedLayoutConfig, setFetchedLayoutConfig] = useState(false);
    const [fetchingLayoutConfig, setFetchingLayoutConfig] = useState(false);
    const [layoutConfiguration, setLayoutConfiguration] = useState<PanelModel[] | null>(null);
    const [existingCandidate, setExistingCandidate] = useState<Candidate | null>(null);
    const [availableCustomFields, setAvailableCustomFields] = useState<CustomFieldWithPredefinedValues[]>([]);
    const [isEditorDirty, setIsEditorDirty] = useState(false);
    const [avatarImage, setAvatarImage] = useState<File | null>(null);
    const { state, init, change, updateInitial, hasChanges } = useObjectStateWithChangeTracker<MinCandidate>(DefaultMinCandidate, DefaultMinCandidateNoChanges);
    const editorRef = useRef<tinymce.Editor | null>(null);
    const [savedChanges, setSavedChanges] = useState(false);
    const [createdCandidateId, setCreatedCandidateId] = useState(0);
    const [screenResizedControl, setScreenResizedControl] = useState(false);
    const layoutRef = useRef<DashboardLayoutComponent | null>(null);
    const theme = useTheme();

    useEffect(() => {
        const getExistingCandidate = async () => {
            setIsFetchingCandidate(true);
            const res = await GetCandidateById(candidateId, errorHandler);
            if (res) setExistingCandidate(res);
            setFetchedCandidate(true);
            setIsFetchingCandidate(false);
        };
        candidateId && getExistingCandidate();
    }, [candidateId, errorHandler]);

    useEffect(() => {
        const getActiveFields = async () => {
            setIsFetchingConfig(true);
            const customFields = await GetCustomFieldsByEntity_OnlyActive(3);
            if (customFields) {
                let udfDefinitions: CustomFieldWithPredefinedValues[] = [];
                for (let i = 0; i < customFields.length; i++) {
                    const u = customFields[i];
                    if (!u.usePredefinedValues) {
                        udfDefinitions.push(u);
                        continue;
                    }

                    const vals = await GetPredefinedValues(u.id);
                    if (vals) udfDefinitions.push({ ...u, values: vals });
                    else udfDefinitions.push(u);
                }
                setAvailableCustomFields(udfDefinitions);
            }
            setFetchedConfig(true);
            setIsFetchingConfig(false);
        };

       
        getActiveFields();
    }, []);

    useEffect(() => {
        if(emailAddress) {
            change('email1', emailAddress);
        }
    }, [change, emailAddress]);

    useEffect(() => {
        const setDefaultCreationValues = async () => {
            let tempState = {...DefaultMinCandidate};
            tempState.availabilityDate = moment().format('YYYY-MM-DD');
            tempState.countryCode = defaultCountry;
            tempState.summary = defaultSummary;
            const res = await GetSingleSetting('Division');
            if (res && res.value) tempState.divisions = res.value;
            if (availableCustomFields.length > 0) {
                for (let i = 0; i < availableCustomFields.length; i++) {
                    const f = availableCustomFields[i];
                    if (f.usePredefinedValues && f.values && f.values.length > 0) {
                        const defaultValue = f.values.find(v => v.isDefault);
                        if (defaultValue) {
                            const field = 'customField' + f.name.substring(13) as keyof MinCandidate;
                            (tempState as any)[field] = defaultValue.value;
                        }
                    }
                }
            }
            init(tempState);
        };
        candidateId === 0 && fetchedConfig && setDefaultCreationValues();
    }, [candidateId, fetchedConfig, availableCustomFields, init, defaultSummary, defaultCountry]);

    useEffect(() => {
        const getSavedState = async () => {
            setFetchingLayoutConfig(true);
            const elementsJson = await GetCustomerSettingBySettingName('CandidateEditRecordDashboardLayoutElements');
            if (elementsJson) {
                const panels = JSON.parse(elementsJson) as PanelModel[];
                setLayoutConfiguration(panels);
            }
            setFetchingLayoutConfig(false);
            setFetchedLayoutConfig(true);
        };
        getSavedState();
    }, []);

    useEffect(() => loadingHandler && loadingHandler(isFetchingCandidate || isFetchingConfig || fetchingLayoutConfig), [isFetchingCandidate, isFetchingConfig, fetchingLayoutConfig, loadingHandler]);

    useEffect(() => {
        if (candidateId && existingCandidate && fetchedCandidate && fetchedConfig) {
            const mc = CandidateToMinCandidate(existingCandidate);
            init(mc);
            const api = editorRef.current;
            if (api) api.setContent(existingCandidate.summary ?? '');
        }
    }, [candidateId, existingCandidate, fetchedCandidate, fetchedConfig, init]);

    const saveChangesHandler = useCallback(async () => {
        if(!state.firstName && !state.lastName ) {
            setShowValidation(true);
            errorHandler && errorHandler("First Name and Last Name can't be empty");
            return false;
        }

        if(Boolean(state.home) && state.home.length > 45) {
            setShowValidation(true);
            errorHandler && errorHandler("Phone can't have more than 45 characters");
            return false;
        }

        if(Boolean(state.work) && state.work.length > 45) {
            setShowValidation(true);
            errorHandler && errorHandler("Work can't have more than 45 characters");
            return false;
        }

        if(Boolean(state.mobile) && state.mobile.length > 45) {
            setShowValidation(true);
            errorHandler && errorHandler("Mobile can't have more than 45 characters");
            return false;
        }

        let notice = state.weeksNotice === '' ? undefined : state.weeksNotice;
        if (Boolean(notice) && !Number.isInteger(+(state.weeksNotice ?? 0))) {
            setShowValidation(true);
            errorHandler && errorHandler("Notice field can't have decimal values");
            return false;
        }

        let emptyNumericUdfs: Partial<MinCandidate> = { };
        let nullDateUdfs: Partial<MinCandidate> = { };
        for (let i = 0; i < availableCustomFields.length; i++) {
            const u = availableCustomFields[i];
            if (!u.editable) continue;
            const customFieldNumber = u.name.substring(13);
            const customFieldKey = "customField" + customFieldNumber as keyof MinCandidate;
            const mapObj = UdfCandidateFieldMapObj['CustomField' + customFieldNumber as CustomFieldType];
            if (!Boolean(mapObj)) continue;
            
            const format = mapObj.format;
            const value = state[customFieldKey] as string | number | undefined;

            const validation = IsValidCustomFieldValue(value, format, u.mandatory);

            if (validation === "required") {
                setShowValidation(true);
                errorHandler && errorHandler(`${u.agencyName} is required`);
                return false;
            }
            else if (validation === "invalid-number") {
                setShowValidation(true);
                errorHandler && errorHandler(`${u.agencyName} must have a valid numeric value`);
                return false;
            }
            else if (validation === "invalid-date") {
                setShowValidation(true);
                errorHandler && errorHandler(`${u.agencyName} must have a valid date value`);
                return false;
            }
            else if (validation === "range-date") {
                setShowValidation(true);
                errorHandler && errorHandler(`${u.agencyName} must be a date between 01-01-1753 and 31-12-9999`);
                return false;
            }

            if (format === "number" && (value === '' || value === null || value === undefined)) (emptyNumericUdfs[customFieldKey] as any) = undefined;
            if ((format === "date" || format === 'datetime') && !Boolean(value)) (nullDateUdfs[customFieldKey] as any) = '0001-01-01T00:00:00';
        }

        loadingHandler && loadingHandler(true);
        const dateOfBirth = state.dateOfBirth ? state.dateOfBirth : '0001-01-01T00:00:00';
        const availabilityDate = state.availabilityDate ? state.availabilityDate : '0001-01-01T00:00:00';
        const editorApi = editorRef.current;
        const summaryContent = editorApi ? editorApi.getContent() : '';
        // let cId = candidateId;
        if (candidateId === 0) {
            const res = await CreateCandidate({...state, summary: summaryContent, dateOfBirth: dateOfBirth, availabilityDate: availabilityDate, weeksNotice: notice, ...emptyNumericUdfs, ...nullDateUdfs }, avatarImage, errorHandler);
            if (!res) {
                loadingHandler && loadingHandler(false);
                return false;
            }
            // cId = res.value;
            setCreatedCandidateId(res.value);
        }
        else if (candidateId && (hasChanges || isEditorDirty || avatarImage)) {
            const res = await UpdateCandidate(candidateId, {...state, summary: summaryContent, dateOfBirth: dateOfBirth, availabilityDate: availabilityDate, weeksNotice: notice, ...emptyNumericUdfs, ...nullDateUdfs }, avatarImage, errorHandler);
            if (!res) {
                loadingHandler && loadingHandler(false);
                return false;
            }
        }
        updateInitial();
        setIsEditorDirty(false);
        editorApi && editorApi.setDirty(false);
        loadingHandler && loadingHandler(false);
        setAvatarImage(null);
        setSavedChanges(true);
        return true;
    }, [state, loadingHandler, candidateId, hasChanges, isEditorDirty, avatarImage, updateInitial, errorHandler, availableCustomFields]);

    useEffect(() => {
        const actionButton = <Button variant="contained" color="success" disabled={!hasChanges && !isEditorDirty && avatarImage === null} onClick={saveChangesHandler}>Save</Button>
        if (candidateId === 0 && setSummaryBar) {
            const sb = <TitleAndActionSummaryBar title="Candidates > Create" browserTabTitle="Create > Candidates" action={actionButton} />;
            setSummaryBar(sb);
        }
        else if (candidateId && setSummaryBar) {
            const goBackAction = <Link to={`/candidates/${candidateId}`} style={{ textDecoration: 'none' }}><Button variant="contained" color="error" sx={{ mr: '5px' }}>Cancel</Button></Link>;
            const sb = <TitleAndActionSummaryBar title="Candidates > Edit" browserTabTitle="Edit > Candidates" action={<>{goBackAction}{actionButton}</>} />;
            setSummaryBar(sb);
        }
    }, [candidateId, hasChanges, isEditorDirty, setSummaryBar, saveChangesHandler, avatarImage]);

    const onStringFieldChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        let val = value;
        if (name.startsWith('dtUDF') && value && !value.includes('T')) val += 'T00:00:00';
        change(name as keyof MinCandidate, val);
    }, [change]);

    const onDateFieldChange = useCallback((m: moment.Moment | null, fieldName: string) => {
        if(m && m.isValid()) {
            change(fieldName as keyof MinCandidate, m.format('YYYY-MM-DD'))
        }
        else if (m === null) change(fieldName as keyof MinCandidate, '');
    }, [change]);

    const onDateTimeFieldChange = useCallback((m: moment.Moment | null, fieldName: string) => {
        if(m && m.isValid()) {
            change(fieldName as keyof MinCandidate, m.format('YYYY-MM-DDTHH:mm'));
        }
        else if (m === null) change(fieldName as keyof MinCandidate, '');
    }, [change]);

    const onNumberFieldChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        const n = +value;
        if (isNaN(n) && value !== '') return;
        if (value.length > 11) return;

        const i = value.indexOf('.');
        if (value.length > 8 && i === -1) return;

        if(i >= 0) {
            const decimals = value.substring(i + 1);
            if (decimals.length > 2) {
                change(name as keyof MinCandidate, value.substring(0, i + 3));
                return;
            }
        }

        change(name as keyof MinCandidate, value);

    }, [change]);

    const onNumericListFieldChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        change(name as keyof MinCandidate, +value);
    }, [change]);

    const divisionIds = useMemo<number[]>(() => {
        if (state.divisions) {
            const split = state.divisions.split(';');
            return split.map(d => +d);
        }
        return [];
    }, [state.divisions]);

    const onDivisionChange = useCallback((divisionIds: NameIdObj[]) => {
        const joined = divisionIds.map(d => d.id).join(';');
        change('divisions', joined);
    }, [change]);

    const onNationalityChange = useCallback((nationalityId: number | null) => {
        change('nationalityID', nationalityId ?? 0);
    }, [change]);

    const onCountryChange = useCallback((countryCode: string | null) => {
        change('countryCode', countryCode ?? '');
    }, [change]);

    const { unsavedChangesDialog, hasBlockedRoute } = useUnsavedChangesDialog(hasChanges || isEditorDirty || avatarImage !== null, saveChangesHandler);

    const redirectCandidateId = useMemo(() => {
        if (candidateId !== 0) return candidateId;
        if (savedChanges && createdCandidateId) return createdCandidateId;
        return 0;
    }, [candidateId, createdCandidateId, savedChanges]);

    const elements = useMemo<CandidateRecordDashboardElementDefinition[]>(() => {
        if (!fetchedLayoutConfig) return [];
        if (layoutConfiguration !== null) {
            const elements = GetPanelDefinitionsFromPanelModels(layoutConfiguration);
            return elements ;
        }
        return DefaultCandidateRecordDashboardElements;
    }, [layoutConfiguration, fetchedLayoutConfig]);

    const removeUploadedImageHandler = useCallback(() => {
        change('photoName', '');
        change('photoUrl', '');
    }, [change]);

    const customFieldsSettingsMap = useMemo<CustomFieldSettingsMap>(() => {
        let obj: CustomFieldSettingsMap = {};
        availableCustomFields.forEach(u => {
            const customFieldNumber = u.name.substring(13);
            const key = 'CustomField' + customFieldNumber as CustomFieldType;
            obj[key] = {
                title: u.agencyName,
                isMultiLine: u.multiLine && !u.usePredefinedValues,
                isMandatory: u.mandatory,
                isEditable: u.editable,
                usePredefinedValues: u.usePredefinedValues,
                values: u.values
            }
        });
        return obj;
    }, [availableCustomFields]);

    const renderElement = useCallback((id: string, type: CandidateRecordDashboardElementType) => {
        switch (type) {
            case 'Divider': return <Box pt="20px"><Divider component="div" /></Box>;
            case 'Spacer': return <></>;
            case 'CandidateInfo': return (
                <RecordAvatarComponent
                    entityId={3}
                    isEditable
                    photoUrl={state.photoUrl}
                    imageFile={avatarImage}
                    imageSetHandler={setAvatarImage}
                    removeUploadedImageHandler={removeUploadedImageHandler}
                />
            );
            case 'CandidateId': return <SingleFieldElement labelWidthPercent={30} fontSize="1rem" fieldTitle='ID' fieldValue={state.id} format="number" />;
            case 'CandidateName': return (
                <EditableSingleFieldElement format="custom" fieldTitle='Name'>
                    <Box display="flex">
                        <RWTextFieldComponent value={state.firstName} name="firstName" variant="standard" placeholder="First Name" sxOptions={{ flex: '1 1 0', pr: '5px' }} onChange={ onStringFieldChange } />
                        <RWTextFieldComponent value={state.lastName} name="lastName" variant="standard" placeholder="Last Name" sxOptions={{ flex: '1 1 0', pl: '5px' }} onChange={ onStringFieldChange } />
                    </Box>
                </EditableSingleFieldElement>
            );
            case 'CandidateGender': return (
                <EditableSingleFieldElement format="select" fieldTitle='Gender' fieldValue={state.genderID.toString()} fieldName="genderID" onChangeHandler={onNumberFieldChange} >
                    <MenuItem value="0">Not stated or inadequately described</MenuItem>
                    <MenuItem value="1">Male</MenuItem>
                    <MenuItem value="2">Female</MenuItem>
                    <MenuItem value="3">Other</MenuItem>
                </EditableSingleFieldElement>
            );
            case 'CandidateAvailable': return <EditableSingleFieldElement fieldTitle='Available' fieldValue={state.availabilityDate} fieldName="availabilityDate" onDateChangeHandler={onDateFieldChange} format="date" />;
            case 'CandidateNotice': 
                const isCandidateNoticeError = showValidation && Boolean(state.weeksNotice) && !Number.isInteger(+(state.weeksNotice ?? 0));
                return (
                    <EditableSingleFieldElement isError={isCandidateNoticeError} errorMessage="Not Valid" fieldTitle='Notice' format="custom">
                        <TextField fullWidth value={state.weeksNotice !== undefined ? state.weeksNotice.toString() : ''} name="weeksNotice" variant="standard" onChange={onNumberFieldChange} InputProps={{ endAdornment: WeeksAdornment }} />
                    </EditableSingleFieldElement>
                );
            case 'CandidateStatus': return (
                <EditableSingleFieldElement format="select" fieldTitle='Status' fieldValue={state.jobSeekingStatusID.toString()} fieldName="jobSeekingStatusID" onChangeHandler={onNumberFieldChange} >
                    <MenuItem value="0">None</MenuItem>
                    <MenuItem value="3">Not Currently Looking</MenuItem>
                    <MenuItem value="2">Open To Opportunities</MenuItem>
                    <MenuItem value="1">Seriously Looking</MenuItem>
                </EditableSingleFieldElement>
            );
            case 'CandidateDateOfBirth': return <EditableSingleFieldElement fieldTitle='DOB' fieldValue={state.dateOfBirth} fieldName="dateOfBirth" onDateChangeHandler={onDateFieldChange} format="date" />;
            case 'CandidateNationality': return (
                <EditableSingleFieldElement format="custom" fieldTitle='Nationality' >
                    <NationalityPicker nationalityId={state.nationalityID} onSelectCallback={onNationalityChange} hideLabel variant="standard" />
                </EditableSingleFieldElement>
            );
            case 'CandidateDivisions': return (
                <EditableSingleFieldElement format="custom" fieldTitle='Division' >
                    <MultipleDivisionsPicker value={divisionIds} onSelect={onDivisionChange} hideLabel variant="standard" limitVisible={2} />
                </EditableSingleFieldElement>
            );
            case 'CandidateEmail1': return <EditableSingleFieldElement fieldTitle='Email 1' fieldValue={state.email1} fieldName="email1" onChangeHandler={onStringFieldChange} />;
            case 'CandidateEmail2': return <EditableSingleFieldElement fieldTitle='Email 2' fieldValue={state.email2} fieldName="email2" onChangeHandler={onStringFieldChange} />;
            case 'CandidatePayrollEmail': return <EditableSingleFieldElement fieldTitle='Payroll Email' fieldValue={state.payrollEmail} fieldName="payrollEmail" onChangeHandler={onStringFieldChange} />;
            case 'CandidateWebsite':
                const website = state.website;
                return <EditableSingleFieldElement fieldTitle='Website' fieldValue={website} fieldName="website" onChangeHandler={onStringFieldChange} />;
            case 'CandidateLocation': return (
                <EditableCandidateLocationElement
                    address1={state.address1}
                    address2={state.address2}
                    suburb={state.suburb}
                    state={state.state ?? ''}
                    postcode={state.postcode}
                    country={state.countryCode}
                    onChangeHandler={onStringFieldChange}
                    onCountryChangeHandler={onCountryChange}
                    errorHandler={errorHandler}
                    loadingHandler={loadingHandler}
                    successHandler={successHandler}
                />
            );
            case 'CandidateMobile':
                const mobileValue = state.mobile;
                const isMobileError = Boolean(state.mobile) && state.mobile.length > 45;
                return <EditableSingleFieldElement isError={showValidation && isMobileError} errorMessage="Not Valid" fieldTitle='Mobile' fieldValue={mobileValue} fieldName="mobile" onChangeHandler={onStringFieldChange} />;
            case 'CandidatePhone':
                const phoneValue = state.home;
                const isPhoneError = Boolean(state.home) && state.home.length > 45;
                return <EditableSingleFieldElement isError={showValidation && isPhoneError} errorMessage="Not Valid" fieldTitle='Phone' fieldValue={phoneValue} fieldName="home" onChangeHandler={onStringFieldChange} />;
            case 'CandidateWork':
                const workPhoneValue = state.work;
                const isWorkPhoneError = Boolean(state.work) && state.work.length > 45;
                return <EditableSingleFieldElement isError={showValidation && isWorkPhoneError} errorMessage="Not Valid" fieldTitle='Work' fieldValue={workPhoneValue} fieldName="work" onChangeHandler={onStringFieldChange} />;
            case 'CandidateOptOut': return <SingleFieldElement useEllipsisForLongValues labelWidthPercent={30} fontSize="1rem" fieldTitle="OptOut" fieldValue={state.optOut ? 'Yes' : 'No'} />;
            case 'CandidateSummary': return (
                <EditableRichTextElement
                    title="Summary"
                    content={state.summary}
                    isDarkTheme={theme.palette.mode === 'dark'}
                    editorRef={editorRef}
                    editorDirtyHandler={ setIsEditorDirty }
                />
            );
            case 'CandidatePreferredJobType': return (
                <EditableSingleFieldElement format="select" fieldTitle='Preferred Job Type' fieldValue={state.preferredJobTypeID.toString()} fieldName="preferredJobTypeID" onChangeHandler={onNumberFieldChange} >
                    <MenuItem value="0">Unspecified</MenuItem>
                    <MenuItem value="1">All</MenuItem>
                    <MenuItem value="2">Permanent</MenuItem>
                    <MenuItem value="3">Contract / Temp</MenuItem>
                </EditableSingleFieldElement>
            );
            case 'CandidatePreferredRate': return (
                <EditableSingleFieldElement format="custom" fieldTitle='Preferred Rate'>
                    <Box display="flex">
                        <RWTextFieldComponent  name="preferredRate" value={state.preferredRate.toString()} variant="standard" sxOptions={{ flex: '1 1 0', pr: '5px' }} onChange={ onNumberFieldChange } />
                        <TextField select value={state.preferredRateUnitsId.toString()} name="preferredRateUnitsId" variant="standard" sx={{ flex: '1 1 0', pl: '5px' }} onChange={ onNumericListFieldChange } >
                            {preferredUnitValues}
                        </TextField>
                    </Box>
                </EditableSingleFieldElement>
            );
            case 'CandidatePreferredSalary': return (
                <EditableSingleFieldElement format="custom" fieldTitle='Preferred Salary'>
                    <Box display="flex">
                        <RWTextFieldComponent name="preferredSalary" value={state.preferredSalary.toString()} variant="standard" sxOptions={{ flex: '1 1 0', pr: '5px' }} onChange={ onNumberFieldChange } />
                        <TextField select value={state.preferredSalaryUnitsId.toString()} name="preferredSalaryUnitsId" variant="standard" sx={{ flex: '1 1 0', pl: '5px' }} onChange={ onNumericListFieldChange } >
                            {preferredUnitValues}
                        </TextField>
                    </Box>
                </EditableSingleFieldElement>
            );
            case 'CandidateLinkedIn':
                const linkedIn = state.linkedInUrl;
                return <EditableSingleFieldElement fieldTitle='LinkedIn' fieldValue={linkedIn} fieldName="linkedInUrl" onChangeHandler={onStringFieldChange} />;
            case 'CandidateTwitter':
                const twitter = state.twitterUrl;
                return <EditableSingleFieldElement fieldTitle='Twitter' fieldValue={twitter} fieldName="twitterUrl" onChangeHandler={onStringFieldChange} />;
            default:
                if (type.startsWith('CustomField')) {
                    const settings = customFieldsSettingsMap[type];
                    if (settings && settings.isEditable) {
                        let title = settings.title;
                        let predefinedValues = settings.usePredefinedValues && settings.values ? settings.values : null;
                        const { field, format } = UdfCandidateFieldMapObj[type as CustomFieldType];
                        const rawValue = state[field as keyof MinCandidate] as string | number | undefined;
                        const udfValue = (state[field as keyof MinCandidate] ?? '').toString();
                        const validationResult = IsValidCustomFieldValue(rawValue, format, settings.isMandatory ?? false);
                        const isValid = validationResult === "ok";
                        const errorMessage = validationErrorMessage(validationResult);
                        if (predefinedValues) {
                            return (
                                <EditableSingleFieldElement isError={showValidation && !isValid} errorMessage={errorMessage} format="select" fieldTitle={title} fieldValue={udfValue} fieldName={field} onChangeHandler={onStringFieldChange}>
                                    <MenuItem value="">None</MenuItem>
                                    {predefinedValues.map(v => <MenuItem key={v.value} value={v.value}>{v.value + (v.isDefault ? ' (Default)' : '')}</MenuItem>)}
                                </EditableSingleFieldElement>
                            );
                        }
                        else if (udfValue !== undefined && format === 'string') {
                            return (
                                <EditableSingleFieldElement
                                    isError={showValidation && !isValid}
                                    errorMessage={errorMessage}
                                    format={format}
                                    fieldTitle={title}
                                    fieldValue={udfValue}
                                    fieldName={field}
                                    multiline={settings.isMultiLine}
                                    onChangeHandler={onStringFieldChange}
                                />
                            );
                        }
                        else if (udfValue !== undefined && format === 'number') {
                            return (
                                <EditableSingleFieldElement
                                    isError={showValidation && !isValid}
                                    errorMessage={errorMessage}
                                    format={format}
                                    fieldTitle={title}
                                    fieldValue={udfValue}
                                    fieldName={field}
                                    onChangeHandler={onNumberFieldChange}
                                />
                            );
                        }
                        else if (format === 'date') {
                            return (
                                <EditableSingleFieldElement
                                    isError={showValidation && !isValid}
                                    errorMessage={errorMessage}
                                    format={format}
                                    fieldTitle={title}
                                    fieldValue={udfValue}
                                    fieldName={field}
                                    onDateChangeHandler={onDateFieldChange}
                                />
                            );
                        }
                        else if (format === 'datetime') {
                            return (
                                <EditableSingleFieldElement
                                    isError={showValidation && !isValid}
                                    errorMessage={errorMessage}
                                    format={format}
                                    fieldTitle={title}
                                    fieldValue={udfValue}
                                    fieldName={field}
                                    onDateChangeHandler={onDateTimeFieldChange}
                                />
                            );
                        }
                    }
                    else if (settings) return <SingleFieldElement labelWidthPercent={30} fontSize="1rem" fieldTitle={settings.title} fieldValue="[ Non Editable Custom Field ]" />;
                    else return <SingleFieldElement labelWidthPercent={30} fontSize="1rem" fieldTitle={type} fieldValue="[ Disabled Custom Field ]" />;
                }
                return <div>{id}</div>;
        }
    }, [state, avatarImage, removeUploadedImageHandler, onStringFieldChange, onNumberFieldChange, onDateFieldChange, showValidation, onNationalityChange, divisionIds, onDivisionChange, onCountryChange, errorHandler, loadingHandler, successHandler, theme.palette.mode, onNumericListFieldChange, customFieldsSettingsMap, onDateTimeFieldChange]);

        const layoutResizeStopHandler = useCallback(() => setScreenResizedControl(prev => !prev), []);

        useEffect(() => {
            if (screenResizedControl) {}
            const api = layoutRef.current;
            if (api && fetchedLayoutConfig) {
                const refreshTimeout = setTimeout(() => {
                    api.refresh();
                    api.refresh();
                }, 250);
                return () => clearTimeout(refreshTimeout);
            }
        }, [fetchedLayoutConfig, screenResizedControl]);
    
        useEffect(() => {
            window.addEventListener('resize', layoutResizeStopHandler);
            return () => window.removeEventListener('resize', layoutResizeStopHandler);
        }, [layoutResizeStopHandler]);

        const renderLayout = useCallback(() => {
            if (!fetchedLayoutConfig) return <></>;
            
            let sorted = [...elements];
            sorted.sort((a, b) => {
                if (a.col === b.col) return a.row < b.row ? -1 : 1;
                else return a.col < b.col ? -1 : 1;
            });

            const mediaQuery = mediaQueryMaxWidth ? `max-width: ${mediaQueryMaxWidth}` : undefined;
            return (
                <DashboardLayoutComponent
                    cellSpacing={cellSpacing}
                    columns={columns}
                    cellAspectRatio={30 / 2}
                    allowDragging={false}
                    allowResizing={false}
                    resizeStop={ layoutResizeStopHandler }
                    ref={l => layoutRef.current = l}
                    mediaQuery={mediaQuery}
                >
                    {sorted.map(e => (
                        <PanelWrapper
                            key={e.id}
                            id={e.id}
                            col={e.col}
                            row={e.row}
                            sizeX={e.sizeX}
                            sizeY={e.sizeY}
                            minSizeX={e.minSizeX}
                            minSizeY={e.minSizeY}
                            maxSizeX={e.maxSizeX}
                            maxSizeY={e.maxSizeY}
                            resizeControl={screenResizedControl}
                            resizeIconColor={theme.palette.text.disabled}
                            gapX={gapX}
                            gapY={gapY}
                            unitWidth={unitWidth}
                            unitHeight={unitHeight}
                        >
                            {renderElement(e.id, e.type)}
                        </PanelWrapper>
                    ))}
                </DashboardLayoutComponent>
            );
        }, [elements, fetchedLayoutConfig, theme.palette.text.disabled, screenResizedControl, layoutResizeStopHandler, renderElement]);

    return (
        <>
            {unsavedChangesDialog}
            { !hasBlockedRoute && redirectCandidateId !== 0 && savedChanges && !hasChanges && !isEditorDirty && <Navigate to={`/candidates/${redirectCandidateId}`} /> }
            <Box p="10px" height="100%">
                <div className="control-section">
                    { renderLayout() }
                </div>
            </Box>
        </>
    );
}