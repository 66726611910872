import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import PageLayout from "layouts/PageLayout";
import PageContentLayout from "layouts/PageContentLayout";
import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import { ChangeRequest } from "common/models/ChangeRequest";
import { ApproveChangeRequest, GetChangeRequestById } from "services/ChangeRequestsService";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { GetMyUser, GetSettingBySettingName } from "services/UsersService";

import Snackbar from "@mui/material/Snackbar";
import Alert from "components/Alert";
import ActionMenu from "components/Menus/ActionMenu";
import moment from "moment";
import { MenuOptionDefinition } from "common/models/MenuDefinition";
import DocumentsGridComponent from "components/Grids/DocumentsGrid";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { Permission } from "common/models/Permissions";
import { companyHasSinglePermission, userHasSinglePermission } from "util/PermissionsUtils";
import { ChangeRequestDecisionEnum, ChangeRequestTypeEnum } from "util/Definitions/Configuration/Placements";
import ConfirmationDialog from "components/Dialogs/Generic/ConfirmationDialog";
import RejectChangeRequestDialog from "components/Dialogs/ChangeRequests/RejectChangeRequestDialog";
import RescindChangeRequestDialog from "components/Dialogs/ChangeRequests/RescindChangeRequestDialog";
import GenerateDocumentDialog from "components/Dialogs/Documents/GenerateDocumentDialog";
import { GetLatestPlacementRate, GetPlacementById } from "services/PlacementsService";
import { Placement, PlacementRateChangeRequest } from "common/models/Placements";
import ReverseChangeRequestDialog from "components/Dialogs/ChangeRequests/ReverseChangeRequestDialog";
import ChangeRequestFieldsGrid from "components/Grids/ChangeRequestFieldsGrid";
import { PreviewEntityType } from "common/models/Previews/Previews";
import { PREVIEW_LINK_RENDERER } from "util/Definitions/Constants/Previews";
import PreviewLoaderComponent from "components/Previews/PreviewLoader";
import { EmailRecipient } from "common/models/EmailRecipient";
import SendEmailDialog from "components/Dialogs/Messages/SendEmailDialog";
import SendSmsDialog from "components/Dialogs/Messages/SendSmsDialog";
import Button from "@mui/material/Button";
import { AllowPlacementsAddEdit } from "common/data/Permissions/PlacementsAccess";
import EditChangePaymentTypeDialog from "components/Dialogs/Placements/ChangeRequests/EditChangePaymentTypeDialog";
import EditChangeConsultantsDialog from "components/Dialogs/Placements/ChangeRequests/EditChangeConsultantsDialog";
import { GetPlacementsSettings } from "services/ConfigurationService";
import EditConvertToPermDialog from "components/Dialogs/Placements/ChangeRequests/EditConvertToPermDialog";
import EditChangeStartDateDialog from "components/Dialogs/Placements/ChangeRequests/EditChangeStartDateDialog";

const formatDateString = (d: string, f: string = 'DD MMM YYYY h:mm A', p: string = 'Never') => {
    if (d && d !== '0001-01-01T00:00:00') {
        const m = moment(d);
        if (m.isValid()) return m.format(f);
    }
    return p;
}

const formatDateOnlyString = (d: string, f: string = 'DD MMM YYYY', p: string = 'Never') => {
    if (d && d !== '0001-01-01T00:00:00') {
        const m = moment(d);
        if (m.isValid()) return m.format(f);
    }
    return p;
}

const renderSummary = (c: ChangeRequest) => {
    const tooltipContent = (
        <table>
            <tbody>
                <tr>
                    <Typography variant="caption" fontWeight={600} component="td">ID</Typography>
                    <Typography variant="caption" pl="10px" component="td">{c.id}</Typography>
                </tr>
                <tr>
                    <Typography variant="caption" fontWeight={600} component="td">Created Date</Typography>
                    <Typography variant="caption" pl="10px" component="td">{formatDateString(c.createdDate)}</Typography>
                </tr>
                <tr>
                    <Typography variant="caption" fontWeight={600} component="td">Created By</Typography>
                    <Typography variant="caption" pl="10px" component="td">{c.createdByUserName}</Typography>
                </tr>
            </tbody>
        </table>
    );

    const jobSummary = (
        <Tooltip arrow title={tooltipContent}>
            <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{c.changeTypeName} (Effective {formatDateOnlyString(c.effectiveDate)}) - {c.decisionName}</span>
        </Tooltip>
    );

    return (<>{jobSummary}</>);
};

const paymentTypeDialogChangeTypes: ChangeRequestTypeEnum[] = [ ChangeRequestTypeEnum.PaymentType, ChangeRequestTypeEnum.RateChange, ChangeRequestTypeEnum.Extension, ChangeRequestTypeEnum.RateCorrection ];
// const summaryLinkStyle: React.CSSProperties = { color: 'inherit', textDecoration: 'none' };
const entityTypeId = 17;

export default function ChangeRequestRecord() {
    const [changeRequest, setChangeRequest] = useState<ChangeRequest>();
    const [placement, setPlacement] = useState<Placement>();
    const [latestRate, setLatestRate] = useState<PlacementRateChangeRequest>();
    const [emailRecipient, setEmailRecipient] = useState<EmailRecipient | null>(null);
    const [smsRecipient, setSmsRecipient] = useState<EmailRecipient | null>(null);
    const [contactEntityType, setContactEntityType] = useState(2);
    const [isLoading, setIsLoading] = useState(false);
    const [isFetchingDefaultTab, setIsFetchingDefaultTab] = useState(false);
    const [longSuccessMessage, setLongSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [isLoadingTab, setIsLoadingTab] = useState(false);
    const [searchParams] = useSearchParams();
    const [showApproveDialog, setShowApproveDialog] = useState(false);
    const [showRejectDialog, setShowRejectDialog] = useState(false);
    const [showRescindDialog, setShowRescindDialog] = useState(false);
    const [showReverseDialog, setShowReverseDialog] = useState(false);
    const [isFinishedSetup, setIsFinishedSetup] = useState(false);
    const [generateDocumentType, setGenerateDocumentType] = useState<'client' | 'candidate' | ''>('');
    const [myUserId, setMyUserId] = useState(0);
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    const [previewType, setPreviewType] = useState<PreviewEntityType | ''>('');
    const [previewRecordId, setPreviewRecordId] = useState(0);
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [ratesCalculatorLink, setRatesCalculatorLink] = useState('');
    const params = useParams();

    const [activeTab, setActiveTab] = useState('Changes');
    // const [tabActionMenuOptions, setTabActionMenuOptions] = useState<MenuOptionDefinition[]>([]);
    const navigate = useNavigate();

    // const canAccessPlacements = useMemo(() => companyHasSinglePermission(Permission.Placements) && userHasSinglePermission(Permission.Placements), []);
    const canAddEditPlacements = useMemo(() => AllowPlacementsAddEdit(), []);
    const canApprovePlacements = useMemo(() => companyHasSinglePermission(Permission.FinancialController) && userHasSinglePermission(Permission.FinancialController), []);
    const canGenerateDocuments = useMemo(() => companyHasSinglePermission(Permission.DocumentTemplates) && userHasSinglePermission(Permission.DocumentTemplates), []);
    const userCanSendEmail = useMemo(() => userHasSinglePermission(Permission.MessageCentre) && companyHasSinglePermission(Permission.MessageCentre), []);
    const userCanSendSms = useMemo(() => userHasSinglePermission(Permission.MessageCentreSms) && companyHasSinglePermission(Permission.MessageCentreSms), []);

    const changeRequestId = useMemo(() => {
        const parsedId = +(params.id ?? '0');
        if (isNaN(parsedId)) return 0;
        return parsedId;
    }, [params.id]);

    // const changeRequestIdArray = useMemo(() => [changeRequestId], [changeRequestId]);

    useEffect(() => {
        const getSettings = async () => {
            const settings = await GetPlacementsSettings();
            if (settings && settings.length > 0) {
                const rateSetting = settings.find(s => s.type === 'PlacementRatesCalculator');
                if (rateSetting && rateSetting.value) setRatesCalculatorLink(rateSetting.value);
            }
        };
        getSettings();
    }, [canApprovePlacements]);

    const getData = useCallback(async () => {
        setIsLoading(true);
        const res = await GetChangeRequestById(changeRequestId);
        if (res === null) navigate('/not-found');
        if (res) {
            setChangeRequest(res);
            const p = await GetPlacementById(res.placementID);
            if (p) setPlacement(p);
            const lr = await GetLatestPlacementRate(res.placementID);
            if (lr) setLatestRate(lr);
        }

        const user = await GetMyUser();
        if (user) setMyUserId(user.userID);
        setIsLoading(false);
    }, [changeRequestId, navigate]);

    useEffect(() => {
        getData();
    }, [getData]);

    useEffect(() => {
        const tabParam = searchParams.get('tab');
        const getData = async () => {
            setIsFetchingDefaultTab(true);
            if (!tabParam) {
                const tabSetting = await GetSettingBySettingName('TabDefaultsChangeRequests');
                if (tabSetting) setActiveTab(tabSetting);
            }
            else setActiveTab(tabParam)
            setIsFetchingDefaultTab(false);
            setIsFinishedSetup(true);
        };
        getData();
    }, [searchParams]);

    const sendEmailCallback = useCallback((type: 'billing' | 'candidate' | 'contact' | 'hiring' | 'pay-contact') => {
        if (changeRequest && placement) {
            let id = 0;
            let email = '';
            let name = '';
            let isOptOut = false;
            let contactEntityType = 2;
            switch (type) {
                case 'billing':
                    id = placement.billingContactContactID;
                    name = placement.billingContactName ?? '';
                    email = placement.billingContactEmail;
                break;
                case 'candidate':
                    id = placement.candidateID;
                    email = placement.candidateEmail;
                    name = placement.candidateName;
                    isOptOut = placement.candidateOptOut;
                    contactEntityType = 3;
                break;
                case 'contact':
                    id = placement.contactID;
                    email = placement.contactEmail;
                    name = placement.contactName;
                break;
                case 'hiring':
                    id = placement.hiringManagerContactID;
                    email = placement.hiringManagerEmail;
                    name = placement.hiringManagerName;
                break;
                case 'pay-contact':
                    id = placement.paymentContactID;
                    email = placement.paymentContactEmail ?? '';
                    name = placement.paymentContactName ?? '';
            }

            const r: EmailRecipient = {
                id: id,
                mobile: '',
                email: email,
                name: name,
                placement: placement,
                changeRequest: changeRequest,
                isOptOut: isOptOut,
            };
            setContactEntityType(contactEntityType);
            setEmailRecipient(r);
        }
    }, [changeRequest, placement]);

    const sendSmsCallback = useCallback((type: 'billing' | 'candidate' | 'contact' | 'hiring' | 'pay-contact') => {
        if (changeRequest && placement) {
            let id = 0;
            let phone = '';
            let name = '';
            let isOptOut = false;
            let contactEntityType = 2;
            switch (type) {
                case 'billing':
                    id = placement.billingContactContactID;
                    name = placement.billingContactName ?? '';
                    phone = placement.billingContactMobile_Standardised;
                break;
                case 'candidate':
                    id = placement.candidateID;
                    phone = placement.candidateTel1_Standardised ?? '';
                    name = placement.candidateName;
                    isOptOut = placement.candidateOptOut;
                    contactEntityType = 3;
                break;
                case 'contact':
                    id = placement.contactID;
                    phone = placement.contactMobile_Standardised;
                    name = placement.contactName;
                break;
                case 'hiring':
                    id = placement.hiringManagerContactID;
                    phone = placement.hiringManagerMobile_Standardised;
                    name = placement.hiringManagerName;
                break;
                case 'pay-contact':
                    id = placement.paymentContactID;
                    phone = placement.paymentContactMobile_Standardised ?? '';
                    name = placement.paymentContactName ?? '';
                break;
            }

            const r: EmailRecipient = {
                id: id,
                mobile: phone,
                email: '',
                name: name,
                placement: placement,
                changeRequest: changeRequest,
                isOptOut: isOptOut,
            };
            setContactEntityType(contactEntityType);
            setSmsRecipient(r);
        }
    }, [changeRequest, placement]);

    const actionMenuDefinitions = useMemo<MenuOptionDefinition[]>(() => {
        const decisionId = changeRequest ? changeRequest.decisionID : 0;
        const isCreatedByMe = changeRequest ? changeRequest.createdByUserID === myUserId : false;
        const createdRateId = changeRequest ? changeRequest.createdRateID : 0;
        const latestRateId = latestRate ? latestRate.id : 0;
        
        let actions: (MenuOptionDefinition)[] = [
            { label: 'Approve', type: 'action', action: () => setShowApproveDialog(true), allow: () => canApprovePlacements && decisionId === ChangeRequestDecisionEnum.Pending },
            { label: 'Reject', type: 'action', action: () => setShowRejectDialog(true), allow: () => canApprovePlacements && decisionId === ChangeRequestDecisionEnum.Pending },
            { label: 'Rescind', type: 'action', action: () => setShowRescindDialog(true), allow: () => isCreatedByMe && decisionId === ChangeRequestDecisionEnum.Pending },
            { label: 'Reverse', type: 'action', action: () => setShowReverseDialog(true), allow: () => canApprovePlacements && decisionId === ChangeRequestDecisionEnum.Approved && createdRateId === latestRateId && createdRateId !== 0 },
            { label: 'Generate Document', type: 'parent', allow: () => canGenerateDocuments && changeRequest?.changeTypeID !== 6, subMenu: [
                { label: 'Candidate Documents', type: 'action', action: () => setGenerateDocumentType('candidate'), allow: () => canGenerateDocuments },
                { label: 'Client Documents', type: 'action', action: () => setGenerateDocumentType('client'), allow: () => canGenerateDocuments },
            ] },
            { label: 'Send Email', type: 'parent', allow: () => userCanSendEmail, subMenu: [
               { label: 'To Billing Contact', type: 'action', action: () => sendEmailCallback('billing') },
               { label: 'To Candidate', type: 'action', action: () => sendEmailCallback('candidate') },
               { label: 'To Contact', type: 'action', action: () => sendEmailCallback('contact') },
               { label: 'To Hiring Manager', type: 'action', action: () => sendEmailCallback('hiring') },
               { label: 'To Pay Contact', type: 'action', action: () => sendEmailCallback('pay-contact') },
            ] },
            { label: 'Send SMS', type: 'parent', allow: () => userCanSendSms, subMenu: [
               { label: 'To Billing Contact', type: 'action', action: () => sendSmsCallback('billing') },
               { label: 'To Candidate', type: 'action', action: () => sendSmsCallback('candidate') },
               { label: 'To Contact', type: 'action', action: () => sendSmsCallback('contact') },
               { label: 'To Hiring Manager', type: 'action', action: () => sendSmsCallback('hiring') },
               { label: 'To Pay Contact', type: 'action', action: () => sendSmsCallback('pay-contact') },
            ] },
        ];

        return actions;
    }, [changeRequest, canApprovePlacements, canGenerateDocuments, myUserId, latestRate, userCanSendEmail, userCanSendSms, sendEmailCallback, sendSmsCallback]);

    const SummaryBar = useMemo(() => {
        if (changeRequest) {

            const action1 = canAddEditPlacements && changeRequest.decisionID === ChangeRequestDecisionEnum.Pending ? (
                <Button variant="contained" color="success" onClick={() => setShowEditDialog(true)} sx={{ mr: '5px' }}>Edit</Button>
            ) : <React.Fragment key="editPlaceholder" />;

            const action = (
                <Box key="actionsMenu">
                    <ActionMenu definition={actionMenuDefinitions} />
                </Box>
            );            

            return (
                <TitleAndActionSummaryBar
                    height="4.5rem"
                    title={changeRequest ? renderSummary(changeRequest) : ''}
                    action={[action1, action]}
                    browserTabTitle={!changeRequest ? '' : "Change Requests"}
                />
            );
        }
    }, [changeRequest, canAddEditPlacements, actionMenuDefinitions]);


    const handleTabChange = useCallback((e: React.SyntheticEvent, newValue: string) => {
        navigate(`/placements/change-requests/${changeRequestId}?tab=${newValue}`);
    }, [changeRequestId, navigate]);

    const counts = useMemo(() => {
        let counts = {
            documents: 0,
            placementDocuments: 0
        };

        if (changeRequest && changeRequest.statisticsCounts && changeRequest.statisticsCounts.length > 0) {
            for (let i = 0; i < changeRequest.statisticsCounts.length; i++) {
                const s = changeRequest.statisticsCounts[i];
                switch (s.type) {
                    case "DocumentsCount": counts.documents = s.value;
                        break;
                }
            }
        }

        if (changeRequest && changeRequest.placementStatisticsCounts && changeRequest.placementStatisticsCounts.length > 0) {
            for (let i = 0; i < changeRequest.placementStatisticsCounts.length; i++) {
                const s = changeRequest.placementStatisticsCounts[i];
                switch (s.type) {
                    case "DocumentsCount": counts.placementDocuments = s.value;
                        break;
                }
            }
        }

        return counts;
    }, [changeRequest]);

    const approveChangeCallback = useCallback(async () => {
        setIsLoading(true);
        const res = await ApproveChangeRequest(changeRequestId, setErrorMessage);
        if (res) {
            

            if(changeRequest)
            {
                const date = new Date(changeRequest.effectiveDate);
                const now = new Date();
                
                if(date <= now) {
                    setLongSuccessMessage('The changes to the Placement will be applied within 1 Hour.');
                }
                else{
                    setLongSuccessMessage('The changes to the Placement will be applied on .' + formatDateString(changeRequest.effectiveDate, 'DD MMM YYYY', ''));
                }
            }
            
            setShowApproveDialog(false);

            const res2 = await GetChangeRequestById(changeRequestId);
            if (res2) {
                setChangeRequest(res2);
            }

            if(changeRequest)
            {
                const date = new Date(changeRequest.effectiveDate);
                const now = new Date();

                if(date <= now) {
                    setLongSuccessMessage('The changes to the Placement will be applied within 15 minutes.');
                }
                else{
                    setLongSuccessMessage('The changes to the Placement will be applied on ' + formatDateString(changeRequest.effectiveDate, 'DD MMM YYYY', ''));
                }
            }

        }
        setIsLoading(false);
    }, [changeRequestId, changeRequest]);

    const redirectToPlacementOnSuccessHandler = useCallback((message: string) => {
        const placementId = changeRequest ? changeRequest.placementID : 0;
        navigate(`/placements/${placementId}`);
    }, [changeRequest, navigate]);

    const handlePreviewHover = useCallback((type: PreviewEntityType | '', id: number, isTags: boolean = false, recordName: string = '', noDelay: boolean = false) => {
        setPreviewType(type);
        setPreviewRecordId(id);
        setIsPreviewOpen(true);
    }, []);

    const handlePreviewClose = useCallback(() => {
        setIsPreviewOpen(false);
        // setPreviewType('')
    }, []);

    const handleEditDialogSuccess = useCallback((message: string) => {
        setSuccessMessage(message);
        getData();
    }, [getData]);

    const placementId = useMemo(() => changeRequest ? changeRequest.placementID : 0, [changeRequest]);
    const candidateId = useMemo(() => changeRequest ? changeRequest.candidateID : 0, [changeRequest]);
    const candidateName = useMemo(() => changeRequest ? changeRequest.candidateName : '', [changeRequest]);
    const clientId = useMemo(() => changeRequest ? changeRequest.clientID : 0, [changeRequest]);
    const clientName = useMemo(() => changeRequest ? changeRequest.clientName : '', [changeRequest]);

    return (
        <>
            <Snackbar open={longSuccessMessage !== ''} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert onClose={() => setLongSuccessMessage('')}>{ longSuccessMessage }</Alert>
            </Snackbar>
            <Snackbar open={successMessage !== ''} autoHideDuration={3000} onClose={() => setSuccessMessage('')}>
                <Alert onClose={() => setSuccessMessage('')}>{ successMessage }</Alert>
            </Snackbar>
            <Snackbar open={errorMessage !== ''} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <PreviewLoaderComponent
                open={isPreviewOpen}
                entityType={previewType}
                recordId={previewRecordId}
            />
            <SendEmailDialog
                open={ emailRecipient !== null }
                sourceEntityId={entityTypeId}
                isPlacementList
                allowCcField
                jobId={placement ? placement.jobID : 0}
                recipients={ emailRecipient ? [emailRecipient] : [] }
                recipientEntityTypeId={ contactEntityType }
                closeHandler={ () => setEmailRecipient(null) }
                loadingHandler={setIsLoading}
                errorHandler={setErrorMessage}
                successHandler={setSuccessMessage}
            />
            <SendSmsDialog
                open={ smsRecipient !== null }
                sourceEntityId={entityTypeId}
                recipients={ smsRecipient ? [smsRecipient] : [] }
                recipientEntityTypeId={ contactEntityType }
                closeHandler={ () => setSmsRecipient(null) }
                loadingHandler={setIsLoading}
                errorHandler={setErrorMessage}
                successHandler={setSuccessMessage}
            />
            <ConfirmationDialog
                message="Are you sure you want to approve this change?"
                onClose={ () => setShowApproveDialog(false) }
                onContinue={ approveChangeCallback }
                open={showApproveDialog}
                title="Approve Change"
                confirmActionText="Yes"
                cancelActionText="No"
            />
            <RejectChangeRequestDialog
                open={showRejectDialog}
                changeRequestId={changeRequestId}
                closeHandler={() => setShowRejectDialog(false)}
                loadingHandler={setIsLoadingTab}
                errorHandler={setErrorMessage}
                successHandler={redirectToPlacementOnSuccessHandler}
            />
            <ReverseChangeRequestDialog
                open={showReverseDialog}
                changeRequestId={changeRequestId}
                closeHandler={() => setShowReverseDialog(false)}
                loadingHandler={setIsLoadingTab}
                errorHandler={setErrorMessage}
                successHandler={redirectToPlacementOnSuccessHandler}
            />
            <RescindChangeRequestDialog
                open={showRescindDialog}
                changeRequestId={changeRequestId}
                closeHandler={() => setShowRescindDialog(false)}
                loadingHandler={setIsLoadingTab}
                errorHandler={setErrorMessage}
                successHandler={redirectToPlacementOnSuccessHandler}
            />
            <GenerateDocumentDialog
                open={Boolean(generateDocumentType)}
                entityId={entityTypeId}
                playerId={changeRequestId}
                placementDocType={generateDocumentType}
                closeHandler={() => setGenerateDocumentType('')}
                loadingHandler={ setIsLoading }
                errorHandler={ setErrorMessage }
                successHandler={ setSuccessMessage }
            />
            {changeRequest && paymentTypeDialogChangeTypes.includes(changeRequest.changeTypeID) &&
                <EditChangePaymentTypeDialog
                    open={showEditDialog}
                    changeRequest={changeRequest}
                    ratesCalculatorLink={ratesCalculatorLink}
                    closeHandler={() => setShowEditDialog(false)}
                    loadingHandler={ setIsLoading }
                    errorHandler={ setErrorMessage }
                    successHandler={handleEditDialogSuccess}
                />
            }
            {changeRequest && changeRequest.changeTypeID === ChangeRequestTypeEnum.ConsultantsChange &&
                <EditChangeConsultantsDialog
                    open={showEditDialog}
                    changeRequest={changeRequest}
                    ratesCalculatorLink={ratesCalculatorLink}
                    closeHandler={() => setShowEditDialog(false)}
                    errorHandler={setErrorMessage}
                    loadingHandler={setIsLoading}
                    successHandler={handleEditDialogSuccess}
                />
            }
            {changeRequest && changeRequest.changeTypeID === ChangeRequestTypeEnum.ConvertToPerm && 
                <EditConvertToPermDialog
                    open={showEditDialog}
                    changeRequest={changeRequest}
                    closeHandler={() => setShowEditDialog(false)}
                    errorHandler={setErrorMessage}
                    loadingHandler={setIsLoading}
                    successHandler={handleEditDialogSuccess}
                />
            }
            {changeRequest && changeRequest.changeTypeID === ChangeRequestTypeEnum.StartDateChange &&
                <EditChangeStartDateDialog
                    open={showEditDialog}
                    changeRequest={changeRequest}
                    closeHandler={() => setShowEditDialog(false)}
                    errorHandler={setErrorMessage}
                    loadingHandler={setIsLoading}
                    successHandler={handleEditDialogSuccess}
                />
            }
            <PageLayout paddingTop={0} SummaryBar={SummaryBar}>
                <Tabs value={activeTab} onChange={handleTabChange}>
                    <Tab value="Home" label={<>Home<br />&nbsp;</>} />
                    <Tab value="Changes" label={<>Changes<br />&nbsp;</>} />
                    <Tab value="Documents" label={<>Documents ({counts.documents})<br />&nbsp;</>} />
                    <Tab value="Placement_Documents" label={<>Placement Documents ({counts.placementDocuments})<br />&nbsp;</>} />
                </Tabs>
                <PageContentLayout showLoading={isLoading || isFetchingDefaultTab || isLoadingTab}>
                    { isFinishedSetup && changeRequest && activeTab === "Home" &&
                        <Box display="flex" pb="20px">
                            <Box>
                                <Box display="flex" minHeight="36px" alignItems="center">
                                    <Box width="150px" fontWeight="bold">ID</Box>
                                    <Box>{changeRequest ? changeRequest.id : ''}</Box>
                                </Box>
                                <Box display="flex" minHeight="36px" alignItems="center">
                                    <Box width="150px" fontWeight="bold">Placement</Box>
                                    <Box>
                                        <Box
                                            component="span"
                                            color="primary.main"
                                            onMouseEnter={ () => handlePreviewHover("placement", placementId) }
                                            onMouseLeave={ () => handlePreviewClose() }
                                        >
                                            {changeRequest ? PREVIEW_LINK_RENDERER(`/placements/${placementId}`, placementId, undefined, 'none') : ''}
                                        </Box>
                                    </Box>
                                </Box>
                                <Box display="flex" minHeight="36px" alignItems="center">
                                    <Box width="150px" fontWeight="bold">Placement Type</Box>
                                    <Box>{placement ? placement.placementTypeName : ''}</Box>
                                </Box>
                                <Box display="flex" minHeight="36px" alignItems="center">
                                    <Box width="150px" fontWeight="bold">Payment Type</Box>
                                    <Box>{placement ? placement.paymentTypeName : ''}</Box>
                                </Box>
                                <Box display="flex" minHeight="36px" alignItems="center">
                                    <Box width="150px" fontWeight="bold">Candidate</Box>
                                    <Box>
                                        <Box
                                            component="span"
                                            color="primary.main"
                                            onMouseEnter={ () => handlePreviewHover("candidate", candidateId) }
                                            onMouseLeave={ () => handlePreviewClose() }
                                        >
                                            {changeRequest ? PREVIEW_LINK_RENDERER(`/candidates/${candidateId}`, candidateName, undefined, 'none') : ''}</Box>
                                        </Box>
                                </Box>
                                <Box display="flex" minHeight="36px" alignItems="center">
                                    <Box width="150px" fontWeight="bold">Client</Box>
                                    <Box>
                                        <Box
                                            component="span"
                                            color="primary.main"
                                            onMouseEnter={ () => handlePreviewHover("client", clientId) }
                                            onMouseLeave={ () => handlePreviewClose() }
                                        >
                                            {changeRequest ? PREVIEW_LINK_RENDERER(`/clients/${clientId}`, clientName, undefined, 'none') : ''}</Box>
                                        </Box>
                                </Box>                                 
                                <Box display="flex" minHeight="36px" alignItems="center">
                                    <Box width="150px" fontWeight="bold">Requested By</Box>
                                    <Box>{changeRequest ? changeRequest.createdByUserName : ''}</Box>
                                </Box>
                                <Box display="flex" minHeight="36px" alignItems="center">
                                    <Box width="150px" fontWeight="bold">Effective Date</Box>
                                    <Box>{changeRequest ? formatDateString(changeRequest.effectiveDate, 'DD MMM YYYY', '') : ''}</Box>
                                </Box>
                                <Box display="flex" minHeight="36px" alignItems="center">
                                    <Box width="150px" fontWeight="bold">Request Date</Box>
                                    <Box>{changeRequest ? formatDateString(changeRequest.createdDate, 'DD MMM YYYY', '') : ''}</Box>
                                </Box>
                                <Box display="flex" minHeight="36px" alignItems="center">
                                    <Box width="150px" fontWeight="bold">Decision</Box>
                                    <Box>{changeRequest ? changeRequest.decisionName : ''}</Box>
                                </Box>
                                <Box display="flex" minHeight="36px" alignItems="center">
                                    <Box width="150px" fontWeight="bold">Decision By</Box>
                                    <Box>{changeRequest ? changeRequest.decisionByUserName : ''}</Box>
                                </Box>
                                <Box display="flex" minHeight="36px" alignItems="center">
                                    <Box width="150px" fontWeight="bold">Decision Date</Box>
                                    <Box>{changeRequest ? formatDateString(changeRequest.decisionDate, 'DD MMM YYYY', '') : ''}</Box>
                                </Box>
                                {changeRequest && changeRequest.decisionID === ChangeRequestDecisionEnum.Rejected &&
                                    <Box display="flex" minHeight="36px" alignItems="center">
                                        <Box width="150px" fontWeight="bold">Decision Reason</Box>
                                        <Box>{changeRequest.decisionReason}</Box>
                                    </Box>
                                }
                                {changeRequest && changeRequest.decisionID === ChangeRequestDecisionEnum.Reversed &&
                                    <>
                                        <Box display="flex" minHeight="36px" alignItems="center">
                                            <Box width="150px" fontWeight="bold">Reversal By</Box>
                                            <Box>{changeRequest.reversalByUserName}</Box>
                                        </Box>
                                        <Box display="flex" minHeight="36px" alignItems="center">
                                            <Box width="150px" fontWeight="bold">Reversal Date</Box>
                                            <Box>{formatDateString(changeRequest.reversalDate, 'DD MMM YYYY', '')}</Box>
                                        </Box>
                                        <Box display="flex" minHeight="36px" alignItems="center">
                                            <Box width="150px" fontWeight="bold">Reversal Reason</Box>
                                            <Box>{changeRequest.reversalReason}</Box>
                                        </Box>
                                    </>
                                }
                            </Box>
                        </Box>
                    }
                    {isFinishedSetup && activeTab === "Changes" && changeRequest &&
                        <ChangeRequestFieldsGrid
                            gridName="change-request/changes"
                            changeTypeId={changeRequest.changeTypeID}
                            existingJson={changeRequest.existingRateJSON}
                            newValuesJson={changeRequest.json}
                            previewHandler={handlePreviewHover}
                            previewCloseHanlder={handlePreviewClose}
                        />
                    }
                    { isFinishedSetup && activeTab === "Documents" &&
                        <DocumentsGridComponent
                            gridName="placement/Documents"
                            source="change-request-record"
                            sourceId={changeRequestId}
                            loadingHandler={setIsLoadingTab}
                            errorHandler={setErrorMessage}
                            successHandler={setSuccessMessage}
                        />
                    }
                    { isFinishedSetup && activeTab === "Placement_Documents" &&
                        <DocumentsGridComponent
                            gridName="placement/Documents"
                            source="placement-record"
                            sourceId={changeRequest?.placementID}
                            loadingHandler={setIsLoadingTab}
                            errorHandler={setErrorMessage}
                            successHandler={setSuccessMessage}
                        />
                    }
                </PageContentLayout>
            </PageLayout>
        </>
    );
}