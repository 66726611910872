import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import MicIcon from '@mui/icons-material/Mic';
import StopIcon from '@mui/icons-material/Stop';
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
import { Editor } from "@tinymce/tinymce-react/lib/cjs/main/ts/components/Editor";
import { useTheme } from "@mui/material/styles";
import * as tinymce from 'tinymce';
import { AddNotesToInterview, AddNotesToInterviewWithFollowUp } from "services/InterviewsService";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import useActiveUsers from "hooks/UseActiveUsers";
import useActiveGroups from "hooks/UseActiveGroups";
import ActionsSplitButton from "components/SummaryBars/Actions/ActionsSplitButton";
import MenuItem from "@mui/material/MenuItem";
import ConfirmationWithDateAndUserDialog from "../Generic/ConfirmationWithDateAndUserDialog";
import moment from "moment";

interface Props {
    open: boolean,
    interviewId: number,
    initialNotes: string,
    closeHandler: () => void,
    loadingHandler?: (isLoading: boolean) => void,
    successHandler?: (message: string, notes: string) => void,
    errorHandler?: (message: string) => void
}

const isoDateFormat = "YYYY-MM-DDTHH:mm";

export default function AddNotesToInterviewDialog({ open, interviewId, initialNotes, closeHandler, loadingHandler, successHandler, errorHandler }: Props) {
    const [isComponentMounted, setIsComponentMounted] = useState(false);
    const [isEditorDirty, setIsEditorDirty] = useState(false);
    const editorRef = useRef<tinymce.Editor | null>(null);
    const [showCustomFollowUp, setShowCustomFollowUp] = useState(false);
    const { browserSupportsSpeechRecognition, isMicrophoneAvailable, listening, interimTranscript, finalTranscript, resetTranscript } = useSpeechRecognition();
    const theme = useTheme();
    const isDarkTheme = useMemo(() => theme.palette.mode === 'dark', [theme.palette.mode]);
    const activeUsers = useActiveUsers();
    const activeGroups = useActiveGroups();

    useEffect(() => setIsComponentMounted(true) , []);

    useEffect(() => {
        if (open && isComponentMounted) {
            const api = editorRef.current;
            if (api) api.setContent(initialNotes);
        }
    }, [open, initialNotes, isComponentMounted]);

    useEffect(() => {
        if (browserSupportsSpeechRecognition && isMicrophoneAvailable && listening) {
            if (finalTranscript !== '' && editorRef.current) {
                editorRef.current.insertContent(finalTranscript + ' ');
                resetTranscript();
            }
        }
    }, [browserSupportsSpeechRecognition, isMicrophoneAvailable, listening, finalTranscript, resetTranscript]);

    const submitCallback = useCallback(async () => {
        const api = editorRef.current;
        if (api) {
            const notes = api.getContent();
            loadingHandler && loadingHandler(true);
            const res = await AddNotesToInterview(interviewId, notes, errorHandler);
            if (res) {
                successHandler && successHandler('Notes Added', notes);
                closeHandler();
            }
            loadingHandler && loadingHandler(false);
        }
    }, [interviewId, loadingHandler, errorHandler, successHandler, closeHandler]);

    const followUpCallback = useCallback(async (assignedTo: number, days: number, date?: string) => {
        const api = editorRef.current;
        if (api) {
            const notes = api.getContent();
            
            let followUpDate = '';
            if (date) followUpDate = date;
            else followUpDate = moment().add(days, 'days').format(isoDateFormat);
            
            loadingHandler && loadingHandler(true);
            const res = await AddNotesToInterviewWithFollowUp(interviewId, notes, assignedTo, followUpDate, errorHandler);
            if (res) {
                successHandler && successHandler('Notes Added With Follow Up', notes);
                setShowCustomFollowUp(false);
                closeHandler();
            }
            loadingHandler && loadingHandler(false);
        }
    }, [closeHandler, errorHandler, interviewId, loadingHandler, successHandler]);

    useEffect(() => {
        if (!open) {
            SpeechRecognition.stopListening();
            setIsEditorDirty(false);
        }
    }, [open]);

    return (
        <>
            <ConfirmationWithDateAndUserDialog
                open={showCustomFollowUp}
                title="Custom Follow Up"
                onClose={ () => setShowCustomFollowUp(false) }
                onContinue={ (date, userId) => followUpCallback(userId, 0, date) }
                dateType="datetime-local"
                userLabel="Assigned To"
                hideBackdrop
                disableUserClearable
            />
            <Dialog open={open} fullWidth maxWidth="md">
                <DialogTitle>Add Notes</DialogTitle>
                <DialogContent>
                    {isComponentMounted &&
                        <Editor
                            initialValue={initialNotes}
                            tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce-5.10.2/tinymce.min.js'}
                            onDirty={ () => setIsEditorDirty(true) }
                            onInit={ (evt, editor) => editorRef.current = editor }
                            init={{
                                height: '400',
                                skin: isDarkTheme ? 'oxide-dark' : undefined,
                                content_css: isDarkTheme ? 'dark' : undefined,
                                branding: false,
                                menubar: false,
                                contextmenu: false,
                                resize: false,
                                setup: (editor) => {
                                    if (activeUsers.length > 0 || activeGroups.length > 0) {
                                        
                                        const getMatchedMentions = (pattern: string) => {
                                            return activeUsers.filter( u => {
                                                return u.name.toLowerCase().indexOf(pattern.toLowerCase()) !== -1;
                                            });
                                        };

                                        const getMatchedGroups = (pattern: string) => {
                                            return activeGroups.filter(u => u.name.toLowerCase().indexOf(pattern.toLowerCase()) !== -1);
                                        }

                                        editor.ui.registry.addAutocompleter('mentions', {
                                            ch: '@',
                                            minChars: 1,
                                            columns: 1,
                                            onAction: (api, rng, value) => {
                                                editor.selection.setRng(rng);
                                                editor.insertContent(value);
                                                editor.insertContent(" ");
                                                api.hide();
                                            },
                                            fetch: async (pattern) => {
                                                const contents = getMatchedMentions(pattern).map(u => {
                                                    const options: tinymce.Ui.InlineContent.AutocompleterContents = {
                                                        type: 'autocompleteitem',
                                                        text: u.name,
                                                        value: `<strong data-umention="${u.id}">${u.name}</strong> `
                                                    }
                                                    return options;
                                                });

                                                const gContents = getMatchedGroups(pattern).map(g => {
                                                    const options: tinymce.Ui.InlineContent.AutocompleterContents = {
                                                        type: 'autocompleteitem',
                                                        text: `${g.name} [GROUP]`,
                                                        value: `<strong data-gmention="${g.id}">${g.name}</strong> `
                                                    };
                                                    return options;
                                                })

                                                return [...contents, ...gContents];
                                            }
                                        });
                                    }
                                }
                            }}
                        />
                    }
                </DialogContent>
                <DialogActions>
                    <Box mr="auto" display="flex" alignItems="center" flexGrow="1">
                        {browserSupportsSpeechRecognition && !listening &&
                            <IconButton onClick={ () => SpeechRecognition.startListening({language: 'en-AU', continuous: true}) }><MicIcon /></IconButton>
                        }
                        {browserSupportsSpeechRecognition && listening &&
                            <>
                                <IconButton onClick={ () => SpeechRecognition.stopListening() }><StopIcon /></IconButton>
                                {interimTranscript === '' && <span style={{ padding: '0 10px' }}>Listening...</span>}
                                <div style={{
                                    flexGrow: 1,
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    maxWidth: '510px',
                                    padding: '0 10px'
                                }} >{interimTranscript}</div>
                            </>
                        }
                    </Box>
                    <Button variant="contained" color="error" onClick={ closeHandler }>Cancel</Button>
                    <ActionsSplitButton mainAction={ submitCallback } mainActionDisabled={!isEditorDirty} secondaryActionDisabled={!isEditorDirty} color="success" menuPlacement="top-end">
                        <MenuItem onClick={ () => followUpCallback(0, 1) }>Submit + Follow up 1 Day</MenuItem>
                        <MenuItem onClick={ () => followUpCallback(0, 7) }>Submit + Follow up 1 Week</MenuItem>
                        <MenuItem onClick={ () => followUpCallback(0, 14) }>Submit + Follow up 2 Weeks</MenuItem>
                        <MenuItem onClick={ () => followUpCallback(0, 30) }>Submit + Follow up 1 Month</MenuItem>
                        <MenuItem onClick={ () => followUpCallback(0, 90) }>Submit + Follow up 3 Months</MenuItem>
                        <MenuItem onClick={ () => followUpCallback(0, 180) }>Submit + Follow up 6 Months</MenuItem>
                        <MenuItem onClick={ () => setShowCustomFollowUp(true) }>Submit + Follow up Custom</MenuItem>
                    </ActionsSplitButton>
                </DialogActions>
            </Dialog>
        </>
    );
}