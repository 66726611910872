import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import { SxProps } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { DateTimeRangePicker } from "@mui/x-date-pickers-pro/DateTimeRangePicker";
import { DateRange } from "@mui/x-date-pickers-pro/models/range";
import { NameIdObj } from "common/models/GenericTypes";
import { ChangeRequestSearch } from "common/models/Search/ChangeRequestSearch";
import ChangeRequestsGridComponent from "components/Grids/ChangeRequestsGrid";
import MultipleNumericValuesPicker from "components/Pickers/MultipleNumericValuesPicker";
import MultipleUserPicker from "components/Pickers/MultipleUserPicker";
import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import PageContentLayout from "layouts/PageContentLayout";
import PageLayout from "layouts/PageLayout";
import React, { useCallback, useMemo, useState } from "react";

const typeOptions: NameIdObj[] = [
    { id: 0, name: 'All' },
    { id: 8, name: 'Client Reference' },
    { id: 3, name: 'Consultants Change' },
    { id: 2, name: 'Convert to Perm' },
    { id: 4, name: 'Extension' },
    { id: 6, name: 'Payment Type' },
    { id: 1, name: 'Rate Change' },
    { id: 7, name: 'Rate Correction' },
    { id: 5, name: 'Start Date Change' },
];

const decisionOptions: NameIdObj[] = [
    { id: -1, name: 'All' },
    { id: 0, name: 'Awaiting Decision' },
    { id: 1, name: 'Approved' },
    { id: 2, name: 'Rejected' },
    { id: 3, name: 'Reversed' },
    { id: 4, name: 'Rescinded' },
];

const filterElementStyle: SxProps = { flex: '1 1 0' };
const userPickerAllOption: NameIdObj[] = [{ id: 0, name: 'All' }];
const isoDateTimeFormat = 'YYYY-MM-DDTHH:mm';

export default function ChangeRequestSearchPage() {
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    // const [showGrid, setShowGrid] = useState(false);
    const [activeTab, setActiveTab] = useState('criteria');
    const [typeFilter, setTypeFilter] = useState([0]);
    const [decisionFilter, setDecisionFilter] = useState([-1]);
    const [requestByFilter, setRequestByFilter] = useState([0]);
    const [decisionByFilter, setDecisionByFilter] = useState([0]);
    const [createdDateFilter, setCreatedDateFilter] = useState<DateRange<moment.Moment>>([null, null]);
    const [decisionDateFilter, setDecisionDateFilter] = useState<DateRange<moment.Moment>>([null, null]);
    const [effectiveDateFilter, setEffectiveDateFilter] = useState<DateRange<moment.Moment>>([null, null]);
    const [searchData, setSearchData] = useState<ChangeRequestSearch>();

    const searchCallback = useCallback(async () => {
        const createdFrom = createdDateFilter[0] ? createdDateFilter[0].format(isoDateTimeFormat) : undefined;
        const createdTo = createdDateFilter[1] ? createdDateFilter[1].format(isoDateTimeFormat) : undefined;
        const decisionFrom = decisionDateFilter[0] ? decisionDateFilter[0].format(isoDateTimeFormat) : undefined;
        const decisionTo = decisionDateFilter[1] ? decisionDateFilter[1].format(isoDateTimeFormat) : undefined;
        const effectiveFrom = effectiveDateFilter[0] ? effectiveDateFilter[0].format(isoDateTimeFormat) : undefined;
        const effectiveTo = effectiveDateFilter[1] ? effectiveDateFilter[1].format(isoDateTimeFormat) : undefined;
        const s: ChangeRequestSearch = {
            type: typeFilter,
            decision: decisionFilter,
            requestBy: requestByFilter,
            decisionBy: decisionByFilter,
            createDateFrom: createdFrom,
            createDateTo: createdTo,
            decisionDateFrom: decisionFrom,
            decisionDateTo: decisionTo,
            effectiveDateFrom: effectiveFrom,
            effectiveDateTo: effectiveTo,
        }

        setSearchData(s);
        setActiveTab('results');
    }, [createdDateFilter, decisionByFilter, decisionDateFilter, decisionFilter, effectiveDateFilter, requestByFilter, typeFilter]);

    const clearCallback = useCallback(() => {
        setTypeFilter([0]);
        setDecisionFilter([-1]);
        setRequestByFilter([0]);
        setDecisionByFilter([0]);
        setCreatedDateFilter([null, null]);
        setDecisionDateFilter([null, null]);
        setEffectiveDateFilter([null, null]);
    }, []);

    const summaryBar = useMemo(() => {
        const clearAction = <Button variant="contained" color="error" onClick={clearCallback} sx={{ mr: '5px' }}>Clear</Button>;
        const searchAction = <Button variant="contained" color="success" onClick={searchCallback}>Search</Button>;
        const actions = <>{clearAction}{searchAction}</>;

        const title = 'Placements > Change Requests > Search';
        return <TitleAndActionSummaryBar title={title} browserTabTitle="Search > Change Requests" action={actions} />
    }, [clearCallback, searchCallback]);

    const typeFilterChangeHandler = useCallback((values: NameIdObj[]) => {
        setTypeFilter(values.map(v => v.id));
    }, []);

    const decisionFilterChangeHandler = useCallback((values: NameIdObj[]) => {
        setDecisionFilter(values.map(v => v.id));
    }, []);

    const requestByFilterChangeHandler = useCallback((values: NameIdObj[]) => {
        setRequestByFilter(values.map(v => v.id));
    }, []);

    const decisionByFilterChangeHandler = useCallback((values: NameIdObj[]) => {
        setDecisionByFilter(values.map(v => v.id));
    }, []);

    const handleTabChange = useCallback((e: React.SyntheticEvent, newValue: string) => {
        setActiveTab(newValue);
    }, []);

    return (
        <PageLayout SummaryBar={summaryBar} paddingTop={0}>
            <Snackbar open={errorMessage !== ''} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <Tabs value={activeTab} onChange={handleTabChange}>
                <Tab value="criteria" label="Criteria" />
                <Tab value="results" label="Results"/>
            </Tabs>
            <PageContentLayout showLoading={isLoading}>
                <Stack spacing={2} paddingTop={2} display={activeTab === 'criteria' ? undefined : 'none'}>
                    <Box display="flex" gap={1}>
                        <MultipleNumericValuesPicker label="Type" value={typeFilter} options={typeOptions} onSelect={typeFilterChangeHandler} sx={filterElementStyle} />
                        <MultipleNumericValuesPicker label="Decision" value={decisionFilter} options={decisionOptions} onSelect={decisionFilterChangeHandler} sx={filterElementStyle} />
                    </Box>
                    <Box display="flex" gap={1}>
                        <MultipleUserPicker label="Request By" value={requestByFilter} onSelect={requestByFilterChangeHandler} appendToStart={userPickerAllOption} sx={filterElementStyle} />
                        <MultipleUserPicker label="Decision By" value={decisionByFilter} onSelect={decisionByFilterChangeHandler} appendToStart={userPickerAllOption} sx={filterElementStyle} />
                    </Box>
                    <DateTimeRangePicker value={createdDateFilter} onChange={setCreatedDateFilter} localeText={{ start: 'Created Date From', end: 'Created Date To' }} />
                    <DateTimeRangePicker value={decisionDateFilter} onChange={setDecisionDateFilter} localeText={{ start: 'Decision Date From', end: 'Decision Date To' }} />
                    <DateTimeRangePicker value={effectiveDateFilter} onChange={setEffectiveDateFilter} localeText={{ start: 'Effective Date From', end: 'Effective Date To' }} />
                </Stack>
                <Box display={activeTab === 'results' ? 'flex' : 'none'} flexGrow={1} flexDirection="column">
                    <ChangeRequestsGridComponent gridName="change-request-search" source="search" searchData={searchData} loadingHandler={setIsLoading} errorHandler={setErrorMessage} />
                </Box>
            </PageContentLayout>
        </PageLayout>
    );
}