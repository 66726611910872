import { DataGridPremium, DataGridPremiumProps, GridColumnOrderChangeParams, GridColumnResizeParams, GridColumnVisibilityModel, GridPinnedColumnFields, GridRowClassNameParams, GridSortModel } from "@mui/x-data-grid-premium";
import { GridInitialStatePremium } from "@mui/x-data-grid-premium/models/gridStatePremium";
import { GridColumnDimensions } from "@mui/x-data-grid/hooks/features/columns/gridColumnsInterfaces";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { GetGridState, UpdateGridState } from "../services/GridsService";
import { GetSettingBySettingName } from "../services/UsersService";

interface Props extends DataGridPremiumProps {
    gridName: string,
    noContainer?: boolean,
    defaultViewModel?: GridColumnVisibilityModel,
    fromDialog?: boolean
}

export default function GridWithStateWrapper(props: Props) {
    const [fetchedState, setFetchedState] = useState(false);
    const [fetchedInitialState, setFetchedInitialState] = useState<GridInitialStatePremium>();
    const [orderedFields, setOrderedFields] = useState<string[]>();
    const [colDimensions, setColDimensions] = useState<Record<string, GridColumnDimensions>>();
    const [visibilityModel, setVisibilityModel] = useState<GridColumnVisibilityModel>();
    const [sortModel, setSortModel] = useState<GridSortModel>();
    const [columnsSwitch, setColumnsSwitch] = useState(false);
    const [hasChanges, setHasChanges] = useState(false);
    const [isStripedRows, setIsStripedRows] = useState(false);
    const isLightMode = useMemo(() => (localStorage.getItem('Theme') !== 'dark'), []);
    const rowClassName = useMemo(() => (isLightMode ? 'light-striped-grid-row' : 'dark-striped-grid-row'), [isLightMode]);

    const stripedTableRowClassHandler = useCallback((params: GridRowClassNameParams<any>) => {
        return params.indexRelativeToCurrentPage % 2 === 0 ? '' : rowClassName;
    }, [rowClassName]);

    useEffect(() => {
        const getSettings = async () => {
            const res = await GetSettingBySettingName('AlternateRowStylingOnGrids');
            if (res === 'True') setIsStripedRows(true);
        };
        getSettings();
    }, []);


    const gridName = useMemo(() => props.gridName ? props.gridName : '', [props.gridName]);
    const defaultViewModel = useMemo<GridColumnVisibilityModel>(() => {
        let m: GridColumnVisibilityModel = {};
        if (props.defaultViewModel) return props.defaultViewModel;
        return m;
    }, [props.defaultViewModel]);

    const defaultPinnedColumns = useMemo<GridPinnedColumnFields | undefined>(() => {
        if (props.initialState && props.initialState.pinnedColumns) return props.initialState.pinnedColumns;
        return undefined;
    }, [props.initialState]);

    useEffect(() => {
        if (props.columns) {}
        if (fetchedState) setColumnsSwitch(prev => !prev);
    }, [props.columns, fetchedState]);

    useEffect(() => {
        const getState = async () => {
            const state = await GetGridState(gridName);
            setFetchedState(false);
            if (state && state.columns) {
                setFetchedInitialState(state);
                setOrderedFields(state.columns.orderedFields);
                setColDimensions(state.columns.dimensions);
                setVisibilityModel(state.columns.columnVisibilityModel);
            }
            if (state && state.sorting && state.sorting.sortModel && state.sorting.sortModel.length > 0) {
                setSortModel(state.sorting.sortModel);
            }
            setFetchedState(true);
        };
        gridName && getState();
    }, [gridName]);

    useEffect(() => {
        if (fetchedState && gridName && hasChanges) {
            const delayGridStateSync = setTimeout(async () => {
                await UpdateGridState(gridName, {
                    columns: {
                        orderedFields: orderedFields,
                        dimensions: colDimensions,
                        columnVisibilityModel: visibilityModel,
                    },
                    sorting: { sortModel: sortModel }
                });
            }, 1000);

            return () => clearTimeout(delayGridStateSync);
        }
    }, [fetchedState, gridName, hasChanges, orderedFields, colDimensions, visibilityModel, sortModel]);

    const handleColumnOrderChange = useCallback((params: GridColumnOrderChangeParams, event: any, details: any) => {
        const api = props.apiRef;
        if (api) {
            const s = api.current.exportState();
            if (s.columns && s.columns.orderedFields) setOrderedFields(s.columns.orderedFields);
        }
        setHasChanges(true);
    }, [props.apiRef]);

    const handleColumnWidthChange = useCallback((params: GridColumnResizeParams, event: any, details: any) => {
        const field = params.colDef.field;
        setColDimensions(prev => {
            if (prev) {
                const dimObj = prev[field];
                if (dimObj) return {...prev, [field]: {...dimObj, width: params.width}};
                return {...prev, [field]: { width: params.width }};
            }
            return { [field]: { width: params.width } };
        });
        setHasChanges(true);
    }, []);

    const handleViewModelChange = useCallback((vModel: GridColumnVisibilityModel) => {
        setVisibilityModel(vModel);
        setHasChanges(true);
    }, []);

    const handleSortModelChange = useCallback((sModel: GridSortModel) => {
        setSortModel(sModel);
        setHasChanges(true);
    }, []);

    const initialState = useMemo<GridInitialStatePremium>(() => {
        if (fetchedInitialState && fetchedInitialState.columns) {
            if(fetchedInitialState.columns.columnVisibilityModel) return fetchedInitialState;
            return { columns: {...fetchedInitialState.columns, columnVisibilityModel: defaultViewModel}, sorting: { sortModel: sortModel }, pinnedColumns: defaultPinnedColumns };
        }
        return { columns: { columnVisibilityModel: defaultViewModel }, sorting: { sortModel: sortModel }, pinnedColumns: defaultPinnedColumns }
    }, [fetchedInitialState, defaultViewModel, sortModel, defaultPinnedColumns]);

    const renderedGrid = useMemo(() => {
        return (
            <>
                {columnsSwitch &&
                    <DataGridPremium
                        autoHeight={props.fromDialog ?? false}
                        {...props}
                        initialState={initialState}
                        onColumnOrderChange={handleColumnOrderChange}
                        onColumnWidthChange={handleColumnWidthChange}
                        onColumnVisibilityModelChange={ handleViewModelChange }
                        onSortModelChange={handleSortModelChange}
                        getRowClassName={ isStripedRows ? stripedTableRowClassHandler : undefined }
                        checkboxSelectionVisibleOnly

                        />
                }
                {!columnsSwitch &&
                    <DataGridPremium
                        autoHeight={props.fromDialog ?? false}
                        {...props}
                        initialState={initialState}
                        onColumnOrderChange={handleColumnOrderChange}
                        onColumnWidthChange={handleColumnWidthChange}
                        onColumnVisibilityModelChange={ handleViewModelChange }
                        onSortModelChange={handleSortModelChange}
                        getRowClassName={ isStripedRows ? stripedTableRowClassHandler : undefined }
                        checkboxSelectionVisibleOnly
                    />
                }
            </>
        );
    }, [columnsSwitch, handleColumnOrderChange, handleColumnWidthChange, handleSortModelChange, handleViewModelChange, initialState, isStripedRows, props, stripedTableRowClassHandler]);

    if (props.noContainer) {
        return renderedGrid;
    }
    
    return (
        <div style={{ display: 'flex', flex: '1', flexDirection: 'column', position: 'relative', overflow: 'hidden' }}>
            <div style={props?.fromDialog ? {top: 0, left: 0, height: '100%', width: '100%', overflow: 'auto' } : {position: 'absolute', top: 0, left: 0, height: '100%', width: '100%', overflow: 'auto' }}>
                {renderedGrid}
            </div>
        </div>
    );
}