import React, { useState } from "react";
import { useNavigate } from "react-router";
import Button from "@mui/material/Button";

import { Authenticate } from "services/AuthService";
import { GetMyPermissions, GetMyCompanyPermissions, GetMySettings } from "services/UsersService";
import { ErrorHandler } from '../../common/models/GenericTypes';
import { useSearchParams } from "react-router-dom";


declare global {
    interface Window {
        pendo: any;
    }
}

interface Props {
    setShowLoading: React.Dispatch<React.SetStateAction<boolean>>,
    handleError: ErrorHandler,
    refreshCurrentUserHandler?: () => void
}

const LoginForm = ({ setShowLoading, handleError, refreshCurrentUserHandler }: Props) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [searchParams] = useSearchParams();
    const returnUrl = searchParams.get('return-url');
    const navigate = useNavigate();
    const isSubmitDisabled = username === '' || password === '';

    const handleSubmit = async (e: React.SyntheticEvent) => {
        e.preventDefault();
        setShowLoading(true);

        const data = await Authenticate(username, password, handleError);

        if (data) {
            localStorage.clear();
            await GetMySettings();

            const userPermissions = await GetMyPermissions(handleError);
            const customerPermissions = await GetMyCompanyPermissions(handleError);
            if (userPermissions && customerPermissions) {
                localStorage.setItem('uPermissions', JSON.stringify(userPermissions));
                localStorage.setItem('cPermissions', JSON.stringify(customerPermissions));
                refreshCurrentUserHandler && refreshCurrentUserHandler();
                if(returnUrl && returnUrl?.indexOf('api/connect/')  >= 0){
                    window.location.replace(returnUrl);
                }else{
                    navigate(returnUrl ?? '/');
                }

            }
        } else {
            setShowLoading(false);
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <input
                type='email'
                placeholder="Email"
                className="login-input-txt"
                name="Username"
                onChange={({currentTarget}) => setUsername(currentTarget.value)}
                value={username}
            />
            <input
                type='password'
                placeholder="Password"
                className="login-input-txt"
                name="Password"
                autoComplete="password"
                onChange={({currentTarget}) => setPassword(currentTarget.value)}
                value={password}
            />
            <Button
                disabled={isSubmitDisabled}
                type="submit"
                variant="contained"
                color="primary"
                sx={{ textTransform: 'none', display: 'block', width: '100%', height: '42px' }}
            >
                Sign In
            </Button>
        </form>
    );
}

export default LoginForm;
