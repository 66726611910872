import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import React, { useCallback } from "react";

interface Props {
    open: boolean,
    closeHandler: () => void,
    sendViaSmsHandler: () => void,
    sendViaEmailHandler: () => void
}

export default function PlacementCandidateConfirmationDialog({ open, closeHandler, sendViaSmsHandler, sendViaEmailHandler }: Props) {
    const smsCallback = useCallback(() => {
        sendViaSmsHandler();
        closeHandler();
    }, [closeHandler, sendViaSmsHandler]);
    
    const emailCallback = useCallback(() => {
        sendViaEmailHandler();
        closeHandler();
    }, [closeHandler, sendViaEmailHandler]);
    
    return (
        <Dialog open={open}>
            <DialogTitle>Placement Confirmation</DialogTitle>
            <DialogContent>
                <DialogContentText>Send Placement Confirmation Message to Candidate?</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="error" onClick={ closeHandler }>No</Button>
                <Button variant="contained" color="success" onClick={ smsCallback } >Yes - Via SMS</Button>
                <Button variant="contained" color="success" onClick={ emailCallback } >Yes - Via Email</Button>
            </DialogActions>
        </Dialog>
    );
}