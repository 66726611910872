import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import RWTextFieldComponent from "components/RWTextFieldComponent";
import React, { useMemo } from "react";
import { IsValidNumericValue } from "util/RegExUtils";

interface Props {
    limit: number,
    rating: string,
    balance: number,
    showValidation: boolean,
    onNumberChangeHandler?: (e: React.ChangeEvent<HTMLInputElement>) => void,
    onStringChangeHandler?: (e: React.ChangeEvent<HTMLInputElement>) => void,
}

export default function EditableClientCreditElement({ limit, rating, balance, showValidation, onNumberChangeHandler = () => {}, onStringChangeHandler }: Props) {
    const isLimitError = useMemo(() => !IsValidNumericValue(limit.toString()), [limit]);
    const isBalanceError = useMemo(() => !IsValidNumericValue(balance.toString()), [balance]);

    return (
        <Box height="100%" display="flex" flexDirection="column">
            <Typography variant="h6" component="div" height="40px">Credit</Typography>
            <Box display="flex" height="40px" px="5px">
                <Typography width="30%" variant="body1" fontWeight={600} component="div" sx={{ userSelect: 'text' }}>Limit</Typography>
                <Box width="70%">
                    <RWTextFieldComponent variant="standard" value={limit.toString()} onChange={onNumberChangeHandler} name="creditLimit" isError={showValidation && isLimitError} />
                </Box>
            </Box>
            <Box display="flex" height="40px" px="5px">
                <Typography width="30%" variant="body1" fontWeight={600} component="div" sx={{ userSelect: 'text' }}>Rating</Typography>
                <Box width="70%">
                    <TextField fullWidth select variant="standard" value={rating} onChange={onStringChangeHandler} name="creditRating">
                        <MenuItem value="">None</MenuItem>
                        <MenuItem value="AAA">AAA</MenuItem>
                        <MenuItem value="AA">AA</MenuItem>
                        <MenuItem value="A">A</MenuItem>
                        <MenuItem value="BBB">BBB</MenuItem>
                        <MenuItem value="BB">BB</MenuItem>
                        <MenuItem value="B">B</MenuItem>
                        <MenuItem value="CCC">CCC</MenuItem>
                        <MenuItem value="CC">CC</MenuItem>
                        <MenuItem value="C">C</MenuItem>
                        <MenuItem value="R">R</MenuItem>
                        <MenuItem value="SD">SD</MenuItem>
                        <MenuItem value="D">D</MenuItem>
                    </TextField>
                </Box>
            </Box>
            <Box display="flex" height="40px" px="5px">
                <Typography width="30%" variant="body1" fontWeight={600} component="div" sx={{ userSelect: 'text' }}>Balance</Typography>
                <Box width="70%">
                    <RWTextFieldComponent variant="standard" value={balance.toString()} onChange={onNumberChangeHandler} name="creditBalance" isError={showValidation && isBalanceError} />
                </Box>
            </Box>
        </Box>
    );
}