import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import PageContentLayout from "layouts/PageContentLayout";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import moment from "moment";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import ConfirmationDialog from "components/Dialogs/Generic/ConfirmationDialog";
import { Classification, ClassificationCreate } from "common/models/JobPosting/Classifications";
import { GetAdvertsClassifications, UpdateAdvertsClassifications } from "services/ConfigurationService";
import useUnsavedChangesDialog from "hooks/UseUnsavedChangesDialog";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import RWTextFieldComponent from "components/RWTextFieldComponent";

interface Props {
    setSummaryBar?: (summaryBar: JSX.Element) => void
}

type ClassificationUI = Classification & { itemId: string, parentItemId: string };
type SubClassificationMap = Record<string, ClassificationUI[]>;

export default function AdvertsClassifications({ setSummaryBar }: Props) {
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [classifications, setClassifications] = useState<ClassificationUI[]>([]);
    const [subClassificationsMap, setSubClassificationsMap] = useState<SubClassificationMap>({});
    const [newClassificationName, setNewClassificationName] = useState('');
    const [newClassificationParent, setNewClassificationParent] = useState('');
    const [expandedModel, setExpandedModel] = useState<string[]>([]);
    const [showAddDialog, setShowAddDialog] = useState(false);
    const [deleteNodeId, setDeleteNodeId] = useState('');
    const [deleteParentNodeId, setDeleteParentNodeId] = useState('');
    const [deletedIds, setDeletedIds] = useState<number[]>([]);

    const getData = useCallback(async () => {
        setIsLoading(true);
        let parents: ClassificationUI[] = [];
        let childrenMap: SubClassificationMap = {};
        let expandedIds: string[] = [];
        const res = await GetAdvertsClassifications(setErrorMessage);
        if (res) {
            for (let i = 0; i < res.length; i++) {
                const row = res[i];
                if (row.parentID === 0) {
                    parents.push({ ...row, itemId: row.id.toString(), parentItemId: '' });
                    expandedIds.push(row.id.toString());
                }
                else {
                    const parentId = row.parentID.toString();
                    const m = childrenMap[parentId];
                    if (m) m.push({ ...row, itemId: row.id.toString(), parentItemId: parentId });
                    else childrenMap[parentId] = [{ ...row, itemId: row.id.toString(), parentItemId: parentId }];
                }
            }
            setExpandedModel(expandedIds);
            setClassifications(parents);
            setSubClassificationsMap(childrenMap);
        }
        setIsLoading(false);
    }, []);

    useEffect(() => {
        getData();
    }, [getData]);

    const hasChanges = useMemo(() => {
        if (deletedIds.length > 0) return true;
        const newClassification = classifications.find(c => c.id === 0);
        if (newClassification) return true;
        const parentIds = Object.keys(subClassificationsMap);
        for (let i = 0; i < parentIds.length; i++) {
            const parentId = parentIds[i];
            const vals = subClassificationsMap[parentId];
            if (vals && vals.length > 0) {
                const newSubClassification = vals.find(s => s.id === 0);
                if (newSubClassification) return true;
            }
        }
        return false;
    }, [classifications, deletedIds.length, subClassificationsMap]);

    const saveChangesCallback = useCallback(async () => {
        setIsLoading(true);
        let createData: ClassificationCreate[] = [];
        for (let i = 0; i < classifications.length; i++) {
            const classification = classifications[i];
            let isIncludeData = classification.id === 0;
            let data: ClassificationCreate = {
                id: classification.id,
                name: classification.name,
                newChildren: [],
            };
            const children = subClassificationsMap[classification.itemId];
            if (children && children.length > 0) {
                for (let i = 0; i < children.length; i++) {
                    const child = children[i];
                    if (child.id === 0) {
                        isIncludeData = true;
                        data.newChildren.push(child.name);
                    }
                }
            }

            if (isIncludeData) createData.push(data);
        }
        
        const res = await UpdateAdvertsClassifications({ create: createData, delete: deletedIds }, setErrorMessage);

        if (!res) {
            setIsLoading(false);
            return false;
        }
        setDeletedIds([]);
        setIsLoading(false);
        await getData();
        setSuccessMessage('Changes Saved');
        return true;
    }, [classifications, deletedIds, getData, subClassificationsMap]);

    useEffect(() => {
        const action = <Button variant="contained" color="primary" onClick={saveChangesCallback} disabled={!hasChanges}>Save</Button>;
        const SummaryBar = (
            <TitleAndActionSummaryBar
                title="Configuration > Adverts > Classifications"
                browserTabTitle="Adverts > Configuration"
                action={action}
            />
        );

        setSummaryBar && setSummaryBar(SummaryBar);
    }, [hasChanges, saveChangesCallback, setSummaryBar]);

    const addClassificationHandler = useCallback((name: string, parentNodeId: string) => {
        if (parentNodeId === '') {
            const trimmed = name.trim();
            const existing = classifications.find(c => c.name === trimmed);
            if (existing) {
                setErrorMessage('This Classification already exists');
                return;
            }
            const nodeId = moment().format('YYYYMMDDHHmmssSS');
            setClassifications(prev => {
                let tmp = [...prev];
                tmp.push({ id: 0, isDeleted: false, name: trimmed, parentID: 0, itemId: nodeId, parentItemId: '' });
                tmp.sort((a,b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0));
                return tmp;
            });
        }
        else {
            const trimmed = name.trim();
            const children = subClassificationsMap[parentNodeId];
            if (children && children.length > 0) {
                const existing = children.find(c => c.name === trimmed);
                if (existing) {
                    setErrorMessage('This Sub Classification already exists');
                    return;
                }
            }
            const nodeId = moment().format('YYYYMMDDHHmmssSS');
            setSubClassificationsMap(prev => {
                let tmp = {...prev};
                const obj = tmp[parentNodeId];
                if (obj) {
                    let tmp2 = [...obj];
                    tmp2.push({ id: 0, isDeleted: false, name: trimmed, parentID: 0, itemId: nodeId, parentItemId: parentNodeId });
                    tmp2.sort((a,b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0));
                    tmp[parentNodeId] = tmp2;
                }
                else {
                    tmp[parentNodeId] = [{ id: 0, isDeleted: false, name: trimmed, parentID: 0, itemId: nodeId, parentItemId: parentNodeId }];
                }
                return tmp;
            });
        }
        setShowAddDialog(false);
    }, [classifications, subClassificationsMap]);

    const removeClassificationHandler = useCallback((nodeId: string, parentNodeId: string) => {
        let deletedId = 0;
        if (parentNodeId === '') {
            setClassifications(prev => {
                let tmp = [...prev];
                let index = -1;
                for (let i = 0; i < tmp.length; i++) {
                    const element = tmp[i];
                    if (element.itemId === nodeId) {
                        deletedId = element.id;
                        index = i;
                        break;
                    }
                }
                if (index !== -1) tmp.splice(index, 1);
                return tmp;
            });
        }
        else {
            setSubClassificationsMap(prev => {
                let tmp = {...prev};
                let tmp2 = [...tmp[parentNodeId]];
                if (tmp2 && tmp2.length > 0) {
                    let index = -1;
                    for (let i = 0; i < tmp2.length; i++) {
                        const element = tmp2[i];
                        if (element.itemId === nodeId) {
                            deletedId = element.id;
                            index = i;
                            break;
                        }
                    }
                    if (index !== -1) {
                        tmp2.splice(index, 1);
                        tmp[parentNodeId] = tmp2;
                    }
                }
                return tmp;
            });
        }
        if (deletedId) setDeletedIds(prev => ([...prev, deletedId]));
    }, []);

    const handleRemoveClick = useCallback((nodeId: string, parentNodeId: string) => {
        setDeleteNodeId(nodeId);
        setDeleteParentNodeId(parentNodeId);
    }, []);

    const handleCancelRemoveClick = useCallback(() => {
        setDeleteNodeId('');
        setDeleteParentNodeId('');
    }, []);

    const handleContinueRemoveClick = useCallback(() => {
        removeClassificationHandler(deleteNodeId, deleteParentNodeId);
        setDeleteNodeId('');
        setDeleteParentNodeId('');
    }, [deleteNodeId, deleteParentNodeId, removeClassificationHandler]);

    const treeItemLabelRenderer = useCallback((nodeId: string, label: string, parentId: string, isLeaf: boolean) => {
        return (
            <Box display="flex" alignItems="center" height={isLeaf ? undefined : '34px'}>
                <Typography flexGrow={1}>
                    {label}
                </Typography>
                { isLeaf && <IconButton size="small" onClick={ () => handleRemoveClick(nodeId, parentId) }><DeleteIcon /></IconButton> }
            </Box>
        );
    }, [handleRemoveClick]);

    const renderTreeItems = useCallback(() => {
        return classifications.map(c => {
            const children = subClassificationsMap[c.itemId];
            if (children && children.length > 0) {
                return (
                    <TreeItem key={c.itemId} itemId={c.itemId} label={treeItemLabelRenderer(c.itemId, c.name, c.parentItemId, false)} >
                        {children.map(s => <TreeItem key={s.itemId} itemId={s.itemId} label={treeItemLabelRenderer(s.itemId, s.name, s.parentItemId, true)} />)}
                    </TreeItem>
                );
            }

            return <TreeItem key={c.itemId} itemId={c.itemId} label={treeItemLabelRenderer(c.itemId, c.name, c.parentItemId, true)}  />;
        });
    }, [classifications, subClassificationsMap, treeItemLabelRenderer]);

    const { unsavedChangesDialog } = useUnsavedChangesDialog(hasChanges, saveChangesCallback);

    useEffect(() => {
        if (!showAddDialog) {
            setNewClassificationParent('');
            setNewClassificationName('');
        }
    }, [showAddDialog]);

    return (
        <>
            {unsavedChangesDialog}
            <Snackbar open={successMessage !== ''} autoHideDuration={3000} onClose={() => setSuccessMessage('')}>
                <Alert onClose={() => setSuccessMessage('')}>{ successMessage }</Alert>
            </Snackbar>
            <Snackbar open={Boolean(errorMessage)} autoHideDuration={3000} onClose={() => setErrorMessage('')}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{errorMessage}</Alert>
            </Snackbar>
            <ConfirmationDialog
                open={Boolean(deleteNodeId) || Boolean(deleteParentNodeId)}
                message="Are you sure you want to delete this classification?"
                onClose={handleCancelRemoveClick}
                onContinue={handleContinueRemoveClick}
                title="Confirm Action"
            />
            <Dialog open={showAddDialog} fullWidth>
                <DialogTitle>Add Classification</DialogTitle>
                <DialogContent>
                    <Stack spacing={2} mt={2}>
                        <TextField select label="Parent" value={newClassificationParent} onChange={({target}) => setNewClassificationParent(target.value)}>
                            <MenuItem value="">None</MenuItem>
                            {classifications.map(c => <MenuItem key={c.itemId} value={c.itemId}>{c.name}</MenuItem>)}
                        </TextField>
                        <RWTextFieldComponent label="Classification Name" value={newClassificationName} onChange={(e) => setNewClassificationName(e.target.value)} />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="error" onClick={ () => setShowAddDialog(false) } >Cancel</Button>
                    <Button variant="contained" color="success" onClick={ () => addClassificationHandler(newClassificationName, newClassificationParent) } disabled={!Boolean(newClassificationName)} >Add</Button>
                </DialogActions>
            </Dialog>
            <PageContentLayout title="Classifications" contentAction={<Button variant="contained" color="success" onClick={ () => setShowAddDialog(true) } >Add Classification</Button>} showLoading={isLoading}>
                <SimpleTreeView
                    expandedItems={expandedModel}
                    onExpandedItemsChange={(e, ids) => setExpandedModel(ids)}
                >
                    {renderTreeItems()}
                </SimpleTreeView>
            </PageContentLayout>
        </>
    );
}