import Box from "@mui/material/Box";
import React, { useMemo } from "react";
import JobBoardViewField from "./JobBoardViewField";

interface Props {
    jobBoardId: number,
    advertId: number,
    attributes?: Record<string, string>,
    advertRecordAttributes?: Record<string, string>,
}

const industryMap: Record<string, string> = {
    "1": "Accounting / Financial / Insurance",
    "26": "Administration",
    "2": "Agriculture",
    "3": "Arts / Graphic Design",
    "4": "Automotive / Aerospace",
    "5": "Catering",
    "6": "Charity",
    "27": "Construction",
    "8": "Consulting",
    "9": "Customer Services",
    "10": "Distribution",
    "11": "Education",
    "12": "Electronics",
    "7": "Engineering",
    "30": "Hospitality / Hotel",
    "13": "IT",
    "14": "Legal",
    "15": "Leisure / Tourism",
    "16": "Management",
    "17": "Manufacturing / Surveying",
    "29": "Marketing",
    "19": "Media",
    "20": "Medical / Pharmaceutical / Scientific",
    "21": "Military / Emergency / Government",
    "22": "Other",
    "23": "Personnel / Recruitment",
    "24": "Property Services",
    "31": "Public Sector",
    "28": "Retail / Purchasing",
    "18": "Sales",
    "32": "Social Care",
    "25": "Telecoms",
};

const regionMap: Record<string, string> = {
    "0": "Standard job posting (not regional)",
    "77": "East Midlands",
    "73": "East of England",
    "127": "London",
    "90": "North East",
    "95": "North West",
    "100": "Northern Ireland",
    "107": "Scotland",
    "117": "South East",
    "132": "South West",
    "140": "Wales",
    "149": "West Midlands",
    "91": "Yorkshire and the Humber",
    "411": "UK (Nationwide)",
    "68": "Non UK",
};

const remoteMap: Record<string, string> = {
    "0": "No",
    "1": "Yes",
};

const jobTypeMap: Record<string, string> = {
    "apprenticeship": "Apprenticeship",
    "contract": "Contract",
    "part_time": "Part Time",
    "permanent": "Permanent",
    "temporary": "Temporary",
};

export default function CVLibraryView({ jobBoardId, advertId, attributes, advertRecordAttributes }: Props) {
    const atts = useMemo<Record<string, string>>(() => {
        if (attributes) return attributes;
        return {};
    }, [attributes]);

    const recordAtts = useMemo<Record<string, string>>(() => {
        if (advertRecordAttributes) return advertRecordAttributes;
        return {};
    }, [advertRecordAttributes]);

    const industry = useMemo(() => atts.Industry ?? '0', [atts.Industry]);
    const region = useMemo(() => atts.Region ?? '0', [atts.Region]);
    const location = useMemo(() => atts.Location ?? '', [atts.Location]);
    const remote = useMemo(() => atts.Remote ?? '0', [atts.Remote]);
    const jobType = useMemo(() => atts.JobType ?? '0', [atts.JobType]);

    const salaryType = useMemo(() => recordAtts.salaryType ?? '', [recordAtts.salaryType]);
    const salaryFrom = useMemo(() => recordAtts.salaryFrom ?? '', [recordAtts.salaryFrom]);
    const salaryTo = useMemo(() => recordAtts.salaryTo ?? '', [recordAtts.salaryTo]);

    return (
        <Box display="flex" flexDirection="column" gap={2}>
            <Box display="flex" gap={2} pt={2}>
                <Box display="flex" flexDirection="column" gap={2} width="50%">
                    <JobBoardViewField label="Industry" value={industryMap[industry] ?? ''} />
                    <JobBoardViewField label="Region" value={regionMap[region] ?? ''} />
                    <JobBoardViewField label="Location" value={location} />
                    <JobBoardViewField label="Remote" value={remoteMap[remote] ?? ''} />
                </Box>
                <Box display="flex" flexDirection="column" gap={2} width="50%">
                    <JobBoardViewField label="Job Type" value={jobTypeMap[jobType] ?? ''} />
                    <JobBoardViewField label="Salary Type" value={salaryType} />
                    <Box display="flex" gap={2}>
                        <JobBoardViewField label="Salary From" sx={{ flex: '1 1 0' }} value={salaryFrom} />
                        <JobBoardViewField label="Salary To" sx={{ flex: '1 1 0' }} value={salaryTo} />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}