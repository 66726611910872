import React, { useEffect, useMemo, useState } from "react";
import { ComplianceItem } from "common/models/Configuration/Compliance";
import { GetComplianceItems } from "services/ComplianceService";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { GetComplianceItemScopeName, GetComplianceItemTypeName } from "util/Compliance";

interface Props {
    itemId: number | null,
    onSelect: (item: ComplianceItem | null) => void,
    variant?: "standard" | "filled" | "outlined" | undefined,
    ignoreIds?: number[],
    label?: string,
    disableClearable?: boolean
}

export default function ComplianceItemPicker({ itemId, onSelect, variant, ignoreIds, label, disableClearable }: Props) {
    const [options, setOptions] = useState<ComplianceItem[]>([]);

    useEffect(() => {
        const getData = async () => {
            const res = await GetComplianceItems();
            if (res) setOptions(res);
        };
        getData();
    }, []);

    const filteredOptions = useMemo(() => {
        if (ignoreIds && ignoreIds.length > 0 && options.length > 0){
            return options.filter(v => !ignoreIds.includes(v.id));
        }
        else return options;
    }, [options, ignoreIds]);

    return (
        <Autocomplete
            size="small"
            // options={options}
            options={filteredOptions}
            getOptionLabel={ o => o.name }
            isOptionEqualToValue={ (o,v) => o.id === v.id }
            onChange={ (e,v) => onSelect(v) }
            renderInput={ params => <TextField {...params} variant={variant} label={label} inputProps={{ ...params.inputProps, "data-lpignore": "true" }} /> }
            renderOption={(props, option) => (
                <ListItem {...props}>
                    <ListItemText
                        primary={option.name}
                        secondary={`${option.id} - ${GetComplianceItemScopeName(option.scopeID)} - ${GetComplianceItemTypeName(option.typeID)}`}
                    />
                </ListItem>
            )}
        />
    );
}