import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import SettingsIcon from '@mui/icons-material/Settings';
import Paper from "@mui/material/Paper";
import React, { useCallback, useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import RWTextFieldComponent from "components/RWTextFieldComponent";

interface Props {
    id: string,
    title: string,
    url: string,
    showConfigButton?: boolean,
    saveConfigHandler?: (id: string, newTitle: string, newUrl: string) => void
}

export default function LinkButtonComponent({ id, title: titleProp, url: urlProp, showConfigButton = false, saveConfigHandler }: Props) {
    const [title, setTitle] = useState('');
    const [url, setUrl] = useState('');
    const [showConfigDialog, setShowConfigDialog] = useState(false);

    const onSaveConfig = useCallback(() => {
        saveConfigHandler && saveConfigHandler(id, title, url);
        setShowConfigDialog(false);
    }, [id, saveConfigHandler, title, url]);

    useEffect(() => {
        if (showConfigDialog) {
            setTitle(titleProp);
            setUrl(urlProp);
        }
    }, [showConfigDialog, titleProp, urlProp]);

    return (
        <>
            <Dialog open={showConfigDialog} fullWidth>
                <DialogTitle>Configure Element</DialogTitle>
                <DialogContent>
                    <Stack spacing={2} mt="10px">

                        <RWTextFieldComponent label="Title" value={title} onChange={(e) => setTitle(e.target.value)} />
                        <RWTextFieldComponent label="URL" value={url} onChange={(e) => setUrl(e.target.value)} />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="error" onClick={() => setShowConfigDialog(false)}>Cancel</Button>
                    <Button variant="contained" color="success" onClick={onSaveConfig} disabled={!Boolean(title) || !Boolean(url)}>Save</Button>
                </DialogActions>
            </Dialog>
            {showConfigButton &&
                <IconButton
                    sx={{ position: 'absolute', right: 30, zIndex: t => t.zIndex.appBar - 5, color: t => t.palette.text.disabled }}
                    onClick={() => setShowConfigDialog(true)}
                >
                    <SettingsIcon />
                </IconButton>
            }
            <Paper sx={{ display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'center' }} >
                <Box>
                    <a href={urlProp} target="_blank" style={{ color: 'inherit', textDecoration: 'none' }} rel="noopener noreferrer">
                        <Button variant="contained">{titleProp}</Button>
                    </a>
                </Box>
            </Paper>
        </>
    );
}