import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Skeleton from "@mui/material/Skeleton";
import CloseIcon from '@mui/icons-material/Close';
import ControlCameraIcon from '@mui/icons-material/ControlCamera';
import moment from "moment";
import React, { useEffect, useState } from "react";
import Draggable from "react-draggable";
import { Activity } from "common/models/Activities";
import { PreviewProps } from "common/models/Previews/Previews";
import { GetActivityById } from "services/ActivitiesService";
import { PREVIEW_HEIGHT, PREVIEW_TABLE_CONTENT, PREVIEW_TABLE_LABEL, PREVIEW_TABLE_STYLE, PREVIEW_TITLE_STYLE, PREVIEW_WIDTH } from "util/Definitions/Constants/Previews";

const skeletonText = <Skeleton variant="text" height="40px" sx={{ minWidth: '300px' }} />;

const formatDate = (date: string | null) => {
    if (date) return moment(date).format('DD MMM YYYY h:mm A');
    return '';
}

export default function ActivityPreviewComponent({ recordId, isSourceHovered, hideCallback, hideDelayMs = 1250 }: PreviewProps) {
    const [shouldClose, setShouldClose] = useState(false);
    const [isFetchingData, setIsFetchingData] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [activity, setActivity] = useState<Activity>();

    useEffect(() => {
        const getData = async () => {
            setIsFetchingData(true);
            const res = await GetActivityById(recordId);
            if (res) setActivity(res);
            setIsFetchingData(false);
        };
        getData();
    }, [recordId]);

    useEffect(() => {
        if (shouldClose) {
            const hidePreviewTimeout = setTimeout(() => {
                hideCallback();
            }, hideDelayMs);
            return () => clearTimeout(hidePreviewTimeout);
        }
    }, [shouldClose, hideDelayMs, hideCallback]);
    
    useEffect(() => {
        if (isSourceHovered || isHovered) setShouldClose(false);
        else setShouldClose(true);
    }, [isSourceHovered, isHovered]);

    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
            defaultPosition={{ x: (window.innerWidth / 2) - (PREVIEW_WIDTH / 2), y: (window.innerHeight / 2) - (PREVIEW_HEIGHT / 2) }}
        >
            <Dialog
                open={true}
                maxWidth={false}
                PaperProps={{
                    onMouseEnter: () => setIsHovered(true),
                    onMouseLeave: () => setIsHovered(false),
                    sx: { height: `${PREVIEW_HEIGHT}px`, width: `${PREVIEW_WIDTH}px` }
                }}
                hideBackdrop
                sx={{ bottom: 'unset', right: 'unset', left: 'unset' }}
            >
                <DialogTitle component="div" display="flex" sx={ PREVIEW_TITLE_STYLE }>
                    <div style={{ alignSelf: 'center' }}>
                        { isFetchingData || !activity ? skeletonText : activity.type }
                    </div>
                    <div style={{ marginLeft: 'auto' }}>
                        <IconButton id="draggable-dialog-title" sx={{ cursor: 'move', color: t => t.palette.primary.contrastText }}><ControlCameraIcon /></IconButton>
                        <IconButton onClick={ () => hideCallback() } sx={{ color: t => t.palette.primary.contrastText }}><CloseIcon /></IconButton>
                    </div>
                </DialogTitle>
                <DialogContent sx={{ display: 'flex' }}>
                    <div style={{ paddingTop: '10px', display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <table style={ PREVIEW_TABLE_STYLE }>
                                    <tbody>
                                        <tr>
                                            <td width="30%" style={ PREVIEW_TABLE_LABEL }>ID</td>
                                            <td width="70%" style={ PREVIEW_TABLE_CONTENT }>{isFetchingData || ! activity ? skeletonText : activity.id}</td>
                                        </tr>
                                        <tr>
                                            <td width="30%" style={ PREVIEW_TABLE_LABEL }>Assigned To</td>
                                            <td width="70%" style={ PREVIEW_TABLE_CONTENT }>{isFetchingData || ! activity ? skeletonText : activity.assignedToName}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Grid>
                            <Grid item xs={6}>
                                <table style={ PREVIEW_TABLE_STYLE }>
                                    <tbody>
                                        <tr>
                                            <td width="30%" style={ PREVIEW_TABLE_LABEL }>Date</td>
                                            <td width="70%" style={ PREVIEW_TABLE_CONTENT }>{isFetchingData || ! activity ? skeletonText : formatDate(activity.activityDate)}</td>
                                        </tr>
                                        <tr>
                                            <td width="30%" style={ PREVIEW_TABLE_LABEL }>Status</td>
                                            <td width="70%" style={ PREVIEW_TABLE_CONTENT }>{isFetchingData || ! activity ? skeletonText : activity.statusName}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Grid>
                        </Grid>
                        <div style={{ paddingTop: '5px' }}>
                            <Divider>Notes</Divider>
                        </div>
                        {isFetchingData || !activity
                            ? <Skeleton variant="rectangular" height="100%" />
                            : <div style={{ flexGrow: 1, overflow: 'auto' }} dangerouslySetInnerHTML={{ __html: activity.notes }} />
                        }
                        <div style={{ paddingBottom: '20px' }}>
                            <Divider />
                        </div>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <table style={ PREVIEW_TABLE_STYLE }>
                                    <tbody>
                                        <tr>
                                            <td width="30%" style={ PREVIEW_TABLE_LABEL }>Created Date</td>
                                            <td width="70%" style={ PREVIEW_TABLE_CONTENT }>{isFetchingData || ! activity ? skeletonText : formatDate(activity.dateCreated)}</td>
                                        </tr>
                                        <tr>
                                            <td width="30%" style={ PREVIEW_TABLE_LABEL }>Created By</td>
                                            <td width="70%" style={ PREVIEW_TABLE_CONTENT }>{isFetchingData || ! activity ? skeletonText : activity.createdByName}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Grid>
                            <Grid item xs={6}>
                                <table style={ PREVIEW_TABLE_STYLE }>
                                    <tbody>
                                        <tr>
                                            <td width="30%" style={ PREVIEW_TABLE_LABEL }>Updated Date</td>
                                            <td width="70%" style={ PREVIEW_TABLE_CONTENT }>{isFetchingData || ! activity ? skeletonText : formatDate(activity.lastUpdatedDate)}</td>
                                        </tr>
                                        <tr>
                                            <td width="30%" style={ PREVIEW_TABLE_LABEL }>Updated By</td>
                                            <td width="70%" style={ PREVIEW_TABLE_CONTENT }>{isFetchingData || ! activity ? skeletonText : activity.updatedByName}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Grid>
                        </Grid>
                    </div>
                </DialogContent>
            </Dialog>
        </Draggable>
    );
}