import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Alert from "components/Alert";
import Snackbar from "@mui/material/Snackbar";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Permission } from "common/models/Permissions";
import AdvertsGridComponent from "components/Grids/AdvertsGrid";
import ClientsGridComponent from "components/Grids/ClientsGrid";
import ContactsGridComponent from "components/Grids/ContactsGrid";
import CandidatesGridComponent from "components/Grids/CandidatesGrid";
import JobsGridComponent from "components/Grids/JobsGrid";
import OpportunitiesGridComponent from "components/Grids/OpportunitiesGrid";
import PlacementsGridComponent from "components/Grids/PlacementsGrid";
import SitesGridComponent from "components/Grids/SitesGrid";
import OnlyTitleSummaryBar from "components/SummaryBars/OnlyTitleSummaryBar";
import PageContentLayout from "layouts/PageContentLayout";
import PageLayout from "layouts/PageLayout";
import { companyHasSinglePermission, userHasSinglePermission } from "util/PermissionsUtils";
import Box from "@mui/material/Box";

export default function GlobalLookup(props: any) {
    const [isClientsLoading, setIsClientsLoading] = useState(false);
    const [isSitesLoading, setIsSitesLoading] = useState(false);
    const [isContactsLoading, setIsContactsLoading] = useState(false);
    const [isOpportunitiesLoading, setIsOpportunitiesLoading] = useState(false);
    const [isJobsLoading, setIsJobsLoading] = useState(false);
    const [isAdvertsLoading, setIsAdvertsLoading] = useState(false);
    const [isCandidatesLoading, setIsCandidatesLoading] = useState(false);
    const [isPlacementsLoading, setIsPlacementsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const [clientsCount, setClientsCount] = useState(0);
    const [sitesCount, setSitesCount] = useState(0);
    const [contactsCount, setContactsCount] = useState(0);
    const [opportunitiesCount, setOpportunitiesCount] = useState(0);
    const [jobsCount, setJobsCount] = useState(0);
    const [advertsCount, setAdvertsCount] = useState(0);
    const [candidatesCount, setCandidatesCount] = useState(0);
    const [placementsCount, setPlacementsCount] = useState(0);

    const isLoading = useMemo(() => (
        isClientsLoading || isSitesLoading || isContactsLoading || isOpportunitiesLoading || isJobsLoading || isAdvertsLoading || isCandidatesLoading || isPlacementsLoading
    ), [isClientsLoading, isSitesLoading, isContactsLoading, isOpportunitiesLoading, isJobsLoading, isAdvertsLoading, isCandidatesLoading, isPlacementsLoading]);

    const [activeTab, setActiveTab] = useState('Clients');
    const [searchParams] = useSearchParams();
    const query = useMemo(() => {
        const q = searchParams.get('q');
        if (q) return q;
        return '';
    }, [searchParams]);
    const SummaryBar = <OnlyTitleSummaryBar title={`Lookup: ${query}`} />;

    const isClientsEnabled = useMemo(() => companyHasSinglePermission(Permission.Clients) && userHasSinglePermission(Permission.Clients), []);
    const isContactsEnabled = useMemo(() => companyHasSinglePermission(Permission.Contacts) && userHasSinglePermission(Permission.Contacts), []);
    const isOpportunitiesEnabled = useMemo(() => companyHasSinglePermission(Permission.Opportunities) && userHasSinglePermission(Permission.Opportunities), []);
    const isJobsEnabled = useMemo(() => companyHasSinglePermission(Permission.Jobs) && userHasSinglePermission(Permission.Jobs), []);
    const isAdvertsEnabled = useMemo(() => companyHasSinglePermission(Permission.Adverts) && userHasSinglePermission(Permission.Adverts), []);
    const isCandidatesEnabled = useMemo(() => companyHasSinglePermission(Permission.Candidates) && userHasSinglePermission(Permission.Candidates), []);
    const isPlacementsEnabled = useMemo(() => companyHasSinglePermission(Permission.Placements) && userHasSinglePermission(Permission.Placements), []);

    useEffect(() => {
        if (!isClientsEnabled) {
            if (isContactsEnabled) setActiveTab('Contacts');
            else if (isOpportunitiesEnabled) setActiveTab('Opportunities');
            else if (isJobsEnabled) setActiveTab('Jobs');
            else if (isAdvertsEnabled) setActiveTab('Adverts');
            else if (isCandidatesEnabled) setActiveTab('Candidates');
            else if (isPlacementsEnabled) setActiveTab('Placements');
        }
    }, [isClientsEnabled, isContactsEnabled, isOpportunitiesEnabled, isJobsEnabled, isAdvertsEnabled, isCandidatesEnabled, isPlacementsEnabled]);

    const handleTabChange = useCallback((e: React.SyntheticEvent, newValue: string) => {
        setActiveTab(newValue);
    }, []);

    return (
        <>
            <Snackbar open={successMessage !== ''} autoHideDuration={3000} onClose={() => setSuccessMessage('')}>
                <Alert onClose={() => setSuccessMessage('')}>{successMessage}</Alert>
            </Snackbar>
            <Snackbar open={errorMessage !== ''} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <PageLayout SummaryBar={SummaryBar} paddingTop={0}>
                <Tabs value={activeTab} onChange={handleTabChange}>
                    {isClientsEnabled && <Tab value="Clients" label={`Clients (${clientsCount})`} />}
                    {isClientsEnabled && <Tab value="Sites" label={`Sites (${sitesCount})`} />}
                    {isContactsEnabled && <Tab value="Contacts" label={`Contacts (${contactsCount})`} />}
                    {isOpportunitiesEnabled && <Tab value="Opportunities" label={`Opportunities (${opportunitiesCount})`} />}
                    {isJobsEnabled && <Tab value="Jobs" label={`Jobs (${jobsCount})`} />}
                    {isAdvertsEnabled && <Tab value="Adverts" label={`Adverts (${advertsCount})`} />}
                    {isCandidatesEnabled && <Tab value="Candidates" label={`Candidates (${candidatesCount})`} />}
                    {isPlacementsEnabled && <Tab value="Placements" label={`Placements (${placementsCount})`} />}
                </Tabs>
                <PageContentLayout showLoading={isLoading}>
                    {isClientsEnabled &&
                        <Box display={activeTab === 'Clients' ? 'flex' : 'none'} flexDirection='column' flexGrow={1}>
                            <ClientsGridComponent
                                gridName="global-lookup/clients"
                                source="lookup"
                                lookupTerm={query}
                                loadingHandler={setIsClientsLoading}
                                setRowCountHandler={setClientsCount}
                                errorHandler={setErrorMessage}
                                successHandler={setSuccessMessage}
                            />
                        </Box>
                    }
                    {isClientsEnabled &&
                        <Box display={activeTab === 'Sites' ? 'flex' : 'none'} flexDirection='column' flexGrow={1}>
                            <SitesGridComponent
                                gridName="global-lookup/sites"
                                sourceId={0}
                                source="lookup"
                                lookupTerm={query}
                                loadingHandler={setIsSitesLoading}
                                setRowCountHandler={setSitesCount}
                                errorHandler={setErrorMessage}
                                successHandler={setSuccessMessage}
                            />
                        </Box>
                    }
                    {isContactsEnabled &&
                        <Box display={activeTab === 'Contacts' ? 'flex' : 'none'} flexDirection='column' flexGrow={1}>
                            <ContactsGridComponent
                                gridName="global-lookup/contacts"
                                source="lookup"
                                lookupTerm={query}
                                loadingHandler={setIsContactsLoading}
                                setRowCountHandler={setContactsCount}
                                errorHandler={setErrorMessage}
                            />
                        </Box>
                    }
                    {isOpportunitiesEnabled &&
                        <Box display={activeTab === 'Opportunities' ? 'flex' : 'none'} flexDirection='column' flexGrow={1}>
                            <OpportunitiesGridComponent
                                gridName="global-lookup/opportunities"
                                source="lookup"
                                lookupTerm={query}
                                loadingHandler={setIsOpportunitiesLoading}
                                setRowCountHandler={setOpportunitiesCount}
                                errorHandler={setErrorMessage}
                                successHandler={setSuccessMessage}
                            />
                        </Box>
                    }
                    {isJobsEnabled &&
                        <Box display={activeTab === 'Jobs' ? 'flex' : 'none'} flexDirection='column' flexGrow={1}>
                            <JobsGridComponent
                                gridName="global-lookup/jobs"
                                jobState={0}
                                source="lookup"
                                lookupTerm={query}
                                loadingHandler={setIsJobsLoading}
                                setRowCountHandler={setJobsCount}
                                errorHandler={setErrorMessage}
                            />
                        </Box>
                    }
                    {isCandidatesEnabled &&
                        <Box display={activeTab === 'Candidates' ? 'flex' : 'none'} flexDirection='column' flexGrow={1}>
                            <CandidatesGridComponent
                                gridName="global-lookup/candidates"
                                source="lookup"
                                lookupTerm={query}
                                loadingHandler={setIsCandidatesLoading}
                                setRowCountHandler={setCandidatesCount}
                                errorHandler={setErrorMessage}
                                successHandler={setSuccessMessage}
                            />
                        </Box>
                    }
                    {isAdvertsEnabled &&
                        <Box display={activeTab === 'Adverts' ? 'flex' : 'none'} flexDirection='column' flexGrow={1}>
                            <AdvertsGridComponent
                                gridName="global-lookup/adverts"
                                source="lookup"
                                lookupTerm={query}
                                loadingHandler={setIsAdvertsLoading}
                                setRowCountHandler={setAdvertsCount}
                                errorHandler={setErrorMessage}
                                successHandler={setSuccessMessage}
                            />
                        </Box>
                    }
                    {isPlacementsEnabled &&
                        <Box display={activeTab === 'Placements' ? 'flex' : 'none'} flexDirection='column' flexGrow={1}>
                            <PlacementsGridComponent
                                gridName="global-lookup/placements"
                                source="lookup"
                                lookupTerm={query}
                                loadingHandler={setIsPlacementsLoading}
                                setRowCountHandler={setPlacementsCount}
                                errorHandler={setErrorMessage}
                                successHandler={setSuccessMessage}
                            />
                        </Box>
                    }
                </PageContentLayout>
            </PageLayout>
        </>
    );
}