import React, { useState } from "react";
import AdvertsGridComponent from "components/Grids/AdvertsGrid";
import OnlyTitleSummaryBar from "components/SummaryBars/OnlyTitleSummaryBar";
import PageContentLayout from "layouts/PageContentLayout";
import PageLayout from "layouts/PageLayout";
import Snackbar from "@mui/material/Snackbar";
import Alert from "components/Alert";

const summaryBar = <OnlyTitleSummaryBar title="Adverts > My Adverts" browserTabTitle="My Adverts > Adverts" />;

export default function MyAdvertsPage() {
    const [isLoading, setIsLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    return (
        <>
            <PageLayout SummaryBar={summaryBar}>
                <Snackbar open={successMessage !== ''} autoHideDuration={3000} onClose={() => setSuccessMessage('')}>
                    <Alert onClose={() => setSuccessMessage('')}>{successMessage}</Alert>
                </Snackbar>
                <Snackbar open={errorMessage !== ''} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                    <Alert severity="error" onClose={() => setErrorMessage('')}>{errorMessage}</Alert>
                </Snackbar>
                <PageContentLayout showLoading={isLoading}>
                    <AdvertsGridComponent
                        loadingHandler={setIsLoading}
                        errorHandler={setErrorMessage}
                        successHandler={setSuccessMessage}
                        gridName="my-adverts"
                        source='my-adverts'
                    />
                </PageContentLayout>
            </PageLayout>
        </>
    );
}