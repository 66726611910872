import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { useTheme } from "@mui/material/styles";
import { PanelModel } from "@syncfusion/ej2-layouts/src/dashboard-layout/dashboard-layout-model";
import { DashboardLayoutComponent } from "@syncfusion/ej2-react-layouts/src/dashboard-layout/dashboardlayout.component";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { CustomField } from "common/models/Configuration/CustomFields";
import { SiteRecordDashboardElementDefinition, SiteRecordDashboardElementType } from "common/models/Dashboard/EditLayout";
import { PreviewEntityType } from "common/models/Previews/Previews";
import { CustomFieldSettingsMap, CustomFieldType } from "common/models/ScreenLayouts/CustomFields";
import { Site } from "common/models/Site";
import { GetCustomerSettingBySettingName } from "services/ConfigurationService";
import { GetCustomFieldsByEntity_OnlyActive } from "services/CustomFieldsService";
import { UdfSiteFieldMapObj } from "util/Definitions/ScreenLayouts/CustomFields";
import { DefaultSiteRecordDashboardElements, GetPanelDefinitionsFromPanelModels, SiteScreenLayoutSettings } from "util/Definitions/ScreenLayouts/Site";
import PanelWrapper from "components/Dashboards/PanelWrapper";
import PreviewLoaderComponent from "components/Previews/PreviewLoader";
import MultiLineTextElement from "../MultiLineTextElement";
import SingleFieldElement from "../SingleFieldElement";

interface Props {
    site: Site | null,
    loadingHandler?: (isloading: boolean) => void,
    errorHandler?: (message: string) => void,
}

const { unitWidth, unitHeight, gapX, gapY, columns, mediaQueryMaxWidth } = SiteScreenLayoutSettings;
const cellSpacing = [gapX, gapY];

export default function ViewRecordScreenLayout({ site, loadingHandler, errorHandler }: Props) {
    const [screenResizedControl, setScreenResizedControl] = useState(false);
    const [fetchingSavedState, setFetchingSavedState] = useState(false);
    const [fetchedSavedState, setFetchedSavedState] = useState(false);
    const [activeFields, setActiveFields] = useState<CustomField[]>([]);
    const [lastSavedState, setLastSavedState] = useState<PanelModel[] | null>(null);
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    const [previewType, setPreviewType] = useState<PreviewEntityType | ''>('');
    const [previewRecordId, setPreviewRecordId] = useState(0);
    const layoutRef = useRef<DashboardLayoutComponent | null>(null);
    const theme = useTheme();

    useEffect(() => {
        const windowResizeHandler = () => setScreenResizedControl(prev => !prev);
        window.addEventListener('resize', windowResizeHandler);
        return () => window.removeEventListener('resize', windowResizeHandler);
    }, []);

    useEffect(() => {
        const getActiveFields = async () => {
            const res = await GetCustomFieldsByEntity_OnlyActive(9);
            if (res) setActiveFields(res);
        };
        getActiveFields();
    }, []);

    const customFieldsSettingsMap = useMemo<CustomFieldSettingsMap>(() => {
        let obj: CustomFieldSettingsMap = {};
        activeFields.forEach(u => {
            const customFieldNumber = u.name.substring(13);
            const key = 'CustomField' + customFieldNumber as CustomFieldType;
            obj[key] = {
                title: u.agencyName,
                isMultiLine: u.multiLine && !u.usePredefinedValues,
            }
        });
        return obj;
    }, [activeFields]);

    useEffect(() => {
        loadingHandler && loadingHandler(fetchingSavedState);
    }, [loadingHandler, fetchingSavedState]);

    useEffect(() => {
        const getSavedState = async () => {
            setFetchingSavedState(true);
            const elementsJson = await GetCustomerSettingBySettingName('SiteRecordDashboardLayoutElements');
            if (elementsJson) {
                const panels = JSON.parse(elementsJson) as PanelModel[];
                setLastSavedState(panels);
            }
            setFetchingSavedState(false);
            setFetchedSavedState(true);
        };
        getSavedState();
    }, []);

    const elements = useMemo<SiteRecordDashboardElementDefinition[]>(() => {
        if (!fetchedSavedState) return [];
        if (lastSavedState !== null) {
            const elements = GetPanelDefinitionsFromPanelModels(lastSavedState);
            return elements ;
        }
        return DefaultSiteRecordDashboardElements;
    }, [lastSavedState, fetchedSavedState]);

    const openPreviewHandler = useCallback((type: PreviewEntityType, recordId: number) => {
        setPreviewType(type);
        setPreviewRecordId(recordId);
        setIsPreviewOpen(true);
    }, []);

    const closePreviewHandler = useCallback(() => {
        setIsPreviewOpen(false);
    }, []);

    const renderElement = useCallback((id: string, type: SiteRecordDashboardElementType) => {
        if (site) {
            switch (type) {
                case 'Divider': return <Box pt="20px"><Divider component="div" /></Box>;
                case 'Spacer': return <></>;
                case 'SiteId': return <SingleFieldElement useEllipsisForLongValues fieldTitle="ID" fieldValue={site.id.toString()} />;
                case 'SiteName': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Name" fieldValue={site.name} />;
                case 'SiteStatus': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Status" fieldValue={site.status} />;
                case 'SitePhone':
                    const phoneLink = site.phoneStandardised ? site.phoneStandardised : site.phone;
                    return <SingleFieldElement useEllipsisForLongValues fieldTitle="Phone" fieldValue={site.phone} href={phoneLink} format="phone-link" />;
                case 'SitePrimaryContact': return (
                    <SingleFieldElement
                        fieldTitle='Primary Contact'
                        fieldValue={site.primaryContactName}
                        format="internal-link"
                        href={`/contacts/${site.primaryContact}`}
                        previewType="contact"
                        previewRecordId={site.primaryContact}
                        openPreviewHandler={openPreviewHandler}
                        closePreviewHandler={closePreviewHandler}
                    />);
                case 'SiteAddress1': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Address 1" fieldValue={site.address1} />;
                case 'SiteAddress2': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Address 2" fieldValue={site.address2} />;
                case 'SiteAddress3': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Address 3" fieldValue={site.address3} />;
                case 'SiteSuburb': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Suburb" fieldValue={site.suburb} />;
                case 'SiteState': return <SingleFieldElement useEllipsisForLongValues fieldTitle={site.country === 'NZ' ? "Region" : "State"} fieldValue = { site.state } />;
                case 'SitePostcode': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Postcode" fieldValue={site.postcode} />;
                case 'SiteCountry': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Country" fieldValue={site.countryName} />;
                case 'SiteSummary': return <MultiLineTextElement title="Summary" content={site.description ?? ''} />;
                default:
                    if (type.startsWith('CustomField')) {
                        const settings = customFieldsSettingsMap[type];
                        if (settings) {
                            const title = settings.title;
                            const { field, format } = UdfSiteFieldMapObj[type as CustomFieldType];
                            const udfValue = site[field];
                            if (format === 'string' || format === 'date' || format === 'datetime') {
                                return <SingleFieldElement isMultiLine={settings.isMultiLine} format={format} fieldTitle={title} fieldValue={udfValue ? udfValue.toString() : null} />;
                            }
                            if (format === 'number') {
                                return <SingleFieldElement format={format} fieldTitle={title} fieldValue={udfValue ? +udfValue : 0} />;
                            }
                        }
                        else return <SingleFieldElement fieldTitle={type} fieldValue="[ Disabled Custom Field ]" />
                    }
                    return <div>{id}</div>;
            }
        }
    }, [site, customFieldsSettingsMap, closePreviewHandler, openPreviewHandler]);

    const layoutResizeStopHandler = useCallback(() => {
        setScreenResizedControl(prev => !prev);
    }, []);

    useEffect(() => {
        if (screenResizedControl) {}
        const api = layoutRef.current;
        if (api && fetchedSavedState) {
            const refreshTimeout = setTimeout(() => {
                api.refresh();
                api.refresh();
            }, 250);
            return () => clearTimeout(refreshTimeout);
        }
    }, [fetchedSavedState, screenResizedControl]);

    const renderLayout = useCallback(() => {
        if (!fetchedSavedState) return <></>;

        const mediaQuery = mediaQueryMaxWidth ? `max-width: ${mediaQueryMaxWidth}` : undefined;
        return (
            <DashboardLayoutComponent
                cellSpacing={cellSpacing}
                columns={columns}
                cellAspectRatio={30 / 2}
                resizeStop={ layoutResizeStopHandler }
                allowDragging={false}
                allowResizing={false}
                ref={l => layoutRef.current = l}
                mediaQuery={mediaQuery}
            >
                {elements.map(e => (
                    <PanelWrapper
                        key={e.id}
                        id={e.id}
                        col={e.col}
                        row={e.row}
                        sizeX={e.sizeX}
                        sizeY={e.sizeY}
                        minSizeX={e.minSizeX}
                        minSizeY={e.minSizeY}
                        maxSizeX={e.maxSizeX}
                        maxSizeY={e.maxSizeY}
                        resizeControl={screenResizedControl}
                        resizeIconColor={theme.palette.text.disabled}
                        gapX={gapX}
                        gapY={gapY}
                        unitWidth={unitWidth}
                        unitHeight={unitHeight}
                    >
                        {renderElement(e.id, e.type)}
                    </PanelWrapper>
                ))}
            </DashboardLayoutComponent>
        );
    }, [elements, fetchedSavedState, theme.palette.text.disabled, screenResizedControl, layoutResizeStopHandler, renderElement]);
    
    return (
        <>
            <PreviewLoaderComponent
                open={isPreviewOpen}
                entityType={previewType}
                recordId={previewRecordId}
            />
            <Box p="10px" height="100%">
                <div className="control-section">
                    { renderLayout() }
                </div>
            </Box>
        </>
    );
}