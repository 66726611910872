import MenuItem from "@mui/material/MenuItem";
import { GridColDef, GridColumnVisibilityModel, GridRenderCellParams, GridRowSelectionModel, GridValueGetter, useGridApiRef } from "@mui/x-data-grid-premium";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Activity } from "common/models/Activities";
import { Permission } from "common/models/Permissions";
import { PreviewEntityType } from "common/models/Previews/Previews";
import {
    DeleteActivities,
    GetActivitiesByClientId,
    GetActivitiesByContactId,
    GetActivitiesByOpportunityId,
    GetActivitiesByJobId,
    GetActivitiesByMeetingId,
    GetActivitiesByCandidateId,
    GetActivitiesByPlacementId,
    GetActivityReportData,
    GetDashboardActivities,
    CloseActivities,
    GetActivitiesByInterviewId
} from "services/ActivitiesService";
import { companyHasSinglePermission, userHasSinglePermission } from "util/PermissionsUtils";
import EditActivityDialog from "../Dialogs/Activities/EditActivityDialog";
import ReassignActivitiesDialog from "../Dialogs/Activities/ReassignActivitiesDialog";
import RescheduleActivitiesDialog from "../Dialogs/Activities/RescheduleActivitiesDialog";
import ConfirmationDialog from "../Dialogs/Generic/ConfirmationDialog";
import SendReferralDialog from "../Dialogs/SendReferralDialog";
import GridWithStateWrapper from "../GridWidthStateWrapper";
import PreviewLoaderComponent from "../Previews/PreviewLoader";
import ActionsDropDownButton from "../SummaryBars/Actions/ActionsDropsDownMenu";
import { GetActivitiesBySubmissionId } from "services/SubmissionsService";
import { DownloadDocument } from "services/DocumentsService";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { defaultGridCellStyle } from "util/GridUtils";

interface Props {
    source: 'dashboard' | 'client-record' | 'contact-record' | 'opportunity-record' | 'meeting-record' | 'interview-record' | 'job-record' | 'candidate-record' | 'placement-record' | 'submission-record' | 'reports',
    sourceId?: number,
    reportType?: string,
    statusFilter?: number,
    categoryFilter?: number,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
    successHandler?: (message: string) => void,
    hideActionsMenu?: boolean,
    checkboxSelection?: boolean,
    refreshControl?: boolean,
    gridName: string,
}

const linkStyle: React.CSSProperties = { color: 'inherit', textDecoration: 'underline' };

export default function ActivitiesGridComponent({ source, sourceId, reportType, statusFilter = 0, categoryFilter = 0, refreshControl, loadingHandler, errorHandler, successHandler, gridName, checkboxSelection, hideActionsMenu = false }: Props) {
    const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
    const [rows, setRows] = useState<Activity[]>([]);
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    const [previewType, setPreviewType] = useState<PreviewEntityType | ''>('');
    const [previewRecordId, setPreviewRecordId] = useState(0);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [isCloseDialogOpen, setIsCloseDialogOpen] = useState(false);
    const [isRescheduleDialogOpen, setIsRescheduleDialogOpen] = useState(false);
    const [isReassignDialogOpen, setIsReassignDialogOpen] = useState(false);
    const [editRow, setEditRow] = useState<Activity | null>(null);
    const apiRef = useGridApiRef();

    const canDelete = useMemo(() =>  companyHasSinglePermission(Permission.ActivitiesDelete) && userHasSinglePermission(Permission.ActivitiesDelete) , []);

    const getData = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        let res: Activity[] | null = [];
        if (source === 'dashboard') res = await GetDashboardActivities(errorHandler);
        if (source === 'client-record' && sourceId) res = await GetActivitiesByClientId(sourceId, statusFilter, categoryFilter, errorHandler);
        if (source === 'contact-record' && sourceId) res = await GetActivitiesByContactId(sourceId, statusFilter, categoryFilter, errorHandler);
        if (source === 'opportunity-record' && sourceId) res = await GetActivitiesByOpportunityId(sourceId, statusFilter, categoryFilter, errorHandler);
        if (source === 'meeting-record' && sourceId) res = await GetActivitiesByMeetingId(sourceId, statusFilter, categoryFilter, errorHandler);
        if (source === 'interview-record' && sourceId) res = await GetActivitiesByInterviewId(sourceId, statusFilter, categoryFilter, errorHandler);
        if (source === 'job-record' && sourceId) res = await GetActivitiesByJobId(sourceId, statusFilter, categoryFilter, errorHandler);
        if (source === 'candidate-record' && sourceId) res = await GetActivitiesByCandidateId(sourceId, statusFilter, categoryFilter, errorHandler);
        if (source === 'placement-record' && sourceId) res = await GetActivitiesByPlacementId(sourceId, statusFilter, categoryFilter, errorHandler);
        if (source === 'submission-record' && sourceId) res = await GetActivitiesBySubmissionId(sourceId, statusFilter, categoryFilter, errorHandler);
        if (source === 'reports' && sourceId && reportType) res = await GetActivityReportData(sourceId, reportType);
        if (res) setRows(res);
        loadingHandler && loadingHandler(false);
    }, [errorHandler, loadingHandler, source, sourceId, reportType, statusFilter, categoryFilter]);

    useEffect(() => {
        if (refreshControl) {}
        getData();
    }, [getData, refreshControl]);

    const downloadDocumentCallback = useCallback(async (documentId: number) => {
        loadingHandler && loadingHandler(true);
        await DownloadDocument(documentId, undefined, errorHandler);
        loadingHandler && loadingHandler(false);
    }, [loadingHandler, errorHandler]);

    const exportToExcel = useCallback(() => {
        const api = apiRef.current;
        if (api) api.exportDataAsExcel();
    }, [apiRef]);

    const columns = useMemo<GridColDef[]>(() => {
        const dateValueGetter: GridValueGetter<Activity, any, undefined, string> = (value, row) => {
            if (value) {
                const d = moment(value);
                if (d.isValid() && d.get('year') > 1) {
                    return d.toDate();
                }
            }
        }

        const activityCellRenderer = (params: GridRenderCellParams) => {
            const activityId = params.row.id;
            const activityType = params.row.typeID;
            let val = params.value;

            if (val && params.field === 'activityDate') {
                val = moment(val).format('DD MMM YYYY h:mm A');
            }

            if (activityId) {
                if (activityType === 2 || activityType === 5) {
                    return (
                        <div style={defaultGridCellStyle}>
                            <Link to={`/messages/${activityId}`} style={ linkStyle } target="_blank">{val}</Link>
                        </div>
                    );
                }
                else if (activityType === 10) {
                    return (
                        <div style={defaultGridCellStyle}>
                            <Link to={`/candidates/screening/${activityId}`} style={ linkStyle } target="_blank">{val}</Link>
                        </div>
                    );
                }
                else if (activityType === 30) {
                    return (
                        <div style={defaultGridCellStyle}>
                            <Link to={`/candidates/reference-check/${params.id}`} style={ linkStyle } target="_blank">{val}</Link>
                        </div>
                    );
                }
                return (
                    <div style={defaultGridCellStyle}>
                        <span
                            style={{ cursor: 'pointer', textDecoration: 'underline', textAlign: 'center' }}
                            onMouseEnter={ () => handlePreviewHover('activity', +activityId) }
                            onMouseLeave={ handlePreviewClose }
                            onClick={ () => setEditRow(params.row) }
                        >{val}</span>
                    </div>
                );
            }
            return val;
        }

        const minHeightCellRenderer = (params: GridRenderCellParams) => {
            return (
                <div style={defaultGridCellStyle}>
                    <span>{params.value}</span>
                </div>
            );
        };

        const linkToJobRenderer = (params: GridRenderCellParams) => {
            const jobId = params.row.jobID;
            if (jobId) {
                return (
                    <div style={defaultGridCellStyle}>
                        <Link to={`/jobs/${jobId}`} style={ linkStyle } onMouseEnter={ () => handlePreviewHover('job', +jobId) } onMouseLeave={ handlePreviewClose } >{params.value}</Link>
                    </div>
                );
            }
        }

        const linkToCandidateRenderer = (params: GridRenderCellParams) => {
            const candidateId = params.row.candidateID;
            if (candidateId) {
                return (
                    <div style={defaultGridCellStyle}>
                        <Link to={`/candidates/${candidateId}`} style={ linkStyle } onMouseEnter={ () => handlePreviewHover('candidate', candidateId) } onMouseLeave={ handlePreviewClose } >{params.value}</Link>
                    </div>
                );
            }
            return params.value;
        }

        const linkToClientRenderer = (params: GridRenderCellParams) => {
            const clientId = params.row.clientID;
            if (clientId) {
                return (
                    <div style={defaultGridCellStyle}>
                        <Link to={`/clients/${clientId}`} style={ linkStyle } onMouseEnter={ () => handlePreviewHover('client', clientId) } onMouseLeave={ handlePreviewClose } >{params.value}</Link>
                    </div>
                );
            }
            return params.value;
        }

        const linkToContactRenderer = (params: GridRenderCellParams) => {
            const contactId = params.row.contactID;
            if (contactId) {
                return (
                    <div style={defaultGridCellStyle}>
                        <Link to={`/contacts/${contactId}`} style={ linkStyle } onMouseEnter={ () => handlePreviewHover('contact', contactId) } onMouseLeave={ handlePreviewClose } >{params.value}</Link>
                    </div>
                );
            }
            return params.value;
        }

        const attachmentsRenderer = (params: GridRenderCellParams) => {
            if (params.value > 0) {
                return (
                    <IconButton onClick={() => downloadDocumentCallback(params.value as number)} size="small" sx={{ mt: '5px' }}>
                            <AttachFileIcon />
                    </IconButton>
                );
            }

            return <></>;
        };

        const htmlRenderer = (params: GridRenderCellParams) => {
            if (params.value) {
                const newLineReplaced = (params.value as string).replaceAll('\n', '<br />');
                return <div style={{ padding: '5px 0' }} dangerouslySetInnerHTML={{ __html: newLineReplaced }} />
            }
        }

        const handlePreviewHover = (type: PreviewEntityType | '', id: number) => {
            setPreviewType(type);
            setPreviewRecordId(id);
            setIsPreviewOpen(true);
        }

        const handlePreviewClose = () => {
            setIsPreviewOpen(false);
        }

        return [
            { field: 'id', headerName: 'ID', width: 75, renderCell: minHeightCellRenderer },
            { field: 'activityDate', headerName: 'Date', headerAlign: 'center', align: 'center', width: 180, type: 'date', valueGetter: dateValueGetter, renderCell: activityCellRenderer },
            { field: 'statusName', headerName: 'Status', renderCell: minHeightCellRenderer },
            { field: 'type', headerName: 'Type', headerAlign: 'center', align: 'center', width: 150, renderCell: activityCellRenderer },
            { field: 'assignedToName', headerName: 'Assigned To', width: 150, renderCell: minHeightCellRenderer },
            { field: 'notes', headerName: 'Notes', width: 800, renderCell: htmlRenderer, },
            { headerName: '', field: 'documentID', type: 'number', width: 30, renderCell: attachmentsRenderer },
            { field: 'clientName', headerName: 'Client', width: 150, renderCell: linkToClientRenderer },
            { field: 'contactName', headerName: 'Contact', width: 150, renderCell: linkToContactRenderer },
            { field: 'jobReference', headerName: 'Job', width: 150, renderCell: linkToJobRenderer },
            { field: 'candidateName', headerName: 'Candidate', width: 150, renderCell: linkToCandidateRenderer },
        ];
    }, [downloadDocumentCallback]);

    const deleteActivitiesCallback = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        const res = await DeleteActivities(selectionModel as number[], errorHandler);
        if(res) {
            setRows(prev => {
                let tmp = [...prev];
                for (let i = 0; i < selectionModel.length; i++) {
                    const id = selectionModel[i] as number;
                    const index = tmp.findIndex(a => a.id === id);
                    if (index !== -1) tmp.splice(index, 1);
                }
                setSelectionModel([]);
                return tmp;
            });
            setIsDeleteDialogOpen(false);
            successHandler && successHandler('Activities deleted');
        }
        loadingHandler && loadingHandler(false);
    }, [selectionModel, loadingHandler, errorHandler, successHandler]);

    const closeActivitiesCallback = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        const res = await CloseActivities(selectionModel as number[], errorHandler);
        loadingHandler && loadingHandler(false);
        if(res) {
            setIsCloseDialogOpen(false);
            successHandler && successHandler('Activities closed');
            await getData();
        }
    }, [selectionModel, getData, loadingHandler, successHandler, errorHandler]);

    const rescheduleSuccessHandlerCallback = useCallback((date: string) => {
        successHandler && successHandler('Activities Rescheduled');
        setIsRescheduleDialogOpen(false);
        setRows(prev => {
            let tmp = [...prev];
            for (let i = 0; i < selectionModel.length; i++) {
                const id = selectionModel[i] as number;
                const index = tmp.findIndex(a => a.id === id);
                if (index !== -1) tmp[index].activityDate = date;
            }
            setSelectionModel([]);
            return tmp;
        })
        getData();
    }, [selectionModel, successHandler, getData]);

    const reassignSuccessHandlerCallback = useCallback(() => {
        successHandler && successHandler('Activities Reassigned');
        setIsReassignDialogOpen(false);
        setSelectionModel([]);
        getData();
    }, [getData, successHandler]);

    const editSuccessHandler = useCallback((message: string) => {
        successHandler && successHandler(message);
        getData();
    }, [successHandler, getData]);

    const canClose = useMemo(() => {
        return !(source === 'reports' && reportType && reportType.startsWith('Closed'));
    }, [source, reportType]);

    const gridActions = useMemo(() => {
        if (hideActionsMenu) return undefined;
        return (
            <ActionsDropDownButton color="secondary" label="List Actions">
                {canDelete && <MenuItem disabled={ selectionModel.length === 0 } onClick={ () => setIsDeleteDialogOpen(true) }>Delete</MenuItem> }
                {canClose && <MenuItem disabled={ selectionModel.length === 0 } onClick={ () => setIsCloseDialogOpen(true) }>Bulk Close</MenuItem> }
                <MenuItem onClick={ exportToExcel }>Export</MenuItem>
                <MenuItem disabled={ selectionModel.length === 0 } onClick={ () => setIsRescheduleDialogOpen(true) }>Reschedule</MenuItem>
                <MenuItem disabled={ selectionModel.length === 0 } onClick={ () => setIsReassignDialogOpen(true) }>Reassign</MenuItem>
            </ActionsDropDownButton>
        );
    }, [canDelete, canClose, hideActionsMenu, selectionModel.length, exportToExcel]);

    const defaultHiddenCols = useMemo<GridColumnVisibilityModel>(() => {
        let model: GridColumnVisibilityModel = { id: false };
        if (source === 'dashboard') {
            model['statusName'] = false;
            model['assignedToName'] = false;
        }
        else if (source === 'client-record') model['clientName'] = false;
        else if (source === 'contact-record') model['contactName'] = false;
        else if (source === 'opportunity-record') {
            model['jobReference'] = false;
            model['candidateName'] = false;
        }
        if (categoryFilter !== 1) model['documentID'] = false;
        return model;
    }, [categoryFilter, source]);

    return (
        <>
            <PreviewLoaderComponent
                open={isPreviewOpen}
                entityType={previewType}
                recordId={previewRecordId}
            />
            <ConfirmationDialog
                open={isDeleteDialogOpen}
                message={`Are you sure you want to delete the ${selectionModel.length} selected activities?`}
                title="Confirm action"
                onClose={() => setIsDeleteDialogOpen(false)}
                onContinue={ deleteActivitiesCallback }
            />
            <ConfirmationDialog
                open={isCloseDialogOpen}
                message={`Are you sure you want to close the ${selectionModel.length} selected activities?`}
                title="Confirm action"
                onClose={() => setIsCloseDialogOpen(false)}
                onContinue={ closeActivitiesCallback }
            />
            <RescheduleActivitiesDialog
                open={ isRescheduleDialogOpen }
                activityIds={ selectionModel as number[] }
                closeHandler={ () => setIsRescheduleDialogOpen(false) }
                errorHandler={ errorHandler }
                successHandler={ rescheduleSuccessHandlerCallback }
            />
            <ReassignActivitiesDialog
                open={ isReassignDialogOpen }
                activityIds={ selectionModel as number[] }
                closeHandler={ () => setIsReassignDialogOpen(false) }
                errorHandler={ errorHandler }
                successHandler={ reassignSuccessHandlerCallback }
            />
            <EditActivityDialog
                open={ editRow !== null && editRow.typeID !== 24 }
                data={ editRow }
                closeHandler={ () => setEditRow(null) }
                loadingHandler={ loadingHandler }
                errorHandler={ errorHandler }
                successHandler={ editSuccessHandler }
            />
            <SendReferralDialog
                open={ editRow !== null && editRow.typeID === 24 }
                dialogMode="edit"
                activity={ editRow }
                closeHandler={ () => setEditRow(null) }
                loadingHandler={ loadingHandler }
                errorHandler={ errorHandler }
                successHandler={ editSuccessHandler }
            />
            {Boolean(gridActions) &&
                <Box pb="10px" ml="auto">
                    {gridActions}
                </Box>
            }
            <GridWithStateWrapper
                defaultViewModel={defaultHiddenCols}
                gridName={gridName}
                rows={rows}
                columns={columns}
                apiRef={apiRef}
                density="compact"
                checkboxSelection={checkboxSelection}
                disableRowSelectionOnClick
                rowSelectionModel={selectionModel}
                onRowSelectionModelChange={ sm  => setSelectionModel(sm) }
                pagination={true}
                pageSizeOptions={[100,250,500,1000]}
                getRowHeight={() => 'auto'}
            />
        </>
    );
}