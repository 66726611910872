import Alert from "components/Alert";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import Snackbar from "@mui/material/Snackbar";
import Typography from "@mui/material/Typography";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import React, { useCallback, useEffect, useMemo, useState } from "react";
import PageContentLayout from "layouts/PageContentLayout";
import useObjectStateWithChangeTracker from "hooks/UseObjectStateWithChangeTracker";
import { StringValueWrapper } from "common/models/GenericTypes";
import { ChangeTracker } from "common/models/hooks/ChangeTracker";
import { ExclusionValue } from "common/models/Configuration/EmailIntegration";
import { IsValidEmail } from "util/EmailUtils";
import TitleAndActionSummaryBar from "../SummaryBars/TitleAndActionSummaryBar";
import Button from "@mui/material/Button";
import { GetExclusionList, UpdateExclusionList } from "services/ConfigurationService";
import { GetMyExclusionList, UpdateMyExclusionList } from "services/UsersService";
import useUnsavedChangesDialog from "hooks/UseUnsavedChangesDialog";
import ConfirmationDialog from "components/Dialogs/Generic/ConfirmationDialog";

interface Props {
    listType: 'Me' | 'Customer',
    summaryBarTitle: string | JSX.Element,
    summaryBarTabTitle: string,
    setSummaryBar?: (summaryBar: JSX.Element) => void
}

const defaultState: StringValueWrapper = { value: '' };
const noChangesState: ChangeTracker<StringValueWrapper> = { value: false };
interface ValidatedExclusionValue extends ExclusionValue {
    error?: boolean
}

export default function EmailIntegrationExclusionsPageContent({ listType, summaryBarTitle, summaryBarTabTitle, setSummaryBar }: Props) {
    const [isLoading, setIsLoading] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [exclusions, setExclusions] = useState<ValidatedExclusionValue[]>([]);
    const [deleteIds, setDeleteIds] = useState<number[]>([]);
    const [newExclusion, setNewExclusion] = useState('');
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const { init, updateInitial, change, hasChanges } = useObjectStateWithChangeTracker<StringValueWrapper>(defaultState, noChangesState);

    const getExclusionListCallback = useCallback( async () => {
        let data: ExclusionValue[] | null = null;
        if (listType === 'Customer')
            data = await GetExclusionList(setErrorMessage);

        else if (listType === 'Me')
            data = await GetMyExclusionList(setErrorMessage);

        if(data) {
            setExclusions(data);
            init({ value: JSON.stringify(data) });
        }
    }, [init, listType]);

    const saveExclusionsCallback = useCallback( async () => {
        for (let i = 0; i < exclusions.length; i++) {
            const ex = exclusions[i];
            if (ex.error){
                setErrorMessage('All excluded emails should be valid');
                return false;
            }
        }

        setIsLoading(true);
        let res: boolean | null = null;
        if (listType === 'Customer')
            res = await UpdateExclusionList(exclusions, deleteIds, setErrorMessage);

        else if (listType === 'Me')
            res = await UpdateMyExclusionList(exclusions, deleteIds, setErrorMessage);

        if(!res) {
            setIsLoading(false);
            return false;
        }
        updateInitial();
        await getExclusionListCallback();
        setShowConfirmDialog(false);
        setShowSuccess(true);
        setIsLoading(false);
        return true;
    }, [exclusions, deleteIds, getExclusionListCallback, listType, updateInitial]);

    useEffect(() => {
        const action = <Button variant="contained" color="success" disabled={!hasChanges} onClick={() => setShowConfirmDialog(true)}>Save</Button>;
        const summaryBar = <TitleAndActionSummaryBar title={summaryBarTitle} browserTabTitle={summaryBarTabTitle} action={action} />;
        setSummaryBar && setSummaryBar(summaryBar);
    }, [setSummaryBar, hasChanges, summaryBarTitle, summaryBarTabTitle]);

    useEffect(() => {
        const getData = async () => {
            setIsLoading(true);
            await getExclusionListCallback();
            setIsLoading(false);
        };
        getData();
    }, [getExclusionListCallback]);

    const changeExclusionHandler = (i: number, val: string) => {
        const test = IsValidEmail(val);
        setExclusions(prev => {
            let newExclusions = [...prev];
            newExclusions[i].value = val;
            newExclusions[i].error = !test;
            change("value", JSON.stringify(newExclusions));
            return newExclusions;
        });
    };

    const addExclusionHandler = useCallback(() => {
        setExclusions(prev => {
            const existing = prev.find(e => e.value.toLowerCase() === newExclusion.toLowerCase());
            
            if (existing) {
                setErrorMessage(`Exclusion ${newExclusion} already exists`);
                return prev;
            }

            let newExclusions: ExclusionValue[] = [...prev, { id: 0, value: newExclusion}];
            setNewExclusion('');
            change("value", JSON.stringify(newExclusions));
            return newExclusions;
        });
    }, [newExclusion, change]);

    const deleteExclusionHandler = (i: number) => {
        setExclusions(prev => {
            const deletedId = prev[i].id;
            if(deletedId !== 0) setDeleteIds(prev => [...prev, deletedId]);
            let newExclusions = [...prev];
            newExclusions.splice(i, 1);
            change("value", JSON.stringify(newExclusions));
            return newExclusions;
        });
    };

    const isNewExclusionError = useMemo(() => newExclusion !== '' && !IsValidEmail(newExclusion), [newExclusion]);

    const { unsavedChangesDialog } = useUnsavedChangesDialog(hasChanges, saveExclusionsCallback);

    return (
        <>
            {unsavedChangesDialog}
            <Snackbar open={showSuccess} autoHideDuration={3000} onClose={() => setShowSuccess(false)}>
                <Alert onClose={() => setShowSuccess(false)}>Changes Saved</Alert>
            </Snackbar>
            <Snackbar open={errorMessage !== ''}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <ConfirmationDialog
                open={showConfirmDialog}
                message="Changes made will take up to 6 hours to take effect"
                onClose={() => setShowConfirmDialog(false)}
                onContinue={saveExclusionsCallback}
                title="Confirm action"
            />
            <PageContentLayout title="Exclusion List" showLoading={isLoading}>
                <div>
                    <div style={{ width: '400px', margin: 'auto' }}>
                        <div>
                            <span style={{ width: '85%', display: 'inline-block', textAlign: 'center' }}>Exclusion</span>
                            <span style={{ width: '15%', display: 'inline-block', textAlign: 'center' }}>Action</span>
                        </div>
                        <div style={{ paddingBottom: '20px' }}>
                            <Input error={ isNewExclusionError } placeholder="New Exclusion" sx={{ width: '85%' }} size="small" value={newExclusion} onChange={ ({target}) => setNewExclusion(target.value) } />
                            <span style={{ width: '15%', display: 'inline-block', textAlign: 'center' }}>
                                <IconButton disabled={ newExclusion === '' || isNewExclusionError } onClick={addExclusionHandler}><AddCircleIcon /></IconButton>
                            </span>
                        </div>
                        <Divider />
                        {exclusions.map((v, i) => (
                            <div key={i} style={{ paddingBottom: '10px', display: 'flex' }}>
                                <div style={{ width: '85%', display: 'inline-block' }}>
                                    <Input error={v.error} sx={{ width: '100%' }} value={v.value} onChange={ ({target}) => changeExclusionHandler(i, target.value) } />
                                    {v.error &&
                                        <Typography variant="body2" color="error">Invalid Email</Typography>
                                    }
                                </div>
                                <span style={{ width: '15%', display: 'inline-block', textAlign: 'center' }}>
                                    <IconButton onClick={ () => deleteExclusionHandler(i) }><DeleteIcon /></IconButton>
                                </span>
                            </div>
                        ))}
                    </div>
                </div>
            </PageContentLayout>
        </>
    );
}
