import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CancelIcon from '@mui/icons-material/Cancel';
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { PREVIEW_TITLE_STYLE } from "util/Definitions/Constants/Previews";
import { EmailRecipient } from "common/models/EmailRecipient";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { Editor } from "@tinymce/tinymce-react";
import { MessagePreviewData } from "common/models/Messages";
import { GetMessagePreview } from "services/MessagesService";

interface Props {
    open: boolean,
    recipients: EmailRecipient[],
    subject: string,
    body: string,
    isDarkTheme: boolean,
    recipientsEntityId: number,
    closeHandler: () => void,
    loadingHandler?: (isLoading: boolean) => void,
}

export default function EmailPreviewDialog({ open, recipients, subject, body, isDarkTheme, recipientsEntityId, closeHandler, loadingHandler }: Props) {
    const [selectedRecipientIndex, setSelectedRecipientIndex] = useState(0);
    const [previewSubject, setPreviewSubject] = useState('');
    const [previewBody, setPreviewBody] = useState('');

    const selectedRecipient = useMemo(() => {
        const r = recipients[selectedRecipientIndex];
        if (r) return r;
        return null;
    }, [recipients, selectedRecipientIndex]);

    const previewData = useMemo<MessagePreviewData | null>(() => {
        if (selectedRecipient) {
            let jobId = 0;
            if (selectedRecipient.job) jobId = selectedRecipient.job.id;
            else if (selectedRecipient.jobTracking) jobId = selectedRecipient.jobTracking.jobID;
            return {
                subject: subject,
                body: body,
                changeRequestId: selectedRecipient.changeRequest ? selectedRecipient.changeRequest.id : 0,
                placementId: selectedRecipient.placement ? selectedRecipient.placement.id : 0,
                recipientEntityId: recipientsEntityId,
                recipientRecordId: selectedRecipient.id,
                jobId: jobId,
            };
        }
        return null;
    }, [body, recipientsEntityId, selectedRecipient, subject]);

    const getPreviewCallback = useCallback(async () => {
        if (previewData) {
            loadingHandler && loadingHandler(true);
            const res = await GetMessagePreview(previewData);
            if (res) {
                setPreviewBody(res.body);
                setPreviewSubject(res.subject);
            }
            loadingHandler && loadingHandler(false);
        }
    }, [loadingHandler, previewData]);

    useEffect(() => {
        if (open && previewData) {
            setPreviewSubject('');
            setPreviewBody('');
            getPreviewCallback();
        }
    }, [getPreviewCallback, open, previewData]);

    return (
        <Dialog open={open} maxWidth="lg" fullWidth>
            <DialogTitle component="div" display="flex" sx={ PREVIEW_TITLE_STYLE }>
                <div style={{ alignSelf: 'center' }}>Email Preview</div>
                <div style={{ marginLeft: 'auto' }}>
                    <IconButton size="small" onClick={ closeHandler } sx={{ color: t => t.palette.primary.contrastText }}><CancelIcon /></IconButton>
                </div>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}></Grid>
                    <Grid item xs={12}>
                        <TextField
                            select
                            fullWidth
                            label="Recipient"
                            value={selectedRecipientIndex.toString()}
                            onChange={ ({target}) => setSelectedRecipientIndex(+target.value) }
                        >
                            {recipients.map((r, i) => (
                                <MenuItem key={`${i}-${r.id}`} value={i.toString()}>{r.name}</MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField label="Subject" value={previewSubject} fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <Editor
                            tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce-5.10.2/tinymce.min.js'}
                            value={previewBody}
                            disabled={true}
                            init={{
                                height: 420,
                                skin: isDarkTheme ? 'oxide-dark' : undefined,
                                content_css: isDarkTheme ? 'dark' : undefined,
                                branding: false,
                                menubar: false,
                                statusbar: false,
                                contextmenu: false,
                                resize: false,
                                plugins: 'powerpaste link code',
                                browser_spellcheck: true,
                                verify_html: false,
                                toolbar1: '',
                                readonly: true,
                            }}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}
