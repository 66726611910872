import React, { useState } from "react";
import Button from "@mui/material/Button";

import { SimpleAlert, ErrorHandler } from '../../common/models/GenericTypes';
import { ForgotPassword } from '../../services/AuthService'

interface Props {
    setShowLoading: React.Dispatch<React.SetStateAction<boolean>>,
    handleError: ErrorHandler,
    setInfoAlert: React.Dispatch<React.SetStateAction<SimpleAlert | null>>
}

const ForgotPasswordForm = ({ setShowLoading, handleError, setInfoAlert }: Props) => {
    const [username, setUsername] = useState('');
    const handleSubmit = async (e: React.SyntheticEvent) => {
        e.preventDefault();
        setShowLoading(true);

        const result = await ForgotPassword(username, handleError);

        if(result) {
            setInfoAlert({ show: true, message: 'Please check your email to continue' });
        }
        setShowLoading(false);
    }

    return (
        <form onSubmit={handleSubmit}>
            <input
                type='email'
                placeholder="Email"
                className="login-input-txt"
                name="Username"
                onChange={({currentTarget}) => setUsername(currentTarget.value)}
                value={username}
            />
            <Button
                disabled={username === ''}
                type="submit"
                variant="contained"
                color="primary"
                sx={{ textTransform: 'none', display: 'block', width: '100%', height: '42px' }}
            >
                Retrieve
            </Button>
        </form>
    );
}

export default ForgotPasswordForm;