import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Document } from "common/models/Document";
import { EditDocument } from "services/DocumentsService";
import RWTextFieldComponent from "components/RWTextFieldComponent";

interface Props {
    open: boolean,
    document: Document | null,
    entityTypeId: number,
    closeHandler: () => void,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
    successHandler?: (message: string, fileId: number, fileName: string, fileType: number, expirationDate: string) => void,
}

interface LabelValue {
    label: string,
    value: string
}

export default function EditDocumentDialog({ open, document, entityTypeId, closeHandler, loadingHandler, errorHandler, successHandler }: Props) {
    const [editedDocument, setEditedDocument] = useState<Document | null>(null);

    useEffect(() => {
        setEditedDocument(document);
    }, [document]);

    const docTypes = useMemo(() => {
        let types: LabelValue[] = [{ label: 'General', value: '1' }];

        switch (entityTypeId) {
            case 1:
                types.push({ label:"Terms of Business", value: "2"});
                types.push({ label:"Invoices / Receipts", value: "3"});
                types.push({ label:"Report", value: "4"});
                break;
            case 2:
                types.push({ label:"Terms of Business", value: "2"});
                types.push({ label:"Report", value: "4"});
                break;
            case 3:
                types.push({ label:"Original CV", value: "2"});
                types.push({ label:"Candidate Setup Forms", value: "5"});
                types.push({ label:"Reference Check", value: "6"});
                types.push({ label:"Formatted CV", value: "9"});
                types.push({ label:"ID, Qualifications, Licenses & Certifications", value: "10"});
                types.push({ label:"Visas", value: "11"});
                types.push({ label:"Vetting & Security", value: "12"});
                types.push({ label:"Test Results", value: "13"});
                types.push({ label:"Interview Notes", value: "14"});
                types.push({ label:"Submitted CV", value: "15"});
                types.push({ label:"Safety / Injuries", value: "16"});
                types.push({ label:"Cover Letter", value: "17"});
                break;
            case 4:
                types.push({ label:"Reference Check", value: "6"});
                types.push({ label:"Report", value: "7"});
                types.push({ label:"Job Description", value: "8"});
                types.push({ label:"Terms of Business", value: "9"});
                break;
            case 5:
                types.push({ label:"Contract - Client", value: "2"});
                types.push({ label:"Contract - Candidate", value: "3"});
                types.push({ label:"Terms of Business", value: "4"});
                types.push({ label:"Candidate Setup Forms", value: "5"});
                types.push({ label:"Contractor Review Form", value: "6"});
                types.push({ label:"Order Forms", value: "7"});
                types.push({ label:"Insurance", value: "8"});
                break;
        }

        types.sort((a,b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));
        return types;
    }, [entityTypeId]);

    const changeNameHandler = useCallback((name: string) => {
        setEditedDocument(prev => prev ? ({...prev, documentName: name + prev.documentExtension}) : null);
    }, []);

    const changeDocTypeHandler = useCallback((docType: number) => {
        setEditedDocument(prev => prev ? ({...prev, documentType: docType}) : null);
    }, []);

    const changeDateHandler = useCallback((date: moment.Moment | null) => {
        let dValue = date ? date.format('YYYY-MM-DD') : '0001-01-01';
        setEditedDocument(prev => prev ? ({...prev, expirationDate: dValue}) : null);
    }, []);

    const docName = useMemo(() => {
        if (editedDocument) {
            const fullName = editedDocument.documentName;
            const ext = editedDocument.documentExtension;
            const index = fullName.lastIndexOf(ext);
            return fullName.substring(0, index);
        }
        return '';
    }, [editedDocument]);

    const expirationDate = useMemo(() => {
        if (editedDocument && editedDocument.expirationDate) {
            const m = moment(editedDocument.expirationDate);
            if (m.isValid() && m.year() > 1) return m;
        }
        return null;
    }, [editedDocument]);

    const editDocumentCallback = useCallback(async () => {
        if (editedDocument) {
            loadingHandler && loadingHandler(true);
            const res = await EditDocument(editedDocument.id, editedDocument, errorHandler);
            if (res) {
                successHandler && successHandler('Document Changed', editedDocument.id, editedDocument.documentName, editedDocument.documentType, editedDocument.expirationDate);
                closeHandler();
            }
            loadingHandler && loadingHandler(false);
        }
    }, [editedDocument, loadingHandler, errorHandler, successHandler, closeHandler]);

    const hasChanges = useMemo(() => {
        if(editedDocument && document) {
            if (editedDocument.documentName !== document.documentName) return true;
            if (editedDocument.documentType !== document.documentType) return true;
            if (editedDocument.expirationDate !== document.expirationDate) return true;
        }
        return false;
    }, [document, editedDocument]);

    return (
        <>
            <Dialog open={open} maxWidth="md" fullWidth>
                <DialogTitle>Edit Document</DialogTitle>
                <DialogContent>
                    <Stack spacing={2}>
                        <div/>
                        <RWTextFieldComponent
                            label="Name"
                            value={docName}
                            onChange={ (e) => changeNameHandler(e.target.value) }
                        />
                        <TextField
                            select
                            fullWidth
                            label="Document Type"
                            value={editedDocument ? editedDocument.documentType.toString() : '1'}
                            onChange={({target}) => changeDocTypeHandler(+target.value)}
                        >
                            {docTypes.map((v, i) => <MenuItem key={i} value={v.value}>{v.label}</MenuItem> )}
                        </TextField>
                        <DatePicker
                            label="Expiration Date"
                            value={expirationDate}
                            onChange={changeDateHandler}
                            slotProps={{actionBar: { actions: ["clear", "today", "cancel", "accept"] }}}
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="error" onClick={ closeHandler }>Cancel</Button>
                    <Button variant="contained" color="success" disabled={!hasChanges} onClick={editDocumentCallback}>Submit</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}