import { Division } from "../common/models/Configuration/Division";
import { CandidateThreshold, DivisionEditSettings, PredictedPlacementDateOffset } from "../common/models/Configuration/Forecasting";
import {ErrorHandler, RequestOptions, SettingObject} from "../common/models/GenericTypes";
import { Get, Post, Put } from "../util/HttpUtils";

export async function GetDivisions(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    }

    return Get<Division[]>('api/divisions/all', options);
}

export async function GetActiveDivisions(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    }

    return Get<Division[]>('api/divisions/active', options);
}

export async function GetDivision(id: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Division>(`api/divisions/${id}`, options);
}

export async function GetDivisionEditSettings(id: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<DivisionEditSettings>(`api/divisions/detailed/${id}`, options);
}

export async function CreateDivision(division: Division, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>(`api/divisions`, options, division);
}

export async function UpdateDivision(id: number, division: Division, jobStages: PredictedPlacementDateOffset[],workflowStatuses: CandidateThreshold[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };
    let payload = { division: division, JobSettings: jobStages,CandidateSettings: workflowStatuses };

    return Put<boolean>(`api/divisions/${id}`, options,payload);
}

export async function GetDivisionSettings(divisionId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<SettingObject[]>(`api/divisions/${divisionId}/settings`, options);
}

export async function SaveDivisionSettings(divisionId: number, settings: SettingObject[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<SettingObject[]>(`api/divisions/${divisionId}/settings`, options, settings);
}