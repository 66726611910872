import { AdvertsConfigOptions } from "common/models/Configuration/Adverts";
import { ChangeTracker } from '../../../common/models/hooks/ChangeTracker';

export const DefaultOptions: AdvertsConfigOptions = {
    ApplyWithIndeed: 'False',
    ApplyWithLinkedIn: 'False',
    ApplyWithSeek: 'False',
    AdvertsNoPublicAdvertID: 'False',
    ExpireAdsOnClose: 'False',
    ApplicationAlertsMode: '0',
    JobApplicationConfirmation: 'False',
    AdvertsSeeAllTemplates: 'True',
    AdvertsApplicationFormUrl: '',
    AdvertsOptionalResume: 'False',
    AdvertsEAP: 'False',
}

export const NoChangesTracker: ChangeTracker<AdvertsConfigOptions> = {
    ApplyWithIndeed: false,
    ApplyWithLinkedIn: false,
    ApplyWithSeek: false,
    AdvertsNoPublicAdvertID: false,
    ExpireAdsOnClose: false,
    ApplicationAlertsMode: false,
    JobApplicationConfirmation: false,
    AdvertsSeeAllTemplates: false,
    AdvertsApplicationFormUrl: false,
    AdvertsOptionalResume: false,
    AdvertsEAP: false,
}