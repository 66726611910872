import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import React, { useEffect, useMemo, useState } from "react";
import { GetActiveDivisions } from "services/DivisionsService";
import { Division } from "common/models/Configuration/Division";
import { SxProps, Theme } from "@mui/material/styles";

interface Props {
    onSelectCallback: (divisionId: number | null) => void,
    variant?: "standard" | "filled" | "outlined" | undefined,
    divisionId: number | null,
    label?: string,
    hideLabel?: boolean,
    appendToStart?: Division[],
    sx?: SxProps<Theme>,

}

export default function DivisionPicker({ divisionId, variant, label = "Division", hideLabel = false, appendToStart, onSelectCallback, sx }: Props) {
    const [options, setOptions] = useState<Division[]>([]);

    useEffect(() => {
        const getData = async () => {
            const data = await GetActiveDivisions();
            if (data) {
                if (appendToStart && appendToStart.length > 0) setOptions([...appendToStart, ...data]);
                else setOptions(data);
            }
        };
        getData();
    }, [appendToStart]);

    const division = useMemo(() => {
        if (divisionId === null) return null;
        const division = options.find(d => d.id === divisionId);
        if (division) return division;
        return null;
    }, [divisionId, options]);

    return (
        <Autocomplete
            size="small"
            value={division}
            options={options}
            getOptionLabel={o => o.name}
            isOptionEqualToValue={ (o, v) => o.id === v.id }
            onChange={ (e,v) => onSelectCallback(v ? v.id: null) }
            sx={sx}
            renderInput={ params => <TextField {...params} variant={variant} label={hideLabel ? undefined : label} inputProps={{ ...params.inputProps, "data-lpignore": "true" }} /> }
        />
    );
}