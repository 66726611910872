import { ErrorHandler, RequestOptions } from "../common/models/GenericTypes";
import { DeleteEmailData, ScheduledMessage } from "../common/models/Messages";
import { Delete, Get, Put } from "../util/HttpUtils";

export async function GetMyScheduledMessages(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<ScheduledMessage[]>('api/ScheduledMessages/Me', options);
}

export async function GetScheduledMessages(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<ScheduledMessage[]>('api/ScheduledMessages', options);
}

export async function RescheduleMessages(date: string, smsIds: number[], emailIds: number[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>('api/ScheduledMessages/Reschedule', options, { date, emails: emailIds, sms: smsIds });
}

export async function DeleteMessages(smsIds: number[], emailData: DeleteEmailData[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Delete<boolean>('api/ScheduledMessages', options, { emails: emailData, sms: smsIds });
}

export async function UpdateScheduledEmail(messageId: number, subject: string, body: string, cc: string[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/ScheduledMessages/Emails/${messageId}`, options, { subject: subject, body: body, ccRecipients: cc })
}

export async function UpdateScheduledSms(messageId: number, body: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/ScheduledMessages/Sms/${messageId}`, options, { value: body });
}