import React, { useEffect, useState } from "react";
import { DocumentTemplate } from "common/models/Templates/DocumentTemplate";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { GetDocumentTemplates } from "services/TemplatesService";

interface Props {
    label?: string,
    typesFilter?: number[],
    statusFilter?: number,
    onSelectHandler: (t: DocumentTemplate | null) => void,
    errorHandler?: (message: string) => void,
    selectedId?: number | null
}

export default function DocumentTemplatePicker({ label, typesFilter, statusFilter, selectedId = null, onSelectHandler, errorHandler }: Props) {
    const [options, setOptions] = useState<DocumentTemplate[]>([]);
    const [selectedValue, setSelectedValue] = useState<DocumentTemplate | null>(null);

    useEffect(() => {
        const getData = async () => {
            const res = await GetDocumentTemplates(false, typesFilter, statusFilter, errorHandler);
            if (res) {
                setOptions(res);
            };
            if(selectedId != null) {
                setSelectedValue(options.find(o => o.id === selectedId) ?? null);
            }
        };
        getData();
    }, [errorHandler, options, selectedId, statusFilter, typesFilter]);

    return (
        <Autocomplete
            value={selectedValue}
            size="small"
            options={options}
            getOptionLabel={o => o.name}
            isOptionEqualToValue={ (o,v) => o.id === v.id }
            onChange={ (e,v) => onSelectHandler(v) }
            renderInput={ params => ( <TextField {...params} label={label} inputProps={{ ...params.inputProps, "data-lpignore": "true" }} /> )}
            renderOption={ (props, option) => (
                <ListItem {...props} key={option.id}>
                    <ListItemText
                        primary={option.name}
                        secondary={option.ownerName}
                    />
                </ListItem>
            )}
        />
    );
}