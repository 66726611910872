import React from 'react';
import { Link } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

import WizTopBarRouteMenu from './WizTopBarRouteMenu';
import { GenericMenuState } from '../../common/models/GenericTypes';
import TopBarRoutes from './../../common/data/TopBarRoutes';
import './WizTopBarRoutes.css'

type WizTopBarRoutesProps = {
    type: "nav" | "menu"
}

interface State extends GenericMenuState {
    selectedModule: number | null | undefined
}

export default class WizTopBarRoutes extends React.Component<WizTopBarRoutesProps, State> {
    state: Readonly<State> = {
        anchor: null,
        selectedModule: null
    }

    handleOpenMenu = (e: React.MouseEvent<HTMLElement>) => {
        this.setState({ anchor: e.currentTarget });
    }

    handleCloseMenu = () => {
        this.setState({ anchor: null });
    }

    setSelectedModule = (selectedOption: number) => {
        this.setState({ selectedModule: selectedOption });
    }

    render(): React.ReactNode {
        switch (this.props.type) {
            case "nav":
                return (
                    <nav className='main-nav-links'>
                        <ul>
                            {TopBarRoutes.map((route, index) => (
                                <li key={index}>
                                    {/* <Link style={{ color: 'white' }} to={route.path}>
                                        <Typography component='span'>{route.text}</Typography>
                                    </Link> */}
                                    <WizTopBarRouteMenu
                                        data={route}
                                        moduleKey={index}
                                        hoveredModule={this.state.selectedModule}
                                        setSelectedModule={this.setSelectedModule}
                                    />
                                </li>
                            ))}
                        </ul>
                    </nav>
                );
            case "menu":
                return (
                    <>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={this.handleOpenMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            anchorEl={this.state.anchor}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(this.state.anchor)}
                            onClose={this.handleCloseMenu}
                        >
                            <nav>
                                {TopBarRoutes.map((route, index) => (
                                    <MenuItem key={index} onClick={this.handleCloseMenu}>
                                        <Link to={route.path} className='main-nav-links-menu'>
                                            <Typography component="span" textAlign="center">{route.text}</Typography>
                                        </Link>
                                    </MenuItem>
                                ))}
                            </nav>
                        </Menu>
                    </>
                );
            default:
                break;
        }
    }
}