import React, { useState } from "react";
import ScheduledMessagesPageContent from "components/Content/ScheduledMessagesPageContent";
import PageLayout from "layouts/PageLayout";

export default function MyScheduledMessages() {
    const [summaryBar, setSummaryBar] = useState<JSX.Element>(<></>);
    return (
        <PageLayout SummaryBar={summaryBar}>
            <ScheduledMessagesPageContent
                summaryBarTitle="Dashboard > My Scheduled Messages"
                summaryBarTabTitle="My Scheduled Messages > Dashboard"
                setSummaryBar={setSummaryBar}
                dataSource="me"
            />
        </PageLayout>
    );
}