import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

interface Props {
    index: number,
    hoveredIndex: number | null,
    onMouseEnter: (i: number | null) => void,
    closeUserMenuHandler: () => void
}

const CustomPopper = styled(Popper)(({ theme }) => ({
    zIndex: theme.zIndex.appBar + 2
}));

const customLinkStyle: React.CSSProperties = { color: 'inherit', textDecoration: 'none' };

export default function WizTopBarOnboardingSubmenu({ index, hoveredIndex, onMouseEnter, closeUserMenuHandler }: Props) {
    const [anchor, setAnchor] = useState<HTMLElement | null>(null);

    useEffect(() => {
        if (index !== hoveredIndex) setAnchor(null);
    }, [index, hoveredIndex]);

    const handleHover = (e: React.MouseEvent<HTMLElement>) => {
        setAnchor(e.currentTarget);
        onMouseEnter(index);
    }

    const handleClickedLink = () => closeUserMenuHandler();

    return (
        <>
            <MenuItem onMouseEnter={handleHover}>
                <ListItemText>Onboarding Requests</ListItemText>
                <ArrowRightIcon />
            </MenuItem>
            {anchor &&
                <CustomPopper
                    open={Boolean(anchor)}
                    anchorEl={anchor}
                    placement="left-start"
                >
                    <Paper elevation={8}>
                        <MenuList>
                            <Link to="/account/onboarding-requests/all" style={customLinkStyle}>
                                <MenuItem onClick={ handleClickedLink } >
                                    All
                                </MenuItem>
                            </Link>
                            <Link to="/account/onboarding-requests/incomplete" style={customLinkStyle}>
                                <MenuItem onClick={ handleClickedLink } >
                                    Incomplete
                                </MenuItem>
                            </Link>
                        </MenuList>
                    </Paper>
                </CustomPopper>
            }
        </>
    );
}