
import { JwtPayload } from '../common/models/LoginResponse'
import Cookies from 'js-cookie';

export function getToken(): string {
    const token = Cookies.get('RecruitWizardAuth');
    if(token) {
        return token;
    }

    return '';
}

export function getJwtPayload() {
    const token = Cookies.get('RecruitWizardAuth');
    if(token) {
        if (token === '') return null;
        const splitToken = token.split('.');
        const payloadString = splitToken[1];
        if (!payloadString) return null;
        const decodedPayload = Buffer.from(payloadString ,'base64').toString();
        const payload = JSON.parse(decodedPayload) as JwtPayload;
        return payload;
    }

    return null;
}

export function isTokenValid(token: string) {
    if (token === '') return false;
    const splitToken = token.split('.');
    const payloadString = splitToken[1];
    if (!payloadString) return false;
    const decodedPayload = Buffer.from(payloadString ,'base64').toString();
    const payload = JSON.parse(decodedPayload) as JwtPayload;
    const expDate = new Date(payload.exp * 1000);
    const currentDate = new Date();
    if (expDate <= currentDate) return false;
    return true;
}

export function clearAuthData() {
    //const theme = localStorage.getItem('Theme');
    //localStorage.clear();
    //localStorage.setItem('Theme', theme ?? 'light');
    Cookies.remove('RecruitWizardAuth')
}

export function forceLogin() {
    clearAuthData();
    const returnPath = encodeURIComponent(document.location.pathname);
    const loginPage = `/login?return-url=${returnPath}`;
    document.location.href = loginPage;
}

export const isEdenRitchie = () => {
    const payload = getJwtPayload();
    if (payload && payload.CustomerID === 129) return true;
    return false;
}