import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import React, { useCallback, useMemo, useState } from "react";
import { ConvertOpportunityToJob } from "services/OpportunitiesService";

interface Props {
    isOpen: boolean,
    opportunityId: number,
    jobType: 'contract' | 'perm' | 'fixedTerm' | null,
    closeHandler: () => void,
    successHandler: (createdJobId: number) => void,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void
}

export default function ConvertToJobDialog({ isOpen, opportunityId, jobType, closeHandler, loadingHandler, successHandler, errorHandler }: Props) {
    const [shouldCopyDocs, setShouldCopyDocs] = useState(false);

    const jobTypeName = useMemo(() => {
        switch (jobType) {
            case 'contract': return 'Contract';
            case 'fixedTerm': return 'Fixed Term';
            case 'perm': return 'Perm';
        }
        return '';
    }, [jobType]);

    const onCloseCallback = useCallback(() => {
        closeHandler();
        setShouldCopyDocs(false);
    }, [closeHandler]);

    const onSubmitCallback = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        if (opportunityId && jobType) {
            const res = await ConvertOpportunityToJob(opportunityId, jobType, shouldCopyDocs, errorHandler);
            if (res) {
                successHandler(res.value);
                onCloseCallback();
            }
        }
        loadingHandler && loadingHandler(false);
    }, [errorHandler, jobType, loadingHandler, onCloseCallback, opportunityId, shouldCopyDocs, successHandler]);
    
    return (
        <Dialog open={isOpen} fullWidth>
            <DialogTitle>Convert to {jobTypeName}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to convert this Opportunity?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <FormControlLabel
                    sx={{ m: 0, mr: 'auto' }}
                    label="Copy Documents"
                    labelPlacement="end"
                    control={
                        <Checkbox
                            checked={ shouldCopyDocs }
                            onChange={ () => setShouldCopyDocs(prev => !prev) }
                            sx={{ p: '4px' }}
                        />
                    }
                />
                <Button variant="contained" color="error" onClick={ onCloseCallback }>Cancel</Button>
                <Button variant="contained" color="success" onClick={ onSubmitCallback }>Ok</Button>
            </DialogActions>
        </Dialog>
    );
}