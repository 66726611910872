import { OnboardingRequest, OnboardingForm } from "../common/models/Onboarding";
import { ErrorHandler, RequestOptions } from "../common/models/GenericTypes";
import { Get } from "../util/HttpUtils";

export async function GetCurrentCustomerESignRequests_All(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<OnboardingRequest[]>('api/Onboarding/Requests/All', options);
}

export async function GetCurrentCustomerESignRequests_Incomplete(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<OnboardingRequest[]>('api/Onboarding/Requests/Incomplete', options);
}

export async function GetOnboardingForms(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<OnboardingForm[]>('api/Onboarding/Forms/All', options);
}