import { Typography } from "@mui/material";
import React, { useEffect } from "react";
import SummaryBarContainer from './SummaryBarContainer';

interface Props {
    title: string | JSX.Element,
    action: JSX.Element | JSX.Element[],
    browserTabTitle?: string,
    height?: string | number
}

export default function TitleAndActionSummaryBar({ title, action, browserTabTitle, height }: Props) {
    useEffect(() => {
        document.title = `${browserTabTitle ?? 'Recruit Wizard'} | Recruit Wizard`;
    }, [browserTabTitle]);

    return (
        <SummaryBarContainer height={height}>
            <Typography variant="h6" overflow="hidden" flexGrow={1} display="flex" pr={1} alignItems="center" fontSize="1.15rem" >
                {title}
            </Typography>
            <div style={{ display: 'flex' }}>{action}</div>
        </SummaryBarContainer>
    );
}