import React, { useEffect, useState } from "react";
import PageContentLayout from "../../../layouts/PageContentLayout";
import JobStagesGrid from "../../../components/Grids/JobStagesGrid";
import { Button } from "@mui/material";
import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import { JobStage } from "common/models/Jobs/Job";
import { AddJobStage, GetJobStages, UpdateJobStage, UpdateJobStagesOrder } from "services/ConfigurationService";
import { NameIdObj } from "common/models/GenericTypes";
import JobStageDialog from "components/Dialogs/Jobs/JobStageDialog";
import OrderDialog from "components/Dialogs/OrderDialog";

interface Props {
    setSummaryBar?: (summaryBar: JSX.Element) => void
}


export default function JobsStagesAdmin({ setSummaryBar }: Props) {
    const [isLoading, setIsLoading] = useState(false);
    const [openOrderDialog, setOpenOrderDialog] = useState(false);
    const [openAddEditDialog, setAddEditDialog] = useState(false);
    const [rows, setRows] = useState<JobStage[]>([]);
    const [selectedJobStage, setSelectedJobStage] = useState<JobStage | null>(null);

    useEffect(() => {
        const getRows = async () => {
            isLoading && setIsLoading(true);
            let res: JobStage[] | null = [];
                res = await GetJobStages(false);
            if (res) {
                setRows(res);
            }
            isLoading && setIsLoading(false);
        };
        
        getRows();
    }, [setIsLoading, isLoading]);

    const handleJobStageSave = async (jobStage: JobStage) => {
        if(jobStage.stageOrder == -1) {
            jobStage.stageOrder = rows.length-1;
        }

        if(jobStage.id == 0) {
            const response = await AddJobStage(jobStage);
           if(response) {
            jobStage.id = response;
            setRows(prev => {
                let tmp = [...prev];
                tmp.push(jobStage);
                return tmp;
            });
           }
        }else {
            const response = await UpdateJobStage(jobStage);
            if(response) {
                setRows(prev => {
                    let tmp = [...prev];
                    const stage = tmp.find(s=>s.id == jobStage.id);
                    if(stage) {
                        let stageIndex = tmp.indexOf(stage);
                        tmp[stageIndex].name = jobStage.name;
                        tmp[stageIndex].enabled = jobStage.enabled;
                    }
                    return tmp;
                });
            }
        }
        setAddEditDialog(false);
    }

    const handleStageOrder = async (orderItems: NameIdObj[]) => {
        setRows(prev => {
            let tmp = [...prev];
            orderItems.forEach(o => {
                let stage = tmp.find(t=>t.id == o.id);
                if(stage) {
                    let indexOfStage = tmp.indexOf(stage);
                    stage.stageOrder = orderItems.indexOf(o);
                    tmp[indexOfStage] = stage;
                }
            });
            return tmp.sort((a, b) => a.stageOrder - b.stageOrder);
        });

         const result = await UpdateJobStagesOrder(orderItems.map(o=>o.id));
         if(result) {
            setOpenOrderDialog(false);
         }

    }

    const handleSelectedJobStage = (jobStage: JobStage) => {
        setSelectedJobStage(jobStage);
        setAddEditDialog(true);
    }

    useEffect(() => {
        const action = <>
            <Button variant="contained" color="secondary" sx={{mr: '10px'}} onClick={() => {setOpenOrderDialog(true)}}>Order Groups</Button>
            <Button variant="contained" color="primary"  onClick={() => {setAddEditDialog(true); setSelectedJobStage(null);}}>Create</Button>
        </>;
        const summaryBar = <TitleAndActionSummaryBar title='Configuration > Jobs > Stages' browserTabTitle="Jobs > Configuration"action={action} />;
        setSummaryBar && setSummaryBar(summaryBar);
    }, [setSummaryBar, rows]);

    return (
    <>
     <JobStageDialog 
                       open={openAddEditDialog}
                       jobStage={selectedJobStage}
                       closeHandler={() => setAddEditDialog(false)}
                       submitHandler={handleJobStageSave}/>

        <OrderDialog
                    orderItems={rows.map(r=> {
                            return { id: r.id, name: r.name} as NameIdObj;
                        })}
                    title="Order Job Stages"
                    open={openOrderDialog}
                    closeHandler={() => setOpenOrderDialog(false)}
                    submitHandler={handleStageOrder}/>
     <PageContentLayout showLoading={isLoading} title='Stages'>
      <JobStagesGrid 
            gridName="job-stages-grid"
            handleSelectedJobStage={handleSelectedJobStage}
            jobStages={rows}
      />
    </PageContentLayout>
    </>
   );
}