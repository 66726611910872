import React, { useMemo, useState, useEffect } from "react";
import OnlyTitleSummaryBar from "components/SummaryBars/OnlyTitleSummaryBar";
import PageContentLayout from "layouts/PageContentLayout";
import PageLayout from "layouts/PageLayout";
import { BulkExtensionImport } from "common/models/Imports/Placements/BulkExtensionImport";
import { ImportBulkExtensions } from "services/PlacementsService";
import { GetMyUser } from "services/UsersService";

import Snackbar from "@mui/material/Snackbar";
import Alert from "components/Alert";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

// @ts-ignore
import { CSVBoxButton } from '@csvbox/react'
import { GetCustomFieldsByEntity_OnlyActive } from "services/CustomFieldsService";

type DynamicCol = { 'column_name': string, 'display_label': string };

function castJsonToType<T>(json: string): T {
    try {
        const parsedJson = JSON.parse(json);
        return parsedJson as T;
    } catch (e) {
        const m = (e as any).message;
        throw new Error(`Failed to cast JSON to type: ${m}`);
    }
}

export default function PlacementBulkExtensionsImport() {
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [consultantId, setConsultantId] = useState(0);
    const [customFieldCols, setCustomFieldCols] = useState<DynamicCol[]>([]);
    const [customFieldSetupFinished, setCustomFieldSetupFinished] = useState(false);

    useEffect(() => {
        const getUser = async () => {
            const u = await GetMyUser();
            if (u) setConsultantId(u.userID);
        };
        getUser();
    }, []);

    useEffect(() => {
        const getCustomFieldData = async () => {
            setIsLoading(true);
            const res = await GetCustomFieldsByEntity_OnlyActive(5, setErrorMessage);
            if (res && res.length > 0) {
                let fields: DynamicCol[] = [];
                for (let i = 0; i < res.length; i++) {
                    const cf = res[i];
                    fields.push({ display_label: cf.agencyName, column_name: cf.nameWithoutSpaces });
                }
                setCustomFieldCols(fields);
            }
            setIsLoading(false);
            setCustomFieldSetupFinished(true);
        };
        getCustomFieldData();
    }, []);

    const summaryBar = useMemo(() => {
        return <OnlyTitleSummaryBar title="Placements > Import Spreadsheet > Bulk Extensions" browserTabTitle="Bulk Extensions > Import Spreadsheet > Clients" />;
    }, []);
    
    return (
        <PageLayout SummaryBar={summaryBar}>
            <Snackbar open={successMessage !== ''} autoHideDuration={3000} onClose={() => setSuccessMessage('')}>
                <Alert onClose={() => setSuccessMessage('')}>{ successMessage }</Alert>
            </Snackbar>
            <Snackbar open={errorMessage !== ''} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <PageContentLayout showLoading={ isLoading }>
                {customFieldSetupFinished &&
                    <CSVBoxButton
                        licenseKey="icin3HbUhH9BDPZ4eSmP7ZJNi4So3k"
                        user={{
                            user_id: consultantId,
                        }}
                        dynamicColumns={customFieldCols}
                        onImport={(result: any, data: any) => {
                            if (result) {
                                const json = JSON.stringify(data);

                                const obj = castJsonToType<BulkExtensionImport>(json);
                                
                                console.log(obj.rows);

                                const importData = async () => {
                                    await ImportBulkExtensions(obj.rows, setErrorMessage);
                                };
                                importData();

                                setSuccessMessage(data.row_success + " rows imported");
                            } else {
                                setErrorMessage("fail");
                            }
                        }}
                        render={(launch: any, isLoading: any) => {
                            return <Box textAlign='center'><Button variant="contained" disabled={isLoading} onClick={launch}>Start Import</Button></Box>;
                        }}
                    >
                        Import
                    </CSVBoxButton>
                }
            </PageContentLayout>
        </PageLayout>
    );
}