import React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import RemoveIcon from '@mui/icons-material/Remove';
import { ReferenceCheckTemplateCustomField } from "common/models/Templates/ReferenceCheckTemplate";


interface Props {
    index: number,
    option: ReferenceCheckTemplateCustomField,
    onChange: (index: number, option: ReferenceCheckTemplateCustomField) => void,
    onDelete: (index: number) => void
}

export default function ReferenceChecksCustomFields({ index, option, onChange, onDelete }: Props) {
    const stringChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        onChange(index, { ...option, [name]: value });
    };

    return (
        <Box display="flex" justifyContent="center">
            <Stack direction="row" spacing={2} width="100%">
                <TextField
                    label="Text"
                    name="Text"
                    value={option.Text ?? ""}
                    onChange={stringChangeHandler}
                    sx={{ flexGrow: 1 }}
                />
                <IconButton onClick={() => onDelete(index)}>
                    <RemoveIcon />
                </IconButton>
            </Stack>
        </Box>
    );
}