import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Submission } from "common/models/Submissions/Submission";
import { DefaultSubmissionRecordDashboardElements, DefaultSubmissionRecordDashboardElements_Float, SubmissionScreenLayoutSettings } from "util/Definitions/ScreenLayouts/Submission";
import { useTheme } from "@mui/material/styles";
import { SubmissionRecordDashboardElementDefinition, SubmissionRecordDashboardElementType } from "common/models/Dashboard/EditLayout";
import Box from "@mui/material/Box";
import { DashboardLayoutComponent } from "@syncfusion/ej2-react-layouts/src/dashboard-layout/dashboardlayout.component";
import PanelWrapper from "components/Dashboards/PanelWrapper";
import Divider from "@mui/material/Divider";
import SingleFieldElement from "../SingleFieldElement";
import MultiLineTextElement from "../MultiLineTextElement";
import { PreviewEntityType } from "common/models/Previews/Previews";
import PreviewLoaderComponent from "components/Previews/PreviewLoader";

interface Props {
    submission: Submission | null,
    isFloat?: boolean,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
}

const { unitWidth, unitHeight, gapX, gapY, columns, mediaQueryMaxWidth } = SubmissionScreenLayoutSettings;
const cellSpacing = [gapX, gapY];

export default function ViewRecordScreenLayout({ submission, isFloat, loadingHandler, errorHandler }: Props) {
    const [screenResizedControl, setScreenResizedControl] = useState(false);
    const [fetchingSavedState, setFetchingSavedState] = useState(false);
    const [fetchedSavedState, setFetchedSavedState] = useState(false);
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    const [previewType, setPreviewType] = useState<PreviewEntityType | ''>('');
    const [previewRecordId, setPreviewRecordId] = useState(0);
    const layoutRef = useRef<DashboardLayoutComponent | null>(null);
    const theme = useTheme();

    useEffect(() => {
        const windowResizeHandler = () => setScreenResizedControl(prev => !prev);
        window.addEventListener('resize', windowResizeHandler);
        return () => window.removeEventListener('resize', windowResizeHandler);
    }, []);

    useEffect(() => {
        loadingHandler && loadingHandler(fetchingSavedState);
    }, [loadingHandler, fetchingSavedState]);

    useEffect(() => {
        const getSavedState = async () => {
            setFetchingSavedState(true);
            setFetchingSavedState(false);
            setFetchedSavedState(true);
        };
        getSavedState();
    }, []);

    const elements = useMemo<SubmissionRecordDashboardElementDefinition[]>(() => {
        if (isFloat) return DefaultSubmissionRecordDashboardElements_Float;
        else return DefaultSubmissionRecordDashboardElements;
    }, [isFloat]);

    const openPreviewHandler = useCallback((type: PreviewEntityType, recordId: number) => {
        setPreviewType(type);
        setPreviewRecordId(recordId);
        setIsPreviewOpen(true);
    }, []);

    const closePreviewHandler = useCallback(() => {
        setIsPreviewOpen(false);
    }, []);

    const renderElement = useCallback((id: string, type: SubmissionRecordDashboardElementType) => {
        if (submission) {
            switch (type) {
                case 'Divider': return <Box pt="20px"><Divider component="div" /></Box>;
                case 'Spacer': return <></>;
                case 'SubmissionJobId': return (
                    <SingleFieldElement
                        useEllipsisForLongValues
                        fieldTitle="Job ID"
                        fieldValue={submission.jobID.toString()}
                        format="internal-link"
                        href={`/jobs/${submission.jobID}`}
                        previewType="job"
                        previewRecordId={submission.jobID}
                        openPreviewHandler={openPreviewHandler}
                        closePreviewHandler={closePreviewHandler}
                    />);
                case 'SubmissionDistributionMode': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Distribution Mode" fieldValue={submission.distributionModeName} />;
                case 'SubmissionJobTitle': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Job Title" fieldValue={submission.jobTitle} />;
                case 'SubmissionType': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Type" fieldValue={submission.typeName} />;
                case 'SubmissionDate': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Date" format="datetime" fieldValue={submission.createdDate} />;
                case 'SubmissionAvailability': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Availability" fieldValue={submission.availability} />;
                case 'SubmissionClientRate': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Client Rate" fieldValue={submission.clientRate} />;
                case 'SubmissionCandidateRate': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Candidate Rate" fieldValue={submission.candidateRate} />;
                case 'SubmissionRating': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Rating" fieldValue={submission.rating} />;
                case 'SubmissionVideoLink': 
                    const link = submission.videoLink;
                    const hasLink = Boolean(link);
                    if (hasLink) return <SingleFieldElement useEllipsisForLongValues fieldTitle="Video Link" format="external-link" fieldValue="Click to view Video" href={link} />;
                    return <SingleFieldElement fieldTitle="Video Link" fieldValue=""/>;
                case 'SubmissionAssignedTo': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Assigned To" fieldValue={submission.userName} />;
                case 'SubmissionCandidateNotificationSent': return <SingleFieldElement fieldTitle="Candidate Notif. Sent" fieldValue={submission.candidateNotificationSent ? 'Yes' : 'No'} />;
                case 'SubmissionComments': return <MultiLineTextElement title="Comments" content={submission.comments} />;
                default: return <div>{id}</div>;
            }
        }
    }, [closePreviewHandler, openPreviewHandler, submission]);
    
    const layoutResizeStopHandler = useCallback(() => {
        setScreenResizedControl(prev => !prev);
    }, []);

    useEffect(() => {
        if (screenResizedControl) {}
        const api = layoutRef.current;
        if (api && fetchedSavedState) {
            const refreshTimeout = setTimeout(() => {
                api.refresh();
                api.refresh();
            }, 250);
            return () => clearTimeout(refreshTimeout);
        }
    }, [fetchedSavedState, screenResizedControl]);

    const renderLayout = useCallback(() => {
        if (!fetchedSavedState) return <></>;

        const mediaQuery = mediaQueryMaxWidth ? `max-width: ${mediaQueryMaxWidth}` : undefined;
        return (
            <DashboardLayoutComponent
                cellSpacing={cellSpacing}
                columns={columns}
                cellAspectRatio={30 / 2}
                resizeStop={ layoutResizeStopHandler }
                allowDragging={false}
                allowResizing={false}
                ref={l => layoutRef.current = l}
                mediaQuery={mediaQuery}
            >
                {elements.map(e => (
                    <PanelWrapper
                        key={e.id}
                        id={e.id}
                        col={e.col}
                        row={e.row}
                        sizeX={e.sizeX}
                        sizeY={e.sizeY}
                        minSizeX={e.minSizeX}
                        minSizeY={e.minSizeY}
                        maxSizeX={e.maxSizeX}
                        maxSizeY={e.maxSizeY}
                        resizeControl={screenResizedControl}
                        resizeIconColor={theme.palette.text.disabled}
                        gapX={gapX}
                        gapY={gapY}
                        unitWidth={unitWidth}
                        unitHeight={unitHeight}
                    >
                        {renderElement(e.id, e.type)}
                    </PanelWrapper>
                ))}
            </DashboardLayoutComponent>
        );
    }, [elements, fetchedSavedState, theme.palette.text.disabled, screenResizedControl, layoutResizeStopHandler, renderElement]);
    
    return (
        <>
            <PreviewLoaderComponent
                open={isPreviewOpen}
                entityType={previewType}
                recordId={previewRecordId}
            />
            <Box p="10px" height="100%">
                <div className="control-section">
                    {isFloat && renderLayout() }
                    {!isFloat && renderLayout() }
                </div>
            </Box>
        </>
    );
}