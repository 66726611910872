import React, { useEffect, useMemo, useState } from "react";
import SideNav from "components/SideBars/SideNav";
import { Navigate, Route, Routes } from "react-router-dom";
import MyDashboardPage from "./Dashboard/MyDashboard";
import CustomDashboardPage from "./Dashboard/CustomDashboard";
import CompanyDashboardPage from "./Dashboard/CompanyDashboard";
import KPIDashboardPage from "./Dashboard/KPIDashboard";
import Top20ClientsPage from "./Clients/Top20";
import Top20ContactsPage from "./Contacts/Top20";
import { isEdenRitchie } from "util/AuthUtils";
import EdenRitchieDashboardPage from "./Dashboard/EdenRitchieDashboard";
import OpporunitiesOverviewPage from "./Opportunities/Overview";
import JobsByConsultantPage from "./Jobs/ByConsultant";
import JobsBySourcePage from "./Jobs/BySource";
import JobsByStagePage from "./Jobs/ByStage";
import AdvertApplicantsBySourcePage from "./Adverts/ApplicantsBySource";
import PlacementsAverageFeesPage from "./Placements/PlacementsAverageFees";
import PlacementsContractFinancialsPage from "./Placements/PlacementsContractFinancials";
import PlacementsContractorBookPage from "./Placements/PlacementsContractorBook";
import PlacementsBySourcePage from "./Placements/PlacementsBySource";
import PerformanceActivitiesPage from "pages/Analytics/Misc/PerformanceActivities";
import PerformanceActivityRatiosPage from "pages/Analytics/Misc/PerformanceActivityRatios";
import PerformanceActivityRatioTrendsPage from "pages/Analytics/Misc/PerformanceActivityRatioTrends";
import PerformanceForecastVsTargetPage from "pages/Analytics/Placements/PerformanceForecastVsTarget";
import PerformancePhoneTimePage from "pages/Analytics/Misc/PerformancePhoneTime";
import PerformanceFeesByConsultantPage from "pages/Analytics/Placements/PerformanceFeesByConsultant";
import { GetAnalyticsSettings } from "services/ConfigurationService";
import ClientReportsPage from "pages/Analytics/Clients/Reports";
import ContactReportsPage from "pages/Analytics/Contacts/Reports";
import OpportunitiesReportsPage from "pages/Analytics/Opportunities/Reports";
import JobsReportsPage from "pages/Analytics/Jobs/Reports";
import AdvertsReportsPage from "pages/Analytics/Adverts/Reports";
import CandidatesReportsPage from "pages/Analytics/Candidates/Reports";
import PlacementsReportsPage from "pages/Analytics/Placements/Reports";
import MiscReportsPage from "pages/Analytics/Misc/Reports"

import {AllowClientsReportsAccess} from "../../common/data/Permissions/ClientsAccess";
import {AllowContactsReportsAccess} from "../../common/data/Permissions/ContactsAccess";
import {AllowOpportunitiesReportsAccess} from "../../common/data/Permissions/OpportunitiesAccess";
import {AllowJobsReportsAccess} from "../../common/data/Permissions/JobsAccess";
import {AllowAdvertsReportsAccess} from "../../common/data/Permissions/AdvertsAccess";
import {AllowCandidatesReportsAccess} from "../../common/data/Permissions/CandidatesAccess";
import {AllowPlacementsReportsAccess} from "../../common/data/Permissions/PlacementsAccess";

const NotFound = <Navigate to='/not-found' />;
const NotAllowed = <Navigate to='/not-authorized' />;
const defaultPath = "/analytics/dashboards/mine";

export default function AnalyticsIndex() {
    const [targetLabelOverride, setTargetLabelOverride] = useState('Target');
    const [stretchLabelOverride, setStretchLabelOverride] = useState('Stretch Target');
    const AllowClientReports = useMemo(() => AllowClientsReportsAccess(), []);
    const AllowContactReports = useMemo(() => AllowContactsReportsAccess(), []);
    const AllowOpportunityReports = useMemo(() => AllowOpportunitiesReportsAccess(), []);
    const AllowJobsReports = useMemo(() => AllowJobsReportsAccess(), []);
    const AllowAdvertsReports = useMemo(() => AllowAdvertsReportsAccess(), []);
    const AllowCandidatesReports = useMemo(() => AllowCandidatesReportsAccess(), []);
    const AllowPlacementsReports = useMemo(() => AllowPlacementsReportsAccess(), []);

    const isUserEdenRitchie = useMemo(() => isEdenRitchie(), []);

    useEffect(() => {
        const getSettings = async () => {
            const res = await GetAnalyticsSettings();
            if (res) {
                for (let i = 0; i < res.length; i++) {
                    const setting = res[i];
                    if (setting.type === 'TargetLabelOverride' && setting.value) setTargetLabelOverride(setting.value);
                    if (setting.type === 'StretchLabelOverride' && setting.value) setStretchLabelOverride(setting.value);
                }
            }
        };
        getSettings();
    }, []);

    return (
            <Routes>
                <Route path='' element={ <Navigate to={defaultPath} /> } />
                
                <Route path='dashboards/mine' element={ <MyDashboardPage /> } />
                <Route path='dashboards/custom' element={ <CustomDashboardPage /> } />
                <Route path='dashboards/company' element={ <CompanyDashboardPage /> } />
                <Route path='dashboards/eden-ritchie' element={ isUserEdenRitchie ? <EdenRitchieDashboardPage /> : NotFound }  />
                <Route path='dashboards/kpi' element={ <KPIDashboardPage /> } />
                
                <Route path='clients/top-20' element={ <Top20ClientsPage /> } />
                
                <Route path='contacts/top-20' element={ <Top20ContactsPage /> } />
                
                <Route path='opportunities/overview' element={ <OpporunitiesOverviewPage /> } />
                
                <Route path='jobs/by-consultant' element={ <JobsByConsultantPage /> } />
                <Route path='jobs/by-source' element={ <JobsBySourcePage /> } />
                <Route path='jobs/by-stage' element={ <JobsByStagePage /> } />
                
                <Route path='adverts/applicants-by-source' element={ <AdvertApplicantsBySourcePage /> } />
                
                <Route path='placements/average-fees' element={ <PlacementsAverageFeesPage /> } />
                <Route path='placements/contract-financials' element={ <PlacementsContractFinancialsPage /> } />
                <Route path='placements/contractor-book' element={ <PlacementsContractorBookPage /> } />
                <Route path='placements/by-source' element={ <PlacementsBySourcePage /> } />
                <Route path='placements/fees-by-consultant' element={ <PerformanceFeesByConsultantPage /> } />
                <Route path='placements/forecasts-vs-targets' element={ <PerformanceForecastVsTargetPage targetLabelOverride={targetLabelOverride} stretchLabelOverride={stretchLabelOverride} /> } />
                
                <Route path='misc/activities' element={ <PerformanceActivitiesPage /> } />
                <Route path='misc/activity-ratios' element={ <PerformanceActivityRatiosPage /> } />
                <Route path='misc/activity-ratio-trends' element={ <PerformanceActivityRatioTrendsPage /> } />
                <Route path='misc/phone-time' element={ <PerformancePhoneTimePage /> } />

                <Route path='clients' element={AllowClientReports ? <ClientReportsPage /> : NotAllowed} />
                <Route path='clients/:reportName' element={AllowClientReports ? <ClientReportsPage /> : NotAllowed} />
                <Route path='contacts' element={AllowContactReports ? <ContactReportsPage /> : NotAllowed} />
                <Route path='contacts/:reportName' element={AllowContactReports ? <ContactReportsPage /> : NotAllowed} />
                <Route path='opportunities' element={AllowOpportunityReports ? <OpportunitiesReportsPage /> : NotAllowed} />
                <Route path='opportunities/:reportName' element={AllowOpportunityReports ? <OpportunitiesReportsPage /> : NotAllowed} />
                <Route path='jobs' element={AllowJobsReports ? <JobsReportsPage /> : NotAllowed} />
                <Route path='jobs/:reportName' element={AllowJobsReports ? <JobsReportsPage /> : NotAllowed} />
                <Route path='adverts' element={AllowAdvertsReports ? <AdvertsReportsPage /> : NotAllowed} />
                <Route path='adverts/:reportName' element={AllowAdvertsReports ? <AdvertsReportsPage /> : NotAllowed} />
                <Route path='candidates' element={AllowCandidatesReports ? <CandidatesReportsPage /> : NotAllowed} />
                <Route path='candidates/:reportName' element={AllowCandidatesReports ? <CandidatesReportsPage /> : NotAllowed} />
                <Route path='placements' element={AllowPlacementsReports ? <PlacementsReportsPage /> : NotAllowed} />
                <Route path='placements/:reportName' element={AllowPlacementsReports ? <PlacementsReportsPage /> : NotAllowed} />
                <Route path='misc' element={<MiscReportsPage />} />
                <Route path='misc/:reportName' element={<MiscReportsPage />} />
            </Routes>
    );
}