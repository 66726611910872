import Autocomplete, { AutocompleteInputChangeReason } from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import TextField from "@mui/material/TextField";
import React, { useCallback, useEffect, useState } from "react";
import { Placement } from "common/models/Placements";
import { GetPlacementById, PlacementLookup } from "services/PlacementsService";

interface Props {
    value: number | null,
    onSelectCallback: (c: Placement | null) => void,
    variant?: "standard" | "filled" | "outlined" | undefined
    label?: string,
    delayMs?: number,
    hideLabel?: boolean,
    disabled?: boolean,
    loadingHandler?: (isLoading: boolean) => void,
    successHandler?: (message: string) => void,
    errorHandler?: (message: string) => void,
}

export default function PlacementPicker({ value, label = 'Placement', variant, hideLabel = false, delayMs = 200, disabled, onSelectCallback, errorHandler, loadingHandler, successHandler }: Props) {
    const [selectedPlacement, setSelectedPlacement] = useState<Placement | null>(null);
    const [lookupTerm, setLookupTerm] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState<Placement[]>([]);

    const getPlacement = useCallback(async (placementId: number) => {
        const res = await GetPlacementById(placementId);
        if (res) setSelectedPlacement(res);
    }, []);

    useEffect(() => {
        if (value) {
            if (!selectedPlacement || (selectedPlacement && selectedPlacement.id !== value)) {
                const item = options.find(o => o.id === value);
                if (item) setSelectedPlacement(item);
                else getPlacement(value);
            }
        }
        else setSelectedPlacement(null);
    }, [value, selectedPlacement, options, getPlacement]);

    useEffect(() => {
        if (lookupTerm.length > 0) {
            const delaySearch = setTimeout(async () => {
                setIsLoading(true);
                const res = await PlacementLookup(lookupTerm, 25);
                if (res) setOptions(res);
                setIsLoading(false);
            }, delayMs);
            return () => clearTimeout(delaySearch);
        }
    }, [lookupTerm, delayMs]);

    const handleInputChange = useCallback((event: React.SyntheticEvent<Element, Event>, value: string, reason: AutocompleteInputChangeReason) => {
        reason !== 'reset' && setLookupTerm(value);
    }, []);

    return (
        <Autocomplete
            size="small"
            fullWidth
            options={options}
            value={ selectedPlacement }
            getOptionLabel={ o => `${o.id} - ${o.jobTitle} - ${o.candidateName}` }
            isOptionEqualToValue={ (o,v) => o.id === v.id }
            loading={isLoading}
            disabled={disabled}
            filterOptions={ x => x }
            onChange={ (e,v) => onSelectCallback(v) }
            renderInput={ (params) => (
                <TextField {...params} variant={variant} label={hideLabel ? undefined : label} inputProps={{ ...params.inputProps, "data-lpignore": "true" }}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (<>{isLoading ? <CircularProgress color="inherit" size={20} /> : params.InputProps.endAdornment}</>)
                    }}
                />
            )}
            onInputChange={ handleInputChange }
            renderOption={(props, option) => (
                <ListItem {...props} key={option.id}>
                    <ListItemText
                        primary={`${option.id} - ${option.jobTitle}`}
                        secondary={option.placementTypeName}
                    />
                </ListItem>
            )}
        />
    );
}