import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import TextField from "@mui/material/TextField";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import UserPicker from "../Pickers/UserPicker";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import DeleteIcon from '@mui/icons-material/Delete';
import useObjectStateWithChangeTracker from "hooks/UseObjectStateWithChangeTracker";
import { ChangeTracker } from "common/models/hooks/ChangeTracker";
import { Editor } from "@tinymce/tinymce-react/lib/cjs/main/ts/components/Editor";
import * as tinymce from 'tinymce';
import { useTheme } from "@mui/material/styles";
import MessageTemplatePicker from "../Pickers/MessageTemplatePicker";
import { MessageTemplate } from "common/models/Templates/MessageTemplate";
import Button from "@mui/material/Button";
import TitleAndActionSummaryBar from "../SummaryBars/TitleAndActionSummaryBar";
import { NameIdObj } from "common/models/GenericTypes";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import IconButton from "@mui/material/IconButton";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { Contact } from "common/models/Contacts";
import ContactPicker from "../Pickers/ContactPicker";
import List from "@mui/material/List";
import { GetJobById } from "services/JobsService";
import { GetMyUser, GetSettingBySettingName } from "services/UsersService";
import { GetCustomerSettingBySettingName } from "services/ConfigurationService";
import FilePicker from "../Pickers/FilePicker";
import { CreateSubmission } from "services/SubmissionsService";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import moment from "moment";
import DocumentPicker from "../Pickers/DocumentPicker";
import { Document } from "common/models/Document";
import { Navigate } from "react-router";
import { GetContactAttendeeData } from "services/ContactsService";
import { GetCandidateAttendeeData, GetCandidateJob } from "services/CandidatesService";
import { Link } from "react-router-dom";
import { Job } from "common/models/Jobs/Job";
import SubmissionPreviewDialog from "components/Dialogs/Submissions/SubmissionPreviewDialog";
import { TemplatePlaceholder } from "common/models/Templates/TemplatePlaceholder";
import { GetPlaceholdersByEntityList } from "services/PlaceholdersService";
import InsertPlaceholderDialog from "components/Dialogs/Generic/InsertPlaceholderDialog";
import { UiCandidateData, UiSubmissionEdit } from "common/models/Submissions/Submission";
import RWTextFieldComponent from "components/RWTextFieldComponent";
import ConfirmationDialog from "components/Dialogs/Generic/ConfirmationDialog";
import { GetComplianceData } from "services/ComplianceService";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

interface Props {
    candidateIds?: number[],
    contactIds?: number[],
    jobId?: number,
    setSummaryBar?: (sb: JSX.Element) => void,
    loadingHandler?: (isLoading: boolean) => void,
    successHandler?: (message: string) => void,
    errorHandler?: (message: string) => void,
    warningHandler?: (message: string) => void,
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface AttendeeWithEmail extends NameIdObj {
    email: string | null
}

// const DefaultCandidateData: CandidateData = {
//     id: 0,
//     email: '',
//     name: '',
//     cvId: 0,
//     videoLink: '',
//     clientRate: '',
//     candidateRate: '',
//     availability: '',
//     comments: '',
//     files: []
// };

const DefaultSubmissionData: UiSubmissionEdit = {
    jobId: 0,
    jobTitle: '',
    assignedTo: 0,
    distributionModeId: 0,
    subject: '',
    body: '',
    sendCandidateConfirmaiton: false,
    contactsJson: '[]',
    followUpAssignedTo: 0,
};

const NoChangesSubmissionData: ChangeTracker<UiSubmissionEdit> = {
    jobId: false,
    jobTitle: false,
    assignedTo: false,
    distributionModeId: false,
    subject: false,
    body: false,
    sendCandidateConfirmaiton: false,
    contactsJson: false,
    followUpAssignedTo: false
};

const originalCvOption: Document = { 
    id: -1,
    documentName: 'Original CV',
    createdByName: '',
    createdDate: '',
    deleted: false,
    documentExtension: '',
    documentType: 0,
    documentTypeName: '',
    expirationDate: '',
    size: 0
};

const renderSummary = (j: Job) => {
    let jobData: React.ReactNode | undefined = undefined;

    if (j) {
        const jLink = <Link to={`/jobs/${j.id}`} style={{ color: 'inherit' }}>{j.title}</Link>;
        const cLink = <Link to={`/clients/${j.clientID}`} style={{ color: 'inherit' }}>{j.clientName}</Link>;
        jobData = <>&nbsp;{jLink}&nbsp;{'@'}&nbsp;{cLink}&nbsp;</>;
    }
    
    const primary = "Submissions > Create";
    return j ? <>{primary} ({jobData})</> : primary;
};

const submissionEntityType = 6;
const contactEntityType = 2;

export default function SubmissionEditPageContent({ candidateIds, contactIds, jobId: jobIdProp = 0, setSummaryBar, loadingHandler, successHandler, errorHandler, warningHandler }: Props) {
    const [activeStep, setActiveStep] = useState(0);
    const [isEditorDirty, setIsEditorDirty] = useState(false);
    const [isFetchingSetupData, setIsFetchingSetupData] = useState(false);
    const [templateType, setTemplateType] = useState('Me');
    const [template, setTemplate] = useState<MessageTemplate | null>(null);
    const [showValidation1, setShowValidation1] = useState(false);
    const [showValidationMessageInfo, setShowValidationMessageInfo] = useState(false);
    const [showValidationContacts, setShowValidationContacts] = useState(false);
    const [isSendCandidateConfirmationEnabled , setIsSendCandidateConfirmationEnabled] = useState(false);
    const [submissionDate, setSubmissionDate] = useState<moment.Moment | null>(null);
    const [followUpDate, setFollowUpDate] = useState<moment.Moment | null>(null);
    const [createdSubmissionId, setCreatedSubmissionId] = useState(0);
    const [savedChanges, setSavedChanges] = useState(false);
    const [linkedJob, setLinkedJob] = useState<Job>();
    const editorRef = useRef<tinymce.Editor | null>(null);
    const theme = useTheme();
    const [contacts, setContacts] = useState<AttendeeWithEmail[]>([]);
    const [candidates, setCandidates] = useState<UiCandidateData[]>([]);
    const [candidatesPreview, setCandidatesPreview] = useState<NameIdObj[]>([]);
    const [singleCandidateApplicationId, setSingleCandidateApplicationId] = useState(0);
    const [selectedCandidateIndex, setSelectedCandidateIndex] = useState(-1);
    const [showPreviewDialog, setShowPreviewDialog] = useState(false);
    const isDarkTheme = useMemo(() => theme.palette.mode === 'dark', [theme.palette.mode]);
    const [showPlaceholders, setShowPlaceholders] = useState(false);
    const [showCvWarningDialog, setShowCvWarningDialog] = useState(false);
    const [placeholders, setPlaceholders] = useState<TemplatePlaceholder[]>([]);
    const [candidateMapAvailableCompItems, setCandidateMapAvailableCompItems] = useState<Record<number, NameIdObj[]>>({});
    const { state, init, change, updateInitial, hasChanges } = useObjectStateWithChangeTracker<UiSubmissionEdit>(DefaultSubmissionData, NoChangesSubmissionData);

    useEffect(() => {
        const getPlaceholders = async () => {
            const res = await GetPlaceholdersByEntityList([submissionEntityType, contactEntityType]);
            if (res) setPlaceholders(res);
        };
        getPlaceholders();
    }, []);
    
    useEffect(() => {
        const getSetupData = async () => {
            setIsFetchingSetupData(true);
            let tmp = {...DefaultSubmissionData};
            setSubmissionDate(moment());
            const me = await GetMyUser();
            if (me) {
                tmp.assignedTo = me.userID;
                tmp.followUpAssignedTo = me.userID;
            }
            const s1 = await GetSettingBySettingName('DefaultDistributionMethod_Submissions');
            if (s1 && !isNaN(+s1)) tmp.distributionModeId = +s1;
            const s2 = await GetCustomerSettingBySettingName('CandidateSubmissionConfirmationType');
            if (s2) {
                if (s2 === '0') tmp.sendCandidateConfirmaiton = false;
                if (s2 === '1') {
                    tmp.sendCandidateConfirmaiton = true;
                    setIsSendCandidateConfirmationEnabled(true);
                }
                if (s2 === '2') tmp.sendCandidateConfirmaiton = true;
            }
            if (jobIdProp) {
                const j = await GetJobById(jobIdProp);
                if (j) {
                    setLinkedJob(j);
                    tmp.jobId = j.id;
                    tmp.jobTitle = j.title;

                    const res = await GetContactAttendeeData([j.contact1ID, j.contact2ID]);
                    if (res) {
                        let a: AttendeeWithEmail[] = res.map(v => ({ id: v.attendeeID, name: v.name, email: v.email }));
                        setContacts(a);
                        tmp.contactsJson = JSON.stringify(a.map(v => v.id));
                    }
                    
                    if (candidateIds && candidateIds.length === 1) {
                        const cj = await GetCandidateJob(candidateIds[0], jobIdProp, errorHandler);
                        if (cj) setSingleCandidateApplicationId(cj.applicationID);
                    }
                }
            }
            else if (contactIds && contactIds.length > 0) {
                const res = await GetContactAttendeeData(contactIds, errorHandler);
                if (res && res.length > 0) {
                    let a: AttendeeWithEmail[] = res.map(v => ({ id: v.attendeeID, name: v.name, email: v.email }));
                    setContacts(a);
                    tmp.contactsJson = JSON.stringify(a.map(v => v.id));
                }
            }
            if (candidateIds && candidateIds.length > 0) {
                const res = await GetCandidateAttendeeData(candidateIds, errorHandler);
                if (res && res.length > 0) {
                    let c: UiCandidateData[] = res.map(v => ({
                        cvId: 0,
                        id: v.attendeeID, name: v.name, email: v.email, hasResume: v.hasResume,
                        availability: '', candidateRate: '', clientRate: '', comments: '', videoLink: '',
                        files: [],
                        complianceDocuments: []
                     }));
                    setCandidates(c);
                    setCandidatesPreview(res.map(c => ({ id: c.id, name: c.name })));
                    setSelectedCandidateIndex(0);
                }
            }
            setIsFetchingSetupData(false);
            init(tmp);
        };
        getSetupData();
    }, [jobIdProp, candidateIds, contactIds, init, errorHandler]);

    const stringChangesCallback = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const {value, name} = e.target;
        change(name as keyof UiSubmissionEdit, value);
    }, [change]);

    const numberChangesCallback = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const {value, name} = e.target;
        change(name as keyof UiSubmissionEdit, +value);
    }, [change]);

    const isRecordOnly = useMemo(() => state.distributionModeId === 0, [state.distributionModeId]);

    const isStepMessageInfoError = useMemo(() => {
        return !isRecordOnly && !Boolean(state.subject);
    }, [isRecordOnly, state.subject]);

    const saveHandler = useCallback(async () => {

        if (!isRecordOnly) {
            setShowValidationMessageInfo(true);
            if (isStepMessageInfoError) {
                errorHandler && errorHandler("Subject can't be empty");
                return false;
            }
            else {
                const api = editorRef.current;
                if (api) {
                    const content = api.getContent();
                    if (!Boolean(content)) {
                        errorHandler && errorHandler("Body can't be empty");
                        return false;
                    }
                    else {
                        const hasUrlPlaceholder = content.includes('#{Submission.PortalUrl}')
                        if (!hasUrlPlaceholder && state.distributionModeId === 1) {
                            errorHandler && errorHandler("Body must contain the #{Submission.PortalUrl} placeholder");
                            return false;
                        }
                        else errorHandler && errorHandler("");
                    }
                }
            }
        }

        loadingHandler && loadingHandler(true);
        const editorApi = editorRef.current;
        const body = editorApi ? editorApi.getContent() : '';
        const submissionDateString = submissionDate ? submissionDate.format('YYYY-MM-DDTHH:mm') : '';
        const followUpDateString = followUpDate ? followUpDate.format('YYYY-MM-DDTHH:mm') : undefined;
        const res = await CreateSubmission({
            assignedToId: state.assignedTo,
            body: body,
            distributionModeID: state.distributionModeId,
            submissionDate: submissionDateString,
            jobId: state.jobId,
            jobTitle: state.jobTitle,
            sendCandidateConfirmation: state.sendCandidateConfirmaiton,
            subject: state.subject,
            candidateData: candidates,
            contacts: contacts.map(v => v.id),
            followUpAssignedToId: state.followUpAssignedTo,
            followUpDate: followUpDateString
        }, errorHandler);
        loadingHandler && loadingHandler(false);

        if(!Boolean(res)) {
            return false;
        }

        updateInitial();
        setIsEditorDirty(false);
        if (editorApi) editorApi.setDirty(false);
        if (res) setCreatedSubmissionId(res.value);
        setSavedChanges(true);
        return true;

    }, [candidates, isRecordOnly, loadingHandler, submissionDate, followUpDate, state.assignedTo, state.distributionModeId, state.jobId, state.jobTitle, state.sendCandidateConfirmaiton, state.subject, state.followUpAssignedTo, contacts, errorHandler, updateInitial, isStepMessageInfoError]);

    const templateChangeHandlerCallback = useCallback((t: MessageTemplate | null) => {
        if (t) {
            setTemplate(t);
            change('subject', t.subject);
            editorRef.current?.setContent(t.body);
        }
        else setTemplate(null);
    }, [change]);

    useEffect(() => {
        loadingHandler && loadingHandler(isFetchingSetupData);
    }, [isFetchingSetupData, loadingHandler]);

    const isActiveStepLast = useMemo(() => {
        return activeStep === (isRecordOnly ? 1 : 2) + candidates.length;
    }, [activeStep, candidates.length, isRecordOnly]);

    const isActiveStepCandidate = useMemo(() => {
        return activeStep > 1 && activeStep <= 1 + candidates.length;
    }, [activeStep, candidates.length]);

    const isStep1Error = useMemo(() => {
        if (isRecordOnly && !submissionDate) return true;
        if (!Boolean(state.jobTitle) || !Boolean(state.assignedTo)) return true;
        return false;
    }, [isRecordOnly, submissionDate, state.jobTitle, state.assignedTo]);

    const isStepContactsError = useMemo(() => {
        if (contacts.length > 0) {
            if (isRecordOnly) return false;
            const noEmailContact = contacts.find(c => !Boolean(c.email));
            if (noEmailContact) return true;
            else return false;
        }
        return true;
    }, [contacts, isRecordOnly]);

    const isCandidateStepError = useMemo(() => {
        if (activeStep < 2 || isRecordOnly) return false;
        const c = candidates[selectedCandidateIndex];
        if (!isRecordOnly && c && c.cvId !== 0) return false;
        return true;
    }, [activeStep, candidates, isRecordOnly, selectedCandidateIndex]);

    const selectedCandidateId = useMemo(() => {
        const c = candidates[selectedCandidateIndex];
        if (c) return c.id;
        return 0;
    }, [candidates, selectedCandidateIndex]);

    // const selectedCandidateComplianceDocs = useMemo(() => {
    //     const c = candidates[selectedCandidateIndex];
    //     if (c) return c.complianceDocuments.map(v => v.id);
    //     return [];
    // }, [candidates, selectedCandidateIndex]);

    const selectedCandidateAvailableComplianceDocs = useMemo(() => {
        return candidateMapAvailableCompItems[selectedCandidateId];
    }, [candidateMapAvailableCompItems, selectedCandidateId]);

    // const selectedCandidateComplianceItems =

    useEffect(() => {
        const getComplianceDocuments = async () => {
            loadingHandler && loadingHandler(true);
            const res = await GetComplianceData(selectedCandidateId, 0, 0, errorHandler);
            if (res) {
                const availableDocuments = res.filter(v => v.documentID > 0);
                setCandidateMapAvailableCompItems(prev => {
                    let tmp = {...prev};
                    tmp[selectedCandidateId] = availableDocuments.map(d => ({ id: d.documentID, name: d.name }));
                    return tmp;
                });
            }
            loadingHandler && loadingHandler(false);
        };
        selectedCandidateId && isActiveStepCandidate && !Boolean(selectedCandidateAvailableComplianceDocs) &&  getComplianceDocuments();
    }, [errorHandler, selectedCandidateAvailableComplianceDocs, isActiveStepCandidate, loadingHandler, selectedCandidateId]);

    const allCvSelected = useMemo(() => {
        for (let i = 0; i < candidates.length; i++) {
            const c = candidates[i];
            if (!Boolean(c.cvId)) return false;
        }
        return true;
    }, [candidates]);

    const nextStepHandler = useCallback(() => {
        let canContinue = true;
        if (activeStep === 0) {
            setShowValidation1(true);
            if (isStep1Error) canContinue = false;
        }
        else if (activeStep === 1) {
            setShowValidationContacts(true);
            if (isStepContactsError) canContinue = false;
            else setSelectedCandidateIndex(0);
        }
        else if (activeStep >= 2 && activeStep < (2 + candidates.length) && isCandidateStepError) {
            warningHandler && warningHandler('No CV was selected');
        }
        if (canContinue) {
            errorHandler && errorHandler('');
            setActiveStep(prev => prev +1);
            if (activeStep > 1) setSelectedCandidateIndex(prev => {
                if (prev + 1 === candidates.length) return prev;
                return prev + 1;
            });
        }
    }, [activeStep, candidates.length, isCandidateStepError, isStep1Error, isStepContactsError, warningHandler, errorHandler]);

    const prevStepHandler = useCallback(() => {
        if (isActiveStepCandidate) setSelectedCandidateIndex(prev => prev > 0 ? prev -1 : prev);
        setActiveStep(prev => prev -1);
    }, [isActiveStepCandidate]);

    useEffect(() => {
        const actionFunction = allCvSelected ? saveHandler : () => setShowCvWarningDialog(true);
        const action = (
            <>
                <Button variant="contained" disabled={activeStep === 0} onClick={ prevStepHandler } sx={{ mr: '5px' }} startIcon={<KeyboardArrowLeftIcon />}>Prev</Button>
                { !isActiveStepLast && <Button variant="contained" onClick={ nextStepHandler } endIcon={<KeyboardArrowRightIcon />}>Next</Button> }
                { isActiveStepLast && <Button variant="contained" /* disabled={isStep1Error || isStep2Error || !completeHasChanges} */ onClick={ actionFunction } color="success" endIcon={<KeyboardArrowRightIcon />}>Save</Button> }
            </>
        );

        if (setSummaryBar) {
            const title = linkedJob ? renderSummary(linkedJob) : "Submissions > Create";
            const sb = <TitleAndActionSummaryBar title={title} browserTabTitle="Create > Submissions" action={action} />;
            setSummaryBar(sb);
        }
        
    }, [hasChanges, isEditorDirty, activeStep, isActiveStepLast, isStep1Error, isStepMessageInfoError, nextStepHandler, prevStepHandler, saveHandler, setSummaryBar, linkedJob, allCvSelected]);

    const addContactHandler = useCallback((c: Contact | null) => {
        if (c) {
            setContacts(prev => {
                const existing = prev.find(v => v.id === c.id);
                if (existing) {
                    errorHandler && errorHandler('Contact Already Added');
                    return prev;
                }
                const tmp = [...prev, { id: c.id, name: c.fullName, email: c.email }];
                change('contactsJson', JSON.stringify(tmp.map(v => v.id)));
                return tmp;
            });
        }
    }, [errorHandler, change]);

    const removeContactHandler = useCallback((index: number) => {
        setContacts(prev => {
            let tmp = [...prev];
            tmp.splice(index, 1);
            change('contactsJson', JSON.stringify(tmp.map(v => v.id)));
            return tmp;
        });
    }, [change]);
    
    const contactsListElement = useMemo(() => {
        return contacts.map((c, i) => {
            const hasEmail = Boolean(c.email);
            return (
                <React.Fragment key={i}>
                    <ListItem disablePadding secondaryAction={ <IconButton edge="end" onClick={ () => removeContactHandler(i) }><DeleteIcon /></IconButton> }>
                        <ListItemButton dense>
                            <ListItemText sx={{ color: t => hasEmail ? undefined : t.palette.error.main }} primary={c.name} secondary={hasEmail ? '' : 'Has No Email'} />
                        </ListItemButton>
                    </ListItem>
                </React.Fragment>
            )
        });
    }, [removeContactHandler, contacts]);

    const handleCandidateChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setCandidates(prev => {
            let tmp = [...prev];
            (tmp[selectedCandidateIndex] as any)[name] = value;
            return tmp;
        });
    }, [selectedCandidateIndex]);

    const handleCandidateCVChange = useCallback((d: Document | null) => {
        setCandidates(prev => {
            let tmp = [...prev];
            tmp[selectedCandidateIndex].cvId = d ? d.id : 0;
            return tmp;
        });
    }, [selectedCandidateIndex]);

    const handleCandidateComplianceChange = useCallback((e: any, vals: NameIdObj[]) => {
        setCandidates(prev => {
            let tmp = [...prev];
            tmp[selectedCandidateIndex].complianceDocuments = vals;
            return tmp;
        });
    }, [selectedCandidateIndex]);

    const handleCandidateRemove = useCallback(() => {
        setCandidates(prev => {
            let tmp = [...prev];
            const isLastSelected = selectedCandidateIndex + 1 === tmp.length;
            tmp.splice(selectedCandidateIndex, 1);
            if (isLastSelected) {
                setSelectedCandidateIndex(prev => prev - 1);
                setActiveStep(prev => prev -1);
            }
            return tmp;
        });
    }, [selectedCandidateIndex]);

    const addCandidateFileHandler = useCallback((files: File[]) => {
        setCandidates(prev => {
            let tmp = [...prev];
            const t = tmp[selectedCandidateIndex];
            t.files = [...t.files, ...files];
            return tmp;
        });
    }, [selectedCandidateIndex]);

    const removeCandidateFileHandler = useCallback((index: number) => {
        setCandidates(prev => {
            let tmp = [...prev];
            const t = tmp[selectedCandidateIndex];
            let tmpf = [...t.files];
            tmpf.splice(index, 1);
            t.files = [...tmpf];
            return tmp;
        });
    }, [selectedCandidateIndex]);

    const redirectSubmissionId = useMemo(() => {
        if (savedChanges && createdSubmissionId) return createdSubmissionId;
    }, [createdSubmissionId, savedChanges]);

    const previewBody = useMemo(() => {
        const api = editorRef.current;
        if (showPreviewDialog && api) return api.getContent();
        return ''; 
    }, [showPreviewDialog]);

    const insertPlaceholderHandler = useCallback((placeholder: string) => {
        const api = editorRef.current;
        if (api) api.insertContent(placeholder);
    }, []);

    return (
        <>
            { redirectSubmissionId !== 0 && savedChanges && <Navigate to={`/submissions/${redirectSubmissionId}`} /> }
            <SubmissionPreviewDialog
                open={showPreviewDialog}
                candidateData={candidates}
                submissionData={state}
                submissionDate={submissionDate}
                candidates={candidatesPreview}
                body={previewBody}
                closeHandler={() => setShowPreviewDialog(false)}
                isDarkTheme={isDarkTheme}
                contactAttendeesData={contacts}
                loadingHandler={loadingHandler}
            />
            <InsertPlaceholderDialog
                open={showPlaceholders}
                closeHandler={() => setShowPlaceholders(false)}
                placeholders={placeholders}
                insertHandler={insertPlaceholderHandler}
            />
            <ConfirmationDialog
                open={showCvWarningDialog}
                message="Not all candidate(s) have a CV selected. Would you like to continue?"
                title="Warning"
                onClose={() => setShowCvWarningDialog(false)}
                onContinue={saveHandler}
            />
            <Stepper nonLinear activeStep={activeStep}>
                <Step><StepLabel error={showValidation1 && isStep1Error}>{ jobIdProp === 0 ? 'Float Info' : 'Submission Info'}</StepLabel></Step>
                <Step><StepLabel error={showValidationContacts && isStepContactsError}>Contacts</StepLabel></Step>
                {candidates.map((c, i) => <Step key={c.id}><StepLabel>{c.name}</StepLabel></Step> )}
                { !isRecordOnly && <Step><StepLabel error={showValidationMessageInfo && isStepMessageInfoError}>Message Info</StepLabel></Step> }
            </Stepper>
            <Box mt="20px" display={activeStep === 0 ? undefined : 'none'}>
                <Stack spacing={2}>
                    <TextField select label="Distribution" value={state.distributionModeId.toString()} name="distributionModeId" onChange={numberChangesCallback}>
                        <MenuItem value="3">Email To Client</MenuItem>
                        <MenuItem value="2">Email To Consultant</MenuItem>
                        <MenuItem value="1">Online Portal</MenuItem>
                        <MenuItem value="0">None - Record In Recruit Wizard Only</MenuItem>
                    </TextField>
                    { isRecordOnly && <DateTimePicker label="Submission Date" value={submissionDate} onChange={setSubmissionDate} disableFuture slotProps={{ textField: { error: showValidation1 && !submissionDate }, actionBar: { actions: ["clear", "today", "cancel", "accept"] } }} />}
                    <RWTextFieldComponent 
                        label="Job Title"
                        value={state.jobTitle}
                        name="jobTitle"
                        onChange={stringChangesCallback} 
                        isError={showValidation1 && !Boolean(state.jobTitle)} />
                    <UserPicker label="Assigned To" onSelect={u => change('assignedTo', u ? u.id : 0)} userId={state.assignedTo} errorMessage={showValidation1 && !state.assignedTo ? 'Required' : ''} />
                    <TextField select label="Send Candidate Confirmation" value={state.sendCandidateConfirmaiton ? '1' : '0'} name="sendCandidateConfirmaiton" onChange={({target}) => change('sendCandidateConfirmaiton', target.value === '1')} disabled={!isSendCandidateConfirmationEnabled}>
                        <MenuItem value="0">No</MenuItem>
                        <MenuItem value="1">Yes</MenuItem>
                    </TextField>
                    <DateTimePicker label="Follow up Date" value={followUpDate} onChange={setFollowUpDate} disablePast slotProps={{ textField: { error: showValidation1 && !submissionDate }, actionBar: { actions: ["clear", "today", "cancel", "accept"] } }} />
                    <UserPicker label="Follow up Assigned To" onSelect={u => change('followUpAssignedTo', u ? u.id : 0)} userId={state.followUpAssignedTo} />
                </Stack>
            </Box>
            <Box mt="20px" display={activeStep === 1 ? undefined : 'none'}>
                <Box display="flex" mt="10px">
                    <Box flex="1 1 0" ml="10px">
                        <Typography variant="h6" mb="10px" textAlign="center">Contacts</Typography>
                        <ContactPicker label="Add Contact" disableQuickCreate value={null} onSelectCallback={addContactHandler} blurOnSelect error={showValidationMessageInfo && isStepMessageInfoError} />
                        <List>{contactsListElement}</List>
                    </Box>
                </Box>
            </Box>
            <Box mt="20px" display={isActiveStepCandidate ? undefined : 'none'}>
                {selectedCandidateIndex >= 0 && candidates.length > 0 &&
                    <Stack spacing={2}>
                        {candidates.length === 1 &&
                            <TextField label="Candidate" fullWidth value={candidates[selectedCandidateIndex].name} disabled />
                        }
                        {candidates.length > 1 &&
                            <Box display="flex">
                                <TextField disabled select label="Candidate" fullWidth value={selectedCandidateIndex.toString()} onChange={({target}) => setSelectedCandidateIndex(+target.value)}>
                                    {candidates.map((c, i) => 
                                        <MenuItem key={c.id} value={i.toString()}>{c.name}</MenuItem>
                                    )}
                                </TextField>
                                <IconButton edge="end" onClick={ handleCandidateRemove }><DeleteIcon /></IconButton>
                            </Box>
                        }
                        <DocumentPicker
                            label="CV"
                            value={candidates[selectedCandidateIndex].cvId}
                            lookupType="submission"
                            sourceId={candidates[selectedCandidateIndex].id}
                            jobId={jobIdProp}
                            applicationId={singleCandidateApplicationId}
                            appendToStart={candidates[selectedCandidateIndex].hasResume ? [originalCvOption] : undefined}
                            onSelectCallback={handleCandidateCVChange}
                            loadingHandler={loadingHandler}
                            successHandler={successHandler}
                            errorHandler={errorHandler}
                        />
                        <RWTextFieldComponent 
                            label="Client Rate / Salary" 
                            name="clientRate"
                            onChange={handleCandidateChange}
                            value={candidates[selectedCandidateIndex].clientRate.toString()}
                        />
                        <RWTextFieldComponent 
                            label="Candidate Rate / Salary" 
                            name="candidateRate"
                            onChange={handleCandidateChange}
                            value={candidates[selectedCandidateIndex].candidateRate.toString()}
                        />
                         <RWTextFieldComponent 
                            label="Availability" 
                            name="availability"
                            onChange={handleCandidateChange}
                            value={candidates[selectedCandidateIndex].availability.toString()}
                        />
                        <RWTextFieldComponent 
                            label="Comments" 
                            name="comments"
                            onChange={handleCandidateChange}
                            value={candidates[selectedCandidateIndex].comments.toString()} 
                            multiline={true} 
                            rows={5}
                        />
                        <RWTextFieldComponent 
                            label="Video Link" 
                            name="videoLink"
                            onChange={handleCandidateChange}
                            value={candidates[selectedCandidateIndex].videoLink.toString()}
                        />
                        {isActiveStepCandidate &&
                            <Autocomplete
                                value={candidates[selectedCandidateIndex].complianceDocuments}
                                multiple
                                size="small"
                                disableCloseOnSelect
                                options={selectedCandidateAvailableComplianceDocs ?? []}
                                getOptionLabel={o => o.name}
                                onChange={handleCandidateComplianceChange}
                                renderOption={(props, option, {selected}) => (
                                    <li {...props}>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option.name}
                                    </li>
                                )}
                                renderInput={(params) => (
                                    <TextField {...params} label="Compliance Documents" inputProps={{ ...params.inputProps, "data-lpignore": "true" }} />
                                )}
                            />
                        }
                        <FilePicker
                            files={candidates[selectedCandidateIndex].files}
                            addFilesHandler={ addCandidateFileHandler }
                            removeFileHandler={ removeCandidateFileHandler }
                        />
                    </Stack>
                }
            </Box>
            <Box mt="20px" display={isActiveStepLast && !isRecordOnly ? undefined : 'none'}>
                <Stack spacing={2}>
                    <Box display="flex" gap="5px">
                        <TextField
                            select
                            fullWidth
                            label="Template Source"
                            value={templateType}
                            onChange={ ({target}) => setTemplateType(target.value) }
                            sx={{ flex: '1 1 0' }}
                        >
                            <MenuItem value="Me">Me</MenuItem>
                            <MenuItem value="Team">My Team</MenuItem>
                            <MenuItem value="All">My Company</MenuItem>
                        </TextField>
                        <Box flex="1 1 0">
                            <MessageTemplatePicker
                                templateId={template ? template.id : null}
                                includeCompanyOwned
                                typeFilter={jobIdProp === 0 ? 8 : 7}
                                templateSource={templateType}
                                entityTypeFilter={2}
                                onSelectHandler={ templateChangeHandlerCallback }
                            />
                        </Box>
                        <Button variant="contained" onClick={() => setShowPreviewDialog(true)}>Preview</Button>
                    </Box>
                    <RWTextFieldComponent 
                        label="Subject"
                        value={state.subject}
                        name="subject"
                        onChange={stringChangesCallback}
                        isError={showValidationMessageInfo && !isRecordOnly && !Boolean(state.subject)} />
                    <Editor
                        tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce-5.10.2/tinymce.min.js'}
                        onInit={ (evt, editor) => editorRef.current = editor }
                        // initialValue={defaultEmailContent}
                        onDirty={ () => setIsEditorDirty(true) }
                        init={{
                            height: 350,
                            skin: isDarkTheme ? 'oxide-dark' : undefined,
                            content_css: isDarkTheme ? 'dark' : undefined,
                            branding: false,
                            menubar: false,
                            statusbar: false,
                            contextmenu: false,
                            resize: false,
                            browser_spellcheck: true,
                            plugins: 'powerpaste code link emoticons table print preview visualchars lists fullscreen',
                            powerpaste_word_import: 'prompt',
                            powerpaste_html_import: 'prompt',
                            powerpaste_allow_local_images: true,
                            fontsize_formats: "8pt 9pt 10pt 12pt 14pt 18pt 24pt 36pt",
                            toolbar1: 'placeholders fontsizeselect fontselect styleselect forecolor backcolor bold italic alignleft aligncenter alignright alignjustify bullist numlist code fullscreen link mybutton table',
                            font_formats: 'Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Calibri=calibri; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Segoe=segoe,segoe ui,dejavu sans,trebuchet ms,verdana,sans-serif; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva',
                            setup: editor => {
                                editor.ui.registry.addButton('placeholders', {
                                    text: 'Insert Placeholder',
                                    onAction: () => setShowPlaceholders(true)
                                });
                            }
                        }}
                    />
                </Stack>
            </Box>
        </>
    );
}
