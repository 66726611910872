import Alert from "components/Alert";
import Snackbar from "@mui/material/Snackbar";
import { GridColDef, GridRenderCellParams, GridRowParams, GridRowSelectionModel, useGridApiRef } from "@mui/x-data-grid-premium";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Permission } from "common/models/Permissions";
import GridWithStateWrapper from "components/GridWidthStateWrapper";
import OnlyTitleSummaryBar from "components/SummaryBars/OnlyTitleSummaryBar";
import PageContentLayout from "layouts/PageContentLayout";
import PageLayout from "layouts/PageLayout";
import { userHasSinglePermission } from "util/PermissionsUtils";
import Box from "@mui/material/Box";
import { ReferenceCheckRequest } from "common/models/ReferenceChecks/ReferenceCheckRequest";
import { GetCurrentCustomerReferenceCheckRequests_All, GetCurrentCustomerReferenceCheckRequests_Incomplete, GetMyReferenceCheckRequests_All, GetMyReferenceCheckRequests_Incomplete } from "services/ReferenceCheckService";
import ReferenceCheckDialog from "components/Dialogs/ReferenceChecks/ReferenceCheckDialog";

interface Props {
    showOnlyIncomplete?: boolean
}

const spanLinkStyle: React.CSSProperties = { cursor: 'pointer', textDecoration: 'underline' };

export default function ReferenceCheckRequestsPage({ showOnlyIncomplete }: Props) {
    const [summaryBar, setSummaryBar] = useState<JSX.Element>(<></>);
    const [isLoading, setIsLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [rows, setRows] = useState<ReferenceCheckRequest[]>([]);
    const [selectedRow, setSelectedRow] = useState<ReferenceCheckRequest | null>(null);
    const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
    const apiRef = useGridApiRef();

    const isTemplatesAdmin = useMemo(() => userHasSinglePermission(Permission.TemplatesAdministrator), []);

    useEffect(() => {
        const summaryBar = <OnlyTitleSummaryBar title={`Account > Reference Check Requests > ${ showOnlyIncomplete ? 'Incomplete' : 'All' }`} />;
        setSummaryBar(summaryBar);
    }, [showOnlyIncomplete]);

    const getRows = useCallback(async () => {
        setIsLoading(true);
        let res: ReferenceCheckRequest[] | null = [];
    
        if (showOnlyIncomplete) {
            if (isTemplatesAdmin) {
                res = await GetCurrentCustomerReferenceCheckRequests_Incomplete(setErrorMessage);
            }
            else {
                res = await GetMyReferenceCheckRequests_Incomplete(setErrorMessage);
            }
        }
        else {
            if (isTemplatesAdmin) {
                res = await GetCurrentCustomerReferenceCheckRequests_All(setErrorMessage);
            }
            else {
                res = await GetMyReferenceCheckRequests_All(setErrorMessage);
            }
        }
    
        if (res) setRows(res);
        setIsLoading(false);
    }, [showOnlyIncomplete, isTemplatesAdmin]);

    useEffect(() => {
        getRows()
    }, [getRows]);

    const columns = useMemo<GridColDef[]>(() => {

        const referenceCheckRenderer = (params: GridRenderCellParams) => {
            return (
                <span style={spanLinkStyle} onClick={ () => setSelectedRow(params.row) }>{params.value}</span>
            );
        };

        return [
            { field: 'id', headerName: 'Request ID', width: 120 },
            { field: 'jobTitle', headerName: 'Job Title', width: 250 },
            { field: 'client', headerName: 'Client', width: 150 },
            { field: 'candidate', headerName: 'Candidate', width: 150 },
            { field: 'status', headerName: 'Status', width: 200 },
            { field: 'requestDate', headerName: 'Request Date', width: 150 },
            { field: 'requestedBy', headerName: 'Requested By', width: 200 },
            { field: 'noOfReferenceChecks', headerName: 'No of references', width: 150, renderCell: referenceCheckRenderer  }
        ];
    }, []);

    const isRowSelectableCallback = useCallback((p: GridRowParams) => {
        return p.row.statusID === 1 || p.row.statusID === 2;
    }, []);

    return (
        <PageLayout SummaryBar={summaryBar}>
            {selectedRow &&
                <ReferenceCheckDialog
                    open={true}
                    requestId={selectedRow.id}
                    loadingHandler={setIsLoading}
                    onClose={() => setSelectedRow(null)}
                />
            }
            <Snackbar
                open={successMessage.length > 0}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={3000}
                onClose={() => setSuccessMessage('')}
            >
                <Alert onClose={() => setSuccessMessage('')}>{ successMessage }</Alert>
            </Snackbar>
            <Snackbar
                open={errorMessage !== ''}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <PageContentLayout title={showOnlyIncomplete ? 'Reference Check Requests - Incomplete' : 'Reference Check Requests'} showLoading={isLoading}>
                <Box>
                    <Box pb="10px" sx={{ float: 'right' }}></Box>
                </Box>
                <GridWithStateWrapper
                    gridName="account/reference-check-requests"
                    apiRef={apiRef}
                    rows={rows}
                    columns={columns}
                    checkboxSelection
                    disableRowSelectionOnClick
                    rowSelectionModel={selectionModel}
                    onRowSelectionModelChange={ sm => setSelectionModel(sm) }
                    isRowSelectable={ isRowSelectableCallback}
                    pagination
                    density="compact"
                    pageSizeOptions={[100,250,500,1000]}
                />
            </PageContentLayout>
        </PageLayout>
    );
}