import { GridColDef, GridColumnVisibilityModel, GridRenderCellParams, GridRowSelectionModel, GridValueGetter, useGridApiRef } from "@mui/x-data-grid-premium";
import moment from "moment";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { ActivityMessage } from "common/models/Activities";
import { GetClientMessages } from "services/ClientsService";
import { GetContactMessages } from "services/ContactsService";
import { GetCandidateMessages } from "services/CandidatesService";
import { GetJobMessages } from "services/JobsService";
import { GetPlacementMessages } from "services/PlacementsService";
import GridWithStateWrapper from "../GridWidthStateWrapper";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import Chip from "@mui/material/Chip";
import PreviewLoaderComponent from "../Previews/PreviewLoader";
import { PreviewEntityType } from "common/models/Previews/Previews";
import { companyHasSinglePermission, userHasSinglePermission } from "util/PermissionsUtils";
import { Permission } from "common/models/Permissions";
import ActionsDropDownButton from "../SummaryBars/Actions/ActionsDropsDownMenu";
import MenuItem from "@mui/material/MenuItem";
import { DeleteActivities } from "services/ActivitiesService";
import ConfirmationDialog from "../Dialogs/Generic/ConfirmationDialog";
import Box from "@mui/material/Box";
import { defaultGridCellStyle } from "util/GridUtils";

interface Props {
    source: 'client-record' | 'contact-record' | 'candidate-record' | 'job-record' | 'placement-record',
    sourceId?: number,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
    successHandler?: (message: string) => void,
    gridName: string
}

const linkStyle: React.CSSProperties = { color: 'inherit', textDecoration: 'underline' };

const defaultHiddenCols: GridColumnVisibilityModel = {
    "activityID": false,
};

export default function ActivityMessagesGridComponent({ source, sourceId, loadingHandler, errorHandler, successHandler, gridName }: Props) {
    const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [rows, setRows] = useState<ActivityMessage[]>([]);
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    const [previewType, setPreviewType] = useState<PreviewEntityType | ''>('');
    const [previewRecordId, setPreviewRecordId] = useState(0);
    const apiRef = useGridApiRef();

    const getData = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        let res: ActivityMessage[] | null = [];
        if (source === 'client-record' && sourceId) res = await GetClientMessages(sourceId, errorHandler);
        if (source === 'contact-record' && sourceId) res = await GetContactMessages(sourceId, errorHandler);
        if (source === 'candidate-record' && sourceId) res = await GetCandidateMessages(sourceId, errorHandler);
        if (source === 'job-record' && sourceId) res = await GetJobMessages(sourceId, errorHandler);
        if (source === 'placement-record' && sourceId) res = await GetPlacementMessages(sourceId, errorHandler);
        if (res) setRows(res);
        loadingHandler && loadingHandler(false);
    }, [source, sourceId, loadingHandler, errorHandler]);

    useEffect(() => {
        getData();
    }, [getData]);

    const columns = useMemo<GridColDef[]>(() => {
        const handlePreviewHover = (type: PreviewEntityType | '', id: number) => {
            setPreviewType(type);
            setPreviewRecordId(id);
            setIsPreviewOpen(true);
        }

        const handlePreviewClose = () => {
            // setPreviewType('');
            // setPreviewRecordId(0);
            setIsPreviewOpen(false);
        }

        const dateValueGetter: GridValueGetter<ActivityMessage, any, undefined, string> = (value, row) => {
            if (value) {
                const d = moment(value);
                if (d.isValid() && d.get('year') > 1) {
                    return d.toDate();
                }
            }
        };

        const dateRenderer = (params: GridRenderCellParams) => {
            if (params.value) {
                const activityType = params.row.typeID;
                if (activityType === 2 || activityType === 5) return (
                    <div style={defaultGridCellStyle}>
                        <Link target="_blank" to={`/messages/${params.id}`} style={linkStyle}>{moment(params.value).format('DD MMM YYYY h:mm A')}</Link>
                    </div>
                );
                return (
                    <div style={defaultGridCellStyle}>
                        <span
                            style={{ cursor: 'pointer', textDecoration: 'underline' }}
                            onMouseEnter={() => handlePreviewHover('activity', +params.id)}
                            onMouseLeave={handlePreviewClose}
                        >{moment(params.value).format('DD MMM YYYY h:mm A')}</span>
                    </div>
                );
            }
            return <div style={defaultGridCellStyle}>{params.value}</div>;
        };

        const minHeightCellRenderer = (params: GridRenderCellParams) => {
            return (
                <div style={defaultGridCellStyle}>
                    <span>{params.value}</span>
                </div>
            );
        };

        const linkToMessageRenderer = (params: GridRenderCellParams) => {
            if (params.value) {
                const activityType = params.row.typeID;
                if (activityType === 2 || activityType === 5) return (
                    <div style={defaultGridCellStyle}>
                        <Link target="_blank" to={`/messages/${params.id}`} style={ linkStyle }>{params.value}</Link>
                    </div>
                );
                return (
                    <div style={defaultGridCellStyle}>
                        <span
                            style={{ cursor: 'pointer', textDecoration: 'underline' }}
                            onMouseEnter={ () => handlePreviewHover('activity', +params.id) }
                            onMouseLeave={ handlePreviewClose }
                        >{params.value}</span>
                    </div>
                );
            }
            return <div style={defaultGridCellStyle}>{params.value}</div>;
        };

        const attachmentsRenderer = (params: GridRenderCellParams) => {
            if (params.value > 0) {
                return (
                    <div style={defaultGridCellStyle}>
                        <Link to={`/messages/${params.id}`} target="_blank">
                            <IconButton size="small" sx={{ mt: '5px', mr: '5px' }}>
                                <Badge badgeContent={params.value} max={9} color="secondary" overlap="circular">
                                    <AttachFileIcon />
                                </Badge>
                            </IconButton>
                        </Link>
                    </div>
                );
            }

            return <></>;
        };

        const toRenderer = (params: GridRenderCellParams) => {
            if (params.value) {
                const recipients = params.value as string;
                const list = recipients.split(";");
                const elements = list.map((v, i) => v ? <Chip key={i} size="small" label={v} sx={{ mt: '5px', mr: '5px' }} /> : <React.Fragment key={i} />);
                return <Box mb="5px" lineHeight="41px">{elements}</Box>;
            }
        };

        return [
            { headerName: 'ID', field: 'activityID', width: 75, renderCell: minHeightCellRenderer },
            { headerName: 'Subject', field: 'subject', width: 300, renderCell: linkToMessageRenderer },
            { headerName: '', field: 'attachments', type: 'number', width: 30, renderCell: attachmentsRenderer },
            { headerName: 'From', field: 'from', width: 375, renderCell: minHeightCellRenderer },
            { headerName: 'To', field: 'to', width: 500, renderCell: toRenderer },
            { headerName: 'Date', field: 'activityDate', headerAlign: 'center', align: 'center', width: 180, type: 'date', valueGetter: dateValueGetter, renderCell: dateRenderer },
            { headerName: 'Type', field: 'type', headerAlign: 'center', align: 'center', width: 125, renderCell: linkToMessageRenderer, filtertype: 'checkedlist' },
            { headerName: 'Assigned To', field: 'assignedToName', headerAlign: 'center', align: 'center', width: 150, renderCell: minHeightCellRenderer }
        ];
    }, []);

    const canDeleteMessages = useMemo(() => companyHasSinglePermission(Permission.ActivitiesDelete) && userHasSinglePermission(Permission.ActivitiesDelete), []);

    const deleteSelectedHandler = useCallback(async () => {
        if (selectionModel.length > 0) {
            loadingHandler && loadingHandler(true);
            const res = await DeleteActivities(selectionModel as number[], errorHandler);
            if (res) {
                setRows(prev => {
                    let tmp = [...prev];
                    for (let i = 0; i < selectionModel.length; i++) {
                        const id = +selectionModel[i];
                        const index = tmp.findIndex(a => a.activityID === id);
                        if (id !== -1) tmp.splice(index, 1);
                    }
                    setSelectionModel([]);
                    return tmp;
                });
                successHandler && successHandler('Messages Deleted');
                setShowDeleteConfirmation(false);
            }
            loadingHandler && loadingHandler(false);
        }
    }, [selectionModel, loadingHandler, successHandler, errorHandler]);

    const gridActions = useMemo(() => {
        if (canDeleteMessages) {
            return (
                <ActionsDropDownButton color="secondary" label="List Actions">
                    <MenuItem disabled={ selectionModel.length === 0 } onClick={() => setShowDeleteConfirmation(true)}>Delete</MenuItem>
                </ActionsDropDownButton>
            );
        }
        return undefined;
    }, [selectionModel.length, canDeleteMessages]);

    return (
        <>
            <PreviewLoaderComponent
                open={isPreviewOpen}
                entityType={previewType}
                recordId={previewRecordId}
            />
            <ConfirmationDialog
                open={showDeleteConfirmation}
                title="Confirm Action"
                message={`Are you sure you want to delete the ${selectionModel.length} selected messages?`}
                onClose={() => setShowDeleteConfirmation(false)}
                onContinue={deleteSelectedHandler}
            />
            {Boolean(gridActions) &&
                <Box pb="10px" ml="auto">
                    {gridActions}
                </Box>
            }
            <GridWithStateWrapper
                getRowId={ row => row.activityID }
                gridName={gridName}
                defaultViewModel={defaultHiddenCols}
                rows={rows}
                columns={columns}
                apiRef={apiRef}
                rowSelectionModel={selectionModel}
                onRowSelectionModelChange={ sm => setSelectionModel(sm) }
                density="compact"
                disableRowSelectionOnClick
                checkboxSelection
                pagination
                pageSizeOptions={[100,250,500,1000]}
                getRowHeight={() => 'auto'}
            />
        </>
    );
}