import { ErrorHandler, NumberValueWrapper, RequestOptions } from "../common/models/GenericTypes";
import { Opportunity, OpportunityOwner } from "../common/models/Opportunities";
import { Get, Delete, Post, Put } from "../util/HttpUtils";
import { Report } from "../common/models/Reports/Report";
import { OpportunitySearch } from "../common/models/Search/OpportunitySearch";
import { PartnerAction } from "common/models/PartnerActions";
import {Meeting} from "../common/models/Meetings";

export async function GetOpportunityById(id: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Opportunity>(`api/opportunities/${id}`, options);
}

export async function OpportunityLookup(term: string, limit: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return await Get<Opportunity[]>(`api/opportunities/Lookup?query=${encodeURIComponent(term)}&limit=${limit}`, options);
}

export async function SearchOpportunities(data: OpportunitySearch, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<Opportunity[]>('api/opportunities/search', options, data);
}

export async function GetMyOpenOpportunities(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Opportunity[]>('api/opportunities/me/open', options);
}

export async function GetReports(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Report[]>('api/opportunities/reports', options);
}

export async function GetOpportunitiesByTags(tagTypeId: number, taggedBy: number, tag: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Opportunity[]>(`api/opportunities/tags/${tagTypeId}?taggedBy=${taggedBy}&tag=${encodeURIComponent(tag)}`, options);
}

export async function GetMyOpportunities(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Opportunity[]>(`api/opportunities/me`, options);
}

export async function GetOpportunitiesDetailedReportData(owner: number, type: number, status: number, stage: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<any>(`api/opportunities/reports/detailed?owner=${owner}&type=${type}&status=${status}&rating=${stage}`, options);
}

export async function CreateOpportunity(opportunityData: Partial<Opportunity>, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<NumberValueWrapper>('api/opportunities', options, opportunityData);
}

export async function UpdateOpportunity(opportunityData: Partial<Opportunity>, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>('api/opportunities', options, opportunityData);
}

export async function UpdateOpportunityCustomField(opportunityId: number, fieldNumber: number, value: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/opportunities/${opportunityId}/custom-fields`, options, { fieldNumber, value });
}

export async function DeleteOpportunityById(opportunityId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Delete<boolean>(`api/opportunities/${opportunityId}`, options);
}

export async function GetOpportunityMeetings(opportunityId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Meeting[]>(`api/opportunities/${opportunityId}/meetings`, options);
}

export async function GetOpportunityOwners(opportunityId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<OpportunityOwner[]>(`api/opportunities/${opportunityId}/Owners`, options);
}

export async function SetOpportunityOwners(opportunityId: number, ownerIds: number[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/opportunities/${opportunityId}/Owners`, options, ownerIds);
}

export async function CloseOpportunity(opportunityId: number, reason: number, comments: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/opportunities/${opportunityId}/Close?reasonId=${reason}&comments=${encodeURIComponent(comments)}`, options);
}

export async function ConvertOpportunityToJob(opportunityId: number, jobType: 'contract' | 'perm' | 'fixedTerm', shouldCopyDocuments: boolean, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<NumberValueWrapper>(`api/opportunities/${opportunityId}/convert/job/${jobType}?copyDocs=${shouldCopyDocuments}`, options);
}

export async function GetOpportunityPartnerActions(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<PartnerAction[]>('api/opportunities/partneractions', options);
}