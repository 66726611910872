import React, { useCallback, useEffect, useMemo, useState } from "react";

import MenuItem from '@mui/material/MenuItem';
import QuestionMark from '@mui/icons-material/QuestionMark';
import IconButton from '@mui/material/IconButton';
import MenuList from "@mui/material/MenuList";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Stack from "@mui/material/Stack";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import ServerInfoDialog from "../Dialogs/Help/ServerInfoDialog";
import Box from "@mui/material/Box";
import Popper from "@mui/material/Popper";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";

const KeyStyled = ({ content, ignoreMarginRight = false }: { content: string, ignoreMarginRight?: boolean }) => (
    <Box
        display="inline-block"
        py="0.2em"
        px="0.5em"
        mr={ ignoreMarginRight ? undefined : "1em" }
        borderRadius="3px"
        border="1px solid"
    >{content}</Box>
);

const LinkStyle: React.CSSProperties = { color: 'inherit', textDecoration: 'none' };

export default function WizTopBarHelpMenu() {
    const [anchor, setAnchor] = useState<HTMLElement | null>(null);
    const [isRootHovered, setIsRootHovered] = useState(false);
    const [isRootPaperHovered, setIsRootPaperHovered] = useState(false);
    const [showShortcutsDialog, setShowShortcutsDialog] = useState(false);
    const [showServerInfoDialog, setShowServerInfoDialog] = useState(false);

    const handleOpenMenu = useCallback((e: React.MouseEvent<HTMLElement>) => {
        setIsRootHovered(true);
        setAnchor(e.currentTarget);
    }, []);

    const handleCloseMenu = useCallback(() => setAnchor(null), []);

    useEffect(() => {
        if (Boolean(anchor) && !isRootHovered && !isRootPaperHovered) setAnchor(null);
    }, [anchor, isRootHovered, isRootPaperHovered]);

    const isEdge = useMemo(() => {
        const userAgent = navigator.userAgent.toLowerCase();
        const index = userAgent.indexOf('edg/');
        return index !== -1;
    }, []);

    const handleOpenShortcutsDialog = useCallback(() => {
        setShowShortcutsDialog(true);
        setAnchor(null);
    }, [])

    const handleOpenServerInfoDialog = useCallback(() => {
        setShowServerInfoDialog(true);
        setAnchor(null);
    }, []);
    
    return (
        <>
            <IconButton
                size="large"
                color="inherit"
                onClick={handleOpenMenu}
                onMouseEnter={handleOpenMenu}
                onMouseLeave={ () => setIsRootHovered(false) }
                sx={{ p: 0 }}
            >
                <QuestionMark />
            </IconButton>
            <Popper
                disablePortal
                anchorEl={anchor}
                open={Boolean(anchor)}
                placement="bottom-end"
                sx={{ zIndex: t => t.zIndex.appBar + 2 }}
                onMouseEnter={ () => setIsRootPaperHovered(true) }
                onMouseLeave={ () => setIsRootPaperHovered(false) }
            >
                <ClickAwayListener onClickAway={ handleCloseMenu }>
                    <Paper elevation={8} onMouseLeave={ handleCloseMenu }>
                        <MenuList>
                            <a rel="noopener noreferrer" href="Tel:+61280718750" style={LinkStyle} onClick={handleCloseMenu}>
                                <MenuItem>Call us? +61 2 8071 8750</MenuItem>
                            </a>
                            <a target="_blank" rel="noopener noreferrer" href="mailto:support@recruitwizard.com" style={LinkStyle} onClick={handleCloseMenu}>
                                <MenuItem>Create a Ticket</MenuItem>
                            </a>
                            <a target="_blank" rel="noopener noreferrer" href="/api-docs" style={LinkStyle} onClick={handleCloseMenu}>
                                <MenuItem>API Documentation</MenuItem>
                            </a>
                            <a target="_blank" rel="noopener noreferrer" href="https://cdn.recruitwizard.com/docs/booleansearchguide.pdf" style={LinkStyle} onClick={handleCloseMenu}>
                                <MenuItem>Download Boolean Search Guide</MenuItem>
                            </a>
                            <a target="_blank" rel="noopener noreferrer" href={isEdge ? "https://g.recruitwizard.com/EdgeExtension" : "https://g.recruitwizard.com/ChromeExtension"} style={LinkStyle} onClick={handleCloseMenu} >
                                <MenuItem>Download {isEdge ? 'Edge' : 'Chrome'} Extension</MenuItem>
                            </a>
                            <a target="_blank" rel="noopener noreferrer" href="https://g.recruitwizard.com/RemoteSupport" style={LinkStyle} onClick={handleCloseMenu}>
                                <MenuItem>Download Remote Support Tool</MenuItem>
                            </a>
                            <MenuItem onClick={handleOpenShortcutsDialog}>Keyboard Shortcuts</MenuItem>
                            <MenuItem onClick={handleOpenServerInfoDialog}>Server Info</MenuItem>
                            <a target="_blank" rel="noopener noreferrer" href="https://status.recruitwizard.com" style={LinkStyle} onClick={handleCloseMenu}>
                                <MenuItem>System Status</MenuItem>
                            </a>
                        </MenuList>
                    </Paper>
                </ClickAwayListener>
            </Popper>
            <Dialog open={showShortcutsDialog} fullWidth>
                <DialogTitle>Keyboard Shortcuts</DialogTitle>
                <DialogContent>
                    <Stack spacing={2}>
                        <Divider>Global</Divider>
                        <div><KeyStyled content="/" />: Perform a Lookup</div>
                        <div><KeyStyled content="ctrl" ignoreMarginRight /> {' + '} <KeyStyled content="i" />: Show Server Info</div>
                        <div></div>
                        <Divider>Within module record</Divider>
                        <div><KeyStyled content="n" />: Open Create Note Dialog</div>
                        <div><KeyStyled content="e" />: Open Edit Page</div>
                        <div><KeyStyled content="t" />: Open Tag Admin Dialog</div>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="success" onClick={() => setShowShortcutsDialog(false)}>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
            <ServerInfoDialog open={showServerInfoDialog} closeHandler={() => setShowServerInfoDialog(false)} />
        </>
    );
}

