import React, { useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {DownloadDocument} from "../../services/DocumentsService";

export default function DownloadDocumentPage() {
    const params = useParams();
    const navigate = useNavigate();

    const documentId = useMemo(() => {
        const parsedId = +(params.id ?? '0');
        if (isNaN(parsedId)) return 0;
        return parsedId;
    }, [params.id]);

    useEffect(() => {
        const getData = async () => {
            await DownloadDocument(documentId, undefined);
            navigate('/');
        };
        getData();
    }, [documentId, navigate]);

    

    return (
        <>
            
        </>
    );
}