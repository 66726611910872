import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import TextField from "@mui/material/TextField";
import React, { useMemo, useState } from "react";
import { CustomField } from "common/models/Configuration/CustomFields";
import { CustomFieldType } from "common/models/ScreenLayouts/CustomFields";
import { PlacementRecordDashboardElementType } from "common/models/Dashboard/EditLayout";

interface Props {
    open: boolean,
    activeFields: CustomField[],
    alreadyAddedElements: string[],
    closeHandler: () => void,
    addElementHandler: (type: PlacementRecordDashboardElementType) => void
}

interface AddOption {
    value: PlacementRecordDashboardElementType,
    label: string,
    secondaryLabel?: string
}

const availableElements: AddOption[] = [
    { value: 'PlacementId', label: 'Id' },
    { value: 'PlacementJobId', label: 'Job ID' },
    { value: 'PlacementType', label: 'Type' },
    { value: 'PlacementJobTitle', label: 'Job Title' },
    { value: 'PlacementCandidate', label: 'Candidate' },
    { value: 'PlacementStartDate', label: 'Start Date' },
    { value: 'PlacementEndDate', label: 'End Date' },
    { value: 'PlacementDivision', label: 'Division' },
    { value: 'PlacementPayDivision', label: 'Pay Division' },
    { value: 'PlacementCurrency', label: 'Currency' },
    { value: 'PlacementSalaryPackage', label: 'Salary Package' },
    { value: 'PlacementInvoiceFee', label: 'Invoice Fee' },
    { value: 'PlacementRatesCalculator', label: 'Rates Calculator Link' },
    { value: 'PlacementChargeRate', label: 'Charge Rate' },
    { value: 'PlacementPayRate', label: 'Pay Rate' },
    { value: 'PlacementOnCost', label: 'On Cost' },
    { value: 'PlacementMargin', label: 'Margin' },
    { value: 'PlacementHoursPerDay', label: 'Hours Per Day' },
    { value: 'PlacementDaysPerWeek', label: 'Days Per Week' },
    { value: 'PlacementComments', label: 'Comments' },
    { value: 'PlacementPaymentType', label: 'Payment Type' },
    { value: 'PlacementPayCompany', label: 'Pay Company' },
    { value: 'PlacementPayContact', label: 'Pay Contact' },
    { value: 'PlacementWarranty', label: 'Warranty' },
    { value: 'PlacementLocation', label: 'Location' },
    { value: 'PlacementClient', label: 'Client' },
    { value: 'PlacementContact', label: 'Contact' },
    { value: 'PlacementHiringManager', label: 'Hiring Manager' },
    { value: 'PlacementBilling1', label: 'Billing 1' },
    { value: 'PlacementBilling2', label: 'Billing 2' },
    { value: 'PlacementBilling3', label: 'Billing 3' },
    { value: 'PlacementSignatory', label: 'Signatory' },
    { value: 'PlacementClientRef', label: 'Client Ref' },
    { value: 'PlacementApprover1', label: 'Approver 1' },
    { value: 'PlacementApprover2', label: 'Approver 2' },
    { value: 'PlacementSummary', label: 'Summary' },
    { value: 'PlacementStatus', label: 'Status' },
    { value: 'PlacementConsultant1', label: 'Consultant 1' },
    { value: 'PlacementConsultant2', label: 'Consultant 2' },
    { value: 'PlacementConsultant3', label: 'Consultant 3' },
    { value: 'PlacementConsultant4', label: 'Consultant 4' },
    { value: 'PlacementConsultant5', label: 'Consultant 5' },
    { value: 'PlacementSource', label: 'Source' },
    { value: 'PlacementNetRevenue', label: 'Net Revenue' },
    { value: 'PlacementDate', label: 'Placement Date' },
    { value: 'PlacementProbation', label: 'Probation' },
    { value: 'PlacementNotice', label: 'Notice' },
    { value: 'PlacementPayFreq', label: 'Pay Frequency' },
    { value: 'PlacementComplianceChecklist', label: 'Compliance Checklist' },
    { value: 'Divider', label: 'Divider' },
    { value: 'Spacer', label: 'Spacer' },
];

const getCustomFieldName = (name: string) => {
    if (!name.startsWith('Custom Field ')) return '';
    return 'CustomField' + name.substring(13);
};

export default function AddPlacementDashboardElementDialog({ open, activeFields, alreadyAddedElements, closeHandler, addElementHandler }: Props) {
    const [elementType, setElementType] = useState<PlacementRecordDashboardElementType>('');

    const options = useMemo<AddOption[]>(() => {
        let o: AddOption[] = [];

        for (let i = 0; i < availableElements.length; i++) {
            const element = availableElements[i];
            const isAlreadyAdded = alreadyAddedElements.includes(element.value) && element.value !== 'Divider' && element.value !== 'Spacer';
            if (!isAlreadyAdded) o.push(element);
        }

        for (let i = 0; i < activeFields.length; i++) {
            const udf = activeFields[i];
            const name = getCustomFieldName(udf.name);
            const isAlreadyAdded = alreadyAddedElements.includes(name);
            if (name && !isAlreadyAdded) o.push({ value: name as CustomFieldType, label: udf.agencyName, secondaryLabel: udf.name });
        }

        o.sort((a, b) => {
            if (b.label < a.label) return 1;
            if (b.label > a.label) return -1;
            return 0;
        });

        return o;
    }, [activeFields, alreadyAddedElements]);

    const selectedElementType = useMemo(() => {
        if (elementType === '') return null;
        const type = options.find( e => e.value === elementType );
        return type ?? null;
    }, [elementType, options]);

    return (
        <Dialog open={open} fullWidth>
            <DialogTitle>Add new element</DialogTitle>
            <DialogContent>
                <Box pt={1}>
                    <Autocomplete size="small"
                        value={selectedElementType}
                        options={options}
                        onChange={ (e, v) => setElementType(v ? v.value : '') }
                        renderInput={ (params) => (
                            <TextField
                                {...params}
                                label="Element Type"
                                inputProps={{ ...params.inputProps, "data-lpignore": "true" }}
                        />)}
                        renderOption={(props, option) => (
                            <ListItem {...props}><ListItemText primary={option.label} secondary={option.secondaryLabel} /></ListItem>
                        )}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="error" onClick={ closeHandler }>Cancel</Button>
                <Button variant="contained" color="success" disabled={elementType === ''} onClick={ () => addElementHandler(elementType) }>Add</Button>
            </DialogActions>
        </Dialog>
    );
}