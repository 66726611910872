import React from "react";

import WizTopBar from "../components/WizTopBar/WizTopBar";
import { Box } from "@mui/material";


export default function MainLayout(props: React.PropsWithChildren<any>) {
    return (
        <>
            <WizTopBar />
            <Box
                sx={{ bgcolor: 'background.default', flexBasis: '100%', display: 'flex', flexDirection: 'column', overflow: 'auto' }}
            >
                {props.children}
            </Box>
        </>
    );
}