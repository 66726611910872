import { Document } from "../common/models/Document";
import { ErrorHandler, RequestOptions } from "../common/models/GenericTypes";
import { DocumentReportData } from "../common/models/Reports/Documents";
import { DownloadBlob } from "../util/FilesUtils";
import { Delete, DownloadFile, Get, PostFile, Put} from "../util/HttpUtils";

export async function DownloadDocument(documentId: number, fileName?: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    const file = await DownloadFile(`api/documents/${documentId}/download`, options);
    if (file) {
        DownloadBlob(file.data, fileName ? fileName + file.fileExtension : file.fileName);
    }
}

export async function DeleteDocuments(documentIds: number[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Delete('api/documents/delete/bulk', options, documentIds);
}

export async function EditDocument(documentId: number, data: Document, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put(`api/documents/${documentId}`, options, data);
}

export async function GetDocumentsDetailedReportData(entityTypeId: number, consultantId: number, documentType: number, fromDate: string, toDate: string, dateType: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<DocumentReportData[]>(`api/documents/reports/detailed?entityType=${entityTypeId}&consultant=${consultantId}&type=${documentType}&from=${encodeURIComponent(fromDate)}&to=${encodeURIComponent(toDate)}&dateType=${dateType}`, options);
}

export async function GetDocuments(entityId: number, playerId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Document[]>(`api/documents/${entityId}/${playerId}`, options);
}

export async function AddDocuments(entityId: number, playerId: number, documentType: number, files: File[], expirationDate: string = '', errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
        const f = files[i];
        formData.append('files', f);
    }

    return PostFile<boolean>(`api/documents/${entityId}/${playerId}/${documentType}?expire=${encodeURIComponent(expirationDate)}`, formData, options);
}

export async function DocumentLookup(sourceId: number, lookupType: 'submission' | 'ref-check' | 'job-order', term: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    let lType = 0;
    if (lookupType === 'submission') lType = 1;
    else if (lookupType === 'ref-check') lType = 2;
    else if (lookupType === 'job-order') lType = 3;

    return await Get<Document[]>(`api/documents/Lookup/${lType}?query=${encodeURIComponent(term)}&sourceId=${sourceId}`, options);
}

export async function GetDocumentById(documentId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Document>(`api/documents/${documentId}`, options);
}