import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { CopyAdvert } from "services/AdvertsService";
import { GetAdvertTemplatesVisibility } from "services/UsersService";
import AdvertTemplatePicker from "components/Pickers/AdvertTemplatePicker";
import UserPicker from "components/Pickers/UserPicker";
import { NameIdObj } from "common/models/GenericTypes";

interface Props {
    open: boolean,
    jobId: number,
    closeHandler: () => void,
    onSuccessHandler: (newAdvertId: number) => void,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
}

const ownerMeOption: NameIdObj = { id: 0, name: 'Me' };

const ownerSpecialOptions: NameIdObj[] = [
    ownerMeOption,
    { id: -1, name: 'My Team' },
    { id: -2, name: 'My Company' },
];

export default function CreateAdvertDialog({ open, jobId, closeHandler, loadingHandler, errorHandler, onSuccessHandler }: Props) {
    const [isAllTemplatesVisible, setIsAllTemplatesVisible] = useState(false);
    const [ownerId, setOwnerId] = useState<number | null>(0);
    const [templateId, setTemplateId] = useState<number | null>(0);

    useEffect(() => {
        const getVisibilitySetting = async () => {
            const res = await GetAdvertTemplatesVisibility();
            if (res && res.value === 'true') setIsAllTemplatesVisible(true);
        };

        getVisibilitySetting();
    }, []);

    const onSubmitCallback = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        if (templateId !== null) {
            const res = await CopyAdvert(templateId, jobId, errorHandler);
            if (res) {
                onSuccessHandler(res.value);
            }
        }
        loadingHandler && loadingHandler(false);
    }, [loadingHandler, templateId, jobId, onSuccessHandler, errorHandler]);

    const ownerOptions = useMemo<NameIdObj[]>(() => {
        if (isAllTemplatesVisible) return ownerSpecialOptions;
        return [ownerMeOption];
    }, [isAllTemplatesVisible]);
    
    return (
        <Dialog open={open} fullWidth>
            <DialogTitle>Create Advert</DialogTitle>
            <DialogContent>
                <Stack pt="10px" spacing={2}>
                    <UserPicker
                        disableClearable
                        label="Owner"
                        userId={ownerId}
                        appendToStart={ownerOptions}
                        onSelect={ u => u && setOwnerId(u.id) }
                    />
                    <AdvertTemplatePicker
                        onSelect={ t => t && setTemplateId(t.advertID)}
                        templateId={templateId}
                        disableClearable
                        includeDefaultOption
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="error" onClick={ closeHandler }>Cancel</Button>
                <Button variant="contained" color="success" onClick={ onSubmitCallback }>Create</Button>
            </DialogActions>
        </Dialog>
    );
}