import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import DocumentTemplatePicker from "components/Pickers/DocumentTemplatePicker";
import { DocumentTemplate } from "common/models/Templates/DocumentTemplate";
import { GetDocumentTemplatePlaceholdersById, GetDocumentTemplatesPlaceholders } from "services/TemplatesService";
import { TemplatePlaceholder } from "common/models/Templates/TemplatePlaceholder";
import { GenerateEntityDocumentFromTemplate, GetEntityDocumentPlaceholderValues } from "services/DocumentGenerationService";
import TextField from "@mui/material/TextField";
import { DownloadDocument } from "services/DocumentsService";
import { GenerateEsignRequest, GetSignersDisplayData } from "services/ESignService";
import { SignersStatus } from "common/models/ESigning";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";

interface Props {
    open: boolean,
    entityId: number,
    playerId: number,
    placementDocType?: 'candidate' | 'client' | '',
    isRefCheck?: boolean,
    closeHandler: () => void,
    onDocumentCreatedHandler?: (docId: number) => void,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
    successHandler?: (message: string) => void,
}

interface PlaceholderField {
    placeholder: string,
    placeholderUsage: string,
    friendlyName: string,
    isEditable: boolean,
    value: string
}

export default function GenerateDocumentDialog({ open, entityId, playerId, placementDocType = '', isRefCheck, closeHandler: closeHandlerProp, onDocumentCreatedHandler, loadingHandler, successHandler, errorHandler }: Props) {
    const [step, setStep] = useState(1);
    const [selectedTemplate, setSelectedTemplate] = useState<DocumentTemplate | null>(null);
    const [fields, setFields] = useState<PlaceholderField[]>([]);
    const [allAvailablePlaceholders, setAllAvailablePlaceholders] = useState<TemplatePlaceholder[]>([]);
    const [generatedDocumentId, setGeneratedDocumentId] = useState(0);
    const [signersDisplayData, setSignersDisplayData] = useState<SignersStatus[]>([]);
    const [areAllSignersOk, setAreAllSignersOk] = useState(false);
    const [isESignTest, setIsESignTest] = useState(false);
    const [showValidation, setShowValidation] = useState(false);

    const templateTypeFilters = useMemo<number[]>(() => {
        if (entityId === 1) return [4, 6];
        else if (entityId === 2) return [4, 7];
        else if (entityId === 3 && isRefCheck) return [5];
        else if (entityId === 3) return [4, 8];
        else if (entityId === 4) return [4, 9];
        else if (entityId === 8) return [4, 10];
        else if ((entityId === 5 || entityId === 17) && placementDocType === 'candidate') return [4, 3];
        else if ((entityId === 5 || entityId === 17) && placementDocType === 'client') return [4, 2];
        return [];
    }, [entityId, isRefCheck, placementDocType]);

    useEffect(() => {
        const getData = async () => {
            const res = await GetDocumentTemplatesPlaceholders();
            if (res) setAllAvailablePlaceholders(res);
        };
        getData();
    }, []);

    useEffect(() => {
        if (open) {
            setStep(1);
            setSelectedTemplate(null);
            setFields([]);
            setGeneratedDocumentId(0);
            setSignersDisplayData([]);
            setAreAllSignersOk(false);
            setIsESignTest(false);
            setShowValidation(false);
        }
    }, [open]);

    const closeHandler = useCallback(() => {
        errorHandler && errorHandler('');
        closeHandlerProp();
    }, [closeHandlerProp, errorHandler]);

    const getPlaceholders = useCallback(async () => {
        if (selectedTemplate) {
            loadingHandler && loadingHandler(true);
            const values = await GetEntityDocumentPlaceholderValues(entityId, playerId, errorHandler);
            const placeholders = await GetDocumentTemplatePlaceholdersById(selectedTemplate.id);

            let f: PlaceholderField[] = [];
            if (values && placeholders) {
                for (let i = 0; i < placeholders.length; i++) {
                    const minP = placeholders[i];
                    const val = values.find(v => v.placeholder === minP.placeholder);
                    if (val) f.push({ ...val, isEditable: minP.isEditable });
                    else {
                        const availableP = allAvailablePlaceholders.find(p => p.placeholder === minP.placeholder);
                        if (availableP) f.push({...availableP, value: '', isEditable: minP.isEditable});
                    }
                }
            }

            setFields(f);
            setStep(prev => prev + 1);
            loadingHandler && loadingHandler(false);
        }
    }, [entityId, playerId, selectedTemplate, allAvailablePlaceholders, loadingHandler, errorHandler]);

    const generateDocument = useCallback(async () => {
        if (selectedTemplate) {
            const emptyField = fields.find(f => f.value === null || !Boolean(f.value.trim()));
            if (emptyField) {
                setShowValidation(true);
                errorHandler && errorHandler('All fields must have values');
                return;
            }

            loadingHandler && loadingHandler(true);
            const placeholders = fields.map(f => ({ placeholder: f.placeholder, value: f.value ?? '' }));
            const res = await GenerateEntityDocumentFromTemplate(entityId, playerId, selectedTemplate.id, placeholders, errorHandler);
            if (res) {
                successHandler && successHandler('Document Generated');
                setGeneratedDocumentId(res.documentId);
                onDocumentCreatedHandler && onDocumentCreatedHandler(res.documentId);
                setStep(prev => prev + 1);
            }
            loadingHandler && loadingHandler(false);
        }
    }, [selectedTemplate, loadingHandler, fields, entityId, playerId, errorHandler, successHandler, onDocumentCreatedHandler]);

    const sendDocumentForESign = useCallback(async () => {
        if (selectedTemplate) {
            loadingHandler && loadingHandler(true);
            const res = await GenerateEsignRequest(entityId, playerId, selectedTemplate.id, generatedDocumentId, isESignTest, errorHandler);
            if (res) {
                successHandler && successHandler('Document sent for eSigning');
                closeHandler();
            }
            loadingHandler && loadingHandler(false);
        }
    }, [entityId, playerId, selectedTemplate, generatedDocumentId, isESignTest, closeHandler, loadingHandler, successHandler, errorHandler]);

    const isNextEnabled = useMemo(() => {
        if (step === 1 && Boolean(selectedTemplate)) return true;
        if (step === 2) return true;
        if (step === 3 && Boolean(generatedDocumentId)) return true;
        if (step === 4 && areAllSignersOk) return true;
        return false;
    }, [step, selectedTemplate, areAllSignersOk, generatedDocumentId]);

    const dialogTitle = useMemo(() => {
        if (step === 1) return 'Select Template';
        if (step === 2) return 'Enter Field Data';
        if (step === 3) return 'Document Generated';
        if (step === 4) return 'eSign Status';
        return '';
    }, [step]);

    const nextBtnText = useMemo(() => {
        if (step === 2) return 'Create';
        if (step === 3) return 'Finish';
        if (step === 4) return 'Send for eSigning'
        return 'Next';
    }, [step]);

    const nextCallback = useCallback(() => {
        if (step === 1) getPlaceholders();
        else if (step === 2) generateDocument();
        else if (step === 3) closeHandler();
        else if (step === 4) sendDocumentForESign();
    }, [step, getPlaceholders, generateDocument, closeHandler, sendDocumentForESign]);

    const backCallback = useCallback(() => {
        if (step > 1) setStep(prev => prev -1);
    }, [step]);

    const onFieldChange = useCallback((index: number, value: string) => {
        setFields(prev => {
            let tmp = [...prev];
            tmp[index].value = value;
            return tmp;
        })
    }, []);

    const downloadFileCallback = useCallback(async () => {
        if (generatedDocumentId > 0) {
            loadingHandler && loadingHandler(true);
            await DownloadDocument(generatedDocumentId, undefined, errorHandler);
            loadingHandler && loadingHandler(false);
        }
    }, [generatedDocumentId, loadingHandler, errorHandler]);

    const eSignCallback = useCallback(async (isTest: boolean) => {
        if (selectedTemplate && selectedTemplate.isElectronicSigningEnabled) {
            loadingHandler && loadingHandler(true);
            const res = await GetSignersDisplayData(entityId, playerId, selectedTemplate.id, errorHandler);
            if (res) {
                let isAllOk = true;
                const notOkRow = res.find(r => r.status !== 'OK');
                if (notOkRow) isAllOk = false;
                setSignersDisplayData(res);
                setAreAllSignersOk(isAllOk);
                setIsESignTest(isTest);
            }
            loadingHandler && loadingHandler(false);
            setStep(4);
        }
    }, [entityId, playerId, selectedTemplate, errorHandler, loadingHandler]);

    return (
        <Dialog open={open} fullWidth>
            <DialogTitle>{dialogTitle}</DialogTitle>
            <DialogContent>
                <Stack spacing={2} mt="10px" display={step === 1 ? undefined : 'none'}>
                    <DocumentTemplatePicker label="Template" onSelectHandler={t => setSelectedTemplate(t)} typesFilter={templateTypeFilters} statusFilter={1} />
                </Stack>
                <Stack spacing={2} mt="10px" display={step === 2 ? undefined : 'none'}>
                    {fields.map((f, i) => ( 
                        <TextField
                            key={i}
                            label={f.friendlyName}
                            value={f.value}
                            onChange={ ({target}) => onFieldChange(i, target.value) }
                            InputProps={{ readOnly: !f.isEditable }}
                            error={showValidation && (f.value === null || !Boolean(f.value.trim()))}
                        />
                    ))}
                </Stack>
                <Stack spacing={2} mt="10px" display={step === 3 ? undefined : 'none'}>
                    <Button disabled={generatedDocumentId === 0} onClick={downloadFileCallback}>Download File</Button>
                    { selectedTemplate && selectedTemplate.isElectronicSigningEnabled && <Button onClick={() => eSignCallback(false)}>Send for Electronic Signing</Button> }
                    {/*{ selectedTemplate && selectedTemplate.isElectronicSigningEnabled && <Button onClick={() => eSignCallback(true)}>Send for Electronic Signing (TEST)</Button> }*/}
                </Stack>
                <Stack spacing={2} mt="10px" display={step === 4 ? undefined : 'none'}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell>Type</TableCell>
                                <TableCell>Value</TableCell>
                                <TableCell>Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {signersDisplayData.map((s, i) => (
                                <TableRow key={i}>
                                    <TableCell>Signer {i + 1}</TableCell>
                                    <TableCell>{s.type}</TableCell>
                                    <TableCell>{s.value}</TableCell>
                                    <TableCell>{s.status}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="error" onClick={closeHandler} >Cancel</Button>
                <Button variant="contained" disabled={step === 1} onClick={backCallback}>Back</Button>
                <Button variant="contained" color="success" disabled={!isNextEnabled} onClick={nextCallback}>{nextBtnText}</Button>
            </DialogActions>
        </Dialog>
    );
}