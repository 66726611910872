import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { GridColDef, GridRenderCellParams, GridValueGetter, useGridApiRef } from "@mui/x-data-grid-premium";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { TagSettings, TagValue } from "common/models/Configuration/Tags";
import { GetTagsOverview, GetTagsSettings } from "services/TagsService";
import GridWithStateWrapper from "../GridWidthStateWrapper";
import RWTextFieldComponent from "components/RWTextFieldComponent";

interface Props {
    entityType: 'clients' | 'contacts' | 'candidates' | 'jobs' | 'placements' | 'opportunities' | 'sites',
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void
}

export default function TagSearchPageContent({ entityType, loadingHandler, errorHandler }: Props) {
    const [rows, setRows] = useState<TagValue[]>([]);
    const [tagSettings, setTagSettings] = useState<TagSettings[]>([]);
    const [tagType, setTagType] = useState(0);
    const [taggedBy, setTaggedBy] = useState(0);
    const [keywords, setKeywords] = useState('');
    const [showGrid, setShowGrid] = useState(false);
    const apiRef = useGridApiRef();

    const entityTypeId = useMemo(() => {
        if (entityType === 'clients') return 1;
        if (entityType === 'contacts') return 2;
        if (entityType === 'candidates') return 3;
        if (entityType === 'jobs') return 4;
        if (entityType === 'placements') return 5;
        if (entityType === 'opportunities') return 8;
        if (entityType === 'sites') return 9;
        return 0;
    }, [entityType]);

    useEffect(() => {
        const getTagSettingsData = async () => {
            loadingHandler && loadingHandler(true);
            const res = await GetTagsSettings(entityTypeId, true, errorHandler);
            if (res) setTagSettings(res);
            loadingHandler && loadingHandler(false);
        };
        getTagSettingsData();
    }, [entityTypeId, loadingHandler, errorHandler]);

    const tagTypeChangeHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setTagType(+e.target.value);
    }, []);

    const taggedByChangeHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setTaggedBy(+e.target.value);
    }, []);

    const keywordsChangeHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setKeywords(e.target.value);
    }, []);

    const columns = useMemo<GridColDef[]>(() => {
        const dateValueGetter: GridValueGetter<TagValue, any, undefined, string> = (value, row) => {
            if (value) {
                const d = moment(value);
                if (d.isValid() && d.get("year") > 1) {
                    return d.toDate();
                }
            }
            return null;
        };

        const dateRenderer = (params: GridRenderCellParams) => {
            if (params.value) {
                return moment(params.value).format('DD MMM YYYY');
            }
            return 'Never';
        };

        const tagTypeNameValueGetter: GridValueGetter<TagValue, any, undefined, number> = (value, row) => {
            const setting = tagSettings.find(t => t.tagTypeId === value);
            if (setting) return setting.tagAgencyName;
            return value;
        };

        const tagTotalRenderer = (params: GridRenderCellParams) => {
            return (
                <Link
                    style={{ color: 'inherit' }}
                    to={`/${entityType}/tags?tagType=${params.row.tagTypeID}&taggedBy=4&tag=${encodeURIComponent(params.row.tag)}`}
                >{params.value}</Link>
            );
        };

        return [
            { headerName: 'Tag Name', field: 'tag', width: 300, renderCell: tagTotalRenderer },
            { headerName: 'Tag Type', field: 'tagTypeID', width: 300, valueGetter: tagTypeNameValueGetter},
            { headerName: 'Total', field: 'countTotal', headerAlign: 'center', align: 'center', width: 125, renderCell: tagTotalRenderer },
            { headerName: 'Last Tagged', field: 'lastTagged', headerAlign: 'center', align: 'center', width: 100, valueGetter: dateValueGetter, renderCell: dateRenderer, type: 'date' }
        ];
    }, [tagSettings, entityType]);

    const searchHandler = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        const res = await GetTagsOverview(entityTypeId, tagType, taggedBy, keywords, errorHandler);
        if (res) setRows(res);
        setShowGrid(true);
        loadingHandler && loadingHandler(false);
    }, [entityTypeId, tagType, taggedBy, keywords, loadingHandler, errorHandler]);

    const keyUpHandler = useCallback((e: KeyboardEvent) => {
        if (e.code === 'Enter') searchHandler();
    }, [searchHandler]);

    useEffect(() => {
        window.addEventListener('keyup', keyUpHandler);
        return () => window.removeEventListener('keyup', keyUpHandler);
    }, [keyUpHandler]);

    return (
        <>
            <Box display="flex" mb={2}>
                <TextField select value={tagType.toString()} label="Tag Type" onChange={tagTypeChangeHandler} sx={{ mr: '5px', flex: '1 1 0' }}>
                    <MenuItem value="0">All</MenuItem>
                    {tagSettings.map(t => (
                        <MenuItem key={t.id} value={t.tagTypeId}>{t.tagAgencyName}</MenuItem>
                    ))}
                </TextField>
                <TextField select value={taggedBy.toString()} label="Tagged By" onChange={taggedByChangeHandler} sx={{ mr: '5px', flex: '1 1 0' }}>
                    <MenuItem value="0">Everyone</MenuItem>
                    <MenuItem value="1">Me</MenuItem>
                    <MenuItem value="2">My Team</MenuItem>
                </TextField>
                <RWTextFieldComponent 
                    value={keywords} 
                    label="Keywords"
                    onChange={keywordsChangeHandler} 
                    name="keywords"
                    sxOptions={{ mr: '5px', flex: '1 1 0' }}/>
                <Button variant="contained" color="success" onClick={searchHandler}>Search</Button>
            </Box>
            {showGrid &&
                <GridWithStateWrapper
                    gridName={entityType}
                    rows={rows}
                    columns={columns}
                    apiRef={ apiRef }
                    density="compact"
                    disableRowSelectionOnClick
                    pagination
                    getRowId={r => `${r.tagTypeID}-${r.tag}`}
                    pageSizeOptions={[100, 250, 500, 1000]}
                />
            }
        </>
    );
}