import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { GridColDef } from "@mui/x-data-grid-premium";
import { FilterWithTimeRange } from "common/models/Analytics/AnalyticsFilterData";
import { PerformanceFeeByConsultantRecord } from "common/models/Analytics/BusinessIntelligence";
import GridWithStateWrapper from "components/GridWidthStateWrapper";
import React, { useEffect, useMemo, useState } from "react";
import { ResponsiveContainer, BarChart, CartesianGrid, XAxis, YAxis, Legend, Bar, Tooltip } from "recharts";
import { GetTotalFeesByConsultantData } from "services/BusinessIntelligence";
import { TickWithHoverRenderer, VerticalBarChartYAxisWidth } from "util/AnalyticsGraphUtils";

interface Props {
    filterData: FilterWithTimeRange,
    graphType?: 'table' | 'graph',
    loadingHandler?: (isLoading: boolean) => void
}

interface PerformanceFeeByConsultantRecordWithId extends PerformanceFeeByConsultantRecord {
    id: number,
}

const gridName = 'analytics/performance/fees-by-consultant';

export default function PerformanceFeesByConsultantContent({ filterData, graphType, loadingHandler }: Props) {
    const [rows, setRows] = useState<PerformanceFeeByConsultantRecordWithId[]>([]);
    const t = useTheme();

    useEffect(() => {
        const getData = async () => {
            loadingHandler && loadingHandler(true);
            const res = await GetTotalFeesByConsultantData(filterData);
            if (res) {
                const data: PerformanceFeeByConsultantRecordWithId[] = res.map((r, i) => ({ ...r, id: i }));
                setRows(data);
            }
            loadingHandler && loadingHandler(false);
        };
        getData();
    }, [filterData, loadingHandler]);

    const columns = useMemo<GridColDef[]>(() => {
        return [
            { field: 'consultant', headerName: 'Consultant', width: 250 },
            { field: 'contract', headerName: 'Temp Fees', headerAlign: 'center', align: 'right', width: 150 },
            { field: 'perm', headerName: 'Perm Fees', headerAlign: 'center', align: 'right', width: 150 },
            { field: 'total', headerName: 'Total Fees', headerAlign: 'center', align: 'right' },
        ];
    }, []);

    if (graphType === 'table') {
        return (
            <GridWithStateWrapper
                gridName={gridName}
                rows={rows}
                density="compact"
                columns={columns}
                sx={{ mt: '20px' }}
            />
        );
    }
    else if (graphType === 'graph') {
        return (
            <>
                <Typography variant="h6" padding={1} textAlign="center" fontWeight={600} component="div">Fees By Consultant</Typography>
                <div style={{ height: 'calc(100% - 48px)' }}>
                    <ResponsiveContainer width="100%" height="100%">
                        <BarChart width={150} height={150} layout="vertical" data={rows} margin={{ right: 50 }} stackOffset="positive">
                            <CartesianGrid strokeDasharray="3 3" vertical />
                            <XAxis type="number" tickFormatter={v => v.toLocaleString()} stroke={t.palette.text.primary} domain={[0, 'dataMax']} />
                            <YAxis dataKey="consultant" type="category" tick={ TickWithHoverRenderer } stroke={t.palette.text.primary} width={VerticalBarChartYAxisWidth} minTickGap={0} interval={0} />
                            <Legend  />
                            <Tooltip />
                            <Bar name="Permanent" dataKey="perm" stackId="a" fill={t.palette.info.main} />
                            <Bar name="Contract" dataKey="contract" stackId="a" fill={t.palette.error.main} />
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </>
        );
    }

    return <></>;
}