import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import TextField from "@mui/material/TextField";
import React, { useMemo, useState } from "react";
import { MainDashboardElementType } from "common/models/Dashboard/EditLayout";

interface Props {
    open: boolean,
    alreadyAddedElements: string[],
    closeHandler: () => void,
    addElementHandler: (type: MainDashboardElementType) => void
}

interface AddOption {
    value: MainDashboardElementType,
    label: string,
    secondaryLabel?: string
}

const availableElements: AddOption[] = [
    { value: 'MyActiveInterviews', label: 'Active Interviews'},
    { value: 'BillingsThisMonth', label: 'Billing This Month'},
    { value: 'BillingsThisQtr', label: 'Billing This Qtr'},
    { value: 'BillingsThisYear', label: 'Billing This Year'},
    { value: 'CurrentContractors', label: 'Current Contractors'},
    { value: 'ForecastNextMonth', label: 'Forecast Next Month'},
    { value: 'ForecastThisMonth', label: 'Forecast This Month'},
    { value: 'HistoricalBillings', label: 'Historical Billings'},
    { value: 'MyOpenJobs', label: 'Open Jobs'},
    { value: 'UserInfo', label: 'User Info'},
    { value: 'LinkButton', label: 'Link Button'},
    { value: 'Activity', label: 'Activity'},
    { value: 'JobPipeline', label: 'Job Pipeline'},
    { value: 'CandidatePipeline', label: 'Candidate Pipeline'},
    { value: 'PlacementFees', label: 'Placement Fees'},
    { value: 'KPI', label: 'KPI'},
    { value: 'Ratio', label: 'Ratio'},
];

export default function AddDashboardElementDialog({ open, alreadyAddedElements, closeHandler, addElementHandler }: Props) {
    const [elementType, setElementType] = useState<MainDashboardElementType>('');

    const options = useMemo<AddOption[]>(() => {
        let o: AddOption[] = [];
        for (let i = 0; i < availableElements.length; i++) {
            const element = availableElements[i];
            const isAlreadyAdded = alreadyAddedElements.includes(element.value) && element.value !== 'LinkButton' && element.value !== 'KPI' && element.value !== 'Ratio';
            if (!isAlreadyAdded) o.push(element);
        }

        o.sort((a, b) => {
            if (b.label < a.label) return 1;
            if (b.label > a.label) return -1;
            return 0;
        });

        return o;
    }, [alreadyAddedElements]);

    const selectedElementType = useMemo(() => {
        if (elementType === '') return null;
        const type = options.find( e => e.value === elementType );
        return type ?? null;
    }, [elementType, options]);

    return (
        <Dialog open={open} fullWidth>
            <DialogTitle>Add new element</DialogTitle>
            <DialogContent>
                <Box pt={1}>
                    <Autocomplete size="small"
                        value={selectedElementType}
                        options={options}
                        onChange={ (e, v) => setElementType(v ? v.value : '') }
                        renderInput={ (params) => (
                            <TextField
                                {...params}
                                label="Element Type"
                                inputProps={{ ...params.inputProps, "data-lpignore": "true" }}
                        />)}
                        renderOption={(props, option) => (
                            <ListItem {...props}><ListItemText primary={option.label} secondary={option.secondaryLabel} /></ListItem>
                        )}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="error" onClick={ closeHandler }>Cancel</Button>
                <Button variant="contained" color="success" disabled={elementType === ""} onClick={ () => addElementHandler(elementType) } >Add</Button>
            </DialogActions>
        </Dialog>
    );
}