import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { SxProps } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import moment from "moment";
import * as XLSX from "xlsx";
import React, { useCallback, useEffect, useState } from "react";
import { GetCandidatesNewReportData } from "services/CandidatesService";
import { DatePicker } from "@mui/x-date-pickers-pro";
import { ReportDataProcessDateFields } from "util/ReportUtils";

interface Props {
    entityTypeId: number,
    description: string,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
}

const filterElementStyle: SxProps = { flex: '1 1 0', mr: 1 };

export default function NewCandidatesReport({ entityTypeId, description, loadingHandler, errorHandler }: Props) {

    const [sinceDate, setSinceDate] = useState<moment.Moment | null>(null);

    useEffect(() => {
        setSinceDate(moment().startOf('month'));
    }, []);

    const getDataCallback = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        const stringSinceDate = sinceDate && sinceDate.isValid() ? sinceDate.format('YYYY-MM-DD') : '';
        const res = await GetCandidatesNewReportData(stringSinceDate, errorHandler);
        if (res) {
            ReportDataProcessDateFields(res as any[]);

            const m = moment();
            const filename = `CandidatesNew${m.format('YYYYMMDDhhmmss')}.xlsx`;
            const ws = XLSX.utils.json_to_sheet(res);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws);
            XLSX.writeFile(wb, filename);
        }
        loadingHandler && loadingHandler(false);
    }, [sinceDate, errorHandler, loadingHandler]);

    return (
        <>
            <Box display="flex">
                <DatePicker label="Added Since" value={sinceDate} onChange={m => setSinceDate(m)} sx={filterElementStyle} slotProps={{actionBar: { actions: ["clear", "today", "cancel", "accept"] }}} />
                <Button variant="contained" color="success" onClick={getDataCallback}>Run Report</Button>
            </Box>
            <Typography component="div" variant="h6" mt={2}>Report Description</Typography>
            <Typography component="div" variant="body2" mb={2}>{description}</Typography>

        </>
    );
}