import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import React, { useCallback, useEffect, useState } from "react";
import { GetCustomerSettingBySettingName } from "services/ConfigurationService";
import DialogContentText from "@mui/material/DialogContentText";
import { MessageTemplate } from "common/models/Templates/MessageTemplate";
import MessageTemplatePicker from "components/Pickers/MessageTemplatePicker";
import ActionsSplitButton from "components/SummaryBars/Actions/ActionsSplitButton";
import MenuItem from "@mui/material/MenuItem";
import { SendRejectionEmail, SendRejectionEmailByCandidateList } from "services/CandidatesService";
import Box from "@mui/material/Box";

interface Props {
    open: boolean,
    jobId: number,
    candidateIds: number[],
    defaultRejectionTemplateId?: number,
    closeHandler: () => void,
    onRejectionEmailSent: () => void,
    errorHandler?: (message: string) => void,
}

const noTemplateOption: MessageTemplate = { id: 0, recruiterID: 0, type: 0, typeName: '', name: 'Do not Send Rejection Email', subject: '', body: '', createdDate: '', deleted: false, contact: false, candidate: false, userFullName: null };
const specialOptions: MessageTemplate[] = [noTemplateOption];

export default function RejectionEmailDialog({ open, jobId, candidateIds, defaultRejectionTemplateId = 0, closeHandler, onRejectionEmailSent, errorHandler }: Props) {
    const [isMandatory, setIsMandatory] = useState(false);
    const [templateId, setTemplateId] = useState(0);

    useEffect(() => {
        const getSettings = async () => {
            const isMandatorySetting = await GetCustomerSettingBySettingName('MandatoryRejectionEmails');
            if (isMandatorySetting) setIsMandatory(isMandatorySetting.toLowerCase() === 'true');
        };
        getSettings();
    }, []);

    useEffect(() => {
        if (open) setTemplateId(defaultRejectionTemplateId);
    }, [defaultRejectionTemplateId, open]);

    const sendRejectionEmailCallback = useCallback(async (laterOption: number) => {
        if (templateId > 0) {
            let res: boolean | null = null;
            if (candidateIds.length === 1) {
                res = await SendRejectionEmail(candidateIds[0], jobId, templateId, laterOption, errorHandler);
            }
            else if (candidateIds.length > 1) {
                res = await SendRejectionEmailByCandidateList(candidateIds, jobId, templateId, laterOption, errorHandler);
            }
            if (res) onRejectionEmailSent();
        }
        else onRejectionEmailSent();
    }, [templateId, onRejectionEmailSent, candidateIds, jobId, errorHandler]);

    return (
        <Dialog open={open}>
            <DialogTitle>Rejection Email</DialogTitle>
            <DialogContent>
                <DialogContentText mb="10px">{`A Rejection Email ${ isMandatory ? 'MUST' : 'should' } be sent. Please choose a template from below`}</DialogContentText>
                <MessageTemplatePicker
                    templateId={templateId}
                    onSelectHandler={ t => setTemplateId(t ? t.id : 0)}
                    templateSource="Me"
                    entityTypeFilter={3}
                    typeFilter={3}
                    includeCompanyOwned
                    appendToStart={specialOptions}
                />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="error" onClick={closeHandler}>Cancel</Button>
                <Box ml="5px">
                    <ActionsSplitButton mainAction={ () => sendRejectionEmailCallback(0) } mainActionDisabled={isMandatory && !Boolean(templateId)} secondaryActionDisabled={isMandatory && !Boolean(templateId)} disablePopperPortal={false} label="Send" color="success" menuPlacement="top-end">
                        <MenuItem onClick={ () => sendRejectionEmailCallback(1) }>In an Hour</MenuItem>
                        <MenuItem onClick={ () => sendRejectionEmailCallback(2) }>In 4 Hours</MenuItem>
                        <MenuItem onClick={ () => sendRejectionEmailCallback(3) }>In 8 Hours</MenuItem>
                        <MenuItem onClick={ () => sendRejectionEmailCallback(4) }>In 12 Hours</MenuItem>
                        <MenuItem onClick={ () => sendRejectionEmailCallback(5) }>In 24 Hours</MenuItem>
                        <MenuItem onClick={ () => sendRejectionEmailCallback(6) }>In 2 Days</MenuItem>
                        <MenuItem onClick={ () => sendRejectionEmailCallback(7) }>In 3 Days</MenuItem>
                        <MenuItem onClick={ () => sendRejectionEmailCallback(9) }>In 5 Days</MenuItem>
                        <MenuItem onClick={ () => sendRejectionEmailCallback(8) }>In 1 Week</MenuItem>
                    </ActionsSplitButton>
                </Box>
            </DialogActions>
        </Dialog>
    );
}