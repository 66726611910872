import { BasicFilter } from "common/models/Analytics/AnalyticsFilterData";
import { Billing } from "../common/models/Analytics/Billings";
import { Forecast } from "../common/models/Analytics/Forecasts";
import { ErrorHandler, RequestOptions } from "../common/models/GenericTypes";
import { Get, Post } from "../util/HttpUtils";

export async function GetMyForecasts(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Forecast[]>('api/Forecasts/Me', options);
}

export async function GetCustomForecasts(filterData: BasicFilter, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<Forecast[]>('api/Forecasts/Custom', options, filterData);
}

export async function GetCompanyForecasts(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Forecast[]>('api/Forecasts/Company', options);
}

export async function GetMyBillings(timeframe: 'ThisMonth' | 'ThisQtr' | 'ThisYear', errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    if (timeframe === 'ThisMonth') return Get<Billing>('api/Forecasts/Actuals/Me', options);
    else return Get<Billing>(`api/Forecasts/Actuals/Me/${timeframe}`, options);
}

export async function GetCustomBillings(timeframe: 'ThisMonth' | 'ThisQtr' | 'ThisYear', filterData: BasicFilter, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    if (timeframe === 'ThisMonth') return Post<Billing>('api/Forecasts/Actuals/Custom', options, filterData);
    else return Post<Billing>(`api/Forecasts/Actuals/Custom/${timeframe}`, options, filterData);
}

export async function GetCompanyBillings(timeframe: 'ThisMonth' | 'ThisQtr' | 'ThisYear', errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    if (timeframe === 'ThisMonth') return Get<Billing>('api/Forecasts/Actuals/Company', options);
    else return Get<Billing>(`api/Forecasts/Actuals/Company/${timeframe}`, options);
}