import React, { useCallback, useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import KeyboardControlKeyIcon from '@mui/icons-material/KeyboardControlKey';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import InfoButtonToolTip from "components/InfoButtonToolTip";
import { companyHasSinglePermission } from "util/PermissionsUtils";
import { Permission as P } from 'common/models/Permissions';
import {GetSurveys_SurveyMonkey} from 'services/CustomerIntegrationsService'
import {GetOnboardingForms} from 'services/OnboardingService'

import { ActivitySettings } from "common/models/Configuration/Activities";

import { AutomationOutput } from "common/models/Configuration/Automation";
import Autocomplete from "@mui/material/Autocomplete";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

import UserPicker from "components/Pickers/UserPicker";
import { NameIdObj } from "common/models/GenericTypes";
import { TagSettings, TagValue } from "common/models/Configuration/Tags";
import { RegexIsPositiveInteger, RegexIsValidEmail } from "util/RegExUtils";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import moment from "moment";
import { TemplatePlaceholder } from "common/models/Templates/TemplatePlaceholder";
import Collapse from "@mui/material/Collapse";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import CardActions from "@mui/material/CardActions";
import Input from "@mui/material/Input";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import TextAreaWithMentions from "components/TextAreaWithMentions";
import MultipleGroupPicker from "components/Pickers/MultipleGroupPicker";
import MultipleUserPicker from "components/Pickers/MultipleUserPicker";
import TextAreaNoFormat from "components/TextAreaNoFormat";
import {SurveyMonkeySurvey} from "../../common/models/Integrations/SurveyMonkey";
import {OnboardingForm} from "../../common/models/Onboarding";
import RWTextFieldComponent from "components/RWTextFieldComponent";

interface Props {
    index: number,
    entityId: number,
    isLast: boolean,
    output: AutomationOutput,
    activitySettings: ActivitySettings[],
    tagSettings?: TagSettings[],
    tagValues?: TagValue[],
    placeholders?: TemplatePlaceholder[],
    triggerId: number,
    onChange: (index: number, output: AutomationOutput) => void,
    moveUp: (index: number) => void,
    moveDown: (index: number, isLast: boolean) => void,
    onDelete: (index: number) => void,
}

interface Props2 {
    typeId: number,
    title: string,
    availableTags: TagValue[],
    selectedTagName: string,
    changeHandler: (tagName: string) => void,
}

interface Props3 {
    entityId: number,
    triggerId: number,
    dateType1: number,
    dateDays: number,
    dateExecution: string,
    dateTime: string,
    entityField: string,
    entityFields: PropertyOptions[],
    numberChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void,
    positiveNumberChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void,
    stringChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void,
    timeChangeHandler: (date: moment.Moment | null) => void,
    
}

interface PropertyOptions {
    value: string,
    label: string
}

type PropertiesByEntityId = Record<number, PropertyOptions[]>;
type EntityNamesById = Record<number, string>;

const entityNames: EntityNamesById = {
    4: 'Job',
    5: 'Placement',
    8: 'Opportunity'
}

const entityFields: PropertiesByEntityId = {
    4: [],
    5: [
        { label:'Start Date', value: 'start-date'},
        { label:'End Date', value: 'end-date'},
    ],
    8: []
};

const entityRecords: PropertiesByEntityId = {
    3: [
        { label:'Candidate', value: 'candidate'},
    ],
    5: [
        { label:'Candidate', value: 'candidate'},
        { label:'Client Contact', value: 'client-contact'},
        { label:'Hiring Manager', value: 'hiring-manager'},
        { label:'Signatory', value: 'signatory'},
        { label:'Billing Contact(s)', value: 'billing-contacts'},
        { label:'Payment Contact', value: 'payment-contact'},
    ],
};

const tagTypeColors = {
    "text": '#333333',
    "1": '#8db6fd',
    "2": '#FFFF66',
    "3": '#fdbf96',
    "4": '#66fc66',
    "5": '#ff9df5',
    "6": '#DCDCDC',
    "7": '#B0E0E6',
    "8": '#F5F5DC',
    "9": '#40E0D0',
    "10": '#FFA07A',
}

function getDescriptionFromType(typeId: number) {
    if (typeId === 1) return "Send Email";
    else if (typeId === 2) return "Send SMS";
    else if (typeId === 3) return "Create Activity";
    else if (typeId === 4) return "Send to Slack";
    else if (typeId === 5) return "Send to Microsoft Teams";
    else if (typeId === 6) return "POST to Webhook";
    else if (typeId === 7) return "Add Tag";
    else if (typeId === 8) return "Remove Tag";
    else if (typeId === 9) return "Send Survey via SurveyMonkey";
    else if (typeId === 10) return "Send Onboarding Request";
    return '';
}

const rwNotificationsOption: NameIdObj = { id: -2, name: 'Recruit Wizard Notifications' };
const consultant1NotificationOption: NameIdObj = { id: -10, name: 'Placement Consultant 1' };

export default function AutomationOutputComponent({ index, entityId, isLast, output, activitySettings, tagSettings, tagValues, placeholders,triggerId, onChange, moveUp, moveDown, onDelete }: Props) {
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [selectedActivityType, setSelectedActivityType] = useState<ActivitySettings | null>(null);
    const [surveys, setSurveys] = useState<SurveyMonkeySurvey[] | null>(null);
    const [onboardingForms, setOnboardingForms] = useState<OnboardingForm[] | null>(null);
    
    useEffect(() => {
        const getData = async () => {
            const res = await GetSurveys_SurveyMonkey();
            if (res) setSurveys(res);
        };
        getData();
    }, []);

    useEffect(() => {
        const getData = async () => {
            const res = await GetOnboardingForms();
            if (res) setOnboardingForms(res);
        };
        getData();
    }, []);
    
    useEffect(() => {
        if (output && output.activityType) {
            const f = activitySettings.find(r => r.id === output.activityType);
            if (f) {
                setSelectedActivityType(f);
                return;
            }
        }
        setSelectedActivityType(null);
    }, [output, activitySettings]);

    const stringChangeHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        onChange(index, { ...output, [name]: value });
    }, [index, onChange, output]);

    const numberChangeHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        onChange(index, { ...output, [name]: +value });
    }, [index, onChange, output]);

    const numberTypeChangeHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        onChange(index, { ...output, [name]: +value, description: getDescriptionFromType(+value) });
    }, [index, onChange, output]);

    const positiveIntegerChangeHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        if (value.trim() === '') onChange(index, { ...output, [name]: 0 });
        else if (RegexIsPositiveInteger(value)) {
            onChange(index, { ...output, [name]: +value });
        }
    }, [index, onChange, output]);

    const onActivityTypeChange = useCallback((event: React.SyntheticEvent<Element, Event>, value: ActivitySettings | null) => {
        onChange(index, { ...output, activityType: value ? value.id : 0 });
        setSelectedActivityType(value);
    }, [index, onChange, output]);

    const onActivityAssignedToChange = useCallback((u: NameIdObj | null) => {
        if(u) onChange(index, { ...output, activityAssignedTo: u.id });
    }, [index, onChange, output]);

    const onFromChange = useCallback((u: NameIdObj | null) => {
        if(u) onChange(index, { ...output, emailFrom: u.id });
    }, [index, onChange, output]);

    const onTagNameChange = useCallback((tagName: string) => {
        onChange(index, { ...output, tagName: tagName });
    }, [index, onChange, output]);

    const onActivityTimeChangeHandler = useCallback((date: moment.Moment | null) => {
        let formatted = '';
        if (date) formatted = date.format('HH:mm');
        onChange(index, { ...output, dateTime: formatted });
    }, [index, onChange, output]);

    const onNotesChange = useCallback((content: string) => {
        onChange(index, { ...output, activityNotes: content });
    }, [index, onChange, output]);

    const onEmailBodyChange = useCallback((content: string) => {
        onChange(index, { ...output, emailBody: content });
    }, [index, onChange, output]);

    const onSmsBodyChange = useCallback((content: string) => {
        onChange(index, { ...output, smsBody: content });
    }, [index, onChange, output]);

    const filteredTagsByType = useMemo(() => {
        if (output.tagTypeId && tagValues) {
            return tagValues.filter(t => t.tagTypeID === output.tagTypeId);
        }
        return [];
    }, [output.tagTypeId, tagValues]);

    const selectedTagSetting = useMemo(() => {
        if (tagSettings && output.tagTypeId) return tagSettings.find(t => t.tagTypeId === output.tagTypeId);
    }, [tagSettings, output.tagTypeId]);

    const availableEntityFields = useMemo(() => {
        const f = entityFields[entityId];
        if (f) return f;
        return [];
    }, [entityId]);

    const availableEntityRecords = useMemo(() => {
        const r = entityRecords[entityId];
        if (r) return r;
        return [];
    }, [entityId]);

    const futureDateSummary = useMemo(() => {
        if ((output.typeId === 1 || output.typeId === 2 || output.typeId === 3) && (output.dateType === 1 || output.dateType === 2)) {
            let timeString = '';
            if (output.dateTime) {
                const m = moment('2020-01-01T' + output.dateTime);
                if (m.isValid()) timeString = m.format('h:mma');
            }

            const field = availableEntityFields.find(f => f.value === output.entityField);
            const fieldName = field ? field.label : '';
            const entity = entityNames[entityId] ?? '';
            if (output.dateType === 1) return `${output.dateDays} Business Days ${output.dateExecution} ${entity} ${fieldName} @ ${timeString}`;
            if (output.dateType === 2) return `${output.dateDays} Days ${output.dateExecution} ${entity} ${fieldName} @ ${timeString}`;
        }
        return '';
    }, [availableEntityFields, entityId, output.dateDays, output.dateExecution, output.dateTime, output.dateType, output.entityField, output.typeId]);

    const onEmailToNumericValuesChange = useCallback((values: NameIdObj[]) => {
        const ids = values.map(v => v.id);
        onChange(index, { ...output, emailToNumericValues: ids });
    }, [index, onChange, output]);

    const onEmailRecipientsChange = useCallback((values: string[]) => {
        
        if (values.length > output.emailToStringValues.length) {
            const last = values[values.length - 1];
            const existing = output.emailToStringValues.find(e => e === last);
            if (existing) return;
            if (!RegexIsValidEmail(last)) return;
        }

        onChange(index, { ...output, emailToStringValues: values });
    }, [index, onChange, output]);

    return (
        <Paper variant="outlined" sx={{ p: 2 }}>
            <Box display="flex" alignItems="center">
                <Typography component="span" paddingRight="10px"># {index + 1}</Typography>
                <IconButton size="small" disabled={index === 0} onClick={() => moveUp(index)}>
                    <ArrowUpwardIcon />
                </IconButton>
                <IconButton size="small" disabled={isLast} onClick={() => moveDown(index, isLast)}>
                    <ArrowDownwardIcon />
                </IconButton>
                <IconButton size="small" onClick={() => setIsCollapsed(prev => !prev)}>
                    { isCollapsed ?  <KeyboardArrowDownIcon /> : <KeyboardControlKeyIcon /> }
                </IconButton>

                { !Boolean(futureDateSummary) && <Typography component="span" px="10px">{output.description}</Typography> }
                { Boolean(futureDateSummary) && <Typography component="span" px="10px">{output.description} - {futureDateSummary}</Typography> }
                
                <IconButton size="small" onClick={() => onDelete(index)} color="error" sx={{ marginLeft: 'auto' }}>
                    <DeleteIcon />
                </IconButton>
            </Box>
            <Collapse in={!isCollapsed}>
                <Stack spacing={2} paddingTop={2}>

                    <Box display="flex">
                        <TextField
                            select
                            label="Action Type"
                            name="typeId"
                            value={output.typeId.toString()}
                            sx={{ flex: '1 0 0' }}
                            onChange={numberTypeChangeHandler}
                        >
                            <MenuItem disabled={!companyHasSinglePermission(P.Automation)} value="7">Add Tag</MenuItem>
                            <MenuItem disabled={!companyHasSinglePermission(P.Automation)} value="3">Create Activity</MenuItem>
                            <MenuItem disabled={!companyHasSinglePermission(P.Automation)} value="6">POST to Webhook</MenuItem>
                            <MenuItem disabled={!companyHasSinglePermission(P.Automation)} value="8">Remove Tag</MenuItem>
                            <MenuItem value="1">Send Email</MenuItem>
                            <MenuItem disabled={!companyHasSinglePermission(P.Automation)} value ="10">Send Onboarding Request</MenuItem>
                            <MenuItem disabled={entityId === 4} value="2">Send SMS</MenuItem>
                            <MenuItem disabled={!companyHasSinglePermission(P.Automation)} value ="9">Send Survey via SurveyMonkey</MenuItem>
                            <MenuItem disabled={!companyHasSinglePermission(P.Automation)} value="4">Send to Slack</MenuItem>
                            <MenuItem disabled={!companyHasSinglePermission(P.Automation)} value="5">Send to Microsoft Teams</MenuItem>
                        </TextField>
                        <InfoButtonToolTip text={companyHasSinglePermission(P.Automation) ? "Type of Action to be executed" : "Type of Action to be executed. These are restricted in Automation Lite." } />
                    </Box>
                    <RWTextFieldComponent 
                            label="Description"
                            name="description"
                            variant="outlined"
                            value={output.description}
                            onChange={stringChangeHandler}
                        />
                    {(output.typeId === 1 || output.typeId === 9 || output.typeId === 10) &&
                        <>
                            {output.typeId === 9 &&
                            <Box display="flex">
                                <TextField select label="Survey" name="surveyId" value={output.surveyId ? output.surveyId.toString() : "0"} onChange={numberChangeHandler} sx={{ flex: '1 0 0' }} >
                                    <MenuItem value="0"></MenuItem>
                                    {surveys && surveys.map(q => <MenuItem key={q.id} value={q.id.toString()}>{q.title}</MenuItem>)}
                                </TextField>
                                <InfoButtonToolTip text="Survey that should be included in the Email" />
                            </Box>
                            }
                            {output.typeId === 10 &&
                                <Box display="flex">
                                    <TextField select label="Onboarding Form" name="onboardingFormId" value={output.onboardingFormId ? output.onboardingFormId.toString() : "0"} onChange={numberChangeHandler} sx={{ flex: '1 0 0' }} >
                                        <MenuItem value="0"></MenuItem>
                                        {onboardingForms && onboardingForms.map(q => <MenuItem key={q.id} value={q.id.toString()}>{q.name}</MenuItem>)}
                                    </TextField>
                                    <InfoButtonToolTip text="Onboarding Form that should be included in the Email" />
                                </Box>
                            }
                            <ActivityDateFields
                                entityId={entityId}
                                triggerId={triggerId}
                                dateType1={output.dateType}
                                dateDays={output.dateDays}
                                dateExecution={output.dateExecution}
                                dateTime={output.dateTime}
                                entityField={output.entityField}
                                entityFields={availableEntityFields}
                                numberChangeHandler={numberChangeHandler}
                                positiveNumberChangeHandler={positiveIntegerChangeHandler}
                                stringChangeHandler={stringChangeHandler}
                                timeChangeHandler={onActivityTimeChangeHandler}
                            />
                            <Box display="flex">
                                <UserPicker
                                    userId={output.emailFrom}
                                    onSelect={onFromChange}
                                    appendToStart={[rwNotificationsOption, consultant1NotificationOption]}
                                    disableClearable
                                    sx={{ flex: '1 0 0' }}
                                    label="From"
                                />
                                <InfoButtonToolTip text="User Email should be sent from" />
                            </Box>
                            <TextField select label="Recipient(s) Type" name="emailToSource" value={output.emailToSource.toString()} onChange={numberChangeHandler} >
                                <MenuItem value="0"></MenuItem>
                                <MenuItem value="1">Groups</MenuItem>
                                <MenuItem value="2">Users</MenuItem>
                                <MenuItem value="3">Entity Record</MenuItem>
                                <MenuItem value="4">Other</MenuItem>
                            </TextField>
                            {output.emailToSource === 1 &&
                                <MultipleGroupPicker value={output.emailToNumericValues} onSelect={onEmailToNumericValuesChange} label="Recipient Groups" />
                            }
                            {output.emailToSource === 2 &&
                                <MultipleUserPicker value={output.emailToNumericValues} onSelect={onEmailToNumericValuesChange} label="Recipient Users" appendToStart={[consultant1NotificationOption]}/>
                            }
                            {output.emailToSource === 3 &&
                                <TextField select label="Recipient Record" name="emailEntityRecord" value={output.emailEntityRecord} onChange={stringChangeHandler}>
                                    <MenuItem value=""></MenuItem>
                                    { availableEntityRecords.map(r => <MenuItem value={r.value}>{r.label}</MenuItem>) }
                                </TextField>
                            }
                            {output.emailToSource === 4 &&
                                <Autocomplete
                                    value={output.emailToStringValues}
                                    multiple
                                    size="small"
                                    disableCloseOnSelect
                                    options={[]}
                                    selectOnFocus
                                    clearOnBlur
                                    autoSelect
                                    freeSolo
                                    onChange={(e,v,r,d) => onEmailRecipientsChange(v)}
                                    renderInput={(params) => ( <TextField {...params} label="Recipient Emails" /> ) }
                                />
                            }
                             <RWTextFieldComponent 
                                label="Subject"
                                name="emailSubject"
                                variant="outlined"
                                value={output.emailSubject}
                                onChange={stringChangeHandler} />
                            <TextAreaWithMentions
                                height={800}
                                toolbar1="mergefields fontsizeselect fontselect styleselect forecolor backcolor bold italic alignleft aligncenter alignright alignjustify bullist numlist table code link fullscreen"
                                initialValue={output.emailBody}
                                onContentChange={onEmailBodyChange}
                                placeholders={placeholders}
                            />
                        </>
                    }
                    {output.typeId === 2 &&
                        <>
                            <ActivityDateFields
                                entityId={entityId}
                                triggerId={triggerId}
                                dateType1={output.dateType}
                                dateDays={output.dateDays}
                                dateExecution={output.dateExecution}
                                dateTime={output.dateTime}
                                entityField={output.entityField}
                                entityFields={availableEntityFields}
                                numberChangeHandler={numberChangeHandler}
                                positiveNumberChangeHandler={positiveIntegerChangeHandler}
                                stringChangeHandler={stringChangeHandler}
                                timeChangeHandler={onActivityTimeChangeHandler}
                            />
                            <TextField select label="Recipient(s) Type" name="smsToSource" value={output.smsToSource ? output.smsToSource.toString(): "0"} onChange={numberChangeHandler} >
                                <MenuItem value="0"></MenuItem>
                                <MenuItem value="3">Entity Record</MenuItem>
                            </TextField>
                            {output.smsToSource && output.smsToSource === 3 &&
                                <TextField select label="Recipient Record" name="smsEntityRecord" value={output.smsEntityRecord} onChange={stringChangeHandler}>
                                    <MenuItem value=""></MenuItem>
                                    { availableEntityRecords.map(r => <MenuItem value={r.value}>{r.label}</MenuItem>) }
                                </TextField>
                            }
                            <TextAreaNoFormat
                                height={200}
                                initialValue={output.smsBody}
                                onContentChange={onSmsBodyChange}
                                placeholders={placeholders}
                            />
                        </>
                    }
                    {output.typeId === 3 &&
                        <>
                            <Box display="flex">
                                <Autocomplete
                                    fullWidth
                                    options={activitySettings}
                                    value={selectedActivityType}
                                    getOptionLabel={o => o.customName ? o.customName : o.name}
                                    isOptionEqualToValue={(o, v) => o.id === v.id}
                                    onChange={onActivityTypeChange}
                                    renderInput={params => <TextField {...params} label="Activity Type" inputProps={{ ...params.inputProps, "data-lpignore": "true" }} />}
                                    renderOption={(props, option) => (
                                        <ListItem {...props} key={option.id}>
                                            <ListItemText primary={option.customName ? option.customName : option.name} secondary={option.name} />
                                        </ListItem>
                                    )}
                                />
                                <InfoButtonToolTip text="Type of Activity that should be created. Eg. 'Call - Outbound'" />
                            </Box>
                            <Box display="flex">
                                <UserPicker
                                    userId={output.activityAssignedTo}
                                    onSelect={onActivityAssignedToChange}
                                    appendToStart={[consultant1NotificationOption]}
                                    disableClearable
                                    sx={{ flex: '1 0 0' }}
                                    label="Assigned to"
                                />
                                <InfoButtonToolTip text="User the Activity should be assigned to" />
                            </Box>
                            { entityId === 5 && <Box display="flex">
                                <TextField
                                    select

                                    sx={{ flex: '1 0 0' }}
                                    label="Activity Player"
                                    name="activityEntityRecord"
                                    value={output.activityEntityRecord} 
                                    onChange={stringChangeHandler}>
                                    <MenuItem value="">None</MenuItem>
                                { availableEntityRecords.map(r => <MenuItem value={r.value}>{r.label}</MenuItem>) }
                            </TextField>
                            <InfoButtonToolTip text="The player the Activity is associated with." />
                            </Box>}
                            
                            <ActivityDateFields
                                entityId={entityId}
                                triggerId={triggerId}
                                dateType1={output.dateType}
                                dateDays={output.dateDays}
                                dateExecution={output.dateExecution}
                                dateTime={output.dateTime}
                                entityField={output.entityField}
                                entityFields={availableEntityFields}
                                numberChangeHandler={numberChangeHandler}
                                positiveNumberChangeHandler={positiveIntegerChangeHandler}
                                stringChangeHandler={stringChangeHandler}
                                timeChangeHandler={onActivityTimeChangeHandler}
                            />
                            <TextAreaWithMentions
                                height={200}
                                initialValue={output.activityNotes}
                                onContentChange={onNotesChange}
                                placeholders={placeholders}
                            />
                        </>
                    }
                    {output.typeId === 4 &&
                        <>
                        <Box display="flex">
                            <RWTextFieldComponent 
                                label="Webhook URL"
                                name="slackWebhookUrl"
                                value={output.slackWebhookUrl}
                                onChange={stringChangeHandler}
                                sxOptions={{ flex: '1 1 0', pr: '5px' }}
                                variant="outlined" />
                            <InfoButtonToolTip text="'Incoming Webhook' URL provided by Slack after creating a Slack App. This will be linked to a single Channel." />
                        </Box>
                        <Box display="flex">
                            <RWTextFieldComponent
                                label="Title"
                                name="messageTitle"
                                variant="outlined"
                                value={output.messageTitle}
                                onChange={stringChangeHandler}
                                sxOptions={{ flex: '1 1 0', pr: '5px' }} />
                            <InfoButtonToolTip text="Title of the message being sent. Usually related to the trigger. eg. 'New Placement Made'" />
                        </Box>
                        <Box display="flex">
                        <RWTextFieldComponent
                                label="Message"
                                name="message"
                                variant="outlined"
                                minRows={6}
                                multiline={true}
                                value={output.message}
                                onChange={stringChangeHandler}
                                sxOptions={{ flex: '1 1 0', pr: '5px' }} />
                            <InfoButtonToolTip text="Main message being sent. This can include Placeholders in the #{Placeholder} format. eg. #{Placement.JobTitle}" />
                        </Box>
                        </>
                    }
                    {output.typeId === 5 &&
                        <>
                            <Box display="flex">
                            <RWTextFieldComponent
                                label="Webhook URL"
                                name="teamsWebhookUrl"
                                variant="outlined"
                                value={output.teamsWebhookUrl}
                                onChange={stringChangeHandler}
                                sxOptions={{ flex: '1 1 0', pr: '5px' }} />
                                <InfoButtonToolTip text="'Incoming Webhook' URL provided by Microsoft Teams after creating a Connector. This will be linked to a single Channel." />
                            </Box>
                            <Box display="flex">
                            <RWTextFieldComponent
                                label="Title"
                                name="messageTitle"
                                variant="outlined"
                                value={output.messageTitle}
                                onChange={stringChangeHandler}
                                sxOptions={{ flex: '1 1 0', pr: '5px' }} />
                                <InfoButtonToolTip text="Title of the message being sent. Usually related to the trigger. eg. 'New Placement Made'" />
                            </Box>
                            <Box display="flex">
                            <RWTextFieldComponent
                                label="Message"
                                name="message"
                                variant="outlined"
                                minRows={6}
                                multiline={true}
                                value={output.message}
                                onChange={stringChangeHandler}
                                sxOptions={{ flex: '1 1 0', pr: '5px' }} />
                                <InfoButtonToolTip text="Main message being sent. This can include Placeholders in the #{Placeholder} format. eg. #{Placement.JobTitle}" />
                            </Box>
                        </>
                    }
                    {output.typeId === 6 &&
                        <>
                            <Box display="flex">
                            <RWTextFieldComponent
                                label="Payload URL"
                                name="webhookUrl"
                                variant="outlined"
                                minRows={6}
                                multiline={true}
                                value={output.webhookUrl}
                                onChange={stringChangeHandler}
                                sxOptions={{ flex: '1 1 0', pr: '5px' }} />
                                <InfoButtonToolTip text="The server endpoint that will receive the webhook payload" />
                            </Box>
                            <Box display="flex">
                            <RWTextFieldComponent
                                label="Header Key"
                                name="webhookHeaderKey"
                                variant="outlined"
                                value={output.webhookHeaderKey}
                                onChange={stringChangeHandler}
                                sxOptions={{ flex: '1 1 0', pr: '5px' }} />
                                <InfoButtonToolTip text="Add a header key/value for the webhook request (optional)" />
                            </Box>
                            <Box display="flex">
                            <RWTextFieldComponent
                                label="Header Value"
                                name="webhookHeaderValue"
                                variant="outlined"
                                value={output.webhookHeaderValue}
                                onChange={stringChangeHandler}
                                sxOptions={{ flex: '1 1 0', pr: '5px' }} />
                                <InfoButtonToolTip text="Add a header key/value for the webhook request (optional)" />
                            </Box>
                            <Box display="flex">
                            <RWTextFieldComponent
                                label="Payload"
                                name="webhookPayload"
                                variant="outlined"
                                value={output.webhookPayload}
                                onChange={stringChangeHandler}
                                multiline={true}
                                minRows={6}
                                sxOptions={{ flex: '1 1 0', pr: '5px' }} />
                            <InfoButtonToolTip text="The payload will be delivered in the body of a POST request using an application/json content type. Each payload will contain a single event." />
                            </Box>
                        </>
                    }
                    {(output.typeId === 7 || output.typeId === 8) &&
                        <>
                            <TextField select label="Tag Type" name="tagTypeId" value={output.tagTypeId.toString()} onChange={numberChangeHandler}>
                                <MenuItem value="0"></MenuItem>
                                {tagSettings && tagSettings.map(s => <MenuItem key={`${s.entityId}-${s.tagTypeId}`} value={s.tagTypeId}>{s.tagAgencyName}</MenuItem> )}
                            </TextField>
                            {Boolean(output.tagTypeId) && selectedTagSetting && filteredTagsByType &&
                                <Box display="flex" justifyContent="center">
                                    <TagsListRenderer
                                        availableTags={filteredTagsByType}
                                        selectedTagName={output.tagName}
                                        title={selectedTagSetting.tagAgencyName}
                                        typeId={selectedTagSetting.tagTypeId}
                                        changeHandler={onTagNameChange}
                                    />
                                </Box>
                            }
                        </>
                    }
                </Stack>
            </Collapse>
        </Paper>
    );
}


const TagsListRenderer = ({ availableTags, selectedTagName, title, typeId, changeHandler }: Props2) => {
    const [filterText, setFilterText] = useState('');

    const selectHandler = useCallback((tag: TagValue) => {
        if (tag.tagTypeID === typeId && tag.tag === selectedTagName) changeHandler('');
        else changeHandler(tag.tag);

    }, [typeId, selectedTagName, changeHandler]);

    const handleFilterTextChangeCallback = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setFilterText(e.target.value);
    }, []);

    const filteredTags = useMemo(() => {
        if (filterText === '') return availableTags;
        return availableTags.filter(t => t.tag.toLowerCase().includes(filterText.toLowerCase()));
    }, [filterText, availableTags]);

    return (
        <Card sx={{ width: '250px', marginRight: '20px', marginBottom: '10px' }}>
            <CardHeader
                sx={{ py: 0, color: tagTypeColors.text, bgcolor: (tagTypeColors as any)[typeId], minHeight: '42px' }}
                title={title}
                titleTypographyProps={{ fontSize: '0.875rem', fontWeight: '600' }}
            />
            <Divider />
            <CardActions>
                <Input
                    value={ filterText }
                    onChange={ handleFilterTextChangeCallback }
                    fullWidth
                    placeholder="Filter"
                    endAdornment={ filterText === '' ? undefined : <IconButton size="small" onClick={ () => setFilterText('') } sx={{ p: 0 }}><CancelIcon /></IconButton> }
                />
            </CardActions>
            <List dense disablePadding sx={{ height: '200px', overflow: 'auto' }}>
                {filteredTags.map((tag, i) => {
                    return (
                        <ListItemButton key={`${i}-${tag.tagTypeID}-${tag.tag}`} onClick={ () => selectHandler(tag) } >
                            <ListItemIcon>
                                <Checkbox checked={ tag.tag === selectedTagName } />
                            </ListItemIcon>
                            <ListItemText primary={tag.tag} sx={{ overflowWrap: 'break-word' }} />
                        </ListItemButton>
                    );
                })}
            </List>
        </Card>
    );
};

const ActivityDateFields = ({ entityId, dateType1, dateDays, dateExecution, dateTime, entityField, entityFields,triggerId, numberChangeHandler, positiveNumberChangeHandler, stringChangeHandler, timeChangeHandler }: Props3) => {
    const activityDateTimeMoment = useMemo(() => {
        if (dateTime) {
            const m = moment('2020-01-01T' + dateTime);
            if (m.isValid()) return m;
        }
        return null;
    }, [dateTime]);
    
    return (
        <>

            <Box display="flex">
                <TextField label="Date Type" select name="dateType" value={dateType1} onChange={numberChangeHandler} sx={{ flex: '1 0 0' }}>
                    <MenuItem value="0">Immediate</MenuItem>
                    {(entityId === 5 && triggerId === 1) && <MenuItem disabled={!companyHasSinglePermission(P.Automation)} value="1">X Business Days</MenuItem>}
                    {(entityId === 5 && triggerId === 1) && <MenuItem disabled={!companyHasSinglePermission(P.Automation)} value="2">X Days</MenuItem>}
                </TextField>
                <InfoButtonToolTip text={companyHasSinglePermission(P.Automation) ? "When this action should be executed" : "When this action should be executed. Scheduling is restricted in Automation Lite."} />
            </Box>
            {(dateType1 === 1 || dateType1 === 2) &&
                <Box display="flex" gap={1}>
                    <TextField label="Days" name="dateDays" value={dateDays.toString()} onChange={positiveNumberChangeHandler} sx={{ flex: '1 1 0' }} />
                    <TextField select label="Activity Execution" name="dateExecution" value={dateExecution} onChange={stringChangeHandler} sx={{ flex: '1 1 0' }}>
                        <MenuItem value=""></MenuItem>
                        <MenuItem value="before">Before</MenuItem>
                        <MenuItem value="after">After</MenuItem>
                    </TextField>
                    <TextField select label="Entity Field" name="entityField" value={entityField} onChange={stringChangeHandler} sx={{ flex: '1 1 0' }}>
                        <MenuItem value="">None</MenuItem>
                        {entityFields.map(f => <MenuItem key={`${entityId}-${f.value}`} value={f.value}>{f.label}</MenuItem> )}
                    </TextField>
                    <TimePicker label="Time" value={activityDateTimeMoment} onChange={timeChangeHandler} sx={{ flex: '1 1 0' }} />
                </Box>
            }
        </>
    );
};