import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import Button from "@mui/material/Button";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useCallback, useEffect, useMemo, useState } from "react";
import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import PageContentLayout from "layouts/PageContentLayout";
import PageLayout from "layouts/PageLayout";
import AccordionDetails from "@mui/material/AccordionDetails";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import LocationPicker from "components/Pickers/LocationPicker";
import { IsValidNumericValue } from "util/RegExUtils";
import InputAdornment from "@mui/material/InputAdornment";
import { Location } from "common/models/Common";
import Box from "@mui/material/Box";
import Switch from "@mui/material/Switch";
import TagsSearchFilter from "components/Tags/TagsSearchFilter";
import { TagFilter } from "common/models/Configuration/Tags";
import Collapse from "@mui/material/Collapse";
import SitesGridComponent from "components/Grids/SitesGrid";
import { ClientSearch } from "common/models/Search/ClientSearch";
import Snackbar from "@mui/material/Snackbar";
import Alert from "components/Alert";
import { SxProps } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

const filterElementStyle: SxProps = { flex: '1 1 0' };

export default function SitesSearchPage() {
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [paramsExpanded, setParamsExpanded] = useState(true);
    const [resultsExpanded, setResultsExpanded] = useState(false);
    const [searchData, setSearchData] = useState<ClientSearch>();
    const [showGrid, setShowGrid] = useState(false);
    const [statusId, setStatusId] = useState(-1);
    const [location, setLocation] = useState<Location | null>(null);
    const [radius, setRadius] = useState('');
    const [taggedBy, setTaggedBy] = useState<'a' | 'm'>('a');
    const [taggedWithin, setTaggedWithin] = useState(999);

    const [isIncludeTag, setIsIncludeTag] = useState(true);
    const [includeTags, setIncludeTags] = useState<TagFilter[]>([]);
    const [excludeTags, setExcludeTags] = useState<TagFilter[]>([]);
    const [availableTags_All, setAvailableTags_All] = useState<TagFilter[]>([]);
    const [availableTags_Me, setAvailableTags_Me] = useState<TagFilter[]>([]);

    const isRadiusValidNumber = useMemo(() => IsValidNumericValue(radius), [radius]);

    const searchCallback = useCallback(() => {
        let s: ClientSearch = {
            status: statusId,
            taggedTimeRange: taggedWithin
        };

        if (isRadiusValidNumber) s.radius = +radius;

        if (location) {
            s.location = location.geoNameID;
            s.latitude = location.latitude;
            s.longitude = location.longitude;
        }

        if (includeTags.length > 0) s.includeTags = includeTags;
        if (excludeTags.length > 0) s.excludeTags = excludeTags;

        setSearchData(s);
        setShowGrid(true);
        setParamsExpanded(false);
        setResultsExpanded(true);

    }, [statusId, taggedWithin, radius, location, includeTags, excludeTags, isRadiusValidNumber]);

    const clearCallback = useCallback(() => {
        setStatusId(-1);
        setLocation(null);
        setRadius('');
        setIncludeTags([]);
        setExcludeTags([]);
        setShowGrid(false);
    }, []);

    const summaryBar = useMemo(() => {
        const clearAction = <Button variant="contained" color="error" onClick={clearCallback} sx={{ mr: '5px' }}>Clear</Button>;
        const searchAction = <Button variant="contained" color="success" onClick={searchCallback}>Search</Button>
        return <TitleAndActionSummaryBar title="Sites > Search" browserTabTitle="Search > Sites" action={<>{clearAction}{searchAction}</>} />
    }, [clearCallback, searchCallback]);

    const handleRemoveCallback = useCallback((data: TagFilter) => {
        const includeIndex = includeTags.findIndex(t => t.typeId === data.typeId && t.tag === data.tag);

        if (includeIndex !== -1) {
            setIncludeTags(prev => {
                let tmp = [...prev];
                const index = tmp.findIndex(t => t.typeId === data.typeId && t.tag === data.tag);
                if (index !== -1) tmp.splice(index, 1);
                return tmp;
            });
        }
        else {
            setExcludeTags(prev => {
                let tmp = [...prev];
                const index = tmp.findIndex(t => t.typeId === data.typeId && t.tag === data.tag);
                if (index !== -1) tmp.splice(index, 1);
                return tmp;
            });
        }

    }, [includeTags]);

    const handleAddCallback = useCallback((typeId: number, tag: string) => {
        const selectedTag = (taggedBy === 'a' ? availableTags_All : availableTags_Me).find(t => t.typeId === typeId && t.tag === tag);
        if (isIncludeTag) {
            setIncludeTags(prev => {
                const assignedTagIndex = prev.findIndex(t => t.typeId === typeId && t.tag === tag);
                if (assignedTagIndex === -1 && selectedTag) {
                    return [...prev, { ...selectedTag, source: taggedBy }]
                }
                return prev;
            });
        }
        else {
            setExcludeTags(prev => {
                const assignedTagIndex = prev.findIndex(t => t.typeId === typeId && t.tag === tag);
                if (assignedTagIndex === -1 && selectedTag) {
                    return [...prev, { ...selectedTag, source: taggedBy }]
                }
                return prev;
            });

        }
    }, [isIncludeTag, availableTags_All, availableTags_Me, taggedBy]);

    const radiusChangeHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;

        if (val === '') {
            setRadius('');
            return;
        }

        const isValid = IsValidNumericValue(val);
        if (isValid) {
            const numberVal = +val;
            if (numberVal > 0) setRadius(val);
        }
        else if (val.endsWith('.')) {
            const pointsPresent = (val.match(/\./g) || []).length;
            if (pointsPresent === 1) setRadius(val);
        }
    }, []);

    const locationChangeHandler = useCallback((l: Location | null) => {
        setLocation(l);
        if (radius === '') setRadius('50');
    }, [radius]);

    const keyUpHandler = useCallback((e: KeyboardEvent) => {
        if (e.code === 'Enter') searchCallback();
    }, [searchCallback]);

    useEffect(() => {
        window.addEventListener('keyup', keyUpHandler);
        return () => window.removeEventListener('keyup', keyUpHandler);
    }, [keyUpHandler]);

    useEffect(() => {
        const item = document.querySelector<HTMLElement>('#site-search-collapse-root>.MuiCollapse-wrapper>.MuiCollapse-wrapperInner');
        if (item) {
            item.style.display = 'flex';
            item.style.flexDirection = 'column';
        }
    }, []);

    return (
        <PageLayout SummaryBar={summaryBar}>
            <Snackbar open={successMessage !== ''} autoHideDuration={3000} onClose={() => setSuccessMessage('')}>
                <Alert onClose={() => setSuccessMessage('')}>{ successMessage }</Alert>
            </Snackbar>
            <Snackbar open={errorMessage !== ''} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{errorMessage}</Alert>
            </Snackbar>
            <PageContentLayout showLoading={isLoading}>
                <Accordion expanded={paramsExpanded} onChange={() => setParamsExpanded(prev => !prev)} >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        Criteria
                        <Typography color="text.secondary" component="span" ml="auto" mr={2}>{paramsExpanded ? 'Click to collapse' : 'Click to expand'}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Stack spacing={2}>
                            <TextField select label="Status" value={statusId.toString()} onChange={({ target }) => setStatusId(+target.value)}>
                                <MenuItem value="-1">All</MenuItem>
                                <MenuItem value="0">Active</MenuItem>
                                <MenuItem value="1">Inactive</MenuItem>
                            </TextField>
                            <Box display="flex" gap={1}>
                                <LocationPicker noContainer fullWidth useDefaultCountry location={location} onLocationChange={locationChangeHandler} innerSx={filterElementStyle} />
                                <TextField
                                    label="Radius (Km)"
                                    value={radius}
                                    onChange={radiusChangeHandler}
                                    error={!isRadiusValidNumber && radius !== ''}
                                    sx={filterElementStyle}
                                    InputProps={{ endAdornment: <InputAdornment position="end">Km</InputAdornment> }}
                                />
                            </Box>
                            <TagsSearchFilter
                                entityId={9}
                                includeTags={includeTags}
                                excludeTags={excludeTags}
                                tagSource={taggedBy}
                                addHandler={handleAddCallback}
                                removeHandler={handleRemoveCallback}
                                setAvailableTagsHandler={taggedBy === 'a' ? setAvailableTags_All : setAvailableTags_Me}
                                loadingHandler={setIsLoading}
                                errorHandler={setErrorMessage}
                            >
                                <Box>
                                    <Box>
                                        Exclude
                                        <Switch checked={isIncludeTag} onChange={({ target }) => setIsIncludeTag(target.checked)} />
                                        Include
                                    </Box>
                                    <TextField select label="Tagged By" value={taggedBy} onChange={({ target }) => setTaggedBy(target.value === 'm' ? 'm' : 'a')}>
                                        <MenuItem value="m">Me</MenuItem>
                                        <MenuItem value="a">Everyone</MenuItem>
                                    </TextField>
                                    <TextField select label="Tagged Within" value={taggedWithin.toString()} onChange={({ target }) => setTaggedWithin(+target.value)}>
                                        <MenuItem value="999">Anytime</MenuItem>
                                        <MenuItem value="1">1 Months</MenuItem>
                                        <MenuItem value="2">2 Months</MenuItem>
                                        <MenuItem value="3">3 Months</MenuItem>
                                        <MenuItem value="6">6 Months</MenuItem>
                                        <MenuItem value="12">1 Year</MenuItem>
                                        <MenuItem value="24">2 Years</MenuItem>
                                        <MenuItem value="36">3 Years</MenuItem>
                                    </TextField>
                                </Box>
                            </TagsSearchFilter>
                        </Stack>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={resultsExpanded} onChange={() => setResultsExpanded(prev => !prev)} style={{ marginBottom: 0, borderBottom: resultsExpanded ? 'none' : 'unset' }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        Results
                        <Typography color="text.secondary" component="span" ml="auto" mr={2}>{resultsExpanded ? 'Click to collapse' : 'Click to expand'}</Typography>
                    </AccordionSummary>
                </Accordion>
                <Collapse
                    id="site-search-collapse-root"
                    in={showGrid && resultsExpanded}
                    sx={{ flexGrow: (showGrid && resultsExpanded) ? 1 : 'unset', display: 'flex', pt: '10px' }}>
                    <SitesGridComponent gridName="site-search" source="search" sourceId={0} searchData={searchData} loadingHandler={setIsLoading} errorHandler={setErrorMessage} successHandler={setSuccessMessage} />
                </Collapse>
            </PageContentLayout>
        </PageLayout>
    );
}