import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import React, { useCallback, useEffect, useMemo } from "react";

interface Props {
    jobBoardId: number,
    advertId: number,
    attributes?: Record<string, string>,
    advertRecordAttributes?: Record<string, string>,
    attributeChangeHandler?: (jobBoardId: number, key: string, value: string) => void
}

export default function MonsterEdit({ jobBoardId, advertId, attributes, advertRecordAttributes, attributeChangeHandler }: Props) {
    const atts = useMemo<Record<string, string>>(() => {
        if (attributes) return attributes;
        return {};
    }, [attributes]);

    const recordAtts = useMemo<Record<string, string>>(() => {
        if (advertRecordAttributes) return advertRecordAttributes;
        return {};
    }, [advertRecordAttributes]);

    const jobType = useMemo(() => atts.JobType ?? '', [atts.JobType]);

    const classification = useMemo(() => recordAtts.classification ?? '', [recordAtts.classification]);
    const subClassification = useMemo(() => recordAtts.subClassification ?? '', [recordAtts.subClassification]);
    const country = useMemo(() => recordAtts.country ?? '', [recordAtts.country]);
    const region = useMemo(() => recordAtts.region ?? '', [recordAtts.region]);
    const location = useMemo(() => recordAtts.location ?? '', [recordAtts.location]);
    const workType = useMemo(() => recordAtts.workType ?? '', [recordAtts.workType]);
    const currencyCode = useMemo(() => recordAtts.currencyCode ?? '', [recordAtts.currencyCode]);
    const currencyName = useMemo(() => recordAtts.currencyName ?? '', [recordAtts.currencyName]);
    const salaryType = useMemo(() => recordAtts.salaryType ?? '', [recordAtts.salaryType]);
    const salaryFrom = useMemo(() => recordAtts.salaryFrom ?? '', [recordAtts.salaryFrom]);
    const salaryTo = useMemo(() => recordAtts.salaryTo ?? '', [recordAtts.salaryTo]);

    useEffect(() => attributeChangeHandler && attributeChangeHandler(jobBoardId, 'Category', classification), [attributeChangeHandler, classification, jobBoardId]);
    useEffect(() => attributeChangeHandler && attributeChangeHandler(jobBoardId, 'SubCategory', subClassification), [attributeChangeHandler, subClassification, jobBoardId]);
    useEffect(() => attributeChangeHandler && attributeChangeHandler(jobBoardId, 'Country', country), [attributeChangeHandler, country, jobBoardId]);
    useEffect(() => attributeChangeHandler && attributeChangeHandler(jobBoardId, 'Region', region), [attributeChangeHandler, region, jobBoardId]);
    useEffect(() => attributeChangeHandler && attributeChangeHandler(jobBoardId, 'Location', location), [attributeChangeHandler, location, jobBoardId]);
    useEffect(() => attributeChangeHandler && attributeChangeHandler(jobBoardId, 'WorkType', workType), [attributeChangeHandler, workType, jobBoardId]);
    useEffect(() => attributeChangeHandler && attributeChangeHandler(jobBoardId, 'Currency', currencyCode), [attributeChangeHandler, currencyCode, jobBoardId]);
    useEffect(() => attributeChangeHandler && attributeChangeHandler(jobBoardId, 'CurrencyName', currencyName), [attributeChangeHandler, currencyName, jobBoardId]);
    useEffect(() => attributeChangeHandler && attributeChangeHandler(jobBoardId, 'CompensationType', salaryType), [attributeChangeHandler, salaryType, jobBoardId]);
    useEffect(() => attributeChangeHandler && attributeChangeHandler(jobBoardId, 'SalaryMin', salaryFrom), [attributeChangeHandler, salaryFrom, jobBoardId]);
    useEffect(() => attributeChangeHandler && attributeChangeHandler(jobBoardId, 'SalaryMax', salaryTo), [attributeChangeHandler, salaryTo, jobBoardId]);

    const localChangeHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        attributeChangeHandler && attributeChangeHandler(jobBoardId, name, value);
    }, [attributeChangeHandler, jobBoardId]);

    return (
        <Box display="flex" gap={2} pt={2}>
            <Box display="flex" flexDirection="column" gap={2} width="50%">
                <TextField disabled label="Category" value={classification} />
                <TextField disabled label="Sub Category" value={subClassification} />
                <TextField disabled label="Country" value={country} />
                <TextField disabled label="Region" value={region} />
                <TextField disabled label="Location" value={location} />
            </Box>
            <Box display="flex" flexDirection="column" gap={2} width="50%">
                <TextField select label="Job Type" name="JobType" value={jobType} onChange={localChangeHandler}>
                    <MenuItem value=""></MenuItem>
                    <MenuItem value="1">Employee</MenuItem>
                    <MenuItem value="3">Intern</MenuItem>
                    <MenuItem value="20">Seasonal</MenuItem>
                    <MenuItem value="2">Temporary / Contract</MenuItem>
                    <MenuItem value="75">Volunteer</MenuItem>
                </TextField>
                <TextField disabled label="Work Type" value={workType} />
                <TextField disabled label="Pay Currency" value={currencyName} />
                <TextField disabled label="Pay Type" value={salaryType} />
                <Box display="flex" gap={2}>
                    <TextField disabled label="Salary From" sx={{ flex: '1 1 0' }} value={salaryFrom} />
                    <TextField disabled label="Salary To" sx={{ flex: '1 1 0' }} value={salaryTo} />
                </Box>
            </Box>
        </Box>
    );
}