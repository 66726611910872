import React, { useEffect, useMemo, useState } from "react";
import { Report } from "common/models/Reports/Report";
import ReportsGridComponent from "components/Reports/ReportsGrid";
import OnlyTitleSummaryBar from "components/SummaryBars/OnlyTitleSummaryBar";
import PageContentLayout from "layouts/PageContentLayout";
import PageLayout from "layouts/PageLayout";
import { GetReports } from "services/ClientsService";
import { Link, useParams } from 'react-router-dom';
import NotContactedClientsReport from "components/Reports/Clients/NotContacted";
import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import DetailedClientsReport from "components/Reports/Clients/ClientsDetailed";
import DetailedSitesReport from "components/Reports/Sites/SitesDetailed";
import DetailedDocumentsReport from "components/Reports/Documents/DocumentsDetailed";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

const gridSummaryBar = <OnlyTitleSummaryBar title="Analytics > Clients" browserTabTitle="Clients > Analytics" />;
const ClientsReportsLink = <Link to="/analytics/clients" style={{ color: 'inherit', textDecoration: 'underline' }}>Clients</Link>;

export default function ClientReportsPage() {
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [reports, setReports] = useState<Report[]>([]);
    const params = useParams();

    const reportName = useMemo(() => {
        const reportNameParam = params.reportName;
        return reportNameParam;
    }, [params.reportName]);

    const reportObj = useMemo(() => {
        return reports.find(r => r.pathName === reportName)
    }, [reports, reportName]);

    const summaryBar = useMemo(() => {
        if (reportObj) {
            const title = <>{'Analytics > '} {ClientsReportsLink} {' > ' + reportObj.title}</>;
            const tabTitle = reportObj.title + ' > Clients > Analytics';
            return <TitleAndActionSummaryBar title={title} browserTabTitle={tabTitle} action={<></>} />;
        }
        return gridSummaryBar;
    }, [reportObj]);

    useEffect(() => {
        const getReports = async () => {
            setIsLoading(true);
            const res = await GetReports();
            if (res) setReports(res);
            setIsLoading(false);
        };
        getReports();
    }, []);

    return (
        <PageLayout SummaryBar={summaryBar}>
            <Snackbar open={successMessage !== ''} autoHideDuration={3000} onClose={() => setSuccessMessage('')}>
                <Alert onClose={() => setSuccessMessage('')}>{ successMessage }</Alert>
            </Snackbar>
            <Snackbar open={errorMessage !== ''}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <PageContentLayout showLoading={isLoading}>
                { !reportName && <ReportsGridComponent data={reports} /> }
                { reportObj && reportObj.pathName === 'not-contacted' && <NotContactedClientsReport description={reportObj.longDescription} loadingHandler={setIsLoading} /> }
                { reportObj && reportObj.pathName === 'detailed' && <DetailedClientsReport description={reportObj.longDescription} loadingHandler={setIsLoading} /> }
                { reportObj && reportObj.pathName === 'sites-detailed' && <DetailedSitesReport description={reportObj.longDescription} loadingHandler={setIsLoading} /> }
                {reportObj && reportObj.pathName === 'documents' &&
                    <DetailedDocumentsReport
                        entityTypeId={1}
                        description={reportObj.longDescription}
                        loadingHandler={setIsLoading}
                        successHandler={setSuccessMessage}
                        errorHandler={setErrorMessage}
                    />
                }
                {reportObj && reportObj.pathName === 'sites-documents' && <DetailedDocumentsReport entityTypeId={9} description={reportObj.longDescription} loadingHandler={setIsLoading} />}
            </PageContentLayout>
        </PageLayout>
    );
}