import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { JobBoardFieldSetupValue } from "common/models/JobPosting/JobBoards";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { GetJobBoardFieldSetupValues } from "services/JobBoardsService";
import { CategoryToOccupatonsMap } from "util/Definitions/Adverts/CarrerOne";

interface Props {
    jobBoardId: number,
    advertId: number,
    attributes?: Record<string, string>,
    advertRecordAttributes?: Record<string, string>,
    attributeChangeHandler?: (jobBoardId: number, key: string, value: string) => void,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
}

const jobBoardTypeId = 68;

export default function CareerOneEdit({ jobBoardId, advertId, attributes, advertRecordAttributes, attributeChangeHandler, loadingHandler, errorHandler }: Props) {
    const [countryToStateMap, setCountryToStateMap] = useState<Record<string, JobBoardFieldSetupValue[]>>({});
    const [stateToRegionMap, setStateToRegionMap] = useState<Record<string, JobBoardFieldSetupValue[]>>({});
    const [regionToAreaMap, setRegionToAreaMap] = useState<Record<string, JobBoardFieldSetupValue[]>>({});
    const [areaToLocationMap, setAreaToLocationMap] = useState<Record<string, JobBoardFieldSetupValue[]>>({});

    const atts = useMemo<Record<string, string>>(() => {
        if (attributes) return attributes;
        return {};
    }, [attributes]);

    const recordAtts = useMemo<Record<string, string>>(() => {
        if (advertRecordAttributes) return advertRecordAttributes;
        return {};
    }, [advertRecordAttributes]);

    const country = useMemo(() => atts.Country ?? '0', [atts.Country]);
    const state = useMemo(() => atts.State ?? '', [atts.State]);
    const region = useMemo(() => atts.Region ?? '', [atts.Region]);
    const area = useMemo(() => atts.Area ?? '', [atts.Area]);
    const suburb = useMemo(() => atts.Location ?? '', [atts.Location]);
    const category = useMemo(() => atts.Category ?? '0', [atts.Category]);
    const occupation = useMemo(() => atts.Occupation ?? '', [atts.Occupation]);
    const contratType = useMemo(() => atts.ContratType ?? '0', [atts.ContratType]);
    const adType = useMemo(() => atts.AdType ?? '1', [atts.AdType]);
    const showSalary = useMemo(() => atts.ShowSalary ?? '1', [atts.ShowSalary]);

    const workType = useMemo(() => recordAtts.workType ?? '', [recordAtts.workType]);
    const salaryType = useMemo(() => recordAtts.salaryType ?? '', [recordAtts.salaryType]);
    const salaryFrom = useMemo(() => recordAtts.salaryFrom ?? '', [recordAtts.salaryFrom]);
    const salaryTo = useMemo(() => recordAtts.salaryTo ?? '', [recordAtts.salaryTo]);

    useEffect(() => attributeChangeHandler && attributeChangeHandler(jobBoardId, 'JobType', workType), [attributeChangeHandler, workType, jobBoardId]);
    useEffect(() => attributeChangeHandler && attributeChangeHandler(jobBoardId, 'PayType', salaryType), [attributeChangeHandler, salaryType, jobBoardId]);
    useEffect(() => attributeChangeHandler && attributeChangeHandler(jobBoardId, 'SalaryMin', salaryFrom), [attributeChangeHandler, salaryFrom, jobBoardId]);
    useEffect(() => attributeChangeHandler && attributeChangeHandler(jobBoardId, 'SalaryMax', salaryTo), [attributeChangeHandler, salaryTo, jobBoardId]);

    useEffect(() => {
        const getStateValues = async () => {
            if (country && country !== '0') {
                const opts = countryToStateMap[country];
                if (!Boolean(opts)) {
                    loadingHandler && loadingHandler(true);
                    const res = await GetJobBoardFieldSetupValues(jobBoardTypeId, advertId, 'STATE', country, errorHandler);
                    if (res) {
                        setCountryToStateMap(prev => {
                            let tmp = {...prev, [country]: res};
                            return tmp;
                        });
                    }
                    loadingHandler && loadingHandler(false);
                }
            }
        };
        getStateValues();
    }, [advertId, country, countryToStateMap, errorHandler, loadingHandler]);

    useEffect(() => {
        const getRegionValues = async () => {
            if (state) {
                const opts = stateToRegionMap[state];
                if (!Boolean(opts)) {
                    loadingHandler && loadingHandler(true);
                    const res = await GetJobBoardFieldSetupValues(jobBoardTypeId, advertId, 'REGION', state, errorHandler);
                    if (res) {
                        setStateToRegionMap(prev => {
                            let tmp = {...prev, [state]: res};
                            return tmp;
                        });
                    }
                    loadingHandler && loadingHandler(false);
                }
            }
        };
        getRegionValues();
    }, [advertId, errorHandler, loadingHandler, state, stateToRegionMap]);

    useEffect(() => {
        const getAreaValues = async () => {
            if (region) {
                const opts = regionToAreaMap[region];
                if (!Boolean(opts)) {
                    loadingHandler && loadingHandler(true);
                    const res = await GetJobBoardFieldSetupValues(jobBoardTypeId, advertId, 'AREA', region, errorHandler);
                    if (res) {
                        setRegionToAreaMap(prev => {
                            let tmp = {...prev, [region]: res};
                            return tmp;
                        });
                    }
                    loadingHandler && loadingHandler(false);
                }
            }
        };
        getAreaValues();
    }, [advertId, errorHandler, loadingHandler, region, regionToAreaMap]);

    useEffect(() => {
        const getLocationValues = async () => {
            if (area) {
                const opts = areaToLocationMap[area];
                if (!Boolean(opts)) {
                    loadingHandler && loadingHandler(true);
                    const res = await GetJobBoardFieldSetupValues(jobBoardTypeId, advertId, 'SUBURB', area, errorHandler);
                    if (res) {
                        setAreaToLocationMap(prev => {
                            let tmp = {...prev, [area]: res};
                            return tmp;
                        });
                    }
                    loadingHandler && loadingHandler(false);
                }
            }
        };
        getLocationValues();
    }, [advertId, area, areaToLocationMap, errorHandler, loadingHandler]);

    const localChangeHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        attributeChangeHandler && attributeChangeHandler(jobBoardId, name, value);
    }, [attributeChangeHandler, jobBoardId]);

    const categoryChangeHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        if (attributeChangeHandler) {
            attributeChangeHandler(jobBoardId, 'Occupation', '');
            attributeChangeHandler(jobBoardId, 'Category', value);
        }
    }, [attributeChangeHandler, jobBoardId]);

    const countryChangeHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        if (attributeChangeHandler) {
            attributeChangeHandler(jobBoardId, 'Location', '');
            attributeChangeHandler(jobBoardId, 'Area', '');
            attributeChangeHandler(jobBoardId, 'Region', '');
            attributeChangeHandler(jobBoardId, 'State', '');
            attributeChangeHandler(jobBoardId, 'Country', value);
        }
    }, [attributeChangeHandler, jobBoardId]);

    const stateChangeHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        if (attributeChangeHandler) {
            attributeChangeHandler(jobBoardId, 'Location', '');
            attributeChangeHandler(jobBoardId, 'Area', '');
            attributeChangeHandler(jobBoardId, 'Region', '');
            attributeChangeHandler(jobBoardId, 'State', value);
        }
    }, [attributeChangeHandler, jobBoardId]);

    const regionChangeHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        if (attributeChangeHandler) {
            attributeChangeHandler(jobBoardId, 'Location', '');
            attributeChangeHandler(jobBoardId, 'Area', '');
            attributeChangeHandler(jobBoardId, 'Region', value);
        }
    }, [attributeChangeHandler, jobBoardId]);

    const areaChangeHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        if (attributeChangeHandler) {
            attributeChangeHandler(jobBoardId, 'Location', '');
            attributeChangeHandler(jobBoardId, 'Area', value);
        }
    }, [attributeChangeHandler, jobBoardId]);

    const renderedOccupationOptions = useMemo(() => {
        if (category) {
            const opts = CategoryToOccupatonsMap[category];
            if (opts) return opts.map(o => <MenuItem key={o.id} value={o.id}>{o.name}</MenuItem>);
        }
        return [];
    }, [category]);

    const renderedStateOptions = useMemo(() => {
        if (country && country !== '0') {
            const opts = countryToStateMap[country];
            if (opts && opts.length > 0) return opts.map(o => <MenuItem key={o.value} value={o.value}>{o.text}</MenuItem>);
        }
        return [];
    }, [country, countryToStateMap]);

    const renderedRegionOptions = useMemo(() => {
        if (state) {
            const opts = stateToRegionMap[state];
            if (opts && opts.length > 0) return opts.map(o => <MenuItem key={o.value} value={o.value}>{o.text}</MenuItem>);
        }
        return [];
    }, [state, stateToRegionMap]);

    const renderedAreaOptions = useMemo(() => {
        if (region) {
            const opts = regionToAreaMap[region];
            if (opts && opts.length > 0) return opts.map(o => <MenuItem key={o.value} value={o.value}>{o.text}</MenuItem>);
        }
        return [];
    }, [region, regionToAreaMap]);

    const renderedLocationOptions = useMemo(() => {
        if (area) {
            const opts = areaToLocationMap[area];
            if (opts && opts.length > 0) return opts.map(o => <MenuItem key={o.value} value={o.value}>{o.text}</MenuItem>);
        }
        return [];
    }, [area, areaToLocationMap]);

    return (
        <Box display="flex" gap={2} pt={2}>
            <Box display="flex" flexDirection="column" gap={2} width="50%">
                <TextField select label="Country" value={country} name="Country" onChange={countryChangeHandler}>
                    <MenuItem value="0"></MenuItem>
					<MenuItem value="15343">Afghanistan</MenuItem>
					<MenuItem value="15354">Aland Islands</MenuItem>
					<MenuItem value="15346">Albania</MenuItem>
					<MenuItem value="15401">Algeria</MenuItem>
					<MenuItem value="15351">American Samoa</MenuItem>
					<MenuItem value="15341">Andorra</MenuItem>
					<MenuItem value="15348">Angola</MenuItem>
					<MenuItem value="15345">Anguilla</MenuItem>
					<MenuItem value="15349">Antarctica</MenuItem>
					<MenuItem value="15344">Antigua and Barbuda</MenuItem>
					<MenuItem value="15350">Argentina</MenuItem>
					<MenuItem value="15347">Armenia</MenuItem>
					<MenuItem value="15353">Aruba</MenuItem>
					<MenuItem value="15299">Australia</MenuItem>
					<MenuItem value="15352">Austria</MenuItem>
					<MenuItem value="15355">Azerbaijan</MenuItem>
					<MenuItem value="15371">Bahamas</MenuItem>
					<MenuItem value="15362">Bahrain</MenuItem>
					<MenuItem value="15358">Bangladesh</MenuItem>
					<MenuItem value="15357">Barbados</MenuItem>
					<MenuItem value="15375">Belarus</MenuItem>
					<MenuItem value="15359">Belgium</MenuItem>
					<MenuItem value="15376">Belize</MenuItem>
					<MenuItem value="15364">Benin</MenuItem>
					<MenuItem value="15366">Bermuda</MenuItem>
					<MenuItem value="15372">Bhutan</MenuItem>
					<MenuItem value="15368">Bolivia</MenuItem>
					<MenuItem value="15369">Bonaire, Sint Eustatius and Saba</MenuItem>
					<MenuItem value="15356">Bosnia and Herzegovina</MenuItem>
					<MenuItem value="15374">Botswana</MenuItem>
					<MenuItem value="15373">Bouvet Island</MenuItem>
					<MenuItem value="15370">Brazil</MenuItem>
					<MenuItem value="15445">British Indian Ocean Territory</MenuItem>
					<MenuItem value="15367">Brunei Darussalam</MenuItem>
					<MenuItem value="15361">Bulgaria</MenuItem>
					<MenuItem value="15360">Burkina Faso</MenuItem>
					<MenuItem value="15363">Burundi</MenuItem>
					<MenuItem value="15391">Cabo Verde</MenuItem>
					<MenuItem value="15456">Cambodia</MenuItem>
					<MenuItem value="15386">Cameroon</MenuItem>
					<MenuItem value="15377">Canada</MenuItem>
					<MenuItem value="15463">Cayman Islands</MenuItem>
					<MenuItem value="15380">Central African Republic</MenuItem>
					<MenuItem value="15553">Chad</MenuItem>
					<MenuItem value="15385">Chile</MenuItem>
					<MenuItem value="15387">China</MenuItem>
					<MenuItem value="15393">Christmas Island</MenuItem>
					<MenuItem value="15378">Cocos (Keeling) Islands</MenuItem>
					<MenuItem value="15388">Colombia</MenuItem>
					<MenuItem value="15458">Comoros</MenuItem>
					<MenuItem value="15381">Congo</MenuItem>
					<MenuItem value="15379">Congo, Democratic Republic of the</MenuItem>
					<MenuItem value="15384">Cook Islands</MenuItem>
					<MenuItem value="15389">Costa Rica</MenuItem>
					<MenuItem value="15383">Cote d'Ivoire</MenuItem>
					<MenuItem value="15437">Croatia</MenuItem>
					<MenuItem value="15390">Cuba</MenuItem>
					<MenuItem value="15392">Curacao</MenuItem>
					<MenuItem value="15394">Cyprus</MenuItem>
					<MenuItem value="15395">Czechia</MenuItem>
					<MenuItem value="15398">Denmark</MenuItem>
					<MenuItem value="15397">Djibouti</MenuItem>
					<MenuItem value="15399">Dominica</MenuItem>
					<MenuItem value="15400">Dominican Republic</MenuItem>
					<MenuItem value="15402">Ecuador</MenuItem>
					<MenuItem value="15404">Egypt</MenuItem>
					<MenuItem value="15548">El Salvador</MenuItem>
					<MenuItem value="15427">Equatorial Guinea</MenuItem>
					<MenuItem value="15406">Eritrea</MenuItem>
					<MenuItem value="15403">Estonia</MenuItem>
					<MenuItem value="15551">Eswatini</MenuItem>
					<MenuItem value="15408">Ethiopia</MenuItem>
					<MenuItem value="15411">Falkland Islands (Malvinas)</MenuItem>
					<MenuItem value="15413">Faroe Islands</MenuItem>
					<MenuItem value="15410">Fiji</MenuItem>
					<MenuItem value="15409">Finland</MenuItem>
					<MenuItem value="15414">France</MenuItem>
					<MenuItem value="15419">French Guiana</MenuItem>
					<MenuItem value="15513">French Polynesia</MenuItem>
					<MenuItem value="15554">French Southern Territories</MenuItem>
					<MenuItem value="15415">Gabon</MenuItem>
					<MenuItem value="15424">Gambia</MenuItem>
					<MenuItem value="15418">Georgia</MenuItem>
					<MenuItem value="15396">Germany</MenuItem>
					<MenuItem value="15421">Ghana</MenuItem>
					<MenuItem value="15422">Gibraltar</MenuItem>
					<MenuItem value="15428">Greece</MenuItem>
					<MenuItem value="15423">Greenland</MenuItem>
					<MenuItem value="15417">Grenada</MenuItem>
					<MenuItem value="15426">Guadeloupe</MenuItem>
					<MenuItem value="15431">Guam</MenuItem>
					<MenuItem value="15430">Guatemala</MenuItem>
					<MenuItem value="15420">Guernsey</MenuItem>
					<MenuItem value="15425">Guinea</MenuItem>
					<MenuItem value="15432">Guinea-Bissau</MenuItem>
					<MenuItem value="15433">Guyana</MenuItem>
					<MenuItem value="15438">Haiti</MenuItem>
					<MenuItem value="15435">Heard Island and McDonald Islands</MenuItem>
					<MenuItem value="15574">Holy See</MenuItem>
					<MenuItem value="15436">Honduras</MenuItem>
					<MenuItem value="15434">Hong Kong</MenuItem>
					<MenuItem value="15439">Hungary</MenuItem>
					<MenuItem value="15448">Iceland</MenuItem>
					<MenuItem value="15444">India</MenuItem>
					<MenuItem value="15440">Indonesia</MenuItem>
					<MenuItem value="15447">Iran</MenuItem>
					<MenuItem value="15446">Iraq</MenuItem>
					<MenuItem value="15441">Ireland</MenuItem>
					<MenuItem value="15443">Isle of Man</MenuItem>
					<MenuItem value="15442">Israel</MenuItem>
					<MenuItem value="15449">Italy</MenuItem>
					<MenuItem value="15451">Jamaica</MenuItem>
					<MenuItem value="15453">Japan</MenuItem>
					<MenuItem value="15450">Jersey</MenuItem>
					<MenuItem value="15452">Jordan</MenuItem>
					<MenuItem value="15464">Kazakhstan</MenuItem>
					<MenuItem value="15454">Kenya</MenuItem>
					<MenuItem value="15457">Kiribati</MenuItem>
					<MenuItem value="15462">Kuwait</MenuItem>
					<MenuItem value="15455">Kyrgyzstan</MenuItem>
					<MenuItem value="15465">Lao People's Democratic Republic</MenuItem>
					<MenuItem value="15474">Latvia</MenuItem>
					<MenuItem value="15466">Lebanon</MenuItem>
					<MenuItem value="15471">Lesotho</MenuItem>
					<MenuItem value="15470">Liberia</MenuItem>
					<MenuItem value="15475">Libya</MenuItem>
					<MenuItem value="15468">Liechtenstein</MenuItem>
					<MenuItem value="15472">Lithuania</MenuItem>
					<MenuItem value="15473">Luxembourg</MenuItem>
					<MenuItem value="15487">Macao</MenuItem>
					<MenuItem value="15481">Madagascar</MenuItem>
					<MenuItem value="15495">Malawi</MenuItem>
					<MenuItem value="15497">Malaysia</MenuItem>
					<MenuItem value="15494">Maldives</MenuItem>
					<MenuItem value="15484">Mali</MenuItem>
					<MenuItem value="15492">Malta</MenuItem>
					<MenuItem value="15482">MarshIslands</MenuItem>
					<MenuItem value="15489">Martinique</MenuItem>
					<MenuItem value="15490">Mauritania</MenuItem>
					<MenuItem value="15493">Mauritius</MenuItem>
					<MenuItem value="15584">Mayotte</MenuItem>
					<MenuItem value="15496">Mexico</MenuItem>
					<MenuItem value="15412">Micronesia</MenuItem>
					<MenuItem value="15478">Moldova</MenuItem>
					<MenuItem value="15477">Monaco</MenuItem>
					<MenuItem value="15486">Mongolia</MenuItem>
					<MenuItem value="15479">Montenegro</MenuItem>
					<MenuItem value="15491">Montserrat</MenuItem>
					<MenuItem value="15476">Morocco</MenuItem>
					<MenuItem value="15498">Mozambique</MenuItem>
					<MenuItem value="15485">Myanmar</MenuItem>
					<MenuItem value="15499">Namibia</MenuItem>
					<MenuItem value="15508">Nauru</MenuItem>
					<MenuItem value="15507">Nepal</MenuItem>
					<MenuItem value="15505">Netherlands</MenuItem>
					<MenuItem value="15500">New Caledonia</MenuItem>
					<MenuItem value="15335">New Zealand</MenuItem>
					<MenuItem value="15504">Nicaragua</MenuItem>
					<MenuItem value="15501">Niger</MenuItem>
					<MenuItem value="15503">Nigeria</MenuItem>
					<MenuItem value="15509">Niue</MenuItem>
					<MenuItem value="15502">Norfolk Island</MenuItem>
					<MenuItem value="15460">North Korea</MenuItem>
					<MenuItem value="15483">North Macedonia</MenuItem>
					<MenuItem value="15488">Northern Mariana Islands</MenuItem>
					<MenuItem value="15506">Norway</MenuItem>
					<MenuItem value="15510">Oman</MenuItem>
					<MenuItem value="15516">Pakistan</MenuItem>
					<MenuItem value="15523">Palau</MenuItem>
					<MenuItem value="15521">Palestine</MenuItem>
					<MenuItem value="15511">Panama</MenuItem>
					<MenuItem value="15514">Papua New Guinea</MenuItem>
					<MenuItem value="15524">Paraguay</MenuItem>
					<MenuItem value="15512">Peru</MenuItem>
					<MenuItem value="15515">Philippines</MenuItem>
					<MenuItem value="15519">Pitcairn</MenuItem>
					<MenuItem value="15517">Poland</MenuItem>
					<MenuItem value="15522">Portugal</MenuItem>
					<MenuItem value="15520">Puerto Rico</MenuItem>
					<MenuItem value="15525">Qatar</MenuItem>
					<MenuItem value="15526">Reunion</MenuItem>
					<MenuItem value="15527">Romania</MenuItem>
					<MenuItem value="15529">Russia</MenuItem>
					<MenuItem value="15530">Rwanda</MenuItem>
					<MenuItem value="15365">Saint Barthelemy</MenuItem>
					<MenuItem value="15537">Saint Helena, Ascension and Tristan da Cunha</MenuItem>
					<MenuItem value="15459">Saint Kitts and Nevis</MenuItem>
					<MenuItem value="15467">Saint Lucia</MenuItem>
					<MenuItem value="15480">Saint Martin</MenuItem>
					<MenuItem value="15518">Saint Pierre and Miquelon</MenuItem>
					<MenuItem value="15575">Saint Vincent and the Grenadines</MenuItem>
					<MenuItem value="15582">Samoa</MenuItem>
					<MenuItem value="15542">San Marino</MenuItem>
					<MenuItem value="15547">Sao Tome and Principe</MenuItem>
					<MenuItem value="15531">Saudi Arabia</MenuItem>
					<MenuItem value="15543">Senegal</MenuItem>
					<MenuItem value="15528">Serbia</MenuItem>
					<MenuItem value="15533">Seychelles</MenuItem>
					<MenuItem value="15541">Sierra Leone</MenuItem>
					<MenuItem value="15536">Singapore</MenuItem>
					<MenuItem value="15549">Sint Maarten</MenuItem>
					<MenuItem value="15540">Slovakia</MenuItem>
					<MenuItem value="15538">Slovenia</MenuItem>
					<MenuItem value="15532">Solomon Islands</MenuItem>
					<MenuItem value="15544">Somalia</MenuItem>
					<MenuItem value="15585">South Africa</MenuItem>
					<MenuItem value="15429">South Georgia and the South Sandwich Islands</MenuItem>
					<MenuItem value="15461">South Korea</MenuItem>
					<MenuItem value="15546">South Sudan</MenuItem>
					<MenuItem value="15407">Spain</MenuItem>
					<MenuItem value="15469">Sri Lanka</MenuItem>
					<MenuItem value="15534">Sudan</MenuItem>
					<MenuItem value="15545">Suriname</MenuItem>
					<MenuItem value="15539">Svalbard and Jan Mayen</MenuItem>
					<MenuItem value="15535">Sweden</MenuItem>
					<MenuItem value="15382">Switzerland</MenuItem>
					<MenuItem value="15550">Syrian Arab Republic</MenuItem>
					<MenuItem value="15566">Taiwan</MenuItem>
					<MenuItem value="15557">Tajikistan</MenuItem>
					<MenuItem value="15567">Tanzania</MenuItem>
					<MenuItem value="15556">Thailand</MenuItem>
					<MenuItem value="15559">Timor-Leste</MenuItem>
					<MenuItem value="15555">Togo</MenuItem>
					<MenuItem value="15558">Tokelau</MenuItem>
					<MenuItem value="15562">Tonga</MenuItem>
					<MenuItem value="15564">Trinidad and Tobago</MenuItem>
					<MenuItem value="15561">Tunisia</MenuItem>
					<MenuItem value="15563">Turkey</MenuItem>
					<MenuItem value="15560">Turkmenistan</MenuItem>
					<MenuItem value="15552">Turks and Caicos Islands</MenuItem>
					<MenuItem value="15565">Tuvalu</MenuItem>
					<MenuItem value="15569">Uganda</MenuItem>
					<MenuItem value="15568">Ukraine</MenuItem>
					<MenuItem value="15342">United Arab Emirates</MenuItem>
					<MenuItem value="15416">United Kingdom of Great Britain and Northern Ireland</MenuItem>
					<MenuItem value="15570">United States Minor Outlying Islands</MenuItem>
					<MenuItem value="15571">United States of America</MenuItem>
					<MenuItem value="15572">Uruguay</MenuItem>
					<MenuItem value="15573">Uzbekistan</MenuItem>
					<MenuItem value="15580">Vanuatu</MenuItem>
					<MenuItem value="15576">Venezuela</MenuItem>
					<MenuItem value="15579">Vietnam</MenuItem>
					<MenuItem value="15577">Virgin Islands (British)</MenuItem>
					<MenuItem value="15578">Virgin Islands (U.S.)</MenuItem>
					<MenuItem value="15581">Wallis and Futuna</MenuItem>
					<MenuItem value="15405">Western Sahara</MenuItem>
					<MenuItem value="15583">Yemen</MenuItem>
					<MenuItem value="15586">Zambia</MenuItem>
					<MenuItem value="15587">Zimbabwe</MenuItem>
                </TextField>
                <TextField select label="State" value={state} name="State" onChange={stateChangeHandler}>
                    <MenuItem value=""></MenuItem>
                    {renderedStateOptions}
                </TextField>
                <TextField select label="Region" value={region} name="Region" onChange={regionChangeHandler}>
                    <MenuItem value=""></MenuItem>
                    {renderedRegionOptions}
                </TextField>
                <TextField select label="Area" value={area} name="Area" onChange={areaChangeHandler}>
                    <MenuItem value=""></MenuItem>
                    {renderedAreaOptions}
                </TextField>
                <TextField select label="Suburb" value={suburb} name="Location" onChange={localChangeHandler}>
                    <MenuItem value=""></MenuItem>
                    {renderedLocationOptions}
                </TextField>
                <TextField disabled label="Work Type" value={workType} />
            </Box>
            <Box display="flex" flexDirection="column" gap={2} width="50%">
                <TextField select label="Category" name="Category" value={category} onChange={categoryChangeHandler}>
                    <MenuItem value="0"></MenuItem>
                    <MenuItem value="C|1">Accounting</MenuItem>
                    <MenuItem value="C|2">Administration & Office Support</MenuItem>
                    <MenuItem value="C|3">Agriculture, Horticulture, Animal & Fishing</MenuItem>
                    <MenuItem value="C|5">Banking, Superannuation & Finance</MenuItem>
                    <MenuItem value="C|6">Construction</MenuItem>
                    <MenuItem value="C|7">Customer Service & CCentre</MenuItem>
                    <MenuItem value="C|4">Design & Architecture</MenuItem>
                    <MenuItem value="C|8">Editorial, Media & Creative Arts</MenuItem>
                    <MenuItem value="C|9">Education, Training & Childcare</MenuItem>
                    <MenuItem value="C|11">Engineering</MenuItem>
                    <MenuItem value="C|12">Executive Management & Consulting</MenuItem>
                    <MenuItem value="C|10">Government, Emergency Services & Defence</MenuItem>
                    <MenuItem value="C|13">Healthcare & Medical</MenuItem>
                    <MenuItem value="C|14">Hospitality, Tourism & Food Services</MenuItem>
                    <MenuItem value="C|15">Human Resources (HR) & Recruitment</MenuItem>
                    <MenuItem value="C|17">Information Technology (IT)</MenuItem>
                    <MenuItem value="C|16">Insurance</MenuItem>
                    <MenuItem value="C|18">Legal</MenuItem>
                    <MenuItem value="C|19">Manufacturing, Production & Operations</MenuItem>
                    <MenuItem value="C|20">Marketing & Advertising</MenuItem>
                    <MenuItem value="C|21">Mining & Energy</MenuItem>
                    <MenuItem value="C|22">Property & Real Estate</MenuItem>
                    <MenuItem value="C|23">Retail</MenuItem>
                    <MenuItem value="C|24">Sales</MenuItem>
                    <MenuItem value="C|25">Science, Technology & Environment</MenuItem>
                    <MenuItem value="C|26">Social Work & Community Services</MenuItem>
                    <MenuItem value="C|27">Sport & Recreation</MenuItem>
                    <MenuItem value="C|28">Trades & Services</MenuItem>
                    <MenuItem value="C|29">Transport & Logistics</MenuItem>
                    <MenuItem value="C|30">Work From Home & Self Employed</MenuItem>
                </TextField>
                <TextField select label="Occupation" name="Occupation" value={occupation} onChange={localChangeHandler}>
                    <MenuItem value=""></MenuItem>
                    {renderedOccupationOptions}
                </TextField>
                <TextField disabled label="Salary Type" value={salaryType} />
                <Box display="flex" gap={2}>
                    <TextField disabled label="Salary From" sx={{ flex: '1 1 0' }} value={salaryFrom} />
                    <TextField disabled label="Salary To" sx={{ flex: '1 1 0' }} value={salaryTo} />
                </Box>
                <TextField select label="Contract Type" name="ContratType" value={contratType} onChange={localChangeHandler}>
                    <MenuItem value="0"></MenuItem>
                    <MenuItem value="13">Contract</MenuItem>
                    <MenuItem value="11">Freelance & Tasks</MenuItem>
                    <MenuItem value="10">Internship & Trainee</MenuItem>
                    <MenuItem value="7">Permanent</MenuItem>
                    <MenuItem value="14">Temporary</MenuItem>
                    <MenuItem value="9">Volunteer</MenuItem>
                </TextField>
                <TextField select label="Ad Type" name="AdType" value={adType} onChange={localChangeHandler}>
                    <MenuItem value="1">Standard</MenuItem>
                    <MenuItem value="7">Premium</MenuItem>
                </TextField>
                <TextField select label="Show salary on Ad?" name="ShowSalary" value={showSalary} onChange={localChangeHandler}>
                    <MenuItem value="1">Yes</MenuItem>
                    <MenuItem value="0">No</MenuItem>
                </TextField>
            </Box>
        </Box>
    );
}