import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";
import { Site } from "common/models/Site";
import SitePicker from "components/Pickers/SitePicker";

interface Props {
    siteId: number,
    siteName: string,
    address1: string,
    address2: string,
    address3: string,
    suburb: string,
    state: string,
    postcode: string,
    country: string,
    clientId?: number,
    error?: boolean,
    onSiteChange: (site: Site | null) => void,
    loadingHandler?: (isLoading: boolean) => void,
    successHandler?: (message: string) => void,
    errorHandler?: (message: string) => void,
}

export default function EditableClientLocationElement({ siteId, siteName, address1, address2, address3, suburb, state, postcode, country, clientId, error, onSiteChange, loadingHandler, errorHandler, successHandler }: Props) {
    return (
        <Box height="100%" display="flex" flexDirection="column" overflow="auto" px="5px">
            <Box display="flex" height="40px">
                <Typography width="30%" variant="h6" fontWeight={600} component="div" /* pt="8px" */>Address</Typography>
            </Box>
            <Box display="flex" height="40px">
                <Box height="100%" width="30%" display="flex" component="div" flexDirection="column">
                    <Typography component="div" color={error ? 'error' : undefined} variant="body1" fontWeight={600} sx={{ userSelect: 'text' }}>
                        Site Name
                    </Typography>
                    { error && <Typography component="div" color="error" variant="caption" sx={{ userSelect: 'text' }}>Required</Typography> }
                </Box>
                <Box width="70%">
                    <SitePicker
                        value={siteId}
                        clientId={clientId}
                        onSelectCallback={onSiteChange}
                        variant="standard"
                        hideLabel
                        error={error}
                        loadingHandler={loadingHandler}
                        errorHandler={errorHandler}
                        successHandler={successHandler}
                    />
                </Box>
            </Box>
            <Box display="flex" height="40px">
                <Typography width="30%" variant="body1" fontWeight={600} component="div" sx={{ userSelect: 'text' }}>Address 1</Typography>
                <Typography width="70%" variant="body1" component="div" sx={{ userSelect: 'text' }}>{address1}</Typography>
            </Box>
            <Box display="flex" height="40px">
                <Typography width="30%" variant="body1" fontWeight={600} component="div" sx={{ userSelect: 'text' }}>Address 2</Typography>
                <Typography width="70%" variant="body1" component="div" sx={{ userSelect: 'text' }}>{address2}</Typography>
            </Box>
            <Box display="flex" height="40px">
                <Typography width="30%" variant="body1" fontWeight={600} component="div" sx={{ userSelect: 'text' }}>Address 3</Typography>
                <Typography width="70%" variant="body1" component="div" sx={{ userSelect: 'text' }}>{address3}</Typography>
            </Box>
            <Box display="flex" height="40px">
                <Typography width="30%" variant="body1" fontWeight={600} component="div" sx={{ userSelect: 'text' }}>Suburb</Typography>
                <Typography width="70%" variant="body1" component="div" sx={{ userSelect: 'text' }}>{suburb}</Typography>
            </Box>
            <Box display="flex" height="40px">
                <Typography width="30%" variant="body1" fontWeight={600} component="div" sx={{ userSelect: 'text' }}>State</Typography>
                <Typography width="70%" variant="body1" component="div" sx={{ userSelect: 'text' }}>{state}</Typography>
            </Box>
            <Box display="flex" height="40px">
                <Typography width="30%" variant="body1" fontWeight={600} component="div" sx={{ userSelect: 'text' }}>Postcode</Typography>
                <Typography width="70%" variant="body1" component="div" sx={{ userSelect: 'text' }}>{postcode}</Typography>
            </Box>
            <Box display="flex" height="40px">
                <Typography width="30%" variant="body1" fontWeight={600} component="div" sx={{ userSelect: 'text' }}>Country</Typography>
                <Typography width="70%" variant="body1" component="div" sx={{ userSelect: 'text' }}>{country}</Typography>
            </Box>
        </Box>
    );
}