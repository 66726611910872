import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Box from "@mui/material/Box";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { Group, GroupParticipant } from "common/models/Group";
import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import PageContentLayout from "layouts/PageContentLayout";
import { CreateGroup, GetGroup, GetManagers, GetMembers, UpdateGroup } from "services/GroupsService";
import { GetActiveUsers, GetAllUsers } from "services/UsersService";
import UserGroupRelationTransferList from "components/Groups/UserGroupRelationTransferList";
import Snackbar from "@mui/material/Snackbar";
import Alert from "components/Alert";
import useObjectStateWithChangeTracker from "hooks/UseObjectStateWithChangeTracker";
import { ChangeTracker } from "common/models/hooks/ChangeTracker";
import { User } from "common/models/Configuration/User";
import useUnsavedChangesDialog from "hooks/UseUnsavedChangesDialog";
import Typography from "@mui/material/Typography";
import RWTextFieldComponent from "components/RWTextFieldComponent";

interface Props {
    setSummaryBar?: (summaryBar: JSX.Element) => void
}

interface GroupChanges extends Group {
    memberIds: string,
    managerIds: string
}

const defaultGroup: Group = { id: 0, active: true, name: '', createdById: 0, updatedById: 0 };
const defaultGroupChanges: GroupChanges = { ...defaultGroup, memberIds: '[]', managerIds: '[]' };
const noChanges: ChangeTracker<GroupChanges> = {
    id: false,
    name: false,
    active: false,
    createdById: false,
    updatedById: false,
    memberIds: false,
    managerIds: false
}

export default function GroupItem({ setSummaryBar }: Props) {
    const [isLoading, setIsLoading] = useState(false);
    const [isFetchingUsers, setIsFetchingUsers] = useState(false);
    const [isFetchingParticipants, setIsFetchingParticipants] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showValidation, setShowValidation] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isShowInactive, setIsShowInactive] = useState(false);
    const [isGeneralExpanded, setIsGeneralExpanded] = useState(true);
    const [isMembersExpanded, setIsMembersExpanded] = useState(true);
    const [isManagersExpanded, setIsManagersExpanded] = useState(true);
    const { state, init, change, updateInitial, hasChanges } = useObjectStateWithChangeTracker<GroupChanges>(defaultGroupChanges, noChanges)
    const [members, setMembers] = useState<GroupParticipant[]>([]);
    const [managers, setManagers] = useState<GroupParticipant[]>([]);
    const [originalMembers, setOriginalMembers] = useState<number[]>([]);
    const [originalManagers, setOriginalManagers] = useState<number[]>([]);
    const [users, setUsers] = useState<User[]>([]);
    const params = useParams();

    const itemId = useMemo(() => {
        const parsedId = +(params.id ?? '0');
        if(isNaN(parsedId)) return 0;
        return parsedId
    }, [params.id]);

    const saveHandler = useCallback(async () => {
        if (state.name.trim() === '') {
            setShowValidation(true);
            setErrorMessage('Group must have a valid name');
            return false;
        }
        setIsSaving(true);

        let memberIds = JSON.parse(state.memberIds);
        if (state.memberIds === JSON.stringify(originalMembers)) {
            memberIds = null;
        }

        let managerIds = JSON.parse(state.managerIds);
        if (state.managerIds === JSON.stringify(originalManagers)) {
            managerIds = null;
        }

        const group: Group = {
            id: state.id,
            active: state.active,
            name: state.name,
            createdById: state.createdById,
            updatedById: state.updatedById
        };

        let res: boolean | null = false;

        if (itemId === 0) {
            res = await CreateGroup(group, memberIds, managerIds, setErrorMessage);
        }
        else {
            res = await UpdateGroup(itemId, group, memberIds, managerIds, setErrorMessage);
        }

        if(!res) {
            setIsSaving(false);
            return false;
        }

        updateInitial();
        setShowSuccess(true);
        setIsSaving(false);
        return true;
    }, [state, itemId, updateInitial, originalMembers, originalManagers]);

    useEffect(() => {
        setIsLoading(isFetchingParticipants || isFetchingUsers || isSaving);
    }, [isFetchingParticipants, isFetchingUsers, isSaving]);

    useEffect(() => {
        const showInactive = (
            <FormControlLabel
                sx={{ m: 0, mr:'20px' }}
                label="Include Inactive Users"
                labelPlacement="start"
                control={
                    <Checkbox
                        checked={ isShowInactive }
                        onChange={ () => setIsShowInactive(prev => !prev) }
                        sx={{ p: '4px' }}
                    />
                }
            />
        );
        const saveButton = <Button variant="contained" color="primary" disabled={!hasChanges} onClick={saveHandler}>Save</Button>;
        const summaryBar = (
            <TitleAndActionSummaryBar
                title="Configuration > Groups"
                browserTabTitle="Groups > Configuration"
                action={<div>{showInactive}{saveButton}</div>}
            />
        );
        setSummaryBar && setSummaryBar(summaryBar);
    }, [setSummaryBar, isShowInactive, hasChanges, saveHandler]);

    useEffect(() => {
        const getUsers = async () => {
            setIsFetchingUsers(true);
            if(isShowInactive) {
                const all = await GetAllUsers(setErrorMessage);
                if(all) setUsers(all);
            }
            else {
                const active = await GetActiveUsers(setErrorMessage);
                if(active) setUsers(active);
            }
            setIsFetchingUsers(false);
        };

        getUsers();
    }, [isShowInactive])

    useEffect(() => {
        const getEditData = async () => {
            setIsFetchingParticipants(true);
            const data = await GetGroup(itemId, setErrorMessage);
            const members = await GetMembers(itemId, setErrorMessage);
            const managers = await GetManagers(itemId, setErrorMessage);

            if(members && managers) {

                let memIds = [];
                let manIds = [];

                for (let i = 0; i < members.length; i++) {
                    const member = members[i];
                    memIds.push(member.userID);
                }
                memIds.sort();

                for (let i = 0; i < managers.length; i++) {
                    const manager = managers[i];
                    manIds.push(manager.userID);
                }
                manIds.sort();
                
                setOriginalMembers(memIds);
                setOriginalManagers(manIds);
                setMembers(members);
                setManagers(managers);
                if(data) init({...data, memberIds: JSON.stringify(memIds), managerIds: JSON.stringify(manIds)});
            }

            setIsFetchingParticipants(false);
        };

        itemId !== 0 && getEditData();
    }, [itemId, init]);

    useEffect(() => {
        let mIds: number[] = [];
        for (let i = 0; i < members.length; i++) {
            const member = members[i];
            mIds.push(member.userID);
        }
        mIds.sort();
        change('memberIds', JSON.stringify(mIds));
    }, [members, change]);

    useEffect(() => {
        let mIds: number[] = [];
        for (let i = 0; i < managers.length; i++) {
            const manager = managers[i];
            mIds.push(manager.userID);
        }
        mIds.sort();
        change('managerIds', JSON.stringify(mIds));
    }, [managers, change]);

    const memberMoveLeftCallback = useCallback((ids: number[]) => {
        setMembers(prev => {
            let newValues = [...prev];
            for (let i = 0; i < ids.length; i++) {
                const id = ids[i];
                const index = newValues.findIndex(v => v.userID === id);
                if(index !== -1) newValues.splice(index, 1);
            }
            return newValues;
        });
    }, []);

    const memberMoveRightCallback = useCallback((ids: number[]) => {
        setMembers(prev => {
            let newValues = [...prev];
            for (let i = 0; i < ids.length; i++) {
                const id = ids[i];
                const index = users.findIndex(u => u.id === id);
                if(index !== -1) {
                    const u = users[index];
                    newValues.push({ group: '', groupID: 0, id: 0, isEnabled: u.isEnabled, user: u.displayName, userID: u.id });
                }
            }
            return newValues;
        });
    }, [users]);

    const managerMoveLeftCallback = useCallback((ids: number[]) => {
        setManagers(prev => {
            let newValues = [...prev];
            for (let i = 0; i < ids.length; i++) {
                const id = ids[i];
                const index = newValues.findIndex(v => v.userID === id);
                if(index !== -1) newValues.splice(index, 1);
            }
            return newValues;
        });
    }, []);

    const managerMoveRightCallback = useCallback((ids: number[]) => {
        setManagers(prev => {
            let newValues = [...prev];
            for (let i = 0; i < ids.length; i++) {
                const id = ids[i];
                const index = users.findIndex(u => u.id === id);
                if(index !== -1) {
                    const u = users[index];
                    newValues.push({ group: '', groupID: 0, id: 0, isEnabled: u.isEnabled, user: u.displayName, userID: u.id });
                }
            }
            return newValues;
        });
    }, [users]);

    const { unsavedChangesDialog, hasBlockedRoute } = useUnsavedChangesDialog(hasChanges, saveHandler);

    return (
        <>
            {unsavedChangesDialog}
            { !hasBlockedRoute && showSuccess && <Navigate to="/configuration/groups" /> }
            <Snackbar open={showSuccess} autoHideDuration={3000} onClose={() => setShowSuccess(false)}>
                <Alert onClose={() => setShowSuccess(false)}>Changes Saved</Alert>
            </Snackbar>
            <Snackbar open={errorMessage !== ''}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <PageContentLayout title="Group" showLoading={isLoading}>
                <Box sx={{ bgcolor: 'background.default', p: 1 }}>
                    <Accordion expanded={isGeneralExpanded} onChange={ () => setIsGeneralExpanded(prev => !prev) }>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            General
                            <Typography color="text.secondary" component="span" ml="auto" mr={2}>{isGeneralExpanded ? 'Click to collapse' : 'Click to expand'}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Stack spacing={3}>
                                <RWTextFieldComponent
                                    label="Name"
                                    value={state.name}
                                    onChange={ (e) => change('name', e.target.value) }
                                    isError={showValidation && state.name.trim() === ''}
                                />
                                <TextField
                                    select
                                    label="Status"
                                    value={state.active ? '1': '0'}
                                    onChange={ ({target}) => change('active', target.value === '1') }
                                >
                                    <MenuItem value="1">Active</MenuItem>
                                    <MenuItem value="0">Inactive</MenuItem>
                                </TextField>
                            </Stack>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={isMembersExpanded} onChange={ () => setIsMembersExpanded(prev => !prev) }>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            Members
                            <Typography color="text.secondary" component="span" ml="auto" mr={2}>{isMembersExpanded ? 'Click to collapse' : 'Click to expand'}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <UserGroupRelationTransferList
                                users={users}
                                participants={members}
                                titleLeft="Non Members"
                                titleRight="Group Members"
                                moveLeftHandler={memberMoveLeftCallback}
                                moveRightHandler={memberMoveRightCallback}
                            />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={isManagersExpanded} onChange={ () => setIsManagersExpanded(prev => !prev) }>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            Managers
                            <Typography color="text.secondary" component="span" ml="auto" mr={2}>{isManagersExpanded ? 'Click to collapse' : 'Click to expand'}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <UserGroupRelationTransferList
                                users={users}
                                participants={managers}
                                titleLeft="Non Managers"
                                titleRight="Group Managers"
                                moveLeftHandler={managerMoveLeftCallback}
                                moveRightHandler={managerMoveRightCallback}
                            />
                        </AccordionDetails>
                    </Accordion>
                </Box>
            </PageContentLayout>
        </>
    );
}