import Alert from "components/Alert";
import Snackbar from "@mui/material/Snackbar";
import React, { useEffect, useState } from "react";
import OnlyTitleSummaryBar from "components/SummaryBars/OnlyTitleSummaryBar";
import PageContentLayout from "layouts/PageContentLayout";
import QuickViewLayoutAdmin from "components/QuickViewLayouts/Jobs/QuickViewLayoutAdmin";

interface Props {
    jobType: 'Permanent' | 'Contract' | 'FixedContract' | 'TalentPool' | 'Panel',
    setSummaryBar?: (summaryBar: JSX.Element) => void
}

export default function JobsQuickViewLayoutPage({ jobType, setSummaryBar }: Props) {
    const [isLoading, setIsloading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [jobTypeSwitch, setJobTypeSwitch] = useState(false);

    useEffect(() => {
        if (jobType) {}
        setJobTypeSwitch(prev => !prev);
    }, [jobType]);

    useEffect(() => {
        if (setSummaryBar) {
            let jt: string = jobType;
            if (jobType === 'FixedContract') jt = 'Fixed Term';
            else if (jobType === 'TalentPool') jt = 'Talent Pool';
            const summaryBar = <OnlyTitleSummaryBar title={`Configuration > Screen Layouts > ${jt} Jobs - Quick View`} browserTabTitle="Screen Layouts > Configuration" />;
            setSummaryBar(summaryBar);
        }
    }, [setSummaryBar, jobType]);
    
    return (
        <>
            <Snackbar open={errorMessage !== ''} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <PageContentLayout showLoading={isLoading}>
                { jobTypeSwitch && <QuickViewLayoutAdmin jobType={jobType} loadingHandler={setIsloading} errorHandler={setErrorMessage} /> }
                { !jobTypeSwitch && <QuickViewLayoutAdmin jobType={jobType} loadingHandler={setIsloading} errorHandler={setErrorMessage} /> }
            </PageContentLayout>
        </>
    );
}