import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { SxProps } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { GetTRC_MMAByDivisionsReports } from "services/MiscReportsService";
import { GridColDef, useGridApiRef } from "@mui/x-data-grid-premium";
import { MMAByDivisionsReportData } from "common/models/Reports/MMAByDivisions";
import GridWithStateWrapper from "components/GridWidthStateWrapper";
import { GetActiveDivisions } from "services/DivisionsService";
import { Division } from "common/models/Configuration/Division";
import { useSearchParams } from "react-router-dom";
import { DatePicker } from "@mui/x-date-pickers-pro";

interface Props {
    description: string,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
}

const filterElementStyle: SxProps = { flex: '1 1 0', mr: 1 };

export default function TRCMMAByDivisionsReport({description, loadingHandler, errorHandler }: Props) {
    const [rows, setRows] = useState<MMAByDivisionsReportData[]>([]);
    const [showGrid, setShowGrid] = useState(false);
    const [divisionId, setDivisionId] = useState(0);
    const [fromDate, setFromDate] = useState<moment.Moment | null>(null);
    const [toDate, setToDate] = useState<moment.Moment | null>(null);
    const apiRef = useGridApiRef();
    const [divisions, setDivisions] = useState<Division[]>([]);
    const [queryParams] = useSearchParams();

    useEffect(() => {
        var yesterday = false;
        if (queryParams.has("Yesterday")) {
            yesterday = queryParams.get("Yesterday") === "1";
        }
        if (yesterday) {
            setFromDate(moment().subtract(1, 'days'));
            setToDate(moment().subtract(1, 'days'));
        }
        else {
            setFromDate(moment().startOf('month'));
            setToDate(moment().endOf('month'));
        }
        const loadDivisions = async () => {
            let res = await GetActiveDivisions()
            if (res) setDivisions(res);
        };
        loadDivisions();
    }, [queryParams]);

    const getDataCallback = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        const stringFromDate = fromDate && fromDate.isValid() ? fromDate.format('YYYY-MM-DD') : '';
        const stringToDate = toDate && toDate.isValid() ? toDate.format('YYYY-MM-DD') : '';
        const res = await GetTRC_MMAByDivisionsReports(divisionId, stringFromDate, stringToDate, errorHandler);
        if (res) {
            setShowGrid(true);
            setRows(res);
        }
        loadingHandler && loadingHandler(false);
    }, [divisionId, fromDate, toDate, errorHandler, loadingHandler]);

    const exportAsExcelHandler = useCallback(() => {
        const api = apiRef.current;
        if (api) {
            const m = moment();
            const filename = `TRC_MMAByDivisions${m.format('YYYYMMDDhhmmss')}`;
            api.exportDataAsExcel({ fileName: filename });
        }
    }, [apiRef]);

    const columns = useMemo<GridColDef[]>(() => {
        return [
            { field: 'Consultant', width: 300 },
            { field: 'BD Calls', headerName: 'BD Calls', width: 120 },
            { field: 'Client Meetings', headerName: 'Client Meetings', width: 120 },
            { field: 'New Jobs', headerName: 'New Jobs', width: 120 },
            { field: 'Exclusive Jobs', headerName: 'Exclusive Jobs', width: 120 },
            { field: 'CVs', width: 120 },
            { field: 'Interviews', width: 120 },
            { field: 'Offers', width: 120 },
            { field: 'Placements', width: 120 },
        ];
    }, []);

    return (
        <>
            <Box display="flex">
                <TextField select label="Division" value={divisionId.toString()} onChange={ ({target}) => setDivisionId(+target.value) } sx={filterElementStyle}>
                    {divisions.map(division => (
                        <MenuItem value={division.id}>{division.name}</MenuItem>
                    ))}
                </TextField>
                <DatePicker label="From Date" value={fromDate} onChange={setFromDate} sx={filterElementStyle} slotProps={{actionBar: { actions: ["clear", "today", "cancel", "accept"] }}} />
                <DatePicker label="To Date" value={toDate} onChange={setToDate} sx={filterElementStyle} slotProps={{actionBar: { actions: ["clear", "today", "cancel", "accept"] }}} />
                <Button variant="contained" color="success" onClick={getDataCallback}>Run Report</Button>
            </Box>
            <Typography component="div" variant="h6" mt={2}>Report Description</Typography>
            <Typography component="div" variant="body2" mb={2}>{description}</Typography>
            {showGrid &&
                <>
                    <Box pb="10px" ml="auto">
                        <Button variant="contained" color="success" onClick={exportAsExcelHandler}>Export As Excel</Button>
                    </Box>
                    <GridWithStateWrapper
                        density="compact"
                        gridName="reports-trc-mmasbydivision"
                        rows={rows}
                        columns={columns}
                        apiRef={apiRef}
                        getRowId={r => r.Consultant}
                        disableRowSelectionOnClick
                    />
                </>
            }
        </>
    );
}