import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Alert from "components/Alert";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import { Permission as P } from '../../../common/models/Permissions';
import { userHasSinglePermission } from '../../../util/PermissionsUtils';

import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import PageContentLayout from "layouts/PageContentLayout";
import { AddUser } from '../../../services/UsersService';
import { DefaultOptions, NoChangesTracker, UsersValidation, DefaultValidationTracker } from '../../../util/Definitions/Configuration/UsersCreate';
import useObjectStateWithChangeTracker from "hooks/UseObjectStateWithChangeTracker";
import useUnsavedChangesDialog from "hooks/UseUnsavedChangesDialog";
import { UserCreate } from "common/models/Configuration/UserCreate";
import { GetActiveDivisions } from "services/DivisionsService";
import { Division } from "common/models/Configuration/Division";
import RWTextFieldComponent from "components/RWTextFieldComponent";

interface Props {
    setSummaryBar?: (summaryBar: JSX.Element) => void
}

export default function UsersAdd({ setSummaryBar }: Props) {
    const [isLoading, setIsLoading] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [divisions, setDivisions] = useState<Division[]>([]);
    const { 
        state,
        // init,
        change,
        updateInitial,
        hasChanges,
        validationMessages = DefaultValidationTracker,
        isValid
    } = useObjectStateWithChangeTracker<UserCreate>(DefaultOptions, NoChangesTracker, UsersValidation, DefaultValidationTracker);
    const navigate = useNavigate();

    const saveSettings = useCallback( async() => {
        setIsLoading(true);
        const res = await AddUser(state, setErrorMessage);
        
        if (!res) {
            setIsLoading(false);
            return false;
        }
        
        updateInitial();
        setShowSuccess(true);
        navigate('/configuration/users');
        setIsLoading(false);
        return true;
    }, [state, navigate, updateInitial]);

    const { unsavedChangesDialog } = useUnsavedChangesDialog(hasChanges, saveSettings);

    const handleChanges = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        change(name as keyof UserCreate, value);
    }

    const handleChangesBoolean = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        change(name as keyof UserCreate, value === 'true');
    }

    const handleChangesNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        change(name as keyof UserCreate, +value);
    }

    useEffect(() => {
        if(divisions.length === 0) {
            const getDivisions = async () => {
                setIsLoading(true);
                const data = await GetActiveDivisions(setErrorMessage);
                if(data) {
                    setDivisions(data);
                    if(data.length === 1) {
                        change('defaultDivision', data[0].id);
                        updateInitial();
                    }
                }
                setIsLoading(false);
            };
            getDivisions();
        }
    }, [change, updateInitial, divisions]);

    useEffect(() => {
        const saveButton = (
            <Button
                disabled={!hasChanges || !isValid}
                variant="contained"
                color="primary"
                onClick={saveSettings}
                sx={{ borderRadius: '2px', padding: 0, height: '36px', width: '80px' }}
            >
                Save
            </Button>
        );
        
        const SummaryBar = ( <TitleAndActionSummaryBar title="Configuration > Users > Add" browserTabTitle="Users > Configuration" action={saveButton} />);

        setSummaryBar && setSummaryBar(SummaryBar);
    }, [setSummaryBar, saveSettings, hasChanges, isValid]);


    return (
        <>
            {unsavedChangesDialog}
            <Snackbar open={showSuccess} autoHideDuration={3000} onClose={() => setShowSuccess(false)}>
                <Alert onClose={() => setShowSuccess(false)}>Changes Saved</Alert>
            </Snackbar>
            <Snackbar anchorOrigin={{ horizontal: 'center', vertical: 'top' }} open={errorMessage !== ''}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <PageContentLayout title="Add User" showLoading={isLoading} >
                <Stack spacing={3}>
                    <RWTextFieldComponent
                        label="First Name"
                        name="firstName"
                        value={state.firstName}
                        onChange={handleChanges}
                        isError={ validationMessages.firstName !== '' }
                        helperText={ validationMessages.firstName }
                    />
                    <RWTextFieldComponent
                        label="Last Name"
                        name="lastName"
                        value={state.lastName}
                        onChange={handleChanges}
                        isError={ validationMessages.lastName !== '' }
                        helperText={ validationMessages.lastName }
                    />
                    <RWTextFieldComponent
                        label="Email Address"
                        name="userName"
                        value={state.userName}
                        onChange={handleChanges}
                        isError={ validationMessages.userName !== '' }
                        helperText={ validationMessages.userName }
                    />
                    <TextField
                        select
                        disabled={!userHasSinglePermission(P.GlobalAdministrator)}
                        label="Configuration Manager"
                        name="configurationManager"
                        value={state.configurationManager}
                        onChange={handleChangesBoolean}
                    >
                        <MenuItem value="true">Yes</MenuItem>
                        <MenuItem value="false">No</MenuItem>
                    </TextField>
                    <TextField
                        select
                        disabled={!userHasSinglePermission(P.GlobalAdministrator)}
                        label="User Type"
                        name="userType"
                        value={state.userType}
                        onChange={handleChanges}
                    >
                        <MenuItem value="standard">Standard</MenuItem>
                        <MenuItem value="standardReporting">Standard + Reporting</MenuItem>
                        <MenuItem value="administrator">Administrator</MenuItem>
                    </TextField>
                    <TextField
                        select
                        label="Default Division"
                        name="defaultDivision"
                        value={state.defaultDivision.toString()}
                        onChange={handleChangesNumber}
                        error={ validationMessages.defaultDivision !== '' }
                        helperText={ validationMessages.defaultDivision }
                    >
                        {divisions.map((d, i) => (
                            <MenuItem key={d.id} value={d.id.toString()}>{d.name}</MenuItem>
                        ))}
                    </TextField>
                </Stack>
            </PageContentLayout>
        </>
    );
}