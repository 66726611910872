import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Alert from "components/Alert";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { Editor } from "@tinymce/tinymce-react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useCallback, useEffect, useRef, useState } from "react";
import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import PageContentLayout from "layouts/PageContentLayout";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import { GetSubmissionsSettings, UpdateSubmissionsSettings } from "services/ConfigurationService";
import { DefaultSubmissionsSettings, SubmissionsSettingsChangeTracker } from "util/Definitions/Configuration/Submissions";
import useObjectStateWithChangeTracker from "hooks/UseObjectStateWithChangeTracker";
import useUnsavedChangesDialog from "hooks/UseUnsavedChangesDialog";
import { SubmissionsConfigOptions } from "common/models/Configuration/Submissions";
import { ObjToSettingsArray } from "util/SettingsUtil";
import Typography from "@mui/material/Typography";
import InsertPlaceholderDialog from "components/Dialogs/Generic/InsertPlaceholderDialog";
import { TemplatePlaceholder } from "common/models/Templates/TemplatePlaceholder";
import { GetPlaceholdersByEntity } from "services/PlaceholdersService";
import RWTextFieldComponent from "components/RWTextFieldComponent";

interface Props {
    setSummaryBar?: (summaryBar: JSX.Element) => void
}

const submissionsEntityId = 6;

const DaysAdornment = <InputAdornment position="end">Day(s)</InputAdornment>;
const defaultMessage = "Hi #{Submission.Candidate.FirstName},<br/><br/>As per our recent conversation, we are happy to confirm that you have been submitted to one of our valued clients today. The details that we agreed for your submission are as follows:<br/><br/><table><tr><td width=\"125px\">Our Client: </td><td>#{Submission.Client.Name}</td></tr><tr><td>Role:</td><td>#{Submission.JobTitle}</td></tr><tr><td>Rate:</td><td>#{Submission.CandidateRate}</td></tr><tr><td>Availability:</td><td>#{Submission.Availability}</td></tr></table><br/><br/>If you have any questions about this submission or if at any time you would like to withdraw your submission, please respond immediately. <br/><br/>In the meantime, we will work hard on your behalf to try and get you selected for an interview with our client.<br/><br/>Regards,<br/><br/>#{User.FirstName}<br/><br/>#{User.Signature}";

export default function SubmissionsAdmin({ setSummaryBar }: Props) {
    const theme = useTheme();
    const isDarkTheme = theme.palette.mode === 'dark';
    const editorRef = useRef<any | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [expandStates, setExpandStates] = useState<boolean[]>([true, true, true, true]);
    const [isEditorDirty, setIsEditorDirty] = useState(false);
    const [templateContent, setTemplateConent] = useState('');
    const [showPlaceholders, setShowPlaceholders] = useState(false);
    const [placeholders, setPlaceholders] = useState<TemplatePlaceholder[]>([]);
    const { state, init, change, updateInitial, hasChanges } = useObjectStateWithChangeTracker<SubmissionsConfigOptions>(DefaultSubmissionsSettings, SubmissionsSettingsChangeTracker);

    useEffect(() => {
        const getPlaceholders = async () => {
            const res = await GetPlaceholdersByEntity(submissionsEntityId);
            if (res) setPlaceholders(res);
        };
        getPlaceholders();
    }, []);

    const saveHandlerCallback = useCallback( async () => {
        setIsLoading(true);
        const editorContent = editorRef.current.getContent();
        const settings = ObjToSettingsArray({ ...state, CandidateSubmissionConfirmation: editorContent });
        const res = await UpdateSubmissionsSettings(settings, setErrorMessage);
        if (!res) {
            setIsLoading(false);
            return false;
        }

        setShowSuccess(true);
        setTemplateConent(editorContent);
        updateInitial();
        setIsLoading(false);
        editorRef.current.setDirty(false);
        setIsEditorDirty(false);
        return true;
    }, [state, updateInitial]);

    const { unsavedChangesDialog } = useUnsavedChangesDialog((isEditorDirty || hasChanges), saveHandlerCallback);

    useEffect(() => {
        const action = <Button variant="contained" color="primary" disabled={ !hasChanges && !isEditorDirty } onClick={ saveHandlerCallback }>Save</Button>;
        const summaryBar = <TitleAndActionSummaryBar title="Configuration > Submissions" browserTabTitle="Submissions > Configuration" action={ action } />;
        setSummaryBar && setSummaryBar(summaryBar);
    }, [setSummaryBar, hasChanges, isEditorDirty, saveHandlerCallback]);

    useEffect(() => {
        const getData = async () => {
            setIsLoading(true);
            const data = await GetSubmissionsSettings(setErrorMessage);
            if (data) {
                let savedSettings: SubmissionsConfigOptions = { ...DefaultSubmissionsSettings }
                for (let i = 0; i < data.length; i++) {
                    const setting = data[i];
                    (savedSettings as any)[setting.type] = setting.value;
                }
                if (!savedSettings.CandidateSubmissionConfirmation) savedSettings.CandidateSubmissionConfirmation = defaultMessage;
                setTemplateConent(savedSettings.CandidateSubmissionConfirmation);
                init({ ...savedSettings, CandidateSubmissionConfirmation: '' });
            }
            setIsLoading(false);
        };

        getData();
    }, [init]);

    const handleChanges = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        change(name as keyof SubmissionsConfigOptions, value);
    }

    const handleExpansion = (index: number) => {
        setExpandStates(prev => {
            let newStates = [...prev];
            newStates[index] = !newStates[index];
            return newStates;
        });
    };

    const insertPlaceholderHandler = useCallback((placeholder: string) => {
        const api = editorRef.current;
        if (api) api.insertContent(placeholder);
    }, []);

    return (
        <>
            {unsavedChangesDialog}
            <Snackbar open={showSuccess} autoHideDuration={3000} onClose={() => setShowSuccess(false)}>
                <Alert onClose={() => setShowSuccess(false)}>Changes Saved</Alert>
            </Snackbar>
            <Snackbar open={errorMessage !== ''} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <InsertPlaceholderDialog
                open={showPlaceholders}
                closeHandler={() => setShowPlaceholders(false)}
                placeholders={placeholders}
                insertHandler={insertPlaceholderHandler}
            />
            <PageContentLayout title="Submissions" showLoading={isLoading}>
                <Box sx={{ bgcolor: 'background.default', p: 1 }}>
                    <Accordion expanded={ expandStates[0] } onChange={ () => handleExpansion(0) }>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            Submission Email
                            <Typography color="text.secondary" component="span" ml="auto" mr={2}>{expandStates[0] ? 'Click to collapse' : 'Click to expand'}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Stack spacing={3}>
                                <TextField
                                    select
                                    label="Confirmation Type"
                                    value={state.CandidateSubmissionConfirmationType}
                                    name="CandidateSubmissionConfirmationType"
                                    onChange={ handleChanges }
                                >
                                    <MenuItem value="0">Never Send</MenuItem>
                                    <MenuItem value="1">Send - Optional</MenuItem>
                                    <MenuItem value="2">Send - Mandatory</MenuItem>
                                </TextField>
                                <Editor
                                    tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce-5.10.2/tinymce.min.js'}
                                    initialValue={templateContent}
                                    onDirty={ () => setIsEditorDirty(true) }
                                    onInit={ (evt, editor) => editorRef.current = editor }
                                    init={{
                                        // height: '100%',
                                        height: '400px',
                                        skin: isDarkTheme ? 'oxide-dark' : undefined,
                                        content_css: isDarkTheme ? 'dark' : undefined,
                                        branding: false,
                                        menubar: false,
                                        contextmenu: false,
                                        plugins: 'powerpaste code link emoticons table print preview visualchars lists fullscreen',
                                        browser_spellcheck: true,
                                        toolbar1: 'forecolor backcolor | undo redo | bold italic alignleft aligncenter alignright alignjustify bullist numlist outdent indent table | code preview fullscreen',
                                        toolbar2: 'placeholders link fontsizeselect fontselect mybutton styleselect',
                                        font_formats: 'Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Calibri=calibri; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Segoe=segoe,segoe ui,dejavu sans,trebuchet ms,verdana,sans-serif; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva',
                                        setup: editor => {
                                            editor.ui.registry.addButton('placeholders', {
                                                text: 'Insert Placeholder',
                                                onAction: () => setShowPlaceholders(true)
                                            });
                                        }
                                    }}
                                />
                            </Stack>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={ expandStates[1] } onChange={ () => handleExpansion(1) }>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            Submission Deadline
                            <Typography color="text.secondary" component="span" ml="auto" mr={2}>{expandStates[1] ? 'Click to collapse' : 'Click to expand'}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Stack spacing={3}>
                                <TextField
                                    select
                                    label="Autopopulate"
                                    value={state.SubmissionDeadlinesAutoPopulate === "True" ? "True" : "False"}
                                    name="SubmissionDeadlinesAutoPopulate"
                                    onChange={ handleChanges }
                                >
                                    <MenuItem value="True">Enabled</MenuItem>
                                    <MenuItem value="False">Disabled</MenuItem>
                                </TextField>
                                <TextField
                                    type="number"
                                    label="Perm"
                                    InputProps={{ endAdornment: DaysAdornment }}
                                    value={state.CVDeadlinePerm}
                                    name="CVDeadlinePerm"
                                    onChange={ handleChanges }
                                />
                                <TextField
                                    type="number"
                                    label="Contract"
                                    InputProps={{ endAdornment: DaysAdornment }}
                                    value={state.CVDeadlineContract}
                                    name="CVDeadlineContract"
                                    onChange={ handleChanges }
                                />
                                <TextField
                                    type="number"
                                    label="Fixed Term"
                                    InputProps={{ endAdornment: DaysAdornment }}
                                    value={state.CVDeadlineFixedContract}
                                    name="CVDeadlineFixedContract"
                                    onChange={ handleChanges }
                                />
                                <TextField
                                    select
                                    label="Manual Submission Send Email Message Content"
                                    value={state.ManualSubmissionSendEmailMessageContents === 'True' ? "True" : "False"}
                                    name="ManualSubmissionSendEmailMessageContents"
                                    onChange={ handleChanges }
                                >
                                    <MenuItem value="True">Enabled</MenuItem>
                                    <MenuItem value="False">Disabled</MenuItem>
                                </TextField>
                            </Stack>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={ expandStates[2] } onChange={ () => handleExpansion(2) }>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            Submission Links
                            <Typography color="text.secondary" component="span" ml="auto" mr={2}>{expandStates[2] ? 'Click to collapse' : 'Click to expand'}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <TextField
                                fullWidth
                                type="number"
                                label="Expire in"
                                InputProps={{ endAdornment: DaysAdornment }}
                                value={state.SubmissionExpirationDays}
                                name="SubmissionExpirationDays"
                                onChange={ handleChanges }
                            />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={ expandStates[3] } onChange={ () => handleExpansion(3) }>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            Default Comments
                            <Typography color="text.secondary" component="span" ml="auto" mr={2}>{expandStates[3] ? 'Click to collapse' : 'Click to expand'}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <RWTextFieldComponent
                                multiline={true}
                                minRows={3}
                                label="Comments"
                                value={state.DefaultSubmissionComments}
                                name="DefaultSubmissionComments"
                                onChange={ handleChanges }
                            />
                        </AccordionDetails>
                    </Accordion>
                </Box>
            </PageContentLayout>
        </>
    );
}