import React, { useCallback, useEffect, useMemo, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Breakpoint } from "@mui/material/styles";
import moment from "moment";
import Stack from "@mui/material/Stack";
import UserPicker from "components/Pickers/UserPicker";
import { getJwtPayload } from "util/AuthUtils";
import { NameIdObj } from "common/models/GenericTypes";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

interface Props {
    open: boolean,
    title: string,
    message?: string,
    onClose: () => void,
    onContinue: (date: string, userId: number) => void,
    dateFieldLabel?: string,
    dateType?: 'date' | 'datetime-local',
    userLabel?: string,
    confirmActionText?: string,
    cancelActionText?: string,
    fullWidth?: boolean,
    maxWidth?: false | Breakpoint
    dividers?: boolean,
    hideBackdrop?: boolean,
    disableUserClearable?: boolean
}

export default function ConfirmationWithDateAndUserDialog(props: Props) {
    const [followUpDate, setFollowUpDate] = useState<moment.Moment | null>(null);

    useEffect(() => {
        if (props.open) setFollowUpDate(moment());
    }, [props.open]);

    const initialUserId = useMemo(() => {
        const jwt = getJwtPayload();
        if (jwt) return +jwt.UserID;
        return 0;
    }, []);

    const [userId, setUserId] = useState(initialUserId);

    const onUserChange = (u: NameIdObj | null) => {
        if (u) setUserId(u.id);
    }

    const continueCallback = useCallback(() => {
        if (followUpDate) {
            let formattedDate = '';
            if (props.dateType === 'datetime-local') formattedDate = followUpDate.format('YYYY-MM-DDTHH:mm');
            else formattedDate = followUpDate.format('YYYY-MM-DD');
            props.onContinue(formattedDate, userId);
        }
    }, [followUpDate, props, userId]);

    return (
        <Dialog open={props.open} maxWidth={props.maxWidth} fullWidth={props.fullWidth} hideBackdrop={props.hideBackdrop} >
            <DialogTitle>{props.title}</DialogTitle>
            <DialogContent dividers={props.dividers}>
                {props.message && <DialogContentText>{props.message}</DialogContentText> }
                <div style={{ marginTop: '10px' }}>
                    <Stack spacing={2}>
                        {props.dateType === 'date' &&
                            <DatePicker value={followUpDate} onChange={setFollowUpDate} slotProps={{actionBar: { actions: ["clear", "today", "cancel", "accept"] }}} />
                        }
                        {props.dateType === 'datetime-local' &&
                            <DateTimePicker value={followUpDate} onChange={setFollowUpDate}                                     slotProps={{
                                actionBar: { actions: ["clear", "today", "cancel", "accept"] },
                            }}/>
                        }
                        <UserPicker
                            label={ props.userLabel }
                            userId={ userId }
                            onSelect={ onUserChange }
                            disableClearable={props.disableUserClearable}
                        />
                    </Stack>
                </div>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="error" onClick={ props.onClose } >
                    { props.cancelActionText ? props.cancelActionText : 'Cancel' }
                </Button>
                <Button variant="contained" color="success" onClick={ continueCallback } >
                    { props.confirmActionText ? props.confirmActionText : 'Continue' }
                </Button>
            </DialogActions>
        </Dialog>
    );
}