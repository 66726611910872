import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";
import { PreviewEntityType } from "common/models/Previews/Previews";

interface Props {
    address1: string,
    address2: string,
    suburb: string,
    state: string,
    postcode: string,
    countryName: string,
    disableTextSelection?: boolean,
    openPreviewHandler?: (type: PreviewEntityType, recordId: number) => void,
    closePreviewHandler?: () => void
}

const labelWidth = "35%";
const valueWidth = "65%";
const fontSize = "1.2rem";

export default function ViewCandidateLocationElement({ address1, address2, suburb, state, postcode, countryName, disableTextSelection = false }: Props) {
    return (
        <Box height="100%" display="flex" flexDirection="column" overflow="auto" px="5px">
            <Typography variant="h6" fontWeight={600} component="div" pt="8px">Address</Typography>
            <Box display="flex" minHeight="40px">
                <Typography display="flex" alignItems="center" width={labelWidth} fontSize={fontSize} variant="body1" fontWeight={600} component="div" sx={ disableTextSelection ? undefined : {userSelect: 'text' }}>Address 1</Typography>
                <Typography display="flex" alignItems="center" width={valueWidth} fontSize={fontSize} variant="body1" component="div" sx={ disableTextSelection ? undefined : { userSelect: 'text' }}>{address1}</Typography>
            </Box>
            <Box display="flex" minHeight="40px">
                <Typography display="flex" alignItems="center" width={labelWidth} fontSize={fontSize} variant="body1" fontWeight={600} component="div" sx={ disableTextSelection ? undefined : {userSelect: 'text' }}>Address 2</Typography>
                <Typography display="flex" alignItems="center" width={valueWidth} fontSize={fontSize} variant="body1" component="div" sx={ disableTextSelection ? undefined : { userSelect: 'text' }}>{address2}</Typography>
            </Box>
            <Box display="flex" minHeight="40px">
                <Typography display="flex" alignItems="center" width={labelWidth} fontSize={fontSize} variant="body1" fontWeight={600} component="div" sx={ disableTextSelection ? undefined : {userSelect: 'text' }}>Suburb</Typography>
                <Typography display="flex" alignItems="center" width={valueWidth} fontSize={fontSize} variant="body1" component="div" sx={ disableTextSelection ? undefined : { userSelect: 'text' }}>{suburb}</Typography>
            </Box>
            <Box display="flex" minHeight="40px">
                <Typography display="flex" alignItems="center" width={labelWidth} fontSize={fontSize} variant="body1" fontWeight={600} component="div" sx={ disableTextSelection ? undefined : {userSelect: 'text' }}>State</Typography>
                <Typography display="flex" alignItems="center" width={valueWidth} fontSize={fontSize} variant="body1" component="div" sx={ disableTextSelection ? undefined : { userSelect: 'text' }}>{state}</Typography>
            </Box>
            <Box display="flex" minHeight="40px">
                <Typography display="flex" alignItems="center" width={labelWidth} fontSize={fontSize} variant="body1" fontWeight={600} component="div" sx={ disableTextSelection ? undefined : {userSelect: 'text' }}>Postcode</Typography>
                <Typography display="flex" alignItems="center" width={valueWidth} fontSize={fontSize} variant="body1" component="div" sx={ disableTextSelection ? undefined : { userSelect: 'text' }}>{postcode}</Typography>
            </Box>
            <Box display="flex" minHeight="40px">
                <Typography display="flex" alignItems="center" width={labelWidth} fontSize={fontSize} variant="body1" fontWeight={600} component="div" sx={ disableTextSelection ? undefined : {userSelect: 'text' }}>Country</Typography>
                <Typography display="flex" alignItems="center" width={valueWidth} fontSize={fontSize} variant="body1" component="div" sx={ disableTextSelection ? undefined : { userSelect: 'text' }}>{countryName}</Typography>
            </Box>
        </Box>
    );
}