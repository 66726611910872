import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { useTheme } from "@mui/material/styles";
import { DashboardLayoutComponent } from "@syncfusion/ej2-react-layouts/src/dashboard-layout/dashboardlayout.component";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Advert } from "common/models/Advert";
import { AdvertRecordDashboardElementDefinition, AdvertRecordDashboardElementType } from "common/models/Dashboard/EditLayout";
import { Job } from "common/models/Jobs/Job";
import { PreviewEntityType } from "common/models/Previews/Previews";
import { GetJobById } from "services/JobsService";
import { AdvertScreenLayoutSettings, DefaultAdvertRecordDashboardElements } from "util/Definitions/ScreenLayouts/Advert";
import PanelWrapper from "components/Dashboards/PanelWrapper";
import PreviewLoaderComponent from "components/Previews/PreviewLoader";
import SingleFieldElement from "../SingleFieldElement";

interface Props {
    advert: Advert | null,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
}

const { unitWidth, unitHeight, gapX, gapY, columns, mediaQueryMaxWidth } = AdvertScreenLayoutSettings;
const cellSpacing = [gapX, gapY];

export default function ViewRecordScreenLayout({ advert, loadingHandler, errorHandler }: Props) {
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    const [previewType, setPreviewType] = useState<PreviewEntityType | ''>('');
    const [previewRecordId, setPreviewRecordId] = useState(0);
    const [screenResizedControl, setScreenResizedControl] = useState(false);
    const [fetchingSavedState, setFetchingSavedState] = useState(false);
    const [fetchedSavedState, setFetchedSavedState] = useState(false);
    const [advertJob, setAdvertJob] = useState<Job>();
    const layoutRef = useRef<DashboardLayoutComponent | null>(null);
    const theme = useTheme();

    useEffect(() => {
        const windowResizeHandler = () => setScreenResizedControl(prev => !prev);
        window.addEventListener('resize', windowResizeHandler);
        return () => window.removeEventListener('resize', windowResizeHandler);
    }, []);

    useEffect(() => {
        const getJob = async () => {
            if (advert && advert.jobID) {
                const res = await GetJobById(advert.jobID);
                if (res) setAdvertJob(res);
            }
        };
        if (advert && advert.jobID) getJob();
    }, [advert]);

    useEffect(() => {
        loadingHandler && loadingHandler(fetchingSavedState);
    }, [loadingHandler, fetchingSavedState]);

    useEffect(() => {
        const getSavedState = async () => {
            setFetchingSavedState(true);
            // const elementsJson = await GetCustomerSettingBySettingName('AdvertRecordDashboardLayoutElements');
            // if (elementsJson) {
            //     const panels = JSON.parse(elementsJson) as PanelModel[];
            //     setLastSavedState(panels);
            // }
            setFetchingSavedState(false);
            setFetchedSavedState(true);
        };
        getSavedState();
    }, []);

    const elements = useMemo<AdvertRecordDashboardElementDefinition[]>(() => {
        return DefaultAdvertRecordDashboardElements;
    }, []);

    const openPreviewHandler = useCallback((type: PreviewEntityType, recordId: number) => {
        setPreviewType(type);
        setPreviewRecordId(recordId);
        setIsPreviewOpen(true);
    }, []);

    const closePreviewHandler = useCallback(() => {
        setIsPreviewOpen(false);
    }, []);

    const renderElement = useCallback((id: string, type: AdvertRecordDashboardElementType) => {
        if (advert) {
            switch (type) {
                case 'Divider': return <Box pt="20px"><Divider component="div" /></Box>;
                case 'Spacer': return <></>;
                case 'AdvertId': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Advert ID" fieldValue={advert.id.toString()} />;
                case 'AdvertPublicId': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Public Advert ID" fieldValue={advert.publicAdvertID} />;
                case 'AdvertJobId': return (
                    <SingleFieldElement
                        fieldTitle='Job ID'
                        fieldValue={advert.jobID.toString() ?? ''}
                        format="internal-link"
                        href={`/jobs/${advert.jobID}`}
                        previewType="job"
                        previewRecordId={advert.jobID}
                        openPreviewHandler={openPreviewHandler}
                        closePreviewHandler={closePreviewHandler}
                    />);
                case 'AdvertScreeningQuestionnaire': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Questionnaire" fieldValue={advert.screeningQuestionnaireTemplate ?? 'None'} />;
                case 'AdvertPostingDate': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Posting Date" fieldValue={advert.postingDate} format="datetime" />;
                case 'AdvertExpiryDate': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Expiry Date" fieldValue={advert.expiryDate} format="datetime" />;
                case 'AdvertExpiredBy': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Expired By" fieldValue={advert.expiredByName} />;
                case 'AdvertApplicants': return (
                    <SingleFieldElement
                        useEllipsisForLongValues
                        fieldTitle="# Applicants"
                        fieldValue={advert.numApplicants.toString()}
                        format="internal-link"
                        href={`/jobs/${advert.jobID}/candidates?advertId=${advert.id}`}
                    />);
                case 'AdvertClient': 
                    const clientName = advertJob ? advertJob.clientName : '';
                    const clientId = advertJob ? advertJob.clientID : 0;
                return (
                    <SingleFieldElement
                        fieldTitle='Client'
                        fieldValue={clientName}
                        format="internal-link"
                        href={`/clients/${clientId}`}
                        previewType="client"
                        previewRecordId={clientId}
                        openPreviewHandler={openPreviewHandler}
                        closePreviewHandler={closePreviewHandler}
                    />);
                case 'AdvertConsultant': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Consultant" fieldValue={advert.consultantName} />;
                case 'AdvertCreatedBy': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Created By" fieldValue={advert.createdByName} />;
                case 'AdvertCreatedDate': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Created Date" fieldValue={advert.createdDate} format="datetime" />;
                default: return <div>{id}</div>;
            }
        }
    }, [advert, advertJob, openPreviewHandler, closePreviewHandler]);

    const layoutResizeStopHandler = useCallback(() => {
        setScreenResizedControl(prev => !prev);
    }, []);

    useEffect(() => {
        if (screenResizedControl) {}
        const api = layoutRef.current;
        if (api && fetchedSavedState) {
            const refreshTimeout = setTimeout(() => {
                api.refresh();
                api.refresh();
            }, 250);
            return () => clearTimeout(refreshTimeout);
        }
    }, [fetchedSavedState, screenResizedControl]);

    const renderLayout = useCallback(() => {
        if (!fetchedSavedState) return <></>;

        const mediaQuery = mediaQueryMaxWidth ? `max-width: ${mediaQueryMaxWidth}` : undefined;
        return (
            <DashboardLayoutComponent
                cellSpacing={cellSpacing}
                columns={columns}
                cellAspectRatio={30 / 2}
                resizeStop={ layoutResizeStopHandler }
                allowDragging={false}
                allowResizing={false}
                ref={l => layoutRef.current = l}
                mediaQuery={mediaQuery}
            >
                {elements.map(e => (
                    <PanelWrapper
                        key={e.id}
                        id={e.id}
                        col={e.col}
                        row={e.row}
                        sizeX={e.sizeX}
                        sizeY={e.sizeY}
                        minSizeX={e.minSizeX}
                        minSizeY={e.minSizeY}
                        maxSizeX={e.maxSizeX}
                        maxSizeY={e.maxSizeY}
                        resizeControl={screenResizedControl}
                        resizeIconColor={theme.palette.text.disabled}
                        gapX={gapX}
                        gapY={gapY}
                        unitWidth={unitWidth}
                        unitHeight={unitHeight}
                    >
                        {renderElement(e.id, e.type)}
                    </PanelWrapper>
                ))}
            </DashboardLayoutComponent>
        );
    }, [elements, fetchedSavedState, theme.palette.text.disabled, screenResizedControl, layoutResizeStopHandler, renderElement]);
    
    return (
        <>
            <PreviewLoaderComponent
                open={isPreviewOpen}
                entityType={previewType}
                recordId={previewRecordId}
            />
            <Box p="10px" height="100%">
                <div className="control-section">
                    { renderLayout() }
                </div>
            </Box>
        </>
    );
}