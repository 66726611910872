import React, { useCallback, useEffect, useState } from "react";
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { DownloadCandidateResumeForViewer } from "services/CandidatesService";
import Box from "@mui/material/Box";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import { GetSettingBySettingName, UpdateSettingBySettingName } from "services/UsersService";
import { RegexIsPositiveNumberWith2Decimals } from "util/RegExUtils";

interface Props {
    candidateId: number,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
}

const settingName = "DocumentScaleRatio";

export default function CandidateResumeRenderer({ candidateId, loadingHandler, errorHandler }: Props) {
    const [fileData, setFileData] = useState('');
    const [numPages, setNumPages] = useState<number | null>(null);
    const [loadingMessage, setLoadingMessage] = useState<string>();
    const [scale, setScale] = useState(2);

    useEffect(() => {
        const getDocScaleSetting = async () => {
            const res = await GetSettingBySettingName(settingName);
            if (res && RegexIsPositiveNumberWith2Decimals(res)) setScale(+res);
        };
    getDocScaleSetting();
    }, []);

    const onScaleChangeHandler = useCallback((value: number) => {
        setScale(prev => {
            let tmp = prev + value;
            if (tmp <= 0) return prev;
            UpdateSettingBySettingName(settingName, tmp.toFixed(2).toString());
            return tmp;
        });
    }, []);

    const onZoomOut = useCallback(() => {
        onScaleChangeHandler(-0.2)
    }, [onScaleChangeHandler]);

    const onZoomIn = useCallback(() => {
        onScaleChangeHandler(0.2)
    }, [onScaleChangeHandler]);

    useEffect(() => {
        const getFile = async () => {
            loadingHandler && loadingHandler(true);
            setLoadingMessage(undefined);
            setFileData('');
            const res = await DownloadCandidateResumeForViewer(candidateId, errorHandler);
            if (res) {
                let reader = new FileReader();
                reader.readAsDataURL(res.data);
                reader.onloadend = () => {
                    const r = reader.result as string;
                    setFileData(r.substring(r.indexOf(',') + 1));
                };
            }
            else setLoadingMessage('No Resume Preview Found');
            loadingHandler && loadingHandler(false);
        };
        candidateId && getFile();
    }, [candidateId, loadingHandler, errorHandler]);

    return (
        <>
            <Box display="flex" flex="1" flexDirection="column" position="relative">
                <Box ml="auto">
                    <Box component="span" display={loadingMessage ? "none" : "flex"}>
                        <Avatar
                            onClick={ onZoomOut }
                            sx={{ mr: '5px', cursor: 'pointer', bgcolor: t => t.palette.secondary.main }}
                            title="Zoom Out"
                        >
                            <RemoveIcon />
                        </Avatar>
                        <Avatar
                            onClick={ onZoomIn }
                            sx={{ mr: '5px', cursor: 'pointer', bgcolor: t => t.palette.secondary.main }}
                            title="Zoom In"
                        >
                            <AddIcon />
                        </Avatar>
                    </Box>
                </Box>
                <Box display="flex" flex="1" flexDirection="column" position="relative" overflow="hidden">
                    <Box position="absolute" top="0" left="0" height="100%" width="100%" overflow="auto">
                        <Box display="flex" justifyContent="center">
                            <Document
                                file={`data:application/pdf;base64,${fileData}`}
                                onLoadSuccess={p => setNumPages(p.numPages)}
                                loading={loadingMessage}
                            >
                                <Stack spacing={2}>
                                    {Array.apply(null, Array(numPages))
                                    .map((x, i)=>i+1)
                                    .map(page => <Page key={page} pageNumber={page} scale={scale}/>)}
                                </Stack>
                            </Document>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    );
}