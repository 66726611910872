import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { SxProps } from "@mui/material/styles";
import { GridColumnGroupingModel } from "@mui/x-data-grid-premium";
import { GridColDef } from "@mui/x-data-grid/models/colDef";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { FilterWithTimeRange } from "common/models/Analytics/AnalyticsFilterData";
import { KpiDashboard } from "common/models/Analytics/BusinessIntelligence";
import { NameIdObj } from "common/models/GenericTypes";
import MultipleDivisionsPicker from "components/Pickers/MultipleDivisionPicker";
import MultipleGroupPicker from "components/Pickers/MultipleGroupPicker";
import MultipleUserPicker from "components/Pickers/MultipleUserPicker";
import PageContentLayout from "layouts/PageContentLayout";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { GetKpiDashboardData } from "services/BusinessIntelligence";
import ShareIcon from '@mui/icons-material/Share';
import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import Avatar from "@mui/material/Avatar";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useNavigate } from "react-router";
import GridWithStateWrapper from "components/GridWidthStateWrapper";
import { GetCustomerSettingBySettingName } from "services/ConfigurationService";
import { GetDatesFromTimeRangeFilter } from "util/DateUtils";
import PageLayout from "../../../layouts/PageLayout";
import OnlyTitleSummaryBar from "../../../components/SummaryBars/OnlyTitleSummaryBar";

interface Props {
    contentPaddingHandler?: (hasPadding: boolean) => void,
}

interface KpiDashboardWithId extends KpiDashboard {
    id: number,
}

const filterElementStyle: SxProps = { flex: '1 1 0' };
const defaultTimeRange = 4;
const gridName = 'analytics/kpi-dashboard';

export default function KPIDashboardPage() {
    const [summaryBar, setSummaryBar] = useState(<></>);
    const [isLoading, setIsLoading] = useState(false);
    const [sourceType, setSourceType] = useState(1);
    const [timeRange, setTimeRange] = useState(defaultTimeRange);
    const [startDate, setStartDate] = useState<moment.Moment | null>(null);
    const [endDate, setEndDate] = useState<moment.Moment | null>(null);
    const [showGrid, setShowGrid] = useState(false);
    const [rows, setRows] = useState<KpiDashboardWithId[]>([]);
    const [selectedDivisions, setSelectedDivisions] = useState<number[]>([]);
    const [selectedGroups, setSelectedGroups] = useState<number[]>([]);
    const [selectedUsers, setSelectedUsers] = useState<number[]>([]);
    const [infoMessage, setInfoMessage] = useState('');
    const [shouldRunReport, setShouldRunReport] = useState(false);
    const [isInitialSetupFinished, setIsInitialSetupFinished] = useState(false);
    const [isInitialQuerySetupFinished, setIsInitialQuerySetupFinished] = useState(false);
    const [currentFilterData, setCurrentFilterData] = useState<FilterWithTimeRange | null>(null);
    const [financialYearStartMonth, setFinancialYearStartMonth] = useState(1);
    const navigate = useNavigate();

    useEffect(() => {
        const getYearStart = async () => {
            const res = await GetCustomerSettingBySettingName('YearStart');
            if (res) {
                const parsed = +res;
                if (parsed >= 1 && parsed <= 12) setFinancialYearStartMonth(parsed);
            }
            setIsInitialSetupFinished(true);
        };
        getYearStart();
    }, []);

    useEffect(() => {
        if (isInitialSetupFinished) {
            const urlParams = new URLSearchParams(window.location.search);
            const st = urlParams.get('sourceType');
            const sid = urlParams.get('sourceIds');
            const tr = urlParams.get('timeRange');
            const sd = urlParams.get('startDate');
            const ed = urlParams.get('endDate');
            let parsedSt = 1;
    
            if (st) {
                const p = Number(st);
                if (p >= 1 && p <= 4) {
                    parsedSt = p;
                    setSourceType(p);
                }
            }
            if (sid) {
                const ids: number[] = sid.split(',').map(n => Number(n));
                if (parsedSt === 2) setSelectedDivisions(ids);
                else if (parsedSt === 3) setSelectedGroups(ids);
                else if (parsedSt === 4) setSelectedUsers(ids);
            }
            if (tr && tr !== '0') {
                const p = Number(tr);
                if (p >=1 && p <= 16) {
                    const dates = GetDatesFromTimeRangeFilter(p, financialYearStartMonth);
                    if (dates) {
                        const [start, end] = dates;
                        setTimeRange(p);
                        setStartDate(start);
                        setEndDate(end);
                    }
                }
            }
            else if (tr === '0') {
                if (sd) {
                    const m = moment(sd);
                    if (m.isValid()) setStartDate(m);
                }
                if (ed) {
                    const m = moment(ed);
                    if (m.isValid()) setEndDate(m);
                }
                if (ed || sd) setTimeRange(0);
            }
            else {
                const dates = GetDatesFromTimeRangeFilter(defaultTimeRange, financialYearStartMonth);
                if (dates) {
                    const [start, end] = dates;
                    setStartDate(start);
                    setEndDate(end);
                }
            }
            setShouldRunReport(true);
            setIsInitialQuerySetupFinished(true);
        }
    }, [financialYearStartMonth, isInitialSetupFinished]);

    const sourceIds = useMemo(() => {
        let sourceIds = '';
        if (sourceType === 2) sourceIds = selectedDivisions.join(',');
        else if (sourceType === 3) sourceIds = selectedGroups.join(',');
        else if (sourceType === 4) sourceIds = selectedUsers.join(',');
        return sourceIds;
    }, [selectedDivisions, selectedGroups, selectedUsers, sourceType]);

    const generatQueryString = useCallback(() => {
        const sd = timeRange === 0 && startDate ? startDate.format('YYYY-MM-DD') : '';
        const ed = timeRange === 0 && endDate ? endDate.format('YYYY-MM-DD') : '';
        if (sourceType === 1) return timeRange === 0 ? `?sourceType=${sourceType}&startDate=${sd}&endDate=${ed}` : `?sourceType=${sourceType}&timeRange=${timeRange}`;
        else {
            return timeRange === 0 ? `?sourceType=${sourceType}&startDate=${sd}&endDate=${ed}&sourceIds=${encodeURIComponent(sourceIds)}` : `?sourceType=${sourceType}&timeRange=${timeRange}&sourceIds=${encodeURIComponent(sourceIds)}`;
        }
    }, [endDate, sourceIds, sourceType, startDate, timeRange]);

    const shareActionCallback = useCallback(() => {
        let url = window.location.protocol + '//' + window.location.host + window.location.pathname + generatQueryString();
        navigator.clipboard.writeText(url);
        setInfoMessage('Link copied to clipboard');
    }, [generatQueryString]);

    useEffect(() => {
        if (isInitialQuerySetupFinished) {
            let url = window.location.pathname + generatQueryString();
            navigate(url);
        }
    }, [generatQueryString, isInitialQuerySetupFinished, navigate]);

    useEffect(() => {
        const action = (
            <Avatar
                key="splitsAction"
                onClick={shareActionCallback}
                title="Copy link to clipboard"
                sx={{ bgcolor: t => t.palette.primary.main, mr: '5px', cursor: 'pointer' }}
            >
                <ShareIcon />
            </Avatar>
        );
        const summaryBar = <TitleAndActionSummaryBar title="Analytics > Dashboards > KPI Dashboard" browserTabTitle="KPI Dashboard > Dashboards > Analytics" action={action} />
        setSummaryBar && setSummaryBar(summaryBar);
    }, [shareActionCallback]);

    useEffect(() => {
        if (isInitialQuerySetupFinished) {
            const dates = GetDatesFromTimeRangeFilter(timeRange, financialYearStartMonth);
            if (dates) {
                const [start, end] = dates;
                setStartDate(start);
                setEndDate(end);
            }
        }
    }, [financialYearStartMonth, isInitialQuerySetupFinished, timeRange]);

    const getDataCallback = useCallback(async () => {
        const f: FilterWithTimeRange = {
            endDate: endDate ? endDate.format('YYYY-MM-DD') : '',
            startDate: startDate ? startDate.format('YYYY-MM-DD') : '',
            userFilterID: sourceIds,
            userFilterTypeID: sourceType
        };

        setCurrentFilterData(f);

        setIsLoading(true);
        const res = await GetKpiDashboardData(f);
        if (res) {
            const data: KpiDashboardWithId[] = res.map((r, i) => ({ ...r, id: i }));
            setRows(data);
        }
        setShowGrid(true);
        setIsLoading(false);
    }, [endDate, sourceIds, sourceType, startDate]);

    useEffect(() => {
        if (shouldRunReport) {
            getDataCallback();
            setShouldRunReport(false);
        }
    }, [getDataCallback, shouldRunReport]);

    const columns = useMemo<GridColDef[]>(() => {
        return [
            { field: 'consultant', headerName: 'Consultant', width: 250 },
            { field: 'bdCalls_Actual', headerName: 'Actual', headerAlign: 'center', align: 'right' },
            { field: 'bdCalls_Target', headerName: 'Target', headerAlign: 'center', align: 'right' },
            { field: 'bdCalls_Variance', headerName: 'Variance', headerAlign: 'center', align: 'right' },
            { field: 'clientVisits_Actual', headerName: 'Actual', headerAlign: 'center', align: 'right' },
            { field: 'clientVisits_Target', headerName: 'Target', headerAlign: 'center', align: 'right' },
            { field: 'clientVisits_Variance', headerName: 'Variance', headerAlign: 'center', align: 'right' },
            { field: 'newJobs_Actual', headerName: 'Actual', headerAlign: 'center', align: 'right' },
            { field: 'newJobs_Target', headerName: 'Target', headerAlign: 'center', align: 'right' },
            { field: 'newJobs_Variance', headerName: 'Variance', headerAlign: 'center', align: 'right' },
            { field: 'internalInterviews_Actual', headerName: 'Actual', headerAlign: 'center', align: 'right' },
            { field: 'internalInterviews_Target', headerName: 'Target', headerAlign: 'center', align: 'right' },
            { field: 'internalInterviews_Variance', headerName: 'Variance', headerAlign: 'center', align: 'right' },
            { field: 'floats_Actual', headerName: 'Actual', headerAlign: 'center', align: 'right' },
            { field: 'floats_Target', headerName: 'Target', headerAlign: 'center', align: 'right' },
            { field: 'floats_Variance', headerName: 'Variance', headerAlign: 'center', align: 'right' },
            { field: 'submissions_Actual', headerName: 'Actual', headerAlign: 'center', align: 'right' },
            { field: 'submissions_Target', headerName: 'Target', headerAlign: 'center', align: 'right' },
            { field: 'submissions_Variance', headerName: 'Variance', headerAlign: 'center', align: 'right' },
            { field: 'firstInterview_Actual', headerName: 'Actual', headerAlign: 'center', align: 'right' },
            { field: 'firstInterview_Target', headerName: 'Target', headerAlign: 'center', align: 'right' },
            { field: 'firstInterview_Variance', headerName: 'Variance', headerAlign: 'center', align: 'right' },
            { field: 'secondInterview_Actual', headerName: 'Actual', headerAlign: 'center', align: 'right' },
            { field: 'secondInterview_Target', headerName: 'Target', headerAlign: 'center', align: 'right' },
            { field: 'secondInterview_Variance', headerName: 'Variance', headerAlign: 'center', align: 'right' },
            { field: 'thirdInterview_Actual', headerName: 'Actual', headerAlign: 'center', align: 'right' },
            { field: 'thirdInterview_Target', headerName: 'Target', headerAlign: 'center', align: 'right' },
            { field: 'thirdInterview_Variance', headerName: 'Variance', headerAlign: 'center', align: 'right' },
            { field: 'placements_Actual', headerName: 'Actual', headerAlign: 'center', align: 'right' },
            { field: 'placements_Target', headerName: 'Target', headerAlign: 'center', align: 'right' },
            { field: 'placements_Variance', headerName: 'Variance', headerAlign: 'center', align: 'right' },
        ];
    }, []);

    const columnGroups = useMemo<GridColumnGroupingModel>(() => {
        return [
            { groupId: 'bd-calls', headerName: 'BD Calls', headerAlign: 'center', children: [{field: 'bdCalls_Actual'}, {field: 'bdCalls_Target'}, {field: 'bdCalls_Variance'}] },
            { groupId: 'client-visits', headerName: 'Client Visits', headerAlign: 'center', children: [{field: 'clientVisits_Actual'}, {field: 'clientVisits_Target'}, {field: 'clientVisits_Variance'}] },
            { groupId: 'new-jobs', headerName: 'New Jobs', headerAlign: 'center', children: [{field: 'newJobs_Actual'}, {field: 'newJobs_Target'}, {field: 'newJobs_Variance'}] },
            { groupId: 'internal-interviews', headerName: 'Internal Interviews', headerAlign: 'center', children: [{field: 'internalInterviews_Actual'}, {field: 'internalInterviews_Target'}, {field: 'internalInterviews_Variance'}] },
            { groupId: 'floats', headerName: 'Floats', headerAlign: 'center', children: [{field: 'floats_Actual'}, {field: 'floats_Target'}, {field: 'floats_Variance'}] },
            { groupId: 'submissions', headerName: 'Submissions', headerAlign: 'center', children: [{field: 'submissions_Actual'}, {field: 'submissions_Target'}, {field: 'submissions_Variance'}] },
            { groupId: 'first-interview', headerName: 'First Interview', headerAlign: 'center', children: [{field: 'firstInterview_Actual'}, {field: 'firstInterview_Target'}, {field: 'firstInterview_Variance'}] },
            { groupId: 'second-interview', headerName: 'Second Interview', headerAlign: 'center', children: [{field: 'secondInterview_Actual'}, {field: 'secondInterview_Target'}, {field: 'secondInterview_Variance'}] },
            { groupId: 'third-interview', headerName: 'Third Interview', headerAlign: 'center', children: [{field: 'thirdInterview_Actual'}, {field: 'thirdInterview_Target'}, {field: 'thirdInterview_Variance'}] },
            { groupId: 'placements', headerName: 'Placements', headerAlign: 'center', children: [{field: 'placements_Actual'}, {field: 'placements_Target'}, {field: 'placements_Variance'}] },
        ];
    }, []);

    const onTimeRangeChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const v = +e.target.value;
        setTimeRange(v);
    }, []);

    const onSourceTypeChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const v = +e.target.value;
        setSourceType(v);
    }, []);

    const onDivisionsChange = useCallback((divisions: NameIdObj[]) => {
        setSelectedDivisions(divisions.map(u => u.id));
    }, []);

    const onGroupsChange = useCallback((groups: NameIdObj[]) => {
        setSelectedGroups(groups.map(u => u.id));
    }, []);

    const onUsersChange = useCallback((users: NameIdObj[]) => {
        setSelectedUsers(users.map(u => u.id));
    }, []);

    const isRunButtonEnabled = useMemo(() => {
        if (currentFilterData) {
            let sDate = startDate ? startDate.format('YYYY-MM-DD') : '';
            let eDate = endDate ? endDate.format('YYYY-MM-DD') : '';
            return currentFilterData.startDate !== sDate || currentFilterData.endDate !== eDate || currentFilterData.userFilterID !== sourceIds || currentFilterData.userFilterTypeID !== sourceType;
        }
        return true;
    }, [currentFilterData, endDate, sourceIds, sourceType, startDate]);

    return (
        <PageLayout SummaryBar={summaryBar}>
            <PageContentLayout showLoading={isLoading} >
                <Snackbar open={Boolean(infoMessage)} autoHideDuration={1500} onClose={() => setInfoMessage('')}>
                    <Alert severity="info" onClose={() => setInfoMessage('')}>{infoMessage}</Alert>
                </Snackbar>
                <Box display="flex" gap={1}>
                    <TextField select label="Source Type" value={sourceType.toString()} onChange={onSourceTypeChange} sx={filterElementStyle} >
                        <MenuItem value="1">Company</MenuItem>
                        <MenuItem value="2">Division</MenuItem>
                        <MenuItem value="3">Group</MenuItem>
                        <MenuItem value="4">User</MenuItem>
                    </TextField>
                    <TextField select label="Time Range" value={timeRange.toString()} onChange={onTimeRangeChange} sx={filterElementStyle} >
                        <MenuItem value="0">Custom Range</MenuItem>
                        <MenuItem value="1">This Week</MenuItem>
                        <MenuItem value="2">This Month</MenuItem>
                        <MenuItem value="3">This Quarter (Calendar)</MenuItem>
                        <MenuItem value="13">This Quarter (Financial)</MenuItem>
                        <MenuItem value="4">This Year (Calendar)</MenuItem>
                        <MenuItem value="14">This Year (Financial)</MenuItem>
                        <MenuItem value="5">Last Week</MenuItem>
                        <MenuItem value="6">Last Month</MenuItem>
                        <MenuItem value="7">Last Quarter (Calendar)</MenuItem>
                        <MenuItem value="15">Last Quarter (Financial)</MenuItem>
                        <MenuItem value="8">Last Year (Calendar)</MenuItem>
                        <MenuItem value="16">Last Year (Financial)</MenuItem>
                        <MenuItem value="9">Last 7 Days</MenuItem>
                        <MenuItem value="10">Last 30 Days</MenuItem>
                        <MenuItem value="11">Last 90 Days</MenuItem>
                        <MenuItem value="12">Last 365 Days</MenuItem>
                    </TextField>
                    <DatePicker label="From Date" value={startDate} onChange={m => setStartDate(m)} disabled={timeRange !== 0} sx={filterElementStyle} slotProps={{actionBar: { actions: ["clear", "today", "cancel", "accept"] }}} />
                    <DatePicker label="To Date" value={endDate} onChange={m => setEndDate(m)} disabled={timeRange !== 0} sx={filterElementStyle} slotProps={{actionBar: { actions: ["clear", "today", "cancel", "accept"] }}} />
                    <Button variant="contained" color="success" onClick={getDataCallback} disabled={!isRunButtonEnabled}>Run Report</Button>
                </Box>
                <Box display={sourceType === 2 ? undefined : 'none'} mt={2}>
                    <MultipleDivisionsPicker value={selectedDivisions} onSelect={onDivisionsChange} />
                </Box>
                <Box display={sourceType === 3 ? undefined : 'none'} mt={2}>
                    <MultipleGroupPicker value={selectedGroups} onSelect={onGroupsChange} />
                </Box>
                <Box display={sourceType === 4 ? undefined : 'none'} mt={2}>
                    <MultipleUserPicker value={selectedUsers} onSelect={onUsersChange} />
                </Box>
                {showGrid &&
                    <GridWithStateWrapper
                        gridName={gridName}
                        rows={rows}
                        density="compact"
                        columns={columns}
                        columnGroupingModel={columnGroups}
                        initialState={{
                            pinnedColumns: {
                                left: ['consultant'],
                            },
                        }}
                    />
                }
            </PageContentLayout>
        </PageLayout>
    );
}