import React from "react";
import { Link } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

import WorkIcon from '@mui/icons-material/Work';
import RssFeedIcon from '@mui/icons-material/RssFeed';
import PersonIcon from '@mui/icons-material/Person';
import BadgeIcon from '@mui/icons-material/Badge';
import DomainIcon from '@mui/icons-material/Domain';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import LanguageIcon from '@mui/icons-material/Language';

import { IGlobalLookupRecord } from '../common/models/SearchDefinitions';

interface Props {
    record: IGlobalLookupRecord
}

const recordLink = (entityId: number, recordId: number): string => {
    switch (entityId) {
        case 1: // Client
            return `/clients/${recordId}`;
        case 2: // Contact
            return `/contacts/${recordId}`;
        case 3: // Candidate
            return `/candidates/${recordId}`;
        case 4: // Job
            return `/jobs/${recordId}`;
        case 5: // Placement
            return `/placements/${recordId}`;
        case 8: // Opportunity
            return `/opportunities/${recordId}`;
        case 9: // Site
            return `/sites/${recordId}`;
        case 15: // Advert
            return `/adverts/${recordId}`;
        default:
            return '';
    }
}

const recordIcon = (entityId: number): JSX.Element => {
    switch (entityId) {
        case 1:
            return <DomainIcon style={{ color: "white" }} />;
        case 2:
            return <BadgeIcon style={{ color: "white" }} />;
        case 3:
            return <PersonIcon style={{ color: "white" }} />;
        case 4:
            return <WorkIcon style={{ color: "white" }} />;
        case 5:
            return <CreditCardIcon style={{ color: "white" }} />;
        case 8:
            return <ShowChartIcon style={{ color: "white" }} />;
        case 9:
            return <LanguageIcon style={{ color: "white" }} />;
        case 15:
            return <RssFeedIcon style={{ color: "white" }} />;
        default:
            return <></>;
    }
}

const primaryText = ({ recordType, jobTitle, recordName }: IGlobalLookupRecord): string => {
    switch (recordType) {
        case 1: // Client
            return recordName + ' - Client';
        case 2: // Contact
            return recordName + ' - Contact';
        case 3: // Candidate
            return recordName + ' - Candidate';
        case 4: // Job
            return recordName + ' - Job';
        case 5: // Placement
            return recordName + ' - Placement';
        case 8: // Opportunity
            return recordName + ' - Opportunity';
        case 9: // Site
            return recordName + ' - Site';
        case 15: // Advert
            return recordName + ' - Advert';
        default:
            return recordName;
    }
}

const secondaryText = ({ recordType, statusName, jobTitle, employer, typeName, recordName }: IGlobalLookupRecord): JSX.Element => {
    let separator = '';
    let firstValue = '';
    let secondValue = '';
    let bJobTitle = Boolean(jobTitle);
    let bEmployer = Boolean(employer);
    let bStatusName = Boolean(statusName);
    let bTypeName = Boolean(typeName);
    let bRecordName = Boolean(recordName);
    let firstLine = '';
    let secondLine = '';
    switch (recordType) {
        case 1: // Client
            if (bStatusName) {
                return <>{ statusName }</>;
            }
            return <></>;
        case 2: // Contact
        case 3: // Candidate
            separator = (bJobTitle && bEmployer) ? ' @ ' : separator;
            firstValue = bJobTitle ? jobTitle : '';
            secondValue = bEmployer ? employer : '';
            return <>{ firstValue + separator + secondValue }</>;
        case 4: // Job
            separator = bEmployer && bStatusName ? ' - ' : separator;
            firstValue = bEmployer ? employer : '';
            secondValue = bStatusName ? statusName : '';
            firstLine = (firstValue === '' && secondValue === '') ? '' : (firstValue + separator + secondValue);
            secondLine = bTypeName ? typeName : '';
            if(firstLine === '' || secondLine === '')
                return <>{ firstLine + secondLine }</>;
            return <>{ firstLine }<br/>{ secondLine }</>;
        case 5: // Placement
            separator = bRecordName && bEmployer ? ' @ ' : separator;
            firstValue = bRecordName ? recordName : '';
            secondValue = bEmployer ? employer : '';
            firstLine = (firstValue === '' && secondValue === '') ? '' : (firstValue + separator + secondValue);
            secondLine = bTypeName ? typeName : '';
            if(firstLine === '' || secondLine === '')
                return <>{ firstLine + secondLine }</>;
            return <>{ firstLine }<br/>{ secondLine }</>;
        case 8: // Opportunity
        case 9: // Site
        case 15: // Advert
            separator = bEmployer && bStatusName ? ' - ': separator;
            firstValue = bEmployer ? employer : '';
            secondValue = bStatusName ? statusName : '';
            return <>{ firstValue + separator + secondValue }</>;
        default:
            return <></>;
    }
}

export default function ({ record }: Props) {
    const { recordType, recordId } = record;
    return (
        <ListItem disableGutters disablePadding>
            <Link to={recordLink(recordType, recordId)} target='_blank' style={{ color: 'inherit', flexBasis: '100%', textDecoration: 'none' }} >
                <ListItemButton>
                    <ListItemAvatar>
                        <Avatar sx={{ bgcolor: '#5E21D0' }} >
                            {recordIcon(recordType)}
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={primaryText(record)}
                        secondaryTypographyProps={{ component: "div" }}
                        secondary={ secondaryText(record) }
                    >
                    </ListItemText>
                </ListItemButton>
            </Link>
        </ListItem>
    );
}