import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import SlowTextFieldWrapper from "components/SlowTextFieldWrapper";
import React, { useCallback, useMemo } from "react";

interface Props {
    jobBoardId: number,
    advertId: number,
    attributes?: Record<string, string>,
    attributeChangeHandler?: (jobBoardId: number, key: string, value: string) => void
}

export default function IrishJobsEdit({ jobBoardId, advertId, attributes, attributeChangeHandler }: Props) {
    const atts = useMemo<Record<string, string>>(() => {
        if (attributes) return attributes;
        return {};
    }, [attributes]);

    const jobCategory = useMemo(() => atts.JobCategories ?? '', [atts.JobCategories]);
    const jobSalaryRange = useMemo(() => atts.JobSalaryRange ?? '-3', [atts.JobSalaryRange]);
    const jobType = useMemo(() => atts.JobType ?? '0', [atts.JobType]);
    const jobQues1 = useMemo(() => atts.JobQues1 ?? '', [atts.JobQues1]);
    const jobQues2 = useMemo(() => atts.JobQues2 ?? '', [atts.JobQues2]);
    const jobQues3 = useMemo(() => atts.JobQues3 ?? '', [atts.JobQues3]);
    const jobLocations = useMemo(() => atts.JobLocations ?? '', [atts.JobLocations]);
    const jobAddnlBens = useMemo(() => atts.JobAddnlBens ?? '', [atts.JobAddnlBens]);
    const jobMinExp = useMemo(() => atts.JobMinExp ?? '0', [atts.JobMinExp]);
    const jobMinQual = useMemo(() => atts.JobMinQual ?? '-1', [atts.JobMinQual]);

    const localChangeHandler = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        attributeChangeHandler && attributeChangeHandler(jobBoardId, name, value);
    }, [attributeChangeHandler, jobBoardId]);

    const question1ChangeHandler = useCallback((newValue: string) => {
        attributeChangeHandler && attributeChangeHandler(jobBoardId, 'JobQues1', newValue);
    }, [attributeChangeHandler, jobBoardId]);

    const question2ChangeHandler = useCallback((newValue: string) => {
        attributeChangeHandler && attributeChangeHandler(jobBoardId, 'JobQues2', newValue);
    }, [attributeChangeHandler, jobBoardId]);

    const question3ChangeHandler = useCallback((newValue: string) => {
        attributeChangeHandler && attributeChangeHandler(jobBoardId, 'JobQues3', newValue);
    }, [attributeChangeHandler, jobBoardId]);

    return (
        <Box display="flex" flexDirection="column" gap={2}>
            <Box display="flex" gap={2} pt={2}>
                <Box display="flex" flexDirection="column" gap={2} width="50%">
                    <TextField select label="Job Category" name="JobCategories" value={jobCategory} onChange={localChangeHandler}>
                        <MenuItem value=""></MenuItem>
                        <MenuItem value="8">Accountancy & Finance</MenuItem>
                        <MenuItem value="20">Banking, Financial services & Insurance</MenuItem>
                        <MenuItem value="11">Beauty, Hair Care, Leisure & Sport</MenuItem>
                        <MenuItem value="9">Construction, Architecture & Property</MenuItem>
                        <MenuItem value="23">Customer Service, Call Centres & Languages</MenuItem>
                        <MenuItem value="13">Education, Childcare & Training</MenuItem>
                        <MenuItem value="6">Engineering & Utilities</MenuItem>
                        <MenuItem value="2">Environmental, Health & Safety</MenuItem>
                        <MenuItem value="27">Executive</MenuItem>
                        <MenuItem value="28">Graduate</MenuItem>
                        <MenuItem value="1">Hotel & Catering</MenuItem>
                        <MenuItem value="16">HR & Recruitment</MenuItem>
                        <MenuItem value="3">IT</MenuItem>
                        <MenuItem value="4">Legal</MenuItem>
                        <MenuItem value="21">Marketing</MenuItem>
                        <MenuItem value="17">Medical Professionals & Healthcare</MenuItem>
                        <MenuItem value="7">Production, Manufacturing & Materials</MenuItem>
                        <MenuItem value="115">Public Sector</MenuItem>
                        <MenuItem value="25">Publishing, Media & Creative Arts</MenuItem>
                        <MenuItem value="10">Retailing, Wholesaling & Purchasing</MenuItem>
                        <MenuItem value="24">Sales</MenuItem>
                        <MenuItem value="26">Science, Pharmaceutical & Food</MenuItem>
                        <MenuItem value="114">Secretarial & Admin</MenuItem>
                        <MenuItem value="14">Security, Trades & General Services</MenuItem>
                        <MenuItem value="12">Social & Not for Profit</MenuItem>
                        <MenuItem value="5">Telecoms</MenuItem>
                        <MenuItem value="19">Tourism, Travel & Airlines</MenuItem>
                        <MenuItem value="18">Transport, Warehousing & Motor</MenuItem>
                    </TextField>
                    <TextField select label="Job Salary Range (€)" name="JobSalaryRange" value={jobSalaryRange} onChange={localChangeHandler}>
                        <MenuItem value="-3"></MenuItem>
                        <MenuItem value="-2">See description</MenuItem>
                        <MenuItem value="-1">Negotiable</MenuItem>
                        <MenuItem value="0">Not disclosed</MenuItem>
                        <MenuItem value="1">10000 - 20000</MenuItem>
                        <MenuItem value="2">15000 - 25000</MenuItem>
                        <MenuItem value="3">20000 - 30000</MenuItem>
                        <MenuItem value="4">25000 - 35000</MenuItem>
                        <MenuItem value="5">30000 - 40000</MenuItem>
                        <MenuItem value="6">35000 - 45000</MenuItem>
                        <MenuItem value="7">40000 - 50000</MenuItem>
                        <MenuItem value="8">45000 - 55000</MenuItem>
                        <MenuItem value="9">50000 - 60000</MenuItem>
                        <MenuItem value="10">55000 - 65000</MenuItem>
                        <MenuItem value="11">60000 - 70000</MenuItem>
                        <MenuItem value="12">65000 - 75000</MenuItem>
                        <MenuItem value="13">70000 - 80000</MenuItem>
                        <MenuItem value="14">75000 - 85000</MenuItem>
                        <MenuItem value="15">80000 - 90000</MenuItem>
                        <MenuItem value="16">85000 - 95000</MenuItem>
                        <MenuItem value="17">90000 - 100000</MenuItem>
                        <MenuItem value="18">100000 - or more</MenuItem>
                    </TextField>
                    <TextField select label="Job Type" name="JobType" value={jobType} onChange={localChangeHandler}>
                        <MenuItem value="0"></MenuItem>
                        <MenuItem value="1">Permanent Full-time</MenuItem>
                        <MenuItem value="2">Permanent Part-time</MenuItem>
                        <MenuItem value="3">Temporary Full-time</MenuItem>
                        <MenuItem value="4">Temporary Part-time</MenuItem>
                        <MenuItem value="5">Contract</MenuItem>
                        <MenuItem value="6">Fixed Term Contract</MenuItem>
                        <MenuItem value="7">Volunteer</MenuItem>
                    </TextField>
                    <SlowTextFieldWrapper label="Question 1" value={jobQues1} onChange={question1ChangeHandler} />
                    <SlowTextFieldWrapper label="Question 2" value={jobQues2} onChange={question2ChangeHandler} />
                    <SlowTextFieldWrapper label="Question 3" value={jobQues3} onChange={question3ChangeHandler} />
                </Box>
                <Box display="flex" flexDirection="column" gap={2} width="50%">
                    <TextField select label="Job Location" name="JobLocations" value={jobLocations} onChange={localChangeHandler}>
                        <MenuItem value=""></MenuItem>
                        <MenuItem value="89">Africa</MenuItem>
                        <MenuItem value="21">Antrim</MenuItem>
                        <MenuItem value="22">Armagh</MenuItem>
                        <MenuItem value="88">Asia</MenuItem>
                        <MenuItem value="93">Australia</MenuItem>
                        <MenuItem value="23">Belfast</MenuItem>
                        <MenuItem value="95">Canada</MenuItem>
                        <MenuItem value="90">Caribbean</MenuItem>
                        <MenuItem value="2">Carlow</MenuItem>
                        <MenuItem value="24">Cavan</MenuItem>
                        <MenuItem value="104">Central America</MenuItem>
                        <MenuItem value="41">Clare</MenuItem>
                        <MenuItem value="42">Cork</MenuItem>
                        <MenuItem value="43">Cork city</MenuItem>
                        <MenuItem value="25">Derry</MenuItem>
                        <MenuItem value="26">Donegal</MenuItem>
                        <MenuItem value="27">Down</MenuItem>
                        <MenuItem value="102">Dublin</MenuItem>
                        <MenuItem value="96">Dublin City Centre</MenuItem>
                        <MenuItem value="97">Dublin North</MenuItem>
                        <MenuItem value="98">Dublin South</MenuItem>
                        <MenuItem value="1">Dublin West</MenuItem>
                        <MenuItem value="85">Eastern Europe</MenuItem>
                        <MenuItem value="28">Fermanagh</MenuItem>
                        <MenuItem value="61">Galway</MenuItem>
                        <MenuItem value="62">Galway city</MenuItem>
                        <MenuItem value="44">Kerry</MenuItem>
                        <MenuItem value="3">Kildare</MenuItem>
                        <MenuItem value="4">Kilkenny</MenuItem>
                        <MenuItem value="5">Laois</MenuItem>
                        <MenuItem value="63">Leitrim</MenuItem>
                        <MenuItem value="45">Limerick</MenuItem>
                        <MenuItem value="46">Limerick city</MenuItem>
                        <MenuItem value="6">Longford</MenuItem>
                        <MenuItem value="7">Louth</MenuItem>
                        <MenuItem value="94">Mainland Europe</MenuItem>
                        <MenuItem value="64">Mayo</MenuItem>
                        <MenuItem value="8">Meath</MenuItem>
                        <MenuItem value="86">Middle East</MenuItem>
                        <MenuItem value="29">Monaghan</MenuItem>
                        <MenuItem value="82">Nationwide</MenuItem>
                        <MenuItem value="105">New Zealand</MenuItem>
                        <MenuItem value="9">Offaly</MenuItem>
                        <MenuItem value="81">Other</MenuItem>
                        <MenuItem value="65">Roscommon</MenuItem>
                        <MenuItem value="66">Sligo</MenuItem>
                        <MenuItem value="103">South America</MenuItem>
                        <MenuItem value="47">Tipperary</MenuItem>
                        <MenuItem value="30">Tyrone</MenuItem>
                        <MenuItem value="91">UK</MenuItem>
                        <MenuItem value="92">USA</MenuItem>
                        <MenuItem value="48">Waterford</MenuItem>
                        <MenuItem value="10">Westmeath</MenuItem>
                        <MenuItem value="11">Wexford</MenuItem>
                        <MenuItem value="12">Wicklow</MenuItem>
                        <MenuItem value="106">Working from Home</MenuItem>
                    </TextField>
                    <TextField select label="Additional Benefits" name="JobAddnlBens" value={jobAddnlBens} onChange={localChangeHandler}>
                        <MenuItem value=""></MenuItem>
                        <MenuItem value="33">Accommodation</MenuItem>
                        <MenuItem value="17">Bonus</MenuItem>
                        <MenuItem value="6">Canteen</MenuItem>
                        <MenuItem value="4">Car Allowance</MenuItem>
                        <MenuItem value="31">Clothing Allowance</MenuItem>
                        <MenuItem value="21">Commission</MenuItem>
                        <MenuItem value="13">Company Vehicle</MenuItem>
                        <MenuItem value="19">Compressed hours</MenuItem>
                        <MenuItem value="8">Creche</MenuItem>
                        <MenuItem value="14">Educational assistance</MenuItem>
                        <MenuItem value="25">Expenses</MenuItem>
                        <MenuItem value="35">Flexi Start</MenuItem>
                        <MenuItem value="2">Flexitime</MenuItem>
                        <MenuItem value="7">Gym</MenuItem>
                        <MenuItem value="40">Health Plan</MenuItem>
                        <MenuItem value="32">Hibernian Health Cover</MenuItem>
                        <MenuItem value="27">Holiday Fund</MenuItem>
                        <MenuItem value="3">House-Rent Allowance</MenuItem>
                        <MenuItem value="23">Laptop</MenuItem>
                        <MenuItem value="26">Life Assurance</MenuItem>
                        <MenuItem value="37">Lunch Vouchers</MenuItem>
                        <MenuItem value="30">Mileage</MenuItem>
                        <MenuItem value="1">Mobile phone</MenuItem>
                        <MenuItem value="16">Negotiable</MenuItem>
                        <MenuItem value="5">Paid Holidays</MenuItem>
                        <MenuItem value="9">Parking</MenuItem>
                        <MenuItem value="12">Pension</MenuItem>
                        <MenuItem value="29">Permanent Health Insurance</MenuItem>
                        <MenuItem value="36">Profit Share</MenuItem>
                        <MenuItem value="18">Quinn Healthcare</MenuItem>
                        <MenuItem value="15">Relocation package</MenuItem>
                        <MenuItem value="24">See Description</MenuItem>
                        <MenuItem value="11">Share options</MenuItem>
                        <MenuItem value="38">Sports & Social Club</MenuItem>
                        <MenuItem value="22">Staff discount</MenuItem>
                        <MenuItem value="34">Travel Allowance</MenuItem>
                        <MenuItem value="28">Travel Tax Incentives</MenuItem>
                        <MenuItem value="39">Uniform</MenuItem>
                        <MenuItem value="10">VHI</MenuItem>
                        <MenuItem value="20">Vouchers</MenuItem>
                    </TextField>
                    <TextField select label="Minimum Experience Required" name="JobMinExp" value={jobMinExp} onChange={localChangeHandler}>
                        <MenuItem value="0"></MenuItem>
                        <MenuItem value="1">See Description</MenuItem>
                        <MenuItem value="2">No minimum required</MenuItem>
                        <MenuItem value="3">0 - 1 Years</MenuItem>
                        <MenuItem value="4">1 - 2 Years</MenuItem>
                        <MenuItem value="5">2 - 3 Years</MenuItem>
                        <MenuItem value="6">3 - 4 Years</MenuItem>
                        <MenuItem value="7">4 - 5 Years</MenuItem>
                        <MenuItem value="8">5 - 6 Years</MenuItem>
                        <MenuItem value="9">6 - 7 Years</MenuItem>
                        <MenuItem value="10">7 - 8 Years</MenuItem>
                        <MenuItem value="11">8 - 9 Years</MenuItem>
                        <MenuItem value="12">9 - 10 Years</MenuItem>
                        <MenuItem value="13">10 - and more..</MenuItem>
                    </TextField>
                    <TextField select label="Minimum Level of Education" name="JobMinQual" value={jobMinQual} onChange={localChangeHandler}>
                        <MenuItem value="-1"></MenuItem>
                        <MenuItem value="0">See Description</MenuItem>
                        <MenuItem value="1">No Minimum required</MenuItem>
                        <MenuItem value="2">Junior-Certificate</MenuItem>
                        <MenuItem value="3">Leaving-Certificate</MenuItem>
                        <MenuItem value="4">Third Level Certification</MenuItem>
                        <MenuItem value="5">Third Level Diploma</MenuItem>
                        <MenuItem value="6">Third Level Degree</MenuItem>
                        <MenuItem value="7">Post Graduate Qualification</MenuItem>
                        <MenuItem value="8">Masters</MenuItem>
                        <MenuItem value="9">PHD</MenuItem>
                        <MenuItem value="10">Professional Qualification</MenuItem>
                        <MenuItem value="11">Part Professional Qualification</MenuItem>
                        <MenuItem value="12">Trade Qualification</MenuItem>
                    </TextField>
                </Box>
            </Box>
        </Box>
    );
}