import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import React, { useCallback, useEffect, useMemo } from "react";

interface Props {
    jobBoardId: number,
    advertId: number,
    attributes?: Record<string, string>,
    advertRecordAttributes?: Record<string, string>,
    attributeChangeHandler?: (jobBoardId: number, key: string, value: string) => void
}

export default function AdzunaEdit({ jobBoardId, advertId, attributes, advertRecordAttributes, attributeChangeHandler }: Props) {
    const atts = useMemo<Record<string, string>>(() => {
        if (attributes) return attributes;
        return {};
    }, [attributes]);

    const recordAtts = useMemo<Record<string, string>>(() => {
        if (advertRecordAttributes) return advertRecordAttributes;
        return {};
    }, [advertRecordAttributes]);

    const category = useMemo(() => atts.category ?? '', [atts.category]);
    const country = useMemo(() => recordAtts.country ?? '', [recordAtts.country]);
    const location = useMemo(() => recordAtts.location ?? '', [recordAtts.location]);
    const contractTime = useMemo(() => atts.contract_time ?? '', [atts.contract_time]);
    const workType = useMemo(() => recordAtts.workType ?? '', [recordAtts.workType]);
    const salaryType = useMemo(() => recordAtts.salaryType ?? '', [recordAtts.salaryType]);
    const salaryFrom = useMemo(() => recordAtts.salaryFrom ?? '', [recordAtts.salaryFrom]);
    const salaryTo = useMemo(() => recordAtts.salaryTo ?? '', [recordAtts.salaryTo]);

    useEffect(() => attributeChangeHandler && attributeChangeHandler(jobBoardId, 'country', country), [attributeChangeHandler, country, jobBoardId]);
    useEffect(() => attributeChangeHandler && attributeChangeHandler(jobBoardId, 'location', location), [attributeChangeHandler, location, jobBoardId]);
    useEffect(() => attributeChangeHandler && attributeChangeHandler(jobBoardId, 'contract_type', workType), [attributeChangeHandler, workType, jobBoardId]);
    useEffect(() => attributeChangeHandler && attributeChangeHandler(jobBoardId, 'salary_frequency', salaryType), [attributeChangeHandler, salaryType, jobBoardId]);
    useEffect(() => attributeChangeHandler && attributeChangeHandler(jobBoardId, 'salary_min', salaryFrom), [attributeChangeHandler, salaryFrom, jobBoardId]);
    useEffect(() => attributeChangeHandler && attributeChangeHandler(jobBoardId, 'salary_max', salaryTo), [attributeChangeHandler, salaryTo, jobBoardId]);

    const localChangeHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        attributeChangeHandler && attributeChangeHandler(jobBoardId, name, value);
    }, [attributeChangeHandler, jobBoardId]);

    return (
        <Box display="flex" gap={2} pt={2}>
            <Box display="flex" flexDirection="column" gap={2} width="50%">
                <TextField select label="Category" name="category" value={category} onChange={localChangeHandler}>
                    <MenuItem value=""></MenuItem>
                    <MenuItem value="1">Accounting & Finance Jobs</MenuItem>
                    <MenuItem value="13">Admin Jobs</MenuItem>
                    <MenuItem value="25">Charity & Voluntary Jobs</MenuItem>
                    <MenuItem value="15">Creative & Design Jobs</MenuItem>
                    <MenuItem value="18">Consultancy Jobs</MenuItem>
                    <MenuItem value="4">Customer Services Jobs</MenuItem>
                    <MenuItem value="26">Domestic help & Cleaning Jobs</MenuItem>
                    <MenuItem value="23">Energy, Oil & Gas Jobs</MenuItem>
                    <MenuItem value="5">Engineering Jobs</MenuItem>
                    <MenuItem value="16">Graduate Jobs</MenuItem>
                    <MenuItem value="7">Healthcare & Nursing Jobs</MenuItem>
                    <MenuItem value="8">Hospitality & Catering Jobs</MenuItem>
                    <MenuItem value="6">HR & Recruitment Jobs</MenuItem>
                    <MenuItem value="2">IT Jobs</MenuItem>
                    <MenuItem value="14">Legal Jobs</MenuItem>
                    <MenuItem value="10">Logistics & Warehouse Jobs</MenuItem>
                    <MenuItem value="27">Maintenance Jobs</MenuItem>
                    <MenuItem value="19">Manufacturing Jobs</MenuItem>
                    <MenuItem value="29">Other/General Jobs</MenuItem>
                    <MenuItem value="28">Part time Jobs</MenuItem>
                    <MenuItem value="9">PR, Advertising & Marketing Jobs</MenuItem>
                    <MenuItem value="24">Property Jobs</MenuItem>
                    <MenuItem value="17">Retail Jobs</MenuItem>
                    <MenuItem value="3">Sales Jobs</MenuItem>
                    <MenuItem value="20">Scientific & QA Jobs</MenuItem>
                    <MenuItem value="21">Social work Jobs</MenuItem>
                    <MenuItem value="11">Teaching Jobs</MenuItem>
                    <MenuItem value="12">Trade & Construction Jobs</MenuItem>
                    <MenuItem value="22">Travel Jobs</MenuItem>
                </TextField>
                <TextField disabled label="Country" value={country} />
                <TextField disabled label="Location" value={location} />
                <TextField select label="Contract Time" name="contract_time" value={contractTime} onChange={localChangeHandler}>
                    <MenuItem value=""></MenuItem>
                    <MenuItem value="full_time">Full Time</MenuItem>
                    <MenuItem value="part_time">Part Time</MenuItem>
                </TextField>
            </Box>
            <Box display="flex" flexDirection="column" gap={2} width="50%">
                <TextField disabled label="Work Type" value={workType} />
                <TextField disabled label="Salary Type" value={salaryType} />
                <Box display="flex" gap={2}>
                    <TextField disabled label="Salary From" sx={{ flex: '1 1 0' }} value={salaryFrom} />
                    <TextField disabled label="Salary To" sx={{ flex: '1 1 0' }} value={salaryTo} />
                </Box>
            </Box>
        </Box>
    );
}