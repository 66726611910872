import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import SlowTextFieldWrapper from "components/SlowTextFieldWrapper";
import React, { useCallback, useEffect, useMemo } from "react";

interface Props {
    jobBoardId: number,
    advertId: number,
    attributes?: Record<string, string>,
    advertRecordAttributes?: Record<string, string>,
    attributeChangeHandler?: (jobBoardId: number, key: string, value: string) => void
}

export default function CVLibraryEdit({ jobBoardId, advertId, attributes, advertRecordAttributes, attributeChangeHandler }: Props) {
    const atts = useMemo<Record<string, string>>(() => {
        if (attributes) return attributes;
        return {};
    }, [attributes]);

    const recordAtts = useMemo<Record<string, string>>(() => {
        if (advertRecordAttributes) return advertRecordAttributes;
        return {};
    }, [advertRecordAttributes]);

    const industry = useMemo(() => atts.Industry ?? '0', [atts.Industry]);
    const region = useMemo(() => atts.Region ?? '0', [atts.Region]);
    const location = useMemo(() => atts.Location ?? '', [atts.Location]);
    const remote = useMemo(() => atts.Remote ?? '0', [atts.Remote]);
    const jobType = useMemo(() => atts.JobType ?? '0', [atts.JobType]);

    const salaryType = useMemo(() => recordAtts.salaryType ?? '', [recordAtts.salaryType]);
    const salaryFrom = useMemo(() => recordAtts.salaryFrom ?? '', [recordAtts.salaryFrom]);
    const salaryTo = useMemo(() => recordAtts.salaryTo ?? '', [recordAtts.salaryTo]);

    useEffect(() => attributeChangeHandler && attributeChangeHandler(jobBoardId, 'SalaryType', salaryType), [attributeChangeHandler, salaryType, jobBoardId]);
    useEffect(() => attributeChangeHandler && attributeChangeHandler(jobBoardId, 'SalaryMin', salaryFrom), [attributeChangeHandler, salaryFrom, jobBoardId]);
    useEffect(() => attributeChangeHandler && attributeChangeHandler(jobBoardId, 'SalaryMax', salaryTo), [attributeChangeHandler, salaryTo, jobBoardId]);

    const localChangeHandler = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        attributeChangeHandler && attributeChangeHandler(jobBoardId, name, value);
    }, [attributeChangeHandler, jobBoardId]);

    const locationChangeHandler = useCallback((newValue: string) => {
        attributeChangeHandler && attributeChangeHandler(jobBoardId, "Location", newValue);
    }, [attributeChangeHandler, jobBoardId]);

    return (
        <Box display="flex" flexDirection="column" gap={2}>
            <Box display="flex" gap={2} pt={2}>
                <Box display="flex" flexDirection="column" gap={2} width="50%">
                    <TextField select label="Industry" value={industry} name="Industry" onChange={localChangeHandler}>
                        <MenuItem value="0"></MenuItem>
                        <MenuItem value="1">Accounting / Financial / Insurance</MenuItem>
                        <MenuItem value="26">Administration</MenuItem>
                        <MenuItem value="2">Agriculture</MenuItem>
                        <MenuItem value="3">Arts / Graphic Design</MenuItem>
                        <MenuItem value="4">Automotive / Aerospace</MenuItem>
                        <MenuItem value="5">Catering</MenuItem>
                        <MenuItem value="6">Charity</MenuItem>
                        <MenuItem value="27">Construction</MenuItem>
                        <MenuItem value="8">Consulting</MenuItem>
                        <MenuItem value="9">Customer Services</MenuItem>
                        <MenuItem value="10">Distribution</MenuItem>
                        <MenuItem value="11">Education</MenuItem>
                        <MenuItem value="12">Electronics</MenuItem>
                        <MenuItem value="7">Engineering</MenuItem>
                        <MenuItem value="30">Hospitality / Hotel</MenuItem>
                        <MenuItem value="13">IT</MenuItem>
                        <MenuItem value="14">Legal</MenuItem>
                        <MenuItem value="15">Leisure / Tourism</MenuItem>
                        <MenuItem value="16">Management</MenuItem>
                        <MenuItem value="17">Manufacturing / Surveying</MenuItem>
                        <MenuItem value="29">Marketing</MenuItem>
                        <MenuItem value="19">Media</MenuItem>
                        <MenuItem value="20">Medical / Pharmaceutical / Scientific</MenuItem>
                        <MenuItem value="21">Military / Emergency / Government</MenuItem>
                        <MenuItem value="22">Other</MenuItem>
                        <MenuItem value="23">Personnel / Recruitment</MenuItem>
                        <MenuItem value="24">Property Services</MenuItem>
                        <MenuItem value="31">Public Sector</MenuItem>
                        <MenuItem value="28">Retail / Purchasing</MenuItem>
                        <MenuItem value="18">Sales</MenuItem>
                        <MenuItem value="32">Social Care</MenuItem>
                        <MenuItem value="25">Telecoms</MenuItem>
                    </TextField>
                    <TextField select label="Region" value={region} name="Region" onChange={localChangeHandler}>
                        <MenuItem value="0">Standard job posting (not regional)</MenuItem>
                        <MenuItem value="77">East Midlands</MenuItem>
                        <MenuItem value="73">East of England</MenuItem>
                        <MenuItem value="127">London</MenuItem>
                        <MenuItem value="90">North East</MenuItem>
                        <MenuItem value="95">North West</MenuItem>
                        <MenuItem value="100">Northern Ireland</MenuItem>
                        <MenuItem value="107">Scotland</MenuItem>
                        <MenuItem value="117">South East</MenuItem>
                        <MenuItem value="132">South West</MenuItem>
                        <MenuItem value="140">Wales</MenuItem>
                        <MenuItem value="149">West Midlands</MenuItem>
                        <MenuItem value="91">Yorkshire and the Humber</MenuItem>
                        <MenuItem value="411">UK (Nationwide)</MenuItem>
                        <MenuItem value="68">Non UK</MenuItem>
                    </TextField>
                    <SlowTextFieldWrapper label="Location" value={location} onChange={locationChangeHandler} />
                    <TextField select label="Remote" name="Remote" value={remote} onChange={localChangeHandler}>
                        <MenuItem value="0">No</MenuItem>
                        <MenuItem value="1">Yes</MenuItem>
                    </TextField>
                </Box>
                <Box display="flex" flexDirection="column" gap={2} width="50%">
                    <TextField select label="Job Type" name="JobType" value={jobType} onChange={localChangeHandler}>
                        <MenuItem value="0"></MenuItem>
                        <MenuItem value="apprenticeship">Apprenticeship</MenuItem>
                        <MenuItem value="contract">Contract</MenuItem>
                        <MenuItem value="part_time">Part Time</MenuItem>
                        <MenuItem value="permanent">Permanent</MenuItem>
                        <MenuItem value="temporary">Temporary</MenuItem>
                    </TextField>
                    <TextField disabled label="Salary Type" value={salaryType} />
                    <Box display="flex" gap={2}>
                        <TextField disabled label="Salary From" sx={{ flex: '1 1 0' }} value={salaryFrom} />
                        <TextField disabled label="Salary To" sx={{ flex: '1 1 0' }} value={salaryTo} />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}