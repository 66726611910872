import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { GridColDef, GridRenderCellParams, useGridApiRef } from "@mui/x-data-grid-premium";
import { Button } from "@mui/material";

import { Division } from "common/models/Configuration/Division";
import PageContentLayout from "layouts/PageContentLayout";
import { GetDivisions } from "services/DivisionsService";
import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import GridWithStateWrapper from "components/GridWidthStateWrapper";

interface Props {
    setSummaryBar?: (summaryBar: JSX.Element) => void
}

const SummaryBar = (
    <TitleAndActionSummaryBar
        title="Configuration > Divisions"
        browserTabTitle="Divisions > Configuration"
        action={ <Button variant="contained" color="success"><Link to="/configuration/divisions/0" style={{ color: 'inherit', textDecoration: 'none' }}>Add Division</Link> </Button> }
    />
);

export default function DivisionsList({ setSummaryBar }: Props) {
    useEffect(() => { setSummaryBar && setSummaryBar(SummaryBar); }, [setSummaryBar]);
    const [isLoading, setIsLoading] = useState(false);
    const [rows, setRows] = useState<Division[]>([]);
    const apiRef = useGridApiRef();


    useEffect(() => {
        const getData = async () => {
            setIsLoading(true);

            const data = await GetDivisions();

            if (data)
                setRows(data);

            setIsLoading(false);
        };
        getData();
    }, []);

    const columns = useMemo<GridColDef[]>(() => {
        const dbNameRenderer = (params: GridRenderCellParams) => {
            return (
                <Link to={`/configuration/divisions/${params.id}`} style={{ color: 'inherit', textDecoration: 'underline' }}>{params.value}</Link>
            );
        };

        const timesheetSystemRenderer = (params: GridRenderCellParams) => {
            if(params.value === 0) return <span>None</span>;
            if(params.value === 1) return <Link to={`/configuration/divisions/${params.row.id}/integrations/astutepayroll`} style={{ color: 'inherit', textDecoration: 'underline' }}>Astute Payroll</Link>;
            return <></>;
        };

        return [
            { field: 'id', headerName: 'ID', renderCell: dbNameRenderer },
            { field: 'name', headerName: 'Name', width: 300, renderCell: dbNameRenderer },
            { field: 'active', headerName: 'Active', width: 200, type: 'boolean' },
            { field: 'countryName', headerName: 'Default Country', width: 200 },
            { field: 'timeZone', headerName: 'Time Zone', width: 200 },
            { field: 'timesheetSystem', headerName: 'Timesheet System ', width: 200, renderCell: timesheetSystemRenderer }
        ];
    }, []);

    return (
        <>
            <PageContentLayout title="Divisions" showLoading={isLoading}>
                <GridWithStateWrapper
                    gridName="configuration/divisions"
                    apiRef={apiRef}
                    rows={rows}
                    columns={columns}
                    disableRowSelectionOnClick
                    pagination={true}
                    density="compact"
                    pageSizeOptions={[100, 250, 500, 1000]}
                />
            </PageContentLayout>
        </>
    );
}