import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import React, { useEffect, useMemo } from "react";

interface Props {
    jobBoardId: number,
    advertId: number,
    attributes?: Record<string, string>,
    attributeChangeHandler?: (jobBoardId: number, key: string, value: string) => void
}

export default function JoraEdit({ jobBoardId, advertId, attributes, attributeChangeHandler }: Props) {

    const atts = useMemo<Record<string, string>>(() => {
        if (attributes) return attributes;
        return {};
    }, [attributes]);

    const country = useMemo(() => atts.country ?? '', [atts.country]);
    const region = useMemo(() => atts.region ?? '', [atts.region]);
    const location = useMemo(() => atts.location ?? '', [atts.location]);
    const workType = useMemo(() => atts.workType ?? '', [atts.workType]);

    useEffect(() => attributeChangeHandler && attributeChangeHandler(jobBoardId, 'country', country), [attributeChangeHandler, country, jobBoardId]);
    useEffect(() => attributeChangeHandler && attributeChangeHandler(jobBoardId, 'region', region), [attributeChangeHandler, region, jobBoardId]);
    useEffect(() => attributeChangeHandler && attributeChangeHandler(jobBoardId, 'location', location), [attributeChangeHandler, location, jobBoardId]);
    useEffect(() => attributeChangeHandler && attributeChangeHandler(jobBoardId, 'job_type', workType), [attributeChangeHandler, workType, jobBoardId]);

    return (
        <Box display="flex" gap={2} pt={2}>
            <Box display="flex" flexDirection="column" gap={2} width="50%">
                <TextField disabled label="Country" value={country} />
                <TextField disabled label="Region" value={region} />
                <TextField disabled label="Location" value={location} />
            </Box>
            <Box display="flex" flexDirection="column" gap={2} width="50%">
                <TextField disabled label="Work Type" value={workType} />
            </Box>
        </Box>
    );
}