import React, { useCallback, useEffect, useMemo, useState } from "react";
import { PreviewEntityType } from "common/models/Previews/Previews";
import ActivityPreviewComponent from "./ActivityPreview";
import AdvertPreviewComponent from "./AdvertPreview";
import TagsPreviewComponent from "./TagsPreview";
import ClientQuickViewComponent from "./ClientQuickView";
import ContactQuickViewComponent from "./ContactQuickView";
import JobQuickViewComponent from "./JobQuickView";
import OpportunityQuickViewComponent from "./OpportunityQuickView";
import CandidateQuickViewComponent from "./CandidateQuickView";
import SiteQuickViewComponent from "./SiteQuickView";
import PlacementQuickViewComponent from "./PlacementQuickView";
import MeetingAttendeesQuickView from "./MeetingAttendeesQuickView";

interface Props {
    entityType: PreviewEntityType | '',
    recordId: number,
    open: boolean,
    isTagsPreview?: boolean,
    titleOverride?: string,
    showDelayMs?: number,
    hideDelayMs?: number
}

export default function PreviewLoaderComponent({ entityType, recordId, open: openProp, isTagsPreview = false, titleOverride = "", showDelayMs = 1750, hideDelayMs = 1250 }: Props) {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (openProp) {
            const showPreviewTimeout = setTimeout(() => {
                setIsOpen(true);
            }, showDelayMs);
            return () => clearTimeout(showPreviewTimeout);
        }
    }, [openProp, showDelayMs, hideDelayMs]);

    const hideCallback = useCallback(() => {
        setIsOpen(false);
    }, []);

    const preview = useMemo(() => {
        if (recordId === 0) return <></>
        if (isTagsPreview) {
            let entityTypeId = 0;
            switch (entityType) {
                case "activity": entityTypeId = 13; break;
                case "client": entityTypeId = 1; break;
                case "contact": entityTypeId = 2; break;
                case "candidate": entityTypeId = 3; break;
                case "opportunity": entityTypeId = 8; break;
                case "placement": entityTypeId = 5; break;
                case "advert": entityTypeId = 15; break;
                case "job": entityTypeId = 4; break;
                case "site": entityTypeId = 9; break;
            }
            if (entityTypeId === 0) return <></>;
            return <TagsPreviewComponent entityTypeId={entityTypeId} recordId={recordId} recordName={titleOverride} isSourceHovered={openProp} hideDelayMs={hideDelayMs} hideCallback={hideCallback} />;
        }
        switch (entityType) {
            case "activity": return <ActivityPreviewComponent isSourceHovered={openProp} recordId={recordId} hideDelayMs={hideDelayMs} hideCallback={hideCallback} />;
            case "client": return <ClientQuickViewComponent recordId={recordId} isSourceHovered={openProp} hideDelayMs={hideDelayMs} hideCallback={hideCallback} />;
            case "contact": return <ContactQuickViewComponent recordId={recordId} isSourceHovered={openProp} hideDelayMs={hideDelayMs} hideCallback={hideCallback} />;
            // case "candidate": return <CandidatePreviewComponent recordId={recordId} isSourceHovered={openProp} hideDelayMs={hideDelayMs} hideCallback={hideCallback} />;
            case "candidate": return <CandidateQuickViewComponent recordId={recordId} isSourceHovered={openProp} hideDelayMs={hideDelayMs} hideCallback={hideCallback} />;
            case "opportunity": return <OpportunityQuickViewComponent recordId={recordId} isSourceHovered={openProp} hideDelayMs={hideDelayMs} hideCallback={hideCallback} />;
            // case "placement": return <PlacementPreviewComponent recordId={recordId} isSourceHovered={openProp} hideDelayMs={hideDelayMs} hideCallback={hideCallback} />;
            case "placement": return <PlacementQuickViewComponent recordId={recordId} isSourceHovered={openProp} hideDelayMs={hideDelayMs} hideCallback={hideCallback} />;
            case "advert": return <AdvertPreviewComponent recordId={recordId} isSourceHovered={openProp} hideDelayMs={hideDelayMs} hideCallback={hideCallback} />;
            case "job": return <JobQuickViewComponent recordId={recordId} isSourceHovered={openProp} hideDelayMs={hideDelayMs} hideCallback={hideCallback} />;
            // case "site": return <SitePreviewComponent recordId={recordId} isSourceHovered={openProp} hideDelayMs={hideDelayMs} hideCallback={hideCallback} />;
            case "site": return <SiteQuickViewComponent recordId={recordId} isSourceHovered={openProp} hideDelayMs={hideDelayMs} hideCallback={hideCallback} />;
            case "meeting-attendees": return <MeetingAttendeesQuickView recordId={recordId} isSourceHovered={openProp} hideDelayMs={hideDelayMs} hideCallback={hideCallback} />;
        }
        return <></>;
    }, [entityType, recordId, isTagsPreview, openProp, hideCallback, hideDelayMs, titleOverride]);

    return (
        <>
            {isOpen &&
                preview
            }
        </>
    );
}