import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import { NameIdObj } from "common/models/GenericTypes";
import useActiveUsers from "hooks/UseActiveUsers";
import useAllUsers from "hooks/UseAllUsers";
import React, { useEffect, useMemo, useState } from "react";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import TextField from "@mui/material/TextField";
import { SxProps } from "@mui/material/styles";

interface Props {
    value: number[],
    onSelect: ((users: NameIdObj[]) => void)
    variant?: "standard" | "filled" | "outlined" | undefined,
    label?: string,
    disableClearable?: boolean,
    blurOnSelect?: boolean,
    hideLabel?: boolean,
    includeInactiveUsers?: boolean,
    appendToStart?: NameIdObj[],
    sx?: SxProps,
}


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function MultipleUserPicker({ value, label = "Users", variant,appendToStart, disableClearable, blurOnSelect, includeInactiveUsers, hideLabel, onSelect, sx }: Props) {
    const [options, setOptions] = useState<NameIdObj[]>([]);
    const activeUsers = useActiveUsers();
    const allUsers = useAllUsers();

    useEffect(() => {
        if (includeInactiveUsers) {
            if (appendToStart && appendToStart.length > 0) {
                setOptions([...appendToStart, ...allUsers]);
            }
            else {
                setOptions(allUsers);
            }
        }
        else {
            if (appendToStart && appendToStart.length > 0)  {
                setOptions([...appendToStart, ...activeUsers]);
            }
            else {
                setOptions(activeUsers);
            }
        }
        

    }, [appendToStart,activeUsers, allUsers, includeInactiveUsers]);

    const selectedOptions = useMemo(() => {
        let selected: NameIdObj[] = [];
        for (let i = 0; i < value.length; i++) {
            const v = value[i];
            const o = options.find(opt => opt.id === v);
            if (o) selected.push(o);
        }
        return selected;
    }, [options, value]);

    return (
        <Autocomplete
            value={selectedOptions}
            multiple
            size="small"
            disableCloseOnSelect
            options={options}
            isOptionEqualToValue={(o, v) => o.id === v.id}
            disableClearable={disableClearable}
            blurOnSelect={blurOnSelect}
            getOptionLabel={o => o.name}
            onChange={(e,v,r,d) => onSelect(v)}
            sx={sx}
            renderOption={(props, option, {selected}) => (
                <li {...props}>
                    <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                    />
                    {option.name}
                </li>
            )}
            renderInput={(params) => (
                <TextField {...params} label={hideLabel ? undefined : label} variant={variant} />
            )}
        />
    );
}