import Alert from "components/Alert";
import Snackbar from "@mui/material/Snackbar";
import React, { useState } from "react";
import ContactsGridComponent from "components/Grids/ContactsGrid";
import OnlyTitleSummaryBar from "components/SummaryBars/OnlyTitleSummaryBar";
import PageContentLayout from "layouts/PageContentLayout";
import PageLayout from "layouts/PageLayout";

const summaryBar = <OnlyTitleSummaryBar title="Contacts > My Contacts" browserTabTitle="My Contacts > Contacts" />;

export default function MyContactsPage() {
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    return (
        <PageLayout SummaryBar={summaryBar}>
            <Snackbar open={successMessage !== ''} autoHideDuration={3000} onClose={() => setSuccessMessage('')}>
                <Alert onClose={() => setSuccessMessage('')}>{ successMessage }</Alert>
            </Snackbar>
            <Snackbar open={errorMessage !== ''} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <PageContentLayout showLoading={isLoading}>
                <ContactsGridComponent
                    gridName="my-contacts"
                    source="my-contacts"
                    loadingHandler={setIsLoading}
                    errorHandler={setErrorMessage}
                    successHandler={setSuccessMessage}
                />
            </PageContentLayout>
        </PageLayout>
    );
}