import { PanelModel } from "@syncfusion/ej2-layouts/src/dashboard-layout/dashboard-layout-model";
import { PlacementRecordDashboardElementDefinition, PlacementRecordDashboardElementType } from "common/models/Dashboard/EditLayout";
import { ScreenLayoutSettings } from "common/models/ScreenLayouts/ScreenLayouts";
import { GetCustomFieldTypeById } from "../ScreenLayouts/CustomFields";

export const DefaultPlacementQuickViewLayoutElements_Permanent: PlacementRecordDashboardElementDefinition[] = [
    { id: "PlacementId_Default", row: 0, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementId' },
    { id: "PlacementJobId_Default", row: 1, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementJobId' },
    { id: "PlacementType_Default", row: 2, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementType' },
    { id: "PlacementJobTitle_Default", row: 3, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementJobTitle' },
    { id: "PlacementCandidate_Default", row: 4, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementCandidate' },
    { id: "PlacementStartDate_Default", row: 5, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementStartDate' },
    { id: "PlacementEndDate_Default", row: 6, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementEndDate' },
    
    { id: "PlacementClient_Default", row: 0, col: 2, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementClient' },
    { id: "PlacementContact_Default", row: 1, col: 2, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementContact' },
    { id: "PlacementHiringManager_Default", row: 2, col: 2, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementHiringManager' },
    { id: "PlacementBilling1_Default", row: 3, col: 2, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementBilling1' },
    { id: "PlacementClientRef_Default", row: 4, col: 2, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementClientRef' },
    { id: "PlacementSite_Default", row: 5, col: 2, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementSite' },
    
    { id: "Divider_Default", row: 7, col: 0, sizeX: 4, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'Divider' },
    
    { id: "PlacementCurrency_Default", row: 8, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementCurrency' },
    { id: "PlacementSalaryPackage_Default", row: 9, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementSalaryPackage' },
    { id: "PlacementInvoiceFee_Default", row: 10, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementInvoiceFee' },
    { id: "PlacementComments_Default", row: 11, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementComments' },
    { id: "PlacementWarranty_Default", row: 12, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementWarranty' },
    
    { id: "PlacementStatus_Default", row: 8, col: 2, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementStatus' },
    { id: "PlacementConsultant1_Default", row: 9, col: 2, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementConsultant1' },
    { id: "PlacementSource_Default", row: 10, col: 2, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementSource' },
    { id: "PlacementNetRevenue_Default", row: 11, col: 2, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementNetRevenue' },
    { id: "PlacementDate_Default", row: 12, col: 2, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementDate' },
    { id: "PlacementHoursPerDay_Default", row: 13, col: 2, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementHoursPerDay' },
    { id: "PlacementProbation_Default", row: 14, col: 2, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementProbation' },
    { id: "PlacementNotice_Default", row: 15, col: 2, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementNotice' },
    { id: "PlacementPayFreq_Default", row: 16, col: 2, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementPayFreq' },
];

export const DefaultPlacementQuickViewLayoutElements_Contract: PlacementRecordDashboardElementDefinition[] = [
    { id: "PlacementId_Default", row: 0, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementId' },
    { id: "PlacementJobId_Default", row: 1, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementJobId' },
    { id: "PlacementType_Default", row: 2, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementType' },
    { id: "PlacementJobTitle_Default", row: 3, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementJobTitle' },
    { id: "PlacementCandidate_Default", row: 4, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementCandidate' },
    { id: "PlacementStartDate_Default", row: 5, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementStartDate' },
    { id: "PlacementEndDate_Default", row: 6, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementEndDate' },
    
    { id: "PlacementClient_Default", row: 0, col: 2, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementClient' },
    { id: "PlacementContact_Default", row: 1, col: 2, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementContact' },
    { id: "PlacementHiringManager_Default", row: 2, col: 2, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementHiringManager' },
    { id: "PlacementBilling1_Default", row: 3, col: 2, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementBilling1' },
    { id: "PlacementClientRef_Default", row: 4, col: 2, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementClientRef' },
    { id: "PlacementSite_Default", row: 5, col: 2, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementSite' },
    
    { id: "Divider_Default", row: 7, col: 0, sizeX: 4, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'Divider' },
    
    { id: "PlacementCurrency_Default", row: 8, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementCurrency' },
    { id: "PlacementChargeRate_Default", row: 9, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementChargeRate' },
    { id: "PlacementPayRate_Default", row: 10, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementPayRate' },
    { id: "PlacementOnCost_Default", row: 11, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementOnCost' },
    { id: "PlacementMargin_Default", row: 12, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementMargin' },
    { id: "PlacementComments_Default", row: 13, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementComments' },
    { id: "PlacementPaymentType_Default", row: 14, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementPaymentType' },
    { id: "PlacementPayCompany_Default", row: 15, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementPayCompany' },
    { id: "PlacementPayContact_Default", row: 16, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementPayContact' },
    
    { id: "PlacementStatus_Default", row: 8, col: 2, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementStatus' },
    { id: "PlacementConsultant1_Default", row: 9, col: 2, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementConsultant1' },
    { id: "PlacementSource_Default", row: 10, col: 2, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementSource' },
    { id: "PlacementNetRevenue_Default", row: 11, col: 2, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementNetRevenue' },
    { id: "PlacementDate_Default", row: 12, col: 2, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementDate' },
    { id: "PlacementHoursPerDay_Default", row: 13, col: 2, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementHoursPerDay' },
    { id: "PlacementProbation_Default", row: 14, col: 2, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementProbation' },
    { id: "PlacementNotice_Default", row: 15, col: 2, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementNotice' },
    { id: "PlacementPayFreq_Default", row: 16, col: 2, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementPayFreq' },
];

export const DefaultPlacementQuickViewLayoutElements_FixedTermContract: PlacementRecordDashboardElementDefinition[] = [
    { id: "PlacementId_Default", row: 0, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementId' },
    { id: "PlacementJobId_Default", row: 1, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementJobId' },
    { id: "PlacementType_Default", row: 2, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementType' },
    { id: "PlacementJobTitle_Default", row: 3, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementJobTitle' },
    { id: "PlacementCandidate_Default", row: 4, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementCandidate' },
    { id: "PlacementStartDate_Default", row: 5, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementStartDate' },
    { id: "PlacementEndDate_Default", row: 6, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementEndDate' },
    
    { id: "PlacementClient_Default", row: 0, col: 2, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementClient' },
    { id: "PlacementContact_Default", row: 1, col: 2, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementContact' },
    { id: "PlacementHiringManager_Default", row: 2, col: 2, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementHiringManager' },
    { id: "PlacementBilling1_Default", row: 3, col: 2, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementBilling1' },
    { id: "PlacementClientRef_Default", row: 4, col: 2, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementClientRef' },
    { id: "PlacementSite_Default", row: 5, col: 2, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementSite' },
    
    { id: "Divider_Default", row: 7, col: 0, sizeX: 4, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'Divider' },
    
    { id: "PlacementCurrency_Default", row: 8, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementCurrency' },
    { id: "PlacementSalaryPackage_Default", row: 9, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementSalaryPackage' },
    { id: "PlacementInvoiceFee_Default", row: 10, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementInvoiceFee' },
    { id: "PlacementComments_Default", row: 11, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementComments' },
    { id: "PlacementWarranty_Default", row: 12, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementWarranty' },
    
    { id: "PlacementStatus_Default", row: 8, col: 2, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementStatus' },
    { id: "PlacementConsultant1_Default", row: 9, col: 2, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementConsultant1' },
    { id: "PlacementSource_Default", row: 10, col: 2, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementSource' },
    { id: "PlacementNetRevenue_Default", row: 11, col: 2, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementNetRevenue' },
    { id: "PlacementDate_Default", row: 12, col: 2, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementDate' },
    { id: "PlacementHoursPerDay_Default", row: 13, col: 2, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementHoursPerDay' },
    { id: "PlacementProbation_Default", row: 14, col: 2, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementProbation' },
    { id: "PlacementNotice_Default", row: 15, col: 2, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementNotice' },
    { id: "PlacementPayFreq_Default", row: 16, col: 2, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementPayFreq' },
];

export const DefaultPlacementQuickViewLayoutElements_MaxTermContract: PlacementRecordDashboardElementDefinition[] = [
    { id: "PlacementId_Default", row: 0, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementId' },
    { id: "PlacementJobId_Default", row: 1, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementJobId' },
    { id: "PlacementType_Default", row: 2, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementType' },
    { id: "PlacementJobTitle_Default", row: 3, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementJobTitle' },
    { id: "PlacementCandidate_Default", row: 4, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementCandidate' },
    { id: "PlacementStartDate_Default", row: 5, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementStartDate' },
    { id: "PlacementEndDate_Default", row: 6, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementEndDate' },
    
    { id: "PlacementClient_Default", row: 0, col: 2, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementClient' },
    { id: "PlacementContact_Default", row: 1, col: 2, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementContact' },
    { id: "PlacementHiringManager_Default", row: 2, col: 2, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementHiringManager' },
    { id: "PlacementBilling1_Default", row: 3, col: 2, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementBilling1' },
    { id: "PlacementClientRef_Default", row: 4, col: 2, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementClientRef' },
    { id: "PlacementSite_Default", row: 5, col: 2, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementSite' },
    
    { id: "Divider_Default", row: 7, col: 0, sizeX: 4, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'Divider' },
    
    { id: "PlacementStatus_Default", row: 8, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementStatus' },
    { id: "PlacementConsultant1_Default", row: 9, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementConsultant1' },
    { id: "PlacementSource_Default", row: 10, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementSource' },
    { id: "PlacementNetRevenue_Default", row: 11, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementNetRevenue' },
    { id: "PlacementDate_Default", row: 12, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementDate' },
    { id: "PlacementHoursPerDay_Default", row: 13, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementHoursPerDay' },
    { id: "PlacementProbation_Default", row: 14, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementProbation' },
    { id: "PlacementNotice_Default", row: 15, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementNotice' },
    { id: "PlacementPayFreq_Default", row: 16, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementPayFreq' },
];

export const DefaultPlacementQuickViewLayoutElements_MarginOnlyContract: PlacementRecordDashboardElementDefinition[] = [
    { id: "PlacementId_Default", row: 0, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementId' },
    { id: "PlacementJobId_Default", row: 1, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementJobId' },
    { id: "PlacementType_Default", row: 2, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementType' },
    { id: "PlacementJobTitle_Default", row: 3, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementJobTitle' },
    { id: "PlacementCandidate_Default", row: 4, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementCandidate' },
    { id: "PlacementStartDate_Default", row: 5, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementStartDate' },
    { id: "PlacementEndDate_Default", row: 6, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementEndDate' },
    
    { id: "PlacementClient_Default", row: 0, col: 2, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementClient' },
    { id: "PlacementContact_Default", row: 1, col: 2, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementContact' },
    { id: "PlacementHiringManager_Default", row: 2, col: 2, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementHiringManager' },
    { id: "PlacementBilling1_Default", row: 3, col: 2, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementBilling1' },
    { id: "PlacementClientRef_Default", row: 4, col: 2, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementClientRef' },
    { id: "PlacementSite_Default", row: 5, col: 2, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementSite' },
    
    { id: "Divider_Default", row: 7, col: 0, sizeX: 4, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'Divider' },
    
    { id: "PlacementCurrency_Default", row: 8, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementCurrency' },
    { id: "PlacementChargeRate_Default", row: 9, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementChargeRate' },
    { id: "PlacementPayRate_Default", row: 10, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementPayRate' },
    { id: "PlacementOnCost_Default", row: 11, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementOnCost' },
    { id: "PlacementMargin_Default", row: 12, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementMargin' },
    { id: "PlacementComments_Default", row: 13, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementComments' },
    { id: "PlacementPaymentType_Default", row: 14, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementPaymentType' },
    { id: "PlacementPayCompany_Default", row: 15, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementPayCompany' },
    { id: "PlacementPayContact_Default", row: 16, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementPayContact' },
    
    { id: "PlacementStatus_Default", row: 8, col: 2, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementStatus' },
    { id: "PlacementConsultant1_Default", row: 9, col: 2, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementConsultant1' },
    { id: "PlacementSource_Default", row: 10, col: 2, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementSource' },
    { id: "PlacementNetRevenue_Default", row: 11, col: 2, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementNetRevenue' },
    { id: "PlacementDate_Default", row: 12, col: 2, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementDate' },
    { id: "PlacementHoursPerDay_Default", row: 13, col: 2, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementHoursPerDay' },
    { id: "PlacementProbation_Default", row: 14, col: 2, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementProbation' },
    { id: "PlacementNotice_Default", row: 15, col: 2, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementNotice' },
    { id: "PlacementPayFreq_Default", row: 16, col: 2, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'PlacementPayFreq' },
];

export const GetMinSizeByElementType = (type: PlacementRecordDashboardElementType) => {
    switch (type) {
        case "PlacementSummary": return [1, 9];
        case "PlacementLocation": return [1, 9];
        default: return [1, 1];
    }
};

export const GetElementTypeById = (id: string): PlacementRecordDashboardElementType => {
    if ( id && id.startsWith('Divider_')) return 'Divider';
    if ( id && id.startsWith('Spacer_')) return 'Spacer';
    if (id && id.startsWith('PlacementId_')) return 'PlacementId';
    if (id && id.startsWith('PlacementJobId_')) return 'PlacementJobId';
    if (id && id.startsWith('PlacementType_')) return 'PlacementType';
    if (id && id.startsWith('PlacementJobTitle_')) return 'PlacementJobTitle';
    if (id && id.startsWith('PlacementCandidate_')) return 'PlacementCandidate';
    if (id && id.startsWith('PlacementStartDate_')) return 'PlacementStartDate';
    if (id && id.startsWith('PlacementEndDate_')) return 'PlacementEndDate';
    if (id && id.startsWith('PlacementDivision_')) return 'PlacementDivision';
    if (id && id.startsWith('PlacementPayDivision_')) return 'PlacementPayDivision';
    if (id && id.startsWith('PlacementCurrency_')) return 'PlacementCurrency';
    if (id && id.startsWith('PlacementRatesCalculator_')) return 'PlacementRatesCalculator';
    if (id && id.startsWith('PlacementChargeRate_')) return 'PlacementChargeRate';
    if (id && id.startsWith('PlacementPayRate_')) return 'PlacementPayRate';
    if (id && id.startsWith('PlacementOnCost_')) return 'PlacementOnCost';
    if (id && id.startsWith('PlacementMargin_')) return 'PlacementMargin';
    if (id && id.startsWith('PlacementSalaryPackage_')) return 'PlacementSalaryPackage';
    if (id && id.startsWith('PlacementInvoiceFee_')) return 'PlacementInvoiceFee';
    if (id && id.startsWith('PlacementHoursPerDay_')) return 'PlacementHoursPerDay';
    if (id && id.startsWith('PlacementDaysPerWeek_')) return 'PlacementDaysPerWeek';
    if (id && id.startsWith('PlacementComments_')) return 'PlacementComments';
    if (id && id.startsWith('PlacementPaymentType_')) return 'PlacementPaymentType';
    if (id && id.startsWith('PlacementPayCompany_')) return 'PlacementPayCompany';
    if (id && id.startsWith('PlacementPayContact_')) return 'PlacementPayContact';
    if (id && id.startsWith('PlacementWarranty_')) return 'PlacementWarranty';
    if (id && id.startsWith('PlacementLocation_')) return 'PlacementLocation';
    if (id && id.startsWith('PlacementSite_')) return 'PlacementSite';
    if (id && id.startsWith('PlacementClient_')) return 'PlacementClient';
    if (id && id.startsWith('PlacementContact_')) return 'PlacementContact';
    if (id && id.startsWith('PlacementHiringManager_')) return 'PlacementHiringManager';
    if (id && id.startsWith('PlacementBilling1_')) return 'PlacementBilling1';
    if (id && id.startsWith('PlacementBilling2_')) return 'PlacementBilling2';
    if (id && id.startsWith('PlacementBilling3_')) return 'PlacementBilling3';
    if (id && id.startsWith('PlacementSignatory_')) return 'PlacementSignatory';
    if (id && id.startsWith('PlacementClientRef_')) return 'PlacementClientRef';
    if (id && id.startsWith('PlacementApprover1_')) return 'PlacementApprover1';
    if (id && id.startsWith('PlacementApprover2_')) return 'PlacementApprover2';
    if (id && id.startsWith('PlacementSummary_')) return 'PlacementSummary';
    if (id && id.startsWith('PlacementStatus_')) return 'PlacementStatus';
    if (id && id.startsWith('PlacementConsultant1_')) return 'PlacementConsultant1';
    if (id && id.startsWith('PlacementConsultant2_')) return 'PlacementConsultant2';
    if (id && id.startsWith('PlacementConsultant3_')) return 'PlacementConsultant3';
    if (id && id.startsWith('PlacementConsultant4_')) return 'PlacementConsultant4';
    if (id && id.startsWith('PlacementConsultant5_')) return 'PlacementConsultant5';
    if (id && id.startsWith('PlacementSource_')) return 'PlacementSource';
    if (id && id.startsWith('PlacementNetRevenue_')) return 'PlacementNetRevenue';
    if (id && id.startsWith('PlacementDate_')) return 'PlacementDate';
    if (id && id.startsWith('PlacementProbation_')) return 'PlacementProbation';
    if (id && id.startsWith('PlacementNotice_')) return 'PlacementNotice';
    if (id && id.startsWith('PlacementPayFreq_')) return 'PlacementPayFreq';
    if (id && id.startsWith('PlacementComplianceChecklist_')) return 'PlacementComplianceChecklist';
    if ( id && id.startsWith('CustomField')) return GetCustomFieldTypeById(id);
    return '';
};

export const GetPanelDefinitionsFromPanelModels = (panels: PanelModel[]) => {
    let elements: PlacementRecordDashboardElementDefinition[] = [];
    panels.forEach((p, i) => {
        let type = GetElementTypeById(p.id ?? '');
        elements.push({
            id: p.id ?? i.toString(),
            sizeX: p.sizeX ?? 1,
            sizeY: p.sizeY ?? 1,
            col: p.col ?? 0,
            row: p.row ?? 0,
            minSizeX: p.minSizeX ?? 1,
            minSizeY: p.minSizeY ?? 1,
            maxSizeX: p.maxSizeX ?? 1,
            maxSizeY: p.maxSizeY ?? null,
            type: type
        });
    });
    return elements ;
};

export const PlacementQuickViewLayoutSettings: ScreenLayoutSettings = {
    unitWidth: 228,
    unitHeight: 40,
    gapX: 10,
    gapY: 0,
    columns: 4
};