import MainDashboardLayout from "components/Dashboards/MainDashboardLayout";
import OnlyTitleSummaryBar from "components/SummaryBars/OnlyTitleSummaryBar";
import PageContentLayout from "layouts/PageContentLayout";
import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import ShareIcon from "@mui/icons-material/Share";
import TitleAndActionSummaryBar from "../../../components/SummaryBars/TitleAndActionSummaryBar";
import PageLayout from "../../../layouts/PageLayout";

interface Props {
    contentPaddingHandler?: (hasPadding: boolean) => void,
}

const SummaryBar = <OnlyTitleSummaryBar title="Analytics > My Dashboard" browserTabTitle="My Dashboard > Analytics" />;

export default function MyDashboardPage() {
    const [summaryBar, setSummaryBar] = useState(<></>);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const summaryBar = <OnlyTitleSummaryBar title="Analytics > Dashboards > My Dashboard" browserTabTitle="My Dashboard > Dashboards > Analytics" />
        setSummaryBar && setSummaryBar(summaryBar);
    }, []);
    
    return (
        <PageLayout SummaryBar={summaryBar}>
            <PageContentLayout showLoading={isLoading} >
                <MainDashboardLayout loadingHandler={setIsLoading} />
            </PageContentLayout>
        </PageLayout>
    );
}