import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { SxProps } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { GridColDef, useGridApiRef } from "@mui/x-data-grid-premium";
import { DatePicker } from "@mui/x-date-pickers-pro";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ActivitySummaryReportData } from "common/models/Reports/ActivitySummary";
import { GetActivitySummaryReportData } from "services/ActivitiesService";
import GridWithStateWrapper from "components/GridWidthStateWrapper";
import UserPicker from "components/Pickers/UserPicker";
import { NameIdObj } from "common/models/GenericTypes";

interface Props {
    description: string,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void
}

const ownerSpecialOptions: NameIdObj[] = [
    { id: 0, name: 'My Company' },
    { id: -1, name: 'Me' },
    { id: -2, name: 'My Team' },
    { id: -3, name: 'Unassigned' },
];

const filterElementStyle: SxProps = { flex: '1 1 0', mr: 1 };

export default function ActivitySummaryReport({ description, loadingHandler, errorHandler }: Props) {
    const [rows, setRows] = useState<ActivitySummaryReportData[]>([]);
    const [showGrid, setShowGrid] = useState(false);
    const [ownerId, setOwnerId] = useState(0);
    const [startDate, setStartDate] = useState<moment.Moment | null>(null);
    const [endDate, setEndDate] = useState<moment.Moment | null>(null);
    const [using, setUsing] = useState(0);
    const [status, setStatus] = useState(0);
    const apiRef = useGridApiRef();

    useEffect(() => {
        setStartDate(moment().startOf('month'));
        setEndDate(moment());
    }, []);

    const getDataCallback = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        const stringStartDate = startDate && startDate.isValid() ? startDate.format('YYYY-MM-DD') : '';
        const stringEndDate = endDate && endDate.isValid() ? endDate.format('YYYY-MM-DD') : '';
        const res = await GetActivitySummaryReportData(ownerId, status, stringStartDate, stringEndDate, using, errorHandler);
        if (res) {
            setShowGrid(true);
            setRows(res);
        }
        loadingHandler && loadingHandler(false);
    }, [ownerId, status, startDate, endDate, using, errorHandler, loadingHandler]);

    const exportAsExcelHandler = useCallback(() => {
        const api = apiRef.current;
        if (api) {
            const m = moment();
            const filename = `ActivitySummary${m.format('YYYYMMDDhhmmss')}`;
            api.exportDataAsExcel({ fileName: filename });
        }
    }, [apiRef]);

    const columns = useMemo<GridColDef[]>(() => {

        return [
            { field: 'Consultant', width: 250 },
            { field: 'ActivityType', headerName: 'Activity Type', width: 200 },
            { field: 'Count', width: 200 },
        ];
    }, []);

    return (
        <>
            <Box display="flex">
                <Box flex="1 1 0" pr={1}>
                    <UserPicker disableClearable label="Owner" userId={ownerId} onSelect={u => setOwnerId(u ? u.id : 0)} appendToStart={ownerSpecialOptions} includeInactiveUsers={true} />
                </Box>
                <TextField select label="Status" value={status.toString()} onChange={({ target }) => setStatus(+target.value)} sx={filterElementStyle}>
                    <MenuItem value="0">All</MenuItem>
                    <MenuItem value="1">Opened</MenuItem>
                    <MenuItem value="2">Closed</MenuItem>
                </TextField>
                <DatePicker label="From Date" value={startDate} onChange={m => setStartDate(m)} sx={filterElementStyle} slotProps={{actionBar: { actions: ["clear", "today", "cancel", "accept"] }}} />
                <DatePicker label="To Date" value={endDate} onChange={m => setEndDate(m)} sx={filterElementStyle} slotProps={{actionBar: { actions: ["clear", "today", "cancel", "accept"] }}} />

                <TextField select label="Using" value={using.toString()} onChange={({ target }) => setUsing(+target.value)} sx={filterElementStyle}>
                    <MenuItem value="0">Activity Date</MenuItem>
                    <MenuItem value="1">Created Date</MenuItem>
                </TextField>
                <Button variant="contained" color="success" onClick={getDataCallback}>Run Report</Button>
            </Box>
            <Typography component="div" variant="h6" mt={2}>Report Description</Typography>
            <Typography component="div" variant="body2">{description}</Typography>
            {showGrid &&
                <>
                    <Box pb="10px" ml="auto">
                        <Button variant="contained" color="success" onClick={exportAsExcelHandler}>Export As Excel</Button>
                    </Box>
                    <GridWithStateWrapper
                        density="compact"
                        gridName="reports-activitySummary"
                        rows={rows}
                        columns={columns}
                        apiRef={apiRef}
                        getRowId={r => r.Consult + "-" + r.ActivityType + "-" + r.Count}
                        disableRowSelectionOnClick
                    />
                </>
            }
        </>
    );
}