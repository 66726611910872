import React from "react";
import EmailIntegrationExclusionsPageContent from "components/Content/EmailIntegrationExclusionsPageContent";

interface Props {
    setSummaryBar?: (summaryBar: JSX.Element) => void
}

export default function ExclusionListAdmin({ setSummaryBar }: Props) {
    return (
        <EmailIntegrationExclusionsPageContent
            listType="Customer"
            summaryBarTabTitle="Email Integration > Configuration"
            summaryBarTitle="Configuration > Email Integration > Exclusion List"
            setSummaryBar={setSummaryBar}
        />
    );
}