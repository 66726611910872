import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import moment from "moment";
import React, { useCallback, useState } from "react";
import * as XLSX from "xlsx";
import { GetUserBillingsDetailedReportData } from "services/PlacementsService";
import UserPicker from "components/Pickers/UserPicker";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import {SxProps} from "@mui/material/styles";

const filterElementStyle: SxProps = { flex: '1 1 0', mr: 1 };

interface Props {
    description: string,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void
}

export default function UserBillingsDetailedReport({ description, loadingHandler, errorHandler }: Props) {
    const [ownerId, setOwnerId] = useState(0);
    const [reportingPeriod, setReportingPeriod] = useState(3);

    const getDataCallback = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        const res = await GetUserBillingsDetailedReportData(ownerId, reportingPeriod, errorHandler);
        const m = moment();
        const filename = `Billings_User_Detailed${m.format('YYYYMMDDhhmmss')}.xlsx`;
        const wb = XLSX.utils.book_new();
        if (res["data1"]) {
            const ws = XLSX.utils.json_to_sheet(res["data1"]);
            XLSX.utils.book_append_sheet(wb, ws, "Overview");
        }
        if (res["data2"]) {
            const ws = XLSX.utils.json_to_sheet(res["data2"]);
            XLSX.utils.book_append_sheet(wb, ws, "Contractors - Actuals");
        }
        
        if (res["data3"]) {
            const ws = XLSX.utils.json_to_sheet(res["data3"]);
            XLSX.utils.book_append_sheet(wb, ws, "Contractors - Estimated");
        }
        
        if (res["data4"]) {
            const ws = XLSX.utils.json_to_sheet(res["data4"]);
            XLSX.utils.book_append_sheet(wb, ws, "Permanent Placements");
        }
        XLSX.writeFile(wb, filename);
        loadingHandler && loadingHandler(false);
    }, [ownerId, reportingPeriod, errorHandler, loadingHandler]);

    return (
        <>
            <Box display="flex">
                <Box flex="1 1 0" pr={1}>
                    <UserPicker disableClearable label="Consultant" userId={ownerId} onSelect={u => setOwnerId(u ? u.id : 0)} includeInactiveUsers={true} />
                </Box>
                <TextField select label="Reporting Period" value={reportingPeriod.toString()} onChange={({ target }) => setReportingPeriod(+target.value)} sx={filterElementStyle}>
                    <MenuItem value="1">This Month</MenuItem>
                    <MenuItem value="2">This Qtr (Financial)</MenuItem>
                    <MenuItem value="3">This Year (Financial)</MenuItem>
                    <MenuItem value="4">Last Month</MenuItem>
                    <MenuItem value="5">Last Qtr (Financial)</MenuItem>
                    <MenuItem value="6">Last Year (Financial)</MenuItem> 
                </TextField>
                <Button variant="contained" color="success" onClick={getDataCallback}>Run Report</Button>
            </Box>
            <Typography component="div" variant="h6" mt={2}>Report Description</Typography>
            <Typography component="div" variant="body2">{description}</Typography>
        </>
    );
}