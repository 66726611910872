import Alert from "components/Alert";
import Snackbar from "@mui/material/Snackbar";
import React, { useEffect, useState } from "react";
import OnlyTitleSummaryBar from "components/SummaryBars/OnlyTitleSummaryBar";
import PageContentLayout from "layouts/PageContentLayout";
import QuickViewLayoutAdmin from "components/QuickViewLayouts/Opportunities/QuickViewLayoutAdmin";

interface Props {
    opportunityType: 'BackFill' | 'Bid' | 'Float' | 'General' | 'Lead' | 'Tender',
    setSummaryBar?: (summaryBar: JSX.Element) => void
}

export default function OpportunitiesQuickViewLayoutPage({ opportunityType, setSummaryBar }: Props) {
    const [isLoading, setIsloading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [opportunityTypeSwitch, setOpportunityTypeSwitch] = useState(false);

    useEffect(() => {
        if (opportunityType) {}
        setOpportunityTypeSwitch(prev => !prev);
    }, [opportunityType]);

    useEffect(() => {
        if (setSummaryBar) {
            let ot: string = opportunityType;
            if (opportunityType === 'BackFill') ot = 'Back Fill';
            const summaryBar = <OnlyTitleSummaryBar title={`Configuration > Screen Layouts > ${ot} Opportunities - Quick View`} browserTabTitle="Screen Layouts > Configuration" />;
            setSummaryBar(summaryBar);
        }
    }, [opportunityType, setSummaryBar]);
    
    return (
        <>
            <Snackbar open={errorMessage !== ''} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <PageContentLayout showLoading={isLoading}>
                { opportunityTypeSwitch && <QuickViewLayoutAdmin loadingHandler={setIsloading} errorHandler={setErrorMessage} opportunityType={opportunityType} /> }
                { !opportunityTypeSwitch && <QuickViewLayoutAdmin loadingHandler={setIsloading} errorHandler={setErrorMessage} opportunityType={opportunityType} /> }
            </PageContentLayout>
        </>
    );
}