import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { SxProps } from "@mui/material/styles";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { FilterWithTimeRange } from "common/models/Analytics/AnalyticsFilterData";
import { PerformanceActivityRatioRecord, TypeActualObj } from "common/models/Analytics/BusinessIntelligence";
import { NameIdObj } from "common/models/GenericTypes";
import MultipleDivisionsPicker from "components/Pickers/MultipleDivisionPicker";
import MultipleGroupPicker from "components/Pickers/MultipleGroupPicker";
import MultipleUserPicker from "components/Pickers/MultipleUserPicker";
import PageContentLayout from "layouts/PageContentLayout";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { GetPerformanceActivityRatiosData } from "services/BusinessIntelligence";
import ShareIcon from '@mui/icons-material/Share';
import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import Avatar from "@mui/material/Avatar";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useNavigate } from "react-router";
import SimpleGaugeGraph from "components/Analytics/Graphs/SimpleGaugeGraph";
import { GetCustomerSettingBySettingName } from "services/ConfigurationService";
import { GetDatesFromTimeRangeFilter } from "util/DateUtils";
import PageLayout from "../../../layouts/PageLayout";
import {Link} from "react-router-dom";

type RatioField = { field: keyof PerformanceActivityRatioRecord, title: string };

const dataFields: RatioField[] = [
    { field: 'jobsToPlacementsRatio', title: 'Jobs To Placements Ratio' },
    { field: 'submissionsToFirstInterviewRatio', title: 'Submissions To First Interview Ratio' },
    { field: 'firstInterviewToSecondInterviewRatio', title: 'First Interview To Second Interview Ratio' },
    { field: 'firstInterviewToOfferRatio', title: 'First Interview To Offer Ratio' },
    { field: 'submissionsToPlacementsRatio', title: 'Submissions To Placements Ratio' },
    { field: 'firstInterviewToPlacementRatio', title: 'First Interview To Placement Ratio' },
    { field: 'offersToPlacementsRatio', title: 'Offers To Placements Ratio' },
];

const filterElementStyle: SxProps = { flex: '1 1 0' };
const defaultTimeRange = 4;

export default function PerformanceActivityRatiosPage() {
    const [summaryBar, setSummaryBar] = useState(<></>);
    const [isLoading, setIsLoading] = useState(false);
    const [sourceType, setSourceType] = useState(1);
    const [timeRange, setTimeRange] = useState(defaultTimeRange);
    const [startDate, setStartDate] = useState<moment.Moment | null>(null);
    const [endDate, setEndDate] = useState<moment.Moment | null>(null);
    const [showGrid, setShowGrid] = useState(false);
    const [ratiosData, setRatiosData] = useState<PerformanceActivityRatioRecord>();
    const [selectedDivisions, setSelectedDivisions] = useState<number[]>([]);
    const [selectedGroups, setSelectedGroups] = useState<number[]>([]);
    const [selectedUsers, setSelectedUsers] = useState<number[]>([]);
    const [infoMessage, setInfoMessage] = useState('');
    const [shouldRunReport, setShouldRunReport] = useState(false);
    const [isInitialSetupFinished, setIsInitialSetupFinished] = useState(false);
    const [isInitialQuerySetupFinished, setIsInitialQuerySetupFinished] = useState(false);
    const [currentFilterData, setCurrentFilterData] = useState<FilterWithTimeRange | null>(null);
    const [financialYearStartMonth, setFinancialYearStartMonth] = useState(1);
    const navigate = useNavigate();

    useEffect(() => {
        const getYearStart = async () => {
            const res = await GetCustomerSettingBySettingName('YearStart');
            if (res) {
                const parsed = +res;
                if (parsed >= 1 && parsed <= 12) setFinancialYearStartMonth(parsed);
            }
            setIsInitialSetupFinished(true);
        };
        getYearStart();
    }, []);

    useEffect(() => {
        if (isInitialSetupFinished) {
            const urlParams = new URLSearchParams(window.location.search);
            const st = urlParams.get('sourceType');
            const sid = urlParams.get('sourceIds');
            const tr = urlParams.get('timeRange');
            const sd = urlParams.get('startDate');
            const ed = urlParams.get('endDate');
            let parsedSt = 1;
    
            if (st) {
                const p = Number(st);
                if (p >= 1 && p <= 4) {
                    parsedSt = p;
                    setSourceType(p);
                }
            }
            if (sid) {
                const ids: number[] = sid.split(',').map(n => Number(n));
                if (parsedSt === 2) setSelectedDivisions(ids);
                else if (parsedSt === 3) setSelectedGroups(ids);
                else if (parsedSt === 4) setSelectedUsers(ids);
            }
            if (tr && tr !== '0') {
                const p = Number(tr);
                if (p >=1 && p <= 16) {
                    const dates = GetDatesFromTimeRangeFilter(p, financialYearStartMonth);
                    if (dates) {
                        const [start, end] = dates;
                        setTimeRange(p);
                        setStartDate(start);
                        setEndDate(end);
                    }
                }
            }
            else if (tr === '0') {
                if (sd) {
                    const m = moment(sd);
                    if (m.isValid()) setStartDate(m);
                }
                if (ed) {
                    const m = moment(ed);
                    if (m.isValid()) setEndDate(m);
                }
                if (ed || sd) setTimeRange(0);
            }
            else {
                const dates = GetDatesFromTimeRangeFilter(defaultTimeRange, financialYearStartMonth);
                if (dates) {
                    const [start, end] = dates;
                    setStartDate(start);
                    setEndDate(end);
                }
            }
    
            setShouldRunReport(true);
            setIsInitialQuerySetupFinished(true);
        }
    }, [financialYearStartMonth, isInitialSetupFinished]);

    const sourceIds = useMemo(() => {
        let sourceIds = '';
        if (sourceType === 2) sourceIds = selectedDivisions.join(',');
        else if (sourceType === 3) sourceIds = selectedGroups.join(',');
        else if (sourceType === 4) sourceIds = selectedUsers.join(',');
        return sourceIds;
    }, [selectedDivisions, selectedGroups, selectedUsers, sourceType]);

    const generatQueryString = useCallback(() => {
        const sd = timeRange === 0 && startDate ? startDate.format('YYYY-MM-DD') : '';
        const ed = timeRange === 0 && endDate ? endDate.format('YYYY-MM-DD') : '';
        if (sourceType === 1) return timeRange === 0 ? `?sourceType=${sourceType}&startDate=${sd}&endDate=${ed}` : `?sourceType=${sourceType}&timeRange=${timeRange}`;
        else {
            return timeRange === 0 ? `?sourceType=${sourceType}&startDate=${sd}&endDate=${ed}&sourceIds=${encodeURIComponent(sourceIds)}` : `?sourceType=${sourceType}&timeRange=${timeRange}&sourceIds=${encodeURIComponent(sourceIds)}`;
        }
    }, [endDate, sourceIds, sourceType, startDate, timeRange]);

    const shareActionCallback = useCallback(() => {
        let url = window.location.protocol + '//' + window.location.host + window.location.pathname + generatQueryString();
        navigator.clipboard.writeText(url);
        setInfoMessage('Link copied to clipboard');
    }, [generatQueryString]);

    useEffect(() => {
        if (isInitialQuerySetupFinished) {
            let url = window.location.pathname + generatQueryString();
            navigate(url);
        }
    }, [generatQueryString, isInitialQuerySetupFinished, navigate]);

    useEffect(() => {
        const action = (
            <Avatar
                key="splitsAction"
                onClick={shareActionCallback}
                title="Copy link to clipboard"
                sx={{ bgcolor: t => t.palette.primary.main, mr: '5px', cursor: 'pointer' }}
            >
                <ShareIcon />
            </Avatar>
        );
        const reportsLink = <Link to="/analytics/misc" style={{ color: 'inherit', textDecoration: 'underline' }}>Misc</Link>;

        const title = <>{'Analytics > '} {reportsLink} {' > ActivityRatios'}</>;
        const tabTitle = 'Activity Ratios > Misc > Analytics';

        const summaryBar = <TitleAndActionSummaryBar title={title} browserTabTitle={tabTitle} action={action} />;
        setSummaryBar(summaryBar);
    }, [shareActionCallback]);
    
    useEffect(() => {
        if (isInitialQuerySetupFinished) {
            const dates = GetDatesFromTimeRangeFilter(timeRange, financialYearStartMonth);
            if (dates) {
                const [start, end] = dates;
                setStartDate(start);
                setEndDate(end);
            }
        }
    }, [financialYearStartMonth, isInitialQuerySetupFinished, timeRange]);

    const getDataCallback = useCallback(async () => {
        const f: FilterWithTimeRange = {
            endDate: endDate ? endDate.format('YYYY-MM-DD') : '',
            startDate: startDate ? startDate.format('YYYY-MM-DD') : '',
            userFilterID: sourceIds,
            userFilterTypeID: sourceType
        };

        setCurrentFilterData(f);

        setIsLoading(true);
        const res = await GetPerformanceActivityRatiosData(f);
        if (res && res[0]) {
            // const data: TypeActualObjWithId[] = res.map((r, i) => ({ ...r, id: i }));
            setRatiosData(res[0]);
        }
        setShowGrid(true);
        setIsLoading(false);
    }, [endDate, sourceIds, sourceType, startDate]);

    useEffect(() => {
        if (shouldRunReport) {
            getDataCallback();
            setShouldRunReport(false);
        }
    }, [getDataCallback, shouldRunReport]);

    const onTimeRangeChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const v = +e.target.value;
        setTimeRange(v);
    }, []);

    const onSourceTypeChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const v = +e.target.value;
        setSourceType(v);
    }, []);

    const onDivisionsChange = useCallback((divisions: NameIdObj[]) => {
        setSelectedDivisions(divisions.map(u => u.id));
    }, []);

    const onGroupsChange = useCallback((groups: NameIdObj[]) => {
        setSelectedGroups(groups.map(u => u.id));
    }, []);

    const onUsersChange = useCallback((users: NameIdObj[]) => {
        setSelectedUsers(users.map(u => u.id));
    }, []);

    const isRunButtonEnabled = useMemo(() => {
        if (currentFilterData) {
            let sDate = startDate ? startDate.format('YYYY-MM-DD') : '';
            let eDate = endDate ? endDate.format('YYYY-MM-DD') : '';
            return currentFilterData.startDate !== sDate || currentFilterData.endDate !== eDate || currentFilterData.userFilterID !== sourceIds || currentFilterData.userFilterTypeID !== sourceType;
        }
        return true;
    }, [currentFilterData, endDate, sourceIds, sourceType, startDate]);

    return (
        <PageLayout SummaryBar={summaryBar}>
            <PageContentLayout showLoading={isLoading} >
                <Snackbar open={Boolean(infoMessage)} autoHideDuration={1500} onClose={() => setInfoMessage('')}>
                    <Alert severity="info" onClose={() => setInfoMessage('')}>{infoMessage}</Alert>
                </Snackbar>
                <Box display="flex" gap={1}>
                    <TextField select label="Source Type" value={sourceType.toString()} onChange={onSourceTypeChange} sx={filterElementStyle} >
                        <MenuItem value="1">Company</MenuItem>
                        <MenuItem value="2">Division</MenuItem>
                        <MenuItem value="3">Group</MenuItem>
                        <MenuItem value="4">User</MenuItem>
                    </TextField>
                    <TextField select label="Time Range" value={timeRange.toString()} onChange={onTimeRangeChange} sx={filterElementStyle} >
                        <MenuItem value="0">Custom Range</MenuItem>
                        <MenuItem value="1">This Week</MenuItem>
                        <MenuItem value="2">This Month</MenuItem>
                        <MenuItem value="3">This Quarter (Calendar)</MenuItem>
                        <MenuItem value="13">This Quarter (Financial)</MenuItem>
                        <MenuItem value="4">This Year (Calendar)</MenuItem>
                        <MenuItem value="14">This Year (Financial)</MenuItem>
                        <MenuItem value="5">Last Week</MenuItem>
                        <MenuItem value="6">Last Month</MenuItem>
                        <MenuItem value="7">Last Quarter (Calendar)</MenuItem>
                        <MenuItem value="15">Last Quarter (Financial)</MenuItem>
                        <MenuItem value="8">Last Year (Calendar)</MenuItem>
                        <MenuItem value="16">Last Year (Financial)</MenuItem>
                        <MenuItem value="9">Last 7 Days</MenuItem>
                        <MenuItem value="10">Last 30 Days</MenuItem>
                        <MenuItem value="11">Last 90 Days</MenuItem>
                        <MenuItem value="12">Last 365 Days</MenuItem>
                    </TextField>
                    <DatePicker label="From Date" value={startDate} onChange={m => setStartDate(m)} disabled={timeRange !== 0} sx={filterElementStyle} slotProps={{actionBar: { actions: ["clear", "today", "cancel", "accept"] }}} />
                    <DatePicker label="To Date" value={endDate} onChange={m => setEndDate(m)} disabled={timeRange !== 0} sx={filterElementStyle} slotProps={{actionBar: { actions: ["clear", "today", "cancel", "accept"] }}} />
                    <Button variant="contained" color="success" onClick={getDataCallback} disabled={!isRunButtonEnabled}>Run Report</Button>
                </Box>
                <Box display={sourceType === 2 ? undefined : 'none'} mt={2}>
                    <MultipleDivisionsPicker value={selectedDivisions} onSelect={onDivisionsChange} />
                </Box>
                <Box display={sourceType === 3 ? undefined : 'none'} mt={2}>
                    <MultipleGroupPicker value={selectedGroups} onSelect={onGroupsChange} />
                </Box>
                <Box display={sourceType === 4 ? undefined : 'none'} mt={2}>
                    <MultipleUserPicker value={selectedUsers} onSelect={onUsersChange} />
                </Box>
                {showGrid && ratiosData &&
                    <Box bgcolor={t => t.palette.background.default} p="10px" height="100%" mt="10px">
                        <Box display="flex" flexWrap="wrap" gap={4}>
                            {
                                dataFields.map((v,i) => (
                                    <SimpleGaugeGraph
                                        key={i}
                                        title={v.title}
                                        percentage={ratiosData[v.field]}
                                        color="blue"
                                        width="350px"
                                        outerRadius={70}
                                        innerRadius={50}
                                    />
                                ))
                            }
                        </Box>
                    </Box>
                }
            </PageContentLayout>
        </PageLayout>
    );
}