import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import TextField from "@mui/material/TextField";
import React, { useMemo, useState } from "react";
import { CustomField } from "common/models/Configuration/CustomFields";
import { JobRecordDashboardElementType } from "common/models/Dashboard/EditLayout";
import { CustomFieldType } from "common/models/ScreenLayouts/CustomFields";

interface Props {
    open: boolean,
    activeFields: CustomField[],
    alreadyAddedElements: string[],
    closeHandler: () => void,
    addElementHandler: (type: JobRecordDashboardElementType) => void
}

interface AddOption {
    value: JobRecordDashboardElementType,
    label: string,
    secondaryLabel?: string
}

const availableElements: AddOption[] = [
    { value: 'JobId', label: 'ID' },
    { value: 'JobType', label: 'Job Type' },
    { value: 'JobWorkType', label: 'Work Type' },
    { value: 'JobTitle', label: 'Job Title' },
    { value: 'JobExclusive', label: 'Exclusive' },
    { value: 'JobQuantity', label: 'Quantity' },
    { value: 'JobStartDate', label: 'Start Date' },
    { value: 'JobStartTime', label: 'Start Time' },
    { value: 'JobDuration', label: 'Duration' },
    { value: 'JobDeadline', label: 'Deadline' },
    { value: 'JobDivision', label: 'Division' },
    { value: 'JobSalaryPackage', label: 'Salary Package' },
    { value: 'JobFee', label: 'Fee' },
    { value: 'JobChargeRate', label: 'Charge Rate' },
    { value: 'JobPayRate', label: 'Pay Rate' },
    { value: 'JobOnCosts', label: 'On Costs' },
    { value: 'JobComments', label: 'Comments' },
    { value: 'JobCurrency', label: 'Currency' },
    { value: 'JobSource', label: 'Source' },
    { value: 'JobLocation', label: 'Location' },
    { value: 'JobClient', label: 'Client' },
    { value: 'JobContact', label: 'Contact' },
    { value: 'JobOtherContact', label: 'Other Contact' },
    { value: 'JobHiringManager', label: 'Hiring Manager' },
    { value: 'JobBilling1', label: 'Billing 1' },
    { value: 'JobClientRef', label: 'Client Ref' },
    { value: 'JobVideoLink', label: 'Video Link' },
    { value: 'JobRating', label: 'Rating' },
    { value: 'JobSummary', label: 'Summary' },
    { value: 'JobSummaryFullWidth', label: 'Summary - Full Width' },
    { value: 'JobStatus', label: 'Status' },
    { value: 'JobStage', label: 'Stage' },
    { value: 'JobOutcome', label: 'Outcome' },
    { value: 'JobHoursPerDay', label: 'Hours Per Day' },
    { value: 'JobDaysPerWeek', label: 'Days Per Week' },
    { value: 'JobConsultant1', label: 'Consultant 1' },
    { value: 'JobConsultant2', label: 'Consultant 2' },
    { value: 'JobComplianceChecklist', label: 'Compliance Checklist' },
    { value: 'Divider', label: 'Divider' },
    { value: 'Spacer', label: 'Spacer' },
];

const getCustomFieldName = (name: string) => {
    if (!name.startsWith('Custom Field ')) return '';
    return 'CustomField' + name.substring(13);
};

export default function AddJobDashboardElementDialog({ open, activeFields, alreadyAddedElements, closeHandler, addElementHandler }: Props) {
    const [elementType, setElementType] = useState<JobRecordDashboardElementType>('');

    const options = useMemo<AddOption[]>(() => {
        let o: AddOption[] = [];

        for (let i = 0; i < availableElements.length; i++) {
            const element = availableElements[i];
            const isAlreadyAdded = alreadyAddedElements.includes(element.value) && element.value !== 'Divider' && element.value !== 'Spacer';
            if (!isAlreadyAdded) o.push(element);
        }

        for (let i = 0; i < activeFields.length; i++) {
            const udf = activeFields[i];
            const name = getCustomFieldName(udf.name);
            const isAlreadyAdded = alreadyAddedElements.includes(name);
            if (name && !isAlreadyAdded) o.push({ value: name as CustomFieldType, label: udf.agencyName, secondaryLabel: udf.name });
        }

        o.sort((a, b) => {
            if (b.label < a.label) return 1;
            if (b.label > a.label) return -1;
            return 0;
        });

        return o;
    }, [activeFields, alreadyAddedElements]);

    const selectedElementType = useMemo(() => {
        if (elementType === '') return null;
        const type = options.find( e => e.value === elementType );
        return type ?? null;
    }, [elementType, options]);

    return (
        <Dialog open={open} fullWidth>
            <DialogTitle>Add new element</DialogTitle>
            <DialogContent>
                <Box pt={1}>
                    <Autocomplete size="small"
                        value={selectedElementType}
                        options={options}
                        onChange={ (e, v) => setElementType(v ? v.value : '') }
                        renderInput={ (params) => (
                            <TextField
                                {...params}
                                label="Element Type"
                                inputProps={{ ...params.inputProps, "data-lpignore": "true" }}
                        />)}
                        renderOption={(props, option) => (
                            <ListItem {...props}><ListItemText primary={option.label} secondary={option.secondaryLabel} /></ListItem>
                        )}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="error" onClick={ closeHandler }>Cancel</Button>
                <Button variant="contained" color="success" disabled={elementType === ''} onClick={ () => addElementHandler(elementType) }>Add</Button>
            </DialogActions>
        </Dialog>
    );
}