import { ComplianceChecklist, ComplianceChecklistCreate, ComplianceItem } from "../common/models/Configuration/Compliance";
import { ComplianceData, ComplianceReportingData } from "../common/models/ComplianceData";
import { ErrorHandler, RequestOptions } from "../common/models/GenericTypes";
import { Get, Post, PostFile, Put } from "../util/HttpUtils";

export async function GetComplianceItems(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<ComplianceItem[]>('api/Compliance/Items', options);
}

export async function CreateComplianceItem(data: Partial<ComplianceItem>, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>('api/Compliance/Items', options, data);
}

export async function UpdateComplianceItem(itemId: number, data: Partial<ComplianceItem>, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/Compliance/Items/${itemId}`, options, data);
}

export async function GetComplianceChecklists(includeInactive: boolean, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<ComplianceChecklist[]>(`api/Compliance/Checklists?includeInactive=${includeInactive}`, options);
}

export async function GetComplianceData(candidateId: number, placementId: number, checklistId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<ComplianceData[]>(`api/Compliance/Data/${candidateId}?placementId=${placementId}&checklistId=${checklistId}`, options);
}

export async function UploadComplianceData(itemId: number, candidateId: number, placementId: number, files: File[], expire: string, notes: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
        const f = files[i];
        formData.append('files', f);
    }

    const encodedNotes = encodeURIComponent(notes);
    const encodedDate = encodeURIComponent(expire);

    return PostFile<boolean>(`api/Compliance/Data/${itemId}/${candidateId}?placementId=${placementId}&notes=${encodedNotes}&expire=${encodedDate}`, formData, options);
}

export async function GetComplianceChecklistById(checklistId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<ComplianceChecklist>(`api/Compliance/Checklists/${checklistId}`, options);
}

export async function CreateComplianceChecklist(data: ComplianceChecklistCreate, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>('api/Compliance/Checklists', options, data);
}

export async function UpdateComplianceChecklist(checklistId: number, data: ComplianceChecklistCreate, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/Compliance/Checklists/${checklistId}`, options, data);
}

export async function GetComplianceExpiryReport(expiryInDays: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<ComplianceReportingData[]>(`api/Compliance/Reports/Expiry/${expiryInDays}`, options);
}