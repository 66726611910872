export const AttachmentsLimitBytes = 25000000;

export function DownloadBlob(b: Blob, filename: string) {
    let url = window.URL.createObjectURL(b);
    let a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.click();

    window.URL.revokeObjectURL(url);
    a.remove();
}