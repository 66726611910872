import React, { useState } from "react";
import Button from "@mui/material/Button";
import { ChangePassword } from '../../services/AuthService';
import { ErrorHandler, SimpleAlert } from '../../common/models/GenericTypes';

interface ChangePasswordForm {
    setShowLoading: React.Dispatch<React.SetStateAction<boolean>>,
    handleError: ErrorHandler,
    setInfoAlert: React.Dispatch<React.SetStateAction<SimpleAlert | null>>,
    token: string | null
}

const ChangePasswordForm = ({ setShowLoading, handleError, setInfoAlert, token }: ChangePasswordForm) => {
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const handleSubmit = async (e: React.SyntheticEvent) => {
        e.preventDefault();
        const t = token ?? '';
        if (Boolean(t) && Boolean(newPassword)) {
            setShowLoading(true);
            const res = await ChangePassword(t, newPassword, handleError);
            if(res) {
                setInfoAlert({ show: true, message: "Password changed successfully" })
            }
            setShowLoading(false);
        }
    }
    return (
        <form onSubmit={handleSubmit}>
            <input
                required
                minLength={6}
                type='password'
                placeholder="New Password"
                className="login-input-txt"
                onChange={({currentTarget}) => setNewPassword(currentTarget.value)}
                value={newPassword}
            />
            <input
                required
                type='password'
                placeholder="Confirm Password"
                className="login-input-txt"
                onChange={({currentTarget}) => setConfirmPassword(currentTarget.value)}
                value={confirmPassword}
            />
            <Button
                disabled={newPassword === '' || newPassword !== confirmPassword}
                type="submit"
                variant="contained"
                color="primary"
                sx={{ textTransform: 'none', display: 'block', width: '100%', height: '42px' }}
            >
                Change Password
            </Button>
        </form>
    );
}

export default ChangePasswordForm;