import { SmsConfigOptions } from "common/models/Configuration/SMS";
import { ChangeTracker } from "common/models/hooks/ChangeTracker";

export const DefaultOptions: SmsConfigOptions = {
    SmsFromName: '',
    SmsFromType: '0',
    SMSProvider: '2',
    SmsDedicatedNumber: '',
    SMSAutoProcessOptOut: 'False',
    DialogSettings_SendSMS: '',
};

export const NoChangesTracker: ChangeTracker<SmsConfigOptions> = {
    SmsFromName: false,
    SmsFromType: false,
    SMSProvider: false,
    SmsDedicatedNumber: false,
    SMSAutoProcessOptOut: false,
    DialogSettings_SendSMS: false,
};