import { Permission as P } from '../../models/Permissions';
import {
    userHasSinglePermission,
    userHasAnyPermission,
    companyHasSinglePermission,
    companyHasAnyPermission,
} from '../../../util/PermissionsUtils';

export const AllowConfigurationModule = () => userHasAnyPermission([
    P.GlobalAdministrator,
    P.AdvertsAdministrator,
    P.DataMaintenanceAdministrator,
    P.EmailIntegrationAdministrator,
    P.GroupsAdministrator,
    P.IntegrationsAdministrator,
    P.JobsAdministrator,
    P.PerformanceAdministrator,
    P.PlacementsAdministrator,
    P.SMSAdministrator,
    P.SubmissionsAdministrator,
    P.TemplatesAdministrator,
    P.UserAdministrator,
    P.AutomationAdministrator,
    P.ClientsAdministrator
]);
export const AllowActivities = () => companyHasSinglePermission(P.Activities) && userHasSinglePermission(P.GlobalAdministrator);
export const AllowAdvertsSettings = () => companyHasSinglePermission(P.Adverts) && userHasSinglePermission(P.GlobalAdministrator);
export const AllowAdverts = () => companyHasSinglePermission(P.Adverts) && userHasAnyPermission([P.GlobalAdministrator, P.AdvertsAdministrator]);
export const AllowAnalytics = () => userHasAnyPermission([P.GlobalAdministrator, P.PerformanceAdministrator]);
export const AllowCompliance = () => userHasAnyPermission([P.GlobalAdministrator, P.ComplianceAdministrator]);
export const AllowAutomation = () => userHasAnyPermission([P.GlobalAdministrator, P.AutomationAdministrator]);
export const AllowCVProcessingEmails = () => userHasAnyPermission([P.GlobalAdministrator, P.TemplatesAdministrator]);
export const AllowCustomFields = () => userHasSinglePermission(P.GlobalAdministrator);
export const AllowScreenLayouts = () => userHasAnyPermission([P.GlobalAdministrator, P.LayoutAdministrator]);
export const AllowDivisions = () => userHasSinglePermission(P.GlobalAdministrator);
export const AllowEmailIntegration = () => companyHasSinglePermission(P.EmailIntegration) && userHasAnyPermission([P.GlobalAdministrator, P.EmailIntegrationAdministrator]);
export const AllowGeneral = () => userHasSinglePermission(P.GlobalAdministrator);
export const AllowGroups = () => userHasAnyPermission([P.GlobalAdministrator, P.GroupsAdministrator]);
export const AllowInvoices = () => userHasAnyPermission([P.GlobalAdministrator, P.IntegrationsAdministrator]);
export const AllowIntegrations = () => userHasAnyPermission([P.GlobalAdministrator, P.IntegrationsAdministrator]);
export const AllowClients = () => companyHasSinglePermission(P.Clients) && userHasAnyPermission([P.GlobalAdministrator, P.ClientsAdministrator]);
export const AllowContacts = () => companyHasSinglePermission(P.Contacts) && userHasSinglePermission(P.GlobalAdministrator);
export const AllowCandidates = () => companyHasSinglePermission(P.Candidates) && userHasSinglePermission(P.GlobalAdministrator);
export const AllowOpportunities = () => companyHasSinglePermission(P.Opportunities) && userHasSinglePermission(P.GlobalAdministrator);
export const AllowSites = () => companyHasSinglePermission(P.Clients) && userHasSinglePermission(P.GlobalAdministrator);
export const AllowJobs = () => companyHasSinglePermission(P.Jobs) && userHasAnyPermission([P.GlobalAdministrator, P.JobsAdministrator]);
export const AllowScheduledMessages = () => companyHasAnyPermission([P.MessageCentre, P.MessageCentreSms]) && userHasAnyPermission([P.GlobalAdministrator, P.DataMaintenanceAdministrator]);
export const AllowPerformance = () => userHasAnyPermission([P.GlobalAdministrator, P.PerformanceAdministrator]);
export const AllowPlacements = () => companyHasSinglePermission(P.Placements) && userHasAnyPermission([P.GlobalAdministrator, P.PlacementsAdministrator]);
export const AllowRecentlyDeletedRecords = () => userHasAnyPermission([P.GlobalAdministrator, P.DataMaintenanceAdministrator]);
export const AllowSMSPurchaces = () => companyHasSinglePermission(P.MessageCentreSms) && userHasAnyPermission([P.GlobalAdministrator, P.SMSAdministrator]);
export const AllowSMSSettings = () => companyHasSinglePermission(P.MessageCentreSms) && userHasSinglePermission(P.GlobalAdministrator);
export const AllowSubmissions = () => userHasAnyPermission([P.GlobalAdministrator, P.SubmissionsAdministrator]);
export const AllowTags = () => companyHasSinglePermission(P.Tags) && userHasSinglePermission(P.GlobalAdministrator);
export const AllowUsers = () => userHasAnyPermission([P.GlobalAdministrator, P.UserAdministrator]);
export const AllowVMS = () => userHasAnyPermission([P.GlobalAdministrator, P.TemplatesAdministrator]);
export const AllowCustomWorkflows = () => companyHasSinglePermission(P.Jobs) && userHasSinglePermission(P.GlobalAdministrator);

export const CustomerHasFullAutomation = () => companyHasSinglePermission(P.Automation);
export const AllowAutomationSideBarFull = () => companyHasSinglePermission(P.Automation)  && userHasAnyPermission([P.GlobalAdministrator, P.AutomationAdministrator]);
export const AllowAutomationSideBarLite = () => !companyHasSinglePermission(P.Automation) && userHasAnyPermission([P.GlobalAdministrator, P.AutomationAdministrator]);