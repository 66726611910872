import React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

interface Props {
    title?: string,
    showLoading?: boolean,
    contentAction?: JSX.Element
}

export default function PageContentLayout({ title, contentAction, showLoading, children }: React.PropsWithChildren<Props>) {
    return (
        <>
            {showLoading &&
                <Backdrop open={showLoading} sx={{ zIndex: (t) => t.zIndex.modal + 1 }} >
                    <CircularProgress sx={{ color: 'text.disabled' }} />
                </Backdrop>
            }
            <Paper sx={{ padding: '1rem', flexBasis: '100%', display: 'flex', flexDirection: 'column' }}>
                {title &&
                    <Box display="flex" justifyContent="space-between" paddingBottom="2rem">
                        <Typography variant="h6">{title}</Typography>
                        {contentAction}
                    </Box>
                }
                {children}
            </Paper>
        </>
    );
}