export enum PartnerActionType
{
    Clients = 1,
    Contacts = 2,
    Candidates = 3,
    CandidatesWorkflow = 4,
    Opportunities = 5,
    Placements = 6,
    PlacementsApproved = 7,
    PlacementsWorkflow = 8,
    PlacementsWorkflowApproved = 9,
    Jobs = 10
}

export enum PartnerActionWorkflowType
{
    None = 0,
    Screening = 1,
    Submissions = 2,
    Interviews = 3,
    ReferenceChecks = 4
}

export enum PartnerActionUrlType
{
    Popup = 0,
    NewWindow = 1
}

export enum PartnerActionStatus
{
    Enabled = 0,
    Disabled = 1
}

export interface PartnerAction {
    name: string,
    partnerReference: string,
    typeId: PartnerActionType,
    workflowTypeId: PartnerActionWorkflowType,
    url: string,
    urlTypeId: PartnerActionUrlType,
    statusId: PartnerActionStatus,
    partnerActionId: string,
    createdDate: string,
    updatedDate: string,
}