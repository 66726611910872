import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Interview } from "common/models/Interviews";
import { DefaultInternalInterviewRecordDashboardElements, DefaultInterviewRecordDashboardElements, DefaultInterviewSlotRecordDashboardElements, InterviewScreenLayoutSettings } from "util/Definitions/ScreenLayouts/Interview";
import { useTheme } from "@mui/material/styles";
import { InterviewRecordDashboardElementDefinition, InterviewRecordDashboardElementType } from "common/models/Dashboard/EditLayout";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import SingleFieldElement from "../SingleFieldElement";
import MultiLineTextElement from "../MultiLineTextElement";
import { DashboardLayoutComponent } from "@syncfusion/ej2-react-layouts/src/dashboard-layout/dashboardlayout.component";
import PanelWrapper from "components/Dashboards/PanelWrapper";
import { PreviewEntityType } from "common/models/Previews/Previews";
import PreviewLoaderComponent from "components/Previews/PreviewLoader";
import moment from "moment";

interface Props {
    interview: Interview | null,
    specialType?: 'internal' | 'slot',
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
}

const { unitWidth, unitHeight, gapX, gapY, columns, mediaQueryMaxWidth } = InterviewScreenLayoutSettings;
const cellSpacing = [gapX, gapY];

const getDateString = (date: string | null, timezone: string | null) => {
    let dateString = '';

    const mdt = moment(date);
    if (mdt.isValid()) dateString = mdt.format('DD MMM YYYY h:mm A');


    return dateString + " " + timezone;
};

const getInterviewDurationString = (duration: number) => {
    switch (duration)
    {
        case 15: return "15 mins";
        case 30: return "30 mins";
        case 45: return "45 mins";
        case 60: return "60 mins";
        case 90: return "90 mins";
        case 120: return "2 hours";
        case 180: return "3 hours";
        case 240: return "4 hours";
        case 360: return "6 hours";
        case 480: return "8 hours";
    }
    return '';
};

const getInterviewDistributionString = (distribution: number) => {
    switch (distribution)
    {
        case 0: return "None - Record in Recruit Wizard Only";
        case 1: return "Calendar Invite";
        case 2: return "Calendar Invite + Email"
    }
    return '';
};

const getInterviewLocationTypeName = (locationTypeId: number) => {
    switch (locationTypeId) {
        case 1: return "In Person";
        case 2: return "Phone Call";
        case 3: return "Microsoft Teams";
        case 4: return "Zoom";
        case 5: return "Other";
    }
    return '';
};

export default function ViewRecordScreenLayout({interview, specialType, loadingHandler, errorHandler}: Props) {
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    const [previewType, setPreviewType] = useState<PreviewEntityType | ''>('');
    const [previewRecordId, setPreviewRecordId] = useState(0);
    const [screenResizedControl, setScreenResizedControl] = useState(false);
    const [fetchingSavedState, setFetchingSavedState] = useState(false);
    const [fetchedSavedState, setFetchedSavedState] = useState(false);
    const layoutRef = useRef<DashboardLayoutComponent | null>(null);
    const theme = useTheme();

    useEffect(() => {
        const windowResizeHandler = () => setScreenResizedControl(prev => !prev);
        window.addEventListener('resize', windowResizeHandler);
        return () => window.removeEventListener('resize', windowResizeHandler);
    }, []);

    useEffect(() => {
        loadingHandler && loadingHandler(fetchingSavedState);
    }, [loadingHandler, fetchingSavedState]);

    useEffect(() => {
        const getSavedState = async () => {
            setFetchingSavedState(true);
            // const elementsJson = await GetCustomerSettingBySettingName('AdvertRecordDashboardLayoutElements');
            // if (elementsJson) {
            //     const panels = JSON.parse(elementsJson) as PanelModel[];
            //     setLastSavedState(panels);
            // }
            setFetchingSavedState(false);
            setFetchedSavedState(true);
        };
        getSavedState();
    }, []);

    const elements = useMemo<InterviewRecordDashboardElementDefinition[]>(() => {
        if (specialType === 'internal') return DefaultInternalInterviewRecordDashboardElements;
        if (specialType === 'slot') return DefaultInterviewSlotRecordDashboardElements;
        return DefaultInterviewRecordDashboardElements;
    }, [specialType]);

    const openPreviewHandler = useCallback((type: PreviewEntityType, recordId: number) => {
        setPreviewType(type);
        setPreviewRecordId(recordId);
        setIsPreviewOpen(true);
    }, []);

    const closePreviewHandler = useCallback(() => {
        setIsPreviewOpen(false);
    }, []);

    const renderElement = useCallback((id: string, type: InterviewRecordDashboardElementType) => {
        if (interview) {
            switch (type) {
                case 'Divider': return <Box pt="20px"><Divider component="div" /></Box>;
                case 'Spacer': return <></>;
                case 'InterviewDistributionMode': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Client Dist Mode" fieldValue={getInterviewDistributionString(interview.distributionModeClient)} />;
                case 'InterviewCandidateDistributionMode': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Candidate Dist Mode" fieldValue={getInterviewDistributionString(interview.distributionModeCandidate)} />;
                case 'InterviewOrganizer': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Organizer" fieldValue={interview.organizerName} />;
                case 'InterviewType': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Type" fieldValue={interview.typeName} />;
                case 'InterviewDate': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Date (Local)" fieldValue={getDateString(interview.dateInLocalTimeZone, interview.timeZoneName)} />;
                case 'InterviewDateUser': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Date (Yours)" format="datetime" fieldValue={interview.date} />;
                case 'InterviewDuration': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Duration" fieldValue={getInterviewDurationString(interview.duration)} />;
                case 'InterviewSubject': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Subject" fieldValue={interview.subject} />;
                case 'InterviewLocationType':
                    return <SingleFieldElement useEllipsisForLongValues fieldTitle="Location Type" fieldValue={getInterviewLocationTypeName(interview.locationTypeID)} />;
                case 'InterviewLocation':
                    return <SingleFieldElement useEllipsisForLongValues fieldTitle="Location" fieldValue={interview.location} />;
                case 'InterviewNotes': return <MultiLineTextElement title="Notes" content={interview.interviewNotes ?? ''} />;
                case 'InterviewJobId': 
                    if (interview.jobID === 0) return undefined;
                    return (
                        <SingleFieldElement
                            fieldTitle='Job ID'
                            fieldValue={interview.jobID.toString()}
                            format="internal-link"
                            href={`/jobs/${interview.jobID}`}
                            previewType="job"
                            previewRecordId={interview.jobID}
                            openPreviewHandler={openPreviewHandler}
                            closePreviewHandler={closePreviewHandler}
                        />
                    );
                default: return <div>{id}</div>;
            }
        }
    }, [interview, openPreviewHandler, closePreviewHandler]);
    
    const layoutResizeStopHandler = useCallback(() => {
        setScreenResizedControl(prev => !prev);
    }, []);

    useEffect(() => {
        if (screenResizedControl) {}
        const api = layoutRef.current;
        if (api && fetchedSavedState) {
            const refreshTimeout = setTimeout(() => {
                api.refresh();
                api.refresh();
            }, 250);
            return () => clearTimeout(refreshTimeout);
        }
    }, [fetchedSavedState, screenResizedControl]);

    const renderLayout = useCallback(() => {
        if (!fetchedSavedState) return <></>;

        const mediaQuery = mediaQueryMaxWidth ? `max-width: ${mediaQueryMaxWidth}` : undefined;
        return (
            <DashboardLayoutComponent
                cellSpacing={cellSpacing}
                columns={columns}
                cellAspectRatio={30 / 2}
                resizeStop={ layoutResizeStopHandler }
                allowDragging={false}
                allowResizing={false}
                ref={l => layoutRef.current = l}
                mediaQuery={mediaQuery}
            >
                {elements.map(e => (
                    <PanelWrapper
                        key={e.id}
                        id={e.id}
                        col={e.col}
                        row={e.row}
                        sizeX={e.sizeX}
                        sizeY={e.sizeY}
                        minSizeX={e.minSizeX}
                        minSizeY={e.minSizeY}
                        maxSizeX={e.maxSizeX}
                        maxSizeY={e.maxSizeY}
                        resizeControl={screenResizedControl}
                        resizeIconColor={theme.palette.text.disabled}
                        gapX={gapX}
                        gapY={gapY}
                        unitWidth={unitWidth}
                        unitHeight={unitHeight}
                    >
                        {renderElement(e.id, e.type)}
                    </PanelWrapper>
                ))}
            </DashboardLayoutComponent>
        );
    }, [elements, fetchedSavedState, theme.palette.text.disabled, screenResizedControl, layoutResizeStopHandler, renderElement]);
    
    return (
        <>
            <PreviewLoaderComponent
                open={isPreviewOpen}
                entityType={previewType}
                recordId={previewRecordId}
            />
            <Box p="10px" height="100%">
                <div className="control-section">
                    {Boolean(specialType) && renderLayout() }
                    {!Boolean(specialType) && renderLayout() }
                </div>
            </Box>
        </>
    );
}