import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { SxProps } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import moment from "moment";
import React, { useCallback, useState, useEffect } from "react";
import * as XLSX from "xlsx";
import { GetAdvertsAnalysisReportData } from "services/AdvertsService";
import { DatePicker } from "@mui/x-date-pickers-pro";
import { ReportDataProcessDateFields } from "util/ReportUtils";

interface Props {
    description: string,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void
}

const filterElementStyle: SxProps = { flex: '1 1 0', mr: 1 };

export default function AdvertsAnalysisReport({ description, loadingHandler, errorHandler }: Props) {
    const [fromDate, setFromDate] = useState<moment.Moment | null>(null);
    const [toDate, setToDate] = useState<moment.Moment | null>(null);

    useEffect(() => {
        setFromDate(moment().startOf('month'));
        setToDate(moment().endOf('month'));
    }, []);

    const getDataCallback = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        const wb = XLSX.utils.book_new();
        const m = moment();
        const filename = `AdvertsAnalysis${m.format('YYYYMMDDhhmmss')}.xlsx`;
        
        const stringFromDate = fromDate && fromDate.isValid() ? fromDate.format('YYYY-MM-DD') : '';
        const stringToDate = toDate && toDate.isValid() ? toDate.format('YYYY-MM-DD') : '';
        const res = await GetAdvertsAnalysisReportData(stringFromDate, stringToDate, errorHandler);

        if (res["data1"]) {
            const ws = XLSX.utils.json_to_sheet(res["data1"]);
            XLSX.utils.book_append_sheet(wb, ws, "Summary");
        }
        if (res["data2"]) {
            ReportDataProcessDateFields(res["data2"] as any[]);
            const ws = XLSX.utils.json_to_sheet(res["data2"]);
            XLSX.utils.book_append_sheet(wb, ws, "Summary By Consultant");
        }
        if (res["data3"]) {
            const ws = XLSX.utils.json_to_sheet(res["data3"]);
            XLSX.utils.book_append_sheet(wb, ws, "Detailed");
        }
        if (res["data4"]) {
            ReportDataProcessDateFields(res["data4"] as any[]);
            const ws = XLSX.utils.json_to_sheet(res["data4"]);
            XLSX.utils.book_append_sheet(wb, ws, "Consultant Report");
        }
        if (res["data5"]) {
            const ws = XLSX.utils.json_to_sheet(res["data5"]);
            XLSX.utils.book_append_sheet(wb, ws, "Consultant Report By Open Job");
        }
        XLSX.writeFile(wb, filename);
        loadingHandler && loadingHandler(false);
    }, [fromDate, toDate, errorHandler, loadingHandler]);

    return (
        <>
            <Box display="flex">
                <DatePicker label="From Date" value={fromDate} onChange={m => setFromDate(m)} sx={filterElementStyle} slotProps={{actionBar: { actions: ["clear", "today", "cancel", "accept"] }}} />
                <DatePicker label="To Date" value={toDate} onChange={m => setToDate(m)} sx={filterElementStyle} slotProps={{actionBar: { actions: ["clear", "today", "cancel", "accept"] }}} />
                <Button variant="contained" color="success" onClick={getDataCallback}>Run Report</Button>
            </Box>
            <Typography component="div" variant="h6" mt={2}>Report Description</Typography>
            <Typography component="div" variant="body2">{description}</Typography>
        </>
    );
}