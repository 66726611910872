import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { NameIdObjString } from "common/models/GenericTypes";
import MultipleStringValuesPicker from "components/Pickers/MultipleStringValuesPicker";
import React, { useCallback, useEffect, useMemo } from "react";

interface Props {
    jobBoardId: number,
    advertId: number,
    attributes?: Record<string, string>,
    advertRecordAttributes?: Record<string, string>,
    attributeChangeHandler?: (jobBoardId: number, key: string, value: string) => void
}

const categoryOptions: NameIdObjString[] = [
    { id: "accounts", name: "Accounting" },
    { id: "accounts-support", name: "Accounts Support" },
    { id: "administration-officesupport", name: "Administration & Office Support" },
    { id: "architecture", name: "Architecture" },
    { id: "banking-finance", name: "Banking & Finance" },
    { id: "bookkeeping", name: "Bookkeeping" },
    { id: "business-development", name: "Business Development" },
    { id: "call-centre", name: "Call Centre" },
    { id: "not-for-profit-charity", name: "Charity & Not - for - Profit" },
    { id: "no-descriptioncustomer-service", name: "Customer Service" },
    { id: "education-training", name: "Education & Training" },
    { id: "engineering", name: "Engineering" },
    { id: "event-management", name: "Event Management" },
    { id: "fashion", name: "Fashion" },
    { id: "test-cat", name: "Government" },
    { id: "healthcare-medical", name: "Healthcare & Medical" },
    { id: "home-care", name: "home - care" },
    { id: "hospitality", name: "Hospitality" },
    { id: "human-resources", name: "Human Resources" },
    { id: "information-technology", name: "Information Technology" },
    { id: "insurance", name: "Insurance" },
    { id: "legal", name: "Legal" },
    { id: "management", name: "Management" },
    { id: "marketing-communications", name: "Marketing Communications" },
    { id: "other", name: "Other" },
    { id: "pr", name: "PR" },
    { id: "property-construction", name: "Property & Construction" },
    { id: "real-estate", name: "Real Estate" },
    { id: "recruitment", name: "Recruitment" },
    { id: "retail", name: "Retail" },
    { id: "sales-marketing", name: "Sales & Marketing" },
    { id: "services", name: "Services" },
    { id: "software", name: "software" },
    { id: "transport-logistics", name: "Transport & Logistics" },
];

const jobTypeOptions: NameIdObjString[] = [
    { id: "internship", name: "Internship" },
    { id: "freelance", name: "Freelance" },
    { id: "full-time", name: "Full Time" },
    { id: "part-time", name: "Part Time" },
    { id: "temporary", name: "Temporary" },
];

const regionOptions: NameIdObjString[] = [
    { id: "act", name: "ACT" },
    { id: "adelaide", name: "Adelaide" },
    { id: "brisbane", name: "Brisbane" },
    { id: "darwin", name: "Darwin" },
    { id: "melbourne", name: "Melbourne" },
    { id: "nsw", name: "NSW" },
    { id: "nt", name: "NT" },
    { id: "online-australia-wide", name: "Online / Australia Widw" },
    { id: "perth", name: "Perth" },
    { id: "qld", name: "QLD" },
    { id: "sa", name: "SA" },
    { id: "sydney", name: "Sydney" },
    { id: "tas", name: "TAS" },
    { id: "vic", name: "VIC" },
    { id: "wa", name: "WA" },
];

export default function WPJobManagerV2Edit({ jobBoardId, advertId, attributes, advertRecordAttributes, attributeChangeHandler }: Props) {
    const atts = useMemo<Record<string, string>>(() => {
        if (attributes) return attributes;
        return {};
    }, [attributes]);

    const recordAtts = useMemo<Record<string, string>>(() => {
        if (advertRecordAttributes) return advertRecordAttributes;
        return {};
    }, [advertRecordAttributes]);

    const categories = useMemo(() => atts.categories ?? '', [atts.categories]);
    const jobTypes = useMemo(() => atts.jobtypes ?? '', [atts.jobtypes]);
    const regions = useMemo(() => atts.regions ?? '', [atts.regions]);

    const location = useMemo(() => recordAtts.location ?? '', [recordAtts.location]);
    const displaySalary = useMemo(() => recordAtts.displaySalary ?? '', [recordAtts.displaySalary]);

    useEffect(() => attributeChangeHandler && attributeChangeHandler(jobBoardId, 'location', location), [attributeChangeHandler, location, jobBoardId]);
    useEffect(() => attributeChangeHandler && attributeChangeHandler(jobBoardId, 'jobsalary', displaySalary), [attributeChangeHandler, displaySalary, jobBoardId]);

    const categoriesArray = useMemo(() => {
        if (categories) return JSON.parse(categories) as string[];
        return [];
    }, [categories]);

    const jobTypesArray = useMemo(() => {
        if (jobTypes) return JSON.parse(jobTypes) as string[];
        return [];
    }, [jobTypes]);

    const regionsArray = useMemo(() => {
        if (regions) return JSON.parse(regions) as string[];
        return [];
    }, [regions]);

    const categoriesChangeHandler = useCallback((vals: NameIdObjString[]) => {
        attributeChangeHandler && attributeChangeHandler(jobBoardId, 'categories', JSON.stringify(vals.map(v => v.id)));
    }, [attributeChangeHandler, jobBoardId]);

    const jobTypesChangeHandler = useCallback((vals: NameIdObjString[]) => {
        attributeChangeHandler && attributeChangeHandler(jobBoardId, 'jobtypes', JSON.stringify(vals.map(v => v.id)));
    }, [attributeChangeHandler, jobBoardId]);

    const regionsChangeHandler = useCallback((vals: NameIdObjString[]) => {
        attributeChangeHandler && attributeChangeHandler(jobBoardId, 'regions', JSON.stringify(vals.map(v => v.id)));
    }, [attributeChangeHandler, jobBoardId]);

    return (
        <Box display="flex" gap={2} pt={2}>
            <Box display="flex" flexDirection="column" gap={2} width="50%">
                <MultipleStringValuesPicker label="Categories" options={categoryOptions} value={categoriesArray} onSelect={categoriesChangeHandler}  />
                <MultipleStringValuesPicker label="JobTypes" options={jobTypeOptions} value={jobTypesArray} onSelect={jobTypesChangeHandler}  />
                <TextField disabled label="Display Salary" value={displaySalary} />
            </Box>
            <Box display="flex" flexDirection="column" gap={2} width="50%">
                <MultipleStringValuesPicker label="Regions" options={regionOptions} value={regionsArray} onSelect={regionsChangeHandler}  />
                <TextField disabled label="Location" value={location} />
            </Box>
        </Box>
    );
}