import { ESignRequest, SignatureStatus, SignersStatus } from "../common/models/ESigning";
import { ErrorHandler, RequestOptions } from "../common/models/GenericTypes";
import { Get, Post } from "../util/HttpUtils";

export async function GetCurrentCustomerESignRequests_All(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<ESignRequest[]>('api/ESigning/Requests/Customer/All', options);
}

export async function GetMyESignRequests_All(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<ESignRequest[]>('api/ESigning/Requests/Me/All', options);
}

export async function GetCurrentCustomerESignRequests_Incomplete(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<ESignRequest[]>('api/ESigning/Requests/Customer/Incomplete', options);
}

export async function GetMyESignRequests_Incomplete(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<ESignRequest[]>('api/ESigning/Requests/Me/Incomplete', options);
}

export async function ESignRequest_SendReminder(requestId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>(`api/ESigning/Requests/${requestId}/Reminder`, options);
}

export async function ESignRequest_BulkSendReminder(requestIds: number[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>(`api/ESigning/Requests/Reminder/Bulk`, options, requestIds);
}

export async function ESignRequest_Revoke(requestId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>(`api/ESigning/Requests/${requestId}/Revoke`, options);
}

export async function ESignRequest_BulkRevoke(requestIds: number[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>(`api/ESigning/Requests/Revoke/Bulk`, options, requestIds);
}

export async function GetSignatureStatus(requestId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<SignatureStatus[]>(`api/ESigning/Requests/${requestId}/SignatureStatus`, options);
}

export async function GetSignersDisplayData(entityId: number, playerId: number, templateId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<SignersStatus[]>(`api/ESigning/Signers/Status?entityId=${entityId}&playerId=${playerId}&templateId=${templateId}`, options);
}

export async function GenerateEsignRequest(entityId: number, playerId: number, templateId: number, documentId: number, isTest: boolean, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>(`api/ESigning?isTest=${isTest}`, options, { entityId, playerId, templateId, documentId });
}

