import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import React, { useCallback, useMemo, useState } from "react";
import { ReassignActivities } from "services/ActivitiesService";
import UserPicker from "components/Pickers/UserPicker";
import { NameIdObj } from "common/models/GenericTypes";

interface Props {
    open: boolean,
    activityIds: number[],
    closeHandler: () => void,
    errorHandler?: (message: string) => void,
    successHandler?: () => void,
    loadingHandler?: (isLoading: boolean) => void
}

export default function ReassignActivitiesDialog({ open, activityIds, closeHandler, errorHandler, successHandler, loadingHandler }: Props) {
    const [user, setUser] = useState<NameIdObj | null>(null);

    const userId = useMemo(() => {
        if (user) return user.id;
        return null;
    }, [user]);

    const reassignActivitiesCallback = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        if (userId) {
            const res = await ReassignActivities(userId, activityIds, errorHandler);
            if (res) successHandler && successHandler();
        }
        loadingHandler && loadingHandler(false);
    }, [loadingHandler, errorHandler, successHandler, userId, activityIds]);

    return (
        <Dialog open={open}>
            <DialogTitle>Confirm Action</DialogTitle>
            <DialogContent>
                <DialogContentText sx={{ mb: '10px' }}>
                    Are you sure you want to re-assign the selected activities ({activityIds.length}) ?
                </DialogContentText>
                <UserPicker
                    userId={userId}
                    onSelect={ u => setUser(u) }
                />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={ closeHandler }>No</Button>
                <Button variant="contained" color="success" disabled={ user === null } onClick={ reassignActivitiesCallback } >Yes</Button>
            </DialogActions>
        </Dialog>
    );
}