import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { SxProps } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import * as XLSX from "xlsx";
import { GetCommissionReports } from "services/PlacementsService";
import UserPicker from "components/Pickers/UserPicker";
import { NameIdObj } from "common/models/GenericTypes";
import { DatePicker } from "@mui/x-date-pickers-pro";
import { ReportDataProcessDateFields } from "util/ReportUtils";

interface Props {
    description: string,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void
}

const ownerSpecialOptions: NameIdObj[] = [ { id: 0, name: 'Me'} ];
const filterElementStyle: SxProps = { flex: '1 1 0', mr: 1 };

export default function CommisionReport({ description, loadingHandler, errorHandler }: Props) {
    const [ownerId, setOwnerId] = useState(0);

    const currentYear = useMemo(() => moment().year(), []);
    const currentMonth = useMemo(() => moment().month(), []);

    const [year, setYear] = useState(currentYear);
    const [month, setMonth] = useState(currentMonth + 1);
    const [lastRunDate, setLastRunDate] = useState<moment.Moment | null>(null);

    useEffect(() => {
        setLastRunDate(moment());
    }, []);

    const getDataCallback = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        const stringLastRunDate = lastRunDate && lastRunDate.isValid() ? lastRunDate.format('YYYY-MM-DD') : '';
        const res = await GetCommissionReports(ownerId, year, month, stringLastRunDate, errorHandler);
        if (res) {
            ReportDataProcessDateFields(res as any[]);
            
            const m = moment();
            const filename = `CommissionExtract${m.format('YYYYMMDDhhmmss')}.xlsx`;
            const ws = XLSX.utils.json_to_sheet(res);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws);
            XLSX.writeFile(wb, filename);
        }
        loadingHandler && loadingHandler(false);
    }, [ownerId, year, month, lastRunDate, errorHandler, loadingHandler]);

    return (
        <>
            <Box display="flex">
                <Box flex="1 1 0" pr={1}>
                    <UserPicker disableClearable label="Owner" userId={ownerId} onSelect={u => setOwnerId(u ? u.id : 0)} appendToStart={ownerSpecialOptions} includeInactiveUsers={true} />
                </Box>
                <TextField select label="Month" value={month.toString()} onChange={({ target }) => setMonth(+target.value)} sx={filterElementStyle}>
                    <MenuItem value="1">January</MenuItem>
                    <MenuItem value="2">Febrary</MenuItem>
                    <MenuItem value="3">March</MenuItem>
                    <MenuItem value="4">April</MenuItem>
                    <MenuItem value="5">May</MenuItem>
                    <MenuItem value="6">June</MenuItem>
                    <MenuItem value="7">July</MenuItem>
                    <MenuItem value="8">August</MenuItem>
                    <MenuItem value="9">September</MenuItem>
                    <MenuItem value="10">October</MenuItem>
                    <MenuItem value="11">November</MenuItem>
                    <MenuItem value="12">December</MenuItem>
                </TextField>
                <TextField select label="Year" value={year.toString()} onChange={({ target }) => setYear(+target.value)} sx={filterElementStyle}>
                    <MenuItem value={currentYear}>{currentYear}</MenuItem>
                    <MenuItem value={currentYear - 1}>{currentYear - 1}</MenuItem>
                    <MenuItem value={currentYear - 2}>{currentYear - 2}</MenuItem>
                    <MenuItem value={currentYear - 3}>{currentYear - 3}</MenuItem>
                    <MenuItem value={currentYear - 4}>{currentYear - 4}</MenuItem>
                    <MenuItem value={currentYear - 5}>{currentYear - 5}</MenuItem>
                    <MenuItem value={currentYear - 6}>{currentYear - 6}</MenuItem>
                    <MenuItem value={currentYear - 7}>{currentYear - 7}</MenuItem>
                    <MenuItem value={currentYear - 8}>{currentYear - 8}</MenuItem>
                    <MenuItem value={currentYear - 9}>{currentYear - 9}</MenuItem>
                </TextField>
                <DatePicker label="Last Run" value={lastRunDate} onChange={ setLastRunDate } sx={filterElementStyle} slotProps={{actionBar: { actions: ["clear", "today", "cancel", "accept"] }}} />
                <Button variant="contained" color="success" onClick={getDataCallback}>Run Report</Button>
            </Box>
            <Typography component="div" variant="h6" mt={2}>Report Description</Typography>
            <Typography component="div" variant="body2">{description}</Typography>
        </>
    );
}