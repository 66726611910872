import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ChangeRequestTypeEnum } from "util/Definitions/Configuration/Placements";
import moment from "moment";
import { GridColDef } from "@mui/x-data-grid/models/colDef/gridColDef";
import GridWithStateWrapper from "../GridWidthStateWrapper";
import { GridRenderCellParams, useGridApiRef } from "@mui/x-data-grid-premium";
import useAllUsers from "hooks/UseAllUsers";
import { GetCustomFieldsByEntity_OnlyActive } from "services/CustomFieldsService";
import { CustomField } from "common/models/Configuration/CustomFields";
import { GetClientById } from "services/ClientsService";
import { GetContactById } from "services/ContactsService";
import { PreviewEntityType } from "common/models/Previews/Previews";
import { Link } from "react-router-dom";

interface Props {
    gridName: string,
    changeTypeId: number,
    existingJson: string,
    newValuesJson: string,
    previewHandler: (type: PreviewEntityType | '', id: number, isTags?: boolean, recordName?: string, noDelay?: boolean) => void,
    previewCloseHanlder: () => void,
}

interface ChangeRequestFieldRow {
    id : string,
    field: string,
    existingValue: string,
    newValue: string,
    summary: string,
    previewEntityType1?: PreviewEntityType,
    previewRecordId1?: number,
    previewEntityType2?: PreviewEntityType,
    previewRecordId2?: number,
}

const udfNameMap: Record<string, string> = {
    'Custom Field 1': 'sUDF1',
    'Custom Field 2': 'sUDF2',
    'Custom Field 3': 'sUDF3',
    'Custom Field 4': 'sUDF4',
    'Custom Field 5': 'sUDF5',
    'Custom Field 6': 'sUDF6',
    'Custom Field 7': 'sUDF7',
    'Custom Field 8': 'sUDF8',
    'Custom Field 9': 'sUDF9',
    'Custom Field 10': 'sUDF10',
    'Custom Field 11': 'lUDF11',
    'Custom Field 12': 'lUDF12',
    'Custom Field 13': 'lUDF13',
    'Custom Field 14': 'lUDF14',
    'Custom Field 15': 'lUDF15',
    'Custom Field 16': 'dtUDF16',
    'Custom Field 17': 'dtUDF17',
    'Custom Field 18': 'dtUDF18',
    'Custom Field 19': 'dtUDF19',
    'Custom Field 20': 'dtUDF20',
    'Custom Field 21': 'sUDF21',
    'Custom Field 22': 'sUDF22',
    'Custom Field 23': 'sUDF23',
    'Custom Field 24': 'sUDF24',
    'Custom Field 25': 'sUDF25',
    'Custom Field 26': 'sUDF26',
    'Custom Field 27': 'sUDF27',
    'Custom Field 28': 'sUDF28',
    'Custom Field 29': 'sUDF29',
    'Custom Field 30': 'sUDF30',
    'Custom Field 31': 'sUDF31',
    'Custom Field 32': 'sUDF32',
    'Custom Field 33': 'sUDF33',
    'Custom Field 34': 'sUDF34',
    'Custom Field 35': 'sUDF35',
    'Custom Field 36': 'sUDF36',
    'Custom Field 37': 'sUDF37',
    'Custom Field 38': 'sUDF38',
    'Custom Field 39': 'sUDF39',
    'Custom Field 40': 'sUDF40',
    'Custom Field 41': 'lUDF41',
    'Custom Field 42': 'lUDF42',
    'Custom Field 43': 'lUDF43',
    'Custom Field 44': 'lUDF44',
    'Custom Field 45': 'lUDF45',
    'Custom Field 46': 'lUDF46',
    'Custom Field 47': 'lUDF47',
    'Custom Field 48': 'lUDF48',
    'Custom Field 49': 'lUDF49',
    'Custom Field 50': 'lUDF50',
    'Custom Field 51': 'dtUDF51',
    'Custom Field 52': 'dtUDF52',
    'Custom Field 53': 'dtUDF53',
    'Custom Field 54': 'dtUDF54',
    'Custom Field 55': 'dtUDF55',
    'Custom Field 56': 'dtUDF56',
    'Custom Field 57': 'dtUDF57',
    'Custom Field 58': 'dtUDF58',
    'Custom Field 59': 'dtUDF59',
    'Custom Field 60': 'dtUDF60',
};

const linkStyle: React.CSSProperties = { color: 'inherit', textDecoration: 'underline' };

const getObjectValue = (obj: any, key: string) => {
    return obj[Object.keys(obj).find(k => k.toLowerCase() === key) ?? key];
};

const getDateStringValue = (v: string) => {
    if (v && v !== '0001-01-01T00:00:00') {
        let m = moment(v);
        if(v.includes('/'))
        {
            m = moment(v, 'DD/MM/YYYY')
        }
        
        if (m.isValid()) return m.format('DD MMM YYYY');
    }
    return '';
}

const formatNumber = (value: number) => {
    if(value != null) {
        return value.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 });
    }
    return "";
};

const getTimeUnitsName = (unitsId: number) => {
    switch (unitsId)
    {
        case 1: return "Per Hour";
        case 2: return "Per Day";
        case 3: return "Per Week";
        case 4: return "Per Month";
        case 5: return "Per Annum";
        default: return "";
    }
};

const getNoticePeriodUnitsName = (unitsId: number) => {
    switch (unitsId) {
        case 1: return "Days";
        case 2: return "Weeks";
        case 3: return "Months";
        default: return "";
    }
};

export default function ChangeRequestFieldsGrid({ gridName, changeTypeId, existingJson, newValuesJson, previewHandler, previewCloseHanlder }: Props) {
    const [udfSettings, setUdfSettings] = useState<CustomField[]>([]);
    const [paymentTypeRows, setPaymentTypeRows] = useState<ChangeRequestFieldRow[]>([]);
    const apiRef = useGridApiRef();
    const allUsers = useAllUsers();

    const needsUdfs = useMemo(() => (changeTypeId === ChangeRequestTypeEnum.RateChange || changeTypeId === ChangeRequestTypeEnum.Extension || changeTypeId === ChangeRequestTypeEnum.PaymentType || changeTypeId === ChangeRequestTypeEnum.RateCorrection), [changeTypeId]);

    useEffect(() => {
        const getUdfSettings = async () => {
            const res = await GetCustomFieldsByEntity_OnlyActive(5);
            if (res) setUdfSettings(res);
        };

        needsUdfs && getUdfSettings();
    }, [needsUdfs]);

    useEffect(() => {
        const getPaymentTypeRows = async () => {
            if (Boolean(existingJson) && Boolean(newValuesJson)){
                const e = JSON.parse(existingJson);
                const n = JSON.parse(newValuesJson);
                let result: ChangeRequestFieldRow[] = [];
                const nPaymentTypeId = n['paymentTypeId'];
                if (nPaymentTypeId === 1) {
                    const currentClientId = e['PaymentCompanyID'] as number;
                    const currentContactId = e['PaymentContactID'] as number;

                    const client = await GetClientById(currentClientId);
                    const contact = await GetContactById(currentContactId);

                    result.push({ id: 'paymenttype', field: 'Payment Type', existingValue: 'Sub Contractor', newValue: 'Employee', summary: 'Changed' });
                    result.push({ id: 'paymentcompany', field: 'Payment Company', existingValue: client ? client.legalName : '', newValue: '', summary: 'Changed', previewEntityType1: 'client', previewRecordId1: currentClientId });
                    result.push({ id: 'paymentcontact', field: 'Payment Contact', existingValue: contact ? contact.fullName : '', newValue: '', summary: 'Changed', previewEntityType1: 'contact', previewRecordId1: currentContactId });
                }
                if (nPaymentTypeId === 2) {
                    const newClientId = n['paymentCompanyId'] as number;
                    const newContactId = n['paymentContactId'] as number;

                    const client = await GetClientById(newClientId);
                    const contact = await GetContactById(newContactId);

                    result.push({ id: 'paymenttype', field: 'Payment Type', existingValue: 'Employee', newValue: 'Sub Contractor', summary: 'Changed' });
                    result.push({ id: 'paymentcompany', field: 'Payment Company', existingValue: '', newValue: client ? client.legalName : '', summary: 'Changed', previewEntityType2: 'client', previewRecordId2: newClientId });
                    result.push({ id: 'paymentcontact', field: 'Payment Contact', existingValue: '', newValue: contact ? contact.fullName : '', summary: 'Changed', previewEntityType2: 'contact', previewRecordId2: newContactId });
                }
                setPaymentTypeRows(result);
            }
        };
        changeTypeId === ChangeRequestTypeEnum.PaymentType && getPaymentTypeRows();
    }, [changeTypeId, existingJson, newValuesJson]);

    const getConsultantRow = useCallback((eId: number, nId: number, ePercent: number, nPercent: number, field: string, key: string): ChangeRequestFieldRow => {
        const eUser = eId ? allUsers.find(u => u.id === eId) : null;
        const nUser = nId ? allUsers.find(u => u.id === nId) : null;
        return {
            id: key,
            field: field,
            existingValue: eUser ? `${eUser.name} (${ePercent}%)` : '',
            newValue: nUser ? `${nUser.name} (${nPercent}%)` : '',
            summary: eId === nId && ePercent === nPercent ? 'No Change' : 'Changed'
        };
    }, [allUsers]);

    const getConsultantRows = useCallback((e: any, n: any): ChangeRequestFieldRow[] => {
        const eId1 = getObjectValue(e, 'consultantid1');
        const eId2 = getObjectValue(e, 'consultantid2');
        const eId3 = getObjectValue(e, 'consultantid3');
        const eId4 = getObjectValue(e, 'consultantid4');
        const eId5 = getObjectValue(e, 'consultantid5');
        const nId1 = getObjectValue(n, 'consultantid1');
        const nId2 = getObjectValue(n, 'consultantid2');
        const nId3 = getObjectValue(n, 'consultantid3');
        const nId4 = getObjectValue(n, 'consultantid4');
        const nId5 = getObjectValue(n, 'consultantid5');

        const eP1 = getObjectValue(e, 'consultantpercentage1');
        const eP2 = getObjectValue(e, 'consultantpercentage2');
        const eP3 = getObjectValue(e, 'consultantpercentage3');
        const eP4 = getObjectValue(e, 'consultantpercentage4');
        const eP5 = getObjectValue(e, 'consultantpercentage5');
        const nP1 = getObjectValue(n, 'consultantpercentage1');
        const nP2 = getObjectValue(n, 'consultantpercentage2');
        const nP3 = getObjectValue(n, 'consultantpercentage3');
        const nP4 = getObjectValue(n, 'consultantpercentage4');
        const nP5 = getObjectValue(n, 'consultantpercentage5');

        return [
            getConsultantRow(eId1, nId1, eP1, nP1, 'Consultant 1', 'consultant1'),
            getConsultantRow(eId2, nId2, eP2, nP2, 'Consultant 2', 'consultant2'),
            getConsultantRow(eId3, nId3, eP3, nP3, 'Consultant 3', 'consultant3'),
            getConsultantRow(eId4, nId4, eP4, nP4, 'Consultant 4', 'consultant4'),
            getConsultantRow(eId5, nId5, eP5, nP5, 'Consultant 5', 'consultant5'),
        ];
    }, [getConsultantRow]);

    const getHoursPerDayAndDaysPerWeekRows = useCallback((e: any, n: any): ChangeRequestFieldRow[] => {
        const hpdKey = 'hoursperday';
        const eHpd = getObjectValue(e, hpdKey);
        const nHpd = getObjectValue(n, hpdKey);

        const dpwKey = 'daysperweek';
        const eDpw = getObjectValue(e, dpwKey);
        const nDpw = getObjectValue(n, dpwKey);

        return [
            { id: hpdKey, field: 'Hours Per Day', existingValue: formatNumber(eHpd), newValue: formatNumber(nHpd), summary: eHpd === nHpd ? 'No Change' : 'Changed' },
            { id: dpwKey, field: 'Days Per Week', existingValue: eDpw, newValue: nDpw, summary: eDpw === nDpw ? 'No Change' : 'Changed' },
        ];
    }, []);
    
    const normalRows = useMemo<ChangeRequestFieldRow[]>(() => {
        if (Boolean(existingJson) && Boolean(newValuesJson)) {
            const e = JSON.parse(existingJson);
            const n = JSON.parse(newValuesJson);

            if (changeTypeId === ChangeRequestTypeEnum.StartDateChange){
                const key = 'startdate';
                const ve =  getObjectValue(e, key);
                const ne =  getObjectValue(n, key);
                let rows = [
                    {
                        id: key,
                        field: 'Start Date',
                        existingValue: getDateStringValue(ve),
                        newValue: getDateStringValue(ne),
                        summary: 'Changed'
                    }
                ];

                const key2 = 'enddate';
                const ve2 = getObjectValue(e, key2);
                const ne2 = getObjectValue(n, key2);

                if (ve2 || ne2) {
                    rows.push({
                        id: key2,
                        field: 'End Date',
                        existingValue: getDateStringValue(ve2),
                        newValue: getDateStringValue(ne2),
                        summary: 'Changed'
                    });
                }

                return rows;
            }
            else if (changeTypeId === ChangeRequestTypeEnum.ConvertToPerm) {
                const spKey = 'salarypackage';
                const nSp = formatNumber(getObjectValue(n, spKey));
                const nSpu = getTimeUnitsName(getObjectValue(n, 'salaryunits'));

                const ifKey = 'invoicefee';
                const nIf = formatNumber(getObjectValue(n, 'feeValue'));
                const nIft = getObjectValue(n, 'feeType') === 1 ? 'Percentage' : 'Fixed';

                return [
                    ...getHoursPerDayAndDaysPerWeekRows(e, n),
                    { id: spKey, field: 'Salary Package', existingValue: '', newValue: `${nSp} ${nSpu}`, summary: 'Changed' },
                    { id: ifKey, field: 'Invoice Fee', existingValue: '', newValue: `${nIf} ${nIft}`, summary: 'Changed' },
                    ...getConsultantRows(e, n)
                ];
            }
            else if (changeTypeId === ChangeRequestTypeEnum.ConsultantsChange) {
                return getConsultantRows(e, n);
            }
            else {
                if (changeTypeId === ChangeRequestTypeEnum.RateChange || changeTypeId === ChangeRequestTypeEnum.Extension || changeTypeId === ChangeRequestTypeEnum.PaymentType || changeTypeId === ChangeRequestTypeEnum.RateCorrection) {
                    let result: ChangeRequestFieldRow[] = [];
                    if (changeTypeId === ChangeRequestTypeEnum.Extension) {
                        const key = 'enddate';
                        const ed =  getObjectValue(e, 'PlacementEndDate');
                        const nd =  getObjectValue(n, key);
                        result.push({ id: key, field: 'End Date', existingValue: ed, newValue: getDateStringValue(nd), summary: 'Changed' });
                    }

                    const crKey = 'chargerate';
                    const eCr = formatNumber(getObjectValue(e, crKey));
                    const eCru = getTimeUnitsName(getObjectValue(e, 'chargerateunits'));
                    const nCr = formatNumber(getObjectValue(n, crKey));
                    const nCru = getTimeUnitsName(getObjectValue(n, 'chargerateunits'));
                    result.push({ id: crKey, field: 'Charge Rate', existingValue: `${eCr} ${eCru}`, newValue: `${nCr} ${nCru}`, summary: eCr === nCr && eCru === nCru ? 'No Change' : 'Changed' });

                    const prKey = 'payrate';
                    const ePr = formatNumber(getObjectValue(e, prKey));
                    const ePru = getTimeUnitsName(getObjectValue(e, 'payrateunits'));
                    const nPr = formatNumber(getObjectValue(n, prKey));
                    const nPru = getTimeUnitsName(getObjectValue(n, 'payrateunits'));
                    result.push({ id: prKey, field: 'Pay Rate', existingValue: `${ePr} ${ePru}`, newValue: `${nPr} ${nPru}`, summary: ePr === nPr && ePru === nPru ? 'No Change' : 'Changed' });

                    const ocKey = 'oncosts';
                    const eOc = formatNumber(getObjectValue(e, ocKey));
                    const eOcu = getTimeUnitsName(getObjectValue(e, 'oncostsunits'));
                    const nOc = formatNumber(getObjectValue(n, ocKey));
                    const nOcu = getTimeUnitsName(getObjectValue(n, 'oncostsunits'));
                    result.push({ id: ocKey, field: 'On Costs', existingValue: `${eOc} ${eOcu}`, newValue: `${nOc} ${nOcu}`, summary: eOc === nOc && eOcu === nOcu ? 'No Change' : 'Changed' });

                    const npKey = 'noticeperiod';
                    const eNp = formatNumber(getObjectValue(e, npKey));
                    const eNpu = getNoticePeriodUnitsName(getObjectValue(e, 'noticeperiodunits'));
                    const nNp = formatNumber(getObjectValue(n, npKey));
                    const nNpu = getNoticePeriodUnitsName(getObjectValue(n, 'noticeperiodunits'));
                    result.push({ id: npKey, field: 'Notice Period', existingValue: `${eNp} ${eNpu}`, newValue: `${nNp} ${nNpu}`, summary: eNp === nNp && eNpu === nNpu ? 'No Change' : 'Changed' });

                    return [...result, ...getHoursPerDayAndDaysPerWeekRows(e, n), ...getConsultantRows(e, n)];
                }
            }
        }
        return [];
    }, [changeTypeId, existingJson, newValuesJson, getConsultantRows, getHoursPerDayAndDaysPerWeekRows]);

    const udfRows = useMemo<ChangeRequestFieldRow[]>(() => {
        if (needsUdfs && udfSettings.length > 0) {
            if (Boolean(existingJson) && Boolean(newValuesJson)) {
                const e = JSON.parse(existingJson);
                const n = JSON.parse(newValuesJson);
                let result: ChangeRequestFieldRow[] = [];

                for (let i = 0; i < udfSettings.length; i++) {
                    const udf = udfSettings[i];
                    const key = udfNameMap[udf.name];
                    const ev = e[key] ?? '';
                    const nv = n[key] ?? '';

                    if (udf.dataType === 'String') 
                        result.push({ id: key, field: udf.agencyName, existingValue: ev, newValue: nv, summary: ev === nv ? 'No Change': 'Changed' });
                    else if (udf.dataType === 'DateTime') {
                        const ed = getDateStringValue(ev);
                        const nd = getDateStringValue(nv);
                        result.push({ id: key, field: udf.agencyName, existingValue: ed, newValue: nd, summary: ed === nd ? 'No Change': 'Changed' });
                    }
                    else if (udf.dataType === 'Decimal') {
                        const ed = formatNumber(ev);
                        const nd = formatNumber(nv);
                        result.push({ id: key, field: udf.agencyName, existingValue: ed, newValue: nd, summary: ed === nd ? 'No Change': 'Changed' });
                    }
                }

                return result;
            }
        }
        return [];
    }, [needsUdfs, udfSettings, existingJson, newValuesJson]);

    const rows = useMemo<ChangeRequestFieldRow[]>(() => {
        return [...paymentTypeRows, ...normalRows, ...udfRows];
    }, [normalRows, paymentTypeRows, udfRows]);

    const columns = useMemo<GridColDef[]>(() => {
        const summaryRenderer = (params: GridRenderCellParams) => {
            if (params.value === 'Changed') return <strong>{params.value}</strong>;
            return params.value;
        };

        const existingValueRenderer = (params: GridRenderCellParams) => {
            if (params.row.previewEntityType1 && params.row.previewRecordId1) {
                const quickViewType = params.row.previewEntityType1 as PreviewEntityType;
                const quickViewId = params.row.previewRecordId1 as number;
                let href = '';
                if (quickViewType === 'client') href = `/clients/${quickViewId}`;
                if (quickViewType === 'contact') href = `/contacts/${quickViewId}`;
                if (href) {
                    return (
                        <Link
                            to={href}
                            style={ linkStyle }
                            onMouseEnter={ () => previewHandler(quickViewType, quickViewId) }
                            onMouseLeave={ previewCloseHanlder }
                            target="_blank"
                        >{params.value}</Link>
                    );
                }
            }

            return params.value;
        };

        const newValueRenderer = (params: GridRenderCellParams) => {
            if (params.row.previewEntityType2 && params.row.previewRecordId2) {
                const quickViewType = params.row.previewEntityType2 as PreviewEntityType;
                const quickViewId = params.row.previewRecordId2 as number;
                let href = '';
                if (quickViewType === 'client') href = `/clients/${quickViewId}`;
                if (quickViewType === 'contact') href = `/contacts/${quickViewId}`;
                if (href) {
                    return (
                        <Link
                            to={href}
                            style={ linkStyle }
                            onMouseEnter={ () => previewHandler(quickViewType, quickViewId) }
                            onMouseLeave={ previewCloseHanlder }
                            target="_blank"
                        >{params.value}</Link>
                    );
                }
            }

            return params.value;
        };

        return [
            { headerName: 'Field', field: 'field', width: 150, headerAlign: 'center', align: 'center' },
            { headerName: 'Existing Value', field: 'existingValue', headerAlign: 'center', align: 'center', width: 250, renderCell: existingValueRenderer },
            { headerName: 'New Value', field: 'newValue', headerAlign: 'center', align: 'center', width: 250, renderCell: newValueRenderer },
            { headerName: 'Summary', field: 'summary', width: 200, renderCell: summaryRenderer },
        ];
    }, [previewCloseHanlder, previewHandler]);

    return (
        <>
            <GridWithStateWrapper
                gridName={gridName}
                rows={rows}
                columns={columns}
                apiRef={apiRef}
                density="compact"
                hideFooter
                disableRowSelectionOnClick
            />
        </>
    );
}