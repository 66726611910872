import React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import { GroupParticipantListItem } from "common/models/Group";

interface Props {
    title?: string,
    subTitle?: string,
    items: GroupParticipantListItem[],
    selected?: number[],
    toggleHandler: (id: number) => void,
    toggleAllHandler: (ids: number[]) => void
}

export default function UserGroupRelationList({
    title = '',
    subTitle = '',
    items,
    selected = [],
    toggleHandler,
    toggleAllHandler
}: Props) {

    const handleToggleAll = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = e.target;
        if(checked) {
            let ids: number[] = [];
            for (let i = 0; i < items.length; i++) {
                const item = items[i];
                ids.push(item.userId);
            }
            toggleAllHandler(ids);
        }
        else {
            toggleAllHandler([]);
        }
    }

    return (
        <Card>
            <CardHeader
                sx={{ px: 2, py: 1 }}
                title={title}
                subheader={subTitle}
                avatar={
                    <Checkbox
                        checked={selected.length === items.length && items.length !== 0}
                        indeterminate={selected.length !== items.length && selected.length !== 0}
                        onChange={ handleToggleAll }
                        disabled={ items.length === 0 }
                    />
                }
            />
            <Divider/>
            <List
                sx={{ width: 350, height: 460, bgcolor: 'background.paper', overflow: 'auto' }}
                dense
                component="div"
                role="list"
            >
                {items.map((item, index) => {
                    return (
                        <ListItemButton key={item.userId} onClick={() => toggleHandler(item.userId)}>
                            <ListItemIcon>
                                <Checkbox checked={selected.indexOf(item.userId) !== -1} />
                            </ListItemIcon>
                            <ListItemText primary={item.user} secondary={item.isEnabled ? "": "INACTIVE"} />
                        </ListItemButton>
                    );
                })}
            </List>
        </Card>
    );
}