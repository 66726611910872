import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import React, { useCallback, useEffect, useState } from "react";
import UserPicker from "components/Pickers/UserPicker";
import TextField from "@mui/material/TextField";
import { SxProps, Theme } from "@mui/material/styles";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import MenuItem from "@mui/material/MenuItem";
import { PlacementRateChangeRequest } from "common/models/Placements";
import { ConvertPlacementToPerm } from "services/ChangeRequestsService";
import RWTextFieldComponent from "components/RWTextFieldComponent";
import { RegexIsPositiveNumberWith2Decimals } from "util/RegExUtils";

interface Props {
    open: boolean,
    placementId: number,
    rate: PlacementRateChangeRequest | null,
    isPendingChanges?: boolean,
    closeHandler: () => void,
    loadingHandler?: (isLoading: boolean) => void,
    successHandler?: (message: string) => void,
    errorHandler?: (message: string) => void
}

const leftStyle: SxProps<Theme> = { flex: '1 1 0', pr: '5px' };
const rightStyle: SxProps<Theme> = { flex: '1 1 0', pl: '5px' };
const validNumberValueRegex = /^-?\d+(\.\d{1,2})?$/;

const daysPerWeekValidator = (value: string) => {
    return value === '' || (RegexIsPositiveNumberWith2Decimals(value) && +value <= 7);
};

export default function ConvertToPermDialog({ open, placementId, rate, isPendingChanges, closeHandler: closeHandlerProp, loadingHandler, successHandler, errorHandler }: Props) {
    const [effectiveDate, setEffectiveDate] = useState<moment.Moment | null>(null);
    const [salary, setSalary] = useState('');
    const [salaryUnits, setSalaryUnits] = useState(0);
    const [invoiceFee, setInvoiceFee] = useState('');
    const [invoiceFeeType, setInvoiceFeeType] = useState(0);
    const [hoursPerDay, setHoursPerDay] = useState("8");
    const [daysPerWeek, setDaysPerWeek] = useState(5);
    const [consultant1Id, setConsultant1Id] = useState(0);
    const [consultant1Percent, setConsultant1Percent] = useState('');
    const [consultant2Id, setConsultant2Id] = useState(0);
    const [consultant2Percent, setConsultant2Percent] = useState('');
    const [consultant3Id, setConsultant3Id] = useState(0);
    const [consultant3Percent, setConsultant3Percent] = useState('');
    const [consultant4Id, setConsultant4Id] = useState(0);
    const [consultant4Percent, setConsultant4Percent] = useState('');
    const [consultant5Id, setConsultant5Id] = useState(0);
    const [consultant5Percent, setConsultant5Percent] = useState('');
    
    useEffect(() => {
        if (open && rate) {
            setEffectiveDate(null);
            setSalary('');
            setSalaryUnits(0);
            setInvoiceFee('');
            setInvoiceFeeType(0)
            setHoursPerDay(rate.hoursPerDay.toString());
            setDaysPerWeek(rate.daysPerWeek);

            setConsultant1Id(rate.consultantID1);
            setConsultant2Id(rate.consultantID2);
            setConsultant3Id(rate.consultantID3);
            setConsultant4Id(rate.consultantID4);
            setConsultant5Id(rate.consultantID5);

            setConsultant1Percent(rate.consultantPercentage1.toString());
            setConsultant2Percent(rate.consultantPercentage2.toString());
            setConsultant3Percent(rate.consultantPercentage3.toString());
            setConsultant4Percent(rate.consultantPercentage4.toString());
            setConsultant5Percent(rate.consultantPercentage5.toString()); 
        }
    }, [open, rate]);

    const closeHandler = useCallback(() => {
        closeHandlerProp();
        errorHandler && errorHandler('');
    }, [closeHandlerProp, errorHandler]);

    useEffect(() => {
        if (open && isPendingChanges) errorHandler && errorHandler('There is already a pending Change Request');
    }, [errorHandler, isPendingChanges, open]);

    const submitCallback = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        const rateId = rate ? rate.id : 0;
        const p1 = consultant1Percent.trim() === '' ? '0' : consultant1Percent;
        const p2 = consultant2Percent.trim() === '' ? '0' : consultant2Percent;
        const p3 = consultant3Percent.trim() === '' ? '0' : consultant3Percent;
        const p4 = consultant4Percent.trim() === '' ? '0' : consultant4Percent;
        const p5 = consultant5Percent.trim() === '' ? '0' : consultant5Percent;
        const feeValue = invoiceFee.trim() === '' ? '0' : invoiceFee;
        const salaryPackage = salary.trim() === '' ? '0' : salary;

        const efDate = effectiveDate && effectiveDate.isValid() ? effectiveDate.format('YYYY-MM-DD') : '';
        const res = await ConvertPlacementToPerm(placementId, {
            consultantId1: consultant1Id,
            consultantId2: consultant2Id,
            consultantId3: consultant3Id,
            consultantId4: consultant4Id,
            consultantId5: consultant5Id,
            consultantPercentage1: +p1,
            consultantPercentage2: +p2,
            consultantPercentage3: +p3,
            consultantPercentage4: +p4,
            consultantPercentage5: +p5,
            daysPerWeek: daysPerWeek,
            hoursPerDay: +hoursPerDay,
            effectiveDate: efDate,
            feeType: invoiceFeeType,
            feeValue: +feeValue,
            rateId: rateId,
            salaryPackage: +salaryPackage,
            salaryUnits: salaryUnits

        }, errorHandler);
        if (res) {
            successHandler && successHandler('Change Request Submitted')
            closeHandler();
        }

        loadingHandler && loadingHandler(false);
    }, [placementId, rate, effectiveDate, daysPerWeek, hoursPerDay,
        consultant1Id, consultant2Id, consultant3Id, consultant4Id, consultant5Id,
        consultant1Percent, consultant2Percent, consultant3Percent, consultant4Percent, consultant5Percent,
        invoiceFee, invoiceFeeType, salary, salaryUnits, closeHandler, loadingHandler, successHandler, errorHandler]);

    const onSalaryChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;
        if ((val.endsWith('.') && !val.endsWith('..'))|| val.match(validNumberValueRegex)) {
            setSalary(val);
        }
    }, []);

    const onInvoiceFeeChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;
        if ((val.endsWith('.') && !val.endsWith('..'))|| val.match(validNumberValueRegex)) {
            setInvoiceFee(val);
        }
    }, []);

    const onPercent1Change = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;
        if ((val.endsWith('.') && !val.endsWith('..'))|| val.match(validNumberValueRegex)) {
            setConsultant1Percent(val);
        }
    }, []);

    const onPercent2Change = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;
        if ((val.endsWith('.') && !val.endsWith('..'))|| val.match(validNumberValueRegex)) {
            setConsultant2Percent(val);
        }
    }, []);

    const onPercent3Change = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;
        if ((val.endsWith('.') && !val.endsWith('..'))|| val.match(validNumberValueRegex)) {
            setConsultant3Percent(val);
        }
    }, []);

    const onPercent4Change = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;
        if ((val.endsWith('.') && !val.endsWith('..'))|| val.match(validNumberValueRegex)) {
            setConsultant4Percent(val);
        }
    }, []);

    const onPercent5Change = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;
        if (val === '')
        if ((val.endsWith('.') && !val.endsWith('..'))|| val.match(validNumberValueRegex)) {
            setConsultant5Percent(val);
        }
    }, []);

    return (
        <Dialog open={open} fullWidth>
            <DialogTitle>Convert to Perm</DialogTitle>
            <DialogContent>
                <Stack spacing={2} mt="10px">
                    <DatePicker label="Effective Date" value={effectiveDate} onChange={m => setEffectiveDate(m)} slotProps={{actionBar: { actions: ["clear", "today", "cancel", "accept"] }}} />
                    <Box display="flex">
                        <RWTextFieldComponent value={salary} label="Salary" placeholder="From" sxOptions={leftStyle} onChange={ onSalaryChange } />
                        <TextField select value={salaryUnits.toString()} label="Salary Units" sx={rightStyle} onChange={ ({target}) => setSalaryUnits(+target.value) } >
                            <MenuItem key={0} value="0">Select</MenuItem>
                            <MenuItem key={1} value="1">Hourly</MenuItem>
                            <MenuItem key={2} value="2">Daily</MenuItem>
                            <MenuItem key={3} value="3">Weekly</MenuItem>
                            <MenuItem key={4} value="4">Monthly</MenuItem>
                            <MenuItem key={5} value="5">Yearly</MenuItem>
                        </TextField>
                    </Box>
                    <Box display="flex">
                        <RWTextFieldComponent value={invoiceFee} label="Invoice Fee" sxOptions={leftStyle} onChange={ onInvoiceFeeChange } />
                        <TextField select value={invoiceFeeType.toString()} label="Invoice Fee Type" sx={rightStyle} onChange={ ({target}) => setInvoiceFeeType(+target.value) } >
                            <MenuItem value="0">Select</MenuItem>
                            <MenuItem value="1">Percentage</MenuItem>
                            <MenuItem value="2">Fixed</MenuItem>
                        </TextField>
                    </Box>
                    <RWTextFieldComponent value={hoursPerDay} label="Hours Per Day" onChange={ (e) => setHoursPerDay(e.target.value) } />
                    <RWTextFieldComponent
                        label="Days Per Week"
                        value={daysPerWeek.toString()}
                        onChange={({target}) => setDaysPerWeek(+target.value)}
                        validator={daysPerWeekValidator}
                    />
                    <Box display="flex">
                        <UserPicker label="Consultant 1" userId={consultant1Id} onSelect={ u => setConsultant1Id(u ? u.id : 0)} sx={leftStyle} />
                        <RWTextFieldComponent label="Consultant 1 %" value={consultant1Percent} onChange={onPercent1Change} disabled={consultant1Id === 0} sxOptions={rightStyle} />
                    </Box>
                    <Box display="flex">
                        <UserPicker label="Consultant 2" userId={consultant2Id} onSelect={ u => setConsultant2Id(u ? u.id : 0)} sx={leftStyle} />
                        <RWTextFieldComponent label="Consultant 2 %" value={consultant2Percent} onChange={onPercent2Change} disabled={consultant2Id === 0} sxOptions={rightStyle} />
                    </Box>
                    <Box display="flex">
                        <UserPicker label="Consultant 3" userId={consultant3Id} onSelect={ u => setConsultant3Id(u ? u.id : 0)} sx={leftStyle} />
                        <RWTextFieldComponent label="Consultant 3 %" value={consultant3Percent} onChange={onPercent3Change} disabled={consultant3Id === 0} sxOptions={rightStyle} />
                    </Box>
                    <Box display="flex">
                        <UserPicker label="Consultant 4" userId={consultant4Id} onSelect={ u => setConsultant4Id(u ? u.id : 0)} sx={leftStyle} />
                        <RWTextFieldComponent label="Consultant 4 %" value={consultant4Percent} onChange={onPercent4Change} disabled={consultant4Id === 0} sxOptions={rightStyle} />
                    </Box>
                    <Box display="flex">
                        <UserPicker label="Consultant 5" userId={consultant5Id} onSelect={ u => setConsultant5Id(u ? u.id : 0)} sx={leftStyle} />
                        <RWTextFieldComponent label="Consultant 5 %" value={consultant5Percent} onChange={onPercent5Change} disabled={consultant5Id === 0} sxOptions={rightStyle} />
                    </Box>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="error" onClick={ closeHandler }>Cancel</Button>
                <Button variant="contained" color="success" disabled={!Boolean(effectiveDate)} onClick={ submitCallback } >Submit</Button>
            </DialogActions>
        </Dialog>
    );
}