import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

interface Props {
    open: boolean,
    saveChangesHandler: () => void,
    discardChangesHandler: () => void,
    cancelHandler: () => void,
    message?: string,
    hideBackdrop?: boolean
}

export default function UnsavedChangesDialog({ open, message, saveChangesHandler, discardChangesHandler, cancelHandler, hideBackdrop }: Props) {
    return (
        <Dialog open={open} hideBackdrop={hideBackdrop}>
            <DialogTitle>Unsaved Changes</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    { message
                        ? message
                        : 'If you leave this page, any changes you have made will be lost. Are you sure you wish to leave this page?'
                    }
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="error" onClick={cancelHandler} >Cancel</Button>
                <Button variant="contained" color="primary" onClick={discardChangesHandler} >Discard Changes</Button>
                <Button variant="contained" color="success" onClick={saveChangesHandler}>Save Changes</Button>


            </DialogActions>
        </Dialog>
    );
}