import { OneDriveFile } from "common/models/Integrations/OneDrive";
import { UserIntegration } from "../common/models/Configuration/UserIntegrations";
import { ErrorHandler, RequestOptions, StringValueWrapper } from "../common/models/GenericTypes";
import { Delete, Get, Post } from "../util/HttpUtils";

export async function GetMyUserIntegrations(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<UserIntegration[]>('api/Users/Me/Integrations', options);
}

export async function GetMyUserIntegrationById(id: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<UserIntegration>(`api/Users/Me/Integrations/${id}`, options);
}

export async function GetRedirectUrl_Gmail(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<StringValueWrapper>('api/Integrations/Users/Gmail', options);
}

export async function Connect_Gmail(code: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>(`api/Integrations/Users/Gmail`, options, { value: code });
}

export async function Disconnect_Gmail(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Delete<boolean>('api/Integrations/Users/Gmail', options);
}

export async function GetRedirectUrl_LinkedIn(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<StringValueWrapper>('api/Integrations/Users/LinkedIn', options);
}

export async function Connect_LinkedIn(code: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>(`api/Integrations/Users/LinkedIn`, options, { value: code });
}

export async function Disconnect_LinkedIn(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Delete<boolean>('api/Integrations/Users/LinkedIn', options);
}

export async function GetRedirectUrl_OneDrive(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<StringValueWrapper>('api/Integrations/Users/OneDrive', options);
}

export async function Connect_OneDrive(code: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>(`api/Integrations/Users/OneDrive`, options, { value: code });
}

export async function Disconnect_OneDrive(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Delete<boolean>('api/Integrations/Users/OneDrive', options);
}

export async function OneDriveUploadFromDocLibrary(docId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<OneDriveFile>(`api/Integrations/Users/OneDrive/${docId}`, options);
}

export async function OneDriveMoveToDocLibrary(docId: number, oneDriveFileId: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>(`api/Integrations/Users/OneDrive/${docId}`, options, { value: oneDriveFileId });
}

export async function GetRedirectUrl_MicrosoftTeams(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<StringValueWrapper>('api/Integrations/Users/MicrosoftTeams', options);
}

export async function Connect_MicrosoftTeams(code: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>(`api/Integrations/Users/MicrosoftTeams`, options, { value: code });
}

export async function Disconnect_MicrosoftTeams(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Delete<boolean>('api/Integrations/Users/MicrosoftTeams', options);
}

export async function GetRedirectUrl_Zoom(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<StringValueWrapper>('api/Integrations/Users/Zoom', options);
}

export async function Connect_Zoom(code: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>(`api/Integrations/Users/Zoom`, options, { value: code });
}

export async function Disconnect_Zoom(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Delete<boolean>('api/Integrations/Users/Zoom', options);
}