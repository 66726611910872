import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import { styled } from "@mui/material/styles";
import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import * as P from "common/data/Permissions/UserMenuAccess";
import * as A from "common/data/Permissions/AdvertsAccess";
import * as C from "common/data/Permissions/CandidatesAccess";
import * as AD from "common/data/Permissions/ConfigurationAccess";
interface Props {
    index: number,
    hoveredIndex: number | null,
    onMouseEnter: (i: number | null) => void,
    closeUserMenuHandler: () => void
}

const CustomPopper = styled(Popper)(({ theme }) => ({
    zIndex: theme.zIndex.appBar + 2
}));

const customLinkStyle: React.CSSProperties = { color: 'inherit', textDecoration: 'none' };

export default function WizTopBarTemplatesSubmenu({ index, hoveredIndex, onMouseEnter, closeUserMenuHandler }: Props) {
    const [anchor, setAnchor] = useState<HTMLElement | null>(null);

    useEffect(() => {
        if (index !== hoveredIndex) setAnchor(null);
    }, [index, hoveredIndex]);

    const handleHover = (e: React.MouseEvent<HTMLElement>) => {
        setAnchor(e.currentTarget);
        onMouseEnter(index);
    }

    const handleClickedLink = () => closeUserMenuHandler();
    const isTemplatesAdmin = useMemo(() => P.AllowTemplates(), []);
    const isAllowedAdverts = useMemo(() => A.AllowAdvertsAddEdit(), []);
    const isAllowedAdvertsConfig = useMemo(() => AD.AllowAdverts(), []);
    const isAllowedCandidates = useMemo(() => C.AllowCandidatesModule(), []);

    return (
        <>
            <MenuItem onMouseEnter={handleHover}>
                <ListItemText>Templates</ListItemText>
                <ArrowRightIcon />
            </MenuItem>
            {anchor &&
                <CustomPopper
                    open={Boolean(anchor)}
                    anchorEl={anchor}
                    placement="left-start"
                >
                    <Paper elevation={8}>
                        <MenuList>
                            {isAllowedAdverts &&
                                <Link to="/adverts/templates" style={customLinkStyle}>
                                    <MenuItem onClick={handleClickedLink} >
                                        Adverts
                                    </MenuItem>
                                </Link>
                            }
                            {isAllowedAdvertsConfig &&
                                <Link to="/configuration/adverts/screening-questionnaires" style={customLinkStyle}>
                                    <MenuItem onClick={handleClickedLink} >
                                        Advert Screening Questionnaires
                                    </MenuItem>
                                </Link>
                            }
                            {isTemplatesAdmin &&
                                <Link to="/account/templates/documents" style={customLinkStyle}>
                                    <MenuItem onClick={ handleClickedLink } >
                                        Documents
                                    </MenuItem>
                                </Link>
                            }
                            <Link to="/account/templates/messages" style={customLinkStyle}>
                                <MenuItem onClick={ handleClickedLink } >
                                    Messages
                                </MenuItem>
                            </Link>
                            {isAllowedCandidates &&
                                <Link to="/account/templates/reference-checks" style={customLinkStyle}>
                                    <MenuItem onClick={handleClickedLink} >
                                        Reference Checks
                                    </MenuItem>
                                </Link>
                            }
                            {isAllowedCandidates &&
                                <Link to="/account/templates/screening-questions" style={customLinkStyle}>
                                    <MenuItem onClick={handleClickedLink} >
                                        Screening Questions
                                    </MenuItem>
                                </Link>
                            }
                        </MenuList>
                    </Paper>
                </CustomPopper>
            }
        </>
    );
}