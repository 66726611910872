import { GridActionsCellItem, GridColDef, GridColumnVisibilityModel, GridRenderCellParams, GridValueGetter, useGridApiRef } from "@mui/x-data-grid-premium";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Advert } from "common/models/Advert";
import { SearchAdverts, ExpireAdvertById, GetDashboardAdverts, GetMyDraftAdverts, GetMyAdverts, AdvertLookup, GetAdvertsByJobId } from "services/AdvertsService";
import GridWithStateWrapper from "../GridWidthStateWrapper";
import { Link } from "react-router-dom";
import PreviewLoaderComponent from "../Previews/PreviewLoader";
import ConfirmationDialog from "../Dialogs/Generic/ConfirmationDialog";
import CopyAdvertDialog from "../Dialogs/Adverts/CopyAdvertDialog";
import { PreviewEntityType } from "common/models/Previews/Previews";
import { companyHasSinglePermission, userHasSinglePermission } from "util/PermissionsUtils";
import { Permission } from "common/models/Permissions";
import { AdvertSearch } from "common/models/Search/AdvertSearch";
import ActionsDropDownButton from "../SummaryBars/Actions/ActionsDropsDownMenu";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";

interface Props {
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
    successHandler?: (message: string) => void,
    setRowCountHandler?: (count: number) => void,
    gridName: string,
    source: 'dashboard' | 'my-adverts' | 'drafts' | 'search' | 'lookup' | 'job-record',
    sourceId?: number,
    lookupTerm?: string,
    searchData?: AdvertSearch
}

const linkStyle: React.CSSProperties = { color: 'inherit', textDecoration: 'underline' };

export default function AdvertsGridComponent({ source, sourceId, gridName, searchData, lookupTerm = '', setRowCountHandler, loadingHandler, errorHandler, successHandler }: Props) {
    const [rows, setRows] = useState<Advert[]>([]);
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    const [previewType, setPreviewType] = useState<PreviewEntityType | ''>('');
    const [previewRecordId, setPreviewRecordId] = useState(0);
    const [expireAdvertId, setExpireAdvertId] = useState<number | null>(null);
    const [copyAdvertId, setCopyAdvertId] = useState<number | null>(null);
    const [advertJobId, setAdvertJobId] = useState<number | null>(null);
    const apiRef = useGridApiRef();

    const getRowsCallback = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        let res: Advert[] | null = [];
        if (source === 'dashboard') res = await GetDashboardAdverts(errorHandler);
        if (source === 'my-adverts') res = await GetMyAdverts(errorHandler);
        if (source === 'drafts') res = await GetMyDraftAdverts(errorHandler);
        if (source === 'search' && searchData) res = await SearchAdverts(searchData, errorHandler);
        if (source === 'lookup' && lookupTerm) res = await AdvertLookup(lookupTerm, 0, errorHandler);
        if (source === 'job-record' && sourceId) res = await GetAdvertsByJobId(sourceId, errorHandler);

        if (res) {
            setRowCountHandler && setRowCountHandler(res.length);
            setRows(res);
        }
        loadingHandler && loadingHandler(false);
    }, [source, sourceId, searchData, lookupTerm, setRowCountHandler, loadingHandler, errorHandler]);

    useEffect(() => {
        getRowsCallback();
    }, [getRowsCallback]);

    const canAddEditAdverts = useMemo(() => companyHasSinglePermission(Permission.AdvertsAddEdit) && userHasSinglePermission(Permission.AdvertsAddEdit), []);

    const columns = useMemo<GridColDef[]>(() => {
        const dateValueGetter: GridValueGetter<Advert, any, undefined, string> = (value) => {
            if (value) {
                const m = moment(value);
                if (m.isValid() && m.get('year') > 1) {
                    return m.toDate();
                }
            }
        };

        const dateRenderer = (params: GridRenderCellParams) => {
            if (params.value) {
                return moment(params.value).format('DD MMM YYYY');
            }
        };

        const linkToAdvertRenderer = (params: GridRenderCellParams) => {
            if (params.value) {
                return <Link to={`/adverts/${params.id}`} 
                             style={ linkStyle } 
                             onMouseEnter={ () => handlePreviewHover('advert', params.row.id) } 
                             onMouseLeave={ handlePreviewClose }
                             target={ source === "search" ? "_blank" : "_self" }
                >{params.value}</Link>;
            }
            return params.value;
        };

        const linkToClientRenderer = (params: GridRenderCellParams) => {
            const clientId = params.row.clientID;
            if (clientId) {
                return <Link target="_blank" to={`/clients/${clientId}`} style={ linkStyle } onMouseEnter={ () => handlePreviewHover('client', clientId) } onMouseLeave={ handlePreviewClose } >{params.value}</Link>;
            }
            return params.value;
        };

        const linkToBoardsRenderer = (params: GridRenderCellParams) => {
            if (params.value) {
                return <Link target="_blank" to={`/adverts/${params.id}?tab=Boards`} style={ linkStyle } >{params.value}</Link>;
            }
            return params.value;
        };

        const linkToApplicationsRenderer = (params: GridRenderCellParams) => {
            const jobId = params.row.jobID;
            if (jobId) {
                return <Link target="_blank" to={`/jobs/${jobId}?tab=JOBS_Candidates&advertId=${params.id}`} style={ linkStyle } >{params.value}</Link>;
            }
            return params.value;
        };

        const linkToSiteRenderer = (params: GridRenderCellParams) => {
            const siteId = params.row.siteID;
            if (siteId) {
                return (
                    <Link
                        to={`/sites/${siteId}`}
                        style={linkStyle}
                        onMouseEnter={() => handlePreviewHover('site', siteId)}
                        onMouseLeave={handlePreviewClose}
                    >{params.value}</Link>
                );
            }
            return params.value;
        };

        const handlePreviewHover = (type: PreviewEntityType | '', id: number) => {
            setPreviewType(type);
            setPreviewRecordId(id);
            setIsPreviewOpen(true);
        };

        const handlePreviewClose = () => {
            setIsPreviewOpen(false);
        };

        return [
            { field: 'actions', headerName: 'Actions', align: 'center', headerAlign: 'center', type: 'actions', getActions: params => [
                (canAddEditAdverts && params.row.statusID >= 2 && params.row.statusID <= 5) ?
                    <GridActionsCellItem
                        icon={ <CancelIcon /> }
                        label="Expire"
                        onClick={ () => setExpireAdvertId(params.row.id) }
                        onResize={()=> {}}
                        onResizeCapture={() => {}}
                    />
                    : <></>
                ,
                (canAddEditAdverts) ?
                    <GridActionsCellItem
                        icon={<AddCircleIcon />}
                        label="Copy"
                        onClick={() => {
                            setCopyAdvertId(params.row.id);
                            setAdvertJobId(params.row.jobID);
                        }}
                        onResize={()=> {}}
                        onResizeCapture={() => {}}
                    />
                    : <></>
                ,
                (canAddEditAdverts && ((params.row.statusID >= 1 && params.row.statusID <= 5) || params.row.statusID === 10)) ?
                    <GridActionsCellItem
                        icon={
                            <Link
                                to={`/adverts/${params.id}/edit`}
                                target="_blank"
                                style={{ color: 'inherit', textDecoration: 'none', maxHeight: '20px', maxWidth: '20px' }}
                            >
                                <EditIcon sx={{ width: '20px', height: '20px' }} />
                            </Link>
                        }
                        label="Edit"
                        onResize={()=> {}}
                        onResizeCapture={() => {}}
                    />
                    : <></>
                ,
                <></>
            ] },
            { field: 'title', headerName: 'Title', width: 300, renderCell: linkToAdvertRenderer },
            { field: 'statusName', headerName: 'Status', align: 'center', headerAlign: 'center', width: 150 },
            { field: 'consultantName', headerName: 'Consultant', align: 'center', headerAlign: 'center', width: 200 },
            { field: 'clientName', headerName: 'Client', align: 'center', headerAlign: 'center', width: 250, renderCell: linkToClientRenderer },
            { field: 'postingDate', headerName: 'Posting Date', align: 'center', headerAlign: 'center', type: 'date', valueGetter: dateValueGetter, renderCell: dateRenderer, width: 150 },
            { field: 'expiryDate', headerName: 'Expiry Date', align: 'center', headerAlign: 'center', type: 'date', valueGetter: dateValueGetter, renderCell: dateRenderer, width: 150 },
            { field: 'createdDate', headerName: 'Created Date', align: 'center', headerAlign: 'center', type: 'date', valueGetter: dateValueGetter, renderCell: dateRenderer, width: 150 },
            { field: 'siteName', headerName: 'Site', align: 'center', headerAlign: 'center', renderCell: linkToSiteRenderer },
            { field: 'numberOfSites', headerName: '# Boards', type: 'number', align: 'center', headerAlign: 'center', width: 150, renderCell: linkToBoardsRenderer },
            { field: 'numApplicants', headerName: '# Applications', type: 'number', align: 'center', headerAlign: 'center', width: 150, renderCell: linkToApplicationsRenderer },
        ];
    }, [canAddEditAdverts, source]);

    const expireAdvertCallback = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        if (expireAdvertId) {
            const res = await ExpireAdvertById(expireAdvertId, errorHandler);
            if (res) {
                successHandler && successHandler('Advert Expired');
                setExpireAdvertId(null);
                getRowsCallback();
            }
        }
        loadingHandler && loadingHandler(false);
    }, [expireAdvertId, loadingHandler, errorHandler, getRowsCallback, successHandler]);

    const closeCopyDialogHandler = useCallback(() => {
        setCopyAdvertId(null);
        setAdvertJobId(null);
    }, []);

    const exportToExcel = useCallback(() => {
        const api = apiRef.current;
        if (api) api.exportDataAsExcel();
    }, [apiRef]);

    const canExport = useMemo(() => companyHasSinglePermission(Permission.AdvertsExport) && userHasSinglePermission(Permission.AdvertsExport), []);

    const gridActions = useMemo(() => {
        if (canExport) {
            return (
                <ActionsDropDownButton color="secondary" label="List Actions">
                    { canExport && <MenuItem onClick={exportToExcel}>Export</MenuItem>}
                </ActionsDropDownButton>
            );
        }
        return undefined;
    }, [canExport, exportToExcel]);

    const defaultHiddenCols = useMemo<GridColumnVisibilityModel>(() => {
        let model: GridColumnVisibilityModel = { 'siteName': false };
        if (source === 'drafts') model['expiryDate'] = false;
        else model['createdDate'] = false;
        return model;
    }, [source]);

    return (
        <>
            <PreviewLoaderComponent
                open={isPreviewOpen}
                entityType={previewType}
                recordId={previewRecordId}
            />
            <ConfirmationDialog
                open={expireAdvertId !== null}
                title="Confirm Action"
                message="Are you sure you want to expire this Advert?"
                confirmActionText="Yes"
                cancelActionText="No"
                onClose={ () => setExpireAdvertId(null) }
                onContinue={ expireAdvertCallback }
            />
            <CopyAdvertDialog
                open={ copyAdvertId !== null && advertJobId !== null }
                advertId={copyAdvertId}
                advertJobId={advertJobId}
                onClose={ closeCopyDialogHandler }
                errorHandler={ errorHandler }
                loadingHandler={ loadingHandler }
                successHandler={ successHandler }
            />
            {Boolean(gridActions) &&
                <Box pb="10px" ml="auto">
                    {gridActions}
                </Box>
            }
            <GridWithStateWrapper
                gridName={gridName}
                defaultViewModel={defaultHiddenCols}
                rows={rows}
                columns={columns}
                apiRef={apiRef}
                density="compact"
                disableRowSelectionOnClick
                pagination
                pageSizeOptions={[100,250,500,1000]}
            />
        </>
    );
}