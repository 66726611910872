import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { DataGridPremium, GridColDef, GridRenderCellParams } from "@mui/x-data-grid-premium";
import moment from "moment";
import React, { useCallback, useMemo } from "react";
import { CandidateList } from "common/models/Candidates/CandidateList";
import { MergeCandidates } from "services/CandidatesService";
import { JobTracking } from "common/models/Jobs/JobTracking";

interface Props {
    open: boolean,
    candidates: CandidateList[] | JobTracking[],
    phoneFieldOverride?: string,
    closeHandler: () => void,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
    successHandler?: (message: string) => void
}

export default function MergeCandidatesDialog({ open, candidates, phoneFieldOverride, closeHandler, loadingHandler, errorHandler, successHandler }: Props) {

    const columns = useMemo<GridColDef[]>(() => {
        const dateRenderer = (params: GridRenderCellParams) => {
            const m = moment(params.value);
            if (m.isValid() && m.get('year') > 1) {
                return m.format('DD MMM YYYY');
            }
            return 'Never';
        };

        return [
            { field: 'fullName', headerName: 'Name', sortable: false, flex: 1 },
            { field: 'jobTitle', headerName: 'Job Title', sortable: false, width: 150 },
            { field: 'location', headerName: 'Location', sortable: false, width: 250 },
            { field: phoneFieldOverride ?? 'phone', headerName: 'Phone', sortable: false, width: 150 },
            { field: 'lastContactDate', headerName: 'Last Contact', width: 150, renderCell: dateRenderer, sortable: false },
        ];
    }, [phoneFieldOverride]);

    const mergeHandlerCallback = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        let candidateIds = candidates.map(c => c.id);
        const res = await MergeCandidates(candidateIds, errorHandler);
        if (res) {
            successHandler && successHandler('Records have been queued for merging. This process can take upto 24 hours.');
            closeHandler();
        }
        loadingHandler && loadingHandler(false);
    }, [closeHandler, errorHandler, loadingHandler, successHandler, candidates]);

    return (
        <Dialog open={open} maxWidth="lg" fullWidth PaperProps={{ sx: { height: '645px' } }}>
            <DialogTitle>Candidates Merge</DialogTitle>
            <DialogContent sx={{ display: 'flex' }}>
                <div style={{ paddingTop: '10px', display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                    Selected records
                    <DataGridPremium
                        density="compact"
                        disableColumnMenu
                        disableColumnReorder
                        disableColumnResize
                        hideFooter
                        columns={columns}
                        rows={candidates}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="error" onClick={ closeHandler }>Cancel</Button>
                <Button variant="contained" color="success" onClick={ mergeHandlerCallback }>Merge</Button>
            </DialogActions>
        </Dialog>
    );
}