import React, { useCallback, useEffect, useState } from "react";
import { useBlocker, useNavigate } from "react-router-dom";

import UnsavedChangesDialog from "../components/Dialogs/UnsavedChangesDialog";

type AsyncBoolFunction = () => Promise<boolean>;
type VoidFunction = () => void;

interface ResponseType {
    unsavedChangesDialog: JSX.Element,
    hasBlockedRoute: boolean,
}

export default function useUnsavedChangesDialog(changesDetected: boolean, saveHandler: AsyncBoolFunction, discardCallback?: VoidFunction, resetControlString?: string, includeQueryString: boolean = true): ResponseType {
    const [showUnsavedChangesDialog, setShowUnsavedChangesDialog] = useState(false);
    const [navigateToBlockedRoute, setNavigateToBlockedRoute] = useState(false);
    const navigate = useNavigate();
    const blocker = useBlocker(changesDetected && !navigateToBlockedRoute);
    const [blockedRoute, setBlockedRoute] = useState('');

    useEffect(() => {
        if(resetControlString) {
            setNavigateToBlockedRoute(false);
            setShowUnsavedChangesDialog(false);
            setBlockedRoute('');
        }
    }, [resetControlString]);

    const handleSaveChanges = useCallback( async () => {
        setShowUnsavedChangesDialog(false);
        const isValid = await saveHandler();
        if (isValid) {
            setNavigateToBlockedRoute(true);
        }
    }, [saveHandler]);

    const handleDiscardChanges = useCallback( () => {
        discardCallback && discardCallback();
        setShowUnsavedChangesDialog(false);
        setNavigateToBlockedRoute(true);
    }, [discardCallback])

    const handleCancel = () => {
        setShowUnsavedChangesDialog(false);
        setBlockedRoute('');
    }

    useEffect(() => {
        if (navigateToBlockedRoute) navigate(blockedRoute);
    }, [navigateToBlockedRoute, blockedRoute, navigate]);

    useEffect(() => {
        if (blocker && blocker.state === 'blocked') {
            setShowUnsavedChangesDialog(true);
            if (includeQueryString) setBlockedRoute(blocker.location.pathname + blocker.location.search);
            else setBlockedRoute(blocker.location.pathname);
        }
    }, [blocker, includeQueryString]);

    return {
        hasBlockedRoute: Boolean(blockedRoute),
        unsavedChangesDialog: (
            <UnsavedChangesDialog
                open={showUnsavedChangesDialog}
                saveChangesHandler={ handleSaveChanges }
                discardChangesHandler = { handleDiscardChanges }
                cancelHandler={ handleCancel }
            />
        )
    };
}