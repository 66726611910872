import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import DivisionPicker from "components/Pickers/DivisionPicker";
import LocationPicker from "components/Pickers/LocationPicker";
import UserPicker from "components/Pickers/UserPicker";
import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import TagsSearchFilter from "components/Tags/TagsSearchFilter";
import PageContentLayout from "layouts/PageContentLayout";
import PageLayout from "layouts/PageLayout";
import { ClientSearch } from "common/models/Search/ClientSearch";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Location } from "common/models/Common";
import { TagFilter } from "common/models/Configuration/Tags";
import { IsValidNumericValue } from "util/RegExUtils";
import { Division } from "common/models/Configuration/Division";
import { GetMySettings } from "services/UsersService";
import { GetLocationById } from "services/CommonService";
import { GetSavedSearchById } from "services/SavedSearchesService";
import { SaveContactSearch, UpdateContactSavedSearch } from "services/ContactsService";
import ContactsGridComponent from "components/Grids/ContactsGrid";
import Snackbar from "@mui/material/Snackbar";
import Alert from "components/Alert";
import SaveSearchDialog from "components/Dialogs/SaveSearchDialog";
import { NameIdObj } from "common/models/GenericTypes";
import { SxProps } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

const ownerSpecialOptions: NameIdObj[] = [
    { id: -1, name: 'Me' },
    { id: -2, name: 'My Team' },
    { id: 0, name: 'My Company' },
    { id: -3, name: 'Unassigned' },
];

const divisionSpecialOptions: Division[] = [
    { id: 0, automatedRejectionDelay: 0, name: 'All', shortName: 'All', active: true, country: '', countryName: '', timeZone: '', timesheetSystem: 0 }
];

const filterElementStyle: SxProps = { flex: '1 1 0' };

export default function ContactsSearchPage() {
    const [activeTab, setActiveTab] = useState('criteria');
    const [isLoading, setIsLoading] = useState(false);
    const [isGridLoading, setIsGridLoading] = useState(false);
    const [isFetchingSavedSearch, setIsFetchingSavedSearch] = useState(false);
    const [isFetchingSettings, setIsFetchingSettings] = useState(false);
    const [shouldUpdateSavedSearch, setShouldUpdateSavedSearch] = useState(false);
    const [loadedSavedSearchName, setLoadedSavedSearchName] = useState('');
    const [loadedSavedSearchFrequencyId, setLoadedSavedSearchFrequencyId] = useState(0);
    const [ownerId, setOwnerId] = useState(0);
    const [status, setStatus] = useState(0);
    const [division, setDivision] = useState(0);
    const [location, setLocation] = useState<Location | null>(null);
    const [radius, setRadius] = useState('');
    const [taggedBy, setTaggedBy] = useState<'a' | 'm'>('a');
    const [taggedWithin, setTaggedWithin] = useState(999);
    const [searchData, setSearchData] = useState<ClientSearch | undefined>();
    const [showGrid, setShowGrid] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [showSaveSearchDialog, setShowSaveSearchDialog] = useState(false);
    const [savedSearchName, setSavedSearchName] = useState('');
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const [isIncludeTag, setIsIncludeTag] = useState(true);
    const [includeTags, setIncludeTags] = useState<TagFilter[]>([]);
    const [excludeTags, setExcludeTags] = useState<TagFilter[]>([]);
    const [availableTags_All, setAvailableTags_All] = useState<TagFilter[]>([]);
    const [availableTags_Me, setAvailableTags_Me] = useState<TagFilter[]>([]);

    const ssid = useMemo(() => {
        const v = searchParams.get('ssid');
        if (v) return +v;
        return null;
    }, [searchParams]);

    const isRadiusValidNumber = useMemo(() => IsValidNumericValue(radius), [radius]);

    useEffect(() => {
        const getDefaultSettings = async () => {
            setIsFetchingSettings(true);
            const data = await GetMySettings(setErrorMessage);
            if (data) {
                const radiusSetting = data.find(s => s.type === 'Search_Radius');
                if (radiusSetting && radiusSetting.value && IsValidNumericValue(radiusSetting.value)) {
                    setRadius(radiusSetting.value);
                }
                
                const divisionSetting = data.find(s => s.type === 'SearchDivision');
                if (divisionSetting && divisionSetting.value && IsValidNumericValue(divisionSetting.value)) {
                    setDivision(+divisionSetting.value);
                }
                
                const locationIdSetting = data.find(s => s.type === 'Search_LocationHiddenId');
                if (locationIdSetting && locationIdSetting.value) {
                    const l = await GetLocationById(+locationIdSetting.value);
                    if (l) setLocation(l)
                }
            }
            setIsFetchingSettings(false);
        };
        !ssid && getDefaultSettings();
    }, [ssid]);

    useEffect(() => {
        const getSavedSearch = async () => {
            if (ssid) {
                setIsFetchingSavedSearch(true);
                const res = await GetSavedSearchById(ssid, setErrorMessage);
                if (res && res.json) {
                    setSavedSearchName(res.name);
                    const t1 = JSON.parse(res.json);
                    if (t1.owner) setOwnerId(+t1.owner);
                    if (t1.status) setStatus(+t1.status);
                    if (t1.division) setDivision(+t1.division);
                    if (t1.tagsTimeRange) setTaggedWithin(+t1.tagsTimeRange);
                    if (t1.locationId) {
                        const l = await GetLocationById(+t1.locationId);
                        setLocation(l);
                    }
                    if (t1.radius) setRadius(t1.radius.toString());
                    if (t1.tags && t1.tags !== '' && t1.tags !== '[]') {
                        const tags = JSON.parse(t1.tags);
                        let inc: TagFilter[] = [];
                        let exc: TagFilter[] = [];
                        for (let i = 0; i < tags.length; i++) {
                            const {id, name, type} = tags[i];
                            const [tName, tTypeId] = (name as string).split('__');
                            if ((id as string).includes('_EXCLUDE')) exc.push({ source: type === 'm' ? 'm' : 'a', tag: tName, typeId: +tTypeId });
                            else inc.push({ source: type === 'm' ? 'm' : 'a', tag: tName, typeId: +tTypeId });
                        }
                        setIncludeTags(inc);
                        setExcludeTags(exc);
                    }
                    setLoadedSavedSearchName(res.name);
                    setLoadedSavedSearchFrequencyId(res.autoRunFrequency);
                }
                setIsFetchingSavedSearch(false);
            }
        };
        ssid && getSavedSearch();
    }, [ssid]);

    useEffect(() => {
        if(ssid && !isFetchingSavedSearch) {
            setShouldUpdateSavedSearch(true);
        }
    }, [ssid, isFetchingSavedSearch, includeTags, excludeTags, ownerId, status, division, location, radius, taggedWithin]);

    const searchCallback = useCallback(async () => {
        let s: ClientSearch = {
            owner: ownerId,
            status: status,
            division: division,
            taggedTimeRange: taggedWithin
        };

        if (isRadiusValidNumber) s.radius = +radius;

        if (location) {
            s.location = location.geoNameID;
            s.latitude = location.latitude;
            s.longitude = location.longitude;
        }
        if (includeTags.length > 0) s.includeTags = includeTags;
        if (excludeTags.length > 0) s.excludeTags = excludeTags;

        if (shouldUpdateSavedSearch && ssid) {
            const res = await UpdateContactSavedSearch(ssid, loadedSavedSearchName, loadedSavedSearchFrequencyId, s);
            if (res) setShouldUpdateSavedSearch(false);
        }

        setSearchData(s);
        setShowGrid(true);
        setActiveTab('results');
    }, [ownerId, status, division, radius, location, includeTags, excludeTags, taggedWithin, shouldUpdateSavedSearch, ssid, loadedSavedSearchName, loadedSavedSearchFrequencyId, isRadiusValidNumber]);

    const saveSearchCallback = useCallback(async (searchName: string, searchFreq: number) => {
        let s: ClientSearch = {
            owner: ownerId,
            status: status,
            division: division,
            taggedTimeRange: taggedWithin
        };

        if (isRadiusValidNumber) s.radius = +radius;

        if (location) {
            s.location = location.geoNameID;
            s.latitude = location.latitude;
            s.longitude = location.longitude;
        }
        if (includeTags.length > 0) s.includeTags = includeTags;
        if (excludeTags.length > 0) s.excludeTags = excludeTags;

        setIsLoading(true);
        var newSsid = await SaveContactSearch(searchName, searchFreq, s, setErrorMessage);
        if (newSsid) {
            setShowSaveSearchDialog(false);
            navigate(`/contacts?ssid=${newSsid.value}`);
        }
        setIsLoading(false);
    }, [ownerId, status, division, radius, location, includeTags, excludeTags, taggedWithin, isRadiusValidNumber, navigate]);

    const clearCallback = useCallback(() => {
        setOwnerId(0);
        setStatus(0);
        setDivision(0);
        setLocation(null);
        setRadius('');
        setIncludeTags([]);
        setExcludeTags([]);
        setShowGrid(false);
    }, []);

    useEffect(() => {
        if (!ssid) {
            setSavedSearchName('');
            clearCallback();
        }
    }, [clearCallback, ssid]);

    const summaryBar = useMemo(() => {
        const clearAction = <Button variant="contained" color="error" onClick={clearCallback} sx={{ mr: '5px' }}>Clear</Button>;
        const saveAction = <Button variant="contained" onClick={() => setShowSaveSearchDialog(true)} sx={{ mr: '5px' }}>Save</Button>;
        const searchAction = <Button variant="contained" color="success" onClick={searchCallback}>Search</Button>;
        const actions = showGrid && !Boolean(ssid) ? <>{saveAction}{searchAction}</> : <>{clearAction}{searchAction}</>;
        const title = Boolean(savedSearchName) ? `Contacts > Search > ${savedSearchName}` : 'Contacts > Search';
        return <TitleAndActionSummaryBar title={title} browserTabTitle="Search > Contacts"  action={actions} />;
    }, [clearCallback, savedSearchName, searchCallback, showGrid, ssid]);

    const handleRemoveCallback = useCallback((data: TagFilter) => {
        const includeIndex = includeTags.findIndex(t => t.typeId === data.typeId && t.tag === data.tag);

        if (includeIndex !== -1) {
            setIncludeTags(prev => {
                let tmp = [...prev];
                const index = tmp.findIndex(t => t.typeId === data.typeId && t.tag === data.tag);
                if (index !== -1) tmp.splice(index, 1);
                return tmp;
            });
        }
        else {
            setExcludeTags(prev => {
                let tmp = [...prev];
                const index = tmp.findIndex(t => t.typeId === data.typeId && t.tag === data.tag);
                if (index !== -1) tmp.splice(index, 1);
                return tmp;
            });
        }

    }, [includeTags]);

    const handleAddCallback = useCallback((typeId: number, tag: string) => {
        const selectedTag = (taggedBy === 'a' ? availableTags_All : availableTags_Me).find(t => t.typeId === typeId && t.tag === tag);
        if (isIncludeTag) {
            setIncludeTags(prev => {
                const assignedTagIndex = prev.findIndex(t => t.typeId === typeId && t.tag === tag);
                if (assignedTagIndex === -1 && selectedTag) {
                    return [...prev, {...selectedTag, source: taggedBy}]
                }
                return prev;
            });
        }
        else {
            setExcludeTags(prev => {
                const assignedTagIndex = prev.findIndex(t => t.typeId === typeId && t.tag === tag);
                if (assignedTagIndex === -1 && selectedTag) {
                    return [...prev, {...selectedTag, source: taggedBy}]
                }
                return prev;
            });

        }
    }, [isIncludeTag, availableTags_All, availableTags_Me, taggedBy]);

    useEffect(() => setIsLoading(isFetchingSavedSearch || isGridLoading || isFetchingSettings), [isFetchingSavedSearch, isFetchingSettings, isGridLoading]);

    const keyUpHandler = useCallback((e: KeyboardEvent) => {
        if (e.code === 'Enter') searchCallback();
    }, [searchCallback]);

    useEffect(() => {
        window.addEventListener('keyup', keyUpHandler);
        return () => window.removeEventListener('keyup', keyUpHandler);
    }, [keyUpHandler]);

    const radiusChangeHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;
        
        if (val === '') {
            setRadius('');
            return;
        }

        const isValid = IsValidNumericValue(val);
        if (isValid) {
            const numberVal = +val;
            if (numberVal > 0) setRadius(val);
        }
        else if (val.endsWith('.')) {
            const pointsPresent = (val.match(/\./g) || []).length;
            if (pointsPresent === 1) setRadius(val);
        }
    }, []);

    const locationChangeHandler = useCallback((l: Location | null) => {
        setLocation(l);
        if (radius === '') setRadius('50');
    }, [radius]);

    useEffect(() => {
        const item = document.querySelector<HTMLElement>('#contact-search-collapse-root>.MuiCollapse-wrapper>.MuiCollapse-wrapperInner');
        if (item) {
            item.style.display = 'flex';
            item.style.flexDirection = 'column';
        }
    }, []);

    const handleTabChange = useCallback((e: React.SyntheticEvent, newValue: string) => {
        setActiveTab(newValue);
    }, []);
    
    return (
        <PageLayout SummaryBar={summaryBar} paddingTop={0}>
            <Snackbar open={successMessage !== ''} autoHideDuration={3000} onClose={() => setSuccessMessage('')}>
                <Alert onClose={() => setSuccessMessage('')}>Changes Saved</Alert>
            </Snackbar>
            <Snackbar open={errorMessage !== ''} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <SaveSearchDialog open={showSaveSearchDialog} closeHandler={() => setShowSaveSearchDialog(false)} saveHandler={saveSearchCallback} disableFrequencyField />
            <Tabs value={activeTab} onChange={handleTabChange}>
                <Tab value="criteria" label="Criteria" />
                <Tab value="results" label="Results"/>
            </Tabs>
            <PageContentLayout showLoading={isLoading}>
                <Stack spacing={2} paddingTop={2} display={activeTab === 'criteria' ? undefined : 'none'}>
                    <Box display="flex" gap={1}>
                        <UserPicker label="Owner" userId={ownerId} onSelect={ u => u && setOwnerId(u.id) } appendToStart={ownerSpecialOptions} sx={filterElementStyle} />
                        <TextField select label="Status" value={status.toString()} onChange={ ({target}) => setStatus(+target.value) } sx={filterElementStyle}>
                            <MenuItem value="0">All</MenuItem>
                            <MenuItem value="1">Unqualified</MenuItem>
                            <MenuItem value="2">Qualified</MenuItem>
                            <MenuItem value="3">Client</MenuItem>
                            <MenuItem value="4">Supplier</MenuItem>
                            <MenuItem value="7">Supplier - Sub Contractor</MenuItem>
                            <MenuItem value="5">Billing</MenuItem>
                            <MenuItem value="6">Archived</MenuItem>
                            <MenuItem value="8">Referee</MenuItem>
                            <MenuItem value="9">Prospect</MenuItem>
                        </TextField>
                        <DivisionPicker divisionId={division} onSelectCallback={d => d !== null && setDivision(d)} appendToStart={divisionSpecialOptions} sx={filterElementStyle} />
                    </Box>
                    <Box display="flex" gap={1}>
                        <LocationPicker noContainer fullWidth useDefaultCountry location={location} onLocationChange={ locationChangeHandler } innerSx={filterElementStyle} />
                        <TextField
                            label="Radius (Km)"
                            value={radius}
                            sx={filterElementStyle}
                            onChange={ radiusChangeHandler }
                            error={!isRadiusValidNumber && radius !== ''}
                            InputProps={{ endAdornment: <InputAdornment position="end">Km</InputAdornment> }}
                        />
                    </Box>
                    <TagsSearchFilter
                        entityId={2}
                        includeTags={includeTags}
                        excludeTags={excludeTags}
                        tagSource={taggedBy}
                        addHandler={handleAddCallback}
                        removeHandler={handleRemoveCallback}
                        setAvailableTagsHandler={taggedBy === 'a' ? setAvailableTags_All : setAvailableTags_Me}
                        loadingHandler={setIsLoading}
                        errorHandler={setErrorMessage}
                    >
                        <Box>
                            <Box>
                                Exclude
                                <Switch checked={isIncludeTag} onChange={({target}) => setIsIncludeTag(target.checked)} />
                                Include
                            </Box>
                            <TextField select label="Tagged By" value={taggedBy} onChange={ ({target}) => setTaggedBy(target.value === 'm' ? 'm' : 'a') }>
                                <MenuItem value="m">Me</MenuItem>
                                <MenuItem value="a">Everyone</MenuItem>
                            </TextField>
                            <TextField select label="Tagged Within" value={taggedWithin.toString()} onChange={ ({target}) => setTaggedWithin(+target.value) }>
                                <MenuItem value="999">Anytime</MenuItem>
                                <MenuItem value="1">1 Months</MenuItem>
                                <MenuItem value="2">2 Months</MenuItem>
                                <MenuItem value="3">3 Months</MenuItem>
                                <MenuItem value="6">6 Months</MenuItem>
                                <MenuItem value="12">1 Year</MenuItem>
                                <MenuItem value="24">2 Years</MenuItem>
                                <MenuItem value="36">3 Years</MenuItem>
                            </TextField>
                        </Box>
                    </TagsSearchFilter>
                </Stack>
                <Box display={activeTab === 'results' ? 'flex' : 'none'} flexGrow={1} flexDirection="column">
                    <ContactsGridComponent gridName="contact-search" source="search" searchData={searchData} loadingHandler={setIsGridLoading} errorHandler={setErrorMessage} successHandler={setSuccessMessage} />
                </Box>
            </PageContentLayout>
        </PageLayout>
    );
}