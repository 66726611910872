import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React, { useMemo } from "react";

interface Props {
    jobBoardId: number,
    advertId: number,
    attributes?: Record<string, string>,
}

const helpText = "Only letters, numbers and the following symbols are allowed for LinkedIn Social: . ' , ? /";

export default function LinkedInSocialView({ jobBoardId, advertId, attributes }: Props) {
    const atts = useMemo<Record<string, string>>(() => {
        if (attributes) return attributes;
        return {};
    }, [attributes]);

    const socialContents = useMemo(() => atts.SocialContents ?? '', [atts.SocialContents]);
    const friendlyName = useMemo(() => atts.ImageFriendlyName ?? '', [atts.ImageFriendlyName]);
    const imageUrl = useMemo(() => atts.UrlImage ?? '', [atts.UrlImage]);

    return (
        <Box display="flex" gap={2} pt={2} flexDirection="column">
            <Box>
                <Typography fontWeight={600}>LinkedIn Status</Typography>
                <TextField fullWidth value={socialContents} multiline rows={7} helperText={helpText} disabled />
            </Box>
            <Box>
                <Box>
                    { !Boolean(imageUrl) && <Typography variant="caption">No file selected</Typography> }
                    { Boolean(imageUrl) && Boolean(friendlyName) && <a href={imageUrl} target="_blank" rel="noopener noreferrer">{friendlyName}</a> }
                </Box>
                <Typography variant="caption" ml={1.75} color="text.secondary">For best results Image should be 138w x 72h or 520w x 272h</Typography>
            </Box>
        </Box>
    );
}