import { RecentRecordItem } from "../common/models/CustomApplicationContextType";

export const RecentRecordsSettingName = 'RecentRecords';

export function AddRecentRecord(entityId: number, recordId: number, title: string) {
    const stored = localStorage.getItem(RecentRecordsSettingName);
    if (stored) {
        const parsed = JSON.parse(stored) as RecentRecordItem[];
        let newRecords = [...parsed];
        const existingRecordIndex = newRecords.findIndex(r => r.entityId === entityId && r.recordId === recordId);
        if (existingRecordIndex === 0) return;
        if (existingRecordIndex > 0) {
            const tmp = {...parsed[existingRecordIndex]};
            newRecords.splice(existingRecordIndex, 1);
            localStorage.setItem(RecentRecordsSettingName, JSON.stringify([tmp, ...newRecords]));
            return;
        }
        localStorage.setItem(RecentRecordsSettingName, JSON.stringify([{entityId: entityId, recordId: recordId, title: title}, ...newRecords]));
        return;
    }
    localStorage.setItem(RecentRecordsSettingName, JSON.stringify([{entityId: entityId, recordId: recordId, title: title}]));
}