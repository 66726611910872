import { PanelModel } from "@syncfusion/ej2-layouts/src/dashboard-layout/dashboard-layout-model";
import { CandidateRecordDashboardElementDefinition, CandidateRecordDashboardElementType } from "common/models/Dashboard/EditLayout";
import { ScreenLayoutSettings } from "common/models/ScreenLayouts/ScreenLayouts";
import { GetCustomFieldTypeById } from "./CustomFields";

export const DefaultCandidateRecordDashboardElements: CandidateRecordDashboardElementDefinition[] = [
    { id: "CandidateName_Default", row: 0, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'CandidateName' },
    { id: "CandidateStatus_Default", row: 1, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'CandidateStatus' },
    { id: "CandidateAvailable_Default", row: 2, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'CandidateAvailable' },
    { id: "CandidateNotice_Default", row: 3, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'CandidateNotice' },
    { id: "CandidateNationality_Default", row: 4, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'CandidateNationality' },
    { id: "CandidateGender_Default", row: 5, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'CandidateGender' },
    { id: "CandidateDateOfBirth_Default", row: 6, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'CandidateDateOfBirth' },
    { id: "Divider_Default11", row: 7, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'Divider' },
    { id: "CandidateMobile_Default", row: 8, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'CandidateMobile' },
    { id: "CandidatePhone_Default", row: 9, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'CandidatePhone' },
    { id: "CandidateWork_Default", row: 10, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'CandidateWork' },
    { id: "Divider_Default12", row: 11, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'Divider' },
    { id: "CandidateEmail1_Default", row: 12, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'CandidateEmail1' },
    { id: "CandidateEmail2_Default", row: 13, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'CandidateEmail2' },
    { id: "CandidatePayrollEmail_Default", row: 14, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'CandidatePayrollEmail' },
    { id: "CandidateOptOut_Default", row: 15, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'CandidateOptOut' },
    
    { id: "CandidateLocation_Default", row: 0, col: 1, sizeX: 1, sizeY: 7, minSizeX: 1, minSizeY: 7, maxSizeX: 1, maxSizeY: 7, type: 'CandidateLocation' },
    { id: "Divider_Default21", row: 7, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'Divider' },
    { id: "CandidatePreferredJobType_Default", row: 8, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'CandidatePreferredJobType' },
    { id: "CandidatePreferredRate_Default", row: 9, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'CandidatePreferredRate' },
    { id: "CandidatePreferredSalary_Default", row: 10, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'CandidatePreferredSalary' },
    
    { id: "CandidateSummary_Default", row: 0, col: 2, sizeX: 1, sizeY: 7, minSizeX: 1, minSizeY: 3, maxSizeX: 1, maxSizeY: null, type: 'CandidateSummary' },
    { id: "Divider_Default31", row: 7, col: 2, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'Divider' },
    { id: "CandidateLinkedIn_Default", row: 8, col: 2, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'CandidateLinkedIn' },
    { id: "CandidateTwitter_Default", row: 9, col: 2, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'CandidateTwitter' },
];

export const GetMinSizeByElementType = (type: CandidateRecordDashboardElementType) => {
    switch (type) {
        case "CandidateInfo": return [1, 3];
        case "CandidateSummary": return [1, 7];
        default: return [1, 1];
    }
};

export const GetElementTypeById = (id: string): CandidateRecordDashboardElementType => {
    if ( id && id.startsWith('Divider_')) return 'Divider';
    if ( id && id.startsWith('Spacer_')) return 'Spacer';
    if (id && id.startsWith('CandidateInfo_')) return 'CandidateInfo';
    if (id && id.startsWith('CandidateId_')) return 'CandidateId';
    if (id && id.startsWith('CandidateName_')) return 'CandidateName';
    if (id && id.startsWith('CandidateGender_')) return 'CandidateGender';
    if (id && id.startsWith('CandidateAvailable_')) return 'CandidateAvailable';
    if (id && id.startsWith('CandidateNotice_')) return 'CandidateNotice';
    if (id && id.startsWith('CandidateStatus_')) return 'CandidateStatus';
    if (id && id.startsWith('CandidateDateOfBirth_')) return 'CandidateDateOfBirth';
    if (id && id.startsWith('CandidateNationality_')) return 'CandidateNationality';
    if (id && id.startsWith('CandidateDivisions_')) return 'CandidateDivisions';
    if (id && id.startsWith('CandidateEmail1_')) return 'CandidateEmail1';
    if (id && id.startsWith('CandidateEmail2_')) return 'CandidateEmail2';
    if (id && id.startsWith('CandidatePayrollEmail_')) return 'CandidatePayrollEmail';
    if (id && id.startsWith('CandidateWebsite_')) return 'CandidateWebsite';
    if (id && id.startsWith('CandidateLocation_')) return 'CandidateLocation';
    if (id && id.startsWith('CandidateMobile_')) return 'CandidateMobile';
    if (id && id.startsWith('CandidatePhone_')) return 'CandidatePhone';
    if (id && id.startsWith('CandidateWork_')) return 'CandidateWork';
    if (id && id.startsWith('CandidateOptOut_')) return 'CandidateOptOut';
    if (id && id.startsWith('CandidateSummary_')) return 'CandidateSummary';
    if (id && id.startsWith('CandidatePreferredJobType_')) return 'CandidatePreferredJobType';
    if (id && id.startsWith('CandidatePreferredRate_')) return 'CandidatePreferredRate';
    if (id && id.startsWith('CandidatePreferredSalary_')) return 'CandidatePreferredSalary';
    if (id && id.startsWith('CandidateLinkedIn_')) return 'CandidateLinkedIn';
    if (id && id.startsWith('CandidateTwitter_')) return 'CandidateTwitter';
    if ( id && id.startsWith('CustomField')) return GetCustomFieldTypeById(id);
    return '';
};

export const GetPanelDefinitionsFromPanelModels = (panels: PanelModel[]) => {
    let elements: CandidateRecordDashboardElementDefinition[] = [];
    panels.forEach((p, i) => {
        let type = GetElementTypeById(p.id ?? '');
        elements.push({
            id: p.id ?? i.toString(),
            sizeX: p.sizeX ?? 1,
            sizeY: p.sizeY ?? 1,
            col: p.col ?? 0,
            row: p.row ?? 0,
            minSizeX: p.minSizeX ?? 1,
            minSizeY: p.minSizeY ?? 1,
            maxSizeX: p.maxSizeX ?? 1,
            maxSizeY: p.maxSizeY ?? null,
            type: type
        });
    });
    return elements ;
};

export const CandidateScreenLayoutSettings: ScreenLayoutSettings = {
    unitWidth: 600,
    unitHeight: 40,
    gapX: 10,
    gapY: 0,
    columns: 3,
    mediaQueryMaxWidth: '1000px'
};