import Alert from "@mui/material/Alert";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Snackbar from "@mui/material/Snackbar";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers-pro";
import { NameIdObj } from "common/models/GenericTypes";
import MultipleDivisionsPicker from "components/Pickers/MultipleDivisionPicker";
import MultipleGroupPicker from "components/Pickers/MultipleGroupPicker";
import MultipleUserPicker from "components/Pickers/MultipleUserPicker";
import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import PageContentLayout from "layouts/PageContentLayout";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ShareIcon from '@mui/icons-material/Share';
import { SxProps } from "@mui/material/styles";
import { FilterWithTimeRange } from "common/models/Analytics/AnalyticsFilterData";
import EdenRitchieDashboardContent from "components/Analytics/Dashboards/EdenRitchieDashboardLayout";
import PageLayout from "../../../layouts/PageLayout";

const filterElementStyle: SxProps = { flex: '1 1 0' };

export default function EdenRitchieDashboardPage() {
    const [summaryBar, setSummaryBar] = useState(<></>);
    const [isLoading, setIsLoading] = useState(false);
    const [sourceType, setSourceType] = useState(1);
    const [timeRange, setTimeRange] = useState(1);
    const [startDate, setStartDate] = useState<moment.Moment | null>(null);
    const [endDate, setEndDate] = useState<moment.Moment | null>(null);
    const [selectedDivisions, setSelectedDivisions] = useState<number[]>([]);
    const [selectedGroups, setSelectedGroups] = useState<number[]>([]);
    const [selectedUsers, setSelectedUsers] = useState<number[]>([]);
    const [infoMessage, setInfoMessage] = useState('');
    const [shouldRunReport, setShouldRunReport] = useState(false);
    const [isInitialQuerySetupFinished, setIsInitialQuerySetupFinished] = useState(false);
    const [filterData, setFilterData] = useState<FilterWithTimeRange | null>(null);
    const navigate = useNavigate();

    const getDatesFromTimeRange = useCallback((tr: number) => {
        let start: moment.Moment | null = null;
        let end: moment.Moment | null = null;
        if (tr === 0) return;
        if (tr === 1) { // This Week
            start = moment().startOf('isoWeek');
            end = moment().endOf('isoWeek');
        }
        else if (tr === 2) { // This Month
            start = moment().startOf('month');
            end = moment().endOf('month');
        }
        else if (tr === 3) { // This Quarter
            start = moment().startOf('quarter');
            end = moment().endOf('quarter');
        }
        else if (tr === 4) { // This Year
            start = moment().startOf('year');
            end = moment().endOf('year');
        }
        else if (tr === 5) { // Last Week
            start = moment().subtract(1,'week').startOf('isoWeek');
            end = moment().subtract(1,'week').endOf('isoWeek');
        }
        else if (tr === 6) { // Last Month
            start = moment().subtract(1, 'months').startOf('month');
            end = moment().subtract(1, 'months').endOf('month');
        }
        else if (tr === 7) { // Last Quarter
            start = moment().subtract(1, 'quarter').startOf('quarter');
            end = moment().subtract(1, 'quarter').endOf('quarter');
        }
        else if (tr === 8) { // Last Year
            const lastYear = moment().get('year') - 1;
            start = moment(`${lastYear}-01-01`);
            end = moment(`${lastYear}-12-31`);
        }
        else if (tr === 9) { // Last 7 Days
            start = moment().subtract(6, 'days');
            end = moment();
        }
        else if (tr === 10) { // Last 30 Days
            start = moment().subtract(30, 'days');
            end = moment();
        }
        else if (tr === 11) { // Last 90 Days
            start = moment().subtract(90, 'days');
            end = moment();
        }
        else if (tr === 12) { // Last 365 Days
            start = moment().subtract(365, 'days');
            end = moment();
        }
        return [start, end];
    }, []);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const st = urlParams.get('sourceType');
        const sid = urlParams.get('sourceIds');
        const tr = urlParams.get('timeRange');
        const sd = urlParams.get('startDate');
        const ed = urlParams.get('endDate');
        let parsedSt = 1;

        if (st) {
            const p = Number(st);
            if (p >= 1 && p <= 4) {
                parsedSt = p;
                setSourceType(p);
            }
        }
        if (sid) {
            const ids: number[] = sid.split(',').map(n => Number(n));
            if (parsedSt === 2) setSelectedDivisions(ids);
            else if (parsedSt === 3) setSelectedGroups(ids);
            else if (parsedSt === 4) setSelectedUsers(ids);
        }
        if (tr && tr !== '0') {
            const p = Number(tr);
            if (p >=1 && p <= 12) {
                const dates = getDatesFromTimeRange(p);
                if (dates) {
                    const [start, end] = dates;
                    setTimeRange(p);
                    setStartDate(start);
                    setEndDate(end);
                }
            }
        }
        else {
            if (sd) {
                const m = moment(sd);
                if (m.isValid()) setStartDate(m);
            }
            if (ed) {
                const m = moment(ed);
                if (m.isValid()) setEndDate(m);
            }
            if (ed || sd) setTimeRange(0);
        }
        if (st || sid || sd || ed || tr) setShouldRunReport(true);
        setIsInitialQuerySetupFinished(true);
    }, [getDatesFromTimeRange]);

    const generatQueryString = useCallback(() => {
        const sd = timeRange === 0 && startDate ? startDate.format('YYYY-MM-DD') : '';
        const ed = timeRange === 0 && endDate ? endDate.format('YYYY-MM-DD') : '';
        if (sourceType === 1) return timeRange === 0 ? `?sourceType=${sourceType}&startDate=${sd}&endDate=${ed}` : `?sourceType=${sourceType}&timeRange=${timeRange}`;
        else {
            let sourceIds = '';
            if (sourceType === 2) sourceIds = selectedDivisions.join(',');
            else if (sourceType === 3) sourceIds = selectedGroups.join(',');
            else if (sourceType === 4) sourceIds = selectedUsers.join(',');
            return timeRange === 0 ? `?sourceType=${sourceType}&startDate=${sd}&endDate=${ed}&sourceIds=${encodeURIComponent(sourceIds)}` : `?sourceType=${sourceType}&timeRange=${timeRange}&sourceIds=${encodeURIComponent(sourceIds)}`;
        }
    }, [endDate, selectedDivisions, selectedGroups, selectedUsers, sourceType, startDate, timeRange]);

    const shareActionCallback = useCallback(() => {
        let url = window.location.protocol + '//' + window.location.host + window.location.pathname + generatQueryString();
        navigator.clipboard.writeText(url);
        setInfoMessage('Link copied to clipboard');
    }, [generatQueryString]);

    useEffect(() => {
        if (isInitialQuerySetupFinished) {
            let url = window.location.pathname + generatQueryString();
            navigate(url);
        }
    }, [generatQueryString, isInitialQuerySetupFinished, navigate]);

    useEffect(() => {
        const action = (
            <Avatar
                key="splitsAction"
                onClick={shareActionCallback}
                title="Copy link to clipboard"
                sx={{ bgcolor: t => t.palette.primary.main, mr: '5px', cursor: 'pointer' }}
            >
                <ShareIcon />
            </Avatar>
        );
        const summaryBar = <TitleAndActionSummaryBar title="Analytics > Dashboards > Eden Ritchie Dashboard" browserTabTitle="Eden Ritchie Dashboard > Dashboards > Analytics" action={action} />
        setSummaryBar && setSummaryBar(summaryBar);
    }, [shareActionCallback]);
    
    useEffect(() => {
        if (isInitialQuerySetupFinished) {
            const dates = getDatesFromTimeRange(timeRange);
            if (dates) {
                const [start, end] = dates;
                setStartDate(start);
                setEndDate(end);
            }
        }
    }, [getDatesFromTimeRange, isInitialQuerySetupFinished, timeRange]);

    const getDataCallback = useCallback(async () => {
        let sourceIds = '';
        if (sourceType === 2) sourceIds = selectedDivisions.join(',');
        else if (sourceType === 3) sourceIds = selectedGroups.join(',');
        else if (sourceType === 4) sourceIds = selectedUsers.join(',');

        const f: FilterWithTimeRange = {
            endDate: endDate ? endDate.format('YYYY-MM-DD') : '',
            startDate: startDate ? startDate.format('YYYY-MM-DD') : '',
            userFilterID: sourceIds,
            userFilterTypeID: sourceType
        };

        setFilterData(f);

    }, [endDate, selectedDivisions, selectedGroups, selectedUsers, sourceType, startDate]);

    useEffect(() => {
        if (shouldRunReport) {
            getDataCallback();
            setShouldRunReport(false);
        }
    }, [getDataCallback, shouldRunReport]);

    const onTimeRangeChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const v = +e.target.value;
        setTimeRange(v);
    }, []);

    const onSourceTypeChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const v = +e.target.value;
        setSourceType(v);
    }, []);

    const onDivisionsChange = useCallback((divisions: NameIdObj[]) => {
        setSelectedDivisions(divisions.map(u => u.id));
    }, []);

    const onGroupsChange = useCallback((groups: NameIdObj[]) => {
        setSelectedGroups(groups.map(u => u.id));
    }, []);

    const onUsersChange = useCallback((users: NameIdObj[]) => {
        setSelectedUsers(users.map(u => u.id));
    }, []);

    return (
        <PageLayout SummaryBar={summaryBar}>
            <PageContentLayout showLoading={isLoading}>
                <Snackbar open={Boolean(infoMessage)} autoHideDuration={1500} onClose={() => setInfoMessage('')}>
                    <Alert severity="info" onClose={() => setInfoMessage('')}>{infoMessage}</Alert>
                </Snackbar>
                <Box display="flex" gap={1}>
                    <TextField select label="Source Type" value={sourceType.toString()} onChange={onSourceTypeChange} sx={filterElementStyle} >
                        <MenuItem value="1">Company</MenuItem>
                        <MenuItem value="2">Division</MenuItem>
                        <MenuItem value="3">Group</MenuItem>
                        <MenuItem value="4">User</MenuItem>
                    </TextField>
                    <TextField select label="Time Range" value={timeRange.toString()} onChange={onTimeRangeChange} sx={filterElementStyle} >
                        <MenuItem value="0">Custom Range</MenuItem>
                        <MenuItem value="1">This Week</MenuItem>
                        <MenuItem value="2">This Month</MenuItem>
                        <MenuItem value="3">This Quarter</MenuItem>
                        <MenuItem value="4">This Year</MenuItem>
                        <MenuItem value="5">Last Week</MenuItem>
                        <MenuItem value="6">Last Month</MenuItem>
                        <MenuItem value="7">Last Quarter</MenuItem>
                        <MenuItem value="8">Last Year</MenuItem>
                        <MenuItem value="9">Last 7 Days</MenuItem>
                        <MenuItem value="10">Last 30 Days</MenuItem>
                        <MenuItem value="11">Last 90 Days</MenuItem>
                        <MenuItem value="12">Last 365 Days</MenuItem>
                    </TextField>
                    <DatePicker label="From Date" value={startDate} onChange={m => setStartDate(m)} disabled={timeRange !== 0} sx={filterElementStyle} slotProps={{actionBar: { actions: ["clear", "today", "cancel", "accept"] }}} />
                    <DatePicker label="To Date" value={endDate} onChange={m => setEndDate(m)} disabled={timeRange !== 0} sx={filterElementStyle} slotProps={{actionBar: { actions: ["clear", "today", "cancel", "accept"] }}} />
                    <Button variant="contained" color="success" onClick={getDataCallback}>Run Report</Button>
                </Box>
                <Box display={sourceType === 2 ? undefined : 'none'} mt={2}>
                    <MultipleDivisionsPicker value={selectedDivisions} onSelect={onDivisionsChange} />
                </Box>
                <Box display={sourceType === 3 ? undefined : 'none'} mt={2}>
                    <MultipleGroupPicker value={selectedGroups} onSelect={onGroupsChange} />
                </Box>
                <Box display={sourceType === 4 ? undefined : 'none'} mt={2}>
                    <MultipleUserPicker value={selectedUsers} onSelect={onUsersChange} />
                </Box>
                { filterData && <EdenRitchieDashboardContent filterData={filterData} loadingHandler={setIsLoading} /> }
            </PageContentLayout>
        </PageLayout>
    );
}