import React from "react";
import UserSignaturePageContent from "components/Content/UserSignaturePageContent";

interface Props {
    setSummaryBar?: (summaryBar: JSX.Element) => void
}

export default function AccountSignaturePage({ setSummaryBar }: Props) {
    return (
        <UserSignaturePageContent
            summaryBarTitle="Account > Signature"
            summaryBarTabTitle="Signature > Account"
            setSummaryBar={setSummaryBar}
        />
    );
}