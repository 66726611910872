import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import ControlCameraIcon from '@mui/icons-material/ControlCamera';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Draggable from "react-draggable";
import { PreviewProps } from "common/models/Previews/Previews";
import { PREVIEW_CLIENT_RENDERER, PREVIEW_HEIGHT, PREVIEW_TEXT_SKELETON, PREVIEW_TITLE_STYLE, PREVIEW_WIDTH } from "util/Definitions/Constants/Previews";
import Divider from "@mui/material/Divider";
import { DownloadCandidateResume, GetCandidateById, GetCandidateWorkHistory } from "services/CandidatesService";
import { Candidate, CandidateWorkHistory } from "common/models/Candidates/Candidate";
import Chip from "@mui/material/Chip";
import { Division } from "common/models/Configuration/Division";
import { DashboardLayoutComponent, PanelModel } from "@syncfusion/ej2-react-layouts";
import { CustomField } from "common/models/Configuration/CustomFields";
import { CandidateQuickViewLayoutSettings, DefaultCandidateQuickViewLayoutElements, GetPanelDefinitionsFromPanelModels } from "util/Definitions/QuickViewLayout/Candidate";
import { useTheme } from "@mui/material/styles";
import { GetCustomFieldsByEntity_OnlyActive } from "services/CustomFieldsService";
import { CandidateRecordDashboardElementDefinition, CandidateRecordDashboardElementType } from "common/models/Dashboard/EditLayout";
import { CustomFieldSettingsMap, CustomFieldType } from "common/models/ScreenLayouts/CustomFields";
import RecordAvatarComponent from "components/Dashboards/Graphs/ClientAvatarComponent";
import PanelWrapper from "components/Dashboards/PanelWrapper";
import ViewCandidateLocationElement from "components/ScreenLayouts/Candidates/ViewCandidateLocationElement";
import MultiLineTextElement from "components/ScreenLayouts/MultiLineTextElement";
import SingleFieldElement from "components/ScreenLayouts/SingleFieldElement";
import { GetCustomerSettingBySettingName } from "services/ConfigurationService";
import { GetDivisions } from "services/DivisionsService";
import { NationalityList } from "util/Definitions/Nationality";
import { UdfCandidateFieldMapObj } from "util/Definitions/ScreenLayouts/CustomFields";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import CandidateWorkHistorySummaryElement from "components/ScreenLayouts/Candidates/CandidateWorkHistorySummary";

const { unitWidth, unitHeight, gapX, gapY, columns } = CandidateQuickViewLayoutSettings;
const cellSpacing = [gapX, gapY];

const formatNumber = (value: number) => {
    return value.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 });
};

const getCandidateGenderName = (genderId: number) => {
    switch (genderId) {
        case 0: return 'Not stated or inadequately described';
        case 1: return 'Male';
        case 2: return 'Female';
        case 3: return 'Other';
        default: return '';
    }
};

const getCandidateJobSeekingStatusName = (statusId: number) => {
    switch (statusId) {
        case 1: return 'Seriously Looking';
        case 2: return 'Open To Opportunities';
        case 3: return 'Not Currently Looking';
        default: return '';
    }
};

export default function CandidateQuickViewComponent({ recordId, isSourceHovered, hideCallback, hideDelayMs = 1250 }: PreviewProps) {
    const [shouldClose, setShouldClose] = useState(false);
    const [isFetchingData, setIsFetchingData] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [candidate, setCandidate] = useState<Candidate>();
    const [workHistory, setWorkHistory] = useState<CandidateWorkHistory[]>([]);

    const [isLoading, setIsLoading] = useState(false);
    const [divisions, setDivisions] = useState<Division[]>([]);
    const [screenResizedControl, setScreenResizedControl] = useState(false);
    const [fetchingSavedState, setFetchingSavedState] = useState(false);
    const [fetchedSavedState, setFetchedSavedState] = useState(false);
    const [activeFields, setActiveFields] = useState<CustomField[]>([]);
    const [lastSavedState, setLastSavedState] = useState<PanelModel[] | null>(null);
    const [isFetchingConfig, setIsFetchingConfig] = useState(false);
    const theme = useTheme();

    useEffect(() => {
        const getData = async () => {
            setIsFetchingData(true);
            const res = await GetCandidateById(recordId);
            const res2 = await GetCandidateWorkHistory(recordId, 2);
            if (res) setCandidate(res);
            if (res2) setWorkHistory(res2);
            setIsFetchingData(false);
        };
        getData();
    }, [recordId]);

    useEffect(() => {
        if (shouldClose) {
            const hidePreviewTimeout = setTimeout(() => {
                hideCallback();
            }, hideDelayMs);
            return () => clearTimeout(hidePreviewTimeout);
        }
    }, [shouldClose, hideDelayMs, hideCallback]);

    useEffect(() => {
        if (isSourceHovered || isHovered) setShouldClose(false);
        else setShouldClose(true);
    }, [isSourceHovered, isHovered]);

    const title = useMemo(() => {
        if(!candidate) return "";
        if(candidate.profileStatusID === -1) return candidate.fullName + ' - ARCHIVED - DO NOT CONTACT';
        if(candidate.candidateClientID !== 0) return <>{candidate.fullName} ( EMPLOYEE at {PREVIEW_CLIENT_RENDERER(candidate.candidateClientID, candidate.candidateClientName)} )</>;
        const cptid = candidate.candidatePlacementTypeID;
        if(cptid >= 1 && cptid <= 4) {
            const placementType = cptid === 1 ? 'PERMANENT' : 'CURRENT CONTRACTOR';
            return <>{candidate.fullName} ( {placementType} at {PREVIEW_CLIENT_RENDERER(candidate.candidatePlacementClientID, candidate.candidatePlacementClientName)} )</>
        }
        return candidate.fullName;
    }, [candidate]);

    const downloadCV = useCallback(async (candidateId: number) => {
        await DownloadCandidateResume(candidateId);
    }, []);

    useEffect(() => {
        const windowResizeHandler = () => setScreenResizedControl(prev => !prev);
        window.addEventListener('resize', windowResizeHandler);
        return () => window.removeEventListener('resize', windowResizeHandler);
    }, []);

    useEffect(() => {
        const getActiveFields = async () => {
            setIsFetchingConfig(true);
            const customFields = await GetCustomFieldsByEntity_OnlyActive(3);
            if (customFields) setActiveFields(customFields);
            setIsFetchingConfig(false);
        };
        getActiveFields();
    }, []);

    useEffect(() => {
        const getDivs = async () => {
            const res = await GetDivisions();
            if (res) setDivisions(res);
        };
        getDivs();
    }, []);

    const divisionNames = useMemo<string[]>(() => {
        if (candidate) {
            let names: string[] = [];
            const ids = candidate.divisions.split(';');
            ids.forEach(d => {
                const item = divisions.find(div => div.id === +d);
                if (item) names.push(item.name);
            });
            return names;
        }
        return [];
    }, [candidate, divisions]);

    const customFieldsSettingsMap = useMemo<CustomFieldSettingsMap>(() => {
        let obj: CustomFieldSettingsMap = {};
        activeFields.forEach(u => {
            const customFieldNumber = u.name.substring(13);
            const key = 'CustomField' + customFieldNumber as CustomFieldType;
            obj[key] = {
                title: u.agencyName,
                isMultiLine: u.multiLine && !u.usePredefinedValues,
            }
        });
        return obj;
    }, [activeFields]);

    useEffect(() => {
        setIsLoading(isFetchingConfig || fetchingSavedState || isFetchingData);
    }, [fetchingSavedState, isFetchingConfig, isFetchingData]);

    useEffect(() => {
        const getSavedState = async () => {
            setFetchingSavedState(true);
            const elementsJson = await GetCustomerSettingBySettingName('CandidateQuickViewLayoutElements');
            if (elementsJson) {
                const panels = JSON.parse(elementsJson) as PanelModel[];
                setLastSavedState(panels);
            }
            setFetchingSavedState(false);
            setFetchedSavedState(true);
        };
        getSavedState();
    }, []);

    const elements = useMemo<CandidateRecordDashboardElementDefinition[]>(() => {
        if (!fetchedSavedState) return [];
        if (lastSavedState !== null) {
            const elements = GetPanelDefinitionsFromPanelModels(lastSavedState);
            return elements ;
        }
        return DefaultCandidateQuickViewLayoutElements;
    }, [lastSavedState, fetchedSavedState]);

    const candidateNationality = useMemo(() => {
        if (candidate && candidate.nationalityID) {
            const nat = NationalityList.find(n => n.id === candidate.nationalityID);
            if (nat) return nat.name;
        }
        return '';
    }, [candidate]);

    const renderElement = useCallback((id: string, type: CandidateRecordDashboardElementType) => {
        if (candidate) {
            switch (type) {
                case 'Divider': return <Box pt="20px"><Divider component="div" /></Box>;
                case 'Spacer': return <></>;
                case 'CandidateInfo': return (
                    <RecordAvatarComponent
                        entityId={3}
                        primaryText={candidate.fullName}
                        secondaryText={""}
                        photoUrl={candidate.photoUrl}
                    />);
                case 'CandidateId': return <SingleFieldElement useEllipsisForLongValues fieldTitle="ID" fieldValue={candidate.id.toString()} />
                case 'CandidateName': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Name" fieldValue={candidate.fullName} />
                case 'CandidateGender': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Gender" fieldValue={getCandidateGenderName(candidate.genderID)} />
                case 'CandidateAvailable': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Available" fieldValue={candidate.availabilityDate} format="date" />
                case 'CandidateNotice': 
                    const weeks = candidate.weeksNotice ? candidate.weeksNotice : 0;
                    return <SingleFieldElement useEllipsisForLongValues fieldTitle="Notice" fieldValue={weeks > 0 ? `${weeks} week(s)` : ''} />
                case 'CandidateStatus': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Status" fieldValue={getCandidateJobSeekingStatusName(candidate.jobSeekingStatusID)} />
                case 'CandidateDateOfBirth': return <SingleFieldElement useEllipsisForLongValues fieldTitle="DOB" fieldValue={candidate.dateOfBirth} format="date" />
                case 'CandidateNationality': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Nationality" fieldValue={candidateNationality} />
                case 'CandidateDivisions': return (
                    <SingleFieldElement fieldTitle="Divisions" format="custom">
                        {divisionNames.map(d => (
                            <Chip key={d} size="small" label={d} sx={{ mr: '5px' }} />
                        ))}
                    </SingleFieldElement>
                );
                case 'CandidateEmail1': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Email 1" fieldValue={candidate.email1} format="email-link" href={candidate.email1} />
                case 'CandidateEmail2': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Email 2" fieldValue={candidate.email2} format="email-link" href={candidate.email2} />
                case 'CandidatePayrollEmail': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Payroll Email" fieldValue={candidate.payrollEmail} format="email-link" href={candidate.payrollEmail} />
                case 'CandidateWebsite': 
                    const website = candidate.website;
                    const href = website && !website.startsWith('http') ? 'https://' + website : website;
                    return <SingleFieldElement useEllipsisForLongValues fieldTitle='Website' href={href} fieldValue={website} format="external-link" />;
                case 'CandidateLocation': return (
                    <ViewCandidateLocationElement
                        address1={candidate.address1 ?? ''}
                        address2={candidate.address2 ?? ''}
                        countryName={candidate.countryName}
                        postcode={candidate.postcode ?? ''}
                        state={candidate.state ?? ''}
                        suburb={candidate.suburb ?? ''}
                    />
                );
                case 'CandidateMobile': 
                    return <SingleFieldElement useEllipsisForLongValues fieldTitle="Mobile" fieldValue={candidate.mobile} href={candidate.mobile_Standardised} format="phone-link" />;
                case 'CandidatePhone': 
                    return <SingleFieldElement useEllipsisForLongValues fieldTitle="Phone" fieldValue={candidate.home} href={candidate.home_Standardised} format="phone-link" />;
                case 'CandidateWork': 
                    return <SingleFieldElement useEllipsisForLongValues fieldTitle="Work" fieldValue={candidate.work} href={candidate.work_Standardised} format="phone-link" />;
                case 'CandidateOptOut': return <SingleFieldElement useEllipsisForLongValues fieldTitle="OptOut" fieldValue={candidate.optOut ? 'Yes' : 'No'} />
                case 'CandidateSummary': return <MultiLineTextElement title="Summary" content={candidate.summary} />;
                case 'CandidateSummaryFullWidth': return <MultiLineTextElement title="Summary" content={candidate.summary} dividerTitle />;
                case 'CandidatePreferredJobType': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Preferred Job Type" fieldValue={candidate.preferredJobTypeName} />
                case 'CandidatePreferredRate': 
                    const numericRate = candidate.preferredRate;
                    const rateUnits = candidate.preferredRateUnits === 'Unrecognised' || numericRate === 0 ? '' : candidate.preferredRateUnits;
                    const preferredRate = numericRate > 0 ? formatNumber(numericRate) : '';
                    return <SingleFieldElement useEllipsisForLongValues fieldTitle="Preferred Rate" fieldValue={`${preferredRate} ${rateUnits}`} />
                case 'CandidatePreferredSalary':
                    const numericSalary = candidate.preferredSalary;
                    const salaryUnits = candidate.preferredSalaryUnits === 'Unrecognised' || numericSalary === 0 ? '' : candidate.preferredSalaryUnits;
                    const preferredSalary = numericSalary > 0 ? formatNumber(numericSalary) : '';
                    return <SingleFieldElement useEllipsisForLongValues fieldTitle="Preferred Salary" fieldValue={`${preferredSalary} ${salaryUnits}`} />
                case 'CandidateLinkedIn': 
                    const linkedIn = candidate.linkedInUrl;
                    const lhref = linkedIn && !linkedIn.startsWith('http') ? 'https://' + linkedIn : linkedIn;
                    return <SingleFieldElement useEllipsisForLongValues fieldTitle='LinkedIn' href={lhref} fieldValue={linkedIn} format="external-link" />;
                case 'CandidateTwitter': 
                    const twitter = candidate.twitterUrl;
                    const thref = twitter && !twitter.startsWith('http') ? 'https://' + twitter : twitter;
                    return <SingleFieldElement useEllipsisForLongValues fieldTitle='Twitter' href={thref} fieldValue={twitter} format="external-link" />;
                case 'CandidateWorkHistorySummary': return <CandidateWorkHistorySummaryElement data={workHistory} />;
                default: 
                    if (type.startsWith('CustomField')) {
                        const settings = customFieldsSettingsMap[type];
                        if (settings) {
                            const title = settings.title;
                            const { field, format } = UdfCandidateFieldMapObj[type as CustomFieldType];
                            const udfValue = candidate[field];
                            if (format === 'string' || format === 'date' || format === 'datetime') {
                                return <SingleFieldElement isMultiLine={settings.isMultiLine} format={format} fieldTitle={title} fieldValue={udfValue ? udfValue.toString() : null} />;
                            }
                            if (format === 'number') {
                                const numValue = udfValue === undefined || udfValue === null ? '' : udfValue.toString();
                                return <SingleFieldElement fieldTitle={title} fieldValue={numValue} />;
                            }
                        }
                        else return <SingleFieldElement fieldTitle={type} fieldValue="[ Disabled Custom Field ]" />
                    }
                    return <div>{id}</div>;
            }
        }
    }, [candidate, candidateNationality, divisionNames, workHistory, customFieldsSettingsMap]);

    const layoutResizeStopHandler = useCallback(() => {
        setScreenResizedControl(prev => !prev);
    }, []);

    const renderLayout = useCallback(() => {
        if (!fetchedSavedState) return <></>;
        return (
            <DashboardLayoutComponent
                cellSpacing={cellSpacing}
                columns={columns}
                cellAspectRatio={12 / 2}
                resizeStop={ layoutResizeStopHandler }
                allowDragging={false}
                allowResizing={false}
            >
                {elements.map(e => (
                    <PanelWrapper
                        key={e.id}
                        id={e.id}
                        col={e.col}
                        row={e.row}
                        sizeX={e.sizeX}
                        sizeY={e.sizeY}
                        minSizeX={e.minSizeX}
                        minSizeY={e.minSizeY}
                        maxSizeX={e.maxSizeX}
                        maxSizeY={e.maxSizeY}
                        resizeControl={screenResizedControl}
                        resizeIconColor={theme.palette.text.disabled}
                        gapX={gapX}
                        gapY={gapY}
                        unitWidth={unitWidth}
                        unitHeight={unitHeight}
                    >
                        {renderElement(e.id, e.type)}
                    </PanelWrapper>
                ))}
            </DashboardLayoutComponent>
        );
    }, [elements, fetchedSavedState, theme.palette.text.disabled, screenResizedControl, layoutResizeStopHandler, renderElement]);

    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
            defaultPosition={{ x: (window.innerWidth / 2) - (PREVIEW_WIDTH / 2), y: (window.innerHeight / 2) - (PREVIEW_HEIGHT / 2) }}
        >
            <Dialog
                open={true}
                maxWidth={false}
                PaperProps={{
                    onMouseEnter: () => setIsHovered(true),
                    onMouseLeave: () => setIsHovered(false),
                    sx: { height: `${PREVIEW_HEIGHT}px`, width: `${PREVIEW_WIDTH}px` }
                }}
                hideBackdrop
                sx={{ bottom: 'unset', right: 'unset', left: 'unset' }}
            >
                <DialogTitle component="div" display="flex" sx={ PREVIEW_TITLE_STYLE }>
                    <div style={{ alignSelf: 'center' }}>
                        { isFetchingData || !candidate ? PREVIEW_TEXT_SKELETON : title }
                    </div>
                    <div style={{ marginLeft: 'auto' }}>
                        {candidate && candidate.hasResume && <IconButton title="Download CV" onClick={() => downloadCV(recordId)} sx={{ color: t => t.palette.primary.contrastText }}><FileDownloadIcon /></IconButton>}
                        <IconButton id="draggable-dialog-title" sx={{ cursor: 'move', color: t => t.palette.primary.contrastText }}><ControlCameraIcon /></IconButton>
                        <IconButton onClick={ () => hideCallback() } sx={{ color: t => t.palette.primary.contrastText }}><CloseIcon /></IconButton>
                    </div>
                </DialogTitle>
                <DialogContent sx={{ p: 0 }}>
                    {isLoading &&
                        <Box display="flex" justifyContent="center" alignItems="center" height="100%" >
                            <CircularProgress />
                        </Box>
                    }
                    {!isLoading &&
                        <Box p="10px" height="100%">
                            <div className="control-section">
                                { renderLayout() }
                            </div>
                        </Box>
                    }
                </DialogContent>
            </Dialog>
        </Draggable>
    );
}