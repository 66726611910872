import React, { useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import TagSearchPageContent from "components/Content/TagSearchPageContent";
import PlacementsGridComponent from "components/Grids/PlacementsGrid";
import OnlyTitleSummaryBar from "components/SummaryBars/OnlyTitleSummaryBar";
import PageContentLayout from "layouts/PageContentLayout";
import PageLayout from "layouts/PageLayout";
import Alert from "components/Alert";
import Snackbar from "@mui/material/Snackbar";

export default function PlacementTagsPage() {
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [searchParams] = useSearchParams();

    const tagTypeId = useMemo(() => {
        const val = searchParams.get('tagType');
        if (val) return +val;
        return 0;
    }, [searchParams]);

    const taggedBy = useMemo(() => {
        const val = searchParams.get('taggedBy');
        if (val) return +val;
        return 0;
    }, [searchParams]);

    const tag = useMemo(() => {
        const val = searchParams.get('tag');
        if (val) return val;
        return '';
    }, [searchParams]);

    const showClientsByTag = useMemo(() => {
        return tagTypeId !== 0 && tag !== '';
    }, [tagTypeId, tag]);

    const summaryBar = useMemo(() => {
        if (showClientsByTag) return <OnlyTitleSummaryBar title={`Placements tagged with "${tag}"`} browserTabTitle="Tags > Placements" />
        return <OnlyTitleSummaryBar title="Placements > Tags" browserTabTitle="Tags > Placements" />;
    }, [tag, showClientsByTag]);
    
    return (
        <PageLayout SummaryBar={summaryBar}>
            <Snackbar open={successMessage !== ''} autoHideDuration={3000} onClose={() => setSuccessMessage('')}>
                <Alert onClose={() => setSuccessMessage('')}>{ successMessage }</Alert>
            </Snackbar>
            <Snackbar open={errorMessage !== ''} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <PageContentLayout showLoading={isLoading}>
                {!showClientsByTag && <TagSearchPageContent entityType="placements" loadingHandler={setIsLoading} errorHandler={setErrorMessage} />}
                {showClientsByTag && <PlacementsGridComponent gridName="placement-tags" source="tags" tagLabel={tag} tagTypeId={tagTypeId} taggedBy={taggedBy} loadingHandler={setIsLoading} successHandler={setSuccessMessage} errorHandler={setErrorMessage} />}
            </PageContentLayout>
        </PageLayout>
    );
}