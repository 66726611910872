import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import ControlCameraIcon from '@mui/icons-material/ControlCamera';
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Draggable from "react-draggable";
import { Opportunity, OpportunityOwner } from "common/models/Opportunities";
import { PreviewProps } from "common/models/Previews/Previews";
import { GetOpportunityById, GetOpportunityOwners } from "services/OpportunitiesService";
import { PREVIEW_HEIGHT, PREVIEW_TEXT_SKELETON, PREVIEW_TITLE_STYLE, PREVIEW_WIDTH } from "util/Definitions/Constants/Previews";
import Divider from "@mui/material/Divider";
import { GetCurrencies } from "services/CommonService";
import { Currency } from "common/models/Common";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { CustomField } from "common/models/Configuration/CustomFields";
import { DashboardLayoutComponent, PanelModel } from "@syncfusion/ej2-react-layouts";
import { DefaultOpportunityQuickViewLayoutElements_BackFill, DefaultOpportunityQuickViewLayoutElements_Bid, DefaultOpportunityQuickViewLayoutElements_Float, DefaultOpportunityQuickViewLayoutElements_General, DefaultOpportunityQuickViewLayoutElements_Lead, DefaultOpportunityQuickViewLayoutElements_Tender, GetPanelDefinitionsFromPanelModels, OpportunityQuickViewLayoutSettings } from "util/Definitions/QuickViewLayout/Opportunity";
import PanelWrapper from "components/Dashboards/PanelWrapper";
import SingleFieldElement from "components/ScreenLayouts/SingleFieldElement";
import Chip from "@mui/material/Chip";
import MultiLineTextElement from "components/ScreenLayouts/MultiLineTextElement";
import { UdfOpportunityFieldMapObj } from "util/Definitions/ScreenLayouts/CustomFields";
import { CustomFieldSettingsMap, CustomFieldType } from "common/models/ScreenLayouts/CustomFields";
import { OpportunityRecordDashboardElementDefinition, OpportunityRecordDashboardElementType } from "common/models/Dashboard/EditLayout";
import { GetCustomerSettingBySettingName } from "services/ConfigurationService";
import { Division } from "common/models/Configuration/Division";
import { GetCustomFieldsByEntity_OnlyActive } from "services/CustomFieldsService";
import { GetDivisions } from "services/DivisionsService";
import useTheme from "@mui/material/styles/useTheme";

const { unitWidth, unitHeight, gapX, gapY, columns } = OpportunityQuickViewLayoutSettings;
const cellSpacing = [gapX, gapY];

interface Props2 {
    currencies: Currency[],
    activeFields: CustomField[],
    owners: OpportunityOwner[],
    divisions: Division[],
    opportunity?: Opportunity,
    setFetchingSavedState: (v: boolean) => void
}

export default function OpportunityQuickViewComponent({ recordId, isSourceHovered, hideCallback, hideDelayMs = 1250 }: PreviewProps) {
    const [shouldClose, setShouldClose] = useState(false);
    const [isFetchingData, setIsFetchingData] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [opportunity, setOpportunity] = useState<Opportunity>();
    const [currencies, setCurrencies] = useState<Currency[]>([]);
    const [opportunityTypeSwitch, setOpportunityTypeSwitch] = useState(false);
    const [opportunityType, setOpportunityType] = useState(0);
    const [fetchingSavedState, setFetchingSavedState] = useState(false);
    const [activeFields, setActiveFields] = useState<CustomField[]>([]);
    const [owners, setOwners] = useState<OpportunityOwner[]>([]);
    const [divisions, setDivisions] = useState<Division[]>([]);

    useEffect(() => {
        if (shouldClose) {
            const hidePreviewTimeout = setTimeout(() => {
                hideCallback();
            }, hideDelayMs);
            return () => clearTimeout(hidePreviewTimeout);
        }
    }, [shouldClose, hideDelayMs, hideCallback]);

    useEffect(() => {
        if (isSourceHovered || isHovered) setShouldClose(false);
        else setShouldClose(true);
    }, [isSourceHovered, isHovered]);

    useEffect(() => {
        if (opportunityType) {}
        setOpportunityTypeSwitch(prev => !prev);
    }, [opportunityType]);

    useEffect(() => {
        const getData = async () => {
            setIsFetchingData(true);
            const cur = await GetCurrencies();
            if (cur) setCurrencies(cur);
            const res = await GetOpportunityById(recordId);
            if (res) {
                setOpportunity(res);
                setOpportunityType(res.type);
            }
            setIsFetchingData(false);
        };
        getData();
    }, [recordId]);

    useEffect(() => {
        const getActiveFields = async () => {
            const res = await GetCustomFieldsByEntity_OnlyActive(8);
            if (res) setActiveFields(res);
        };
        getActiveFields();
    }, []);

    useEffect(() => {
        const getOwners = async () => {
            if (opportunity) {
                const res = await GetOpportunityOwners(opportunity.id);
                if (res) setOwners(res);
            }
        };
        opportunity && opportunity.id && getOwners();
    }, [opportunity]);

    useEffect(() => {
        const getDivs = async () => {
            const res = await GetDivisions();
            if (res) setDivisions(res);
        };
        getDivs();
    }, []);

    useEffect(() => {
        setIsLoading(fetchingSavedState || isFetchingData);
    }, [fetchingSavedState, isFetchingData]);

    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
            defaultPosition={{ x: (window.innerWidth / 2) - (PREVIEW_WIDTH / 2), y: (window.innerHeight / 2) - (PREVIEW_HEIGHT / 2) }}
        >
            <Dialog
                open={true}
                maxWidth={false}
                PaperProps={{ 
                    onMouseEnter: () => setIsHovered(true),
                    onMouseLeave: () => setIsHovered(false),
                    sx: { height: `${PREVIEW_HEIGHT}px`, width: `${PREVIEW_WIDTH}px` }
                }}
                hideBackdrop
                sx={{ bottom: 'unset', right: 'unset', left: 'unset' }}
            >
                <DialogTitle component="div" display="flex" sx={ PREVIEW_TITLE_STYLE }>
                    <div style={{ alignSelf: 'center' }}>
                        { isFetchingData || !opportunity ? PREVIEW_TEXT_SKELETON : opportunity.name }
                    </div>
                    <div style={{ marginLeft: 'auto' }}>
                        <IconButton id="draggable-dialog-title" sx={{ cursor: 'move', color: t => t.palette.primary.contrastText }}><ControlCameraIcon /></IconButton>
                        <IconButton onClick={ () => hideCallback() } sx={{ color: t => t.palette.primary.contrastText }}><CloseIcon /></IconButton>
                    </div>
                </DialogTitle>
                <DialogContent sx={{ p: 0 }}>
                    {(isLoading || !Boolean(opportunity)) &&
                        <Box display="flex" justifyContent="center" alignItems="center" height="100%" >
                            <CircularProgress />
                        </Box>
                    }
                    <Box p="10px" height="100%" display={isLoading ? 'none' : undefined}>
                        <div className="control-section">
                            { opportunity && opportunityTypeSwitch &&
                                <RenderContent
                                    currencies={currencies}
                                    activeFields={activeFields}
                                    divisions={divisions}
                                    owners={owners}
                                    opportunity={opportunity}
                                    setFetchingSavedState={setFetchingSavedState}
                                />
                            }
                            { opportunity && !opportunityTypeSwitch &&
                                <RenderContent
                                    currencies={currencies}
                                    activeFields={activeFields}
                                    divisions={divisions}
                                    owners={owners}
                                    opportunity={opportunity}
                                    setFetchingSavedState={setFetchingSavedState}
                                />
                            }
                        </div>
                    </Box>
                </DialogContent>
            </Dialog>
        </Draggable>
    );
}

function RenderContent({ opportunity, currencies, activeFields, divisions, owners, setFetchingSavedState }: Props2) {
    const [screenResizedControl, setScreenResizedControl] = useState(false);
    const [fetchedSavedState, setFetchedSavedState] = useState(false);
    const [lastSavedState, setLastSavedState] = useState<PanelModel[] | null>(null);
    const theme = useTheme();

    const layoutDataSettingName = useMemo(() => {
        if (opportunity) {
            switch (opportunity.type) {
                case 1: return 'OpportunityQuickViewLayoutElements_BackFill';
                case 4: return 'OpportunityQuickViewLayoutElements_Bid';
                case 3: return 'OpportunityQuickViewLayoutElements_Float';
                case 5: return 'OpportunityQuickViewLayoutElements_General';
                case 6: return 'OpportunityQuickViewLayoutElements_Lead';
                case 2: return 'OpportunityQuickViewLayoutElements_Tender';
            }
        }
        return '';
    }, [opportunity]);

    const defaultElements = useMemo(() => {
        if (opportunity) {
            switch (opportunity.type) {
                case 1: return DefaultOpportunityQuickViewLayoutElements_BackFill;
                case 4: return DefaultOpportunityQuickViewLayoutElements_Bid;
                case 3: return DefaultOpportunityQuickViewLayoutElements_Float;
                case 5: return DefaultOpportunityQuickViewLayoutElements_General;
                case 6: return DefaultOpportunityQuickViewLayoutElements_Lead;
                case 2: return DefaultOpportunityQuickViewLayoutElements_Tender;
            }
        }
        return [];
    }, [opportunity]);

    useEffect(() => {
        const windowResizeHandler = () => setScreenResizedControl(prev => !prev);
        window.addEventListener('resize', windowResizeHandler);
        return () => window.removeEventListener('resize', windowResizeHandler);
    }, []);

    const currency = useMemo(() => {
        if (opportunity) {
            const c = currencies.find(v => v.id === opportunity.currency);
            if (c) return c;
        }
        return null;
    }, [opportunity, currencies]);

    const divisionNames = useMemo<string[]>(() => {
        if (opportunity) {
            let names: string[] = [];
            const ids = opportunity.divisions.split(';');
            ids.forEach(d => {
                const item = divisions.find(div => div.id === +d);
                if (item) names.push(item.name);
            });
            return names;
        }
        return [];
    }, [divisions, opportunity]);

    const customFieldsSettingsMap = useMemo<CustomFieldSettingsMap>(() => {
        let obj: CustomFieldSettingsMap = {};
        activeFields.forEach(u => {
            const customFieldNumber = u.name.substring(13);
            const key = 'CustomField' + customFieldNumber as CustomFieldType;
            obj[key] = {
                title: u.agencyName,
                isMultiLine: u.multiLine && !u.usePredefinedValues,
            }
        });
        return obj;
    }, [activeFields]);

    useEffect(() => {
        const getSavedState = async () => {
            setFetchingSavedState(true);
            const elementsJson = await GetCustomerSettingBySettingName(layoutDataSettingName);
            if (elementsJson) {
                const panels = JSON.parse(elementsJson) as PanelModel[];
                setLastSavedState(panels);
            }
            setFetchingSavedState(false);
            setFetchedSavedState(true);
        };
        layoutDataSettingName && getSavedState();
    }, [layoutDataSettingName, setFetchingSavedState]);

    const elements = useMemo<OpportunityRecordDashboardElementDefinition[]>(() => {
        if (!fetchedSavedState) return [];
        if (lastSavedState !== null) {
            const elements = GetPanelDefinitionsFromPanelModels(lastSavedState);
            return elements ;
        }
        return defaultElements;
    }, [lastSavedState, fetchedSavedState, defaultElements]);

    const renderElement = useCallback((id: string, type: OpportunityRecordDashboardElementType) => {
        if (opportunity) {
            switch (type) {
                case 'Divider': return <Box pt="20px"><Divider component="div" /></Box>;
                case 'Spacer': return <></>;
                case 'OpportunityId': return <SingleFieldElement useEllipsisForLongValues fieldTitle="ID" fieldValue={opportunity.id.toString()} />;
                case 'OpportunityName': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Name" fieldValue={opportunity.name} />;
                case 'OpportunityType': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Type" fieldValue={opportunity.typeName} />;
                case 'OpportunityStatus': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Status" fieldValue={opportunity.statusName} />;
                case 'OpportunityStage': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Stage" fieldValue={opportunity.stageName} />;
                case 'OpportunityValue': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Value" fieldValue={opportunity.value.toFixed(2)} />;
                case 'OpportunityProbability': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Probability (%)" fieldValue={opportunity.probability.toFixed(2)} />;
                case 'OpportunityCurrency': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Currency" fieldValue={currency ? currency.formattedDescription : ''} />;
                case 'OpportunityStartDate': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Start Date" fieldValue={opportunity.startDate} format="date" />;
                case 'OpportunityCloseDate': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Close Date" fieldValue={opportunity.closeDate} format="date" />;
                case 'OpportunityDeadline': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Deadline" fieldValue={opportunity.deadline} format="date" />;
                case 'OpportunityClient': return (
                    <SingleFieldElement
                        fieldTitle='Client'
                        fieldValue={opportunity.clientName}
                        format="internal-link"
                        href={`/clients/${opportunity.clientID}`}
                        previewType="client"
                        previewRecordId={opportunity.clientID}
                    />);
                case 'OpportunityContact': return (
                    <SingleFieldElement
                        fieldTitle='Contact'
                        fieldValue={opportunity.contactName}
                        format="internal-link"
                        href={`/contacts/${opportunity.contactID}`}
                        previewType="contact"
                        previewRecordId={opportunity.contactID}
                    />);
                case 'OpportunityCandidate': return (
                    <SingleFieldElement
                        fieldTitle='Candidate'
                        fieldValue={opportunity.candidateName}
                        format="internal-link"
                        href={`/candidates/${opportunity.candidateID}`}
                        previewType="candidate"
                        previewRecordId={opportunity.candidateID}
                    />);
                case 'OpportunityOwners': return <SingleFieldElement fieldTitle="Owners" fieldValue={owners.length.toString()} />;
                case 'OpportunityDivision': return (
                    <SingleFieldElement fieldTitle="Division" format="custom">
                        {divisionNames.map(d => (
                            <Chip key={d} size="small" label={d} sx={{ mr: '5px' }} />
                        ))}
                    </SingleFieldElement>
                );
                case 'OpportunitySummary': return <MultiLineTextElement title="Summary" content={opportunity.description ?? ''} />;
                case 'OpportunitySummaryFullWidth': return <MultiLineTextElement title="Summary" content={opportunity.description ?? ''} dividerTitle />;
                default: 
                    if (type.startsWith('CustomField')) {
                        const settings = customFieldsSettingsMap[type];
                        if (settings) {
                            const title = settings.title;
                            const { field, format } = UdfOpportunityFieldMapObj[type as CustomFieldType];
                            const udfValue = opportunity[field];
                            if (format === 'string' || format === 'date' || format === 'datetime') {
                                return <SingleFieldElement isMultiLine={settings.isMultiLine} format={format} fieldTitle={title} fieldValue={udfValue ? udfValue.toString() : null} />;
                            }
                            if (format === 'number') {
                                const numValue = udfValue === undefined || udfValue === null ? '' : udfValue.toString();
                                return <SingleFieldElement fieldTitle={title} fieldValue={numValue} />;
                            }
                        }
                        else return <SingleFieldElement fieldTitle={type} fieldValue="[ Disabled Custom Field ]" />
                    }
                    return <div>{id}</div>;
            }
        }
    }, [opportunity, currency, customFieldsSettingsMap, owners.length, divisionNames]);

    const layoutResizeStopHandler = useCallback(() => {
        setScreenResizedControl(prev => !prev);
    }, []);

    const renderLayout = useCallback(() => {
        if (!fetchedSavedState) return <></>;
        return (
            <DashboardLayoutComponent
                cellSpacing={cellSpacing}
                columns={columns}
                cellAspectRatio={12 / 2}
                resizeStop={ layoutResizeStopHandler }
                allowDragging={false}
                allowResizing={false}
            >
                {elements.map(e => (
                    <PanelWrapper
                        key={e.id}
                        id={e.id}
                        col={e.col}
                        row={e.row}
                        sizeX={e.sizeX}
                        sizeY={e.sizeY}
                        minSizeX={e.minSizeX}
                        minSizeY={e.minSizeY}
                        maxSizeX={e.maxSizeX}
                        maxSizeY={e.maxSizeY}
                        resizeControl={screenResizedControl}
                        resizeIconColor={theme.palette.text.disabled}
                        gapX={gapX}
                        gapY={gapY}
                        unitWidth={unitWidth}
                        unitHeight={unitHeight}
                    >
                        {renderElement(e.id, e.type)}
                    </PanelWrapper>
                ))}
            </DashboardLayoutComponent>
        );
    }, [fetchedSavedState, layoutResizeStopHandler, elements, screenResizedControl, theme.palette.text.disabled, renderElement]);

    return renderLayout();
}