import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import React, { useCallback, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

import GreenIcon from "assets/icons/jobs/Candidate_Green_Small.png";
import YellowIcon from "assets/icons/jobs/Candidate_Yellow_Small.png";
import BlueIcon from "assets/icons/jobs/Candidate_Blue_Small.png";
import RedIcon from "assets/icons/jobs/Candidate_Red_Small.png";
import GreyIcon from "assets/icons/jobs/Candidate_Grey_Small.png";
import Radio from "@mui/material/Radio";
import { LinkCandidateToJob } from "services/CandidatesService";
import CandidatePicker from "components/Pickers/CandidatePicker";
import RWTextFieldComponent from "components/RWTextFieldComponent";

interface Props {
    open: boolean,
    jobId: number,
    closeHandler: () => void,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
    successHandler?: (message: string) => void
}

export default function LinkJobToCandidateDialog({ open, jobId, closeHandler, loadingHandler, errorHandler, successHandler }: Props) {
    const [candidateId, setCandidateId] = useState(0);
    const [ratingId, setRatingId] = useState(3);
    const [source, setSource] = useState('');

    useEffect(() => {
        if (!open) {
            setCandidateId(0);
            setRatingId(3);
            setSource('');
        }
    }, [open]);

    const linkToJobCallback = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        const res = await LinkCandidateToJob(candidateId, jobId, ratingId, source, errorHandler);
        if (res) {
            closeHandler();
            successHandler && successHandler('Candidate Linked To Job');
        }
        loadingHandler && loadingHandler(false);
    }, [candidateId, jobId, ratingId, source, loadingHandler, successHandler, errorHandler, closeHandler]);
    
    return (
        <Dialog open={open} fullWidth>
            <DialogTitle>Link Candidate To Job</DialogTitle>
            <DialogContent>
                <Stack spacing={2} mt="10px">
                    <CandidatePicker triggerFocus={open} componentId="LinkJobToCandidateDialog_CandidatePicker" value={candidateId} onSelectCallback={ c => setCandidateId(c ? c.id : 0) } />
                    <Box display="flex" justifyContent="center">
                        <Box display="flex" flexDirection="column">
                            <Box textAlign="center" height="32px"><img src={GreenIcon} alt="Green" /></Box>
                            <Radio name="green" checked={ratingId === 4} onChange={() => setRatingId(4)} />
                        </Box>
                        <Box>
                            <Box textAlign="center" height="32px"><img src={YellowIcon} alt="Yellow" /></Box>
                            <Radio name="yellow" checked={ratingId === 2} onChange={() => setRatingId(2)} />
                        </Box>
                        <Box>
                            <Box textAlign="center" height="32px"><img src={BlueIcon} alt="Blue" /></Box>
                            <Radio name="blue" checked={ratingId === 1} onChange={() => setRatingId(1)} />
                        </Box>
                        <Box>
                            <Box textAlign="center" height="32px"><img src={RedIcon} alt="Red" /></Box>
                            <Radio name="red" checked={ratingId === 0} onChange={() => setRatingId(0)} />
                        </Box>
                        <Box>
                            <Box textAlign="center" height="32px"><img src={GreyIcon} alt="Grey" /></Box>
                            <Radio name="red" checked={ratingId === 3} onChange={() => setRatingId(3)} />
                        </Box>
                    </Box>
                    <RWTextFieldComponent label="Source" value={source} onChange={ (e) => setSource(e.target.value) } />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="error" onClick={ closeHandler }>Cancel</Button>
                <Button variant="contained" color="success" disabled={ candidateId === 0 } onClick={ linkToJobCallback }>Continue</Button>
            </DialogActions>
        </Dialog>
    );
}