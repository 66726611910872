import PageContentLayout from "layouts/PageContentLayout";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import Button from "@mui/material/Button";
import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import { SimpleTreeView, TreeItem } from "@mui/x-tree-view";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import ConfirmationDialog from "components/Dialogs/Generic/ConfirmationDialog";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import moment from "moment";
import { AdvertLocation, AdvertLocationCreate } from "common/models/JobPosting/Locations";
import { GetAdvertsLocations, UpdateAdvertsLocations } from "services/ConfigurationService";
import RWTextFieldComponent from "components/RWTextFieldComponent";

interface Props {
    setSummaryBar?: (summaryBar: JSX.Element) => void
}

type Props2 = {
    id: string,
    name: string,
    isLeaf?: boolean,
    deleteHandler?: () => void
};

type LocationNodeUI = AdvertLocation & { 
    itemId: string,
    parentItemId: string,
    children: LocationNodeUI[]
};

export default function AdvertsLocations({ setSummaryBar }: Props) {
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [locationData, setLocationData] = useState<LocationNodeUI[]>([]);
    const [showAddDialog, setShowAddDialog] = useState(false);
    const [newLocationName, setNewLocationName] = useState('');
    const [parent1Index, setParent1Index] = useState(-1);
    const [parent2Index, setParent2Index] = useState(-1);
    const [expandedModel, setExpandedModel] = useState<string[]>([]);
    const [deletePathIndexes, setDeletePathIndexes] = useState<number[]>([]);
    const [deletedIds, setDeletedIds] = useState<number[]>([]);

    const getData = useCallback(async () => {
        setIsLoading(true);
        let idToIndexMap: Record<number, number> = {};
        let expandMap: Record<string, boolean> = {};
        let nodeArray: LocationNodeUI[] = [];
        let tree: LocationNodeUI[] = [];

        const res = await GetAdvertsLocations(setErrorMessage);
        if (res) {

            for (let i = 0; i < res.length; i++) {
                const l = res[i];
                idToIndexMap[l.id] = i;
                nodeArray.push({ ...l, itemId: l.id.toString(), parentItemId: l.parentID.toString(), children: [] });
            }

            for (let i = 0; i < nodeArray.length; i++) {
                const node = nodeArray[i];
                if (node.parentID === 0) {
                    tree.push(node);
                }
                else {
                    const index = idToIndexMap[node.parentID];
                    nodeArray[index].children.push(node);
                    expandMap[node.parentItemId] = true;
                }
            }
            setExpandedModel(Object.keys(expandMap));
            setLocationData(tree);
        }

        setIsLoading(false);
    }, []);

    useEffect(() => {
        getData();
    }, [getData]);

    const recursiveHasNew = useCallback((elements: LocationNodeUI[]): boolean => {
        if (elements.length === 0) return false;
        for (let i = 0; i < elements.length; i++) {
            const element = elements[i];
            if (element.id === 0) return true;
            if (recursiveHasNew(element.children)) return true;
        }
        return false;
    }, []);

    const hasChanges = useMemo(() => {
        if (deletedIds.length > 0) return true;
        return recursiveHasNew(locationData);
    }, [deletedIds.length, locationData, recursiveHasNew]);

    const recursiveGetCreateData = useCallback((elements: LocationNodeUI[]): AdvertLocationCreate[] => {
        if (elements.length === 0) return [];
        let createData: AdvertLocationCreate[] = [];
        for (let i = 0; i < elements.length; i++) {
            const element = elements[i];
            let current: AdvertLocationCreate = {
                id: element.id,
                name: element.name,
                newChildren: recursiveGetCreateData(element.children)
            };

            if (current.id === 0 || current.newChildren.length > 0) createData.push(current);
        }

        return createData;
    }, []);

    const saveChangesCallback = useCallback(async () => {
        setIsLoading(true);
        const createData = recursiveGetCreateData(locationData);
        const res = await UpdateAdvertsLocations({ create: createData, delete: deletedIds }, setErrorMessage);

        if (!res) {
            setIsLoading(false);
            return false;
        }

        setDeletedIds([]);
        setIsLoading(false);
        await getData();
        setSuccessMessage('Changes Saved');
        return true;
    }, [deletedIds, getData, locationData, recursiveGetCreateData]);

    useEffect(() => {
        const action = <Button variant="contained" color="primary" onClick={saveChangesCallback} disabled={!hasChanges}>Save</Button>;
        const SummaryBar = (
            <TitleAndActionSummaryBar
                title="Configuration > Adverts > Locations"
                browserTabTitle="Adverts > Configuration"
                action={action}
            />
        );

        setSummaryBar && setSummaryBar(SummaryBar);
    }, [hasChanges, saveChangesCallback, setSummaryBar]);

    const recursiveDeleteNode = useCallback((elements: LocationNodeUI[], indexes: number[]): number => {
        const index = indexes[0];
        if (indexes.length === 1) {
            const elementId = elements[index].id;
            elements.splice(index, 1);
            return elementId;
        }
        const children = elements[index].children;
        return recursiveDeleteNode(children, indexes.slice(1));
    }, []);

    const deleteNode = useCallback((indexPath: number[]) => {
        let deletedId = 0;
        setLocationData(prev => {
            let tmp = [...prev];
            deletedId = recursiveDeleteNode(tmp, indexPath);
            return tmp;
        });
        if (deletedId) setDeletedIds(prev => [...prev, deletedId]);
        setDeletePathIndexes([]);
    }, [recursiveDeleteNode]);

    const recursiveAddNode = useCallback((elements: LocationNodeUI[], indexes: number[], name: string, parentId: number, parentItemId: string): boolean => {
        if (indexes.length === 0) {
            const trimmed = name.trim();
            
            const existing = elements.find(e => e.name === trimmed);
            if (existing) {
                setErrorMessage('This Location already exists');
                return false;
            }

            const nodeId = moment().format('YYYYMMDDHHmmssSS');
            elements.push({
                id: 0,
                itemId: nodeId,
                parentID: parentId,
                parentItemId: parentItemId,
                isDeleted: false,
                name: trimmed,
                children: []
            });
            elements.sort((a,b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0));
            return true;
        }

        const index = indexes[0];
        const item = elements[index];
        return recursiveAddNode(item.children, indexes.slice(1), name, item.id, item.itemId);
    }, []);

    const addNode = useCallback((name: string, index1: number, index2: number) => {
        let indexPath: number[] = [];
        let wasAdded = true;
        if (index1 > -1) indexPath.push(index1);
        if (index1 > -1 && index2 > -1) indexPath.push(index2);
        setLocationData(prev => {
            let tmp = [...prev];
            tmp.sort((a,b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0));
            wasAdded = recursiveAddNode(tmp, indexPath, name, 0, '');
            return tmp;
        });
        if (wasAdded) setShowAddDialog(false);
    }, [recursiveAddNode]);

    const renderTreeNode = useCallback((node: LocationNodeUI, indexPath: number[]) => {
        if (node.children && node.children.length > 0) {
            return (
                <CustomTreeNodeElement key={node.itemId} id={node.itemId} name={node.name}>
                    {node.children.map((c, i) => renderTreeNode(c, [...indexPath, i]))}
                </CustomTreeNodeElement>
            );
        }
        return <CustomTreeNodeElement key={node.itemId} id={node.itemId} name={node.name} isLeaf deleteHandler={() => setDeletePathIndexes(indexPath)} />
    }, []);

    const renderTreeItems2 = useMemo(() => {
        return (
            <>
                {locationData.map((n, i) => renderTreeNode(n, [i]))}
            </>
        );
    }, [locationData, renderTreeNode]);

    useEffect(() => {
        if (parent1Index) {}
        setParent2Index(-1);
    }, [parent1Index]);

    useEffect(() => {
        if (!showAddDialog) {
            setNewLocationName('');
            setParent1Index(-1);
            setParent2Index(-1);
        }
    }, [showAddDialog]);
    
    return (
        <>
            <Snackbar open={successMessage !== ''} autoHideDuration={3000} onClose={() => setSuccessMessage('')}>
                <Alert onClose={() => setSuccessMessage('')}>{ successMessage }</Alert>
            </Snackbar>
            <Snackbar open={Boolean(errorMessage)} autoHideDuration={3000} onClose={() => setErrorMessage('')}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{errorMessage}</Alert>
            </Snackbar>
            <ConfirmationDialog
                open={deletePathIndexes.length > 0}
                message="Are you sure you want to delete this location?"
                onClose={() => setDeletePathIndexes([])}
                onContinue={() => deleteNode(deletePathIndexes)}
                title="Confirm Action"
            />
            <Dialog open={showAddDialog} fullWidth>
                <DialogTitle>Add Location</DialogTitle>
                <DialogContent>
                    <Stack spacing={2} mt={2}>
                        <TextField select label="Parent 1" value={parent1Index.toString()} onChange={({target}) => setParent1Index(+target.value)}>
                            <MenuItem value="-1">None</MenuItem>
                            {locationData.map((l, i) => <MenuItem key={l.itemId} value={i.toString()} >{l.name}</MenuItem>)}
                        </TextField>
                        {parent1Index !== -1 &&
                            <TextField select label="Parent 2" value={parent2Index.toString()} onChange={({target}) => setParent2Index(+target.value)}>
                                <MenuItem value="-1">None</MenuItem>
                                {locationData[parent1Index].children.map((l, i) => <MenuItem key={l.itemId} value={i.toString()} >{l.name}</MenuItem>)}
                            </TextField>
                        }
                        <RWTextFieldComponent label="Location Name" value={newLocationName} onChange={(e) => setNewLocationName(e.target.value)} />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="error" onClick={ () => setShowAddDialog(false) } >Cancel</Button>
                    <Button variant="contained" color="success" onClick={ () => addNode(newLocationName, parent1Index, parent2Index) }  disabled={!Boolean(newLocationName)} >Add</Button>
                </DialogActions>
            </Dialog>
            <PageContentLayout title="Locations" contentAction={<Button variant="contained" color="success" onClick={ () => setShowAddDialog(true) } >Add Location</Button>} showLoading={isLoading}>
                <SimpleTreeView
                    expandedItems={expandedModel}
                    onExpandedItemsChange={(e, ids) => setExpandedModel(ids)}
                >
                    { renderTreeItems2 }
                </SimpleTreeView>
            </PageContentLayout>
        </>
    );
}

const CustomTreeNodeElement = ({ id, name, isLeaf, deleteHandler, children }: React.PropsWithChildren<Props2>) => {
    return (
        <TreeItem key={id} itemId={id} label={(
            <Box display="flex" alignItems="center" height={isLeaf ? undefined : '34px'}>
                <Typography flexGrow={1}>
                    {name}
                </Typography>
                { isLeaf && <IconButton size="small" onClick={ deleteHandler }><DeleteIcon /></IconButton> }
            </Box>
        )}>
            {children}
        </TreeItem>
    );
};