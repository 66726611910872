import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import Alert from "components/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import AddIcon from '@mui/icons-material/Add';

import { ScreeningQuestionnaireQuestion, ScreeningQuestionnaireTemplate } from "common/models/Configuration/AdvertScreeningQuestionnaire";
import ScreeningQuestionnaireQuestionComponent from "components/Adverts/ScreeningQuestionnaireQuestion";
import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import PageContentLayout from "layouts/PageContentLayout";
import { CreateScreeningquestionnaireTemplate, GetScreeningQuestionnaireTemplate, UpdateScreeningQuestionnaireTemplate } from "services/AdvertsService";
import { GetAutomatedRejectionsOptions } from "services/ConfigurationService";
import useObjectStateWithChangeTracker from "hooks/UseObjectStateWithChangeTracker";
import { DefaultScreeningQuestionnaire, NoChangesTracker } from "util/Definitions/AdvertsScreeningQuestionnaire";
import useUnsavedChangesDialog from "hooks/UseUnsavedChangesDialog";
import RWTextFieldComponent from "components/RWTextFieldComponent";

interface Props {
    setSummaryBar?: (summaryBar: JSX.Element) => void
}

export default function AdvertsScreeningQuestionnaireItem({ setSummaryBar }: Props) {
    const [isLoading, setIsLoading] = useState(false);
    const [isFetchingRejectionTemplates, setIsFetchingRejectionTemplates] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const { state, init, change, updateInitial, hasChanges } = useObjectStateWithChangeTracker<ScreeningQuestionnaireTemplate>(DefaultScreeningQuestionnaire, NoChangesTracker);
    const [questions, setQuestions] = useState<ScreeningQuestionnaireQuestion[]>([]);
    const [rejectionOptions, setRejectionOptions] = useState<JSX.Element[]>([]);
    const params = useParams();

    const itemId = useMemo(() => {
        const parsedId = +(params.id ?? '0');
        if(isNaN(parsedId)) return 0;
        return parsedId
    }, [params.id]);

    const saveHandler = useCallback(async () => {
        try {
            
            setIsLoading(true);

            if(state.name === '') {
                throw Error('The screening questionnaire must have a name');
            }

            if(questions.length === 0) {
                throw Error('The screening questionnaire must have at least one question');
            }

            let realQuestions: ScreeningQuestionnaireQuestion[] = [];
            for (let i = 0; i < questions.length; i++) {
                const f = questions[i];
                if (f.question === '') {
                    throw Error(`Question #${i + 1} field is empty`);
                }

                if(f.typeId !== 1) {
                    if(f.answers && f.answers.length > 1) {
                        const emptyIndex = f.answers.findIndex(v => v.Text === '');
                        if(emptyIndex !== -1) {
                            throw Error(`Question #${i + 1} has an answer element with empty text`);
                        }
                        const noNockoutIndex = f.answers.findIndex(v => !v.Knockout);
                        if (noNockoutIndex === -1) {
                            throw Error(`Question #${i + 1} at least one of the answer values must be knockout NO`);
                        }
                    }
                    else {
                        throw Error(`Question #${i + 1} should have at least two answers`);
                    }
                }

                realQuestions.push({ ...f, id: i });
            }

            const realScreeningQuestionnaire = { ...state, json: JSON.stringify(realQuestions) };

            if(itemId === 0) {
                const res = await CreateScreeningquestionnaireTemplate(realScreeningQuestionnaire, setErrorMessage);
                if (!res) throw Error('');

                updateInitial();
                setShowSuccess(true);
            }
            else {
                if(itemId > 0) {
                    const res = await UpdateScreeningQuestionnaireTemplate(itemId, realScreeningQuestionnaire, setErrorMessage);
                    if (!res) throw Error('');

                    updateInitial();
                    setShowSuccess(true);
                }
            }

            setIsLoading(false);
            return true;
        } catch (error) {

            setIsLoading(false);
            if(error instanceof Error && error.message) {
                setErrorMessage(error.message);
            }
            return false;
        }

    }, [questions, itemId, state, updateInitial]);

    const { unsavedChangesDialog, hasBlockedRoute } = useUnsavedChangesDialog(hasChanges, saveHandler);

    useEffect(() => {
        const catchedSaveHandler = async () => {
            try { await saveHandler(); } catch (error) { }
        }
        const saveButton = <Button variant="contained" color="primary" disabled={!hasChanges} onClick={catchedSaveHandler} >Save</Button>;
        const summaryBar = (
            <TitleAndActionSummaryBar
                title="Configuration > Adverts > Screening Questionnaires"
                browserTabTitle="Adverts > Configuration"
                action={saveButton}
            />
        );
        setSummaryBar && setSummaryBar(summaryBar);
    }, [saveHandler, setSummaryBar, hasChanges]);

    useEffect(() => {
        const getData = async () => {
            setIsLoading(true);
            const data = await GetScreeningQuestionnaireTemplate(itemId);
            
            if(data) {
                const questions = JSON.parse(data.json) as ScreeningQuestionnaireQuestion[];
                init(data);
                setQuestions(questions);
            }
            setIsLoading(false);
        };
        itemId !== 0 && getData();
    }, [itemId, init]);

    useEffect(() => {
        change('json', JSON.stringify(questions));
    }, [questions, change]);

    useEffect(() => {
        const getTemplates = async () => {
            setIsFetchingRejectionTemplates(true);
            const rejectionTemplates = await GetAutomatedRejectionsOptions();
            if(rejectionTemplates) {
                let options = rejectionTemplates.map( t => <MenuItem key={t.id} value={t.id.toString()}>{t.name}</MenuItem>);
                options.unshift(<MenuItem key={0} value="0">None</MenuItem>);
                setRejectionOptions(options);
            }
            setIsFetchingRejectionTemplates(false);
        };
        getTemplates();
    }, []);

    const stringChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        change(name as keyof ScreeningQuestionnaireTemplate, value);
    }

    const numberChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        change(name as keyof ScreeningQuestionnaireTemplate, +value);
    }

    const addQuestionHandler = () => {
        setQuestions(prev => {
            let tmp = [...prev];
            let newId = 0;

            for (let i = 0; i < prev.length + 1; i++) {
                const index = prev.findIndex(f => f.id === i);
                if(index === -1) {
                    newId = i;
                    break;
                }
            }

            tmp.push({ id: newId, question: '', typeId: 1, answers: null, rejectionEmailTemplateId: 0 });
            return tmp;
        });
    }

    const questionChangeHandler = useCallback((index: number, question: ScreeningQuestionnaireQuestion) => {
        setQuestions(prev => {
            let tmp = [...prev];
            tmp[index] = question;
            return tmp;
        });
    }, []);

    const questionMoveUpHandler = useCallback((index: number) => {
        if(index === 0) return;
        setQuestions(prev => {
            let tmp = [...prev];
            const q = prev[index];
            tmp.splice(index, 1);
            tmp.splice(index -1, 0, q);
            return tmp;
        });
    }, []);

    const questionMoveDownHandler = useCallback((index: number, isLast: boolean) => {
        if(isLast) return;
        setQuestions(prev => {
            let tmp = [...prev];
            const q = prev[index];
            tmp.splice(index, 1);
            tmp.splice(index +1, 0, q);
            return tmp;
        });
    }, []);

    const questionDeleteHandler = useCallback((index: number) => {
        setQuestions(prev => {
            let tmp = [...prev];
            tmp.splice(index, 1);
            return tmp;
        });
    }, []);

    return (
        <>
            {unsavedChangesDialog}
            { !hasBlockedRoute && showSuccess && <Navigate to="/configuration/adverts/screening-questionnaires" /> }
            <Snackbar open={showSuccess} autoHideDuration={3000} onClose={() => setShowSuccess(false)}>
                <Alert onClose={() => setShowSuccess(false)}>Changes Saved</Alert>
            </Snackbar>
            <Snackbar open={errorMessage !== ''} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <PageContentLayout title="Screening Questionnaire" showLoading={isLoading || isFetchingRejectionTemplates}>
                <Stack spacing={3}>
                    <RWTextFieldComponent
                        label="Name"
                        name="name"
                        value={state.name}
                        onChange={stringChangeHandler}
                    />
                    <TextField
                        select
                        label="Automated Rejection Delay"
                        name="automatedRejectionDelay"
                        value={state.automatedRejectionDelay.toString()}
                        onChange={numberChangeHandler}
                    >
                        <MenuItem value="60">1 Hour</MenuItem>
                        <MenuItem value="120">2 Hours</MenuItem>
                        <MenuItem value="240">4 Hours</MenuItem>
                        <MenuItem value="480">8 Hours</MenuItem>
                        <MenuItem value="720">12 Hours</MenuItem>
                        <MenuItem value="1440">24 Hours</MenuItem>
                        <MenuItem value="2880">2 Days</MenuItem>
                        <MenuItem value="4320">3 Days</MenuItem>
                        <MenuItem value="10080">1 Week</MenuItem>
                    </TextField>
                    <TextField
                        select
                        label="Status"
                        name="statusID"
                        value={state.statusID.toString()}
                        onChange={numberChangeHandler}
                    >
                        <MenuItem value="1">Active</MenuItem>
                        <MenuItem value="2">Inactive</MenuItem>
                    </TextField>
                    <Box>
                        <Button onClick={addQuestionHandler} endIcon={<AddIcon />}>Add Question</Button>
                        {questions.map((q, i) => (
                            <ScreeningQuestionnaireQuestionComponent
                                key={q.id}
                                index={i}
                                isLast={i === (questions.length -1)}
                                question={q}
                                rejectionOptions={rejectionOptions}
                                onChange={questionChangeHandler}
                                moveUp={questionMoveUpHandler}
                                moveDown={questionMoveDownHandler}
                                onDelete={questionDeleteHandler}
                            />
                            )
                        )}
                    </Box>
                </Stack>
            </PageContentLayout>
        </>
    );
}
