import React from "react";
import { Routes, Route } from "react-router";
import Dashboard from "./Dashboard";
import Activities from "./Activities";
import MyScheduledMessages from './MyScheduledMessages';
import GlobalLookup from './GlobalLookup';

export default class DashboardIndex extends React.Component {
    render(): React.ReactNode {
        return (
            <Routes>
                <Route path='' element={<Dashboard />} />
                <Route path='activities' element={<Activities />} />
                <Route path='my-scheduled-messages' element={<MyScheduledMessages />} />
                <Route path='lookup' element={<GlobalLookup />} />
            </Routes>
        );
    }
}