import { TagValue } from "common/models/Configuration/Tags";

export const DefaultTagValue: TagValue = {
    tagID: 0,
    entityID: 0,
    playerID: 0,
    recruiterID: 0,
    recruiterName: '',
    agencyID: 0,
    tagTypeID: 0,
    tagTypeName: '',
    countTotal: 0,
    tag: '',
    createdDate: '0001-01-01',
    lastTagged: '0001-01-01',
    isAgency: 0
}