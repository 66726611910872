import { useTheme } from "@mui/material/styles";
import { useEffect } from "react";

export default function useKanbanStyleUpdater(isSetupFinished: boolean, delayMs: number = 150, triggerParam?: boolean) {
    const theme = useTheme();

    useEffect(() => {
        if (triggerParam) {}
        if (isSetupFinished) {
            const defaultText = theme.palette.text.primary;
            const emptyBackground = theme.palette.background.default;
            const contentBackground = theme.palette.background.paper;
            const isDark = theme.palette.mode === 'dark';
            const bgImg = isDark ? 'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))' : 'unset';

            const kanbanContainer = document.querySelectorAll<HTMLElement>('div.wx-material-theme');
            kanbanContainer.forEach((v, i) => {
                v.style.setProperty('--wx-kanban-background', emptyBackground);
                v.style.setProperty('--wx-kanban-background-light', contentBackground);
                v.style.setProperty('--wx-border-color', isDark ? contentBackground : '#dfdfdf');
                v.style.setProperty('--wx-color-font', defaultText);
                v.style.setProperty('--wx-icon-color', defaultText);
                v.style.setProperty('--wx-kanban-column-width', 'unset');
            });

            const kanbanHeadersContainer = document.querySelectorAll<HTMLElement>('div.wx-content>div.wx-header');
            kanbanHeadersContainer.forEach((v, i) => {
                v.style.width = '100%';
            });

            const kanbanHeaders = document.querySelectorAll<HTMLElement>('div.wx-header>div.wx-column');
            kanbanHeaders.forEach((v, i) => {
                v.style.backgroundImage = bgImg;
                v.style.flex = '1 1 0';
            });

            const kanbanCards = document.querySelectorAll<HTMLElement>('div.wx-column>div.wx-list-wrapper>div.wx-card');
            kanbanCards.forEach((v, i) => {
                v.style.backgroundImage = bgImg;
            });

            const kanbanContentContainer = document.querySelectorAll<HTMLElement>('div.wx-content');
            kanbanContentContainer.forEach((v, i) => {
                v.style.height = '100%';
                v.style.paddingBottom = '0';
            });

            const kanbanRow = document.querySelectorAll<HTMLElement>('div.wx-row');
            kanbanRow.forEach((v, i) => {
                v.style.height = '100%';
            });

            const kanbanColumns = document.querySelectorAll<HTMLElement>('div.wx-row>div.wx-content>div.wx-column');
            kanbanColumns.forEach((v, i) => {
                v.style.display = 'flex';
                v.style.flexDirection = 'column';
                v.style.flex = '1 1 0';
            });

            const kanbanListWrappers = document.querySelectorAll<HTMLElement>('div.wx-list-wrapper');
            kanbanListWrappers.forEach((v, i) => {
                v.style.flexGrow = '1';
            });

            const kanbanControlWrappers = document.querySelectorAll<HTMLElement>('div.wx-controls-wrapper');
            kanbanControlWrappers.forEach((v, i) => {
                v.style.display = 'none';
            });
        }
    }, [isSetupFinished, theme, delayMs, triggerParam]);
}