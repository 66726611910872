import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { Editor } from "@tinymce/tinymce-react";
import * as tinymce from 'tinymce';
import React, { useCallback, useEffect, useRef, useState } from "react";
import { CancelPlacement } from "services/PlacementsService";
import useActiveUsers from "hooks/UseActiveUsers";
import useActiveGroups from "hooks/UseActiveGroups";
import { useTheme } from "@mui/material/styles";

interface Props {
    open: boolean,
    placementId: number,
    candidateName: string,
    jobTitle: string,
    closeHandler: () => void,
    loadingHandler?: (isLoading: boolean) => void,
    successHandler?: (message: string) => void,
    errorHandler?: (message: string) => void
}

export default function CancelPlacementDialog({ open, placementId, candidateName, jobTitle, closeHandler, loadingHandler, successHandler, errorHandler }: Props) {
    const [reasonId, setReasonId] = useState(5);
    const activeUsers = useActiveUsers();
    const activeGroups = useActiveGroups();
    const editorRef = useRef<tinymce.Editor | null>(null);
    const theme = useTheme();
    const isDarkTheme = theme.palette.mode === 'dark';

    useEffect(() => {
        if (!open) {
            setReasonId(5);
            const api = editorRef.current;
            if (api) api.setContent('');
        }
    }, [open]);

    const submitCallback = useCallback(async () => {
        const api = editorRef.current;
        if (api) {
            const comments = api.getContent();
            loadingHandler && loadingHandler(true);
            const res = await CancelPlacement(placementId, reasonId, comments, errorHandler);
            if (res) {
                successHandler && successHandler('Placement Cancelled');
                closeHandler();
            }
            loadingHandler && loadingHandler(false);
        }
    }, [placementId, reasonId, loadingHandler, errorHandler, successHandler, closeHandler]);

    return (
        <Dialog open={open} fullWidth>
            <DialogTitle>Cancel Placement</DialogTitle>
            <DialogContent>
                <Stack spacing={2} mt="10px">
                    <TextField label="Placement ID" value={placementId.toString()} InputProps={{ readOnly: true }} />
                    <TextField label="Candidate" value={candidateName} InputProps={{ readOnly: true }} />
                    <TextField label="Job Title" value={jobTitle} InputProps={{ readOnly: true }} />
                    <TextField select label="Reason" value={reasonId.toString()} onChange={({target}) => setReasonId(+target.value)}>
                        <MenuItem value="5">Administrative</MenuItem>
                        <MenuItem value="1">Cancelled By Candidate</MenuItem>
                        <MenuItem value="3">Cancelled By Client</MenuItem>
                    </TextField>
                    <Editor
                        tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce-5.10.2/tinymce.min.js'}
                        onInit={ (evt, editor) => editorRef.current = editor }
                        init={{
                            height: 200,
                            skin: isDarkTheme ? 'oxide-dark' : undefined,
                            content_css: isDarkTheme ? 'dark' : undefined,
                            branding: false,
                            menubar: false,
                            statusbar: false,
                            contextmenu: false,
                            resize: false,
                            plugins: 'powerpaste link code',
                            browser_spellcheck: true,
                            forced_root_block: false,
                            verify_html: false,
                            toolbar1: '',
                            setup: (editor) => {
                                if (activeUsers.length > 0 || activeGroups.length > 0) {
                                    
                                    const getMatchedMentions = (pattern: string) => {
                                        return activeUsers.filter( u => {
                                            return u.name.toLowerCase().indexOf(pattern.toLowerCase()) !== -1;
                                        });
                                    };

                                    const getMatchedGroups = (pattern: string) => {
                                        return activeGroups.filter(u => u.name.toLowerCase().indexOf(pattern.toLowerCase()) !== -1);
                                    }

                                    editor.ui.registry.addAutocompleter('mentions', {
                                        ch: '@',
                                        minChars: 1,
                                        columns: 1,
                                        onAction: (api, rng, value) => {
                                            editor.selection.setRng(rng);
                                            editor.insertContent(value);
                                            editor.insertContent(" ");
                                            api.hide();
                                        },
                                        fetch: async (pattern) => {
                                            const contents = getMatchedMentions(pattern).map(u => {
                                                const options: tinymce.Ui.InlineContent.AutocompleterContents = {
                                                    type: 'autocompleteitem',
                                                    text: u.name,
                                                    value: `<strong data-umention="${u.id}">${u.name}</strong> `
                                                }
                                                return options;
                                            });

                                            const gContents = getMatchedGroups(pattern).map(g => {
                                                const options: tinymce.Ui.InlineContent.AutocompleterContents = {
                                                    type: 'autocompleteitem',
                                                    text: `${g.name} [GROUP]`,
                                                    value: `<strong data-gmention="${g.id}">${g.name}</strong> `
                                                };
                                                return options;
                                            })

                                            return [...contents, ...gContents];
                                        }
                                    });
                                }
                            }
                        }}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={ closeHandler }>Cancel</Button>
                <Button variant="contained" color="success" onClick={ submitCallback } >Submit</Button>
            </DialogActions>
        </Dialog>
    );
}