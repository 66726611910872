import { NameIdObj } from "common/models/GenericTypes";

export const NationalityList: NameIdObj[] = [
    { id: 0, name: '-- Select --' },
    { id: 156, name: 'Afghan' },
    { id: 116, name: 'Albanian' },
    { id: 50, name: 'Algerian' },
    { id: 3, name: 'American' },
    { id: 157, name: 'American Samoan' },
    { id: 125, name: 'Andorran' },
    { id: 132, name: 'Angolan' },
    { id: 158, name: 'Anguillan' },
    { id: 154, name: 'Antarctica' },
    { id: 159, name: 'Antiguan' },
    { id: 18, name: 'Argentinean' },
    { id: 146, name: 'Armenian' },
    { id: 51, name: 'Aruban' },
    { id: 11, name: 'Australian' },
    { id: 6, name: 'Austrian' },
    { id: 117, name: 'Azeri' },
    { id: 52, name: 'Bahamian' },
    { id: 53, name: 'Bahraini' },
    { id: 112, name: 'Bangladeshi' },
    { id: 54, name: 'Barbadian' },
    { id: 147, name: 'Belarusian' },
    { id: 19, name: 'Belgian' },
    { id: 131, name: 'Belizean' },
    { id: 160, name: 'Beninese' },
    { id: 12, name: 'Bermuda National' },
    { id: 161, name: 'Bhutanese' },
    { id: 55, name: 'Bolivian' },
    { id: 162, name: 'Bosnian' },
    { id: 123, name: 'Botswana National' },
    { id: 163, name: 'Bouvet Island' },
    { id: 56, name: 'Brazillian' },
    { id: 2, name: 'British' },
    { id: 69, name: 'British (Gibraltar)' },
    { id: 59, name: 'British (Guernsey)' },
    { id: 74, name: 'British (Isle of Man)' },
    { id: 105, name: 'British (Jersey)' },
    { id: 57, name: 'British (Virgin Islands)' },
    { id: 164, name: 'British Indian Ocean Territory' },
    { id: 165, name: 'Bruneian' },
    { id: 58, name: 'Bulgarian' },
    { id: 166, name: 'Burkinabè' },
    { id: 167, name: 'Burundian' },
    { id: 115, name: 'Cambodian' },
    { id: 152, name: 'Cameroonian' },
    { id: 16, name: 'Canadian' },
    { id: 168, name: 'Cape Verdean' },
    { id: 20, name: 'Cayman Islander' },
    { id: 169, name: 'Central African' },
    { id: 170, name: 'Chadian' },
    { id: 60, name: 'Chilean' },
    { id: 61, name: 'Chinese' },
    { id: 26, name: 'Chinese (Hong Kong)' },
    { id: 171, name: 'Christmas Islander' },
    { id: 172, name: 'Cocos Islander' },
    { id: 62, name: 'Columbian' },
    { id: 173, name: 'Comorian' },
    { id: 174, name: 'Congolese' },
    { id: 175, name: 'Congolese' },
    { id: 21, name: 'Cook Islander' },
    { id: 63, name: 'Costa Rican' },
    { id: 42, name: 'Croatian' },
    { id: 176, name: 'Cuban' },
    { id: 64, name: 'Cypriut' },
    { id: 22, name: 'Czech' },
    { id: 23, name: 'Danish' },
    { id: 177, name: 'Djiboutian' },
    { id: 178, name: 'Dominican' },
    { id: 133, name: 'Domnican Republic National' },
    { id: 7, name: 'Dutch' },
    { id: 33, name: 'Dutch (Antilles)' },
    { id: 179, name: 'East Timorese' },
    { id: 65, name: 'Ecuadorian' },
    { id: 66, name: 'Egyptian' },
    { id: 67, name: 'El Salvador National' },
    { id: 122, name: 'Equatorial Guinea National' },
    { id: 180, name: 'Eritrean' },
    { id: 68, name: 'Estonian' },
    { id: 124, name: 'Ethiopian' },
    { id: 181, name: 'Falkland Islander' },
    { id: 182, name: 'Faroese' },
    { id: 183, name: 'Fijian' },
    { id: 24, name: 'Finnish' },
    { id: 248, name: 'Finnish' },
    { id: 4, name: 'French' },
    { id: 184, name: 'French Guianese' },
    { id: 185, name: 'French Polynesian' },
    { id: 186, name: 'French Southern Territories' },
    { id: 142, name: 'Gabonese' },
    { id: 187, name: 'Gambian' },
    { id: 149, name: 'Georgian' },
    { id: 5, name: 'German' },
    { id: 25, name: 'Ghanaian' },
    { id: 70, name: 'Greek' },
    { id: 114, name: 'Greenland National' },
    { id: 188, name: 'Grenadian' },
    { id: 189, name: 'Guadeloupian' },
    { id: 190, name: 'Guamanian' },
    { id: 71, name: 'Guatemalan' },
    { id: 191, name: 'Guinean' },
    { id: 192, name: 'Guinean' },
    { id: 128, name: 'Guyanese' },
    { id: 193, name: 'Haitian' },
    { id: 194, name: 'Heard and McDonald Islands' },
    { id: 143, name: 'Honduran' },
    { id: 43, name: 'Hungarian' },
    { id: 195, name: 'I-Kiribati' },
    { id: 72, name: 'Icelandic' },
    { id: 27, name: 'Indian' },
    { id: 44, name: 'Indonesian' },
    { id: 73, name: 'Iranian' },
    { id: 113, name: 'Iraqi' },
    { id: 17, name: 'Irish' },
    { id: 28, name: 'Israeli' },
    { id: 75, name: 'Italian' },
    { id: 76, name: 'Ivoirian' },
    { id: 77, name: 'Jamacan' },
    { id: 8, name: 'Japanese' },
    { id: 78, name: 'Jordanian' },
    { id: 118, name: 'Kazakh' },
    { id: 29, name: 'Kenyan' },
    { id: 220, name: 'Kittitian' },
    { id: 79, name: 'Kuwaiti' },
    { id: 196, name: 'Kyrgyz' },
    { id: 197, name: 'Laotian' },
    { id: 108, name: 'Latvian' },
    { id: 80, name: 'Lebanese' },
    { id: 107, name: 'Liberian' },
    { id: 199, name: 'Libyan' },
    { id: 81, name: 'Liechtenstein National' },
    { id: 82, name: 'Lithuanian' },
    { id: 30, name: 'Luxembourgeois' },
    { id: 83, name: 'Macau National' },
    { id: 136, name: 'Macedonian' },
    { id: 205, name: 'Mahorais' },
    { id: 200, name: 'Malagasy' },
    { id: 141, name: 'Malawian' },
    { id: 31, name: 'Malaysian' },
    { id: 201, name: 'Maldivian' },
    { id: 138, name: 'Malian' },
    { id: 85, name: 'Maltese' },
    { id: 202, name: 'Marshallese' },
    { id: 203, name: 'Martiniquais' },
    { id: 204, name: 'Mauritanian' },
    { id: 32, name: 'Mauritian' },
    { id: 86, name: 'Mexican' },
    { id: 206, name: 'Micronesian' },
    { id: 121, name: 'Moldovan' },
    { id: 110, name: 'Monaco National' },
    { id: 148, name: 'Mongolian' },
    { id: 249, name: 'Montenegrin' },
    { id: 207, name: 'Montserratian' },
    { id: 45, name: 'Moroccan' },
    { id: 198, name: 'Mosotho' },
    { id: 137, name: 'Mozambican' },
    { id: 208, name: 'Myanmar' },
    { id: 119, name: 'Namibian' },
    { id: 209, name: 'Nauruan' },
    { id: 126, name: 'Nepalese' },
    { id: 210, name: 'New Caledonian' },
    { id: 34, name: 'New Zealander' },
    { id: 243, name: 'Ni-Vanuatu' },
    { id: 211, name: 'Nicaraguan' },
    { id: 87, name: 'Nigerian' },
    { id: 212, name: 'Nigerien' },
    { id: 213, name: 'Niuean' },
    { id: 214, name: 'Norfolk Islander' },
    { id: 151, name: 'North Korean' },
    { id: 215, name: 'Northern Mariana Islands' },
    { id: 35, name: 'Norwegian' },
    { id: 127, name: 'Omani' },
    { id: 88, name: 'Pakistani' },
    { id: 216, name: 'Palauan' },
    { id: 153, name: 'Palestinian' },
    { id: 36, name: 'Panamanian' },
    { id: 89, name: 'Papua New Guinean' },
    { id: 144, name: 'Paraguayan' },
    { id: 90, name: 'Peruvian' },
    { id: 91, name: 'Philippine' },
    { id: 217, name: 'Pitcairn Islander' },
    { id: 46, name: 'Polish' },
    { id: 48, name: 'Portuguese' },
    { id: 140, name: 'Puerto Rican' },
    { id: 111, name: 'Qatari' },
    { id: 218, name: 'Reunionese' },
    { id: 92, name: 'Romanian' },
    { id: 103, name: 'Russian' },
    { id: 219, name: 'Rwandan' },
    { id: 247, name: 'Sahrawi' },
    { id: 221, name: 'Saint Lucian' },
    { id: 222, name: 'Saint Vincentian' },
    { id: 224, name: 'Sammarinese' },
    { id: 223, name: 'Samoan' },
    { id: 225, name: 'Sao Tomean' },
    { id: 93, name: 'Saudi Arabian' },
    { id: 145, name: 'Senegalese' },
    { id: 250, name: 'Serbian' },
    { id: 227, name: 'Seychellois' },
    { id: 135, name: 'Sierra Leonean' },
    { id: 9, name: 'Singaporean' },
    { id: 94, name: 'Slovakian' },
    { id: 95, name: 'Slovenian' },
    { id: 228, name: 'Solomon Islander' },
    { id: 229, name: 'Somali' },
    { id: 13, name: 'South African' },
    { id: 155, name: 'South Georgia and The South Sandwich Islands' },
    { id: 104, name: 'South Korean' },
    { id: 37, name: 'Spanish' },
    { id: 49, name: 'Sri Lankan' },
    { id: 230, name: 'St. Helena' },
    { id: 231, name: 'St. Pierre and Miquelon' },
    { id: 232, name: 'Sudanese' },
    { id: 130, name: 'Suriname National' },
    { id: 233, name: 'Svalbard and Jan Mayen Islands' },
    { id: 234, name: 'Swazi' },
    { id: 14, name: 'Swedish' },
    { id: 38, name: 'Swiss' },
    { id: 235, name: 'Syrian' },
    { id: 15, name: 'Taiwanese' },
    { id: 236, name: 'Tajikistani' },
    { id: 134, name: 'Tanzanian' },
    { id: 39, name: 'Thai' },
    { id: 237, name: 'Togolese' },
    { id: 238, name: 'Tokelauan' },
    { id: 239, name: 'Tongan' },
    { id: 96, name: 'Trinidad & Tobago National' },
    { id: 97, name: 'Tunisian' },
    { id: 47, name: 'Turkish' },
    { id: 139, name: 'Turkmen' },
    { id: 240, name: 'Turks and Caicos Islands' },
    { id: 241, name: 'Tuvaluan' },
    { id: 98, name: 'UAE National' },
    { id: 129, name: 'Ugandan' },
    { id: 106, name: 'Ukranian' },
    { id: 242, name: 'United States Minor Outlying Islands' },
    { id: 99, name: 'Uruguayan' },
    { id: 109, name: 'Uzbek' },
    { id: 244, name: 'Vatican City State (Holy See)' },
    { id: 100, name: 'Venezuela' },
    { id: 102, name: 'Vietnamese' },
    { id: 245, name: 'Virgin Islander' },
    { id: 246, name: 'Wallis and Futuna Islander' },
    { id: 120, name: 'Yemeni' },
    { id: 40, name: 'Zambian' },
    { id: 41, name: 'Zimbabwean' },
];