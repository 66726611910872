import { useEffect, useState } from "react";
import { NameIdObj } from "../common/models/GenericTypes";
import { GetAllGroups } from "../services/GroupsService";

const activeGroupsLocalSettingName = 'ActiveGroups';

export default function useActiveGroups() {
    const [groups, setGroups] = useState<NameIdObj[]>([]);
    useEffect(() => {
        const getData = async () => {
            const cachedGroups = localStorage.getItem(activeGroupsLocalSettingName);
            if (cachedGroups) setGroups(JSON.parse(cachedGroups));
            else {
                const res = await GetAllGroups();
                if (res) {
                    const activeGroups = res.filter(g => g.active);
                    const data: NameIdObj[] = activeGroups.map(u => ({ id: u.id, name: u.name }));
                    localStorage.setItem(activeGroupsLocalSettingName, JSON.stringify(data));
                    setGroups(data);
                }
            }
        };

        getData();
    }, []);

    return groups;
}