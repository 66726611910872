import { ErrorHandler, RequestOptions } from "../common/models/GenericTypes";
import { SavedSearch } from "../common/models/SavedSearches";
import { Delete, Get, Put } from "../util/HttpUtils";

export async function GetSavedSearchById(ssid: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<SavedSearch>(`api/SavedSearches/${ssid}`, options);
}

export async function DeleteSavedSearch(id: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Delete<boolean>(`api/SavedSearches/${id}`, options);
}

export async function UpdateSavedSearch(id: number, data: SavedSearch, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/SavedSearches/${id}`, options, data);
}