import React, { useMemo } from "react";
import Typography from '@mui/material/Typography';
import Box from "@mui/material/Box";
import { PREVIEW_EMAIL_RENDERER, PREVIEW_EXTERNAL_LINK_RENDERER, PREVIEW_LINK_RENDERER, PREVIEW_PHONE_RENDERER } from "util/Definitions/Constants/Previews";
import moment from "moment";
import { PreviewEntityType } from "common/models/Previews/Previews";
import InfoButtonToolTip from "../InfoButtonToolTip";

type CommonProps = {
    fieldTitle: string,
    placeholder?: string,
    previewType?: PreviewEntityType,
    isMultiLine?: boolean,
    previewRecordId?: number,
    disableTextSelection?: boolean,
    useEllipsisForLongValues?: boolean,
    helpInfo?: React.ReactNode,
    labelWidthPercent?: number,
    fontSize?: string,
    openPreviewHandler?: (type: PreviewEntityType, recordId: number) => void,
    closePreviewHandler?: () => void
}

type ConditionalProps =
    | {
        format: 'custom'
        fieldValue?: never,
        href?: never,
        showTrailingDecimals?: never,
        action?: never
    }
    | {
        fieldValue: string | null,
        format?: 'string' | 'date' | 'datetime' | 'time'
        href?: never,
        showTrailingDecimals?: never,
        action?: never
    }
    | {
        fieldValue: string,
        format: 'action',
        href?: never,
        showTrailingDecimals?: never,
        action: () => void
    }
    | {
        fieldValue: string,
        format: 'internal-link' | 'external-link' | 'phone-link' | 'email-link',
        showTrailingDecimals?: never,
        href: string,
        action?: never
    }
    | {
        fieldValue: number,
        format: 'number',
        showTrailingDecimals?: boolean,
        href?: never,
        action?: never
    };

type Props = CommonProps & ConditionalProps;

const formatNumber = (value: number) => {
    return value.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 });
};

export default function SingleFieldElement({ 
    fieldTitle, fieldValue, href, format = 'string', placeholder = '', isMultiLine, previewType, previewRecordId, disableTextSelection = false, children,
    useEllipsisForLongValues = false, helpInfo, showTrailingDecimals, labelWidthPercent = 35, fontSize = "1.2rem",
    action, openPreviewHandler, closePreviewHandler
}: React.PropsWithChildren<Props>) {
    const value = useMemo(() => {
        if (!fieldValue && format !== 'number' && format !== 'custom') return placeholder;
        if (fieldValue && (format === 'date' || format === 'datetime') && (fieldValue === '0001-01-01T00:00:00')) return placeholder;
        switch (format) {
            case 'string':
                if (isMultiLine) return fieldValue;
                else if (useEllipsisForLongValues) {
                    return (
                        <Box height="100%" width="100%" display="flex" alignItems="center">
                            <Box overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap" title={fieldValue?.toString()}>{fieldValue}</Box>
                        </Box>
                    );
                }
                return fieldValue;
            case 'number': 
                if (showTrailingDecimals) return formatNumber(fieldValue);
                return fieldValue;
            case 'date':
                const md = moment(fieldValue);
                if (md.isValid()) return md.format('DD MMM YYYY');
                return placeholder;
            case 'datetime':
                const mdt = moment(fieldValue);
                if (mdt.isValid()) return mdt.format('DD MMM YYYY h:mm A');
                return placeholder;
            case 'time':
                const t = moment(fieldValue);
                if (t.isValid()) return t.format('h:mm A');
                return placeholder;
            case 'internal-link': return (
                <Box
                    component="span"
                    color="primary.main"
                    onMouseEnter={ previewType && previewRecordId && openPreviewHandler ? () => openPreviewHandler(previewType, previewRecordId) : undefined }
                    onMouseLeave={ previewType && previewRecordId && closePreviewHandler ? () => closePreviewHandler() : undefined }
                >{PREVIEW_LINK_RENDERER(href ?? '', fieldValue, undefined, 'none')}</Box>
            );
            case 'external-link': return <Box component="span" color="primary.main">{PREVIEW_EXTERNAL_LINK_RENDERER(href ?? '', fieldValue, '_blank', 'none')}</Box>;
            case 'phone-link': return <Box component="span" color="primary.main">{PREVIEW_PHONE_RENDERER(fieldValue ?? '', href)}</Box>;
            case 'email-link': return <Box component="span" color="primary.main">{PREVIEW_EMAIL_RENDERER(fieldValue ? fieldValue.toString() : '')}</Box>;
            case 'custom' : return children;
            case 'action': return <Box onClick={action} component="span" color="primary.main" sx={{ cursor: 'pointer' }}>{fieldValue}</Box>;
            default: return fieldValue;
        }
    }, [format, placeholder, fieldValue, isMultiLine, href, children, useEllipsisForLongValues, action, previewType, previewRecordId, showTrailingDecimals, openPreviewHandler, closePreviewHandler]);

    const isMultilineText = useMemo(() => format === 'string' && isMultiLine, [format, isMultiLine]);

    if (fieldTitle.length > 22) {
        return (
            <Box display="flex" flexDirection="column" minHeight="40px" height="100%" px="5px">
                <Box mb="5px">
                    <Typography
                        height="100%"
                        display="flex"
                        alignItems="center"
                        component="div"
                        variant="body1"
                        fontWeight={600}
                        sx={ disableTextSelection ? undefined : { userSelect: 'text' }}
                    >
                        {fieldTitle}
                        {Boolean(helpInfo) && <InfoButtonToolTip text={helpInfo} />}
                    </Typography>
                </Box>
                <Typography
                    display="flex"
                    overflow={isMultilineText ? "auto" : undefined}
                    alignItems={isMultilineText ? undefined : "center"}
                    component="div"
                    variant="body1"
                    sx={ disableTextSelection ? undefined : { userSelect: 'text' }}
                >{value}</Typography>
            </Box>
        );
    }

    return (
        <Box display="flex" height="100%" px="5px">
            <Box minHeight="40px" height="100%" width={`${labelWidthPercent}%`}>
                <Typography
                    height="100%"
                    display="flex"
                    alignItems="center"
                    component="div"
                    variant="body1"
                    fontWeight={600}
                    fontSize={fontSize}
                    sx={ disableTextSelection ? undefined : { userSelect: 'text' }}
                >
                    {fieldTitle}
                    {Boolean(helpInfo) && <InfoButtonToolTip text={helpInfo} />}
                </Typography>
            </Box>
            <Typography
                height="100%"
                width={`${100 - labelWidthPercent}%`}
                display="flex"
                overflow={isMultilineText ? "auto" : undefined}
                alignItems={isMultilineText ? undefined : "center"}
                component="div"
                variant="body1"
                fontSize={fontSize}
                sx={ disableTextSelection ? undefined : { userSelect: 'text' }}
            >{value}</Typography>
        </Box>
    );
}