import React, { useCallback, useEffect, useMemo, useState } from "react";
import PageLayout from '../../layouts/PageLayout';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { companyHasSinglePermission, userHasSinglePermission } from "util/PermissionsUtils";
import { Permission } from "common/models/Permissions";
import PageContentLayout from "layouts/PageContentLayout";
import { useNavigate, useSearchParams } from "react-router-dom";
import JobsGridComponent from "components/Grids/JobsGrid";
import Switch from "@mui/material/Switch";
import { GetSettingBySettingName, UpdateSettingBySettingName } from "services/UsersService";
import ActivitiesGridComponent from "components/Grids/ActivitiesGrid";
import Snackbar from "@mui/material/Snackbar";
import Alert from "components/Alert";
import JobsKanbanComponent from "components/Kanbans/JobsKanban";
import MeetingsGridComponent from "components/Grids/MeetingsGrid";
import OpportunitiesGridComponent from "components/Grids/OpportunitiesGrid";
import AdvertsGridComponent from "components/Grids/AdvertsGrid";
import PlacementsGridComponent from "components/Grids/PlacementsGrid";
import MainDashboardLayout from "components/Dashboards/MainDashboardLayout";
import Box from "@mui/material/Box";
import TimezoneVerificationDialog from "components/Dialogs/TimezoneVerificationDialog";

const defaultJobViewSettingName = 'DefaultJobsViewDashboard';

export default function Dashboard()  {
    const [activeTab, setActiveTab] = useState('DASHBOARD_Dashboard');
    const [jobsView, setJobsView] = useState<'list' | 'kanban'>('kanban');
    const [isLoading, setIsLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoadingTab, setIsLoadingTab] = useState(false);
    const [isTimeZoneLoading, setIsTimeZoneLoading] = useState(false);
    const [searchParams] = useSearchParams();
    const [tabAction, setTabAction] = useState<JSX.Element>();
    const [isFinishedSetup, setIsFinishedSetup] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'Dashboard | Recruit Wizard';
    }, []);

    useEffect(() => {
        const tabParam = searchParams.get('tab');
        const getData = async () => {
            setIsLoading(true);
            const defaultJobView = await GetSettingBySettingName(defaultJobViewSettingName);
            if (defaultJobView) setJobsView(defaultJobView === 'list' ? 'list' : 'kanban');
            if (!tabParam) {
                const tabSetting = await GetSettingBySettingName('DefaultTabDashboard');
                if (tabSetting) setActiveTab(tabSetting);
            }
            else setActiveTab(tabParam)
            setIsFinishedSetup(true);
            setIsLoading(false);
        };
        getData();
    }, [searchParams]);

    const isActivitiesAvailable = useMemo(() => userHasSinglePermission(Permission.Activities) && companyHasSinglePermission(Permission.Activities), []);
    const isJobsAvailable = useMemo(() => userHasSinglePermission(Permission.Jobs) && companyHasSinglePermission(Permission.Jobs), []);
    const isJobsRosterAvailable = useMemo(() => false, []);
    const isAdvertsAvailable = useMemo(() => userHasSinglePermission(Permission.Adverts) && companyHasSinglePermission(Permission.Adverts), []);
    const isPipelineAvailable = useMemo(() => isJobsAvailable && userHasSinglePermission(Permission.Candidates) && companyHasSinglePermission(Permission.Candidates), [isJobsAvailable]);
    const isContractorsAvailable = useMemo(() => userHasSinglePermission(Permission.Placements) && companyHasSinglePermission(Permission.Placements), []);
    const isOpportunitiesAvailable = useMemo(() => userHasSinglePermission(Permission.Opportunities) && companyHasSinglePermission(Permission.Opportunities), []);

    const handleTabChange = (e: React.SyntheticEvent, newValue: string) => {
        navigate(`/dashboard?tab=${newValue}`);
    }

    const jobsViewChangeHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = e.target.checked;
        const value = isChecked ? 'list' : 'kanban';
        setJobsView( value );
        UpdateSettingBySettingName(defaultJobViewSettingName, value);
    }, []);

    const openJobsViewSwitch = useMemo(() => (
        <span>
            <span>Kanban</span>
            <Switch checked={jobsView === 'list'} onChange={ jobsViewChangeHandler } />
            <span>List</span>
        </span>
    ), [jobsView, jobsViewChangeHandler]);

    useEffect(() => {
        if (tabAction && activeTab !== 'DASHBOARD_Dashboard') {
            setTabAction(undefined);
        }
    }, [activeTab, tabAction]);

    return (
        <PageLayout paddingTop={0}>
            <Snackbar open={successMessage !== ''} autoHideDuration={3000} onClose={() => setSuccessMessage('')}>
                <Alert onClose={() => setSuccessMessage('')}>{ successMessage }</Alert>
            </Snackbar>
            <Snackbar open={errorMessage !== ''} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <TimezoneVerificationDialog loadingHandler={setIsTimeZoneLoading} />
            <Box display="flex">
                <Tabs value={activeTab} onChange={ handleTabChange } sx={{ flexGrow: 1 }}>
                    <Tab value="DASHBOARD_Dashboard" label="Home" />
                    { isJobsAvailable && <Tab value="DASHBOARD_Jobs" label="Open Jobs" /> }
                    { isJobsRosterAvailable && <Tab value="DASHBOARD_Jobs_CG" label="Jobs Roster" /> }
                    { isActivitiesAvailable && <Tab value="DASHBOARD_Activities" label="Open Activity" /> }
                    { isActivitiesAvailable && <Tab value="DASHBOARD_Meetings" label="Open Meetings" /> }
                    { isOpportunitiesAvailable && <Tab value="DASHBOARD_opportunities" label="Open Opportunities" /> }
                    { isAdvertsAvailable && <Tab value="DASHBOARD_adverts" label="Live Adverts" /> }
                    { isContractorsAvailable && <Tab value="DASHBOARD_contractors" label="Contractors" /> }
                </Tabs>
                <Box alignSelf="center">
                    {tabAction}
                </Box>
            </Box>
            <PageContentLayout showLoading={isLoading || isLoadingTab || isTimeZoneLoading}>
                { isFinishedSetup && activeTab === "DASHBOARD_Dashboard" &&
                    <MainDashboardLayout loadingHandler={setIsLoadingTab} errorHandler={setErrorMessage} setActionHandler={setTabAction} />
                }
                { isFinishedSetup && isJobsAvailable && activeTab === "DASHBOARD_Jobs" &&
                    <>
                        {jobsView === 'kanban' &&
                            <>
                                {openJobsViewSwitch}
                                <JobsKanbanComponent source="dashboard" loadingHandler={setIsLoadingTab} />
                            </>
                        }
                        {jobsView === 'list' &&
                            <JobsGridComponent
                                source="dashboard"
                                jobState={1}
                                loadingHandler={setIsLoadingTab}
                                extraActions={openJobsViewSwitch}
                                gridName="dashboard/Jobs"
                            />
                        }
                    </>
                }
                { isFinishedSetup && isJobsRosterAvailable && activeTab === "DASHBOARD_Jobs_CG" &&
                    <div>Jobs_CG Content</div>
                }
                { isFinishedSetup && isActivitiesAvailable && activeTab === "DASHBOARD_Activities" &&
                    <ActivitiesGridComponent
                        source="dashboard"
                        loadingHandler={ setIsLoadingTab }
                        errorHandler={ setErrorMessage }
                        successHandler={ setSuccessMessage }
                        checkboxSelection
                        gridName="dashboard/Activities"
                    />
                }
                { isFinishedSetup && isActivitiesAvailable && activeTab === "DASHBOARD_Meetings" &&
                    <MeetingsGridComponent
                        source="dashboard"
                        loadingHandler={setIsLoadingTab}
                        errorHandler={setErrorMessage}
                        successHandler={setSuccessMessage}
                        gridName="dashboard/Meetings"
                    />
                }
                { isFinishedSetup && isOpportunitiesAvailable && activeTab === "DASHBOARD_opportunities" &&
                    <OpportunitiesGridComponent
                        source="dashboard"
                        loadingHandler={setIsLoadingTab}
                        errorHandler={setErrorMessage}
                        successHandler={setSuccessMessage}
                        gridName="dashboard/Opportunities"
                    />
                }
                { isFinishedSetup && isAdvertsAvailable && activeTab === "DASHBOARD_adverts" &&
                    <AdvertsGridComponent
                        loadingHandler={setIsLoadingTab}
                        errorHandler={setErrorMessage}
                        successHandler={setSuccessMessage}
                        gridName="dashboard/Adverts"
                        source='dashboard'
                    />
                }
                { isFinishedSetup && isContractorsAvailable && activeTab === "DASHBOARD_contractors" &&
                    <PlacementsGridComponent
                        source="dashboard"
                        loadingHandler={setIsLoadingTab}
                        errorHandler={setErrorMessage}
                        successHandler={setSuccessMessage}
                        gridName="dashboard/Contractors"
                    />
                }
            </PageContentLayout>
        </PageLayout>
    );
}