import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import DocumentTemplatePicker from "components/Pickers/DocumentTemplatePicker";
import { DocumentTemplate } from "common/models/Templates/DocumentTemplate";
import { GetDocumentTemplatePlaceholdersById, GetDocumentTemplatesPlaceholders } from "services/TemplatesService";
import { TemplatePlaceholder } from "common/models/Templates/TemplatePlaceholder";
import { GenerateFormattedCv, GetEntityDocumentPlaceholderValues } from "services/DocumentGenerationService";
import TextField from "@mui/material/TextField";
import { DownloadDocument } from "services/DocumentsService";
import { GenerateEsignRequest, GetSignersDisplayData } from "services/ESignService";
import { SignersStatus } from "common/models/ESigning";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import MenuItem from "@mui/material/MenuItem";

interface Props {
    open: boolean,
    candidateId: number,
    jobId?: number,
    applicationId?: number,
    closeHandler: () => void,
    onDocumentCreatedHandler?: (docId: number) => void,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
    successHandler?: (message: string) => void,
}

interface PlaceholderField {
    placeholder: string,
    placeholderUsage: string,
    friendlyName: string,
    isEditable: boolean,
    value: string
}

const candidateEntityId = 3;
const jobEntityId = 4;

export default function GenerateFormattedCvDialog({ open, candidateId, jobId = 0, applicationId = 0, closeHandler, onDocumentCreatedHandler, loadingHandler, successHandler, errorHandler }: Props) {
    const [step, setStep] = useState(1);
    const [resumeTypeId, setResumeTypeId] = useState(1);
    const [selectedTemplate, setSelectedTemplate] = useState<DocumentTemplate | null>(null);
    const [fields, setFields] = useState<PlaceholderField[]>([]);
    const [allAvailablePlaceholders, setAllAvailablePlaceholders] = useState<TemplatePlaceholder[]>([]);
    const [generatedDocumentId, setGeneratedDocumentId] = useState(0);
    const [signersDisplayData, setSignersDisplayData] = useState<SignersStatus[]>([]);
    const [areAllSignersOk, setAreAllSignersOk] = useState(false);
    const [isESignTest, setIsESignTest] = useState(false);

    const templateTypeFilters = useMemo<number[]>(() => {
        return [1];
    }, []);

    useEffect(() => {
        const getData = async () => {
            const res = await GetDocumentTemplatesPlaceholders();
            if (res) setAllAvailablePlaceholders(res);
        };
        getData();
    }, []);

    useEffect(() => {
        if (open) {
            setStep(1);
            setSelectedTemplate(null);
            setFields([]);
            setGeneratedDocumentId(0);
            setSignersDisplayData([]);
            setAreAllSignersOk(false);
            setIsESignTest(false);
        }
    }, [open]);

    const getPlaceholders = useCallback(async () => {
        if (selectedTemplate) {
            loadingHandler && loadingHandler(true);
            let values: TemplatePlaceholder[] = [];
            const cValues = await GetEntityDocumentPlaceholderValues(candidateEntityId, candidateId, errorHandler);
            if (cValues) values = [...cValues];

            if (jobId) {
                const jValues = await GetEntityDocumentPlaceholderValues(jobEntityId, jobId, errorHandler);
                if (jValues) values = [...values, ...jValues];
            }

            const placeholders = await GetDocumentTemplatePlaceholdersById(selectedTemplate.id);

            let f: PlaceholderField[] = [];
            if (values && placeholders) {
                for (let i = 0; i < placeholders.length; i++) {
                    const minP = placeholders[i];
                    const val = values.find(v => v.placeholder === minP.placeholder);
                    if (val) f.push({ ...val, isEditable: minP.isEditable });
                    else {
                        const availableP = allAvailablePlaceholders.find(p => p.placeholder === minP.placeholder);
                        if (availableP) f.push({...availableP, value: '', isEditable: minP.isEditable});
                    }
                }
            }

            setFields(f);
            setStep(prev => prev + 1);
            loadingHandler && loadingHandler(false);
        }
    }, [selectedTemplate, loadingHandler, candidateId, errorHandler, jobId, allAvailablePlaceholders]);

    const generateDocument = useCallback(async () => {
        if (selectedTemplate) {
            loadingHandler && loadingHandler(true);
            const placeholders = fields.map(f => ({ placeholder: f.placeholder, value: f.value ?? '' }));
            const res = await GenerateFormattedCv(candidateId, selectedTemplate.id, resumeTypeId, jobId, applicationId, placeholders, errorHandler);
            if (res) {
                successHandler && successHandler('Document Generated');
                setGeneratedDocumentId(res.documentId);
                onDocumentCreatedHandler && onDocumentCreatedHandler(res.documentId);
                setStep(prev => prev + 1);
            }
            loadingHandler && loadingHandler(false);
        }
    }, [selectedTemplate, loadingHandler, fields, candidateId, resumeTypeId, jobId, applicationId, errorHandler, successHandler, onDocumentCreatedHandler]);

    const sendDocumentForESign = useCallback(async () => {
        if (selectedTemplate) {
            loadingHandler && loadingHandler(true);
            const res = await GenerateEsignRequest(candidateEntityId, candidateId, selectedTemplate.id, generatedDocumentId, isESignTest, errorHandler);
            if (res) {
                successHandler && successHandler('Document sent for eSigning');
                closeHandler();
            }
            loadingHandler && loadingHandler(false);
        }
    }, [candidateId, selectedTemplate, generatedDocumentId, isESignTest, closeHandler, loadingHandler, successHandler, errorHandler]);

    const isNextEnabled = useMemo(() => {
        if (step === 1 && Boolean(selectedTemplate)) return true;
        if (step === 2) return true;
        if (step === 3 && Boolean(generatedDocumentId)) return true;
        if (step === 4 && areAllSignersOk) return true;
        return false;
    }, [step, selectedTemplate, areAllSignersOk, generatedDocumentId]);

    const dialogTitle = useMemo(() => {
        if (step === 1) return 'Select Template';
        if (step === 2) return 'Enter Field Data';
        if (step === 3) return 'Document Generated';
        if (step === 4) return 'eSign Status';
        return '';
    }, [step]);

    const nextBtnText = useMemo(() => {
        if (step === 2) return 'Create';
        if (step === 3) return 'Finish';
        if (step === 4) return 'Send for eSigning'
        return 'Next';
    }, [step]);

    const nextCallback = useCallback(() => {
        if (step === 1) getPlaceholders();
        else if (step === 2) generateDocument();
        else if (step === 3) closeHandler();
        else if (step === 4) sendDocumentForESign();
    }, [step, getPlaceholders, generateDocument, closeHandler, sendDocumentForESign]);

    const backCallback = useCallback(() => {
        if (step > 1) setStep(prev => prev -1);
    }, [step]);

    const onFieldChange = useCallback((index: number, value: string) => {
        setFields(prev => {
            let tmp = [...prev];
            tmp[index].value = value;
            return tmp;
        })
    }, []);

    const downloadFileCallback = useCallback(async () => {
        if (generatedDocumentId > 0) {
            loadingHandler && loadingHandler(true);
            await DownloadDocument(generatedDocumentId, undefined, errorHandler);
            loadingHandler && loadingHandler(false);
        }
    }, [generatedDocumentId, loadingHandler, errorHandler]);

    const eSignCallback = useCallback(async (isTest: boolean) => {
        if (selectedTemplate && selectedTemplate.isElectronicSigningEnabled) {
            loadingHandler && loadingHandler(true);
            const res = await GetSignersDisplayData(candidateEntityId, candidateId, selectedTemplate.id, errorHandler);
            if (res) {
                let isAllOk = true;
                const notOkRow = res.find(r => r.status !== 'OK');
                if (notOkRow) isAllOk = false;
                setSignersDisplayData(res);
                setAreAllSignersOk(isAllOk);
                setIsESignTest(isTest);
            }
            loadingHandler && loadingHandler(false);
            setStep(4);
        }
    }, [candidateId, selectedTemplate, errorHandler, loadingHandler]);

    return (
        <Dialog open={open} fullWidth>
            <DialogTitle>{dialogTitle}</DialogTitle>
            <DialogContent>
                <Stack spacing={2} mt="10px" display={step === 1 ? undefined : 'none'}>
                    <TextField select label="Resume Type" value={resumeTypeId.toString()} onChange={({target}) => setResumeTypeId(+target.value)}>
                        <MenuItem value="0">None</MenuItem>
                        <MenuItem value="1">Latest</MenuItem>
                        <MenuItem value="2">Application</MenuItem>
                    </TextField>
                    <DocumentTemplatePicker label="Template" onSelectHandler={t => setSelectedTemplate(t)} typesFilter={templateTypeFilters} statusFilter={1} />
                </Stack>
                <Stack spacing={2} mt="10px" display={step === 2 ? undefined : 'none'}>
                    {fields.map((f, i) => ( 
                        <TextField
                            key={i}
                            label={f.friendlyName}
                            value={f.value ?? ''}
                            onChange={ ({target}) => onFieldChange(i, target.value) }
                            InputProps={{ readOnly: !f.isEditable }}
                        />
                    ))}
                </Stack>
                <Stack spacing={2} mt="10px" display={step === 3 ? undefined : 'none'}>
                    <Button disabled={generatedDocumentId === 0} onClick={downloadFileCallback}>Download File</Button>
                    { selectedTemplate && selectedTemplate.isElectronicSigningEnabled && <Button onClick={() => eSignCallback(false)}>Send for Electronic Signing</Button> }
                    {/*{ selectedTemplate && selectedTemplate.isElectronicSigningEnabled && <Button onClick={() => eSignCallback(true)}>Send for Electronic Signing (TEST)</Button> }*/}
                </Stack>
                <Stack spacing={2} mt="10px" display={step === 4 ? undefined : 'none'}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell>Type</TableCell>
                                <TableCell>Value</TableCell>
                                <TableCell>Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {signersDisplayData.map((s, i) => (
                                <TableRow key={i}>
                                    <TableCell>Signer {i + 1}</TableCell>
                                    <TableCell>{s.type}</TableCell>
                                    <TableCell>{s.value}</TableCell>
                                    <TableCell>{s.status}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="error" onClick={closeHandler} >Cancel</Button>
                <Button variant="contained" disabled={step === 1} onClick={backCallback}>Back</Button>
                <Button variant="contained" color="success" disabled={!isNextEnabled} onClick={nextCallback}>{nextBtnText}</Button>
            </DialogActions>
        </Dialog>
    );
}