import { PanelModel } from "@syncfusion/ej2-layouts/src/dashboard-layout/dashboard-layout-model";
import { JobRecordDashboardElementDefinition, JobRecordDashboardElementType } from "common/models/Dashboard/EditLayout";
import { ScreenLayoutSettings } from "common/models/ScreenLayouts/ScreenLayouts";
import { GetCustomFieldTypeById } from "./CustomFields";

export const DefaultJobRecordDashboardElements_Permanent: JobRecordDashboardElementDefinition[] = [
    { id: "JobTitle_Default", row: 0, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobTitle' },
    { id: "JobType_Default", row: 1, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobType' },
    { id: "JobWorkType_Default", row: 2, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobWorkType' },
    { id: "JobQuantity_Default", row: 3, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobQuantity' },
    { id: "JobExclusive_Default", row: 4, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobExclusive' },
    { id: "JobStartDate_Default", row: 5, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobStartDate' },
    { id: "JobHoursPerDay_Default", row: 6, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobHoursPerDay' },
    { id: "JobDaysPerWeek_Default", row: 7, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobDaysPerWeek' },
    { id: "Spacer_Default", row: 8, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'Spacer' },
    { id: "Divider_Default1", row: 9, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'Divider' },
    { id: "JobSalaryPackage_Default", row: 10, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobSalaryPackage' },
    { id: "JobFee_Default", row: 11, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobFee' },
    { id: "JobComments_Default", row: 12, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobComments' },
    
    { id: "JobLocation_Default", row: 0, col: 1, sizeX: 1, sizeY: 9, minSizeX: 1, minSizeY: 9, maxSizeX: 1, maxSizeY: 9, type: 'JobLocation' },
    { id: "Divider_Default21", row: 9, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'Divider' },
    { id: "JobClient_Default", row: 10, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobClient' },
    { id: "JobContact_Default", row: 11, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobContact' },
    { id: "JobHiringManager_Default", row: 12, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobHiringManager' },
    { id: "JobBilling1_Default", row: 13, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobBilling1' },
    { id: "JobClientRef_Default", row: 14, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobClientRef' },
    { id: "Divider_Default22", row: 15, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'Divider' },
    { id: "JobVideoLink_Default", row: 16, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobVideoLink' },
    
    { id: "JobSummary_Default", row: 0, col: 2, sizeX: 1, sizeY: 9, minSizeX: 1, minSizeY: 3, maxSizeX: 1, maxSizeY: null, type: 'JobSummary' },
    { id: "Divider_Default31", row: 9, col: 2, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'Divider' },
    { id: "JobStatus_Default", row: 10, col: 2, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobStatus' },
    { id: "JobStage_Default", row: 11, col: 2, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobStage' },
    { id: "JobOutcome_Default", row: 12, col: 2, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobOutcome' },
    { id: "JobSource_Default", row: 13, col: 2, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobSource' },
    { id: "JobRating_Default", row: 14, col: 2, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobRating' },
    { id: "Divider_Default32", row: 15, col: 2, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'Divider' },
    { id: "JobConsultant1_Default", row: 16, col: 2, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobConsultant1' },
    { id: "JobConsultant2_Default", row: 17, col: 2, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobConsultant2' },
];
export const DefaultJobRecordDashboardElements_Contract: JobRecordDashboardElementDefinition[] = [
    { id: "JobTitle_Default", row: 0, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobTitle' },
    { id: "JobType_Default", row: 1, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobType' },
    { id: "JobWorkType_Default", row: 2, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobWorkType' },
    { id: "JobQuantity_Default", row: 3, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobQuantity' },
    { id: "JobExclusive_Default", row: 4, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobExclusive' },
    { id: "JobStartDate_Default", row: 5, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobStartDate' },
    { id: "JobDuration_Default", row: 6, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobDuration' },
    { id: "JobHoursPerDay_Default", row: 7, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobHoursPerDay' },
    { id: "JobDaysPerWeek_Default", row: 8, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobDaysPerWeek' },
    { id: "Divider_Default1", row: 9, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'Divider' },
    { id: "JobChargeRate_Default", row: 10, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobChargeRate' },
    { id: "JobPayRate_Default", row: 11, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobPayRate' },
    { id: "JobOnCosts_Default", row: 12, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobOnCosts' },
    { id: "JobComments_Default", row: 13, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobComments' },
    
    { id: "JobLocation_Default", row: 0, col: 1, sizeX: 1, sizeY: 9, minSizeX: 1, minSizeY: 9, maxSizeX: 1, maxSizeY: 9, type: 'JobLocation' },
    { id: "Divider_Default21", row: 9, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'Divider' },
    { id: "JobClient_Default", row: 10, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobClient' },
    { id: "JobContact_Default", row: 11, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobContact' },
    { id: "JobHiringManager_Default", row: 12, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobHiringManager' },
    { id: "JobBilling1_Default", row: 13, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobBilling1' },
    { id: "JobClientRef_Default", row: 14, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobClientRef' },
    { id: "Divider_Default22", row: 15, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'Divider' },
    { id: "JobVideoLink_Default", row: 16, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobVideoLink' },
    
    { id: "JobSummary_Default", row: 0, col: 2, sizeX: 1, sizeY: 9, minSizeX: 1, minSizeY: 3, maxSizeX: 1, maxSizeY: null, type: 'JobSummary' },
    { id: "Divider_Default31", row: 9, col: 2, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'Divider' },
    { id: "JobStatus_Default", row: 10, col: 2, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobStatus' },
    { id: "JobStage_Default", row: 11, col: 2, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobStage' },
    { id: "JobOutcome_Default", row: 12, col: 2, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobOutcome' },
    { id: "JobSource_Default", row: 13, col: 2, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobSource' },
    { id: "JobRating_Default", row: 14, col: 2, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobRating' },
    { id: "Divider_Default32", row: 15, col: 2, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'Divider' },
    { id: "JobConsultant1_Default", row: 16, col: 2, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobConsultant1' },
    { id: "JobConsultant2_Default", row: 17, col: 2, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobConsultant2' },
];
export const DefaultJobRecordDashboardElements_FixedContract: JobRecordDashboardElementDefinition[] = [
    { id: "JobTitle_Default", row: 0, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobTitle' },
    { id: "JobType_Default", row: 1, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobType' },
    { id: "JobWorkType_Default", row: 2, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobWorkType' },
    { id: "JobQuantity_Default", row: 3, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobQuantity' },
    { id: "JobExclusive_Default", row: 4, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobExclusive' },
    { id: "JobStartDate_Default", row: 5, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobStartDate' },
    { id: "JobDuration_Default", row: 6, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobDuration' },
    { id: "JobHoursPerDay_Default", row: 7, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobHoursPerDay' },
    { id: "JobDaysPerWeek_Default", row: 8, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobDaysPerWeek' },
    { id: "Divider_Default1", row: 9, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'Divider' },
    { id: "JobSalaryPackage_Default", row: 10, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobSalaryPackage' },
    { id: "JobFee_Default", row: 11, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobFee' },
    { id: "JobComments_Default", row: 12, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobComments' },
    
    { id: "JobLocation_Default", row: 0, col: 1, sizeX: 1, sizeY: 9, minSizeX: 1, minSizeY: 9, maxSizeX: 1, maxSizeY: 9, type: 'JobLocation' },
    { id: "Divider_Default21", row: 9, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'Divider' },
    { id: "JobClient_Default", row: 10, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobClient' },
    { id: "JobContact_Default", row: 11, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobContact' },
    { id: "JobHiringManager_Default", row: 12, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobHiringManager' },
    { id: "JobBilling1_Default", row: 13, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobBilling1' },
    { id: "JobClientRef_Default", row: 14, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobClientRef' },
    { id: "Divider_Default22", row: 15, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'Divider' },
    { id: "JobVideoLink_Default", row: 16, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobVideoLink' },
    
    { id: "JobSummary_Default", row: 0, col: 2, sizeX: 1, sizeY: 9, minSizeX: 1, minSizeY: 3, maxSizeX: 1, maxSizeY: null, type: 'JobSummary' },
    { id: "Divider_Default31", row: 9, col: 2, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'Divider' },
    { id: "JobStatus_Default", row: 10, col: 2, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobStatus' },
    { id: "JobStage_Default", row: 11, col: 2, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobStage' },
    { id: "JobOutcome_Default", row: 12, col: 2, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobOutcome' },
    { id: "JobSource_Default", row: 13, col: 2, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobSource' },
    { id: "JobRating_Default", row: 14, col: 2, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobRating' },
    { id: "Divider_Default32", row: 15, col: 2, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'Divider' },
    { id: "JobConsultant1_Default", row: 16, col: 2, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobConsultant1' },
    { id: "JobConsultant2_Default", row: 17, col: 2, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobConsultant2' },
];
export const DefaultJobRecordDashboardElements_TalentPool: JobRecordDashboardElementDefinition[] = [
    { id: "JobTitle_Default", row: 0, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobTitle' },
    { id: "JobType_Default", row: 1, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobType' },
    { id: "JobWorkType_Default", row: 2, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobWorkType' },
    { id: "JobQuantity_Default", row: 3, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobQuantity' },
    { id: "JobExclusive_Default", row: 4, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobExclusive' },
    { id: "JobStartDate_Default", row: 5, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobStartDate' },
    { id: "JobHoursPerDay_Default", row: 6, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobHoursPerDay' },
    { id: "JobDaysPerWeek_Default", row: 7, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobDaysPerWeek' },
    { id: "Spacer_Default", row: 8, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'Spacer' },
    { id: "Divider_Default1", row: 9, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'Divider' },
    { id: "JobComments_Default", row: 10, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobComments' },
    
    { id: "JobLocation_Default", row: 0, col: 1, sizeX: 1, sizeY: 9, minSizeX: 1, minSizeY: 9, maxSizeX: 1, maxSizeY: 9, type: 'JobLocation' },
    { id: "Divider_Default21", row: 9, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'Divider' },
    { id: "JobClient_Default", row: 10, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobClient' },
    { id: "JobContact_Default", row: 11, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobContact' },
    { id: "JobHiringManager_Default", row: 12, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobHiringManager' },
    { id: "JobBilling1_Default", row: 13, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobBilling1' },
    { id: "JobClientRef_Default", row: 14, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobClientRef' },
    { id: "Divider_Default22", row: 15, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'Divider' },
    { id: "JobVideoLink_Default", row: 16, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobVideoLink' },
    
    { id: "JobSummary_Default", row: 0, col: 2, sizeX: 1, sizeY: 9, minSizeX: 1, minSizeY: 3, maxSizeX: 1, maxSizeY: null, type: 'JobSummary' },
    { id: "Divider_Default31", row: 9, col: 2, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'Divider' },
    { id: "JobStatus_Default", row: 10, col: 2, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobStatus' },
    { id: "JobStage_Default", row: 11, col: 2, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobStage' },
    { id: "JobOutcome_Default", row: 12, col: 2, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobOutcome' },
    { id: "JobSource_Default", row: 13, col: 2, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobSource' },
    { id: "JobRating_Default", row: 14, col: 2, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobRating' },
    { id: "Divider_Default32", row: 15, col: 2, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'Divider' },
    { id: "JobConsultant1_Default", row: 16, col: 2, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobConsultant1' },
    { id: "JobConsultant2_Default", row: 17, col: 2, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobConsultant2' },
];
export const DefaultJobRecordDashboardElements_Panel: JobRecordDashboardElementDefinition[] = [
    { id: "JobTitle_Default", row: 0, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobTitle' },
    { id: "JobType_Default", row: 1, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobType' },
    { id: "JobWorkType_Default", row: 2, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobWorkType' },
    { id: "JobQuantity_Default", row: 3, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobQuantity' },
    { id: "JobExclusive_Default", row: 4, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobExclusive' },
    { id: "JobStartDate_Default", row: 5, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobStartDate' },
    { id: "JobHoursPerDay_Default", row: 6, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobHoursPerDay' },
    { id: "JobDaysPerWeek_Default", row: 7, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobDaysPerWeek' },
    { id: "Spacer_Default", row: 8, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'Spacer' },
    { id: "Divider_Default1", row: 9, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'Divider' },
    { id: "JobSalaryPackage_Default", row: 10, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobSalaryPackage' },
    { id: "JobFee_Default", row: 11, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobFee' },
    { id: "JobComments_Default", row: 12, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobComments' },
    
    { id: "JobLocation_Default", row: 0, col: 1, sizeX: 1, sizeY: 9, minSizeX: 1, minSizeY: 9, maxSizeX: 1, maxSizeY: 9, type: 'JobLocation' },
    { id: "Divider_Default21", row: 9, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'Divider' },
    { id: "JobClient_Default", row: 10, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobClient' },
    { id: "JobContact_Default", row: 11, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobContact' },
    { id: "JobHiringManager_Default", row: 12, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobHiringManager' },
    { id: "JobBilling1_Default", row: 13, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobBilling1' },
    { id: "JobClientRef_Default", row: 14, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobClientRef' },
    { id: "Divider_Default22", row: 15, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'Divider' },
    { id: "JobVideoLink_Default", row: 16, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobVideoLink' },
    
    { id: "JobSummary_Default", row: 0, col: 2, sizeX: 1, sizeY: 9, minSizeX: 1, minSizeY: 3, maxSizeX: 1, maxSizeY: null, type: 'JobSummary' },
    { id: "Divider_Default31", row: 9, col: 2, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'Divider' },
    { id: "JobStatus_Default", row: 10, col: 2, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobStatus' },
    { id: "JobStage_Default", row: 11, col: 2, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobStage' },
    { id: "JobOutcome_Default", row: 12, col: 2, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobOutcome' },
    { id: "JobSource_Default", row: 13, col: 2, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobSource' },
    { id: "JobRating_Default", row: 14, col: 2, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobRating' },
    { id: "Divider_Default32", row: 15, col: 2, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'Divider' },
    { id: "JobConsultant1_Default", row: 16, col: 2, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobConsultant1' },
    { id: "JobConsultant2_Default", row: 17, col: 2, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'JobConsultant2' },
];

export const GetMinSizeByElementType = (type: JobRecordDashboardElementType) => {
    switch (type) {
        case "JobSummary": return [1, 9];
        case "JobLocation": return [1, 9];
        default: return [1, 1];
    }
};

export const GetElementTypeById = (id: string): JobRecordDashboardElementType => {
    if ( id && id.startsWith('Divider_')) return 'Divider';
    if ( id && id.startsWith('Spacer_')) return 'Spacer';
    if (id && id.startsWith('JobId_')) return 'JobId';
    if (id && id.startsWith('JobType_')) return 'JobType';
    if (id && id.startsWith('JobWorkType_')) return 'JobWorkType';
    if (id && id.startsWith('JobTitle_')) return 'JobTitle';
    if (id && id.startsWith('JobExclusive_')) return 'JobExclusive';
    if (id && id.startsWith('JobQuantity_')) return 'JobQuantity';
    if (id && id.startsWith('JobStartDate_')) return 'JobStartDate';
    if (id && id.startsWith('JobStartTime_')) return 'JobStartTime';
    if (id && id.startsWith('JobDuration_')) return 'JobDuration';
    if (id && id.startsWith('JobDeadline_')) return 'JobDeadline';
    if (id && id.startsWith('JobDivision_')) return 'JobDivision';
    if (id && id.startsWith('JobSalaryPackage_')) return 'JobSalaryPackage';
    if (id && id.startsWith('JobFee_')) return 'JobFee';
    if (id && id.startsWith('JobChargeRate_')) return 'JobChargeRate';
    if (id && id.startsWith('JobPayRate_')) return 'JobPayRate';
    if (id && id.startsWith('JobOnCosts_')) return 'JobOnCosts';
    if (id && id.startsWith('JobComments_')) return 'JobComments';
    if (id && id.startsWith('JobCurrency_')) return 'JobCurrency';
    if (id && id.startsWith('JobSource_')) return 'JobSource';
    if (id && id.startsWith('JobLocation_')) return 'JobLocation';
    if (id && id.startsWith('JobClient_')) return 'JobClient';
    if (id && id.startsWith('JobContact_')) return 'JobContact';
    if (id && id.startsWith('JobOtherContact_')) return 'JobOtherContact';
    if (id && id.startsWith('JobHiringManager_')) return 'JobHiringManager';
    if (id && id.startsWith('JobBilling1_')) return 'JobBilling1';
    if (id && id.startsWith('JobClientRef_')) return 'JobClientRef';
    if (id && id.startsWith('JobVideoLink_')) return 'JobVideoLink';
    if (id && id.startsWith('JobRating_')) return 'JobRating';
    if (id && id.startsWith('JobSummary_')) return 'JobSummary';
    if (id && id.startsWith('JobStatus_')) return 'JobStatus';
    if (id && id.startsWith('JobStage_')) return 'JobStage';
    if (id && id.startsWith('JobOutcome_')) return 'JobOutcome';
    if (id && id.startsWith('JobHoursPerDay_')) return 'JobHoursPerDay';
    if (id && id.startsWith('JobDaysPerWeek_')) return 'JobDaysPerWeek';
    if (id && id.startsWith('JobConsultant1_')) return 'JobConsultant1';
    if (id && id.startsWith('JobConsultant2_')) return 'JobConsultant2';
    if (id && id.startsWith('JobComplianceChecklist_')) return 'JobComplianceChecklist';
    if ( id && id.startsWith('CustomField')) return GetCustomFieldTypeById(id);
    return '';
};

export const GetPanelDefinitionsFromPanelModels = (panels: PanelModel[]) => {
    let elements: JobRecordDashboardElementDefinition[] = [];
    panels.forEach((p, i) => {
        let type = GetElementTypeById(p.id ?? '');
        elements.push({
            id: p.id ?? i.toString(),
            sizeX: p.sizeX ?? 1,
            sizeY: p.sizeY ?? 1,
            col: p.col ?? 0,
            row: p.row ?? 0,
            minSizeX: p.minSizeX ?? 1,
            minSizeY: p.minSizeY ?? 1,
            maxSizeX: p.maxSizeX ?? 1,
            maxSizeY: p.maxSizeY ?? null,
            type: type
        });
    });
    return elements ;
};

export const JobScreenLayoutSettings: ScreenLayoutSettings = {
    unitWidth: 600,
    unitHeight: 40,
    gapX: 10,
    gapY: 0,
    columns: 3,
    mediaQueryMaxWidth: '1000px'
};