import React, { useCallback, useState } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import KeyboardControlKeyIcon from '@mui/icons-material/KeyboardControlKey';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DeleteIcon from '@mui/icons-material/Delete';
import RWTextFieldComponent from "components/RWTextFieldComponent";
import AddIcon from '@mui/icons-material/Add';
import { Button, Collapse } from "@mui/material";
import { ReferenceCheckTemplateCustomField, ReferenceCheckTemplateField } from "common/models/Templates/ReferenceCheckTemplate";
import ReferenceChecksCustomFields from "./ReferenceChecksCustomFields";

interface Props {
    index: number,
    isLast: boolean,
    field: ReferenceCheckTemplateField,
    onChange: (index: number, field: ReferenceCheckTemplateField) => void,
    moveUp: (index: number) => void,
    moveDown: (index: number, isLast: boolean) => void,
    onDelete: (index: number) => void
}

export default function ReferenceChecksField({ index, isLast, field, onChange, moveUp, moveDown, onDelete }: Props) {
    const [isCollapsed, setIsCollapsed] = useState(false);

    const stringChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        onChange(index, { ...field, [name]: value });
    };
    const booleanChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        onChange(index, {...field, [name]: value === '1'})
    }

    const addOptionHandler = () => {
        const defaultOption: ReferenceCheckTemplateCustomField = { Text: ''};
        if(field.options) {
            let tmp = [...field.options];
            tmp.push(defaultOption);
            onChange(index, { ...field, options: tmp });
            return;
        }

        onChange(index, { ...field, options: [defaultOption] });
    }

    const optionChangeHandler = useCallback((aIndex: number, option: ReferenceCheckTemplateCustomField) => {
        
        if(field.options) {
            let tmp = [...field.options];
            tmp[aIndex] = option;
            onChange(index, { ...field, options: tmp });
        }
        console.log(field);
    }, [field, index, onChange]);

    const optionDeleteHandler = useCallback((aIndex: number) => {
        if(field.options) {
            let tmp = [...field.options];
            tmp.splice(aIndex, 1);
            onChange(index, { ...field, options: tmp });
        }
    }, [field, index, onChange]);


    return (
        <Paper variant="outlined" sx={{ p: 2 }}>
            <Box display="flex" alignItems="center">
                <Typography component="span" paddingRight="10px"># {index + 1}</Typography>
                <IconButton size="small" disabled={index === 0} onClick={() => moveUp(index)}>
                    <ArrowUpwardIcon />
                </IconButton>
                <IconButton size="small" disabled={isLast} onClick={() => moveDown(index, isLast)}>
                    <ArrowDownwardIcon />
                </IconButton>
                <IconButton size="small" onClick={() => setIsCollapsed(prev => !prev)}>
                    { isCollapsed ?  <KeyboardArrowDownIcon /> : <KeyboardControlKeyIcon /> }
                </IconButton>

                {isCollapsed && <Typography component="span" px="10px">{field.name}</Typography> }


                <IconButton size="small" onClick={() => onDelete(index)} color="error" sx={{ marginLeft: 'auto' }}>
                    <DeleteIcon />
                </IconButton>
            </Box>
            <Collapse in={!isCollapsed}>
                <Stack spacing={2} paddingTop={2}>
                    <RWTextFieldComponent
                        label="Question"
                        name="name"
                        value={field.name}
                        onChange={stringChangeHandler}
                    />
                    <TextField
                        select
                        label="Field Type"
                        name="type"
                        value={field.type}
                        onChange={stringChangeHandler}
                    >
                        <MenuItem value="0">Text - Single line</MenuItem>
                        <MenuItem value="1">Text - Multi line</MenuItem>
                        <MenuItem value="2">Select - Single</MenuItem>
                        <MenuItem value="3">Select - Multi</MenuItem>
                        <MenuItem value="4">Rating</MenuItem>
                    </TextField>
                    <TextField
                        select
                        label="Mandatory"
                        name="mandatory"
                        value={field.mandatory ? '1' : '0'}
                        onChange={booleanChangeHandler}
                    >
                        <MenuItem value="1">Yes</MenuItem>
                        <MenuItem value="0">No</MenuItem>
                    </TextField>
                    { (+field.type > 1) &&
                        <>
                            <Box>
                                <Paper square sx={{ p: 2 }}>
                                    <Box display="flex" alignItems="center" paddingBottom={2}>
                                        <Typography component="span" paddingRight="10px">Options</Typography>
                                        <Button color="success" sx={{ marginLeft: 'auto' }} onClick={addOptionHandler} endIcon={<AddIcon />}>Add Option Value</Button>
                                    </Box>
                                    <Stack spacing={1.5}>
                                        {field.options && field.options.map((option, i) => (
                                            <ReferenceChecksCustomFields
                                                key={i}
                                                index={i}
                                                option={option}
                                                onChange={optionChangeHandler}
                                                onDelete={optionDeleteHandler}
                                            />
                                        ))}
                                    </Stack>
                                </Paper>
                            </Box>
                        </>
                    }
                </Stack>
            </Collapse>
        </Paper>
    );
}