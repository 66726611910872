import { ErrorHandler, RequestOptions } from "../common/models/GenericTypes";
import { Get } from "../util/HttpUtils";

export async function GetSmsUsageReportData(owner: number, start_date: string, end_date: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<any>(`api/misc/reports/sms-usage?owner=${owner}&startDate=${start_date}&endDate=${end_date}`, options);
}