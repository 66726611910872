import { Invoice } from "../common/models/Invoices/Invoice";
import { Item } from "../common/models/Invoices/Item";
import { TaxRate } from "../common/models/Invoices/TaxRate";
import { ErrorHandler, NumberArrayWrapper, NumberValueWrapper, RequestOptions } from "../common/models/GenericTypes";
import { DownloadBlob } from "../util/FilesUtils";
import { Get, Post, Put, Delete, DownloadFile } from "../util/HttpUtils";

export async function GetAllInvoices(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return await Get<Invoice[]>(`api/invoices/all`, options);
}

export async function GetDraftInvoices(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return await Get<Invoice[]>(`api/invoices/drafts`, options);
}

export async function GetAwaitingApprovalInvoices(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return await Get<Invoice[]>(`api/invoices/awaitingapproval`, options);
}

export async function GetAllTaxRates(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return await Get<TaxRate[]>(`api/invoices/taxrates/all`, options);
}

export async function GetActiveTaxRates(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return await Get<TaxRate[]>(`api/invoices/taxrates/active`, options);
}

export async function UpdateTaxRate(data: TaxRate, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/invoices/taxrates/${data.id}`, options, data);
}

export async function CreateTaxRate(data: TaxRate, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>(`api/invoices/taxrates`, options, data);
}

export async function GetAllItems(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return await Get<Item[]>(`api/invoices/items/all`, options);
}

export async function GetActiveItems(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return await Get<Item[]>(`api/invoices/items/active`, options);
}

export async function UpdateItem(data: Item, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/invoices/items/${data.id}`, options, data);
}

export async function CreateItem(data: Item, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>(`api/invoices/items`, options, data);
}