import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import React from "react";
import { Bar, BarChart, CartesianGrid, LabelList, ResponsiveContainer, XAxis, YAxis } from "recharts";
import { StageTotalObj } from "common/models/Analytics/BusinessIntelligence";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

interface Props {
    title: string,
    data: StageTotalObj[] | null
}

export default function BarGraphStageTotal({ title, data }: Props) {
    const t = useTheme();

    return (
        <Paper sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h6" padding={1} textAlign="center" fontWeight={600} component="div">{title}</Typography>
            {data === null && <Box m="auto"><CircularProgress /></Box> }
            <div style={{ height: 'calc(100% - 48px)', display: data === null ? 'none' : undefined }}>
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart width={150} height={150} data={data ?? []} margin={{ top: 10 }}>
                        <CartesianGrid strokeDasharray="3 3" vertical={false} />
                        <XAxis dataKey="stage" stroke={t.palette.text.primary} />
                        <YAxis stroke={t.palette.text.primary} />
                        <Bar dataKey="total" fill={t.palette.primary.main}>
                            <LabelList dataKey="total" position="top" fill={t.palette.text.primary} />
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </Paper>
    );
}