import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';

import WizCircularLogo from "./../WizCircularLogo";
import WizTopBarRoutes from "./WizTopBarRoutes";
import WizTopBarSearch from "./WizTopBarSearch";
import WizTopBarUserMenu from "./WizTopBarUserMenu";
import NavBarContainer from "./NavBar/NavBarContainer";
import CustomApplicationContext from "util/CustomApplicationContext";
import WizTopBarHelpMenu from "./WizTopBarHelpMenu";
import { WizTopBarRecentRecordsMenu } from "./WizTopBarRecenRecordsMenu";
import SettingsIcon from '@mui/icons-material/Settings';
import NotificationsIcon from '@mui/icons-material/Notifications';
import * as ConfigurationPermissions from 'common/data/Permissions/ConfigurationAccess';
import { GetServerStatus } from "services/CommonService";
import { Alert, AlertTitle, Badge, BadgeProps, Button, Popover, Tooltip, Typography, styled } from "@mui/material";
import { ServerStatus } from "common/models/Common";

export default function WizTopBar() {
    const [currentVersion, setCurrentVersion] = useState('');
    const [serverVersion, setServerVersion] = useState('');
    const [notificationCount, setNotificationCount] = useState<number>(0);
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const id = open ? 'simple-popover' : undefined;

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        if(anchorEl != null) {
            setAnchorEl(null);
            setOpen(false);
        } else{
            setAnchorEl(event.currentTarget);
            setOpen(true);
        }
    };

    const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
    };

    const updateVersions = useCallback((result: ServerStatus | null) => {
    if(result) {
        setCurrentVersion(result.informationalVersion);
        setServerVersion(result.informationalVersion);
    }
    },[]);

    useEffect(() => {
        const getCurrentVersion = async () => {
            const res = await GetServerStatus();
            if (res) {
                updateVersions(res);
            }
        };
        getCurrentVersion();
    }, [updateVersions]);

    useEffect(() => {
        const interval = setInterval(async () => {
            const res = await GetServerStatus();
            if (res) {
                setServerVersion(res.informationalVersion);
                if(currentVersion === serverVersion) {
                    setNotificationCount(0);
                }else{
                    setNotificationCount(1);
                }
            }
        }, 30 * 1000);
        return () => clearInterval(interval);
    }, [currentVersion,serverVersion,notificationCount]);

    const StyledBadge = styled(Badge)<BadgeProps>(() => ({
        '& .MuiBadge-badge': {
          top: 0,
          border: `2px solid #5E21D0; background:red;`,
          padding: '0 4px',
        },
      }));
    return (
        <>
            <AppBar position="fixed">
                <Toolbar id="recruitWizardAppBar" disableGutters variant="dense" sx={{ px: '12px' }}>
                    <IconButton onClick={() => navigate('/dashboard')}>
                        <WizCircularLogo />
                    </IconButton>
                    <Box sx={{ flexGrow: 1, height: '48px', display: { xs: 'none', lg: 'flex' } }}>
                        <NavBarContainer />
                    </Box>

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', lg: 'none' } }}>
                        <WizTopBarRoutes type="menu" />
                    </Box>

                    <Box sx={{ flexGrow: 0 }}>
                        <WizTopBarSearch />
                    </Box>

                    <Box flexGrow={0} ml="10px">
                        <WizTopBarRecentRecordsMenu />
                        { ConfigurationPermissions.AllowConfigurationModule() && <IconButton title="Configuration" color="inherit" onClick={() => navigate('/configuration')}>
                            <SettingsIcon />
                        </IconButton> }
                        <WizTopBarHelpMenu />
                        {
                            <>
                            <Tooltip title="Click to see your notifications">
                            <IconButton aria-label="Notifications"  onClick={handleClick}>
                                <StyledBadge badgeContent={notificationCount} style={{color: 'white',zIndex: '1000'}} >
                                    <NotificationsIcon style={{color: 'white'}}/>
                                </StyledBadge>
                            </IconButton>
                            </Tooltip>
                                <Popover
                                    id={id}
                                    open={anchorEl ? true: false}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                         vertical: 'bottom',
                                         horizontal: 'left',
                                    }}
                                    onClick={handleClose}> 
                                    {currentVersion === serverVersion ?
                                        <Typography style={{color:'#5E21D0', fontWeight:'bold'}}sx={{ p: 2 }}>You have no notifications.</Typography>
                                      : <Alert
                                            severity="info"
                                            action={<Button color="inherit" size="small" onClick={() => (window.location.reload())}>Refresh</Button>}>
                                            <AlertTitle>Recruit Wizard Updated</AlertTitle>
                                                Your version ({currentVersion}) of Recruit Wizard is out of date, click the refresh button to ensure you have the latest version ({serverVersion}).
                                        </Alert>}
                                </Popover>
                            </>}
                            <CustomApplicationContext.Consumer>
                                {c => <WizTopBarUserMenu currentUser={c.currentUser} currentUserRefreshHandler={c.refreshCurrentUser} navigate={navigate} />}
                            </CustomApplicationContext.Consumer>
                    </Box>
                </Toolbar>
            </AppBar>
            <Toolbar variant="dense" />
        </>
    );
}
