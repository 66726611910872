import React, { useCallback, useEffect, useMemo, useState } from "react";
import OnlyTitleSummaryBar from "components/SummaryBars/OnlyTitleSummaryBar";
import PageContentLayout from "layouts/PageContentLayout";
import PageLayout from "layouts/PageLayout";
import { GetSettingBySettingName, UpdateSettingBySettingName } from "services/UsersService";
import JobsGridComponent from "components/Grids/JobsGrid";
import JobsKanbanComponent from "components/Kanbans/JobsKanban";
import Switch from "@mui/material/Switch";

const summaryBar = <OnlyTitleSummaryBar title="Jobs > Open > Team" browserTabTitle="Team > Open > Jobs" />;
const defaultJobViewSettingName = 'DefaultJobsViewTeamJobs';

export default function MyJobsPage() {
    const [jobsView, setJobsView] = useState<'list' | 'kanban'>('kanban');
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingTab, setIsLoadingTab] = useState(false);

    useEffect(() => {
        const getData = async () => {
            setIsLoading(true);
            const defaultJobView = await GetSettingBySettingName(defaultJobViewSettingName);
            if (defaultJobView) setJobsView(defaultJobView === 'list' ? 'list' : 'kanban');
            setIsLoading(false);
        };
        getData();
    }, []);

    const jobsViewChangeHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = e.target.checked;
        const value = isChecked ? 'list' : 'kanban';
        setJobsView(value);
        UpdateSettingBySettingName(defaultJobViewSettingName, value);
    }, []);

    const openJobsViewSwitch = useMemo(() => (
        <span>
            <span>Kanban</span>
            <Switch checked={jobsView === 'list'} onChange={jobsViewChangeHandler} />
            <span>List</span>
        </span>
    ), [jobsView, jobsViewChangeHandler]);

    return (
        <>
            <PageLayout SummaryBar={summaryBar}>
                <PageContentLayout showLoading={isLoading || isLoadingTab}>
                    {jobsView === 'kanban' &&
                        <>
                            {openJobsViewSwitch}
                        <JobsKanbanComponent source="team-jobs" loadingHandler={setIsLoadingTab} />
                        </>
                    }
                    {jobsView === 'list' &&
                        <JobsGridComponent
                            source="team-jobs"
                            jobState={1}
                            loadingHandler={setIsLoadingTab}
                            extraActions={openJobsViewSwitch}
                            gridName="jobs/team-jobs"
                        />
                    }
                </PageContentLayout>
            </PageLayout>
        </>
    );
}