import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import moment from "moment";
import * as XLSX from "xlsx";
import React, { useCallback } from "react";
import { GetCandidatesByEmployerReportData } from "services/CandidatesService";

interface Props {
    description: string,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
}

export default function CandidatesByEmployerReport({ description, loadingHandler, errorHandler }: Props) {
    
    const getDataCallback = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        const res = await GetCandidatesByEmployerReportData(errorHandler);
        if (res) {
            const m = moment();
            const filename = `CandidatesByEmployer${m.format('YYYYMMDDhhmmss')}.xlsx`;
            const ws = XLSX.utils.json_to_sheet(res);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws);
            XLSX.writeFile(wb, filename);
        }
        loadingHandler && loadingHandler(false);
    }, [errorHandler, loadingHandler]);


    return (
        <>
            <Box display="flex" justifyContent="flex-end">
                <Button variant="contained" color="success" onClick={getDataCallback}>Run Report</Button>
            </Box>
            <Typography component="div" variant="h6" mt={2}>Report Description</Typography>
            <Typography component="div" variant="body2" mb={2}>{description}</Typography>

        </>
    );
}