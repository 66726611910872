import React, { useCallback, useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { GetAutomationTemplates, CreateAutomationFromTemplate } from "services/AutomationService"
import { NameIdObj } from "common/models/GenericTypes";

import MenuItem from "@mui/material/MenuItem";
import {useNavigate} from "react-router-dom";


interface Props {
    open: boolean,
    closeHandler: () => void,
    loadingHandler?: (isLoading: boolean) => void,
    successHandler?: (message: string) => void,
    errorHandler?: (message: string) => void
}

export default function CreateAutomationDialog({ open, closeHandler, loadingHandler, successHandler, errorHandler }: Props) {
    const [stateType, setStateType] = useState(0);
    const [templates, setTemplates] = useState<NameIdObj[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        const getData = async () => {
            loadingHandler && loadingHandler(true);
            const res = await GetAutomationTemplates();
            if (res) {
                setTemplates(res);
            }
            loadingHandler && loadingHandler(false);
        };
        getData();
    }, [loadingHandler]);
    
    const submitCallback = useCallback(async () => {
        loadingHandler && loadingHandler(true);

        if(stateType === 0)
        {
            //REDIRECT TO BLANK CREATE SCREEN
            
            navigate('/configuration/automation/0');
        } 
        else {
            let data = await CreateAutomationFromTemplate(stateType);
            
            //REDIRECT TO NEWLY CREATED AUTOMATION
            
            if(data){
                navigate(`/configuration/automation/${data.id}`);
            }
            
            successHandler && successHandler('Automation Created');
            closeHandler();
        }
        
        loadingHandler && loadingHandler(false);
    }, [closeHandler, loadingHandler, navigate, stateType, successHandler]);
    
    return (
        <>
            <Dialog open={open} fullWidth>
                <DialogTitle>Create Automation</DialogTitle>
                <DialogContent>
                    <Stack mt="10px" spacing={2}>
                            <TextField
                                select
                                value={stateType}
                                label="Type"
                                onChange={({ target }) => setStateType(+target.value)}
                            >
                                <MenuItem value="0">New Blank Automation</MenuItem>
                                {templates.map(option => (<MenuItem key={option.id} value={option.id.toString()}>{option.name}</MenuItem>))}
                            </TextField>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="error" onClick={ closeHandler }>Cancel</Button>
                    <Button variant="contained" color="success" onClick={ submitCallback } >Submit</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}