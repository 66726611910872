import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import PageContentLayout from "layouts/PageContentLayout";
import PageLayout from "layouts/PageLayout";
import React, { useMemo, useState } from "react";
import ScreeningEditPageContent from "./ScreeningEditPageContent";
import { useParams, useSearchParams } from "react-router-dom";

export default function ScreeningCreatePage() {
    const [summaryBar, setSummaryBar] = useState(<></>);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const params = useParams();
    const [searchParams] = useSearchParams();

    const candidateId = useMemo(() => {
        const parsedId = +(params.id ?? '0');
        if (isNaN(parsedId)) return 0;
        return parsedId;
    }, [params.id]);

    const jobId = useMemo(() => {
        const cId = searchParams.get('jobId');
        if (cId && !isNaN(+cId)) return +cId;
        return undefined;
    }, [searchParams]);
    
    return (
        <PageLayout SummaryBar={summaryBar}>
            <Snackbar open={errorMessage !== ''} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <Snackbar open={successMessage !== ''} autoHideDuration={3000} onClose={() => setSuccessMessage('')} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert color="success" onClose={() => setSuccessMessage('')}>{ successMessage }</Alert>
            </Snackbar>
            <PageContentLayout showLoading={isLoading}>
                <ScreeningEditPageContent
                    candidateId={candidateId}
                    jobId={jobId}
                    setSummaryBar={setSummaryBar}
                    loadingHandler={setIsLoading}
                    successHandler={setSuccessMessage}
                    errorHandler={setErrorMessage}
                />
            </PageContentLayout>
        </PageLayout>
    );
}