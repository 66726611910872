import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { GetSingleSetting, UpdateSettingBySettingName } from "services/UsersService";
import { DefaultUserSettings } from "util/Definitions/Configuration/Users/Edit";

type Props = {
    open: boolean,
    closeHandler: () => void,
    continueHandler: (clientDistMethod: string, candidateDistMethod: string, locationType: string, duration: string) => void,
    loadingHandler?: (isLoading: boolean) => void
};

const ClientDistributionMethodSettingName = 'DefaultDistributionMethod_InteriewsClients';
const CandidateDistributionMethodSettingName = 'DefaultDistributionMethod_InteriewsCandidates';
const LocationTypeSettingName = 'DefaultInterviewLocationTypeId';
const DurationTypeSettingName = 'DefaultDuration_Interviews';
const OnboardingSettingName = 'InterviewsOnboardingUtcDate';
const ClientDefaultDistributionMethod = DefaultUserSettings.DefaultDistributionMethod_InteriewsClients;
const CandidateDefaultDistributionMethod = DefaultUserSettings.DefaultDistributionMethod_InteriewsCandidates;
const DefaultLocationType = DefaultUserSettings.DefaultInterviewLocationTypeId;
const DefaultDuration = DefaultUserSettings.DefaultDuration_Interviews;

export default function InterviewsOnboardingDialog({ open, closeHandler, continueHandler, loadingHandler }: Props) {
    const [clientDistributionMethod, setClientDistributionMethod] = useState(ClientDefaultDistributionMethod);
    const [candidateDistributionMethod, setCandidateDistributionMethod] = useState(CandidateDefaultDistributionMethod);
    const [locationType, setLocationType] = useState(DefaultLocationType);
    const [duration, setDuration] = useState(DefaultDuration);
    const [originalClientDistributionMethod, setOriginalClientDistributionMethod] = useState(ClientDefaultDistributionMethod);
    const [originalCandidateDistributionMethod, setOriginalCandidateDistributionMethod] = useState(CandidateDefaultDistributionMethod);
    const [originalLocationType, setOriginalLocationType] = useState(DefaultLocationType);
    const [originalDuration, setOriginalDuration] = useState(DefaultDuration);

    useEffect(() => {
        const getData = async () => {
            const dist1 = await GetSingleSetting(ClientDistributionMethodSettingName);
            if (dist1 && dist1.value) {
                setClientDistributionMethod(dist1.value);
                setOriginalClientDistributionMethod(dist1.value);
            }
            
            const dist2 = await GetSingleSetting(CandidateDistributionMethodSettingName);
            if (dist2 && dist2.value) {
                setCandidateDistributionMethod(dist2.value);
                setOriginalCandidateDistributionMethod(dist2.value);
            }

            const ltype = await GetSingleSetting(LocationTypeSettingName);
            if (ltype && ltype.value) {
                setLocationType(ltype.value);
                setOriginalLocationType(ltype.value);
            }

            const dur = await GetSingleSetting(DurationTypeSettingName);
            if (dur && dur.value) {
                setDuration(dur.value);
                setOriginalDuration(dur.value);
            }
        };
        getData();
    }, []);

    const hasChanges = useMemo(() => clientDistributionMethod !== originalClientDistributionMethod || locationType !== originalLocationType, [clientDistributionMethod, locationType, originalClientDistributionMethod, originalLocationType]);

    const onContinueCallback = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        if (clientDistributionMethod !== originalClientDistributionMethod) await UpdateSettingBySettingName(ClientDistributionMethodSettingName, clientDistributionMethod);
        if (candidateDistributionMethod !== originalCandidateDistributionMethod) await UpdateSettingBySettingName(CandidateDistributionMethodSettingName, candidateDistributionMethod);
        if (locationType !== originalLocationType) await UpdateSettingBySettingName(LocationTypeSettingName, locationType);
        if (duration !== originalDuration) await UpdateSettingBySettingName(DurationTypeSettingName, duration);
        const m = moment.utc();
        UpdateSettingBySettingName(OnboardingSettingName, m.format('YYYY-MM-DDTHH:mm'));
        loadingHandler && loadingHandler(false);
        continueHandler(clientDistributionMethod, candidateDistributionMethod, locationType, duration);
    }, [candidateDistributionMethod, clientDistributionMethod, continueHandler, duration, loadingHandler, locationType, originalCandidateDistributionMethod, originalClientDistributionMethod, originalDuration, originalLocationType]);

    return (
        <Dialog open={open}>
            <DialogTitle>Interviews Onboarding</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Did you know you can set defaults for Interviews? You can change your defaults right now or do it later from the settings page on your account menu.
                </DialogContentText>
                <Stack spacing={2} sx={{ pt: 2 }}>
                    <TextField
                        select
                        label="Default Distribution Method - Clients"
                        value={clientDistributionMethod}
                        onChange={({target}) => setClientDistributionMethod(target.value)}
                    >
                        <MenuItem value="CalendarInviteOnly">Calendar Invite Only</MenuItem>
                        <MenuItem value="CalendarInvitePlusEmail">Calendar Invite + Email</MenuItem>
                        <MenuItem value="NoneRecordOnly">None - Record In Recruit Wizard Only</MenuItem>
                    </TextField>
                    <TextField
                        select
                        label="Default Distribution Method - Candidates"
                        value={candidateDistributionMethod}
                        onChange={({target}) => setCandidateDistributionMethod(target.value)}
                    >
                        <MenuItem value="CalendarInviteOnly">Calendar Invite Only</MenuItem>
                        <MenuItem value="CalendarInvitePlusEmail">Calendar Invite + Email</MenuItem>
                        <MenuItem value="NoneRecordOnly">None - Record In Recruit Wizard Only</MenuItem>
                    </TextField>
                    <TextField
                        select
                        label="Default Location Type"
                        value={locationType}
                        onChange={({target}) => setLocationType(target.value)}
                    >
                        <MenuItem value="1">In Person</MenuItem>
                        <MenuItem value="2">Phone Call</MenuItem>
                        <MenuItem value="3">Microsoft Teams</MenuItem>
                        {/*<MenuItem value="4">Zoom</MenuItem>*/}
                        <MenuItem value="5">Other</MenuItem>
                    </TextField>
                    <TextField
                        select
                        label="Default Duration"
                        value={duration}
                        onChange={({target}) => setDuration(target.value)}
                    >
                        <MenuItem value="0">None</MenuItem>
                        <MenuItem value="15">15 mins</MenuItem>
                        <MenuItem value="30">30 mins</MenuItem>
                        <MenuItem value="60">1 hour</MenuItem>
                        <MenuItem value="90">90 mins</MenuItem>
                        <MenuItem value="120">2 hours</MenuItem>
                        <MenuItem value="240">4 hours</MenuItem>
                        <MenuItem value="360">6 hours</MenuItem>
                        <MenuItem value="480">8 hours</MenuItem>
                    </TextField>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="error" onClick={ closeHandler } >Cancel</Button>
                <Button variant="contained" color="success" onClick={ onContinueCallback } >{ hasChanges ? 'Save Changes + Continue' : 'Continue' }</Button>
            </DialogActions>
        </Dialog>
    );
}