import { MenuList, Paper, Popper, styled } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";

import NavBarSubmenuElement from './NavBarSubmenuElement';
import { TopBarRoute } from "common/models/TopBarTypes";

interface Props {
    anchor: HTMLElement | null,
    options?: Array<TopBarRoute>,
    isRootHovered: boolean,
    closeSubmodule: () => void,
    showCreateOpportunityDialogHandler?: () => void,
    isInternal?: boolean
}

const CustomPopper = styled(Popper)(({ theme }) => ({
    zIndex: theme.zIndex.appBar
}));

export default function NavBarSubmenuContainer({ anchor, options, isRootHovered, closeSubmodule, showCreateOpportunityDialogHandler, isInternal }: Props) {
    const [hoveredSubIndex, setHoveredSubIndex] = useState<number | null>(null);
    const [isRootPaperHovered, setIsRootPaperHovered] = useState(false);

    useEffect(() => {
        if (Boolean(anchor) && !isRootHovered && !isRootPaperHovered) closeSubmodule();
    }, [anchor, closeSubmodule, isRootHovered, isRootPaperHovered]);

    const hasOptions = useMemo(() => (options && options.length > 0), [options]);

    const renderedChildren = useMemo(() => {
        return (
            <MenuList>
                {(options && options.length > 0) &&
                    options.map((option, index) => (
                        <NavBarSubmenuElement
                            key={index}
                            option={option}
                            elementIndex={index}
                            hoveredSubIndex={hoveredSubIndex}
                            isRootHovered={isRootHovered}
                            changeHoveredSubIndex={setHoveredSubIndex}
                            showCreateOpportunityDialogHandler={option.path === '/opportunities/create' ? showCreateOpportunityDialogHandler : undefined }
                        />
                    ))
                }
            </MenuList>
        );
    }, [hoveredSubIndex, isRootHovered, options, showCreateOpportunityDialogHandler]);

    if (isInternal) {
        return (
            <CustomPopper
                open={Boolean(anchor)}
                anchorEl={anchor}
                placement="right-start"
                onMouseEnter={ () => setIsRootPaperHovered(true) }
                onMouseLeave={ () => setIsRootPaperHovered(false) }
            >
                {hasOptions &&
                    <Paper elevation={8}>
                        {renderedChildren}
                    </Paper>
                }
            </CustomPopper>
        );
    }

    return (
        <Popper
            open={Boolean(anchor)}
            anchorEl={anchor}
            sx={{ zIndex: (theme) => (theme.zIndex.appBar - 1) }}
            onMouseEnter={ () => setIsRootPaperHovered(true) }
            onMouseLeave={ () => setIsRootPaperHovered(false) }
        >
            <Paper elevation={6}>
                { hasOptions &&
                    renderedChildren
                }
            </Paper>
        </Popper>
    );
}