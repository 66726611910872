import React, { useCallback, useEffect, useMemo, useState } from "react";
import { GridColDef, GridRenderCellParams, useGridApiRef } from "@mui/x-data-grid-premium";
import Alert from "components/Alert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import { CustomField, CustomFieldPredefinedValue } from "common/models/Configuration/CustomFields";
import PageContentLayout from "layouts/PageContentLayout";
import { GetCustomFieldsByEntity, GetPredefinedValues, SavePredefinedValues, UpdateCustomfields } from "services/CustomFieldsService";
import OnlyTitleSummaryBar from "components/SummaryBars/OnlyTitleSummaryBar";
import GridWithStateWrapper from "components/GridWidthStateWrapper";
import UnsavedChangesDialog from "components/Dialogs/UnsavedChangesDialog";
import RWTextFieldComponent from "components/RWTextFieldComponent";

interface Props {
    setSummaryBar?: (summaryBar: JSX.Element) => void,
    entityId: number
}

const entityNameMap = {
    3: 'Candidates',
    1: 'Clients',
    2: 'Contacts',
    4: 'Jobs',
    8: 'Opportunities',
    5: 'Placements',
    9: 'Sites',
};

export default function CustomFieldsAdmin({ setSummaryBar, entityId }: Props) {
    const [rows, setRows] = useState<CustomField[]>([]);
    const [predefinedValues, setPredefinedValues] = useState<CustomFieldPredefinedValue[]>([]);
    const [originalValues, setOriginalValues] = useState('');
    const [deletedPredefinedValues, setDeletedPredefinedValues] = useState<number[]>([]);
    const [newValueString, setNewValueString] = useState('');
    const [isloading, setIsLoading] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [editedRow, setEditedRow] = useState<CustomField | null>(null);
    const [selectedRow, setSelectedRow] = useState<CustomField | null>(null);
    const [showEditRowDialog, setShowEditRowDialog] = useState(false);
    const [showEditValuesDialog, setShowEditValuesDialog] = useState(false);
    const [showUnsavedRowChangesDialog, setShowUnsavedRowChangesDialog] = useState(false);
    const [showUnsavedValueChangesDialog, setShowUnsavedValueChangesDialog] = useState(false);
    const gridApiRef = useGridApiRef();

    const entityName = useMemo(() => (entityNameMap as any)[entityId.toString()] , [entityId]);

    useEffect(() => {
        const summaryBar = (
            <OnlyTitleSummaryBar
                title={`Configuration > Custom Fields > ${entityName}`}
                browserTabTitle="Custom Fields > Configuration"
            />
        );
        setSummaryBar && setSummaryBar(summaryBar);
    }, [setSummaryBar, entityName]);

    const getRowsDataCallback = useCallback( async () => {
        setIsLoading(true);
        const data = await GetCustomFieldsByEntity(entityId, setErrorMessage);
        if(data) {
            setRows(data);
        }
        setIsLoading(false);
    }, [entityId]);

    useEffect(() => {
        getRowsDataCallback();
    }, [getRowsDataCallback]);

    const saveRowHandler = useCallback(async () => {

        if(editedRow) {
            setIsLoading(true);

            const res = await UpdateCustomfields(entityId, [editedRow], setErrorMessage);
            if(!res) {
                setIsLoading(false);
                return false;
            }

            if (editedRow.id === 0) {
                await getRowsDataCallback();
            }
            else {
                gridApiRef.current.updateRows([editedRow]);
            }

            handleCloseEditRowDialog();
            setShowSuccess(true);
            setIsLoading(false);
        }

        return true;
    }, [entityId, getRowsDataCallback, gridApiRef, editedRow]);

    const columns = useMemo<GridColDef[]>(() => {
        const nameRenderer = (params: GridRenderCellParams) => {
            return (
            <span
                style={{ cursor: 'pointer', textDecoration: 'underline' }}
                onClick={ () => {
                    setSelectedRow(params.row);
                    setEditedRow(params.row);
                    setShowEditRowDialog(true);
                }}
            >
                {params.value}
            </span>
            );
        };

        const getValues = async (fieldId: number) => {
            setIsLoading(true);
            const data = await GetPredefinedValues(fieldId);
            if(data) {
                setPredefinedValues(data);
                const originalValues = data.map(v => `${v.value}|${v.isDefault}`).join('|');
                setOriginalValues(originalValues);
            }
            setIsLoading(false);
        };

        const valuesRenderer = (params: GridRenderCellParams) => {
            if(params.row.dataType !== 'String')
                return <></>;

            return (
                <span
                    onClick={ async () => {
                        await getValues(params.row.id);
                        setSelectedRow(params.row);
                        setShowEditValuesDialog(true);
                    } }
                    style={{ cursor: 'pointer', textDecoration: 'underline' }}
                >
                    Edit Values
                </span>
            );
        };

        if (entityId === 5) {
            return [
                { field: 'name', headerName: 'Name', width: 200, renderCell: nameRenderer },
                { field: 'agencyName', headerName: 'Screen Name', width: 200 },
                { field: 'dataType', headerName: 'Field Type' },
                { field: 'isActive', headerName: 'Enabled', type: 'boolean' },
                { field: 'editable', headerName: 'Editable', type: 'boolean'  },
                { field: 'multiLine', headerName: 'Multi Line', type: 'boolean' },
                { field: 'mandatory', headerName: 'Mandatory', type: 'boolean' },
                { field: 'isSearchable', headerName: 'Searchable', type: 'boolean' },
                { field: 'usePredefinedValues', headerName: 'Dropdown', type: 'boolean' },
                { field: 'values', headerName: 'Values', renderCell: valuesRenderer },
            ];
        }

        return [
            { field: 'name', headerName: 'Name', width: 200, renderCell: nameRenderer },
            { field: 'agencyName', headerName: 'Screen Name', width: 200 },
            { field: 'dataType', headerName: 'Field Type' },
            { field: 'isActive', headerName: 'Enabled', type: 'boolean' },
            { field: 'editable', headerName: 'Editable', type: 'boolean'  },
            { field: 'editableViaGrids', headerName: 'Editable via Grids', type: 'boolean', width: 130 },
            { field: 'multiLine', headerName: 'Multi Line', type: 'boolean' },
            { field: 'mandatory', headerName: 'Mandatory', type: 'boolean' },
            { field: 'isSearchable', headerName: 'Searchable', type: 'boolean' },
            { field: 'usePredefinedValues', headerName: 'Dropdown', type: 'boolean' },
            { field: 'values', headerName: 'Values', renderCell: valuesRenderer },
        ];
    }, [entityId]);

    const hasRowChanges = useMemo(() => {
        if (selectedRow && editedRow) {
            if (selectedRow.agencyName !== editedRow.agencyName) return true;
            else if (selectedRow.isActive !== editedRow.isActive) return true;
            else if (selectedRow.editable !== editedRow.editable) return true;
            else if (selectedRow.editableViaGrids !== editedRow.editableViaGrids) return true;
            else if (selectedRow.multiLine !== editedRow.multiLine) return true;
            else if (selectedRow.mandatory !== editedRow.mandatory) return true;
            else if (selectedRow.isSearchable !== editedRow.isSearchable) return true;
            else if (selectedRow.usePredefinedValues !== editedRow.usePredefinedValues) return true;
        }
        return false;
    }, [editedRow, selectedRow]);

    const hasValueChanges = useMemo(() => {
        const currentValues = predefinedValues.map(v => `${v.value}|${v.isDefault}`).join('|');
        return originalValues !== currentValues;
    }, [predefinedValues, originalValues]);

    const rowStringChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setEditedRow(prev => ( prev ? { ...prev, [name]: value } : null ));
    }

    const rowBooleanChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setEditedRow(prev => ( prev ? { ...prev, [name]: value === '1' } : null ));
    }

    const predefinedValueNameChangeHandler = (index: number, value: string) => {
        setPredefinedValues(prev => {
            let newValues = [...prev];
            newValues[index].value = value;
            return newValues;
        });
    };

    const predefinedValuesAddHandler = () => {
        setPredefinedValues(prev => {
            let newValues: CustomFieldPredefinedValue[] = [...prev, { id: 0, isDefault: false, value: newValueString }];
            setNewValueString('');
            return newValues;
        });
    }

    const predefinedValuesChangeDefaultHandler = (index: number) => {
        setPredefinedValues(prev => {
            let newValues = [...prev];
            for (let i = 0; i < newValues.length; i++) {
                const v = newValues[i];
                if(index !== i) v.isDefault = false;
                else v.isDefault = !v.isDefault;
            }
            return newValues;
        });
    };

    const predefinedValuesDeleteHandler = (index: number) => {
        setPredefinedValues(prev => {
            const deletedId = prev[index].id;
            if (deletedId !== 0)
                setDeletedPredefinedValues(prev => [...prev, deletedId]);

            let newValues = [...prev];
            newValues.splice(index, 1);
            return newValues;
        });
    };

    const handleCloseEditRowDialog = () => {
        setShowEditRowDialog(false);
        setSelectedRow(null);
        setEditedRow(null);
        setShowUnsavedRowChangesDialog(false);
    }

    const handleCloseValuesDialog = () => {
        setShowEditValuesDialog(false);
        setPredefinedValues([]);
        setOriginalValues('');
        setShowUnsavedValueChangesDialog(false);
        setDeletedPredefinedValues([]);
        setNewValueString('');
        setSelectedRow(null);
    };

    const handleSavePredefinedValues = async () => {
        setIsLoading(true);
        if(selectedRow) {
            const res = await SavePredefinedValues(selectedRow.id, predefinedValues, deletedPredefinedValues, setErrorMessage);
            if(res) {
                handleCloseValuesDialog();
                setShowSuccess(true);
            }
        }
        setIsLoading(false);
    }

    return (
        <>
            <Snackbar open={showSuccess} autoHideDuration={3000} onClose={() => setShowSuccess(false)}>
                <Alert onClose={() => setShowSuccess(false)}>Changes Saved</Alert>
            </Snackbar>
            <Snackbar open={errorMessage !== ''}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <UnsavedChangesDialog
                open={showUnsavedRowChangesDialog}
                message="If you continue, all unsaved changes will be lost. Are you sure you wish to continue?"
                cancelHandler={() => setShowUnsavedRowChangesDialog(false)}
                discardChangesHandler={handleCloseEditRowDialog}
                saveChangesHandler={saveRowHandler}
            />
            <UnsavedChangesDialog
                open={showUnsavedValueChangesDialog}
                message="If you continue, all unsaved changes will be lost. Are you sure you wish to continue?"
                cancelHandler={() => setShowUnsavedValueChangesDialog(false)}
                discardChangesHandler={handleCloseValuesDialog}
                saveChangesHandler={handleSavePredefinedValues}
            />
            {showEditRowDialog && editedRow &&
                <Dialog open={true} onClose={ hasRowChanges ? () => setShowUnsavedRowChangesDialog(true) : handleCloseEditRowDialog } fullWidth>
                    <DialogTitle>
                        Edit { editedRow.name }
                    </DialogTitle>
                    <DialogContent dividers>
                        <Stack spacing={2}>
                            <RWTextFieldComponent
                                label="Screen Name"
                                value={editedRow.agencyName}
                                name="agencyName"
                                onChange={ rowStringChangeHandler }
                                isError={ editedRow.agencyName.length > 100 }
                                helperText={editedRow.agencyName.length > 100 ? `Screen name too long (${editedRow.agencyName.length}). Max 100 characters` : undefined}
                            />

                            <TextField
                                select
                                label="Enabled"
                                value={ editedRow.isActive ? "1": "0" }
                                name="isActive"
                                onChange={ rowBooleanChangeHandler }
                            >
                                <MenuItem value="1">Yes</MenuItem>
                                <MenuItem value="0">No</MenuItem>
                            </TextField>

                            <TextField
                                select
                                label="Editable"
                                value={ editedRow.editable ? "1": "0" }
                                name="editable"
                                onChange={ rowBooleanChangeHandler }
                            >
                                <MenuItem value="1">Yes</MenuItem>
                                <MenuItem value="0">No</MenuItem>
                            </TextField>

                            {entityId !== 5 &&
                                <TextField
                                    select
                                    label="Editable via Grids"
                                    value={editedRow.editableViaGrids ? "1" : "0"}
                                    name="editableViaGrids"
                                    onChange={rowBooleanChangeHandler}
                                >
                                    <MenuItem value="1">Yes</MenuItem>
                                    <MenuItem value="0">No</MenuItem>
                                </TextField>
                            }

                            {editedRow.dataType === 'String' &&
                                <TextField
                                    select
                                    label="Multi Line"
                                    value={ editedRow.multiLine ? "1": "0" }
                                    name="multiLine"
                                    onChange={ rowBooleanChangeHandler }
                                >
                                    <MenuItem value="1">Yes</MenuItem>
                                    <MenuItem value="0">No</MenuItem>
                                </TextField>
                            }

                            <TextField
                                select
                                label="Mandatory"
                                value={ editedRow.mandatory ? "1": "0" }
                                name="mandatory"
                                onChange={ rowBooleanChangeHandler }
                            >
                                <MenuItem value="1">Yes</MenuItem>
                                <MenuItem value="0">No</MenuItem>
                            </TextField>

                            <TextField
                                select
                                label="Searchable"
                                value={ editedRow.isSearchable ? "1": "0" }
                                name="isSearchable"
                                onChange={ rowBooleanChangeHandler }
                            >
                                <MenuItem value="1">Yes</MenuItem>
                                <MenuItem value="0">No</MenuItem>
                            </TextField>

                            {editedRow.dataType === 'String' &&
                                <TextField
                                    select
                                    label="Dropdown"
                                    value={ editedRow.usePredefinedValues ? "1": "0" }
                                    name="usePredefinedValues"
                                    onChange={ rowBooleanChangeHandler }
                                >
                                    <MenuItem value="1">Yes</MenuItem>
                                    <MenuItem value="0">No</MenuItem>
                                </TextField>
                            }
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="error" onClick={ hasRowChanges ? () => setShowUnsavedRowChangesDialog(true) : handleCloseEditRowDialog }>Cancel</Button>
                        <Button variant="contained" color="success" disabled={editedRow.agencyName.length > 100 || !hasRowChanges} onClick={ saveRowHandler }>Save</Button>
                    </DialogActions>
                </Dialog>
            }
            {showEditValuesDialog && selectedRow &&
                <Dialog open={true} onClose={ hasValueChanges ? () => setShowUnsavedValueChangesDialog(true) : handleCloseValuesDialog } fullWidth>
                    <DialogTitle>Predefined Values [ { selectedRow.agencyName !== '' ? selectedRow.agencyName : selectedRow.name } ]</DialogTitle>
                    <DialogContent dividers>
                        <div>
                            <span style={{ width: '70%', display: 'inline-block', textAlign: 'center' }}>Value</span>
                            <span style={{ width: '15%', display: 'inline-block', textAlign: 'center' }}>Default</span>
                            <span style={{ width: '15%', display: 'inline-block', textAlign: 'center' }}>Action</span>
                        </div>
                        <div style={{ paddingBottom: '20px' }}>
                            <Input placeholder="New Value" sx={{ width: '70%' }} size="small" value={newValueString} onChange={ ({target}) => setNewValueString(target.value) } />
                            <span style={{ width: '15%', display: 'inline-block', textAlign: 'center' }}></span>
                            <span style={{ width: '15%', display: 'inline-block', textAlign: 'center' }}>
                                <IconButton disabled={newValueString === ''} onClick={predefinedValuesAddHandler}><AddCircleIcon /></IconButton>
                            </span>
                        </div>
                        <Divider />
                        <div style={{ paddingTop: '20px' }}></div>
                        {predefinedValues.map((v, i) => (
                            <div key={i} style={{ paddingBottom: '10px' }}>
                                <Input sx={{ width: '70%' }} value={v.value} name="value" onChange={ ({target}) => predefinedValueNameChangeHandler(i, target.value) } />
                                <span style={{ width: '15%', display: 'inline-block', textAlign: 'center' }}>
                                    <Radio checked={v.isDefault} name="isDefault" value={i} onClick={ () => predefinedValuesChangeDefaultHandler(i) } />
                                </span>
                                <span style={{ width: '15%', display: 'inline-block', textAlign: 'center' }}>
                                    <IconButton onClick={ () => predefinedValuesDeleteHandler(i) }>
                                        <DeleteIcon />
                                    </IconButton>
                                </span>
                            </div>
                        ))}
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="error" onClick={ hasValueChanges ? () => setShowUnsavedValueChangesDialog(true) : handleCloseValuesDialog }>Cancel</Button>
                        <Button variant="contained" color="success" onClick={ handleSavePredefinedValues } disabled={!hasValueChanges}>Save</Button>
                    </DialogActions>
                </Dialog>
            }
            <PageContentLayout title={`Custom Fields - ${entityName}`} showLoading={isloading}>
                <GridWithStateWrapper
                    getRowId={ (row) => row.dbTypeId }
                    gridName="configuration/custom-fields"
                    rows={rows}
                    columns={columns}
                    disableRowSelectionOnClick
                    density="compact"
                    pinnedColumns={{ right: ['values'] }}
                    apiRef={gridApiRef}
                    hideFooter
                />
            </PageContentLayout>
        </>
    );
}