import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { SxProps } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { DatePicker } from "@mui/x-date-pickers-pro";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import * as XLSX from "xlsx";
import { GetActivityDetailedReportData, GetMeetingsDetailedReportData, GetSubmissionsDetailedReportData, GetInterviewsDetailedReportData } from "services/ActivitiesService";
import UserPicker from "components/Pickers/UserPicker";
import { NameIdObj } from "common/models/GenericTypes";
import { ReportDataProcessDateFields } from "util/ReportUtils";

interface Props {
    description: string,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void
}

const ownerSpecialOptions: NameIdObj[] = [
    { id: 0, name: 'My Company' },
    { id: -1, name: 'Me' },
    { id: -2, name: 'My Team' },
    { id: -3, name: 'Unassigned' },
];

const filterElementStyle: SxProps = { flex: '1 1 0', mr: 1 };

export default function DetailedActivityReport({ description, loadingHandler, errorHandler }: Props) {
    const [ownerId, setOwnerId] = useState(0);
    const [using, setUsing] = useState(0);
    const [startDate, setStartDate] = useState<moment.Moment | null>(null);
    const [endDate, setEndDate] = useState<moment.Moment | null>(null);

    useEffect(() => {
        setStartDate(moment().startOf('month'));
        setEndDate(moment());
    }, []);

    const getDataCallback = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        const stringStartDate = startDate && startDate.isValid() ? startDate.format('YYYY-MM-DD') : '';
        const stringEndDate = endDate && endDate.isValid() ? endDate.format('YYYY-MM-DD') : '';
        const res1 = await GetActivityDetailedReportData(ownerId, stringStartDate, stringEndDate, using, errorHandler);
        const res2 = await GetInterviewsDetailedReportData(ownerId, stringStartDate, stringEndDate, using, errorHandler);
        const res3 = await GetMeetingsDetailedReportData(ownerId, stringStartDate, stringEndDate, using, errorHandler);
        const res4 = await GetSubmissionsDetailedReportData(ownerId, stringStartDate, stringEndDate, using, errorHandler);
        if (res1 && res2 && res3 && res4) {
            ReportDataProcessDateFields(res1);
            ReportDataProcessDateFields(res2);
            ReportDataProcessDateFields(res3);
            ReportDataProcessDateFields(res4);

            const m = moment();
            const filename = `ActivityDetailed${m.format('YYYYMMDDhhmmss')}.xlsx`;

            const wb = XLSX.utils.book_new();
            
            const ws1 = XLSX.utils.json_to_sheet(res1, { cellDates: true });
            XLSX.utils.book_append_sheet(wb, ws1, "Activities");

            const ws2 = XLSX.utils.json_to_sheet(res2, { cellDates: true });
            XLSX.utils.book_append_sheet(wb, ws2, "Interviews");

            const ws3 = XLSX.utils.json_to_sheet(res3, { cellDates: true });
            XLSX.utils.book_append_sheet(wb, ws3, "Meetings");

            const ws4 = XLSX.utils.json_to_sheet(res4, { cellDates: true });
            XLSX.utils.book_append_sheet(wb, ws4, "Submissions");
            
            XLSX.writeFile(wb, filename);
        }
        loadingHandler && loadingHandler(false);
    }, [ownerId, startDate, endDate, using, errorHandler, loadingHandler]);

    return (
        <>
            <Box display="flex">
                <Box flex="1 1 0" pr={1}>
                    <UserPicker disableClearable label="Owner" userId={ownerId} onSelect={u => setOwnerId(u ? u.id : 0)} appendToStart={ownerSpecialOptions} includeInactiveUsers={true} />
                </Box>
                <DatePicker label="From Date" value={startDate} onChange={m => setStartDate(m)} sx={filterElementStyle} slotProps={{ actionBar: { actions: ["clear", "today", "cancel", "accept"] } }} />
                <DatePicker label="To Date" value={endDate} onChange={m => setEndDate(m)} sx={filterElementStyle} slotProps={{ actionBar: { actions: ["clear", "today", "cancel", "accept"] } }} />

                <TextField select label="Using" value={using.toString()} onChange={({ target }) => setUsing(+target.value)} sx={filterElementStyle}>
                    <MenuItem value="0">Activity Date</MenuItem>
                    <MenuItem value="1">Created Date</MenuItem>
                </TextField>
                <Button variant="contained" color="success" onClick={getDataCallback}>Run Report</Button>
            </Box>
            <Typography component="div" variant="h6" mt={2}>Report Description</Typography>
            <Typography component="div" variant="body2">{description}</Typography>
        </>
    );
}