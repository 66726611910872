import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";
import { Client } from "common/models/Clients";

interface Props {
    client: Client | null,
    fields: (keyof Client)[]
    title?: string,
    suffix?: string,
    placeholder?: string,
    ignoreNoValueFields?: boolean,
    hideLabels?: boolean,
    ignoreMinHeight?: boolean,
    disableTextSelection?: boolean,
}

type ClientFieldsTitles = {
    [key in keyof Client]: string
}


const fieldTitleMap: Partial<ClientFieldsTitles> = {
    siteName: 'Site Name',
    address1: 'Address 1',
    address2: 'Address 2',
    address3: 'Address 3',
    suburb: 'Suburb',
    state: 'State',
    postcode: 'Postcode',
    countryCode: 'Country',
    countryName: 'Country',
    cvDeadlinePerm: 'Perm',
    cvDeadlineContract: 'Contract',
    cvDeadlineFixedContract: 'FTC',
    creditLimit: 'Limit',
    creditRating: 'Rating',
    creditBalance: 'Balance',
}

export default function ClientFieldsElement({ client, fields, title, ignoreNoValueFields = false, hideLabels = false, ignoreMinHeight = false, suffix = '', placeholder = '', disableTextSelection = false }: Props) {
    return (
        <Box height="100%" display="flex" flexDirection="column" overflow="auto" px="5px">
            { title && <Typography variant="h6" fontWeight={600} component="div" pt="8px">{title}</Typography>}
            {fields.map(f => {
                const fieldValue = client ? client[f] : '';
                if (!fieldValue && fieldValue !== 0 && ignoreNoValueFields) return <React.Fragment key={f} />;
                const displayValue = fieldValue || fieldValue === 0 ? fieldValue + suffix : placeholder;
                return (
                    <Box key={f} display="flex" minHeight={ ignoreMinHeight ? undefined : "40px"}>
                        { !hideLabels && <Typography display="flex" alignItems="center" width="30%" variant="body1" fontWeight={600} component="div" sx={ disableTextSelection ? undefined : {userSelect: 'text' }}>{fieldTitleMap[f] ?? f}</Typography> }
                        <Typography display="flex" alignItems="center" width={hideLabels ? '100%' : '70%'} variant="body1" component="div" sx={ disableTextSelection ? undefined : { userSelect: 'text' }}>{displayValue}</Typography>
                    </Box>
                );

            }
            )}
        </Box>
    );
}