import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import React, { useMemo } from "react";
import Dropzone, { Accept } from "react-dropzone";
import { SimpleFileObj } from "common/models/GenericTypes";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import Box from '@mui/material/Box';

interface Props {
    files: File[],
    uploadedFiles?: SimpleFileObj[],
    templateFiles?: SimpleFileObj[],
    downloadFileHandler?: (file: SimpleFileObj) => void,
    downloadTemplateFileHandler?: (file: SimpleFileObj) => void,
    removeFileHandler: (index: number) => void,
    removeUploadedFileHandler?: (index: number) => void,
    removeTemplateFileHandler?: (index: number) => void,
    addFilesHandler: (files: File[]) => void,
    limitSizeBytes?: number,
    errorMessage?: string,
    singleFile?: boolean,
    zoneText?: string,
    height?: string,
    accept?: Accept,
    flexibleListContainer?: boolean,
}

const getSizeString = (bytes: number, si = false, dp = 1) => {
    const thresh = si ? 1000 : 1024;
    if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
    }

    const units = si 
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10**dp;

    do {
    bytes /= thresh;
    ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);
    return bytes.toFixed(dp) + ' ' + units[u];
};

export default function FilePicker({ files, uploadedFiles, templateFiles, limitSizeBytes, removeFileHandler, downloadFileHandler, downloadTemplateFileHandler, removeUploadedFileHandler, removeTemplateFileHandler, addFilesHandler, errorMessage = '', zoneText, height = '100px', singleFile = false, accept, flexibleListContainer = false }: Props) {
    const uploadedFilesRender = useMemo(() => {
        if (uploadedFiles) {
            return uploadedFiles.map((file, i) => (
                <React.Fragment key={i}>
                    <ListItem disablePadding secondaryAction={ removeUploadedFileHandler ? <IconButton edge="end" onClick={ () => removeUploadedFileHandler(i) }><DeleteIcon /></IconButton> : <></> }>
                        <ListItemButton dense onClick={ () => downloadFileHandler && downloadFileHandler(file) }>
                            <ListItemText primary={file.name} />
                        </ListItemButton>
                    </ListItem>
                </React.Fragment>
            ));
        }
    }, [uploadedFiles, removeUploadedFileHandler, downloadFileHandler]);

    const templateFilesRender = useMemo(() => {
        if (templateFiles) {
            return templateFiles.map((file, i) => (
                <React.Fragment key={i}>
                    <ListItem disablePadding secondaryAction={ removeTemplateFileHandler ? <IconButton edge="end" onClick={ () => removeTemplateFileHandler(i) }><DeleteIcon /></IconButton> : <></> }>
                        <ListItemButton dense onClick={ () => downloadTemplateFileHandler && downloadTemplateFileHandler(file) }>
                            <ListItemText primary={file.name} />
                        </ListItemButton>
                    </ListItem>
                </React.Fragment>
            ));
        }
    }, [templateFiles, removeTemplateFileHandler, downloadTemplateFileHandler]);

    const filesRender = useMemo(() => {
        return files.map((file, i) => (
            <React.Fragment key={i}>
                <ListItem disablePadding secondaryAction={ <IconButton edge="end" onClick={ () => removeFileHandler(i) }><DeleteIcon /></IconButton> }>
                    <ListItemButton dense>
                        <ListItemText primary={file.name} secondary={getSizeString(file.size, true)} />
                    </ListItemButton>
                </ListItem>
            </React.Fragment>
        ));
    }, [removeFileHandler, files]);

    const totalToUpload = useMemo(() => {
        if (files.length > 0) return files.reduce((total, item) => total + item.size, 0);
        return 0;
    }, [files]);

    const isLimitSurpassed = useMemo(() => limitSizeBytes ? totalToUpload > limitSizeBytes : false, [limitSizeBytes, totalToUpload]);

    return (
            <Box flexGrow={1} display="flex" flexDirection="column">
                <Dropzone onDropAccepted={ addFilesHandler } multiple={ !singleFile } accept={accept}>
                    {({getRootProps, getInputProps}) => (
                    <Paper
                        variant="outlined"
                        sx={{
                            p: '10px',
                            borderStyle: 'dashed',
                            borderWidth: '4px',
                            cursor: 'pointer',
                            minHeight: height,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderColor: t => errorMessage ? t.palette.error.main : undefined
                        }}
                        {...getRootProps({ className: 'dropzone' })}
                    >

                        <input {...getInputProps()} />
                        <Typography variant="h6" component="div" sx={{ textAlign: 'center' }} color={ errorMessage ? 'error' : undefined }>
                            { zoneText ?? 'Drop files to upload or click' }
                        </Typography>
                    </Paper>

                    )}
                </Dropzone>
                {totalToUpload > 0 &&
                    <Typography component="div" variant='body2'>
                        Total size: <Typography component="span" variant='body2' color={isLimitSurpassed ? 'error.main' : undefined}>{getSizeString(totalToUpload, true)}</Typography>
                    </Typography>
                }
                <Typography variant="caption" component="div" color="error" marginX="14px" marginTop="4px">{errorMessage}</Typography>
                {((uploadedFiles && uploadedFiles.length > 0) || files.length > 0 || (templateFiles && templateFiles.length > 0)) &&
                    <Divider variant="middle"><Chip size='small' label="ATTACHMENTS" /></Divider>
                }
                <div style={{ display: 'flex', flex: '1', flexDirection: 'column', position: 'relative', overflow: 'hidden' }}>
                    <div style={{position: flexibleListContainer ? 'absolute' : undefined, top: 0, left: 0, height: '100%', width: '100%', overflow: 'auto' }}>
                        {(files.length > 0 || (uploadedFiles && uploadedFiles.length > 0) || (templateFiles && templateFiles.length > 0)) &&
                            <List dense sx={{ maxHeight: flexibleListContainer ? undefined : '200px', overflow: 'auto' }}>
                                {templateFilesRender}
                                {uploadedFilesRender}
                                {filesRender}
                            </List>
                        }
                    </div>
                </div>
                {/* {(files.length > 0 || (uploadedFiles && uploadedFiles.length > 0) || (templateFiles && templateFiles.length > 0)) &&
                    <List dense sx={{ maxHeight: '200px', overflow: 'auto' }}>
                        {((uploadedFiles && uploadedFiles.length > 0) || files.length > 0 || (templateFiles && templateFiles.length > 0)) &&
                            <Divider variant="middle"><Chip size='small' label="ATTACHMENTS" /></Divider>
                        }
                        {templateFilesRender}
                        {uploadedFilesRender}
                        {filesRender}
                    </List>
                } */}
            </Box>
    );
}