import Alert from "components/Alert";
import Snackbar from "@mui/material/Snackbar";
import React, { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import EditRecordScreenLayout from "components/ScreenLayouts/Jobs/EditRecordScreenLayout";
import PageContentLayout from "layouts/PageContentLayout";
import PageLayout from "layouts/PageLayout";
import { GetMySettings } from "services/UsersService";
import { GetJobsSettings } from "services/ConfigurationService";

export default function JobCreatePage() {
    const [summaryBar, setSummaryBar] = useState(<></>);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [searchParams] = useSearchParams();
    const [defaultCurrency, setDefaultCurency] = useState(0);
    const [defaultHoursPerDay, setDefaultHoursPerDay] = useState(8);
    const [defaultDaysPerWeek, setDefaultDaysPerWeek] = useState(5);
    const [defaultSummary, setDefaultSummary] = useState('');
    
    const clientId = useMemo(() => {
        const cId = searchParams.get('ClientID');
        if (cId && !isNaN(+cId)) return +cId;
        return undefined;
    }, [searchParams]);
    
    const contactId = useMemo(() => {
        const cId = searchParams.get('ContactID');
        if (cId && !isNaN(+cId)) return +cId;
        return undefined;
    }, [searchParams]);
    
    const siteId = useMemo(() => {
        const cId = searchParams.get('SiteID');
        if (cId && !isNaN(+cId)) return +cId;
        return undefined;
    }, [searchParams]);
    
    const jobType = useMemo(() => {
        const jt = searchParams.get('JobType');
        return jt ?? undefined;
    }, [searchParams]);

    const jobId = useMemo(() => {
        const jId = searchParams.get('SourceJobID');
        if (jId && !isNaN(+jId)) return +jId;
        return undefined;
    }, [searchParams]);

    useEffect(() => {
        const getSettings = async () => {
            const settings = await GetMySettings();
            if (settings) {
                const c = settings.find(s => s.type === 'Currency');
                if (c && c.value) setDefaultCurency(+c.value);
            }
        };
        getSettings();
    }, []);

    useEffect(() => {
        const getJobSettings = async () => {
            const settings = await GetJobsSettings()
            if (settings && settings.length > 0) {
                for (let i = 0; i < settings.length; i++) {
                    const setting = settings[i];
                    if (setting.type === 'JobDefaultHoursPerDay' && Boolean(setting.value)) setDefaultHoursPerDay(+setting.value);
                    else if (setting.type === 'JobDefaultDaysPerWeek' && Boolean(setting.value)) setDefaultDaysPerWeek(+setting.value);
                    else if (setting.type === 'JobDefaultSummary' && setting.value) setDefaultSummary(setting.value);
                }
            }
        };
        getJobSettings();
    }, []);

    return (
        <PageLayout SummaryBar={summaryBar}>
            <Snackbar open={errorMessage !== ''} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <Snackbar open={successMessage !== ''} autoHideDuration={3000} onClose={() => setSuccessMessage('')} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert color="success" onClose={() => setSuccessMessage('')}>{ successMessage }</Alert>
            </Snackbar>
            <PageContentLayout showLoading={isLoading}>
                <EditRecordScreenLayout
                    jobId={jobId}
                    isCopy={Boolean(jobId)}
                    jobType={jobType}
                    clientId={clientId}
                    contactId={contactId}
                    siteId={siteId}
                    defaultSummary={defaultSummary}
                    defaultCurrency={defaultCurrency}
                    defaultHoursPerDay={defaultHoursPerDay}
                    defaultDaysPerWeek={defaultDaysPerWeek}
                    loadingHandler={setIsLoading}
                    setSummaryBar={setSummaryBar}
                    errorHandler={setErrorMessage}
                    successHandler={setSuccessMessage}
                />
            </PageContentLayout>
        </PageLayout>
    );
}