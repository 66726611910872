import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import BusinessIcon from '@mui/icons-material/Business';
import DeleteIcon from '@mui/icons-material/Delete';
import React, { useCallback, useMemo } from "react";
import ListItemText from "@mui/material/ListItemText";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";

import WorkIcon from '@mui/icons-material/Work';
import RssFeedIcon from '@mui/icons-material/RssFeed';
import PersonIcon from '@mui/icons-material/Person';
import BadgeIcon from '@mui/icons-material/Badge';
import DomainIcon from '@mui/icons-material/Domain';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import LanguageIcon from '@mui/icons-material/Language';

interface Props {
    entityId?: number,
    primaryText?: string,
    secondaryText?: string,
    photoUrl?: string,
    isEditable?: boolean,
    imageFile?: File | null,
    imageSetHandler?: (img: File | null) => void,
    removeUploadedImageHandler?: () => void,
}

const recordIcon = (entityId: number): JSX.Element => {
    switch (entityId) {
        case 1:
            return <DomainIcon style={{ fontSize: '3rem' }} />;
        case 2:
            return <BadgeIcon style={{ fontSize: '3rem' }} />;
        case 3:
            return <PersonIcon style={{ fontSize: '3rem' }} />;
        case 4:
            return <WorkIcon style={{ fontSize: '3rem' }} />;
        case 5:
            return <CreditCardIcon style={{ fontSize: '3rem' }} />;
        case 8:
            return <ShowChartIcon style={{ fontSize: '3rem' }} />;
        case 9:
            return <LanguageIcon style={{ fontSize: '3rem' }} />;
        case 15:
            return <RssFeedIcon style={{ fontSize: '3rem' }} />;
        default:
            return <BusinessIcon sx={{ fontSize: '3rem' }} />
    }
}

export default function RecordAvatarComponent({ entityId = 0, primaryText = '', secondaryText = '', photoUrl = '', isEditable = false, imageFile = null, imageSetHandler, removeUploadedImageHandler }: Props) {
    const deleteHandler = useCallback(() => {
        if (imageFile) imageSetHandler && imageSetHandler(null);
        else removeUploadedImageHandler && removeUploadedImageHandler();
    }, [imageFile, imageSetHandler, removeUploadedImageHandler]);

    const fileChangeHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        if (imageSetHandler) {
            const { files } = e.target;
            if(files && files[0]) imageSetHandler(files[0]);
        }
    }, [imageSetHandler]);

    const localImageData = useMemo(() => {
        if(imageFile) return URL.createObjectURL(imageFile);
        return null;
    }, [imageFile]);

    const source = useMemo(() => {
        if (localImageData) return localImageData;
        if (photoUrl) return photoUrl;
        return '';
    }, [localImageData, photoUrl]);

    return (
        <Box display="flex" height="100%">
            <ListItem disableGutters>
                <ListItemAvatar>
                    <Avatar  alt={secondaryText} src={ source } sx={{ width: '87px', height: '87px', bgcolor: t => t.palette.primary.main }}>
                        { (!source) && recordIcon(entityId)}
                    </Avatar>
                </ListItemAvatar>
                { !isEditable && 
                    <ListItemText
                        primary={primaryText}
                        primaryTypographyProps={{ title: primaryText, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                        secondary={secondaryText}
                        secondaryTypographyProps={{ title: secondaryText, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                        sx={{ ml: 2 }}
                    />
                }
                { isEditable && (
                    <>
                        <Button variant="contained" component="label" sx={{ ml: '20px' }}>
                            Change Image
                            <input hidden accept="image/*" type="file" onChange={ fileChangeHandler } />
                        </Button>
                        { source && <IconButton onClick={deleteHandler}><DeleteIcon /></IconButton>}
                    </>
                )}
            </ListItem>
        </Box>
    );
}