import Alert from "components/Alert";
import Snackbar from "@mui/material/Snackbar";
import { GridColDef, GridRenderCellParams, GridRowParams, GridRowSelectionModel, GridValueGetter, useGridApiRef } from "@mui/x-data-grid-premium";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { ESignRequest } from "common/models/ESigning";
import { Permission } from "common/models/Permissions";
import SignatureStatusDialog from "components/Dialogs/eSign/SignatureStatusDialog";
import ConfirmationDialog from "components/Dialogs/Generic/ConfirmationDialog";
import GridWithStateWrapper from "components/GridWidthStateWrapper";
import OnlyTitleSummaryBar from "components/SummaryBars/OnlyTitleSummaryBar";
import PageContentLayout from "layouts/PageContentLayout";
import PageLayout from "layouts/PageLayout";
import { ESignRequest_BulkRevoke, ESignRequest_BulkSendReminder, ESignRequest_Revoke, ESignRequest_SendReminder, GetCurrentCustomerESignRequests_All, GetCurrentCustomerESignRequests_Incomplete, GetMyESignRequests_All, GetMyESignRequests_Incomplete } from "services/ESignService";
import { companyHasSinglePermission, userHasSinglePermission } from "util/PermissionsUtils";
import ActionsDropDownButton from "components/SummaryBars/Actions/ActionsDropsDownMenu";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";

interface Props {
    showOnlyIncomplete?: boolean
}

const spanLinkStyle: React.CSSProperties = { cursor: 'pointer', textDecoration: 'underline' };

export default function ESignRequestsPage({ showOnlyIncomplete }: Props) {
    const [summaryBar, setSummaryBar] = useState<JSX.Element>(<></>);
    const [isLoading, setIsLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [rows, setRows] = useState<ESignRequest[]>([]);
    const [revokeRow, setRevokeRow] = useState<ESignRequest | null>(null);
    const [selectedRow, setSelectedRow] = useState<ESignRequest | null>(null);
    const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
    const [showBulkReminderDialog, setShowBulkReminderDialog] = useState(false);
    const [showBulkRevokeDialog, setShowBulkRevokeDialog] = useState(false);
    const apiRef = useGridApiRef();

    const isTemplatesAdmin = useMemo(() => userHasSinglePermission(Permission.TemplatesAdministrator), []);

    useEffect(() => {
        const summaryBar = <OnlyTitleSummaryBar title={`Account > eSign Requests > ${ showOnlyIncomplete ? 'Incomplete' : 'All' }`} />;
        setSummaryBar(summaryBar);
    }, [showOnlyIncomplete]);

    const getRows = useCallback(async () => {
        setIsLoading(true);
        let res: ESignRequest[] | null = [];
    
        if (showOnlyIncomplete) {
            if (isTemplatesAdmin) {
                res = await GetCurrentCustomerESignRequests_Incomplete(setErrorMessage);
            }
            else {
                res = await GetMyESignRequests_Incomplete(setErrorMessage);
            }
        }
        else {
            if (isTemplatesAdmin) {
                res = await GetCurrentCustomerESignRequests_All(setErrorMessage);
            }
            else {
                res = await GetMyESignRequests_All(setErrorMessage);
            }
        }
    
        if (res) setRows(res);
        setIsLoading(false);
    }, [showOnlyIncomplete, isTemplatesAdmin]);

    useEffect(() => {
        getRows()
    }, [getRows]);

    const sendReminderHandler = useCallback(async (requestId: number) => {
        setIsLoading(true);
        const res = await ESignRequest_SendReminder(requestId, setErrorMessage);
        if (res) setSuccessMessage('Reminder sent');
        setIsLoading(false);
    }, []);

    const sendBulkReminderHandler = useCallback(async () => {
        setIsLoading(true);
        if (selectionModel.length > 0) {
            const res = await ESignRequest_BulkSendReminder(selectionModel as number[], setErrorMessage);
            if (res) {
                setSuccessMessage('Reminder(s) sent');
                setShowBulkReminderDialog(false);
                setSelectionModel([]);
            }
        }
        setIsLoading(false);
    }, [selectionModel]);

    const revokeHandler = useCallback(async () => {
        setIsLoading(true);
        if (revokeRow) {
            const res = await ESignRequest_Revoke(revokeRow.id, setErrorMessage);
            if (res) {
                setSuccessMessage('Request revoked');
                setRevokeRow(null);
                getRows();
            }
        }
        setIsLoading(false);
    }, [revokeRow, getRows]);

    const bulkRevokeHandler = useCallback(async () => {
        setIsLoading(true);
        if (selectionModel.length > 0) {
            const res = await ESignRequest_BulkRevoke(selectionModel as number[], setErrorMessage);
            if (res) {
                setSuccessMessage('Request(s) revoked');
                setShowBulkRevokeDialog(false);
                setSelectionModel([]);
                getRows();
            }
        }
        setIsLoading(false);
    }, [selectionModel, getRows]);

    const columns = useMemo<GridColDef[]>(() => {
        const dateValueGetter: GridValueGetter<ESignRequest, any, undefined, string> = (value) => {
            if (value) {
                const d = moment(value);
                if (d.isValid() && d.get("year") > 1) {
                    return d.toDate();
                }
            }
            return null;
        };

        const dateRenderer = (params: GridRenderCellParams) => {
            if (params.value) {
                return moment(params.value).format('DD MMM YYYY');
            }
        };

        const actionsRenderer = (params: GridRenderCellParams) => {
            const statusId = params.row.statusID;
            if (statusId !== 1 && statusId !== 2) return <></>;
            const requestId = +params.id.valueOf();
            return (
                <>
                    <span style={{...spanLinkStyle, marginRight: '15px'}} onClick={ () => sendReminderHandler(requestId) } >Send Reminder</span> |
                    <span style={{...spanLinkStyle, marginLeft: '15px'}} onClick={ () => setRevokeRow(params.row) } >Revoke Request</span>
                </>
            );
        };

        const generatedFromRenderer = (params: GridRenderCellParams) => {
            let urlPath = '';
            switch (params.row.entityID) {
                case 1: urlPath = 'clients';
                    break;
                case 2: urlPath = 'contacts';
                    break;
                case 3: urlPath = 'candidates';
                    break;
                case 4: urlPath = 'jobs';
                    break;
                case 5: urlPath = 'placements';
                    break;
                case 8: urlPath = 'opportunities';
                    break;
                case 17: urlPath = 'placements/change-requests';
                    break;
            }
            
            if (urlPath === '') return params.value;

            return (
                <Link
                    style={{ color: 'inherit', textDecoration: 'underline' }}
                    to={`/${urlPath}/${params.row.playerID}`}
                    target="_blank"
                >{params.value}</Link>
            );
        };

        const statusRenderer = (params: GridRenderCellParams) => {
            return (
                <span style={spanLinkStyle} onClick={ () => setSelectedRow(params.row) }>{params.value}</span>
            );
        };

        return [
            { field: 'id', headerName: 'Request ID', width: 120 },
            { field: 'documentTitle', headerName: 'Document Title', width: 400 },
            { field: 'originRecordName', headerName: 'Generated From', width: 200, renderCell: generatedFromRenderer },
            { field: 'createdDate', headerName: 'Request Date', width: 120, type: 'date', valueGetter: dateValueGetter, renderCell: dateRenderer },
            { field: 'consultantName', headerName: 'Consultant Name', width: 200 },
            { field: 'statusName', headerName: 'Status', width: 200, renderCell: statusRenderer },
            { field: 'actions', headerName: 'Actions', width: 250, disableColumnMenu: true, sortable: false, renderCell: actionsRenderer },
        ];
    }, [sendReminderHandler]);

    const exportToExcel = useCallback(() => {
        const api = apiRef.current;
        if (api) api.exportDataAsExcel();
    }, [apiRef]);

    const canExport = useMemo(() => companyHasSinglePermission(Permission.MiscExport) && userHasSinglePermission(Permission.MiscExport), []);

    const gridActions = useMemo(() => {
        return (
            <ActionsDropDownButton color="secondary" label="List Actions">
                { canExport && <MenuItem onClick={exportToExcel}>Export</MenuItem>}
                <MenuItem disabled={selectionModel.length === 0} onClick={() => setShowBulkReminderDialog(true)}>Send Reminder</MenuItem>
                <MenuItem disabled={selectionModel.length === 0} onClick={() => setShowBulkRevokeDialog(true)}>Revoke Request</MenuItem>
            </ActionsDropDownButton>
        );
    }, [canExport, exportToExcel, selectionModel.length]);

    const isRowSelectableCallback = useCallback((p: GridRowParams) => {
        return p.row.statusID === 1 || p.row.statusID === 2;
    }, []);

    return (
        <PageLayout SummaryBar={summaryBar}>
            <ConfirmationDialog
                open={revokeRow !== null}
                title="Confirm action"
                message="Are you sure you want to revoke the request?"
                onClose={ () => setRevokeRow(null) }
                onContinue={ revokeHandler }
                fullWidth
            />
            <ConfirmationDialog
                open={showBulkReminderDialog}
                title="Confirm action"
                message={`Are you sure you want to send reminders to the ${selectionModel.length} selected request(s)?`}
                onClose={ () => setShowBulkReminderDialog(false) }
                onContinue={ sendBulkReminderHandler }
                fullWidth
            />
            <ConfirmationDialog
                open={showBulkRevokeDialog}
                title="Confirm action"
                message={`Are you sure you want to revoke the ${selectionModel.length} selected request(s)?`}
                onClose={ () => setShowBulkRevokeDialog(false) }
                onContinue={ bulkRevokeHandler }
                fullWidth
            />
            {selectedRow &&
                <SignatureStatusDialog
                    open={true}
                    eSignRequestId={selectedRow.id}
                    loadingHandler={setIsLoading}
                    onClose={() => setSelectedRow(null)}
                />
            }
            <Snackbar
                open={successMessage.length > 0}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={3000}
                onClose={() => setSuccessMessage('')}
            >
                <Alert onClose={() => setSuccessMessage('')}>{ successMessage }</Alert>
            </Snackbar>
            <Snackbar
                open={errorMessage !== ''}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <PageContentLayout title={showOnlyIncomplete ? 'eSign Requests - Incomplete' : 'eSign Requests'} showLoading={isLoading}>
                <Box>
                    <Box pb="10px" sx={{ float: 'right' }}>{ gridActions }</Box>
                </Box>
                <GridWithStateWrapper
                    gridName="account/esign-requests"
                    apiRef={apiRef}
                    rows={rows}
                    columns={columns}
                    checkboxSelection
                    disableRowSelectionOnClick
                    rowSelectionModel={selectionModel}
                    onRowSelectionModelChange={ sm => setSelectionModel(sm) }
                    isRowSelectable={ isRowSelectableCallback}
                    pagination
                    density="compact"
                    pageSizeOptions={[100,250,500,1000]}
                />
            </PageContentLayout>
        </PageLayout>
    );
}