import React, { useCallback, useEffect, useMemo, useState } from "react";
import { GetMyGeneralSettings, GetMyUser, UpdateMyGeneralSettings } from "services/UsersService";
import InformationDialog from "./Generic/InformationDialog";
import ConfirmationDialog from "./Generic/ConfirmationDialog";

interface Props {
    loadingHandler?: (isLoading: boolean) => void
}

const dismissedTzSettingName = 'DismissedTimeZone';

export default function TimezoneVerificationDialog({ loadingHandler }: Props) {
    const [showAutoUpdated, setShowAutoUpdated] = useState(false);
    const [showConfirmUpdate, setShowConfirmUpdate] = useState(false);
    const [savedTz, setSavedTz] = useState('');
    const deviceTz = useMemo(() => Intl.DateTimeFormat().resolvedOptions().timeZone, [])

    const wasDismissed = useMemo(() => {
        const s = localStorage.getItem(dismissedTzSettingName);
        return s === deviceTz;
    }, [deviceTz]);

    const updateTzCallback = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        const s = await GetMyGeneralSettings();
        if (s) {
            await UpdateMyGeneralSettings({...s, timeZoneName: deviceTz});
            setShowConfirmUpdate(false);
        }
        loadingHandler && loadingHandler(false);
    }, [deviceTz, loadingHandler]);

    const dismissCallback = useCallback(() => {
        localStorage.setItem(dismissedTzSettingName, deviceTz);
        setShowConfirmUpdate(false)
    }, [deviceTz]);

    useEffect(() => {
        const getTimeZoneData = async () => {
            const me = await GetMyUser();
            if (me && !me.timeZoneCheckComplete) {
                setSavedTz(me.timeZoneName);
                if (!Boolean(me.timeZoneName)) {
                    await updateTzCallback();
                    setShowAutoUpdated(true);
                }
                else if (me.timeZoneName !== deviceTz) {
                     setShowConfirmUpdate(true);
                }
            }
        };
        getTimeZoneData();
    }, [deviceTz, updateTzCallback]);

    return (
        <>
            <InformationDialog
                open={showAutoUpdated}
                message={<>Your account does not currently have a timezone. We are updating it to <strong>{deviceTz}</strong></>}
                onClose={() => setShowAutoUpdated(false)}
                title="No Timezone"
                fullWidth
            />
            <ConfirmationDialog
                open={!wasDismissed && showConfirmUpdate}
                title="Timezone Mismatch"
                message={<>Your timezone value of <strong>{savedTz}</strong> does not match your device timezone of <strong>{deviceTz}</strong>. Would you like to update your timezone to match?</>}
                onClose={dismissCallback}
                onContinue={updateTzCallback}
                cancelActionText="No"
                confirmActionText="Yes"
                fullWidth
            />
        </>
    );
}