import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { SxProps } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { GetSmsUsageReportData } from "services/SmsService";
import { GridColDef, useGridApiRef } from "@mui/x-data-grid-premium";
import { SmsUsageReportData } from "common/models/Reports/SmsUsage";
import GridWithStateWrapper from "components/GridWidthStateWrapper";
import { DatePicker } from "@mui/x-date-pickers-pro";

interface Props {
    description: string,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
}

const filterElementStyle: SxProps = { flex: '1 1 0', mr: 1 };

export default function SmsUsageReport({ description, loadingHandler, errorHandler }: Props) {
    const [rows, setRows] = useState<SmsUsageReportData[]>([]);
    const [showGrid, setShowGrid] = useState(false);
    const [consultantId] = useState(0);
    const [fromDate, setFromDate] = useState<moment.Moment | null>(null);
    const [toDate, setToDate] = useState<moment.Moment | null>(null);
    const apiRef = useGridApiRef();

    useEffect(() => {
        setFromDate(moment().startOf('month'));
        setToDate(moment().endOf('month'));
    }, []);

    const getDataCallback = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        const stringFromDate = fromDate && fromDate.isValid() ? fromDate.format('YYYY-MM-DD') : '';
        const stringToDate = toDate && toDate.isValid() ? toDate.format('YYYY-MM-DD') : '';
        const res = await GetSmsUsageReportData(consultantId, stringFromDate, stringToDate, errorHandler);
        if (res) {
            setShowGrid(true);
            setRows(res);
        }
        loadingHandler && loadingHandler(false);
    }, [consultantId, fromDate, toDate, errorHandler, loadingHandler]);

    const exportAsExcelHandler = useCallback(() => {
        const api = apiRef.current;
        if (api) {
            const m = moment();
            const filename = `SmsUsage${m.format('YYYYMMDDhhmmss')}`;
            api.exportDataAsExcel({ fileName: filename });
        }
    }, [apiRef]);

    const columns = useMemo<GridColDef[]>(() => {
        return [
            { field: 'User', width: 350 },
            { field: 'Messages', width: 350 },
            { field: 'CreditsUsed', headerName: "Credits Used", width: 350 },
        ];
    }, []);

    return (
        <>
            <Box display="flex">
                <DatePicker label="From Date" value={fromDate} onChange={setFromDate} sx={filterElementStyle} slotProps={{actionBar: { actions: ["clear", "today", "cancel", "accept"] }}} />
                <DatePicker label="To Date" value={toDate} onChange={setToDate} sx={filterElementStyle} slotProps={{actionBar: { actions: ["clear", "today", "cancel", "accept"] }}} />
                <Button variant="contained" color="success" onClick={getDataCallback}>Run Report</Button>
            </Box>
            <Typography component="div" variant="h6" mt={2}>Report Description</Typography>
            <Typography component="div" variant="body2" mb={2}>{description}</Typography>
            {showGrid &&
                <>
                    <Box pb="10px" ml="auto">
                        <Button variant="contained" color="success" onClick={exportAsExcelHandler}>Export As Excel</Button>
                    </Box>
                    <GridWithStateWrapper
                        density="compact"
                        gridName="reports-smsusage"
                        rows={rows}
                        columns={columns}
                        apiRef={apiRef}
                        getRowId={r => r.User + "-" + r.Messages}
                        disableRowSelectionOnClick
                    />
                </>
            }
        </>
    );
}