import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import React, { useCallback, useEffect, useMemo } from "react";

interface Props {
    jobBoardId: number,
    advertId: number,
    attributes?: Record<string, string>,
    advertRecordAttributes?: Record<string, string>,
    attributeChangeHandler?: (jobBoardId: number, key: string, value: string) => void
}

const categoryOptions = [
    <MenuItem key="0" value="0"></MenuItem>,
    <MenuItem key="Bilingual" value="Bilingual">Bilingual</MenuItem>,
    <MenuItem key="Executive" value="Executive">Executive</MenuItem>,
    <MenuItem key="Graduate" value="Graduate">Graduate</MenuItem>,
    <MenuItem key="Home Working" value="Home Working">Home Working</MenuItem>,
    <MenuItem key="Part Time" value="Part Time">Part Time</MenuItem>,
    <MenuItem key="Public Sector" value="Public Sector">Public Sector</MenuItem>,
    <MenuItem key="Temp" value="Temp">Temp</MenuItem>,
];

export default function JobServeEdit({ jobBoardId, advertId, attributes, advertRecordAttributes, attributeChangeHandler }: Props) {
    const atts = useMemo<Record<string, string>>(() => {
        if (attributes) return attributes;
        return {};
    }, [attributes]);

    const recordAtts = useMemo<Record<string, string>>(() => {
        if (advertRecordAttributes) return advertRecordAttributes;
        return {};
    }, [advertRecordAttributes]);

    const category1 = useMemo(() => atts.Category1 ?? '0', [atts.Category1]);
    const category2 = useMemo(() => atts.Category2 ?? '0', [atts.Category2]);
    const category3 = useMemo(() => atts.Category3 ?? '0', [atts.Category3]);
    const type = useMemo(() => atts.Type ?? '0', [atts.Type]);
    const industry = useMemo(() => atts.Industry ?? '0', [atts.Industry]);
    const featured = useMemo(() => atts.Featured ?? 'off', [atts.Featured]);

    const country = useMemo(() => recordAtts.country ?? '', [recordAtts.country]);
    const region = useMemo(() => recordAtts.region ?? '', [recordAtts.region]);
    const location = useMemo(() => recordAtts.location ?? '', [recordAtts.location]);

    useEffect(() => attributeChangeHandler && attributeChangeHandler(jobBoardId, 'CountryCode', country), [attributeChangeHandler, country, jobBoardId]);
    useEffect(() => attributeChangeHandler && attributeChangeHandler(jobBoardId, 'Region', region), [attributeChangeHandler, region, jobBoardId]);
    useEffect(() => attributeChangeHandler && attributeChangeHandler(jobBoardId, 'Location', location), [attributeChangeHandler, location, jobBoardId]);

    const localChangeHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        attributeChangeHandler && attributeChangeHandler(jobBoardId, name, value);
    }, [attributeChangeHandler, jobBoardId]);

    return (
        <Box display="flex" gap={2} pt={2}>
            <Box display="flex" flexDirection="column" gap={2} width="50%">
                <TextField select label="Category 1" value={category1} name="Category1" onChange={localChangeHandler}>
                    {categoryOptions}
                </TextField>
                <TextField select label="Category 2" value={category2} name="Category2" onChange={localChangeHandler}>
                    {categoryOptions}
                </TextField>
                <TextField select label="Category 3" value={category3} name="Category3" onChange={localChangeHandler}>
                    {categoryOptions}
                </TextField>
                <TextField disabled label="Country" value={country} />
                <TextField disabled label="Region" value={region} />
                <TextField disabled label="Location" value={location} />
            </Box>
            <Box display="flex" flexDirection="column" gap={2} width="50%">
                <TextField select label="Type" name="Type" value={type} onChange={localChangeHandler}>
                    <MenuItem value="0"></MenuItem>
                    <MenuItem value="P">Permanent</MenuItem>
                    <MenuItem value="C">Contract</MenuItem>
                    <MenuItem value="B">Permanent/Contract</MenuItem>
                    <MenuItem value="S">Part Time/Temp/Seasonal</MenuItem>
                </TextField>
                <TextField select label="Industry" name="Industry" value={industry} onChange={localChangeHandler}>
                    <MenuItem value="0"></MenuItem>
                    <MenuItem value="13">Advertising, Media & Entertainment</MenuItem>
                    <MenuItem value="26">Agriculture, Forestry & Fishing</MenuItem>
                    <MenuItem value="09">Call Centre & Customer Services</MenuItem>
                    <MenuItem value="24">Community & Sport</MenuItem>
                    <MenuItem value="11">Construction & Civil Engineering</MenuItem>
                    <MenuItem value="25">Consulting & Corporate Strategy</MenuItem>
                    <MenuItem value="18">Defence, Military & Armed Forces</MenuItem>
                    <MenuItem value="20">Education</MenuItem>
                    <MenuItem value="03">Engineering</MenuItem>
                    <MenuItem value="02">Finance, Accounting & Banking</MenuItem>
                    <MenuItem value="14">Food & Hospitality</MenuItem>
                    <MenuItem value="08">Healthcare & Medical</MenuItem>
                    <MenuItem value="10">HR & Recruitment</MenuItem>
                    <MenuItem value="01">IT & Telecommunications</MenuItem>
                    <MenuItem value="07">Legal</MenuItem>
                    <MenuItem value="21">Manufacturing</MenuItem>
                    <MenuItem value="15">Mining, Energy, Oil & Gas</MenuItem>
                    <MenuItem value="06">Office & Administration</MenuItem>
                    <MenuItem value="27">Real Estate & Property</MenuItem>
                    <MenuItem value="17">Retail & Consumer Products</MenuItem>
                    <MenuItem value="04">Sales & Marketing</MenuItem>
                    <MenuItem value="16">Science, Biotech & Pharmaceuticals</MenuItem>
                    <MenuItem value="23">Self-Employment</MenuItem>
                    <MenuItem value="22">Trades & Services</MenuItem>
                    <MenuItem value="12">Transport & Logistics</MenuItem>
                    <MenuItem value="19">Travel & Tourism</MenuItem>
                    <MenuItem value="28">Other</MenuItem>
                </TextField>
                <TextField select label="Featured?" name="Featured" value={featured} onChange={localChangeHandler}>
                    <MenuItem value="off">No</MenuItem>
                    <MenuItem value="on">Yes</MenuItem>
                </TextField>
            </Box>
        </Box>
    );
}