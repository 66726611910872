import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { DataGridPremium, GridColDef, GridRenderCellParams, GridRowSelectionModel } from "@mui/x-data-grid-premium";
import moment from "moment";
import React, { useCallback, useMemo, useState } from "react";
import { Client } from "common/models/Clients";
import { MergeClients } from "services/ClientsService";

interface Props {
    open: boolean,
    clients: Client[],
    closeHandler: () => void,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
    successHandler?: (message: string) => void
}

export default function MergeClientsDialog({ open, clients, closeHandler, loadingHandler, errorHandler, successHandler }: Props) {
    const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);

    const columns = useMemo<GridColDef[]>(() => {
        const dateRenderer = (params: GridRenderCellParams) => {
            const m = moment(params.value);
            if (m.isValid() && m.get('year') > 1) {
                return m.format('DD MMM YYYY');
            }
            return 'Never';
        };

        return [
            { field: 'legalName', headerName: 'Name', sortable: false, flex: 1 },
            { field: 'primaryContactName', headerName: 'Primary Contact', sortable: false, width: 150 },
            { field: 'address1', headerName: 'Location', sortable: false, width: 250 },
            { field: 'statusName', headerName: 'Status', sortable: false, width: 150 },
            { field: 'lastContactDate', headerName: 'Last Contact', width: 150, renderCell: dateRenderer, sortable: false },
        ];
    }, []);

    const selectionChangeHandler = useCallback((sm: GridRowSelectionModel) => {
        if (sm.length > 1) {
            const last = sm[sm.length - 1];
            setSelectionModel([last]);
            return;
        }
        setSelectionModel(sm);
    }, []);

    const mergeHandlerCallback = useCallback(async () => {
        if (selectionModel.length === 1) {
            const selectedId = selectionModel[0].valueOf() as number;
            loadingHandler && loadingHandler(true);
            let clientIds: number[] = [];
            for (let i = 0; i < clients.length; i++) {
                const client = clients[i];
                if (client.id !== selectedId) clientIds.push(client.id);
            }
            const res = await MergeClients(selectedId, clientIds, errorHandler);
            if (res) {
                successHandler && successHandler('Records have been queued for merging. This process takes approximately 5 minutes.');
                setSelectionModel([]);
                closeHandler();
            }
            loadingHandler && loadingHandler(false);
        }
    }, [closeHandler, errorHandler, loadingHandler, successHandler, selectionModel, clients]);

    return (
        <Dialog open={open} maxWidth="lg" fullWidth PaperProps={{ sx: { height: '645px' } }}>
            <DialogTitle>Clients Merge</DialogTitle>
            <DialogContent sx={{ display: 'flex' }}>
                <div style={{ paddingTop: '10px', display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                    Select a master record to remain and merge the other record(s) into
                    <DataGridPremium
                        rowSelectionModel={selectionModel}
                        onRowSelectionModelChange={ selectionChangeHandler }
                        density="compact"
                        disableColumnMenu
                        disableColumnReorder
                        disableColumnResize
                        checkboxSelection
                        hideFooter
                        columns={columns}
                        rows={clients}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="error" onClick={ closeHandler }>Cancel</Button>
                <Button variant="contained" color="success" disabled={ selectionModel.length !== 1 } onClick={ mergeHandlerCallback }>Merge</Button>
            </DialogActions>
        </Dialog>
    );
}