import { ErrorHandler, RequestOptions, StringValueWrapper } from "common/models/GenericTypes";
import { JXTBoardSetupData } from "common/models/JobPosting/JXT";
import { JobBoardFileUploadResult } from "common/models/JobPosting/JobBoardFileUpload";
import { JobBoardFieldSetupValue } from "common/models/JobPosting/JobBoards";
import { RefariJobBoardSetupData } from "common/models/JobPosting/Refari";
import { ShazammeBoardSetupData } from "common/models/JobPosting/Shazamme";
import { VolcanicDiscipline } from "common/models/JobPosting/Volcanic";
import { Delete, Get, PostFile } from "util/HttpUtils";

export async function GetGenericApplyLink(jobBoardId: number, advertId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<StringValueWrapper>(`api/jobposting/${advertId}/JobBoards/${jobBoardId}/GenericLink`, options);
}

export async function LinkedInSocialUploadImage(jobBoardId: number, advertId: number, file: File, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    const formData = new FormData();
    formData.append('file', file);

    return PostFile<JobBoardFileUploadResult>(`api/jobposting/${advertId}/JobBoards/${jobBoardId}/File`, formData, options);
}

export async function LinkedInSocialDeleteImage(jobBoardId: number, advertId: number, realName: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Delete<boolean>(`api/jobposting/${advertId}/JobBoards/${jobBoardId}/File/${realName}`, options);
}

export async function GetShazammeBoardSetupData(jobBoardId: number, advertId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<ShazammeBoardSetupData>(`api/jobposting/${advertId}/JobBoards/${jobBoardId}/Shazamme`, options);
}

export async function GetJXTBoardSetupData(jobBoardId: number, advertId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<JXTBoardSetupData>(`api/jobposting/${advertId}/JobBoards/${jobBoardId}/JXT`, options);
}

export async function GetVolcanicBoardSetupData(jobBoardId: number, advertId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<VolcanicDiscipline[]>(`api/jobposting/${advertId}/JobBoards/${jobBoardId}/Volcanic`, options);
}

export async function GetJobBoardFieldSetupValues(jobBoardId: number, advertId: number, valueType: string, parentValue: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    const vt = encodeURIComponent(valueType);
    const pv = encodeURIComponent(parentValue);

    return Get<JobBoardFieldSetupValue[]>(`api/jobposting/${advertId}/JobBoards/${jobBoardId}/Values?valueType=${vt}&parentValue=${pv}`, options);
}

export async function GetRefariBoardSetupData(jobBoardId: number, advertId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<RefariJobBoardSetupData>(`api/jobposting/${advertId}/JobBoards/${jobBoardId}/Refari`, options);
}

export async function GetSeekBoardSetupData(jobBoardId: number, advertId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<any>(`api/jobposting/${advertId}/JobBoards/${jobBoardId}/Seek`, options);
}