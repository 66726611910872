import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { SxProps } from "@mui/material/styles";
import { AdvertLocation } from "common/models/JobPosting/Locations";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { GetAdvertsLocations } from "services/ConfigurationService";

interface Props {
    countryId: number,
    regionId: number,
    locationId: number,
    onCountryChange: (location: AdvertLocation | null) => void,
    onRegionChange: (location: AdvertLocation | null) => void,
    onLocationChange: (location: AdvertLocation | null) => void,
    variant?: "standard" | "filled" | "outlined" | undefined,
    sx?: SxProps,
    error1?: boolean,
    error2?: boolean,
    error3?: boolean,
}

export default function AdvertLocationsPicker({ countryId, regionId, locationId, error1 = false, error2 = false, error3 = false, variant, sx, onCountryChange, onRegionChange, onLocationChange }: Props) {
    const [countries, setCountries] = useState<AdvertLocation[]>([]);
    const [regionsMap, setRegionsMap] = useState<Record<number, AdvertLocation[]>>({});
    const [locationsMap, setLocationsMap] = useState<Record<number, AdvertLocation[]>>({});
    
    const countryChangeHandler = useCallback((l: AdvertLocation | null) => {
        onRegionChange(null);
        onLocationChange(null);
        onCountryChange(l);
    }, [onCountryChange, onLocationChange, onRegionChange]);
    
    const regionChangeHandler = useCallback((l: AdvertLocation | null) => {
        onLocationChange(null);
        onRegionChange(l);
    }, [onLocationChange, onRegionChange]);

    useEffect(() => {
        const getSetupData = async () => {
            const res = await GetAdvertsLocations();
            if (res) {
                let idToIndexMap: Record<number, number> = {};
                let countriesTmp: AdvertLocation[] = [];
                let regionsTmp: Record<number, AdvertLocation[]> = {};
                let locationsTmp: Record<number, AdvertLocation[]> = {};
                
                for (let i = 0; i < res.length; i++) {
                    const item = res[i];
                    idToIndexMap[item.id] = i;
                    if (item.parentID === 0) countriesTmp.push(item);
                }

                for (let i = 0; i < res.length; i++) {
                    const item = res[i];
                    if (item.parentID === 0) continue;

                    const parent1 = res[idToIndexMap[item.parentID]];
                    if (parent1.parentID === 0) {
                        const r = regionsTmp[parent1.id];
                        if (r) r.push(item);
                        else regionsTmp[item.parentID] = [item];
                        continue;
                    }

                    const parent2 = res[idToIndexMap[parent1.parentID]];
                    if (parent2.parentID === 0) {
                        const l = locationsTmp[parent1.id];
                        if (l) l.push(item);
                        else locationsTmp[item.parentID] = [item];
                    }
                }

                setCountries(countriesTmp);
                setRegionsMap(regionsTmp);
                setLocationsMap(locationsTmp);
            }
        };
        getSetupData();
    }, []);

    const regions = useMemo(() => {
        return regionsMap[countryId] ?? [];
    }, [countryId, regionsMap]);

    const locations = useMemo(() => {
        return locationsMap[regionId] ?? [];
    }, [regionId, locationsMap]);

    const selectedCountry = useMemo(() => {
        const item = countries.find(c => c.id === countryId);
        if (item) return item;
        if (countryId !== 0) countryChangeHandler(null);
        return null;
    }, [countries, countryChangeHandler, countryId]);

    const selectedRegion = useMemo(() => {
        const item = regions.find(c => c.id === regionId);
        if (item) return item;
        if (regionId !== 0) regionChangeHandler(null);
        return null;
    }, [regionChangeHandler, regionId, regions]);

    const selectedLocation = useMemo(() => {
        const item = locations.find(c => c.id === locationId);
        if (item) return item;
        if (locationId !== 0) onLocationChange(null);
        return null;
    }, [locations, locationId, onLocationChange]);

    return (
        <Box display="flex" gap="5px">
            <Autocomplete
                size="small"
                value={selectedCountry}
                options={countries}
                getOptionLabel={o => o.name}
                isOptionEqualToValue={(o, v) => o.id === v.id}
                onChange={ (e, v) => countryChangeHandler(v) }
                sx={sx}
                renderInput={ params => <TextField {...params} variant={variant} label="Country" error={error1} inputProps={{ ...params.inputProps, "data-lpignore": "true" }} /> }
            />
            <Autocomplete
                size="small"
                value={selectedRegion}
                options={regions}
                getOptionLabel={o => o.name}
                isOptionEqualToValue={(o, v) => o.id === v.id}
                onChange={ (e, v) => regionChangeHandler(v) }
                sx={sx}
                renderInput={ params => <TextField {...params} variant={variant} label="Region" error={error2} inputProps={{ ...params.inputProps, "data-lpignore": "true" }} /> }
            />
            <Autocomplete
                size="small"
                value={selectedLocation}
                options={locations}
                getOptionLabel={o => o.name}
                isOptionEqualToValue={(o, v) => o.id === v.id}
                onChange={ (e, v) => onLocationChange(v) }
                sx={sx}
                renderInput={ params => <TextField {...params} variant={variant} label="Location" error={error3} inputProps={{ ...params.inputProps, "data-lpignore": "true" }} /> }
            />
        </Box>
    );
}