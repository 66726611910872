import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { GetServerStatus } from "services/CommonService";
import { getJwtPayload } from "util/AuthUtils";

interface Props {
    open: boolean,
    closeHandler: () => void
}

interface ServerInfoData {
    userID: number,
    userName: string,
    customerName: string,
    version: string,
    informationalVersion: string,
    runTimeVersion: string,
    serverName: string,
    userAgent: string,
    environment: string
}

const ServerInfoDataStyle: React.CSSProperties = { paddingBottom: '10px' };

const defaultServerInfoData: ServerInfoData = {
    userID: 0,
    userName: '',
    customerName: '',
    version: '',
    informationalVersion: '',
    runTimeVersion: '',
    serverName: '',
    userAgent: '',
    environment: ''
};

export default function ServerInfoDialog({ open, closeHandler }: Props) {
    const [serverInfoData, setServerInfoData] = useState(defaultServerInfoData);

    useEffect(() => {
        const getData = async () => {
            let data = {...defaultServerInfoData};
            data.userAgent = navigator.userAgent;
            const p = getJwtPayload();
            if (p) {
                data.customerName = p.CustomerName;
                data.userID = p.UserID;
                data.userName = p.UserName;
            }
            const s = await GetServerStatus();
            if (s) {
                data.version = s.version;
                data.informationalVersion = s.informationalVersion;
                data.runTimeVersion = s.runTimeVersion;
                data.serverName = s.serverName;
                data.environment = s.environment;
            }
            setServerInfoData(data);
        };
        getData();
    }, []);

    return (
        <Dialog open={open} maxWidth={false} fullWidth>
        <DialogTitle>Server Info</DialogTitle>
        <DialogContent dividers>
            <DialogContentText component="div">
                <div style={ ServerInfoDataStyle }>
                    <Typography gutterBottom component="span" variant="h6">Server Name: </Typography> {serverInfoData.serverName}
                </div>
                <div style={ServerInfoDataStyle}>
                    <Typography component="span" variant="h6">Environment: </Typography> {serverInfoData.environment}
                </div>
                <div style={ServerInfoDataStyle}>
                    <Typography component="span" variant="h6">Runtime: </Typography> {serverInfoData.runTimeVersion}
                </div>
                <div style={ ServerInfoDataStyle }>
                    <Typography component="span" variant="h6">Version: </Typography> {serverInfoData.version}
                </div>
                <div style={ ServerInfoDataStyle }>
                    <Typography component="span" variant="h6">Informational Version: </Typography> {serverInfoData.informationalVersion}
                </div>
                <div style={ ServerInfoDataStyle }>
                    <Typography gutterBottom component="span" variant="h6">User Agent: </Typography> {serverInfoData.userAgent}
                </div>
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button variant="contained" color="success" onClick={ closeHandler }>
                Ok
            </Button>
        </DialogActions>
    </Dialog>
    );
}