import React, { useCallback, useMemo, useState } from "react";
import { ListItemText, MenuItem } from "@mui/material";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

import { TopBarRoute } from "common/models/TopBarTypes";
import NavBarSubmenuContainer from "./NavBarSubmenuContainer";
import { Link } from "react-router-dom";

interface Props {
    option: TopBarRoute,
    elementIndex: number,
    hoveredSubIndex: number | null,
    isRootHovered: boolean,
    changeHoveredSubIndex: (index: number | null) => void,
    showCreateOpportunityDialogHandler?: () => void,
}

const hideSubMenu = new CustomEvent('hideNavSubMenu');

export default function NavBarSubmenuElement({ changeHoveredSubIndex, elementIndex, isRootHovered, hoveredSubIndex, option, showCreateOpportunityDialogHandler }: Props) {
    const [anchor, setAnchor] = useState<HTMLElement | null>(null);
    
    const shouldRender = useMemo(() => {
        if (option.allow && !option.allow()) return false;
        return true;
    }, [option]);

    const hasOptions = useMemo(() => (option.options && option.options.length > 0), [option.options]);
    const isHovered = useMemo(() => (elementIndex === hoveredSubIndex), [elementIndex, hoveredSubIndex]);

    const handleHover = useCallback((e: React.MouseEvent<HTMLElement>) => {
        changeHoveredSubIndex(elementIndex);
        setAnchor(e.currentTarget);
    }, [changeHoveredSubIndex, elementIndex]);

    const handleClose = useCallback(() => {
        document.dispatchEvent(hideSubMenu);
    }, []);

    if (!shouldRender) return <></>;

    if (option.path === '/opportunities/create' && showCreateOpportunityDialogHandler) {
        return (
            <MenuItem onMouseEnter={handleHover} onClick={showCreateOpportunityDialogHandler} >
                <ListItemText primaryTypographyProps={{ fontSize: '0.885rem' }}>{option.text}</ListItemText>
            </MenuItem>
        );
    }
    return (
        <>
            <Link onClick={handleClose} to={option.path} style={{ color: 'inherit', textDecoration: 'none' }}>
                <MenuItem
                    onMouseEnter={handleHover}
                    sx={hasOptions ? { paddingRight: 0 } : undefined}
                >
                    <ListItemText primaryTypographyProps={{ fontSize: '0.885rem' }}>{option.text}</ListItemText>
                    {hasOptions &&
                        <ArrowRightIcon />
                    }
                </MenuItem>
            </Link>
            {(isHovered && hasOptions && Boolean(anchor)) &&
                <NavBarSubmenuContainer
                    anchor={anchor}
                    options={option.options}
                    closeSubmodule={() => { }}
                    isRootHovered={isRootHovered}
                    isInternal
                />
            }
        </>
    );
}
