import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-premium";
import { DataGridPremium } from "@mui/x-data-grid-premium/DataGridPremium";
import React, { useMemo } from "react";
import { TopPerformersRecord } from "common/models/Analytics/BusinessIntelligence";

interface Props {
    data: TopPerformersRecord[] | null
}

interface CustomTopPerformersRecordWithId extends TopPerformersRecord {
    id: number,
}

export default function TopPerformersTableGraph({ data: dataProp }: Props) {
    const data = useMemo<CustomTopPerformersRecordWithId[] | null>(() => {
        if (dataProp) return dataProp.map((r, i) => ({ ...r, id: i }));
        return null;
    }, [dataProp]);

    const columns = useMemo<GridColDef[]>(() => {
        const tooltipRenderer = (params: GridRenderCellParams) => {
            if (params.colDef.field === 'total')
                return <div title={params.value}>{(params.value as number).toLocaleString()}</div>
            return <div title={params.value}>{params.value}</div>;
        };
        
        return [
            { field: 'consultant', headerName: 'Consultant', headerAlign: 'center', disableColumnMenu: true, flex: 1, renderCell: tooltipRenderer },
            { field: 'total', headerName: 'Total', headerAlign: 'center', align: 'right', disableColumnMenu: true, flex: 1, renderCell: tooltipRenderer },
        ];
    }, []);

    return (
        <Paper sx={{ height: '100%', width: '100%' }}>
            <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                <Typography variant="h6" padding={1} textAlign="center" fontWeight={600} component="div">Top Performers - This Month</Typography>
                <DataGridPremium
                    density="compact"
                    loading={data === null}
                    rows={data ?? []}
                    columns={columns}
                    disableRowSelectionOnClick
                    hideFooter
                />
            </div>
        </Paper>
    );
}