import { ChangeTracker } from "common/models/hooks/ChangeTracker";
import { MinOpportunity, Opportunity } from "common/models/Opportunities";

export function OpportunityToMinOpportunity(o: Opportunity): MinOpportunity {
    return {
        id: o.id,
        name: o.name,
        type: o.type,
        statusID: o.statusID,
        statusName: o.statusName,
        stageID: o.stageID,
        value: o.value,
        probability: o.probability,
        currency: o.currency,
        startDate: o.startDate,
        closeDate: o.closeDate,
        deadline: o.deadline,
        clientID: o.clientID,
        contactID: o.contactID,
        candidateID: o.candidateID,
        divisions: o.divisions,
        description: o.description,
        customField1: o.customField1, customField2: o.customField2, customField3: o.customField3, customField4: o.customField4, customField5: o.customField5, customField6: o.customField6, customField7: o.customField7, customField8: o.customField8, customField9: o.customField9, customField10: o.customField10,
        customField11: o.customField11, customField12: o.customField12, customField13: o.customField13, customField14: o.customField14, customField15: o.customField15, customField16: o.customField16, customField17: o.customField17, customField18: o.customField18, customField19: o.customField19, customField20: o.customField20,
        customField21: o.customField21, customField22: o.customField22, customField23: o.customField23, customField24: o.customField24, customField25: o.customField25, customField26: o.customField26, customField27: o.customField27, customField28: o.customField28, customField29: o.customField29, customField30: o.customField30, 
        customField31: o.customField31, customField32: o.customField32, customField33: o.customField33, customField34: o.customField34, customField35: o.customField35, customField36: o.customField36, customField37: o.customField37, customField38: o.customField38, customField39: o.customField39, customField40: o.customField40, 
        customField41: o.customField41, customField42: o.customField42, customField43: o.customField43, customField44: o.customField44, customField45: o.customField45, customField46: o.customField46, customField47: o.customField47, customField48: o.customField48, customField49: o.customField49, customField50: o.customField50, 
        customField51: o.customField51, customField52: o.customField52, customField53: o.customField53, customField54: o.customField54, customField55: o.customField55, customField56: o.customField56, customField57: o.customField57, customField58: o.customField58, customField59: o.customField59, customField60: o.customField60
    };
}

export const DefaultMinOpportunity: MinOpportunity = {
    id: 0,
    name: '',
    type: 0,
    statusID: 1,
    statusName: 'Open',
    stageID: 1,
    value: 0,
    probability: 0,
    currency: 0,
    startDate: '',
    closeDate: '',
    deadline: '',
    clientID: 0,
    contactID: 0,
    candidateID: 0,
    divisions: '',
    description: '',
    customField1: '', customField2: '', customField3: '', customField4: '', customField5: '', customField6: '', customField7: '', customField8: '', customField9: '', customField10: '',
    customField11: undefined, customField12: undefined, customField13: undefined, customField14: undefined, customField15: undefined,
    customField16: '0001-01-01T00:00:00', customField17: '0001-01-01T00:00:00', customField18: '0001-01-01T00:00:00', customField19: '0001-01-01T00:00:00', customField20: '0001-01-01T00:00:00',
    customField21: '', customField22: '', customField23: '', customField24: '', customField25: '', customField26: '', customField27: '', customField28: '', customField29: '', customField30: '',
    customField31: undefined, customField32: undefined, customField33: undefined, customField34: undefined, customField35: undefined,
    customField36: '0001-01-01T00:00:00', customField37: '0001-01-01T00:00:00', customField38: '0001-01-01T00:00:00', customField39: '0001-01-01T00:00:00', customField40: '0001-01-01T00:00:00',
    customField41: '', customField42: '', customField43: '', customField44: '', customField45: '', customField46: '', customField47: '', customField48: '', customField49: '', customField50: '',
    customField51: undefined, customField52: undefined, customField53: undefined, customField54: undefined, customField55: undefined,
    customField56: '0001-01-01T00:00:00', customField57: '0001-01-01T00:00:00', customField58: '0001-01-01T00:00:00', customField59: '0001-01-01T00:00:00', customField60: '0001-01-01T00:00:00',
};

export const DefaultMinOpportunityNoChanges: ChangeTracker<MinOpportunity> = {
    id: false,
    name: false,
    type: false,
    statusID: false,
    statusName: false,
    stageID: false,
    value: false,
    probability: false,
    currency: false,
    startDate: false,
    closeDate: false,
    deadline: false,
    clientID: false,
    contactID: false,
    candidateID: false,
    divisions: false,
    description: false,
    customField1: false, customField2: false, customField3: false, customField4: false, customField5: false, customField6: false, customField7: false, customField8: false, customField9: false, customField10: false,
    customField11: false, customField12: false, customField13: false, customField14: false, customField15: false, customField16: false, customField17: false, customField18: false, customField19: false, customField20: false,
    customField21: false, customField22: false, customField23: false, customField24: false, customField25: false, customField26: false, customField27: false, customField28: false, customField29: false, customField30: false, 
    customField31: false, customField32: false, customField33: false, customField34: false, customField35: false, customField36: false, customField37: false, customField38: false, customField39: false, customField40: false, 
    customField41: false, customField42: false, customField43: false, customField44: false, customField45: false, customField46: false, customField47: false, customField48: false, customField49: false, customField50: false, 
    customField51: false, customField52: false, customField53: false, customField54: false, customField55: false, customField56: false, customField57: false, customField58: false, customField59: false, customField60: false
};

export const PlaceholderOpportunity: Opportunity = {
    id: -1,
    recruiterID: 0,
    type: 5,
    typeName: 'General',
    statusID: 1,
    statusName: 'Open',
    stageID: 1,
    stageName: 'Lead',
    name: 'Placeholder Opportunity Name',
    employerName: null,
    clientID: -1,
    contactID: -1,
    candidateID: -1,
    candidateName: 'Placeholder Candidate Name',
    clientName: 'Recruit Wizard Pty Ltd',
    contactName: 'Glen Perry',
    description: '',
    probability: 75.25,
    value: 50.25,
    currency: 9,
    updatedByID: 0,
    closeDate: '2010-10-10T10:10:10.100',
    startDate: '2010-10-10T10:10:10.100',
    deadline: '2010-10-10T10:10:10.100',
    createdById: 0,
    createdByName: '',
    updatedById: 0,
    updatedByName: '',
    createdDate: '',
    updatedDate: '',
    lastActivityDate: '',
    nextActivityDate: '',
    deleted: false,
    customField1: 'Custom Field 1',
    customField2: 'Custom Field 2',
    customField3: 'Custom Field 3',
    customField4: 'Custom Field 4',
    customField5: 'Custom Field 5',
    customField6: 'Custom Field 6',
    customField7: 'Custom Field 7',
    customField8: 'Custom Field 8',
    customField9: 'Custom Field 9',
    customField10: 'Custom Field 10',
    customField11: 11,
    customField12: 12,
    customField13: 13,
    customField14: 14,
    customField15: 15,
    customField16: '2010-10-10T10:10:10.100',
    customField17: '2010-10-10T10:10:10.100',
    customField18: '2010-10-10T10:10:10.100',
    customField19: '2010-10-10T10:10:10.100',
    customField20: '2010-10-10T10:10:10.100',
    customField21: 'Custom Field 21',
    customField22: 'Custom Field 22',
    customField23: 'Custom Field 23',
    customField24: 'Custom Field 24',
    customField25: 'Custom Field 25',
    customField26: 'Custom Field 26',
    customField27: 'Custom Field 27',
    customField28: 'Custom Field 28',
    customField29: 'Custom Field 29',
    customField30: 'Custom Field 30',
    customField31: 31,
    customField32: 32,
    customField33: 33,
    customField34: 34,
    customField35: 35,
    customField36: '2010-10-10T10:10:10.100',
    customField37: '2010-10-10T10:10:10.100',
    customField38: '2010-10-10T10:10:10.100',
    customField39: '2010-10-10T10:10:10.100',
    customField40: '2010-10-10T10:10:10.100',
    customField41: 'Custom Field 41',
    customField42: 'Custom Field 42',
    customField43: 'Custom Field 43',
    customField44: 'Custom Field 44',
    customField45: 'Custom Field 45',
    customField46: 'Custom Field 46',
    customField47: 'Custom Field 47',
    customField48: 'Custom Field 48',
    customField49: 'Custom Field 49',
    customField50: 'Custom Field 50',
    customField51: 51,
    customField52: 52,
    customField53: 53,
    customField54: 54,
    customField55: 55,
    customField56: '2010-10-10T10:10:10.100',
    customField57: '2010-10-10T10:10:10.100',
    customField58: '2010-10-10T10:10:10.100',
    customField59: '2010-10-10T10:10:10.100',
    customField60: '2010-10-10T10:10:10.100',
    tags: false,
    divisions: '',
    owners: '',
    statisticsDates: [],
    statisticsCounts: []
};