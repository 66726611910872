import React, { useCallback, useEffect, useMemo, useState } from "react";
import PageContentLayout from "layouts/PageContentLayout";
import Snackbar from "@mui/material/Snackbar";
import Alert from "components/Alert";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import ComplianceItemPicker from "components/Pickers/ComplianceItemPicker";
import { ComplianceChecklistItemsCreate, ComplianceItem } from "common/models/Configuration/Compliance";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import DeleteIcon from '@mui/icons-material/Delete';
import { CreateComplianceChecklist, GetComplianceChecklistById, UpdateComplianceChecklist } from "services/ComplianceService";
import { useNavigate, useParams } from "react-router";
import RWTextFieldComponent from "components/RWTextFieldComponent";

interface Props {
    setSummaryBar?: (summaryBar: JSX.Element) => void,
}

export default function ComplianceChecklistRecordPage({ setSummaryBar }: Props) {
    const [name, setName] = useState('');
    const [status, setStatus] = useState(0);
    const [isloading, setIsLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [items, setItems] = useState<ComplianceItem[]>([]);
    const navigate = useNavigate();
    const params = useParams();

    const checklistId = useMemo(() => {
        const parsedId = +(params.id ?? '0');
        if(isNaN(parsedId)) return 0;
        return parsedId
    }, [params.id]);

    useEffect(() => {
        const getData = async () => {
            setIsLoading(true);
            const res = await GetComplianceChecklistById(checklistId, setErrorMessage);
            if (res) {
                setName(res.name);
                setStatus(res.statusID);
                setItems(res.items);
            }
            setIsLoading(false);
        };
        checklistId > 0 && getData();
    }, [checklistId]);

    const saveHandler = useCallback(async () => {
        setIsLoading(true);
        if (!Boolean(name.trim())) {
            setErrorMessage('Checklist must have a name');
            setIsLoading(false);
            return;
        }
        if (items.length === 0) {
            setErrorMessage('At least one compliance item must be selected');
            setIsLoading(false);
            return;
        }

        const orderedItems: ComplianceChecklistItemsCreate[] = items.map((item, index) => ({ id: item.id, orderID: index }));

        if (checklistId === 0) {
            const res = await CreateComplianceChecklist({ name: name, statusID: status, items: orderedItems }, setErrorMessage);
            if (res) navigate('/configuration/compliance/checklists');
        }
        else if (checklistId > 0) {
            const res = await UpdateComplianceChecklist(checklistId, { name: name, statusID: status, items: orderedItems }, setErrorMessage);
            if (res) navigate('/configuration/compliance/checklists');
        }

        setIsLoading(false);
    }, [checklistId, name, status, items, navigate]);

    useEffect(() => {
        const action = <Button variant="contained" color="primary" onClick={saveHandler}>Save</Button>;
        const summaryBar = (
            <TitleAndActionSummaryBar
                title={'Configuration > Compliance > Checklists'}
                browserTabTitle="Compliance > Configuration"
                action={action}
            />
        );
        setSummaryBar && setSummaryBar(summaryBar);
    }, [setSummaryBar, saveHandler]);

    const selectedItemIds = useMemo(() => {
        return items.map(i => i.id);
    }, [items]);

    const addItem = useCallback((item: ComplianceItem | null) => {
        if (item) setItems(prev => [...prev, item]);
    }, []);

    const moveItemUp = useCallback((index: number) => {
        if (index === 0) return;
        setItems(prev => {
            let tmp = [...prev];
            const q = prev[index];
            tmp.splice(index, 1);
            tmp.splice(index -1, 0, q);
            return tmp;
        });
    }, []);

    const moveItemDown = useCallback((index: number, isLast: boolean) => {
        if(isLast) return;
        setItems(prev => {
            let tmp = [...prev];
            const q = prev[index];
            tmp.splice(index, 1);
            tmp.splice(index +1, 0, q);
            return tmp;
        });
    }, []);

    const onItemDelete = useCallback((index: number) => {
        setItems(prev => {
            let tmp = [...prev];
            tmp.splice(index, 1);
            return tmp;
        })
    }, []);
    
    return (
        <>
            <Snackbar open={successMessage !== ''} autoHideDuration={3000} onClose={() => setSuccessMessage('')}>
                <Alert onClose={() => setSuccessMessage('')}>{ successMessage }</Alert>
            </Snackbar>
            <Snackbar open={errorMessage !== ''}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <PageContentLayout showLoading={isloading}>
                <Stack spacing={2}>
                    <RWTextFieldComponent label="Name" value={name} onChange={(e) => setName(e.target.value)} />
                    <TextField select label="Status" value={status.toString()} onChange={({target}) => setStatus(+target.value)}>
                        <MenuItem value="0">Inactive</MenuItem>
                        <MenuItem value="1">Active</MenuItem>
                    </TextField>
                    <ComplianceItemPicker
                        itemId={null}
                        ignoreIds={selectedItemIds}
                        onSelect={ addItem }
                        label="Add Compliance Item"
                    />
                    <Box>
                        {items.map((v, i, a) => {
                            const isLast = i === (a.length -1);
                            return (
                                <Paper key={v.id} variant="outlined" sx={{ p: 2 }}>
                                    <Box display="flex" alignItems="center">
                                        <Typography component="span" pr="10px"># {i + 1}</Typography>
                                        <IconButton size="small" disabled={i === 0} onClick={() => moveItemUp(i)}>
                                            <ArrowUpwardIcon />
                                        </IconButton>
                                        <IconButton size="small" disabled={isLast} onClick={() => moveItemDown(i, isLast)}>
                                            <ArrowDownwardIcon />
                                        </IconButton>
                                        <Typography component="span" px="10px">{v.name}</Typography>
                                        <IconButton size="small" onClick={() => onItemDelete(i)} color="error" sx={{ marginLeft: 'auto' }}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </Box>
                                </Paper>
                            );
                        })}
                    </Box>
                </Stack>
            </PageContentLayout>
        </>
    );
}