import Alert from "components/Alert";
import Box from "@mui/material/Box";
import Snackbar from "@mui/material/Snackbar";
import React, { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import ActivitiesGridComponent from "components/Grids/ActivitiesGrid";
import UserPicker from "components/Pickers/UserPicker";
import OnlyTitleSummaryBar from "components/SummaryBars/OnlyTitleSummaryBar";
import PageContentLayout from "layouts/PageContentLayout";
import PageLayout from "layouts/PageLayout";
import { GetMyUser } from "services/UsersService";

export default function Activities() {
    const [consultantId, setConsultantId] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [searchParams] = useSearchParams();

    useEffect(() => {
        const getUser = async () => {
            const u = await GetMyUser();
            if (u) setConsultantId(u.userID);
        };
        getUser();
    }, []);

    const reportType = useMemo(() => {
        const param = searchParams.get('rt');
        return param ? param : 'OpenActivityTodayPrior';
    }, [searchParams]);

    const summaryBar = useMemo(() => {
        let title = ""
        switch (reportType) {
            case 'OpenActivityTodayPrior': title = 'Open Activity - Today + Prior';
                break;
            case 'OpenBDCallsTodayPrior': title = 'BD Calls - Today + Prior';
                break;
            case 'OpenActivityTomorrow': title = 'Open Activity - Tomorrow';
                break;
            case 'OpenActivityNext7Days': title = 'Open Activity - Next 7 Days';
                break;
            case 'OpenActivityAll': title = 'Open Activity - All';
                break;
            case 'ClosedActivityToday': title = 'Closed Activity - Today';
                break;
            case 'ClosedActivityYesterday': title = 'Closed Activity - Yesterday';
                break;
            case 'ClosedActivityLast7Days': title = 'Closed Activity - Last 7 Days';
                break;
            default: title = 'Open Activity - Today + Prior';
                break;
        }
        return <OnlyTitleSummaryBar title={title} />;
    }, [reportType]);

    return (
        <PageLayout SummaryBar={summaryBar}>
            <Snackbar open={successMessage !== ''} autoHideDuration={3000} onClose={() => setSuccessMessage('')}>
                <Alert onClose={() => setSuccessMessage('')}>{successMessage}</Alert>
            </Snackbar>
            <Snackbar open={errorMessage !== ''}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <PageContentLayout showLoading={isLoading}>
                <Box display="flex" mb={2}>
                    <Box flex="1 1 0">
                        <UserPicker
                            disableClearable
                            label="Consultant"
                            userId={consultantId}
                            onSelect={u => setConsultantId(u ? u.id : 0)}
                            includeInactiveUsers={true}
                        />
                    </Box>
                </Box>
                <ActivitiesGridComponent
                    gridName="ActivitiesSearch"
                    source="reports"
                    checkboxSelection
                    sourceId={consultantId}
                    reportType={reportType}
                    loadingHandler={setIsLoading}
                    successHandler={setSuccessMessage}
                    errorHandler={setErrorMessage}
                />
            </PageContentLayout>
        </PageLayout>
    );
}