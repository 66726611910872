import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { NameIdObj } from "common/models/GenericTypes";
import React, { useMemo } from "react"
import { SxProps } from "@mui/material/styles";

interface Props {
    value: number[],
    options: NameIdObj[],
    onSelect: ((values: NameIdObj[]) => void)
    variant?: "standard" | "filled" | "outlined" | undefined,
    label?: string,
    disableClearable?: boolean,
    blurOnSelect?: boolean,
    sx?: SxProps
}
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function MultipleNumericValuesPicker ({ value, options, label = "Values", variant, disableClearable, blurOnSelect, onSelect, sx }: Props) {
    const selectedOptions = useMemo(() => {
        let selected: NameIdObj[] = [];
        for (let i = 0; i < value.length; i++) {
            const v = value[i];
            const o = options.find(opt => opt.id === v);
            if (o) selected.push(o);
        }
        return selected;
    }, [options, value]);

    return (
        <Autocomplete
            value={selectedOptions}
            multiple
            size="small"
            disableCloseOnSelect
            options={options}
            disableClearable={disableClearable}
            blurOnSelect={blurOnSelect}
            getOptionLabel={o => o.name}
            onChange={(e,v,r,d) => onSelect(v)}
            sx={sx}
            renderOption={(props, option, {selected}) => (
                <li {...props}>
                    <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                    />
                    {option.name}
                </li>
            )}
            renderInput={(params) => (
                <TextField {...params} label={label} variant={variant} />
            )}
        />
    );
}