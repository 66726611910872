import React, { useCallback, useMemo } from "react";
import { EmailRecipient } from "common/models/EmailRecipient";
import RecipientsValidationDialog from "./Messages/RecipientsValidationDialog";
import * as XLSX from "xlsx";
import moment from "moment";

interface Props {
    open: boolean,
    entityType: 'clients' | 'contacts' | 'candidates' | 'jobs' | 'placements' | 'opportunities' | 'sites',
    filename: string,
    records: EmailRecipient[],
    includeTimeStamp?: boolean,
    closeHandler: () => void,
}

interface RecordData {
    "Record Type": number,
    "Name": string,
    "Email Address": string
}

export default function ExportRecordsDialog({ open, entityType, filename, records, includeTimeStamp = false, closeHandler }: Props) {

    const entityTypeId = useMemo(() => {
        if (entityType === 'clients') return 1;
        if (entityType === 'contacts') return 2;
        if (entityType === 'candidates') return 3;
        if (entityType === 'jobs') return 4;
        if (entityType === 'placements') return 5;
        if (entityType === 'opportunities') return 8;
        if (entityType === 'sites') return 9;
        return 0;
    }, [entityType]);

    const invalidRecords = useMemo(() => {
        return records.filter(r => r.id && !r.isOptOut && (r.email === '' || r.email === null || r.email === undefined));
    }, [records]);

    const noContactRecords = useMemo(() => {
        return records.filter(r => !r.id);
    }, [records]);

    const optOutRecords = useMemo(() => {
        return records.filter(r => r.isOptOut);
    }, [records]);

    const validRecords = useMemo(() => {
        return records.filter(r => r.email !== '' && r.email !== null && r.email !== undefined && !r.isOptOut);
    }, [records]);

    const exportToExcel = useCallback(() => {
        let ts = '';
        if (includeTimeStamp) ts = moment().format('YYYYMMDDHHmmss');
        let v: RecordData[] = [];
        if (entityType === 'clients') v = validRecords.map(r => ({ "Record Type": r.id, "Name": r.client ? r.client.primaryContactName : '', "Email Address": r.email }));
        else v = validRecords.map(r => ({ "Record Type": r.id, "Name": r.name, "Email Address": r.email }));
        const ws = XLSX.utils.json_to_sheet(v);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws);
        XLSX.writeFile(wb, filename + ts + '.xlsx');
        closeHandler();
    }, [validRecords, entityType, filename, includeTimeStamp, closeHandler]);

    return (
        <RecipientsValidationDialog
            open={open}
            closeHandler={closeHandler}
            continueHandler={exportToExcel}
            entityTypeId={entityTypeId}
            totalRecipients={records}
            invalidEmailOrPhoneRecipients={invalidRecords}
            noPrimaryContactRecipients={noContactRecords}
            optOutRecipients={optOutRecords}
            validRecipients={validRecords}
        />
    );
}