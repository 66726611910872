import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardHeader from "@mui/material/CardHeader";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import Input from "@mui/material/Input";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CancelIcon from '@mui/icons-material/Cancel';
import React, { useCallback, useMemo, useState } from "react";
import { TagValue } from "common/models/Configuration/Tags";
import { DefaultTagValue } from "util/Definitions/Tags";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import { IsAlphaNumericString } from "util/RegExUtils";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Virtuoso } from "react-virtuoso";

interface Props {
    typeId: number,
    isStructured: boolean,
    title: string,
    availableTags: TagValue[],
    assignedTags: TagValue[],
    minRequired?: number,
    addHandler: (typeId: number, tag: string) => void,
    removeHandler: (tag: TagValue) => void,
    createTagHandler: (tag: TagValue) => void,
    color?: string,
    textColor?: string,
}

export default function TagsSelectionList({
    typeId,
    isStructured,
    title,
    availableTags,
    assignedTags,
    color,
    textColor,
    minRequired = 0,
    addHandler,
    removeHandler,
    createTagHandler,
}: Props) {
    const [filterText, setFilterText] = useState('');
    const [newTagText, setNewTagText] = useState('');

    const assignedTagsRecord = useMemo(() => {
        let rec: Record<string, boolean> = {};
        for (let i = 0; i < assignedTags.length; i++) {
            const t = assignedTags[i];
            if (t.tagTypeID === typeId) rec[t.tag] = true;
        }
        return rec;
    }, [assignedTags, typeId]);

    const selectHandler = useCallback((tag: TagValue) => {
        if (!Boolean(assignedTagsRecord[tag.tag])) {
            addHandler(tag.tagTypeID, tag.tag);
            return;
        }

        removeHandler(tag);
    }, [assignedTagsRecord, removeHandler, addHandler]);

    const handleFilterTextChangeCallback = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setFilterText(e.target.value);
    }, []);

    const handleNewTagTextChangeCallback = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setNewTagText(e.target.value);
    }, []);

    const isNewTagValid = useMemo(() => {
        if (newTagText !== '') {
            return IsAlphaNumericString(newTagText);
        }
        return true;
    }, [newTagText]);

    const createTagCallback = useCallback(() => {
        if (isNewTagValid) {
            const existingTag = availableTags.find(t => t.tagTypeID === typeId && t.tag.toLowerCase() === newTagText.toLowerCase());
            if (existingTag) {
                addHandler(typeId, existingTag.tag);
                setNewTagText('');
                return;
            }
            createTagHandler({...DefaultTagValue, tagTypeID: typeId, tag: newTagText});
            setNewTagText('');
        }
    }, [addHandler, availableTags, createTagHandler, isNewTagValid, newTagText, typeId]);

    const handleNewTagTextEnterCallback = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
        if (newTagText !== '' && (e.code === 'Enter' || e.code === 'NumpadEnter') && isNewTagValid) {
            createTagCallback();
        }
    }, [newTagText, isNewTagValid, createTagCallback]);

    const filteredTags = useMemo(() => {
        if (filterText === '') return availableTags;
        return availableTags.filter(t => t.tag.toLowerCase().includes(filterText.toLowerCase()));
    }, [filterText, availableTags]);

    const minRequiredTitle = useMemo(() => {
        if (minRequired > 0) {
            const keys = Object.keys(assignedTagsRecord);
            return `(${keys.length} / ${minRequired})`;
        }
        return <></>;
    }, [assignedTagsRecord, minRequired]);

    const isCreateButtonDisabled = useMemo(() => (!Boolean(newTagText.trim()) || !isNewTagValid), [isNewTagValid, newTagText]);

    return (
        <Card sx={{ width: '250px', marginRight: '20px', marginBottom: '10px' }}>
            <CardHeader
                sx={{ py: 0, color: textColor, bgcolor: color, minHeight: '42px' }}
                title={<>{title} {minRequiredTitle}</>}
                titleTypographyProps={{ fontSize: '0.875rem', fontWeight: '600' }}
            />
            <Divider />
            <CardActions>
                <Input
                    value={ filterText }
                    onChange={ handleFilterTextChangeCallback }
                    fullWidth
                    placeholder="Filter"
                    endAdornment={ filterText === '' ? undefined : <IconButton size="small" onClick={ () => setFilterText('') } sx={{ p: 0 }}><CancelIcon /></IconButton> }
                />
            </CardActions>
            <List dense disablePadding sx={{ height: '200px', overflow: 'auto' }}>
                <Virtuoso
                    style={{ flexGrow: 1 }}
                    totalCount={filteredTags.length}
                    data={filteredTags}
                    computeItemKey={ (i, tag) => `${i}-${tag.tagTypeID}-${tag.tag}-${Boolean(assignedTagsRecord[tag.tag])}`}
                    itemContent={(i, tag) => {
                        return (
                            <ListItemButton onClick={ () => selectHandler(tag) } >
                                <ListItemIcon>
                                    <Checkbox checked={ assignedTagsRecord[tag.tag] } sx={{ p: 0 }} />
                                </ListItemIcon>
                                <ListItemText primary={tag.tag} sx={{ overflowWrap: 'break-word' }} />
                            </ListItemButton>
                        );
                    }}
                />
            </List>
            {!isStructured &&
                <CardActions>
                    <TextField
                        fullWidth
                        variant="standard"
                        placeholder="Add New Tags Here"
                        error={!isNewTagValid}
                        helperText={ isNewTagValid ? '' : 'No special characters allowed'}
                        value={ newTagText }
                        onChange={ handleNewTagTextChangeCallback }
                        onKeyUp={ handleNewTagTextEnterCallback }
                    />
                    <IconButton size="small" disabled={isCreateButtonDisabled} color={isCreateButtonDisabled ? undefined : 'success'} onClick={createTagCallback}><AddCircleIcon /></IconButton>
                </CardActions>
            }
        </Card>
    );
}