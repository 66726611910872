import React, { useCallback, useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";

interface Props {
    open: boolean,
    closeHandler: () => void,
}

export default function CreateOpportunityHandlerDialog({ open, closeHandler }: Props) {
    const [opportunityType, setOpporunityType] = useState(5);
    const navigate = useNavigate();

    useEffect(() => {
        if (open) {
            setOpporunityType(5);
        }
    }, [open]);

    const submitHandler = useCallback(() => {
        closeHandler();
        navigate(`/opportunities/create?type=${opportunityType}`);
    }, [closeHandler, navigate, opportunityType]);

    return (
        <>
            <Dialog open={open} fullWidth>
                <DialogTitle>Create Opportunity</DialogTitle>
                <DialogContent>
                    <Stack mt="10px" spacing={2}>
                            <TextField
                                select
                                value={opportunityType}
                                label="Type"
                                onChange={({ target }) => setOpporunityType(+target.value)}
                            >
                                    <MenuItem value="1">Back Fill</MenuItem>
                                    <MenuItem value="4">Bid</MenuItem>
                                    <MenuItem value="3">Float</MenuItem>
                                    <MenuItem value="5">General</MenuItem>
                                    <MenuItem value="6">Lead</MenuItem>
                                    <MenuItem value="2">Tender</MenuItem>
                            </TextField>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="error" onClick={ closeHandler }>Cancel</Button>
                    <Button variant="contained" color="success" onClick={submitHandler} >Submit</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}