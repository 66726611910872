import Button from "@mui/material/Button";
import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import AdvertScreeningQuestionnairePicker from "components/Pickers/AdvertScreeningQuestionnairePicker";
import { GetAdvertById, GetAvailableJobBoardsByAdvertId, GetScreeningQuestionnaireTemplate, PostAdvert, UpdateAdvert } from "services/AdvertsService";
import { ScreeningQuestionnaireTemplate } from "common/models/Configuration/AdvertScreeningQuestionnaire";
import MenuItem from "@mui/material/MenuItem";
import CurrencyPicker from "components/Pickers/CurrencyPicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import UserPicker from "components/Pickers/UserPicker";
import moment from "moment";
import { Editor } from "@tinymce/tinymce-react";
import useTheme from "@mui/material/styles/useTheme";
import { JobBoard } from "common/models/Configuration/JobBoard";
import { DataGridPremium, GridColDef, GridRenderCellParams, GridRowSelectionModel, useGridApiRef } from "@mui/x-data-grid-premium";
import AdvertClassificationsPicker from "components/Pickers/AdvertClassificationsPicker";
import AdvertLocationsPicker from "components/Pickers/AdvertLocationsPicker";
import { ChangeTracker } from "common/models/hooks/ChangeTracker";
import useObjectStateWithChangeTracker from "hooks/UseObjectStateWithChangeTracker";
import { GetMyUser } from "services/UsersService";
import Container from "@mui/material/Container";
import useUnsavedChangesDialog from "hooks/UseUnsavedChangesDialog";
import { Link, Navigate } from "react-router-dom";
import JobBoardLoaderComponent from "components/JobBoards/JobBoardLoader";
import { AdvertEdit } from "common/models/JobPosting/AdvertEdit";
import { JobBoardMetadata } from "common/models/JobPosting/JobBoardMetadata";
import { AdvertJobBoardAttributeEdit } from "common/models/JobPosting/AdvertJobBoardAttributeEdit";
import { Classification } from "common/models/JobPosting/Classifications";
import { AdvertLocation } from "common/models/JobPosting/Locations";
import { GetCurrencyById } from "services/CommonService";
import { AdvertAttribute } from "common/models/Advert";
import RWTextFieldComponent from "components/RWTextFieldComponent";

interface Props {
    advertId?: number,
    setSummaryBar?: (sb: JSX.Element) => void,
    loadingHandler?: (isLoading: boolean) => void,
    successHandler?: (message: string) => void,
    errorHandler?: (message: string) => void,
}

interface UiJobBoard extends JobBoard {
    adsRemaining: string,
    usage: string,
    warning: string,
    isSelected: boolean,
    isDisabled: boolean,
    attributes: AdvertAttribute[]
}

type UiJobBoardEditData = {
    id: number,
    typeId: number,
    name: string,
    attributes: Record<string, string>
}

type PayValueItem = { value: number, label: string };
type PayValueDescription = { start: number, iterations: number, increment: number };
type PayValues = { from: PayValueDescription, to: PayValueDescription };
type PayValuesMap = Record<number, PayValues>;
type NumberToNumberRecord = Record<number, number>;

const formatNumber = (value: number) => {
    return value.toLocaleString('en-US', { maximumFractionDigits: 2 });
};

const getWorkTypeName = (id: number) => {
    if (id === 1) return 'Casual';
    if (id === 2) return 'Full Time';
    if (id === 3) return 'Part Time';
    return '';
};

const getSalaryTypeName = (id: number) => {
    if (id === 1) return 'Hourly';
    if (id === 2) return 'Daily';
    if (id === 3) return 'Annual Salary';
    return '';
};

const FromValuesGenerator = (start: number, iterations: number, increment: number) => {
    let vals: PayValueItem[] = [];
    
    for (let i = 0; i < iterations; i++) {
        const v = start + (increment * i);
        vals.push({ value: v, label: formatNumber(v) });
    }

    return vals;
};

const ToValuesGenerator = (start: number, iterations: number, increment: number) => {
    let vals: PayValueItem[] = [];
    
    for (let i = 0; i < iterations; i++) {
        const v = start + (increment * (i + 1));
        vals.push({ value: v, label: formatNumber(v) });
    }

    return vals;
};

const payValuesMap: PayValuesMap = {
    1: { from: { start: 5, iterations: 50, increment: 5 }, to: { start: 0, iterations: 20, increment: 5 } }, // Hourly
    2: { from: { start: 100, iterations: 50, increment: 50 }, to: { start: 0, iterations: 20, increment: 50 } }, // Daily
    3: { from: { start: 5000, iterations: 100, increment: 5000 }, to: { start: 0, iterations: 50, increment: 5000 } }, // Yearly
};

const defaultAdvertEdit: AdvertEdit = {
    title: '',
    shortDescription: '',
    description: '',
    bullet1: '',
    bullet2: '',
    bullet3: '',
    classificationId: 0,
    subClassificationId: 0,
    countryId: 0,
    regionId: 0,
    locationId: 0,
    workTypeId: 0,
    payCurrencyId: 0,
    payFrom: 0,
    payTo: 0,
    payTypeId: 0,
    payDisplay: '',
    postingDate: '',
    screeningQuestionnaireTemplateId: 0,
    consultantId: 0,
    jobBoards: []
};

const noChangesAdvertEdit: ChangeTracker<AdvertEdit> = {
    title: false,
    shortDescription: false,
    description: false,
    bullet1: false,
    bullet2: false,
    bullet3: false,
    classificationId: false,
    subClassificationId: false,
    countryId: false,
    regionId: false,
    locationId: false,
    workTypeId: false,
    payCurrencyId: false,
    payFrom: false,
    payTo: false,
    payTypeId: false,
    payDisplay: false,
    postingDate: false,
    screeningQuestionnaireTemplateId: false,
    consultantId: false,
    jobBoards: false,
};



export default function AdvertEditPageContent({ advertId = 0, setSummaryBar, loadingHandler, successHandler, errorHandler }: Props) {
    const [isSetupFinished, setIsSetupFinished] = useState(false);
    const [isFetchingBoardData, setIsFetchingBoardData] = useState(false);
    const [isFetchingEditData, setIsFetchingEditData] = useState(false);
    const [advertStatusId, setAdvertStatusId] = useState(0);
    const [availableJobBoards, setAvailableJobBoards] = useState<UiJobBoard[]>([]);
    const [jobBoardEditData, setJobBoardEditData] = useState<UiJobBoardEditData[]>([]);
    const [activeStep, setActiveStep] = useState(0);
    const { state, init, change, updateInitial, hasChanges } = useObjectStateWithChangeTracker<AdvertEdit>(defaultAdvertEdit, noChangesAdvertEdit);
    const [classificationName, setClassificationName] = useState('');
    const [subClassificationName, setSubClassificationName] = useState('');
    const [countryName, setCountryName] = useState('');
    const [regionName, setRegionName] = useState('');
    const [locationName, setLocationName] = useState('');
    const [currencyCode, setCurrencyCode] = useState('');
    const [currencyName, setCurrencyName] = useState('');
    const [postingDate, setPostingDate] = useState<moment.Moment | null>(null);
    const [selectedQuestionnaire, setSelectedQuestionnaire] = useState<ScreeningQuestionnaireTemplate | null>(null);
    const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
    const [boardIdToIndexMap, setBoardIdToIndexMap] = useState<NumberToNumberRecord>({});
    const [savedChanges, setSavedChanges] = useState(false);
    const [showValidation1, setShowValidation1] = useState(false);
    const [showValidation2, setShowValidation2] = useState(false);
    const [errorMessage1Toggle, setErrorMessage1Toggle] = useState(false);
    const [errorMessage2Toggle, setErrorMessage2Toggle] = useState(false);
    const [isAttributesChanged, setIsAttributesChanged] = useState(false);
    const [isAdvertPosted, setIsAdvertPosted] = useState(false);
    const [validationMessageMap, setValidationMessageMap] = useState<Record<number, string>>({});
    const apiRef = useGridApiRef();
    const theme = useTheme();
    const isDarkTheme = useMemo(() => theme.palette.mode === 'dark', [theme.palette.mode]);

    useEffect(() => {
        const getAvailableJobBoards = async () => {
            setIsFetchingBoardData(true);
            const me = await GetMyUser();
            const res = await GetAvailableJobBoardsByAdvertId(advertId, me ? me.userID : 0, true, errorHandler);
            let selectionModelTmp: number[] = [];
            let idsMap: NumberToNumberRecord = {};
            let boardEditData: UiJobBoardEditData[] = [];
            if (res) {
                for (let i = 0; i < res.length; i++) {
                    const board = res[i] as UiJobBoard;
                    if (board.isSelected) selectionModelTmp.push(board.id);
                    let atts: Record<string, string> = {};
                    if (board.attributes && board.attributes.length > 0) {
                        for (let j = 0; j < board.attributes.length; j++) {
                            const att = board.attributes[j];
                            atts[att.type] = att.value;
                        }
                    }
                    boardEditData.push({ id: board.id, typeId: board.jobBoardID, name: board.name, attributes: atts });
                    idsMap[board.id] = i;
                }
                setAvailableJobBoards(res as UiJobBoard[]);
                setSelectionModel(selectionModelTmp);
                setBoardIdToIndexMap(idsMap);
                setJobBoardEditData(boardEditData);
            }
            setIsFetchingBoardData(false);
        };
        getAvailableJobBoards();
    }, [advertId, errorHandler]);

    useEffect(() => {
        const getEditData = async () => {
            setIsFetchingEditData(true);
            const res = await GetAdvertById(advertId);
            if (res) {
                setAdvertStatusId(res.statusID);
                let tmp: AdvertEdit = {...defaultAdvertEdit};

                tmp.title = res.title;
                tmp.shortDescription = res.shortDescription ?? '';
                tmp.bullet1 = res.bullet1;
                tmp.bullet2 = res.bullet2;
                tmp.bullet3 = res.bullet3;
                tmp.classificationId = res.classificationID;
                tmp.subClassificationId = res.subClassificationID;
                tmp.countryId = res.countryID;
                tmp.regionId = res.regionID;
                tmp.locationId = res.locationID;
                tmp.payCurrencyId = res.payCurrencyID;
                tmp.workTypeId = res.workTypeID;
                tmp.payTypeId = res.payType;
                tmp.payFrom = res.payFrom;
                tmp.payTo = res.payTo;
                tmp.payDisplay = res.payDisplay ?? '';
                tmp.consultantId = res.consultantID;
                if (res.statusID === 4 || res.statusID === 5) setIsAdvertPosted(true);
                if (res.screeningQuestionnaireTemplateID) {
                    const res2 = await GetScreeningQuestionnaireTemplate(res.screeningQuestionnaireTemplateID);
                    if (res2) setSelectedQuestionnaire(res2);
                }
                const m = moment(res.postingDate);
                if (m.isValid()) {
                    setPostingDate(m);
                    tmp.postingDate = m.format('YYYY-MM-DDTHH:mm');
                    
                    const mNow = moment();
                    if (res.statusID === 1 && m.isBefore(mNow)) {
                        setPostingDate(mNow);
                        tmp.postingDate = m.format('YYYY-MM-DDTHH:mm');
                    }
                }
                
                tmp.description = res.description ?? '';

                if (tmp.payCurrencyId) {
                    const currencyRes = await GetCurrencyById(tmp.payCurrencyId);
                    if (currencyRes) {
                        setCurrencyCode(currencyRes.code);
                        setCurrencyName(currencyRes.formattedDescription);
                    }
                }

                setClassificationName(res.classificationName);
                setSubClassificationName(res.subClassificationName);
                setCountryName(res.countryName);
                setRegionName(res.regionName);
                setLocationName(res.locationName);

                init(tmp);
            }
            setIsFetchingEditData(false);
            setIsSetupFinished(true);
        };
        advertId && getEditData();
    }, [advertId, init]);

    useEffect(() => {
        loadingHandler && loadingHandler(isFetchingBoardData || isFetchingEditData);
    }, [isFetchingBoardData, isFetchingEditData, loadingHandler]);

    const columns = useMemo<GridColDef[]>(() => {
        const imgRender = (params: GridRenderCellParams) => {
            return <img alt="img" style={{ maxHeight: '60px', maxWidth: '100px' }} src={`https://cdn.recruitwizard.com/images/jobboards/default/${params.value}.png`} />;
        }

        return [
            { field: 'jobBoardID', headerName: 'Job Board', width: 110, disableColumnMenu: true, sortable: false, renderCell: imgRender },
            { field: 'name', headerName: 'Name', width: 200 },
            { field: 'usage', headerName: 'Ads Used' },
            { field: 'warning', headerName: 'Ads Remaining', width: 150 },
        ];
    }, []);

    const selectedBoards = useMemo(() => {
        if (jobBoardEditData.length === 0 || selectionModel.length === 0) return [];
        let tmp: UiJobBoardEditData[] = [];
        for (let i = 0; i < selectionModel.length; i++) {
            const id = selectionModel[i];
            const index = boardIdToIndexMap[+id];
            const item = jobBoardEditData[index];
            if (item) tmp.push(item);
        }
        return tmp;
    }, [jobBoardEditData, boardIdToIndexMap, selectionModel]);

    const isActiveStepLast = useMemo(() => {
        return activeStep === 2 + selectedBoards.length;
    }, [activeStep, selectedBoards.length]);

    const isStep1Error = useMemo(() => {
        return !Boolean(state.title) || state.shortDescription.length < 10 || state.shortDescription.length > 150 
        || state.classificationId === 0 || state.subClassificationId === 0
        || state.countryId === 0 || state.regionId === 0 || state.locationId === 0
        || state.workTypeId === 0 || state.payCurrencyId === 0 || state.payFrom === 0 || state.payTo === 0;
    }, [state.classificationId, state.countryId, state.locationId, state.payCurrencyId, state.payFrom, state.payTo, state.regionId, state.shortDescription.length, state.subClassificationId, state.title, state.workTypeId]);

    useEffect(() => {
        if (errorMessage1Toggle) {}

        let message = '';
        if (errorHandler && showValidation1 && activeStep === 0) {
            let errorList: string[] = [];
            if (!Boolean(state.title)) errorList.push('Title cannot be empty');
            if (state.shortDescription.length < 10 || state.shortDescription.length > 150) errorList.push('Short Description must be at least 10 characters long and 150 characters at most');
            if (state.classificationId === 0) errorList.push('Classification must be set');
            if (state.subClassificationId === 0) errorList.push('Sub Classification must be set');
            if (state.countryId === 0) errorList.push('Country must be set');
            if (state.regionId === 0) errorList.push('Region must be set');
            if (state.locationId === 0) errorList.push('Location must be set');
            if (state.workTypeId === 0) errorList.push('Work Type must be set');
            if (state.payCurrencyId === 0) errorList.push('Pay Currency must be set');
            if (state.payFrom === 0) errorList.push('Pay From must be set');
            if (state.payTo === 0) errorList.push('Pay To must be set');
            const isPostingDateRecent = postingDate ? postingDate.isAfter(moment().subtract(1, 'hour')) : false;
            if (!isPostingDateRecent && !isAdvertPosted) errorList.push("Posting Date can't be more than 1 hour in the past");
            if (errorList.length > 0) {
                message = errorList.join('\n');
                errorHandler(message);
            }
        }
        
        errorHandler && errorHandler(message);
    }, [activeStep, errorHandler, errorMessage1Toggle, isAdvertPosted, postingDate, showValidation1, state.classificationId, state.countryId, state.locationId, state.payCurrencyId, state.payFrom, state.payTo, state.regionId, state.shortDescription.length, state.subClassificationId, state.title, state.workTypeId]);

    useEffect(() => {
        if (errorMessage2Toggle) {}

        let message = '';
        if (errorHandler && showValidation2 && activeStep === 1) {
            const l = state.description.length;
            if (l < 100 || l > 15000) {
                message = `Description must have between 100 and 15,000 characters. Currently: ${l}`;
            }
        }
        
        errorHandler && errorHandler(message);
    }, [activeStep, errorHandler, errorMessage2Toggle, showValidation2, state.description.length]);

    const prevStepHandler = useCallback(() => {
        setActiveStep(prev => prev - 1);
    }, []);

    const nextStepHandler = useCallback(() => {
        let canContinue = true;
        if (activeStep === 0) {
            setErrorMessage1Toggle(prev => !prev);
            setShowValidation1(true);
            const isPostingDateRecent = postingDate ? postingDate.isAfter(moment().subtract(1, 'hour')) : false;
            if (isStep1Error || (!isPostingDateRecent && !isAdvertPosted)) canContinue = false;
        }
        else if (activeStep === 1) {
            setErrorMessage2Toggle(prev => !prev);
            setShowValidation2(true);
            const l = state.description.length;
            if (l < 100 || l > 15000) {
                canContinue = false;
            }
        }
        if (canContinue) {
            errorHandler && errorHandler('');
            setActiveStep(prev => prev + 1);
        }
    }, [activeStep, errorHandler, isAdvertPosted, isStep1Error, postingDate, state.description.length]);

    const saveHandler = useCallback(async () => {
        const isPostingDateRecent = postingDate ? postingDate.isAfter(moment().subtract(1, 'hour')) : false;
        if (!isPostingDateRecent && !isAdvertPosted) {
            errorHandler && errorHandler("Posting Date can't be more than 1 hour in the past");
            return false;
        }
        
        const pDate = postingDate && postingDate.isValid() ? postingDate.utc().format('YYYY-MM-DDTHH:mm') : '0001-01-01T00:00:00';
        const questionnaireId = selectedQuestionnaire ? selectedQuestionnaire.id : 0;
        
        let jobBoardData: JobBoardMetadata[] = [];
        for (let i = 0; i < selectedBoards.length; i++) {
            const jobBoard = selectedBoards[i];
            let atts: AdvertJobBoardAttributeEdit[] = [];
            const attKeys = Object.keys(jobBoard.attributes);
            for (let j = 0; j < attKeys.length; j++) {
                const key = attKeys[j];
                atts.push({ name: key, value: jobBoard.attributes[key] });
            }

            jobBoardData.push({
                id: jobBoard.id,
                typeId: jobBoard.typeId,
                attributes: atts
            });
        }

        const tmp: AdvertEdit = { ...state, postingDate: pDate, screeningQuestionnaireTemplateId: questionnaireId, jobBoards: jobBoardData };

        loadingHandler && loadingHandler(true);
        const res = await UpdateAdvert(advertId, tmp, errorHandler);
        loadingHandler && loadingHandler(false);

        if (!Boolean(res)) return false;

        updateInitial();
        setIsAttributesChanged(false);
        setSavedChanges(true);
        return true;
    }, [postingDate, isAdvertPosted, selectedQuestionnaire, state, loadingHandler, advertId, errorHandler, updateInitial, selectedBoards]);

    const postHandler = useCallback(async () => {
        const isPostingDateRecent = postingDate ? postingDate.isAfter(moment().subtract(1, 'hour')) : false;
        if (!isPostingDateRecent && !isAdvertPosted) {
            errorHandler && errorHandler("Posting Date can't be more than 1 hour in the past");
            return false;
        }

        const validationKeys = Object.keys(validationMessageMap);
        if (validationKeys.length > 0) {
            for (let i = 0; i < validationKeys.length; i++) {
                const key = validationKeys[i];
                const msg = validationMessageMap[+key];
                if (msg) {
                    errorHandler && errorHandler(msg);
                    return false;
                }
            }
        }
        
        const pDate = postingDate && postingDate.isValid() ? postingDate.utc().format('YYYY-MM-DDTHH:mm') : '0001-01-01T00:00:00';
        const questionnaireId = selectedQuestionnaire ? selectedQuestionnaire.id : 0;
        
        let jobBoardData: JobBoardMetadata[] = [];
        for (let i = 0; i < selectedBoards.length; i++) {
            const jobBoard = selectedBoards[i];
            let atts: AdvertJobBoardAttributeEdit[] = [];
            const attKeys = Object.keys(jobBoard.attributes);
            for (let j = 0; j < attKeys.length; j++) {
                const key = attKeys[j];
                atts.push({ name: key, value: jobBoard.attributes[key] });
            }

            jobBoardData.push({
                id: jobBoard.id,
                typeId: jobBoard.typeId,
                attributes: atts
            });
        }

        const tmp: AdvertEdit = { ...state, postingDate: pDate, screeningQuestionnaireTemplateId: questionnaireId, jobBoards: jobBoardData };

        loadingHandler && loadingHandler(true);
        const res = await PostAdvert(advertId, tmp, errorHandler);
        loadingHandler && loadingHandler(false);

        if (!Boolean(res)) return false;

        updateInitial();
        setIsAttributesChanged(false);
        setSavedChanges(true);
        return true;
    }, [advertId, errorHandler, isAdvertPosted, loadingHandler, postingDate, selectedBoards, selectedQuestionnaire, state, updateInitial, validationMessageMap]);

    useEffect(() => {
        const action = (
            <>
                {advertStatusId === 1 && <Button variant="contained" onClick={saveHandler} disabled={!hasChanges && !isAttributesChanged} color="secondary" sx={{ mr: '5px' }}>Save Draft</Button> }
                <Button variant="contained" disabled={activeStep === 0} onClick={prevStepHandler} sx={{ mr: '5px' }} startIcon={<KeyboardArrowLeftIcon />}>Prev</Button>
                {!isActiveStepLast && <Button variant="contained" onClick={nextStepHandler} endIcon={<KeyboardArrowRightIcon />}>Next</Button> }
                {isActiveStepLast && <Button variant="contained" onClick={postHandler} color="success">Post Ad</Button> }
            </>
        );

        const advertLink = <Link to={`/adverts/${advertId}`} style={{ color: 'inherit', textDecoration: 'underline' }}>Adverts</Link>;
        const title = <> {advertLink} &nbsp; {' > Edit'} </>;
        const sb = <TitleAndActionSummaryBar title={title} browserTabTitle="Edit > Adverts" action={action} />
        setSummaryBar && setSummaryBar(sb);
    }, [activeStep, advertId, advertStatusId, hasChanges, isActiveStepLast, isAttributesChanged, nextStepHandler, postHandler, prevStepHandler, saveHandler, setSummaryBar]);

    const onStringFieldChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        change(name as keyof AdvertEdit, value);
    }, [change]);

    const onNumberFieldChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        change(name as keyof AdvertEdit, +value);
    }, [change]);

    const onClassificationChange = useCallback((c: Classification | null) => {
        change('classificationId', c ? c.id : 0);
        setClassificationName(c ? c.name : '');
    }, [change]);

    const onSubClassificationChange = useCallback((c: Classification | null) => {
        change('subClassificationId', c ? c.id : 0)
        setSubClassificationName(c ? c.name : '');
    }, [change]);

    const onCountryChange = useCallback((l: AdvertLocation | null) => {
        change('countryId', l ? l.id : 0);
        setCountryName(l ? l.name : '');
    }, [change]);

    const onRegionChange = useCallback((l: AdvertLocation | null) => {
        change('regionId', l ? l.id : 0);
        setRegionName(l ? l.name : '');
    }, [change]);

    const onLocationChange = useCallback((l: AdvertLocation | null) => {
        change('locationId', l ? l.id : 0);
        setLocationName(l ? l.name : '');
    }, [change]);

    const onCurrencyChange = useCallback((id: number | null, code?: string | null, name?: string | null) => {
        change('payCurrencyId', id ?? 0);
        setCurrencyCode(code ?? '');
        setCurrencyName(name ?? '');
    }, [change]);

    const onPayTypeChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        change('payFrom', 0);
        change('payTo', 0);
        change(name as keyof AdvertEdit, +value);
    }, [change]);

    const onPayFromChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        change('payTo', 0);
        change(name as keyof AdvertEdit, +value);
    }, [change]);

    const onPostingDateChange = useCallback((m: moment.Moment | null) => {
        setPostingDate(m);
        const d = m && m.isValid() ? m.format('YYYY-MM-DDTHH:mm') : '0001-01-01T00:00:00';
        change('postingDate', d);
    }, [change]);

    const onDescriptionChange = useCallback((content: string) => {
        if (!isSetupFinished) return;
        change('description', content);
    }, [change, isSetupFinished])

    const { unsavedChangesDialog, hasBlockedRoute } = useUnsavedChangesDialog(hasChanges || isAttributesChanged, saveHandler);

    const shouldRedirect = useMemo(() => {
        return !hasBlockedRoute && savedChanges && Boolean(advertId);
    }, [advertId, hasBlockedRoute, savedChanges]);

    const valuesFrom = useMemo(() => {
        const desc = payValuesMap[state.payTypeId];
        if (desc) {
            const { start, iterations, increment } = desc.from;
            return FromValuesGenerator(start, iterations, increment);
        }
        return [];
    }, [state.payTypeId]);

    const valuesTo = useMemo(() => {
        const desc = payValuesMap[state.payTypeId];
        if (desc && state.payFrom > 0) {
            const { iterations, increment } = desc.to;
            return ToValuesGenerator(state.payFrom, iterations, increment);
        }
        return [];
    }, [state.payFrom, state.payTypeId]);

    const jobBoardAttributeChangeHandler = useCallback((jobBoardId: number, key: string, value: string) => {
        setIsAttributesChanged(true);
        setJobBoardEditData(prev => {
            let tmp = [...prev];
            const i = boardIdToIndexMap[jobBoardId];
            if (tmp[i]) {
                let tmp2 = {...tmp[i].attributes};
                tmp2[key] = value;
                tmp[i].attributes = tmp2;
            }
            return tmp;
        });
    }, [boardIdToIndexMap]);

    const jobBoardValidationMessageChangeHandler = useCallback((jobBoardId: number, message: string) => {
        setValidationMessageMap(prev => {
            let tmp = {...prev};
            tmp[jobBoardId] = message;
            return tmp;
        });
    }, []);

    const currentBoardIndex = useMemo(() => {
        if (selectedBoards.length > 0 && activeStep > 2) {
            const tmp = selectedBoards[activeStep - 3];
            return boardIdToIndexMap[tmp.id];
        }
        return -1;
    }, [activeStep, boardIdToIndexMap, selectedBoards]);

    const recordAttributes = useMemo<Record<string, string>>(() => {
        return {
            'classification': classificationName,
            'subClassification': subClassificationName,
            'country': countryName,
            'region': regionName,
            'location': locationName,
            'workType': getWorkTypeName(state.workTypeId),
            'salaryType': getSalaryTypeName(state.payTypeId),
            'salaryFrom': formatNumber(state.payFrom),
            'salaryTo': formatNumber(state.payTo),
            'displaySalary': state.payDisplay,
            'currencyCode': currencyCode,
            'currencyName': currencyName,
            'jobTitle': state.title,
            'bullet1': state.bullet1,
            'bullet2': state.bullet2,
            'bullet3': state.bullet3,
            'shortDescription': state.shortDescription,
            'description': state.description,
        };
    }, [classificationName, countryName, currencyCode, currencyName, locationName, regionName, state.bullet1, state.bullet2, state.bullet3, state.description, state.payDisplay, state.payFrom, state.payTo, state.payTypeId, state.shortDescription, state.title, state.workTypeId, subClassificationName]);

    return (
        <>
            {unsavedChangesDialog}
            { shouldRedirect && <Navigate to={`/adverts/${advertId}`} /> }
            <Stepper nonLinear activeStep={activeStep}>
                <Step><StepLabel error={showValidation1 && isStep1Error}>Ad Details</StepLabel></Step>
                <Step><StepLabel>Description</StepLabel></Step>
                <Step><StepLabel>Board Selection</StepLabel></Step>
                {selectedBoards.map(b => <Step key={b.id}><StepLabel error={Boolean(validationMessageMap[b.id])}>{b.name}</StepLabel></Step>)}
            </Stepper>
            <Container maxWidth="md" sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                <Box mt="20px" display={activeStep === 0 ? undefined : 'none'} flexGrow={1}>
                <div style={{ display: 'flex', flex: '1', flexDirection: 'column', position: 'relative', overflow: 'hidden', height: '100%' }}>
                        <div style={{position: 'absolute', top: 0, left: 0, height: '100%', width: '100%', overflow: 'auto' }}>
                            <Stack spacing={2} pt={2}>
                                <RWTextFieldComponent 
                                    label="Title"
                                    name="title"
                                    value={state.title}
                                    onChange={onStringFieldChange}
                                    isError={showValidation1 && !Boolean(state.title)} />
                                <RWTextFieldComponent 
                                    label="Short Description"
                                    name="shortDescription"
                                    multiline={true}
                                    value={state.shortDescription}
                                    onChange={onStringFieldChange}
                                    isError={showValidation1 && (state.shortDescription.length > 150 || state.shortDescription.length < 10)}
                                    rows={4} 
                                    helperText={`${150 - state.shortDescription.length} chrs left`}/>
                                <RWTextFieldComponent 
                                    label="Bullet 1"
                                    name="bullet1"
                                    value={state.bullet1}
                                    onChange={onStringFieldChange}
                                />
                                <RWTextFieldComponent 
                                    label="Bullet 2"
                                    name="bullet2"
                                    value={state.bullet2}
                                    onChange={onStringFieldChange}
                                />
                                <RWTextFieldComponent 
                                    label="Bullet 3"
                                    name="bullet3"
                                    value={state.bullet3}
                                    onChange={onStringFieldChange}
                                />
                                <AdvertClassificationsPicker
                                    parentId={state.classificationId}
                                    childId={state.subClassificationId}
                                    onParentChange={onClassificationChange}
                                    onChildChange={onSubClassificationChange}
                                    error1={showValidation1 && state.classificationId === 0}
                                    error2={showValidation1 && state.subClassificationId === 0}
                                    sx={{ flex: '1 1 0' }}
                                />
                                <AdvertLocationsPicker
                                    countryId={state.countryId}
                                    regionId={state.regionId}
                                    locationId={state.locationId}
                                    onCountryChange={onCountryChange}
                                    onRegionChange={onRegionChange}
                                    onLocationChange={onLocationChange}
                                    error1={showValidation1 && state.countryId === 0}
                                    error2={showValidation1 && state.regionId === 0}
                                    error3={showValidation1 && state.locationId === 0}
                                    sx={{ flex: '1 1 0' }}
                                />
                                <Box display="flex" gap="5px">
                                    <TextField select label="Work Type" name="workTypeId" value={state.workTypeId.toString()} onChange={onNumberFieldChange} sx={{ flex: '1 1 0' }} error={showValidation1 && state.workTypeId === 0}>
                                        <MenuItem value="0">None</MenuItem>
                                        <MenuItem value="1">Casual</MenuItem>
                                        <MenuItem value="2">Full Time</MenuItem>
                                        <MenuItem value="3">Part Time</MenuItem>
                                    </TextField>
                                    <CurrencyPicker
                                        currencyId={state.payCurrencyId}
                                        onSelectCallback={onCurrencyChange}
                                        label="Pay Currency"
                                        sx={{ flex: '1 1 0' }}
                                        error={showValidation1 && state.payCurrencyId === 0}
                                    />
                                </Box>
                                <Box display="flex" gap="5px">
                                    <TextField select label="Pay Type" name="payTypeId" value={state.payTypeId.toString()} onChange={onPayTypeChange} sx={{ flex: '1 1 0' }} error={showValidation1 && state.payTypeId === 0} >
                                        <MenuItem value="0">Select</MenuItem>
                                        <MenuItem value="3">Annual Salary</MenuItem>
                                        <MenuItem value="2">Daily</MenuItem>
                                        <MenuItem value="1">Hourly</MenuItem>
                                    </TextField>
                                    <TextField select label="Pay From" name="payFrom" value={state.payFrom} onChange={onPayFromChange} disabled={state.payTypeId === 0} sx={{ flex: '1 1 0' }} error={showValidation1 && state.payFrom === 0} >
                                        <MenuItem value="0"></MenuItem>
                                        {valuesFrom.map(v => <MenuItem key={v.value} value={v.value.toString()}>{v.label}</MenuItem>)}
                                    </TextField>
                                    <TextField select label="Pay To" name="payTo" value={state.payTo} onChange={onNumberFieldChange} disabled={state.payTypeId === 0 || state.payFrom === 0} sx={{ flex: '1 1 0' }} error={showValidation1 && state.payTo === 0} >
                                        <MenuItem value="0"></MenuItem>
                                        {valuesTo.map(v => <MenuItem key={v.value} value={v.value.toString()}>{v.label}</MenuItem>)}
                                    </TextField>
                                </Box>
                                <RWTextFieldComponent 
                                    label="Pay Display"
                                    name="payDisplay"
                                    value={state.payDisplay}
                                    onChange={onStringFieldChange} />
                                <AdvertScreeningQuestionnairePicker
                                    onSelectCallback={setSelectedQuestionnaire}
                                    templateId={selectedQuestionnaire ? selectedQuestionnaire.id : 0 }
                                    disabled={isAdvertPosted}
                                />
                                <DateTimePicker label="Posting Date" value={postingDate} disabled={isAdvertPosted} onChange={onPostingDateChange} slotProps={{ actionBar: { actions: ["clear", "today", "cancel", "accept"] } }} />
                                <UserPicker label="Consultant" userId={state.consultantId} disabled={isAdvertPosted} onSelect={u => change('consultantId', u ? u.id : 0)} />
                            </Stack>
                        </div>
                    </div>
                </Box>
                <Box mt="20px" display={activeStep === 1 ? undefined : 'none'} flexGrow={1}>
                    <Editor
                        tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce-5.10.2/tinymce.min.js'}
                        value={state.description}
                        onEditorChange={onDescriptionChange}
                        init={{
                            height: '100%',
                            skin: isDarkTheme ? 'oxide-dark' : undefined,
                            content_css: isDarkTheme ? 'dark' : undefined,
                            branding: false,
                            menubar: false,
                            statusbar: false,
                            contextmenu: false,
                            resize: false,
                            plugins: 'powerpaste code link emoticons table print preview visualchars lists',
                            browser_spellcheck: true,
                            verify_html: false,
                            toolbar1: 'undo redo styleselect bold italic alignleft aligncenter alignright alignjustify bullist numlist outdent indent link code',
                            readonly: true,
                        }}
                    />
                </Box>
                <Box mt="20px" display={activeStep === 2 ? undefined : 'none'} flexGrow={1}>
                    <div style={{ display: 'flex', flex: '1', flexDirection: 'column', position: 'relative', overflow: 'hidden', height: '100%' }}>
                        <div style={{position: 'absolute', top: 0, left: 0, height: '100%', width: '100%', overflow: 'auto' }}>
                            <DataGridPremium
                                density="comfortable"
                                apiRef={apiRef}
                                checkboxSelection
                                disableRowSelectionOnClick
                                rowSelectionModel={selectionModel}
                                onRowSelectionModelChange={ setSelectionModel }
                                rows={availableJobBoards}
                                columns={columns}
                                isRowSelectable={p => !p.row.isDisabled}
                            />
                        </div>
                    </div>
                </Box>
                <Box mt="20px" display={activeStep > 2 ? undefined : 'none'} flexGrow={1}>
                    { currentBoardIndex >= 0 && Boolean(jobBoardEditData[currentBoardIndex]) && 
                        <JobBoardLoaderComponent
                            type="edit"
                            advertId={advertId}
                            jobBoardId={jobBoardEditData[currentBoardIndex].id}
                            jobBoardTypeId={jobBoardEditData[currentBoardIndex].typeId}
                            attributes={jobBoardEditData[currentBoardIndex].attributes}
                            advertRecordAttributes={recordAttributes}
                            attributeChangeHandler={jobBoardAttributeChangeHandler}
                            validationMessageHandler={jobBoardValidationMessageChangeHandler}
                            loadingHandler={loadingHandler}
                            errorHandler={errorHandler}
                        />
                    }
                </Box>
            </Container>
        </>
    );
}