import { GridColDef, GridColumnVisibilityModel, GridRenderCellParams, GridRowSelectionModel, GridValueGetter, useGridApiRef } from "@mui/x-data-grid-premium";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import GridWithStateWrapper from "../GridWidthStateWrapper";
import PreviewLoaderComponent from "../Previews/PreviewLoader";
import moment from "moment";
import { companyHasSinglePermission, userHasSinglePermission } from "util/PermissionsUtils";
import { Permission } from "common/models/Permissions";
import ConfirmationDialog from "../Dialogs/Generic/ConfirmationDialog";
import { PreviewEntityType } from "common/models/Previews/Previews";
import { MenuOptionDefinition } from "common/models/MenuDefinition";
import ActionMenu from "../Menus/ActionMenu";
import { ChangeRequest } from "common/models/ChangeRequest";
import { GetMyPending, GetAllPending, ApproveChangeRequest, SearchChangeRequests } from "services/ChangeRequestsService";
import { GetPlacementChangeRequests } from "services/PlacementsService";
import InformationDialog from "../Dialogs/Generic/InformationDialog";
import Box from "@mui/material/Box";
import { ChangeRequestSearch } from "common/models/Search/ChangeRequestSearch";

interface Props {
    source: 'placement-record' | 'all-pending' | 'my-pending' | 'search',
    placementId?: number,
    gridName: string,
    searchData?: ChangeRequestSearch,
    setRowCountHandler?: (count: number) => void,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
    successHandler?: (message: string) => void
}

const linkStyle: React.CSSProperties = { color: 'inherit', textDecoration: 'underline' };
// const entityTypeId = 17;

export default function ChangeRequestsGridComponent({ source, placementId, setRowCountHandler, loadingHandler, errorHandler, successHandler, gridName, searchData }: Props) {
    const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
    const [rows, setRows] = useState<ChangeRequest[]>([]);
    const [selectedValidated, setSelectedValidated] = useState<ChangeRequest[]>([]);
    const [selectedNotValidated, setSelectedNotValidated] = useState<ChangeRequest[]>([]);
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    const [previewType, setPreviewType] = useState<PreviewEntityType | ''>('');
    const [previewRecordId, setPreviewRecordId] = useState(0);
    const [previewRecordName, setPreviewRecordName] = useState('');
    const [isApproveDialogOpen, setIsApproveDialogOpen] = useState(false);
    const apiRef = useGridApiRef();

    const userCanApprovePlacements = useMemo(() => userHasSinglePermission(Permission.FinancialController), []);

    const getData = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        let res: ChangeRequest[] | null = [];
        if (source === 'placement-record' && placementId) res = await GetPlacementChangeRequests(placementId, errorHandler);
        else if (source === 'my-pending') res = await GetMyPending(errorHandler);
        else if (source === 'all-pending') res = await GetAllPending(errorHandler);
        else if (source === 'search' && searchData) res = await SearchChangeRequests(searchData, errorHandler);
        if (res) {
            setRowCountHandler && setRowCountHandler(res.length)
            setRows(res);
        }
        loadingHandler && loadingHandler(false);
    }, [loadingHandler, source, placementId, errorHandler, searchData, setRowCountHandler]);

    useEffect(() => {
        getData();
    }, [getData]);

    const columns = useMemo<GridColDef[]>(() => {
        const dateValueGetter: GridValueGetter<ChangeRequest, any, undefined, string> = (value) => {
            if (value) {
                const m = moment(value);
                if (m.isValid() && m.get('year') > 1) {
                    return m.toDate();
                }
            }
        };

        const dateRenderer = (params: GridRenderCellParams) => {
            if (params.value) {
                return moment(params.value).format('DD MMM YYYY');
            }
            if (params.field === 'endDate') return 'Never';
        };

        const linkToChangeRequestRenderer = (params: GridRenderCellParams) => {
            return (
                <Link
                    to={`/placements/change-requests/${params.id}`}
                    style={linkStyle}
                    onMouseEnter={() => handlePreviewHover('placement', params.row.id)}
                    onMouseLeave={handlePreviewClose}
                >{params.value}</Link>
            );
        }

        const linkToPlacementRenderer = (params: GridRenderCellParams) => {
            return (
                <Link
                    to={`/placements/${params.row.placementID}`}
                    style={ linkStyle }
                    onMouseEnter={ () => handlePreviewHover('placement', params.row.placementID) }
                    onMouseLeave={ handlePreviewClose }
                >{params.value}</Link>
            );
        }

        const linkToClientRenderer = (params: GridRenderCellParams) => {
            const clientId = params.row.clientID;
            if (clientId) {
                return (
                    <Link
                        to={`/clients/${clientId}`}
                        style={ linkStyle }
                        onMouseEnter={ () => handlePreviewHover('client', clientId) }
                        onMouseLeave={ handlePreviewClose }
                    >{params.value}</Link>
                );
            }
            return params.value;
        }

        const linkToCandidateRenderer = (params: GridRenderCellParams) => {
            const candidateId = params.row.candidateID;
            if (candidateId) {
                return (
                    <Link
                        to={`/candidates/${candidateId}`}
                        style={ linkStyle }
                        onMouseEnter={ () => handlePreviewHover('candidate', candidateId) }
                        onMouseLeave={ handlePreviewClose }
                    >{params.value}</Link>
                );
            }
            return params.value;
        };

        const handlePreviewHover = (type: PreviewEntityType | '', id: number, isTags: boolean = false, recordName: string = '', noDelay: boolean = false) => {
            setPreviewType(type);
            setPreviewRecordId(id);
            setPreviewRecordName(recordName);
            setIsPreviewOpen(true);
        };

        const handlePreviewClose = () => {
            setIsPreviewOpen(false);
        };

        return [
            { headerName: 'ID', field: 'id', width: 75, headerAlign: 'center', align: 'center', renderCell: linkToChangeRequestRenderer },
            { headerName: 'Type', field: 'changeTypeName', headerAlign: 'center', align: 'center', width: 150 },
            { headerName: 'Effective Date', field: 'effectiveDate', headerAlign: 'center', align: 'center', width: 110, type: 'date', valueGetter: dateValueGetter, renderCell: dateRenderer },
            { headerName: 'Job Title', field: 'jobTitle', width: 200, renderCell: linkToPlacementRenderer },
            { headerName: 'Client', field: 'clientName', width: 200, renderCell: linkToClientRenderer },
            { headerName: 'Candidate', field: 'candidateName', width: 150, renderCell: linkToCandidateRenderer },
            { headerName: 'Division', field: 'divisionName', width: 200 },
            { headerName: 'Requested By', field: 'createdByUserName', width: 200 },
            { headerName: 'Requested Date', field: 'createdDate', headerAlign: 'center', align: 'center', width: 150, type: 'date', valueGetter: dateValueGetter, renderCell: dateRenderer },
            { headerName: 'Decision', field: 'decisionName', width: 200 },
            { headerName: 'Decision By', field: 'decisionByUserName', width: 200 },
        ];
    }, []);

    const closeApproveDialogHandler = useCallback(() => {
        setIsApproveDialogOpen(false);
        setSelectedNotValidated([]);
        setSelectedValidated([]);
        setSelectionModel([]);
    }, []);

    const bulkApproveCallback = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        let okIds: number[] = [];
        let errorIds: number[] = [];
        for (let i = 0; i < selectedValidated.length; i++) {
            const v = selectedValidated[i];
            const res = await ApproveChangeRequest(v.id, errorHandler);
            if (res) okIds.push(v.id);
            else errorIds.push(v.id);
        }

        if (okIds.length === selectedValidated.length) {
            successHandler && successHandler('Change Requests Approved');
        }
        else if (errorIds.length > 0) {
            errorHandler && errorHandler(`The following record(s) (${errorIds.length}) returned error when approving: ${errorIds.join(', ')}`);
        }

        closeApproveDialogHandler();
        
        loadingHandler && loadingHandler(false);
    }, [selectedValidated, loadingHandler, errorHandler, successHandler, closeApproveDialogHandler]);

    const prepareApproveDialog = useCallback(() => {
        if (selectionModel.length > 0) {
            let valids: ChangeRequest[] = [];
            let notValids: ChangeRequest[] = [];
            for (let i = 0; i < selectionModel.length; i++) {
                const id = selectionModel[i] as number;
                const r = rows.find(cr => cr.id === id);
                if (r && r.decisionID === 0) valids.push(r);
                else if (r && r.decisionID !== 0) notValids.push(r);
            }
            setSelectedValidated(valids);
            setSelectedNotValidated(notValids);
            setIsApproveDialogOpen(true);
        }
    }, [rows, selectionModel]);

    const exportToExcel = useCallback(() => {
        const api = apiRef.current;
        if (api) api.exportDataAsExcel();
    }, [apiRef]);

    const canExport = useMemo(() => companyHasSinglePermission(Permission.PlacementsExport) && userHasSinglePermission(Permission.PlacementsExport), []);

    const actionMenuDefinitions = useMemo<MenuOptionDefinition[]>(() => {
        const noRecordSelected = selectionModel.length === 0;

        return [
            { label: 'Approve', type: 'action', disabled: noRecordSelected, allow: () => userCanApprovePlacements && source !== 'search', action: prepareApproveDialog },
            { label: 'Export', type: 'action', action: exportToExcel, allow: () => canExport },
        ];
    }, [selectionModel.length, prepareApproveDialog, exportToExcel, userCanApprovePlacements, source, canExport]);

    const gridActions = useMemo(() => {
        if (userCanApprovePlacements || canExport) {
            return <ActionMenu color="secondary" label="List Actions" definition={actionMenuDefinitions} />;
        }
        return undefined;
    }, [userCanApprovePlacements, canExport, actionMenuDefinitions]);

    const defaultHiddenCols = useMemo<GridColumnVisibilityModel>(() => {
        let model: GridColumnVisibilityModel = { 'divisionName': false };
        if (source === "my-pending" || source === "all-pending") model['decisionByUserName'] = false;
        return model;
    }, [source]);

    return (
        <>
            <PreviewLoaderComponent
                open={isPreviewOpen}
                entityType={previewType}
                recordId={previewRecordId}
                titleOverride={previewRecordName}
            />
            <ConfirmationDialog
                open={isApproveDialogOpen && selectedValidated.length > 0 && selectedNotValidated.length === 0}
                message={`Are you sure you want to approve the selected ${selectionModel.length} Change Requests?`}
                title="Bulk Change Requests Approval"
                onClose={ closeApproveDialogHandler }
                onContinue={ bulkApproveCallback }
                cancelActionText="No"
                confirmActionText="Yes"
            />
            <ConfirmationDialog
                open={isApproveDialogOpen && selectedValidated.length > 0 && selectedNotValidated.length > 0}
                message={`${selectedNotValidated.length} of the selected records haven't been validated. Do you want to proceed with the ${selectedValidated.length} valid records?`}
                title="Bulk Placement Approval"
                onClose={ closeApproveDialogHandler }
                onContinue={ bulkApproveCallback }
                cancelActionText="No"
                confirmActionText="Yes"
            />
            <InformationDialog
                open={isApproveDialogOpen && selectedValidated.length === 0}
                message={`None of the ${selectionModel.length} selected records were validated for approval`}
                title="Bulk Placement Approval"
                onClose={ closeApproveDialogHandler }
            />
            <>
                {Boolean(gridActions) &&
                    <Box pb="10px" ml="auto">
                        {gridActions}
                    </Box>
                }
                <GridWithStateWrapper
                    gridName={gridName}
                    defaultViewModel={defaultHiddenCols}
                    rows={rows}
                    columns={columns}
                    apiRef={apiRef}
                    density="compact"
                    checkboxSelection
                    disableRowSelectionOnClick
                    rowSelectionModel={selectionModel}
                    onRowSelectionModelChange={ sm => setSelectionModel(sm) }
                    pagination
                    pageSizeOptions={[100,250,500,1000]}
                />
            </>
        </>
    );
}
