import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import ReplayIcon from '@mui/icons-material/Replay';
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useTheme } from "@mui/material/styles";
import { PanelModel } from "@syncfusion/ej2-layouts/src/dashboard-layout/dashboard-layout-model";
import { DashboardLayoutComponent } from "@syncfusion/ej2-react-layouts/src/dashboard-layout/dashboardlayout.component";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { CustomField } from "common/models/Configuration/CustomFields";
import { OpportunityRecordDashboardElementDefinition, OpportunityRecordDashboardElementType } from "common/models/Dashboard/EditLayout";
import { CustomFieldSettingsMap, CustomFieldType } from "common/models/ScreenLayouts/CustomFields";
import useUnsavedChangesDialog from "hooks/UseUnsavedChangesDialog";
import { GetCustomerSettingBySettingName, UpdateCustomerSettingBySettingName } from "services/ConfigurationService";
import { GetCustomFieldsByEntity_OnlyActive } from "services/CustomFieldsService";
import { PlaceholderOpportunity } from "util/Definitions/Opportunities";
import { UdfOpportunityFieldMapObj } from "util/Definitions/ScreenLayouts/CustomFields";
import { DefaultOpportunityRecordDashboardElements_BackFill, DefaultOpportunityRecordDashboardElements_Bid, DefaultOpportunityRecordDashboardElements_Float, DefaultOpportunityRecordDashboardElements_General, DefaultOpportunityRecordDashboardElements_Lead, DefaultOpportunityRecordDashboardElements_Tender, GetElementTypeById, GetMinSizeByElementType, GetPanelDefinitionsFromPanelModels, OpportunityScreenLayoutSettings } from "util/Definitions/ScreenLayouts/Opportunity";
import PanelWrapper from "components/Dashboards/PanelWrapper";
import ConfirmationDialog from "components/Dialogs/Generic/ConfirmationDialog";
import MultiLineTextElement from "../MultiLineTextElement";
import SingleFieldElement from "../SingleFieldElement";
import AddOpportunityDashboardElementDialog from "components/Dialogs/Dashboard/AddOpportunityDashboardElementDialog";

interface Props {
    layoutType: 'Record' | 'Edit',
    opportunityType: 'BackFill' | 'Bid' | 'Float' | 'General' | 'Lead' | 'Tender',
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
}

const opportunity = PlaceholderOpportunity;

const LayoutElementsToString = (elements: PanelModel[]) => {
    if (elements.length === 0) return '';
    let s = elements.map(e => ElementString(e));
    s.sort()
    return s.join('|');
};

const ElementString = (element: PanelModel) => {
    const type = GetElementTypeById(element.id ?? '');
    const val = `${type}|${element.row}|${element.col}|${element.sizeX}|${element.sizeY}`;
    return val;
};

const { unitWidth, unitHeight, gapX, gapY, columns, mediaQueryMaxWidth } = OpportunityScreenLayoutSettings;
const cellSpacing = [gapX, gapY];

export default function ScreenLayoutAdmin({ layoutType, opportunityType, loadingHandler, errorHandler }: Props) {
    const [layoutSwapControl, setLayoutSwapControl] = useState(true);
    const [screenResizedControl, setScreenResizedControl] = useState(false);
    const [showAddElementDialog, setShowAddElementDialog] = useState(false);
    const [fetchingSavedState, setFetchingSavedState] = useState(false);
    const [fetchingActiveFields, setFetchingActiveFields] = useState(false);
    const [fetchedSavedState, setFetchedSavedState] = useState(false);
    const [activeFields, setActiveFields] = useState<CustomField[]>([]);
    const [initialState, setInitialState] = useState<PanelModel[] | null>(null);
    const [hasChanges, setHasChanges] = useState(false);
    const [saveAsBothEditAndView, setSaveAsBothEditAndView] = useState(false);
    const [showConfirmSaveBothDialog, setShowConfirmSaveBothDialog] = useState(false);
    const [showResetToDefaultConfirmDialog, setShowResetToDefaultConfirmDialog] = useState(false);
    const [lastSavedState, setLastSavedState] = useState<PanelModel[] | null>(null);
    const layoutRef = useRef<DashboardLayoutComponent | null>(null);
    const theme = useTheme();

    const customerSettingName = useMemo(() => {
        if (layoutType === 'Edit') return `OpportunityEditRecordDashboardLayoutElements_${opportunityType}`;
        return `OpportunityRecordDashboardLayoutElements_${opportunityType}`;
    }, [layoutType, opportunityType]);

    const defaultElements = useMemo(() => {
        switch (opportunityType) {
            case 'BackFill': return DefaultOpportunityRecordDashboardElements_BackFill;
            case 'Bid': return DefaultOpportunityRecordDashboardElements_Bid;
            case 'Float': return DefaultOpportunityRecordDashboardElements_Float;
            case 'General': return DefaultOpportunityRecordDashboardElements_General;
            case 'Lead': return DefaultOpportunityRecordDashboardElements_Lead;
            case 'Tender': return DefaultOpportunityRecordDashboardElements_Tender;
        }
    }, [opportunityType]);

    useEffect(() => {
        const windowResizeHandler = () => setScreenResizedControl(prev => !prev);
        window.addEventListener('resize', windowResizeHandler);
        return () => window.removeEventListener('resize', windowResizeHandler);
    }, []);

    useEffect(() => {
        const getActiveFields = async () => {
            setFetchingActiveFields(true);
            const res = await GetCustomFieldsByEntity_OnlyActive(8);
            if (res) setActiveFields(res);
            setFetchingActiveFields(false);
        };
        getActiveFields();
    }, []);

    const customFieldsSettingsMap = useMemo<CustomFieldSettingsMap>(() => {
        let obj: CustomFieldSettingsMap = {};
        activeFields.forEach(u => {
            const customFieldNumber = u.name.substring(13);
            const key = 'CustomField' + customFieldNumber as CustomFieldType;
            obj[key] = {
                title: u.agencyName,
                isMultiLine: u.multiLine && !u.usePredefinedValues,
                isEditable: u.editable
            }
        });
        return obj;
    }, [activeFields]);

    useEffect(() => {
        const getSavedState = async () => {
            setFetchingSavedState(true);
            const elementsJson = await GetCustomerSettingBySettingName(customerSettingName);
            if (elementsJson) {
                const panels = JSON.parse(elementsJson) as PanelModel[];
                setLastSavedState(panels);
                setInitialState(panels);
            }
            setFetchingSavedState(false);
            setFetchedSavedState(true);
        };
        getSavedState();
    }, [customerSettingName]);

    useEffect(() => {
        loadingHandler && loadingHandler(fetchingSavedState || fetchingActiveFields);
    }, [loadingHandler, fetchingSavedState, fetchingActiveFields]);

    const initialStateString = useMemo(() => {
        if (initialState && initialState.length ) return LayoutElementsToString(initialState);
        return '';
    }, [initialState]);

    const layoutChangeHandler = useCallback(() => {
        const api = layoutRef.current;
        if (api) {
            const currentData = api.serialize();
            const currentStringData = LayoutElementsToString(currentData);
            setHasChanges(currentStringData !== initialStateString);
        }
    }, [initialStateString]);

    useEffect(() => {
        if (layoutSwapControl) {}
        layoutChangeHandler();
    }, [layoutSwapControl, layoutChangeHandler]);

    const elements = useMemo<OpportunityRecordDashboardElementDefinition[]>(() => {
        if (!fetchedSavedState) return [];
        if (lastSavedState !== null) {
            const elements = GetPanelDefinitionsFromPanelModels(lastSavedState);
            return elements ;
        }
        return defaultElements;
    }, [lastSavedState, fetchedSavedState, defaultElements]);

    const removeElementHandler = useCallback((id: string) => {
        const api = layoutRef.current;
        if (api) {
            api.removePanel(id);
            layoutChangeHandler();
        }
    }, [layoutChangeHandler]);

    const addElementHandler = useCallback((newElementType: OpportunityRecordDashboardElementType) => {
        const api = layoutRef.current;
        if (api) {
            const m = moment().format('YYYYMMDDhhmmssSS');
            const id = `${newElementType}_${m}`;
            const canGrowY = newElementType === 'OpportunitySummary' || newElementType.startsWith('CustomField');
            const [minX, minY] = GetMinSizeByElementType(newElementType);
            const maxSizeY = canGrowY ? undefined : minY;
            api.addPanel({ id: id, col: 0, row: 0, sizeX: 1, sizeY: 1 });
            api.updatePanel({ id: id, col: 0, row: 0, sizeX: minX, sizeY: minY, minSizeX: minX, minSizeY: minY, maxSizeX: 1, maxSizeY: maxSizeY, enabled: false });
            const newDataObj = api.serialize();
            setLastSavedState(newDataObj);
            setLayoutSwapControl(prev => !prev);
            setShowAddElementDialog(false);
            layoutChangeHandler();
        }
    }, [layoutChangeHandler]);

    const renderElement = useCallback((id: string, type: OpportunityRecordDashboardElementType) => {
        if (opportunity) {
            switch (type) {
                case 'Divider': return <Box pt="20px"><Divider component="div" /></Box>;
                case 'Spacer': return <></>;
                case 'OpportunityId': return <SingleFieldElement disableTextSelection useEllipsisForLongValues fieldTitle="ID" fieldValue={opportunity.id.toString()} />;
                case 'OpportunityName': return <SingleFieldElement disableTextSelection useEllipsisForLongValues fieldTitle="Name" fieldValue={opportunity.name} />;
                case 'OpportunityType': return <SingleFieldElement disableTextSelection useEllipsisForLongValues fieldTitle="Type" fieldValue={opportunity.typeName} />;
                case 'OpportunityStatus': return <SingleFieldElement disableTextSelection useEllipsisForLongValues fieldTitle="Status" fieldValue={opportunity.statusName} />;
                case 'OpportunityStage': return <SingleFieldElement disableTextSelection useEllipsisForLongValues fieldTitle="Stage" fieldValue={opportunity.stageName} />;
                case 'OpportunityValue': return <SingleFieldElement disableTextSelection useEllipsisForLongValues fieldTitle="Value" fieldValue={opportunity.value.toFixed(2)} />;
                case 'OpportunityProbability': return <SingleFieldElement disableTextSelection useEllipsisForLongValues fieldTitle="Probability (%)" fieldValue={opportunity.probability.toFixed(2)} />;
                case 'OpportunityCurrency': return <SingleFieldElement disableTextSelection useEllipsisForLongValues fieldTitle="Currency" fieldValue="Australian Dollar (AUD)" />;
                case 'OpportunityStartDate': return <SingleFieldElement disableTextSelection useEllipsisForLongValues fieldTitle="Start Date" fieldValue={opportunity.startDate} format="date" />;
                case 'OpportunityCloseDate': return <SingleFieldElement disableTextSelection useEllipsisForLongValues fieldTitle="Close Date" fieldValue={opportunity.closeDate} format="date" />;
                case 'OpportunityDeadline': return <SingleFieldElement disableTextSelection useEllipsisForLongValues fieldTitle="Deadline" fieldValue={opportunity.deadline} format="date" />;
                case 'OpportunityClient': return (
                    <SingleFieldElement disableTextSelection fieldTitle='Client' format="custom">
                        <Box component="span" color="primary.main">{opportunity.clientName}</Box>
                    </SingleFieldElement>
                );
                case 'OpportunityContact': return (
                    <SingleFieldElement disableTextSelection fieldTitle='Contact' format="custom">
                        <Box component="span" color="primary.main">{opportunity.contactName}</Box>
                    </SingleFieldElement>
                );
                case 'OpportunityCandidate': return (
                    <SingleFieldElement disableTextSelection fieldTitle='Candidate' format="custom">
                        <Box component="span" color="primary.main">{opportunity.candidateName}</Box>
                    </SingleFieldElement>
                );
                case 'OpportunityOwners': return (
                    <SingleFieldElement disableTextSelection fieldTitle='Owners' format="custom">
                        <Box component="span" color="primary.main">5</Box>
                    </SingleFieldElement>
                );
                case 'OpportunityDivision': return (
                    <SingleFieldElement disableTextSelection fieldTitle="Divisions" format="custom">
                        <Chip size="small" label="Placeholder Division" sx={{ mr: '5px' }} />
                    </SingleFieldElement>
                );
                case 'OpportunitySummary': return <MultiLineTextElement disableTextSelection title="Summary" content={opportunity.description ?? ''} />;
                default: 
                    if (type.startsWith('CustomField')) {
                        const settings = customFieldsSettingsMap[type];
                        if (settings && layoutType === 'Edit' && !settings.isEditable) return <SingleFieldElement disableTextSelection fieldTitle={settings.title} fieldValue="[ Non Editable Custom Field ]" />;
                        if (settings) {
                            const title = settings.title;
                            const { field, format } = UdfOpportunityFieldMapObj[type as CustomFieldType];
                            const udfValue = opportunity[field];
                            if (format === 'string' || format === 'date' || format === 'datetime') {
                                return <SingleFieldElement disableTextSelection isMultiLine={settings.isMultiLine} format={format} fieldTitle={title} fieldValue={udfValue ? udfValue.toString() : null} />;
                            }
                            if (format === 'number') {
                                return <SingleFieldElement disableTextSelection format={format} fieldTitle={title} fieldValue={udfValue ? +udfValue : 0} />;
                            }
                        }
                        else return <SingleFieldElement disableTextSelection fieldTitle={type} fieldValue="[ Disabled Custom Field ]" />
                    }
                    return <div>{id}</div>;
            }
        }
    }, [customFieldsSettingsMap, layoutType]);

    const saveChangesHandler = useCallback(async () => {
        const api = layoutRef.current;
        if (api && fetchedSavedState) {
            const obj = api.serialize();
            loadingHandler && loadingHandler(true);
            const res = await UpdateCustomerSettingBySettingName(customerSettingName, JSON.stringify(obj), errorHandler);
            loadingHandler && loadingHandler(false);
            if (!res) return false;
            setInitialState(obj);
        }
        return true;
    }, [fetchedSavedState, customerSettingName, loadingHandler, errorHandler]);

    const saveChangesAsBothHandler = useCallback(async () => {
        const api = layoutRef.current;
        if (api && fetchedSavedState) {
            const obj = api.serialize();
            loadingHandler && loadingHandler(true);
            const res1 = await UpdateCustomerSettingBySettingName(`OpportunityEditRecordDashboardLayoutElements_${opportunityType}`, JSON.stringify(obj), errorHandler);
            const res2 = await UpdateCustomerSettingBySettingName(`OpportunityRecordDashboardLayoutElements_${opportunityType}`, JSON.stringify(obj), errorHandler);
            loadingHandler && loadingHandler(false);
            if (!res1 || !res2) return false;
            setInitialState(obj);
            setShowConfirmSaveBothDialog(false);
            setSaveAsBothEditAndView(false);
        }
        return true;
    }, [opportunityType, fetchedSavedState, loadingHandler, errorHandler]);

    const resetLayoutHandler = useCallback(() => {
        setLastSavedState(null);
        setShowResetToDefaultConfirmDialog(false);
        setLayoutSwapControl(prev => !prev);
    }, []);
    
    const layoutResizeStopHandler = useCallback(() => {
        setScreenResizedControl(prev => !prev);
        layoutChangeHandler();
    }, [layoutChangeHandler]);

    useEffect(() => {
        if (screenResizedControl) {}
        const api = layoutRef.current;
        if (api && fetchedSavedState) {
            const refreshTimeout = setTimeout(() => {
                api.refresh();
                api.refresh();
            }, 250);
            return () => clearTimeout(refreshTimeout);
        }
    }, [fetchedSavedState, screenResizedControl]);

    const renderLayout = useCallback(() => {
        if (!fetchedSavedState) return <></>;

        const mediaQuery = mediaQueryMaxWidth ? `max-width: ${mediaQueryMaxWidth}` : undefined;
        return (
            <DashboardLayoutComponent
                cellSpacing={cellSpacing}
                columns={columns}
                cellAspectRatio={30 / 2}
                allowResizing
                resizeStop={ layoutResizeStopHandler }
                change={ layoutChangeHandler }
                ref={l => layoutRef.current = l}
                mediaQuery={mediaQuery}
            >
                {elements.map(e => (
                    <PanelWrapper
                        key={e.id}
                        id={e.id}
                        col={e.col}
                        row={e.row}
                        sizeX={e.sizeX}
                        sizeY={e.sizeY}
                        minSizeX={e.minSizeX}
                        minSizeY={e.minSizeY}
                        maxSizeX={e.maxSizeX}
                        maxSizeY={e.maxSizeY}
                        resizeControl={screenResizedControl}
                        resizeIconColor={theme.palette.text.disabled}
                        gapX={gapX}
                        gapY={gapY}
                        unitWidth={unitWidth}
                        unitHeight={unitHeight}
                        removeHandler={removeElementHandler}
                    >
                        {renderElement(e.id, e.type)}
                    </PanelWrapper>
                ))}
            </DashboardLayoutComponent>
        );
    }, [elements, fetchedSavedState, theme.palette.text.disabled, screenResizedControl, layoutResizeStopHandler, layoutChangeHandler, removeElementHandler, renderElement]);

    const { unsavedChangesDialog } = useUnsavedChangesDialog(hasChanges, saveChangesHandler, undefined, undefined, true);

    const addedElementTypes = useMemo(() => {
        const api = layoutRef.current;
        if (api && showAddElementDialog) {
            const t = api.serialize();
            return t.map(e => {
                const id = e.id;
                if (id) {
                    const i = id.indexOf('_');
                    return id.substring(0, i);
                }
                return '';
            });

        }
        return [];
    }, [showAddElementDialog]);
    
    return (
        <>
            {unsavedChangesDialog}
            <ConfirmationDialog
                message="Are you sure you want to save the current layout for both Edit and View?"
                title="Confirm Action"
                onClose={ () => setShowConfirmSaveBothDialog(false) }
                onContinue={ saveChangesAsBothHandler }
                open={showConfirmSaveBothDialog}
                fullWidth
            />
            <ConfirmationDialog
                message="Are you sure you want to reset the current layout to its default form? All changes will be lost"
                title="Confirm Action"
                onClose={ () => setShowResetToDefaultConfirmDialog(false) }
                onContinue={ resetLayoutHandler }
                open={showResetToDefaultConfirmDialog}
                fullWidth
            />
            <AddOpportunityDashboardElementDialog
                open={showAddElementDialog}
                activeFields={activeFields}
                alreadyAddedElements={addedElementTypes}
                closeHandler={() => setShowAddElementDialog(false)}
                addElementHandler={addElementHandler}
            />
            <div style={{ marginBottom: '10px' }}>
                <span>
                    <Button
                        variant="contained"
                        endIcon={<ReplayIcon />}
                        onClick={() => setShowResetToDefaultConfirmDialog(true)}
                        sx={{ mr: '5px' }}
                    >Reset to default</Button>
                    <Button
                        variant="contained"
                        endIcon={<AddIcon />}
                        onClick={() => setShowAddElementDialog(true)}
                    >Add Element</Button>
                </span>
                <span style={{ float: 'right' }}>
                    <FormControlLabel
                        sx={{ m: 0, mr:'20px' }}
                        label="Save as both Edit & View"
                        labelPlacement="start"
                        control={
                            <Checkbox
                                checked={ saveAsBothEditAndView }
                                onChange={ () => setSaveAsBothEditAndView(prev => !prev) }
                                sx={{ p: '4px' }}
                            />
                        }
                    />
                    <Button
                        color="success"
                        variant="contained"
                        endIcon={<SaveIcon />}
                        disabled={!hasChanges && !saveAsBothEditAndView}
                        onClick={saveAsBothEditAndView ? () => setShowConfirmSaveBothDialog(true) : saveChangesHandler}
                    >Save</Button>
                </span>
            </div>
            <Box p="10px" height="100%">
                <div className="control-section">
                    {layoutSwapControl && renderLayout()}
                    {!layoutSwapControl && renderLayout()}
                </div>
            </Box>
        </>
    );
}