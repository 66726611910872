import Alert from "components/Alert";
import Snackbar from "@mui/material/Snackbar";
import React, { useEffect, useMemo, useState } from "react";
import PageContentLayout from "layouts/PageContentLayout";
import PageLayout from "layouts/PageLayout";
import EditRecordScreenLayout from "components/ScreenLayouts/Placements/EditRecordScreenLayout";
import { GetCustomerSettingBySettingName, GetPlacementsSettings, GetPlacementsSources } from "services/ConfigurationService";
import { useParams } from "react-router-dom";

export default function PlacementEditPage() {
    const [summaryBar, setSummaryBar] = useState(<></>);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [netRevenueLabel, setNetRevenueLabel] = useState('Net Revenue');
    const [isOptionalEndDate, setIsOptionalEndDate] = useState(false);
    const [isPredefinedSources, setIsPredefinedSources] = useState(false);
    const [isSeperatePayBillDivision, setIsSeperatePayBillDivision] = useState(false);
    const [ratesCalculatorLink, setRatesCalculatorLink] = useState('');
    const [sources, setSources] = useState<string[]>();
    const params = useParams();

    const placementId = useMemo(() => {
        const parsedId = +(params.id ?? '0');
        if (isNaN(parsedId)) return 0;
        return parsedId;
    }, [params.id]);

    useEffect(() => {
        const getNetRevenueLabel = async () => {
            const settings = await GetPlacementsSettings();
            if (settings && settings.length > 0) {
                for (let i = 0; i < settings.length; i++) {
                    const s = settings[i];
                    if (s.type === 'PlacementOptionalEndDate' && s.value) setIsOptionalEndDate(s.value.toLowerCase() === 'true');
                    else if (s.type === 'PlacementRatesCalculator' && s.value) setRatesCalculatorLink(s.value);
                    else if (s.type === 'PlacementsPredefinedSources' && s.value) setIsPredefinedSources(s.value.toLowerCase() === 'true');
                    else if (s.type === 'PlacementsSeperatePayBillDivision' && s.value) setIsSeperatePayBillDivision(s.value.toLowerCase() === 'true');
                }
            }
            const res = await GetCustomerSettingBySettingName('NetRevenueName');
            if (res) setNetRevenueLabel(res);
        };
        getNetRevenueLabel();
    }, []);

    useEffect(() => {
        const getSources = async () => {
            const res = await GetPlacementsSources();
            if (res) setSources(res.map(s => s.name));
        };
        isPredefinedSources && placementId && getSources();
    }, [isPredefinedSources, placementId]);

    return (
        <PageLayout SummaryBar={summaryBar}>
            <Snackbar open={errorMessage !== ''} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <Snackbar open={successMessage !== ''} autoHideDuration={3000} onClose={() => setSuccessMessage('')} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert color="success" onClose={() => setSuccessMessage('')}>{ successMessage }</Alert>
            </Snackbar>
            <PageContentLayout showLoading={isLoading}>
                <EditRecordScreenLayout
                    placementId={placementId}
                    netRevenueLabel={netRevenueLabel}
                    isOptionalEndDate={isOptionalEndDate}
                    isSeperatePayBillDivision={isSeperatePayBillDivision}
                    ratesCalculatorLink={ratesCalculatorLink}
                    predefinedSources={sources}
                    loadingHandler={setIsLoading}
                    setSummaryBar={setSummaryBar}
                    errorHandler={setErrorMessage}
                    successHandler={setSuccessMessage}
                />
            </PageContentLayout>
        </PageLayout>
    );
}