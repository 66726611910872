import { Candidate, CandidateRefCheckData, CandidateUpload, CandidateWorkHistory, ScreenCandidateData } from "../common/models/Candidates/Candidate";
import { CandidateList } from "../common/models/Candidates/CandidateList";
import { ErrorHandler, NumberValueWrapper, RequestOptions, StringValueWrapper } from "../common/models/GenericTypes";
import { DownloadBlob } from "../util/FilesUtils";
import { Report } from "../common/models/Reports/Report";
import { DownloadFile, Get, Post, Delete, Put, PostFile, PutFile } from "../util/HttpUtils";
import { ActivityMessage } from "../common/models/Activities";
import { Submission } from "../common/models/Submissions/Submission";
import { Placement } from "../common/models/Placements";
import { OfferConfirmationData } from "../common/models/Candidates/MakeOfferData";
import { JobTracking } from "../common/models/Jobs/JobTracking";
import { CandidateJob } from "../common/models/Jobs/CandidateJob";
import {Meeting, MeetingAttendee} from "../common/models/Meetings";
import { CandidateSearch } from "common/models/Search/CandidateSearch";
import { ImportableCandidate } from "../common/models/Imports/CandidateImport";
import { PartnerAction } from "common/models/PartnerActions";
import { RejectionEmailVerificationData } from "common/models/Jobs/RejectionEmailVerificationData";
import { CandidateDataExceptionReportRecord } from "common/models/Reports/CandidateDataExceptionReportRecord";
import {Interview} from "common/models/Interviews";

export async function CandidateLookup(term: string, limit: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return await Get<CandidateList[]>(`api/candidates/Lookup?query=${encodeURIComponent(term)}&limit=${limit}`, options);
}

export async function GetCandidateById(candidateId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Candidate>(`api/candidates/${candidateId}`, options);
}

export async function GetCandidateWorkHistory(candidateId: number, limit: number = 0, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    if (limit > 0) return Get<CandidateWorkHistory[]>(`api/Candidates/${candidateId}/WorkHistory?limit=${limit}`, options);
    return Get<CandidateWorkHistory[]>(`api/candidates/${candidateId}/WorkHistory`, options);
}

export async function GetCandidateSingleWorkHistory(candidateId: number, workHistoryId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<CandidateWorkHistory>(`api/candidates/${candidateId}/WorkHistory/${workHistoryId}`, options);
}

export async function CreateCandidateWorkHistory(candidateId: number, data: Partial<CandidateWorkHistory>, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler,
    };

    return Post<boolean>(`api/candidates/${candidateId}/WorkHistory`, options, data);
}

export async function UpdateCandidateWorkHistory(candidateId: number, workHistoryId: number, data: Partial<CandidateWorkHistory>, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler,
    };

    return Put<boolean>(`api/candidates/${candidateId}/WorkHistory/${workHistoryId}`, options, data);
}

export async function DownloadCandidateResume(candidateId: number, fileName?: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    const file = await DownloadFile(`api/candidates/${candidateId}/Resume`, options);
    if (file) {
        DownloadBlob(file.data, fileName ? fileName + file.fileExtension : file.fileName);
    }
}

export async function DownloadCandidateResumeForViewer(candidateId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return DownloadFile(`api/candidates/${candidateId}/Resume/Viewer`, options);
}

export async function UploadCandidateResume(candidateId: number, jobId: number, files: File[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
        const f = files[i];
        formData.append('files', f);
    }

    return PostFile<boolean>(`api/candidates/${candidateId}/Resume?jobId=${jobId}`, formData, options);
}

export async function GetCandidatesRecentUploads(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<CandidateUpload[]>('api/candidates/uploads/recent', options);
}

export async function GetCandidatesRecordsRecentUploads(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<CandidateList[]>('api/candidates/uploads/recent/full', options);
}

export async function GetReports(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Report[]>('api/candidates/reports', options);
}

export async function GetCandidatesNewReportData(sinceDate: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<any>(`api/candidates/reports/new?sinceDate=${sinceDate}`, options)
}
export async function GetCandidatesRecentlyInactive(timeRange: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<any>(`api/candidates/reports/recently-inactive?timeRange=${timeRange}`, options)
}

export async function GetCandidatesByEmployerReportData(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<any>(`api/candidates/reports/candidates-by-employer`, options)
}

export async function GetCandidatesSnapshotReportData(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<any>(`api/candidates/reports/database-snapshot`, options)
}

export async function GetCandidatesSnapshotStateReportData(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<any>(`api/candidates/reports/database-snapshot-state`, options)
}

export async function GetCurrentJobForPreviouslyPlacedCandidatesReportData(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<any>(`api/candidates/reports/previously-placed`, options)
}

export async function GetCandidatesSeriouslyLookingReportData(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<any>(`api/candidates/reports/seriously-looking`, options)
}

export async function GetCandidatesDataExceptionReportData(timeRange: number, divisions: number[], missingData: string[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    const query = new URLSearchParams();
    query.set('timeRange', timeRange.toString());
    for (let i = 0; i < divisions.length; i++) {
        const d = divisions[i];
        query.append('divisions', d.toString());
    }
    
    for (let i = 0; i < missingData.length; i++) {
        const m = missingData[i];
        query.append('missingData', m);
    }

    return Get<CandidateDataExceptionReportRecord[]>(`api/candidates/reports/data-exception?${query.toString()}`, options)
}

export async function GetCandidatesDetailedReportData(divisions: number[], jobSeekingStatus: number[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    const query = new URLSearchParams();
    for (let i = 0; i < divisions.length; i++) {
        const d = divisions[i];
        query.append('divisions', d.toString());
    }
    
    for (let i = 0; i < jobSeekingStatus.length; i++) {
        const s = jobSeekingStatus[i];
        query.append('jobSeekingStatus', s.toString());
    }

    return Get<any>(`api/candidates/reports/detailed?${query.toString()}`, options)
}

export async function GetCandidatesByTag(tagTypeId: number, taggedBy: number, tag: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<CandidateList[]>(`api/candidates/tags/${tagTypeId}?taggedBy=${taggedBy}&tag=${encodeURIComponent(tag)}`, options);
}

export async function MergeCandidates(candidateIds: number[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>('api/candidates/merge', options, candidateIds);
}

export async function DeleteCandidateById(candidateId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Delete<boolean>(`api/candidates/${candidateId}`, options);
}

export async function GetCandidatePlacements(candidateId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Placement[]>(`api/candidates/${candidateId}/placements`, options);
}

export async function GetCandidateMeetings(candidateId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Meeting[]>(`api/candidates/${candidateId}/meetings`, options);
}

export async function GetCandidateInterviews(candidateId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Interview[]>(`api/candidates/${candidateId}/interviews`, options);
}

export async function GetCandidateMessages(candidateId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<ActivityMessage[]>(`api/candidates/${candidateId}/messages`, options);
}

export async function GetCandidateSubmissions(candidateId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Submission[]>(`api/candidates/${candidateId}/submissions`, options);
}

export async function CreateCandidate(candidateData: Partial<Candidate>, photoFile: File | null, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    const formData = new FormData();
    formData.append('candidate', JSON.stringify(candidateData));
    if (photoFile) formData.append('photo', photoFile);

    return PostFile<NumberValueWrapper>('api/candidates', formData, options);
}

export async function UpdateCandidate(candidateId: number, candidateData: Partial<Candidate>, photoFile: File | null, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    const formData = new FormData();
    formData.append('candidate', JSON.stringify(candidateData));
    if (photoFile) formData.append('photo', photoFile);

    return PutFile<boolean>(`api/candidates/${candidateId}`, formData, options);
}

export async function UpdateCandidateCustomField(candidateId: number, fieldNumber: number, value: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/candidates/${candidateId}/custom-fields`, options, { fieldNumber, value });
}

export async function ToggleCandidateOptOut(candidateId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/candidates/${candidateId}/opt-out`, options);
}

export async function LinkCandidateToJob(candidateId: number, jobId: number, ratingId: number, source: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/candidates/${candidateId}/link-job/${jobId}/${ratingId}?source=${encodeURIComponent(source)}`, options);
}

export async function LinkCandidatesToJob(candidateIds: number[], jobId: number, ratingId: number, source: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/candidates/link-job/${jobId}/${ratingId}?source=${encodeURIComponent(source)}`, options, candidateIds);
}

export async function CandidateOfferMade(candidateId: number, jobId: number, data: OfferConfirmationData, files: File[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    const formData = new FormData();
    formData.append('offerData', JSON.stringify(data));
    for (let i = 0; i < files.length; i++) {
        const f = files[i];
        formData.append('files', f);
    }

    return PostFile<boolean>(`api/candidates/${candidateId}/offer/${jobId}`, formData, options);
}

export async function SendCandidateToOutsourcingWizard(candidateId: number, jobId: number, priority: string, cvTemplate: string, comments: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>(`api/candidates/${candidateId}/outsourcing-wizard`, options, { 
        jobId: jobId,
        priority: priority,
        cvTemplate: cvTemplate,
        comments: comments,
    });
}

export async function GetCandidateJobs(candidateId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<CandidateJob[]>(`api/candidates/${candidateId}/jobs`, options);
}

export async function GetCandidateJob(candidateId: number, jobId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<JobTracking>(`api/candidates/${candidateId}/jobs/${jobId}`, options);
}

export async function UpdateCandidateJobRating(candidateId: number, jobId: number, actionId: number, reason: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/candidates/${candidateId}/jobs/${jobId}/updateRating`, options, { ratingId: actionId, reason: reason });
}

export async function UpdateCandidateJobRating_Bulk(candidateIds: number[], jobId: number, actionId: number, reason: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/candidates/jobs/${jobId}/updateRating`, options, { ratingId: actionId, reason: reason, candidateIds: candidateIds });
}

export async function UpdateCandidateSavedSearchRating(candidateId: number, ssid: number, actionId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/candidates/${candidateId}/saved-search/${ssid}/updateRating`, options, { ratingId: actionId });
}

export async function UpdateCandidateSavedSearchRating_Bulk(candidateIds: number[], ssid: number, actionId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/candidates/saved-search/${ssid}/updateRating`, options, { ratingId: actionId, candidateIds: candidateIds });
}

export async function UpdateCandidateSavedSearchFrequency(ssid: number, frequencyId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/candidates/saved-search/${ssid}/frequency/${frequencyId}`, options);
}

export async function ExportCandidateToFoundU(candidateId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>(`api/candidates/${candidateId}/export/foundU`, options);
}

export async function ExportMultipleCandidatesToFoundU(candidateIds: number[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>(`api/candidates/export/foundU`, options, candidateIds);
}

export async function ExportCandidateToDeputy(candidateId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>(`api/candidates/${candidateId}/export/Deputy`, options);
}

export async function ExportMultipleCandidatesToDeputy(candidateIds: number[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>(`api/candidates/export/Deputy`, options, candidateIds);
}

export async function VerifyRejectionEmail(candidateId: number, jobId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<StringValueWrapper>(`api/candidates/${candidateId}/jobs/${jobId}/verify-rejection`, options);
}

export async function VerifyRejectionEmailByCandidateList(candidateIds: number[], jobId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<RejectionEmailVerificationData[]>(`api/candidates/jobs/${jobId}/verify-rejection`, options, candidateIds);
}

export async function SendRejectionEmail(candidateId: number, jobId: number, templateId: number, sendLater: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>(`api/candidates/${candidateId}/jobs/${jobId}/rejection-email?templateId=${templateId}&sendLater=${sendLater}`, options);
}

export async function SendRejectionEmailByCandidateList(candidateIds: number[], jobId: number, templateId: number, sendLater: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>(`api/candidates/jobs/${jobId}/rejection-email?templateId=${templateId}&sendLater=${sendLater}`, options, candidateIds);
}

export async function GetCandidateAttendeeData(candidateIds: number[], errorHandler?: ErrorHandler){
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    let query = '';

    if (candidateIds.length > 0) {
        query = 'candidateIds=' + candidateIds.join('&candidateIds=');
    }

    return Get<MeetingAttendee[]>(`api/Candidates/Attendees?${query}`, options);
}

export async function ScreenCandidate(data: ScreenCandidateData, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<NumberValueWrapper>(`api/candidates/screen`, options, data);
}

export async function DownloadCandidateScreeningPdf(activityId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    const file = await DownloadFile(`api/candidates/screen/${activityId}/pdf`, options);
    if (file) {
        DownloadBlob(file.data, file.fileName);
    }
}

export async function ReferenceCheckCandidate(data: CandidateRefCheckData, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<NumberValueWrapper>(`api/candidates/${data.candidateId}/reference-check`, options, data);
}



export async function SearchCandidates(data: CandidateSearch, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<CandidateList[]>('api/candidates/search', options, data);
}

export async function SaveCandidatesSearch(searchName: string, searchFrequency: number, data: CandidateSearch, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<NumberValueWrapper>(`api/candidates/search/${searchFrequency}?name=${encodeURIComponent(searchName)}`, options, data);
}

export async function UpdateCandidateSavedSearch(ssid: number, searchName: string, searchFrequency: number, data: CandidateSearch, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/candidates/search/${ssid}?freqId=${searchFrequency}&name=${encodeURIComponent(searchName)}`, options, data);
}

export async function ImportCandidate(data: Partial<ImportableCandidate>, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<NumberValueWrapper>('api/candidates/import', options, data);
}

export async function GetCandidatePartnerActions(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<PartnerAction[]>('api/candidates/partneractions', options);
}

export async function GetCandidateWorkflowPartnerActions(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<PartnerAction[]>('api/candidates/partneractions/workflow', options);
}

export async function RedFlagCandidate(candidateId: number, comments: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>(`api/candidates/${candidateId}/red-flag`, options, { comments: comments });
}

export async function RemoveRedFlag(candidateId: number, comments: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>(`api/candidates/${candidateId}/remove-red-flag`, options, { comments: comments });
}

export async function AcknowledgeRedFlag(candidateId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>(`api/candidates/${candidateId}/acknowledge-red-flag`, options, { } );
}

export async function DownloadResumes(candidateIds: number[], jobId: number, typeId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/candidates/download-resumes/${jobId}/${typeId}`, options, candidateIds);
}

export async function UploadFormattedCVToCandidate(candidateId: number, file: File, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    const formData = new FormData();
    formData.append('file', file);

    return PostFile<NumberValueWrapper>(`api/candidates/${candidateId}/documents/formatted-cv`, formData, options);
}

