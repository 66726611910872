import Alert from "components/Alert";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import React, { useCallback, useEffect, useState } from "react";
import { SmsConfigOptions } from "common/models/Configuration/SMS";
import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import useObjectStateWithChangeTracker from "hooks/UseObjectStateWithChangeTracker";
import useUnsavedChangesDialog from "hooks/UseUnsavedChangesDialog";
import PageContentLayout from "layouts/PageContentLayout";
import { GetSmsSettings, SaveSmsSettings } from "services/ConfigurationService";
import { DefaultOptions, NoChangesTracker } from "util/Definitions/Configuration/SMS";
import { IsValidNumericValue } from "util/RegExUtils";
import { ObjToSettingsArray, SettingsArrayToObj } from "util/SettingsUtil";
import RWTextFieldComponent from "components/RWTextFieldComponent";

interface Props {
    setSummaryBar?: (summaryBar: JSX.Element) => void
}

interface tmpSendSmsSettings {
    CreditLimit: number,
    IsConfirmToSend: boolean
};

const defaultSendSmsSettings: tmpSendSmsSettings = {
    CreditLimit: 0,
    IsConfirmToSend: true
};

export default function SmsSettings({ setSummaryBar }: Props) {
    const [isLoading, setIsLoading] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [sendSmsSettings, setSendSmsSettings] = useState(defaultSendSmsSettings);
    const { state, init, change, updateInitial, hasChanges } = useObjectStateWithChangeTracker<SmsConfigOptions>(DefaultOptions, NoChangesTracker);

    useEffect(() => {
        const getData = async () => {
            setIsLoading(true);
            const res = await GetSmsSettings(setErrorMessage);
            if (res) {
                let settings = SettingsArrayToObj<SmsConfigOptions>(res, DefaultOptions);
                if (settings.DialogSettings_SendSMS) {
                    const obj = JSON.parse(settings.DialogSettings_SendSMS) as tmpSendSmsSettings;
                    setSendSmsSettings(obj);
                }
                else settings.DialogSettings_SendSMS = JSON.stringify(defaultSendSmsSettings);

                init(settings);
            }
            setIsLoading(false);
        };
        getData();
    }, [init]);

    const saveHandler = useCallback(async() => {
        setIsLoading(true);
        const settings = ObjToSettingsArray(state);
        const res = await SaveSmsSettings(settings, setErrorMessage);
        
        if (!res) {
            setIsLoading(false);
            return false;
        }
        
        updateInitial();
        setShowSuccess(true);
        setIsLoading(false);
        return true;
    }, [state, updateInitial]);

    useEffect(() => {
        const saveButton = <Button variant="contained" color="primary" onClick={saveHandler} disabled={!hasChanges}>Save</Button>;
        const summaryBar = <TitleAndActionSummaryBar title="Configuration > SMS > Settings" browserTabTitle="SMS > Configuration" action={saveButton} />;
        setSummaryBar && setSummaryBar(summaryBar);
    }, [hasChanges, saveHandler, setSummaryBar]);

    const handleChanges = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        change(name as keyof SmsConfigOptions, value);
    };

    const handleSendSmsChanges = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        if (name === 'IsConfirmToSend') setSendSmsSettings(prev => ({...prev, IsConfirmToSend: value === '1'}));
        else if (name === 'CreditLimit' && IsValidNumericValue(value)) {
            setSendSmsSettings(prev => ({...prev, CreditLimit: +value}))
        }
    };

    useEffect(() => {
        change('DialogSettings_SendSMS', JSON.stringify(sendSmsSettings));
    }, [sendSmsSettings, change]);

    const { unsavedChangesDialog } = useUnsavedChangesDialog(hasChanges, saveHandler);

    return (
        <>
            {unsavedChangesDialog}
            <Snackbar open={showSuccess} autoHideDuration={3000} onClose={() => setShowSuccess(false)}>
                <Alert onClose={() => setShowSuccess(false)}>Changes Saved</Alert>
            </Snackbar>
            <Snackbar open={errorMessage !== ''}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <PageContentLayout title="SMS Settings" showLoading={isLoading}>
                <Stack spacing={3}>
                    <TextField
                        select
                        label="Provider"
                        name="SMSProvider"
                        value={state.SMSProvider}
                        onChange={handleChanges}
                    >
                        <MenuItem value="0">Recruit Wizard SMS ( Legacy )</MenuItem>
                        <MenuItem value="2">Recruit Wizard SMS</MenuItem>
                    </TextField>
                    <TextField
                        select
                        label="Send Type"
                        name="SmsFromType"
                        value={state.SmsFromType}
                        onChange={handleChanges}
                        InputProps={{ readOnly: true }}
                    >
                        <MenuItem value="0">Alphacode</MenuItem>
                        <MenuItem value="1">Dedicated Number</MenuItem>
                    </TextField>
                    {state.SmsFromType === "0" &&
                        <RWTextFieldComponent
                            label="From Name"
                            name="SmsFromName"
                            value={state.SmsFromName}
                            onChange={handleChanges}
                        />
                    }
                    {state.SmsFromType === "1" &&
                        <TextField
                            label="Dedicated Number"
                            name="SmsDedicatedNumber"
                            value={state.SmsDedicatedNumber}
                            onChange={handleChanges}
                            InputProps={{ readOnly: true }}
                        />
                    }
                    <TextField
                        select
                        label="Confirmation to Send?"
                        name="IsConfirmToSend"
                        value={sendSmsSettings.IsConfirmToSend ? '1' : '0'}
                        onChange={handleSendSmsChanges}
                    >
                        <MenuItem value="1">Enabled</MenuItem>
                        <MenuItem value="0">Disabled</MenuItem>
                    </TextField>
                    <RWTextFieldComponent
                        label="Confirmation Limit"
                        name="CreditLimit"
                        value={sendSmsSettings.CreditLimit.toString()}
                        onChange={handleSendSmsChanges}
                    />
                    <TextField
                        select
                        label="Auto Process Opt Out?"
                        name="SMSAutoProcessOptOut"
                        value={state.SMSAutoProcessOptOut}
                        onChange={handleChanges}
                    >
                        <MenuItem value="True">Enabled</MenuItem>
                        <MenuItem value="False">Disabled</MenuItem>
                    </TextField>
                </Stack>
            </PageContentLayout>
        </>
    );
}