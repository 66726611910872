import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { AllowTemplates } from "common/data/Permissions/UserMenuAccess";
import { NameIdObj } from "common/models/GenericTypes";
import { MessageTemplate } from "common/models/Templates/MessageTemplate";
import UserPicker from "components/Pickers/UserPicker";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { AddMessageTemplateAttachment, CreateMessageTemplate } from "services/TemplatesService";
import { getJwtPayload } from "util/AuthUtils";
import { DefaultMessageTemplateData } from "util/Definitions/Templates/MessageTemplates";

interface Props {
    open: boolean,
    body: string,
    subject: string,
    recipientEntityId: number,
    files: File[],
    closeHandler: () => void,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
    successHandler?: (message: string) => void
}

const companyOwnedUserPickerOption: NameIdObj[] = [{ id: 0, name: 'Company Owned' }];

export default function SaveAsMessageTemplateDialog({ open, body, subject, recipientEntityId, files, closeHandler, loadingHandler, errorHandler, successHandler }: Props) {
    const [templateName, setTemplateName] = useState('');
    const [templateType, setTemplateType] = useState(1);
    const [templateOwnerId, setTemplateOwnerId] = useState(0);
    const [includeAttachments, setIncludeAttachments] = useState(false);

    const isTemplateAdmin = useMemo(() => AllowTemplates(), []);

    const currentUserId = useMemo(() => {
        const tokenUserId = getJwtPayload()?.UserID;
        const userId = +(tokenUserId ?? '0');
        return userId;
    }, []);

    useEffect(() => {
        if (!open) {
            setTemplateOwnerId(currentUserId);
            setTemplateType(1);
            setTemplateName('');
            setIncludeAttachments(false);
        }
    }, [currentUserId, open]);

    const saveChangesCallback = useCallback(async () => {
        if (!Boolean(subject)) {
            errorHandler && errorHandler('The template must have a subject');
            return false;
        }

        if(!Boolean(body)) {
            errorHandler && errorHandler('The template must have a body');
            return false;
        }

        if(!Boolean(templateName)) {
            errorHandler && errorHandler('The template must have a name');
            return false;
        }

        let data: MessageTemplate = {...DefaultMessageTemplateData};
        data.body = body;
        data.subject = subject;
        data.candidate = recipientEntityId === 3;
        data.contact = recipientEntityId === 2;
        data.type = templateType;
        data.name = templateName;
        data.recruiterID = templateOwnerId;

        loadingHandler && loadingHandler(true);
        
        const creationData = await CreateMessageTemplate(data, errorHandler);
        if (!Boolean(creationData)) {
            loadingHandler && loadingHandler(false);
            return false;
        }

        if (includeAttachments && files.length > 0 && creationData) {
            const uploadRes = await AddMessageTemplateAttachment(creationData.value, files, errorHandler);
            if (!uploadRes) {
                loadingHandler && loadingHandler(false);
                return false;
            }
        }

        loadingHandler && loadingHandler(false);

        successHandler && successHandler('Message Template Created');
        closeHandler();
        return true;
    }, [body, closeHandler, errorHandler, files, includeAttachments, loadingHandler, recipientEntityId, subject, successHandler, templateName, templateOwnerId, templateType]);

    return (
        <Dialog open={open} fullWidth>
            <DialogTitle>Save As Message Template</DialogTitle>
            <DialogContent>
                <Stack spacing={2} pt={2}>
                    <TextField label="Template Name" value={templateName} onChange={({target}) => setTemplateName(target.value)} />
                    <TextField select label="Template Type" value={templateType.toString()} onChange={({target}) => setTemplateType(+target.value)}>
                        <MenuItem value="1">Email</MenuItem>
                        <MenuItem value="3">Rejection Email</MenuItem>
                        <MenuItem value="4">Interview Confirmation</MenuItem>
                        <MenuItem value="5">Meeting Confirmation</MenuItem>
                        <MenuItem value="6">Offer Confirmation</MenuItem>
                        <MenuItem value="7">Submission</MenuItem>
                        <MenuItem value="8">Float</MenuItem>
                        <MenuItem value="9">Placement</MenuItem>
                        <MenuItem value="10">Onboarding</MenuItem>
                    </TextField>
                    {isTemplateAdmin &&
                        <UserPicker
                            onSelect={ u => u && setTemplateOwnerId(u.id) }
                            userId={templateOwnerId}
                            label="Template Owner"
                            appendToStart={companyOwnedUserPickerOption}
                        />
                    }
                </Stack>
            </DialogContent>
            <DialogActions>
                {files.length > 0 &&
                    <FormControlLabel
                        label="Include Attachments"
                        control={<Checkbox checked={includeAttachments} onChange={({target}) => setIncludeAttachments(target.checked)} sx={{ p: '4px' }} />}
                        sx={{ m: 0, mr: 'auto' }}
                    />
                }
                <Button variant="contained" onClick={closeHandler}>Cancel</Button>
                <Button variant="contained" color="success" onClick={saveChangesCallback}>Save</Button>
            </DialogActions>
        </Dialog>
    );
}