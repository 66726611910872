import { DownloadBlob } from "util/FilesUtils";
import { DocumentGenerationResult } from "../common/models/DocumentGeneration";
import { ErrorHandler, RequestOptions } from "../common/models/GenericTypes";
import { TemplatePlaceholder } from "../common/models/Templates/TemplatePlaceholder";
import { DownloadFileFromPost, Post } from "../util/HttpUtils";

export async function GetEntityDocumentPlaceholderValues(entityId: number, playerId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<TemplatePlaceholder[]>('api/DocumentGeneration/Placeholders', options, { entityId, playerId });
}

export async function GenerateEntityDocumentFromTemplate(entityId: number, playerId: number, templateId: number, placeholders: Pick<TemplatePlaceholder, 'placeholder' | 'value'>[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<DocumentGenerationResult>('api/DocumentGeneration', options, { entityId, playerId, templateId, placeholders});
}

export async function GenerateScreeningRefCheckDocument(entityId: number, playerId: number, templateId: number, placeholders: Pick<TemplatePlaceholder, 'placeholder' | 'value'>[], errorHandler?: ErrorHandler, isScreeningOrRefChecks: boolean = false, isPdf:boolean = false) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };
    const payload = JSON.stringify({ entityId, playerId, templateId, placeholders,isScreeningOrRefChecks, isPdf })
    const file = await DownloadFileFromPost(`api/DocumentGeneration/srcd`, options,payload);
    if (file) {
        DownloadBlob(file.data,file.fileName);
    }
}

export async function GenerateFormattedCv(candidateId: number, templateId: number, resumeTypeId: number, jobId: number, applicationId: number, placeholders: Pick<TemplatePlaceholder, 'placeholder' | 'value'>[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<DocumentGenerationResult>('api/DocumentGeneration/fcv', options, { templateId, resumeTypeId, jobId, candidateId, applicationId, placeholders });
}