import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Breakpoint } from "@mui/material/styles";
import Iframe from "react-iframe";

interface Props {
    open: boolean,
    title: string,
    url: string,
    closeHandler: () => void,
    closeActionText?: string,
    fullWidth?: boolean,
    maxWidth?: false | Breakpoint
}

export default function ShowIframeDialog({ closeHandler, open, title, url, closeActionText = "Close", fullWidth = true, maxWidth = "lg"  }: Props) {
    return (
        <Dialog open={open} maxWidth={maxWidth} fullWidth={fullWidth} >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent sx={{ height: '700px' }}>
                {url &&
                    <Iframe
                        url={url}
                        width="100%"
                        height="100%"
                        display="block"
                        position="relative"
                        className=""
                    />
                }
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    onClick={ closeHandler }
                >
                    { closeActionText }
                </Button>
            </DialogActions>
        </Dialog>
    );
}