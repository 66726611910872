import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { DashboardLayoutComponent, PanelModel } from "@syncfusion/ej2-react-layouts";
import Box from "@mui/material/Box";
import AddIcon from '@mui/icons-material/Add';
import ReplayIcon from '@mui/icons-material/Replay';
import { GetMyBillings, GetMyForecasts } from "services/ForecastsService";
import { Forecast } from "common/models/Analytics/Forecasts";
import GaugeGraphComponent from "./Graphs/GaugeGraphComponent";
import Button from "@mui/material/Button";
import PanelWrapper from "./PanelWrapper";
import moment from "moment";
import { useTheme } from "@mui/material/styles";
import { ExtendedPanelModel, MainDashboardElementDefinition, MainDashboardElementType, TypesTracker } from "common/models/Dashboard/EditLayout";
import AddDashboardElementDialog from "../Dialogs/Dashboard/AddDashboardElementDialog";
import { GetMyUser, GetSettingBySettingName, UpdateSettingBySettingName } from "services/UsersService";
import { ExtendedUser } from "common/models/Configuration/User";
import { DefaultMainDashboardElements, DefaultMainDashboardElementTypesTracker, GetDefaultSizeByElementType, GetElementTypeById, GetMinSizeByElementType, GetPanelDefinitionsFromPanelModels } from "util/Definitions/Dashboards/Main";
import UserAvatarComponent from "./Graphs/UserAvatarComponent";
import { Billing } from "common/models/Analytics/Billings";
import BillingsGaugeGraphComponent from "./Graphs/BillingsGaugeGraphComponent";
import { GetMyContractorBookByQty, GetMyActiveInterviews, GetMyBillingsTrend } from "services/BusinessIntelligence";
import { ActiveInterview, BillingTrend, StageTotalObj, UserFilterTypeID } from "common/models/Analytics/BusinessIntelligence";
import OpenJobsTableGraph from "./Graphs/OpenJobsTableGraph";
import ActiveInterviewsTableGraph from "./Graphs/ActiveInterviewsTableGraph";
import BarGraphStageTotal from "./Graphs/BarGraphStageTotal";
import BarGraphBillingsTrend from "./Graphs/BarGraphBillingsTrend";
import useUnsavedChangesDialog from "hooks/UseUnsavedChangesDialog";
import ConfirmationDialog from "../Dialogs/Generic/ConfirmationDialog";
import { GetCustomerSettingBySettingName, GetDashboardSettings } from "services/ConfigurationService";
import LinkButtonComponent from "./Graphs/LinkButtonComponent";
import ActivitiesGraphComponent from "./Graphs/ActivitiesGraph";
import JobPipelineGraphComponent from "./Graphs/JobPipelineGraph";
import PlacementFeesGraphComponent from "./Graphs/PlacementFeesGraph";
import KpiGaugeGraphComponent from "./Graphs/KpiGaugeGraph";
import RatioGraphComponent from "./Graphs/RatioGraph";
import CandidatePipelineGraphComponent from "./Graphs/CandidatePipelineGraph";

interface Props {
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
    setActionHandler?: (action: JSX.Element) => void
}

type ElementSettingsMap = Record<string, any>;

const LayoutElementsToString = (elements: PanelModel[]) => {
    if (elements.length === 0) return '';
    let s = elements.map(e => ElementString(e));
    s.sort()
    return s.join('|');
}

const ElementString = (element: PanelModel) => {
    const type = GetElementTypeById(element.id ?? '');
    const val = `${type}|${element.row}|${element.col}|${element.sizeX}|${element.sizeY}`;
    return val;
};


const dashboardElements_SettingName = "DashboardLayoutElements";
const unitWidth: number = 112;
const unitHeight: number = 112;
const gapX: number = 10;
const gapY: number = 10;
const cellSpacing = [gapX, gapY];
const columns = 15;
const mediaQueryMaxWidth = '1000px';

export default function MainDashboardLayout({ loadingHandler, errorHandler, setActionHandler }: Props) {
    const [isEditMode, setIsEditMode] = useState(false);
    const [hasChanges, setHasChanges] = useState(false);
    const [configHasChanges, setConfigHasChanges] = useState(false);
    const [showResetToDefaultConfirmDialog, setShowResetToDefaultConfirmDialog] = useState(false);
    const [currentUser, setCurrentUser] = useState<ExtendedUser | null>(null);
    const [thisMonthForecast, setThisMonthForecast] = useState<Forecast | null>(null);
    const [nextMonthForecast, setNextMonthForecast] = useState<Forecast | null>(null);
    const [thisMonthBillings, setThisMonthBillings] = useState<Billing | null>(null);
    const [thisQtrBillings, setThisQtrBillings] = useState<Billing | null>(null);
    const [thisYearBillings, setThisYearBillings] = useState<Billing | null>(null);
    const [myActiveInterviews, setMyActiveInterviews] = useState<ActiveInterview[] | null>(null);
    const [currentContractors, setCurrentContractors] = useState<StageTotalObj[] | null>(null);
    const [historicalBillings, setHistoricalBillings] = useState<BillingTrend[] | null>(null);
    const [stretchLabelOverride, setStretchLabelOverride] = useState<string>();
    const [targetLabelOverride, setTargetLabelOverride] = useState<string>();
    const [showAddElementDialog, setShowAddElementDialog] = useState(false);
    const [layoutSwapControl, setLayoutSwapControl] = useState(true);
    const [screenResizedControl, setScreenResizedControl] = useState(false);
    const [initialState, setInitialState] = useState<ExtendedPanelModel[] | null>(null);
    const [lastSavedState, setLastSavedState] = useState<ExtendedPanelModel[] | null>(null);
    const [fetchingSavedState, setFetchingSavedState] = useState(false);
    const [fetchedSavedState, setFetchedSavedState] = useState(false);
    const layoutRef = useRef<DashboardLayoutComponent | null>(null);
    const [changedElementSettings, setChangedElementSettings] = useState<ElementSettingsMap>({});
    const [initialElementSettings, setInitialElementSettings] = useState<ElementSettingsMap>({});
    const [netRevenueLabel, setNetRevenueLabel] = useState('Net Revenue');
    const theme = useTheme();

    useEffect(() => {
        const keyNums = Object.keys(changedElementSettings);
        setConfigHasChanges(keyNums.length > 0);
    }, [changedElementSettings]);

    const initialStateString = useMemo(() => {
        if (initialState && initialState.length ) return LayoutElementsToString(initialState);
        return '';
    }, [initialState]);

    const layoutChangeHandler = useCallback(() => {
        const api = layoutRef.current;
        if (api) {
            const currentData = api.serialize();
            const currentStringData = LayoutElementsToString(currentData);
            setHasChanges(currentStringData !== initialStateString);
        }
    }, [initialStateString]);

    useEffect(() => {
        const getNetRevenueLabel = async () => {
            const res = await GetCustomerSettingBySettingName('NetRevenueName');
            if (res) setNetRevenueLabel(res);
        };
        getNetRevenueLabel();
    }, []);

    useEffect(() => {
        if (layoutSwapControl) {}
        layoutChangeHandler();
    }, [layoutSwapControl, layoutChangeHandler]);

    useEffect(() => {
        if (lastSavedState) {}
        setLayoutSwapControl(prev => !prev);
    }, [lastSavedState]);

    useEffect(() => {
        const windowResizeHandler = () => setScreenResizedControl(prev => !prev);
        window.addEventListener('resize', windowResizeHandler);
        return () => window.removeEventListener('resize', windowResizeHandler);
    }, []);

    useEffect(() => {
        loadingHandler && loadingHandler(fetchingSavedState);
    }, [loadingHandler, fetchingSavedState]);

    useEffect(() => {
        const getSavedState = async () => {
            setFetchingSavedState(true);
            const elementsJson = await GetSettingBySettingName(dashboardElements_SettingName);
            if (elementsJson) {
                const panels = JSON.parse(elementsJson) as ExtendedPanelModel[];
                let initialConfigs: ElementSettingsMap = {};
                for (let i = 0; i < panels.length; i++) {
                    const e = panels[i];
                    const id = e.id ?? '';
                    if (e.config) initialConfigs[id] = e.config;
                }
                setLastSavedState(panels);
                setInitialState(panels);
                setInitialElementSettings(initialConfigs);
            }
            else setInitialState(DefaultMainDashboardElements as ExtendedPanelModel[]);
            setFetchingSavedState(false);
            setFetchedSavedState(true);
        };
        getSavedState();
    }, []);

    const elements = useMemo<MainDashboardElementDefinition[]>(() => {
        if (!fetchedSavedState) return [];
        if (lastSavedState !== null) {
            const elements = GetPanelDefinitionsFromPanelModels(lastSavedState);
            return elements ;
        }
        return DefaultMainDashboardElements;
    }, [lastSavedState, fetchedSavedState]);

    const typesTracker = useMemo<TypesTracker>(() => {
        let t = {...DefaultMainDashboardElementTypesTracker};
        elements.forEach(e => t[e.type] = true);
        return t;
    }, [elements]);

    useEffect(() => {
        const getForecastData = async () => {
            const res = await GetMyForecasts();
            if (res) {
                const thisMonth = res.find(f => f.period.toLowerCase() === 'this month');
                const nextMonth = res.find(f => f.period.toLowerCase() === 'next month');
                if (thisMonth) setThisMonthForecast(thisMonth);
                if (nextMonth) setNextMonthForecast(nextMonth);
            }
        };
        (typesTracker.ForecastNextMonth || typesTracker.ForecastThisMonth) && !thisMonthForecast && !nextMonthForecast && getForecastData();
    }, [typesTracker.ForecastNextMonth, typesTracker.ForecastThisMonth, thisMonthForecast, nextMonthForecast]);

    useEffect(() => {
        const getUserData = async () => {
            const userRes = await GetMyUser();
            if (userRes) setCurrentUser(userRes);
        };
        typesTracker.UserInfo && !currentUser && getUserData();
    }, [typesTracker.UserInfo, currentUser]);

    useEffect(() => {
        const getThisMonthBillings = async () => {
            const billingsThisMonthRes = await GetMyBillings('ThisMonth');
            if (billingsThisMonthRes) setThisMonthBillings(billingsThisMonthRes);
        };
        typesTracker.BillingsThisMonth && !thisMonthBillings && getThisMonthBillings();
    }, [typesTracker.BillingsThisMonth, thisMonthBillings]);

    useEffect(() => {
        const getThisQtrBillings = async () => {
            const billingsThisQtrRes = await GetMyBillings('ThisQtr');
            if (billingsThisQtrRes) setThisQtrBillings(billingsThisQtrRes);
        };
        typesTracker.BillingsThisQtr && !thisQtrBillings && getThisQtrBillings();
    }, [typesTracker.BillingsThisQtr, thisQtrBillings]);

    useEffect(() => {
        const getThisYearBillings = async () => {
            const billingsThisYearRes = await GetMyBillings('ThisYear');
            if (billingsThisYearRes) setThisYearBillings(billingsThisYearRes);
        };
        typesTracker.BillingsThisYear && !thisYearBillings && getThisYearBillings();
    }, [typesTracker.BillingsThisYear, thisYearBillings]);

    useEffect(() => {
        const getActiveInterviewsData = async () => {
            const myActiveInterviewsRes = await GetMyActiveInterviews();
            if (myActiveInterviewsRes) setMyActiveInterviews(myActiveInterviewsRes);
        };
        typesTracker.MyActiveInterviews && !myActiveInterviews && getActiveInterviewsData();
    }, [typesTracker.MyActiveInterviews, myActiveInterviews]);

    useEffect(() => {
        const getCurrentContractorsData = async () => {
            const currentContractorsRes = await GetMyContractorBookByQty(UserFilterTypeID.User);
            if (currentContractorsRes) setCurrentContractors(currentContractorsRes);
        };
        typesTracker.CurrentContractors && !currentContractors && getCurrentContractorsData();
    }, [typesTracker.CurrentContractors, currentContractors]);

    useEffect(() => {
        const getBillingsTrendData = async () => {
            const historicalBillingsRes = await GetMyBillingsTrend();
            if (historicalBillingsRes) setHistoricalBillings(historicalBillingsRes);
            const dashboardSettings = await GetDashboardSettings();
            if (dashboardSettings) {
                const s = dashboardSettings.find(v => v.type === 'StretchLabelOverride');
                const t = dashboardSettings.find(v => v.type === 'TargetLabelOverride');
                if (s) setStretchLabelOverride(s.value);
                if (t) setTargetLabelOverride(t.value);
            }
        };
        typesTracker.HistoricalBillings && !historicalBillings && getBillingsTrendData();
    }, [typesTracker.HistoricalBillings, historicalBillings]);

    const linkButtonConfigChange = useCallback((id: string, newTitle: string, newUrl: string) => {
        setChangedElementSettings(prev => {
            let tmp = {...prev};
            tmp[id] = { title: newTitle, url: newUrl };
            return tmp;
        });
    }, []);

    const activitiesGraphConfigChange = useCallback((id: string, newTitle: string, timeRange: number, consultantVal: number) => {
        setChangedElementSettings(prev => {
            let tmp = {...prev};
            tmp[id] = { title: newTitle, timeRange: timeRange, consultant: consultantVal };
            return tmp;
        });
    }, []);

    const placementFeesGraphConfigChange = useCallback((id: string, timeRange: number, consultantVal: number) => {
        setChangedElementSettings(prev => {
            let tmp = {...prev};
            tmp[id] = { timeRange: timeRange, consultant: consultantVal };
            return tmp;
        });
    }, []);

    const kpiGraphConfigChange = useCallback((id: string, title: string, timeRange: number, type: string, sourceType: string, sourceVal: number) => {
        setChangedElementSettings(prev => {
            let tmp = {...prev};
            tmp[id] = { title: title, timeRange: timeRange, kpiType: type, sourceType: sourceType, sourceId: sourceVal };
            return tmp;
        });
    }, []);

    const ratioGraphConfigChange = useCallback((id: string, title: string, timeRange: number, type: string, sourceType: string, sourceVal: number) => {
        setChangedElementSettings(prev => {
            let tmp = {...prev};
            tmp[id] = { title: title, timeRange: timeRange, ratioType: type, sourceType: sourceType, sourceId: sourceVal };
            return tmp;
        });
    }, []);

    const renderElement = useCallback((id: string, type: MainDashboardElementType, config?: any) => {
        switch (type) {
            case "UserInfo":
                return <UserAvatarComponent user={currentUser} />;
            case "ForecastThisMonth":
                return <GaugeGraphComponent data={thisMonthForecast} title="Forecast - This Month" />;
            case "ForecastNextMonth":
                return <GaugeGraphComponent data={nextMonthForecast} title="Forecast - Next Month" />;
            case "BillingsThisMonth":
                return <BillingsGaugeGraphComponent data={thisMonthBillings} title="Billings - This Month" />;
            case "BillingsThisQtr":
                return <BillingsGaugeGraphComponent data={thisQtrBillings} title="Billings - This Qtr" />;
            case "BillingsThisYear":
                return <BillingsGaugeGraphComponent data={thisYearBillings} title="Billings - This Year" />;
            case "HistoricalBillings":
                return  <BarGraphBillingsTrend data={historicalBillings} title="Historical Billings" stretchLabel={stretchLabelOverride} targetLabel={targetLabelOverride} />
            case "MyOpenJobs": return <OpenJobsTableGraph gridName="Dashboard/MyOpenJobs" />;
            case "MyActiveInterviews": return <ActiveInterviewsTableGraph gridName="Dashboard/MyActiveInterviews" data={myActiveInterviews} />;
            case "CurrentContractors": return <BarGraphStageTotal data={currentContractors} title="Current Contractors" />;
            case "LinkButton": 
                const link = config && config.url ? config.url : '';
                const title = config && config.title ? config.title : '';
                return <LinkButtonComponent id={id} title={title} url={link} showConfigButton={isEditMode} saveConfigHandler={linkButtonConfigChange} />
            case "Activity":
                const activityTitle = config && config.title ? config.title : 'Activities';
                const activitiesConsultant = config && config.consultant ? config.consultant : -1;
                const timeRange = config && config.timeRange ? config.timeRange : 1;
                return <ActivitiesGraphComponent id={id} title={activityTitle} consultant={activitiesConsultant} timeRange={timeRange} showConfigButton={isEditMode} saveConfigHandler={activitiesGraphConfigChange} />;
            case "JobPipeline": return <JobPipelineGraphComponent />;
            case "CandidatePipeline": return <CandidatePipelineGraphComponent />;
            case "PlacementFees":
                const timeRangeFees = config && config.timeRange ? config.timeRange : 2;
                const placementFeesConsultant = config && config.consultant ? config.consultant : -1;
                return <PlacementFeesGraphComponent id={id} consultant={placementFeesConsultant} timeRange={timeRangeFees} showConfigButton={isEditMode} netRevenueLabel={netRevenueLabel} saveConfigHandler={placementFeesGraphConfigChange} />;
            case "KPI":
                const kpiTitle = config && config.title ? config.title : 'KPI';
                const prefix = config && config.kpiType ? config.kpiType : 'bdCalls';
                const sourceType = config && config.sourceType ? config.sourceType : 'consultant';
                const sourceId = config && config.sourceId ? config.sourceId : -1;
                const kpiTimeRange = config && config.timeRange ? config.timeRange : 1;
                return (
                    <KpiGaugeGraphComponent
                        id={id}
                        title={kpiTitle}
                        sourceType={sourceType}
                        sourceId={sourceId}
                        timeRange={kpiTimeRange}
                        type={prefix}
                        showConfigButton={isEditMode}
                        saveConfigHandler={kpiGraphConfigChange}
                    />
                );
                case "Ratio":
                    const ratioTitle = config && config.title ? config.title : 'Ratio';
                    const ratioType = config && config.ratioType ? config.ratioType : 'submissionsToFirstInterviewRatio';
                    const ratioSourceType = config && config.sourceType ? config.sourceType : 'consultant';
                    const ratioSourceId = config && config.sourceId ? config.sourceId : -1;
                    const ratioTimeRange = config && config.timeRange ? config.timeRange : 1;
                    return (
                        <RatioGraphComponent
                            id={id}
                            title={ratioTitle}
                            sourceType={ratioSourceType}
                            sourceId={ratioSourceId}
                            timeRange={ratioTimeRange}
                            type={ratioType}
                            showConfigButton={isEditMode}
                            saveConfigHandler={ratioGraphConfigChange}
                        />
                    );
            default: return <div>{id}</div>;
        }
    }, [currentUser, thisMonthForecast, nextMonthForecast, thisMonthBillings, thisQtrBillings, thisYearBillings, historicalBillings, stretchLabelOverride, targetLabelOverride, myActiveInterviews, currentContractors, isEditMode, linkButtonConfigChange, activitiesGraphConfigChange, netRevenueLabel, placementFeesGraphConfigChange, kpiGraphConfigChange, ratioGraphConfigChange]);

    const removeElementHandler = useCallback((id: string) => {
        const api = layoutRef.current;
        if (api) {
            api.removePanel(id);
            layoutChangeHandler();
        }
    }, [layoutChangeHandler]);

    const addElementHandler = useCallback((newElementType: MainDashboardElementType) => {
        const api = layoutRef.current;
        if (api) {
            const m = moment().format('YYYYMMDDhhmmssSS');
            const id = `${newElementType}_${m}`;
            const [minX, minY] = GetMinSizeByElementType(newElementType);
            const [defX, defY] = GetDefaultSizeByElementType(newElementType);
            api.addPanel({ id: id, col: 0, row: 0, sizeX: defX, sizeY: defY });
            api.updatePanel({ id: id, col: 0, row: 0, sizeX: defX, sizeY: defY, minSizeX: minX, minSizeY: minY });
            const newDataObj = api.serialize();
            setLastSavedState(newDataObj);
            setLayoutSwapControl(prev => !prev);
            setShowAddElementDialog(false);
            layoutChangeHandler();
        }
    }, [layoutChangeHandler]);

    const saveChangesHandler = useCallback(async () => {
        const api = layoutRef.current;
        if (isEditMode) {
            if (api && fetchedSavedState) {
                let obj = api.serialize() as ExtendedPanelModel[];
                for (let i = 0; i < obj.length; i++) {
                    const e = obj[i];
                    const id = e.id ?? '';
                    if (changedElementSettings[id]) e.config = changedElementSettings[id];
                    else if (initialElementSettings[id]) e.config = initialElementSettings[id];
                }
                loadingHandler && loadingHandler(true);
                const res = await UpdateSettingBySettingName(dashboardElements_SettingName, JSON.stringify(obj), errorHandler);
                loadingHandler && loadingHandler(false);
                if (!res) return false;
                setInitialElementSettings(prev => ({...prev, ...changedElementSettings}));
                setChangedElementSettings({});
                setInitialState(obj);
                setIsEditMode(false);
                return true;
            }
        }
        return true;
    }, [isEditMode, fetchedSavedState, loadingHandler, errorHandler, changedElementSettings, initialElementSettings]);

    const { unsavedChangesDialog } = useUnsavedChangesDialog(hasChanges || configHasChanges, saveChangesHandler, undefined, undefined, true);

    const resetLayoutHandler = useCallback(() => {
        setLastSavedState(null);
        setShowResetToDefaultConfirmDialog(false);
        setLayoutSwapControl(prev => !prev);
    }, []);

    const layoutResizeStopHandler = useCallback(() => {
        setScreenResizedControl(prev => !prev);
        layoutChangeHandler();
    }, [layoutChangeHandler]);

    useEffect(() => {
        if (screenResizedControl) {}
        const api = layoutRef.current;
        if (api && fetchedSavedState) {
            const refreshTimeout = setTimeout(() => {
                api.refresh();
                api.refresh();
            }, 250);
            return () => clearTimeout(refreshTimeout);
        }
    }, [fetchedSavedState, screenResizedControl]);

    const renderLayout = useCallback(() => {
        if (!fetchedSavedState) return <></>;

        const mediaQuery = mediaQueryMaxWidth ? `max-width: ${mediaQueryMaxWidth}` : undefined;
        const w = window.innerWidth;
        return (
            <DashboardLayoutComponent
                cellSpacing={cellSpacing}
                columns={columns}
                allowDragging={isEditMode}
                allowResizing={isEditMode}
                resizeStop={ layoutResizeStopHandler }
                change={ layoutChangeHandler }
                ref={l => layoutRef.current = l}
                mediaQuery={mediaQuery}
                cellAspectRatio={w <= 1000 ? 3 : 1}
            >
                {elements.map(e => (
                    <PanelWrapper
                        key={e.id}
                        id={e.id}
                        col={e.col}
                        row={e.row}
                        sizeX={e.sizeX}
                        sizeY={e.sizeY}
                        minSizeX={e.minSizeX}
                        minSizeY={e.minSizeY}
                        resizeControl={screenResizedControl}
                        resizeIconColor={theme.palette.text.disabled}
                        gapX={gapX}
                        gapY={gapY}
                        unitWidth={unitWidth}
                        unitHeight={unitHeight}
                        removeHandler={isEditMode ? removeElementHandler : undefined}
                    >
                        {renderElement(e.id, e.type, changedElementSettings[e.id] ?? initialElementSettings[e.id])}
                    </PanelWrapper>
                ))}
            </DashboardLayoutComponent>
        );
    }, [fetchedSavedState, isEditMode, layoutResizeStopHandler, layoutChangeHandler, elements, screenResizedControl, theme.palette.text.disabled, removeElementHandler, renderElement, changedElementSettings, initialElementSettings]);

    useEffect(() => {
        if (setActionHandler) {
            const action = (
                <>
                    {!isEditMode &&
                        <Button
                            variant="contained"
                            onClick={() => setIsEditMode(true)}
                        >Edit Dashboard</Button>
                    }
                    {isEditMode &&
                        <Button
                            color="success"
                            variant="contained"
                            disabled={!hasChanges && !configHasChanges}
                            onClick={saveChangesHandler}
                        >Save</Button>
                    }
                </>
            );
            setActionHandler(action);
        }
    }, [isEditMode, hasChanges, saveChangesHandler, setActionHandler, configHasChanges]);

    const addedElementTypes = useMemo(() => {
        const api = layoutRef.current;
        if (api && showAddElementDialog) {
            const t = api.serialize();
            return t.map(e => {
                const id = e.id;
                if (id) {
                    const i = id.indexOf('_');
                    return id.substring(0, i);
                }
                return '';
            });

        }
        return [];
    }, [showAddElementDialog]);

    return (
        <>
            {unsavedChangesDialog}
            <ConfirmationDialog
                message="Are you sure you want to reset the current layout to its default form? All changes will be lost"
                title="Confirm Action"
                onClose={ () => setShowResetToDefaultConfirmDialog(false) }
                onContinue={ resetLayoutHandler }
                open={showResetToDefaultConfirmDialog}
                fullWidth
            />
            <AddDashboardElementDialog
                open={showAddElementDialog}
                alreadyAddedElements={addedElementTypes}
                closeHandler={ () => setShowAddElementDialog(false) }
                addElementHandler={ addElementHandler }
            />
            {isEditMode && (
                <div style={{ marginBottom: '10px' }}>
                    <span>
                        <Button
                            variant="contained"
                            endIcon={<ReplayIcon />}
                            onClick={ () => setShowResetToDefaultConfirmDialog(true) }
                            sx={{ mr: '5px' }}
                        >Reset to default</Button>
                        <Button
                            variant="contained"
                            endIcon={<AddIcon />}
                            onClick={() => setShowAddElementDialog(true)}
                        >Add Element</Button>
                    </span>
                </div>
            )}
            <Box bgcolor={t => t.palette.background.default} p="10px" height="100%">
                <div className="control-section">
                    { layoutSwapControl && renderLayout() }
                    { !layoutSwapControl && renderLayout() }
                </div>
            </Box>
        </>
    );
}