import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { DatePicker } from "@mui/x-date-pickers-pro";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { RescheduleActivities } from "services/ActivitiesService";

interface Props {
    open: boolean,
    activityIds: number[],
    closeHandler: () => void,
    errorHandler?: (message: string) => void,
    successHandler?: (date: string) => void,
    loadingHandler?: (isLoading: boolean) => void
}

export default function RescheduleActivitiesDialog({ open, activityIds, closeHandler, errorHandler, successHandler, loadingHandler }: Props) {
    const [date, setDate] = useState<moment.Moment | null>(null);

    const currentDate = useMemo(() => {
        return moment();
    }, []);

    useEffect(() => {
        if (!open) setDate(currentDate);
    }, [open, currentDate]);

    const isDateValid = useMemo(() => ( date && date >= currentDate), [date, currentDate]);

    const rescheduleActivitiesCallback = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        const stringDate = date ? date.format('YYYY-MM-DD') : '';
        const res = await RescheduleActivities(stringDate, activityIds, errorHandler);
        if (res) successHandler && successHandler(stringDate);
        loadingHandler && loadingHandler(false);
    }, [loadingHandler, errorHandler, successHandler, date, activityIds]);

    return (
        <Dialog open={open}>
            <DialogTitle>Confirm Action</DialogTitle>
            <DialogContent dividers>
                <DialogContentText>
                    Are you sure you want to reschedule the selected activities ({activityIds.length}) ?
                </DialogContentText>
                <DatePicker
                    value={date}
                    label="Reschedule date"
                    onChange={m => setDate(m)}
                    disablePast
                    slotProps={{ textField: (props) => ({...props, error: !isDateValid, helperText: isDateValid ? '' : 'Reschedule date must be from today onwards', fullWidth: true, sx: { mt: '10px' }}), actionBar: { actions: ["clear", "today", "cancel", "accept"] } }}
                />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={ closeHandler }>No</Button>
                <Button variant="contained" color="success" disabled={ !isDateValid } onClick={ rescheduleActivitiesCallback }>Yes</Button>
            </DialogActions>
        </Dialog>
    );
}