export enum UserFilterTypeID {
    Company = 1,
    Division = 2,
    Group = 3,
    User = 4
}

export interface OpenJob {
    jobID: number,
    jobTitle: string,
    client: string,
    forecast: number
}

export interface ActiveInterview {
    jobID: number,
    candidateID: number,
    candidate: string,
    jobTitle: string,
    client: string,
    stage: string,
}

export interface StageTotalObj {
    stage: string,
    total: number
}

export interface BillingTrend {
    month: string,
    actual: number,
    target: number,
    stretch: number
}

export interface KpiDashboard {
    consultant: string,
    bdCalls_Target: number,
    bdCalls_Actual: number,
    bdCalls_Variance: number,
    clientVisits_Target: number,
    clientVisits_Actual: number,
    clientVisits_Variance: number,
    newJobs_Target: number,
    newJobs_Actual: number,
    newJobs_Variance: number,
    internalInterviews_Target: number,
    internalInterviews_Actual: number,
    internalInterviews_Variance: number,
    floats_Target: number,
    floats_Actual: number,
    floats_Variance: number,
    submissions_Target: number,
    submissions_Actual: number,
    submissions_Variance: number,
    firstInterview_Target: number,
    firstInterview_Actual: number,
    firstInterview_Variance: number,
    secondInterview_Target: number,
    secondInterview_Actual: number,
    secondInterview_Variance: number,
    thirdInterview_Target: number,
    thirdInterview_Actual: number,
    thirdInterview_Variance: number,
    forthInterview_Target: number,
    forthInterview_Actual: number,
    forthInterview_Variance: number,
    placements_Target: number,
    placements_Actual: number,
    placements_Variance: number,
    afterCare_Target: number,
    afterCare_Actual: number,
    afterCare_Variance: number,
}

export interface KpiOverview {
    bdCalls_Target: number,
    bdCalls_Actual: number,
    bdCalls_TargetToDate: number,
    bdCalls_Formatting: string,
    bdCalls_Explaination: string,
    clientVisits_Target: number,
    clientVisits_Actual: number,
    clientVisits_TargetToDate: number,
    clientVisits_Formatting: string,
    clientVisits_Explaination: string,
    candidateMeetings_Target: number,
    candidateMeetings_Actual: number,
    candidateMeetings_TargetToDate: number,
    candidateMeetings_Formatting: string,
    candidateMeetings_Explaination: string,
    newJobs_Target: number,
    newJobs_Actual: number,
    newJobs_TargetToDate: number,
    newJobs_Formatting: string,
    newJobs_Explaination: string,
    internalInterviews_Target: number,
    internalInterviews_Actual: number,
    internalInterviews_TargetToDate: number,
    internalInterviews_Formatting: string,
    internalInterviews_Explaination: string,
    floats_Target: number,
    floats_Actual: number,
    floats_TargetToDate: number,
    floats_Formatting: string,
    floats_Explaination: string,
    submissions_Target: number,
    submissions_Actual: number,
    submissions_TargetToDate: number,
    submissions_Formatting: string,
    submissions_Explaination: string,
    firstInterview_Target: number,
    firstInterview_Actual: number,
    firstInterview_TargetToDate: number,
    firstInterview_Formatting: string,
    firstInterview_Explaination: string,
    secondInterview_Target: number,
    secondInterview_Actual: number,
    secondInterview_TargetToDate: number,
    secondInterview_Formatting: string,
    secondInterview_Explaination: string,
    thirdInterview_Target: number,
    thirdInterview_Actual: number,
    thirdInterview_TargetToDate: number,
    thirdInterview_Formatting: string,
    thirdInterview_Explaination: string,
    forthInterview_Target: number,
    forthInterview_Actual: number,
    forthInterview_TargetToDate: number,
    forthInterview_Formatting: string,
    forthInterview_Explaination: string,
    placements_Target: number,
    placements_Actual: number,
    placements_TargetToDate: number,
    placements_Formatting: string,
    placements_Explaination: string,
    candidateAfterCare_Target: number,
    candidateAfterCare_Actual: number,
    candidateAfterCare_TargetToDate: number,
    candidateAfterCare_Formatting: string,
    candidateAfterCare_Explaination: string,
    clientAfterCare_Target: number,
    clientAfterCare_Actual: number,
    clientAfterCare_TargetToDate: number,
    clientAfterCare_Formatting: string,
    clientAfterCare_Explaination: string
}

export interface TopClientsByJobRecord {
    client: string,
    exclusive: number,
    competitive: number,
    total: number,
    percentExclusive: number
}

export interface TopClientsByFeesRecord {
    client: string,
    perm: number,
    margin: number,
    total: number,
    percentContract: number
}

export interface TopClientsByActiviyRecord {
    client: string,
    meetings: number,
    submissions: number,
    interviews: number,
    offers: number,
    placements: number
}

export interface TopClientsByRatiosRecord {
    client: string,
    submissions: number,
    firstInterviews: number,
    secondInterviews: number,
    offers: number,
    placements: number,
    submissionsToFirstInterviewRatio: number,
    firstInterviewToSecondInterviewRatio: number,
    firstInterviewToOfferRatio: number,
    secondInterviewToOfferRatio: number,
    submissionsToPlacementsRatio: number,
    firstInterviewToPlacementRatio: number,
    secondInterviewToPlacementRatio: number,
    offersToPlacementsRatio: number,
}

export interface TopContactsByJobRecord {
    contact: string,
    exclusive: number,
    competitive: number,
    total: number,
    percentExclusive: number
}

export interface TopContactsByFeesRecord {
    contact: string,
    perm: number,
    margin: number,
    total: number,
    percentContract: number
}

export interface TopContactsByActiviyRecord {
    contact: string,
    meetings: number,
    submissions: number,
    interviews: number,
    offers: number,
    placements: number
}

export interface TopContactsByRatiosRecord {
    contact: string,
    submissions: number,
    firstInterviews: number,
    secondInterviews: number,
    offers: number,
    placements: number,
    submissionsToFirstInterviewRatio: number,
    firstInterviewToSecondInterviewRatio: number,
    firstInterviewToOfferRatio: number,
    secondInterviewToOfferRatio: number,
    submissionsToPlacementsRatio: number,
    firstInterviewToPlacementRatio: number,
    secondInterviewToPlacementRatio: number,
    offersToPlacementsRatio: number,
}

export interface TopPerformersRecord {
    consultant: string,
    total: number
}

export interface JobsByClientRecord {
    client: string,
    jobs: number
}

export interface KeyActivityRecord {
    activity: string,
    total: number
}

export interface OpportunitiesByTypeRecord {
    type: string,
    open: number,
    closed: number,
    total: number
}

export interface OpportunitiesByStageRecord {
    stage: string,
    open: number,
    closed: number,
    total: number
}

export interface JobsByConsultantRecord {
    consultant: string,
    exclusive: number,
    competitive: number,
    total: number,
    percentExclusive: number
}

export interface JobsBySourceRecord {
    source: string,
    exclusive: number,
    competitive: number,
    total: number,
    percentExclusive: number
}

export interface JobsByStageRecord {
    stage: string,
    exclusive: number,
    competitive: number,
    total: number,
    percentExclusive: number
}

export interface ApplicantsBySourceRecord {
    source: string,
    perm: number,
    contract: number,
    ftc: number,
    talentPool: number,
    total: number
}

export interface AveragePermFeesRecord {
    month: string,
    placements: number,
    avgPermFee: number
}

export interface AverageContractMarginRecord {
    month: string,
    placements: number,
    avgContractMargin: number
}

export interface PlacementsAverageByConsultantRecord {
    consultant: string,
    contract: number,
    perm: number,
    total: number,
}

export interface PlacementsContractFinancialsOverviewRecord {
    period: string,
    thisPeriod: number,
    lastPeriod: number,
    difference: number,
}

export interface PlacementsContractFinancialsTrendRecord {
    month: string,
    billings: number,
    margin: number,
    percentage: number,
}

export interface PlacementQtyBySourceRecord {
    source: string,
    perm: number,
    contractors: number,
    total: number,
    percent_Total: number,
}

export interface PlacementFeesBySourceRecord {
    source: string,
    perm: number,
    margin: number,
    total: number,
    percent_Total: number,
}

export interface TypeActualObj {
    type: string,
    actual: number
}

export interface PerformanceActivityRatioRecord {
    jobs: number,
    submissions: number,
    firstInterviews: number,
    secondInterviews: number,
    offers: number,
    placements: number,
    jobsToPlacementsRatio: number,
    submissionsToFirstInterviewRatio: number,
    firstInterviewToSecondInterviewRatio: number,
    firstInterviewToOfferRatio: number,
    submissionsToPlacementsRatio: number,
    firstInterviewToPlacementRatio: number,
    offersToPlacementsRatio: number,
}

export interface PerformanceActivityRatioTrendsRecord {
    month: string,
    jobs: number,
    submissions: number,
    firstInterviews: number,
    secondInterviews: number,
    offers: number,
    placements: number,
    submissionsToFirstInterviewRatio: number,
    firstInterviewToSecondInterviewRatio: number,
    firstInterviewToOfferRatio: number,
    secondInterviewToOfferRatio: number,
    submissionsToPlacementsRatio: number,
    firstInterviewToPlacementRatio: number,
    secondInterviewToPlacementRatio: number,
    offersToPlacementsRatio: number,
    jobsToPlacementsRatio: number,
}

export interface PerformanceFeeByConsultantRecord {
    consultant: string,
    contract: number,
    perm: number,
    total: number,
}

export interface PerformancePhoneTimeRecord {
    user: string,
    total: number,
    inbound: number,
    outbound: number,
    averageCallTime: string,
    totalCallTime: string
}

export interface ActivitiesGraphData {
    bdCalls: number,
    clientVisits: number,
    floats: number,
    jobs: number,
    exclusives: number,
    subs: number,
    interviews: number,
    offers: number,
    placements: number,
}

export interface JobPipelineGraphData {
    resourcing: number,
    shortlist: number,
    submitted: number,
    interview: number,
    offered: number,
    placed: number,
}

export interface PlacementFeesGraphData {
    permanent: number,
    contract: number,
    fixedTerm: number,
    all: number,
    allTarget: number,
}