import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import React, { useCallback, useMemo } from "react";
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, TooltipProps, XAxis, YAxis } from "recharts";
import { NameType, ValueType } from "recharts/types/component/DefaultTooltipContent";
import { BillingTrend } from "common/models/Analytics/BusinessIntelligence";
import CircularProgress from "@mui/material/CircularProgress";

interface Props {
    title: string,
    data: BillingTrend[] | null,
    stretchLabel?: string,
    targetLabel?: string
}

const customTooltipCircleStyle: React.CSSProperties = { width: '10px', height: '10px', marginRight: '10px', borderRadius: '50%', alignSelf: 'center' };

const getBarName = (dataKey: string | undefined, stretchOverride?: string, targetOverride?: string) => {
    switch (dataKey) {
        case 'actual': return 'Billings';
        case 'target': return targetOverride ? targetOverride : 'Target';
        case 'stretch': return stretchOverride ? stretchOverride : 'Stretch Target';
        default: return '';
    }
};

const formatMonthText = (month: string) => {
    const [m, y] = month.split(' ');
    return `${m.substring(0, 3)} ${y.substring(2, 4)}`;
};

export default function BarGraphBillingsTrend({ title, data, stretchLabel, targetLabel }: Props) {
    const t = useTheme();

    const reversedData = useMemo(() => {
        if (data) return data.reverse();
        return data;
    }, [data]);

    const CustomTooltip = useCallback(({ active, payload, label }: TooltipProps<ValueType, NameType>) => {
        if (active && payload && payload.length) {
            return (
                <Box
                    bgcolor={t => t.palette.background.paper}
                    border={t => `solid 1px ${t.palette.text.disabled}`}
                    p={1}
                >
                    <div style={{ textAlign: 'center', fontWeight: 600 }}>{label}</div>
                    {payload.map(p => (
                        <div key={p.dataKey} style={{ display: 'flex', marginTop: '5px' }}>
                            <div style={{ ...customTooltipCircleStyle, backgroundColor: p.color }} />
                            <div style={{ textAlign: 'right' }}>{getBarName(p.dataKey?.toString(), stretchLabel, targetLabel)}: {p.value?.toLocaleString()}</div>
                        </div>
                    ))}
                </Box>
            );
        }
    
        return null;
    }, [stretchLabel, targetLabel]);

    return (
        <Paper sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h6" padding={1} textAlign="center" fontWeight={600} component="div">{title}</Typography>
            {data === null && <Box m="auto"><CircularProgress /></Box> }
            <div style={{ height: 'calc(100% - 48px)', display: data === null ? 'none' : undefined }}>
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart width={150} height={150} data={reversedData ?? []}>
                        <CartesianGrid strokeDasharray="3 3" vertical={false} />
                        <XAxis dataKey="month" tickFormatter={reversedData ? formatMonthText : undefined} stroke={t.palette.text.primary} />
                        <YAxis type="number" tickFormatter={v => v.toLocaleString()} stroke={t.palette.text.primary} />
                        <Tooltip content={<CustomTooltip />} />
                        <Bar dataKey="actual" fill={t.palette.info.main} />
                        <Bar dataKey="target" fill={t.palette.success.main} />
                        <Bar dataKey="stretch" fill={t.palette.error.main} />
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </Paper>
    );
}