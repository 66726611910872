import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";

import Alert from "components/Alert";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Typography  from "@mui/material/Typography";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import GoogleIcon from '@mui/icons-material/Google';

import LoginForm from '../../components/LoginForms/LoginForm';
import ForgotPasswordForm from '../../components/LoginForms/ForgotPasswordForm';
import ChangePasswordForm from '../../components/LoginForms/ChangePasswordForm';
import { SimpleAlert } from '../../common/models/GenericTypes';
import welcomeImg from '../../assets/img/welcome-back.svg';
import './Login.css';
import { Link } from "react-router-dom";
import CustomApplicationContext from "util/CustomApplicationContext";

const WinLogo = () => (
    <svg aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"  style={{ width: '1em', height: '1em' }}>
        <path fill="currentColor" d="M0 32h214.6v214.6H0V32zm233.4 0H448v214.6H233.4V32zM0 265.4h214.6V480H0V265.4zm233.4 0H448V480H233.4V265.4z" />
    </svg>
);

const forgotPasswordMessage = "Enter the email address you usually sign in with. If the account exists, we'll email you a reset link that you can use to reset your password.";

export default function Login(): JSX.Element {
    let initialForm: 1|2|3 = 1;
    const [searchParams] = useSearchParams();
    const token = searchParams.get('t');

    if(Boolean(token)) initialForm = 3;

    const [formType, setFormType] = useState<1|2|3>(initialForm);
    const [showLoading, setShowLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState<SimpleAlert | null>(null);
    const [infoAlert, setInfoAlert] = useState<SimpleAlert | null>(null);

    const handleError = (message: string) => {
        setErrorAlert({ show: true, message })
    }

    let displayForm: JSX.Element | null = null;
    switch (formType) {
        case 3:
            displayForm = (
                <ChangePasswordForm
                    setShowLoading={setShowLoading}
                    handleError={handleError}
                    setInfoAlert={setInfoAlert}
                    token={token}
                />
            );
            break;
        case 2:
            displayForm = (
                <ForgotPasswordForm
                    setShowLoading={setShowLoading}
                    handleError={handleError}
                    setInfoAlert={setInfoAlert}
                />
            );
            break;
        case 1:
        default:
            displayForm = (
                <CustomApplicationContext.Consumer>
                    {c => (
                        <LoginForm setShowLoading={setShowLoading} handleError={handleError} refreshCurrentUserHandler={c.refreshCurrentUser} />
                    )}
                </CustomApplicationContext.Consumer>
            );
    }

    return (
        <>
            <Backdrop open={showLoading} sx={{ zIndex: (t) => t.zIndex.drawer + 1 }} >
                <CircularProgress sx={{ color: 'text.disabled' }} />
            </Backdrop>
            <Box display="flex" flex="1 0 0">
                <Box bgcolor="secondary.main" flex="1 0 0">
                    <Box display="flex" height="100%" justifyContent="center" alignItems="center">
                        <Box bgcolor="white" width="550px" borderRadius="8%" boxShadow={t => `${t.palette.primary.main} -15px 15px`} py="50px">
                            <Stack alignItems='center' spacing={4} marginTop='3rem' maxWidth='700px' margin='auto' >
                                <img src={welcomeImg} alt="welcome" />
                                { formType === 2 &&
                                    <Typography textAlign='center' color='text.secondary'>{forgotPasswordMessage}</Typography>
                                }
                                <Paper
                                    elevation={0}
                                    sx={{
                                        bgcolor: (t) => t.customVars.bgcLoginForm,
                                        width: '400px',
                                        borderRadius: '10px',
                                        padding: '48px',
                                        textAlign: 'center'
                                    }}
                                >
                                    {displayForm}
                                </Paper>

                                { formType === 1 &&
                                    <>
                                        <Typography color="primary" variant="h3" fontWeight="bold" textAlign="center"></Typography>
                                        <Stack spacing={1} width="400px" px={2}>
                                            <Link to="/external/microsoft" style={{ color: 'inherit', textDecoration: 'none' }}>
                                                <Button
                                                    variant="contained"
                                                    startIcon={ <WinLogo /> }
                                                    sx={{ textTransform: 'none', width: '100%', height: '42px' }}
                                                >
                                                    Microsoft
                                                </Button>
                                            </Link>
                                            <Link to="/external/google" style={{ color: 'inherit', textDecoration: 'none' }}>
                                                <Button
                                                    variant="contained"
                                                    startIcon={<GoogleIcon />}
                                                    sx={{ textTransform: 'none', width: '100%', height: '42px' }}
                                                >
                                                    Google
                                                </Button>
                                            </Link>
                                        </Stack>
                                    </>
                                }

                                {(errorAlert && errorAlert.show) &&
                                    <Alert severity="error" onClose={() => setErrorAlert(null)}>{ errorAlert.message }</Alert>
                                }
                                {(infoAlert && infoAlert.show) &&
                                    <Alert severity="info" onClose={() => setInfoAlert(null)}>{ infoAlert.message }</Alert>
                                }
                                { formType === 1 &&
                                    <Button variant='text' sx={{ color: t => t.palette.mode === 'dark' ? 'white' : t.palette.secondary.main }} onClick={() => setFormType(2)}>
                                        Forgot password
                                    </Button>
                                }
                                { formType === 2 &&
                                    <Button variant='text' sx={{ color: t => t.palette.mode === 'dark' ? 'white' : t.palette.secondary.main }} onClick={() => setFormType(1)}>
                                        Back
                                    </Button>
                                }
                                { formType === 3 &&
                                    <Button variant='text' sx={{ color: t => t.palette.mode === 'dark' ? 'white' : t.palette.secondary.main }} onClick={() => setFormType(1)}>
                                        Back to login
                                    </Button>
                                }
                            </Stack>
                        </Box>
                    </Box>
                </Box>
                {/*<Box bgcolor="white" flex="1 0 0" display="flex">*/}
                {/*    <img src={bigLoginImg} alt="bigLoginImage" />*/}
                {/*</Box>*/}
            </Box>
        </>
    );
}