import { CustomField, CustomFieldPredefinedValue, CustomFieldPredefinedValueWithFieldId } from "../common/models/Configuration/CustomFields";
import { ErrorHandler, RequestOptions } from "../common/models/GenericTypes";
import { Get, Post, Put } from "../util/HttpUtils";

export async function GetCustomFieldsByEntity(entityId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<CustomField[]>(`api/CustomFields/${entityId}`, options);
}

export async function GetCustomFieldsByEntity_OnlyActive(entityId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<CustomField[]>(`api/CustomFields/${entityId}?onlyActive=true`, options);
}

export async function UpdateCustomfields(entityId: number, fields: CustomField[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/CustomFields/${entityId}`, options, fields);
}

export async function GetPredefinedValues(fieldId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<CustomFieldPredefinedValue[]>(`api/CustomFields/PredefinedValues/${fieldId}`, options);
}

export async function GetMultiplePredefinedValues(fieldIds: number[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    const query = fieldIds.join('&fieldIds=');
    return Get<CustomFieldPredefinedValueWithFieldId[]>(`api/CustomFields/PredefinedValues?fieldIds=${query}`, options);
}

export async function SavePredefinedValues(fieldId: number, values: CustomFieldPredefinedValue[], deletedIds: number[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>(`api/CustomFields/PredefinedValues/${fieldId}`, options, { values, deletedIds });
}