import Alert from "components/Alert";
import Snackbar from "@mui/material/Snackbar";
import React, { useEffect, useState } from "react";
import OnlyTitleSummaryBar from "components/SummaryBars/OnlyTitleSummaryBar";
import PageContentLayout from "layouts/PageContentLayout";
import ScreenLayoutAdmin from "components/ScreenLayouts/Placements/ScreenLayoutAdmin";

interface Props {
    type: 'record' | 'edit',
    placementType: 'Permanent' | 'Contract' | 'FixedTermContract' | 'MaxTermContract' | 'MarginOnly',
    setSummaryBar?: (summaryBar: JSX.Element) => void
}

export default function PlacementsScreenLayoutPage({ type, placementType, setSummaryBar }: Props) {
    const [isLoading, setIsloading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [placementTypeSwitch, setPlacementTypeSwitch] = useState(false);

    useEffect(() => {
        if (placementType) {}
        setPlacementTypeSwitch(prev => !prev);
    }, [placementType]);

    useEffect(() => {
        if (setSummaryBar) {
            const typeTitle = type === 'record' ? 'View' : 'Create / Edit';
            let jt: string = placementType;
            if (placementType === 'FixedTermContract') jt = 'Fixed Term';
            else if (placementType === 'MaxTermContract') jt = 'Maximum Term';
            else if (placementType === 'MarginOnly') jt = 'Margin Only';
            const summaryBar = <OnlyTitleSummaryBar title={`Configuration > Screen Layouts > ${jt} Placements - ${typeTitle}`} browserTabTitle="Screen Layouts > Configuration" />;
            setSummaryBar(summaryBar);
        }
    }, [setSummaryBar, type, placementType]);
    
    return (
        <>
            <Snackbar open={errorMessage !== ''} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <PageContentLayout showLoading={isLoading}>
                { type === 'record' && placementTypeSwitch && <ScreenLayoutAdmin placementType={placementType} layoutType="Record" errorHandler={setErrorMessage} loadingHandler={setIsloading} /> }
                { type === 'edit' && placementTypeSwitch && <ScreenLayoutAdmin placementType={placementType} layoutType="Edit" errorHandler={setErrorMessage} loadingHandler={setIsloading} /> }
                { type === 'record' && !placementTypeSwitch && <ScreenLayoutAdmin placementType={placementType} layoutType="Record" errorHandler={setErrorMessage} loadingHandler={setIsloading} /> }
                { type === 'edit' && !placementTypeSwitch && <ScreenLayoutAdmin placementType={placementType} layoutType="Edit" errorHandler={setErrorMessage} loadingHandler={setIsloading} /> }
            </PageContentLayout>
        </>
    );
}