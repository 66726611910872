import React from "react";
import logo from "./../assets/img/logo-light.png";
import "./WizCircularLogo.css";

export default class WizCircularLogo extends React.Component<any,any> {
    render(): React.ReactNode {
        return (
            <span className="wiz-circular-logo-container">
                <img src={logo} alt="Recruit Wizard Logo" />
            </span>
        );
    }
}