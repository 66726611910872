import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import SettingsIcon from '@mui/icons-material/Settings';
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import useActiveUsers from "hooks/UseActiveUsers";
import { FilterWithTimeRange } from "common/models/Analytics/AnalyticsFilterData";
import { GetPerformanceActivityRatiosData } from "services/BusinessIntelligence";
import { PerformanceActivityRatioRecord } from "common/models/Analytics/BusinessIntelligence";
import moment from "moment";
import useActiveGroups from "hooks/UseActiveGroups";
import RWTextFieldComponent from "components/RWTextFieldComponent";

interface Props {
    id: string,
    title: string,
    sourceType: string,
    sourceId: number,
    timeRange: number,
    type: string,
    showConfigButton?: boolean,
    saveConfigHandler?: (id: string, title: string, newTimeRange: number, newRatioType: string, newSourceType: string, newSourceId: number) => void
}

const getDatesFromTimeRange = (tr: number) => {
    let start: moment.Moment | null = null;
    let end: moment.Moment | null = null;
    if (tr === 0) return;
    if (tr === 1) { // This Week
        start = moment().startOf('isoWeek');
        end = moment().endOf('isoWeek');
    }
    else if (tr === 2) { // This Month
        start = moment().startOf('month');
        end = moment().endOf('month');
    }
    else if (tr === 3) { // This Quarter
        start = moment().startOf('quarter');
        end = moment().endOf('quarter');
    }
    else if (tr === 4) { // This Year
        start = moment().startOf('year');
        end = moment().endOf('year');
    }
    else if (tr === 5) { // Last Week
        start = moment().subtract(1,'week').startOf('isoWeek');
        end = moment().subtract(1,'week').endOf('isoWeek');
    }
    else if (tr === 6) { // Last Month
        start = moment().subtract(1, 'months').startOf('month');
        end = moment().subtract(1, 'months').endOf('month');
    }
    else if (tr === 7) { // Last Quarter
        start = moment().subtract(1, 'quarter').startOf('quarter');
        end = moment().subtract(1, 'quarter').endOf('quarter');
    }
    else if (tr === 8) { // Last Year
        const lastYear = moment().get('year') - 1;
        start = moment(`${lastYear}-01-01`);
        end = moment(`${lastYear}-12-31`);
    }
    else if (tr === 9) { // Last 7 Days
        start = moment().subtract(6, 'days');
        end = moment();
    }
    else if (tr === 10) { // Last 30 Days
        start = moment().subtract(30, 'days');
        end = moment();
    }
    else if (tr === 11) { // Last 90 Days
        start = moment().subtract(90, 'days');
        end = moment();
    }
    else if (tr === 12) { // Last 365 Days
        start = moment().subtract(365, 'days');
        end = moment();
    }
    return [start, end];
};

export default function RatioGraphComponent({ id, title: titleProp, sourceType: sourceTypeProp, sourceId: sourceIdProp, timeRange: timeRangeProp, type: typeProp, showConfigButton, saveConfigHandler }: Props) {
    const [title, setTitle] = useState('');
    const [sourceType, setSourceType] = useState('consultant');
    const [sourceId, setSourceId] = useState(-1);
    const [timeRange, setTimeRange] = useState(1);
    const [type, setType] = useState('submissionsToFirstInterviewRatio');
    const [showConfigDialog, setShowConfigDialog] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [ratioData, setRatioData] = useState<PerformanceActivityRatioRecord | null>(null);
    const users = useActiveUsers();
    const groups = useActiveGroups();

    const value = useMemo(() => ratioData ? (ratioData as any)[typeProp] + ' %' : '', [ratioData, typeProp]);

    useEffect(() => {
        if (showConfigDialog) {
            setTitle(titleProp);
            setTimeRange(timeRangeProp);
            setType(typeProp);
            setSourceType(sourceTypeProp);
            setSourceId(sourceIdProp);
        }
    }, [showConfigDialog, timeRangeProp, typeProp, sourceIdProp, sourceTypeProp, titleProp]);

    useEffect(() => {
        const getRatioData = async () => {
            setIsLoading(true);
            let range = getDatesFromTimeRange(timeRangeProp);
            if (range) {
                const [start, end] = range;
                if (start && end) {
                    // 1 - Company
                    // 2 - Division
                    // 3 - Group
                    // 4 - User
                    let userFilterTypeId = 4;
                    if (sourceTypeProp === 'group') userFilterTypeId = 3;
                    else if (sourceIdProp === -3) userFilterTypeId = 1
                    const f: FilterWithTimeRange = {
                        endDate: end.format('YYYY-MM-DD'),
                        startDate: start.format('YYYY-MM-DD'),
                        userFilterID: sourceIdProp.toString(),
                        userFilterTypeID: userFilterTypeId
                    };

                    const res = await GetPerformanceActivityRatiosData(f);
                    if (res && res[0]) setRatioData(res[0]);
                }
            }
            setIsLoading(false);
        };
        getRatioData();
    }, [sourceIdProp, timeRangeProp, sourceTypeProp]);

    const onSaveConfig = useCallback(() => {
        saveConfigHandler && saveConfigHandler(id, title, timeRange, type, sourceType, sourceId);
        setShowConfigDialog(false);
    }, [saveConfigHandler, id, title, timeRange, type, sourceType, sourceId]);

    const sourceTypeChangeHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setSourceId(0);
        setSourceType(value);
    }, []);
    
    return (
        <>
            <Dialog open={showConfigDialog} fullWidth>
                <DialogTitle>Configure Element</DialogTitle>
                <DialogContent>
                    <Stack spacing={2} mt="10px">
                        <RWTextFieldComponent label="Title" value={title} onChange={(e) => setTitle(e.target.value)} />
                        <TextField select label="Source Type" value={sourceType} onChange={sourceTypeChangeHandler} fullWidth>
                            <MenuItem value="consultant">Consultant</MenuItem>
                            <MenuItem value="group">Group</MenuItem>
                        </TextField>
                        {sourceType === 'consultant' &&
                            <TextField select label="Consultant" value={sourceId.toString()} onChange={({target}) => setSourceId(+target.value)} fullWidth>
                                <MenuItem value="0"></MenuItem>
                                <MenuItem value="-1">Me</MenuItem>
                                <MenuItem value="-3">My Company</MenuItem>
                                {users.map(u => <MenuItem key={u.id} value={u.id}>{u.name}</MenuItem>)}
                            </TextField>
                        }
                        {sourceType === 'group' &&
                            <TextField select label="Group" value={sourceId.toString()} onChange={({target}) => setSourceId(+target.value)} fullWidth>
                                <MenuItem value="0"></MenuItem>
                                {groups.map(g => <MenuItem key={g.id} value={g.id}>{g.name}</MenuItem>)}
                            </TextField>
                        }
                        <TextField select label="Time Range" value={timeRange.toString()} onChange={({target}) => setTimeRange(+target.value)} fullWidth>
                            <MenuItem value="1">This Week</MenuItem>
                            <MenuItem value="2">This Month</MenuItem>
                        </TextField>
                        <TextField select label="Type" value={type} onChange={({target}) => setType(target.value)} fullWidth>
                            <MenuItem value="submissionsToFirstInterviewRatio">Submissions To 1st Interview</MenuItem>
                            <MenuItem value="submissionsToPlacementsRatio">Submissions To Placement</MenuItem>
                            <MenuItem value="firstInterviewToPlacementRatio">1st Interviews To Placement</MenuItem>
                            <MenuItem value="offersToPlacementsRatio">Offers To Placements</MenuItem>
                            <MenuItem value="jobsToPlacementsRatio">Jobs To Placements</MenuItem>
                        </TextField>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="error" onClick={() => setShowConfigDialog(false)}>Cancel</Button>
                    <Button variant="contained" color="success" onClick={onSaveConfig}>Save</Button>
                </DialogActions>
            </Dialog>
            {showConfigButton &&
                <IconButton
                    sx={{ position: 'absolute', right: 30, zIndex: t => t.zIndex.appBar - 5, color: t => t.palette.text.disabled }}
                    onClick={() => setShowConfigDialog(true)}
                >
                    <SettingsIcon />
                </IconButton>
            }
            <Paper sx={{ height: '100%', p: '8px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <Box display="flex" flexDirection="column" height="100%">
                    <Typography component="div" textAlign="center" variant="h6">
                        {titleProp}
                    </Typography>
                    {isLoading && <Box m="auto"><CircularProgress /></Box> }
                    <Typography component="div" textAlign="center" variant="h4" flexGrow={1} display={isLoading ? "none" : "flex"} alignItems="center" justifyContent="center">
                        {value}
                    </Typography>
                </Box>
            </Paper>
        </>
    );
}