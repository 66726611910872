import { Invoice } from "../common/models/Invoices/Invoice";
import { CreateInvoice } from "../common/models/Invoices/CreateInvoice";
import { ErrorHandler, NumberValueWrapper, RequestOptions } from "../common/models/GenericTypes";
import { BillingTarget, Placement, PlacementRateChangeRequest } from "../common/models/Placements";
import {ActivityMessage} from "../common/models/Activities";
import { ChangeRequest } from "../common/models/ChangeRequest";
import { PlacementRate } from "../common/models/PlacementRate";
import { Report } from "../common/models/Reports/Report";
import { PlacementSearch } from "../common/models/Search/PlacementSearch";
import { Get, Post, Put, Delete } from "../util/HttpUtils";
import { PartnerAction } from "common/models/PartnerActions";
import {ImportableRateChange} from "../common/models/Imports/Placements/BulkRateChangeImport";
import {ImportableExtension} from "../common/models/Imports/Placements/BulkExtensionImport";
import {Meeting} from "../common/models/Meetings";

export async function PlacementLookup(term: string, limit: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return await Get<Placement[]>(`api/placements/Lookup?query=${encodeURIComponent(term)}&limit=${limit}`, options);
}

export async function SearchPlacements(data: PlacementSearch, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<Placement[]>('api/placements/search', options, data);
}

export async function GetPlacementById(placementId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Placement>(`api/placements/${placementId}`, options);
}

export async function GetLatestPlacementById(placementId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Placement>(`api/placements/${placementId}/latest`, options);
}

export async function GetUserTargetsByYear(userId: number, year: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<BillingTarget[]>(`api/placements/users/${userId}/targets/${year}`, options);
}

export async function UpdateUserTargets(userId: number, year: number, targets: BillingTarget[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/placements/users/${userId}/targets/${year}`, options, targets);
}

export async function GetMyContractorsPlacements(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Placement[]>('api/Placements/Contractors/Me', options);
}

export async function GetAllContractorsPlacements(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Placement[]>('api/Placements/Contractors/All', options);
}

export async function GetMyExpiringContractorsPlacements(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Placement[]>('api/Placements/ExpiringContractors/Me', options);
}

export async function GetAllExpiringContractorsPlacements(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Placement[]>('api/Placements/ExpiringContractors/All', options);
}

export async function GetMyRecentlyExpiredContractorsPlacements(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Placement[]>('api/Placements/RecentlyExpiredContractors/Me', options);
}

export async function GetAllRecentlyExpiredContractorsPlacements(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Placement[]>('api/Placements/RecentlyExpiredContractors/All', options);
}

export async function ApprovePlacementById(placementId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/Placements/${placementId}/Approve`, options);
}

export async function BulkApprovePlacements(placementIds: number[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>('api/Placements/Approve/Bulk', options, placementIds);
}

export async function RevokePlacementApproval(placementId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Delete<boolean>(`api/Placements/${placementId}/UnApprove`, options);
}

export async function GetReports(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Report[]>('api/placements/reports', options);
}

export async function GetPlacementsByTags(tagTypeId: number, taggedBy: number, tag: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };
    return Get<Placement[]>(`api/placements/tags/${tagTypeId}?taggedBy=${taggedBy}&tag=${encodeURIComponent(tag)}`, options);
}

export async function GetConsultantCommissionReports(year: number, month: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };
    return Get<Placement[]>(`api/placements/reports/consultant-commission?year=${year}&month=${month}`, options);
}

export async function GetCancelledPlacementsReportData(owner: number, start_date: string, end_date: string, type: number, deleted: number, using: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<any>(`api/placements/reports/cancelled?owner=${owner}&startDate=${start_date}&endDate=${end_date}&type=${type}&includeDeleted=${deleted}&reportType=${using}`, options);
}

export async function GetExpiredContractorsReportData(owner: number, start_date: string, end_date: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<any>(`api/placements/reports/expired?owner=${owner}&startDate=${start_date}&endDate=${end_date}`, options);
}


export async function Get_IvoryGroup_MarginReportData(start_date: string, end_date: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<any>(`api/placements/reports/custom-ivory-margin-report?startDate=${start_date}&endDate=${end_date}`, options);
}

export async function GetNewPlacementsReportData(owner: number, start_date: string, end_date: string, type: number, cancelled: number, using: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<any>(`api/placements/reports/new?owner=${owner}&startDate=${start_date}&endDate=${end_date}&type=${type}&includeCancelled=${cancelled}&reportType=${using}`, options);
}

export async function GetCurrentContractorsReportData(owner: number, as_at_date: string, start_date: string, end_date: string, using: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<any>(`api/placements/reports/current-contractors?owner=${owner}&asAtDate=${as_at_date}&startDate=${start_date}&endDate=${end_date}&reportType=${using}`, options);
}

export async function TscGetCurrentContractorsReportData(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<any>(`api/placements/reports/custom-tsc-current-contractors-mondaydotcom`, options);
}

export async function GetCurrentContractorFinancialsReportData(owner: number, as_at_date: string, start_date: string, end_date: string, using: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<any>(`api/placements/reports/current-contractor-financials?owner=${owner}&asAtDate=${as_at_date}&startDate=${start_date}&endDate=${end_date}&reportType=${using}`, options);
}

export async function GetPlacementsDetailedReportData(owner: number, start_date: string, end_date: string, type: number, using: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<any>(`api/placements/reports/detailed?owner=${owner}&startDate=${start_date}&endDate=${end_date}&type=${type}&reportType=${using}`, options);
}

export async function GetEarlyTerminatedPlacementsReportData(owner: number, start_date: string, end_date: string, type: number, using: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<any>(`api/placements/reports/early-terminated?owner=${owner}&startDate=${start_date}&endDate=${end_date}&type=${type}&reportType=${using}`, options);
}

export async function GetNoReferencePlacementsReportData(start_date: string, end_date: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<any>(`api/placements/reports/no-reference-placements?&startDate=${start_date}&endDate=${end_date}`, options);
}

export async function GetTimesheetDataDumpData(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<any>(`api/placements/reports/timesheet-data`, options);
}

export async function GetBulkChangeRequestsExtensions(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<any>(`api/placements/reports/bulk-change-requests/extensions`, options);
}

export async function GetBulkChangeRequestsRateChanges(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<any>(`api/placements/reports/bulk-change-requests/rate-changes`, options);
}

export async function GetEmployeeVenureReports(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };
    return Get<Placement[]>(`api/placements/reports/employee-tenure`, options);
}

export async function GetContractorVenureReports(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };
    return Get<Placement[]>(`api/placements/reports/contractor-tenure`, options);
}

export async function GetCommissionReports(owner: number, year: number, month: number, lastRunDate: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };
    return Get<Placement[]>(`api/placements/reports/commission?owner=${owner}&year=${year}&month=${month}&lastRunDate=${encodeURIComponent(lastRunDate)}`, options);
}

export async function GetNTT_PlacementCompliance(start_date: string, end_date: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<any>(`api/placements/reports/custom-ntt-placement-compliance?startDate=${start_date}&endDate=${end_date}`, options);
}

export async function GetCG_PlacementSourceAnalysis(start_date: string, end_date: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<any>(`api/placements/reports/custom-cg-placement-sourceanalysis?startDate=${start_date}&endDate=${end_date}`, options);
}


export async function Get_TRC_ContractorMix(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<any>(`api/placements/reports/custom-trc-contractormix`, options);
}

export async function Get_TRC_SalesMonthly(type: number, year: number, month: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<any>(`api/placements/reports/custom-trc-sales-monthly?type=${type}&year=${year}&month=${month}`, options);
}

export async function Get_TRC_SalesAnnually(type: number, year: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<any>(`api/placements/reports/custom-trc-sales-annual?type=${type}&year=${year}`, options);
}

export async function DeletePlacementById(placementId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Delete<boolean>(`api/placements/${placementId}`, options);
}

export async function GetMyPlacements(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Placement[]>(`api/placements/Me`, options);
}

export async function GetRelatedPlacements(placementId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Placement[]>(`api/placements/${placementId}/relatedplacements`, options);
}

export async function GetPlacementChangeRequests(placementId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<ChangeRequest[]>(`api/placements/${placementId}/changerequests`, options);
}

export async function GetPlacementFinancials(placementId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<PlacementRate[]>(`api/placements/${placementId}/financials`, options);
}

export async function GetAwaitingApproval(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Placement[]>(`api/placements/awaiting-approval`, options);
}

export async function GetMyAwaitingApproval(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Placement[]>(`api/placements/my-awaiting-approval`, options);
}

export async function CancelPlacement(placementId: number, reasonId: number, comments: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/placements/${placementId}/cancel`, options, { reasonId: reasonId, comments: comments });
}

export async function ChangePlacementApprovers(placementId: number, approver1: number, approver2: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/placements/${placementId}/approvers`, options, { approver1: approver1, approver2: approver2 });
}

export async function ChangePlacementContacts(placementId: number, contact: number, hiringMgr: number, billing1: number, billing2: number, billing3: number, signatory: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    const data = {
        contact: contact,
        hiringMgr: hiringMgr,
        billing1: billing1,
        billing2: billing2,
        billing3: billing3,
        signatory: signatory
    };

    return Put<boolean>(`api/placements/${placementId}/contacts`, options, data);
}

export async function ChangePlacementNetRevenue(placementId: number, netRevenue: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/placements/${placementId}/net-revenue`, options, { value: netRevenue });
}

export async function ChangePlacementJobTitle(placementId: number, jobTitle: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/placements/${placementId}/JobTitle`, options, { value: jobTitle });
}

export async function ChangePlacementPayFrequency(placementId: number, payFrequency: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/placements/${placementId}/pay-frequency/${payFrequency}`, options);
}

export async function ChangePlacementClientRef(placementId: number, clientRef: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/placements/${placementId}/client-ref`, options, { value: clientRef });
}

export async function ExportPlacementToAstutePayroll(placementId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>(`api/placements/${placementId}/export/astute`, options);
}

export async function RefreshPlacementToAstutePayroll(placementId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>(`api/placements/${placementId}/refresh/astute`, options);
}

export async function CreatePlacement(placementData: Partial<Placement>, shouldCloseJob: boolean, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<NumberValueWrapper>(`api/placements?shouldCloseJob=${shouldCloseJob}`, options, placementData);
}

export async function UpdatePlacement(placementId: number, placementData: Partial<Placement>, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/placements/${placementId}`, options, placementData);
}

export async function GetLatestPlacementRate(placementId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<PlacementRateChangeRequest>(`api/placements/${placementId}/rates/latest`, options);
}

export async function TerminatePlacement(placementId: number, reasonId: number, endDate: string, comments: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/placements/${placementId}/terminate`, options, { reasonId, endDate, comments });
}

export async function GetPlacementMessages(placementId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<ActivityMessage[]>(`api/placements/${placementId}/messages`, options);
}

export async function SavePlacementsSearch(searchName: string, searchFrequency: number, data: PlacementSearch, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<NumberValueWrapper>(`api/placements/search/${searchFrequency}?name=${encodeURIComponent(searchName)}`, options, data);
}

export async function UpdatePlacementsSavedSearch(ssid: number, searchName: string, searchFrequency: number, data: PlacementSearch, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/placements/search/${ssid}?freqId=${searchFrequency}&name=${encodeURIComponent(searchName)}`, options, data);
}

export async function GetPlacementPartnerActions(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<PartnerAction[]>('api/placements/partneractions', options);
}

export async function GetApprovedPlacementPartnerActions(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<PartnerAction[]>('api/placements/partneractions/approved', options);
}

export async function GetPlacementWorkflowPartnerActions(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };
    
    return Get<PartnerAction[]>('api/placements/partneractions/workflow', options);
}

export async function GetApprovedPlacementWorkflowPartnerActions(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<PartnerAction[]>('api/placements/partneractions/workflow/approved', options);
}

export async function ImportBulkExtensions(data: Array<ImportableExtension>, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>('api/placements/import/extensions', options, data);
}

export async function ImportBulkRateChanges(data: Array<ImportableRateChange>, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>('api/placements/import/rate-changes', options, data);
}

export async function GetPlacementMeetings(placementId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Meeting[]>(`api/placements/${placementId}/meetings`, options);
}

export async function GetCompanyBillingsOverviewReportData(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<any>(`api/placements/reports/billings-company-overview`, options);
}

export async function GetUserBillingsDetailedReportData(userId: number, reportingPeriod: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<any>(`api/placements/reports/billings-user-detailed?userId=${userId}&reportingPeriod=${reportingPeriod}`, options);
}

export async function GetPlacementInvoices(placementId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return await Get<Invoice[]>(`api/placements/${placementId}/invoices`, options);
}

export async function CreatePlacementInvoice(placementId: number, data: CreateInvoice, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<NumberValueWrapper>(`api/placements/${placementId}/invoice`, options, data);
}