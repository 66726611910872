import { ErrorHandler, RequestOptions, StringValueWrapper } from "common/models/GenericTypes";
import { SeekBoardSettingsData, SeekBrandingWrapper, SeekJobCategory, SeekJobCategorySuggestionWrapper, SeekLocationResponseRoot, SeekPreviewParams } from "common/models/JobPosting/Seek";
import { Get, Post } from "util/HttpUtils";

export async function GetSeekBoardSettingsData(jobBoardId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<SeekBoardSettingsData>(`api/Seek/${jobBoardId}/AuthSettings`, options);
}

export async function GetSeekLocationSuggestions(hirerId: string, searchTerm: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<SeekLocationResponseRoot>(`api/Seek/LocationSuggestions`, options, { hirerId: hirerId, search: searchTerm });
}

export async function GetSeekCategorySuggestions(hirerId: string, locationId: string, jobTitle: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<SeekJobCategorySuggestionWrapper[]>(`api/Seek/JobCategorySuggestions`, options, { hirerId, locationId, jobTitle });
}

export async function GetSeekCategories(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<SeekJobCategory[]>(`api/Seek/JobCategories`, options);
}

export async function GetSeekBrandings(hirerId: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<SeekBrandingWrapper[]>(`api/Seek/Brandings`, options, { value: hirerId });
}

export async function GenerateSeekAdvertPreviewUrl(previewData: SeekPreviewParams, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<StringValueWrapper>(`api/Seek/Preview`, options, previewData);
}