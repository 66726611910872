import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import ControlCameraIcon from '@mui/icons-material/ControlCamera';
import React, { useEffect, useMemo, useState } from "react";
import Draggable from "react-draggable";
import { PreviewProps } from "common/models/Previews/Previews";
import { PREVIEW_HEIGHT, PREVIEW_TITLE_STYLE, PREVIEW_WIDTH } from "util/Definitions/Constants/Previews";
import { DataGridPremium, GridColDef, GridRenderCellParams, GridValueGetter } from "@mui/x-data-grid-premium";
import { GetRecordTags } from "services/TagsService";
import { TagValue } from "common/models/Configuration/Tags";
import moment from "moment";

interface Props extends PreviewProps {
    entityTypeId: number,
    recordName: string
}

export default function TagsPreviewComponent({ entityTypeId, recordId, isSourceHovered, recordName, hideCallback, hideDelayMs = 1250 }: Props) {
    const [shouldClose, setShouldClose] = useState(false);
    const [isFetchingData, setIsFetchingData] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [rows, setRows] = useState<TagValue[]>([]);

    useEffect(() => {
        const getData = async () => {
            setIsFetchingData(true);
            const res = await GetRecordTags(entityTypeId, recordId);
            if (res) setRows(res);
            setIsFetchingData(false);
        };
        getData();
    }, [entityTypeId, recordId]);

    useEffect(() => {
        if (shouldClose) {
            const hidePreviewTimeout = setTimeout(() => {
                hideCallback();
            }, hideDelayMs);
            return () => clearTimeout(hidePreviewTimeout);
        }
    }, [shouldClose, hideDelayMs, hideCallback]);

    useEffect(() => {
        if (isSourceHovered || isHovered) setShouldClose(false);
        else setShouldClose(true);
    }, [isSourceHovered, isHovered]);

    const columns = useMemo<GridColDef[]>(() => {
        const dateValueGetter: GridValueGetter<TagValue, any, undefined, string> = (value) => {
            if (value) {
                const d = moment(value);
                if (d.isValid() && d.get('year') > 1) {
                    return d.toDate();
                }
            }
        };

        const dateRenderer = (params: GridRenderCellParams) => {
            if (params.value) {
                return moment(params.value).format('DD MMM YYYY');
            }
        };

        return [
            { field: 'tagTypeName', headerName: 'Type', sortable: false, width: 200 },
            { field: 'tag', headerName: 'Tag Text', sortable: false, flex: 1 },
            { field: 'recruiterName', headerName: 'Tagged By', sortable: false, width: 200 },
            { field: 'createdDate', headerName: 'Date', sortable: false, width: 120, valueGetter: dateValueGetter, renderCell: dateRenderer },
        ];
    }, []);

    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
            defaultPosition={{ x: (window.innerWidth / 2) - (PREVIEW_WIDTH / 2), y: (window.innerHeight / 2) - (PREVIEW_HEIGHT / 2) }}
        >
            <Dialog
                open={true}
                maxWidth={false}
                PaperProps={{
                    onMouseEnter: () => setIsHovered(true),
                    onMouseLeave: () => setIsHovered(false),
                    sx: { height: `${PREVIEW_HEIGHT}px`, width: `${PREVIEW_WIDTH}px` }
                }}
                hideBackdrop
                sx={{ bottom: 'unset', right: 'unset', left: 'unset' }}
            >
                <DialogTitle component="div" display="flex" sx={ PREVIEW_TITLE_STYLE }>
                    <div style={{ alignSelf: 'center' }}>
                        Tags - {recordName}
                    </div>
                    <div style={{ marginLeft: 'auto' }}>
                        <IconButton id="draggable-dialog-title" sx={{ cursor: 'move', color: t => t.palette.primary.contrastText }}><ControlCameraIcon /></IconButton>
                        <IconButton onClick={ () => hideCallback() } sx={{ color: t => t.palette.primary.contrastText }}><CloseIcon /></IconButton>
                    </div>
                </DialogTitle>
                <DialogContent sx={{ display: 'flex' }}>
                    <div style={{ paddingTop: '10px', display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                        Showing {rows.length} tags
                        <DataGridPremium
                            loading={isFetchingData}
                            getRowId={ row => row.tagID}
                            density="compact"
                            disableColumnMenu
                            disableColumnReorder
                            disableColumnResize
                            hideFooter
                            columns={columns}
                            rows={rows}
                        />
                    </div>
                </DialogContent>
            </Dialog>
        </Draggable>
    );
}
