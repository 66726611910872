import React, { useCallback, useEffect, useState } from "react";
import Alert from "components/Alert";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";


import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import PageContentLayout from "layouts/PageContentLayout";
import { GetPlacementsSettings, SavePlacementsSettings } from '../../../services/ConfigurationService';
import { DefaultOptions, NoChangesTracker } from '../../../util/Definitions/Configuration/Placements';
import useObjectStateWithChangeTracker from "hooks/UseObjectStateWithChangeTracker";
import useUnsavedChangesDialog from "hooks/UseUnsavedChangesDialog";
import { PlacementsConfigOptions } from "common/models/Configuration/Placements";
import { ObjToSettingsArray } from "util/SettingsUtil";
import RWTextFieldComponent from "components/RWTextFieldComponent";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import TextAreaWithFormat from "components/TextAreaWithFormat";

interface Props {
    setSummaryBar?: (summaryBar: JSX.Element) => void
}

export default function Settings({ setSummaryBar }: Props) {
    const [isLoading, setIsLoading] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const { state, init, change, updateInitial, hasChanges } = useObjectStateWithChangeTracker<PlacementsConfigOptions>(DefaultOptions, NoChangesTracker);

    useEffect(() => {
        const getSettings = async () => {
            setIsLoading(true);
            const data = await GetPlacementsSettings(setErrorMessage);
            if (data) {
                let savedSettings = DefaultOptions;
                for (let i = 0; i < data.length; i++) {
                    const setting = data[i];
                    (savedSettings as any)[setting.type] = setting.value;
                }

                init(savedSettings);
            }
            setIsLoading(false);
        };
        getSettings();
    }, [init]);

    const saveSettings = useCallback( async() => {
        setIsLoading(true);
        const settings = ObjToSettingsArray(state);
        const res = await SavePlacementsSettings(settings, setErrorMessage);
        
        if (!res) {
            setIsLoading(false);
            return false;
        }
        
        updateInitial();
        setShowSuccess(true);
        setIsLoading(false);
        return true;
    }, [state, updateInitial]);

    const { unsavedChangesDialog } = useUnsavedChangesDialog(hasChanges, saveSettings);

    const handleChanges = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        change(name as keyof PlacementsConfigOptions, value);
    }

    useEffect(() => {
        const saveButton = (
            <Button
                disabled={!hasChanges}
                variant="contained"
                color="primary"
                onClick={saveSettings}
                sx={{ borderRadius: '2px', padding: 0, height: '36px', width: '80px' }}
            >
                Save
            </Button>
        );
        
        const SummaryBar = (
            <TitleAndActionSummaryBar
                title="Configuration > Placements > Settings"
                browserTabTitle="Placements > Configuration"
                action={saveButton}
            />);

        setSummaryBar && setSummaryBar(SummaryBar);
    }, [setSummaryBar, saveSettings, hasChanges]);


    return (
        <>
            {unsavedChangesDialog}
            <Snackbar open={showSuccess} autoHideDuration={3000} onClose={() => setShowSuccess(false)}>
                <Alert onClose={() => setShowSuccess(false)}>Changes Saved</Alert>
            </Snackbar>
            <Snackbar open={errorMessage !== ''}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <PageContentLayout title="Placement Settings" showLoading={isLoading} >
                <Stack spacing={3}>
                    <TextField
                        select
                        label="Perm Exclusion Period"
                        name="PermExclusionPeriod"
                        value={state.PermExclusionPeriod}
                        onChange={handleChanges}
                    >
                        <MenuItem value="0">0 Months</MenuItem>
                        <MenuItem value="1">1 Month</MenuItem>
                        <MenuItem value="2">2 Months</MenuItem>
                        <MenuItem value="3">3 Months</MenuItem>
                        <MenuItem value="4">4 Months</MenuItem>
                        <MenuItem value="5">5 Months</MenuItem>
                        <MenuItem value="6">6 Months</MenuItem>
                        <MenuItem value="7">7 Months</MenuItem>
                        <MenuItem value="8">8 Months</MenuItem>
                        <MenuItem value="9">9 Months</MenuItem>
                        <MenuItem value="10">10 Months</MenuItem>
                        <MenuItem value="11">11 Months</MenuItem>
                        <MenuItem value="12">12 Months</MenuItem>
                        <MenuItem value="18">18 Months</MenuItem>
                        <MenuItem value="24">2 Years ( Default )</MenuItem>
                        <MenuItem value="36">3 Years</MenuItem>
                    </TextField>

                    <TextField
                        select
                        label="Default Warranty"
                        name="DefaultWarrantyPeriod"
                        value={state.DefaultWarrantyPeriod}
                        onChange={handleChanges}
                    >
                        <MenuItem value="0">None</MenuItem>
                        <MenuItem value="1">1 Month</MenuItem>
                        <MenuItem value="2">2 Months</MenuItem>
                        <MenuItem value="3">3 Months</MenuItem>
                        <MenuItem value="4">4 Months</MenuItem>
                        <MenuItem value="5">5 Months</MenuItem>
                        <MenuItem value="6">6 Months</MenuItem>
                        <MenuItem value="7">7 Months</MenuItem>
                        <MenuItem value="8">8 Months</MenuItem>
                        <MenuItem value="9">9 Months</MenuItem>
                        <MenuItem value="10">10 Months</MenuItem>
                        <MenuItem value="11">11 Months</MenuItem>
                        <MenuItem value="12">12 Months</MenuItem>
                    </TextField>

                    <TextField
                        select
                        label="Default Payment Type"
                        name="PlacementDefaultPaymentType"
                        value={state.PlacementDefaultPaymentType}
                        onChange={handleChanges}
                    >
                        <MenuItem value="0">None</MenuItem>
                        <MenuItem value="1">Employee</MenuItem>
                        <MenuItem value="2">Sub Contractor</MenuItem>
                    </TextField>

                    <TextField
                        select
                        label="Default Pay / Bill Units"
                        name="PlacementDefaultPayBillUnits"
                        value={state.PlacementDefaultPayBillUnits}
                        onChange={handleChanges}
                    >
                        <MenuItem value="0">None</MenuItem>
                        <MenuItem value="1">Hourly</MenuItem>
                        <MenuItem value="2">Daily</MenuItem>
                        <MenuItem value="3">Weekly</MenuItem>
                        <MenuItem value="4">Monthly</MenuItem>
                        <MenuItem value="5">Yearly</MenuItem>
                    </TextField>

                    <TextField
                        select
                        label="Default Pay Frequency"
                        name="DefaultPayFrequency"
                        value={state.DefaultPayFrequency}
                        onChange={handleChanges}
                    >
                        <MenuItem value="0">None</MenuItem>
                        <MenuItem value="1">Weekly</MenuItem>
                        <MenuItem value="5">Weekly ( 1 Week in Arrears )</MenuItem>
                        <MenuItem value="6">Weekly ( 3 Weeks in Arrears )</MenuItem>
                        <MenuItem value="2">Fortnightly</MenuItem>
                        <MenuItem value="3">Monthly</MenuItem>
                        <MenuItem value="4">4 Weekly</MenuItem>
                    </TextField>

                    <TextField
                        select
                        label="Default Notice Period Units"
                        name="PlacementDefaultNoticePeriodUnits"
                        value={state.PlacementDefaultNoticePeriodUnits}
                        onChange={handleChanges}
                    >
                        <MenuItem value="0">None</MenuItem>
                        <MenuItem value="1">Days</MenuItem>
                        <MenuItem value="2">Weeks</MenuItem>
                    </TextField>
                    <TextField
                        label="Oncosts %"
                        name="PlacementOnCostsPercentage"
                        value={state.PlacementOnCostsPercentage}
                        type="number"
                        inputMode="decimal"
                        onChange={handleChanges}
                    />

                    <RWTextFieldComponent
                        label="Rates Calculator URL"
                        name="PlacementRatesCalculator"
                        value={state.PlacementRatesCalculator}
                        onChange={handleChanges}
                    />

                    <TextField
                        select
                        label="Predefined Sources"
                        name="PlacementsPredefinedSources"
                        value={state.PlacementsPredefinedSources}
                        onChange={handleChanges}
                    >
                        <MenuItem value="True">Enabled</MenuItem>
                        <MenuItem value="False">Disabled</MenuItem>
                    </TextField>

                    <TextField
                        select
                        label="Bulk Placements"
                        name="BulkPlacements"
                        value={state.BulkPlacements}
                        onChange={handleChanges}
                    >
                        <MenuItem value="True">Enabled</MenuItem>
                        <MenuItem value="False">Disabled</MenuItem>
                    </TextField>

                    <TextField
                        select
                        label="Optional End Date"
                        name="PlacementOptionalEndDate"
                        value={state.PlacementOptionalEndDate}
                        onChange={handleChanges}
                    >
                        <MenuItem value="True">Enabled</MenuItem>
                        <MenuItem value="False">Disabled</MenuItem>
                    </TextField>

                    <TextField
                        select
                        label="Hide Excluding Super Descriptor"
                        name="HideExcludingSuper"
                        value={state.HideExcludingSuper}
                        onChange={handleChanges}
                    >
                        <MenuItem value="True">Enabled</MenuItem>
                        <MenuItem value="False">Disabled</MenuItem>
                    </TextField>

                    <TextField
                        select
                        label="Auto Export On Approval - Contract"
                        name="PlacementAutoExportOnApprovalContract"
                        value={state.PlacementAutoExportOnApprovalContract}
                        onChange={handleChanges}
                    >
                        <MenuItem value="True">Enabled</MenuItem>
                        <MenuItem value="False">Disabled</MenuItem>
                    </TextField>

                    <TextField
                        select
                        label="Auto Export On Approval - Permanent"
                        name="PlacementAutoExportOnApprovalPermanent"
                        value={state.PlacementAutoExportOnApprovalPermanent}
                        onChange={handleChanges}
                    >
                        <MenuItem value="True">Enabled</MenuItem>
                        <MenuItem value="False">Disabled</MenuItem>
                    </TextField>

                    <TextField
                        select
                        label="Candidate Confirmations"
                        name="PlacementCandidateConfirmation"
                        value={state.PlacementCandidateConfirmation}
                        onChange={handleChanges}
                    >
                        <MenuItem value="True">Enabled</MenuItem>
                        <MenuItem value="False">Disabled</MenuItem>
                    </TextField>

                    <TextField
                        select
                        label="Separate Pay / Bill Division"
                        name="PlacementsSeperatePayBillDivision"
                        value={state.PlacementsSeperatePayBillDivision}
                        onChange={handleChanges}
                    >
                        <MenuItem value="True">Enabled</MenuItem>
                        <MenuItem value="False">Disabled</MenuItem>
                    </TextField>

                    <TextField
                        select
                        label="Generate Docs only After Approval?"
                        name="PlacementsGenerateDocsAfterApproval"
                        value={state.PlacementsGenerateDocsAfterApproval}
                        onChange={handleChanges}
                    >
                        <MenuItem value="True">Enabled</MenuItem>
                        <MenuItem value="False">Disabled</MenuItem>
                    </TextField>
                    <TextField
                        select
                        label="Single Entity for Backoffice Integrations?"
                        name="PlacementsSingleBackOfficeEntity"
                        value={state.PlacementsSingleBackOfficeEntity}
                        onChange={handleChanges}
                    >
                        <MenuItem value="True">Yes</MenuItem>
                        <MenuItem value="False">No</MenuItem>
                    </TextField>
                    <TextField
                        select
                        label="Check if Reference Check exists before Placing a Candidate"
                        name="PlacementsCheckForRefCheck"
                        value={state.PlacementsCheckForRefCheck === 'True' ? 'True' : 'False'}
                        onChange={handleChanges}
                    >
                        <MenuItem value="True">Enabled</MenuItem>
                        <MenuItem value="False">Disabled</MenuItem>
                    </TextField>
                    <TextField
                        select
                        label="Placement Approval Notifications For Consultants"
                        name="PlacementApprovalNotificationForConsultants"
                        value={state.PlacementApprovalNotificationForConsultants}
                        onChange={handleChanges}
                    >
                        <MenuItem value="True">Enabled</MenuItem>
                        <MenuItem value="False">Disabled</MenuItem>
                    </TextField>
                    <TextField
                        select
                        label="Who can Cancel / Terminate Placements?"
                        name="PlacementsWhoCanCancelAndTerminate"
                        value={state.PlacementsWhoCanCancelAndTerminate}
                        onChange={handleChanges}
                    >
                        <MenuItem value="Anyone">Anyone</MenuItem>
                        <MenuItem value="FinancialControllers">Financial Controllers</MenuItem>
                    </TextField>
                    <RWTextFieldComponent
                        label="Financial Integration Errors Notification"
                        name="BackOfficeErrorsNotification"
                        value={state.BackOfficeErrorsNotification}
                        onChange={handleChanges}
                    />
                    <Box>
                        <InputLabel shrink sx={{ pl: 2 }}>Default Summary</InputLabel>
                        <TextAreaWithFormat
                            height={300}
                            initialValue={state.PlacementDefaultSummary}
                            onContentChange={content => change('PlacementDefaultSummary', content)}
                        />
                    </Box>
                </Stack>
            </PageContentLayout>
        </>
    );
}