import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import { useTheme } from "@mui/material/styles";
import { DashboardLayoutComponent, PanelModel } from "@syncfusion/ej2-react-layouts/src";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import * as tinymce from 'tinymce';
import { CustomField, CustomFieldPredefinedValue } from "common/models/Configuration/CustomFields";
import { Contact } from "common/models/Contacts";
import { SiteRecordDashboardElementDefinition, SiteRecordDashboardElementType } from "common/models/Dashboard/EditLayout";
import { CustomFieldSettingsMap, CustomFieldType } from "common/models/ScreenLayouts/CustomFields";
import { MinSite, Site } from "common/models/Site";
import useObjectStateWithChangeTracker from "hooks/UseObjectStateWithChangeTracker";
import useUnsavedChangesDialog from "hooks/UseUnsavedChangesDialog";
import { GetCustomerSettingBySettingName } from "services/ConfigurationService";
import { GetCustomFieldsByEntity_OnlyActive, GetPredefinedValues } from "services/CustomFieldsService";
import { CreateSite, GetSiteById, UpdateSite } from "services/SitesService";
import { GetMySettings } from "services/UsersService";
import { IsValidCustomFieldValue, UdfSiteFieldMapObj } from "util/Definitions/ScreenLayouts/CustomFields";
import { DefaultSiteRecordDashboardElements, GetPanelDefinitionsFromPanelModels, SiteScreenLayoutSettings } from "util/Definitions/ScreenLayouts/Site";
import { DefaultMinSite, DefaultMinSiteNoChanges, SiteToMinSite } from "util/Definitions/Sites";
import PanelWrapper from "components/Dashboards/PanelWrapper";
import ContactPicker from "components/Pickers/ContactPicker";
import CountryPicker from "components/Pickers/CountryPicker";
import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import EditableRichTextElement from "../EditableRichTextElement";
import EditableSingleFieldElement from "../EditableSingleFieldElement";
import SingleFieldElement from "../SingleFieldElement";

interface Props {
    siteId?: number
    clientId?: number,
    defaultSummary?: string,
    setSummaryBar?: (sb: JSX.Element) => void,
    loadingHandler?: (isLoading: boolean) => void,
    successHandler?: (message: string) => void,
    errorHandler?: (message: string) => void,
}

interface CustomFieldWithPredefinedValues extends CustomField {
    values?: CustomFieldPredefinedValue[],
}

const validationErrorMessage = (validationResult: string) => {
    if (validationResult === 'ok') return '';
    if (validationResult === 'required') return 'Required';
    return 'Not Valid';
};

const { unitWidth, unitHeight, gapX, gapY, columns, mediaQueryMaxWidth } = SiteScreenLayoutSettings;
const cellSpacing = [gapX, gapY];

export default function EditRecordScreenLayout({ siteId = 0, clientId, defaultSummary = '', setSummaryBar, loadingHandler, successHandler, errorHandler }: Props) {
    const [isFetchingConfig, setIsFetchingConfig] = useState(false);
    const [isFetchingSite, setIsFetchingSite] = useState(false);
    const [fetchedConfig, setFetchedConfig] = useState(false);
    const [fetchedSite, setFetchedSite] = useState(false);
    const [showValidation, setShowValidation] = useState(false);
    const [fetchedLayoutConfig, setFetchedLayoutConfig] = useState(false);
    const [fetchingLayoutConfig, setFetchingLayoutConfig] = useState(false);
    const [layoutConfiguration, setLayoutConfiguration] = useState<PanelModel[] | null>(null);
    const [existingSite, setExistingSite] = useState<Site | null>(null);
    const [availableCustomFields, setAvailableCustomFields] = useState<CustomFieldWithPredefinedValues[]>([]);
    const [isEditorDirty, setIsEditorDirty] = useState(false);
    const { state, init, change, updateInitial, hasChanges } = useObjectStateWithChangeTracker<MinSite>(DefaultMinSite, DefaultMinSiteNoChanges);
    const editorRef = useRef<tinymce.Editor | null>(null);
    const [savedChanges, setSavedChanges] = useState(false);
    const [createdSiteId, setCreatedSiteId] = useState(0);
    const [screenResizedControl, setScreenResizedControl] = useState(false);
    const layoutRef = useRef<DashboardLayoutComponent | null>(null);
    const theme = useTheme();

    useEffect(() => {
        const getExistingSite = async () => {
            setIsFetchingSite(true);
            const res = await GetSiteById(siteId, errorHandler);
            if (res) setExistingSite(res);
            setFetchedSite(true);
            setIsFetchingSite(false);
        };
        siteId && getExistingSite();
    }, [siteId, errorHandler]);

    useEffect(() => {
        const getActiveFields = async () => {
            setIsFetchingConfig(true);
            const customFields = await GetCustomFieldsByEntity_OnlyActive(9);
            if (customFields) {
                let udfDefinitions: CustomFieldWithPredefinedValues[] = [];
                for (let i = 0; i < customFields.length; i++) {
                    const u = customFields[i];
                    if (!u.usePredefinedValues) {
                        udfDefinitions.push(u);
                        continue;
                    }

                    const vals = await GetPredefinedValues(u.id);
                    if (vals) udfDefinitions.push({ ...u, values: vals });
                    else udfDefinitions.push(u);
                }
                setAvailableCustomFields(udfDefinitions);
            }
            setFetchedConfig(true);
            setIsFetchingConfig(false);
        };
        getActiveFields();
    }, []);

    useEffect(() => {
        const getUserLocation = async () => {
            let tempSite = {...DefaultMinSite};
            tempSite.description = defaultSummary;
            const settings = await GetMySettings();
            if (settings) {
                const countryCode = settings.find(s => s.type === 'DefaultCountry');
                if (countryCode && countryCode.value) tempSite.country = countryCode.value;
            }
            init(tempSite);
        };

        siteId === 0 && fetchedConfig && getUserLocation();
    }, [siteId, fetchedConfig, init, defaultSummary]);

    useEffect(() => {
        const setDefaultCreationValues = () => {
            let tempState = {...DefaultMinSite};
            if (availableCustomFields.length > 0) {
                for (let i = 0; i < availableCustomFields.length; i++) {
                    const f = availableCustomFields[i];
                    if (f.usePredefinedValues && f.values && f.values.length > 0) {
                        const defaultValue = f.values.find(v => v.isDefault);
                        if (defaultValue) {
                            const field = 'customField' + f.name.substring(13) as keyof MinSite;
                            (tempState as any)[field] = defaultValue.value;
                        }
                    }
                }
            }
            init(tempState);
        };
        siteId === 0 && fetchedConfig && setDefaultCreationValues();
    }, [siteId, fetchedConfig, availableCustomFields, init]);

    useEffect(() => {
        const getSavedState = async () => {
            setFetchingLayoutConfig(true);
            const elementsJson = await GetCustomerSettingBySettingName('SiteEditRecordDashboardLayoutElements');
            if (elementsJson) {
                const panels = JSON.parse(elementsJson) as PanelModel[];
                setLayoutConfiguration(panels);
            }
            setFetchingLayoutConfig(false);
            setFetchedLayoutConfig(true);
        };
        getSavedState();
    }, []);

    useEffect(() => loadingHandler && loadingHandler(isFetchingSite || isFetchingConfig || fetchingLayoutConfig), [isFetchingSite, isFetchingConfig, fetchingLayoutConfig, loadingHandler]);

    useEffect(() => {
        if (siteId && existingSite && fetchedSite && fetchedConfig) {
            const mc = SiteToMinSite(existingSite);
            init(mc);
            const api = editorRef.current;
            if (api && existingSite.description) api.setContent(existingSite.description);
        }
    }, [siteId, existingSite, fetchedSite, fetchedConfig, init]);

    const saveChangesHandler = useCallback(async () => {
        if(!state.name) {
            setShowValidation(true);
            errorHandler && errorHandler("Name is required");
            return false;
        }

        if (!state.country) {
            setShowValidation(true);
            errorHandler && errorHandler("Country is required");
            return false;
        }

        if(Boolean(state.phone) && state.phone.length > 45) {
            setShowValidation(true);
            errorHandler && errorHandler("Phone can't have more than 45 characters");
            return false;
        }

        let emptyNumericUdfs: Partial<MinSite> = { };
        let nullDateUdfs: Partial<MinSite> = { };
        for (let i = 0; i < availableCustomFields.length; i++) {
            const u = availableCustomFields[i];
            if (!u.editable) continue;
            const customFieldNumber = u.name.substring(13);
            const customFieldKey = "customField" + customFieldNumber as keyof MinSite;
            const { format } = UdfSiteFieldMapObj['CustomField' + customFieldNumber as CustomFieldType];
            const value = state[customFieldKey];

            const validation = IsValidCustomFieldValue(value === null ? undefined : value, format, u.mandatory);

            if (validation === "required") {
                setShowValidation(true);
                errorHandler && errorHandler(`${u.agencyName} is required`);
                return false;
            }
            else if (validation === "invalid-number") {
                setShowValidation(true);
                errorHandler && errorHandler(`${u.agencyName} must have a valid numeric value`);
                return false;
            }
            else if (validation === "invalid-date") {
                setShowValidation(true);
                errorHandler && errorHandler(`${u.agencyName} must have a valid date value`);
                return false;
            }
            else if (validation === "range-date") {
                setShowValidation(true);
                errorHandler && errorHandler(`${u.agencyName} must be a date between 01-01-1753 and 31-12-9999`);
                return false;
            }

            if (format === "number" && (value === '' || value === null || value === undefined)) (emptyNumericUdfs[customFieldKey] as any) = undefined;
            if ((format === "date" || format === 'datetime') && !Boolean(value)) (nullDateUdfs[customFieldKey] as any) = '0001-01-01T00:00:00';
        }

        loadingHandler && loadingHandler(true);
        const editorApi = editorRef.current;
        const summaryContent = editorApi ? editorApi.getContent() : '';
        if (siteId === 0) {
            const res = await CreateSite({...state, clientID: clientId, description: summaryContent, ...emptyNumericUdfs, ...nullDateUdfs }, errorHandler);
            if (!res) {
                loadingHandler && loadingHandler(false);
                return false;
            }
            setCreatedSiteId(res.value);
        }
        else if (siteId && (hasChanges || isEditorDirty)) {
            const res = await UpdateSite(siteId, {...state, description: summaryContent, ...emptyNumericUdfs, ...nullDateUdfs }, errorHandler);
            if (!res) {
                loadingHandler && loadingHandler(false);
                return false;
            }
        }
        updateInitial();
        setIsEditorDirty(false);
        editorApi && editorApi.setDirty(false);
        loadingHandler && loadingHandler(false);
        setSavedChanges(true);
        return true;
    }, [siteId, clientId, state, availableCustomFields, hasChanges, isEditorDirty, updateInitial, loadingHandler, errorHandler]);

    useEffect(() => {
        const actionButton = <Button variant="contained" color="success" disabled={!hasChanges && !isEditorDirty} onClick={saveChangesHandler}>Save</Button>
        if (siteId === 0 && setSummaryBar) {
            const sb = <TitleAndActionSummaryBar title="Sites > Create" browserTabTitle="Create > Sites" action={actionButton} />;
            setSummaryBar(sb);
        }
        else if (siteId && setSummaryBar) {
            const goBackAction = <Link to={`/sites/${siteId}`} style={{ textDecoration: 'none' }}><Button variant="contained" color="error" sx={{ mr: '5px' }}>Cancel</Button></Link>;
            const sb = <TitleAndActionSummaryBar title="Sites > Edit" browserTabTitle="Edit > Sites" action={<>{goBackAction}{actionButton}</>} />;
            setSummaryBar(sb);
        }
    }, [siteId, hasChanges, isEditorDirty, setSummaryBar, saveChangesHandler]);
    
    const onStringFieldChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        let val = value;
        if (name.startsWith('dtUDF') && value && !value.includes('T')) val += 'T00:00:00';
        change(name as keyof MinSite, val);
    }, [change]);

    const onDateFieldChange = useCallback((m: moment.Moment | null, fieldName: string) => {
        if(m && m.isValid()) {
            change(fieldName as keyof MinSite, m.format('YYYY-MM-DD'))
        }
        else if (m === null) change(fieldName as keyof MinSite, '');
    }, [change]);

    const onDateTimeFieldChange = useCallback((m: moment.Moment | null, fieldName: string) => {
        if(m && m.isValid()) {
            change(fieldName as keyof MinSite, m.format('YYYY-MM-DDTHH:mm'));
        }
        else if (m === null) change(fieldName as keyof MinSite, '');
    }, [change]);

    const onNumberFieldChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        const n = +value;
        if (isNaN(n) && value !== '') return;
        if (value.length > 11) return;

        const i = value.indexOf('.');
        if (value.length > 8 && i === -1) return;

        if(i >= 0) {
            const decimals = value.substring(i + 1);
            if (decimals.length > 2) {
                change(name as keyof MinSite, value.substring(0, i + 3));
                return;
            }
        }

        change(name as keyof MinSite, value);

    }, [change]);

    const onContactChange = useCallback((contact: Contact | null) => {
        change('primaryContact', contact ? contact.id : 0);
    }, [change]);

    const onCountryChange = useCallback((countryCode: string | null) => {
        if (countryCode === 'AU') change('state', '');
        change('country', countryCode ?? '');
    }, [change]);

    const { unsavedChangesDialog, hasBlockedRoute } = useUnsavedChangesDialog(hasChanges || isEditorDirty, saveChangesHandler);

    const redirectSiteId = useMemo(() => {
        if (siteId !== 0) return siteId;
        if (savedChanges && createdSiteId) return createdSiteId;
        return 0;
    }, [siteId, createdSiteId, savedChanges]);

    const elements = useMemo<SiteRecordDashboardElementDefinition[]>(() => {
        if (!fetchedLayoutConfig) return [];
        if (layoutConfiguration !== null) {
            const elements = GetPanelDefinitionsFromPanelModels(layoutConfiguration);
            return elements ;
        }
        return DefaultSiteRecordDashboardElements;
    }, [layoutConfiguration, fetchedLayoutConfig]);

    const customFieldsSettingsMap = useMemo<CustomFieldSettingsMap>(() => {
        let obj: CustomFieldSettingsMap = {};
        availableCustomFields.forEach(u => {
            const customFieldNumber = u.name.substring(13);
            const key = 'CustomField' + customFieldNumber as CustomFieldType;
            obj[key] = {
                title: u.agencyName,
                isMultiLine: u.multiLine && !u.usePredefinedValues,
                isMandatory: u.mandatory,
                isEditable: u.editable,
                usePredefinedValues: u.usePredefinedValues,
                values: u.values
            }
        });
        return obj;
    }, [availableCustomFields]);

    const renderElement = useCallback((id: string, type: SiteRecordDashboardElementType) => {
        switch (type) {
            case 'Divider': return <Box pt="20px"><Divider component="div" /></Box>;
            case 'Spacer': return <></>;
            case 'SiteSummary': return (
                <EditableRichTextElement
                    title="Summary"
                    content={state.description ?? ''}
                    isDarkTheme={theme.palette.mode === 'dark'}
                    editorRef={editorRef}
                    editorDirtyHandler={ setIsEditorDirty }
                />
            );
            case 'SiteId': return <SingleFieldElement labelWidthPercent={30} fontSize="1rem" fieldTitle='ID' fieldValue={state.id} format="number" />;
            case 'SiteName': return (
                <EditableSingleFieldElement
                    isError={showValidation && !state.name}
                    errorMessage="Required"
                    fieldTitle='Name'
                    fieldValue={state.name}
                    fieldName="name"
                    onChangeHandler={onStringFieldChange}
                />
            );
            case 'SitePhone':
                const phoneValue = state.phone;
                const isPhoneError = Boolean(state.phone) && state.phone.length > 45;
                return <EditableSingleFieldElement isError={showValidation && isPhoneError} errorMessage="Not Valid" fieldTitle='Phone' fieldValue={phoneValue} fieldName="phone" onChangeHandler={onStringFieldChange} />;
            case 'SitePrimaryContact': return (
                <EditableSingleFieldElement format="custom" fieldTitle='Primary Contact' >
                    <ContactPicker
                        hideLabel
                        variant="standard"
                        value={state.primaryContact} onSelectCallback={ c => onContactChange(c) }
                        errorHandler={errorHandler}
                        loadingHandler={loadingHandler}
                        successHandler={successHandler}
                    />
                </EditableSingleFieldElement>
            );
            case 'SiteStatus': return (
                <EditableSingleFieldElement format="select" fieldTitle='Status' fieldValue={state.statusID.toString()} fieldName="statusID" onChangeHandler={onNumberFieldChange} >
                    <MenuItem value="0">Active</MenuItem>
                    <MenuItem value="1">Inactive</MenuItem>
                </EditableSingleFieldElement>
            );
            case 'SiteAddress1': return (
                <EditableSingleFieldElement
                    fieldTitle='Address 1'
                    fieldValue={state.address1}
                    fieldName="address1"
                    onChangeHandler={onStringFieldChange}
                />
            );
            case 'SiteAddress2': return (
                <EditableSingleFieldElement
                    fieldTitle='Address 2'
                    fieldValue={state.address2}
                    fieldName="address2"
                    onChangeHandler={onStringFieldChange}
                />
            );
            case 'SiteAddress3': return (
                <EditableSingleFieldElement
                    fieldTitle='Address 3'
                    fieldValue={state.address3}
                    fieldName="address3"
                    onChangeHandler={onStringFieldChange}
                />
            );
            case 'SiteSuburb': return (
                <EditableSingleFieldElement
                    fieldTitle='Suburb'
                    fieldValue={state.suburb}
                    fieldName="suburb"
                    onChangeHandler={onStringFieldChange}
                />
            );
            case 'SiteState':
                if (state.country === 'AU') {
                    return (
                        <EditableSingleFieldElement
                            fieldTitle='State'
                            fieldValue={state.state}
                            fieldName="state"
                            onChangeHandler={onStringFieldChange}
                            format="select"
                        >
                            <MenuItem value="">None</MenuItem>
                            <MenuItem value="ACT">ACT</MenuItem>
                            <MenuItem value="New South Wales">New South Wales</MenuItem>
                            <MenuItem value="Northern Territory">Northern Territory</MenuItem>
                            <MenuItem value="Queensland">Queensland</MenuItem>
                            <MenuItem value="South Australia">South Australia</MenuItem>
                            <MenuItem value="Tasmania">Tasmania</MenuItem>
                            <MenuItem value="Victoria">Victoria</MenuItem>
                            <MenuItem value="Western Australia">Western Australia</MenuItem>
                        </EditableSingleFieldElement>
                    );
                }
                if (state.country === 'NZ') {
                    return (
                        <EditableSingleFieldElement
                            fieldTitle='Region'
                            fieldValue={state.state}
                            fieldName="state"
                            onChangeHandler={onStringFieldChange}
                            format="select"
                        >
                            <MenuItem value="">None</MenuItem>
                            <MenuItem value="Auckland">Auckland</MenuItem>
                            <MenuItem value="Bay of Plenty">Bay of Plenty</MenuItem>
                            <MenuItem value="Canterbury">Canterbury</MenuItem>
                            <MenuItem value="Chatham Islands Territory">Chatham Islands Territory</MenuItem>
                            <MenuItem value="Gisborne">Gisborne</MenuItem>
                            <MenuItem value="Hawke's Bay">Hawke's Bay</MenuItem>
                            <MenuItem value="Manawatu-Wanganui">Manawatu-Wanganui</MenuItem>
                            <MenuItem value="Marlborough">Marlborough</MenuItem>
                            <MenuItem value="Nelson">Nelson</MenuItem>
                            <MenuItem value="Northland">Northland</MenuItem>
                            <MenuItem value="Otago">Otago</MenuItem>
                            <MenuItem value="Southland">Southland</MenuItem>
                            <MenuItem value="Taranaki">Taranaki</MenuItem>
                            <MenuItem value="Tasman">Tasman</MenuItem>
                            <MenuItem value="Waikato">Waikato</MenuItem>
                            <MenuItem value="Wellington">Wellington</MenuItem>
                            <MenuItem value="West Coast">West Coast</MenuItem>
                        </EditableSingleFieldElement>
                    );
                }
                return (
                    <EditableSingleFieldElement
                        fieldTitle='State'
                        fieldValue={state.state}
                        fieldName="state"
                        onChangeHandler={onStringFieldChange}
                    />
                );
            case 'SitePostcode': return (
                <EditableSingleFieldElement
                    fieldTitle='Postcode'
                    fieldValue={state.postcode}
                    fieldName="postcode"
                    onChangeHandler={onStringFieldChange}
                />
            );
            case 'SiteCountry': return (
                <EditableSingleFieldElement format="custom" fieldTitle='Country' isError={showValidation && !state.country} errorMessage="Required" >
                    <CountryPicker hideLabel variant="standard" code={state.country} onSelectCallback={ onCountryChange } error={ showValidation && !Boolean(state.country)} />
                </EditableSingleFieldElement>
            );
            default:
                if (type.startsWith('CustomField')) {
                    const settings = customFieldsSettingsMap[type];
                    if (settings && settings.isEditable) {
                        let title = settings.title;
                        let predefinedValues = settings.usePredefinedValues && settings.values ? settings.values : null;
                        const { field, format } = UdfSiteFieldMapObj[type as CustomFieldType];
                        const rawValue = state[field as keyof MinSite];
                        const udfValue = (state[field as keyof MinSite] ?? '').toString();
                        const validationResult = IsValidCustomFieldValue(rawValue === null ? undefined : rawValue, format, settings.isMandatory ?? false);
                        const isValid = validationResult === "ok";
                        const errorMessage = validationErrorMessage(validationResult);
                        if (predefinedValues) {
                            return (
                                <EditableSingleFieldElement isError={showValidation && !isValid} errorMessage={errorMessage} format="select" fieldTitle={title} fieldValue={udfValue} fieldName={field} onChangeHandler={onStringFieldChange}>
                                    <MenuItem value="">None</MenuItem>
                                    {predefinedValues.map(v => <MenuItem key={v.value} value={v.value}>{v.value + (v.isDefault ? ' (Default)' : '')}</MenuItem>)}
                                </EditableSingleFieldElement>
                            );
                        }
                        else if (udfValue !== undefined && format === 'string') {
                            return (
                                <EditableSingleFieldElement
                                    isError={showValidation && !isValid}
                                    errorMessage={errorMessage}
                                    format={format}
                                    fieldTitle={title}
                                    fieldValue={udfValue}
                                    fieldName={field}
                                    multiline={settings.isMultiLine}
                                    onChangeHandler={onStringFieldChange}
                                />
                            );
                        }
                        else if (udfValue !== undefined && format === 'number') {
                            return (
                                <EditableSingleFieldElement
                                    isError={showValidation && !isValid}
                                    errorMessage={errorMessage}
                                    format={format}
                                    fieldTitle={title}
                                    fieldValue={udfValue}
                                    fieldName={field}
                                    onChangeHandler={onNumberFieldChange}
                                />
                            );
                        }
                        else if (format === 'date') {
                            return (
                                <EditableSingleFieldElement
                                    isError={showValidation && !isValid}
                                    errorMessage={errorMessage}
                                    format={format}
                                    fieldTitle={title}
                                    fieldValue={udfValue}
                                    fieldName={field}
                                    onDateChangeHandler={onDateFieldChange}
                                />
                            );
                        }
                        else if (format === 'datetime') {
                            return (
                                <EditableSingleFieldElement
                                    isError={showValidation && !isValid}
                                    errorMessage={errorMessage}
                                    format={format}
                                    fieldTitle={title}
                                    fieldValue={udfValue}
                                    fieldName={field}
                                    onDateChangeHandler={onDateTimeFieldChange}
                                />
                            );
                        }
                    }
                    else if (settings) return <SingleFieldElement labelWidthPercent={30} fontSize="1rem" fieldTitle={settings.title} fieldValue="[ Non Editable Custom Field ]" />;
                    else return <SingleFieldElement labelWidthPercent={30} fontSize="1rem" fieldTitle={type} fieldValue="[ Disabled Custom Field ]" />;
                }
                return <div>{id}</div>;
        }
    }, [state, showValidation, customFieldsSettingsMap, setIsEditorDirty, editorRef, theme.palette.mode,
        onStringFieldChange, onNumberFieldChange, onDateFieldChange, onDateTimeFieldChange, onContactChange, loadingHandler, errorHandler, successHandler, onCountryChange]);

    const layoutResizeStopHandler = useCallback(() => setScreenResizedControl(prev => !prev), []);

    useEffect(() => {
        if (screenResizedControl) {}
        const api = layoutRef.current;
        if (api && fetchedLayoutConfig) {
            const refreshTimeout = setTimeout(() => {
                api.refresh();
                api.refresh();
            }, 250);
            return () => clearTimeout(refreshTimeout);
        }
    }, [fetchedLayoutConfig, screenResizedControl]);

    useEffect(() => {
        window.addEventListener('resize', layoutResizeStopHandler);
        return () => window.removeEventListener('resize', layoutResizeStopHandler);
    }, [layoutResizeStopHandler]);

    const renderLayout = useCallback(() => {
        if (!fetchedLayoutConfig) return <></>;
        
        let sorted = [...elements];
        sorted.sort((a, b) => {
            if (a.col === b.col) return a.row < b.row ? -1 : 1;
            else return a.col < b.col ? -1 : 1;
        });

        const mediaQuery = mediaQueryMaxWidth ? `max-width: ${mediaQueryMaxWidth}` : undefined;
        return (
            <DashboardLayoutComponent
                cellSpacing={cellSpacing}
                columns={columns}
                cellAspectRatio={30 / 2}
                allowDragging={false}
                allowResizing={false}
                resizeStop={ layoutResizeStopHandler }
                ref={l => layoutRef.current = l}
                mediaQuery={mediaQuery}
            >
                {sorted.map(e => (
                    <PanelWrapper
                        key={e.id}
                        id={e.id}
                        col={e.col}
                        row={e.row}
                        sizeX={e.sizeX}
                        sizeY={e.sizeY}
                        minSizeX={e.minSizeX}
                        minSizeY={e.minSizeY}
                        maxSizeX={e.maxSizeX}
                        maxSizeY={e.maxSizeY}
                        resizeControl={screenResizedControl}
                        resizeIconColor={theme.palette.text.disabled}
                        gapX={gapX}
                        gapY={gapY}
                        unitWidth={unitWidth}
                        unitHeight={unitHeight}
                    >
                        {renderElement(e.id, e.type)}
                    </PanelWrapper>
                ))}
            </DashboardLayoutComponent>
        );
    }, [elements, fetchedLayoutConfig, theme.palette.text.disabled, screenResizedControl, layoutResizeStopHandler, renderElement]);

    return (
        <>
            {unsavedChangesDialog}
            { !hasBlockedRoute && redirectSiteId !== 0 && savedChanges && !hasChanges && !isEditorDirty && <Navigate to={`/sites/${redirectSiteId}`} /> }
            <Box p="10px" height="100%">
                <div className="control-section">
                    { renderLayout() }
                </div>
            </Box>
        </>
    );
}