import React, { useEffect, useMemo, useState } from "react";
import PageLayout from "layouts/PageLayout";
import { useSearchParams } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import Alert from "components/Alert";
import PageContentLayout from "layouts/PageContentLayout";
import SubmissionEditPageContent from "components/Content/SubmissionEditPageContent";

export default function SubmissionCreatePage() {
    const [summaryBar, setSummaryBar] = useState(<></>);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [warningMessage, setWarningMessage] = useState('');
    const [searchParams] = useSearchParams();

    const candidateIds = useMemo(() => {
        const cIds = searchParams.get('candidateIds');
        if (cIds) {
            const split = cIds.split(',');
            return split.map(s => +s);
        }
        return undefined;
    }, [searchParams]);

    const contactIds = useMemo(() => {
        const cIds = searchParams.get('contactIds');
        if (cIds) {
            const split = cIds.split(',');
            return split.map(s => +s);
        }
        return undefined;
    }, [searchParams]);

    const jobId = useMemo(() => {
        const tId = searchParams.get('jobId');
        if (tId && !isNaN(+tId)) return +tId;
        return undefined;
    }, [searchParams]);

    return (
        <PageLayout SummaryBar={summaryBar}>
            <Snackbar open={errorMessage !== ''} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <Snackbar open={successMessage !== ''} autoHideDuration={3000} onClose={() => setSuccessMessage('')} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert color="success" onClose={() => setSuccessMessage('')}>{ successMessage }</Alert>
            </Snackbar>
            <Snackbar open={warningMessage !== ''} autoHideDuration={3000} onClose={() => setWarningMessage('')} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert color="warning" onClose={() => setWarningMessage('')}>{ warningMessage }</Alert>
            </Snackbar>
            <PageContentLayout showLoading={isLoading}>
                <SubmissionEditPageContent
                    jobId={jobId}
                    candidateIds={candidateIds}
                    contactIds={contactIds}
                    setSummaryBar={setSummaryBar}
                    errorHandler={setErrorMessage}
                    loadingHandler={setIsLoading}
                    successHandler={setSuccessMessage}
                    warningHandler={setWarningMessage}
                />
            </PageContentLayout>
        </PageLayout>
    );
}