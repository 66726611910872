import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import MenuItem from "@mui/material/MenuItem";
import Snackbar from "@mui/material/Snackbar";
import { GridColDef, GridRenderCellParams, GridRowSelectionModel, GridValueGetter, useGridApiRef } from "@mui/x-data-grid-premium";
import TitleAndActionSummaryBar from "../SummaryBars/TitleAndActionSummaryBar";
import RescheduleDialog from "../Dialogs/ScheduledMessages/RescheduleDialog";
import ConfirmationDialog from "../Dialogs/Generic/ConfirmationDialog";
import PageContentLayout from "layouts/PageContentLayout";
import { DeleteMessages, GetMyScheduledMessages, GetScheduledMessages, RescheduleMessages } from "services/ScheduledMessagesService";
import { DeleteEmailData, ScheduledMessage } from "common/models/Messages";
import GridWithStateWrapper from "../GridWidthStateWrapper";
import ActionsDropDownButton from "../SummaryBars/Actions/ActionsDropsDownMenu";
import { PreviewEntityType } from "common/models/Previews/Previews";
import PreviewLoaderComponent from "../Previews/PreviewLoader";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import EditScheduledMessageDialog from "components/Dialogs/Messages/EditScheduledEmailDialog";
import EditScheduledSmsDialog from "components/Dialogs/Messages/EditScheduledSmsDialog";

interface Props {
    dataSource: "me" | "customer",
    summaryBarTitle: string,
    summaryBarTabTitle: string,
    setSummaryBar?: (summaryBar: JSX.Element) => void
}

const linkStyle: React.CSSProperties = { color: 'inherit', textDecoration: 'underline' };

export default function ScheduledMessagesPageContent({ dataSource, summaryBarTitle, summaryBarTabTitle, setSummaryBar }: Props) {
    const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [rows, setRows] = useState<ScheduledMessage[]>([]);
    const [showRescheduleDialog, setShowRescheduleDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [rescheduleDate, setRescheduleDate] = useState('');
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    const [previewType, setPreviewType] = useState<PreviewEntityType | ''>('');
    const [previewRecordId, setPreviewRecordId] = useState(0);
    const [editRow, setEditRow] = useState<ScheduledMessage | null>(null);
    const apiRef = useGridApiRef();

    useEffect(() => {
        const handleReschedule = () => {
            const dateString = moment().format('YYYY-MM-DDTHH:mm');
            setRescheduleDate(dateString);
            setShowRescheduleDialog(true);
        };

        const handleDelete = () => {
            setShowDeleteDialog(true);
        };

        const noneSelected = selectionModel.length === 0;
        const actionMenu = (
            <ActionsDropDownButton>
                <MenuItem disabled={ noneSelected } onClick={ handleReschedule } >Reschedule</MenuItem>
                <MenuItem disabled={ noneSelected } onClick={ handleDelete } >Delete</MenuItem>
            </ActionsDropDownButton>
        );
        const SummaryBar = (
            <TitleAndActionSummaryBar
                title={summaryBarTitle}
                browserTabTitle={summaryBarTabTitle}
                action={actionMenu}
            />
        );

        setSummaryBar && setSummaryBar(SummaryBar);
    }, [selectionModel.length, setSummaryBar, summaryBarTitle, summaryBarTabTitle]);

    const getData = useCallback(async () => {
        setIsLoading(true);
        let data: ScheduledMessage[] | null = null;    
        if(dataSource === "me") data = await GetMyScheduledMessages(setErrorMessage);
        else data = await GetScheduledMessages(setErrorMessage);
    
        if (data) {
            setRows(data.map((d, i) => ({...d, gridId: `${i}-${d.messageID}-${d.entityID}-${d.playerID}` })));
        }
    
        setIsLoading(false);
    }, [dataSource]);

    useEffect(() => {
        getData();
    }, [getData]);


    const handlePreviewHover = (type: PreviewEntityType | '', id: number) => {
        setPreviewType(type);
        setPreviewRecordId(id);
        setIsPreviewOpen(true);
    }

    const handlePreviewClose = () => {
        setIsPreviewOpen(false);
    }

    const columns = useMemo<GridColDef[]>(() => {
        const dateValueGetter: GridValueGetter<ScheduledMessage, any, undefined, string> = (value, row) => {
            if (value) {
                const d = moment(value);
                if (d.isValid() && d.get('year') > 1) {
                    return d.toDate();
                }
            }
        }

        const dateRenderer = (params: GridRenderCellParams) => {
            if (params.value) {
                return moment(params.value).format('DD MMM YYYY hh:mma');
            }
        };
        
        const recordNameRenderer = (params: GridRenderCellParams) => {
            const entityId = params.row.entityID;
            const playerId = params.row.playerID;
            if (entityId === 2) {
                return <Link target="_blank" to={`/contacts/${playerId}`} style={linkStyle} onMouseEnter={() => handlePreviewHover('contact', playerId)} onMouseLeave={handlePreviewClose} >{params.value}</Link>;
            }
            if (entityId === 3) {
                return <Link target="_blank" to={`/candidates/${playerId}`} style={linkStyle} onMouseEnter={() => handlePreviewHover('candidate', playerId)} onMouseLeave={handlePreviewClose} >{params.value}</Link>;
            }
            return params.value;
        }

        const editLinkRenderer = (params: GridRenderCellParams) => {
            return (
                <Box minHeight="42px" display="flex" alignItems="center">
                    <Box
                        component="span"
                        textAlign="center"
                        style={{ cursor: 'pointer', textDecoration: 'underline'}}
                        onClick={ () => setEditRow(params.row) }
                    >{params.value}</Box>
                </Box>
            );
        };

        return [
            { field: 'messageID', headerName: 'ID', width: 75, renderCell: editLinkRenderer },
            { field: 'messageType', headerName: 'Type', width: 65 },
            { field: 'subject', headerName: 'Subject', width: 400, renderCell: editLinkRenderer },
            { field: 'recordName', headerName: 'Recipient', width: 200, renderCell: recordNameRenderer },
            { field: 'sender', headerName: 'Sender', width: 200 },
            { field: 'sendAfter', headerName: 'Scheduled Date', type: "dateTime", valueGetter: dateValueGetter, renderCell: dateRenderer, width: 200 },
        ];
    }, []);

    const onEditSuccess = useCallback((message: string) => {
        setSuccessMessage(message);
        getData();
    }, [getData]);

    const handleReschedule = useCallback( async () => {
        setIsLoading(true);
        let smsIds: number[] = [];
        let emailIds: number[] = [];
        let updateRows: ScheduledMessage[] = [];
        setIsLoading(true);
        for (let i = 0; i < selectionModel.length; i++) {
            const rowId = selectionModel[i];
            const row = apiRef.current.getRow(rowId) as ScheduledMessage;
            const isGroupInArray = updateRows.findIndex(g => g.messageID === row.messageID) >= 0;
            if (!isGroupInArray) {
                const group = rows.filter(r => r.messageID === row.messageID);
                const t = group.map(g => ({...g, sendAfter: rescheduleDate}));
                updateRows.push(...t);
            }
            if (row.messageType.toLowerCase() === 'sms') {
                smsIds.push(row.messageID);
                continue;
            }
            if (row.messageType.toLowerCase() === 'email') {
                emailIds.push(row.messageID);
            }
        }
        const res = await RescheduleMessages(rescheduleDate, smsIds, emailIds, setErrorMessage);
        if(res){
            apiRef.current.updateRows(updateRows);
            setSelectionModel([]);
            setShowRescheduleDialog(false);
            setSuccessMessage('Message Rescheduled');
        }
        setIsLoading(false);
    }, [apiRef, rescheduleDate, selectionModel, rows]);

    const handleDelete = useCallback( async () => {
        let smsIds: number[] = [];
        let emailData: DeleteEmailData[] = [];
        let deletedIds: string[] = [];
        setIsLoading(true);
        for (let i = 0; i < selectionModel.length; i++) {
            const rowId = selectionModel[i];
            const row = apiRef.current.getRow(rowId) as ScheduledMessage;
            deletedIds.push(row.gridId);
            if (row.messageType.toLowerCase() === 'sms') {
                smsIds.push(row.messageID);
                continue;
            }
            if (row.messageType.toLowerCase() === 'email') {
                emailData.push({ entityId: row.entityID, playerId: row.playerID, messageId: row.messageID });
            }
        }
        const res = await DeleteMessages(smsIds, emailData, setErrorMessage);
        if(res) {
            let newRows = [...rows];
            for (let i = 0; i < deletedIds.length; i++) {
                const id = deletedIds[i];
                const rowIndex = newRows.findIndex(r => r.gridId === id);
                if(rowIndex !== -1) newRows.splice(rowIndex, 1);
            }
            setRows(newRows);
            setSelectionModel([]);
            setShowDeleteDialog(false);
            setSuccessMessage('Message Deleted');
        }
        setIsLoading(false);
    }, [apiRef, rows, selectionModel]);

    const gridName = useMemo(() => {
        switch (dataSource) {
            case 'customer':
                return 'configuration/scheduled-messages_ScheduledMessages';
            case 'me':
                return 'dashboard/my-scheduled-messages_ScheduledMessages';
            default:
                return '';
        }
    }, [dataSource]);

    const emailId = useMemo(() => {
        if (editRow && editRow.messageType === 'Email') return editRow.messageID;
        return 0;
    }, [editRow]);

    const smsId = useMemo(() => {
        if (editRow && editRow.messageType === 'SMS') return editRow.messageID;
        return 0;
    }, [editRow]);

    return (
        <>
            <PreviewLoaderComponent
                open={isPreviewOpen}
                entityType={previewType}
                recordId={previewRecordId}
            />
            <EditScheduledMessageDialog
                open={Boolean(editRow && editRow.messageType === 'Email')}
                closeHandler={() => setEditRow(null)}
                entityId={editRow ? editRow.entityID : 0}
                messageId={emailId}
                errorHandler={setErrorMessage}
                loadingHandler={setIsLoading}
                successHandler={onEditSuccess}
            />
            <EditScheduledSmsDialog
                open={Boolean(editRow && editRow.messageType === 'SMS')}
                closeHandler={() => setEditRow(null)}
                entityId={editRow ? editRow.entityID : 0}
                messageId={smsId}
                errorHandler={setErrorMessage}
                loadingHandler={setIsLoading}
                successHandler={onEditSuccess}
            />
            <Snackbar open={successMessage !== ''} autoHideDuration={3000} onClose={() => setSuccessMessage('')}>
                <Alert onClose={() => setSuccessMessage('')}>{successMessage}</Alert>
            </Snackbar>
            <Snackbar open={errorMessage !== ''} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <RescheduleDialog
                open={showRescheduleDialog}
                dateTime={rescheduleDate}
                onDateChange={setRescheduleDate}
                onClose={() => setShowRescheduleDialog(false)}
                onSubmit={handleReschedule}
            />
            <ConfirmationDialog
                open={showDeleteDialog}
                title={`Delete ${ selectionModel.length === 1 ? 'Message' : 'Messages' }`}
                message={
                    selectionModel.length === 1 ?
                    "Are you sure you want to delete this message?" :
                    `Are you sure you want to delete all ${selectionModel.length} selected messages?`
                }
                onClose={ () => setShowDeleteDialog(false) }
                onContinue={ handleDelete }
                confirmActionText="Confirm"
                fullWidth
                dividers
            />
            <PageContentLayout showLoading={isLoading}>
                <GridWithStateWrapper
                    gridName={gridName}
                    apiRef={apiRef}
                    getRowId={ (row) => row.gridId }
                    rows={rows}
                    columns={columns}
                    disableRowSelectionOnClick
                    disableMultipleRowSelection
                    checkboxSelection
                    pagination={true}
                    density="compact"
                    rowSelectionModel={selectionModel}
                    onRowSelectionModelChange={ sm => setSelectionModel(sm) }
                    pageSizeOptions={[100,250,500,1000]}
                />
            </PageContentLayout>
        </>
    );
}