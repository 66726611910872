import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { JXTArea, JXTCountry, JXTLocation, JXTProfession, JXTRole, JXTWorkTypeOption } from "common/models/JobPosting/JXT";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { GetJXTBoardSetupData } from "services/JobBoardsService";

interface Props {
    jobBoardId: number,
    advertId: number,
    attributes?: Record<string, string>,
    advertRecordAttributes?: Record<string, string>,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
    attributeChangeHandler?: (jobBoardId: number, key: string, value: string) => void
}

export default function JXTEdit({ jobBoardId, advertId, attributes, advertRecordAttributes, loadingHandler, errorHandler, attributeChangeHandler }: Props) {
    const [professions, setProfessions] = useState<JXTProfession[]>([]);
    const [professionToRolesMap, setProfessionToRolesMap] = useState<Record<string, JXTRole[]>>({});
    const [countries, setCountries] = useState<JXTCountry[]>([]);
    const [countryToLocationsMap, setCountryToLocationsMap] = useState<Record<string, JXTLocation[]>>({});
    const [locationToAreasMap, setLocationToAreasMap] = useState<Record<string, JXTArea[]>>({});
    const [workTypes, setWorkTypes] = useState<JXTWorkTypeOption[]>([]);

    useEffect(() => {
        const getSetupData = async () => {
            loadingHandler && loadingHandler(true);
            const res = await GetJXTBoardSetupData(jobBoardId, advertId, errorHandler);
            if (res) {
                if (res.professions && res.professions.length > 0) setProfessions(res.professions);

                let p2rMap: Record<string, JXTRole[]> = {};
                for (let i = 0; i < res.roles.length; i++) {
                    const r = res.roles[i];
                    if (p2rMap[r.professionRole]) p2rMap[r.professionRole].push(r);
                    else p2rMap[r.professionRole] = [r];
                }

                if (res.countries && res.countries.length > 0) setCountries(res.countries);
                
                let c2lMap: Record<string, JXTLocation[]> = {};
                for (let i = 0; i < res.locations.length; i++) {
                    const l = res.locations[i];
                    if (c2lMap[l.countryLocation]) c2lMap[l.countryLocation].push(l);
                    else c2lMap[l.countryLocation] = [l];
                }
                
                let l2aMap: Record<string, JXTArea[]> = {};
                for (let i = 0; i < res.areas.length; i++) {
                    const a = res.areas[i];
                    if (l2aMap[a.locationArea]) l2aMap[a.locationArea].push(a);
                    else l2aMap[a.locationArea] = [a];
                }

                if (res.worktypes && res.worktypes.length > 0) setWorkTypes(res.worktypes);

                setProfessionToRolesMap(p2rMap);
                setCountryToLocationsMap(c2lMap);
                setLocationToAreasMap(l2aMap);
            }
            loadingHandler && loadingHandler(false);
        };
        getSetupData();
    }, [advertId, errorHandler, jobBoardId, loadingHandler]);

    const atts = useMemo<Record<string, string>>(() => {
        if (attributes) return attributes;
        return {};
    }, [attributes]);

    const recordAtts = useMemo<Record<string, string>>(() => {
        if (advertRecordAttributes) return advertRecordAttributes;
        return {};
    }, [advertRecordAttributes]);

    const profession1 = useMemo(() => atts.Profession1 ?? '', [atts.Profession1]);
    const profession2 = useMemo(() => atts.Profession2 ?? '', [atts.Profession2]);
    const profession3 = useMemo(() => atts.Profession3 ?? '', [atts.Profession3]);
    const role1 = useMemo(() => atts.Role1 ?? '', [atts.Role1]);
    const role2 = useMemo(() => atts.Role2 ?? '', [atts.Role2]);
    const role3 = useMemo(() => atts.Role3 ?? '', [atts.Role3]);
    const country = useMemo(() => atts.Country ?? '', [atts.Country]);
    const location = useMemo(() => atts.Location ?? '', [atts.Location]);
    const area = useMemo(() => atts.Area ?? '', [atts.Area]);
    const workType = useMemo(() => atts.WorkType ?? '', [atts.WorkType]);
    const displaySalary = useMemo(() => atts.DisplaySalary ?? 'off', [atts.DisplaySalary]);
    const residentsOnly = useMemo(() => atts.ResidentsOnly ?? 'off', [atts.ResidentsOnly]);
    const jobTemplate = useMemo(() => atts.JobTemplate ?? '', [atts.JobTemplate]);
    const sector = useMemo(() => atts.Sector ?? '', [atts.Sector]);

    const salaryType = useMemo(() => recordAtts.salaryType ?? '', [recordAtts.salaryType]);
    const salaryFrom = useMemo(() => recordAtts.salaryFrom ?? '', [recordAtts.salaryFrom]);
    const salaryTo = useMemo(() => recordAtts.salaryTo ?? '', [recordAtts.salaryTo]);

    useEffect(() => attributeChangeHandler && attributeChangeHandler(jobBoardId, 'SalaryType', salaryType), [attributeChangeHandler, salaryType, jobBoardId]);
    useEffect(() => attributeChangeHandler && attributeChangeHandler(jobBoardId, 'SalaryMin', salaryFrom), [attributeChangeHandler, salaryFrom, jobBoardId]);
    useEffect(() => attributeChangeHandler && attributeChangeHandler(jobBoardId, 'SalaryMax', salaryTo), [attributeChangeHandler, salaryTo, jobBoardId]);

    const localChangeHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        attributeChangeHandler && attributeChangeHandler(jobBoardId, name, value);
    }, [attributeChangeHandler, jobBoardId]);

    const renderedProfessions = useMemo(() => {
        return professions.map(p => <MenuItem key={p.id} value={`P|${p.id}`}>{p.name}</MenuItem>);
    }, [professions]);

    const renderedRoles1 = useMemo(() => {
        if (profession1) {
            const opts = professionToRolesMap[profession1];
            if (opts) return opts.map(o => <MenuItem key={o.id} value={`R|${o.id}`}>{o.name}</MenuItem>)
        }
        return [];
    }, [profession1, professionToRolesMap]);

    const renderedRoles2 = useMemo(() => {
        if (profession2) {
            const opts = professionToRolesMap[profession2];
            if (opts) return opts.map(o => <MenuItem key={o.id} value={`R|${o.id}`}>{o.name}</MenuItem>)
        }
        return [];
    }, [profession2, professionToRolesMap]);

    const renderedRoles3 = useMemo(() => {
        if (profession3) {
            const opts = professionToRolesMap[profession3];
            if (opts) return opts.map(o => <MenuItem key={o.id} value={`R|${o.id}`}>{o.name}</MenuItem>)
        }
        return [];
    }, [profession3, professionToRolesMap]);

    const renderedCountries = useMemo(() => {
        return countries.map(c => <MenuItem key={c.id} value={`C|${c.id}`}>{c.name}</MenuItem>)
    }, [countries]);

    const renderedLocations = useMemo(() => {
        if (country) {
            const opts = countryToLocationsMap[country];
            if (opts) return opts.map(o => <MenuItem key={o.id} value={`L|${o.id}`}>{o.name}</MenuItem>);
        }
        return [];
    }, [country, countryToLocationsMap]);

    const renderedAreas = useMemo(() => {
        if (location) {
            const opts = locationToAreasMap[location];
            if (opts) return opts.map(o => <MenuItem key={o.id} value={`A|${o.id}`}>{o.name}</MenuItem>);
        }
        return [];
    }, [location, locationToAreasMap]);

    const renderedWorkTypes = useMemo(() => {
        return workTypes.map(w => <MenuItem key={w.id} value={w.id}>{w.name}</MenuItem>)
    }, [workTypes]);

    return (
        <Box display="flex" gap={2} pt={2}>
            <Box display="flex" flexDirection="column" gap={2} width="50%">
                <TextField select label="Profession 1" name="Profession1" value={profession1} onChange={localChangeHandler}>
                    <MenuItem value=""></MenuItem>
                    {renderedProfessions}
                </TextField>
                <TextField select label="Role 1" name="Role1" value={role1} onChange={localChangeHandler}>
                    <MenuItem value=""></MenuItem>
                    {renderedRoles1}
                </TextField>
                <TextField select label="Profession 2" name="Profession2" value={profession2} onChange={localChangeHandler}>
                    <MenuItem value=""></MenuItem>
                    {renderedProfessions}
                </TextField>
                <TextField select label="Role 2" name="Role2" value={role2} onChange={localChangeHandler}>
                    <MenuItem value=""></MenuItem>
                    {renderedRoles2}
                </TextField>
                <TextField select label="Profession 3" name="Profession3" value={profession3} onChange={localChangeHandler}>
                    <MenuItem value=""></MenuItem>
                    {renderedProfessions}
                </TextField>
                <TextField select label="Role 3" name="Role3" value={role3} onChange={localChangeHandler}>
                    <MenuItem value=""></MenuItem>
                    {renderedRoles3}
                </TextField>
                <TextField select label="Country" name="Country" value={country} onChange={localChangeHandler}>
                    <MenuItem value=""></MenuItem>
                    {renderedCountries}
                </TextField>
                <TextField select label="Location" name="Location" value={location} onChange={localChangeHandler}>
                    <MenuItem value=""></MenuItem>
                    {renderedLocations}
                </TextField>
                <TextField select label="Area" name="Area" value={area} onChange={localChangeHandler}>
                    <MenuItem value=""></MenuItem>
                    {renderedAreas}
                </TextField>
            </Box>
            <Box display="flex" flexDirection="column" gap={2} width="50%">
                <TextField select label="Work Type" name="WorkType" value={workType} onChange={localChangeHandler}>
                    <MenuItem value=""></MenuItem>
                    {renderedWorkTypes}
                </TextField>
                <TextField disabled label="Salary Type" value={salaryType} />
                <Box display="flex" gap={2}>
                    <TextField disabled label="Salary From" sx={{ flex: '1 1 0' }} value={salaryFrom} />
                    <TextField disabled label="Salary To" sx={{ flex: '1 1 0' }} value={salaryTo} />
                </Box>
                <TextField select label="Display Salary" name="DisplaySalary" value={displaySalary} onChange={localChangeHandler}>
                    <MenuItem value="on">Yes</MenuItem>
                    <MenuItem value="off">No</MenuItem>
                </TextField>
                <TextField select label="Residents Only" name="ResidentsOnly" value={residentsOnly} onChange={localChangeHandler}>
                    <MenuItem value="on">Yes</MenuItem>
                    <MenuItem value="off">No</MenuItem>
                </TextField>
                <TextField select label="Job Template" name="JobTemplate" value={jobTemplate} onChange={localChangeHandler}>
                    <MenuItem value=""></MenuItem>
                </TextField>
                <TextField select label="Sector" name="Sector" value={sector} onChange={localChangeHandler}>
                    <MenuItem value=""></MenuItem>
                    <MenuItem value="Private">Private</MenuItem>
                    <MenuItem value="Public">Public</MenuItem>
                    <MenuItem value="Charity">Charity</MenuItem>
                </TextField>
            </Box>
        </Box>
    );
}