import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import React, { useMemo } from "react";

interface Props {
    title: string,
    content: string,
    dividerTitle?: boolean,
    disableTextSelection?: boolean,
}

export default function MultiLineTextElement({ title, content, dividerTitle = false, disableTextSelection = false }: Props) {
    const html = useMemo(() => {
        if (content) return content.replaceAll('\n', '<br />');
        return '';
    }, [content]);

    return (
            <Box height="100%" display="flex" flexDirection="column">
                {dividerTitle &&
                    <Box pt='5px'>
                        <Divider>{title}</Divider>
                    </Box>
                }
                {!dividerTitle &&
                    <Typography
                        variant="h6"
                        component="div"
                        fontWeight={600}
                        sx={ disableTextSelection ? undefined : { userSelect: 'text' } }
                    >{title}</Typography>
                }
                <Box overflow="auto" p="10px" flexGrow={1}>
                    <Typography variant="body2" component="div" dangerouslySetInnerHTML={{ __html: html }} sx={ disableTextSelection ? undefined : { userSelect: 'text' } } />
                </Box>
            </Box>
    );
}