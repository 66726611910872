import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { SxProps } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import * as XLSX from "xlsx";
import { GetTRC_Weekly121_PartReportData } from "services/MiscReportsService";
import UserPicker from "components/Pickers/UserPicker";
import { NameIdObj } from "common/models/GenericTypes";
import { ReportDataProcessDateFields } from "util/ReportUtils";

interface Props {
    description: string,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void
}

const filterElementStyle: SxProps = { flex: '1 1 0', mr: 1 };
const ownerSpecialOptions: NameIdObj[] = [ { id: -1, name: 'Me' } ];

export default function TRCWeekly121({ description, loadingHandler, errorHandler }: Props) {
    const [ownerId, setOwnerId] = useState(-1);
    const [ownerName, setOwnerName] = useState('');
    const [start_date, setStartDate] = useState('');
    const [end_date, setEndDate] = useState('');

    useEffect(() => {
        setStartDate(moment().subtract(7, "days").startOf('week').format('YYYY-MM-DD'));
        setEndDate(moment().subtract(7, "days").endOf('week').format('YYYY-MM-DD'));
    }, []);

    const getDataCallback = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        const res = await GetTRC_Weekly121_PartReportData(ownerId, start_date, end_date, errorHandler);
        const m = moment();
        const filename = `Weekly121_${ownerName}_${m.format('YYYYMMDDhhmmss')}.xlsx`;
        const wb = XLSX.utils.book_new();
        if (res && res["data1"]) {
            ReportDataProcessDateFields(res["data1"] as any[]);
            
            const ws = XLSX.utils.json_to_sheet(res["data1"]);
            XLSX.utils.book_append_sheet(wb, ws, "Sales");
        }
        if (res && res["data5"]) {
            ReportDataProcessDateFields(res["data5"] as any[]);
            
            const ws = XLSX.utils.json_to_sheet(res["data5"]);
            XLSX.utils.book_append_sheet(wb, ws, "MMAs");
        }
        if (res && res["data4"]) {
            ReportDataProcessDateFields(res["data4"] as any[]);
            
            const ws = XLSX.utils.json_to_sheet(res["data4"]);
            XLSX.utils.book_append_sheet(wb, ws, "Jobs");
        }

        if (res && res["data2"]) {
            ReportDataProcessDateFields(res["data2"] as any[]);
            
            const ws = XLSX.utils.json_to_sheet(res["data2"]);
            XLSX.utils.book_append_sheet(wb, ws, "Interviews - Reported Week");
        }
        if (res && res["data3"]) {
            ReportDataProcessDateFields(res["data3"] as any[]);
            
            const ws = XLSX.utils.json_to_sheet(res["data3"]);
            XLSX.utils.book_append_sheet(wb, ws, "Interviews - Next Week");
        }
        if (res && res["data9"]) {
            ReportDataProcessDateFields(res["data9"] as any[]);
            
            const ws = XLSX.utils.json_to_sheet(res["data9"]);
            XLSX.utils.book_append_sheet(wb, ws, "Meetings - Last Week");
        }
        if (res && res["data10"]) {
            ReportDataProcessDateFields(res["data10"] as any[]);
            
            const ws = XLSX.utils.json_to_sheet(res["data10"]);
            XLSX.utils.book_append_sheet(wb, ws, "Meetings - Reported Week");
        }
        if (res && res["data11"]) {
            ReportDataProcessDateFields(res["data11"] as any[]);
            
            const ws = XLSX.utils.json_to_sheet(res["data11"]);
            XLSX.utils.book_append_sheet(wb, ws, "Current Contractors");
        }
        if (res && res["data12"]) {
            ReportDataProcessDateFields(res["data12"] as any[]);
            
            const ws = XLSX.utils.json_to_sheet(res["data12"]);
            XLSX.utils.book_append_sheet(wb, ws, "Expiring Contractors");
        }
        if (res && res["data13"]) {
            ReportDataProcessDateFields(res["data13"] as any[]);
            
            const ws = XLSX.utils.json_to_sheet(res["data13"]);
            XLSX.utils.book_append_sheet(wb, ws, "Expired Contractors");
        }
        if (res && res["data14"]) {
            ReportDataProcessDateFields(res["data14"] as any[]);
            
            const ws = XLSX.utils.json_to_sheet(res["data14"]);
            XLSX.utils.book_append_sheet(wb, ws, "Perm Under Warranty");
        }
        if (res && res["data15"]) {
            ReportDataProcessDateFields(res["data15"] as any[]);
            
            const ws = XLSX.utils.json_to_sheet(res["data15"]);
            XLSX.utils.book_append_sheet(wb, ws, "Fixed Term Contractors");
        }
        if (res && res["data7"]) {
            ReportDataProcessDateFields(res["data7"] as any[]);
            
            const ws = XLSX.utils.json_to_sheet(res["data7"]);
            XLSX.utils.book_append_sheet(wb, ws, "Tags - Summary");
        }
        if (res && res["data8"]) {
            ReportDataProcessDateFields(res["data8"] as any[]);
            
            const ws = XLSX.utils.json_to_sheet(res["data8"]);
            XLSX.utils.book_append_sheet(wb, ws, "Tags - Detailed");
        }
        if (res && res["data6"]) {
            ReportDataProcessDateFields(res["data6"] as any[]);
            
            const ws = XLSX.utils.json_to_sheet(res["data6"]);
            XLSX.utils.book_append_sheet(wb, ws, "Misc");
        }
        if (res && res["data16"]) {
            ReportDataProcessDateFields(res["data16"] as any[]);
            
            const ws = XLSX.utils.json_to_sheet(res["data16"]);
            XLSX.utils.book_append_sheet(wb, ws, "Referrals - Sent");
        }
        if (res && res["data17"]) {
            ReportDataProcessDateFields(res["data17"] as any[]);
            
            const ws = XLSX.utils.json_to_sheet(res["data17"]);
            XLSX.utils.book_append_sheet(wb, ws, "Referrals - Received");
        }
        XLSX.writeFile(wb, filename);
        loadingHandler && loadingHandler(false);
    }, [ownerId, ownerName, start_date, end_date, errorHandler, loadingHandler]);

    const onOwnerChange = useCallback((u: NameIdObj | null) => {
        setOwnerId(u ? u.id : 0);
        setOwnerName(u ? u.name : '');
    }, []);

    return (
        <>
            <Box display="flex">
                <Box flex="1 1 0" pr={1}>
                    <UserPicker
                        disableClearable
                        label="Owner"
                        userId={ownerId}
                        onSelect={onOwnerChange}
                        appendToStart={ownerSpecialOptions}
                        includeInactiveUsers={true}
                    />
                </Box>
                <TextField label="From Date" value={start_date.toString()} onChange={({ target }) => setStartDate("" + target.value)} type="date" sx={filterElementStyle} />
                <TextField label="To Date" value={end_date.toString()} onChange={({ target }) => setEndDate("" + target.value)} type="date" sx={filterElementStyle} />
                <Button variant="contained" color="success" onClick={getDataCallback}>Run Report</Button>
            </Box>
            <Typography component="div" variant="h6" mt={2}>Report Description</Typography>
            <Typography component="div" variant="body2">{description}</Typography>
        </>
    );
}