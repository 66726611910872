import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import React, { useEffect, useMemo, useState } from "react";
import { AdvertTemplate } from "common/models/Advert";
import { GetAdvertTemplates } from "services/AdvertsService";

const defaultOption: AdvertTemplate = {
    id: 0,
    name: "Default Template",
    userFullName: "",
    advertID: 0,
    userID: 0,
    deleted: false,
    deletedDate: "",
    deletedby: 0,
    lastUpdatedByID: 0,
    lastUpdatedDate: "",
    dateCreated: ""
};

interface Props {
    templateId: number | null,
    onSelect: (userId: AdvertTemplate | null) => void,
    variant?: "standard" | "filled" | "outlined" | undefined,
    label?: string,
    includeDefaultOption?: boolean,
    disableClearable?: boolean
    errorMessage?: string,
    blurOnSelect?: boolean
    hideLabel?: boolean
}

export default function AdvertTemplatePicker({ templateId, onSelect, variant, label = "Template", includeDefaultOption, disableClearable, errorMessage = '', blurOnSelect, hideLabel = false }: Props) {
    const [options, setOptions] = useState<AdvertTemplate[]>([]);

    useEffect(() => {
        const getTemplates = async () => {
            const res = await GetAdvertTemplates();
            if (res && includeDefaultOption) setOptions([defaultOption, ...res]);
            else if (res) setOptions(res);
        };
        getTemplates();
    }, [includeDefaultOption]);

    const template = useMemo(() => {
        if (templateId === null) return null;
        const template = options.find(t => t.advertID === templateId);
        if (template) return template;
        return null;
    }, [templateId, options]);

    return (
        <Autocomplete
            size="small"
            value={template}
            options={options}
            getOptionLabel={ o => o.name}
            isOptionEqualToValue={ (o, v) => o.advertID === v.advertID }
            onChange={ (e,v) => onSelect(v) }
            disableClearable={disableClearable}
            blurOnSelect={blurOnSelect}
            renderInput={ params => <TextField {...params} error={errorMessage !== ''} helperText={errorMessage} variant={variant} label={hideLabel ? undefined : label} inputProps={{ ...params.inputProps, "data-lpignore": "true" }} /> }
        />
    );
}