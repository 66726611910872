import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { SxProps } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import moment from "moment";
import React, { useCallback, useState } from "react";
import * as XLSX from "xlsx";
import { GetStrategic_CoachingReportData } from "services/MiscReportsService";
import UserPicker from "components/Pickers/UserPicker";
import { NameIdObj } from "common/models/GenericTypes";

interface Props {
    description: string,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void
}

const filterElementStyle: SxProps = { flex: '1 1 0', mr: 1 };
const ownerSpecialOptions: NameIdObj[] = [ { id: -1, name: 'Me' } ];

export default function TRCStrategicCoachingReport({ description, loadingHandler, errorHandler }: Props) {
    const [ownerId, setOwnerId] = useState(-1);
    const [ownerName, setOwnerName] = useState('');

    var cur_time = new Date();
    var year = cur_time.getFullYear();
    var month = cur_time.getUTCMonth() + 1;
    var date = (new Date(year, month, 0)).getDate();
    var month_first_day = year + "-" + (month < 10 ? "0" : "") + month + "-01";
    var month_cur_day = year + "-" + (month < 10 ? "0" : "") + month + "-" + (date < 10 ? "0" : "") + date;

    const [start_date, setStartDate] = useState(month_first_day);
    const [end_date, setEndDate] = useState(month_cur_day);

    const getDataCallback = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        const res = await GetStrategic_CoachingReportData(ownerId, start_date, end_date, errorHandler);
        if (res) {
            const m = moment();
            const filename = `Strategic_Coaching_${ownerName}_${m.format('YYYYMMDDhhmmss')}.xlsx`;
            const wb = XLSX.utils.book_new();
            for (var key of Object.keys(res)) {
                var sheet_name = key;
                sheet_name = sheet_name.replace("sheet_", "");
                sheet_name = sheet_name.replace(/_/g, " ");
                const ws = XLSX.utils.json_to_sheet(res[key]);
                XLSX.utils.book_append_sheet(wb, ws, sheet_name);
            }
            XLSX.writeFile(wb, filename);
        }
        loadingHandler && loadingHandler(false);
    }, [ownerId, ownerName, start_date, end_date, errorHandler, loadingHandler]);

    const onOwnerChange = useCallback((u: NameIdObj | null) => {
        setOwnerId(u ? u.id : 0);
        setOwnerName(u ? u.name : '');
    }, []);

    return (
        <>
            <Box display="flex">
                <Box flex="1 1 0" pr={1}>
                    <UserPicker
                        disableClearable
                        label="Owner"
                        userId={ownerId}
                        onSelect={onOwnerChange}
                        appendToStart={ownerSpecialOptions}
                        includeInactiveUsers={true}
                    />
                </Box>
                <TextField label="From Date" value={start_date.toString()} defaultValue={month_first_day} onChange={({ target }) => setStartDate("" + target.value)} type="date" sx={filterElementStyle} />
                <TextField label="To Date" value={end_date.toString()} defaultValue={month_cur_day} onChange={({ target }) => setEndDate("" + target.value)} type="date" sx={filterElementStyle} />
                <Button variant="contained" color="success" onClick={getDataCallback}>Run Report</Button>
            </Box>
            <Typography component="div" variant="h6" mt={2}>Report Description</Typography>
            <Typography component="div" variant="body2">{description}</Typography>
        </>
    );
}