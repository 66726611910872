import { GridColDef, GridRenderCellParams, GridRowSelectionModel, useGridApiRef } from "@mui/x-data-grid-premium";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import GridWithStateWrapper from "../GridWidthStateWrapper";
import moment from "moment";
import { companyHasSinglePermission, userHasSinglePermission } from "util/PermissionsUtils";
import { Permission } from "common/models/Permissions";
import { MenuOptionDefinition } from "common/models/MenuDefinition";
import ActionMenu from "../Menus/ActionMenu";
import { PlacementRate } from "common/models/PlacementRate";
import { GetPlacementFinancials } from "services/PlacementsService";
import Box from "@mui/material/Box";

interface Props {
    source: 'placement-record',
    placementId?: number,
    gridName: string,
    setRowCountHandler?: (count: number) => void,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
    successHandler?: (message: string) => void
}

const linkStyle: React.CSSProperties = { color: 'inherit', textDecoration: 'underline' };

export default function FinancialsGridComponent({ source, placementId, setRowCountHandler, loadingHandler, errorHandler, successHandler, gridName }: Props) {
    const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
    const [rows, setRows] = useState<PlacementRate[]>([]);
    const apiRef = useGridApiRef();

    const userCanApprovePlacements = useMemo(() => userHasSinglePermission(Permission.FinancialController), []);

    const getData = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        let res: PlacementRate[] | null = [];
        if (source === 'placement-record' && placementId) res = await GetPlacementFinancials(placementId, errorHandler);
        if (res) {
            setRowCountHandler && setRowCountHandler(res.length)
            setRows(res);
        }
        loadingHandler && loadingHandler(false);
    }, [loadingHandler, errorHandler, setRowCountHandler, source, placementId]);

    useEffect(() => {
        getData();
    }, [getData]);

    const columns = useMemo<GridColDef[]>(() => {

        const dateRenderer = (params: GridRenderCellParams) => {
            if (params.value) {
                if (params.value === '0001-01-01T00:00:00')
                    return "Never";
                return moment(params.value).format('DD MMM YYYY');
            }
        };

        const linkToChangeRequestRenderer = (params: GridRenderCellParams) => {
            if (params.value) {
                return (
                    <Link
                        to={`/placements/change-requests/${params.value}`}
                        style={linkStyle}
                    >{params.value}</Link>
                );
            }
            return "";
        }

        const chargeRateRenderer = (params: GridRenderCellParams) => {
            return params.value + " " + params.row.chargeRateUnitsType;
        }

        const payRateRenderer = (params: GridRenderCellParams) => {
            return params.value + " " + params.row.payRateUnitsType;
        }

        const onCostsRenderer = (params: GridRenderCellParams) => {
            return params.value + " " + params.row.onCostsUnitsType;
        }

        const currentRenderer = (params: GridRenderCellParams) => {
            if (params.value) {
                return "Yes";
            }
            return "No";
        };

        return [
            { headerName: 'Rate ID', field: 'id', width: 75, headerAlign: 'center', align: 'center' },
            { headerName: 'Type', field: 'placementRateType', headerAlign: 'center', align: 'center', width: 100 },
            { headerName: 'Charge Rate', field: 'chargeRate', headerAlign: 'center', align: 'center', width: 150, renderCell: chargeRateRenderer },
            { headerName: 'Pay Rate', field: 'payRate', headerAlign: 'center', align: 'center', width: 150, renderCell: payRateRenderer },
            { headerName: 'On Costs', field: 'onCosts', headerAlign: 'center', align: 'center', width: 150, renderCell: onCostsRenderer },
            { headerName: 'Margin', field: 'margin', headerAlign: 'center', align: 'center', width: 150 },
            { headerName: 'Start Date', field: 'startDate', headerAlign: 'center', align: 'center', width: 120, renderCell: dateRenderer },
            { headerName: 'End Date', field: 'endDate', headerAlign: 'center', align: 'center', width: 120, renderCell: dateRenderer },
            { headerName: 'Hours Per Day', field: 'hoursPerDay', headerAlign: 'center', align: 'center', width: 120 },
            { headerName: 'Days Per Week', field: 'daysPerWeek', headerAlign: 'center', align: 'center', width: 120 },
            { headerName: 'Current', field: 'current', headerAlign: 'center', align: 'center', width: 120, renderCell: currentRenderer },
            { headerName: 'Change Request', field: 'changeRequestID', headerAlign: 'center', align: 'center', width: 120, renderCell: linkToChangeRequestRenderer },
            { headerName: 'Consultant 1', field: 'consultantInfo1', headerAlign: 'center', align: 'center', width: 250 },
            { headerName: 'Consultant 2', field: 'consultantInfo2', headerAlign: 'center', align: 'center', width: 250 },
            { headerName: 'Consultant 3', field: 'consultantInfo3', headerAlign: 'center', align: 'center', width: 250 },
            { headerName: 'Consultant 4', field: 'consultantInfo4', headerAlign: 'center', align: 'center', width: 250 },
            { headerName: 'Consultant 5', field: 'consultantInfo5', headerAlign: 'center', align: 'center', width: 250 },

        ];
    }, []);

    const exportToExcel = useCallback(() => {
        const api = apiRef.current;
        if (api) api.exportDataAsExcel();
    }, [apiRef]);

    const canExport = useMemo(() => companyHasSinglePermission(Permission.PlacementsExport) && userHasSinglePermission(Permission.PlacementsExport), []);

    const actionMenuDefinitions = useMemo<MenuOptionDefinition[]>(() => {
        return [
            { label: 'Export', type: 'action', action: exportToExcel, allow: () => canExport },
        ];
    }, [canExport, exportToExcel]);

    const gridActions = useMemo(() => {
        if (userCanApprovePlacements || canExport) {
            return <ActionMenu color="secondary" label="List Actions" definition={actionMenuDefinitions} />;
        }
        return undefined;
    }, [canExport, actionMenuDefinitions, userCanApprovePlacements]);

    return (
        <>
            {Boolean(gridActions) &&
                <Box pb="10px" ml="auto">
                    {gridActions}
                </Box>
            }
            <GridWithStateWrapper
                gridName={gridName}
                rows={rows}
                columns={columns}
                apiRef={apiRef}
                density="compact"
                checkboxSelection
                disableRowSelectionOnClick
                rowSelectionModel={selectionModel}
                onRowSelectionModelChange={ sm => setSelectionModel(sm) }
                pagination
                pageSizeOptions={[100,250,500,1000]}
            />
        </>
    );
}
