import React, { useCallback, useEffect, useMemo, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import { GridColDef, GridRenderCellParams, useGridApiRef } from "@mui/x-data-grid-premium";

import { ActivitySettings } from "../../common/models/Configuration/Activities";
import OnlyTitleSummaryBar from "../../components/SummaryBars/OnlyTitleSummaryBar";
import PageContentLayout from "../../layouts/PageContentLayout";
import { GetActivitiesSettings, UpdateActivitySettings } from "../../services/ConfigurationService";
import GridWithStateWrapper from "../../components/GridWidthStateWrapper";
import { NonAdminEditableActivityTypes } from "../../util/Definitions/Activities";
import UnsavedChangesDialog from "../../components/Dialogs/UnsavedChangesDialog";
import Autocomplete from "@mui/material/Autocomplete";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import RWTextFieldComponent from "../../components/RWTextFieldComponent";
import { NameIdObj } from "../../common/models/GenericTypes";
import { GetJobCandidateStatusesForLookup } from "../../services/ConfigurationService";

interface Props {
    setSummaryBar?: (summaryBar: JSX.Element) => void
}

const SummaryBar = <OnlyTitleSummaryBar title="Configuration > Activities" browserTabTitle="Activities > Configuration" />;
const categories = ["Misc / Other", "Calls/Notes", "Meetings", "Messages", "Submissions", "Interviews"];

export default function Activities({ setSummaryBar }: Props) {
    useEffect(() => { setSummaryBar && setSummaryBar(SummaryBar); }, [setSummaryBar]);
    const [isLoading, setIsLoading] = useState(false);
    const [rows, setRows] = useState<ActivitySettings[]>([]);
    const [workflowStatuses, setWorkflowStatuses] = useState<NameIdObj[]>([]);
    const [initialDialogData, setInitialDialogData] = useState<ActivitySettings | null>(null);
    const [dialogData, setDialogData] = useState<ActivitySettings | null>(null);
    const [selectedFollowUp, setSelectedFollowUp] = useState<ActivitySettings | null>(null);
    const [showUnsavedChangesDialog, setShowUnsavedChangesDialog] = useState(false);
    const apiRef = useGridApiRef();

    useEffect(() => {
        const getData = async () => {
            setIsLoading(true);
            const statuses = await GetJobCandidateStatusesForLookup();
            const data = await GetActivitiesSettings();
            if(data) setRows(data);

            if(statuses)
                setWorkflowStatuses(statuses);

            setIsLoading(false);
        };
        getData();
    }, []);

    useEffect(() => {
        if (initialDialogData && initialDialogData.defaultFollowUpActivityTypeID) {
            const f = rows.find(r => r.id === initialDialogData.defaultFollowUpActivityTypeID);
            if (f) {
                setSelectedFollowUp(f);
                return;
            }
        }
        setSelectedFollowUp(null);
    }, [initialDialogData, rows]);

    const columns = useMemo<GridColDef[]>(() => {
        const dbNameRenderer = (params: GridRenderCellParams) => {
            if (NonAdminEditableActivityTypes.includes(params.row.id))
                return <>{params.value}</>;

            return (
                <span
                    onClick={() => {
                        setDialogData(params.row);
                        setInitialDialogData(params.row);
                    }}
                    style={{ cursor: 'pointer', textDecoration: 'underline' }}
                >{params.value}</span>
            );
        };

        return [
            { field: 'id', headerName: 'ID' },
            { field: 'name', headerName: 'Database Name', width: 200, renderCell: dbNameRenderer },
            { field: 'customName', headerName: 'Screen Name', width: 200 },
            { field: 'category', headerName: 'Category', width: 200, valueGetter: value => categories[value] },
            { field: 'workflowStatusName', headerName: 'Workflow Status', width: 200 },
        ];
    }, []);

    const onChangeString = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setDialogData(prev => ( prev ? {...prev, [name]: value} : null));
    };

    const onChangeNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        const numValue = +value;
        if(name === 'workflowStatus') {
            const opt = workflowStatuses.find(w => w.id === numValue);
            const statusName = opt ? opt.name : '';
            setDialogData(prev => ( prev ? {...prev, [name]: numValue, workflowStatusName: statusName} : null));
        }
        else
            setDialogData(prev => ( prev ? {...prev, [name]: numValue} : null));
    };

    const onFollowUpChange = useCallback((event: React.SyntheticEvent<Element, Event>, value: ActivitySettings | null) => {
        setDialogData(prev => prev ? {...prev, defaultFollowUpActivityTypeID: value ? value.id : 0} : null);
        setSelectedFollowUp(value);
    }, []);

    const onSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = e.target;
        setDialogData(prev => ( prev ? {...prev, [name]: checked} : null));
    };

    const hasChanges = useMemo(() => {
        if (dialogData && initialDialogData) {
            if (dialogData.customName !== initialDialogData.customName) return true;
            if (dialogData.category !== initialDialogData.category) return true;
            if (dialogData.defaultStatus !== initialDialogData.defaultStatus) return true;
            if (dialogData.workflowStatus !== initialDialogData.workflowStatus) return true;
            if (dialogData.defaultFollowUpActivityTypeID !== initialDialogData.defaultFollowUpActivityTypeID) return true;
            if (dialogData.isCandidatesEnabled !== initialDialogData.isCandidatesEnabled) return true;
            if (dialogData.isClientsEnabled !== initialDialogData.isClientsEnabled) return true;
            if (dialogData.isContactsEnabled !== initialDialogData.isContactsEnabled) return true;
            if (dialogData.isJobsEnabled !== initialDialogData.isJobsEnabled) return true;
            if (dialogData.isOpportunitiesEnabled !== initialDialogData.isOpportunitiesEnabled) return true;
            if (dialogData.isPlacementsEnabled !== initialDialogData.isPlacementsEnabled) return true;
        }
        return false;
    }, [dialogData, initialDialogData]);

    const closeEditDialogHandler = useCallback(() => {
        setDialogData(null);
        setInitialDialogData(null);
        setShowUnsavedChangesDialog(false);
    }, []);

    const saveChanges = useCallback(async () => {
        if(dialogData){
            setIsLoading(true);
            const res = await UpdateActivitySettings(dialogData);
            if(res) {
                apiRef.current.updateRows([dialogData]);
            }
            closeEditDialogHandler();
            setIsLoading(false);
        }
    }, [dialogData, apiRef, closeEditDialogHandler]);

    const closeHandlerWithConfirmation = useMemo(() => hasChanges ? () => setShowUnsavedChangesDialog(true) : closeEditDialogHandler, [hasChanges, closeEditDialogHandler]);

    return (
        <>
            {dialogData &&
                <Dialog open={true} maxWidth="md" fullWidth onClose={ closeHandlerWithConfirmation }>
                    <DialogTitle>Edit Activity - {dialogData.name} ({dialogData.id})</DialogTitle>
                    <DialogContent dividers>
                        <Grid container columnSpacing={2}>
                            <Grid item xs={12} md={8}>
                                <Stack spacing={3}>
                                    <RWTextFieldComponent
                                        label="Screen Name"
                                        name="customName"
                                        value={dialogData.customName}
                                        onChange={onChangeString}
                                    />
                                    <TextField
                                        select
                                        label="Category"
                                        name="category"
                                        value={dialogData.category.toString()}
                                        onChange={onChangeNumber}
                                    >
                                        <MenuItem value="0">Misc / Other</MenuItem>
                                        <MenuItem value="1">Calls/Notes</MenuItem>
                                        <MenuItem value="2">Meetings</MenuItem>
                                        <MenuItem value="4">Submissions</MenuItem>
                                        <MenuItem value="5">Interviews</MenuItem>
                                    </TextField>
                                    <TextField
                                        select
                                        label="Default Status"
                                        name="defaultStatus"
                                        value={dialogData.defaultStatus.toString()}
                                        onChange={onChangeNumber}
                                    >
                                        <MenuItem value="1">Open</MenuItem>
                                        <MenuItem value="2">Closed</MenuItem>
                                    </TextField>
                                    <TextField
                                        select
                                        label="Workflow Status"
                                        name="workflowStatus"
                                        value={dialogData.workflowStatus.toString()}
                                        onChange={onChangeNumber}
                                    >
                                        <MenuItem value="0">None</MenuItem>
                                        {workflowStatuses.map((s) => (
                                            <MenuItem key={s.id} value={s.id.toString()}>{s.name}</MenuItem>
                                        ))}
                                    </TextField>
                                    <Autocomplete
                                        fullWidth
                                        options={rows}
                                        value={selectedFollowUp}
                                        getOptionLabel={ o => o.customName ? o.customName : o.name }
                                        isOptionEqualToValue={ (o, v) => o.id === v.id }
                                        onChange={ onFollowUpChange }
                                        renderInput={ params => <TextField {...params} label="Follow Up" inputProps={{ ...params.inputProps, "data-lpignore": "true" }} /> }
                                        renderOption={(props, option) => (
                                            <ListItem {...props} key={option.id}>
                                                <ListItemText primary={option.customName ? option.customName : option.name} secondary={option.name} />
                                            </ListItem>
                                        )}
                                    />
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FormControl component="fieldset" variant="standard">
                                    <FormLabel component="legend">Module Visibility</FormLabel>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={<Switch checked={dialogData.isCandidatesEnabled} name="isCandidatesEnabled" onChange={onSwitchChange} />}
                                            label="Candidates"
                                        />
                                        <FormControlLabel
                                            control={<Switch checked={dialogData.isClientsEnabled} name="isClientsEnabled" onChange={onSwitchChange} />}
                                            label="Clients"
                                        />
                                        <FormControlLabel
                                            control={<Switch checked={dialogData.isContactsEnabled} name="isContactsEnabled" onChange={onSwitchChange} />}
                                            label="Contacts"
                                        />
                                        <FormControlLabel
                                            control={<Switch checked={dialogData.isJobsEnabled} name="isJobsEnabled" onChange={onSwitchChange} />}
                                            label="Jobs"
                                        />
                                        <FormControlLabel
                                            control={<Switch checked={dialogData.isOpportunitiesEnabled} name="isOpportunitiesEnabled" onChange={onSwitchChange} />}
                                            label="Opportunities"
                                        />
                                        <FormControlLabel
                                            control={<Switch checked={dialogData.isPlacementsEnabled} name="isPlacementsEnabled" onChange={onSwitchChange} />}
                                            label="Placements"
                                        />
                                    </FormGroup>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="error" onClick={ closeHandlerWithConfirmation }>Cancel</Button>
                        <Button variant="contained" color="success" disabled={!hasChanges} onClick={saveChanges}>Save</Button>
                    </DialogActions>
                </Dialog>
            }
            <UnsavedChangesDialog
                open={showUnsavedChangesDialog}
                discardChangesHandler={closeEditDialogHandler}
                cancelHandler={() => setShowUnsavedChangesDialog(false)}
                saveChangesHandler={saveChanges}
                message="If you continue any changes made will be lost. Are you sure you wish to continue?"
                hideBackdrop
            />
            <PageContentLayout title="Activity Settings" showLoading={isLoading}>
                <GridWithStateWrapper
                    gridName="configuration/activities"
                    apiRef={apiRef}
                    rows={rows}
                    columns={columns}
                    disableRowSelectionOnClick
                    pagination={true}
                    density="compact"
                    pageSizeOptions={[100,250,500,1000]}
                />
            </PageContentLayout>
        </>
    );
}