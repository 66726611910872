import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";
import CountryPicker from "components/Pickers/CountryPicker";
import RWTextFieldComponent from "components/RWTextFieldComponent";

interface Props {
    address1: string,
    address2: string,
    suburb: string,
    state: string,
    postcode: string,
    country: string,
    onChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void,
    onCountryChangeHandler: (c: string | null) => void,
    loadingHandler?: (isLoading: boolean) => void,
    successHandler?: (message: string) => void,
    errorHandler?: (message: string) => void,
}

export default function EditableCandidateLocationElement({ address1, address2, suburb, state, postcode, country, onChangeHandler, onCountryChangeHandler, loadingHandler, errorHandler, successHandler }: Props) {
    return (
        <Box height="100%" display="flex" flexDirection="column" overflow="auto" px="5px">
            <Box display="flex" height="40px">
                <Typography width="30%" variant="h6" fontWeight={600} component="div" /* pt="8px" */>Address</Typography>
            </Box>
            <Box display="flex" height="40px">
                <Typography width="30%" variant="body1" fontWeight={600} component="div" sx={{ userSelect: 'text' }}>Address 1</Typography>
                <Box width="70%">
                    <RWTextFieldComponent value={address1} variant="standard" name="address1" onChange={onChangeHandler} />
                </Box>
            </Box>
            <Box display="flex" height="40px">
                <Typography width="30%" variant="body1" fontWeight={600} component="div" sx={{ userSelect: 'text' }}>Address 2</Typography>
                <Box width="70%">
                    <RWTextFieldComponent value={address2} variant="standard" name="address2" onChange={onChangeHandler} />
                </Box>
            </Box>
            <Box display="flex" height="40px">
                <Typography width="30%" variant="body1" fontWeight={600} component="div" sx={{ userSelect: 'text' }}>Suburb</Typography>
                <Box width="70%">
                    <RWTextFieldComponent value={suburb} variant="standard" name="suburb" onChange={onChangeHandler} />
                </Box>
            </Box>
            <Box display="flex" height="40px">
                <Typography width="30%" variant="body1" fontWeight={600} component="div" sx={{ userSelect: 'text' }}>State</Typography>
                <Box width="70%">
                    <RWTextFieldComponent value={state} variant="standard" name="state" onChange={onChangeHandler} />
                </Box>
            </Box>
            <Box display="flex" height="40px">
                <Typography width="30%" variant="body1" fontWeight={600} component="div" sx={{ userSelect: 'text' }}>Postcode</Typography>
                <Box width="70%">
                    <RWTextFieldComponent value={postcode} variant="standard" name="postcode" onChange={onChangeHandler} />
                </Box>
            </Box>
            <Box display="flex" height="40px">
                <Typography width="30%" variant="body1" fontWeight={600} component="div" sx={{ userSelect: 'text' }}>Country</Typography>
                <Box width="70%">
                    <CountryPicker fullWidth variant="standard" hideLabel code={country} onSelectCallback={onCountryChangeHandler} />
                </Box>
            </Box>
        </Box>
    );
}