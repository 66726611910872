import IconButton from "@mui/material/IconButton";
import { GridColDef, GridRenderCellParams, GridRowSelectionModel, GridValueGetter, useGridApiRef } from "@mui/x-data-grid-premium";
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Placement } from "common/models/Placements";
import { BulkApprovePlacements, GetMyContractorsPlacements, GetAllContractorsPlacements, GetMyExpiringContractorsPlacements, GetAllExpiringContractorsPlacements, GetMyRecentlyExpiredContractorsPlacements, GetAllRecentlyExpiredContractorsPlacements, GetPlacementsByTags, GetMyPlacements, PlacementLookup, SearchPlacements, GetRelatedPlacements, GetAwaitingApproval, GetMyAwaitingApproval } from "services/PlacementsService";
import GridWithStateWrapper from "../GridWidthStateWrapper";
import PreviewLoaderComponent from "../Previews/PreviewLoader";
import moment from "moment";
import { companyHasSinglePermission, userHasSinglePermission } from "util/PermissionsUtils";
import { Permission } from "common/models/Permissions";
import ConfirmationDialog from "../Dialogs/Generic/ConfirmationDialog";
import { PreviewEntityType } from "common/models/Previews/Previews";
import TagsManagementDialog from "../Dialogs/TagsManagementDialog";
import { MenuOptionDefinition } from "common/models/MenuDefinition";
import ActionMenu from "../Menus/ActionMenu";
import SendEmailDialog from "../Dialogs/Messages/SendEmailDialog";
import { EmailRecipient } from "common/models/EmailRecipient";
import SendSmsDialog from "../Dialogs/Messages/SendSmsDialog";
import { GetClientPlacements } from "services/ClientsService";
import { GetContactPlacements } from "services/ContactsService";
import { GetJobPlacements } from "services/JobsService";
import { GetSiteContractors } from "services/SitesService";
import { GetCandidatePlacements } from "services/CandidatesService";
import { PlacementSearch } from "common/models/Search/PlacementSearch";
import Box from "@mui/material/Box";
import { GetCustomFieldsByEntity_OnlyActive, GetPredefinedValues } from "services/CustomFieldsService";
import { CustomField, CustomFieldPredefinedValue } from "common/models/Configuration/CustomFields";
import { GetPlacementsByAutomationFilters } from "services/AutomationService";
import { AutomationPreviewData } from "common/models/Configuration/AutomationPreviewData";

interface Props {
    source: 'dashboard' | 'client-record' | 'contact-record' | 'site-record' | 'job-record' | 'placement-record' | 'candidate-record' | 'my-placements' | 'my-contractors' | 'all-contractors' | 'my-expiring-contractors' | 'all-expiring-contractors' | 'my-recently-expired-contractors' | 'all-recently-expired-contractors' | 'tags' | 'search' | 'lookup' | 'my-awaiting-approval' | 'all-awaiting-approval' | 'automation',
    sourceId?: number,
    gridName: string,
    tagLabel?: string,
    tagTypeId?: number,
    taggedBy?: number,
    lookupTerm?: string,
    searchData?: PlacementSearch,
    filterJSON?: string,
    setRowCountHandler?: (count: number) => void,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
    successHandler?: (message: string) => void,
    fromDialog?: boolean,
    fromDialogPreviewData?: AutomationPreviewData
}

interface CustomFieldWithPredefinedValues extends CustomField {
    values?: CustomFieldPredefinedValue[],
}

const linkStyle: React.CSSProperties = { color: 'inherit', textDecoration: 'underline' };
const entityTypeId = 5;

export default function PlacementsGridComponent({ source, sourceId, tagLabel = '', tagTypeId = 0, taggedBy = 0, lookupTerm = '', searchData,filterJSON = '',fromDialog = false,fromDialogPreviewData, setRowCountHandler, loadingHandler, errorHandler, successHandler, gridName }: Props) {
    const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
    const [rows, setRows] = useState<Placement[]>([]);
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    const [showTagsPrevewNoDelay, setShowTagsPreviewNoDelay] = useState(false);
    const [previewType, setPreviewType] = useState<PreviewEntityType | ''>('');
    const [previewRecordId, setPreviewRecordId] = useState(0);
    const [isPreviewTags, setIsPreviewTags] = useState(false);
    const [previewRecordName, setPreviewRecordName] = useState('');
    const [isApproveDialogOpen, setIsApproveDialogOpen] = useState(false);
    const [sendEmailEntityType, setSendEmailEntityType] = useState<number | null>(null);
    const [emailRecipients, setEmailRecipients] = useState<EmailRecipient[]>([]);
    const [sendSmsEntityType, setSendSmsEntityType] = useState<number | null>(null);
    const [taggingRecords, setTaggingRecords] = useState<number[]>([]);
    const [activeFields, setActiveFields] = useState<CustomFieldWithPredefinedValues[]>([]);
    const apiRef = useGridApiRef();

    const userCanApprovePlacements = useMemo(() => userHasSinglePermission(Permission.FinancialController), []);
    const userCanAddEditPlacements = useMemo(() => companyHasSinglePermission(Permission.PlacementsAddEdit) && userHasSinglePermission(Permission.PlacementsAddEdit), []);
    const userCanSendEmail = useMemo(() => userHasSinglePermission(Permission.MessageCentre) && companyHasSinglePermission(Permission.MessageCentre), []);
    const userCanSendSms = useMemo(() => userHasSinglePermission(Permission.MessageCentreSms) && companyHasSinglePermission(Permission.MessageCentreSms), []);
    const [loadingForDialog, setLoadingForDialog] = useState<boolean>(false);

    const formatNumber = (value: number) => {
        return value.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 });
    };

    const getData = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        let res: Placement[] | null = [];
        if (source === 'dashboard') res = await GetMyContractorsPlacements(errorHandler);
        if (source === 'my-contractors') res = await GetMyContractorsPlacements(errorHandler);
        if (source === 'all-contractors') res = await GetAllContractorsPlacements(errorHandler);
        if (source === 'my-awaiting-approval') res = await GetMyAwaitingApproval(errorHandler);
        if (source === 'all-awaiting-approval') res = await GetAwaitingApproval(errorHandler);
        if (source === 'my-expiring-contractors') res = await GetMyExpiringContractorsPlacements(errorHandler);
        if (source === 'all-expiring-contractors') res = await GetAllExpiringContractorsPlacements(errorHandler);
        if (source === 'my-recently-expired-contractors') res = await GetMyRecentlyExpiredContractorsPlacements(errorHandler);
        if (source === 'all-recently-expired-contractors') res = await GetAllRecentlyExpiredContractorsPlacements(errorHandler);
        if (source === 'client-record' && sourceId) res = await GetClientPlacements(sourceId, errorHandler);
        if (source === 'contact-record' && sourceId) res = await GetContactPlacements(sourceId, errorHandler);
        if (source === 'site-record' && sourceId) res = await GetSiteContractors(sourceId, errorHandler);
        if (source === 'job-record' && sourceId) res = await GetJobPlacements(sourceId, errorHandler);
        if (source === 'placement-record' && sourceId) res = await GetRelatedPlacements(sourceId, errorHandler);
        if (source === 'candidate-record' && sourceId) res = await GetCandidatePlacements(sourceId, errorHandler);
        if (source === 'my-placements') res = await GetMyPlacements(errorHandler);
        if (source === 'tags') res = await GetPlacementsByTags(tagTypeId, taggedBy, tagLabel, errorHandler);
        if (source === 'search' && searchData) res = await SearchPlacements(searchData, errorHandler);
        if (source === 'lookup' && lookupTerm) res = await PlacementLookup(lookupTerm, 0, errorHandler);
        if (source === 'automation'&& fromDialogPreviewData)  {
            res = await GetPlacementsByAutomationFilters(filterJSON,fromDialogPreviewData,errorHandler);
            setLoadingForDialog(true);
        }

        if (res) {
            setRowCountHandler && setRowCountHandler(res.length)
            setRows(res);
        }
        loadingHandler && loadingHandler(false);
    }, [loadingHandler, source, errorHandler, sourceId, tagTypeId, taggedBy, tagLabel, searchData, lookupTerm, fromDialogPreviewData, filterJSON, setRowCountHandler]);

    useEffect(() => {
        if(!loadingForDialog) {
            getData();
        }
    }, [getData,loadingForDialog]);

    useEffect(() => {
        const getActiveFields = async () => {
            const customFields = await GetCustomFieldsByEntity_OnlyActive(entityTypeId);
            if (customFields) {
                let udfDefinitions: CustomFieldWithPredefinedValues[] = [];
                for (let i = 0; i < customFields.length; i++) {
                    const u = customFields[i];
                    if (u.usePredefinedValues) {
                        const vals = await GetPredefinedValues(u.id);
                        if (vals) udfDefinitions.push({ ...u, values: vals });
                        else udfDefinitions.push(u);
                        continue;
                    }
                    
                    udfDefinitions.push(u);
                }
                setActiveFields(udfDefinitions);
            }
        };
        getActiveFields();
    }, []);

    const columns = useMemo<GridColDef[]>(() => {
        const dateValueGetter: GridValueGetter<Placement, any, undefined, string> = (value) => {
            if (value) {
                const m = moment(value);
                if (m.isValid() && m.get('year') > 1) {
                    return m.toDate();
                }
            }
        };

        const dateRenderer = (params: GridRenderCellParams) => {
            if (params.value) {
                return moment(params.value).format('DD MMM YYYY');
            }
            if (params.field === 'endDate') return 'Never';
        };

        const linkToPlacementRenderer = (params: GridRenderCellParams) => {
            return (
                <Link
                    to={`/placements/${params.id}`}
                    style={ linkStyle }
                    onMouseEnter={ () => handlePreviewHover('placement', params.row.id) }
                    onMouseLeave={ handlePreviewClose }
                    target={ source === "search" ? "_blank" : "_self" }
                >{params.value}</Link>
            );
        }

        const linkToParentPlacementRenderer = (params: GridRenderCellParams) => {
            if (params.row.parentPlacementID !== 0) {
                return (
                    <Link
                        to={`/placements/${params.row.parentPlacementID}`}
                        style={linkStyle}
                        onMouseEnter={() => handlePreviewHover('placement', params.row.parentPlacementID)}
                        onMouseLeave={handlePreviewClose}
                    >{params.value}</Link>
                );
            } else {
                return '';
            }
        }

        const tagsRenderer = (params: GridRenderCellParams) => {
            if (userCanAddEditPlacements) {
                return (
                    <IconButton
                        size="small"
                        onMouseEnter={ () => handlePreviewHover('placement', params.row.id, true, params.row.jobTitle) }
                        onMouseLeave={ handlePreviewClose }
                        onClick={ () => setTaggingRecords([params.row.id]) }
                    >
                        {params.value
                            ? <LocalOfferIcon style={{ color: '#f209a6' }} />
                            : <LocalOfferOutlinedIcon />
                        }
                    </IconButton>
                );
            }
            return (
                <IconButton
                    size="small"
                    onMouseEnter={() => handlePreviewHover('placement', params.row.id, true, params.row.jobTitle)}
                    onMouseLeave={handlePreviewClose}
                    onClick={() => handlePreviewHover('placement', params.row.id, true, params.row.jobTitle, true)}
                >
                    {params.value
                        ? <LocalOfferIcon />
                        : <LocalOfferOutlinedIcon />
                    }
                </IconButton>
            );
        };

        const linkToClientRenderer = (params: GridRenderCellParams) => {
            const clientId = params.row.clientID;
            if (clientId) {
                return (
                    <Link
                        to={`/clients/${clientId}`}
                        style={ linkStyle }
                        onMouseEnter={ () => handlePreviewHover('client', clientId) }
                        onMouseLeave={ handlePreviewClose }
                    >{params.value}</Link>
                );
            }
            return params.value;
        }

        const linkToContactRenderer = (params: GridRenderCellParams) => {
            const contactId = params.row.contactID;
            if (contactId) {
                return (
                    <Link
                        to={`/contacts/${contactId}`}
                        style={ linkStyle }
                        onMouseEnter={ () => handlePreviewHover('contact', contactId) }
                        onMouseLeave={ handlePreviewClose }
                    >{params.value}</Link>
                );
            }
            return params.value;
        }

        const linkToTimesheetApprover1Renderer = (params: GridRenderCellParams) => {
            const contactId = params.row.timesheetApprover1;
            if (contactId) {
                return (
                    <Link
                        to={`/contacts/${contactId}`}
                        style={linkStyle}
                        onMouseEnter={() => handlePreviewHover('contact', contactId)}
                        onMouseLeave={handlePreviewClose}
                    >{params.value}</Link>
                );
            }
            return params.value;
        }

        const linkToTimesheetApprover2Renderer = (params: GridRenderCellParams) => {
            const contactId = params.row.timesheetApprover2;
            if (contactId) {
                return (
                    <Link
                        to={`/contacts/${contactId}`}
                        style={linkStyle}
                        onMouseEnter={() => handlePreviewHover('contact', contactId)}
                        onMouseLeave={handlePreviewClose}
                    >{params.value}</Link>
                );
            }
            return params.value;
        }

        const linkToSignatoryRenderer = (params: GridRenderCellParams) => {
            const contactId = params.row.signatoryContactID;
            if (contactId) {
                return (
                    <Link
                        to={`/contacts/${contactId}`}
                        style={linkStyle}
                        onMouseEnter={() => handlePreviewHover('contact', contactId)}
                        onMouseLeave={handlePreviewClose}
                    >{params.value}</Link>
                );
            }
            return params.value;
        }

        const linkToBillingContactRenderer = (params: GridRenderCellParams) => {
            const contactId = params.row.billingContactContactID;
            if (contactId) {
                return (
                    <Link
                        to={`/contacts/${contactId}`}
                        style={linkStyle}
                        onMouseEnter={() => handlePreviewHover('contact', contactId)}
                        onMouseLeave={handlePreviewClose}
                    >{params.value}</Link>
                );
            }
            return params.value;
        }

        const linkToCandidateRenderer = (params: GridRenderCellParams) => {
            const candidateId = params.row.candidateID;
            if (candidateId) {
                return (
                    <Link
                        to={`/candidates/${candidateId}`}
                        style={ linkStyle }
                        onMouseEnter={ () => handlePreviewHover('candidate', candidateId) }
                        onMouseLeave={ handlePreviewClose }
                    >{params.value}</Link>
                );
            }
            return params.value;
        };

        const chargeRateRenderer = (params: GridRenderCellParams) => {
            if (params.row.placementTypeID === 2 || params.row.placementTypeID === 4 || params.row.placementTypeID === 5) {
                return params.row.chargeRate + ' ' + params.row.chargeRateUnitsName;
            }
        };

        const payRenderer = (params: GridRenderCellParams) => {
            if (params.row.placementTypeID === 2 || params.row.placementTypeID === 4 ) {
                return params.row.payRate + ' ' + params.row.payRateUnitsName;
            }
            else if (params.row.placementTypeID === 5 && params.row.payRate !== 0)
            {
                return params.row.payRate + ' ' + params.row.payRateUnitsName; 
            }
            else if (params.row.placementTypeID === 5 && params.row.payRate === 0){
                return "";
            }
                
            return params.row.salary + ' ' + params.row.salaryUnitsName;
        };

        const approvalStatusRenderer = (params: GridRenderCellParams) => {
            if (params.row.isChangeRequestPending) return 'Pending Change Request'; 
            else if (params.row.approved) return 'Approved';
            else return 'Pending Approval';
        };

        const onCostsRenderer = (params: GridRenderCellParams) => {
            const value = params.value;
            if ((value || value === 0) && typeof value === 'number') {
                return formatNumber(value) + ' ' + params.row.onCostsUnitsName;
            }
            return <></>;
        };

        const handlePreviewHover = (type: PreviewEntityType | '', id: number, isTags: boolean = false, recordName: string = '', noDelay: boolean = false) => {
            setPreviewType(type);
            setPreviewRecordId(id);
            setIsPreviewTags(isTags);
            setPreviewRecordName(recordName);
            setIsPreviewOpen(true);
            setShowTagsPreviewNoDelay(noDelay);
        };

        const handlePreviewClose = () => {
            setIsPreviewOpen(false);
            setShowTagsPreviewNoDelay(false);
        };

        let cols: GridColDef[] = [
            { headerName: 'ID', field: 'id', width: 75, headerAlign: 'center', align: 'center', renderCell: linkToPlacementRenderer },
            { headerName: 'Type', field: 'placementTypeName', headerAlign: 'center', align: 'center', width: 100 },
            { headerName: 'Job Title', field: 'jobTitle', width: 300, renderCell: linkToPlacementRenderer },
            { headerName: 'Tags', field: 'tags', width: 70, headerAlign: 'center', align: 'center', renderCell: tagsRenderer },
            { headerName: 'Client', field: 'clientName', width: 300, renderCell: linkToClientRenderer },
            { headerName: 'Contact', field: 'contactName', width: 200, renderCell: linkToContactRenderer },
            { headerName: 'Candidate', field: 'candidateName', width: 200, renderCell: linkToCandidateRenderer },
            { headerName: 'Placement Date', field: 'placementDate', headerAlign: 'center', align: 'center', width: 130, type: 'date', valueGetter: dateValueGetter, renderCell: dateRenderer },
            { headerName: 'Start Date', field: 'startDate', headerAlign: 'center', align: 'center', width: 110, type: 'date', valueGetter: dateValueGetter, renderCell: dateRenderer },
            { headerName: 'End Date', field: 'endDate', headerAlign: 'center', align: 'center', width: 110, type: 'date', valueGetter: dateValueGetter, renderCell: dateRenderer },
            { headerName: 'Charge Rate', field: 'chargeRate', headerAlign: 'center', align: 'center', width: 150, renderCell: chargeRateRenderer },
            { headerName: 'Pay / Salary', field: 'salary', headerAlign: 'center', align: 'center', width: 150, renderCell: payRenderer },
            { headerName: 'Invoice Fee', field: 'invoiceFee', headerAlign: 'center', align: 'center', width: 150 },
            { headerName: 'On Costs', field: 'onCosts', headerAlign: 'center', width: 150, renderCell: onCostsRenderer },
            { headerName: 'Status', field: 'placementStatusName', headerAlign: 'center', align: 'center', width: 125 },
            { headerName: 'Consultant 1', field: 'consultantName1', headerAlign: 'center', align: 'center', width: 150 },
            { headerName: 'Consultant 2', field: 'consultantName2', headerAlign: 'center', align: 'center', width: 150 },
            { headerName: 'Consultant 3', field: 'consultantName3', headerAlign: 'center', align: 'center', width: 150 },
            { headerName: 'Consultant 4', field: 'consultantName4', headerAlign: 'center', align: 'center', width: 150 },
            { headerName: 'Consultant 5', field: 'consultantName5', headerAlign: 'center', align: 'center', width: 150 },
            { headerName: 'Hiring Manager', field: 'hiringManagerName', headerAlign: 'center', align: 'center', width: 150, renderCell: linkToContactRenderer },
            { headerName: 'Approval Status', field: 'approvalStatus', headerAlign: 'center', align: 'center', width: 150, renderCell: approvalStatusRenderer },
            { headerName: 'Signatory', field: 'signatoryName', headerAlign: 'center', align: 'center', width: 150, renderCell: linkToSignatoryRenderer },
            { headerName: 'Billing Contact', field: 'billingContactName', headerAlign: 'center', align: 'center', width: 150, renderCell: linkToBillingContactRenderer },
            { headerName: 'Approver 1', field: 'timesheetApprover1Name', headerAlign: 'center', align: 'center', width: 150, renderCell: linkToTimesheetApprover1Renderer },
            { headerName: 'Approver 2', field: 'timesheetApprover2Name', headerAlign: 'center', align: 'center', width: 150, renderCell: linkToTimesheetApprover2Renderer },

            { headerName: 'Payment Type', field: 'paymentTypeName', headerAlign: 'center', align: 'center', width: 150 },
            { headerName: 'Pay Frequency', field: 'payFrequencyName', headerAlign: 'center', align: 'center', width: 150 },
            { headerName: 'Client Reference', field: 'clientReference', headerAlign: 'center', align: 'center', width: 150 },
            { headerName: 'Parent Placement ID', field: 'parentPlacementID', headerAlign: 'center', align: 'center', width: 150, renderCell: linkToParentPlacementRenderer },
            { headerName: 'Division', field: 'divisionName', width: 200 }
        ];

        if (activeFields && activeFields.length > 0) {
            for (let i = 0; i < activeFields.length; i++) {
                const udf = activeFields[i];
                const udfNumber = udf.name.slice(13);
                const isString = udf.dataType === 'String';
                const isDecimal = udf.dataType === 'Decimal';
                const isDate = udf.dataType === 'DateTime';
                const isDropdown = udf.usePredefinedValues;

                if (isDate) {
                    cols.push({ 
                        field: `customField${udfNumber}`,
                        headerName: udf.agencyName,
                        headerAlign: 'center',
                        valueGetter: dateValueGetter,
                        renderCell: dateRenderer,
                    });
                }
                else if (isString && isDropdown && udf.values && udf.values.length > 0) {
                    cols.push({ 
                        field: `customField${udfNumber}`,
                        headerName: udf.agencyName,
                        headerAlign: 'center',
                        valueOptions: udf.values.map(v => v.value),
                        type: 'singleSelect',
                    });
                }
                else {
                    cols.push({ 
                        field: `customField${udfNumber}`,
                        headerName: udf.agencyName,
                        headerAlign: 'center',
                        align: isDecimal ? 'right' : undefined,
                    });
                }
            }
        }

        return cols;
    }, [activeFields, userCanAddEditPlacements, source]);

    const closeApproveDialogHandler = useCallback(() => {
        setIsApproveDialogOpen(false);
        setSelectionModel([]);
    }, []);

    const bulkApproveCallback = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        const res = await BulkApprovePlacements(selectionModel as number[], errorHandler);
        if (res) {
            closeApproveDialogHandler()
            successHandler && successHandler('Placements Approved');
            getData();
        }
        loadingHandler && loadingHandler(false);
    }, [loadingHandler, selectionModel, errorHandler, closeApproveDialogHandler, successHandler, getData]);

    const sendEmailCallback = useCallback((entityType: number, idKey: keyof Placement, nameKey: keyof Placement, emailKey: keyof Placement) => {
        if (selectionModel.length > 0) {
            let recipients: EmailRecipient[] = [];
            for (let i = 0; i < selectionModel.length; i++) {
                const id = selectionModel[i];
                const row = rows.find(r => r.id === +id.valueOf());
                if (row) {
                    let isOptOut = false;
                    if (entityType === 3) isOptOut = row.candidateOptOut;
                    const recipientId = row[idKey] as number;
                    const recipientName = row[nameKey] as string;
                    const recipientEmail = row[emailKey] as string;

                    recipients.push({ id: recipientId, email: recipientEmail, name: recipientName, placement: row, mobile: '', isOptOut: isOptOut });
                }
            }
            setSendEmailEntityType(entityType);
            setEmailRecipients(recipients);
        }
    }, [rows, selectionModel]);

    const sendSmsCallback = useCallback((entityType: number, idKey: keyof Placement, nameKey: keyof Placement, mobileKey: keyof Placement) => {
        if (selectionModel.length > 0) {
            let recipients: EmailRecipient[] = [];
            for (let i = 0; i < selectionModel.length; i++) {
                const id = selectionModel[i];
                const row = rows.find(r => r.id === +id.valueOf());
                if (row) {
                    let isOptOut = false;
                    if (entityType === 3) isOptOut = row.candidateOptOut;
                    const recipientId = row[idKey] as number;
                    const recipientName = row[nameKey] as string;
                    const recipientMobile = row[mobileKey] as string;

                    recipients.push({ 
                        id: recipientId,
                        mobile: recipientMobile,
                        name: recipientName,
                        placement: row,
                        placementId: row.id,
                        email: '',
                        isOptOut: isOptOut,
                    });
                }
            }
            setSendSmsEntityType(entityType);
            setEmailRecipients(recipients);
        }
    }, [rows, selectionModel]);

    const exportToExcel = useCallback(() => {
        const api = apiRef.current;
        if (api) api.exportDataAsExcel();
    }, [apiRef]);

    const canExport = useMemo(() => companyHasSinglePermission(Permission.PlacementsExport) && userHasSinglePermission(Permission.PlacementsExport), []);

    const actionMenuDefinitions = useMemo<MenuOptionDefinition[]>(() => {
        const noRecordSelected = selectionModel.length === 0;

        return [
            { label: 'Approve', type: 'action', disabled: noRecordSelected, allow: () => userCanApprovePlacements, action: () => setIsApproveDialogOpen(true) },
            { label: 'Export', type: 'action', action: exportToExcel, allow: () => canExport },
            { label: 'Send Email', type: 'parent', allow: () => userCanSendEmail, subMenu: [
                { label: 'To Billing Contact', type: 'action', disabled: noRecordSelected, action: () => sendEmailCallback(2, "billingContactContactID", "billingContactName", "billingContactEmail") },
                { label: 'To Candidate', type: 'action', disabled: noRecordSelected, action: () => sendEmailCallback(3, "candidateID", "candidateName", "candidateEmail") },
                { label: 'To Contact', type: 'action', disabled: noRecordSelected, action: () => sendEmailCallback(2, "contactID", "contactName", "contactEmail") },
                { label: 'To Hiring Manager', type: 'action', disabled: noRecordSelected, action: () => sendEmailCallback(2, "hiringManagerContactID", "hiringManagerName", "hiringManagerEmail") },
            ] },
            { label: 'Send SMS', type: 'parent', allow: () => userCanSendSms, subMenu: [
                { label: 'To Billing Contact', type: 'action', disabled: noRecordSelected, action: () => sendSmsCallback(2, "billingContactContactID", "billingContactName", "billingContactMobile_Standardised") },
                { label: 'To Candidate', type: 'action', disabled: noRecordSelected, action: () => sendSmsCallback(3, "candidateID", "candidateName", "candidateTel1_Standardised") },
                { label: 'To Contact', type: 'action', disabled: noRecordSelected, action: () => sendSmsCallback(2, "contactID", "contactName", "contactMobile_Standardised" ) },
                { label: 'To Hiring Manager', type: 'action', disabled: noRecordSelected, action: () => sendSmsCallback(2, "hiringManagerContactID", "hiringManagerName", "hiringManagerMobile_Standardised")},
            ] },
            { label: 'Bulk Tag', type: 'action', disabled: noRecordSelected, allow: () => userCanAddEditPlacements, action: () => setTaggingRecords(selectionModel as number[]) },
        ];
    }, [selectionModel, userCanApprovePlacements, userCanSendEmail, userCanSendSms, userCanAddEditPlacements, canExport, sendEmailCallback, sendSmsCallback, exportToExcel]);

    const gridActions = useMemo(() => {
        if (userCanApprovePlacements || userCanSendEmail || userCanSendSms || userCanAddEditPlacements) {
            return <ActionMenu color="secondary" label="List Actions" definition={actionMenuDefinitions} />;
        }
        return undefined;
    }, [userCanApprovePlacements, userCanSendEmail, userCanSendSms, userCanAddEditPlacements, actionMenuDefinitions]);

    const tagManagementSuccessHandler = useCallback((message: string, recordIds: number[], finalTagCount: number) => {
        successHandler && successHandler(message);
        const api = apiRef.current;
        if (api) {
            const hasTags = finalTagCount > 0;
            api.updateRows(recordIds.map(id => ({ id: id, tags: hasTags })));
        }
    }, [apiRef, successHandler]);

    return (
        <>
            <PreviewLoaderComponent
                open={isPreviewOpen}
                entityType={previewType}
                recordId={previewRecordId}
                isTagsPreview={isPreviewTags}
                showDelayMs={showTagsPrevewNoDelay ? 0 : undefined}
                titleOverride={previewRecordName}
            />
            <TagsManagementDialog
                open={ taggingRecords.length > 0 }
                closeHandler={ () => setTaggingRecords([]) }
                entityId={ entityTypeId }
                recordIds={ taggingRecords }
                loadingHandler={ loadingHandler }
                errorHandler={ errorHandler }
                successHandler={ tagManagementSuccessHandler }
            />
            <ConfirmationDialog
                open={isApproveDialogOpen}
                message={`Are you sure you want to approve the ${selectionModel.length} selected placements?`}
                title="Bulk Placement Approval"
                onClose={ closeApproveDialogHandler }
                onContinue={ bulkApproveCallback }
                cancelActionText="No"
                confirmActionText="Yes"
            />
            <>
                <SendEmailDialog
                    open={ sendEmailEntityType !== null }
                    sourceEntityId={entityTypeId}
                    recipients={ emailRecipients }
                    recipientEntityTypeId={ sendEmailEntityType ?? 0 }
                    closeHandler={ () => setSendEmailEntityType(null) }
                    isPlacementList
                    loadingHandler={loadingHandler}
                    errorHandler={errorHandler}
                    successHandler={successHandler}
                />
                <SendSmsDialog
                    open={ sendSmsEntityType !== null }
                    sourceEntityId={entityTypeId}
                    recipients={ emailRecipients }
                    recipientEntityTypeId={ sendSmsEntityType ?? 0 }
                    isPlacementList
                    closeHandler={ () => setSendSmsEntityType(null) }
                    loadingHandler={loadingHandler}
                    errorHandler={errorHandler}
                    successHandler={successHandler}
                />
                {source !== 'automation' && Boolean(gridActions) &&
                    <Box pb="10px" ml="auto">
                        {gridActions}
                    </Box>
                }
                <GridWithStateWrapper
                    gridName={gridName}
                    rows={rows}
                    columns={columns}
                    apiRef={apiRef}
                    density="compact"
                    checkboxSelection
                    disableRowSelectionOnClick
                    rowSelectionModel={selectionModel}
                    onRowSelectionModelChange={ sm => setSelectionModel(sm) }
                    pagination
                    pageSizeOptions={[100,250,500,1000]}
                    fromDialog={fromDialog}
                />
            </>
        </>
    );
}
