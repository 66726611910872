import { PanelModel } from "@syncfusion/ej2-layouts/src/dashboard-layout/dashboard-layout-model";
import { OpportunityRecordDashboardElementDefinition, OpportunityRecordDashboardElementType } from "common/models/Dashboard/EditLayout";
import { ScreenLayoutSettings } from "common/models/ScreenLayouts/ScreenLayouts";
import { GetCustomFieldTypeById } from "./CustomFields";

export const DefaultOpportunityRecordDashboardElements_BackFill: OpportunityRecordDashboardElementDefinition[] = [
    { id: "OpportunityName_Default", row: 0, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'OpportunityName' },
    { id: "OpportunityType_Default", row: 1, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'OpportunityType' },
    { id: "OpportunityStatus_Default", row: 2, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'OpportunityStatus' },
    { id: "OpportunityStage_Default", row: 3, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'OpportunityStage' },
    { id: "Divider_Default11", row: 4, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'Divider' },
    { id: "OpportunityCurrency_Default", row: 5, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'OpportunityCurrency' },
    { id: "OpportunityValue_Default", row: 6, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'OpportunityValue' },
    { id: "OpportunityProbability_Default", row: 7, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'OpportunityProbability' },
    { id: "Divider_Default12", row: 8, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'Divider' },
    { id: "OpportunityOwners_Default", row: 9, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'OpportunityOwners' },
    
    { id: "OpportunityClient_Default", row: 0, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'OpportunityClient' },
    { id: "OpportunityContact_Default", row: 1, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'OpportunityContact' },
    { id: "Spacer_Default21", row: 2, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'Spacer' },
    { id: "OpportunityCandidate_Default", row: 3, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'OpportunityCandidate' },
    { id: "Divider_Default21", row: 4, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'Divider' },
    { id: "OpportunityStartDate_Default", row: 5, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'OpportunityStartDate' },
    { id: "Spacer_Default22", row: 6, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'Spacer' },
    { id: "OpportunityCloseDate_Default", row: 7, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'OpportunityCloseDate' },
    
    { id: "OpportunitySummary_Default", row: 0, col: 2, sizeX: 1, sizeY: 9, minSizeX: 1, minSizeY: 3, maxSizeX: 1, maxSizeY: null, type: 'OpportunitySummary' },
];

export const DefaultOpportunityRecordDashboardElements_Bid: OpportunityRecordDashboardElementDefinition[] = [
    { id: "OpportunityName_Default", row: 0, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'OpportunityName' },
    { id: "OpportunityType_Default", row: 1, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'OpportunityType' },
    { id: "OpportunityStatus_Default", row: 2, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'OpportunityStatus' },
    { id: "OpportunityStage_Default", row: 3, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'OpportunityStage' },
    { id: "Divider_Default11", row: 4, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'Divider' },
    { id: "OpportunityCurrency_Default", row: 5, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'OpportunityCurrency' },
    { id: "OpportunityValue_Default", row: 6, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'OpportunityValue' },
    { id: "OpportunityProbability_Default", row: 7, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'OpportunityProbability' },
    { id: "Divider_Default12", row: 8, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'Divider' },
    { id: "OpportunityOwners_Default", row: 9, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'OpportunityOwners' },
    
    { id: "OpportunityClient_Default", row: 0, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'OpportunityClient' },
    { id: "OpportunityContact_Default", row: 1, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'OpportunityContact' },
    { id: "Spacer_Default21", row: 2, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'Spacer' },
    { id: "Spacer_Default22", row: 3, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'Spacer' },
    { id: "Divider_Default21", row: 4, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'Divider' },
    { id: "OpportunityStartDate_Default", row: 5, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'OpportunityStartDate' },
    { id: "OpportunityDeadline_Default", row: 6, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'OpportunityDeadline' },
    { id: "OpportunityCloseDate_Default", row: 7, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'OpportunityCloseDate' },
    
    { id: "OpportunitySummary_Default", row: 0, col: 2, sizeX: 1, sizeY: 9, minSizeX: 1, minSizeY: 3, maxSizeX: 1, maxSizeY: null, type: 'OpportunitySummary' },
];

export const DefaultOpportunityRecordDashboardElements_Float: OpportunityRecordDashboardElementDefinition[] = [
    { id: "OpportunityName_Default", row: 0, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'OpportunityName' },
    { id: "OpportunityType_Default", row: 1, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'OpportunityType' },
    { id: "OpportunityStatus_Default", row: 2, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'OpportunityStatus' },
    { id: "OpportunityStage_Default", row: 3, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'OpportunityStage' },
    { id: "Divider_Default11", row: 4, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'Divider' },
    { id: "OpportunityCurrency_Default", row: 5, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'OpportunityCurrency' },
    { id: "OpportunityValue_Default", row: 6, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'OpportunityValue' },
    { id: "OpportunityProbability_Default", row: 7, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'OpportunityProbability' },
    { id: "Divider_Default12", row: 8, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'Divider' },
    { id: "OpportunityOwners_Default", row: 9, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'OpportunityOwners' },
    
    { id: "OpportunityClient_Default", row: 0, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'OpportunityClient' },
    { id: "OpportunityContact_Default", row: 1, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'OpportunityContact' },
    { id: "Spacer_Default21", row: 2, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'Spacer' },
    { id: "OpportunityCandidate_Default", row: 3, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'OpportunityCandidate' },
    { id: "Divider_Default21", row: 4, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'Divider' },
    { id: "OpportunityStartDate_Default", row: 5, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'OpportunityStartDate' },
    { id: "Spacer_Default22", row: 6, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'Spacer' },
    { id: "OpportunityCloseDate_Default", row: 7, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'OpportunityCloseDate' },
    
    { id: "OpportunitySummary_Default", row: 0, col: 2, sizeX: 1, sizeY: 9, minSizeX: 1, minSizeY: 3, maxSizeX: 1, maxSizeY: null, type: 'OpportunitySummary' },
];

export const DefaultOpportunityRecordDashboardElements_General: OpportunityRecordDashboardElementDefinition[] = [
    { id: "OpportunityName_Default", row: 0, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'OpportunityName' },
    { id: "OpportunityType_Default", row: 1, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'OpportunityType' },
    { id: "OpportunityStatus_Default", row: 2, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'OpportunityStatus' },
    { id: "OpportunityStage_Default", row: 3, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'OpportunityStage' },
    { id: "Divider_Default11", row: 4, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'Divider' },
    { id: "OpportunityCurrency_Default", row: 5, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'OpportunityCurrency' },
    { id: "OpportunityValue_Default", row: 6, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'OpportunityValue' },
    { id: "OpportunityProbability_Default", row: 7, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'OpportunityProbability' },
    { id: "Divider_Default12", row: 8, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'Divider' },
    { id: "OpportunityOwners_Default", row: 9, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'OpportunityOwners' },
    
    { id: "OpportunityClient_Default", row: 0, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'OpportunityClient' },
    { id: "OpportunityContact_Default", row: 1, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'OpportunityContact' },
    { id: "Spacer_Default21", row: 2, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'Spacer' },
    { id: "Spacer_Default22", row: 3, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'Spacer' },
    { id: "Divider_Default21", row: 4, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'Divider' },
    { id: "OpportunityStartDate_Default", row: 5, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'OpportunityStartDate' },
    { id: "OpportunityDeadline_Default", row: 6, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'OpportunityDeadline' },
    { id: "OpportunityCloseDate_Default", row: 7, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'OpportunityCloseDate' },
    
    { id: "OpportunitySummary_Default", row: 0, col: 2, sizeX: 1, sizeY: 9, minSizeX: 1, minSizeY: 3, maxSizeX: 1, maxSizeY: null, type: 'OpportunitySummary' },
];

export const DefaultOpportunityRecordDashboardElements_Lead: OpportunityRecordDashboardElementDefinition[] = [
    { id: "OpportunityName_Default", row: 0, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'OpportunityName' },
    { id: "OpportunityType_Default", row: 1, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'OpportunityType' },
    { id: "OpportunityStatus_Default", row: 2, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'OpportunityStatus' },
    { id: "OpportunityStage_Default", row: 3, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'OpportunityStage' },
    { id: "Divider_Default11", row: 4, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'Divider' },
    { id: "OpportunityCurrency_Default", row: 5, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'OpportunityCurrency' },
    { id: "OpportunityValue_Default", row: 6, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'OpportunityValue' },
    { id: "OpportunityProbability_Default", row: 7, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'OpportunityProbability' },
    { id: "Divider_Default12", row: 8, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'Divider' },
    { id: "OpportunityOwners_Default", row: 9, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'OpportunityOwners' },
    
    { id: "OpportunityClient_Default", row: 0, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'OpportunityClient' },
    { id: "OpportunityContact_Default", row: 1, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'OpportunityContact' },
    { id: "Spacer_Default21", row: 2, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'Spacer' },
    { id: "Spacer_Default22", row: 3, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'Spacer' },
    { id: "Divider_Default21", row: 4, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'Divider' },
    { id: "OpportunityStartDate_Default", row: 5, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'OpportunityStartDate' },
    { id: "Spacer_Default23", row: 6, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'Spacer' },
    { id: "OpportunityCloseDate_Default", row: 7, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'OpportunityCloseDate' },
    
    { id: "OpportunitySummary_Default", row: 0, col: 2, sizeX: 1, sizeY: 9, minSizeX: 1, minSizeY: 3, maxSizeX: 1, maxSizeY: null, type: 'OpportunitySummary' },
];

export const DefaultOpportunityRecordDashboardElements_Tender: OpportunityRecordDashboardElementDefinition[] = [
    { id: "OpportunityName_Default", row: 0, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'OpportunityName' },
    { id: "OpportunityType_Default", row: 1, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'OpportunityType' },
    { id: "OpportunityStatus_Default", row: 2, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'OpportunityStatus' },
    { id: "OpportunityStage_Default", row: 3, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'OpportunityStage' },
    { id: "Divider_Default11", row: 4, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'Divider' },
    { id: "OpportunityCurrency_Default", row: 5, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'OpportunityCurrency' },
    { id: "OpportunityValue_Default", row: 6, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'OpportunityValue' },
    { id: "OpportunityProbability_Default", row: 7, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'OpportunityProbability' },
    { id: "Divider_Default12", row: 8, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'Divider' },
    { id: "OpportunityOwners_Default", row: 9, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'OpportunityOwners' },
    
    { id: "OpportunityClient_Default", row: 0, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'OpportunityClient' },
    { id: "OpportunityContact_Default", row: 1, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'OpportunityContact' },
    { id: "Spacer_Default21", row: 2, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'Spacer' },
    { id: "Spacer_Default22", row: 3, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'Spacer' },
    { id: "Divider_Default21", row: 4, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'Divider' },
    { id: "OpportunityStartDate_Default", row: 5, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'OpportunityStartDate' },
    { id: "OpportunityDeadline_Default", row: 6, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'OpportunityDeadline' },
    { id: "OpportunityCloseDate_Default", row: 7, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'OpportunityCloseDate' },
    
    { id: "OpportunitySummary_Default", row: 0, col: 2, sizeX: 1, sizeY: 9, minSizeX: 1, minSizeY: 3, maxSizeX: 1, maxSizeY: null, type: 'OpportunitySummary' },
];

export const GetMinSizeByElementType = (type: OpportunityRecordDashboardElementType) => {
    switch (type) {
        case "OpportunitySummary": return [1, 9]
        default: return [1, 1];
    }
};

export const GetElementTypeById = (id: string): OpportunityRecordDashboardElementType => {
    if ( id && id.startsWith('Divider_')) return 'Divider';
    if ( id && id.startsWith('Spacer_')) return 'Spacer';
    if (id && id.startsWith('OpportunityId_')) return 'OpportunityId';
    if (id && id.startsWith('OpportunityName_')) return 'OpportunityName';
    if (id && id.startsWith('OpportunityType_')) return 'OpportunityType';
    if (id && id.startsWith('OpportunityStatus_')) return 'OpportunityStatus';
    if (id && id.startsWith('OpportunityStage_')) return 'OpportunityStage';
    if (id && id.startsWith('OpportunityValue_')) return 'OpportunityValue';
    if (id && id.startsWith('OpportunityProbability_')) return 'OpportunityProbability';
    if (id && id.startsWith('OpportunityCurrency_')) return 'OpportunityCurrency';
    if (id && id.startsWith('OpportunityStartDate_')) return 'OpportunityStartDate';
    if (id && id.startsWith('OpportunityCloseDate_')) return 'OpportunityCloseDate';
    if (id && id.startsWith('OpportunityDeadline_')) return 'OpportunityDeadline';
    if (id && id.startsWith('OpportunityClient_')) return 'OpportunityClient';
    if (id && id.startsWith('OpportunityContact_')) return 'OpportunityContact';
    if (id && id.startsWith('OpportunityCandidate_')) return 'OpportunityCandidate';
    if (id && id.startsWith('OpportunityOwners_')) return 'OpportunityOwners';
    if (id && id.startsWith('OpportunityDivision_')) return 'OpportunityDivision';
    if (id && id.startsWith('OpportunitySummary_')) return 'OpportunitySummary';
    if ( id && id.startsWith('CustomField')) return GetCustomFieldTypeById(id);
    return '';
};

export const GetPanelDefinitionsFromPanelModels = (panels: PanelModel[]) => {
    let elements: OpportunityRecordDashboardElementDefinition[] = [];
    panels.forEach((p, i) => {
        let type = GetElementTypeById(p.id ?? '');
        elements.push({
            id: p.id ?? i.toString(),
            sizeX: p.sizeX ?? 1,
            sizeY: p.sizeY ?? 1,
            col: p.col ?? 0,
            row: p.row ?? 0,
            minSizeX: p.minSizeX ?? 1,
            minSizeY: p.minSizeY ?? 1,
            maxSizeX: p.maxSizeX ?? 1,
            maxSizeY: p.maxSizeY ?? null,
            type: type
        });
    });
    return elements ;
};

export const OpportunityScreenLayoutSettings: ScreenLayoutSettings = {
    unitWidth: 600,
    unitHeight: 40,
    gapX: 10,
    gapY: 0,
    columns: 3,
    mediaQueryMaxWidth: '1000px'
};
