import { EmailIntegrationConfigOptions } from '../../../common/models/Configuration/EmailIntegration';
import { ChangeTracker } from '../../../common/models/hooks/ChangeTracker';

export const DefaultOptions: EmailIntegrationConfigOptions = {
    EmailIntegrationType: "0",
    EmailIntegrationIgnoreAttachments: "False",
}

export const NoChangesTracker: ChangeTracker<EmailIntegrationConfigOptions> = {
    EmailIntegrationType: false,
    EmailIntegrationIgnoreAttachments: false,
}

