import React, {useCallback, useEffect, useState} from "react";
import Button from "@mui/material/Button";
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';

import LogoutIcon from '@mui/icons-material/Logout';
import {Link, NavigateFunction} from "react-router-dom";
import {clearAuthData} from "util/AuthUtils";
import WizTopBarEmailIntegrationSubmenu from "./UserMenuSubmenus/WizTopBarEmailIntegrationSubmenu";
import WizTopBarESignSubmenu from "./UserMenuSubmenus/WizTopBarESignSubmenu";
import WizTopBarOnboardingSubmenu from "./UserMenuSubmenus/WizTopBarOnboardingSubmenu";
import WizTopBarTemplatesSubmenu from "./UserMenuSubmenus/WizTopBarTemplatesSubmenu";
import CustomApplicationContext from "util/CustomApplicationContext";
import {ExtendedUser} from "common/models/Configuration/User";
import Popper from "@mui/material/Popper";
import Paper from "@mui/material/Paper";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Avatar from "@mui/material/Avatar";
import {companyHasSinglePermission} from "../../util/PermissionsUtils";
import {Permission} from "../../common/models/Permissions";
import WizTopBarReferenceCheckSubmenu from "./UserMenuSubmenus/WizTopBarReferenceCheckSubmenu";

interface Props {
    navigate: NavigateFunction,
    currentUser: ExtendedUser | null,
    currentUserRefreshHandler: () => void
}

const routerLinkStyle: React.CSSProperties = { color: 'inherit', textDecoration: 'none' };

function stringToColor(string: string) {
    let hash = 0;
    let i;
  
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let color = '#';
  
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
  
    return color;
  }
  
  function stringAvatar(name: string) {
    return {
      sx: {
        bgcolor: stringToColor(name ?? ''),
        width: 32, height: 32, fontSize: '16px'
      },
      children: `${name}`,
    };
  }

export default function WizTopBarUserMenu({ currentUser, currentUserRefreshHandler, navigate }: Props) {
    const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
    const [anchor, setAnchor] = useState<HTMLElement | null>(null);
    const [isRootHovered, setIsRootHovered] = useState(false);
    const [isRootPaperHovered, setIsRootPaperHovered] = useState(false);

    const handleOpenMenu = useCallback((e: React.MouseEvent<HTMLElement>) => {
        setIsRootHovered(true);
        setAnchor(e.currentTarget);
    }, []);

    const handleCloseMenu = useCallback(() => {
        setAnchor(null);
        setHoveredIndex(null);
    }, []);

    useEffect(() => {
        if (Boolean(anchor) && !isRootHovered && !isRootPaperHovered) setAnchor(null);
    }, [anchor, isRootHovered, isRootPaperHovered]);

    useEffect(() => {
        if (currentUser === null) currentUserRefreshHandler();
    }, [currentUser, currentUserRefreshHandler]);

    const handleHoverOption = useCallback((i: number | null) => {
        setHoveredIndex(i);
    }, []);

    const handleLogout = useCallback(() => {
        clearAuthData();
        handleCloseMenu();
        navigate('/login');
    }, [handleCloseMenu, navigate]);

    return (
        <>
            <Button
                variant="text"
                onClick={handleOpenMenu}
                onMouseEnter={handleOpenMenu}
                onMouseLeave={() => setIsRootHovered(false)}
                sx={{ textTransform: 'none', px: 0 }}
            >
                <CustomApplicationContext.Consumer>
                    {c => (
                        <Avatar {...stringAvatar(c.currentUser ? c.currentUser.initials : 'Recruit Wizard')} />
                    )}
                </CustomApplicationContext.Consumer>
            </Button>
            <Popper
                disablePortal
                anchorEl={anchor}
                open={Boolean(anchor)}
                placement="bottom-end"
                sx={{ zIndex: t => t.zIndex.appBar + 2 }}
                onMouseEnter={ () => setIsRootPaperHovered(true) }
                onMouseLeave={ () => setIsRootPaperHovered(false) }
            >
                <ClickAwayListener onClickAway={ handleCloseMenu }>
                    <Paper elevation={8} onMouseLeave={ handleCloseMenu }>
                        <Link to="/account" style={{ color: 'inherit', textDecoration: 'none' }}>
                            <MenuItem onClick={handleCloseMenu} onMouseEnter={ () => handleHoverOption(3) }>
                                Account
                            </MenuItem>
                        </Link>
                        <Divider sx={{ my: 1 }} />
                        <WizTopBarEmailIntegrationSubmenu
                            index={4}
                            hoveredIndex={hoveredIndex}
                            onMouseEnter={handleHoverOption}
                            closeUserMenuHandler={handleCloseMenu}
                        />
                        <WizTopBarESignSubmenu
                            index={5}
                            hoveredIndex={hoveredIndex}
                            onMouseEnter={handleHoverOption}
                            closeUserMenuHandler={handleCloseMenu}
                        />
                        <WizTopBarReferenceCheckSubmenu
                            index={6}
                            hoveredIndex={hoveredIndex}
                            onMouseEnter={handleHoverOption}
                            closeUserMenuHandler={handleCloseMenu}
                        />
                        
                        <Link to="/account/integrations" style={routerLinkStyle}>
                            <MenuItem onClick={handleCloseMenu} onMouseEnter={ () => handleHoverOption(6) }>
                                Integrations
                            </MenuItem>
                        </Link>
                        { companyHasSinglePermission(Permission.Onboarding) && <WizTopBarOnboardingSubmenu
                            index={7}
                            hoveredIndex={hoveredIndex}
                            onMouseEnter={handleHoverOption}
                            closeUserMenuHandler={handleCloseMenu}
                        /> }
                        <Link to="/account/saved-searches" style={routerLinkStyle}>
                            <MenuItem onClick={handleCloseMenu} onMouseEnter={ () => handleHoverOption(9) }>
                                Saved Searches
                            </MenuItem>
                        </Link>
                        <WizTopBarTemplatesSubmenu
                            index={14}
                            hoveredIndex={hoveredIndex}
                            onMouseEnter={ handleHoverOption }
                            closeUserMenuHandler={handleCloseMenu}
                        />
                        <Divider />
                        <MenuItem onClick={handleLogout} onMouseEnter={ () => handleHoverOption(15) }>
                            <ListItemIcon>
                                <LogoutIcon />
                            </ListItemIcon>
                            <Typography textAlign="center">Logout</Typography>
                        </MenuItem>
                    </Paper>
                </ClickAwayListener>
            </Popper>
        </>
    );
}