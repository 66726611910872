import Button from "@mui/material/Button";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import PageLayout from "layouts/PageLayout";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import LocationPicker from "components/Pickers/LocationPicker";
import { Location } from "common/models/Common";
import { IsValidNumericValue } from "util/RegExUtils";
import InputAdornment from "@mui/material/InputAdornment";
import PageContentLayout from "layouts/PageContentLayout";
import { useNavigate, useSearchParams } from "react-router-dom";
import { GetLocationById } from "services/CommonService";
import { GetMySettings } from "services/UsersService";
import DivisionPicker from "components/Pickers/DivisionPicker";
import TagsSearchFilter from "components/Tags/TagsSearchFilter";
import Switch from "@mui/material/Switch";
import { TagFilter } from "common/models/Configuration/Tags";
import { Division } from "common/models/Configuration/Division";
import { CustomField, CustomFieldPredefinedValue } from "common/models/Configuration/CustomFields";
import { GetCustomFieldsByEntity_OnlyActive, GetPredefinedValues } from "services/CustomFieldsService";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import CandidatesGridComponent from "components/Grids/CandidatesGrid";
import { CandidateSearch } from "common/models/Search/CandidateSearch";
import { GetSavedSearchById } from "services/SavedSearchesService";
import SaveSearchDialog from "components/Dialogs/SaveSearchDialog";
import { SaveCandidatesSearch, UpdateCandidateSavedSearch, UpdateCandidateSavedSearchFrequency } from "services/CandidatesService";
import CandidatesSavedSearchGridComponent from "components/Grids/CandidatesSavedSearchGrid";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Job } from "common/models/Jobs/Job";
import { GetJobById } from "services/JobsService";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { SxProps } from "@mui/material/styles";
import RWTextFieldComponent from "components/RWTextFieldComponent";
import { GetGeneralSettings } from "services/ConfigurationService";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

interface CustomFieldWithPredefinedValues extends CustomField {
    values?: CustomFieldPredefinedValue[],
}

interface StringUdf {
    isEssential: boolean,
    value: string
}

interface DecimalUdf {
    isEssential: boolean,
    valueFrom: string,
    valueTo: string,
}

interface DateUdf {
    isEssential: boolean,
    valueFrom: moment.Moment | null,
    valueTo: moment.Moment | null,
}

type UdfSearchValues = Record<string, StringUdf | DecimalUdf | DateUdf>;

const filterElementStyle: SxProps = { flex: '1 1 0' };

const divisionSpecialOptions: Division[] = [
    { id: 0, automatedRejectionDelay: 0, name: 'All', shortName: 'All', active: true, country: '', countryName: '', timeZone: '', timesheetSystem: 0 }
];

type stringKeys = 'customField1' | 'customField2' | 'customField3' | 'customField4' | 'customField5' | 'customField6' | 'customField7' | 'customField8' | 'customField9' | 'customField10' | 'customField11' | 'customField12' | 'customField13' | 'customField14' | 'customField15' | 'customField16' | 'customField17' | 'customField18' | 'customField19' | 'customField20' | 'customField21' | 'customField22' | 'customField23' | 'customField24' | 'customField25' | 'customField26' | 'customField27' | 'customField28' | 'customField29' | 'customField30';
type dateKeys = 'customField31' | 'customField32' | 'customField33' | 'customField34' | 'customField35' | 'customField36' | 'customField37' | 'customField38' | 'customField39' | 'customField40' | 'customField41' | 'customField42' | 'customField43' | 'customField44' | 'customField45';
type decimalKeys = 'customField46' | 'customField47' | 'customField48' | 'customField49' | 'customField50' | 'customField51' | 'customField52' | 'customField53' | 'customField54' | 'customField55' | 'customField56' | 'customField57' | 'customField58' | 'customField59' | 'customField60';


const renderSummary = (j: Job) => {
    const sx: React.CSSProperties = {color: 'inherit', textDecoration: 'underline'};
    return <>{'Candidates > Search -'}&nbsp;<Link to={`/jobs/${j.jobID}`} style={sx}>{j.title}</Link>&nbsp;(&nbsp;{<Link to={`/clients/${j.clientID}`} style={sx}>{j.clientName}</Link>}&nbsp;)</>;
};

const savedSearchFreqOptions = [
    <MenuItem key="0" value="0">Manual</MenuItem>,
    <MenuItem key="1" value="1">Hourly</MenuItem>,
    <MenuItem key="2" value="2">Daily</MenuItem>,
    <MenuItem key="3" value="3">Weekly</MenuItem>,
    <MenuItem key="4" value="4">Monthly</MenuItem>,
];

export default function CandidatesSearchPage() {
    const [activeTab, setActiveTab] = useState('criteria');
    const [isLoading, setIsLoading] = useState(false);
    const [isGridLoading, setIsGridLoading] = useState(false);
    const [isFetchingSavedSearch, setIsFetchingSavedSearch] = useState(false);
    const [fetchedSavedSearch, setFetchedSavedSearch] = useState(false);
    const [isFetchingSettings, setIsFetchingSettings] = useState(false);
    const [isFetchingConfig, setIsFetchingConfig] = useState(false);
    const [fetchedConfig, setFetchedConfig] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [showGrid, setShowGrid] = useState(false);
    const [showSaveSearchDialog, setShowSaveSearchDialog] = useState(false);
    const [loadedSavedSearchName, setLoadedSavedSearchName] = useState('');
    const [loadedSavedSearchFrequencyId, setLoadedSavedSearchFrequencyId] = useState(0);
    const [availableCustomFields, setAvailableCustomFields] = useState<CustomFieldWithPredefinedValues[]>([]);
    const [customFieldsSearchValues, setCustomFieldsSearchValues] = useState<UdfSearchValues>({});
    const [defaultCustomFieldsSearchValues, setDefaultCustomFieldsSearchValues] = useState<UdfSearchValues>({});
    const [jobTitleEssential, setJobTitleEssential] = useState(true);
    const [jobTitle, setJobTitle] = useState('');
    const [employer, setEmployer] = useState('');
    const [timeRange, setTimeRange] = useState('withinlast1job');
    const [cvTextEssential, setCvTextEssential] = useState(true);
    const [cvText, setCvText] = useState('');
    const [summaryEssential, setSummaryEssential] = useState(true);
    const [summary, setSummary] = useState('');
    const [division, setDivision] = useState(0);
    const [locationEssential, setLocationEssential] = useState(true);
    const [location, setLocation] = useState<Location | null>(null);
    const [radius, setRadius] = useState('');
    const [isApplicantsOnly, setIsApplicantsOnly] = useState(false);
    const [jobSeekingStatusEssential, setJobSeekingStatusEssential] = useState(true);
    const [jobSeekingStatusId, setJobSeekingStatusId] = useState(0);
    const [availability, setAvailability] = useState(0);
    const [jobType, setJobType] = useState(0);
    const [searchParams] = useSearchParams();
    const [linkedJob, setLinkedJob] = useState<Job>();
    const [savedSearchName, setSavedSearchName] = useState('');
    const [isAutoSaveSearch, setIsAutoSaveSearch] = useState(false);
    const [searchedAfterListReturn, setSearchedAfterListReturn] = useState(false);
    const navigate = useNavigate();

    const [tagsEssential, setTagsEssential] = useState(true);
    const [taggedBy, setTaggedBy] = useState<'a' | 'm'>('a');
    const [taggedWithin, setTaggedWithin] = useState(999);
    const [isIncludeTag, setIsIncludeTag] = useState(true);
    const [includeTags, setIncludeTags] = useState<TagFilter[]>([]);
    const [excludeTags, setExcludeTags] = useState<TagFilter[]>([]);
    const [availableTags_All, setAvailableTags_All] = useState<TagFilter[]>([]);
    const [availableTags_Me, setAvailableTags_Me] = useState<TagFilter[]>([]);
    const [searchData, setSearchData] = useState<CandidateSearch>();

    const ssid = useMemo(() => {
        const v = searchParams.get('ssid');
        if (v) return +v;
        return undefined;
    }, [searchParams]);

    const jobId = useMemo(() => {
        const v = searchParams.get('jobId');
        if (v) return +v;
        return undefined;
    }, [searchParams]);

    const candidateId = useMemo(() => {
        const v = searchParams.get('candidateId');
        if (v) return +v;
        return undefined;
    }, [searchParams]);

    useEffect(() => {
        const getDefaultSettings = async () => {
            setIsFetchingSettings(true);
            const data = await GetMySettings(setErrorMessage);
            if (data) {
                const radiusSetting = data.find(s => s.type === 'Search_Radius');
                if (radiusSetting && radiusSetting.value && IsValidNumericValue(radiusSetting.value)) {
                    setRadius(radiusSetting.value);
                }

                const divisionSetting = data.find(s => s.type === 'SearchDivision');
                if (divisionSetting && divisionSetting.value && IsValidNumericValue(divisionSetting.value)) {
                    setDivision(+divisionSetting.value);
                }

                const locationIdSetting = data.find(s => s.type === 'Search_LocationHiddenId');
                if (locationIdSetting && locationIdSetting.value) {
                    const l = await GetLocationById(+locationIdSetting.value);
                    if (l) setLocation(l)
                }
            }
            setIsFetchingSettings(false);
        };
        !ssid && getDefaultSettings();
    }, [ssid]);

    useEffect(() => {
        const getInstanceSettings = async () => {
            const data = await GetGeneralSettings();
            if (data) {
                const setting = data.find(s => s.type === 'AutoSaveCandidateSearches');
                if (setting && setting.value === 'True') setIsAutoSaveSearch(true);
            }
        };
        !ssid && getInstanceSettings();
    }, [ssid]);

    useEffect(() => {
        const getActiveFields = async () => {
            setIsFetchingConfig(true);
            const customFields = await GetCustomFieldsByEntity_OnlyActive(3);
            if (customFields) {
                let udfSearchValues: UdfSearchValues = {};
                let udfDefinitions: CustomFieldWithPredefinedValues[] = [];
                for (let i = 0; i < customFields.length; i++) {
                    const u = customFields[i];
                    if (!u.isSearchable) continue;
                    if (u.dataType === 'String') udfSearchValues[u.name] = { isEssential: true, value: '' };
                    else if (u.dataType === 'Decimal') udfSearchValues[u.name] = { isEssential: true, valueFrom: '', valueTo: '' };
                    else if (u.dataType === 'DateTime') udfSearchValues[u.name] = { isEssential: true, valueFrom: null, valueTo: null };
                    if (!u.usePredefinedValues) {
                        udfDefinitions.push(u);
                        continue;
                    }

                    const vals = await GetPredefinedValues(u.id);
                    if (vals) udfDefinitions.push({ ...u, values: vals });
                    else udfDefinitions.push(u);
                }
                setAvailableCustomFields(udfDefinitions);
                setCustomFieldsSearchValues(udfSearchValues);
                setDefaultCustomFieldsSearchValues(window.structuredClone(udfSearchValues));
            }
            setFetchedConfig(true);
            setIsFetchingConfig(false);
        };
        getActiveFields();
    }, []);

    

    useEffect(() => {
        const getSavedSearch = async () => {
            if (ssid) {
                setIsFetchingSavedSearch(true);
                const res = await GetSavedSearchById(ssid, setErrorMessage);
                if (res) {
                    setSavedSearchName(res.name);
                    if (res.jobID) {
                        const j = await GetJobById(res.jobID);
                        if (j) setLinkedJob(j);
                    }
                }
                if (res && res.json) {
                    const t1 = JSON.parse(res.json);
                    if (t1.jobTitle) setJobTitle(t1.jobTitle);
                    if (t1.employer) setEmployer(t1.employer);
                    if (t1.workHistoryTimeRange) setTimeRange(t1.workHistoryTimeRange);
                    setJobTitleEssential(Boolean(t1.workHistoryMandatory));

                    if (t1.cvText) setCvText(t1.cvText);
                    setCvTextEssential(Boolean(t1.cvTextMandatory));

                    if (t1.summary) setSummary(t1.summary)
                    setSummaryEssential(t1.summaryMandatory);

                    if (t1.tagsTimeRange) setTaggedWithin(+t1.tagsTimeRange);
                    if (t1.locationId) {
                        const l = await GetLocationById(+t1.locationId);
                        setLocation(l);
                    }
                    setLocationEssential(Boolean(t1.locationMandatory));
                    if (t1.radius) setRadius(t1.radius.toString());
                    setIsApplicantsOnly(Boolean(t1.applicantsOnly));

                    if (t1.jobSeekingStatus) setJobSeekingStatusId(+t1.jobSeekingStatus);
                    if (t1.availability) setAvailability(+t1.availability);
                    if (t1.jobType) setJobType(+t1.jobType);
                    setJobSeekingStatusEssential(Boolean(t1.filtersMandatory));

                    if (t1.division) setDivision(+t1.division);

                    if (t1.tags && t1.tags !== '' && t1.tags !== '[]') {
                        const tags = JSON.parse(t1.tags);
                        let inc: TagFilter[] = [];
                        let exc: TagFilter[] = [];
                        for (let i = 0; i < tags.length; i++) {
                            const {id, name, type} = tags[i];
                            const [tName, tTypeId] = (name as string).split('__');
                            if ((id as string).includes('_EXCLUDE')) exc.push({ source: type === 'm' ? 'm' : 'a', tag: tName, typeId: +tTypeId });
                            else inc.push({ source: type === 'm' ? 'm' : 'a', tag: tName, typeId: +tTypeId });
                        }
                        setIncludeTags(inc);
                        setExcludeTags(exc);
                    }
                    setTagsEssential(Boolean(t1.tagsMandatory));

                    let udfValues: UdfSearchValues = {};
                    for (let i = 0; i < availableCustomFields.length; i++) {
                        const f = availableCustomFields[i];
                        const num = +(f.name.split(' ').pop() ?? 0);
                        const mandatoryKey = `UDF${num}Mandatory`;
                        const value1Key = `UDF${num}`;
                        const value2Key = `UDF${num}_end`;
                        const val1 = t1[value1Key];
                        const val2 = t1[value2Key];

                        if (num >=1 && num <= 30 && Boolean(val1) && val1 !== '-- Select --') {
                            udfValues[f.name] = { isEssential: Boolean(t1[mandatoryKey]), value: val1 };
                        }
                        else if (num >= 31 && num <= 45 && (Boolean(val1) || Boolean(val2))) {
                            udfValues[f.name] = {
                                isEssential: Boolean(t1[mandatoryKey]),
                                valueFrom: Boolean(val1) && !(val1 as string).endsWith('/0001') ? moment(val1, 'DD/MM/YYYY') : null,
                                valueTo: Boolean(val2) && !(val2 as string).endsWith('/9999') ? moment(val2, 'DD/MM/YYYY') : null
                            };
                        }
                        else if (num >= 46 && num <= 60 && (Boolean(val1) || Boolean(val2))) {
                            udfValues[f.name] = {
                                isEssential: Boolean(t1[mandatoryKey]),
                                valueFrom: Boolean(val1) ? val1 : '',
                                valueTo: Boolean(val2) ? val2 : ''
                            };
                        }
                    }

                    setCustomFieldsSearchValues(prev => ({...prev, ...udfValues}));

                    setLoadedSavedSearchName(res.name);
                    setLoadedSavedSearchFrequencyId(res.autoRunFrequency);
                }
                setFetchedSavedSearch(true);
                setIsFetchingSavedSearch(false);
            }
        };
        ssid && fetchedConfig && getSavedSearch();
    }, [ssid, fetchedConfig, availableCustomFields]);

    const isRadiusValidNumber = useMemo(() => IsValidNumericValue(radius), [radius]);

    const clearCallback = useCallback(() => {
        setJobTitle('');
        setEmployer('');
        setTimeRange('withinlast1job');
        setCvText('');
        setSummary('');
        setLocation(null);
        setRadius('');
        setJobSeekingStatusId(0);
        setAvailability(0);
        setJobType(0);
        setDivision(0);
        setIncludeTags([]);
        setExcludeTags([]);
        setCustomFieldsSearchValues(window.structuredClone(defaultCustomFieldsSearchValues));
        setShowGrid(false);
    }, [defaultCustomFieldsSearchValues]);

    useEffect(() => {
        if (!ssid) setSavedSearchName('');
    }, [ssid]);

    const searchCallback = useCallback(async () => {
        let s: CandidateSearch = {
            ssid: ssid,
            jobId: jobId,
            jobTitle: jobTitle,
            employer: employer,
            timeRange: timeRange,
            jobRequired: jobTitleEssential,
            cvText: cvText,
            cvTextRequired: cvTextEssential,
            summary: summary,
            summaryRequired: summaryEssential,
            division: division,
            tagsRequired: tagsEssential,
            locationRequired: locationEssential,
            jobSeekingStatusId: jobSeekingStatusId,
            availability: availability,
            jobTypeId: jobType,
            seekingStatusRequired: jobSeekingStatusEssential,
            applicantsOnly: isApplicantsOnly,

            taggedTimeRange: taggedWithin,
        };

        if (isRadiusValidNumber) s.radius = +radius;

        if (location) {
            s.location = location.geoNameID;
            s.latitude = location.latitude;
            s.longitude = location.longitude;
        }

        if (includeTags.length > 0) s.includeTags = includeTags;
        if (excludeTags.length > 0) s.excludeTags = excludeTags;

        if (availableCustomFields.length > 0) {
            for (let i = 0; i < availableCustomFields.length; i++) {
                const f = availableCustomFields[i];
                const num = +(f.name.split(' ').pop() ?? 0);
                const key = 'customField' + num;
                const v = customFieldsSearchValues[f.name];

                if (num >= 1 && num <= 30 && v) {
                    const vv = v as StringUdf;
                    s[key as stringKeys] = { required: vv.isEssential, value: vv.value };
                }
                else if (num >= 31 && num <= 45 && v) {
                    const vv = v as DateUdf;
                    s[key as dateKeys] = {
                        required: vv.isEssential,
                        from: vv.valueFrom ? vv.valueFrom.format('YYYY-MM-DD') : '',
                        to: vv.valueTo ? vv.valueTo.format('YYYY-MM-DD') : ''
                    };
                }
                else if (num >= 46 && num <= 60 && v) {
                    const vv = v as DecimalUdf;
                    s[key as decimalKeys] = { required: vv.isEssential, from: vv.valueFrom, to: vv.valueTo };
                }
            }
        }
        if (ssid) {
            await UpdateCandidateSavedSearch(ssid, loadedSavedSearchName, loadedSavedSearchFrequencyId, s);
        }

        setSearchData(s);
        setShowGrid(true);
        setActiveTab('results');
    }, [ssid, jobId, jobTitle, employer, timeRange, jobTitleEssential, cvText, cvTextEssential, summary, summaryEssential, division, tagsEssential, locationEssential, jobSeekingStatusId, availability, jobType, jobSeekingStatusEssential, isApplicantsOnly, taggedWithin, isRadiusValidNumber, radius, location, includeTags, excludeTags, availableCustomFields, customFieldsSearchValues, loadedSavedSearchName, loadedSavedSearchFrequencyId]);

    useEffect(() => {
        if (ssid && candidateId && fetchedConfig && fetchedSavedSearch && !searchedAfterListReturn) {
            searchCallback();
            setSearchedAfterListReturn(true);
        }
    }, [candidateId, fetchedConfig, fetchedSavedSearch, searchCallback, searchedAfterListReturn, ssid]);

    const saveSearchCallback = useCallback(async (searchName: string, searchFreq: number) => {
        let s: CandidateSearch = {
            jobTitle: jobTitle,
            employer: employer,
            timeRange: timeRange,
            jobRequired: jobTitleEssential,
            cvText: cvText,
            cvTextRequired: cvTextEssential,
            summary: summary,
            summaryRequired: summaryEssential,
            division: division,
            tagsRequired: tagsEssential,
            locationRequired: locationEssential,
            jobSeekingStatusId: jobSeekingStatusId,
            availability: availability,
            jobTypeId: jobType,
            seekingStatusRequired: jobSeekingStatusEssential,
            applicantsOnly: isApplicantsOnly,

            taggedTimeRange: taggedWithin,
        };

        if (isRadiusValidNumber) s.radius = +radius;

        if (location) {
            s.location = location.geoNameID;
            s.latitude = location.latitude;
            s.longitude = location.longitude;
        }

        if (includeTags.length > 0) s.includeTags = includeTags;
        if (excludeTags.length > 0) s.excludeTags = excludeTags;

        if (availableCustomFields.length > 0) {
            for (let i = 0; i < availableCustomFields.length; i++) {
                const f = availableCustomFields[i];
                const num = +(f.name.split(' ').pop() ?? 0);
                const key = 'customField' + num;
                const v = customFieldsSearchValues[f.name];

                if (num >= 1 && num <= 30 && v) {
                    const vv = v as StringUdf;
                    s[key as stringKeys] = { required: vv.isEssential, value: vv.value };
                }
                else if (num >= 31 && num <= 45 && v) {
                    const vv = v as DateUdf;
                    s[key as dateKeys] = {
                        required: vv.isEssential,
                        from: vv.valueFrom ? vv.valueFrom.format('YYYY-MM-DD') : '',
                        to: vv.valueTo ? vv.valueTo.format('YYYY-MM-DD') : ''
                    };
                }
                else if (num >= 46 && num <= 60 && v) {
                    const vv = v as DecimalUdf;
                    s[key as decimalKeys] = { required: vv.isEssential, from: vv.valueFrom, to: vv.valueTo };
                }
            }
        }

        setIsLoading(true);
        var newSsid = await SaveCandidatesSearch(searchName, searchFreq, s, setErrorMessage);
        if (newSsid) {
            setShowSaveSearchDialog(false);
            navigate(`/candidates?ssid=${newSsid.value}`);
        }
        setIsLoading(false);

    }, [availability, availableCustomFields, customFieldsSearchValues, cvText, cvTextEssential, division, employer, excludeTags, includeTags, isApplicantsOnly, isRadiusValidNumber, jobSeekingStatusEssential, jobSeekingStatusId, jobTitle, jobTitleEssential, jobType, location, locationEssential, navigate, radius, summary, summaryEssential, taggedWithin, tagsEssential, timeRange]);

    const searchClickHandler = useCallback(async () => {
        if (!ssid && isAutoSaveSearch) {
            const name = 'Candidate Auto Search: ' + moment().format('DD MMM YYYY HH:mm');
            await saveSearchCallback(name, 0);
        }
        searchCallback();

    }, [isAutoSaveSearch, saveSearchCallback, searchCallback, ssid]);

    const onAutoRunFrequencyChange = useCallback(async (e: React.ChangeEvent<HTMLInputElement>) => {
        const v = +e.target.value;
        setLoadedSavedSearchFrequencyId(v);
        if (ssid) {
            setIsLoading(true);
            await UpdateCandidateSavedSearchFrequency(ssid, v);
            setIsLoading(false);
        }
    }, [ssid]);

    const summaryBar = useMemo(() => {
        const clearAction = <Button variant="contained" color="error" onClick={clearCallback} sx={{ mr: '5px' }}>Clear</Button>;
        const saveAction = <Button variant="contained" onClick={() => setShowSaveSearchDialog(true)} sx={{ mr: '5px' }}>Save</Button>;
        const searchAction = <Button variant="contained" color="success" onClick={searchClickHandler}>Search</Button>;
        const ssFreq = Boolean(ssid) ? (
            <>
                <Typography variant="h6" display="flex" alignItems="center" mr="5px">Auto Run Frequency</Typography>
                <TextField select value={loadedSavedSearchFrequencyId.toString()} onChange={onAutoRunFrequencyChange} sx={{ mr: '5px' }}>{savedSearchFreqOptions}</TextField>
            </>
        ) : <></>;
        const actions = showGrid && !Boolean(ssid) ? <>{clearAction}{saveAction}{searchAction}</> : <>{ssFreq}{clearAction}{searchAction}</>;
        let title: JSX.Element | string = "Candidates > Search";
        if (linkedJob) title = renderSummary(linkedJob);
        else if (savedSearchName) title = `Candidates > Search > ${savedSearchName}`;

        return <TitleAndActionSummaryBar title={title} browserTabTitle="Search > Candidates"  action={actions} />;
    }, [clearCallback, searchClickHandler, ssid, loadedSavedSearchFrequencyId, onAutoRunFrequencyChange, showGrid, linkedJob, savedSearchName]);

    const radiusChangeHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;

        if (val === '') {
            setRadius('');
            return;
        }

        const isValid = IsValidNumericValue(val);
        if (isValid) {
            const numberVal = +val;
            if (numberVal > 0) setRadius(val);
        }
        else if (val.endsWith('.')) {
            const pointsPresent = (val.match(/\./g) || []).length;
            if (pointsPresent === 1) setRadius(val);
        }
    }, []);

    const locationChangeHandler = useCallback((l: Location | null) => {
        setLocation(l);
        if (radius === '') setRadius('50');
    }, [radius]);

    const handleRemoveCallback = useCallback((data: TagFilter) => {
        const includeIndex = includeTags.findIndex(t => t.typeId === data.typeId && t.tag === data.tag);

        if (includeIndex !== -1) {
            setIncludeTags(prev => {
                let tmp = [...prev];
                const index = tmp.findIndex(t => t.typeId === data.typeId && t.tag === data.tag);
                if (index !== -1) tmp.splice(index, 1);
                return tmp;
            });
        }
        else {
            setExcludeTags(prev => {
                let tmp = [...prev];
                const index = tmp.findIndex(t => t.typeId === data.typeId && t.tag === data.tag);
                if (index !== -1) tmp.splice(index, 1);
                return tmp;
            });
        }

    }, [includeTags]);

    const handleAddCallback = useCallback((typeId: number, tag: string) => {
        const selectedTag = (taggedBy === 'a' ? availableTags_All : availableTags_Me).find(t => t.typeId === typeId && t.tag === tag);
        if (isIncludeTag) {
            setIncludeTags(prev => {
                const assignedTagIndex = prev.findIndex(t => t.typeId === typeId && t.tag === tag);
                if (assignedTagIndex === -1 && selectedTag) {
                    return [...prev, {...selectedTag, source: taggedBy}]
                }
                return prev;
            });
        }
        else {
            setExcludeTags(prev => {
                const assignedTagIndex = prev.findIndex(t => t.typeId === typeId && t.tag === tag);
                if (assignedTagIndex === -1 && selectedTag) {
                    return [...prev, {...selectedTag, source: taggedBy}]
                }
                return prev;
            });

        }
    }, [isIncludeTag, availableTags_All, availableTags_Me, taggedBy]);

    const udfIsEssentialChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { name } = e.target;
        setCustomFieldsSearchValues(prev => {
            let tmp = {...prev};
            tmp[name].isEssential = !tmp[name].isEssential;
            return tmp;
        });
    }, []);

    const udfStringValueChangeHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setCustomFieldsSearchValues(prev => {
            let tmp = {...prev};
            (tmp[name] as StringUdf).value = value;
            return tmp;
        });
    }, []);

    const udfStringValueUpdate = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setCustomFieldsSearchValues(prev => {
            let tmp = {...prev};
            (tmp[name] as StringUdf).value = value;
            return tmp;
        });
    }, []);


    const udfFromDecimalValueChangeHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setCustomFieldsSearchValues(prev => {
            let tmp = {...prev};
            (tmp[name] as DecimalUdf).valueFrom = value;
            return tmp;
        });
    }, []);

    const udfToDecimalValueChangeHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setCustomFieldsSearchValues(prev => {
            let tmp = {...prev};
            (tmp[name] as DecimalUdf).valueTo = value;
            return tmp;
        });
    }, []);

    const udfFromDateValueChangeHandler = useCallback((date: moment.Moment | null, name: string) => {
        setCustomFieldsSearchValues(prev => {
            let tmp = {...prev};
            (tmp[name] as DateUdf).valueFrom = date;
            return tmp;
        });
    }, []);

    const udfToDateValueChangeHandler = useCallback((date: moment.Moment | null, name: string) => {
        setCustomFieldsSearchValues(prev => {
            let tmp = {...prev};
            (tmp[name] as DateUdf).valueTo = date;
            return tmp;
        });
    }, []);

    const onUpdateTextComponentChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
            switch (name) {
                case "employer":
                    setEmployer(value);
                    break;
                case "jobtitle":
                    setJobTitle(value);
                    break;
                case "summary":
                    setSummary(value);
                    break;
                case "cvText":
                    setCvText(value);
                    break;
            
                default:
                    break;
        }
    }, [setEmployer,setJobTitle,setSummary,setCvText]);

    const renderedUdfs = useMemo(() => {
        if (fetchedConfig) {
            return availableCustomFields.map(udf => {
                const sv = customFieldsSearchValues[udf.name];
                const isEssential = sv ? sv.isEssential : true;
                if (udf.dataType === 'String' && udf.values && udf.values.length > 0) {
                    const value = sv ? (sv as StringUdf).value : '';
                    return (
                        <Box key={udf.id} display="flex" bgcolor="background.default" borderRadius="10px" p={1}>
                            <Box>
                                <Checkbox title="Toggle is essential" checked={isEssential} name={udf.name} onChange={udfIsEssentialChange} color="success" icon={<StarBorderIcon />} checkedIcon={<StarIcon />} />
                            </Box>
                            <TextField select label={udf.agencyName} value={value} name={udf.name} onChange={udfStringValueChangeHandler} sx={{ minWidth: '226px' }}>
                                <MenuItem value="">None</MenuItem>
                                {udf.values.map((v, i) => <MenuItem key={i} value={`"${v.value}"`}>{v.value + (v.isDefault ? ' (Default)' : '')}</MenuItem>)}
                            </TextField>
                        </Box>
                    );
                }
                if (udf.dataType === 'String') {
                    const value = sv ? (sv as StringUdf).value : '';
                    return (
                        
                        <Box key={udf.id} display="flex" bgcolor="background.default" borderRadius="10px" p={1}>
                            <Box>
                                <Checkbox title="Toggle is essential" checked={isEssential} name={udf.name} onChange={udfIsEssentialChange} color="success" icon={<StarBorderIcon />} checkedIcon={<StarIcon />} />
                            </Box>
                            <RWTextFieldComponent label={udf.agencyName} value={value} onChange={udfStringValueUpdate} multiline={udf.multiLine} name={udf.name}/>
                        </Box>
                    );
                }
                if (udf.dataType === 'DateTime') {
                    const valueFrom = sv ? (sv as DateUdf).valueFrom : null;
                    const valueTo = sv ? (sv as DateUdf).valueTo : null;
                    return (
                        <Box key={udf.id} display="flex" bgcolor="background.default" borderRadius="10px" p={1}>
                            <Box>
                                <Checkbox title="Toggle is essential" checked={isEssential} name={udf.name} onChange={udfIsEssentialChange} color="success" icon={<StarBorderIcon />} checkedIcon={<StarIcon />} />
                            </Box>
                            <Box display="flex" flexDirection="column" gap={1}>
                                {udf.agencyName}
                                <DatePicker label="From" value={valueFrom} onChange={d => udfFromDateValueChangeHandler(d, udf.name)} slotProps={{ textField: { sx: { width: '226px' } }, actionBar: { actions: ["clear", "today", "cancel", "accept"] } }}  />
                                <DatePicker label="To" value={valueTo} onChange={d => udfToDateValueChangeHandler(d, udf.name)} slotProps={{ textField: { sx: { width: '226px' } }, actionBar: { actions: ["clear", "today", "cancel", "accept"] } }} />
                            </Box>
                        </Box>
                    );
                }
                if (udf.dataType === 'Decimal') {
                    const valueFrom = sv ? (sv as DecimalUdf).valueFrom : '';
                    const valueTo = sv ? (sv as DecimalUdf).valueTo : '';
                    return (
                        <Box key={udf.id} display="flex" bgcolor="background.default" borderRadius="10px" p={1}>
                            <Box>
                                <Checkbox title="Toggle is essential" checked={isEssential} name={udf.name} onChange={udfIsEssentialChange} color="success" icon={<StarBorderIcon />} checkedIcon={<StarIcon />} />
                            </Box>
                            <Box display="flex" flexDirection="column" gap={1}>
                                {udf.agencyName}
                                <RWTextFieldComponent label="From" value={valueFrom} name={udf.name} onChange={udfFromDecimalValueChangeHandler} />
                                <RWTextFieldComponent label="To" value={valueTo} name={udf.name} onChange={udfToDecimalValueChangeHandler} />
                            </Box>
                        </Box>
                    );
                }
                return <React.Fragment key={udf.id} />;
            });
        }
    }, [fetchedConfig, availableCustomFields, customFieldsSearchValues, udfIsEssentialChange, udfStringValueChangeHandler, udfStringValueUpdate, udfFromDateValueChangeHandler, udfToDateValueChangeHandler, udfFromDecimalValueChangeHandler, udfToDecimalValueChangeHandler]);

    useEffect(() => {
        const item = document.querySelector<HTMLElement>('#candidate-search-collapse-root>.MuiCollapse-wrapper>.MuiCollapse-wrapperInner');
        if (item) {
            item.style.display = 'flex';
            item.style.flexDirection = 'column';
        }
    }, []);

    const handleTabChange = useCallback((e: React.SyntheticEvent, newValue: string) => {
        setActiveTab(newValue);
    }, []);

    return (
        <PageLayout SummaryBar={summaryBar} paddingTop={0}>
            <Snackbar open={successMessage !== ''} autoHideDuration={3000} onClose={() => setSuccessMessage('')}>
                <Alert onClose={() => setSuccessMessage('')}>{ successMessage }</Alert>
            </Snackbar>
            <Snackbar open={errorMessage !== ''} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert color="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <SaveSearchDialog open={showSaveSearchDialog} closeHandler={() => setShowSaveSearchDialog(false)} saveHandler={saveSearchCallback} />
            <Tabs value={activeTab} onChange={handleTabChange}>
                <Tab value="criteria" label="Criteria" />
                <Tab value="results" label="Results"/>
            </Tabs>
            <PageContentLayout showLoading={isLoading || isFetchingConfig || isFetchingSavedSearch || isFetchingSettings || isGridLoading}>
                <Stack spacing={2} paddingTop={2} display={activeTab === 'criteria' ? undefined : 'none'}>
                    <Box display="flex" gap={2} bgcolor="background.default" borderRadius="10px" p={1}>
                        <Checkbox title="Toggle is essential" color="success" checked={jobTitleEssential} onChange={() => setJobTitleEssential(prev => !prev)} icon={<StarBorderIcon />} checkedIcon={<StarIcon />} />
                        <RWTextFieldComponent label="Job Title" multiline={false} name="jobtitle" onChange={onUpdateTextComponentChange} value={jobTitle}></RWTextFieldComponent>
                        <RWTextFieldComponent label="Employer" multiline={false} name="employer" onChange={onUpdateTextComponentChange} value={employer}></RWTextFieldComponent>
                        <TextField select label="Time Range" value={timeRange} onChange={({target}) => setTimeRange(target.value)} fullWidth >
                            <MenuItem value="withinlast1job">Within Last 1 Job</MenuItem>
                            <MenuItem value="withinlast1yr">Within Last 1 Year</MenuItem>
                            <MenuItem value="withinlast2jobs">Within Last 2 Jobs</MenuItem>
                            <MenuItem value="withinlast2yrs">Within Last 2 Years</MenuItem>
                            <MenuItem value="withinlast3jobs">Within Last 3 Jobs</MenuItem>
                            <MenuItem value="withinlast3yrs">Within Last 3 Years</MenuItem>
                        </TextField>
                    </Box>
                    <Box display="flex" gap={2} bgcolor="background.default" borderRadius="10px" p={1}>
                        <Checkbox title="Toggle is essential" color="success" checked={cvTextEssential} onChange={() => setCvTextEssential(prev => !prev)} icon={<StarBorderIcon />} checkedIcon={<StarIcon />} />
                        <RWTextFieldComponent label="CV Text" multiline={false} name="cvText" onChange={onUpdateTextComponentChange} value={cvText}></RWTextFieldComponent>
                    </Box>
                    <Box display="flex" gap={2} bgcolor="background.default" borderRadius="10px" p={1}>
                        <Checkbox title="Toggle is essential" color="success" checked={summaryEssential} onChange={() => setSummaryEssential(prev => !prev)} icon={<StarBorderIcon />} checkedIcon={<StarIcon />} />
                        <RWTextFieldComponent label="Summary" multiline={false} name="summary" onChange={onUpdateTextComponentChange} value={summary}></RWTextFieldComponent>
                    </Box>
                    <Box display="flex" gap={2} bgcolor="background.default" borderRadius="10px" p={1}>
                        <Checkbox title="Toggle is essential" color="success" checked={locationEssential} onChange={() => setLocationEssential(prev => !prev)} icon={<StarBorderIcon />} checkedIcon={<StarIcon />} />
                        <LocationPicker noContainer fullWidth useDefaultCountry location={location} onLocationChange={ locationChangeHandler } innerSx={filterElementStyle} />
                        <TextField
                            label="Radius (Km)"
                            value={radius}
                            onChange={ radiusChangeHandler }
                            error={!isRadiusValidNumber && radius !== ''}
                            InputProps={{ endAdornment: <InputAdornment position="end">Km</InputAdornment> }}
                            sx={filterElementStyle}
                        />
                        {ssid && jobId && <FormControlLabel label="Applicants Only?" control={<Checkbox checked={isApplicantsOnly} onChange={() => setIsApplicantsOnly(prev => !prev)} />} />}
                    </Box>
                    <Box display="flex" gap={2} bgcolor="background.default" borderRadius="10px" p={1}>
                        <Checkbox title="Toggle is essential" color="success" checked={jobSeekingStatusEssential} onChange={() => setJobSeekingStatusEssential(prev => !prev)} icon={<StarBorderIcon />} checkedIcon={<StarIcon />} />
                        <TextField select label="Job Seeking Status" value={jobSeekingStatusId.toString()} onChange={({target}) => setJobSeekingStatusId(+target.value)} fullWidth >
                            <MenuItem value="0">All</MenuItem>
                            <MenuItem value="3">Not Currently Looking</MenuItem>
                            <MenuItem value="2">Open To Opportunities</MenuItem>
                            <MenuItem value="1">Seriously Looking</MenuItem>
                        </TextField>
                        <TextField select label="Availability" value={availability.toString()} onChange={({target}) => setAvailability(+target.value)} fullWidth >
                            <MenuItem value="0">All</MenuItem>
                            <MenuItem value="1">1 Day</MenuItem>
                            <MenuItem value="2">2 Days</MenuItem>
                            <MenuItem value="3">3 Days</MenuItem>
                            <MenuItem value="7">7 Days</MenuItem>
                            <MenuItem value="14">14 Days</MenuItem>
                            <MenuItem value="21">21 Days</MenuItem>
                            <MenuItem value="30">30 Days</MenuItem>
                            <MenuItem value="60">60 Days</MenuItem>
                            <MenuItem value="90">90 Days</MenuItem>
                            <MenuItem value="180">180 Days</MenuItem>
                            <MenuItem value="365">365 Days</MenuItem>
                        </TextField>
                        <TextField select label="Job Type" value={jobType.toString()} onChange={({target}) => setJobType(+target.value)} fullWidth >
                            <MenuItem value="0">All</MenuItem>
                            <MenuItem value="2">Permanent</MenuItem>
                            <MenuItem value="3">Contract / Temp</MenuItem>
                        </TextField>
                    </Box>
                    <Box bgcolor="background.default" borderRadius="10px" p={1}>
                        <DivisionPicker divisionId={division} onSelectCallback={d => d !== null && setDivision(d)} appendToStart={divisionSpecialOptions} />
                    </Box>
                    <Box bgcolor="background.default" borderRadius="10px" p={1}>
                        <TagsSearchFilter
                            entityId={3}
                            includeTags={includeTags}
                            excludeTags={excludeTags}
                            tagSource={taggedBy}
                            addHandler={handleAddCallback}
                            removeHandler={handleRemoveCallback}
                            setAvailableTagsHandler={taggedBy === 'a' ? setAvailableTags_All : setAvailableTags_Me}
                            loadingHandler={setIsLoading}
                            errorHandler={setErrorMessage}
                        >
                            <Box display="flex" gap={2} mb={2}>
                                <Box>
                                    <Checkbox title="Toggle is essential" color="success" checked={tagsEssential} onChange={() => setTagsEssential(prev => !prev)} icon={<StarBorderIcon />} checkedIcon={<StarIcon />} />
                                </Box>
                                <Box>
                                    <Box>
                                        Exclude
                                        <Switch checked={isIncludeTag} onChange={({target}) => setIsIncludeTag(target.checked)} />
                                        Include
                                    </Box>
                                    <TextField select label="Tagged By" value={taggedBy} onChange={ ({target}) => setTaggedBy(target.value === 'm' ? 'm' : 'a') }>
                                        <MenuItem value="m">Me</MenuItem>
                                        <MenuItem value="a">Everyone</MenuItem>
                                    </TextField>
                                    <TextField select label="Tagged Within" value={taggedWithin.toString()} onChange={ ({target}) => setTaggedWithin(+target.value) }>
                                        <MenuItem value="999">Anytime</MenuItem>
                                        <MenuItem value="1">1 Months</MenuItem>
                                        <MenuItem value="2">2 Months</MenuItem>
                                        <MenuItem value="3">3 Months</MenuItem>
                                        <MenuItem value="6">6 Months</MenuItem>
                                        <MenuItem value="12">1 Year</MenuItem>
                                        <MenuItem value="24">2 Years</MenuItem>
                                        <MenuItem value="36">3 Years</MenuItem>
                                    </TextField>
                                </Box>
                            </Box>
                        </TagsSearchFilter>
                    </Box>
                    <Box display="flex" flexWrap="wrap" gap={2}>
                        {renderedUdfs}
                    </Box>
                </Stack>
                <Box display={activeTab === 'results' ? 'flex' : 'none'} flexGrow={1} flexDirection="column">
                    {!Boolean(ssid) &&
                        <CandidatesGridComponent
                            gridName="candidate-search"
                            source="search"
                            searchData={searchData}
                            loadingHandler={setIsGridLoading}
                            errorHandler={setErrorMessage}
                            successHandler={setSuccessMessage}
                        />
                    }
                    {Boolean(ssid) &&
                        <CandidatesSavedSearchGridComponent
                            scrollToId={candidateId}
                            gridName="candidate-saved-search"
                            searchData={searchData}
                            loadingHandler={setIsGridLoading}
                            errorHandler={setErrorMessage}
                            successHandler={setSuccessMessage}
                        />
                    }
                </Box>
            </PageContentLayout>
        </PageLayout>
    );
}