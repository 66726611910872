import React, { useCallback, useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { MenuItem } from "@mui/material";
import { NameIdObj } from "../../../common/models/GenericTypes";
import {  GetJobStagesLookup, GetKanbanGroupsLookup } from "../../../services/ConfigurationService";
import {  JobCandidateStatusResponse } from "../../../common/models/Jobs/Job";

interface Props {
    jobCandidateStatus: JobCandidateStatusResponse | null,
    open: boolean,
    submitHandler: (jobCandidateStatus: JobCandidateStatusResponse) => void,
    closeHandler: () => void,
    loadingHandler?: (isLoading: boolean) => void
}

export default function JobCandidateStatusDialog({ open,jobCandidateStatus,submitHandler, closeHandler,loadingHandler }: Props) {
  const [kanbanGroups, setKanbanGroups] = useState<NameIdObj[]>([]);
  const [jobStages, setJobStages] = useState<NameIdObj[]>([]);
  const [selectedKanbanGroup, setSelectedKanbanGroup] = useState<NameIdObj | null>(null);
  const [selectedJobStage, setSelectedJobStage] = useState<NameIdObj | null>(null);
  const [selectedJobCandidateStatus, setSelectedJobCandidateStatus] = useState<JobCandidateStatusResponse | null>(null);
  const defaults = {id: 0, name: 'None'} as NameIdObj;
  useEffect(() => {
    const getData = async () => {
        loadingHandler && loadingHandler(true);

        let groups: NameIdObj[] | null = [];
        groups = await GetKanbanGroupsLookup();
        if (groups) {
          setKanbanGroups(groups);
        }

        let jobStages: NameIdObj[] | null = [];
        jobStages = await GetJobStagesLookup();
        if (jobStages) {
          jobStages.unshift(defaults);
          setJobStages(jobStages);
        }


        loadingHandler && loadingHandler(false);
    };
    
    getData();
}, [loadingHandler]);

    const [formValues, setFormValues] = useState({
        name: '',
        enabled: 'true',
      });
    
      const [errors, setErrors] = useState({
        name: '',
        jobCandidateKanbanGroupId: ''
      });

      useEffect(() => {
        if(jobCandidateStatus){
          setSelectedJobCandidateStatus(jobCandidateStatus);
          setFormValues({
            name: selectedJobCandidateStatus?.name ?? '',
            enabled: selectedJobCandidateStatus?.enabled == true ? 'true' : 'false',
          });

          if(selectedJobCandidateStatus?.jobStageId) {
            const jobStage = jobStages.find(j=>j.id == selectedJobCandidateStatus?.jobStageId);
            if(jobStage) {
              setSelectedJobStage(jobStage);
            }
          }else {
            setSelectedJobStage(defaults);
          }

          if(selectedJobCandidateStatus?.jobCandidateKanbanGroupId) {
            const kanbanGroupd = kanbanGroups.find(j=>j.id == selectedJobCandidateStatus?.jobCandidateKanbanGroupId);
            
            if(kanbanGroupd) {
              setSelectedKanbanGroup(kanbanGroupd);
            }
          }
        } else {
          setFormValues({
            name: '',
            enabled: 'true',
          });

          setSelectedJobStage(defaults);
          setSelectedKanbanGroup(null);
          setSelectedJobCandidateStatus(null);
        }
    }, [jobCandidateStatus,selectedJobCandidateStatus]);
    
      const handleChange = (e : any) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
        setErrors({ ...errors, [name]: '' });
      };
    
      const validate = () => {
        let tempErrors = { ...errors };
        tempErrors.name = formValues.name ? '' : 'Candidate Workflow Status name is required.';
        tempErrors.jobCandidateKanbanGroupId = selectedKanbanGroup != null ? '' : 'Kanban group is required.'
        setErrors(tempErrors);
        return Object.values(tempErrors).every(x => x === '');
      };

      const stringToBoolean = (str: string): boolean => {
        const truthyValues = ['true', '1', 'yes'];
        return truthyValues.includes(str.toLowerCase().trim());
    }

    const handleSubmit = () => {
        if (validate()) {
            let candidateStatus: JobCandidateStatusResponse = {
                name: formValues.name,
                enabled: stringToBoolean(formValues.enabled),
                id: jobCandidateStatus?.id ?? 0,
                statusOrder: jobCandidateStatus?.statusOrder ?? -1,
                jobCandidateKanbanGroupId: selectedKanbanGroup?.id ?? 0,
                jobStageId: selectedJobStage?.id ?? 0,
                jobCandidateKanbanGroup: selectedKanbanGroup?.name ?? '',
                jobStage: selectedJobStage?.name ?? '',
                systemStatus: false
            };
            submitHandler(candidateStatus);

            setFormValues({
              name: '',
              enabled: 'true',
            });
            
            setSelectedJobStage(defaults);
            setSelectedKanbanGroup(null);
            setSelectedJobCandidateStatus(null);

        }
      };

      const handleClose = () => {
        closeHandler();
      }

      const handleKanbanGroupChanged = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if(event) {
            const group = kanbanGroups.find(a=>a.id == +event.target.value);
            if(group)
                setSelectedKanbanGroup(group);
        }
      }

      const handleJobStageChanged = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if(event) {
            const jobStage = jobStages.find(a=>a.id == +event.target.value);
            if(jobStage)
                setSelectedJobStage(jobStage);
        }
      }

    return (
        <>
            <Dialog open={open} fullWidth>
                <DialogTitle>{jobCandidateStatus == null ? 'Add Candidate Workflow Status' : 'Edit Candidate Workflow Status'}</DialogTitle>
                <DialogContent>
                <TextField
                            margin="dense"
                            name="name"
                            label="Candidate Workflow Status Name"
                            type="text"
                            fullWidth
                            variant="outlined"
                            sx={{mb: '15px'}}
                            value={formValues.name}
                            onChange={handleChange}
                            error={!!errors.name}
                            helperText={errors.name}/>
                  <TextField
                            sx={{minWidth: '100%', mt: '10px'}}
                            select
                            label="Candidate Kanban Group"
                            name="kanbanGroups"
                            value={selectedKanbanGroup?.id ?? 0}
                            onChange={handleKanbanGroupChanged}
                            error={!!errors.jobCandidateKanbanGroupId}
                            helperText={errors.jobCandidateKanbanGroupId}
                            >
                          {kanbanGroups.map((a,index) => {
                            return <MenuItem key={index} value={a.id}>{a.name}</MenuItem>
                          })}
                </TextField>          
                <TextField
                            sx={{minWidth: '100%', mt: '10px'}}
                            select
                            label="Job Stage"
                            name="jogStages"
                            value={selectedJobStage?.id ?? 0}
                            onChange={handleJobStageChanged}>
                          {jobStages.map((a,index) => {
                            return <MenuItem key={index} value={a.id}>{a.name}</MenuItem>
                          })}
                </TextField>
                <TextField
                        sx={{minWidth: '100%', mt: '10px'}}
                        select
                        label="Status"
                        name="enabled"
                        value={formValues.enabled}
                        onChange={handleChange}
                    >
                        <MenuItem value="true">Enabled</MenuItem>
                        <MenuItem value="false">Disabled</MenuItem>
                    </TextField>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="error" onClick={ handleClose }>Cancel</Button>
                    <Button variant="contained" color="success" onClick={handleSubmit} >Submit</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}