import Alert from "components/Alert";
import Divider from "@mui/material/Divider";
import Snackbar from "@mui/material/Snackbar";
import React, { useEffect, useState } from "react";
import { UserIntegration } from "common/models/Configuration/UserIntegrations";
import UserIntegrationsGrid from "components/Integrations/UserIntegrationsGrid";
import OnlyTitleSummaryBar from "components/SummaryBars/OnlyTitleSummaryBar";
import PageContentLayout from "layouts/PageContentLayout";
import PageLayout from "layouts/PageLayout";
import { GetMyUserIntegrations } from "services/UserIntegrationsService";

export default function UserIntegrationsIndex() {
    const [summaryBar, setSummaryBar] = useState<JSX.Element>(<></>);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [activeIntegrations, setActiveIntegrations] = useState<UserIntegration[]>([]);
    const [availableIntegrations, setAvailableIntegrations] = useState<UserIntegration[]>([]);

    useEffect(() => {
        const summaryBar = <OnlyTitleSummaryBar title="Account > Integrations" browserTabTitle="Integrations > Account" />;
        setSummaryBar(summaryBar);
    }, []);

    useEffect(() => {
        const getData = async () => {
            setIsLoading(true);
            const res = await GetMyUserIntegrations(setErrorMessage);
            if (res) {
                let enabled: UserIntegration[] = [];
                let disabled: UserIntegration[] = [];
                
                for (let i = 0; i < res.length; i++) {
                    const e = res[i];
                    if (e.statusID === 1) {
                        enabled.push(e);
                        continue;
                    }
                    disabled.push(e);
                }
                
                setActiveIntegrations(enabled);
                setAvailableIntegrations(disabled);
            }
            setIsLoading(false);
        };
        getData();
    }, []);

    return (
        <PageLayout SummaryBar={summaryBar}>
            <Snackbar open={errorMessage !== ''} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <PageContentLayout title="Integrations" showLoading={isLoading}>
            {activeIntegrations.length > 0 && <UserIntegrationsGrid title="Active" items={activeIntegrations} pb="20px" />}
                {activeIntegrations.length > 0 && availableIntegrations.length > 0 && <Divider />}
                {availableIntegrations.length > 0 && <UserIntegrationsGrid title="Available" items={availableIntegrations} pt="20px" />}
            </PageContentLayout>
        </PageLayout>
    );
}