import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CancelIcon from '@mui/icons-material/Cancel';
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { PREVIEW_DRAGGABLE_TITLE_STYLE } from "util/Definitions/Constants/Previews";
import DraggablePaperComponent from "components/DraggablePaperComponent";
import DialogContent from "@mui/material/DialogContent";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { Editor } from "@tinymce/tinymce-react";
import * as tinymce from 'tinymce';
import { Ui } from 'tinymce';
import { useTheme } from "@mui/material/styles";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import { RegexIsValidEmail } from "util/RegExUtils";
import { GetEmailMessageById } from "services/MessagesService";
import { UpdateScheduledEmail } from "services/ScheduledMessagesService";
import RWTextFieldComponent from "components/RWTextFieldComponent";

interface Props {
    open: boolean,
    messageId: number,
    entityId: number,
    closeHandler: () => void,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
    successHandler?: (message: string) => void,
}

export default function EditScheduledEmailDialog({ open, messageId, entityId, closeHandler, loadingHandler, errorHandler, successHandler }: Props) {
    const [subject, setSubject] = useState('');
    const [initialSubject, setInitialSubject] = useState('');
    const [body, setBody] = useState('');
    const [allowCcField, setAllowCcField] = useState(false);
    const [ccRecipients, setCcRecipients] = useState<string[]>([]);
    const [initialCcValues, setInitialCcValues] = useState('');
    const [showValidation, setShowValidation] = useState(false);
    const [isEditorDirty, setIsEditorDirty] = useState(false);
    const [isJob, setIsJob] = useState(false);
    const [isPlacementList, setIsPlacementList] = useState(false);
    const [rerenderControl, setRerenderControl] = useState(false);
    const editorRef = useRef<tinymce.Editor | null>(null);
    const theme = useTheme();
    const isDarkTheme = useMemo(() => theme.palette.mode === 'dark', [theme.palette.mode]);

    useEffect(() => {
        if (!open) {
            setSubject('');
            setInitialSubject('');
            setBody('');
            setAllowCcField(false);
            setCcRecipients([]);
            setInitialCcValues('');
            setShowValidation(false);
            setIsEditorDirty(false);
        }
    }, [open]);

    useEffect(() => {
        if (entityId) {}
        setRerenderControl(prev => !prev);
    }, [entityId]);

    useEffect(() => {
        const getData = async () => {
            loadingHandler && loadingHandler(true);
            const res = await GetEmailMessageById(messageId, errorHandler);
            if (res) {
                setSubject(res.subject);
                setInitialSubject(res.subject);
                const ccString = res.cc ?? '';
                setInitialCcValues(ccString);
                setBody(res.body);
                setAllowCcField(Boolean(res.cc));
                setIsJob(Boolean(res.jobID));
                setIsPlacementList(Boolean(res.placementID));
            }
            loadingHandler && loadingHandler(false);
        };
        messageId && getData();
    }, [errorHandler, loadingHandler, messageId]);

    const onCcRecipientsChange = useCallback((values: string[]) => {        
        if (values.length > ccRecipients.length) {
            const last = values[values.length - 1];
            const existing = ccRecipients.find(e => e === last);
            if (existing) return;
            if (!RegexIsValidEmail(last)) return;
        }

        setCcRecipients(values);
    }, [ccRecipients]);

    const placeholders = useMemo<string[]>(() => {
        let placeholders: string[] = [];

        if (entityId === 2) { // CONTACTS
            placeholders = [
                '{Contact.FirstName}', '{Contact.LastName}', '{Contact.Email}',
                '|',
                '{User.FirstName}', '{User.Email}', '{User.DeskPhone}', '{User.Mobile}'
            ];

            if (isPlacementList) {
                placeholders = [
                    '{Candidate.FirstName}', '{Candidate.LastName}', '{Candidate.Email}', '{Candidate.UpdateRequestUrl}',
                    '|',
                    '{User.FirstName}', '{User.Email}', '{User.DeskPhone}', '{User.Mobile}',
                    '|',
                    '{Placement.BillingContact.FirstName}', '{Placement.BillingContact.LastName}', '{Placement.ChargeRate}', '{Placement.ClientName}', '{Placement.Contact.FirstName}', '{Placement.Contact.LastName}', '{Placement.EndDate}', '{Placement.FullAddress}', '{Placement.HiringManager.FirstName}', '{Placement.HiringManager.LastName}', '{Placement.JobTitle}', '{Placement.PayRate}', '{Placement.Salary}', '{Placement.StartDate}'
                ];
            }
            else if (isJob) {
                placeholders = [
                    '{Candidate.FirstName}', '{Candidate.LastName}', '{Candidate.Email}', '{Candidate.UpdateRequestUrl}',
                    '|',
                    '{Job.JobID}', '{Job.Title}', '{Job.Duration}',
                    '|',
                    '{Job.Client.Website}', '{Job.Client.TradingName}',
                    '|',
                    '{Job.ClientContact.JobTitle}', '{Job.ClientContact.FirstName}', '{Job.ClientContact.LastName}', '{Job.ClientContact.LinkedInURL}',
                    '|',
                    '{Job.HiringManager.JobTitle}', '{Job.HiringManager.FirstName}', '{Job.HiringManager.LastName}', '{Job.HiringManager.LinkedInURL}',
                    '|',
                    '{User.FirstName}', '{User.Email}', '{User.DeskPhone}', '{User.Mobile}'
                ];
            }
        }
        else if (entityId === 3) { // CANDIDATE  TODO: Candidate placeholders
            placeholders = [
                '{Candidate.FirstName}', '{Candidate.LastName}', '{Candidate.Email}', '{Candidate.UpdateRequestUrl}',
                '|',
                '{User.FirstName}', '{User.Email}', '{User.DeskPhone}', '{User.Mobile}'
            ];
            if (isPlacementList) {
                placeholders = [
                    '{Candidate.FirstName}', '{Candidate.LastName}', '{Candidate.Email}', '{Candidate.UpdateRequestUrl}',
                    '|',
                    '{User.FirstName}', '{User.Email}', '{User.DeskPhone}', '{User.Mobile}',
                    '|',
                    '{Placement.BillingContact.FirstName}', '{Placement.BillingContact.LastName}', '{Placement.ChargeRate}', '{Placement.ClientName}', '{Placement.Contact.FirstName}', '{Placement.Contact.LastName}', '{Placement.EndDate}', '{Placement.FullAddress}', '{Placement.HiringManager.FirstName}', '{Placement.HiringManager.LastName}', '{Placement.JobTitle}', '{Placement.PayRate}', '{Placement.Salary}', '{Placement.StartDate}'
                ];
            }
            else if (isJob) {
                placeholders = [
                    '{Candidate.FirstName}', '{Candidate.LastName}', '{Candidate.Email}', '{Candidate.UpdateRequestUrl}',
                    '|',
                    '{Job.JobID}', '{Job.Title}', '{Job.Duration}',
                    '|',
                    '{Job.Client.Website}', '{Job.Client.TradingName}',
                    '|',
                    '{Job.ClientContact.JobTitle}', '{Job.ClientContact.FirstName}', '{Job.ClientContact.LastName}', '{Job.ClientContact.LinkedInURL}',
                    '|',
                    '{Job.HiringManager.JobTitle}', '{Job.HiringManager.FirstName}', '{Job.HiringManager.LastName}', '{Job.HiringManager.LinkedInURL}',
                    '|',
                    '{User.FirstName}', '{User.Email}', '{User.DeskPhone}', '{User.Mobile}'
                ];
            }
        }

        return placeholders;
    }, [entityId, isJob, isPlacementList]);

    const editor = useMemo(() => {
        if (open) {
            return (
                <Editor
                    initialValue={body}
                    onDirty={ () => setIsEditorDirty(true) }
                    onInit={ (evt, editor) => editorRef.current = editor }
                    tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce-5.10.2/tinymce.min.js'}
                    init={{
                        height: 380,
                        skin: isDarkTheme ? 'oxide-dark' : undefined,
                        content_css: isDarkTheme ? 'dark' : undefined,
                        branding: false,
                        menubar: false,
                        statusbar: false,
                        contextmenu: false,
                        resize: false,
                        browser_spellcheck: true,
                        plugins: 'powerpaste code link emoticons table print preview visualchars lists fullscreen',
                        powerpaste_word_import: 'prompt',
                        powerpaste_html_import: 'prompt',
                        powerpaste_allow_local_images: true,
                        fontsize_formats: "8pt 9pt 10pt 12pt 14pt 18pt 24pt 36pt",
                        toolbar1: 'placeholders fontsizeselect fontselect styleselect forecolor backcolor bold italic alignleft aligncenter alignright alignjustify bullist numlist code fullscreen link mybutton table',
                        font_formats: 'Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Calibri=calibri; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Segoe=segoe,segoe ui,dejavu sans,trebuchet ms,verdana,sans-serif; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva',
                        setup: editor => {
                            editor.ui.registry.addMenuButton('placeholders', {
                                text: 'Placeholders',
                                fetch: callback =>{
                                    let menuItems: Ui.Menu.NestedMenuItemContents[] = [];
                                    for (let i = 0; i < placeholders.length; i++) {
                                        const p = placeholders[i];
                                        if (p === '|')
                                            menuItems.push(p);
                                        else
                                            menuItems.push({ type: 'menuitem', text: p, onAction: function () { editor.insertContent(p) } });
                                    }
                                    callback(menuItems);
                                }
                            });
                        }
                    }}
                />
            );
        }
        return <></>;
    }, [body, isDarkTheme, open, placeholders]);

    const ccValuesChanged = useMemo(() => {
        const ccString = ccRecipients.join(';');
        return initialCcValues !== ccString;
    }, [ccRecipients, initialCcValues]);

    const submitCallback = useCallback(async () => {
        const api = editorRef.current;
        if (api) {
            const content = api.getContent();
            if (!Boolean(subject.trim())) {
                setShowValidation(true);
                errorHandler && errorHandler('Subject cannot be empty');
                return false;
            }
    
            if (!Boolean(content.trim())) {
                setShowValidation(true);
                errorHandler && errorHandler('Body cannot be empty');
                return false;
            }
    
            loadingHandler && loadingHandler(true);
            const res = await UpdateScheduledEmail(messageId, subject, content, ccRecipients, errorHandler);
            if (res) {
                successHandler && successHandler('Message Saved');
                closeHandler();
            }
            loadingHandler && loadingHandler(false);

        }
    }, [ccRecipients, closeHandler, errorHandler, loadingHandler, messageId, subject, successHandler]);

    return (
        <Dialog open={open} maxWidth="lg" fullWidth PaperComponent={DraggablePaperComponent} aria-labelledby="draggable-dialog-title">
            <DialogTitle component="div" display="flex" sx={PREVIEW_DRAGGABLE_TITLE_STYLE} id="draggable-dialog-title" >
                <Box alignSelf="center">Scheduled Message</Box >
                <Box ml="auto" style={{ marginLeft: 'auto' }}>
                    <IconButton size="small" onClick={ closeHandler } sx={{ color: t => t.palette.primary.contrastText }}><CancelIcon /></IconButton>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Stack spacing={2} pt={2}>
                    {allowCcField &&
                        <Autocomplete
                            value={ccRecipients}
                            multiple
                            size="small"
                            disableCloseOnSelect
                            options={[]}
                            selectOnFocus
                            clearOnBlur
                            autoSelect
                            freeSolo
                            onChange={(e,v,r,d) => onCcRecipientsChange(v)}
                            renderInput={(params) => ( <TextField {...params} label="CC" /> ) }
                        />
                    }
                    <RWTextFieldComponent
                        label="Subject"
                        value={subject}
                        onChange={ ({target}) => setSubject(target.value) }
                        isError={showValidation && subject === ''}
                    />
                    <>
                        {rerenderControl && editor}
                        {!rerenderControl && editor}
                    </>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="success" disabled={!isEditorDirty && subject === initialSubject && !ccValuesChanged} onClick={submitCallback}>Save</Button>
            </DialogActions>
        </Dialog>
    );
}