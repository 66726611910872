import React, { useCallback, useEffect, useMemo, useState } from "react";
import Alert from "components/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import useObjectStateWithChangeTracker from "hooks/UseObjectStateWithChangeTracker";
import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import PageContentLayout from "layouts/PageContentLayout";

import { GeneralConfigOptions, SendEmailSettings } from "common/models/Configuration/General";
import { DefaultOptions, NoChangesTracker } from "util/Definitions/Configuration/General";
import { GetGeneralSettings, SaveGeneralSettings } from "services/ConfigurationService";
import { GetCountries, GetCurrencies } from "services/CommonService";
import { Country, Currency } from "common/models/Common";
import { ObjToSettingsArray_Ignore } from "util/SettingsUtil";
import useUnsavedChangesDialog from "hooks/UseUnsavedChangesDialog";
import Box from "@mui/material/Box";
import InfoButtonToolTip from "components/InfoButtonToolTip";
import { RegexIsValidUrl } from "util/RegExUtils";
import RWTextFieldComponent from "components/RWTextFieldComponent";

interface Props {
    setSummaryBar?: (summaryBar: JSX.Element) => void
}

const urlValidation = (url: string) => {
    console.log('VALIDATING');
    if (url === '' || url === null || url === undefined) return true;
    return RegexIsValidUrl(url);
};

const DefaultCountry: Country = { countryCode: 'AU', countryName: 'Australia', countryID: 11, countryNationality: 'Australian' };
const DefaultCurrency: Currency = { code: "AUD", description: "Australian Dollar", formattedDescription: "Australian Dollar (AUD)", id: 9 };

export default function General({ setSummaryBar }: Props) {
    const [isLoading, setIsLoading] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [setupData, setSetupData] = useState<{co: Country[], cu: Currency[]}>({co: [], cu: []});
    const [showValidation, setShowValidation] = useState(false);
    const { state, init, change, updateInitial, hasChanges } = useObjectStateWithChangeTracker<GeneralConfigOptions>(DefaultOptions, NoChangesTracker);

    useEffect(() => {
        const getSettings = async () => {
            setIsLoading(true);
            const data = await GetGeneralSettings(setErrorMessage);
            const countries = await GetCountries();
            const currencies = await GetCurrencies();
            let sendEmailJson = '';

            if (data) {
                let savedSettings = DefaultOptions;
                for (let i = 0; i < data.length; i++) {
                    const setting = data[i];

                    if (setting.type === "DialogSettings_SendEmail") {
                        sendEmailJson = setting.value;
                        continue;
                    }

                    (savedSettings as any)[setting.type] = setting.value;
                }
                setSetupData({ co: countries ?? [], cu: currencies ?? [] });
                if(sendEmailJson) {
                    const emailSettings: SendEmailSettings = JSON.parse(sendEmailJson);
                    savedSettings.IsConfirmToSend = emailSettings.IsConfirmToSend ? "true" : "false";
                    savedSettings.EmailLimit = emailSettings.EmailLimit;
                }

                init(savedSettings);
            }
            setIsLoading(false);
        }
        getSettings();
    }, [init]);

    const isWebsiteUrlValid = useMemo(() => urlValidation(state.WebsiteURL), [state.WebsiteURL]);
    const isJobsUrlValid = useMemo(() => urlValidation(state.JobsURL), [state.JobsURL]);
    const isContactUsUrlValid = useMemo(() => urlValidation(state.ContactUsURL), [state.ContactUsURL]);
    const isPrivacyPolicyUrlValid = useMemo(() => urlValidation(state.PrivacyURL), [state.PrivacyURL]);
    const isApplicationSuccessUrlValid = useMemo(() => urlValidation(state.ApplicationSuccessURL), [state.ApplicationSuccessURL]);
    const isLinkedInUrlValid = useMemo(() => urlValidation(state.LinkedInURL), [state.LinkedInURL]);
    const isTwitterUrlValid = useMemo(() => urlValidation(state.TwitterURL), [state.TwitterURL]);
    const isFacebookUrlValid = useMemo(() => urlValidation(state.FacebookURL), [state.FacebookURL]);

    const saveSettings = useCallback(async () => {
        if (!isWebsiteUrlValid) {
            setShowValidation(true);
            setErrorMessage('Website URL not valid');
            return false;
        }
        
        if (!isJobsUrlValid) {
            setShowValidation(true);
            setErrorMessage('Jobs URL not valid');
            return false;
        }
        
        if (!isContactUsUrlValid) {
            setShowValidation(true);
            setErrorMessage('Contact Us URL not valid');
            return false;
        }
        
        if (!isPrivacyPolicyUrlValid) {
            setShowValidation(true);
            setErrorMessage('Privacy Policy URL not valid');
            return false;
        }
        
        if (!isApplicationSuccessUrlValid) {
            setShowValidation(true);
            setErrorMessage('Application Success URL not valid');
            return false;
        }
        
        if (!isLinkedInUrlValid) {
            setShowValidation(true);
            setErrorMessage('LinkedIn URL not valid');
            return false;
        }
        
        if (!isTwitterUrlValid) {
            setShowValidation(true);
            setErrorMessage('Twitter URL not valid');
            return false;
        }
        
        if (!isFacebookUrlValid ) {
            setShowValidation(true);
            setErrorMessage('Facebook URL not valid');
            return false;
        }

        setIsLoading(true);
        const sendEmailSettings: SendEmailSettings = {
            IsConfirmToSend: state.IsConfirmToSend === "true",
            EmailLimit: +state.EmailLimit
        };

        const settings = ObjToSettingsArray_Ignore<GeneralConfigOptions>(state, ["IsConfirmToSend", "EmailLimit"]);
        settings.push({ type: "DialogSettings_SendEmail", value: JSON.stringify(sendEmailSettings) });

        const res = await SaveGeneralSettings(settings, setErrorMessage);

        if (!res) {
            setIsLoading(false);
            return false;
        }

        updateInitial();
        setShowSuccess(true);
        setIsLoading(false);
        return true;
    }, [isApplicationSuccessUrlValid, isContactUsUrlValid, isFacebookUrlValid, isJobsUrlValid, isLinkedInUrlValid, isPrivacyPolicyUrlValid, isTwitterUrlValid, isWebsiteUrlValid, state, updateInitial]);

    const { unsavedChangesDialog } = useUnsavedChangesDialog(hasChanges, saveSettings);

    const handleChanges = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        change(name as keyof GeneralConfigOptions, value);
    }, [change]);

    useEffect(() => {
        const saveButton = (
            <Button
                disabled={!hasChanges}
                variant="contained"
                color="primary"
                onClick={saveSettings}
                sx={{ borderRadius: '2px', padding: 0, height: '36px', width: '80px' }}
            >
                Save
            </Button>
        );

        const SummaryBar = (
            <TitleAndActionSummaryBar
                title="Configuration > General"
                browserTabTitle="General > Configuration"
                action={saveButton}
            />);

        setSummaryBar && setSummaryBar(SummaryBar);
    }, [hasChanges, saveSettings, setSummaryBar]);

    return (
        <>
            {unsavedChangesDialog}
            <Snackbar open={showSuccess} autoHideDuration={3000} onClose={() => setShowSuccess(false)}>
                <Alert onClose={() => setShowSuccess(false)}>Changes Saved</Alert>
            </Snackbar>
            <Snackbar open={errorMessage !== ''}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <PageContentLayout title="General Settings" showLoading={isLoading} >
                <Stack spacing={3}>
                    <RWTextFieldComponent
                        label="Customer Name"
                        name="CustomerName"
                        value={state.CustomerName}
                        onChange={handleChanges}
                    />
                    <RWTextFieldComponent
                        label="Trading Name"
                        name="TradingName"
                        value={state.TradingName}
                        onChange={handleChanges}
                    />
                    <Autocomplete
                        size="small"
                        disableClearable
                        value={setupData.co.length > 0 ? (setupData.co.find(v => v.countryCode === state.DefaultCountry)) : DefaultCountry}
                        onChange={(e,v) => change("DefaultCountry", v.countryCode)}
                        options={setupData.co}
                        getOptionLabel={o => o.countryName}
                        isOptionEqualToValue={(o, v) => o.countryCode === v.countryCode}
                        renderInput={(params) => <TextField {...params} label="Default Country" />}
                    />
                    <Autocomplete
                        size="small"
                        disableClearable
                        value={setupData.cu.length > 0 ? (setupData.cu.find(v => v.id === parseInt(state.Currency))) : DefaultCurrency}
                        onChange={(e,v) => change("Currency", v.id.toString())}
                        options={setupData.cu}
                        getOptionLabel={o => `${o.description} (${o.code})`}
                        isOptionEqualToValue={(o, v) => o.id === v.id}
                        renderInput={(params) => <TextField {...params} label="Default Currency" />}
                    />
                    <TextField
                        select
                        label="Confirmation to Send Emails?"
                        name="IsConfirmToSend"
                        value={state.IsConfirmToSend}
                        onChange={handleChanges}
                    >
                        <MenuItem value="true">Enabled</MenuItem>
                        <MenuItem value="false">Disabled</MenuItem>
                    </TextField>
                    <RWTextFieldComponent
                        type="number"
                        label="Email Confirmation Limit"
                        name="EmailLimit"
                        value={state.EmailLimit?.toString()}
                        onChange={handleChanges}
                    />
                    <TextField
                        select
                        label="Electronic Signing Provider"
                        name="ElectronicSigningProvider"
                        value={state.ElectronicSigningProvider}
                        onChange={handleChanges}
                    >
                        <MenuItem value="0">-- Select --</MenuItem>
                        <MenuItem value="1">Recruit Wizard Signing (HelloSign) - Depreciated</MenuItem>
                        <MenuItem value="4">Recruit Wizard Signing (GoodSign)</MenuItem>
                    </TextField>
                    <TextField
                        select
                        label="Labour Hire"
                        name="LabourHire"
                        value={state.LabourHire}
                        onChange={handleChanges}
                    >
                        <MenuItem value="True">Enabled</MenuItem>
                        <MenuItem value="False">Disabled</MenuItem>
                    </TextField>
                    <TextField
                        select
                        label="Executive Search"
                        name="ExecutiveSearch"
                        value={state.ExecutiveSearch}
                        onChange={handleChanges}
                    >
                        <MenuItem value="True">Enabled</MenuItem>
                        <MenuItem value="False">Disabled</MenuItem>
                    </TextField>
                    <TextField
                        select
                        label="Invoicing"
                        name="IsInvoicingEnabled"
                        value={state.IsInvoicingEnabled}
                        onChange={handleChanges}
                    >
                        <MenuItem value="True">Enabled</MenuItem>
                        <MenuItem value="False">Disabled</MenuItem>
                    </TextField>
                    <TextField
                        select
                        label="Compliance"
                        name="IsComplianceEnabled"
                        value={state.IsComplianceEnabled}
                        onChange={handleChanges}
                    >
                        <MenuItem value="True">Enabled</MenuItem>
                        <MenuItem value="False">Disabled</MenuItem>
                    </TextField>
                    <TextField
                        select
                        label="Hide Contact Details from Interview Communications"
                        name="HideDetailsInterviewCommunications"
                        value={state.HideDetailsInterviewCommunications}
                        onChange={handleChanges}
                    >
                        <MenuItem value="True">Enabled</MenuItem>
                        <MenuItem value="False">Disabled</MenuItem>
                    </TextField>
                    <TextField
                        select
                        label="Compliance"
                        name="IsComplianceEnabled"
                        value={state.IsComplianceEnabled}
                        onChange={handleChanges}
                    >
                        <MenuItem value="True">Enabled</MenuItem>
                        <MenuItem value="False">Disabled</MenuItem>
                    </TextField>
                    <RWTextFieldComponent
                        label="Candidate Response Email"
                        name="CandidateResponseEmail"
                        value={state.CandidateResponseEmail}
                        onChange={handleChanges}
                    />
                    <RWTextFieldComponent
                        label="Website URL"
                        name="WebsiteURL"
                        value={state.WebsiteURL}
                        isError={showValidation && !isWebsiteUrlValid}
                        onChange={handleChanges}
                    />
                    <RWTextFieldComponent
                        label="Jobs URL"
                        name="JobsURL"
                        value={state.JobsURL}
                        isError={showValidation && !isJobsUrlValid}
                        onChange={handleChanges}
                    />
                    <RWTextFieldComponent
                        label="Contact Us URL"
                        name="ContactUsURL"
                        value={state.ContactUsURL}
                        isError={showValidation && !isContactUsUrlValid}
                        onChange={handleChanges}
                    />
                    <RWTextFieldComponent
                        label="Privacy Policy URL"
                        name="PrivacyURL"
                        value={state.PrivacyURL}
                        isError={showValidation && !isPrivacyPolicyUrlValid}
                        onChange={handleChanges}
                    />
                    <Box display="flex">
                        <RWTextFieldComponent
                            label="Application Success URL"
                            name="ApplicationSuccessURL"
                            value={state.ApplicationSuccessURL}
                            isError={showValidation && !isApplicationSuccessUrlValid}
                            onChange={handleChanges}
                            sxOptions={{ flex: '1 1 0', pr: '5px' }}
                        />
                        <InfoButtonToolTip text="When an Application is successfully submitted using the Recruit Wizard Hosted Application Form, the applicant will be redirected here. If this is blank, they will be redirected to the 'Jobs URL' above." />
                    </Box>
                    <RWTextFieldComponent
                        label="LinkedIn URL"
                        name="LinkedInURL"
                        value={state.LinkedInURL}
                        isError={showValidation && !isLinkedInUrlValid}
                        onChange={handleChanges}
                    />
                    <RWTextFieldComponent
                        label="Twitter URL"
                        name="TwitterURL"
                        value={state.TwitterURL}
                        isError={showValidation && !isTwitterUrlValid}
                        onChange={handleChanges}
                    />
                    <RWTextFieldComponent
                        label="Facebook URL"
                        name="FacebookURL"
                        value={state.FacebookURL}
                        isError={showValidation && !isFacebookUrlValid}
                        onChange={handleChanges}
                    />
                    <RWTextFieldComponent
                        label="Corporate Colour Code"
                        name="CorporateColourCode"
                        value={state.CorporateColourCode}
                        onChange={handleChanges}
                    />
                    <TextField
                        select
                        label="Auto Save Candidate Searches"
                        name="AutoSaveCandidateSearches"
                        value={state.AutoSaveCandidateSearches}
                        onChange={handleChanges}
                    >
                        <MenuItem value="True">Enabled</MenuItem>
                        <MenuItem value="False">Disabled</MenuItem>
                    </TextField>
                    <TextField
                        select
                        label="Who Can Merge Records?"
                        name="WhoCanMergeRecords"
                        value={state.WhoCanMergeRecords}
                        onChange={handleChanges}
                    >
                        <MenuItem value="Anyone">Anyone (default)</MenuItem>
                        <MenuItem value="DataMaintenanceAdministrator">Data Maintenance Administrator</MenuItem>
                    </TextField>
                </Stack>
            </PageContentLayout>
        </>
    );
}