import { PanelModel } from "@syncfusion/ej2-layouts/src/dashboard-layout/dashboard-layout-model";
import { ScreenLayoutSettings } from "common/models/ScreenLayouts/ScreenLayouts";
import { GetCustomFieldTypeById } from "../ScreenLayouts/CustomFields";
import { JobRecordDashboardElementDefinition, JobRecordDashboardElementType } from "common/models/Dashboard/EditLayout";

export const DefaultJobQuickViewLayoutElements_Permanent: JobRecordDashboardElementDefinition[] = [
    { id: "JobId_Default", row: 0, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 6, maxSizeY: null, type: 'JobId' },
    { id: "JobType_Default", row: 1, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 6, maxSizeY: null, type: 'JobType' },
    { id: "JobExclusive_Default", row: 2, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 6, maxSizeY: null, type: 'JobExclusive' },
    { id: "JobQuantity_Default", row: 3, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 6, maxSizeY: null, type: 'JobQuantity' },
    { id: "JobStartDate_Default", row: 4, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 6, maxSizeY: null, type: 'JobStartDate' },
    { id: "JobDeadline_Default", row: 5, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 6, maxSizeY: null, type: 'JobDeadline' },
    { id: "JobStatus_Default", row: 6, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 6, maxSizeY: null, type: 'JobStatus' },
    { id: "JobRating_Default", row: 7, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 6, maxSizeY: null, type: 'JobRating' },
    
    { id: "JobLocation_Default", row: 0, col: 2, sizeX: 2, sizeY: 9, minSizeX: 1, minSizeY: 1, maxSizeX: 6, maxSizeY: null, type: 'JobLocation' },

    { id: "JobClient_Default", row: 0, col: 4, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 6, maxSizeY: null, type: 'JobClient' },
    { id: "JobContact_Default", row: 1, col: 4, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 6, maxSizeY: null, type: 'JobContact' },
    { id: "JobHiringManager_Default", row: 2, col: 4, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 6, maxSizeY: null, type: 'JobHiringManager' },
    { id: "JobBilling1_Default", row: 3, col: 4, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 6, maxSizeY: null, type: 'JobBilling1' },
    { id: "JobConsultant1_Default", row: 4, col: 4, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 6, maxSizeY: null, type: 'JobConsultant1' },
    { id: "JobConsultant2_Default", row: 5, col: 4, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 6, maxSizeY: null, type: 'JobConsultant2' },
    
    { id: "Spacer_Default", row: 9, col: 0, sizeX: 6, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 6, maxSizeY: null, type: 'Spacer' },
    { id: "JobSummaryFullWidth_Default", row: 10, col: 0, sizeX: 6, sizeY: 6, minSizeX: 1, minSizeY: 1, maxSizeX: 6, maxSizeY: null, type: 'JobSummaryFullWidth' },

];

export const DefaultJobQuickViewLayoutElements_Contract: JobRecordDashboardElementDefinition[] = [
    { id: "JobId_Default", row: 0, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 6, maxSizeY: null, type: 'JobId' },
    { id: "JobType_Default", row: 1, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 6, maxSizeY: null, type: 'JobType' },
    { id: "JobExclusive_Default", row: 2, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 6, maxSizeY: null, type: 'JobExclusive' },
    { id: "JobQuantity_Default", row: 3, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 6, maxSizeY: null, type: 'JobQuantity' },
    { id: "JobStartDate_Default", row: 4, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 6, maxSizeY: null, type: 'JobStartDate' },
    { id: "JobDeadline_Default", row: 5, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 6, maxSizeY: null, type: 'JobDeadline' },
    { id: "JobStatus_Default", row: 6, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 6, maxSizeY: null, type: 'JobStatus' },
    { id: "JobRating_Default", row: 7, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 6, maxSizeY: null, type: 'JobRating' },
    
    { id: "JobLocation_Default", row: 0, col: 2, sizeX: 2, sizeY: 9, minSizeX: 1, minSizeY: 1, maxSizeX: 6, maxSizeY: null, type: 'JobLocation' },

    { id: "JobClient_Default", row: 0, col: 4, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 6, maxSizeY: null, type: 'JobClient' },
    { id: "JobContact_Default", row: 1, col: 4, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 6, maxSizeY: null, type: 'JobContact' },
    { id: "JobHiringManager_Default", row: 2, col: 4, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 6, maxSizeY: null, type: 'JobHiringManager' },
    { id: "JobBilling1_Default", row: 3, col: 4, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 6, maxSizeY: null, type: 'JobBilling1' },
    { id: "JobConsultant1_Default", row: 4, col: 4, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 6, maxSizeY: null, type: 'JobConsultant1' },
    { id: "JobConsultant2_Default", row: 5, col: 4, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 6, maxSizeY: null, type: 'JobConsultant2' },
    
    { id: "Spacer_Default", row: 9, col: 0, sizeX: 6, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 6, maxSizeY: null, type: 'Spacer' },
    { id: "JobSummaryFullWidth_Default", row: 10, col: 0, sizeX: 6, sizeY: 6, minSizeX: 1, minSizeY: 1, maxSizeX: 6, maxSizeY: null, type: 'JobSummaryFullWidth' },

];

export const DefaultJobQuickViewLayoutElements_FixedContract: JobRecordDashboardElementDefinition[] = [
    { id: "JobId_Default", row: 0, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 6, maxSizeY: null, type: 'JobId' },
    { id: "JobType_Default", row: 1, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 6, maxSizeY: null, type: 'JobType' },
    { id: "JobExclusive_Default", row: 2, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 6, maxSizeY: null, type: 'JobExclusive' },
    { id: "JobQuantity_Default", row: 3, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 6, maxSizeY: null, type: 'JobQuantity' },
    { id: "JobStartDate_Default", row: 4, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 6, maxSizeY: null, type: 'JobStartDate' },
    { id: "JobDeadline_Default", row: 5, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 6, maxSizeY: null, type: 'JobDeadline' },
    { id: "JobStatus_Default", row: 6, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 6, maxSizeY: null, type: 'JobStatus' },
    { id: "JobRating_Default", row: 7, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 6, maxSizeY: null, type: 'JobRating' },
    
    { id: "JobLocation_Default", row: 0, col: 2, sizeX: 2, sizeY: 9, minSizeX: 1, minSizeY: 1, maxSizeX: 6, maxSizeY: null, type: 'JobLocation' },

    { id: "JobClient_Default", row: 0, col: 4, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 6, maxSizeY: null, type: 'JobClient' },
    { id: "JobContact_Default", row: 1, col: 4, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 6, maxSizeY: null, type: 'JobContact' },
    { id: "JobHiringManager_Default", row: 2, col: 4, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 6, maxSizeY: null, type: 'JobHiringManager' },
    { id: "JobBilling1_Default", row: 3, col: 4, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 6, maxSizeY: null, type: 'JobBilling1' },
    { id: "JobConsultant1_Default", row: 4, col: 4, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 6, maxSizeY: null, type: 'JobConsultant1' },
    { id: "JobConsultant2_Default", row: 5, col: 4, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 6, maxSizeY: null, type: 'JobConsultant2' },
    
    { id: "Spacer_Default", row: 9, col: 0, sizeX: 6, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 6, maxSizeY: null, type: 'Spacer' },
    { id: "JobSummaryFullWidth_Default", row: 10, col: 0, sizeX: 6, sizeY: 6, minSizeX: 1, minSizeY: 1, maxSizeX: 6, maxSizeY: null, type: 'JobSummaryFullWidth' },

];

export const DefaultJobQuickViewLayoutElements_TalentPool: JobRecordDashboardElementDefinition[] = [
    { id: "JobId_Default", row: 0, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 6, maxSizeY: null, type: 'JobId' },
    { id: "JobType_Default", row: 1, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 6, maxSizeY: null, type: 'JobType' },
    { id: "JobExclusive_Default", row: 2, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 6, maxSizeY: null, type: 'JobExclusive' },
    { id: "JobQuantity_Default", row: 3, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 6, maxSizeY: null, type: 'JobQuantity' },
    { id: "JobStartDate_Default", row: 4, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 6, maxSizeY: null, type: 'JobStartDate' },
    { id: "JobDeadline_Default", row: 5, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 6, maxSizeY: null, type: 'JobDeadline' },
    { id: "JobStatus_Default", row: 6, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 6, maxSizeY: null, type: 'JobStatus' },
    { id: "JobRating_Default", row: 7, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 6, maxSizeY: null, type: 'JobRating' },
    
    { id: "JobLocation_Default", row: 0, col: 2, sizeX: 2, sizeY: 9, minSizeX: 1, minSizeY: 1, maxSizeX: 6, maxSizeY: null, type: 'JobLocation' },

    { id: "JobClient_Default", row: 0, col: 4, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 6, maxSizeY: null, type: 'JobClient' },
    { id: "JobContact_Default", row: 1, col: 4, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 6, maxSizeY: null, type: 'JobContact' },
    { id: "JobHiringManager_Default", row: 2, col: 4, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 6, maxSizeY: null, type: 'JobHiringManager' },
    { id: "JobBilling1_Default", row: 3, col: 4, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 6, maxSizeY: null, type: 'JobBilling1' },
    { id: "JobConsultant1_Default", row: 4, col: 4, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 6, maxSizeY: null, type: 'JobConsultant1' },
    { id: "JobConsultant2_Default", row: 5, col: 4, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 6, maxSizeY: null, type: 'JobConsultant2' },
    
    { id: "Spacer_Default", row: 9, col: 0, sizeX: 6, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 6, maxSizeY: null, type: 'Spacer' },
    { id: "JobSummaryFullWidth_Default", row: 10, col: 0, sizeX: 6, sizeY: 6, minSizeX: 1, minSizeY: 1, maxSizeX: 6, maxSizeY: null, type: 'JobSummaryFullWidth' },

];

export const DefaultJobQuickViewLayoutElements_Panel: JobRecordDashboardElementDefinition[] = [
    { id: "JobId_Default", row: 0, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 6, maxSizeY: null, type: 'JobId' },
    { id: "JobType_Default", row: 1, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 6, maxSizeY: null, type: 'JobType' },
    { id: "JobExclusive_Default", row: 2, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 6, maxSizeY: null, type: 'JobExclusive' },
    { id: "JobQuantity_Default", row: 3, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 6, maxSizeY: null, type: 'JobQuantity' },
    { id: "JobStartDate_Default", row: 4, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 6, maxSizeY: null, type: 'JobStartDate' },
    { id: "JobDeadline_Default", row: 5, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 6, maxSizeY: null, type: 'JobDeadline' },
    { id: "JobStatus_Default", row: 6, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 6, maxSizeY: null, type: 'JobStatus' },
    { id: "JobRating_Default", row: 7, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 6, maxSizeY: null, type: 'JobRating' },
    
    { id: "JobLocation_Default", row: 0, col: 2, sizeX: 2, sizeY: 9, minSizeX: 1, minSizeY: 1, maxSizeX: 6, maxSizeY: null, type: 'JobLocation' },

    { id: "JobClient_Default", row: 0, col: 4, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 6, maxSizeY: null, type: 'JobClient' },
    { id: "JobContact_Default", row: 1, col: 4, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 6, maxSizeY: null, type: 'JobContact' },
    { id: "JobHiringManager_Default", row: 2, col: 4, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 6, maxSizeY: null, type: 'JobHiringManager' },
    { id: "JobBilling1_Default", row: 3, col: 4, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 6, maxSizeY: null, type: 'JobBilling1' },
    { id: "JobConsultant1_Default", row: 4, col: 4, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 6, maxSizeY: null, type: 'JobConsultant1' },
    { id: "JobConsultant2_Default", row: 5, col: 4, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 6, maxSizeY: null, type: 'JobConsultant2' },
    
    { id: "Spacer_Default", row: 9, col: 0, sizeX: 6, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 6, maxSizeY: null, type: 'Spacer' },
    { id: "JobSummaryFullWidth_Default", row: 10, col: 0, sizeX: 6, sizeY: 6, minSizeX: 1, minSizeY: 1, maxSizeX: 6, maxSizeY: null, type: 'JobSummaryFullWidth' },

];

export const GetMinSizeByElementType = (type: JobRecordDashboardElementType) => {
    switch (type) {
        // case "ContactInfo": return [1, 3];
        // case "ContactSummary": return [1, 5];
        // case "ContactLocation": return [1, 9];
        default: return [1, 1];
    }
};

export const GetDefaultSizeByElementType = (type: JobRecordDashboardElementType) => {
    switch (type) {
        case "JobSummary": return [2, 7];
        case "JobSummaryFullWidth": return [6, 6];
        default: return [2, 1];
    }
};

export const GetElementTypeById = (id: string): JobRecordDashboardElementType => {
    if ( id && id.startsWith('Divider_')) return 'Divider';
    if ( id && id.startsWith('Spacer_')) return 'Spacer';
    if (id && id.startsWith('JobId_')) return 'JobId';
    if (id && id.startsWith('JobType_')) return 'JobType';
    if (id && id.startsWith('JobWorkType_')) return 'JobWorkType';
    if (id && id.startsWith('JobTitle_')) return 'JobTitle';
    if (id && id.startsWith('JobExclusive_')) return 'JobExclusive';
    if (id && id.startsWith('JobQuantity_')) return 'JobQuantity';
    if (id && id.startsWith('JobStartDate_')) return 'JobStartDate';
    if (id && id.startsWith('JobDuration_')) return 'JobDuration';
    if (id && id.startsWith('JobDeadline_')) return 'JobDeadline';
    if (id && id.startsWith('JobDivision_')) return 'JobDivision';
    if (id && id.startsWith('JobSalaryPackage_')) return 'JobSalaryPackage';
    if (id && id.startsWith('JobFee_')) return 'JobFee';
    if (id && id.startsWith('JobChargeRate_')) return 'JobChargeRate';
    if (id && id.startsWith('JobPayRate_')) return 'JobPayRate';
    if (id && id.startsWith('JobOnCosts_')) return 'JobOnCosts';
    if (id && id.startsWith('JobComments_')) return 'JobComments';
    if (id && id.startsWith('JobCurrency_')) return 'JobCurrency';
    if (id && id.startsWith('JobSource_')) return 'JobSource';
    if (id && id.startsWith('JobLocation_')) return 'JobLocation';
    if (id && id.startsWith('JobClient_')) return 'JobClient';
    if (id && id.startsWith('JobContact_')) return 'JobContact';
    if (id && id.startsWith('JobOtherContact_')) return 'JobOtherContact';
    if (id && id.startsWith('JobHiringManager_')) return 'JobHiringManager';
    if (id && id.startsWith('JobBilling1_')) return 'JobBilling1';
    if (id && id.startsWith('JobClientRef_')) return 'JobClientRef';
    if (id && id.startsWith('JobVideoLink_')) return 'JobVideoLink';
    if (id && id.startsWith('JobRating_')) return 'JobRating';
    if (id && id.startsWith('JobSummaryFullWidth_')) return 'JobSummaryFullWidth';
    if (id && id.startsWith('JobSummary_')) return 'JobSummary';
    if (id && id.startsWith('JobStatus_')) return 'JobStatus';
    if (id && id.startsWith('JobStage_')) return 'JobStage';
    if (id && id.startsWith('JobOutcome_')) return 'JobOutcome';
    if (id && id.startsWith('JobHoursPerDay_')) return 'JobHoursPerDay';
    if (id && id.startsWith('JobDaysPerWeek_')) return 'JobDaysPerWeek';
    if (id && id.startsWith('JobConsultant1_')) return 'JobConsultant1';
    if (id && id.startsWith('JobConsultant2_')) return 'JobConsultant2';
    if (id && id.startsWith('JobComplianceChecklist_')) return 'JobComplianceChecklist';
    if ( id && id.startsWith('CustomField')) return GetCustomFieldTypeById(id);
    return '';
};

export const GetPanelDefinitionsFromPanelModels = (panels: PanelModel[]) => {
    let elements: JobRecordDashboardElementDefinition[] = [];
    panels.forEach((p, i) => {
        let type = GetElementTypeById(p.id ?? '');
        elements.push({
            id: p.id ?? i.toString(),
            sizeX: p.sizeX ?? 1,
            sizeY: p.sizeY ?? 1,
            col: p.col ?? 0,
            row: p.row ?? 0,
            minSizeX: p.minSizeX ?? 1,
            minSizeY: p.minSizeY ?? 1,
            maxSizeX: p.maxSizeX ?? 1,
            maxSizeY: p.maxSizeY ?? null,
            type: type
        });
    });
    return elements ;
};

export const JobQuickViewLayoutSettings: ScreenLayoutSettings = {
    unitWidth: 228,
    unitHeight: 40,
    gapX: 10,
    gapY: 0,
    columns: 6
};