import { ChangeTracker } from "common/models/hooks/ChangeTracker";
import { Job, MinJob } from "common/models/Jobs/Job";

export function JobToMinJob(j: Job): MinJob {
    return {
        id: j.id,
        jobReference: j.jobReference,
        type: j.type,
        workTypeID: j.workTypeID,
        title: j.title,
        exclusive: j.exclusive,
        candidatesRequired: j.candidatesRequired,
        startDate: j.startDate,
        startTime: j.startTime,
        duration: j.duration,
        durationPeriod: j.durationPeriod,
        deadline: j.deadline,
        division: j.division,
        salaryFrom: j.salaryFrom,
        salaryTo: j.salaryTo,
        salaryUnits: j.salaryUnits,
        feeType: j.feeType,
        permFeeAmount: j.permFeeAmount,
        permFeePercentage: j.permFeePercentage,
        chargeRateFrom: j.chargeRateFrom,
        chargeRateTo: j.chargeRateTo,
        chargeRateUnits: j.chargeRateUnits,
        rateFrom: j.rateFrom,
        rateTo: j.rateTo,
        rateUnits: j.rateUnits,
        onCostsFrom: j.onCostsFrom,
        onCostsTo: j.onCostsTo,
        onCostsUnits: j.onCostsUnits,
        remunerationComment: j.remunerationComment,
        currencyID: j.currencyID,
        source: j.source,
        siteID: j.siteID,
        siteName: j.siteName,
        address1: j.address1,
        address2: j.address2,
        address3: j.address3,
        state: j.state,
        postcode: j.postcode,
        suburb: j.suburb,
        countryName: j.countryName,
        clientID: j.clientID,
        contact1ID: j.contact1ID,
        contact2ID: j.contact2ID,
        contact3ID: j.contact3ID,
        contact4ID: j.contact4ID,
        clientReference: j.clientReference,
        videoLink: j.videoLink,
        rating: j.rating,
        description: j.description,
        statusName: j.statusName,
        stageID: j.stageID,
        stageName: j.stageName,
        outcome: j.outcome,
        hoursPerDay: j.hoursPerDay,
        daysPerWeek: j.daysPerWeek,
        consultantID: j.consultantID,
        consultantID2: j.consultantID2,
        complianceChecklistID: j.complianceChecklistID,
        customField1: j.customField1, customField2: j.customField2, customField3: j.customField3, customField4: j.customField4, customField5: j.customField5, customField6: j.customField6, customField7: j.customField7, customField8: j.customField8, customField9: j.customField9, customField10: j.customField10,
        customField11: j.customField11, customField12: j.customField12, customField13: j.customField13, customField14: j.customField14, customField15: j.customField15, customField16: j.customField16, customField17: j.customField17, customField18: j.customField18, customField19: j.customField19, customField20: j.customField20,
        customField21: j.customField21, customField22: j.customField22, customField23: j.customField23, customField24: j.customField24, customField25: j.customField25, customField26: j.customField26, customField27: j.customField27, customField28: j.customField28, customField29: j.customField29, customField30: j.customField30, 
        customField31: j.customField31, customField32: j.customField32, customField33: j.customField33, customField34: j.customField34, customField35: j.customField35, customField36: j.customField36, customField37: j.customField37, customField38: j.customField38, customField39: j.customField39, customField40: j.customField40, 
        customField41: j.customField41, customField42: j.customField42, customField43: j.customField43, customField44: j.customField44, customField45: j.customField45, customField46: j.customField46, customField47: j.customField47, customField48: j.customField48, customField49: j.customField49, customField50: j.customField50, 
        customField51: j.customField51, customField52: j.customField52, customField53: j.customField53, customField54: j.customField54, customField55: j.customField55, customField56: j.customField56, customField57: j.customField57, customField58: j.customField58, customField59: j.customField59, customField60: j.customField60
    };
}

export const DefaultMinJob: MinJob = {
    id: 0,
    jobReference: '',
    type: '',
    workTypeID: 0,
    title: '',
    exclusive: false,
    candidatesRequired: 1,
    startDate: '',
    startTime: '',
    duration: 0,
    durationPeriod: 0,
    deadline: '',
    division: 0,
    salaryFrom: 0,
    salaryTo: 0,
    salaryUnits: 0,
    feeType: 0,
    permFeeAmount: 0,
    permFeePercentage: 0,
    chargeRateFrom: 0,
    chargeRateTo: 0,
    chargeRateUnits: 0,
    rateFrom: 0,
    rateTo: 0,
    rateUnits: 0,
    onCostsFrom: 0,
    onCostsTo: 0,
    onCostsUnits: 0,
    remunerationComment: '',
    currencyID: 0,
    source: '',
    siteID: 0,
    siteName: '',
    address1: '',
    address2: '',
    address3: '',
    state: '',
    postcode: '',
    suburb: '',
    countryName: '',
    clientID: 0,
    contact1ID: 0,
    contact2ID: 0,
    contact3ID: 0,
    contact4ID: 0,
    clientReference: '',
    videoLink: '',
    rating: 0,
    description: '',
    statusName: 'Open',
    stageID: 0,
    stageName: 'Open',
    outcome: 0,
    hoursPerDay: 8,
    daysPerWeek: 5,
    consultantID: 0,
    consultantID2: 0,
    complianceChecklistID: 0,
    customField1: '', customField2: '', customField3: '', customField4: '', customField5: '', customField6: '', customField7: '', customField8: '', customField9: '', customField10: '',
    customField11: undefined, customField12: undefined, customField13: undefined, customField14: undefined, customField15: undefined,
    customField16: '0001-01-01T00:00:00', customField17: '0001-01-01T00:00:00', customField18: '0001-01-01T00:00:00', customField19: '0001-01-01T00:00:00', customField20: '0001-01-01T00:00:00',
    customField21: '', customField22: '', customField23: '', customField24: '', customField25: '', customField26: '', customField27: '', customField28: '', customField29: '', customField30: '',
    customField31: undefined, customField32: undefined, customField33: undefined, customField34: undefined, customField35: undefined,
    customField36: '0001-01-01T00:00:00', customField37: '0001-01-01T00:00:00', customField38: '0001-01-01T00:00:00', customField39: '0001-01-01T00:00:00', customField40: '0001-01-01T00:00:00',
    customField41: '', customField42: '', customField43: '', customField44: '', customField45: '', customField46: '', customField47: '', customField48: '', customField49: '', customField50: '',
    customField51: undefined, customField52: undefined, customField53: undefined, customField54: undefined, customField55: undefined,
    customField56: '0001-01-01T00:00:00', customField57: '0001-01-01T00:00:00', customField58: '0001-01-01T00:00:00', customField59: '0001-01-01T00:00:00', customField60: '0001-01-01T00:00:00',
};

export const DefaultMinJobNoChanges: ChangeTracker<MinJob> = {
    id: false,
    jobReference: false,
    type: false,
    workTypeID: false,
    title: false,
    exclusive: false,
    candidatesRequired: false,
    startDate: false,
    startTime: false,
    duration: false,
    durationPeriod: false,
    deadline: false,
    division: false,
    salaryFrom: false,
    salaryTo: false,
    salaryUnits: false,
    feeType: false,
    permFeeAmount: false,
    permFeePercentage: false,
    chargeRateFrom: false,
    chargeRateTo: false,
    chargeRateUnits: false,
    rateFrom: false,
    rateTo: false,
    rateUnits: false,
    onCostsFrom: false,
    onCostsTo: false,
    onCostsUnits: false,
    remunerationComment: false,
    currencyID: false,
    source: false,
    siteID: false,
    siteName: false,
    address1: false,
    address2: false,
    address3: false,
    state: false,
    postcode: false,
    suburb: false,
    countryName: false,
    clientID: false,
    contact1ID: false,
    contact2ID: false,
    contact3ID: false,
    contact4ID: false,
    clientReference: false,
    videoLink: false,
    rating: false,
    description: false,
    statusName: false,
    stageID: false,
    stageName: false,
    outcome: false,
    hoursPerDay: false,
    daysPerWeek: false,
    consultantID: false,
    consultantID2: false,
    complianceChecklistID: false,
    customField1: false, customField2: false, customField3: false, customField4: false, customField5: false, customField6: false, customField7: false, customField8: false, customField9: false, customField10: false,
    customField11: false, customField12: false, customField13: false, customField14: false, customField15: false, customField16: false, customField17: false, customField18: false, customField19: false, customField20: false,
    customField21: false, customField22: false, customField23: false, customField24: false, customField25: false, customField26: false, customField27: false, customField28: false, customField29: false, customField30: false, 
    customField31: false, customField32: false, customField33: false, customField34: false, customField35: false, customField36: false, customField37: false, customField38: false, customField39: false, customField40: false, 
    customField41: false, customField42: false, customField43: false, customField44: false, customField45: false, customField46: false, customField47: false, customField48: false, customField49: false, customField50: false, 
    customField51: false, customField52: false, customField53: false, customField54: false, customField55: false, customField56: false, customField57: false, customField58: false, customField59: false, customField60: false
};

export const PlaceholderJob: Job = {
    id: 581129,
    jobID: 581129,
    jobReference: "581129",
    title: "Conv Opp to COntract Job",
    stageID: 0,
    stageName: "Resourcing",
    rating: 0,
    ratingName: '',
    remunerationComment: "",
    videoLink: "",
    description: "opp to cont",
    type: "Contract",
    typeName: "Contract",
    siteID: 0,
    siteName: 'Sydney HQ',
    address1: "Suite 1, Level 1",
    address2: "16 Greenfield Parade",
    address3: "",
    suburb: "Bankstown",
    state: "NSW",
    postcode: "2200",
    countryCode: "AU",
    countryName: "Australia",
    currencyID: 9,
    currencyName: "AUD - Australian Dollar",
    dealAmount: 0,
    rateFrom: 0,
    rateTo: 0,
    rateUnits: 0,
    rateUnitsName: '',
    chargeRateFrom: 0,
    chargeRateTo: 0,
    chargeRateUnits: 0,
    chargeRateUnitsName: '',
    onCostsFrom: 0,
    onCostsTo: 0,
    onCostsUnits: 0,
    onCostsUnitsName: '',
    salaryFrom: 0,
    salaryTo: 0,
    salaryUnits: 0,
    salaryUnitsName: '',
    createDate: "2023-02-21T18:13:42.213",
    deadline: "2023-03-08T00:00:00",
    closeDate: "2023-02-21T23:16:58.63",
    startDate: "2023-02-22T00:00:00",
    startTime: "0001-01-01T00:00:00",
    endDate: "0001-01-01T00:00:00",
    statusName: "Closed",
    consultant1Name: "User2 Two",
    consultantID: 12109,
    consultantID2: 0,
    consultant2Name: null,
    createdByConsultantID: 12107,
    green: 0,
    yellow: 0,
    blue: 0,
    red: 0,
    toDo: 0,
    total: 0,
    outcome: 9,
    outcomeName: "Budget not approved",
    outcomeComments: "test",
    clientID: 175160,
    clientName: "Activities R Us",
    contact1ID: 602806,
    contact1Name: "Actions Galore",
    contact2ID: 602806,
    contact2Name: "Actions Galore",
    contact3ID: 602806,
    contact3Name: "Actions Galore",
    contact4ID: 602806,
    contact4Name: "Actions Galore",
    duration: 0,
    durationPeriod: 0,
    durationPeriodName: '',
    candidatesRequired: 1,
    candidatesFilled: 0,
    permFeePercentage: 0,
    permFeeAmount: 0,
    feeType: 0,
    feeTypeName: '',
    updatedDate: "2023-02-21T18:16:58.63",
    division: 178,
    divisionName: '',
    createdByID: 12109,
    createdDate: "2023-02-21T18:13:42.213",
    deleted: false,
    clientReference: "",
    shortAddress: "",
    fullAddress: "",
    customField1: "Custom Field 1",
    customField2: "Custom Field 2",
    customField3: "Custom Field 3",
    customField4: "Custom Field 4",
    customField5: "Custom Field 5",
    customField6: "Custom Field 6",
    customField7: "Custom Field 7",
    customField8: "Custom Field 8",
    customField9: "Custom Field 9",
    customField10: "Custom Field 10",
    customField11: 11,
    customField12: 12,
    customField13: 13,
    customField14: 14,
    customField15: 15,
    customField16: "2010-10-10T10:10:10.100",
    customField17: "2010-10-10T10:10:10.100",
    customField18: "2010-10-10T10:10:10.100",
    customField19: "2010-10-10T10:10:10.100",
    customField20: "2010-10-10T10:10:10.100",
    customField21: "Custom Field 21",
    customField22: "Custom Field 22",
    customField23: "Custom Field 23",
    customField24: "Custom Field 24",
    customField25: "Custom Field 25",
    customField26: "Custom Field 26",
    customField27: "Custom Field 27",
    customField28: "Custom Field 28",
    customField29: "Custom Field 29",
    customField30: "Custom Field 30",
    customField31: 31,
    customField32: 32,
    customField33: 33,
    customField34: 34,
    customField35: 35,
    customField36: "2010-10-10T10:10:10.100",
    customField37: "2010-10-10T10:10:10.100",
    customField38: "2010-10-10T10:10:10.100",
    customField39: "2010-10-10T10:10:10.100",
    customField40: "2010-10-10T10:10:10.100",
    customField41: "Custom Field 41",
    customField42: "Custom Field 42",
    customField43: "Custom Field 43",
    customField44: "Custom Field 44",
    customField45: "Custom Field 45",
    customField46: "Custom Field 46",
    customField47: "Custom Field 47",
    customField48: "Custom Field 48",
    customField49: "Custom Field 49",
    customField50: "Custom Field 50",
    customField51: 51,
    customField52: 52,
    customField53: 53,
    customField54: 54,
    customField55: 55,
    customField56: "2010-10-10T10:10:10.100",
    customField57: "2010-10-10T10:10:10.100",
    customField58: "2010-10-10T10:10:10.100",
    customField59: "2010-10-10T10:10:10.100",
    customField60: "2010-10-10T10:10:10.100",
    exclusive: false,
    savedSearchID: 353230,
    hoursPerDay: 8,
    daysPerWeek: 5,
    source: "",
    tags: false,
    splitRecipientID1: 12109,
    splitRecipientID2: 0,
    splitRecipientID3: 0,
    splitRecipientID4: 0,
    splitRecipientID5: 0,
    splitPercentage1: 100,
    splitPercentage2: 0,
    splitPercentage3: 0,
    splitPercentage4: 0,
    splitPercentage5: 0,
    estimatedPlacementDate: "0001-01-01T00:00:00",
    placements: 0,
    workTypeID: 2,
    workType: "Full Time",
    averageDailyMargin: 0,
    liveAdverts: 0,
    payRange: '',
    createdByName: '',
    cvDeadline: '',
    location: '',
    updatedByID: 0,
    updatedByName: '',
    complianceChecklistID: 1,
    complianceChecklistName: 'Baseline Requirements',
    statisticsCounts: [],
    statisticsDates: []
};