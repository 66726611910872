import React, { useState } from "react";
import OpportunitiesGridComponent from "components/Grids/OpportunitiesGrid";
import OnlyTitleSummaryBar from "components/SummaryBars/OnlyTitleSummaryBar";
import PageContentLayout from "layouts/PageContentLayout";
import PageLayout from "layouts/PageLayout";

const summaryBar = <OnlyTitleSummaryBar title="Opportunities > My Opportunities" browserTabTitle="My Opportunities > Opportunities" />;

export default function MyOpportunitiesPage() {
    const [isLoading, setIsLoading] = useState(false);

    return (
        <>
            <PageLayout SummaryBar={summaryBar}>
                <PageContentLayout showLoading={isLoading}>
                    <OpportunitiesGridComponent
                        gridName="opportunities/my-opportunities"
                        source="my-opportunities"
                        loadingHandler={setIsLoading}
                    />
                </PageContentLayout>
            </PageLayout>
        </>
    );
}