import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import moment from "moment";
import React, { useCallback } from "react";
import * as XLSX from "xlsx";
import { GetForecastDetailedReportData } from "services/MiscReportsService";

interface Props {
    description: string,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void
}

export default function ForecastDetailedReport({ description, loadingHandler, errorHandler }: Props) {

    const getDataCallback = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        const res = await GetForecastDetailedReportData(errorHandler);
        const wb = XLSX.utils.book_new();
        const m = moment();
        const filename = `ForecastDetailed${m.format('YYYYMMDDhhmmss')}.xlsx`;
        if (res && res["data1"]) {
            const ws = XLSX.utils.json_to_sheet(res["data1"]);
            XLSX.utils.book_append_sheet(wb, ws, "This month");
        }
        if (res && res["data2"]) {
            const ws = XLSX.utils.json_to_sheet(res["data2"]);
            XLSX.utils.book_append_sheet(wb, ws, "Next month");
        }
        XLSX.writeFile(wb, filename);
        loadingHandler && loadingHandler(false);
    }, [errorHandler, loadingHandler]);

    return (
        <>
            <Box display="flex" justifyContent="flex-end">
                <Button variant="contained" color="success" onClick={getDataCallback}>Run Report</Button>
            </Box>
            <Typography component="div" variant="h6" mt={2}>Report Description</Typography>
            <Typography component="div" variant="body2">{description}</Typography>
        </>
    );
}