import { Permission as P } from "common/models/Permissions";

export interface ActionPermissionRow {
    module: string,
    access: P | null,
    addEdit: P | null,
    delete: P | null,
    export: P | null,
    reports: P | null,
}

export interface FeaturePermissionRow {
    module: string,
    feature: string,
    description?: string,
    access: P
}

export const  ActionPermissionsGridRows: ActionPermissionRow[] = [
    { module: 'Activities', access: P.Activities, addEdit: null, delete: P.ActivitiesDelete, export: null, reports: null },
    { module: 'Adverts', access: P.Adverts, addEdit: P.AdvertsAddEdit, delete: P.AdvertsDelete, export: P.AdvertsExport, reports: P.AdvertsReports },
    { module: 'Dashboard', access: P.Dashboard, addEdit: null, delete: null, export: null, reports: null },
    { module: 'Documents', access: null, addEdit: P.Documents, delete: P.DocumentsDelete, export: null, reports: null },
    { module: 'Candidates', access: P.Candidates, addEdit: P.CandidatesAddEdit, delete: P.CandidatesDelete, export: P.CandidatesExport, reports: P.CandidatesReports },
    { module: 'Clients', access: P.Clients, addEdit: P.ClientsAddEdit, delete: P.ClientsDelete, export: P.ClientsExport, reports: P.ClientsReports },
    { module: 'Contacts', access: P.Contacts, addEdit: P.ContactsAddEdit, delete: P.ContactsDelete, export: P.ContactsExport, reports: P.ContactsReports },
    { module: 'Jobs', access: P.Jobs, addEdit: P.JobsAddEdit, delete: P.JobsDelete, export: P.JobsExport, reports: P.JobsReports },
    { module: 'Misc', access: null, addEdit: null, delete: null, export: P.MiscExport, reports: P.MiscReports },
    { module: 'Opportunities', access: P.Opportunities, addEdit: P.OpportunitiesAddEdit, delete: P.OpportunitiesDelete, export: P.OpportunitiesExport, reports: P.OpportunitiesReports },
    { module: 'Placements', access: P.Placements, addEdit: P.PlacementsAddEdit, delete: P.PlacementsDelete, export: P.PlacementsExport, reports: P.PlacementsReports },
];

export const FeaturePermissionsGridRows: FeaturePermissionRow[] = [
    { module: 'Admin', feature: 'Global Administrator', access: P.GlobalAdministrator, description: 'Gives complete access to all Recruit Wizard Settings - Only available to Internal Recruit Wizard staff' },
    { module: 'Admin', feature: 'Adverts Administrator', access: P.AdvertsAdministrator },
    { module: 'Admin', feature: 'Automation Administrator', access: P.AutomationAdministrator },
    { module: 'Admin', feature: 'Clients Administrator', access: P.ClientsAdministrator },
    { module: 'Admin', feature: 'Compliance Administrator', access: P.ComplianceAdministrator },
    { module: 'Admin', feature: 'Data Maintenance Administrator', access: P.DataMaintenanceAdministrator },
    { module: 'Admin', feature: 'Email Integration Administrator', access: P.EmailIntegrationAdministrator },
    { module: 'Admin', feature: 'Groups Administrator', access: P.GroupsAdministrator },
    { module: 'Admin', feature: 'Integrations Administrator', access: P.IntegrationsAdministrator },
    { module: 'Admin', feature: 'Jobs Administrator', access: P.JobsAdministrator },
    { module: 'Admin', feature: 'Layout Administrator', access: P.LayoutAdministrator },
    { module: 'Admin', feature: 'Performance Administrator', access: P.PerformanceAdministrator },
    { module: 'Admin', feature: 'Placements Administrator', access: P.PlacementsAdministrator },
    { module: 'Admin', feature: 'SMS Administrator', access: P.SMSAdministrator },
    { module: 'Admin', feature: 'Submissions Administrator', access: P.SubmissionsAdministrator },
    { module: 'Admin', feature: 'Templates Administrator', access: P.TemplatesAdministrator },
    { module: 'Admin', feature: 'User Administrator', access: P.UserAdministrator },
    { module: 'All', feature: 'Billing Admin', access: P.BillingAdmin },
    { module: 'All', feature: 'Client Portal Admin', access: P.ClientPortalAdmin },
    { module: 'All', feature: 'Financial Controller', access: P.FinancialController },
    { module: 'Jobs', feature: 'Deadline Changes', access: P.JobDeadlineChanges },
    { module: 'Various', feature: 'Document Generation', access: P.DocumentTemplates },
    { module: 'Various', feature: 'Electronic Signing', access: P.ElectronicSigning },
    { module: 'Various', feature: 'Email Integration', access: P.EmailIntegration },
    { module: 'Various', feature: 'Email Marketing', access: P.EmailMarketing },
    { module: 'Various', feature: 'Message Centre - Email', access: P.MessageCentre },
    { module: 'Various', feature: 'Message Centre - SMS', access: P.MessageCentreSms },
    { module: 'Various', feature: 'Bulk Tags', access: P.BulkTags },
    { module: 'Various', feature: 'Tags', access: P.Tags },
];