import Alert from "components/Alert";
import Snackbar from "@mui/material/Snackbar";
import React, { useEffect, useState } from "react";
import OnlyTitleSummaryBar from "components/SummaryBars/OnlyTitleSummaryBar";
import PageContentLayout from "layouts/PageContentLayout";
import QuickViewLayoutAdmin from "components/QuickViewLayouts/Site/QuickViewLayoutAdmin";

interface Props {
    setSummaryBar?: (summaryBar: JSX.Element) => void
}

const summaryBar = <OnlyTitleSummaryBar title="Configuration > Screen Layouts > Sites - Quick View" browserTabTitle="Screen Layouts > Configuration" />;

export default function SitesQuickViewLayoutPage({ setSummaryBar }: Props) {
    const [isLoading, setIsloading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        setSummaryBar && setSummaryBar(summaryBar);
    }, [setSummaryBar]);
    
    return (
        <>
            <Snackbar open={errorMessage !== ''} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <PageContentLayout showLoading={isLoading}>
                <QuickViewLayoutAdmin loadingHandler={setIsloading} errorHandler={setErrorMessage} />
            </PageContentLayout>
        </>
    );
}