import Alert from "components/Alert";
import Snackbar from "@mui/material/Snackbar";
import { GridColDef, GridRenderCellParams, useGridApiRef } from "@mui/x-data-grid-premium";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { User } from "common/models/Configuration/User";
import EditKpisDialog from "components/Dialogs/Performance/EditKpisDialog";
import EditTargetsDialog from "components/Dialogs/Performance/EditTargetsDialog";
import GridWithStateWrapper from "components/GridWidthStateWrapper";
import OnlyTitleSummaryBar from "components/SummaryBars/OnlyTitleSummaryBar";
import PageContentLayout from "layouts/PageContentLayout";
import { GetActiveUsers } from "services/UsersService";

interface Props {
    setSummaryBar?: (summaryBar: JSX.Element) => void
}

type PerformanceDialogTypes = null | 'kpis' | 'targets';

export default function PerformanceConfigPage({ setSummaryBar }: Props) {
    const [isLoading, setIsLoading] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [rows, setRows] = useState<User[]>([]);
    const [selectedRow, setSelectedRow] = useState<User | null>(null);
    const [activeDialog, setActiveDiaog] = useState<PerformanceDialogTypes>(null);
    const apiRef = useGridApiRef();

    useEffect(() => {
        const summaryBar = <OnlyTitleSummaryBar title="Configuration > Performance" browserTabTitle="Performance > Configuration" />;
        setSummaryBar && setSummaryBar(summaryBar);
    }, [setSummaryBar]);

    useEffect(() => {
        const getData = async () => {
            setIsLoading(true);
            const data = await GetActiveUsers();
            if (data) setRows(data);
            setIsLoading(false);
        };
        getData();
    }, []);

    const columns = useMemo<GridColDef[]>(() => {
        const openDialogHandler = (dialog: PerformanceDialogTypes, row: User) => {
            setActiveDiaog(dialog);
            setSelectedRow(row);
        };

        const kpisRenderer = (params: GridRenderCellParams) => {
            return (
                <span
                    style={{ cursor: 'pointer', textDecoration: 'underline' }}
                    onClick={ () => openDialogHandler('kpis', params.row) }
                >Edit KPIs</span>
            );
        };
        
        const targetRenderer = (params: GridRenderCellParams) => {
            return (
                <span
                    style={{ cursor: 'pointer', textDecoration: 'underline' }}
                    onClick={ () => openDialogHandler('targets', params.row) }
                >Edit Targets</span>
            );
        };

        return [
            { field: 'firstName', headerName: 'First Name', width: 250 },
            { field: 'lastName', headerName: 'Last Name', width: 250 },
            { field: 'kpis', headerName: 'KPIs', renderCell: kpisRenderer },
            { field: 'targets', headerName: 'Targets', renderCell: targetRenderer },
        ];
    }, []);

    const closeDialogHandler = useCallback(() => {
        setActiveDiaog(null);
        setSelectedRow(null);
    }, []);

    const successHandler = useCallback(() => {
        setShowSuccess(true);
        setActiveDiaog(null);
    }, []);
    
    return (
        <>
            <Snackbar open={showSuccess} autoHideDuration={3000} onClose={() => setShowSuccess(false)}>
                <Alert onClose={() => setShowSuccess(false)}>Changes Saved</Alert>
            </Snackbar>
            <Snackbar anchorOrigin={{ horizontal: 'center', vertical: 'top' }} open={errorMessage !== ''}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            {activeDialog === 'kpis' && selectedRow &&
                <EditKpisDialog
                    open={true}
                    userId={selectedRow.id}
                    userName={selectedRow.displayName}
                    loadingHandler={setIsLoading}
                    closeHandler={closeDialogHandler}
                    errorMessageHandler={setErrorMessage}
                    successHandler={successHandler}
                />
            }
            {activeDialog === 'targets' && selectedRow &&
                <EditTargetsDialog
                    open={true}
                    userId={selectedRow.id}
                    userName={selectedRow.displayName}
                    loadingHandler={setIsLoading}
                    closeHandler={closeDialogHandler}
                    errorMessageHandler={setErrorMessage}
                    successHandler={successHandler}
                />
            }
            <PageContentLayout title="Performance" showLoading={isLoading}>
                <GridWithStateWrapper
                    gridName="configuration/performance"
                    apiRef={apiRef}
                    rows={rows}
                    columns={columns}
                    disableRowSelectionOnClick
                    pagination={true}
                    density="compact"
                    pageSizeOptions={[100, 250, 500, 1000]}
                />
            </PageContentLayout>
        </>
    );
}