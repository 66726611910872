import React from "react";

export const VerticalBarChartYAxisWidth = 300;

export function GetTrimmedTickContent(v: string) {
    return v.length > 35 ? `${v.substring(0, 35)}...` : v;
}

export function TickWithHoverRenderer(props: any) {
    return <text {...props}><title>{props.payload.value}</title>{GetTrimmedTickContent(props.payload.value)}</text>;
}