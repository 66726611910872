import React, { useMemo } from "react"
import {Navigate, Route, Routes} from "react-router-dom";
import MainLayout from "../layouts/MainLayout";

import DashboardIndex from './../pages/Dashboard/Index';
import Interviews from './Interviews/Interviews';
import MeetingRecord from './Meetings/Record';
import DownloadDocumentPage from './Documents/Download';
import Meetings from './Meetings/Meetings';
import ClientRecord from './../pages/Clients/Record';
import SiteRecord from './../pages/Sites/Record';
import ContactRecord from './../pages/Contacts/Record';
import OpportunityRecord from './../pages/Opportunities/Record';
import JobRecord from './../pages/Jobs/Record';
import PlacementRecord from './../pages/Placements/Record';
import AdvertRecord from './../pages/Adverts/Record';
import AdvertEdit from './../pages/Adverts/Edit';
import CandidateRecord from './../pages/Candidates/Record';
import AnalyticsIndex from './Analytics/Index';
import ConfigurationIndex from './Configuration/Index';
import NotAuthorized from './Error/NotAuthorized';
import UserEditIndex from "pages/Configuration/Users/EditIndex";
import AccountGeneralIndex from "pages/Account/General/AccountGeneralIndex";
import AccountEmailExclusionsPage from "pages/Account/AccountExclusionsPage";
import AccountUnlinkedMailPage from "pages/Account/AccountUnlinkedMailPage";
import ESignRequestsPage from "pages/Account/ESignRequestsPage";
import OnboardingRequestsPage from "pages/Account/OnboardingRequestsPage";
import MessageItemPage from "pages/Account/MessageItemPage";
import UserIntegrationsIndex from "pages/Account/Integrations/Index";
import UserIntegrationItem from "pages/Account/Integrations/UserIntegrationItem";
import SavedSearchesPage from "pages/Account/SavedSearchesPage";
import AccountTemplatesDocumentsPage from "pages/Account/Templates/AccountTemplatesDocumentsPage";
import * as P from "../common/data/Permissions/UserMenuAccess";
import AccountTemplatesMessagesPage from "pages/Account/Templates/AccountTemplatesMessagesPage";
import MessageTemplateItemPage from "pages/Account/Templates/MessageTemplateItemPage";
import AccountTemplatesScreeningQuestionnairesPage
    from "pages/Account/Templates/AccountTemplatesScreeningQuestionnairesPage";
import ScreeningQuestionnaireItemPage from "pages/Account/Templates/ScreeningQuestionnaireItemPage";

import AccountTemplatesReferenceChecksPage from "pages/Account/Templates/AccountTemplatesReferenceChecksPage";
import ReferenceCheckItemPage from "pages/Account/Templates/ReferenceCheckItemPage";

import {
    AllowClientsAddEdit,
    AllowClientsImport,
    AllowClientsModule,
    AllowClientsReportsAccess
} from "../common/data/Permissions/ClientsAccess";
import {
    AllowContactsAddEdit,
    AllowContactsImport,
    AllowContactsModule,
    AllowContactsReportsAccess
} from "../common/data/Permissions/ContactsAccess";
import {
    AllowCandidatesAddEdit,
    AllowCandidatesImport,
    AllowCandidatesModule,
    AllowCandidatesReportsAccess
} from "../common/data/Permissions/CandidatesAccess";
import {
    AllowOpportunitiesAddEdit,
    AllowOpportunitiesModule,
    AllowOpportunitiesReportsAccess
} from "../common/data/Permissions/OpportunitiesAccess";
import {AllowJobsAddEdit, AllowJobsModule, AllowJobsReportsAccess} from "../common/data/Permissions/JobsAccess";
import {
    AllowAdvertsAddEdit,
    AllowAdvertsModule,
    AllowAdvertsReportsAccess
} from "../common/data/Permissions/AdvertsAccess";
import {
    AllowFinancialController,
    AllowPlacementsAddEdit, AllowPlacementsImport,
    AllowPlacementsModule,
    AllowPlacementsReportsAccess
} from "../common/data/Permissions/PlacementsAccess";
import DocumentTemplateItemPage from "pages/Account/Templates/DocumentTemplateItemPage";
import MyClientsPage from "pages/Clients/MyClients";
import ClientTagsPage from "pages/Clients/Tags";
import ClientImportPage from "pages/Clients/Import";
import ClientCreatePage from "pages/Clients/ClientCreate";
import ClientEditPage from "pages/Clients/ClientEdit";
import AdvertsSearchPage from "pages/Adverts/Search";
import MyAdvertsPage from "pages/Adverts/MyAdverts"
import MyDraftAdvertsPage from "pages/Adverts/MyDrafts"
import MyAdvertTemplatesPage from "pages/Adverts/MyTemplates"
import ClientsSearchPage from "pages/Clients/Search";
import MyContactsPage from "pages/Contacts/MyContacts";
import ContactTagsPage from "pages/Contacts/Tags";
import ContactImportPage from "pages/Contacts/Import";
import JobTagsPage from "pages/Jobs/Tags";
import MyJobsPage from "pages/Jobs/MyJobs";
import MyPlacementsPage from "pages/Placements/MyPlacements";
import TeamJobsPage from "pages/Jobs/TeamJobs";
import CompanyJobsPage from "pages/Jobs/CompanyJobs";
import OpportunityTagsPage from "pages/Opportunities/Tags";
import MyOpportunitiesPage from "pages/Opportunities/MyOpportunities";
import PlacementTagsPage from "pages/Placements/Tags";
import CandidateTagsPage from "pages/Candidates/Tags";
import CandidateImportPage from "pages/Candidates/Import";
import NotFound from "pages/Error/NotFound";
import SitesSearchPage from "pages/Sites/Search";
import OpportunitiesSearchPage from "pages/Opportunities/Search";
import ContactsSearchPage from "pages/Contacts/Search";
import JobsSearchPage from "pages/Jobs/Search";
import PlacementSearchPage from "pages/Placements/Search";
import MyContractorsPage from "pages/Placements/ContractorsMine";
import AllContractorsPage from "pages/Placements/ContractorsAll";
import AllAwaitingApprovalPage from "pages/Placements/AwaitingApprovalAll";
import MyAwaitingApprovalPage from "pages/Placements/AwaitingApprovalMine";

import MyPendingChangesPage from "pages/Placements/PendingChangesMine";
import AllPendingChangesPage from "pages/Placements/PendingChangesAll";

import MyExpiringContractorsPage from "pages/Placements/ExpiringContractorsMine";
import AllExpiringContractorsPage from "pages/Placements/ExpiringContractorsAll";

import MyRecentlyExpiredContractorsPage from "pages/Placements/RecentlyExpiredContractorsMine";
import AllRecentlyExpiredContractorsPage from "pages/Placements/RecentlyExpiredContractorsAll";
import SiteCreatePage from "pages/Sites/SiteCreate";
import SiteEditPage from "pages/Sites/SiteEdit";
import ContactCreatePage from "pages/Contacts/ContactCreate";
import ContactEditPage from "pages/Contacts/ContactEdit";
import OpportunityCreatePage from "pages/Opportunities/OpportunityCreate";
import OpportunityEditPage from "pages/Opportunities/OpportunityEdit";
import CandidateCreatePage from "pages/Candidates/CandidateCreate";
import CandidateEditPage from "pages/Candidates/CandidateEdit";
import JobCreatePage from "pages/Jobs/JobCreate";
import JobEditPage from "pages/Jobs/JobEdit";
import CandidateUploadPage from "pages/Candidates/Upload";
import CandidatesRecentUploadsPage from "pages/Candidates/RecentUploads";
import PlacementCreatePage from "pages/Placements/PlacementCreate";
import PlacementEditPage from "pages/Placements/PlacementEdit";

import ChangeRequestPage from "pages/Placements/ChangeRequests/Record"
import MeetingCreatePage from "pages/Meetings/MeetingCreate";
import MeetingEditPage from "pages/Meetings/MeetingEdit";
import InterviewRecord from "pages/Interviews/Record";
import SubmissionRecord from "pages/Submissions/Record";
import InterviewCreatePage from "pages/Interviews/InterviewCreate";
import InterviewEditPage from "pages/Interviews/InterviewEdit";
import SubmissionCreatePage from "pages/Submissions/SubmissionCreate";
import CandidatesSearchPage from "pages/Candidates/Search";
import ScreeningCreatePage from "./Candidates/ScreeningCreate";
import ScreeningEditPage from "./Candidates/ScreeningEdit";
import ReferenceCheckCreatePage from "./Candidates/ReferenceCheckCreate";
import ReferenceCheckEditPage from "./Candidates/ReferenceCheckEdit";
import SmsReplyPage from "./Account/SmsReplyPage";
import ConsultantJobsPage from "./Jobs/ConsultantJobs";
import ReferenceCheckRequestsPage from "./Account/ReferenceCheckRequestsPage";

import PlacementBulkExtensionsImport from "pages/Placements/Import/BulkExtensions";
import PlacementBulkRateChangesImport from "pages/Placements/Import/BulkRateChanges";
import ChangeRequestSearchPage from "./Placements/ChangeRequests/Search";

import AllInvoices from "./Invoices/All";
import DraftInvoices from "./Invoices/Drafts";
import AwaitingApprovalInvoices from "./Invoices/AwaitingApproval";
import { AllowInvoices } from "../common/data/Permissions/ConfigurationAccess";

const NotAllowed = <Navigate to='/not-authorized' />;

export default function Home() {
    const AllowTemplates = useMemo(() => P.AllowTemplates(), []);
    const AllowClients = useMemo(() => AllowClientsModule(), []);
    const AllowContacts = useMemo(() => AllowContactsModule(), []);
    const AllowContactsEdit = useMemo(() => AllowContactsAddEdit(), []);
    const AllowCandidates = useMemo(() => AllowCandidatesModule(), []);
    const AllowCandidatesEdit = useMemo(() => AllowCandidatesAddEdit(), []);
    const AllowJobs = useMemo(() => AllowJobsModule(), []);
    const AllowJobsEdit = useMemo(() => AllowJobsAddEdit(), []);
    const AllowAdverts = useMemo(() => AllowAdvertsModule(), []);
    const AllowAdvertsEdit = useMemo(() => AllowAdvertsAddEdit(), []);
    const AllowOpportunities = useMemo(() => AllowOpportunitiesModule(), []);
    const AllowOpportunitiesEdit = useMemo(() => AllowOpportunitiesAddEdit(), []);
    const AllowPlacements = useMemo(() => AllowPlacementsModule(), []);
    const AllowPlacementsEdit = useMemo(() => AllowPlacementsAddEdit(), []);
    const AllowPlacementApproval = useMemo(() => AllowFinancialController(), []);
    const AllowClientsAddEditValue = useMemo(() => AllowClients && AllowClientsAddEdit(), [AllowClients]);
    const AllowClImport = useMemo(() => AllowClientsImport(), []);
    const AllowCoImport = useMemo(() => AllowContactsImport(), []);
    const AllowCaImport = useMemo(() => AllowCandidatesImport(), []);
    const AllowPlImport = useMemo(() => AllowPlacementsImport(), []);
    const AllowClientReports = useMemo(() => AllowClientsReportsAccess(), []);
    const AllowContactReports = useMemo(() => AllowContactsReportsAccess(), []);
    const AllowOpportunityReports = useMemo(() => AllowOpportunitiesReportsAccess(), []);
    const AllowJobsReports = useMemo(() => AllowJobsReportsAccess(), []);
    const AllowAdvertsReports = useMemo(() => AllowAdvertsReportsAccess(), []);
    const AllowCandidatesReports = useMemo(() => AllowCandidatesReportsAccess(), []);
    const AllowPlacementsReports = useMemo(() => AllowPlacementsReportsAccess(), []);
    
    return (
            <MainLayout>
                <Routes>
                    <Route path='' element={<Navigate to='/dashboard' />} />
                    <Route path='dashboard/*' element={<DashboardIndex />} />

                    <Route path='interviews' element={<Interviews />} />
                    <Route path='interviews/:id' element={<InterviewRecord />} />
                    <Route path='interviews/create' element={<InterviewCreatePage />} />
                    <Route path='interviews/:id/edit' element={<InterviewEditPage />} />

                    <Route path='meetings' element={<Meetings />} />
                    <Route path='documents/:id' element={<DownloadDocumentPage />} />
                    <Route path='meetings/:id' element={<MeetingRecord />} />
                    <Route path='meetings/create' element={<MeetingCreatePage />} />
                    <Route path='meetings/:id/edit' element={<MeetingEditPage />} />

                    <Route path='clients' element={AllowClients ? <ClientsSearchPage /> : NotAllowed} />
                    <Route path='clients/my-clients' element={AllowClients ? <MyClientsPage /> : NotAllowed} />
                    <Route path='clients/tags' element={AllowClients ? <ClientTagsPage /> : NotAllowed} />
                    <Route path='clients/import' element={AllowClImport ? <ClientImportPage /> : NotAllowed} />
                    <Route path='clients/create' element={AllowClientsAddEditValue ? <ClientCreatePage /> : NotAllowed} />
                    <Route path='clients/:id' element={AllowClients ? <ClientRecord /> : NotAllowed} />
                    <Route path='clients/:id/edit' element={AllowClientsAddEditValue ? <ClientEditPage /> : NotAllowed} />


                    <Route path='sites' element={<SitesSearchPage />} />
                    <Route path='sites/:id' element={AllowClients ? <SiteRecord /> : NotAllowed} />
                    <Route path='sites/create' element={AllowClientsAddEditValue ? <SiteCreatePage /> : NotAllowed} />
                    <Route path='sites/:id/edit' element={AllowClientsAddEditValue ? <SiteEditPage /> : NotAllowed} />

                    <Route path='contacts' element={AllowContacts ? <ContactsSearchPage /> : NotAllowed} />
                    <Route path='contacts/:id' element={AllowContacts ? <ContactRecord /> : NotAllowed} />
                    <Route path='contacts/create' element={AllowContactsEdit ? <ContactCreatePage /> : NotAllowed} />
                    <Route path='contacts/:id/edit' element={AllowContactsEdit ? <ContactEditPage /> : NotAllowed} />
                    <Route path='contacts/my-contacts' element={AllowContacts ? <MyContactsPage /> : NotAllowed} />
                    <Route path='contacts/tags' element={AllowContacts ? <ContactTagsPage /> : NotAllowed} />
                    <Route path='contacts/import' element={AllowCoImport ? <ContactImportPage /> : NotAllowed} />



                    <Route path='opportunities' element={AllowOpportunities ? <OpportunitiesSearchPage /> : NotAllowed} />
                    <Route path='opportunities/my-opportunities' element={AllowOpportunities ? <MyOpportunitiesPage /> : NotAllowed} />
                    <Route path='opportunities/tags' element={AllowOpportunities ? <OpportunityTagsPage /> : NotAllowed} />
                    <Route path='opportunities/:id' element={AllowOpportunities ? <OpportunityRecord /> : NotAllowed} />
                    <Route path='opportunities/create' element={AllowOpportunitiesEdit ? <OpportunityCreatePage /> : NotAllowed} />
                    <Route path='opportunities/:id/edit' element={AllowOpportunitiesEdit ? <OpportunityEditPage /> : NotAllowed} />




                    <Route path='jobs' element={AllowJobs ? <JobsSearchPage /> : NotAllowed} />
                    <Route path='jobs/my-jobs' element={AllowJobs ? <MyJobsPage /> : NotAllowed} />
                    <Route path='jobs/team-jobs' element={AllowJobs ? <TeamJobsPage /> : NotAllowed} />
                    <Route path='jobs/company-jobs' element={AllowJobs ? <CompanyJobsPage /> : NotAllowed} />
                    <Route path='jobs/consultant-jobs' element={AllowJobs ? <ConsultantJobsPage /> : NotAllowed} />

                    <Route path='jobs/:id' element={AllowJobs ? <JobRecord /> : NotAllowed} />
                    <Route path='jobs/create' element={AllowJobsEdit ? <JobCreatePage /> : NotAllowed} />
                    <Route path='jobs/:id/edit' element={AllowJobsEdit ? <JobEditPage /> : NotAllowed} />
                    <Route path='jobs/tags' element={AllowJobs ? <JobTagsPage /> : NotAllowed} />

                    <Route path='adverts' element={AllowAdverts ? <AdvertsSearchPage /> : NotAllowed} />
                    <Route path='adverts/my-adverts' element={AllowAdverts ? <MyAdvertsPage /> : NotAllowed} />
                    <Route path='adverts/:id' element={AllowAdverts ? <AdvertRecord /> : NotAllowed} />
                    <Route path='adverts/:id/edit' element={AllowAdvertsEdit ? <AdvertEdit /> : NotAllowed} />
                    <Route path='adverts/drafts' element={AllowAdverts ? <MyDraftAdvertsPage /> : NotAllowed} />
                    <Route path='adverts/templates' element={AllowAdverts ? <MyAdvertTemplatesPage /> : NotAllowed} />


                    <Route path='candidates' element={AllowCandidates ? <CandidatesSearchPage /> : NotAllowed} />
                    <Route path='candidates/:id' element={AllowCandidates ? <CandidateRecord /> : NotAllowed} />
                    <Route path='candidates/create' element={AllowCandidatesEdit ? <CandidateCreatePage /> : NotAllowed} />
                    <Route path='candidates/:id/edit' element={AllowCandidatesEdit ? <CandidateEditPage /> : NotAllowed} />
                    <Route path='candidates/:id/screening' element={AllowCandidatesEdit ? <ScreeningCreatePage /> : NotAllowed} />
                    <Route path='candidates/screening/:id' element={AllowCandidatesEdit ? <ScreeningEditPage /> : NotAllowed} />
                    <Route path='candidates/:id/reference-check' element={AllowCandidatesEdit ? <ReferenceCheckCreatePage /> : NotAllowed} />
                    <Route path='candidates/reference-check/:id' element={AllowCandidatesEdit ? <ReferenceCheckEditPage /> : NotAllowed} />
                    <Route path='candidates/tags' element={AllowCandidates ? <CandidateTagsPage /> : NotAllowed} />
                    <Route path='candidates/import' element={AllowCaImport ? <CandidateImportPage /> : NotAllowed} />
                    <Route path='candidates/upload' element={AllowCandidatesEdit ? <CandidateUploadPage /> : NotAllowed} />
                    <Route path='candidates/recent-uploads' element={AllowCandidates ? <CandidatesRecentUploadsPage /> : NotAllowed} />

                    <Route path='placements' element={AllowPlacements ? <PlacementSearchPage /> : NotAllowed} />
                    <Route path='placements/create' element={AllowPlacementsEdit ? <PlacementCreatePage /> : NotAllowed} />
                    <Route path='placements/:id/edit' element={AllowPlacementsEdit ? <PlacementEditPage /> : NotAllowed} />
                    <Route path='placements/my-placements' element={AllowPlacements ? <MyPlacementsPage /> : NotAllowed} />
                    <Route path='placements/tags' element={AllowPlacements ? <PlacementTagsPage /> : NotAllowed} />
                    <Route path='placements/:id' element={AllowPlacements ? <PlacementRecord /> : NotAllowed} />
                    
                    <Route path='placements/my-placements' element={AllowPlacements ? <MyPlacementsPage /> : NotAllowed} />
                    <Route path='placements/awaiting-approval' element={AllowPlacementApproval ? <AllAwaitingApprovalPage /> : NotAllowed} />
                    <Route path='placements/my-awaiting-approval' element={AllowPlacements ? <MyAwaitingApprovalPage /> : NotAllowed} />
                    <Route path='placements/contractors' element={AllowPlacements ? <AllContractorsPage /> : NotAllowed} />
                    <Route path='placements/my-contractors' element={AllowPlacements ? <MyContractorsPage /> : NotAllowed} />
                    <Route path='placements/expiring-contractors' element={AllowPlacementApproval ? <AllExpiringContractorsPage /> : NotAllowed} />
                    <Route path='placements/my-expiring-contractors' element={AllowPlacements ? <MyExpiringContractorsPage /> : NotAllowed} />
                    <Route path='placements/recently-expired-contractors' element={AllowPlacementApproval ? <AllRecentlyExpiredContractorsPage /> : NotAllowed} />
                    <Route path='placements/my-recently-expired-contractors' element={AllowPlacements ? <MyRecentlyExpiredContractorsPage /> : NotAllowed} />
                    <Route path='placements/change-requests/search' element={AllowPlacements ? <ChangeRequestSearchPage /> : NotAllowed} />
                    <Route path='placements/change-requests/pending/all' element={AllowPlacementApproval ? <AllPendingChangesPage /> : NotAllowed} />
                    <Route path='placements/change-requests/pending/mine' element={AllowPlacements ? <MyPendingChangesPage /> : NotAllowed} />
                    <Route path='placements/change-requests/:id' element={AllowPlacements ? <ChangeRequestPage /> : NotAllowed} />
                    <Route path='placements/import/bulk-change-requests-extensions' element={AllowPlImport ? <PlacementBulkExtensionsImport /> : NotAllowed} />
                    <Route path='placements/import/bulk-change-requests-rate-changes' element={AllowPlImport ? <PlacementBulkRateChangesImport /> : NotAllowed} />

                    <Route path='submissions/:id' element={AllowPlacements ? <SubmissionRecord /> : NotAllowed} />
                    <Route path='submissions/create' element={AllowPlacements ? <SubmissionCreatePage /> : NotAllowed} />

                    

                    <Route path='configuration/*' element={<ConfigurationIndex />} />
                    <Route path='configuration/users/:userId/edit/*' element={<UserEditIndex />} />

                    <Route path='not-authorized' element={<NotAuthorized />} />
                    <Route path='not-found' element={<NotFound />} />

                    <Route path="/account/*" element={<AccountGeneralIndex />} />
                    <Route path="/account/esign-requests/all" element={<ESignRequestsPage />} />
                    <Route path="/account/esign-requests/incomplete" element={<ESignRequestsPage showOnlyIncomplete />} />
                    <Route path="/account/reference-check-requests/all" element={<ReferenceCheckRequestsPage />} />
                    <Route path="/account/reference-check-requests/incomplete" element={<ReferenceCheckRequestsPage showOnlyIncomplete />} />
                    <Route path="/account/onboarding-requests/all" element={<OnboardingRequestsPage />} />
                    <Route path="/account/onboarding-requests/incomplete" element={<OnboardingRequestsPage showOnlyIncomplete />} />
                    <Route path="/account/email-integration/exclusion-list" element={<AccountEmailExclusionsPage />} />
                    <Route path="/account/email-integration/unlinked-mail" element={<AccountUnlinkedMailPage />} />
                    <Route path="/account/saved-searches" element={<SavedSearchesPage />} />
                    <Route path="/account/templates/documents" element={AllowTemplates ? <AccountTemplatesDocumentsPage /> : NotAllowed} />
                    <Route path="/account/templates/documents/:id" element={AllowTemplates ? <DocumentTemplateItemPage /> : NotAllowed} />
                    <Route path="/account/templates/messages" element={<AccountTemplatesMessagesPage />} />
                    <Route path="/account/templates/messages/:id" element={<MessageTemplateItemPage />} />
                    <Route path="/account/templates/screening-questions" element={<AccountTemplatesScreeningQuestionnairesPage />} />
                    <Route path="/account/templates/screening-questions/:id" element={<ScreeningQuestionnaireItemPage />} />

                    <Route path="/account/templates/reference-checks" element={<AccountTemplatesReferenceChecksPage />} />
                    <Route path="/account/templates/reference-checks/:id" element={<ReferenceCheckItemPage />} />

                    <Route path="/account/integrations" element={<UserIntegrationsIndex />} />
                    <Route path="/account/integrations/:id" element={<UserIntegrationItem />} />

                    <Route path="/messages/:messageId" element={<MessageItemPage />} />
                    <Route path="/sms/:id/reply" element={<SmsReplyPage />} />
                    
                    <Route path='analytics/*' element={<AnalyticsIndex />} />

                    <Route path='invoices/all' element={AllowInvoices() ? <AllInvoices /> : NotAllowed} />
                    <Route path='invoices/drafts' element={AllowInvoices() ? <DraftInvoices /> : NotAllowed} />
                    <Route path='invoices/awaiting-approval' element={AllowInvoices() ? <AwaitingApprovalInvoices /> : NotAllowed} />
                </Routes>
            </MainLayout>
    );
}
