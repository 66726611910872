import IconButton from "@mui/material/IconButton";
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import { GridColDef, GridRenderCellParams, GridValueGetter, useGridApiRef } from "@mui/x-data-grid-premium";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { CandidateUpload } from "common/models/Candidates/Candidate";
import { PreviewEntityType } from "common/models/Previews/Previews";
import { GetCandidatesRecentUploads } from "services/CandidatesService";
import GridWithStateWrapper from "../GridWidthStateWrapper";
import PreviewLoaderComponent from "../Previews/PreviewLoader";
import { companyHasSinglePermission, userHasSinglePermission } from "util/PermissionsUtils";
import { Permission } from "common/models/Permissions";
import TagsManagementDialog from "../Dialogs/TagsManagementDialog";
import { MenuOptionDefinition } from "common/models/MenuDefinition";
import ActionMenu from "../Menus/ActionMenu";
import Box from "@mui/material/Box";

interface Props {
    gridName: string,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
    successHandler?: (message: string) => void,
}

const linkStyle: React.CSSProperties = { color: 'inherit', textDecoration: 'underline' };
const entityTypeId = 3;

export default function CandidatesRecentUploadsGridComponent({ gridName, loadingHandler, errorHandler, successHandler }: Props) {
    const [rows, setRows] = useState<CandidateUpload[]>([]);
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    const [showTagsPrevewNoDelay, setShowTagsPreviewNoDelay] = useState(false);
    const [previewType, setPreviewType] = useState<PreviewEntityType | ''>('');
    const [previewRecordId, setPreviewRecordId] = useState(0);
    const [isPreviewTags, setIsPreviewTags] = useState(false);
    const [previewRecordName, setPreviewRecordName] = useState('');
    const [taggingRecords, setTaggingRecords] = useState<number[]>([]);
    const apiRef = useGridApiRef();

    const userCanAddEditCandidates = useMemo(() => userHasSinglePermission(Permission.CandidatesAddEdit) && companyHasSinglePermission(Permission.CandidatesAddEdit), []);
    const canExportCandidates = useMemo(() => companyHasSinglePermission(Permission.CandidatesExport) && userHasSinglePermission(Permission.CandidatesExport), []);

    useEffect(() => {
        const getData = async () => {
            loadingHandler && loadingHandler(true);
            const res = await GetCandidatesRecentUploads(errorHandler);
            if (res) setRows(res);
            loadingHandler && loadingHandler(false);
        };
        getData();
    }, [loadingHandler, errorHandler]);

    const columns = useMemo<GridColDef[]>(() => {
        const handlePreviewHover = (type: PreviewEntityType | '', id: number, isTags: boolean = false, recordName: string = '', noDelay: boolean = false) => {
            setPreviewType(type);
            setPreviewRecordId(id);
            setIsPreviewTags(isTags);
            setPreviewRecordName(recordName);
            setIsPreviewOpen(true);
            setShowTagsPreviewNoDelay(noDelay);
        };

        const handlePreviewClose = () => {
            setIsPreviewOpen(false);
            setShowTagsPreviewNoDelay(false);
        };

        const dateValueGetter: GridValueGetter<CandidateUpload, any, undefined, string> = (value) => {
            if (value) {
                const m = moment(value);
                if (m.isValid() && m.get('year') > 1) {
                    return m.toDate();
                }
            }
        };

        const dateRenderer = (params: GridRenderCellParams) => {
            if (params.value) {
                return moment(params.value).format('DD MMM YYYY');
            }
            return 'Never';
        };

        const linkToCandidateRenderer = (params: GridRenderCellParams) => {
            const candidateId = params.row.candidateID;
            if (candidateId) {
                return (
                    <Link
                        to={`/candidates/${candidateId}`}
                        style={ linkStyle }
                        onMouseEnter={() => handlePreviewHover('candidate', candidateId) }
                        onMouseLeave={ handlePreviewClose }
                    >{params.value}</Link>
                );
            }
            return params.value;
        };

        const tagsRenderer = (params: GridRenderCellParams) => {
            const candidateId = params.row.candidateID;
            const candidateName = params.row.candidateName;
            if (userCanAddEditCandidates && candidateId) {
                return (
                    <IconButton
                        size="small"
                        onMouseEnter={ () => handlePreviewHover('candidate', candidateId, true, candidateName) }
                        onMouseLeave={ handlePreviewClose }
                        onClick={ () => setTaggingRecords([candidateId]) }
                    >
                        {params.value
                            ? <LocalOfferIcon style={{ color: '#f209a6' }} />
                            : <LocalOfferOutlinedIcon />
                        }
                    </IconButton>
                );
            }
            return (
                <IconButton
                    size="small"
                    onMouseEnter={() => handlePreviewHover('candidate', candidateId, true, candidateName)}
                    onMouseLeave={handlePreviewClose}
                    onClick={() => handlePreviewHover('candidate', candidateId, true, candidateName, true)}
                >
                    {params.value
                        ? <LocalOfferIcon />
                        : <LocalOfferOutlinedIcon />
                    }
                </IconButton>
            );
        };

        return [
            { headerName: 'ID', field: 'id', width: 75 },
            { headerName: 'Candidate', field: 'candidateName', width: 400, renderCell: linkToCandidateRenderer },
            { headerName: 'Upload Date', field: 'receivedDate', headerAlign: 'center', align: 'center', width: 100, valueGetter: dateValueGetter, renderCell: dateRenderer, type: 'date' },
            { headerName: 'Tags', field: 'hasTags', width: 70, headerAlign: 'center', align: 'center', renderCell: tagsRenderer },
            { headerName: 'Status', field: 'status', headerAlign: 'center', align: 'center', width: 200 },
            { headerName: 'Source', field: 'source', headerAlign: 'center', align: 'center', width: 200 }
        ];
    }, [userCanAddEditCandidates]);

    const tagManagementSuccessHandler = useCallback((message: string, recordIds: number[], finalTagCount: number) => {
        successHandler && successHandler(message);
        setRows(prev => {
            let tmpRows = [...prev];
            const hasTags = finalTagCount > 0;

            for (let i = 0; i < tmpRows.length; i++) {
                const r = tmpRows[i];
                if (recordIds.includes(r.candidateID)) r.hasTags = hasTags;
            }
            return tmpRows;
        });
    }, [successHandler]);

    const exportToExcel = useCallback(() => {
        const api = apiRef.current;
        if (api) api.exportDataAsExcel();
    }, [apiRef]);

    const gridActions = useMemo(() => {
        if (canExportCandidates) {
            const actionMenuDefinitions: MenuOptionDefinition[] = [
                { label: 'Export', type: 'action', action: exportToExcel, allow: () => canExportCandidates },
            ];

            return <ActionMenu color="secondary" label="List Actions" definition={actionMenuDefinitions} />;
        }
    }, [canExportCandidates, exportToExcel]);

    return (
        <>
            <PreviewLoaderComponent
                open={isPreviewOpen}
                entityType={previewType}
                recordId={previewRecordId}
                isTagsPreview={isPreviewTags}
                showDelayMs={showTagsPrevewNoDelay ? 0 : undefined}
                titleOverride={previewRecordName}
            />
            <TagsManagementDialog
                open={ taggingRecords.length > 0 }
                closeHandler={ () => setTaggingRecords([]) }
                entityId={ entityTypeId }
                recordIds={ taggingRecords }
                loadingHandler={ loadingHandler }
                errorHandler={ errorHandler }
                successHandler={ tagManagementSuccessHandler }
            />
            {Boolean(gridActions) &&
                <Box pb="10px" ml="auto">
                    {gridActions}
                </Box>
            }
            <GridWithStateWrapper
                gridName={gridName}
                rows={rows}
                columns={columns}
                apiRef={apiRef}
                density="compact"
                disableRowSelectionOnClick
                pagination
                pageSizeOptions={[100,250,500,1000]}
            />
        </>
    );
}