import React from "react";
import UserGeneralPageContent from "components/Content/UserGeneralContentPage";
import CustomApplicationContext from "util/CustomApplicationContext";

interface Props {
    setSummaryBar?: (summaryBar: JSX.Element) => void
}

export default function AccountGeneralSettingsPage({ setSummaryBar }: Props) {
    return (
        <CustomApplicationContext.Consumer>
            {c => (
                <UserGeneralPageContent
                    summaryBarTitle="Account > Contact Details"
                    summaryBarTabTitle="Contact Details > Account"
                    setSummaryBar={setSummaryBar}
                    refreshCurrentUserHandler={c.refreshCurrentUser}
                />
            )}
        </CustomApplicationContext.Consumer>
    );
}