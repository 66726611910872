import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { SxProps } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import * as XLSX from "xlsx";
import { GetNTTSLATimeRangeReports } from "services/MiscReportsService";
import { ReportDataProcessDateFields } from "util/ReportUtils";

interface Props {
    description: string,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void
}

const filterElementStyle: SxProps = { flex: '1 1 0', mr: 1 };

export default function NTTSLATimeRangeReport({ description, loadingHandler, errorHandler }: Props) {
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');

    useEffect(() => {
        const m = moment();
        setFromDate(m.startOf('month').format('YYYY-MM-DD'));
        setToDate(m.endOf('month').format('YYYY-MM-DD'));
    }, []);

    const getDataCallback = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        const res = await GetNTTSLATimeRangeReports(fromDate, toDate, errorHandler);
        const m = moment();
        const filename = `NTT_SLAReport_TimeRange_${m.format('YYYYMMDDhhmmss')}.xlsx`;
        const wb = XLSX.utils.book_new();
        if (res && res["data1"]) {
            ReportDataProcessDateFields(res["data1"] as any[]);

            const ws = XLSX.utils.json_to_sheet(res["data1"]);
            XLSX.utils.book_append_sheet(wb, ws, "Placements");
        }
        if (res && res["data2"]) {
            ReportDataProcessDateFields(res["data2"] as any[]);

            const ws = XLSX.utils.json_to_sheet(res["data2"]);
            XLSX.utils.book_append_sheet(wb, ws, "Jobs SLA - Active");
        }
        if (res && res["data3"]) {
            ReportDataProcessDateFields(res["data3"] as any[]);

            const ws = XLSX.utils.json_to_sheet(res["data3"]);
            XLSX.utils.book_append_sheet(wb, ws, "Offer Acceptance Rate");
        }
        if (res && res["data4"]) {
            ReportDataProcessDateFields(res["data4"] as any[]);

            const ws = XLSX.utils.json_to_sheet(res["data3"]);
            XLSX.utils.book_append_sheet(wb, ws, "Jobs Released To Suppliers");
        }
        XLSX.writeFile(wb, filename);
        loadingHandler && loadingHandler(false);
    }, [fromDate, toDate, errorHandler, loadingHandler]);

    return (
        <>
            <Box display="flex">
                <TextField type="date" label="From Date" value={fromDate} onChange={({ target }) => setFromDate(target.value)} sx={filterElementStyle} />
                <TextField type="date" label="To Date" value={toDate} onChange={({ target }) => setToDate(target.value)} sx={filterElementStyle} />
                <Button variant="contained" color="success" onClick={getDataCallback}>Run Report</Button>
            </Box>
            <Typography component="div" variant="h6" mt={2}>Report Description</Typography>
            <Typography component="div" variant="body2">{description}</Typography>
        </>
    );
}