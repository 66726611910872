import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { JobStage } from "../../../common/models/Jobs/Job";
import { GetJobStages } from "../../../services/ConfigurationService";
import { ResetJobStage } from "../../../services/JobsService";
import RWTextFieldComponent from "../../../components/RWTextFieldComponent";

interface Props {
    isOpen: boolean,
    jobId: number,
    jobStage: number,
    jobStageName: string,
    jobTitle: string,
    closeHandler: () => void,
    onSuccessHandler: (newStageId: number, newStageName: string) => void,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void
}

export default function ResetJobStageDialog({ isOpen, jobId, jobStage: jobStageProp, jobStageName: jobStageNameProp, jobTitle, closeHandler, loadingHandler, onSuccessHandler, errorHandler }: Props) {
    const [jobStages, setJobStages] = useState<JobStage[]>([]);
    const [selectedStageOrder, setSelectedStageOrder] = useState(-1);
    const [comments, setComments] = useState('');

    useEffect(() => {
        const getStages = async () => {
            const res = await GetJobStages(false,errorHandler);
            if (res) setJobStages(res);
        };
        getStages();
    }, [errorHandler]);

    const originalStatusOrder = useMemo(() => {
        const obj = jobStages.find(s => s.id === jobStageProp);
        if (obj) return obj.stageOrder;
        return 0;
    }, [jobStages, jobStageProp]);


    useEffect(() => {
        if (!isOpen) {
            setSelectedStageOrder(originalStatusOrder);
            setComments('');
        }
    }, [isOpen, originalStatusOrder]);

    const options = useMemo<JobStage[]>(() => {
        let opts: JobStage[] = [
            { name: 'None', stageOrder: -1, id: -1, enabled: true },
        ];
        let originalValue = 0;
        for (let i = 0; i < jobStages.length; i++) {
            const s = jobStages[i];
            if (s.stageOrder < originalStatusOrder) {
                opts.push(s);
            }
            else if (s.stageOrder === originalStatusOrder) originalValue = s.id;
        }

        opts.push({ name: jobStageNameProp, stageOrder: originalStatusOrder, id: originalValue, enabled: true });
        return opts;
    }, [jobStageNameProp, jobStages, originalStatusOrder]);

    const selectedStageName = useMemo(() => {
        const opt = options.find(o => o.stageOrder === selectedStageOrder);
        if (opt) return opt.name;
        return '';
    }, [options, selectedStageOrder]);

    const submitCallback = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        let s = options.find(o => o.stageOrder === selectedStageOrder);
        if (s) {
            const res = await ResetJobStage(jobId, s.id, comments, errorHandler);
            if (res) {
                onSuccessHandler(s.id, selectedStageName);
                closeHandler();
            }
        }
        loadingHandler && loadingHandler(false);
    }, [loadingHandler, options, selectedStageOrder, jobId, comments, errorHandler, onSuccessHandler, selectedStageName, closeHandler]);

    return (
        <Dialog open={isOpen} fullWidth>
            <DialogTitle>Reset Job Stage</DialogTitle>
            <DialogContent dividers>
                <Stack spacing={2}>
                    <TextField
                        value={jobId}
                        InputProps={{ readOnly: true }}
                        label="Jobs ID"
                    />
                    <TextField
                        value={jobTitle}
                        InputProps={{ readOnly: true }}
                        label="Job Title"
                    />
                    <TextField
                        select
                        value={selectedStageOrder.toString()}
                        label="Stage"
                        onChange={ ({target}) => setSelectedStageOrder(+target.value) }
                    >
                        {options.map(o => <MenuItem key={o.stageOrder} value={o.stageOrder.toString()}>{o.name}</MenuItem>)}
                    </TextField>
                    <RWTextFieldComponent
                        value={comments}
                        label="Comments"
                        onChange={({target}) => setComments(target.value) }
                        multiline
                        minRows={6}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="error" onClick={ closeHandler }>Cancel</Button>
                <Button variant="contained" color="success" disabled={ selectedStageOrder === -1 || selectedStageOrder === originalStatusOrder } onClick={ submitCallback } >Submit</Button>
            </DialogActions>
        </Dialog>
    );
}
