import Button from "@mui/material/Button";
import * as tinymce from 'tinymce';
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Client, ClientOwner, MinClient } from "common/models/Clients";
import { CustomField, CustomFieldPredefinedValue } from "common/models/Configuration/CustomFields";
import { Contact } from "common/models/Contacts";
import { Site } from "common/models/Site";
import { CreateClient, GetClientById, GetClientOwners, SetClientOwners, UpdateClient } from "services/ClientsService";
import { GetCustomFieldsByEntity_OnlyActive, GetPredefinedValues } from "services/CustomFieldsService";
import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import useObjectStateWithChangeTracker from "hooks/UseObjectStateWithChangeTracker";
import useUnsavedChangesDialog from "hooks/UseUnsavedChangesDialog";
import { Navigate } from "react-router-dom";
import { ClientToMinClient, DefaultMinClient, DefaultMinClientNoChanges } from "util/Definitions/Clients";
import OwnersListDialog from "components/Dialogs/Dashboard/OwnersListDialog";
import Box from "@mui/material/Box";
import { DashboardLayoutComponent, PanelModel } from "@syncfusion/ej2-react-layouts";
import PanelWrapper from "components/Dashboards/PanelWrapper";
import { ClientRecordDashboardElementDefinition, ClientRecordDashboardElementType } from "common/models/Dashboard/EditLayout";
import RecordAvatarComponent from "components/Dashboards/Graphs/ClientAvatarComponent";
import EditableClientLocationElement from "./EditableClientLocationElement";
import EditableClientDeadlinesElement from "./EditableClientDeadlinesElement";
import EditableRichTextElement from "../EditableRichTextElement";
import SingleFieldElement from "../SingleFieldElement";
import EditableSingleFieldElement from "../EditableSingleFieldElement";
import ClientPicker from "components/Pickers/ClientPicker";
import ContactPicker from "components/Pickers/ContactPicker";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import EditableClientCreditElement from "./EditableClientCreditElement";
import { CustomFieldSettingsMap, CustomFieldType } from "common/models/ScreenLayouts/CustomFields";
import { useTheme } from "@mui/material/styles";
import { IsValidCustomFieldValue, UdfClientFieldMapObj } from "util/Definitions/ScreenLayouts/CustomFields";
import { ClientScreenLayoutSettings, DefaultClientRecordDashboardElements, GetPanelDefinitionsFromPanelModels } from "util/Definitions/ScreenLayouts/Client";
import { GetCustomerSettingBySettingName } from "services/ConfigurationService";
import EditableClientCodeElement from "./EditableClientCodeElement";
import { Link } from "react-router-dom";
import { IsValidNumericValue, RegexIsPositiveInteger } from "util/RegExUtils";
import { GetMyUser, GetSingleSetting } from "services/UsersService";
import moment from "moment";
import Divider from "@mui/material/Divider";
import { NameIdObj } from "common/models/GenericTypes";
import MultipleDivisionsPicker from "components/Pickers/MultipleDivisionPicker";
import RWTextFieldComponent from "components/RWTextFieldComponent";
import TextField from "@mui/material/TextField";

interface Props {
    clientId?: number
    defaultCode1Type?: number,
    defaultCode2Type?: number,
    defaultSummary?: string,
    setSummaryBar?: (sb: JSX.Element) => void,
    loadingHandler?: (isLoading: boolean) => void,
    successHandler?: (message: string) => void,
    errorHandler?: (message: string) => void,
}

interface CustomFieldWithPredefinedValues extends CustomField {
    values?: CustomFieldPredefinedValue[],
}

const validationErrorMessage = (validationResult: string) => {
    if (validationResult === 'ok') return '';
    if (validationResult === 'required') return 'Required';
    return 'Not Valid';
};

const noticeOptions = [
    <MenuItem key="0" value="0">None</MenuItem>,
    <MenuItem key="1" value="1">Days</MenuItem>,
    <MenuItem key="2" value="2">Weeks</MenuItem>,
    <MenuItem key="3" value="3">Months</MenuItem>,
];

const { unitWidth, unitHeight, gapX, gapY, columns, mediaQueryMaxWidth } = ClientScreenLayoutSettings;
const cellSpacing = [gapX, gapY];

export default function EditRecordScreenLayout({ clientId = 0, defaultCode1Type = 0, defaultCode2Type = 0, defaultSummary = '', setSummaryBar, loadingHandler, successHandler, errorHandler }: Props) {
    const [isFetchingConfig, setIsFetchingConfig] = useState(false);
    const [isFetchingClient, setIsFetchingClient] = useState(false);
    const [isSettingUpCreation, setIsSettingUpCreation] = useState(false);
    const [fetchedConfig, setFetchedConfig] = useState(false);
    const [fetchedClient, setFetchedClient] = useState(false);
    const [showValidation, setShowValidation] = useState(false);
    const [fetchedLayoutConfig, setFetchedLayoutConfig] = useState(false);
    const [fetchingLayoutConfig, setFetchingLayoutConfig] = useState(false);
    const [layoutConfiguration, setLayoutConfiguration] = useState<PanelModel[] | null>(null);
    const [isOwnersDialogOpen, setIsOwnersDialogOpen] = useState(false);
    const [existingClient, setExistingClient] = useState<Client | null>(null);
    const [owners, setOwners] = useState<ClientOwner[]>([]);
    const [initialOwnersString, setInitialOwnersString] = useState('');
    const [availableCustomFields, setAvailableCustomFields] = useState<CustomFieldWithPredefinedValues[]>([]);
    const [isEditorDirty, setIsEditorDirty] = useState(false);
    const [avatarImage, setAvatarImage] = useState<File | null>(null);
    const { state, init, change, updateInitial, hasChanges } = useObjectStateWithChangeTracker<MinClient>(DefaultMinClient, DefaultMinClientNoChanges);
    const editorRef = useRef<tinymce.Editor | null>(null);
    const [savedChanges, setSavedChanges] = useState(false);
    const [createdClientId, setCreatedClientId] = useState(0);
    const [screenResizedControl, setScreenResizedControl] = useState(false);
    const layoutRef = useRef<DashboardLayoutComponent | null>(null);
    const theme = useTheme();

    const hasOwnerChanges = useMemo(() => {
        const currentOwnersString = owners.map(o => o.recruiterID).sort().join('|');
        return currentOwnersString !== initialOwnersString;
    }, [initialOwnersString, owners]);

    useEffect(() => {
        const getExistingClient = async () => {
            setIsFetchingClient(true);
            const res = await GetClientById(clientId, errorHandler);
            if (res) setExistingClient(res);
            setFetchedClient(true);
            setIsFetchingClient(false);
        };
        clientId && getExistingClient();
    }, [clientId, errorHandler]);

    useEffect(() => {
        const getOwners = async () => {
            if (clientId) {
                const res = await GetClientOwners(clientId);
                if (res) {
                    const ownersString = res.map(o => o.recruiterID).sort().join('|');
                    setOwners(res);
                    setInitialOwnersString(ownersString);
                }
            }
        };
        clientId && getOwners();
    }, [clientId]);

    const addOwnerCallback = useCallback((u: NameIdObj | null) => {
        if (u) {
            setOwners(prev => ([...prev, {
                clientID: clientId, firstName: '', fullName: u.name, lastName: '', recruiterID: u.id, userName: ''
            }]));
        }
    }, [clientId]);

    const removeOwnerCallback = useCallback((userId: number) => {
        setOwners(prev => {
            let ows = [...prev];
            const index = ows.findIndex(o => o.recruiterID === userId);
            if (index !== -1) ows.splice(index, 1);
            return ows;
        });
    }, []);

    useEffect(() => {
        const getActiveFields = async () => {
            setIsFetchingConfig(true);
            const customFields = await GetCustomFieldsByEntity_OnlyActive(1);
            if (customFields) {
                let udfDefinitions: CustomFieldWithPredefinedValues[] = [];
                for (let i = 0; i < customFields.length; i++) {
                    const u = customFields[i];
                    if (!u.usePredefinedValues) {
                        udfDefinitions.push(u);
                        continue;
                    }

                    const vals = await GetPredefinedValues(u.id);
                    if (vals) udfDefinitions.push({ ...u, values: vals });
                    else udfDefinitions.push(u);
                }
                setAvailableCustomFields(udfDefinitions);
            }
            setFetchedConfig(true);
            setIsFetchingConfig(false);
        };
        getActiveFields();
    }, []);

    useEffect(() => {
        const setDefaultCreationValues = async () => {
            setIsSettingUpCreation(true);
            let tempState = {...DefaultMinClient};
            tempState.code1Type = defaultCode1Type;
            tempState.code2Type = defaultCode2Type;
            tempState.description = defaultSummary;
            const res = await GetSingleSetting('Division');
            if (res && res.value) tempState.divisions = res.value;
            if (availableCustomFields.length > 0) {
                for (let i = 0; i < availableCustomFields.length; i++) {
                    const f = availableCustomFields[i];
                    if (f.usePredefinedValues && f.values && f.values.length > 0) {
                        const defaultValue = f.values.find(v => v.isDefault);
                        if (defaultValue) {
                            const field = 'customField' + f.name.substring(13) as keyof MinClient;
                            (tempState as any)[field] = defaultValue.value;
                        }
                    }
                }
            }
            init(tempState);
            setIsSettingUpCreation(false);
        };
        clientId === 0 && fetchedConfig && setDefaultCreationValues();
    }, [clientId, fetchedConfig, availableCustomFields, init, defaultCode1Type, defaultCode2Type, defaultSummary]);

    useEffect(() => {
        const getSavedState = async () => {
            setFetchingLayoutConfig(true);
            const elementsJson = await GetCustomerSettingBySettingName('ClientEditRecordDashboardLayoutElements');
            if (elementsJson) {
                const panels = JSON.parse(elementsJson) as PanelModel[];
                setLayoutConfiguration(panels);
            }
            setFetchingLayoutConfig(false);
            setFetchedLayoutConfig(true);
        };
        getSavedState();
    }, []);

    useEffect(() => loadingHandler && loadingHandler(isFetchingClient || isFetchingConfig || fetchingLayoutConfig || isSettingUpCreation), [isFetchingClient, isFetchingConfig, fetchingLayoutConfig, loadingHandler, isSettingUpCreation]);

    useEffect(() => {
        if (clientId && existingClient && fetchedClient && fetchedConfig) {
            const mc = ClientToMinClient(existingClient);
            init(mc);
            const api = editorRef.current;
            if (api) api.setContent(existingClient.description ?? '');
        }
    }, [clientId, existingClient, fetchedClient, fetchedConfig, init]);

    const saveChangesHandler = useCallback(async () => {
        if(!state.legalName && !state.tradingName ) {
            setShowValidation(true);
            errorHandler && errorHandler("Legal Name and Trading Name can't both be empty");
            return false;
        }

        if(Boolean(state.phone) && state.phone.length > 45) {
            setShowValidation(true);
            errorHandler && errorHandler("Phone can't have more than 45 characters");
            return false;
        }

        const isPermError = !IsValidNumericValue(state.cvDeadlinePerm.toString());
        const isContractError = !IsValidNumericValue(state.cvDeadlineContract.toString());
        const isFtcError = !IsValidNumericValue(state.cvDeadlineFixedContract.toString());

        if (isPermError || isContractError || isFtcError) {
            setShowValidation(true);
            let fieldName = "";
            if (isPermError) fieldName = "Perm";
            else if (isContractError) fieldName = "Contract";
            else if (isFtcError) fieldName = "FTC"
            errorHandler && errorHandler(`Deadline ${fieldName} must have a valid numeric value`);
            return false;
        }

        const isCreditBalanceError = !IsValidNumericValue(state.creditBalance.toString());
        const isCreditLimitError = !IsValidNumericValue(state.creditLimit.toString());

        if (isCreditBalanceError || isCreditLimitError) {
            setShowValidation(true);
            let fieldName = "";
            if (isCreditLimitError) fieldName = "Limit";
            else if (isCreditBalanceError) fieldName = "Balance";
            errorHandler && errorHandler(`Credit ${fieldName} must have a valid numeric value`);
            return false;
        }

        let emptyNumericUdfs: Partial<MinClient> = { };
        let nullDateUdfs: Partial<MinClient> = { };
        for (let i = 0; i < availableCustomFields.length; i++) {
            const u = availableCustomFields[i];
            if (!u.editable) continue;
            const customFieldNumber = u.name.substring(13);
            const customFieldKey = "customField" + customFieldNumber as keyof MinClient;
            const { format } = UdfClientFieldMapObj['CustomField' + customFieldNumber as CustomFieldType];
            const value = state[customFieldKey];

            const validation = IsValidCustomFieldValue(value, format, u.mandatory);

            if (validation === "required") {
                setShowValidation(true);
                errorHandler && errorHandler(`${u.agencyName} is required`);
                return false;
            }
            else if (validation === "invalid-number") {
                setShowValidation(true);
                errorHandler && errorHandler(`${u.agencyName} must have a valid numeric value`);
                return false;
            }
            else if (validation === "invalid-date") {
                setShowValidation(true);
                errorHandler && errorHandler(`${u.agencyName} must have a valid date value`);
                return false;
            }
            else if (validation === "range-date") {
                setShowValidation(true);
                errorHandler && errorHandler(`${u.agencyName} must be a date between 01-01-1753 and 31-12-9999`);
                return false;
            }

            if (format === "number" && (value === '' || value === null || value === undefined)) (emptyNumericUdfs[customFieldKey] as any) = undefined;
            if ((format === "date" || format === 'datetime') && !Boolean(value)) (nullDateUdfs[customFieldKey] as any) = '0001-01-01T00:00:00';
        }

        loadingHandler && loadingHandler(true);
        const editorApi = editorRef.current;
        const summaryContent = editorApi ? editorApi.getContent() : '';
        const ownerIds = owners.map(o => o.recruiterID);
        const creditLimit = state.creditLimit ? state.creditLimit : undefined;
        const creditBalance = state.creditBalance ? state.creditBalance : undefined;
        const notice = Boolean(state.defaultNoticePeriod) ? state.defaultNoticePeriod : 0;
        let cId = clientId;
        if (clientId === 0) {
            const res = await CreateClient({...state, description: summaryContent, creditLimit: creditLimit, creditBalance: creditBalance, defaultNoticePeriod: notice, ...emptyNumericUdfs, ...nullDateUdfs }, avatarImage, errorHandler);
            if (!res) {
                loadingHandler && loadingHandler(false);
                return false;
            }
            cId = res.value;
            if (ownerIds.length === 0 && cId !== 0) {
                const me = await GetMyUser();
                if (me && me.userID) {
                    await SetClientOwners(cId, [+me.userID]);
                }
            }
            setCreatedClientId(res.value);
        }
        else if (clientId && (hasChanges || isEditorDirty || avatarImage)) {
            const res = await UpdateClient({...state, description: summaryContent, creditLimit: creditLimit, creditBalance: creditBalance, defaultNoticePeriod: notice, ...emptyNumericUdfs, ...nullDateUdfs }, avatarImage, errorHandler);
            if (!res) {
                loadingHandler && loadingHandler(false);
                return false;
            }
        }
        if (hasOwnerChanges && cId) {
            const res = await SetClientOwners(cId, ownerIds);
            if (res) setInitialOwnersString(ownerIds.sort().join('|'));
        }
        updateInitial();
        setIsEditorDirty(false);
        editorApi && editorApi.setDirty(false);
        loadingHandler && loadingHandler(false);
        setAvatarImage(null);
        setSavedChanges(true);
        return true;
    }, [clientId, state, availableCustomFields, owners, hasChanges, hasOwnerChanges, isEditorDirty, avatarImage, updateInitial, loadingHandler, errorHandler]);

    useEffect(() => {
        const actionButton = <Button variant="contained" color="success" disabled={!hasChanges && !isEditorDirty && ! hasOwnerChanges && avatarImage === null} onClick={saveChangesHandler}>Save</Button>
        if (clientId === 0 && setSummaryBar) {
            const sb = <TitleAndActionSummaryBar title="Clients > Create" browserTabTitle="Create > Clients" action={actionButton} />;
            setSummaryBar(sb);
        }
        else if (clientId && setSummaryBar) {
            const goBackAction = <Link to={`/clients/${clientId}`} style={{ textDecoration: 'none' }}><Button variant="contained" color="error" sx={{ mr: '5px' }}>Cancel</Button></Link>;
            const sb = <TitleAndActionSummaryBar title="Clients > Edit" browserTabTitle="Edit > Clients" action={<>{goBackAction}{actionButton}</>} />;
            setSummaryBar(sb);
        }
    }, [clientId, hasChanges, isEditorDirty, hasOwnerChanges, avatarImage, setSummaryBar, saveChangesHandler]);

    const onStringFieldChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        let val = value;
        if (name.startsWith('dtUDF') && value && !value.includes('T')) val += 'T00:00:00';
        change(name as keyof MinClient, val);
    }, [change]);

    const onDateFieldChange = useCallback((m: moment.Moment | null, fieldName: string) => {
        if(m && m.isValid()) {
            change(fieldName as keyof MinClient, m.format('YYYY-MM-DD'))
        }
        else if (m === null) change(fieldName as keyof MinClient, '');
    }, [change]);

    const onDateTimeFieldChange = useCallback((m: moment.Moment | null, fieldName: string) => {
        if(m && m.isValid()) {
            change(fieldName as keyof MinClient, m.format('YYYY-MM-DDTHH:mm'));
        }
        else if (m === null) change(fieldName as keyof MinClient, '');
    }, [change]);

    const positiveIntegerNumberValidator = useCallback((value: string) => {
        return value === '' || RegexIsPositiveInteger(value);
    }, []);

    const onNumberFieldChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        const n = +value;
        if (isNaN(n) && value !== '') return;
        if (value.length > 11) return;

        const i = value.indexOf('.');
        if (value.length > 8 && i === -1) return;

        if(i >= 0) {
            const decimals = value.substring(i + 1);
            if (decimals.length > 2) {
                change(name as keyof MinClient, value.substring(0, i + 3));
                return;
            }
        }

        change(name as keyof MinClient, value);

    }, [change]);

    const onNumericListFieldChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        change(name as keyof MinClient, +value);
    }, [change]);

    const onParentChange = useCallback((client: Client | null) => {
        change('parentCompany', client ? client.id : 0);
    }, [change]);

    const onContactChange = useCallback((contact: Contact | null, field: 'primaryContact' | 'defaultBillingContactID' | 'defaultTimesheetApproverID') => {
        change(field, contact ? contact.id : 0);
    }, [change]);

    const onSiteChange = useCallback((site: Site | null) => {
        if (site) {
            change('siteID', site.id,);
            change('siteName', site.name,);
            change('address1', site.address1);
            change('address2', site.address2);
            change('address3', site.address3);
            change('suburb', site.suburb);
            change('state', site.state);
            change('postcode', site.postcode);
            change('countryName', site.countryName ?? '');
        }
        else {
            change('siteID', 0);
            change('siteName', '',);
            change('address1', '');
            change('address2', '');
            change('address3', '');
            change('suburb', '');
            change('state', '');
            change('postcode', '');
            change('countryName', '');
        }

    }, [change]);

    const divisionIds = useMemo<number[]>(() => {
        if (state.divisions) {
            const split = state.divisions.split(';');
            return split.map(d => +d);
        }
        return [];
    }, [state.divisions]);

    const onDivisionChange = useCallback((divisionIds: NameIdObj[]) => {
        const joined = divisionIds.map(d => d.id).join(';');
        change('divisions', joined);
    }, [change]);

    const { unsavedChangesDialog, hasBlockedRoute } = useUnsavedChangesDialog(hasChanges || isEditorDirty || hasOwnerChanges || avatarImage !== null, saveChangesHandler);

    const redirectClientId = useMemo(() => {
        if (clientId !== 0) return clientId;
        if (savedChanges && createdClientId) return createdClientId;
        return 0;
    }, [clientId, createdClientId, savedChanges]);

    const elements = useMemo<ClientRecordDashboardElementDefinition[]>(() => {
        if (!fetchedLayoutConfig) return [];
        if (layoutConfiguration !== null) {
            const elements = GetPanelDefinitionsFromPanelModels(layoutConfiguration);
            return elements ;
        }
        return DefaultClientRecordDashboardElements;
    }, [layoutConfiguration, fetchedLayoutConfig]);

    const removeUploadedImageHandler = useCallback(() => {
        change('photoName', '');
        change('photoUrl', '');
    }, [change]);

    const customFieldsSettingsMap = useMemo<CustomFieldSettingsMap>(() => {
        let obj: CustomFieldSettingsMap = {};
        availableCustomFields.forEach(u => {
            const customFieldNumber = u.name.substring(13);
            const key = 'CustomField' + customFieldNumber as CustomFieldType;
            obj[key] = {
                title: u.agencyName,
                isMultiLine: u.multiLine && !u.usePredefinedValues,
                isMandatory: u.mandatory,
                isEditable: u.editable,
                usePredefinedValues: u.usePredefinedValues,
                values: u.values
            }
        });
        return obj;
    }, [availableCustomFields]);

    const renderElement = useCallback((id: string, type: ClientRecordDashboardElementType) => {
        switch (type) {
            case 'Divider': return <Box pt="20px"><Divider component="div" /></Box>;
            case 'Spacer': return <></>;
            case 'ClientInfo': return (
                <RecordAvatarComponent
                    isEditable
                    entityId={1}
                    primaryText={state.tradingName}
                    secondaryText={state.legalName}
                    photoUrl={state.photoUrl}
                    imageFile={avatarImage}
                    imageSetHandler={setAvatarImage}
                    removeUploadedImageHandler={removeUploadedImageHandler}
                />
            );
            case 'ClientLocation': return (
                <EditableClientLocationElement
                    siteId={state.siteID}
                    clientId={clientId}
                    siteName={state.siteName}
                    address1={state.address1}
                    address2={state.address2}
                    address3={state.address3}
                    suburb={state.suburb}
                    state={state.state}
                    postcode={state.postcode}
                    country={state.countryName}
                    onSiteChange={onSiteChange}
                    errorHandler={errorHandler}
                    loadingHandler={loadingHandler}
                    successHandler={successHandler}
                />
            );
            case 'ClientSubmissionDeadlines': return (
                <EditableClientDeadlinesElement
                    showValidation={showValidation}
                    contract={state.cvDeadlineContract}
                    ftc={state.cvDeadlineFixedContract}
                    perm={state.cvDeadlinePerm}
                    onNumberChangeHandler={onNumberFieldChange}
                />
            );
            case 'ClientSummary': return (
                <EditableRichTextElement
                    title="Summary"
                    content={state.description}
                    isDarkTheme={theme.palette.mode === 'dark'}
                    editorRef={editorRef}
                    editorDirtyHandler={ setIsEditorDirty }
                />
            );
            case 'ClientId': return <SingleFieldElement labelWidthPercent={30} fontSize="1rem" fieldTitle='ID' fieldValue={state.id} format="number" />;
            case 'ClientName':
                return (
                    <EditableSingleFieldElement
                        isError={showValidation && !state.legalName && !state.tradingName}
                        errorMessage="Required"
                        fieldTitle='Legal Name'
                        fieldValue={state.legalName}
                        fieldName="legalName"
                        onChangeHandler={onStringFieldChange}
                    />
                );
            case 'ClientTradingName':
                return (
                    <EditableSingleFieldElement
                        isError={showValidation && !state.legalName && !state.tradingName}
                        errorMessage="Required"
                        fieldTitle='Trading Name'
                        fieldValue={state.tradingName}
                        fieldName="tradingName"
                        onChangeHandler={onStringFieldChange}
                    />
                );
            case 'ClientPhone':
                const phoneValue = state.phone;
                const isPhoneError = Boolean(state.phone) && state.phone.length > 45;
                return <EditableSingleFieldElement isError={showValidation && isPhoneError} errorMessage="Not Valid" fieldTitle='Phone' fieldValue={phoneValue} fieldName="phone" onChangeHandler={onStringFieldChange} />;
            case 'ClientWebsite':
                const website = state.website;
                return <EditableSingleFieldElement fieldTitle='Website' fieldValue={website} fieldName="website" onChangeHandler={onStringFieldChange} />;
            case 'ClientLinkedIn':
                const linkedIn = state.linkedinURL;
                return <EditableSingleFieldElement fieldTitle='LinkedIn' fieldValue={linkedIn} fieldName="linkedinURL" onChangeHandler={onStringFieldChange} />;
            case 'ClientTwitter':
                const twitter = state.twitterURL;
                return <EditableSingleFieldElement fieldTitle='Twitter' fieldValue={twitter} fieldName="twitterURL" onChangeHandler={onStringFieldChange} />;
            case 'ClientParent': return (
                <EditableSingleFieldElement format="custom" fieldTitle='Parent' >
                    <ClientPicker
                        hideLabel
                        variant="standard"
                        value={state.parentCompany}
                        onSelectCallback={ onParentChange }
                        errorHandler={errorHandler}
                        loadingHandler={loadingHandler}
                        successHandler={successHandler}
                    />
                </EditableSingleFieldElement>
            );
            case 'ClientPrimaryContact': return (
                <EditableSingleFieldElement format="custom" fieldTitle='Primary Contact' >
                    <ContactPicker
                        hideLabel
                        variant="standard"
                        value={state.primaryContact}
                        clientId={clientId}
                        onSelectCallback={ c => onContactChange(c, 'primaryContact') }
                        errorHandler={errorHandler}
                        loadingHandler={loadingHandler}
                        successHandler={successHandler}
                    />
                </EditableSingleFieldElement>
            );
            case 'ClientBillingContact': return (
                <EditableSingleFieldElement format="custom" fieldTitle='Billing Contact' >
                    <ContactPicker
                        hideLabel
                        variant="standard"
                        value={state.defaultBillingContactID} onSelectCallback={ c => onContactChange(c, 'defaultBillingContactID') }
                        clientId={clientId}
                        errorHandler={errorHandler}
                        loadingHandler={loadingHandler}
                        successHandler={successHandler}
                    />
                </EditableSingleFieldElement>
            );
            case 'ClientDefaultApprover': return (
                <EditableSingleFieldElement format="custom" fieldTitle='Default Approver' >
                    <ContactPicker
                        hideLabel
                        variant="standard"
                        value={state.defaultTimesheetApproverID} onSelectCallback={ c => onContactChange(c, 'defaultTimesheetApproverID') }
                        clientId={clientId}
                        errorHandler={errorHandler}
                        loadingHandler={loadingHandler}
                        successHandler={successHandler}
                    />
                </EditableSingleFieldElement>
            );
            case 'ClientOwners': return (
                <EditableSingleFieldElement format="custom" fieldTitle="Owners">
                    <Typography variant="body1" component="span" color="primary.main" sx={{ cursor: 'pointer' }} onClick={ () => setIsOwnersDialogOpen(true) }>Click to change Owners</Typography>
                </EditableSingleFieldElement>
            );
            case 'ClientStatus': return (
                <EditableSingleFieldElement format="select" fieldTitle='Status' fieldValue={state.type.toString()} fieldName="type" onChangeHandler={onNumberFieldChange} >
                    <MenuItem value="1">Unqualified</MenuItem>
                    <MenuItem value="2">Qualified</MenuItem>
                    <MenuItem value="3">Client</MenuItem>
                    <MenuItem value="4">Supplier</MenuItem>
                    <MenuItem value="6">Supplier - Sub Contractor</MenuItem>
                    <MenuItem value="5">Archived</MenuItem>
                    <MenuItem value="7">Prospect</MenuItem>
                    <MenuItem value="8">Previous Client</MenuItem>
                </EditableSingleFieldElement>
            );
            case 'ClientRating': return (
                <EditableSingleFieldElement format="select" fieldTitle='Rating' fieldValue={state.rating.toString()} fieldName="rating" onChangeHandler={onNumberFieldChange} >
                    <MenuItem value="0">None</MenuItem>
                    <MenuItem value="1">1</MenuItem>
                    <MenuItem value="2">2</MenuItem>
                    <MenuItem value="3">3</MenuItem>
                    <MenuItem value="4">4</MenuItem>
                    <MenuItem value="5">5</MenuItem>
                </EditableSingleFieldElement>
            );
            case 'ClientCredit': return (
                <EditableClientCreditElement
                    balance={state.creditBalance}
                    limit={state.creditLimit}
                    rating={state.creditRating}
                    showValidation={showValidation}
                    onNumberChangeHandler={onNumberFieldChange}
                    onStringChangeHandler={onStringFieldChange}
                />
            );
            case 'ClientDivisions': return (
                <EditableSingleFieldElement format="custom" fieldTitle='Division' >
                    <MultipleDivisionsPicker value={divisionIds} onSelect={onDivisionChange} hideLabel variant="standard" limitVisible={2} />
                </EditableSingleFieldElement>
            );
            case 'ClientCode1': return (
                <EditableClientCodeElement
                    type={state.code1Type}
                    value={state.code1}
                    typeField="code1Type"
                    valueField="code1"
                    onTypeChangeHandler={onNumberFieldChange}
                    onValueChangeHandler={onStringFieldChange}
                />
            );
            case 'ClientCode2': return (
                <EditableClientCodeElement
                    type={state.code2Type}
                    value={state.code2}
                    typeField="code2Type"
                    valueField="code2"
                    onTypeChangeHandler={onNumberFieldChange}
                    onValueChangeHandler={onStringFieldChange}
                />
            );
            case 'ClientDefaultNoticePeriod': return (
                <EditableSingleFieldElement format="custom" fieldTitle='Default Notice'>
                    <Box display="flex">
                        <RWTextFieldComponent value={state.defaultNoticePeriod?.toString()} name="defaultNoticePeriod" variant="standard" placeholder="From" sxOptions={{ flex: '1 1 0', pr: '5px' }} onChange={ onNumberFieldChange } validator={positiveIntegerNumberValidator} />
                        <TextField select value={state.defaultNoticePeriodUnits.toString()} name="defaultNoticePeriodUnits" variant="standard" sx={{ flex: '1 1 0', pl: '5px' }} onChange={ onNumericListFieldChange } >
                            {noticeOptions}
                        </TextField>
                    </Box>
                </EditableSingleFieldElement>
            );
            default:
                if (type.startsWith('CustomField')) {
                    const settings = customFieldsSettingsMap[type];
                    if (settings && settings.isEditable) {
                        let title = settings.title;
                        let predefinedValues = settings.usePredefinedValues && settings.values ? settings.values : null;
                        const { field, format } = UdfClientFieldMapObj[type as CustomFieldType];
                        const rawValue = state[field as keyof MinClient];
                        const udfValue = (state[field as keyof MinClient] ?? '').toString();
                        const validationResult = IsValidCustomFieldValue(rawValue, format, settings.isMandatory ?? false);
                        const isValid = validationResult === "ok";
                        const errorMessage = validationErrorMessage(validationResult);
                        if (predefinedValues) {
                            return (
                                <EditableSingleFieldElement isError={showValidation && !isValid} errorMessage={errorMessage} format="select" fieldTitle={title} fieldValue={udfValue} fieldName={field} onChangeHandler={onStringFieldChange}>
                                    <MenuItem value="">None</MenuItem>
                                    {predefinedValues.map(v => <MenuItem key={v.value} value={v.value}>{v.value + (v.isDefault ? ' (Default)' : '')}</MenuItem>)}
                                </EditableSingleFieldElement>
                            );
                        }
                        else if (udfValue !== undefined && format === 'string') {
                            return (
                                <EditableSingleFieldElement
                                    isError={showValidation && !isValid}
                                    errorMessage={errorMessage}
                                    format={format}
                                    fieldTitle={title}
                                    fieldValue={udfValue}
                                    fieldName={field}
                                    multiline={settings.isMultiLine}
                                    onChangeHandler={onStringFieldChange}
                                />
                            );
                        }
                        else if (udfValue !== undefined && format === 'number') {
                            return (
                                <EditableSingleFieldElement
                                    isError={showValidation && !isValid}
                                    errorMessage={errorMessage}
                                    format={format}
                                    fieldTitle={title}
                                    fieldValue={udfValue}
                                    fieldName={field}
                                    onChangeHandler={onNumberFieldChange}
                                />
                            );
                        }
                        else if (format === 'date') {
                            return (
                                <EditableSingleFieldElement
                                    isError={showValidation && !isValid}
                                    errorMessage={errorMessage}
                                    format={format}
                                    fieldTitle={title}
                                    fieldValue={udfValue}
                                    fieldName={field}
                                    onDateChangeHandler={onDateFieldChange}
                                />
                            );
                        }
                        else if (format === 'datetime') {
                            return (
                                <EditableSingleFieldElement
                                    isError={showValidation && !isValid}
                                    errorMessage={errorMessage}
                                    format={format}
                                    fieldTitle={title}
                                    fieldValue={udfValue}
                                    fieldName={field}
                                    onDateChangeHandler={onDateTimeFieldChange}
                                />
                            );
                        }
                    }
                    else if (settings) return <SingleFieldElement labelWidthPercent={30} fontSize="1rem" fieldTitle={settings.title} fieldValue="[ Non Editable Custom Field ]" />;
                    else return <SingleFieldElement labelWidthPercent={30} fontSize="1rem" fieldTitle={type} fieldValue="[ Disabled Custom Field ]" />;
                }
                return <div>{id}</div>;
        }
    }, [state, avatarImage, removeUploadedImageHandler, clientId, onSiteChange, errorHandler, loadingHandler, successHandler, showValidation, onNumberFieldChange, theme.palette.mode, onStringFieldChange, onParentChange, divisionIds, onDivisionChange, positiveIntegerNumberValidator, onNumericListFieldChange, onContactChange, customFieldsSettingsMap, onDateFieldChange, onDateTimeFieldChange]);

    const layoutResizeStopHandler = useCallback(() => setScreenResizedControl(prev => !prev), []);

    useEffect(() => {
        if (screenResizedControl) {}
        const api = layoutRef.current;
        if (api && fetchedLayoutConfig) {
            const refreshTimeout = setTimeout(() => {
                api.refresh();
                api.refresh();
            }, 250);
            return () => clearTimeout(refreshTimeout);
        }
    }, [fetchedLayoutConfig, screenResizedControl]);

    useEffect(() => {
        window.addEventListener('resize', layoutResizeStopHandler);
        return () => window.removeEventListener('resize', layoutResizeStopHandler);
    }, [layoutResizeStopHandler]);

    const renderLayout = useCallback(() => {
        if (!fetchedLayoutConfig) return <></>;
        
        let sorted = [...elements];
        sorted.sort((a, b) => {
            if (a.col === b.col) return a.row < b.row ? -1 : 1;
            else return a.col < b.col ? -1 : 1;
        });

        const mediaQuery = mediaQueryMaxWidth ? `max-width: ${mediaQueryMaxWidth}` : undefined;
        return (
            <DashboardLayoutComponent
                cellSpacing={cellSpacing}
                columns={columns}
                cellAspectRatio={30 / 2}
                allowDragging={false}
                allowResizing={false}
                resizeStop={ layoutResizeStopHandler }
                ref={l => layoutRef.current = l}
                mediaQuery={mediaQuery}
            >
                {sorted.map(e => (
                    <PanelWrapper
                        key={e.id}
                        id={e.id}
                        col={e.col}
                        row={e.row}
                        sizeX={e.sizeX}
                        sizeY={e.sizeY}
                        minSizeX={e.minSizeX}
                        minSizeY={e.minSizeY}
                        maxSizeX={e.maxSizeX}
                        maxSizeY={e.maxSizeY}
                        resizeControl={screenResizedControl}
                        resizeIconColor={theme.palette.text.disabled}
                        gapX={gapX}
                        gapY={gapY}
                        unitWidth={unitWidth}
                        unitHeight={unitHeight}
                    >
                        {renderElement(e.id, e.type)}
                    </PanelWrapper>
                ))}
            </DashboardLayoutComponent>
        );
    }, [elements, fetchedLayoutConfig, theme.palette.text.disabled, screenResizedControl, layoutResizeStopHandler, renderElement]);

    return (
        <>
            {unsavedChangesDialog}
            { !hasBlockedRoute && redirectClientId !== 0 && savedChanges && !hasChanges && !isEditorDirty && <Navigate to={`/clients/${redirectClientId}`} /> }
            <OwnersListDialog
                type="Client"
                open={isOwnersDialogOpen}
                editable
                closeHandler={ () => setIsOwnersDialogOpen(false) }
                owners={owners}
                addOwnerHandler={addOwnerCallback}
                removeOwnerHandler={removeOwnerCallback}
            />
            <Box p="10px" height="100%">
                <div className="control-section">
                    { renderLayout() }
                </div>
            </Box>
        </>
    );
}
