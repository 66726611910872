import { AnalyticsConfigOptions } from '../../../common/models/Configuration/Analytics';
import { ChangeTracker } from '../../../common/models/hooks/ChangeTracker';

export const DefaultOptions: AnalyticsConfigOptions = {
    AnalyticsActivityNumbersBasedOn: "1",
    AnalyticsContractBillingsBasedOn: "0",
    AnalyticsPermBillingsBasedOn: "0",
    AnalyticsPlacementNumbersBasedOn: "0",
    ForecastingMode: "0",
    NetRevenueName: "Net Revenue",
    StretchLabelOverride: "",
    TargetLabelOverride: "",
    YearStart: "1",
}

export const NoChangesTracker: ChangeTracker<AnalyticsConfigOptions> = {
    AnalyticsActivityNumbersBasedOn: false,
    AnalyticsContractBillingsBasedOn: false,
    AnalyticsPermBillingsBasedOn: false,
    AnalyticsPlacementNumbersBasedOn: false,
    ForecastingMode: false,
    NetRevenueName: false,
    StretchLabelOverride: false,
    TargetLabelOverride: false,
    YearStart: false,
}