import {
    GridColDef,
    GridColumnVisibilityModel,
    GridRenderCellParams,
    GridRowSelectionModel,
    GridValueGetter,
    useGridApiRef
} from "@mui/x-data-grid-premium";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Meeting } from "common/models/Meetings";
import { CancelMeeting, GetDashboardMeetings, GetMeetingsReportData } from "services/MeetingsService";
import GridWithStateWrapper from "../GridWidthStateWrapper";
import { Link } from "react-router-dom";
import ConfirmationDialog from "../Dialogs/Generic/ConfirmationDialog";
import ActionsDropDownButton from "components/SummaryBars/Actions/ActionsDropsDownMenu";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import { MeetingSearch } from "common/models/Search/MeetingSearch";
import {GetClientMeetings} from "../../services/ClientsService";
import {GetContactMeetings} from "../../services/ContactsService";
import {GetOpportunityMeetings} from "../../services/OpportunitiesService";
import {GetJobMeetings} from "../../services/JobsService";
import {GetPlacementMeetings} from "../../services/PlacementsService";
import {GetCandidateMeetings} from "../../services/CandidatesService";
import {defaultGridCellStyle} from "../../util/GridUtils";
import { PreviewEntityType } from "common/models/Previews/Previews";
import PreviewLoaderComponent from "components/Previews/PreviewLoader";

interface Props {
    gridName: string,
    source: 'dashboard' | 'search' | 'client-record' | 'contact-record' | 'candidate-record' | 'opportunity-record' | 'job-record' | 'placement-record',
    sourceId?: number
    searchData?: MeetingSearch,
    excelName?: string,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
    successHandler?: (message: string) => void
}

const linkStyle: React.CSSProperties = { color: 'inherit', textDecoration: 'underline' };

export default function MeetingsGridComponent({ gridName, source, sourceId, searchData, excelName, loadingHandler, errorHandler, successHandler }: Props) {
    const [rows, setRows] = useState<Meeting[]>([]);
    const [cancelMeetingId, setCancelMeetingId] = useState<number | null>(null);
    const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    const [previewType, setPreviewType] = useState<PreviewEntityType | ''>('');
    const [previewRecordId, setPreviewRecordId] = useState(0);
    const [isPreviewTags, setIsPreviewTags] = useState(false);
    const [previewRecordName, setPreviewRecordName] = useState('');
    const apiRef = useGridApiRef();

    const getRowsCallback = useCallback(async () => {
            loadingHandler && loadingHandler(true);
            let res: Meeting[] | null = [];
            if (source === 'dashboard') res = await GetDashboardMeetings(errorHandler);
            if (source === 'client-record' && sourceId) res = await GetClientMeetings(sourceId, errorHandler);
            if (source === 'contact-record' && sourceId) res = await GetContactMeetings(sourceId, errorHandler);
            if (source === 'candidate-record' && sourceId) res = await GetCandidateMeetings(sourceId, errorHandler);
            if (source === 'opportunity-record' && sourceId) res = await GetOpportunityMeetings(sourceId, errorHandler);
            if (source === 'job-record' && sourceId) res = await GetJobMeetings(sourceId, errorHandler);
            if (source === 'placement-record' && sourceId) res = await GetPlacementMeetings(sourceId, errorHandler);
            else if (source === 'search' && searchData) res = await GetMeetingsReportData(searchData.consultantId, searchData.timeRange, searchData.status, errorHandler);
            if (res) setRows(res);
            loadingHandler && loadingHandler(false);
    }, [loadingHandler, source, errorHandler, sourceId, searchData]);

    useEffect(() => {
        getRowsCallback();
    }, [getRowsCallback]);

    const cancelMeetingCallback = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        if (cancelMeetingId) {
            const res = await CancelMeeting(cancelMeetingId, errorHandler);
            if (res) {
                successHandler && successHandler('Meeting Canceled');
                setCancelMeetingId(null);
                getRowsCallback();
            }
        }
        loadingHandler && loadingHandler(false);
    }, [cancelMeetingId, loadingHandler, errorHandler, getRowsCallback, successHandler]);

    const columns = useMemo<GridColDef[]>(() => {
        const handlePreviewHover = (type: PreviewEntityType | '', id: number, isTags: boolean = false, recordName: string = '') => {
            setPreviewType(type);
            setPreviewRecordId(id);
            setIsPreviewTags(isTags);
            setPreviewRecordName(recordName);
            setIsPreviewOpen(true);
        };

        const handlePreviewClose = () => {
            setIsPreviewOpen(false);
        };

        const dateValueGetter: GridValueGetter<Meeting, any, undefined, string> = (value) => {
            if (value) {
                const m = moment(value);
                if (m.isValid() && m.get('year') > 1) {
                    return m.toDate();
                }
            }
        };
        
        const dateRenderer = (params: GridRenderCellParams) => {
            if (params.value) {
                let val = moment(params.value).format('DD MMM YYYY h:mm A');
                
                return (
                    <div style={defaultGridCellStyle}>
                        <span>{val}</span>
                    </div>
                );
            }
            return params.value;
        }

        const linkToMeetingRenderer = (params: GridRenderCellParams) => {
            const meetingId = params.row.id;
            if (meetingId) {
                return <div style={defaultGridCellStyle}>
                    <Link to={`/meetings/${meetingId}`} style={linkStyle}>{params.value}</Link>
                </div>
            }
            return params.value;
        };

        const linkToAttendeesRenderer = (params: GridRenderCellParams) => {
            const meetingId = params.row.id;
            const count = params.row.numberOfAttendees;
            if (meetingId && count > 0) {
                return (
                    <div style={defaultGridCellStyle}>
                        <Link to={`/meetings/${meetingId}?tab=Attendees`} style={ linkStyle } onMouseEnter={ () => handlePreviewHover('meeting-attendees', meetingId) } onMouseLeave={ handlePreviewClose } >{params.value}</Link>
                    </div>
                );
            }
            return params.value;
        }

        const htmlRenderer = (params: GridRenderCellParams) => {
            if (params.value) {
                const newLineReplaced = (params.value as string).replaceAll('\n', '<br />');
                return <div style={{padding: '5px 0' }} dangerouslySetInnerHTML={{ __html: newLineReplaced }} />
            }
        }

        const minHeightCellRenderer = (params: GridRenderCellParams) => {
            return (
                <div style={defaultGridCellStyle}>
                    <span>{params.value}</span>
                </div>
            );
        };

        return [
            { field: 'id', headerName: 'ID', width: 100, align: 'center', headerAlign: 'center',renderCell: linkToMeetingRenderer },
            { field: 'date', headerName: 'Date', width: 200, type: 'date', align: 'center', headerAlign: 'center', valueGetter: dateValueGetter, renderCell: dateRenderer},
            { field: 'statusName', headerName: 'Status', align: 'center', headerAlign: 'center', renderCell: minHeightCellRenderer },
            { field: 'typeName', headerName: 'Type', align: 'center', headerAlign: 'center', width: 150, renderCell: linkToMeetingRenderer },
            { field: 'organizerName', headerName: 'Organizer', align: 'center', headerAlign: 'center', width: 200, renderCell: minHeightCellRenderer },
            { field: 'numberOfAttendees', type: 'number', headerName: '# Attendees', align: 'center', headerAlign: 'center', renderCell: linkToAttendeesRenderer },
            { field: 'location', headerName: 'Location', align: 'center', headerAlign: 'center', width: 200, renderCell: minHeightCellRenderer },
            { field: 'subject', headerName: 'Subject', renderCell: linkToMeetingRenderer, width: 200 },
            { field: 'meetingNotes', headerName: 'Notes', width: 250 , renderCell: htmlRenderer},
        ];
    }, []);

    const exportAsExcelHandler = useCallback(() => {
        const api = apiRef.current;
        if (api) {
            const reportName = excelName ? excelName : 'Meetings';
            const m = moment();
            const filename = reportName + m.format('YYYYMMDDhhmmss');
            api.exportDataAsExcel({ fileName: filename });
        }
    }, [apiRef, excelName]);

    const gridActions = useMemo(() => {
        const meetingId = selectionModel[0] ? +selectionModel[0] : 0;

        return (
            <ActionsDropDownButton color="secondary" label="List Actions">
                <MenuItem disabled={ selectionModel.length !== 1 } onClick={ () => setCancelMeetingId(meetingId) }>Cancel</MenuItem>
                {selectionModel.length !== 1 ?
                    <MenuItem disabled >Edit</MenuItem>
                    :
                    <Link to={`/meetings/${meetingId}/edit`} style={{ color: 'inherit', textDecoration: 'none' }}>
                        <MenuItem>Edit</MenuItem>
                    </Link>
                }
                <MenuItem onClick={ exportAsExcelHandler }>Export as excel</MenuItem>
            </ActionsDropDownButton>
        );
    }, [exportAsExcelHandler, selectionModel]);

    const defaultHiddenCols: GridColumnVisibilityModel = {
        'id': false,
        'meetingNotes': false
    };

    return (
        <>
            <PreviewLoaderComponent
                open={isPreviewOpen}
                entityType={previewType}
                recordId={previewRecordId}
                isTagsPreview={isPreviewTags}
                titleOverride={previewRecordName}
            />
            <ConfirmationDialog
                title="Confirm Action"
                message="Are you sure you want to cancel this meeting?"
                onClose={ () => setCancelMeetingId(null) }
                onContinue={ cancelMeetingCallback }
                open={ cancelMeetingId !== null }
                cancelActionText="No"
                confirmActionText="Yes"
            />
            {Boolean(gridActions) &&
                <Box pb="10px" ml="auto">
                    {gridActions}
                </Box>
            }
            <GridWithStateWrapper
                defaultViewModel={defaultHiddenCols}
                gridName={gridName}
                rows={rows}
                columns={columns}
                apiRef={apiRef}
                density="compact"
                disableRowSelectionOnClick
                rowSelectionModel={selectionModel}
                onRowSelectionModelChange={ sm  => setSelectionModel(sm) }
                pagination={true}
                pageSizeOptions={[100,250,500,1000]}
                getRowHeight={() => 'auto'}
            />
        </>
    );
}