import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { CandidateWorkHistory } from "common/models/Candidates/Candidate";
import moment from "moment";
import React, { useMemo } from "react";

interface Props {
    data: CandidateWorkHistory[]
}

const whDateRenderer = (date: string | null, placeholder: string = 'Unknown') => {
    if(date) {
        const m = moment(date);
        if (m.isValid()) return m.format('MMM YYYY');
    }
    return placeholder;
}

const whSectionRenderer = (wh: CandidateWorkHistory, whCount: number) => {
    const org = wh.organizationName ? ' | ' + wh.organizationName : '';
    return (
        <Box height={whCount > 1 ? '50%' : '100%'} display="flex" flexDirection="column">
            <div style={{ paddingTop: '5px' }}>
                <Divider><Chip size="small" label={wh.title + org}/></Divider>
            </div>
            <Typography variant="subtitle2" sx={{ color: 'text.disabled' }}>
                { whDateRenderer(wh.startDate) + " - " + whDateRenderer(wh.endDate, "Present") + ' | ' + wh.jobTypeName }
            </Typography>
            <Box overflow="auto" p="10px" flexGrow={1}>
                <Typography
                    variant="body2"
                    component="div"
                    dangerouslySetInnerHTML={{ __html: wh.description }}
                />
            </Box>
        </Box>
    );
}

export default function CandidateWorkHistorySummaryElement({ data }: Props) {
    const wh1 = useMemo(() => {
        if (data[0]) return whSectionRenderer(data[0], data.length);
        return  <></>;
    }, [data]);
    
    const wh2 = useMemo(() => {
        if (data[1]) return whSectionRenderer(data[1], data.length);
        return  <></>;
    }, [data]);

    return (
        <>
            {wh1}
            {wh2}
        </>
    );
}