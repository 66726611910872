import Alert from "components/Alert";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Location, TimeZone } from "common/models/Common";
import { UserSettingsConfigOptions } from "common/models/Configuration/User";
import { SettingObject } from "common/models/GenericTypes";
import useObjectStateWithChangeTracker from "hooks/UseObjectStateWithChangeTracker";
import useUnsavedChangesDialog from "hooks/UseUnsavedChangesDialog";
import PageContentLayout from "layouts/PageContentLayout";
import { GetLocationById, GetTimeZone } from "services/CommonService";
import { GetMySettings, GetUserSettings, UpdateMySettings, UpdateUserSettings } from "services/UsersService";
import { GetIntegration } from "services/CustomerIntegrationsService";
import { DefaultUserSettings, NoChangesUserSettings } from "util/Definitions/Configuration/Users/Edit";
import { ObjToSettingsArray } from "util/SettingsUtil";
import CountryPicker from "../Pickers/CountryPicker";
import CurrencyPicker from "../Pickers/CurrencyPicker";
import DivisionPicker from "../Pickers/DivisionPicker";
import LocationPicker from "../Pickers/LocationPicker";
import ScreeningTemplatePicker from "../Pickers/ScreeningTemplatePicker";
import ReferenceCheckTemplatePicker from "../Pickers/ReferenceCheckTemplatePicker";
import TitleAndActionSummaryBar from "../SummaryBars/TitleAndActionSummaryBar";
import { IsValidNumericValue } from "util/RegExUtils";
import TimeZonePicker from "../Pickers/TimeZonePicker";
import MessageTemplatePicker from "components/Pickers/MessageTemplatePicker";
import AdvertScreeningQuestionnairePicker from "components/Pickers/AdvertScreeningQuestionnairePicker";
import InfoButtonToolTip from "../InfoButtonToolTip";
import Box from "@mui/material/Box";
import RWTextFieldComponent from "components/RWTextFieldComponent";

interface Props {
    userId?: number,
    summaryBarTitle: string | JSX.Element,
    summaryBarTabTitle: string,
    setSummaryBar?: (summaryBar: JSX.Element) => void
    refreshCurrentUserHandler?: () => void
}

export default function UserSettingsPageContent({ userId = 0, summaryBarTitle, summaryBarTabTitle, setSummaryBar, refreshCurrentUserHandler }: Props) {
    const [isLoading, setIsLoading] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isVoipVisible, setIsVoipVisible] = useState(false);
    const { state, init, change, updateInitial, hasChanges } = useObjectStateWithChangeTracker<UserSettingsConfigOptions>(DefaultUserSettings, NoChangesUserSettings);
    const [selectedLocation, setSelectedLocation] = useState<Location | null>(null);
    const [selectedTimeZone, setSelectedTimeZone] = useState<TimeZone | null>(null);

    const isRadiusValidNumber = useMemo(() => IsValidNumericValue(state.Search_Radius), [state.Search_Radius]);
    const isRadiusError = useMemo(() => !isRadiusValidNumber && state.Search_Radius !== '', [isRadiusValidNumber, state.Search_Radius]);

    const saveHandler = useCallback(async () => {
        setIsLoading(true);
        const settings = ObjToSettingsArray(state);
        let res: boolean | null = null;


        if (userId === 0) {
            res = await UpdateMySettings(settings, setErrorMessage);
            if (res) refreshCurrentUserHandler && refreshCurrentUserHandler();
        }
        else {
            res = await UpdateUserSettings(userId, settings, setErrorMessage);
        }

        if (!res) {
            setIsLoading(false);
            return false;
        }

        updateInitial();
        setShowSuccess(true);
        setIsLoading(false);
        return true;
    }, [state, updateInitial, userId, refreshCurrentUserHandler]);

    useEffect(() => {
        const action = <Button variant="contained" color="success" onClick={ saveHandler } disabled={!hasChanges || isRadiusError}>Save</Button>;
        const summaryBar = <TitleAndActionSummaryBar title={ summaryBarTitle } browserTabTitle={ summaryBarTabTitle } action={action} />;
        setSummaryBar && setSummaryBar(summaryBar);
    }, [setSummaryBar, summaryBarTitle, summaryBarTabTitle, saveHandler, hasChanges, isRadiusError]);

    const { unsavedChangesDialog } = useUnsavedChangesDialog(hasChanges, saveHandler);

    useEffect(() => {
        const getData = async () => {
            const data = await GetIntegration(17);
            if (data && data.statusID === 1) {
                setIsVoipVisible(true);
            }
        };
        getData();
    }, []);

    useEffect(() => {
        const getData = async () => {
            setIsLoading(true);
            let data: SettingObject[] | null = [];
            if (userId === 0) {
                data = await GetMySettings(setErrorMessage);
            }
            else {
                data = await GetUserSettings(userId, setErrorMessage);
            }

            if (data) {
                let savedSettings: UserSettingsConfigOptions = { ...DefaultUserSettings };
                for (let i = 0; i < data.length; i++) {
                    const setting = data[i];
                    (savedSettings as any)[setting.type] = setting.value;
                }

                if (savedSettings.Search_LocationHiddenId) {
                    const location = await GetLocationById(+savedSettings.Search_LocationHiddenId);
                    if (location) setSelectedLocation(location);
                }

                const tz = await GetTimeZone(savedSettings.UserTimezone);
                if (tz) setSelectedTimeZone(tz);

                init(savedSettings);
            }
            setIsLoading(false);
        };
        getData();
    }, [userId, init]);

    const locationChangeCallback = useCallback((l: Location | null) => {
        if (l) {
            change('Search_Latitude', l.latitude.toString());
            change('Search_Longitude', l.longitude.toString());
            change('Search_LocationHiddenId', l.geoNameID.toString());
            change('Search_Location', l.name);
        }
        else {
            change('Search_Latitude', '');
            change('Search_Longitude', '');
            change('Search_LocationHiddenId', '');
            change('Search_Location', '');
        }
        setSelectedLocation(l);
    }, [change]);

    const radiusChangeHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;

        if (val === '') {
            change('Search_Radius', val);
            return;
        }

        const isValid = IsValidNumericValue(val);
        if (isValid) {
            const numberVal = +val;
            if (numberVal > 0) change('Search_Radius', val);
        }
        else if (val.endsWith('.')) {
            const pointsPresent = (val.match(/\./g) || []).length;
            if (pointsPresent === 1) change('Search_Radius', val);
        }
    }, [change]);

    const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        change(name as keyof UserSettingsConfigOptions, value);
    };

    const timeZoneChangeCallback = useCallback((tz: TimeZone | null) => {
        setSelectedTimeZone(tz);
        change('UserTimezone', tz ? tz.zoneName : '');
    }, [change]);

    return (
        <>
            {unsavedChangesDialog}
            <Snackbar open={showSuccess} autoHideDuration={3000} onClose={() => setShowSuccess(false)}>
                <Alert onClose={() => setShowSuccess(false)}>Changes Saved</Alert>
            </Snackbar>
            <Snackbar open={errorMessage !== ''} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <PageContentLayout title="Settings" showLoading={isLoading}>
                <Stack spacing={3}>
                    {isVoipVisible &&
                        <RWTextFieldComponent
                            label="VOIP Identifier"
                            value={state.VoipIdentifier}
                            name="VoipIdentifier"
                            onChange={changeHandler}
                        />
                    }
                    <LocationPicker
                        location={selectedLocation}
                        fullWidth
                        onLocationChange={locationChangeCallback}
                        delayMs={0}
                    />
                    <RWTextFieldComponent
                        label="Radius (km)"
                        value={state.Search_Radius}
                        name="Search_Radius"
                        onChange={radiusChangeHandler}
                        isError={isRadiusError}
                    />
                    <TextField
                        select
                        label="Theme"
                        value={state.Theme}
                        name="Theme"
                        onChange={changeHandler}
                    >
                        <MenuItem value="light">Light</MenuItem>
                        <MenuItem value="dark">Dark</MenuItem>
                    </TextField>
                    <TextField
                        select
                        label="Alternate row Styling on Grids"
                        value={state.AlternateRowStylingOnGrids}
                        name="AlternateRowStylingOnGrids"
                        onChange={changeHandler}
                    >
                        <MenuItem value="True">Yes</MenuItem>
                        <MenuItem value="False">No</MenuItem>
                    </TextField>
                    <DivisionPicker
                        divisionId={state.Division === "0" ? null : +state.Division}
                        onSelectCallback={ id => change('Division', id ? id.toString() : '') }
                    />
                    <DivisionPicker
                        divisionId={state.SearchDivision === "0" ? null : +state.SearchDivision}
                        onSelectCallback={ id => change('SearchDivision', id ? id.toString() : '') }
                        label="Search Division"
                    />
                    <CountryPicker
                        code={state.DefaultCountry}
                        onSelectCallback={ c => change('DefaultCountry', c ? c : '') }
                        label="Default Country"
                    />
                    <TimeZonePicker value={selectedTimeZone} onSelectCallback={ timeZoneChangeCallback } />
                    <CurrencyPicker
                        currencyId={state.Currency ? +state.Currency : null}
                        onSelectCallback={ id => change('Currency', id ? id.toString() : '') }
                    />
                    <Box display="flex">
                        <TextField
                            select
                            label="Job Application Notifications"
                            value={state.JobApplicationNotificationMode}
                            name="JobApplicationNotificationMode"
                            onChange={changeHandler}
                            sx={{ flex: '1 0 0' }}
                        >
                            <MenuItem value="-1">None</MenuItem>
                            <MenuItem value="0">Primary Consultant (Default)</MenuItem>
                            <MenuItem value="1">Any Consultant</MenuItem>
                        </TextField>
                        <InfoButtonToolTip text={"Job Application Notifications are an Email sent to you every time a candidate applies to a Job. You can choose to never receive them (None), only get them when you're listed as Consultant 1 on the Job (Primary Consultant) or always receive them (Any Consultant)." } />
                    </Box>

                    <ScreeningTemplatePicker
                        userId={userId}
                        templateId={state.DefaultScreeningQuestionID === "0" ? null : +state.DefaultScreeningQuestionID}
                        label="Default Screening Template"
                        onSelectCallback={ t => change('DefaultScreeningQuestionID', t ? t.id.toString() : '')}
                    />
                    <ReferenceCheckTemplatePicker
                        userId={userId}
                        templateId={state.DefaultReferenceCheckTemplateID === "0" ? null : +state.DefaultReferenceCheckTemplateID}
                        label="Default Reference Check Template"
                        onSelectCallback={t => change('DefaultReferenceCheckTemplateID', t ? t.id.toString() : '')}
                    />
                    <MessageTemplatePicker
                        templateId={state.DefaultRejectionEmailTemplateID === "0" ? null : +state.DefaultRejectionEmailTemplateID}
                        label="Default Rejection Email Template"                        
                        onSelectHandler={ t => change('DefaultRejectionEmailTemplateID', t ? t.id.toString() : '')}
                        templateSource={userId === 0 ? "Me" : "All"}
                        entityTypeFilter={3}
                        typeFilter={3}
                        includeCompanyOwned
                    />
                    <AdvertScreeningQuestionnairePicker
                        label="Default Advert Screening Questionnaire"
                        onSelectCallback={ t => change('DefaultAdvertScreeningQuestionnaireID', t ? t.id.toString() : '') }
                        templateId={state.DefaultAdvertScreeningQuestionnaireID === "0" ? null : +state.DefaultAdvertScreeningQuestionnaireID}
                    />
                    <TextField
                        select
                        label="Send Calendar Invites to me"
                        value={state.Calendar_User_ByDefault}
                        name="Calendar_User_ByDefault"
                        onChange={changeHandler}
                    >
                        <MenuItem value="True">Enabled</MenuItem>
                        <MenuItem value="False">Disabled</MenuItem>
                    </TextField>
                    <TextField
                        select
                        label="Default Distribution Method - Submissions"
                        value={state.DefaultDistributionMethod_Submissions}
                        name="DefaultDistributionMethod_Submissions"
                        onChange={changeHandler}
                    >
                        <MenuItem value="3">Email To Client</MenuItem>
                        <MenuItem value="2">Email To Consultant</MenuItem>
                        <MenuItem value="1">Online Portal</MenuItem>
                        <MenuItem value="0">None - Record In Recruit Wizard Only</MenuItem>
                    </TextField>
                    <TextField
                        select
                        label="Default Distribution Method - Meetings"
                        value={state.DefaultDistributionMethod_Meetings}
                        name="DefaultDistributionMethod_Meetings"
                        onChange={changeHandler}
                    >
                        <MenuItem value="CalendarInviteOnly">Calendar Invite Only</MenuItem>
                        <MenuItem value="CalendarInvitePlusEmail">Calendar Invite + Email</MenuItem>
                        <MenuItem value="NoneRecordOnly">None - Record In Recruit Wizard Only</MenuItem>
                    </TextField>
                    <TextField
                        select
                        label="Default Duration - Meetings"
                        value={state.DefaultDuration_Meetings}
                        name="DefaultDuration_Meetings"
                        onChange={changeHandler}
                    >
                        <MenuItem value="0">None</MenuItem>
                        <MenuItem value="15">15 mins</MenuItem>
                        <MenuItem value="30">30 mins</MenuItem>
                        <MenuItem value="60">1 hour</MenuItem>
                        <MenuItem value="90">90 mins</MenuItem>
                        <MenuItem value="120">2 hours</MenuItem>
                        <MenuItem value="240">4 hours</MenuItem>
                        <MenuItem value="480">1 Day</MenuItem>
                    </TextField>
                    <TextField
                        select
                        label="Default Distribution Method - Interviews > Clients"
                        value={state.DefaultDistributionMethod_InteriewsClients}
                        name="DefaultDistributionMethod_InteriewsClients"
                        onChange={changeHandler}
                    >
                        <MenuItem value="CalendarInviteOnly">Calendar Invite Only</MenuItem>
                        <MenuItem value="CalendarInvitePlusEmail">Calendar Invite + Email</MenuItem>
                        <MenuItem value="NoneRecordOnly">None - Record In Recruit Wizard Only</MenuItem>
                    </TextField>
                    <TextField
                        select
                        label="Default Distribution Method - Interviews > Candidates"
                        value={state.DefaultDistributionMethod_InteriewsCandidates}
                        name="DefaultDistributionMethod_InteriewsCandidates"
                        onChange={changeHandler}
                    >
                        <MenuItem value="CalendarInviteOnly">Calendar Invite Only</MenuItem>
                        <MenuItem value="CalendarInvitePlusEmail">Calendar Invite + Email</MenuItem>
                        <MenuItem value="NoneRecordOnly">None - Record In Recruit Wizard Only</MenuItem>
                    </TextField>
                    <TextField
                        select
                        label="Default Duration - Interviews"
                        value={state.DefaultDuration_Interviews}
                        name="DefaultDuration_Interviews"
                        onChange={changeHandler}
                    >
                        <MenuItem value="0">None</MenuItem>
                        <MenuItem value="15">15 mins</MenuItem>
                        <MenuItem value="30">30 mins</MenuItem>
                        <MenuItem value="60">1 hour</MenuItem>
                        <MenuItem value="90">90 mins</MenuItem>
                        <MenuItem value="120">2 hours</MenuItem>
                        <MenuItem value="240">4 hours</MenuItem>
                        <MenuItem value="480">1 Day</MenuItem>
                    </TextField>
                    <TextField
                        select
                        label="Default Meeting Location Type"
                        value={state.DefaultMeetingLocationTypeId ?? '2'}
                        name="DefaultMeetingLocationTypeId"
                        onChange={changeHandler}
                    >
                        <MenuItem value="1">In Person</MenuItem>
                        <MenuItem value="2">Phone Call</MenuItem>
                        <MenuItem value="3">Microsoft Teams</MenuItem>
                        {/*<MenuItem value="4">Zoom</MenuItem>*/}
                        <MenuItem value="5">Other</MenuItem>
                    </TextField>
                    <TextField
                        select
                        label="Default Interview Location Type"
                        value={state.DefaultInterviewLocationTypeId ?? '2'}
                        name="DefaultInterviewLocationTypeId"
                        onChange={changeHandler}
                    >
                        <MenuItem value="1">In Person</MenuItem>
                        <MenuItem value="2">Phone Call</MenuItem>
                        <MenuItem value="3">Microsoft Teams</MenuItem>
                        {/*<MenuItem value="4">Zoom</MenuItem>*/}
                        <MenuItem value="5">Other</MenuItem>
                    </TextField>
                    <RWTextFieldComponent
                        label="Default Interview Location"
                        value={state.DefaultInterviewLocation}
                        name="DefaultInterviewLocation"
                        onChange={changeHandler}
                    />
                    <TextField
                        select
                        label="Open edit dialog on follow up activities created"
                        value={state.OpenEditDialogOnFollowUpActivitiesCreated}
                        name="OpenEditDialogOnFollowUpActivitiesCreated"
                        onChange={changeHandler}
                    >
                        <MenuItem value="True">Yes</MenuItem>
                        <MenuItem value="False">No</MenuItem>
                    </TextField>
                </Stack>
            </PageContentLayout>
        </>
    );
}
