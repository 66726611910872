import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { ActivitySettings } from "common/models/Configuration/Activities";
import CandidatePicker from "components/Pickers/CandidatePicker";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { GetActivitiesSettings } from "services/ConfigurationService";

interface Props {
    open: boolean,
    interviewId: number,
    closeHandler: () => void,
    loadingHandler?: (isLoading: boolean) => void,
    successHandler?: (message: string) => void,
    errorHandler?: (message: string) => void
}

export default function AddCandidateToInterviewSlotDialog({ open, interviewId, closeHandler, loadingHandler, successHandler, errorHandler }: Props) {
    const [interviewActivities, setInterviewActivities] = useState<ActivitySettings[]>([]);
    const [candidateId, setCandidateId] = useState(0);
    const [typeId, setTypeId] = useState(33);
    
    useEffect(() => {
        const getData = async () => {
            loadingHandler && loadingHandler(true);
            const activitySettings = await GetActivitiesSettings(undefined, 5);
          
            if (activitySettings) {
                const filteredSettings = activitySettings?.filter(a => a.id !== 31)

                setInterviewActivities(filteredSettings);
            }
            
            loadingHandler && loadingHandler(false);
        };
        getData();
    }, [loadingHandler]);

    return (
        <Dialog open={open} fullWidth>
            <DialogTitle>Add Candidate</DialogTitle>
            <DialogContent>
                <Stack spacing={2} mt="10px">
                    <TextField select label="Interview Type" value={typeId.toString()} onChange={({target}) => setTypeId(+target.value)} >
                        {interviewActivities.map(a => <MenuItem key={a.id} value={a.id.toString()}>{a.customName}</MenuItem>)}
                    </TextField>
                    <CandidatePicker value={candidateId} onSelectCallback={c => setCandidateId(c ? c.id : 0)} />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="error" onClick={ closeHandler }>Cancel</Button>
                {(typeId === 33 || candidateId === 0) && <Button variant="contained" color="success" disabled>Submit</Button> }
                {!(typeId === 33 || candidateId === 0) && 
                    <Link to={`/interviews/${interviewId}/edit?type=${typeId}&candidateId=${candidateId}`} style={{ marginLeft: '8px' }}>
                        <Button variant="contained" color="success">Submit</Button>
                    </Link>
                }
            </DialogActions>
        </Dialog>
    );
}