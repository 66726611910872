import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { GridColDef, GridRenderCellParams, useGridApiRef, GridRowSelectionModel } from "@mui/x-data-grid-premium";
import Alert from "components/Alert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MenuItem from "@mui/material/MenuItem";
import Snackbar from "@mui/material/Snackbar";
import TextField from "@mui/material/TextField";

import { ScreeningQuestionnaireTemplate } from "common/models/Configuration/AdvertScreeningQuestionnaire";
import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import PageContentLayout from "layouts/PageContentLayout";
import { CopyScreeningQuestionnaireTemplate, GetScreeningQuestionnaireTemplates } from "services/AdvertsService";
import GridWithStateWrapper from "components/GridWidthStateWrapper";
import ActionsDropDownButton from "components/SummaryBars/Actions/ActionsDropsDownMenu";

interface Props {
    setSummaryBar?: (summaryBar: JSX.Element) => void
}

const createTemplateLink = <Link to="/configuration/adverts/screening-questionnaires/0" style={{ color: 'inherit', textDecoration: 'none', padding: '6px 16px', flexGrow: 1 }}>Add Questionnaire</Link>;

export default function AdvertsScreeningQuestionnaires({ setSummaryBar }: Props) {
    const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [rows, setRows] = useState<ScreeningQuestionnaireTemplate[]>([]);
    const [copyTemplateName, setCopyTemplateName] = useState('');
    const apiRef = useGridApiRef();

    useEffect(() => {
        const handleCopyQuestionnaire = () => {
            const selectedId = +selectionModel[0];
            const row = rows.find(r => r.id === selectedId);
            if(row) setCopyTemplateName(row.name);
        };

        const actionsMenu = (
            <ActionsDropDownButton>
                <MenuItem sx={{ p: 0 }}>{createTemplateLink}</MenuItem>
                <MenuItem onClick={handleCopyQuestionnaire} disabled={ selectionModel.length !== 1 } >Copy Questionnaire</MenuItem>
            </ActionsDropDownButton>
        );
        const SummaryBar = (
            <TitleAndActionSummaryBar
                title="Configuration > Adverts > Screening Questionnaires"
                browserTabTitle="Adverts > Configuration"
                action={actionsMenu}
            />
        );
        setSummaryBar && setSummaryBar(SummaryBar);
    }, [setSummaryBar, selectionModel, rows]);

    const getData = useCallback(async () => {
        setIsLoading(true);
        let data = await GetScreeningQuestionnaireTemplates(setErrorMessage);
        if(data) {
            data.sort((a, b) => {
                return a.statusID - b.statusID || a.name.localeCompare(b.name);
            });
            setRows(data);
        }

        setIsLoading(false);
    }, [])

    useEffect(() => {
        getData();
    }, [getData]);

    function getAutomatedRejectionDelay(quotaValue: number) {
        if (quotaValue === 60) {
            return "1 Hour";
        }
        else if (quotaValue === 120) {
            return "2 Hours";
        }
        else if (quotaValue === 240) {
            return "4 Hours";
        }
        else if (quotaValue === 480) {
            return "8 Hours";
        }
        else if (quotaValue === 720) {
            return "12 Hours";
        }
        else if (quotaValue === 1440) {
            return "24 Hours";
        }
        else if (quotaValue === 2880) {
            return "2 Days";
        }
        else if (quotaValue === 4320) {
            return "3 Days";
        }
        else if (quotaValue === 10080) {
            return "1 Week";
        }
        return '';
    }

    const columns = useMemo<GridColDef[]>(() => {
        const dbNameRenderer = (params: GridRenderCellParams) => {
            return (
                <Link
                    to={ '/configuration/adverts/screening-questionnaires/' + params.id }
                    style={{ color: "inherit", textDecoration: 'underline' }}
                >
                    {params.value}
                </Link>
            );
        };

        return [
            { field: 'id', headerName: 'ID', renderCell: dbNameRenderer },
            { field: 'name', headerName: 'Name', width: 300, renderCell: dbNameRenderer },
            { field: 'automatedRejectionDelay', headerName: 'Automated Rejection Delay', width: 200, valueGetter: value => { return getAutomatedRejectionDelay(value) } },
            { field: 'status', headerName: 'Status', width: 200 }
        ];
    }, []);

    const selectionChangeHandler = (newSelectionModel: GridRowSelectionModel) => {
        setSelectionModel(prev => {
            const prevLength = prev.length;
            const newLength = newSelectionModel.length;

            if(prevLength === 0 && newLength > 1)
                return [newSelectionModel[0]];

            if(prevLength === 1 && newLength > 0)
                return [newSelectionModel[newLength - 1]]
            return newSelectionModel;
        });
    };

    const handleCopyQuestionnaireConfirmation = async () => {
        setIsLoading(true);
        const templateId = +selectionModel[0];
        const res = await CopyScreeningQuestionnaireTemplate(templateId, copyTemplateName, setErrorMessage);
        if(res) {
            setCopyTemplateName('');
            setSelectionModel([]);
            setShowSuccess(true);
            await getData();
        }
        setIsLoading(false);
    };

    return (
        <>
            <Snackbar open={showSuccess} autoHideDuration={3000} onClose={() => setShowSuccess(false)}>
                <Alert onClose={() => setShowSuccess(false)}>Changes Saved</Alert>
            </Snackbar>
            <Snackbar open={errorMessage !== ''} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <Dialog open={copyTemplateName !== ''} onClose={() => {}} fullWidth>
                <DialogTitle>Confirm</DialogTitle>
                <DialogContent dividers>
                    <TextField
                        label="New template name"
                        autoFocus
                        fullWidth
                        value={copyTemplateName}
                        onChange={({target}) => setCopyTemplateName(target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="error" onClick={() => setCopyTemplateName('')}>Cancel</Button>
                    <Button variant="contained" color="success" onClick={ handleCopyQuestionnaireConfirmation }>OK</Button>
                </DialogActions>
            </Dialog>
            <PageContentLayout title="Screening Questionnaires" showLoading={isLoading}>
                <GridWithStateWrapper
                    gridName="configuration/adverts/screening-questionnaires"
                    apiRef={apiRef}
                    rows={rows}
                    columns={columns}
                    disableRowSelectionOnClick
                    disableMultipleRowSelection
                    checkboxSelection
                    pagination={true}
                    density="compact"
                    rowSelectionModel={selectionModel}
                    onRowSelectionModelChange={ selectionChangeHandler }
                    pageSizeOptions={[100,250,500,1000]}
                />
            </PageContentLayout>
        </>
    );
}