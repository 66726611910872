import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import Alert from "components/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import AddIcon from '@mui/icons-material/Add';

import { AutomationOutput, Automation, AutomationFilter, OrderByObject } from "common/models/Configuration/Automation";
import { AutomationEventType } from "common/models/Automation/AutomationEventType";
import AutomationOutputComponent from "components/Automation/AutomationOutput";
import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import PageContentLayout from "layouts/PageContentLayout";
import { CreateAutomation, GetAutomation, UpdateAutomation, GetAutomationEventTypes, GetAutomationModuleTypes } from "services/AutomationService";
import { companyHasSinglePermission } from "util/PermissionsUtils";
import { Permission as P } from '../../../common/models/Permissions';
import useObjectStateWithChangeTracker from "hooks/UseObjectStateWithChangeTracker";
import { DefaultAutomation, NoChangesTracker } from "util/Definitions/Automation";
import useUnsavedChangesDialog from "hooks/UseUnsavedChangesDialog";
import { TagSettings, TagValue } from "common/models/Configuration/Tags";
import { GetAllTagsSettings, GetCustomerTags, GetTagValues } from "services/TagsService";
import Typography from "@mui/material/Typography";
import AutomationFilterComponent from "components/Automation/AutomationFilter";
import { GetDivisions } from "services/DivisionsService";
import { Division } from "common/models/Configuration/Division";
import { GetActivitiesSettings, GetJobSources, GetPlacementsSources } from "services/ConfigurationService";
import { NameIdObj, NameIdObjString } from "common/models/GenericTypes";
import { GetCountries } from "services/CommonService";
import { ActivitySettings } from "common/models/Configuration/Activities";
import { GetPlaceholdersByEntity, GetPlaceholdersByEntityList } from "services/PlaceholdersService";
import { TemplatePlaceholder } from "common/models/Templates/TemplatePlaceholder";
import InfoButtonToolTip from "../../../components/InfoButtonToolTip";
import { CustomFieldWithPredefinedValues } from "common/models/Configuration/CustomFields";
import { GetCustomFieldsByEntity_OnlyActive, GetMultiplePredefinedValues } from "services/CustomFieldsService";
import AutomationSchedule from "components/Automation/AutomationSchedule";
import { AutomationScheduleData, NewScheduleData } from "components/Automation/AutomationScheduleData";
import moment from "moment";
import { FormControl, FormControlLabel, FormLabel, Menu, MenuProps, Radio, RadioGroup, alpha, styled } from "@mui/material";
import AutomationPreviewDataDialog from "components/Automation/AutomationPreviewDataDialog";
import RWTextFieldComponent from "components/RWTextFieldComponent";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { AutomationPreviewData, DefaultPreviewData } from "common/models/Configuration/AutomationPreviewData";
import ActionsSplitButton from "components/SummaryBars/Actions/ActionsSplitButton";
interface Props {
    setSummaryBar?: (summaryBar: JSX.Element) => void
}

type TagsByEntityId = Record<number, TagValue[]>;
type PlaceholdersByEntityId = Record<number, TemplatePlaceholder[]>;
type CustomFieldsByEntityId = Record<number, CustomFieldWithPredefinedValues[]>;

const filterableEntities = [1, 2, 3, 4, 5, 8];

const timeBasedCandidateOrderByColumns: string[] = [
    'Update Date',
    'CV Updated Date',
    'Last Contacted Date',
  ];

  const timeBasedPlacementOrderByColumns: string[] = [
    'Start Date',
    'End Date'
  ];

  const StyledMenu = styled((props: MenuProps) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color:
        theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  }));
  

export default function AutomationItem({ setSummaryBar }: Props) {
    const [isLoading, setIsLoading] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const { state, init, change, updateInitial, hasChanges } = useObjectStateWithChangeTracker<Automation>(DefaultAutomation, NoChangesTracker);
    const [outputs, setOutputs] = useState<AutomationOutput[]>([]);
    const [filters, setFilters] = useState<AutomationFilter[]>([]);
    const [eventTypes, setEventTypes] = useState<AutomationEventType[]>([]);
    const [moduleTypes, setModuleTypes] = useState<NameIdObj[]>([]);
    const [tagSettings, setTagSettings] = useState<TagSettings[]>([]);
    const [activitySettings, setActivitySettings] = useState<ActivitySettings[]>([]);
    const [isFetchingAutomationSettings, setIsFetchingAutomationSettings] = useState(false);
    const [isFetchingTagSettings, setIsFetchingTagSettings] = useState(false);
    const [isFetchingExistingItem, setIsFetchingExistingItem] = useState(false);
    const [cachedTags, setCachedTags] = useState<TagsByEntityId>({});
    const [cachedPlaceholders, setCachedPlaceholders] = useState<PlaceholdersByEntityId>({});
    const [cachedCustomFields, setCachedCustomFields] = useState<CustomFieldsByEntityId>({});
    const [cachedDivisions, setCachedDivisions] = useState<Division[]>([]);
    const [cachedJobSources, setCachedJobSources] = useState<NameIdObjString[]>([]);
    const [cachedPlacementSources, setCachedPlacementSources] = useState<NameIdObjString[]>([]);
    const [cachedCountries, setCachedCountries] = useState<NameIdObjString[]>([]);
    const [isFetchingCustomFieldData, setIsFetchingCustomFieldData] = useState(false);
    const [scheduleData, setScheduleData] = useState<AutomationScheduleData>(NewScheduleData);
    const [orderByObject, setOrderByObject] = useState<OrderByObject>({columnName:'Update Date',isAscending: true});
    const [isGridLoading, setIsGridLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [openPreviewData, setOpenPreviewData] = useState(false);
    const [previewData, setPreviewData] = useState<AutomationPreviewData>(DefaultPreviewData);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const params = useParams();

    const itemId = useMemo(() => {
        const parsedId = +(params.id ?? '0');
        if(isNaN(parsedId)) return 0;
        return parsedId
    }, [params.id]);

    const linkedEntityId = useMemo(() => {
        if(state.triggerID === 1) {
            const eventType = eventTypes.find(e => e.webhookEventID === state.eventID);
            if (eventType) return eventType.entityType;
        }
        if(state.triggerID === 2 && state.moduleId != null) {
            const moduleType = moduleTypes.find(e => e.id === state.moduleId);
            if (moduleType) return moduleType.id;
        }

        return 0;
    }, [eventTypes,moduleTypes, state.triggerID, state.eventID, state.moduleId]);

    const getData = useCallback(async () => {
        setIsFetchingAutomationSettings(true);
        let eventTypes = await GetAutomationEventTypes(setErrorMessage);
        if (eventTypes) {
            setEventTypes(eventTypes);
        }

        let moduleTypes = await GetAutomationModuleTypes(setErrorMessage);
        if (moduleTypes) {
            setModuleTypes(moduleTypes);
        }

        setIsFetchingAutomationSettings(false);
    }, []);


    useEffect(() => {
        getData();
    }, [getData]);

    useEffect(() => {
        const getDivisionsData = async () => {
            const res = await GetDivisions(setErrorMessage);
            if (res) setCachedDivisions(res);
        };
        getDivisionsData();
    }, []);

    useEffect(() => {
        const getJobSourceData = async () => {
            const res = await GetJobSources(setErrorMessage);
            if (res) {
                const obj = res.map<NameIdObjString>(s => ({ id: s, name: s }));
                setCachedJobSources(obj);
            }
        };
        getJobSourceData();
    }, []);

    useEffect(() => {
        const getPlacementSourceData = async () => {
            const res = await GetPlacementsSources(setErrorMessage);
            if (res) {
                const obj = res.map<NameIdObjString>(s => ({ id: s.name, name: s.name }));
                setCachedPlacementSources(obj);
            }
        };
        getPlacementSourceData();
    }, []);

    useEffect(() => {
        const getCountriesData = async () => {
            const res = await GetCountries(setErrorMessage);
            if (res) {
                const obj = res.map<NameIdObjString>(c => ({ id: c.countryCode, name: c.countryName }));
                setCachedCountries(obj);
            }
        };
        getCountriesData();
    }, []);

    useEffect(() => {
        const getTagSettings = async () => {
            setIsFetchingTagSettings(true);
            const res = await GetAllTagsSettings(setErrorMessage);
            if (res) {
                const active = res.filter(f => f.visible);
                setTagSettings(active);
            }
            setIsFetchingTagSettings(false);
        };
        getTagSettings();
    }, []);

    useEffect(() => {
        const needsFetching = !Boolean(cachedPlaceholders[linkedEntityId]);
        const getPlaceholderData = async () => {
            if (linkedEntityId === 10) {
                const res = await GetPlaceholdersByEntityList([10, 4], setErrorMessage);
                if (res) {
                    setCachedPlaceholders(prev => {
                        let tmp = {...prev};
                        tmp[linkedEntityId] = res;
                        return tmp;
                    });
                }
            }
            else {
                const res = await GetPlaceholdersByEntity(linkedEntityId, setErrorMessage);
                if (res) {
                    setCachedPlaceholders(prev => {
                        let tmp = {...prev};
                        tmp[linkedEntityId] = res;
                        return tmp;
                    });
                }
            }
        };
        needsFetching && linkedEntityId !== 0 && getPlaceholderData();
    }, [cachedPlaceholders, linkedEntityId]);

    useEffect(() => {
        const needsFetching = !Boolean(cachedCustomFields[linkedEntityId]);
        const getCustomFieldsData = async () => {
            setIsFetchingCustomFieldData(true);
            const res = await GetCustomFieldsByEntity_OnlyActive(linkedEntityId, setErrorMessage);
            if (res) {
                const filtered = res.filter(c => c.usePredefinedValues && c.dataType === 'String');
                const fIds = filtered.map(f => f.id);
                const vals = await GetMultiplePredefinedValues(fIds);
                if (vals) {
                    let fields: CustomFieldWithPredefinedValues[] = [];
                    for (let i = 0; i < filtered.length; i++) {
                        const udf = filtered[i];
                        const values = vals.filter(f => f.fieldId === udf.id);
                        fields.push({ ...udf, values: values });                        
                    }
                    setCachedCustomFields(prev => {
                        let tmp = {...prev};
                        tmp[linkedEntityId] = fields;
                        return tmp;
                    });
                }
            }
            setIsFetchingCustomFieldData(false);
        };
        needsFetching && linkedEntityId !== 0 && getCustomFieldsData()
    }, [cachedCustomFields, linkedEntityId]);

    useEffect(() => {
        const getActivitiesData = async () => {
            const res = await GetActivitiesSettings();
            if (res) setActivitySettings(res);
        };
        getActivitiesData();
    }, []);

    const activityValidation  = useCallback((f: AutomationOutput, i: number) => {
        if(state.statusID === 1) {
            if(f.typeId === 1) {
                if(f.emailFrom === -1) {
                    throw Error(`Action #${i+1} email from cannot be empty`)
                }

                if(f.emailSubject === '') {
                    throw Error(`Action #${i+1} email subject cannot be empty`)
                }

                if(f.emailToSource === 0) {
                    throw Error(`Action #${i+1} email recipient type cannot be empty`)
                }

                if(f.emailToSource === 1  && f.emailToNumericValues.length === 0) {
                    throw Error(`At least one Recipient Group is required for Action #${i+1}`)
                }

                if(f.emailToSource === 2  && f.emailToNumericValues.length === 0) {
                    throw Error(`At least one Recipient User is required for Action #${i+1}`)
                }

                if(f.emailToSource === 3  && f.emailEntityRecord === '') {
                    throw Error(`Action #${i+1} entity record cannot be empty`)
                }

                if(f.emailToSource === 4  && f.emailToStringValues.length === 0) {
                    throw Error(`At least one Recipient email is required for Action #${i+1}`)
                }

                if(f.emailBody === '') {
                    throw Error(`Action #${i+1} email body cannot be empty`)
                } 
            }

            if(f.typeId === 2) {
                if(f.smsBody === '') {
                    throw Error(`Action #${i+1} sms body cannot be empty`)
                }

                if(f.smsEntityRecord === '') {
                    throw Error(`Action #${i+1} sms Recipient record cannot be empty`)
                }

                if(f.smsToSource === 0) {
                    throw Error(`Action #${i+1} sms Recipient type cannot be empty`)
                }
            }

            if(f.typeId === 4) {
                if(f.teamsWebhookUrl === '') {
                    throw Error(`Action #${i+1} slack webhook url cannot be empty`)
                }
                
                if(f.messageTitle === '') {
                    throw Error(`Action #${i+1} slack title cannot be empty`)
                }

                if(f.message === '') {
                    throw Error(`Action #${i+1} slack message cannot be empty`)
                }

            }

            if(f.typeId === 5) {
                if(f.teamsWebhookUrl === '') {
                    throw Error(`Action #${i+1} Microsoft Teams webhook url cannot be empty`)
                }
                
                if(f.messageTitle === '') {
                    throw Error(`Action #${i+1} Microsoft Teams title cannot be empty`)
                }

                if(f.message === '') {
                    throw Error(`Action #${i+1} Microsoft Teams message cannot be empty`)
                }

            }

            if(f.typeId === 9) {
                if(f.surveyId === 0) {
                    throw Error(`Action #${i+1} survey cannot be empty`)
                }
                if(f.emailFrom === -1) {
                    throw Error(`Action #${i+1} email from cannot be empty`)
                }

                if(f.emailSubject === '') {
                    throw Error(`Action #${i+1} email subject cannot be empty`)
                }

                if(f.emailToSource === 0) {
                    throw Error(`Action #${i+1} email recipient type cannot be empty`)
                }

                if(f.emailToSource === 1  && f.emailToNumericValues.length === 0) {
                    throw Error(`At least one Recipient Group is required for Action #${i+1}`)
                }

                if(f.emailToSource === 2  && f.emailToNumericValues.length === 0) {
                    throw Error(`At least one Recipient User is required for Action #${i+1}`)
                }

                if(f.emailToSource === 3  && f.emailEntityRecord === '') {
                    throw Error(`Action #${i+1} entity record cannot be empty`)
                }

                if(f.emailToSource === 4  && f.emailToStringValues.length === 0) {
                    throw Error(`At least one Recipient email is required for Action #${i+1}`)
                }

                if(f.emailBody === '') {
                    throw Error(`Action #${i+1} email body cannot be empty`)
                } 
            }

            if(f.typeId === 10) {
                if(f.onboardingFormId === 0) {
                    throw Error(`Action #${i+1} onboarding form cannot be empty`)
                }

                if(f.emailFrom === -1) {
                    throw Error(`Action #${i+1} email from cannot be empty`)
                }

                if(f.emailSubject === '') {
                    throw Error(`Action #${i+1} email subject cannot be empty`)
                }

                if(f.emailToSource === 0) {
                    throw Error(`Action #${i+1} email recipient type cannot be empty`)
                }

                if(f.emailToSource === 1  && f.emailToNumericValues.length === 0) {
                    throw Error(`At least one Recipient Group is required for Action #${i+1}`)
                }

                if(f.emailToSource === 2  && f.emailToNumericValues.length === 0) {
                    throw Error(`At least one Recipient User is required for Action #${i+1}`)
                }

                if(f.emailToSource === 3  && f.emailEntityRecord === '') {
                    throw Error(`Action #${i+1} entity record cannot be empty`)
                }

                if(f.emailToSource === 4  && f.emailToStringValues.length === 0) {
                    throw Error(`At least one Recipient email is required for Action #${i+1}`)
                }

                if(f.emailBody === '') {
                    throw Error(`Action #${i+1} email body cannot be empty`)
                } 
            }

            if(f.typeId === 3 ) {
                if(f.activityType === 0) {
                    throw Error(`Action #${i + 1} activity type field cannot be empty`);
                }
                
                if(f.activityAssignedTo === 0) {
                    throw Error(`Action #${i + 1} activity assigned to field cannot be empty`);
                }

                if(f.activityNotes === '') {
                    throw Error(`Action #${i + 1} activity notes field cannot be empty`);
                }
            }

            if(f.typeId === 6) {
                if(f.webhookHeaderKey === '') {
                    throw Error(`Action #${i + 1} webhook header key is required`);
                }

                if(f.webhookHeaderValue === '') {
                    throw Error(`Action #${i + 1} webhook header value is required`);
                }

                if(f.webhookPayload === '') {
                    throw Error(`Action #${i + 1} webhook payload is required`);
                }
                
                if(f.webhookUrl === '') {
                    throw Error(`Action #${i + 1} webhook url is required`);
                }
            }

            if((f.typeId === 7 || f.typeId === 8) && f.tagName === '') {
                    throw Error(`Please select a tag for Action #${i+1}.`)
            }

        }
    }, [state.statusID]);

    const saveHandler = useCallback(async (runNow: boolean = false) => {
        try {
            setIsLoading(true);

            if(state.name === '') {
                throw Error('The Automation must have a name');
            }

            if (state.statusID === 1 && outputs.length === 0) {
                throw Error('The Automation must have at least one action');
            }

            let realOutputs: AutomationOutput[] = [];
            for (let i = 0; i < outputs.length; i++) {
                const f = outputs[i];
                if (f.description === '') {
                    throw Error(`Action #${i + 1} field is empty`);
                }

                if (f.typeId === 3 && f.dateType === 1) {
                    if (f.dateDays === 0) throw Error(`Action #${i + 1} days field must be highter than 0`);
                    if (!Boolean(f.dateExecution)) throw Error(`Action #${i + 1} execution field cannot be empty`);
                    if (!Boolean(f.entityField)) throw Error(`Action #${i + 1} entity field field cannot be empty`);
                    if (!Boolean(f.dateTime) ) throw Error(`Action #${i + 1} time field cannot be empty`);
                }

                activityValidation(f,i);
                realOutputs.push({ ...f, id: i });
            }

            if(state.triggerID === 2) {
                validateScheduleData(scheduleData);
                
                if(orderByObject.columnName === "") {
                    throw Error('The automation must have a order by column.')
                }
            }

            const realFilters: AutomationFilter[] = filters.map((f, i) => ({ ...f, id: i }))

            const realAutomation: Automation = { ...state, actionsJSON: JSON.stringify(realOutputs), filtersJSON: JSON.stringify(realFilters), scheduleMetaDataJSON: JSON.stringify(scheduleData), orderByJSON: JSON.stringify(orderByObject) };


            if(state.triggerID === 2) {
                if(state.moduleId === null)
                    throw Error('The Automation must have a module.');
                
                if(realFilters.length === 0 && state.statusID === 1)
                    throw Error('The Automation must have at least one filter')
            }

            if (itemId === 0) {
                const res = await CreateAutomation(realAutomation, runNow, setErrorMessage);
                if (!res) throw Error('');

                updateInitial();
                setShowSuccess(true);
            }
            else {
                if (itemId > 0) {
                    const res = await UpdateAutomation(itemId, realAutomation, runNow, setErrorMessage);
                    if (!res) throw Error('');

                    updateInitial();
                    setShowSuccess(true);
                }
            }

            setIsLoading(false);
            return true;
        } catch (error) {
            setIsLoading(false);
            if(error instanceof Error && error.message) {
                setErrorMessage(error.message);
            }
            return false;
        }

    }, [state, outputs, filters, scheduleData, orderByObject, itemId, activityValidation, updateInitial]);

    const { unsavedChangesDialog, hasBlockedRoute } = useUnsavedChangesDialog(hasChanges, saveHandler);

    useEffect(() => {
        const catchedSaveHandler = async (runNow?: boolean) => {
            try { await saveHandler(runNow); } catch (error) { }
        }
        let saveButton: JSX.Element = <></>;
        if (state.triggerID === 1) saveButton = <Button variant="contained" color="success" disabled={!hasChanges} onClick={() => catchedSaveHandler()} >Save</Button>;
        else if (state.triggerID === 2) {
            saveButton = (
                <ActionsSplitButton mainAction={() => catchedSaveHandler()} mainActionDisabled={!hasChanges} secondaryActionDisabled={!hasChanges} label="Save" color="success">
                    <MenuItem onClick={() => catchedSaveHandler(true)} disabled={!hasChanges}>Save and Run Now</MenuItem>
                </ActionsSplitButton>
            );
        }
        const summaryBar = (
            <TitleAndActionSummaryBar
                title="Configuration > Automation"
                browserTabTitle="Automation > Configuration"
                action={saveButton}
            />
        );
        setSummaryBar && setSummaryBar(summaryBar);
    }, [saveHandler, setSummaryBar, hasChanges, state.triggerID]);

    useEffect(() => {
        const getData = async () => {
            setIsFetchingExistingItem(true);
            const data = await GetAutomation(itemId);

            if (data) {
                const outputs = JSON.parse(data.actionsJSON) as AutomationOutput[];
                if (data && data.filtersJSON) {
                    let parsedFilters = JSON.parse(data.filtersJSON) as AutomationFilter[];
                    for (let i = 0; i < parsedFilters.length; i++) {
                        const f = parsedFilters[i];
                        const hasTypeValue = typeof f.type === 'number' && f.type !== null && f.type !== undefined;
                        if (!hasTypeValue) {
                            const include = (f as any)['include'];
                            if (include === true) f.type = 1;
                            else if (include === false) f.type = 0;
                        }
                    }
                    setFilters(parsedFilters);
                }

                if(data && data.scheduleMetaDataJSON) {
                    let scheduleData = JSON.parse(data.scheduleMetaDataJSON) as AutomationScheduleData;
                    scheduleData.startTime = moment(scheduleData.startTime);
                    scheduleData.runUntil = moment(scheduleData.runUntil);
                    scheduleData.runAfter = moment(scheduleData.runAfter);
                    scheduleData.isNew = false;
                    setScheduleData(scheduleData);
                }

                if(data && data.orderByJSON) {
                    const orderBydata = JSON.parse(data.orderByJSON) as OrderByObject;
                    setOrderByObject(orderBydata);
                }


                init(data);
                setOutputs(outputs);
            }
            setIsFetchingExistingItem(false);
        };
        itemId !== 0 && getData();
    }, [itemId, init]);

    useEffect(() => {
        change('actionsJSON', JSON.stringify(outputs));
    }, [outputs, change]);

    useEffect(() => {
        change('filtersJSON', JSON.stringify(filters));
    }, [filters, change]);

    useEffect(() => {
        setIsLoading(isFetchingAutomationSettings || isFetchingExistingItem || isFetchingTagSettings || isFetchingCustomFieldData);
    }, [isFetchingAutomationSettings, isFetchingCustomFieldData, isFetchingExistingItem, isFetchingTagSettings]);

    // const stringUpdateHandler = useCallback((name:string, value: string) => {

    //     change(name as keyof Automation, value);

    //     if(name === 'triggerID') {

    //         if(value === '1') {
    //             change('moduleId' as keyof Automation, "");
    //         }
    
    //         if(value === '2'){
    //             change('eventID' as keyof Automation, "");
    //             change('eventName' as keyof Automation, "");
    //         }
    //     }
    // }, [change])

    const stringChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        change(name as keyof Automation, value);

        if(name === 'triggerID') {

            if(value === '1') {
                change('moduleId' as keyof Automation, "");
            }
    
            if(value === '2'){
                change('eventID' as keyof Automation, "");
                change('eventName' as keyof Automation, "");
            }
        }
    }

    const triggerChangeHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        change(name as keyof Automation, value);

        if(value === '1') {
            change('moduleId' as keyof Automation, null);
        }

        if(value === '2'){
            change('eventID' as keyof Automation, null);
            change('eventName' as keyof Automation, "");
    }
        change('triggerID', +value);
    }, [change]);

    const numberChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        change(name as keyof Automation, +value);

        if(name === 'moduleId') {
            orderByObject.columnName = '';
            setOrderByObject(orderByObject);
        }
    }

    const excludeRecordsProcessedInDaysHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const value = +e.target.value;
        if(value >= 0) {
            change('excludeRecordsProcessedInDays' as keyof Automation,value);
        }
    }, [change]);

    const maxRecordsToExecuteHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const value = +e.target.value;
        if(value >= 0) {
            change('maxNumberOfRecordsToExecute' as keyof Automation,value);
        }
    }, [change]);

    const addOutputHandler = () => {
        setOutputs(prev => {
            let tmp = [...prev];
            let newId = 0;

            for (let i = 0; i < prev.length + 1; i++) {
                const index = prev.findIndex(f => f.id === i);
                if(index === -1) {
                    newId = i;
                    break;
                }
            }

            tmp.push({ 
                id: newId,
                description: 'Send Email',
                typeId: 1,

                dateType: 0,
                dateDays: 0,
                dateExecution: 'after',
                dateTime: '',

                slackWebhookUrl: '',
                teamsWebhookUrl: '',
                messageTitle: '',
                message: '',
                webhookUrl: '',
                webhookHeaderKey: '',
                webhookHeaderValue: '',
                webhookPayload: '',
                activityType: 0,
                activityAssignedTo: 0,
                activityEntityRecord: '',
                activityNotes: '',
                entityField: '',

                tagTypeId: 0,
                tagName: '',
                onboardingFormId: 0,
                surveyId: 0,
                emailFrom: -1,
                emailToSource: 0,
                emailToNumericValues: [],
                emailToStringValues: [],
                emailEntityRecord: '',
                emailSubject: '',
                emailBody: '',
                smsToSource: 0,
                smsEntityRecord: '',
                smsBody: '',
            });

            return tmp;
        });
    }

    const addFilterHandler = useCallback(() => {
        setFilters(prev => {
            let tmp = [...prev];
            let newId = 0;
            for (let i = 0; i < prev.length + 1; i++) {
                const index = prev.findIndex(f => f.id === i);
                if (index === -1) {
                    newId = i;
                    break;
                }
            }

            tmp.push({
                id: newId,
                type: 1,
                property: '',
                customField: '',
                numericValues: [],
                stringValues: [],
                freeTextValue: '',
                moduleFilterValues: {days: 0,isBusinessDays: false, isFutureDate: false}
            });

            return tmp;
        });
    }, []);

    const outputChangeHandler = useCallback((index: number, output: AutomationOutput) => {
        setOutputs(prev => {
            let tmp = [...prev];
            tmp[index] = output;
            return tmp;
        });
    }, []);

    
    const outputScheduleHandler = useCallback((schedule: AutomationScheduleData) => {
       setScheduleData(schedule)
       change('scheduleMetaDataJSON', JSON.stringify(schedule));
    }, [change]);

    const filterChangeHandler = useCallback((index: number, filter: AutomationFilter) => {
        setFilters(prev => {
            let tmp = [...prev];
            tmp[index] = filter;
            return tmp;
        });
    }, []);

    const outputMoveUpHandler = useCallback((index: number) => {
        if (index === 0) return;
        setOutputs(prev => {
            let tmp = [...prev];
            const q = prev[index];
            tmp.splice(index, 1);
            tmp.splice(index -1, 0, q);
            return tmp;
        });
    }, []);

    const outputMoveDownHandler = useCallback((index: number, isLast: boolean) => {
        if (isLast) return;
        setOutputs(prev => {
            let tmp = [...prev];
            const q = prev[index];
            tmp.splice(index, 1);
            tmp.splice(index +1, 0, q);
            return tmp;
        });
    }, []);

    const outputDeleteHandler = useCallback((index: number) => {
        setOutputs(prev => {
            let tmp = [...prev];
            tmp.splice(index, 1);
            return tmp;
        });
    }, []);

    const filterDeleteHandler = useCallback((index: number) => {
        setFilters(prev => {
            let tmp = [...prev];
            tmp.splice(index, 1);
            return tmp;
        });
    }, []);

    const isFiltersEnabled = useMemo(() => {
        return filterableEntities.includes(linkedEntityId);
    }, [linkedEntityId]);

    const filteredTagSettings = useMemo(() => {
        return tagSettings.filter(t => t.entityId === linkedEntityId);
    }, [tagSettings, linkedEntityId]);

    const filteredActivitySettings = useMemo(() => {
        const isClient = linkedEntityId === 1;
        const isContact = linkedEntityId === 2;
        const isCandidate = linkedEntityId === 3;
        const isJob = linkedEntityId === 4;
        const isPlacement = linkedEntityId === 5;
        const isOpportunity = linkedEntityId === 8;
        return activitySettings.filter(s => {
            return (s.isClientsEnabled && isClient)
            || (s.isContactsEnabled && isContact)
            || (s.isCandidatesEnabled && isCandidate)
            || (s.isJobsEnabled && isJob)
            || (s.isPlacementsEnabled && isPlacement)
            || (s.isOpportunitiesEnabled && isOpportunity);
        });
    }, [activitySettings, linkedEntityId]);

    useEffect(() => {
        const needsFetching = !Boolean(cachedTags[linkedEntityId]);
        if (linkedEntityId && filteredTagSettings.length > 0 && needsFetching) {
            const getTags = async () => {
                let fetchedAvailableTags = await GetCustomerTags(linkedEntityId, setErrorMessage);
                if (fetchedAvailableTags) {
                    for (let i = 0; i < filteredTagSettings.length; i++) {
                        const setting = filteredTagSettings[i];
                        if (setting.structured) {
                            const structuredValues = await GetTagValues(linkedEntityId, setting.tagTypeId, setErrorMessage);
                            if (structuredValues && fetchedAvailableTags) {
                                let newAvailables: TagValue[] = fetchedAvailableTags.filter(t => t.tagTypeID !== setting.tagTypeId);
                                fetchedAvailableTags = [...newAvailables, ...structuredValues];
                            }
                        }
                    }
                    setCachedTags(prev => {
                        let tmp = {...prev};
                        tmp[linkedEntityId] = fetchedAvailableTags ?? [];
                        return tmp;
                    });
                }
            };

            getTags();
        }
    }, [linkedEntityId, filteredTagSettings, cachedTags]);

    const filteredTagValues = useMemo(() => {
        return cachedTags[linkedEntityId] ?? [];
    }, [linkedEntityId, cachedTags]);

    const filteredPlaceholders = useMemo(() => {
        return cachedPlaceholders[linkedEntityId];
    }, [cachedPlaceholders, linkedEntityId]);

    const filteredCustomFields = useMemo(() => {
        return cachedCustomFields[linkedEntityId];
    }, [cachedCustomFields, linkedEntityId]);

    const validateScheduleData = (scheduleData: AutomationScheduleData) => { 

        if(scheduleData.timeZone === null || scheduleData.timeZone === undefined) {
            throw Error('The timezone is required to configure your time based automation schedule.');
        }

        if(!scheduleData.schedule) {
            throw Error('The schedule type is required to configure your time based automation schedule.');
        }

        if(!scheduleData.interval) {
            throw Error('The inverval is required to configure your time based automation schedule.');
        }

        //Daily Validation
        if(scheduleData.schedule === "Daily") {
         if(scheduleData.interval === "Once") {
                if(scheduleData.runDays.length === 0) {
                    throw Error('Schedule run days are required.');
                }

                if(!scheduleData.startTime) {
                    throw Error('Schedule start time is required.');
                }
            }

            if(scheduleData.interval === "Hourly") {
                if(scheduleData.runDays.length === 0) {
                    throw Error('Schedule run days are required.');
                }
    
                if(!scheduleData.runAfter) {
                    throw Error('Schedule run after is required.');
                }
    
                if(!scheduleData.runUntil) {
                    throw Error('Schedule run until is required.');
                }
    
                if(!scheduleData.hourMinuteInterval) {
                    throw Error('Schedule hour/minute interval is required.');
                }
            }
        }

        //Days Per Month Validation
        if(scheduleData.schedule === "DaysPerMonth") { 
            if(!scheduleData.startTime) {
                throw Error('Schedule start time is required.');
            }

            if(scheduleData.interval === "SpecificDate") {
                if(!scheduleData.dayOfMonth && scheduleData.dayOfMonth < 1) {
                    throw Error('Schedule Day of Month is required.');
                }
            }

            if(scheduleData.interval === "DayOfWeekOfMonth") {
                if(!scheduleData.weekOrdinal && scheduleData.weekOrdinal < 1) {
                    throw Error('Schedule week ordinal is required.');
                }

                if(!scheduleData.dayOfWeek && scheduleData.dayOfWeek < 1) {
                    throw Error('Schedule day of week is required.');
                }
            }
        }   
    }
      const updateOrderByDirectionChange = useCallback((name: string,value: string) => {
        let isAscending = true;
        let columnName = '';
        setOrderByObject(prevState => {
        isAscending = prevState.isAscending;;
        columnName = prevState.columnName;

        if(name === 'columnName') {
            columnName = value;
        }
        if(name === 'isAscending') {
            isAscending = stringToBoolean(value);
        }
        return ({ 
          ...prevState,
          isAscending: isAscending,
          columnName: columnName
        })});
        change('orderByJSON', JSON.stringify({isAscending: isAscending, columnName: columnName }));
      }, [change]);


      

      const stringToBoolean = (value: string):boolean => {
        return JSON.parse(value.toLowerCase());
    }
    

    const handleOrderByDirectionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        updateOrderByDirectionChange('isAscending',event.target.value);
    }

    const handleOrderByChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        updateOrderByDirectionChange('columnName',event.target.value);
    }

    const previewDataHandler = (value: boolean,dataForNextRunOnly: boolean = false) => {
        updateOpenPreviewData(value,dataForNextRunOnly);
    }

    const updateOpenPreviewData = useCallback((value: boolean,dataForNextRunOnly: boolean = false) => {
        let previewData = {
            nextRunOnly: dataForNextRunOnly,
            maxRecords: state.maxNumberOfRecordsToExecute ?? 1000,
            automationId:state.id,
            orderBy: orderByObject
        }
        setPreviewData(previewData);
        setOpenPreviewData(value)
        handleClose();
      }, [state, orderByObject, setOpenPreviewData, setPreviewData]);

    

    return (
        <>
            {unsavedChangesDialog}
            { !hasBlockedRoute && showSuccess && <Navigate to="/configuration/automation" /> }
            <Snackbar open={showSuccess} autoHideDuration={3000} onClose={() => setShowSuccess(false)}>
                <Alert onClose={() => setShowSuccess(false)}>Changes Saved</Alert>
            </Snackbar>
            <Snackbar open={errorMessage !== ''} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <Snackbar open={successMessage !== ''} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity="success" onClose={() => setSuccessMessage('')}>{ successMessage }</Alert>
            </Snackbar>
            <PageContentLayout title="Automation Workflow Builder" showLoading={isLoading || isGridLoading}>
                <Stack spacing={3}>
                    <Box display="flex">
                        <RWTextFieldComponent label="Name" variant="outlined" name="name" value={state.name} multiline={false} onChange={stringChangeHandler} sxOptions={{ flex: '1 0 0' }}/>
                        <InfoButtonToolTip text={"Enter a name for your Automation"} />
                    </Box>
                    
                    <Box display="flex">
                        <TextField
                            select
                            label="Status"
                            name="statusID"
                            value={state.statusID.toString()}
                            onChange={numberChangeHandler}
                            sx={{ flex: '1 0 0' }}
                        >
                            <MenuItem value="1">Active</MenuItem>
                            <MenuItem value="2">Inactive</MenuItem>
                        </TextField>
                        <InfoButtonToolTip text={"Disable an Automation to prevent it from being triggered"} />
                    </Box>

                    <Box display="flex">
                        <TextField
                            select
                            label="Trigger"
                            name="triggerID"
                            value={state.triggerID ? state.triggerID.toString() : '1'}
                            onChange={triggerChangeHandler}
                            sx={{ flex: '1 0 0' }}
                        >
                            <MenuItem value="1">Event Based</MenuItem>
                            <MenuItem disabled={!companyHasSinglePermission(P.Automation)}value="2">Time Based</MenuItem>
                        </TextField>
                        <InfoButtonToolTip text={"How the Automation is triggered. Event based; Something happening in Recruit Wizard. | Time based; Executes on a schedule."} />
                    </Box>
                    <Box display="flex">
                        {
                            state.triggerID === 1 ?  
                        <TextField
                            select
                            label="Event"
                            name="eventID"
                            value={state.eventID?.toString()}
                            onChange={numberChangeHandler}
                            sx={{ flex: '1 0 0' }}
                        >
                            {eventTypes.map(a => <MenuItem disabled={!companyHasSinglePermission(P.Automation) && a.productAvailability === 1} key={a.webhookEventID} value={a.webhookEventID.toString()}>{a.name}</MenuItem>)}
                        </TextField> 
                        :
                        <TextField
                            select
                            label="Module"
                            name="moduleId"
                            value={state.moduleId?.toString()}
                            onChange={numberChangeHandler}
                            sx={{ flex: '1 0 0' }}
                        >
                            {moduleTypes.map(a => <MenuItem disabled={!companyHasSinglePermission(P.Automation)} key={a.id} value={a.id.toString()}>{a.name}</MenuItem>)}
                        </TextField> 
                        }
                        
                        <InfoButtonToolTip text={"Time based Automation Modules gives you unique functionality for each module type."} />
                    </Box>
                    {state.triggerID === 2 && 
                    <>
                      <Box display="flex">                
                            <RWTextFieldComponent
                                sxOptions={{ flex: '1 0 0' }}
                                type="number"
                                label="Exclude records previously processed in last X days"
                                name="excludeNumberOfRecordsProcessedInDays"
                                value={state.excludeRecordsProcessedInDays?.toString() ?? ''}
                                onChange={excludeRecordsProcessedInDaysHandler}/>
                            <InfoButtonToolTip text={"Prevents the Automation from running again for records that have been processed in the time period specified. 0 days will have no limit."} />
                        </Box>
                        <Box display="flex">
                            <RWTextFieldComponent
                                sxOptions={{ flex: '1 0 0' }}
                                type="number"
                                label="Max number of records to execute"
                                name="maxNumbetOfRecordsToExecute"
                                value={state.maxNumberOfRecordsToExecute?.toString() ?? ''}
                                onChange={maxRecordsToExecuteHandler}/>
                            <InfoButtonToolTip text={"Maximum number of records to execute."} />
                        </Box>
                        <Box display="flex">
                        <TextField
                         sx={{ flex: '1 0 0' }}
                                label="Order Records by"
                                select
                                onChange={handleOrderByChange}
                                value={orderByObject.columnName}
                            >
                             {state.moduleId === 3 ? timeBasedCandidateOrderByColumns.map(columnName => <MenuItem key={columnName} value={columnName}>{columnName}</MenuItem>) :
                             timeBasedPlacementOrderByColumns.map(columnName => <MenuItem key={columnName} value={columnName}>{columnName}</MenuItem>)}
                            </TextField>
                            <InfoButtonToolTip text={"Order records by selected column"} />
                        </Box>
                        <Box>
                        <FormControl>
                            <FormLabel id="demo-form-control-label-placement">Order direction</FormLabel>
                            <RadioGroup
                                row={true}
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue="true"
                                name="radio-buttons-group"
                                value={orderByObject?.isAscending}>
                                    <FormControlLabel value="Ascending" control={<Radio value="true" onChange={handleOrderByDirectionChange}/>} label="Ascending"/>
                                    <FormControlLabel value="Descending" control={<Radio value="false" onChange={handleOrderByDirectionChange} />} label="Descending" />
                            </RadioGroup>
                            </FormControl>
                        </Box>
                    </>}

                    {state.triggerID === 2 && <Box><AutomationSchedule scheduleData={scheduleData} onChange={outputScheduleHandler}/></Box>}
            
                    {!isFiltersEnabled && <Typography color="text.disabled">No filters available</Typography>}
                    {isFiltersEnabled &&
                        <Box>
                            <Button onClick={addFilterHandler} endIcon={<AddIcon />}>Add Filter</Button>
                            {filters.map((f, i) => (
                                <AutomationFilterComponent
                                    key={f.id}
                                    index={i}
                                    filter={f}
                                    entityId={linkedEntityId}
                                    divisions={linkedEntityId !== 8 ? cachedDivisions : undefined}
                                    jobSources={linkedEntityId === 4 ? cachedJobSources : undefined}
                                    placementSources={linkedEntityId === 5 ? cachedPlacementSources : undefined}
                                    countries={linkedEntityId === 5 ? cachedCountries : undefined}
                                    customFields={filteredCustomFields}
                                    onChange={filterChangeHandler}
                                    onDelete={filterDeleteHandler}
                                    isTimeBased={state.triggerID === 2 ? true : false}
                                />
                            ))}
                        </Box>
                    }
                    {(isFiltersEnabled && filters.length > 0 && state.triggerID === 2) && <Box display="flex"><Box sx={{flex:'1 0 0'}}></Box>
                    <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon/>}
      >
        Preview Data
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={() => {previewDataHandler(true,false)}}>
          All Data
        </MenuItem>
        {state.id > 0 && <MenuItem onClick={() => {previewDataHandler(true,true)}} >Next Run</MenuItem>}
      </StyledMenu>
                    
                    
                   </Box>}
                    <AutomationPreviewDataDialog 
                    moduleId={state.moduleId ?? 0}
                    open={openPreviewData}
                    gridLoadHandler={(value: boolean) => {
                        setIsGridLoading(value);
                    }}
                    cancelHandler={() => {previewDataHandler(false)}}
                    filters={JSON.stringify(filters)}
                    errorHandler={(message:string) => {
                        console.log('Error received');
                        setErrorMessage(message);
                        previewDataHandler(false);
                    }}
                    successHandler={(message: string) => {
                        setSuccessMessage(message);
                    }}
                    fromDialogPreviewData={previewData}
                    />

                    <Box>
                        <Button disabled={!companyHasSinglePermission(P.Automation)} onClick={addOutputHandler} endIcon={<AddIcon />}>Add Action</Button>
                        {outputs.map((o, i) => (
                            <AutomationOutputComponent
                                key={o.id}
                                index={i}
                                triggerId={state.triggerID  ?? 1}
                                entityId={linkedEntityId}
                                isLast={i === (outputs.length - 1)}
                                output={o}
                                onChange={outputChangeHandler}
                                moveUp={outputMoveUpHandler}
                                moveDown={outputMoveDownHandler}
                                onDelete={outputDeleteHandler}
                                activitySettings={filteredActivitySettings}
                                tagSettings={(o.typeId === 7 || o.typeId === 8) ? filteredTagSettings : undefined}
                                tagValues={(o.typeId === 7 || o.typeId === 8) ? filteredTagValues : undefined}
                                placeholders={(o.typeId === 1 || o.typeId === 2 || o.typeId === 3 || o.typeId === 9 || o.typeId === 10) ? filteredPlaceholders : undefined}
                            />
                            )
                        )}
                    </Box>
                </Stack>
            </PageContentLayout>
        </>
    );
}