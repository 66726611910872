import { PanelModel } from "@syncfusion/ej2-layouts/src/dashboard-layout/dashboard-layout-model";
import { CandidateRecordDashboardElementDefinition, CandidateRecordDashboardElementType } from "common/models/Dashboard/EditLayout";
import { ScreenLayoutSettings } from "common/models/ScreenLayouts/ScreenLayouts";
import { GetCustomFieldTypeById } from "../ScreenLayouts/CustomFields";

export const DefaultCandidateQuickViewLayoutElements: CandidateRecordDashboardElementDefinition[] = [
    { id: "CandidateId_Default", row: 0, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'CandidateId' },
    { id: "CandidateMobile_Default", row: 1, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'CandidateMobile' },
    { id: "CandidatePhone_Default", row: 2, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'CandidatePhone' },
    { id: "CandidateEmail1_Default", row: 3, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'CandidateEmail1' },
    
    { id: "CandidateStatus_Default", row: 0, col: 2, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'CandidateStatus' },
    { id: "CandidatePreferredJobType_Default", row: 1, col: 2, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'CandidatePreferredJobType' },
    { id: "CandidatePreferredSalary_Default", row: 2, col: 2, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'CandidatePreferredSalary' },
    { id: "CandidatePreferredRate_Default", row: 3, col: 2, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'CandidatePreferredRate' },
    
    // { id: "Spacer_Default", row: 4, col: 0, sizeX: 4, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'Spacer' },
    { id: "CandidateSummaryFullWidth_Default", row: 4, col: 0, sizeX: 4, sizeY: 4, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'CandidateSummaryFullWidth' },
    { id: "CandidateWorkHistorySummary_Default", row: 8, col: 0, sizeX: 4, sizeY: 6, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'CandidateWorkHistorySummary' },

];

export const GetMinSizeByElementType = (type: CandidateRecordDashboardElementType) => {
    switch (type) {
        default: return [1, 1];
    }
};

export const GetDefaultSizeByElementType = (type: CandidateRecordDashboardElementType) => {
    switch (type) {
        default: return [2, 1];
    }
};

export const GetElementTypeById = (id: string): CandidateRecordDashboardElementType => {
    if ( id && id.startsWith('Divider_')) return 'Divider';
    if ( id && id.startsWith('Spacer_')) return 'Spacer';
    if (id && id.startsWith('CandidateInfo_')) return 'CandidateInfo';
    if (id && id.startsWith('CandidateId_')) return 'CandidateId';
    if (id && id.startsWith('CandidateName_')) return 'CandidateName';
    if (id && id.startsWith('CandidateGender_')) return 'CandidateGender';
    if (id && id.startsWith('CandidateAvailable_')) return 'CandidateAvailable';
    if (id && id.startsWith('CandidateNotice_')) return 'CandidateNotice';
    if (id && id.startsWith('CandidateStatus_')) return 'CandidateStatus';
    if (id && id.startsWith('CandidateDateOfBirth_')) return 'CandidateDateOfBirth';
    if (id && id.startsWith('CandidateNationality_')) return 'CandidateNationality';
    if (id && id.startsWith('CandidateDivisions_')) return 'CandidateDivisions';
    if (id && id.startsWith('CandidateEmail1_')) return 'CandidateEmail1';
    if (id && id.startsWith('CandidateEmail2_')) return 'CandidateEmail2';
    if (id && id.startsWith('CandidatePayrollEmail_')) return 'CandidatePayrollEmail';
    if (id && id.startsWith('CandidateWebsite_')) return 'CandidateWebsite';
    if (id && id.startsWith('CandidateLocation_')) return 'CandidateLocation';
    if (id && id.startsWith('CandidateMobile_')) return 'CandidateMobile';
    if (id && id.startsWith('CandidatePhone_')) return 'CandidatePhone';
    if (id && id.startsWith('CandidateWork_')) return 'CandidateWork';
    if (id && id.startsWith('CandidateOptOut_')) return 'CandidateOptOut';
    if (id && id.startsWith('CandidateSummaryFullWidth_')) return 'CandidateSummaryFullWidth';
    if (id && id.startsWith('CandidateSummary_')) return 'CandidateSummary';
    if (id && id.startsWith('CandidatePreferredJobType_')) return 'CandidatePreferredJobType';
    if (id && id.startsWith('CandidatePreferredRate_')) return 'CandidatePreferredRate';
    if (id && id.startsWith('CandidatePreferredSalary_')) return 'CandidatePreferredSalary';
    if (id && id.startsWith('CandidateLinkedIn_')) return 'CandidateLinkedIn';
    if (id && id.startsWith('CandidateTwitter_')) return 'CandidateTwitter';
    if (id && id.startsWith('CandidateWorkHistorySummary_')) return 'CandidateWorkHistorySummary';
    if ( id && id.startsWith('CustomField')) return GetCustomFieldTypeById(id);
    return '';
};

export const GetPanelDefinitionsFromPanelModels = (panels: PanelModel[]) => {
    let elements: CandidateRecordDashboardElementDefinition[] = [];
    panels.forEach((p, i) => {
        let type = GetElementTypeById(p.id ?? '');
        elements.push({
            id: p.id ?? i.toString(),
            sizeX: p.sizeX ?? 1,
            sizeY: p.sizeY ?? 1,
            col: p.col ?? 0,
            row: p.row ?? 0,
            minSizeX: p.minSizeX ?? 1,
            minSizeY: p.minSizeY ?? 1,
            maxSizeX: p.maxSizeX ?? 1,
            maxSizeY: p.maxSizeY ?? null,
            type: type
        });
    });
    return elements ;
};

export const CandidateQuickViewLayoutSettings: ScreenLayoutSettings = {
    unitWidth: 228,
    unitHeight: 40,
    gapX: 10,
    gapY: 0,
    columns: 4
}