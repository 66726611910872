import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { SxProps } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import moment from "moment";
import React, { useCallback, useMemo, useState } from "react";
import { GetComplianceExpiryReport } from "services/ComplianceService";
import { GridColDef, GridRenderCellParams, GridValueGetter, useGridApiRef } from "@mui/x-data-grid-premium";
import { DownloadDocument } from "services/DocumentsService";
import { ComplianceReportingData } from "common/models/ComplianceData";
import GridWithStateWrapper from "components/GridWidthStateWrapper";
import IconButton from "@mui/material/IconButton";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { Link } from "react-router-dom";

interface Props {
    description: string,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
}

const filterElementStyle: SxProps = { flex: '1 1 0', mr: 1 };
const linkStyle: React.CSSProperties = { color: 'inherit', textDecoration: 'underline' };

export default function ComplianceExpiryReport({ description, loadingHandler, errorHandler }: Props) {
    const [rows, setRows] = useState<ComplianceReportingData[]>([]);
    const [showGrid, setShowGrid] = useState(false);
    const [expiryInDays, setExpiryInDays] = useState(180);
    const apiRef = useGridApiRef();

    const getDataCallback = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        const res = await GetComplianceExpiryReport(expiryInDays, errorHandler);
        if (res) {
            setShowGrid(true);
            setRows(res);
        }
        loadingHandler && loadingHandler(false);
    }, [expiryInDays, errorHandler, loadingHandler]);

    const exportAsExcelHandler = useCallback(() => {
        const api = apiRef.current;
        if (api) {
            const m = moment();
            const filename = `ComplianceExpiry${m.format('YYYYMMDDhhmmss')}`;
            api.exportDataAsExcel({ fileName: filename });
        }
    }, [apiRef]);

    const downloadDocumentCallback = useCallback(async (documentId: number) => {
        loadingHandler && loadingHandler(true);
        await DownloadDocument(documentId, undefined, errorHandler);
        loadingHandler && loadingHandler(false);
    }, [loadingHandler, errorHandler]);

    const columns = useMemo<GridColDef[]>(() => {
        const dateValueGetter: GridValueGetter<ComplianceReportingData, any, undefined, string> = (value) => {
            if (value) {
                const d = moment(value);
                if (d.isValid() && d.get('year') > 1) {
                    return d.toDate();
                }
            }
        };

        const dateRenderer = (params: GridRenderCellParams) => {
            if (params.value) {
                return moment(params.value).format('DD MMM YYYY');
            }
        };

        const linktoRecordRenderer = (params: GridRenderCellParams) => {
            if (params.value) {

                let url = `/candidates/${params.row.candidateID}?tab=Compliance`;

                if (params.row.placementID !== 0)
                    url = `/placements/${params.row.placementID}?tab=Compliance`;

                return (
                    <Link
                        to={url}
                        style={linkStyle}
                    >{params.value}</Link>
                );
            }
        };

        const downloadDocumentRenderer = (params: GridRenderCellParams) => {
            if (params.row.documentID) {
                return (

                    <span
                        style={{ cursor: 'pointer', textDecoration: 'underline' }}
                        onClick={() => downloadDocumentCallback(params.row.documentID as number)}
                    ><IconButton size="small" sx={{ mt: '5px' }}>
                            <AttachFileIcon />
                        </IconButton></span>
                );
            }
            return <></>;
        };

        return [
            { headerName: 'Name', field: 'name', width: 250 },
            { headerName: '', field: 'attachments', type: 'number', width: 30, renderCell: downloadDocumentRenderer },
            { headerName: 'Type', field: 'typeName', headerAlign: 'center', align: 'center', width: 150 },
            { headerName: 'Record', field: 'recordName', headerAlign: 'center', align: 'center', width: 250, renderCell: linktoRecordRenderer },
            { headerName: 'Updated By', field: 'updatedByName', headerAlign: 'center', align: 'center', width: 250 },
            { headerName: 'Updated Date', field: 'updatedDate', headerAlign: 'center', align: 'center', width: 150, type: 'date', valueGetter: dateValueGetter, renderCell: dateRenderer },
            { headerName: 'Expiry Date', field: 'expiryDate', headerAlign: 'center', align: 'center', width: 150, type: 'date', valueGetter: dateValueGetter, renderCell: dateRenderer },
            { headerName: 'Notes', field: 'notes', width: 400 },
        ];
    }, [downloadDocumentCallback]);

    return (
        <>
            <Box display="flex">
                <TextField select label="Expiring within" value={expiryInDays.toString()} onChange={({ target }) => setExpiryInDays(+target.value)} sx={filterElementStyle}>
                    <MenuItem value="30">1 month</MenuItem>
                    <MenuItem value="90">3 months</MenuItem>
                    <MenuItem value="180">6 month</MenuItem>
                    <MenuItem value="365">12 months</MenuItem>
                    <MenuItem value="540">18 months</MenuItem>
                    <MenuItem value="730">24 months</MenuItem>
                </TextField>
                <Button variant="contained" color="success" onClick={getDataCallback}>Run Report</Button>
            </Box>
            <Typography component="div" variant="h6" mt={2}>Report Description</Typography>
            <Typography component="div" variant="body2" mb={2}>{description}</Typography>
            {showGrid &&
                <>
                    <Box pb="10px" ml="auto">
                        <Button variant="contained" color="success" onClick={exportAsExcelHandler}>Export As Excel</Button>
                    </Box>
                    <GridWithStateWrapper
                        density="compact"
                        gridName="reports-expiring-compliance"
                        rows={rows}
                        columns={columns}
                        apiRef={apiRef}
                        getRowId={r => r.documentID}
                        disableRowSelectionOnClick
                    />
                </>
            }
        </>
    );
}
