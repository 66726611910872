import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { ShazammeProfession, ShazammeRole } from "common/models/JobPosting/Shazamme";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { GetShazammeBoardSetupData } from "services/JobBoardsService";

interface Props {
    jobBoardId: number,
    advertId: number,
    attributes?: Record<string, string>,
    advertRecordAttributes?: Record<string, string>,
    loadingHandler?: (isLoading: boolean) => void,
    attributeChangeHandler?: (jobBoardId: number, key: string, value: string) => void
}

export default function ShazammeEdit({ jobBoardId, advertId, attributes, advertRecordAttributes, loadingHandler, attributeChangeHandler }: Props) {
    const [professions, setProfessions] = useState<ShazammeProfession[]>([]);
    const [rolesMap, setRolesMap] = useState<Record<string, ShazammeRole[]>>({});

    useEffect(() => {
        const getSetupData = async () => {
            loadingHandler && loadingHandler(true);
            const res = await GetShazammeBoardSetupData(jobBoardId, advertId);
            if (res && res.professions && res.professions.length > 0) setProfessions(res.professions);
            if (res && res.roles && res.roles.length > 0) {
                let map: Record<string, ShazammeRole[]> = {};
                for (let i = 0; i < res.roles.length; i++) {
                    const role = res.roles[i];
                    if (map[role.professionID]) map[role.professionID].push(role);
                    else map[role.professionID] = [role];
                }
                setRolesMap(map);
            }
            loadingHandler && loadingHandler(false);
        };
        getSetupData();
    }, [advertId, jobBoardId, loadingHandler]);

    const atts = useMemo<Record<string, string>>(() => {
        if (attributes) return attributes;
        return {};
    }, [attributes]);

    const recordAtts = useMemo<Record<string, string>>(() => {
        if (advertRecordAttributes) return advertRecordAttributes;
        return {};
    }, [advertRecordAttributes]);

    const region = useMemo(() => recordAtts.region ?? '', [recordAtts.region]);
    const location = useMemo(() => recordAtts.location ?? '', [recordAtts.location]);
    const workType = useMemo(() => recordAtts.workType ?? '', [recordAtts.workType]);
    const salaryType = useMemo(() => recordAtts.salaryType ?? '', [recordAtts.salaryType]);
    const salaryFrom = useMemo(() => recordAtts.salaryFrom ?? '', [recordAtts.salaryFrom]);
    const salaryTo = useMemo(() => recordAtts.salaryTo ?? '', [recordAtts.salaryTo]);
    const displaySalary = useMemo(() => recordAtts.displaySalary ?? '', [recordAtts.displaySalary]);

    useEffect(() => attributeChangeHandler && attributeChangeHandler(jobBoardId, 'Region', region), [attributeChangeHandler, region, jobBoardId]);
    useEffect(() => attributeChangeHandler && attributeChangeHandler(jobBoardId, 'Location', location), [attributeChangeHandler, location, jobBoardId]);
    useEffect(() => attributeChangeHandler && attributeChangeHandler(jobBoardId, 'WorkType', workType), [attributeChangeHandler, workType, jobBoardId]);
    useEffect(() => attributeChangeHandler && attributeChangeHandler(jobBoardId, 'SalaryType', salaryType), [attributeChangeHandler, salaryType, jobBoardId]);
    useEffect(() => attributeChangeHandler && attributeChangeHandler(jobBoardId, 'SalaryMin', salaryFrom), [attributeChangeHandler, salaryFrom, jobBoardId]);
    useEffect(() => attributeChangeHandler && attributeChangeHandler(jobBoardId, 'SalaryMax', salaryTo), [attributeChangeHandler, salaryTo, jobBoardId]);
    useEffect(() => attributeChangeHandler && attributeChangeHandler(jobBoardId, 'PublicSalary', displaySalary), [attributeChangeHandler, displaySalary, jobBoardId]);

    const professionAtt = useMemo(() => atts.Profession ?? '', [atts.Profession]);
    const roleAtt = useMemo(() => atts.Role ?? '', [atts.Role]);

    const professionChangeHandler = useCallback((e: any, v: ShazammeProfession | null) => {
        if (v) {
            attributeChangeHandler && attributeChangeHandler(jobBoardId, 'Profession', v.professionID);
            attributeChangeHandler && attributeChangeHandler(jobBoardId, 'ProfessionName', v.profession);
            return;
        }

        attributeChangeHandler && attributeChangeHandler(jobBoardId, 'Profession', '');
        attributeChangeHandler && attributeChangeHandler(jobBoardId, 'ProfessionName', '');
    }, [attributeChangeHandler, jobBoardId]);

    const roleChangeHandler = useCallback((e: any, v: ShazammeRole | null) => {
        if (v) {
            attributeChangeHandler && attributeChangeHandler(jobBoardId, 'Role', v.roleID);
            attributeChangeHandler && attributeChangeHandler(jobBoardId, 'RoleName', v.role);
            return;
        }
        
        attributeChangeHandler && attributeChangeHandler(jobBoardId, 'Role', '');
        attributeChangeHandler && attributeChangeHandler(jobBoardId, 'RoleName', '');
    }, [attributeChangeHandler, jobBoardId]);

    const selectedProfession = useMemo(() => {
        if (professionAtt) {
            const item = professions.find(p => p.professionID === professionAtt);
            if (item) return item;
        }
        return null;
    }, [professionAtt, professions]);

    const roles = useMemo(() => {
        const roles = rolesMap[professionAtt];
        return roles ?? [];
    }, [professionAtt, rolesMap]);

    const selectedRole = useMemo(() => {
        if (roleAtt) {
            const item = roles.find(r => r.roleID === roleAtt);
            if (item) return item;
        }
        return null;
    }, [roleAtt, roles]);

    return (
        <Box display="flex" gap={2} pt={2}>
            <Box display="flex" flexDirection="column" gap={2} width="50%">
                <Autocomplete
                    size="small"
                    value={selectedProfession}
                    options={professions}
                    getOptionLabel={o => o.profession}
                    isOptionEqualToValue={(o, v) => o.professionID === v.professionID}
                    onChange={ professionChangeHandler }
                    renderInput={ params => <TextField {...params} label="Profession" inputProps={{ ...params.inputProps, "data-lpignore": "true" }} /> }
                />
                <Autocomplete
                    size="small"
                    value={selectedRole}
                    options={roles}
                    getOptionLabel={o => o.role}
                    isOptionEqualToValue={(o, v) => o.roleID === v.roleID}
                    onChange={ roleChangeHandler }
                    renderInput={ params => <TextField {...params} label="Role" inputProps={{ ...params.inputProps, "data-lpignore": "true" }} /> }
                />
                <TextField disabled label="Region" value={region} />
                <TextField disabled label="Location" value={location} />
            </Box>
            <Box display="flex" flexDirection="column" gap={2} width="50%">
                <TextField disabled label="Work Type" value={workType} />
                <TextField disabled label="Salary Type" value={salaryType} />
                <Box display="flex" gap={2}>
                    <TextField disabled label="Salary From" sx={{ flex: '1 1 0' }} value={salaryFrom} />
                    <TextField disabled label="Salary To" sx={{ flex: '1 1 0' }} value={salaryTo} />
                </Box>
                <TextField disabled label="Display Salary" value={displaySalary} />
            </Box>
        </Box>
    );
}