import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Interview } from "common/models/Interviews";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import PageLayout from "layouts/PageLayout";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { MenuOptionDefinition } from "common/models/MenuDefinition";
import Box from "@mui/material/Box";
import ActionMenu from "components/Menus/ActionMenu";
import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import moment from "moment";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import { CancelInterview, GetInterviewById, GetInterviewTypeById } from "services/InterviewsService";
import PageContentLayout from "layouts/PageContentLayout";
import ViewRecordScreenLayout from "components/ScreenLayouts/Interviews/ViewRecordScreenLayout";
import { GetSettingBySettingName } from "services/UsersService";
import InterviewAttendeesGridComponent from "components/Grids/InterviewAttendeesGrid";
import DocumentsGridComponent from "components/Grids/DocumentsGrid";
import Snackbar from "@mui/material/Snackbar";
import Alert from "components/Alert";
import AddNotesToInterviewDialog from "components/Dialogs/Interviews/AddNotesToInterviewDialog";
import ConfirmationDialog from "components/Dialogs/Generic/ConfirmationDialog";
import AddCandidateToInterviewSlotDialog from "components/Dialogs/Interviews/AddCandidateToInterviewSlotDialog";
import { InterviewType } from "common/models/Configuration/InterviewType";
import ActivitiesGridComponent from "../../components/Grids/ActivitiesGrid";

const formatDateString = (d: string, f: string = 'DD MMM YYYY h:mm A') => {
    if (d && d !== '0001-01-01T00:00:00') {
        const m = moment(d);
        if (m.isValid()) return m.format(f);
    }
    return 'Never';
}

const renderSummary = (i: Interview) => {
    const tooltipContent = (
        <table>
            <tbody>
                <tr>
                    <Typography variant="caption" fontWeight={600} component="td">ID</Typography>
                    <Typography variant="caption" pl="10px" component="td">{i.id}</Typography>
                </tr>
                <tr>
                    <Typography variant="caption" fontWeight={600} component="td">Created Date</Typography>
                    <Typography variant="caption" pl="10px" component="td">{formatDateString(i.dateCreated)}</Typography>
                </tr>
            </tbody>
        </table>
    );

    const jobSummary = (
        <Tooltip arrow title={tooltipContent}>
            <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{i.subject} ({i.typeName}) - {i.statusName}</span>
        </Tooltip>
    );

    return (<>{jobSummary}</>);
};

export default function InterviewRecord() {
    const [interview, setInterview] = useState<Interview>();
    const [interviewType, setInterviewType] = useState<InterviewType>();
    const [showAddNotesDialog, setShowAddNotesDialog] = useState(false);
    const [showAddCandidateDialog, setShowAddCandidateDialog] = useState(false);
    const [showCancelDialog, setShowCancelDialog] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isFetchingDefaultTab, setIsFetchingDefaultTab] = useState(false);
    const [isLoadingTab, setIsLoadingTab] = useState(false);
    const [isFinishedSetup, setIsFinishedSetup] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [activeTab, setActiveTab] = useState('Home');
    const params = useParams();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const interviewId = useMemo(() => {
        const parsedId = +(params.id ?? '0');
        if (isNaN(parsedId)) return 0;
        return parsedId;
    }, [params.id]);

    useEffect(() => {
        const getData = async () => {
            setIsLoading(true);
            const res = await GetInterviewById(interviewId);
            if (res === null) navigate('/not-found');
            if (res) {
                setInterview(res);
                const type = await GetInterviewTypeById(res.typeId);
                if (type) setInterviewType(type);
            }
            setIsLoading(false);
        };
        getData();
    }, [interviewId, navigate]);

    useEffect(() => {
        const tabParam = searchParams.get('tab');
        const getData = async () => {
            setIsFetchingDefaultTab(true);
            if (!tabParam) {
                const tabSetting = await GetSettingBySettingName('TabDefaultsInterviews');
                if (tabSetting) setActiveTab(tabSetting);
            }
            else setActiveTab(tabParam)
            setIsFetchingDefaultTab(false);
            setIsFinishedSetup(true);
        };
        getData();
    }, [searchParams]);

    const handleTabChange = useCallback((e: React.SyntheticEvent, newValue: string) => {
        navigate(`/interviews/${interviewId}?tab=${newValue}`);
    }, [interviewId, navigate]);

    const isInternalInterview = useMemo(() => interviewType ? interviewType.isInternal: false, [interviewType]);
    const isInterviewSlot = useMemo(() => interviewType ? interviewType.isSlot: false, [interviewType]);

    const actionMenuDefinitions = useMemo<MenuOptionDefinition[]>(() => {
        const isClosed = interview ? interview.statusID === 2 : false;
        const isOpen = interview ? interview.statusID === 1 : false;
        const interviewType = interview ? interview.typeId : 0;
        let actions: (MenuOptionDefinition)[] = [
            { label: 'Add Candidate', type: 'action', action: () => setShowAddCandidateDialog(true), allow: () => isOpen && isInterviewSlot },
            { label: 'Add Notes', type: 'action', action: () => setShowAddNotesDialog(true) },
            { label: 'Reschedule', type: 'link', href: `/interviews/${interviewId}/edit?type=${interviewType}&reschedule=true`, allow: () => isClosed || isOpen },
            { label: 'Edit', type: 'link', href: `/interviews/${interviewId}/edit?type=${interviewType}`, allow: () => isOpen },
            { label: 'Cancel', type: 'action', action: () => setShowCancelDialog(true), allow: () => isOpen || isClosed },
        ];

        return actions;
    }, [interview, isInterviewSlot, interviewId]);

    const SummaryBar = useMemo(() => {
        if (interview) {
            const action = (
                <Box key="actionsMenu">
                    <ActionMenu definition={actionMenuDefinitions} />
                </Box>
            );

            return (
                <TitleAndActionSummaryBar
                    height="4.5rem"
                    title={interview ? renderSummary(interview) : ''}
                    action={[action]}
                    browserTabTitle={!interview ? '' : "Interviews"}
                />
            );
        }
    }, [interview, actionMenuDefinitions]);

    const addNotesSuccessHandler = useCallback((message: string, notes: string) => {
        setSuccessMessage(message);
        setInterview(prev => prev ? ({...prev, interviewNotes: notes}) : prev);
    }, []);

    const addCandidateSuccessHandler = useCallback((message: string) => {}, []);

    const cancelInterviewCallback = useCallback(async () => {
        setIsLoading(true);
        const res = await CancelInterview(interviewId, setErrorMessage);
        if (res) {
            setSuccessMessage('Interview Cancelled');
            setInterview(prev => prev ? ({...prev, statusID: 3, statusName: 'Cancelled'}) : prev);
            setShowCancelDialog(false);
        }
        setIsLoading(false);
    }, [interviewId]);

    const specialType = useMemo(() => {
        if (isInternalInterview) return 'internal';
        if (isInterviewSlot) return 'slot';
    }, [isInternalInterview, isInterviewSlot]);

    const cancelMessage = useMemo(() => {
        if (interview) {
            if (interview.statusID === 1) return 'Are you sure you want to cancel this interview?';
            if (interview.statusID === 2) return 'This interview is already completed. Are you sure you want to cancel it?';
        }
        return '';
    }, [interview]);

    const counts = useMemo(() => {
        let counts = {
            activities: 0,
            activitiesDate: '\u00A0',
        };

        if (interview && interview.statisticsCounts && interview.statisticsCounts.length > 0) {
            for (let i = 0; i < interview.statisticsCounts.length; i++) {
                const s = interview.statisticsCounts[i];
                switch (s.type) {
                    case "ActivitiesCount": counts.activities = s.value;
                        break;
                }
            }
        }

        if (interview && interview.statisticsDates && interview.statisticsDates.length > 0) {
            for (let i = 0; i < interview.statisticsDates.length; i++) {
                const s = interview.statisticsDates[i];
                switch (s.type) {
                    case "ActivitiesDate":
                        const aDate = moment(s.value);
                        if (aDate.isValid()) counts.activitiesDate = aDate.format('DD MMM YYYY');
                        break;
                }
            }
        }

        return counts;
    }, [interview]);

    return (
        <>
            <Snackbar open={successMessage !== ''} autoHideDuration={3000} onClose={() => setSuccessMessage('')}>
                <Alert onClose={() => setSuccessMessage('')}>{ successMessage }</Alert>
            </Snackbar>
            <Snackbar open={errorMessage !== ''} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <AddNotesToInterviewDialog
                closeHandler={ () => setShowAddNotesDialog(false) }
                initialNotes={interview ? interview.interviewNotes ?? '' : ''}
                interviewId={interviewId}
                open={showAddNotesDialog}
                loadingHandler={setIsLoadingTab}
                successHandler={addNotesSuccessHandler}
                errorHandler={setErrorMessage}
            />
            {isInterviewSlot &&
                <AddCandidateToInterviewSlotDialog
                    interviewId={interviewId}
                    open={showAddCandidateDialog}
                    closeHandler={() => setShowAddCandidateDialog(false)}
                    loadingHandler={setIsLoadingTab}
                    successHandler={addCandidateSuccessHandler}
                    errorHandler={setErrorMessage}
                />
            }
            <ConfirmationDialog
                message={cancelMessage}
                onClose={() => setShowCancelDialog(false)}
                onContinue={cancelInterviewCallback}
                open={showCancelDialog}
                title="Cancel Interview"
                cancelActionText="No"
                confirmActionText="Yes"
            />
            <PageLayout paddingTop={0} SummaryBar={SummaryBar}>
                <Tabs value={activeTab} onChange={handleTabChange}>
                    <Tab value="Home" label={<>Home<br />&nbsp;</>} />
                    <Tab value="Attendees" label={<>Attendees<br />&nbsp;</>} />
                    <Tab value="Attachments" label={<>Attachments<br />&nbsp;</>} />
                    { !isInterviewSlot && <Tab value="CandidateMessage" label={<>Candidate Message<br />&nbsp;</>} /> }
                    {!isInternalInterview && <Tab value="ClientMessage" label={<>Client Message<br />&nbsp;</>} />}
                    <Tab value="Activities" label={<>Activities ({counts.activities})<br />{counts.activitiesDate}</>} />
                </Tabs>
                <PageContentLayout showLoading={isLoading || isFetchingDefaultTab || isLoadingTab}>
                    { isFinishedSetup && activeTab === "Home" &&
                        <ViewRecordScreenLayout
                            interview={interview ? interview : null}
                            specialType={specialType}
                            loadingHandler={setIsLoadingTab}
                            errorHandler={setErrorMessage}
                        />
                    }
                    { isFinishedSetup && activeTab === "Attendees" &&
                        <InterviewAttendeesGridComponent
                            interviewId={interviewId}
                            gridName="interview/attendees"
                            loadingHandler={setIsLoadingTab}
                            errorHandler={setErrorMessage}
                            successHandler={setSuccessMessage}
                        />
                    }
                    { isFinishedSetup && activeTab === "Attachments" &&
                        <DocumentsGridComponent
                            gridName="interview/attachments"
                            source="interview-record"
                            sourceId={interviewId}
                            hideActions
                            hideCheckboxSelection
                            loadingHandler={setIsLoadingTab}
                            errorHandler={setErrorMessage}
                            successHandler={setSuccessMessage}
                        />
                    }
                    { isFinishedSetup && activeTab === "CandidateMessage" &&
                        <Typography component="div" pt="20px" px="10px">
                            <div dangerouslySetInnerHTML={{ __html: interview ? interview.candidateEmailBody : '' }} />
                        </Typography>
                    }
                    { isFinishedSetup && activeTab === "ClientMessage" && !isInternalInterview &&
                        <Typography component="div" pt="20px" px="10px">
                            <div dangerouslySetInnerHTML={{ __html: interview ? interview.clientEmailBody : '' }} />
                        </Typography>
                    }
                    { isFinishedSetup && activeTab === "Activities" &&
                        <ActivitiesGridComponent
                            source="interview-record"
                            sourceId={interviewId}
                            loadingHandler={setIsLoadingTab}
                            errorHandler={setErrorMessage}
                            successHandler={setSuccessMessage}
                            gridName="interviews/Activities"
                            hideActionsMenu
                        />
                    } 
                </PageContentLayout>
            </PageLayout>
        </>
    );
}