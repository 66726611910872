import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import React, { useEffect, useMemo, useState } from "react";
import { Country } from "common/models/Common";
import { GetCountries } from "services/CommonService";
import { SxProps, Theme } from "@mui/material/styles";

interface Props {
    onSelectCallback: (c: string | null) => void
    code: string | null,
    variant?: "standard" | "filled" | "outlined" | undefined,
    label?: string,
    error?: boolean,
    disableClearable?: boolean
    hideLabel?: boolean,
    fullWidth?: boolean,
    sx?: SxProps<Theme>,
}

export default function CountryPicker({ code, variant, hideLabel, label = "Country", error, disableClearable, fullWidth, sx, onSelectCallback }: Props) {
    const [options, setOptions] = useState<Country[]>([]);

    useEffect(() => {
        const getData = async () => {
            const data = await GetCountries();
            if (data) setOptions(data);
        };
        getData();
    }, []);

    const country = useMemo(() => {
        if (code === null || code === '') return null;
        const country = options.find(c => c.countryCode === code);
        if (country) return country;
        return null;
    }, [code, options]);


    return (
        <Autocomplete
            size="small"
            fullWidth={fullWidth}
            value={country}
            options={options}
            getOptionLabel={o => o.countryName}
            disableClearable={disableClearable}
            isOptionEqualToValue={ (o, v) => o.countryCode === v.countryCode }
            onChange={ (e,v) => onSelectCallback(v ? v.countryCode : null) }
            sx={sx}
            renderInput={ params => <TextField {...params} error={error} label={ hideLabel ? undefined : label } variant={variant} inputProps={{ ...params.inputProps, "data-lpignore": "true" }} /> }
        />
    );
}