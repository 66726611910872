import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardHeader from "@mui/material/CardHeader";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import Input from "@mui/material/Input";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CancelIcon from '@mui/icons-material/Cancel';
import React, { useCallback, useMemo, useState } from "react";
import { TagFilter } from "common/models/Configuration/Tags";
import IconButton from "@mui/material/IconButton";

interface Props {
    title: string,
    availableTags: TagFilter[],
    assignedTags: TagFilter[],
    addHandler: (typeId: number, tag: string) => void,
    removeHandler: (tag: TagFilter) => void,
    color?: string,
    textColor?: string,
}

export default function TagFilterSelectionList({
    title,
    availableTags,
    assignedTags,
    color,
    textColor,
    addHandler,
    removeHandler,
}: Props) {
    const [filterText, setFilterText] = useState('');

    const selectHandler = useCallback((tag: TagFilter) => {
        const index = assignedTags.findIndex(t => t.typeId === tag.typeId && t.tag === tag.tag);
        if (index === -1) {
            addHandler(tag.typeId, tag.tag);
            return;
        }

        removeHandler(tag);
    }, [assignedTags, addHandler, removeHandler]);

    const handleFilterTextChangeCallback = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setFilterText(e.target.value);
    }, []);

    const filteredTags = useMemo(() => {
        if (filterText === '') return availableTags;
        return availableTags.filter(t => t.tag.toLowerCase().includes(filterText.toLowerCase()));
    }, [filterText, availableTags]);

    return (
        <Card sx={{ width: '250px', marginRight: '20px', marginBottom: '10px' }}>
            <CardHeader
                sx={{ py: 0, color: textColor, bgcolor: color, minHeight: '42px' }}
                title={title}
                titleTypographyProps={{ fontSize: '0.875rem', fontWeight: '600' }}
            />
            <Divider />
            <CardActions>
                <Input
                    value={ filterText }
                    onChange={ handleFilterTextChangeCallback }
                    fullWidth
                    placeholder="Filter"
                    endAdornment={ filterText === '' ? undefined : <IconButton size="small" onClick={ () => setFilterText('') } sx={{ p: 0 }}><CancelIcon /></IconButton> }
                />
            </CardActions>
            <List dense disablePadding sx={{ height: '200px', overflow: 'auto' }}>
                {filteredTags.map((tag, i) => {
                    return (
                        <ListItemButton key={`${i}-${tag.typeId}-${tag.tag}`} onClick={ () => selectHandler(tag) } >
                            <ListItemIcon>
                                <Checkbox checked={ assignedTags.findIndex(t => t.typeId === tag.typeId && t.tag === tag.tag) !== -1 } sx={{ p: 0 }} />
                            </ListItemIcon>
                            <ListItemText primary={tag.tag} sx={{ overflowWrap: 'break-word' }} />
                        </ListItemButton>
                    );
                })}
            </List>
        </Card>
    );
}