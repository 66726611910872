import { AdvertEdit } from "common/models/JobPosting/AdvertEdit";
import { Advert, AdvertApplication, AdvertTemplate, JobSite } from "../common/models/Advert";
import { AdvertsNotificationTemplate } from "../common/models/Configuration/Adverts";
import { ScreeningQuestionnaireTemplate } from "../common/models/Configuration/AdvertScreeningQuestionnaire";
import { JobBoard, JobBoardUpdateData } from "../common/models/Configuration/JobBoard";
import { ErrorHandler, NumberArrayWrapper, NumberValueWrapper, RequestOptions } from "../common/models/GenericTypes";
import { ApplicationDoc } from "../common/models/JobPosting/ApplicationDoc";
import { UserAdvertQuotas } from "../common/models/JobPosting/UserAdvertQuotas";
import { Report } from "../common/models/Reports/Report";
import { AdvertSearch } from "../common/models/Search/AdvertSearch";
import { DownloadBlob } from "../util/FilesUtils";
import { Get, Post, Put, Delete, DownloadFile } from "../util/HttpUtils";

export async function AdvertLookup(term: string, limit: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return await Get<Advert[]>(`api/jobposting/Lookup?query=${encodeURIComponent(term)}&limit=${limit}`, options);
}

export async function GetAdvertById(advertId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Advert>(`api/jobposting/${advertId}`, options);
}

export async function GetJobBoardsByAdvertId(advertId: number, includeAttributes: boolean, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<JobSite[]>(`api/jobposting/${advertId}/JobBoards?includeAttributes=${includeAttributes}`, options);
}

export async function GetAvailableJobBoardsByAdvertId(advertId: number, consultantId: number, includeAttributes: boolean, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<JobBoard[]>(`api/jobposting/${advertId}/JobBoards/Availables?consultantId=${consultantId}&includeAttributes=${includeAttributes}`, options);
}

export async function GetApplicationsByAdvertId(advertId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<AdvertApplication[]>(`api/jobposting/${advertId}/Applications`, options);
}

export async function DownloadApplicationDocument(applicationId: number, documentType: 'cv' | 'cover-letter' | 'questionnaire', fileName?: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    let docType = 0;
    if (documentType === 'cv') docType = 1;
    else if (documentType === 'cover-letter') docType = 2;
    else if (documentType === 'questionnaire') docType = 3;

    const file = await DownloadFile(`api/jobposting/applications/${applicationId}/download/${docType}`, options);
    if (file) {
        DownloadBlob(file.data, fileName ? fileName + file.fileExtension : file.fileName);
    }
}

export async function SearchAdverts(data: AdvertSearch, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<Advert[]>('api/jobposting/search', options, data);
}

export async function GetDashboardAdverts(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Advert[]>('api/jobposting/live/me', options);
}

export async function GetMyDraftAdverts(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Advert[]>('api/jobposting/drafts/me', options);
}

export async function GetMyTemplateAdverts(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<AdvertTemplate[]>('api/jobposting/templates/me', options);
}

export async function GetAdvertTemplates(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<AdvertTemplate[]>('api/jobposting/templates', options);
}

export async function DeleteAdvertTemplate(templateId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Delete<boolean>(`api/jobposting/templates/${templateId}`, options);
}

export async function ConvertAdvertToTemplate(advertId: number, newTemplateName: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>(`api/jobposting/templates/convert/${advertId}?templateName=${encodeURIComponent(newTemplateName)}`, options);
}

export async function GetMyAdverts(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Advert[]>('api/jobposting/me', options);
}

export async function GetAdvertsByJobId(jobId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Advert[]>(`api/jobs/${jobId}/adverts`, options);
}

export async function DeleteAdvertById(advertId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Delete<boolean>(`api/JobPosting/${advertId}`, options);
}

export async function ExpireAdvertById(advertId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/JobPosting/${advertId}/Expire`, options);
}

export async function CopyAdvert(advertId: number, jobId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<NumberValueWrapper>(`api/JobPosting/${advertId}/Copy?jobId=${jobId}`, options);
}

export async function UpdateAdvert(advertId: number, data: AdvertEdit, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/JobPosting/${advertId}`, options, data);
}

export async function PostAdvert(advertId: number, data: AdvertEdit, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>(`api/JobPosting/${advertId}/Post`, options, data);
}

export async function GetAdvertsJobBoards(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    }

    return Get<JobBoard[]>('api/JobPosting/JobBoards/All', options);
}

export async function UpdateAdvertsJobBoard(jobBoardId: number, updateData: JobBoardUpdateData, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        noDataReturned: true,
        errorHandler,
    };

    return Put<boolean>(`api/JobPosting/JobBoards/${jobBoardId}`, options, updateData);
}

export async function GetUserQuotas(jobBoardId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<UserAdvertQuotas[]>(`api/JobPosting/UserAdQuotas/${jobBoardId}`, options);
}

export async function GetNewAdvertsJobBoards(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<JobBoard[]>('api/JobPosting/JobBoards/Add', options);
}

export async function AddNewAdvertsJobBoards(jobBoardIds: NumberArrayWrapper, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>('api/JobPosting/JobBoards/Add', options, jobBoardIds);
}

export async function GetNotificationTemplate(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<AdvertsNotificationTemplate>('api/JobPosting/NotificationTemplate', options);
}

export async function SaveNotificationTemplate(template: AdvertsNotificationTemplate, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    }

    return Post<boolean>('api/JobPosting/NotificationTemplate', options, template);
}

export async function GetScreeningQuestionnaireTemplates(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    }

    return Get<ScreeningQuestionnaireTemplate[]>('api/jobposting/screeningquestionnairetemplates/all', options);
}

export async function GetScreeningQuestionnaireTemplate(id: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<ScreeningQuestionnaireTemplate>(`api/jobposting/screeningquestionnairetemplates/${id}`, options);
}

export async function CreateScreeningquestionnaireTemplate(template: ScreeningQuestionnaireTemplate, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>('api/jobposting/screeningquestionnairetemplates', options, template);
}

export async function UpdateScreeningQuestionnaireTemplate(id: number, template: ScreeningQuestionnaireTemplate, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/jobposting/screeningquestionnairetemplates/${id}`, options, template);
}

export async function CopyScreeningQuestionnaireTemplate(templateID: number, newName: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>(`api/jobposting/screeningquestionnairetemplates/${templateID}/copy`, options, { value: newName });
}

export async function GetReports(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Report[]>('api/jobposting/reports', options);
}

export async function GetAdvertsAnalysisReportData(start_date: string, end_date: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<any>(`api/jobposting/reports/analysis?startDate=${start_date}&endDate=${end_date}`, options);
}

export async function GetAdvertsAnalysisSnapshotReportData(start_date: string, end_date: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<any>(`api/jobposting/reports/analysis-snapshot?startDate=${start_date}&endDate=${end_date}`, options);
}

export async function GetApplicationDocsByApplicationId(applicationId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<ApplicationDoc[]>(`api/jobposting/applications/${applicationId}/documents`, options);
}

export async function SaveAdvertsSearch(searchName: string, searchFrequency: number, data: AdvertSearch, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<NumberValueWrapper>(`api/jobposting/search/${searchFrequency}?name=${encodeURIComponent(searchName)}`, options, data);
}

export async function UpdateAdvertsSavedSearch(ssid: number, searchName: string, searchFrequency: number, data: AdvertSearch, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/jobposting/search/${ssid}?freqId=${searchFrequency}&name=${encodeURIComponent(searchName)}`, options, data);
}

export async function ApproveAdvert(advertId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/jobposting/${advertId}/approve`, options);
}