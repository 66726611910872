import React, { useCallback, useEffect, useState } from "react";
import TextField, { TextFieldVariants } from "@mui/material/TextField";

interface Props {
	name?: string,
	label?: string,
	value: string,
	multiline?: boolean,
	sxOptions?: any,
	isError?: boolean,
	variant?: string,
	rows?: number,
	type?: string,
	minRows?: number,
	helperText?: React.ReactNode
	disabled?: boolean,
	placeholder?: string,
	autofocus?: boolean
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
	validator?: (text: string) => boolean,
}


export default function RWTextFieldComponent({ label = '', autofocus = false, placeholder, disabled = false, helperText, variant = "outlined", minRows, type = "text", rows, sxOptions, multiline = false, name, value, isError = false, onChange, validator }: Props) {
	const [inputValue, setInputValue] = useState(value);

	useEffect(() => {
		setInputValue(value);
	}, [value]);

	const localChangeHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		const v = e.target.value;
		if (validator) {
			validator(v) && setInputValue(v);
			return;
		}
		setInputValue(v);
	}, [validator]);

	return (
		<>
			<TextField
				autoFocus={autofocus}
				disabled={disabled}
				placeholder={placeholder}
				helperText={helperText}
				type={type}
				minRows={minRows}
				variant={variant as TextFieldVariants}
				sx={sxOptions}
				name={name}
				label={label}
				value={inputValue}
				onChange={localChangeHandler}
				onMouseOut={disabled ? undefined : () => onChange({ target: { value: inputValue, name: name } } as React.ChangeEvent<HTMLInputElement>)}
				onBlur={disabled ? undefined : () => onChange({ target: { value: inputValue, name: name } } as React.ChangeEvent<HTMLInputElement>)}
				fullWidth
				rows={rows}
				multiline={multiline}
				error={isError}
			/>
		</>
	);
}
