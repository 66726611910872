import React, { useCallback, useEffect, useMemo, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import MenuItem from "@mui/material/MenuItem";
import { GetJobById, UpdateJob } from "services/JobsService";

interface Props {
    open: boolean,
    jobId: number,
    type: string,
    closeHandler: () => void,
    loadingHandler?: (isLoading: boolean) => void,
    successHandler?: (message: string, newType: string) => void,
    errorHandler?: (message: string) => void
}

export default function ChangeTypeDialog({ open, jobId, type, closeHandler, loadingHandler, successHandler, errorHandler }: Props) {

    const [stateType, setStateType] = useState('');

    useEffect(() => {
        if (open) {
            setStateType(type);
        }
    }, [open, type]);

    const submitCallback = useCallback(async () => {
        loadingHandler && loadingHandler(true);

        let job = await GetJobById(jobId);
        if (job) {
            job.type = stateType;

            const res = await UpdateJob(jobId, job, errorHandler);

            if (res) {
                successHandler && successHandler('Job Type Updated', stateType);
                closeHandler();
            }
        }

        loadingHandler && loadingHandler(false);
    }, [jobId, stateType, loadingHandler, successHandler, errorHandler, closeHandler]);
    
    const hasChanges = useMemo(() => {
        return stateType !== type;
    }, [stateType, type]);

    return (
        <>
            <Dialog open={open} fullWidth>
                <DialogTitle>Change Type</DialogTitle>
                <DialogContent>
                    <Stack mt="10px" spacing={2}>
                            <TextField
                                select
                                value={stateType}
                                label="Type"
                                onChange={({ target }) => setStateType(target.value)}
                            >
                                <MenuItem value="Contract">Contract</MenuItem>
                                <MenuItem value="FixedContract">Fixed Term</MenuItem>
                                <MenuItem value="TalentPool">Talent Pool</MenuItem>
                                <MenuItem value="Panel">Panel</MenuItem>
                                <MenuItem value="Permanent">Permanent</MenuItem>
                            </TextField>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="error" onClick={ closeHandler }>Cancel</Button>
                    <Button variant="contained" color="success" disabled={!hasChanges} onClick={ submitCallback } >Submit</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}