import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import React, { useCallback, useEffect, useMemo } from "react";

interface Props {
    jobBoardId: number,
    advertId: number,
    attributes?: Record<string, string>,
    advertRecordAttributes?: Record<string, string>,
    attributeChangeHandler?: (jobBoardId: number, key: string, value: string) => void
}

export default function LinkedInJobWrappingEdit({ jobBoardId, advertId, attributes, advertRecordAttributes, attributeChangeHandler }: Props) {

    const atts = useMemo<Record<string, string>>(() => {
        if (attributes) return attributes;
        return {};
    }, [attributes]);

    const recordAtts = useMemo<Record<string, string>>(() => {
        if (advertRecordAttributes) return advertRecordAttributes;
        return {};
    }, [advertRecordAttributes]);

    const country = useMemo(() => recordAtts.country ?? '', [recordAtts.country]);
    const location = useMemo(() => recordAtts.location ?? '', [recordAtts.location]);
    const workType = useMemo(() => recordAtts.workType ?? '', [recordAtts.workType]);
    const displaySalary = useMemo(() => recordAtts.displaySalary ?? '', [recordAtts.displaySalary]);

    useEffect(() => attributeChangeHandler && attributeChangeHandler(jobBoardId, 'Country', country), [attributeChangeHandler, country, jobBoardId]);
    useEffect(() => attributeChangeHandler && attributeChangeHandler(jobBoardId, 'Location', location), [attributeChangeHandler, location, jobBoardId]);
    useEffect(() => attributeChangeHandler && attributeChangeHandler(jobBoardId, 'JobType', workType), [attributeChangeHandler, workType, jobBoardId]);
    useEffect(() => attributeChangeHandler && attributeChangeHandler(jobBoardId, 'Salary', displaySalary), [attributeChangeHandler, displaySalary, jobBoardId]);

    const jobFunction = useMemo(() => atts.JobFunction ?? '', [atts.JobFunction]);
    const industry = useMemo(() => atts.Industry ?? '', [atts.Industry]);
    const experienceLevel = useMemo(() => atts.ExperienceLevel ?? '', [atts.ExperienceLevel]);

    const localChangeHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        attributeChangeHandler && attributeChangeHandler(jobBoardId, name, value);
    }, [attributeChangeHandler, jobBoardId]);

    return (
        <Box display="flex" gap={2} pt={2}>
            <Box display="flex" flexDirection="column" gap={2} width="50%">
                <TextField select label="Job Function" name="JobFunction" value={jobFunction} onChange={localChangeHandler} >
                    <MenuItem value=""></MenuItem>
                    <MenuItem value="acct">Accounting / Auditing</MenuItem>
                    <MenuItem value="adm">Administrative</MenuItem>
                    <MenuItem value="advr">Advertising</MenuItem>
                    <MenuItem value="anls">Analyst</MenuItem>
                    <MenuItem value="art">Art / Creative</MenuItem>
                    <MenuItem value="bd">Business Development</MenuItem>
                    <MenuItem value="cnsl">Consulting</MenuItem>
                    <MenuItem value="cust">Customer Service</MenuItem>
                    <MenuItem value="dsgn">Design</MenuItem>
                    <MenuItem value="dist">Distribution</MenuItem>
                    <MenuItem value="edu">Education</MenuItem>
                    <MenuItem value="eng">Engineering</MenuItem>
                    <MenuItem value="fin">Finance</MenuItem>
                    <MenuItem value="genb">General Business</MenuItem>
                    <MenuItem value="hcpr">Health Care Provider</MenuItem>
                    <MenuItem value="hr">Human Resources</MenuItem>
                    <MenuItem value="it">Information Technology</MenuItem>
                    <MenuItem value="lgl">Legal</MenuItem>
                    <MenuItem value="mgmt">Management</MenuItem>
                    <MenuItem value="mnfc">Manufacturing</MenuItem>
                    <MenuItem value="mrkt">Marketing</MenuItem>
                    <MenuItem value="othr">Other</MenuItem>
                    <MenuItem value="prdm">Product Management</MenuItem>
                    <MenuItem value="prod">Production</MenuItem>
                    <MenuItem value="prjm">Project Management</MenuItem>
                    <MenuItem value="pr">Public Relations</MenuItem>
                    <MenuItem value="prch">Purchasing</MenuItem>
                    <MenuItem value="qa">Quality Assurance</MenuItem>
                    <MenuItem value="rsch">Research</MenuItem>
                    <MenuItem value="sale">Sales</MenuItem>
                    <MenuItem value="sci">Science</MenuItem>
                    <MenuItem value="stra">Strategy / Planning</MenuItem>
                    <MenuItem value="supl">Supply Chain</MenuItem>
                    <MenuItem value="trng">Training</MenuItem>
                    <MenuItem value="wrt">Writing / Editing</MenuItem>
                </TextField>
                <TextField select label="Industry" name="Industry" value={industry} onChange={localChangeHandler} >
                    <MenuItem value=""></MenuItem>
                    <MenuItem value="47">Accounting</MenuItem>
                    <MenuItem value="94">Airlines/Aviation</MenuItem>
                    <MenuItem value="120">Alternative Dispute Resolution</MenuItem>
                    <MenuItem value="125">Alternative Medicine</MenuItem>
                    <MenuItem value="127">Animation</MenuItem>
                    <MenuItem value="19">Apparel & Fashion</MenuItem>
                    <MenuItem value="50">Architecture & Planning</MenuItem>
                    <MenuItem value="111">Arts and Crafts</MenuItem>
                    <MenuItem value="53">Automotive</MenuItem>
                    <MenuItem value="52">Aviation & Aerospace</MenuItem>
                    <MenuItem value="41">Banking</MenuItem>
                    <MenuItem value="12">Biotechnology</MenuItem>
                    <MenuItem value="36">Broadcast Media</MenuItem>
                    <MenuItem value="49">Building Materials</MenuItem>
                    <MenuItem value="138">Business Supplies and Equipment</MenuItem>
                    <MenuItem value="129">Capital Markets</MenuItem>
                    <MenuItem value="54">Chemicals</MenuItem>
                    <MenuItem value="90">Civic & Social Organization</MenuItem>
                    <MenuItem value="51">Civil Engineering</MenuItem>
                    <MenuItem value="128">Commercial Real Estate</MenuItem>
                    <MenuItem value="118">Computer & Network Security</MenuItem>
                    <MenuItem value="109">Computer Games</MenuItem>
                    <MenuItem value="3">Computer Hardware</MenuItem>
                    <MenuItem value="5">Computer Networking</MenuItem>
                    <MenuItem value="4">Computer Software</MenuItem>
                    <MenuItem value="48">Construction</MenuItem>
                    <MenuItem value="24">Consumer Electronics</MenuItem>
                    <MenuItem value="25">Consumer Goods</MenuItem>
                    <MenuItem value="91">Consumer Services</MenuItem>
                    <MenuItem value="18">Cosmetics</MenuItem>
                    <MenuItem value="65">Dairy</MenuItem>
                    <MenuItem value="1">Defense & Space</MenuItem>
                    <MenuItem value="99">Design</MenuItem>
                    <MenuItem value="132">E-Learning</MenuItem>
                    <MenuItem value="69">Education Management</MenuItem>
                    <MenuItem value="112">Electrical/Electronic Manufacturing</MenuItem>
                    <MenuItem value="28">Entertainment</MenuItem>
                    <MenuItem value="86">Environmental Services</MenuItem>
                    <MenuItem value="110">Events Services</MenuItem>
                    <MenuItem value="76">Executive Office</MenuItem>
                    <MenuItem value="122">Facilities Services</MenuItem>
                    <MenuItem value="63">Farming</MenuItem>
                    <MenuItem value="43">Financial Services</MenuItem>
                    <MenuItem value="38">Fine Art</MenuItem>
                    <MenuItem value="66">Fishery</MenuItem>
                    <MenuItem value="34">Food & Beverages</MenuItem>
                    <MenuItem value="23">Food Production</MenuItem>
                    <MenuItem value="101">Fund</MenuItem>
                    <MenuItem value="26">Furniture</MenuItem>
                    <MenuItem value="29">Gambling & Casinos</MenuItem>
                    <MenuItem value="145">Glass, Ceramics & Concrete</MenuItem>
                    <MenuItem value="75">Government Administration</MenuItem>
                    <MenuItem value="148">Government Relations</MenuItem>
                    <MenuItem value="140">Graphic Design</MenuItem>
                    <MenuItem value="124">Health, Wellness and Fitness</MenuItem>
                    <MenuItem value="68">Higher Education</MenuItem>
                    <MenuItem value="14">Hospital & Health Care</MenuItem>
                    <MenuItem value="31">Hospitality</MenuItem>
                    <MenuItem value="137">Human Resources</MenuItem>
                    <MenuItem value="134">Import and Export</MenuItem>
                    <MenuItem value="88">Individual & Family Services</MenuItem>
                    <MenuItem value="147">Industrial Automation</MenuItem>
                    <MenuItem value="84">Information Services</MenuItem>
                    <MenuItem value="96">Information Technology and Services</MenuItem>
                    <MenuItem value="42">Insurance</MenuItem>
                    <MenuItem value="74">International Affairs</MenuItem>
                    <MenuItem value="141">International Trade and Development 6 Internet</MenuItem>
                    <MenuItem value="45">Investment Banking</MenuItem>
                    <MenuItem value="46">Investment Management</MenuItem>
                    <MenuItem value="73">Judiciary</MenuItem>
                    <MenuItem value="77">Law Enforcement 9 Law Practice</MenuItem>
                    <MenuItem value="10">Legal Services</MenuItem>
                    <MenuItem value="72">Legislative Office</MenuItem>
                    <MenuItem value="30">Leisure, Travel & Tourism</MenuItem>
                    <MenuItem value="85">Libraries</MenuItem>
                    <MenuItem value="116">Logistics and Supply Chain</MenuItem>
                    <MenuItem value="143">Luxury Goods & Jewelry</MenuItem>
                    <MenuItem value="55">Machinery</MenuItem>
                    <MenuItem value="11">Management Consulting</MenuItem>
                    <MenuItem value="95">Maritime</MenuItem>
                    <MenuItem value="97">Market Research</MenuItem>
                    <MenuItem value="80">Marketing and Advertising</MenuItem>
                    <MenuItem value="135">Mechanical or Industrial Engineering</MenuItem>
                    <MenuItem value="126">Media Production</MenuItem>
                    <MenuItem value="17">Medical Devices</MenuItem>
                    <MenuItem value="13">Medical Practice</MenuItem>
                    <MenuItem value="139">Mental Health Care</MenuItem>
                    <MenuItem value="71">Military</MenuItem>
                    <MenuItem value="56">Mining & Metals</MenuItem>
                    <MenuItem value="35">Motion Pictures and Film</MenuItem>
                    <MenuItem value="37">Museums and Institutions</MenuItem>
                    <MenuItem value="115">Music</MenuItem>
                    <MenuItem value="114">Nanotechnology</MenuItem>
                    <MenuItem value="81">Newspapers</MenuItem>
                    <MenuItem value="100">Non</MenuItem>
                    <MenuItem value="57">Oil & Energy</MenuItem>
                    <MenuItem value="113">Online Media</MenuItem>
                    <MenuItem value="123">Outsourcing/Offshoring</MenuItem>
                    <MenuItem value="87">Package/Freight Delivery</MenuItem>
                    <MenuItem value="146">Packaging and Containers</MenuItem>
                    <MenuItem value="61">Paper & Forest Products</MenuItem>
                    <MenuItem value="39">Performing Arts</MenuItem>
                    <MenuItem value="15">Pharmaceuticals</MenuItem>
                    <MenuItem value="131">Philanthropy</MenuItem>
                    <MenuItem value="136">Photography</MenuItem>
                    <MenuItem value="117">Plastics</MenuItem>
                    <MenuItem value="107">Political Organization</MenuItem>
                    <MenuItem value="67">Primary/Secondary Education</MenuItem>
                    <MenuItem value="83">Printing</MenuItem>
                    <MenuItem value="105">Professional Training & Coaching</MenuItem>
                    <MenuItem value="102">Program Development</MenuItem>
                    <MenuItem value="79">Public Policy</MenuItem>
                    <MenuItem value="98">Public Relations and Communications</MenuItem>
                    <MenuItem value="78">Public Safety</MenuItem>
                    <MenuItem value="82">Publishing</MenuItem>
                    <MenuItem value="62">Railroad Manufacture</MenuItem>
                    <MenuItem value="64">Ranching</MenuItem>
                    <MenuItem value="44">Real Estate</MenuItem>
                    <MenuItem value="40">Recreational Facilities and Services 89 Religious Institutions</MenuItem>
                    <MenuItem value="144">Renewables & Environment</MenuItem>
                    <MenuItem value="70">Research</MenuItem>
                    <MenuItem value="32">Restaurants</MenuItem>
                    <MenuItem value="27">Retail</MenuItem>
                    <MenuItem value="121">Security and Investigations 7 Semiconductors</MenuItem>
                    <MenuItem value="58">Shipbuilding</MenuItem>
                    <MenuItem value="20">Sporting Goods</MenuItem>
                    <MenuItem value="33">Sports</MenuItem>
                    <MenuItem value="104">Staffing and Recruiting</MenuItem>
                    <MenuItem value="22">Supermarkets 8 Telecommunications</MenuItem>
                    <MenuItem value="60">Textiles</MenuItem>
                    <MenuItem value="130">Think Tanks</MenuItem>
                    <MenuItem value="21">Tobacco</MenuItem>
                    <MenuItem value="108">Translation and Localization</MenuItem>
                    <MenuItem value="92">Transportation/Trucking/Railroad</MenuItem>
                    <MenuItem value="59">Utilities</MenuItem>
                    <MenuItem value="106">Venture Capital & Private Equity</MenuItem>
                    <MenuItem value="16">Veterinary</MenuItem>
                    <MenuItem value="93">Warehousing</MenuItem>
                    <MenuItem value="133">Wholesale</MenuItem>
                    <MenuItem value="142">Wine and Spirits</MenuItem>
                    <MenuItem value="119">Wireless</MenuItem>
                    <MenuItem value="103">Writing and Editing</MenuItem>
                </TextField>
                <TextField disabled label="Country" value={country} />
                <TextField disabled label="Location" value={location} />
            </Box>
            <Box display="flex" flexDirection="column" gap={2} width="50%">
                <TextField disabled label="Work Type" value={workType} />
                <TextField select label="Experience Level" name="ExperienceLevel" value={experienceLevel} onChange={localChangeHandler} >
                    <MenuItem value=""></MenuItem>
                    <MenuItem value="3">Associate</MenuItem>
                    <MenuItem value="5">Director</MenuItem>
                    <MenuItem value="2">Entry level</MenuItem>
                    <MenuItem value="6">Executive</MenuItem>
                    <MenuItem value="1">Internship</MenuItem>
                    <MenuItem value="4">Mid-Senior level</MenuItem>
                    <MenuItem value="0">Not Applicable</MenuItem>
                </TextField>
                <TextField disabled label="Display Salary" value={displaySalary} />
            </Box>
        </Box>
    );
}