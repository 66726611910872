import React, { useMemo, useState } from "react";
import { NavLink } from "react-router-dom";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import useTheme from "@mui/material/styles/useTheme";

import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';

import { TopBarRoute } from "common/models/TopBarTypes";
import { ListItemText } from "@mui/material";

interface Props {
    routes?: TopBarRoute[]
    prefix?: string,
    nestLevel?: number,
    ignoreRoutes?: string[],
}

export default function SideNav({ routes, prefix, nestLevel = 0, ignoreRoutes } : Props) {
    const [expandedIndex, setExpandedIndex] = useState<number | null>(null);
    const theme = useTheme();

    const leftPadding = useMemo(() => {
        return (2 * nestLevel) + 2;
    }, [nestLevel]);

    return (
        <List sx={{ py: nestLevel === 0 ? undefined : 0 }}>
            {(routes && routes.length > 0) &&
                routes.map((opt, index) => {
                    let shouldBeIgnored = false;
                    if (opt.path !== '' && ignoreRoutes && ignoreRoutes.length > 0) shouldBeIgnored = ignoreRoutes.includes(opt.path);
                    if (!shouldBeIgnored && (!opt.allow || opt.allow())) {
                        const hasPath = Boolean(opt.path);
                        return (
                            <React.Fragment key={index}>
                                <ListItem disableGutters disablePadding >
                                    {hasPath &&
                                        <NavLink
                                            to={prefix ? prefix + opt.path : opt.path }
                                            style={ ({ isActive }) => ({
                                                color: (isActive && opt.path === document.location.pathname) ? 'inherit' : theme.palette.text.secondary,
                                                textDecoration: 'none',
                                                flexBasis: '100%'
                                            }) }
                                        >
                                            <ListItemButton sx={{ pl: leftPadding }}>
                                                <ListItemText primary={opt.text} sx={{ my: 0 }} />
                                            </ListItemButton>
                                        </NavLink>
                                    }
                                    {!hasPath &&
                                        <ListItemButton onClick={ () => setExpandedIndex(prev => prev === index ? null : index)} sx={{ color: expandedIndex === index ? 'inherit' : theme.palette.text.secondary, pl: leftPadding }}>
                                            <ListItemText primary={opt.text} sx={{ my: 0 }} />
                                            {opt.options && (expandedIndex === index ? <ExpandLess /> : <ExpandMore />)}
                                        </ListItemButton>
                                    }
                                </ListItem>
                                {opt.options &&
                                    <Collapse in={index === expandedIndex}>
                                        <List disablePadding>
                                            {opt.options.map((subOpt, subIndex) => {
                                                let shouldBeIgnored2 = false;
                                                if (subOpt.path !== '' && ignoreRoutes && ignoreRoutes.length > 0) shouldBeIgnored2 = ignoreRoutes.includes(subOpt.path);
                                                if (shouldBeIgnored2 || (subOpt.allow && !subOpt.allow())) return <React.Fragment key={subIndex} />
                                                if (subOpt.options && subOpt.options.length > 0) return <SideNav key={subIndex} routes={[subOpt]} nestLevel={nestLevel + 1} />

                                                return (
                                                    <ListItem key={subIndex} disableGutters disablePadding>
                                                        <NavLink
                                                            to={subOpt.path}
                                                            style={ ({ isActive }) => ({
                                                                color: (isActive && subOpt.path === document.location.pathname) ? 'inherit' : theme.palette.text.secondary,
                                                                textDecoration: 'none',
                                                                flexBasis: '100%'
                                                            }) }
                                                        >
                                                            <ListItemButton sx={{ pl: leftPadding + 2 }}>
                                                                {subOpt.text}
                                                            </ListItemButton>
                                                        </NavLink>
                                                    </ListItem>
                                                );
                                            })
                                            }
                                        </List>
                                    </Collapse>
                                }
                            </React.Fragment>
                        );
                    }

                    return <React.Fragment key={index} />
                })
            }
        </List>
    );
}