import Button from "@mui/material/Button";
import { GridColDef, GridRenderCellParams, GridRowSelectionModel, GridValueGetter, useGridApiRef } from "@mui/x-data-grid-premium";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import GridWithStateWrapper from "components/GridWidthStateWrapper";
import PageContentLayout from "layouts/PageContentLayout";
import PageLayout from "layouts/PageLayout";
import { CopyDocumentTemplate, DeleteDocumentTemplates, GetDocumentTemplates } from "services/TemplatesService";
import MenuItem from "@mui/material/MenuItem";
import { Link } from "react-router-dom";
import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import Snackbar from "@mui/material/Snackbar";
import Alert from "components/Alert";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import ActionsDropDownButton from "components/SummaryBars/Actions/ActionsDropsDownMenu";
import { DocumentTemplate } from "common/models/Templates/DocumentTemplate";
import ConfirmationDialog from "components/Dialogs/Generic/ConfirmationDialog";
import RWTextFieldComponent from "components/RWTextFieldComponent";

const createTemplateLink = <Link to="/account/templates/documents/0" style={{ color: 'inherit', textDecoration: 'none', padding: '6px 16px', flexGrow: 1 }} >Add Template</Link>;

export default function AccountTemplatesDocumentsPage() {
    const [summaryBar, setSummaryBar] = useState<JSX.Element>(<></>);
    const [isLoading, setIsLoading] = useState(false);
    const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
    const [rows, setRows] = useState<DocumentTemplate[]>([]);
    const [copyTemplateName, setCopyTemplateName] = useState('');
    const apiRef = useGridApiRef();

    useEffect(() => {
        const handleCopyTemplate = () => {
            const selectedId = +selectionModel[0];
            const row = rows.find(r => r.id === selectedId);
            if(row) setCopyTemplateName(row.name);
        }
        const actionsMenu = (
            <ActionsDropDownButton>
                <MenuItem sx={{ p: 0 }}>{createTemplateLink}</MenuItem>
                <MenuItem onClick={handleCopyTemplate} disabled={ selectionModel.length !== 1 } >Copy Template</MenuItem>
                <MenuItem onClick={() => setShowConfirmDeleteDialog(true)} disabled={selectionModel.length === 0}>Delete</MenuItem>
            </ActionsDropDownButton>
        );
        const SummaryBar = (
            <TitleAndActionSummaryBar
                title="Account > Templates > Documents"
                browserTabTitle="Documents > Templates"
                action={actionsMenu}
            />
        );
        setSummaryBar(SummaryBar);
    }, [rows, selectionModel]);

    const getRows = useCallback(async () => {
        setIsLoading(true);
        const data = await GetDocumentTemplates(false, undefined, undefined, setErrorMessage);
        if (data) setRows(data);
        setIsLoading(false);
    }, []);

    const handleDeleteTemplates = useCallback(async () => {
        setIsLoading(true);
        const res = await DeleteDocumentTemplates(selectionModel as number[], setErrorMessage);
        if (res) {
            setShowConfirmDeleteDialog(false);
            getRows();
        }
        setIsLoading(false);
    }, [selectionModel, getRows]);

    useEffect(() => {
        getRows();
    }, [getRows]);

    const columns = useMemo<GridColDef[]>(() => {
        const linkToTemplateRenderer = (params: GridRenderCellParams) => {
            return (
                <Link
                    to={'/account/templates/documents/' + params.id}
                    style={{ color: 'inherit', textDecoration: 'underline' }}
                >
                    {params.value}
                </Link>
            );
        };

        const typeValueGetter: GridValueGetter<DocumentTemplate, any, undefined, number> = (value) => {
            switch (value) {
                case 1: return "Formatted CV";
                case 2: return "Placement Docs - Clients";
                case 3: return "Placement Docs - Candidates";
                case 4: return "General";
                case 5: return "Reference Check";
                case 6: return "Client Docs";
                case 7: return "Contact Docs";
                case 8: return "Candidate Docs";
                case 9: return "Job Docs";
                case 10: return "Opportunity Docs";      
                case 11: return "Screening";
                case 12: return "Reference Check";
                default: return "Error";
            }
        };

        const statusValueGetter: GridValueGetter<DocumentTemplate, any, undefined, number> = (value) => {
            switch (value) {
                case 0: return "Draft";
                case 1: return "Published";
                case 2: return "Archived";
                default: return "Error";
            }
        };

        const isEsignEnabledValueGetter: GridValueGetter<DocumentTemplate, any, undefined, boolean> = (value) => {
            return value ? 'Yes' : 'No';           
        }

        const ownerValueGetter: GridValueGetter<DocumentTemplate, any, undefined, any> = (value, row) => {
            if (row.ownerID === 0) return 'Company Owned';
            return value;
        }

        return [
            { field: 'id', headerName: 'ID', renderCell: linkToTemplateRenderer },
            { field: 'name', headerName: 'Name', width: 400, renderCell: linkToTemplateRenderer },
            { field: 'typeID', headerName: 'Type', width: 300, valueGetter: typeValueGetter },
            { field: 'statusID', headerName: 'Status', width: 300, valueGetter: statusValueGetter },
            { field: 'isElectronicSigningEnabled', headerName: 'eSign Enabled?', width: 150, valueGetter: isEsignEnabledValueGetter },
            { field: 'ownerName', headerName: 'Owner', width: 200, valueGetter: ownerValueGetter },
        ]
    }, []);

    // const selectionChangeHandler = (newSelectionModel: GridRowSelectionModel) => {
    //     setSelectionModel(prev => {
    //         const prevLength = prev.length;
    //         const newLength = newSelectionModel.length;

    //         if(prevLength === 0 && newLength > 1)
    //             return [newSelectionModel[0]];

    //         if(prevLength === 1 && newLength > 0)
    //             return [newSelectionModel[newLength - 1]]
    //         return newSelectionModel;
    //     });
    // };

    const copyTemplateConfirmCallback = useCallback(async () => {
        setIsLoading(true);
        const templateId = +selectionModel[0];
        const res = await CopyDocumentTemplate(templateId, copyTemplateName, setErrorMessage);
        if (res) {
            setCopyTemplateName('');
            setSelectionModel([]);
            setSuccessMessage('Copy created');
            await getRows();
        }
        setIsLoading(false);
    }, [copyTemplateName, selectionModel, getRows]);
    
    return (
        <PageLayout SummaryBar={summaryBar}>
            <Snackbar open={successMessage !== ''} autoHideDuration={3000} onClose={() => setSuccessMessage('')}>
                <Alert onClose={() => setSuccessMessage('')}>{successMessage}</Alert>
            </Snackbar>
            <Snackbar open={errorMessage !== ''} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            { copyTemplateName !== '' &&
                <Dialog open={copyTemplateName !== ''} fullWidth>
                    <DialogTitle>Confirm</DialogTitle>
                    <DialogContent dividers>
                        <RWTextFieldComponent
                            label="New template name"
                            autofocus={true}
                            value={copyTemplateName}
                            onChange={({target}) => setCopyTemplateName(target.value)}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="error" onClick={() => setCopyTemplateName('')}>Cancel</Button>
                        <Button variant="contained" color="success" onClick={ copyTemplateConfirmCallback }>OK</Button>
                    </DialogActions>
                </Dialog>
            }
            <ConfirmationDialog
                message={`Are you sure you want to delete the selected (${selectionModel.length}) templates?`}
                onClose={() => setShowConfirmDeleteDialog(false)}
                onContinue={handleDeleteTemplates}
                open={showConfirmDeleteDialog}
                title="Confirm Action"
            />
            <PageContentLayout showLoading={isLoading}>
                <GridWithStateWrapper
                    gridName="DocumentTemplates"
                    apiRef={apiRef}
                    rows={rows}
                    columns={columns}
                    disableRowSelectionOnClick
                    checkboxSelection
                    pagination
                    density="compact"
                    rowSelectionModel={selectionModel}
                    onRowSelectionModelChange={sm => setSelectionModel(sm)}
                    pageSizeOptions={[100,250,500,1000]}
                    
                />
            </PageContentLayout>
        </PageLayout>
    );
}