import React, { useCallback, useEffect, useMemo, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import { GridCellEditStopParams, GridCellEditStopReasons, GridColDef, GridRenderCellParams, GridRenderEditCellParams, GridRowSelectionModel, GridColumnVisibilityModel, GridValueGetter, useGridApiRef } from "@mui/x-data-grid-premium";

import { TaxRate } from "../../../common/models/Invoices/TaxRate";
import TitleAndActionSummaryBar from "../../../components/SummaryBars/TitleAndActionSummaryBar";
import PageContentLayout from "../../../layouts/PageContentLayout";
import { GetAllTaxRates, CreateTaxRate, UpdateTaxRate } from "../../../services/InvoiceService";
import GridWithStateWrapper from "../../../components/GridWidthStateWrapper";
import UnsavedChangesDialog from "../../../components/Dialogs/UnsavedChangesDialog";
import Autocomplete from "@mui/material/Autocomplete";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

interface Props {
    setSummaryBar?: (summaryBar: JSX.Element) => void
}

export default function TaxRates({ setSummaryBar }: Props) {
    useEffect(() => { setSummaryBar && setSummaryBar(SummaryBar); }, [setSummaryBar]);
    const [isLoading, setIsLoading] = useState(false);
    const [rows, setRows] = useState<TaxRate[]>([]);
    const [initialDialogData, setInitialDialogData] = useState<TaxRate | null>(null);
    const [dialogData, setDialogData] = useState<TaxRate | null>(null);
    const [showUnsavedChangesDialog, setShowUnsavedChangesDialog] = useState(false);
    const apiRef = useGridApiRef();

    useEffect(() => {
        const getData = async () => {
            setIsLoading(true);
            const data = await GetAllTaxRates();
            if(data) setRows(data);

            setIsLoading(false);
        };
        getData();
    }, []);

    function newItem() {
        // Create a new TaxRate object
        const newTaxRate: TaxRate = {
            id: 0,
            name: '',
            rate: 0,
            statusID: 1,
            statusName: "Active"
        };
        setDialogData(newTaxRate);
        setInitialDialogData(newTaxRate);
    }

    const addTaxRateButton = <Button variant="contained" color="success" onClick={() => newItem()}>Add Tax Rate</Button>

    const SummaryBar = (
        <TitleAndActionSummaryBar
            title="Configuration > Invoices > Tax Rates"
            browserTabTitle="Tax Rates > Invoices > Configuration"
            action={addTaxRateButton}
        />
    );


    const columns = useMemo<GridColDef[]>(() => {
        const dbNameRenderer = (params: GridRenderCellParams) => {
            return (
                <span
                    onClick={() => {
                        setDialogData(params.row);
                        setInitialDialogData(params.row);
                    }}
                    style={{ cursor: 'pointer', textDecoration: 'underline' }}
                >{params.value}</span>
            );
        };

        return [
            { field: 'id', headerName: 'ID' },
            { field: 'name', headerName: 'Name', width: 200, renderCell: dbNameRenderer },
            { field: 'rate', headerName: 'Rate', width: 200 },
            { field: 'statusName', headerName: 'Status', width: 200 }
        ];
    }, []);

    const onChangeString = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setDialogData(prev => ( prev ? {...prev, [name]: value} : null));
    };

    const onChangeNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        const numValue = +value;
        if(name === 'statusID') {
            const opt = numValue == 0 ? "Inactive" : "Active";
            setDialogData(prev => (prev ? { ...prev, [name]: numValue, statusName: opt } : null));
        }
        else
            setDialogData(prev => ( prev ? {...prev, [name]: numValue} : null));
    };

    const onSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = e.target;
        setDialogData(prev => ( prev ? {...prev, [name]: checked} : null));
    };

    const hasChanges = useMemo(() => {
        if (dialogData && initialDialogData) {
            if (dialogData.name !== initialDialogData.name) return true;
            if (dialogData.rate !== initialDialogData.rate) return true;
            if (dialogData.statusID !== initialDialogData.statusID) return true;
        }
        return false;
    }, [dialogData, initialDialogData]);

    const validChanges = useMemo(() => {
        if (dialogData && initialDialogData) {
            if (dialogData.name !== "") return true;
        }
        return false;
    }, [dialogData, initialDialogData]);

    const closeEditDialogHandler = useCallback(() => {
        setDialogData(null);
        setInitialDialogData(null);
        setShowUnsavedChangesDialog(false);
    }, []);

    const saveChanges = useCallback(async () => {
        if(dialogData){
            setIsLoading(true);

            if (dialogData.id === 0) {
                await CreateTaxRate(dialogData);

                const data = await GetAllTaxRates();
                if (data) setRows(data);
            }
            else {
                const res = await UpdateTaxRate(dialogData);

                if (res) {
                    apiRef.current.updateRows([dialogData]);
                }
            }
            closeEditDialogHandler();
            setIsLoading(false);
        }
    }, [dialogData, apiRef, closeEditDialogHandler]);

    const closeHandlerWithConfirmation = useMemo(() => hasChanges ? () => setShowUnsavedChangesDialog(true) : closeEditDialogHandler, [hasChanges, closeEditDialogHandler]);

    return (
        <>
            { dialogData &&
                <Dialog open={true} maxWidth="md" fullWidth onClose={ closeHandlerWithConfirmation }>
                    <DialogTitle>Tax Rate</DialogTitle>
                    <DialogContent dividers>
                        <Grid container columnSpacing={2}>
                            <Grid item xs={12} md={8}>
                                <Stack spacing={3}>
                                    <TextField
                                        select
                                        label="Status"
                                        name="statusID"
                                        value={dialogData.statusID.toString()}
                                        onChange={onChangeNumber}
                                    >
                                        <MenuItem value="1">Active</MenuItem>
                                        <MenuItem value="0">Inactive</MenuItem>
                                    </TextField>
                                    <TextField
                                        label="Name"
                                        name="name"
                                        value={dialogData.name}
                                        onChange={onChangeString}
                                    />
                                    <TextField
                                        label="Rate"
                                        name="rate"
                                        value={dialogData.rate.toString()}
                                        onChange={onChangeNumber}
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="error" onClick={ closeHandlerWithConfirmation }>Cancel</Button>
                        <Button variant="contained" color="success" disabled={!validChanges} onClick={saveChanges}>Save</Button>
                    </DialogActions>
                </Dialog>
            }
            <UnsavedChangesDialog
                open={showUnsavedChangesDialog}
                discardChangesHandler={closeEditDialogHandler}
                cancelHandler={() => setShowUnsavedChangesDialog(false)}
                saveChangesHandler={saveChanges}
                message="If you continue any changes made will be lost. Are you sure you wish to continue?"
                hideBackdrop
            />
            <PageContentLayout title="Tax Rates" showLoading={isLoading}>
                <GridWithStateWrapper
                    gridName="configuration/tax-rates"
                    apiRef={apiRef}
                    rows={rows}
                    columns={columns}
                    disableRowSelectionOnClick
                    pagination={true}
                    density="compact"
                    pageSizeOptions={[100,250,500,1000]}
                />
            </PageContentLayout>
        </>
    );
}