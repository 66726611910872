import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import PageLayout from "layouts/PageLayout";
import Snackbar from "@mui/material/Snackbar";
import Alert from "components/Alert";
import PageContentLayout from "layouts/PageContentLayout";
import InterviewEditPageContent from "components/Content/InterviewEditPageContent";
import { GetMySettings } from "services/UsersService";
import useOnboarding from "hooks/UseOnboarding";
import InterviewsOnboardingDialog from "components/Dialogs/Onboarding/Interviews";

const getDistributionModeIdFromSettingValue = (value: string) => {
    if (value === 'CalendarInviteOnly') return 1;
    if (value === 'CalendarInvitePlusEmail') return 2;
    return 0;
}

export default function InterviewCreatePage() {
    const [summaryBar, setSummaryBar] = useState(<></>);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [searchParams] = useSearchParams();
    const [defaultLocationTypeId, setDefaultLocationTypeId] = useState(2);
    const [defaultClientDistributionModeId, setDefaultClientDistributionModeId] = useState(2);
    const [defaultCandidateDistributionModeId, setDefaultCandidateDistributionModeId] = useState(2);
    const [defaultDuration, setDefaultDuration] = useState(60);
    const [defaultLocation, setDefaultLocation] = useState('');
    const { isNeeded: isOnboardingNeeded, showDialog: showOnboardingDialog, setShowDialog: setShowOnboardingDialog } = useOnboarding('InterviewsOnboardingUtcDate');

    useEffect(() => {
        if (isOnboardingNeeded) setShowOnboardingDialog(true);
    }, [isOnboardingNeeded, setShowOnboardingDialog]);

    const candidateId = useMemo(() => {
        const tId = searchParams.get('candidateId');
        if (tId && !isNaN(+tId)) return +tId;
        return undefined;
    }, [searchParams]);

    const jobId = useMemo(() => {
        const tId = searchParams.get('jobId');
        if (tId && !isNaN(+tId)) return +tId;
        return undefined;
    }, [searchParams]);

    const typeId = useMemo(() => {
        const tId = searchParams.get('typeId');
        if (tId && !isNaN(+tId)) return +tId;
        return undefined;
    }, [searchParams]);

    useEffect(() => {
        const getSettings = async () => {
            const settings = await GetMySettings();
            if (settings) {
                for (let i = 0; i < settings.length; i++) {
                    const s = settings[i];
                    if (s.value && s.type === 'DefaultInterviewLocationTypeId') setDefaultLocationTypeId(+s.value);
                    if (s.value && s.type === 'DefaultDistributionMethod_InteriewsClients') setDefaultClientDistributionModeId(getDistributionModeIdFromSettingValue(s.value));
                    if (s.value && s.type === 'DefaultDistributionMethod_InteriewsCandidates') setDefaultCandidateDistributionModeId(getDistributionModeIdFromSettingValue(s.value));
                    if (s.value && s.type === 'DefaultDuration_Interviews') setDefaultDuration(+s.value);
                    if (s.value && s.type === 'DefaultInterviewLocation') setDefaultLocation(s.value);
                }
            }
        };
        getSettings();
    }, []);

    const onContinueOnboarding = useCallback((clientDistMethod: string, candidateDistMethod: string, locationType: string, duration: string) => {
        setDefaultClientDistributionModeId(getDistributionModeIdFromSettingValue(clientDistMethod));
        setDefaultCandidateDistributionModeId(getDistributionModeIdFromSettingValue(candidateDistMethod));
        setDefaultLocationTypeId(+locationType);
        setDefaultDuration(+duration);
        setShowOnboardingDialog(false);
    }, [setShowOnboardingDialog]);
    
    return (
        <PageLayout SummaryBar={summaryBar}>
            <Snackbar open={errorMessage !== ''}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <Snackbar open={successMessage !== ''} autoHideDuration={3000} onClose={() => setSuccessMessage('')} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert color="success" onClose={() => setSuccessMessage('')}>{ successMessage }</Alert>
            </Snackbar>
            <PageContentLayout showLoading={isLoading}>
                <InterviewsOnboardingDialog
                    open={showOnboardingDialog}
                    closeHandler={() => setShowOnboardingDialog(false)}
                    continueHandler={onContinueOnboarding}
                    loadingHandler={setIsLoading}
                />
                <InterviewEditPageContent
                    typeId={typeId}
                    jobId={jobId}
                    candidateId={candidateId}
                    defaultLocationTypeId={defaultLocationTypeId}
                    defaultLocation={defaultLocation}
                    defaultClientDistributionModeId={defaultClientDistributionModeId}
                    defaultCandidateDistributionModeId={defaultCandidateDistributionModeId}
                    defaultDuration={defaultDuration}
                    setSummaryBar={setSummaryBar}
                    errorHandler={setErrorMessage}
                    loadingHandler={setIsLoading}
                    successHandler={setSuccessMessage}
                />
            </PageContentLayout>
        </PageLayout>
    );
}