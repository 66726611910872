import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import TextField from "@mui/material/TextField";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Job } from "common/models/Jobs/Job";
import { CopyAdvert } from "services/AdvertsService";
import { GetJobById, GetMyTeamOpenJobs } from "services/JobsService";
import { useNavigate } from "react-router-dom";

interface Props {
    open: boolean,
    advertId: number | null,
    advertJobId: number | null,
    onClose: () => void,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
    successHandler?: (message: string) => void,
}

export default function CopyAdvertDialog({ open, advertId, advertJobId, onClose, loadingHandler, errorHandler, successHandler }: Props) {
    const [openJobs, setOpenJobs] = useState<Job[]>([]);
    const [currentJob, setCurrentJob] = useState<Job | null>(null);
    const [selectedJob, setSelectedJob] = useState<Job | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const getCurrentJob = async () => {
            if (advertJobId) {
                setIsLoading(true);
                const job = await GetJobById(advertJobId);
                if (job) {
                    setCurrentJob(job);
                    setSelectedJob(job);
                }
                setIsLoading(false);
            }
        };
        advertJobId && getCurrentJob();
    }, [advertJobId]);

    useEffect(() => {
        const getData = async () => {
            const res = await GetMyTeamOpenJobs();
            if (res) setOpenJobs(res);
        };
        getData();
    }, []);

    const copyAdvertCallback = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        if (advertId && selectedJob) {
            const res = await CopyAdvert(advertId, selectedJob.id, errorHandler);
            if (res) {
                successHandler && successHandler('Advert Copied');
                setSelectedJob(null);
                setCurrentJob(null);
                onClose();
                navigate(`/adverts/${res.value}/edit`);
            }
        }
        loadingHandler && loadingHandler(false);
    }, [loadingHandler, advertId, selectedJob, errorHandler, successHandler, onClose, navigate]);

    const jobOptions = useMemo<Job[]>(() => {
        if (openJobs && currentJob) return [currentJob, ...openJobs];
        return [];
    }, [openJobs, currentJob]);

    const closeDialogCallback = useCallback(() => {
        setSelectedJob(null);
        setCurrentJob(null);
        onClose();
    }, [onClose]);

    return (
        <Dialog open={open} fullWidth>
            <DialogTitle>Copy Advert</DialogTitle>
            <DialogContent>
                <div style={{ paddingTop: '5px' }}>
                    <Autocomplete
                        size="small"
                        value={ selectedJob }
                        options={jobOptions}
                        getOptionLabel={o => o.title}
                        isOptionEqualToValue={(o,v) => o.id === v.id}
                        loading={isLoading}
                        onChange={ (e,v) => setSelectedJob(v) }
                        renderInput={ params => (
                            <TextField
                                {...params}
                                label="Job"
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <>
                                            {isLoading ? <CircularProgress color="inherit" size={20} /> : params.InputProps.endAdornment}
                                        </>
                                    )
                                }}
                                inputProps={{ ...params.inputProps, "data-lpignore": "true" }}
                            />
                        )}
                        renderOption={(props, option) => (
                            <ListItem {...props}>
                                <ListItemText
                                    primary={`${option.id} - ${option.title}`}
                                    secondary={`${option.consultant1Name} - ${option.clientName}` + (option.id === advertJobId ? ' [CURRENT JOB]' : '')}
                                />
                            </ListItem>
                        )}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="error" onClick={ closeDialogCallback }>Cancel</Button>
                <Button variant="contained" color="success" disabled={ !selectedJob } onClick={ copyAdvertCallback }>Copy</Button>
            </DialogActions>
        </Dialog>
    );
}