import { GridCellEditStopParams, GridCellEditStopReasons, GridColDef, GridColumnVisibilityModel, GridRenderCellParams, GridRowSelectionModel, useGridApiRef } from "@mui/x-data-grid-premium";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import GridWithStateWrapper from "../GridWidthStateWrapper";
import { JobCandidateStatusResponse } from "../../common/models/Jobs/Job";

interface Props {
    candidateWorkflowStatuses: JobCandidateStatusResponse[],
    gridName: string,
    handleCandidateSelected:(jobCandidateStatus: JobCandidateStatusResponse) => void,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void
}


const defaultHiddenCols: GridColumnVisibilityModel = {
    "id": false,
};
const linkStyle: React.CSSProperties = { color: 'inherit', textDecoration: 'underline', cursor: 'pointer' };
export default function JobCandidateStatusesGrid({ candidateWorkflowStatuses,handleCandidateSelected,loadingHandler, errorHandler, gridName }: Props) {
    const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
    const [rows, setRows] = useState<JobCandidateStatusResponse[]>([]);
    const [editCellFieldKey, setEditCellFieldKey] = useState<keyof JobCandidateStatusResponse | null>(null);
    const apiRef = useGridApiRef();
  


    useEffect(() => {
        if(candidateWorkflowStatuses) {
            setRows(candidateWorkflowStatuses);
        }

    },[candidateWorkflowStatuses])

    const columns = useMemo<GridColDef[]>(() => {

        const openJobCandidateStatusDialog = (params: GridRenderCellParams) => {

            if (params.row.id && !params.row.systemStatus) {
                return <a onClick={() => {handleCandidateSelected(params.row);}} style={ linkStyle }>{params.value}</a>;
            }
    }
        const configManagerRenderer = (params: GridRenderCellParams) => {
            if (params.row.enabled == true) {
                return <span>Yes</span>
            }else{
                return <span>No</span>
            }
        }

        let cols: GridColDef[] = [
            { field: 'id', headerName: 'ID'},
            { field: 'name', headerName: 'Name', width: 300, renderCell: openJobCandidateStatusDialog},
            { field: 'enabled', headerName: 'Enabled', width: 300, renderCell: configManagerRenderer},
            { field: 'jobCandidateKanbanGroup', headerName: 'Candidate Kanban Group', width: 300},
            { field: 'jobStage', headerName: 'Job Stage', width: 300},
        ];

        return cols;
    }, [ apiRef]);


    const cellEditHandler = useCallback((params: GridCellEditStopParams) => {
        if (params.reason === GridCellEditStopReasons.escapeKeyDown) return ;
        setEditCellFieldKey(params.field as keyof JobCandidateStatusResponse);
    }, []);

    const processRowUpdate = useCallback(async (newRow: JobCandidateStatusResponse) => {
        setEditCellFieldKey(null);
        return newRow;
    }, [editCellFieldKey, errorHandler, loadingHandler]);

    return (
        <>

            <GridWithStateWrapper
                rows={rows}
                columns={columns}
                apiRef={apiRef}
                density="compact"
                checkboxSelection={false}
                disableRowSelectionOnClick
                rowSelectionModel={selectionModel}
                onRowSelectionModelChange={ sm => setSelectionModel(sm) }
                pagination
                onCellEditStop={ cellEditHandler }
                processRowUpdate={ processRowUpdate }
                pageSizeOptions={[100,250,500,1000]}
                gridName={gridName}
                defaultViewModel={defaultHiddenCols}
            />
        </>
    );
}