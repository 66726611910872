import React, { useCallback, useEffect, useMemo, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { ChangePlacementJobTitle } from "services/PlacementsService";
import moment from "moment";
import RWTextFieldComponent from "components/RWTextFieldComponent";

interface Props {
    open: boolean,
    placementId: number,
    jobTitle: string,
    closeHandler: () => void,
    loadingHandler?: (isLoading: boolean) => void,
    successHandler?: (message: string, jobTitle: string) => void,
    errorHandler?: (message: string) => void
}

export default function ChangeJobTitleDialog({ open, placementId, jobTitle: jobTitleProp, closeHandler, loadingHandler, successHandler, errorHandler }: Props) {
    const [jobTitle, setJobTitle] = useState('');

    useEffect(() => {
        if (open) setJobTitle(jobTitleProp);
    }, [open, jobTitleProp]);

    const submitCallback = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        const res = await ChangePlacementJobTitle(placementId, jobTitle, errorHandler);
        if (res) {
            successHandler && successHandler('Job Title Updated', jobTitle);
            closeHandler();
        }
        loadingHandler && loadingHandler(false);
    }, [placementId, jobTitle, loadingHandler, successHandler, errorHandler, closeHandler]);

    const effectiveDate = useMemo(() => {
        return moment().format('DD MMM YYYY');
    }, []);

    return (
        <>
            <Dialog open={open} fullWidth>
                <DialogTitle>Change Job Title</DialogTitle>
                <DialogContent>
                    <Stack mt="10px" spacing={2}>
                        <TextField label="Effective Date" value={effectiveDate} InputProps={{ readOnly: true }} />
                        <RWTextFieldComponent value={jobTitle} label="Job Title" onChange={(e) => setJobTitle(e.target.value)} />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="error" onClick={ closeHandler }>Cancel</Button>
                    <Button variant="contained" color="success" disabled={!Boolean(jobTitle) || jobTitle === jobTitleProp.toString()} onClick={ submitCallback } >Submit</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}