import { UserGeneralSettings, UserSettingsConfigOptions } from "common/models/Configuration/User";
import { ChangeTracker } from "common/models/hooks/ChangeTracker";

export const DefaultGeneralUserSettings: UserGeneralSettings = {
    firstName: "",
    lastName: "",
    userName: "",
    userTypeID: 0,
    timeZoneName: "",
    configurationManager: false,
    jobTitle: "",
    mobile: "",
    landline: "",
    linkedinUrl: "",
    twitterUrl: "",
    address1: "",
    address2: "",
    address3: "",
    suburb: "",
    state: "",
    postcode: "",
    country: ""
};

export const NoChangesGeneralUserSettings : ChangeTracker<UserGeneralSettings> = {
    address1: false,
    address2: false,
    address3: false,
    configurationManager: false,
    country: false,
    firstName: false,
    jobTitle: false,
    landline: false,
    lastName: false,
    linkedinUrl: false,
    mobile: false,
    postcode: false,
    state: false,
    suburb: false,
    timeZoneName: false,
    twitterUrl: false,
    userName: false,
    userTypeID: false,
}

export const DefaultUserSettings: UserSettingsConfigOptions = {
    JobApplicationNotificationMode: "0",
    Calendar_User_ByDefault: "False",
    Currency: "9",
    Theme: "light",
    AlternateRowStylingOnGrids: 'False',
    DefaultCountry: "AU",
    DefaultScreeningQuestionID: "0",
    DefaultReferenceCheckTemplateID: "0",
    DefaultRejectionEmailTemplateID: "0",
    DefaultAdvertScreeningQuestionnaireID: "0",
    UserTimezone: "",
    Division: "0",
    Search_Latitude: "",
    Search_Location: "",
    Search_LocationHiddenId: "",
    Search_Longitude: "",
    Search_Radius: "",
    SearchDivision: "0",
    VoipIdentifier: "",
    DefaultDistributionMethod_Submissions: "3",
    DefaultDistributionMethod_Meetings: "CalendarInvitePlusEmail",
    DefaultDuration_Meetings: "60",
    DefaultDistributionMethod_InteriewsClients: "CalendarInvitePlusEmail",
    DefaultDistributionMethod_InteriewsCandidates: "CalendarInvitePlusEmail",
    DefaultDuration_Interviews: "60",
    DefaultInterviewLocationTypeId: "2",
    DefaultMeetingLocationTypeId: "2",
    DefaultInterviewLocation: "",
    DocumentScaleRatio: "2",
    OpenEditDialogOnFollowUpActivitiesCreated: "False"
}

export const NoChangesUserSettings: ChangeTracker<UserSettingsConfigOptions> = {
    JobApplicationNotificationMode: false,
    Calendar_User_ByDefault: false,
    Currency: false,
    Theme: false,
    AlternateRowStylingOnGrids: false,
    DefaultCountry: false,
    DefaultScreeningQuestionID: false,
    DefaultReferenceCheckTemplateID: false,
    DefaultRejectionEmailTemplateID: false,
    DefaultAdvertScreeningQuestionnaireID: false,
    UserTimezone: false,
    Division: false,
    Search_Latitude: false,
    Search_Location: false,
    Search_LocationHiddenId: false,
    Search_Longitude: false,
    Search_Radius: false,
    SearchDivision: false,
    VoipIdentifier: false,
    DefaultDistributionMethod_Submissions: false,
    DefaultDistributionMethod_Meetings: false,
    DefaultDuration_Meetings: false,
    DefaultDistributionMethod_InteriewsClients: false,
    DefaultDistributionMethod_InteriewsCandidates: false,
    DefaultDuration_Interviews: false,
    DefaultInterviewLocationTypeId: false,
    DefaultMeetingLocationTypeId: false,
    DefaultInterviewLocation: false,
    DocumentScaleRatio: false,
    OpenEditDialogOnFollowUpActivitiesCreated: false
}