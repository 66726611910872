import { List } from "@mui/material";
import React, { useCallback, useState } from "react";

import NavBarModuleRoot from './NavBarModuleRoot';
import Routes from '../../../common/data/TopBarRoutes';

export default function NavBarContainer() {
    const [selectedModule, setSelectedModule] = useState<number | null>(null);
    const handleHoverModule = useCallback((hoveredModule: number | null) => {
        setSelectedModule(hoveredModule);
    }, []);

    return (
        <List disablePadding dense sx={{ display: 'flex' }}>
            {Routes.map((route, index) => (
                <NavBarModuleRoot
                    key={index}
                    module={route}
                    moduleIndex={index}
                    selectedModule={ selectedModule }
                    changeSelectedModule={handleHoverModule}
                />
            ))}
        </List>
    );
}