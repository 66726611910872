import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";

import PageLayout from "layouts/PageLayout";
import PageContentLayout from "layouts/PageContentLayout";
import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import { Job } from "common/models/Jobs/Job";
import { DeleteJobById, GetJobById, PutJobOnHold, ReopenJob, ResetEstimatedPlacementDate, UpdateJobDeadline } from "services/JobsService";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { GetSettingBySettingName, UpdateSettingBySettingName, GetJobRecordSettings } from "services/UsersService";

import Avatar from '@mui/material/Avatar';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import PercentIcon from '@mui/icons-material/Percent';
import ConfirmationDialog from "components/Dialogs/Generic/ConfirmationDialog";
import Snackbar from "@mui/material/Snackbar";
import Alert from "components/Alert";
import ActionMenu from "components/Menus/ActionMenu";
import moment from "moment";
import { GetActivitiesSettings } from "services/ConfigurationService";
import { ActivitySettings } from "common/models/Configuration/Activities";
import { MenuOptionDefinition } from "common/models/MenuDefinition";
import EditActivityDialog from "components/Dialogs/Activities/EditActivityDialog";
import { DefaultActivity } from "util/Definitions/Activities";
import { AllowJobsDelete, AllowJobsAddEdit } from "common/data/Permissions/JobsAccess";
import PreviewLoaderComponent from "components/Previews/PreviewLoader";
import TagsManagementDialog from "components/Dialogs/TagsManagementDialog";
import ActivitiesGridComponent from "components/Grids/ActivitiesGrid";
import ActivityMessagesGridComponent from "components/Grids/ActivityMessagesGrid";
import AdvertsGridComponent from "components/Grids/AdvertsGrid";
import PlacementsGridComponent from "components/Grids/PlacementsGrid";
import JobTrackingGridComponent from "components/Grids/JobTrackingGrid";
import InvoicesGridComponent from "components/Grids/InvoicesGrid";
import Switch from "@mui/material/Switch";
import DocumentsGridComponent from "components/Grids/DocumentsGrid";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { Permission } from "common/models/Permissions";
import { companyHasSinglePermission, userHasSinglePermission } from "util/PermissionsUtils";
import { AddRecentRecord } from "util/LocalStorageUtils";
import ViewRecordScreenLayout from "components/ScreenLayouts/Jobs/ViewRecordScreenLayout";

import CandidateKanban from "components/Kanbans/CandidateKanban";
import CloseJobsDialog from "components/Dialogs/Jobs/CloseJobsDialog";
import CreateAdvertDialog from "components/Dialogs/Adverts/CreateAdvertDialog";
import ResetJobStageDialog from "components/Dialogs/Jobs/ResetJobStageDialog";
import ConfirmationWithDateDialog from "components/Dialogs/Generic/ConfirmationWithDateDialog";
import { AllowCandidatesAddEdit } from "common/data/Permissions/CandidatesAccess";
import UploadCvDialog from "components/Dialogs/Candidates/UploadCvDialog";
import { Contact } from "common/models/Contacts";
import { GetContactById } from "services/ContactsService";
import { EmailRecipient } from "common/models/EmailRecipient";
import SendEmailDialog from "components/Dialogs/Messages/SendEmailDialog";
import SendSmsDialog from "components/Dialogs/Messages/SendSmsDialog";
import LinkJobToCandidateDialog from "components/Dialogs/Jobs/LinkJobToCandidateDialog";
import SubmissionsGridComponent from "components/Grids/SubmissionsGrid";
import ChangeSplitsDialog from "components/Dialogs/Jobs/ChangeSplitsDialog";
import ChangeTypeDialog from "components/Dialogs/Jobs/ChangeTypeDialog";
import CreateInvoiceDialog from "components/Dialogs/Invoices/CreateInvoiceDialog";
import GenerateDocumentDialog from "components/Dialogs/Documents/GenerateDocumentDialog";
import SearchIcon from '@mui/icons-material/Search';
import usePartnerActions from "hooks/UsePartnerActions";
import ShowIframeDialog from "components/Dialogs/Generic/ShowIFrameDialog";
import { PartnerActionType } from "common/models/PartnerActions";
import { RegexIsInteger } from "util/RegExUtils";
import Chip from "@mui/material/Chip";
import JobDescriptionRenderer from "../../components/Jobs/JobDescriptionRenderer";
import UploadDescriptionDialog from "../../components/Dialogs/Jobs/UploadDescriptionDialog";
import useRecordHotkeys from "hooks/UseRecordHotkeys";
import InterviewsGridComponent from "../../components/Grids/InterviewsGrid";
import MeetingsGridComponent from "../../components/Grids/MeetingsGrid";
const defaultJobViewSettingName = 'JobTrackingDefaultView';


const summaryLinkStyle: React.CSSProperties = { color: 'inherit', textDecoration: 'none' };
const formatDateString = (d: string, f: string = 'DD MMM YYYY h:mm A') => {
    if (d && d !== '0001-01-01T00:00:00') {
        const m = moment(d);
        if (m.isValid()) return m.format(f);
    }
    return 'Never';
}

const renderSummary = (c: Job) => {
    const tooltipContent = (
        <table>
            <tbody>
                <tr>
                    <Typography variant="caption" fontWeight={600} component="td">ID</Typography>
                    <Typography variant="caption" pl="10px" component="td">{c.id}</Typography>
                </tr>
                <tr>
                    <Typography variant="caption" fontWeight={600} component="td" sx={{ verticalAlign: 'top' }}>Title</Typography>
                    <Typography variant="caption" pl="10px" component="td">{c.title}</Typography>
                </tr>
                <tr>
                    <Typography variant="caption" fontWeight={600} component="td">Created Date</Typography>
                    <Typography variant="caption" pl="10px" component="td">{formatDateString(c.createdDate)}</Typography>
                </tr>
                <tr>
                    <Typography variant="caption" fontWeight={600} component="td">Created By</Typography>
                    <Typography variant="caption" pl="10px" component="td">{c.createdByName}</Typography>
                </tr>
                <tr>
                    <Typography variant="caption" fontWeight={600} component="td">Updated Date</Typography>
                    <Typography variant="caption" pl="10px" component="td">{formatDateString(c.updatedDate)}</Typography>
                </tr>
                <tr>
                    <Typography variant="caption" fontWeight={600} component="td">Updated By</Typography>
                    <Typography variant="caption" pl="10px" component="td">{c.updatedByName}</Typography>
                </tr>
            </tbody>
        </table>
    );
    const locationUrl = '/clients/' + c.clientID;

    const jobSummary = (
        <Tooltip arrow title={tooltipContent}>
            <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{c.title} ({c.typeName}) for <a href={locationUrl} style={{ color: 'inherit', textDecoration: 'underline' }} key="clientNameAction" target="_blank" rel="noopener noreferrer" >{`${c.clientName}`}</a> - {c.stageName}</span>
        </Tooltip>
    );

    return (<>{jobSummary}</>);
};

const contactEntityType = 2;
const entityTypeId = 4;

export default function JobRecord() {
    const [job, setJob] = useState<Job>();
    const [contact1, setContact1] = useState<Contact | null>(null);
    const [contact2, setContact2] = useState<Contact | null>(null);
    const [contact3, setContact3] = useState<Contact | null>(null);
    const [emailRecipient, setEmailRecipient] = useState<EmailRecipient | null>(null);
    const [smsRecipient, setSmsRecipient] = useState<EmailRecipient | null>(null);
    const [activitySettings, setActivitySettings] = useState<ActivitySettings[]>([]);
    const [selectedActivity, setSelectedActivity] = useState<ActivitySettings | null>(null);
    const [noteActivitySetting, setNoteActivitySetting] = useState<ActivitySettings | null>(null);
    const params = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [isFetchingDefaultTab, setIsFetchingDefaultTab] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [tmpErrorMessage, setTmpErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [isRecordDeleted, setIsRecordDeleted] = useState(false);
    const [isLoadingTab, setIsLoadingTab] = useState(false);
    const [showTagsPrevew, setShowTagsPreview] = useState(false);
    const [showTagsPrevewNoDelay, setShowTagsPreviewNoDelay] = useState(false);
    const [showTagsManagement, setShowTagsManagement] = useState(false);
    const [showSplitsDialog, setShowSplitsDialog] = useState(false);
    const [showCreateInvoiceDialog, setShowCreateInvoiceDialog] = useState(false);
    const [searchParams] = useSearchParams();
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showChangeTypeDialog, setShowChangeTypeDialog] = useState(false);
    const [showReopenDialog, setShowReopenDialog] = useState(false);
    const [showPutOnHoldDialog, setShowPutOnHoldDialog] = useState(false);
    const [showResetStageDialog, setShowResetStageDialog] = useState(false);
    const [showUploadCvDialog, setShowUploadCvDialog] = useState(false);
    const [showUploadDescriptionDialog, setShowUploadDescriptionDialog] = useState(false);
    
    const [showGenerateDocumentsDialog, setShowGenerateDocumentsDialog] = useState(false);
    const [isCloseJobsDialogOpen, setIsCloseJobsDialogOpen] = useState(false);
    const [isCreateAdvertDialogOpen, setIsCreateAdvertDialogOpen] = useState(false);
    const [isResetEstimatedPlacementDateOpen, setIsResetEstimatedPlacementDateOpen] = useState(false);
    const [isUpdateDeadlineDialogOpen, setIsUpdateDeadlineDialogOpen] = useState(false);
    const [activeTab, setActiveTab] = useState('JOBS_Home');
    const [defaultTab, setDefaultTab] = useState<string>();
    const [tabSetupFinished, setTabSetupFinished] = useState(false);
    const [jobsView, setJobsView] = useState<'list' | 'kanban'>('kanban');
    const [allowPutOnHold, setAllowPutOnHold] = useState(false);
    const [allowResetStage, setAllowResetStage] = useState(false);
    const [allowDeadlineChanges, setAllowDeadlineChanges] = useState(false);
    const [showLinkJobToCandidateDialog, setShowLinkJobToCandidateDialog] = useState(false);
    const [isFinishedSetup, setIsFinishedSetup] = useState(false);
    const [refreshActivitiesGridControl, setRefreshActivitiesGridControl] = useState(false);
    const [refreshInvoicesGrid, setRefreshInvoicesGrid] = useState(false);
    const [forecastingMode, setForecastingMode] = useState('');
    const [tabActionMenuOptions, setTabActionMenuOptions] = useState<MenuOptionDefinition[]>([]);
    const [showPartnerActionPopup, setShowPartnerActionPopup] = useState(false);
    const [advertIdFilter, setAdvertIdFilter] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        if (!showTagsPrevew) setShowTagsPreviewNoDelay(false);
    }, [showTagsPrevew]);

    const canAccessCandidates = useMemo(() => companyHasSinglePermission(Permission.Candidates) && userHasSinglePermission(Permission.Candidates), []);
    const canAccessAdverts = useMemo(() => companyHasSinglePermission(Permission.Adverts) && userHasSinglePermission(Permission.Adverts), []);

    const canAccessPlacements = useMemo(() => companyHasSinglePermission(Permission.Placements) && userHasSinglePermission(Permission.Placements), []);
    const canAccessActivities = useMemo(() => companyHasSinglePermission(Permission.Activities) && userHasSinglePermission(Permission.Activities), []);
    const canChangeJobDeadlines = useMemo(() => companyHasSinglePermission(Permission.JobDeadlineChanges) && userHasSinglePermission(Permission.JobDeadlineChanges), []);
    const canAddEditCandidates = useMemo(() => AllowCandidatesAddEdit(), []);
    const userCanSendEmail = useMemo(() => userHasSinglePermission(Permission.MessageCentre) && companyHasSinglePermission(Permission.MessageCentre), []);
    const userCanSendSms = useMemo(() => userHasSinglePermission(Permission.MessageCentreSms) && companyHasSinglePermission(Permission.MessageCentreSms), []);
    const canGenerateDocuments = useMemo(() => companyHasSinglePermission(Permission.DocumentTemplates) && userHasSinglePermission(Permission.DocumentTemplates), []);

    const jobId = useMemo(() => {
        const parsedId = +(params.id ?? '0');
        if (isNaN(parsedId)) return 0;
        return parsedId;
    }, [params.id]);

    const jobIdArray = useMemo(() => [jobId], [jobId]);

    const partnerActionsData = useMemo(() => ({ jobId }), [jobId]);
    const { partnerActions, popupTitle, popupUrl } = usePartnerActions(PartnerActionType.Jobs, partnerActionsData, setShowPartnerActionPopup);

    const canAddEditJobs = useMemo(() => AllowJobsAddEdit(), []);
    const canDeleteJobs = useMemo(() => AllowJobsDelete(), []);

    const hotkeySettings = useMemo<Record<string, () => void>>(() => {
        return {
            n: () => noteActivitySetting && setSelectedActivity({...noteActivitySetting}),
            e: () => navigate(`/jobs/${jobId}/edit`),
            t: () => setShowTagsManagement(true),
        };
    }, [jobId, navigate, noteActivitySetting]);

    useRecordHotkeys(hotkeySettings, !canAddEditJobs);

    useEffect(() => {
        const getData = async () => {
            setIsLoading(true);
            const res = await GetJobById(jobId);
            if (res === null) {
                navigate('/not-found');
                return;
            }
            const settings = await GetActivitiesSettings(4, undefined, setErrorMessage);
            const jobRecordSettings = await GetJobRecordSettings(setErrorMessage);
            if (jobRecordSettings) {
                const h = jobRecordSettings.find(s => s.type === 'OnHold');
                if (h) setAllowPutOnHold(h.value === 'True');
                const r = jobRecordSettings.find(s => s.type === 'AllowResetStage');
                if (r) setAllowResetStage(r.value === 'True');
                const f = jobRecordSettings.find(s => s.type === 'ForecastingMode');
                if (f) setForecastingMode(f.value);
                const d = jobRecordSettings.find(s => s.type === 'structureddeadlinechanges');
                if (d) setAllowDeadlineChanges(d.value === 'True');
            }
            if (res) {
                AddRecentRecord(4, jobId, res.title);
                setJob(res);
            }
            if (settings) setActivitySettings(settings);
            setIsLoading(false);
        };
        getData();
    }, [jobId, navigate]);

    useEffect(() => {
        const getContacts = async () => {
            if (job) {
                let c1: Contact | null = null;
                let c2: Contact | null = null;
                let c3: Contact | null = null;
                if (job.contact1ID) c1 = await GetContactById(job.contact1ID);
                
                if (job.contact1ID === job.contact2ID) c2 = c1;
                else if (job.contact2ID) c2 = await GetContactById(job.contact2ID);

                if (job.contact1ID === job.contact3ID) c3 = c1;
                else if (job.contact2ID === job.contact3ID) c3 = c2;
                else if (job.contact3ID) c3 = await GetContactById(job.contact3ID);

                c1 && setContact1(c1);
                c2 && setContact2(c2);
                c3 && setContact3(c3);
            }
        };
        getContacts();
    }, [job]);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const tabParam = urlParams.get('tab');
        const advertId = urlParams.get('advertId');
        if (advertId && RegexIsInteger(advertId)) setAdvertIdFilter(+advertId);

        const getData = async () => {
            setIsFetchingDefaultTab(true);
            if (!tabParam) {
                const tabSetting = await GetSettingBySettingName('TabDefaultsJobs');
                if (tabSetting) setDefaultTab(tabSetting);
            }
            else setActiveTab(tabParam)

            const defaultJobView = await GetSettingBySettingName(defaultJobViewSettingName);
            if (defaultJobView) setJobsView(defaultJobView === 'list' ? 'list' : 'kanban');

            setIsFetchingDefaultTab(false);
            setIsFinishedSetup(true);
        };
        getData();
    }, []);

    const sendEmailCallback = useCallback((type: 'c1' | 'c2' | 'c3') => {
        let contact: Contact | null = null;
        switch (type) {
            case 'c1': contact = contact1;
                break;
            case 'c2': contact = contact2;
                break;
            case 'c3': contact = contact3;
                break;
        }
        if (contact) {
            const r: EmailRecipient = {
                id: contact.id,
                mobile: contact.mobile_Standardised,
                email: contact.email ? contact.email : contact.email2,
                name: contact.fullName,
                contact: contact,
                isOptOut: contact.optOut,
                job: job
            };
            setEmailRecipient(r);
        }
    }, [job, contact1, contact2, contact3]);

    const sendSmsCallback = useCallback((type: 'c1' | 'c2' | 'c3') => {
        let contact: Contact | null = null;
        switch (type) {
            case 'c1': contact = contact1;
                break;
            case 'c2': contact = contact2;
                break;
            case 'c3': contact = contact3;
                break;
        }
        if (contact) {
            const r: EmailRecipient = {
                id: contact.id,
                mobile: contact.mobile_Standardised,
                email: contact.email ? contact.email : contact.email2,
                name: contact.fullName,
                contact: contact,
                isOptOut: contact.optOut,
                job: job
            };
            setSmsRecipient(r);
        }
    }, [job, contact1, contact2, contact3]);

    const actionMenuDefinitions = useMemo<MenuOptionDefinition[]>(() => {
        const isJobOpen = job ? job.statusName === 'Open' : false;
        const isJobOnHold = job ? job.statusName === 'Hold' : false;
        const stage = job ? job.stageID : 0;
        const jobType = job ? job.type : '';
        let actions: (MenuOptionDefinition)[] = [
            ...partnerActions,
            ...tabActionMenuOptions,
            { label: 'Change Type', type: 'action', action: () => setShowChangeTypeDialog(true), allow: () => canAddEditJobs && (isJobOpen || isJobOnHold) },
            { label: 'Delete', type: 'action', action: () => setShowDeleteDialog(true), allow: () => canDeleteJobs },
            { label: 'Close Job', type: 'action', action: () => setIsCloseJobsDialogOpen(true), allow: () => canAddEditJobs && (isJobOpen || isJobOnHold) },
            { label: 'Copy Job', type: 'link', href: `/jobs/create?JobType=${jobType}&SourceJobID=${jobId}`, allow: () => canAddEditJobs },
            { label: 'Create Advert', type: 'action', action: () => setIsCreateAdvertDialogOpen(true), allow: () => canAddEditJobs && isJobOpen },
            { label: 'Create Invoice', type: 'action', action: () => setShowCreateInvoiceDialog(true), allow: () => canAddEditJobs && isJobOpen },
            { label: 'Reopen Job', type: 'action', action: () => setShowReopenDialog(true), allow: () => canAddEditJobs && !isJobOpen },
            { label: 'Put Job on Hold', type: 'action', action: () => setShowPutOnHoldDialog(true), allow: () => canAddEditJobs && allowPutOnHold && !isJobOnHold },
            { label: 'Reset Stage', type: 'action', action: () => setShowResetStageDialog(true), allow: () => canAddEditJobs && allowResetStage && isJobOpen && stage !== 0 },
            { label: 'Reset Estimated Placement Date', type: 'action', action: () => setIsResetEstimatedPlacementDateOpen(true), allow: () => canAddEditJobs && isJobOpen && forecastingMode === '0' },
            { label: 'Update Deadline', type: 'action', action: () => setIsUpdateDeadlineDialogOpen(true), allow: () => canAddEditJobs && allowDeadlineChanges && isJobOpen && canChangeJobDeadlines },
            
            { label: 'Upload Description', type: 'action', action: () => setShowUploadDescriptionDialog(true) },
            { label: 'Upload CV', type: 'action', action: () => setShowUploadCvDialog(true), allow: () => canAddEditCandidates },
            { label: 'Send Email', type: 'parent', allow: () => userCanSendEmail, subMenu: [
                { label: 'To Billing Contact', type: 'action', action: () => sendEmailCallback('c3') },
                { label: 'To Contact', type: 'action', action: () => sendEmailCallback('c1') },
                { label: 'To Hiring Manager', type: 'action', action: () => sendEmailCallback('c2') },
            ] },
            { label: 'Send SMS', type: 'parent', allow: () => userCanSendSms, subMenu: [
                { label: 'To Billing Contact', type: 'action', action: () => sendSmsCallback('c3') },
                { label: 'To Contact', type: 'action', action: () => sendSmsCallback('c1') },
                { label: 'To Hiring Manager', type: 'action', action: () => sendSmsCallback('c2') },
            ] },
            { label: 'Link Candidate To Job', type: 'action', action: () => setShowLinkJobToCandidateDialog(true), allow: () => isJobOpen },
            { label: 'Generate Document', type: 'action', action: () => setShowGenerateDocumentsDialog(true), allow: () => canGenerateDocuments },
        ];

        for (let i = 0; i < activitySettings.length; i++) {
            const setting = activitySettings[i];
            if (setting.id === 18) setNoteActivitySetting(setting);
            if (setting.id === 33) actions.push({ label: setting.customName, type: 'link', href: `/interviews/create?typeId=${setting.id}&jobId=${jobId}` });
            else actions.push({ label: setting.customName, type: 'action', action: () => setSelectedActivity(setting) });
        }

        return actions;
    }, [job, partnerActions, tabActionMenuOptions, canAddEditJobs, canDeleteJobs, allowPutOnHold, allowResetStage, forecastingMode, allowDeadlineChanges, canChangeJobDeadlines, canAddEditCandidates, userCanSendEmail, sendEmailCallback, userCanSendSms, sendSmsCallback, canGenerateDocuments, activitySettings, jobId]);

    const googleMapsLink = (c: Job) => {
        const baseUrl = 'https://www.google.com/maps/search/?api=1';
        let address = '';
        if (c.address1) address = c.address1;
        if (c.address2) address += ' ' + c.address2;
        if (c.address3) address += ' ' + c.address3;
        if (c.suburb) address += ' ' + c.suburb;
        if (c.state) address += ' ' + c.state;
        if (c.postcode) address += ' ' + c.postcode;
        if (c.countryName) address += ' ' + c.countryName;

        if (address !== '') {
            return `${baseUrl}&query=${encodeURIComponent(address)}`;
        }
        return null;
    }

    const SummaryBar = useMemo(() => {
        if (job) {
            const tagsAction = (
                <Avatar
                    key="tagsAction"
                    onClick={canAddEditJobs ? () => setShowTagsManagement(true) : () => setShowTagsPreviewNoDelay(true)}
                    onMouseEnter={ () => setShowTagsPreview(true) }
                    onMouseLeave={ () => setShowTagsPreview(false) }
                    sx={{ bgcolor: '#f209a6', mr: '5px', cursor: 'pointer' }}>
                    { job.tags ? <LocalOfferIcon /> : <LocalOfferOutlinedIcon />}
                </Avatar>
            );

            const splitsAction = (
                <Avatar
                    key="splitsAction"
                    onClick={() => setShowSplitsDialog(true)}
                    sx={{ bgcolor: t => t.palette.primary.main, mr: '5px', cursor: 'pointer' }}
                >
                    <PercentIcon />
                </Avatar>
            );

            const searchAction = job && job.savedSearchID ? (
                <Link key="searchAction" to={`/candidates?ssid=${job.savedSearchID}&jobId=${jobId}`} style={summaryLinkStyle}>
                    <Avatar sx={{ mr: '5px' }}>
                        <SearchIcon />
                    </Avatar>
                </Link>
            ) : <React.Fragment key="searchPlaceholder" />;

            const locationUrl = googleMapsLink(job);
            const locationAction = locationUrl ? (
                <a href={locationUrl}
                    style={summaryLinkStyle}
                    key="locationAction"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <Avatar sx={{ bgcolor: "#ea4335", mr: '5px' }}>
                        <LocationOnIcon />
                    </Avatar>
                </a>
            ) : <Avatar key="locationPlaceholder" sx={{ mr: '5px' }}><LocationOnIcon /></Avatar>;

            const action1 = canAddEditJobs ? (
                <Link key="editLink" to={`/jobs/${jobId}/edit`} style={summaryLinkStyle}>
                    <Button variant="contained" color="success" sx={{ mr: '5px' }}>Edit</Button>
                </Link>
            ) : <React.Fragment key="editPlaceholder" />;

            const action = (
                <Box key="actionsMenu">
                    <ActionMenu definition={actionMenuDefinitions} />
                </Box>
            );

            return (
                <TitleAndActionSummaryBar
                    height="4.5rem"
                    title={job ? renderSummary(job) : ''}
                    action={[searchAction, splitsAction, locationAction, tagsAction, action1, action]}
                    browserTabTitle={!job ? '' : job.title + " - Jobs"}
                />
            );
        }
    }, [jobId, job, actionMenuDefinitions, canAddEditJobs]);


    const handleTabChange = useCallback((e: React.SyntheticEvent, newValue: string) => {
        setActiveTab(newValue);
        navigate(`/jobs/${jobId}?tab=${newValue}`);
    }, [jobId, navigate]);

    const deleteRecordCallback = useCallback(async () => {
        setIsLoading(true);
        const res = await DeleteJobById(jobId, setErrorMessage);
        if (res) setIsRecordDeleted(true);
        setIsLoading(false);
    }, [jobId]);

    const reopenJobCallback = useCallback(async () => {
        setIsLoading(true);
        const res = await ReopenJob(jobId, setErrorMessage);
        if (res) {
            setJob(prev => prev ? ({...prev, statusName: 'Open'}) : prev);
            setSuccessMessage('Job Reopened Successfully');
            setShowReopenDialog(false);
        }
        setIsLoading(false);
    }, [jobId]);

    const putJobOnHoldCallback = useCallback(async () => {
        setIsLoading(true);
        const res = await PutJobOnHold(jobId, setErrorMessage);
        if (res) {
            setJob(prev => prev ? ({...prev, statusName: 'Hold'}) : prev);
            setSuccessMessage('Job placed on Hold Successfully');
            setShowPutOnHoldDialog(false);
        }
        setIsLoading(false);
    }, [jobId]);

    const counts = useMemo(() => {
        let counts = {
            documents: 0,
            candidates: 0,
            adverts: 0,
            advertsDate: '\u00A0',
            callsNotes: 0,
            callsNotesDate: '\u00A0',
            meetings: 0,
            meetingsDate: '\u00A0',
            messages: 0,
            messagesDate: '\u00A0',
            activities: 0,
            activitiesDate: '\u00A0',
            submissions: 0,
            submissionsDate: '\u00A0',
            jobs: 0,
            jobsDate: '\u00A0',
            interviews: 0,
            interviewsDate: '\u00A0',
            placements: 0,
            placementsDate: '\u00A0',
            invoices: 0
        };

        if (job && job.total !== 0)
            counts.candidates = job.total;

        if (job && job.statisticsCounts && job.statisticsCounts.length > 0) {
            for (let i = 0; i < job.statisticsCounts.length; i++) {
                const s = job.statisticsCounts[i];
                switch (s.type) {
                    case "DocumentsCount": counts.documents = s.value;
                        break;
                    case "AdvertsCount": counts.adverts = s.value;
                        break;
                    case "CallsNotesCount": counts.callsNotes = s.value;
                        break;
                    case "MeetingsCount": counts.meetings = s.value;
                        break;
                    case "MessagesCount": counts.messages = s.value;
                        break;
                    case "InterviewsCount": counts.interviews = s.value;
                        break;
                    case "PlacementsCount": counts.placements = s.value;
                        break;
                    case "SubmissionsCount": counts.submissions = s.value;
                        break;
                    case "ActivitiesCount": counts.activities = s.value;
                        break;
                    case "InvoicesCount": counts.invoices = s.value;
                        break;
                }
            }
        }
        if (job && job.statisticsDates && job.statisticsDates.length > 0) {
            for (let i = 0; i < job.statisticsDates.length; i++) {
                const s = job.statisticsDates[i];
                switch (s.type) {
                    case "AdvertsDate":
                        const adDate = moment(s.value);
                        if (adDate.isValid()) counts.advertsDate = adDate.format('DD MMM YYYY');
                        break;
                    case "CallsNotesDate":
                        const cnDate = moment(s.value);
                        if (cnDate.isValid()) counts.callsNotesDate = cnDate.format('DD MMM YYYY');
                        break;
                    case "MeetingsDate":
                        const mDate = moment(s.value);
                        if (mDate.isValid()) counts.meetingsDate = mDate.format('DD MMM YYYY');
                        break;
                    case "MessagesDate":
                        const mssDate = moment(s.value);
                        if (mssDate.isValid()) counts.messagesDate = mssDate.format('DD MMM YYYY');
                        break;
                    case "InterviewsDate":
                        const iDate = moment(s.value);
                        if (iDate.isValid()) counts.interviewsDate = iDate.format('DD MMM YYYY');
                        break;
                    case "PlacementsDate":
                        const pDate = moment(s.value);
                        if (pDate.isValid()) counts.placementsDate = pDate.format('DD MMM YYYY');
                        break;
                    case "SubmissionsDate":
                        const cvDate = moment(s.value);
                        if (cvDate.isValid()) counts.submissionsDate = cvDate.format('DD MMM YYYY');
                        break;
                    case "ActivitiesDate":
                        const aDate = moment(s.value);
                        if (aDate.isValid()) counts.activitiesDate = aDate.format('DD MMM YYYY');
                        break;
                }
            }
        }
        return counts;
    }, [job]);

    useEffect(() => {
        if (tabActionMenuOptions.length > 0 && activeTab !== 'JOBS_Home') {
            setTabActionMenuOptions([]);
        }
    }, [activeTab, tabActionMenuOptions]);

    const tagManagementSuccessHandler = useCallback((message: string, recordIds: number[], finalTagCount: number) => {
        setSuccessMessage(message);
    }, []);

    const jobsViewChangeHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = e.target.checked;
        const value = isChecked ? 'list' : 'kanban';
        setJobsView(value);
        UpdateSettingBySettingName(defaultJobViewSettingName, value);
    }, []);

    const openJobsViewSwitch = useMemo(() => (
        <span>
            <span>Kanban</span>
            <Switch checked={jobsView === 'list'} onChange={jobsViewChangeHandler} />
            <span>List</span>
            { Boolean(advertIdFilter) && <Chip label={`Filtering results by Advert ${advertIdFilter}`} onDelete={() => setAdvertIdFilter(0)} sx={{ ml: 1 }} />}
        </span>
    ), [advertIdFilter, jobsView, jobsViewChangeHandler]);

    const closeJobsSuccessCallback = useCallback(() => {
        setJob(prev => prev ? ({...prev, statusName: 'Closed'}) : prev);
        setSuccessMessage('Jobs Closed Successfully');
    }, []);

    const onCreateAdvertSuccess = useCallback((advertId: number) => {
        navigate(`/adverts/${advertId}/edit`);
    }, [navigate]);

    const onResetStageSuccess = useCallback((stageId: number, stageName: string) => {
        setJob(prev => prev ? {...prev, stageID: stageId, stageName: stageName} : undefined);
    }, []);

    const resetEstimatedPlacementDateCallback = useCallback(async (date: string) => {
        setIsLoading(true);
        const res = await ResetEstimatedPlacementDate(jobId, date);
        if (res) {
            setIsResetEstimatedPlacementDateOpen(false);
            setSuccessMessage('Estimated placement date changed');
            setJob(prev => prev ? ({...prev, estimatedPlacementDate: date}) : prev);
        }
        setIsLoading(false);
    }, [jobId]);

    const updateDeadlineCallback = useCallback(async (date: string) => {
        setIsLoading(true);
        const res = await UpdateJobDeadline(jobId, date);
        if (res) {
            setIsUpdateDeadlineDialogOpen(false);
            setSuccessMessage('Deadline Updated');
            setJob(prev => prev ? {...prev, deadline: date} : undefined);
        }
        setIsLoading(false);
    }, [jobId]);

    const onSplitsChangeHandler = useCallback((message: string, id1: number, p1: number, id2: number, p2: number, id3: number, p3: number, id4: number, p4: number, id5: number, p5: number) => {
        setSuccessMessage(message);
        setJob(prev => prev ? {...prev, splitRecipientID1: id1, splitPercentage1: p1, splitRecipientID2: id2, splitPercentage2: p2, splitRecipientID3: id3, splitPercentage3: p3, splitRecipientID4: id4, splitPercentage4: p4, splitRecipientID5: id5, splitPercentage5: p5} : prev);
    }, []);

    const onCreateInvoiceSuccess = useCallback((message: string) => {
        setRefreshInvoicesGrid(true);
        setSuccessMessage(message);
    }, []);

    const onTypeChangeHandler = useCallback((message: string, newType: string) => {
        setSuccessMessage(message);

        let typeName = newType;
        if (newType === 'FixedContract') typeName = 'Fixed Contract';
        if (newType === 'TalentPool') typeName = 'Talent Pool';

        setJob(prev => prev ? ({...prev, type: newType, typeName: typeName}) : prev)

        setShowChangeTypeDialog(false);
        const at = activeTab;

        setActiveTab('somethingelse');
        setActiveTab(at);
    }, [activeTab]);

    const activityCreatedSuccessCallback = useCallback((message: string) => {
        setSuccessMessage(message);
        setRefreshActivitiesGridControl(prev => !prev);
    }, []);

    useEffect(() => {
        if (defaultTab && !tabSetupFinished) {
            const accessRestrictedTabs = ['JOBS_CallsNotes', 'JOBS_Meetings', 'Messages', 'JOBS_Submissions', 'JOBS_Interviews', 'JOBS_Activities'];
            setActiveTab(prev => {
                if (accessRestrictedTabs.includes(defaultTab) && !canAccessActivities) return prev;
                if (defaultTab === 'JOBS_Candidates' && !canAccessCandidates) return prev;
                if (defaultTab === 'JOBS_Adverts' && !canAccessAdverts) return prev;
                if (defaultTab === 'JOBS_Placements' && !canAccessPlacements) return prev;
                return defaultTab;
            });
            setTabSetupFinished(true);
        }
    }, [defaultTab, canAccessActivities, canAccessPlacements, canAccessAdverts, canAccessCandidates, tabSetupFinished]);

    return (
        <>
            <Snackbar open={successMessage !== ''} autoHideDuration={3000} onClose={() => setSuccessMessage('')}>
                <Alert onClose={() => setSuccessMessage('')}>{ successMessage }</Alert>
            </Snackbar>
            <Snackbar open={isRecordDeleted} autoHideDuration={1200} onClose={() => navigate('/jobs/')}>
                <Alert onClose={() => navigate('/jobs/')}>Record deleted</Alert>
            </Snackbar>
            <Snackbar open={errorMessage !== ''} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <Snackbar open={Boolean(tmpErrorMessage)} autoHideDuration={3000} onClose={() => setTmpErrorMessage('')}>
                <Alert severity="error" onClose={() => setTmpErrorMessage('')}>{tmpErrorMessage}</Alert>
            </Snackbar>
            <PageLayout paddingTop={0} SummaryBar={SummaryBar}>
                <SendEmailDialog
                    open={ emailRecipient !== null }
                    sourceEntityId={entityTypeId}
                    jobId={jobId}
                    allowCcField
                    recipients={ emailRecipient ? [emailRecipient] : [] }
                    recipientEntityTypeId={ contactEntityType }
                    closeHandler={ () => setEmailRecipient(null) }
                    loadingHandler={setIsLoading}
                    errorHandler={setErrorMessage}
                    successHandler={setSuccessMessage}
                />
                <SendSmsDialog
                    open={ smsRecipient !== null }
                    sourceEntityId={entityTypeId}
                    jobId={jobId}
                    recipients={ smsRecipient ? [smsRecipient] : [] }
                    recipientEntityTypeId={ contactEntityType }
                    closeHandler={ () => setSmsRecipient(null) }
                    loadingHandler={setIsLoading}
                    errorHandler={setErrorMessage}
                    successHandler={setSuccessMessage}
                />
                <ConfirmationDialog
                    message="Are you sure you want to delete this record?"
                    onClose={ () => setShowDeleteDialog(false) }
                    onContinue={ deleteRecordCallback }
                    open={showDeleteDialog}
                    title="Confirm Action"
                    confirmActionText="Yes"
                    cancelActionText="No"
                />
                <ConfirmationDialog
                    message="Are you sure you want to Reopen this Job?"
                    onClose={ () => setShowReopenDialog(false) }
                    onContinue={ reopenJobCallback }
                    open={showReopenDialog}
                    title="Confirm Action"
                    confirmActionText="Yes"
                    cancelActionText="No"
                />
                <ConfirmationDialog
                    message="Are you sure you want to put this Job on Hold?"
                    onClose={ () => setShowPutOnHoldDialog(false) }
                    onContinue={ putJobOnHoldCallback }
                    open={showPutOnHoldDialog}
                    title="Confirm Action"
                    confirmActionText="Yes"
                    cancelActionText="No"
                />
                <ResetJobStageDialog
                    isOpen={showResetStageDialog}
                    jobId={jobId}
                    jobStage={job ? job.stageID: 0}
                    jobStageName={job ? job.stageName : ''}
                    jobTitle={job ? job.title : ''}
                    closeHandler={ () => setShowResetStageDialog(false) }
                    onSuccessHandler={ onResetStageSuccess }
                    errorHandler={setErrorMessage}
                    loadingHandler={setIsLoading}
                />
                <ConfirmationWithDateDialog
                    open={isResetEstimatedPlacementDateOpen}
                    initialDateValue={job ? job.estimatedPlacementDate : ''}
                    onClose={ () => setIsResetEstimatedPlacementDateOpen(false) }
                    onContinue={ resetEstimatedPlacementDateCallback }
                    title="Confirm Action"
                    dateType="date"
                    dateFieldLabel="Estimated placement date"
                    disablePast
                />
                <ConfirmationWithDateDialog
                    open={isUpdateDeadlineDialogOpen}
                    initialDateValue={job ? job.deadline : ''}
                    onClose={ () => setIsUpdateDeadlineDialogOpen(false) }
                    onContinue={ updateDeadlineCallback }
                    title="Confirm Action"
                    dateType="date"
                    dateFieldLabel="New deadline"
                    disablePast
                />
                <CloseJobsDialog
                    isOpen={ isCloseJobsDialogOpen }
                    closeHandler={ () => setIsCloseJobsDialogOpen(false) }
                    jobIds={ [jobId] }
                    errorHandler={setErrorMessage}
                    successHandler={closeJobsSuccessCallback}
                />
                <CreateAdvertDialog
                    open={isCreateAdvertDialogOpen}
                    jobId={jobId}
                    closeHandler={ () => setIsCreateAdvertDialogOpen(false) }
                    onSuccessHandler={ onCreateAdvertSuccess }
                    errorHandler={setErrorMessage}
                    loadingHandler={setIsLoading}
                />
                <EditActivityDialog
                    closeHandler={() => setSelectedActivity(null)}
                    data={job && selectedActivity
                        ? {
                            ...DefaultActivity,
                            jobID: jobId,
                            jobTitle: job.title,
                            category: 1,
                            type: selectedActivity.customName,
                            typeID: selectedActivity.id
                          }
                        : null
                    }
                    open={ Boolean(selectedActivity) }
                    loadingHandler={setIsLoading}
                    errorHandler={setErrorMessage}
                    successHandler={activityCreatedSuccessCallback}
                />
                <PreviewLoaderComponent
                    open={showTagsPrevew}
                    entityType="job"
                    recordId={jobId}
                    showDelayMs={showTagsPrevewNoDelay ? 0 : undefined}
                    isTagsPreview
                />
                <CreateInvoiceDialog
                    closeHandler={() => setShowCreateInvoiceDialog(false)}
                    jobId={jobId}
                    consultant1Id={job ? job.splitRecipientID1 : 0}
                    consultant2Id={job ? job.splitRecipientID2 : 0}
                    consultant3Id={job ? job.splitRecipientID3 : 0}
                    consultant4Id={job ? job.splitRecipientID4 : 0}
                    consultant5Id={job ? job.splitRecipientID5 : 0}
                    consultant1Percent={job ? job.splitPercentage1 : 0}
                    consultant2Percent={job ? job.splitPercentage2 : 0}
                    consultant3Percent={job ? job.splitPercentage3 : 0}
                    consultant4Percent={job ? job.splitPercentage4 : 0}
                    consultant5Percent={job ? job.splitPercentage5 : 0}
                    open={showCreateInvoiceDialog}
                    loadingHandler={setIsLoading}
                    errorHandler={setErrorMessage}
                    successHandler={onCreateInvoiceSuccess}
                /> 
                <ChangeTypeDialog
                    closeHandler={() => setShowChangeTypeDialog(false)}
                    jobId={jobId}
                    open={showChangeTypeDialog}
                    type={job ? job.type : ''}
                    loadingHandler={setIsLoading}
                    errorHandler={setErrorMessage}
                    successHandler={onTypeChangeHandler}
                /> 
                <ChangeSplitsDialog
                    closeHandler={() => setShowSplitsDialog(false)}
                    jobId={jobId}
                    open={showSplitsDialog}
                    consultant1Id={job ? job.splitRecipientID1 : 0}
                    consultant2Id={job ? job.splitRecipientID2 : 0}
                    consultant3Id={job ? job.splitRecipientID3 : 0}
                    consultant4Id={job ? job.splitRecipientID4 : 0}
                    consultant5Id={job ? job.splitRecipientID5 : 0}
                    consultant1Percent={job ? job.splitPercentage1 : 0}
                    consultant2Percent={job ? job.splitPercentage2 : 0}
                    consultant3Percent={job ? job.splitPercentage3 : 0}
                    consultant4Percent={job ? job.splitPercentage4 : 0}
                    consultant5Percent={job ? job.splitPercentage5 : 0}
                    loadingHandler={setIsLoading}
                    errorHandler={setTmpErrorMessage}
                    successHandler={onSplitsChangeHandler}
                /> 
                <TagsManagementDialog
                    open={showTagsManagement}
                    entityId={4}
                    recordIds={jobIdArray}
                    closeHandler={ () => setShowTagsManagement(false) }
                    loadingHandler={ setIsLoading }
                    errorHandler={ setErrorMessage }
                    successHandler={ tagManagementSuccessHandler }
                />
                <UploadCvDialog
                    open={showUploadCvDialog}
                    candidateId={0}
                    jobId={jobId}
                    closeHandler={ () => setShowUploadCvDialog(false) }
                    errorHandler={setErrorMessage}
                    loadingHandler={setIsLoading}
                    successHandler={setSuccessMessage}
                />
                <UploadDescriptionDialog
                    open={showUploadDescriptionDialog}
                    jobId={jobId}
                    closeHandler={ () => setShowUploadDescriptionDialog(false) }
                    errorHandler={setErrorMessage}
                    loadingHandler={setIsLoading}
                    successHandler={setSuccessMessage}
                />
                <LinkJobToCandidateDialog
                    open={showLinkJobToCandidateDialog}
                    closeHandler={() => setShowLinkJobToCandidateDialog(false)}
                    jobId={jobId}
                    loadingHandler={setIsLoading}
                    errorHandler={setErrorMessage}
                    successHandler={setSuccessMessage}
                />
                <GenerateDocumentDialog
                    open={showGenerateDocumentsDialog}
                    entityId={entityTypeId}
                    playerId={jobId}
                    closeHandler={() => setShowGenerateDocumentsDialog(false)}
                    loadingHandler={ setIsLoading }
                    errorHandler={ setErrorMessage }
                    successHandler={ setSuccessMessage }
                />
                <Tabs value={activeTab} onChange={handleTabChange}>
                    <Tab value="JOBS_Home" label={<>Home<br />&nbsp;</>} />
                    <Tab value="Description" label={<>Description<br />&nbsp;</>} />
                    {canAccessCandidates && <Tab value="JOBS_Candidates" label={<>Candidates ({counts.candidates})<br />&nbsp;</>} />}
                    {canAccessAdverts && <Tab value="JOBS_Adverts" label={<>Adverts ({counts.adverts})<br />{counts.advertsDate}</>} />}
                    {canAccessActivities && <Tab value="JOBS_CallsNotes" label={<>Calls/Notes ({counts.callsNotes})<br />{counts.callsNotesDate}</>} />}
                    {/* canAccessActivities && <Tab value="JOBS_Meetings" label={<>Meetings ({counts.meetings})<br />{counts.meetingsDate}</>} /> */}
                    {canAccessActivities && <Tab value="Messages" label={<>Messages ({counts.messages})<br />{counts.messagesDate}</>} />}
                    {canAccessActivities && <Tab value="JOBS_Submissions" label={<>Submissions ({counts.submissions})<br />{counts.submissionsDate}</>} />}
                    {canAccessActivities && <Tab value="JOBS_Interviews" label={<>Interviews ({counts.interviews})<br />{counts.interviewsDate}</>} />}
                    {canAccessPlacements && <Tab value="JOBS_Placements" label={<>Placements ({counts.placements})<br />{counts.placementsDate}</>} />}
                    {/*<Tab value="Invoices" label={<>Invoices ({counts.invoices})<br />&nbsp;</>} />*/}
                    <Tab value="JOBS_Documents" label={<>Documents ({counts.documents})<br />&nbsp;</>} />
                    {canAccessActivities && <Tab value="JOBS_Activities" label={<>Activities ({counts.activities})<br />{counts.activitiesDate}</>} />}
                </Tabs>
                <PageContentLayout showLoading={isLoading || isFetchingDefaultTab || isLoadingTab}>
                    <ShowIframeDialog open={showPartnerActionPopup} closeHandler={() => setShowPartnerActionPopup(false)} title={popupTitle} url={popupUrl} />
                    { isFinishedSetup && activeTab === "JOBS_Home" &&
                        <ViewRecordScreenLayout
                            job={job ? job : null}
                            loadingHandler={setIsLoadingTab}
                            errorHandler={setErrorMessage}
                        />
                    }
                    { isFinishedSetup && activeTab === "Description" &&
                        <JobDescriptionRenderer jobId={jobId} loadingHandler={setIsLoadingTab} errorHandler={setTmpErrorMessage} />
                    }
                    { isFinishedSetup && canAccessCandidates && activeTab === "JOBS_Candidates" &&
                        <>
                            {jobsView === 'kanban' &&
                                <CandidateKanban
                                    jobId={jobId}
                                    advertId={advertIdFilter}
                                    extraActions={openJobsViewSwitch}
                                    loadingHandler={setIsLoadingTab}
                                    errorHandler={setErrorMessage}
                                />
                            }
                            {jobsView === 'list' &&
                                <JobTrackingGridComponent
                                    source="job-candidates"
                                    gridName="job/Candidates"
                                    jobId={jobId}
                                    job={job}
                                    advertId={advertIdFilter}
                                    extraActions={openJobsViewSwitch}
                                    loadingHandler={setIsLoadingTab}
                                    errorHandler={setTmpErrorMessage}
                                    successHandler={setSuccessMessage}
                                />
                            }
                        </>
                    }
                    { isFinishedSetup && canAccessAdverts && activeTab === "JOBS_Adverts" &&
                        <AdvertsGridComponent
                            loadingHandler={setIsLoadingTab}
                            errorHandler={setErrorMessage}
                            successHandler={setSuccessMessage}
                            gridName="job/Adverts"
                            source='job-record'
                            sourceId={jobId}
                        />
                    }
                    { isFinishedSetup && canAccessActivities && activeTab === "JOBS_CallsNotes" &&
                        <ActivitiesGridComponent
                            source="job-record"
                            sourceId={jobId}
                            categoryFilter={1}
                            loadingHandler={ setIsLoadingTab }
                            errorHandler={ setErrorMessage }
                            successHandler={ setSuccessMessage }
                            gridName="job/CallsNotes"
                            refreshControl={refreshActivitiesGridControl}
                            hideActionsMenu
                        />
                    }
                    { isFinishedSetup && canAccessActivities && activeTab === "JOBS_Meetings" &&
                        <MeetingsGridComponent
                            source="job-record"
                            sourceId={jobId}
                            loadingHandler={ setIsLoadingTab }
                            errorHandler={ setErrorMessage }
                            successHandler={ setSuccessMessage }
                            gridName="job/Meetings"
                        />
                    }
                    {   isFinishedSetup && canAccessActivities && activeTab === "Messages" &&
                        <ActivityMessagesGridComponent
                            source="job-record"
                            sourceId={jobId}
                            errorHandler={setErrorMessage}
                            loadingHandler={setIsLoadingTab}
                            successHandler={setSuccessMessage}
                            gridName="jobs/Messages"
                        />
                    }
                    { isFinishedSetup && canAccessActivities && activeTab === "JOBS_Submissions" &&
                        <SubmissionsGridComponent
                            source="job-record"
                            sourceId={jobId}
                            loadingHandler={setIsLoadingTab}
                            errorHandler={setErrorMessage}
                            successHandler={setSuccessMessage}
                            gridName="job/Submissions"
                        />
                    }
                    { isFinishedSetup && canAccessActivities && activeTab === "JOBS_Interviews" &&
                        <InterviewsGridComponent
                            source="job-record"
                            sourceId={jobId}
                            loadingHandler={setIsLoadingTab}
                            errorHandler={setErrorMessage}
                            successHandler={setSuccessMessage}
                            gridName="job/Interviews"
                        />
                    }
                    { isFinishedSetup && canAccessPlacements && activeTab === "JOBS_Placements" &&
                        <PlacementsGridComponent
                            source="job-record"
                            sourceId={jobId}
                            loadingHandler={setIsLoadingTab}
                            errorHandler={setErrorMessage}
                            successHandler={setSuccessMessage}
                            gridName="jobs/Placements"
                        />
                    }
                    {isFinishedSetup && activeTab === "Invoices" &&
                        <InvoicesGridComponent
                        source="job-record"
                        sourceId={jobId}
                        loadingHandler={setIsLoadingTab}
                        errorHandler={setErrorMessage}
                        successHandler={setSuccessMessage}
                        gridName="jobs/invoices"
                        refreshControl={refreshInvoicesGrid}
                        />
                    }
                    { isFinishedSetup && activeTab === "JOBS_Documents" &&
                        <DocumentsGridComponent
                            gridName="job/Documents"
                            source="job-record"
                            sourceId={jobId}
                            loadingHandler={setIsLoadingTab}
                            errorHandler={setErrorMessage}
                            successHandler={setSuccessMessage}
                        />
                    }
                    { isFinishedSetup && canAccessActivities && activeTab === "JOBS_Activities" &&
                        <ActivitiesGridComponent
                            source="job-record"
                            sourceId={jobId}
                            loadingHandler={ setIsLoadingTab }
                            errorHandler={ setErrorMessage }
                            successHandler={ setSuccessMessage }
                            gridName="job/Activities"
                            refreshControl={refreshActivitiesGridControl}
                            hideActionsMenu
                        />
                    }
                </PageContentLayout>
            </PageLayout>
        </>
    );
}
