import { ErrorHandler, RequestOptions } from "../common/models/GenericTypes";
import { Get } from "../util/HttpUtils";

export async function GetConsultantReportData(owner: number, start_date: string, end_date: string,sourceType: number, sourceIds: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };
    
    let url = `api/misc/reports/consultant?startDate=${start_date}&endDate=${end_date}&sourceType=${sourceType}`;

    if(sourceIds !== '') {
        url = url + `&sourceIds=${encodeURIComponent(sourceIds)}`
    }


    return Get<any>(url, options);
}

export async function GetSpellerConsultantReportData(owner: number, start_date: string, end_date: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<any>(`api/misc/reports/custom-speller-consultant?owner=${owner}&startDate=${start_date}&endDate=${end_date}`, options);
}