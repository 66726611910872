import React from 'react';
import ReactDOM from 'react-dom';
import { registerLicense } from '@syncfusion/ej2-base'
import rg4js from 'raygun4js';
import App from './App';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

// const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

registerLicense('Mgo+DSMBaFt8QHJqU01hXk5Hd0BLVGpAblJ3T2ZQdVt5ZDU7a15RRnVeQF1gSHhXf0VrXXxfcg==');

if (process.env.NODE_ENV !== 'development') {
  rg4js('apiKey', 'X92wLHwQBdOKQASq9oTvg');
  rg4js('enableCrashReporting', true);
  rg4js('enablePulse', true);
  rg4js('options', {
    ignore3rdPartyErrors: true
  });
}

ReactDOM.render( <App />, rootElement);