import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { DataGridPremium, GridColDef, GridRenderCellParams } from "@mui/x-data-grid-premium";
import React, { useEffect, useMemo, useState } from "react";
import { ReferenceCheck } from "common/models/ReferenceChecks/ReferenceCheckRequest";
import { GetReferenceChecks } from "services/ReferenceCheckService";

interface Props {
    open: boolean,
    requestId: number,
    onClose: () => void,
    loadingHandler: (isLoading: boolean) => void
}

export default function ReferenceCheckDialog({ open, requestId, onClose, loadingHandler }: Props) {
    const [rows, setRows] = useState<ReferenceCheck[]>([]);

    useEffect(() => {
        const getData = async () => {
            loadingHandler(true);
            const data = await GetReferenceChecks(requestId);
            if (data) setRows(data);
            loadingHandler(false);
        };
        getData();
    }, [requestId, loadingHandler]);
   

    const columns = useMemo<GridColDef[]>(() => {

        const fraudTextRenderer = (params: GridRenderCellParams) => {
            return (
                <span>{params.row.possibleFraud ? 'Possible Fraud Detected' : 'No'}</span>
            );
        };

        return [
            { field: 'id', headerName: 'Id', width: 50},
            { field: 'possibleFraud', headerName: 'Possible Fraud Detected', width: 150, renderCell: fraudTextRenderer},
            { field: 'referee', headerName: 'Referee', width: 150 },
            { field: 'status', headerName: 'Status', width: 200 },
            { field: 'requestDate', headerName: 'Request Date', width: 150 },
            { field: 'completedDate', headerName: 'Completed Date', width: 150 },
            { field: 'templateName', headerName: 'Template Name', width: 150 },
            { field: 'isDigital', headerName: 'Is Digital Request', width: 150 },
        ]
    }, []);

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
            <DialogTitle>Reference Checks</DialogTitle>
            <DialogContent>
                <div style={{ height: '100%', width: '100%' }}>
                    <div style={{ display: 'flex', height: '100%', minHeight: '300px' }}>
                        <div style={{ flexGrow: 1 }}>
                            <DataGridPremium
                                rows={rows}
                                columns={columns}
                                getRowId={ row => row.id }
                                disableRowSelectionOnClick
                                disableColumnMenu
                                disableColumnReorder
                                hideFooter
                                density="compact"
                                getRowClassName={(params) => params.row.possibleFraud ? 'possible-fraud' : ''}
                                sx={{
                                    '& .possible-fraud': {
                                      backgroundColor: 'red',
                                      color: 'white',
                                      '&:hover': {
                                        backgroundColor: 'red',
                                        color: 'white'
                                      },
                                    },
                                  }}    
                            />
                        </div>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={onClose}>Ok</Button>
            </DialogActions>
        </Dialog>
    );
}