import React, { useCallback, useEffect, useState } from "react";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Alert from "components/Alert";
import Button from "@mui/material/Button";
import DeleteIcon from '@mui/icons-material/Delete';
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import Snackbar from "@mui/material/Snackbar";

import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import PageContentLayout from "layouts/PageContentLayout";
import { GetJobSources, SaveJobSources } from "services/ConfigurationService";
import { ChangeTracker } from "common/models/hooks/ChangeTracker";
import useObjectStateWithChangeTracker from "hooks/UseObjectStateWithChangeTracker";
import useUnsavedChangesDialog from "hooks/UseUnsavedChangesDialog";

interface Props {
    setSummaryBar?: (summaryBar: JSX.Element) => void
}

interface StringValue { value: string };
const defaultState: StringValue = { value: '' };
const noChangesState: ChangeTracker<StringValue> = { value: false };

export default function JobsSourcesAdmin({ setSummaryBar }: Props) {
    const [isLoading, setIsLoading] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [sources, setSources] = useState<string[]>([]);
    const [newSource, setNewSource] = useState('');
    const { init, change, updateInitial, hasChanges } = useObjectStateWithChangeTracker<StringValue>(defaultState, noChangesState);

    const saveSourcesCallback = useCallback( async () => {
        setIsLoading(true);
        const res = await SaveJobSources(sources, setErrorMessage);
        if(!res) {
            setIsLoading(false);
            return false;
        }
        updateInitial();
        setShowSuccess(true);
        setIsLoading(false);
        return true;
    }, [sources, updateInitial]);

    useEffect(() => {
        const action = <Button variant="contained" color="primary" disabled={!hasChanges} onClick={saveSourcesCallback}>Save</Button>;
        const summaryBar = <TitleAndActionSummaryBar title="Configuration > Jobs > Sources" browserTabTitle="Jobs > Configuration" action={action} />;
        setSummaryBar && setSummaryBar(summaryBar);
    }, [setSummaryBar, saveSourcesCallback, hasChanges]);

    useEffect(() => {
        const getData = async () => {
            setIsLoading(true);
            const data = await GetJobSources(setErrorMessage);
            if(data) {
                setSources(data);
                init({ value: JSON.stringify(data) });
            }
            setIsLoading(false);
        };
        getData();
    }, [init]);

    const changeSourceHandler = (i: number, val: string) => {
        setSources(prev => {
            let newSources = [...prev];
            newSources[i] = val;
            change("value", JSON.stringify(newSources));
            return newSources;
        });
    };

    const addSourceHandler = () => {
        setSources(prev => {
            let newSources = [...prev, newSource];
            setNewSource('');
            change("value", JSON.stringify(newSources));
            return newSources;
        });
    };

    const deleteSouceHandler = (i: number) => {
        setSources(prev => {
            let newSources = [...prev];
            newSources.splice(i, 1);
            change("value", JSON.stringify(newSources));
            return newSources;
        });
    };

    const { unsavedChangesDialog } = useUnsavedChangesDialog(hasChanges, saveSourcesCallback);

    return (
        <>
            {unsavedChangesDialog}
            <Snackbar open={showSuccess} autoHideDuration={3000} onClose={() => setShowSuccess(false)}>
                <Alert onClose={() => setShowSuccess(false)}>Changes Saved</Alert>
            </Snackbar>
            <Snackbar open={errorMessage !== ''}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <PageContentLayout title="Sources" showLoading={isLoading}>
                <div>
                    <span style={{ width: '85%', display: 'inline-block', textAlign: 'center' }}>Source</span>
                    <span style={{ width: '15%', display: 'inline-block', textAlign: 'center' }}>Action</span>
                </div>
                <div style={{ paddingBottom: '20px' }}>
                    <Input placeholder="New Source" sx={{ width: '85%' }} size="small" value={newSource} onChange={ ({target}) => setNewSource(target.value) } />
                    <span style={{ width: '15%', display: 'inline-block', textAlign: 'center' }}>
                        <IconButton disabled={newSource === ''} onClick={addSourceHandler}><AddCircleIcon /></IconButton>
                    </span>
                </div>
                <Divider />
                {sources.map((v, i) => (
                    <div key={i} style={{ paddingBottom: '10px' }}>
                        <Input sx={{ width: '85%' }} value={v} onChange={ ({target}) => changeSourceHandler(i, target.value) } />
                        <span style={{ width: '15%', display: 'inline-block', textAlign: 'center' }}>
                            <IconButton onClick={ () => deleteSouceHandler(i) }><DeleteIcon /></IconButton>
                        </span>
                    </div>
                ))}
            </PageContentLayout>
        </>
    );
}