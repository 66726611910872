import { GeneralConfigOptions } from '../../../common/models/Configuration/General';
import { ChangeTracker } from '../../../common/models/hooks/ChangeTracker';

export const DefaultOptions: GeneralConfigOptions = {
    ContactUsURL: "",
    CorporateColourCode: "",
    Currency: "9",
    CustomerName: "",
    DefaultCountry: "AU",
    ElectronicSigningProvider: "4",
    ExecutiveSearch: "False",
    FacebookURL: "",
    HideDetailsInterviewCommunications: "True",
    IsInvoicingEnabled: "False",
    IsComplianceEnabled: "False",
    JobsURL: "",
    ApplicationSuccessURL: "",
    LabourHire: "False",
    CandidateResponseEmail: "",
    LinkedInURL: "",
    PrivacyURL: "",
    TradingName: "",
    TwitterURL: "",
    WebsiteURL: "",
    IsConfirmToSend: "true",
    EmailLimit: 0,
    AutoSaveCandidateSearches: "False",
    WhoCanMergeRecords: 'Anyone',
}

export const NoChangesTracker: ChangeTracker<GeneralConfigOptions> = {
    ContactUsURL: false,
    CorporateColourCode: false,
    Currency: false,
    CustomerName: false,
    DefaultCountry: false,
    ElectronicSigningProvider: false,
    ExecutiveSearch: false,
    FacebookURL: false,
    HideDetailsInterviewCommunications: false,
    IsInvoicingEnabled: false,
    IsComplianceEnabled: false,
    JobsURL: false,
    ApplicationSuccessURL: false,
    LabourHire: false,
    CandidateResponseEmail: false,
    LinkedInURL: false,
    PrivacyURL: false,
    TradingName: false,
    TwitterURL: false,
    WebsiteURL: false,
    IsConfirmToSend: false,
    EmailLimit: false,
    AutoSaveCandidateSearches: false,
    WhoCanMergeRecords: false,
}

