import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import TextField from "@mui/material/TextField";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { DocumentTemplate, DocumentTemplatePlaceholder } from "common/models/Templates/DocumentTemplate";
import { TemplatePlaceholder } from "common/models/Templates/TemplatePlaceholder";
import FilePicker from "components/Pickers/FilePicker";
import UserPicker from "components/Pickers/UserPicker";
import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import PlaceholderTransferList from "components/Templates/PlaceholderTransferList";
import PageContentLayout from "layouts/PageContentLayout";
import PageLayout from "layouts/PageLayout";
import { CreateDocumentTemplate, DownloadDocumentTemplateFile, GetDocumentTemplateById, GetDocumentTemplatesPlaceholders, UpdateDocumentTemplate, UpdateDocumentTemplateFile } from "services/TemplatesService";
import Snackbar from "@mui/material/Snackbar";
import Alert from "components/Alert";
import { Accept } from "react-dropzone";
import { NameIdObj, SimpleFileObj } from "common/models/GenericTypes";
import { DefaultDocumentTemplate } from "util/Definitions/Templates/DocumentTemplate";
import useUnsavedChangesDialog from "hooks/UseUnsavedChangesDialog";
import InsertPlaceholderDialog from "components/Dialogs/Generic/InsertPlaceholderDialog";
import { IconButton, Tooltip } from "@mui/material";
import SearchCircleIcon from '@mui/icons-material/Search';
import RWTextFieldComponent from "components/RWTextFieldComponent";

const documentsListLink = <Link to="/account/templates/documents" style={{ color: 'inherit', textDecoration: 'underline' }}>Documents</Link>;

const signatorTypes: ReactNode[] = [
    <MenuItem key="0" value="0">Nobody</MenuItem>,
    <MenuItem key="1" value="1">User</MenuItem>,
    <MenuItem key="2" value="2">Candidate</MenuItem>,
    <MenuItem key="3" value="3">Hiring Manager</MenuItem>,
    <MenuItem key="4" value="4">Client Contact</MenuItem>,
    <MenuItem key="5" value="5">Billing Contact 1</MenuItem>,
    <MenuItem key="8" value="8">Billing Contact 2</MenuItem>,
    <MenuItem key="9" value="9">Billing Contact 3</MenuItem>,
    <MenuItem key="6" value="6">Signatory Contact</MenuItem>,
    <MenuItem key="7" value="7">Payment Contact</MenuItem>,
    <MenuItem key="10" value="10">Primary Contact</MenuItem>,
    <MenuItem key="11" value="11">Contact ( Contact Docs Only )</MenuItem>,
];

const companyOwnedUser: NameIdObj[] = [{ id: 0, name: 'Company Owned' }];
const signatoryUsersExtra: NameIdObj[] = [
    { id: -1, name: 'Current User' }, 
    { id: -2, name: 'Consultant 1' }
];

const wordDocFilter: Accept = {
    // "application/msword": ['.doc'],
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": ['.docx']
};

export default function DocumentTemplateItemPage() {
    const [validateStep1, setValidateStep1] = useState(false);
    const [validateStep2, setValidateStep2] = useState(false);
    const [validateStep3, setValidateStep3] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [infoMessage, setInfoMessage] = useState("");
    const [isChangesSaved, setIsChangesSaved] = useState(false);
    const [templateData, setTemplateData] = useState(DefaultDocumentTemplate);
    const [initialTemplateData, setInitialTemplateData] = useState(DefaultDocumentTemplate);
    const [availablePlaceholders, setAvailablePlaceholders] = useState<TemplatePlaceholder[]>([]);
    const [fileToUpload, setFileToUpload] = useState<File[]>([]);
    const [uploadedFile, setUploadedFile] = useState<SimpleFileObj[]>([]);
    const [showPlaceHolders, setShowPlaceHolders] = useState(false);
    const navigate = useNavigate();
    const params = useParams();

    const itemId = useMemo(() => {
        const parsedId = +(params.id ?? '0');
        if(isNaN(parsedId)) return 0;
        return parsedId
    }, [params.id]);

    useEffect(() => {
        if (itemId !== 0) {
            const getData = async () => {
                setIsLoading(true);
                const res = await GetDocumentTemplateById(itemId, setErrorMessage);
                if (res) {
                    setTemplateData(res);
                    setInitialTemplateData(res);
                    if (res.fileName !== '') setUploadedFile([{ id: 0, name: res.fileName, size: 0 }]);
                }
                setIsLoading(false);
            };
            getData();
        }
    }, [itemId]);

    useEffect(() => {
        const getData = async () => {
            setIsLoading(true);
            const res = await GetDocumentTemplatesPlaceholders();
            if (res) {
                //Code is added to add the Template.Name placeholder in the InsertPlaceholderDialog component.
                //In the backend we replace the Template.Name placeholder with the actual template name.
                let modifiedList: TemplatePlaceholder[] = [ {
                    friendlyName: 'Template Name',
                    placeholder: 'Template.Name',
                    placeholderUsage: "#{Template.Name}",
                    value: ''
                },
                {
                    friendlyName: 'Screening - Questions And Responses',
                    placeholder: 'Screening.QuestionsAndResponses',
                    placeholderUsage: "#{Screening.QuestionsAndResponses}",
                    value: ''
                },
                {
                    friendlyName: 'Reference Check - Questions and Responses',
                    placeholder: 'ReferenceCheck.QuestionsAndResponses',
                    placeholderUsage: "#{ReferenceCheck.QuestionsAndResponses}",
                    value: ''
                },
                {
                    friendlyName: 'Reference Check - Referee',
                    placeholder: 'ReferenceCheck.Referee',
                    placeholderUsage: "#{ReferenceCheck.Referee}",
                    value: ''
                }
            ];
                
                setAvailablePlaceholders(modifiedList.concat(res));
            }

            setIsLoading(false);
        };
        getData();
    }, []);

    const hasChanges = useMemo(() => {
        if (itemId === 0) {
            if (isChangesSaved) return false;
            return true;
        }
        const t = templateData;
        const i = initialTemplateData;
        const placeholdersChanged = t.placeholders.length !== i.placeholders.length;
        if (placeholdersChanged) return true;

        const basicValuesChanged = t.name !== i.name || t.typeID !== i.typeID || t.statusID !== i.statusID || t.ownerID !== i.ownerID
            || t.electronicSigningMessage !== i.electronicSigningMessage || t.electronicSigningSubject !== i.electronicSigningSubject
            || t.isElectronicSigningEnabled !== i.isElectronicSigningEnabled || t.fileName !== i.fileName || t.generatedFileName !== i.generatedFileName;
        if (basicValuesChanged) return true;

        const signatorsChanged = t.signatoryType1 !== i.signatoryType1 || t.signatoryUserID1 !== i.signatoryUserID1
            || t.signatoryType2 !== i.signatoryType2 || t.signatoryUserID2 !== i.signatoryUserID2
            || t.signatoryType3 !== i.signatoryType3 || t.signatoryUserID3 !== i.signatoryUserID3
            || t.signatoryType4 !== i.signatoryType4 || t.signatoryUserID4 !== i.signatoryUserID4
            || t.signatoryType5 !== i.signatoryType5 || t.signatoryUserID5 !== i.signatoryUserID5
            || t.signatoryType6 !== i.signatoryType6 || t.signatoryUserID6 !== i.signatoryUserID6
            || t.signatoryType7 !== i.signatoryType7 || t.signatoryUserID7 !== i.signatoryUserID7
            || t.signatoryType8 !== i.signatoryType8 || t.signatoryUserID8 !== i.signatoryUserID8
            || t.signatoryType9 !== i.signatoryType9 || t.signatoryUserID9 !== i.signatoryUserID9
            || t.signatoryType10 !== i.signatoryType10 || t.signatoryUserID10 !== i.signatoryUserID10;
        if (signatorsChanged) return true;

        const tPlaceholdersArray = t.placeholders.map(v => ({ placeholder: v.placeholder, isEditable: v.isEditable }));
        const iPlaceholdersArray = i.placeholders.map(v => ({ placeholder: v.placeholder, isEditable: v.isEditable }));
        const tPlaceholders = JSON.stringify(tPlaceholdersArray);
        const iPlaceholders = JSON.stringify(iPlaceholdersArray);
        if (tPlaceholders !== iPlaceholders) return true;

        return false;
    }, [itemId, initialTemplateData, templateData, isChangesSaved]);

    const isStep1Error = useMemo(() => {
        return templateData.name === '' || (templateData.statusID !== 0 && fileToUpload.length !== 1 && uploadedFile.length !== 1);
    }, [templateData.name, templateData.statusID, fileToUpload.length, uploadedFile.length]);

    const isStep2Error = useMemo(() => {
        return templateData.placeholders.length === 0;
    }, [templateData.placeholders.length]);

    const isStep3Error = useMemo(() => {
        if (!templateData.isElectronicSigningEnabled) return false;
        let isSignatorsError = (templateData.signatoryType1 === 1 && templateData.signatoryUserID1 === 0)
            || (templateData.signatoryType2 === 1 && templateData.signatoryUserID2 === 0)
            || (templateData.signatoryType3 === 1 && templateData.signatoryUserID3 === 0)
            || (templateData.signatoryType4 === 1 && templateData.signatoryUserID4 === 0)
            || (templateData.signatoryType5 === 1 && templateData.signatoryUserID5 === 0)
            || (templateData.signatoryType6 === 1 && templateData.signatoryUserID6 === 0)
            || (templateData.signatoryType7 === 1 && templateData.signatoryUserID7 === 0)
            || (templateData.signatoryType8 === 1 && templateData.signatoryUserID8 === 0)
            || (templateData.signatoryType9 === 1 && templateData.signatoryUserID9 === 0)
            || (templateData.signatoryType10 === 1 && templateData.signatoryUserID10 === 0);
        return templateData.electronicSigningMessage === '' || templateData.electronicSigningSubject === '' || isSignatorsError;
    }, [templateData.signatoryType1, templateData.signatoryUserID1, templateData.signatoryType2, templateData.signatoryUserID2,
        templateData.signatoryType3, templateData.signatoryUserID3, templateData.signatoryType4, templateData.signatoryUserID4,
        templateData.signatoryType5, templateData.signatoryUserID5, templateData.signatoryType6, templateData.signatoryUserID6,
        templateData.signatoryType7, templateData.signatoryUserID7, templateData.signatoryType8, templateData.signatoryUserID8,
        templateData.signatoryType9, templateData.signatoryUserID9, templateData.signatoryType10, templateData.signatoryUserID10,
        templateData.electronicSigningMessage, templateData.electronicSigningSubject, templateData.isElectronicSigningEnabled]);

    const nextStepHandler = useCallback(() => {
        let canContinue = true;
        if(activeStep === 0 && !validateStep1) setValidateStep1(true);
        else if(activeStep === 1 && !validateStep2) setValidateStep2(true);
        else if(activeStep === 2 && !validateStep3) setValidateStep3(true);
        if (activeStep === 0 && isStep1Error) canContinue = false;
        if (activeStep === 1 && isStep2Error) canContinue = false;
        if (activeStep === 2 && isStep3Error) canContinue = false;
        if (canContinue) setActiveStep(prev => prev +1);
    }, [isStep1Error, isStep2Error, isStep3Error, activeStep, validateStep1, validateStep2, validateStep3]);

    const prevStepHandler = useCallback(() => {
        if(activeStep === 0 && !validateStep1) setValidateStep1(true);
        else if(activeStep === 1 && !validateStep2) setValidateStep2(true);
        else if(activeStep === 2 && !validateStep3) setValidateStep3(true);
        setActiveStep(prev => prev -1);
    }, [activeStep, validateStep1, validateStep2, validateStep3]);

    const isActiveStepLast = useMemo(() => (
        (!templateData.isElectronicSigningEnabled && activeStep === 1) || activeStep === 2
    ), [templateData.isElectronicSigningEnabled, activeStep]);

    const saveHandler = useCallback(async () => {
        const failedValidation = isStep1Error || isStep2Error || isStep3Error;
        if (failedValidation) {
            setValidateStep2(true);
            setValidateStep3(true);
            return false;
        }

        if (templateData.statusID !== 0 && templateData.isElectronicSigningEnabled) {
            const hasNoSignatories = templateData.signatoryType1 === 0 && 
            templateData.signatoryType2 === 0 && templateData.signatoryType3 === 0 && 
            templateData.signatoryType4 === 0 && templateData.signatoryType5 === 0 && 
            templateData.signatoryType6 === 0 && templateData.signatoryType7 === 0 && 
            templateData.signatoryType8 === 0 && templateData.signatoryType9 === 0 && 
            templateData.signatoryType10 === 0;
            if (hasNoSignatories) {
                setErrorMessage('Must have at least one signatory');
                return false;
            }
        }

        if(templateData.generatedFileName === '') {
            setErrorMessage('Generated File name is required.');
            return false;
        }

        setIsLoading(true);
        if (itemId === 0) {
            const file = fileToUpload.length === 1 ? fileToUpload[0] : null;
            const res = await CreateDocumentTemplate(templateData, file, setErrorMessage);
            setIsLoading(false);
            if (res) {
                setFileToUpload([]);
                setIsChangesSaved(true);
                return true;
            }
            else return false;
        }
        else {
            let res: boolean | null = true;
            let res2: boolean | null = true;
            if (!hasChanges && fileToUpload.length !== 1) {
                setIsChangesSaved(true);
                return true;
            }
            if (hasChanges) res = await UpdateDocumentTemplate(itemId, templateData, setErrorMessage);
            if (fileToUpload.length === 1) res2 = await UpdateDocumentTemplateFile(itemId, fileToUpload[0], setErrorMessage);
            setIsLoading(false);
            if (res && res2) {
                setInitialTemplateData(templateData);
                setFileToUpload([]);
                setIsChangesSaved(true);
                return true;
            }
            else return false;
        }

    }, [itemId, templateData, fileToUpload, isStep1Error, isStep2Error, isStep3Error, hasChanges]);

    const summaryBar = useMemo(() => {
        const action = (
            <>
                <Button variant="contained" disabled={activeStep === 0} onClick={ prevStepHandler } sx={{ mr: '5px' }} startIcon={<KeyboardArrowLeftIcon />}>Prev</Button>
                { !isActiveStepLast && <Button variant="contained" disabled={activeStep === 2} onClick={ nextStepHandler } endIcon={<KeyboardArrowRightIcon />}>Next</Button> }
                { isActiveStepLast && <Button variant="contained" onClick={ saveHandler } color="success" endIcon={<KeyboardArrowRightIcon />}>Save</Button> }
            </>
        );
        return (
            <TitleAndActionSummaryBar
                action={action}
                title={<>{'Account > Templates > '}{documentsListLink}</>}
                browserTabTitle="Documents > Templates"
            />
        );
    }, [activeStep, isActiveStepLast, prevStepHandler, nextStepHandler, saveHandler]);

    const stringChangeHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        const key = e.target.name as keyof DocumentTemplate;
        setTemplateData(prev => ({...prev, [key]: value}));
    }, []);

    const numberChangeHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>,templateChange: boolean = false) => {
        const { value } = e.target;
        const key = e.target.name as keyof DocumentTemplate;
        setTemplateData(prev => ({...prev, [key]: +value}));
        if(templateChange) {
            if(+value === 8) {
                setTemplateData(prev => ({...prev,'generatedFileName': '#{Template.Name}_#{Candidate.FullName}_#{CurrentDate}'}));
            } else if(+value === 6) {
                setTemplateData(prev => ({...prev,'generatedFileName': '#{Template.Name}_#{Client.Name}_#{CurrentDate}'}));
            } else if(+value === 7) {
                setTemplateData(prev => ({...prev,'generatedFileName': '#{Template.Name}_#{Contact.ClientName}_#{CurrentDate}'}));
            } else if(+value === 1) {
                setTemplateData(prev => ({...prev,'generatedFileName': '#{Template.Name}#{if Job.Title != ""}_#{Job.Title}#{/if}#{if Job.Client.TradingName != ""}_#{Job.Client.TradingName}#{/if}_#{Candidate.FullName}_#{CurrentDate}'}));
            } else if(+value === 9) {
                setTemplateData(prev => ({...prev,'generatedFileName': '#{Template.Name}_#{Job.Client.Name}_#{Job.Title}_#{CurrentDate}'}));
            } else if(+value === 10) {
                setTemplateData(prev => ({...prev,'generatedFileName': '#{Template.Name}_#{Opportunity.Name}_#{Opportunity.ClientName}_#{Opportunity.TypeName}_#{CurrentDate}'}));
            } else if(+value === 2) {
                setTemplateData(prev => ({...prev,'generatedFileName': '#{Template.Name}_#{Placement.Client.Name}_#{Placement.JobTitle}_#{Placement.Candidate.FullName}_#{CurrentDate}'}));
            } else if(+value === 3) {
                setTemplateData(prev => ({...prev,'generatedFileName': '#{Template.Name}_#{Placement.Client.Name}_#{Placement.JobTitle}_#{Placement.Candidate.FullName}_#{CurrentDate}'}));
            } else if(+value === 11) {
                setTemplateData(prev => ({...prev,'generatedFileName': '#{Template.Name}_Screening_#{Candidate.FullName}_#{CurrentDate}'}));
            }
            else if(+value === 12) {
                setTemplateData(prev => ({...prev,'generatedFileName': '#{Template.Name}_Reference_Check_#{Candidate.FullName}_#{CurrentDate}'}));
            }
        }
    }, []);

    const booleanChangeHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        const key = e.target.name as keyof DocumentTemplate;
        setTemplateData(prev => ({...prev, [key]: value === "1"}));
    }, []);

    const setPlaceholdersHandler = useCallback((newPlaceholders: DocumentTemplatePlaceholder[]) => {
        setTemplateData(prev => ({...prev, placeholders: newPlaceholders}));
    }, []);

    const downloadFileCallback = useCallback(async (file: SimpleFileObj) => {
        setIsLoading(true);
        await DownloadDocumentTemplateFile(itemId, setErrorMessage);
        setIsLoading(false);
    }, [itemId]);

    const deleteUploadedFileHandler = useCallback((index: number) => {
        setTemplateData(prev => ({ ...prev, fileID: 0, fileName: '' }));
        setUploadedFile([]);
    }, []);

    const { unsavedChangesDialog, hasBlockedRoute } = useUnsavedChangesDialog(hasChanges || fileToUpload.length === 1, saveHandler);

    useEffect(() => {
        if (hasBlockedRoute) return;
        if (itemId === 0 && !hasChanges && isChangesSaved) {
            navigate('/account/templates/documents');
        }
        else if (itemId !== 0 && !hasChanges && isChangesSaved && fileToUpload.length === 0) {
            navigate('/account/templates/documents');
        }
    }, [itemId, hasChanges, isChangesSaved, fileToUpload, hasBlockedRoute, navigate]);

    useEffect(() => {
        if (validateStep2 && templateData.placeholders.length === 0) setErrorMessage("At least one placeholder must be selected");
        else setErrorMessage('');
    }, [templateData.placeholders.length, validateStep2]);

    const insertPlaceholderHandler = useCallback((placeholder: string) => {
        setTemplateData((prev) => {
            const value = `${prev.generatedFileName}_${placeholder}`
            return {...prev,'generatedFileName': value};
          });
    }, []);

    return (
        <PageLayout SummaryBar={summaryBar}>
            {unsavedChangesDialog}
            {/* {isChangesSaved && <Navigate to="/account/templates/documents" />} */}
            <Snackbar open={errorMessage !== ''} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <Snackbar open={Boolean(infoMessage)} autoHideDuration={1500} onClose={() => setInfoMessage('')}>
                <Alert severity="info" onClose={() => setInfoMessage('')}>{infoMessage}</Alert>
            </Snackbar>
            <PageContentLayout showLoading={isLoading}>
                <Stepper nonLinear activeStep={activeStep}>
                    <Step><StepLabel error={validateStep1 && isStep1Error}>Template</StepLabel></Step>
                    <Step><StepLabel error={validateStep2 && isStep2Error}>Placeholders</StepLabel></Step>
                    { templateData.isElectronicSigningEnabled && <Step><StepLabel error={validateStep3 && isStep3Error}>Signatories</StepLabel></Step> }
                </Stepper>
                {activeStep === 0 &&
                    <Stack spacing={2} pt={2} height="100%">
                        <RWTextFieldComponent
                            value={templateData.name}
                            onChange={stringChangeHandler}
                            name="name"
                            label="Name"
                            isError={validateStep1 && templateData.name === ''}
                            helperText={validateStep1 && templateData.name === '' ? "Name must be set" : '' }
                        />
                        <TextField
                            select
                            onChange={ e => {numberChangeHandler(e as React.ChangeEvent<HTMLInputElement>,true)}}
                            name="typeID"
                            value={templateData.typeID.toString()}
                            label="Type"
                            error={validateStep1 && templateData.typeID === 0}
                            helperText={validateStep1 && templateData.typeID === 0 ? "Type must be set" : ''}
                        >
                            {/*<MenuItem value="4">General</MenuItem>*/}
                            <MenuItem value="8">Candidate Docs</MenuItem>
                            <MenuItem value="6">Client Docs</MenuItem>
                            <MenuItem value="7">Contact Docs</MenuItem>
                            <MenuItem value="1">Formatted CV</MenuItem>
                            <MenuItem value="9">Job Docs</MenuItem>
                            <MenuItem value="10">Opportunity Docs</MenuItem>
                            <MenuItem value="2">Placement Docs - Clients</MenuItem>
                            <MenuItem value="3">Placement Docs - Candidate</MenuItem>
                            <MenuItem value="12">Reference Check</MenuItem>
                            <MenuItem value="11">Screening</MenuItem>
                            
                        </TextField>
                        <Box display="flex">
                            <RWTextFieldComponent
                                sxOptions={{ flex: '1 1 0' }}
                                value={templateData.generatedFileName ?? ''}
                                onChange={stringChangeHandler}
                                name="generatedFileName"
                                label="Generated File Name"
                                isError={validateStep1 && templateData.generatedFileName === ''}
                                helperText={validateStep1 && templateData.generatedFileName === '' ? "Genereated file name is required" : '' }
                            />
                            {
                                templateData.typeID > 0 && 
                                <Tooltip title="Search available placeholders">
                                    <IconButton onClick={() => setShowPlaceHolders(true)}><SearchCircleIcon /></IconButton>
                                </Tooltip>
                            }   
                        </Box>
                        <InsertPlaceholderDialog
                            open={showPlaceHolders}
                            closeHandler={() => setShowPlaceHolders(false)}
                            placeholders={availablePlaceholders}
                            insertHandler={insertPlaceholderHandler}/>
                        <TextField
                            select
                            value={templateData.statusID.toString()}
                            onChange={ e => {numberChangeHandler(e as React.ChangeEvent<HTMLInputElement>)}}
                            name="statusID"
                            label="Status"
                        >
                            <MenuItem value="0">Draft</MenuItem>
                            <MenuItem value="1">Published</MenuItem>
                            { itemId !== 0 && <MenuItem value="2">Archived</MenuItem> }
                        </TextField>
                        <UserPicker
                            label="Owner"
                            appendToStart={companyOwnedUser}
                            userId={templateData.ownerID}
                            disableClearable
                            onSelect={ u => { setTemplateData(prev => ({...prev, ownerID: u ? u.id : 0})) } }
                        />
                        <TextField
                            select
                            name="isElectronicSigningEnabled"
                            onChange={booleanChangeHandler}
                            value={templateData.isElectronicSigningEnabled ? "1" : "0"}
                            label="Electronic Signing Enabled?"
                        >
                            <MenuItem value="0">No</MenuItem>
                            <MenuItem value="1">Yes</MenuItem>
                        </TextField>
                        <FilePicker
                            files={ fileToUpload }
                            uploadedFiles={ fileToUpload.length > 0 ? [] : uploadedFile }
                            addFilesHandler={ f => setFileToUpload(f) }
                            removeFileHandler={ () => setFileToUpload([]) }
                            removeUploadedFileHandler={ deleteUploadedFileHandler }
                            downloadFileHandler={ downloadFileCallback }
                            zoneText="Drop or click to select template file"
                            errorMessage={ validateStep1 && templateData.statusID !== 0 && fileToUpload.length !== 1 && uploadedFile.length !== 1 ? 'A file must be selected' : undefined }
                            singleFile
                            accept={wordDocFilter}
                        />
                    </Stack>
                }
                {activeStep === 1 &&
                    <Box height="100%">
                        <PlaceholderTransferList
                            selectedPlaceholders={templateData.placeholders}
                            placeholders={availablePlaceholders}
                            setPlaceholdersHandler={setPlaceholdersHandler}
                            infoMessageHandler={setInfoMessage}
                        />
                    </Box>
                }
                {activeStep === 2 &&
                    <Box>
                        <Stack spacing={2}>
                            <div />
                            <RWTextFieldComponent
                                name="electronicSigningSubject"
                                onChange={stringChangeHandler}
                                value={templateData.electronicSigningSubject}
                                label="Subject"
                                isError={validateStep3 && templateData.electronicSigningSubject === ''}
                                helperText={validateStep3 && templateData.electronicSigningSubject === '' ? "Can't be empty" : '' }
                            />
                            <RWTextFieldComponent
                                name="electronicSigningMessage"
                                onChange={stringChangeHandler}
                                value={templateData.electronicSigningMessage}
                                label="Message"
                                multiline={true}
                                rows={3}
                                isError={validateStep3 && templateData.electronicSigningMessage === ''}
                                helperText={validateStep3 && templateData.electronicSigningMessage === '' ? "Can't be empty" : '' }
                            />
                            <TextField
                                select
                                name="isElectronicSigningOrderedSigners"
                                onChange={booleanChangeHandler}
                                value={templateData.isElectronicSigningOrderedSigners ? "1" : "0"}
                                label="Ordered Signers?"
                            >
                                <MenuItem value="0">No</MenuItem>
                                <MenuItem value="1">Yes</MenuItem>
                            </TextField>
                            <Grid container>
                                <Grid item xs={ templateData.signatoryType1 === 1 ? 6 : 12 }>
                                    <TextField
                                        fullWidth
                                        select
                                        name="signatoryType1"
                                        onChange={ e => {numberChangeHandler(e as React.ChangeEvent<HTMLInputElement>)}}
                                        value={templateData.signatoryType1}
                                        label="Signatory 1 Type"
                                    >{ signatorTypes }</TextField>
                                </Grid>
                                <Grid item display={ templateData.signatoryType1 !== 1 ? 'none' : undefined } xs={ 6 } sx={{ pl: 2 }}>
                                    <UserPicker
                                        label="Signatory 1 User"
                                        appendToStart={signatoryUsersExtra}
                                        userId={templateData.signatoryUserID1}
                                        onSelect={ u => { setTemplateData(prev => ({...prev, signatoryUserID1: u ? u.id : 0})) } }
                                        errorMessage={validateStep3 && templateData.signatoryType1 === 1 && templateData.signatoryUserID1 === 0 ? 'Select User': ''}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={ templateData.signatoryType2 === 1 ? 6 : 12 }>
                                    <TextField
                                        fullWidth
                                        select
                                        name="signatoryType2"
                                        onChange={ e => {numberChangeHandler(e as React.ChangeEvent<HTMLInputElement>)}}
                                        value={templateData.signatoryType2}
                                        label="Signatory 2 Type"
                                    >{ signatorTypes }</TextField>
                                </Grid>
                                <Grid item display={ templateData.signatoryType2 !== 1 ? 'none' : undefined } xs={ 6 } sx={{ pl: 2 }}>
                                    <UserPicker
                                        label="Signatory 2 User"
                                        appendToStart={signatoryUsersExtra}
                                        userId={templateData.signatoryUserID2}
                                        onSelect={ u => { setTemplateData(prev => ({...prev, signatoryUserID2: u ? u.id : 0})) } }
                                        errorMessage={validateStep3 && templateData.signatoryType2 === 1 && templateData.signatoryUserID2 === 0 ? 'Select User': ''}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={ templateData.signatoryType3 === 1 ? 6 : 12 }>
                                    <TextField
                                        fullWidth
                                        select
                                        name="signatoryType3"
                                        onChange={ e => {numberChangeHandler(e as React.ChangeEvent<HTMLInputElement>)}}
                                        value={templateData.signatoryType3}
                                        label="Signatory 3 Type"
                                    >{ signatorTypes }</TextField>
                                </Grid>
                                <Grid item display={ templateData.signatoryType3 !== 1 ? 'none' : undefined } xs={ 6 } sx={{ pl: 2 }}>
                                    <UserPicker
                                        label="Signatory 3 User"
                                        appendToStart={signatoryUsersExtra}
                                        userId={templateData.signatoryUserID3}
                                        onSelect={ u => { setTemplateData(prev => ({...prev, signatoryUserID3: u ? u.id : 0})) } }
                                        errorMessage={validateStep3 && templateData.signatoryType3 === 1 && templateData.signatoryUserID3 === 0 ? 'Select User': ''}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={ templateData.signatoryType4 === 1 ? 6 : 12 }>
                                    <TextField
                                        fullWidth
                                        select
                                        name="signatoryType4"
                                        onChange={ e => {numberChangeHandler(e as React.ChangeEvent<HTMLInputElement>)}}
                                        value={templateData.signatoryType4}
                                        label="Signatory 4 Type"
                                    >{ signatorTypes }</TextField>
                                </Grid>
                                <Grid item display={ templateData.signatoryType4 !== 1 ? 'none' : undefined } xs={ 6 } sx={{ pl: 2 }}>
                                    <UserPicker
                                        label="Signatory 4 User"
                                        appendToStart={signatoryUsersExtra}
                                        userId={templateData.signatoryUserID4}
                                        onSelect={ u => { setTemplateData(prev => ({...prev, signatoryUserID4: u ? u.id : 0})) } }
                                        errorMessage={validateStep3 && templateData.signatoryType4 === 1 && templateData.signatoryUserID4 === 0 ? 'Select User': ''}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={ templateData.signatoryType5 === 1 ? 6 : 12 }>
                                    <TextField
                                        fullWidth
                                        select
                                        name="signatoryType5"
                                        onChange={ e => {numberChangeHandler(e as React.ChangeEvent<HTMLInputElement>)}}
                                        value={templateData.signatoryType5}
                                        label="Signatory 5 Type"
                                    >{ signatorTypes }</TextField>
                                </Grid>
                                <Grid item display={ templateData.signatoryType5 !== 1 ? 'none' : undefined } xs={ 6 } sx={{ pl: 2 }}>
                                    <UserPicker
                                        label="Signatory 5 User"
                                        appendToStart={signatoryUsersExtra}
                                        userId={templateData.signatoryUserID5}
                                        onSelect={ u => { setTemplateData(prev => ({...prev, signatoryUserID5: u ? u.id : 0})) } }
                                        errorMessage={validateStep3 && templateData.signatoryType5 === 1 && templateData.signatoryUserID5 === 0 ? 'Select User': ''}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={ templateData.signatoryType6 === 1 ? 6 : 12 }>
                                    <TextField
                                        fullWidth
                                        select
                                        name="signatoryType6"
                                        onChange={ e => {numberChangeHandler(e as React.ChangeEvent<HTMLInputElement>)}}
                                        value={templateData.signatoryType6}
                                        label="Signatory 6 Type"
                                    >{ signatorTypes }</TextField>
                                </Grid>
                                <Grid item display={ templateData.signatoryType6 !== 1 ? 'none' : undefined } xs={ 6 } sx={{ pl: 2 }}>
                                    <UserPicker
                                        label="Signatory 6 User"
                                        appendToStart={signatoryUsersExtra}
                                        userId={templateData.signatoryUserID6}
                                        onSelect={ u => { setTemplateData(prev => ({...prev, signatoryUserID6: u ? u.id : 0})) } }
                                        errorMessage={validateStep3 && templateData.signatoryType6 === 1 && templateData.signatoryUserID6 === 0 ? 'Select User': ''}
                                    />
                                </Grid>
                            </Grid>
                                <Grid container>
                                <Grid item xs={ templateData.signatoryType7 === 1 ? 6 : 12 }>
                                    <TextField
                                        fullWidth
                                        select
                                        name="signatoryType7"
                                        onChange={ e => {numberChangeHandler(e as React.ChangeEvent<HTMLInputElement>)}}
                                        value={templateData.signatoryType7}
                                        label="Signatory 7 Type"
                                    >{ signatorTypes }</TextField>
                                </Grid>
                                <Grid item display={ templateData.signatoryType7 !== 1 ? 'none' : undefined } xs={ 6 } sx={{ pl: 2 }}>
                                    <UserPicker
                                        label="Signatory 7 User"
                                        appendToStart={signatoryUsersExtra}
                                        userId={templateData.signatoryUserID7}
                                        onSelect={ u => { setTemplateData(prev => ({...prev, signatoryUserID7: u ? u.id : 0})) } }
                                        errorMessage={validateStep3 && templateData.signatoryType7 === 1 && templateData.signatoryUserID7 === 0 ? 'Select User': ''}
                                    />
                                </Grid>
                            </Grid>
                                <Grid container>
                                <Grid item xs={ templateData.signatoryType8 === 1 ? 6 : 12 }>
                                    <TextField
                                        fullWidth
                                        select
                                        name="signatoryType8"
                                        onChange={ e => {numberChangeHandler(e as React.ChangeEvent<HTMLInputElement>)}}
                                        value={templateData.signatoryType8}
                                        label="Signatory 8 Type"
                                    >{ signatorTypes }</TextField>
                                </Grid>
                                <Grid item display={ templateData.signatoryType8 !== 1 ? 'none' : undefined } xs={ 6 } sx={{ pl: 2 }}>
                                    <UserPicker
                                        label="Signatory 8 User"
                                        appendToStart={signatoryUsersExtra}
                                        userId={templateData.signatoryUserID8}
                                        onSelect={ u => { setTemplateData(prev => ({...prev, signatoryUserID8: u ? u.id : 0})) } }
                                        errorMessage={validateStep3 && templateData.signatoryType8 === 1 && templateData.signatoryUserID8 === 0 ? 'Select User': ''}
                                    />
                                </Grid>
                            </Grid>
                                <Grid container>
                                <Grid item xs={ templateData.signatoryType9 === 1 ? 6 : 12 }>
                                    <TextField
                                        fullWidth
                                        select
                                        name="signatoryType9"
                                        onChange={ e => {numberChangeHandler(e as React.ChangeEvent<HTMLInputElement>)}}
                                        value={templateData.signatoryType9}
                                        label="Signatory 9 Type"
                                    >{ signatorTypes }</TextField>
                                </Grid>
                                <Grid item display={ templateData.signatoryType9 !== 1 ? 'none' : undefined } xs={ 6 } sx={{ pl: 2 }}>
                                    <UserPicker
                                        label="Signatory 9 User"
                                        appendToStart={signatoryUsersExtra}
                                        userId={templateData.signatoryUserID9}
                                        onSelect={ u => { setTemplateData(prev => ({...prev, signatoryUserID9: u ? u.id : 0})) } }
                                        errorMessage={validateStep3 && templateData.signatoryType9 === 1 && templateData.signatoryUserID9 === 0 ? 'Select User': ''}
                                    />
                                </Grid>
                            </Grid>
                                <Grid container>
                                <Grid item xs={ templateData.signatoryType10 === 1 ? 6 : 12 }>
                                    <TextField
                                        fullWidth
                                        select
                                        name="signatoryType10"
                                        onChange={ e => {numberChangeHandler(e as React.ChangeEvent<HTMLInputElement>)}}
                                        value={templateData.signatoryType10}
                                        label="Signatory 10 Type"
                                    >{ signatorTypes }</TextField>
                                </Grid>
                                <Grid item display={ templateData.signatoryType10 !== 1 ? 'none' : undefined } xs={ 6 } sx={{ pl: 2 }}>
                                    <UserPicker
                                        label="Signatory 10 User"
                                        appendToStart={signatoryUsersExtra}
                                        userId={templateData.signatoryUserID10}
                                        onSelect={ u => { setTemplateData(prev => ({...prev, signatoryUserID10: u ? u.id : 0})) } }
                                        errorMessage={validateStep3 && templateData.signatoryType10 === 1 && templateData.signatoryUserID10 === 0 ? 'Select User': ''}
                                    />
                                </Grid>
                            </Grid>
                        </Stack>
                    </Box>
                }
            </PageContentLayout>
        </PageLayout>
    );
}
