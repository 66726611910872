import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { SxProps } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { GetSpellerConsultantReportData } from "services/ConsultantService";
import UserPicker from "components/Pickers/UserPicker";
import { GridColDef, useGridApiRef } from "@mui/x-data-grid-premium";
import { SpellerConsultantReportData } from "common/models/Reports/SpellerConsultantReportData";
import GridWithStateWrapper from "components/GridWidthStateWrapper";
import { DatePicker } from "@mui/x-date-pickers-pro";
import { NameIdObj } from "common/models/GenericTypes";

interface Props {
    description: string,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
}

const ownerSpecialOptions: NameIdObj[] = [
    { id: 0, name: 'My Company' },
    { id: -1, name: 'Me' },
    { id: -2, name: 'My Team' },
    { id: -3, name: 'Unassigned' },
];

const filterElementStyle: SxProps = { flex: '1 1 0', mr: 1 };

export default function SpellerConsultantReport({ description, loadingHandler, errorHandler }: Props) {
    const [rows, setRows] = useState<SpellerConsultantReportData[]>([]);
    const [showGrid, setShowGrid] = useState(false);
    const [consultantId, setConsultantId] = useState(0);
    const [fromDate, setFromDate] = useState<moment.Moment | null>(null);
    const [toDate, setToDate] = useState<moment.Moment | null>(null);
    const apiRef = useGridApiRef();

    useEffect(() => {
        setFromDate(moment().startOf('month'));
        setToDate(moment().endOf('month'));
    }, []);

    const getDataCallback = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        const stringFromDate = fromDate && fromDate.isValid() ? fromDate.format('YYYY-MM-DD') : '';
        const stringToDate = toDate && toDate.isValid() ? toDate.format('YYYY-MM-DD') : '';
        const res = await GetSpellerConsultantReportData(consultantId, stringFromDate, stringToDate, errorHandler);
        if (res) {
            setShowGrid(true);
            setRows(res);
        }
        loadingHandler && loadingHandler(false);
    }, [consultantId, fromDate, toDate, errorHandler, loadingHandler]);

    const exportAsExcelHandler = useCallback(() => {
        const api = apiRef.current;
        if (api) {
            const m = moment();
            const filename = `SpellerConsultant${m.format('YYYYMMDDhhmmss')}`;
            api.exportDataAsExcel({ fileName: filename });
        }
    }, [apiRef]);

    const columns = useMemo<GridColDef[]>(() => {
        return [
            { field: 'Name', width: 300 },
            { field: 'Calls', width: 120 },
            { field: 'CallsCandidate', headerName: 'Candidate Calls', width: 120 },
            { field: 'CallsClient', headerName: 'Client Calls', width: 120 },
            { field: 'Notes', width: 120 },
            { field: 'Meetings', width: 120 },
            { field: 'MessagesIn', headerName: 'Messages In', width: 120 },
            { field: 'MessagesOut', headerName: 'Messages Out', width: 120 },
            { field: 'Submissions', width: 120 },
            { field: 'Floats', width: 120 },
            { field: 'TelephoneScreened', headerName: 'Telephone Screened', width: 150 },
            { field: 'Interviews1st', headerName: '1st Interviews', width: 120 },
            { field: 'OtherInterviews', headerName: 'Other Interviews', width: 120 },
            { field: 'Offers', width: 120 },
            { field: 'OpenJobs', headerName: 'Open Jobs', width: 120 },
            { field: 'PlacementsNew', headerName: 'New Placements', width: 120 },
            { field: 'NewJobs', headerName: 'New Jobs', width: 120 }
        ];
    }, []);

    return (
        <>
            <Box display="flex">
                <Box flex="1 1 0" pr={1}>
                    <UserPicker disableClearable label="Consultant" userId={consultantId} onSelect={u => setConsultantId(u ? u.id : 0)} appendToStart={ownerSpecialOptions} includeInactiveUsers={true} />
                </Box>
                <DatePicker label="From Date" value={fromDate} onChange={m => setFromDate(m)} sx={filterElementStyle} slotProps={{actionBar: { actions: ["clear", "today", "cancel", "accept"] }}} />
                <DatePicker label="To Date" value={toDate} onChange={m => setToDate(m)} sx={filterElementStyle} slotProps={{actionBar: { actions: ["clear", "today", "cancel", "accept"] }}} />
                <Button variant="contained" color="success" onClick={getDataCallback}>Run Report</Button>
            </Box>
            <Typography component="div" variant="h6" mt={2}>Report Description</Typography>
            <Typography component="div" variant="body2" mb={2}>{description}</Typography>
            {showGrid &&
                <>
                    <Box pb="10px" ml="auto">
                        <Button variant="contained" color="success" onClick={exportAsExcelHandler}>Export As Excel</Button>
                    </Box>
                    <GridWithStateWrapper
                        density="compact"
                        gridName="reports-speller-consultant"
                        rows={rows}
                        columns={columns}
                        apiRef={apiRef}
                        getRowId={r => r.UserID}
                        disableRowSelectionOnClick
                    />
                </>
            }
        </>
    );
}