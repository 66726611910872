import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import PageLayout from "layouts/PageLayout";
import { Advert } from "common/models/Advert";
import { GetAdvertById } from "services/AdvertsService";
import Snackbar from "@mui/material/Snackbar";
import Alert from "components/Alert";
import { AddRecentRecord } from "util/LocalStorageUtils";
import Iframe from 'react-iframe'
import { GetCustomerSettingBySettingName } from "services/ConfigurationService";
import AdvertEditPageContent from "components/Content/AdvertEditPageContent";
import PageContentLayout from "layouts/PageContentLayout";

export default function AdvertEdit() {
    const [advert, setAdvert] = useState<Advert>();
    const params = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [isAdvertsEAP, setIsAdvertsEAP] = useState(false);
    const [fetchedSettings, setFetchedSettings] = useState(false);
    const [summaryBar, setSummaryBar] = useState(<></>);
    const navigate = useNavigate();

    const advertId = useMemo(() => {
        const parsedId = +(params.id ?? '0');
        if (isNaN(parsedId)) return 0;
        return parsedId;
    }, [params.id]);

    useEffect(() => {
        const getData = async () => {
            // setIsLoading(true);
            const res = await GetAdvertById(advertId);
            if (res === null) navigate('/not-found');
            if (res) {
                AddRecentRecord(15, advertId, res.title);
                setAdvert(res);
            }
            // setIsLoading(false);
        };
        getData();
    }, [advertId, navigate]);

    useEffect(() => {
        const getAdvertsSettings = async () => {
            const settings = await GetCustomerSettingBySettingName('AdvertsEAP');
            if (settings && settings === 'True') setIsAdvertsEAP(true);
            setFetchedSettings(true);
        };
        getAdvertsSettings();
    }, []);

    const handleMessage = useCallback((e: MessageEvent) => {
        if (e.data === 'OK') navigate(`/adverts/${advertId}`);
    }, [advertId, navigate]);

    useEffect(() => {
        window.addEventListener('message', handleMessage);
        return () => window.removeEventListener('message', handleMessage);
    }, [handleMessage]);

    return (
        <>
            <Snackbar open={successMessage !== ''} autoHideDuration={3000} onClose={() => setSuccessMessage('')}>
                <Alert onClose={() => setSuccessMessage('')}>{ successMessage }</Alert>
            </Snackbar>
            <Snackbar open={errorMessage !== ''} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity="error" onClose={() => setErrorMessage('')} sx={{ whiteSpace: 'pre-line' }}>{ errorMessage }</Alert>
            </Snackbar>
            <PageLayout paddingTop={isAdvertsEAP ? undefined : 0} SummaryBar={summaryBar}>
                {fetchedSettings && !isAdvertsEAP &&
                    <Iframe
                        url={advert ? advert.editUrl : ''}
                        width="100%"
                        height="100%"
                        id="advert-edit-iframe"
                        className=""
                        display="block"
                        position="relative"
                    />
                }
                {fetchedSettings && isAdvertsEAP &&
                    <PageContentLayout showLoading={isLoading}>
                        <AdvertEditPageContent
                            advertId={advertId}
                            setSummaryBar={setSummaryBar}
                            errorHandler={setErrorMessage}
                            loadingHandler={setIsLoading}
                            successHandler={setSuccessMessage}
                        />
                    </PageContentLayout>
                }
            </PageLayout>
        </>
    );
}
