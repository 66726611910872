import Autocomplete, { AutocompleteInputChangeReason } from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import { SeekLocation } from "common/models/JobPosting/Seek";
import React, { useCallback, useEffect, useState } from "react";
import { GetSeekLocationSuggestions } from "services/SeekService";

interface Props {
    value: string | null,
    name: string | null,
    hirerId: string,
    onSelectCallback: (l: SeekLocation | null) => void,
    label?: string,
    delayMs?: number,
}

export default function SeekLocationPicker({ value, name, hirerId, onSelectCallback, label = 'Location', delayMs = 200 }: Props) {
    const [selectedLocation, setSelectedLocation] = useState<SeekLocation | null>(null);
    const [lookupTerm, setLookupTerm] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState<SeekLocation[]>([]);

    useEffect(() => {
        if (value) {
            if (!selectedLocation || (selectedLocation && selectedLocation.id.value !== value)) {
                const item = options.find(o => o.id.value === value);
                if (item) setSelectedLocation(item);
                else setSelectedLocation({ contextualName: name ?? '', countryCode: '', id: { value: value }, name: name ?? '' });
            }
        }
        else setSelectedLocation(null);
    }, [name, options, selectedLocation, value]);

    useEffect(() => {
        if (lookupTerm.length > 0) {
            const delaySearch = setTimeout(async () => {
                setIsLoading(true);
                const res = await GetSeekLocationSuggestions(hirerId, lookupTerm);
                if (res && res.locationSuggestions && res.locationSuggestions.length > 0) {
                    const locations = res.locationSuggestions.map(l => l.location);
                    setOptions(locations);
                }
                setIsLoading(false);
            }, delayMs);
            return () => clearTimeout(delaySearch);
        }
    }, [delayMs, hirerId, lookupTerm]);

    const handleInputChange = useCallback((event: React.SyntheticEvent<Element, Event>, value: string, reason: AutocompleteInputChangeReason) => {
        reason !== 'reset' && setLookupTerm(value);
    }, []);

    return (
        <Autocomplete
            size="small"
            fullWidth
            options={options}
            value={selectedLocation}
            getOptionLabel={o => o.contextualName}
            isOptionEqualToValue={ (o,v) => o.id.value === v.id.value }
            loading={isLoading}
            filterOptions={ x => x }
            onChange={ (e,v) => onSelectCallback(v) }
            renderInput={ (params) => (
                <TextField
                    {...params}
                    label={label}
                    inputProps={{ ...params.inputProps, "data-lpignore": "true" }}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (<>{isLoading ? <CircularProgress color="inherit" size={20} /> : params.InputProps.endAdornment}</>)
                    }}
                />
            )}
            onInputChange={ handleInputChange }
            // renderOption={(props, option) => (
            //     <ListItem {...props} key={option.id.value}>
            //         <ListItemText
            //             primary={option.name}
            //             secondary={option.countryCode}
            //             secondaryTypographyProps={{ component: 'div' }}
            //         />
            //     </ListItem>
            // )}
        />
    );

}