import React, { useEffect, useState } from "react";
import PageContentLayout from "../../../layouts/PageContentLayout";
import JobCandidateKanbanGroupsGrid from "../../../components/Grids/JobCandidateKanbanGroupsGrid";
import { Alert, Button, Snackbar } from "@mui/material";
import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import { JobCandidateKanbanGroup } from "common/models/Jobs/Job";
import { AddKanbanGroup, GetKanbanGroups, UpdateKanbanGroup, UpdateKanbanGroupOrder } from "services/ConfigurationService";
import { NameIdObj } from "common/models/GenericTypes";
import JobCandidateKanbanDialog from "components/Dialogs/Jobs/JobCandidateKanbanDialog";
import OrderDialog from "components/Dialogs/OrderDialog";

interface Props {
    setSummaryBar?: (summaryBar: JSX.Element) => void
}


export default function KanbanGroupsAdmin({ setSummaryBar }: Props) {
    const [isLoading, setIsLoading] = useState(false);
    const [openOrderDialog, setOpenOrderDialog] = useState(false);
    const [openAddEditDialog, setAddEditDialog] = useState(false);
    const [rows, setRows] = useState<JobCandidateKanbanGroup[]>([]);
    const [selectedKanbanGroup, setSelectedKanbanGroup] = useState<JobCandidateKanbanGroup | null>(null);
    const [errorMessage, setErrorMessage] = useState('');
    useEffect(() => {
        const getRows = async () => {
            isLoading && setIsLoading(true);
            let res: JobCandidateKanbanGroup[] | null = [];
                res = await GetKanbanGroups(false);
            if (res) {
                setRows(res);
            }
            isLoading && setIsLoading(false);
        };
        
        getRows();
    }, [setIsLoading, isLoading]);


    useEffect(() => {
        const action = <>
            <Button variant="contained" color="secondary" sx={{mr: '10px'}} onClick={() => {setOpenOrderDialog(!openOrderDialog)}}>Order Groups</Button>
            <Button variant="contained" color="primary"  onClick={() => {setAddEditDialog(true); setSelectedKanbanGroup(null)}}>Create</Button>
        </>;
        const summaryBar = <TitleAndActionSummaryBar title='Configuration > Jobs > Candidate Kanban Groups' browserTabTitle="Jobs > Configuration"action={action} />;
        setSummaryBar && setSummaryBar(summaryBar);
    }, [setSummaryBar, rows,openOrderDialog]);

    const handleKanbanGroupOrder = async (orderItems: NameIdObj[]) => {
        setRows(prev => {
            let tmp = [...prev];
            orderItems.forEach(o => {
                let group = tmp.find(t=>t.id == o.id);
                if(group) {
                    let indexOfGroup = tmp.indexOf(group);
                    group.groupOrder = orderItems.indexOf(o);
                    tmp[indexOfGroup] = group;
                }
            });
            return tmp.sort((a, b) => a.groupOrder - b.groupOrder);
        });

         const result = await UpdateKanbanGroupOrder(orderItems.map(o=>o.id));
         if(result) {
            setOpenOrderDialog(false);
         }
    }

    const handleKanbanGroupSave = async (kanbanGroup: JobCandidateKanbanGroup) => {
        if(kanbanGroup.groupOrder == -1) {
            kanbanGroup.groupOrder = rows.length-1;
        }

        if(kanbanGroup.id == 0) {
            const response = await AddKanbanGroup(kanbanGroup);
           if(response) {
            kanbanGroup.id = response;
            setRows(prev => {
                let tmp = [...prev];
                tmp.push(kanbanGroup);
                return tmp;
            });
           }
        }else {
            const response = await UpdateKanbanGroup(kanbanGroup);
            if(response) {
                setRows(prev => {
                    let tmp = [...prev];
                    const group = tmp.find(s=>s.id == kanbanGroup.id);
                    if(group) {
                        let groupIndex = tmp.indexOf(group);
                        tmp[groupIndex].name = kanbanGroup.name;
                        tmp[groupIndex].enabled = kanbanGroup.enabled;
                    }
                    return tmp;
                });
            }else {
                setErrorMessage(`The ${kanbanGroup.name} Candidate Kanban Group currently has Candidate Workflow Status items assigned to it. Please reallocate the Candidate Workflow Status items before disabling this group.`)
            }
        }
        setAddEditDialog(false);
    }
    
    const handleGroupSelected = (group: JobCandidateKanbanGroup) => {
        setSelectedKanbanGroup(group);
        setAddEditDialog(true);
    }

    return (
    <>
      <Snackbar open={errorMessage !== ''}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
        <JobCandidateKanbanDialog 
                       open={openAddEditDialog}
                       kanbanGroup={selectedKanbanGroup}
                       closeHandler={() => setAddEditDialog(false)}
                       submitHandler={handleKanbanGroupSave}/>

        <OrderDialog
                    orderItems={rows.map(r=> {
                            return { id: r.id, name: r.name} as NameIdObj;
                        })}
                    title="Order Kanban Groups"
                    open={openOrderDialog}
                    closeHandler={() => setOpenOrderDialog(false)}
                    submitHandler={handleKanbanGroupOrder}/>
        <PageContentLayout showLoading={isLoading} title='Candidate Kanban Groups'>
            <JobCandidateKanbanGroupsGrid 
            gridName="job-candidate-kanban-grid"
            kanbanGroups={rows}
            handleGroupSelected={handleGroupSelected}/>
        </PageContentLayout>
    </>
   );
}