import { PanelModel } from "@syncfusion/ej2-layouts/src/dashboard-layout/dashboard-layout-model";
import { AdvertRecordDashboardElementDefinition, AdvertRecordDashboardElementType } from "common/models/Dashboard/EditLayout";
import { ScreenLayoutSettings } from "common/models/ScreenLayouts/ScreenLayouts";

export const DefaultAdvertRecordDashboardElements: AdvertRecordDashboardElementDefinition[] = [
    { id: "AdvertId_Default", row: 0, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'AdvertId' },
    { id: "AdvertPublicId_Default", row: 1, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'AdvertPublicId' },
    { id: "AdvertJobId_Default", row: 2, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'AdvertJobId' },
    { id: "AdvertScreeningQuestionnaire_Default", row: 3, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'AdvertScreeningQuestionnaire' },
    { id: "AdvertPostingDate_Default", row: 4, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'AdvertPostingDate' },
    { id: "AdvertExpiryDate_Default", row: 5, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'AdvertExpiryDate' },
    { id: "AdvertExpiredBy_Default", row: 6, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'AdvertExpiredBy' },
    { id: "AdvertApplicants_Default", row: 7, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'AdvertApplicants' },
    
    { id: "AdvertClient_Default", row: 0, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'AdvertClient' },
    { id: "AdvertConsultant_Default", row: 1, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'AdvertConsultant' },
    { id: "AdvertCreatedBy_Default", row: 2, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'AdvertCreatedBy' },
    { id: "AdvertCreatedDate_Default", row: 3, col: 1, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'AdvertCreatedDate' },
];

export const GetMinSizeByElementType = (type: AdvertRecordDashboardElementType) => {
    switch (type) {
        default: return [1, 1];
    }
};

export const GetElementTypeById = (id: string): AdvertRecordDashboardElementType => {
    if ( id && id.startsWith('Divider_')) return 'Divider';
    if ( id && id.startsWith('Spacer_')) return 'Spacer';
    if (id && id.startsWith('AdvertId_')) return 'AdvertId';
    if (id && id.startsWith('AdvertPublicId_')) return 'AdvertPublicId';
    if (id && id.startsWith('AdvertJobId_')) return 'AdvertJobId';
    if (id && id.startsWith('AdvertScreeningQuestionnaire_')) return 'AdvertScreeningQuestionnaire';
    if (id && id.startsWith('AdvertPostingDate_')) return 'AdvertPostingDate';
    if (id && id.startsWith('AdvertExpiryDate_')) return 'AdvertExpiryDate';
    if (id && id.startsWith('AdvertExpiredBy_')) return 'AdvertExpiredBy';
    if (id && id.startsWith('AdvertApplicants_')) return 'AdvertApplicants';
    if (id && id.startsWith('AdvertClient_')) return 'AdvertClient';
    if (id && id.startsWith('AdvertConsultant_')) return 'AdvertConsultant';
    if (id && id.startsWith('AdvertCreatedBy_')) return 'AdvertCreatedBy';
    if (id && id.startsWith('AdvertCreatedDate_')) return 'AdvertCreatedDate';
    // if ( id && id.startsWith('CustomField')) return GetCustomFieldTypeById(id);
    return '';
};

export const GetPanelDefinitionsFromPanelModels = (panels: PanelModel[]) => {
    let elements: AdvertRecordDashboardElementDefinition[] = [];
    panels.forEach((p, i) => {
        let type = GetElementTypeById(p.id ?? '');
        elements.push({
            id: p.id ?? i.toString(),
            sizeX: p.sizeX ?? 1,
            sizeY: p.sizeY ?? 1,
            col: p.col ?? 0,
            row: p.row ?? 0,
            minSizeX: p.minSizeX ?? 1,
            minSizeY: p.minSizeY ?? 1,
            maxSizeX: p.maxSizeX ?? 1,
            maxSizeY: p.maxSizeY ?? null,
            type: type
        });
    });
    return elements ;
};

export const AdvertScreenLayoutSettings: ScreenLayoutSettings = {
    unitWidth: 600,
    unitHeight: 40,
    gapX: 10,
    gapY: 0,
    columns: 3,
    mediaQueryMaxWidth: '1000px'
};