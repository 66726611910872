import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { MenuOptionDefinition } from "common/models/MenuDefinition";
import SubMenuContainer from "./SubMenuContainer";

interface Props {
    index: number 
    definition: MenuOptionDefinition | '|',
    hoveredIndex: number | null,
    hoveredIndexHandler: (i: number | null) => void,
    closeMenuHandler: () => void
}

const linkStyle: React.CSSProperties = { color: 'inherit', textDecoration: 'none' };

export default function MenuOptionRenderer({ index, definition, hoveredIndex, hoveredIndexHandler, closeMenuHandler }: Props) {
    const element = useMemo(() => {
        if (definition === '|') return <Divider />
        if (definition.allow && !definition.allow()) return <></>;
        if (definition.type === 'link' && definition.href) {
            if (definition.disabled) return <MenuItem disabled>{definition.label}</MenuItem>;
            return (
                <Link to={definition.href} style={ linkStyle } onMouseEnter={ () => hoveredIndexHandler(index) }>
                    <MenuItem>{definition.label}</MenuItem>
                </Link>
            );
        }
        if (definition.type === 'external-link' && definition.href) {
            return (
                <a href={definition.href} style={ linkStyle } target="_blank" rel="noopener noreferrer" onMouseEnter={ () => hoveredIndexHandler(index) }>
                    <MenuItem>{definition.label}</MenuItem>
                </a>
            );
        }
        if (definition.type === 'action' && definition.action) return <MenuItem onClick={definition.action} onMouseEnter={ () => hoveredIndexHandler(index) } disabled={definition.disabled}>{definition.label}</MenuItem>
        if (definition.type === 'parent' && definition.subMenu && definition.subMenu.length > 0) {
            return (
                <SubMenuContainer
                    definition={definition}
                    index={index}
                    hoveredIndex={hoveredIndex}
                    action={definition.action}
                    hoveredIndexHandler={hoveredIndexHandler}
                    closeMenuHandler={closeMenuHandler}
                />
            );
        }
        return <React.Fragment />;
    }, [definition, index, hoveredIndex, hoveredIndexHandler, closeMenuHandler]);
    return element;
}