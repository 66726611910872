import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import moment from "moment";
import React, { useCallback, useMemo, useState } from "react";
import { GridColDef, useGridApiRef } from "@mui/x-data-grid-premium";
import GridWithStateWrapper from "components/GridWidthStateWrapper";
import { CandidatesSnapshotReportData, CandidatesSnapshotStateReportData } from "common/models/Reports/CandidatesSnapshot";
import { GetCandidatesSnapshotReportData, GetCandidatesSnapshotStateReportData } from "services/CandidatesService";
import { Tab, Tabs } from "@mui/material";

interface Props {
    description: string,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
}

export default function CandidatesSnapshotReport({ description, loadingHandler, errorHandler }: Props) {
    const [rows1, setRows1] = useState<CandidatesSnapshotReportData[]>([]);
    const [rows2, setRows2] = useState<CandidatesSnapshotStateReportData[]>([]);
    const [showGrid, setShowGrid] = useState(false);
    const apiRef1 = useGridApiRef();
    const apiRef2 = useGridApiRef();
    const [activeTab, setActiveTab] = useState('Region');

    const handleTabChange = (e: React.SyntheticEvent, newValue: string) => {
        setActiveTab(newValue);
    }

    const getDataCallback = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        const res1 = await GetCandidatesSnapshotReportData(errorHandler);
        if (res1) {
            setRows1(res1);
        }
        const res2 = await GetCandidatesSnapshotStateReportData(errorHandler);
        if (res2) {
            setRows2(res2);
        }
        setShowGrid(true);
        loadingHandler && loadingHandler(false);
    }, [errorHandler, loadingHandler]);

    const exportAsExcelHandler1 = useCallback(() => {
        const api = apiRef1.current;
        if (api) {
            const m = moment();
            const filename = `CandidatesSnapshot${m.format('YYYYMMDDhhmmss')}`;
            api.exportDataAsExcel({ fileName: filename });
        }
    }, [apiRef1]);

    const exportAsExcelHandler2 = useCallback(() => {
        const api = apiRef2.current;
        if (api) {
            const m = moment();
            const filename = `CandidatesSnapshotState${m.format('YYYYMMDDhhmmss')}`;
            api.exportDataAsExcel({ fileName: filename });
        }
    }, [apiRef2]);

    const columns1 = useMemo<GridColDef[]>(() => {
        return [
            { field: 'Region', width: 300 },
            { field: 'NoOfCandidates', headerName: "No. Of Candidates", width: 300 },
        ];
    }, []);
    
    const columns2 = useMemo<GridColDef[]>(() => {
        return [
            { field: 'State', width: 300 },
            { field: 'NoOfCandidates', headerName: "No. Of Candidates", width: 300 },
        ];
    }, []);

    return (
        <>
            <Box display="flex" justifyContent="flex-end">
                <Button variant="contained" color="success" onClick={getDataCallback}>Run Report</Button>
            </Box>
            <Typography component="div" variant="h6" mt={2}>Report Description</Typography>
            <Typography component="div" variant="body2" mb={2}>{description}</Typography>
            { showGrid &&
                <>
                    <Box display="flex">
                        <Tabs value={activeTab} onChange={handleTabChange} sx={{ flexGrow: 1 }}>
                            <Tab value="Region" label="Region" />
                            <Tab value="State" label="State" />
                        </Tabs>
                    </Box>
                    {activeTab === "Region" &&
                        <>
                            <Box pb="10px" ml="auto">
                                <Button variant="contained" color="success" onClick={exportAsExcelHandler1}>Export As Excel</Button>
                            </Box>
                            <GridWithStateWrapper
                                density="compact"
                                rows={rows1}
                                columns={columns1}
                                apiRef={apiRef1}
                                getRowId={r => r.Region}
                                gridName="reports-candidatesnapshit-region"
                                disableRowSelectionOnClick
                                
                            />
                        </>
                    }
                    {activeTab !== "Region" &&
                        <>
                            <Box pb="10px" ml="auto">
                                <Button variant="contained" color="success" onClick={exportAsExcelHandler2}>Export As Excel</Button>
                            </Box>
                            <GridWithStateWrapper
                                density="compact"
                                rows={rows2}
                                columns={columns2}
                                apiRef={apiRef2}
                                gridName="reports-candidatesnapshit-state"
                                getRowId={r => r.State}
                                disableRowSelectionOnClick
                            />
                        </>
                    }
                </>
            }
        </>
    );
}