import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Alert from "components/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MenuItem from "@mui/material/MenuItem";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { Editor } from "@tinymce/tinymce-react";
import * as tinymce from 'tinymce';
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Link, Navigate, useParams, useSearchParams } from "react-router-dom";
import { AllowTemplates } from "common/data/Permissions/UserMenuAccess";
import { NameIdObj, SimpleFileObj } from "common/models/GenericTypes";
import { MessageTemplate } from "common/models/Templates/MessageTemplate";
import FilePicker from "components/Pickers/FilePicker";
import UserPicker from "components/Pickers/UserPicker";
import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import useObjectStateWithChangeTracker from "hooks/UseObjectStateWithChangeTracker";
import useUnsavedChangesDialog from "hooks/UseUnsavedChangesDialog";
import PageContentLayout from "layouts/PageContentLayout";
import PageLayout from "layouts/PageLayout";
import { DownloadMessageTemplateAttachmentFile, GetMessageTemplate, GetMessageTemplateAttachments, AddMessageTemplateAttachment, UpdateMessageTemplate, RemoveMessageTemplateAttachment, CreateMessageTemplate } from "services/TemplatesService";
import { getJwtPayload } from "util/AuthUtils";
import { DefaultMessageTemplateData, NoChangesDefaultMessageTemplate } from "util/Definitions/Templates/MessageTemplates";
import { AttachmentsLimitBytes } from "util/FilesUtils";
import Typography from "@mui/material/Typography";
import { TemplatePlaceholder } from "common/models/Templates/TemplatePlaceholder";
import { GetPlaceholdersByEntityList } from "services/PlaceholdersService";
import InsertPlaceholderDialog from "components/Dialogs/Generic/InsertPlaceholderDialog";
import RWTextFieldComponent from "components/RWTextFieldComponent";

const MessagesLink = <Link to="/account/templates/messages" style={{ color: 'inherit', textDecoration: 'underline' }}>Messages</Link>;

const companyOwnedUserPickerOption: NameIdObj[] = [{ id: 0, name: 'Company Owned' }];

const messageTemplateTypes = {
    "1": 'Email',
    "2": 'SMS',
    "3": 'Rejection Email',
    "4": 'Interview Confirmation',
    "5": 'Meeting Confirmation',
    "6": 'Offer Confirmation',
    "7": 'Submission',
    "8": 'Float',
    "9": 'Placement',
    "10": 'Onboarding',
};

const onboardingPlaceholder: TemplatePlaceholder = { friendlyName: 'Onboarding - Form URL', placeholder: 'Onboarding.FormUrl', placeholderUsage: '#{Onboarding.FormUrl}', value: '' };

export default function MessageTemplateItemPage() {
    const [summaryBar, setSummaryBar] = useState<JSX.Element>(<></>);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showSuccess, setShowSuccess] = useState(false);
    const { state, init, change, updateInitial, hasChanges } = useObjectStateWithChangeTracker<MessageTemplate>(DefaultMessageTemplateData, NoChangesDefaultMessageTemplate);
    const [attachments, setAttachments] = useState<SimpleFileObj[]>([]);
    const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
    const [attachmentsToRemove, setAttachmentsToRemove] = useState<number[]>([]);
    const [isEditorDirty, setIsEditorDirty] = useState(false);
    const editorRef = useRef<tinymce.Editor | null>(null);
    const theme = useTheme();
    const isDarkTheme = theme.palette.mode === 'dark';
    const params = useParams();
    const [searchParams] = useSearchParams();
    const [templateTypeId, setTemplateTypeId] = useState(0);
    const [templateTypeName, setTemplateTypeName] = useState('');
    const [isExpandedMetadata, setIsExpandedMetadata] = useState(true);
    const [isExpandedTemplate, setIsExpandedTemplate] = useState(true);
    const [placeholders, setPlaceholders] = useState<TemplatePlaceholder[]>([]);
    const [showPlaceholdersDialog, setShowPlaceholdersDialog] = useState(false);
    const [fetchedExistingItem, setfetchedExistingItem] = useState(false);
    const [finishedItemCreationSetup, setFinishedItemCreationSetup] = useState(false);

    const itemId = useMemo(() => {
        const parsedId = +(params.id ?? '0');
        if(isNaN(parsedId)) return 0;
        return parsedId
    }, [params.id]);

    const isTemplateAdmin = useMemo(() => AllowTemplates(), []);

    const saveChangesCallback = useCallback(async () => {
        if (filesToUpload.length > 0) {
            const totalSizeToUpload = filesToUpload.reduce((total, item) => total + item.size, 0);
            if (totalSizeToUpload > AttachmentsLimitBytes) {
                setErrorMessage("The total size of Attachments cannot be more than 25MB");
                return false;
            }
        }

        let templateId = itemId;
        let templateData = state;
        const api = editorRef.current;
        if (state.type !== 2) {
            const messageBody = api ? api.getContent() : '';
            templateData.body = messageBody;
            if (!templateData.subject) {
                setErrorMessage('The template must have a subject');
                return false;
            }
        }

        if(!templateData.body) {
            setErrorMessage('The template must have a body');
            return false;
        }

        if(!templateData.name) {
            setErrorMessage('The template must have a name');
            return false;
        }

        setIsLoading(true);
        if(itemId !== 0) {
            if(hasChanges || isEditorDirty) {
                const updateRes = await UpdateMessageTemplate(itemId, templateData, setErrorMessage);
                if (!updateRes) {
                    setIsLoading(false);
                    return false;
                }
                updateInitial();
                setIsEditorDirty(false);
                if(state.type !== 2 && api) api.setDirty(false);
            }
            if(attachmentsToRemove.length > 0) {
                const removeRes = await RemoveMessageTemplateAttachment(itemId, attachmentsToRemove, setErrorMessage);
                if (!removeRes) {
                    setIsLoading(false);
                    return false;
                }
                setAttachmentsToRemove([]);
            }
        }

        if(itemId === 0) {
            const creationData = await CreateMessageTemplate(templateData, setErrorMessage);
            if (!creationData) {
                setIsLoading(false);
                return false;
            }
            templateId = creationData.value;
            updateInitial();
            setIsEditorDirty(false);
            if(state.type !== 2 && api) api.setDirty(false);
        }

        if(filesToUpload.length > 0 && templateId !== 0) {
            const uploadRes = await AddMessageTemplateAttachment(templateId, filesToUpload, setErrorMessage);
            if (!uploadRes) {
                setIsLoading(false);
                return false;
            }
            setAttachments(prev => {
                let newAtts = [...prev];
                for (let i = 0; i < filesToUpload.length; i++) {
                    const f = filesToUpload[i];
                    newAtts.push({ id: uploadRes[i] ?? 0, name: f.name, size: f.size });
                }
                return newAtts;
            });
            setFilesToUpload([]);
        }

        setIsLoading(false);
        setShowSuccess(true);
        return true;
    }, [itemId, filesToUpload, attachmentsToRemove, hasChanges, isEditorDirty, state, updateInitial]);

    useEffect(() => {
        const action = (
            <Button
                variant="contained"
                color="success"
                disabled={!hasChanges && !isEditorDirty && filesToUpload.length === 0 && attachmentsToRemove.length === 0}
                onClick={ saveChangesCallback }
            >Save</Button>
        );

        const sb = (
            <TitleAndActionSummaryBar
                title={<>{'Account > Templates > '}{MessagesLink}</>}
                browserTabTitle="Messages > Templates"
                action={action}
            />
        );
        setSummaryBar(sb);
    }, [hasChanges, isEditorDirty, filesToUpload.length, attachmentsToRemove.length, saveChangesCallback]);

    useEffect(() => {
        const getData = async () => {
            setIsLoading(true);
            const data = await GetMessageTemplate(itemId, setErrorMessage);
            const att = await GetMessageTemplateAttachments(itemId, setErrorMessage);
            if (data) {
                const templateName = (messageTemplateTypes as any)[data.type.toString()];
                setTemplateTypeId(data.type);
                setTemplateTypeName(templateName);
                init(data);
            }

            if (att) {
                let files: SimpleFileObj[] = [];
                for (let i = 0; i < att.length; i++) {
                    const f = att[i];
                    files.push({ id: f.id, name: f.friendlyName, size: f.size })
                }
                if (files.length > 0) setAttachments(files);
            }
            setfetchedExistingItem(true);
            setIsLoading(false);
        };
        itemId !== 0 && getData();
    }, [itemId, init]);

    useEffect(() => {
        const setCreateData = () => {
            const tokenUserId = getJwtPayload()?.UserID;
            const userId = +(tokenUserId ?? '0');
            const typeParam = searchParams.get('type');
            let templateType = 1;
            if(typeParam) {
                const parsedType = +typeParam;
                if(parsedType >= 1 && parsedType <= 10) {
                    templateType = parsedType;
                }
            }
            const templateName = (messageTemplateTypes as any)[templateType.toString()];
            setTemplateTypeId(templateType);
            setTemplateTypeName(templateName);
            init({...DefaultMessageTemplateData, type: templateType, recruiterID: userId});
            setFinishedItemCreationSetup(true);
        };
        itemId === 0 && setCreateData();
    }, [itemId, searchParams, init]);

    const placeholdersEntityIds = useMemo(() => {
        if (fetchedExistingItem || finishedItemCreationSetup) {
            if (state.type === 1 && state.contact) return [2, 4];
            if (state.type === 1 && state.candidate) return [3, 4];
            if (state.type === 2 && state.contact) return [2];
            if (state.type === 2 && state.candidate) return [3];
            if (state.type === 3) return [3, 4];
            if (state.type === 4) return [11];
            if (state.type === 5) return [10];
            if (state.type === 6) return [3, 4];
            if (state.type === 7) return [6, 2];
            if (state.type === 8) return [6, 2];
            if (state.type === 9) return [5];
            if (state.type === 10 && state.contact) return [2, 4];
            if (state.type === 10 && state.candidate) return [3, 4];
        }
        return [];
    }, [fetchedExistingItem, finishedItemCreationSetup, state.candidate, state.contact, state.type]);

    useEffect(() => {
        if (placeholdersEntityIds.length > 0) {
            const getTypePlaceholders = async () => {
                setIsLoading(true);
                const res = await GetPlaceholdersByEntityList(placeholdersEntityIds, setErrorMessage);
                if (res && state.type === 10) setPlaceholders([onboardingPlaceholder, ...res]);
                else if (res) setPlaceholders(res);
                setIsLoading(false);
            };
            getTypePlaceholders();
        }
    }, [placeholdersEntityIds, state.type]);

    const handleStringFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        change(name as keyof MessageTemplate, value)
    }

    const handleIsContactIsCandidateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (value === '1') {
            change('contact' , true);
            change('candidate', false);
        }
        else {
            change('candidate', true);
            change('contact' , false);
        }
    }

    const addFilesCallback = useCallback((files: File[]) => {
        setFilesToUpload(prev => [...prev, ...files]);
    }, []);

    const removeUploadedFileCallback = useCallback((index: number) => {
        setAttachments(prev => {
            let att = [...prev];
            const file = att[index];
            if (file.id !== 0) {
                setAttachmentsToRemove(prev2 => [...prev2, file.id]);
            }
            att.splice(index, 1);
            return att;
        });
    }, []);

    const removeFileCallback = useCallback((index: number) => {
        setFilesToUpload(prev => {
            let files = [...prev];
            files.splice(index, 1);
            return files;
        });
    }, []);

    const downloadFileCallback = useCallback(async (file: SimpleFileObj) => {
        setIsLoading(true);
        await DownloadMessageTemplateAttachmentFile(itemId, file.id);
        setIsLoading(false);
    }, [itemId]);
    
    const { unsavedChangesDialog, hasBlockedRoute } = useUnsavedChangesDialog(hasChanges || isEditorDirty || filesToUpload.length > 0 || attachmentsToRemove.length > 0, saveChangesCallback);

    const insertPlaceholderHandler = useCallback((placeholder: string) => {
        if (templateTypeId === 2) {
            change('body', state.body + placeholder);
            return;
        }
        const api = editorRef.current;
        if (api) api.insertContent(placeholder);
    }, [change, state.body, templateTypeId]);

    return (
        <PageLayout SummaryBar={summaryBar}>
            {unsavedChangesDialog}
            { !hasBlockedRoute && showSuccess && itemId === 0 && <Navigate to="/account/templates/messages" /> }
            {showSuccess &&
                <Snackbar open={showSuccess} autoHideDuration={3000} onClose={() => setShowSuccess(false)}>
                    <Alert onClose={() => setShowSuccess(false)}>Changes Saved</Alert>
                </Snackbar>
            }
            <Snackbar open={errorMessage !== ''} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <InsertPlaceholderDialog
                open={showPlaceholdersDialog}
                closeHandler={() => setShowPlaceholdersDialog(false)}
                insertHandler={insertPlaceholderHandler}
                placeholders={placeholders}
            />
            <PageContentLayout title={`${templateTypeName} Template`} showLoading={isLoading}>
                <Box sx={{ bgcolor: 'background.default', p: 1 }}>
                    <Accordion expanded={isExpandedMetadata} onChange={ () => setIsExpandedMetadata(prev => !prev) }>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            Metadata
                            <Typography color="text.secondary" component="span" ml="auto" mr={2}>{isExpandedMetadata ? 'Click to collapse' : 'Click to expand'}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Stack spacing={2}>
                                <RWTextFieldComponent
                                    label="Name"
                                    value={state.name}
                                    name="name"
                                    onChange={ handleStringFieldChange }
                                />
                                {isTemplateAdmin &&
                                    <UserPicker
                                        onSelect={ u => u && change('recruiterID', u.id) }
                                        userId={state.recruiterID}
                                        label="Template Owner"
                                        appendToStart={companyOwnedUserPickerOption}
                                    />
                                }
                                <TextField
                                    select
                                    label="Recipient"
                                    value={state.contact ? '1' : '2'}
                                    onChange={ handleIsContactIsCandidateChange }
                                >
                                    <MenuItem value="1">Contact</MenuItem>
                                    <MenuItem value="2">Candidate</MenuItem>
                                </TextField>
                            </Stack>
                        </AccordionDetails>
                    </Accordion>
                    {templateTypeId !== 2 &&
                        <Accordion expanded={isExpandedTemplate} onChange={ () => setIsExpandedTemplate(prev => !prev) }>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                Template
                                <Typography color="text.secondary" component="span" ml="auto" mr={2}>{isExpandedTemplate ? 'Click to collapse' : 'Click to expand'}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Stack spacing={2}>
                                    <>
                                        <RWTextFieldComponent
                                            label="Subject"
                                            value={state.subject}
                                            name="subject"
                                            onChange={ handleStringFieldChange }
                                        />
                                    </>
                                    <div style={{ height: '100%', width: '100%', paddingBottom: '3px' }}>
                                        <div style={{ display: 'flex', height: '100%' }}>
                                            <div style={{ flexGrow: 1 }}>
                                                <Editor
                                                    tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce-5.10.2/tinymce.min.js'}
                                                    initialValue={state.body}
                                                    onDirty={ () => setIsEditorDirty(true) }
                                                    onInit={ (evt, editor) => editorRef.current = editor }
                                                    init={{
                                                        height: '300px',
                                                        skin: isDarkTheme ? 'oxide-dark' : undefined,
                                                        content_css: isDarkTheme ? 'dark' : undefined,
                                                        branding: false,
                                                        menubar: false,
                                                        contextmenu: false,
                                                        plugins: 'powerpaste code link emoticons table print preview visualchars lists fullscreen',
                                                        browser_spellcheck: true,
                                                        toolbar1: 'forecolor backcolor | undo redo | bold italic alignleft aligncenter alignright alignjustify bullist numlist outdent indent table | code preview fullscreen',
                                                        toolbar2: 'placeholders link fontsizeselect fontselect mybutton styleselect',
                                                        font_formats: 'Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Calibri=calibri; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Segoe=segoe,segoe ui,dejavu sans,trebuchet ms,verdana,sans-serif; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva',
                                                        setup: editor => {
                                                            editor.ui.registry.addButton('placeholders', {
                                                                text: 'Insert Placeholder',
                                                                onAction: () => setShowPlaceholdersDialog(true)
                                                            });
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <FilePicker
                                        files={ filesToUpload }
                                        uploadedFiles={ attachments }
                                        addFilesHandler={ addFilesCallback }
                                        removeFileHandler={ removeFileCallback }
                                        removeUploadedFileHandler={ removeUploadedFileCallback }
                                        downloadFileHandler={ downloadFileCallback }
                                        limitSizeBytes={AttachmentsLimitBytes}
                                    />
                                </Stack>
                            </AccordionDetails>
                        </Accordion>
                    }
                    {templateTypeId === 2 &&
                        <Accordion expanded={isExpandedTemplate} onChange={ () => setIsExpandedTemplate(prev => !prev) }>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                Template
                                <Typography color="text.secondary" component="span" ml="auto" mr={2}>{isExpandedTemplate ? 'Click to collapse' : 'Click to expand'}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Stack spacing={2}>
                                    <Box>
                                        <Button variant="contained" onClick={() => setShowPlaceholdersDialog(true)}>Insert Placeholder</Button>
                                    </Box>
                                    <RWTextFieldComponent
                                        multiline={true}
                                        minRows={4}
                                        label="Message"
                                        value={state.body}
                                        name="body"
                                        onChange={ handleStringFieldChange }
                                    />
                                </Stack>
                            </AccordionDetails>
                        </Accordion>
                    }
                </Box>
            </PageContentLayout>
        </PageLayout>
    );
}