import { Candidate, CandidateWorkHistory, MinCandidate } from "common/models/Candidates/Candidate";
import { ChangeTracker } from "common/models/hooks/ChangeTracker";

export function CandidateToMinCandidate(c: Candidate): MinCandidate {
    return {
        id: c.id,
        firstName: c.firstName,
        lastName: c.lastName,
        genderID: c.genderID,
        availabilityDate: c.availabilityDate,
        weeksNotice: c.weeksNotice,
        jobSeekingStatusID: c.jobSeekingStatusID,
        dateOfBirth: c.dateOfBirth,
        nationalityID: c.nationalityID,
        divisions: c.divisions,
        email1: c.email1,
        email2: c.email2,
        payrollEmail: c.payrollEmail,
        website: c.website,
        address1: c.address1,
        address2: c.address2,
        countryCode: c.countryCode,
        postcode: c.postcode,
        state: c.state,
        suburb: c.suburb,
        mobile: c.mobile,
        home: c.home,
        work: c.work,
        optOut: c.optOut,
        summary: c.summary,
        preferredJobTypeID: c.preferredJobTypeID,
        preferredRate: c.preferredRate,
        preferredRateUnitsId: c.preferredRateUnitsId,
        preferredSalary: c.preferredSalary,
        preferredSalaryUnitsId: c.preferredSalaryUnitsId,
        linkedInUrl: c.linkedInUrl,
        twitterUrl: c.twitterUrl,
        photoName: c.photoName,
        photoUrl: c.photoUrl,
        customField1: c.customField1, customField2: c.customField2, customField3: c.customField3, customField4: c.customField4, customField5: c.customField5, customField6: c.customField6, customField7: c.customField7, customField8: c.customField8, customField9: c.customField9, customField10: c.customField10,
        customField11: c.customField11, customField12: c.customField12, customField13: c.customField13, customField14: c.customField14, customField15: c.customField15, customField16: c.customField16, customField17: c.customField17, customField18: c.customField18, customField19: c.customField19, customField20: c.customField20,
        customField21: c.customField21, customField22: c.customField22, customField23: c.customField23, customField24: c.customField24, customField25: c.customField25, customField26: c.customField26, customField27: c.customField27, customField28: c.customField28, customField29: c.customField29, customField30: c.customField30, 
        customField31: c.customField31, customField32: c.customField32, customField33: c.customField33, customField34: c.customField34, customField35: c.customField35, customField36: c.customField36, customField37: c.customField37, customField38: c.customField38, customField39: c.customField39, customField40: c.customField40, 
        customField41: c.customField41, customField42: c.customField42, customField43: c.customField43, customField44: c.customField44, customField45: c.customField45, customField46: c.customField46, customField47: c.customField47, customField48: c.customField48, customField49: c.customField49, customField50: c.customField50, 
        customField51: c.customField51, customField52: c.customField52, customField53: c.customField53, customField54: c.customField54, customField55: c.customField55, customField56: c.customField56, customField57: c.customField57, customField58: c.customField58, customField59: c.customField59, customField60: c.customField60
    };
}

export const DefaultMinCandidate: MinCandidate = {
    id: 0,
    firstName: '',
    lastName: '',
    genderID: 0,
    availabilityDate: '',
    weeksNotice: 0,
    jobSeekingStatusID: 0,
    dateOfBirth: '',
    nationalityID: 0,
    divisions: '',
    email1: '',
    email2: '',
    payrollEmail: '',
    website: '',
    address1: '',
    address2: '',
    countryCode: '',
    postcode: '',
    state: '',
    suburb: '',
    mobile: '',
    home: '',
    work: '',
    optOut: false,
    summary: '',
    preferredJobTypeID: 0,
    preferredRate: 0,
    preferredRateUnitsId: 0,
    preferredSalary: 0,
    preferredSalaryUnitsId: 0,
    linkedInUrl: '',
    twitterUrl: '',
    photoName: '',
    photoUrl: '',
    customField1: '', customField2: '', customField3: '', customField4: '', customField5: '', customField6: '', customField7: '', customField8: '', customField9: '', customField10: '',
    customField11: '', customField12: '', customField13: '', customField14: '', customField15: '', customField16: '', customField17: '', customField18: '', customField19: '', customField20: '',
    customField21: '', customField22: '', customField23: '', customField24: '', customField25: '', customField26: '', customField27: '', customField28: '', customField29: '', customField30: '',
    customField31: '0001-01-01T00:00:00', customField32: '0001-01-01T00:00:00', customField33: '0001-01-01T00:00:00', customField34: '0001-01-01T00:00:00', customField35: '0001-01-01T00:00:00', customField36: '0001-01-01T00:00:00', customField37: '0001-01-01T00:00:00', customField38: '0001-01-01T00:00:00', customField39: '0001-01-01T00:00:00', customField40: '0001-01-01T00:00:00', customField41: '0001-01-01T00:00:00', customField42: '0001-01-01T00:00:00', customField43: '0001-01-01T00:00:00', customField44: '0001-01-01T00:00:00', customField45: '0001-01-01T00:00:00',
    customField46: undefined, customField47: undefined, customField48: undefined, customField49: undefined, customField50: undefined, customField51: undefined, customField52: undefined, customField53: undefined, customField54: undefined, customField55: undefined, customField56: undefined, customField57: undefined, customField58: undefined, customField59: undefined, customField60: undefined,
};

export const DefaultMinCandidateNoChanges: ChangeTracker<MinCandidate> = {
    id: false,
    firstName: false,
    lastName: false,
    genderID: false,
    availabilityDate: false,
    weeksNotice: false,
    jobSeekingStatusID: false,
    dateOfBirth: false,
    nationalityID: false,
    divisions: false,
    email1: false,
    email2: false,
    payrollEmail: false,
    website: false,
    address1: false,
    address2: false,
    countryCode: false,
    postcode: false,
    state: false,
    suburb: false,
    mobile: false,
    home: false,
    work: false,
    optOut: false,
    summary: false,
    preferredJobTypeID: false,
    preferredRate: false,
    preferredRateUnitsId: false,
    preferredSalary: false,
    preferredSalaryUnitsId: false,
    linkedInUrl: false,
    twitterUrl: false,
    photoName: false,
    photoUrl: false,
    customField1: false, customField2: false, customField3: false, customField4: false, customField5: false, customField6: false, customField7: false, customField8: false, customField9: false, customField10: false,
    customField11: false, customField12: false, customField13: false, customField14: false, customField15: false, customField16: false, customField17: false, customField18: false, customField19: false, customField20: false,
    customField21: false, customField22: false, customField23: false, customField24: false, customField25: false, customField26: false, customField27: false, customField28: false, customField29: false, customField30: false, 
    customField31: false, customField32: false, customField33: false, customField34: false, customField35: false, customField36: false, customField37: false, customField38: false, customField39: false, customField40: false, 
    customField41: false, customField42: false, customField43: false, customField44: false, customField45: false, customField46: false, customField47: false, customField48: false, customField49: false, customField50: false, 
    customField51: false, customField52: false, customField53: false, customField54: false, customField55: false, customField56: false, customField57: false, customField58: false, customField59: false, customField60: false
};

export const PlaceholderCandidate: Candidate = {
    id: -1,
    fullName: "Glen Perry",
    firstName: "Glen",
    lastName: "Perry",
    email1: "glen@recruitwizard.com",
    email2: "glen@wizardsoft.com",
    payrollEmail: "glen@payroll.com",
    mobile: "0408 808 877",
    home: "02 8071 8750",
    work: "+61 2 8071 8700",
    mobile_Standardised: "+61408808877",
    home_Standardised: "+61280718750",
    work_Standardised: "+61280718700",
    jobTitle: "CEO",
    employer: "Recruit Wizard",
    genderID: 1,
    address1: "Suite 1, Level 1",
    address2: "16 Greenfield Parade",
    suburb: "Bankstown",
    state: "NSW",
    postcode: "2200",
    countryCode: "AU",
    countryName: "Australia",
    linkedInUrl: "",
    facebookUrl: "",
    twitterUrl: "",
    photoName: "",
    photoUrl: "",
    dateOfBirth: "2010-10-10T10:10:10.100",
    preferredRate: 25.15,
    preferredRateUnits: 'Monthly',
    preferredRateUnitsId: 4,
    preferredSalary: 500.25,
    preferredSalaryUnits: 'Monthly',
    preferredSalaryUnitsId: 4,
    jobSeekingStatusID: 0,
    jobSeekingStatusName: "Not Currently Looking",
    preferredJobTypeID: 0,
    preferredJobTypeName: "Contract / Temp",
    summary: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam in lacinia est, vel venenatis felis. Praesent sed rhoncus arcu. Nulla quis pellentesque orci. Duis blandit sodales auctor. Etiam ut justo egestas lacus sodales molestie. Nulla facilisi. Curabitur in metus odio. Nullam eu auctor nunc. In hac habitasse platea dictumst. Nulla ultrices lacinia leo ut blandit. Nullam aliquam justo enim, aliquet suscipit dui bibendum id. Fusce vitae egestas leo, nec mollis nibh. Donec libero libero, finibus vitae tellus eu, tincidunt rhoncus mi.<br/><br/> Cras dui ipsum, varius ut magna sit amet, lacinia tincidunt velit. Proin interdum ex augue. Etiam ac risus nunc. Nullam enim nunc, eleifend vel sem eget, interdum fermentum enim. Donec consectetur nibh quis dui rutrum ultrices. Nam congue nibh neque, at commodo elit fringilla quis. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Curabitur et semper sapien. Vivamus congue ante non lacus bibendum scelerisque ac sit amet felis. Morbi sit amet nunc faucibus, laoreet nunc quis, scelerisque purus.",
    profileStatusID: 0,
    candidateClientID: 0,
    candidateClientName: "",
    candidatePlacementTypeID: 0,
    candidatePlacementClientID: 0,
    candidatePlacementClientName: "",
    hasResume: false,
    hasResumeViewerDoc: false,
    lastUpdateDate: null,
    resumeUpdateDate: null,
    isRedFlagged: false,
    redFlaggedDate: '2010-10-10T10:10:10.100',
    redFlaggedByUserName: '',
    redFlaggedComments: '',
    tags: false,
    divisions: "",
    availabilityDate: "2010-10-10T10:10:10.100",
    weeksNotice: 5,
    nationality: "Australian",
    nationalityID: 0,
    website: "https://recruitwizard.com",
    optOut: false,
    customField1: "Custom Field 1",
    customField2: "Custom Field 2",
    customField3: "Custom Field 3",
    customField4: "Custom Field 4",
    customField5: "Custom Field 5",
    customField6: "Custom Field 6",
    customField7: "Custom Field 7",
    customField8: "Custom Field 8",
    customField9: "Custom Field 9",
    customField10: "Custom Field 10",
    customField11: "Custom Field 11",
    customField12: "Custom Field 12",
    customField13: "Custom Field 13",
    customField14: "Custom Field 14",
    customField15: "Custom Field 15",
    customField16: "Custom Field 16",
    customField17: "Custom Field 17",
    customField18: "Custom Field 18",
    customField19: "Custom Field 19",
    customField20: "Custom Field 20",
    customField21: "Custom Field 21",
    customField22: "Custom Field 22",
    customField23: "Custom Field 23",
    customField24: "Custom Field 24",
    customField25: "Custom Field 25",
    customField26: "Custom Field 26",
    customField27: "Custom Field 27",
    customField28: "Custom Field 28",
    customField29: "Custom Field 29",
    customField30: "Custom Field 30",
    customField31: "2010-10-10T10:10:10.100",
    customField32: "2010-10-10T10:10:10.100",
    customField33: "2010-10-10T10:10:10.100",
    customField34: "2010-10-10T10:10:10.100",
    customField35: "2010-10-10T10:10:10.100",
    customField36: "2010-10-10T10:10:10.100",
    customField37: "2010-10-10T10:10:10.100",
    customField38: "2010-10-10T10:10:10.100",
    customField39: "2010-10-10T10:10:10.100",
    customField40: "2010-10-10T10:10:10.100",
    customField41: "2010-10-10T10:10:10.100",
    customField42: "2010-10-10T10:10:10.100",
    customField43: "2010-10-10T10:10:10.100",
    customField44: "2010-10-10T10:10:10.100",
    customField45: "2010-10-10T10:10:10.100",
    customField46: 46,
    customField47: 47,
    customField48: 48,
    customField49: 49,
    customField50: 50,
    customField51: 51,
    customField52: 52,
    customField53: 53,
    customField54: 54,
    customField55: 55,
    customField56: 56,
    customField57: 57,
    customField58: 58,
    customField59: 59,
    customField60: 60,
    statisticsDates: [],
    statisticsCounts: []
}

export const PlaceholderCandidateWorkHistory: CandidateWorkHistory[] = [
    {
        id: 1,
        title: 'Placeholder Title 1',
        organizationName: 'Placeholder Org 1',
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt atque, earum nobis iste dolor doloremque itaque hic ratione recusandae sint. Adipisci eius possimus nemo debitis culpa obcaecati, nesciunt dolor illo ea! Nostrum veniam vero dicta, nobis laborum et non harum mollitia cupiditate maxime. Accusamus debitis eius tempore, iure, minus ab aperiam consequatur beatae quasi officia soluta maxime saepe quisquam molestias ipsam possimus adipisci eaque? Ullam voluptatibus pariatur, reiciendis dolorem cum unde atque! Ratione aperiam minima provident! Incidunt facilis magni, quidem mollitia atque saepe reiciendis suscipit tenetur unde ipsum beatae reprehenderit deleniti ullam ex a, voluptatem, dolorem fugiat rem eos quo?',
        jobTypeID: 0,
        jobTypeName: 'Unspecified',
        startDate: '2020-01-01',
        endDate: '2020-02-01',
    },
    {
        id: 1,
        title: 'Placeholder Title 2',
        organizationName: 'Placeholder Org 2',
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt atque, earum nobis iste dolor doloremque itaque hic ratione recusandae sint. Adipisci eius possimus nemo debitis culpa obcaecati, nesciunt dolor illo ea! Nostrum veniam vero dicta, nobis laborum et non harum mollitia cupiditate maxime. Accusamus debitis eius tempore, iure, minus ab aperiam consequatur beatae quasi officia soluta maxime saepe quisquam molestias ipsam possimus adipisci eaque? Ullam voluptatibus pariatur, reiciendis dolorem cum unde atque! Ratione aperiam minima provident! Incidunt facilis magni, quidem mollitia atque saepe reiciendis suscipit tenetur unde ipsum beatae reprehenderit deleniti ullam ex a, voluptatem, dolorem fugiat rem eos quo?',
        jobTypeID: 0,
        jobTypeName: 'Unspecified',
        startDate: '2020-01-01',
        endDate: null,
    },
];
