import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { GridColDef } from "@mui/x-data-grid-premium";
import { BasicFilter } from "common/models/Analytics/AnalyticsFilterData";
import { PlacementsContractFinancialsOverviewRecord } from "common/models/Analytics/BusinessIntelligence";
import GridWithStateWrapper from "components/GridWidthStateWrapper";
import React, { useEffect, useMemo, useState } from "react";
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { GetContractFinancialsOverview } from "services/BusinessIntelligence";

interface Props {
    filterData: BasicFilter,
    graphType?: 'table' | 'graph',
    loadingHandler?: (isLoading: boolean) => void
}

interface PlacementsContractFinancialsOverviewRecordWithId extends PlacementsContractFinancialsOverviewRecord {
    id: number,
}

const gridName = 'analytics/placements/contract-financials/overview';

export default function PlacementsContractFinancialsOverviewContent({ filterData, graphType = 'graph', loadingHandler }: Props) {
    const [rows, setRows] = useState<PlacementsContractFinancialsOverviewRecordWithId[]>([]);
    const t = useTheme();

    useEffect(() => {
        const getData = async () => {
            loadingHandler && loadingHandler(true);
            const res = await GetContractFinancialsOverview(filterData);
            if (res) {
                const data: PlacementsContractFinancialsOverviewRecordWithId[] = res.map((r, i) => ({ ...r, id: i }));
                setRows(data);
            }
            loadingHandler && loadingHandler(false);
        };
        getData();
    }, [filterData, loadingHandler]);

    const positiveData = useMemo<PlacementsContractFinancialsOverviewRecordWithId[]>(() => {
        return rows.map(r => ({ 
            id: r.id,
            period: r.period,
            difference: r.difference < 0 ? 0 : r.difference,
            lastPeriod: r.lastPeriod < 0 ? 0 : r.lastPeriod,
            thisPeriod: r.thisPeriod < 0 ? 0 : r.thisPeriod
        }));
    }, [rows]);

    const columns = useMemo<GridColDef[]>(() => {
        return [
            { field: 'period', headerName: 'Period', width: 250 },
            { field: 'thisPeriod', headerName: 'This Period', headerAlign: 'center', align: 'right', width: 150 },
            { field: 'lastPeriod', headerName: 'Last Period', headerAlign: 'center', align: 'right', width: 150 },
            { field: 'difference', headerName: 'Difference', headerAlign: 'center', align: 'right', width: 150 },
        ];
    }, []);

    if (graphType === 'table') {
        return (
            <GridWithStateWrapper
                gridName={gridName}
                rows={rows}
                density="compact"
                columns={columns}
                sx={{ mt: '20px' }}
            />
        );
    }
    else if (graphType === 'graph') {
        return (
            <>
                <Typography variant="h6" padding={1} textAlign="center" fontWeight={600} component="div">Contract Financials Overview</Typography>
                <div style={{ height: 'calc(100% - 48px)' }}>
                    <ResponsiveContainer width="100%" height="100%">
                        <BarChart width={150} height={150} data={positiveData}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="period" stroke={t.palette.text.primary} />
                            <YAxis type="number" width={100} tickFormatter={v => v.toLocaleString()} stroke={t.palette.text.primary} />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="lastPeriod" name="Last Period" fill={t.palette.info.main} offset="positive" />
                            <Bar dataKey="thisPeriod" name="This Period" fill={t.palette.error.main} offset="positive" />
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </>
        );
    }

    return <></>;
}