import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import RWTextFieldComponent from "components/RWTextFieldComponent";
import React, { useCallback, useEffect, useState } from "react";
import { ReverseChangeRequest } from "services/ChangeRequestsService";

interface Props {
    open: boolean,
    changeRequestId: number,
    closeHandler: () => void,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
    successHandler?: (message: string) => void,
}

export default function ReverseChangeRequestDialog({ open, changeRequestId, closeHandler, loadingHandler, errorHandler, successHandler }: Props) {
    const [comments, setComments] = useState('');

    useEffect(() => {
        if (!open) setComments('');
    }, [open]);

    const submitCallback = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        const res = await ReverseChangeRequest(changeRequestId, comments, errorHandler);
        if (res) {
            successHandler && successHandler('Change Request Reversed');
            closeHandler();
        }
        loadingHandler && loadingHandler(false);
    }, [changeRequestId, comments, loadingHandler, successHandler, errorHandler, closeHandler]);

    return (
        <Dialog open={open} fullWidth>
            <DialogTitle>Reverse Change Request</DialogTitle>
            <DialogContent>
                <RWTextFieldComponent label="Comments" value={comments} onChange={(e) => setComments(e.target.value)} rows={5} multiline={true} />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="error" onClick={closeHandler}>Cancel</Button>
                <Button variant="contained" color="success" onClick={submitCallback}>Submit</Button>
            </DialogActions>
        </Dialog>
    );
}