import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import React, { useCallback, useMemo } from "react";
import { Cell, Label, Pie, PieChart, ResponsiveContainer } from "recharts";

interface Props {
    title: string,
    target: number,
    actual: number,
    targetToDate: number,
    explaination: string,
    color: string,
}

const testData = [
    { name: 'Group A', value: 100 },
];

export default function KpiGaugeGraph({ title, target, actual, targetToDate, explaination: explanation, color: colorProp }: Props) {
    const t = useTheme();

    const color = useMemo(() => {
        switch (colorProp) {
            case 'green': return t.palette.success.main;
            case 'orange': return t.palette.warning.main;
            case 'red': return t.palette.error.main;
            default: return t.palette.info.main;
        }
    }, [colorProp, t.palette.error.main, t.palette.info.main, t.palette.success.main, t.palette.warning.main]);

    const getAngleValue = useCallback((v: number) => {
        if (target === 0) return 0;
        return (v * 360) / target;
    }, [target]);

    const targetToDateAngle = useMemo(() => {
        return getAngleValue(targetToDate);
    }, [getAngleValue, targetToDate]);

    const actualAngle = useMemo(() => {
        return getAngleValue(actual);
    }, [actual, getAngleValue]);

    const percentage = useMemo(() => {
        if (target === 0) return 'N/A';
        return Math.round((actual * 100) / target) + '%';
    }, [actual, target]);
    
    return (
        <Paper sx={{ p: '10px', width: '225px' }}>
            <Box display="flex">
                <Box flexGrow={1}>
                    <Typography component="div" variant="body2">{title}</Typography>
                    <Typography component="div" variant="caption">Target: {target}</Typography>
                </Box>
                <Typography variant="h5">{actual}</Typography>
            </Box>
            <ResponsiveContainer height={150}>
                <PieChart height={150}>
                    <Pie
                        data={testData}
                        dataKey="value"
                        startAngle={90}
                        endAngle={90 - targetToDateAngle}
                        innerRadius={40}
                        outerRadius={60}
                    >
                        <Cell fill={t.palette.text.disabled} />
                    </Pie>
                    <Pie
                        data={testData}
                        dataKey="value"
                        startAngle={90}
                        endAngle={90 - actualAngle}
                        innerRadius={40}
                        outerRadius={60}
                    >
                        <Label
                            fontSize={20}
                            value={percentage}
                            position="center"
                            fill={t.palette.text.primary}
                        />
                        <Cell fill={color} />
                    </Pie>
                </PieChart>
            </ResponsiveContainer>
            <Typography variant="caption" color={color}>{explanation}</Typography>
        </Paper>
    );
}