import Alert from "components/Alert";
import Snackbar from "@mui/material/Snackbar";
import { GridColDef, GridRenderCellParams, GridRowSelectionModel, GridSortModel, GridValueGetter, useGridApiRef } from "@mui/x-data-grid-premium";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { UnlinkedMail } from "common/models/Activities";
import ConfirmationDialog from "components/Dialogs/Generic/ConfirmationDialog";
import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import PageContentLayout from "layouts/PageContentLayout";
import PageLayout from "layouts/PageLayout";
import { CreateExclussion_EamilIntegration, CreateMultipleExclussions_EamilIntegration, IgnoreEmailMessage, IgnoreMultipleEmailMessages } from "services/MessagesService";
import { GetMyUnlinkedMail } from "services/UsersService";
import MenuItem from "@mui/material/MenuItem";
import { IdEmailWrapper } from "common/models/Common";
import GridWithStateWrapper from "components/GridWidthStateWrapper";
import ActionsDropDownButton from "components/SummaryBars/Actions/ActionsDropsDownMenu";
import { GridInitialStatePremium } from "@mui/x-data-grid-premium/models/gridStatePremium";
import moment from "moment";

const spanLinkStyle: React.CSSProperties = { cursor: 'pointer', textDecoration: 'underline' };
const routerLinkStyle: React.CSSProperties = { color: 'inherit', textDecoration: 'underline' };

export default function AccountUnlinkedMailPage() {
    const [summaryBar, setSummaryBar] = useState<JSX.Element>(<></>);
    const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
    const [showConfirmIgnore, setShowConfirmIgnore] = useState(false);
    const [showConfirmExclude, setShowConfirmExclude] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [rows, setRows] = useState<UnlinkedMail[]>([]);
    const apiRef = useGridApiRef();

    const ignoreMultipleHandler = useCallback(async () => {
        setIsLoading(true);
        const res = await IgnoreMultipleEmailMessages(selectionModel as number[], setErrorMessage);

        if (!res) {
            setIsLoading(false);
            return false;
        }

        setRows(prev => {
            const ids = selectionModel as number[];
            let newRows = [...prev];
            for (let i = 0; i < ids.length; i++) {
                const id = ids[i];
                const index = newRows.findIndex(r => r.activityID === id);
                if (index !== -1) newRows.splice(index, 1);
            }
            setSelectionModel([]);
            return newRows;
        });

        setShowSuccess(true);
        setIsLoading(false);
        setShowConfirmIgnore(false);
        return true;
    }, [selectionModel]);

    const excludeMultipleHandler = useCallback(async () => {
        setIsLoading(true);
        let excludeData: IdEmailWrapper[] = [];
        const ids = selectionModel as number[];
        for (let i = 0; i < ids.length; i++) {
            const id = ids[i];
            const row = apiRef.current.getRow(id) as UnlinkedMail;

            let fromTo = '';
            switch (row.typeID) {
                case 0:
                    fromTo = row.from;
                    break;
                case 1:
                    fromTo = row.to;
                    break;
            }

            excludeData.push({ id: id, email: fromTo });
        }

        const res = await CreateMultipleExclussions_EamilIntegration(excludeData, setErrorMessage);
        if (!res) {
            setIsLoading(false);
            return false;
        }

        setRows(prev => {
            let newRows = [...prev];
            for (let i = 0; i < ids.length; i++) {
                const id = ids[i];
                const index = newRows.findIndex(r => r.activityID === id);
                if (index !== -1) newRows.splice(index, 1);
            }
            setSelectionModel([]);
            return newRows;
        });

        setShowSuccess(true);
        setIsLoading(false);
        setShowConfirmExclude(false);
        return true;
    }, [apiRef, selectionModel]);

    useEffect(() => {
        const handleExcludeClick = () => setShowConfirmExclude(true);
        const handleIgnoreClick = () => setShowConfirmIgnore(true);

        const actionsMenu = (
            <ActionsDropDownButton>
                <MenuItem onClick={handleExcludeClick} disabled={selectionModel.length === 0}>Exclude</MenuItem>
                <MenuItem onClick={handleIgnoreClick} disabled={selectionModel.length === 0}>Ignore</MenuItem>
            </ActionsDropDownButton>
        );

        const summaryBar = (
            <TitleAndActionSummaryBar
                title="Account > Email Integration > Unlinked Mail"
                browserTabTitle="Unlinked Mail > Email Integration > Account"
                action={actionsMenu}
            />
        );
        setSummaryBar && setSummaryBar(summaryBar);
    }, [selectionModel.length]);

    useEffect(() => {
        const getRows = async () => {
            setIsLoading(true);
            const rows = await GetMyUnlinkedMail(setErrorMessage);
            if (rows) setRows(rows);
            setIsLoading(false);
        };
        getRows();
    }, []);

    const ignoreSingleMessageHandler = useCallback(async (messageId: number) => {
        setIsLoading(true);
        const res = await IgnoreEmailMessage(messageId, setErrorMessage);
        if (res) {
            setRows(prev => {
                let newRows = [...prev];
                const index = newRows.findIndex(r => r.activityID === messageId);
                if (index !== -1) newRows.splice(index, 1);
                return newRows;
            });
            setShowSuccess(true);
        }
        setIsLoading(false);
    }, []);

    const excludeSingleMessageHandler = useCallback(async (messageId: number, email: string) => {
        setIsLoading(true);
        const res = await CreateExclussion_EamilIntegration(messageId, email, setErrorMessage);
        if (res) {
            setRows(prev => {
                let newRows = [...prev];
                const index = newRows.findIndex(r => r.activityID === messageId);
                if (index !== -1) newRows.splice(index, 1);
                return newRows;
            });
            setShowSuccess(true);
        }
        setIsLoading(false);
    }, []);

    const columns = useMemo<GridColDef[]>(() => {
        const dateValueGetter: GridValueGetter<UnlinkedMail, any, undefined, string> = (value) => {
            if (value) {
                const d = moment(value);
                if (d.isValid() && d.get("year") > 1) {
                    return d.toDate();
                }
            }
            return null;
        };

        const dateRenderer = (params: GridRenderCellParams) => {
            if (params.value) {
                return moment(params.value).format('DD MMM YYYY hh:mm:ss A');
            }
            return 'Never';
        };
        
        const fromToRenderer = (params: GridRenderCellParams) => {
            switch (params.row.typeID) {
                case 0:
                    return params.row.from;
                case 1:
                    return params.row.to;
                default:
                    return '';
            }
        };

        const actionsRenderer = (params: GridRenderCellParams) => {
            const messageId = params.row.activityID;
            let fromTo = '';

            switch (params.row.typeID) {
                case 0:
                    fromTo = params.row.from;
                    break;
                case 1:
                    fromTo = params.row.to;
                    break;
            }

            return (
                <>
                    <span style={{...spanLinkStyle, marginRight: '15px'}} onClick={ () => ignoreSingleMessageHandler(messageId) } >Ignore</span> |
                    <span style={{...spanLinkStyle, margin: '0 15px'}} onClick={ () => excludeSingleMessageHandler(messageId, fromTo) } >Exclude</span> |
                    <Link to={`/contacts/create?email=${encodeURIComponent(fromTo)}`} style={{ ...routerLinkStyle, margin: '0 15px' }} >Create Contact</Link> |
                    <Link to={`/candidates/create?email=${encodeURIComponent(fromTo)}`} style={{ ...routerLinkStyle, marginLeft: '15px' }} >Create Candidate</Link>
                </>
            );
        }

        const subjectRenderer = (params: GridRenderCellParams) => <Link target={"_blank"} to={`/messages/${params.id}`} style={routerLinkStyle}>{params.value}</Link>;

        return [
            { field: 'messageDate', headerName: 'Date', type: 'dateTime', width: 200, valueGetter: dateValueGetter, renderCell: dateRenderer },
            { field: 'from', headerName: 'From / To', width: 300, renderCell: fromToRenderer },
            { field: 'subject', headerName: 'Subject', width: 500, renderCell: subjectRenderer },
            { field: 'actions', headerName: 'Actions', width: 450, renderCell: actionsRenderer, disableColumnMenu: true, sortable: false },
        ];
    }, [excludeSingleMessageHandler, ignoreSingleMessageHandler]);

    const onSortChange = useCallback((model: GridSortModel) => {
        localStorage.setItem('UnlinkedMailGrid_SortModel', JSON.stringify(model));
    }, []);

    const gridInitialState = useMemo<GridInitialStatePremium | undefined>(() => {
        const savedSortModel = localStorage.getItem('UnlinkedMailGrid_SortModel');
        if (savedSortModel) {
            const model = JSON.parse(savedSortModel) as GridSortModel;
            return { sorting: { sortModel: model } };
        }
        return undefined;
    }, []);

    return (
        <PageLayout SummaryBar={summaryBar}>
            {showConfirmExclude &&
                <ConfirmationDialog
                    open={true}
                    title="Exclude Multiple"
                    message={`Are you sure you want to exclude all selected messages (${selectionModel.length}) ?`}
                    onClose={ () => setShowConfirmExclude(false) }
                    onContinue={ excludeMultipleHandler }
                />
            }
            {showConfirmIgnore &&
                <ConfirmationDialog
                    open={true}
                    title="Ignore Multiple"
                    message={`Are you sure you want to ignore all selected messages (${selectionModel.length}) ?`}
                    onClose={ () => setShowConfirmIgnore(false) }
                    onContinue={ ignoreMultipleHandler }
                />
            }
            <Snackbar open={showSuccess} autoHideDuration={3000} onClose={() => setShowSuccess(false)}>
                <Alert onClose={() => setShowSuccess(false)}>Changes Saved</Alert>
            </Snackbar>
            <Snackbar open={errorMessage !== ''} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <PageContentLayout title="Unlinked Mail" showLoading={isLoading}>
                <GridWithStateWrapper
                    gridName="account/email-integration/unlinked-mail_UnlinkedMail"
                    getRowId={ row => row.activityID }
                    apiRef={apiRef}
                    rows={rows}
                    columns={columns}
                    disableRowSelectionOnClick
                    rowSelectionModel={selectionModel}
                    onRowSelectionModelChange={ sm => setSelectionModel(sm) }
                    pagination
                    density="compact"
                    checkboxSelection
                    onSortModelChange={onSortChange}
                    initialState={gridInitialState}
                    pageSizeOptions={[100,250,500,1000]}
                />
            </PageContentLayout>
        </PageLayout>
    );
}