import MenuItem from "@mui/material/MenuItem";
import { GridColDef, GridColumnVisibilityModel, GridRenderCellParams, GridRowSelectionModel, GridValueGetter, useGridApiRef } from "@mui/x-data-grid-premium";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ComplianceData } from "common/models/ComplianceData";
import { Permission } from "common/models/Permissions";
import { DownloadDocument } from "services/DocumentsService";
import { GetComplianceData } from "services/ComplianceService";
import { companyHasSinglePermission, userHasSinglePermission } from "util/PermissionsUtils";
import GridWithStateWrapper from "../GridWidthStateWrapper";
import ActionsDropDownButton from "../SummaryBars/Actions/ActionsDropsDownMenu";
import IconButton from "@mui/material/IconButton";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import EditComplianceDataDialog from "../Dialogs/Compliance/EditComplianceDataDialog";
import Box from "@mui/material/Box";

interface Props {
    candidateId: number,
    placementId: number,
    checklistId: number,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
    successHandler?: (message: string) => void,
    gridName: string
}

export default function ComplianceGridComponent({ candidateId, placementId, checklistId, loadingHandler, errorHandler, successHandler, gridName }: Props) {
    const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
    const [rows, setRows] = useState<ComplianceData[]>([]);
    const [selectedRow, setSelectedRow] = useState<ComplianceData | null>(null);
    // const [showUploadDialog, setShowUploadDialog] = useState(false);
    // const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    // const [editRow, setEditRow] = useState<ComplianceData | null>(null);
    const apiRef = useGridApiRef();
    
    const getData = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        let res: ComplianceData[] | null = [];
        res = await GetComplianceData(candidateId, placementId, checklistId, errorHandler);
        if (res) setRows(res);
        loadingHandler && loadingHandler(false);
    }, [candidateId, placementId, checklistId, loadingHandler, errorHandler]);

    useEffect(() => {
        getData();
    }, [getData]);

    const downloadDocumentCallback = useCallback(async (documentId: number) => {
        loadingHandler && loadingHandler(true);
        await DownloadDocument(documentId, undefined, errorHandler);
        loadingHandler && loadingHandler(false);
    }, [loadingHandler, errorHandler]);

    const columns = useMemo<GridColDef[]>(() => {
        const dateValueGetter: GridValueGetter<ComplianceData, any, undefined, string> = (value) => {
            if (value) {
                const d = moment(value);
                if (d.isValid() && d.get('year') > 1) {
                    return d.toDate();
                }
            }
        };

        const dateRenderer = (params: GridRenderCellParams) => {
            if (params.value) {
                return moment(params.value).format('DD MMM YYYY');
            }
        };

        // const createdByRenderer = (params: GridRenderCellParams) => {
        //     if (params.row.createdById === -2) return "Client Portal";
        //     return params.value;
        // };

        const EditRenderer = (params: GridRenderCellParams) => {
            return (
                <span
                    style={{ cursor: 'pointer', textDecoration: 'underline' }}
                    onClick={ () => setSelectedRow(params.row) }
                >
                    {params.value}
                </span>
            );
            // return (
            //     <span
            //         style={{ cursor: 'pointer', textDecoration: 'underline' }}
            //         onClick={ () => downloadDocumentCallback(params.id as number) }
            //     >{params.value}</span>
            // );
        };

        const downloadDocumentRenderer = (params: GridRenderCellParams) => {
            if (params.row.documentID) {
                return (

                    <span
                        style={{ cursor: 'pointer', textDecoration: 'underline' }}
                        onClick={() => downloadDocumentCallback(params.row.documentID as number)}
                    ><IconButton size="small" sx={{ mt: '5px' }}>
                            <AttachFileIcon />
                        </IconButton></span>
                );
            }
                return <></>;
        };
        
        return [
            { headerName: 'Name', field: 'name', width: 250, renderCell: EditRenderer },
            { headerName: '', field: 'attachments', type: 'number', width: 30, renderCell: downloadDocumentRenderer },
            { headerName: 'Type', field: 'typeName', headerAlign: 'center', align: 'center', width: 150 },
            { headerName: 'Scope', field: 'scopeName', headerAlign: 'center', align: 'center', width: 150 },
            { headerName: 'Updated By', field: 'updatedByName', headerAlign: 'center', align: 'center', width: 250 },
            { headerName: 'Updated Date', field: 'updatedDate', headerAlign: 'center', align: 'center', width: 150, type: 'date', valueGetter: dateValueGetter, renderCell: dateRenderer },
            { headerName: 'Expiry Date', field: 'expiryDate', headerAlign: 'center', align: 'center', width: 150, type: 'date', valueGetter: dateValueGetter, renderCell: dateRenderer },
            { headerName: 'Notes', field: 'notes', width: 400 },
        ];
    }, [downloadDocumentCallback]);

    const successCallback = useCallback((message: string) => {
        successHandler && successHandler(message);
        getData();
    }, [successHandler, getData]);

    // const editClickCallback = useCallback(() => {
    //     if (selectionModel.length === 1) {
    //         const selectedRow = rows.find(r => r.id === selectionModel[0]);
    //         if (selectedRow) setEditRow(selectedRow);
    //     }
    // }, [selectionModel, rows]);

    const exportToExcel = useCallback(() => {
        const api = apiRef.current;
        if (api) api.exportDataAsExcel();
    }, [apiRef]);

    const canExportData = useMemo(() => companyHasSinglePermission(Permission.MiscExport) && userHasSinglePermission(Permission.MiscExport), []);

    const gridActions = useMemo(() => {
        if (canExportData) {
            return (
                <ActionsDropDownButton color="secondary" label="List Actions">
                    { canExportData && <MenuItem onClick={exportToExcel}>Export</MenuItem> }
                </ActionsDropDownButton>
            );
        }
        return undefined;
    }, [canExportData, exportToExcel]);

    const defaultHiddenCols = useMemo<GridColumnVisibilityModel>(() => {
        let model: GridColumnVisibilityModel = { };
        if (placementId === 0) model['scopeName'] = false;
        return model;
    }, [placementId]);

    return (
        <>
            <EditComplianceDataDialog
                open={Boolean(selectedRow)}
                itemId={selectedRow ? selectedRow.id : 0}
                candidateId={candidateId}
                placementId={placementId}
                data={selectedRow}
                closeHandler={() => setSelectedRow(null)}
                loadingHandler={loadingHandler}
                successHandler={successCallback}
                errorHandler={errorHandler}
            />
            {Boolean(gridActions) &&
                <Box pb="10px" ml="auto">
                    {gridActions}
                </Box>
            }
            <GridWithStateWrapper
                gridName={gridName}
                defaultViewModel={defaultHiddenCols}
                rows={rows}
                columns={columns}
                apiRef={apiRef}
                density="compact"
                checkboxSelection
                disableRowSelectionOnClick
                rowSelectionModel={selectionModel}
                onRowSelectionModelChange={ sm => setSelectionModel(sm) }
                pagination
                pageSizeOptions={[100,250,500,1000]}
            />
        </>
    );
}