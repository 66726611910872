import React, { useCallback, useEffect, useState } from "react";
import Alert from "components/Alert";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import PageContentLayout from "layouts/PageContentLayout";
import { GetJobsSettings, SaveJobsSettings, GetAutomatedRejectionsOptions } from "services/ConfigurationService";
import { ObjToSettingsArray, SettingsArrayToObj } from "util/SettingsUtil";
import { JobsConfigOptions } from "common/models/Configuration/Jobs";
import { DefaultOptions, NoChangesTracker } from "util/Definitions/Configuration/Jobs";
import useObjectStateWithChangeTracker from "hooks/UseObjectStateWithChangeTracker";
import useUnsavedChangesDialog from "hooks/UseUnsavedChangesDialog";
import { GetWorkflowItemsOrder } from "services/WorkflowService";
import { RegexIsPositiveNumberWith2Decimals } from "util/RegExUtils";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import TextAreaWithFormat from "components/TextAreaWithFormat";
import RWTextFieldComponent from "components/RWTextFieldComponent";

interface Props {
    setSummaryBar?: (summaryBar: JSX.Element) => void
}

const BusinessDaysAdornment = <InputAdornment position="end">Business Day(s)</InputAdornment>;
const automatedRejectionNone = <MenuItem key={0} value="0">None</MenuItem>;

const daysPerWeekValidator = (value: string) => {
    return value === '' || (RegexIsPositiveNumberWith2Decimals(value) && +value <= 7);
};

export default function Settings({ setSummaryBar }: Props) {
    const [isLoading, setIsLoading] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [automatedRejectionOptions, setAutomatedRejectionOptions] = useState<JSX.Element[]>([automatedRejectionNone]);
    const { state, init, change, updateInitial, hasChanges } = useObjectStateWithChangeTracker<JobsConfigOptions>(DefaultOptions, NoChangesTracker);

    useEffect(() => {
        const getSettings = async () => {
            setIsLoading(true);
            const data = await GetJobsSettings(setErrorMessage);
            const options = await GetAutomatedRejectionsOptions(setErrorMessage);

            if(options) {
                let t = options.map(option => (<MenuItem key={option.id} value={option.id.toString()}>{option.name}</MenuItem>));
                t.unshift(automatedRejectionNone);
                setAutomatedRejectionOptions(t);
            }

            if (data) {
                const savedSettings = SettingsArrayToObj<JobsConfigOptions>(data, DefaultOptions);
                init(savedSettings);
            }
            setIsLoading(false);
        };
        getSettings();
    }, [init]);

    const saveSettings = useCallback(async() => {
        setIsLoading(true);
        const settings = ObjToSettingsArray(state);
        const res = await SaveJobsSettings(settings, setErrorMessage);
        
        if (!res) {
            setIsLoading(false);
            return false;
        }

        if (state.WorkflowSortOrder === '1') {
            await GetWorkflowItemsOrder(setErrorMessage);
        }

        
        updateInitial();
        setShowSuccess(true);
        setIsLoading(false);
        return true;
    }, [state, updateInitial]);

    const { unsavedChangesDialog } = useUnsavedChangesDialog(hasChanges, saveSettings);

    const handleChanges = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        change(name as keyof JobsConfigOptions, value);
    }, [change]);

    const numberChangeHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        change(name as keyof JobsConfigOptions, +value);
    }, [change]);

    const decimalChangeHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        if (value === '') change(name as keyof JobsConfigOptions, '');
        if (RegexIsPositiveNumberWith2Decimals(value)) change(name as keyof JobsConfigOptions, value);
    }, [change]);
    
    useEffect(() => {
        const saveButton = (
            <Button
                disabled={!hasChanges}
                variant="contained"
                color="primary"
                onClick={saveSettings}
                sx={{ borderRadius: '2px', padding: 0, height: '36px', width: '80px' }}
            >
                Save
            </Button>
        );
        
        const SummaryBar = (
            <TitleAndActionSummaryBar
                title="Configuration > Jobs > Settings"
                browserTabTitle="Jobs > Configuration"
                action={saveButton}
            />);

        setSummaryBar && setSummaryBar(SummaryBar);
    }, [hasChanges, saveSettings, setSummaryBar]);

    return (
        <>
            {unsavedChangesDialog}
            <Snackbar open={showSuccess} autoHideDuration={3000} onClose={() => setShowSuccess(false)}>
                <Alert onClose={() => setShowSuccess(false)}>Changes Saved</Alert>
            </Snackbar>
            <Snackbar open={errorMessage !== ''}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <PageContentLayout title="Job Settings" showLoading={isLoading} >
                <Stack spacing={3}>
                    <TextField
                        type="number"
                        label="Start Date Offset - Perm"
                        name="JobStartDateOffsetPerm"
                        value={state.JobStartDateOffsetPerm}
                        InputProps={{ endAdornment: BusinessDaysAdornment }}
                        onChange={handleChanges}
                    />
                    <TextField
                        type="number"
                        label="Start Date Offset - Contract"
                        name="JobStartDateOffsetContract"
                        value={state.JobStartDateOffsetContract}
                        InputProps={{ endAdornment: BusinessDaysAdornment }}
                        onChange={handleChanges}
                    />
                    <TextField
                        type="number"
                        label="Start Date Offset - FTC"
                        name="JobStartDateOffsetFTC"
                        value={state.JobStartDateOffsetFTC}
                        InputProps={{ endAdornment: BusinessDaysAdornment }}
                        onChange={handleChanges}
                    />
                    <TextField
                        select
                        label="Default Work Type"
                        name="JobDefaultWorkType"
                        value={state.JobDefaultWorkType}
                        onChange={handleChanges}
                    >
                        <MenuItem value="0">None</MenuItem>
                        <MenuItem value="1">Casual</MenuItem>
                        <MenuItem value="2">Full Time</MenuItem>
                        <MenuItem value="3">Part Time</MenuItem>
                    </TextField>
                    <TextField
                        select
                        label="Can put on Hold"
                        name="OnHold"
                        value={state.OnHold}
                        onChange={handleChanges}
                    >
                        <MenuItem value="True">Enabled</MenuItem>
                        <MenuItem value="False">Disabled</MenuItem>
                    </TextField>
                    <TextField
                        select
                        label="Can Reset Stage"
                        name="AllowResetStage"
                        value={state.AllowResetStage}
                        onChange={handleChanges}
                    >
                        <MenuItem value="True">Enabled</MenuItem>
                        <MenuItem value="False">Disabled</MenuItem>
                    </TextField>
                    <TextField
                        select
                        label="Structured Deadline Changes"
                        name="structureddeadlinechanges"
                        value={state.structureddeadlinechanges}
                        onChange={handleChanges}
                    >
                        <MenuItem value="True">Enabled</MenuItem>
                        <MenuItem value="False">Disabled</MenuItem>
                    </TextField>
                    <TextField
                        select
                        label="Exclusive By Default"
                        name="ExclusiveJobsByDefault"
                        value={state.ExclusiveJobsByDefault}
                        onChange={handleChanges}
                    >
                        <MenuItem value="True">Enabled</MenuItem>
                        <MenuItem value="False">Disabled</MenuItem>
                    </TextField>
                    <TextField
                        select
                        label="Rejection Emails"
                        name="RejectionEmails"
                        value={state.RejectionEmails}
                        onChange={handleChanges}
                    >
                        <MenuItem value="True">Enabled</MenuItem>
                        <MenuItem value="False">Disabled</MenuItem>
                    </TextField>
                    <TextField
                        select
                        label="Mandatory Rejection Emails"
                        name="MandatoryRejectionEmails"
                        value={state.MandatoryRejectionEmails}
                        onChange={handleChanges}
                    >
                        <MenuItem value="True">Enabled</MenuItem>
                        <MenuItem value="False">Disabled</MenuItem>
                    </TextField>
                    <TextField
                        select
                        label="Automated Rejections - Filled by Us"
                        name="AutomatedRejectionsFilledByUs"
                        value={state.AutomatedRejectionsFilledByUs}
                        onChange={handleChanges}
                    >
                        {automatedRejectionOptions}
                    </TextField>
                    <TextField
                        select
                        label="Automated Rejections - Filled by Client"
                        name="AutomatedRejectionsFilledByClient"
                        value={state.AutomatedRejectionsFilledByClient}
                        onChange={handleChanges}
                    >
                        {automatedRejectionOptions}
                    </TextField>
                    <TextField
                        select
                        label="Automated Rejections - Filled by Competitor"
                        name="AutomatedRejectionsFilledByCompetitor"
                        value={state.AutomatedRejectionsFilledByCompetitor}
                        onChange={handleChanges}
                    >
                        {automatedRejectionOptions}
                    </TextField>
                    <TextField
                        select
                        label="Automated Rejections - Withdrawn"
                        name="AutomatedRejectionsWithdrawn"
                        value={state.AutomatedRejectionsWithdrawn}
                        onChange={handleChanges}
                    >
                        {automatedRejectionOptions}
                    </TextField>
                    <TextField
                        select
                        label="Automated Rejections - Administrative"
                        name="AutomatedRejectionsAdministrative"
                        value={state.AutomatedRejectionsAdministrative}
                        onChange={handleChanges}
                    >
                        {automatedRejectionOptions}
                    </TextField>
                    <TextField
                        select
                        label="Automated Rejections - Unknown"
                        name="AutomatedRejectionsUnknown"
                        value={state.AutomatedRejectionsUnknown}
                        onChange={handleChanges}
                    >
                        {automatedRejectionOptions}
                    </TextField>
                    <TextField
                        select
                        label="Automated Rejections - Not Supplied"
                        name="AutomatedRejectionsNotSupplied"
                        value={state.AutomatedRejectionsNotSupplied}
                        onChange={handleChanges}
                    >
                        {automatedRejectionOptions}
                    </TextField>
                    <TextField
                        select
                        label="Automated Rejections - Client did not win work"
                        name="AutomatedRejectionsClientDidNotWinWork"
                        value={state.AutomatedRejectionsClientDidNotWinWork}
                        onChange={handleChanges}
                    >
                        {automatedRejectionOptions}
                    </TextField>
                    <TextField
                        select
                        label="Automated Rejections - Budget not Approved"
                        name="AutomatedRejectionsBudgetNotApproved"
                        value={state.AutomatedRejectionsBudgetNotApproved}
                        onChange={handleChanges}
                    >
                        {automatedRejectionOptions}
                    </TextField>
                    <TextField
                        select
                        label="Automated Rejections - Red Flagged Candidates"
                        name="AutomatedRejectionsRedFlaggedCandidates"
                        value={state.AutomatedRejectionsRedFlaggedCandidates}
                        onChange={handleChanges}
                    >
                        {automatedRejectionOptions}
                    </TextField>
                    <TextField
                        select
                        label="Automated Rejections - Red Flagged Candidates Sending Delay"
                        name="automatedRejectionDelay"
                        value={state.AutomatedRejectionsRedFlaggedCandidatesDelayInMinutes.toString()}
                        onChange={numberChangeHandler}
                    >
                        <MenuItem value="60">1 Hour</MenuItem>
                        <MenuItem value="120">2 Hours</MenuItem>
                        <MenuItem value="240">4 Hours</MenuItem>
                        <MenuItem value="480">8 Hours</MenuItem>
                        <MenuItem value="720">12 Hours</MenuItem>
                        <MenuItem value="1440">24 Hours</MenuItem>
                        <MenuItem value="2880">2 Days</MenuItem>
                        <MenuItem value="4320">3 Days</MenuItem>
                        <MenuItem value="10080">1 Week</MenuItem>
                    </TextField>
                    <TextField
                        select
                        label="Workflow Sort Order"
                        name="WorkflowSortOrder"
                        value={state.WorkflowSortOrder ? state.WorkflowSortOrder : '0'}
                        onChange={handleChanges}
                    >
                        <MenuItem value="0">Alphabetical (Default)</MenuItem>
                        <MenuItem value="1">Workflow Order</MenuItem>
                    </TextField>
                    <TextField
                        label="Default Hours Per Day"
                        name="JobDefaultHoursPerDay"
                        value={state.JobDefaultHoursPerDay}
                        onChange={decimalChangeHandler}
                    />
                    <RWTextFieldComponent
                        label="Default Days Per Week"
                        name="JobDefaultDaysPerWeek"
                        value={state.JobDefaultDaysPerWeek}
                        onChange={handleChanges}
                        validator={daysPerWeekValidator}
                    />
                    <Box>
                        <InputLabel shrink sx={{ pl: 2 }}>Default Summary</InputLabel>
                        <TextAreaWithFormat
                            height={300}
                            initialValue={state.JobDefaultSummary}
                            onContentChange={content => change('JobDefaultSummary', content)}
                        />
                    </Box>
                </Stack>
            </PageContentLayout>
        </>
    );
}