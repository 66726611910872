import { ActivitySettings } from '../common/models/Configuration/Activities';
import { ErrorHandler, NameIdObj, NumberValueWrapper, RequestOptions, SettingObject, StringValueWrapper } from '../common/models/GenericTypes';
import { Get, Post, Put } from '../util/HttpUtils';
import { RatingReason } from '../common/models/Configuration/Jobs';
import { DocumentTemplateCustomField } from '../common/models/Configuration/CustomFields/DocumentTemplates';
import { PlacementSource } from '../common/models/Configuration/Placements';
import { ExclusionValue } from '../common/models/Configuration/EmailIntegration';
import { RecentlyDeletedRecord, RecoverDeletedRecordData } from '../common/models/Configuration/RecentlyDeletedRecord';
import { SendEmailSettings, SendSmsSettings } from '../common/models/Configuration/Messages';
import { SmsPackage, SmsPurchase } from '../common/models/Configuration/SMS';
import { Classification, UpdateClassificationData } from '../common/models/JobPosting/Classifications';
import { AdvertLocation, UpdateAdvertLocationData } from '../common/models/JobPosting/Locations';
import { JobCandidateKanbanGroup, JobCandidateStatusRequest, JobCandidateStatusResponse, JobStage } from '../common/models/Jobs/Job';

export async function GetCustomerSettingBySettingName(settingName: string, errorHandler?: ErrorHandler) {
    const cachedValue = localStorage.getItem(settingName);
    if (cachedValue) {
        return cachedValue;
    }

    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    const res = await Get<StringValueWrapper>(`api/configuration/settings?setting=${encodeURIComponent(settingName)}`, options);
    if (res && res.value) {
        localStorage.setItem(settingName, res.value);
        return res.value;
    }
    return null;
}

export async function GetCustomerSettingBySettingNameIgnoreCache(settingName: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    const res = await Get<StringValueWrapper>(`api/configuration/settings?setting=${encodeURIComponent(settingName)}`, options);
    if (res && res.value) {
        localStorage.setItem(settingName, res.value);
        return res.value;
    }
    return null;
}

export async function UpdateCustomerSettingBySettingName(settingName: string, value: string, errorHandler?: ErrorHandler) {
    const options:RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    }

    const res = await Put<boolean>(`api/configuration/settings?setting=${encodeURIComponent(settingName)}`, options, { value });
    if (res) localStorage.setItem(settingName, value);
    return res;
}

export async function GetActivitiesSettings(entityIdFilter?: number, categoryIdFilter?: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    }
    let url = 'api/configuration/activities?';
    if (entityIdFilter) url += `entityIdfilter=${entityIdFilter}&`;
    if (categoryIdFilter !== undefined) url += `categoryIdfilter=${categoryIdFilter}`;

    return Get<ActivitySettings[]>(url, options);
}

export async function UpdateActivitySettings(settings: ActivitySettings, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        noDataReturned: true,
        errorHandler
    }

    return Put<boolean>(`api/configuration/activities/${settings.id}`, options, settings);
}

export async function GetAnalyticsSettings(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<SettingObject[]>('api/configuration/analytics', options);
}

export async function SaveAnalyticsSettings(settings: SettingObject[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler,
    };

    return Post<boolean>('api/configuration/analytics', options, settings);
}

export async function GetDashboardSettings(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<SettingObject[]>('api/configuration/dashboard', options);
}

export async function GetNotificationsSettings(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<SettingObject[]>('api/configuration/notifications', options);
}

export async function SaveNotificationsSettings(settings: SettingObject[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler,
    };

    return Post<boolean>('api/configuration/notifications', options, settings);
}

export async function GetAuthenticationSettings(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<SettingObject[]>('api/configuration/authentication', options);
}

export async function SaveAuthenticationSettings(settings: SettingObject[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler,
    };

    return Post<boolean>('api/configuration/authentication', options, settings);
}

export async function GetAdvertsSettings(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<SettingObject[]>('api/configuration/jobposting', options);
}

export async function SaveAdvertsSettings(settings: SettingObject[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler,
    };

    return Post<boolean>('api/configuration/jobposting', options, settings);
}

export async function GetAdvertsClassifications(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Classification[]>('api/configuration/jobposting/classifications', options);
}

export async function UpdateAdvertsClassifications(data: UpdateClassificationData, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>('api/configuration/jobposting/classifications', options, data);
}

export async function GetAdvertsLocations(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<AdvertLocation[]>('api/configuration/jobposting/locations', options);
}

export async function UpdateAdvertsLocations(data: UpdateAdvertLocationData, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>('api/configuration/jobposting/locations', options, data);
}

export async function GetGeneralSettings(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<SettingObject[]>('api/configuration/general', options);
}

export async function SaveGeneralSettings(settings: SettingObject[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler,
    };

    return Post<boolean>('api/configuration/general', options, settings);
}

export async function GetEmailIntegrationSettings(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<SettingObject[]>('api/configuration/email-integration', options);
}

export async function SaveEmailIntegrationSettings(settings: SettingObject[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler,
    };

    return Post<boolean>('api/configuration/email-integration', options, settings);
}

export async function GetPlacementsSettings(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<SettingObject[]>('api/configuration/placements', options);
}

export async function SavePlacementsSettings(settings: SettingObject[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<SettingObject[]>('api/configuration/placements', options, settings);
}

export async function GetJobsSettings(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<SettingObject[]>('api/configuration/jobs', options);
}

export async function SaveJobsSettings(settings: SettingObject[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<SettingObject[]>('api/configuration/jobs', options, settings);
}

export async function GetClientsSettings(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<SettingObject[]>('api/configuration/Clients', options);
}

export async function SaveClientsSettings(settings: SettingObject[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<SettingObject[]>('api/configuration/Clients', options, settings);
}


export async function GetContactsSettings(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<SettingObject[]>('api/configuration/Contacts', options);
}

export async function SaveContactsSettings(settings: SettingObject[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<SettingObject[]>('api/configuration/Contacts', options, settings);
}

export async function GetCandidatesSettings(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<SettingObject[]>('api/configuration/Candidates', options);
}

export async function SaveCandidatesSettings(settings: SettingObject[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<SettingObject[]>('api/configuration/Candidates', options, settings);
}

export async function GetOpportunitiesSettings(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<SettingObject[]>('api/configuration/Opportunities', options);
}

export async function SaveOpportunitiesSettings(settings: SettingObject[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<SettingObject[]>('api/configuration/Opportunities', options, settings);
}

export async function GetSitesSettings(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<SettingObject[]>('api/configuration/Sites', options);
}

export async function SaveSitesSettings(settings: SettingObject[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<SettingObject[]>('api/configuration/Sites', options, settings);
}

export async function GetAutomatedRejectionsOptions(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    }

    return Get<NameIdObj[]>('api/configuration/automatedrejectionoptions', options);
}

export async function GetRatingReasons(actionType: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<RatingReason[]>(`api/configuration/ratingreasons/${actionType}`, options);
}

export async function GetCachedRatingReasons(updateCache: boolean, errorHandler?: ErrorHandler) {
    const localSettingName = 'RatingReasons';
    if (!updateCache) {
        const cachedValue = localStorage.getItem(localSettingName);
    
        if (cachedValue) {
            const values = JSON.parse(cachedValue);
            return values as RatingReason[];
        }
    }

    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    const res = await Get<RatingReason[]>(`api/configuration/ratingreasons/all`, options);
    if (res) {
        localStorage.setItem(localSettingName, JSON.stringify(res));
        return res;
    }
    return null;
}

export async function SaveRatingReasons(actionType: number, reasons: RatingReason[], deleteIds: number[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/configuration/ratingreasons/${actionType}`, options, { reasons, deleteIds });
}

export async function GetJobSources(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<string[]>('api/jobs/sources', options);
}

export async function SaveJobSources(sources: string[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>('api/jobs/sources', options, sources);
}

export async function GetPlacementsSources(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<PlacementSource[]>('api/placements/sources', options);
}

export async function SavePlacementsSources(sources: PlacementSource[], deleteIds: number[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>('api/placements/sources', options, { sources, deleteIds });
}

export async function GetDocTemplateCustomFields(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<DocumentTemplateCustomField[]>('api/DocumentTemplates/custom-fields', options);
}

export async function SaveDocTemplateCustomfields(fields: DocumentTemplateCustomField[], deleteIds: number[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>('api/DocumentTemplates/custom-fields', options, { fields, deleteIds });
}

export async function GetExclusionList(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<ExclusionValue[]>('api/emailintegration/exclusionlist', options);
}

export async function UpdateExclusionList(exclusions: ExclusionValue[], deleteIds: number[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>('api/emailintegration/exclusionlist', options, { exclusions, deleteIds })
}

export async function GetRecentlyDeletedRecords(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<RecentlyDeletedRecord[]>('api/datamaintenance/recentlydeletedrecords', options);
}

export async function RecoverRecentlyDeletedRecords(data: RecoverDeletedRecordData[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    }

    return Post<boolean>('api/datamaintenance/recentlydeletedrecords/recover', options, data);
}

export async function GetSubmissionsSettings(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<SettingObject[]>('api/configuration/submissions', options);
}

export async function UpdateSubmissionsSettings(settings: SettingObject[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>('api/configuration/submissions', options, settings);
}

export async function GetSendEmailSettings(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<SendEmailSettings>('api/configuration/sendemail', options);
}

export async function GetSendSmsSettings(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<SendSmsSettings>('api/configuration/sendsms', options);
}

export async function GetSmsSettings(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<SettingObject[]>('api/configuration/sms', options);
}

export async function SaveSmsSettings(settings: SettingObject[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>('api/configuration/sms', options, settings);
}

export async function GetSmsPurchases(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<SmsPurchase[]>('api/configuration/sms/purchases', options);
}

export async function AddPurchase(packageId: number, notes: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<NumberValueWrapper>(`api/configuration/sms/purchases/${packageId}`, options, { value: notes });
}

export async function GetSmsPackages(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<SmsPackage[]>('api/configuration/sms/packages', options);
}

export async function GetInvoiceSettings(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<SettingObject[]>('api/configuration/invoices', options);
}

export async function SaveInvoiceSettings(settings: SettingObject[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler,
    };

    return Post<boolean>('api/configuration/invoices', options, settings);
}

export async function UpdateJobStagesOrder(stageIds: number[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/configuration/UpdateJobStagesOrder`, options, stageIds);
}

export async function AddJobStage(jobStage: JobStage, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<number>('api/configuration/AddJobStage', options, jobStage);
}

export async function UpdateJobStage(jobStage: JobStage, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Put<number>(`api/configuration/UpdateJobStage`, options, jobStage);
}

export async function GetKanbanGroups(enabledOnly: boolean,errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<JobCandidateKanbanGroup[]>(`api/configuration/KanbanGroups?enabledOnly=${enabledOnly}`, options);
}


export async function UpdateKanbanGroupOrder(stageIds: number[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/configuration/UpdateKanbanGroupsOrder`, options, stageIds);
}

export async function AddKanbanGroup(jobStage: JobCandidateKanbanGroup, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<number>('api/configuration/AddKanbanGroup', options, jobStage);
}

export async function UpdateKanbanGroup(jobStage: JobCandidateKanbanGroup, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Put<number>(`api/configuration/UpdateKanbanGroup`, options, jobStage);
}

export async function GetJobCandidateStatuses(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<JobCandidateStatusResponse[]>('api/configuration/JobCandidateStatuses', options);
}

export async function GetJobStagesLookup(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<NameIdObj[]>('api/configuration/JobStagesLookup', options);
}

export async function GetKanbanGroupsLookup(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<NameIdObj[]>('api/configuration/KanbanGroupsLookup', options);
}

export async function UpdateJobCandidateStatusOrder(statusIds: number[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/configuration/UpdateJobCandidateStatusOrder`, options, statusIds);
}

export async function AddJobCandidateStatus(jobCandidateStatus: JobCandidateStatusRequest, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<number>('api/configuration/AddJobCandidateStatus', options, jobCandidateStatus);
}

export async function UpdateJobCandidateStatus(jobCandidateStatus: JobCandidateStatusRequest, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Put<number>(`api/configuration/UpdateJobCandidateStatus`, options, jobCandidateStatus);
}

export async function GetJobCandidateStatusesForLookup(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<NameIdObj[]>('api/configuration/JobCandidateStatusesForLookup', options);
}

export async function GetJobStages(enabledOnly: boolean,errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<JobStage[]>(`api/configuration/Stages?enabledOnly=${enabledOnly}`, options);
}

export async function GetInterviewWorkflowStatuses(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<number[]>('api/configuration/GetInterviewWorkflowStatuses', options);
}

export async function GetOfferedWorkflowStatus(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<number>('api/configuration/GetOfferedWorkflowStatus', options);
}

export async function GetOfferAcceptedWorkflowStatus(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<number>('api/configuration/GetOfferAcceptedWorkflowStatus', options);
}


