import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { createRoutesFromElements, Outlet, Route, RouterProvider, useNavigate } from 'react-router';
import { createBrowserRouter, useLocation } from 'react-router-dom';

import CssBaseline from '@mui/material/CssBaseline';
import ThemeProvider from '@mui/material/styles/ThemeProvider';

import { LightTheme, DarkTheme } from "./themes/MainTheme";
import SecureRouteWrapper from './components/SecureRouteWrapper';
import CustomApplicationContext from './util/CustomApplicationContext';
import Login from './pages/Login/Login';
import ExternalLogin from './pages/Login/ExternalLogin';
import ImpersonateLogin from './pages/Login/ImpersonateLogin';
import Home from './pages/Home';
import './App.css'
import { LicenseInfo } from '@mui/x-data-grid-premium';
import { ExtendedUser } from './common/models/Configuration/User';
import { GetMyUser } from './services/UsersService';
import rg4js from 'raygun4js';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';
import EmailReceiverPage from 'pages/Anonymous/EmailReceiver';
import moment from 'moment';

export default function App() {
    const storedTheme = localStorage.getItem('Theme');
    const [theme, setTheme] = useState<string | null>(storedTheme);
    const [currentUser, setCurrentUser] = useState<ExtendedUser | null>(null);

    const currentLocaleString = useMemo(() => navigator.language, []);

    useEffect(() => {
        moment.updateLocale(currentLocaleString, {
            week: {
                dow: 1
            }
        });
    }, [currentLocaleString]);

    const handleUserRefresh = useCallback(async () => {
        const res = await GetMyUser();
        if (res) {
            setCurrentUser(res);

            if (process.env.NODE_ENV !== 'development') {
                rg4js('setUser', {
                    identifier: res.userID.toString(),
                    isAnonymous: false,
                    email: res.userName,
                    firstName: res.firstName,
                    fullName: res.displayName
                });

                if (res.userName.indexOf("recruitwizard.com") === -1) {
                    window.pendo.initialize({
                        visitor: {
                            id: res.userName,
                            email: res.userName,
                            full_name: res.displayName,
                            created_date: res.createdDateUnix
                        },

                        account: {
                            id: res.customerId,
                            name: res.customerName
                        }
                    });
                }
            }
        }

        setTheme(localStorage.getItem('Theme'));
    }, []);

    const getTheme = () => {
        switch (theme) {
            case 'light':
                return LightTheme;
            case "dark":
                return DarkTheme;
            default:
                return LightTheme;
        }
    };

    const router = useMemo(() => {
        return createBrowserRouter(
            createRoutesFromElements(
                <Route path="/" element={<Layout />}>
                    <Route path='/login' element={<Login />} />
                    <Route path='/external/:service' element={<ExternalLogin refreshCurrentUserHandler={handleUserRefresh} />} />
                    <Route path='/impersonate/' element={<ImpersonateLogin refreshCurrentUserHandler={handleUserRefresh} />} />
                    <Route path='/email-receiver/:key' element={<EmailReceiverPage />} />
                    <Route path='/*' element={<SecureRouteWrapper><Home /></SecureRouteWrapper>} />
                </Route>
            )
        );
    }, [handleUserRefresh]);

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <CustomApplicationContext.Provider value={{ currentUser: currentUser, refreshCurrentUser: handleUserRefresh }}>
                <ThemeProvider theme={getTheme()}>
                    <CssBaseline />
                    <RouterProvider router={router} />
                </ThemeProvider>
            </CustomApplicationContext.Provider>
        </LocalizationProvider>
    );
}


function Layout() {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (location.pathname === '/') navigate('/dashboard');
    }, [location.pathname, navigate]);

    useEffect(() => {
        if (process.env.NODE_ENV !== 'development') {
            rg4js('trackEvent', { type: 'pageView', path: location.pathname });
        }
    }, [location]);

    LicenseInfo.setLicenseKey(
        '985846aeafb4001bbd7989adc7c7b89eTz04MzY2NSxFPTE3Mzg3OTgzMDgwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y'
    );

    return <Outlet />
}
