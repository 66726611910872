import Backdrop from "@mui/material/Backdrop";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import RWTextFieldComponent from "components/RWTextFieldComponent";
import moment from "moment";
import React, { useCallback, useMemo, useState } from "react";
import { CloseJobs } from "services/JobsService";

interface Props {
    isOpen: boolean,
    jobIds: number[],
    closeHandler: () => void,
    successHandler?: () => void,
    errorHandler?: (message: string) => void
}

export default function CloseJobsDialog({ isOpen, jobIds, closeHandler, successHandler, errorHandler }: Props) {
    const [isLoading, setIsLoading] = useState(false);
    const [reason, setReason] = useState(0);
    const [comments, setComments] = useState('');

    const submitCallback = useCallback(async () => {
        setIsLoading(true);
        const res = await CloseJobs(jobIds, reason, comments, errorHandler);
        setIsLoading(false);
        if (res) {
            setReason(0);
            setComments('');
            successHandler && successHandler();
            closeHandler();
        }
    }, [jobIds, reason, comments, errorHandler, successHandler, closeHandler]);

    const jobsToClose = useMemo(() => {
        return jobIds.length === 1 ? '1 Job' : `${jobIds.length} Jobs`;
    }, [jobIds.length]);

    const dateTimeString = useMemo(() => {
        if (isOpen) return moment().format('DD MMM YYYY');
        return '';
    }, [isOpen]);

    return (
        <>
            <Backdrop open={isLoading} sx={{ zIndex: (t) => t.zIndex.modal + 1 }} >
                <CircularProgress sx={{ color: 'text.disabled' }} />
            </Backdrop>
            <Dialog open={isOpen} fullWidth>
                <DialogTitle>Close Jobs</DialogTitle>
                <DialogContent dividers>
                    <Stack spacing={2}>
                        <TextField
                            value={jobsToClose}
                            InputProps={{ readOnly: true }}
                            label="Jobs To Close"
                        />
                        <TextField
                            value={dateTimeString}
                            InputProps={{ readOnly: true }}
                            label="Date"
                        />
                        <TextField
                            select
                            value={reason}
                            label="Reason"
                            onChange={ ({target}) => setReason(+target.value) }
                        >
                            <MenuItem value="0">None</MenuItem>
                            <MenuItem value="5">Administrative</MenuItem>
                            <MenuItem value="9">Budget not approved</MenuItem>
                            <MenuItem value="8">Client did not win work</MenuItem>
                            <MenuItem value="1">Filled by Us</MenuItem>
                            <MenuItem value="2">Filled by Client</MenuItem>
                            <MenuItem value="3">Filled by Competitor</MenuItem>
                            <MenuItem value="11">Over Capacity</MenuItem>
                            <MenuItem value="7">Not Supplied</MenuItem>
                            <MenuItem value="10">Not worked Properly</MenuItem>
                            <MenuItem value="6">Unknown</MenuItem>
                            <MenuItem value="4">Withdrawn</MenuItem>
                        </TextField>
                        <RWTextFieldComponent
                            value={comments}
                            label="Comments"
                            onChange={({target}) => setComments(target.value) }
                            multiline
                            minRows={6}
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="error" onClick={ closeHandler }>Cancel</Button>
                    <Button variant="contained" color="success" disabled={ reason === 0 } onClick={ submitCallback } >Submit</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
