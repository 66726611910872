import Avatar from "@mui/material/Avatar";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import React, { useMemo } from "react";
import { ExtendedUser } from "common/models/Configuration/User";

interface Props {
    user: ExtendedUser | null,
}

export default function UserAvatarComponent({ user }: Props) {
    const displayName = useMemo(() => user ? user.displayName : 'User', [user]);
    const jobTitle = useMemo(() => user ? user.jobTitle : '', [user]);

    return (
        <Paper sx={{ display: 'flex', height: '100%' }} >
            <ListItem>
                <ListItemAvatar>
                    <Avatar alt={displayName} sx={{ width: '80px', height: '80px' }} />
                </ListItemAvatar>
                <ListItemText primary={displayName} secondary={jobTitle} sx={{ ml: 2 }} />
            </ListItem>
        </Paper>
    );
}