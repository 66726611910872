import { ErrorHandler, RequestOptions } from '../common/models/GenericTypes';
import { ServerStatus, Country, Currency, TimeZone, WorkflowStatus, Location } from '../common/models/Common';
import { Referral } from "../common/models/Activities";
import { Post, Get } from '../util/HttpUtils';

export async function GetCountries(errorHandler?: ErrorHandler) {
    const storedCountries = localStorage.getItem('common_countries');
    if (storedCountries) {
        return JSON.parse(storedCountries) as Country[];
    }

    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    const fetchedCountries = await Get<Country[]>('api/Common/Countries', options);
    if (fetchedCountries) {
        localStorage.setItem('common_countries', JSON.stringify(fetchedCountries));
    }
    return fetchedCountries;
}

export async function GetCurrencyById(currencyId: number, errorHandler?: ErrorHandler) {
    const storedCurrencies = localStorage.getItem('common_currencies');
    if (storedCurrencies) {
        const currencies = JSON.parse(storedCurrencies) as Currency[];
        const item = currencies.find(c => c.id === currencyId);
        if (item) return item;
        return null;
    }

    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Currency>(`api/Common/Currencies/${currencyId}/ById`, options);
}

export async function GetCurrencyByCode(currencyCode: string, errorHandler?: ErrorHandler) {
    const storedCurrencies = localStorage.getItem('common_currencies');
    if (storedCurrencies) {
        const currencies = JSON.parse(storedCurrencies) as Currency[];
        const item = currencies.find(c => c.code === currencyCode);
        if (item) return item;
        return null;
    }

    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Currency>(`api/Common/Currencies/${currencyCode}/ByCode`, options);
}

export async function GetCurrencies(errorHandler?: ErrorHandler) {
    const storedCurrencies = localStorage.getItem('common_currencies');
    if (storedCurrencies) {
        return JSON.parse(storedCurrencies) as Currency[];
    }

    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    const fetchedCurrencies = await Get<Currency[]>('api/Common/Currencies', options);
    if (fetchedCurrencies) {
        localStorage.setItem('common_currencies', JSON.stringify(fetchedCurrencies));
    }
    return fetchedCurrencies;
}

export async function GetWorkflowStatuses(errorHandler?: ErrorHandler) {
    const storedStatuses = localStorage.getItem('workflow_statuses');
    if (storedStatuses) {
        return JSON.parse(storedStatuses) as WorkflowStatus[];
    }

    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    const fetchedStatuses = await Get<WorkflowStatus[]>('api/Common/WorkflowStatuses', options);
    if (fetchedStatuses) {
        localStorage.setItem('workflow_statuses', JSON.stringify(fetchedStatuses));
    }
    return fetchedStatuses;
}

export async function GetTimeZone(name: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return await Get<TimeZone>(`api/Common/Timezones?name=${encodeURIComponent(name)}`, options);
}

export async function TimeZoneLookup(term: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return await Get<TimeZone[]>(`api/Common/Timezones/Lookup?query=${encodeURIComponent(term)}`, options);
}

export async function GetServerStatus(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return await Get<ServerStatus>('api/serverstatus', options);
}

export async function GetLocationLookup(countryCode: string, term: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return await Get<Location[]>(`api/Common/Countries/${countryCode}/Locations/Lookup?query=${encodeURIComponent(term)}`, options);
}

export async function GetLocationById(id: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return await Get<Location>(`api/Common/Locations/${id}`, options);
}

export async function CreateReferral(referral: Referral, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        noDataReturned: true,
        errorHandler
    };

    return Post<boolean>('api/common/referrals', options, referral);
}

export async function CreateMultipleReferrals(referral: Referral, referTo: number[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        noDataReturned: true,
        errorHandler
    };

    return Post<boolean>('api/common/referrals/bulk', options, { referral, referTo });
}