import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import AddIcon from '@mui/icons-material/Add';
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, Navigate, useParams } from "react-router-dom";
import { AllowTemplates } from "common/data/Permissions/UserMenuAccess";
import { ChangeTracker } from "common/models/hooks/ChangeTracker";
import { ScreeningQuestionsTemplate, ScreeningQuestionsTemplateField } from "common/models/Templates/ScreeningQuestionsTemplate";
import UserPicker from "components/Pickers/UserPicker";
import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import useObjectStateWithChangeTracker from "hooks/UseObjectStateWithChangeTracker";
import PageContentLayout from "layouts/PageContentLayout";
import PageLayout from "layouts/PageLayout";
import { CreateScreeningQuestionnaireTemplate, GetScreeningQuestionnaireTemplateById, UpdateScreeningQuestionnaireTemplate } from "services/TemplatesService";
import ScreeningQuestionsField from "components/Templates/ScreeningQuestionsField";
import Alert from "components/Alert";
import Snackbar from "@mui/material/Snackbar";
import useUnsavedChangesDialog from "hooks/UseUnsavedChangesDialog";
import { NameIdObj } from "common/models/GenericTypes";
import DocumentTemplatePicker from "components/Pickers/DocumentTemplatePicker";
import RWTextFieldComponent from "components/RWTextFieldComponent";

const ScreeningQuestionsLink = <Link to="/account/templates/screening-questions" style={{ color: 'inherit', textDecoration: 'underline' }}>Screening Questions</Link>;

const defaultScreeningQuestionsTemplateItem: ScreeningQuestionsTemplate = {
    id: 0,
    ownerID: 0,
    owner: '',
    name: '',
    statusID: 1,
    status: '',
    json: '[]',
    documentTemplateId: 0
};

const defaultScreeningQuestionField: ScreeningQuestionsTemplateField = {
    order: 0,
    name: '',
    type: '0',
    mandatory: false,
    documentTemplate: '' 
};

const noChangesDefault: ChangeTracker<ScreeningQuestionsTemplate> = {
    id: false,
    ownerID: false,
    owner: false,
    name: false,
    statusID: false,
    status: false,
    json: false,
    documentTemplateId: false
};

const companyOwnedUserPickerOption: NameIdObj[] = [{ id: 0, name: 'Company Owned' }];

export default function ScreeningQuestionnaireItemPage() {
    const [summaryBar, setSummaryBar] = useState<JSX.Element>(<></>);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showSuccess, setShowSuccess] = useState(false);
    const { state, init, change, updateInitial, hasChanges } = useObjectStateWithChangeTracker<ScreeningQuestionsTemplate>(defaultScreeningQuestionsTemplateItem, noChangesDefault);
    const [fields, setFields] = useState<ScreeningQuestionsTemplateField[]>([]);
    const templateTypeFilters = [11];
    const [selectedTemplateId, setSelectedTemplateId] = useState(0);

    const params = useParams();
    const itemId = useMemo(() => {
        const parsedId = +(params.id ?? '0');
        if(isNaN(parsedId)) return 0;
        return parsedId
    }, [params.id]);

    const isTemplateAdmin = useMemo(() => AllowTemplates(), []);

    const saveHandler = useCallback(async () => {
        const trimmedName = state.name.trim();
        if (trimmedName === '') {
            setErrorMessage("The name field can't be empty");
            return false;
        }

        if (fields.length === 0) {
            setErrorMessage("Screening questions must have at least one question");
            return false;
        }

        for (let i = 0; i < fields.length; i++) {
            const f = fields[i];
            if (f.name.trim() === '') {
                setErrorMessage(`Field #${i + 1} 'Question' is empty`);
                return false;
            }
        }

        setIsLoading(true);
        let res: boolean | null = null;
        if (itemId !== 0) {
            res = await UpdateScreeningQuestionnaireTemplate(itemId, state, setErrorMessage);
        }
        else {
            res = await CreateScreeningQuestionnaireTemplate(state, setErrorMessage);
        }

        if (!res) {
            setIsLoading(false);
            return false;
        }
        updateInitial();
        setIsLoading(false);
        setShowSuccess(true);
        return true;
    }, [itemId, state, fields, updateInitial]);

    useEffect(() => {
        const action = <Button variant="contained" color="success" disabled={!hasChanges} onClick={saveHandler}>Save</Button>;

        const sb = (
            <TitleAndActionSummaryBar
                title={<>{'Account > Templates > '}{ScreeningQuestionsLink}</>}
                browserTabTitle="Screening Questions > Templates"
                action={action}
            />
        );
        setSummaryBar(sb);
    }, [hasChanges, saveHandler]);

    useEffect(() => {
        const getData = async () => {
            setIsLoading(true);
            let data = await GetScreeningQuestionnaireTemplateById(itemId, setErrorMessage);
            if (data) {
                try {
                    const parsedFields = JSON.parse(data.json) as ScreeningQuestionsTemplateField[];
                    setFields(parsedFields);                    
                } catch (error) {
                    data.json = '[]';
                }
                init(data);
                setSelectedTemplateId(data.documentTemplateId);
            }
            setIsLoading(false);
        };
        itemId !== 0 && getData();
    }, [itemId, init,setSelectedTemplateId]);

    useEffect(() => {
        change('json', JSON.stringify(fields));
    }, [fields, change]);

    useEffect(() => {
        change('documentTemplateId',selectedTemplateId);
    }, [selectedTemplateId, change]);

    const addFieldCallback = useCallback(() => {
        setFields(prev => {
            let tmp = [...prev];
            let newId = 0;

            for (let i = 0; i < prev.length + 1; i++) {
                const index = prev.findIndex(f => f.order === i);
                if (index === -1) {
                    newId = i;
                    break;
                }
            }
            tmp.push({...defaultScreeningQuestionField, order: newId});
            return tmp;
        });
    }, []);

    const fieldChangeCallback = useCallback((index: number, field: ScreeningQuestionsTemplateField) => {
        setFields(prev => {
            let tmp = [...prev];
            tmp[index] = field;
            return tmp;
        });
    }, []);

    const fieldDeleteCallback = useCallback((index: number) => {
        setFields(prev => {
            let tmp = [...prev];
            tmp.splice(index, 1);
            return tmp;
        });
    }, []);

    const fieldMoveUpCallback = useCallback((index: number) => {
        if(index === 0) return;
        setFields(prev => {
            let tmp = [...prev];
            const f = prev[index];
            tmp.splice(index, 1);
            tmp.splice(index -1, 0, f);
            return tmp;
        });
    }, []);

    const fieldMoveDownCallback = useCallback((index: number, isLast: boolean) => {
        if(isLast) return;
        setFields(prev => {
            let tmp = [...prev];
            const f = prev[index];
            tmp.splice(index, 1);
            tmp.splice(index +1, 0, f);
            return tmp;
        });
    }, []);

    const updateTemplateId = useCallback((id: number) => {
        setSelectedTemplateId(id);
    }, []);

    const { unsavedChangesDialog, hasBlockedRoute } = useUnsavedChangesDialog(hasChanges, saveHandler);
    
    return (
        <PageLayout SummaryBar={summaryBar}>
            {unsavedChangesDialog}
            { !hasBlockedRoute && showSuccess && itemId === 0 && <Navigate to="/account/templates/screening-questions" /> }
            {showSuccess &&
                <Snackbar open={showSuccess} autoHideDuration={3000} onClose={() => setShowSuccess(false)}>
                    <Alert onClose={() => setShowSuccess(false)}>Changes Saved</Alert>
                </Snackbar>
            }
            <Snackbar open={errorMessage !== ''} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <PageContentLayout title="Screening Questionnaire Template" showLoading={isLoading}>
                <Stack spacing={2}>
                    <RWTextFieldComponent
                        label="Name"
                        value={state.name}
                        onChange={ ({target}) => change('name', target.value) }
                    />
                    {isTemplateAdmin &&
                        <UserPicker
                            onSelect={ u => u && change('ownerID', u.id) }
                            userId={state.ownerID}
                            label="Template Owner"
                            appendToStart={companyOwnedUserPickerOption}
                        />
                    }
                    <TextField
                        select
                        label="Status"
                        value={state.statusID.toString()}
                        onChange={ ({target}) => change('statusID', +target.value) }
                    >
                        <MenuItem value="1">Active</MenuItem>
                        <MenuItem value="2">Inactive</MenuItem>
                    </TextField>
                    <DocumentTemplatePicker 
                        label="Template" 
                        onSelectHandler={t => updateTemplateId(t?.id ?? 0)} 
                        typesFilter={templateTypeFilters} statusFilter={1}
                        selectedId={selectedTemplateId} />
                    <Box>
                        <Button endIcon={ <AddIcon /> } onClick={addFieldCallback}>Add Field</Button>
                        {fields.map((f, i) => (
                            <ScreeningQuestionsField
                                key={f.order}
                                index={i}
                                isLast={i === (fields.length - 1)}
                                field={f}
                                onChange={fieldChangeCallback}
                                moveUp={fieldMoveUpCallback}
                                moveDown={fieldMoveDownCallback}
                                onDelete={fieldDeleteCallback}
                            />
                        ))}
                    </Box>
                </Stack>
            </PageContentLayout>
        </PageLayout>
    );
}