import { MessageTemplateAttachment } from "../common/models/EmailIntegration";
import { ErrorHandler, NumberValueWrapper, RequestOptions } from "../common/models/GenericTypes";
import { CVProcessingEmailTemplate } from "../common/models/Templates/CVProcessingEmailTemplate";
import { DocumentTemplate, DocumentTemplatePlaceholder } from "../common/models/Templates/DocumentTemplate";
import { MessageTemplate } from "../common/models/Templates/MessageTemplate";
import { ScreeningQuestionsTemplate } from "../common/models/Templates/ScreeningQuestionsTemplate";
import { ReferenceCheckTemplate } from "../common/models/Templates/ReferenceCheckTemplate";
import { TemplatePlaceholder } from "../common/models/Templates/TemplatePlaceholder";
import { DownloadBlob } from "../util/FilesUtils";
import { Delete, DownloadFile, Get, Post, PostFile, Put, PutFile } from "../util/HttpUtils";


export async function GetCvProcessingTemplates(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<CVProcessingEmailTemplate[]>('api/CvProcessingTemplates', options);
}

export async function GetCvProcessingTemplate(templateId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<CVProcessingEmailTemplate>(`api/CvProcessingTemplates/${templateId}`, options);
}

export async function GetDocumentTemplates(includeDeleted: boolean, typesFilter?: number[], statusId?: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    let filter = '';
    if (typesFilter && typesFilter.length > 0) filter = '&' + typesFilter.map(f => `typeId=${f}`).join('&');
    if (statusId !== undefined) filter += `&statusId=${statusId}`
    return Get<DocumentTemplate[]>(`api/DocumentTemplates?includeDeleted=${includeDeleted}${filter}`, options);
}

export async function GetDocumentTemplateById(templateId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<DocumentTemplate>(`api/DocumentTemplates/${templateId}`, options);
}

export async function GetDocumentTemplatePlaceholdersById(templateId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<DocumentTemplatePlaceholder[]>(`api/DocumentTemplates/${templateId}/Placeholders`, options);
}

export async function DownloadDocumentTemplateFile(templateId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    const file = await DownloadFile(`api/DocumentTemplates/${templateId}/File`, options);
    if (file !== null) {
        DownloadBlob(file.data, file.fileName);
    }
}

export async function CreateDocumentTemplate(templateData: DocumentTemplate, file: File | null, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    const formData = new FormData();
    formData.append('templateData', JSON.stringify(templateData));
    if (file) formData.append('file', file);

    return PostFile<boolean>('api/DocumentTemplates', formData, options);
}

export async function UpdateDocumentTemplate(templateId: number, templateData: DocumentTemplate, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/DocumentTemplates/${templateId}`, options, templateData);
}

export async function UpdateDocumentTemplateFile(templateId: number, file: File, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    const formData = new FormData();
    formData.append('file', file);

    return PutFile<boolean>(`api/DocumentTemplates/${templateId}/File`, formData, options);
}

export async function CopyDocumentTemplate(templateId: number, newName: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>(`api/DocumentTemplates/${templateId}?newName=${encodeURIComponent(newName)}`, options);
}

export async function DeleteDocumentTemplates(documentTemplateIds: number[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Delete<boolean>('api/DocumentTemplates/Bulk', options, documentTemplateIds);
}

export async function GetDocumentTemplatesPlaceholders(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<TemplatePlaceholder[]>('api/DocumentTemplates/Placeholders', options);
}

export async function GetAllMessageTemplates(errorHandler?: ErrorHandler, type: number = 0, entityType: number = 0, includeCompanyOwned: boolean = false) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<MessageTemplate[]>(`api/MessageTemplates?type=${type}&entityType=${entityType}&includeCompanyOwned=${includeCompanyOwned}`, options);
}

export async function GetMyMessageTemplates(errorHandler?: ErrorHandler, type: number = 0, entityType: number = 0, includeCompanyOwned: boolean = false) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<MessageTemplate[]>(`api/MessageTemplates/Me?type=${type}&entityType=${entityType}&includeCompanyOwned=${includeCompanyOwned}`, options);
}

export async function GetMyTeamMessageTemplates(errorHandler?: ErrorHandler, type: number = 0, entityType: number = 0, includeCompanyOwned: boolean = false) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<MessageTemplate[]>(`api/MessageTemplates/MyTeam?type=${type}&entityType=${entityType}&includeCompanyOwned=${includeCompanyOwned}`, options);
}

export async function GetMessageTemplatesTest(source: string, errorHandler?: ErrorHandler, type: number = 0, entityType: number = 0, includeCompanyOwned: boolean = false) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<any>(`api/MessageTemplates/Test/${source}/${type}/${entityType}?includeCompanyOwned=${includeCompanyOwned}`, options);
}

export async function GetMessageTemplate(templateId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<MessageTemplate>(`api/MessageTemplates/${templateId}`, options);
}

export async function CreateMessageTemplate(data: MessageTemplate, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<NumberValueWrapper>('api/MessageTemplates', options, data);
}

export async function DeleteMessageTemplates(templateIds: number[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Delete<boolean>('api/MessageTemplates', options, templateIds);
}

export async function UpdateMessageTemplate(templateId: number, data: MessageTemplate, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/MessageTemplates/${templateId}`, options, data);
}

export async function CopyMessageTemplate(templateId: number, newName: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>(`api/MessageTemplates/${templateId}?newName=${encodeURIComponent(newName)}`, options);
}

export async function GetMessageTemplateAttachments(templateId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<MessageTemplateAttachment[]>(`api/MessageTemplates/${templateId}/Attachments`, options);
}

export async function DownloadMessageTemplateAttachmentFile(templateId: number, attachmentId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    const file = await DownloadFile(`api/MessageTemplates/${templateId}/Attachments/${attachmentId}`, options);
    if (file !== null) {
        DownloadBlob(file.data, file.fileName);
    }
}

export async function AddMessageTemplateAttachment(templateId: number, files: File[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
        const f = files[i];
        formData.append('files', f);
    }

    return PostFile<number[]>(`api/MessageTemplates/${templateId}/Attachments`, formData, options);
}

export async function RemoveMessageTemplateAttachment(templateId: number, attachmentIds: number[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    }

    return Delete<boolean>(`api/MessageTemplates/${templateId}/Attachments`, options, attachmentIds);
}

export async function GetMyScreeningQuestionnaireTemplates(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<ScreeningQuestionsTemplate[]>('api/ScreeningQuestionTemplates/Me', options);
}

export async function GetAllScreeningQuestionnaireTemplates(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<ScreeningQuestionsTemplate[]>('api/ScreeningQuestionTemplates', options);
}

export async function GetScreeningQuestionnaireTemplateById(templateId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<ScreeningQuestionsTemplate>(`api/ScreeningQuestionTemplates/${templateId}`, options);
}

export async function CopyScreeningQuestionnaireTemplate(templateId: number, newName: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>(`api/ScreeningQuestionTemplates/${templateId}?newName=${encodeURIComponent(newName)}`, options);
}

export async function CreateScreeningQuestionnaireTemplate(data: ScreeningQuestionsTemplate, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>('api/ScreeningQuestionTemplates', options, data);
}

export async function UpdateScreeningQuestionnaireTemplate(templateId: number, data: ScreeningQuestionsTemplate, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/ScreeningQuestionTemplates/${templateId}`, options, data);
}

export async function MarkScreeningQuestionnaireTemplatesAsInactive(templateIds: number[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>('api/ScreeningQuestionTemplates/Bulk/Inactive', options, templateIds);
}

export async function GetMyReferenceCheckTemplates(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<ReferenceCheckTemplate[]>('api/ReferenceCheckTemplates/Me', options);
}

export async function GetAllReferenceCheckTemplates(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<ReferenceCheckTemplate[]>('api/ReferenceCheckTemplates', options);
}

export async function GetReferenceCheckTemplateById(templateId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<ReferenceCheckTemplate>(`api/ReferenceCheckTemplates/${templateId}`, options);
}

export async function CopyReferenceCheckTemplate(templateId: number, newName: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>(`api/ReferenceCheckTemplates/${templateId}?newName=${encodeURIComponent(newName)}`, options);
}

export async function CreateReferenceCheckTemplate(data: ReferenceCheckTemplate, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>('api/ReferenceCheckTemplates', options, data);
}

export async function UpdateReferenceCheckTemplate(templateId: number, data: ReferenceCheckTemplate, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/ReferenceCheckTemplates/${templateId}`, options, data);
}

export async function MarkReferenceCheckTemplatesAsInactive(templateIds: number[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>('api/ReferenceCheckTemplates/Bulk/Inactive', options, templateIds);
}