import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import React, { useState } from "react";
import InvoicesGridComponent from "../../components/Grids/InvoicesGrid";
import ChangeRequestsGridComponent from "../../components/Grids/InvoicesGrid";
import OnlyTitleSummaryBar from "../../components/SummaryBars/OnlyTitleSummaryBar";
import PageContentLayout from "../../layouts/PageContentLayout";
import PageLayout from "../../layouts/PageLayout";

const summaryBar = <OnlyTitleSummaryBar title="Invoices > Drafts" browserTabTitle="Drafts > Invoices" />;

export default function DraftInvoices() {
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    return (
        <>
            <Snackbar open={errorMessage !== ''} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert color="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <Snackbar open={successMessage !== ''} autoHideDuration={3000} onClose={() => setSuccessMessage('')}>
                <Alert onClose={() => setSuccessMessage('')}>{ successMessage }</Alert>
            </Snackbar>
            <PageLayout SummaryBar={summaryBar}>
                <PageContentLayout showLoading={isLoading}>
                    <InvoicesGridComponent
                        gridName="invoices/drafts"
                        source="drafts"
                        loadingHandler={setIsLoading}
                        errorHandler={setErrorMessage}
                        successHandler={setSuccessMessage}
                    />
                </PageContentLayout>
            </PageLayout>
        </>
    );
}