import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardHeader from "@mui/material/CardHeader";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CancelIcon from '@mui/icons-material/Cancel';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import React, { useCallback, useMemo, useState } from "react";
import { Virtuoso } from "react-virtuoso";
import ListItem from "@mui/material/ListItem";
import { DocumentTemplatePlaceholder } from "common/models/Templates/DocumentTemplate";
import ToggleButton from "@mui/material/ToggleButton";
import Box from "@mui/material/Box";

interface Props {
    title: string,
    placeholders: DocumentTemplatePlaceholder[],
    selectedPlaceholders: DocumentTemplatePlaceholder[],
    placeholderSelectedHandler: (placeholder: DocumentTemplatePlaceholder) => void,
    placeholderEditableHandler?: (placeholder: string) => void,
    copyToClipboardHandler?: (message: string) => void
}

export default function PlaceholderSelectionList({ title, placeholders, selectedPlaceholders, placeholderSelectedHandler,  placeholderEditableHandler, copyToClipboardHandler}: Props) {
    const [filterText, setFilterText] = useState("");

    const filteredPlaceholders = useMemo(() => {
        if (filterText === '') return placeholders;
        const filter = filterText.toLowerCase();
        return placeholders.filter(p => p.friendlyName.toLowerCase().includes(filter));
    }, [filterText, placeholders]);

    const onCopyPlaceholder = useCallback((p: string) => {
        copyToClipboardHandler && copyToClipboardHandler(p);
    }, [copyToClipboardHandler]);

    return (
        <>
            <Card sx={{ width: 562, display: 'flex', flexDirection: 'column' }}>
                <CardHeader
                    title={title}
                    sx={{ py: 0, minHeight: '42px' }}
                    titleTypographyProps={{ fontSize: '0.875rem', fontWeight: '600' }}
                />
                <Divider />
                <CardActions>
                    <Input
                        value={filterText}
                        fullWidth
                        placeholder="Filter"
                        onChange={ ({target}) => setFilterText(target.value) }
                        endAdornment={ filterText === '' ? undefined : <IconButton size="small" onClick={ () => setFilterText('') } sx={{ p: 0 }}><CancelIcon /></IconButton> }
                    />
                </CardActions>
                <Virtuoso
                    style={{ flexGrow: 1 }}
                    totalCount={filteredPlaceholders.length}
                    data={filteredPlaceholders}
                    computeItemKey={ (index, item) => `${index}-${item.placeholder}`}
                    itemContent={(index, item) => {
                        const selectedIndex = selectedPlaceholders.findIndex(p => item.placeholder === p.placeholder);
                        return (
                            <ListItem disablePadding disableGutters secondaryAction={ placeholderEditableHandler ? (
                                <Box display="flex">
                                    <ToggleButton
                                        size="small"
                                        value="val"
                                        onClick={ () => placeholderEditableHandler(item.placeholder) } selected={item.isEditable}
                                    >{ item.isEditable ? "Editable" : "Not Editable" }</ToggleButton>
                                    <IconButton title="Copy to clipboard" onClick={() => onCopyPlaceholder(item.placeholder)}><ContentCopyIcon /></IconButton>
                                </Box>
                            ) : undefined }>
                                <ListItemButton onClick={ () => placeholderSelectedHandler(item) }>
                                    <ListItemIcon>
                                        <Checkbox checked={ selectedIndex !== -1 } />
                                    </ListItemIcon>
                                    <ListItemText primary={item.friendlyName} secondary={item.placeholder} />
                                </ListItemButton>
                            </ListItem>
                        );
                    }}
                />
            </Card>
        </>
    );
}