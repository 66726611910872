import Alert from "components/Alert";
import Snackbar from "@mui/material/Snackbar";
import React, { useEffect, useState } from "react";
import ScreenLayoutAdmin from "components/ScreenLayouts/Jobs/ScreenLayoutAdmin";
import OnlyTitleSummaryBar from "components/SummaryBars/OnlyTitleSummaryBar";
import PageContentLayout from "layouts/PageContentLayout";

interface Props {
    type: 'record' | 'edit',
    jobType: 'Permanent' | 'Contract' | 'FixedContract' | 'TalentPool' | 'Panel',
    setSummaryBar?: (summaryBar: JSX.Element) => void
}

export default function JobsScreenLayoutPage({ type, jobType, setSummaryBar }: Props) {
    const [isLoading, setIsloading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [jobTypeSwitch, setJobTypeSwitch] = useState(false);

    useEffect(() => {
        if (jobType) {}
        setJobTypeSwitch(prev => !prev);
    }, [jobType]);

    useEffect(() => {
        if (setSummaryBar) {
            const typeTitle = type === 'record' ? 'View' : 'Create / Edit';
            let jt: string = jobType;
            if (jobType === 'FixedContract') jt = 'Fixed Term';
            else if (jobType === 'TalentPool') jt = 'Talent Pool';
            const summaryBar = <OnlyTitleSummaryBar title={`Configuration > Screen Layouts > ${jt} Jobs - ${typeTitle}`} browserTabTitle="Screen Layouts > Configuration" />;
            setSummaryBar(summaryBar);
        }
    }, [setSummaryBar, type, jobType]);
    
    return (
        <>
            <Snackbar open={errorMessage !== ''} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <PageContentLayout showLoading={isLoading}>
                { type === 'record' && jobTypeSwitch && <ScreenLayoutAdmin jobType={jobType} layoutType="Record" errorHandler={setErrorMessage} loadingHandler={setIsloading} /> }
                { type === 'edit' && jobTypeSwitch && <ScreenLayoutAdmin jobType={jobType} layoutType="Edit" errorHandler={setErrorMessage} loadingHandler={setIsloading} /> }
                { type === 'record' && !jobTypeSwitch && <ScreenLayoutAdmin jobType={jobType} layoutType="Record" errorHandler={setErrorMessage} loadingHandler={setIsloading} /> }
                { type === 'edit' && !jobTypeSwitch && <ScreenLayoutAdmin jobType={jobType} layoutType="Edit" errorHandler={setErrorMessage} loadingHandler={setIsloading} /> }
            </PageContentLayout>
        </>
    );
}