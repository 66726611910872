import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CandidatesGridComponent from "components/Grids/CandidatesGrid";
import PlacementsGridComponent from "components/Grids/PlacementsGrid";
import { AutomationPreviewData } from "common/models/Configuration/AutomationPreviewData";

interface Props {
    open: boolean,
    filters: string,
    moduleId: number,
    fromDialogPreviewData: AutomationPreviewData,
    gridLoadHandler: (data: boolean) => void
    cancelHandler: () => void,
    errorHandler: (message: string) => void,
    successHandler: (message: string) => void,
}

export default function AutomationPreviewDataDialog({open,moduleId,fromDialogPreviewData,cancelHandler,gridLoadHandler,errorHandler,successHandler, filters}: Props) {
    return (
        <Dialog 
         open={open} hideBackdrop={false} 
         fullWidth={true}
         maxWidth={'xl'}>
            <DialogTitle>Preview {moduleId === 3 ? 'Candidate data' : 'Placement data'}</DialogTitle>
            <DialogContent style={{height: 'Auto'}}>
                {moduleId === 3 && 
                <CandidatesGridComponent
                    gridName="candidate-search-automation-filters"
                    source="automation"
                    filterJSON={filters}
                    loadingHandler={gridLoadHandler}
                    errorHandler={errorHandler}
                    successHandler={successHandler}
                    fromDialog={true}
                    fromDialogPreviewData={fromDialogPreviewData}/>}

                {moduleId === 5 && 
                <PlacementsGridComponent 
                    gridName="placement-search-automation-filters"
                    source="automation"
                    filterJSON={filters}
                    loadingHandler={gridLoadHandler}
                    errorHandler={errorHandler}
                    successHandler={successHandler}
                    fromDialog={true}
                    fromDialogPreviewData={fromDialogPreviewData}/>}
                
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="error" onClick={cancelHandler} >Close</Button>
            </DialogActions>
        </Dialog>
    );
}