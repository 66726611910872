import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import ReplayIcon from '@mui/icons-material/Replay';
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useTheme } from "@mui/material/styles";
import { PanelModel } from "@syncfusion/ej2-layouts/src/dashboard-layout/dashboard-layout-model";
import { DashboardLayoutComponent } from "@syncfusion/ej2-react-layouts/src/dashboard-layout/dashboardlayout.component";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { CustomField } from "common/models/Configuration/CustomFields";
import { ContactRecordDashboardElementDefinition, ContactRecordDashboardElementType } from "common/models/Dashboard/EditLayout";
import { CustomFieldSettingsMap, CustomFieldType } from "common/models/ScreenLayouts/CustomFields";
import useUnsavedChangesDialog from "hooks/UseUnsavedChangesDialog";
import { GetCustomerSettingBySettingName, UpdateCustomerSettingBySettingName } from "services/ConfigurationService";
import { GetCustomFieldsByEntity_OnlyActive } from "services/CustomFieldsService";
import { PlaceholderContact } from "util/Definitions/Contacts";
import { ContactScreenLayoutSettings, DefaultContactRecordDashboardElements, GetElementTypeById, GetMinSizeByElementType, GetPanelDefinitionsFromPanelModels } from "util/Definitions/ScreenLayouts/Contact";
import { UdfContactFieldMapObj } from "util/Definitions/ScreenLayouts/CustomFields";
import PanelWrapper from "components/Dashboards/PanelWrapper";
import ConfirmationDialog from "components/Dialogs/Generic/ConfirmationDialog";
import ViewClientLocationElement from "../Clients/ViewClientLocationElement";
import SingleFieldElement from "../SingleFieldElement";
import MultiLineTextElement from "../MultiLineTextElement";
import AddContactDashboardElementDialog from "components/Dialogs/Dashboard/AddContactDashboardElementDialog";
import RecordAvatarComponent from "components/Dashboards/Graphs/ClientAvatarComponent";

interface Props {
    layoutType: 'Record' | 'Edit',
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
}

const contact = PlaceholderContact;

const LayoutElementsToString = (elements: PanelModel[]) => {
    if (elements.length === 0) return '';
    let s = elements.map(e => ElementString(e));
    s.sort()
    return s.join('|');
}

const ElementString = (element: PanelModel) => {
    const type = GetElementTypeById(element.id ?? '');
    const val = `${type}|${element.row}|${element.col}|${element.sizeX}|${element.sizeY}`;
    return val;
};

const ignoreEditElement = [ 'ContactOptOut' ];

const { unitWidth, unitHeight, gapX, gapY, columns, mediaQueryMaxWidth } = ContactScreenLayoutSettings;
const cellSpacing = [gapX, gapY];

export default function ScreenLayoutAdmin({ layoutType, loadingHandler, errorHandler }: Props) {
    const [layoutSwapControl, setLayoutSwapControl] = useState(true);
    const [screenResizedControl, setScreenResizedControl] = useState(false);
    const [showAddElementDialog, setShowAddElementDialog] = useState(false);
    const [fetchingSavedState, setFetchingSavedState] = useState(false);
    const [fetchingActiveFields, setFetchingActiveFields] = useState(false);
    const [fetchedSavedState, setFetchedSavedState] = useState(false);
    const [activeFields, setActiveFields] = useState<CustomField[]>([]);
    const [initialState, setInitialState] = useState<PanelModel[] | null>(null);
    const [hasChanges, setHasChanges] = useState(false);
    const [saveAsBothEditAndView, setSaveAsBothEditAndView] = useState(false);
    const [showConfirmSaveBothDialog, setShowConfirmSaveBothDialog] = useState(false);
    const [showResetToDefaultConfirmDialog, setShowResetToDefaultConfirmDialog] = useState(false);
    const [lastSavedState, setLastSavedState] = useState<PanelModel[] | null>(null);
    const layoutRef = useRef<DashboardLayoutComponent | null>(null);
    const theme = useTheme();

    const customerSettingName = useMemo(() => layoutType === 'Edit' ? 'ContactEditRecordDashboardLayoutElements' : 'ContactRecordDashboardLayoutElements', [layoutType]);

    useEffect(() => {
        const windowResizeHandler = () => setScreenResizedControl(prev => !prev);
        window.addEventListener('resize', windowResizeHandler);
        return () => window.removeEventListener('resize', windowResizeHandler);
    }, []);

    useEffect(() => {
        const getActiveFields = async () => {
            setFetchingActiveFields(true);
            const res = await GetCustomFieldsByEntity_OnlyActive(2);
            if (res) setActiveFields(res);
            setFetchingActiveFields(false);
        };
        getActiveFields();
    }, []);

    const customFieldsSettingsMap = useMemo<CustomFieldSettingsMap>(() => {
        let obj: CustomFieldSettingsMap = {};
        activeFields.forEach(u => {
            const customFieldNumber = u.name.substring(13);
            const key = 'CustomField' + customFieldNumber as CustomFieldType;
            obj[key] = {
                title: u.agencyName,
                isMultiLine: u.multiLine && !u.usePredefinedValues,
                isEditable: u.editable
            }
        });
        return obj;
    }, [activeFields]);

    useEffect(() => {
        const getSavedState = async () => {
            setFetchingSavedState(true);
            const elementsJson = await GetCustomerSettingBySettingName(customerSettingName);
            if (elementsJson) {
                const panels = JSON.parse(elementsJson) as PanelModel[];
                setLastSavedState(panels);
                setInitialState(panels);
            }
            setFetchingSavedState(false);
            setFetchedSavedState(true);
        };
        getSavedState();
    }, [customerSettingName]);

    useEffect(() => {
        loadingHandler && loadingHandler(fetchingSavedState || fetchingActiveFields);
    }, [loadingHandler, fetchingSavedState, fetchingActiveFields]);

    const initialStateString = useMemo(() => {
        if (initialState && initialState.length ) return LayoutElementsToString(initialState);
        return '';
    }, [initialState]);

    const layoutChangeHandler = useCallback(() => {
        const api = layoutRef.current;
        if (api) {
            const currentData = api.serialize();
            const currentStringData = LayoutElementsToString(currentData);
            setHasChanges(currentStringData !== initialStateString);
        }
    }, [initialStateString]);

    useEffect(() => {
        if (layoutSwapControl) {}
        layoutChangeHandler();
    }, [layoutSwapControl, layoutChangeHandler]);

    const elements = useMemo<ContactRecordDashboardElementDefinition[]>(() => {
        if (!fetchedSavedState) return [];
        if (lastSavedState !== null) {
            const elements = GetPanelDefinitionsFromPanelModels(lastSavedState);
            return elements ;
        }
        return DefaultContactRecordDashboardElements;
    }, [lastSavedState, fetchedSavedState]);

    const removeElementHandler = useCallback((id: string) => {
        const api = layoutRef.current;
        if (api) {
            api.removePanel(id);
            layoutChangeHandler();
        }
    }, [layoutChangeHandler]);

    const addElementHandler = useCallback((newElementType: ContactRecordDashboardElementType) => {
        const api = layoutRef.current;
        if (api) {
            const m = moment().format('YYYYMMDDhhmmssSS');
            const id = `${newElementType}_${m}`;
            const canGrowY = newElementType === 'ContactSummary' || newElementType.startsWith('CustomField');
            const [minX, minY] = GetMinSizeByElementType(newElementType);
            const maxSizeY = canGrowY ? undefined : minY;
            api.addPanel({ id: id, col: 0, row: 0, sizeX: 1, sizeY: 1 });
            api.updatePanel({ id: id, col: 0, row: 0, sizeX: minX, sizeY: minY, minSizeX: minX, minSizeY: minY, maxSizeX: 1, maxSizeY: maxSizeY, enabled: false });
            const newDataObj = api.serialize();
            setLastSavedState(newDataObj);
            setLayoutSwapControl(prev => !prev);
            setShowAddElementDialog(false);
            layoutChangeHandler();
        }
    }, [layoutChangeHandler]);

    const renderElement = useCallback((id: string, type: ContactRecordDashboardElementType) => {
        if (contact) {
            switch (type) {
                case 'Divider': return <Box pt="20px"><Divider component="div" /></Box>;
                case 'Spacer': return <></>;
                case 'ContactInfo': return (
                    <RecordAvatarComponent
                        entityId={2}
                        primaryText={contact.fullName}
                        secondaryText={contact.clientName ?? ''}
                        photoUrl={contact.photoUrl}
                    />);
                case 'ContactId': return <SingleFieldElement disableTextSelection useEllipsisForLongValues fieldTitle="ID" fieldValue={contact.id.toString()} />;
                case 'ContactName': return <SingleFieldElement disableTextSelection useEllipsisForLongValues fieldTitle="Name" fieldValue={contact.fullName} />;
                case 'ContactJobTitle': return <SingleFieldElement disableTextSelection useEllipsisForLongValues fieldTitle="Job Title" fieldValue={contact.jobTitle} />;
                case 'ContactClient': return (
                    <SingleFieldElement disableTextSelection fieldTitle='Client' format="custom">
                        <Box component="span" color="primary.main">{contact.clientName}</Box>
                    </SingleFieldElement>
                );
                case 'ContactManager': return (
                    <SingleFieldElement disableTextSelection fieldTitle='Manager' format="custom">
                        <Box component="span" color="primary.main">{contact.reportsToName ?? ''}</Box>
                    </SingleFieldElement>
                );
                case 'ContactOwner': return (
                    <SingleFieldElement disableTextSelection fieldTitle='Owners' format="custom">
                        <Box component="span" color="primary.main">5</Box>
                    </SingleFieldElement>
                );
                case 'ContactStatus': return <SingleFieldElement disableTextSelection useEllipsisForLongValues fieldTitle="Status" fieldValue={contact.statusName} />;
                case 'ContactEmail1': return <SingleFieldElement disableTextSelection useEllipsisForLongValues fieldTitle="Email 1" fieldValue={contact.email} format="email-link" href={contact.email} />;
                case 'ContactEmail2': return <SingleFieldElement disableTextSelection useEllipsisForLongValues fieldTitle="Email 2" fieldValue={contact.email2} format="email-link" href={contact.email2} />;
                case 'ContactOptOut': return <SingleFieldElement disableTextSelection useEllipsisForLongValues fieldTitle="Opt Out" fieldValue={contact.optOut ? 'Yes' : 'No'} />;
                case 'ContactLocation': return (
                    <ViewClientLocationElement
                        address1={contact.address1 ?? ''}
                        address2={contact.address2 ?? ''}
                        address3={contact.address3 ?? ''}
                        countryName={contact.countryName}
                        postcode={contact.postcode ?? ''}
                        siteId={contact.siteID}
                        siteName={contact.siteName ?? ''}
                        state={contact.state ?? ''}
                        suburb={contact.suburb ?? ''}
                        disableTextSelection
                    />
                );
                case 'ContactMobile': 
                    const mobile = contact.mobile_Standardised ? contact.mobile_Standardised : contact.mobile;
                    return (
                        <SingleFieldElement disableTextSelection useEllipsisForLongValues fieldTitle="Mobile" format="custom">
                            <Box component="span" color="primary.main">{mobile}</Box>
                        </SingleFieldElement>
                    );
                case 'ContactPhone': 
                    const phone = contact.phone;
                    return (
                        <SingleFieldElement disableTextSelection useEllipsisForLongValues fieldTitle="Phone" format="custom">
                            <Box component="span" color="primary.main">{phone}</Box>
                        </SingleFieldElement>
                    );
                case 'ContactSummary': return <MultiLineTextElement disableTextSelection title="Summary" content={contact.description} />;
                case 'ContactCandidate': return (
                    <SingleFieldElement disableTextSelection fieldTitle='Candidate' format="custom">
                        <Box component="span" color="primary.main">{contact.linkedCandidateName}</Box>
                    </SingleFieldElement>
                );
                case 'ContactDivision': return (
                    <SingleFieldElement disableTextSelection fieldTitle="Divisions" format="custom">
                        <Chip size="small" label="Placeholder Division" sx={{ mr: '5px' }} />
                    </SingleFieldElement>
                );
                case 'ContactLinkedIn': return (
                    <SingleFieldElement disableTextSelection fieldTitle='LinkedIn' format="custom">
                        <Box component="span" color="primary.main">{contact.linkedinURL}</Box>
                    </SingleFieldElement>
                );
                case 'ContactTwitter': return (
                    <SingleFieldElement disableTextSelection fieldTitle='Twitter' format="custom">
                        <Box component="span" color="primary.main">{contact.twitterURL}</Box>
                    </SingleFieldElement>
                );
                default: 
                    if (type.startsWith('CustomField')) {
                        const settings = customFieldsSettingsMap[type];
                        if (settings && layoutType === 'Edit' && !settings.isEditable) return <SingleFieldElement disableTextSelection fieldTitle={settings.title} fieldValue="[ Non Editable Custom Field ]" />;
                        if (settings) {
                            const title = settings.title;
                            const { field, format } = UdfContactFieldMapObj[type as CustomFieldType];
                            const udfValue = contact[field];
                            if (format === 'string' || format === 'date' || format === 'datetime') {
                                return <SingleFieldElement disableTextSelection isMultiLine={settings.isMultiLine} format={format} fieldTitle={title} fieldValue={udfValue ? udfValue.toString() : null} />;
                            }
                            if (format === 'number') {
                                return <SingleFieldElement disableTextSelection format={format} fieldTitle={title} fieldValue={udfValue ? +udfValue : 0} />;
                            }
                        }
                        else return <SingleFieldElement disableTextSelection fieldTitle={type} fieldValue="[ Disabled Custom Field ]" />
                    }
                    return <div>{id}</div>;
            }
        }
    }, [customFieldsSettingsMap, layoutType]);

    const saveChangesHandler = useCallback(async () => {
        const api = layoutRef.current;
        if (api && fetchedSavedState) {
            const obj = api.serialize();
            loadingHandler && loadingHandler(true);
            const res = await UpdateCustomerSettingBySettingName(customerSettingName, JSON.stringify(obj), errorHandler);
            loadingHandler && loadingHandler(false);
            if (!res) return false;
            setInitialState(obj);
        }
        return true;
    }, [fetchedSavedState, customerSettingName, loadingHandler, errorHandler]);

    const saveChangesAsBothHandler = useCallback(async () => {
        const api = layoutRef.current;
        if (api && fetchedSavedState) {
            const obj = api.serialize();
            loadingHandler && loadingHandler(true);
            const res1 = await UpdateCustomerSettingBySettingName('ContactEditRecordDashboardLayoutElements', JSON.stringify(obj), errorHandler);
            const res2 = await UpdateCustomerSettingBySettingName('ContactRecordDashboardLayoutElements', JSON.stringify(obj), errorHandler);
            loadingHandler && loadingHandler(false);
            if (!res1 || !res2) return false;
            setInitialState(obj);
            setShowConfirmSaveBothDialog(false);
            setSaveAsBothEditAndView(false);
        }
        return true;
    }, [fetchedSavedState, loadingHandler, errorHandler]);

    const resetLayoutHandler = useCallback(() => {
        setLastSavedState(null);
        setShowResetToDefaultConfirmDialog(false);
        setLayoutSwapControl(prev => !prev);
    }, []);
    
    const layoutResizeStopHandler = useCallback(() => {
        setScreenResizedControl(prev => !prev);
        layoutChangeHandler();
    }, [layoutChangeHandler]);

    useEffect(() => {
        if (screenResizedControl) {}
        const api = layoutRef.current;
        if (api && fetchedSavedState) {
            const refreshTimeout = setTimeout(() => {
                api.refresh();
                api.refresh();
            }, 250);
            return () => clearTimeout(refreshTimeout);
        }
    }, [fetchedSavedState, screenResizedControl]);

    const renderLayout = useCallback(() => {
        if (!fetchedSavedState) return <></>;

        const mediaQuery = mediaQueryMaxWidth ? `max-width: ${mediaQueryMaxWidth}` : undefined;
        return (
            <DashboardLayoutComponent
                cellSpacing={cellSpacing}
                columns={columns}
                cellAspectRatio={30 / 2}
                allowResizing
                resizeStop={ layoutResizeStopHandler }
                change={ layoutChangeHandler }
                ref={l => layoutRef.current = l}
                mediaQuery={mediaQuery}
            >
                {elements.map(e => (
                    <PanelWrapper
                        key={e.id}
                        id={e.id}
                        col={e.col}
                        row={e.row}
                        sizeX={e.sizeX}
                        sizeY={e.sizeY}
                        minSizeX={e.minSizeX}
                        minSizeY={e.minSizeY}
                        maxSizeX={e.maxSizeX}
                        maxSizeY={e.maxSizeY}
                        resizeControl={screenResizedControl}
                        resizeIconColor={theme.palette.text.disabled}
                        gapX={gapX}
                        gapY={gapY}
                        unitWidth={unitWidth}
                        unitHeight={unitHeight}
                        removeHandler={removeElementHandler}
                    >
                        {renderElement(e.id, e.type)}
                    </PanelWrapper>
                ))}
            </DashboardLayoutComponent>
        );
    }, [elements, fetchedSavedState, theme.palette.text.disabled, screenResizedControl, layoutResizeStopHandler, layoutChangeHandler, removeElementHandler, renderElement]);

    const { unsavedChangesDialog } = useUnsavedChangesDialog(hasChanges, saveChangesHandler, undefined, undefined, true);

    const addedElementTypes = useMemo(() => {
        const api = layoutRef.current;
        if (api && showAddElementDialog) {
            const t = api.serialize();
            return t.map(e => {
                const id = e.id;
                if (id) {
                    const i = id.indexOf('_');
                    return id.substring(0, i);
                }
                return '';
            });

        }
        return [];
    }, [showAddElementDialog]);
    
    return (
        <>
            {unsavedChangesDialog}
            <ConfirmationDialog
                message="Are you sure you want to save the current layout for both Edit and View?"
                title="Confirm Action"
                onClose={ () => setShowConfirmSaveBothDialog(false) }
                onContinue={ saveChangesAsBothHandler }
                open={showConfirmSaveBothDialog}
                fullWidth
            />
            <ConfirmationDialog
                message="Are you sure you want to reset the current layout to its default form? All changes will be lost"
                title="Confirm Action"
                onClose={ () => setShowResetToDefaultConfirmDialog(false) }
                onContinue={ resetLayoutHandler }
                open={showResetToDefaultConfirmDialog}
                fullWidth
            />
            <AddContactDashboardElementDialog
                open={showAddElementDialog}
                activeFields={activeFields}
                alreadyAddedElements={addedElementTypes}
                ignoreElements={layoutType === 'Edit' ? ignoreEditElement : undefined}
                closeHandler={() => setShowAddElementDialog(false)}
                addElementHandler={addElementHandler}
            />
            <div style={{ marginBottom: '10px' }}>
                <span>
                    <Button
                        variant="contained"
                        endIcon={<ReplayIcon />}
                        onClick={() => setShowResetToDefaultConfirmDialog(true)}
                        sx={{ mr: '5px' }}
                    >Reset to default</Button>
                    <Button
                        variant="contained"
                        endIcon={<AddIcon />}
                        onClick={() => setShowAddElementDialog(true)}
                    >Add Element</Button>
                </span>
                <span style={{ float: 'right' }}>
                    <FormControlLabel
                        sx={{ m: 0, mr:'20px' }}
                        label="Save as both Edit & View"
                        labelPlacement="start"
                        control={
                            <Checkbox
                                checked={ saveAsBothEditAndView }
                                onChange={ () => setSaveAsBothEditAndView(prev => !prev) }
                                sx={{ p: '4px' }}
                            />
                        }
                    />
                    <Button
                        color="success"
                        variant="contained"
                        endIcon={<SaveIcon />}
                        disabled={!hasChanges && !saveAsBothEditAndView}
                        onClick={saveAsBothEditAndView ? () => setShowConfirmSaveBothDialog(true) : saveChangesHandler}
                    >Save</Button>
                </span>
            </div>
            <Box p="10px" height="100%">
                <div className="control-section">
                    {layoutSwapControl && renderLayout()}
                    {!layoutSwapControl && renderLayout()}
                </div>
            </Box>
        </>
    );
}