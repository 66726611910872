import Box from "@mui/material/Box";
import React, { useMemo } from "react";
import JobBoardViewField from "./JobBoardViewField";

interface Props {
    jobBoardId: number,
    advertId: number,
    attributes?: Record<string, string>,
    advertRecordAttributes?: Record<string, string>,
}

const categoryMap: Record<string, string> = {
    "1": "Accounting & Finance Jobs",
    "13": "Admin Jobs",
    "25": "Charity & Voluntary Jobs",
    "15": "Creative & Design Jobs",
    "18": "Consultancy Jobs",
    "4": "Customer Services Jobs",
    "26": "Domestic help & Cleaning Jobs",
    "23": "Energy, Oil & Gas Jobs",
    "5": "Engineering Jobs",
    "16": "Graduate Jobs",
    "7": "Healthcare & Nursing Jobs",
    "8": "Hospitality & Catering Jobs",
    "6": "HR & Recruitment Jobs",
    "2": "IT Jobs",
    "14": "Legal Jobs",
    "10": "Logistics & Warehouse Jobs",
    "27": "Maintenance Jobs",
    "19": "Manufacturing Jobs",
    "29": "Other/General Jobs",
    "28": "Part time Jobs",
    "9": "PR, Advertising & Marketing Jobs",
    "24": "Property Jobs",
    "17": "Retail Jobs",
    "3": "Sales Jobs",
    "20": "Scientific & QA Jobs",
    "21": "Social work Jobs",
    "11": "Teaching Jobs",
    "12": "Trade & Construction Jobs",
    "22": "Travel Jobs",
};

const contractTimeMap: Record<string, string> = {
    "full_time": "Full Time",
    "part_time": "Part Time",
};

export default function AdzunaEdit({ jobBoardId, advertId, attributes, advertRecordAttributes }: Props) {
    const atts = useMemo<Record<string, string>>(() => {
        if (attributes) return attributes;
        return {};
    }, [attributes]);

    const recordAtts = useMemo<Record<string, string>>(() => {
        if (advertRecordAttributes) return advertRecordAttributes;
        return {};
    }, [advertRecordAttributes]);

    const category = useMemo(() => atts.category ?? '', [atts.category]);
    const country = useMemo(() => recordAtts.country ?? '', [recordAtts.country]);
    const location = useMemo(() => recordAtts.location ?? '', [recordAtts.location]);
    const contractTime = useMemo(() => atts.contract_time ?? '', [atts.contract_time]);
    const workType = useMemo(() => recordAtts.workType ?? '', [recordAtts.workType]);
    const salaryType = useMemo(() => recordAtts.salaryType ?? '', [recordAtts.salaryType]);
    const salaryFrom = useMemo(() => recordAtts.salaryFrom ?? '', [recordAtts.salaryFrom]);
    const salaryTo = useMemo(() => recordAtts.salaryTo ?? '', [recordAtts.salaryTo]);

    return (
        <Box display="flex" gap={2} pt={2}>
            <Box display="flex" flexDirection="column" gap={2} width="50%">
                <JobBoardViewField label="Category" value={categoryMap[category] ?? ''} />
                <JobBoardViewField label="Country" value={country} />
                <JobBoardViewField label="Location" value={location} />
                <JobBoardViewField label="Contract Time" value={contractTimeMap[contractTime] ?? ''} />
            </Box>
            <Box display="flex" flexDirection="column" gap={2} width="50%">
                <JobBoardViewField label="Work Type" value={workType} />
                <JobBoardViewField label="Salary Type" value={salaryType} />
                <Box display="flex" gap={2}>
                    <JobBoardViewField label="Salary From" sx={{ flex: '1 1 0' }} value={salaryFrom} />
                    <JobBoardViewField label="Salary To" sx={{ flex: '1 1 0' }} value={salaryTo} />
                </Box>
            </Box>
        </Box>
    );
}