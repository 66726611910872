import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { GetDashboardPlacementFeesGraphData } from "services/BusinessIntelligence";
import SettingsIcon from '@mui/icons-material/Settings';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import {User} from "../../../common/models/Configuration/User";
import {GetActiveUsers} from "../../../services/UsersService";

interface Props {
    id: string,
    consultant: number,
    timeRange: number,
    netRevenueLabel: string,
    showConfigButton?: boolean,
    saveConfigHandler?: (id: string, newTimeRange: number, newConsultant: number) => void
}

interface GraphData {
    title: string,
    netRevenue: number,
    target: number,
    // color: string
}

const GetTimeRangeName = (timeRange: number) => {
    switch (timeRange) {
        case 2: return "This Month";
        case 3: return "This Quarter";
        case 4: return "This Year";
        case 6: return "Last Month";
        case 7: return "Last Quarter";
        case 8: return "Last Year";
    }
    return "";
};

export default function PlacementFeesGraphComponent({ id, consultant: consultantProp, timeRange: timeRangeProp, netRevenueLabel, showConfigButton, saveConfigHandler }: Props) {
    const [consultant, setConsultant] = useState(-1);
    const [timeRange, setTimeRange] = useState(1);
    const [showConfigDialog, setShowConfigDialog] = useState(false);
    const [graphData, setGraphData] = useState<GraphData[]>([]);
    const t = useTheme();
    const [users, setUsers] = useState<User[]>([]);

    useEffect(() => {
        const getData = async () => {
            const data = await GetActiveUsers();
            if (data) setUsers(data);
        };
        getData();
    }, []);
    
    const GetConsultantName = useCallback((consultantId: number) => {
        switch (consultantId) {
            case -1: return 'Me';
            case -2: return 'My Team';
            case -3: return 'My Company';
        }
        return users.find(item => item.id === consultantId)?.displayName;
    }, [users]);

    const graphTitle = useMemo(() => {
        const trn = GetTimeRangeName(timeRangeProp);
        if (Boolean(netRevenueLabel)) return `${netRevenueLabel} - ${trn} (${GetConsultantName(consultantProp)})`;
        return `Placement Fees - ${trn} (${GetConsultantName(consultantProp)})`;
    }, [timeRangeProp, netRevenueLabel, GetConsultantName, consultantProp]);

    const getDatesFromTimeRange = useCallback((tr: number) => {
        let start: moment.Moment | null = null;
        let end: moment.Moment | null = null;
        if (tr === 0) return;
        if (tr === 1) { // This Week
            start = moment().startOf('isoWeek');
            end = moment().endOf('isoWeek');
        }
        else if (tr === 2) { // This Month
            start = moment().startOf('month');
            end = moment().endOf('month');
        }
        else if (tr === 3) { // This Quarter
            start = moment().startOf('quarter');
            end = moment().endOf('quarter');
        }
        else if (tr === 4) { // This Year
            start = moment().startOf('year');
            end = moment().endOf('year');
        }
        else if (tr === 5) { // Last Week
            start = moment().subtract(1,'week').startOf('isoWeek');
            end = moment().subtract(1,'week').endOf('isoWeek');
        }
        else if (tr === 6) { // Last Month
            start = moment().subtract(1, 'months').startOf('month');
            end = moment().subtract(1, 'months').endOf('month');
        }
        else if (tr === 7) { // Last Quarter
            start = moment().subtract(1, 'quarter').startOf('quarter');
            end = moment().subtract(1, 'quarter').endOf('quarter');
        }
        else if (tr === 8) { // Last Year
            const lastYear = moment().get('year') - 1;
            start = moment(`${lastYear}-01-01`);
            end = moment(`${lastYear}-12-31`);
        }
        else if (tr === 9) { // Last 7 Days
            start = moment().subtract(6, 'days');
            end = moment();
        }
        else if (tr === 10) { // Last 30 Days
            start = moment().subtract(30, 'days');
            end = moment();
        }
        else if (tr === 11) { // Last 90 Days
            start = moment().subtract(90, 'days');
            end = moment();
        }
        else if (tr === 12) { // Last 365 Days
            start = moment().subtract(365, 'days');
            end = moment();
        }
        return [start, end];
    }, []);

    useEffect(() => {
        const getData = async () => {
            const dates = getDatesFromTimeRange(timeRangeProp);
            if (dates) {
                const [m1, m2] = dates;
                if (m1 && m2) {
                    const start = m1.format('YYYY-MM-DD');
                    const end = m2.format('YYYY-MM-DD');
                    const res = await GetDashboardPlacementFeesGraphData(start, end);
                    if (res) {
                        const data: GraphData[] = [
                            { title: 'Permanent', netRevenue: res.permanent, target: 0 },
                            { title: 'Contract', netRevenue: res.contract, target: 0 },
                            { title: 'Fixed Term', netRevenue: res.fixedTerm, target: 0 },
                            { title: 'All', netRevenue: res.all, target: res.allTarget },
                        ];

                        setGraphData(data);
                    }
                }
            }
        };
        getData();
    }, [getDatesFromTimeRange, t.palette.info.main, t.palette.primary.main, t.palette.secondary.main, t.palette.text.disabled, timeRangeProp]);

    const onSaveConfig = useCallback(() => {
        saveConfigHandler && saveConfigHandler(id, timeRange, consultant);
        setShowConfigDialog(false);
    }, [id, saveConfigHandler, timeRange, consultant]);

    useEffect(() => {
        if (showConfigDialog) {
            setTimeRange(timeRangeProp);
            setConsultant(consultantProp);
        }
    }, [showConfigDialog, timeRangeProp, consultantProp]);
    
    return (
        <>
            <Dialog open={showConfigDialog} fullWidth>
                <DialogTitle>Configure Element</DialogTitle>
                <DialogContent>
                    <Stack spacing={2} mt="10px">
                        <TextField select label="Consultant" value={consultant.toString()} onChange={({target}) => setConsultant(+target.value)} fullWidth>
                            <MenuItem value="-1">Me</MenuItem>
                            <MenuItem value="-2">My Team</MenuItem>
                            <MenuItem value="-3">My Company</MenuItem>
                            {users.map(u => (
                                <MenuItem value={u.id}>{u.displayName}</MenuItem>
                            ))}
                        </TextField>                        
                        <TextField select label="Time Range" value={timeRange.toString()} onChange={({target}) => setTimeRange(+target.value)} fullWidth>
                            <MenuItem value="2">This Month</MenuItem>
                            <MenuItem value="3">This Quarter</MenuItem>
                            <MenuItem value="4">This Year</MenuItem>
                            <MenuItem value="6">Last Month</MenuItem>
                            <MenuItem value="7">Last Quarter</MenuItem>
                            <MenuItem value="8">Last Year</MenuItem>
                        </TextField>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="error" onClick={() => setShowConfigDialog(false)}>Cancel</Button>
                    <Button variant="contained" color="success" onClick={onSaveConfig}>Save</Button>
                </DialogActions>
            </Dialog>
            {showConfigButton &&
                <IconButton
                    sx={{ position: 'absolute', right: 30, zIndex: t => t.zIndex.appBar - 5, color: t => t.palette.text.disabled }}
                    onClick={() => setShowConfigDialog(true)}
                >
                    <SettingsIcon />
                </IconButton>
            }
            <Paper sx={{ height: '100%' }}>
                <Typography variant="h6" padding={1} textAlign="center" fontWeight={600} component="div">{graphTitle}</Typography>
                <div style={{ height: 'calc(100% - 48px)' }}>
                    <ResponsiveContainer width="100%" height="100%">
                        <BarChart width={150} height={150} data={graphData}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="title" type="category" stroke={t.palette.text.primary} />
                            <YAxis type="number" dataKey="netRevenue"  />
                            <Tooltip contentStyle={{ backgroundColor: t.palette.background.paper }} />
                            <Legend />
                            <Bar dataKey="netRevenue" name="Actual" fill={t.palette.info.main} />
                            <Bar dataKey="target" name="Target" fill={t.palette.error.main} />
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </Paper>
        </>
    );
}