import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import { useTheme } from "@mui/material/styles";
import { PanelModel } from "@syncfusion/ej2-layouts/src/dashboard-layout/dashboard-layout-model";
import { DashboardLayoutComponent } from "@syncfusion/ej2-react-layouts/src/dashboard-layout/dashboardlayout.component";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Currency } from "common/models/Common";
import { CustomField } from "common/models/Configuration/CustomFields";
import { Division } from "common/models/Configuration/Division";
import { OpportunityRecordDashboardElementDefinition, OpportunityRecordDashboardElementType } from "common/models/Dashboard/EditLayout";
import { Opportunity, OpportunityOwner } from "common/models/Opportunities";
import { PreviewEntityType } from "common/models/Previews/Previews";
import { CustomFieldSettingsMap, CustomFieldType } from "common/models/ScreenLayouts/CustomFields";
import { GetCurrencies } from "services/CommonService";
import { GetCustomerSettingBySettingName } from "services/ConfigurationService";
import { GetCustomFieldsByEntity_OnlyActive } from "services/CustomFieldsService";
import { GetDivisions } from "services/DivisionsService";
import { GetOpportunityOwners } from "services/OpportunitiesService";
import { UdfOpportunityFieldMapObj } from "util/Definitions/ScreenLayouts/CustomFields";
import { DefaultOpportunityRecordDashboardElements_BackFill, DefaultOpportunityRecordDashboardElements_Bid, DefaultOpportunityRecordDashboardElements_Float, DefaultOpportunityRecordDashboardElements_General, DefaultOpportunityRecordDashboardElements_Lead, DefaultOpportunityRecordDashboardElements_Tender, GetPanelDefinitionsFromPanelModels, OpportunityScreenLayoutSettings } from "util/Definitions/ScreenLayouts/Opportunity";
import PanelWrapper from "components/Dashboards/PanelWrapper";
import OwnersListDialog from "components/Dialogs/Dashboard/OwnersListDialog";
import PreviewLoaderComponent from "components/Previews/PreviewLoader";
import MultiLineTextElement from "../MultiLineTextElement";
import SingleFieldElement from "../SingleFieldElement";

interface Props {
    opportunity: Opportunity | null,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
}

const { unitWidth, unitHeight, gapX, gapY, columns, mediaQueryMaxWidth } = OpportunityScreenLayoutSettings;
const cellSpacing = [gapX, gapY];

export default function ViewRecordScreenLayout({ opportunity, loadingHandler, errorHandler }: Props) {
    const [isOwnersDialogOpen, setIsOwnersDialogOpen] = useState(false);
    const [owners, setOwners] = useState<OpportunityOwner[]>([]);
    const [divisions, setDivisions] = useState<Division[]>([]);
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    const [previewType, setPreviewType] = useState<PreviewEntityType | ''>('');
    const [previewRecordId, setPreviewRecordId] = useState(0);
    const [screenResizedControl, setScreenResizedControl] = useState(false);
    const [fetchingSavedState, setFetchingSavedState] = useState(false);
    const [fetchedSavedState, setFetchedSavedState] = useState(false);
    const [activeFields, setActiveFields] = useState<CustomField[]>([]);
    const [lastSavedState, setLastSavedState] = useState<PanelModel[] | null>(null);
    const [currencies, setCurrencies] = useState<Currency[]>([]);
    const layoutRef = useRef<DashboardLayoutComponent | null>(null);
    const theme = useTheme();

    const layoutDataSettingName = useMemo(() => {
        if (opportunity) {
            switch (opportunity.type) {
                case 1: return 'OpportunityRecordDashboardLayoutElements_BackFill';
                case 4: return 'OpportunityRecordDashboardLayoutElements_Bid';
                case 3: return 'OpportunityRecordDashboardLayoutElements_Float';
                case 5: return 'OpportunityRecordDashboardLayoutElements_General';
                case 6: return 'OpportunityRecordDashboardLayoutElements_Lead';
                case 2: return 'OpportunityRecordDashboardLayoutElements_Tender';
            }
        }
        return '';
    }, [opportunity]);

    const defaultElements = useMemo(() => {
        if (opportunity) {
            switch (opportunity.type) {
                case 1: return DefaultOpportunityRecordDashboardElements_BackFill;
                case 4: return DefaultOpportunityRecordDashboardElements_Bid;
                case 3: return DefaultOpportunityRecordDashboardElements_Float;
                case 5: return DefaultOpportunityRecordDashboardElements_General;
                case 6: return DefaultOpportunityRecordDashboardElements_Lead;
                case 2: return DefaultOpportunityRecordDashboardElements_Tender;
            }
        }
        return [];
    }, [opportunity]);

    useEffect(() => {
        const windowResizeHandler = () => setScreenResizedControl(prev => !prev);
        window.addEventListener('resize', windowResizeHandler);
        return () => window.removeEventListener('resize', windowResizeHandler);
    }, []);

    useEffect(() => {
        const getActiveFields = async () => {
            const res = await GetCustomFieldsByEntity_OnlyActive(8);
            if (res) setActiveFields(res);
        };
        getActiveFields();
    }, []);

    useEffect(() => {
        const getCurrencies = async () => {
            const res = await GetCurrencies();
            if (res) setCurrencies(res);
        };
        getCurrencies();
    }, []);

    const currency = useMemo(() => {
        if (opportunity) {
            const c = currencies.find(v => v.id === opportunity.currency);
            if (c) return c;
        }
        return null;
    }, [opportunity, currencies]);

    useEffect(() => {
        const getOwners = async () => {
            if (opportunity) {
                const res = await GetOpportunityOwners(opportunity.id);
                if (res) setOwners(res);
            }
        };
        opportunity && opportunity.id && getOwners();
    }, [opportunity]);

    useEffect(() => {
        const getDivs = async () => {
            const res = await GetDivisions();
            if (res) setDivisions(res);
        };
        getDivs();
    }, []);

    const divisionNames = useMemo<string[]>(() => {
        if (opportunity) {
            let names: string[] = [];
            const ids = opportunity.divisions.split(';');
            ids.forEach(d => {
                const item = divisions.find(div => div.id === +d);
                if (item) names.push(item.name);
            });
            return names;
        }
        return [];
    }, [opportunity, divisions]);

    const customFieldsSettingsMap = useMemo<CustomFieldSettingsMap>(() => {
        let obj: CustomFieldSettingsMap = {};
        activeFields.forEach(u => {
            const customFieldNumber = u.name.substring(13);
            const key = 'CustomField' + customFieldNumber as CustomFieldType;
            obj[key] = {
                title: u.agencyName,
                isMultiLine: u.multiLine && !u.usePredefinedValues,
            }
        });
        return obj;
    }, [activeFields]);

    useEffect(() => {
        loadingHandler && loadingHandler(fetchingSavedState);
    }, [loadingHandler, fetchingSavedState]);

    useEffect(() => {
        const getSavedState = async () => {
            setFetchingSavedState(true);
            const elementsJson = await GetCustomerSettingBySettingName(layoutDataSettingName);
            if (elementsJson) {
                const panels = JSON.parse(elementsJson) as PanelModel[];
                setLastSavedState(panels);
            }
            setFetchingSavedState(false);
            setFetchedSavedState(true);
        };
        layoutDataSettingName && getSavedState();
    }, [layoutDataSettingName]);

    const elements = useMemo<OpportunityRecordDashboardElementDefinition[]>(() => {
        if (!fetchedSavedState) return [];
        if (lastSavedState !== null) {
            const elements = GetPanelDefinitionsFromPanelModels(lastSavedState);
            return elements ;
        }
        return defaultElements;
    }, [lastSavedState, fetchedSavedState, defaultElements]);

    const openPreviewHandler = useCallback((type: PreviewEntityType, recordId: number) => {
        setPreviewType(type);
        setPreviewRecordId(recordId);
        setIsPreviewOpen(true);
    }, []);

    const closePreviewHandler = useCallback(() => {
        setIsPreviewOpen(false);
    }, []);

    const renderElement = useCallback((id: string, type: OpportunityRecordDashboardElementType) => {
        if (opportunity) {
            switch (type) {
                case 'Divider': return <Box pt="20px"><Divider component="div" /></Box>;
                case 'Spacer': return <></>;
                case 'OpportunityId': return <SingleFieldElement useEllipsisForLongValues fieldTitle="ID" fieldValue={opportunity.id.toString()} />;
                case 'OpportunityName': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Name" fieldValue={opportunity.name} />;
                case 'OpportunityType': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Type" fieldValue={opportunity.typeName} />;
                case 'OpportunityStatus': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Status" fieldValue={opportunity.statusName} />;
                case 'OpportunityStage': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Stage" fieldValue={opportunity.stageName} />;
                case 'OpportunityValue': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Value" fieldValue={opportunity.value.toFixed(2)} />;
                case 'OpportunityProbability': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Probability (%)" fieldValue={opportunity.probability.toFixed(2)} />;
                case 'OpportunityCurrency': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Currency" fieldValue={currency ? currency.formattedDescription : ''} />;
                case 'OpportunityStartDate': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Start Date" fieldValue={opportunity.startDate} format="date" />;
                case 'OpportunityCloseDate': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Close Date" fieldValue={opportunity.closeDate} format="date" />;
                case 'OpportunityDeadline': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Deadline" fieldValue={opportunity.deadline} format="date" />;
                case 'OpportunityClient': return (
                    <SingleFieldElement
                        fieldTitle='Client'
                        fieldValue={opportunity.clientName}
                        format="internal-link"
                        href={`/clients/${opportunity.clientID}`}
                        previewType="client"
                        previewRecordId={opportunity.clientID}
                        openPreviewHandler={openPreviewHandler}
                        closePreviewHandler={closePreviewHandler}
                    />);
                case 'OpportunityContact': return (
                    <SingleFieldElement
                        fieldTitle='Contact'
                        fieldValue={opportunity.contactName}
                        format="internal-link"
                        href={`/contacts/${opportunity.contactID}`}
                        previewType="contact"
                        previewRecordId={opportunity.contactID}
                        openPreviewHandler={openPreviewHandler}
                        closePreviewHandler={closePreviewHandler}
                    />);
                case 'OpportunityCandidate': return (
                    <SingleFieldElement
                        fieldTitle='Candidate'
                        fieldValue={opportunity.candidateName}
                        format="internal-link"
                        href={`/candidates/${opportunity.candidateID}`}
                        previewType="candidate"
                        previewRecordId={opportunity.candidateID}
                        openPreviewHandler={openPreviewHandler}
                        closePreviewHandler={closePreviewHandler}
                    />);
                case 'OpportunityOwners': return (
                    <SingleFieldElement fieldTitle="Owners" format="custom">
                        <div title={`${owners.length} Owner(s). Click to view list`} onClick={() => setIsOwnersDialogOpen(true)} style={{ cursor: 'pointer', display: 'flex' }}>
                            {owners.map(o => (
                                <Chip key={o.recruiterID} size="small" label={o.fullName} sx={{ mr: '5px' }} />
                            ))}
                        </div>
                    </SingleFieldElement>
                );
                case 'OpportunityDivision': return (
                    <SingleFieldElement fieldTitle="Division" format="custom">
                        {divisionNames.map(d => (
                            <Chip key={d} size="small" label={d} sx={{ mr: '5px' }} />
                        ))}
                    </SingleFieldElement>
                );
                case 'OpportunitySummary': return <MultiLineTextElement title="Summary" content={opportunity.description ?? ''} />;
                default: 
                    if (type.startsWith('CustomField')) {
                        const settings = customFieldsSettingsMap[type];
                        if (settings) {
                            const title = settings.title;
                            const { field, format } = UdfOpportunityFieldMapObj[type as CustomFieldType];
                            const udfValue = opportunity[field];
                            if (format === 'string' || format === 'date' || format === 'datetime') {
                                return <SingleFieldElement isMultiLine={settings.isMultiLine} format={format} fieldTitle={title} fieldValue={udfValue ? udfValue.toString() : null} />;
                            }
                            if (format === 'number') {
                                const numValue = udfValue === undefined || udfValue === null ? '' : udfValue.toString();
                                return <SingleFieldElement fieldTitle={title} fieldValue={numValue} />;
                            }
                        }
                        else return <SingleFieldElement fieldTitle={type} fieldValue="[ Disabled Custom Field ]" />
                    }
                    return <div>{id}</div>;
            }
        }
    }, [opportunity, currency, openPreviewHandler, closePreviewHandler, owners, divisionNames, customFieldsSettingsMap]);

    const layoutResizeStopHandler = useCallback(() => {
        setScreenResizedControl(prev => !prev);
    }, []);

    useEffect(() => {
        if (screenResizedControl) {}
        const api = layoutRef.current;
        if (api && fetchedSavedState) {
            const refreshTimeout = setTimeout(() => {
                api.refresh();
                api.refresh();
            }, 250);
            return () => clearTimeout(refreshTimeout);
        }
    }, [fetchedSavedState, screenResizedControl]);

    const renderLayout = useCallback(() => {
        if (!fetchedSavedState) return <></>;

        const mediaQuery = mediaQueryMaxWidth ? `max-width: ${mediaQueryMaxWidth}` : undefined;
        return (
            <DashboardLayoutComponent
                cellSpacing={cellSpacing}
                columns={columns}
                cellAspectRatio={30 / 2}
                resizeStop={ layoutResizeStopHandler }
                allowDragging={false}
                allowResizing={false}
                ref={l => layoutRef.current = l}
                mediaQuery={mediaQuery}
            >
                {elements.map(e => (
                    <PanelWrapper
                        key={e.id}
                        id={e.id}
                        col={e.col}
                        row={e.row}
                        sizeX={e.sizeX}
                        sizeY={e.sizeY}
                        minSizeX={e.minSizeX}
                        minSizeY={e.minSizeY}
                        maxSizeX={e.maxSizeX}
                        maxSizeY={e.maxSizeY}
                        resizeControl={screenResizedControl}
                        resizeIconColor={theme.palette.text.disabled}
                        gapX={gapX}
                        gapY={gapY}
                        unitWidth={unitWidth}
                        unitHeight={unitHeight}
                    >
                        {renderElement(e.id, e.type)}
                    </PanelWrapper>
                ))}
            </DashboardLayoutComponent>
        );
    }, [elements, fetchedSavedState, theme.palette.text.disabled, screenResizedControl, layoutResizeStopHandler, renderElement]);
    
    return (
        <>
            <PreviewLoaderComponent
                open={isPreviewOpen}
                entityType={previewType}
                recordId={previewRecordId}
            />
            <OwnersListDialog
                type="Opportunity"
                open={isOwnersDialogOpen}
                closeHandler={ () => setIsOwnersDialogOpen(false) }
                owners={owners}
            />
            <Box p="10px" height="100%">
                <div className="control-section">
                    { renderLayout() }
                </div>
            </Box>
        </>
    );
}