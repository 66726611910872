import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Location } from "common/models/Common";
import { GetLocationLookup } from "services/CommonService";
import { GetMySettings } from "services/UsersService";
import CountryPicker from "./CountryPicker";
import Box from "@mui/material/Box";
import { SxProps, Theme } from "@mui/material/styles";

interface Props {
    location: Location | null,
    onLocationChange: (location: Location | null) => void,
    useDefaultCountry?: boolean,
    fullWidth?: boolean,
    countryLabel?: string,
    locationLabel?: string,
    delayMs?: number,
    noContainer?: boolean,
    gap?: number | string,
    sx?: SxProps<Theme>,
    innerSx?: SxProps<Theme>,
}

const defaultLocation: Location = {
    geoNameID: 0,
    name: '',
    countryCode: '',
    latitude: 0,
    longitude: 0,
    location: '',
    state: '',
    country: ''
};

export default function LocationPicker({ location, onLocationChange, countryLabel, useDefaultCountry = false, fullWidth, locationLabel = "Location", noContainer, gap = 2, sx, innerSx, delayMs = 200 }: Props) {
    const [isLoading, setIsLoading] = useState(false);
    const [lookupTerm, setLookupTerm] = useState('');
    const [options, setOptions] = useState<Location[]>([]);
    const [countryCode, setCountryCode] = useState<string | null>(null);

    useEffect(() => {
        if (location) setCountryCode(location.countryCode);
        else setCountryCode(null);
    }, [location]);

    useEffect(() => {
        const getUserLocation = async () => {
            const settings = await GetMySettings();
            if (settings) {
                const countryCode = settings.find(s => s.type === 'DefaultCountry');
                if (countryCode && countryCode.value) setCountryCode(countryCode.value);
            }
        };

        useDefaultCountry && getUserLocation();
    }, [useDefaultCountry]);

    useEffect(() => {
        if (lookupTerm.length > 0 && countryCode) {
            const delaySearch = setTimeout(async () => {
                setIsLoading(true);
                const data = await GetLocationLookup(countryCode, lookupTerm);
                if (data) setOptions(data);
                setIsLoading(false);
            }, delayMs);

            return () => clearTimeout(delaySearch);
        }
    }, [lookupTerm, countryCode, delayMs]);

    const countryChangedCallback = useCallback((code: string | null) => {
        if (code) onLocationChange({...defaultLocation, countryCode: code});
        else onLocationChange(null);
        setLookupTerm('');
    }, [onLocationChange]);

    const changeHandler = useCallback((l: Location | null) => {
        if (l === null && location) onLocationChange({...location, latitude: 0, longitude: 0, geoNameID: 0 });
        else onLocationChange(l);
    }, [location, onLocationChange]);

    const content = useMemo(() => {
        return (
            <>
                <CountryPicker code={countryCode} onSelectCallback={countryChangedCallback} label={countryLabel} fullWidth={fullWidth} sx={innerSx} />
                <Autocomplete
                    size="small"
                    fullWidth={fullWidth}
                    value={location && location.geoNameID ? location : null}
                    options={options}
                    getOptionLabel={o => o.name}
                    isOptionEqualToValue={(o,v) => o.geoNameID === v.geoNameID}
                    loading={isLoading}
                    filterOptions={ x => x }
                    sx={innerSx}
                    renderInput={ params => <TextField {...params} label={locationLabel} inputProps={{ ...params.inputProps, "data-lpignore": "true" }} /> }
                    onInputChange={ (e,val) => setLookupTerm(val) }
                    onChange={ (e,v) => changeHandler(v) }
                    disabled={!countryCode}
                />
            </>
        );
    }, [changeHandler, countryChangedCallback, countryCode, countryLabel, fullWidth, innerSx, isLoading, location, locationLabel, options]);

    if (noContainer) return content;
    return (
        <Box display="flex" gap={gap} sx={sx}>
            {content}
        </Box>
    );
}
