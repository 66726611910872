import { GridCellEditStopParams, GridCellEditStopReasons, GridColDef, GridColumnVisibilityModel, GridRenderCellParams, GridRowSelectionModel, useGridApiRef } from "@mui/x-data-grid-premium";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import GridWithStateWrapper from "../GridWidthStateWrapper";
import { JobCandidateKanbanGroup } from "../../common/models/Jobs/Job";
interface Props {
    kanbanGroups: JobCandidateKanbanGroup[],
    gridName: string,
    handleGroupSelected:(group: JobCandidateKanbanGroup) => void,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void
}


const defaultHiddenCols: GridColumnVisibilityModel = {
    "id": false,
};
const linkStyle: React.CSSProperties = { color: 'inherit', textDecoration: 'underline', cursor: 'pointer' };
export default function JobCandidateKanbanGroupsGrid({ kanbanGroups,handleGroupSelected, loadingHandler, errorHandler, gridName }: Props) {
    const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
    const [rows, setRows] = useState<JobCandidateKanbanGroup[]>([]);
    const [editCellFieldKey, setEditCellFieldKey] = useState<keyof JobCandidateKanbanGroup | null>(null);
    const apiRef = useGridApiRef();

    useEffect(() => {
       if(kanbanGroups) {
        setRows(kanbanGroups);
       }


    }, [kanbanGroups]);
    
    const columns = useMemo<GridColDef[]>(() => {

        const openKanbanGroupDialog = (params: GridRenderCellParams) => {

            if (params.row.id) {
                return <a onClick={() => {handleGroupSelected(params.row)}} style={ linkStyle }>{params.value}</a>;
            }
    }
        const configManagerRenderer = (params: GridRenderCellParams) => {
            if (params.row.enabled == true) {
                return <span>Yes</span>
            }else{
                return <span>No</span>
            }
        }

        let cols: GridColDef[] = [
            { field: 'id', headerName: 'ID'},
            { field: 'name', headerName: 'Name', width: 300, renderCell: openKanbanGroupDialog},
            { field: 'enabled', headerName: 'Enabled', width: 300, renderCell: configManagerRenderer},
        ];

        return cols;
    }, [ apiRef]);


    const cellEditHandler = useCallback((params: GridCellEditStopParams) => {
        if (params.reason === GridCellEditStopReasons.escapeKeyDown) return ;
        setEditCellFieldKey(params.field as keyof JobCandidateKanbanGroup);
    }, []);

    const processRowUpdate = useCallback(async (newRow: JobCandidateKanbanGroup) => {
        setEditCellFieldKey(null);
        return newRow;
    }, [editCellFieldKey, errorHandler, loadingHandler]);

    return (
        <>



            <GridWithStateWrapper
                rows={rows}
                columns={columns}
                apiRef={apiRef}
                density="compact"
                checkboxSelection={false}
                disableRowSelectionOnClick
                disableColumnSorting={true}
                rowSelectionModel={selectionModel}
                onRowSelectionModelChange={ sm => setSelectionModel(sm) }
                pagination
                onCellEditStop={ cellEditHandler }
                processRowUpdate={ processRowUpdate }
                pageSizeOptions={[100,250,500,1000]}
                gridName={gridName}
                defaultViewModel={defaultHiddenCols}
            />
        </>
    );
}