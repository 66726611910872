import { ErrorHandler, RequestOptions } from "../common/models/GenericTypes";
import { ScreeningQuestionsTemplate } from "../common/models/Templates/ScreeningQuestionsTemplate";
import { ReferenceCheckTemplate } from "../common/models/Templates/ReferenceCheckTemplate";
import { Get, Post } from "../util/HttpUtils";
import { WorkflowOrderItem } from "common/models/Workflow";

export async function GetAvailableScreeningQuestionTemplates(userId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<ScreeningQuestionsTemplate[]>(`api/Workflow/Users/${userId}/ScreeningQuestionTemplates`, options);
}

export async function GetAvailableReferenceCheckTemplates(userId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<ReferenceCheckTemplate[]>(`api/Workflow/Users/${userId}/ReferenceCheckTemplates`, options);
}

export async function CreateRefCheck_Referoo(data: unknown, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>('api/Workflow/Referoo/RefCheck', options, data);
}

export async function GetWorkflowItemsOrder(errorHandler?: ErrorHandler) {
    const localSettingName = 'WorkflowItemsOrder';
    const cachedValue = localStorage.getItem(localSettingName);

    if (cachedValue) {
        const values = JSON.parse(cachedValue);
        return values as WorkflowOrderItem[];
    }
    
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    const res = await Get<WorkflowOrderItem[]>('api/Workflow/Order', options);
    if (res) {
        localStorage.setItem(localSettingName, JSON.stringify(res));
        return res;
    }
    return null;
}