import Alert from "components/Alert";
import Snackbar from "@mui/material/Snackbar";
import React, { useEffect, useMemo, useState } from "react";
import EditRecordScreenLayout from "components/ScreenLayouts/Candidates/EditRecordScreenLayout";
import PageContentLayout from "layouts/PageContentLayout";
import PageLayout from "layouts/PageLayout";
import { useSearchParams } from "react-router-dom";
import { GetCandidatesSettings } from "services/ConfigurationService";
import { GetMySettings } from "services/UsersService";

export default function CandidateCreatePage() {
    const [summaryBar, setSummaryBar] = useState(<></>);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [searchParams] = useSearchParams();
    const [defaultCountry, setDefaultCountry] = useState('');
    const [defaultSummary, setDefaultSummary] = useState('');

    const email = useMemo(() => {
        const email = searchParams.get('email');
        if (email) return email;
        return undefined;
    }, [searchParams]);

    useEffect(() => {
        const getCountry = async () => {
            const settings = await GetMySettings();
            if (settings) {
                const countryCode = settings.find(s => s.type === 'DefaultCountry');
                if (countryCode && countryCode.value) setDefaultCountry(countryCode.value);
            }
        };
        getCountry();
    }, []);

    useEffect(() => {
        const getSettings = async () => {
            const settings = await GetCandidatesSettings();
            if (settings && settings.length > 0) {
                const summarySetting = settings.find(s => s.type === 'CandidateDefaultSummary');
                if (summarySetting && summarySetting.value) setDefaultSummary(summarySetting.value);
            }
        };
        getSettings();
    }, []);

    return (
        <PageLayout SummaryBar={summaryBar}>
            <Snackbar open={errorMessage !== ''} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <Snackbar open={successMessage !== ''} autoHideDuration={3000} onClose={() => setSuccessMessage('')} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert color="success" onClose={() => setSuccessMessage('')}>{ successMessage }</Alert>
            </Snackbar>
            <PageContentLayout showLoading={isLoading}>
                <EditRecordScreenLayout
                    defaultCountry={defaultCountry}
                    defaultSummary={defaultSummary}
                    loadingHandler={setIsLoading}
                    setSummaryBar={setSummaryBar}
                    errorHandler={setErrorMessage}
                    successHandler={setSuccessMessage}
                    emailAddress={email}
                />
            </PageContentLayout>
        </PageLayout>
    );
}