import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CancelIcon from '@mui/icons-material/Cancel';
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { TagValue } from "common/models/Configuration/Tags";
import { AddTagsToRecord, BulkAddTags, GetCustomerTagsIncludeStructured, GetMyTagsSettings, GetRecordTags, RemoveTagsFromRecord } from "services/TagsService";
import TagsSelectionList from "../Tags/TagsSelectionList";
import Box from "@mui/material/Box";
import { PREVIEW_TITLE_STYLE } from "util/Definitions/Constants/Previews";
import IconButton from "@mui/material/IconButton";
import UnsavedChangesDialog from "./UnsavedChangesDialog";
import { unstable_batchedUpdates } from "react-dom";

interface Props {
    open: boolean,
    entityId: number,
    recordIds: number[],
    enforceMinRequired?: boolean,
    closeHandler: () => void,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
    successHandler?: (message: string, recordIds: number[], finalTagCount: number) => void,
}

export interface TagGroup2 {
    typeId: number,
    isStructured: boolean,
    title: string,
    color: string,
    minTags: number,
}

const tagTypeColors = {
    "text": '#333333',
    "1": '#8db6fd',
    "2": '#FFFF66',
    "3": '#fdbf96',
    "4": '#66fc66',
    "5": '#ff9df5',
    "6": '#DCDCDC',
    "7": '#B0E0E6',
    "8": '#F5F5DC',
    "9": '#40E0D0',
    "10": '#FFA07A',
}

export default function TagsManagementDialog({ open, entityId, recordIds, enforceMinRequired = false, closeHandler, loadingHandler, errorHandler, successHandler }: Props) {
    const [originalTags, setOriginalTags] = useState<TagValue[]>([]);
    const [tagGroup1, setTagGroup1] = useState<TagGroup2>();
    const [tagGroup2, setTagGroup2] = useState<TagGroup2>();
    const [tagGroup3, setTagGroup3] = useState<TagGroup2>();
    const [tagGroup4, setTagGroup4] = useState<TagGroup2>();
    const [tagGroup5, setTagGroup5] = useState<TagGroup2>();
    const [tagGroup6, setTagGroup6] = useState<TagGroup2>();
    const [tagGroup7, setTagGroup7] = useState<TagGroup2>();
    const [tagGroup8, setTagGroup8] = useState<TagGroup2>();
    const [tagGroup9, setTagGroup9] = useState<TagGroup2>();
    const [tagGroup10, setTagGroup10] = useState<TagGroup2>();
    const [tags1, setTags1] = useState<TagValue[]>([]);
    const [tags2, setTags2] = useState<TagValue[]>([]);
    const [tags3, setTags3] = useState<TagValue[]>([]);
    const [tags4, setTags4] = useState<TagValue[]>([]);
    const [tags5, setTags5] = useState<TagValue[]>([]);
    const [tags6, setTags6] = useState<TagValue[]>([]);
    const [tags7, setTags7] = useState<TagValue[]>([]);
    const [tags8, setTags8] = useState<TagValue[]>([]);
    const [tags9, setTags9] = useState<TagValue[]>([]);
    const [tags10, setTags10] = useState<TagValue[]>([]);
    const [assigned1, setAssigned1] = useState<TagValue[]>([]);
    const [assigned2, setAssigned2] = useState<TagValue[]>([]);
    const [assigned3, setAssigned3] = useState<TagValue[]>([]);
    const [assigned4, setAssigned4] = useState<TagValue[]>([]);
    const [assigned5, setAssigned5] = useState<TagValue[]>([]);
    const [assigned6, setAssigned6] = useState<TagValue[]>([]);
    const [assigned7, setAssigned7] = useState<TagValue[]>([]);
    const [assigned8, setAssigned8] = useState<TagValue[]>([]);
    const [assigned9, setAssigned9] = useState<TagValue[]>([]);
    const [assigned10, setAssigned10] = useState<TagValue[]>([]);
    const [showUnsavedChangesDialog, setShowUnsavedChangesDialog] = useState(false);

    useEffect(() => {
        if (!open) {
            unstable_batchedUpdates(() => {
                setOriginalTags([]);
                setTagGroup1(undefined);
                setTagGroup2(undefined);
                setTagGroup3(undefined);
                setTagGroup4(undefined);
                setTagGroup5(undefined);
                setTagGroup6(undefined);
                setTagGroup7(undefined);
                setTagGroup8(undefined);
                setTagGroup9(undefined);
                setTagGroup10(undefined);
                setTags1([]);
                setTags2([]);
                setTags3([]);
                setTags4([]);
                setTags5([]);
                setTags6([]);
                setTags7([]);
                setTags8([]);
                setTags9([]);
                setTags10([]);
                setAssigned1([]);
                setAssigned2([]);
                setAssigned3([]);
                setAssigned4([]);
                setAssigned5([]);
                setAssigned6([]);
                setAssigned7([]);
                setAssigned8([]);
                setAssigned9([]);
                setAssigned10([]);
            });
        }
    }, [open]);

    useEffect(() => {
        const getData = async () => {
            loadingHandler && loadingHandler(true);
            const fetchedSettings = await GetMyTagsSettings(entityId, true, errorHandler);
            const fetchedAvailableTags = await GetCustomerTagsIncludeStructured(entityId, errorHandler);
            let fetchedAssignedTags: TagValue[] | null = [];
            if (recordIds.length === 1) fetchedAssignedTags = await GetRecordTags(entityId, recordIds[0], errorHandler);

            let tg1: TagGroup2 | null = null;
            let tg2: TagGroup2 | null = null;
            let tg3: TagGroup2 | null = null;
            let tg4: TagGroup2 | null = null;
            let tg5: TagGroup2 | null = null;
            let tg6: TagGroup2 | null = null;
            let tg7: TagGroup2 | null = null;
            let tg8: TagGroup2 | null = null;
            let tg9: TagGroup2 | null = null;
            let tg10: TagGroup2 | null = null;

            if (fetchedSettings) {
                for (let i = 0; i < fetchedSettings.length; i++) {
                    const setting = fetchedSettings[i];
                    const title = setting.tagAgencyName !== '' ? setting.tagAgencyName : setting.databaseName;
                    if (setting.tagTypeId === 1) tg1 = { typeId: setting.tagTypeId, isStructured: setting.structured, title, color: tagTypeColors[1], minTags: setting.minTags };
                    else if (setting.tagTypeId === 2) tg2 = { typeId: setting.tagTypeId, isStructured: setting.structured, title, color: tagTypeColors[2], minTags: setting.minTags };
                    else if (setting.tagTypeId === 3) tg3 = { typeId: setting.tagTypeId, isStructured: setting.structured, title, color: tagTypeColors[3], minTags: setting.minTags };
                    else if (setting.tagTypeId === 4) tg4 = { typeId: setting.tagTypeId, isStructured: setting.structured, title, color: tagTypeColors[4], minTags: setting.minTags };
                    else if (setting.tagTypeId === 5) tg5 = { typeId: setting.tagTypeId, isStructured: setting.structured, title, color: tagTypeColors[5], minTags: setting.minTags };
                    else if (setting.tagTypeId === 6) tg6 = { typeId: setting.tagTypeId, isStructured: setting.structured, title, color: tagTypeColors[6], minTags: setting.minTags };
                    else if (setting.tagTypeId === 7) tg7 = { typeId: setting.tagTypeId, isStructured: setting.structured, title, color: tagTypeColors[7], minTags: setting.minTags };
                    else if (setting.tagTypeId === 8) tg8 = { typeId: setting.tagTypeId, isStructured: setting.structured, title, color: tagTypeColors[8], minTags: setting.minTags };
                    else if (setting.tagTypeId === 9) tg9 = { typeId: setting.tagTypeId, isStructured: setting.structured, title, color: tagTypeColors[9], minTags: setting.minTags };
                    else if (setting.tagTypeId === 10) tg10 = { typeId: setting.tagTypeId, isStructured: setting.structured, title, color: tagTypeColors[10], minTags: setting.minTags };
                }
            }

            if (fetchedAvailableTags) {
                let t1: TagValue[] = [];
                let t2: TagValue[] = [];
                let t3: TagValue[] = [];
                let t4: TagValue[] = [];
                let t5: TagValue[] = [];
                let t6: TagValue[] = [];
                let t7: TagValue[] = [];
                let t8: TagValue[] = [];
                let t9: TagValue[] = [];
                let t10: TagValue[] = [];
                for (let i = 0; i < fetchedAvailableTags.length; i++) {
                    const tag = fetchedAvailableTags[i];
                    if (tag.tagTypeID === 1) t1.push(tag);
                    else if (tag.tagTypeID === 2) t2.push(tag);
                    else if (tag.tagTypeID === 3) t3.push(tag);
                    else if (tag.tagTypeID === 4) t4.push(tag);
                    else if (tag.tagTypeID === 5) t5.push(tag);
                    else if (tag.tagTypeID === 6) t6.push(tag);
                    else if (tag.tagTypeID === 7) t7.push(tag);
                    else if (tag.tagTypeID === 8) t8.push(tag);
                    else if (tag.tagTypeID === 9) t9.push(tag);
                    else if (tag.tagTypeID === 10) t10.push(tag);
                }

                let at1: TagValue[] = [];
                let at2: TagValue[] = [];
                let at3: TagValue[] = [];
                let at4: TagValue[] = [];
                let at5: TagValue[] = [];
                let at6: TagValue[] = [];
                let at7: TagValue[] = [];
                let at8: TagValue[] = [];
                let at9: TagValue[] = [];
                let at10: TagValue[] = [];

                if (fetchedAssignedTags) {
                    for (let i = 0; i < fetchedAssignedTags.length; i++) {
                        const tag = fetchedAssignedTags[i];
                        if (tag.tagTypeID === 1) at1.push(tag);
                        else if (tag.tagTypeID === 2) at2.push(tag);
                        else if (tag.tagTypeID === 3) at3.push(tag);
                        else if (tag.tagTypeID === 4) at4.push(tag);
                        else if (tag.tagTypeID === 5) at5.push(tag);
                        else if (tag.tagTypeID === 6) at6.push(tag);
                        else if (tag.tagTypeID === 7) at7.push(tag);
                        else if (tag.tagTypeID === 8) at8.push(tag);
                        else if (tag.tagTypeID === 9) at9.push(tag);
                        else if (tag.tagTypeID === 10) at10.push(tag);
                    }
                }

                unstable_batchedUpdates(() => {
                    tg1 && setTagGroup1(tg1);
                    tg2 && setTagGroup2(tg2);
                    tg3 && setTagGroup3(tg3);
                    tg4 && setTagGroup4(tg4);
                    tg5 && setTagGroup5(tg5);
                    tg6 && setTagGroup6(tg6);
                    tg7 && setTagGroup7(tg7);
                    tg8 && setTagGroup8(tg8);
                    tg9 && setTagGroup9(tg9);
                    tg10 && setTagGroup10(tg10);

                    t1.length > 0 && setTags1(t1);
                    t2.length > 0 && setTags2(t2);
                    t3.length > 0 && setTags3(t3);
                    t4.length > 0 && setTags4(t4);
                    t5.length > 0 && setTags5(t5);
                    t6.length > 0 && setTags6(t6);
                    t7.length > 0 && setTags7(t7);
                    t8.length > 0 && setTags8(t8);
                    t9.length > 0 && setTags9(t9);
                    t10.length > 0 && setTags10(t10);

                    at1.length > 0 && setAssigned1(at1);
                    at2.length > 0 && setAssigned2(at2);
                    at3.length > 0 && setAssigned3(at3);
                    at4.length > 0 && setAssigned4(at4);
                    at5.length > 0 && setAssigned5(at5);
                    at6.length > 0 && setAssigned6(at6);
                    at7.length > 0 && setAssigned7(at7);
                    at8.length > 0 && setAssigned8(at8);
                    at9.length > 0 && setAssigned9(at9);
                    at10.length > 0 && setAssigned10(at10);
                    
                    if (fetchedAssignedTags) {
                        setOriginalTags(fetchedAssignedTags);
                    }
                })
            }

            loadingHandler && loadingHandler(false);
        };

        open && getData();
    }, [open, entityId, recordIds, loadingHandler, errorHandler]);

    const hasChanges = useMemo(() => {
        const assignedTags = [...assigned1, ...assigned2, ...assigned3, ...assigned4, ...assigned5, ...assigned6, ...assigned7, ...assigned8, ...assigned9, ...assigned10 ];
        if (originalTags.length !== assignedTags.length) return true;
        for (let i = 0; i < assignedTags.length; i++) {
            const tag = assignedTags[i];
            const index = originalTags.findIndex(t => t.tagTypeID === tag.tagTypeID && t.tag === tag.tag);
            if (index === -1) return true;
        }
        return false;
    }, [assigned1, assigned10, assigned2, assigned3, assigned4, assigned5, assigned6, assigned7, assigned8, assigned9, originalTags]);

    const removeTagSetStateFunction = useCallback((prev: TagValue[], tag: TagValue) => {
        const index = prev.findIndex(t => t.tagTypeID === tag.tagTypeID && t.tag === tag.tag);
        if (index !== -1) {
            let tmp = [...prev];
            tmp.splice(index, 1);
            return tmp;
        }
        return prev;
    }, []);

    const handleRemoveCallback = useCallback((data: TagValue) => {
        if (data.tagTypeID === 1) setAssigned1(prev => removeTagSetStateFunction(prev, data));
        else if (data.tagTypeID === 2) setAssigned2(prev => removeTagSetStateFunction(prev, data));
        else if (data.tagTypeID === 3) setAssigned3(prev => removeTagSetStateFunction(prev, data));
        else if (data.tagTypeID === 4) setAssigned4(prev => removeTagSetStateFunction(prev, data));
        else if (data.tagTypeID === 5) setAssigned5(prev => removeTagSetStateFunction(prev, data));
        else if (data.tagTypeID === 6) setAssigned6(prev => removeTagSetStateFunction(prev, data));
        else if (data.tagTypeID === 7) setAssigned7(prev => removeTagSetStateFunction(prev, data));
        else if (data.tagTypeID === 8) setAssigned8(prev => removeTagSetStateFunction(prev, data));
        else if (data.tagTypeID === 9) setAssigned9(prev => removeTagSetStateFunction(prev, data));
        else if (data.tagTypeID === 10) setAssigned10(prev => removeTagSetStateFunction(prev, data));
    }, [removeTagSetStateFunction]);

    const addTagSetStateFunction = useCallback((prev: TagValue[], availableTags: TagValue[], tag: string) => {
        const availableTag = availableTags.find(t => t.tag === tag);
        if (availableTag) return [...prev, availableTag];
        return prev;
    }, []);

    const handleAddCallback = useCallback((typeId: number, tag: string) => {
        if (typeId === 1) setAssigned1(prev => addTagSetStateFunction(prev, tags1, tag));
        else if (typeId === 2) setAssigned2(prev => addTagSetStateFunction(prev, tags2, tag));
        else if (typeId === 3) setAssigned3(prev => addTagSetStateFunction(prev, tags3, tag));
        else if (typeId === 4) setAssigned4(prev => addTagSetStateFunction(prev, tags4, tag));
        else if (typeId === 5) setAssigned5(prev => addTagSetStateFunction(prev, tags5, tag));
        else if (typeId === 6) setAssigned6(prev => addTagSetStateFunction(prev, tags6, tag));
        else if (typeId === 7) setAssigned7(prev => addTagSetStateFunction(prev, tags7, tag));
        else if (typeId === 8) setAssigned8(prev => addTagSetStateFunction(prev, tags8, tag));
        else if (typeId === 9) setAssigned9(prev => addTagSetStateFunction(prev, tags9, tag));
        else if (typeId === 10) setAssigned10(prev => addTagSetStateFunction(prev, tags10, tag));
    }, [addTagSetStateFunction, tags1, tags10, tags2, tags3, tags4, tags5, tags6, tags7, tags8, tags9]);

    const createTagSetStateFunction = useCallback((prev: TagValue[], tag: TagValue) => {
        const i = prev.findIndex(t => t.tag === tag.tag);
        if (i === -1) {
            let tmp = [...prev];
            tmp.push(tag);
            if (tag.tagTypeID === 1) setAssigned1(prev => [...prev, tag]);
            else if (tag.tagTypeID === 2) setAssigned2(prev => [...prev, tag]);
            else if (tag.tagTypeID === 3) setAssigned3(prev => [...prev, tag]);
            else if (tag.tagTypeID === 4) setAssigned4(prev => [...prev, tag]);
            else if (tag.tagTypeID === 5) setAssigned5(prev => [...prev, tag]);
            else if (tag.tagTypeID === 6) setAssigned6(prev => [...prev, tag]);
            else if (tag.tagTypeID === 7) setAssigned7(prev => [...prev, tag]);
            else if (tag.tagTypeID === 8) setAssigned8(prev => [...prev, tag]);
            else if (tag.tagTypeID === 9) setAssigned9(prev => [...prev, tag]);
            else if (tag.tagTypeID === 10) setAssigned10(prev => [...prev, tag]);
            return tmp;
        }
        return prev;
    }, []);

    const handleCreateTagCallback = useCallback((tag: TagValue) => {
        if (tag.tagTypeID === 1) setTags1(prev => createTagSetStateFunction(prev, tag));
        else if (tag.tagTypeID === 2) setTags2(prev => createTagSetStateFunction(prev, tag));
        else if (tag.tagTypeID === 3) setTags3(prev => createTagSetStateFunction(prev, tag));
        else if (tag.tagTypeID === 4) setTags4(prev => createTagSetStateFunction(prev, tag));
        else if (tag.tagTypeID === 5) setTags5(prev => createTagSetStateFunction(prev, tag));
        else if (tag.tagTypeID === 6) setTags6(prev => createTagSetStateFunction(prev, tag));
        else if (tag.tagTypeID === 7) setTags7(prev => createTagSetStateFunction(prev, tag));
        else if (tag.tagTypeID === 8) setTags8(prev => createTagSetStateFunction(prev, tag));
        else if (tag.tagTypeID === 9) setTags9(prev => createTagSetStateFunction(prev, tag));
        else if (tag.tagTypeID === 10) setTags10(prev => createTagSetStateFunction(prev, tag));
    }, [createTagSetStateFunction]);

    const hasMinRequiredTags = useMemo(() => {
        if (enforceMinRequired) {
            if (tagGroup1 && tagGroup1.minTags > 0 && assigned1.length < tagGroup1.minTags) return false;
            if (tagGroup2 && tagGroup2.minTags > 0 && assigned2.length < tagGroup2.minTags) return false;
            if (tagGroup3 && tagGroup3.minTags > 0 && assigned3.length < tagGroup3.minTags) return false;
            if (tagGroup4 && tagGroup4.minTags > 0 && assigned4.length < tagGroup4.minTags) return false;
            if (tagGroup5 && tagGroup5.minTags > 0 && assigned5.length < tagGroup5.minTags) return false;
            if (tagGroup6 && tagGroup6.minTags > 0 && assigned6.length < tagGroup6.minTags) return false;
            if (tagGroup7 && tagGroup7.minTags > 0 && assigned7.length < tagGroup7.minTags) return false;
            if (tagGroup8 && tagGroup8.minTags > 0 && assigned8.length < tagGroup8.minTags) return false;
            if (tagGroup9 && tagGroup9.minTags > 0 && assigned9.length < tagGroup9.minTags) return false;
            if (tagGroup10 && tagGroup10.minTags > 0 && assigned10.length < tagGroup10.minTags) return false;
        }
        return true;
    }, [assigned1.length, assigned10.length, assigned2.length, assigned3.length, assigned4.length, assigned5.length, assigned6.length, assigned7.length, assigned8.length, assigned9.length, enforceMinRequired, tagGroup1, tagGroup10, tagGroup2, tagGroup3, tagGroup4, tagGroup5, tagGroup6, tagGroup7, tagGroup8, tagGroup9]);

    const saveChangesHandlerCallback = useCallback(async () => {
        if (enforceMinRequired && !hasMinRequiredTags) {
            errorHandler && errorHandler('Please make sure to include the minimum required on each tag group');
            return;
        }
        
        loadingHandler && loadingHandler(true);
        setShowUnsavedChangesDialog(false);
        const assignedTags = [...assigned1, ...assigned2, ...assigned3, ...assigned4, ...assigned5, ...assigned6, ...assigned7, ...assigned8, ...assigned9, ...assigned10];
        if (recordIds.length === 1) {
            const recordId = recordIds[0];
            let removedTags: TagValue[] = [];
            for (let i = 0; i < originalTags.length; i++) {
                const tag = originalTags[i];
                const index = assignedTags.findIndex(t => t.tagTypeID === tag.tagTypeID && t.tag === tag.tag);
                if (index === -1) removedTags.push(tag);
            }
    
            let addTags = [...assignedTags];
            for (let i = 0; i < originalTags.length; i++) {
                const tag = originalTags[i];
                const index = addTags.findIndex(t => t.tagTypeID === tag.tagTypeID && t.tag === tag.tag);
                if (index !== -1) addTags.splice(index, 1);
            }
    
            let removedSuccess: boolean | null = true;
            if (removedTags.length > 0) {
                removedSuccess = await RemoveTagsFromRecord(entityId, recordId, removedTags, errorHandler);
            }
    
            let addSuccess: boolean | null = true;
            if (addTags.length > 0) {
                addSuccess = await AddTagsToRecord(entityId, recordId, addTags, errorHandler);
            }
    
            if (removedSuccess && addSuccess) {
                successHandler && successHandler('Changes Saved', recordIds, assignedTags.length);
                closeHandler();
            }
        }
        else if (recordIds.length > 1) {
            const res = await BulkAddTags(entityId, recordIds, assignedTags);
            if (res) {
                successHandler && successHandler('Tagged Records', recordIds, assignedTags.length);
                closeHandler();
            }
        }

        loadingHandler && loadingHandler(false);
    }, [enforceMinRequired, hasMinRequiredTags, loadingHandler, assigned1, assigned2, assigned3, assigned4, assigned5, assigned6, assigned7, assigned8, assigned9, assigned10, recordIds, errorHandler, originalTags, entityId, successHandler, closeHandler]);

    const closeWithChangesHandlerCallback = useCallback(() => {
        if (hasChanges) {
            setShowUnsavedChangesDialog(true);
            return;
        }
        closeHandler();
    }, [hasChanges, closeHandler]);

    const discardChangesHandlerCallback = useCallback(() => {
        setShowUnsavedChangesDialog(false);
        closeHandler();
    }, [closeHandler]);

    const renderAssignedTag = useCallback((t: TagValue, i: number) => {
        return (
            <Chip
                key={`${i}-${t.tagTypeID}-${t.tag}`}
                size="small"
                label={t.tag}
                onDelete={ () => handleRemoveCallback(t) }
                sx={{ bgcolor: (tagTypeColors as any)[t.tagTypeID], color: tagTypeColors.text, mr: '5px' }}
                deleteIcon={ <CancelIcon style={{ color: tagTypeColors.text }} /> }
            />
        );
    }, [handleRemoveCallback]);

    return (
        <>
            <UnsavedChangesDialog
                open={ showUnsavedChangesDialog }
                saveChangesHandler={ saveChangesHandlerCallback }
                discardChangesHandler={ discardChangesHandlerCallback }
                cancelHandler={ () => setShowUnsavedChangesDialog(false) }
                message="Any changes you have made will be lost. Are you sure you wish to continue?"
            />
            <Dialog open={open} maxWidth={false} fullWidth>
                <DialogTitle component="div" display="flex" sx={ PREVIEW_TITLE_STYLE }>
                    <div style={{ alignSelf: 'center' }}>{ recordIds.length > 1 ? "Bulk Tagging" : "Add / Remove Tags" }</div>
                    <div style={{ marginLeft: 'auto' }}>
                        <IconButton size="small" onClick={ closeWithChangesHandlerCallback } sx={{ color: t => t.palette.primary.contrastText }} ><CancelIcon /></IconButton>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <Box display="flex" flexWrap="wrap" justifyContent="center" paddingTop={1} >
                        { tagGroup1 &&
                            <TagsSelectionList
                                typeId={tagGroup1.typeId}
                                isStructured={tagGroup1.isStructured}
                                title={tagGroup1.title}
                                availableTags={tags1}
                                assignedTags={assigned1}
                                color={tagGroup1.color}
                                textColor={tagTypeColors.text}
                                minRequired={tagGroup1.minTags}
                                addHandler={handleAddCallback}
                                removeHandler={handleRemoveCallback}
                                createTagHandler={handleCreateTagCallback}
                            />
                        }
                        { tagGroup2 &&
                            <TagsSelectionList
                                typeId={tagGroup2.typeId}
                                isStructured={tagGroup2.isStructured}
                                title={tagGroup2.title}
                                availableTags={tags2}
                                assignedTags={assigned2}
                                color={tagGroup2.color}
                                textColor={tagTypeColors.text}
                                minRequired={tagGroup2.minTags}
                                addHandler={handleAddCallback}
                                removeHandler={handleRemoveCallback}
                                createTagHandler={handleCreateTagCallback}
                            />
                        }
                        { tagGroup3 &&
                            <TagsSelectionList
                                typeId={tagGroup3.typeId}
                                isStructured={tagGroup3.isStructured}
                                title={tagGroup3.title}
                                availableTags={tags3}
                                assignedTags={assigned3}
                                color={tagGroup3.color}
                                textColor={tagTypeColors.text}
                                minRequired={tagGroup3.minTags}
                                addHandler={handleAddCallback}
                                removeHandler={handleRemoveCallback}
                                createTagHandler={handleCreateTagCallback}
                            />
                        }
                        { tagGroup4 &&
                            <TagsSelectionList
                                typeId={tagGroup4.typeId}
                                isStructured={tagGroup4.isStructured}
                                title={tagGroup4.title}
                                availableTags={tags4}
                                assignedTags={assigned4}
                                color={tagGroup4.color}
                                textColor={tagTypeColors.text}
                                minRequired={tagGroup4.minTags}
                                addHandler={handleAddCallback}
                                removeHandler={handleRemoveCallback}
                                createTagHandler={handleCreateTagCallback}
                            />
                        }
                        { tagGroup5 &&
                            <TagsSelectionList
                                typeId={tagGroup5.typeId}
                                isStructured={tagGroup5.isStructured}
                                title={tagGroup5.title}
                                availableTags={tags5}
                                assignedTags={assigned5}
                                color={tagGroup5.color}
                                textColor={tagTypeColors.text}
                                minRequired={tagGroup5.minTags}
                                addHandler={handleAddCallback}
                                removeHandler={handleRemoveCallback}
                                createTagHandler={handleCreateTagCallback}
                            />
                        }
                        { tagGroup6 &&
                            <TagsSelectionList
                                typeId={tagGroup6.typeId}
                                isStructured={tagGroup6.isStructured}
                                title={tagGroup6.title}
                                availableTags={tags6}
                                assignedTags={assigned6}
                                color={tagGroup6.color}
                                textColor={tagTypeColors.text}
                                minRequired={tagGroup6.minTags}
                                addHandler={handleAddCallback}
                                removeHandler={handleRemoveCallback}
                                createTagHandler={handleCreateTagCallback}
                            />
                        }
                        { tagGroup7 &&
                            <TagsSelectionList
                                typeId={tagGroup7.typeId}
                                isStructured={tagGroup7.isStructured}
                                title={tagGroup7.title}
                                availableTags={tags7}
                                assignedTags={assigned7}
                                color={tagGroup7.color}
                                textColor={tagTypeColors.text}
                                minRequired={tagGroup7.minTags}
                                addHandler={handleAddCallback}
                                removeHandler={handleRemoveCallback}
                                createTagHandler={handleCreateTagCallback}
                            />
                        }
                        { tagGroup8 &&
                            <TagsSelectionList
                                typeId={tagGroup8.typeId}
                                isStructured={tagGroup8.isStructured}
                                title={tagGroup8.title}
                                availableTags={tags8}
                                assignedTags={assigned8}
                                color={tagGroup8.color}
                                textColor={tagTypeColors.text}
                                minRequired={tagGroup8.minTags}
                                addHandler={handleAddCallback}
                                removeHandler={handleRemoveCallback}
                                createTagHandler={handleCreateTagCallback}
                            />
                        }
                        { tagGroup9 &&
                            <TagsSelectionList
                                typeId={tagGroup9.typeId}
                                isStructured={tagGroup9.isStructured}
                                title={tagGroup9.title}
                                availableTags={tags9}
                                assignedTags={assigned9}
                                color={tagGroup9.color}
                                textColor={tagTypeColors.text}
                                minRequired={tagGroup9.minTags}
                                addHandler={handleAddCallback}
                                removeHandler={handleRemoveCallback}
                                createTagHandler={handleCreateTagCallback}
                            />
                        }
                        { tagGroup10 &&
                            <TagsSelectionList
                                typeId={tagGroup10.typeId}
                                isStructured={tagGroup10.isStructured}
                                title={tagGroup10.title}
                                availableTags={tags10}
                                assignedTags={assigned10}
                                color={tagGroup10.color}
                                textColor={tagTypeColors.text}
                                minRequired={tagGroup10.minTags}
                                addHandler={handleAddCallback}
                                removeHandler={handleRemoveCallback}
                                createTagHandler={handleCreateTagCallback}
                            />
                        }
                    </Box>
                    <div style={{ paddingTop: '10px' }}>
                        { assigned1.map(renderAssignedTag) }
                        { assigned2.map(renderAssignedTag) }
                        { assigned3.map(renderAssignedTag) }
                        { assigned4.map(renderAssignedTag) }
                        { assigned5.map(renderAssignedTag) }
                        { assigned6.map(renderAssignedTag) }
                        { assigned7.map(renderAssignedTag) }
                        { assigned8.map(renderAssignedTag) }
                        { assigned9.map(renderAssignedTag) }
                        { assigned10.map(renderAssignedTag) }
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="success" onClick={ saveChangesHandlerCallback } disabled={ !hasChanges } >Save</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}