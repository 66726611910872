import Box from "@mui/material/Box";
import { SeekBranding, SeekJobCategory } from "common/models/JobPosting/Seek";
import React, { useEffect, useMemo, useState } from "react";
import { GetSeekBoardSettingsData, GetSeekBrandings, GetSeekCategories } from "services/SeekService";
import JobBoardViewField from "./JobBoardViewField";
import SeekBrandingCard from "components/Pickers/SeekBrandingCard";

interface Props {
    jobBoardId: number,
    advertId: number,
    attributes?: Record<string, string>,
    advertRecordAttributes?: Record<string, string>,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
}

export default function SeekView({ jobBoardId, advertId, attributes, advertRecordAttributes, loadingHandler, errorHandler }: Props) {
    const [hirerId, setHirerId] = useState('');
    const [categories, setCategories] = useState<SeekJobCategory[]>([]);
    const [brandings, setBrandings] = useState<SeekBranding[]>([]);
    const [categorySuggestionType, setCategorySuggestionType] = useState('');

    useEffect(() => {
        const getSetupData = async () => {
            loadingHandler && loadingHandler(true);
            const res = await GetSeekBoardSettingsData(jobBoardId);
            if (res && res.hirerId) setHirerId(res.hirerId);
            loadingHandler && loadingHandler(false);
        };
        getSetupData();
    }, [advertId, errorHandler, jobBoardId, loadingHandler]);

    useEffect(() => {
        const getCategories = async () => {
            const res = await GetSeekCategories();
            if (res) setCategories(res);
        };
        getCategories();
    }, []);

    useEffect(() => {
        const getBranding = async () => {
            const res = await GetSeekBrandings(hirerId);
            if (res) setBrandings(res.map(r => r.node));
        };
        Boolean(hirerId) && getBranding();
    }, [hirerId]);

    const atts = useMemo<Record<string, string>>(() => {
        if (attributes) return attributes;
        return {};
    }, [attributes]);

    const locationName = useMemo(() => atts.LocationName ?? '', [atts.LocationName]);
    const category = useMemo(() => atts.Category ?? '', [atts.Category]);
    const subCategory = useMemo(() => atts.SubCategory ?? '', [atts.SubCategory]);
    const billingReference = useMemo(() => atts.BillingReference ?? '', [atts.BillingReference]);
    const branding = useMemo(() => atts.Branding ?? '', [atts.Branding]);
    const seekUrl = useMemo(() => atts.SeekPositionProfileUri ?? '', [atts.SeekPositionProfileUri]);

    useEffect(() => {
        if (categorySuggestionType === '' && category) setCategorySuggestionType('custom');
    }, [category, categorySuggestionType]);

    const cat1 = useMemo(() => {
        if (category && categories.length > 0) {
            const cat = categories.find(c => c.id.value === category);
            if (cat) return cat;
        }

        return null;
    }, [categories, category]);

    const cat2 = useMemo(() => {
        if (subCategory && cat1 && cat1.children && cat1.children.length > 0) {
            const cat = cat1.children.find(c => c.id.value === subCategory);
            if (cat) return cat;
        }

        return null;
    }, [cat1, subCategory]);

    const brandingObj = useMemo(() => {
        if (branding && brandings.length > 0) {
            const br = brandings.find(b => b.id.value === branding);
            if (br) return br;
        }
        return null;
    }, [branding, brandings]);

    return (
        <Box display="flex" flexDirection="column" gap={2} pt={2}>
            { Boolean(seekUrl) && <a href={seekUrl} rel="noopener noreferrer" target="_blank" style={{ color: 'inherit', fontWeight: 'bold', textAlign: 'right' }}>Click to view on SEEK</a> }
            <JobBoardViewField label="Location (Suburb or town)" value={locationName} />
            <JobBoardViewField label="Category" value={cat1 ? cat1.name : ''} />
            <JobBoardViewField label="Sub-Category" value={cat2 ? cat2.name : ''} />
            <JobBoardViewField label="Billing Reference (Optional)" value={billingReference} />
            { brandingObj &&
                <>
                    <JobBoardViewField label="Branding" value={brandingObj.name} />
                    <Box display="flex" flexWrap="wrap" justifyContent="center" minHeight="186px" sx={{ pointerEvents: 'none' }}> 
                        <SeekBrandingCard isSelected brandingData={brandingObj} />
                    </Box>
                </>
            }
        </Box>
    );
}