import Alert from "components/Alert";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Snackbar from "@mui/material/Snackbar";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-premium";
import { DataGridPremium } from "@mui/x-data-grid-premium/DataGridPremium";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { ActionPermissionsGridRows, FeaturePermissionsGridRows } from "common/data/Permissions/GridTableRows";
import { StringValueWrapper } from "common/models/GenericTypes";
import { ChangeTracker } from "common/models/hooks/ChangeTracker";
import { Permission } from "common/models/Permissions";
import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import useObjectStateWithChangeTracker from "hooks/UseObjectStateWithChangeTracker";
import useUnsavedChangesDialog from "hooks/UseUnsavedChangesDialog";
import PageContentLayout from "layouts/PageContentLayout";
import { GetUserPermissions, UpdateUserPermissions } from "services/UsersService";

interface Props {
    setSummaryBar?: (summaryBar: JSX.Element) => void,
    userName?: string
}

const defaultStringValue: StringValueWrapper = { value: '' };
const noChangesStringValue: ChangeTracker<StringValueWrapper> = { value: false };

const UsersLink = <Link to="/configuration/users" style={{ color: 'inherit', textDecoration: 'underline' }}>Users</Link>;

export default function UserPermissions({ setSummaryBar, userName }: Props) {
    const [isLoading, setIsLoading] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [permissions, setPermissions] = useState<Permission[]>([]);
    const { init, change, updateInitial, hasChanges } = useObjectStateWithChangeTracker<StringValueWrapper>(defaultStringValue, noChangesStringValue);
    const params = useParams();

    const userId = useMemo(() => {
        const parsedId = +(params.userId ?? '0');
        if(isNaN(parsedId)) return 0;
        return parsedId;
    }, [params.userId]);

    const saveHandler = useCallback(async () => {
        setIsLoading(true);
        const res = UpdateUserPermissions(userId, permissions, setErrorMessage);
        if (!res) {
            setIsLoading(false);
            return false;
        }
        setShowSuccess(true);
        updateInitial();
        setIsLoading(false);
        return true;
    }, [permissions, updateInitial, userId]);

    useEffect(() => {
        const action = <Button variant="contained" color="success" onClick={ saveHandler } disabled={!hasChanges} >Save</Button>;
        const userNameText = userName ? userName + ' > ' : '';
        const title = <> {'Configuration > '} {UsersLink} {' > ' + userNameText + 'Permissions'} </>;
        const tabTitle = userNameText + 'Users > Configuration > Recruit Wizard';
        const summaryBar = <TitleAndActionSummaryBar title={ title } browserTabTitle={ tabTitle } action={action} />;
        setSummaryBar && setSummaryBar(summaryBar);
    }, [setSummaryBar, userName, saveHandler, hasChanges]);

    useEffect(() => {
        const getData = async () => {
            setIsLoading(true);
            const data = await GetUserPermissions(userId);
            if (data) {
                setPermissions(data);
                let sorted = [...data].sort();
                init({ value: JSON.stringify(sorted) });
            }
            setIsLoading(false);
        };
        getData();
    }, [userId, init]);

    useEffect(() => {
        let sorted = [...permissions].sort();
        change('value', JSON.stringify(sorted));
    }, [change, permissions]);

    const handleChanges = (index: number, value: number) => {
        if (index === -1) {
            setPermissions(prev => [...prev, value]);
        }
        else {
            setPermissions(prev => {
                let newP = [...prev];
                newP.splice(index, 1);
                return newP;
            });
        }
    };

    const actionPermissionsGridGolumns = useMemo<GridColDef[]>(() => {
        const permissionCellRenderer = (params: GridRenderCellParams) => {
            if (params.value === null) return 'N/A';
            const index = permissions.indexOf(params.value);
            const value = +params.value;
            return <Checkbox disableTouchRipple checked={index !== -1} value={value} onChange={ () => handleChanges(index, value) } />;
        };

        return [
            { field: 'module', headerName: 'Module', width: 150, sortable: false },
            { field: 'access', headerName: 'Access', align: 'center', sortable: false, renderCell: permissionCellRenderer },
            { field: 'addEdit', headerName: 'Add / Edit', align: 'center', sortable: false, renderCell: permissionCellRenderer },
            { field: 'delete', headerName: 'Delete', align: 'center', sortable: false, renderCell: permissionCellRenderer },
            { field: 'export', headerName: 'Export', align: 'center', sortable: false, renderCell: permissionCellRenderer },
            { field: 'reports', headerName: 'Reports', align: 'center', sortable: false, renderCell: permissionCellRenderer },
        ];
    }, [permissions]);

    const featurePermissionsColumns = useMemo<GridColDef[]>(() => {
        const permissionCellRenderer = (params: GridRenderCellParams) => {
            const index = permissions.indexOf(params.value);
            const value = +params.value;
            return <Checkbox disableTouchRipple disabled={value === Permission.GlobalAdministrator} checked={index !== -1} value={params.value} onChange={ () => handleChanges(index, value) } />;
        };

        return [
            { field: 'module', headerName: 'Module' },
            { field: 'feature', headerName: 'Feature', width: 250 },
            { field: 'access', headerName: 'Access', align: 'center', sortable: false, disableColumnMenu: true, renderCell: permissionCellRenderer },
        ];
    }, [permissions]);

    const { unsavedChangesDialog } = useUnsavedChangesDialog(hasChanges, saveHandler);

    return (
        <>
            {unsavedChangesDialog}
            <Snackbar open={showSuccess} autoHideDuration={3000} onClose={() => setShowSuccess(false)}>
                <Alert onClose={() => setShowSuccess(false)}>Changes Saved</Alert>
            </Snackbar>
            <Snackbar open={errorMessage !== ''} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <PageContentLayout title="Permissions" showLoading={isLoading}>
                <Grid container columnSpacing={4}>
                    <Grid item md={8}>
                        <div style={{ height: '100%', width: '100%', minHeight: '500px' }}>
                            <div style={{ display: 'flex', height: '100%' }}>
                                <div style={{ flexGrow: 1 }}>
                                    <DataGridPremium
                                        disableColumnMenu
                                        disableColumnReorder
                                        disableColumnResize
                                        getRowId={ r => r.module }
                                        rows={ActionPermissionsGridRows}
                                        columns={actionPermissionsGridGolumns}
                                        disableRowSelectionOnClick
                                        density="compact"
                                        hideFooter
                                    />
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item md={4}>
                        <div style={{ height: '100%', width: '100%', minHeight: '1150px' }}>
                            <div style={{ display: 'flex', height: '100%' }}>
                                <div style={{ flexGrow: 1 }}>
                                    <DataGridPremium
                                        disableColumnReorder
                                        disableColumnResize
                                        getRowId={ r => r.feature }
                                        rows={FeaturePermissionsGridRows}
                                        columns={featurePermissionsColumns}
                                        disableRowSelectionOnClick
                                        density="compact"
                                        hideFooter
                                    />
                                </div>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </PageContentLayout>
        </>
    );
}