import Alert from "components/Alert";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { TagSettings } from "common/models/Configuration/Tags";
import { StringValueWrapper } from "common/models/GenericTypes";
import { ChangeTracker } from "common/models/hooks/ChangeTracker";
import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import useObjectStateWithChangeTracker from "hooks/UseObjectStateWithChangeTracker";
import useUnsavedChangesDialog from "hooks/UseUnsavedChangesDialog";
import PageContentLayout from "layouts/PageContentLayout";
import { GetUserTagsSettings, UpdateUserTagsSettings } from "services/TagsService";

interface Props {
    setSummaryBar?: (summaryBar: JSX.Element) => void,
    userName?: string
}

const defaultStringValue: StringValueWrapper = { value: '' };
const noChangesStringValue: ChangeTracker<StringValueWrapper> = { value: false };

const UsersLink = <Link to="/configuration/users" style={{ color: 'inherit', textDecoration: 'underline' }}>Users</Link>;

export default function UserTags({ setSummaryBar, userName }: Props) {
    const [isLoading, setIsLoading] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [tagSettings, setTagSettings] = useState<TagSettings[]>([]);
    const { state, init, change, updateInitial, hasChanges } = useObjectStateWithChangeTracker<StringValueWrapper>(defaultStringValue, noChangesStringValue);
    const params = useParams();

    const userId = useMemo(() => {
        const parsedId = +(params.userId ?? '0');
        if(isNaN(parsedId)) return 0;
        return parsedId;
    }, [params.userId]);

    const isAllValuesOk = useMemo(() => {
        for (let i = 0; i < tagSettings.length; i++) {
            const t = tagSettings[i];
            if (t.minTags < 0 || !Number.isInteger(t.minTags)) return false;
        }
        return true;
    }, [tagSettings]);

    const saveHandler = useCallback(async () => {
        if (!isAllValuesOk) {
            setErrorMessage('All values must be whole numbers greater or equal to 0');
            return false;
        }
        setIsLoading(true);
        const res = await UpdateUserTagsSettings(userId, 3, tagSettings, setErrorMessage);
        if (!res) {
            setIsLoading(false);
            return false;
        }
        updateInitial();
        setIsLoading(false);
        setShowSuccess(true);
        return true;
    }, [userId, tagSettings, isAllValuesOk, updateInitial]);

    useEffect(() => {
        const action = <Button variant="contained" color="success" onClick={ saveHandler } disabled={!hasChanges} >Save</Button>;
        const userNameText = userName ? userName + ' > ' : '';
        const title = <> {'Configuration > '} {UsersLink} {' > ' + userNameText + 'Tags'} </>;
        const tabTitle = userNameText + 'Users > Configuration > Recruit Wizard';
        const summaryBar = <TitleAndActionSummaryBar title={ title } browserTabTitle={ tabTitle } action={action} />;
        setSummaryBar && setSummaryBar(summaryBar);
    }, [setSummaryBar, userName, saveHandler, hasChanges]);

    const { unsavedChangesDialog } = useUnsavedChangesDialog(hasChanges, saveHandler);

    useEffect(() => {
        const getData = async () => {
            setIsLoading(true);
            const data = await GetUserTagsSettings(userId, 3, setErrorMessage);
            if(data) {
                let minValues: number[] = [];
                let firstRowCheckMap: any = {};
                let finalData: TagSettings[] = [];
                for (let i = 0; i < data.length; i++) {
                    const tag = data[i];
                    if (firstRowCheckMap[tag.tagTypeId]) continue;
                    firstRowCheckMap[tag.tagTypeId] = true;
                    finalData.push(tag);
                    minValues.push(tag.minTags);
                }
                setTagSettings(finalData);
                init({ value: JSON.stringify(minValues) });
            }
            setIsLoading(false);
        };
        getData();
    }, [userId, init]);

    const handleTagSettingChange = (index: number, value: string) => {
        let values = JSON.parse(state.value) as number[];
        setTagSettings(prev => {
            let newSettings = [...prev];
            newSettings[index].minTags = +value;
            values[index] = +value;
            change('value', JSON.stringify(values));
            return newSettings;
        });
    };

    return (
        <>
            {unsavedChangesDialog}
            <Snackbar open={showSuccess} autoHideDuration={3000} onClose={() => setShowSuccess(false)}>
                <Alert onClose={() => setShowSuccess(false)}>Changes Saved</Alert>
            </Snackbar>
            <Snackbar open={errorMessage !== ''} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <PageContentLayout title="Tags" showLoading={isLoading}>
                <Stack spacing={3}>
                    {tagSettings.map((t, i) => {
                        const isError = t.minTags < 0 || !Number.isInteger(t.minTags);
                        return (
                           <TextField
                               key={t.id}
                               label={t.tagAgencyName}
                               type="number"
                               value={t.minTags}
                               onChange={ ({target}) => handleTagSettingChange(i, target.value) }
                               error={isError}
                               helperText={isError ? 'Only whole numbers equal or greater than 0' : ''}
                           />
                       );
                    }
                    )}
                </Stack>
            </PageContentLayout>
        </>
    );

}