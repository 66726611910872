import React from "react";
import UserRecordDefaultsPageContent from "components/Content/UserRecordDefaultsPageContent";


interface Props {
    setSummaryBar?: (summaryBar: JSX.Element) => void
}

export default function RecordDefaultsPage({ setSummaryBar }: Props) {
    return (
        <UserRecordDefaultsPageContent setSummaryBar={setSummaryBar} />
    );
}