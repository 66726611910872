import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import { GridColDef, GridRenderCellParams, GridValueGetter, useGridApiRef } from "@mui/x-data-grid-premium";
import { AllowCandidatesAddEdit } from "common/data/Permissions/CandidatesAccess";
import { CandidateWorkHistory } from "common/models/Candidates/Candidate";
import EditWorkHistoryDialog from "components/Dialogs/Candidates/EditWorkHistoryDialog";
import GridWithStateWrapper from "components/GridWidthStateWrapper";
import ActionsDropDownButton from "components/SummaryBars/Actions/ActionsDropsDownMenu";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { GetCandidateWorkHistory } from "services/CandidatesService";

interface Props {
    candidateId: number,
    gridName: string,
    extraActions?: JSX.Element,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
    successHandler?: (message: string) => void,
}

export default function WorkHistoryGridComponent({ candidateId, gridName, extraActions, loadingHandler, errorHandler, successHandler }: Props) {
    const [selectedWorkHistoryId, setSelectedWorkHistoryId] = useState<number | null>(null);
    const [rows, setRows] = useState<CandidateWorkHistory[]>([]);
    const apiRef = useGridApiRef();

    const canAddEditCandidates = useMemo(() => AllowCandidatesAddEdit(), []);

    const getData = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        const res = await GetCandidateWorkHistory(candidateId, undefined, errorHandler);
        if (res) setRows(res);
        loadingHandler && loadingHandler(false);
    }, [candidateId, errorHandler, loadingHandler]);

    useEffect(() => {
        getData();
    }, [getData]);

    const columns = useMemo<GridColDef[]>(() => {
        const dateValueGetter: GridValueGetter<CandidateWorkHistory, any, undefined, string> = (value) => {
            if (value) {
                const d = moment(value);
                if (d.isValid() && d.get("year") > 1) {
                    return d.toDate();
                }
            }
            return null;
        };

        const dateRenderer = (params: GridRenderCellParams) => {
            if (params.value) {
                return moment(params.value).format('DD MMM YYYY');
            }
        };

        const actionsRenderer = (params: GridRenderCellParams) => {
            return (
                <span
                    onClick={ () => setSelectedWorkHistoryId(+params.id) }
                    style={{ cursor: 'pointer', textDecoration: 'underline' }}
                >
                    Edit
                </span>
            );
        };

        return [
            { field: 'title', headerName: 'Title', width: 300 },
            { field: 'organizationName', headerName: 'Organization', width: 150 },
            { field: 'jobTypeName', headerName: 'Type' },
            { field: 'startDate', headerName: 'Start Date', valueGetter: dateValueGetter, renderCell: dateRenderer },
            { field: 'endDate', headerName: 'End Date', valueGetter: dateValueGetter, renderCell: dateRenderer },
            { field: 'id', headerName: 'Action', renderCell: actionsRenderer },
        ];
    }, []);

    const gridActions = useMemo(() => {
        if (canAddEditCandidates) {
            return (
                <ActionsDropDownButton color="secondary" label="List Actions">
                    <MenuItem onClick={() => setSelectedWorkHistoryId(0)}>Add Work History Item</MenuItem>
                </ActionsDropDownButton>
            );
        }
        return undefined;
    }, [canAddEditCandidates]);

    const editSuccessHandler = useCallback((message: string) => {
        successHandler && successHandler(message);
        getData();
    }, [getData, successHandler]);

    return (
        <>
            <EditWorkHistoryDialog
                open={selectedWorkHistoryId !== null}
                candidateId={candidateId}
                workHistoryId={selectedWorkHistoryId ?? 0}
                closeHandler={() => setSelectedWorkHistoryId(null)}
                loadingHandler={loadingHandler}
                errorHandler={errorHandler}
                successHandler={editSuccessHandler}
            />
            <Box>
                { extraActions }
                { Boolean(gridActions) && <Box pb="10px" sx={{ float: 'right' }}>{ gridActions }</Box>}
            </Box>
            <GridWithStateWrapper
                gridName={gridName}
                rows={rows}
                columns={columns}
                apiRef={apiRef}
                density="compact"
                disableRowSelectionOnClick
                pagination
                pageSizeOptions={[100,250,500,1000]}
            />
        </>
    );
}