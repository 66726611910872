import React, { useEffect, useMemo, useState } from "react";
import { Report } from "common/models/Reports/Report";
import ReportsGridComponent from "components/Reports/ReportsGrid";
import OnlyTitleSummaryBar from "components/SummaryBars/OnlyTitleSummaryBar";
import PageContentLayout from "layouts/PageContentLayout";
import PageLayout from "layouts/PageLayout";
import { GetReports } from "services/ContactsService";
import { Link, useParams } from 'react-router-dom';
import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import DetailedDocumentsReport from "components/Reports/Documents/DocumentsDetailed";
import NotContacted from "components/Reports/Contacts/NotContacted";
import ContactsNewReport from "components/Reports/Contacts/ContactsNew";
import DetailedContactsReport from "components/Reports/Contacts/ContactsDetailed";
import EmailMarketingReport from "components/Reports/Contacts/EmailMarketing";


const gridSummaryBar = <OnlyTitleSummaryBar title="Analytics > Contacts" browserTabTitle="Contacts > Analytics" />;
const ClientsReportsLink = <Link to="/analytics/contacts" style={{ color: 'inherit', textDecoration: 'underline' }}>Contacts</Link>;

export default function ContactReportsPage() {
    const [isLoading, setIsLoading] = useState(false);
    const [reports, setReports] = useState<Report[]>([]);
    const params = useParams();

    const reportName = useMemo(() => {
        const reportNameParam = params.reportName;
        return reportNameParam;
    }, [params.reportName]);

    const reportObj = useMemo(() => {
        return reports.find(r => r.pathName === reportName)
    }, [reports, reportName]);

    const summaryBar = useMemo(() => {
        if (reportObj) {
            const title = <>{'Analytics > '} {ClientsReportsLink} {' > ' + reportObj.title}</>;
            const tabTitle = reportObj.title + ' > Contacts > Analytics';
            return <TitleAndActionSummaryBar title={title} browserTabTitle={tabTitle} action={<></>} />;
        }
        return gridSummaryBar;
    }, [reportObj]);

    useEffect(() => {
        const getReports = async () => {
            setIsLoading(true);
            const res = await GetReports();
            if (res) setReports(res);
            setIsLoading(false);
        };
        getReports();
    }, []);

    return (
        <PageLayout SummaryBar={summaryBar}>
            <PageContentLayout showLoading={isLoading}>
                { !reportName && <ReportsGridComponent data={reports} /> }
                {reportObj && reportObj.pathName === 'documents' && <DetailedDocumentsReport entityTypeId={2} description={reportObj.longDescription} loadingHandler={setIsLoading} />}
                {reportObj && reportObj.pathName === 'detailed' && <DetailedContactsReport description={reportObj.longDescription} loadingHandler={setIsLoading} />}
                {reportObj && reportObj.pathName === 'not-contacted' && <NotContacted description={reportObj.longDescription} loadingHandler={setIsLoading} />}
                {reportObj && reportObj.pathName === 'email-marketing' && <EmailMarketingReport description={reportObj.longDescription} loadingHandler={setIsLoading} />}
                {reportObj && reportObj.pathName === 'new-contacts' && <ContactsNewReport description={reportObj.longDescription} loadingHandler={setIsLoading} />}
            </PageContentLayout>
        </PageLayout>
    );
}