import { ErrorHandler, NumberValueWrapper, RequestOptions, StringValueWrapper } from "../common/models/GenericTypes";
import { Meeting, MeetingAttendee, MeetingEdit } from "../common/models/Meetings";
import { Get, Post, PostFile, Put, PutFile } from "../util/HttpUtils";
import {MeetingType} from "../common/models/Configuration/MeetingType";

export async function GetMeetingById(meetingId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Meeting>(`api/meetings/${meetingId}`, options);
}

export async function GetMeetingAttendees(meetingId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<MeetingAttendee[]>(`api/meetings/${meetingId}/attendees`, options);
}

export async function GetDashboardMeetings(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Meeting[]>('api/Meetings/Dashboard', options);
}

export async function AddNotesToMeeting(meetingId: number, notes: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/Meetings/${meetingId}/Notes`, options, { value: notes });
}

export async function AddNotesToMeetingWithFollowUp(meetingId: number, notes: string, followUpUserId: number, followUpDate: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/Meetings/${meetingId}/Notes`, options, { value: notes, followUpDate: followUpDate, followUpUserId: followUpUserId });
}

export async function CancelMeeting(meetingId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/Meetings/${meetingId}/Cancel`, options);
}

export async function GetMeetingsReportData(consultantId: number, timeRange: number, status: number, errorHandler?: ErrorHandler){
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Meeting[]>(`api/Meetings/Search?consultant=${consultantId}&timeRange=${timeRange}&status=${status}`, options);
}

export async function CreateMeeting(data: MeetingEdit, files: File[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    const formData = new FormData();
    formData.append('meetingData', JSON.stringify(data));
    for (let i = 0; i < files.length; i++) {
        const f = files[i];
        formData.append('files', f);
    }

    return PostFile<NumberValueWrapper>('api/Meetings', formData, options);
}

export async function UpdateMeeting(meetingId: number, data: MeetingEdit, files: File[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    const formData = new FormData();
    formData.append('meetingData', JSON.stringify(data));
    for (let i = 0; i < files.length; i++) {
        const f = files[i];
        formData.append('files', f);
    }

    return PutFile<boolean>(`api/Meetings/${meetingId}`, formData, options);
}

export async function GetMeetingMessagePreview(meetingId: number, data: MeetingEdit, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<StringValueWrapper>(`api/Meetings/${meetingId}/Preview`, options, data);
}

export async function CreateMeetingType(meetingType: MeetingType, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>(`api/Configuration/MeetingTypes`, options, meetingType);
}

export async function UpdateMeetingType(meetingTypeId: number, meetingType: MeetingType, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/Configuration/MeetingTypes/${meetingTypeId}`, options, meetingType);
}

export async function GetMeetingTypes(errorHandler?: ErrorHandler){
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };
    
    return Get<MeetingType[]>(`api/Configuration/MeetingTypes`, options);
}

export async function GetMeetingTypeById(id: number, errorHandler?: ErrorHandler){
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };
    
    return Get<MeetingType>(`api/Configuration/MeetingTypes/${id}`, options);
}

export async function GetActiveMeetingTypes(entityId: number, errorHandler?: ErrorHandler){
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<MeetingType[]>(`api/Configuration/MeetingTypes?entityId=${entityId}`, options);
}