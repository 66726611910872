import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import React, { useCallback, useMemo } from "react";
import { Cell, Pie, PieChart, PieLabelRenderProps, ResponsiveContainer, Sector, SectorProps } from "recharts";
import { Forecast } from "common/models/Analytics/Forecasts";
import CircularProgress from "@mui/material/CircularProgress";

interface DataTest { name: string, value: number }
const testData: DataTest[] = [
    { name: 'Group A', value: 100 },
];

interface Props {
    title: string,
    data: Forecast | null,
}

const centerY: string | number = 120;

export default function GaugeGraphComponent({ title, data }: Props) {
    const t = useTheme();

    const pieData = useMemo<DataTest[]>(() => {
        if (data) {
            return [
                { name: data.target.toLocaleString(), value: data.target },
                { name: data.stretch.toLocaleString(), value: data.stretch - data.target },
                { name: data.stretchEnd.toLocaleString(), value: data.stretchEnd - data.stretch },
            ];
        }

        return [];
    }, [data]);

    const needlePosition = useMemo<{start: number, end: number}>(() => {
        if (data) {
            const needleWidth = 2;
            const { needleMarker, stretchEnd } = data;
            if (needleMarker < 0) return { start: 180 + needleWidth, end: 180 - needleWidth };
            if (needleMarker > stretchEnd) return { start: 0 + needleWidth, end: 0 - needleWidth  }
            const needleAngle = 180 - ((needleMarker * 180) / stretchEnd);
            return { start: needleAngle + needleWidth, end: needleAngle - needleWidth };
        }

        return { start: 0, end: 0 };
    }, [data]);

    const colors = useMemo(() => {
        return [ t.palette.error.light, t.palette.warning.light, t.palette.success.light];
    }, [t.palette.error.light, t.palette.warning.light, t.palette.success.light]);

    const renderCustomLabel = useCallback((props: PieLabelRenderProps) => {
        const { endAngle = 0, outerRadius = 0, cx = 0, cy = 0 } = props;
        const RAD = Math.PI / 180;
        const cos = Math.cos(-RAD * endAngle);
        const sin = Math.sin(-RAD * endAngle);
        const x = +cx + ((+outerRadius + 10) * cos);
        const y = +cy + ((+outerRadius + 10) * sin);
        return (
            <text x={x} y={y} fill={t.palette.text.primary} textAnchor="start" dominantBaseline="central" fontSize={14}>
                {props.name}
            </text>
        );
    }, [t.palette.text.primary]);

    const renderActiveShape = useCallback((props: SectorProps) => {
        const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } = props;
        const total = data ? Math.round(data.totalForecast).toLocaleString() : '';
        return (
            <g>
                <text x={cx} y={cy} dy={8} textAnchor="middle" fill={t.palette.text.primary} fontWeight={600} fontSize={16}>{total}</text>
                <Sector
                    cx={cx}
                    cy={cy}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    fill={fill}
                />
            </g>
        );
    }, [data, t.palette.text.primary]);

    return (
        <Paper sx={{ height: '100%', display: 'flex', flexDirection: 'column' }} >
            <Typography variant="h6" padding={1} textAlign="center" fontWeight={600} component="div">{title}</Typography>
            {data === null && <Box m="auto"><CircularProgress /></Box> }
            <ResponsiveContainer width="100%" height={150} style={{ display: data === null ? 'none' : undefined }}>
                <PieChart width={370} height={150} style={{ cursor: 'inherit' }}>
                    <Pie
                        dataKey="value"
                        nameKey="name"
                        startAngle={180}
                        endAngle={0}
                        data={pieData}
                        cx="50%"
                        cy={centerY}
                        outerRadius={100}
                        innerRadius={75}
                        label={renderCustomLabel}
                        labelLine={false}
                    >
                        <Cell fill={colors[0]} />
                        <Cell fill={colors[1]} />
                        <Cell fill={colors[2]} />
                    </Pie>
                    <Pie
                        dataKey="value"
                        startAngle={needlePosition.start}
                        endAngle={needlePosition.end}
                        data={testData}
                        cx="50%"
                        cy={centerY}
                        outerRadius={87.5}
                        innerRadius={50}
                        fill={t.palette.text.primary}
                        activeIndex={0}
                        activeShape={renderActiveShape}
                    />
                </PieChart>
            </ResponsiveContainer>
            <Box display="flex" justifyContent="space-around" padding={1}>
                <Typography variant="body2" component="span">
                    Confirmed<span style={{ padding: '0 5px' }}>|</span><span style={{ fontWeight: 600 }}>{data ? data.confirmed.toLocaleString() : ''}</span>
                </Typography>
                <Typography variant="body2" component="span">
                    Predicted<span style={{ padding: '0 5px' }}>|</span><span style={{ fontWeight: 600 }}>{data ? data.predicted.toLocaleString() : ''}</span>
                </Typography>
            </Box>
        </Paper>
    );
}