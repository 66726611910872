import { Activity } from "../common/models/Activities";
import { Contact } from "../common/models/Contacts";
import { ErrorHandler, NumberValueWrapper, RequestOptions, StringValueWrapper } from "../common/models/GenericTypes";
import { Submission, SubmissionCreateCandidateData, SubmissionCreateData } from "../common/models/Submissions/Submission";
import { Get, Post, PostFile } from "../util/HttpUtils";

export async function GetSubmissionById(submissionId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return await Get<Submission>(`api/submissions/${submissionId}`, options);
}

export async function GetSubmissionContacts(submissionId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return await Get<Contact[]>(`api/submissions/${submissionId}/contacts`, options);
}

export async function GetActivitiesBySubmissionId(submissionId: number, status: number = 0, category: number = 0, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return await Get<Activity[]>(`api/submissions/${submissionId}/Activities?status=${status}&category=${category}`, options);
}

export async function ResendSubmission(submissionId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return await Post<boolean>(`api/submissions/${submissionId}/resend`, options);
}

export async function ConvertSubmissionToPermJob(submissionId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return await Post<NumberValueWrapper>(`api/submissions/${submissionId}/ConvertToPerm`, options);
}

export async function ConvertSubmissionToContractJob(submissionId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return await Post<NumberValueWrapper>(`api/submissions/${submissionId}/ConvertToContract`, options);
}

export async function CreateSubmission(data: SubmissionCreateData, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    const formData = new FormData();
    let cData: Partial<SubmissionCreateCandidateData>[] = [];
    for (let i = 0; i < data.candidateData.length; i++) {
        const c = data.candidateData[i];
        const paramName = `files-${c.id}`;
        for (let j = 0; j < c.files.length; j++) {
            const f = c.files[j];
            formData.append(paramName, f);
        }
        cData.push({...c, files: undefined});
    }

    formData.append('submissionData', JSON.stringify({...data, candidateData: cData}));

    if (data.jobId) return await PostFile<NumberValueWrapper>('api/Submissions', formData, options);
    else return await PostFile<NumberValueWrapper>('api/Submissions/Float', formData, options);
}

export async function GetSubmissionMessagePreview(candidateId: number, contactId: number, data: SubmissionCreateData, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    let cData: Partial<SubmissionCreateCandidateData>[] = [];
    for (let i = 0; i < data.candidateData.length; i++) {
        const c = data.candidateData[i];
        cData.push({...c, files: undefined});
    }

    return Post<StringValueWrapper>(`api/Submissions/Preview/${candidateId}?contactId=${contactId}`, options, { ...data, candidateData: cData});
}