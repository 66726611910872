import React, { useState } from "react";
import EmailIntegrationExclusionsPageContent from "components/Content/EmailIntegrationExclusionsPageContent";
import PageLayout from "layouts/PageLayout";

export default function AccountEmailExclusionsPage() {
    const [summaryBar, setSummaryBar] = useState<JSX.Element>(<></>);
    return (
        <PageLayout SummaryBar={summaryBar}>
            <EmailIntegrationExclusionsPageContent
                listType="Me"
                summaryBarTitle="Account > Email Integration > Exclusion List"
                summaryBarTabTitle="Exclusion List > Email Integration"
                setSummaryBar={setSummaryBar}
            />
        </PageLayout>
    );
}