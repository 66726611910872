import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import CloseIcon from '@mui/icons-material/Close';
import React, { useMemo } from "react";
import { EmailRecipient } from "common/models/EmailRecipient";
import ListItemText from "@mui/material/ListItemText";
import Button from "@mui/material/Button";
import ListItemButton from "@mui/material/ListItemButton";
import { Link } from "react-router-dom";

interface Props {
    open: boolean,
    entityTypeId: number,
    recipients: EmailRecipient[],
    removeHandler: (index: number) => void,
    closeHandler: () => void,
    isSmsRecipients?: boolean
}

const recipientLinkStyle: React.CSSProperties = { width: '100%', color: 'inherit', textDecoration: 'none', padding: '0 16px' };

export default function ManageRecipientsDialog({ open, entityTypeId, recipients, removeHandler, closeHandler, isSmsRecipients = false }: Props) {
    const recipientPath = useMemo(() => {
        if (entityTypeId === 2) return 'contacts';
        if (entityTypeId === 3) return 'candidates';
        return '';
    }, [entityTypeId]);
    
    return (
        <Dialog open={open} fullWidth>
            <DialogTitle>Manage Recipients</DialogTitle>
            <DialogContent>
                <List>
                    {recipients.map((r, i) => (
                        <ListItem key={`${i}-${r.id}`} disablePadding secondaryAction={ <IconButton onClick={ () => removeHandler(i) }><CloseIcon /></IconButton> }>
                            <ListItemButton dense>
                                <Link to={`/${recipientPath}/${r.id}`} target="_blank" style={recipientLinkStyle}>
                                    <ListItemText primary={r.name} secondary={ isSmsRecipients ? r.mobile : r.email} />
                                </Link>
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="success" onClick={closeHandler}>Ok</Button>
            </DialogActions>
        </Dialog>
    );
}