import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import PageLayout from "layouts/PageLayout";
import PageContentLayout from "layouts/PageContentLayout";
import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import { Meeting } from "common/models/Meetings";
import { CancelMeeting, GetMeetingById } from "services/MeetingsService";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { GetSettingBySettingName } from "services/UsersService";

import ConfirmationDialog from "components/Dialogs/Generic/ConfirmationDialog";
import Snackbar from "@mui/material/Snackbar";
import Alert from "components/Alert";
import ActionMenu from "components/Menus/ActionMenu";
import moment from "moment";
import { MenuOptionDefinition } from "common/models/MenuDefinition";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import ActivitiesGridComponent from "components/Grids/ActivitiesGrid";
import ViewRecordScreenLayout from "components/ScreenLayouts/Meetings/ViewRecordScreenLayout";
import MeetingAttendeesGridComponent from "components/Grids/MeetingAttendeesGrid";
import DocumentsGridComponent from "components/Grids/DocumentsGrid";
import AddNotesToMeetingDialog from "components/Dialogs/Meetings/AddNotesToMeetingDialog";
import Divider from "@mui/material/Divider";

const formatDateString = (d: string, f: string = 'DD MMM YYYY h:mm A') => {
    if (d && d !== '0001-01-01T00:00:00') {
        const m = moment(d);
        if (m.isValid()) return m.format(f);
    }
    return 'Never';
}

const renderSummary = (m: Meeting) => {
    const tooltipContent = (
        <table>
            <tbody>
                <tr>
                    <Typography variant="caption" fontWeight={600} component="td">ID</Typography>
                    <Typography variant="caption" pl="10px" component="td">{m.id}</Typography>
                </tr>
                <tr>
                    <Typography variant="caption" fontWeight={600} component="td">Created Date</Typography>
                    <Typography variant="caption" pl="10px" component="td">{formatDateString(m.dateCreated)}</Typography>
                </tr>
            </tbody>
        </table>
    );

    const jobSummary = (
        <Tooltip arrow title={tooltipContent}>
            <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{m.subject} ({m.typeName}) - {m.statusName}</span>
        </Tooltip>
    );

    return (<>{jobSummary}</>);
};

export default function MeetingRecord() {
    const [meeting, setMeeting] = useState<Meeting>();
    const params = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [isFetchingDefaultTab, setIsFetchingDefaultTab] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [isLoadingTab, setIsLoadingTab] = useState(false);
    const [showAddNotesDialog, setShowAddNotesDialog] = useState(false);
    const [showCancelDialog, setShowCancelDialog] = useState(false);
    const [isFinishedSetup, setIsFinishedSetup] = useState(false);
    const [searchParams] = useSearchParams();

    const [activeTab, setActiveTab] = useState('Home');
    const [tabActionMenuOptions, setTabActionMenuOptions] = useState<MenuOptionDefinition[]>([]);
    const navigate = useNavigate();

    const meetingId = useMemo(() => {
        const parsedId = +(params.id ?? '0');
        if (isNaN(parsedId)) return 0;
        return parsedId;
    }, [params.id]);

    useEffect(() => {
        const getData = async () => {
            setIsLoading(true);
            const res = await GetMeetingById(meetingId);
            if (res === null) navigate('/not-found');
            if (res) {
                setMeeting(res);
            }
            setIsLoading(false);
        };
        getData();
    }, [meetingId, navigate]);

    useEffect(() => {
        const tabParam = searchParams.get('tab');
        const getData = async () => {
            setIsFetchingDefaultTab(true);
            if (!tabParam) {
                const tabSetting = await GetSettingBySettingName('TabDefaultsMeetings');
                if (tabSetting) setActiveTab(tabSetting);
            }
            else setActiveTab(tabParam)
            setIsFetchingDefaultTab(false);
            setIsFinishedSetup(true);
        };
        getData();
    }, [searchParams]);

    const actionMenuDefinitions = useMemo<MenuOptionDefinition[]>(() => {
        const isClosed = meeting ? meeting.statusID === 2 : false;
        const isOpen = meeting ? meeting.statusID === 1 : false;
        let actions: (MenuOptionDefinition)[] = [
            ...tabActionMenuOptions,
            { label: 'Add Notes', type: 'action', action: () => setShowAddNotesDialog(true) },
            { label: 'Reschedule', type: 'link', href: `/meetings/${meetingId}/edit?reschedule=true`, allow: () => isClosed },
            { label: 'Edit', type: 'link', href: `/meetings/${meetingId}/edit`, allow: () => isOpen },
            { label: 'Cancel', type: 'action', action: () => setShowCancelDialog(true), allow: () => isOpen || isClosed },
        ];

        return actions;
    }, [meetingId, meeting, tabActionMenuOptions]);

    const SummaryBar = useMemo(() => {
        if (meeting) {
            const action = (
                <Box key="actionsMenu">
                    <ActionMenu definition={actionMenuDefinitions} />
                </Box>
            );

            return (
                <TitleAndActionSummaryBar
                    height="4.5rem"
                    title={meeting ? renderSummary(meeting) : ''}
                    action={[action]}
                    browserTabTitle={!meeting ? '' : "Meetings"}
                />
            );
        }
    }, [meeting, actionMenuDefinitions]);


    const handleTabChange = useCallback((e: React.SyntheticEvent, newValue: string) => {
        navigate(`/meetings/${meetingId}?tab=${newValue}`);
    }, [meetingId, navigate]);

    const counts = useMemo(() => {
        let counts = {
            activities: 0,
            activitiesDate: '\u00A0',
        };

        if (meeting && meeting.statisticsCounts && meeting.statisticsCounts.length > 0) {
            for (let i = 0; i < meeting.statisticsCounts.length; i++) {
                const s = meeting.statisticsCounts[i];
                switch (s.type) {
                    case "ActivitiesCount": counts.activities = s.value;
                        break;
                }
            }
        }

        if (meeting && meeting.statisticsDates && meeting.statisticsDates.length > 0) {
            for (let i = 0; i < meeting.statisticsDates.length; i++) {
                const s = meeting.statisticsDates[i];
                switch (s.type) {
                    case "ActivitiesDate":
                        const aDate = moment(s.value);
                        if (aDate.isValid()) counts.activitiesDate = aDate.format('DD MMM YYYY');
                        break;
                }
            }
        }

        return counts;
    }, [meeting]);

    useEffect(() => {
        if (tabActionMenuOptions.length > 0 && activeTab !== 'Home') {
            setTabActionMenuOptions([]);
        }
    }, [activeTab, tabActionMenuOptions]);

    const addNotesSuccessHandler = useCallback((message: string, notes: string) => {
        setSuccessMessage(message);
        setMeeting(prev => prev ? ({...prev, meetingNotes: notes}) : prev);
    }, []);

    const cancelMeetingCallback = useCallback(async () => {
        setIsLoading(true);
        const res = await CancelMeeting(meetingId, setErrorMessage);
        if (res) {
            setSuccessMessage('Meeting Cancelled');
            setMeeting(prev => prev ? ({...prev, statusID: 3, statusName: 'Cancelled'}) : prev);
            setShowCancelDialog(false);
        }
        setIsLoading(false);
    }, [meetingId]);

    const isNoDistribution = useMemo(() => meeting && meeting.distributionMode === 0, [meeting]);

    const cancelMessage = useMemo(() => {
        if (meeting) {
            if (meeting.statusID === 1) return 'Are you sure you want to cancel this meeting?';
            if (meeting.statusID === 2) return 'This meeting is already completed. Are you sure you want to cancel it?';
        }
        return '';
    }, [meeting]);

    return (
        <>
            <Snackbar open={successMessage !== ''} autoHideDuration={3000} onClose={() => setSuccessMessage('')}>
                <Alert onClose={() => setSuccessMessage('')}>{ successMessage }</Alert>
            </Snackbar>
            <Snackbar open={errorMessage !== ''} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <AddNotesToMeetingDialog
                closeHandler={ () => setShowAddNotesDialog(false) }
                initialNotes={meeting ? meeting.meetingNotes ?? '' : ''}
                meetingId={meetingId}
                open={showAddNotesDialog}
                loadingHandler={setIsLoadingTab}
                successHandler={addNotesSuccessHandler}
                errorHandler={setErrorMessage}
            />
            <ConfirmationDialog
                message={cancelMessage}
                onClose={() => setShowCancelDialog(false)}
                onContinue={cancelMeetingCallback}
                open={showCancelDialog}
                title="Cancel Meeting"
                cancelActionText="No"
                confirmActionText="Yes"
            />
            <PageLayout paddingTop={0} SummaryBar={SummaryBar}>
                <Tabs value={activeTab} onChange={handleTabChange}>
                    <Tab value="Home" label={<>Home<br />&nbsp;</>} />
                    <Tab value="Attendees" label={<>Attendees<br />&nbsp;</>} />
                    <Tab value="Attachments" label={<>Attachments<br />&nbsp;</>} />
                    {!isNoDistribution && <Tab value="Message" label={<>Message<br />&nbsp;</>} />}
                    <Tab value="Activities" label={<>Activities ({counts.activities})<br />{counts.activitiesDate}</>} />
                </Tabs>
                <PageContentLayout showLoading={isLoading || isFetchingDefaultTab || isLoadingTab}>
                    { isFinishedSetup && activeTab === "Home" &&
                        <ViewRecordScreenLayout
                            meeting={meeting ? meeting : null}
                            loadingHandler={setIsLoadingTab}
                            errorHandler={setErrorMessage}
                        />
                    }
                    { isFinishedSetup && activeTab === "Attendees" &&
                        <MeetingAttendeesGridComponent
                            meetingId={meetingId}
                            gridName="interview/attendees"
                            loadingHandler={setIsLoadingTab}
                            errorHandler={setErrorMessage}
                            successHandler={setSuccessMessage}
                        />
                    }
                    { isFinishedSetup && activeTab === "Attachments" &&
                        <DocumentsGridComponent
                            gridName="meeting/attachments"
                            source="meeting-record"
                            sourceId={meetingId}
                            hideActions
                            hideCheckboxSelection
                            loadingHandler={setIsLoadingTab}
                            errorHandler={setErrorMessage}
                            successHandler={setSuccessMessage}
                        />
                    }
                    { isFinishedSetup && activeTab === "Message" && !isNoDistribution &&
                        <Typography component="div" pt="20px" px="10px">
                            <Box mb={1}><strong>Subject: </strong>{meeting ? meeting.subject : ''}</Box>
                            <Divider />
                            <div dangerouslySetInnerHTML={{ __html: meeting ? meeting.emailBody : '' }} />
                        </Typography>
                    }
                    { isFinishedSetup && activeTab === "Activities" &&
                        <ActivitiesGridComponent
                            source="meeting-record"
                            sourceId={meetingId}
                            loadingHandler={setIsLoadingTab}
                            errorHandler={setErrorMessage}
                            successHandler={setSuccessMessage}
                            gridName="meetings/Activities"
                            hideActionsMenu
                        />
                    }
                </PageContentLayout>
            </PageLayout>
        </>
    );
}