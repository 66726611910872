import {
    GridActionsCellItem,
    GridColDef, GridColumnVisibilityModel,
    GridRenderCellParams,
    GridRowSelectionModel,
    GridValueGetter,
    useGridApiRef
} from "@mui/x-data-grid-premium";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {Interview} from "common/models/Interviews";
import { CancelInterview } from "services/InterviewsService";
import GridWithStateWrapper from "../GridWidthStateWrapper";
import { Link } from "react-router-dom";
import ConfirmationDialog from "../Dialogs/Generic/ConfirmationDialog";
import ActionsDropDownButton from "components/SummaryBars/Actions/ActionsDropsDownMenu";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import {GetClientInterviews} from "../../services/ClientsService";
import {GetContactInterviews} from "../../services/ContactsService";
import {GetCandidateInterviews} from "../../services/CandidatesService";
import {GetJobInterviews} from "../../services/JobsService";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import {defaultGridCellStyle} from "../../util/GridUtils";

interface Props {
    gridName: string,
    source: 'client-record' | 'contact-record' | 'job-record' | 'candidate-record',
    sourceId?: number
    excelName?: string,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
    successHandler?: (message: string) => void
}

const linkStyle: React.CSSProperties = { color: 'inherit', textDecoration: 'underline' };

export default function InterviewsGridComponent({ gridName, source, sourceId, excelName, loadingHandler, errorHandler, successHandler }: Props) {
    const [rows, setRows] = useState<Interview[]>([]);
    const [cancelInterviewId, setCancelInterviewId] = useState<number | null>(null);
    const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
    const apiRef = useGridApiRef();

    const getRowsCallback = useCallback(async () => {
            loadingHandler && loadingHandler(true);
            let res: Interview[] | null = [];
            if (source === 'client-record' && sourceId) res = await GetClientInterviews(sourceId, errorHandler);
            if (source === 'contact-record' && sourceId) res = await GetContactInterviews(sourceId, errorHandler);
            if (source === 'job-record' && sourceId) res = await GetJobInterviews(sourceId, errorHandler);
            if (source === 'candidate-record' && sourceId) res = await GetCandidateInterviews(sourceId, errorHandler);
            if (res) setRows(res);
            loadingHandler && loadingHandler(false);
    }, [loadingHandler, source, errorHandler]);

    useEffect(() => {
        getRowsCallback();
    }, [getRowsCallback]);

    const cancelMeetingCallback = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        if (cancelInterviewId) {
            const res = await CancelInterview(cancelInterviewId, errorHandler);
            if (res) {
                successHandler && successHandler('Meeting Canceled');
                setCancelInterviewId(null);
                getRowsCallback();
            }
        }
        loadingHandler && loadingHandler(false);
    }, [cancelInterviewId, loadingHandler, errorHandler, getRowsCallback, successHandler]);

    const columns = useMemo<GridColDef[]>(() => {
        const dateValueGetter: GridValueGetter<Interview, any, undefined, string> = (value) => {
            if (value) {
                const d = moment(value);
                if (d.isValid() && d.get("year") > 1) {
                    return d.toDate();
                }
            }
            return null;
        };
        
        const dateRenderer = (params: GridRenderCellParams) => {
            if (params.value) {
                let val = moment(params.value).format('DD MMM YYYY h:mm A');

                return (
                    <div style={defaultGridCellStyle}>
                        <span>{val}</span>
                    </div>
                );
            }
            return params.value;
        }
        
        const linkToInterviewRenderer = (params: GridRenderCellParams) => {
            const interviewId = params.row.id;
            if (interviewId) {
                return <div style={defaultGridCellStyle}>
                    <Link to={`/interviews/${interviewId}`} style={linkStyle}>{params.value}</Link>
                </div>
            }
            return params.value;
        };

        const htmlRenderer = (params: GridRenderCellParams) => {
            if (params.value) {
                const newLineReplaced = (params.value as string).replaceAll('\n', '<br />');
                return <div style={{padding: '5px 0' }} dangerouslySetInnerHTML={{ __html: newLineReplaced }} />
            }
        }

        const minHeightCellRenderer = (params: GridRenderCellParams) => {
            return (
                <div style={defaultGridCellStyle}>
                    <span>{params.value}</span>
                </div>
            );
        };

        return [
            { field: 'actions', headerName: 'Actions', width: 100, align: 'center', headerAlign: 'center', type: 'actions', getActions: params => [
                    (params.row.statusID === 1)
                        ? <GridActionsCellItem icon={ <CancelIcon /> } label="Cancel" onClick={ () => setCancelInterviewId(params.row.id) } onResize={()=> {}} onResizeCapture={() => {}} />
                        : <></>
                    ,
                    (params.row.statusID === 1)
                        ? <GridActionsCellItem
                            icon={
                                <Link to={`/interviews/${params.id}/edit`} target="_blank" style={{ color: 'inherit', textDecoration: 'none', maxHeight: '20px', maxWidth: '20px' }}>
                                    <EditIcon sx={{ width: '20px', height: '20px' }} />
                                </Link>
                            }
                            label="Edit"
                            onResize={()=> {}}
                            onResizeCapture={() => {}}
                        />
                        : <></>
                    ,
                ]},
            { field: 'id', headerName: 'ID', width: 100, align: 'center', headerAlign: 'center', renderCell: linkToInterviewRenderer },
            { field: 'date', headerName: 'Date', width: 200, type: 'date', align: 'center', headerAlign: 'center', valueGetter: dateValueGetter, renderCell: dateRenderer },
            { field: 'statusName', headerName: 'Status', width: 200, align: 'center', headerAlign: 'center', renderCell: minHeightCellRenderer },
            { field: 'typeName', headerName: 'Type', width: 200, align: 'center', headerAlign: 'center', renderCell: linkToInterviewRenderer },
            { field: 'organizerName', headerName: 'Organizer', width: 200, align: 'center', headerAlign: 'center', renderCell: minHeightCellRenderer },
            { field: 'numberOfAttendees', headerName: '# Attendees', width: 200, align: 'center', headerAlign: 'center', renderCell: minHeightCellRenderer },
            { field: 'location', headerName: 'Location', width: 200, align: 'center', headerAlign: 'center', renderCell: minHeightCellRenderer },
            { field: 'subject', headerName: 'Subject', width: 200, renderCell: linkToInterviewRenderer, },
            { field: 'interviewNotes', headerName: 'Notes', width: 200, renderCell: htmlRenderer },
        ];
    }, []);

    const exportAsExcelHandler = useCallback(() => {
        const api = apiRef.current;
        if (api) {
            const reportName = excelName ? excelName : 'Interviews';
            const m = moment();
            const filename = reportName + m.format('YYYYMMDDhhmmss');
            api.exportDataAsExcel({ fileName: filename });
        }
    }, [apiRef, excelName]);

    const gridActions = useMemo(() => {
        const interviewId = selectionModel[0] ? +selectionModel[0] : 0;

        return (
            <ActionsDropDownButton color="secondary" label="List Actions">
                <MenuItem disabled={ selectionModel.length !== 1 } onClick={ () => setCancelInterviewId(interviewId) }>Cancel</MenuItem>
                {selectionModel.length !== 1 ?
                    <MenuItem disabled >Edit</MenuItem>
                    :
                    <Link to={`/interviews/${interviewId}/edit`} style={{ color: 'inherit', textDecoration: 'none' }}>
                        <MenuItem>Edit</MenuItem>
                    </Link>
                }
                <MenuItem onClick={ exportAsExcelHandler }>Export as excel</MenuItem>
            </ActionsDropDownButton>
        );
    }, [exportAsExcelHandler, selectionModel]);

    const defaultHiddenCols: GridColumnVisibilityModel = {
        'id': false,
        'interviewNotes': false
    };

    return (
        <>
            <ConfirmationDialog
                title="Confirm Action"
                message="Are you sure you want to cancel this meeting?"
                onClose={ () => setCancelInterviewId(null) }
                onContinue={ cancelMeetingCallback }
                open={ cancelInterviewId !== null }
                cancelActionText="No"
                confirmActionText="Yes"
            />
            {Boolean(gridActions) &&
                <Box pb="10px" ml="auto">
                    {gridActions}
                </Box>
            }
            <GridWithStateWrapper
                defaultViewModel={defaultHiddenCols}
                gridName={gridName}
                rows={rows}
                columns={columns}
                apiRef={apiRef}
                density="compact"
                disableRowSelectionOnClick
                rowSelectionModel={selectionModel}
                onRowSelectionModelChange={ sm  => setSelectionModel(sm) }
                pagination={true}
                pageSizeOptions={[100,250,500,1000]}
                getRowHeight={() => 'auto'}
            />
        </>
    );
}