import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { SxProps } from "@mui/material/styles";
import { Classification } from "common/models/JobPosting/Classifications";
import { AdvertLocation } from "common/models/JobPosting/Locations";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { GetAdvertsClassifications } from "services/ConfigurationService";

interface Props {
    parentId: number,
    childId: number,
    onParentChange: (item: Classification | null) => void,
    onChildChange: (item: Classification | null) => void,
    variant?: "standard" | "filled" | "outlined" | undefined,
    sx?: SxProps,
    error1?: boolean,
    error2?: boolean,
}

export default function AdvertClassificationsPicker({ parentId, childId, error1 = false, error2 = false, onParentChange, onChildChange, sx, variant }: Props) {
    const [classifications, setClassifications] = useState<Classification[]>([]);
    const [subClassifications, setSubClassifications] = useState<Record<number, Classification[]>>({});

    const parentChangeHandler = useCallback((l: AdvertLocation | null) => {
        onChildChange(null);
        onParentChange(l);
    }, [onChildChange, onParentChange]);

    useEffect(() => {
        const getSetupData = async () => {
            const res = await GetAdvertsClassifications();
            if (res) {
                let parents: Classification[] = [];
                let childrenMap: Record<number, Classification[]> = {};
                for (let i = 0; i < res.length; i++) {
                    const item = res[i];
                    if (item.parentID === 0) parents.push(item);
                    else {
                        const children = childrenMap[item.parentID];
                        if (children) children.push(item);
                        else childrenMap[item.parentID] = [item];
                    }
                }
                setClassifications(parents);
                setSubClassifications(childrenMap);
            }
        };
        getSetupData();
    }, []);

    const children = useMemo(() => {
        return subClassifications[parentId] ?? [];
    }, [parentId, subClassifications]);

    const selectedParent = useMemo(() => {
        const item = classifications.find(c => c.id === parentId);
        if (item) return item;
        if (parentId !== 0) parentChangeHandler(null);
        return null;
    }, [classifications, parentChangeHandler, parentId]);

    const selectedChild = useMemo(() => {
        const item = children.find(c => c.id === childId);
        if (item) return item;
        if (childId !== 0) onChildChange(null);
        return null;
    }, [childId, children, onChildChange]);
    
    return (
        <Box display="flex" gap="5px">
            <Autocomplete
                size="small"
                value={selectedParent}
                options={classifications}
                getOptionLabel={o => o.name}
                isOptionEqualToValue={(o, v) => o.id === v.id}
                onChange={ (e, v) => parentChangeHandler(v) }
                sx={sx}
                renderInput={ params => <TextField {...params} variant={variant} label="Classification" error={error1} inputProps={{ ...params.inputProps, "data-lpignore": "true" }} /> }
            />
            <Autocomplete
                size="small"
                value={selectedChild}
                options={children}
                getOptionLabel={o => o.name}
                isOptionEqualToValue={(o, v) => o.id === v.id}
                onChange={ (e, v) => onChildChange(v) }
                sx={sx}
                renderInput={ params => <TextField {...params} variant={variant} label="Sub Classification" error={error2} inputProps={{ ...params.inputProps, "data-lpignore": "true" }} /> }
            />
        </Box>
    );
}