import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import React, { useMemo, useState } from "react";
import JobsGridComponent from "components/Grids/JobsGrid";

interface Props {
    gridName: string,
}

export default function OpenJobsTableGraph({ gridName }: Props) {
    const [itemCount, setItemCount] = useState(0);

    const titleCounter = useMemo(() => {
        if (itemCount) return ` (${itemCount})`;
        return '';
    }, [itemCount]);

    return (
        <Paper sx={{ height: '100%', width: '100%' }}>
            <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                <Typography variant="h6" padding={1} textAlign="center" fontWeight={600} component="div">Open Jobs{titleCounter}</Typography>
                <JobsGridComponent
                    source="dashboard-widget"
                    jobState={1}
                    gridName={gridName}
                    setRowCountHandler={setItemCount}
                    hideActionsMenu
                />
            </div>
        </Paper>
    );
}