import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import React, { useState } from "react";

export default function CandidatePipelineGraphComponent() {
    const t = useTheme();

    return (
        <Paper sx={{display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h6" padding={1} textAlign="center" fontWeight={600} component="div">Candidate Pipeline is no longer a supported Dashboard Element</Typography>
        </Paper>
    );
}