import Backdrop from "@mui/material/Backdrop";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import RWTextFieldComponent from "components/RWTextFieldComponent";
import React, { useCallback, useState } from "react";
import { CloseOpportunity } from "services/OpportunitiesService";

interface Props {
    isOpen: boolean,
    opportunityId: number,
    opportunityName: string,
    closeHandler: () => void,
    successHandler?: (message: string) => void,
    errorHandler?: (message: string) => void
}

export default function CloseOpportunitiesDialog({ isOpen, opportunityId, opportunityName, closeHandler, successHandler, errorHandler }: Props) {
    const [isLoading, setIsLoading] = useState(false);
    const [reason, setReason] = useState(0);
    const [comments, setComments] = useState('');

    const submitCallback = useCallback(async () => {
        setIsLoading(true);
        const res = await CloseOpportunity(opportunityId, reason, comments, errorHandler);
        setIsLoading(false);
        if (res) {
            setReason(0);
            setComments('');
            successHandler && successHandler('Opportunity Closed');
            closeHandler();
        }
    }, [opportunityId, reason, comments, errorHandler, successHandler, closeHandler]);

    return (
        <>
            <Backdrop open={isLoading} sx={{ zIndex: (t) => t.zIndex.modal + 1 }} >
                <CircularProgress sx={{ color: 'text.disabled' }} />
            </Backdrop>
            <Dialog open={isOpen} fullWidth>
                <DialogTitle>Close Opportunity</DialogTitle>
                <DialogContent dividers>
                    <Stack spacing={2}>
                        <TextField
                            select
                            value={reason.toString()}
                            label="Reason"
                            onChange={ ({target}) => setReason(+target.value) }
                        >
                            <MenuItem value="0">None</MenuItem>
                            <MenuItem value="1">Administrative</MenuItem>
                            <MenuItem value="2">Budget not approved</MenuItem>
                            <MenuItem value="3">Converted - Won</MenuItem>
                            <MenuItem value="4">Lost to competitor</MenuItem>
                            <MenuItem value="5">Unknown</MenuItem>
                        </TextField>
                        <RWTextFieldComponent
                            value={comments}
                            label="Comments"
                            onChange={(e) => setComments(e.target.value) }
                            multiline={true}
                            minRows={12}
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="error" onClick={ closeHandler }>Cancel</Button>
                    <Button variant="contained" color="success" disabled={ reason === 0 } onClick={ submitCallback } >Submit</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
