import React, { useCallback, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { SxProps } from "@mui/material/styles";

interface Props {
    value: string,
    name?: string,
    label?: string,
    multiline?: boolean,
    rows?: string | number,
    sx?: SxProps,
    helperText?: React.ReactNode,
    validator?: (text: string) => boolean,
    onChange: (valueUpdated: string) => void
}


export default function SlowTextFieldWrapper({ label, sx, multiline, name, value, rows, validator, helperText, onChange }: Props) {
    const [inputValue, setInputValue] = useState(value);

    const localChangeHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const v = e.target.value;
        if (validator) {
            validator(v) && setInputValue(v);
            return;
        }
        setInputValue(v);
    }, [validator]);

    useEffect(() => {
        setInputValue(value);
    }, [value]);

    const blurHandler = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
        const v = e.target.value;
        onChange(v);
    }, [onChange]);

    return (
        <TextField
            sx={sx}
            name={name}
            label={label}
            value={inputValue}
            onChange={localChangeHandler}
            onBlur={blurHandler}
            fullWidth
            rows={rows}
            multiline={multiline}
            helperText={helperText}
        />
    );
}
