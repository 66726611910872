import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import CancelIcon from '@mui/icons-material/Cancel';
import IconButton from "@mui/material/IconButton";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { Editor } from "@tinymce/tinymce-react";
import * as tinymce from 'tinymce';
import MicIcon from '@mui/icons-material/Mic';
import StopIcon from '@mui/icons-material/Stop';
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { PREVIEW_DRAGGABLE_TITLE_STYLE } from "util/Definitions/Constants/Previews";
import ActionsSplitButton from "components/SummaryBars/Actions/ActionsSplitButton";
import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import MessageTemplatePicker from "components/Pickers/MessageTemplatePicker";
import { MessageTemplate } from "common/models/Templates/MessageTemplate";
import { GetMySignature } from "services/UsersService";
import { GetCustomerSettingBySettingName } from "services/ConfigurationService";
import FilePicker from "components/Pickers/FilePicker";
import { DownloadMessageTemplateAttachmentFile, GetMessageTemplateAttachments } from "services/TemplatesService";
import { SimpleFileObj } from "common/models/GenericTypes";
import { EmailRecipient, OnboardingData} from "common/models/EmailRecipient";
import RecipientsValidationDialog from "components/Dialogs/Messages/RecipientsValidationDialog";
import ManageRecipientsDialog from "components/Dialogs/Messages/ManageRecipientsDialog";
import EmailPreviewDialog from "components/Dialogs/Messages/EmailPreviewDialog";
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
import { SendOnboardingForm } from "services/MessagesService";
import ConfirmationWithDateDialog from "../Generic/ConfirmationWithDateDialog";
import moment from "moment";
import DraggablePaperComponent from 'components/DraggablePaperComponent'
import { AttachmentsLimitBytes } from "util/FilesUtils";
import { TemplatePlaceholder } from "common/models/Templates/TemplatePlaceholder";
import { GetPlaceholdersByEntityList } from "services/PlaceholdersService";
import InsertPlaceholderDialog from "../Generic/InsertPlaceholderDialog";
import {OnboardingForm} from "../../../common/models/Onboarding";
import {GetOnboardingForms} from "../../../services/OnboardingService";

interface Props {
    open: boolean,
    sourceEntityId: number,
    recipientEntityTypeId: number,
    recipients: EmailRecipient[],
    closeHandler: () => void,
    jobId?: number,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
    successHandler?: (message: string) => void
}

const onboardingPlaceholder: TemplatePlaceholder = { friendlyName: 'Onboarding - Form URL', placeholder: 'Onboarding.FormUrl', placeholderUsage: '#{Onboarding.FormUrl}', value: '' };
const jobEntityId = 4;

export default function SendOnboardingDialog({ open, sourceEntityId, recipientEntityTypeId: entityTypeId, recipients, closeHandler, loadingHandler, errorHandler, successHandler, jobId = 0 }: Props) {
    const [validRecipients, setValidRecipients] = useState<EmailRecipient[]>([]);
    const [invalidEmailRecipients, setInvalidEmailRecipients] = useState<EmailRecipient[]>([]);
    const [noContactRecipients, setNoContactRecipients] = useState<EmailRecipient[]>([]);
    const [optOutRecipients, setOptOutRecipients] = useState<EmailRecipient[]>([]);
    const [showRecipients, setShowRecipients] = useState(false);
    const [showPreview, setShowPreview] = useState(false);
    const [showPlaceholders, setShowPlaceholders] = useState(false);

    const [isContinueWithValids, setIsContinueWithValids] = useState(false);
    const [showValidation, setShowValidation] = useState(false);
    const [templateType, setTemplateType] = useState('Me');
    const [onboardingForm, setOnboardingForm] = useState(0);
    const [ccRecipients, setCcRecipients] = useState<string[]>([]);
    const [selectedTemplate, setSelectedTemplate] = useState<MessageTemplate | null>(null);
    const [subject, setSubject] = useState('');
    const [body, setBody] = useState('');
    const [signature, setSignature] = useState('');
    const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
    const [attachments, setAttachments] = useState<SimpleFileObj[]>([]);
    const [showCustomSend, setShowCustomSend] = useState(false);
    const [isIgnoreSendLimit, setIsIgnoreSendLimit] = useState(false);
    const [placeholders, setPlaceholders] = useState<TemplatePlaceholder[]>([]);
    const editorRef = useRef<tinymce.Editor | null>(null);
    const { browserSupportsSpeechRecognition, isMicrophoneAvailable, listening, interimTranscript, finalTranscript, resetTranscript } = useSpeechRecognition();
    const theme = useTheme();
    const isDarkTheme = useMemo(() => theme.palette.mode === 'dark', [theme.palette.mode]);
    const [onboardingForms, setOnboardingForms] = useState<OnboardingForm[] | null>(null);

    useEffect(() => {
        const getCustomerSetting = async () => {
            const res = await GetCustomerSettingBySettingName('EmailDeliverabilityChecksPassed');
            if (res && res === 'True') setIsIgnoreSendLimit(true);
        };
        getCustomerSetting();
    }, []);

    useEffect(() => {
        if (open && placeholders.length === 0) {
            const getPlaceholders = async () => {
                loadingHandler && loadingHandler(true);
                const res = await GetPlaceholdersByEntityList([entityTypeId, jobEntityId]);
                if (res) setPlaceholders([ onboardingPlaceholder, ...res ]);
                loadingHandler && loadingHandler(false);
            };
            getPlaceholders();
        }
    }, [jobId, loadingHandler, open, placeholders.length, entityTypeId]);

    useEffect(() => {
        const getData = async () => {
            const res = await GetOnboardingForms();
            if (res) setOnboardingForms(res);
        };
        getData();
    }, []);

    const allRecipientsValid = useMemo(() => {
        if (open) {
            let valids: EmailRecipient[] = [];
            let noEmails: EmailRecipient[] = [];
            let noContact: EmailRecipient[] = [];
            let optOuts: EmailRecipient[] = [];

            for (let i = 0; i < recipients.length; i++) {
                const r = recipients[i];
                if (r.isOptOut) optOuts.push(r);
                else if (!r.id) noContact.push(r);
                else if (r.email === '' || r.email === null || r.email === undefined) noEmails.push(r);
                else valids.push(r);
            }

            setValidRecipients(valids);
            setInvalidEmailRecipients(noEmails);
            setNoContactRecipients(noContact);
            setOptOutRecipients(optOuts);
            return noEmails.length === 0 && noContact.length === 0 && optOuts.length === 0;
        }
        return true;
    }, [recipients, open]);

    useEffect(() => {
        if (!open) {
            setSubject('');
            setAttachments([]);
            setFilesToUpload([]);
            setSelectedTemplate(null);
            setIsContinueWithValids(false);
            setCcRecipients([]);
        }
    }, [open]);

    useEffect(() => {
        const getAttachments = async () => {
            if (selectedTemplate) {
                const att = await GetMessageTemplateAttachments(selectedTemplate.id);
                if (att) {
                    let files: SimpleFileObj[] = [];
                    for (let i = 0; i < att.length; i++) {
                        const f = att[i];
                        files.push({ id: f.id, name: f.friendlyName, size: f.size })
                    }
                    if (files.length > 0) setAttachments(files);
                }
            }
        };

        open && selectedTemplate && getAttachments();
    }, [open, selectedTemplate]);

    useEffect(() => {
        const getSettings = async () => {
            const res = await GetMySignature();
            if (res) setSignature(res.value);
        };
        getSettings();
    }, []);

    useEffect(() => {
        if (browserSupportsSpeechRecognition && isMicrophoneAvailable && listening) {
            if (finalTranscript !== '' && editorRef.current) {
                editorRef.current.insertContent(finalTranscript + ' ');
                resetTranscript();
            }
        }
    }, [browserSupportsSpeechRecognition, isMicrophoneAvailable, listening, finalTranscript, resetTranscript]);

    const templateChangeHandlerCallback = useCallback((template: MessageTemplate | null) => {
        if (template) {
            const body = template.body.replaceAll('#{User.Signature}', signature);
            setSubject(template.subject);
            editorRef.current?.setContent(body);
        }
        setAttachments([]);
        setSelectedTemplate(template);
    }, [signature]);

    const addFilesCallback = useCallback((files: File[]) => {
        setFilesToUpload(prev => [...prev, ...files]);
    }, []);

    const removeUploadedFileCallback = useCallback((index: number) => {
        setAttachments(prev => {
            let att = [...prev];
            att.splice(index, 1);
            return att;
        });
    }, []);

    const removeFileCallback = useCallback((index: number) => {
        setFilesToUpload(prev => {
            let files = [...prev];
            files.splice(index, 1);
            return files;
        });
    }, []);

    const downloadFileCallback = useCallback(async (file: SimpleFileObj) => {
        if (selectedTemplate) {
            await DownloadMessageTemplateAttachmentFile(selectedTemplate.id, file.id);
        }
    }, [selectedTemplate]);

    const removeRecipientCallback = useCallback((index: number) => {
        setValidRecipients( prev => {
            let tmp = [...prev];
            if (tmp[index]) tmp.splice(index, 1);
            return tmp;
        });
    }, []);

    const showPreviewCallback = useCallback(() => {
        if (editorRef.current) {
            const body = editorRef.current.getContent();
            setBody(body);
            setShowPreview(true);
        }
    }, []);

    const sendEmailCallback = useCallback(async (minutesDelay: number = 0) => {
        
        let body = '';
        if (editorRef.current) body = editorRef.current.getContent();
        
        if (validRecipients.length === 0) {
            errorHandler && errorHandler("Empty list of recipients");
            return;
        }

        if (!body || !subject) {
            errorHandler && errorHandler("Body and Subject can't be empty");
            setShowValidation(true);
            return;
        }

        if (onboardingForm === 0) {
            errorHandler && errorHandler("Onboarding Form must be set");
            setShowValidation(true);
            return;
        }

        if (!body.includes("#{Onboarding.FormUrl}")) {
            errorHandler && errorHandler("Email must include the #{Onboarding.FormUrl} Placeholder");
            setShowValidation(true);
            return;
        }

        if (filesToUpload.length > 0) {
            const totalSizeToUpload = filesToUpload.reduce((total, item) => total + item.size, 0);
            if (totalSizeToUpload > AttachmentsLimitBytes) {
                errorHandler && errorHandler("The total size of Attachments cannot be more than 25MB");
                setShowValidation(true);
                return;
            }
        }
        
        let errorList: string[] = [];
        
        let templateFileIds: number[] = [];
        
        for (let i = 0; i < attachments.length; i++) {
            const att = attachments[i];
            templateFileIds.push(att.id);
        }
        
        loadingHandler && loadingHandler(true);
        for (let i = 0; i < validRecipients.length; i++) {
            const recipient = validRecipients[i];

            const onboardingData: OnboardingData = {
                onboardingFormId: onboardingForm,
                entityTypeId: entityTypeId,
                templateAttachmentIds: templateFileIds,
                jobId: jobId,
                recipientIds: [recipient.id],
                placementId: recipient.placement ? recipient.placement.id : 0,
                changeRequestId: recipient.changeRequest ? recipient.changeRequest.id : 0,
                subject: subject,
                templateId: selectedTemplate ? selectedTemplate.id : 0,
                body: body,
                ccRecipients: ccRecipients
            };

            const res = await SendOnboardingForm(onboardingData, filesToUpload, minutesDelay, errorHandler);
            if (!res) {
                const currentError = `${recipient.name} (${recipient.email})`;
                const index = errorList.findIndex(e => e === currentError);
                if (index === -1) errorList.push(currentError);
            }
        }

        if (errorList.length > 0) errorHandler && errorHandler(`There was an error sending the email to the following recipients: ${errorList.join(', ')}`);
        else {
            successHandler && successHandler('Email Sent');
            closeHandler();
        }
        loadingHandler && loadingHandler(false);
        return;
        
    }, [validRecipients, subject, loadingHandler, errorHandler, attachments, onboardingForm, entityTypeId, jobId, selectedTemplate, ccRecipients, filesToUpload, successHandler, closeHandler]);

    const sendEmailValidationCallback = useCallback((minutesDelay: number = 0) => {
        sendEmailCallback(minutesDelay);
    }, [sendEmailCallback]);

    const customSendHandlerCallback = useCallback((date: string) => {
        let delay = 0;
        const m = moment(date);
        const now = moment();

        if (m.isValid()) delay = m.diff(now, 'minutes');
        sendEmailValidationCallback(delay);
    }, [sendEmailValidationCallback]);

    const isOverLimit = useMemo(() => !isIgnoreSendLimit && validRecipients.length > 10, [isIgnoreSendLimit, validRecipients.length]);

    useEffect(() => {
        if (open && isOverLimit) {
            errorHandler && errorHandler("Your account is restricted to sending 10 emails at a time. Please contact our Support Team to discuss increasing the limit");
            if (allRecipientsValid) closeHandler();
        }
    }, [allRecipientsValid, closeHandler, errorHandler, isOverLimit, open]);

    const insertPlaceholderHandler = useCallback((placeholder: string) => {
        const api = editorRef.current;
        if (api) api.insertContent(placeholder);
    }, []);

    useEffect(() => {
        if (!open || showRecipients || showPreview || showPlaceholders || showCustomSend) SpeechRecognition.stopListening();
    }, [open, showCustomSend, showPlaceholders, showPreview, showRecipients]);

    return (
        <>
            <RecipientsValidationDialog
                open={open && !allRecipientsValid && !isContinueWithValids}
                entityTypeId={entityTypeId}
                totalRecipients={ recipients }
                validRecipients={ validRecipients }
                invalidEmailOrPhoneRecipients={ invalidEmailRecipients }
                noPrimaryContactRecipients={ noContactRecipients }
                optOutRecipients={ optOutRecipients }
                continueHandler={ () => setIsContinueWithValids(true) }
                closeHandler={closeHandler}
                isOverLimit={isOverLimit}
            />
            <ManageRecipientsDialog
                open={open && showRecipients}
                entityTypeId={entityTypeId}
                recipients={validRecipients}
                removeHandler={removeRecipientCallback}
                closeHandler={ () => setShowRecipients(false) }
            />
            <EmailPreviewDialog
                open={open && showPreview && validRecipients.length > 0}
                recipientsEntityId={entityTypeId}
                body={body}
                isDarkTheme={isDarkTheme}
                recipients={validRecipients}
                subject={subject}
                closeHandler={() => setShowPreview(false)}
                loadingHandler={loadingHandler}
            />
            <ConfirmationWithDateDialog
                open={ open && showCustomSend }
                title="Custom Date"
                onClose={ () => setShowCustomSend(false) }
                onContinue={ customSendHandlerCallback }
                dateType="datetime-local"
                dateFieldLabel="Send After"
                hideBackdrop
            />
            <InsertPlaceholderDialog
                open={showPlaceholders}
                closeHandler={() => setShowPlaceholders(false)}
                placeholders={placeholders}
                insertHandler={insertPlaceholderHandler}
            />
            <Dialog open={open && !isOverLimit && (allRecipientsValid || isContinueWithValids)} maxWidth="lg" fullWidth PaperComponent={DraggablePaperComponent} aria-labelledby="draggable-dialog-title" disableEnforceFocus>
                <DialogTitle component="div" display="flex" sx={PREVIEW_DRAGGABLE_TITLE_STYLE} id="draggable-dialog-title" >
                    <div style={{ alignSelf: 'center' }}>Send Onboarding Form</div>
                    <div style={{ marginLeft: 'auto' }}>
                        <IconButton size="small" onClick={() => setShowRecipients(true)} sx={{ color: t => t.palette.primary.contrastText }}><PeopleAltIcon /></IconButton>
                        <IconButton size="small" onClick={ closeHandler } sx={{ color: t => t.palette.primary.contrastText }}><CancelIcon /></IconButton>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}></Grid>
                        <Grid item xs={6}>
                            <TextField
                                select
                                fullWidth
                                label="Template Type"
                                value={templateType}
                                onChange={ ({target}) => setTemplateType(target.value) }
                            >
                                <MenuItem value="Me">My Templates</MenuItem>
                                <MenuItem value="Team">My Team's Templates</MenuItem>
                                <MenuItem value="All">All Templates</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={6}>
                            {open &&
                                <MessageTemplatePicker
                                    templateId={selectedTemplate ? selectedTemplate.id : null}
                                    includeCompanyOwned
                                    typeFilter={10}
                                    templateSource={templateType}
                                    entityTypeFilter={entityTypeId}
                                    onSelectHandler={ templateChangeHandlerCallback }
                                />
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                select
                                fullWidth
                                label="Onboarding Form"
                                value={onboardingForm}
                                onChange={ ({target}) => setOnboardingForm(+target.value) }
                                error={showValidation && onboardingForm === 0}
                            >
                                <MenuItem value="0"></MenuItem>
                                {onboardingForms && onboardingForms.map(q => <MenuItem key={q.id} value={q.id.toString()}>{q.name}</MenuItem>)}
                            </TextField>
                        </Grid>
                        
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Subject"
                                value={subject}
                                onChange={ ({target}) => setSubject(target.value) }
                                error={showValidation && subject === ''}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Editor
                                tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce-5.10.2/tinymce.min.js'}
                                onInit={ (evt, editor) => editorRef.current = editor }
                                initialValue={`<br />${signature}`}
                                init={{
                                    height: 380,
                                    skin: isDarkTheme ? 'oxide-dark' : undefined,
                                    content_css: isDarkTheme ? 'dark' : undefined,
                                    branding: false,
                                    menubar: false,
                                    statusbar: false,
                                    contextmenu: false,
                                    resize: false,
                                    browser_spellcheck: true,
                                    plugins: 'powerpaste code link emoticons table print preview visualchars lists fullscreen',
                                    powerpaste_word_import: 'prompt',
                                    powerpaste_html_import: 'prompt',
                                    powerpaste_allow_local_images: true,
                                    fontsize_formats: "8pt 9pt 10pt 12pt 14pt 18pt 24pt 36pt",
                                    toolbar1: 'placeholders fontsizeselect fontselect styleselect forecolor backcolor bold italic alignleft aligncenter alignright alignjustify bullist numlist code fullscreen link mybutton table',
                                    font_formats: 'Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Calibri=calibri; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Segoe=segoe,segoe ui,dejavu sans,trebuchet ms,verdana,sans-serif; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva',
                                    setup: editor => {
                                        editor.ui.registry.addButton('placeholders', {
                                            text: 'Insert Placeholder',
                                            onAction: () => setShowPlaceholders(true)
                                        });
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FilePicker
                                files={ filesToUpload }
                                uploadedFiles={ attachments }
                                addFilesHandler={ addFilesCallback }
                                removeFileHandler={ removeFileCallback }
                                removeUploadedFileHandler={ removeUploadedFileCallback }
                                downloadFileHandler={ downloadFileCallback }
                                limitSizeBytes={AttachmentsLimitBytes}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <div style={{ marginRight: 'auto', display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                        {browserSupportsSpeechRecognition && !listening &&
                            <IconButton onClick={ () => SpeechRecognition.startListening({language: 'en-AU', continuous: true}) }><MicIcon /></IconButton>
                        }
                        {browserSupportsSpeechRecognition && listening &&
                            <>
                                <IconButton onClick={ () => SpeechRecognition.stopListening() }><StopIcon /></IconButton>
                                {interimTranscript === '' && <span style={{ padding: '0 10px' }}>Listening...</span>}
                                <div style={{
                                    flexGrow: 1,
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    maxWidth: '510px',
                                    padding: '0 10px'
                                }} >{interimTranscript}</div>
                            </>
                        }
                    </div>
                    <Button variant="contained" onClick={ showPreviewCallback }>Preview</Button>
                    <ActionsSplitButton mainAction={ () => sendEmailValidationCallback(0) } mainActionDisabled={false} label="Send" color="success" menuPlacement="top-end">
                        <MenuItem onClick={ () => sendEmailValidationCallback(60) }>In an Hour</MenuItem>
                        <MenuItem onClick={ () => sendEmailValidationCallback(60 * 4) }>In 4 Hours</MenuItem>
                        <MenuItem onClick={ () => sendEmailValidationCallback(60 * 8) }>In 8 Hours</MenuItem>
                        <MenuItem onClick={ () => sendEmailValidationCallback(60 * 12) }>In 12 Hours</MenuItem>
                        <MenuItem onClick={ () => sendEmailValidationCallback(60 * 24) }>In 24 Hours</MenuItem>
                        <MenuItem onClick={ () => sendEmailValidationCallback(60 * 24 * 2) }>In 2 Days</MenuItem>
                        <MenuItem onClick={ () => sendEmailValidationCallback(60 * 24 * 3) }>In 3 Days</MenuItem>
                        <MenuItem onClick={ () => sendEmailValidationCallback(60 * 24 * 5) }>In 5 Days</MenuItem>
                        <MenuItem onClick={ () => sendEmailValidationCallback(60 * 24 * 7) }>In 1 Week</MenuItem>
                        <MenuItem onClick={ () => setShowCustomSend(true) }>Custom</MenuItem>
                    </ActionsSplitButton>
                </DialogActions>
            </Dialog>
        </>
    );
}
