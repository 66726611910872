import { Group, GroupParticipant } from '../common/models/Group';
import { RequestOptions, ErrorHandler } from '../common/models/GenericTypes';
import { Get, Post, Put } from '../util/HttpUtils';

export async function GetAllGroups(errorHandler?: ErrorHandler): Promise<Group[] | null> {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Group[]>('api/groups/all', options);
}

export async function GetGroup(id: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Group>(`api/groups/${id}`, options);
}

export async function GetMembers(id: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<GroupParticipant[]>(`api/groups/${id}/members`, options);
}

export async function GetManagers(id: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<GroupParticipant[]>(`api/groups/${id}/managers`, options);
}

export async function CreateGroup(group: Group, memberIds: number[] | null, managerIds: number[] | null, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>('api/groups', options, { group, memberIds, managerIds })
}

export async function UpdateGroup(id: number, group: Group, memberIds: number[] | null, managerIds: number[] | null, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/groups/${id}`, options, { group, memberIds, managerIds } );
}