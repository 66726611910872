import React, { useCallback, useEffect, useMemo, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import moment from "moment";
import { ChangePlacementClientRef } from "services/PlacementsService";
import { PlacementStatusEnum } from "util/Definitions/Configuration/Placements";
import ConfirmationDialog from "../Generic/ConfirmationDialog";
import RWTextFieldComponent from "components/RWTextFieldComponent";

interface Props {
    open: boolean,
    placementId: number,
    clientRef: string,
    placementStatusId: number,
    closeHandler: () => void,
    loadingHandler?: (isLoading: boolean) => void,
    successHandler?: (message: string, clientRef: string) => void,
    errorHandler?: (message: string) => void
}

export default function ChangeClientRefDialog({ open, placementId, clientRef: clientRefProp, placementStatusId, closeHandler, loadingHandler, successHandler, errorHandler }: Props) {
    const [showWarning, setShowWarning] = useState(false);
    const [clientRef, setClientRef] = useState('');

    const warningText = useMemo(() => {
        if (placementStatusId === PlacementStatusEnum.Completed || placementStatusId === PlacementStatusEnum.EarlyTerminated) return 'This placement is already Completed. Are you sure you want to make this change?';
        return '';
    }, [placementStatusId]);

    useEffect(() => {
        if (open && Boolean(warningText)) setShowWarning(true);
    }, [open, warningText]);

    useEffect(() => {
        if (open) setClientRef(clientRefProp);
    }, [open, clientRefProp]);

    const effectiveDate = useMemo(() => {
        return moment().format('DD MMM YYYY');
    }, []);

    const submitCallback = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        const res = await ChangePlacementClientRef(placementId, clientRef, errorHandler);
        if (res) {
            successHandler && successHandler('Client Ref Changed', clientRef);
            closeHandler();
        }
        loadingHandler && loadingHandler(false);
    }, [placementId, clientRef, loadingHandler, successHandler, errorHandler, closeHandler]);

    return (
        <>
            <ConfirmationDialog
                open={open && showWarning && Boolean(warningText)}
                message={warningText}
                onClose={closeHandler}
                title="Change Client Ref"
                onContinue={() => setShowWarning(false)}
            />
            <Dialog open={open && !showWarning} fullWidth>
                <DialogTitle>Change Client Ref</DialogTitle>
                <DialogContent>
                    <Stack mt="10px" spacing={2}>
                        <TextField label="Effective Date" value={effectiveDate} InputProps={{ readOnly: true }} />
                        <RWTextFieldComponent label='Client Ref' value={clientRef} onChange={(e) => setClientRef(e.target.value)} />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="error" onClick={ closeHandler }>Cancel</Button>
                    <Button variant="contained" color="success" disabled={clientRef === clientRefProp.toString()} onClick={ submitCallback } >Submit</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}