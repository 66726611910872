import Alert from "components/Alert";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { CustomerIntegration } from "common/models/Configuration/CustomerIntegrations";
import CustomContentConfirmationDialog from "components/Dialogs/Generic/CustomContentConfirmationDialog";
import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import PageContentLayout from "layouts/PageContentLayout";
import { Connect_ActiveCampaign, Connect_Aircall, Connect_AskNicely, Connect_CampaignMonitor, Connect_Deputy, Connect_FoundU, Connect_Mailchimp, Connect_Microsoft365, Connect_OutsourcingWizard, Connect_Refari, Connect_Referoo, Connect_SurveyMonkey, Connect_WorkPro, Disconnect_ActiveCampaign, Disconnect_Aircall, Disconnect_AskNicely, Disconnect_CampaignMonitor, Disconnect_Deputy, Disconnect_FoundU, Disconnect_Mailchimp, Disconnect_Microsoft365, Disconnect_OutsourcingWizard, Disconnect_Refari, Disconnect_Referoo, Disconnect_SurveyMonkey, Disconnect_WorkPro, GetRedirectUrl_Aircall, GetRedirectUrl_CampaignMonitor, GetRedirectUrl_Deputy, GetRedirectUrl_FoundU, GetRedirectUrl_Mailchimp, GetRedirectUrl_Microsoft365, GetRedirectUrl_Referoo, GetRedirectUrl_SurveyMonkey, GetWebHookUrl_AskNicely } from "services/CustomerIntegrationsService";
import { GetIntegration } from "services/CustomerIntegrationsService";
import { IsValidNumericValue } from "util/RegExUtils";
import RWTextFieldComponent from "components/RWTextFieldComponent";

interface Props {
    setSummaryBar?: (summaryBar: JSX.Element) => void
}

const nonConfigurableIntegrationIds = [19, 20, 21, 23];
const ActiveCampaignSubdomainAdornment = <InputAdornment position="end">.activehosted.com</InputAdornment>;
const FoundUSubdomainAdornment = <InputAdornment position="end">.foundu.com.au</InputAdornment>;
const AskNicelySubdomainAdornment = <InputAdornment position="end">.asknice.ly</InputAdornment>;

export default function CustomerIntegrationItem({ setSummaryBar }: Props) {
    const [isLoading, setIsLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [integration, setIntegration] = useState<CustomerIntegration | null>(null);
    const [showDialog, setShowDialog] = useState(false);
    const [subdomain, setSubdomain] = useState('');
    const [apiKey, setApiKey] = useState('');
    const [minutesDelay, setMinutesDelay] = useState('');
    const [webHookUrl, setWebHookUrl] = useState('');
    const [siteId, setSiteId] = useState('');
    const [searchParams] = useSearchParams();
    const params = useParams();

    const itemId = useMemo(() => {
        const parsedId = +(params.id ?? '0');
        if(isNaN(parsedId)) return 0;
        return parsedId
    }, [params.id]);

    useEffect(() => {
        const handleFromRedirect = async () => {
            if (integration && integration.statusID === 0) {
                setIsLoading(true);
                switch (itemId) {
                    case 1:
                        const code1 = searchParams.get('code');
                        if (code1) {
                            const res = await Connect_Referoo(code1, setErrorMessage);
                            if (res) {
                                setSuccessMessage('Connected');
                                setIntegration(prev => prev ? ({...prev, statusID: 1}) : prev);
                            }
                        }
                    break;
                    case 2:
                        const code2 = searchParams.get('code');
                        if (code2) {
                            const res2 = await Connect_Aircall(code2, setErrorMessage);
                            if (res2) {
                                setSuccessMessage('Connected');
                                setIntegration(prev => prev ? ({...prev, statusID: 1}) : prev);
                            }
                        }
                    break;
                    case 6:
                        const tenant = searchParams.get('tenant');
                        if (tenant) {
                            const res = await Connect_Microsoft365(tenant, setErrorMessage);
                            if (res) {
                                setSuccessMessage('Connected');
                                setIntegration(prev => prev ? ({...prev, statusID: 1}) : prev);
                            }
                        }
                    break;
                    case 8:
                        const code8 = searchParams.get('code');
                        if (code8) {
                            const res = await Connect_Mailchimp(code8, setErrorMessage);
                            if (res) {
                                setSuccessMessage('Connected');
                                setIntegration(prev => prev ? ({...prev, statusID: 1}) : prev);
                            }
                        }
                    break;
                    case 9:
                        const code9 = searchParams.get('code');
                        if (code9) {
                            const res = await Connect_CampaignMonitor(code9, setErrorMessage);
                            if (res) {
                                setSuccessMessage('Connected');
                                setIntegration(prev => prev ? ({...prev, statusID: 1}) : prev);
                            }
                        }
                    break;
                    case 16:
                        const code16 = searchParams.get('code');
                        if (code16) {
                            const res = await Connect_FoundU(code16, setErrorMessage);
                            if (res) {
                                setSuccessMessage('Connected');
                                setIntegration(prev => prev ? ({...prev, statusID: 1}) : prev);
                            }
                        }
                    break;
                    case 27:
                        const code27 = searchParams.get('code');
                        if (code27) {
                            const res = await Connect_Deputy(code27, setErrorMessage);
                            if (res) {
                                setSuccessMessage('Connected');
                                setIntegration(prev => prev ? ({...prev, statusID: 1}) : prev);
                            }
                        }
                    break;
                    case 28:
                        const code28 = searchParams.get('code');
                        if (code28) {
                            const res = await Connect_SurveyMonkey(code28, setErrorMessage);
                            if (res) {
                                setSuccessMessage('Connected');
                                setIntegration(prev => prev ? ({...prev, statusID: 1}) : prev);
                            }
                        }
                    break;
                }
                setIsLoading(false);
            }
        };
        integration && integration.statusID === 0 && handleFromRedirect();
    }, [integration, itemId, searchParams]);

    const connectCallback = useCallback(async () => {
        setIsLoading(true);
        switch (itemId) {
            case 1:
                const res1 = await GetRedirectUrl_Referoo(setErrorMessage);
                if (res1 && res1.value) window.open(res1.value, "_self");
                break;
            case 2:
                const res2 = await GetRedirectUrl_Aircall(setErrorMessage);
                if (res2 && res2.value) window.open(res2.value, "_self");
                break;
            case 6:
                const res6 = await GetRedirectUrl_Microsoft365(setErrorMessage);
                if (res6 && res6.value) window.open(res6.value, "_self");
                break;
            case 8:
                const res8 = await GetRedirectUrl_Mailchimp(setErrorMessage);
                if (res8 && res8.value) window.open(res8.value, "_self");
                break;
            case 9:
                const res9 = await GetRedirectUrl_CampaignMonitor(setErrorMessage);
                if (res9 && res9.value) window.open(res9.value, "_self");
                break;
            case 12:
                setShowDialog(true);
                break;
            case 13:
                const res13 = await Connect_OutsourcingWizard(setErrorMessage);
                if (res13) {
                    setSuccessMessage('Connected');
                    setIntegration(prev => prev ? ({...prev, statusID: 1}) : prev);
                }
                break;
            case 14:
                setShowDialog(true);
                break;
            case 15:
                setShowDialog(true);
                break;
            case 16:
                setShowDialog(true);
                break;
            case 27:
                const res27 = await GetRedirectUrl_Deputy(setErrorMessage);
                if (res27 && res27.value) window.open(res27.value, "_self");
                break;
            case 28:
                const res28 = await GetRedirectUrl_SurveyMonkey(setErrorMessage);
                if (res28 && res28.value) window.open(res28.value, "_self");
                break;
            case 29:
                setShowDialog(true);
        }
        setIsLoading(false);
    }, [itemId]);

    const connectFromDialogCallback = useCallback(async () => {
        setIsLoading(true);
        switch (itemId) {
            case 12:
                const res12 = await Connect_ActiveCampaign(subdomain, apiKey, setErrorMessage);
                if (res12) {
                    setSuccessMessage('Connected');
                    setIntegration(prev => prev ? ({...prev, statusID: 1}) : prev);
                    setSubdomain('');
                    setApiKey('');
                    setShowDialog(false);
                }
                break;
            case 14:
                const res14 = await Connect_AskNicely(subdomain, apiKey, minutesDelay, setErrorMessage);
                if (res14) {
                    setSuccessMessage('Connected');
                    setIntegration(prev => prev ? ({...prev, statusID: 1}) : prev);
                    setSubdomain('');
                    setApiKey('');
                    setMinutesDelay('');
                    setShowDialog(false);
                }
                break;
            case 15:
                const res15 = await Connect_Refari(apiKey, setErrorMessage);
                if (res15) {
                    setSuccessMessage('Connected');
                    setIntegration(prev => prev ? ({...prev, statusID: 1}) : prev);
                    setApiKey('');
                    setShowDialog(false);
                }
                break;
            case 16:
                const res16 = await GetRedirectUrl_FoundU(subdomain, setErrorMessage);
                if (res16 && res16.value) window.open(res16.value, "_self");
                break;
            case 29:
                const res29 = await Connect_WorkPro(siteId, setErrorMessage);
                if (res29) {
                    setSuccessMessage('Connected');
                    setIntegration(prev => prev ? ({...prev, statusID: 1}) : prev);
                    setSiteId('');
                    setShowDialog(false);
                }
                break;
        }
        setIsLoading(false);
    }, [itemId, subdomain, apiKey, minutesDelay, siteId]);

    const disconnectCallback = useCallback(async () => {
        let success = false;
        setIsLoading(true);
        switch (itemId) {
            case 1:
                const res1 = await Disconnect_Referoo(setErrorMessage);
                if (res1) success = true;
                break;
            case 2:
                const res2 = await Disconnect_Aircall(setErrorMessage);
                if (res2) success = true;
                break;
            case 6:
                const res6 = await Disconnect_Microsoft365(setErrorMessage);
                if (res6) success = true;
                break;
            case 8:
                const res8 = await Disconnect_Mailchimp(setErrorMessage);
                if (res8) success = true;
                break;
            case 9:
                const res9 = await Disconnect_CampaignMonitor(setErrorMessage);
                if (res9) success = true;
                break;
            case 12:
                const res12 = await Disconnect_ActiveCampaign(setErrorMessage);
                if (res12) success = true;
                break;
            case 13:
                const res13 = await Disconnect_OutsourcingWizard(setErrorMessage);
                if (res13) success = true;
                break;
            case 14:
                const res14 = await Disconnect_AskNicely(setErrorMessage);
                if (res14) success = true;
                break;
            case 15:
                const res15 = await Disconnect_Refari(setErrorMessage);
                if (res15) success = true;
                break;
            case 16:
                const res16 = await Disconnect_FoundU(setErrorMessage);
                if (res16) success = true;
                break;
            case 27:
                const res27 = await Disconnect_Deputy(setErrorMessage);
                if (res27) success = true;
                break;
            case 28:
                const res28 = await Disconnect_SurveyMonkey(setErrorMessage);
                if (res28) success = true;
                break;
            case 29:
                const res29 = await Disconnect_WorkPro(setErrorMessage);
                if (res29) success = true;
                break;
        }

        if (success) {
            setSuccessMessage('Disconnected');
            setIntegration(prev => prev ? ({...prev, statusID: 0}) : prev);
        }
        setIsLoading(false);
    }, [itemId]);

    useEffect(() => {
        const isNonConfigurable = nonConfigurableIntegrationIds.includes(itemId);
        const statusId = integration ? integration.statusID : 0;
        const connectButton = isNonConfigurable 
            ? <></>
            : <Button variant="contained" color="primary" onClick={statusId === 1 ? disconnectCallback : connectCallback}>{statusId === 1 ? 'Disconnect' : 'Connect'}</Button>
        const summaryBar = (
            <TitleAndActionSummaryBar
                title="Configuration > Integrations"
                browserTabTitle="Integrations > Configuration"
                action={connectButton}
            />
        );
        setSummaryBar && setSummaryBar(summaryBar);
    }, [setSummaryBar, integration, itemId, connectCallback, disconnectCallback]);
    
    useEffect(() => {
        const getData = async () => {
            setIsLoading(true);
            const data = await GetIntegration(itemId, setErrorMessage);
            if(data) setIntegration(data);
            if (itemId === 14) {
                const res = await GetWebHookUrl_AskNicely(setErrorMessage);
                if (res && res.value) setWebHookUrl(res.value);
            }
            setIsLoading(false);
        };
        getData();
    }, [itemId]);

    const onMinutesDelayChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        if(IsValidNumericValue(value)) {
            setMinutesDelay(value);
        }
    }, []);

    return (
        <>
            <Snackbar open={successMessage !== ''} autoHideDuration={3000} onClose={() => setSuccessMessage('')}>
                <Alert onClose={() => setSuccessMessage('')}>{ successMessage }</Alert>
            </Snackbar>
            <Snackbar open={errorMessage !== ''}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <CustomContentConfirmationDialog
                open={showDialog && itemId === 12}
                cancelHandler={() => setShowDialog(false)}
                confirmHandler={connectFromDialogCallback}
                title="Active Campaign"
                confirmButtonText="Connect"
                isConfirmButtonDisabled={itemId !== 12 || !Boolean(subdomain.trim()) || !Boolean(apiKey.trim())}
                fullWidth
            >
                <Stack spacing={2} mt="10px">
                    <TextField label="Subdomain" value={subdomain} onChange={ ({target}) => setSubdomain(target.value) } InputProps={{ endAdornment: ActiveCampaignSubdomainAdornment }} />
                    <RWTextFieldComponent label="API Key" value={apiKey} onChange={ (e) => setApiKey(e.target.value) } />
                </Stack>
            </CustomContentConfirmationDialog>
            <CustomContentConfirmationDialog
                open={showDialog && itemId === 14}
                cancelHandler={() => setShowDialog(false)}
                confirmHandler={connectFromDialogCallback}
                title="Ask Nicely"
                confirmButtonText="Connect"
                isConfirmButtonDisabled={itemId !== 14 || !Boolean(subdomain.trim()) || !Boolean(apiKey.trim()) || !Boolean(minutesDelay.trim()) || !Boolean(webHookUrl.trim())}
                fullWidth
            >
                <Stack spacing={2} mt="10px">
                    <TextField label="Subdomain" value={subdomain} onChange={ ({target}) => setSubdomain(target.value) } InputProps={{ endAdornment: AskNicelySubdomainAdornment }} />
                    <RWTextFieldComponent label="API Key" value={apiKey} onChange={ ({target}) => setApiKey(target.value) } />
                    <RWTextFieldComponent label="Sending Delay (Minutes)" value={minutesDelay} onChange={ onMinutesDelayChange } />
                    <TextField label="WebHook URL" value={webHookUrl} InputProps={{ readOnly: true }} />
                </Stack>
            </CustomContentConfirmationDialog>
            <CustomContentConfirmationDialog
                open={showDialog && itemId === 15}
                cancelHandler={() => setShowDialog(false)}
                confirmHandler={connectFromDialogCallback}
                title="Refari"
                confirmButtonText="Connect"
                isConfirmButtonDisabled={itemId !== 15 || !Boolean(apiKey.trim())}
                fullWidth
            >
                <Stack spacing={2} mt="10px">
                    <RWTextFieldComponent label="API Key" value={apiKey} onChange={ ({target}) => setApiKey(target.value) } />
                </Stack>
            </CustomContentConfirmationDialog>
            <CustomContentConfirmationDialog
                open={showDialog && itemId === 16}
                cancelHandler={() => setShowDialog(false)}
                confirmHandler={connectFromDialogCallback}
                title="FoundU"
                confirmButtonText="Connect"
                isConfirmButtonDisabled={itemId !== 16 || !Boolean(subdomain.trim())}
                fullWidth
            >
                <Stack spacing={2} mt="10px">
                    <TextField label="Subdomain" value={subdomain} onChange={ ({target}) => setSubdomain(target.value) } InputProps={{ endAdornment: FoundUSubdomainAdornment }} />
                </Stack>
            </CustomContentConfirmationDialog>
            <CustomContentConfirmationDialog
                open={showDialog && itemId === 29}
                cancelHandler={() => setShowDialog(false)}
                confirmHandler={connectFromDialogCallback}
                title="WorkPro"
                confirmButtonText="Connect"
                isConfirmButtonDisabled={itemId !== 29 || !Boolean(siteId.trim()) || !IsValidNumericValue(siteId)}
                fullWidth
            >
                <Stack spacing={2} mt="10px">
                    <TextField label="Site ID (Numeric Value)" value={siteId} onChange={ ({target}) => setSiteId(target.value) } />
                </Stack>
            </CustomContentConfirmationDialog>
            <PageContentLayout title={integration != null ? `${integration.name} Integration` : 'Integration'} showLoading={isLoading}>
                {integration && <img src={`https://cdn.recruitwizard.com/images/integrations/${integration.logoUrl}`} style={{ maxHeight: '50px', width: 'fit-content', marginBottom: '20px' }} alt="logo" />}
                {integration !== null && <div dangerouslySetInnerHTML={{ __html: integration.description }} />}
            </PageContentLayout>
        </>
    );
}