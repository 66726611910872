import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import SettingsIcon from '@mui/icons-material/Settings';
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Cell, Label, Pie, PieChart, ResponsiveContainer } from "recharts";
import CircularProgress from "@mui/material/CircularProgress";
import useActiveUsers from "hooks/UseActiveUsers";
import moment from "moment";
import { GetKpiOverviewData } from "services/BusinessIntelligence";
import { FilterWithTimeRange } from "common/models/Analytics/AnalyticsFilterData";
import { KpiOverview } from "common/models/Analytics/BusinessIntelligence";
import useActiveGroups from "hooks/UseActiveGroups";
import RWTextFieldComponent from "components/RWTextFieldComponent";


interface Props {
    id: string,
    title: string,
    sourceType: string,
    sourceId: number,
    timeRange: number,
    type: string,
    showConfigButton?: boolean,
    saveConfigHandler?: (id: string, newTitle: string, newTimeRange: number, newKpiType: string, newSourceType: string, newSourceId: number) => void
}

const testData = [
    { name: 'Group A', value: 100 },
];

const getDatesFromTimeRange = (tr: number) => {
    let start: moment.Moment | null = null;
    let end: moment.Moment | null = null;
    if (tr === 0) return;
    if (tr === 1) { // This Week
        start = moment().startOf('isoWeek');
        end = moment().endOf('isoWeek');
    }
    else if (tr === 2) { // This Month
        start = moment().startOf('month');
        end = moment().endOf('month');
    }
    else if (tr === 3) { // This Quarter
        start = moment().startOf('quarter');
        end = moment().endOf('quarter');
    }
    else if (tr === 4) { // This Year
        start = moment().startOf('year');
        end = moment().endOf('year');
    }
    else if (tr === 5) { // Last Week
        start = moment().subtract(1,'week').startOf('isoWeek');
        end = moment().subtract(1,'week').endOf('isoWeek');
    }
    else if (tr === 6) { // Last Month
        start = moment().subtract(1, 'months').startOf('month');
        end = moment().subtract(1, 'months').endOf('month');
    }
    else if (tr === 7) { // Last Quarter
        start = moment().subtract(1, 'quarter').startOf('quarter');
        end = moment().subtract(1, 'quarter').endOf('quarter');
    }
    else if (tr === 8) { // Last Year
        const lastYear = moment().get('year') - 1;
        start = moment(`${lastYear}-01-01`);
        end = moment(`${lastYear}-12-31`);
    }
    else if (tr === 9) { // Last 7 Days
        start = moment().subtract(6, 'days');
        end = moment();
    }
    else if (tr === 10) { // Last 30 Days
        start = moment().subtract(30, 'days');
        end = moment();
    }
    else if (tr === 11) { // Last 90 Days
        start = moment().subtract(90, 'days');
        end = moment();
    }
    else if (tr === 12) { // Last 365 Days
        start = moment().subtract(365, 'days');
        end = moment();
    }
    return [start, end];
};

export default function KpiGaugeGraphComponent({ id, title: titleProp, sourceType: sourceTypeProp, sourceId: sourceIdProp, timeRange: timeRangeProp, type: typeProp, showConfigButton, saveConfigHandler }: Props) {
    const [title, setTitle] = useState('');
    const [sourceType, setSourceType] = useState('consultant');
    const [sourceId, setSourceId] = useState(-1);
    const [timeRange, setTimeRange] = useState(1);
    const [type, setType] = useState('bdCalls');
    const [showConfigDialog, setShowConfigDialog] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [kpiData, setKpiData] = useState<KpiOverview | null>(null);
    const users = useActiveUsers();
    const groups = useActiveGroups();
    const t = useTheme();

    const actual = useMemo(() => kpiData ? (kpiData as any)[`${typeProp}_Actual`] as number : 0, [kpiData, typeProp]);
    const target = useMemo(() => kpiData ? (kpiData as any)[`${typeProp}_Target`] as number : 0, [kpiData, typeProp]);
    const targetToDate = useMemo(() => kpiData ? (kpiData as any)[`${typeProp}_TargetToDate`] as number : 0, [kpiData, typeProp]);
    const colorProp = useMemo(() => kpiData ? (kpiData as any)[`${typeProp}_Formatting`] as string : '', [kpiData, typeProp]);
    const explanation = useMemo(() => kpiData ? (kpiData as any)[`${typeProp}_Explaination`] as string : '', [kpiData, typeProp]);

    useEffect(() => {
        if (showConfigDialog) {
            setTitle(titleProp)
            setTimeRange(timeRangeProp);
            setType(typeProp);
            setSourceType(sourceTypeProp)
            setSourceId(sourceIdProp);
        }
    }, [showConfigDialog, timeRangeProp, typeProp, sourceIdProp, sourceTypeProp, titleProp]);

    useEffect(() => {
        const getKpiData = async () => {
            setIsLoading(true);
            let range = getDatesFromTimeRange(timeRangeProp);
            if (range) {
                const [start, end] = range;
                if (start && end) {
                    // 1 - Company
                    // 2 - Division
                    // 3 - Group
                    // 4 - User
                    let userFilterTypeId = 4;
                    if (sourceTypeProp === 'group') userFilterTypeId = 3;
                    else if (sourceIdProp === -3) userFilterTypeId = 1
                    const f: FilterWithTimeRange = {
                        endDate: end.format('YYYY-MM-DD'),
                        startDate: start.format('YYYY-MM-DD'),
                        userFilterID: sourceIdProp.toString(),
                        userFilterTypeID: userFilterTypeId
                    };

                    const res = await GetKpiOverviewData(f);
                    if (res) setKpiData(res);
                }
            }
            setIsLoading(false);
        };
        getKpiData();
    }, [sourceIdProp, timeRangeProp, sourceTypeProp]);
    
    const color = useMemo(() => {
        switch (colorProp) {
            case 'green': return t.palette.success.main;
            case 'orange': return t.palette.warning.main;
            case 'red': return t.palette.error.main;
            default: return t.palette.info.main;
        }
    }, [colorProp, t.palette.error.main, t.palette.info.main, t.palette.success.main, t.palette.warning.main]);

    const getAngleValue = useCallback((v: number) => {
        if (target === 0) return (v * 360);
        return (v * 360) / target;
    }, [target]);

    const targetToDateAngle = useMemo(() => {
        return getAngleValue(targetToDate);
    }, [getAngleValue, targetToDate]);

    const actualAngle = useMemo(() => {
        return getAngleValue(actual);
    }, [actual, getAngleValue]);

    const percentage = useMemo(() => {
        if (target === 0) return Math.round(actual * 100) + '%';
        return Math.round((actual * 100) / target) + '%';
    }, [actual, target]);

    const onSaveConfig = useCallback(() => {
        saveConfigHandler && saveConfigHandler(id, title, timeRange, type, sourceType, sourceId);
        setShowConfigDialog(false);
    }, [saveConfigHandler, id, title, timeRange, type, sourceType, sourceId]);

    const sourceTypeChangeHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setSourceId(0);
        setSourceType(value);
    }, []);
    
    return (
        <>
            <Dialog open={showConfigDialog} fullWidth>
                <DialogTitle>Configure Element</DialogTitle>
                <DialogContent>
                    <Stack spacing={2} mt="10px">
                        <RWTextFieldComponent label="Title" value={title} onChange={(e) => setTitle(e.target.value)} />
                        <TextField select label="Source Type" value={sourceType} onChange={sourceTypeChangeHandler} fullWidth>
                            <MenuItem value="consultant">Consultant</MenuItem>
                            <MenuItem value="group">Group</MenuItem>
                        </TextField>
                        {sourceType === 'consultant' &&
                            <TextField select label="Consultant" value={sourceId.toString()} onChange={({target}) => setSourceId(+target.value)} fullWidth>
                                <MenuItem value="0"></MenuItem>
                                <MenuItem value="-1">Me</MenuItem>
                                <MenuItem value="-3">My Company</MenuItem>
                                {users.map(u => <MenuItem key={u.id} value={u.id}>{u.name}</MenuItem>)}
                            </TextField>
                        }
                        {sourceType === 'group' &&
                            <TextField select label="Group" value={sourceId.toString()} onChange={({target}) => setSourceId(+target.value)} fullWidth>
                                <MenuItem value="0"></MenuItem>
                                {groups.map(g => <MenuItem key={g.id} value={g.id.toString()}>{g.name}</MenuItem>)}
                            </TextField>
                        }
                        <TextField select label="Time Range" value={timeRange.toString()} onChange={({target}) => setTimeRange(+target.value)} fullWidth>
                            <MenuItem value="1">This Week</MenuItem>
                            <MenuItem value="2">This Month</MenuItem>
                            <MenuItem value="3">This Quarter</MenuItem>
                            <MenuItem value="4">This Year</MenuItem>
                            <MenuItem value="5">Last Week</MenuItem>
                            <MenuItem value="6">Last Month</MenuItem>
                            <MenuItem value="7">Last Quarter</MenuItem>
                            <MenuItem value="8">Last Year</MenuItem>
                            <MenuItem value="9">Last 7 Days</MenuItem>
                            <MenuItem value="10">Last 30 Days</MenuItem>
                            <MenuItem value="11">Last 90 Days</MenuItem>
                            <MenuItem value="12">Last 365 Days</MenuItem>
                        </TextField>
                        <TextField select label="Type" value={type} onChange={({target}) => setType(target.value)} fullWidth>
                            <MenuItem value="bdCalls">BD Calls</MenuItem>
                            <MenuItem value="clientVisits">Client Meetings</MenuItem>
                            <MenuItem value="newJobs">New Jobs</MenuItem>
                            <MenuItem value="floats">Floats</MenuItem>
                            <MenuItem value="submissions">Submissions</MenuItem>
                            <MenuItem value="firstInterview">1st Client Interviews</MenuItem>
                            <MenuItem value="placements">Placements</MenuItem>
                        </TextField>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="error" onClick={() => setShowConfigDialog(false)}>Cancel</Button>
                    <Button variant="contained" color="success" onClick={onSaveConfig}>Save</Button>
                </DialogActions>
            </Dialog>
            {showConfigButton &&
                <IconButton
                    sx={{ position: 'absolute', right: 30, zIndex: t => t.zIndex.appBar - 5, color: t => t.palette.text.disabled }}
                    onClick={() => setShowConfigDialog(true)}
                >
                    <SettingsIcon />
                </IconButton>
            }
            <Paper sx={{ height: '100%', p: '8px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <Box display="flex">
                    <Box flexGrow={1}>
                        <Typography component="div" variant="body1" fontWeight="bold">{titleProp}</Typography>
                        <Typography component="div" variant="body2">Target: {target}</Typography>
                    </Box>
                    <Box>
                        <Typography component="div" variant="body1" fontWeight="bold">&nbsp;</Typography>
                        <Typography component="div" variant="body2">Achieved: {actual}</Typography>
                    </Box>
                </Box>
                {isLoading && <Box m="auto"><CircularProgress /></Box> }
                <ResponsiveContainer height={150} style={{ display: isLoading ? 'none' : undefined }}>
                    <PieChart height={150}>
                        <Pie
                            data={testData}
                            dataKey="value"
                            startAngle={90}
                            endAngle={90 - targetToDateAngle}
                            innerRadius={40}
                            outerRadius={60}
                        >
                            <Cell fill={t.palette.text.disabled} />
                        </Pie>
                        <Pie
                            data={testData}
                            dataKey="value"
                            startAngle={90}
                            endAngle={90 - actualAngle}
                            innerRadius={40}
                            outerRadius={60}
                        >
                            <Label
                                fontSize={20}
                                value={percentage}
                                position="center"
                                fill={t.palette.text.primary}
                            />
                            <Cell fill={color} />
                        </Pie>
                    </PieChart>
                </ResponsiveContainer>
                <Typography variant="body2" color={color}>{explanation}</Typography>
            </Paper>
        </>
    );
}
