import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Editor } from "@tinymce/tinymce-react";
import React, { useEffect, useState } from "react";
import * as tinymce from 'tinymce';

interface Props {
    title: string,
    content: string,
    editorRef: React.MutableRefObject<tinymce.Editor | null>,
    isDarkTheme?: boolean,
    editorDirtyHandler?: (isDirty: boolean) => void
}

export default function EditableRichTextElement({ title, content, editorRef, isDarkTheme = false, editorDirtyHandler }: Props) {
    const [isComponentMounted, setIsComponentMounted] = useState(false);
    useEffect(() => setIsComponentMounted(true) , []);

    return (
            <Box height="100%" display="flex" pb="10px" flexDirection="column">
                <Typography variant="h6" component="div">{title}</Typography>
                {isComponentMounted && (
                    <Editor
                        tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce-5.10.2/tinymce.min.js'}
                        initialValue={content ?? ''}
                        onDirty={ () => editorDirtyHandler && editorDirtyHandler(true) }
                        onInit={ (evt, editor) => editorRef.current = editor }
                        init={{
                            height: '100%',
                            skin: isDarkTheme ? 'oxide-dark' : undefined,
                            content_css: isDarkTheme ? 'dark' : undefined,
                            plugins: 'paste',
                            paste_data_images: true,
                            branding: false,
                            menubar: false,
                            contextmenu: false,
                            resize: false
                        }}
                    />
                )}
            </Box>
    );
}