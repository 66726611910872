import { useEffect, useMemo, useState } from "react";
import { GetSettingBySettingName } from "services/UsersService";

type OnboardingSetting = 'MeetingsOnboardingUtcDate' | 'InterviewsOnboardingUtcDate'

export default function useOnboarding(settingName: OnboardingSetting) {
    const [isNeeded, setIsNeeded] = useState(false);
    const [showDialog, setShowDialog] = useState(false);

    useEffect(() => {
        const getSetting = async () => {
            const res = await GetSettingBySettingName(settingName);
            if (!Boolean(res)) setIsNeeded(true);
        };
        getSetting();
    }, [settingName]);

    const obj = useMemo(() => ({
        isNeeded, showDialog, setShowDialog
    }), [isNeeded, showDialog]);

    return obj;
}