import React, { useMemo, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import PageLayout from "layouts/PageLayout";
import Snackbar from "@mui/material/Snackbar";
import Alert from "components/Alert";
import PageContentLayout from "layouts/PageContentLayout";
import InterviewEditPageContent from "components/Content/InterviewEditPageContent";

export default function InterviewEditPage() {
    const [summaryBar, setSummaryBar] = useState(<></>);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [searchParams] = useSearchParams();
    const params = useParams();

    const interviewId = useMemo(() => {
        const parsedId = +(params.id ?? '0');
        if (isNaN(parsedId)) return 0;
        return parsedId;
    }, [params.id]);

    const candidateId = useMemo(() => {
        const tId = searchParams.get('candidateId');
        if (tId && !isNaN(+tId)) return +tId;
        return undefined;
    }, [searchParams]);

    const typeId = useMemo(() => {
        const tId = searchParams.get('type');
        if (tId && !isNaN(+tId)) return +tId;
        return undefined;
    }, [searchParams]);
    
    return (
        <PageLayout SummaryBar={summaryBar}>
            <Snackbar open={errorMessage !== ''}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <Snackbar open={successMessage !== ''} autoHideDuration={3000} onClose={() => setSuccessMessage('')} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert color="success" onClose={() => setSuccessMessage('')}>{ successMessage }</Alert>
            </Snackbar>
            <PageContentLayout showLoading={isLoading}>
                <InterviewEditPageContent
                    interviewId={interviewId}
                    candidateId={candidateId}
                    typeId={typeId}
                    setSummaryBar={setSummaryBar}
                    errorHandler={setErrorMessage}
                    loadingHandler={setIsLoading}
                    successHandler={setSuccessMessage}
                />
            </PageContentLayout>
        </PageLayout>
    );
}