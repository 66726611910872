import { PanelModel } from "@syncfusion/ej2-layouts/src/dashboard-layout/dashboard-layout-model";
import { ScreenLayoutSettings } from "common/models/ScreenLayouts/ScreenLayouts";
import { InterviewRecordDashboardElementDefinition, InterviewRecordDashboardElementType } from "common/models/Dashboard/EditLayout";

export const DefaultInterviewRecordDashboardElements: InterviewRecordDashboardElementDefinition[] = [
    { id: "InterviewCandidateDistributionMode_Default", row: 0, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'InterviewCandidateDistributionMode' },
    { id: "InterviewDistributionMode_Default", row: 1, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'InterviewDistributionMode' },
    { id: "InterviewOrganizer_Default", row: 2, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'InterviewOrganizer' },
    { id: "InterviewType_Default", row: 3, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'InterviewType' },
    { id: "InterviewDate_Default", row: 4, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'InterviewDate' },
    { id: "InterviewDateUser_Default", row: 5, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'InterviewDateUser' },
    { id: "InterviewDuration_Default", row: 6, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'InterviewDuration' },
    { id: "InterviewLocationType_Default", row: 7, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'InterviewLocationType' },
    { id: "InterviewLocation_Default", row: 8, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'InterviewLocation' },
    { id: "InterviewSubject_Default", row: 9, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'InterviewSubject' },
    { id: "InterviewJobId_Default", row: 10, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'InterviewJobId' },
        
    { id: "InterviewNotes_Default", row: 0, col: 1, sizeX: 2, sizeY: 10, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: null, type: 'InterviewNotes' },
];

export const DefaultInternalInterviewRecordDashboardElements: InterviewRecordDashboardElementDefinition[] = [
    { id: "InterviewCandidateDistributionMode_Default", row: 0, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'InterviewCandidateDistributionMode' },
    { id: "InterviewOrganizer_Default", row: 1, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'InterviewOrganizer' },
    { id: "InterviewType_Default", row: 2, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'InterviewType' },
    { id: "InterviewDate_Default", row: 3, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'InterviewDate' },
    { id: "InterviewDateUser_Default", row: 4, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'InterviewDateUser' },
    { id: "InterviewDuration_Default", row: 5, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'InterviewDuration' },
    { id: "InterviewLocationType_Default", row: 6, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'InterviewLocationType' },
    { id: "InterviewLocation_Default", row: 7, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'InterviewLocation' },
    { id: "InterviewSubject_Default", row: 8, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'InterviewSubject' },
    { id: "InterviewJobId_Default", row: 9, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'InterviewJobId' },
        
    { id: "InterviewNotes_Default", row: 0, col: 1, sizeX: 2, sizeY: 10, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: null, type: 'InterviewNotes' },
];

export const DefaultInterviewSlotRecordDashboardElements: InterviewRecordDashboardElementDefinition[] = [
    { id: "InterviewDistributionMode_Default", row: 0, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'InterviewDistributionMode' },
    { id: "InterviewOrganizer_Default", row: 1, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'InterviewOrganizer' },
    { id: "InterviewType_Default", row: 2, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'InterviewType' },
    { id: "InterviewDate_Default", row: 3, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'InterviewDate' },
    { id: "InterviewDateUser_Default", row: 4, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'InterviewDateUser' },
    { id: "InterviewDuration_Default", row: 5, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'InterviewDuration' },
    { id: "InterviewLocationType_Default", row: 6, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'InterviewLocationType' },
    { id: "InterviewLocation_Default", row: 7, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'InterviewLocation' },
    { id: "InterviewSubject_Default", row: 8, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'InterviewSubject' },
    { id: "InterviewJobId_Default", row: 9, col: 0, sizeX: 1, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: 1, type: 'InterviewJobId' },
        
    { id: "InterviewNotes_Default", row: 0, col: 1, sizeX: 2, sizeY: 10, minSizeX: 1, minSizeY: 1, maxSizeX: 1, maxSizeY: null, type: 'InterviewNotes' },
];

export const GetMinSizeByElementType = (type: InterviewRecordDashboardElementType) => {
    switch (type) {
        default: return [1, 1];
    }
};

export const GetElementTypeById = (id: string): InterviewRecordDashboardElementType => {
    if ( id && id.startsWith('Divider_')) return 'Divider';
    if ( id && id.startsWith('Spacer_')) return 'Spacer';
    if (id && id.startsWith('InterviewDistributionMode_')) return 'InterviewDistributionMode';
    if (id && id.startsWith('InterviewCandidateDistributionMode_')) return 'InterviewCandidateDistributionMode';
    if (id && id.startsWith('InterviewOrganizer_')) return 'InterviewOrganizer';
    if (id && id.startsWith('InterviewType_')) return 'InterviewType';
    if (id && id.startsWith('InterviewDate_')) return 'InterviewDate';
    if (id && id.startsWith('InterviewDateUser_')) return 'InterviewDateUser';
    if (id && id.startsWith('InterviewDuration_')) return 'InterviewDuration';
    if (id && id.startsWith('InterviewSubject_')) return 'InterviewSubject';
    if (id && id.startsWith('InterviewLocationType_')) return 'InterviewLocationType';
    if (id && id.startsWith('InterviewLocation_')) return 'InterviewLocation';
    if (id && id.startsWith('InterviewJobId_')) return 'InterviewJobId';
    if (id && id.startsWith('InterviewNotes_')) return 'InterviewNotes';
    return '';
};

export const GetPanelDefinitionsFromPanelModels = (panels: PanelModel[]) => {
    let elements: InterviewRecordDashboardElementDefinition[] = [];
    panels.forEach((p, i) => {
        let type = GetElementTypeById(p.id ?? '');
        elements.push({
            id: p.id ?? i.toString(),
            sizeX: p.sizeX ?? 1,
            sizeY: p.sizeY ?? 1,
            col: p.col ?? 0,
            row: p.row ?? 0,
            minSizeX: p.minSizeX ?? 1,
            minSizeY: p.minSizeY ?? 1,
            maxSizeX: p.maxSizeX ?? 1,
            maxSizeY: p.maxSizeY ?? null,
            type: type
        });
    });
    return elements ;
};

export const InterviewScreenLayoutSettings: ScreenLayoutSettings = {
    unitWidth: 600,
    unitHeight: 40,
    gapX: 10,
    gapY: 0,
    columns: 3,
    mediaQueryMaxWidth: '1000px'
};