import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import React from "react";

interface Props {
    title: string,
    value: string,
    height?: string,
    width?: string,
}

export default function SingleValueGraph({ height = '150px', width = '225px', title, value }: Props) {
    return (
        <Paper sx={{ height: height, width: width, p: '10px' }}>
            <Box display="flex" flexDirection="column" height="100%">
                <Typography component="div" textAlign="center" variant="body2">
                    {title}
                </Typography>
                <Typography component="div" textAlign="center" variant="h4" flexGrow={1} display="flex" alignItems="center" justifyContent="center">
                    {value}
                </Typography>
            </Box>
        </Paper>
    );
}