import { Container, Typography } from '@mui/material';
import React from 'react';
import PageContentLayout from '../../layouts/PageContentLayout';

export default function NotAuthorized() {
    return (
        <Container sx={{ paddingTop: 2 }}>
            <PageContentLayout title='Not Allowed'>
                <Typography variant='body1'>
                    You do not have permission to access this resource
                </Typography>
            </PageContentLayout>
        </Container>
    );
}