import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import React, { useCallback, useEffect, useState } from "react";
import { Candidate } from "common/models/Candidates/Candidate";
import { CandidateList } from "common/models/Candidates/CandidateList";
import { CandidateLookup, GetCandidateById } from "services/CandidatesService";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

interface Props {
    value: number | null,
    onSelectCallback: (c: CandidateList | null) => void,
    componentId?: string,
    triggerFocus?: boolean,
    variant?: "standard" | "filled" | "outlined" | undefined,
    label?: string,
    delayMs?: number,
    hideLabel?: boolean,
    blurOnSelect?: boolean,
    error?: boolean,
}

export default function CandidatePicker({ value, componentId, triggerFocus, onSelectCallback, variant, label = 'Candidate', delayMs = 200, hideLabel = false, blurOnSelect, error }: Props) {
    const [selectedCandidate, setSelectedCandidate] = useState<Candidate | CandidateList | null>(null);
    const [lookupTerm, setLookupTerm] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState<CandidateList[]>([]);

    const getCandidate = useCallback(async (candidateId: number) => {
        const res = await GetCandidateById(candidateId);
        if (res) setSelectedCandidate(res);
    }, []);

    useEffect(() => {
        if (componentId && triggerFocus) {
            const picker = document.getElementById(componentId);
            if (picker) picker.focus();
        }
    }, [componentId, triggerFocus]);

    useEffect(() => {
        if (value) {
            if (!selectedCandidate || (selectedCandidate && selectedCandidate.id !== value)) {
                const item = options.find(o => o.id === value);
                if (item) setSelectedCandidate(item);
                else getCandidate(value);
            }
        }
        else setSelectedCandidate(null);
    }, [value, selectedCandidate, options, getCandidate]);

    useEffect(() => {
        if (lookupTerm.length > 0) {
            const delaySearch = setTimeout(async () => {
                setIsLoading(true);
                const res = await CandidateLookup(lookupTerm, 25);
                if (res) setOptions(res);
                setIsLoading(false);
            }, delayMs);

            return () => clearTimeout(delaySearch);
        }
    }, [lookupTerm, delayMs]);

    const getSecondatyText = useCallback((c: CandidateList) => {
        if (Boolean(c.jobTitle) && Boolean(c.employer) && Boolean(c.location)) {
            return `${c.jobTitle} @ ${c.employer} - ${c.location}`;
        }
        if (Boolean(c.employer) && Boolean(c.location)) return `@ ${c.employer} - ${c.location}`;
        if (Boolean(c.location)) return c.location;
        return '';
    }, []);

    return (
        <Autocomplete
            size="small"
            options={options}
            value={ selectedCandidate as CandidateList }
            getOptionLabel={ o => o.fullName }
            isOptionEqualToValue={(o,v) => o.id === v.id}
            loading={isLoading}
            filterOptions={ x => x }
            blurOnSelect={blurOnSelect}
            onChange={ (e,v) => onSelectCallback(v) }
            id={componentId}
            openOnFocus={triggerFocus}
            renderInput={ (params) => (
                <TextField {...params} variant={variant} label={hideLabel ? undefined : label} inputProps={{ ...params.inputProps, "data-lpignore": "true" }} error={error}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (<>{isLoading ? <CircularProgress color="inherit" size={20} /> : params.InputProps.endAdornment}</>)
                    }}
                />
            )}
            onInputChange={(e, val) => setLookupTerm(val)}
            renderOption={(props, option) => (
                <ListItem {...props} key={option.id}>
                    <ListItemText
                        primary={`${option.fullName}`}
                        secondary={getSecondatyText(option)}
                    />
                </ListItem>
            )}
        />
    );
}