import IconButton from "@mui/material/IconButton";
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import { GridColDef, GridRenderCellParams, GridRowSelectionModel, GridValueGetter, useGridApiRef, GridRowId, GridColumnVisibilityModel } from "@mui/x-data-grid-premium";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { JobTracking } from "common/models/Jobs/JobTracking";
import { PreviewEntityType } from "common/models/Previews/Previews";
import { GetJobCandidateRatingCounts, GetJobCandidatesByAdvertIdAndRatingId, UpdateJobTrackingComments } from "services/JobsService";
import GridWithStateWrapper from "../GridWidthStateWrapper";
import PreviewLoaderComponent from "../Previews/PreviewLoader";
import TagsManagementDialog from "../Dialogs/TagsManagementDialog";
import { GetPhoneLink, GetPhoneLinkWithStandardized } from "util/CommonUtils";
import { MenuOptionDefinition } from "common/models/MenuDefinition";
import ActionMenu from "../Menus/ActionMenu";
import { companyHasSinglePermission, userHasSinglePermission } from "util/PermissionsUtils";
import { Permission } from "common/models/Permissions";
import SendEmailDialog from "../Dialogs/Messages/SendEmailDialog";
import SendSmsDialog from "../Dialogs/Messages/SendSmsDialog";
import { EmailRecipient } from "common/models/EmailRecipient";
import { GetMarketingPlatformsAvailable, GetMySettings } from "services/UsersService";
import MergeCandidatesDialog from "../Dialogs/Candidates/MergeCandidatesDialog";
import { ActivitySettings } from "common/models/Configuration/Activities";
import { GetActivitiesSettings, GetCachedRatingReasons, GetGeneralSettings, GetInterviewWorkflowStatuses, GetOfferAcceptedWorkflowStatus, GetOfferedWorkflowStatus } from "services/ConfigurationService";
import EditActivityDialog from "../Dialogs/Activities/EditActivityDialog";
import SendReferralDialog from "../Dialogs/SendReferralDialog";
import { DefaultActivity } from "util/Definitions/Activities";
import { RecordExportData } from "common/models/Common";
import ExportRecordsToMailchimpDialog from "../Dialogs/ExportRecordsToMailchimpDialog";
import ExportRecordsToCampaignMonitorDialog from "../Dialogs/ExportRecordsToCampaignMonitorDialog";
import ExportRecordsDialog from "../Dialogs/ExportRecordsDialog";
import LinkCandidatesToJobDialog from "../Dialogs/Candidates/LinkCandidatesToJobDialog";
import OfferMadeDialog from "../Dialogs/Candidates/OfferMadeDialog"
import { Activity } from "common/models/Activities";
import { Job } from "common/models/Jobs/Job";
import { GetPlacementsSettings } from "services/ConfigurationService";
import { GetIntegration } from "services/CustomerIntegrationsService";
import ConfirmationDialog from "../Dialogs/Generic/ConfirmationDialog";
import { ExportCandidateToFoundU, ExportMultipleCandidatesToFoundU, UpdateCandidateJobRating_Bulk, VerifyRejectionEmailByCandidateList } from "services/CandidatesService";
import CandidateRatingIcon from "../Candidates/CandidateRatingIcon";
import { RatingReason } from "common/models/Configuration/Jobs";
import PlaceCandidatesDialog from "../Dialogs/Candidates/PlaceCandidatesDialog";
import Box from "@mui/material/Box";
import { CandidatePositionTracker } from 'common/models/CandidatePositionTracker'
import PersonIcon from '@mui/icons-material/Person';
import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";
import ReferooActionsDialog from "components/Dialogs/Candidates/ReferooActionsDialog";
import useWorkflowOrder from "hooks/UseWorkflowOrder";
import { GetWorkflowItemsOrder } from "services/WorkflowService";
import UnlinkCandidatesFromJobDialog from "components/Dialogs/Candidates/UnlinkCandidatesFromJobDialog";
import { UnlinkCandidateData } from "common/models/Candidates/Candidate";
import { defaultGridCellStyle } from "util/GridUtils";
import DownloadResumesDialog from "../Dialogs/Candidates/DownloadResumesDialog";
import DigitalReferenceCheckDialog from "components/Dialogs/Candidates/DigitalReferenceCheckDialog";
import RejectionEmailDialog from "components/Dialogs/Candidates/RejectionEmailDialog";
import { GetActiveMeetingTypes } from "services/MeetingsService";
import { GetActiveInterviewTypes } from "services/InterviewsService";
import { MeetingType } from "common/models/Configuration/MeetingType";
import { InterviewType } from "common/models/Configuration/InterviewType";

interface Props {
    jobId: number,
    job?: Job,
    source: 'job-candidates',
    advertId?: number,
    consultantId?: number,
    gridName: string,
    extraActions?: JSX.Element,
    setRowCountHandler?: (count: number) => void,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
    successHandler?: (message: string) => void,
}

interface CandidateIdWidthApplicationId {
    candidateId: number,
    applicationId: number,
}

interface SelectedRowsImportantData {
    selectedCandidateIds: number[],
    selectedCandidatesWithApplicationId: CandidateIdWidthApplicationId[]

};

const linkStyle: React.CSSProperties = { color: 'inherit', textDecoration: 'underline' };
const entityTypeId = 3;

const compareRatingReasons = (a: RatingReason, b: RatingReason) => {
    if (b.value < a.value) return 1;
    if (b.value > a.value) return -1;
    return 0;
};

const greenBgColor = "#1da826";
const yellowBgColor = "#fecc02";
const blueBgColor = "#0366cc";
const redBgColor = "#cc0303";
const emptyRatingCounts = [0, 0, 0, 0, 0, 0];

const defaultHiddenCols: GridColumnVisibilityModel = {
    'id': false,
    'emailAddress': false,
    'mobile': false,
    'preferredSalary': false,
    'preferredRate': false,
    'lastActivityDate': false,
    'lastContactDate': false,
};

export default function JobTrackingGridComponent({ jobId, job, consultantId = 0, advertId = 0, source, setRowCountHandler, loadingHandler, errorHandler, successHandler, gridName, extraActions }: Props) {
    const [rows, setRows] = useState<JobTracking[]>([]);
    const [activitySettings, setActivitySettings] = useState<ActivitySettings[]>([]);
    const [interviewWorkflowStatuses, setInterviewWorkflowStatuses] = useState<number[]>([]); 
    const [offerWorkflowStatus, setOfferWorkflowStatus] = useState<number>(0); 
    const [offerAcceptedWorkflowStatus, setOfferAcceptedWorkflowStatus] = useState<number>(0); 
    const [selectedActivity, setSelectedActivity] = useState<ActivitySettings | null>(null);
    const [meetingTypes, setMeetingTypes] = useState<MeetingType[]>([]);
    const [interviewTypes, setInterviewTypes] = useState<InterviewType[]>([]);
    const [canBulkPlacements, setCanBulkPlacements] = useState(false);
    const [showSendReferralDialog, setShowSendReferralDialog] = useState(false);
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    const [showTagsPrevewNoDelay, setShowTagsPreviewNoDelay] = useState(false);
    const [previewType, setPreviewType] = useState<PreviewEntityType | ''>('');
    const [previewRecordId, setPreviewRecordId] = useState(0);
    const [isPreviewTags, setIsPreviewTags] = useState(false);
    const [previewRecordName, setPreviewRecordName] = useState('');
    const [hasMailChimpAccess, setHasMailChimpAccess] = useState(false);
    const [hasCampaignMonitorAccess, setHasCampaignMonitorAccess] = useState(false);
    const [showLinkCandidateToJobDialog, setShowLinkCandidateToJobDialog] = useState(false);
    const [showDownloadResumesDialog, setShowDownloadResumesDialog] = useState(false);
    const [unlinkCandidateData, setUnlinkCandidateData] = useState<UnlinkCandidateData[]>([]);
    const [showOfferMadeDialog, setShowOfferMadeDialog] = useState(false);
    const [showPlaceCandidatesDialog, setShowPlaceCandidatesDialog] = useState(false);
    const [sendMessageDialogType, setSendMessageDialogType] = useState<'email' | 'sms' | 'marketing-list' | null>(null);
    const [exportRecordDialogType, setExportRecordDialogType] = useState<'mailchimp' | 'campaign-monitor' | null>(null);
    const [messageRecipients, setMessageRecipients] = useState<EmailRecipient[]>([]);
    const [exportRecords, setExportRecords] = useState<RecordExportData[]>([]);
    const [taggingRecords, setTaggingRecords] = useState<number[]>([]);
    const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
    const [mergeRecords, setMergeRecords] = useState<JobTracking[]>([]);
    const [isFoundUConnected, setIsFoundUConnected] = useState(false);
    const [isReferooConnected, setIsReferooConnected] = useState(false);
    const [showExportToFoundUDialog, setShowExportToFoundUDialog] = useState(false);
    const [showReferooDialog, setShowReferooDialog] = useState(false);
    const [allStatus, setAllStatus] = useState<string[]>([]);
    const [allSources, setAllSources] = useState<string[]>([]);
    const [allRatingReasons, setAllRatingReasons] = useState<string[]>([]);
    const [greenReasons, setGreenReasons] = useState<RatingReason[]>([]);
    const [yellowReasons, setYellowReasons] = useState<RatingReason[]>([]);
    const [blueReasons, setBlueReasons] = useState<RatingReason[]>([]);
    const [redReasons, setRedReasons] = useState<RatingReason[]>([]);
    const [ratingIdFilter, setRatingIdFilter] = useState<number | undefined>();
    const [isInitialQuerySetupFinished, setIsInitialQuerySetupFinished] = useState(false);
    const [rejectionEmailRecipients, setRejectionEmailRecipients] = useState<number[]>([]);
    const [bulkRejectionReason, setBulkRejectionReason] = useState('');
    const [ratingCounts, setRatingCounts] = useState(emptyRatingCounts)
    const [defaultRejectionTemplateId, setDefaultRejectionTemplateId] = useState(0);
    const apiRef = useGridApiRef();
    const navigate = useNavigate();
    const workflowOrder = useWorkflowOrder();
    const [showDigitalReferenceCheckDialog, setShowDigitalReferenceCheckDialog] = useState(false);
    const [onlyDataAdminsCanMerge, setOnlyDataAdminsCanMerge] = useState(false);

    const canExportCandidates = useMemo(() => companyHasSinglePermission(Permission.CandidatesExport) && userHasSinglePermission(Permission.CandidatesExport), []);
    const userCanAddEditCandidates = useMemo(() => userHasSinglePermission(Permission.CandidatesAddEdit) && companyHasSinglePermission(Permission.CandidatesAddEdit), []);
    const userCanEmailMarket = useMemo(() => userHasSinglePermission(Permission.EmailMarketing) && companyHasSinglePermission(Permission.EmailMarketing), []);
    const userCanSendEmail = useMemo(() => userHasSinglePermission(Permission.MessageCentre) && companyHasSinglePermission(Permission.MessageCentre), []);
    const userCanSendSms = useMemo(() => userHasSinglePermission(Permission.MessageCentreSms) && companyHasSinglePermission(Permission.MessageCentreSms), []);
    const isUserDataMaintenanceAdmin = useMemo(() => userHasSinglePermission(Permission.DataMaintenanceAdministrator), []);

    const userCanMergeRecords = useMemo(() => {
        if (onlyDataAdminsCanMerge && !isUserDataMaintenanceAdmin) return false;
        return true;
    }, [isUserDataMaintenanceAdmin, onlyDataAdminsCanMerge]);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const rating = urlParams.get('RatingID');
        if (rating) {
            const parsed = +rating;
            if (parsed === 0 || parsed === 1 || parsed === 2 || parsed === 3 || parsed === 4) setRatingIdFilter(parsed);
        }
        setIsInitialQuerySetupFinished(true);
    }, []);

    const generatQueryString = useCallback(() => {
        const urlParams = new URLSearchParams(window.location.search);
        if (ratingIdFilter || ratingIdFilter === 0) urlParams.set('RatingID', ratingIdFilter.toString());
        else urlParams.delete('RatingID');
        return urlParams.toString();
    }, [ratingIdFilter]);

    useEffect(() => {
        const getSettings = async () => {
            const mySettings = await GetMySettings();
            if (mySettings) {
                const templateSetting = mySettings.find(s => s.type === 'DefaultRejectionEmailTemplateID');
                if (templateSetting && templateSetting.value !== '0') setDefaultRejectionTemplateId(+templateSetting.value);
            }
        };
        getSettings();
    }, []);

    useEffect(() => {
        if (isInitialQuerySetupFinished) {
            let url = window.location.pathname + '?' + generatQueryString();
            navigate(url);
        }
    }, [generatQueryString, isInitialQuerySetupFinished, navigate]);

    const getData = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        let res: JobTracking[] | null = [];
        if (source === 'job-candidates' && jobId) {
            res = await GetJobCandidatesByAdvertIdAndRatingId(jobId, advertId, ratingIdFilter, errorHandler);
            let sourcesMap: Record<string, boolean> = {};
            if (res && res.length > 0) {
                for (let i = 0; i < res.length; i++) {
                    const r = res[i];
                    if (r.source) sourcesMap[r.source] = true;
                }
                setAllSources(Object.keys(sourcesMap).sort());
            }

            GetJobCandidateRatingCounts(jobId, advertId).then(v => {
                if (v) setRatingCounts([v.red, v.blue, v.yellow, v.unrated, v.green, v.total]);
            });
        }
        
        if (res) {
            setRowCountHandler && setRowCountHandler(res.length)
            setRows(res);
        }
        loadingHandler && loadingHandler(false);

    }, [loadingHandler, source, jobId, consultantId, errorHandler, advertId, ratingIdFilter, setRowCountHandler]);

    useEffect(() => {
        isInitialQuerySetupFinished && getData();
    }, [getData, isInitialQuerySetupFinished]);

    useEffect(() => {
        const getGeneralSettings = async () => {
            const res = await GetGeneralSettings(errorHandler);
            if (res) {
                const setting = res.find(s => s.type === 'WhoCanMergeRecords');
                if (setting) setOnlyDataAdminsCanMerge(setting.value === 'DataMaintenanceAdministrator');
            }
        };
        getGeneralSettings();
    }, [errorHandler]);

    useEffect(() => {
        const getMeetingTypes = async () => {
            const res = await GetActiveMeetingTypes(entityTypeId);
            if (res) setMeetingTypes(res);
        };
        getMeetingTypes();
    }, []);

    useEffect(() => {
        const getInterviewTypes = async () => {
            const res = await GetActiveInterviewTypes();
            if (res) setInterviewTypes(res);
        };
        getInterviewTypes();
    }, []);

    useEffect(() => {
        const getActivitySettings = async () => {
            const res = await GetActivitiesSettings(3, undefined, errorHandler);
            if (res) setActivitySettings(res);

            const interviewStutuses = await GetInterviewWorkflowStatuses();
            if(interviewStutuses) {
                setInterviewWorkflowStatuses(interviewStutuses);
            }

            const offeredStatsus = await GetOfferedWorkflowStatus();
            if(offeredStatsus) {
                setOfferWorkflowStatus(offeredStatsus);
            }

            const offerAcceptedStatus = await GetOfferAcceptedWorkflowStatus();
            if(offerAcceptedStatus) {
                setOfferAcceptedWorkflowStatus(offerAcceptedStatus);
            }


            const placementSettings = await GetPlacementsSettings();
            if (placementSettings) {
                const p = placementSettings.find(s => s.type === 'BulkPlacements');
                if (p && p.value.toLowerCase() === 'true') setCanBulkPlacements(true);
            }

            let green: RatingReason[] = [];
            let yellow: RatingReason[] = [];
            let blue: RatingReason[] = [];
            let red: RatingReason[] = [];
            const ratingReasons = await GetCachedRatingReasons(false);
            if (ratingReasons) {
                ratingReasons.sort(compareRatingReasons);
                for (let i = 0; i < ratingReasons.length; i++) {
                    const rr = ratingReasons[i];
                    if (rr.actionType === 4) green.push(rr);
                    else if(rr.actionType === 2) yellow.push(rr);
                    else if(rr.actionType === 1) blue.push(rr);
                    else if(rr.actionType === 0) red.push(rr);
                }
                setAllRatingReasons(ratingReasons.map(r => r.value));
                setGreenReasons(green);
                setYellowReasons(yellow);
                setBlueReasons(blue);
                setRedReasons(red);
            }

            const workflowStatus = await GetWorkflowItemsOrder();
            if (workflowStatus) {
                setAllStatus(workflowStatus.map(s => s.statusValue).sort());
            }
        };
        getActivitySettings();
    }, [errorHandler]);

    const ratingChangedHandler = useCallback((jobTrackingId: number, newRatingId: number, reason: string) => {
        const api = apiRef.current;
        if (api) {
            api.updateRows([{ jobTrackingID: jobTrackingId, ratingID: newRatingId, ratingReason: reason }]);
        }
    }, [apiRef]);

    const columns = useMemo<GridColDef[]>(() => {
        const handlePreviewHover = (type: PreviewEntityType | '', id: number, isTags: boolean = false, recordName: string = '', noDelay: boolean = false) => {
            setPreviewType(type);
            setPreviewRecordId(id);
            setIsPreviewTags(isTags);
            setPreviewRecordName(recordName);
            setIsPreviewOpen(true);
            setShowTagsPreviewNoDelay(noDelay);
        };

        const handlePreviewClose = () => {
            setIsPreviewOpen(false);
            setShowTagsPreviewNoDelay(false);
        };

        const dateValueGetter: GridValueGetter<JobTracking, any, undefined, string> = (value) => {
            if (value) {
                const m = moment(value);
                if (m.isValid() && m.get('year') > 1) {
                    return m.toDate();
                }
            }
        };

        const dateRenderer = (params: GridRenderCellParams) => {
            if (params.value) {
                return <div style={defaultGridCellStyle}>{moment(params.value).format('DD MMM YYYY')}</div>;
            }
            return <div style={defaultGridCellStyle}>Never</div>;
        };

        const minHeightCellRenderer = (params: GridRenderCellParams) => {
            return (
                <div style={defaultGridCellStyle}>
                    <span>{params.value}</span>
                </div>
            );
        };

        const linkToCandidateRenderer = (params: GridRenderCellParams) => {
            const candidateId = params.row.id;
            if (candidateId) {
                return (
                    <div style={defaultGridCellStyle}>
                        <Link
                            to={`/candidates/${candidateId}?jobId=${params.row.jobID}`}
                            style={ linkStyle }
                            onMouseEnter={() => handlePreviewHover('candidate', params.row.id) }
                            onMouseLeave={ handlePreviewClose }
                            onClick={(e) => handleCandidateClick(e, params.row.jobTrackingID, params.row.id, params.row.jobID) }
                        >{params.value}</Link>
                    </div>
                );
            }
            return <div style={defaultGridCellStyle}>{params.value}</div>;
        };

        const nameValueGetter: GridValueGetter<JobTracking, any, undefined, string> = (value) => {
            if (value && value.trim() !== '') {
                return value;
            }

            return '-----';
        };

        const makeId = (length: number) => {
            let result = '';
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            const charactersLength = characters.length;
            let counter = 0;
            while (counter < length) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
                counter += 1;
            }
            return result;
        };

        const handleCandidateClick = (e: React.MouseEvent<HTMLElement>, jobTrackingId: number, candidateId: number, jobId: number) => {
            e.preventDefault();
            let dataId = makeId(10);
            var rows = apiRef.current.getAllRowIds();
            let maxIndex = rows.length - 1;
            let mainCandidateIndex = apiRef.current.getRowIndexRelativeToVisibleRows(jobTrackingId);
            mainCandidateIndex = mainCandidateIndex + (apiRef.current.state.pagination.paginationModel.page * apiRef.current.state.pagination.paginationModel.pageSize);
            let mainCandidatePrevIndex = mainCandidateIndex - 1;
            let mainCandidateNextIndex = mainCandidateIndex + 1;

            let mainPrevRowId: number = 0;
            let mainNextRowId: number = 0;

            let mainCandidatePrevCandidateId = "0";
            let mainCandidateNextCandidateId = "0";

            if (mainCandidatePrevIndex >= 0) {
                mainPrevRowId = apiRef.current.getRowIdFromRowIndex(mainCandidatePrevIndex) as number;
                let prevRow = apiRef.current.getRow(mainPrevRowId);
                mainCandidatePrevCandidateId = prevRow.id.toString();
            }

            if (mainCandidateNextIndex <= maxIndex) {
                mainNextRowId = apiRef.current.getRowIdFromRowIndex(mainCandidateNextIndex) as number;
                let nextRow = apiRef.current.getRow(mainNextRowId);
                mainCandidateNextCandidateId = nextRow.id.toString();
            }

            let collection: CandidatePositionTracker[] = [];

            rows.forEach((element: GridRowId) => {

                let jobTrackingId: number = element as number;

                let currentIndex = apiRef.current.getRowIndexRelativeToVisibleRows(jobTrackingId);
                currentIndex = currentIndex + (apiRef.current.state.pagination.paginationModel.page * apiRef.current.state.pagination.paginationModel.pageSize);
              
                let prevIndex = currentIndex - 1;
                let nextIndex = currentIndex + 1;

                let prevRowId: number = 0;
                let nextRowId: number = 0;

                let prevCandidateId: number = 0;
                let nextCandidateId: number = 0;

                let currentRow = apiRef.current.getRow(jobTrackingId);

                let candidateId = currentRow.id;
                if (prevIndex >= 0) {
                    prevRowId = apiRef.current.getRowIdFromRowIndex(prevIndex) as number;
                    let prevRow = apiRef.current.getRow(prevRowId);
                    prevCandidateId = prevRow.id;
                }


                if (nextIndex <= maxIndex) {
                    nextRowId = apiRef.current.getRowIdFromRowIndex(nextIndex) as number;
                    let nextRow = apiRef.current.getRow(nextRowId);
                    nextCandidateId = nextRow.id;
                }

                let info: CandidatePositionTracker = { candidateId, prevCandidateId, nextCandidateId };

                collection.push(info)
            });

            let val = JSON.stringify(collection);
            localStorage.setItem(dataId, val);

            navigate(`/candidates/${candidateId}?jobId=${jobId}&ratingId=${ratingIdFilter}&dataId=${dataId}&prevCandidateId=${mainCandidatePrevCandidateId}&nextCandidateId=${mainCandidateNextCandidateId}`);
        };

        const emailLinkRenderer = (params: GridRenderCellParams) => {
            const email = params.value;
            if (email) {
                return <div style={defaultGridCellStyle}><a href={`mailto:${email}`} style={linkStyle}>{email}</a></div>;
            }
        };

        const tagsRenderer = (params: GridRenderCellParams) => {
            if (userCanAddEditCandidates) {
                return (
                    <div style={defaultGridCellStyle}>
                        <IconButton
                            size="small"
                            onMouseEnter={ () => handlePreviewHover('candidate', params.row.id, true, params.row.fullName) }
                            onMouseLeave={ handlePreviewClose }
                            onClick={ () => setTaggingRecords([params.row.id]) }
                        >
                            {params.value
                                ? <LocalOfferIcon style={{ color: '#f209a6' }} />
                                : <LocalOfferOutlinedIcon />
                            }
                        </IconButton>
                    </div>
                );
            }
            return (
                <div style={defaultGridCellStyle}>
                    <IconButton
                        size="small"
                        onMouseEnter={() => handlePreviewHover('candidate', params.row.id, true, params.row.fullName)}
                        onMouseLeave={handlePreviewClose}
                        onClick={() => handlePreviewHover('candidate', params.row.id, true, params.row.fullName, true)}
                    >
                        {params.value
                            ? <LocalOfferIcon />
                            : <LocalOfferOutlinedIcon />
                        }
                    </IconButton>
                </div>
            );
        };

        const phoneLinkRenderer = (params: GridRenderCellParams) => {
            if (params.row.mobile_standardized) {
                return <div style={defaultGridCellStyle}>{GetPhoneLinkWithStandardized(params.row.mobile, params.row.mobile_standardized)}</div>;
            }
            return <div style={defaultGridCellStyle}>{GetPhoneLink(params.row.mobile, false)}</div>;
        };

        const ratingIdRenderer = (params: GridRenderCellParams) => {
            const candidateId = params.row.id;
            const jobTrackingId = params.row.jobTrackingID;
            const applicationId = params.row.applicationID;
            const jId = jobId;
            return (
                <div style={defaultGridCellStyle}>
                    <CandidateRatingIcon
                        size={30}
                        candidateId={candidateId}
                        jobId={jId}
                        applicationId={applicationId}
                        actionId={params.value}
                        useRatingReasonsProps
                        greenReasonsProp={greenReasons}
                        yellowReasonsProp={yellowReasons}
                        blueReasonsProp={blueReasons}
                        redReasonsProp={redReasons}
                        defaultRejectionTemplateId={defaultRejectionTemplateId}
                        onActionChangeHandler={ (rId, rr) => ratingChangedHandler(jobTrackingId, rId, rr)}
                        horizontal
                        errorHandler={errorHandler}
                        loadingHandler={loadingHandler}
                        successHandler={successHandler}
                    />
                </div>
            );
        };

        return [
            { headerName: 'ID', field: 'id', width: 75, renderCell: linkToCandidateRenderer },
            { headerName: 'Rating', field: 'ratingID', renderCell: ratingIdRenderer },
            { headerName: 'Name', field: 'fullName', width: 250, valueGetter: nameValueGetter, renderCell: linkToCandidateRenderer },
            { headerName: 'Email', field: 'emailAddress', headerAlign: 'center', align: 'center', width: 250, renderCell: emailLinkRenderer },
            { headerName: 'Mobile', field: 'mobile', headerAlign: 'center', align: 'center', width: 250, renderCell: phoneLinkRenderer },
            { headerName: 'Tags', field: 'tags', width: 70, headerAlign: 'center', align: 'center', renderCell: tagsRenderer, editable: false },
            { headerName: 'Job Title', field: 'jobTitle', width: 200, renderCell: minHeightCellRenderer },
            { headerName: 'Employer', field: 'employer', width: 250, renderCell: minHeightCellRenderer },
            { headerName: 'Location', field: 'location', width: 200, renderCell: minHeightCellRenderer },
            { headerName: 'Comments', field: 'comments', editable: true, width: 250, renderCell: minHeightCellRenderer },
            { headerName: 'Rating Reason', field: 'ratingReason', width: 250, type: 'singleSelect', valueOptions: allRatingReasons, renderCell: minHeightCellRenderer },
            { headerName: 'Status', field: 'currentStatusName', width: 150, type: 'singleSelect', valueOptions: allStatus, renderCell: minHeightCellRenderer },
            { headerName: 'Source', field: 'source', width: 100, type: allSources.length > 0 ? 'singleSelect' : undefined, valueOptions: allSources.length > 0 ? allSources : undefined, renderCell: minHeightCellRenderer },
            { headerName: 'Available', field: 'availabilityDate', headerAlign: 'center', align: 'center', width: 125, valueGetter: dateValueGetter, renderCell: dateRenderer, type: 'date' },
            { headerName: 'Furthest Status', field: 'furthestStatusName', width: 150, type: 'singleSelect', valueOptions: allStatus, renderCell: minHeightCellRenderer },
            { headerName: 'Status Updated', field: 'statusUpdatedDate', headerAlign: 'center', align: 'center', width: 125, valueGetter: dateValueGetter, renderCell: dateRenderer, type: 'date' },
            { headerName: 'Preferred Salary', field: 'preferredSalary', headerAlign: 'center', align: 'center', width: 125, renderCell: minHeightCellRenderer },
            { headerName: 'Preferred Rate', field: 'preferredRate', headerAlign: 'center', align: 'center', width: 125, renderCell: minHeightCellRenderer },
            { headerName: 'Last Updated', field: 'compareDate', headerAlign: 'center', align: 'center', width: 125, valueGetter: dateValueGetter, renderCell: dateRenderer, type: 'date', },
            { headerName: 'Last Activity', field: 'lastActivityDate', headerAlign: 'center', align: 'center', width: 125, valueGetter: dateValueGetter, renderCell: dateRenderer, type: 'date' },
            { headerName: 'Last Contact', field: 'lastContactDate', headerAlign: 'center', align: 'center', width: 125, valueGetter: dateValueGetter, renderCell: dateRenderer, type: 'date' },
        ];
    }, [allRatingReasons, allStatus, allSources, apiRef, navigate, ratingIdFilter, userCanAddEditCandidates, source, jobId, greenReasons, yellowReasons, blueReasons, redReasons, defaultRejectionTemplateId, errorHandler, loadingHandler, successHandler, ratingChangedHandler]);

    useEffect(() => {
        const getData = async () => {
            const res = await GetMarketingPlatformsAvailable();
            if (res) {
                setHasMailChimpAccess(res.mailChimp);
                setHasCampaignMonitorAccess(res.campaignMonitor);
            }
            const foundU = await GetIntegration(16);
            if (foundU) setIsFoundUConnected(foundU.statusID === 1);
            const referoo = await GetIntegration(1);
            if (referoo) setIsReferooConnected(referoo.statusID === 1);
        };
        userCanEmailMarket && getData();
    }, [userCanEmailMarket]);

    useEffect(() => {
        const getData = async () => {
            const foundU = await GetIntegration(16);
            if (foundU) setIsFoundUConnected(foundU.statusID === 1);

            const referoo = await GetIntegration(1);
            if (referoo) setIsReferooConnected(referoo.statusID === 1);
        };
         getData();
    }, []);

    const { selectedCandidateIds, selectedCandidatesWithApplicationId } = useMemo<SelectedRowsImportantData>(() => {
        let selectedCandidateIds: number[] = [];
        let selectedCandidatesWithApplicationId: CandidateIdWidthApplicationId[] = [];
        for (let i = 0; i < selectionModel.length; i++) {
            const rowId = selectionModel[i];
            const candidate = rows.find(c => c.jobTrackingID === rowId);
            if (candidate) {
                selectedCandidateIds.push(candidate.id);
                selectedCandidatesWithApplicationId.push({ candidateId: candidate.id, applicationId: candidate.applicationID });
            }
        }
        return { selectedCandidateIds, selectedCandidatesWithApplicationId };
    }, [rows, selectionModel]);

    const sendMessageCallback = useCallback((type: 'email' | 'sms' | 'marketing-list', idKey: keyof JobTracking, nameKey: keyof JobTracking, emailOrPhoneKey: keyof JobTracking) => {
        if (selectionModel.length > 0) {
            let recipients: EmailRecipient[] = [];
            for (let i = 0; i < selectionModel.length; i++) {
                const id = selectionModel[i];
                const row = rows.find(r => r.jobTrackingID === +id.valueOf());
                if (row) {
                    const recipientId = row[idKey] as number;
                    const recipientName = row[nameKey] as string;
                    const emailOrPhone = row[emailOrPhoneKey] as string;
                    const email = type === 'email' || type === 'marketing-list' ? emailOrPhone : '';
                    const phone = type === 'sms' ? emailOrPhone : '';

                    recipients.push({ id: recipientId, email: email, name: recipientName, mobile: phone, isOptOut: row.optOut, jobTracking: row });
                }
            }
            setSendMessageDialogType(type);
            setMessageRecipients(recipients);
        }
    }, [selectionModel, rows]);

    const exportToMailchimpOrCampaignMonitorCallback = useCallback((dialogType: 'mailchimp' | 'campaign-monitor') => {
        if (selectionModel.length > 0) {
            let exportRecordsData: RecordExportData[] = [];
            for (let i = 0; i < selectionModel.length; i++) {
                const id = selectionModel[i];
                const row = rows.find(r => r.jobTrackingID === +id);
                if (row) {
                    exportRecordsData.push({
                        id: row.id,
                        email: row.emailAddress,
                        firstName: row.firstName,
                        lastName: row.lastName,
                        isOptOut: row.optOut,
                        candidate: row
                    });
                }
            }
            setExportRecordDialogType(dialogType);
            setExportRecords(exportRecordsData);
        }
    }, [selectionModel, rows]);

    const mergeRecordsHandler = useCallback(() => {
        let selectedCandidates: JobTracking[] = [];
        for (let i = 0; i < selectionModel.length; i++) {
            const candidateId = selectionModel[i];
            const candidate = rows.find(s => s.jobTrackingID === candidateId.valueOf());
            if (candidate) selectedCandidates.push(candidate);
        }
        setMergeRecords(selectedCandidates);
    }, [rows, selectionModel]);

    const exportToExcel = useCallback(() => {
        const api = apiRef.current;
        if (api) api.exportDataAsExcel();
    }, [apiRef]);

    const exportToFoundUCallback = useCallback(async () => {
        if (selectedCandidateIds.length > 0) {
            loadingHandler && loadingHandler(true);
            let res: boolean | null = null;
            if (selectedCandidateIds.length === 1) res = await ExportCandidateToFoundU(selectedCandidateIds[0], errorHandler);
            else res = await ExportMultipleCandidatesToFoundU(selectedCandidateIds, errorHandler);
            if (res) {
                setShowExportToFoundUDialog(false);
                successHandler && successHandler('Record(s) Exported to FoundU');
            }
            loadingHandler && loadingHandler(false);
        }
    }, [selectedCandidateIds, loadingHandler, errorHandler, successHandler]);

    const isAllSelectedGreen = useMemo(() => {
        for (let i = 0; i < selectionModel.length; i++) {
            const id = selectionModel[i];
            const row = rows.find(r => r.jobTrackingID === id);
            if (row && row.ratingID !== 4) {
                return false;
            }
        }
        return true;
    }, [rows, selectionModel]);

    const selectedSingleCandidate = useMemo<JobTracking | null>(() => {
        if (selectionModel.length === 1) {
            const [id] = selectionModel;
            const candidate = rows.find(c => c.jobTrackingID === +id);
            if (candidate) return candidate;
        }
        return null;
    }, [selectionModel, rows]);

    const handleUnlinkFromJob = useCallback(() => {
        let unlink: UnlinkCandidateData[] = [];
        for (let i = 0; i < selectionModel.length; i++) {
            const id = selectionModel[i];
            const candidate = rows.find(c => c.jobTrackingID === +id);
            if (candidate) unlink.push({
                candidateId: candidate.id,
                applicationId: candidate.applicationID,
                candidateName: candidate.fullName,
                furthestStatus: candidate.furthestStatusName,
                source: candidate.source
            });
        }
        setUnlinkCandidateData(unlink);
    }, [rows, selectionModel]);

    const actionMenuDefinitions = useMemo<MenuOptionDefinition[]>(() => {
        const noRecordSelected = selectionModel.length === 0;
        const notEnoughForMerge = selectionModel.length < 2;
        const hasMarketingPlatform = hasMailChimpAccess || hasCampaignMonitorAccess;
        const contactIdsQuery = encodeURIComponent(selectedCandidateIds.join(','));

        let actions: MenuOptionDefinition[] = [
            { label: 'Download Resumes', type: 'action', allow: () => canExportCandidates, disabled: noRecordSelected, action: () => setShowDownloadResumesDialog(true) },
            { label: 'Export', type: 'action', action: exportToExcel, allow: () => canExportCandidates },
            { label: 'Export Marketing List', type: 'action', disabled: noRecordSelected, allow: () => userCanEmailMarket && !hasMarketingPlatform, action: () => sendMessageCallback('marketing-list', 'id', 'fullName', 'emailAddress') },
            { label: 'Export to Mailchimp', type: 'action', disabled: noRecordSelected, allow: () => userCanEmailMarket && hasMailChimpAccess, action: () => exportToMailchimpOrCampaignMonitorCallback('mailchimp') },
            { label: 'Export to Campaign Monitor', type: 'action', disabled: noRecordSelected, allow: () => userCanEmailMarket && hasCampaignMonitorAccess, action: () => exportToMailchimpOrCampaignMonitorCallback('campaign-monitor') },
            { label: 'Merge', type: 'action', disabled: notEnoughForMerge, allow: () => userCanAddEditCandidates && userCanMergeRecords, action: mergeRecordsHandler },
            { label: 'Send Email', type: 'action', disabled: noRecordSelected, allow: () => userCanSendEmail, action: () => sendMessageCallback('email', 'id', 'fullName', 'emailAddress') },
            { label: 'Send SMS', type: 'action', disabled: noRecordSelected, allow: () => userCanSendSms, action: () => sendMessageCallback('sms', 'id', 'fullName', 'mobile_Standardized') },
            { label: 'Bulk Tag', type: 'action', disabled: noRecordSelected, allow: () => userCanAddEditCandidates, action: () => setTaggingRecords(selectedCandidateIds as number[]) },
            { label: 'Link To Job', type: 'action', disabled: noRecordSelected, action: () => setShowLinkCandidateToJobDialog(true) },
            { label: 'Unlink From Job', type: 'action', disabled: noRecordSelected, action: handleUnlinkFromJob },
        ];

        for (let i = 0; i < activitySettings.length; i++) {
            const setting = activitySettings[i];
            if (setting.category === 2) continue;
            if (setting.workflowStatus === 0) {
                if (setting.id === 23)
                    actions.push({ label: setting.customName, type: 'action', disabled: selectionModel.length !== 1, action: () => setShowSendReferralDialog(true) });
                else
                    actions.push({ label: setting.customName, type: 'action', disabled: selectionModel.length !== 1, action: () => setSelectedActivity(setting) });
            }
        }

        let meetingActions: MenuOptionDefinition = {
            label: 'Meetings', type: 'parent', subMenu: []
        };

        if (meetingTypes.length > 0 && meetingActions.subMenu) {
            for (let i = 0; i < meetingTypes.length; i++) {
                const t = meetingTypes[i];
                    const href = `/meetings/create?typeId=${t.id}&contactIds=${contactIdsQuery}&source=${entityTypeId}`;
                    meetingActions.subMenu.push({ label: t.name, type: 'link', href: href });
            }
        }

        if (meetingActions.subMenu && meetingActions.subMenu.length > 0) actions.push(meetingActions);

        return actions;
    }, [selectionModel.length, hasMailChimpAccess, hasCampaignMonitorAccess, selectedCandidateIds, exportToExcel, mergeRecordsHandler, handleUnlinkFromJob, meetingTypes, canExportCandidates, userCanEmailMarket, sendMessageCallback, exportToMailchimpOrCampaignMonitorCallback, userCanAddEditCandidates, userCanMergeRecords, userCanSendEmail, userCanSendSms, activitySettings]);

    const verifyRejectionEmails = useCallback(async () => {
        const onlyApplicants = selectedCandidatesWithApplicationId.filter(v => Boolean(v.applicationId));
        if (onlyApplicants.length > 0) {
            const verificationData = await VerifyRejectionEmailByCandidateList(onlyApplicants.map(v => v.candidateId), jobId, errorHandler);
            if (verificationData) {
                const needRejectionEmail = verificationData.filter(d => !Boolean(d.rejectionSentDate));
                if (needRejectionEmail.length > 0) return needRejectionEmail.map(r => r.candidateID);
            }
        }
        return [];
    }, [errorHandler, jobId, selectedCandidatesWithApplicationId]);

    const bulkRateCallback = useCallback(async (actionId: number, reason: string) => {
        loadingHandler && loadingHandler(true);
        
        if (actionId === 0) {
            const needRejection = await verifyRejectionEmails();
            if (needRejection.length > 0) {
                setRejectionEmailRecipients(needRejection);
                setBulkRejectionReason(reason);
                loadingHandler && loadingHandler(false);
                return;
            }
        }

        const res = await UpdateCandidateJobRating_Bulk(selectedCandidateIds, jobId, actionId, reason, errorHandler);
        if (res) {
            successHandler && successHandler('Record(s) rating changed');
            const api = apiRef.current;
            if (api) api.updateRows(selectionModel.map(id => ({ jobTrackingID: id, ratingID: actionId, ratingReason: reason })));
        }
        loadingHandler && loadingHandler(false);
    }, [apiRef, errorHandler, jobId, loadingHandler, selectedCandidateIds, selectionModel, successHandler, verifyRejectionEmails]);

    const bulkRateActions = useMemo<MenuOptionDefinition | null>(() => {
        if (source === 'job-candidates') {
            const yellowOptions: MenuOptionDefinition[] = yellowReasons.map<MenuOptionDefinition>(r => ({ label: r.value, type: 'action', action: () => bulkRateCallback(2, r.value) })); // 2
            const blueOptions: MenuOptionDefinition[] = blueReasons.map<MenuOptionDefinition>(r => ({ label: r.value, type: 'action', action: () => bulkRateCallback(1, r.value) })); // 1
            const redOptions: MenuOptionDefinition[] = redReasons.map<MenuOptionDefinition>(r => ({ label: r.value, type: 'action', action: () => bulkRateCallback(0, r.value) })); // 0

            return { label: 'Rate Candidate(s)', type: 'parent', disabled: selectionModel.length === 0, orderIndex: -1, subMenu: [
                { label: 'Grey', type: 'action', action: () => bulkRateCallback(3, '') },
                { label: 'Yellow', type: yellowReasons.length > 0 ? 'parent' : 'action', subMenu: yellowOptions, action: () => bulkRateCallback(2, '') },
                { label: 'Blue', type: blueReasons.length > 0 ? 'parent' : 'action', subMenu: blueOptions, action: () => bulkRateCallback(1, '') },
                { label: 'Red', type: redReasons.length > 0 ? 'parent' : 'action', subMenu: redOptions, action: () => bulkRateCallback(0, '') },
            ] };
        }

        return null;
    }, [blueReasons, bulkRateCallback, redReasons, selectionModel.length, source, yellowReasons]);

    const workflowMenuDefinitions = useMemo<MenuOptionDefinition[]>(() => {
        const selectedRecords = selectionModel.length;
        const candidateIdsQuery = encodeURIComponent(selectedCandidateIds.join(','));
        const candidateId = selectedCandidateIds[0] ?? 0;
        let workflow: MenuOptionDefinition[] = [
            { label: 'Export to foundU', type: 'action', action: () => setShowExportToFoundUDialog(true), disabled: selectedRecords === 0, allow: () => isFoundUConnected },
        ];

        let offerOptions: MenuOptionDefinition = { label: 'Offers', type: 'parent', subMenu: [], orderIndex: workflowOrder.offerIndex };
        let interviewActions: MenuOptionDefinition = { label: 'Interviews', type: 'parent', subMenu: [], orderIndex: workflowOrder.interviewIndex };
        let referenceCheckActions: MenuOptionDefinition = { label: 'Ref Checks', type: 'parent', subMenu: [], orderIndex: workflowOrder.refCheckIndex };

        if (jobId) {
            if (isAllSelectedGreen) workflow.push({ label: 'Submit Candidate(s)', type: 'link', href: `/submissions/create?candidateIds=${candidateIdsQuery}&jobId=${jobId}`, disabled: selectedRecords === 0, orderIndex: workflowOrder.submitIndex });
            else workflow.push({ label: 'Submit Candidate(s)', type: 'action', action: () => errorHandler && errorHandler('Only green candidates can be submitted'), disabled: selectedRecords === 0, orderIndex: workflowOrder.submitIndex });
            for (let i = 0; i < activitySettings.length; i++) {
                
                const s = activitySettings[i];
                if (s.workflowStatus === 0) continue;
                if (interviewWorkflowStatuses.indexOf(s.workflowStatus) > -1) continue;
                else if (s.id === 30) {
                    referenceCheckActions.subMenu?.push({ label: s.customName, type: 'link', disabled: selectedRecords !== 1, href: `/candidates/${candidateId}/reference-check?jobId=${jobId}` });
                    referenceCheckActions.subMenu?.push({ label: 'Digital Reference Check', type: 'action', disabled: selectedRecords !== 1, action: () => setShowDigitalReferenceCheckDialog(true) },);
                    referenceCheckActions.subMenu?.push({ label: 'Referoo', type: 'action', disabled: selectedRecords !== 1, allow: () => isReferooConnected, action: () => setShowReferooDialog(true) },);
                }
                if (s.id === 10) workflow.push({ label: s.customName, type: 'link', disabled: selectedRecords !== 1, href: `/candidates/${candidateId}/screening?jobId=${jobId}`, orderIndex: workflowOrder.indexes[s.workflowStatus] });
                else if (s.id === 16) {
                    workflow.push({
                        label: 'Place Candidate(s)',
                        type: 'action',
                        disabled: selectedRecords === 0,
                        action: () => setShowPlaceCandidatesDialog(true),
                        orderIndex: workflowOrder.indexes[s.workflowStatus]
                    });
                }
                else if (s.workflowStatus === offerWorkflowStatus || s.id === 39 || s.workflowStatus === offerAcceptedWorkflowStatus) {

                    if (s.id === 14)
                        offerOptions.subMenu?.push({
                            label: s.customName,
                            type: 'action',
                            disabled: selectedRecords !== 1,
                            action: () => setShowOfferMadeDialog(true)
                        });
                    else
                        offerOptions.subMenu?.push({
                            label: s.customName,
                            type: 'action',
                            disabled: selectedRecords !== 1,
                            action: () => setSelectedActivity(s)
                        });
                }
                else if (s.id === 9 || s.id === 40 || s.id === 50 || s.id === 73) {
                    workflow.push({ label: s.customName, type: 'action', disabled: selectedRecords !== 1, action: () => setSelectedActivity(s), orderIndex: workflowOrder.indexes[s.workflowStatus] });
                } else if (s.workflowStatus > 0 && s.id !== 15) {
                    workflow.push({ label: s.customName, type: 'action', action: () => setSelectedActivity(s), orderIndex: workflowOrder.indexes[s.workflowStatus] });
                }
            }

            if (interviewTypes.length > 0 && interviewActions.subMenu) {
                for (let i = 0; i < interviewTypes.length; i++) {
                    const t = interviewTypes[i];
                    const href = `/interviews/create?typeId=${t.id}&candidateId=${candidateId}&jobId=${jobId}`;
                    interviewActions.subMenu.push({ label: t.name, type: 'link', href: href, disabled: selectedRecords !== 1 });
                }
            }
        }

        if (offerOptions.subMenu && offerOptions.subMenu.length > 0) workflow.push(offerOptions);
        if (referenceCheckActions.subMenu && referenceCheckActions.subMenu.length > 0) workflow.push(referenceCheckActions);
        if (interviewActions.subMenu && interviewActions.subMenu.length > 0) {
            interviewActions.subMenu.sort((a,b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));
            workflow.push(interviewActions);
        }
        if(bulkRateActions) workflow.push(bulkRateActions);

        return workflow;
    }, [selectionModel.length, selectedCandidateIds, workflowOrder.offerIndex, workflowOrder.interviewIndex, workflowOrder.refCheckIndex, workflowOrder.submitIndex, workflowOrder.indexes, jobId, bulkRateActions, isFoundUConnected, isAllSelectedGreen, interviewTypes, errorHandler, activitySettings, isReferooConnected]);

    const ratingFilterActions = useMemo(() => {
        if (source === 'job-candidates') {
            const size = 30;
            return (
                <Box display="flex" gap="15px" alignItems="center">
                    <Badge badgeContent={ratingCounts[4]} color="primary" max={10000}>
                        <Avatar onClick={() => setRatingIdFilter(prev => prev === 4 ? undefined : 4)} sx={{ color: ratingIdFilter === 4 ? 'black' : undefined, bgcolor: greenBgColor, width: size, height: size, cursor: 'pointer' }}>
                            <PersonIcon />
                        </Avatar>
                    </Badge>
                    <Badge badgeContent={ratingCounts[2]} color="primary" max={10000}>
                        <Avatar onClick={() => setRatingIdFilter(prev => prev === 2 ? undefined : 2)} sx={{ color: ratingIdFilter === 2 ? 'black' : undefined, bgcolor: yellowBgColor, width: size, height: size, cursor: 'pointer' }}>
                            <PersonIcon />
                        </Avatar>
                    </Badge>
                    <Badge badgeContent={ratingCounts[1]} color="primary" max={10000}>
                        <Avatar onClick={() => setRatingIdFilter(prev => prev === 1 ? undefined : 1)} sx={{ color: ratingIdFilter === 1 ? 'black' : undefined, bgcolor: blueBgColor, width: size, height: size, cursor: 'pointer' }}>
                            <PersonIcon />
                        </Avatar>
                    </Badge>
                    <Badge badgeContent={ratingCounts[0]} color="primary" max={10000}>
                        <Avatar onClick={() => setRatingIdFilter(prev => prev === 0 ? undefined : 0)} sx={{ color: ratingIdFilter === 0 ? 'black' : undefined, bgcolor: redBgColor, width: size, height: size, cursor: 'pointer' }}>
                            <PersonIcon />
                        </Avatar>
                    </Badge>
                    <Badge badgeContent={ratingCounts[3]} color="primary" max={10000}>
                        <Avatar onClick={() => setRatingIdFilter(prev => prev === 3 ? undefined : 3)} sx={{ color: ratingIdFilter === 3 ? 'black' : undefined, width: size, height: size, cursor: 'pointer' }}>
                            <PersonIcon />
                        </Avatar>
                    </Badge>
                    <Badge badgeContent={ratingCounts[5]} color="primary" max={10000}>
                        <Avatar onClick={() => setRatingIdFilter(undefined)} sx={{ width: size, height: size, cursor: 'pointer' }}>
                            <FilterAltOffIcon />
                        </Avatar>
                    </Badge>
                </Box>
            );
        }
    }, [ratingCounts, ratingIdFilter, source]);

    const gridActions = useMemo(() => {
        return (
            <Box display="flex">
                {ratingFilterActions}
                <ActionMenu ml={ratingFilterActions ? '15px' : undefined} label="Workflow" definition={workflowMenuDefinitions} sortType={workflowOrder.sortType} />
                <ActionMenu ml="5px" color="secondary" label="List Actions" definition={actionMenuDefinitions} />
            </Box>
        );
    }, [actionMenuDefinitions, ratingFilterActions, workflowMenuDefinitions, workflowOrder.sortType]);

    const tagManagementSuccessHandler = useCallback((message: string, recordIds: number[], finalTagCount: number) => {
        successHandler && successHandler(message);
        const hasTags = finalTagCount > 0;
        setRows(prev => {
            let tmp = [...prev];
            for (let i = 0; i < tmp.length; i++) {
                const row = tmp[i];
                if (recordIds.includes(row.id)) row.tags = hasTags
            }
            return tmp;
        });
    }, [successHandler]);

    const selectedActivityData = useMemo<Activity | null>(() => {
        if (selectedSingleCandidate && selectedActivity && selectedActivity.workflowStatus !== 0) {
            return {
                ...DefaultActivity,
                candidateID: selectedSingleCandidate.id,
                candidateName: selectedSingleCandidate.fullName,
                jobID: selectedSingleCandidate.jobID,
                jobReference: job ? job.jobReference : '',
                jobTitle: job ? job.title : '',
                clientID: job ? job.clientID : 0,
                clientName: job ? job.clientName: '',
                contactID: job ? job.contact1ID: 0,
                contactName: job ? job.contact1Name: '',
                category: selectedActivity.category,
                type: selectedActivity.customName,
                typeID: selectedActivity.id
            };
        }
        if (selectedSingleCandidate && job && selectedActivity) {
            return {
                ...DefaultActivity,
                candidateID: selectedSingleCandidate.id,
                candidateName: selectedSingleCandidate.fullName,
                jobID: job ? job.jobID : 0,
                jobReference: job ? job.jobReference : '',
                jobTitle: job ? job.title : '',
                category: selectedActivity.category,
                type: selectedActivity.customName,
                typeID: selectedActivity.id
            };
        }
        if (selectedSingleCandidate && selectedActivity) {
            return {
                ...DefaultActivity,
                candidateID: selectedSingleCandidate.id,
                candidateName: selectedSingleCandidate.fullName,
                category: selectedActivity.category,
                type: selectedActivity.customName,
                typeID: selectedActivity.id
            };
        }
        return null;
    }, [selectedSingleCandidate, job, selectedActivity]);

    const processRowUpdate = useCallback(async (newRow: JobTracking, oldRow: JobTracking) => {
            if (newRow.comments !== oldRow.comments) {
                loadingHandler && loadingHandler(true);
                await UpdateJobTrackingComments(newRow.jobTrackingID, newRow.comments, errorHandler);
                loadingHandler && loadingHandler(false);
            }
            return newRow;
    }, [errorHandler, loadingHandler]);

    const unlinkSuccessHandler = useCallback((message: string) => {
        successHandler && successHandler(message);
        getData();
    }, [getData, successHandler]);

    const rejectionEmailSentSuccess = useCallback(() => {
        setRejectionEmailRecipients([]);
        successHandler && successHandler('Rejection Email(s) sent');
        bulkRateCallback(0, bulkRejectionReason);
    }, [bulkRateCallback, bulkRejectionReason, successHandler]);

    return (
        <>
            <PreviewLoaderComponent
                open={isPreviewOpen}
                entityType={previewType}
                recordId={previewRecordId}
                isTagsPreview={isPreviewTags}
                showDelayMs={showTagsPrevewNoDelay ? 0 : undefined}
                titleOverride={previewRecordName}
            />
            <TagsManagementDialog
                open={ taggingRecords.length > 0 }
                closeHandler={ () => setTaggingRecords([]) }
                entityId={ entityTypeId }
                recordIds={ taggingRecords }
                loadingHandler={ loadingHandler }
                errorHandler={ errorHandler }
                successHandler={ tagManagementSuccessHandler }
            />
            <ExportRecordsDialog
                open={ sendMessageDialogType === 'marketing-list' }
                closeHandler={ () => setSendMessageDialogType(null) }
                entityType="candidates"
                filename="Candidates"
                records={messageRecipients}
                includeTimeStamp
            />
            <ConfirmationDialog
                message={`Are you sure you want to export the ${selectionModel.length} selected record(s) to foundU?`}
                onClose={ () => setShowExportToFoundUDialog(false) }
                onContinue={ exportToFoundUCallback }
                open={showExportToFoundUDialog}
                title="Confirm Action"
                confirmActionText="Yes"
                cancelActionText="No"
            />
            <ExportRecordsToMailchimpDialog
                open={exportRecordDialogType === 'mailchimp'}
                closeHandler={() => setExportRecordDialogType(null)}
                records={exportRecords}
                loadingHandler={loadingHandler}
                successHandler={successHandler}
                errorHandler={errorHandler}
            />
            <ExportRecordsToCampaignMonitorDialog
                open={exportRecordDialogType === 'campaign-monitor'}
                closeHandler={() => setExportRecordDialogType(null)}
                records={exportRecords}
                loadingHandler={loadingHandler}
                successHandler={successHandler}
                errorHandler={errorHandler}
            />
            <SendEmailDialog
                open={sendMessageDialogType === 'email'}
                sourceEntityId={entityTypeId}
                recipients={messageRecipients}
                recipientEntityTypeId={entityTypeId}
                closeHandler={() => setSendMessageDialogType(null)}
                loadingHandler={loadingHandler}
                errorHandler={errorHandler}
                successHandler={successHandler}
                jobId={jobId}
            />
            <SendSmsDialog
                open={ sendMessageDialogType === 'sms' }
                sourceEntityId={entityTypeId}
                recipients={ messageRecipients }
                recipientEntityTypeId={ entityTypeId }
                closeHandler={ () => setSendMessageDialogType(null) }
                loadingHandler={loadingHandler}
                errorHandler={errorHandler}
                successHandler={successHandler}
                jobId={jobId}
            />
            <MergeCandidatesDialog
                open={mergeRecords.length > 1}
                candidates={mergeRecords}
                phoneFieldOverride="mobile_Standardized"
                closeHandler={() => setMergeRecords([])}
                errorHandler={errorHandler}
                loadingHandler={loadingHandler}
                successHandler={successHandler}
            />
            <EditActivityDialog
                closeHandler={() => setSelectedActivity(null)}
                data={selectedSingleCandidate && selectedActivity
                    ? selectedActivityData
                    : null
                }
                open={ Boolean(selectedActivity) && Boolean(selectedSingleCandidate) }
                loadingHandler={loadingHandler}
                errorHandler={errorHandler}
                successHandler={successHandler}
            />
            <SendReferralDialog
                open={showSendReferralDialog && Boolean(selectedSingleCandidate)}
                candidateId={selectedSingleCandidate ? selectedSingleCandidate.id : 0}
                candidateName={selectedSingleCandidate ? selectedSingleCandidate.fullName : ''}
                closeHandler={ () => setShowSendReferralDialog(false) }
                loadingHandler={loadingHandler}
                errorHandler={errorHandler}
                successHandler={successHandler}
            />
            <LinkCandidatesToJobDialog
                open={showLinkCandidateToJobDialog && selectionModel.length > 0}
                closeHandler={() => setShowLinkCandidateToJobDialog(false)}
                candidateIds={selectedCandidateIds}
                loadingHandler={loadingHandler}
                errorHandler={errorHandler}
                successHandler={successHandler}
            />
            <DownloadResumesDialog
                jobId={jobId}
                open={showDownloadResumesDialog && selectionModel.length > 0}
                closeHandler={() => setShowDownloadResumesDialog(false)}
                candidateIds={selectedCandidateIds}
                loadingHandler={loadingHandler}
                errorHandler={errorHandler}
                successHandler={successHandler}
            />
            <UnlinkCandidatesFromJobDialog
                open={unlinkCandidateData.length > 0}
                closeHandler={() => setUnlinkCandidateData([])}
                data={unlinkCandidateData}
                jobId={jobId}
                loadingHandler={loadingHandler}
                errorHandler={errorHandler}
                successHandler={unlinkSuccessHandler}

            />
            <ReferooActionsDialog
                open={showReferooDialog}
                jobId={jobId}
                candidateId={selectedCandidateIds.length === 1 ? +selectedCandidateIds[0] : 0}
                closeHandler={() => setShowReferooDialog(false)}
                loadingHandler={loadingHandler}
                errorHandler={errorHandler}
                successHandler={successHandler}
            />
            <DigitalReferenceCheckDialog
                jobId={jobId}
                open={showDigitalReferenceCheckDialog}
                candidateId={selectedCandidateIds.length === 1 ? +selectedCandidateIds[0] : 0}
                closeHandler={() => setShowDigitalReferenceCheckDialog(false)}
                loadingHandler={loadingHandler}
                errorHandler={errorHandler}
                successHandler={successHandler} 
            />
            {jobId !== 0 &&
                <OfferMadeDialog
                    open={showOfferMadeDialog}
                    candidateId={selectedSingleCandidate ? selectedSingleCandidate.id : 0}
                    jobId={jobId}
                    closeHandler={() => setShowOfferMadeDialog(false)}
                    loadingHandler={loadingHandler}
                    errorHandler={errorHandler}
                    successHandler={successHandler}
                />
            }
            <PlaceCandidatesDialog
                open={showPlaceCandidatesDialog}
                candidateIds={selectedCandidateIds}
                jobId={jobId}
                jobType={job ? job.type : ''}
                closeHandler={() => setShowPlaceCandidatesDialog(false)}
                canBulkPlace={canBulkPlacements}
                loadingHandler={loadingHandler}
                errorHandler={errorHandler}
            />
            <RejectionEmailDialog
                open={rejectionEmailRecipients.length > 0}
                candidateIds={rejectionEmailRecipients}
                jobId={jobId}
                defaultRejectionTemplateId={defaultRejectionTemplateId}
                closeHandler={() => setRejectionEmailRecipients([])}
                onRejectionEmailSent={ rejectionEmailSentSuccess }
                errorHandler={errorHandler}
            />
            <Box>
                { extraActions }
                { Boolean(gridActions) && <Box pb="10px" sx={{ float: 'right' }}>{ gridActions }</Box>}
            </Box>
            <GridWithStateWrapper
                gridName={gridName}
                defaultViewModel={defaultHiddenCols}
                rows={rows}
                columns={columns}
                apiRef={apiRef}
                density="compact"
                checkboxSelection
                disableRowSelectionOnClick
                rowSelectionModel={selectionModel}
                processRowUpdate={processRowUpdate}
                onRowSelectionModelChange={sm => setSelectionModel(sm)}
                getRowId={r => r.jobTrackingID}
                pagination
                pageSizeOptions={[100,250,500,1000]}
                getRowHeight={() => 'auto'}
            />
        </>
    );
}