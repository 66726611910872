import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Breakpoint } from "@mui/material/styles";

interface Props {
    open: boolean,
    title: string,
    message: string | JSX.Element,
    onClose: () => void,
    onContinue: () => void,
    confirmActionText?: string,
    cancelActionText?: string,
    fullWidth?: boolean,
    maxWidth?: false | Breakpoint
    dividers?: boolean,
    hideBackdrop?: boolean
}

export default function ConfirmationDialog(props: Props) {
    return (
        <Dialog open={props.open} maxWidth={props.maxWidth} fullWidth={props.fullWidth} hideBackdrop={props.hideBackdrop} >
            <DialogTitle>{props.title}</DialogTitle>
            <DialogContent dividers={props.dividers}>
                <DialogContentText>{props.message}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="error"
                    onClick={ props.onClose }
                >
                    { props.cancelActionText ? props.cancelActionText : 'Cancel' }
                </Button>
                <Button
                    variant="contained"
                    color="success"
                    onClick={ props.onContinue }
                >
                    { props.confirmActionText ? props.confirmActionText : 'Continue' }
                </Button>
            </DialogActions>
        </Dialog>
    );
}