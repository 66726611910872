import { AstutePayrollSettings } from "common/models/Configuration/CustomerIntegrations";
import { ErrorHandler, NameIdObj, RequestOptions } from "common/models/GenericTypes";
import { Get, Put } from "util/HttpUtils";

export async function GetAstuteSettingsByDivisionId(divisionId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };
    
    return Get<AstutePayrollSettings>(`api/Divisions/${divisionId}/astute-payroll`, options);
}

export async function UpdateAstuteSettingsByDivisionId(divisionId: number, settings: AstutePayrollSettings, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };
    
    return Put<boolean>(`api/Divisions/${divisionId}/astute-payroll`, options, settings);
}

export async function GetAstuteEntitiesByDivisionId(divisionId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };
    
    return Get<NameIdObj[]>(`api/Divisions/${divisionId}/astute-payroll/entities`, options);
}

export async function GetAstuteChargeCodesByDivisionId(divisionId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };
    
    return Get<NameIdObj[]>(`api/Divisions/${divisionId}/astute-payroll/charge-codes`, options);
}

export async function GetAstuteSuperItemsByDivisionId(divisionId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };
    
    return Get<NameIdObj[]>(`api/Divisions/${divisionId}/astute-payroll/super-items`, options);
}

export async function GetAstuteGLGroupsByDivisionId(divisionId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<NameIdObj[]>(`api/Divisions/${divisionId}/astute-payroll/gl-groups`, options);
}