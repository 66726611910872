import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import React, { useCallback, useEffect, useState } from "react";
import { RedFlagCandidate } from "services/CandidatesService";
import DialogContentText from "@mui/material/DialogContentText";
import RWTextFieldComponent from "components/RWTextFieldComponent";

interface Props {
    open: boolean,
    candidateId: number,
    candidateName: string,
    closeHandler: () => void,
    loadingHandler?: (isLoading: boolean) => void,
    successHandler?: (message: string) => void,
    errorHandler?: (message: string) => void
}

export default function RedFlagCandidateDialog({ open, candidateId, candidateName,  closeHandler, loadingHandler, successHandler, errorHandler }: Props) {
    const [comments, setComments] = useState('');

    useEffect(() => {
        if (!open) {
            setComments('');
        }
    }, [open]);

    const submitCallback = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        const res = await RedFlagCandidate(candidateId, comments, errorHandler);
        if (res) {
            successHandler && successHandler(candidateName + ' has been Red Flagged');
            closeHandler();
        }
        loadingHandler && loadingHandler(false);
        
    }, [candidateId, comments, candidateName, loadingHandler, errorHandler, successHandler, closeHandler]);

    return (
        <Dialog open={open} maxWidth={"lg"} >
            <DialogTitle>Red Flag Candidate</DialogTitle>
            <DialogContent>
                <Stack spacing={2} mt="10px">
                    <DialogContentText>Red flagging a candidate will exclude them from all future searches + automatically reject them if they apply for any roles.</DialogContentText>
                    <DialogContentText>A warning will appear anytime someone opens their record. Please be as detailed as possible with your comments below.</DialogContentText>
                    <RWTextFieldComponent
                        multiline={true}
                        minRows={6}
                        label="Comments"
                        name="comments"
                        value={comments}
                        onChange={ (e) => setComments(e.target.value) }
                        sxOptions={{ flex: '1 1 0', pr: '5px' }} />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={ closeHandler }>Cancel</Button>
                <Button variant="contained" color="success" onClick={ submitCallback } >Submit</Button>
            </DialogActions>
        </Dialog>
    );
}