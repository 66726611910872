import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import moment from "moment";
import React, { useCallback } from "react";
import * as XLSX from "xlsx";
import { GetCompanyBillingsOverviewReportData } from "services/PlacementsService";

interface Props {
    description: string,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void
}

export default function CompanyBillingDetailedReport({ description, loadingHandler, errorHandler }: Props) {

    const getDataCallback = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        const res = await GetCompanyBillingsOverviewReportData(errorHandler);
        const m = moment();
        const filename = `Billings_Company_Overview${m.format('YYYYMMDDhhmmss')}.xlsx`;
        const wb = XLSX.utils.book_new();
        if (res) {
            const ws = XLSX.utils.json_to_sheet(res);
            XLSX.utils.book_append_sheet(wb, ws, "Overview");
        }
        XLSX.writeFile(wb, filename);
        loadingHandler && loadingHandler(false);
    }, [errorHandler, loadingHandler]);

    return (
        <>
            <Box display="flex" justifyContent="flex-end">
                <Button variant="contained" color="success" onClick={getDataCallback}>Run Report</Button>
            </Box>
            <Typography component="div" variant="h6" mt={2}>Report Description</Typography>
            <Typography component="div" variant="body2">{description}</Typography>
        </>
    );
}