import Button from "@mui/material/Button";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import Snackbar from "@mui/material/Snackbar";
import Alert from "components/Alert";
import { GridColDef, GridRenderCellParams, useGridApiRef } from "@mui/x-data-grid-premium";
import PageContentLayout from "layouts/PageContentLayout";
import { GetComplianceChecklists } from "services/ComplianceService";
import { ComplianceChecklist } from "common/models/Configuration/Compliance";
import GridWithStateWrapper from "components/GridWidthStateWrapper";
import { Link } from "react-router-dom";

interface Props {
    setSummaryBar?: (summaryBar: JSX.Element) => void,
}

const linkStyle: React.CSSProperties = { color: 'inherit', textDecoration: 'underline' };

export default function ComplianceChecklistsConfigPage({ setSummaryBar }: Props) {
    const [rows, setRows] = useState<ComplianceChecklist[]>([]);
    const [isloading, setIsLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const gridApiRef = useGridApiRef();

    const getRowsDataCallback = useCallback(async () => {
        setIsLoading(true);
        const res = await GetComplianceChecklists(true, setErrorMessage);
        if (res) setRows(res);
        setIsLoading(false);
    }, []);

    useEffect(() => {
        getRowsDataCallback();
    }, [getRowsDataCallback]);
    
    useEffect(() => {
        const action = <Link to="/configuration/compliance/checklists/0"><Button variant="contained" color="success">Add</Button></Link>
        const summaryBar = (
            <TitleAndActionSummaryBar
                title={'Configuration > Compliance > Checklists'}
                browserTabTitle="Compliance > Configuration"
                action={action}
            />
        );
        setSummaryBar && setSummaryBar(summaryBar);
    }, [setSummaryBar]);

    const columns = useMemo<GridColDef[]>(() => {

        const editActionRenderer = (params: GridRenderCellParams) => {
            return <Link to={`/configuration/compliance/checklists/${params.id}`} style={linkStyle}>{params.value}</Link>;
        };

        const statusRenderer = (params: GridRenderCellParams) => {
            switch (params.value) {
                case 0: return 'Inactive';
                case 1: return 'Active';
                default: return '';
            }
        };

        return [
            { field: 'id', headerName: 'ID', renderCell: editActionRenderer},
            { field: 'name', headerName: 'Name', width: 200, renderCell: editActionRenderer },
            { field: 'statusID', headerName: 'Status', width: 200, renderCell: statusRenderer },
        ];
    }, []);
    
    return (
        <>
            <Snackbar open={successMessage !== ''} autoHideDuration={3000} onClose={() => setSuccessMessage('')}>
                <Alert onClose={() => setSuccessMessage('')}>{ successMessage }</Alert>
            </Snackbar>
            <Snackbar open={errorMessage !== ''}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <PageContentLayout showLoading={isloading}>
                <GridWithStateWrapper
                    gridName="configuration/compliance/checklists"
                    rows={rows}
                    columns={columns}
                    disableRowSelectionOnClick
                    density="compact"
                    apiRef={gridApiRef}
                />
            </PageContentLayout>
        </>
    );
}