import { useCallback, useEffect } from "react";

type RecordHotkeyMap = Record<string, () => void>;

export default function useRecordHotkeys(hotkeySettings: RecordHotkeyMap, supressAction: boolean = false) {
    const localHotkeyHandler = useCallback((e: KeyboardEvent) => {
        const isAnyDialogOpen = Boolean(document.querySelector('[role="dialog"]'));
        if (isAnyDialogOpen) return;

        const isAnyCellBeingEdited = Boolean(document.querySelector('.MuiDataGrid-cell--editing[role="cell"]'));
        if (isAnyCellBeingEdited) return;

        const isAnyInputFocused = Boolean(document.querySelector('input:focus'));
        if (isAnyInputFocused) return;

        if (!supressAction) {
            const action = hotkeySettings[e.key];
            if (action) action();
        }
    }, [hotkeySettings, supressAction]);

    useEffect(() => {
        document.addEventListener('keyup', localHotkeyHandler);
        return () => document.removeEventListener('keyup', localHotkeyHandler);
    }, [localHotkeyHandler]);
}