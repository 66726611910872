import React, { useEffect, useState } from "react";
import Divider from "@mui/material/Divider";

import { CustomerIntegration } from "common/models/Configuration/CustomerIntegrations";
import OnlyTitleSummaryBar from "components/SummaryBars/OnlyTitleSummaryBar";
import PageContentLayout from "layouts/PageContentLayout";
import { GetAllIntegrations } from "services/CustomerIntegrationsService";
import CustomerIntegrationsGrid from "components/Integrations/CustomerIntegrationsGrid";

interface Props {
    setSummaryBar?: (summaryBar: JSX.Element) => void
}

export default function IntegrationsIndex({ setSummaryBar }: Props) {
    const [isLoading, setIsLoading] = useState(false);
    const [activeIntegrations, setActiveIntegrations] = useState<CustomerIntegration[]>([]);
    const [availableIntegrations, setAvailableIntegrations] = useState<CustomerIntegration[]>([]);

    useEffect(() => {
        const summaryBar = <OnlyTitleSummaryBar title="Configuration > Integrations" browserTabTitle="Integrations > Configuration" />;
        setSummaryBar && setSummaryBar(summaryBar);
    }, [setSummaryBar]);

    useEffect(() => {
        const getData = async () => {
            setIsLoading(true);
            const data = await GetAllIntegrations();
            if(data) {
                let enabled: CustomerIntegration[] = [];
                let disabled: CustomerIntegration[] = [];

                for (let i = 0; i < data.length; i++) {
                    const e = data[i];
                    if (e.statusID === 1) {
                        enabled.push(e);
                        continue;
                    }
                    disabled.push(e);
                }

                setActiveIntegrations(enabled);
                setAvailableIntegrations(disabled);
            }
            setIsLoading(false);
        }
        getData();
    }, []);

    return (
        <>
            <PageContentLayout title="Integrations" showLoading={isLoading}>
                {activeIntegrations.length > 0 && <CustomerIntegrationsGrid title="Active" items={activeIntegrations} pb="20px" />}
                {activeIntegrations.length > 0 && availableIntegrations.length > 0 && <Divider />}
                {availableIntegrations.length > 0 && <CustomerIntegrationsGrid title="Available" items={availableIntegrations} pt="20px" />}
            </PageContentLayout>
        </>
    );
}