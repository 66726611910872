import React, { useEffect, useMemo, useState } from "react";
import { Report } from "common/models/Reports/Report";
import ReportsGridComponent from "components/Reports/ReportsGrid";
import OnlyTitleSummaryBar from "components/SummaryBars/OnlyTitleSummaryBar";
import PageContentLayout from "layouts/PageContentLayout";
import PageLayout from "layouts/PageLayout";
import { GetReports } from "services/JobsService";
import { Link, useParams } from 'react-router-dom';
import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import DetailedDocumentsReport from "components/Reports/Documents/DocumentsDetailed";
import JobsConversionReport from "components/Reports/Jobs/JobsConversion";
import ExclusiveJobsReport from "components/Reports/Jobs/ExclusiveJobs";
import CustomJobsConversionReport from "components/Reports/Jobs/CustomJobsConversion";
import JobsSLAReport from "components/Reports/Jobs/JobsSLA";
import DetailedJobsReport from "components/Reports/Jobs/JobsDetailed";
import DeadlineJobsReport from "components/Reports/Jobs/JobsDeadline";
import ForecastExceptionsReport from "components/Reports/Jobs/JobsForecastException";

const gridSummaryBar = <OnlyTitleSummaryBar title="Analytics > Jobs" browserTabTitle="Jobs > Analytics" />;
const ClientsReportsLink = <Link to="/analytics/jobs" style={{ color: 'inherit', textDecoration: 'underline' }}>Jobs</Link>;

export default function JobsReportsPage() {
    const [isLoading, setIsLoading] = useState(false);
    const [reports, setReports] = useState<Report[]>([]);
    const params = useParams();

    const reportName = useMemo(() => {
        const reportNameParam = params.reportName;
        return reportNameParam;
    }, [params.reportName]);

    const reportObj = useMemo(() => {
        return reports.find(r => r.pathName === reportName)
    }, [reports, reportName]);

    const summaryBar = useMemo(() => {
        if (reportObj) {
            const title = <>{'Analytics > '} {ClientsReportsLink} {' > ' + reportObj.title}</>;
            const tabTitle = reportObj.title + ' > Jobs > Analytics';
            return <TitleAndActionSummaryBar title={title} browserTabTitle={tabTitle} action={<></>} />;
        }
        return gridSummaryBar;
    }, [reportObj]);

    useEffect(() => {
        const getReports = async () => {
            setIsLoading(true);
            const res = await GetReports();
            if (res) setReports(res);
            setIsLoading(false);
        };
        getReports();
    }, []);

    return (
        <PageLayout SummaryBar={summaryBar}>
            <PageContentLayout showLoading={isLoading}>
                { !reportName && <ReportsGridComponent data={reports} /> }
                {reportObj && reportObj.pathName === 'documents' && <DetailedDocumentsReport entityTypeId={4} description={reportObj.longDescription} loadingHandler={setIsLoading} />}
                {reportObj && reportObj.pathName === 'conversion' && <JobsConversionReport description={reportObj.longDescription} loadingHandler={setIsLoading} />}
                {reportObj && reportObj.pathName === 'exclusive' && <ExclusiveJobsReport description={reportObj.longDescription} loadingHandler={setIsLoading} />}
                {reportObj && reportObj.pathName === 'custom-ntt-conversion' && <CustomJobsConversionReport description={reportObj.longDescription} loadingHandler={setIsLoading} />}
                {reportObj && reportObj.pathName === 'sla' && <JobsSLAReport description={reportObj.longDescription} loadingHandler={setIsLoading} />}
                {reportObj && reportObj.pathName === 'detailed' && <DetailedJobsReport description={reportObj.longDescription} loadingHandler={setIsLoading} />}
                {reportObj && reportObj.pathName === 'deadline' && <DeadlineJobsReport description={reportObj.longDescription} loadingHandler={setIsLoading} />}
                {reportObj && reportObj.pathName === 'forecast-exceptions' && <ForecastExceptionsReport description={reportObj.longDescription} loadingHandler={setIsLoading} />}
            </PageContentLayout>
        </PageLayout>
    );
}