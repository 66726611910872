import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { AddDocuments } from "services/DocumentsService";
import FilePicker from "components/Pickers/FilePicker";

interface Props {
    open: boolean,
    entityTypeId: number,
    playerId: number,
    closeHandler: () => void,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
    successHandler?: (message: string) => void,
}

interface LabelValue {
    label: string,
    value: string
}

export default function UploadDocumentsDialog({ open, entityTypeId, playerId, closeHandler, loadingHandler, errorHandler, successHandler }: Props) {
    const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
    const [documentType, setDocumentType] = useState(1);
    const [expirationDate, setExpirationDate] = useState<moment.Moment | null>(null);

    useEffect(() => {
        if (!open) {
            setFilesToUpload([]);
            setDocumentType(1);
            setExpirationDate(null);
        }
    }, [open]);

    const addFilesCallback = useCallback((files: File[]) => {
        setFilesToUpload(prev => [...prev, ...files]);
    }, []);

    const removeFileCallback = useCallback((index: number) => {
        setFilesToUpload(prev => {
            let files = [...prev];
            files.splice(index, 1);
            return files;
        });
    }, []);

    const docTypes = useMemo(() => {
        let types: LabelValue[] = [{ label: 'General', value: '1' }];

        switch (entityTypeId) {
            case 1:
                types.push({ label:"Terms of Business", value: "2"});
                types.push({ label:"Invoices / Receipts", value: "3"});
                types.push({ label:"Report", value: "4"});
                break;
            case 2:
                types.push({ label:"Terms of Business", value: "2"});
                types.push({ label:"Report", value: "4"});
                break;
            case 3:
                types.push({ label:"Original CV", value: "2"});
                types.push({ label:"Candidate Setup Forms", value: "5"});
                types.push({ label:"Reference Check", value: "6"});
                types.push({ label:"Formatted CV", value: "9"});
                types.push({ label:"ID, Qualifications, Licenses & Certifications", value: "10"});
                types.push({ label:"Visas", value: "11"});
                types.push({ label:"Vetting & Security", value: "12"});
                types.push({ label:"Test Results", value: "13"});
                types.push({ label:"Interview Notes", value: "14"});
                types.push({ label:"Submitted CV", value: "15"});
                types.push({ label:"Safety / Injuries", value: "16"});
                types.push({ label:"Cover Letter", value: "17"});
                break;
            case 4:
                types.push({ label:"Reference Check", value: "6"});
                types.push({ label:"Report", value: "7"});
                types.push({ label:"Job Description", value: "8"});
                types.push({ label:"Terms of Business", value: "9"});
                break;
            case 5:
                types.push({ label:"Contract - Client", value: "2"});
                types.push({ label:"Contract - Candidate", value: "3"});
                types.push({ label:"Terms of Business", value: "4"});
                types.push({ label:"Candidate Setup Forms", value: "5"});
                types.push({ label:"Contractor Review Form", value: "6"});
                types.push({ label:"Order Forms", value: "7"});
                types.push({ label:"Insurance", value: "8"});
                break;                    
        }

        types.sort((a,b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));
        return types;
    }, [entityTypeId]);

    const uploadDocumentsCallback = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        let res: boolean | null = null;
        const expDate = expirationDate && expirationDate.isValid() ? expirationDate.format('YYYY-MM-DD') : '0001-01-01';
        res = await AddDocuments(entityTypeId, playerId, documentType, filesToUpload, expDate, errorHandler);
        if (res) {
            successHandler && successHandler('File(s) Added Successfully');
            closeHandler();
        }
        loadingHandler && loadingHandler(false);
    }, [documentType, playerId, filesToUpload, expirationDate, entityTypeId, loadingHandler, errorHandler, successHandler, closeHandler]);

    return (
        <>
            <Dialog open={open} maxWidth="md" fullWidth>
                <DialogTitle>Add Documents</DialogTitle>
                <DialogContent>
                    <Stack spacing={2}>
                        <div />
                        <TextField
                            select
                            fullWidth
                            label="Document Type"
                            value={documentType.toString()}
                            onChange={({target}) => setDocumentType(+target.value)}
                        >
                            {docTypes.map((v, i) => <MenuItem key={i} value={v.value}>{v.label}</MenuItem> )}
                        </TextField>
                        <DatePicker
                            label="Expiration Date"
                            value={expirationDate}
                            onChange={m => setExpirationDate(m)}
                            slotProps={{actionBar: { actions: ["clear", "today", "cancel", "accept"] }}}
                        />
                        <FilePicker
                            files={filesToUpload}
                            addFilesHandler={addFilesCallback}
                            removeFileHandler={removeFileCallback}
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="error" onClick={ closeHandler }>Cancel</Button>
                    <Button variant="contained" color="success" disabled={filesToUpload.length === 0} onClick={uploadDocumentsCallback}>Submit</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}