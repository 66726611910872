import React, { useEffect, useMemo, useState } from "react";
import { Report } from "common/models/Reports/Report";
import ReportsGridComponent from "components/Reports/ReportsGrid";
import OnlyTitleSummaryBar from "components/SummaryBars/OnlyTitleSummaryBar";
import PageContentLayout from "layouts/PageContentLayout";
import PageLayout from "layouts/PageLayout";
import { GetReports } from "services/PlacementsService";
import { Link, useParams } from 'react-router-dom';
import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import DetailedDocumentsReport from "components/Reports/Documents/DocumentsDetailed";
import ConsultantCommissionReport from "components/Reports/Placements/ConsultantCommission";
import CancelledPlacementsReport from "components/Reports/Placements/CancelledPlacements";
import EarlyTerminatedPlacementsReport from "components/Reports/Placements/EarlyTerminatedPlacements";
import EmployeeTenureReport from "components/Reports/Placements/EmployeeTenure";
import CommisionReport from "components/Reports/Placements/Commissions";
import NoReferencePlacementsReport from "components/Reports/Placements/NoReferencePlacements";
import TimesheetDataDump from "components/Reports/Placements/TimesheetDataDump";
import NewPlacementsReport from "components/Reports/Placements/NewPlacements";
import ExpiredContractorsReport from "components/Reports/Placements/ExpiredContractors";
import PlacementsDetailedReport from "components/Reports/Placements/PlacementsDetailed";
import NTTPlacementCompliance from "components/Reports/Placements/NTT_PlacementCompliance";
import CGPlacementSourceAnalysis from "components/Reports/Placements/CG_PlacementSourceAnalysis";
import TRCContractorMix from "components/Reports/Placements/TRC_ContractorMix";
import CurrentContractorsReport from "components/Reports/Placements/CurrentContractors";
import TSCCurrentContractorsReport from "components/Reports/Placements/TSC_CurrentContractors";
import CurrentContractorFinancialsReport from "components/Reports/Placements/CurrentContractorFinancials";
import IvoryGroupMarginReport from "components/Reports/Placements/IvoryGroup_MarginReport";
import TRCSalesMonthly from "components/Reports/Placements/TRC_SalesMonthly";
import TRCSalesYearly from "components/Reports/Placements/TRC_SalesYearly";

import BulkChangeRequestExtensions from "components/Reports/Placements/BulkChangeRequest-Extensions";
import BulkChangeRequestRateChanges from "components/Reports/Placements/BulkChangeRequest-RateChanges";
import ContractorTenureReport from "components/Reports/Placements/ContractorTenure";
import CompanyBillingDetailedReport from "../../../components/Reports/Misc/CompanyBillingDetailed";
import UserBillingsDetailedReport from "../../../components/Reports/Misc/UserBillingsDetailed";

const gridSummaryBar = <OnlyTitleSummaryBar title="Analytics > Placements" browserTabTitle="Placements > Analytics" />;
const ClientsReportsLink = <Link to="/analytics/placements" style={{ color: 'inherit', textDecoration: 'underline' }}>Placements</Link>;

export default function PlacementsReportsPage() {
    const [isLoading, setIsLoading] = useState(false);
    const [reports, setReports] = useState<Report[]>([]);
    const params = useParams();

    const reportName = useMemo(() => {
        const reportNameParam = params.reportName;
        return reportNameParam;
    }, [params.reportName]);

    const reportObj = useMemo(() => {
        return reports.find(r => r.pathName === reportName)
    }, [reports, reportName]);

    const summaryBar = useMemo(() => {
        if (reportObj) {
            const title = <>{'Analytics > '} {ClientsReportsLink} {' > ' + reportObj.title}</>;
            const tabTitle = reportObj.title + ' > Placements > Analytics';
            return <TitleAndActionSummaryBar title={title} browserTabTitle={tabTitle} action={<></>} />;
        }
        return gridSummaryBar;
    }, [reportObj]);

    useEffect(() => {
        const getReports = async () => {
            setIsLoading(true);
            const res = await GetReports();
            if (res) setReports(res);
            setIsLoading(false);
        };
        getReports();
    }, []);

    return (
        <PageLayout SummaryBar={summaryBar}>
            <PageContentLayout showLoading={isLoading}>
                { !reportName && <ReportsGridComponent data={reports} /> }
                {reportObj && reportObj.pathName === 'documents' && <DetailedDocumentsReport entityTypeId={5} description={reportObj.longDescription} loadingHandler={setIsLoading} />}
                {reportObj && reportObj.pathName === 'consultant-commission' && <ConsultantCommissionReport description={reportObj.longDescription} loadingHandler={setIsLoading} />}
                {reportObj && reportObj.pathName === 'cancelled' && <CancelledPlacementsReport description={reportObj.longDescription} loadingHandler={setIsLoading} />}
                {reportObj && reportObj.pathName === 'early-terminations' && <EarlyTerminatedPlacementsReport description={reportObj.longDescription} loadingHandler={setIsLoading} />}
                {reportObj && reportObj.pathName === 'employee-tenure' && <EmployeeTenureReport description={reportObj.longDescription} loadingHandler={setIsLoading} />}
                {reportObj && reportObj.pathName === 'contractor-tenure' && <ContractorTenureReport description={reportObj.longDescription} loadingHandler={setIsLoading} />}
                {reportObj && reportObj.pathName === 'commission' && <CommisionReport description={reportObj.longDescription} loadingHandler={setIsLoading} />}
                {reportObj && reportObj.pathName === 'no-reference-placements' && <NoReferencePlacementsReport description={reportObj.longDescription} loadingHandler={setIsLoading} />}
                {reportObj && reportObj.pathName === 'timesheet-dump' && <TimesheetDataDump description={reportObj.longDescription} loadingHandler={setIsLoading} />}
                {reportObj && reportObj.pathName === 'new-placements' && <NewPlacementsReport description={reportObj.longDescription} loadingHandler={setIsLoading} />}
                {reportObj && reportObj.pathName === 'expired-contractors' && <ExpiredContractorsReport description={reportObj.longDescription} loadingHandler={setIsLoading} />}
                {reportObj && reportObj.pathName === 'detailed' && <PlacementsDetailedReport description={reportObj.longDescription} loadingHandler={setIsLoading} />}

                {reportObj && reportObj.pathName === 'bulk-change-requests-extensions' && <BulkChangeRequestExtensions description={reportObj.longDescription} loadingHandler={setIsLoading} />}
                {reportObj && reportObj.pathName === 'bulk-change-requests-rates' && <BulkChangeRequestRateChanges description={reportObj.longDescription} loadingHandler={setIsLoading} />}

                {reportObj && reportObj.pathName === 'billings-company-overview' && <CompanyBillingDetailedReport description={reportObj.longDescription} loadingHandler={setIsLoading} />}
                {reportObj && reportObj.pathName === 'billings-user-detailed' && <UserBillingsDetailedReport description={reportObj.longDescription} loadingHandler={setIsLoading} />}
                
                {reportObj && reportObj.pathName === 'custom-ntt-placement-compliance' && <NTTPlacementCompliance description={reportObj.longDescription} loadingHandler={setIsLoading} />}
                {reportObj && reportObj.pathName === 'custom-cg-source-analysis' && <CGPlacementSourceAnalysis description={reportObj.longDescription} loadingHandler={setIsLoading} />}
                {reportObj && reportObj.pathName === 'custom-trc-margin-report' && <TRCContractorMix description={reportObj.longDescription} loadingHandler={setIsLoading} />}
                {reportObj && reportObj.pathName === 'custom-trc-sales-monthly' && <TRCSalesMonthly description={reportObj.longDescription} loadingHandler={setIsLoading} />}
                {reportObj && reportObj.pathName === 'custom-trc-sales-annual' && <TRCSalesYearly description={reportObj.longDescription} loadingHandler={setIsLoading} />}
                {reportObj && reportObj.pathName === 'current-contractors' && <CurrentContractorsReport description={reportObj.longDescription} loadingHandler={setIsLoading} />}
                {reportObj && reportObj.pathName === 'current-contractor-financials' && <CurrentContractorFinancialsReport description={reportObj.longDescription} loadingHandler={setIsLoading} />}
                {reportObj && reportObj.pathName === 'custom-ivory-margin-report' && <IvoryGroupMarginReport description={reportObj.longDescription} loadingHandler={setIsLoading} />}
                {reportObj && reportObj.pathName === 'tsc-current-contractors-mondaydotcom' && <TSCCurrentContractorsReport description={reportObj.longDescription} loadingHandler={setIsLoading} />}
            </PageContentLayout>
        </PageLayout>
    );
}