import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { GridColDef, GridRenderCellParams, GridValueGetter, useGridApiRef } from "@mui/x-data-grid-premium";
import { NameIdObj, NameIdObjString } from "common/models/GenericTypes";
import { PreviewEntityType } from "common/models/Previews/Previews";
import { CandidateDataExceptionReportRecord } from "common/models/Reports/CandidateDataExceptionReportRecord";
import GridWithStateWrapper from "components/GridWidthStateWrapper";
import MultipleDivisionsPicker from "components/Pickers/MultipleDivisionPicker";
import MultipleStringValuesPicker from "components/Pickers/MultipleStringValuesPicker";
import PreviewLoaderComponent from "components/Previews/PreviewLoader";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { GetCandidatesDataExceptionReportData } from "services/CandidatesService";
import { GetDivisions } from "services/DivisionsService";

interface Props {
    description: string,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
}

const missingElements: NameIdObjString[] = [
    { id: 'mobile', name: 'Mobile' },
    { id: 'phone', name: 'Phone' },
    { id: 'email1', name: 'Email 1' },
    { id: 'email2', name: 'Email 2' },
    { id: 'address1', name: 'Address 1' },
    { id: 'address2', name: 'Address 2' },
    { id: 'suburb', name: 'Suburb' },
    { id: 'postcode', name: 'Postcode' },
];

const linkStyle: React.CSSProperties = { color: 'inherit', textDecoration: 'underline' };

export default function CandidatesDataExceptionReport({ description, loadingHandler, errorHandler }: Props) {
    const [divisionIdNameMap, setDivisionIdNameMap] = useState<Record<number, string>>({});
    const [rows, setRows] = useState<CandidateDataExceptionReportRecord[]>([]);
    const [lastActivity, setLastActivity] = useState(0);
    const [missing, setMissing] = useState<string[]>([]);
    const [divisions, setDivisions] = useState<number[]>([]);
    const [showGrid, setShowGrid] = useState(false);
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    const [previewType, setPreviewType] = useState<PreviewEntityType | ''>('');
    const [previewRecordId, setPreviewRecordId] = useState(0);
    const [isPreviewTags, setIsPreviewTags] = useState(false);
    const [previewRecordName, setPreviewRecordName] = useState('');
    const apiRef = useGridApiRef();

    useEffect(() => {
        const getDivs = async () => {
            const res = await GetDivisions();
            if (res) {
                let map: Record<number, string> = {};
                for (let i = 0; i < res.length; i++) {
                    const d = res[i];
                    map[d.id] = d.name;
                }
                setDivisionIdNameMap(map);
            }
        };
        getDivs();
    }, []);
    
    const getDataCallback = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        const res = await GetCandidatesDataExceptionReportData(lastActivity, divisions, missing, errorHandler);
        if (res) {
            setShowGrid(true);
            setRows(res);
        }
        loadingHandler && loadingHandler(false);
    }, [divisions, errorHandler, lastActivity, loadingHandler, missing]);

    const onMissingChange = useCallback((items: NameIdObjString[]) => {
        const ids = items.map(i => i.id);
        setMissing(ids);
    }, []);

    const onDivisionChange = useCallback((items: NameIdObj[]) => {
        const ids = items.map(i => i.id);
        setDivisions(ids);
    }, []);

    const columns = useMemo<GridColDef[]>(() => {
        const handlePreviewHover = (type: PreviewEntityType | '', id: number, isTags: boolean = false, recordName: string = '') => {
            setPreviewType(type);
            setPreviewRecordId(id);
            setIsPreviewTags(isTags);
            setPreviewRecordName(recordName);
            setIsPreviewOpen(true);
        };

        const handlePreviewClose = () => {
            setIsPreviewOpen(false);
        };

        const dateValueGetter: GridValueGetter<CandidateDataExceptionReportRecord, any, undefined, string | null> = (value) => {
            if (value) {
                const m = moment(value);
                if (m.isValid() && m.get('year') > 1) {
                    return m.toDate();
                }
            }

            return null;
        };

        const divisionsValueGetter: GridValueGetter<CandidateDataExceptionReportRecord, any, undefined, string> = (value) => {
            if (value) {
                const split = value.split(';');
                let ids: Record<string, boolean> = {};
                for (let i = 0; i < split.length; i++) {
                    const s = split[i].trim();
                    if (s) ids[s] = true;
                }
                const keys = Object.keys(ids);
                const names = keys.map(k => divisionIdNameMap[+k]);
                return names;
            }
        };

        const dateRenderer = (params: GridRenderCellParams) => {
            if (params.value) {
                return moment(params.value).format('DD MMM YYYY');
            }
            return 'Never';
        };

        const divisionsRenderer = (params: GridRenderCellParams) => {
            if (params.value) {
                const v = params.value as string[]
                return v.map((d, i) => <Chip key={i} size="small" label={d} sx={{ mr: '5px' }} />)
            }
        };

        const linkToCandidateRenderer = (params: GridRenderCellParams) => {
            if (params.id) {
                return (
                    <Link
                        to={`/candidates/${params.id}`}
                        style={linkStyle}
                        onMouseEnter={() => handlePreviewHover('candidate', params.row.id)}
                        onMouseLeave={handlePreviewClose}
                    >{params.value}</Link>
                );
            }
            return params.value;
        };

        const missingDataRenderer = (params: GridRenderCellParams) => {
            if (params.value) return params.value;
            return <Box color={t => t.palette.error.main}>(Empty)</Box>
        };

        return [
            { field: 'id', headerName: 'ID', width: 100, renderCell: linkToCandidateRenderer },
            { field: 'fullName', headerName: 'Name', width: 250, renderCell: linkToCandidateRenderer },
            { field: 'mobile', headerName: 'Mobile', width: 200, renderCell: missingDataRenderer },
            { field: 'phone', headerName: 'Phone', width: 200, renderCell: missingDataRenderer },
            { field: 'email1', headerName: 'Email 1', width: 200, renderCell: missingDataRenderer },
            { field: 'email2', headerName: 'Email 2', width: 200, renderCell: missingDataRenderer },
            { field: 'address1', headerName: 'Address 1', width: 200, renderCell: missingDataRenderer },
            { field: 'address2', headerName: 'Address 2', width: 200, renderCell: missingDataRenderer },
            { field: 'suburb', headerName: 'Suburb', width: 200, renderCell: missingDataRenderer },
            { field: 'postcode', headerName: 'Postcode', width: 200, renderCell: missingDataRenderer },
            { field: 'divisions', headerName: 'Divisions', width: 200, valueGetter: divisionsValueGetter, renderCell: divisionsRenderer },
            { field: 'lastActivityDate', headerName: 'Last Activity Date', width: 200, type: 'date', valueGetter: dateValueGetter, renderCell: dateRenderer },
        ];
    }, [divisionIdNameMap]);

    const exportAsExcelHandler = useCallback(() => {
        const api = apiRef.current;
        if (api) {
            const m = moment();
            const filename = `CandidatesExceptionData${m.format('YYYYMMDDhhmmss')}`;
            api.exportDataAsExcel({ fileName: filename });
        }
    }, [apiRef]);
    
    return (
        <>
            <PreviewLoaderComponent
                open={isPreviewOpen}
                entityType={previewType}
                recordId={previewRecordId}
                isTagsPreview={isPreviewTags}
                titleOverride={previewRecordName}
            />
            <Box display="flex" gap={2}>
                <TextField select label="Last Activity Date" value={lastActivity.toString()} onChange={({target}) => setLastActivity(+target.value)} sx={{ flex: '1 1 0' }}>
                    <MenuItem value="0">All</MenuItem>
                    <MenuItem value="1">Last Week</MenuItem>
                    <MenuItem value="2">Last Month</MenuItem>
                    <MenuItem value="3">Last 3 Months</MenuItem>
                    <MenuItem value="4">Last Year</MenuItem>
                </TextField>
                <Box flex="1 1 0">
                    <MultipleDivisionsPicker value={divisions} onSelect={onDivisionChange}  />
                </Box>
                <Box flex="1 1 0">
                    <MultipleStringValuesPicker label="Missing data" options={missingElements} value={missing} onSelect={onMissingChange} />
                </Box>
                <Button variant="contained" color="success" onClick={getDataCallback}>Run Report</Button>
            </Box>
            <Typography component="div" variant="h6" mt={2}>Report Description</Typography>
            <Typography component="div" variant="body2" mb={2}>{description}</Typography>
            {showGrid && 
                <>
                    <Box pb="10px" ml="auto">
                        <Button variant="contained" color="success" onClick={exportAsExcelHandler}>Export As Excel</Button>
                    </Box>
                    <GridWithStateWrapper
                        density="compact"
                        gridName=""
                        rows={rows}
                        columns={columns}
                        apiRef={apiRef}
                        disableRowSelectionOnClick
                    />
                </>
            }
        </>
    );
}