import { Client, MinClient } from "common/models/Clients";
import { ChangeTracker } from "common/models/hooks/ChangeTracker";

export function ClientToMinClient(c: Client): MinClient {
    return {
        address1: c.address1,
        address2: c.address2,
        address3: c.address3,
        code1: c.code1,
        code1Type: c.code1Type,
        code2: c.code2,
        code2Type: c.code2Type,
        countryCode: c.countryCode,
        countryName: c.countryName,
        creditBalance: c.creditBalance,
        creditLimit: c.creditLimit,
        creditRating: c.creditRating,
        cvDeadlineContract: c.cvDeadlineContract,
        cvDeadlineFixedContract: c.cvDeadlineFixedContract,
        cvDeadlinePerm: c.cvDeadlinePerm,
        defaultBillingContactID: c.defaultBillingContactID,
        defaultTimesheetApproverID: c.defaultTimesheetApproverID,
        description: c.description,
        divisions: c.divisions,
        id: c.id,
        legalName: c.legalName,
        linkedinURL: c.linkedinURL,
        parentCompany: c.parentCompany,
        phone: c.phone,
        postcode: c.postcode,
        primaryContact: c.primaryContact,
        rating: c.rating,
        siteID: c.siteID,
        siteName: c.siteName,
        state: c.state,
        suburb: c.suburb,
        tradingName: c.tradingName,
        twitterURL: c.twitterURL,
        type: c.type,
        website: c.website,
        photoName: c.photoName,
        photoUrl: c.photoUrl,
        defaultNoticePeriod: c.defaultNoticePeriod,
        defaultNoticePeriodUnits: c.defaultNoticePeriodUnits,
        customField1: c.customField1, customField2: c.customField2, customField3: c.customField3, customField4: c.customField4, customField5: c.customField5, customField6: c.customField6, customField7: c.customField7, customField8: c.customField8, customField9: c.customField9, customField10: c.customField10,
        customField11: c.customField11, customField12: c.customField12, customField13: c.customField13, customField14: c.customField14, customField15: c.customField15, customField16: c.customField16, customField17: c.customField17, customField18: c.customField18, customField19: c.customField19, customField20: c.customField20,
        customField21: c.customField21, customField22: c.customField22, customField23: c.customField23, customField24: c.customField24, customField25: c.customField25, customField26: c.customField26, customField27: c.customField27, customField28: c.customField28, customField29: c.customField29, customField30: c.customField30, 
        customField31: c.customField31, customField32: c.customField32, customField33: c.customField33, customField34: c.customField34, customField35: c.customField35, customField36: c.customField36, customField37: c.customField37, customField38: c.customField38, customField39: c.customField39, customField40: c.customField40, 
        customField41: c.customField41, customField42: c.customField42, customField43: c.customField43, customField44: c.customField44, customField45: c.customField45, customField46: c.customField46, customField47: c.customField47, customField48: c.customField48, customField49: c.customField49, customField50: c.customField50, 
        customField51: c.customField51, customField52: c.customField52, customField53: c.customField53, customField54: c.customField54, customField55: c.customField55, customField56: c.customField56, customField57: c.customField57, customField58: c.customField58, customField59: c.customField59, customField60: c.customField60
    };
};

export const DefaultMinClient: MinClient = {
    id: 0,
    defaultBillingContactID: 0,
    defaultTimesheetApproverID: 0,
    legalName: '',
    parentCompany: 0,
    phone: '',
    primaryContact: 0,
    rating: 0,
    type: 1,
    tradingName: '',
    website: '',
    siteID: 0,
    siteName: '',
    address1: '',
    address2: '',
    address3: '',
    countryCode: '',
    countryName: '',
    state: '',
    postcode: '',
    suburb: '',
    description: '',
    cvDeadlineContract: 0,
    cvDeadlineFixedContract: 0,
    cvDeadlinePerm: 0,
    creditBalance: 0,
    creditLimit: 0,
    creditRating: '',
    code1: '',
    code1Type: 0,
    code2: '',
    code2Type: 0,
    divisions: '',
    linkedinURL: '',
    twitterURL: '',
    photoName: '',
    photoUrl: '',
    defaultNoticePeriod: 0,
    defaultNoticePeriodUnits: 0,
    customField1: '', customField2: '', customField3: '', customField4: '', customField5: '', customField6: '', customField7: '', customField8: '', customField9: '', customField10: '',
    customField11: undefined, customField12: undefined, customField13: undefined, customField14: undefined, customField15: undefined,
    customField16: '0001-01-01T00:00:00', customField17: '0001-01-01T00:00:00', customField18: '0001-01-01T00:00:00', customField19: '0001-01-01T00:00:00', customField20: '0001-01-01T00:00:00',
    customField21: '', customField22: '', customField23: '', customField24: '', customField25: '', customField26: '', customField27: '', customField28: '', customField29: '', customField30: '', 
    customField31: '', customField32: '', customField33: '', customField34: '', customField35: '', customField36: '', customField37: '', customField38: '', customField39: '', customField40: '', 
    customField41: undefined, customField42: undefined, customField43: undefined, customField44: undefined, customField45: undefined, customField46: undefined, customField47: undefined, customField48: undefined, customField49: undefined, customField50: undefined, 
    customField51: '0001-01-01T00:00:00', customField52: '0001-01-01T00:00:00', customField53: '0001-01-01T00:00:00', customField54: '0001-01-01T00:00:00', customField55: '0001-01-01T00:00:00',
    customField56: '0001-01-01T00:00:00', customField57: '0001-01-01T00:00:00', customField58: '0001-01-01T00:00:00', customField59: '0001-01-01T00:00:00', customField60: '0001-01-01T00:00:00'
};

export const DefaultMinClientNoChanges: ChangeTracker<MinClient> = {
    id: false,
    defaultBillingContactID: false,
    defaultTimesheetApproverID: false,
    legalName: false,
    parentCompany: false,
    phone: false,
    primaryContact: false,
    rating: false,
    type: false,
    tradingName: false,
    website: false,
    siteID: false,
    siteName: false,
    address1: false,
    address2: false,
    address3: false,
    countryCode: false,
    countryName: false,
    state: false,
    postcode: false,
    suburb: false,
    description: false,
    cvDeadlineContract: false,
    cvDeadlineFixedContract: false,
    cvDeadlinePerm: false,
    creditBalance: false,
    creditLimit: false,
    creditRating: false,
    code1: false,
    code1Type: false,
    code2: false,
    code2Type: false,
    divisions: false,
    linkedinURL: false,
    twitterURL: false,
    photoName: false,
    photoUrl: false,
    defaultNoticePeriod: false,
    defaultNoticePeriodUnits: false,
    customField1: false, customField2: false, customField3: false, customField4: false, customField5: false, customField6: false, customField7: false, customField8: false, customField9: false, customField10: false,
    customField11: false, customField12: false, customField13: false, customField14: false, customField15: false, customField16: false, customField17: false, customField18: false, customField19: false, customField20: false,
    customField21: false, customField22: false, customField23: false, customField24: false, customField25: false, customField26: false, customField27: false, customField28: false, customField29: false, customField30: false, 
    customField31: false, customField32: false, customField33: false, customField34: false, customField35: false, customField36: false, customField37: false, customField38: false, customField39: false, customField40: false, 
    customField41: false, customField42: false, customField43: false, customField44: false, customField45: false, customField46: false, customField47: false, customField48: false, customField49: false, customField50: false, 
    customField51: false, customField52: false, customField53: false, customField54: false, customField55: false, customField56: false, customField57: false, customField58: false, customField59: false, customField60: false
};

export const PlaceholderClient: Client = {
	id: -1,
	legalName: "Recruit Wizard Pty Ltd",
	statusName: "Client",
	siteID: -1,
	siteName: "Sydney HQ",
	address1: "Suite 1, Level 1",
	address2: "16 Greenfield Parade",
	address3: "",
	suburb: "Bankstown",
	state: "NSW",
	postcode: "2200",
	countryCode: "AU",
	countryName: "Australia",
	parentCompany: -1,
	parentCompanyName: "Wizardsoft Pty Ltd",
	type: 3,
	phone: "+61 8071 8700",
	phone_Standardised: "+61 8071 8700",
	contacts: 0,
	allJobs: 0,
	openJobs: 0,
	placements: 0,
	contractors: 0,
	sites: 0,
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam in lacinia est, vel venenatis felis. Praesent sed rhoncus arcu. Nulla quis pellentesque orci. Duis blandit sodales auctor. Etiam ut justo egestas lacus sodales molestie. Nulla facilisi. Curabitur in metus odio. Nullam eu auctor nunc. In hac habitasse platea dictumst. Nulla ultrices lacinia leo ut blandit. Nullam aliquam justo enim, aliquet suscipit dui bibendum id. Fusce vitae egestas leo, nec mollis nibh. Donec libero libero, finibus vitae tellus eu, tincidunt rhoncus mi.<br/><br/> Cras dui ipsum, varius ut magna sit amet, lacinia tincidunt velit. Proin interdum ex augue. Etiam ac risus nunc. Nullam enim nunc, eleifend vel sem eget, interdum fermentum enim. Donec consectetur nibh quis dui rutrum ultrices. Nam congue nibh neque, at commodo elit fringilla quis. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Curabitur et semper sapien. Vivamus congue ante non lacus bibendum scelerisque ac sit amet felis. Morbi sit amet nunc faucibus, laoreet nunc quis, scelerisque purus.",
	website: "https://recruitwizard.com",
	rating: 1,
	createdByID: -1,
	createdByName: "Placeholder Creator Name",
	updatedByID: -1,
	updatedByName: "Placeholder Updater Name",
	primaryContact: -1,
	primaryContactName: "Glen Perry",
    primaryContactFirstName: "Glen",
    primaryContactLastName: "Perry",
	primaryContactPhone: '0408 808 877',
	primaryContactEmail: "glen@recruitwizard.com",
	primaryContactOptOut: false,
	defaultBillingContactID: -1,
	defaultBillingContactName: "Accounts Team",
	defaultTimesheetApproverID: -1,
	defaultTimesheetApproverName: "Ben Macpherson",
    defaultNoticePeriod: 0,
	defaultNoticePeriodUnits: 0,
	code1Type: 0,
	code1: "",
	code2Type: 0,
	code2: "",
	tradingName: "Recruit Wizard",
	divisions: "",
	cvDeadlinePerm: 0,
	cvDeadlineContract: 0,
	cvDeadlineFixedContract: 0,
    photoName: "",
	photoUrl: "",
	createdDate: "2010-10-10T10:10:10.100",
	updatedDate: "2010-10-10T10:10:10.100",
	lastActivityDate: "2010-10-10T10:10:10.100",
	nextActivityDate: "2010-10-10T10:10:10.100",
	lastContactDate: "2010-10-10T10:10:10.100",
	nextContactDate: "2010-10-10T10:10:10.100",
	twitterURL: "",
	linkedinURL: "",
	deleted: false,
	customField1: "Custom Field 1",
	customField2: "Custom Field 2",
	customField3: "Custom Field 3",
	customField4: 'Custom Field 4',
	customField5: 'Custom Field 5',
	customField6: 'Custom Field 6',
	customField7: 'Custom Field 7',
	customField8: 'Custom Field 8',
	customField9: 'Custom Field 9',
	customField10: 'Custom Field 10',
	customField11: 11,
	customField12: 12,
	customField13: 13,
	customField14: 14,
	customField15: 15,
	customField16: "2010-10-10T10:10:10.100",
	customField17: "2010-10-10T10:10:10.100",
	customField18: "2010-10-10T10:10:10.100",
	customField19: "2010-10-10T10:10:10.100",
	customField20: "2010-10-10T10:10:10.100",
	customField21: 'Custom Field 21',
	customField22: 'Custom Field 22',
	customField23: 'Custom Field 23',
	customField24: 'Custom Field 24',
	customField25: 'Custom Field 25',
	customField26: 'Custom Field 26',
	customField27: 'Custom Field 27',
	customField28: 'Custom Field 28',
	customField29: 'Custom Field 29',
	customField30: 'Custom Field 30',
	customField31: 'Custom Field 31',
	customField32: 'Custom Field 32',
	customField33: 'Custom Field 33',
	customField34: 'Custom Field 34',
	customField35: 'Custom Field 35',
	customField36: 'Custom Field 36',
	customField37: 'Custom Field 37',
	customField38: 'Custom Field 38',
	customField39: 'Custom Field 39',
	customField40: 'Custom Field 40',
	customField41: 41,
	customField42: 42,
	customField43: 43,
	customField44: 44,
	customField45: 45,
	customField46: 46,
	customField47: 47,
	customField48: 48,
	customField49: 49,
	customField50: 50,
	customField51: "2010-10-10T10:10:10.100",
	customField52: "2010-10-10T10:10:10.100",
	customField53: "2010-10-10T10:10:10.100",
	customField54: "2010-10-10T10:10:10.100",
	customField55: "2010-10-10T10:10:10.100",
	customField56: "2010-10-10T10:10:10.100",
	customField57: "2010-10-10T10:10:10.100",
	customField58: "2010-10-10T10:10:10.100",
	customField59: "2010-10-10T10:10:10.100",
	customField60: "2010-10-10T10:10:10.100",
    	ownerIds: [],
	tags: false,
	creditLimit: 0,
	creditRating: "",
	creditBalance: 0,
	statisticsCounts: [],
	statisticsDates: []
};
