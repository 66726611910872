import { InvoiceConfigOptions } from '../../../common/models/Configuration/Invoices';
import { ChangeTracker } from '../../../common/models/hooks/ChangeTracker';

export const DefaultOptions: InvoiceConfigOptions = {
    NotificationInvoiceApprovalRequest: "",
    InvoicesDefaultPaymentTerms: 14,
}

export const NoChangesTracker: ChangeTracker<InvoiceConfigOptions> = {
    NotificationInvoiceApprovalRequest: false,
    InvoicesDefaultPaymentTerms: false,
}