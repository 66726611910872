import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import React, { useMemo, useState } from "react";
import CandidatePicker from "components/Pickers/CandidatePicker";
import { CandidateList } from "common/models/Candidates/CandidateList";
import { Link } from "react-router-dom";

interface Props {
    open: boolean,
    contactIds: number[],
    closeHandler: () => void
}

export default function FloatCandidateDialog({ open, contactIds, closeHandler }: Props) {
    const [selectedCandidate, setSelectedCandidate] = useState<CandidateList | null>(null);

    const url = useMemo(() => {
        if (selectedCandidate) {
            const contactIdsQuery = encodeURIComponent(contactIds.join(','));
            return `/submissions/create?candidateIds=${selectedCandidate.id}&contactIds=${contactIdsQuery}`;
        }
        return '';
    }, [selectedCandidate, contactIds]);

    return (
        <Dialog open={open} maxWidth="md" fullWidth>
            <DialogTitle>Float Candidate</DialogTitle>
            <DialogContent>
                <div style={{ paddingTop: '10px' }}>
                    <CandidatePicker
                        value={selectedCandidate ? selectedCandidate.id : 0}
                        onSelectCallback={ setSelectedCandidate }
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="error" onClick={closeHandler}>Cancel</Button>
                { url === '' && <Button variant="contained" color="success" disabled>Submit</Button> }
                { Boolean(url) && 
                    <Link to={url} style={{ marginLeft: '.5rem' }}>
                        <Button variant="contained" color="success">Submit</Button>
                    </Link>
                }
                {/* {createPath === '' &&
                    <Button variant="contained" color="success" disabled >Submit</Button>
                }
                {Boolean(createPath) &&
                    <Link to={createPath} style={{ marginLeft: '.5rem' }}>
                        <Button variant="contained" color="success"  >Submit</Button>
                    </Link>
                } */}
            </DialogActions>
        </Dialog>
    );
}