import Autocomplete, { AutocompleteInputChangeReason } from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import TextField from "@mui/material/TextField";
import React, { useCallback, useEffect, useState } from "react";
import { Site } from "common/models/Site";
import { GetSiteById, SiteLookup } from "services/SitesService";
import QuickCreateSiteDialog from "../Dialogs/Sites/QuickCreateSiteDialog";

interface Props {
    value: number | null,
    onSelectCallback: (s: Site | null) => void,
    clientId?: number,
    variant?: "standard" | "filled" | "outlined" | undefined,
    label?: string,
    delayMs?: number,
    hideLabel?: boolean,
    error?: boolean,
    loadingHandler?: (isLoading: boolean) => void,
    successHandler?: (message: string) => void,
    errorHandler?: (message: string) => void,
}

export default function SitePicker({ value, label = 'Site', variant, hideLabel, clientId, delayMs = 200, error = false, onSelectCallback, errorHandler, loadingHandler, successHandler }: Props) {
    const [selectedSite, setSelectedSite] = useState<Site | null>(null);
    const [lookupTerm, setLookupTerm] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState<Site[]>([]);
    const [showQuickCreateDialog, setShowQuickCreateDialog] = useState(false);

    const getSite = useCallback(async (siteId: number) => {
        const res = await GetSiteById(siteId);
        if (res) setSelectedSite(res);
    }, []);

    useEffect(() => {
        if (value) {
            if (!selectedSite || (selectedSite && selectedSite.id !== value)) {
                const item = options.find(o => o.id === value);
                if (item) setSelectedSite(item);
                else getSite(value);
            }
        }
        else setSelectedSite(null);
    }, [value, selectedSite, options, getSite]);

    useEffect(() => {
        if (lookupTerm.length > 0) {
            const delaySearch = setTimeout(async () => {
                setIsLoading(true);
                const res = await SiteLookup(lookupTerm, 10, clientId);
                if (res) setOptions(res);
                setIsLoading(false);
            }, delayMs);
            return () => clearTimeout(delaySearch);
        }
    }, [lookupTerm, delayMs, clientId]);

    const handleInputChange = useCallback((event: React.SyntheticEvent<Element, Event>, value: string, reason: AutocompleteInputChangeReason) => {
        reason !== 'reset' && setLookupTerm(value);
    }, []);
    
    return (
        <Box display="flex">
            <QuickCreateSiteDialog
                open={showQuickCreateDialog}
                name={lookupTerm}
                closeHandler={() => setShowQuickCreateDialog(false)}
                onSiteCreated={ onSelectCallback }
                errorHandler={errorHandler}
                loadingHandler={loadingHandler}
                successHandler={successHandler}
            />
            <Autocomplete
                size="small"
                fullWidth
                options={options}
                value={ selectedSite }
                getOptionLabel={ o => o.name }
                isOptionEqualToValue={ (o,v) => o.id === v.id }
                loading={isLoading}
                filterOptions={ x => x }
                onChange={ (e,v) => onSelectCallback(v) }
                renderInput={ (params) => (
                    <TextField {...params} variant={variant} label={hideLabel ? undefined : label} error={error} inputProps={{ ...params.inputProps, "data-lpignore": "true" }}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (<>{isLoading ? <CircularProgress color="inherit" size={20} /> : params.InputProps.endAdornment}</>)
                        }}
                    />
                )}
                onInputChange={ handleInputChange }
                renderOption={(props, option) => (
                    <ListItem {...props} key={option.id}>
                        <ListItemText
                            primary={option.name}
                            secondary={(
                                <>
                                    {option.address1 && <Box>{option.address1}</Box>}
                                    {option.suburb && <Box>{option.suburb}</Box>}
                                    {option.state && <Box>{option.state}</Box>}
                                    {option.country && <Box>{option.country}</Box>}
                                </>
                            )}
                        />
                    </ListItem>
                )}
            />
            <IconButton onClick={() => setShowQuickCreateDialog(true)}><AddCircleIcon /></IconButton>
        </Box>
    );
}