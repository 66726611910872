import React, { useCallback, useEffect, useMemo, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Breakpoint } from "@mui/material/styles";
import moment from "moment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import Box from "@mui/material/Box";

interface Props {
    open: boolean,
    title: string,
    message?: string,
    onClose: () => void,
    onContinue: (date: string) => void,
    dateFieldLabel?: string,
    dateType?: 'date' | 'datetime-local',
    confirmActionText?: string,
    cancelActionText?: string,
    fullWidth?: boolean,
    maxWidth?: false | Breakpoint
    dividers?: boolean,
    hideBackdrop?: boolean,
    disablePast?: boolean,
    disableFuture?: boolean,
    initialDateValue?: string
}

export default function ConfirmationWithDateDialog(props: Props) {
    const [date, setDate] = useState<moment.Moment | null>(null);

    useEffect(() => {
        if (props.open) {
            let m = moment();
            if (props.initialDateValue && props.initialDateValue !== '0001-01-01T00:00:00') {
                const tmp = moment(props.initialDateValue);
                if (tmp.isValid()) m = tmp;
            }
            return setDate(m);
        }
    }, [props.open, props.initialDateValue, props.dateType]);

    const handleDateChange = useCallback((m: moment.Moment | null) => {
        setDate(m);
    }, []);

    const stringDate = useMemo(() => {
        const format = props.dateType === 'datetime-local' ? 'YYYY-MM-DDTHH:mm' : 'YYYY-MM-DD';
        if (date && date.isValid()) return date.format(format);
        return '0001-01-01T00:00:00';
    }, [props.dateType, date]);

    return (
        <Dialog open={props.open} maxWidth={props.maxWidth} fullWidth={props.fullWidth} hideBackdrop={props.hideBackdrop} >
            <DialogTitle>{props.title}</DialogTitle>
            <DialogContent dividers={props.dividers}>
                <Box mt="10px">
                    {props.message && <DialogContentText>{props.message}</DialogContentText> }
                    {props.dateType === 'date' &&
                        <DatePicker disablePast={props.disablePast} disableFuture={props.disableFuture} value={date} label={props.dateFieldLabel} onChange={handleDateChange} slotProps={{actionBar: { actions: ["clear", "today", "cancel", "accept"] }}} />
                    }
                    {props.dateType === 'datetime-local' &&
                        <DateTimePicker disablePast={props.disablePast} disableFuture={props.disableFuture} value={date} label={props.dateFieldLabel} onChange={handleDateChange}
                                        slotProps={{
                                            actionBar: { actions: ["clear", "today", "cancel", "accept"] },
                                        }}/>
                    }
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="error"
                    onClick={ props.onClose }
                >
                    { props.cancelActionText ? props.cancelActionText : 'Cancel' }
                </Button>
                <Button
                    variant="contained"
                    color="success"
                    onClick={ () => props.onContinue(stringDate) }
                >
                    { props.confirmActionText ? props.confirmActionText : 'Continue' }
                </Button>
            </DialogActions>
        </Dialog>
    );
}