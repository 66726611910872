import React, { useCallback, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { AutomationPlacementLog } from "common/models/Automation/AutomationPlacementLog";
import { AutomationCandidateLog } from "common/models/Automation/AutomationCandidateLog";
import { GridRenderCellParams } from "@mui/x-data-grid-premium";
import Link from "@mui/material/Link";
import { GetCandidateAutomationLogs, GetPlacementAutomationLogs } from "services/AutomationService";
interface Props {
    open: boolean,
    moduleId: number,
    automationId: number,
    automationName: string,
    cancelHandler: () => void,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
}
const linkStyle: React.CSSProperties = { color: 'inherit', textDecoration: 'underline' };
export default function AutomationLogDataDialog({open, moduleId,automationId,automationName, cancelHandler,loadingHandler,errorHandler}: Props) {
    const [rows, setRows] = useState<AutomationPlacementLog[] | AutomationCandidateLog[]>([]);
    const [columns, setColumns] = useState<GridColDef[]>([]);
    const [loadingForDialog, setLoadingForDialog] = useState<boolean>(false);

    const linkToCandidateRenderer = (params: GridRenderCellParams) => {
        if (params.id) {
            return (
                <Link
                    href={`/candidates/${params.row.candidateId}`}
                    style={linkStyle}
                    target="_blank"
                >{params.value}</Link>
            );
        }
        return params.value;
    };

    const linkToPlacementRenderer = (params: GridRenderCellParams) => {
        if (params.id) {
            return (
                <Link
                    href={`/placements/${params.row.placementId}`}
                    style={linkStyle}
                    target="_blank"
                >{params.value}</Link>
            );
        }
        return params.value;
    };
    
    const getData = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        let res: AutomationCandidateLog[]| AutomationPlacementLog[] | null = [];
        if (moduleId === 5) {
            setColumns([
                { field: 'id', headerName: 'Id', width: 100},
                { field: 'placementId', headerName: 'Placement Id', width: 200, renderCell: linkToPlacementRenderer},
                { field: 'executedDateDisplay', headerName: 'Date Executed', width: 200},
                { field: 'clientName', headerName: 'Client Name', width: 200},
                { field: 'jobTitle', headerName: 'Job Title', width: 200},
                { field: 'startDateDisplay', headerName: 'Start Date', width: 200},
                { field: 'endDateDisplay', headerName: 'End Date', width: 200},
                { field: 'candidate', headerName: 'Candidate', width: 200},
              ]);
            res = await GetPlacementAutomationLogs(automationId,errorHandler);
        } else if(moduleId === 3) {
            setColumns([
                { field: 'id', headerName: 'Id', width: 100},
                { field: 'executedDateDisplay', headerName: 'Date Executed', width: 200},
                { field: 'fullName', headerName: 'Full Name', width: 200,renderCell: linkToCandidateRenderer},
                { field: 'dbo', headerName: 'Date of Birth', width: 200},
                { field: 'cvUpdatedDateDisplay', headerName: 'CV updated date', width: 200},
                { field: 'profileUpdatedDateDisplay', headerName: 'Profile updated date', width: 200},
                { field: 'lastContactDateDisplay', headerName: 'Last Contact Date', width: 200},
              ]);
            res = await GetCandidateAutomationLogs(automationId,errorHandler);
        }
        
        if (res) {
            setRows(res);
        }
        setLoadingForDialog(true);
        loadingHandler && loadingHandler(false);
        
    }, [automationId, errorHandler, loadingHandler, moduleId]);

    useEffect(() => {
        if(!loadingForDialog && open) {
            getData();
        }
    }, [getData,open,loadingForDialog]);

    const closeDialogHandler = useCallback( () => {
        cancelHandler();
        setRows([]);
        setLoadingForDialog(false);
    },[setLoadingForDialog, cancelHandler, setRows]);


    return (
        <Dialog 
         open={open} hideBackdrop={false} 
         fullWidth={true}
         maxWidth={'xl'}>
            <DialogTitle>Logs for Automation: {automationName}</DialogTitle>
            <DialogContent style={{height: 'Auto'}}>
            <div style={{ height: 600, width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 100 },
          },
          columns: {
            columnVisibilityModel: {
                id: false,
                customerId: false
            }
          }
        }}
        pageSizeOptions={[100,250,500,1000]}
      />
    </div>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="error" onClick={closeDialogHandler} >Close</Button>
            </DialogActions>
        </Dialog>
    );
}