import React, { useCallback, useEffect, useMemo, useState } from "react";
import PageContentLayout from "layouts/PageContentLayout";
import { useParams } from "react-router-dom";
import {
    GetAstuteChargeCodesByDivisionId,
    GetAstuteEntitiesByDivisionId,
    GetAstuteGLGroupsByDivisionId,
    GetAstuteSettingsByDivisionId,
    GetAstuteSuperItemsByDivisionId,
    UpdateAstuteSettingsByDivisionId
} from "services/AstutePayrollService";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { AstutePayrollRegion, AstutePayrollSettings } from "common/models/Configuration/CustomerIntegrations";
import { ChangeTracker } from "common/models/hooks/ChangeTracker";
import useObjectStateWithChangeTracker from "hooks/UseObjectStateWithChangeTracker";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { NameIdObj } from "common/models/GenericTypes";
import MenuItem from "@mui/material/MenuItem";
import { DataGridPremium, GridColDef, GridRenderEditCellParams, useGridApiRef } from "@mui/x-data-grid-premium";
import Button from "@mui/material/Button";
import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import useUnsavedChangesDialog from "hooks/UseUnsavedChangesDialog";
import RWTextFieldComponent from "components/RWTextFieldComponent";

interface Props {
    setSummaryBar?: (summaryBar: JSX.Element) => void
}

const DefaultAstuteSettings: AstutePayrollSettings = {
    apiKey: '',
    apiPassword: '',
    apiUsername: '',
    collectTimesheetData: false,
    collectTimesheetLastRun: '', // date
    entityID: 0,
    permChargeItemID: 0,
    region: '',
    regionSettings: [],
};

const NoChangesAstuteSettings: ChangeTracker<AstutePayrollSettings> = {
    apiKey: false,
    apiPassword: false,
    apiUsername: false,
    collectTimesheetData: false,
    collectTimesheetLastRun: false,
    entityID: false,
    permChargeItemID: false,
    region: false,
    regionSettings: false,
};

interface GridValueOptions {
    label: string,
    value: number,
};

type GridValueOptionsMap = Record<number, string>;

export default function TimesheetIntegrationAstutePayroll({ setSummaryBar }: Props) {
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [entities, setEntities] = useState<NameIdObj[]>([]);
    const [chargeCodes, setChargeCodes] = useState<NameIdObj[]>([]);
    const [superItems, setSuperItems] = useState<GridValueOptions[]>([]);
    const [glGroups, setGlGroups] = useState<GridValueOptions[]>([]);
    const [regions, setRegions] = useState<AstutePayrollRegion[]>([]);
    const [gridChanged, setGridChanged] = useState(false);

    const { state, init, change, updateInitial, hasChanges } = useObjectStateWithChangeTracker<AstutePayrollSettings>(DefaultAstuteSettings, NoChangesAstuteSettings);

    const params = useParams();
    const apiRef = useGridApiRef();

    const divisionId = useMemo(() => {
        const parsedId = +(params.id ?? '0');
        if (isNaN(parsedId)) return 0;
        return parsedId;
    }, [params.id]);

    const saveHandler = useCallback(async () => {
        setIsLoading(true);
        const settings: AstutePayrollSettings = { ...state, regionSettings: regions };
        const res = await UpdateAstuteSettingsByDivisionId(divisionId, settings, setErrorMessage);
        if (!res) {
            setIsLoading(false);
            return false;
        }

        updateInitial();

        // FETCH INTEGRATION SETTINGS
        const res2 = await GetAstuteSettingsByDivisionId(divisionId, setErrorMessage);
        if (res2) {
            if (res2.apiKey && res2.apiUsername && res2.apiPassword) {
                const e = await GetAstuteEntitiesByDivisionId(divisionId, setErrorMessage);
                
                const c = await GetAstuteChargeCodesByDivisionId(divisionId, setErrorMessage);
                
                const s = await GetAstuteSuperItemsByDivisionId(divisionId, setErrorMessage);

                const g = await GetAstuteGLGroupsByDivisionId(divisionId, setErrorMessage);
                
                if (e) setEntities(e);
                if (c) setChargeCodes(c);
                if (s) {
                    const options = s.map(o => ({ label: o.name, value: o.id }));
                    setSuperItems([{ label: 'None', value: 0 }, ...options]);
                }

                if (g) {
                    const options = g.map(o => ({ label: o.name, value: o.id }));
                    setGlGroups([{ label: 'None', value: 0 }, ...options]);
                }
            }
            if (res2.regionSettings) setRegions(res2.regionSettings);
            init({ ...res2, regionSettings: [] });
        }

        setSuccessMessage('Changes Saved');
        setGridChanged(false);
        setIsLoading(false);
        return true;
    }, [divisionId, init, regions, state, updateInitial]);

    useEffect(() => {
        const action = <Button variant="contained" color="primary" onClick={ saveHandler } disabled={!hasChanges && !gridChanged}>Save</Button>;
        const summaryBar = <TitleAndActionSummaryBar title="Configuration > Divisions > Astute Payroll" browserTabTitle="Divisions > Configuration" action={action} />;
        setSummaryBar && setSummaryBar(summaryBar);
    }, [setSummaryBar, hasChanges, gridChanged, saveHandler]);

    useEffect(() => {
        const getData = async () => {
            setIsLoading(true);
            // FETCH INTEGRATION SETTINGS
            const res = await GetAstuteSettingsByDivisionId(divisionId, setErrorMessage);
            if (res) {
                if (res.apiKey && res.apiUsername && res.apiPassword) {
                    const e = await GetAstuteEntitiesByDivisionId(divisionId, setErrorMessage);

                    const c = await GetAstuteChargeCodesByDivisionId(divisionId, setErrorMessage);

                    const s = await GetAstuteSuperItemsByDivisionId(divisionId, setErrorMessage);

                    const g = await GetAstuteGLGroupsByDivisionId(divisionId, setErrorMessage);

                    if (e) setEntities(e);
                    if (c) setChargeCodes(c);
                    if (s) {
                        const options = s.map(o => ({ label: o.name, value: o.id }));
                        setSuperItems([{ label: 'None', value: 0 }, ...options]);
                    }

                    if (g) {
                        const options = g.map(o => ({ label: o.name, value: o.id }));
                        setGlGroups([{ label: 'None', value: 0 }, ...options]);
                    }
                }
                if (res.regionSettings) setRegions(res.regionSettings);
                init({...res, regionSettings: []});
            }
            setIsLoading(false);
        };
        getData();
    }, [divisionId, init]);

    const onStringFieldChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        let val = value;
        change(name as keyof AstutePayrollSettings, val);
    }, [change]);

    const handleNumberChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        let val = +value;
        change(name as keyof AstutePayrollSettings, val);
    }, [change]);

    const superGridValuesMap = useMemo<GridValueOptionsMap>(() => {
        let map: GridValueOptionsMap = { 0: 'None' };
        for (let i = 0; i < superItems.length; i++) {
            const item = superItems[i];
            map[item.value] = item.label;
        }
        return map;
    }, [superItems]);

    const glGroupsGridValuesMap = useMemo<GridValueOptionsMap>(() => {
        let map: GridValueOptionsMap = { 0: 'None' };
        for (let i = 0; i < glGroups.length; i++) {
            const item = glGroups[i];
            map[item.value] = item.label;
        }
        return map;
    }, [glGroups]);

    const columns = useMemo<GridColDef[]>(() => {
        const superItemRenderer = (params: GridRenderEditCellParams) => {
            return superGridValuesMap[params.value];
        };

        const glGroupRenderer = (params: GridRenderEditCellParams) => {
            return glGroupsGridValuesMap[params.value];
        };

        return [
            { field: 'region', headerName: 'Region', width: 200 },
            { field: 'defaultSuperItemID', headerName: 'Default Super Item', width: 150, editable: true, type: 'singleSelect', valueOptions: superItems, renderCell: superItemRenderer },
            { field: 'glGroupEmployee', headerName: 'GL Group Employee', width: 150, editable: true, type: 'singleSelect', valueOptions: glGroups, renderCell: glGroupRenderer },
            { field: 'glGroupSubContractor', headerName: 'GL Group Sub Contractor', width: 200, editable: true, type: 'singleSelect', valueOptions: glGroups, renderCell: glGroupRenderer },
            { field: 'permIncomeAccount', headerName: 'Perm Income', width: 150, editable: true },
            { field: 'contractIncomeAccountEmployee', headerName: 'Contract Income Employee', width: 200, editable: true },
            { field: 'contractExpenseAccountEmployee', headerName: 'Contract Expense Employee', width: 200, editable: true },
            { field: 'contractIncomeAccountSubContractor', headerName: 'Contract Income Sub Contractor', width: 200, editable: true },
            { field: 'contractExpenseAccountSubContractor', headerName: 'Contract Expense Sub Contractor', width: 200, editable: true },
        ];
    }, [superGridValuesMap, glGroupsGridValuesMap, superItems, glGroups]);

    const processRowUpdate = useCallback(async (newRegion: AstutePayrollRegion) => {
        setGridChanged(true);
        setRegions(prev => {
            let tmp = [...prev];
            const index = tmp.findIndex(v => v.region === newRegion.region);
            if (index >= 0) tmp[index] = {...newRegion};
            return tmp;
        });
        return newRegion;
    }, []);

    const { unsavedChangesDialog } = useUnsavedChangesDialog(hasChanges || gridChanged, saveHandler);
    
    return (
        <>
            { unsavedChangesDialog }
            <Snackbar open={successMessage !== ''} autoHideDuration={3000} onClose={() => setSuccessMessage('')}>
                <Alert onClose={() => setSuccessMessage('')}>{ successMessage }</Alert>
            </Snackbar>
            <Snackbar open={errorMessage !== ''} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <PageContentLayout title="Astute Payroll" showLoading={isLoading}>
                <Stack spacing={2}>
                    <RWTextFieldComponent label="API Key" name="apiKey" value={state.apiKey} onChange={onStringFieldChange} />
                    <RWTextFieldComponent label="API Username" name="apiUsername" value={state.apiUsername} onChange={onStringFieldChange} />
                    <RWTextFieldComponent label="API Password" name="apiPassword" value={state.apiPassword} onChange={onStringFieldChange} />
                    <TextField select label="Entity" name="entityID" value={state.entityID.toString()} onChange={handleNumberChange} >
                        <MenuItem value="0">Unknown</MenuItem>
                        {entities.map((e, i) => <MenuItem key={i} value={e.id.toString()}>{e.name}</MenuItem>)}
                    </TextField>
                    <TextField select label="Perm Charge Item" name="permChargeItemID" value={state.permChargeItemID.toString()} onChange={handleNumberChange}>
                        <MenuItem value="0">Unknown</MenuItem>
                        {chargeCodes.map((e, i) => <MenuItem key={i} value={e.id.toString()}>{e.name}</MenuItem>)}
                    </TextField>
                    <TextField select label="Collect Timesheet Data" name="collectTimesheetData" value={state.collectTimesheetData ? '1' : '0'} onChange={handleNumberChange}>
                        <MenuItem value="0">No</MenuItem>
                        <MenuItem value="1">Yes</MenuItem>
                    </TextField>
                    <div style={{ height: '100%', width: '100%', minHeight: '500px', display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                        <div style={{ display: 'flex', height: '100%', flexDirection: 'column', flexGrow: 1 }}>
                            <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', height: '100%' }}>
                                <DataGridPremium
                                    apiRef={apiRef}
                                    rows={regions}
                                    columns={columns}
                                    getRowId={r => r.region}
                                    density="compact"
                                    processRowUpdate={processRowUpdate}
                                />
                            </div>
                        </div>
                    </div>
                </Stack>
            </PageContentLayout>
        </>
    );
}
