import CompanyDashboardLayout from "components/Analytics/Dashboards/CompanyDashboardLayout";
import OnlyTitleSummaryBar from "components/SummaryBars/OnlyTitleSummaryBar";
import PageContentLayout from "layouts/PageContentLayout";
import React, { useEffect, useState } from "react";
import PageLayout from "../../../layouts/PageLayout";

export default function CompanyDashboardPage() {
    const [summaryBar, setSummaryBar] = useState(<></>);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const summaryBar = <OnlyTitleSummaryBar title="Analytics > Dashboards > Company Dashboard" browserTabTitle="Company Dashboard > Dashboards > Analytics" />
        setSummaryBar && setSummaryBar(summaryBar);
    }, []);
    
    return (
        <PageLayout SummaryBar={summaryBar}>
            <PageContentLayout showLoading={isLoading} >
                <CompanyDashboardLayout loadingHandler={setIsLoading} />
            </PageContentLayout>
        </PageLayout>
    );
}