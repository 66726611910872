import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

interface Props {
    SummaryBar: JSX.Element,
    SideBar: JSX.Element,
    paddingTop?: number,
}

export default function PageWithSidebarLayout({ SummaryBar, SideBar, paddingTop = 2, children }: React.PropsWithChildren<Props>) {
    
    return (
        <>
        <Box position="sticky" top="0" sx={{ zIndex: (t) => t.zIndex.appBar - 2 }}>
            {SummaryBar}
        </Box>
        <Grid container>
            <Grid item display={{ xs: 'none', lg: 'initial' }} lg={2} xl="auto" sx={{ overflowY: 'auto', height: 'calc(100vh - 96px)' }}>
                {SideBar}
            </Grid>
            <Grid item xs={12} lg={10} xl sx={{ overflowY: 'auto', height: 'calc(100vh - 96px)' }}>
                <Container maxWidth={false} sx={{ pb: 2, pt: paddingTop ?? 2, height: '100%', display: 'flex', flexDirection: 'column', overflowY: 'auto' }}>
                    {children}
                </Container>
            </Grid>
        </Grid>
        </>
    );
}