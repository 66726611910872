import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import React, { useCallback } from "react";
import { AcknowledgeRedFlag } from "services/CandidatesService";
import DialogContentText from "@mui/material/DialogContentText";
import moment from "moment/moment";

interface Props {
    open: boolean,
    candidateId: number,
    candidateName: string,
    date: string,
    flaggedBy: string,
    comments: string,
    closeHandler: () => void,
    loadingHandler?: (isLoading: boolean) => void,
    successHandler?: (message: string) => void,
    errorHandler?: (message: string) => void
}

export default function RedFlagWarningDialog({ open, candidateId, candidateName, date, flaggedBy, comments, closeHandler, loadingHandler, successHandler, errorHandler }: Props) {
    const mDate = moment(date);
    const dateVal = mDate.format('DD MMM YYYY');

    const submitCallback = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        const res = await AcknowledgeRedFlag(candidateId, errorHandler);
        if (res) {
            closeHandler();
        }
        loadingHandler && loadingHandler(false);

    }, [candidateId, loadingHandler, errorHandler, closeHandler]);
    
    return (
        <Dialog open={open} maxWidth={"lg"} >
            <DialogTitle style={{color: 'red'}}>Red Flag Warning</DialogTitle>
            <DialogContent dividers>
                <Stack spacing={2} mt="10px">
                    <DialogContentText>{candidateName} was red flagged by {flaggedBy} on {dateVal}. Details are below:</DialogContentText>
                    <DialogContentText>{comments}</DialogContentText>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="success" onClick={ submitCallback } >Acknowledge</Button>
            </DialogActions>
        </Dialog>
    );
}