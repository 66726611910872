import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";

import PageLayout from "layouts/PageLayout";
import PageContentLayout from "layouts/PageContentLayout";
import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import { Site } from "common/models/Site";
import { GetSiteById } from "services/SitesService";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { GetSettingBySettingName, UpdateSettingBySettingName } from "services/UsersService";

import Avatar from '@mui/material/Avatar';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import { AllowClientsAddEdit } from "common/data/Permissions/ClientsAccess";
import Snackbar from "@mui/material/Snackbar";
import Alert from "components/Alert";
import ActionMenu from "components/Menus/ActionMenu";
import { AllowJobsAddEdit } from "common/data/Permissions/JobsAccess";
import moment from "moment";
import { MenuOptionDefinition } from "common/models/MenuDefinition";
import PreviewLoaderComponent from "components/Previews/PreviewLoader";
import TagsManagementDialog from "components/Dialogs/TagsManagementDialog";
import PlacementsGridComponent from "components/Grids/PlacementsGrid";
import JobsGridComponent from "components/Grids/JobsGrid";
import Switch from "@mui/material/Switch";
import JobsKanbanComponent from "components/Kanbans/JobsKanban";
import ContactsGridComponent from "components/Grids/ContactsGrid";
import DocumentsGridComponent from "components/Grids/DocumentsGrid";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { Permission } from "common/models/Permissions";
import { companyHasSinglePermission, userHasSinglePermission } from "util/PermissionsUtils";
import { AddRecentRecord } from "util/LocalStorageUtils";
import ViewRecordScreenLayout from "components/ScreenLayouts/Sites/ViewRecordScreenLayout";

const summaryLinkStyle: React.CSSProperties = { color: 'inherit', textDecoration: 'none' };
// const fullHeightStyle: React.CSSProperties = { height: '100%' };
const defaultJobViewSettingName = 'DefaultJobsViewSites';

const formatDateString = (d: string, f: string = 'DD MMM YYYY h:mm A') => {
    if (d && d !== '0001-01-01T00:00:00') {
        const m = moment(d);
        if (m.isValid()) return m.format(f);
    }
    return 'Never';
}

const renderSummary = (c: Site) => {
    const tooltipContent = (
        <table>
            <tbody>
                <tr>
                    <Typography variant="caption" fontWeight={600} component="td">ID</Typography>
                    <Typography variant="caption" pl="10px" component="td">{c.id}</Typography>
                </tr>
                <tr>
                    <Typography variant="caption" fontWeight={600} component="td" sx={{ verticalAlign: 'top' }}>Name</Typography>
                    <Typography variant="caption" pl="10px" component="td">{c.name}</Typography>
                </tr>
                <tr>
                    <Typography variant="caption" fontWeight={600} component="td">Created Date</Typography>
                    <Typography variant="caption" pl="10px" component="td">{formatDateString(c.createdDate)}</Typography>
                </tr>
                <tr>
                    <Typography variant="caption" fontWeight={600} component="td">Created By</Typography>
                    <Typography variant="caption" pl="10px" component="td">{c.createdByName}</Typography>
                </tr>
                <tr>
                    <Typography variant="caption" fontWeight={600} component="td">Updated Date</Typography>
                    <Typography variant="caption" pl="10px" component="td">{formatDateString(c.updatedDate)}</Typography>
                </tr>
                <tr>
                    <Typography variant="caption" fontWeight={600} component="td">Updated By</Typography>
                    <Typography variant="caption" pl="10px" component="td">{c.updatedByName}</Typography>
                </tr>
            </tbody>
        </table>
    );
    const siteName = (
        <Tooltip arrow title={tooltipContent}>
            <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{c.name}</span>
        </Tooltip>
    );
    const statusName = <span style={{ padding: '0 5px' }}>{`(${c.status})`}</span>;
    return (<>{siteName}{statusName}</>);
};

const googleMapsLink = (c: Site) => {
    const baseUrl = 'https://www.google.com/maps/search/?api=1';
    let address = '';
    if (c.address1) address = c.address1;
    if (c.address2) address += ' ' + c.address2;
    if (c.address3) address += ' ' + c.address3;
    if (c.suburb) address += ' ' + c.suburb;
    if (c.state) address += ' ' + c.state;
    if (c.postcode) address += ' ' + c.postcode;
    if (c.countryName) address += ' ' + c.countryName;

    if (address !== '') {
        return `${baseUrl}&query=${encodeURIComponent(address)}`;
    }
    return null;
}

export default function SiteRecord() {
    const [site, setSite] = useState<Site>();
    const params = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [isFetchingDefaultTab, setIsFetchingDefaultTab] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    // const [isRecordDeleted, setIsRecordDeleted] = useState(false);
    const [isLoadingTab, setIsLoadingTab] = useState(false);
    const [showTagsPrevew, setShowTagsPreview] = useState(false);
    const [showTagsPrevewNoDelay, setShowTagsPreviewNoDelay] = useState(false);
    const [showTagsManagement, setShowTagsManagement] = useState(false);
    const [isFinishedSetup, setIsFinishedSetup] = useState(false);
    // const [showSendReferralDialog, setShowSendReferralDialog] = useState(false);
    const [searchParams] = useSearchParams();
    // const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [activeTab, setActiveTab] = useState('Home');
    const [defaultTab, setDefaultTab] = useState<string>();
    const [tabSetupFinished, setTabSetupFinished] = useState(false);
    const [jobsView, setJobsView] = useState<'list' |'kanban'>('list');
    const [tabActionMenuOptions, setTabActionMenuOptions] = useState<MenuOptionDefinition[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        if (!showTagsPrevew) setShowTagsPreviewNoDelay(false);
    }, [showTagsPrevew]);

    const canAccessContacts = useMemo(() => companyHasSinglePermission(Permission.Contacts) && userHasSinglePermission(Permission.Contacts), []);
    const canAccessJobs = useMemo(() => companyHasSinglePermission(Permission.Jobs) && userHasSinglePermission(Permission.Jobs), []);
    const canAccessPlacements = useMemo(() => companyHasSinglePermission(Permission.Placements) && userHasSinglePermission(Permission.Placements), []);

    const siteId = useMemo(() => {
        const parsedId = +(params.id ?? '0');
        if (isNaN(parsedId)) return 0;
        return parsedId;
    }, [params.id]);

    const siteIdArray = useMemo(() => [siteId], [siteId]);

    const canEditClients = useMemo(() => AllowClientsAddEdit(), []);
    const canAddJobs = useMemo(() => AllowJobsAddEdit(), []);

    useEffect(() => {
        const getData = async () => {
            setIsLoading(true);
            const res = await GetSiteById(siteId);
            if (res === null) navigate('/not-found');
            if (res) {
                AddRecentRecord(9, siteId, res.name);
                setSite(res);
            }
            setIsLoading(false);
        };
        getData();
    }, [siteId, navigate]);

    useEffect(() => {
        const tabParam = searchParams.get('tab');
        const getData = async () => {
            setIsFetchingDefaultTab(true);
            const defaultJobView = await GetSettingBySettingName(defaultJobViewSettingName);
            if (defaultJobView) setJobsView(defaultJobView === 'list' ? 'list' : 'kanban');
            if (!tabParam) {
                const tabSetting = await GetSettingBySettingName('TabDefaultsSites');
                if (tabSetting) setDefaultTab(tabSetting);
            }
            else setActiveTab(tabParam)
            setIsFetchingDefaultTab(false);
            setIsFinishedSetup(true);
        };
        getData();
    }, [searchParams]);

    const actionMenuDefinitions = useMemo<MenuOptionDefinition[]>(() => {
        let actions: (MenuOptionDefinition)[] = [
            ...tabActionMenuOptions,
            { label: 'Create Job', type: 'parent', allow: () => canAddJobs, subMenu: [
                { label: 'Contract', type: 'link', href: `/jobs/create?SiteID=${siteId}&JobType=Contract` },
                { label: 'Fixed Term', type: 'link', href: `/jobs/create?SiteID=${siteId}&JobType=FixedContract` },
                { label: 'Panel', type: 'link', href: `/jobs/create?SiteID=${siteId}&JobType=Panel` },
                { label: 'Permanent', type: 'link', href: `/jobs/create?SiteID=${siteId}&JobType=Permanent` },
                { label: 'Talent Pool', type: 'link', href: `/jobs/create?SiteID=${siteId}&JobType=TalentPool` },
            ]},
        ];

        return actions;
    }, [tabActionMenuOptions,  canAddJobs, siteId]);

    const SummaryBar = useMemo(() => {
        if (site) {
            const tagsAction = (
                <Avatar
                    key="tagsAction"
                    onClick={ canEditClients ? () => setShowTagsManagement(true) : () => setShowTagsPreviewNoDelay(true) }
                    onMouseEnter={ () => setShowTagsPreview(true) }
                    onMouseLeave={ () => setShowTagsPreview(false) }
                    sx={{ bgcolor: '#f209a6', mr: '5px', cursor: 'pointer' }}>
                    { site.tags ? <LocalOfferIcon /> : <LocalOfferOutlinedIcon /> }
                </Avatar>
            );

            const locationUrl = googleMapsLink(site);
            const locationAction = locationUrl ? (
                <a href={ locationUrl }
                    style={summaryLinkStyle}
                    key="locationAction"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <Avatar sx={{ bgcolor: "#ea4335", mr: '5px' }}>
                        <LocationOnIcon />
                    </Avatar>
                </a>
            ) : <Avatar key="locationPlaceholder" sx={{ mr: '5px' }}><LocationOnIcon /></Avatar>;

            const action1 = canEditClients ? (
                <Link key="editLink" to={`/sites/${siteId}/edit`} style={summaryLinkStyle}>
                    <Button variant="contained" color="success" sx={{ mr: '5px' }}>Edit</Button>
                </Link>
            ) : <React.Fragment key="editPlaceholder" />;

            const action = (
                <Box key="actionsMenu">
                    <ActionMenu definition={actionMenuDefinitions} />
                </Box>
            );

            return (
                <TitleAndActionSummaryBar
                    height="4.5rem"
                    title={site ? renderSummary(site) : ''}
                    action={[locationAction, tagsAction, action1, action]}
                    browserTabTitle={!site ? '' : site.name + " - Sites"}
                />
            );
        }
    }, [siteId, site, actionMenuDefinitions, canEditClients]);

    const handleTabChange = useCallback((e: React.SyntheticEvent, newValue: string) => {
        navigate(`/sites/${siteId}?tab=${newValue}`);
    }, [siteId, navigate]);


    const counts = useMemo(() => {
        let counts = {
            documents: 0,
            contacts: 0,
            openJobs: 0,
            openJobsDate: '\u00A0',
            jobs: 0,
            jobsDate: '\u00A0',
            contractors: 0,
            contractorsDate: '\u00A0',
        };

        if (site && site.statisticsCounts && site.statisticsCounts.length > 0) {
            for (let i = 0; i < site.statisticsCounts.length; i++) {
                const s = site.statisticsCounts[i];
                switch (s.type) {
                    case "DocumentsCount": counts.documents = s.value;
                        break;
                    case "ContactsCount": counts.contacts = s.value;
                        break;
                    case "JobsOpenCount": counts.openJobs = s.value;
                        break;
                    case "JobsCount": counts.jobs = s.value;
                        break;
                    case "ContractorsCount": counts.contractors = s.value;
                        break;
                }
            }
        }
        if (site && site.statisticsDates && site.statisticsDates.length > 0) {
            for (let i = 0; i < site.statisticsDates.length; i++) {
                const s = site.statisticsDates[i];
                switch (s.type) {
                    case "JobsOpenDate":
                        const joDate = moment(s.value);
                        if (joDate.isValid()) counts.openJobsDate = joDate.format('DD MMM YYYY');
                        break;
                    case "JobsDate":
                        const jDate = moment(s.value);
                        if (jDate.isValid()) counts.jobsDate = jDate.format('DD MMM YYYY');
                        break;
                    case "ContractorsDate":
                        const pDate = moment(s.value);
                        if (pDate.isValid()) counts.contractorsDate = pDate.format('DD MMM YYYY');
                        break;
                }
            }
        }
        return counts;
    }, [site]);

    const jobsViewChangeHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = e.target.checked;
        const value = isChecked ? 'list' : 'kanban';
        setJobsView( value );
        UpdateSettingBySettingName(defaultJobViewSettingName, value);
    }, []);

    const openJobsViewSwitch = useMemo(() => (
        <span>
            <span>Kanban</span>
            <Switch checked={jobsView === 'list'} onChange={ jobsViewChangeHandler } />
            <span>List</span>
        </span>
    ), [jobsView, jobsViewChangeHandler]);

    useEffect(() => {
        if (tabActionMenuOptions.length > 0 && activeTab !== 'Home') {
            setTabActionMenuOptions([]);
        }
    }, [activeTab, tabActionMenuOptions]);

    const tagManagementSuccessHandler = useCallback((message: string, recordIds: number[], finalTagCount: number) => {
        setSuccessMessage(message);
    }, []);

    useEffect(() => {
        if (defaultTab && !tabSetupFinished) {
            const accessRestrictedTabs = ['Jobs', 'OpenJobs'];
            setActiveTab(prev => {
                if (accessRestrictedTabs.includes(defaultTab) && !canAccessJobs) return prev;
                if (defaultTab === 'Contacts' && !canAccessContacts) return prev;
                if (defaultTab === 'Contractors' && !canAccessPlacements) return prev;
                return defaultTab;
            });
            setTabSetupFinished(true);
        }
    }, [defaultTab, canAccessPlacements, canAccessJobs, canAccessContacts, tabSetupFinished]);

    return (
        <>
            <Snackbar open={successMessage !== ''} autoHideDuration={3000} onClose={() => setSuccessMessage('')}>
                <Alert onClose={() => setSuccessMessage('')}>{ successMessage }</Alert>
            </Snackbar>
            {/* <Snackbar open={isRecordDeleted} autoHideDuration={1200} onClose={() => navigate('/sites/')}>
                <Alert onClose={() => navigate('/sites/')}>Record deleted</Alert>
            </Snackbar> */}
            <Snackbar open={errorMessage !== ''} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <PageLayout paddingTop={0} SummaryBar={SummaryBar}>
                <PreviewLoaderComponent
                    open={showTagsPrevew}
                    entityType="site"
                    recordId={siteId}
                    showDelayMs={showTagsPrevewNoDelay ? 0 : undefined}
                    isTagsPreview
                />
                <TagsManagementDialog
                    open={showTagsManagement}
                    entityId={9}
                    recordIds={siteIdArray}
                    closeHandler={ () => setShowTagsManagement(false) }
                    loadingHandler={ setIsLoading }
                    errorHandler={ setErrorMessage }
                    successHandler={ tagManagementSuccessHandler }
                />
                <Tabs value={activeTab} onChange={handleTabChange}>
                    <Tab value="Home" label={<>Home<br/>&nbsp;</>} />
                    {canAccessContacts && <Tab value="Contacts" label={<>Contacts ({counts.contacts})<br />&nbsp;</>} />}
                    {canAccessJobs && <Tab value="OpenJobs" label={<>Open Jobs ({counts.openJobs})<br />{counts.openJobsDate}</>} />}
                    {canAccessJobs && <Tab value="Jobs" label={<>All Jobs ({counts.jobs})<br />{counts.jobsDate}</>} />}
                    {canAccessPlacements && <Tab value="Contractors" label={<>Contractors ({counts.contractors})<br />{counts.contractorsDate}</>} />}
                    <Tab value="Documents" label={<>Documents ({counts.documents})<br />&nbsp;</>} />
                </Tabs>
                <PageContentLayout showLoading={isLoading || isFetchingDefaultTab || isLoadingTab}>
                    { isFinishedSetup && activeTab === "Home" &&
                        <ViewRecordScreenLayout
                            site={site ? site : null}
                            loadingHandler={setIsLoading}
                            errorHandler={setErrorMessage}
                        />
                    }
                    { isFinishedSetup && canAccessContacts && activeTab === "Contacts" &&
                        <ContactsGridComponent
                            source="site-record"
                            sourceId={siteId}
                            loadingHandler={ setIsLoadingTab }
                            errorHandler={ setErrorMessage }
                            successHandler={ setSuccessMessage }
                            gridName="sites/Contacts"
                        />
                    }
                    { isFinishedSetup && canAccessJobs && activeTab === "OpenJobs" &&
                        <>
                            {jobsView === 'kanban' &&
                                <>
                                    {openJobsViewSwitch}
                                    <JobsKanbanComponent source="site-record" sourceId={siteId} loadingHandler={setIsLoadingTab} />
                                </>
                            }
                            {jobsView === 'list' &&
                                <JobsGridComponent
                                    source="site-record"
                                    sourceId={siteId}
                                    jobState={1}
                                    loadingHandler={setIsLoadingTab}
                                    extraActions={openJobsViewSwitch}
                                    gridName="sites/OpenJobs"
                                />
                            }
                        </>
                    }
                    { isFinishedSetup && canAccessJobs && activeTab === "Jobs" &&
                        <JobsGridComponent
                            source="site-record"
                            sourceId={siteId}
                            jobState={0}
                            loadingHandler={setIsLoadingTab}
                            gridName="sites/Jobs"
                        />
                    }
                    { isFinishedSetup && canAccessPlacements && activeTab === "Contractors" &&
                        <PlacementsGridComponent
                            source="site-record"
                            sourceId={siteId}
                            loadingHandler={setIsLoadingTab}
                            errorHandler={setErrorMessage}
                            successHandler={setSuccessMessage}
                            gridName="sites/Placements"
                        />
                    }
                    { isFinishedSetup && activeTab === "Documents" &&
                        <DocumentsGridComponent
                            gridName="sites/Documents"
                            source="site-record"
                            sourceId={siteId}
                            loadingHandler={setIsLoadingTab}
                            errorHandler={setErrorMessage}
                            successHandler={setSuccessMessage}
                        />
                    }
                </PageContentLayout>
            </PageLayout>
        </>
    );
}