import React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import RemoveIcon from '@mui/icons-material/Remove';

import { ScreeningQuestionnaireQuestionAnswer } from "common/models/Configuration/AdvertScreeningQuestionnaire";
import RWTextFieldComponent from "components/RWTextFieldComponent";

interface Props {
    index: number,
    answer: ScreeningQuestionnaireQuestionAnswer,
    onChange: (index: number, answer: ScreeningQuestionnaireQuestionAnswer) => void,
    onDelete: (index: number) => void
}

export default function ScreeningQuestionnaireAnswerComponent({ index, answer, onChange, onDelete }: Props) {
    const stringChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        onChange(index, { ...answer, [name]: value });
    };

    const booleanChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        onChange(index, { ...answer, [name]: value === "1" });
    };

    return (
        <Box display="flex" justifyContent="center">
            <Stack direction="row" spacing={2} width="100%">
                <RWTextFieldComponent
                label="Text"
                name="Text"
                value={answer.Text}
                sxOptions={{ flexGrow: 1 }}
                onChange={stringChangeHandler}
                />
                <TextField
                    select
                    label="Knockout"
                    name="Knockout"
                    sx={{ minWidth: '100px' }}
                    value={answer.Knockout ? "1" : "0"}
                    onChange={booleanChangeHandler}
                >
                    <MenuItem value="1">Yes</MenuItem>
                    <MenuItem value="0">No</MenuItem>
                </TextField>
                <IconButton onClick={() => onDelete(index)}>
                    <RemoveIcon />
                </IconButton>
            </Stack>
        </Box>
    );
}