import MenuItem from "@mui/material/MenuItem";
import {
    GridColDef,
    GridColumnVisibilityModel,
    GridRenderCellParams,
    GridRowSelectionModel,
    GridValueGetter,
    useGridApiRef
} from "@mui/x-data-grid-premium";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Document } from "common/models/Document";
import { Permission } from "common/models/Permissions";
import { GetDocuments, DeleteDocuments, DownloadDocument } from "services/DocumentsService";
import { companyHasSinglePermission, userHasSinglePermission } from "util/PermissionsUtils";
import EditDocumentDialog from "../Dialogs/Documents/EditDocumentDialog";
import UploadDocumentsDialog from "../Dialogs/Documents/UploadDocumentsDialog";
import ConfirmationDialog from "../Dialogs/Generic/ConfirmationDialog";
import GridWithStateWrapper from "../GridWidthStateWrapper";
import ActionsDropDownButton from "../SummaryBars/Actions/ActionsDropsDownMenu";
import Box from "@mui/material/Box";
import { GetInterviewDocuments } from "services/InterviewsService";
import { InterviewDocument } from "common/models/Interviews";


interface Props {
    source: 'client-record' | 'contact-record' | 'candidate-record' | 'job-record' | 'placement-record' | 'change-request-record' | 'opportunity-record' | 'site-record' | 'submission-record' | 'meeting-record' | 'interview-record',
    sourceId?: number,
    hideActions?: boolean,
    hideCheckboxSelection?: boolean,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
    successHandler?: (message: string) => void,
    gridName: string
}

export default function DocumentsGridComponent({ source, sourceId, hideActions = false, hideCheckboxSelection = false, loadingHandler, errorHandler, successHandler, gridName }: Props) {
    const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
    const [rows, setRows] = useState<Document[]>([]);
    const [interviewRows, setInterviewRows] = useState<InterviewDocument[]>([]);
    const [showUploadDialog, setShowUploadDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [editRow, setEditRow] = useState<Document | null>(null);
    const apiRef = useGridApiRef();

    const canAddEditDocuments = useMemo(() => companyHasSinglePermission(Permission.Documents) && userHasSinglePermission(Permission.Documents), []);
    const canExportData = useMemo(() => companyHasSinglePermission(Permission.MiscExport) && userHasSinglePermission(Permission.MiscExport), []);
    const canDeleteDocs = useMemo(() => companyHasSinglePermission(Permission.DocumentsDelete) && userHasSinglePermission(Permission.DocumentsDelete), []);

    const entityTypeId = useMemo(() => {
        if (source === 'client-record') return 1;
        if (source === 'contact-record') return 2;
        if (source === 'candidate-record') return 3;
        if (source === 'job-record') return 4;
        if (source === 'placement-record') return 5;
        if (source === 'submission-record') return 6;
        if (source === 'opportunity-record') return 8;
        if (source === 'site-record') return 9;
        if (source === 'meeting-record') return 10;
        if (source === 'interview-record') return 11;
        if (source === 'change-request-record') return 17;
        return 0;
    }, [source]);
    
    const getData = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        let res: Document[] | null = [];
        if (source === 'interview-record' && sourceId) {
            const res2 = await GetInterviewDocuments(sourceId, errorHandler);
            if (res2) setInterviewRows(res2);
        }
        if (sourceId) res = await GetDocuments(entityTypeId, sourceId, errorHandler);
        if (res) setRows(res);
        loadingHandler && loadingHandler(false);
    }, [loadingHandler, source, sourceId, entityTypeId, errorHandler]);

    useEffect(() => {
        getData();
    }, [getData]);

    const downloadDocumentCallback = useCallback(async (documentId: number) => {
        loadingHandler && loadingHandler(true);
        await DownloadDocument(documentId, undefined, errorHandler);
        loadingHandler && loadingHandler(false);
    }, [loadingHandler, errorHandler]);

    const defaultHiddenCols: GridColumnVisibilityModel = {
        "id": false,
    };

    const editLinkCallBack = useCallback((id: number) => {
        if (id > 0) {
            const selectedRow = rows.find(r => r.id === id);
            if (selectedRow) setEditRow(selectedRow);
        }
    }, [rows]);

    const columns = useMemo<GridColDef[]>(() => {
        const dateValueGetter: GridValueGetter<Document, any, undefined, string> = (value) => {
            if (value) {
                const d = moment(value);
                if (d.isValid() && d.get('year') > 1) {
                    return d.toDate();
                }
            }
        };

        const dateRenderer = (params: GridRenderCellParams) => {
            if (params.value) {
                return moment(params.value).format('DD MMM YYYY');
            }
        };

        const createdByRenderer = (params: GridRenderCellParams) => {
            if (params.row.createdById === -2) return "Client Portal";
            return params.value;
        };

        const sizeRenderer = (params: GridRenderCellParams) => {
            const value = params.value;
            var SizeValue = 0;
            var UnitSize = "KB";
            if (value < 524288 && value > 0) {
                SizeValue = value / 1024;
                UnitSize = "KB"
            }
            else if (value > 0) {
                SizeValue = value / 1048576;
                UnitSize = "MB"
            }

            return `${SizeValue.toFixed(2)} ${UnitSize}`;
        };

        const downloadDocumentRenderer = (params: GridRenderCellParams) => {
            return (
                <span
                    style={{ cursor: 'pointer', textDecoration: 'underline' }}
                    onClick={ () => downloadDocumentCallback(params.id as number) }
                >{params.value}</span>
            );
        };

        const editDocumentRenderer = (params: GridRenderCellParams) => {
            return (
                <span
                    style={{ cursor: 'pointer', textDecoration: 'underline' }}
                    onClick={ () => editLinkCallBack(params.id as number) }
                >Edit</span>
            );
        };
        

        if (source !== 'interview-record') {
            
            const columns: GridColDef[] =[
                { headerName: 'ID', field: 'id', width: 120, renderCell: downloadDocumentRenderer },
                { headerName: 'Name', field: 'documentName', width: 600, renderCell: downloadDocumentRenderer },
                { headerName: 'Type', field: 'documentTypeName', headerAlign: 'center', align: 'center', width: 200 },
                { headerName: 'Date Uploaded', field: 'createdDate', headerAlign: 'center', align: 'center', width: 150, type: 'date', valueGetter: dateValueGetter, renderCell: dateRenderer },
                { headerName: 'Expiration Date', field: 'expirationDate', headerAlign: 'center', align: 'center', width: 150, type: 'date', valueGetter: dateValueGetter, renderCell: dateRenderer },
                { headerName: 'Uploaded By', field: 'createdByName', headerAlign: 'center', align: 'center', width: 200, renderCell: createdByRenderer },
                { headerName: 'Size', field: 'size', headerAlign: 'center', align: 'center', width: 75, renderCell: sizeRenderer },
            ];
            if(canAddEditDocuments) 
                columns.push( { headerName: 'Edit', field: 'edit', headerAlign: 'center', align: 'center', width: 75, renderCell: editDocumentRenderer });
            return  columns;
        }
        else {
            const entityValueGetter: GridValueGetter<Document, any, undefined, number> = (value) => {
                if (value) {
                    switch (value) {
                        case 1: return 'Client';
                        case 2: return 'Contact';
                        case 3: return 'Candidate';
                        case 4: return 'Job';
                        case 5: return 'Placement';
                        case 7: return 'User';
                        case 8: return 'Meeting';
                        case 10: return 'Interview';
                        case 11: return 'Opportunity';
                        default: return value;
                    }
                }
            };

            const columns: GridColDef[] =  [
                { headerName: 'ID', field: 'id', width: 120, renderCell: downloadDocumentRenderer },
                { headerName: 'Name', field: 'documentName', width: 600, renderCell: downloadDocumentRenderer },
                { headerName: 'Entity', field: 'entityID', width: 150, valueGetter: entityValueGetter },
                { headerName: 'Date Uploaded', field: 'createdDate', headerAlign: 'center', align: 'center', width: 150, type: 'date', valueGetter: dateValueGetter, renderCell: dateRenderer },
                { headerName: 'Size', field: 'size', headerAlign: 'center', align: 'center', width: 75, renderCell: sizeRenderer }
            ];

            if(canAddEditDocuments) 
                columns.push( { headerName: 'Edit', field: 'edit', headerAlign: 'center', align: 'center', width: 75, renderCell: editDocumentRenderer });

            return columns;
        }
        
    }, [canAddEditDocuments, downloadDocumentCallback, editLinkCallBack, source]);

    const successCallback = useCallback((message: string) => {
        successHandler && successHandler(message);
        getData();
    }, [successHandler, getData]);

    const deleteDocumentCallback = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        const res = await DeleteDocuments(selectionModel as number[], errorHandler);
        if (res) {
            setShowDeleteDialog(false);
            setSelectionModel([]);
            successCallback('Documents Deleted Successfully');
        }
        loadingHandler && loadingHandler(false);
    }, [selectionModel, loadingHandler, errorHandler, successCallback]);

    const editClickCallback = useCallback(() => {
        if (selectionModel.length === 1) {
            const selectedRow = rows.find(r => r.id === selectionModel[0]);
            if (selectedRow) setEditRow(selectedRow);
        }
    }, [selectionModel, rows]);

    const exportToExcel = useCallback(() => {
        const api = apiRef.current;
        if (api) api.exportDataAsExcel();
    }, [apiRef]);



    const gridActions = useMemo(() => {
        if (!hideActions && (canAddEditDocuments || canExportData || canDeleteDocs)) {
            return (
                <ActionsDropDownButton color="secondary" label="List Actions">
                    { canAddEditDocuments && <MenuItem onClick={ () => setShowUploadDialog(true) }>Add Documents</MenuItem> }
                    { canDeleteDocs && <MenuItem disabled={selectionModel.length === 0} onClick={ () => setShowDeleteDialog(true) } >Delete Documents</MenuItem> }
                    { canAddEditDocuments && <MenuItem disabled={selectionModel.length !== 1} onClick={ editClickCallback } >Edit</MenuItem> }
                    { canExportData && <MenuItem onClick={exportToExcel}>Export</MenuItem> }
                </ActionsDropDownButton>
            );
        }
        return undefined;
    }, [selectionModel.length, canAddEditDocuments, canExportData, canDeleteDocs, hideActions, editClickCallback, exportToExcel]);

    return (
        <>
            <UploadDocumentsDialog
                open={showUploadDialog}
                closeHandler={ () => setShowUploadDialog(false) }
                entityTypeId={entityTypeId}
                playerId={sourceId ?? 0}
                errorHandler={errorHandler}
                loadingHandler={loadingHandler}
                successHandler={successCallback}
            />
            <ConfirmationDialog
                message={`Are you sure you want to delete the ${selectionModel.length} selected document(s)?`}
                onClose={ () => setShowDeleteDialog(false) }
                onContinue={ deleteDocumentCallback }
                open={showDeleteDialog}
                title="Delete Documents"
                fullWidth
            />
            <EditDocumentDialog
                open={editRow !== null}
                closeHandler={ () => setEditRow(null) }
                document={ editRow }
                entityTypeId={entityTypeId}
                errorHandler={errorHandler}
                loadingHandler={loadingHandler}
                successHandler={successCallback}
            />
            {Boolean(gridActions) &&
                <Box pb="10px" ml="auto">
                    {gridActions}
                </Box>
            }
            <GridWithStateWrapper
                gridName={gridName}
                rows={ source !== 'interview-record' ? rows : interviewRows}
                columns={columns}
                apiRef={apiRef}
                density="compact"
                checkboxSelection={!hideCheckboxSelection}
                disableRowSelectionOnClick
                rowSelectionModel={selectionModel}
                defaultViewModel={defaultHiddenCols}
                onRowSelectionModelChange={ sm => setSelectionModel(sm) }
                pagination
                pageSizeOptions={[100,250,500,1000]}
            />
        </>
    );
}