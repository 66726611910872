import { Invoice } from "../common/models/Invoices/Invoice";
import { ActivityMessage } from "../common/models/Activities";
import { Client, ClientHierarchy, ClientOwner } from "../common/models/Clients";
import { Contact } from "../common/models/Contacts";
import { ErrorHandler, NumberValueWrapper, RequestOptions } from "../common/models/GenericTypes";
import { Job } from "../common/models/Jobs/Job";
import { Opportunity } from "../common/models/Opportunities";
import { Placement } from "../common/models/Placements";
import { Report } from "../common/models/Reports/Report";
import { ClientSearch } from "../common/models/Search/ClientSearch";
import { Site } from "../common/models/Site";
import {Submission} from "../common/models/Submissions/Submission";
import { Delete, Get, Post, PostFile, Put, PutFile } from "../util/HttpUtils";
import { ImportableClient } from "../common/models/Imports/ClientImport";
import { PartnerAction } from "common/models/PartnerActions";
import {Meeting} from "common/models/Meetings";
import {Interview} from "common/models/Interviews";

export async function ClientLookup(term: string, limit: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return await Get<Client[]>(`api/clients/Lookup?query=${encodeURIComponent(term)}&limit=${limit}`, options);
}

export async function GetMyClients(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Client[]>(`api/clients/Me`, options);
}

export async function GetClientById(clientId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Client>(`api/clients/${clientId}`, options);
}

export async function DeleteClientById(clientId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Delete<boolean>(`api/clients/${clientId}`, options);
}

export async function GetClientOwners(clientId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<ClientOwner[]>(`api/clients/${clientId}/Owners`, options);
}

export async function SetClientOwners(clientId: number, ownerIds: number[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/clients/${clientId}/Owners`, options, ownerIds);
}

export async function GetClientHierarchy(clientId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<ClientHierarchy[]>(`api/clients/${clientId}/Hierarchy`, options);
}

export async function GetClientSites(clientId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Site[]>(`api/clients/${clientId}/Sites`, options);
}

export async function GetClientPlacements(clientId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Placement[]>(`api/clients/${clientId}/Placements`, options);
}

export async function GetClientMessages(clientId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<ActivityMessage[]>(`api/clients/${clientId}/Messages`, options);
}

export async function GetClientJobs(clientId: number, stateId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Job[]>(`api/clients/${clientId}/Jobs?stateId=${stateId}`, options);
}

export async function GetClientSubmissions(clientId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Submission[]>(`api/clients/${clientId}/Submissions`, options);
}

export async function GetClientOpportunities(clientId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Opportunity[]>(`api/clients/${clientId}/Opportunities`, options);
}

export async function GetClientContacts(clientId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Contact[]>(`api/clients/${clientId}/Contacts`, options);
}

export async function GetClientMeetings(clientId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Meeting[]>(`api/clients/${clientId}/Meetings`, options);
}

export async function GetClientInterviews(clientId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Interview[]>(`api/clients/${clientId}/Interviews`, options);
}

export async function MergeClients(masterId: number, clientIds: number[], errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>(`api/clients/${masterId}/Merge`, options, clientIds);
}

export async function GetClientsByTag(tagTypeId: number, taggedBy: number, tag: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Client[]>(`api/clients/Tags/${tagTypeId}?taggedBy=${taggedBy}&tag=${encodeURIComponent(tag)}`, options);
}

export async function CreateClient(clientData: Partial<Client>, photoFile: File | null, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    const formData = new FormData();
    formData.append('client', JSON.stringify(clientData))
    if (photoFile) formData.append('photo', photoFile);

    return PostFile<NumberValueWrapper>('api/clients', formData, options);
}

export async function UpdateClient(clientData: Partial<Client>, photoFile: File | null, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    const formData = new FormData();
    formData.append('client', JSON.stringify(clientData))
    if (photoFile) formData.append('photo', photoFile);

    return PutFile<boolean>('api/clients', formData, options);
}

export async function UpdateClientCustomField(clientId: number, fieldNumber: number, value: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/clients/${clientId}/custom-fields`, options, { fieldNumber, value });
}

export async function GetReports(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<Report[]>('api/clients/reports', options);
}

export async function GetClientsNotContactedReportData(owner: number, status: number, rating: number, timeRange: number,  errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<any>(`api/clients/reports/not-contacted?owner=${owner}&status=${status}&rating=${rating}&timeRange=${timeRange}`, options);
}

export async function GetClientsDetailedReportData(owner: number, status: number, rating: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<any>(`api/clients/reports/detailed?owner=${owner}&status=${status}&rating=${rating}`, options);
}

export async function SearchClients(data: ClientSearch, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<Client[]>('api/clients/search', options, data);
}

export async function SaveClientsSearch(searchName: string, searchFrequency: number, data: ClientSearch, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<NumberValueWrapper>(`api/clients/search/${searchFrequency}?name=${encodeURIComponent(searchName)}`, options, data);
}

export async function UpdateClientSavedSearch(ssid: number, searchName: string, searchFrequency: number, data: ClientSearch, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Put<boolean>(`api/clients/search/${ssid}?freqId=${searchFrequency}&name=${encodeURIComponent(searchName)}`, options, data);
}

export async function ImportClient(data: Partial<ImportableClient>, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<NumberValueWrapper>('api/clients/import', options, data);
}

export async function GetClientPartnerActions(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<PartnerAction[]>('api/clients/partneractions', options);
}

export async function GetClientInvoices(clientId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return await Get<Invoice[]>(`api/clients/${clientId}/invoices`, options);
}