import { PanelModel } from "@syncfusion/ej2-react-layouts";
import { ClientRecordDashboardElementDefinition, ClientRecordDashboardElementType } from "common/models/Dashboard/EditLayout";
import { ScreenLayoutSettings } from "common/models/ScreenLayouts/ScreenLayouts";
import { GetCustomFieldTypeById } from "../ScreenLayouts/CustomFields";

export const DefaultClientQuickViewLayoutElements: ClientRecordDashboardElementDefinition[] = [
    { id: "ClientId_Default", row: 0, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'ClientId' },
    { id: "ClientName_Default", row: 1, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'ClientName' },
    { id: "ClientPhone_Default", row: 2, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'ClientPhone' },
    { id: "ClientWebsite_Default", row: 3, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'ClientWebsite' },
    { id: "ClientParent_Default", row: 4, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'ClientParent' },
    { id: "ClientPrimaryContact_Default", row: 5, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'ClientPrimaryContact' },
    { id: "ClientStatus_Default", row: 6, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'ClientStatus' },
    { id: "ClientRating_Default", row: 7, col: 0, sizeX: 2, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'ClientRating' },
    
    { id: "ClientLocation_Default", row: 0, col: 2, sizeX: 2, sizeY: 9, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'ClientLocation' },
    
    { id: "Spacer_Default", row: 9, col: 0, sizeX: 4, sizeY: 1, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'Spacer' },
    { id: "ClientSummaryFullWidth_Default", row: 10, col: 0, sizeX: 4, sizeY: 6, minSizeX: 1, minSizeY: 1, maxSizeX: 4, maxSizeY: null, type: 'ClientSummaryFullWidth' },
  ];

export const GetMinSizeByElementType = (type: ClientRecordDashboardElementType) => {
    switch (type) {
        case "ClientInfo": return [1, 3];
        case "ClientSummary": return [1, 9];
        case "ClientSummaryFullWidth": return [1, 7];
        case "ClientLocation": return [1, 9];
        case "ClientSubmissionDeadlines": return [1, 4];
        case "ClientCredit": return [1, 4];
        default: return [1, 1];
    }
};

export const GetDefaultSizeByElementType = (type: ClientRecordDashboardElementType) => {
    switch (type) {
        case "ClientSummary": return [2, 7];
        case "ClientSummaryFullWidth": return [4, 6];
        default: return [2, 1];
    }
};

export const GetElementTypeById = (id: string): ClientRecordDashboardElementType => {
    if ( id && id.startsWith('Divider_')) return 'Divider';
    if ( id && id.startsWith('Spacer_')) return 'Spacer';
    if ( id && id.startsWith('ClientInfo_')) return 'ClientInfo';
    if ( id && id.startsWith('ClientSummary_')) return 'ClientSummary';
    if ( id && id.startsWith('ClientSummaryFullWidth_')) return 'ClientSummaryFullWidth';
    if ( id && id.startsWith('ClientLocation_')) return 'ClientLocation';
    if ( id && id.startsWith('ClientId_')) return 'ClientId';
    if ( id && id.startsWith('ClientName_')) return 'ClientName';
    if ( id && id.startsWith('ClientTradingName_')) return 'ClientTradingName';
    if ( id && id.startsWith('ClientPhone_')) return 'ClientPhone';
    if ( id && id.startsWith('ClientWebsite_')) return 'ClientWebsite';
    if ( id && id.startsWith('ClientParent_')) return 'ClientParent';
    if ( id && id.startsWith('ClientPrimaryContact_')) return 'ClientPrimaryContact';
    if ( id && id.startsWith('ClientBillingContact_')) return 'ClientBillingContact';
    if ( id && id.startsWith('ClientDefaultApprover_')) return 'ClientDefaultApprover';
    if ( id && id.startsWith('ClientOwners_')) return 'ClientOwners';
    if ( id && id.startsWith('ClientStatus_')) return 'ClientStatus';
    if ( id && id.startsWith('ClientRating_')) return 'ClientRating';
    if ( id && id.startsWith('ClientSubmissionDeadlines_')) return 'ClientSubmissionDeadlines';
    if ( id && id.startsWith('ClientCredit_')) return 'ClientCredit';
    if ( id && id.startsWith('ClientDivisions_')) return 'ClientDivisions';
    if ( id && id.startsWith('ClientCode1_')) return 'ClientCode1';
    if ( id && id.startsWith('ClientCode2_')) return 'ClientCode2';
    if ( id && id.startsWith('ClientLinkedIn_')) return 'ClientLinkedIn';
    if ( id && id.startsWith('ClientTwitter_')) return 'ClientTwitter';
    if ( id && id.startsWith('CustomField')) return GetCustomFieldTypeById(id);
    return '';
};

export const GetPanelDefinitionsFromPanelModels = (panels: PanelModel[]) => {
    let elements: ClientRecordDashboardElementDefinition[] = [];
    panels.forEach((p, i) => {
        let type = GetElementTypeById(p.id ?? '');
        elements.push({
            id: p.id ?? i.toString(),
            sizeX: p.sizeX ?? 1,
            sizeY: p.sizeY ?? 1,
            col: p.col ?? 0,
            row: p.row ?? 0,
            minSizeX: p.minSizeX ?? 1,
            minSizeY: p.minSizeY ?? 1,
            maxSizeX: p.maxSizeX ?? 1,
            maxSizeY: p.maxSizeY ?? null,
            type: type
        });
    });
    return elements ;
};

export const ClientQuickViewLayoutSettings: ScreenLayoutSettings = {
    unitWidth: 228,
    unitHeight: 40,
    gapX: 10,
    gapY: 0,
    columns: 4
};