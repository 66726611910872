import Alert from "components/Alert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MenuItem from "@mui/material/MenuItem";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { GridColDef, GridRenderCellParams, useGridApiRef } from "@mui/x-data-grid-premium";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { ChangeTracker } from "common/models/hooks/ChangeTracker";
import { SavedSearch, SavedSearchSettings } from "common/models/SavedSearches";
import ConfirmationDialog from "components/Dialogs/Generic/ConfirmationDialog";
import GridWithStateWrapper from "components/GridWidthStateWrapper";
import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import useObjectStateWithChangeTracker from "hooks/UseObjectStateWithChangeTracker";
import PageContentLayout from "layouts/PageContentLayout";
import PageLayout from "layouts/PageLayout";
import { DeleteSavedSearch, UpdateSavedSearch } from "services/SavedSearchesService";
import { GetMySavedSearches, GetMySavedSearchSettings, UpdateMySavedSearchSettings } from "services/UsersService";
import RWTextFieldComponent from "components/RWTextFieldComponent";

const routerLinkStyle: React.CSSProperties = { color: 'inherit', textDecoration: 'underline' };

const defaultSettings: SavedSearchSettings = {
    userID: 0,
    time: 10,
    dailySummary: false
};

const noChanges: ChangeTracker<SavedSearchSettings> = {
    userID: false,
    time: false,
    dailySummary: false
};

export default function SavedSearchesPage() {
    const [summaryBar, setSummaryBar] = useState<JSX.Element>(<></>);
    const [isLoading, setIsLoading] = useState(false);
    const [isFetchingSettings, setIsFetchingSettings] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const { state, init, change, updateInitial, hasChanges } = useObjectStateWithChangeTracker<SavedSearchSettings>(defaultSettings, noChanges);
    const [rows, setRows] = useState<SavedSearch[]>([]);
    const apiRef = useGridApiRef();
    const [selectedRow, setSelectedRow] = useState<SavedSearch | null>(null);
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    const saveSettingsHandler = useCallback(async () => {
        setIsLoading(true);
        const res = await UpdateMySavedSearchSettings(state, setErrorMessage);
        if (res) {
            setShowSuccess(true);
            updateInitial();
        }
        setIsLoading(false);
    }, [state, updateInitial]);

    useEffect(() => {
        const action = <Button variant="contained" color="success" disabled={!hasChanges} onClick={saveSettingsHandler}>Save</Button>;
        const sb = (
            <TitleAndActionSummaryBar
                title="Account > Saved Searches"
                browserTabTitle="Saved Searches > Account"
                action={action}
            />
        );
        setSummaryBar(sb);
    }, [hasChanges, saveSettingsHandler]);

    useEffect(() => {
        const getSettings = async () => {
            setIsFetchingSettings(true);
            const res = await GetMySavedSearchSettings();
            if (res) init(res);
            setIsFetchingSettings(false);
        };
        getSettings();
    }, [init]);

    useEffect(() => {
        const getRows = async () => {
            setIsLoading(true);
            const res = await GetMySavedSearches();
            if (res) setRows(res);
            setIsLoading(false);
        };
        getRows();
    }, []);

    const columns = useMemo<GridColDef[]>(() => {
        const nameColumnRenderer = (params: GridRenderCellParams) => {
            const typeId = params.row.typeID as number;
            const jobId = params.row.jobID as number;
            const ssid = params.row.id as number;
            if (typeId === 15 ) return <Link style={ routerLinkStyle } to={`/adverts?ssid=${ssid}`}>{params.value}</Link>;
            if (typeId === 5 ) return <Link style={ routerLinkStyle } to={`/placements?ssid=${ssid}`}>{params.value}</Link>;
            if (typeId === 4 ) return <Link style={ routerLinkStyle } to={`/jobs?ssid=${ssid}`}>{params.value}</Link>;
            if (typeId === 3 && jobId === 0 ) return <Link style={ routerLinkStyle } to={`/candidates?ssid=${ssid}`}>{params.value}</Link>;
            if (typeId === 3 && jobId !== 0 ) return <Link style={ routerLinkStyle } to={`/candidates?ssid=${ssid}&jobId=${jobId}`}>{params.value}</Link>;
            if (typeId === 2 ) return <Link style={ routerLinkStyle } to={`/contacts?ssid=${ssid}`}>{params.value}</Link>;
            if (typeId === 1 ) return <Link style={ routerLinkStyle } to={`/clients?ssid=${ssid}`}>{params.value}</Link>;
            return params.value;
        };

        const jobColumnRenderer = (params: GridRenderCellParams) => {
            if (params.row.jobID === 0) {
                return <></>;
            }

            return <Link style={routerLinkStyle} to={`/jobs/${params.row.jobID}`}>{params.value}</Link>
        };

        const editClickHanlder = (row: SavedSearch) => {
            setShowEditDialog(true);
            setSelectedRow(row);
        };

        const editColRenderer = (params: GridRenderCellParams) => {
            return <span style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => editClickHanlder(params.row)}>Edit</span>;
        };

        const deleteClickHanlder = (row: SavedSearch) => {
            setShowDeleteDialog(true);
            setSelectedRow(row);
        };

        const deleteColRenderer = (params: GridRenderCellParams) => {
            return <span style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => deleteClickHanlder(params.row)}>Delete</span>;
        };

        return [
            { field: 'id', headerName: 'ID' },
            { field: 'name', headerName: 'Name', width: 300, renderCell: nameColumnRenderer },
            { field: 'type', headerName: 'Type' },
            { field: 'jobReference', headerName: 'Job', renderCell: jobColumnRenderer },
            { field: 'autoRunFrequencyName', headerName: 'Auto Run Frequency', width: 200 },
            { field: 'edit', headerName: 'Edit', disableColumnMenu: true, sortable: false, renderCell: editColRenderer },
            { field: 'delete', headerName: 'Delete', disableColumnMenu: true, sortable: false, renderCell: deleteColRenderer },
        ];
    }, []);

    const closeDialogHandler = useCallback(() => {
        setSelectedRow(null);
        setShowDeleteDialog(false);
        setShowEditDialog(false);
    }, []);

    const nameChangeHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setSelectedRow(prev => prev ? ({...prev, name: value}) : null );
    }, []);

    const autoRunFreqChangeHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        let valName = '';
        switch (value) {
            case "0": valName = "Manual";
                break;
            case "1": valName = "Hourly";
                break;
            case "2": valName = "Daily";
                break;
            case "3": valName = "Weekly";
                break;
            case "4": valName = "Monthly";
                break;
        }
        setSelectedRow(prev => prev ? ({ ...prev, autoRunFrequency: +value, autoRunFrequencyName: valName }) : null );
    }, []);

    const saveChangesCallback = useCallback(async () => {
        if (selectedRow) {
            setIsLoading(true);
            const res = await UpdateSavedSearch(selectedRow.id, selectedRow, setErrorMessage);
            if (res) {
                apiRef.current.updateRows([selectedRow]);
                setShowSuccess(true);
                setSelectedRow(null);
                setShowEditDialog(false);
            }
            setIsLoading(false);
        }
    }, [selectedRow, apiRef]);

    const deleteSavedSearchCallback = useCallback(async () => {
        if (selectedRow) {
            setIsLoading(true);
            const res = await DeleteSavedSearch(selectedRow.id, setErrorMessage);
            if(res) {
                setShowSuccess(true);
                setSelectedRow(null);
                setRows(prev => {
                    let newRows = [...prev];
                    const index = newRows.findIndex(r => r.id === selectedRow.id);
                    if(index !== -1) newRows.splice(index, 1);
                    return newRows;
                });
                setShowDeleteDialog(false);
            }
            setIsLoading(false);
        }
    }, [selectedRow]);

    return (
        <PageLayout SummaryBar={summaryBar}>
            {selectedRow && showEditDialog &&
                <Dialog open={true} fullWidth>
                    <DialogTitle>Edit Saved Search</DialogTitle>
                    <DialogContent dividers>
                        <Stack spacing={2}>
                            <RWTextFieldComponent
                                label="Name"
                                value={selectedRow.name}
                                onChange={nameChangeHandler}
                                disabled={selectedRow.jobID !== 0}
                            />
                            <TextField
                                select
                                label="Auto Run Frequency"
                                value={selectedRow.autoRunFrequency.toString()}
                                onChange={autoRunFreqChangeHandler}
                                disabled={selectedRow.typeID !== 3}
                            >
                                <MenuItem value="0">Manual</MenuItem>
                                <MenuItem value="1">Hourly</MenuItem>
                                <MenuItem value="2">Daily</MenuItem>
                                <MenuItem value="3">Weekly</MenuItem>
                                <MenuItem value="4">Monthly</MenuItem>
                            </TextField>
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="error" onClick={closeDialogHandler}>Cancel</Button>
                        <Button variant="contained" color="success" onClick={saveChangesCallback}>Save</Button>
                    </DialogActions>
                </Dialog>
            }
            {selectedRow && showDeleteDialog &&
                <ConfirmationDialog
                    open={true}
                    message="Are you sure you want to delete the selected Saved Search?"
                    title="Confirm Action"
                    onClose={closeDialogHandler}
                    onContinue={deleteSavedSearchCallback}
                    cancelActionText="No"
                    confirmActionText="Yes"
                />
            }
            <Snackbar open={showSuccess} autoHideDuration={3000} onClose={() => setShowSuccess(false)}>
                <Alert onClose={() => setShowSuccess(false)}>Changes Saved</Alert>
            </Snackbar>
            <Snackbar open={errorMessage !== ''} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <PageContentLayout showLoading={isLoading || isFetchingSettings}>
                <div style={{ maxWidth: '600px', width: '100%', margin: '0 auto', paddingBottom: '20px' }}>
                    <div style={{ width: '50%', paddingRight: '5px', display: 'inline-block' }}>
                        <TextField
                            select
                            value={state.dailySummary ? '1' : '0'}
                            label="Daily Summary Email"
                            fullWidth
                            onChange={({target}) => change('dailySummary', target.value === '1')}
                        >
                            <MenuItem value="1">Enabled</MenuItem>
                            <MenuItem value="0">Disabled</MenuItem>
                        </TextField>
                    </div>
                    <div style={{ width: '50%', paddingLeft: '5px', display: 'inline-block' }}>
                        <TextField
                            select
                            value={state.time.toString()}
                            label="Email Time"
                            fullWidth
                            onChange={({target}) => change('time', +target.value)}
                        >
                            <MenuItem value="0">12:00 am</MenuItem>
                            <MenuItem value="1">1:00 am</MenuItem>
                            <MenuItem value="2">2:00 am</MenuItem>
                            <MenuItem value="3">3:00 am</MenuItem>
                            <MenuItem value="4">4:00 am</MenuItem>
                            <MenuItem value="5">5:00 am</MenuItem>
                            <MenuItem value="6">6:00 am</MenuItem>
                            <MenuItem value="7">7:00 am</MenuItem>
                            <MenuItem value="8">8:00 am</MenuItem>
                            <MenuItem value="9">9:00 am</MenuItem>
                            <MenuItem value="10">10:00 am</MenuItem>
                            <MenuItem value="11">11:00 am</MenuItem>
                            <MenuItem value="12">12:00 pm</MenuItem>
                            <MenuItem value="13">1:00 pm</MenuItem>
                            <MenuItem value="14">2:00 pm</MenuItem>
                            <MenuItem value="15">3:00 pm</MenuItem>
                            <MenuItem value="16">4:00 pm</MenuItem>
                            <MenuItem value="17">5:00 pm</MenuItem>
                            <MenuItem value="18">6:00 pm</MenuItem>
                            <MenuItem value="19">7:00 pm</MenuItem>
                            <MenuItem value="20">8:00 pm</MenuItem>
                            <MenuItem value="21">9:00 pm</MenuItem>
                            <MenuItem value="22">10:00 pm</MenuItem>
                            <MenuItem value="23">11:00 pm</MenuItem>
                        </TextField>
                    </div>
                </div>
                <GridWithStateWrapper
                    gridName="account/saved-searches_SavedSearches"
                    apiRef={apiRef}
                    rows={rows}
                    columns={columns}
                    disableRowSelectionOnClick
                    pagination
                    density="compact"
                    pageSizeOptions={[100,250,500,1000]}
                />
            </PageContentLayout>
        </PageLayout>
    );
}