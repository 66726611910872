import Box from "@mui/material/Box";
import React, { useMemo } from "react";
import JobBoardViewField from "./JobBoardViewField";

interface Props {
    jobBoardId: number,
    advertId: number,
    attributes?: Record<string, string>,
    advertRecordAttributes?: Record<string, string>,
}

const jobFunctionMap: Record<string, string> = {
    "acct": "Accounting / Auditing",
    "adm": "Administrative",
    "advr": "Advertising",
    "anls": "Analyst",
    "art": "Art / Creative",
    "bd": "Business Development",
    "cnsl": "Consulting",
    "cust": "Customer Service",
    "dsgn": "Design",
    "dist": "Distribution",
    "edu": "Education",
    "eng": "Engineering",
    "fin": "Finance",
    "genb": "General Business",
    "hcpr": "Health Care Provider",
    "hr": "Human Resources",
    "it": "Information Technology",
    "lgl": "Legal",
    "mgmt": "Management",
    "mnfc": "Manufacturing",
    "mrkt": "Marketing",
    "othr": "Other",
    "prdm": "Product Management",
    "prod": "Production",
    "prjm": "Project Management",
    "pr": "Public Relations",
    "prch": "Purchasing",
    "qa": "Quality Assurance",
    "rsch": "Research",
    "sale": "Sales",
    "sci": "Science",
    "stra": "Strategy / Planning",
    "supl": "Supply Chain",
    "trng": "Training",
    "wrt": "Writing / Editing",
};

const industryMap: Record<string, string> = {
    "47": "Accounting",
    "94": "Airlines/Aviation",
    "120": "Alternative Dispute Resolution",
    "125": "Alternative Medicine",
    "127": "Animation",
    "19": "Apparel & Fashion",
    "50": "Architecture & Planning",
    "111": "Arts and Crafts",
    "53": "Automotive",
    "52": "Aviation & Aerospace",
    "41": "Banking",
    "12": "Biotechnology",
    "36": "Broadcast Media",
    "49": "Building Materials",
    "138": "Business Supplies and Equipment",
    "129": "Capital Markets",
    "54": "Chemicals",
    "90": "Civic & Social Organization",
    "51": "Civil Engineering",
    "128": "Commercial Real Estate",
    "118": "Computer & Network Security",
    "109": "Computer Games",
    "3": "Computer Hardware",
    "5": "Computer Networking",
    "4": "Computer Software",
    "48": "Construction",
    "24": "Consumer Electronics",
    "25": "Consumer Goods",
    "91": "Consumer Services",
    "18": "Cosmetics",
    "65": "Dairy",
    "1": "Defense & Space",
    "99": "Design",
    "132": "E-Learning",
    "69": "Education Management",
    "112": "Electrical/Electronic Manufacturing",
    "28": "Entertainment",
    "86": "Environmental Services",
    "110": "Events Services",
    "76": "Executive Office",
    "122": "Facilities Services",
    "63": "Farming",
    "43": "Financial Services",
    "38": "Fine Art",
    "66": "Fishery",
    "34": "Food & Beverages",
    "23": "Food Production",
    "101": "Fund",
    "26": "Furniture",
    "29": "Gambling & Casinos",
    "145": "Glass, Ceramics & Concrete",
    "75": "Government Administration",
    "148": "Government Relations",
    "140": "Graphic Design",
    "124": "Health, Wellness and Fitness",
    "68": "Higher Education",
    "14": "Hospital & Health Care",
    "31": "Hospitality",
    "137": "Human Resources",
    "134": "Import and Export",
    "88": "Individual & Family Services",
    "147": "Industrial Automation",
    "84": "Information Services",
    "96": "Information Technology and Services",
    "42": "Insurance",
    "74": "International Affairs",
    "141": "International Trade and Development 6 Internet",
    "45": "Investment Banking",
    "46": "Investment Management",
    "73": "Judiciary",
    "77": "Law Enforcement 9 Law Practice",
    "10": "Legal Services",
    "72": "Legislative Office",
    "30": "Leisure, Travel & Tourism",
    "85": "Libraries",
    "116": "Logistics and Supply Chain",
    "143": "Luxury Goods & Jewelry",
    "55": "Machinery",
    "11": "Management Consulting",
    "95": "Maritime",
    "97": "Market Research",
    "80": "Marketing and Advertising",
    "135": "Mechanical or Industrial Engineering",
    "126": "Media Production",
    "17": "Medical Devices",
    "13": "Medical Practice",
    "139": "Mental Health Care",
    "71": "Military",
    "56": "Mining & Metals",
    "35": "Motion Pictures and Film",
    "37": "Museums and Institutions",
    "115": "Music",
    "114": "Nanotechnology",
    "81": "Newspapers",
    "100": "Non",
    "57": "Oil & Energy",
    "113": "Online Media",
    "123": "Outsourcing/Offshoring",
    "87": "Package/Freight Delivery",
    "146": "Packaging and Containers",
    "61": "Paper & Forest Products",
    "39": "Performing Arts",
    "15": "Pharmaceuticals",
    "131": "Philanthropy",
    "136": "Photography",
    "117": "Plastics",
    "107": "Political Organization",
    "67": "Primary/Secondary Education",
    "83": "Printing",
    "105": "Professional Training & Coaching",
    "102": "Program Development",
    "79": "Public Policy",
    "98": "Public Relations and Communications",
    "78": "Public Safety",
    "82": "Publishing",
    "62": "Railroad Manufacture",
    "64": "Ranching",
    "44": "Real Estate",
    "40": "Recreational Facilities and Services 89 Religious Institutions",
    "144": "Renewables & Environment",
    "70": "Research",
    "32": "Restaurants",
    "27": "Retail",
    "121": "Security and Investigations 7 Semiconductors",
    "58": "Shipbuilding",
    "20": "Sporting Goods",
    "33": "Sports",
    "104": "Staffing and Recruiting",
    "22": "Supermarkets 8 Telecommunications",
    "60": "Textiles",
    "130": "Think Tanks",
    "21": "Tobacco",
    "108": "Translation and Localization",
    "92": "Transportation/Trucking/Railroad",
    "59": "Utilities",
    "106": "Venture Capital & Private Equity",
    "16": "Veterinary",
    "93": "Warehousing",
    "133": "Wholesale",
    "142": "Wine and Spirits",
    "119": "Wireless",
    "103": "Writing and Editing",
};

const experienceLevelMap: Record<string, string> = {
    "3": "Associate",
    "5": "Director",
    "2": "Entry level",
    "6": "Executive",
    "1": "Internship",
    "4": "Mid-Senior level",
    "0": "Not Applicable",
};

export default function LinkedInJobWrappingView({ jobBoardId, advertId, attributes, advertRecordAttributes }: Props) {

    const atts = useMemo<Record<string, string>>(() => {
        if (attributes) return attributes;
        return {};
    }, [attributes]);

    const recordAtts = useMemo<Record<string, string>>(() => {
        if (advertRecordAttributes) return advertRecordAttributes;
        return {};
    }, [advertRecordAttributes]);

    const country = useMemo(() => recordAtts.country ?? '', [recordAtts.country]);
    const location = useMemo(() => recordAtts.location ?? '', [recordAtts.location]);
    const workType = useMemo(() => recordAtts.workType ?? '', [recordAtts.workType]);
    const displaySalary = useMemo(() => recordAtts.displaySalary ?? '', [recordAtts.displaySalary]);

    const jobFunction = useMemo(() => atts.JobFunction ?? '', [atts.JobFunction]);
    const industry = useMemo(() => atts.Industry ?? '', [atts.Industry]);
    const experienceLevel = useMemo(() => atts.ExperienceLevel ?? '', [atts.ExperienceLevel]);

    return (
        <Box display="flex" gap={2} pt={2}>
            <Box display="flex" flexDirection="column" gap={2} width="50%">
                <JobBoardViewField label="Job Function" value={jobFunctionMap[jobFunction] ?? ''} />
                <JobBoardViewField label="Industry" value={industryMap[industry] ?? ''} />
                <JobBoardViewField label="Country" value={country} />
                <JobBoardViewField label="Location" value={location} />
            </Box>
            <Box display="flex" flexDirection="column" gap={2} width="50%">
                <JobBoardViewField label="Work Type" value={workType} />
                <JobBoardViewField label="Experience Level" value={experienceLevelMap[experienceLevel] ?? ''} />
                <JobBoardViewField label="Display Salary" value={displaySalary} />
            </Box>
        </Box>
    );
}