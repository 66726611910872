import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { DataGridPremium, GridColDef, GridRenderCellParams, useGridApiRef, GridPreProcessEditCellProps } from "@mui/x-data-grid-premium";
import * as tinymce from 'tinymce';
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import { JobBoard, JobBoardUpdateData, UserQuotaUpdateData } from "common/models/Configuration/JobBoard";
import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import PageContentLayout from "layouts/PageContentLayout";
import { GetAdvertsJobBoards, UpdateAdvertsJobBoard, GetUserQuotas } from "services/AdvertsService";
import { Editor } from "@tinymce/tinymce-react";
import { UserAdvertQuotas } from "common/models/JobPosting/UserAdvertQuotas";
import { useTheme } from "@mui/system";
import { useNavigate } from "react-router-dom";
import GridWithStateWrapper from "components/GridWidthStateWrapper";
import UnsavedChangesDialog from "components/Dialogs/UnsavedChangesDialog";
import RWTextFieldComponent from "components/RWTextFieldComponent";

interface Props {
    setSummaryBar?: (summaryBar: JSX.Element) => void
}


export default function Activities({ setSummaryBar }: Props) {
    const navigate = useNavigate();
    useEffect(() => {
        const addJobBoardButton = <Button variant="contained" color="primary" onClick={() => navigate('add')}>Add Job Boards</Button>
        
        const SummaryBar = (
            <TitleAndActionSummaryBar
                title="Configuration > Adverts > Job Boards"
                browserTabTitle="Adverts > Configuration" 
                action={addJobBoardButton}
            />
        );
        
        setSummaryBar && setSummaryBar(SummaryBar); 
    }, [setSummaryBar, navigate]);
    
    const theme = useTheme();
    const isDarkTheme = theme.palette.mode === 'dark';
    const [isLoading, setIsLoading] = useState(false);
    const [rows, setRows] = useState<JobBoard[]>([]);
    const [quotasRows, setQuotasRows] = useState<UserAdvertQuotas[]>([]);
    const [initialDialogData, setInitialDialogData] = useState<JobBoard | null>(null);
    const [showUnsavedChangesDialog, setShowUnsavedChangesDialog] = useState(false);
    const [dialogData, setDialogData] = useState<JobBoard | null>(null);
    const editorRef = useRef<tinymce.Editor | null>(null);
    const [isEditorDirty, setIsEditorDirty] = useState(false);
    const [changedQuotas, setChangedQuotas] = useState<UserQuotaUpdateData[]>([])
    const apiRef = useGridApiRef();

    useEffect(() => {
        const getData = async () => {
            setIsLoading(true);

            const data = await GetAdvertsJobBoards();
            if(data)
                setRows(data);

            setIsLoading(false);
        };
        getData();
    }, []);

    const columns = useMemo<GridColDef[]>(() => {
        const dbNameRenderer = (params: GridRenderCellParams) => {
            return (
                <span
                    onClick={async () => {
                        setIsLoading(true);
                        const quotaData = await GetUserQuotas(params.row.id);
                        if (quotaData)
                            setQuotasRows(quotaData);
                        setDialogData(params.row);
                        setInitialDialogData(params.row);
                        setIsLoading(false);
                    }}
                    style={{ cursor: 'pointer', textDecoration: 'underline' }}
                >{params.value}</span>
            );
        };

        const getQuota = (quotaValue: number) => {
            if (quotaValue === 0)
                return 'Unlimited';
            return '' + quotaValue;
        }
    
        const getStatus = (quotaValue: number) => {
            if (quotaValue === 2)
                return 'Ready for Posting';
            return 'Setup Required !';
        }

        return [
            { field: 'id', headerName: 'ID' },
            { field: 'img', headerName: 'Image', width: 150,  editable: true, renderCell: (params) => <img alt={params.row.name} style={{ maxHeight: '60px' }} src={"https://cdn.recruitwizard.com/images/jobboards/" + params.row.id + ".png"} /> },
            { field: 'name', headerName: 'Name', width: 300, renderCell: dbNameRenderer },
            { field: 'adsQuota', headerName: 'Quota', width: 200, valueGetter: value => { return getQuota(value) } },
            { field: 'adsUsed', headerName: 'Ads Used', width: 200 },
            { field: 'statusID', headerName: 'Status', width: 200, valueGetter: value => { return getStatus(value) } },
        ];
    }, []);

    const quotaColumns = useMemo<GridColDef[]>(() => {
        const validator = (params: GridPreProcessEditCellProps) => {
            const num = Number(params.props.value);
            const hasError = num < 0 || num === Number.EPSILON;
            return { ...params.props, error: hasError };
        };

        return [
            { field: 'consultant', headerName: 'Consultant', width: 300 },
            { field: 'userAdsQuota', headerName: 'Ads Quota', type: 'number', editable: true, preProcessEditCellProps: validator },
            { field: 'userAdsUsed', headerName: 'Ads Used', type: 'number' },
        ];
    }, []);

    const onChangeString = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setDialogData(prev => ( prev ? { ...prev, [name]: value } : null ));
    };

    const onChangeNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        const numValue = +value;
        setDialogData(prev => ( prev ? { ...prev, [name]: numValue } : null ));
    };

    const onChangeBoolean = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setDialogData(prev => ( prev ? { ...prev, [name]: value === '1' } : null ));
    };

    // const quotaValueChanged = (params: GridCellEditStopParams) => {
    //     if (params.reason === GridCellEditStopReasons.escapeKeyDown) return;
    //     const userId = Number(params.id);
    //     const value = Number(params.value);
    //     const existingIndex = changedQuotas.findIndex( q => q.userId === userId );
    //     let quotas = [...changedQuotas];
    //     if(existingIndex !== -1) {
    //         quotas[existingIndex].userAdsQuota = value;
    //         setChangedQuotas(quotas);
    //         return;
    //     }
    //     quotas.push({ userId: userId, userAdsQuota: value });
    //     setChangedQuotas(quotas);
    // }

    const processRowUpdate = useCallback((newRow: UserAdvertQuotas, oldRow: UserAdvertQuotas) => {
        if (newRow.userAdsQuota === oldRow.userAdsQuota) return newRow;
        const userId = newRow.userID;
        const value = newRow.userAdsQuota;
        const existingIndex = changedQuotas.findIndex( q => q.userId === userId );
        let quotas = [...changedQuotas];
        if(existingIndex !== -1) {
            quotas[existingIndex].userAdsQuota = value;
            setChangedQuotas(quotas);
            return newRow;
        }
        quotas.push({ userId: userId, userAdsQuota: value });
        setChangedQuotas(quotas);
        return newRow;
    }, [changedQuotas]);

    const hasChanges = useMemo(() => {
        if (dialogData && initialDialogData) {
            if (isEditorDirty) return true;
            if (changedQuotas.length > 0) return true;
            if (dialogData.name !== initialDialogData.name) return true;
            if (dialogData.defaultBoard !== initialDialogData.defaultBoard) return true;
            if (dialogData.mandatoryBoard !== initialDialogData.mandatoryBoard) return true;
            if (dialogData.quotasEnabled !== initialDialogData.quotasEnabled) return true;
            if (dialogData.recurringQuota !== initialDialogData.recurringQuota) return true;
            if (dialogData.quotaVariance !== initialDialogData.quotaVariance) return true;
        }
        return false;
    }, [dialogData, initialDialogData, isEditorDirty, changedQuotas.length]);

    const closeEditDialogHandler = useCallback(() => {
        setDialogData(null);
        setInitialDialogData(null);
        setQuotasRows([]);
        setChangedQuotas([]);
        setShowUnsavedChangesDialog(false);
        setIsEditorDirty(false);
    }, []);

    const saveChanges = useCallback(async () => {
        if (dialogData) {
            let jobBoardData = dialogData;
            const editorApi = editorRef.current;
            if(editorApi) jobBoardData.footer = editorApi.getContent();
            
            const data: JobBoardUpdateData = {
                jobBoard: jobBoardData,
                userQuotaData: changedQuotas
            };
    
            setIsLoading(true);
            const res = await UpdateAdvertsJobBoard(dialogData.jobBoardID, data);
            if(res) {
                jobBoardData.adsQuota = dialogData.recurringQuota + dialogData.quotaVariance;
                apiRef.current.updateRows([jobBoardData]);
                closeEditDialogHandler();
            }
            setIsLoading(false);
        }
    }, [dialogData, apiRef, changedQuotas, closeEditDialogHandler]);

    const closeHandlerWithConfirmation = useMemo(() => hasChanges ? () => setShowUnsavedChangesDialog(true) : closeEditDialogHandler, [hasChanges, closeEditDialogHandler]);

    return (
        <>
            <UnsavedChangesDialog
                open={showUnsavedChangesDialog}
                discardChangesHandler={closeEditDialogHandler}
                cancelHandler={() => setShowUnsavedChangesDialog(false)}
                saveChangesHandler={saveChanges}
                message="If you continue any changes made will be lost. Are you sure you wish to continue?"
                hideBackdrop
            />
            {dialogData &&
                <Dialog open={true} maxWidth="md" fullWidth onClose={closeHandlerWithConfirmation}>
                    <DialogTitle>Edit Job Board</DialogTitle>
                    <DialogContent dividers>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4}>
                                <Stack spacing={3}>
                                    <RWTextFieldComponent
                                        label="Name"
                                        name="name"
                                        value={dialogData.name}
                                        onChange={onChangeString}
                                    />
                                    <TextField
                                        select
                                        label="Default Board?"
                                        value={dialogData.defaultBoard ? "1" : "0"}
                                        name="defaultBoard"
                                        onChange={onChangeBoolean}
                                    >
                                        <MenuItem value="1">Enabled</MenuItem>
                                        <MenuItem value="0">Disabled</MenuItem>
                                    </TextField>
                                    <TextField
                                        select
                                        label="Mandatory Board?"
                                        value={dialogData.mandatoryBoard ? "1" : "0"}
                                        name="mandatoryBoard"
                                        onChange={onChangeBoolean}
                                    >
                                        <MenuItem value="1">Enabled</MenuItem>
                                        <MenuItem value="0">Disabled</MenuItem>
                                    </TextField>
                                    <TextField
                                        select
                                        label="Quotas?"
                                        value={dialogData.quotasEnabled ? "1" : "0"}
                                        name="quotasEnabled"
                                        onChange={onChangeBoolean}
                                    >
                                        <MenuItem value="1">Enabled</MenuItem>
                                        <MenuItem value="0">Disabled</MenuItem>
                                    </TextField>
                                    <RWTextFieldComponent
                                        type="number"
                                        label="Quota - Recurring"
                                        name="recurringQuota"
                                        value={dialogData.recurringQuota.toString()}
                                        onChange={onChangeNumber}
                                    />
                                    <RWTextFieldComponent
                                        type="number"
                                        label="Quota - Variance"
                                        name="quotaVariance"
                                        value={dialogData.quotaVariance.toString()}
                                        onChange={onChangeNumber}
                                    />
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <div style={{ height: '100%', width: '100%', minHeight: '300px', paddingBottom: '3px' }}>
                                    <div style={{ display: 'flex', height: '100%' }}>
                                        <div style={{ flexGrow: 1 }}>
                                            <DataGridPremium
                                                hideFooter
                                                disableColumnReorder
                                                disableColumnMenu
                                                disableColumnResize
                                                rows={quotasRows}
                                                columns={quotaColumns}
                                                disableRowSelectionOnClick
                                                density="compact"
                                                getRowId={ (row) => row.userID}
                                                processRowUpdate={ processRowUpdate }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                Footer
                                <Editor
                                    tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce-5.10.2/tinymce.min.js'}
                                    initialValue={dialogData.footer}
                                    onDirty={ () => setIsEditorDirty(true) }
                                    onInit={ (evt, editor) => editorRef.current = editor  }
                                    init={{
                                        skin: isDarkTheme ? 'oxide-dark' : undefined,
                                        content_css: isDarkTheme ? 'dark' : undefined,
                                        plugins: 'powerpaste code link emoticons table print preview visualchars lists',
                                        branding: false,
                                        height: 215,
                                        menubar: false,
                                        toolbar1: 'forecolor backcolor undo redo bold italic alignleft aligncenter alignright alignjustify bullist numlist outdent indent',
                                        toolbar2: 'link fontsizeselect fontselect mybutton styleselect mergefields',
                                        font_formats: 'Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Calibri=calibri; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Segoe=segoe,segoe ui,dejavu sans,trebuchet ms,verdana,sans-serif; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva',
                                        setup: editor => {
                                            editor.ui.registry.addMenuButton('mergefields', {
                                                text: 'Placeholders',
                                                fetch: function (callback) {
                                                    callback([
                                                        { type: 'menuitem', text: '{Account.Website}', onAction: () => editor.insertContent('{Account.Website}') },
                                                        '|',
                                                        { type: 'menuitem', text: '{Advert.ID}', onAction: () => editor.insertContent('{Advert.ID}') },
                                                        { type: 'menuitem', text: '{Advert.JobID}', onAction: () => editor.insertContent('{Advert.JobID}') },
                                                        '|',
                                                        { type: 'menuitem', text: '{Consultant.Email}', onAction: () => editor.insertContent('{Consultant.Email}') },
                                                        { type: 'menuitem', text: '{Consultant.Landline}', onAction: () => editor.insertContent('{Consultant.Landline}') },
                                                        { type: 'menuitem', text: '{Consultant.Linkedin}', onAction: () => editor.insertContent('{Consultant.Linkedin}') },
                                                        { type: 'menuitem', text: '{Consultant.Mobile}', onAction: () => editor.insertContent('{Consultant.Mobile}') },
                                                        { type: 'menuitem', text: '{Consultant.Name}', onAction: () => editor.insertContent('{Consultant.Name}') },
                                                        { type: 'menuitem', text: '{Consultant.Twitter}', onAction: () => editor.insertContent('{Consultant.Twitter}') },
                                                        '|',
                                                        { type: 'menuitem', text: '{Consultant2.Email}', onAction: () => editor.insertContent('{Consultant2.Email}') },
                                                        { type: 'menuitem', text: '{Consultant2.Landline}', onAction: () => editor.insertContent('{Consultant2.Landline}') },
                                                        { type: 'menuitem', text: '{Consultant2.Linkedin}', onAction: () => editor.insertContent('{Consultant2.Linkedin}') },
                                                        { type: 'menuitem', text: '{Consultant2.Mobile}', onAction: () => editor.insertContent('{Consultant2.Mobile}') },
                                                        { type: 'menuitem', text: '{Consultant2.Name}', onAction: () => editor.insertContent('{Consultant2.Name}') },
                                                        { type: 'menuitem', text: '{Consultant2.Twitter}', onAction: () => editor.insertContent('{Consultant2.Twitter}') },
                                                        '|',
                                                        { type: 'menuitem', text: '{Job.Reference}', onAction: () => editor.insertContent('{Job.Reference}') }
                                                    ]);
                                                },
                                            });
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="error" onClick={closeHandlerWithConfirmation}>Cancel</Button>
                        <Button variant="contained" color="success" disabled={!hasChanges} onClick={saveChanges}>Save</Button>
                    </DialogActions>
                </Dialog>
            }
            <PageContentLayout title="Job Boards" showLoading={isLoading}>
                <GridWithStateWrapper
                    gridName="configuration/adverts/job-boards"
                    apiRef={apiRef}
                    rows={rows}
                    columns={columns}
                    disableRowSelectionOnClick
                    pagination={true}
                    density="comfortable"
                    pageSizeOptions={[100,250,500,1000]}
                />
            </PageContentLayout>
        </>
    );
}