import React, { useCallback, useEffect, useMemo, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import UserPicker from "components/Pickers/UserPicker";
import { SxProps } from "@mui/system/styleFunctionSx/styleFunctionSx";
import { Theme } from "@mui/material/styles";
import { UpdateJobSplits } from "services/JobsService";
import { DatePicker } from "@mui/x-date-pickers-pro";
import InvoiceItemPicker from "components/Pickers/InvoiceItemPicker";
import { Item } from "common/models/Invoices/Item"
import { CreateInvoice } from "common/models/Invoices/CreateInvoice"
import { CreateJobInvoice } from "services/JobsService"
import { CreatePlacementInvoice } from "services/PlacementsService"
import ActionsSplitButton from "components/SummaryBars/Actions/ActionsSplitButton";
import MenuItem from "@mui/material/MenuItem";

interface Props {
    open: boolean,
    clientId?: number,
    jobId?: number,
    placementId?: number,
    consultant1Id?: number,
    consultant1Percent?: number,
    consultant2Id?: number,
    consultant2Percent?: number,
    consultant3Id?: number,
    consultant3Percent?: number,
    consultant4Id?: number,
    consultant4Percent?: number,
    consultant5Id?: number,
    consultant5Percent?: number,
    closeHandler: () => void,
    loadingHandler?: (isLoading: boolean) => void,
    successHandler?: (message: string) => void,
    errorHandler?: (message: string) => void
}

const leftStyle: SxProps<Theme> = { flex: '1 1 0', pr: '5px' };
const rightStyle: SxProps<Theme> = { flex: '1 1 0', pl: '5px' };
const validNumberValueRegex = /^-?\d+(\.\d{1,2})?$/;
const isoDateFormat = "YYYY-MM-DD";

export default function CreateInvoiceDialog({ open, clientId, jobId, placementId,
    consultant1Id: consultant1IdProp = 0, consultant1Percent: consultant1PercentProp = 0,
    consultant2Id: consultant2IdProp = 0, consultant2Percent: consultant2PercentProp = 0,
    consultant3Id: consultant3IdProp = 0, consultant3Percent: consultant3PercentProp = 0,
    consultant4Id: consultant4IdProp = 0, consultant4Percent: consultant4PercentProp = 0,
    consultant5Id: consultant5IdProp = 0, consultant5Percent: consultant5PercentProp = 0,
    closeHandler, loadingHandler, successHandler, errorHandler }: Props) {

    const [invoiceId, setInvoiceId] = useState(0);
    const [itemId, setItemId] = useState(0);
    const [date, setDate] = useState('');
    const [dueInDays, setDueInDays] = useState('');
    const [clientReference, setClientReference] = useState('');
    const [description, setDescription] = useState('');
    const [qty, setQty] = useState('1');
    const [buyPrice, setBuyPrice] = useState('');
    const [sellPrice, setSellPrice] = useState('');

    const [consultant1Id, setConsultant1Id] = useState(0);
    const [consultant1Percent, setConsultant1Percent] = useState('');
    const [consultant2Id, setConsultant2Id] = useState(0);
    const [consultant2Percent, setConsultant2Percent] = useState('');
    const [consultant3Id, setConsultant3Id] = useState(0);
    const [consultant3Percent, setConsultant3Percent] = useState('');
    const [consultant4Id, setConsultant4Id] = useState(0);
    const [consultant4Percent, setConsultant4Percent] = useState('');
    const [consultant5Id, setConsultant5Id] = useState(0);
    const [consultant5Percent, setConsultant5Percent] = useState('');

    useEffect(() => {
        if (open) {
            setItemId(0);
            setClientReference('');
            setDueInDays('');
            setDescription('');
            setQty('1');
            setBuyPrice('');
            setSellPrice('');

            setConsultant1Id(consultant1IdProp);
            setConsultant1Percent(consultant1PercentProp.toString());
            setConsultant2Id(consultant2IdProp);
            setConsultant2Percent(consultant2PercentProp.toString());
            setConsultant3Id(consultant3IdProp);
            setConsultant3Percent(consultant3PercentProp.toString());
            setConsultant4Id(consultant4IdProp);
            setConsultant4Percent(consultant4PercentProp.toString());
            setConsultant5Id(consultant5IdProp);
            setConsultant5Percent(consultant5PercentProp.toString());
        }
    }, [open, consultant1IdProp, consultant1PercentProp, consultant2IdProp, consultant2PercentProp, consultant3IdProp, consultant3PercentProp, consultant4IdProp, consultant4PercentProp, consultant5IdProp, consultant5PercentProp]);

    const submitCallback = useCallback(async () => {
        loadingHandler && loadingHandler(true);
        let totalPercent = 0;
        const p1 = consultant1Id ? +consultant1Percent : 0;
        const p2 = consultant2Id ? +consultant2Percent : 0;
        const p3 = consultant3Id ? +consultant3Percent : 0;
        const p4 = consultant4Id ? +consultant4Percent : 0;
        const p5 = consultant5Id ? +consultant5Percent : 0;

        if (consultant1Id) totalPercent += p1;
        if (consultant2Id) totalPercent += p2;
        if (consultant3Id) totalPercent += p3;
        if (consultant4Id) totalPercent += p4;
        if (consultant5Id) totalPercent += p5;

        if (totalPercent !== 100) {
            loadingHandler && loadingHandler(false);
            errorHandler && errorHandler(`${consultant1Id} ${totalPercent} Splits must add up to 100%`);
            return;
        }

        let data: CreateInvoice = {
            typeId: 1,
            date: date,
            paymentTermsInDays: +dueInDays,
            clientReference: clientReference,
            itemID: itemId,
            description: description,
            quantity: +qty,
            buyPrice: +buyPrice,
            sellPrice: +sellPrice,
            consultantID1: consultant1Id,
            consultantID2: consultant2Id,
            consultantID3: consultant3Id,
            consultantID4: consultant4Id,
            consultantID5: consultant5Id,
            consultantPercentage1: p1,
            consultantPercentage2: p2,
            consultantPercentage3: p3,
            consultantPercentage4: p4,
            consultantPercentage5: p5,
        }
      
        if (jobId) {
            const res = await CreateJobInvoice(jobId, data, errorHandler);
            if (res) {
                successHandler && successHandler('Invoice Created');
                closeHandler();
            }
        }

        if (placementId) {
            const res = await CreatePlacementInvoice(placementId, data, errorHandler);
            if (res) {
                successHandler && successHandler('Invoice Created');
                closeHandler();
            }
        }       

        loadingHandler && loadingHandler(false);
    }, [clientId, jobId, placementId, date, itemId, dueInDays, description, qty, buyPrice, sellPrice, consultant1Id, consultant2Id, consultant3Id, consultant4Id, consultant5Id, consultant1Percent, consultant2Percent, consultant3Percent, consultant4Percent, consultant5Percent, loadingHandler, successHandler, errorHandler, closeHandler]);
    
    const hasChanges = useMemo(() => {
        return date !== '' && itemId != 0 && description != '' && +dueInDays != 0 && +qty != 0 && +sellPrice != 0;
    }, [date, itemId, dueInDays, description, qty, buyPrice, sellPrice]);

    const onDueInDaysChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;
        if ((val.endsWith('.') && !val.endsWith('..')) || val.match(validNumberValueRegex)) {
            setDueInDays(val);
        }
    }, []);

    const onPercent1Change = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;
        if ((val.endsWith('.') && !val.endsWith('..'))|| val.match(validNumberValueRegex)) {
            setConsultant1Percent(val);
        }
    }, []);

    const onPercent2Change = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;
        if ((val.endsWith('.') && !val.endsWith('..'))|| val.match(validNumberValueRegex)) {
            setConsultant2Percent(val);
        }
    }, []);

    const onPercent3Change = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;
        if ((val.endsWith('.') && !val.endsWith('..'))|| val.match(validNumberValueRegex)) {
            setConsultant3Percent(val);
        }
    }, []);

    const onPercent4Change = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;
        if ((val.endsWith('.') && !val.endsWith('..'))|| val.match(validNumberValueRegex)) {
            setConsultant4Percent(val);
        }
    }, []);

    const onPercent5Change = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;
        if ((val.endsWith('.') && !val.endsWith('..'))|| val.match(validNumberValueRegex)) {
            setConsultant5Percent(val);
        }
    }, []);

    const itemChosenHandler = useCallback((e: Item | null) => {
        if (e) {
            setItemId(e.id);
            setBuyPrice(e.buyPrice.toString());
            setSellPrice(e.sellPrice.toString());
            setDescription(e.description);
        }
    }, []);

    const qtyChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;
        if ((val.endsWith('.') && !val.endsWith('..')) || val.match(validNumberValueRegex)) {
            setQty(val);
        }
    }, []);

    const buyPriceChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;
        if ((val.endsWith('.') && !val.endsWith('..')) || val.match(validNumberValueRegex)) {
            setBuyPrice(val);
        }
    }, []);

    const sellPriceChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;
        if ((val.endsWith('.') && !val.endsWith('..')) || val.match(validNumberValueRegex)) {
            setSellPrice(val);
        }
    }, []);

    const onDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;
        if (val) {
            setDescription(val);
        }
    };

    const onClientReferenceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;
        if (val) {
            setClientReference(val);
        }
    };

    const handleDateChange = useCallback((m: moment.Moment | null) => {
        if (m && m.isValid()) setDate(m.format(isoDateFormat));
    }, []);

    return (
        <>
            <Dialog open={open} maxWidth="lg" fullWidth>
                <DialogTitle>Create Invoice</DialogTitle>
                <DialogContent>
                    <Stack mt="10px" spacing={2}>
                        <Box display="flex">
                            <DatePicker label="Invoice Date" sx={leftStyle} onChange={handleDateChange} />
                            <TextField label="Due in (Days)" sx={rightStyle} value={dueInDays} onChange={onDueInDaysChange} />
                        </Box>
                        <Box display="flex">
                            <TextField label="Client Reference" sx={{ flex: '1 0 0' }} value={clientReference} onChange={onClientReferenceChange} />
                        </Box>
                        <Box width="100%">
                            <InvoiceItemPicker itemId={itemId} onSelect={u => itemChosenHandler(u)} variant="outlined" />
                        </Box>
                        <Box display="flex">                            
                            <TextField label="Quantity" value={qty} onChange={qtyChange} disabled={itemId === 0} sx={leftStyle} />
                            <TextField label="Description" value={description} onChange={onDescriptionChange} disabled={itemId === 0} sx={leftStyle} />
                            <TextField label="Buy Price" value={buyPrice} onChange={buyPriceChange} disabled={itemId === 0} sx={leftStyle} />
                            <TextField label="Sell Price" value={sellPrice} onChange={sellPriceChange} disabled={itemId === 0} sx={rightStyle} />
                        </Box>
                        <Box display="flex">
                            <UserPicker label="Consultant 1" userId={consultant1Id} onSelect={u => setConsultant1Id(u ? u.id : 0)} sx={leftStyle} />
                            <TextField label="Consultant 1 %" value={consultant1Percent} onChange={onPercent1Change} disabled={consultant1Id === 0} sx={rightStyle} />
                        </Box>
                        <Box display="flex">
                            <UserPicker label="Consultant 2" userId={consultant2Id} onSelect={ u => setConsultant2Id(u ? u.id : 0)} sx={leftStyle} />
                            <TextField label="Consultant 2 %" value={consultant2Percent} onChange={onPercent2Change} disabled={consultant2Id === 0} sx={rightStyle} />
                        </Box>
                        <Box display="flex">
                            <UserPicker label="Consultant 3" userId={consultant3Id} onSelect={ u => setConsultant3Id(u ? u.id : 0)} sx={leftStyle} />
                            <TextField label="Consultant 3 %" value={consultant3Percent} onChange={onPercent3Change} disabled={consultant3Id === 0} sx={rightStyle} />
                        </Box>
                        <Box display="flex">
                            <UserPicker label="Consultant 4" userId={consultant4Id} onSelect={ u => setConsultant4Id(u ? u.id : 0)} sx={leftStyle} />
                            <TextField label="Consultant 4 %" value={consultant4Percent} onChange={onPercent4Change} disabled={consultant4Id === 0} sx={rightStyle} />
                        </Box>
                        <Box display="flex">
                            <UserPicker label="Consultant 5" userId={consultant5Id} onSelect={ u => setConsultant5Id(u ? u.id : 0)} sx={leftStyle} />
                            <TextField label="Consultant 5 %" value={consultant5Percent} onChange={onPercent5Change} disabled={consultant5Id === 0} sx={rightStyle} />
                        </Box>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button style={{ marginRight: '10px' }} variant="contained" color="error" onClick={closeHandler}>Cancel</Button>
                    <ActionsSplitButton mainAction={submitCallback} color="success" menuPlacement="top-end" label="Save Draft" mainActionDisabled={!hasChanges} secondaryActionDisabled={!hasChanges}>
                        {
                            [
                                <MenuItem key={1} onClick={() => console.log("sent for approval")}>Submit for Approval</MenuItem>,
                                <MenuItem key={2} onClick={() => console.log("sent to astute")}>Approve + Send</MenuItem>
                            ]
                        }
                    </ActionsSplitButton>
                </DialogActions>
            </Dialog>
        </>
    );
}