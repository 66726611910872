import Alert from "components/Alert";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import { useTheme } from "@mui/material/styles";
import { Editor } from "@tinymce/tinymce-react";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import useUnsavedChangesDialog from "hooks/UseUnsavedChangesDialog";
import PageContentLayout from "layouts/PageContentLayout";
import { GetMySignature, GetUserSignature, UpdateMySignature, UpdateUserSignature } from "services/UsersService";
import TitleAndActionSummaryBar from "../SummaryBars/TitleAndActionSummaryBar";

interface Props {
    userId?: number,
    summaryBarTitle: string | JSX.Element,
    summaryBarTabTitle: string,
    setSummaryBar?: (summaryBar: JSX.Element) => void
}

export default function UserSignaturePageContent({ userId = 0, summaryBarTitle, summaryBarTabTitle, setSummaryBar }: Props) {
    const [isLoading, setIsLoading] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [signature, setSignature] = useState('');
    const [isEditorDirty, setIsEditorDirty] = useState(false);
    const theme = useTheme();
    const isDarkTheme = theme.palette.mode === 'dark';
    const editorRef = useRef<any | null>(null);

    const saveHandler = useCallback(async () => {
        const content = editorRef.current.getContent();
        setIsLoading(true);
        if (userId === 0) {
            const res = await UpdateMySignature(content, setErrorMessage);
            if (!res) {
                setIsLoading(false);
                return false;
            }
        }
        else {
            const res = await UpdateUserSignature(userId, content, setErrorMessage);
            if (!res) {
                setIsLoading(false);
                return false;
            }
        }
        setIsLoading(false);
        editorRef.current.setDirty(false);
        setIsEditorDirty(false);
        setShowSuccess(true);
        return true;
    }, [userId]);

    const summaryBar = useMemo(() => {
        const action = <Button variant="contained" color="success" onClick={saveHandler} disabled={!isEditorDirty}>Save</Button>;
        return <TitleAndActionSummaryBar title={ summaryBarTitle } browserTabTitle={ summaryBarTabTitle } action={action} />;
    }, [isEditorDirty, saveHandler, summaryBarTitle, summaryBarTabTitle]);

    useEffect(() => {
        setSummaryBar && setSummaryBar(summaryBar);
    }, [setSummaryBar, summaryBar]);

    const { unsavedChangesDialog } = useUnsavedChangesDialog(isEditorDirty, saveHandler);

    useEffect(() => {
        const getData = async () => {
            setIsLoading(true);
            if (userId === 0) {
                const data = await GetMySignature(setErrorMessage);
                if (data) setSignature(data.value);
            }
            else {
                const data = await GetUserSignature(userId, setErrorMessage);
                if (data) setSignature(data.value);
            }
            setIsLoading(false);
        };
        getData();
    }, [userId]);

    return (
        <>
            {unsavedChangesDialog}
            <Snackbar open={showSuccess} autoHideDuration={3000} onClose={() => setShowSuccess(false)}>
                <Alert onClose={() => setShowSuccess(false)}>Changes Saved</Alert>
            </Snackbar>
            <Snackbar open={errorMessage !== ''} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <PageContentLayout title="Signature" showLoading={isLoading}>
                <Editor
                    tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce-5.10.2/tinymce.min.js'}
                    initialValue={signature}
                    onDirty={ () => setIsEditorDirty(true) }
                    onInit={ (evt, editor) => editorRef.current = editor }
                    init={{
                        height: '100%',
                        skin: isDarkTheme ? 'oxide-dark' : undefined,
                        content_css: isDarkTheme ? 'dark' : undefined,
                        branding: false,
                        menubar: false,
                        contextmenu: false,
                        plugins: 'powerpaste code link emoticons table print preview visualchars lists fullscreen',
                        browser_spellcheck: true,
                        toolbar1: 'forecolor backcolor | undo redo | bold italic alignleft aligncenter alignright alignjustify bullist numlist outdent indent table | code preview fullscreen',
                        toolbar2: 'link fontsizeselect fontselect mybutton styleselect',
                        font_formats: 'Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Calibri=calibri; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Segoe=segoe,segoe ui,dejavu sans,trebuchet ms,verdana,sans-serif; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva',
                    }}
                />
            </PageContentLayout>
        </>
    );
}