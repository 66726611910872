import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import CloseIcon from '@mui/icons-material/Close';
import React, { useCallback, useMemo } from "react";
import { ClientOwner } from "common/models/Clients";
import UserPicker from "components/Pickers/UserPicker";
import Box from "@mui/material/Box";
import { ContactOwner } from "common/models/Contacts";
import { OpportunityOwner } from "common/models/Opportunities";
import { NameIdObj } from "common/models/GenericTypes";

interface Props {
    open: boolean,
    owners: ClientOwner[] | ContactOwner[] | OpportunityOwner[],
    type: 'Client' | 'Contact' | 'Opportunity',
    editable?: boolean,
    addOwnerHandler?: (user: NameIdObj | null) => void,
    removeOwnerHandler?: (ownerId: number) => void,
    closeHandler: () => void
}

export default function OwnersListDialog({ open, owners, type, editable = false, closeHandler, addOwnerHandler, removeOwnerHandler }: Props) {
    const ignoreIds = useMemo<number[] | undefined>(() => {
        if (editable && owners.length > 0) {
            return owners.map(o => o.recruiterID);
        }
        return undefined;
    }, [editable, owners]);

    const selectedUserCallback = useCallback((u: NameIdObj | null) => {
        addOwnerHandler && addOwnerHandler(u);
    }, [addOwnerHandler]);

    return (
        <Dialog open={open} fullWidth>
            <DialogTitle>{type} Owners</DialogTitle>
            <DialogContent>
                { editable && <Box pt={1}><UserPicker blurOnSelect ignoreIds={ignoreIds} label="Owner" userId={0} onSelect={ selectedUserCallback } /></Box>}
                <List>
                    {owners.map(o => (
                        <ListItem key={o.recruiterID} secondaryAction={ editable ? <IconButton onClick={ () => removeOwnerHandler && removeOwnerHandler(o.recruiterID) }><CloseIcon /></IconButton> : undefined }>
                            <ListItemText primary={o.fullName} />
                        </ListItem>
                    ))}
                </List>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="success" onClick={closeHandler}>Ok</Button>
            </DialogActions>
        </Dialog>
    );
}