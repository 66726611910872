import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import PageContentLayout from "layouts/PageContentLayout";
import PageLayout from "layouts/PageLayout";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { GetActivityById } from "services/ActivitiesService";
import { GetCandidateById } from "services/CandidatesService";
import { GetContactById } from "services/ContactsService";
import { GetSms, ReplySms } from "services/MessagesService";

const multiLineTextFieldId = 'NewSMSMessageTextArea';

export default function SmsReplyPage() {
    const params = useParams();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [message, setMessage] = useState('');
    const [newMessage, setNewMessage] = useState('');
    const [entityId, setEntityId] = useState(0);
    const [playerId, setPlayerId] = useState(0);
    const [playerFullName, setPlayerFullName] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    
    const smsId = useMemo(() => {
        const parsedId = +(params.id ?? '0');
        if (isNaN(parsedId)) return 0;
        return parsedId;
    }, [params.id]);

    const activityId = useMemo(() => {
        const param = searchParams.get('ActivityID');
        if (param) {
            const parsedId = +param;
            if (isNaN(parsedId)) return 0;
            return parsedId;
        }
        return 0;
    }, [searchParams]);

    useEffect(() => {
        const getData = async () => {
            setIsLoading(true);
            const res = await GetSms(smsId, setErrorMessage);
            if (res) {
                if (res.entityID === 2){
                    const contact = await GetContactById(res.playerID, setErrorMessage);
                    if (contact) setPlayerFullName(contact.fullName);
                    
                }
                else if (res.entityID === 3){
                    const candidate = await GetCandidateById(res.playerID, setErrorMessage);
                    if (candidate) setPlayerFullName(candidate.fullName);
                }
                setEntityId(res.entityID);
                setPlayerId(res.playerID);
            }
            else if (res === null) {
                navigate('/not-found');
                return;
            }
            setIsLoading(false);
        };
        getData();
    }, [navigate, smsId]);

    useEffect(() => {
        const getData = async () => {
            const res = await GetActivityById(activityId, setErrorMessage);
            if (res) setMessage(res.notes);
        };
        getData();
    }, [activityId]);

    const isMessageEmpty = useMemo(() => {
        return newMessage.trim() === '';
    }, [newMessage]);

    const submitCallback = useCallback(async () => {
        setIsLoading(true);
        const res = await ReplySms(smsId, newMessage, setErrorMessage);
        setIsLoading(false);
        if (res) {
            let playerLink = '';
            if (entityId === 2) playerLink = `/contacts/${playerId}`;
            else if (entityId === 3) playerLink = `/candidates/${playerId}`;
            
            if (playerLink) navigate(playerLink);
        }
    }, [smsId, newMessage, entityId, playerId, navigate]);

    const summaryBar = useMemo(() => {
        let playerLink = '';
        let playerType = '';
        if (entityId === 2) {
            playerLink = `/contacts/${playerId}`;
            playerType = '( Contact )';
        }
        else if (entityId === 3) {
            playerLink = `/candidates/${playerId}`;
            playerType = '( Candidate )';
        }
        const title = <>Reply from&nbsp;<Link to={playerLink} style={{ color: 'inherit', textDecoration: 'underline' }}>{playerFullName}</Link>&nbsp;{playerType}</>;
        const action = <Button variant="contained" color="success" disabled={isMessageEmpty} onClick={submitCallback}>Submit</Button>;
        const sb = (
            <TitleAndActionSummaryBar title={title} browserTabTitle="Reply to SMS" action={ action } />
        );
        return sb;
    }, [isMessageEmpty, entityId, playerId, playerFullName, submitCallback]);

    useEffect(() => {
        const item = document.getElementById(multiLineTextFieldId);
        if (item) {
            item.style.alignSelf = 'start';
        }
    }, []);
    
    return (
        <>
            <Snackbar open={Boolean(errorMessage)} autoHideDuration={3000} onClose={() => setErrorMessage('')}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{errorMessage}</Alert>
            </Snackbar>
            <PageLayout SummaryBar={summaryBar}>
                <PageContentLayout showLoading={isLoading}>
                    <Stack spacing={2} height="100%">
                        <Box flex="1 1 0" display="flex" flexDirection="column">
                            <Typography fontWeight="bold">Their Message</Typography>
                            <Paper variant="outlined" sx={{ flexGrow: 1, p: '8.5px 14px' }}>
                                {message}
                            </Paper>
                        </Box>
                        <TextField
                            multiline
                            label="New Message"
                            value={newMessage}
                            onChange={({target}) => setNewMessage(target.value)}
                            sx={{ height: '100%', flex: '1 1 0' }}
                            id={multiLineTextFieldId}
                            InputProps={{ sx: { height: '100%' } }}
                        />
                    </Stack>
                </PageContentLayout>
            </PageLayout>
        </>
    );
}