import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { SeekBranding } from "common/models/JobPosting/Seek";
import React, { useMemo } from "react";

interface Props {
    isSelected: boolean,
    brandingData: SeekBranding,
}

export default function SeekBrandingCard({ isSelected, brandingData }: Props) {

    const coverImg = useMemo(() => {
        const img = brandingData.images.find(i => i.typeCode === 'CoverImage');
        if (img) return img.url;
        return '';
    }, [brandingData.images]);
    
    const logoImg = useMemo(() => {
        const img = brandingData.images.find(i => i.typeCode === 'OriginalLogo');
        if (img) return img.url;
        return '';
    }, [brandingData.images]);

    const cardBorder = useMemo(() => {
        if (isSelected) return '3px solid #6D25B6';
        return '1px solid #9FB0BF';
    }, [isSelected]);

    return (
        <Box width="284px" height="171px" border={cardBorder} display="flex" flexDirection="column" borderRadius="5px" sx={{ cursor: 'pointer', ':hover': { boxShadow: '0 0 5px black' } }}>
            <Box height="102px" sx={{ borderTopLeftRadius: '5px', borderTopRightRadius: '5px', pointerEvents: 'none' }}>
                <img src={coverImg} alt="Cover" width="100%" height="100%" />
            </Box>
            <Box display="flex" alignItems="center" flexGrow={1} sx={{ pointerEvents: 'none' }}>
                <img src={logoImg} alt="Logo" style={{ maxWidth: '61px', maxHeight: '51px', margin: '0 14px' }} />
                <Typography variant="caption" px="14px" borderLeft="1px solid #EBEBEB" fontWeight="bold">{brandingData.name}</Typography>
            </Box>
        </Box>
    );
}