export enum Permission {
    GlobalAdministrator = 1,
    AdvertsAdministrator = 76,
    DataMaintenanceAdministrator = 77,
    EmailIntegrationAdministrator = 78,
    GroupsAdministrator = 79,
    IntegrationsAdministrator = 80,
    JobsAdministrator = 81,
    PerformanceAdministrator = 83,
    PlacementsAdministrator = 84,
    SMSAdministrator = 85,
    SubmissionsAdministrator = 86,
    TemplatesAdministrator = 87,
    UserAdministrator = 88,
    LayoutAdministrator = 89,
    ComplianceAdministrator = 90,
    Automation = 91,
    AutomationAdministrator = 92,
    ClientsAdministrator = 93,

    Activities = 33,
    ActivitiesDelete = 64,
    Adverts = 34,
    AdvertsDelete = 35,
    AdvertsAddEdit = 36,
    AdvertsExport = 46,
    AdvertsReports = 47,
    BillingAdmin = 65,
    BackOfficeIntegration = 40,
    BulkTags = 37,
    Candidates = 18,
    CandidatesAddEdit = 8,
    CandidatesDelete = 19,
    CandidatesExport = 56,
    CandidatesReports = 57,
    Clients = 12,
    ClientsAddEdit = 13,
    ClientsDelete = 14,
    ClientsExport = 48,
    ClientsReports = 49,
    ClientPortalAdmin = 66,
    Contacts = 15,
    ContactsDelete = 16,
    ContactsAddEdit = 17,
    ContactsExport = 60,
    ContactsReports = 61,
    Dashboard = 11,
    Documents = 30,
    DocumentsDelete = 31,
    ElectronicSigning = 74,
    EmailIntegration = 29,
    EmailMarketing = 73,
    DocumentTemplates = 62,
    Jobs = 9,
    JobsAddEdit = 10,
    JobsDelete = 20,
    JobsExport = 50,
    JobsReports = 51,
    MessageCentre = 3,
    MessageCentreSms = 45,
    MiscExport = 58,
    MiscReports = 59,
    Opportunities = 41,
    OpportunitiesAddEdit = 42,
    OpportunitiesDelete = 43,
    OpportunitiesExport = 52,
    OpportunitiesReports = 53,
    Placements = 21,
    FinancialController = 39,
    PlacementsDelete = 22,
    PlacementsAddEdit = 23,
    PlacementsExport = 54,
    PlacementsReports = 55,
    Tags = 28,
    JobDeadlineChanges = 72,
    //VendorManagementSystem = 75,
    //JobUpdateOutcomes = 76,
    DigitalReferenceChecks = 94,
    Onboarding = 95
}