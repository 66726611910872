import Box from "@mui/material/Box";
import React, { useMemo } from "react";
import JobBoardViewField from "./JobBoardViewField";

interface Props {
    jobBoardId: number,
    advertId: number,
    attributes?: Record<string, string>,
}

export default function RecruitDotNetView({ jobBoardId, advertId, attributes }: Props) {

    const atts = useMemo<Record<string, string>>(() => {
        if (attributes) return attributes;
        return {};
    }, [attributes]);

    const country = useMemo(() => atts.country ?? '', [atts.country]);
    const region = useMemo(() => atts.region ?? '', [atts.region]);
    const location = useMemo(() => atts.location ?? '', [atts.location]);
    const workType = useMemo(() => atts.workType ?? '', [atts.workType]);
    const salaryType = useMemo(() => atts.salaryType ?? '', [atts.salaryType]);
    const salaryFrom = useMemo(() => atts.salaryFrom ?? '', [atts.salaryFrom]);
    const salaryTo = useMemo(() => atts.salaryTo ?? '', [atts.salaryTo]);

    return (
        <Box display="flex" gap={2} pt={2}>
            <Box display="flex" flexDirection="column" gap={2} width="50%">
                <JobBoardViewField label="Country" value={country} />
                <JobBoardViewField label="Region" value={region} />
                <JobBoardViewField label="Location" value={location} />
            </Box>
            <Box display="flex" flexDirection="column" gap={2} width="50%">
                <JobBoardViewField label="Work Type" value={workType} />
                <JobBoardViewField label="Salary Type" value={salaryType} />
                <Box display="flex" gap={2}>
                    <JobBoardViewField label="Salary From" sx={{ flex: '1 1 0' }} value={salaryFrom} />
                    <JobBoardViewField label="Salary To" sx={{ flex: '1 1 0' }} value={salaryTo} />
                </Box>
            </Box>
        </Box>
    );
}