import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Input from "@mui/material/Input";
import TextField from "@mui/material/TextField";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ChangeTracker } from "common/models/hooks/ChangeTracker";
import { BillingTarget } from "common/models/Placements";
import useObjectStateWithChangeTracker from "hooks/UseObjectStateWithChangeTracker";
import { GetUserTargetsByYear, UpdateUserTargets } from "services/PlacementsService";
import UnsavedChangesDialog from "../UnsavedChangesDialog";

interface Props {
    open: boolean,
    userId: number,
    userName: string,
    loadingHandler: (isLoading: boolean) => void,
    closeHandler: () => void,
    successHandler: () => void,
    errorMessageHandler: (message: string) => void
}

interface TargetValuesString {
    targets: string,
    stretches: string
}

const defaultTargetValues: TargetValuesString = { targets: '', stretches: '' };
const noChangesTargetValues: ChangeTracker<TargetValuesString> = { targets: false, stretches: false };

const monthNameMap: any = {
    1: "January",
    2: "February",
    3: "March",
    4: "April",
    5: "May",
    6: "June",
    7: "July",
    8: "August",
    9: "September",
    10: "October",
    11: "November",
    12: "December",
};

export default function EditTargetsDialog({ open, userId, userName, loadingHandler, closeHandler, successHandler, errorMessageHandler }: Props) {
    const currentDate = useMemo(() => moment(), []);
    const [showSaveDialog, setShowSaveDialog] = useState(false);
    const [year, setYear] = useState(currentDate.year());
    const [nextYear, setNextYear] = useState<number | null>(null);
    const [targets, setTargets] = useState<BillingTarget[]>([]);
    const { init, change, updateInitial, hasChanges } = useObjectStateWithChangeTracker<TargetValuesString>(defaultTargetValues, noChangesTargetValues);

    const years = useMemo(() => {
        let options: number[] = [];
        const start = currentDate.year() + 5;
        for (let i = 0; i < 15; i++) {
            options.unshift(start - i);
        }
        return options;
    }, [currentDate]);

    const defaultTargets = useMemo(() => {
        let targets: BillingTarget[] = [];
        for (let i = 0; i < 12; i++) {
            targets.push({ userID: userId, year: year, month: i + 1, stretch: 0, target: 0 });
        }
        return targets;
    }, [userId, year]);

    const hasNegativeValues = useMemo(() => {
        for (let i = 0; i < targets.length; i++) {
            const t = targets[i];
            if (t.target < 0 || t.stretch < 0) return true;
        }
        return false;
    }, [targets]);
    
    useEffect(() => {
        const getData = async () => {
            loadingHandler(true);
            const data = await GetUserTargetsByYear(userId, year);
            if (data) {
                const realData = data.length === 0 ? defaultTargets : data;
                let targetValues: number[] = [];
                let stretchValues: number[] = [];
                
                for (let i = 0; i < realData.length; i++) {
                    const d = realData[i];
                    targetValues.push(d.target);
                    stretchValues.push(d.stretch);
                }

                setTargets(realData);
                init({ targets: JSON.stringify(targetValues), stretches: JSON.stringify(stretchValues) });
            }
            loadingHandler(false);
        };
        getData();
    }, [loadingHandler, userId, defaultTargets, year, init]);

    useEffect(() => {
        let targetValues: number[] = [];
        let stretchValues: number[] = [];
        
        for (let i = 0; i < targets.length; i++) {
            const d = targets[i];
            targetValues.push(d.target);
            stretchValues.push(d.stretch);
        }

        change('targets', JSON.stringify(targetValues));
        change('stretches', JSON.stringify(stretchValues));
    }, [targets, change]);

    const handleTargetChange = useCallback( (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setTargets(prev => {
            let newTargets = [...prev];
            newTargets[+name].target = parseInt(value);
            return newTargets;
        });
    }, []);
    
    const handleStretchChange = useCallback( (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setTargets(prev => {
            let newTargets = [...prev];
            newTargets[+name].stretch = parseInt(value);
            return newTargets;
        });
    }, []);

    const saveChangesCallback = useCallback(async () => {
        if (hasNegativeValues) {
            errorMessageHandler('Targets & Stretches must be greater than or equal to 0');
            return false;
        }

        loadingHandler(true);
        const res = await UpdateUserTargets(userId, year, targets, errorMessageHandler);
        if (!res) return false;
        loadingHandler(false);
        updateInitial();
        return true;
    }, [errorMessageHandler, loadingHandler, updateInitial, hasNegativeValues, targets, userId, year]);

    const saveBtnClickHandler = useCallback(async () => {
        const res = await saveChangesCallback();
        if (res) successHandler();
    }, [successHandler, saveChangesCallback]);

    const yearChangedHandler = useCallback( async (year: number) => {        
        if (hasChanges) {
            setShowSaveDialog(true);
            setNextYear(year);
            return;
        }
        setYear(year);
    }, [hasChanges]);

    const discardChangesAndContinueHandler = useCallback(() => {
        if (nextYear) {
            setYear(nextYear);
            setNextYear(null);
        }
        setShowSaveDialog(false);
    }, [nextYear]);

    const saveChangesFromDialogHandler = useCallback( async () => {
        const res = await saveChangesCallback();
        if (res) discardChangesAndContinueHandler();
    }, [saveChangesCallback, discardChangesAndContinueHandler]);

    const cancelDialogHandler = useCallback(() => {
        setShowSaveDialog(false);
        setNextYear(null);
    }, []);

    return (
        <>
            <UnsavedChangesDialog
                open={showSaveDialog}
                cancelHandler={ cancelDialogHandler }
                discardChangesHandler={ discardChangesAndContinueHandler } 
                saveChangesHandler={ saveChangesFromDialogHandler }
                message="If you continue changes will be lost. Would you like to save changes before proceeding?"
            />
            <Dialog open={open} fullWidth >
                <DialogTitle>Targets - {userName}</DialogTitle>
                <DialogContent dividers>
                    <Autocomplete
                        size="small"
                        value={year}
                        options={years}
                        fullWidth
                        disableClearable
                        onChange={ (e,v) => yearChangedHandler(v) }
                        getOptionLabel={ o => o.toString() }
                        renderInput={ params => <TextField {...params} label="Calendar Year" inputProps={{ ...params.inputProps, "data-lpignore": "true" }} /> }
                    />
                    <Divider sx={{ my: 2 }} />
                    <div style={{ maxWidth: '400px', margin: 'auto' }}>
                        <div>
                            <span style={{ width: '33%', display: 'inline-block', textAlign: 'center' }}>Month</span>
                            <span style={{ width: '33%', display: 'inline-block', textAlign: 'center' }}>Target</span>
                            <span style={{ width: '33%', display: 'inline-block', textAlign: 'center' }}>Stretch</span>
                        </div>
                        {targets.map((t, i) => (
                            <div key={t.month} style={{ paddingTop: '15px' }}>
                                <span style={{ width: '33%', display: 'inline-block', paddingRight: '10px' }}>{monthNameMap[t.month.toString()]}</span>
                                <span style={{ width: '33%', display: 'inline-block', padding: '0px 10px' }}>
                                    <Input 
                                        type="number"
                                        value={t.target}
                                        name={i.toString()}
                                        onChange={handleTargetChange}
                                        error={t.target < 0}
                                    />
                                </span>
                                <span style={{ width: '33%', display: 'inline-block', padding: '0px 10px' }}>
                                    <Input
                                        type="number"
                                        value={t.stretch}
                                        name={i.toString()}
                                        onChange={handleStretchChange}
                                        error={t.stretch < 0}
                                    />
                                </span>
                            </div>
                        ))}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="error" onClick={closeHandler}>Cancel</Button>
                    <Button variant="contained" color="success" onClick={saveBtnClickHandler} disabled={!hasChanges}>Save</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}