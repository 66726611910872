import { Permission } from '../common/models/Permissions';

export function userHasSinglePermission(permission: Permission): boolean {
    const USER_PERMISSIONS: Array<Permission> = JSON.parse(localStorage.getItem('uPermissions') ?? '');
    const res = USER_PERMISSIONS.findIndex(p => p === permission);
    return res !== -1;
}

export function userHasAnyPermission(Allowed: Array<Permission>) : boolean {
    const USER_PERMISSIONS: Array<Permission> = JSON.parse(localStorage.getItem('uPermissions') ?? '');

    for (let i = 0; i < Allowed.length; i++) {
        const a = Allowed[i];
        const res = USER_PERMISSIONS.findIndex(p => p === a);
        if (res !== -1) return true;
    }
    return false;
}

export function userHasAllPermissions(Allowed: Array<Permission>) : boolean {
    const USER_PERMISSIONS: Array<Permission> = JSON.parse(localStorage.getItem('uPermissions') ?? '');

    for (let i = 0; i < Allowed.length; i++) {
        const a = Allowed[i];
        const res = USER_PERMISSIONS.findIndex(p => p === a);
        if (res === -1) return false;
    }
    return true;
}

export function companyHasSinglePermission(permission: Permission): boolean {
    const COMPANY_PERMISSIONS: Array<Permission> = JSON.parse(localStorage.getItem('cPermissions') ?? '');
    const res = COMPANY_PERMISSIONS.findIndex(p => p === permission);
    return res !== -1;
}

export function companyHasAnyPermission(Allowed: Array<Permission>) : boolean {
    const COMPANY_PERMISSIONS: Array<Permission> = JSON.parse(localStorage.getItem('cPermissions') ?? '');

    for (let i = 0; i < Allowed.length; i++) {
        const a = Allowed[i];
        const res = COMPANY_PERMISSIONS.findIndex(p => p === a);
        if (res !== -1) return true;
    }
    return false;
}

export function companyHasAllPermissions(Allowed: Array<Permission>) : boolean {
    const COMPANY_PERMISSIONS: Array<Permission> = JSON.parse(localStorage.getItem('cPermissions') ?? '');

    for (let i = 0; i < Allowed.length; i++) {
        const a = Allowed[i];
        const res = COMPANY_PERMISSIONS.findIndex(p => p === a);
        if (res === -1) return false;
    }
    return true;
}