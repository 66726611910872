import { BasicFilter, FilterWithJobStage, FilterWithTimeRange } from "common/models/Analytics/AnalyticsFilterData";
import { ActiveInterview, BillingTrend, TopPerformersRecord, JobsByClientRecord, KeyActivityRecord, KpiDashboard, OpenJob, StageTotalObj, TopClientsByActiviyRecord, TopClientsByFeesRecord, TopClientsByJobRecord, TopClientsByRatiosRecord, TopContactsByActiviyRecord, TopContactsByFeesRecord, TopContactsByJobRecord, TopContactsByRatiosRecord, UserFilterTypeID, KpiOverview, OpportunitiesByTypeRecord, OpportunitiesByStageRecord, JobsByConsultantRecord, JobsBySourceRecord, JobsByStageRecord, ApplicantsBySourceRecord, AveragePermFeesRecord, AverageContractMarginRecord, PlacementsAverageByConsultantRecord, PlacementsContractFinancialsOverviewRecord, PlacementsContractFinancialsTrendRecord, PlacementQtyBySourceRecord, PlacementFeesBySourceRecord, TypeActualObj, PerformanceActivityRatioRecord, PerformanceActivityRatioTrendsRecord, PerformancePhoneTimeRecord, ActivitiesGraphData, JobPipelineGraphData, PlacementFeesGraphData } from "../common/models/Analytics/BusinessIntelligence";
import { ErrorHandler, RequestOptions } from "../common/models/GenericTypes";
import { Get, Post } from "../util/HttpUtils";

export async function GetMyOpenJobs(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<OpenJob[]>('api/BusinessIntelligence/MyOpenJobs', options);
}

export async function GetMyActiveInterviews(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<ActiveInterview[]>('api/BusinessIntelligence/MyActiveInterviews', options);
}

export async function GetMyContractorBookByQty(typeId: UserFilterTypeID, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<StageTotalObj[]>(`api/BusinessIntelligence/MyContractorBookByQty?userTypeId=${typeId.valueOf()}`, options);
}

export async function GetContractorBookByQty(filterData: BasicFilter, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<StageTotalObj[]>(`api/BusinessIntelligence/ContractorBookByQty`, options, filterData);
}

export async function GetMyBillingsTrend(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<BillingTrend[]>('api/BusinessIntelligence/MyBillingsTrend', options);
}

export async function GetCustomBillingsTrend(filterData: BasicFilter, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<BillingTrend[]>('api/BusinessIntelligence/CustomBillingsTrend', options, filterData);
}

export async function GetCompanyBillingsTrend(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<BillingTrend[]>('api/BusinessIntelligence/CompanyBillingsTrend', options);
}

export async function GetKpiDashboardData(filterData: FilterWithTimeRange, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<KpiDashboard[]>('api/BusinessIntelligence/KPIDashboard', options, filterData);
}

export async function GetKpiOverviewData(filterData: FilterWithTimeRange, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };
    
    return Post<KpiOverview>('api/BusinessIntelligence/KPIOverview', options, filterData);
}

export async function TopClientsByJobs(filterData: FilterWithTimeRange, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<TopClientsByJobRecord[]>('api/BusinessIntelligence/TopClientsByJobs', options, filterData);
}

export async function TopClientsByFees(filterData: FilterWithTimeRange, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<TopClientsByFeesRecord[]>('api/BusinessIntelligence/TopClientsByFees', options, filterData);
}

export async function TopClientsByActivity(filterData: FilterWithTimeRange, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<TopClientsByActiviyRecord[]>('api/BusinessIntelligence/TopClientsByActivity', options, {...filterData, sortColumn: 'Placements'});
}

export async function TopClientsByRatios(filterData: FilterWithTimeRange, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<TopClientsByRatiosRecord[]>('api/BusinessIntelligence/TopClientsByRatios', options, {...filterData, sortColumn: 'SubmissionsToPlacementsRatio'});
}

export async function TopContactsByJobs(filterData: FilterWithTimeRange, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<TopContactsByJobRecord[]>('api/BusinessIntelligence/TopContactsByJobs', options, filterData);
}

export async function TopContactsByFees(filterData: FilterWithTimeRange, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<TopContactsByFeesRecord[]>('api/BusinessIntelligence/TopContactsByFees', options, filterData);
}

export async function TopContactsByActivity(filterData: FilterWithTimeRange, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<TopContactsByActiviyRecord[]>('api/BusinessIntelligence/TopContactsByActivity', options, {...filterData, sortColumn: 'Placements'});
}

export async function TopContactsByRatios(filterData: FilterWithTimeRange, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<TopContactsByRatiosRecord[]>('api/BusinessIntelligence/TopContactsByRatios', options, {...filterData, sortColumn: 'SubmissionsToPlacementsRatio'});
}

export async function CustomTopPerformers(filterData: BasicFilter, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<TopPerformersRecord[]>('api/BusinessIntelligence/CustomTopPerformers', options, filterData);
}

export async function CompanyTopPerformers(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<TopPerformersRecord[]>('api/BusinessIntelligence/CompanyTopPerformers', options);
}

export async function GetCustomOpenJobsByClient(filterData: BasicFilter, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<JobsByClientRecord[]>('api/BusinessIntelligence/CustomOpenJobs', options, filterData);
}

export async function GetCompanyOpenJobsByClient(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<JobsByClientRecord[]>('api/BusinessIntelligence/CompanyOpenJobs', options);
}

export async function GetCustomKeyActivity(filterData: BasicFilter, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<KeyActivityRecord[]>('api/BusinessIntelligence/CustomKeyActivity', options, filterData);
}

export async function GetCompanyKeyActivity(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<KeyActivityRecord[]>('api/BusinessIntelligence/CompanyKeyActivity', options);
}

export async function GetOpportunitiesByType(filterData: BasicFilter, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<OpportunitiesByTypeRecord[]>('api/BusinessIntelligence/OpportunitiesByType', options, filterData);
}

export async function GetOpportunitiesByStage(filterData: BasicFilter, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<OpportunitiesByStageRecord[]>('api/BusinessIntelligence/OpportunitiesByStage', options, filterData);
}

export async function GetNewJobsByConsultant(filterData: FilterWithTimeRange, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<JobsByConsultantRecord[]>('api/BusinessIntelligence/JobsByConsultantNew', options, filterData);
}

export async function GetOpenJobsByConsultant(filterData: FilterWithJobStage, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<JobsByConsultantRecord[]>('api/BusinessIntelligence/JobsByConsultantOpen', options, filterData);
}

export async function GetClosedJobsByConsultant(filterData: FilterWithTimeRange, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<JobsByConsultantRecord[]>('api/BusinessIntelligence/JobsByConsultantClosed', options, filterData);
}

export async function GetPlacedJobsByConsultant(filterData: FilterWithTimeRange, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<JobsByConsultantRecord[]>('api/BusinessIntelligence/JobsByConsultantPlaced', options, filterData);
}

export async function GetJobsBySource(filterData: FilterWithTimeRange, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<JobsBySourceRecord[]>('api/BusinessIntelligence/JobsBySource', options, filterData);
}

export async function GetJobsByStage(filterData: BasicFilter, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<JobsByStageRecord[]>('api/BusinessIntelligence/JobsByStage', options, filterData);
}

export async function GetApplicantsBySource(filterData: BasicFilter, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<ApplicantsBySourceRecord[]>('api/BusinessIntelligence/ApplicantsBySource', options, filterData);
}

export async function GetPlacementsAveragePermFees(filterData: BasicFilter, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<AveragePermFeesRecord[]>('api/BusinessIntelligence/AveragePermFees', options, filterData);
}

export async function GetPlacementsAverageDailyContractMargin(filterData: BasicFilter, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<AverageContractMarginRecord[]>('api/BusinessIntelligence/AverageDailyContractMargin', options, filterData);
}

export async function GetPlacementsAverageHourlyContractMargin(filterData: BasicFilter, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<AverageContractMarginRecord[]>('api/BusinessIntelligence/AverageHourlyContractMargin', options, filterData);
}

export async function GetPlacementsAverageByConsultant(filterData: FilterWithTimeRange, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<PlacementsAverageByConsultantRecord[]>('api/BusinessIntelligence/AverageFeesByConsultant', options, filterData);
}

export async function GetContractFinancialsOverview(filterData: BasicFilter, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<PlacementsContractFinancialsOverviewRecord[]>('api/BusinessIntelligence/ContractFinancialsOverview', options, filterData);
}

export async function GetContractFinancialsTrend(filterData: BasicFilter, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<PlacementsContractFinancialsTrendRecord[]>('api/BusinessIntelligence/ContractFinancialsTrend', options, filterData);
}

export async function GetContractorBookByFees(filterData: BasicFilter, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<StageTotalObj[]>('api/BusinessIntelligence/ContractorBookByFees', options, filterData);
}

export async function GetContractorBookByQuantity(filterData: BasicFilter, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<StageTotalObj[]>('api/BusinessIntelligence/ContractorBookByQty', options, filterData);
}

export async function GetPlacementQuantityBySource(filterData: FilterWithTimeRange, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<PlacementQtyBySourceRecord[]>('api/BusinessIntelligence/PlacementQtyBySource', options, filterData);
}

export async function GetPlacementFeesBySource(filterData: FilterWithTimeRange, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<PlacementFeesBySourceRecord[]>('api/BusinessIntelligence/PlacementFeesBySource', options, filterData);
}

export async function GetPerformanceActivitiesData(filterData: FilterWithTimeRange, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<TypeActualObj[]>('api/BusinessIntelligence/Activities', options, filterData);
}

export async function GetPerformanceActivityRatiosData(filterData: FilterWithTimeRange, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<PerformanceActivityRatioRecord[]>('api/BusinessIntelligence/ActivityRatios', options, { ...filterData, sortColumn: ''});
}

export async function GetPerformanceActivityRatioTrendData(filterData: BasicFilter, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<PerformanceActivityRatioTrendsRecord[]>('api/BusinessIntelligence/ActivityRatiosTrends', options, filterData);
}

export async function GetTotalFeesByConsultantData(filterData: FilterWithTimeRange, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<any[]>('api/BusinessIntelligence/TotalFeesByConsultant', options, filterData);
}

export async function GetPhoneTimeData(filterData: FilterWithTimeRange, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Post<PerformancePhoneTimeRecord[]>('api/BusinessIntelligence/PhoneTime', options, filterData);
}

export async function GetDashboardActivitiesGraphData(consultantId: number, startDate: string, endDate: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    const params = new URLSearchParams();
    params.set('consultantId', consultantId.toString());
    params.set('startDate', startDate);
    params.set('endDate', endDate);

    return Get<ActivitiesGraphData>(`api/BusinessIntelligence/DashboardActivitiesGraph?${params}`, options);
}

export async function GetDashboardJobPipelineGraphData(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<JobPipelineGraphData>('api/BusinessIntelligence/DashboardJobPipelineGraph', options);
}


export async function GetDashboardPlacementFeesGraphData(startDate: string, endDate: string, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    const start = encodeURIComponent(startDate);
    const end = encodeURIComponent(endDate);

    return Get<PlacementFeesGraphData>(`api/BusinessIntelligence/DashboardPlacementFeesGraph?startDate=${start}&endDate=${end}`, options);
}