import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import CancelIcon from '@mui/icons-material/Cancel';
import MicIcon from '@mui/icons-material/Mic';
import StopIcon from '@mui/icons-material/Stop';
import * as tinymce from 'tinymce';
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { PREVIEW_DRAGGABLE_TITLE_STYLE } from "util/Definitions/Constants/Previews";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import MessageTemplatePicker from "components/Pickers/MessageTemplatePicker";
import { Editor } from "@tinymce/tinymce-react";
import { useTheme } from "@mui/material/styles";
import { MessageTemplate } from "common/models/Templates/MessageTemplate";
import { EmailData, EmailRecipient } from "common/models/EmailRecipient";
import RecipientsValidationDialog from "./RecipientsValidationDialog";
import ManageRecipientsDialog from "./ManageRecipientsDialog";
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
import Button from "@mui/material/Button";
import ActionsSplitButton from "components/SummaryBars/Actions/ActionsSplitButton";
import Box from "@mui/material/Box";
import { GetRemainingSmsCredits, SendSms, SmsMessageCreditCheck } from "services/MessagesService";
import SmsPreviewDialog from "./SmsPreviewDialog";
import moment from "moment";
import ConfirmationWithDateDialog from "../Generic/ConfirmationWithDateDialog";
import ConfirmationDialog from "../Generic/ConfirmationDialog";
import { GetSendSmsSettings } from "services/ConfigurationService";
import { SendSmsSettings } from "common/models/Configuration/Messages";
import DraggablePaperComponent from 'components/DraggablePaperComponent'
import { TemplatePlaceholder } from "common/models/Templates/TemplatePlaceholder";
import { GetPlaceholdersByEntity, GetPlaceholdersByEntityList } from "services/PlaceholdersService";
import InsertPlaceholderDialog from "../Generic/InsertPlaceholderDialog";
import { MessagePreviewData } from "common/models/Messages";

interface Props {
    open: boolean,
    sourceEntityId: number,
    recipientEntityTypeId: number,
    recipients: EmailRecipient[],
    isPlacementList?: boolean,
    closeHandler: () => void,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
    successHandler?: (message: string) => void,
    placementId?: number,
    jobId?: number,
}

const jobEntityId = 4;

export default function SendSmsDialog({ open, sourceEntityId, recipientEntityTypeId: entityTypeId, recipients, isPlacementList, closeHandler, loadingHandler, errorHandler, successHandler, placementId = 0, jobId = 0 }: Props) {
    const [sendSmsSettings, setSendSmsSettings] = useState<SendSmsSettings | null>(null);
    const [validRecipients, setValidRecipients] = useState<EmailRecipient[]>([]);
    const [invalidRecipients, setInvalidRecipients] = useState<EmailRecipient[]>([]);
    const [noContactRecipients, setNoContactRecipients] = useState<EmailRecipient[]>([]);
    const [optOutRecipients, setOptOutRecipients] = useState<EmailRecipient[]>([]);
    const [showRecipients, setShowRecipients] = useState(false);
    const [showPreview, setShowPreview] = useState(false);
    const [showConfirmSend, setShowConfirmSend] = useState(false);
    const [showPlaceholders, setShowPlaceholders] = useState(false);
    const [minsDelay, setMinsDelay] = useState(0);
    const [isContinueWithValids, setIsContinueWithValids] = useState(false);
    const [body, setBody] = useState('');
    const [showCustomSend, setShowCustomSend] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [templateType, setTemplateType] = useState('Me');
    const [remainingCredits, setRemainingCredits] = useState(0);
    const [neededCredits, setNeededCredits] = useState(0);
    const editorRef = useRef<tinymce.Editor | null>(null);
    const [selectedTemplate, setSelectedTemplate] = useState<MessageTemplate | null>(null);
    const [placeholders, setPlaceholders] = useState<TemplatePlaceholder[]>([]);
    const { browserSupportsSpeechRecognition, isMicrophoneAvailable, listening, interimTranscript, finalTranscript, resetTranscript } = useSpeechRecognition();
    const theme = useTheme();
    const isDarkTheme = useMemo(() => theme.palette.mode === 'dark', [theme.palette.mode]);

    useEffect(() => {
        const getCredits =  async () => {
            const res = await GetRemainingSmsCredits();
            const settings = await GetSendSmsSettings();
            if (res) setRemainingCredits(res.value);
            if (settings) setSendSmsSettings(settings);
        };
        getCredits();
    }, []);

    useEffect(() => {
        if (open && placeholders.length === 0) {
            const getPlaceholders = async () => {
                loadingHandler && loadingHandler(true);
                let res: TemplatePlaceholder[] | null = null;
                if (sourceEntityId !== jobEntityId && jobId) {
                    res = await GetPlaceholdersByEntityList([sourceEntityId, jobEntityId]);
                }
                else {
                    res = await GetPlaceholdersByEntity(sourceEntityId);
                }
                if (res) setPlaceholders(res);
                loadingHandler && loadingHandler(false);
            };
            getPlaceholders();
        }
    }, [jobId, loadingHandler, open, placeholders.length, sourceEntityId]);

    useEffect(() => {
        if (browserSupportsSpeechRecognition && isMicrophoneAvailable && listening) {
            if (finalTranscript !== '' && editorRef.current) {
                editorRef.current.insertContent(finalTranscript + ' ');
                resetTranscript();
            }
        }
    }, [browserSupportsSpeechRecognition, isMicrophoneAvailable, listening, finalTranscript, resetTranscript]);

    const allRecipientsValid = useMemo(() => {
        if (open) {
            let valids: EmailRecipient[] = [];
            let invalids: EmailRecipient[] = [];
            let noContact: EmailRecipient[] = [];
            let optOuts: EmailRecipient[] = [];

            for (let i = 0; i < recipients.length; i++) {
                const r = recipients[i];
                if (r.isOptOut) optOuts.push(r);
                else if (!r.id) noContact.push(r);
                else if (r.mobile === '' || r.mobile === null || r.mobile === undefined) invalids.push(r);
                else valids.push(r);
            }

            setValidRecipients(valids);
            setInvalidRecipients(invalids);
            setNoContactRecipients(noContact);
            setOptOutRecipients(optOuts);
            return invalids.length === 0 && noContact.length ===0 && optOuts.length === 0;
        }
        return true;
    }, [recipients, open]);

    useEffect(() => {
        if (!open) {
            setIsContinueWithValids(false);
            setMinsDelay(0);
            setNeededCredits(0);
            setShowConfirmSend(false);
        }
    }, [open]);

    const templateChangeHandlerCallback = useCallback((template: MessageTemplate | null) => {
        if (template && editorRef.current) {
            const message = template.body.replaceAll('\n', '<br />');
            editorRef.current.setContent(message);
        }
        setSelectedTemplate(template);
    }, []);

    const removeRecipientCallback = useCallback((index: number) => {
        setValidRecipients( prev => {
            let tmp = [...prev];
            if (tmp[index]) tmp.splice(index, 1);
            return tmp;
        });
    }, []);

    const showPreviewCallback = useCallback(() => {
        if (editorRef.current) {
            const body = editorRef.current.getContent();
            setBody(body);
            setShowPreview(true);
        }
    }, []);

    const sendSmsCallback = useCallback(async (minutesDelay: number = 0) => {
        setIsSending(true);
        if (editorRef.current) {
            loadingHandler && loadingHandler(true);

            const body = editorRef.current.getContent();

            if (isPlacementList) {
                let isSuccess = false;
                for (let i = 0; i < validRecipients.length; i++) {
                    const recipient = validRecipients[i];
                    const emailData: EmailData = {
                        entityTypeId: entityTypeId,
                        templateAttachmentIds: [],
                        jobId: jobId,
                        recipientIds: [recipient.id],
                        placementId: recipient.placementId ?? 0,
                        changeRequestId: recipient.changeRequest ? recipient.changeRequest.id : 0,
                        subject: '',
                        templateId: 0,
                        body: body
                    };
    
                    const res = await SendSms(emailData, minutesDelay, errorHandler);
                    if (res) isSuccess = isSuccess || res;
                }

                if (isSuccess) {
                    successHandler && successHandler('Message Sent');
                    closeHandler();
                }
            }
            else {
                let recipientIds: number[] = [];

                for (let i = 0; i < validRecipients.length; i++) {
                    const recipient = validRecipients[i];
                    recipientIds.push(recipient.id);
                }
                
                const emailData: EmailData = {
                    entityTypeId: entityTypeId,
                    templateAttachmentIds: [],
                    jobId: jobId,
                    recipientIds: recipientIds,
                    placementId: placementId,
                    changeRequestId: 0,
                    subject: '',
                    templateId: 0,
                    body: body
                };

                const res = await SendSms(emailData, minutesDelay, errorHandler);
                if (res) {
                    successHandler && successHandler('Message Sent');
                    closeHandler();
                }
            }

            loadingHandler && loadingHandler(false);
        }

        setIsSending(false);

    }, [loadingHandler, isPlacementList, validRecipients, entityTypeId, jobId, placementId, errorHandler, successHandler, closeHandler]);

    const sendSmsValidationCallback = useCallback(async (minutesDelay: number = 0) => {
        if (editorRef.current) {
            const body = editorRef.current.getContent();
            let data: MessagePreviewData[] = [];
            
            for (let i = 0; i < validRecipients.length; i++) {
                const recipient = validRecipients[i];
                data.push({
                    subject: '',
                    body: body,
                    changeRequestId: recipient.changeRequest ? recipient.changeRequest.id : 0,
                    placementId: recipient.placement ? recipient.placement.id : 0,
                    recipientEntityId: entityTypeId,
                    recipientRecordId: recipient.id,
                    jobId: jobId,
                });
            }

            setIsSending(true);
            loadingHandler && loadingHandler(true);
            const res = await SmsMessageCreditCheck(data, errorHandler);
            loadingHandler && loadingHandler(false);
            if (res) setNeededCredits(res.creditsNeeded);
            if (res && res.creditsNeeded > res.creditBalance) {
                errorHandler && errorHandler(res.statusMessage);
                setIsSending(false);
                return;
            }
            if (!res) {
                setIsSending(false);
                return;
            }
            if (sendSmsSettings && sendSmsSettings.isConfirmToSend && sendSmsSettings.creditLimit < res.creditsNeeded) {
                setIsSending(false);
                setMinsDelay(minutesDelay);
                setShowCustomSend(false);
                setShowConfirmSend(true);
            }
            else {
                sendSmsCallback(minutesDelay);
            }
        }
    }, [loadingHandler, errorHandler, sendSmsSettings, validRecipients, entityTypeId, jobId, sendSmsCallback]);

    const customSendHandlerCallback = useCallback((date: string) => {
        let delay = 0;
        const m = moment(date);
        const now = moment();

        if (m.isValid()) delay = m.diff(now, 'minutes');
        sendSmsValidationCallback(delay);
    }, [sendSmsValidationCallback]);

    const handleConfirmSend = useCallback(() => {
        setShowConfirmSend(false);
        sendSmsCallback(minsDelay);
    }, [minsDelay, sendSmsCallback]);

    const insertPlaceholderHandler = useCallback((placeholder: string) => {
        const api = editorRef.current;
        if (api) api.insertContent(placeholder);
    }, []);

    useEffect(() => {
        if (!open || showRecipients || showPreview || showConfirmSend || showPlaceholders || showCustomSend) SpeechRecognition.stopListening();
    }, [open, showConfirmSend, showCustomSend, showPlaceholders, showPreview, showRecipients]);

    return (
        <>
            <RecipientsValidationDialog
                open={open && !allRecipientsValid && !isContinueWithValids}
                entityTypeId={entityTypeId}
                totalRecipients={ recipients }
                validRecipients={ validRecipients }
                invalidEmailOrPhoneRecipients={ invalidRecipients }
                noPrimaryContactRecipients={ noContactRecipients }
                optOutRecipients={ optOutRecipients }
                continueHandler={ () => setIsContinueWithValids(true) }
                closeHandler={closeHandler}
                isSmsRecipients
            />
            <ManageRecipientsDialog
                open={open && showRecipients}
                entityTypeId={entityTypeId}
                recipients={validRecipients}
                removeHandler={removeRecipientCallback}
                closeHandler={ () => setShowRecipients(false) }
                isSmsRecipients
            />
            <SmsPreviewDialog
                open={open && showPreview && validRecipients.length > 0}
                recipientsEntityId={entityTypeId}
                body={body}
                isDarkTheme={isDarkTheme}
                recipients={validRecipients}
                closeHandler={ () => setShowPreview(false) }
                loadingHandler={loadingHandler}
            />
            <ConfirmationWithDateDialog
                open={ open && showCustomSend }
                title="Custom Date"
                onClose={ () => setShowCustomSend(false) }
                onContinue={ customSendHandlerCallback }
                dateType="datetime-local"
                dateFieldLabel="Send After"
                hideBackdrop
            />
            <ConfirmationDialog
                open={ open && showConfirmSend }
                message={<>Do you want to send this SMS to <strong>{validRecipients.length}</strong> recipients? This action requires <strong>{neededCredits}</strong> credits</>}
                title="Send SMS"
                onContinue={ handleConfirmSend }
                onClose={ () => setShowConfirmSend(false) }
                cancelActionText="No"
                confirmActionText="Yes"
                hideBackdrop
            />
            <InsertPlaceholderDialog
                open={showPlaceholders}
                closeHandler={() => setShowPlaceholders(false)}
                placeholders={placeholders}
                insertHandler={insertPlaceholderHandler}
            />
            <Dialog open={open && (allRecipientsValid || isContinueWithValids)} maxWidth="lg" fullWidth PaperComponent={DraggablePaperComponent} aria-labelledby="draggable-dialog-title">
                <DialogTitle component="div" display="flex" sx={PREVIEW_DRAGGABLE_TITLE_STYLE} id="draggable-dialog-title" >
                    <div style={{ alignSelf: 'center' }}>Send SMS</div>
                    <div style={{ marginLeft: 'auto' }}>
                        <IconButton size="small" onClick={ () => setShowRecipients(true) } sx={{ color: t => t.palette.primary.contrastText }}><PeopleAltIcon /></IconButton>
                        <IconButton size="small" onClick={ closeHandler } sx={{ color: t => t.palette.primary.contrastText }}><CancelIcon /></IconButton>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}></Grid>
                        <Grid item xs={6}>
                            <TextField
                                select
                                fullWidth
                                label="Template Type"
                                value={templateType}
                                onChange={ ({target}) => setTemplateType(target.value) }
                            >
                                <MenuItem value="Me">My Templates</MenuItem>
                                <MenuItem value="Team">My Team's Templates</MenuItem>
                                <MenuItem value="All">All Templates</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={6}>
                            {open &&
                                <MessageTemplatePicker
                                    templateId={selectedTemplate ? selectedTemplate.id : null}
                                    includeCompanyOwned
                                    typeFilter={2}
                                    templateSource={templateType}
                                    entityTypeFilter={entityTypeId}
                                    onSelectHandler={ templateChangeHandlerCallback }
                                />
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <Editor
                                tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce-5.10.2/tinymce.min.js'}
                                onInit={ (evt, editor) => editorRef.current = editor }
                                init={{
                                    height: 510,
                                    skin: isDarkTheme ? 'oxide-dark' : undefined,
                                    content_css: isDarkTheme ? 'dark' : undefined,
                                    branding: false,
                                    menubar: false,
                                    statusbar: false,
                                    contextmenu: false,
                                    resize: false,
                                    browser_spellcheck: true,
                                    plugins: 'paste',
                                    paste_as_text: true,
                                    toolbar1: 'placeholders',
                                    forced_root_block: "",
                                    entity_encoding: 'raw',
                                    setup: editor => {
                                        editor.ui.registry.addButton('placeholders', {
                                            text: 'Insert Placeholder',
                                            onAction: () => setShowPlaceholders(true)
                                        });
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            Credits Remaining: <Box component="span" color="red" fontWeight="500">{remainingCredits}</Box>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <div style={{ marginRight: 'auto', display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                        {browserSupportsSpeechRecognition && !listening &&
                            <IconButton onClick={ () => SpeechRecognition.startListening({language: 'en-AU', continuous: true}) }><MicIcon /></IconButton>
                        }
                        {browserSupportsSpeechRecognition && listening &&
                            <>
                                <IconButton onClick={ () => SpeechRecognition.stopListening() }><StopIcon /></IconButton>
                                {interimTranscript === '' && <span style={{ padding: '0 10px' }}>Listening...</span>}
                                <div style={{
                                    flexGrow: 1,
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    maxWidth: '510px',
                                    padding: '0 10px'
                                }} >{interimTranscript}</div>
                            </>
                        }
                    </div>
                    <Button variant="contained" onClick={ showPreviewCallback }>Preview</Button>
                    <ActionsSplitButton mainAction={ () => sendSmsValidationCallback(0) } mainActionDisabled={false} label={isSending ? 'Sending...' : 'Send'} color="success" menuPlacement="top-end">
                        <MenuItem onClick={ () => sendSmsValidationCallback(60) }>In an Hour</MenuItem>
                        <MenuItem onClick={ () => sendSmsValidationCallback(60 * 4) }>In 4 Hours</MenuItem>
                        <MenuItem onClick={ () => sendSmsValidationCallback(60 * 8) }>In 8 Hours</MenuItem>
                        <MenuItem onClick={ () => sendSmsValidationCallback(60 * 12) }>In 12 Hours</MenuItem>
                        <MenuItem onClick={ () => sendSmsValidationCallback(60 * 24) }>In 24 Hours</MenuItem>
                        <MenuItem onClick={ () => sendSmsValidationCallback(60 * 24 * 2) }>In 2 Days</MenuItem>
                        <MenuItem onClick={ () => sendSmsValidationCallback(60 * 24 * 3) }>In 3 Days</MenuItem>
                        <MenuItem onClick={ () => sendSmsValidationCallback(60 * 24 * 5) }>In 5 Days</MenuItem>
                        <MenuItem onClick={ () => sendSmsValidationCallback(60 * 24 * 7) }>In 1 Week</MenuItem>
                        <MenuItem onClick={ () => setShowCustomSend(true) }>Custom</MenuItem>
                    </ActionsSplitButton>
                </DialogActions>
            </Dialog>
        </>
    );
}