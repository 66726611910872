import Alert from "components/Alert";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import React, { useMemo, useState } from "react";
import CandidatesRecentUploadsGridComponent from "components/Grids/CandidatesRecentUploadsGrid";
import TitleAndActionSummaryBar from "components/SummaryBars/TitleAndActionSummaryBar";
import PageContentLayout from "layouts/PageContentLayout";
import PageLayout from "layouts/PageLayout";
import CandidatesGridComponent from "components/Grids/CandidatesGrid";

export default function CandidatesRecentUploadsPage() {
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [isViewCandidates, setViewCandidates] = useState(false);

    const summaryBar = useMemo(() => {
        const action = <Button variant="contained" color="success" onClick={() => setViewCandidates(prev => !prev)}>{ isViewCandidates ? 'View Recent Uploads' : 'View Candidates'}</Button>;
        return <TitleAndActionSummaryBar title="Candidates > Recent Uploads" browserTabTitle="Recent Uploads > Candidates" action={action} />
    }, [isViewCandidates]);

    return (
        <PageLayout SummaryBar={summaryBar}>
            <Snackbar open={successMessage !== ''} autoHideDuration={3000} onClose={() => setSuccessMessage('')}>
                <Alert onClose={() => setSuccessMessage('')}>{ successMessage }</Alert>
            </Snackbar>
            <Snackbar open={errorMessage !== ''} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity="error" onClose={() => setErrorMessage('')}>{ errorMessage }</Alert>
            </Snackbar>
            <PageContentLayout showLoading={isLoading}>
                {isViewCandidates &&
                    <CandidatesGridComponent
                        gridName="candidate/recent-uploads/records"
                        source="recent"
                        errorHandler={setErrorMessage}
                        loadingHandler={setIsLoading}
                        successHandler={setSuccessMessage}
                    />
                }
                {!isViewCandidates &&
                    <CandidatesRecentUploadsGridComponent
                        gridName="candidates/recent-uploads"
                        errorHandler={setErrorMessage}
                        loadingHandler={setIsLoading}
                        successHandler={setSuccessMessage}
                    />
                }
            </PageContentLayout>
        </PageLayout>
    );
}