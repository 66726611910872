import { ClientsConfigOptions } from "common/models/Configuration/Clients"
import { ChangeTracker } from "common/models/hooks/ChangeTracker"

export const DefaultOptions: ClientsConfigOptions = {
    ClientDefaultCode1Type: '0',
    ClientDefaultCode2Type: '0',
    ClientDefaultSummary: '',
}

export const NoChangesTracker: ChangeTracker<ClientsConfigOptions> = {
    ClientDefaultCode1Type: false,
    ClientDefaultCode2Type: false,
    ClientDefaultSummary: false,
}