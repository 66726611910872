import Box from "@mui/material/Box";
import React, { useMemo } from "react";
import JobBoardViewField from "./JobBoardViewField";
import Chip from "@mui/material/Chip";

interface Props {
    jobBoardId: number,
    advertId: number,
    attributes?: Record<string, string>,
    advertRecordAttributes?: Record<string, string>,
}

const categoryMap: Record<string, string> = {
    "accounts": "Accounting",
    "accounts-support": "Accounts Support",
    "administration-officesupport": "Administration & Office Support",
    "architecture": "Architecture",
    "banking-finance": "Banking & Finance",
    "bookkeeping": "Bookkeeping",
    "business-development": "Business Development",
    "call-centre": "Call Centre",
    "not-for-profit-charity": "Charity & Not - for - Profit",
    "no-descriptioncustomer-service": "Customer Service",
    "education-training": "Education & Training",
    "engineering": "Engineering",
    "event-management": "Event Management",
    "fashion": "Fashion",
    "test-cat": "Government",
    "healthcare-medical": "Healthcare & Medical",
    "home-care": "home - care",
    "hospitality": "Hospitality",
    "human-resources": "Human Resources",
    "information-technology": "Information Technology",
    "insurance": "Insurance",
    "legal": "Legal",
    "management": "Management",
    "marketing-communications": "Marketing Communications",
    "other": "Other",
    "pr": "PR",
    "property-construction": "Property & Construction",
    "real-estate": "Real Estate",
    "recruitment": "Recruitment",
    "retail": "Retail",
    "sales-marketing": "Sales & Marketing",
    "services": "Services",
    "software": "software",
    "transport-logistics": "Transport & Logistics",
};

const jobTypeMap: Record<string, string> = {
    "internship": "Internship",
    "freelance": "Freelance",
    "full-time": "Full Time",
    "part-time": "Part Time",
    "temporary": "Temporary",
};

const regionMap: Record<string, string> = {
    "act": "ACT",
    "adelaide": "Adelaide",
    "brisbane": "Brisbane",
    "darwin": "Darwin",
    "melbourne": "Melbourne",
    "nsw": "NSW",
    "nt": "NT",
    "online-australia-wide": "Online / Australia Widw",
    "perth": "Perth",
    "qld": "QLD",
    "sa": "SA",
    "sydney": "Sydney",
    "tas": "TAS",
    "vic": "VIC",
    "wa": "WA",
};

export default function WPJobManagerV2View({ jobBoardId, advertId, attributes, advertRecordAttributes }: Props) {
    const atts = useMemo<Record<string, string>>(() => {
        if (attributes) return attributes;
        return {};
    }, [attributes]);

    const recordAtts = useMemo<Record<string, string>>(() => {
        if (advertRecordAttributes) return advertRecordAttributes;
        return {};
    }, [advertRecordAttributes]);

    const categories = useMemo(() => atts.categories ?? '', [atts.categories]);
    const jobTypes = useMemo(() => atts.jobtypes ?? '', [atts.jobtypes]);
    const regions = useMemo(() => atts.regions ?? '', [atts.regions]);

    const location = useMemo(() => recordAtts.location ?? '', [recordAtts.location]);
    const displaySalary = useMemo(() => recordAtts.displaySalary ?? '', [recordAtts.displaySalary]);

    const categoriesArray = useMemo(() => {
        if (categories) return JSON.parse(categories) as string[];
        return [];
    }, [categories]);

    const jobTypesArray = useMemo(() => {
        if (jobTypes) return JSON.parse(jobTypes) as string[];
        return [];
    }, [jobTypes]);

    const regionsArray = useMemo(() => {
        if (regions) return JSON.parse(regions) as string[];
        return [];
    }, [regions]);

    const renderedCategories = useMemo(() => {
        if (categoriesArray && categoriesArray.length > 0) {
            return (
                <>{categoriesArray.map(c => <Chip key={c} size="small" label={categoryMap[c] ?? ''} /> )}</>
            );
        }
        return <></>;
    }, [categoriesArray]);

    const renderedJobTypes = useMemo(() => {
        if (jobTypesArray && jobTypesArray.length > 0) {
            return (
                <>{jobTypesArray.map(jt => <Chip key={jt} size="small" label={jobTypeMap[jt] ?? ''} /> )}</>
            );
        }
        return <></>;
    }, [jobTypesArray]);

    const renderedRegions = useMemo(() => {
        if (regionsArray && regionsArray.length > 0) {
            return (
                <>{regionsArray.map(r => <Chip key={r} size="small" label={regionMap[r] ?? ''} /> )}</>
            );
        }
        return <></>;
    }, [regionsArray]);

    return (
        <Box display="flex" gap={2} pt={2}>
            <Box display="flex" flexDirection="column" gap={2} width="50%">
                <JobBoardViewField label="Categories" value={renderedCategories} />
                <JobBoardViewField label="JobTypes" value={renderedJobTypes} />
                <JobBoardViewField label="Display Salary" value={displaySalary} />
            </Box>
            <Box display="flex" flexDirection="column" gap={2} width="50%">
                <JobBoardViewField label="Regions" value={renderedRegions} />
                <JobBoardViewField label="Location" value={location} />
            </Box>
        </Box>
    );
}