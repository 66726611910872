import { ReferenceCheck, ReferenceCheckRequest } from "common/models/ReferenceChecks/ReferenceCheckRequest";
import { ErrorHandler, RequestOptions } from "../common/models/GenericTypes";
import { Get, Post } from "../util/HttpUtils";
import { ManualReferenceCheckRequest } from "common/models/ReferenceChecks/ManualReferenceCheck";

export async function GetReferenceCheckRequestByActivityId(activityId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<ManualReferenceCheckRequest>(`api/ReferenceCheck/GetByActivityId/${activityId}`, options);
}

export async function CreateDigitalRefCheck(data: unknown, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        noDataReturned: true,
        needsAuth: true,
        errorHandler
    };

    return Post<boolean>('api/ReferenceCheck/DigitalReferenceCheck', options, data);
}

export async function GetCurrentCustomerReferenceCheckRequests_All(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<ReferenceCheckRequest[]>('api/ReferenceCheck/Customer/All', options);
}

export async function GetMyReferenceCheckRequests_All(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<ReferenceCheckRequest[]>('api/ReferenceCheck/Me/All', options);
}

export async function GetCurrentCustomerReferenceCheckRequests_Incomplete(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<ReferenceCheckRequest[]>('api/ReferenceCheck/Customer/Incomplete', options);
}

export async function GetMyReferenceCheckRequests_Incomplete(errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<ReferenceCheckRequest[]>('api/ReferenceCheck/Me/Incomplete', options);
}


export async function GetReferenceChecks(requestId: number, errorHandler?: ErrorHandler) {
    const options: RequestOptions = {
        needsAuth: true,
        errorHandler
    };

    return Get<ReferenceCheck[]>(`api/ReferenceCheck/Requests/${requestId}/References`, options);
}