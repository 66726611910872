import React from "react";

export function GetPhoneLink(phone: string, underline: boolean = false) {
    if (phone) {
        const cleanPhone = phone.replaceAll(' ', '');
        return <a href={`tel:${cleanPhone}`} rel="noopener noreferrer" style={{ color: 'inherit', textDecoration: underline ? 'underline' : 'none' }}>{phone}</a>;
    }
    return <></>;
}

export function GetPhoneLinkWithStandardized(phone: string, standardized: string, underline: boolean = false) {
    if (phone) {
        if (standardized) {
            const cleanPhone = standardized.replaceAll(' ', '');
            return <a href={`tel:${cleanPhone}`} rel="noopener noreferrer" style={{ color: 'inherit', textDecoration: underline ? 'underline' : 'none' }}>{phone}</a>;
        } else {
            return GetPhoneLink(phone, underline);
        }

    }
    return <></>;
}