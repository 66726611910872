import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Placement } from "common/models/Placements";
import { PreviewEntityType } from "common/models/Previews/Previews";
import { Division } from "common/models/Configuration/Division";
import { CustomField } from "common/models/Configuration/CustomFields";
import { PanelModel } from "@syncfusion/ej2-layouts/src/dashboard-layout/dashboard-layout-model";
import { useTheme } from "@mui/material/styles";
import { DefaultPlacementRecordDashboardElements_Contract, DefaultPlacementRecordDashboardElements_FixedTermContract, DefaultPlacementRecordDashboardElements_MaxTermContract, DefaultPlacementRecordDashboardElements_Permanent, DefaultPlacementRecordDashboardElements_MarginOnlyContract, GetPanelDefinitionsFromPanelModels, PlacementScreenLayoutSettings } from "util/Definitions/ScreenLayouts/Placement";
import { GetCustomFieldsByEntity_OnlyActive } from "services/CustomFieldsService";
import { GetDivisions } from "services/DivisionsService";
import { CustomFieldSettingsMap, CustomFieldType } from "common/models/ScreenLayouts/CustomFields";
import { GetCustomerSettingBySettingName } from "services/ConfigurationService";
import { PlacementRecordDashboardElementDefinition, PlacementRecordDashboardElementType } from "common/models/Dashboard/EditLayout";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import SingleFieldElement from "../SingleFieldElement";
import ViewClientLocationElement from "../Clients/ViewClientLocationElement";
import MultiLineTextElement from "../MultiLineTextElement";
import Chip from "@mui/material/Chip";
import { UdfPlacementFieldMapObj } from "util/Definitions/ScreenLayouts/CustomFields";
import { DashboardLayoutComponent } from "@syncfusion/ej2-react-layouts/src/dashboard-layout/dashboardlayout.component";
import PanelWrapper from "components/Dashboards/PanelWrapper";
import PreviewLoaderComponent from "components/Previews/PreviewLoader";
import { Currency } from "common/models/Common";
import { GetCurrencies } from "services/CommonService";

interface Props {
    placement: Placement | null,
    netRevenueLabel?: string,
    isSeperatePayBillDivision?: boolean,
    loadingHandler?: (isLoading: boolean) => void,
    errorHandler?: (message: string) => void,
}

const { unitWidth, unitHeight, gapX, gapY, columns, mediaQueryMaxWidth } = PlacementScreenLayoutSettings;
const cellSpacing = [gapX, gapY];

const formatNumber = (value: number) => {
    return value.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 });
};

const getNoticePeriodUnitsName = (unitsId: number) => {
    switch (unitsId)
    {
        case 1: return "Day(s)";
        case 2: return "Week(s)";
        case 3: return "Month(s)";
        default: return "";
    }
};

const getPayFreqName = (payFreqId: number) => {
    switch (payFreqId)
    {
        case 1: return "Weekly";
        case 2: return "Fortnightly";
        case 3: return "Monthly";
        case 4: return "4 Weekly";
        case 5: return "Weekly ( 1 Week in Arrears )";
        case 6: return "Weekly ( 3 Weeks in Arrears )";
        default: return "";
    }
};

const getPaymentTypeName = (paymentTypeId: number) => {
    switch (paymentTypeId)
    {
        case 1: return "Employee";
        case 2: return "Sub Contractor";
        default: return "";
    }
};

const getFeeString = (feeType: number, feeAmount: number, feePercent: number) => {
    if (feeType === 1) return `${formatNumber(feePercent)} Percent (${formatNumber(feeAmount)})`;
    if (feeType === 2) return `${formatNumber(feeAmount)} Fixed (${formatNumber(feePercent)}%)`;
    return '';
};

export default function ViewRecordScreenLayout({ placement, netRevenueLabel = 'Net Revenue', isSeperatePayBillDivision, loadingHandler, errorHandler }: Props) {
    const [divisions, setDivisions] = useState<Division[]>([]);
    const [currencies, setCurrencies] = useState<Currency[]>([]);
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    const [previewType, setPreviewType] = useState<PreviewEntityType | ''>('');
    const [previewRecordId, setPreviewRecordId] = useState(0);
    const [screenResizedControl, setScreenResizedControl] = useState(false);
    const [fetchingSavedState, setFetchingSavedState] = useState(false);
    const [fetchedSavedState, setFetchedSavedState] = useState(false);
    const [activeFields, setActiveFields] = useState<CustomField[]>([]);
    const [lastSavedState, setLastSavedState] = useState<PanelModel[] | null>(null);
    const layoutRef = useRef<DashboardLayoutComponent | null>(null);
    const theme = useTheme();

    const layoutDataSettingName = useMemo(() => {
        if (placement) {
            switch (placement.placementTypeID) {
                case 1: return 'PlacementRecordDashboardLayoutElements_Permanent';
                case 2: return 'PlacementRecordDashboardLayoutElements_Contract';
                case 3: return 'PlacementRecordDashboardLayoutElements_FixedTermContract';
                case 4: return 'PlacementRecordDashboardLayoutElements_MaxTermContract';
                case 5: return 'PlacementRecordDashboardLayoutElements_MarginOnlyContract';
            }
        }
        return '';
    }, [placement]);

    const defaultElements = useMemo(() => {
        if (placement) {
            switch (placement.placementTypeID) {
                case 1: return DefaultPlacementRecordDashboardElements_Permanent;
                case 2: return DefaultPlacementRecordDashboardElements_Contract;
                case 3: return DefaultPlacementRecordDashboardElements_FixedTermContract;
                case 4: return DefaultPlacementRecordDashboardElements_MaxTermContract;
                case 5: return DefaultPlacementRecordDashboardElements_MarginOnlyContract;
            }
        }
        return [];
    }, [placement]);

    useEffect(() => {
        const windowResizeHandler = () => setScreenResizedControl(prev => !prev);
        window.addEventListener('resize', windowResizeHandler);
        return () => window.removeEventListener('resize', windowResizeHandler);
    }, []);

    useEffect(() => {
        const getActiveFields = async () => {
            const res = await GetCustomFieldsByEntity_OnlyActive(5);
            if (res) setActiveFields(res);
        };
        getActiveFields();
    }, []);

    useEffect(() => {
        const getSettingsData = async () => {
            const res = await GetDivisions();
            if (res) setDivisions(res);
            const res2 = await GetCurrencies();
            if (res2) setCurrencies(res2);
        };
        getSettingsData();
    }, []);

    const divisionNames = useMemo<string[]>(() => {
        if (placement) {
            let names: string[] = [];
            const ids = placement.division.toString().split(';');
            ids.forEach(d => {
                const item = divisions.find(div => div.id === +d);
                if (item) names.push(item.name);
            });
            return names;
        }
        return [];
    }, [placement, divisions]);

    const payDivisionNames = useMemo<string[]>(() => {
        if (placement) {
            let names: string[] = [];
            const ids = placement.payDivision.toString().split(';');
            ids.forEach(d => {
                const item = divisions.find(div => div.id === +d);
                if (item) names.push(item.name);
            });
            return names;
        }
        return [];
    }, [placement, divisions]);

    const customFieldsSettingsMap = useMemo<CustomFieldSettingsMap>(() => {
        let obj: CustomFieldSettingsMap = {};
        activeFields.forEach(u => {
            const customFieldNumber = u.name.substring(13);
            const key = 'CustomField' + customFieldNumber as CustomFieldType;
            obj[key] = {
                title: u.agencyName,
                isMultiLine: u.multiLine && !u.usePredefinedValues,
            }
        });
        return obj;
    }, [activeFields]);

    useEffect(() => {
        loadingHandler && loadingHandler(fetchingSavedState);
    }, [loadingHandler, fetchingSavedState]);

    useEffect(() => {
        const getSavedState = async () => {
            setFetchingSavedState(true);
            const elementsJson = await GetCustomerSettingBySettingName(layoutDataSettingName);
            if (elementsJson) {
                const panels = JSON.parse(elementsJson) as PanelModel[];
                setLastSavedState(panels);
            }
            setFetchingSavedState(false);
            setFetchedSavedState(true);
        };
        layoutDataSettingName && getSavedState();
    }, [layoutDataSettingName]);

    const elements = useMemo<PlacementRecordDashboardElementDefinition[]>(() => {
        if (!fetchedSavedState) return [];
        if (lastSavedState !== null) {
            const elements = GetPanelDefinitionsFromPanelModels(lastSavedState);
            return elements ;
        }
        return defaultElements;
    }, [lastSavedState, fetchedSavedState, defaultElements]);

    const openPreviewHandler = useCallback((type: PreviewEntityType, recordId: number) => {
        setPreviewType(type);
        setPreviewRecordId(recordId);
        setIsPreviewOpen(true);
    }, []);

    const closePreviewHandler = useCallback(() => {
        setIsPreviewOpen(false);
    }, []);

    const currencyName = useMemo(() => {
        if (placement) {
            const currency = currencies.find(c => c.id === placement.currencyID);
            if (currency) return currency.formattedDescription;
        }
        return '';
    }, [placement, currencies]);

    const renderElement = useCallback((id: string, type: PlacementRecordDashboardElementType) => {
        if (placement) {
            switch (type) {
                case 'Divider': return <Box pt="20px"><Divider component="div" /></Box>;
                case 'Spacer': return <></>;
                case 'PlacementId': return <SingleFieldElement useEllipsisForLongValues fieldTitle="ID" fieldValue={placement.id.toString()} />;
                case 'PlacementJobId': return (
                    <SingleFieldElement
                        fieldTitle='Job ID'
                        fieldValue={placement.jobID.toString()}
                        format="internal-link"
                        href={`/jobs/${placement.jobID}`}
                        previewType="job"
                        previewRecordId={placement.jobID}
                        openPreviewHandler={openPreviewHandler}
                        closePreviewHandler={closePreviewHandler}
                    />);
                case 'PlacementType': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Job Type" fieldValue={placement.placementTypeName} />;
                case 'PlacementJobTitle': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Job Title" fieldValue={placement.jobTitle} />;
                case 'PlacementCandidate': return (
                    <SingleFieldElement
                        fieldTitle='Candidate'
                        fieldValue={placement.candidateName}
                        format="internal-link"
                        href={`/candidates/${placement.candidateID}`}
                        previewType="candidate"
                        previewRecordId={placement.candidateID}
                        openPreviewHandler={openPreviewHandler}
                        closePreviewHandler={closePreviewHandler}
                    />);
                case 'PlacementStartDate': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Start Date" fieldValue={placement.startDate} format="date" />;
                case 'PlacementEndDate': return <SingleFieldElement useEllipsisForLongValues fieldTitle="End Date" fieldValue={placement.endDate} format="date" />;
                case 'PlacementDivision': return (
                        <SingleFieldElement fieldTitle={isSeperatePayBillDivision ? 'Bill Division' : 'Division'} format="custom">
                            {divisionNames.map(d => (
                                <Chip key={d} size="small" label={d} sx={{ mr: '5px' }} />
                            ))}
                        </SingleFieldElement>
                    );
                case 'PlacementPayDivision': return (
                        <SingleFieldElement fieldTitle="Pay Division" format="custom">
                            {payDivisionNames.map(d => (
                                <Chip key={d} size="small" label={d} sx={{ mr: '5px' }} />
                            ))}
                        </SingleFieldElement>
                    );
                case 'PlacementCurrency': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Currency" fieldValue={currencyName} />;
                case 'PlacementSalaryPackage':
                    const salaryFrom = formatNumber(placement.salary);
                    return <SingleFieldElement useEllipsisForLongValues fieldTitle="Salary Package" fieldValue={`${salaryFrom} ${placement.salaryUnitsName}`} />;
                case 'PlacementInvoiceFee': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Fee" fieldValue={getFeeString(placement.feeType, placement.permFeeAmount, placement.permFeePercentage)} />;
                case 'PlacementRatesCalculator': return <SingleFieldElement useEllipsisForLongValues fieldTitle="" fieldValue="Rates Calculator" />;
                case 'PlacementChargeRate': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Charge Rate" fieldValue={ formatNumber(placement.chargeRate) + ' ' + placement.chargeRateUnitsName} />;
                case 'PlacementPayRate': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Pay Rate" fieldValue={ formatNumber(placement.payRate) + ' ' + placement.payRateUnitsName} />;
                case 'PlacementOnCost': return <SingleFieldElement useEllipsisForLongValues fieldTitle="On Costs" fieldValue={ formatNumber(placement.onCosts) + ' ' + placement.onCostsUnitsName} />;
                case 'PlacementMargin': 
                    const margin = placement.chargeRate - (placement.payRate + placement.onCosts);
                    const marginPercent = placement.chargeRate !== 0 ? (margin * 100) / placement.chargeRate : 0;
                    return <SingleFieldElement useEllipsisForLongValues fieldTitle="Margin" fieldValue={ placement.chargeRate === 0 ? '' : `${formatNumber(margin)} (${formatNumber(marginPercent)}%)`} />;
                case 'PlacementHoursPerDay': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Hours Per Day" fieldValue={placement.hoursPerDay.toString()} />;
                case 'PlacementDaysPerWeek': 
                    const daysPerWeek = placement.daysPerWeek >= 1 && placement.daysPerWeek <= 7 ? placement.daysPerWeek.toString() : '';
                    return <SingleFieldElement useEllipsisForLongValues fieldTitle="Days Per Week" fieldValue={`${daysPerWeek} Day${placement.daysPerWeek > 1 ? 's' : ''}`} />;
                case 'PlacementComments': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Comments" fieldValue={placement.remComments} />;
                case 'PlacementPaymentType': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Payment Type" fieldValue={getPaymentTypeName(placement.paymentTypeID)} />;
                case 'PlacementPayCompany': return (
                    <SingleFieldElement
                        fieldTitle='Pay Company'
                        fieldValue={placement.paymentCompanyName ?? ''}
                        format="internal-link"
                        href={`/clients/${placement.paymentCompanyID}`}
                        previewType="client"
                        previewRecordId={placement.paymentCompanyID}
                        openPreviewHandler={openPreviewHandler}
                        closePreviewHandler={closePreviewHandler}
                    />);
                case 'PlacementPayContact': return (
                    <SingleFieldElement
                        fieldTitle='Pay Contact'
                        fieldValue={placement.paymentContactName ?? ''}
                        format="internal-link"
                        href={`/contacts/${placement.paymentContactID}`}
                        previewType="contact"
                        previewRecordId={placement.paymentContactID}
                        openPreviewHandler={openPreviewHandler}
                        closePreviewHandler={closePreviewHandler}
                    />);
                case 'PlacementWarranty': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Warranty" fieldValue={placement.warrantyLength + ' Month(s)'} />;
                case 'PlacementLocation': return (
                    <ViewClientLocationElement
                        address1={placement.address1 ?? ''}
                        address2={placement.address2 ?? ''}
                        address3={placement.address3 ?? ''}
                        countryName={placement.countryName ?? ''}
                        postcode={placement.postcode ?? ''}
                        siteId={placement.siteID}
                        siteName={placement.siteName ?? ''}
                        state={placement.state ?? ''}
                        suburb={placement.suburb ?? ''}
                        openPreviewHandler={openPreviewHandler}
                        closePreviewHandler={closePreviewHandler}
                    />);
                case 'PlacementClient': return (
                    <SingleFieldElement
                        fieldTitle='Client'
                        fieldValue={placement.clientName}
                        format="internal-link"
                        href={`/clients/${placement.clientID}`}
                        previewType="client"
                        previewRecordId={placement.clientID}
                        openPreviewHandler={openPreviewHandler}
                        closePreviewHandler={closePreviewHandler}
                    />);
                case 'PlacementContact': return (
                    <SingleFieldElement
                        fieldTitle='Contact'
                        fieldValue={placement.contactName}
                        format="internal-link"
                        href={`/contacts/${placement.contactID}`}
                        previewType="contact"
                        previewRecordId={placement.contactID}
                        openPreviewHandler={openPreviewHandler}
                        closePreviewHandler={closePreviewHandler}
                    />);
                case 'PlacementHiringManager': return (
                    <SingleFieldElement
                        fieldTitle='Hiring Mgr'
                        fieldValue={placement.hiringManagerName}
                        format="internal-link"
                        href={`/contacts/${placement.hiringManagerContactID}`}
                        previewType="contact"
                        previewRecordId={placement.hiringManagerContactID}
                        openPreviewHandler={openPreviewHandler}
                        closePreviewHandler={closePreviewHandler}
                    />);
                case 'PlacementBilling1': return (
                    <SingleFieldElement
                        fieldTitle='Billing 1'
                        fieldValue={placement.billingContactName ?? ''}
                        format="internal-link"
                        href={`/contacts/${placement.billingContactContactID}`}
                        previewType="contact"
                        previewRecordId={placement.billingContactContactID}
                        openPreviewHandler={openPreviewHandler}
                        closePreviewHandler={closePreviewHandler}
                    />);
                case 'PlacementBilling2': return (
                    <SingleFieldElement
                        fieldTitle='Billing 2'
                        fieldValue={placement.billingContact2Name ?? ''}
                        format="internal-link"
                        href={`/contacts/${placement.billingContact2ContactID}`}
                        previewType="contact"
                        previewRecordId={placement.billingContact2ContactID}
                        openPreviewHandler={openPreviewHandler}
                        closePreviewHandler={closePreviewHandler}
                    />);
                case 'PlacementBilling3': return (
                    <SingleFieldElement
                        fieldTitle='Billing 3'
                        fieldValue={placement.billingContact3Name ?? ''}
                        format="internal-link"
                        href={`/contacts/${placement.billingContact3ContactID}`}
                        previewType="contact"
                        previewRecordId={placement.billingContact3ContactID}
                        openPreviewHandler={openPreviewHandler}
                        closePreviewHandler={closePreviewHandler}
                    />);
                case 'PlacementSignatory': return (
                    <SingleFieldElement
                        fieldTitle='Signatory'
                        fieldValue={placement.signatoryName ?? ''}
                        format="internal-link"
                        href={`/contacts/${placement.signatoryContactID}`}
                        previewType="contact"
                        previewRecordId={placement.signatoryContactID}
                        openPreviewHandler={openPreviewHandler}
                        closePreviewHandler={closePreviewHandler}
                    />);
                case 'PlacementClientRef': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Client Ref" fieldValue={placement.clientReference} />;
                case 'PlacementApprover1': return (
                    <SingleFieldElement
                        fieldTitle='Approver 1'
                        fieldValue={placement.timesheetApprover1Name ?? ''}
                        format="internal-link"
                        href={`/contacts/${placement.timesheetApprover1}`}
                        previewType="contact"
                        previewRecordId={placement.timesheetApprover1}
                        openPreviewHandler={openPreviewHandler}
                        closePreviewHandler={closePreviewHandler}
                    />);
                case 'PlacementApprover2': return (
                    <SingleFieldElement
                        fieldTitle='Approver 2'
                        fieldValue={placement.timesheetApprover2Name ?? ''}
                        format="internal-link"
                        href={`/contacts/${placement.timesheetApprover2}`}
                        previewType="contact"
                        previewRecordId={placement.timesheetApprover2}
                        openPreviewHandler={openPreviewHandler}
                        closePreviewHandler={closePreviewHandler}
                    />);
                case 'PlacementSummary': return <MultiLineTextElement title="Summary" content={placement.summary ?? ''} />;
                case 'PlacementStatus': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Status" fieldValue={placement.placementStatusName} />;
                case 'PlacementConsultant1': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Consultant 1" fieldValue={ placement.consultantID1 ? `${placement.consultantName1} (${placement.consultantPercentage1}%)` : ''} />;
                case 'PlacementConsultant2': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Consultant 2" fieldValue={ placement.consultantID2 ? `${placement.consultantName2} (${placement.consultantPercentage2}%)` : ''} />;
                case 'PlacementConsultant3': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Consultant 3" fieldValue={ placement.consultantID3 ? `${placement.consultantName3} (${placement.consultantPercentage3}%)` : ''} />;
                case 'PlacementConsultant4': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Consultant 4" fieldValue={ placement.consultantID4 ? `${placement.consultantName4} (${placement.consultantPercentage4}%)` : ''} />;
                case 'PlacementConsultant5': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Consultant 5" fieldValue={ placement.consultantID5 ? `${placement.consultantName5} (${placement.consultantPercentage5}%)` : ''} />;
                case 'PlacementSource': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Source" fieldValue={placement.source} />;
                case 'PlacementNetRevenue': return <SingleFieldElement useEllipsisForLongValues fieldTitle={netRevenueLabel} fieldValue={formatNumber(placement.netRevenue)} />;
                case 'PlacementDate': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Placement Date" fieldValue={placement.placementDate} format="date" />;
                case 'PlacementProbation': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Probation" fieldValue={placement.probationLength + ' Month(s)'} />;
                case 'PlacementNotice': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Notice" fieldValue={placement.noticePeriod + ' ' + getNoticePeriodUnitsName(placement.noticePeriodUnits)} />;
                case 'PlacementPayFreq': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Pay Frequency" fieldValue={getPayFreqName(placement.payFrequencyID)} />;
                case 'PlacementComplianceChecklist': return <SingleFieldElement useEllipsisForLongValues fieldTitle="Compliance Checklist" fieldValue={placement.complianceChecklistName} />;
                default: 
                    if (type.startsWith('CustomField')) {
                        const settings = customFieldsSettingsMap[type];
                        if (settings) {
                            const title = settings.title;
                            const { field, format } = UdfPlacementFieldMapObj[type as CustomFieldType];
                            const udfValue = placement[field];
                            if (format === 'string' || format === 'date' || format === 'datetime') {
                                return <SingleFieldElement isMultiLine={settings.isMultiLine} format={format} fieldTitle={title} fieldValue={udfValue ? udfValue.toString() : null} />;
                            }
                            if (format === 'number') {
                                const numValue = udfValue === undefined || udfValue === null ? '' : udfValue.toString();
                                return <SingleFieldElement fieldTitle={title} fieldValue={numValue} />;
                            }
                        }
                        else return <SingleFieldElement fieldTitle={type} fieldValue="[ Disabled Custom Field ]" />
                    }
                    return <div>{id}</div>;
            }
        }
    }, [placement, openPreviewHandler, closePreviewHandler, isSeperatePayBillDivision, divisionNames, payDivisionNames, currencyName, netRevenueLabel, customFieldsSettingsMap]);
    
    const layoutResizeStopHandler = useCallback(() => {
        setScreenResizedControl(prev => !prev);
    }, []);

    useEffect(() => {
        if (screenResizedControl) {}
        const api = layoutRef.current;
        if (api && fetchedSavedState) {
            const refreshTimeout = setTimeout(() => {
                api.refresh();
                api.refresh();
            }, 250);
            return () => clearTimeout(refreshTimeout);
        }
    }, [fetchedSavedState, screenResizedControl]);

    const renderLayout = useCallback(() => {
        if (!fetchedSavedState) return <></>;

        const mediaQuery = mediaQueryMaxWidth ? `max-width: ${mediaQueryMaxWidth}` : undefined;
        return (
            <DashboardLayoutComponent
                cellSpacing={cellSpacing}
                columns={columns}
                cellAspectRatio={30 / 2}
                resizeStop={ layoutResizeStopHandler }
                allowDragging={false}
                allowResizing={false}
                ref={l => layoutRef.current = l}
                mediaQuery={mediaQuery}
            >
                {elements.map(e => (
                    <PanelWrapper
                        key={e.id}
                        id={e.id}
                        col={e.col}
                        row={e.row}
                        sizeX={e.sizeX}
                        sizeY={e.sizeY}
                        minSizeX={e.minSizeX}
                        minSizeY={e.minSizeY}
                        maxSizeX={e.maxSizeX}
                        maxSizeY={e.maxSizeY}
                        resizeControl={screenResizedControl}
                        resizeIconColor={theme.palette.text.disabled}
                        gapX={gapX}
                        gapY={gapY}
                        unitWidth={unitWidth}
                        unitHeight={unitHeight}
                    >
                        {renderElement(e.id, e.type)}
                    </PanelWrapper>
                ))}
            </DashboardLayoutComponent>
        );
    }, [elements, fetchedSavedState, theme.palette.text.disabled, screenResizedControl, layoutResizeStopHandler, renderElement]);

    return (
        <>
            <PreviewLoaderComponent
                open={isPreviewOpen}
                entityType={previewType}
                recordId={previewRecordId}
            />
            <Box p="10px" height="100%">
                <div className="control-section">
                    { renderLayout() }
                </div>
            </Box>
        </>
    );
}